import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import SideBar from '../../components/common/SideBar';
import RouteName from '../App/RouteNames';
import {
  checkPermissionGetReasonCodeConfig,
  checkPermissionSearchAdjustment,
  checkPermissionSearchDispute,
  checkPermissionSearchWriteoff,
  checkPermissionGetConfigGroupReasonCode,
  checkPermissionGetConfigArOpsThresholds,
  checkPermissionBulkAdjustments,
} from './CheckPermission';
import { checkPermissionGetCreditNoteReport } from '../ReportManagement/CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends PureComponent {
  render() {
    const { isExpand = true, permissionsArOps, permissionsReports } = this.props;
    const creditNotesRoute = [];
    let modeGetCreditNoteReport = 0;
    if (!isHasPermissionWithMenu({ permissions: permissionsArOps, listKeys: permissionList.AROperations })) return null;

    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      modeGetCreditNoteReport = checkPermissionGetCreditNoteReport({ listPermission });
    }

    let modeGetReasonCodeConfig = 0;
    let modeSearchAdjustment = 0;
    let modeSearchDispute = 0;
    let modeSearchWriteoff = 0;
    let modeGetConfigGroupReasonCode = 0;
    let modeGetConfigArOpsThresholds = 0;
    let modeBulkAdjustments = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeGetReasonCodeConfig = checkPermissionGetReasonCodeConfig({ listPermission });
      modeSearchAdjustment = checkPermissionSearchAdjustment({ listPermission });
      modeSearchDispute = checkPermissionSearchDispute({ listPermission });
      modeSearchWriteoff = checkPermissionSearchWriteoff({ listPermission });
      modeGetConfigGroupReasonCode = checkPermissionGetConfigGroupReasonCode({ listPermission });
      modeGetConfigArOpsThresholds = checkPermissionGetConfigArOpsThresholds({ listPermission });
      modeBulkAdjustments = checkPermissionBulkAdjustments({ listPermission });
    }

    if (modeBulkAdjustments) {
      creditNotesRoute.push({
        icon: 'ti-power-off',
        label: 'arOperationPage:sidebar.bulkAdjustments',
        href: RouteName.arOperationsBulkAdjustments.path,
      });
    }
    if (modeGetCreditNoteReport) {
      creditNotesRoute.push({
        icon: 'ti-comments',
        label: 'common:label.creditNotes',
        href: RouteName.arCreditNotesReport.path,
      });
    }

    const sidePropDetails = {
      isExpand: false,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'common:label.config',
          isExpandChild: true,
          childs: [
            // {
            //   icon: 'ti-save',
            //   label: 'arOperationPage:sidebar.GLsetup',
            //   href: RouteName.arOperationsConfigGL.path,
            //   disable: true,
            // },
            {
              icon: 'ti-unlock',
              label: 'arOperationPage:sidebar.GLsetup',
              href: RouteName.arOperationsConfig.path,
            },
            {
              icon: 'ti-map',
              label: 'arOperationPage:sidebar.itemMap',
              href: RouteName.arOperationsConfigItemMap.path,
            },
            {
              icon: 'ti-harddrives',
              label: 'arOperationPage:sidebar.reasonCode',
              href: RouteName.arOperationsConfigReasonCode.path,
            },
            {
              icon: 'ti-layout',
              label: 'arOperationPage:sidebar.groupsReason',
              href: RouteName.arOperationsConfigGetReasonGroup.path,
            },
            {
              icon: 'ti-layout',
              label: 'arOperationPage:sidebar.ARThresholds',
              href: RouteName.arOperationsARThresholds.path,
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'navbar:arHub.subMain.AROperations',
          childs: [
            // {
            //   icon: 'ti-unlock',
            //   label: 'arOperationPage:sidebar.GLsetup',
            //   href: RouteName.arOperationsConfig.path,
            // },
            {
              icon: 'ti-panel',
              label: 'arOperationPage:sidebar.adjustments',
              href: RouteName.arOperationsAdjustments.path,
            },
            {
              icon: 'ti-user',
              label: 'arOperationPage:sidebar.disputes',
              href: RouteName.arOperationsDisputes.path,
            },
            {
              icon: 'ti-pencil-alt',
              label: 'arOperationPage:sidebar.writeOffs',
              href: RouteName.arOperationsWriteOffs.path,
            },
            // {
            //   icon: 'ti-power-off',
            //   label: 'arOperationPage:sidebar.bulkAdjustments',
            //   href: RouteName.arOperationsBulkAdjustments.path,
            //   // disable: true,
            // },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'common:label.bulkOperations',
          isExpandChild: true,
          childs: [...creditNotesRoute],
        },
      ],
    };
    sidePropDetails.isExpand = isExpand;
    const newSideProps = cloneDeep(sidePropDetails);

    if (modeGetReasonCodeConfig === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'arOperationPage:sidebar.aROpsConfig'
      );
    }
    if (modeSearchAdjustment === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'arOperationPage:sidebar.adjustments'
      );
    }
    if (modeSearchDispute === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'arOperationPage:sidebar.disputes'
      );
    }
    if (modeSearchWriteoff === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'arOperationPage:sidebar.writeOffs'
      );
    }
    if (modeGetConfigGroupReasonCode === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'arOperationPage:sidebar.groupsReason'
      );
    }
    if (modeGetConfigArOpsThresholds === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'arOperationPage:sidebar.ARThresholds'
      );
    }

    return <SideBar {...newSideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsArOps = store.get('auth').toJS().permissionsApp.arHubModules;
  const permissionsReports = store.get('auth').toJS().permissionsApp.opsHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsArOps: permissionsArOps.length ? permissionsArOps.filter(a => a.moduleName === 'AR_OPS')[0] : {},
    permissionsReports: permissionsReports.length ? permissionsReports.filter(a => a.moduleName === 'REPORTS')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
  modeSearchAdjustment: PropTypes.number,
  modeSearchDispute: PropTypes.number,
  modeSearchWriteoff: PropTypes.number,
  modeGetReasonCodeConfig: PropTypes.number,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  sideProps: {},
  modeSearchAdjustment: 2,
  modeSearchDispute: 2,
  modeSearchWriteoff: 2,
  modeGetReasonCodeConfig: 2,
  permissionsArOps: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
