import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import DiscountForm from './DiscountForm';
import { makeErrorMessage } from '../selectors';
import { makeGetPermissionsPricingManagement, makeGetCcpTime, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  checkPermissionCeateDiscountOffer,
  checkPermissionApproveDiscountOffer,
  checkPermissionSellableDiscountOffer,
  checkPermissionNonSellableDiscountOffer,
  checkPermissionSunsetDiscountOffer,
} from '../CheckPermission';
import { createDisscountOffer, getUsageContainerSpec } from '../actions';
import {
  getCcpDateTimeConfig,
  getAccumulatorsConfig,
  getCcpPropertiesConfig,
  getConfigUsageTypeConfig,
} from '../../App/actions';

class PricingPriceOfferAdd extends Component {
  constructor(props) {
    const { location } = props;
    super(props);
    this.state = {
      isSubmitting: false,
      newDiscountOffer:
        location && location.state && !location.state.filter
          ? location.state
          : {
              status: 'CREATE',
              minimumQuantity: '1',
              discountingType: 'CUSTOMER',
            },
      accumulatorList: [],
      grantList: [],
      usageContainerRecordList: [],
      defaultCurrency: null,
    };
  }

  componentDidMount() {
    const {
      getAccumulatorsConfig,
      getUsageContainerSpec,
      ccpTime,
      getCcpDateTimeConfig,
      getCcpPropertiesConfig,
      ccpPropertyList,
    } = this.props;
    const { newDiscountOffer } = this.state;
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) this.setState({ newDiscountOffer: { ...newDiscountOffer, effectiveDate: data?.ccpTime || null } });
      });
    } else {
      this.setState({ newDiscountOffer: { ...newDiscountOffer, effectiveDate: ccpTime || null } });
    }
    getAccumulatorsConfig(({ data, success }) => {
      if (success) {
        this.setState({ accumulatorList: data && data.accumulatorList ? data.accumulatorList : [] });
      }
    });

    getUsageContainerSpec(({ data, success }) => {
      if (success) {
        this.setState({ usageContainerRecordList: data && data.length ? data : [] });
      }
    });
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          this.doInitCcpPropertyList(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitCcpPropertyList(ccpPropertyList);
    }
  }

  doInitCcpPropertyList = ccpPropertyList => {
    const { newDiscountOffer } = this.state;
    const countryProperty = ccpPropertyList.find(val => val.property === 'defaultCountry');
    const defaultCurrency = ccpPropertyList.find(val => val.property === 'defaultCurrency');

    this.setState({
      newDiscountOffer: {
        ...newDiscountOffer,
        country: countryProperty && countryProperty?.value ? countryProperty?.value : null,
      },
      defaultCurrency: defaultCurrency?.value ? defaultCurrency.value : null,
    });
  };

  onSave = data => {
    const { createDisscountOffer, history } = this.props;
    this.setState({ isSubmitting: true });
    createDisscountOffer(data, ({ success, payload }) => {
      this.setState({ isSubmitting: false });
      if (success) {
        history.push(RouteNames.prettifyPath(RouteNames.pricingDiscountEdit.path, payload));
      }
    });
  };

  render() {
    const {
      newDiscountOffer,
      isSubmitting,
      accumulatorList,
      grantList,
      usageContainerRecordList,
      defaultCurrency,
    } = this.state;
    const { permissionsPricing, t, location, getConfigUsageTypeConfig } = this.props;
    let modeCreateDiscountOffer = 0;
    let modeApproveDiscountOffer = 0;
    let modeSellableDiscountOffer = 0;
    let modeNonSellableDiscountOffer = 0;
    let modeSunsetDiscountOffer = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateDiscountOffer = checkPermissionCeateDiscountOffer({ listPermission: listPermissionPricing });
      modeApproveDiscountOffer = checkPermissionApproveDiscountOffer({ listPermission: listPermissionPricing });
      modeSellableDiscountOffer = checkPermissionSellableDiscountOffer({ listPermission: listPermissionPricing });
      modeNonSellableDiscountOffer = checkPermissionNonSellableDiscountOffer({ listPermission: listPermissionPricing });
      modeSunsetDiscountOffer = checkPermissionSunsetDiscountOffer({ listPermission: listPermissionPricing });
    }
    if (modeCreateDiscountOffer !== 2) return '';

    const optionsAccumulatorId = accumulatorList.map(item => ({
      label: item.accumulatorid,
      value: item.accumulatorid,
    }));

    const optionsGrantId = grantList.map(item => ({
      label: item.resourceId,
      value: item.resourceId,
    }));

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.pricingDiscountOffer.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.discountOffer'), url: RouteNames.pricingDiscountOffer.path },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <DiscountForm
          item={newDiscountOffer}
          onSubmit={this.onSave}
          isSubmitting={isSubmitting}
          creatingMode
          optionsAccumulatorId={optionsAccumulatorId}
          optionsGrantId={optionsGrantId}
          usageContainerRecordList={usageContainerRecordList}
          modeApproveDiscountOffer={modeApproveDiscountOffer}
          modeSellableDiscountOffer={modeSellableDiscountOffer}
          modeNonSellableDiscountOffer={modeNonSellableDiscountOffer}
          modeSunsetDiscountOffer={modeSunsetDiscountOffer}
          title="label.createNewDiscountOffer"
          defaultCurrency={defaultCurrency}
          getConfigUsageTypeConfig={getConfigUsageTypeConfig}
        />
      </div>
    );
  }
}

PricingPriceOfferAdd.propTypes = {
  createDisscountOffer: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  getConfigUsageTypeConfig: PropTypes.func.isRequired,
};

PricingPriceOfferAdd.defaultProps = {
  location: {},
  history: {},
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
  ccpTime: makeGetCcpTime() || '',
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

const mapDispatchToProps = dispatch => ({
  createDisscountOffer: (e, cb) => dispatch(createDisscountOffer(e, cb)),
  getAccumulatorsConfig: cb => dispatch(getAccumulatorsConfig(cb)),
  getUsageContainerSpec: cb => dispatch(getUsageContainerSpec(cb)),
  getCcpDateTimeConfig: (e, cb) => dispatch(getCcpDateTimeConfig(e, cb)),
  getCcpPropertiesConfig: (e, cb) => dispatch(getCcpPropertiesConfig(e, cb)),
  getConfigUsageTypeConfig: (e, cb) => dispatch(getConfigUsageTypeConfig(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'pricingPage'])(compose(withConnect)(PricingPriceOfferAdd));
