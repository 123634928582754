import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPageTotalCount } from '../../../utils/utils';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import constant, { arOpsFields } from '../../../containers/CustomerPage/constants';
import { TablePagination, DataTable, ModalDetailsItem, FormCollapse, GenericInput, ModalWithItem } from '../../common';
import {
  makeGetArActivity,
  makeArActivityParams,
  makeErrorMessageTU,
} from '../../../containers/CustomerPage/selectors';
import ArActivitySearchForm from '../ArActivitySearchForm';
import ArOpsActivitySearchForm from '../ArOpsActivitySearchForm';
import ArOpsActivitySearchFormNoInvoiceId from '../ArOpsActivitySearchFormNoInvoiceId';
import {
  searchAllArOpsRequests,
  getArActivity,
  setArActivityParams,
  getArOpsUnitById,
  getArOpsRequestById,
} from '../../../containers/CustomerPage/actions';
import ArOperationsDetails from './ArOperationsDetails';
import RouteNames from '../../../containers/App/RouteNames';
import { makeGetPermissionsArOpsManagement } from '../../../containers/App/selectors';

import {
  checkPermissionGetDisputeDetails,
  checkPermissionProcessSettlement,
  checkPermissionGetWriteoffDetails,
  checkPermissionProcessWriteoffReversal,
} from '../../../containers/ArOperations/CheckPermission';

const arActivitySort = {
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  arType: {
    asc: 'arType_ASC',
    desc: 'arType_DESC',
  },
  arCrDrType: {
    asc: 'arCrDrType_ASC',
    desc: 'arCrDrType_DESC',
  },
  source: {
    asc: 'source_ASC',
    desc: 'source_DESC',
  },
  itemId: {
    asc: 'itemId_ASC',
    desc: 'itemId_DESC',
  },
  invoiceUnitId: {
    asc: 'invoiceUnitId_ASC',
    desc: 'invoiceUnitId_ASC',
  },
};

const arOpsSort = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  customReason: {
    asc: 'reason_ASC',
    desc: 'reason_DESC',
  },
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
  itemId: {
    asc: 'itemId_ASC',
    desc: 'itemId_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_ASC',
  },
  parentId: {
    asc: 'parentId_ASC',
    desc: 'parentId_ASC',
  },
};

class ArActivity extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: { accountId: props.id },
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      isOpenModal: false,
      isSearchDataModal: false,
      itemData: {},
      activeTab: {
        // name: 'Recurring Data',
        // isActive: true,
      },
      pageArOps: 0,
      sizeArOps: 20,
      filterArOps: { accountId: props.id },
      sortArOps: '',
      sortedArOps: {},
      isSearchingArOps: false,
      totalCountArOps: null,
      arOpsData: [],
      itemDetailsSelect: null,
      detailsData: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.arActivity }),
      totalCountArOps: getPageTotalCount({
        totalCount: state.totalCountArOps,
        size: state.sizeArOps,
        page: state.pageArOps,
        items: state.arOpsData,
      }),
    };
  }

  componentDidMount() {
    this.doSearchAllArOpsRequests();
  }

  doSearchAllArOpsRequests = () => {
    const { searchAllArOpsRequests, invoiceSelect } = this.props;
    const { pageArOps, sizeArOps, filterArOps, sortedArOps } = this.state;
    this.setState({ isSearchingArOps: true });
    if (invoiceSelect) filterArOps.invoiceId = invoiceSelect ? invoiceSelect.invoiceUnitId : null;
    searchAllArOpsRequests(
      {
        page: pageArOps + 1,
        size: sizeArOps,
        filter: filterArOps,
        sort: !isEmpty(sortedArOps) ? arOpsSort[sortedArOps.sortCol][sortedArOps.sortDir] : null,
      },
      ({ data }) => {
        this.setState({ isSearchingArOps: false, arOpsData: data || [] });
      }
    );
  };

  doSearchArActivity = () => {
    const { getArActivity } = this.props;
    const { page, size, filter, sorted, itemDetailsSelect } = this.state;
    this.setState({ isSearching: true });
    const newFilter = cloneDeep(filter);
    newFilter.arOpsRequestId = itemDetailsSelect.id;
    getArActivity(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? arActivitySort[sorted.sortCol][sorted.sortDir] : null,
      },
      () => {
        this.setState({ isSearching: false });
      }
    );
  };

  onHandleMainSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filterArOps: newFilter }, () => this.doSearchAllArOpsRequests());
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filter: newFilter }, () => this.doSearchArActivity());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchArActivity());
  };

  onSortMainColumn = (sortCol, sortDir) => {
    this.setState({ sortedArOps: { sortCol, sortDir } }, () => this.doSearchAllArOpsRequests());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchArActivity());
  };

  onSizeMainChange = size => {
    this.setState({ sizeArOps: size, pageArOps: 0 }, () => this.doSearchAllArOpsRequests());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchArActivity());
  };

  onPageMainChange = page => {
    this.setState({ pageArOps: page }, () => this.doSearchAllArOpsRequests());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetArOpsUnitById = id => {
    const { getArOpsUnitById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true, activeTab: {} });
    getArOpsUnitById(id, ({ data, success }) =>
      this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  renderItem = item => {
    const { itemData } = this.state;
    switch (item.state) {
      case 'AR Ops Data':
        return (
          <div className="row col-md-12 p-2">
            {constant.dataFieldArOpsDataId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.arData && itemData.arData[0] && itemData.arData[0][val.name]
                    ? itemData.arData[0][val.name]
                    : null
                }
                disabled
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableOpsTaxData} data={itemData.taxData ? itemData.taxData : []} />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableCurrencyArOpsDataId} data={itemData.balances ? itemData.balances : []} />
          </div>
        );

      default:
        return null;
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleModalDetails = () => {
    this.setState({ itemDetailsSelect: null });
  };

  onViewItemDetails = ({ item }) => {
    this.setState({ itemDetailsSelect: item || null });
  };

  onViewRequestDetails = ({ item }) => {
    const { getArOpsRequestById } = this.props;
    getArOpsRequestById(item.id, ({ success, data }) => {
      if (success) this.setState({ isOpenModalItem: true, detailsData: data || {} });
    });
  };

  onToggleModalRequest = () => {
    const { isOpenModalItem } = this.state;
    this.setState({ isOpenModalItem: !isOpenModalItem });
  };

  handleClickButton = (item, isWriteOff) => {
    const { history, location } = this.props;
    if (isWriteOff) {
      return history.push({
        pathname: RouteNames.arOperationsReverseWriteOffs.path,
        state: { writeOffItemSelected: item, backLink: location.pathname },
      });
    }
    return history.push({
      pathname: RouteNames.arOperationsSettleDisputes.path,
      state: { disputeSelected: item, backLink: location.pathname },
    });
  };

  render() {
    const {
      arActivity,
      id,
      t,
      currencyOptions,
      customerInfo,
      isComponent,
      invoiceSelect,
      permissionsArOps,
      modeSearchArOpsUnit,
    } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      isOpenModal,
      itemData,
      isSearchDataModal,
      activeTab,
      pageArOps,
      sizeArOps,
      sortedArOps,
      isSearchingArOps,
      totalCountArOps,
      arOpsData,
      itemDetailsSelect,
      isOpenModalItem,
      detailsData,
    } = this.state;
    let newCollapsibleMenu = constant.collapsibleMenuArOpsUnitById;

    let modeGetDisputeDetails = 0;
    let modeProcessSettlement = 0;
    let modeGetWriteoffDetails = 0;
    let modeProcessWriteoffReversal = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeGetDisputeDetails = checkPermissionGetDisputeDetails({ listPermission });
      modeProcessSettlement = checkPermissionProcessSettlement({ listPermission });
      modeGetWriteoffDetails = checkPermissionGetWriteoffDetails({ listPermission });
      modeProcessWriteoffReversal = checkPermissionProcessWriteoffReversal({ listPermission });
    }

    const detailsColumns = [];
    const actionColumns = [];

    if (modeProcessSettlement || modeProcessWriteoffReversal) {
      actionColumns.push({
        name: 'Selected',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => {
          let labelButton = t('label.settle');
          let isWriteOff = false;
          if (item.action === 'DISPUTE' && item.status !== 'COLSED') labelButton = t('label.settle');
          if (item.action === 'WRITEOFF' && item.status !== 'COLSED') {
            isWriteOff = true;
            labelButton = t('label.reverse');
          }
          return (isWriteOff && modeProcessWriteoffReversal) || (!isWriteOff && modeProcessSettlement) ? (
            <button
              type="button"
              onClick={() => this.handleClickButton(item, isWriteOff)}
              // disabled={item.status === 'COLSED' || (item.action !== 'DISPUTE' && item.action !== 'WRITEOFF')}
              className="button x-small btn-select-next"
              disabled={
                (isWriteOff && modeProcessWriteoffReversal === 1) ||
                (!isWriteOff && modeProcessSettlement === 1) ||
                item.status === 'COLSED' ||
                (item.action !== 'DISPUTE' && item.action !== 'WRITEOFF')
              }
            >
              {labelButton}
            </button>
          ) : (
            <span />
          );
        },
      });
    }

    if ((modeGetDisputeDetails || modeGetWriteoffDetails) && modeSearchArOpsUnit) {
      actionColumns.push({
        name: 'parentId',
        label: 'common:label.requestId',
        render: (colName, item) => {
          let isWriteOff = false;
          if (item.action === 'WRITEOFF') {
            isWriteOff = true;
          }
          return (isWriteOff && modeGetWriteoffDetails) || (!isWriteOff && modeGetDisputeDetails) ? (
            <button
              className="text-success no-border"
              disabled={modeSearchArOpsUnit === 1}
              type="button"
              onClick={() => this.onViewItemDetails({ item })}
            >
              {item.id || ''}
            </button>
          ) : (
            <span />
          );
        },
      });
      detailsColumns.push({
        name: 'action',
        label: 'common:label.request',
        isRenderT: true,
        render: (colName, item, t) => {
          let isWriteOff = false;
          if (item.action === 'WRITEOFF') {
            isWriteOff = true;
          }
          return (isWriteOff && modeGetWriteoffDetails) || (!isWriteOff && modeGetDisputeDetails) ? (
            <button
              className="btn btn-outline-success btn-sm"
              type="button"
              onClick={() => this.onViewRequestDetails({ item })}
            >
              {t ? t('common:label.view') : ''}
            </button>
          ) : (
            <span />
          );
        },
      });
      detailsColumns.push({
        name: 'details',
        label: 'common:label.details',
        isRenderT: true,
        render: (colName, item, t) => {
          let isWriteOff = false;
          if (item.action === 'WRITEOFF') {
            isWriteOff = true;
          }
          return (isWriteOff && modeGetWriteoffDetails) || (!isWriteOff && modeGetDisputeDetails) ? (
            <button
              className="btn btn-outline-success btn-sm"
              type="button"
              onClick={() => this.onViewItemDetails({ item })}
              disabled={modeSearchArOpsUnit === 1}
            >
              {t ? t('common:label.view') : ''}
            </button>
          ) : (
            <span />
          );
        },
      });
    }

    if (!itemData.arData) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'AR Ops Data');
    }
    if (!itemData.taxData) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');
    }
    if (!itemData.balances) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Currency');
    }

    // if (
    //   !itemData.type ||
    //   [
    //     'BILL_ADJUSTMENT',
    //     'TRANSACTION_ADJUSTMENT',
    //     'BILL_DISPUTE',
    //     'TRANSACTION_DISPUTE',
    //     'BILL_SETTLEMENT',
    //     'ACCOUNT_WRITEOFF',
    //     'INVOICE_WRITEOFF',
    //     'TRANSACTION_SETTLEMENT',
    //     'WRITE_OFF',
    //     'WRITE_OFF_REVERSAL',
    //   ].indexOf(itemData.type) === -1
    // )
    //   newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'AR Ops Data');
    // if (
    //   !itemData.type ||
    //   ['TAX_ADJUSTMENT', 'TAX_DISPUTE', 'TAX_SETTLEMENT', 'TAX_WRITEOFF', 'TAX_WRITEOFF_REVERSAL', 'TAX'].indexOf(
    //     itemData.type
    //   ) === -1
    // )
    //   newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');

    const columns = constant.accountArActivities.arActivitiesTable;
    const newColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <button type="button" className="text-success no-border" onClick={() => this.doGetArOpsUnitById(item.id)}>
            {item.id}
          </button>
        ),
      },
      // {
      //   name: 'parentId',
      //   label: 'label.parentId',
      // },
      {
        name: 'select',
        label: 'label.view',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetArOpsUnitById(item.id)}
          >
            {t('label.view')}
          </button>
        ),
      },
      ...columns,
    ];

    const tableColumns = [
      ...actionColumns,
      // {
      //   name: 'parentId',
      //   label: 'common:label.requestId',
      //   render: (colName, item) => (
      //     <button className="text-success no-border" type="button" onClick={() => this.onViewItemDetails({ item })}>
      //       {item.id || ''}
      //     </button>
      //   ),
      // },
      {
        name: 'parentId',
        label: 'common:label.parentId',
        sortable: true,
      },
      {
        label: 'common:label.userId',
        sortable: true,
        name: 'userId',
      },
      {
        label: 'common:label.itemId',
        sortable: true,
        name: 'itemId',
      },
      {
        label: 'common:label.invoiceId',
        name: 'invoiceId',
      },
      {
        label: 'common:label.date',
        name: 'date',
      },
      {
        label: 'common:label.source',
        name: 'source',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:source')().find(val => val.value === item.source) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        label: 'common:label.reason',
        sortable: true,
        name: 'customReason',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:reasonConfig')().find(val => val.value === item.customReason) : '';
          return <span>{slt ? slt.label : item.customReason || ''}</span>;
        },
      },
      {
        label: 'common:label.arType',
        sortable: true,
        name: 'type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        label: 'common:label.action',
        name: 'action',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arAction')().find(val => val.value === item.action) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        label: 'common:label.amount',
        name: 'amount',
      },
      {
        label: 'common:label.status',
        name: 'status',
      },
      ...detailsColumns,
    ];

    const invoicesColumns = [
      {
        name: 'name',
        label: 'common:label.name',
      },
      {
        name: 'transactionId',
        label: 'common:label.transactionId',
      },
      {
        name: 'amount',
        label:
          detailsData.type === 'INVOICE_LINE_ADJUSTMENT' ? 'common:label.invoiceLineAmount' : 'common:label.lineAmount',
      },
      {
        name: 'percent',
        label: 'common:label.percentAdjustment',
      },
    ];

    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className={isComponent ? '' : 'card card-statistics'}>
          {!isComponent && (
            <>
              <TitleFrom title={t('label.arRequestLog')} />
              <AccountHeaderFrom
                accountNum={id}
                commercialName={customerInfo?.commercialName || ''}
                isCommercialName={customerInfo?.customerSegment !== 'B2C'}
                currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
              />
            </>
          )}

          <div>
            <div className="card-body">
              {invoiceSelect ? (
                <ArOpsActivitySearchFormNoInvoiceId onSubmit={this.onHandleMainSubmit} />
              ) : (
                <ArOpsActivitySearchForm onSubmit={this.onHandleMainSubmit} />
              )}
              <br />
              <div>
                <DataTable
                  columns={tableColumns}
                  data={arOpsData || []}
                  isLoading={isSearchingArOps}
                  sorted={sortedArOps}
                  onSort={this.onSortMainColumn}
                />
              </div>
            </div>
          </div>
          <ModalWithItem
            isOpen={!!itemDetailsSelect}
            onToggle={this.onToggleModalDetails}
            modalTitle={t('label.arActivityDetails')}
            wrapperClass="modal-custom modal-70"
          >
            <div className="card-body">
              <ArActivitySearchForm onSubmit={this.onHandleSubmit} />
              <br />
              <div>
                <DataTable
                  columns={newColumns}
                  data={arActivity}
                  isLoading={isSearching}
                  sorted={sorted}
                  onSort={this.onSortColumn}
                />
              </div>
            </div>
            <div className="col-sm-12 mt-3 page-size-modal">
              <TablePagination
                pageNumber={page}
                pageSize={size}
                totalCount={totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
            <div className="col-md-12 mt-3 pr-0">
              <button
                type="button"
                onClick={this.onToggleModalDetails}
                className="button button-border black x-small mr-2 float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </ModalWithItem>
        </div>
        <div className={isComponent ? 'col-sm-12 mt-3 page-size-modal' : 'col-sm-12 mt-3'}>
          <TablePagination
            pageNumber={pageArOps}
            pageSize={sizeArOps}
            totalCount={totalCountArOps}
            onPageChange={this.onPageMainChange}
            onSizeChange={this.onSizeMainChange}
          />
        </div>
        <br />
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={constant.dataFieldArOpsUnitById}
          title={t('label.aRActivity')}
          isSearch={isSearchDataModal}
          wrapperClass="modal-custom modal-70"
        >
          <div className="pt-4">
            {newCollapsibleMenu.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={this.onToggleTab}
              >
                {this.renderItem(item)}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
        <ModalWithItem
          wrapperClass="modal-70 modal-custom"
          modalTitle={t('label.arActivityRequestDetails')}
          isOpen={isOpenModalItem}
          onToggle={this.onToggleModalRequest}
        >
          <AccountHeaderFrom accountNum={detailsData?.accountId || ''} />
          <div className="col-md-12 mb-30 mt-3">
            <ArOperationsDetails
              listFields={arOpsFields}
              data={detailsData}
              isDisabledAllField
              onChange={() => {}}
              onCancel={this.onToggleModalRequest}
              invoiceLinesColums={invoicesColumns}
            />
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  arActivity: makeGetArActivity() || [],
  params: makeArActivityParams() || {},
  errorMessage: makeErrorMessageTU() || '',
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

ArActivity.propTypes = {
  arActivity: PropTypes.arrayOf(PropTypes.any),
  getArActivity: PropTypes.func.isRequired,
  searchAllArOpsRequests: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ArActivity.defaultProps = {
  id: '',
  arActivity: [],
};

const newArActivity = connect(mapStateToProps, {
  getArActivity,
  setArActivityParams,
  getArOpsUnitById,
  searchAllArOpsRequests,
  getArOpsRequestById,
})(ArActivity);

export default withTranslation(['common', 'selections'])(newArActivity);
