import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class AccountSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.email',
      name: 'email',
    },
    {
      label: 'label.firstName',
      name: 'firstName',
    },
    {
      label: 'label.lastName',
      name: 'lastName',
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'common:label.customerId',
      name: 'customerId',
    },
    {
      label: 'label.clientId',
      name: 'clientId',
    },
    {
      label: 'label.parentId',
      name: 'parentId',
    },
    {
      label: 'common:label.partyId',
      name: 'partyId',
    },
    {
      label: 'common:label.groupId',
      name: 'groupId',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'label.taxId',
      name: 'taxId',
    },
    {
      label: 'label.foreignTaxId',
      name: 'foreignTaxId',
    },
    {
      label: 'label.currency',
      name: 'currency',
    },
    {
      label: 'label.country',
      name: 'country',
    },
    {
      label: 'label.state',
      name: 'state',
    },
    {
      label: 'label.city',
      name: 'city',
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:status',
      type: 'select',
      wrapperClass: 'drd-modal col-md-3',
      isClearable: true,
    },
    {
      label: 'common:label.effectiveStartDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.effectiveEndDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.subscriptionStatus',
      name: 'subscriptionStatus',
      tOptions: 'selections:subscriptionStatus',
      type: 'select',
      wrapperClass: 'col-md-3',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'type',
      tOptions: 'selections:accountType',
      type: 'select',
      wrapperClass: 'drd-modal col-md-3',
      isClearable: true,
    },
    {
      label: 'label.accountCategory',
      name: 'accountCategory',
      tOptions: 'selections:accountCategory',
      type: 'select',
    },
    {
      label: 'label.fetchParent',
      name: 'fetchParentOnly',
      tOptions: 'selections:selectBool',
      type: 'select',
    },
    {
      label: 'label.childAccount',
      name: 'childAccount',
      tOptions: 'selections:selectBool',
      type: 'select',
    },
    {
      label: 'label.sellingCompany',
      name: 'sellingCompany',
    },
    {
      label: 'common:label.groupCompany',
      name: 'groupCompany',
    },
    {
      label: 'label.collectionAgentAssigned',
      name: 'collectionAgentAssigned',
      tOptions: 'selections:selectBool',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

AccountSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountSearchForm);
