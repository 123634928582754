import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map, groupBy, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GenericInput, FormCollapse } from '../common';

export default function AttributesForm({ customAttributesByObjectType, onChange }) {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState({});

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };
  return (
    <div className="col-md-12">
      {customAttributesByObjectType &&
        customAttributesByObjectType.length > 0 &&
        map(
          groupBy(
            sortBy(customAttributesByObjectType, val => val.category),
            item => {
              return item.category;
            }
          ),
          (listAttribute, key) => {
            return (
              <FormCollapse
                key={key}
                title={key}
                isActive={key === activeTab.name && activeTab.isActive}
                onToggleTab={onToggleTab}
                state={key}
                isSubCollapse
                isActiveSubForm={key === activeTab.name && activeTab.isActive}
              >
                <div className="group-collapsible col-md-12 row mt-3">
                  {map(listAttribute || [], item => {
                    if (item.type === 'BOOLEAN') {
                      return (
                        <GenericInput
                          key={item.index}
                          required={item.mandatory === 'Y'}
                          value={item.value === 'true' || item.value === true ? 'TRUE' : 'FALSE'}
                          label={item.displayName || ' '}
                          name={item.name}
                          placeholder={
                            item.mandatory === 'Y'
                              ? `${t('label.field')} ${item.name} ${t('label.mustBe')} ${t(
                                  `label.${item.type}`
                                )}  and can not be blank`
                              : `${t('label.field')} ${item.name} ${t('label.mustBe')} ${t(`label.${item.type}`)}  `
                          }
                          wrapperClass="form-group col-md-4"
                          type="select"
                          tOptions="selections:selectBool"
                          subLabel="Boolean(true/false)"
                          onChange={({ value }) => onChange(value, item.index)}
                        />
                      );
                    }
                    return (
                      <GenericInput
                        key={item.index}
                        value={item.value}
                        label={item.displayName || ' '}
                        required={item.mandatory === 'Y'}
                        type={item.type === 'ENUM' ? 'select' : item.type === 'VARCHAR' ? 'text' : 'number'}
                        options={
                          item?.attributesLOVs?.length
                            ? item?.attributesLOVs?.map(val => ({
                                label: `${val.attributeId} : ${val.attributeValue}`,
                                value: val.attributeId,
                              }))
                            : []
                        }
                        subLabel={`${
                          item.type ? item.type.charAt(0).toUpperCase() + item.type.toLowerCase().slice(1) : ''
                        }${item.type === 'VARCHAR' ? '(255)' : ''}`}
                        onChange={({ value }) => onChange(value, item.index)}
                        name={item.name}
                        wrapperClass="form-group col-md-4"
                      />
                    );
                  })}
                </div>
              </FormCollapse>
            );
          }
        )}
    </div>
  );
}

AttributesForm.propTypes = {
  customAttributesByObjectType: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

AttributesForm.defaultProps = {
  customAttributesByObjectType: {},
  onChange: () => {},
};
