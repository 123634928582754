import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default payload => {
  let newPayload = '{dummy: ""}';
  if (payload) newPayload = parseToMutationRequest(payload, ['merchantName', 'gatewayName']);

  const getTenantConfig = `getTenantConfig(input: ${newPayload})`;
  return gql`
    query {
      ${getTenantConfig} {
        id
        tenantMerchantAccounts {
          index
          type
          name
          startDate
          validity
          authType
          status
          webApiType
          country
          httpBasicAttributes {
            clientId
            clientProfileId
            username
            password
          }
          jwtAttributes {
            authorizationKey
            merchantId
            publicKey
            privateKey
          }
          oauthAttributes {
            clientId
            clientSecret
            companyId
            refreshToken
            accessToken
          }
        }
      }
    }
  `;
};
