import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CollectionSchedule from '../CollectionAdmin/CollectionConfig/CollectionScheduleNew';
import CollectionActions from '../CollectionAdmin/CollectionConfig/CollectionActions';
import CollectionAgent from '../CollectionAdmin/CollectionConfig/CollectionAgent';
import PageTitle from '../../../components/PageTitle';
import { FormWithTableItem } from '../../../components/common';
import Footer from '../../../components/Footer';
import RouteName from '../../App/RouteNames';

class CollectionConfig extends React.PureComponent {
  render() {
    const { t, match } = this.props;
    let title = 'label.collectionSchedule';
    if (match.path === RouteName.collectionActionsConfig.path) {
      title = 'label.collectionActions';
    }
    if (match.path === RouteName.collectionAgents.path) {
      title = 'label.collectionAgents';
    }

    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteName.collectionsConfig.path}
          titleBtn={t('label.back')}
          titleRight={t('collectionPage:sidebar.collectionConfig')}
        />
        <br />
        <div className="col-md-12 pt-2 pb-4 mb-4 pr-4">
          <FormWithTableItem title={title} wrapperClass="collection-form-item">
            <div className="col-md-12 pl-3">
              {match.path === RouteName.collectionSchedule.path && <CollectionSchedule />}
              {match.path === RouteName.collectionActionsConfig.path && <CollectionActions />}
              {match.path === RouteName.collectionAgents.path && <CollectionAgent />}
            </div>
          </FormWithTableItem>
        </div>
        <Footer className="footer-bottom" />
      </div>
    );
  }
}

CollectionConfig.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

CollectionConfig.defaultProps = {
  match: {},
};

export default withTranslation('common')(CollectionConfig);
