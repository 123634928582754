import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 3,
    columns: [],
    data: [[{ value: title || 'ACCOUNT STATEMENT', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.common, // journalId
      columns.common, // batchId
      columns.common, // revenueStartDate
      columns.common, // revenueEndDate
      columns.common, // amount
      columns.common, // amountRecognized
      columns.common, // glAccount
      columns.common, // calendarPeriod
      columns.common, // revenueRecognitionType
      columns.common, // objectId
      columns.common, // objectType
    ],
    data: [
      [
        {
          value: t('label.id'),
          style: styleHeader,
        },
        {
          value: t('label.batchId'),
          style: styleHeader,
        },
        {
          value: t('label.startDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.amount'),
          style: styleHeader,
        },
        {
          value: t('label.amountRecognized'),
          style: styleHeader,
        },
        {
          value: t('label.glAccount'),
          style: styleHeader,
        },
        {
          value: t('label.calendarPeriod'),
          style: styleHeader,
        },
        {
          value: t('label.revenueRecognitionType'),
          style: styleHeader,
        },
        {
          value: t('label.objectId'),
          style: styleHeader,
        },
        {
          value: t('label.objectType'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.journalId ? item.journalId : '' });
      newItem.push({ value: item.batchId ? item.batchId : '' });
      newItem.push({ value: item.revenueStartDate ? item.revenueStartDate : '' });
      newItem.push({ value: item.revenueEndDate ? item.revenueEndDate : '' });
      newItem.push({ value: formatMoneyValueToExport(item.amount) });
      newItem.push({ value: formatMoneyValueToExport(item.amountRecognized) });
      newItem.push({ value: item.glAccount ? item.glAccount : '' });
      newItem.push({ value: item.calendarPeriod ? item.calendarPeriod : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'revenueRecognitionType', fieldOption: 'revenueRecognitionType' }),
      });
      newItem.push({ value: item.objectId ? item.objectId : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'objectType', fieldOption: 'revenueObjectType' }),
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
