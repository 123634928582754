import React, { useEffect, useState } from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import XMLViewer from 'react-xml-viewer';
import { getBase64, splitF } from '../../../utils/utils';
import { getS3Buctket, readFileFromS3 } from '../../../containers/App/actions';

let fileSelect = null;
let pthSelect = null;

const ModalViewFile = ({
  getS3Buctket,
  readFileFromS3,
  paymentFileData,
  pathFile,
  htmlFile,
  isOpen,
  onToggle,
  title,
}) => {
  let defaultPath = null;

  if (paymentFileData && paymentFileData.length) {
    defaultPath = paymentFileData.find(val => val.fileType === 'PDF');
    if (!defaultPath) {
      defaultPath = paymentFileData.find(val => val.fileType === 'HTML');
    }
  }

  const { t } = useTranslation('common');
  const [base64FilePdf, setBase64FileHtml] = useState(null);
  const [isPdf, setIsPdf] = useState(true);
  const [isXML, setIsXML] = useState(false);
  const [xmlData, setXmlData] = useState(false);
  const [pathFileSelect, setPathFileSelect] = useState(defaultPath && defaultPath.path ? defaultPath.path : null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doGetFileFromS3 = () => {
    if (isOpen) {
      if (!paymentFileData && !paymentFileData.length) {
        getS3Buctket('', config => {
          readFileFromS3({ ...config.data, url: isPdf ? pathFile : htmlFile }, ({ success, data }) => {
            if (success) {
              pthSelect = htmlFile;
              fileSelect = data;
              getBase64(data, result => {
                setBase64FileHtml(result);
              });
            }
          });
        });
      } else {
        getS3Buctket('', config => {
          readFileFromS3(
            { ...config.data, url: pathFileSelect || (defaultPath && defaultPath.path) || null },
            ({ success, data }) => {
              if (success) {
                pthSelect = pathFileSelect || defaultPath?.path || '';
                fileSelect = data;
                if (isXML) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    setXmlData(reader.result);
                  };
                  reader.readAsText(fileSelect);
                }
                getBase64(data, result => {
                  setBase64FileHtml(result);
                });
              }
            }
          );
        });
      }
    }
  };

  const downloadFile = (theBlob, fileName) => {
    saveAs(theBlob, fileName);
  };

  useEffect(() => {
    doGetFileFromS3();
  }, [doGetFileFromS3, getS3Buctket, pathFile, readFileFromS3, isPdf, pathFileSelect, paymentFileData]);

  return (
    <Modal isOpen={isOpen} toggle={onToggle} className="modal-lg info-form">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="col-md-12 d-flex">
          <div>
            <button type="button" className="button x-small ml-4" onClick={onToggle}>
              {t('label.back')}
            </button>
          </div>
          {paymentFileData && paymentFileData.length && (
            <div className="group-btn-invoice text-right mr-3" style={{ width: '96%' }}>
              {paymentFileData.map(val => (
                <button
                  type="button"
                  className="button x-small"
                  onClick={() => {
                    setPathFileSelect(val.path || null);
                    if (val.fileType === 'XML') {
                      setIsXML(true);
                    } else {
                      setIsXML(false);
                    }
                  }}
                >
                  {val.fileType}
                </button>
              ))}
              <button
                type="button"
                className="button x-small"
                onClick={() => downloadFile(fileSelect, splitF(pthSelect))}
              >
                {t('label.download')}
              </button>
            </div>
          )}
          {!paymentFileData && (
            <div className="group-btn-invoice text-right mr-3" style={{ width: '96%' }}>
              <button type="button" className="button x-small" onClick={() => setIsPdf(false)}>
                HTML
              </button>
              <button type="button" className="button x-small" onClick={() => setIsPdf(true)}>
                PDF
              </button>
            </div>
          )}
        </div>
        {!isXML && (
          <iframe
            type="application/pdf"
            src={base64FilePdf}
            title="report_view"
            style={{ width: '95%', minHeight: '740px', marginLeft: '20px' }}
          />
        )}
        {isXML && (
          <div
            className="xml-viewer"
            style={{ width: '95%', minHeight: '740px', marginLeft: '20px', overflow: 'auto' }}
          >
            <XMLViewer xml={xmlData} />
          </div>
        )}
      </ModalBody>
      {/* <ModalFooter>
        <Button color="primary" onClick={onToggle}>
          {t('label.ok')}
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

ModalViewFile.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
};

ModalViewFile.defaultProps = {
  title: '',
};

export default connect(null, { getS3Buctket, readFileFromS3 })(ModalViewFile);
