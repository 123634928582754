import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

class MonthYearGroup extends React.PureComponent {
  state = { isOpenDatePicker: false };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.value) return null;
    return {
      value: props.value ? moment(props.value, props.dateFormat || 'MM-YYYY').toDate() : null,
      oldValue: props.value,
    };
  }

  onHandleOpenDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (!isOpenDatePicker) this.setState({ isOpenDatePicker: true });
  };

  onHandleCloseDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (isOpenDatePicker) this.setState({ isOpenDatePicker: false });
  };

  handleChange = value => {
    this.setState({ value, isOpenDatePicker: false });
    const { onChange, dateFormat, isUpperCase } = this.props;
    if (onChange) {
      if (value) {
        if (isUpperCase) {
          return onChange(
            moment(value)
              .format(dateFormat || 'MM-YYYY')
              .toUpperCase()
          );
        }
        return onChange(moment(value).format(dateFormat || 'MM-YYYY'));
      }
      return onChange(null);
    }
  };

  render() {
    const { isOpenDatePicker } = this.state;
    const { label, wrapperClass, disabled, t, dateFormat, ...restProps } = this.props;
    return (
      <div className={`form-group ${wrapperClass}`}>
        <span>
          {t(label)}
          {label && restProps.required && <i className="fa fa-star is-required" />}
        </span>
        <DatePicker
          className="form-control"
          open={isOpenDatePicker}
          id="from-date"
          onFocus={this.onHandleOpenDatePicker}
          onBlur={this.onHandleCloseDatePicker}
          onClickOutside={this.onHandleCloseDatePicker}
          selected={this.state.value}
          disabled={disabled}
          isClearable
          autoComplete="off"
          dateFormat={dateFormat || 'MM-YYYY'}
          showMonthYearPicker
          popperModifiers={{
            flip: {
              behavior: ['bottom'],
            },
          }}
          {...restProps}
          onChange={this.handleChange}
        />
        <button
          type="button"
          className="icon-calendar no-border"
          onClick={this.onHandleOpenDatePicker}
          disabled={disabled}
          // onBlur={this.onHandleCloseDatePicker}
        >
          <i className="fa fa-calendar" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

MonthYearGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
};

MonthYearGroup.defaultProps = {
  label: '',
  placeholder: 'Select Date',
  wrapperClass: 'col-md-3',
};

export default withTranslation('common')(MonthYearGroup);
