import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = data.map(val =>
    parseToMutationRequest(val, [
      'status',
      'index',
      'frequency',
      'addNextYearPeriods',
      'adjustingPeriodCount',
      'unit',
      'status',
    ])
  );
  const modifyCalendarConfig = `modifyCalendarConfig(input: [${dataModify}])`;
  return gql`
  mutation {
    ${modifyCalendarConfig} {
      id
    }
  }
`;
};
