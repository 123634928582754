import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput, TitleFrom } from '../../../components/common';
import { makeTenantDataConfig } from '../selectors';
import { getTenantDataConfig } from '../actions';
import dataField from '../constant';

class TenantForm extends React.PureComponent {
  state = { tenantData: {}, dataSubmit: {}, oldData: {} };

  static getDerivedStateFromProps(props, state) {
    if (props.data === state.oldData) return null;
    return {
      tenantData: props.data,
      dataSubmit: { id: props.data && props.data.id ? props.data.id : '' },
      oldData: props.data,
    };
  }

  componentDidMount() {
    const { data, getTenantDataConfig } = this.props;
    getTenantDataConfig();
    this.setState({ tenantData: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ tenantData: nextProps.data, dataSubmit: { id: nextProps.data.id ? nextProps.data.id : '' } });
    }
  }

  onChange = ({ name, value }) => {
    const { tenantData, dataSubmit } = this.state;
    tenantData[name] = value;
    dataSubmit[name] = value;
    this.setState({ ...tenantData, dataSubmit });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { modifyTenant } = this.props;
    const { dataSubmit } = this.state;
    modifyTenant({ ...dataSubmit });
  };

  render() {
    const { tenantData } = this.state;
    const { modeSetLincenseData, modeModifyLicenseData, isRequireField, t, getTenantConfig } = this.props;
    let tenantField = cloneDeep(dataField.tenant);
    if (modeSetLincenseData === 0 || modeModifyLicenseData === 0) {
      tenantField = tenantField.filter(item => item.name !== 'licenseKey');
    }
    return (
      <div className="card card-statistics">
        <TitleFrom title={t('tenantPage:tenantManagementPage.tenantData')} />
        <div className="card-body">
          <form className="card-body" onSubmit={this.onHandleSubmit}>
            <div className="group-flex">
              {map(tenantField, item => (
                <GenericInput
                  key={item.name}
                  value={tenantData && tenantData[item.name] ? tenantData[item.name] : ''}
                  label={item.label || ''}
                  onChange={this.onChange}
                  disabled={modeModifyLicenseData === 1 && item.name === 'licenseKey'}
                  name={item.name}
                  required={isRequireField}
                  wrapperClass="col-md-4"
                />
              ))}
            </div>
            <div className="mt-2 mb-2">
              <button type="submit" className="button button-border ml-2 x-small float-right">
                {t('label.saveConfig')}
              </button>
              <button
                type="button"
                onClick={() => getTenantConfig()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

TenantForm.propTypes = {
  modifyTenant: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  modeSetLincenseData: PropTypes.number,
  modeModifyLicenseData: PropTypes.number,
  isRequireField: PropTypes.bool,
};

TenantForm.defaultProps = {
  modifyTenant: () => {},
  data: {},
  modeSetLincenseData: 2,
  modeModifyLicenseData: 2,
  isRequireField: false,
};

const mapStateToProps = createStructuredSelector({
  data: makeTenantDataConfig() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getTenantDataConfig })(TenantForm));
