import gql from 'graphql-tag';

export default () => {
  const getConfigCorrespondence = 'getConfigCorrespondence(dummy: "")';
  return gql`
    query {
      ${getConfigCorrespondence} {
        id
        name
        templates {
          index
          description
          filePath
          type
          status
          templateType
          messageType
          smsTemplate
          emailSubject
        }
      }
    }
  `;
};
