import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class FocusInputTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modify: false,
      value: props.value,
    };
    this.inputRef = React.createRef();
  }

  componentWillReceiveProps(props) {
    if (props.value !== this.state.value) {
      this.setState({
        value: props.value,
      });
    }
  }

  onChange = evt => {
    this.setState({ value: evt.target.value });
    const { onUpdateValue, name, index } = this.props;
    onUpdateValue(index, name, evt.target.value);
  };

  onDoubleClickTd = () => {
    this.setState({ modify: true });
    const { onClick } = this.props;
    setTimeout(() => {
      this.inputRef.current.focus();
      if (onClick) onClick();
    }, 250);
  };

  renderContent = () => {
    const { modify, value } = this.state;
    const { fa } = this.props;
    return (
      <div onDoubleClick={this.onDoubleClickTd}>
        <span
          className={classnames('', {
            'field-none': modify,
          })}
        >
          {value}
        </span>
        <span
          className={classnames('', {
            'field-none': !modify,
          })}
        >
          <div className="wr-input">
            <input
              type={this.props.type}
              value={value || null}
              onChange={this.onChange}
              className="form-control input-group"
              onBlur={() => this.setState({ modify: false })}
              ref={this.inputRef}
            />
            {fa && (
              <div className="wr-input-icon">
                <i className={`fa ${fa}`} />
              </div>
            )}
          </div>
        </span>
      </div>
    );
  };

  render() {
    const { isOnTable } = this.props;
    if (isOnTable) return this.renderContent();
    return <td onDoubleClick={this.onDoubleClickTd}>{this.renderContent()}</td>;
  }
}

FocusInputTable.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  fa: PropTypes.string,
};

FocusInputTable.defaultProps = {
  type: 'text',
  onClick: () => {},
  fa: '',
};

export default FocusInputTable;
