import { fromJS } from 'immutable';
import { cloneDeep, isEmpty } from 'lodash';
import { loadState } from '../../localStorage';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGIN_FAILURE,
  GET_PERMISSIONS_BY_ID_SUCCESS,
  REMOVE_PERMISSIONS,
  GET_S3_BUCKET_SUCESS,
  GET_CURRENCY_CONFIG_SUCCESS,
  ON_RELOAD_AVATAR,
  GET_CCP_PROPERTIES_CONFIG_SUCESS,
  GET_CCP_DATE_TIME_CONFIG_SUCCESS,
  GET_TENANT_DEFAULT_CONFIG_SUCCESS,
} from './constants';

const persistedState = loadState() || {};
// The initial state of the App
export const initialState = fromJS({
  isFetching: false,
  token: persistedState.token,
  expiryTime: null,
  user: null,
  error: null,
  permissionsApp: {
    customerHubModules: [],
    pricingHubModules: [],
    billingHubModules: [],
    arHubModules: [],
    opsHubModules: [],
    revenueHubModules: [],
    correspondenceModulePermissions: [],
    mediationHubModules: [],
  },
  s3Config: {},
  currencyConfig: {},
  avatarProfile: null,
  ccpPropertyList: [],
  ccpTime: null,
  tenantDefaultData: null,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return state.set('isFetching', true).set('error', false);
    case LOGIN_SUCCESS: {
      const {
        payload: { meta, user },
      } = action;
      return state
        .set('isFetching', false)
        .set('token', meta.jwt)
        .set('expiryTime', meta.expiry_time)
        .set('user', user)
        .set('error', false);
    }

    case LOGIN_FAILURE:
      return state
        .set('isFetching', false)
        .set('token', null)
        .set('error', 'Username or Password is invalid');

    case LOGOUT_REQUEST:
      return state
        .set('isFetching', false)
        .set('token', null)
        .set('error', false);

    case LOGOUT_FAILURE:
      return state.set('isFetching', false);

    case REMOVE_PERMISSIONS: {
      const newPermissionsApp = {
        customerHubModules: [],
        pricingHubModules: [],
        arHubModules: [],
        opsHubModules: [],
        revenueHubModules: [],
      };
      return state.set('permissionsApp', newPermissionsApp);
    }

    case GET_PERMISSIONS_BY_ID_SUCCESS: {
      const newState = cloneDeep(state).toJS();
      const newPermissionsApp = newState.permissionsApp || {};
      if (isEmpty(newPermissionsApp.arHubModules) && !isEmpty(action.payload.arHubModules)) {
        newPermissionsApp.arHubModules = action.payload.arHubModules;
      }
      if (isEmpty(newPermissionsApp.pricingHubModules) && !isEmpty(action.payload.pricingHubModules)) {
        newPermissionsApp.pricingHubModules = action.payload.pricingHubModules;
      }
      if (isEmpty(newPermissionsApp.customerHubModules) && !isEmpty(action.payload.customerHubModules)) {
        newPermissionsApp.customerHubModules = action.payload.customerHubModules;
      }
      if (isEmpty(newPermissionsApp.opsHubModules) && !isEmpty(action.payload.opsHubModules)) {
        newPermissionsApp.opsHubModules = action.payload.opsHubModules;
      }
      if (isEmpty(newPermissionsApp.billingHubModules) && !isEmpty(action.payload.billingHubModules)) {
        newPermissionsApp.billingHubModules = action.payload.billingHubModules;
      }
      if (isEmpty(newPermissionsApp.revenueHubModules) && !isEmpty(action.payload.revenueHubModules)) {
        newPermissionsApp.revenueHubModules = action.payload.revenueHubModules;
      }
      if (isEmpty(newPermissionsApp.mediationHubModules) && !isEmpty(action.payload.mediationHubModules)) {
        newPermissionsApp.mediationHubModules = action.payload.mediationHubModules;
      }
      return state.set('permissionsApp', newPermissionsApp);
    }

    case GET_S3_BUCKET_SUCESS:
      return state.set('s3Config', action.payload);

    case GET_CURRENCY_CONFIG_SUCCESS:
      return state.set('currencyConfig', action.payload);

    case ON_RELOAD_AVATAR:
      return state.set('avatarProfile', action.payload);

    case GET_CCP_PROPERTIES_CONFIG_SUCESS:
      return state.set('ccpPropertyList', action.payload);

    case GET_CCP_DATE_TIME_CONFIG_SUCCESS:
      return state.set('ccpTime', action.payload);

    // case GET_TENANT_DEFAULT_CONFIG_SUCCESS:
    //   return state.set('tenantDefaultData', action.payload);

    default:
      return state;
  }
}

export default appReducer;
