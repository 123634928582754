import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, []);
  return gql`
  query {
    getAccountItems(input: ${dataSearch}) {
      accountItemsData {
        invoiceUnitId
        itemId
        name
        description
        productFamily
        productLine
        productType
        productSubType
        type
        revenueType
        revenueRecognitionType
        status
        totalAmount
      }
    }
  }
`;
};
