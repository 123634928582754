import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t }) => [
  {
    xSteps: 5,
    columns: [],
    data: [[{ value: 'EMPLOYEES', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.id,
      columns.common, // employee
      columns.common, // jobTitle
      columns.common, // clientId
      columns.common, // clientName
      columns.common, // clientJobTitle
      columns.common, // firstPayrollDate
      columns.common, // firstPayrollAmount
      columns.common, // secondPayrollDate
      columns.common, // secondSalaryAmount
      columns.common, // numDaysWorked
      columns.common, // startDate
      columns.common, // endDate
      columns.common, // createdDate
      columns.status, // status
    ],
    data: [
      [
        {
          value: t('label.id'),
          style: styleHeader,
        },
        {
          value: t('label.employee'),
          style: styleHeader,
        },
        {
          value: t('label.jobTitle'),
          style: styleHeader,
        },
        {
          value: t('label.clientId'),
          style: styleHeader,
        },
        {
          value: t('label.clientName'),
          style: styleHeader,
        },
        {
          value: t('label.clientJobTitle'),
          style: styleHeader,
        },
        {
          value: t('label.firstPayrollDate'),
          style: styleHeader,
        },
        {
          value: t('label.firstPayrollAmount'),
          style: styleHeader,
        },
        {
          value: t('label.secondPayrollDate'),
          style: styleHeader,
        },
        {
          value: t('label.secondSalaryAmount'),
          style: styleHeader,
        },
        {
          value: t('label.numDaysWorked'),
          style: styleHeader,
        },
        {
          value: t('label.startDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.createdDate'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.id ? item.id : '' });
      newItem.push({ value: item.employeeName ? item.employeeName : '' });
      newItem.push({ value: item.jobTitle ? item.jobTitle : '' });
      newItem.push({ value: item.clientId ? item.clientId : '' });
      newItem.push({ value: item.clientName ? item.clientName : '' });
      newItem.push({ value: item.clientJobTitle ? item.clientJobTitle : '' });
      newItem.push({ value: item.firstPayrollDate ? item.firstPayrollDate : '' });
      newItem.push({ value: item.firstSalaryAmount ? item.firstSalaryAmount : '' });
      newItem.push({ value: item.secondPayrollDate ? item.secondPayrollDate : '' });
      newItem.push({ value: item.secondSalaryAmount ? item.secondSalaryAmount : '' });
      newItem.push({ value: item.noOfDaysWorked ? item.noOfDaysWorked : '' });
      newItem.push({ value: item.startDate ? item.startDate : '' });
      newItem.push({ value: item.endDate ? item.endDate : '' });
      newItem.push({ value: item.createdDate ? item.createdDate : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'timeAndBillingObjectStatus' }),
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
