import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.name',
      name: 'name',
    },
  ];

  btnSpan = 'col-md-9';
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SearchForm);
