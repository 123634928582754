import React, { Component } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import RouteName from '../App/RouteNames';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import customerReducer from '../CustomerPage/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import customerSaga from '../CustomerPage/saga';
import AROpsConfig from './AROpsConfig';
import Adjustments from './Adjustments';
import AdjustmentDetail from './Adjustments/AdjustmentDetail';
import AdjustmentApply from './Adjustments/AdjustmentApply';
import DisputeDetail from './Disputes/DisputesDetail';
import Disputes from './Disputes';
import DisputesApply from './Disputes/DisputesApply';
import SettleADispute from './Disputes/SettleADispute';
import WriteOffs from './WriteOff';
import WriteOffDetails from './WriteOff/WriteOffDetails';
import WriteOffsApply from './WriteOff/WirteOffApply';
import ReverseWriteOff from './WriteOff/ReverseWriteOff';
import ItemMap from './ItemMap';
import ReasonCode from './ReasonCode';
import GroupsReason from './GroupsReason';
import GroupsReasonCreate from './GroupsReason/GroupsReasonCreate';
import GroupsReasonModify from './GroupsReason/GroupsReasonModify';
import ARThresholds from './ARThresholds';
import BulkAdjustments from './BulkAdjustments';
import Sidebar from './Sidebar';
import { makeGetPermissionsReports, makeGetPermissionsArOpsManagement } from '../App/selectors';
import { CreditNotes } from '../ReportManagement/AROpsReports';
import { checkPermissionForAllPages } from '../ReportManagement/CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class ArOperations extends Component {
  componentDidMount() {}

  render() {
    const { permissionsReports, permissionsArOps } = this.props;
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsArOps, listKeys: permissionList.AROperations })) return null;

    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <Switch>
            <Route exact path={RouteName.arOperationsConfig.path} component={AROpsConfig} />
            <Route exact path={RouteName.arOperationsAdjustments.path} component={Adjustments} />
            <Route exact path={RouteName.arOperationsAdjustmentDetail.path} component={AdjustmentDetail} />
            <Route exact path={RouteName.arOperationsAdjustmentApply.path} component={AdjustmentApply} />
            <Route exact path={RouteName.arOperationsDisputes.path} component={Disputes} />
            <Route exact path={RouteName.arOperationsDisputesDetail.path} component={DisputeDetail} />
            <Route exact path={RouteName.arOperationsDisputesApply.path} component={DisputesApply} />
            <Route exact path={RouteName.arOperationsSettleDisputes.path} component={SettleADispute} />
            <Route exact path={RouteName.arOperationsWriteOffs.path} component={WriteOffs} />
            <Route exact path={RouteName.arOperationsWriteOffsDetails.path} component={WriteOffDetails} />
            <Route exact path={RouteName.arOperationsWriteOffsApply.path} component={WriteOffsApply} />
            <Route exact path={RouteName.arOperationsReverseWriteOffs.path} component={ReverseWriteOff} />
            {/* Config */}
            {/* <Route exact path={RouteName.arOperationsConfigGL.path} component={AROpsConfig} /> */}
            <Route exact path={RouteName.arOperationsConfigItemMap.path} component={ItemMap} />
            <Route exact path={RouteName.arOperationsConfigReasonCode.path} component={ReasonCode} />
            <Route exact path={RouteName.arOperationsConfigGetReasonGroup.path} component={GroupsReason} />
            <Route exact path={RouteName.arOperationsConfigGetReasonGroupAdd.path} component={GroupsReasonCreate} />
            <Route exact path={RouteName.arOperationsConfigGetReasonGroupModify.path} component={GroupsReasonModify} />
            <Route exact path={RouteName.arOperationsARThresholds.path} component={ARThresholds} />
            <Route
              exact
              path={RouteName.arCreditNotesReport.path}
              component={props => <CreditNotes {...props} permissions={{ ...permissions }} isArOperations />}
            />
            <Route exact path={RouteName.arOperationsBulkAdjustments.path} component={BulkAdjustments} />
          </Switch>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'arOperationsReducer', reducer });
const withSaga = injectSaga({ key: 'arOperationsSaga', saga });

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

const withReducerCustomer = injectReducer({ key: 'customerReducer', reducer: customerReducer });
const withSagaCustomer = injectSaga({ key: 'customerSaga', saga: customerSaga });

const mapStateToProps = createStructuredSelector({
  permissionsReports: makeGetPermissionsReports() || {},
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withReducer,
  withSaga,
  withReducerReport,
  withSagaReport,
  withConnect,
  withReducerCustomer,
  withSagaCustomer
)(ArOperations);
