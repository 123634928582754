import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['status']);
  const modifyNotesUnit = `modifyNotesUnit(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyNotesUnit} {
      id
    }
  }
`;
};
