// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'minThresholdToSkipNotes',
    'arOpsReason',
    'maxThreshold',
    'index',
    'arOpsType',
    'adjustmentARType',
    'disputeARType',
    'writeOffARType',
    'accountType',
  ]);
  const createConfigArOpsThresholds = `createConfigArOpsThresholds(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigArOpsThresholds} {
      id
    }
  }
`;
};
