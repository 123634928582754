import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { useTranslation } from 'react-i18next';

const StepProgressBar = ({ listSteps, stepSelected, onSelectStep, isSuportOldSelected, oldSelected }) => {
  const { t } = useTranslation('common');
  return (
    <ProgressBar
      percent={stepSelected * (100 / (listSteps.length - 1))}
      filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      className="stepas"
    >
      {listSteps &&
        listSteps.length &&
        listSteps.map((val, index) => (
          <Step transition="scale" key={`key-${index}`}>
            {() => {
              let isEnable = val.step - 1 <= stepSelected;
              if (isSuportOldSelected) {
                isEnable = val.step - 1 <= oldSelected;
              }
              return (
                <div>
                  <button
                    onClick={() => onSelectStep(val.step)}
                    className={`btn btn-step ${isEnable ? 'active' : 'disabled'}`}
                    // disabled={val.step - 1 > stepSelected}
                    type="button"
                  >
                    {val.step}
                  </button>
                  <div className="label-step" style={{ left: `-${t(val.label).length * 4}%` }}>
                    {t(val.label)}
                  </div>
                </div>
              );
            }}
          </Step>
        ))}
    </ProgressBar>
  );
};

export default StepProgressBar;
