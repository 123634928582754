import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['includeChildTask'])}`
    : '';
  const searchTasks = `searchTasks(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchTasks} {
        id
        accountId
        userId
        parentTaskId
        createdDate
        taskType
        taskCategory
        taskDescription
        ticketId
        status
        userFirstname
        userLastName
        organization
        userEmail
        accountType
      }
    }
  `;
};
