import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SearchAccountForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.firstName',
      name: 'firstName',
    },
    {
      label: 'common:label.lastName',
      name: 'lastName',
    },
    {
      name: 'city',
      label: 'common:label.city',
    },
    {
      label: 'common:label.state',
      name: 'state',
    },
    {
      label: 'common:label.clientId',
      name: 'clientId',
    },
    {
      label: 'common:label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:status',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'type',
      tOptions: 'accountType',
      type: 'select',
      wrapperClass: 'drd-modal col-md-3',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

SearchAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchAccountForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SearchAccountForm);
