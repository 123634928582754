import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, []);
  const modifyRegulatoryProductCodes = `modifyRegulatoryProductCodes(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyRegulatoryProductCodes} {
      id
      description
      startDate
      endDate
      includeIva
      includeIeps
      complement
    }
  }
`;
};
