import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { selectItemsId } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import ItemSearchForm from './ItemSearchForm';
import ItemSearchFormWithItemType from './ItemSearchFormWithItemType';

class ModalSearchItem extends Component {
  state = {};

  render() {
    const {
      t,
      defaultSearchParams,
      isOpen,
      selectItemsId,
      onSelect,
      onCancel,
      selectedParentId,
      defaultSearchForm,
      isWithType,
      defaultFilter,
      disabledSearchFields,
      readOnlySearchFields,
    } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'company',
          label: 'common:label.company',
          sortName: 'company',
        },

        {
          name: 'status',
          label: 'common:label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={t('label.chooseItemId')}
        isOpen={isOpen}
        SearchForm={
          isWithType
            ? props => (
              <ItemSearchFormWithItemType
                {...props}
                disabledSearchFields={disabledSearchFields || null}
                defaultFilter={defaultFilter || null}
                readOnlySearchFields={readOnlySearchFields || null}
              />
              )
            : props => (
              <ItemSearchForm
                {...props}
                disabledSearchFields={disabledSearchFields || null}
                defaultFilter={defaultFilter || null}
                readOnlySearchFields={readOnlySearchFields || null}
              />
              )
        }
        searchItems={selectItemsId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        defaultSearchForm={defaultSearchForm}
      />
    );
  }
}

ModalSearchItem.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  selectItemsId: PropTypes.func,
};

ModalSearchItem.defaultProps = {
  isOpen: false,
  onSelect: () => {},
  onCancel: () => {},
  selectedParentId: '',
  selectItemsId: () => {},
};

export default withTranslation('common')(connect(null, { selectItemsId })(ModalSearchItem));
