import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropsType from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../App/RouteNames';
import { searchCollectionProfiles, createCollectionProfiles, modifyCollectionProfiles } from '../actions';
import { makeGetPermissionsCollectionManagement } from '../../App/selectors';
import { GenericInput, FormWithTableItem, DataTable, TablePagination } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { getPageTotalCount, validate } from '../../../utils/utils';
import {
  checkPermissionSearchCollectionProfile,
  checkPermissionCreateCollectionProfile,
  checkPermissionModifyCollectionProfile,
} from '../CheckPermission';
import { CollectionProfileSearchForm } from '../../../components/CustomerHub';
import Footer from '../../../components/Footer';

const sortTypes = {
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountSubType: {
    asc: 'accountSubType_ASC',
    desc: 'accountSubType_DESC',
  },
  marketSegment: {
    asc: 'marketSegment_ASC',
    desc: 'marketSegment_DESC',
  },
  salesChannel: {
    asc: 'salesChannel_ASC',
    desc: 'salesChannel_DESC',
  },
};

const CollectionProfile = ({
  searchCollectionProfiles,
  createCollectionProfiles,
  modifyCollectionProfiles,
  permissionsCollections,
  currencyOptions,
  customerInfo,
  id,
  isComponent,
}) => {
  const { t } = useTranslation('common');
  const [isSearching, setIsSearching] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [data, setData] = useState([]);
  const [wasValidated, setValidate] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [sorted, setSorted] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [isNeedSearch, setIsNeedSearch] = useState(true);
  const formRef = useRef();

  const doGetCollectionProfile = useCallback(() => {
    setIsSearching(true);
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchCollectionProfiles(payload, ({ success, data }) => {
      setIsSearching(false);
      setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
      if (success) {
        setData(data || []);
        setIsModify(true);
      } else {
        setData([]);
      }
    });
  }, [filter, page, searchCollectionProfiles, size, sorted, totalCount]);

  useEffect(() => {
    if (isNeedSearch) {
      doGetCollectionProfile();
      setIsNeedSearch(false);
    }
  }, [doGetCollectionProfile, isNeedSearch]);

  let modeGetCollectionProfile = 0;
  let modeModifyCollectionProfile = 0;
  let modeCreateCollectionProfile = 0;
  if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
    const listPermission = permissionsCollections.collectionModulePermissions;
    modeGetCollectionProfile = checkPermissionSearchCollectionProfile({ listPermission });
    modeCreateCollectionProfile = checkPermissionCreateCollectionProfile({ listPermission });
    modeModifyCollectionProfile = checkPermissionModifyCollectionProfile({ listPermission });
  }
  if (!modeGetCollectionProfile) return '';

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleAddCollectionProfile = () => {
    let newData = cloneDeep(data);
    newData = [
      {
        id: null,
        collectionProfile: null,
        accountType: null,
        accountSubType: null,
        marketSegment: null,
        salesChannel: null,
        isNew: true,
      },
      ...newData,
    ];
    setData(newData);
  };

  const headerTable = [
    {
      name: 'id',
      label: 'label.id',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.id}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="id"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'collectionProfile',
      label: 'label.collectionProfile',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.collectionProfile}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="collectionProfile"
          required
        />
      ),
    },
    {
      name: 'accountType',
      label: 'label.accountType',
      sortable: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.accountType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="accountType"
          tOptions="selections:accountType"
          type="select"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'accountSubType',
      label: 'label.accountSubType',
      sortable: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.accountSubType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="accountSubType"
          tOptions="selections:accountSubType"
          type="select"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'marketSegment',
      label: 'label.marketSegment',
      sortable: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.marketSegment}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="marketSegment"
          tOptions="selections:marketSegment"
          type="select"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'salesChannel',
      label: 'label.salesChannel',
      sortable: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.salesChannel}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="salesChannel"
          tOptions="selections:salesChannel"
          type="select"
          menuPortalTarget
        />
      ),
    },
  ];

  const onHandleSubmitSearch = filter => {
    setFilter(filter);
    setPage(0);
    setIsNeedSearch(true);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
    setIsNeedSearch(true);
  };

  const onPageChange = page => {
    setIsNeedSearch(true);
    return setPage(page);
  };

  const onSizeChange = size => {
    setIsNeedSearch(true);
    return setSize(size);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    let newPayload = cloneDeep(data);
    if (newPayload && newPayload.length) {
      newPayload = newPayload.map(val => {
        const { isNew, ...rest } = val;
        return rest;
      });
    }
    if (!isModify)
      return createCollectionProfiles({ embrixData: newPayload }, ({ success }) => {
        if (success) doGetCollectionProfile();
      });

    return modifyCollectionProfiles({ embrixData: newPayload }, ({ success }) => {
      if (success) doGetCollectionProfile();
    });
  };

  if (isComponent) {
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div>
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <CollectionProfileSearchForm onSubmit={onHandleSubmitSearch} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormWithTableItem
          title={`${t('customerPage:sidebarSearchPage.config.subTitle.collectionProfile')}`}
          subClass="border-bottom"
          accountNum={id}
          commercialName={customerInfo?.commercialName || ''}
          isCommercialName={customerInfo?.customerSegment !== 'B2C'}
          currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        >
          <br />
          <form
            ref={formRef}
            noValidate
            className={`col-md-12 p-2 needs-validation ${wasValidated ? 'was-validated' : ''}`}
            onSubmit={modeModifyCollectionProfile === 2 ? onSubmit : () => {}}
          >
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateCollectionProfile === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={onHandleAddCollectionProfile}
                >
                  +
                  {t('label.addNew')}
                </button>
              )}
              {modeModifyCollectionProfile === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {isModify ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => doGetCollectionProfile()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <div>
              <DataTable
                columns={headerTable}
                isSupportRemoveIndex
                data={data && data.length ? data : []}
                isLoading={isSearching}
                sorted={sorted}
                onSort={onSortColumn}
                isFixedHeaderTable
              />
            </div>
          </form>
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={onPageChange}
            onSizeChange={onSizeChange}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('label.customerManagement'), url: RouteNames.customers.path },
            { name: t('customerPage:sidebarSearchPage.config.subTitle.collectionProfile') },
          ]}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <CollectionProfileSearchForm onSubmit={onHandleSubmitSearch} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormWithTableItem
          title={`${t('customerPage:sidebarSearchPage.config.subTitle.collectionProfile')}`}
          subClass="border-bottom"
        >
          <br />
          <form
            ref={formRef}
            noValidate
            className={`col-md-12 p-2 needs-validation ${wasValidated ? 'was-validated' : ''}`}
            onSubmit={modeModifyCollectionProfile === 2 ? onSubmit : () => {}}
          >
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateCollectionProfile === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={onHandleAddCollectionProfile}
                >
                  +
                  {t('label.addNew')}
                </button>
              )}
              {modeModifyCollectionProfile === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {isModify ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => doGetCollectionProfile()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <div>
              <DataTable
                columns={headerTable}
                isSupportRemoveIndex
                data={data && data.length ? data : []}
                isLoading={isSearching}
                sorted={sorted}
                onSort={onSortColumn}
                isFixedHeaderTable
              />
            </div>
          </form>
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={onPageChange}
            onSizeChange={onSizeChange}
          />
        </div>
      </div>
      <Footer className="footer-bottom" />
    </div>
  );
};

CollectionProfile.propTypes = {
  searchCollectionProfiles: PropsType.func.isRequired,
  createCollectionProfiles: PropsType.func.isRequired,
  modifyCollectionProfiles: PropsType.func.isRequired,
  permissionsCollections: PropsType.objectOf(PropsType.any),
};

CollectionProfile.defaultProps = {
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default connect(mapStateToProps, {
  searchCollectionProfiles,
  createCollectionProfiles,
  modifyCollectionProfiles,
})(CollectionProfile);
