import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'status',
    'serviceType',
    'tariff',
    'direction',
    'tierMax',
    'tierMin',
    'contractCapacity',
    'commitmentBase',
    'rentalAmount',
    'exchangeRate',
  ]);
  const createConfigOperatorMap = `createConfigOperatorMap(input: ${dataModify})`;
  return gql`
  mutation {
    ${createConfigOperatorMap} {
      id
    }
  }
`;
};
