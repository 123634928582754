import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  const createTask = `createTask(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createTask} {
      id
    }
  }
`;
};
