import React from 'react';

const messageExpression = (
  <div>
    <span>Error: Please check the following characters: "(", ")".</span>
    <br />
    <span>Example for expression:</span>
    <br />
    <span>Ex1: ( detail.end_date - detail.start_date)</span>
    <br />
    <span>Ex2: ( 1.0 )</span>
    <br />
    <span>Ex3: ( 1.5 * ( detail.end_date - detail.start_date )</span>
    <br />
    <span>Ex4: ( 5 + detail.end_date )</span>
    <br />
    <span>Ex5: ( detail.end_date + 5.5 )</span>
  </div>
);

// const operatorOptions = [
//   { key: '+', value: '+' },
//   { key: '-', value: '-' },
//   { key: '*', value: '*' },
//   { key: '/', value: '/' },
//   { key: '(', value: '(' },
//   { key: ')', value: ')' },
//   { key: 'min', value: 'min ( DETAIL.upload , DETAIL.download )' },
//   { key: 'max', value: 'max ( DETAIL.upload , DETAIL.download )' },
//   { key: 'avg', value: 'avg ( DETAIL.upload , DETAIL.download )' },
// ];

const operatorOptions = [
  { key: '+', value: '+' },
  { key: '-', value: '-' },
  { key: '*', value: '*' },
  { key: '/', value: '/' },
  { key: '(', value: '(' },
  { key: ')', value: ')' },
  { key: ',', value: ',' },
  { key: 'min', value: 'min ( ' },
  { key: 'max', value: 'max ( ' },
  { key: 'avg', value: 'avg ( ' },
];

const dataFieldViewTaxCode = [
  {
    name: 'productCode',
    label: 'label.code',
    required: true,
  },

  {
    name: 'description',
    label: 'label.description',
    required: true,
  },
  {
    name: 'taxPercent',
    label: 'label.taxPercent',
    required: true,
    type: 'number',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    type: 'date',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
    required: true,
    tOptions: 'selections:jurisdiction',
    type: 'select',
  },
  {
    name: 'jurisdictionList',
    label: 'label.jurisdictionList',
  },
  {
    name: 'excludeList',
    label: 'label.excludeList',
  },
  {
    name: 'minimumThreshold',
    label: 'label.minimumThreshold',
    type: 'number',
  },
  {
    name: 'maximumThreshold',
    label: 'label.maximumThreshold',
    type: 'number',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
    required: true,
  },
  // {
  //   name: 'transactionName',
  //   label: 'label.transactionName',
  // },
  // {
  //   name: 'serviceName',
  //   label: 'label.serviceName',
  // },
  {
    name: 'additionalTaxType',
    label: 'label.additionalTaxType',
  },
  {
    name: 'additionalTaxDescription',
    label: 'label.additionalTaxDescription',
  },
  {
    name: 'additionalTaxRate',
    label: 'label.additionalTaxRate',
    type: 'number',
  },
];

export const groupNamePermissions = {
  getTaxconfig: 'GET_TAX_CONFIG',
  modifyTaxconfig: 'MODIFY_TAX_CONFIG',
  createTaxconfig: 'CREATE_TAX_CONFIG',
  getUomConfig: 'SEARCH_UOM_CONFIG',
  createUomConfig: 'CREATE_UOM_CONFIG',
  modifyUomConfig: 'MODIFY_UOM_CONFIG',
  createConfigUsageType: 'CREATE_CONFIG_USAGE_TYPE',
  modifyConfigUsageType: 'MODIFY_CONFIG_USAGE_TYPE',
  getConfigUsageType: 'GET_CONFIG_USAGE_TYPE',
  CREATE_TOPUP_SYSTEM_OFFER_CONFIG: 'CREATE_TOPUP_SYSTEM_OFFER_CONFIG',
  MODIFY_TOPUP_SYSTEM_OFFER_CONFIG: 'MODIFY_TOPUP_SYSTEM_OFFER_CONFIG',
  SEARCH_TOPUP_SYSTEM_OFFER_CONFIG: 'SEARCH_TOPUP_SYSTEM_OFFER_CONFIG',
  CREATE_ZONE_UNIT_CONFIG: 'CREATE_ZONE_UNIT_CONFIG',
  MODIFY_ZONE_UNIT_CONFIG: 'MODIFY_ZONE_UNIT_CONFIG',
  VIEW_ZONE_UNIT_CONFIG: 'VIEW_ZONE_UNIT_CONFIG',
  SEARCH_RATED_USAGE: 'SEARCH_RATED_USAGE',
  UNDO_INVOICE_CANCEL: 'UNDO_INVOICE_CANCEL',
  SEARCH_SAVED_MANUAL_INVOICE: 'SEARCH_SAVED_MANUAL_INVOICE',
  CREATE_CONFIG_COUNTRY_CODES: 'CREATE_CONFIG_COUNTRY_CODES',
  MODIFY_CONFIG_COUNTRY_CODES: 'MODIFY_CONFIG_COUNTRY_CODES',
  SEARCH_CONFIG_COUNTRY_CODES: 'SEARCH_CONFIG_COUNTRY_CODES',
  CREATE_RATE_UNIT_CONFIG: 'CREATE_RATE_UNIT_CONFIG',
  MODIFY_RATE_UNIT_CONFIG: 'MODIFY_RATE_UNIT_CONFIG',
  VIEW_RATE_UNIT_CONFIG: 'VIEW_RATE_UNIT_CONFIG',
  CREATE_RATE_UNIT_MAP_CONFIG: 'CREATE_RATE_UNIT_MAP_CONFIG',
  GET_RATE_UNIT_MAP_CONFIG: 'GET_RATE_UNIT_MAP_CONFIG',
  MODIFY_RATE_UNIT_MAP_CONFIG: 'MODIFY_RATE_UNIT_MAP_CONFIG',
  CREATE_REGULATORY_PRODUCT_CODE: 'CREATE_REGULATORY_PRODUCT_CODE',
  MODIFY_REGULATORY_PRODUCT_CODE: 'MODIFY_REGULATORY_PRODUCT_CODE',
  VIEW_REGULATORY_PRODUCT_CODE: 'VIEW_REGULATORY_PRODUCT_CODE',
  VIEW_CURRENCY_CONFIG: 'VIEW_CURRENCY_CONFIG',
  MODIFY_CURRENCY_CONFIG: 'MODIFY_CURRENCY_CONFIG',
  CREATE_CURRENCY_CONFIG: 'CREATE_CURRENCY_CONFIG',
  VIEW_GRANTS_CONFIG: 'VIEW_GRANTS_CONFIG',
  MODIFY_GRANTS_CONFIG: 'MODIFY_GRANTS_CONFIG',
  CREATE_GRANTS_CONFIG: 'CREATE_GRANTS_CONFIG',
  VIEW_ACCUMULATOR_CONFIG: 'VIEW_ACCUMULATOR_CONFIG',
  MODIFY_ACCUMULATOR_CONFIG: 'MODIFY_ACCUMULATOR_CONFIG',
  CREATE_ACCUMULATOR_CONFIG: 'CREATE_ACCUMULATOR_CONFIG',
  VIEW_TIME_UNIT_CONFIG: 'VIEW_TIME_UNIT_CONFIG',
  MODIFY_TIME_UNIT_CONFIG: 'MODIFY_TIME_UNIT_CONFIG',
  CREATE_TIME_UNIT_CONFIG: 'CREATE_TIME_UNIT_CONFIG',
};

const tableColumnsPACProductCodes = [
  {
    name: 'description',
    label: 'label.description',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'includeIva',
    label: 'label.includeIva',
  },
  {
    name: 'includeIeps',
    label: 'label.includeIeps',
  },
  {
    name: 'complement',
    label: 'label.complement',
  },
];

export const pacProductCodesSort = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  description: {
    asc: 'description_ASC',
    desc: 'description_DESC',
  },
};

export { operatorOptions, messageExpression, dataFieldViewTaxCode, tableColumnsPACProductCodes };

export const searchTopupOffersColumns = [
  {
    name: 'serviceType',
    label: 'label.serviceType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'topupOfferId',
    label: 'label.topupOfferId',
  },
  {
    name: 'topupOfferName',
    label: 'label.topupOfferName',
  },
  {
    name: 'paymentOfferId',
    label: 'label.paymentOfferId',
  },
  {
    name: 'paymentOfferName',
    label: 'label.paymentOfferName',
  },
  {
    name: 'burnRateOfferId',
    label: 'label.burnRateOfferId',
  },
  {
    name: 'burnRateOfferName',
    label: 'label.burnRateOfferName',
  },
];

export const prepaidSystemOffersSort = {
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
};

export const listTopupOffersFields = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    tOptions: 'selections:serviceType',
    type: 'select',
  },
  {
    name: 'topupOfferId',
    label: 'label.topupOfferId',
    fa: 'fa fa-external-link',
    readOnly: true,
  },
  {
    name: 'topupOfferName',
    label: 'label.topupOfferName',
    readOnly: true,
  },
  {
    name: 'paymentOfferId',
    label: 'label.paymentOfferId',
    fa: 'fa fa-external-link',
    readOnly: true,
  },
  {
    name: 'paymentOfferName',
    label: 'label.paymentOfferName',
    readOnly: true,
  },
  {
    name: 'burnRateOfferId',
    label: 'label.burnRateOfferId',
    fa: 'fa fa-external-link',
    readOnly: true,
  },
  {
    name: 'burnRateOfferName',
    label: 'label.burnRateOfferName',
    readOnly: true,
  },
];
