import gql from 'graphql-tag';

export default id => gql`
  query {
    getInvoiceInstallmentsByInvoiceId(invoiceId: "${id}") {
      id
      paymentInstallmentId
      dueDate
      paymentDate
      amount
      paidAmount
      index
      installmentStatus
      daysOffset
      monthOffset
      percent
    }
  }
`;
