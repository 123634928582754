import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../../components/common';
import { countryList, stateUSData } from '../../../../constantsApp/countryList';
import { getCities, getZips } from '../../../../utils/utils';

class AddressForm extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    const item = props.item && props.item.length > 0 ? props.item[0] : {};
    return {
      ...item,
      item: props.item || {},
    };
  }

  onChangeItem = () => {
    const { onChangeItem } = this.props;
    if (onChangeItem) {
      const { item, ...values } = this.state;
      onChangeItem([{ ...values }]);
    }
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value }, () => this.onChangeItem());
  };

  render() {
    const { onAddNewAddress, t } = this.props;
    const { item } = this.state;
    const isUSA = this.state.country === 'USA';
    const addressInputs = [
      {
        label: 'label.country',
        wrapperClass: 'col-md-4',
        name: 'country',
        required: true,
        type: 'select',
        options: countryList,
        onChange: this.handleInputChange,
      },
      {
        label: 'label.state',
        wrapperClass: 'col-md-4',
        name: 'state',
        type: isUSA ? 'select' : 'text',
        required: true,
        isCreateOption: true,
        onChange: this.handleInputChange,
        options: isUSA
          ? stateUSData.map(item => ({
              label: `${item.label} (${item.value})`,
              value: item.value,
            }))
          : [],
      },
      {
        label: 'label.city',
        wrapperClass: 'col-md-4',
        name: 'city',
        required: true,
        isCreateOption: true,
        type: isUSA ? 'select' : 'text',
        options: isUSA ? getCities(this.state.state || '').map(d => ({ label: d.city, value: d.city })) : [],
      },
      {
        label: 'label.postalCode',
        wrapperClass: 'col-md-4',
        name: 'postalCode',
        isCreateOption: true,
        type: isUSA ? 'select' : 'text',
        required: true,
        options: isUSA
          ? getZips(this.state.city || '', this.state.state || '').map(d => ({ label: d.zipcode, value: d.zipcode }))
          : [],
      },
      {
        label: 'label.street',
        wrapperClass: 'col-md-4',
        name: 'street',
        required: true,
        type: 'textarea',
      },
      {
        label: 'label.landmark',
        wrapperClass: 'col-md-4',
        name: 'landmark',
      },
      {
        label: 'label.extraLine',
        wrapperClass: 'col-md-4',
        name: 'extraLine',
      },
    ];
    return (
      <div className="form-row">
        {item &&
          item.length > 0 &&
          addressInputs.map(input => (
            <GenericInput
              key={input.name}
              {...input}
              value={this.state[input.name]}
              onChange={this.handleInputChange}
            />
          ))}
        <div className="col-md-12">
          <button
            disabled={item && item.length > 0}
            type="button"
            className="button button-border black x-small mt-3"
            onClick={onAddNewAddress}
          >
            +
            {t('label.addNewAddress')}
          </button>
        </div>
      </div>
    );
  }
}

AddressForm.propTypes = {
  item: PropTypes.any.isRequired,
  onChangeItem: PropTypes.func,
  creatingMode: PropTypes.bool,
};

AddressForm.defaultProps = {
  item: [],
  creatingMode: true,
};
export default withTranslation('common')(AddressForm);
