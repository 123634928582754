import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'switchName',
        'durationMinutesStart',
        'durationMinutesEnd',
      ])}`
    : '';
  const getNonBroadSoftRawCdrData = `getNonBroadSoftRawCdrData(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getNonBroadSoftRawCdrData} {
        startTime
        endTime
        direction
        callingNumber
        calledNumber
        answerTime
        sreserved
        disconnectReason
        failureCode
        incomingRoute
        outgoingRoute
        callStatus
        terminatingAccess
        extIddAbc
        extIdoBcd
        callDuration
        commercial
        extDurationMinutes
        extDurationSeconds
        partySipCallIdZero
        partySipCallIdOne
      }
    }
  `;
};