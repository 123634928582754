import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, isEmpty, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountTable from '../AccountTable';
import ChooseKindService from './ChooseKindService';
import ServiceForm from './ServiceForm';
import QuoteForm from './QuoteForm';
import constants from '../constants';
import { quoteEnum } from '../../../constantsApp';
import { getBundleByBundleId, getCcpPropertiesConfig, getDiscountOfferById } from '../../App/actions';
import { saveQuote, buildOrderProvisioningAttributes, getQuote } from '../actions';
import { ModalQuoteDetails } from '../../../components/Quotes';
import RouteNames from '../../App/RouteNames';
import { StepProgressBar, Breadcrumb } from '../../../components/common';
import { injectValueFromConfig, getLastIndex } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';
import ModalMultipleSelectDisscountOffer from '../../../components/common/ModalMultipleSelectDisscountOffer';
import QuoteProvisioningAttributesForm from './QuoteProvisioningAttributesForm';
import ModalAccountId from '../../../components/common/ModalAccountId';
import { makeGetCcpPropertiesConfig } from '../../App/selectors';

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.purchaseOptions',
  },
  {
    step: 3,
    label: 'label.setQuoteProvisioningData',
  },
  {
    step: 4,
    label: 'label.manageCreateQuote',
  },
];

const convertbuildOrderProvisioningAttributesPayload = quoteDetail => {
  return {
    type: quoteDetail.type,
    status: quoteDetail.status,
    accountId: quoteDetail.accountId,
    userId: quoteDetail.userId,
    initialTerm: quoteDetail.initialTerm,
    initialTermUnit: quoteDetail.initialTermUnit,
    renewalTerm: quoteDetail.renewalTerm,
    renewalTermUnit: quoteDetail.renewalTermUnit,
    services: quoteDetail.services.map(service => {
      const { index, serviceType, action, status, bundleId, packageId } = service;
      return { index, serviceType, action, status, bundleId, packageId, provisioningId: 'Dummy' };
    }),
  };
};

const convertbuildOrderProvisioningAttributesNewQuote = data => {
  if (!data || !data.length) return null;
  const newPayload = [];
  data.forEach(serviceAttributes => {
    const { configProvisioningAttributes, serviceType, priceOfferId } = serviceAttributes || {};
    if (configProvisioningAttributes && configProvisioningAttributes.length) {
      configProvisioningAttributes.forEach(provisioningAttributes => {
        const { attributesList, name } = provisioningAttributes || {};

        const payloadProvisioningAttribute = {
          serviceType: serviceType || null,
          provisioningName: name || null,
          priceOfferId: priceOfferId || null,
          provisioningAttributesList: null,
        };
        if (attributesList && attributesList.length) {
          attributesList.forEach(att => {
            const { name, attributeValue } = att;
            const payloadAttribute = { name: name || null, value: attributeValue || null };
            if (!payloadProvisioningAttribute || !payloadProvisioningAttribute.provisioningAttributesList) {
              payloadProvisioningAttribute.provisioningAttributesList = [];
            }
            payloadProvisioningAttribute.provisioningAttributesList.push(payloadAttribute);
          });
        }
        newPayload.push(payloadProvisioningAttribute);
      });
    }
  });
  return newPayload;
};

const convertLineFromDiscountOffers = (list, lastIndex) => {
  if (list && list.length) {
    return list.map((item, index) => ({
      index: lastIndex + index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: item.startDate,
      endDate: item.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: item.priceOfferId,
      discountOfferId: item.id || null,
      discountOfferName: item.name,
    }));
  }
  return [];
};

class NewQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: {},
      quoteDetail: this.initQuoteDetail(props),
      groupServices: [],
      mode: constants.ModalMode.none,
      buildOrderProvisioningAttributes: null,
      activeTab: {},
      quoteDetailsGet: null,
      isOpenModalDetails: false,
      isOpenModalAccountId: false,
      oldStageSelected: 0,
      isSkip1StStep: !!props.accountId,
    };
    this.services = [];
  }

  componentDidMount() {
    const { accountId, data } = this.props;
    if (accountId) this.onSelectNext({ accountId, id: accountId });
    if (data && data.services) {
      const { quoteDetail } = this.state;
      const newData = cloneDeep(quoteDetail);
      newData.services = data.services;
      const groupServices = groupBy(data.services, item => {
        return item.serviceType;
      });
      this.setState({ quoteDetail: { ...newData, salesAgentId: data.salesAgentId || null }, groupServices });
    }
  }

  doInitPropertiesConfig = (ccpPropertyList, cb) => {
    const { quoteDetail } = this.state;
    const newQuoteDetail = cloneDeep(quoteDetail);
    const newInfo = injectValueFromConfig({
      dataConfig: ccpPropertyList,
      listFields: ['initialTermUnit', 'initialTerm', 'renewalTermUnit', 'renewalTerm'],
      defaultData: quoteDetail,
    });
    newQuoteDetail.initialTermUnit = newInfo.initialTermUnit;
    newQuoteDetail.initialTerm = newInfo.initialTerm;
    newQuoteDetail.renewalTermUnit = newInfo.renewalTermUnit;
    newQuoteDetail.renewalTerm = newInfo.renewalTerm;
    this.setState({ quoteDetail: { ...newQuoteDetail } }, () => {
      if (cb) cb();
    });
  };

  doGetCcpPropertiesConfig = cb => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList, cb);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList, cb);
    }
  };

  initQuoteDetail = props => {
    return {
      type: quoteEnum.type.new,
      accountId: null,
      userId: getUserId() || 'TestUserId',
      isPartialFulfillmentAllowed: false,
      effectiveDate: null,
      submittedDate: null,
      initialTerm: null,
      initialTermUnit: null,
      renewalTerm: null,
      renewalTermUnit: null,
      services: props && props.data && props.data.services ? props.data.services : [],
    };
  };

  onClickRow = () => {};

  onSelectNext = rowItem => {
    const { quoteDetail } = this.state;
    quoteDetail.accountId = rowItem.id;
    this.setState(
      {
        selectItem: rowItem,
        quoteDetail,
        isSkip1StStep: false,
      },
      () => {
        this.onPressNextButton();
      }
    );
  };

  onPressBackButton = () => {
    const { stage } = this.state;
    const { location, history } = this.props;
    const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;

    if (accountId) {
      const redirectUrl = `/customers/${accountId}/services`;
      history.push(redirectUrl);
      return;
    }
    history.push({
      pathname: RouteNames.searchQuoteAndList.path,
      state: location && location.state ? location.state : null,
    });
  };

  onPressCancelButton = () => {
    this.setState({
      stage: 0,
      oldStageSelected: 0,
    });
  };

  onPressNextButton = () => {
    const { stage, quoteDetail, mode } = this.state;
    const { t } = this.props;
    // const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;
    if (stage === 1) {
      if (!quoteDetail.services || quoteDetail.services.length === 0) {
        toast(t('message.msgChoosePackageBundlePO'), { type: toast.TYPE.ERROR });
        return;
      }
      this.doGetCcpPropertiesConfig(() => {
        if (mode === constants.ModalMode.purchasePackage) {
          this.doInitPackageItem();
        }
      });
      this.doBuildOrderProvisioningAttributes();
    }

    this.setState({
      stage: stage + 1,
      isOpenModalDisscountOffers: false,
      oldStageSelected: stage + 1,
    });
  };

  onPressCreateButton = isSave => {
    const { quoteDetail, groupServices, buildOrderProvisioningAttributes } = this.state;

    const newQuoteDetail = cloneDeep(quoteDetail);
    newQuoteDetail.services = [];
    newQuoteDetail.provisioningAttributes = convertbuildOrderProvisioningAttributesNewQuote(
      buildOrderProvisioningAttributes
    );
    map(groupServices, serviceGroup => {
      return serviceGroup.map(service => {
        const {
          bundleName,
          packageName,
          provisioningId,
          status,
          discountOfferId,
          isPackage,
          isBundle,
          isDisscount,
          isPriceOffer,
          ...newService
        } = cloneDeep(service);
        if (newService && newService.lines && newService.lines.length) {
          newService.lines.forEach((val, idx) => {
            delete newService.lines[idx].discountOfferName;
            delete newService.lines[idx].priceOfferName;
            delete newService.lines[idx].status;
            delete newService.lines[idx].id;

            newService.lines[idx].startDate = newService.lines[idx].startDate || null;
            newService.lines[idx].endDate = newService.lines[idx].endDate || null;
            if (newService.lines[idx] && newService.lines[idx].discountOfferId) {
              newService.lines[idx].discAmountOverride = newService.lines[idx].priceOverride;
              newService.lines[idx].discPercentOverride = newService.lines[idx].discountPercent;
              delete newService.lines[idx].priceOverride;
              delete newService.lines[idx].discountPercent;
            }
          });
        }
        newQuoteDetail.services.push(newService);
        return true;
      });
    });
    // if (!this.checkprovisioningIdOfService(newQuoteDetail)) {
    //   toast("Provisioning Id can't be empty", { type: toast.TYPE.ERROR });
    //   return;
    // }
    const { doSaveQuote, getQuote, id, isComponent } = this.props;
    if (isSave) {
      return doSaveQuote({ ...newQuoteDetail, id: id || null }, null, isComponent);
    }
    return getQuote({ ...newQuoteDetail, id: id || null }, ({ success, data }) => {
      if (success) {
        this.setState({ isOpenModalDetails: true, quoteDetailsGet: data || {} });
      }
    });
  };

  // checkprovisioningIdOfService = quoteDetail => {
  //   let result = true;
  //   quoteDetail.services.forEach(item => {
  //     if (!item.provisioningId) {
  //       result = false;
  //     }
  //   });
  //   return result;
  // };

  initServiceWithSelectPackage = ({
    index,
    bundleId,
    packageId,
    serviceType,
    packageName,
    bundleName,
    discountOfferId,
  }) => {
    return {
      index,
      serviceType,
      action: 'ADD',
      status: quoteEnum.status.create,
      bundleId,
      packageId,
      packageName,
      bundleName,
      lines: [],
      discountOfferId,
      isPackage: true,
    };
  };

  initServiceWithSelectBundle = (bundle, index) => {
    return {
      index: index + 1,
      serviceType: bundle && bundle.components && bundle.components.length > 0 ? bundle.components[0].serviceType : '',
      action: 'ADD',
      reason: null,
      status: 'CREATED',
      bundleId: bundle.id,
      bundleName: bundle.name,
      packageId: null,
      discountOfferId: bundle.discountOfferId || null,
      discountOfferName: bundle.discountOfferName || null,
      isBundle: true,
      lines: bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [],
    };
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId || null,
      discountOfferName: component.discountOfferName,
      priceOfferName: component.priceOfferName,
    };
  };

  initServiceFromPriceOffers = (priceOffers, serviceIndex) => {
    return {
      index: serviceIndex,
      serviceType: priceOffers[0].serviceType || null,
      provisioningId: null,
      action: 'ADD',
      reason: null,
      status: 'CREATED',
      bundleId: null,
      packageId: null,
      isPriceOffer: true,
      lines: priceOffers.map((item, i) => {
        return this.initLineFromPriceOffer(item, i);
      }),
    };
  };

  initLineFromPriceOffer = (priceOffer, index) => {
    return {
      index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: priceOffer.startDate,
      endDate: priceOffer.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: priceOffer.id,
      priceOfferName: priceOffer.name,
      discountOfferId: priceOffer.discountOfferId || null,
    };
  };

  addLine = (groupIndex, serviceIndex, bundle) => {
    const { quoteDetail, groupServices } = this.state;
    try {
      groupServices[groupIndex][serviceIndex].lines = bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [];
      quoteDetail.services = [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          quoteDetail.services.push(service);
          return service;
        });
      });
      this.setState({
        quoteDetail,
        groupServices,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeMode = mode => {
    const { quoteDetail } = this.state;
    // quoteDetail.services = [];
    this.setState({
      mode,
      quoteDetail,
    });
  };

  onHandleChangeQuoteForm = e => {
    const { quoteDetail } = this.state;
    const newData = cloneDeep(quoteDetail);
    if (e.fieldName === 'type' && e.value !== 'TRIAL') {
      newData.trialType = null;
    }
    newData[e.fieldName] = e.value;

    this.setState({
      quoteDetail: newData,
    });
  };

  onRemoveItem = evt => {
    const { quoteDetail } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(quoteDetail);
    const indexItemRemove = newData.services.findIndex(
      item => item.serviceType === evt.serviceType && item.index === evt.index
    );
    newData.services.splice(indexItemRemove, 1);
    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ quoteDetail: newData, groupServices });
  };

  onChangeFormLine = (groupIndex, serviceIndex, e) => {
    const { quoteDetail } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(quoteDetail);
    try {
      let indexServiceChange = -1;
      indexServiceChange = newData.services.findIndex(
        item =>
          item.index === groupServices[groupIndex][serviceIndex].index &&
          item.serviceType === groupServices[groupIndex][serviceIndex].serviceType
      );
      if (indexServiceChange !== -1 && !Number.isNaN(indexServiceChange)) {
        const lineIndex = newData.services[indexServiceChange].lines.findIndex(item => item.index === e.lineIndex);
        newData.services[indexServiceChange].lines[lineIndex][e.fieldName] = e.value;
        groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState({
          quoteDetail: newData,
          groupServices,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChangeService = (groupIndex, e) => {
    try {
      const { quoteDetail, groupServices } = this.state;
      groupServices[groupIndex][e.index][e.fieldName] = e.value;
      quoteDetail.services = [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          quoteDetail.services.push(service);
          return service;
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProvisioningId = ({ name, value, item }) => {
    const { quoteDetail } = this.state;
    let groupServices = [];
    const newData = cloneDeep(quoteDetail);
    for (let i = 0; i < newData.services.length; i++) {
      if (newData.services[i].serviceType === item.serviceType) {
        newData.services[i][name] = value;
      }
    }
    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ quoteDetail: newData, groupServices });
  };

  doInitPackageItem = () => {
    const { quoteDetail } = this.state;
    const { getBundleByBundleId, getDiscountOfferById } = this.props;
    // const listBundleId = quoteDetail.services.map(item => item.bundleId) || [];
    const listBundleId = quoteDetail.services.filter(val => !!val.bundleId).map(item => item.bundleId) || [];
    const listDiscountId =
      quoteDetail.services.filter(val => !!val.discountOfferId).map(item => item.discountOfferId) || [];

    const newDataWithBundleId = [];
    const newDataWithDiscountId = [];

    if (!isEmpty(listBundleId)) {
      listBundleId.map(item =>
        getBundleByBundleId(item, ({ success, data }) => {
          newDataWithBundleId.push(data.components.map(cp => ({ ...cp, bundleId: item })));
          if (newDataWithBundleId.length === listBundleId.length && success) {
            newDataWithBundleId.forEach(lineFetchFromAPI => {
              if (quoteDetail.services && quoteDetail.services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = quoteDetail.services.findIndex(sr => sr.bundleId === val.bundleId);
                  quoteDetail.services[indexService].lines[j] = {};
                  quoteDetail.services[indexService].lines[j].index = j + 1;
                  quoteDetail.services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  quoteDetail.services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  quoteDetail.services[indexService].lines[j].action = 'ADD';
                  quoteDetail.services[indexService].lines[j].status = 'CREATED';
                  quoteDetail.services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  quoteDetail.services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });
          }
        })
      );
    }

    if (!isEmpty(listDiscountId)) {
      listDiscountId.map(item =>
        getDiscountOfferById(item, ({ success, data }) => {
          if (success) newDataWithDiscountId.push([{ discountOfferName: data.name || null, discountOfferId: item }]);
          if (newDataWithDiscountId.length === listDiscountId.length && success) {
            newDataWithDiscountId.forEach(lineFetchFromAPI => {
              if (quoteDetail.services && quoteDetail.services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = quoteDetail.services.findIndex(sr => sr.discountOfferId === val.discountOfferId);
                  quoteDetail.services[indexService].lines[j] = {};
                  quoteDetail.services[indexService].lines[j].index = j + 1;
                  quoteDetail.services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  quoteDetail.services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  quoteDetail.services[indexService].lines[j].action = 'ADD';
                  quoteDetail.services[indexService].lines[j].status = 'CREATED';
                  quoteDetail.services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  quoteDetail.services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });

            const groupServices = groupBy(quoteDetail.services, item => {
              return item.serviceType;
            });
            this.setState({
              quoteDetail,
              mode: constants.ModalMode.purchasePackage,
              groupServices,
            });
          }
        })
      );
    }
  };

  onSelectService = ({ services, mode, ids, rows }) => {
    if (!services || (services && services.length === 0) || !ids || (ids && ids.length === 0)) {
      // const { quoteDetail } = this.state;
      // quoteDetail.services = [];

      // this.setState({
      //   quoteDetail,
      //   groupServices: [],
      // });
      return;
    }
    const { quoteDetail } = this.state;
    const newData = cloneDeep(quoteDetail);
    let service = [];
    let groupServices = [];
    let serviceIndex = 0;
    switch (mode) {
      case constants.ModalMode.purchasePackage:
        if (!newData.services) newData.services = [];
        newData.services = newData.services.filter(val => !val.isPackage);

        newData.services = [
          ...newData.services,
          ...services[0].map((item, index) => {
            return this.initServiceWithSelectPackage({
              index: index + 1,
              packageId: ids[0],
              bundleId: item.bundleId,
              bundleName: item.bundleName,
              serviceType: item.serviceType,
              discountOfferId: item.discountOfferId || null,
              packageName: rows && rows.length ? rows[0].name : '',
            });
          }),
        ];
        // const listBundleId = quoteDetail.services.map(item => item.bundleId) || [];
        // const newDataWithBundleId = [];
        // if (!isEmpty(listBundleId)) {
        //   listBundleId.map(item =>
        //     getBundleByBundleId(item, ({ success, data }) => {
        //       newDataWithBundleId.push(data.components.map(cp => ({ ...cp, bundleId: item })));
        //       if (newDataWithBundleId.length === listBundleId.length && success) {
        //         newDataWithBundleId.forEach(lineFetchFromAPI => {
        //           if (
        //             quoteDetail.services &&
        //             quoteDetail.services.length &&
        //             lineFetchFromAPI &&
        //             lineFetchFromAPI.length
        //           ) {
        //             lineFetchFromAPI.forEach((val, j) => {
        //               const indexService = quoteDetail.services.findIndex(sr => sr.bundleId === val.bundleId);
        //               quoteDetail.services[indexService].lines[j] = {};
        //               quoteDetail.services[indexService].lines[j].index = j + 1;
        //               quoteDetail.services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
        //               quoteDetail.services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
        //               quoteDetail.services[indexService].lines[j].action = 'ADD';
        //               quoteDetail.services[indexService].lines[j].status = 'CREATED';
        //               quoteDetail.services[indexService].lines[j].priceOfferName = val.priceOfferName;
        //               quoteDetail.services[indexService].lines[j].discountOfferName = val.discountOfferName;
        //             });
        //           }
        //         });
        //       }
        //     })
        //   );
        // }
        groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState({
          quoteDetail: newData,
          mode: constants.ModalMode.purchasePackage,
          groupServices,
        });
        break;
      case constants.ModalMode.purchaseBundle:
        if (!newData.services) newData.services = [];
        newData.services = newData.services.filter(val => !val.isBundle);

        newData.services = [
          ...newData.services,
          ...services.map((item, index) => {
            return this.initServiceWithSelectBundle(item, index);
          }),
        ];
        groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState({
          quoteDetail: newData,
          mode: constants.ModalMode.purchaseBundle,
          groupServices,
        });
        break;
      case constants.ModalMode.purchaseAlaCarte:
        if (!newData.services) newData.services = [];
        newData.services = newData.services.filter(val => !val.isPriceOffer);

        service = groupBy(services, item => {
          return item.serviceType;
        });

        serviceIndex = 0;
        newData.services = [
          ...newData.services,
          ...map(service, item => {
            serviceIndex += 1;
            return this.initServiceFromPriceOffers(item, serviceIndex);
          }),
        ];

        groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState({
          quoteDetail: newData,
          mode: constants.ModalMode.purchaseAlaCarte,
          groupServices,
        });
        break;
      default:
        break;
    }
  };

  onChangeCheckBox = ({ fieldName, value }) => {
    const { quoteDetail } = this.state;
    const newData = cloneDeep(quoteDetail);
    newData[fieldName] = value;

    this.setState({
      quoteDetail: newData,
    });
  };

  onSelectMultipleDiscountOffers = (id, row) => {
    const { quoteDetail } = this.state;
    const newData = cloneDeep(quoteDetail);
    if (row && row.length && newData && newData.services) {
      let listDefaultLines = [];

      newData.services.forEach(val => {
        const { lines } = val;
        if (lines && lines.length) {
          listDefaultLines = [...listDefaultLines, ...lines.filter(line => !!line.discountOfferId)];
        }
      });
      const newRows = row.filter(r => !listDefaultLines.find(ln => ln.discountOfferId === r.id));
      const newServices = [];
      if (newRows && newRows.length) {
        const newRowsGroupBy = groupBy(newRows, item => {
          return item.serviceType;
        });
        map(newRowsGroupBy, (value, key) => {
          const newService = {
            index: newServices.length + newData.services.length + 1,
            serviceType: key,
            provisioningId: null,
            action: 'ADD',
            reason: null,
            status: 'CREATED',
            bundleId: null,
            packageId: null,
            lines: [],
            isDisscount: true,
          };
          newService.lines = convertLineFromDiscountOffers(value, getLastIndex({ data: value }));
          newServices.push(newService);
        });
      }
      if (newServices && newServices.length) {
        newData.services = [...newData.services, ...newServices];
      }

      // newData.services = newData.services.map(sv => {
      //   const { serviceType, lines, ...rest } = sv;
      //   const listDiscountSelect = row.filter(r => r.serviceType === serviceType);
      //   let listLines = [];
      //   if (listDiscountSelect && listDiscountSelect.length)
      //     listLines = convertLineFromDiscountOffers(listDiscountSelect, getLastIndex({ data: lines }));
      //   return { ...rest, serviceType, lines: [...lines, ...listLines] };
      // });
      const groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      // this.doBuildOrderProvisioningAttributes();
      this.setState({
        quoteDetail: newData,
        groupServices,
        isOpenModalDisscountOffers: false,
      });
    }
  };

  doBuildOrderProvisioningAttributes = () => {
    const { buildOrderProvisioningAttributes } = this.props;
    const { quoteDetail } = this.state;
    buildOrderProvisioningAttributes(convertbuildOrderProvisioningAttributesPayload(quoteDetail), ({ data }) => {
      this.setState({ buildOrderProvisioningAttributes: data || null });
    });
  };

  onToggleTab = (activeTabName, serviceType) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && serviceType === activeTab.serviceType) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive, serviceType },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true, serviceType } });
    }
  };

  onHandleChangeProvisioningAttributes = ({ value, indexService, indexAttribute, indexItem }) => {
    const { buildOrderProvisioningAttributes } = this.state;
    try {
      const newAttributes = cloneDeep(buildOrderProvisioningAttributes);
      newAttributes[indexService].configProvisioningAttributes[indexAttribute].attributesList[
        indexItem
      ].attributeValue = value;

      this.setState({ buildOrderProvisioningAttributes: newAttributes });
    } catch (error) {
      console.log(error);
    }
  };

  onClickItem = () => {
    this.setState({ isOpenModalAccountId: true });
  };

  onSelectAccountId = id => {
    const { quoteDetail } = this.state;
    quoteDetail.salesAgentId = id || null;
    this.setState({ isOpenModalAccountId: false, quoteDetail: { ...quoteDetail } });
  };

  renderContent = ({ modeNewQuoteType, modeOverrideQuoteLines, modeUpdateProvisioningAttributes }) => {
    const {
      stage,
      selectItem,
      bundleName,
      packageName,
      mode,
      quoteDetail,
      groupServices,
      activeTab,
      buildOrderProvisioningAttributes,
      isSkip1StStep,
    } = this.state;
    const { location } = this.props;
    switch (stage) {
      case 0:
        return isSkip1StStep ? null : (
          <AccountTable
            type="new-quote"
            subscriptionStatus="CLOSED"
            statusRemove={['ACTIVE', 'SUSPENDED']}
            isSubscriptionStatusFilter
            // subscriptionStatus={null}
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            modeNewQuoteType={modeNewQuoteType}
            onSelectNext={this.onSelectNext}
            linkTo={{
              pathname: RouteNames.searchQuoteAndList.path,
              state: location && location.state ? location.state : null,
            }}
            isNoPageTitle
          />
        );

      case 2:
        return (
          <div>
            <QuoteForm
              id={selectItem.id}
              quoteDetail={quoteDetail}
              onChangeCheckBox={this.onChangeCheckBox}
              onHandleChange={this.onHandleChangeQuoteForm}
              onClick={this.onClickItem}
            />
            {modeUpdateProvisioningAttributes && (
              <QuoteProvisioningAttributesForm
                data={buildOrderProvisioningAttributes}
                activeTab={activeTab}
                onChange={this.onHandleChangeProvisioningAttributes}
                onToggleTab={this.onToggleTab}
              />
            )}
          </div>
        );
      case 3:
        return (
          <ServiceForm
            id={selectItem.id}
            bundleName={bundleName}
            packageName={packageName}
            onChangeProvisioningId={this.onChangeProvisioningId}
            quoteDetail={quoteDetail}
            addLine={this.addLine}
            onChangeFormLine={this.onChangeFormLine}
            onChangeService={this.onChangeService}
            groupServices={groupServices}
            onRemoveLine={this.onRemoveLine}
            onRemoveItem={this.onRemoveItem}
            mode={mode}
            modeOverrideQuoteLines={modeOverrideQuoteLines}
          />
        );
      default:
        return null;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    if (step < stage + 1 || step <= oldStageSelected + 1) {
      this.setState({ stage: step - 1 });
    }
  };

  onToggleModalDetais = () => {
    this.setState({ isOpenModalDetails: false, quoteDetailsGet: null });
  };

  onSelectModalDiscounts = () => {
    this.setState({ isOpenModalDisscountOffers: true });
  };

  onRemoveLine = ({ itemService, itemLine }) => {
    try {
      const { quoteDetail } = this.state;
      const newQuoteDetail = cloneDeep(quoteDetail);

      const indexService = newQuoteDetail.services.findIndex(item => item.index === itemService.index);
      const indexLine = newQuoteDetail.services[indexService].lines.findIndex(item => item.index === itemLine.index);
      if (indexService > -1 && indexLine > -1) {
        newQuoteDetail.services[indexService].lines.splice(indexLine, 1);
      }
      const groupServices = groupBy(newQuoteDetail.services, item => {
        return item.serviceType;
      });
      this.setState({
        quoteDetail: newQuoteDetail,
        groupServices,
        isOpenModalDisscountOffers: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      stage,
      isOpenModalDisscountOffers,
      quoteDetail,
      selectItem,
      mode,
      quoteDetailsGet,
      isOpenModalDetails,
      isOpenModalAccountId,
      oldStageSelected,
      bundleName,
      packageName,
      groupServices,
    } = this.state;
    const { permissions, t, isComponent, onToggle } = this.props;
    let serviceTypeOptions = t('selections:serviceType')();
    let addFirstParamsField = null;
    let defaultFilter = null;
    if (quoteDetail && quoteDetail?.services?.length) {
      const serviceTypesList = quoteDetail.services.map(val => val.serviceType);
      serviceTypeOptions = serviceTypeOptions.filter(sr => serviceTypesList.includes(sr.value));
      if (serviceTypeOptions && serviceTypeOptions.length) {
        addFirstParamsField = { name: 'serviceType', value: serviceTypeOptions[0].value };
        defaultFilter = { serviceType: serviceTypeOptions[0].value };
      } else {
        serviceTypeOptions = [];
      }
    }

    const { newQuoteDetails, oldQuoteDetails, accountId } = quoteDetailsGet || {};
    const isHasOldQuote = oldQuoteDetails && oldQuoteDetails.serviceLevelQuoteDetails;

    const {
      modePackagePurchase,
      modeNewQuoteType,
      modeBundlePurchase,
      modeAlacartePurchase,
      modeOverrideQuoteLines,
      modeUpdateProvisioningAttributes,
      modeSaveQuote,
      modeGetQuote,
    } = permissions || {};

    if (!modeNewQuoteType) return '';

    const items = [
      {
        name: t('quotePage:sidebar.newQuote'),
        url: RouteNames.newQuote.path,
      },
    ];

    if (quoteDetail.accountId) {
      items.push({
        name: quoteDetail.accountId || null,
      });
    }

    return (
      <div>
        {!isComponent && (
          <div className="col-md-12 row p-0">
            <div className="col-sm-6">
              <button type="button" className="button x-small height-btn" onClick={() => this.onPressBackButton(true)}>
                {t('label.back')}
              </button>
            </div>
            <div className="col-sm-6 pr-0">
              <Breadcrumb items={items} />
            </div>
          </div>
        )}
        <div className="col-md-12 row text-center pb-3">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              oldSelected={oldStageSelected}
              listSteps={listSteps}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3">
            <button
              type="button"
              className="button button-border x-small gray  "
              onClick={onToggle || this.onPressCancelButton}
            >
              {t('label.cancel')}
            </button>
            {stage < 3 ? (
              <button
                type="button"
                className="button button-border x-small "
                onClick={this.onPressNextButton}
                disabled={modeNewQuoteType === 1}
              >
                {t('label.next')}
              </button>
            ) : (
              <>
                {!!modeGetQuote && (
                  <button
                    type="button"
                    className="button button-border x-small "
                    onClick={() => this.onPressCreateButton(false)}
                    disabled={modeGetQuote === 1}
                  >
                    {t('label.getQuote')}
                  </button>
                )}
                {modeNewQuoteType && (
                  <button
                    type="button"
                    className="button button-border x-small "
                    onClick={() => this.onPressCreateButton(true)}
                    disabled={modeSaveQuote === 1 || modeNewQuoteType === 1}
                  >
                    {t('label.save')}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {/* 
        <div className="col-md-12 pb-5 pt-3 group-step">
          <StepProgressBar
            oldSelected={oldStageSelected}
            listSteps={listSteps}
            stepSelected={stage}
            onSelectStep={this.onSelectStep}
            isSuportOldSelected
          />
        </div> */}
        {this.renderContent({
          modeNewQuoteType,
          modePackagePurchase,
          modeBundlePurchase,
          modeAlacartePurchase,
          modeOverrideQuoteLines,
          modeUpdateProvisioningAttributes,
        })}
        <div style={{ display: stage === 1 ? 'block' : 'none' }}>
          {stage === 1 && (
            <>
              <ChooseKindService
                id={selectItem.id}
                onSelectService={this.onSelectService}
                mode={mode}
                onChangeMode={this.onChangeMode}
                modePackagePurchase={modePackagePurchase}
                modeBundlePurchase={modeBundlePurchase}
                modeAlacartePurchase={modeAlacartePurchase}
                onSelectModalDiscounts={this.onSelectModalDiscounts}
                isMulti
              />
              <ServiceForm
                id={selectItem.id}
                bundleName={bundleName}
                packageName={packageName}
                onChangeProvisioningId={this.onChangeProvisioningId}
                quoteDetail={quoteDetail}
                addLine={this.addLine}
                onChangeFormLine={this.onChangeFormLine}
                onChangeService={this.onChangeService}
                groupServices={groupServices}
                onRemoveLine={this.onRemoveLine}
                onRemoveItem={this.onRemoveItem}
                mode={mode}
                modeOverrideQuoteLines={modeOverrideQuoteLines}
                isViewOnly={stage === 1}
              />
            </>
          )}
        </div>
        {/* <div className="form-group col-md-12 mt-4 justify-content-center d-flex row ">
          <button type="button" className="button button-border x-small gray  " onClick={this.onPressCancelButton}>
            {t('label.cancel')}
          </button>
          {stage < 3 ? (
            <button
              type="button"
              className="button button-border x-small "
              onClick={this.onPressNextButton}
              disabled={modeNewQuoteType === 1}
            >
              {t('label.next')}
            </button>
          ) : (
            <>
              {!!modeGetQuote && (
                <button
                  type="button"
                  className="button button-border x-small "
                  onClick={() => this.onPressCreateButton(false)}
                  disabled={modeGetQuote === 1}
                >
                  {t('label.getQuote')}
                </button>
              )}
              {modeNewQuoteType && (
                <button
                  type="button"
                  className="button button-border x-small "
                  onClick={() => this.onPressCreateButton(true)}
                  disabled={modeSaveQuote === 1 || modeNewQuoteType === 1}
                >
                  {t('label.save')}
                </button>
              )}
            </>
          )}
        </div> */}
        <ModalMultipleSelectDisscountOffer
          isOpen={isOpenModalDisscountOffers && stage === 1}
          // isMultipleOptionInject
          // optionsInject={{
          //   serviceType: serviceTypeOptions,
          // }}
          addFirstParamsField={addFirstParamsField}
          onCancel={() => {
            this.setState({ isOpenModalDisscountOffers: false });
            // this.doBuildOrderProvisioningAttributes();
          }}
          onSelect={this.onSelectMultipleDiscountOffers}
          defaultFilter={defaultFilter}
        />
        <ModalQuoteDetails
          isOpenModalDetails={isOpenModalDetails}
          onToggle={this.onToggleModalDetais}
          oldQuoteDetails={oldQuoteDetails}
          newQuoteDetails={newQuoteDetails}
          isHasOldQuote={isHasOldQuote}
          isSupportColor
          accountId={accountId}
        />

        <ModalAccountId
          isOpen={isOpenModalAccountId}
          onSelect={this.onSelectAccountId}
          selectedParentId={quoteDetail?.salesAgentId}
          defaultSearchParams={{ type: 'SALES_AGENT' }}
          onCancel={() => {
            this.setState({ isOpenModalAccountId: false });
          }}
        />
      </div>
    );
  }
}

NewQuote.propTypes = {
  doSaveQuote: PropTypes.func.isRequired,
  getBundleByBundleId: PropTypes.func.isRequired,
  getQuote: PropTypes.func.isRequired,
  getCcpPropertiesConfig: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  permissions: PropTypes.objectOf(PropTypes.any),
  buildOrderProvisioningAttributes: PropTypes.func.isRequired,
  getDiscountOfferById: PropTypes.func.isRequired,
  t: PropTypes.func,
  ccpPropertyList: PropTypes.arrayOf(PropTypes.any),
};

NewQuote.defaultProps = {
  location: {},
  history: {},
  permissions: {},
  t: () => {},
  ccpPropertyList: [],
};

const mapStateToProps = createStructuredSelector({
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

const tNewQuote = connect(mapStateToProps, {
  getBundleByBundleId,
  doSaveQuote: saveQuote,
  getCcpPropertiesConfig,
  buildOrderProvisioningAttributes,
  getQuote,
  getDiscountOfferById,
})(NewQuote);

export default withTranslation('common')(tNewQuote);
