import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigPendingBillingTrigger(dummy: "") {
      id
      name
      configPendingBillingTriggerList {
        index
        dom
        monthOffset
        usageFlag
      }
    }
  }
`;
