import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cloneDeep, orderBy } from 'lodash';
import { toast } from 'react-toastify';
import Tooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../../constantsApp/countryList';
import { GenericInput, CollapsibleTable, ModalWithItem, DataTable } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import PricingSection from './PricingForm/PricingSection';
import { AuditHistoryForm } from '../../../components/PricingHub';
import ModalSelectItemId from './ModalSelectItemId';
import ModalSearchItemServices from '../../../components/common/ModalSearchItemServices';
import { getLastIndex, supportRemoveIndex, convertIdPOAndDO } from '../../../utils/utils';
import ModalSelectProvisioningName from '../../../components/common/ModalSelectProvisioningName';

const defaultFlatPricing = {
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultRecurringPricing = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  prices: [],
  // prices: [{ index: 1, currencyId: 'USD', amount: null }],
  grants: [],
  accumulators: [],
};

const defaultCustomerPricing = {
  index: 1,
  salesChannel: 'NONE',
  marketSegment: 'NONE',
  accountType: 'NONE',
  accountSubType: 'NONE',
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultRecurringTiered = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  unit: 'MONTHS',
  frequency: 1,
  tierPricing: [
    {
      id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
      name: '',
      description: '',
      tierType: 'PROSPECTIVE',
      tierReference: 'BALANCE',
      tiers: [
        {
          minimum: '0',
          maximum: '0',
          // prices: [],
          prices: [],
          grants: [],
          accumulators: [],
        },
      ],
    },
  ],
};

const defaultAttributeBased = {
  index: 1,
  rateUnit: 'NONE',
  timeUnit: 'NONE',
  zoneUnit: 'NONE',
  impactUnit: 'NONE',
  holidayName: 'NONE',
  code: '',
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultTiered = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
  name: '',
  description: '',
  priceOfferId: '',
  tierType: null,
  tierReference: null,
  accumulatorId: null,
  volumeTreatment: 'ACTUAL',
  evaluationStart: 0,
  evaluationEnd: 0,
  offsetMonths: 0,
  nextPricingTerm: 0,
  tiers: [
    {
      maximum: '0',
      minimum: '0',
      // prices: [],
      prices: [],
      grants: [],
      accumulators: [],
      // grants: [{ resourceId: '', amount: '0', grantDuration: 0, grantUnit: null }],
      // accumulators: [{ accumulatorId: '', amount: '0', duration: 0, unit: null }],
    },
  ],
};

const defaultUsageAttributeBase = {
  index: 1,
  name: 'usage',
  description: 'usage',
  usageAttributePricingData: [
    {
      attribute: '',
      value: 'NONE',
    },
  ],
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultComplex = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 10000000000000)}`,
  index: 1,
  name: '',
  description: '',
  purchaseProration: null,
  cancelProration: null,
  upgradeProration: null,
  downgradeProration: null,
  unit: null,
  frequency: 1,
};

const mapPricingFromItem = ({
  pricingModel,
  flatPricing,
  recurringPricing,
  customerPricing,
  tierPricing,
  txnPricing,
  transactionType,
  complexPricing,
  pricings,
  isChangeModel,
  usageAttributePricing,
}) => {
  if (pricingModel === 'FLAT')
    return !flatPricing || flatPricing.length === 0 ? [{ ...defaultFlatPricing }] : flatPricing;
  if (pricingModel === 'RECURRING')
    return !recurringPricing || recurringPricing.length === 0 || isChangeModel
      ? [{ ...defaultRecurringPricing }]
      : recurringPricing;
  if (pricingModel === 'CUSTOMER_ATTRIBUTE_BASED')
    return !customerPricing || customerPricing.length === 0 ? [{ ...defaultCustomerPricing }] : customerPricing;
  if (pricingModel === 'RECURRING_TIERED')
    return !recurringPricing || recurringPricing.length === 0 || isChangeModel
      ? [{ ...defaultRecurringTiered }]
      : recurringPricing;
  if (pricingModel === 'TRANSACTION_ATTRIBUTE_BASED')
    return !txnPricing || txnPricing.length === 0 ? [{ ...defaultAttributeBased }] : txnPricing[0].txnPrices;
  if (pricingModel === 'TIERED') {
    return !tierPricing || !tierPricing.length ? [{ ...defaultTiered }] : tierPricing;
  }
  if (
    pricingModel === 'COMPLEX' &&
    (transactionType === 'USAGE' || transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING')
  ) {
    return !complexPricing || !complexPricing.length
      ? [{ ...defaultComplex }]
      : orderBy(complexPricing, ['index'], ['asc']);
  }

  if (pricingModel === 'USAGE_ATTRIBUTE_BASED') {
    return !usageAttributePricing || usageAttributePricing.length === 0
      ? [{ ...defaultUsageAttributeBase }]
      : usageAttributePricing;
  }

  return pricings;
};

class PricingOfferForm extends Component {
  state = {
    wasValidated: false,
    formValid: true,
    isSelectingItemId: false,
    itemId: null,
    pricingModel: '',
    transactionType: '',
    isOpenModalBilledService: false,
    activeTab: {},
    provisioningNames: null,
    isOpenModalProvisioningName: false,
    isMultipleSelectProvisioningName: false,
    itemProvisioningNameSelected: null,
    indexProvisioningNameSelected: null,
    openModalConfigName: null,
    activeSubTab: {},
    provisioningNameDefault: [],
    isFetchProvisioningName: false,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldItem === props.item) return null;
    return {
      ...props.item,
      pricings: mapPricingFromItem(props.item || {}) || [],
      item: props.item,
      oldItem: props.item,
    };
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onOpenParentSelector = e => {
    this.setState({ isSelectingItemId: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingItemId: false });
  };

  onSelectItemId = itemId => {
    this.setState({ itemId, isSelectingItemId: false });
  };

  handleInputChange = ({ name, value }) => {
    const { pricingModel, transactionType } = this.state;
    const { creatingMode } = this.props;
    if (name === 'transactionType') this.setState({ complexPricing: null });
    if (name === 'omsTaskType' && value === 'PROVISIONING_ONLY' && creatingMode) {
      this.state.pricingModel = 'NONE';
      this.state.transactionType = 'NONE';
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (
          (name === 'transactionType' && transactionType !== value) ||
          (name === 'pricingModel' && pricingModel !== value)
        ) {
          this.handleModelChange(name, value);
        }
      }
    );
  };

  handleModelChange = () => {
    const {
      flatPricing,
      recurringPricing,
      customerPricing,
      tierPricing,
      pricings,
      transactionType,
      usageAttributePricing,
      pricingModel,
      complexPricing,
    } = this.state;
    const newPricings = mapPricingFromItem({
      pricingModel,
      flatPricing,
      recurringPricing,
      customerPricing,
      tierPricing,
      pricings,
      isChangeModel: true,
      usageAttributePricing,
      transactionType,
      complexPricing,
    });
    this.setState({ pricings: newPricings });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { onSubmit, creatingMode } = this.props;
    const {
      id,
      name,
      description,
      itemId,
      startDate,
      endDate,
      startDuration,
      startUnit,
      endDuration,
      endUnit,
      pricingModel,
      transactionType,
      minimumQuantity,
      maximumQuantity,
      salesChannel,
      marketSegment,
      accountType,
      accountSubType,
      serviceType,
      serviceAddOn,
      status,
      country,
      pricings,
      item,
      billableServiceId,
      pricingType,
      provisioningNames,
      isProvisionable,
      omsTaskType,
      effectiveDate,
      accountingCode,
    } = this.state;
    let tierPricing = null;
    let txnPricing = null;
    let complexPricing = null;
    let flatPricing = null;
    let customerPricing = null;
    let recurringPricing = null;
    let usageAttributePricing = null;
    if (pricingModel === 'TIERED') {
      tierPricing = cloneDeep(pricings);
      if (creatingMode && tierPricing && tierPricing.length) {
        tierPricing = tierPricing.map((pr, index) => {
          const { id, ...rest } = pr;
          return { ...rest, id: convertIdPOAndDO(name, 'tiers', index) };
        });
      }
    }

    if (pricingModel === 'FLAT') {
      flatPricing = cloneDeep(pricings);
    }

    if (pricingModel === 'CUSTOMER_ATTRIBUTE_BASED') {
      if (pricings && pricings.length) {
        customerPricing = cloneDeep(pricings).map((val, idx) => {
          const { index, ...rest } = val;
          return { ...rest, index: creatingMode ? idx + 1 : index };
        });
      }
    }

    if (pricingModel === 'RECURRING' || pricingModel === 'RECURRING_TIERED') {
      recurringPricing = cloneDeep(pricings);
      if (creatingMode && recurringPricing && recurringPricing.length) {
        recurringPricing = recurringPricing.map((pr, index) => {
          const { tierPricing, ...rest } = pr;
          let newTierPricing = [];
          if (tierPricing && tierPricing.length) {
            newTierPricing = tierPricing.map((tier, idx) => {
              const { id, ...tr } = tier;
              return { ...tr, id: convertIdPOAndDO(name, `recurring${index + 1}_tiers`, idx) };
            });
          }
          return { ...rest, tierPricing: newTierPricing };
        });
      }
    }

    if (pricingModel === 'TRANSACTION_ATTRIBUTE_BASED') {
      txnPricing = {};
      if (creatingMode) {
        txnPricing.id = convertIdPOAndDO(name, 'transaction');
        txnPricing.name = 'USAGE1';
        txnPricing.description = 'USAGE1';
      } else {
        txnPricing.id = item.txnPricing && item.txnPricing[0] ? item.txnPricing[0].id : '';
      }
      txnPricing.txnPrices = pricings;
    }

    if (
      (transactionType === 'USAGE' ||
        transactionType === 'FORWARD_RECURRING' ||
        transactionType === 'ARREARS_RECURRING') &&
      pricingModel === 'COMPLEX'
    ) {
      complexPricing = pricings;
      if (creatingMode && complexPricing && complexPricing.length) {
        complexPricing = complexPricing.map((pr, idx) => {
          const { id, index, ...rest } = pr;
          return { ...rest, index: creatingMode ? idx + 1 : index, id: convertIdPOAndDO(name, 'complex', idx) };
        });
      }
    }
    if (pricingModel === 'USAGE_ATTRIBUTE_BASED') {
      if (pricings && pricings.length) {
        usageAttributePricing = cloneDeep(pricings).map((val, idx) => {
          const { index, ...rest } = val;
          return { ...rest, index: creatingMode ? idx + 1 : index };
        });
      }
    }

    let provisioningNamesConvert = null;
    if (provisioningNames && provisioningNames.length && creatingMode) {
      provisioningNamesConvert = provisioningNames.map(pr => ({
        name: pr.name,
        attributes: pr.attributes || null,
      }));
    }

    if (provisioningNames && provisioningNames.length && !creatingMode) {
      provisioningNamesConvert = supportRemoveIndex({ data: provisioningNames, keyCheck: 'name' });
    }

    const data = {
      id,
      name: name || null,
      description: description || null,
      itemId: itemId || null,
      startDate: startDate || null,
      endDate: endDate || null,
      startDuration: startDuration || startDuration === 0 ? startDuration : null,
      startUnit: startUnit || null,
      endDuration: endDuration || endDuration === 0 ? endDuration : null,
      endUnit: endUnit || null,
      pricingModel: pricingModel || null,
      transactionType: transactionType || null,
      minimumQuantity: minimumQuantity || minimumQuantity === 0 ? minimumQuantity : null,
      maximumQuantity: maximumQuantity || maximumQuantity === 0 ? maximumQuantity : null,
      salesChannel: salesChannel || null,
      marketSegment: marketSegment || null,
      accountType: accountType || null,
      accountSubType: accountSubType || null,
      serviceType: serviceType || null,
      serviceAddOn: serviceAddOn || null,
      status: status || null,
      customerPricing: customerPricing || null,
      recurringPricing: recurringPricing || null,
      flatPricing: flatPricing || null,
      tierPricing: tierPricing || null,
      country: country || null,
      txnPricing: txnPricing || null,
      complexPricing: complexPricing || null,
      usageAttributePricing: usageAttributePricing || null,
      pricingType: pricingType || null,
      billableServiceId: billableServiceId || null,
      provisioningNames: provisioningNamesConvert || null,
      isProvisionable: typeof isProvisionable === 'boolean' ? `${isProvisionable}` : null,
      omsTaskType: omsTaskType || null,
      effectiveDate: effectiveDate || null,
      accountingCode: accountingCode || null,
    };
    if (onSubmit) {
      onSubmit(data, success => {
        this.setState({ wasValidated: !success });
      });
    }
  };

  onChangePricings = pricings => {
    const { pricingModel, transactionType } = this.state;
    const newStates = cloneDeep({ pricings });
    newStates.recurringPricing = null;
    newStates.customerPricing = null;
    newStates.flatPricing = null;
    newStates.txnPrices = null;
    if (pricingModel === 'FLAT') {
      newStates.flatPricing = pricings;
    } else if (pricingModel === 'RECURRING' || transactionType === 'RECURRING_TIERED') {
      newStates.recurringPricing = pricings;
    } else if (pricingModel === 'CUSTOMER_ATTRIBUTE_BASED') {
      newStates.customerPricing = pricings;
    } else if (pricingModel === 'TIERED') {
      newStates.tierPricing = pricings;
    } else if (pricingModel === 'TRANSACTION_ATTRIBUTE_BASED') {
      newStates.txnPrices = pricings;
    } else if ((transactionType === 'USAGE' || transactionType === 'FORWARD_RECURRING') && pricingModel === 'COMPLEX') {
      newStates.complexPricing = pricings;
    }

    this.setState(newStates);
  };

  addNewCustomerPricing = () => {
    // e.preventDefault();
    const { pricings } = this.state;
    const defaultPricing = mapPricingFromItem(this.state);
    const newPricing = defaultPricing && defaultPricing.length > 0 ? defaultPricing[0] : null;
    const prices = newPricing && newPricing.prices ? [...newPricing.prices] : [];
    this.setState({ pricings: [...pricings, { ...newPricing, prices, index: getLastIndex({ data: pricings }) }] });
  };

  addNewUsageAttributeBase = () => {
    const { pricings } = this.state;
    const newPricing = pricings && pricings.length > 0 ? cloneDeep(pricings[0]) : null;
    const prices =
      newPricing && newPricing.prices
        ? [...newPricing.prices].map(val => ({ currencyId: val.currencyId || '', amount: '0' }))
        : [];
    this.setState({ pricings: [...pricings, { ...newPricing, prices, index: getLastIndex({ data: pricings }) }] });
  };

  onAddCurrency = ({ idx, index }) => {
    try {
      const { pricings, pricingModel } = this.state;
      const { creatingMode, defaultCurrency } = this.props;
      let newPricing = cloneDeep(pricings);
      let indexNewItem = 0;
      const data = { currencyId: defaultCurrency || null, amount: '0' };
      if (
        pricingModel === 'FLAT' ||
        pricingModel === 'TRANSACTION_ATTRIBUTE_BASED' ||
        pricingModel === 'CUSTOMER_ATTRIBUTE_BASED'
      ) {
        if (creatingMode) {
          if (!newPricing[index || 0].prices) newPricing[index || 0].prices = [];
          newPricing[index || 0].prices.push(data);
        }
        if (!creatingMode) {
          if (!newPricing[index || 0].prices) newPricing[index || 0].prices = [];
          newPricing[index || 0].prices.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing[index || 0].prices.push(data);
        }
      }

      if (pricingModel === 'USAGE_ATTRIBUTE_BASED') {
        if (creatingMode) {
          newPricing = newPricing.map(val => {
            const { prices, ...rest } = val;
            return { ...rest, prices: prices ? [...prices, data] : [data] };
          });
        }
        if (!creatingMode) {
          if (!newPricing[index || 0].prices) newPricing[index || 0].prices = [];
          newPricing[index || 0].prices.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing = newPricing.map(val => {
            const { prices, ...rest } = val;
            return { ...rest, prices: [...prices, data] };
          });
        }
      }

      if (pricingModel === 'TIERED' || pricingModel === 'COMPLEX') {
        if (creatingMode) {
          if (newPricing[index || 0].tiers) {
            if (!newPricing[index || 0].tiers[idx].prices) newPricing[index || 0].tiers[idx].prices = [];
            newPricing[index || 0].tiers[idx].prices.push(data);
          } else {
            if (!newPricing[index || 0].prices) newPricing[index || 0].prices = [];
            newPricing[index || 0].prices.push(data);
          }
        }
        if (!creatingMode) {
          if (newPricing[index || 0].tiers) {
            if (!newPricing[index || 0].tiers[idx].prices) newPricing[index || 0].tiers[idx].prices = [];
            newPricing[index || 0].tiers[idx].prices.forEach(item => {
              if (item.index > indexNewItem) {
                indexNewItem = item.index;
              }
            });
            data.index = indexNewItem;
            newPricing[index || 0].tiers[idx].prices.push(data);
          } else {
            if (!newPricing[index || 0].prices) newPricing[index || 0].prices = [];
            newPricing[index || 0].prices.forEach(item => {
              if (item.index > indexNewItem) {
                indexNewItem = item.index;
              }
            });
            data.index = indexNewItem;
            newPricing[index || 0].prices.push(data);
          }
        }
      }

      if (pricingModel === 'RECURRING') {
        if (!newPricing[index || 0].prices) newPricing[index || 0].prices = [];
        if (creatingMode) {
          newPricing[index || 0].prices.push(data);
        }
        if (!creatingMode) {
          newPricing[index || 0].prices.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing[index || 0].prices.push(data);
        }
      }
      if (pricingModel === 'RECURRING_TIERED') {
        if (!newPricing[0].tierPricing[0].tiers[idx].prices) newPricing[0].tierPricing[0].tiers[idx].prices = [];
        if (creatingMode) newPricing[0].tierPricing[0].tiers[idx].prices.push(data);
        if (!creatingMode) {
          newPricing[0].tierPricing[0].tiers[idx].prices.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing[0].tierPricing[0].tiers[idx].prices.push(data);
        }
      }

      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log('error', error);
    }
  };

  handleRemoveCurrency = ({ idx, idxPrice, isPricingField, indexPricing, index }) => {
    const { pricings, pricingModel } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    if (pricingModel === 'RECURRING_TIERED') {
      if (creatingMode) {
        newPricing[index || 0].tierPricing[0].tiers[idx].prices.splice(idxPrice, 1);
      } else {
        newPricing[index || 0].tierPricing[0].tiers[idx].prices[idxPrice] = {
          index: newPricing[index || 0].tierPricing[0].tiers[idx].prices[idxPrice].index,
        };
      }
    } else if (!isPricingField) {
      if (creatingMode) {
        newPricing[index || 0].tiers[idx].prices.splice(idxPrice, 1);
      } else {
        newPricing[index || 0].tiers[idx].prices[idxPrice] = {
          index: newPricing[index || 0].tiers[idx].prices[idxPrice].index,
        };
      }
    }
    if (isPricingField) {
      if (creatingMode) {
        newPricing[indexPricing].prices.splice(idxPrice, 1);
      } else {
        newPricing[indexPricing].prices[idxPrice] = {
          index: newPricing[indexPricing].prices[idxPrice].index,
        };
      }
    }

    this.setState({ pricings: newPricing });
  };

  onAddGrants = ({ idx, index, isPricingField, indexPricing }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    if (!isPricingField) {
      if (!newPricing[index || 0].tiers[idx].grants) newPricing[index || 0].tiers[idx].grants = [];
      if (newPricing[index || 0].tiers[idx].grants.length) {
        newPricing[index || 0].tiers[idx].grants.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[index || 0].tiers[idx].grants.push({
          resourceId: '',
          amount: '0',
          grantDuration: 0,
          grantUnit: '',
        });
      } else {
        newPricing[index || 0].tiers[idx].grants.push({
          index: indexNewItem + 1,
          resourceId: '',
          amount: '0',
          grantDuration: 0,
          grantUnit: '',
        });
      }
    }

    if (isPricingField) {
      if (!newPricing[indexPricing].grants) newPricing[indexPricing].grants = [];
      if (newPricing[indexPricing].grants.length) {
        newPricing[indexPricing].grants.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[indexPricing].grants.push({
          resourceId: '',
          amount: '0',
          grantDuration: 0,
          grantUnit: null,
        });
      } else {
        newPricing[indexPricing].grants.push({
          index: indexNewItem + 1,
          resourceId: '',
          amount: '0',
          grantDuration: 0,
          grantUnit: null,
        });
      }
    }

    this.setState({ pricings: newPricing });
  };

  handleRemoveGrants = ({ idxGrant, idx, isPricingField, indexPricing }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    if (!isPricingField) {
      if (creatingMode) {
        newPricing[0].tiers[idx].grants.splice(idxGrant, 1);
      } else {
        newPricing[0].tiers[idx].grants[idxGrant] = {
          index: newPricing[0].tiers[idx].grants[idxGrant].index,
        };
      }
    }

    if (isPricingField) {
      if (creatingMode) {
        newPricing[indexPricing].grants.splice(idxGrant, 1);
      } else {
        newPricing[indexPricing].grants[idxGrant] = {
          index: newPricing[indexPricing].grants[idxGrant].index,
        };
      }
    }

    this.setState({ pricings: newPricing });
  };

  onAddAccumulators = ({ idx, isPricingField, index, indexPricing }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    const newData = {
      accumulatorId: '',
      amount: '0',
      duration: 0,
      unit: null,
    };
    if (!isPricingField) {
      if (!newPricing[index || 0].tiers[idx].accumulators) newPricing[index || 0].tiers[idx].accumulators = [];
      if (newPricing[index || 0].tiers[idx].accumulators.length) {
        newPricing[index || 0].tiers[idx].accumulators.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[index || 0].tiers[idx].accumulators.push(newData);
      } else {
        newPricing[index || 0].tiers[idx].accumulators.push({
          index: indexNewItem + 1,
          ...newData,
        });
      }
    }

    if (isPricingField) {
      if (!newPricing[indexPricing].accumulators) newPricing[indexPricing].accumulators = [];
      if (newPricing[indexPricing].accumulators.length) {
        newPricing[indexPricing].accumulators.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[indexPricing].accumulators.push(newData);
      } else {
        newPricing[indexPricing].accumulators.push({
          index: indexNewItem + 1,
          ...newData,
        });
      }
    }

    this.setState({ pricings: newPricing });
  };

  handleRemoveAccumulators = ({ idxAccumulator, idx, isPricingField, indexPricing }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    if (!isPricingField) {
      if (creatingMode) {
        newPricing[0].tiers[idx].accumulators.splice(idxAccumulator, 1);
      } else {
        newPricing[0].tiers[idx].accumulators[idxAccumulator] = {
          index: newPricing[0].tiers[idx].accumulators[idxAccumulator].index,
          accumulatorId: newPricing[0].tiers[idx].accumulators[idxAccumulator].accumulatorId,
        };
      }
    }

    if (isPricingField) {
      if (creatingMode) {
        newPricing[indexPricing].accumulators.splice(idxAccumulator, 1);
      } else {
        newPricing[indexPricing].accumulators[idxAccumulator] = {
          index: newPricing[indexPricing].accumulators[idxAccumulator].index,
          accumulatorId: newPricing[indexPricing].accumulators[idxAccumulator].accumulatorId,
        };
      }
    }

    this.setState({ pricings: newPricing });
  };

  handleAddTier = ({ isTier, pricingIdx, isComplexUsage }) => {
    const { pricings, pricingModel } = this.state;
    const { creatingMode, defaultCurrency } = this.props;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    const newData = {
      maximum: '0',
      minimum: '0',
      // prices: [],
      grants: [],
      prices: [],
      // grants: [{ resourceId: '', amount: '0', grantDuration: 0, grantUnit: null }],
      // accumulators: [{ accumulatorId: '', amount: '0', duration: 0, unit: null }],
      accumulators: [],
    };

    if (!creatingMode) {
      newData.prices[0] = { ...newData.prices[0], index: 1, currencyId: defaultCurrency || null };
    }
    if (!newPricing[pricingIdx]?.tiers) newPricing[pricingIdx] = { tiers: [] };
    const defaultTier =
      newPricing[pricingIdx] && newPricing[pricingIdx].tiers[0] ? newPricing[pricingIdx].tiers[0] : {};

    if (creatingMode) {
      if (isComplexUsage) {
        if (isTier) {
          if (creatingMode) {
            newPricing[pricingIdx].tiers.push(defaultTier);
          } else {
            newPricing[pricingIdx].tiers.forEach(item => {
              if (item.index > indexNewItem) {
                indexNewItem = item.index;
              }
            });
            defaultTier.index = indexNewItem + 1;
            newPricing[pricingIdx].tiers.push(defaultTier);
          }
        } else {
          newPricing[pricingIdx].tiers.push({
            maximum: '0',
            minimum: '0',
            prices: [],
          });
        }
      } else {
        newPricing[0].tiers.push(newData);
      }
    } else {
      if (newPricing.length) {
        if (pricingModel === 'TIERED' && newPricing[0].tiers) {
          newPricing[0].tiers.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
        } else {
          newPricing.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
        }
      }
      if (isComplexUsage) {
        newPricing[pricingIdx].tiers.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
        defaultTier.index = indexNewItem + 1;
        newPricing[pricingIdx].tiers.push(defaultTier);
      } else {
        newPricing[0].tiers.push({
          index: indexNewItem + 1,
          ...newData,
        });
      }
    }

    this.setState({ pricings: newPricing });
  };

  onRemoveTier = ({ idx, index, indexTierPricing }) => {
    try {
      const { pricings, pricingModel } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (pricingModel === 'RECURRING_TIERED') {
        if (creatingMode) {
          newPricing[index || 0].tierPricing[indexTierPricing].tiers.splice(idx, 1);
        } else {
          newPricing[index || 0].tierPricing[indexTierPricing].tiers[idx] = {
            index: newPricing[index || 0].tierPricing[indexTierPricing].tiers[idx].index,
          };
        }
      } else if (creatingMode) {
        newPricing[index || 0].tiers.splice(idx, 1);
      } else {
        newPricing[index || 0].tiers[idx] = {
          index: newPricing[index || 0].tiers[idx].index,
        };
      }

      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  onUpdateTierItem = (data, idx, index) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    newPricing[index].tiers[idx] = data;
    this.setState({ pricings: newPricing });
  };

  onUpdateDataPricing = ({ data, index }) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    newPricing[index] = data;
    this.setState({ pricings: newPricing });
  };

  onAddSelections = selectField => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (selectField) {
      const lastItem = newPricing.length ? newPricing.length - 1 : 0;

      if (selectField.indexOf(1) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          salesChannel: 'NONE',
          marketSegment: 'NONE',
          accountType: 'NONE',
          accountSubType: 'NONE',
          index: 1,
        };
      }
      if (selectField.indexOf(2) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          index: 1,
          usageAttributePricingData: [
            {
              index: 1,
              attribute: '',
              value: 'NONE',
            },
          ],
        };
      }
      if (selectField.indexOf(3) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          rateUnit: 'NONE',
          timeUnit: 'NONE',
          zoneUnit: 'NONE',
          impactUnit: 'NONE',
          holidayName: 'NONE',
          index: 1,
        };
      }
      if (selectField.indexOf(4) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          tierType: 'PROSPECTIVE',
          tierReference: 'BALANCE',
          accumulatorId: null,
          volumeTreatment: 'ACTUAL',
          evaluationStart: '0',
          evaluationEnd: '0',
          offsetMonths: '0',
          nextPricingTerm: '0',
          // purchaseProration: 'FULL',
          // cancelProration: 'FULL',
          // upgradeProration: 'FULL',
          // downgradeProration: 'FULL',
          index: 1,
          tiers: [
            {
              maximum: '0',
              minimum: '0',
              prices: [],
              grants: [],
              accumulators: [],
            },
          ],
        };
      } else {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          index: 1,
          tierType: null,
          tierReference: null,
          accumulatorId: null,
          volumeTreatment: null,
          evaluationStart: '0',
          evaluationEnd: '0',
          offsetMonths: '0',
          nextPricingTerm: '0',
          // purchaseProration: null,
          // cancelProration: null,
          // upgradeProration: null,
          // downgradeProration: null,
          prices: [],
          grants: [],
          accumulators: [],
          tiers: null,
        };
      }
    }
    return this.setState({ pricings: newPricing, complexPricing: newPricing });
  };

  onCopyOldComplexUsagePricing = index => {
    try {
      const { pricings } = this.state;
      const newPricing = cloneDeep(pricings);
      const newData = cloneDeep(newPricing[index]);
      let indexNewItem = 0;
      newData.id = `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`;
      newPricing.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
      newData.index = indexNewItem + 1;
      newPricing.push(newData);
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  onAddAccumulatorsWithOtherType = ({ index }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    const newData = {
      accumulatorId: '',
      amount: '0',
      duration: 0,
      unit: null,
    };
    if (!newPricing[index].accumulators) newPricing[index].accumulators = [];
    if (newPricing[index].accumulators && newPricing[index].accumulators.length) {
      newPricing[index].accumulators.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    if (creatingMode) {
      newPricing[index].accumulators.push(newData);
    } else {
      newPricing[index].accumulators.push({
        index: indexNewItem + 1,
        ...newData,
      });
    }
    this.setState({ pricings: newPricing });
  };

  onAddGrantWithOtherType = ({ index }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = pricings;
    let indexNewItem = 0;
    const dataNew = {
      resourceId: '',
      amount: '0',
      grantDuration: 0,
      grantUnit: '',
    };

    if (!newPricing[index].grants) newPricing[index].grants = [];
    if (newPricing[index].grants.length) {
      newPricing[index].grants.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    if (creatingMode) {
      newPricing[index].grants.push(dataNew);
    } else {
      dataNew.index = indexNewItem + 1;
      newPricing[index].grants.push(dataNew);
    }
    this.setState({ pricings: newPricing });
  };

  onChangeGrantWithOtherType = ({ name, value, idx, index }) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (newPricing && newPricing[index || 0].grants && newPricing[index || 0].grants[idx])
      newPricing[index || 0].grants[idx][name] = value;
    this.setState({ pricings: newPricing });
  };

  onChangeAccumulatorsWithOtherType = ({ name, value, idx, index }) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (newPricing && newPricing[index || 0].accumulators && newPricing[index || 0].accumulators[idx])
      newPricing[index || 0].accumulators[idx][name] = value;
    this.setState({ pricings: newPricing });
  };

  handleRemoveAccumulatorsWithOtherType = ({ index, idx }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (newPricing && newPricing[index || 0].accumulators && newPricing[0].accumulators[idx])
      if (creatingMode) {
        newPricing[index || 0].accumulators.splice(idx, 1);
      } else {
        newPricing[index || 0].accumulators[idx] = {
          index: newPricing[index || 0].accumulators[idx].index,
        };
      }

    this.setState({ pricings: newPricing });
  };

  handleRemoveGrantsWithOtherType = ({ index, idx }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (newPricing && newPricing[index || 0].grants && newPricing[index || 0].grants[idx])
      if (creatingMode) {
        newPricing[index || 0].grants.splice(idx, 1);
      } else {
        newPricing[index || 0].grants[idx] = {
          index: newPricing[index || 0].grants[idx].index,
        };
      }

    this.setState({ pricings: newPricing });
  };

  onAddGrantsRecurring = ({ idx, indexTierPricing }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;

    try {
      if (
        newPricing[0].tierPricing &&
        newPricing[0].tierPricing[indexTierPricing] &&
        newPricing[0].tierPricing[indexTierPricing].tiers &&
        newPricing[0].tierPricing[indexTierPricing].tiers.length &&
        !newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants
      ) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants = [];
      }
      if (
        newPricing[0].tierPricing &&
        newPricing[0].tierPricing[indexTierPricing] &&
        newPricing[0].tierPricing[indexTierPricing].tiers &&
        newPricing[0].tierPricing[indexTierPricing].tiers.length &&
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants
      ) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants.push({
          resourceId: '',
          amount: '0',
          grantDuration: 0,
          grantUnit: '',
        });
      } else {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants.push({
          index: indexNewItem + 1,
          resourceId: '',
          amount: '0',
          grantDuration: 0,
          grantUnit: '',
        });
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log('error', error);
    }
  };

  onAddAccumulatorsRecurring = ({ idx, indexTierPricing }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;

    try {
      if (
        newPricing[0].tierPricing &&
        newPricing[0].tierPricing[indexTierPricing] &&
        newPricing[0].tierPricing[indexTierPricing].tiers &&
        newPricing[0].tierPricing[indexTierPricing].tiers.length &&
        !newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators
      ) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators = [];
      }
      if (
        newPricing[0].tierPricing &&
        newPricing[0].tierPricing[indexTierPricing] &&
        newPricing[0].tierPricing[indexTierPricing].tiers &&
        newPricing[0].tierPricing[indexTierPricing].tiers.length &&
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators
      ) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators.push({
          accumulatorId: '',
          amount: '0',
          duration: 0,
          unit: null,
        });
      } else {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators.push({
          index: indexNewItem + 1,
          accumulatorId: '',
          amount: '0',
          duration: 0,
          unit: null,
        });
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log('error', error);
    }
  };

  handleRemoveGrantsRecurring = ({ idxGrant, idx, indexTierPricing }) => {
    try {
      const { pricings } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (creatingMode) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants.splice(idxGrant, 1);
      } else {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants[idxGrant] = {
          index: newPricing[0].tierPricing[indexTierPricing].tiers[idx].grants[idxGrant].index,
        };
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  handleRemoveAccumulatorsRecurring = ({ idxAccumulator, idx, indexTierPricing }) => {
    try {
      const { pricings } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (creatingMode) {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators.splice(idxAccumulator, 1);
      } else {
        newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators[idxAccumulator] = {
          index: newPricing[0].tierPricing[indexTierPricing].tiers[idx].accumulators[idxAccumulator].index,
        };
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  handleAddTierRecurring = ({ indexTierPricing }) => {
    try {
      const { pricings } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      let indexNewItem = 0;
      const newData = {
        maximum: '0',
        minimum: '0',
        grants: [],
        prices: [],
        accumulators: [],
      };
      if (!newPricing[0]?.tierPricing[indexTierPricing]?.tiers) newPricing[0].tierPricing[indexTierPricing].tiers = [];
      if (creatingMode) {
        newPricing[0].tierPricing[indexTierPricing].tiers.push(newData);
      } else {
        newPricing[0].tierPricing[indexTierPricing].tiers.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
        const newDataWithIndexPrices = cloneDeep(newData);
        // newDataWithIndexPrices.prices[0].index = 1;
        newPricing[0].tierPricing[indexTierPricing].tiers.push({
          index: indexNewItem + 1,
          ...newDataWithIndexPrices,
        });
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  handleRemovCurrencyWithOtherType = ({ idx, index }) => {
    try {
      const { pricings, pricingModel } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (
        pricingModel === 'FLAT' ||
        pricingModel === 'CUSTOMER_ATTRIBUTE_BASED' ||
        pricingModel === 'USAGE_ATTRIBUTE_BASED'
      ) {
        if (creatingMode) {
          newPricing[index].prices.splice(idx, 1);
        } else {
          newPricing[index].prices.splice(idx, 1);
          newPricing[index].prices[idx] = {
            index: newPricing[index].prices[idx].index,
          };
        }
      }

      if (pricingModel === 'RECURRING_TIERED' || pricingModel === 'RECURRING') {
        if (creatingMode) {
          newPricing[index].prices.splice(idx, 1);
        } else {
          newPricing[index].prices.splice(idx, 1);
          newPricing[index].prices[idx] = {
            index: newPricing[index].prices[idx].index,
          };
        }
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeRemoveSelection = value => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    const lastItem = newPricing.length - 1;
    if (value === 1) {
      delete newPricing[lastItem].salesChannel;
      delete newPricing[lastItem].marketSegment;
      delete newPricing[lastItem].accountType;
      delete newPricing[lastItem].accountSubType;
    }
    if (value === 2) {
      delete newPricing[lastItem].usageAttributePricingData;
    }
    if (value === 3) {
      delete newPricing[lastItem].rateUnit;
      delete newPricing[lastItem].timeUnit;
      delete newPricing[lastItem].zoneUnit;
      delete newPricing[lastItem].impactUnit;
      delete newPricing[lastItem].holidayName;
    }
    if (value === 4) {
      delete newPricing[lastItem].tierType;
      delete newPricing[lastItem].tierReference;
      delete newPricing[lastItem].accumulatorId;
      delete newPricing[lastItem].volumeTreatment;
      delete newPricing[lastItem].evaluationStart;
      delete newPricing[lastItem].evaluationEnd;
      delete newPricing[lastItem].offsetMonths;
      delete newPricing[lastItem].nextPricingTerm;
      delete newPricing[lastItem].purchaseProration;
      delete newPricing[lastItem].cancelProration;
      delete newPricing[lastItem].upgradeProration;
      delete newPricing[lastItem].downgradeProration;
      newPricing[lastItem].tierType = null;
      newPricing[lastItem].tierReference = null;
      newPricing[lastItem].accumulatorId = null;
      newPricing[lastItem].volumeTreatment = null;
      newPricing[lastItem].evaluationStart = '0';
      newPricing[lastItem].evaluationEnd = '0';
      newPricing[lastItem].offsetMonths = '0';
      newPricing[lastItem].nextPricingTerm = '0';
      newPricing[lastItem].purchaseProration = null;
      newPricing[lastItem].cancelProration = null;
      newPricing[lastItem].upgradeProration = null;
      newPricing[lastItem].downgradeProration = null;
      newPricing[lastItem].prices = [];
      newPricing[lastItem].grants = [];
      newPricing[lastItem].accumulators = [];
      newPricing[lastItem].tiers = null;
    }

    return this.setState({ pricings: newPricing, complexPricing: newPricing });
  };

  onChangeAddSelection = (value, selectField) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    const lastItem = newPricing.length - 1;
    if (value === 1) {
      newPricing[lastItem] = {
        ...newPricing[lastItem],
        salesChannel: 'NONE',
        marketSegment: 'NONE',
        accountType: 'NONE',
        accountSubType: 'NONE',
      };
    }
    if (value === 2) {
      newPricing[lastItem] = {
        ...newPricing[lastItem],
        usageAttributePricingData: [
          {
            index: 1,
            attribute: '',
            value: 'NONE',
          },
        ],
      };
    }
    if (value === 3) {
      newPricing[lastItem] = {
        ...newPricing[lastItem],
        rateUnit: 'NONE',
        timeUnit: 'NONE',
        zoneUnit: 'NONE',
        impactUnit: 'NONE',
        holidayName: 'NONE',
      };
    }

    if (selectField.indexOf(4) > -1) {
      newPricing[lastItem].tierType = 'PROSPECTIVE';
      newPricing[lastItem].tierReference = 'BALANCE';
      newPricing[lastItem].accumulatorId = null;
      newPricing[lastItem].volumeTreatment = 'ACTUAL';
      newPricing[lastItem].evaluationStart = '0';
      newPricing[lastItem].evaluationEnd = '0';
      newPricing[lastItem].offsetMonths = '0';
      newPricing[lastItem].nextPricingTerm = '0';
      newPricing[lastItem].prices = null;
      newPricing[lastItem].grants = null;
      newPricing[lastItem].accumulators = null;
      // newPricing[lastItem].purchaseProration = 'FULL';
      // newPricing[lastItem].cancelProration = 'FULL';
      // newPricing[lastItem].upgradeProration = 'FULL';
      // newPricing[lastItem].downgradeProration = 'FULL';
      newPricing[lastItem].tiers = [
        {
          maximum: '0',
          minimum: '0',
          prices: [],
          grants: [],
          accumulators: [],
        },
      ];
    } else {
      newPricing[lastItem].tierType = null;
      newPricing[lastItem].tierReference = null;
      newPricing[lastItem].accumulatorId = null;
      newPricing[lastItem].volumeTreatment = null;
      newPricing[lastItem].evaluationStart = '0';
      newPricing[lastItem].evaluationEnd = '0';
      newPricing[lastItem].offsetMonths = '0';
      newPricing[lastItem].nextPricingTerm = '0';
      // newPricing[lastItem].purchaseProration = null;
      // newPricing[lastItem].cancelProration = null;
      // newPricing[lastItem].upgradeProration = null;
      // newPricing[lastItem].downgradeProration = null;
      newPricing[lastItem].prices = [];
      newPricing[lastItem].grants = [];
      newPricing[lastItem].accumulators = [];
      newPricing[lastItem].tiers = null;
    }

    return this.setState({ pricings: newPricing, complexPricing: newPricing });
  };

  onChangeRecurringComplex = ({ name, value }) => {
    try {
      const { pricings } = this.state;
      const newPricing = cloneDeep(pricings);
      newPricing.forEach((val, idx) => {
        const newItem = cloneDeep(val);
        newItem[name] = value;
        newPricing[idx] = newItem;
        this.setState({ pricings: newPricing, complexPricing: newPricing });
      });
    } catch (error) {
      console.log(error);
    }
  };

  onAddProvisioningName = () => {
    this.setState({
      isOpenModalProvisioningName: true,
      isMultipleSelectProvisioningName: true,
      itemProvisioningNameSelected: null,
    });
    // let { provisioningNames } = this.state;
    // if (!provisioningNames) provisioningNames = [];
    // provisioningNames = [...provisioningNames, { name: null, index: getLastIndex({ data: provisioningNames }) }];
    // this.setState({ provisioningNames: [...provisioningNames] });
  };

  onRemoveItem = ({ index, item }) => {
    const { provisioningNames } = this.state;
    const { creatingMode } = this.props;
    if (creatingMode || item?.isNew) {
      provisioningNames.splice(index, 1);
    } else {
      provisioningNames[index] = { index: provisioningNames[index].index };
    }
    this.setState({ provisioningNames: [...provisioningNames] });
  };

  onChangeTableProvisioningName = ({ index, name, value }) => {
    try {
      const { provisioningNames, activeSubTab } = this.state;
      provisioningNames[activeSubTab.index].attributes[index][name] = value;
      this.setState({ provisioningNames: [...provisioningNames] });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTable = ({ name, value, index }) => {
    const { provisioningNames } = this.state;
    const newData = cloneDeep(provisioningNames);
    newData[index][name] = value;
    this.setState({ provisioningNames: newData });
  };

  onToggleModalProvisioningName = ({ isMultiple, itemProvisioningNameSelected, index }) => {
    this.setState({
      isOpenModalProvisioningName: true,
      isMultipleSelectProvisioningName: isMultiple,
      itemProvisioningNameSelected,
      indexProvisioningNameSelected: index,
    });
  };

  onSelectProvisioningName = id => {
    const { provisioningNames, indexProvisioningNameSelected } = this.state;
    if (id) provisioningNames[indexProvisioningNameSelected].name = id;
    this.setState({
      provisioningNames: [...provisioningNames],
      isOpenModalProvisioningName: false,
      isMultipleSelectProvisioningName: false,
      itemProvisioningNameSelected: null,
      indexProvisioningNameSelected: null,
    });
  };

  onSelectMultipleProvisioningName = (ids, rows) => {
    const { provisioningNames } = this.state;
    let listItem = [];
    if (ids && ids.length) {
      listItem = rows.map((row, index) => ({
        name: row.name,
        index: getLastIndex({ data: provisioningNames }) + index,
        isNew: true,
        attributes: row.attributesList
          ? row.attributesList.map((val, idx) => ({
              name: val.name || null,
              value: val.value || null,
              index: idx + 1,
            }))
          : null,
      }));
    }
    this.setState({
      provisioningNames: provisioningNames ? [...provisioningNames, ...listItem] : [...listItem],
      isOpenModalProvisioningName: false,
      isMultipleSelectProvisioningName: false,
      itemProvisioningNameSelected: null,
      indexProvisioningNameSelected: null,
    });
  };

  onCancelData = () => {
    this.setState({
      id: null,
      name: null,
      description: null,
      itemId: null,
      startDate: null,
      endDate: null,
      startDuration: null,
      startUnit: null,
      endDuration: null,
      endUnit: null,
      pricingModel: null,
      transactionType: null,
      minimumQuantity: null,
      maximumQuantity: null,
      salesChannel: null,
      marketSegment: null,
      accountType: null,
      accountSubType: null,
      serviceType: null,
      serviceAddOn: null,
      status: null,
      country: null,
      pricings: null,
      billableServiceId: null,
      pricingType: null,
      effectiveDate: null,
    });
  };

  openModalConfigName = name => {
    if (name === 'provisioningName') {
      const { provisioningNames, provisioningNameDefault, isFetchProvisioningName } = this.state;
      const { searchProvisioningAttributesConfigItem } = this.props;
      if (!isFetchProvisioningName && (!provisioningNameDefault || !provisioningNameDefault.length)) {
        searchProvisioningAttributesConfigItem({ page: 1, size: 500 }, ({ success, data }) => {
          if (success) this.setState({ provisioningNameDefault: data });
        });
      }
      this.setState({
        openModalConfigName: name,
        oldProvisioningNames: cloneDeep(provisioningNames),
        activeSubTab: {},
      });
    }
    return this.setState({ openModalConfigName: name, activeSubTab: {} });
  };

  onToggleModalConfigName = () => {
    const { provisioningNames } = this.state;
    this.setState({ openModalConfigName: null, oldProvisioningNames: cloneDeep(provisioningNames) });
  };

  onCancelModalConfigName = () => {
    const { oldProvisioningNames, provisioningNames } = this.state;
    this.setState({
      openModalConfigName: null,
      provisioningNames: oldProvisioningNames || provisioningNames,
      oldProvisioningNames: null,
    });
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem) this.setState({ activeSubTab: {} });
    if (activeSubTab.index !== indexItem) {
      this.setState({ activeSubTab: { key, index: indexItem } });
    }
  };

  render() {
    const {
      creatingMode,
      isSubmitting,
      modeModifyPriceOffer,
      modeApprovePriceOffer,
      modeSellablePriceOffer,
      modeNonSellablePriceOffer,
      modeSunsetPriceOffer,
      optionsAccumulatorId,
      optionsGrantId,
      t,
      usageContainerRecordList,
      isShowBilledService,
      onCancel,
      getAuditData,
      id,
      convertAuditJsonData,
      title,
      getConfigUsageTypeConfig,
    } = this.props;
    const {
      wasValidated,
      pricings,
      pricingModel,
      isSelectingItemId,
      parentItemId,
      item,
      transactionType,
      isOpenModalBilledService,
      effectiveDate,
      provisioningNames,
      isOpenModalProvisioningName,
      isMultipleSelectProvisioningName,
      itemProvisioningNameSelected,
      openModalConfigName,
      omsTaskType,
      activeSubTab,
      provisioningNameDefault,
      serviceType,
    } = this.state;
    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;
    if (dataCopy2Create.name) delete dataCopy2Create.name;
    if (dataCopy2Create.description) delete dataCopy2Create.description;

    let newStatusOptionsWithPermission = cloneDeep(t('selections:statusPricing')());
    if (modeApprovePriceOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'APPROVED');
    if (modeSellablePriceOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SELLABLE');
    if (modeNonSellablePriceOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'NON_SELLABLE');
    if (modeSunsetPriceOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SUNSET');

    if (modeApprovePriceOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'APPROVED')
      ].isDisabled = true;
    if (modeSellablePriceOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SELLABLE')
      ].isDisabled = true;
    if (modeNonSellablePriceOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'NON_SELLABLE')
      ].isDisabled = true;
    if (modeSunsetPriceOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SUNSET')
      ].isDisabled = true;

    const tabelColumnProvisioningNames = [
      {
        name: 'name',
        label: 'label.provisioningName',
        style: { width: '280px' },
        required: true,
        // render: (name, item, idx) => {
        //   return (
        //     <GenericInput
        //       value={item.name || ''}
        //       name="name"
        //       onChange={({ name, value }) => this.onChangeTable({ index: idx, name, value })}
        //       wrapperClass="col-md-12"
        //       required
        //       readOnly
        //       // onClick={() => this.onToggleModalProvisioningName({ item, index: idx })}
        //       // fa="fa-external-link"
        //     />
        //   );
        // },
      },
      {
        name: 'viewLov ',
        label: 'label.attributes',
        render: (colName, item, idx) => {
          return (
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={() => this.onToggleSubTab(idx, item, idx, 'provisioningName')}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'provisioningName' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          );
        },
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item, idx) => {
          return (
            <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: idx, item })}>
              <i className="fa fa-trash" />
            </button>
          );
        },
      },
    ];

    const attributesColumns = [
      {
        label: 'label.name',
        name: 'name',
      },
      {
        label: 'label.value',
        name: 'value',
        style: { minWidth: '200px' },
        render: (name, item, idx) => {
          let provisioningNameDefaultItem = null;
          let attributeListSelect = null;
          if (
            provisioningNames &&
            provisioningNames.length &&
            provisioningNames[activeSubTab.index] &&
            provisioningNames[activeSubTab.index] &&
            provisioningNames[activeSubTab.index].name
          ) {
            provisioningNameDefaultItem = provisioningNameDefault.find(
              pr => pr.name === provisioningNames[activeSubTab.index].name
            );
            if (
              provisioningNameDefaultItem &&
              provisioningNameDefaultItem &&
              provisioningNameDefaultItem.attributesList
            ) {
              attributeListSelect = provisioningNameDefaultItem.attributesList.find(i => i.name === item.name);
            }
          }
          return (
            <GenericInput
              value={item.value}
              name="value"
              onChange={({ name, value }) => this.onChangeTableProvisioningName({ index: idx, name, value })}
              wrapperClass="col-md-12"
              type={attributeListSelect && attributeListSelect.attributesLovs ? 'select' : 'text'}
              options={
                attributeListSelect && attributeListSelect.attributesLovs
                  ? attributeListSelect.attributesLovs.map(val => ({
                      label: val.code,
                      value: val.code,
                    }))
                  : null
              }
              menuPortalTarget
            />
          );
        },
      },
    ];

    let listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
      },
      {
        label: 'common:label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'common:label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        type: 'textarea',
        required: true,
      },
      {
        name: 'omsTaskType',
        label: 'common:label.type',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:omsTaskType',
        isClearable: true,
      },
      {
        name: 'pricingType',
        label: 'common:label.pricingType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:pricingType',
        isClearable: true,
        type: 'select',
        disabled: !creatingMode,
      },
      {
        label: 'common:label.billableServiceSelect',
        wrapperClass: 'col-md-4',
        name: 'billableServiceId',
        required: true,
        type: 'popup',
        open: () => {
          this.setState({ isOpenModalBilledService: true });
        },
      },
      {
        label: 'common:label.itemId',
        wrapperClass: 'col-md-4',
        name: 'itemId',
        required: true,
        type: 'popup',
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:serviceType',
        isClearable: true,
        required: true,
      },
      {
        name: 'pricingModel',
        label: 'common:label.pricingModel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:pricingModel',
        isClearable: true,
        required: true,
        disabled: creatingMode && omsTaskType === 'PROVISIONING_ONLY',
      },
      {
        name: 'transactionType',
        label: 'common:label.transactionType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:transactionType',
        required: true,
        isClearable: true,
        disabled: (creatingMode && omsTaskType === 'PROVISIONING_ONLY') || !creatingMode,
      },
      {
        name: 'status',
        label: 'common:label.status',
        wrapperClass: 'col-md-4',
        type: 'select',
        options: newStatusOptionsWithPermission,
        isClearable: true,
      },
      {
        label: 'common:label.effectiveDate',
        wrapperClass: 'col-md-4',
        name: 'effectiveDate',
        type: 'date',
        required: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.startUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'startUnit',
        tOptions: 'selections:timeUnit',
      },
      {
        label: 'common:label.startDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'startDuration',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.endUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'endUnit',
        tOptions: 'selections:timeUnit',
      },
      {
        label: 'common:label.endDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'endDuration',
      },
      {
        label: 'common:label.minimumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'minimumQuantity',
      },
      {
        label: 'common:label.maximumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'maximumQuantity',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:salesChannel',
        isClearable: true,
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:marketSegment',
        isClearable: true,
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountType',
        isClearable: true,
      },
      {
        name: 'accountSubType',
        label: 'common:label.accountSubType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountSubType',
        isClearable: true,
      },
      {
        name: 'country',
        label: 'common:label.country',
        wrapperClass: 'col-md-4',
        options: countryList,
        isClearable: true,
        type: 'select',
      },
      {
        label: 'common:label.accountingCode',
        wrapperClass: 'col-md-4',
        name: 'accountingCode',
      },
      {
        label: 'common:label.serviceAddOn',
        wrapperClass: 'col-md-4',
        name: 'serviceAddOn',
      },
      {
        label: 'common:label.externalId',
        wrapperClass: 'col-md-4',
        name: 'externalId',
      },
      {
        label: 'common:label.externalName',
        wrapperClass: 'col-md-4',
        name: 'externalName',
      },
      {
        label: 'common:label.isProvisionable',
        wrapperClass: 'col-md-4 mt-4 pt-2',
        name: 'isProvisionable',
        type: 'checkbox',
        disabled: omsTaskType === 'BILLING_ONLY',
      },
    ];

    if (!isShowBilledService) {
      listInputs = listInputs.filter(val => val.name !== 'billableServiceId');
    }

    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block w-100">
              <h5 className="card-title">
                {t(title)}
                <Tooltip
                  trigger={['hover']}
                  placement="bottom"
                  overlay={t('label.provisioningNames')}
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                >
                  <button
                    type="button"
                    onClick={() => this.openModalConfigName('provisioningName')}
                    className="button x-small mr-2 btn-config-item ml-5"
                    disabled={omsTaskType === 'BILLING_ONLY'}
                  >
                    P
                  </button>
                </Tooltip>
                {!creatingMode && (
                  <Tooltip
                    trigger={['hover']}
                    placement="bottom"
                    overlay={t('label.auditHistory')}
                    arrowContent={<div className="rc-tooltip-arrow-inner" />}
                  >
                    <button
                      type="button"
                      onClick={() => this.openModalConfigName('auditHistory')}
                      className="button x-small mr-2 btn-config-item"
                    >
                      A
                    </button>
                  </Tooltip>
                )}
              </h5>
            </div>
            <div>
              <form
                onSubmit={this.handleSubmit}
                className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                noValidate
                ref={this.formRef}
              >
                <ModalWithItem
                  isOpen={!!openModalConfigName}
                  onToggle={this.onCancelModalConfigName}
                  modalTitle={
                    openModalConfigName === 'provisioningName' ? t('label.provisioningNames') : t('label.auditHistory')
                  }
                >
                  {openModalConfigName === 'auditHistory' && (
                    <button
                      onClick={this.onCancelModalConfigName}
                      type="button"
                      className="button button-border black x-small mr-2 mb-2"
                    >
                      {t('label.cancel')}
                    </button>
                  )}
                  {openModalConfigName === 'auditHistory' && (
                    <AuditHistoryForm
                      getAuditData={getAuditData}
                      id={id}
                      convertAuditJsonData={convertAuditJsonData}
                      optionsAccumulatorId={optionsAccumulatorId}
                      optionsGrantId={optionsGrantId}
                      usageContainerRecordList={usageContainerRecordList}
                      isShowBilledService={isShowBilledService}
                      isOpenItem={openModalConfigName === 'auditHistory'}
                    />
                  )}
                  {openModalConfigName === 'provisioningName' && (
                    <div className="group-collapsible">
                      <CollapsibleTable
                        columns={tabelColumnProvisioningNames}
                        data={provisioningNames && provisioningNames.length ? provisioningNames : []}
                        isSupportRemoveIndex
                        isViewOnly
                        isNoNeedFixedHeader
                        indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
                      >
                        <DataTable
                          columns={attributesColumns}
                          data={
                            provisioningNames &&
                            provisioningNames.length &&
                            provisioningNames[activeSubTab.index] &&
                            provisioningNames[activeSubTab.index].attributes
                              ? provisioningNames[activeSubTab.index].attributes
                              : []
                          }
                        />
                      </CollapsibleTable>
                      <div className="mt-3 mb-3 ml-3">
                        <button
                          type="button"
                          className="button button-border black x-small"
                          onClick={this.onAddProvisioningName}
                        >
                          +
                          {t('common:label.addProvisioningName')}
                        </button>
                      </div>
                    </div>
                  )}
                  {openModalConfigName === 'provisioningName' && (
                    <button onClick={this.onToggleModalConfigName} type="button" className="button x-small float-right">
                      {t('label.apply')}
                    </button>
                  )}
                  {openModalConfigName !== 'auditHistory' && (
                    <button
                      onClick={this.onCancelModalConfigName}
                      type="button"
                      className="button button-border black x-small float-right mr-2"
                    >
                      {t('label.cancel')}
                    </button>
                  )}
                </ModalWithItem>
                <div className="form-row">
                  {listInputs.map(input => (
                    <GenericInput
                      {...input}
                      key={input.name}
                      value={this.state[input.name]}
                      onChange={this.handleInputChange}
                      disabled={(!creatingMode && input.name === 'pricingModel') || input.disabled}
                      fa={input.name === 'itemId' || input.name === 'billableServiceId' ? 'fa-external-link' : ''}
                      onClick={input.name === 'itemId' ? this.onOpenParentSelector : input.open || (e => {})}
                    />
                  ))}

                  <div className="col-md-12">
                    <PricingSection
                      pricings={cloneDeep(pricings)}
                      pricingModel={pricingModel}
                      onChangePricings={this.onChangePricings}
                      creatingMode={creatingMode}
                      onAddCurrency={this.onAddCurrency}
                      onAddGrants={this.onAddGrants}
                      onAddAccumulators={this.onAddAccumulators}
                      handleRemovePrice={this.handleRemoveCurrency}
                      handleRemoveGrants={this.handleRemoveGrants}
                      handleRemoveAccumulators={this.handleRemoveAccumulators}
                      handleAddTier={this.handleAddTier}
                      optionsAccumulatorId={optionsAccumulatorId}
                      onRemoveTier={this.onRemoveTier}
                      onUpdateTierItem={this.onUpdateTierItem}
                      optionsGrantId={optionsGrantId}
                      onUpdateDataPricing={this.onUpdateDataPricing}
                      transactionType={transactionType}
                      onAddSelections={this.onAddSelections}
                      onCopyOldComplexUsagePricing={this.onCopyOldComplexUsagePricing}
                      onAddAccumulatorsWithOtherType={this.onAddAccumulatorsWithOtherType}
                      onAddGrantWithOtherType={this.onAddGrantWithOtherType}
                      onChangeGrantWithOtherType={this.onChangeGrantWithOtherType}
                      onChangeAccumulatorsWithOtherType={this.onChangeAccumulatorsWithOtherType}
                      handleRemoveAccumulatorsWithOtherType={this.handleRemoveAccumulatorsWithOtherType}
                      handleRemoveGrantsWithOtherType={this.handleRemoveGrantsWithOtherType}
                      onAddGrantsRecurring={this.onAddGrantsRecurring}
                      onAddAccumulatorsRecurring={this.onAddAccumulatorsRecurring}
                      handleRemoveGrantsRecurring={this.handleRemoveGrantsRecurring}
                      handleRemoveAccumulatorsRecurring={this.handleRemoveAccumulatorsRecurring}
                      handleAddTierRecurring={this.handleAddTierRecurring}
                      handleRemovCurrencyWithOtherType={this.handleRemovCurrencyWithOtherType}
                      usageContainerRecordList={usageContainerRecordList}
                      addNewCustomerPricing={this.addNewCustomerPricing}
                      addNewUsageAttributeBase={this.addNewUsageAttributeBase}
                      onChangeRemoveSelection={this.onChangeRemoveSelection}
                      onChangeAddSelection={this.onChangeAddSelection}
                      onChangeRecurringComplex={this.onChangeRecurringComplex}
                      getConfigUsageTypeConfig={getConfigUsageTypeConfig}
                      serviceType={serviceType}
                    />
                  </div>

                  <div className="form-group col-md-12 action-buttons">
                    <label htmlFor="validationCustom01" />
                    <br />
                    {isSubmitting && (
                      <button type="button" className="button button-border x-small float-right">
                        {t('label.saving')}
                      </button>
                    )}
                    {!creatingMode && !isSubmitting && modeModifyPriceOffer === 2 && (
                      <Link
                        to={{
                          pathname: RouteNames.pricingPriceOfferAdd.path,
                          state: { ...dataCopy2Create, effectiveDate },
                        }}
                        className="button button-border gray x-small float-right"
                      >
                        {t('label.copyToCreate')}
                      </Link>
                    )}
                    {!creatingMode && !isSubmitting && modeModifyPriceOffer === 2 && (
                      <button type="submit" className="button button-border x-small float-right">
                        {t('label.modify')}
                      </button>
                    )}
                    {creatingMode && !isSubmitting && (
                      <button type="submit" className="button button-border x-small float-right">
                        {t('label.create')}
                      </button>
                    )}
                    {onCancel ? (
                      <button
                        onClick={onCancel}
                        type="button"
                        className="button button-border black x-small  float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    ) : (
                      <button
                        onClick={this.onCancelData}
                        type="button"
                        className="button button-border black x-small  float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <ModalSelectItemId
                isOpen={isSelectingItemId}
                selectedParentId={parentItemId}
                onSelect={this.onSelectItemId}
                onCancel={this.onCancelSelector}
                toggleModal={this.onCancelSelector}
                defaultSearchForm={{ type: 'REVENUE' }}
              />
              <ModalSearchItemServices
                isOpen={isOpenModalBilledService}
                onSelect={id => {
                  this.setState({ billableServiceId: id, isOpenModalBilledService: false });
                }}
                onCancel={() => {
                  this.setState({ isOpenModalBilledService: false });
                }}
                isChangeItem
                selectedParentId={this.state && this.state.billableServiceId ? this.state.billableServiceId : ''}
              />

              <ModalSelectProvisioningName
                isOpen={isOpenModalProvisioningName}
                onSelect={
                  isMultipleSelectProvisioningName
                    ? this.onSelectMultipleProvisioningName
                    : this.onSelectProvisioningName
                }
                onCancel={() => {
                  this.setState({ isOpenModalProvisioningName: false, itemProvisioningNameSelected: null });
                }}
                isMultipleSelector={isMultipleSelectProvisioningName}
                selectedParentId={itemProvisioningNameSelected ? itemProvisioningNameSelected.name : null}
                isNoAttributes
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PricingOfferForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  modeModifyPriceOffer: PropTypes.number,
  modeApprovePriceOffer: PropTypes.number,
  modeSellablePriceOffer: PropTypes.number,
  modeNonSellablePriceOffer: PropTypes.number,
  modeSunsetPriceOffer: PropTypes.number,
  getPriceOfferAudit: PropTypes.func.isRequired,
  id: PropTypes.string,
  defaultCurrency: PropTypes.string,
};

PricingOfferForm.defaultProps = {
  onSubmit: () => {},
  item: {},
  isSubmitting: false,
  creatingMode: true,
  modeModifyPriceOffer: 0,
  modeApprovePriceOffer: 2,
  modeSellablePriceOffer: 2,
  modeNonSellablePriceOffer: 2,
  modeSunsetPriceOffer: 2,
  id: '',
  defaultCurrency: null,
};

export default withTranslation(['common'])(PricingOfferForm);
