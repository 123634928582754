import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getRegR00105Report = `getRegR00105Report(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRegR00105Report} {
        c601
        c602
        c003
        c006
        c101
        c102
        c103
        r0010501
      }
    }
  `;
};
