import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TitleFrom from '../../../../components/common/TitleFrom';
import { GenericInput } from '../../../../components/common';
import { getTenantFinanceGateway } from '../../actions';
import { makeTenantMerchantDataConfig } from '../../selectors';

class FinanceGatewayAttributes extends React.PureComponent {
  state = { tenantData: [{}] };

  componentDidMount() {
    const { data } = this.props;
    this.doGetTenantFinanceGateway();
    this.setState({ tenantData: data?.tenantMerchantAccounts || [], idState: data?.id || null });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        tenantData: nextProps?.data?.tenantMerchantAccounts || [],
        idState: nextProps?.data?.id || null,
      });
    }
  }

  doGetTenantFinanceGateway = () => {
    const { getTenantFinanceGateway } = this.props;
    getTenantFinanceGateway();
  };

  onChange = ({ value, name, indexAttr, indexData }) => {
    try {
      const { tenantData } = this.state;
      const newData = cloneDeep(tenantData);
      newData[indexData].financeGatewayAttributes[indexAttr][name] = value;
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewMapping = ({ indexData }) => {
    try {
      const { tenantData } = this.state;
      const newData = cloneDeep(tenantData);
      let lastIndex = 0;
      if (!newData[indexData].financeGatewayAttributes) newData[indexData].financeGatewayAttributes = [];
      newData[indexData].financeGatewayAttributes.forEach(elm => {
        if (lastIndex < elm.index) lastIndex = elm.index;
      });
      newData[indexData].financeGatewayAttributes.push({ url: null, type: '', index: lastIndex + 1 });
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { modifyTenant, id } = this.props;
    const { tenantData, idState } = this.state;
    const dataSelect = tenantData.filter(val => val.type === 'FINANCE_GATEWAY');
    modifyTenant({ id: id || idState, tenantMerchantAccounts: dataSelect }, () => this.doGetTenantFinanceGateway());
  };

  render() {
    const { tenantData } = this.state;
    const { modeModifyFinanceGatewayMerchant, isRequireField, t } = this.props;
    const data = tenantData.find(val => val.type === 'FINANCE_GATEWAY');
    const indexData = tenantData.findIndex(val => val.type === 'FINANCE_GATEWAY');
    return (
      <form className="col-md-12 card card-statistics" onSubmit={this.onSubmit}>
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.financeGatewayAttributes')} />
          </div>
        </div>
        {data &&
          data.financeGatewayAttributes &&
          data.financeGatewayAttributes.map((item, index) => (
            <div className="group-flex pl-2" key={index}>
              <GenericInput
                label="label.endPointType"
                type="select"
                value={item.type || null}
                wrapperClass="col-md-4"
                required={isRequireField}
                tOptions="selections:financeGatewayType"
                disabled={modeModifyFinanceGatewayMerchant === 1}
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="type"
              />
              <GenericInput
                label="label.endPointURL"
                value={item.url}
                required={isRequireField}
                type="text"
                disabled={modeModifyFinanceGatewayMerchant === 1}
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="url"
                wrapperClass="col-md-4"
              />
              <GenericInput
                label="label.apiType"
                value={item.apiType}
                required={isRequireField}
                type="select"
                tOptions="selections:apiType"
                disabled={modeModifyFinanceGatewayMerchant === 1}
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="apiType"
                wrapperClass="col-md-4"
              />
            </div>
          ))}
        <div className="col-md-12 text-center mt-3">
          <button
            type="button"
            className="button button-border gray x-small"
            onClick={() => this.onAddNewMapping({ indexData })}
          >
            {t('label.addNewMapping')}
          </button>
        </div>
        <div className="mt-3 mb-3 col-md-12">
          <button type="submit" className="button button-border ml-2 x-small float-right">
            {t('label.saveConfig')}
          </button>
          <button
            type="button"
            onClick={() => this.doGetTenantFinanceGateway()}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </div>
      </form>
    );
  }
}

FinanceGatewayAttributes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  modeModifyFinanceGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

FinanceGatewayAttributes.defaultProps = {
  data: [],
  onHandleChange: () => {},
  modeModifyFinanceGatewayMerchant: 2,
  isRequireField: false,
};

const mapStateToProps = createStructuredSelector({
  data: makeTenantMerchantDataConfig() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getTenantFinanceGateway })(FinanceGatewayAttributes)
);
