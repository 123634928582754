import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import { btnCollapse } from '../../../../constantsApp';
import RouteNames from '../../../App/RouteNames';
import {
  WorkWeekConfigurationForm,
  PeakHoursConfigurationForm,
  HolidayConfigurationForm,
} from '../../../../components/BaseConfigurationsHub';
import { makeGetTimeUnitCfg } from '../../selectors';
import { getTimeUnitCfg, modifyTimeUnitCfg, createTimeUnitCfg } from '../../actions';
import { getCcpPropertiesConfig } from '../../../App/actions';
import { FormCollapse, Switch } from '../../../../components/common';
import { injectValueFromConfig } from '../../../../utils/utils';
import {
  checkPermissionCreateTimeUnitConfig,
  checkPermissionModifyTimeUnitConfig,
  checkPermissionViewTimeUnitConfig,
} from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

class TimeUnit extends PureComponent {
  state = {
    timeUnitCfg: { useStartTime: false, workWeekCfg: {}, peakHourCfg: {}, holidaySpecialCfgs: [] },
    id: '',
    activeTab: {
      name: btnCollapse.timeUnit[0].state,
      isActive: true,
    },
  };

  componentDidMount() {
    this.doGetTimeUnitCfg();
  }

  componentWillReceiveProps(nextProps) {
    const { timeUnitCfg } = this.props;
    if (timeUnitCfg && nextProps.timeUnitCfg && timeUnitCfg !== nextProps.timeUnitCfg) {
      this.setState({
        timeUnitCfg: nextProps.timeUnitCfg,
      });
    }
  }

  doGetTimeUnitCfg = () => {
    const { getTimeUnitCfg, getCcpPropertiesConfig } = this.props;
    getTimeUnitCfg(({ data }) => {
      this.setState({ isModify: !!(data && data.id), id: data.id ? data.id : '' });

      if (!data || !data.id)
        getCcpPropertiesConfig('', ({ success, data }) => {
          if (success) {
            const { timeUnitCfg } = this.state;
            const newData = injectValueFromConfig({
              dataConfig: data.ccpPropertyList,
              listFields: ['useUsageStartTime'],
              defaultData: timeUnitCfg,
            });
            const newTimeUnitData = cloneDeep(timeUnitCfg);
            newTimeUnitData.useStartTime = newData.useUsageStartTime;
            this.setState({ timeUnitCfg: newTimeUnitData });
          }
        });
    });
  };

  onChangeUseStartTime = evt => {
    const { timeUnitCfg } = this.state;
    timeUnitCfg.useStartTime = evt.target.checked;
    this.setState({ ...timeUnitCfg });
  };

  onHandleChangeWorkWeek = ({ name, value }) => {
    const { timeUnitCfg } = this.state;
    const newData = cloneDeep(timeUnitCfg);
    newData.workWeekCfg[name] = value;
    this.setState({ timeUnitCfg: newData });
  };

  onHandleChangePeakHours = ({ name, value }) => {
    const { timeUnitCfg } = this.state;
    const newData = cloneDeep(timeUnitCfg);
    newData.peakHourCfg[name] = moment(`2019-09-09 ${value}`).format('YYYY-MM-DD HH:mm:ss');
    this.setState({ timeUnitCfg: newData });
  };

  onHandleAddNewHoliday = () => {
    const { timeUnitCfg } = this.state;
    const newData = cloneDeep(timeUnitCfg);
    const newDataItem = {
      name: '',
      startDate: '',
      endDate: '',
    };
    if (!newData.holidaySpecialCfgs) newData.holidaySpecialCfgs = [];
    newData.holidaySpecialCfgs = [newDataItem, ...newData.holidaySpecialCfgs];
    this.setState({ timeUnitCfg: newData });
  };

  onChangeTableHoliday = ({ name, value, index }) => {
    const { timeUnitCfg } = this.state;
    const newData = cloneDeep(timeUnitCfg);
    newData.holidaySpecialCfgs[index][name] = value;
    this.setState({ timeUnitCfg: newData });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onHanldeSubmit = () => {
    const { isModify, id, timeUnitCfg } = this.state;
    const { modifyTimeUnitCfg, createTimeUnitCfg } = this.props;
    if (isModify) {
      timeUnitCfg.id = id;
      timeUnitCfg.useStartTime = timeUnitCfg.useStartTime === true ? 'true' : 'false';
      modifyTimeUnitCfg(timeUnitCfg);
    } else {
      const newPayload = cloneDeep(timeUnitCfg);
      const holidaySpecialCfgs = cloneDeep(newPayload.holidaySpecialCfgs);
      const holidaySpecialCfgsRemoveIndex = holidaySpecialCfgs.map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      newPayload.holidaySpecialCfgs = holidaySpecialCfgsRemoveIndex;
      newPayload.id = '5dd805fb78b1fa0011b84538-TU';
      createTimeUnitCfg(newPayload, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetRateUnit();
        }
      });
    }
  };

  renderItem = item => {
    const { timeUnitCfg } = this.state;
    switch (item.id) {
      case 1:
        return <WorkWeekConfigurationForm data={timeUnitCfg.workWeekCfg} onChange={this.onHandleChangeWorkWeek} />;
      case 2:
        return <PeakHoursConfigurationForm data={timeUnitCfg.peakHourCfg} onChange={this.onHandleChangePeakHours} />;
      case 3:
        return (
          <HolidayConfigurationForm
            data={timeUnitCfg.holidaySpecialCfgs}
            onHandleAddNewHoliday={this.onHandleAddNewHoliday}
            onChangeTable={this.onChangeTableHoliday}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { activeTab, timeUnitCfg, isModify } = this.state;
    const { t, permissionsPricing } = this.props;

    let modeCreateTimeUnitConfig = 0;
    let modeModifyTimeUnitConfig = 0;
    let modeViewTimeUnitConfig = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeCreateTimeUnitConfig = checkPermissionCreateTimeUnitConfig({ listPermission });
      modeModifyTimeUnitConfig = checkPermissionModifyTimeUnitConfig({ listPermission });
      modeViewTimeUnitConfig = checkPermissionViewTimeUnitConfig({ listPermission });
    }

    if (!modeViewTimeUnitConfig) return null;

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('label.timeUnit') },
          ]}
        />
        <div className="col-md-5 mt-4 ml-1">
          <Switch
            onChange={this.onChangeUseStartTime}
            title={t('label.timeConfig')}
            checked={timeUnitCfg.useStartTime}
          />
        </div>

        <div className="col-md-12 mt-3">
          {btnCollapse.timeUnit.map(item => (
            <FormCollapse
              key={item.title}
              isActive={item.state === activeTab.name && activeTab.isActive}
              title={item.title}
              state={item.state}
              onToggleTab={this.onToggleTab}
            >
              {this.renderItem(item)}
            </FormCollapse>
          ))}
          <div className="form-group col-md-12 mt-3 mb-3">
            {modeCreateTimeUnitConfig === 2 && modeModifyTimeUnitConfig === 2 && (
              <button type="button" className="button x-small float-right" onClick={this.onHanldeSubmit}>
                {isModify ? t('label.modify') : t('label.createConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetTimeUnitCfg()}
              className="button button-border black x-small float-right mr-2"
            >
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TimeUnit.propTypes = {
  getTimeUnitCfg: PropTypes.func.isRequired,
  modifyTimeUnitCfg: PropTypes.func.isRequired,
  createTimeUnitCfg: PropTypes.func.isRequired,
  getCcpPropertiesConfig: PropTypes.func.isRequired,
  timeUnitCfg: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

TimeUnit.defaultProps = {
  timeUnitCfg: [],
};

const mapStateToProps = createStructuredSelector({
  timeUnitCfg: makeGetTimeUnitCfg() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getTimeUnitCfg, modifyTimeUnitCfg, createTimeUnitCfg, getCcpPropertiesConfig })(TimeUnit)
);
