import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { queryRequest, mutationRequest } from '../../utils/request';
import {
  // getProductFamilyConfig,
  // modifyProductFamilyConfig,
  // createProductFamilyConfig,
  getCurrencyConfig,
  modifyCurrencyConfig,
  createCurrencyConfig,
  getGrantsConfig,
  modifyGrantsConfig,
  createGrantsConfig,
  getAccumulatorsConfig,
  modifyAccumulatorsConfig,
  createAccumulatorsConfig,
  getRateUnit,
  modifyRateUnit,
  createRateUnit,
  getTimeUnitCfg,
  modifyTimeUnitCfg,
  createTimeUnitCfg,
  getRateUnitSvcTxnMapCfg,
  modifyRateUnitSvcTxnMapCfg,
  createRateUnitSvcTxnMapCfg,
  getTaxCodeConfig,
  modifyTaxConfig,
  createTaxConfig,
  get3rdPartyTaxConfig,
  searchUom,
  createUom,
  modifyUom,
  getConfigUsageType,
  modifyConfigUsageType,
  createConfigUsageType,
  searchConfigCountryCodes,
  modifyConfigCountryCode,
  createConfigCountryCode,
  searchRegulatoryProductCodes,
  searchPrepaidSystemOffers,
  createPrepaidSystemOffers,
  modifyPrepaidSystemOffers,
  searchZoneUnit,
  modifyZoneUnit,
  createZoneUnit,
  getZonesByZoneUnitId,
  createRegulatoryProductCodes,
  modifyRegulatoryProductCodes,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

// export function* getProductFamilyConfigSaga({ cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(queryRequest, getProductFamilyConfig());
//     yield put(isEndConnected());
//     if (response.getProductFamilyConfig && response.getProductFamilyConfig.id) {
//       yield put(actions.getProductFamilyConfigSuccess(response.getProductFamilyConfig));
//       if (cb) yield cb({ success: true, data: response.getProductFamilyConfig });
//     } else if (cb) yield cb({ success: false });
//   } catch (err) {
//     yield put(isEndConnected());
//     yield put(actions.getProductFamilyConfigSuccess([]));
//     if (cb) yield cb({ success: false });
//   }
// }

// export function* modifyProductFamilyConfigSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(mutationRequest, modifyProductFamilyConfig(payload));
//     yield put(actions.getProductFamilyConfig());
//     if (response.modifyProductFamilyConfig) {
//       if (cb) cb({ success: true, data: response.modifyProductFamilyConfig });
//       toast('Modify product family successfully!', { type: toast.TYPE.SUCCESS });
//     }
//     yield put(isEndConnected());
//   } catch (err) {
//     if (cb) cb({ success: false, data: [] });
//     yield put(isEndConnected());
//   }
// }

// export function* createProductFamilyConfigSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(mutationRequest, createProductFamilyConfig(payload));
//     if (response.createProductFamilyConfig) {
//       if (cb) cb({ success: true, data: response.createProductFamilyConfig });
//       toast('Create product family successfully!', { type: toast.TYPE.SUCCESS });
//     }
//     yield put(isEndConnected());
//   } catch (err) {
//     if (cb) cb({ success: false, data: [] });
//     yield put(isEndConnected());
//   }
// }

// Currency
export function* getCurrencyConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCurrencyConfig());
    yield put(isEndConnected());
    if (response.getCurrencyConfig && response.getCurrencyConfig.id) {
      yield put(actions.getCurrencyConfigSuccess(response.getCurrencyConfig));
      if (cb) yield cb({ success: true, data: response.getCurrencyConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCurrencyConfigSuccess([]));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyCurrencyConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCurrencyConfig(payload));
    yield put(actions.getCurrencyConfig());
    if (response.modifyCurrencyConfig) {
      if (cb) cb({ success: true, data: response.modifyCurrencyConfig });
      toast.success(i18n.t('common:apiMessage.modifyCurrencySuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createCurrencyConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCurrencyConfig(payload));
    yield put(actions.getCurrencyConfig());
    if (response.createCurrencyConfig) {
      if (cb) cb({ success: true, data: response.createCurrencyConfig });
      toast.success(i18n.t('common:apiMessage.createCurrencySuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Grants
export function* getGrantsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGrantsConfig());
    yield put(isEndConnected());
    if (response.getGrantsConfig && response.getGrantsConfig.id) {
      yield put(actions.getGrantsConfigSuccess(response.getGrantsConfig));
      if (cb) yield cb({ success: true, data: response.getGrantsConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getGrantsConfigSuccess([]));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyGrantsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyGrantsConfig(payload));
    yield put(actions.getGrantsConfig());
    if (response.modifyGrantsConfig) {
      if (cb) cb({ success: true, data: response.modifyGrantsConfig });
      toast.success(i18n.t('common:apiMessage.modifyGrantsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createGrantsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createGrantsConfig(payload));
    yield put(actions.getGrantsConfig());
    if (response.createGrantsConfig) {
      if (cb) cb({ success: true, data: response.createGrantsConfig });
      toast.success(i18n.t('common:apiMessage.createGrantsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Accumulators
export function* getAccumulatorsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccumulatorsConfig());
    yield put(isEndConnected());
    if (response.getAccumulatorsConfig && response.getAccumulatorsConfig.id) {
      yield put(actions.getAccumulatorsConfigSuccess(response.getAccumulatorsConfig));
      if (cb) yield cb({ success: true, data: response.getAccumulatorsConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAccumulatorsConfigSuccess([]));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyAccumulatorsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyAccumulatorsConfig(payload));
    yield put(actions.getAccumulatorsConfig());
    if (response.modifyAccumulatorsConfig) {
      if (cb) cb({ success: true, data: response.modifyAccumulatorsConfig });
      toast.success(i18n.t('common:apiMessage.modifyAccumulatorsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createAccumulatorsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createAccumulatorsConfig(payload));
    yield put(actions.getAccumulatorsConfig());
    if (response.createAccumulatorsConfig) {
      if (cb) cb({ success: true, data: response.createAccumulatorsConfig });
      toast.success(i18n.t('common:apiMessage.createAccumulatorsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Rate Unit
export function* getRateUnitSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRateUnit());
    yield put(isEndConnected());
    if (response.getRateUnit && response.getRateUnit.id) {
      yield put(actions.getRateUnitSuccess(response.getRateUnit));
      if (cb) yield cb({ success: true, data: response.getRateUnit });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRateUnitSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyRateUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRateUnit(payload));
    yield put(actions.getRateUnit());
    if (response.modifyRateUnit) {
      if (cb) cb({ success: true, data: response.modifyRateUnit });
      toast.success(i18n.t('common:apiMessage.modifyRateUnitSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createRateUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRateUnit(payload));
    yield put(actions.getRateUnit());
    if (response.createRateUnit) {
      if (cb) cb({ success: true, data: response.createRateUnit });
      toast.success(i18n.t('common:apiMessage.createRateUnitSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Time Unit
export function* getTimeUnitCfgSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTimeUnitCfg());
    yield put(isEndConnected());
    if (response.getTimeUnitCfg && response.getTimeUnitCfg.id) {
      yield put(actions.getTimeUnitCfgSuccess(response.getTimeUnitCfg));
      if (cb) yield cb({ success: true, data: response.getTimeUnitCfg });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTimeUnitCfgSuccess({}));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyTimeUnitCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTimeUnitCfg(payload));
    yield put(actions.getTimeUnitCfg());
    if (response.modifyTimeUnitCfg) {
      if (cb) cb({ success: true, data: response.modifyTimeUnitCfg });
      toast.success(i18n.t('common:apiMessage.modifyTimeUnitSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* createTimeUnitCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTimeUnitCfg(payload));
    yield put(actions.getTimeUnitCfg());
    if (response.createTimeUnitCfg) {
      if (cb) cb({ success: true, data: response.createTimeUnitCfg });
      toast.success(i18n.t('common:apiMessage.createTimeUnitSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

// Rate Unit Map
export function* getRateUnitMapCfgSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRateUnitSvcTxnMapCfg());
    yield put(isEndConnected());
    if (response.getRateUnitSvcTxnMapCfg && response.getRateUnitSvcTxnMapCfg.id) {
      yield put(actions.getRateUnitMapCfgSuccess(response.getRateUnitSvcTxnMapCfg));
      if (cb) yield cb({ success: true, data: response.getRateUnitSvcTxnMapCfg });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRateUnitMapCfgSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyRateUnitCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRateUnitSvcTxnMapCfg(payload));
    yield put(actions.getRateUnitMapCfg());
    if (response.modifyRateUnitSvcTxnMapCfg) {
      if (cb) cb({ success: true, data: response.modifyRateUnitSvcTxnMapCfg });
      toast.success(i18n.t('common:apiMessage.modifyRateUnitMapSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createRateUnitCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRateUnitSvcTxnMapCfg(payload));
    yield put(actions.getRateUnitMapCfg());
    if (response.createRateUnitSvcTxnMapCfg) {
      if (cb) cb({ success: true, data: response.createRateUnitSvcTxnMapCfg });
      toast.success(i18n.t('common:apiMessage.createRateUnitMapSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Tax config
export function* getTaxCodeConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxCodeConfig());
    yield put(isEndConnected());
    if (response.getTaxConfig && response.getTaxConfig) {
      yield put(actions.getTaxCodeConfigSuccess(response.getTaxConfig));
      if (cb) yield cb({ success: true, data: response.getTaxConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTaxCodeConfigSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* modifyTaxCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTaxConfig(payload));
    yield put(actions.getTaxCodeConfig());
    if (response.modifyTaxConfig) {
      if (cb) cb({ success: true, data: response.modifyTaxConfig });
      toast.success(i18n.t('common:apiMessage.modifyTaxCodeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createTaxCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTaxConfig(payload));
    if (response.createTaxConfig) {
      if (cb) cb({ success: true, data: response.createTaxConfig });
      toast.success(i18n.t('common:apiMessage.createTaxCodeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* get3rdPartyTaxConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, get3rdPartyTaxConfig());
    yield put(isEndConnected());
    if (response.get3rdPartyTaxConfig && response.get3rdPartyTaxConfig) {
      if (cb) yield cb({ success: true, data: response.get3rdPartyTaxConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

// UMO
export function* getUomSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchUom(payload));
    yield put(isEndConnected());
    if (response.searchUom) {
      yield put(actions.getUomSuccess(response.searchUom));
      if (cb) yield cb({ success: true, data: response.searchUom });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUomSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyTUomSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyUom(payload));
    if (response.modifyUom) {
      if (cb) cb({ success: true, data: response.modifyUom });
      toast.success(i18n.t('common:apiMessage.modifyUnitOfMeasureSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createUomSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createUom(payload));
    if (response.createUom) {
      toast.success(i18n.t('common:apiMessage.createUnitOfMeasureSuccessfully'));
      if (cb) cb({ success: true, data: response.createUom });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Usage Type
export function* getConfigUsageTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigUsageType(payload));
    yield put(isEndConnected());
    if (response.getConfigUsageType) {
      yield put(actions.getConfigUsageTypeSuccess(response.getConfigUsageType));
      if (cb) yield cb({ success: true, data: response.getConfigUsageType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getConfigUsageTypeSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyConfigUsageTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigUsageType(payload));
    if (response.modifyConfigUsageType) {
      if (cb) cb({ success: true, data: response.modifyConfigUsageType });
      toast.success(i18n.t('common:apiMessage.modifyUsageTypeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createConfigUsageTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigUsageType(payload));
    if (response.createConfigUsageType) {
      toast.success(i18n.t('common:apiMessage.createUsageTypeSuccessfully'));
      if (cb) cb({ success: true, data: response.createConfigUsageType });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Contry codes
export function* searchConfigCountryCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigCountryCodes(payload));
    yield put(isEndConnected());
    if (response.searchConfigCountryCodes) {
      yield put(actions.searchConfigCountryCodesSuccess(response.searchConfigCountryCodes));
      if (cb) yield cb({ success: true, data: response.searchConfigCountryCodes });
    } else {
      yield put(actions.searchConfigCountryCodesSuccess(null));
      if (cb) yield cb({ success: false, data: null });
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchConfigCountryCodesSuccess([]));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* createConfigCountryCodeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigCountryCode(payload));
    if (response.createConfigCountryCode) {
      if (cb) cb({ success: true, data: response.createConfigCountryCode });
      toast.success(i18n.t('common:apiMessage.createCountryCodeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyConfigCountryCodeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigCountryCode(payload));
    if (response.modifyConfigCountryCode) {
      if (cb) cb({ success: true, data: response.modifyConfigCountryCode });
      toast.success(i18n.t('common:apiMessage.modifyCountryCodeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchPacProductCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRegulatoryProductCodes(payload));
    yield put(isEndConnected());
    if (response?.searchRegulatoryProductCodes) {
      yield put(actions.searchPacProductCodesSuccess(response.searchRegulatoryProductCodes));
      if (cb) yield cb({ success: true, data: response.searchRegulatoryProductCodes });
    } else {
      if (cb) yield cb({ success: false, data: null });
      yield put(actions.searchPacProductCodesSuccess([]));
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchPacProductCodesSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchPrepaidSystemOffersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchPrepaidSystemOffers(payload));
    yield put(isEndConnected());
    if (response.searchPrepaidSystemOffers) {
      yield put(actions.searchPrepaidSystemOffersSuccess(response.searchPrepaidSystemOffers));
      if (cb) yield cb({ success: true, data: response.searchPrepaidSystemOffers });
    } else {
      yield put(actions.searchPrepaidSystemOffersSuccess(null));
      if (cb) yield cb({ success: false, data: null });
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchPrepaidSystemOffersSuccess(null));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* createPrepaidSystemOffersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, createPrepaidSystemOffers(payload));
    yield put(isEndConnected());
    if (response.createPrepaidSystemOffers) {
      toast.success(i18n.t('common:apiMessage.createPrepaidSystemOffersSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createPrepaidSystemOffers });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyPrepaidSystemOffersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyPrepaidSystemOffers(payload));
    yield put(isEndConnected());
    if (response.modifyPrepaidSystemOffers) {
      toast.success(i18n.t('common:apiMessage.modifyPrepaidSystemOffersSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifyPrepaidSystemOffers });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* searchZoneUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchZoneUnit(payload));
    yield put(isEndConnected());
    if (response.searchZoneUnit) {
      if (cb) yield cb({ success: true, data: response.searchZoneUnit });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* createZoneUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createZoneUnit(payload));
    if (response.createZoneUnit) {
      if (cb) cb({ success: true, data: response.createZoneUnit });
      toast.success(i18n.t('common:apiMessage.createZoneUnitSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyZoneUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyZoneUnit(payload));
    if (response.modifyZoneUnit) {
      if (cb) cb({ success: true, data: response.modifyZoneUnit });
      toast.success(i18n.t('common:apiMessage.modifyZoneUnitSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getZonesByZoneUnitIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getZonesByZoneUnitId(payload));
    yield put(isEndConnected());
    if (response.getZonesByZoneUnitId) {
      if (cb) yield cb({ success: true, data: response.getZonesByZoneUnitId });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* createRegulatoryProductCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRegulatoryProductCodes(payload));
    yield put(isEndConnected());
    if (response.createRegulatoryProductCodes) {
      toast.success(i18n.t('common:apiMessage.createRegulatoryProductCodesSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createRegulatoryProductCodes });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyRegulatoryProductCodesSaga({ payload, cb, isDelete }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRegulatoryProductCodes(payload));
    yield put(isEndConnected());
    if (response.modifyRegulatoryProductCodes) {
      if (isDelete) {
        toast.success(i18n.t('common:apiMessage.deleteRegulatoryProductCodesSuccessfully'));
      } else toast.success(i18n.t('common:apiMessage.modifyRegulatoryProductCodesSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifyRegulatoryProductCodes });
    } else {
      if (isDelete) {
        toast.success(i18n.t('common:apiMessage.deleteRegulatoryProductCodesSuccessfully'));
      }
      if (cb) yield cb({ success: false, data: null });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export default function* baseConfigurationsSaga() {
  // yield takeLatest(types.GET_PRODUCT_FAMILY_CONFIG, getProductFamilyConfigSaga);
  // yield takeLatest(types.MODIFY_PRODUCT_FAMILY_CONFIG, modifyProductFamilyConfigSaga);
  // yield takeLatest(types.CREATE_PRODUCT_FAMILY_CONFIG, createProductFamilyConfigSaga);
  yield takeLatest(types.GET_CURRENCY_CONFIG, getCurrencyConfigSaga);
  yield takeLatest(types.MODIFY_CURRENCY_CONFIG, modifyCurrencyConfigSaga);
  yield takeLatest(types.CREATE_CURRENCY_CONFIG, createCurrencyConfigSaga);
  yield takeLatest(types.GET_GRANTS_CONFIG, getGrantsSaga);
  yield takeLatest(types.MODIFY_GRANTS_CONFIG, modifyGrantsSaga);
  yield takeLatest(types.CREATE_GRANTS_CONFIG, createGrantsSaga);
  yield takeLatest(types.GET_ACCUMULATORS_CONFIG, getAccumulatorsSaga);
  yield takeLatest(types.MODIFY_ACCUMULATORS_CONFIG, modifyAccumulatorsSaga);
  yield takeLatest(types.CREATE_ACCUMULATORS_CONFIG, createAccumulatorsSaga);
  yield takeLatest(types.GET_RATE_UNIT, getRateUnitSaga);
  yield takeLatest(types.MODIFY_RATE_UNIT, modifyRateUnitSaga);
  yield takeLatest(types.CREATE_RATE_UNIT, createRateUnitSaga);
  yield takeLatest(types.GET_TIME_UNIT_CFG, getTimeUnitCfgSaga);
  yield takeLatest(types.MODIFY_TIME_UNIT_CFG, modifyTimeUnitCfgSaga);
  yield takeLatest(types.CREATE_TIME_UNIT_CFG, createTimeUnitCfgSaga);
  yield takeLatest(types.GET_RATE_UNIT_MAP, getRateUnitMapCfgSaga);
  yield takeLatest(types.CREATE_RATE_UNIT_MAP, createRateUnitCfgSaga);
  yield takeLatest(types.MODIFY_RATE_UNIT_MAP, modifyRateUnitCfgSaga);
  yield takeLatest(types.GET_TAX_CODE_CONFIG_BASE, getTaxCodeConfigSaga);
  yield takeLatest(types.MODIFY_TAX_CODE_CONFIG_BASE, modifyTaxCodeConfigSaga);
  yield takeLatest(types.CREATE_TAX_CODE_CONFIG_BASE, createTaxCodeConfigSaga);
  yield takeLatest(types.GET_3RD_PARTY_TAX_CONFIG_BASE, get3rdPartyTaxConfigSaga);
  yield takeLatest(types.GET_UOM, getUomSaga);
  yield takeLatest(types.CREATE_UOM, createUomSaga);
  yield takeLatest(types.MODIFY_UOM, modifyTUomSaga);
  yield takeLatest(types.GET_USAGE_TYPE, getConfigUsageTypeSaga);
  yield takeLatest(types.CREATE_USAGE_TYPE, createConfigUsageTypeSaga);
  yield takeLatest(types.MODIFY_USAGE_TYPE, modifyConfigUsageTypeSaga);
  yield takeLatest(types.SEARCH_COUNTRY_CODES, searchConfigCountryCodesSaga);
  yield takeLatest(types.CREATE_COUNTRY_CODES, createConfigCountryCodeSaga);
  yield takeLatest(types.MODIFY_COUNTRY_CODES, modifyConfigCountryCodeSaga);
  yield takeLatest(types.SEARCH_PAC_PRODUCT_CODE, searchPacProductCodesSaga);
  yield takeLatest(types.SEARCH_PREPAID_SYSTEM_OFFERS, searchPrepaidSystemOffersSaga);
  yield takeLatest(types.CREATE_PREPAID_SYSTEM_OFFERS, createPrepaidSystemOffersSaga);
  yield takeLatest(types.MODIFY_PREPAID_SYSTEM_OFFERS, modifyPrepaidSystemOffersSaga);
  yield takeLatest(types.SEARCH_ZONE_UNIT, searchZoneUnitSaga);
  yield takeLatest(types.CREATE_ZONE_UNIT, createZoneUnitSaga);
  yield takeLatest(types.MODIFY_ZONE_UNIT, modifyZoneUnitSaga);
  yield takeLatest(types.GET_ZONE_UNIT_BY_ID, getZonesByZoneUnitIdSaga);
  yield takeLatest(types.CREATE_REGULATORY_PRODUCT_CODES, createRegulatoryProductCodesSaga);
  yield takeLatest(types.MODIFY_REGULATORY_PRODUCT_CODES, modifyRegulatoryProductCodesSaga);
}
