import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { searchEmployeeCosting, searchAllEmployeeCosting, createEmployeeCosting } from '../actions';
import { makeGetEmployees, makeGetListALlEmployees } from '../selectors';
import {
  DataTable,
  GenericInput,
  ButtonExport,
  ButtonExportCSV,
  ExcelExport,
  SwitchExport,
  ModalUploadFile,
  TablePagination,
  TitleFrom,
  ModalWithItem,
} from '../../../components/common';
import { getPageTotalCount, parseToMutationRequestPostMethod, formatNumberValue } from '../../../utils/utils';
import { EmployeeSearchForm } from '../../../components/BillingHub';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelEmployee';
import { headersCSV, dataCSV } from '../../../utils/CSVHelper/exportCSVEmployees';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfEmployees';
import { uploadMultiPartFiles, getCloudFileDataByFileName } from '../../App/actions';

const sortTypes = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  clientId: {
    asc: 'clientId_ASC',
    desc: 'clientId_DESC',
  },
  clientName: {
    asc: 'clientName_ASC',
    desc: 'clientName_DESC',
  },
  employeeName: {
    asc: 'employeeName_ASC',
    desc: 'employeeName_DESC',
  },
};

const statsFileRecordColumns = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  // {
  //   name: 'batchId',
  //   label: 'common:label.batchId',
  // },
  {
    name: 'totalCount',
    label: 'common:label.totalCount',
  },
  {
    name: 'pendingCount',
    label: 'common:label.pendingCount',
  },
  {
    name: 'successCount',
    label: 'common:label.successCount',
  },
  {
    name: 'failureCount',
    label: 'common:label.errorCount',
  },
];

class Employee extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    employeeList: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isOpenUploadFile: false,
    isOpenModalStatsFile: false,
    fileNameUpload: null,
  };

  formRef = React.createRef();

  buttonRef = React.createRef();

  buttonCSVRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.employeeList) return null;
    return {
      employeeList: props.employeeList || [],
      totalCount: getPageTotalCount({ ...state, items: props.employeeList }),
      oldData: props.employeeList,
      dataSubmit: [],
      isOnChange: false,
    };
  }

  componentDidMount() {
    this.doSearchEmployeeCosting();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { employeeList, dataSubmit } = this.state;
    const newData = cloneDeep(employeeList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ employeeList: newData });
  };

  doSearchEmployeeCosting = () => {
    const { searchEmployeeCosting } = this.props;
    const { filter, page, size, sorted } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchEmployeeCosting(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewEmployee = () => {
    let indexNewItem = 0;
    const { employeeList, dataSubmit } = this.state;
    let newData = cloneDeep(employeeList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      id: null,
      employeeName: null,
      clientName: null,
      jobTitle: null,
      firstPayrollDate: null,
      secondPayrollDate: null,
      startDate: null,
      endDate: null,
      firstSalaryAmount: null,
      secondSalaryAmount: null,
      noOfDaysWorked: null,
      clientJobTitle: null,
      fileName: null,
      clientId: null,
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ employeeList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { employeeList, dataSubmit } = this.state;
    const newData = cloneDeep(employeeList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ employeeList: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { createEmployeeCosting, t } = this.props;
    const { employeeList } = this.state;
    this.setState({ wasValidated: true });
    const newPayloadCreate = cloneDeep(employeeList[0]);
    const { isNew, ...rest } = newPayloadCreate;
    if (
      !rest.id ||
      !rest.employeeName ||
      !rest.clientName ||
      !rest.jobTitle ||
      !rest.firstPayrollDate ||
      !rest.secondPayrollDate ||
      !rest.startDate
    ) {
      return toast.error(t('message.mandatory'));
    }
    return createEmployeeCosting(rest, ({ success }) => {
      if (success) this.doSearchEmployeeCosting();
    });
  };

  onToggleModal = () => {
    const { isOpenUploadFile } = this.state;
    this.setState({ isOpenUploadFile: !isOpenUploadFile });
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchEmployeeCosting());
  };

  onHandleUpload = ({ files }) => {
    const { isOpenUploadFile } = this.state;
    const { uploadMultiPartFiles } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        { category: 'TIME_AND_BILLING', timeAndBillingSubType: 'EMPLOYEE_PAYROLL_COSTING', name: files[0].name },
        ['category', 'timeAndBillingSubType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          this.setState({
            isOpenUploadFile: !isOpenUploadFile,
            itemSelect: null,
            fileNameUpload: files[0]?.name || '',
          });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null, fileNameUpload: null });
    }
  };

  onCancelUploadFile = () => {
    this.setState({ isOpenUploadFile: false, itemSelect: null });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchEmployeeCosting());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchEmployeeCosting());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchEmployeeCosting());
  };

  doGetAllListEmployeeCosting = cb => {
    const { filter, sorted } = this.state;
    const { searchAllEmployeeCosting } = this.props;
    const payload = {
      page: 1,
      size: 10000,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchAllEmployeeCosting(payload, () => {
      if (cb) cb();
    });
  };

  onExport = isCsv => {
    this.doGetAllListEmployeeCosting(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf && !isCsv) {
        const { listALlEmployees, t } = this.props;
        convertJson2Pdf({
          data: listALlEmployees,
          t,
          title: 'EMPLOYEES',
          fileName: `list_employees_${moment(new Date()).format('YYYY_MM_DD')}`,
        });
      } else if (isCsv) {
        this.buttonCSVRef.current.click();
      } else this.buttonRef.current.click();
    });
  };

  onToggleModalViewStatsFile = () => {
    const { isOpenModalStatsFile } = this.state;
    if (!isOpenModalStatsFile) {
      this.doGetStatsFile();
    }
    this.setState({ isOpenModalStatsFile: !isOpenModalStatsFile });
  };

  doGetStatsFile = () => {
    const { getCloudFileDataByFileName } = this.props;
    const { fileNameUpload } = this.state;
    getCloudFileDataByFileName(fileNameUpload, ({ success, data }) => {
      if (success) this.setState({ statsFileData: data || [] });
    });
  };

  render() {
    const {
      employeeList,
      isSearching,
      wasValidated,
      isOpenUploadFile,
      itemSelect,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      isOpenModalStatsFile,
      statsFileData,
      fileNameUpload,
    } = this.state;
    const { t, listALlEmployees } = this.props;
    const employeeColumns = [
      {
        name: 'id',
        label: t('label.id'),
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="id"
            required
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'employeeName',
        label: t('label.employee'),
        style: { minWidth: '180px' },
        sortable: true,
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.employeeName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="employeeName"
            required
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'jobTitle',
        label: t('label.jobTitle'),
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.jobTitle}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="jobTitle"
            required
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'clientId',
        label: t('label.clientId'),
        sortable: true,
        style: { minWidth: '190px' },
        render: (colName, item) => (
          <GenericInput
            value={item.clientId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="clientId"
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'clientName',
        label: t('label.clientName'),
        style: { minWidth: '190px' },
        required: true,
        sortable: true,
        render: (colName, item) => (
          <GenericInput
            value={item.clientName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="clientName"
            required
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'clientJobTitle',
        label: t('label.clientJobTitle'),
        style: { minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.clientJobTitle}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="clientJobTitle"
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'firstPayrollDate',
        label: t('label.firstPayrollDate'),
        style: { minWidth: '230px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.firstPayrollDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="firstPayrollDate"
            required
            type="date"
            readOnly={!item.isNew}
            disabled={!item.isNew}
          />
        ),
      },
      {
        name: 'firstSalaryAmount',
        label: t('label.firstPayrollAmount'),
        style: { minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.isNew ? item.firstSalaryAmount : formatNumberValue(item.firstSalaryAmount)}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="firstSalaryAmount"
            readOnly={!item.isNew}
            type={!item.isNew ? 'text' : 'number'}
          />
        ),
      },
      {
        name: 'secondPayrollDate',
        label: t('label.secondPayrollDate'),
        style: { minWidth: '230px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.secondPayrollDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="secondPayrollDate"
            type="date"
            readOnly={!item.isNew}
            required
            disabled={!item.isNew}
          />
        ),
      },
      {
        name: 'secondSalaryAmount',
        label: t('label.secondSalaryAmount'),
        style: { minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.isNew ? item.secondSalaryAmount : formatNumberValue(item.secondSalaryAmount)}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="secondSalaryAmount"
            readOnly={!item.isNew}
            type={!item.isNew ? 'text' : 'number'}
          />
        ),
      },
      {
        name: 'noOfDaysWorked',
        label: t('label.numDaysWorked'),
        style: { minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.isNew ? item.noOfDaysWorked : formatNumberValue(item.noOfDaysWorked)}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="noOfDaysWorked"
            readOnly={!item.isNew}
            type={!item.isNew ? 'text' : 'number'}
          />
        ),
      },
      {
        name: 'startDate',
        label: t('label.startDate'),
        sortable: true,
        style: { minWidth: '270px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.startDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="startDate"
            type="date"
            readOnly={!item.isNew}
            disabled={!item.isNew}
            required
          />
        ),
      },
      {
        name: 'endDate',
        label: t('label.endDate'),
        sortable: true,
        style: { minWidth: '270px' },
        render: (colName, item) => (
          <GenericInput
            value={item.endDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="endDate"
            type="date"
            readOnly={!item.isNew}
            disabled={!item.isNew}
          />
        ),
      },
      {
        name: 'createdDate',
        label: t('label.createdDate'),
        sortable: true,
        style: { minWidth: '270px' },
        render: (colName, item) => (
          <GenericInput
            value={item.createdDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="createdDate"
            type="date"
            readOnly
            disabled
          />
        ),
      },
      {
        name: 'status',
        label: t('label.status'),
        style: { minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="status"
            tOptions="selections:timeAndBillingObjectStatus"
            type="select"
            menuPortalTarget
            readOnly
            disabled
          />
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.invoicingBilling.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
            { name: t('label.employee') },
          ]}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <TitleFrom title={t('label.employee')} />
            <div className="col-md-12 mt-3">
              <button type="button" className="button x-small float-right mr-2" onClick={this.onToggleModal}>
                {t('label.upload')}
              </button>
              <button
                type="button"
                className="button button-border black x-small float-right mr-2"
                onClick={this.onToggleModalViewStatsFile}
                disabled={!fileNameUpload}
              >
                {t('label.viewUploadFileDetails')}
              </button>
            </div>
            <br />
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <EmployeeSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex mb-4 pt-3 float-right">
              <div className="ml-auto mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={this.onChangeSwitch}
                  wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdf}
                  rightTitle={t('label.pdf')}
                />
              </div>
              <>
                <ButtonExport onExport={() => this.onExport(false)} />
                <ExcelExport
                  element={<button type="button" className="display-none" ref={this.buttonRef} />}
                  nameSheet="EMPLOYEES"
                  multiDataSet={convertJson2Sheet({ data: listALlEmployees, t })}
                  fileName={`list_employees_${moment(new Date()).format('YYYY_MM_DD')}`}
                />
                <ButtonExportCSV onExport={() => this.onExport(true)} btnTitle="CSV" />
                <CsvDownloader
                  filename={`list_employees_${moment(new Date()).format('YYYY_MM_DD')}`}
                  datas={dataCSV({ t, data: listALlEmployees || [] })}
                  columns={headersCSV({ t })}
                  className="display-none"
                  separator=";"
                  extension=".csv"
                >
                  <button type="button" className="display-none" ref={this.buttonCSVRef} />
                </CsvDownloader>
              </>
            </div>
          </div>
        </div>
        <div className="card card-statistics h-100 mr-3">
          <br />
          <form
            onSubmit={this.hanldeSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <div className="form-group col-md-12 buttons-attibute">
              <button
                type="button"
                className="button button-border black x-small"
                disabled={employeeList && employeeList.find(val => !!val.isNew)}
                onClick={this.onAddNewEmployee}
              >
                +
                {t('label.addNewEmployee')}
              </button>
              <button
                type="submit"
                disabled={employeeList && !employeeList.find(val => !!val.isNew)}
                className="button button-border x-small float-right"
              >
                {t('label.save')}
              </button>
              <button
                type="button"
                onClick={() => this.doSearchEmployeeCosting()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <br />
            <div>
              <DataTable
                columns={employeeColumns}
                data={employeeList && employeeList.length ? employeeList : []}
                isLoading={isSearching}
                isFixedHeaderTable
                sorted={sorted}
                onSort={this.onSortColumn}
              />
            </div>
          </form>
        </div>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalUploadFile
          title={t('label.uploadEmployeePayroll')}
          isOpen={isOpenUploadFile}
          onCancel={this.onCancelUploadFile}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          // accept=".csv"
        />
        <ModalWithItem
          modalTitle={t('label.uploadFileStatus')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStatsFile}
          onToggle={this.onToggleModalViewStatsFile}
        >
          <button type="button" onClick={this.doGetStatsFile} className="btn-reload">
            <i className="ti-reload" />
          </button>
          <DataTable columns={statsFileRecordColumns} data={statsFileData ? [statsFileData] : []} />
          <button type="button" onClick={this.onToggleModalViewStatsFile} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

Employee.propTypes = {
  employeeList: PropTypes.objectOf(PropTypes.any),
  searchEmployeeCosting: PropTypes.func.isRequired,
  createEmployeeCosting: PropTypes.func.isRequired,
};

Employee.defaultProps = {
  employeeList: {},
};
const mapStateToProps = createStructuredSelector({
  employeeList: makeGetEmployees() || {},
  listALlEmployees: makeGetListALlEmployees() || [],
});

const newEmployee = connect(mapStateToProps, {
  searchEmployeeCosting,
  createEmployeeCosting,
  searchAllEmployeeCosting,
  uploadMultiPartFiles,
  getCloudFileDataByFileName,
})(Employee);

export default withTranslation('common')(newEmployee);
