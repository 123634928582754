import gql from 'graphql-tag';

export default id => gql`
  query {
    getAccountById(input: { id: "${id}" }) {
      id
      parentId
      customerSegment
      type
      subType
      salesChannel
      marketSegment
      sellingCompany
      lineOfBusiness
      legalEntity
      currency
      status
      reason
      effectiveDate
      salesAgentId
      clientId
      type
      region
      externalRefId
      collectionAgent
      employeeId
      foreignTaxId
      commercialName
      taxId
      organization
      name
      statusModifiedDate
      accountCategory
      customerId
      partyId
      groupId
      contacts {
        id
        roles
        salutation
        firstName
        middleName
        lastName
        position
        organization
        email
        identity
        identityDocument
        phones {
          type
          number
        }
      }
      addresses {
        id
        roles
        street
        extraLine
        landmark
        city
        state
        country
        postalCode
        code
        floor
        municipality
        locality
        latitude
        longitude
        neighbourhood
        address1
        address2
        address3
        unit
        district
      }
      billingProfiles {
        id
        parentId
        billingDom
        billingSegment
        billingFrequency
        duration
        invoiceType
        invoiceDelivery
        paymentProfileId
        pauseBilling
        pauseInvoicing
        collectionProfile
      }
      paymentProfiles {
        id
        paymentTerm
        paymentMethod
        creditCards {
          cardToken
          cardExpiry
          last4CC
          merchant
        }
      }
      customAttributes {
        name
        value
      }
    }
  }
`;
