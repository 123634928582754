import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataCreate = parseOrderMutation(data, [
    'objectType',
    'serviceType',
    'status',
    'index',
    'flexAttribute',
    'mandatoryFlag',
  ]);
  const createFlexAttributeMapping = `createFlexAttributeMapping(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createFlexAttributeMapping} {
      id
    }
  }
`;
};
