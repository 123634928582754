import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['objectType', 'extractStatus', 'extractBatch', 'reconcileBatch'])}`
    : '';
  const getAccountingReconciliationReport = `getAccountingReconciliationReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getAccountingReconciliationReport} {
        company
        accountId
        billUnitId
        objectDate
        objectId
        extractBatch
        arType
        createdDate
        extractDate
        totalSummary
        addonTaxAmount
        mainTaxAmount
        total
        journalTotal
        accountingLogTotal
        batchAmount
        objectType
        extractStatus
        matchStatus
        folioId
      }
    }
  `;
};
