import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['recordNumber', 'length', 'mandatory', 'type']);
  const createCustomUsageRecCfg = `createCustomUsageRecCfg(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createCustomUsageRecCfg} {
      id
    }
  }
`;
};
