import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { queryRequest, mutationRequest } from '../../utils/request';
import { mutationRequest as mutationRequestJob } from '../../utils/reuqestJob';
import {
  searchOperatorDispute,
  getOperatorDisputeById,
  searchOperatorInvoice,
  getOperatorInvoiceById,
  searchOperatorRecord,
  getOperatorRecordById,
  searchTenantDispute,
  searchTenantDisputeRecordList,
  searchTenantOperatorRecord,
  searchTenantOperatorRecordList,
  operatorDisputeRecon,
  operatorInvoiceRecon,
  searchReRatingBatches,
  saveReratingBatch,
  getReratingBatchById,
  modifyReratingBatch,
  getConfigUsagePlatforms,
  modifyConfigUsagePlatform,
  createConfigUsagePlatform,
  getCustomUsageRecCfg,
  modifyCustomUsageRecCfg,
  createCustomUsageRecCfg,
  createUsageFileMapCfg,
  getUsageFileMapCfg,
  modifyUsageFileMapCfg,
  getUsageStreamMap,
  createUsageProcMapConfig,
  modifyUsageProcMapConfig,
  searchSuspendedFailedBatch,
  searchDetailRecords,
  updateFileDetailRecord,
  initiateUsageReprocess,
  initiateReratingProcess,
  getSuspendedFailedBatchDetail,
  createAndProcessUsageRecord,
  getZeroRateUsageConfig,
  modifyZeroRateUsageConfig,
  createZeroRateUsageConfig,
  searchVoicePlan,
  createVoicePlan,
  modifyVoicePlan,
  getRegionMapConfig,
  modifyRegionMapConfig,
  createRegionMapConfig,
  getTrunkRulesConfig,
  modifyTrunkRulesConfig,
  createTrunkRulesConfig,
  getTenantInterConnectIndicator,
  getTenantTrafficType,
  getTenantTrunkType,
  getTenantUsageIndicator,
  searchTrunkRulesConfigList,
  getConfigPnnPlanNumbering,
  getConfigOperatorMap,
  createConfigOperatorMap,
  modifyConfigOperatorMap,
  searchConfigOperatorMapList,
  searchConfigOperatorData,
  searchConfigOperatorProvisioning,
  searchConfigOperatorSms,
  searchConfigOperatorVoice,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* searchOperatorDisputeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchOperatorDispute(payload));
    if (response.searchOperatorDispute && response.searchOperatorDispute) {
      yield put(actions.searchOperatorDisputeSuccess(response.searchOperatorDispute));
      if (cb) yield cb({ success: true, data: response.searchOperatorDispute });
    } else {
      yield put(actions.searchOperatorDisputeSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchOperatorDisputeSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOperatorDisputeByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOperatorDisputeById(payload));
    if (response.getOperatorDisputeById && response.getOperatorDisputeById) {
      if (cb) yield cb({ success: true, data: response.getOperatorDisputeById });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchOperatorInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchOperatorInvoice(payload));
    if (response.searchOperatorInvoice && response.searchOperatorInvoice) {
      yield put(actions.searchOperatorInvoiceSuccess(response.searchOperatorInvoice));
      if (cb) yield cb({ success: true, data: response.searchOperatorInvoice });
    } else {
      yield put(actions.searchOperatorInvoiceSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchOperatorInvoiceSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOperatorInvoiceByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOperatorInvoiceById(payload));
    if (response.getOperatorInvoiceById && response.getOperatorInvoiceById) {
      if (cb) yield cb({ success: true, data: response.getOperatorInvoiceById });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchOperatorRecordLogSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchOperatorRecord(payload));
    if (response.searchOperatorRecord && response.searchOperatorRecord) {
      yield put(actions.searchOperatorRecordLogSuccess(response.searchOperatorRecord));
      if (cb) yield cb({ success: true, data: response.searchOperatorRecord });
    } else {
      yield put(actions.searchOperatorRecordLogSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchOperatorRecordLogSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOperatorRecordLogByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOperatorRecordById(payload));
    if (response.getOperatorRecordById && response.getOperatorRecordById) {
      if (cb) yield cb({ success: true, data: response.getOperatorRecordById });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchTenantDisputeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchTenantDispute(payload));
    if (response.searchTenantDispute && response.searchTenantDispute) {
      yield put(actions.searchTenantDisputeSuccess(response.searchTenantDispute));
      if (cb) yield cb({ success: true, data: response.searchTenantDispute });
    } else {
      yield put(actions.searchTenantDisputeSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchTenantDisputeSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchTenantDisputeRecordListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchTenantDisputeRecordList(payload));
    if (response.searchTenantDisputeRecordList && response.searchTenantDisputeRecordList) {
      if (cb) yield cb({ success: true, data: response.searchTenantDisputeRecordList });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchTenantOperatorRecordSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchTenantOperatorRecord(payload));
    if (response.searchTenantOperatorRecord && response.searchTenantOperatorRecord) {
      yield put(actions.searchTenantOperatorRecordSuccess(response.searchTenantOperatorRecord));
      if (cb) yield cb({ success: true, data: response.searchTenantOperatorRecord });
    } else {
      yield put(actions.searchTenantOperatorRecordSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchTenantOperatorRecordSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchTenantOperatorRecordListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchTenantOperatorRecordList(payload));
    if (response.searchTenantOperatorRecordList && response.searchTenantOperatorRecordList) {
      if (cb) yield cb({ success: true, data: response.searchTenantOperatorRecordList });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* operatorDisputeReconSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, operatorDisputeRecon(payload));
    if (response.operatorDisputeRecon) {
      yield put(isEndConnected());
      toast.success(i18n.t('common:apiMessage.reconcileDisputeSuccessfully'));
      if (cb) yield cb({ success: true });
    } else {
      yield put(isEndConnected());
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* operatorInvoiceReconSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, operatorInvoiceRecon(payload));
    if (response.operatorInvoiceRecon) {
      yield put(isEndConnected());
      toast.success(i18n.t('common:apiMessage.reconcileInvoiceSuccessfully'));
      if (cb) yield cb({ success: true });
    } else {
      yield put(isEndConnected());
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchReRatingBatchesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchReRatingBatches(payload));
    if (response.searchReRatingBatches && response.searchReRatingBatches) {
      yield put(actions.searchReRatingBatchesSuccess(response.searchReRatingBatches));
      if (cb) yield cb({ success: true, data: response.searchReRatingBatches });
    } else {
      yield put(actions.searchReRatingBatchesSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchReRatingBatchesSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* saveReratingBatchSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveReratingBatch(payload));
    if (response.saveReratingBatch) {
      toast.success(i18n.t('common:apiMessage.saveReratingBatchSuccessfully'));
      if (cb) cb({ success: true, data: response.saveReratingBatch });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getReratingBatchByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getReratingBatchById(payload));
    if (response.getReratingBatchById && response.getReratingBatchById) {
      if (cb) yield cb({ success: true, data: response.getReratingBatchById });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyReratingBatchSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyReratingBatch(payload));
    if (response.modifyReratingBatch && response.modifyReratingBatch) {
      toast.success(i18n.t('common:apiMessage.modifyReratingBatchSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifyReratingBatch });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getConfigUsagePlatformsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigUsagePlatforms(payload));
    if (response.getConfigUsagePlatforms && response.getConfigUsagePlatforms) {
      if (cb) yield cb({ success: true, data: response.getConfigUsagePlatforms });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyConfigUsagePlatformSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigUsagePlatform(payload));
    if (response.modifyConfigUsagePlatform && response.modifyConfigUsagePlatform) {
      toast.success(i18n.t('common:apiMessage.modifyConfigUsagePlatformSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifyConfigUsagePlatform });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* createConfigUsagePlatformSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigUsagePlatform(payload));
    if (response.createConfigUsagePlatform && response.createConfigUsagePlatform) {
      toast.success(i18n.t('common:apiMessage.createConfigUsagePlatformSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createConfigUsagePlatform });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

// Custom Record
export function* getCustomUsageRecCfgSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCustomUsageRecCfg());
    yield put(isEndConnected());
    if (response.getCustomUsageRecCfg && response.getCustomUsageRecCfg.id) {
      yield put(actions.getCustomUsageRecCfgSuccess(response.getCustomUsageRecCfg));
      if (cb) yield cb({ success: true, data: response.getCustomUsageRecCfg });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCustomUsageRecCfgSuccess({}));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyCustomUsageRecCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCustomUsageRecCfg(payload));
    yield put(actions.getCustomUsageRecCfg());
    if (response.modifyCustomUsageRecCfg) {
      if (cb) cb({ success: true, data: response.modifyCustomUsageRecCfg });
      toast.success(i18n.t('common:apiMessage.modifyCustomRecordsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* createCustomUsageRecCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCustomUsageRecCfg(payload));
    yield put(actions.getCustomUsageRecCfg());
    if (response.createCustomUsageRecCfg) {
      if (cb) cb({ success: true, data: response.createCustomUsageRecCfg });
      toast.success(i18n.t('common:apiMessage.createCustomRecordsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

// File Mapping
export function* getUsageFileMapCfgSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageFileMapCfg());
    yield put(isEndConnected());
    if (response.getUsageFileMapCfg && response.getUsageFileMapCfg) {
      yield put(actions.getUsageFileMapCfgSuccess(response.getUsageFileMapCfg));
      if (cb) yield cb({ success: true, data: response.getUsageFileMapCfg });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUsageFileMapCfgSuccess({}));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* createUsageFileMapCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createUsageFileMapCfg(payload));
    yield put(actions.getUsageFileMapCfg());
    if (response.createUsageFileMapCfg) {
      if (cb) cb({ success: true, data: response.createUsageFileMapCfg });
      toast.success(i18n.t('common:apiMessage.createFileMappingSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* modifyUsageFileMapCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyUsageFileMapCfg(payload));
    yield put(actions.getUsageFileMapCfg());
    if (response.modifyUsageFileMapCfg) {
      if (cb) cb({ success: true, data: response.modifyUsageFileMapCfg });
      toast.success(i18n.t('common:apiMessage.modifyFileMappingSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

// Processing Streams
export function* getUsageStreamMapSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageStreamMap());
    if (response.getUsageStreamMap && response.getUsageStreamMap) {
      yield put(actions.getUsageStreamMapSuccess(response.getUsageStreamMap));
      if (cb) yield cb({ success: true, data: response.getUsageStreamMap });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUsageStreamMapSuccess({}));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* createUsageStreamMapByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createUsageProcMapConfig(payload));
    toast.success(i18n.t('common:apiMessage.createProcessingStreamsSuccessfully'));
    if (response.createUsageProcMapConfig) {
      if (cb) cb({ success: true, data: response.createUsageProcMapConfig });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* modifyUsageProcMapConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyUsageProcMapConfig(payload));
    if (response.modifyUsageProcMapConfig) {
      if (cb) cb({ success: true, data: response.modifyUsageProcMapConfig });
      toast.success(i18n.t('common:apiMessage.modifyProcessingStreamsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* searchSuspendedFailedBatchSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchSuspendedFailedBatch(payload));
    if (response.searchSuspendedFailedBatch && response.searchSuspendedFailedBatch) {
      if (cb) yield cb({ success: true, data: response.searchSuspendedFailedBatch });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchDetailRecordsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchDetailRecords(payload));
    if (response.searchDetailRecords && response.searchDetailRecords) {
      if (cb) yield cb({ success: true, data: response.searchDetailRecords });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* updateFileDetailRecordSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateFileDetailRecord(payload));
    toast.success(i18n.t('common:apiMessage.updateFileDetailRecordSuccessfully'));
    if (response.updateFileDetailRecord) {
      if (cb) cb({ success: true, data: response.updateFileDetailRecord });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* initiateUsageReprocessSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequestJob, initiateUsageReprocess(payload));
    toast.success(i18n.t('common:apiMessage.initiateUsageReprocessSuccessfully'));
    if (response.initiateUsageReprocess) {
      if (cb) cb({ success: true, data: response.initiateUsageReprocess });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* initiateReratingProcessSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequestJob, initiateReratingProcess(payload));
    toast.success(i18n.t('common:apiMessage.initiateReratingProcessSuccessfully'));
    if (response.initiateReratingProcess) {
      if (cb) cb({ success: true, data: response.initiateReratingProcess });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* getSuspendedFailedBatchDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSuspendedFailedBatchDetail(payload));
    if (response.getSuspendedFailedBatchDetail && response.getSuspendedFailedBatchDetail) {
      if (cb) yield cb({ success: true, data: response.getSuspendedFailedBatchDetail });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* createAndProcessUsageRecordSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createAndProcessUsageRecord(payload));
    if (response.createAndProcessUsageRecord && response.createAndProcessUsageRecord) {
      toast.success(i18n.t('common:apiMessage.createAndProcessUsageRecordSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createAndProcessUsageRecord });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

// Zero Price Usage
export function* getZeroRateUsageConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getZeroRateUsageConfig());
    yield put(isEndConnected());
    if (response.getZeroRateUsageConfig && response.getZeroRateUsageConfig) {
      if (cb) yield cb({ success: true, data: response.getZeroRateUsageConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyZeroRateUsageConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyZeroRateUsageConfig(payload));
    if (response.modifyZeroRateUsageConfig) {
      if (cb) cb({ success: true, data: response.modifyZeroRateUsageConfig });
      toast.success(i18n.t('common:apiMessage.modifyZeroRateUsageSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createZeroRateUsageConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createZeroRateUsageConfig(payload));
    if (response.createZeroRateUsageConfig) {
      if (cb) cb({ success: true, data: response.createZeroRateUsageConfig });
      toast.success(i18n.t('common:apiMessage.createZeroRateUsageSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Voice Plan
export function* searchVoicePlanSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchVoicePlan(payload));
    yield put(isEndConnected());
    if (response.searchVoicePlan) {
      yield put(actions.searchVoicePlanSuccess(response.searchVoicePlan));
      if (cb) yield cb({ success: true, data: response.searchVoicePlan });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchVoicePlanSuccess([]));
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* modifyVoicePlanSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyVoicePlan(payload));
    if (response.modifyVoicePlan) {
      if (cb) cb({ success: true, data: response.modifyVoicePlan });
      toast.success(i18n.t('common:apiMessage.modifyVoicePlanSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createVoicePlanSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createVoicePlan(payload));
    if (response.createVoicePlan) {
      if (cb) cb({ success: true, data: response.createVoicePlan });
      toast.success(i18n.t('common:apiMessage.createVoicePlanSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Region Mapping
export function* getRegionMapConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegionMapConfig());
    yield put(isEndConnected());
    if (response.getRegionMapConfig && response.getRegionMapConfig) {
      if (cb) yield cb({ success: true, data: response.getRegionMapConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyRegionMapConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRegionMapConfig(payload));
    // yield put(actions.getTaxCodeConfig());
    if (response.modifyRegionMapConfig) {
      if (cb) cb({ success: true, data: response.modifyRegionMapConfig });
      toast.success(i18n.t('common:apiMessage.modifyRegionMapSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createRegionMapConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRegionMapConfig(payload));
    if (response.createRegionMapConfig) {
      if (cb) cb({ success: true, data: response.createRegionMapConfig });
      toast.success(i18n.t('common:apiMessage.createRegionMapSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Trunk Rules
export function* getTrunkRulesConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTrunkRulesConfig());
    yield put(isEndConnected());
    if (response.getTrunkRulesConfig && response.getTrunkRulesConfig.id) {
      yield put(actions.getTrunkRulesConfigSuccess(response.getTrunkRulesConfig));
      if (cb) yield cb({ success: true, data: response.getTrunkRulesConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTrunkRulesConfigSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyTrunkRulesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTrunkRulesConfig(payload));
    if (response.modifyTrunkRulesConfig) {
      if (cb) cb({ success: true, data: response.modifyTrunkRulesConfig });
      toast.success(i18n.t('common:apiMessage.modifyTrunkRuleSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createTrunkRulesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTrunkRulesConfig(payload));
    if (response.createTrunkRulesConfig) {
      toast.success(i18n.t('common:apiMessage.createTrunkRuleSuccessfully'));
      if (cb) cb({ success: true, data: response.createTrunkRulesConfig });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getTenantInterConnectIndicatorSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantInterConnectIndicator());
    yield put(isEndConnected());
    if (response.getTenantInterConnectIndicator && response.getTenantInterConnectIndicator) {
      if (cb) yield cb({ success: true, data: response.getTenantInterConnectIndicator });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getTenantTrafficTypeSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantTrafficType());
    yield put(isEndConnected());
    if (response.getTenantTrafficType && response.getTenantTrafficType) {
      if (cb) yield cb({ success: true, data: response.getTenantTrafficType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getTenantTrunkTypeSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantTrunkType());
    yield put(isEndConnected());
    if (response.getTenantTrunkType && response.getTenantTrunkType) {
      if (cb) yield cb({ success: true, data: response.getTenantTrunkType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getTenantUsageIndicatorSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantUsageIndicator());
    yield put(isEndConnected());
    if (response.getTenantUsageIndicator && response.getTenantUsageIndicator) {
      if (cb) yield cb({ success: true, data: response.getTenantUsageIndicator });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchTrunkRulesConfigListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchTrunkRulesConfigList(payload));
    yield put(isEndConnected());
    if (response.searchTrunkRulesConfigList && response.searchTrunkRulesConfigList) {
      yield put(actions.getTrunkRulesConfigSuccess(response.searchTrunkRulesConfigList));
      if (cb) yield cb({ success: true, data: response.searchTrunkRulesConfigList });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTrunkRulesConfigSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigPnnPlanNumberingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigPnnPlanNumbering(payload));
    yield put(isEndConnected());
    if (response.getConfigPnnPlanNumbering && response.getConfigPnnPlanNumbering) {
      yield put(actions.getConfigPnnPlanNumberingSuccess(response.getConfigPnnPlanNumbering));
      if (cb) yield cb({ success: true, data: response.getConfigPnnPlanNumbering });
    } else {
      yield put(actions.getConfigPnnPlanNumberingSuccess(null));
      if (cb) yield cb({ success: false, data: null });
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getConfigPnnPlanNumberingSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

// Operator Map
export function* getConfigOperatorMapSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigOperatorMap());
    yield put(isEndConnected());
    if (response.getConfigOperatorMap && response.getConfigOperatorMap.id) {
      yield put(actions.getOperatorMapSuccess(response.getConfigOperatorMap));
      if (cb) yield cb({ success: true, data: response.getConfigOperatorMap });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOperatorMapSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyConfigOperatorMaptSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigOperatorMap(payload));
    if (response.modifyConfigOperatorMap) {
      if (cb) cb({ success: true, data: response.modifyConfigOperatorMap });
      toast.success(i18n.t('common:apiMessage.modifyOperatorMapSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createConfigOperatorMapSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigOperatorMap(payload));
    if (response.createConfigOperatorMap) {
      toast.success(i18n.t('common:apiMessage.createOperatorMapSuccessfully'));
      if (cb) cb({ success: true, data: response.createConfigOperatorMap });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchConfigOperatorMapListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigOperatorMapList(payload));
    yield put(isEndConnected());
    if (response.searchConfigOperatorMapList && response.searchConfigOperatorMapList) {
      yield put(actions.getOperatorMapSuccess(response.searchConfigOperatorMapList));
      if (cb) yield cb({ success: true, data: response.searchConfigOperatorMapList });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOperatorMapSuccess([]));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchConfigOperatorDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigOperatorData(payload));
    yield put(isEndConnected());
    if (response.searchConfigOperatorData) {
      if (cb) yield cb({ success: true, data: response.searchConfigOperatorData });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* searchConfigOperatorProvisioningSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigOperatorProvisioning(payload));
    yield put(isEndConnected());
    if (response.searchConfigOperatorProvisioning) {
      if (cb) yield cb({ success: true, data: response.searchConfigOperatorProvisioning });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* searchConfigOperatorSmsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigOperatorSms(payload));
    yield put(isEndConnected());
    if (response.searchConfigOperatorSms) {
      if (cb) yield cb({ success: true, data: response.searchConfigOperatorSms });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* searchConfigOperatorVoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigOperatorVoice(payload));
    yield put(isEndConnected());
    if (response.searchConfigOperatorVoice) {
      if (cb) yield cb({ success: true, data: response.searchConfigOperatorVoice });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* paymentSaga() {
  yield takeLatest(types.SEARCH_OPERATOR_DISPUTE, searchOperatorDisputeSaga);
  yield takeLatest(types.GET_OPERATOR_BY_ID, getOperatorDisputeByIdSaga);
  yield takeLatest(types.SEARCH_OPERATOR_INVOICE, searchOperatorInvoiceSaga);
  yield takeLatest(types.GET_OPERATOR_INVOICE_BY_ID, getOperatorInvoiceByIdSaga);
  yield takeLatest(types.SEARCH_OPERATOR_RECORD_LOG, searchOperatorRecordLogSaga);
  yield takeLatest(types.GET_OPERATOR_RECORD_LOG_BY_ID, getOperatorRecordLogByIdSaga);
  yield takeLatest(types.SEARCH_TENANT_DISPUTE, searchTenantDisputeSaga);
  yield takeLatest(types.GET_TENANT_DISPUTE_BY_ID, searchTenantDisputeRecordListSaga);
  yield takeLatest(types.SEARCH_TENANT_RECORD, searchTenantOperatorRecordSaga);
  yield takeLatest(types.GET_TENANT_RECORD_BY_ID, searchTenantOperatorRecordListSaga);
  yield takeLatest(types.OPERATOR_INVOICE_RECON, operatorInvoiceReconSaga);
  yield takeLatest(types.OPERATOR_DISPUTE_RECON, operatorDisputeReconSaga);
  yield takeLatest(types.SEARCH_RERATING_BATCHES, searchReRatingBatchesSaga);
  yield takeLatest(types.SAVE_RERATING_BATCH, saveReratingBatchSaga);
  yield takeLatest(types.GET_RERATING_BY_ID, getReratingBatchByIdSaga);
  yield takeLatest(types.MODIFY_RERATING_BATCH, modifyReratingBatchSaga);
  yield takeLatest(types.GET_CONFIG_USAGE_PLATFORMS, getConfigUsagePlatformsSaga);
  yield takeLatest(types.CREATE_CONFIG_USAGE_PLATFORM, createConfigUsagePlatformSaga);
  yield takeLatest(types.MODIFY_CONFIG_USAGE_PLATFORM, modifyConfigUsagePlatformSaga);
  yield takeLatest(types.GET_CUSTOM_RECORD_CFG, getCustomUsageRecCfgSaga);
  yield takeLatest(types.MODIFY_CUSTOM_RECORD_CFG, modifyCustomUsageRecCfgSaga);
  yield takeLatest(types.CREATE_CUSTOM_RECORD_CFG, createCustomUsageRecCfgSaga);
  yield takeLatest(types.GET_USAGE_FILE_MAPPING_CFG, getUsageFileMapCfgSaga);
  yield takeLatest(types.CREATE_FILE_MAPPING, createUsageFileMapCfgSaga);
  yield takeLatest(types.MODIFY_FILE_MAPPING, modifyUsageFileMapCfgSaga);
  yield takeLatest(types.GET_USAGE_STREAM_MAPPING_CFG, getUsageStreamMapSaga);
  yield takeLatest(types.CREATE_STREAM_MAPPING, createUsageStreamMapByTypeSaga);
  yield takeLatest(types.MODIFY_STREAM_MAPPING, modifyUsageProcMapConfigSaga);
  yield takeLatest(types.SEARCH_SUSPENDED_FAILED_BATCH, searchSuspendedFailedBatchSaga);
  yield takeLatest(types.SEARCH_DETAIL_RECORDS, searchDetailRecordsSaga);
  yield takeLatest(types.UPLOAD_FILE_DETAIL_RECORD, updateFileDetailRecordSaga);
  yield takeLatest(types.INITIATE_USAGE_REPROCESS, initiateUsageReprocessSaga);
  yield takeLatest(types.INITIATE_RERATING_REPROCESS, initiateReratingProcessSaga);
  yield takeLatest(types.GET_SUSPENDED_FAILED_BATCH_DETAIL, getSuspendedFailedBatchDetailSaga);
  yield takeLatest(types.CREATE_AND_PROCESS_USAGE_RECORD, createAndProcessUsageRecordSaga);
  yield takeLatest(types.GET_ZERO_RATE_USAGE_CONFIG, getZeroRateUsageConfigSaga);
  yield takeLatest(types.MODIFY_ZERO_RATE_USAGE_CONFIG, modifyZeroRateUsageConfigSaga);
  yield takeLatest(types.CREATE_ZERO_RATE_USAGE_CONFIG, createZeroRateUsageConfigSaga);
  yield takeLatest(types.GET_VOICE_PLAN, searchVoicePlanSaga);
  yield takeLatest(types.CREATE_VOICE_PLAN, createVoicePlanSaga);
  yield takeLatest(types.MODIFY_VOICE_PLAN, modifyVoicePlanSaga);
  yield takeLatest(types.GET_REGION_MAP_CONFIG, getRegionMapConfigSaga);
  yield takeLatest(types.MODIFY_REGION_MAP_CONFIG, modifyRegionMapConfigSaga);
  yield takeLatest(types.CREATE_REGION_MAP_CONFIG, createRegionMapConfigSaga);
  yield takeLatest(types.GET_TRUNK_RULES, getTrunkRulesConfigSaga);
  yield takeLatest(types.MODIFY_TRUNK_RULES, modifyTrunkRulesConfigSaga);
  yield takeLatest(types.CREATE_TRUNK_RULES, createTrunkRulesConfigSaga);
  yield takeLatest(types.GET_TENANT_USAGE_INDICATOR, getTenantUsageIndicatorSaga);
  yield takeLatest(types.GET_TENANT_TRUNK_TYPE, getTenantTrunkTypeSaga);
  yield takeLatest(types.GET_TENANT_TRAFFIC_TYPE, getTenantTrafficTypeSaga);
  yield takeLatest(types.GET_TENANT_INTER_CONNECT_INDICATOR, getTenantInterConnectIndicatorSaga);
  yield takeLatest(types.SEARCH_TRUNK_RULES_CONFIG_LIST, searchTrunkRulesConfigListSaga);
  yield takeLatest(types.GET_CONFIG_PNP_PLAN_NUMBER, getConfigPnnPlanNumberingSaga);

  yield takeLatest(types.GET_OPERATOR_MAP, getConfigOperatorMapSaga);
  yield takeLatest(types.MODIFY_OPERATOR_MAP, modifyConfigOperatorMaptSaga);
  yield takeLatest(types.CREATE_OPERATOR_MAP, createConfigOperatorMapSaga);
  yield takeLatest(types.SEARCH_CONFIG_OPERATOR_MAP_LIST, searchConfigOperatorMapListSaga);
  yield takeLatest(types.SEARCH_CONFIG_OPERATOR_DATA, searchConfigOperatorDataSaga);
  yield takeLatest(types.SEARCH_CONFIG_OPERATOR_PROVISIONING, searchConfigOperatorProvisioningSaga);
  yield takeLatest(types.SEARCH_CONFIG_OPERATOR_SMS, searchConfigOperatorSmsSaga);
  yield takeLatest(types.SEARCH_CONFIG_OPERATOR_VOICE, searchConfigOperatorVoiceSaga);
}
