import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class FailedPaymentSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.thresholdAmount',
      name: 'thresholdAmount',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.failureReason',
      name: 'failureReason',
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      type: 'select',
      tOptions: 'selections:paymentMethod',
      isClearable: true,
    },
    // {
    //   label: 'label.paymentTransactionType',
    //   name: 'paymentTransactionType',
    //   tOptions: 'selections:paymentTransactionType',
    //   isClearable: true,
    //   type: 'select',
    // },
  ];

  btnSpan = 'col-md-6';
}

FailedPaymentSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

FailedPaymentSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(FailedPaymentSearchForm);
