import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GenericInput } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import DependencyComponent from './DependencyComponent';
import ModalBundleItem from './ModalBundleItem';
import ModalPackageItem from './ModalPackageItem';

class DependencyItemForm extends Component {
  state = {
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
    lockedType: null,
    dependencyLists: [],
    isSelectingBundleId: false,
    isSelectingPackageItemId: false,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    let lockedType = props.item.bundleId ? 'bundleId' : null;
    lockedType = !lockedType && !!props.item.packageId ? 'packageId' : lockedType;
    return {
      ...props.item,
      lockedType,
      dependencyLists: props.item.dependencyLists || [],
      item: props.item,
    };
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onCancelSelector = () => {
    this.setState({ isSelectingParent: false });
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onAddNewComponent = e => {
    e.preventDefault();
    let { dependencyLists } = this.state;
    const { creatingMode } = this.props;
    dependencyLists = dependencyLists || [];
    let lastIndex = 0;
    dependencyLists.forEach(item => {
      if (item.index > lastIndex) lastIndex = item.index;
    });
    if (creatingMode)
      return this.setState({
        dependencyLists: [...dependencyLists, { type: null, packageId: null, bundleId: null, isNew: true }],
      });
    return this.setState({ dependencyLists: [...dependencyLists, { index: lastIndex + 1, type: null, isNew: true }] });
  };

  onAddNewComponentFromSelect = rows => {
    let { dependencyLists } = this.state;
    dependencyLists = dependencyLists || [];
    let lastIndex = 0;
    dependencyLists.forEach(item => {
      if (item.index > lastIndex) lastIndex = item.index;
    });
    this.setState({ dependencyLists: [...dependencyLists, ...rows] });
  };

  handleChangeComponents = dependencyLists => {
    let lockedType = dependencyLists && dependencyLists.find(d => !!d.bundleId) ? 'bundleId' : null;
    lockedType = !lockedType && dependencyLists && dependencyLists.find(d => !!d.packageId) ? 'packageId' : null;
    this.setState({ dependencyLists, lockedType });
  };

  onSelectBundleId = (bundleId, row) => {
    const lockedType = bundleId ? 'bundleId' : null;
    this.setState({
      isSelectingBundleId: false,
      isSelectingPackageItemId: false,
      bundleId: bundleId || null,
      bundleName: row ? row.name : null,
      name: row ? row.name : null,
      lockedType,
    });
  };

  onSelectPackageItemId = (packageId, row) => {
    const lockedType = packageId ? 'packageId' : null;
    this.setState({
      isSelectingBundleId: false,
      isSelectingPackageItemId: false,
      packageName: row ? row.name : null,
      name: row ? row.name : null,
      packageId: packageId || null,
      lockedType,
    });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingBundleId: false, isSelectingPackageItemId: false });
  };

  handleClickBundleId = e => {
    e.preventDefault();
    this.setState({
      isSelectingBundleId: true,
      isSelectingPackageItemId: false,
    });
  };

  handleClickPackageId = e => {
    e.preventDefault();
    this.setState({
      isSelectingBundleId: false,
      isSelectingPackageItemId: true,
    });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { onSubmit } = this.props;
    const { id, bundleId, packageId, startDate, endDate, dependencyLists } = this.state;
    const newDependencyLists =
      dependencyLists && dependencyLists.length
        ? cloneDeep(dependencyLists).map(val => {
            const { name, ...rest } = val;
            return rest;
          })
        : null;
    const data = {
      id: id || null,
      bundleId: bundleId || null,
      packageId: packageId || null,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      dependencyLists: newDependencyLists,
    };
    if (onSubmit) {
      onSubmit(data, ({ success }) => {
        this.setState({ wasValidated: !success });
      });
    }
  };

  onCancelData = () => {
    this.setState({
      id: null,
      bundleId: null,
      packageId: null,
      startDate: null,
      endDate: null,
      dependencyLists: [],
    });
  };

  render() {
    const {
      creatingMode,
      modeGetBundleDependency,
      modeGetPackageDependency,
      modeModifyDependency,
      t,
      onCancel,
    } = this.props;
    const {
      packageId,
      lockedType,
      bundleId,
      wasValidated,
      dependencyLists,
      item,
      isSelectingBundleId,
      selectedBundleId,
      isSelectingPackageItemId,
      selectedPackageItemId,
    } = this.state;

    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;

    let listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
        disabled: !creatingMode,
      },
      {
        label: 'common:label.bundleName',
        wrapperClass: 'col-md-4',
        name: 'bundleName',
        disabled: !!packageId || lockedType === 'packageId' || modeGetPackageDependency === 1,
        readOnly: !!packageId || lockedType === 'packageId',
        type: 'text',
        onClick: packageId ? null : this.handleClickBundleId,
        fa: 'fa fa-external-link',
      },
      {
        label: 'common:label.packageName',
        wrapperClass: 'col-md-4',
        name: 'packageName',
        disabled: !!bundleId || lockedType === 'bundleId' || modeGetPackageDependency === 1,
        readOnly: !!bundleId || lockedType === 'bundleId',
        type: 'text',
        onClick: bundleId ? null : this.handleClickPackageId,
        fa: 'fa fa-external-link',
      },
      {
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        name: 'startDate',
        type: 'date',
      },
      {
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        name: 'endDate',
        type: 'date',
      },
    ];

    if (modeGetBundleDependency === 0) listInputs = listInputs.filter(item => item.name !== 'bundleId');
    if (modeGetPackageDependency === 0) listInputs = listInputs.filter(item => item.name !== 'packageId');

    return (
      <form
        onSubmit={this.handleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        noValidate
        ref={this.formRef}
      >
        <div className="form-row">
          {listInputs.map(input => (
            <GenericInput
              {...input}
              key={input.name}
              value={
                !creatingMode &&
                ((input.name === 'bundleName' && this.state.bundleId) ||
                  (input.name === 'packageName' && this.state.packageId))
                  ? this.state.name
                  : this.state[input.name]
              }
              onChange={this.handleInputChange}
            />
          ))}
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <DependencyComponent
              components={dependencyLists}
              onChangeComponents={this.handleChangeComponents}
              lockedType={lockedType}
              creatingMode={creatingMode}
              onAddNewComponentFromSelect={this.onAddNewComponentFromSelect}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12 action-buttons">
            <label htmlFor="validationCustom01" />
            <br />
            <button
              type="button"
              className={`btn-new-component button button-border x-small float-left ${
                wasValidated && (!dependencyLists || dependencyLists.length === 0) ? 'btn btn-danger' : ''
              }`}
              onClick={this.onAddNewComponent}
            >
              {t('label.addNewDependencyList')}
            </button>
            {item && item.id && modeModifyDependency === 2 && (
              <Link
                to={{ pathname: RouteNames.dependencyItemAdd.path, state: dataCopy2Create }}
                className="button button-border gray x-small float-right"
              >
                {t('label.copyToCreate')}
              </Link>
            )}
            {item && item.id && modeModifyDependency === 2 && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.modify')}
              </button>
            )}
            {!(item && item.id) && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.create')}
              </button>
            )}
            {onCancel ? (
              <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
                {t('label.cancel')}
              </button>
            ) : (
              <button
                onClick={this.onCancelData}
                type="button"
                className="button button-border black x-small  float-right"
              >
                {t('label.cancel')}
              </button>
            )}
          </div>
        </div>
        <ModalBundleItem
          isOpen={isSelectingBundleId}
          selectedParentId={selectedBundleId}
          onSelect={this.onSelectBundleId}
          onCancel={this.onCancelSelector}
          isRequired={false}
        />
        <ModalPackageItem
          isOpen={isSelectingPackageItemId}
          selectedParentId={selectedPackageItemId}
          onSelect={this.onSelectPackageItemId}
          onCancel={this.onCancelSelector}
          isRequired={false}
        />
      </form>
    );
  }
}

DependencyItemForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  modeGetBundleDependency: PropTypes.number,
  modeGetPackageDependency: PropTypes.number,
  modeModifyDependency: PropTypes.number,
};

DependencyItemForm.defaultProps = {
  item: {},
  onSubmit: () => {},
  isSubmitting: false,
  creatingMode: true,
  modeGetBundleDependency: 2,
  modeGetPackageDependency: 2,
  modeModifyDependency: 0,
};
export default withTranslation('common')(DependencyItemForm);
