import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accountType',
        'initialTermUnit',
        'renewalTermUnit',
        'marketSegment',
      ])}`
    : '';
  const getActiveSubscriptionReport = `getActiveSubscriptionReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getActiveSubscriptionReport} {
        id
        accountId
        company
        firstName
        lastName
        subscriptionId
        effectiveDate
        startDate
        endDate
        sellingCompany
        accountType
        initialTermUnit
        renewalTermUnit
        initialTerm
        renewalTerm
        status
      }
    }
  `;
};
