import React from 'react';

export default {
  groupNamePermissions: {
    CREATE_TASK: 'CREATE_TASK',
    MODIFY_TASK: 'MODIFY_TASK',
    SEARCH_TASKS: 'SEARCH_TASKS',
    CREATE_CHILD_TASK: 'CREATE_CHILD_TASK',
    GET_CHILD_TASKS: 'GET_CHILD_TASKS',
    SYNC_TASK_TO_EXTERNAL: 'SYNC_TASK_TO_EXTERNAL',
    SYNC_TASK_STATUS_FROM_EXTERNAL: 'SYNC_TASK_STATUS_FROM_EXTERNAL',
    TASK_ADMIN: 'TASK_ADMIN',
  },
};

export const searchTaskColumns = [
  {
    name: 'parentTaskId',
    label: 'label.parentTaskId',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'taskType',
    label: 'label.type',
  },
  {
    name: 'taskCategory',
    label: 'label.category',
  },
  {
    name: 'taskDescription',
    label: 'label.description',
  },
  {
    name: 'userId',
    label: 'label.userId',
  },
  {
    name: 'userEmail',
    label: 'label.userEmail',
  },
  {
    name: 'userFirstname',
    label: 'label.userFirstname',
  },
  {
    name: 'userLastName',
    label: 'label.userLastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    fieldOption: 'accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const taskSort = {
  ticketId: {
    asc: 'ticketId_ASC',
    desc: 'ticketId_DESC',
  },
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

export const listFieldsCreateEditTask = (isAdmin, isFromCustomer) => [
  {
    name: 'id',
    label: 'label.idHistory',
    isNonModify: true,
  },
  {
    name: 'status',
    label: 'label.status',
  },
  {
    name: 'ticketId',
    label: 'label.ticketId',
  },
  {
    name: 'createdDate',
    label: 'label.date',
    type: 'date',
    isNonModify: true,
  },
  {
    name: 'parentTaskId',
    label: 'label.parentTaskId',
    isNoSupportCreate: true,
    isNonModify: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    fa: 'fa fa-external-link',
    readOnly: isFromCustomer,
    disabled: isFromCustomer,
  },
  {
    name: 'organization',
    label: 'label.organization',
    readOnly: true,
  },
  {
    name: 'taskType',
    label: 'label.type',
  },
  {
    name: 'taskCategory',
    label: 'label.category',
  },
  {
    name: 'taskDescription',
    label: 'label.description',
    type: 'textarea',
  },
  {
    name: 'userId',
    label: 'label.userId',
    fa: 'fa fa-external-link',
    disabled: !isAdmin,
  },
  {
    name: 'userEmail',
    label: 'label.userEmail',
    readOnly: true,
  },
  {
    name: 'userFirstname',
    label: 'label.userFirstname',
    readOnly: true,
  },
  {
    name: 'userLastName',
    label: 'label.userLastName',
    readOnly: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    readOnly: true,
  },
  {
    name: 'notes',
    label: 'label.notes',
    type: 'textarea',
  },
];

export const tableChildTask = [
  ...searchTaskColumns.map(item => {
    const { sortable, ...rest } = item;
    return { ...rest };
  }),
  {
    name: 'notes',
    label: 'label.notes',
  },
];

export const tableTaskHistory = [
  {
    name: 'modifiedDate',
    label: 'label.date',
  },
  {
    name: 'status',
    label: 'label.status',
  },
  { name: 'ticketId', label: 'label.ticketId' },
  ...searchTaskColumns
    .filter(val => val.name !== 'parentTaskId')
    .map(item => {
      const { sortable, ...rest } = item;
      return { ...rest };
    }),
  {
    name: 'notes',
    label: 'label.notes',
  },
];
