import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import CollectionAdmin from './CollectionAdmin';
import RouteName from '../App/RouteNames';
import SideBar from './Sidebar';
import CollectionAgent from './CollectionAgent';
import CollectionConfig from './CollectionConfig';
import CollectionProfile from './CollectionProfile';
import { makeGetPermissionsCollectionManagement } from '../App/selectors';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Collections extends PureComponent {
  render() {
    const { permissionsCollections } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsCollections, listKeys: permissionList.collections })) return null;
    return (
      <div className="container-fluid">
        <div className="row">
          <SideBar />
          <Switch>
            <Route
              exact
              path={RouteName.collections.path}
              render={() => <Redirect to={RouteName.collectionsSearchAccount.path} />}
            />
            <Route exact path={RouteName.collectionActionsConfig.path} component={CollectionConfig} />
            <Route exact path={RouteName.collectionProfile.path} component={CollectionProfile} />
            <Route exact path={RouteName.collectionSchedule.path} component={CollectionConfig} />
            <Route exact path={RouteName.collectionAgents.path} component={CollectionConfig} />
            <Route exact path={RouteName.collectionsAdmin.path} component={CollectionAdmin} />
            <Route exact path={RouteName.collectionsAgentChild.path} component={CollectionAgent} />
            <Route
              exact
              path={RouteName.collectionsAgent.path}
              render={() => <Redirect to={RouteName.collectionsSearchAccount.path} />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'collectionsReducer', reducer });
const withSaga = injectSaga({ key: 'collectionsSaga', saga });

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducer, withSaga, withReducerReport, withSagaReport, withConnect)(Collections);
