export const SEARCH_TASKS = 'app/tasks/SEARCH_TASKS';
export const SEARCH_TASKS_SUCCESS = 'app/tasks/SEARCH_TASKS_SUCCESS';

export const SEARCH_ALL_TASKS = 'app/tasks/SEARCH_ALL_TASKS';
export const SEARCH_ALL_TASKS_SUCCESS = 'app/tasks/SEARCH_ALL_TASKS_SUCCESS';

export const CREATE_TASK = 'app/tasks/CREATE_TASK';
export const GET_TASK_HISTORY_BY_ID = 'app/tasks/GET_TASK_HISTORY_BY_ID';
export const SYNC_TASK_TO_EXTERNAL = 'app/tasks/SYNC_TASK_TO_EXTERNAL';

export const MODIFY_TASK = 'app/tasks/MODIFY_TASK';
export const GET_CHILD_TASK_BY_PARENT_ID = 'app/tasks/GET_CHILD_TASK_BY_PARENT_ID';
