import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['ratingType', 'productLine', 'status'])}`
    : '';
  const getUsageDetailReport = `getUsageDetailReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getUsageDetailReport} {
        batchId
        fileName
        fileDate
        source
        startDate
        endDate
        provisioningId
        usageClass
        errorCode
        usageId
        headerId
        itemId
        quantityUnit
        grossValue
        netValue
        recordIndicator
        status
      }
    }
  `;
};
