export default [
  {
    method: 'CHECK',
    description: 'Check Payments',
  },
  {
    method: 'CREDIT_CARD',
    description: 'CC Payments',
  },
  {
    method: 'NON_PAYING',
    description: 'Non_paying child account',
  },
];
