import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'thresholdAmount',
        'accountType',
        'paymentMethod',
      ])}`
    : '';
  const getFailedPaymentsReport = `getFailedPaymentsReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getFailedPaymentsReport} {
        accountId
        company
        firstName
        lastName
        accountType
        paymentDate
        invoiceUnitId
        paymentMethod
        amount
        failureReason
        paymentTerm
      }
    }
  `;
};
