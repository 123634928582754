import gql from 'graphql-tag';

export default id => gql`
  query {
    getUnallocatedPayment(input: { accountId: "${id}" }) {
      accountId
      unallocatedAmount
    }
  }
`;
