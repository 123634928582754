import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class ItemServicesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusPricing',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
    },
    {
      label: 'common:label.serviceProductCode',
      name: 'serviceProductCode',
    },
    {
      label: 'common:label.chargeType',
      name: 'chargeType',
      tOptions: 'selections:chargeType',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'common:label.isParent',
      name: 'isParent',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-12';
}

ItemServicesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ItemServicesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ItemServicesSearchForm);
