import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  revenueMilestone: [],
  revenueMilestoneItem: [],
  exchangeRate: [],
  exchangeRateId: [],
  exchangeRateExportId: [],
  accountingLogs: [],
  ebsExtract: [],
  listAllEbsExtract: [],
  listRevenueJournal: [],
  listAllRevenueJournal: [],
  listAllAccountingEntry: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_REVENUE_MILESTONE_SUCCESS:
      return state.set('revenueMilestone', action.payload);

    case types.GET_REVENUE_MILESTONE_BY_ID_SUCCESS:
      return state.set('revenueMilestoneItem', action.payload);

    case types.SEARCH_EXCHANGE_RATE_SUCCESS:
      return state.set('exchangeRate', action.payload);

    case types.GET_EXCHANGE_RATE_BY_ID_SUCCESS:
      return state.set('exchangeRateId', action.payload);

    case types.EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS:
      return state.set('exchangeRateExportId', action.payload);

    case types.SEARCH_ACCOUNTING_ENTRY_SUCCESS:
      return state.set('accountingLogs', action.payload);

    case types.SEARCH_EBS_EXTRACT_SUCCESS:
      return state.set('ebsExtract', action.payload);

    case types.SEARCH_REVENUE_JOURNAL_SUCCESS:
      return state.set('listRevenueJournal', action.payload);

    case types.SEARCH_ALL_REVENUE_JOURNAL_SUCCESS:
      return state.set('listAllRevenueJournal', action.payload);

    case types.SEARCH_ALL_ACCOUNT_ENTRY_SUCCESS:
      return state.set('listAllAccountingEntry', action.payload);

    case types.SEARCH_ALL_EBS_EXTRACT_SUCCESS:
      return state.set('listAllEbsExtract', action.payload);

    default:
      return state;
  }
}
