import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  tenantData: {},
  ccpProperties: [],
  getPaymentMethodUrls: {},
  getGatewayCanonicalConfigByName: [],
  gatewayApiMapByName: [],
  idSequenceConfig: [],
  tenantDataConfig: {},
  tenantProfileConfig: {},
  tenantMerchantDataConfig: {},
  tenantDataGateway: {},
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_TENANT_CONFIG_SUCCESS:
      return state.set('tenantData', action.payload);

    case types.MODIFY_TENANT_SUCCESS:
      return state.set('tenantData', action.payload);

    case types.GET_CCP_PROPERTIES_SUCCESS:
      return state.set('ccpProperties', action.payload);

    case types.GET_PAYMENT_METHOD_URL_SUCCESS:
      return state.set('getPaymentMethodUrls', action.payload);

    case types.GET_GATEWAY_CANONICAL_CONFIG_BY_NAME_SUCCESS:
      return state.set('getGatewayCanonicalConfigByName', action.payload);

    case types.GET_GATEWAY_API_MAP_BY_NAME_SUCCESS:
      return state.set('gatewayApiMapByName', action.payload);

    case types.GET_ID_SEQUENCE_CONFIG_SUCCESS:
      return state.set('idSequenceConfig', action.payload);

    case types.GET_TENANT_DATA_CONFIG_SUCCESS:
      return state.set('tenantDataConfig', action.payload);

    case types.GET_TENANT_PROFILE_CONFIG_SUCCESS:
      return state.set('tenantProfileConfig', action.payload);

    case types.GET_TENANT_MERCHANT_DATA_CONFIG_SUCCESS:
      return state.set('tenantMerchantDataConfig', action.payload);

    case types.GET_GATEWAY_DATA_SUCCESS:
      return state.set('tenantDataGateway', action.payload);
    // case types.GET_GETEWAY_API_MAP_BY_ID_SUCCESS:
    //   console.log('action.payload', action.payload);
    //   return '';
    // return state.set('gatewayApiMapByName', action.payload);

    default:
      return state;
  }
}
