import gql from 'graphql-tag';

export default id => {
  const syncProjectApp = `syncProjectApp(input: { accountId: "${id}" })`;
  return gql`
  mutation {
    ${syncProjectApp} {
      id
    }
  }
`;
};
