import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class UsageSummarySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
    },
    {
      label: 'label.ratingType',
      name: 'ratingType',
      type: 'select',
      tOptions: 'selections:recordIndicator',
      isClearable: true,
    },

    {
      label: 'label.source',
      name: 'source',
      type: 'select',
      options: [],
      isClearable: true,
    },
    {
      label: 'label.fileNameBatchId',
      name: 'fileName',
    },
  ];

  btnSpan = 'col-md-9';
}

UsageSummarySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UsageSummarySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UsageSummarySearchForm);
