import gql from 'graphql-tag';

export default fileName => {
  const operatorInvoiceRecon = `operatorInvoiceRecon(fileName: "${fileName}")`;
  return gql`
    mutation {
      ${operatorInvoiceRecon} {
        id
      }
    }
  `;
};
