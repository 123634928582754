import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderSplitOpportunity(input: { orderId: "${id}" }) {
      orderId
      splitOrder {
        order {
          billables {
            serviceId 
            omsInternalId
            quantity
            accountId
            splitAccounts {
              accountId
              quantity
              totalPrice
              servicePrice
            }
          }
        }
        billingAccounts {
          id
        }
      }
    }
  }
`;
