import React, { useState, useEffect } from 'react';
import PropsType from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../../App/RouteNames';
import { getZonesByZoneUnitId, modifyZoneUnit } from '../../actions';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';
import { Breadcrumb } from '../../../../components/common';
import ZoneUnitForm from './ZoneUnitForm';
import { checkPermissionModifyZoneUnit } from '../../CheckPermission';

const ZoneUnitModify = ({ getZonesByZoneUnitId, modifyZoneUnit, match, location, permissionsPricing }) => {
  const { t } = useTranslation('common');
  const [data, setData] = useState({ zones: [] });

  let modeModifyZoneUnit = 0;

  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermission = permissionsPricing.pricingModulePermissions;
    modeModifyZoneUnit = checkPermissionModifyZoneUnit({ listPermission });
  }

  const onSubmit = payload => {
    modifyZoneUnit(payload);
  };

  useEffect(() => {
    const {
      params: { id },
    } = match;
    const { state } = location;
    if (modeModifyZoneUnit)
      getZonesByZoneUnitId(id, ({ success, data }) => {
        if (success) {
          let newData = cloneDeep(data);
          newData.zones = data || [];
          if (state && state.item)
            newData = {
              zones: newData.zones || null,
              id: state?.item?.id || null,
              name: state?.item?.name || null,
              description: state?.item?.description || null,
              serviceType: state?.item?.serviceType || null,
              zoneType: state?.item?.zoneType || null,
              status: state?.item?.status || null,
            };
          setData(newData);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getZonesByZoneUnitId, modeModifyZoneUnit]);

  if (!modeModifyZoneUnit) return null;

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            <NavLink
              className="button x-small"
              to={{ pathname: RouteNames.zoneUnit.path, state: location && location.state ? location.state : null }}
            >
              {t('label.back')}
            </NavLink>
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
                { name: t('label.zoneUnitEdit') },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <ZoneUnitForm defaultData={data} onSubmit={onSubmit} isDisabledSave={modeModifyZoneUnit === 1} isSupportScroll />
    </div>
  );
};

ZoneUnitModify.propTypes = {
  getZonesByZoneUnitId: PropsType.func.isRequired,
  modifyZoneUnit: PropsType.func.isRequired,
  match: PropsType.objectOf(PropsType.any),
  permissionsPricing: PropsType.objectOf(PropsType.any),
  location: PropsType.objectOf(PropsType.any),
};

ZoneUnitModify.defaultProps = { match: {}, location: {}, permissionsPricing: {} };

const mapStateToProps = createStructuredSelector({ permissionsPricing: makeGetPermissionsPricingManagement() || {} });

export default connect(mapStateToProps, { getZonesByZoneUnitId, modifyZoneUnit })(ZoneUnitModify);
