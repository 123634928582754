import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isArray, concat } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Navbar from './Navbar/Navbar';
import Loader from '../Loader';
import injectReducer from '../../utils/injectReducer';
import reducer from './reducers';
import ModalChangePassword from './ModalChangePassword';
import {
  getId,
  getDaysForPasswordChange,
  getSSOLogin,
  getLastPasswordChangeDate,
  setAvatarBase64,
} from '../../localStorages';
import {
  handleChangePassword,
  getRoleAndRoleGroupById,
  getRoleById,
  getRoleIdByRoleGroups,
  checkAuth,
  getCcpPropertiesConfig,
  readFileFromS3,
  getAwsS3Credential,
} from '../App/actions';
import * as actions from './actions';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { getBase64 } from '../../utils/utils';

class Layout extends Component {
  layoutRef = React.createRef();

  state = {
    isShowRestPassword: false,
  };

  componentDidMount() {
    const {
      getRoleAndRoleGroupById,
      getRoleById,
      doIsEndConnected,
      doIsConnecting,
      getRoleIdByRoleGroups,
      checkAuth,
      getCcpPropertiesConfig,
      s3Config,
      getAwsS3Credential,
    } = this.props;
    getCcpPropertiesConfig('', ({ success, data }) => {
      if (success) {
        const isShowRestPassword = data.ccpPropertyList.find(val => val.property === 'ssoEnabled');
        const passwordExpiryInMonths = data.ccpPropertyList.find(val => val.property === 'passwordExpiryInMonths');
        let isShowRestByDateTime = false;
        if (getLastPasswordChangeDate() && passwordExpiryInMonths?.value) {
          const dayPassword = moment(getLastPasswordChangeDate(), 'YYYY-MM-DD').add(
            passwordExpiryInMonths.value || 0,
            'months'
          );
          const dayNeedToChangePassword = moment(new Date(), 'YYYY-MM-DD');
          const monthSelect = dayPassword.diff(dayNeedToChangePassword, 'days');
          if (monthSelect <= 15) {
            isShowRestByDateTime = true;
          }
        }

        this.setState({
          isShowRestPassword: !(isShowRestPassword && isShowRestPassword.value === 'true') && isShowRestByDateTime,
        });
      }
    });

    doIsConnecting();
    checkAuth();
    if (getId() && getSSOLogin()) {
      // Handle SSO
      getRoleById(getId(), () => {
        doIsEndConnected();
      });
    } else {
      // Handle with roles id
      getRoleAndRoleGroupById(getId(), ({ success, data }) => {
        if (data?.profilePicturePath) {
          if (!s3Config || !s3Config.accessKey) {
            getAwsS3Credential('', ({ success }) => {
              if (success) {
                this.doReadFileFromConfig(data, data.profilePicturePath);
              }
            });
          } else {
            this.doReadFileFromConfig(s3Config, data.profilePicturePath);
          }
        }
        if (success && data) {
          if (data.roles && isArray(data.roles)) {
            const groupIdRoles = data.roles.map(item => item.roleId);
            let countDownTimer = groupIdRoles.length;
            groupIdRoles.map(id =>
              getRoleById(id, () => {
                countDownTimer--;
                if (countDownTimer === 0) {
                  doIsEndConnected();
                }
              })
            );
          }

          // Handle with role groups
          if (data.roleGroups && isArray(data.roleGroups) && !data.roles) {
            const roleGroupId = data.roleGroups.map(item => item.roleGroupId);
            let listRoleId = [];
            let countDownFetchRoleGroup = roleGroupId.length;
            roleGroupId.map(id =>
              getRoleIdByRoleGroups(id, ({ success, data }) => {
                countDownFetchRoleGroup--;
                if (success && data.length) {
                  listRoleId = concat(
                    listRoleId,
                    data.flatMap(item => item.roleId)
                  );
                  if (countDownFetchRoleGroup === 0) {
                    let countDownTimer = listRoleId.length;
                    listRoleId.map(id =>
                      getRoleById(id, () => {
                        countDownTimer--;
                        if (countDownTimer === 0) {
                          doIsEndConnected();
                        }
                      })
                    );
                  }
                } else {
                  doIsEndConnected();
                }
              })
            );
          }
        } else {
          doIsEndConnected();
        }
      });
    }
  }

  toggleSidebar = () => {
    const { toggleSidebar } = this.props;
    toggleSidebar();
  };

  doReadFileFromConfig = (config, pathFile) => {
    const { readFileFromS3 } = this.props;
    readFileFromS3({ ...config, url: pathFile }, ({ success, data }) => {
      if (success) {
        getBase64(data, result => {
          setAvatarBase64(result);
        });
      }
    });
  };

  render() {
    const { isExpand, handleChangePassword, permissions, children, t } = this.props;
    const { isShowRestPassword } = this.state;
    const className = isExpand ? '' : 'slide-menu';
    return (
      <div
        className={`${className} ${
          localStorage.getItem('i18nextLng') === 'es' ? localStorage.getItem('i18nextLng') : ''
        }`}
      >
        <Navbar onToggleSidebar={this.toggleSidebar} permissions={permissions} />
        {isShowRestPassword ? (
          <div className="msg-reset-pass">
            {`${getDaysForPasswordChange()} ${t('common:message.messageCountDateResetPassword')}`}
          </div>
        ) : (
          <div className="msg-reset-pass pt-3" />
        )}
        {children}
        <ModalChangePassword handleChangePassword={handleChangePassword} />
        <Loader />
      </div>
    );
  }
}

Layout.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
  getRoleAndRoleGroupById: PropTypes.func.isRequired,
  getRoleById: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func,
  handleChangePassword: PropTypes.func,
  doIsConnecting: PropTypes.func,
  doIsEndConnected: PropTypes.func,
  getRoleIdByRoleGroups: PropTypes.func.isRequired,
  checkAuth: PropTypes.func.isRequired,
};

Layout.defaultProps = {
  permissions: {},
  isExpand: false,
  toggleSidebar: () => {},
  handleChangePassword: () => {},
  doIsConnecting: () => {},
  doIsEndConnected: () => {},
};

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const { s3Config } = store.get('auth');

  return {
    isExpand: sidebarExpanded,
    permissions: store.get('auth').toJS() ? store.get('auth').toJS().permissionsApp : {},
    s3Config,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(actions.toggleSidebar()),
  handleChangePassword: (newPassword, cb) => dispatch(handleChangePassword(newPassword, cb)),
  getRoleAndRoleGroupById: (payload, cb) => dispatch(getRoleAndRoleGroupById(payload, cb)),
  getRoleById: (payload, cb) => dispatch(getRoleById(payload, cb)),
  getRoleIdByRoleGroups: (payload, cb) => dispatch(getRoleIdByRoleGroups(payload, cb)),
  checkAuth: () => dispatch(checkAuth()),
  doIsEndConnected: () => dispatch(isEndConnected()),
  doIsConnecting: () => dispatch(isConnecting()),
  dispatch,
  getCcpPropertiesConfig: (payload, cb) => dispatch(getCcpPropertiesConfig(payload, cb)),
  readFileFromS3: (payload, cb) => dispatch(readFileFromS3(payload, cb)),
  getAwsS3Credential: (payload, cb) => dispatch(getAwsS3Credential(payload, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'layout', reducer });

export default withTranslation('common')(compose(withReducer, withConnect)(Layout));
