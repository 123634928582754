import gql from 'graphql-tag';

export default () => {
  const getCostCenterMap = 'getCostCenterMap(dummy: "")';
  return gql`
    query {
      ${getCostCenterMap} {
        id
        index
        country
        state
        company
        legalEntity
        businessUnit
        marketSegment
        costCenter
      }
    }
  `;
};
