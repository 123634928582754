import gql from 'graphql-tag';

export default ({ id }) => {
  const getExchangeRate = `getExchangeRate(input: { id: "${id}" })`;
  return gql`
    query {
      ${getExchangeRate} {
        id
        name
        userId
        effectiveDate
        type
        exchangeRateList {
          index
          sourceCurrency
          targetCurrency
          type
          exchangeRate
        }
      }
    }
  `;
};
