import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class AgeingPaymentSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.paymentStatus',
      name: 'status',
      type: 'select',
      tOptions: 'selections:statusPayment',
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'label.groupByType',
      name: 'groupByType',
      tOptions: 'selections:paymentAgeingReportGroupByType',
      isClearable: true,
      type: 'select',
      defaultValue: 'PAYMENT',
      required: true,
    },
    {
      label: 'label.groupCompany',
      name: 'groupCompany',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      tOptions: 'selections:accountType',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.numberOfDaysSincePaymentDateMoreThan',
      name: 'startNumberOfDaysSincePaymentDate',
      type: 'number',
      enableFieldsOnChange: { groupByType: 'PAYMENT' },
      isInteger: true,
    },
    {
      label: 'label.numberOfDaysSincePaymentDateLessThan',
      name: 'endNumberOfDaysSincePaymentDate',
      type: 'number',
      enableFieldsOnChange: { groupByType: 'PAYMENT' },
      isInteger: true,
    },
    {
      label: 'label.functionalCurrency',
      name: 'functionalCurrency',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-12';
}

AgeingPaymentSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AgeingPaymentSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AgeingPaymentSearchForm);
