import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class MonthGroup extends React.PureComponent {
  handleChange = value => {
    const { onChange, name } = this.props;
    if (onChange) {
      if (value) {
        onChange({ name, value: moment(value).format('MM/YYYY') });
        return;
      }
      onChange({ value: null });
    }
  };

  render() {
    const { label, wrapperClass, date, ...restProps } = this.props;
    const dateSelected = date && moment(date, 'MM/YYYY').isValid() ? moment(date, 'MM/YYYY').toDate() : null;
    return (
      <div className={`form-group ${wrapperClass}`}>
        <span>
          {label}
          {label && restProps.required && <i className="fa fa-star is-required" />}
        </span>
        <DatePicker
          className="form-control"
          selected={dateSelected}
          {...restProps}
          isClearable
          onChange={this.handleChange}
          autoComplete="off"
          popperModifiers={{
            flip: {
              behavior: ['bottom'],
            },
          }}
        />
      </div>
    );
  }
}

MonthGroup.propTypes = {
  wrapperClass: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

MonthGroup.defaultProps = {
  label: '',
  name: '',
  wrapperClass: 'col-md-3',
  date: '',
  onChange: () => {},
};

export default MonthGroup;
