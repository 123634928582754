import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.organization'),
  t('label.accountId'),
  t('label.accountCurrency'),
  t('label.invoiceId'),
  t('label.invoiceDue'),
  // t('label.trialType'),
  t('label.allocationDate'),
  t('label.allocatedAmountInAccountCurrencyExport'),
  t('label.allocatedAmountInPaymentSuspenseCurrencyExport'),
  t('label.exchangeRate'),
  t('label.transactionType'),
  t('label.paymentId'),
  t('label.paymentSuspenseId'),
  t('label.status'),
  t('label.folioStatus'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ content: item.organization ? item.organization : '' });
      newItem.push({ content: item.accountId ? item.accountId : '' });
      newItem.push({ content: item.accountCurrency ? item.accountCurrency : '' });
      newItem.push({ content: item.invoiceId ? item.invoiceId : '' });
      newItem.push({ content: formatMoneyValueToExport(item.invoiceDue) });
      newItem.push({ content: item.allocationDate ? item.allocationDate : '' });
      newItem.push({ content: formatMoneyValueToExport(item.allocatedAmount) });
      newItem.push({ content: formatMoneyValueToExport(item.sourceCurrencyAmount) });
      newItem.push({ content: formatMoneyValueToExport(item.exchangeRate) });
      newItem.push({
        content: getLabelOptions({ item, t, fieldName: 'transactionType', fieldOption: 'paymentTransactionType' }),
      });
      newItem.push({ content: item.paymentId ? item.paymentId : '' });
      newItem.push({ content: item.paymentSuspenseId ? item.paymentSuspenseId : '' });
      newItem.push({
        content: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'statusPayment' }),
      });
      newItem.push({
        content: getLabelOptions({ item, t, fieldName: 'folioStatus', fieldOption: 'folioStatus' }),
      });
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 5, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
