import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { GenericInput } from '../common';
import dataField from '../../containers/TenantManagement/constant';
import {
  JsonWebTokenAttributeForm,
  OauthAttributesForm,
  HttpBasicAttributesForm,
} from '../../containers/TenantManagement/TenantDetail/SubForm';

export default class PaymentGateway extends React.PureComponent {
  state = { tenantData: [{}] };

  componentDidMount() {
    const { data } = this.props;
    if (data.length > 0) this.setState({ tenantData: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.length > 0) {
      this.setState({ tenantData: nextProps.data });
    }
  }

  onChange = ({ name, value, index, indexCreate }) => {
    const { onHandleChange, indexData } = this.props;
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    newData[index][name] = value;
    this.setState({ tenantData: newData });
    onHandleChange({ name, value, index: indexData, indexCreate });
  };

  onChangeAttributes = ({ name, value, index, indexAttr, fieldName = '', isNotIndex = false }) => {
    const { tenantData } = this.state;
    const { onChangeAttributes } = this.props;
    onChangeAttributes({ name, value, index, indexAttr, fieldName, isNotIndex });
  };

  render() {
    const { tenantData } = this.state;
    const {
      modeModifyPaymentGatewayMerchant,
      isRequireField,
      modeModifyTaxGatewayMerchant,
      modeModifyFinanceGatewayMerchant,
      dataJwtAttributes,
      indexDataJwtAttributes,
      dataHttpBasicAttributes,
      indexDataHttpBasic,
      dataOauthAttributes,
      indexDataOauthAttributes,
      indexData,
    } = this.props;

    return (
      <div className="col-md-12 p-2">
        {tenantData.map((item, index) => (
          <div className={`col-md-12 group-flex mt-2 ${index > 0 ? 'geteway-group' : ''}`} key={index}>
            {dataField.fieldGetwayForm.map(field => {
              if (field && (!isEmpty(field.options) || field.tOptions)) {
                return (
                  <GenericInput
                    key={field.name}
                    label={field.label || ''}
                    required={isRequireField}
                    type="select"
                    value={item[field.name] || null}
                    wrapperClass="col-md-4"
                    disabled={field.disabled || modeModifyPaymentGatewayMerchant === 1}
                    onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                    name={field.name}
                    {...field}
                  />
                );
              }

              if (field && field.isFieldDate) {
                return (
                  <GenericInput
                    key={field.name}
                    label={field.label || ''}
                    type="date"
                    value={item[field.name] || null}
                    required={isRequireField}
                    disabled={modeModifyPaymentGatewayMerchant === 1}
                    wrapperClass="col-md-4"
                    onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                    name={field.name}
                  />
                );
              }

              return (
                <GenericInput
                  key={field.name}
                  label={field.label || ''}
                  value={item[field.name] || null}
                  required={isRequireField}
                  type={field.isNumber ? 'number' : 'text'}
                  disabled={modeModifyPaymentGatewayMerchant === 1}
                  onChange={({ name, value }) => this.onChange({ value, name, index })}
                  name={field.name}
                  wrapperClass="col-md-4"
                />
              );
            })}
            {!isEmpty(tenantData[index]) && item.authType === 'JSON_WEB_TOKEN' && (
              <JsonWebTokenAttributeForm
                onHandleChange={this.onChangeAttributes}
                index={indexDataJwtAttributes}
                isRequireField={isRequireField}
                modeModifyPaymentGatewayMerchant={
                  modeModifyPaymentGatewayMerchant || modeModifyFinanceGatewayMerchant || modeModifyTaxGatewayMerchant
                }
                data={dataJwtAttributes || [{}]}
              />
            )}

            {!isEmpty(tenantData[index]) && item.authType === 'OAUTH2' && (
              <OauthAttributesForm
                onHandleChange={this.onChangeAttributes}
                index={indexDataOauthAttributes}
                isRequireField={isRequireField}
                modeModifyPaymentGatewayMerchant={
                  modeModifyPaymentGatewayMerchant || modeModifyFinanceGatewayMerchant || modeModifyTaxGatewayMerchant
                }
                data={dataOauthAttributes || [{}]}
              />
            )}
            {!isEmpty(tenantData[index]) && item.authType === 'HTTP_BASIC' && (
              <HttpBasicAttributesForm
                onHandleChange={this.onChangeAttributes}
                index={indexDataHttpBasic}
                isRequireField={isRequireField}
                modeModifyPaymentGatewayMerchant={
                  modeModifyPaymentGatewayMerchant || modeModifyFinanceGatewayMerchant || modeModifyTaxGatewayMerchant
                }
                data={dataHttpBasicAttributes || [{}]}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

PaymentGateway.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onHandleChange: PropTypes.func,
  onChangeAttributes: PropTypes.func,
  isRequireField: PropTypes.bool,
  modeModifyPaymentGatewayMerchant: PropTypes.number,
};

PaymentGateway.defaultProps = {
  data: [{}],
  onHandleChange: () => {},
  onChangeAttributes: () => {},
  isRequireField: false,
  modeModifyPaymentGatewayMerchant: 2,
};
