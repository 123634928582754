export default {
  sidebar: {
    paymentConfig: 'Payment Config',
    paymentAdmin: 'Payment Admin',
    configuration: 'Configuration',
    paymentTypes: 'Payment Types',
    paymentTerms: 'Payment Terms',
    method: 'Method',
    addPaymentMethod: 'add payment method',
    apply: 'apply',
    invoiceDateOffset: 'Invoice Date Offset',
    workingDay: 'Working Day',
    days: 'Days',
    addPaymentTerm: 'Add payment term',
    // paymentAgent:'Payment agent',
    paymentOperations: 'Payment operations',
    searchAccount: 'Search account',
    accounts: 'Accounts',
    oneOffPayment: 'One-off payment',
    viewPaymentActivity: 'View payment activity',
    paymentAllocation: 'Payment allocation',
    paymentSuspense: 'Payment suspense',
    customerRefunds: 'Customer Refunds',
    manualChargebacks: 'Manual Chargebacks',
    batchPayments: 'Payments',
    batchRefunds: 'Refunds',
    batchReversals: 'reversals',
    paymentHistory: 'Payment History',
    batchProcessing: 'Batch Processing',
    paymentSurcharge: 'Payment Surcharge',
    upload: 'Batch Payment File Upload',
    processPayment: 'Process Payment',
    batchPaymentFile: 'Batch File',
    itemMap: 'Item Map',
    manualPayment: 'Manual Payment',
    allocationRules: 'Allocation Rules',
  },
};
