import gql from 'graphql-tag';
import { getUserId } from '../../localStorages';

export default id => gql`
  query {
    getUserByUserId(input: { userId: "${id || getUserId()}" }) {
      id
      userId
      password
      category
      status
      type
      profilePicturePath
      address {
        street
        city
        extraLine
        landmark
        state
        country
        postalCode
      }
      contact {
        salutation
        firstName
        lastName
        middleName
        position
        organization
        email
        phones {
          number
          type
        }
      }
    }
  }
`;
