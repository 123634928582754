/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { searchCollectionProfilesConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import CollectionProfileSearchForm from './CollectionProfileSearchForm';

class ModalSearchCollectionProfile extends PureComponent {
  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
        },
        {
          name: 'collectionProfile',
          label: 'label.collectionProfile',
        },
        {
          name: 'accountType',
          label: 'label.accountType',
        },
        {
          name: 'accountSubType',
          label: 'label.accountSubType',
        },
        {
          name: 'marketSegment',
          label: 'label.marketSegment',
        },
        {
          name: 'salesChannel',
          label: 'label.salesChannel',
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      searchCollectionProfilesConfig,
      selectFieldName,
    } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseCollectionProfile')}
        isOpen={isOpen}
        SearchForm={CollectionProfileSearchForm}
        searchItems={searchCollectionProfilesConfig}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName={selectFieldName}
      />
    );
  }
}

ModalSearchCollectionProfile.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectFieldName: PropTypes.string,
  searchCollectionProfilesConfig: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSearchCollectionProfile.defaultProps = {
  isOpen: false,
  selectedParentId: '',
  selectFieldName: '',
};

export default withTranslation('common')(
  connect(null, { searchCollectionProfilesConfig })(ModalSearchCollectionProfile)
);
