import { cloneDeep } from 'lodash';
import pdfConfig from './helper';

const HeaderTable = ({ t }) => [
  t('label.accountNumber'),
  t('label.name'),
  t('label.type'),
  t('label.detailType'),
  t('label.description'),
  t('label.notesAccount'),
  t('label.notesName'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (typeof item === 'object') {
      newItem.push(item.accountNumber ? item.accountNumber : '');
      newItem.push(item.name ? item.name : '');
      newItem.push(item.type ? item.type : '');
      newItem.push(item.detailType ? item.detailType : '');
      newItem.push(item.description ? item.description : '');
      newItem.push(item.notesAccount ? item.notesAccount : '');
      newItem.push(item.notesName ? item.notesName : '');
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, ...rest, cellPadding: 2, fontSize: 7, ...rest });
};

export default convertJson2Pdf;
