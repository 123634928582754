import gql from 'graphql-tag';

export default () => gql`
  query {
    get3rdPartyTaxConfig(providerName:"AVALARA") {
      id
      configCodes {
        code
        jurisdiction
        description
        taxPercent
        category
        transactionName
        transactionId
        serviceName
        serviceId
      }
    }
  }
`;
