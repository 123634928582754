import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default payload => {
  const type  = parseToMutationRequest(payload, ['type']);
  return gql`
  query {
    getPieChartDashBoardByType(input: ${type}) {
      dashboards {
        name
        data {
          name
          value
        }
      }
    }
  }
`;
};
