import React, { PureComponent } from 'react';
import PropsType from 'prop-types';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { ModalQuestion } from '../../../../components/BaseConfigurationsHub';
import ListFileMapping from './ListFileMapping';

class FileMapping extends PureComponent {
  state = {
    isOpenModalQuestion: true,
    isCreate: false,
  };

  onChangeSwitch = () => {
    const { isCreate } = this.state;
    this.setState({ isCreate: !isCreate });
  };

  onSubmit = () => {
    const { isOpenModalQuestion, isCreate } = this.state;
    const { history } = this.props;
    this.setState({ isOpenModalQuestion: !isOpenModalQuestion });
    if (isCreate) history.push(RouteNames.customRecords.path);
  };

  render() {
    const { isCreate, isOpenModalQuestion } = this.state;
    const { t } = this.props;
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.invoiceUsage.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('usagePage:sidebar.usageMapping') },
            { name: t('baseConfigurationsPage:sidebar.fileMapping') },
          ]}
          isNoDefaultButton
        />
        <ListFileMapping />
        <ModalQuestion
          isOpen={isOpenModalQuestion}
          onSubmit={this.onSubmit}
          isActiveSwitch={isCreate}
          onChangeSwitch={this.onChangeSwitch}
        />
      </div>
    );
  }
}

FileMapping.propTypes = {
  history: PropsType.objectOf(PropsType.any),
};

FileMapping.defaultProps = {
  history: {},
};

export default withTranslation('common')(FileMapping);
