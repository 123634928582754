import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { FormWithTableItem, GenericInput } from '../../../components/common';
import selectConstant from '../../../utils/selectConstant';
import { getAddressesForm, validate } from '../../../utils/utils';
import { countryList } from '../../../constantsApp/countryList';

const dataFieldsContact = [
  {
    name: 'email',
    label: 'common:label.email',
    isNoRequired: true,
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
    isNoRequired: true,
  },
];

const dataField = {
  withAddressesUSForm: [
    {
      name: 'street',
      label: 'common:label.street',
      type: 'textarea',
    },
    {
      label: 'common:label.country',
      name: 'country',
      options: countryList,
      type: 'select',
    },
    {
      label: 'common:label.state',
      name: 'state',
      options: selectConstant.stateUSData,
      isCreateOption: true,
      type: 'select',
    },
    {
      label: 'common:label.city',
      name: 'city',
      options: [],
      isCreateOption: true,
      type: 'select',
      fieldSelect: 'city',
    },
    {
      label: 'common:label.postalCode',
      name: 'postalCode',
      options: [],
      isCreateOption: true,
      type: 'select',
      fieldSelect: 'postalCode',
    },
  ],

  withAddressesNormalForm: [
    {
      name: 'street',
      label: 'common:label.street',
      type: 'textarea',
    },
    {
      label: 'common:label.country',
      name: 'country',
      options: countryList,
      type: 'select',
    },
    {
      name: 'state',
      label: 'common:label.state',
    },
    {
      label: 'common:label.city',
      name: 'city',
    },
    {
      name: 'postalCode',
      label: 'common:label.postalCode',
    },
  ],
};

const QuickCreate = ({ data, createAccount, modeCreateCustomer }) => {
  const formRef = useRef();

  const { t } = useTranslation('common');

  const [dataSubmit, setDataSubmit] = useState({});
  const [wasValidated, setValidate] = useState(false);

  const onChangeData = ({ name, value, field }) => {
    if (!dataSubmit[field]) dataSubmit[field] = [{}];
    dataSubmit[field][0][name] = value;
    setDataSubmit({ ...dataSubmit });
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    const newPayload = cloneDeep(dataSubmit);
    newPayload.contacts = newPayload.contacts.map(val => {
      const { phones, ...rest } = val;
      return rest;
    });
    return createAccount(newPayload);
  };

  const onResetData = useCallback(() => {
    setDataSubmit(cloneDeep(data));
  }, [data]);

  useEffect(() => {
    onResetData();
  }, [onResetData]);

  const contactData = dataSubmit && dataSubmit.contacts ? dataSubmit.contacts[0] : {};
  const contactAddress = dataSubmit && dataSubmit.addresses ? dataSubmit.addresses[0] : {};
  let listFields = dataField.withAddressesNormalForm;

  if (contactAddress && contactAddress.country === 'USA') {
    listFields = getAddressesForm({
      city: contactAddress.city,
      state: contactAddress.state,
      country: contactAddress.country,
      addresses: dataField,
    });
  }

  if (modeCreateCustomer !== 2) return null;

  return (
    <form
      ref={formRef}
      noValidate
      className={`col-md-12 mb-30 needs-validation ${wasValidated ? 'was-validated' : ''}`}
      onSubmit={onSubmit}
    >
      <FormWithTableItem title={t('label.quickCreateAccount')}>
        <div className="mb-4 ml-3 mr-3 mt-3 col-md-12 row">
          {dataFieldsContact.map(val => (
            <GenericInput
              {...val}
              required={!val.isNoRequired}
              value={contactData[val.name] || null}
              onChange={e => onChangeData({ ...e, field: 'contacts' })}
            />
          ))}
          {listFields.map(val => {
            const { optionSelect, options, ...rest } = val;
            return (
              <GenericInput
                options={val.optionSelect || val.options || []}
                required
                value={contactAddress[val.name] || null}
                onChange={e => onChangeData({ ...e, field: 'addresses' })}
                {...rest}
              />
            );
          })}
        </div>
        <div className="col-sm-12 mb-0 btn-account pb-3">
          {modeCreateCustomer === 2 && (
            <button
              type="submit"
              className="button x-small float-right"
              // onClick={onHandleSubmit}
            >
              {t('common:label.createAccount')}
            </button>
          )}
          <button type="button" className="button mr-2 button-border black x-small float-right" onClick={onResetData}>
            {t('common:label.cancel')}
          </button>
        </div>
      </FormWithTableItem>
    </form>
  );
};

QuickCreate.propTypes = {
  modeCreateCustomer: PropTypes.number,
  createAccount: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

QuickCreate.defaultProps = {
  data: {},
  modeCreateCustomer: 0,
};

export default QuickCreate;
