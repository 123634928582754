import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchRegulatoryProductCodes = `searchRegulatoryProductCodes(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchRegulatoryProductCodes} {
        id
        description
        startDate
        endDate
        includeIva
        includeIeps
        complement
      }
    }
  `;
};
