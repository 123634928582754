export default {
  sidebar: {
    productFamily: 'Product Family',
    resources: 'Resources',
    usageRating: 'Usage Rating',
    usageMapping: 'Usage Mapping',
    currency: 'Currency',
    grants: 'Grants',
    accumulators: 'Accumulators',
    rateUnit: 'Rate Unit',
    rateUnitMap: 'Rate Unit Map',
    timeUnit: 'Time Unit',
    zoneUnit: 'Zone Unit',
    customRecords: 'Custom Records',
    fileMapping: 'File Mapping',
    processingStreams: 'Processing Streams',
    taxation: 'Taxation',
    taxCodeConfig: 'Tax Code Config',
    thirdPartyTaxConfig: 'Third Party Tax Config',
    regionMapping: 'Region Mapping',
    zeroPriceUsage: 'Zero Price Usage',
    operatorMap: 'Operator Map',
    trunkRule: 'Trunk Rule',
    unitOfMeasure: 'Unit Of Measure',
    usageType: 'Usage Type',
    voicePlans: 'Voice Plans',
    countryCodes: 'Country Codes',
    PNNList: 'PNN List',
    ratingConfig: 'Rating Config',
    pacProductCodes: 'PAC Product Codes',
    prepaidConfig: 'Prepaid Config',
    topupOffers: 'Topup Offers',
    taxTypeConfig: 'Tax Type Config',
    pricingConfig: 'Pricing Config',
    regulatoryProductCodes: 'Regulatory Product Codes',
  },
};
