import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataCreate = parseOrderMutation(data, [
    'orderType',
    'index',
    'apiName',
    'apiCategory',
    'status',
    'onErrorResubmit',
    'validateOnly',
    'level',
    'action',
    'provisioningSequence',
    'merchantName',
    'synchronousResponse',
    'merchantName',
    'executeOnError',
    'errorApiName',
  ]);
  const createProvSequenceConfig = `createProvSequenceConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createProvSequenceConfig} {
      id
    }
  }
`;
};
