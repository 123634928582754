/**
 * Dispute Value Not String
 */

export default [
  'type',
  'reason',
  'source',
  'taxRule',
  'amount',
  'percent',
  'settledAmount',
  'status',
];
