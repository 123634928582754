import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'serviceType', 'transactionType']);
  const createRateUnitSvcTxnMapCfg = `createRateUnitSvcTxnMapCfg(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createRateUnitSvcTxnMapCfg} {
      id
    }
  }
`;
};
