import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'taxType']);
  const createTaxTypeConfig = `createTaxTypeConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createTaxTypeConfig} {
      id
    }
  }
`;
};
