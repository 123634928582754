import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import {
  uploadMultiPartFiles,
  getBatchPaymentFileConfigSelect,
  getCurrencyConfigApp,
  getObjectFileById,
  saveOutputTemplateByType,
  getOutputTemplateByType,
} from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { PaymentSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  ModalWithItem,
  DragDropConfigExportTemplate,
} from '../../../../components/common';
import { sortPayment } from '../../constants';
import { getListPayment, getListAllPayment } from '../../actions';
import { makeGetListPayments, makeGetListAllPayments } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  formatMoneyValue,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import ModalViewPaymentFile from '../../../../components/common/ModalViewPaymentFile';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelPayments';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfPayments';
import { PAGE_SIZE_DOWNLOAD, statusDisableFielsdViewFolio } from '../../../../utils/constants';
import ModalViewPaymentActivity from '../../../../components/common/ModalViewPaymentActivity';

let tableColumnsOutput = null;
let tableColumnsDefault = null;
class Payment extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listPayments: [],
    listPaymentOptions: [],
    currencyOptions: [],
    isOpenModalViewPaymentActivity: false,
    isActivityViewer: false,
    isOpenFile: false,
    pathFile: null,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listPayments: props.listPayments,
      totalCount: getPageTotalCount({ ...state, items: props.listPayments }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetPaymentReport },
      getBatchPaymentFileConfigSelect,
      getCurrencyConfigApp,
    } = this.props;
    if (modeGetPaymentReport) {
      this.doGetReportPayments();
      this.doGetOutputTemplateByType();
      // getBatchPaymentFileConfigSelect('', ({ success, data }) => {
      //   if (success && data && data.files) {
      //     const listPaymentOptions = data.files.map(val => {
      //       return { label: val.name, value: val.name };
      //     });
      //     this.setState({
      //       listPaymentOptions: [
      //         { label: 'Automated', value: 'Automated' },
      //         { label: 'Manual', value: 'Manual' },
      //         ...listPaymentOptions,
      //       ],
      //     });
      //   }
      // });

      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetReportPayments();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetReportPayments());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportPayments());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetReportPayments();
    });
  };

  doGetReportPayments = () => {
    const { filter, page, size, sorted } = this.state;
    const { getListPayment } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) && sortPayment[sorted.sortCol] ? sortPayment[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getListPayment(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAllListPayments = cb => {
    const { filter, sorted } = this.state;
    const { getListAllPayment } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter,
      sort: !isEmpty(sorted) ? sortPayment[sorted.sortCol][sorted.sortDir] : null,
    };
    getListAllPayment(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAllListPayments(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllPayments, t } = this.props;
        convertJson2Pdf({
          data: listAllPayments,
          t,
          title: t('reportPage:sidebar.payment').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.payment')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAllListPayments(() => {
      const { listAllPayments, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_PAYMENT_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.payment')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.payment')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllPayments,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              accountType: 'accountType',
              paymentMethod: 'paymentMethod',
              status: 'statusPayment',
              paymentTerm: 'paymentTerm',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllPayments,
          t,
          title: t('reportPage:sidebar.payment').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.payment')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.payment')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  onToggleModalViewPaymentActivity = (item, isActivityViewer) => {
    const { isOpenModalViewPaymentActivity } = this.state;
    this.setState({
      isOpenModalViewPaymentActivity: !isOpenModalViewPaymentActivity,
      itemSelected: item,
      isActivityViewer,
    });
  };

  onToggleViewPaymentFile = () => {
    this.setState({ isOpenFile: false });
  };

  onHanldeViewPaymentFile = ({ item }) => {
    const { getObjectFileById } = this.props;
    getObjectFileById(item.paymentId, data => {
      let pdfPath = '';
      let htmlPath = '';
      if (data && data.length) {
        const pdfData = data.find(val => val.fileType === 'PDF');
        const htmlData = data.find(val => val.fileType === 'HTML');
        if (pdfData) {
          pdfPath = pdfData.path;
        }
        if (htmlData) {
          htmlPath = htmlData.path;
        }
        this.setState({ pathFile: pdfPath, isOpenFile: true, htmlFile: htmlPath, paymentFileData: data });
      }
    });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('PAYMENT_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listPayments,
      listPaymentOptions,
      currencyOptions,
      emails,
      itemSelected,
      isActivityViewer,
      isOpenModalViewPaymentActivity,
      isOpenFile,
      pathFile,
      htmlFile,
      paymentFileData,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const {
      listAllPayments,
      t,
      permissions: { modeGetPaymentReport, modeDownloadReport, modeGetMailReport },
    } = this.props;
    if (!modeGetPaymentReport) return '';
    const tabelColumnPayment = [
      {
        name: 'paymentId',
        label: 'label.paymentId',
        sortable: true,
        render: (colName, item) =>
          item.paymentId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
            >
              {item.paymentId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'referenceId',
        label: 'common:label.paymentReferenceId',
        render: (colName, item) =>
          item.referenceId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, false)}
            >
              {item.referenceId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'paymentSuspenseId',
        label: 'common:label.paymentSuspenseId',
      },
      {
        name: 'paymentDate',
        label: 'label.paymentDate',
        sortable: true,
      },
      {
        name: 'createdDate',
        label: 'label.createdDate',
        sortable: true,
      },
      {
        name: 'status',
        label: 'label.status',
        fieldOption: 'statusPayment',
        isRenderT: true,
        sortable: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'folioStatus',
        label: 'label.folioStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'sourceCurrency',
        label: 'label.paymentCurrency',
      },
      {
        name: 'amount',
        label: 'label.paymentAmount',
      },
      {
        name: 'remainingAmount',
        label: 'common:label.remainingAmount',
      },
      {
        name: 'fileName',
        label: 'common:label.paymentFile',
      },
      {
        name: 'fileDate',
        label: 'common:label.paymentFileDate',
        sortable: true,
      },
      {
        name: 'paymentTransactionType',
        label: 'label.paymentTxtType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t
            ? t('selections:paymentTransactionType')().find(val => val.value === item.paymentTransactionType)
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'organization',
        label: 'label.organization',
      },
      {
        name: 'accountId',
        label: 'label.accountId',
        sortable: true,
      },
      {
        name: 'firstName',
        label: 'label.firstName',
      },
      {
        name: 'lastName',
        label: 'label.lastName',
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'paymentMethod',
        label: 'label.paymentMethod',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'paymentSource',
        label: 'label.paymentSource',
      },
      {
        name: 'paymentSourceId',
        label: 'label.paymentSourceId',
      },
      // {
      //   name: 'paymentTerm',
      //   label: 'label.paymentTerm',
      //   isRenderT: true,
      //   render: (colName, item, t) => {
      //     const slt = t ? t('selections:paymentTerm')().find(val => val.value === item.paymentTerm) : '';
      //     return <span>{slt ? slt.label : ''}</span>;
      //   },
      // },
      {
        name: 'currency',
        label: 'label.accountCurrency',
      },
      {
        name: 'invoiceUnitId',
        label: 'label.invoiceUnitId',
      },
      // {
      //   name: 'suspenseFlag',
      //   label: 'label.suspenseFlag',
      //   render: (colName, item) => {
      //     return <span>{item.suspenseFlag ? 'TRUE' : 'FALSE'}</span>;
      //   },
      // },
      {
        name: 'userId',
        label: 'label.userId',
      },
      {
        name: 'paymentDescription',
        label: 'label.description',
      },
      {
        name: 'exchangeRate',
        label: 'label.appliedExchangeRate',
        render: (colName, item) => {
          return <span>{item.exchangeRate}</span>;
        },
      },
      {
        name: 'currentDateExchangeRate',
        label: 'label.currentExchangeRate',
        render: (colName, item) => {
          return <span>{item.currentDateExchangeRate}</span>;
        },
      },
      {
        name: 'unRealizedGain',
        label: 'label.unrealizedLossGainInSystemCurrency',
        exportLabel: 'label.unrealizedLossGainInSystemCurrencyExport',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.unRealizedGain)}</span>;
        },
      },
      {
        name: 'paymentDateExchangeRate',
        label: 'label.paymentDateExchangeRate',
      },
      // {
      //   name: 'transactionId',
      //   label: 'label.transactionId',
      // },
    ];

    tableColumnsDefault = tabelColumnPayment;
    const exportColumns = exportColumnsSave || {};

    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    const newTableView = [
      {
        name: 'viewFolio',
        label: 'common:label.viewFolio',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            // onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
            onClick={() => this.onHanldeViewPaymentFile({ item })}
            disabled={!item?.paymentId || statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
          >
            {t('label.view')}
          </button>
        ),
      },
      ...tableColumnsOutput,
    ];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={[{ name: t('label.report'), url: RouteNames.report.path }, { name: t('reportPage:sidebar.payment') }]}
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.payment')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <PaymentSearchForm
                  onSubmit={this.onHandleSubmitSearch}
                  isMultipleOptionInject
                  optionsInject={{
                    // paymentSource: listPaymentOptions,
                    sourceCurrency: currencyOptions,
                  }}
                />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.payment').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllPayments,
                          t,
                          title: t('reportPage:sidebar.payment').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.payment')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listPayments}
                    />
                  )}
                </div>
                <DataTable
                  columns={newTableView}
                  data={listPayments && listPayments.length ? listPayments : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalViewPaymentActivity
          id={itemSelected?.paymentId || null}
          isOpen={isOpenModalViewPaymentActivity}
          onToggle={evt => this.onToggleModalViewPaymentActivity()}
          itemSelected={{ ...itemSelected, paymentRefId: itemSelected?.referenceId || null }}
          title={isActivityViewer ? t('label.paymentActivity') : t('label.paymentReferenceActivity')}
          isSuspense={isActivityViewer}
          isRemainingAmount
          isPaymentActivity
        />

        <ModalViewPaymentFile
          title={t('label.paymentFile')}
          isOpen={isOpenFile}
          pathFile={pathFile}
          htmlFile={htmlFile}
          onToggle={this.onToggleViewPaymentFile}
          paymentFileData={paymentFileData}
        />
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

Payment.propTypes = {
  getListPayment: PropTypes.func,
  getListAllPayment: PropTypes.func,
  listAllPayments: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

Payment.defaultProps = {
  getListPayment: () => {},
  getListAllPayment: () => {},
  listAllPayments: [],
};

const mapStateToProps = createStructuredSelector({
  listPayments: makeGetListPayments() || [],
  listAllPayments: makeGetListAllPayments() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getListPayment,
    getListAllPayment,
    getBatchPaymentFileConfigSelect,
    getCurrencyConfigApp,
    uploadMultiPartFiles,
    getObjectFileById,
    saveOutputTemplateByType,
    getOutputTemplateByType,
  })(Payment)
);
