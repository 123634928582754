import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['folioType', 'fileType', 'mandatory']);
  const createFolioResponse = `createFolioResponse(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createFolioResponse} {
        id
        folioType
        folioResponseFiles {
          index
          name
          fileType
          mandatory
        }
      }
    }
  `;
};
