import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataGet = parseToMutationRequest(data, ['type']);

  const getGatewayCanonicalByObjectType = `getGatewayCanonicalByObjectType(input: ${dataGet})`;
  return gql`
    query {
      ${getGatewayCanonicalByObjectType} {
        taxCanonical{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        paymentCanonical{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        billingCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        financeCanonical{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        customRecord{
            attributeName
        }
        projectCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        customerSupportCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        documentCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        operationCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        orderCanonicalSpec {
          attributes {
            attributeName
          }
        }
        accountCanonicalSpec {
          attributes {
            attributeName
          }
        }
        itemCanonicalSpec {
          attributes {
            attributeName
          }
        }
        bundleCanonicalSpec {
          attributes {
            attributeName
          }
        }
        packageCanonicalSpec {
          attributes {
            attributeName
          }
        }
        priceOfferCanonicalSpec {
          attributes {
            attributeName
          }
        }
        provisioningResponseCanonicalSpec {
          attributes {
            attributeName
          }
        }
        invoiceCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
        accountAndOrderCanonicalSpec{
          request{
            attributes{
              attributeName
            }
          }
          response{
            attributes{
              attributeName
            }
          }
        }
      }
    }
  `;
};
