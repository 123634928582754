import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import { roundFloat } from '../../../utils/utils';
import { disputeFieldApply } from '../constant';
import { ArOperationsForm } from '../../../components/ArOperationsHub';
import PageTitle from '../../../components/PageTitle';
import ModalAccountItems from '../../../components/common/ModalAccountItems';
import ModalInvoice from '../../../components/common/ModalInvoice';
import { processDispute, getARReasonCodeConfig } from '../actions';
import { makeGetPermissionsArOpsManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import { getCcpPropertiesConfig } from '../../App/actions';

import {
  checkPermissionProcessTaxDispute,
  checkPermissionProcessDispute,
  checkPermissionGetArOpsTaxOptions,
} from '../CheckPermission';
import ModalAccountId from '../../../components/common/ModalAccountId';

let isDisablePercent = false;

class DisputesApply extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dataCreate: { userId: 'USER-002', source: 'AGENT_CARE' },
      isOpenModalItem: false,
      isOpenModalInvoice: false,
      isOpenModalAccountId: false,
      optionResonCodes: [],
      fixedTaxRuleForAROps: null,
      dataCancel: {},
      isFilterInstallmentInvoicesForAR: null,
    };
  }

  componentDidMount() {
    const { getARReasonCodeConfig, getCcpPropertiesConfig, ccpPropertyList } = this.props;

    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitDefaultValue(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitDefaultValue(ccpPropertyList);
    }
    getARReasonCodeConfig('', ({ success, data }) => {
      if (success && data && data.arReasonCodes) {
        const optionResonCodes = data.arReasonCodes
          .filter(val => !!val.customReasonCode)
          .map(val => ({
            label: val.customReasonCode,
            value: val.customReasonCode,
            ...val,
          }));
        this.setState({
          optionResonCodes,
        });
      }
    });
  }

  doInitDefaultValue = ccpPropertyList => {
    const { dataCreate } = this.state;
    const fixedTaxRuleForAROps = ccpPropertyList.find(val => val.property === 'fixedTaxRuleForAROps');
    const defaultTaxRuleForAROps = ccpPropertyList.find(val => val.property === 'defaultTaxRuleForAROps');
    const filterInstallmentInvoicesForAR = ccpPropertyList.find(
      val => val.property === 'filterInstallmentInvoicesForAR'
    );
    dataCreate.taxRule = 'WITHOUT_TAX';
    if (defaultTaxRuleForAROps && defaultTaxRuleForAROps.value) {
      dataCreate.taxRule = defaultTaxRuleForAROps.value;
    }
    if (fixedTaxRuleForAROps && fixedTaxRuleForAROps.value) {
      dataCreate.taxRule = fixedTaxRuleForAROps.value;
    }
    this.setState({
      fixedTaxRuleForAROps,
      dataCreate: { ...dataCreate },
      dataCancel: { ...dataCreate },
      isFilterInstallmentInvoicesForAR:
        filterInstallmentInvoicesForAR && filterInstallmentInvoicesForAR.value === 'true',
    });
  };

  onHandleSubmit = evt => {
    const { processDispute, t } = this.props;
    evt.preventDefault();
    const {
      dataCreate: { invoiceCancellation, ...rest },
    } = this.state;
    if (!rest.customReason && !rest.reason) {
      return toast.error(t('message.messgaeErrorReasonCode'));
    }
    if (!rest.type || !rest.accountId || (!rest.reason && !rest.customReason)) {
      return toast('Filed AR Type, Account Id, Reason is require', { type: toast.TYPE.WARNING });
    }

    return processDispute(rest);
  };

  onSelectItem = id => {
    const { dataCreate } = this.state;
    dataCreate.itemId = id;
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalItem: false });
  };

  onSelectInvoice = id => {
    const { dataCreate } = this.state;
    dataCreate.invoiceId = id;
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalInvoice: false });
  };

  onSelectAccountId = id => {
    const { dataCreate } = this.state;
    dataCreate.accountId = id;
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalAccountId: false });
  };

  onOpenModal = name => {
    let { isOpenModalItem, isOpenModalInvoice, isOpenModalAccountId } = this.state;
    const { dataCreate } = this.state;
    if (name === 'itemId') {
      isOpenModalItem = true;
    }
    if (name === 'invoiceId') {
      if (!dataCreate.accountId) toast('Please input account Id first!', { type: toast.TYPE.WARNING });
      isOpenModalInvoice = true;
    }
    if (name === 'accountId') {
      isOpenModalAccountId = true;
    }
    return this.setState({ isOpenModalItem, isOpenModalInvoice, isOpenModalAccountId });
  };

  onHandleChange = ({ name, value }) => {
    const { dataCreate, optionResonCodes } = this.state;
    if (name === 'percent' && (Number.parseFloat(value) > 100 || Number.parseFloat(value) < 0)) {
      return '';
    }
    dataCreate[name] = value;
    if (name === 'customReason') {
      const optionSelect = optionResonCodes.find(val => val.value === value);
      if (optionSelect) {
        dataCreate.invoiceCancellation = optionSelect.invoiceCancellation ? 'TRUE' : 'FALSE';
        dataCreate.percent = optionSelect.fullCredit ? '100' : null;
        isDisablePercent = !!optionSelect.fullCredit;
      }
    }
    if (name === 'amount' && dataCreate.percent) dataCreate.percent = 0;
    if (name === 'percent' && dataCreate.amount) delete dataCreate.amount;
    return this.setState({ dataCreate: { ...dataCreate } });
  };

  onBlurAmount = () => {
    const { dataCreate } = this.state;
    if (!dataCreate.amount) return;
    dataCreate.amount = roundFloat(dataCreate.amount, 2);
    this.setState({ dataCreate: { ...dataCreate } });
  };

  onCancel = () => {
    const { dataCancel } = this.state;
    this.setState({ dataCreate: { ...dataCancel } });
  };

  render() {
    const {
      dataCreate,
      isOpenModalItem,
      isOpenModalInvoice,
      isOpenModalAccountId,
      optionResonCodes,
      fixedTaxRuleForAROps,
      isFilterInstallmentInvoicesForAR,
    } = this.state;
    const { permissionsArOps, t, location } = this.props;
    let modeProcessTaxDispute = 0;
    let modeProcessDispute = 0;
    let modeGetArOpsTaxOptions = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeProcessDispute = checkPermissionProcessDispute({ listPermission });
      modeProcessTaxDispute = checkPermissionProcessTaxDispute({ listPermission });
      modeGetArOpsTaxOptions = checkPermissionGetArOpsTaxOptions({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          {modeProcessDispute !== 0 && (
            <div className="content-wrapper mr-3">
              <PageTitle
                linkTo={{
                  pathname: RouteNames.arOperationsDisputes.path,
                  state: location?.state ? location.state : null,
                }}
                titleBtn={t('label.back')}
                titleRight={t('arOperationPage:sidebar.disputes')}
              />
              <div className="col-md-12 mb-30">
                <ArOperationsForm
                  title={t('common:label.applyDispute')}
                  listFields={
                    dataCreate.customReason
                      ? disputeFieldApply(t, dataCreate, optionResonCodes, fixedTaxRuleForAROps, isDisablePercent)
                      : disputeFieldApply(
                          t,
                          dataCreate,
                          optionResonCodes,
                          fixedTaxRuleForAROps,
                          isDisablePercent
                        ).filter(val => val.name !== 'invoiceCancellation')
                  }
                  data={dataCreate}
                  isApply
                  onChange={this.onHandleChange}
                  onClick={this.onOpenModal}
                  onBlurAmount={this.onBlurAmount}
                  onHandleSubmit={this.onHandleSubmit}
                  modeProcessDispute={modeProcessDispute}
                  modeProcessTaxDispute={modeProcessTaxDispute}
                  modeGetArOpsTaxOptions={modeGetArOpsTaxOptions}
                  onCancel={this.onCancel}
                />
              </div>
            </div>
          )}
          {/* <ModalItem
            isOpen={isOpenModalItem}
            onSelect={this.onSelectItem}
            selectedParentId={dataCreate.itemId}
            onCancel={() => {
              this.setState({ isOpenModalItem: false });
            }}
          /> */}
          <ModalAccountItems
            isOpen={isOpenModalItem}
            onSelect={this.onSelectItem}
            selectedParentId={dataCreate.itemId}
            accountId={dataCreate.accountId}
            invoiceUnitId={dataCreate.invoiceId}
            onCancel={() => {
              this.setState({ isOpenModalItem: false });
            }}
          />
          <ModalInvoice
            isOpen={isOpenModalInvoice}
            onSelect={this.onSelectInvoice}
            accountId={dataCreate.accountId}
            selectedParentId={dataCreate.invoiceId}
            onCancel={() => {
              this.setState({ isOpenModalInvoice: false });
            }}
            filterInstallmentInvoices={isFilterInstallmentInvoicesForAR}
          />
          <ModalAccountId
            isOpen={isOpenModalAccountId}
            onSelect={this.onSelectAccountId}
            accountId={dataCreate.accountId}
            selectedParentId={dataCreate.accountId}
            onCancel={() => {
              this.setState({ isOpenModalAccountId: false });
            }}
          />
        </div>
      </div>
    );
  }
}

DisputesApply.propTypes = {
  processDispute: PropTypes.func.isRequired,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

DisputesApply.defaultProps = {
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    processDispute,
    getARReasonCodeConfig,
    getCcpPropertiesConfig,
  })(DisputesApply)
);
