export const searchAccountInputs = [
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.firstName',
    name: 'firstName',
  },
  {
    label: 'common:label.lastName',
    name: 'lastName',
  },
  {
    label: 'common:label.city',
    name: 'city',
  },
  {
    label: 'common:label.state',
    name: 'state',
  },
  {
    label: 'common:label.organization',
    name: 'organization',
  },
];

export const searchAccountSelectors = [
  {
    name: 'status',
    label: 'common:label.status',
    tOptions: 'selections:status',
    type: 'select',
  },
];
export default { searchAccountSelectors, searchAccountInputs };
