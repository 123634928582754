import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FocusSelectTable, FocusInputTable } from '../../common';

const headers = ['label.action', 'label.description', 'label.remove'];

export default function TableCollectionConfig({
  data,
  onHandleChangeTable,
  handleClickRemoveAction,
}) {
  const { t } = useTranslation(['common', 'selections']);
  return (
    <div className="table-responsive mt-15 sticky-header form-focus">
      <table className="table center-aligned-table mb-0">
        <thead>
          <tr>
            {headers.map(item => (
              <th key={item}>{t(item)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <FocusSelectTable
                value={t('selections:action')().find(val => val.value === item.action)}
                options={t('selections:action')()}
                name="action"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              />
              <FocusInputTable
                value={item.description}
                name="description"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              />
              <td>
                <button type="button" className="btn-trash" onClick={() => handleClickRemoveAction(item)}>
                  <i className="fa fa-trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

TableCollectionConfig.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChangeTable: PropTypes.func,
  handleClickRemoveAction: PropTypes.func.isRequired,
};

TableCollectionConfig.defaultProps = {
  data: [],
  onHandleChangeTable: () => {},
};
