import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, ['index']);
  return gql`
  query {
    getOrderBillableByIdAndIndex(input: ${dataSearch}) {
      id
      index
      currency
      advanceFlag
      recurringFrequency
      partMonthWaiveOffFlag
      calendarMonthFlag
      alignedToCycle
      alternateDescription
      recurringType
      recurringUnit
      isGrant
      invoiceExhibits
      cutOffDate
      percentile
      locations {
        index
        percent
      }
    }
  }
`;
};
