export default {
  sidebar: {
    items: 'Artículo',
    priceOffer: 'Oferta de Precio',
    discountOffer: 'Oferta de Descuento',
    // itemServices: 'Servicios de Artículos',
    itemServices: 'Servicios Facturables',
    discountTrigger: 'Disparador de Descuento',
  },
};
