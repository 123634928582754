import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';

class FocusSelectTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modify: false,
      value: props.value,
    };

    this.selectRef = React.createRef();
  }

  componentWillReceiveProps(props) {
    if (props.value !== this.state.value) {
      this.setState({
        value: props.value,
      });
    }
  }

  onChange = val => {
    this.setState({ value: val });

    const { onUpdateValue, name, index } = this.props;
    onUpdateValue(index, name, val.value);
  };

  onBlur = () => {
    this.setState({ modify: false });
  };

  onDoubleClickTd = () => {
    this.setState({ modify: true });
    setTimeout(() => {
      this.selectRef.current.focus();
    }, 250);
  };

  renderContent() {
    const { modify, value } = this.state;
    const { ...props } = this.props;

    return (
      <div onDoubleClick={this.onDoubleClickTd}>
        <div
          className={classnames('', {
            'field-none': modify,
          })}
        >
          {value && value.label}
        </div>
        <Select
          value={value}
          onChange={this.onChange}
          {...props}
          isClearable
          onBlur={this.onBlur}
          className={classnames('react-select', {
            'field-none': !modify,
          })}
          ref={this.selectRef}
          classNamePrefix="custom-react-select"
          styles={{
            control: props => ({
              ...props,
              backgroundColor: '#f6f7f8',
              border: '0px solid transparent',
              borderRadius: 0,
              minHeight: '27px',
            }),
            indicatorContainer: props => ({
              ...props,
              padding: '4px 8px',
            }),
            indicatorSeparator: props => ({
              ...props,
              marginTop: '7px',
              marginBottom: '7px',
            }),
          }}
          menuPortalTarget={document.querySelector('body')}
        />
      </div>
    );
  }

  render() {
    const { isOnTable } = this.props;
    if (isOnTable) return this.renderContent();
    return <td onDoubleClick={this.onDoubleClickTd}>{this.renderContent()}</td>;
  }
}

FocusSelectTable.propTypes = {
  value: PropTypes.object,
  onUpdateValue: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.number,
  options: PropTypes.array,
};

export default FocusSelectTable;
