import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataModify = parseOrderMutation(data, ['orderType', 'index', 'omsTasks']);
  const modifyOmsConfig = `modifyOmsConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyOmsConfig} {
      id
    }
  }
`;
};
