import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getServiceReconReport = `getServiceReconReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getServiceReconReport} {
        accountId
        priceOfferName
        priceOfferId
        bundleId
        packageId
        bundleName
        packageName
        provisioningApprovedDate
        contractApprovedDate
        contractDate
        serviceType
      }
    }
  `;
};
