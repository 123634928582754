import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType', 'type', 'usageDirection', 'fileId'])}`
    : '';
  const searchUsageTransaction = `searchUsageTransaction(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchUsageTransaction} {
        id
        type
        accountId
        description
        billUnitId
        serviceUnitId
        serviceType
        itemId
        sharedTransactionId
        originalTransactionId
        source
        startDate
        endDate
        revenueRecognitionType
        netAmount
        grossAmount
        taxCode
        omsInternalId
        billableServiceId
      }
    }
  `;
};
