import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['accountType', 'jobType'])}` : '';
  const getFailedJobsReport = `getFailedJobsReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getFailedJobsReport} {
        jobId
        index
        accountId
        firstName
        lastName
        company
        referenceId
        referenceType
        accountType
        jobType
        jobDate
        failureReason
      }
    }
  `;
};
