import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, FormCollapse, TitleFrom, DataTable } from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';
import { getConfigUsageType, modifyConfigUsageType, createConfigUsageType } from '../../actions';
import {
  checkPermissionGetConfigUsageType,
  checkPermissionCreateConfigUsageType,
  checkPermissionModifyConfigUsageType,
} from '../../CheckPermission';

class UsageType extends React.PureComponent {
  state = {
    data: {},
    wasValidated: false,
    isModify: false,
    formValid: true,
    activeTab: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetConfigUsageType();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetConfigUsageType = () => {
    const { getConfigUsageType } = this.props;
    getConfigUsageType('', ({ data }) => {
      this.setState({
        data: data || {},
        isModify: !!data,
        activeTab: {},
      });
    });
  };

  addNewItem = ({ index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.configUsageTypeService[index].configUsageTypeList)
        newData.configUsageTypeService[index].configUsageTypeList = [];
      newData.configUsageTypeService[index].configUsageTypeList.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.configUsageTypeService[index].configUsageTypeList.push({
        index: lastIndex + 1,
        usagetype: '',
        description: '',
        status: 'ACTIVE',
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewOderType = () => {
    try {
      const { data } = this.state;
      const { t } = this.props;
      let lastIndex = 0;
      const listOptions = t('selections:serviceType')();
      const newData = cloneDeep(data);
      if (!newData.configUsageTypeService) newData.configUsageTypeService = [];
      newData.configUsageTypeService.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });

      newData.configUsageTypeService = [
        {
          index: lastIndex + 1,
          serviceType: listOptions
            ? listOptions.filter(
                val => newData.configUsageTypeService.map(i => i.serviceType).indexOf(val.value) === -1
              )[0].value
            : null,
          status: 'ACTIVE',
          configUsageTypeList: [],
        },
        ...newData.configUsageTypeService,
      ];
      this.setState({ data: newData });
      this.onToggleTab(`state-${0}`, true);
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = ({ evt }) => {
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data } = this.state;
    const { modifyConfigUsageType, createConfigUsageType } = this.props;
    if (data.id) {
      const { ...payloadModify } = cloneDeep(data);
      return modifyConfigUsageType({ ...payloadModify }, ({ success }) => {
        if (success) this.doGetConfigUsageType();
      });
    }
    const { type, ...payload } = cloneDeep(data);
    return createConfigUsageType({ ...payload, type: 'Service Usage Types' }, ({ success }) => {
      if (success) this.doGetConfigUsageType();
    });
  };

  onToggleTab = (name, isAddNew) => {
    const { activeTab } = this.state;
    if (activeTab.name === name && !isAddNew) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.configUsageTypeService[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTable = ({ name, value, index, indexParent }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.configUsageTypeService[indexParent].configUsageTypeList[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t, permissionPricing } = this.props;
    let modeGetConfigUsageType = 0;
    let modeCreateConfigUsageType = 0;
    let modeModifyConfigUsageType = 0;
    if (permissionPricing && permissionPricing.pricingModulePermissions) {
      const listPermission = permissionPricing.pricingModulePermissions;
      modeCreateConfigUsageType = checkPermissionCreateConfigUsageType({ listPermission });
      modeGetConfigUsageType = checkPermissionGetConfigUsageType({ listPermission });
      modeModifyConfigUsageType = checkPermissionModifyConfigUsageType({ listPermission });
    }
    if (!modeGetConfigUsageType) return '';
    const { data, activeTab, wasValidated, isModify } = this.state;

    const usageTypeColumns = [
      {
        name: 'usagetype',
        label: 'label.name',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.usagetype}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="usagetype"
            required
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="description"
            required
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="status"
            type="select"
            tOptions="selections:status"
            menuPortalTarget
            required
          />
        ),
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.usageType') },
          ]}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.serviceUsageType')} />
            <div className="form-group col-md-12 buttons-attibute pt-4 mb-0">
              {modeCreateConfigUsageType === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewOderType}>
                  +
                  {t('label.addNewServiceType')}
                </button>
              )}
              {modeModifyConfigUsageType === 2 && (
                <button
                  type="button"
                  onClick={evt => this.onHandleSave({ evt })}
                  className="button button-border x-small float-right"
                >
                  {data && data.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetConfigUsageType}
                className="button mr-2 button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            {data &&
              data.configUsageTypeService &&
              data.configUsageTypeService.length > 0 &&
              data.configUsageTypeService.map((item, index) => {
                if (size(item) < 2) return '';
                const optionsServiceType = t('selections:serviceType')();
                const typeSelect = optionsServiceType.find(val => val.value === item.serviceType);
                return (
                  <form
                    noValidate
                    ref={this.formRef}
                    // onSubmit={this.onHandleSave}
                    className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                  >
                    <FormCollapse
                      key={`key-${index}`}
                      isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                      title={`${typeSelect ? typeSelect.label : ''}`}
                      state={`state-${index}`}
                      onToggleTab={this.onToggleTab}
                    >
                      <div className="col-md-12 row p-3">
                        <div className="col-md-12 row">
                          <GenericInput
                            label="label.serviceType"
                            name="serviceType"
                            required
                            value={item.serviceType}
                            type="select"
                            tOptions="selections:serviceType"
                            wrapperClass="mr-0 col-md-4 pl-2 pb-3"
                            onChange={({ name, value }) => this.onHandleChange({ index, name, value })}
                          />
                          <GenericInput
                            label="label.status"
                            name="status"
                            required
                            value={item.status}
                            type="select"
                            tOptions="selections:status"
                            wrapperClass="col-md-4 pb-3"
                            onChange={({ name, value }) => this.onHandleChange({ index, name, value })}
                          />
                        </div>
                        <DataTable
                          indexParent={index}
                          columns={usageTypeColumns}
                          data={item && item.configUsageTypeList ? item.configUsageTypeList : []}
                          isSupportRemoveIndex
                        />
                        <div className="form-group col-md-12 buttons-attibute pt-4">
                          <button
                            type="button"
                            className="button button-border black x-small"
                            onClick={() => this.addNewItem({ index })}
                          >
                            +
                            {t('label.addNewUsageType')}
                          </button>
                        </div>
                      </div>
                    </FormCollapse>
                  </form>
                );
              })}
            <br />
          </div>
        </div>
      </div>
    );
  }
}

UsageType.propTypes = {
  getConfigUsageType: PropTypes.func,
  modifyConfigUsageType: PropTypes.func,
  createConfigUsageType: PropTypes.func,
};

UsageType.defaultProps = {
  getConfigUsageType: () => {},
  modifyConfigUsageType: () => {},
  createConfigUsageType: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getConfigUsageType,
    modifyConfigUsageType,
    createConfigUsageType,
  })(withRouter(UsageType))
);
