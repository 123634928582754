import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'type', 'recordNumber', 'length', 'mandatory']);
  const modifyCustomUsageRecCfg = `modifyCustomUsageRecCfg(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyCustomUsageRecCfg} {
      id
    }
  }
`;
};
