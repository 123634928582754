import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataModify = parseOrderMutation(data, ['quantity', 'type', 'paymentMethod', 'totalPrice', 'servicePrice']);
  const saveSplitOpportunity = `saveSplitOpportunity(input: ${dataModify})`;
  return gql`
  mutation {
    ${saveSplitOpportunity} {
      orderId
    }
  }
`;
};
