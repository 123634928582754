import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataCreate = parseOrderMutation(data, ['numberOfAccounts']);
  const createBillingAccounts = `createBillingAccounts(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createBillingAccounts} {
      id
      type
      contacts {
        firstName
      }
      addresses {
        city
      }
      billingProfiles {
        billingDom
        billingFrequency
      }
      paymentProfiles {
        paymentTerm
        paymentMethod
        creditCards {
          merchant
        }
      }
    }
  }
`;
};
