import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({});

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
