import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class RoleGroupSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'common:label.type',
      name: 'type',
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:permissionStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-3';
}

RoleGroupSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

RoleGroupSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(RoleGroupSearchForm);
