import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataModify = parseOrderMutation(data, ['orderType', 'index', 'orderProration', 'alignToCycle', 'waiveCancel']);
  const modifyOrderReasonCodes = `modifyOrderReasonCodes(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyOrderReasonCodes} {
      id
    }
  }
`;
};
