import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'status',
    'index',
    'locationIndex',
    'autoCreateLegalEntities',
    'autoCreateBusinessUnits',
    'type',
  ]);
  const modifyDivision = `modifyDivision(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyDivision} {
      id
    }
  }
`;
};
