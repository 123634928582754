import listCurrency from '../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const sortRevenue = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  productFamily: {
    asc: 'productFamily_ASC',
    desc: 'productFamily_DESC',
  },
  productLine: {
    asc: 'productLine_ASC',
    desc: 'productLine_DESC',
  },
  productType: {
    asc: 'productType_ASC',
    desc: 'productType_DESC',
  },
  productSubType: {
    asc: 'productSubType_ASC',
    desc: 'productSubType_DESC',
  },
  currency: {
    asc: 'currency_ASC',
    desc: 'currency_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const listMilestoneFields = [
  {
    label: 'common:label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'common:label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'common:label.productFamily',
    name: 'productFamily',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.currency',
    name: 'currency',
    type: 'select',
    options: currencyOptions,
  },
  {
    label: 'common:label.country',
    name: 'country',
  },
  {
    label: 'common:label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:milestoneStatus',
  },
  {
    label: 'common:label.unit',
    name: 'unit',
    type: 'select',
    tOptions: 'selections:milestoneUnits',
    required: true,
  },
  {
    label: 'common:label.serviceType',
    name: 'type',
    type: 'select',
    tOptions: 'selections:serviceType',
  },
  {
    label: 'common:label.offsetValue',
    name: 'offsetval',
    type: 'number',
    required: true,
  },
];

const sortCurency = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};

const sortAccountingLog = {
  drAccountType: {
    asc: 'drAccountType_ASC',
    desc: 'drAccountType_DESC',
  },
  drGlAccount: {
    asc: 'drGlAccount_ASC',
    desc: 'drGlAccount_DESC',
  },
  drAmount: {
    asc: 'drAmount_ASC',
    desc: 'drAmount_DESC',
  },
  drCurrency: {
    asc: 'drCurrency_ASC',
    desc: 'drCurrency_DESC',
  },
  crAccountType: {
    asc: 'crAccountType_ASC',
    desc: 'crAccountType_DESC',
  },
  crGlAccount: {
    asc: 'crGlAccount_ASC',
    desc: 'crGlAccount_DESC',
  },
  crAmount: {
    asc: 'crAmount_ASC',
    desc: 'crAmount_DESC',
  },
  crCurrency: {
    asc: 'crCurrency_ASC',
    desc: 'crCurrency_DESC',
  },
  objectId: {
    asc: 'objectId_ASC',
    desc: 'objectId_DESC',
  },
  objectType: {
    asc: 'objectType_ASC',
    desc: 'objectType_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

const sortEbsExtract = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  period: {
    asc: 'period_ASC',
    desc: 'period_DESC',
  },
  extractStatus: {
    asc: 'extractStatus_ASC',
    desc: 'extractStatus_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};

const groupNamePermissions = {
  MODIFY_CONFIG_CORRESPONDENCE_TEMPLATE: 'MODIFY_CONFIG_CORRESPONDENCE_TEMPLATE',
  CREATE_CONFIG_CORRESPONDENCE_TEMPLATE: 'CREATE_CONFIG_CORRESPONDENCE_TEMPLATE',
  VIEW_CONFIG_CORRESPONDENCE_TEMPLATE: 'VIEW_CONFIG_CORRESPONDENCE_TEMPLATE',
  
};

export { sortRevenue, listMilestoneFields, sortCurency, groupNamePermissions, sortAccountingLog, sortEbsExtract };
