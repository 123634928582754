import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  listQuotes: [],
  subscriptionAssets: [],
  serviceUnits: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_QUOTE:
      return state.set('errorMessage', '');

    case types.SEARCH_QUOTE_SUCCESS:
      return state.set('listQuotes', action.payload);

    default:
      return state;
  }
}
