import React, { useState } from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWithItem from '../ModalWithItem';
import DataTable from '../DataTable/Table';
import GenericInput from '../GenericInput';

const tableColumns = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'category',
    label: 'label.category',
  },
];

const ModalSelectSubscription = ({ isOpen, onToggle, wrapperClass, data, onSelect }) => {
  const { t } = useTranslation('common');
  const [selectedItem, setSelectedItem] = useState(null);

  const onSelectItem = ({ item }) => {
    setSelectedItem(item);
  };

  const newColumns = [
    {
      name: 'select',
      label: 'common:label.select',
      isRenderT: true,
      style: { width: '80px', maxWidth: '80px' },
      render: (name, item) => {
        return (
          <GenericInput
            value={!!(selectedItem && selectedItem.id === item.id)}
            wrapperClass="col-md-12"
            onChange={() => onSelectItem({ item })}
            name={item.id}
            type="checkbox"
            checked={!!(selectedItem && selectedItem.id === item.id)}
          />
        );
      },
    },
    ...tableColumns,
  ];
  return (
    <ModalWithItem
      isOpen={isOpen}
      toggle={onToggle}
      modalTitle={t('label.selectASubscriptionToProceed')}
      wrapperClass={wrapperClass || null}
    >
      <div>
        <DataTable columns={newColumns} data={data || [{}]} onClickRow={(evt, item) => onSelectItem({ item })} />
      </div>
      <div className="float-right">
        <button
          type="button"
          className="btn btn-secondary mr-2"
          onClick={() => {
            onToggle();
            setSelectedItem(null);
          }}
        >
          {t('label.cancel')}
        </button>

        <button
          type="button"
          className="btn btn-success"
          onClick={() => {
            onSelect(selectedItem);
            setSelectedItem(null);
          }}
        >
          {t('label.select')}
        </button>
      </div>
    </ModalWithItem>
  );
};

ModalSelectSubscription.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  onSelect: PropsType.func.isRequired,
  wrapperClass: PropsType.string,
  data: PropsType.arrayOf(PropsType.any),
};

ModalSelectSubscription.defaultProps = {
  wrapperClass: '',
  data: [],
};

export default ModalSelectSubscription;
