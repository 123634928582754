import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPageTotalCount, formatMoneyValue } from '../../../utils/utils';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { getUserId } from '../../../localStorages';
import RouteNames from '../../../containers/App/RouteNames';
import { TablePagination, DataTable, GenericInput, ModalWithItem } from '../../common';
import PaymentsSearchForm from '../PaymentsSearchForm';
import InvoicesSearchForm from '../InvoicesSearchForm';
import {
  searchPaymentByAccount,
  getObjectFileById,
  reversePaymentService,
  selectInvoiceId,
  allocatePaymentService,
  reGeneratePayment,
  updatePaymentStatus,
} from '../../../containers/App/actions';
import ModalViewPaymentFile from '../../common/ModalViewPaymentFile';
import ModalViewPaymentActivity from '../../common/ModalViewPaymentActivity';
import { statusDisableFielsdViewFolio } from '../../../utils/constants';
import { makeGetPermissionsPaymentManagement } from '../../../containers/App/selectors';

import {
  checkPermissionProcessPaymentReversal,
  checkPermissionManualPaymentAllocation,
  checkPermissionRegeneratePaymentFolio,
  checkPermissiontResendToVendor,
} from '../../../containers/PaymentsPage/CheckPermission';

const paymentDetails = [
  {
    name: 'id',
    label: 'common:label.id',
  },
  {
    name: 'paymentDate',
    label: 'common:label.paymentDate',
  },
  {
    name: 'amount',
    label: 'common:label.paymentAmount',
  },
  {
    name: 'remainingAmount',
    label: 'common:label.remainingAmount',
  },
];

const paymentsSort = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  invoiceUnitId: {
    asc: 'invoiceUnitId_ASC',
    desc: 'invoiceUnitId_DESC',
  },
  paymentDate: {
    asc: 'paymentDate_ASC',
    desc: 'paymentDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  transactionId: {
    asc: 'transactionId_ASC',
    desc: 'transactionId_ASC',
  },
};

const messageCSVStamping = content => {
  if (content && content.search(/\r/) === -1) return <div>{content}</div>;
  const newContent = content ? content.split(/\r/g) : content;
  return <div>{newContent && newContent.length ? newContent.map(val => <div>{val}</div>) : ''}</div>;
};

class Payments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: { accountId: props.id },
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      data: [],
      paymentFileData: [],
      paymentSelect: null,
      reversePaymentData: { paymentForInvalidAccount: 'TRUE' },
      invoiceData: [],
      pageInvoice: 0,
      sizeInvoice: 20,
      filterInvoice: { accountId: props.id, due: '0' },
      totalCountInvoice: null,
      dataSubmitAllocateInvoice: [],
      remainingAmountSelect: 0,
      isReGenrate: false,
      isOpenModalStamping: false,
      stampingsData: [],
      csvData: null,
      isOpenModalStampingCSVData: false,
      isOpenModalViewPaymentActivity: false,
      isActivityViewer: false,
      isPacEnabled: false,
      isBatchFolioStamping: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.data }),
      totalCountInvoice: getPageTotalCount({ ...state, items: state.invoiceData }),
    };
  }

  componentDidMount() {
    const { location, ccpPropertyList, getCcpPropertiesConfig } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchPayments();
      });
    } else {
      this.doSearchPayments();
    }

    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doSetCcpProertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doSetCcpProertiesConfig(ccpPropertyList);
    }
  }

  doSetCcpProertiesConfig = ccpPropertyList => {
    const pacEnabledProperty = ccpPropertyList.find(val => val.property === 'pacEnabled');
    const batchFolioStamping = ccpPropertyList.find(val => val.property === 'batchFolioStamping');
    this.setState({
      isPacEnabled: pacEnabledProperty && pacEnabledProperty.value === 'true',
      isBatchFolioStamping: batchFolioStamping && batchFolioStamping.value === 'true',
    });
  };

  doSearchPayments = () => {
    const { searchPaymentByAccount } = this.props;
    const { page, size, filter, sorted } = this.state;
    this.setState({ isSearching: true });
    searchPaymentByAccount(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? paymentsSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        if (success) {
          this.setState({ isSearching: false, data });
        } else {
          this.setState({ isSearching: false, data: [] });
        }
      }
    );
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filter: newFilter }, () => this.doSearchPayments());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchPayments());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchPayments());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchPayments());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleViewPaymentFile = () => {
    this.setState({ isOpenFile: false });
  };

  onChangePaymentType = ({ paymentSelect, value }) => {
    const { reversePaymentData } = this.state;
    reversePaymentData.reversedAmount = paymentSelect.remainingAmount;
    this.setState({ typeSelect: value, reversePaymentData: { ...reversePaymentData } });
  };

  onHanldeViewPaymentFile = ({ item }) => {
    const { getObjectFileById } = this.props;
    getObjectFileById(item.id, data => {
      let pdfPath = '';
      let htmlPath = '';
      if (data && data.length) {
        const pdfData = data.find(val => val.fileType === 'PDF');
        const htmlData = data.find(val => val.fileType === 'HTML');
        if (pdfData) {
          pdfPath = pdfData.path;
        }
        if (htmlData) {
          htmlPath = htmlData.path;
        }
        this.setState({ pathFile: pdfPath, isOpenFile: true, htmlFile: htmlPath, paymentFileData: data });
      }
    });
  };

  onSelectPayment = item => {
    const { paymentSelect } = this.state;
    const { getObjectFileById } = this.props;
    getObjectFileById(item.id, data => {
      if (data && data.length) {
        this.setState({ isReGenrate: true });
      } else {
        this.setState({ isReGenrate: false });
      }
    });
    if (paymentSelect && paymentSelect.id === item.id) {
      this.setState({ paymentSelect: null, reversePaymentData: {}, typeSelect: null });
    } else {
      this.setState({
        paymentSelect: cloneDeep(item),
        reversePaymentData: {
          reversedAmount: item.remainingAmount,
          paymentForInvalidAccount: 'TRUE',
          status: item.status,
        },
        typeSelect: null,
      });
    }
  };

  onHandleReverse = type => {
    const { reversePaymentService } = this.props;
    const { reversePaymentData, paymentSelect } = this.state;
    const payloadRequest = {};
    payloadRequest.id = paymentSelect.id;
    payloadRequest.reversedAmount = reversePaymentData.reversedAmount;
    payloadRequest.reason = reversePaymentData.reason;
    payloadRequest.notes = reversePaymentData.notes;
    payloadRequest.reversalDate = reversePaymentData.reversalDate;
    payloadRequest.paymentForInvalidAccount = type === 'REVERSE_PAYMENT' ? 'true' : 'false';
    payloadRequest.userId = getUserId() || 'UserX001';
    reversePaymentService(payloadRequest, ({ success }) => {
      if (success) {
        this.setState({ paymentSelect: {}, reversePaymentData: {}, typeSelect: null });
        this.doSearchPayments();
      }
    });
  };

  onHandleReGenerate = () => {
    const { reGeneratePayment } = this.props;
    const { reversePaymentData, paymentSelect } = this.state;
    const payloadRequest = {};
    payloadRequest.id = paymentSelect.id;
    payloadRequest.bank = reversePaymentData.bank;
    payloadRequest.paymentMethod = reversePaymentData.paymentMethod;
    reGeneratePayment(payloadRequest, ({ success }) => {
      if (success) {
        this.setState({ paymentSelect: {}, reversePaymentData: {}, typeSelect: null });
        this.doSearchPayments();
      }
    });
  };

  onHandleResendToVendor = () => {
    const { resendPaymentVendor } = this.props;
    const { paymentSelect } = this.state;
    resendPaymentVendor(paymentSelect.id, ({ success }) => {
      if (success) {
        this.setState({ paymentSelect: {}, reversePaymentData: {}, typeSelect: null });
        this.doSearchPayments();
      }
    });
  };

  onHandleAllocate = () => {
    const { allocatePaymentService, history } = this.props;
    const { dataSubmitAllocateInvoice, paymentSelect } = this.state;
    const data = {
      id: paymentSelect ? paymentSelect.id : '',
      userId: getUserId() || 'UserX001',
      allocationData: dataSubmitAllocateInvoice.length ? dataSubmitAllocateInvoice : null,
    };

    allocatePaymentService(data, ({ success }) => {
      if (success) {
        this.setState({
          dataSubmitAllocateInvoice: [],
          invoiceData: [],
          pageInvoice: 0,
          sizeInvoice: 20,
          totalCountInvoice: null,
          typeSelect: null,
          filterInvoice: {},
          remainingAmountSelect: 0,
          paymentSelect: {},
        });
        this.doSearchPayments();
      }
    });
  };

  onUpdatePaymentStatus = () => {
    const { paymentSelect } = this.state;
    const { updatePaymentStatus } = this.props;
    updatePaymentStatus({ id: paymentSelect.id, status: 'CANCELLATION_STAMPED' }, ({ success }) => {
      if (success) {
        this.setState({
          dataSubmitAllocateInvoice: [],
          invoiceData: [],
          pageInvoice: 0,
          sizeInvoice: 20,
          totalCountInvoice: null,
          typeSelect: null,
          filterInvoice: {},
          remainingAmountSelect: 0,
          paymentSelect: {},
        });
        this.doSearchPayments();
      }
    });
  };

  onChangeReversePayment = ({ name, value }) => {
    const { reversePaymentData, paymentSelect } = this.state;
    // if (
    //   (name === 'reversedAmount' && Number.parseFloat(paymentSelect.remainingAmount) < Number.parseFloat(value)) ||
    //   Number.parseFloat(value) < 0
    // )
    //   return '';
    if (
      (name === 'reversedAmount' && Number.parseFloat(paymentSelect.amount) < Number.parseFloat(value)) ||
      Number.parseFloat(value) < 0
    )
      return '';
    const newData = cloneDeep(reversePaymentData);
    newData[name] = value;
    this.setState({ reversePaymentData: newData });
  };

  doSearchInvoiceUnit = () => {
    const { selectInvoiceId } = this.props;
    const { pageInvoice, sizeInvoice, filterInvoice } = this.state;
    this.setState({ isSearchingInvoice: true });
    selectInvoiceId(
      {
        page: pageInvoice + 1,
        size: sizeInvoice,
        filter: filterInvoice,
        // sort: !isEmpty(sortedInvoice) ? paymentsSort[sortedInvoice.sortCol][sortedInvoice.sortDir] : null,
      },
      ({ success, data }) => {
        if (success) {
          this.setState({ isSearchingInvoice: false, invoiceData: data });
        } else {
          this.setState({ isSearchingInvoice: false, invoiceData: [] });
        }
      }
    );
  };

  onSizeChangeInvoice = size => {
    this.setState({ sizeInvoice: size, pageInvoice: 0 }, () => this.doSearchInvoiceUnit());
  };

  onPageChangeInvoice = page => {
    this.setState({ pageInvoice: page }, () => this.doSearchInvoiceUnit());
  };

  onHandleSubmitSearchInvoice = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    newFilter.due = '0';
    this.setState({ filterInvoice: newFilter }, () => this.doSearchInvoiceUnit());
  };

  onSelectPaymentInvoice = item => {
    const { dataSubmitAllocateInvoice, paymentSelect } = this.state;
    let newData = cloneDeep(dataSubmitAllocateInvoice);
    if (newData && newData.find(val => val.invoiceUnitId === item.id)) {
      // const itemSelect = newData.find(val => val.invoiceUnitId === item.id);
      newData = newData.filter(val => val.invoiceUnitId !== item.id);
      // paymentSelect.remainingAmount =
      //   Number.parseFloat(paymentSelect.remainingAmount) + Number.parseFloat(itemSelect.amount || 0);
    } else {
      // paymentSelect.remainingAmount =
      //   Number.parseFloat(paymentSelect.remainingAmount || 0) - Number.parseFloat(item.due || 0);
      newData.push({
        invoiceUnitId: item.id,
        accountId: item.accountId,
        amount:
          Number.parseFloat(paymentSelect.remainingAmount || 0) < Number.parseFloat(item.due)
            ? Number.parseFloat(paymentSelect.remainingAmount || 0)
            : item.due || '0',
      });
    }

    this.setState({ dataSubmitAllocateInvoice: newData });
  };

  onChangeInvoiceAmount = ({ name, value, item }) => {
    try {
      const { dataSubmitAllocateInvoice, paymentSelect } = this.state;
      const newData = cloneDeep(dataSubmitAllocateInvoice);
      const indexItem = dataSubmitAllocateInvoice.findIndex(val => val.invoiceUnitId === item.id);
      newData[indexItem][name] = value;
      let totalAmount = 0;
      newData.forEach(val => {
        const { amount } = val;
        totalAmount += Number.parseFloat(amount || 0);
      });
      if (
        Number.parseFloat(paymentSelect.remainingAmount) < totalAmount ||
        Number.parseFloat(value || 0) > Number.parseFloat(item.due || 0)
      ) {
        return '';
      }

      this.setState({ dataSubmitAllocateInvoice: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHanldeCancelAllocate = () => {
    this.setState({
      dataSubmitAllocateInvoice: [],
      invoiceData: [],
      pageInvoice: 0,
      sizeInvoice: 20,
      totalCountInvoice: null,
      typeSelect: null,
      filterInvoice: {},
      remainingAmountSelect: 0,
    });
  };

  doGetObjectFileById = invoiceId => {
    const { history, getObjectFileById, id } = this.props;
    const { filter } = this.state;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.prettifyPath(RouteNames.customerDetailsPayments.path, id),
            fileData: data,
            filter,
          },
        });
      }
    });
  };

  doGetInvoiceStampingByInvoiceId = invoiceUnitId => {
    const { getPacInterfaceRecordById } = this.props;
    getPacInterfaceRecordById(invoiceUnitId, ({ success, data }) => {
      this.setState({ stampingsData: success ? data : [] });
    });
  };

  onToggleModalStamping = item => {
    const { isOpenModalStamping } = this.state;
    if (!isOpenModalStamping && item) {
      this.doGetInvoiceStampingByInvoiceId(item.id);
    }
    this.setState({ isOpenModalStamping: !isOpenModalStamping });
  };

  onToggleModalStampingCSVData = item => {
    const { isOpenModalStampingCSVData } = this.state;
    if (!isOpenModalStampingCSVData && item) {
      this.setState({ csvData: item.csvRecord });
    }
    this.setState({ isOpenModalStampingCSVData: !isOpenModalStampingCSVData });
  };

  onToggleModalViewPaymentActivity = (item, isActivityViewer) => {
    const { isOpenModalViewPaymentActivity } = this.state;
    this.setState({
      isOpenModalViewPaymentActivity: !isOpenModalViewPaymentActivity,
      itemSelected: item,
      isActivityViewer,
    });
  };

  render() {
    const { id, t, currencyOptions, customerInfo, location, permissionsPayment, modeSearchPayment } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      data,
      isOpenFile,
      pathFile,
      htmlFile,
      paymentFileData,
      paymentSelect,
      typeSelect,
      invoiceData,
      reversePaymentData,
      pageInvoice,
      sizeInvoice,
      totalCountInvoice,
      isSearchingInvoice,
      dataSubmitAllocateInvoice,
      remainingAmountSelect,
      isReGenrate,
      isOpenModalStamping,
      csvData,
      stampingsData,
      isOpenModalStampingCSVData,
      isOpenModalViewPaymentActivity,
      itemSelected,
      isActivityViewer,
      isPacEnabled,
      isBatchFolioStamping,
    } = this.state;

    if (!modeSearchPayment) return null;

    let modeProcessPaymentReversal = 0;
    let modeManualPaymentAllocation = 0;
    let modeRegeneratePaymentFolio = 0;
    let modeResendToVendor = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeProcessPaymentReversal = checkPermissionProcessPaymentReversal({ listPermission });
      modeManualPaymentAllocation = checkPermissionManualPaymentAllocation({ listPermission });
      modeRegeneratePaymentFolio = checkPermissionRegeneratePaymentFolio({ listPermission });
      modeResendToVendor = checkPermissiontResendToVendor({ listPermission });
    }

    const newData = cloneDeep(dataSubmitAllocateInvoice);
    let totalAmount = 0;
    newData.forEach(val => {
      const { amount } = val;
      totalAmount += Number.parseFloat(amount || 0);
    });
    const additionalColumns = [];
    if (isPacEnabled && isBatchFolioStamping) {
      additionalColumns.push({
        name: 'viewAcitvity',
        label: 'common:label.viewFolio',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            // onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
            disabled={statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
            onClick={() => this.onHanldeViewPaymentFile({ item })}
          >
            {t('label.view')}
          </button>
        ),
      });
      additionalColumns.push({
        name: 'stamping',
        label: 'common:label.stamping',
        render: (colName, item) => {
          let isDisableButton = true;
          if (
            item.folioStatus === 'STAMPING_REQUESTED' ||
            item.folioStatus === 'STAMPED' ||
            item.folioStatus === 'CANCELLATION_STAMPING_REQUESTED' ||
            item.folioStatus === 'CANCELLATION_STAMPED'
            // ||
            // item.folioStatus === 'CLOSED' ||
            // item.folioStatus === 'COLLECTION'
          ) {
            isDisableButton = false;
          }
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              disabled={isDisableButton}
              onClick={() => {
                this.onToggleModalStamping(item);
              }}
            >
              {t('label.view')}
            </button>
          );
        },
      });
    }
    const newColumns = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (colName, item) => (
          <GenericInput
            value={paymentSelect && paymentSelect.id === item.id}
            name="isSelectPayment"
            type="checkbox"
            wrapperClass="col-md-4"
            onChange={({ value }) => this.onSelectPayment(item)}
          />
        ),
      },

      {
        name: 'paymentRefId',
        label: 'common:label.paymentRefId',
        render: (colName, item) =>
          item.paymentRefId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, false)}
            >
              {item.paymentRefId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'id',
        label: 'common:label.id',
        sortable: true,
        render: (colName, item) => (
          <button
            className="btn btn-outline-success btn-sm no-border"
            type="button"
            // onClick={() => this.onHanldeViewPaymentFile({ item })}
            onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
          >
            {item.id}
          </button>
        ),
      },
      {
        name: 'accountId',
        label: 'label.accountId',
      },
      {
        name: 'paymentDate',
        label: 'common:label.paymentDate',
        sortable: true,
      },
      {
        name: 'folioId',
        label: 'common:label.folioUUID',
      },
      {
        name: 'transactionId',
        label: 'label.transactionId',
        sortable: true,
      },
      {
        name: 'externalTransId',
        label: 'common:label.externalId',
      },
      {
        name: 'userId',
        label: 'common:label.userId',
      },
      {
        name: 'paymentDescription',
        label: 'common:label.description',
      },
      {
        name: 'paymentSuspenseId',
        label: 'common:label.paymentSuspenseId',
      },
      {
        name: 'paymentSource',
        label: 'common:label.paymentSource',
      },
      {
        name: 'paymentSourceId',
        label: 'common:label.paymentSourceId',
      },
      {
        name: 'status',
        label: 'common:label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'folioStatus',
        label: 'common:label.folioStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'amount',
        label: 'common:label.amount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.amount || 0)}</span>;
        },
      },
      {
        name: 'currency',
        label: 'label.currency',
      },
      {
        name: 'method',
        label: 'common:label.paymentMethod',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.method) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'remainingAmount',
        label: 'common:label.remainingAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.remainingAmount)}</span>;
        },
      },
      {
        name: 'reversedAmount',
        label: 'common:label.reversedAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.reversedAmount)}</span>;
        },
      },
      {
        name: 'invoiceUnitId',
        label: 'common:label.invoiceId',
      },
      {
        name: 'sourceCurrency',
        label: 'common:label.sourceCurrency',
      },
      {
        name: 'sourceCurrencyAmount',
        label: 'common:label.sourceCurrencyAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.sourceCurrencyAmount)}</span>;
        },
      },
      {
        name: 'exchangeDeltaAmount',
        label: 'common:label.exchangeDelta',
      },
      {
        name: 'toleratedThldAmount',
        label: 'common:label.thresholdTolerance',
      },
    ];

    const invoiceColumns = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (colName, item) => {
          return (
            <GenericInput
              value={dataSubmitAllocateInvoice && dataSubmitAllocateInvoice.find(val => val.invoiceUnitId === item.id)}
              name="selectInvoiceId"
              type="checkbox"
              wrapperClass="col-md-4"
              onChange={({ value }) => this.onSelectPaymentInvoice(item)}
              disabled={
                (Number.parseFloat(paymentSelect.remainingAmount) <= totalAmount ||
                  Number.parseFloat(paymentSelect.remainingAmount) - totalAmount - Number.parseFloat(item.due) < 0) &&
                dataSubmitAllocateInvoice.length &&
                !dataSubmitAllocateInvoice.find(val => val.invoiceUnitId === item.id)
              }
            />
          );
        },
      },
      {
        name: 'allocateAmount',
        label: 'common:label.allocateAmount',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={
              dataSubmitAllocateInvoice &&
              dataSubmitAllocateInvoice.find(val => val.invoiceUnitId === item.id) &&
              dataSubmitAllocateInvoice.find(val => val.invoiceUnitId === item.id).amount
                ? dataSubmitAllocateInvoice.find(val => val.invoiceUnitId === item.id).amount
                : ''
            }
            name="amount"
            wrapperClass="col-md-12"
            disabled={
              !(dataSubmitAllocateInvoice && dataSubmitAllocateInvoice.find(val => val.invoiceUnitId === item.id))
            }
            type="number"
            onChange={({ name, value }) => this.onChangeInvoiceAmount({ name, value, item })}
          />
        ),
      },
      {
        name: 'due',
        label: 'common:label.due',
      },
      {
        name: 'id',
        label: 'common:label.id',
        render: (colName, item) => {
          return (
            <button className="text-success no-border" type="button" onClick={() => this.doGetObjectFileById(item.id)}>
              {item.id}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'common:label.invoiceDate',
      },
      {
        name: 'dueDate',
        label: 'common:label.dueDate',
      },
      {
        name: 'total',
        label: 'common:label.invoiceTotal',
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
    ];

    const stampingRecordColumns = [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:pacRecordStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'extractType',
        label: 'common:label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:pacExtractType')().find(val => val.value === item.extractType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'createdDate',
        label: 'common:label.createdDate',
      },
      {
        name: 'objectDate',
        label: 'common:label.modifiedDate',
      },
      {
        name: 'data',
        label: 'common:label.data',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              this.onToggleModalStampingCSVData(item);
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];

    let paymentSelectOptions = t('selections:paymentAction')();
    if ((paymentSelect && paymentSelect.remainingAmount === 0) || !modeManualPaymentAllocation) {
      paymentSelectOptions = paymentSelectOptions.filter(val => val.value !== 'ALLOCATE_PAYMENT');
    }
    if ((paymentSelect && !isReGenrate) || !modeRegeneratePaymentFolio) {
      paymentSelectOptions = paymentSelectOptions.filter(val => val.value !== 'RE_GENERATE_FOLIO');
    }
    if (
      (paymentSelect &&
        ['CANCELLATION_STAMPING_REQUESTED', 'STAMPING_REQUESTED'].indexOf(paymentSelect.folioStatus) === -1) ||
      !modeResendToVendor
    ) {
      paymentSelectOptions = paymentSelectOptions.filter(val => val.value !== 'RESEND_TO_VENDOR');
    }

    if (
      (paymentSelect &&
        paymentSelect.id &&
        (Number.parseFloat(paymentSelect.remainingAmount) === Number.parseFloat(paymentSelect.amount * -1) ||
          paymentSelect?.paymentTransactionType === 'PAYMENT_REVERSAL' ||
          paymentSelect?.status === 'REVERSED' ||
          paymentSelect?.status === 'REVERSED_TO_SUSPENSE' ||
          paymentSelect?.folioStatus === 'CANCELLATION_STAMPING_REQUESTED' ||
          paymentSelect?.folioStatus === 'CANCELLATION_STAMPED')) ||
      !modeProcessPaymentReversal
    ) {
      paymentSelectOptions = paymentSelectOptions.filter(val => val.value !== 'REVERSE_PAYMENT');
    }

    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('customerPage:sidebarHandleAccountPage.payments')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <PaymentsSearchForm
              onSubmit={this.onHandleSubmit}
              defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
            />
            <br />
            <div>
              <DataTable
                columns={newColumns}
                data={data}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
              />
            </div>
            <div className="col-md-12 row pb-3 pl-2">
              <GenericInput
                value={typeSelect}
                name="type"
                onChange={({ name, value }) => this.onChangePaymentType({ paymentSelect, value })}
                wrapperClass="col-md-3"
                label="label.action"
                options={paymentSelectOptions}
                type="select"
                placeholder="<Select Action>"
                disabled={!paymentSelect?.id}
                isPlaceholder
                isClearable
              />
              {(typeSelect === 'REVERSE_PAYMENT' || typeSelect === 'CANCEL_PAYMENT') && (
                <>
                  <GenericInput
                    value={reversePaymentData.reversedAmount}
                    name="reversedAmount"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.amountToReverse"
                    type="number"
                  />
                  <GenericInput
                    value={reversePaymentData.reason}
                    name="reason"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.reversalReason"
                    tOptions="selections:paymentReversalReason"
                    type="select"
                    isClearable
                  />
                  {/* <GenericInput
                    value={reversePaymentData.paymentForInvalidAccount}
                    name="paymentForInvalidAccount"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.createSuspense"
                    tOptions="selections:selectBool"
                    type="select"
                  /> */}
                  <GenericInput
                    value={reversePaymentData.reversalDate}
                    name="reversalDate"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.reversalDate"
                    type="date"
                    minDate={paymentSelect && paymentSelect.paymentDate ? new Date(paymentSelect.paymentDate) : null}
                    isClearable
                  />
                  <GenericInput
                    value={reversePaymentData.notes}
                    name="notes"
                    type="textarea"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.notes"
                  />
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="button button-border x-small mt-4"
                      onClick={evt => this.onHandleReverse(typeSelect)}
                      disabled={modeProcessPaymentReversal === 1}
                    >
                      {typeSelect === 'CANCEL_PAYMENT' ? t('label.cancelPayment') : t('label.reversePayment')}
                    </button>
                  </div>
                </>
              )}
              {/* {typeSelect === 'MANUAL_CANCELLATION_UPDATE' && (
                <div className="col-md-9 row">
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="button button-border x-small mt-4"
                      onClick={this.onUpdatePaymentStatus}
                    >
                      {t('label.submit')}
                    </button>
                  </div>
                </div>
              )} */}
              {typeSelect === 'RE_GENERATE_FOLIO' && (
                <div className="col-md-9 row">
                  <GenericInput
                    value={reversePaymentData.paymentMethod}
                    name="paymentMethod"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.paymentMethod"
                  />
                  <GenericInput
                    value={reversePaymentData.bank}
                    name="bank"
                    onChange={this.onChangeReversePayment}
                    wrapperClass="col-md-3"
                    label="label.bank"
                  />
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="button button-border x-small mt-4"
                      onClick={this.onHandleReGenerate}
                      disabled={modeRegeneratePaymentFolio === 1}
                    >
                      {t('label.reGenerate')}
                    </button>
                  </div>
                </div>
              )}
              {typeSelect === 'RESEND_TO_VENDOR' && !!modeResendToVendor && (
                <div className="col-md-9 row">
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="button button-border x-small mt-4"
                      onClick={this.onHandleResendToVendor}
                      disabled={modeResendToVendor === 1}
                    >
                      {t('label.submit')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalViewPaymentFile
          title={t('label.paymentFile')}
          isOpen={isOpenFile}
          pathFile={pathFile}
          htmlFile={htmlFile}
          paymentFileData={paymentFileData}
          onToggle={this.onToggleViewPaymentFile}
        />
        <ModalWithItem
          isOpen={typeSelect === 'ALLOCATE_PAYMENT'}
          onToggle={this.onHanldeCancelAllocate}
          modalTitle={t('label.paymentAllocation')}
        >
          <div className="col-md-12 row border-bottom pt-3 pb-3">
            {paymentDetails.map(val => {
              let value = '';
              if (val.name === 'remainingAmount' && paymentSelect) {
                value = (Number.parseFloat(paymentSelect[val.name] || 0) - Number.parseFloat(totalAmount)).toFixed(2);
              }
              return (
                <GenericInput
                  value={paymentSelect ? value || paymentSelect[val.name] : ''}
                  onChange={() => {}}
                  wrapperClass="col-md-3"
                  readOnly
                  {...val}
                />
              );
            })}
          </div>
          <br />
          <InvoicesSearchForm onSubmit={this.onHandleSubmitSearchInvoice} />
          <br />
          <DataTable columns={invoiceColumns} data={invoiceData} isLoading={isSearchingInvoice} />
          <div className="col-sm-12 mb-30 text-right">
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={this.onHanldeCancelAllocate}
            >
              {t('label.cancel')}
            </button>
            <button
              type="button"
              className="button button-border x-small mr-2"
              disabled={modeManualPaymentAllocation === 1}
              onClick={this.onHandleAllocate}
            >
              {t('label.allocatePayment')}
            </button>
          </div>
          <div className="col-sm-12 mb-30">
            <TablePagination
              pageNumber={pageInvoice}
              pageSize={sizeInvoice}
              totalCount={totalCountInvoice}
              onPageChange={this.onPageChangeInvoice}
              onSizeChange={this.onSizeChangeInvoice}
            />
          </div>
        </ModalWithItem>

        <ModalWithItem
          modalTitle={t('label.stampingRecord')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStamping}
          onToggle={this.onToggleModalStamping}
        >
          <DataTable columns={stampingRecordColumns} data={stampingsData || []} />
          <button type="button" onClick={this.onToggleModalStamping} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>

        <ModalWithItem
          modalTitle={t('label.data')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStampingCSVData}
          onToggle={this.onToggleModalStampingCSVData}
        >
          <div className="mt-3 mb-5 csv-content">
            {/* {csvData} */}
            {messageCSVStamping(csvData)}
          </div>
          <button type="button" onClick={this.onToggleModalStampingCSVData} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
        {/* <ModalViewAllocation
          id={itemSelected?.id || null}
          isOpen={isOpenModalViewPaymentActivity}
          onToggle={evt => this.onToggleModalViewPaymentActivity()}
          itemSelected={itemSelected}
        /> */}
        <ModalViewPaymentActivity
          id={itemSelected?.id || null}
          isOpen={isOpenModalViewPaymentActivity}
          onToggle={evt => this.onToggleModalViewPaymentActivity()}
          itemSelected={itemSelected}
          title={isActivityViewer ? t('label.paymentActivity') : t('label.paymentReferenceActivity')}
          isSuspense={isActivityViewer}
          isRemainingAmount
          isPaymentActivity
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

Payments.propTypes = {
  searchPaymentByAccount: PropTypes.func.isRequired,
  id: PropTypes.string,
};

Payments.defaultProps = {
  id: '',
};

const newPayments = connect(mapStateToProps, {
  searchPaymentByAccount,
  getObjectFileById,
  reversePaymentService,
  selectInvoiceId,
  allocatePaymentService,
  reGeneratePayment,
  updatePaymentStatus,
})(Payments);

export default withTranslation(['common', 'selections'])(newPayments);
