import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput, ModalWithItem } from '../../../components/common';

const CreateTaskForm = ({
  listFields,
  data,
  onChangeData,
  onClickItem,
  onSubmit,
  onToggleModal,
  isOpenModal,
  title,
  children,
}) => {
  const { t } = useTranslation('common');

  return (
    <ModalWithItem
      wrapperClass="modal-70 modal-custom"
      modalTitle={t(title)}
      isOpen={isOpenModal}
      onToggle={onToggleModal}
    >
      {children}
      <div className="col-md-12 row">
        {listFields.map(item => (
          <GenericInput
            {...item}
            value={data[item.name] || null}
            readOnly={!!item.fa || item.readOnly}
            disabled={item.readOnly || item.disabled}
            onClick={item.fa ? () => onClickItem(item.name) : null}
            onChange={onChangeData}
          />
        ))}
      </div>
      <div>
        <div className="text-center mt-3 mb-3">
          <button type="button" className="button x-small float-right" onClick={onSubmit}>
            {t('label.create')}
          </button>
          <button type="button" className="button button-border black x-small mr-2 float-right" onClick={onToggleModal}>
            {t('label.cancel')}
          </button>
        </div>
      </div>
    </ModalWithItem>
  );
};
CreateTaskForm.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  listFields: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  isOpenModal: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.element,
};

CreateTaskForm.defaultProps = {
  listFields: [],
  data: {},
  isOpenModal: false,
  title: '',
  children: null,
};

export default CreateTaskForm;
