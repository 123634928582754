import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionSearchRoles,
  checkPermissionSearchRoleGroups,
  checkPermissionSearchUser,
  checkPermissionGetConfigApprovals,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const {
      isExpand = true,
      location: { pathname },
      permissionsUser,
      permissionsArOps,
    } = this.props;

    if (!isHasPermissionWithMenu({ permissions: permissionsUser, listKeys: permissionList.userManagement }))
      return null;
    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: false,
          mainTilte: 'navbar:operationsHub.main',
          href: RouteNames.userManagementRole.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'navbar:operationsHub.subMain.userManagement',
          childs: [
            {
              icon: 'ti-widget',
              label: 'userManagementPage:sidebar.roles',
              href: RouteNames.userManagementRole.path,
              isActive: pathname && pathname.indexOf(RouteNames.userManagementRole.path) >= 0,
            },
            {
              icon: 'ti-view-list-alt',
              label: 'userManagementPage:sidebar.roleGroups',
              href: RouteNames.userManagementRoleGroup.path,
              isActive: pathname && pathname.indexOf(RouteNames.userManagementRoleGroup.path) >= 0,
            },
            {
              icon: 'ti-user',
              label: 'userManagementPage:sidebar.users',
              href: RouteNames.userManagementUser.path,
              isActive: pathname && pathname.indexOf(RouteNames.userManagementUser.path) >= 0,
            },
            {
              icon: 'ti-settings',
              label: 'userManagementPage:sidebar.userGroups',
              href: RouteNames.userManagementUserGroups.path,
              isActive: pathname && pathname.indexOf(RouteNames.userManagementUserGroups.path) >= 0,
            },
            {
              icon: 'ti-bookmark-alt',
              label: 'userManagementPage:sidebar.approvals',
              href: RouteNames.userManagementApproval.path,
              isActive: pathname && pathname.indexOf(RouteNames.userManagementApproval.path) >= 0,
            },
          ],
        },
      ],
    };

    const newSideProps = cloneDeep(sideProps);

    let modeSearchUser = 0;
    let modeSearchRoles = 0;
    let modeSearchRoleGroups = 0;
    let modeGetConfigApprovals = 0;

    if (permissionsUser && permissionsUser.userModulePermissions) {
      const listPermission = permissionsUser.userModulePermissions;
      modeSearchUser = checkPermissionSearchUser({ listPermission });
      modeSearchRoles = checkPermissionSearchRoles({ listPermission });
      modeSearchRoleGroups = checkPermissionSearchRoleGroups({ listPermission });
    }

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeGetConfigApprovals = checkPermissionGetConfigApprovals({ listPermission });
    }

    const indexRoles = newSideProps.items.findIndex(
      child => child.mainTilte === 'navbar:operationsHub.subMain.userManagement'
    );

    if (modeSearchUser === 0 && indexRoles > -1) {
      newSideProps.items[indexRoles].childs = newSideProps.items[indexRoles].childs.filter(
        item => item.label !== 'userManagementPage:sidebar.users'
      );
    }

    if (modeSearchRoles === 0 && indexRoles > -1) {
      newSideProps.items[indexRoles].childs = newSideProps.items[indexRoles].childs.filter(
        item => item.label !== 'userManagementPage:sidebar.roles'
      );
    }

    if (modeSearchRoleGroups === 0 && indexRoles > -1) {
      newSideProps.items[indexRoles].childs = newSideProps.items[indexRoles].childs.filter(
        item => item.label !== 'userManagementPage:sidebar.roleGroups'
      );
    }
    if (modeGetConfigApprovals === 0 && indexRoles > -1) {
      newSideProps.items[indexRoles].childs = newSideProps.items[indexRoles].childs.filter(
        item => item.label !== 'userManagementPage:sidebar.approvals'
      );
    }

    return <SideBar {...newSideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsUser = store.get('auth').toJS().permissionsApp.opsHubModules;
  const permissionsArOps = store.get('auth').toJS().permissionsApp.arHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsUser: permissionsUser.length ? permissionsUser.filter(a => a.moduleName === 'USER_MANAGEMENT')[0] : {},
    permissionsArOps: permissionsArOps.length ? permissionsArOps.filter(a => a.moduleName === 'AR_OPS')[0] : {},
  };
};

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsUser: PropTypes.objectOf(PropTypes.any),
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsUser: {},
  permissionsArOps: {},
  location: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
