import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getLastIndex } from '../../../utils/utils';
import AccountTable from '../AccountTable';
import ModifyService from './ModifyService';
import constants, { orderListRadioButtons } from '../constants';
import { orderEnum } from '../../../constantsApp';
import {
  StepProgressBar,
  ModalSelectSubscription,
  Breadcrumb,
  GenericInput,
  FormWithTableItem,
} from '../../../components/common';
import {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  createOrder,
  getServiceUnitsBySubscriptionId,
  buildOrderProvisioningAttributes,
} from '../actions';
import { makeGetServiceUnits, makeGetSubscriptionAssets } from '../selectors';
import { makeGetPermissionsOrderManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  checkPermissionModifyOrderType,
  checkPermissionAddBundle,
  checkPermissionAddPackage,
  checkPermissionAddPriceOffer,
  checkPermissionDeleteBundle,
  checkPermissionDeletePackage,
  checkPermissionDeletePriceOffer,
} from '../CheckPermission';
import RouteNames from '../../App/RouteNames';
import {
  getCcpDateTimeConfig,
  getCcpPropertiesConfig,
  getBundleByBundleId,
  getDiscountOfferById,
} from '../../App/actions';
import OrderProvisioningAttributesForm from '../../../components/Orders/OrderProvisioningAttributesForm';
import { getUserId } from '../../../localStorages';
import {
  convertbuildOrderProvisioningAttributesPayload,
  convertbuildOrderProvisioningAttributesNewOrder,
  convertbuildOrderProvisioningAttributesWithDefaultValue,
  convertSubscriptionDataEdit,
  convertLineFromDiscountOffers,
  initServiceWithSelectBundle,
  initServiceFromPriceOffers,
} from '../utilities';
import ModalMultipleSelectDisscountOffer from '../../../components/common/ModalMultipleSelectDisscountOffer';
import { AttributesForm } from '../../../components/Orders';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';

let lastStep = 0;

const listStepsWithProvisioning = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.manageAndOverride',
  },
  {
    step: 3,
    label: 'label.provisioningDataAndCreateOrder',
  },
];

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.manageCreateOrder',
  },
];

class NewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: { id: props.location.state ? props.location.state.accountId : '' },
      orderDetail: this.initOrderDetail(props),
      subscriptionMessage: '',
      groupServices: [],
      defaultServiceFromAPI: [],
      isOpenModal: false,
      isModify: false,
      isReadOnly: true,
      ccpTime: null,
      isOpenModalSubscription: false,
      isMultiSubscriptionEnabled: false,
      subscriptionData: [],
      subscriptionName: null,
      provisioningEnabled: false,
      activeTab: {},
      buildOrderProvisioningAttributesList: null,
      oldStageSelected: 0,
      isPartialFulfillmentAllowed: null,
      customAttributesByObjectType: [],
    };
    this.services = [];
    this.initServiceUnits = {};
  }

  componentDidMount() {
    this.onGetCcpDateTimeConfig();
    this.doGetCcpPropertiesConfig();
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(this.initServiceUnits) !== JSON.stringify(nextProps.subscriptionAssets)) {
      this.initServiceUnits = nextProps.subscriptionAssets;
      this.getServiceUnitsBySubscriptionId(nextProps.subscriptionAssets);
    }
  };

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState({
        customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
      });
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState({
            customAttributesByObjectType: (data && data.attributes) || [],
          });
        }
      }, 'ORDER');
    }
  };

  doInitFromAccountScreen = () => {
    const {
      location: { state },
      getSubscriptionAssets,
    } = this.props;
    const {
      selectItem: { id },
    } = this.state;
    if (state && state.accountId && id) {
      this.initOrderDetailByAccountId([state.data || {}]);
      getSubscriptionAssets({ id: state?.data?.id || null, status: state?.data?.status || null });
      this.setState({ stage: 1, oldStageSelected: 1 });
    }
  };

  doGetCcpPropertiesConfig = () => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
    }
  };

  doInitPropertiesConfig = ccpPropertyList => {
    const multiSubscriptionEnabled = ccpPropertyList.find(val => val.property === 'multiSubscriptionEnabled');
    const provisioningEnabled = ccpPropertyList.find(val => val.property === 'provisioningEnabled');
    const partialProvisioningEnabled = ccpPropertyList.find(val => val.property === 'partialProvisioningEnabled');

    this.setState(
      {
        isMultiSubscriptionEnabled: multiSubscriptionEnabled && multiSubscriptionEnabled?.value === 'true',
        provisioningEnabled: provisioningEnabled && provisioningEnabled?.value === 'true',
        isPartialFulfillmentAllowed: partialProvisioningEnabled && partialProvisioningEnabled?.value === 'true',
      },
      () => this.doInitFromAccountScreen()
    );
  };

  onGetCcpDateTimeConfig = () => {
    const { getCcpDateTimeConfig } = this.props;
    const { ccpTime } = this.state;
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          this.setState({ ccpTime: data ? data.ccpTime : null });
        }
      });
    }
  };

  onToggle = () => {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
    }));
  };

  initOrderDetail = props => {
    return {
      type: orderEnum.type.modify,
      status: orderEnum.status.create,
      reason: '',
      accountId: props.location.state ? props.location.state.accountId : '',
      userId: getUserId() || 'TestUserId',
      isPartialFulfillmentAllowed: false,
      billingOnlyFlag: false,
      provisioningOnlyFlag: false,
      billOrderCharges: false,
      effectiveDate: moment(new Date()).format('YYYY-MM-DD'),
      submittedDate: moment(new Date()).format('YYYY-MM-DD'),
      initialTerm: '1',
      initialTermUnit: orderEnum.initialTermUnit.months,
      renewalTerm: '1',
      renewalTermUnit: orderEnum.renewalTermUnit.months,
      services: [],
    };
  };

  onClickRow = () => {};

  onSelectNext = rowItem => {
    const { orderDetail } = this.state;
    orderDetail.accountId = rowItem.id;
    this.setState(
      {
        selectItem: rowItem,
        orderDetail,
      },
      () => {
        this.onPressNextButton();
        this.doGetCustomAttributesByObjectType();
      }
    );
  };

  selectAccount = () => {
    const { selectItem } = this.state;
    if (selectItem && selectItem.id) {
      this.getSubscriptionByAccountId(selectItem.id);
    }
  };

  getSubscriptionByAccountId = id => {
    const { stage, isMultiSubscriptionEnabled } = this.state;
    const { getSubscriptionByAccountId } = this.props;
    getSubscriptionByAccountId(
      id,
      (success, data) => {
        if (success) {
          const isMulti =
            data &&
            data.length > 1 &&
            isMultiSubscriptionEnabled &&
            data.filter(val => val.status !== 'CLOSED') &&
            data.filter(val => val.status !== 'CLOSED').length > 1;
          if (!isMulti) this.initOrderDetailByAccountId(data);

          this.setState({
            stage: stage + 1,
            isOpenModalSubscription: isMulti,
            subscriptionData: data || [],
            oldStageSelected: stage + 1,
          });
        } else {
          toast(data, { type: toast.TYPE.ERROR });
        }
      },
      true
    );
  };

  getServiceUnitsBySubscriptionId = data => {
    // const { orderDetail, isMultiSubscriptionEnabled } = this.state;
    const { orderDetail } = this.state;
    let subscriptionName = null;
    let services = [];
    const defaultIndexService = [];
    // if (data && data.length > 1 && isMultiSubscriptionEnabled) {
    //   return this.setState({ isOpenModalSubscription: true });
    // }
    if (data.length) {
      services = data.map((item, index) => {
        const newLine = item.lines || [];
        for (let i = 0; i < newLine.length; i++) {
          newLine[i].action = 'MODIFY';
          // newLine[i].action = 'ADD';
        }
        if (item.subscriptionName) subscriptionName = item.subscriptionName;
        return {
          index: index + 1,
          serviceType: item.serviceType,
          provisioningId: item.provisioningId || '',
          action: 'MODIFY',
          // action: 'ADD',
          reason: item.reason || null,
          status: item.status || null,
          bundleId: item.bundleId || null,
          bundleName: item.bundleName || null,
          packageId: item.packageId || null,
          packageName: item.packageName || null,
          subscriptionName: item.subscriptionName || null,
          lines: newLine,
          isDeafultService: true,
        };
      });
    }
    const groupServices = groupBy(services, item => {
      return item.serviceType;
    });

    return this.setState({
      orderDetail: {
        ...orderDetail,
        services,
      },
      defaultServiceFromAPI: services,
      groupServices,
      defaultIndexService,
      subscriptionName,
    });
  };

  initOrderDetailByAccountId = subscription => {
    const { orderDetail, isPartialFulfillmentAllowed } = this.state;
    if (!subscription || (subscription && subscription.length < 1)) return;
    orderDetail.accountId = subscription[0].accountId;
    orderDetail.reason = subscription[0].reason;
    orderDetail.renewalTerm = subscription[0].renewalTerm;
    orderDetail.renewalTermUnit = subscription[0].renewalTermUnit;
    orderDetail.initialTerm = subscription[0].initialTerm;
    orderDetail.initialTermUnit = subscription[0].initialTermUnit;
    orderDetail.trialTerm = subscription[0].trialTerm;
    orderDetail.trialTermUnit = subscription[0].trialTermUnit;
    orderDetail.effectiveDate = subscription[0].effectiveDate;
    orderDetail.subscriptionCategory = subscription[0].category;
    orderDetail.status = subscription[0].status;
    orderDetail.isPartialFulfillmentAllowed = isPartialFulfillmentAllowed || null;
    this.setState({
      orderDetail,
    });
  };

  onChangeFormLine = (groupIndex, serviceIndex, e) => {
    const { orderDetail, defaultServiceFromAPI } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(orderDetail);
    try {
      const indexServiceChange = newData.services.findIndex(
        item => item.index === groupServices[groupIndex][serviceIndex].index
      );
      const lineIndex = newData.services[indexServiceChange].lines.findIndex(item => item.index === e.lineIndex);
      newData.services[indexServiceChange].lines[lineIndex][e.fieldName] = e.value;
      newData.services[indexServiceChange].lines[lineIndex].isModify = true;
      if (
        newData.services &&
        newData.services[indexServiceChange] &&
        !isEmpty(defaultServiceFromAPI[indexServiceChange])
      ) {
        // newData.services[indexServiceChange].lines[lineIndex].action = 'MODIFY';
        newData.services[indexServiceChange].action = 'MODIFY';
      }

      groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      this.setState({
        orderDetail: newData,
        groupServices,
        isModify: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProvisioningId = ({ name, value, item }) => {
    const { orderDetail } = this.state;
    let groupServices = [];
    const newData = cloneDeep(orderDetail);
    for (let i = 0; i < newData.services.length; i++) {
      if (newData.services[i].serviceType === item.serviceType) {
        newData.services[i][name] = value;
      }
    }
    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ orderDetail: newData, groupServices, isModify: true });
  };

  onHandleChangeOrderForm = e => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    // if (e.fieldName === 'isPartialFulfillmentAllowed' && e.value) {
    //   newData.billingOnlyFlag = null;
    //   newData.provisioningOnlyFlag = null;
    //   newData.billOrderCharges = null;
    // }
    if (e.fieldName === 'billingOnlyFlag' && e.value) {
      // newData.isPartialFulfillmentAllowed = null;
      newData.provisioningOnlyFlag = null;
      // newData.billOrderCharges = null;
    }
    if (e.fieldName === 'provisioningOnlyFlag' && e.value) {
      // newData.isPartialFulfillmentAllowed = null;
      newData.billingOnlyFlag = null;
      // newData.billOrderCharges = null;
    }
    newData[e.fieldName] = e.value;
    const groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });

    this.setState({
      orderDetail: newData,
      groupServices,
    });
  };

  onPressBackButton = () => {
    const { history } = this.props;
    history.push(RouteNames.searchAndList.path);
  };

  onPressCancelButton = () => {
    this.setState({
      stage: 0,
      oldStageSelected: 0,
    });
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId || null,
      discountOfferName: component.discountOfferName || null,
    };
  };

  addLine = (groupIndex, serviceIndex, bundle) => {
    const { orderDetail, groupServices } = this.state;
    try {
      groupServices[groupIndex][serviceIndex].lines = bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          orderDetail.services.push(service);
          return true;
        });
      });
      this.setState({
        orderDetail,
        groupServices,
        isModify: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  doInitPackageItem = () => {
    const { orderDetail } = this.state;
    const { getBundleByBundleId, getDiscountOfferById } = this.props;
    const newData = cloneDeep(orderDetail);
    const { services } = newData;
    const listBundleId = services.filter(val => !!val.bundleId && val.isPackage).map(item => item.bundleId) || [];
    const listDiscountId =
      services.filter(val => !!val.discountOfferId && val.isPackage).map(item => item.discountOfferId) || [];
    const newDataWithBundleId = [];
    const newDataWithDiscountId = [];
    if (!isEmpty(listBundleId)) {
      listBundleId.map(item =>
        getBundleByBundleId(item, ({ success, data }) => {
          if (success) newDataWithBundleId.push(data.components.map(cp => ({ ...cp, bundleId: item })));
          if (newDataWithBundleId.length === listBundleId.length && success) {
            newDataWithBundleId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(sr => sr.bundleId === val.bundleId && sr.isPackage);
                  if (!services[indexService].lines) services[indexService].lines = [];
                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });
            newData.services = services;
            const groupServices = groupBy(newData.services, item => {
              return item.serviceType;
            });
            this.setState({
              orderDetail: newData,
              mode: constants.ModalMode.none,
              groupServices,
            });
          }
        })
      );
    }
    if (!isEmpty(listDiscountId)) {
      listDiscountId.map(item =>
        getDiscountOfferById(item, ({ success, data }) => {
          if (success) newDataWithDiscountId.push([{ discountOfferName: data.name || null, discountOfferId: item }]);
          if (newDataWithDiscountId.length === listDiscountId.length && success) {
            newDataWithDiscountId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(
                    sr => sr.discountOfferId === val.discountOfferId && sr.isPackage
                  );
                  if (!services[indexService].lines) services[indexService].lines = [];

                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });

            newData.services = services;
            const groupServices = groupBy(newData.services, item => {
              return item.serviceType;
            });
            this.setState({
              orderDetail: newData,
              mode: constants.ModalMode.none,
              groupServices,
            });
          }
        })
      );
    }
  };

  onSelectService = ({ services, mode, ids, rows, index, name }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    if (!services || (services && services.length === 0) || !ids || (ids && ids.length === 0)) {
      // newData[index].services = [];
      // newData[index].groupServices = [];

      // this.setState({
      //   subscriptionsData: newData,
      // });
      return;
    }
    const service = [];
    const serviceIndex = 0;
    // newData[index].services = [];
    switch (mode) {
      case constants.ModalMode.purchasePackage: {
        const listExistService = newData.services.filter(val => !val.isPackage || val.subscriptionName !== name);
        let newServices = [];
        newServices = [
          ...listExistService,
          ...services[0].map((item, index) => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === item?.serviceType
            );
            return {
              index: getLastIndex({ data: listExistService }) + index,
              packageId: ids[0],
              bundleId: item.bundleId,
              bundleName: item.bundleName,
              serviceType: item.serviceType,
              discountOfferId: item.discountOfferId || null,
              action: 'ADD',
              status: 'CREATED',
              // eslint-disable-next-line no-nested-ternary
              packageName: item.name ? item.name : rows && rows.length ? rows[0].name : '',
              subscriptionName: name,
              isPackage: true,
              isNew: true,
              provisioningId: existService ? existService.provisioningId : null,
            };
          }),
        ];
        newData.services = newServices;
        const groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState(
          {
            orderDetail: newData,
            mode: constants.ModalMode.none,
            groupServices,
          },
          () => this.doInitPackageItem()
        );
        break;
      }
      case constants.ModalMode.purchaseBundle: {
        const listExistService = newData.services.filter(val => !val.isBundle || val.subscriptionName !== name);
        let newServices = [];

        newServices = [
          ...listExistService,
          ...services.map((item, index) => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === item?.components[0]?.serviceType
            );
            return {
              subscriptionName: name,
              provisioningId: existService ? existService.provisioningId : null,
              isBundle: true,
              isNew: true,
              ...initServiceWithSelectBundle(item, getLastIndex({ data: listExistService }) + index - 1),
            };
          }),
        ];
        newData.services = newServices;
        const groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState({
          orderDetail: newData,
          mode: constants.ModalMode.none,
          groupServices,
        });
        break;
      }
      case constants.ModalMode.purchaseAlaCarte: {
        const listExistService = newData.services.filter(val => !val.isPriceOffer || val.subscriptionName !== name);

        let newServices = [];
        // const existService = newData.services.find(
        //   val => val.subscriptionName === name && val.serviceType === services[0]?.serviceType
        // );
        const service = groupBy(services, item => {
          return item.serviceType;
        });
        let serviceIndex = 0;

        newServices = [
          ...listExistService,
          ...map(service, item => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === services[0]?.serviceType
            );
            serviceIndex += 1;
            return {
              provisioningId: existService ? existService.provisioningId : null,
              subscriptionName: name,
              isPriceOffer: true,
              isNew: true,
              ...initServiceFromPriceOffers(item, getLastIndex({ data: listExistService }) + serviceIndex - 1),
            };
          }),
          // {
          //   ...initServiceFromPriceOffers(services, getLastIndex({ data: listExistService })),
          //   provisioningId: existService ? existService.provisioningId : null,
          //   subscriptionName: name,
          //   isPriceOffer: true,
          // },
        ];
        newData.services = newServices;
        const groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });

        this.setState({
          orderDetail: newData,
          mode: constants.ModalMode.none,
          groupServices,
        });
        break;
      }
      default:
        break;
    }
  };

  onPressNextButton = () => {
    const { selectItem, provisioningEnabled, stage } = this.state;
    if (selectItem.id && stage === 0) {
      this.selectAccount();
    }
    if (provisioningEnabled && stage === 1) {
      this.doBuildOrderProvisioningAttributes();
      this.setState({ stage: stage + 1, oldStageSelected: stage + 1 });
    }
  };

  onPressCreateButton = () => {
    const {
      orderDetail,
      groupServices,
      isReadOnly,
      isMultiSubscriptionEnabled,
      buildOrderProvisioningAttributesList,
      customAttributesByObjectType,
    } = this.state;
    const newOrderDetail = cloneDeep(orderDetail);
    newOrderDetail.services = [];
    newOrderDetail.status = 'CREATED';
    newOrderDetail.isPartialFulfillmentAllowed = newOrderDetail.isPartialFulfillmentAllowed || null;
    newOrderDetail.billingOnlyFlag = newOrderDetail.billingOnlyFlag || null;
    newOrderDetail.provisioningOnlyFlag = newOrderDetail.provisioningOnlyFlag || null;
    newOrderDetail.billOrderCharges = newOrderDetail.billOrderCharges || null;
    newOrderDetail.provisioningAttributes = convertbuildOrderProvisioningAttributesNewOrder(
      buildOrderProvisioningAttributesList
    );
    const subscriptionList = [];
    const subscriptionCategory = orderDetail.subscriptionCategory;
    delete newOrderDetail.effectiveDate;
    delete newOrderDetail.subscriptionCategory;
    map(groupServices, serviceGroup => {
      return serviceGroup.map(service => {
        const { bundleName, packageName, isOverrideLines, ...newService } = cloneDeep(service);
        delete newService.bundleName;
        delete newService.status;
        delete newService.isDiscount;
        delete newService.isPackage;
        delete newService.isBundle;
        delete newService.isPriceOffer;
        delete newService.discountOfferId;
        delete newService.isNew;
        newService.lines = newService.lines
          ? newService.lines.filter(line => line.action !== 'MODIFY' || line.isModify)
          : null;

        if (newService.lines && newService.lines.length) {
          for (let i = 0; i < newService.lines.length; i++) {
            newService.lines[i].status = 'CREATED';
            newService.lines[i].priceUnitId = newService.lines[i].id;
            delete newService.lines[i].priceOfferName;
            delete newService.lines[i].discountOfferName;
            delete newService.lines[i].isModify;
            if (newService.lines[i] && newService.lines[i].discountOfferId) {
              newService.lines[i].discAmountOverride = newService.lines[i].priceOverride;
              newService.lines[i].discPercentOverride = newService.lines[i].discountPercent;
              newService.lines[i].discountUnitId = newService.lines[i].id;
              delete newService.lines[i].priceOverride;
              delete newService.lines[i].discountPercent;
              delete newService.lines[i].priceUnitId;
            }
            delete newService.lines[i].id;
          }
          // if (!isOverrideLines) newService.lines = null;
        } else {
          newService.lines = null;
        }

        if (newService.isDeafultService) {
          if (!isReadOnly || newService?.lines?.length) {
            delete newService.isDeafultService;
            if (newService?.lines?.length) {
              newOrderDetail.services.push(newService);
              if (newService.subscriptionName) {
                subscriptionList.push({
                  subscriptionName: newService.subscriptionName,
                  index: subscriptionList.length + 1,
                  subscriptionCategory,
                  ...convertSubscriptionDataEdit(newOrderDetail),
                });
              }
            } else if (newService.isSendService) {
              delete newService.isSendService;
              delete newService.isDeafultService;
              newOrderDetail.services.push(newService);
              if (newService.subscriptionName) {
                subscriptionList.push({
                  subscriptionName: newService.subscriptionName,
                  index: subscriptionList.length + 1,
                  subscriptionCategory,
                  ...convertSubscriptionDataEdit(newOrderDetail),
                });
              }
            }
          } else if (newService.isSendService) {
            delete newService.isSendService;
            delete newService.isDeafultService;
            newOrderDetail.services.push(newService);
            if (newService.subscriptionName) {
              subscriptionList.push({
                subscriptionName: newService.subscriptionName,
                index: subscriptionList.length + 1,
                subscriptionCategory,
                ...convertSubscriptionDataEdit(newOrderDetail),
              });
            }
          }
        } else if (newService?.lines?.length || newService.isSendService) {
          newOrderDetail.services.push(newService);
          if (newService.subscriptionName) {
            subscriptionList.push({
              subscriptionName: newService.subscriptionName,
              index: subscriptionList.length + 1,
              subscriptionCategory,
              ...convertSubscriptionDataEdit(newOrderDetail),
            });
          }
        }
        return true;
      });
    });

    const { docreateOrder } = this.props;

    const temp = [];
    if (customAttributesByObjectType && customAttributesByObjectType.length)
      customAttributesByObjectType.map(item => {
        if (item.value && item.isModify) {
          temp.push({ name: item.name, value: item.value });
        }
      });
    docreateOrder(
      {
        ...newOrderDetail,
        orderCustomAttributes: temp && temp.length ? temp : null,
        subscriptions:
          isMultiSubscriptionEnabled && subscriptionList && subscriptionList.length ? subscriptionList[0] : null,
      },
      () => {}
    );
  };

  onSubmitSuspendResume = ({ item, type }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    const indexService = orderDetail.services.findIndex(or => or.index === item.index);
    if (indexService || indexService === 0) {
      newData.services[indexService].action = type;
      newData.services[indexService].isSendService = type !== 'MODIFY';
    }
    const groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({
      orderDetail: newData,
      groupServices,
    });
  };

  onRemoveItem = ({ itemRemove }) => {
    const { orderDetail, defaultServiceFromAPI, ccpTime } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(orderDetail);

    let isPackage = false;
    let isBundle = false;
    if (itemRemove.packageId) {
      isPackage = true;
    } else if (itemRemove.bundleId) {
      isBundle = true;
    }
    if (itemRemove.isNew) {
      const indexItemRemove = newData.services.findIndex(item => item.index === itemRemove.index);
      newData.services.splice(indexItemRemove, 1);
    } else {
      if (isBundle) {
        const indexItemRemove = newData.services.findIndex(item => item.index === itemRemove.index);
        if (itemRemove.isDeafultService) {
          if (newData.services[indexItemRemove].lines)
            // delete newData.services[indexItemRemove].lines;
            newData.services[indexItemRemove].lines.forEach((val, i) => {
              newData.services[indexItemRemove].lines[i].action = 'MODIFY';
            });
          newData.services[indexItemRemove].action = 'CANCEL';
        } else {
          newData.services.splice(indexItemRemove, 1);
        }
      }

      if (isPackage) {
        const groupIndex = newData.services.filter(item => item.index === itemRemove.index).map(item => item.index);
        groupIndex.forEach(item => {
          const indexItemRemove = newData.services.findIndex(v => v.index === item);
          if (itemRemove.isDeafultService) {
            // newData.services[indexItemRemove].action = 'CANCEL';
            newData.services[indexItemRemove].action = 'MODIFY';
            if (newData.services[indexItemRemove].lines)
              // delete newData.services[indexItemRemove].lines;
              newData.services[indexItemRemove].lines.forEach((val, i) => {
                newData.services[indexItemRemove].lines[i].action = 'CANCEL';
              });
          } else {
            newData.services.splice(indexItemRemove, 1);
          }
        });
      }

      if (!isBundle && !isPackage) {
        const indexItemRemove = newData.services.findIndex(item => item.provisioningId === itemRemove.provisioningId);
        if (defaultServiceFromAPI.findIndex(item => item.provisioningId === itemRemove.provisioningId) > -1) {
          newData.services[indexItemRemove].action = 'MODIFY';
          if (newData.services[indexItemRemove].lines) {
            // delete newData.services[indexItemRemove].lines;
          }
          newData.services[indexItemRemove].lines.forEach((val, i) => {
            newData.services[indexItemRemove].lines[i].action = 'CANCEL';
            newData.services[indexItemRemove].lines[i].endDate = ccpTime;
          });
        } else {
          newData.services.splice(indexItemRemove, 1);
        }
      }
    }

    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ orderDetail: newData, groupServices, isOpenModal: false, isModify: true });
  };

  onRemoveLine = ({ itemService, itemLine }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    const indexService = newData.services.findIndex(item => item.index === itemService.index);
    const indexLine = newData.services[indexService].lines.findIndex(
      item => item.priceOfferId === itemLine.priceOfferId
    );
    if (indexService > -1 && indexLine > -1) {
      newData.services[indexService].action = 'MODIFY';
      if (newData.services[indexService].lines[indexLine].status === 'CREATED') {
        newData.services[indexService].lines.splice(indexLine, 1);
      } else {
        newData.services[indexService].lines[indexLine].action = 'CANCEL';
      }
    }
    const groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ orderDetail: newData, groupServices, isModify: true });
  };

  onHandleInitNewLines = ({ item }) => {
    try {
      const { orderDetail } = this.state;
      const newData = cloneDeep(orderDetail);
      const indexService = newData.services.findIndex(val => val.index === item.index);
      newData.services[indexService].isOverrideLines = true;
      const groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      this.setState({ orderDetail: newData, groupServices, isModify: true });
    } catch (error) {
      console.log('error', error);
    }
  };

  onToggleTab = (activeTabName, serviceType) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && serviceType === activeTab.serviceType) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive, serviceType },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true, serviceType } });
    }
  };

  doBuildOrderProvisioningAttributes = () => {
    const { buildOrderProvisioningAttributes } = this.props;
    const { orderDetail } = this.state;
    buildOrderProvisioningAttributes(
      convertbuildOrderProvisioningAttributesPayload(orderDetail, 'CREATED'),
      ({ data }) => {
        this.setState({
          buildOrderProvisioningAttributesList: convertbuildOrderProvisioningAttributesWithDefaultValue(data) || null,
        });
      }
    );
  };

  onHandleChangeProvisioningAttributes = ({ value, indexService, indexAttribute, indexItem }) => {
    const { buildOrderProvisioningAttributesList } = this.state;
    try {
      const newAttributes = cloneDeep(buildOrderProvisioningAttributesList);
      newAttributes[indexService].configProvisioningAttributes[indexAttribute].attributesList[
        indexItem
      ].attributeValue = value;

      this.setState({ buildOrderProvisioningAttributesList: newAttributes });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleModalDiscounts = () => {
    this.setState({ isOpenModalDisscountOffers: true });
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  renderContent = ({
    modeAddBundle,
    modeAddPackage,
    modeAddPriceOffer,
    modeDeleteBundle,
    modeDeletePackage,
    modeDeletePriceOffer,
  }) => {
    const {
      stage,
      selectItem,
      orderDetail,
      subscriptionMessage,
      groupServices,
      isOpenModal,
      isReadOnly,
      isMultiSubscriptionEnabled,
      activeTab,
      buildOrderProvisioningAttributesList,
      subscriptionName,
      customAttributesByObjectType,
    } = this.state;

    switch (stage) {
      case 0:
        return (
          <AccountTable
            type="modify-order"
            subscriptionStatus="ACTIVE"
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isNoPageTitle
          />
        );

      case 1:
        return (
          <>
            <ModifyService
              id={selectItem.id}
              modeAddBundle={modeAddBundle}
              modeAddPackage={modeAddPackage}
              modeAddPriceOffer={modeAddPriceOffer}
              modeDeleteBundle={modeDeleteBundle}
              modeDeletePackage={modeDeletePackage}
              modeDeletePriceOffer={modeDeletePriceOffer}
              orderDetail={orderDetail}
              groupServices={groupServices}
              subscriptionMessage={subscriptionMessage}
              onChangeFormLine={this.onChangeFormLine}
              onChangeProvisioningId={this.onChangeProvisioningId}
              onSelectService={this.onSelectService}
              addLine={this.addLine}
              isOpen={isOpenModal}
              onRemoveItem={this.onRemoveItem}
              onRemoveLine={this.onRemoveLine}
              onToggle={this.onToggle}
              onChangeModifyService={this.onChangeModifyService}
              isReadOnly={isReadOnly}
              onHandleInitNewLines={this.onHandleInitNewLines}
              isSupportPOName
              isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
              isReadMultiSubscriptionEnabled
              onSubmitSuspendResume={this.onSubmitSuspendResume}
              onToggleModalDiscounts={this.onToggleModalDiscounts}
              subscriptionName={subscriptionName}
            />
            <FormWithTableItem title="label.customerAttributes">
              <div className="col-md-12 p-0">
                <AttributesForm
                  customAttributesByObjectType={customAttributesByObjectType}
                  onChange={this.onHandleChangeOrderAttribute}
                />
              </div>
            </FormWithTableItem>
          </>
        );

      case 2:
        return (
          <>
            <div className="col-md-12 row justify-content-center mt-3 mb-2">
              {orderListRadioButtons.map(item => (
                <GenericInput
                  key={item.name}
                  disabled={item.disabled}
                  value={orderDetail[item.name] || null}
                  onChange={({ name, value }) => this.onHandleChangeOrderForm({ fieldName: name, value })}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  {...item}
                />
              ))}
            </div>
            {buildOrderProvisioningAttributesList && (
              <OrderProvisioningAttributesForm
                data={buildOrderProvisioningAttributesList}
                activeTab={activeTab}
                onChange={e => this.onHandleChangeProvisioningAttributes({ ...e })}
                onToggleTab={this.onToggleTab}
              />
            )}
          </>
        );

      default:
        return <AccountTable onClickRow={this.onClickRow} selectItem={selectItem} onSelectNext={this.onSelectNext} />;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    if (step < stage + 1 || step <= oldStageSelected + 1) {
      this.setState({ stage: step - 1 });
    }
  };

  onChangeModifyService = () => {
    const { isReadOnly } = this.state;
    this.setState({ isReadOnly: !isReadOnly });
  };

  onSelectMultipleDiscountOffers = ({ id, row, index }) => {
    const { orderDetail, subscriptionName } = this.state;
    const newData = cloneDeep(orderDetail);
    let { services } = newData;
    if (row && row.length) {
      if (!services || !services.length) services = [];
      let listDefaultLines = [];
      services = services.filter(val => !val.isDiscount);
      services.forEach(val => {
        const { lines } = val;
        if (lines && lines.length) {
          listDefaultLines = [...listDefaultLines, ...lines.filter(line => !!line.discountOfferId)];
        }
      });

      const newRows = row.filter(r => !listDefaultLines.find(ln => ln.discountOfferId === r.id));
      const newServices = [];
      if (newRows && newRows.length) {
        const newRowsGroupBy = groupBy(newRows, item => {
          return item.serviceType;
        });
        map(newRowsGroupBy, (value, key) => {
          const existService = newData.services.find(val => val.serviceType === key);
          const newService = {
            index: newServices.length + getLastIndex({ data: services }),
            serviceType: key,
            provisioningId: existService ? existService.provisioningId : null,
            action: 'ADD',
            reason: null,
            status: 'CREATED',
            bundleId: null,
            packageId: null,
            lines: [],
            isDiscount: true,
            subscriptionName,
            isNew: true,
          };
          newService.lines = convertLineFromDiscountOffers(value, getLastIndex({ data: value }));
          newServices.push(newService);
        });
      }
      if (newServices && newServices.length) {
        services = [...services, ...newServices];
      }

      newData.services = services;
      // subscriptionsData[index].stage += subscriptionsData[index].stage;
      // this.doBuildOrderProvisioningAttributes(index);
      const groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      this.setState({
        orderDetail: newData,
        groupServices,
        isOpenModalDisscountOffers: false,
      });
    }
  };

  render() {
    const {
      stage,
      isOpenModalSubscription,
      subscriptionData,
      provisioningEnabled,
      oldStageSelected,
      isOpenModalDisscountOffers,
    } = this.state;
    const { permissionOrder, t, getSubscriptionAssets } = this.props;
    let modeModifyOrderType = 0;
    let modeAddBundle = 0;
    let modeAddPackage = 0;
    let modeAddPriceOffer = 0;
    let modeDeleteBundle = 0;
    let modeDeletePackage = 0;
    let modeDeletePriceOffer = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeModifyOrderType = checkPermissionModifyOrderType({ listPermission });
      modeAddBundle = checkPermissionAddBundle({ listPermission });
      modeAddPackage = checkPermissionAddPackage({ listPermission });
      modeAddPriceOffer = checkPermissionAddPriceOffer({ listPermission });
      modeDeleteBundle = checkPermissionDeleteBundle({ listPermission });
      modeDeletePackage = checkPermissionDeletePackage({ listPermission });
      modeDeletePriceOffer = checkPermissionDeletePriceOffer({ listPermission });
    }
    if (modeModifyOrderType === 0) {
      return '';
    }
    const {
      location: { state },
    } = this.props;

    lastStep = provisioningEnabled ? 2 : 1;

    return (
      <div className="mb-4">
        <div className="col-md-12 row p-0">
          <div className="col-sm-6">
            {!state && (
              <button type="button" className="button x-small" onClick={() => this.onPressBackButton(true)}>
                {t('label.back')}
              </button>
            )}
            {state && state.backLink && (
              <Link to={state.backLink} type="button" className="button x-small">
                {t('label.back')}
              </Link>
            )}
          </div>
          <div className="col-sm-6 pr-0">
            <Breadcrumb
              items={[
                {
                  name: t('orderPage:sidebar.modifyOrder'),
                  url: RouteNames.modifyOrder.path,
                },
              ]}
            />
          </div>
        </div>

        <div className="col-md-12 row text-center pb-3 mb-2">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              listSteps={provisioningEnabled ? listStepsWithProvisioning : listSteps}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              oldSelected={oldStageSelected}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3 p-m">
            <button
              type="button"
              className="button button-border x-small gray box-shadow-btn"
              onClick={this.onPressCancelButton}
            >
              {t('label.cancel')}
            </button>
            {stage < lastStep ? (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressNextButton}
              >
                {t('label.next')}
              </button>
            ) : (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressCreateButton}
                // disabled={modeModifyOrderType === 1 || !isModify || isReadOnly}
                disabled={modeModifyOrderType === 1}
              >
                {t('label.create')}
              </button>
            )}
          </div>
        </div>

        {this.renderContent({
          modeAddBundle,
          modeAddPackage,
          modeAddPriceOffer,
          modeDeleteBundle,
          modeDeletePackage,
          modeDeletePriceOffer,
        })}

        <ModalSelectSubscription
          onToggle={() => {
            this.onPressBackButton();
            this.initServiceUnits = {};
          }}
          isOpen={isOpenModalSubscription}
          data={subscriptionData}
          onSelect={data => {
            this.setState({ isOpenModalSubscription: false });
            this.initOrderDetailByAccountId([data]);
            getSubscriptionAssets({ id: data.id || null, status: data.status || null });
          }}
        />
        <ModalMultipleSelectDisscountOffer
          isOpen={isOpenModalDisscountOffers}
          // isMultipleOptionInject
          // optionsInject={{
          //   serviceType: serviceTypeOptions,
          // }}
          // addFirstParamsField={addFirstParamsField}
          onCancel={() => {
            this.setState({ isOpenModalDisscountOffers: false });
            // this.doBuildOrderProvisioningAttributes(index);
          }}
          onSelect={(id, row) => this.onSelectMultipleDiscountOffers({ id, row })}
          // defaultFilter={defaultFilter}
        />
      </div>
    );
  }
}

NewOrder.propTypes = {
  getSubscriptionByAccountId: PropTypes.func.isRequired,
  docreateOrder: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  subscriptionAssets: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
};

NewOrder.defaultProps = {
  subscriptionAssets: [],
  location: {},
  permissionOrder: {},
};

const mapStateToProps = createStructuredSelector({
  serviceUnits: makeGetServiceUnits(),
  subscriptionAssets: makeGetSubscriptionAssets(),
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
});

const NewOrderT = connect(mapStateToProps, {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  docreateOrder: createOrder,
  getServiceUnitsBySubscriptionId,
  getCcpDateTimeConfig,
  getCcpPropertiesConfig,
  buildOrderProvisioningAttributes,
  getBundleByBundleId,
  getDiscountOfferById,
  getCustomAttributesByObjectType,
})(NewOrder);

export default withTranslation('common')(NewOrderT);
