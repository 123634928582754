import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import BundleItem from './BundleItem';
import BundleItemAdd from './BundleItem/BundleItemAdd';
import BundleItemEdit from './BundleItem/BundleItemEdit';
import PackageItem from './PackageItem';
import PackageItemAdd from './PackageItem/PackageItemAdd';
import PackageItemEdit from './PackageItem/PackageItemEdit';
import DependencyItem from './DependencyItem';
import DependencyItemAdd from './DependencyItem/DependencyItemAdd';
import DependencyItemEdit from './DependencyItem/DependencyItemEdit';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class BundleManagement extends PureComponent {
  render() {
    const { permissionsBundleManagement } = this.props;
    if (
      !isHasPermissionWithMenu({ permissions: permissionsBundleManagement, listKeys: permissionList.bundleManagement })
    )
      return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.bundleItem.path} component={BundleItem} />
              <Route exact path={RouteNames.bundleItemAdd.path} component={BundleItemAdd} />
              <Route exact path={RouteNames.bundleItemEdit.path} component={BundleItemEdit} />
              <Route exact path={RouteNames.packageItem.path} component={PackageItem} />
              <Route exact path={RouteNames.packageItemAdd.path} component={PackageItemAdd} />
              <Route exact path={RouteNames.packageItemEdit.path} component={PackageItemEdit} />
              <Route exact path={RouteNames.dependencyItem.path} component={DependencyItem} />
              <Route exact path={RouteNames.dependencyItemAdd.path} component={DependencyItemAdd} />
              <Route exact path={RouteNames.dependencyItemEdit.path} component={DependencyItemEdit} />
              <Route exact path="" component={BundleItem} />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const permissionsBundleManagement = store.get('auth').toJS().permissionsApp.pricingHubModules;

  return {
    permissionsBundleManagement: permissionsBundleManagement.length
      ? permissionsBundleManagement.filter(a => a.moduleName === 'BUNDLE_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  push,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'bundleReducer', reducer });
const withSaga = injectSaga({ key: 'bundleSaga', saga });

export default compose(withReducer, withSaga, withConnect)(BundleManagement);
