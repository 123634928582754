import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

export const PaginationData = ({ totalCount, pageSize, handlePageChange, pageNumber }) => {
  return (
    <nav className="pagination-data" aria-label="pagination">
      <ReactPaginate
        previousLabel={(
          <svg transform="rotate(90)" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
            <path
              fill="#8891A5"
              fillRule="nonzero"
              d="M5 3.586L8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586z"
            />
          </svg>
)}
        nextLabel={(
          <svg transform="rotate(-90)" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
            <path
              fill="#8891A5"
              fillRule="nonzero"
              d="M5 3.586L8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586z"
            />
          </svg>
)}
        breakLabel="..."
        pageClassName="pagination__item page-item-data"
        previousClassName="pagination__item page-item-data prev-page"
        previousLinkClassName="page-link"
        breakClassName="pagination__item page-item-data"
        nextClassName="pagination__item page-item-data next-page"
        nextLinkClassName="page-link"
        breakLinkClassName="page-link"
        pageLinkClassName="pagination__link page-link"
        pageCount={Math.ceil(totalCount / Math.max(pageSize, 1))}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        forcePage={pageNumber}
        initialPage={0}
      />
    </nav>
  );
};
PaginationData.propTypes = {
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  handlePageChange: PropTypes.func,
  pageNumber: PropTypes.number,
};
PaginationData.defaultProps = {
  pageSize: 20,
  totalCount: 0,
  handlePageChange: () => {},
  pageNumber: 0,
};
export default PaginationData;
