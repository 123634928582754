export const GET_FUTURE_CYCLE_CFG_BILLING = 'app/customers/GET_FUTURE_CYCLE_CFG_BILLING';
export const GET_FUTURE_CYCLE_CFG_SUCCESS_BILLING = 'app/customers/GET_FUTURE_CYCLE_CFG_SUCCESS_BILLING';
export const MODIFY_FUTURE_CYCLE_CFG_BILLING = 'app/customers/MODIFY_FUTURE_CYCLE_CFG_BILLING';
export const CREATE_FUTURE_CYCLE_CFG_BILLING = 'app/customers/CREATE_FUTURE_CYCLE_CFG_BILLING';

// Advance Billing
export const GET_FUTURE_ADVANCE_CFG_BILLING = 'app/customers/GET_FUTURE_ADVANCE_CFG_BILLING';
export const GET_FUTURE_ADVANCE_CFG_SUCCESS_BILLING = 'app/customers/GET_FUTURE_ADVANCE_CFG_SUCCESS_BILLING';
export const MODIFY_FUTURE_ADVANCE_CFG_BILLING = 'app/customers/MODIFY_FUTURE_ADVANCE_CFG_BILLING';
export const CREATE_FUTURE_ADVANCE_CFG_BILLING = 'app/customers/CREATE_FUTURE_ADVANCE_CFG_BILLING';

// Pending Billing
export const GET_PENDING_BILL_TRIGGER = 'app/customers/GET_PENDING_BILL_TRIGGER';
export const GET_PENDING_BILL_TRIGGER_SUCCESS = 'app/customers/GET_PENDING_BILL_TRIGGER_SUCCESS';
export const MODIFY_PENDING_BILL_TRIGGER = 'app/customers/MODIFY_PENDING_BILL_TRIGGER';
export const CREATE_PENDING_BILL_TRIGGER = 'app/customers/CREATE_PENDING_BILL_TRIGGER';

export const SEARCH_EMPLOYEE_COSTING = 'app/customers/SEARCH_EMPLOYEE_COSTING';
export const SEARCH_EMPLOYEE_COSTING_SUCCESS = 'app/customers/SEARCH_EMPLOYEE_COSTING_SUCCESS';
export const SEARCH_ALL_EMPLOYEE_COSTING = 'app/customers/SEARCH_ALL_EMPLOYEE_COSTING';
export const SEARCH_ALL_EMPLOYEE_COSTING_SUCCESS = 'app/customers/SEARCH_ALL_EMPLOYEE_COSTING_SUCCESS';
export const CREATE_EMPLOYEE_COSTING = 'app/customers/CREATE_EMPLOYEE_COSTING';
export const MODIFY_EMPLOYEE_COSTING = 'app/customers/MODIFY_EMPLOYEE_COSTING';

export const SEARCH_CLIENT_PROJECT = 'app/customers/SEARCH_CLIENT_PROJECT';
export const SEARCH_CLIENT_PROJECT_SUCCESS = 'app/customers/SEARCH_CLIENT_PROJECT_SUCCESS';
export const SEARCH_ALL_CLIENT_PROJECT = 'app/customers/SEARCH_ALL_CLIENT_PROJECT';
export const SEARCH_ALL_CLIENT_PROJECT_SUCCESS = 'app/customers/SEARCH_ALL_CLIENT_PROJECT_SUCCESS';
// export const GET_CLOUD_FILE_DATA_BY_NAME = 'GET_CLOUD_FILE_DATA_BY_NAME';
export const MODIFY_PROJECT_CLIENT = 'MODIFY_PROJECT_CLIENT';
export const CREATE_PROJECT_CLIENT = 'CREATE_PROJECT_CLIENT';
export const GET_PROJECT_CLIENT_BY_ID = 'GET_PROJECT_CLIENT_BY_ID';
export const SEARCH_SAVED_MANUAL_INVOICE = 'SEARCH_SAVED_MANUAL_INVOICE';
export const GET_PENDING_MANUAL_INVOICE_BILLS = 'GET_PENDING_MANUAL_INVOICE_BILLS';
export const PROCESS_SAVED_MANUAL_INVOICE = 'PROCESS_SAVED_MANUAL_INVOICE';

export const MODIFY_EXPENSES_CONFIG = 'MODIFY_EXPENSES_CONFIG';
export const CREATE_EXPENSES_CONFIG = 'CREATE_EXPENSES_CONFIG';
export const GET_EXPENSES_CONFIG = 'GET_EXPENSES_CONFIG';

export const CREATE_EXPENSE_DATA = 'CREATE_EXPENSE_DATA';
export const MODIFY_EXPENSE_DATA = 'MODIFY_EXPENSE_DATA';
export const SEARCH_EXPENSE_DATA = 'SEARCH_EXPENSE_DATA';

export const CREATE_EMPLOYEE_BONUS = 'CREATE_EMPLOYEE_BONUS';
export const MODIFY_EMPLOYEE_BONUS = 'MODIFY_EMPLOYEE_BONUS';
export const SEARCH_EMPLOYEE_BONUS = 'SEARCH_EMPLOYEE_BONUS';