import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'serviceType', 'status']);
  const modifyConfigUsageType = `modifyConfigUsageType(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigUsageType} {
      id
    }
  }
`;
};
