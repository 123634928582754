export default {
  createAccount: [
    {
      id: 1,
      title: 'Create Account Info',
      state: 'accountInfo',
    },
    {
      id: 2,
      title: 'Create Contact',
      state: 'contact',
    },
    {
      id: 3,
      title: 'Create Address',
      state: 'address',
    },
    {
      id: 4,
      title: 'Create Payment Profile',
      state: 'paymentProfile',
    },
    {
      id: 5,
      title: 'Create Billing Profile',
      state: 'billingProfile',
    },
  ],
  collections: [
    {
      id: 1,
      title: 'common:label.collectionActions',
      state: 'actions',
    },
    {
      id: 2,
      title: 'common:label.collectionSchedule',
      state: 'schedule',
    },
    {
      id: 3,
      title: 'common:label.collectionAgents',
      state: 'agents',
    },
  ],
  paymentConfiguration: [
    {
      id: 1,
      title: 'Merchant Account',
      state: 'account',
    },
    {
      id: 2,
      title: 'Payment Types',
      state: 'Types',
    },
    {
      id: 3,
      title: 'Payment Terms',
      state: 'terms',
    },
  ],
  rolesDetails: [
    {
      id: 1,
      title: 'common:label.selfCareHubModules',
      code: 'selfCareHubModules',
      hubFieldName: 'selfCareHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.selfCarePermissions',
          code: 'selfCareModulePermissions',
          hubName: 'SELF_CARE',
          moduleName: 'SELF_CARE',
          hubFieldName: 'selfCareHubModules',
          moduleFieldName: 'selfCareModulePermissions',
          permissions: [
            'CREATE_USER',
            'PROCESS_ACCOUNT',
            'SEARCH_OFFERS',
            'ADD_CREDIT_CARD',
            'MODIFY_CREDIT_CARD',
            'MODIFY_OFFERS',
            'MODIFY_CONTACT',
            'VIEW_SUBSCRIPTION',
            'VIEW_BALANCES',
            'VIEW_BILLS',
            'VIEW_INVOICE',
            'VIEW_TRANSACTIONS',
            'APPLY_PAYMENT',
            'MODIFY_USER',
            'CREATE_LAYOUT_PREFERENCE',
            'MODIFY_LAYOUT_PREFERENCE',
            'VIEW_LAYOUT_PREFERENCE',
            'CREATE_ADS_DATA',
            'MODIFY_ADS_DATA',
            'VIEW_ADS_DATA',
            'UPGRADE_SUBSCRIPTION',
            'DOWNGRADE_SUBSCRIPTION',
            'DOWNLOAD_INVOICE',
            'VIEW_NOTES',
            'DOWNLOAD_NOTES',
            'CREATE_SELF_CARE_CONFIG',
            'MODIFY_SELF_CARE_CONFIG',
            'GET_SELF_CARE_CONFIG',
            'THIRD_PARTY_SELF_CARE',
          ],
        },
      ],
    },
    {
      id: 2,
      title: 'common:label.customerHubModules',
      code: 'CustomerHubModules',
      hubFieldName: 'customerHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.customerManagementPermissions',
          code: 'customerModulePermissions',
          hubName: 'CUSTOMER_HUB',
          moduleName: 'CUSTOMER_MANAGEMENT',
          hubFieldName: 'customerHubModules',
          moduleFieldName: 'customerModulePermissions',
          permissions: [
            'CREATE_CUSTOMER',
            'MODIFY_CUSTOMER',
            'SUSPEND_CUSTOMER',
            'RESUME_CUSTOMER',
            'CLOSE_CUSTOMER',
            'ADD_ADDRESS',
            'MODIFY_ADDRESS',
            'DELETE_ADDRESS',
            'ADD_CONTACT',
            'MODIFY_CONTACT',
            'DELETE_CONTACT',
            'ADD_PHONE',
            'MODIFY_PHONE',
            'DELETE_PHONE',
            'MODIFY_BILLING_PROFILE',
            'MODIFY_PAYMENT_PROFILE',
            'MODIFY_CREDIT_CARD',
            'ADD_CREDIT_CARD',
            'DELETE_CREDIT_CARD',
            'SEARCH_ACCOUNTS',
            'GET_ACCOUNT',
            'GET_BILLING_PROFILE',
            'GET_PAYMENT_PROFILE',
            'GET_ADDRESS',
            'GET_CONTACT',
            'CREATE_CUSTOM_ATTRIBUTE_CONFIG',
            'MODIFY_CUSTOM_ATTRIBUTE_CONFIG',
            'MODIFY_CUSTOM_ATTRIBUTES',
            'CREATE_CUSTOMER_HIERARCHY',
            'GET_CUSTOM_ATTRIBUTE_CONFIG',
            'GET_CUSTOMER_HIERARCHY',
            'CREATE_EXTERNAL_PO',
            'MODIFY_EXTERNAL_PO',
            'GET_EXTERNAL_PO',
            'CREATE_CUSTOM_ATTRIBUTES',
            'GET_CUSTOM_ATTRIBUTES',
            'GET_ORDER_ACCOUNT_SUMMARY',
            'UPDATE_BILLING_ACCOUNT',
            'CREATE_CONFIG_COUNTRY_CODES',
            'MODIFY_CONFIG_COUNTRY_CODES',
            'SEARCH_CONFIG_COUNTRY_CODES',
            'CREATE_ACCOUNT_EXCHANGE_RATES',
            'MODIFY_ACCOUNT_EXCHANGE_RATES',
            'GET_ACCOUNT_EXCHANGE_RATES',
            'CREATE_CUSTOM_DB_SEQUENCE_CONFIG',
            'MODIFY_CUSTOM_DB_SEQUENCE_CONFIG',
            'GET_CUSTOM_DB_SEQUENCE_CONFIG',
            'CREATE_PAYMENT_INSTALLMENT',
            'MODIFY_PAYMENT_INSTALLMENT',
            'GET_PAYMENT_INSTALLMENT',
            'CREATE_TASK',
            'MODIFY_TASK',
            'SEARCH_TASKS',
            'CREATE_CHILD_TASK',
            'GET_CHILD_TASKS',
            'SYNC_TASK_TO_EXTERNAL',
            'SYNC_TASK_STATUS_FROM_EXTERNAL',
            'TASK_ADMIN',
            'GET_ACCOUNT_STATEMENT',
          ],
        },
        {
          id: 2,
          title: 'common:label.orderManagementPermissions',
          code: 'orderModulePermissions',
          hubName: 'CUSTOMER_HUB',
          moduleName: 'ORDER_MANAGEMENT',
          hubFieldName: 'customerHubModules',
          moduleFieldName: 'orderModulePermissions',
          permissions: [
            'CREATE_ORDER',
            'UPDATE_ORDER',
            'CANCEL_ORDER',
            'SUBMIT_ORDER',
            'NEW_ORDER_TYPE',
            'MODIFY_ORDER_TYPE',
            'SUSPEND_ORDER_TYPE',
            'RESUME_ORDER_TYPE',
            'CANCEL_ORDER_TYPE',
            'PACKAGE_PURCHASE',
            'BUNDLE_PURCHASE',
            'ALACARTE_PURCHASE',
            'ADD_BUNDLE',
            'ADD_PACKAGE',
            'ADD_PRICE_OFFER',
            'DELETE_BUNDLE',
            'DELETE_PACKAGE',
            'DELETE_PRICE_OFFER',
            'SEARCH_ORDER',
            'GET_ORDER',
            'NEW_OPT_IN_TRIAL_ORDER_TYPE',
            'NEW_OPT_OUT_TRIAL_ORDER_TYPE',
            'OPT_IN_ORDER_TYPE',
            'OPT_OUT_ORDER_TYPE',
            'UPGRADE_ORDER_TYPE',
            'DOWNGRADE_ORDER_TYPE',
            'APPROVE_ORDER',
            'CREATE_OMS_TASK_CONFIG',
            'MODIFY_OMS_TASK_CONFIG',
            'GET_OMS_TASK_CONFIG',
            'CREATE_PROV_SEQUENCE_CONFIG',
            'MODIFY_PROV_SEQUENCE_CONFIG',
            'GET_PROV_SEQUENCE_CONFIG',
            'CREATE_FLEX_ATTRIBUTE_CONFIG',
            'MODIFY_FLEX_ATTRIBUTE_CONFIG',
            'GET_FLEX_ATTRIBUTES_CONFIG',
            'CREATE_BILLING_ACCOUNT',
            'PROCESS_SPLIT_ORDER',
            'GET_ORDER_REASON_CODES_CONFIG',
            'CREATE_ORDER_REASON_CODES_CONFIG',
            'MODIFY_ORDER_REASON_CODES_CONFIG',
            'UNDO_PROCESS_SPLIT_ORDER',
            'CREATE_PROV_ATTRIBUTE_CONFIG',
            'MODIFY_PROV_ATTRIBUTE_CONFIG',
            'GET_PROV_ATTRIBUTE_CONFIG',
            'MOVE_PROVISIONING_ORDER_TYPE',
          ],
        },
        {
          id: 3,
          title: 'common:label.quoteManagementPermissions',
          code: 'quoteModulePermissions',
          hubName: 'CUSTOMER_HUB',
          moduleName: 'QUOTE_MANAGEMENT',
          hubFieldName: 'customerHubModules',
          moduleFieldName: 'quoteModulePermissions',
          permissions: [
            'SAVE_QUOTE',
            'SEARCH_QUOTE',
            'CONVERT_QUOTE',
            'CANCEL_QUOTE',
            'APPROVE_QUOTE',
            'NEW_QUOTE_TYPE',
            'MODIFY_QUOTE_TYPE',
            'CANCEL_QUOTE_TYPE',
            'PACKAGE_PURCHASE',
            'BUNDLE_PURCHASE',
            'ALACARTE_PURCHASE',
            'ADD_BUNDLE',
            'ADD_PACKAGE',
            'ADD_PRICE_OFFER',
            'DELETE_BUNDLE',
            'DELETE_PACKAGE',
            'DELETE_PRICE_OFFER',
            'GET_QUOTE',
            'UPGRADE_QUOTE_TYPE',
            'DOWNGRADE_QUOTE_TYPE',
            'OVERRIDE_QUOTE_LINES',
            'UPDATE_PROVISIONING_ATTRIBUTES',
          ],
        },
        {
          id: 4,
          title: 'common:label.activityManagementPermissions',
          code: 'activityModulePermissions',
          hubName: 'CUSTOMER_HUB',
          moduleName: 'ACTIVITY_MANAGEMENT',
          hubFieldName: 'customerHubModules',
          moduleFieldName: 'activityModulePermissions',
          permissions: ['GET_ACTIVITY', 'SEARCH_ACTIVITY', 'VIEW_ACTIVITY_DETAILS'],
        },
        {
          id: 5,
          title: 'common:label.subscriptionManagementPermissions',
          code: 'subscriptionModulePermissions',
          hubName: 'CUSTOMER_HUB',
          moduleName: 'SUBSCRIPTION_MANAGEMENT',
          hubFieldName: 'customerHubModules',
          moduleFieldName: 'subscriptionModulePermissions',
          permissions: [
            'GET_SUBSCRIPTION',
            'GET_SERVICE_UNIT',
            'GET_PRICE_UNIT',
            'GET_DISCOUNT_UNIT',
            'SEARCH_PROVISIONING_DATA',
            'CREATE_SPECIAL_PRICE_RATE',
            'MODIFY_SPECIAL_PRICE_RATE',
            'GET_SPECIAL_PRICE_RATE',
            'CREATE_NPA_SPECIAL_PRICE_RATE',
            'MODIFY_NPA_SPECIAL_PRICE_RATE',
            'GET_NPA_SPECIAL_PRICE_RATE',
          ],
        },
      ],
    },
    {
      id: 3,
      title: 'common:label.pricingHubModules',
      code: 'PricingHubModules',
      hubFieldName: 'pricingHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.pricingManagementPermissions',
          code: 'pricingModulePermissions',
          hubName: 'PRICING_HUB',
          moduleName: 'PRICE_MANAGEMENT',
          hubFieldName: 'pricingHubModules',
          moduleFieldName: 'pricingModulePermissions',
          permissions: [
            'CREATE_ITEM',
            'MODIFY_ITEM',
            'APPROVE_ITEM',
            'SELLABLE_ITEM',
            'NON_SELLABLE_ITEM',
            'SUNSET_ITEM',
            'SEARCH_ITEMS',
            'GET_ITEM',
            'CREATE_PRICE_OFFER',
            'MODIFY_PRICE_OFFER',
            'APPROVE_PRICE_OFFER',
            'SELLABLE_PRICE_OFFER',
            'NON_SELLABLE_PRICE_OFFER',
            'SUNSET_PRICE_OFFER',
            'SEARCH_PRICE_OFFERS',
            'GET_PRICE_OFFER',
            'CREATE_RATE_UNIT_CONFIG',
            'MODIFY_RATE_UNIT_CONFIG',
            'VIEW_RATE_UNIT_CONFIG',
            'CREATE_TIME_UNIT_CONFIG',
            'MODIFY_TIME_UNIT_CONFIG',
            'VIEW_TIME_UNIT_CONFIG',
            'CREATE_USAGE_MAPPING_CONFIG',
            'MODIFY_USAGE_MAPPING_CONFIG',
            'VIEW_USAGE_MAPPING_CONFIG',
            'ADD_USAGE_MAPPING_CUSTOM_ATTRIBUTES',
            'MODIFY_USAGE_MAPPING_CUSTOM_ATTRIBUTES',
            'CREATE_USAGE_STREAM_CONFIG',
            'MODIFY_USAGE_STREAM_CONFIG',
            'VIEW_USAGE_STREAM_CONFIG',
            'ADD_USAGE_CANONICAL_CUSTOM_ATTRIBUTES',
            'MODIFY_USAGE_CANONICAL_CUSTOM_ATTRIBUTES',
            'VIEW_USAGE_CANONICAL_MAPPING',
            'MODIFY_CURRENCY_CONFIG',
            'VIEW_CURRENCY_CONFIG',
            'CREATE_GRANTS_CONFIG',
            'MODIFY_GRANTS_CONFIG',
            'VIEW_GRANTS_CONFIG',
            'CREATE_ACCUMULATOR_CONFIG',
            'MODIFY_ACCUMULATOR_CONFIG',
            'VIEW_ACCUMULATOR_CONFIG',
            'CREATE_DISCOUNT_OFFER',
            'MODIFY_DISCOUNT_OFFER',
            'APPROVE_DISCOUNT_OFFER',
            'SELLABLE_DISCOUNT_OFFER',
            'NON_SELLABLE_DISCOUNT_OFFER',
            'SUNSET_DISCOUNT_OFFER',
            'SEARCH_DISCOUNT_OFFERS',
            'GET_DISCOUNT_OFFER',
            'CREATE_RATE_UNIT_MAP_CONFIG',
            'GET_RATE_UNIT_MAP_CONFIG',
            'MODIFY_RATE_UNIT_MAP_CONFIG',
            'INACTIVATE_FILE_MAPPING',
            'INACTIVATE_STREAM_MAPPING',
            'CREATE_REGION_MAP_CONFIG',
            'MODIFY_REGION_MAP_CONFIG',
            'GET_REGION_MAP_CONFIG',
            'CREATE_FLEX_USG_ATTRIBUTE_MAP_CONFIG',
            'MODIFY_FLEX_USG_ATTRUBUTE_MAP_CONFIG',
            'GET_FLEX_USG_ATTRIBUTE_MAP_CONFIG',
            'CREATE_ZERO_RATE_CONFIG',
            'MODIFY_ZERO_RATE_CONFIG',
            'GET_ZERO_RATE_CONFIG',
            'CREATE_OPERATOR_MAP_CONFIG',
            'MODIFY_OPERATOR_MAP_CONFIG',
            'GET_OPERATOR_MAP_CONFIG',
            'CREATE_TRUNK_RULES_CONFIG',
            'MODIFY_TRUNK_RULES_CONFIG',
            'GET_TRUNK_RULES_CONFIG',
            'CREATE_UOM_CONFIG',
            'MODIFY_UOM_CONFIG',
            'SEARCH_UOM_CONFIG',
            'CREATE_ITEM_SERVICES',
            'MODIFY_ITEM_SERVICES',
            'SEARCH_ITEM_SERVICES',
            'CREATE_CONFIG_USAGE_TYPE',
            'MODIFY_CONFIG_USAGE_TYPE',
            'GET_CONFIG_USAGE_TYPE',
            'ADD_VOICE_PLAN',
            'MODIFY_VOICE_PLAN',
            'SEARCH_VOICE_PLAN',
            'CREATE_SEG_MAPPING',
            'MODIFY_SEG_MAPPING',
            'GET_SEG_MAPPING',
            'CREATE_TOPUP_SYSTEM_OFFER_CONFIG',
            'MODIFY_TOPUP_SYSTEM_OFFER_CONFIG',
            'SEARCH_TOPUP_SYSTEM_OFFER_CONFIG',
            'CREATE_ZONE_UNIT_CONFIG',
            'MODIFY_ZONE_UNIT_CONFIG',
            'VIEW_ZONE_UNIT_CONFIG',
            'SEARCH_RATED_USAGE',
            'CREATE_REGULATORY_PRODUCT_CODE',
            'MODIFY_REGULATORY_PRODUCT_CODE',
            'VIEW_REGULATORY_PRODUCT_CODE',
            'CREATE_CURRENCY_CONFIG',
            'CREATE_PRODUCT_FAMILY',
            'MODIFY_PRODUCT_FAMILY',
            'SEARCH_PRODUCT_FAMILY',
          ],
        },
        {
          id: 2,
          title: 'common:label.bundleManagementPermissions',
          code: 'bundleModulePermissions',
          hubName: 'PRICING_HUB',
          moduleName: 'BUNDLE_MANAGEMENT',
          hubFieldName: 'pricingHubModules',
          moduleFieldName: 'bundleModulePermissions',
          permissions: [
            'CREATE_BUNDLE',
            'MODIFY_BUNDLE',
            'APPROVE_BUNDLE',
            'SELLABLE_BUNDLE',
            'NON_SELLABLE_BUNDLE',
            'SUNSET_BUNDLE',
            'SEARCH_BUNDLE',
            'GET_BUNDLE',
            'CREATE_PACKAGE',
            'MODIFY_PACKAGE',
            'APPROVE_PACKAGE',
            'SELLABLE_PACKAGE',
            'NON_SELLABLE_PACKAGE',
            'SUNSET_PACKAGE',
            'SEARCH_PACKAGE',
            'GET_PACKAGE',
            'CREATE_DEPENDENCY',
            'MODIFY_DEPENDENCY',
            'GET_BUNDLE_DEPENDENCY',
            'GET_PACKAGE_DEPENDENCY',
          ],
        },
      ],
    },
    {
      id: 4,
      title: 'common:label.billingHubModules',
      code: 'BillingHubModules',
      hubFieldName: 'billingHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.billingModulePermissions',
          code: 'billingModulePermissions',
          hubName: 'BILLING_HUB',
          moduleName: 'BILLING',
          hubFieldName: 'billingHubModules',
          moduleFieldName: 'billingModulePermissions',
          permissions: [
            'SEARCH_BILLS',
            'GET_BILL',
            'TRIGGER_PENDING_BILL',
            'TRIGGER_MANUAL_BILL',
            'CREATE_DELAYED_BILLING_CONFIG',
            'MODIFY_DELAYED_BILLING_CONFIG',
            'GET_DELAYED_BILLING_CONFIG',
            'CREATE_IN_ADVANCE_BILLING_CONFIG',
            'MODIFY_IN_ADVANCE_BILLING_CONFIG',
            'GET_IN_ADVANCE_BILLING_CONFIG',
            'CREATE_PENDING_BILL_TRIGGER_CONFIG',
            'MODIFY_PENDING_BILL_TRIGGER_CONFIG',
            'GET_PENDING_BILL_TRIGGER_CONFIG',
            'SEARCH_LADING_BILL',
            'CREATE_EXPENSES_CONFIG',
            'GET_EXPENSES_CONFIG',
            'MODIFY_EXPENSES_CONFIG',
            'CREATE_EXPENSE_DATA',
            'MODIFY_EXPENSE_DATA',
            'SEARCH_EXPENSE_DATA',
            'SEARCH_EMPLOYEE_BONUS',
            'CREATE_EMPLOYEE_BONUS',
            'MODIFY_EMPLOYEE_BONUS',
          ],
        },
        {
          id: 2,
          title: 'common:label.invoicingModulePermissions',
          code: 'invoicingModulePermissions',
          hubName: 'BILLING_HUB',
          moduleName: 'INVOICING',
          hubFieldName: 'billingHubModules',
          moduleFieldName: 'invoicingModulePermissions',
          permissions: [
            'SEARCH_INVOICES',
            'GET_INVOICE',
            'CREATE_INVOICE_TEMPLATE',
            'MODIFY_INVOICE_TEMPLATE',
            'VIEW_INVOICE_TEMPLATE',
            'DOWNLOAD_INVOICE',
            'REGENERATE_INVOICE',
            'RE_INVOICE',
            'SEND_INVOICE',
            'APPROVE_INVOICE',
            'RESYNC_INVOICE',
            'UNDO_MANUAL_INVOICE',
            'EXTEND_INVOICE_DUE_DATE',
            'GENERATE_INVOICE',
            'UNDO_INVOICE_CANCEL',
            'RESEND_TO_VENDOR',
            'SEARCH_SAVED_MANUAL_INVOICE',
            'GET_FOLIO_RESPONSE',
            'CREATE_FOLIO_RESPONSE',
            'MODIFY_FOLIO_RESPONSE',
          ],
        },
        {
          id: 3,
          title: 'common:label.ratingModulePermissions',
          code: 'ratingModulePermissions',
          hubName: 'BILLING_HUB',
          moduleName: 'RATING',
          hubFieldName: 'billingHubModules',
          moduleFieldName: 'ratingModulePermissions',
          permissions: [
            'SEARCH_TRANSACTIONS',
            'GET_TRANSACTION',
            'GET_BALANCE',
            'SET_CREDIT_LIMIT_CONFIG',
            'MODIFY_CREDIT_LIMIT_CONFIG',
            'VIEW_TRANSACTION_DETAILS',
            'RE_RATING',
            'CREATE_RERATING_CONFIG',
            'GET_RERATING_CONFIG',
            'MODIFY_RERATING_CONFIG',
            'BACKOUT_RERATING',
            'GET_CREDIT_LIMIT_CONFIG',
            'CREATE_RERATING_BATCH',
            'MODIFY_RERATING_BATCH',
            'GET_RERATING_BATCH',
            'EDIT_SUSPENDED_FAILED_USAGE_RECORDS',
            'TEST_USAGE',
          ],
        },
        {
          id: 4,
          title: 'common:label.taxationModulePermissions',
          code: 'taxationModulePermissions',
          hubName: 'BILLING_HUB',
          moduleName: 'TAXATION',
          hubFieldName: 'billingHubModules',
          moduleFieldName: 'taxationModulePermissions',
          permissions: [
            'CREATE_TAX_CONFIG',
            'MODIFY_TAX_CONFIG',
            'GET_TAX_CONFIG',
            'GET_TAX_TYPE_CONFIG',
            'MODIFY_TAX_TYPE_CONFIG',
            'CREATE_TAX_TYPE_CONFIG',
          ],
        },
      ],
    },

    {
      id: 5,
      title: 'common:label.arHubModules',
      code: 'ARHubModules',
      hubFieldName: 'arHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.arOpsPermissions',
          code: 'arOpsModulePermissions',
          hubName: 'AR_HUB',
          moduleName: 'AR_OPS',
          hubFieldName: 'arHubModules',
          moduleFieldName: 'arOpsModulePermissions',
          permissions: [
            'CREATE_REASON_CODE_CONFIG',
            'MODIFY_REASON_CODE_CONFIG',
            'GET_REASON_CODE_CONFIG',
            'PROCESS_ADJUSTMENT',
            'PROCESS_TAX_ADJUSTMENT',
            'SEARCH_ADJUSTMENTS',
            'GET_ADJUSTMENT_DETAILS',
            'PROCESS_DISPUTE',
            'PROCESS_TAX_DISPUTE',
            'SEARCH_DISPUTES',
            'GET_DISPUTE_DETAILS',
            'PROCESS_SETTLEMENT',
            'PROCESS_TAX_SETTLEMENT',
            'SEARCH_SETTLEMENT',
            'GET_SETTLEMENT_DETAILS',
            'PROCESS_WRITEOFF',
            'PROCESS_TAX_WRITEOFF',
            'SEARCH_WRITEOFF',
            'GET_WRITEOFF_DETAILS',
            'PROCESS_WRITEOFF_REVERSAL',
            'PROCESS_TAX_WRITEOFF_REVERSAL',
            'SEARCH_WRITEOFF_REVERSAL',
            'GET_WRITEOFF_REVERSAL_DETAILS',
            'SEARCH_AROPS_UNIT',
            'CREATE_NOTES_TEMPLATE',
            'MODIFY_NOTES_TEMPLATE',
            'VIEW_NOTES_TEMPLATE',
            'SEARCH_NOTES',
            'GET_NOTES',
            'CREATE_CREDIT_NOTES',
            'CREATE_DEBIT_NOTES',
            'AR_OPS_TAX_OPTIONS',
            'CREATE_CONFIG_AR_OPS_THRESHOLDS',
            'MODIFY_CONFIG_AR_OPS_THRESHOLDS',
            'GET_CONFIG_AR_OPS_THRESHOLDS',
            'CREATE_CONFIG_GROUP_REASON_CODE',
            'MODIFY_CONFIG_GROUP_REASON_CODE',
            'GET_CONFIG_GROUP_REASON_CODE',
            'CREATE_CONFIG_APPROVALS',
            'MODIFY_CONFIG_APPROVALS',
            'GET_CONFIG_APPROVALS',
            'CREATE_OBJECT_APPROVALS',
            'MODIFY_OBJECT_APPROVALS',
            'GET_OBJECT_APPROVALS',
            'CREATE_CONFIG_USER_GROUPS',
            'MODIFY_CONFIG_USER_GROUPS',
            'GET_CONFIG_USER_GROUPS',
            'BULK_ADJUSTMENT',
            'CREDIT_NOTE_APPROVAL',
          ],
        },
        {
          id: 2,
          title: 'common:label.paymentPermissions',
          code: 'paymentModulePermissions',
          hubName: 'AR_HUB',
          moduleName: 'PAYMENT',
          hubFieldName: 'arHubModules',
          moduleFieldName: 'paymentModulePermissions',
          permissions: [
            'CREATE_PAYMENT_TERMS_CONFIG',
            'MODIFY_PAYMENT_TERMS_CONFIG',
            'CREATE_PAYMENT_MERCHANT_CONFIG',
            'MODIFY_PAYMENT_MERCHANT_CONFIG',
            'CREATE_PAYMENT_METHOD_CONFIG',
            'MODIFY_PAYMENT_METHOD_CONFIG',
            'GET_PAYMENT_CONFIGURATION',
            'PROCESS_PAYMENT',
            'PROCESS_PAYMENT_REVERSAL',
            'AUTO_PAYMENT_ALLOCATION',
            'MANUAL_PAYMENT_ALLOCATION',
            'SEARCH_PAYMENTS',
            'SEARCH_PAYMENT_REVERSALS',
            'SEARCH_PAYMENT_SUSPENSE',
            'PROCESS_PAYMENT_SUSPENSE',
            'PROCESS_REFUND',
            'PROCESS_CHARGEBACKS',
            'APPLY_BATCH_PAYMENTS',
            'APPLY_BATCH_PAYMENT_REVERSALS',
            'APPLY_BATCH_REFUNDS',
            'VIEW_PAYMENT_HISTORY',
            'SEARCH_AR_ACTIVITY',
            'GET_AR_ACTIVITY',
            'VIEW_AR_ACTIVITY_DETAILS',
            'BATCH_PAYMENT_UPLOAD',
            'BATCH_PAYMENT_PROCESS',
            'VIEW_BANAMEX_PAYMENT',
            'VIEW_BANORTE_PAYMENT',
            'VIEW_BANCOMER_PAYMENT',
            'VIEW_SANTANDER_PAYMENT',
            'CREATE_PAYMENT_SURCHARGE_CONFIG',
            'MODIFY_PAYMENT_SURCHARGE_CONFIG',
            'GET_PAYMENT_SURCHARGE_CONFIG',
            'CREATE_PAYMENT_ITEM_MAP',
            'MODIFY_PAYMENT_ITEM_MAP',
            'GET_PAYMENT_ITEM_MAP',
            'CREATE_BATCH_PAYMENT_FILE_CONFIG',
            'MODIFY_BATCH_PAYMENT_FILE_CONFIG',
            'GET_BATCH_PAYMENT_FILE_CONFIG',
            'GET_PAYMENT_ALLOCATION_CONFIG',
            'MODIFY_PAYMENT_ALLOCATION_CONFIG',
            'CREATE_PAYMENT_ALLOCATION_CONFIG',
            'REGENERATE_PAYMENT_FOLIO',
            'RESEND_TO_VENDOR',
          ],
        },
        {
          id: 3,
          title: 'common:label.collectionPermissions',
          code: 'collectionModulePermissions',
          hubName: 'AR_HUB',
          moduleName: 'COLLECTION',
          hubFieldName: 'arHubModules',
          moduleFieldName: 'collectionModulePermissions',
          permissions: [
            'CREATE_COLLECTION_AGENT_CONFIG',
            'GET_COLLECTION_AGENT_CONFIG',
            'MODIFY_COLLECTION_AGENT_CONFIG',
            'CREATE_COLLECTION_ACTION_CONFIG',
            'GET_COLLECTION_ACTION_CONFIG',
            'MODIFY_COLLECTION_ACTION_CONFIG',
            'CREATE_COLLECTION_SCHEDULE_CONFIG',
            'GET_COLLECTION_SCHEDULE_CONFIG',
            'MODIFY_COLLECTION_SCHEDULE_CONFIG',
            'SEARCH_COLLECTION_AGENT_ACTIVITY',
            'REASSIGN_COLLECTION_AGENT',
            'SEARCH_ACCOUNTS_IN_COLLECTION',
            'SEARCH_INVOICE_UNITS_IN_COLLECTION',
            'SEARCH_COLLECTION_UNITS',
            'ADD_MANUAL_ACTIONS',
            'VIEW_MANUAL_ACTIONS',
            'CREATE_COLLECTION_PROFILE',
            'MODIFY_COLLECTION_PROFILE',
            'SEARCH_COLLECTION_PROFILE',
          ],
        },
      ],
    },
    {
      id: 6,
      title: 'common:label.opsHubModules',
      code: 'OpsHubModules',
      hubFieldName: 'opsHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.userManagementPermissions',
          code: 'userModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'USER_MANAGEMENT',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'userModulePermissions',
          permissions: [
            'CREATE_ROLES',
            'MODIFY_ROLES',
            'SEARCH_ROLES',
            'DELETE_ROLES',
            'CREATE_ROLE_GROUPS',
            'MODIFY_ROLE_GROUPS',
            'SEARCH_ROLE_GROUPS',
            'DELETE_ROLE_GROUPS',
            'CREATE_USER',
            'MODIFY_USER',
            'SEARCH_USER',
            'CHANGE_USER_STATUS',
            'CHANGE_ROLE_STATUS',
            'CHANGE_ROLE_GROUP_STATUS',
            'GET_ROLES',
            'GET_ROLES_GROUP',
            'GET_USER',
          ],
        },
        {
          id: 2,
          title: 'common:label.jobsManagementPermissions',
          code: 'jobsModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'JOBS_MANAGEMENT',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'jobsModulePermissions',
          permissions: [
            'CREATE_JOB_SCHEDULE',
            'MODIFY_JOB_SCHEDULE',
            'GET_JOB_SCHEDULE',
            'UPDATE_JOB_SCHEDULE_STATUS',
            'PROCESS_JOB_SCHEDULE',
            'REPROCESS_JOB_SCHEDULE',
            'CREATE_JOB_CONFIG',
            'MODIFY_JOB_CONFIG',
            'FIND_JOB_CONFIG',
            'CREATE_JOB',
            'MODIFY_JOB',
            'UPDATE_JOB_STATUS',
            'PROCESS_JOB',
            'REPROCESS_JOB',
            'GET_JOB_STATS',
            'MODIFY_JOB_TYPE',
            'REFRESH_JOB_SCHEDULE',
          ],
        },
        {
          id: 3,
          title: 'common:label.tenantManagementPermissions',
          code: 'tenantOnboardModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'TENANT_ONBOARDING',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'tenantOnboardModulePermissions',
          permissions: [
            'GET_TENANT_INFO',
            'CREATE_TENANT_INFO',
            'SET_LICENSE_DATA',
            'MODIFY_LICENSE_DATA',
            'SET_COMPANY_PROFILE',
            'MODIFY_COMPANY_PROFILE',
            'SET_PROPERTY_DEFAULTS',
            'MODIFY_PROPERTY_DEFAULTS',
            'SET_PAYMENT_GATEWAY_MERCHANT',
            'SET_TAX_GATEWAY_MERCHANT',
            'SET_FINANCE_GATEWAY_MERCHANT',
            'SET_CRM_GATEWAY_PROVIDER',
            'SET_DATA_GATEWAY_PROVIDER',
            'SET_USAGE_GATEWAY_PROVIDER',
            'SET_PROVISIONING_GATEWAY_PROVIDER',
            'MODIFY_PAYMENT_GATEWAY_MERCHANT',
            'MODIFY_TAX_GATEWAY_MERCHANT',
            'MODIFY_FINANCE_GATEWAY_MERCHANT',
            'MODIFY_CRM_GATEWAY_PROVIDER',
            'MODIFY_DATA_GATEWAY_PROVIDER',
            'MODIFY_USAGE_GATEWAY_PROVIDER',
            'MODIFY_PROVISIONING_GATEWAY_PROVIDER',
            'CREATE_GATEWAY_CANONICAL',
            'MODIFY_GATEWAY_CANONICAL',
            'GET_GATEWAY_CANONICAL',
            'CREATE_PAYMENT_METHOD_URL',
            'MODIFY_PAYMENT_METHOD_URL',
            'GET_PAYMENT_METHOD_URL',
            'GET_GATEWAY_CANONICAL_SPEC',
            'CREATE_QUEUE_CONFIG',
            'MODIFY_QUEUE_CONFIG',
            'GET_QUEUE_CONFIG',
            'CREATE_OMS_TASK_CONFIG',
            'MODIFY_OMS_TASK_CONFIG',
            'GET_OMS_TASK_CONFIG',
          ],
        },
        {
          id: 4,
          title: 'common:label.dashboardPermissions',
          code: 'dashboardModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'DASHBOARD',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'dashboardModulePermissions',
          permissions: [
            'SAVE_USER_PREFERENCES',
            'GET_USER_PREFERENCES',
            'CUSTOMER_ONBOARDING',
            'CUSTOMER_CHURN',
            'TRIAL_TO_PAID_CONVERSION',
            'SUBSCRIPTION_CANCELLATIONS',
            'BILLED_REVENUE',
            'BILLED_CUSTOMER',
            'PAYMENT_SUCCESS',
            'PAYMENT_SUSPENSE',
            'PAYMENT_FAILED',
            'ACCOUNTS_WITH_FAILED_PAYMENT',
            'COLLECTION_0_30_DAYS',
            'COLLECTION_30_60_DAYS',
            'COLLECTION_60_90_DAYS',
            'COLLECTION_OVER_90_DAYS',
            'TOTAL_REVENUE',
            'FAILED_ORDER',
            'BILLS_FAILED',
            'INVOICES_FAILED',
            'TOTAL_CREDIT_ADJUSTMENTS',
            'TOTAL_DISPUTES',
            'TOTAL_SETTLEMENTS',
            'JOBS_FAILED',
            'SUBMITTED_ORDER',
            'USAGE_TRANSACTION',
            'RATED_USAGE_TRANSACTION',
            'TOTAL_JOBS',
            'RECOGNIZED_REVENUE',
            'TOTAL_WRITEOFF',
            'ACTIVE_SUBSCRIPTIONS',
            'TOTAL_DEBIT_ADJUSTMENTS',
            'REVENUE_BY_PRODUCT_COMPANY',
            'REVENUE_BY_REVENUE_TYPE',
            'CUSTOMER_COUNT_BY_ACCOUNT_TYPE',
            'CUSTOMER_COUNT_BY_SELLING_COMPANY',
            'REVENUE_FORECAST',
            'MONTHLY_RECURRING_REVENUE',
            'USAGE_DATA_BY_SERVICE_TYPE',
            'USAGE_DATA_BY_CALL_TYPE',
            'FOLIO_SUMMARY',
          ],
        },
        {
          id: 5,
          title: 'common:label.reportPermissions',
          code: 'reportModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'REPORTS',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'reportModulePermissions',
          permissions: [
            'CREATE_REPORT_STYLESHEET',
            'MODIFY_REPORT_STYLESHEET',
            'VIEW_REPORT_STYLESHEET',
            'EDIT_REPORT_DATA',
            'VIEW_REPORT_DATA',
            'GET_NEW_CUSTOMER_REPORT',
            'SEARCH_ACCOUNT_REPORT',
            'GET_ACCOUNT_CLOSURE_REPORT',
            'GET_ORDER_REPORT',
            'GET_TRIAL_SUBSCRIPTION_REPORT',
            'GET_PAID_SUBSCRIPTION_REPORT',
            'GET_TRIAL_TO_PAID_SUBSCRIPTION_REPORT',
            'GET_UNBILLED_TRANSACTION_REPORT',
            'GET_USAGE_SUMMARY_REPORT',
            'GET_USAGE_DETAIL_REPORT',
            'GET_USAGE_RECONCILIATION_REPORT',
            'GET_CUSTOMER_BILLING_REPORT',
            'GET_BILLED_TRANSACTION_SUMMARY_REPORT',
            'GET_BILL_PRODUCT_SUMMARY_REPORT',
            'GET_INVOICE_REPORT',
            'GET_TAX_REPORT',
            'GET_BILLING_INVOICING_RECON_REPORT',
            'GET_JOBSCHEDULE_REPORT',
            'GET_FAILED_JOBS_REPORT',
            'GET_JOB_DETAIL_REPORT',
            'GET_PAYMENT_REPORT',
            'GET_FAILED_PAYMENT_REPORT',
            'GET_PAYMENT_SUSPENSE_REPORT',
            'GET_FAILED_PAYMENT_CUSTOMER_REPORT',
            'GET_INVOICING_AR_RECON_REPORT',
            'GET_COLLECTION_0_30_DAYS_REPORT',
            'GET_COLLECTION_30_60_DAYS_REPORT',
            'GET_COLLECTION_60_90_DAYS_REPORT',
            'GET_COLLECTION_GT_90_DAYS_REPORT',
            'GET_AGEING_REPORT',
            'GET_AROPS_REPORT',
            'GET_WRITEOFF_REPORT',
            'GET_DISPUTE_REPORT',
            'GET_REVENUE_SUMMARY_BY_ITEM_REPORT',
            'GET_REVENUE_RECOGNITION_BY_ITEM_REPORT',
            'GET_TAX_SUMMARY_REPORT',
            'GET_TAX_DETAIL_REPORT',
            'GET_SUBSCRIPTION_REPORT',
            'GET_REVENUE_RECOGNITION_REPORT',
            'GET_REVENUE_SUMMARY_REPORT',
            'GET_ACTIVE_SUBSCRIPTION_REPORT',
            'DOWNLOAD_REPORT_DATA',
            'GET_ACTIVE_TRIAL_SUBSCRIPTION_REPORT',
            'GET_SERVICE_BILLING_REPORT',
            'MAIL_REPORT_DATA',
            'GET_INVOICING_PAYMENT_RECON_REPORT',
            'GET_SUBSCRIPTION_CONTRACT_EXPIRY_REPORT',
            'GET_REVENUE_FORECAST_REPORT',
            'GET_MONTHLY_RECURRING_REVENUE',
            'GET_VOLUME_AMOUNT_BY_USAGE_TYPE',
            'GET_VOLUME_AMOUNT_PER_OPERATOR',
            'EXCESS_VOICE_TRAFFIC_REPORT',
            'VOICE_TRAFFIC_BY_LD_DESTINATION_REPORT',
            'DATA_SERVICE_REPORT',
            'NEW_SMS_REPORT',
            'RAW_INTERCONNECT_VOICE_REPORT',
            'VOICE_CALL_TYPE_SUMMARY_REPORT',
            'RAW_INTERCONNECT_SMS_REPORT',
            'GET_CREDIT_NOTE_REPORT',
            'BILLABLE_SERVICES_REPORT',
            'INTERNET_OPERATOR_DATA_COST_REPORT',
            'TELECOM_SUMMARY_REPORT',
            'SEARCH_MEDIATION_FILE_STATUS',
            'GET_ACCOUNTING_RECONCILIATION_REPORT',
            'GET_RAW_CDR_REPORT',
            'GET_RAW_SMS_REPORT',
            // 'GET_BROADSOFT_RAW_CDR_REPORT',
            'GET_NON_BROADSOFT_RAW_CDR_REPORT',
            'GET_ASSETS_FORECAST_REPORT',
            'EMPLOYEE_COSTING_PNL_REPORT',
            'BILLABLE_SERVICES_FORECAST_REPORT',
            'GET_OBJECT_FILES_REPORT',
            'GET_PROVISIONING_DATA_REPORT',
            'BILLING_TRACKER_REPORT',
            'COMMITMENT_REPORT',
            'GET_PAYMENT_AGEING_REPORT',
            'SEARCH_SERVICE_CANCELLATION_EXTRACT',
            'SEARCH_KPI_EXTRACT',
            'GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT',
            'GET_BROADSOFT_ZERODURATION_CDRS_REPORT',
            'GET_SUSPENDED_FAILED_RECORDS_REPORT',
            'GET_BI_EXTRACT',
            'GET_REG_R001_05_REPORT',
            'GET_REG_R001_06_REPORT',
            'GET_REG_R004_01_REPORT',
            'GET_REG_R004_05_REPORT',
            'GET_REG_R005_01_REPORT',
            'GET_REG_R005_02_REPORT',
            'GET_REG_R005_03_REPORT',
            'GET_REG_R005_04_REPORT',
            'GET_REG_R005_05_REPORT',
            'GET_REG_R005_06_REPORT',
            'GET_CREDIT_BUREAU_REPORT',
            'PUSH_KPI_EXTRACT',
            'TAX_EXEMPTION_REPORT',
            'FONATEL_REPORT',
            'PHC_ASSET_REPORT',
            'SERVICE_RECON_REPORT',
          ],
        },
        {
          id: 5,
          title: 'common:label.correspondencePermissions',
          code: 'correspondenceModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'CORRESPONDENCE',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'correspondenceModulePermissions',
          permissions: [
            'CREATE_PAYMENT_FAILURE_TEMPLATE',
            'MODIFY_PAYMENT_FAILURE_TEMPLATE',
            'VIEW_PAYMENT_FAILURE_TEMPLATE',
            'CREATE_FIRST_PAYMENT_REMINDER_TEMPLATE',
            'MODIFY_FIRST_PAYMENT_REMINDER_TEMPLATE',
            'VIEW_FIRST_PAYMENT_REMINDER_TEMPLATE',
            'CREATE_SECOND_PAYMENT_REMINDER_TEMPLATE',
            'CREATE_SUSPEND_SUBSCRIPTION_TEMPLATE',
            'MODIFY_SECOND_PAYMENT_REMINDER_TEMPLATE',
            'MODIFY_SUSPEND_SUBSCRIPTION_TEMPLATE',
            'VIEW_SECOND_PAYMENT_REMINDER_TEMPLATE',
            'CREATE_THIRD_PAYMENT_REMINDER_TEMPLATE',
            'MODIFY_THIRD_PAYMENT_REMINDER_TEMPLATE',
            'VIEW_THIRD_PAYMENT_REMINDER_TEMPLATE',
            'CREATE_PAYMENT_SUCCESS_TEMPLATE',
            'MODIFY_PAYMENT_SUCCESS_TEMPLATE',
            'VIEW_PAYMENT_SUCCESS_TEMPLATE',
            'CREATE_NEW_SUBSCRIPTION_TEMPLATE',
            'MODIFY_NEW_SUBSCRIPTION_TEMPLATE',
            'VIEW_NEW_SUBSCRIPTION_TEMPLATE',
            'CREATE_CANCEL_SUBSCRIPTION_TEMPLATE',
            'MODIFY_CANCEL_SUBSCRIPTION_TEMPLATE',
            'VIEW_CANCEL_SUBSCRIPTION_TEMPLATE',
            'CREATE_RENEWAL_DUE_TEMPLATE',
            'MODIFY_RENEWAL_DUE_TEMPLATE',
            'VIEW_RENEWAL_DUE_TEMPLATE',
            'CREATE_INVOICE_READY_TEMPLATE',
            'MODIFY_INVOICE_READY_TEMPLATE',
            'VIEW_INVOICE_READY_TEMPLATE',
            'CREATE_TRIAL_EXPIRY_TEMPLATE',
            'MODIFY_TRIAL_EXPIRY_TEMPLATE',
            'VIEW_TRIAL_EXPIRY_TEMPLATE',
            'CREATE_CREDIT_LIMIT_TEMPLATE',
            'MODIFY_CREDIT_LIMIT_TEMPLATE',
            'VIEW_CREDIT_LIMIT_TEMPLATE',
            'CREATE_CREDIT_THRESHOLD_TEMPLATE',
            'MODIFY_CREDIT_THRESHOLD_TEMPLATE',
            'VIEW_CREDIT_THRESHOLD_TEMPLATE',
            'CREATE_INVOICE_DUE_DATE_TEMPLATE',
            'MODIFY_INVOICE_DUE_DATE_TEMPLATE',
            'VIEW_INVOICE_DUE_DATE_TEMPLATE',
            'CREATE_OPS_JOB_FAILURE_TEMPLATE',
            'MODIFY_OPS_JOB_FAILURE_TEMPLATE',
            'VIEW_OPS_JOB_FAILURE_TEMPLATE',
            'CREATE_CONFIG_CORRESPONDENCE_TEMPLATE',
            'MODIFY_CONFIG_CORRESPONDENCE_TEMPLATE',
            'VIEW_CONFIG_CORRESPONDENCE_TEMPLATE',
            'VIEW_SUSPEND_SUBSCRIPTION_TEMPLATE',
            'CREATE_CREDIT_NOTE_READY_TEMPLATE',
            'MODIFY_CREDIT_NOTE_READY_TEMPLATE',
            'VIEW_CREDIT_NOTE_READY_TEMPLATE',
            'CREATE_COLLECTION_ENTRY_TEMPLATE',
            'MODIFY_COLLECTION_ENTRY_TEMPLATE',
            'VIEW_COLLECTION_ENTRY_TEMPLATE',
          ],
        },
        {
          id: 6,
          title: 'common:label.systemParametersPermissions',
          code: 'systemParametersModulePermissions',
          hubName: 'OPS_HUB',
          moduleName: 'SYSTEM_PARAMETERS',
          hubFieldName: 'opsHubModules',
          moduleFieldName: 'systemParametersModulePermissions',
          permissions: ['SET_SYSTEM_TIME'],
        },
      ],
    },
    {
      id: 7,
      title: 'common:label.revenueHubModules',
      code: 'RevenueHubModules',
      hubFieldName: 'revenueHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.revenueManagementPermissions',
          code: 'revenueModulePermissions',
          hubName: 'REVENUE_HUB',
          moduleName: 'REVENUE',
          hubFieldName: 'revenueModulePermissions',
          moduleFieldName: 'revenueModulePermissions',
          permissions: [
            'CREATE_LOCATION_CONFIG',
            'GET_LOCATION_CONFIG',
            'MODIFY_LOCATION_CONFIG',
            'CREATE_CURRENCY_EXCHANGE_CONFIG',
            'GET_CURRENCY_EXCHANGE_CONFIG',
            'MODIFY_CURRENCY_EXCHANGE_CONFIG',
            'CREATE_MILESTONE_CONFIG',
            'GET_MILESTONE_CONFIG',
            'MODIFY_MILETSTONE_CONFIG',
            'CREATE_GL_ACCOUNTS_CONFIG',
            'GET_GL_ACCOUNTS_CONFIG',
            'MODIFY_GL_ACCOUNTS_CONFIG',
            'CREATE_LEDGER_MAP',
            'GET_LEDGER_MAP',
            'MODIFY_LEDGER_MAP',
            'CREATE_SUB_LEDGER_MAP',
            'GET_SUB_LEDGER_MAP',
            'MODIFY_SUB_LEDGER_MAP',
            'CREATE_MANUAL_RECOGNITION',
            'VIEW_LEDGER',
            'VIEW_SUB_LEDGER',
            'VIEW_BALANCE_SHEET',
            'VIEW_P_AND_L',
            'CREATE_ITEM_SPLIT_CONFIG',
            'MODIFY_ITEM_SPLIT_CONFIG',
            'GET_ITEM_SPLIT_CONFIG',
            'CREATE_CALENDAR_CONFIG',
            'GET_CALENDAR_CONFIG',
            'MODIFY_CALENDAR_CONFIG',
            'CREATE_ENTERPRISE_CONFIG',
            'GET_ENTERPRISE_CONFIG',
            'MODIFY_ENTERPRISE_CONFIG',
            'CREATE_DIVISIONS_CONFIG',
            'GET_DIVISIONS_CONFIG',
            'MODIFY_DIVISIONS_CONFIG',
            'CREATE_LEGAL_ENTITY_CONFIG',
            'GET_LEGAL_ENTITY_CONFIG',
            'MODIFY_LEGAL_ENTITY_CONFIG',
            'CREATE_BUSINESS_UNIT_CONFIG',
            'GET_BUSINESS_UNIT_CONFIG',
            'MODIFY_BUSINESS_UNIT_CONFIG',
            'CREATE_DEPARTMENT_CONFIG',
            'GET_DEPARTMENT_CONFIG',
            'MODIFY_DEPARTMENT_CONFIG',
            'CREATE_COST_CENTER_CONFIG',
            'GET_COST_CENTER_CONFIG',
            'MODIFY_COST_CENTER_CONFIG',
            'CREATE_COA_CONFIG',
            'GET_COA_CONFIG',
            'MODIFY_COA_CONFIG',
            'CREATE_CONFIG_ACCOUNTING_RULES',
            'GET_CONFIG_ACCOUNTING_RULES',
            'MODIFY_CONFIG_ACCOUNTING_RULES',
            'SAVE_COST_CENTER_MAP_CONFIG',
            'GET_COST_CENTER_MAP_CONFIG',
            'RESEND_ERP_BATCH',
          ],
        },
      ],
    },
    {
      id: 7,
      title: 'common:label.mediationHubModules',
      code: 'mediationHubModules',
      hubFieldName: 'mediationHubModules',
      childs: [
        {
          id: 1,
          title: 'common:label.mediationManagementPermissions',
          code: 'mediationModulePermissions',
          hubName: 'MEDIATION_HUB',
          moduleName: 'MEDIATION',
          hubFieldName: 'mediationHubModules',
          moduleFieldName: 'mediationModulePermissions',
          permissions: [
            'GET_CONFIG_PNN_PLAN_NUMBERING',
            'CREATE_MEDIATION_THREADS_CONFIG',
            'MODIFY_MEDIATION_THREADS_CONFIG',
            'GET_MEDIATION_THREADS_CONFIG',
            'CREATE_USAGE_PLATFORM_CONFIG',
            'MODIFY_USAGE_PLATFORM_CONFIG',
            'GET_USAGE_PLATFORM_CONFIG',
          ],
        },
      ],
    },
  ],
  user: [
    {
      id: 1,
      title: 'User → Address',
      state: 'User → Address',
    },
    {
      id: 2,
      title: 'User → Contact',
      state: 'User → Contact',
    },
    {
      id: 3,
      title: 'User → Roles',
      state: 'User → Roles',
    },
    {
      id: 4,
      title: 'User → Role Groups',
      state: 'User → Role Groups',
    },
  ],
  proflie: [
    {
      id: 1,
      title: 'User Address',
      state: 'User Address',
    },
    {
      id: 2,
      title: 'User Contact',
      state: 'User Contact',
    },
  ],

  tenantManagement: [
    {
      id: 1,
      title: 'label.tenant',
      state: 'Tenant',
    },
    {
      id: 2,
      title: 'label.companyProfile',
      state: 'Company Profile',
    },
    {
      id: 3,
      title: 'label.gatewayMerchantAccounts',
      state: 'Gateway Merchant Accounts',
    },
    {
      id: 4,
      title: 'label.platformDefaults',
      state: 'Platform Defaults',
    },
  ],

  gatewayMerchantAccounts: [
    {
      id: 1,
      title: 'label.taxGateway',
      state: 'Tax Gateway',
    },
    {
      id: 2,
      title: 'label.paymentGateway',
      state: 'Payment Gateway',
    },
    {
      id: 3,
      title: 'label.financeGateway',
      state: 'Finance Gateway',
    },
  ],

  timeUnit: [
    {
      id: 1,
      title: 'label.workWeekConfiguration',
      state: 'Work Week Configuration',
    },
    {
      id: 2,
      title: 'label.peakHoursConfiguration',
      state: 'Peak Hours Configuration',
    },
    {
      id: 3,
      title: 'label.holidayConfiguration',
      state: 'Holiday Configuration',
    },
  ],
};
