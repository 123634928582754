import listCurrency from '../../constantsApp/currency.json';
import { countryList } from '../../constantsApp/countryList';
import utils from '../../utils/utils';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const sortRevenue = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  productFamily: {
    asc: 'productFamily_ASC',
    desc: 'productFamily_DESC',
  },
  productLine: {
    asc: 'productLine_ASC',
    desc: 'productLine_DESC',
  },
  productType: {
    asc: 'productType_ASC',
    desc: 'productType_DESC',
  },
  productSubType: {
    asc: 'productSubType_ASC',
    desc: 'productSubType_DESC',
  },
  currency: {
    asc: 'currency_ASC',
    desc: 'currency_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const listMilestoneFields = [
  {
    label: 'common:label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'common:label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'common:label.productFamily',
    name: 'productFamily',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.currency',
    name: 'currency',
    type: 'select',
    options: currencyOptions,
  },
  {
    label: 'common:label.country',
    name: 'country',
  },
  {
    label: 'common:label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:milestoneStatus',
  },
  {
    label: 'common:label.unit',
    name: 'unit',
    type: 'select',
    tOptions: 'selections:milestoneUnits',
    required: true,
  },
  {
    label: 'common:label.serviceType',
    name: 'type',
    type: 'select',
    tOptions: 'selections:serviceType',
  },
  {
    label: 'common:label.offsetValue',
    name: 'offsetval',
    type: 'number',
    required: true,
  },
];

const sortCurency = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};

const sortAccountingLog = {
  drAccountType: {
    asc: 'drAccountType_ASC',
    desc: 'drAccountType_DESC',
  },
  drGlAccount: {
    asc: 'drGlAccount_ASC',
    desc: 'drGlAccount_DESC',
  },
  drAmount: {
    asc: 'drAmount_ASC',
    desc: 'drAmount_DESC',
  },
  drCurrency: {
    asc: 'drCurrency_ASC',
    desc: 'drCurrency_DESC',
  },
  crAccountType: {
    asc: 'crAccountType_ASC',
    desc: 'crAccountType_DESC',
  },
  crGlAccount: {
    asc: 'crGlAccount_ASC',
    desc: 'crGlAccount_DESC',
  },
  crAmount: {
    asc: 'crAmount_ASC',
    desc: 'crAmount_DESC',
  },
  crCurrency: {
    asc: 'crCurrency_ASC',
    desc: 'crCurrency_DESC',
  },
  objectId: {
    asc: 'objectId_ASC',
    desc: 'objectId_DESC',
  },
  objectType: {
    asc: 'objectType_ASC',
    desc: 'objectType_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

const sortEbsExtract = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  period: {
    asc: 'period_ASC',
    desc: 'period_DESC',
  },
  extractStatus: {
    asc: 'extractStatus_ASC',
    desc: 'extractStatus_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};

const groupNamePermissions = {
  CREATE_LOCATION_CONFIG: 'CREATE_LOCATION_CONFIG',
  GET_LOCATION_CONFIG: 'GET_LOCATION_CONFIG',
  MODIFY_LOCATION_CONFIG: 'MODIFY_LOCATION_CONFIG',
  CREATE_CURRENCY_EXCHANGE_CONFIG: 'CREATE_CURRENCY_EXCHANGE_CONFIG',
  GET_CURRENCY_EXCHANGE_CONFIG: 'GET_CURRENCY_EXCHANGE_CONFIG',
  MODIFY_CURRENCY_EXCHANGE_CONFIG: 'MODIFY_CURRENCY_EXCHANGE_CONFIG',
  CREATE_MILESTONE_CONFIG: 'CREATE_MILESTONE_CONFIG',
  GET_MILESTONE_CONFIG: 'GET_MILESTONE_CONFIG',
  MODIFY_MILETSTONE_CONFIG: 'MODIFY_MILETSTONE_CONFIG',
  CREATE_GL_ACCOUNTS_CONFIG: 'CREATE_GL_ACCOUNTS_CONFIG',
  GET_GL_ACCOUNTS_CONFIG: 'GET_GL_ACCOUNTS_CONFIG',
  MODIFY_GL_ACCOUNTS_CONFIG: 'MODIFY_GL_ACCOUNTS_CONFIG',
  CREATE_LEDGER_MAP: 'CREATE_LEDGER_MAP',
  GET_LEDGER_MAP: 'GET_LEDGER_MAP',
  MODIFY_LEDGER_MAP: 'MODIFY_LEDGER_MAP',
  CREATE_SUB_LEDGER_MAP: 'CREATE_SUB_LEDGER_MAP',
  GET_SUB_LEDGER_MAP: 'GET_SUB_LEDGER_MAP',
  MODIFY_SUB_LEDGER_MAP: 'MODIFY_SUB_LEDGER_MAP',
  CREATE_MANUAL_RECOGNITION: 'CREATE_MANUAL_RECOGNITION',
  VIEW_LEDGER: 'VIEW_LEDGER',
  VIEW_SUB_LEDGER: 'VIEW_SUB_LEDGER',
  VIEW_BALANCE_SHEET: 'VIEW_BALANCE_SHEET',
  VIEW_P_AND_L: 'VIEW_P_AND_L',
  CREATE_ITEM_SPLIT_CONFIG: 'CREATE_ITEM_SPLIT_CONFIG',
  MODIFY_ITEM_SPLIT_CONFIG: 'MODIFY_ITEM_SPLIT_CONFIG',
  GET_ITEM_SPLIT_CONFIG: 'GET_ITEM_SPLIT_CONFIG',
  CREATE_CALENDAR_CONFIG: 'CREATE_CALENDAR_CONFIG',
  GET_CALENDAR_CONFIG: 'GET_CALENDAR_CONFIG',
  MODIFY_CALENDAR_CONFIG: 'MODIFY_CALENDAR_CONFIG',
  CREATE_ENTERPRISE_CONFIG: 'CREATE_ENTERPRISE_CONFIG',
  GET_ENTERPRISE_CONFIG: 'GET_ENTERPRISE_CONFIG',
  MODIFY_ENTERPRISE_CONFIG: 'MODIFY_ENTERPRISE_CONFIG',
  CREATE_DIVISIONS_CONFIG: 'CREATE_DIVISIONS_CONFIG',
  GET_DIVISIONS_CONFIG: 'GET_DIVISIONS_CONFIG',
  MODIFY_DIVISIONS_CONFIG: 'MODIFY_DIVISIONS_CONFIG',
  CREATE_LEGAL_ENTITY_CONFIG: 'CREATE_LEGAL_ENTITY_CONFIG',
  GET_LEGAL_ENTITY_CONFIG: 'GET_LEGAL_ENTITY_CONFIG',
  MODIFY_LEGAL_ENTITY_CONFIG: 'MODIFY_LEGAL_ENTITY_CONFIG',
  CREATE_BUSINESS_UNIT_CONFIG: 'CREATE_BUSINESS_UNIT_CONFIG',
  GET_BUSINESS_UNIT_CONFIG: 'GET_BUSINESS_UNIT_CONFIG',
  MODIFY_BUSINESS_UNIT_CONFIG: 'MODIFY_BUSINESS_UNIT_CONFIG',
  CREATE_DEPARTMENT_CONFIG: 'CREATE_DEPARTMENT_CONFIG',
  GET_DEPARTMENT_CONFIG: 'GET_DEPARTMENT_CONFIG',
  MODIFY_DEPARTMENT_CONFIG: 'MODIFY_DEPARTMENT_CONFIG',
  CREATE_COST_CENTER_CONFIG: 'CREATE_COST_CENTER_CONFIG',
  GET_COST_CENTER_CONFIG: 'GET_COST_CENTER_CONFIG',
  MODIFY_COST_CENTER_CONFIG: 'MODIFY_COST_CENTER_CONFIG',
  CREATE_COA_CONFIG: 'CREATE_COA_CONFIG',
  GET_COA_CONFIG: 'GET_COA_CONFIG',
  MODIFY_COA_CONFIG: 'MODIFY_COA_CONFIG',
  RESEND_ERP_BATCH: 'RESEND_ERP_BATCH',
};

const sortJournal = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  itemId: {
    asc: 'itemId_ASC',
    desc: 'itemId_DESC',
  },
  revenueStartDate: {
    asc: 'revenueStartDate_ASC',
    desc: 'revenueStartDate_DESC',
  },
  revenueEndDate: {
    asc: 'revenueEndDate_ASC',
    desc: 'revenueEndDate_DESC',
  },
  legalEntity: {
    asc: 'legalEntity_ASC',
    desc: 'legalEntity_DESC',
  },
  productCompany: {
    asc: 'productCompany_ASC',
    desc: 'productCompany_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  setOfBooks: {
    asc: 'setOfBooks_ASC',
    desc: 'setOfBooks_DESC',
  },
  costCenter: {
    asc: 'costCenter_ASC',
    desc: 'costCenter_DESC',
  },
  revenueType: {
    asc: 'revenueType_ASC',
    desc: 'revenueType_DESC',
  },
  revenueRecognitionType: {
    asc: 'revenueRecognitionType_ASC',
    desc: 'revenueRecognitionType_DESC',
  },
  revenueActivityType: {
    asc: 'revenueActivityType_ASC',
    desc: 'revenueActivityType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const collapsibleMenuAccountActivities = [
  {
    title: 'customerPage:sidebarHandleAccountPage.accountInfo',
    state: 'Account Info',
    key: 'activityAccountInfo',
    entityType: 'ACCOUNT_INFO',
    dataFields: [
      {
        label: 'label.customerSegment',
        name: 'customerSegment',
        tOptions: 'selections:customerSegment',
        type: 'select',
      },
      {
        label: 'label.accountType',
        name: 'type',
        tOptions: 'selections:accountType',
        type: 'select',
      },
      {
        label: 'label.accountSubType',
        name: 'subType',
        tOptions: 'selections:accountSubType',
        type: 'select',
      },
      // {
      //   label: 'label.creditProfileIndex',
      //   name: 'creditProfileIndex',
      // },
      {
        label: 'label.salesChannel',
        name: 'salesChannel',
        tOptions: 'selections:salesChannel',
        type: 'select',
      },
      {
        label: 'label.marketSegment',
        name: 'marketSegment',
        tOptions: 'selections:marketSegment',
        type: 'select',
      },
      {
        label: 'label.sellingCompany',
        name: 'sellingCompany',
      },
      {
        label: 'label.lineOfBusiness',
        name: 'lineOfBusiness',
      },
      {
        label: 'label.legalEntity',
        name: 'legalEntity',
      },
      {
        label: 'label.currency',
        name: 'currency',
        optionSelect: utils.convertCurrency2Option(listCurrency.currencies),
      },
      {
        label: 'label.status',
        name: 'status',
        tOptions: 'selections:status',
        type: 'select',
      },
      {
        label: 'label.reason',
        name: 'reason',
        tOptions: 'selections:reason',
        type: 'select',
      },
      {
        label: 'label.effectiveDate',
        name: 'effectiveDate',
      },
      {
        label: 'label.userId',
        name: 'userId',
      },
      {
        label: 'label.clientId',
        name: 'clientId',
      },
      {
        label: 'label.salesAgentId',
        name: 'salesAgentId',
      },
      {
        label: 'label.organization',
        name: 'organization',
      },
      {
        label: 'label.region',
        name: 'region',
      },
      {
        label: 'label.collectionAgentId',
        name: 'collectionAgent',
      },
      {
        label: 'label.employeeId',
        name: 'employeeId',
      },
      {
        label: 'label.commercialName',
        name: 'commercialName',
      },
      {
        label: 'label.taxId',
        name: 'taxId',
      },
      {
        label: 'label.foreignTaxId',
        name: 'foreignTaxId',
      },
    ],
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.accountInfo',
    state: 'Account Info',
    key: 'activityAccountInfo',
    entityType: 'CUSTOMER',
    dataFields: [
      {
        label: 'label.customerSegment',
        name: 'customerSegment',
        tOptions: 'selections:customerSegment',
        type: 'select',
      },
      {
        label: 'label.accountType',
        name: 'type',
        tOptions: 'selections:accountType',
        type: 'select',
      },
      {
        label: 'label.accountSubType',
        name: 'subType',
        tOptions: 'selections:accountSubType',
        type: 'select',
      },
      // {
      //   label: 'label.creditProfileIndex',
      //   name: 'creditProfileIndex',
      // },
      {
        label: 'label.salesChannel',
        name: 'salesChannel',
        tOptions: 'selections:salesChannel',
        type: 'select',
      },
      {
        label: 'label.marketSegment',
        name: 'marketSegment',
        tOptions: 'selections:marketSegment',
        type: 'select',
      },
      {
        label: 'label.sellingCompany',
        name: 'sellingCompany',
      },
      {
        label: 'label.lineOfBusiness',
        name: 'lineOfBusiness',
      },
      {
        label: 'label.legalEntity',
        name: 'legalEntity',
      },
      {
        label: 'label.currency',
        name: 'currency',
        optionSelect: utils.convertCurrency2Option(listCurrency.currencies),
      },
      {
        label: 'label.status',
        name: 'status',
        tOptions: 'selections:status',
        type: 'select',
      },
      {
        label: 'label.reason',
        name: 'reason',
        tOptions: 'selections:reason',
        type: 'select',
      },
      {
        label: 'label.effectiveDate',
        name: 'effectiveDate',
      },
      {
        label: 'label.userId',
        name: 'userId',
      },
      {
        label: 'label.clientId',
        name: 'clientId',
      },
      {
        label: 'label.salesAgentId',
        name: 'salesAgentId',
      },
      {
        label: 'label.organization',
        name: 'organization',
      },
      {
        label: 'label.region',
        name: 'region',
      },
      {
        label: 'label.collectionAgentId',
        name: 'collectionAgent',
      },
      {
        label: 'label.employeeId',
        name: 'employeeId',
      },
      {
        label: 'label.commercialName',
        name: 'commercialName',
      },
      {
        label: 'label.taxId',
        name: 'taxId',
      },
      {
        label: 'label.foreignTaxId',
        name: 'foreignTaxId',
      },
    ],
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.contact',
    state: 'Account Contact',
    key: 'activityContacts',
    entityType: 'CONTACT',
    dataFields: [
      {
        name: 'id',
        label: 'common:label.id',
        isDisable: true,
      },
      {
        label: 'common:label.salutation',
        name: 'salutation',
      },
      {
        label: 'common:label.firstName',
        name: 'firstName',
      },
      {
        label: 'common:label.middleName',
        name: 'middleName',
      },
      {
        label: 'common:label.lastName',
        name: 'lastName',
      },
      {
        label: 'common:label.position',
        name: 'position',
      },
      {
        label: 'common:label.organization',
        name: 'organization',
      },
      {
        label: 'common:label.email',
        name: 'email',
      },
      {
        label: 'common:label.identity',
        name: 'identity',
        tooltipDetails: 'common:message.identityNote',
      },
      {
        label: 'common:label.identityDocument',
        name: 'identityDocument',
        tooltipDetails: 'common:message.identityDocumentNote',
      },
      {
        label: 'common:label.phone',
        name: 'phones',
      },
    ],
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.addresses',
    state: 'Account Addresses',
    key: 'activityAddresses',
    entityType: 'ADDRESS',
    dataFields: [
      {
        name: 'id',
        label: 'common:label.id',
        required: true,
      },
      {
        name: 'unit',
        label: 'common:label.unit',
      },
      {
        name: 'street',
        label: 'common:label.street',
        type: 'textarea',
      },
      {
        label: 'common:label.country',
        name: 'country',
        optionSelect: countryList,
      },
      {
        name: 'state',
        label: 'common:label.state',
      },
      {
        name: 'district',
        label: 'common:label.district',
      },
      {
        label: 'common:label.city',
        name: 'city',
      },
      {
        name: 'postalCode',
        label: 'common:label.postalCode',
      },
      {
        name: 'code',
        label: 'common:label.code',
        isDisable: true,
      },
    ],
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.paymentProfile',
    state: 'Payment Profile',
    key: 'activityPaymentProfiles',
    entityType: 'PAYMENT_PROFILE',
    dataFields: [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        label: 'common:label.paymentTerm',
        name: 'paymentTerm',
        tOptions: 'selections:paymentTerm',
        type: 'select',
      },
      {
        label: 'common:label.paymentMethod',
        name: 'paymentMethod',
        tOptions: 'selections:paymentMethod',
        type: 'select',
      },
      {
        label: 'common:label.cardToken',
        name: 'cardToken',
      },
      {
        label: 'common:label.cardExp',
        name: 'cardExpiry',
      },
      {
        label: 'common:label.creditCardCVV',
        name: 'last4CC',
      },
    ],
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.billingProfile',
    state: 'Billing Profile',
    key: 'activityBillingProfiles',
    entityType: 'BILLING_PROFILE',
    dataFields: [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'parentId',
        label: 'common:label.parentId',
        isDisable: true,
      },
      {
        name: 'billingDom',
        label: 'common:label.billingDom',
        type: 'number',
      },
      {
        name: 'billingSegment',
        label: 'common:label.billingSegment',
      },
      {
        name: 'billingFrequency',
        label: 'common:label.billingFrequency',
        tOptions: 'selections:billingFrequency',
        type: 'select',
      },
      {
        name: 'invoiceType',
        label: 'common:label.invoiceType',
        tOptions: 'selections:invoiceType',
        type: 'select',
      },
      {
        name: 'invoiceDelivery',
        label: 'common:label.invoiceDelivery',
        tOptions: 'selections:invoiceDelivery',
        type: 'select',
      },
      {
        name: 'paymentProfileId',
        label: 'common:label.paymentProfileId',
      },
      {
        name: 'lastAccountingDate',
        label: 'label.lastAccountingDate',
      },
      {
        name: 'nextAccountingDate',
        label: 'label.nextAccountingDate',
      },
      {
        name: 'futureAccountingDate',
        label: 'label.futureAccountingDate',
      },
      {
        name: 'lastBillDate',
        label: 'label.lastBillDate',
      },
      {
        name: 'lastBillUnitId',
        label: 'label.lastBillId',
      },
      {
        name: 'nextBillDate',
        label: 'label.nextBillDate',
      },
      {
        name: 'nextBillUnitId',
        label: 'label.nextBillId',
      },
      {
        name: 'futureCycleOpen',
        label: 'label.futureCycleOpen',
      },
      {
        name: 'futureBillDate',
        label: 'label.futureBillDate',
      },
      {
        name: 'futureBillUnitId',
        label: 'label.futureBillId',
      },
      {
        name: 'status',
        label: 'label.status',
        tOptions: 'selections:status',
        type: 'select',
      },
      {
        name: 'reason',
        label: 'label.reason',
        tOptions: 'selections:reason',
        type: 'select',
      },
    ],
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.customAttributes',
    state: 'Custom Attributes',
    key: 'activityCustomAttributes',
    entityType: 'CUSTOM_ATTRIBUTES',
    dataFields: [],
  },
];

const collapsibleMenuBeforeAfter = [
  {
    title: 'Before',
    // state: 'Before',
    state: 'Childrent',
    key: 'beforeData',
  },
  {
    title: 'After',
    // state: 'Aftter',
    state: 'Childrent',
    key: 'afterData',
  },
];

export {
  sortRevenue,
  listMilestoneFields,
  sortCurency,
  groupNamePermissions,
  sortAccountingLog,
  sortEbsExtract,
  sortJournal,
  collapsibleMenuAccountActivities,
  collapsibleMenuBeforeAfter,
};
