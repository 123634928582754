export default {
  sidebar: {
    collectionAdmin: 'Administración de Cobranza',
    // collectionAdmin: 'Administración de Cobranza',
    collectionConfig: 'Configuración de Cobranza',
    // collectionConfig: 'Configuración de Cobranza',
    // collectionActions: 'Acciones de Cobranza',
    collectionActions: 'Acciones de Cobranza',
    collectionSchedule: 'Calendario de Cobros',
    collectionAgents: 'Agentes de Cobranza',
    searchAccounts: 'Buscar Cuentas',
    // searchAccounts: 'Search Accounts',
    invoiceUnits: 'Unidades de Factura',
    collectionHistory: 'Historial de Cobranza',
    agentActivity: 'Actividad del Agente',
    accountsInCollection: 'Cuentas en Cobranza',
    // schedulePatternConfig: 'Configuración del Patrón de Programación',
    collectionAging: 'Antiguedad en Cobranza',
  },
};
