import gql from 'graphql-tag';

export default () => gql`
  query {
    viewBulkAdjustmentUploadStats(dummy: "") {
      fileName
      fileUploadDate
      totalCount
      successCount
      failureCount
    }
  }
`;
