import gql from 'graphql-tag';

export default id => {
  const undoProcessSplitOrder = `undoProcessSplitOrder(input: "${id}")`;
  return gql`
  mutation {
    ${undoProcessSplitOrder}
  }
`;
};
