import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import { formatMoneyValue, formatNumberValue } from '../../../utils/utils';
import 'rc-tooltip/assets/bootstrap_white.css';

const ColData = ({
  name,
  item,
  render,
  indexParent,
  idx,
  isRenderT,
  col,
  activeTab,
  isAmount,
  isNoNeedConvertNumber,
  ...rest
}) => {
  const { t } = useTranslation(['common', 'selections']);
  let colText = render && !isRenderT ? render(name, item, idx, indexParent, activeTab) : item[name];
  if (isRenderT) colText = render(name, item, t);
  // return <td onClick={idx === col.length - 1 ? '' : onClickTd}>{colText}</td>;
  return (
    <td {...rest}>
      {/* {isAmount ? formatMoneyValue(colText) : isNoNeedConvertNumber ? colText : formatNumberValue(colText)} */}
      {colText}
    </td>
  );
};

ColData.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  render: PropTypes.func,
  isRenderT: PropTypes.bool,
};
ColData.defaultProps = {
  name: '',
  item: {},
  render: null,
  isRenderT: false,
};

const RowHeader = ({ columns, sorted, onSort, isLoading, onCheckAll, t }) => {
  const hHtml = columns.map((col, idx) => {
    const onSortCol = !isLoading && onSort && col.sortable ? onSort : () => {};
    return (
      <th
        key={`th-${col.name}-${idx}`}
        className={classNames(col.className, {
          sortable: col.sortable,
          sorting: col.sortable && col.name === sorted.sortCol,
          'sorting-desc': col.sortable && col.name === sorted.sortCol && sorted.sortDir === 'desc',
          'sorting-asc': col.sortable && col.name === sorted.sortCol && sorted.sortDir !== 'desc',
        })}
        onClick={e => onSortCol(col.name, sorted.sortDir === 'desc' ? 'asc' : 'desc')}
        style={col.style ? col.style : {}}
        disabled={isLoading}
      >
        <label className="label-header">
          {col.isCheckable && (
            <input name={col.name} type="checkbox" checked={col.name === sorted.sortCol} onChange={onCheckAll} />
          )}
          {col.label ? t(col.label) : ''}
          {col.label && col.required && <i className="fa fa-star is-required" />}
          {col?.tooltipLabel && (
          <Tooltip
            placement="bottom"
            overlay={col?.tooltipLabel ? t(col?.tooltipLabel) : ''}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
            overlayClassName="tooltip-info-header-tabel"
          >
            <i className="fa fa-info-circle info-header-table-hover" />
          </Tooltip>
            )}
        </label>
      </th>
    );
  });
  return <tr className="text-dark">{hHtml}</tr>;
};

RowHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  onSort: PropTypes.func,
  isLoading: PropTypes.bool,
};
RowHeader.defaultProps = {
  columns: [],
  sorted: {},
  isLoading: false,
};

const RowContent = ({
  columns,
  item,
  className,
  onClickRow,
  onClickTd,
  getRowClass,
  indexParent,
  indexItem,
  ExpandRow,
  activeTab,
  isSupportRemoveIndex,
  ...rest
}) => {
  const rowClassNames = `${className} ${getRowClass ? getRowClass(item) : ''}`;
  const rowCols = columns.map((col, idx) => (
    <ColData
      key={`${col.key}-${idx}`}
      name={col.name}
      item={item}
      render={col.render}
      indexParent={indexParent}
      idx={indexItem}
      isRenderT={col.isRenderT}
      col={col}
      activeTab={activeTab}
      isAmount={col.isAmount}
      isNoNeedConvertNumber={col.isNoNeedConvertNumber}
      {...rest}
    />
  ));
  return rowCols;
};

RowContent.propTypes = {
  columns: PropTypes.array.isRequired,
  item: PropTypes.object,
  className: PropTypes.string,
  onClickRow: PropTypes.func,
  getRowClass: PropTypes.func,
};
RowContent.defaultProps = {
  columns: [],
  item: {},
  className: '',
  onClickRow: null,
  getRowClass: null,
};

export { RowHeader, RowContent, ColData };
