import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import UserManagementRole from './Role';
import UserManagementRoleAdd from './Role/RoleAdd';
import UserManagementRoleEdit from './Role/RoleEdit';
import UserManagementRoleGroup from './RoleGroup';
import UserManagementRoleGroupAdd from './RoleGroup/RoleGroupAdd';
import UserManagementRoleGroupEdit from './RoleGroup/RoleGroupEdit';
import UserManagementUser from './User';
import UserManagementUserEdit from './User/UserEdit';
import UserManagementUserAdd from './User/UserAdd';
import UserGroups from './UserGroups';
import UserGroupsCreate from './UserGroups/UserGroupsCreate';
import UserGroupsModify from './UserGroups/UserGroupsModify';
import Approvals from './Approvals';
import ApprovalsCreate from './Approvals/ApprovalsCreate';
import ApprovalsModify from './Approvals/ApprovalsModify';
import { makeGetPermissionsUserManagement } from '../App/selectors';
import Sidebar from './Sidebar';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class PricingManagement extends PureComponent {
  render() {
    const { permissionsUser } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsUser, listKeys: permissionList.userManagement }))
      return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.userManagementRole.path} component={UserManagementRole} />
              <Route exact path={RouteNames.userManagementRoleAdd.path} component={UserManagementRoleAdd} />
              <Route exact path={RouteNames.userManagementRoleEdit.path} component={UserManagementRoleEdit} />
              <Route exact path={RouteNames.userManagementRoleGroup.path} component={UserManagementRoleGroup} />
              <Route exact path={RouteNames.userManagementRoleGroupAdd.path} component={UserManagementRoleGroupAdd} />
              <Route exact path={RouteNames.userManagementRoleGroupEdit.path} component={UserManagementRoleGroupEdit} />
              <Route exact path={RouteNames.userManagementUser.path} component={UserManagementUser} />
              <Route exact path={RouteNames.userManagementUserAdd.path} component={UserManagementUserAdd} />
              <Route exact path={RouteNames.userManagementUserEdit.path} component={UserManagementUserEdit} />
              <Route exact path={RouteNames.userManagementUserGroups.path} component={UserGroups} />
              <Route exact path={RouteNames.userManagementUserGroupsAdd.path} component={UserGroupsCreate} />
              <Route exact path={RouteNames.userManagementUserGroupsModify.path} component={UserGroupsModify} />
              <Route exact path={RouteNames.userManagementApproval.path} component={Approvals} />
              <Route exact path={RouteNames.userManagementApprovalAdd.path} component={ApprovalsCreate} />
              <Route exact path={RouteNames.userManagementApprovalModify.path} component={ApprovalsModify} />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsUser: makeGetPermissionsUserManagement() || {},
});

const mapDispatchToProps = dispatch => ({
  push,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'userManagementReducer', reducer });
const withSaga = injectSaga({ key: 'userManagementSaga', saga });

export default compose(withReducer, withSaga, withConnect)(PricingManagement);
