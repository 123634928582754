import { createSelector } from 'reselect';

const selectStateRootApp = state => state.get('auth');

const makeGetPermissions = () => createSelector(selectStateRootApp, item => item.get('permissionsApp'));

const makeGetPermissionsCustomerManagement = () =>
  createSelector(selectStateRootApp, item => {
    const customerHubModules = item.get('permissionsApp').customerHubModules;
    if (customerHubModules) {
      return customerHubModules.filter(a => a.moduleName === 'CUSTOMER_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsCustomerManagementSubscription = () =>
  createSelector(selectStateRootApp, item => {
    const customerHubModules = item.get('permissionsApp').customerHubModules;
    if (customerHubModules) {
      return customerHubModules.filter(a => a.moduleName === 'SUBSCRIPTION_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsCustomerManagementActivity = () =>
  createSelector(selectStateRootApp, item => {
    const customerHubModules = item.get('permissionsApp').customerHubModules;
    if (customerHubModules) {
      return customerHubModules.filter(a => a.moduleName === 'ACTIVITY_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsOrderManagement = () =>
  createSelector(selectStateRootApp, item => {
    const customerHubModules = item.get('permissionsApp').customerHubModules;
    if (customerHubModules) {
      return customerHubModules.filter(a => a.moduleName === 'ORDER_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsBilling = () =>
  createSelector(selectStateRootApp, item => {
    const billingHubModules = item.get('permissionsApp').billingHubModules;
    if (billingHubModules) {
      return billingHubModules.filter(a => a.moduleName === 'BILLING')[0];
    }
    return {};
  });

const makeGetPermissionsInvoice = () =>
  createSelector(selectStateRootApp, item => {
    const billingHubModules = item.get('permissionsApp').billingHubModules;
    if (billingHubModules) {
      return billingHubModules.filter(a => a.moduleName === 'INVOICING')[0];
    }
    return {};
  });

const makeGetPermissionsRating = () =>
  createSelector(selectStateRootApp, item => {
    const billingHubModules = item.get('permissionsApp').billingHubModules;
    if (billingHubModules) {
      return billingHubModules.filter(a => a.moduleName === 'RATING')[0];
    }
    return {};
  });

const makeGetPermissionsPricingManagement = () =>
  createSelector(selectStateRootApp, item => {
    const pricingHubModules = item.get('permissionsApp').pricingHubModules;
    if (pricingHubModules) {
      return pricingHubModules.filter(a => a.moduleName === 'PRICE_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsBundleManagement = () =>
  createSelector(selectStateRootApp, item => {
    const pricingHubModules = item.get('permissionsApp').pricingHubModules;
    if (pricingHubModules) {
      return pricingHubModules.filter(a => a.moduleName === 'BUNDLE_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsArOpsManagement = () =>
  createSelector(selectStateRootApp, item => {
    const arHubModules = item.get('permissionsApp').arHubModules;
    if (arHubModules) {
      return arHubModules.filter(a => a.moduleName === 'AR_OPS')[0];
    }
    return {};
  });

const makeGetPermissionsPaymentManagement = () =>
  createSelector(selectStateRootApp, item => {
    const arHubModules = item.get('permissionsApp').arHubModules;
    if (arHubModules) {
      return arHubModules.filter(a => a.moduleName === 'PAYMENT')[0];
    }
    return {};
  });

const makeGetPermissionsCollectionManagement = () =>
  createSelector(selectStateRootApp, item => {
    const arHubModules = item.get('permissionsApp').arHubModules;
    if (arHubModules) {
      return arHubModules.filter(a => a.moduleName === 'COLLECTION')[0];
    }
    return {};
  });

const makeGetPermissionsJobsManagement = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'JOBS_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsUserManagement = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'USER_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetPermissionsTenantManagement = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'TENANT_ONBOARDING')[0];
    }
    return {};
  });

const makeGetPermissionsDashboard = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'DASHBOARD')[0];
    }
    return {};
  });

const makeGetPermissionsReports = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'REPORTS')[0];
    }
    return {};
  });

const makeGetPermissionsRevenue = () =>
  createSelector(selectStateRootApp, item => {
    const revenueHubModules = item.get('permissionsApp').revenueHubModules;
    if (revenueHubModules) {
      return revenueHubModules.filter(a => a.moduleName === 'REVENUE')[0];
    }
    return {};
  });

const makeGetPermissionsTaxConfig = () =>
  createSelector(selectStateRootApp, item => {
    const billingHubModules = item.get('permissionsApp').billingHubModules;
    if (billingHubModules) {
      return billingHubModules.filter(a => a.moduleName === 'TAXATION')[0];
    }
    return {};
  });

const makeGetPermissionsCorrespondence = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').opsHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'CORRESPONDENCE')[0];
    }
    return {};
  });

const makeGetPermissionsMediation = () =>
  createSelector(selectStateRootApp, item => {
    const opsHubModules = item.get('permissionsApp').mediationHubModules;
    if (opsHubModules) {
      return opsHubModules.filter(a => a.moduleName === 'MEDIATION')[0];
    }
    return {};
  });

const makeGetPermissionsQuoteManagement = () =>
  createSelector(selectStateRootApp, item => {
    const customerHubModules = item.get('permissionsApp').customerHubModules;
    if (customerHubModules) {
      return customerHubModules.filter(a => a.moduleName === 'QUOTE_MANAGEMENT')[0];
    }
    return {};
  });

const makeGetS3Config = () =>
  createSelector(selectStateRootApp, items => (items.get('s3Config') ? items.get('s3Config') : null));

const makeGetCurrencyConfig = () =>
  createSelector(selectStateRootApp, items => (items.get('currencyConfig') ? items.get('currencyConfig') : null));

const makeGetAvatarProfile = () =>
  createSelector(selectStateRootApp, items => (items.get('avatarProfile') ? items.get('avatarProfile') : null));

const makeGetCcpPropertiesConfig = () =>
  createSelector(selectStateRootApp, items => (items.get('ccpPropertyList') ? items.get('ccpPropertyList') : null));

const makeGetCcpTime = () =>
  createSelector(selectStateRootApp, items => (items.get('ccpTime') ? items.get('ccpTime') : null));

// const makeGetTenantDefaultData = () =>
//   createSelector(selectStateRootApp, items => (items.get('tenantDefaultData') ? items.get('tenantDefaultData') : null));

export {
  makeGetPermissions,
  makeGetPermissionsCustomerManagement,
  makeGetPermissionsCustomerManagementSubscription,
  makeGetPermissionsCustomerManagementActivity,
  makeGetPermissionsOrderManagement,
  makeGetPermissionsBilling,
  makeGetPermissionsInvoice,
  makeGetPermissionsRating,
  makeGetPermissionsPricingManagement,
  makeGetPermissionsBundleManagement,
  makeGetPermissionsArOpsManagement,
  makeGetPermissionsUserManagement,
  makeGetPermissionsPaymentManagement,
  makeGetPermissionsCollectionManagement,
  makeGetPermissionsJobsManagement,
  makeGetPermissionsTenantManagement,
  makeGetPermissionsDashboard,
  makeGetPermissionsReports,
  makeGetPermissionsRevenue,
  makeGetPermissionsTaxConfig,
  makeGetS3Config,
  makeGetPermissionsCorrespondence,
  makeGetCurrencyConfig,
  makeGetPermissionsMediation,
  makeGetAvatarProfile,
  makeGetCcpPropertiesConfig,
  makeGetPermissionsQuoteManagement,
  makeGetCcpTime,
  // makeGetTenantDefaultData,
};
