import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class RSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.monthYear',
      name: 'monthYear',
      type: 'monthYear',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

RSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

RSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(RSearchForm);
