import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class ZoneUnitSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.serviceType',
      name: 'serviceType',
      tOptions: 'selections:serviceType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:genericStatus',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

ZoneUnitSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ZoneUnitSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ZoneUnitSearchForm);
