import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import { validate } from '../../../utils/utils';
import { GenericInput } from '../../../components/common';
import { getUsageContainerSpec } from '../actions';
import { getAccumulatorsConfig } from '../../App/actions';

const DiscountTriggerForm = ({
  onSubmit,
  isCreate,
  data,
  getAccumulatorsConfig,
  getUsageContainerSpec,
  onCancel,
  modeCreateDiscountOffer,
  modeModifyDiscountOffer,
}) => {
  const { t } = useTranslation('common');
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({});
  const [optionsAccumulatorId, setOptionsAccumulatorId] = useState([]);
  const [optionRecordType, setOptionRecordType] = useState([]);

  const additionalField = [
    {
      name: 'accumulatorId',
      label: 'label.accumulatorId',
      type: 'select',
      options: optionsAccumulatorId,
      disabled: !dataSubmit || dataSubmit.evaluationComponent !== 'BALANCE_OF_ACCUMULATOR',
    },
    {
      name: 'usageContainerField',
      label: 'label.usageField',
      type: 'select',
      options: optionRecordType,
      disabled: !dataSubmit || dataSubmit.evaluationComponent !== 'USAGE_CONTAINER_FIELD',
    },
  ];

  // if (dataSubmit?.evaluationComponent && dataSubmit.evaluationComponent === 'BALANCE_OF_ACCUMULATOR') {
  //   additionalField.push({
  //     name: 'accumulatorId',
  //     label: 'label.accumulatorId',
  //     type: 'select',
  //     options: optionsAccumulatorId,
  //   });
  // }

  // if (dataSubmit?.evaluationComponent && dataSubmit.evaluationComponent === 'USAGE_CONTAINER_FIELD') {
  //   additionalField.push({
  //     name: 'usageContainerField',
  //     label: 'label.usageField',
  //     type: 'select',
  //     options: optionRecordType,
  //   });
  // }

  const discountTriggerListFields = [
    {
      name: 'id',
      label: 'label.id',
      disabled: !isCreate,
    },
    {
      name: 'name',
      label: 'label.name',
      required: true,
      disabled: !isCreate,
    },
    {
      label: 'label.evaluationComponent',
      name: 'evaluationComponent',
      type: 'select',
      tOptions: 'selections:evaluationComponent',
      required: true,
    },
    ...additionalField,
    {
      label: 'label.comparisonOperator',
      name: 'comparisonOperator',
      type: 'select',
      tOptions: 'selections:comparisonOperator',
      required: true,
    },
    {
      name: 'value',
      label: 'label.value',
    },
  ];

  const onChange = ({ name, value }) => {
    dataSubmit[name] = value;
    if (name === 'evaluationComponent') {
      if (value !== 'BALANCE_OF_ACCUMULATOR') dataSubmit.accumulatorId = null;
      if (value !== 'USAGE_CONTAINER_FIELD') dataSubmit.usageContainerField = null;
    }
    setDataSubmit({ ...dataSubmit });
  };

  const onHandlenSubmit = e => {
    e.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    return onSubmit(dataSubmit);
  };

  useEffect(() => {
    if (data && data.id) setDataSubmit(data);
  }, [data]);

  useEffect(() => {
    if (
      dataSubmit?.evaluationComponent &&
      dataSubmit.evaluationComponent === 'BALANCE_OF_ACCUMULATOR' &&
      !optionsAccumulatorId.length
    ) {
      getAccumulatorsConfig(({ data, success }) => {
        if (success) {
          const optionsAccumulatorId =
            data && data.accumulatorList
              ? data.accumulatorList.map(item => ({
                  label: item.accumulatorid,
                  value: item.accumulatorid,
                }))
              : [];

          setOptionsAccumulatorId(optionsAccumulatorId);
        }
      });
    }

    if (
      dataSubmit?.evaluationComponent &&
      dataSubmit.evaluationComponent === 'USAGE_CONTAINER_FIELD' &&
      !optionRecordType.length
    ) {
      getUsageContainerSpec(({ data, success }) => {
        if (success) {
          let attributesList = [];
          if (data && data.length) {
            data.forEach(dt => {
              if (dt?.attributes && dt.attributes.length) attributesList = [...attributesList, ...dt.attributes];
            });
          }
          const optionRecordType = attributesList
            ? uniqBy(attributesList, 'attributeName').map(val => ({
                label: val.attributeName,
                value: val.attributeName,
              }))
            : [];
          setOptionRecordType(optionRecordType);
        }
      }, true);
    }
  }, [
    dataSubmit.evaluationComponent,
    getAccumulatorsConfig,
    getUsageContainerSpec,
    optionRecordType,
    optionsAccumulatorId,
  ]);

  const isDisable = (isCreate && modeCreateDiscountOffer !== 2) || (!isCreate && modeModifyDiscountOffer !== 2);
  return (
    <div className="col-md-12 mb-30">
      <form
        onSubmit={onHandlenSubmit}
        className={`needs-validation row card-body ${wasValidated ? 'was-validated' : ''}`}
        noValidate
        ref={formRef}
      >
        <div className="col-md-12 row">
          {discountTriggerListFields.map(item => (
            <GenericInput
              key={item.name}
              {...item}
              wrapperClass="col-md-4"
              value={dataSubmit[item.name]}
              onChange={onChange}
            />
          ))}
        </div>
        <div className="col-md-12 mt-2">
          {!isDisable && (
            <button type="submit" disabled={isDisable} className="button button-border x-small float-right">
              {t(isCreate ? 'label.create' : 'label.modify')}
            </button>
          )}
          <button
            type="button"
            className="button button-border black x-small mr-2 float-right"
            onClick={() => {
              if (onCancel) onCancel();
              setDataSubmit(data || {});
            }}
          >
            {t('label.cancel')}
          </button>
        </div>
      </form>
    </div>
  );
};
DiscountTriggerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  getAccumulatorsConfig: PropTypes.func.isRequired,
  getUsageContainerSpec: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  modeCreateDiscountOffer: PropTypes.number,
  modeModifyDiscountOffer: PropTypes.number,
};

DiscountTriggerForm.defaultProps = {
  isCreate: false,
  data: {},
  onCancel: null,
  modeCreateDiscountOffer: 0,
  modeModifyDiscountOffer: 0,
};

export default connect(null, { getAccumulatorsConfig, getUsageContainerSpec })(DiscountTriggerForm);
