import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['extractStatus', 'type'])}` : '';
  const searchEbsExtract = `searchEbsExtract(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchEbsExtract} {
        id
        ledgerId
        accountingDate
        currencyCode
        createdBy
        actualFlag
        category
        period
        source
        batchId
        batchName
        extractStatus
        type
        totalCreditAmount
        totalDebitAmount
        filePath
        createdDate
        modifiedDate
        count
        externalRefId
        inputDate
        merchant
        gatewayType
    }
  }
  `;
};
