import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { getDistinctUsageTypes } from '../../App/actions';
import RouteNames from '../../App/RouteNames';
import {
  getTrunkRulesConfig,
  modifyTrunkRulesConfig,
  createTrunkRulesConfig,
  getTenantInterConnectIndicator,
  getTenantTrafficType,
  getTenantTrunkType,
  getTenantUsageIndicator,
  searchTrunkRulesConfigList,
} from '../actions';
import { makeGetTrunkRules } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput, TablePagination } from '../../../components/common';
import { getPageTotalCount } from '../../../utils/utils';
import {
  checkPermissionCreateTrunkRuleConfig,
  checkPermissionModifyTrunkRuleConfig,
  checkPermissionGetTrunkRuleConfig,
} from '../CheckPermission';
import ModalOperatorMapList from '../../../components/common/ModalOperatorMapList';
import ModalAccountId from '../../../components/common/ModalAccountId';
import { TrunkRulesSearchForm } from '../../../components/BaseConfigurationsHub';

const sortTypes = {
  trunkGroup: {
    asc: 'trunkGroup_ASC',
    desc: 'trunkGroup_DESC',
  },
  trafficType: {
    asc: 'trafficType_ASC',
    desc: 'trafficType_DESC',
  },
  trunkType: {
    asc: 'trunkType_ASC',
    desc: 'trunkType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  interConnectIndicator: {
    asc: 'interConnectIndicator_ASC',
    desc: 'interConnectIndicator_DESC',
  },
  usageIndicator: {
    asc: 'usageIndicator_ASC',
    desc: 'usageIndicator_DESC',
  },
  carrierId: {
    asc: 'carrierId_ASC',
    desc: 'carrierId_DESC',
  },
};
class TrunkRules extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    listTrunkRules: [],
    isSearching: true,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    rateUnitConfig: [],
    interConnectIndicatorDropdown: [],
    tenantTrafficTypeDropdown: [],
    tenantTrunkDropdown: [],
    tenantUsageIndicatorDropdown: [],
    usageTypeList: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.listTrunkRules) return null;
    return {
      listTrunkRules: props.listTrunkRules,
      totalCount: getPageTotalCount({ ...state, items: props.listTrunkRules }),
      oldData: props.listTrunkRules,
      dataSubmit: [],
      isOnChange: false,
    };
  }

  componentDidMount() {
    this.doGetTrunkRulesConfig();
    this.doGetListDropdownConfig();
    const { getDistinctUsageTypes } = this.props;
    getDistinctUsageTypes('', ({ success, data }) => {
      if (success) {
        const usageTypeList = data.map(val => ({
          label: val.usageType,
          value: val.usageType,
        }));

        this.setState({ usageTypeList });
      }
    });
  }

  doGetListDropdownConfig = () => {
    const {
      getTenantInterConnectIndicator,
      getTenantTrafficType,
      getTenantTrunkType,
      getTenantUsageIndicator,
    } = this.props;

    getTenantInterConnectIndicator('', ({ data, success }) => {
      if (success)
        this.setState({ interConnectIndicatorDropdown: data && data.tenantDetailList ? data.tenantDetailList : [] });
    });
    getTenantTrafficType('', ({ data, success }) => {
      if (success)
        this.setState({ tenantTrafficTypeDropdown: data && data.tenantDetailList ? data.tenantDetailList : [] });
    });
    getTenantTrunkType('', ({ data, success }) => {
      if (success) this.setState({ tenantTrunkDropdown: data && data.tenantDetailList ? data.tenantDetailList : [] });
    });
    getTenantUsageIndicator('', ({ data, success }) => {
      if (success)
        this.setState({ tenantUsageIndicatorDropdown: data && data.tenantDetailList ? data.tenantDetailList : [] });
    });
  };

  onChangeTable = ({ name, value, index }) => {
    const { listTrunkRules, dataSubmit } = this.state;
    const newData = cloneDeep(listTrunkRules);
    newData[index][name] = value;
    this.setState({ listTrunkRules: newData });
  };

  // doGetTrunkRulesConfig = () => {
  //   const { getTrunkRulesConfig } = this.props;
  //   getTrunkRulesConfig(({ data }) => {
  //     this.setState({ isSearching: false, isModify: !!(data && data.id) });
  //   });
  // };

  doGetTrunkRulesConfig = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchTrunkRulesConfigList } = this.props;
    if (!filter.status) delete filter.status;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true, listTrunkRules: [] });
    searchTrunkRulesConfigList(payload, ({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.length) });
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetTrunkRulesConfig());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetTrunkRulesConfig());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetTrunkRulesConfig());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetTrunkRulesConfig());
  };

  onAddNewTrunkRules = () => {
    // let indexNewItem = 0;
    const { listTrunkRules, dataSubmit } = this.state;
    let newData = cloneDeep(listTrunkRules);
    // if (newData.length) {
    //   newData.forEach(item => {
    //     if (item.index > indexNewItem) {
    //       indexNewItem = item.index;
    //     }
    //   });
    // }
    const newDataItem = {
      index: -1,
      trunkGroup: '',
      site: '',
      trafficType: '',
      service: '',
      trunkType: '',
      accountId: '',
      interConnectIndicator: '',
      usageIndicator: '',
      carrierId: '',
      interConnectType: '',
      usageType: '',
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ listTrunkRules: newData, dataSubmit });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = ({ index }) => {
    try {
      const { listTrunkRules, dataSubmit } = this.state;
      const newData = cloneDeep(listTrunkRules);
      if (newData[index] && newData[index].index === -1) {
        newData.splice(index, 1);
      } else {
        newData[index] = {
          index: newData[index].index,
        };
      }
      this.setState({ listTrunkRules: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHanldeSubmit = () => {
    const { isModify, listTrunkRules } = this.state;
    const { modifyTrunkRulesConfig, createTrunkRulesConfig } = this.props;

    if (isModify) {
      const newPayloadModify = {
        trunkRulesConfigList: listTrunkRules.map(val => {
          const { id, ...rest } = val;
          return { ...rest };
        }),
        id: listTrunkRules ? listTrunkRules[0].id : null,
      };
      modifyTrunkRulesConfig(newPayloadModify, ({ success }) => {
        if (success) {
          this.doGetTrunkRulesConfig();
        }
      });
    } else {
      const dataRemoveIndex = cloneDeep(listTrunkRules).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        trunkRulesConfigList: dataRemoveIndex,
      };
      createTrunkRulesConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetTrunkRulesConfig();
        }
      });
    }
  };

  onToggleModal = () => {
    this.setState({ isOpen: false, itemSelected: null, indexSelect: null });
  };

  onOpenModal = ({ item, index }) => {
    this.setState({ isOpen: true, itemSelected: item, indexSelect: index });
  };

  onToggleModalAccountId = () => {
    this.setState({ isOpenModalAccountId: false, itemAccountSelected: null, indexSelect: null });
  };

  onOpenModalAccountId = ({ item, index }) => {
    this.setState({ isOpenModalAccountId: true, itemAccountSelected: item, indexSelect: index });
  };

  onSelect = id => {
    try {
      const { listTrunkRules, dataSubmit, itemSelected } = this.state;
      const newData = cloneDeep(listTrunkRules);
      const indexItemChange = newData.findIndex(item => item.index === itemSelected.index);
      const indexDataSubmit = dataSubmit.findIndex(item => item.index === itemSelected.index);
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit].carrierId = id;
      } else {
        dataSubmit.push({ index: itemSelected.index });
        dataSubmit[dataSubmit.length - 1].carrierId = id;
      }
      newData[indexItemChange].carrierId = id;
      this.setState({ listTrunkRules: newData, isOpen: false, itemSelected: null, indexSelect: null });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectAccountId = id => {
    try {
      const { listTrunkRules, dataSubmit, itemAccountSelected } = this.state;
      const newData = cloneDeep(listTrunkRules);
      const indexItemChange = newData.findIndex(item => item.index === itemAccountSelected.index);
      const indexDataSubmit = dataSubmit.findIndex(item => item.index === itemAccountSelected.index);
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit].accountId = id;
      } else {
        dataSubmit.push({ index: itemAccountSelected.index });
        dataSubmit[dataSubmit.length - 1].accountId = id;
      }
      newData[indexItemChange].accountId = id;
      this.setState({
        listTrunkRules: newData,
        isOpenModalAccountId: false,
        itemAccountSelected: null,
        indexSelect: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      listTrunkRules,
      isSearching,
      isOpenModalRemoveItem,
      interConnectIndicatorDropdown,
      tenantTrafficTypeDropdown,
      tenantTrunkDropdown,
      tenantUsageIndicatorDropdown,
      isOpen,
      itemSelected,
      page,
      size,
      totalCount,
      sorted,
      isOpenModalAccountId,
      usageTypeList,
    } = this.state;
    const { t, permissionsPricing } = this.props;
    let modeCreateTrunkRules = 0;
    let modeModifyTrunkRules = 0;
    let modeGetTrunkRules = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateTrunkRules = checkPermissionCreateTrunkRuleConfig({ listPermission: listPermissionPricing });
      modeModifyTrunkRules = checkPermissionModifyTrunkRuleConfig({ listPermission: listPermissionPricing });
      modeGetTrunkRules = checkPermissionGetTrunkRuleConfig({ listPermission: listPermissionPricing });
    }
    if (!modeGetTrunkRules) return '';
    const creditProfileColumns = [
      {
        name: 'trunkGroup',
        label: 'label.trunkGroup',
        sortable: true,
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.trunkGroup}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="trunkGroup"
          />
        ),
      },
      {
        name: 'site',
        label: 'label.site',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.site}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="site"
          />
        ),
      },
      {
        name: 'trafficType',
        label: 'label.trafficType',
        sortable: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.trafficType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="trafficType"
            type="select"
            menuPortalTarget
            options={
              tenantTrafficTypeDropdown && tenantTrafficTypeDropdown.length
                ? sortBy(
                    tenantTrafficTypeDropdown.map(val => ({ label: val.value, value: val.name })),
                    ['label']
                  )
                : []
            }
          />
        ),
      },
      {
        name: 'service',
        label: 'label.service',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.service}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="service"
          />
        ),
      },
      {
        name: 'trunkType',
        label: 'label.trunkType',
        sortable: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.trunkType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="trunkType"
            type="select"
            menuPortalTarget
            options={
              tenantTrunkDropdown && tenantTrunkDropdown.length
                ? sortBy(
                    tenantTrunkDropdown.map(val => ({ label: val.value, value: val.name })),
                    ['label']
                  )
                : []
            }
          />
        ),
      },
      {
        name: 'accountId',
        label: 'label.accountId',
        sortable: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.accountId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountId"
            fa="fa fa-external-link"
            onClick={() => this.onOpenModalAccountId({ item, index: idx })}
          />
        ),
      },
      {
        name: 'interConnectIndicator',
        label: 'label.interConnectIndicator',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.interConnectIndicator}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="interConnectIndicator"
            type="select"
            menuPortalTarget
            options={
              interConnectIndicatorDropdown && interConnectIndicatorDropdown.length
                ? sortBy(
                    interConnectIndicatorDropdown.map(val => ({ label: val.value, value: val.name })),
                    ['label']
                  )
                : []
            }
          />
        ),
      },
      {
        name: 'usageIndicator',
        label: 'label.usageIndicator',
        sortable: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.usageIndicator}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="usageIndicator"
            type="select"
            menuPortalTarget
            options={
              tenantUsageIndicatorDropdown && tenantUsageIndicatorDropdown.length
                ? sortBy(
                    tenantUsageIndicatorDropdown.map(val => ({ label: val.value, value: val.name })),
                    ['label']
                  )
                : []
            }
          />
        ),
      },
      {
        name: 'carrierId',
        label: 'label.carrierId',
        sortable: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.carrierId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="carrierId"
            fa="fa fa-external-link"
            onClick={() => this.onOpenModal({ item, index: idx })}
          />
        ),
      },
      {
        name: 'interConnectType',
        label: 'label.interConnectType',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.interConnectType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="interConnectType"
          />
        ),
      },
      {
        name: 'usageType',
        label: 'label.usageType',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.usageType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="usageType"
            type="select"
            options={usageTypeList}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: idx })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: `${t('label.service')} - ${t('baseConfigurationsPage:sidebar.trunkRule')}` },
          ]}
          isNoDefaultButton
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <TrunkRulesSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormWithTableItem
          title={`${t('label.service')} - ${t('baseConfigurationsPage:sidebar.trunkRule')}`}
          subClass="border-bottom"
        >
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateTrunkRules === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={this.onAddNewTrunkRules}
              >
                +
                {t('label.addNewTrunkRules')}
              </button>
            )}
            {modeModifyTrunkRules === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetTrunkRulesConfig()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              isSupportRemoveIndex
              columns={creditProfileColumns}
              data={listTrunkRules}
              isLoading={isSearching}
              sorted={sorted}
              onSort={this.onSortColumn}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>

        <ModalOperatorMapList
          isOpen={isOpen}
          onSelect={this.onSelect}
          onCancel={this.onToggleModal}
          selectedParentId={
            this.state && this.state.itemSelected && this.state.itemSelected.carrierId
              ? this.state.itemSelected.carrierId
              : ''
          }
        />
        <ModalAccountId
          isOpen={isOpenModalAccountId}
          onSelect={this.onSelectAccountId}
          onCancel={this.onToggleModalAccountId}
          selectedParentId={
            this.state && this.state.itemAccountSelected && this.state.itemAccountSelected.accountId
              ? this.state.itemAccountSelected.accountId
              : ''
          }
        />
      </div>
    );
  }
}

TrunkRules.propTypes = {
  getTrunkRulesConfig: PropTypes.func.isRequired,
  modifyTrunkRulesConfig: PropTypes.func.isRequired,
  createTrunkRulesConfig: PropTypes.func.isRequired,
  listTrunkRules: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  permissionsPricing: PropTypes.object,
};

TrunkRules.defaultProps = {
  listTrunkRules: [],
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  listTrunkRules: makeGetTrunkRules() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getTrunkRulesConfig,
    modifyTrunkRulesConfig,
    createTrunkRulesConfig,
    getTenantInterConnectIndicator,
    getTenantTrafficType,
    getTenantTrunkType,
    getTenantUsageIndicator,
    searchTrunkRulesConfigList,
    getDistinctUsageTypes,
  })(TrunkRules)
);
