import gql from 'graphql-tag';

export default () => {
  const getGlAccount = 'getGlAccount(dummy: "")';
  return gql`
    query {
      ${getGlAccount} {
        id
        delimiter
        segments {
          index
          length
          leadingZeroes
          name
          segmentValues {
            index
            name
            code
          }
        }
        accountRanges {
          index
          startingNumber
          accountType
          subAccountRanges {
            index
            startingNumber
            accountSubType
          }
        }
      }
    }
  `;
};
