export default {
  sidebar: {
    newQuote: 'New Quote',
    searchList: 'Search & List',
    modifyQuote: 'Modify Quote',
    upgradeQuote: 'Upgrade Quote',
    downgradeQuote: 'Downgrade Quote',
    searchQuote: 'Search Quote',
  },
};
