import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput, ModalWithItem, DataTable } from '../../../components/common';

const ModalTaskDetailsForm = ({
  listFields,
  data,
  onChangeData,
  onClickItem,
  onSubmit,
  onToggleModal,
  isOpenModal,
  title,
  modeGetChildTask,
  doGetChildTasksByParentId,
  doSyncTaskToExternal,
  modeCreateChildTask,
  onToggleModalCreateChildTask,
  tableTaskHistory,
  modeSyncTaskToExternal,
}) => {
  const { t } = useTranslation('common');

  return (
    <ModalWithItem
      wrapperClass="modal-70 modal-custom"
      modalTitle={t(title)}
      isOpen={isOpenModal}
      onToggle={onToggleModal}
    >
      <div className="col-md-12 row">
        {listFields.map(item => {
          if (item.name === 'ticketId') {
            return (
              <GenericInput
                {...item}
                value={data[item.name] || null}
                onClick={item.fa ? () => onClickItem(item.name) : null}
                onChange={onChangeData}
                labelRightButton="label.create"
                isBtnRight={!data[item.name]}
                disabled={item.readOnly || item.isNonModify || item.disabled}
                readOnly={item.readOnly || item.isNonModify || item.disabled}
                onClickRightButton={() => doSyncTaskToExternal({ item: data })}
                isDisableRightButton={!modeSyncTaskToExternal}
              />
            );
          }
          return (
            <GenericInput
              {...item}
              value={data[item.name] || null}
              readOnly={!!item.fa || item.readOnly || item.isNonModify || item.disabled}
              disabled={item.readOnly || item.isNonModify || item.disabled}
              onClick={item.fa ? () => onClickItem(item.name) : null}
              onChange={onChangeData}
            />
          );
        })}
      </div>
      <div className="col-md-12 mb-3 row">
        <div className="text-center mt-3 mb-3 col-md-12">
          {!!modeGetChildTask && (
            <button
              type="button"
              className="button x-small float-right mr-2"
              onClick={doGetChildTasksByParentId}
              disabled={modeGetChildTask === 1}
            >
              {t('label.viewChildTasks')}
            </button>
          )}
          {modeCreateChildTask === 2 && (
            <button
              type="button"
              className="button x-small float-right mr-2"
              onClick={onToggleModalCreateChildTask}
            >
              {t('label.createChildTask')}
            </button>
          )}
          <button type="button" className="button x-small float-right" onClick={onSubmit}>
            {t('label.modify')}
          </button>
          <button type="button" className="button button-border black x-small mr-2 float-right" onClick={onToggleModal}>
            {t('label.cancel')}
          </button>
        </div>
      </div>
      <div className="col-md-12 border-top mb-4">
        <h6 className="mt-2 mb-2">{t('label.taskHistory')}</h6>
        <DataTable columns={tableTaskHistory} data={data?.audits || []} />
      </div>
    </ModalWithItem>
  );
};
ModalTaskDetailsForm.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  listFields: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  isOpenModal: PropTypes.bool,
  title: PropTypes.string,
  modeGetChildTask: PropTypes.number,
  modeCreateChildTask: PropTypes.number,
  doGetChildTasksByParentId: PropTypes.func,
  doSyncTaskToExternal: PropTypes.func,
  onToggleModalCreateChildTask: PropTypes.func,
  tableTaskHistory: PropTypes.arrayOf(PropTypes.any),
  modeSyncTaskToExternal: PropTypes.number,
};

ModalTaskDetailsForm.defaultProps = {
  listFields: [],
  data: {},
  isOpenModal: false,
  title: '',
  modeGetChildTask: 0,
  modeCreateChildTask: 0,
  doGetChildTasksByParentId: () => {},
  doSyncTaskToExternal: () => {},
  onToggleModalCreateChildTask: () => {},
  tableTaskHistory: [],
  modeSyncTaskToExternal: 0,
};

export default ModalTaskDetailsForm;
