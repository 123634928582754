import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Switch, GenericInput } from '../../../common';
import ProcessingStreamsChildrenForm from './ProcessingStreamsChildrenForm';

const HandleParent = SortableHandle(({ onOnHoverDrag, onMoveDrag }) => (
  <button
    type="button"
    className="sortable-handle-icon no-border"
    onMouseEnter={onOnHoverDrag}
    onMouseLeave={onMoveDrag}
  >
    <i className="fa fa-bars" />
  </button>
));

const SortableItemParent = SortableElement(
  ({ data, onUpdateProcessingValue, onChangeRating, onChangeSize, isFullSize }) => {
    const { t } = useTranslation('common');
    return (
      <div className="col-md-12 mb-1 ml-2 row box-shadow p-1">
        {/* <div className="col-md-2 row ml-3">
          <HandleParent />
        </div> */}

        <div
          className="col-md-12 row mr-2 justify-content-center"
          // className={`col-md-12 row mr-2 justify-content-center ${isFullSize ? 'full-screen' : 'close-item'}`}
          // style={{ zIndex: 999999999 }}
        >
          <GenericInput
            value={data.ratingType}
            label={t('label.ratingType')}
            type="select"
            wrapperClass="col-md-3"
            onChange={({ name, value }) => onChangeRating({ name, value, index: data.index })}
            tOptions="selections:recordIndicator"
            name="ratingType"
            menuPortalTarget
            required
          />
          <Switch
            title={t('label.active')}
            checked={data.active === 'true' || data.active === true}
            onChange={evt => onChangeRating({ name: 'active', value: evt.target.checked, index: data.index })}
            wrapperClass="switch-stream"
            name={`rating-type-${data.index}`}
          />
        </div>

        {/* <div className="col-md-2 pt-3 no-padding">
          <button type="button" className="btn-phone float-right" onClick={onChangeSize}>
            {isFullSize ? <i className="fa fa-minus-circle" aria-hidden="true" /> : <i className="fa fa-plus-circle" />}
          </button>
        </div> */}
        {/* <span className={`item-stream ${isFullSize ? 'full-screen' : 'close-item'}`}>
          <ProcessingStreamsChildrenForm
            data={data.usageProcessStreamSeqCfg}
            onChangeSize={onChangeSize}
            isFullSize={isFullSize}
            onUpdateProcessingValue={({ dataProcess }) => onUpdateProcessingValue({ dataProcess, index: data.index })}
          />
        </span> */}
        <ProcessingStreamsChildrenForm
          data={data.usageProcessStreamSeqCfg}
          // onChangeSize={onChangeSize}
          // isFullSize={isFullSize}
          onUpdateProcessingValue={({ dataProcess }) => onUpdateProcessingValue({ dataProcess, index: data.index })}
        />

        {/* <div className={`col-md-12 row justify-content-center mt-3 ${isFullSize ? 'full-screen' : 'close-item'}`}>
          <button
            type="button"
            className={`${isFullSize ? 'button gray x-small mb-2 button-border' : 'no-border'}`}
            onClick={() => {}}
          >
            {t('label.addAnotherRatingType')}
          </button>
        </div> */}
      </div>
    );
  }
);

const SortableListParent = SortableContainer(({ items, ...props }) => {
  return (
    <div className="row" style={{ zIndex: -1 }}>
      {items.map((data, index) => (
        <SortableItemParent index={index} indexItem={index} data={data} key={`item-${index}`} {...props} />
      ))}
    </div>
  );
});

class ProcessingStreamsParentForm extends React.Component {
  state = { data: [], isFullSize: true };

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.data) {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  // onChangeSize = () => {
  //   const { isFullSize } = this.state;
  //   console.log('onChangeSize');
  //   this.setState({ isFullSize: !isFullSize });
  // };

  render() {
    const { data, isFullSize } = this.state;
    const { ...rest } = this.props;
    return (
      <SortableListParent
        items={[data]}
        useDragHandle
        // onChangeSize={this.onChangeSize}
        {...rest}
        isFullSize={isFullSize}
      />
    );
  }
}

ProcessingStreamsParentForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  // onUpdateProcessingValue: PropTypes.func,
};

ProcessingStreamsParentForm.defaultProps = {
  data: [],
  // onUpdateProcessingValue: () => {},
};

export default ProcessingStreamsParentForm;
