import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, sortBy, uniqBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPageTotalCount } from '../../../utils/utils';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { TablePagination, DataTable, GenericInput } from '../../common';
import ProvisioningDataSearchForm from '../ProvisioningDataSearchForm';
import {
  searchServiceProvisioningData,
  getProvisioningInstanceData,
  updateProvisioningData,
} from '../../../containers/App/actions';

const provisioningDataSort = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
};

const listFlexAttributes = [
  {
    name: 'flexAttr1',
    label: 'FLEXATTR1',
  },
  {
    name: 'flexAttr2',
    label: 'FLEXATTR2',
  },
  {
    name: 'flexAttr3',
    label: 'FLEXATTR3',
  },
  {
    name: 'flexAttr4',
    label: 'FLEXATTR4',
  },
  {
    name: 'flexAttr5',
    label: 'FLEXATTR5',
  },
  {
    name: 'flexAttr6',
    label: 'FLEXATTR6',
  },
  {
    name: 'flexAttr7',
    label: 'FLEXATTR7',
  },
  {
    name: 'flexAttr8',
    label: 'FLEXATTR8',
  },
  {
    name: 'flexAttr9',
    label: 'FLEXATTR9',
  },
  {
    name: 'flexAttr10',
    label: 'FLEXATTR10',
  },
  {
    name: 'flexAttr11',
    label: 'FLEXATTR11',
  },
  {
    name: 'flexAttr12',
    label: 'FLEXATTR12',
  },
];

class ProvisioningData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: { accountId: props.id },
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      isOpenModal: false,
      isSearchDataModal: false,
      itemData: {},
      activeTab: {
        // name: 'Recurring Data',
        // isActive: true,
      },
      dataResponse: null,
      isFirstSearch: true,
      data: [],
      instanceOptions: [],
      optionsSelectAttr: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.data }),
    };
  }

  componentDidMount() {
    this.doSearchProvisioningData();
    const { getProvisioningInstanceData, id } = this.props;
    getProvisioningInstanceData({ accountId: id }, ({ success, data }) => {
      if (success && data) {
        const instanceOptions = data.map(val => ({
          label: `${val.instanceId}: ${val.serviceName}`,
          value: val.instanceId,
        }));

        this.setState({ instanceOptions });
      }
    });
    this.doGetFlexAttributesMappingConfig();
  }

  doGetFlexAttributesMappingConfig = () => {
    const { optionsSelectAttr } = this.state;
    const { getFlexAttributesMappingConfig } = this.props;
    if (!optionsSelectAttr || !optionsSelectAttr.length)
      getFlexAttributesMappingConfig({ objectType: 'SERVICE' }, ({ success, data }) => {
        if (success) {
          const optionsList =
            data && data.types && data.types.length
              ? data.types.map(val => ({ value: val.provAttributeType, label: val.provAttributeType }))
              : [];

          this.setState({ optionsSelectAttr: optionsList ? uniqBy(sortBy(optionsList, ['label']), 'label') : [] });
        }
      });
  };

  doSearchProvisioningData = () => {
    const { searchServiceProvisioningData } = this.props;
    const { page, size, filter, sorted, isFirstSearch } = this.state;
    if (!filter || !filter.serviceType) filter.serviceType = 'VOICE';
    // if (!filter || !filter.type) filter.type = 'TRUNK';
    if ((!filter || !filter.status) && isFirstSearch) filter.status = 'ACTIVE';
    searchServiceProvisioningData(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? provisioningDataSort[sorted.sortCol][sorted.sortDir] : null,
      },
      resp => {
        const newResp = resp;
        const flexAttrsList = {};
        if (
          newResp.success &&
          newResp.data &&
          newResp.data.types &&
          newResp.data.types.length &&
          newResp.data.types[0].flexAttrMapping
        ) {
          newResp.data.types[0].flexAttrMapping.forEach(val => {
            flexAttrsList[val.flexAttribute] = val.tag;
          });
        }
        if (newResp.data && newResp.data.provisioning && newResp.data.provisioning[0]) {
          newResp.data.provisioning[0] = { ...newResp.data.provisioning[0], ...flexAttrsList };
          this.setState({
            dataResponse: newResp.data.provisioning[0],
            data: newResp.data.provisioning,
            isFirstSearch: false,
          });
        } else {
          this.setState({ dataResponse: null, data: [], isFirstSearch: false });
        }
      }
    );
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filter: newFilter }, () => this.doSearchProvisioningData());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchProvisioningData());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchProvisioningData());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchProvisioningData());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  onChangeTable = ({ index, name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[index][name] = value;
    newData[index].isOnchange = true;
    this.setState({ data: newData });
  };

  onHandleSave = idx => {
    try {
      const { updateProvisioningData } = this.props;
      const { data } = this.state;
      const newData = cloneDeep(data);
      updateProvisioningData({
        provisioningId: newData[idx].provisioningId,
        instanceId: newData[idx].instanceId,
        id: newData[idx].id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { id, t, currencyOptions, customerInfo } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      dataResponse,
      data,
      instanceOptions,
      optionsSelectAttr,
    } = this.state;

    const columns = [
      {
        name: 'action',
        label: 'common:label.action',
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <button
            type="button"
            className="button button-border x-small"
            onClick={evt => this.onHandleSave(idx)}
            disabled={!item.isOnchange}
          >
            {t('label.submit')}
          </button>
        ),
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'type',
        label: 'common:label.type',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:typeProvisioning')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        sortable: true,
      },
      {
        name: 'instanceId',
        label: 'common:label.instanceId',
        style: { minWidth: '300px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.instanceId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="instanceId"
            type="select"
            options={instanceOptions || []}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
        sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
    ];

    if (dataResponse) {
      listFlexAttributes.forEach(att => {
        columns.push({
          name: att.name,
          label: dataResponse[att.label],
        });
      });
    }
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('customerPage:sidebarHandleAccountPage.provisioningData')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <ProvisioningDataSearchForm
              onSubmit={this.onHandleSubmit}
              defaultFilter={{ status: 'ACTIVE' }}
              keyOptionInject="type"
              optionsInject={optionsSelectAttr}
            />
            <br />
            <div>
              <DataTable
                columns={columns}
                data={data || []}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

ProvisioningData.propTypes = {
  ProvisioningData: PropTypes.arrayOf(PropTypes.any),
  searchServiceProvisioningData: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ProvisioningData.defaultProps = {
  id: '',
  ProvisioningData: [],
};

const newProvisioningData = connect(mapStateToProps, {
  searchServiceProvisioningData,
  getProvisioningInstanceData,
  updateProvisioningData,
})(ProvisioningData);

export default withTranslation(['common', 'selections'])(newProvisioningData);
