import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../components/PricingHub/SearchComponent';

class ProvisioningAttributesMapSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.name',
      name: 'name',
    },
  ];

  btnSpan = 'col-md-9';
}

ProvisioningAttributesMapSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ProvisioningAttributesMapSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ProvisioningAttributesMapSearchForm);
