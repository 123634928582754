import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getPACProductCodesConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import SearchForm from './SearchForm';

class ModalSelectPACProductCodes extends Component {
  state = {};

  render() {
    const {
      t,
      defaultSearchParams,
      isOpen,
      getPACProductCodesConfig,
      onSelect,
      onCancel,
      selectedParentId,
    } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
        },
        {
          name: 'description',
          label: 'label.description',
        },
        {
          name: 'startDate',
          label: 'label.startDate',
        },
        {
          name: 'endDate',
          label: 'label.endDate',
        },
        {
          name: 'includeIva',
          label: 'label.includeIva',
        },
        {
          name: 'includeIeps',
          label: 'label.includeIeps',
        },
        {
          name: 'complement',
          label: 'label.complement',
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={t('label.chooseProductCodes')}
        isOpen={isOpen}
        SearchForm={SearchForm}
        searchItems={getPACProductCodesConfig}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalSelectPACProductCodes.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  getPACProductCodesConfig: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default withTranslation('common')(connect(null, { getPACProductCodesConfig })(ModalSelectPACProductCodes));
