import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import InputGroup from '../InputGroup';
import GenericInput from '../GenericInput';
import DateGroup from '../DateGroup';
import SubmitForm from '../SubmitForm';

class SearchForm extends Component {
  state = {};

  componentDidMount() {
    const { searchInputs, searchSelectors, dateSelector } = this.props;
    searchInputs.map(e => this.setState({ [e.name]: '' }));
    searchSelectors.map(e => this.setState({ [e.name]: null }));
    dateSelector.map(e => this.setState({ [e.name]: null }));
  }

  handleInputChange = e => {
    const {
      target: { name, value },
    } = e;
    const { onValueChange } = this.props;
    this.setState({ [name]: value });
    onValueChange(name, value);
  };

  handleSelectChange = (name, e) => {
    const value = e && e.value ? e.value : null;
    const { onValueChange } = this.props;
    this.setState({ [name]: value });
    onValueChange(name, value);
  };

  handleDateChange = (name, e) => {
    this.setState({ [name]: e || '' });
    const { onValueChange } = this.props;
    onValueChange(name, e);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit();
    }
  };

  handleClickClearButton = () => {
    const { searchInputs, searchSelectors, dateSelector, onClickClearButton, filter } = this.props;
    const { onValueChange } = this.props;
    searchInputs.forEach(item => {
      this.setState({ [item.name]: '' });
      onValueChange(item.name, '');
    });
    searchSelectors.forEach(item => {
      this.setState({ [item.name]: '' });
      onValueChange(item.name, '');
    });
    dateSelector.forEach(item => {
      this.setState({ [item.name]: '' });
      onValueChange(item.name, '');
    });
    onClickClearButton({ subscriptionStatus: filter.subscriptionStatus });
  };

  render() {
    const { isSubmitting, t } = this.props;
    const { searchInputs, searchSelectors, dateSelector, isHaveClearButton, filter } = this.props;
    return (
      <SubmitForm
        onSubmit={this.handleSubmit}
        isSubmitting={isSubmitting}
        isHaveClearButton={isHaveClearButton}
        onClickClearButton={this.handleClickClearButton}
        submitText={t('label.search')}
        btnSearchSpan="col-md-12"
        t={t}
        isNoNeedPermission
      >
        {searchInputs.map(item => (
          <InputGroup key={item.name} {...item} value={this.state[item.name]} onChange={this.handleInputChange} />
        ))}
        {searchSelectors.map(item => (
          <GenericInput
            key={item.name}
            {...item}
            value={this.state[item.name] || filter[item.name]}
            onChange={e => this.handleSelectChange(item.name, e)}
          />
        ))}
        {dateSelector.map(item => (
          <DateGroup
            key={item.name}
            {...item}
            value={this.state[item.name]}
            onChange={e => this.handleDateChange(item.name, e)}
            isClearable
          />
        ))}
      </SubmitForm>
    );
  }
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func,
  onClickClearButton: PropTypes.func,
  onValueChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isHaveClearButton: PropTypes.bool,
  searchInputs: PropTypes.arrayOf(PropTypes.object),
  searchSelectors: PropTypes.arrayOf(PropTypes.object),
  dateSelector: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.objectOf(PropTypes.any),
};

SearchForm.defaultProps = {
  isSubmitting: false,
  isHaveClearButton: false,
  onSubmit: () => {},
  onClickClearButton: () => {},
  onValueChange: () => {},
  searchInputs: [],
  searchSelectors: [],
  dateSelector: [],
  filter: {},
};

export default withTranslation('common')(SearchForm);
