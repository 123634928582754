import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BreadcrumbItem = ({ url, name }) => {
  if (url) {
    return (
      <li className="breadcrumb-item">
        <Link to={url} className="default-color">
          {name}
        </Link>
      </li>
    );
  }
  return <li className="breadcrumb-item active">{name}</li>;
};

const Breadcrumb = ({ items = [] }) => {
  const { t } = useTranslation('common');
  return (
    <ol className="breadcrumb pt-0 float-left float-sm-right">
      <li className="breadcrumb-item">
        <Link to="/">{t ? t('label.home') : 'Home'}</Link>
      </li>
      {items.map((item, idx) => (
        <BreadcrumbItem {...item} key={idx} />
      ))}
    </ol>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
};

Breadcrumb.defaultProps = {
  items: [],
};

export default Breadcrumb;
