export default {
  customerSegment: {
    b2b: 'B2B',
    b2c: 'B2C',
    b2b2c: 'B2B2C',
  },
  accountReason: {
    none: 'NONE',
    paymentFailure: 'PAYMENT_FAILURE',
    customerRequest: 'CUSTOMER_REQUEST',
    collections: 'COLLECTIONS',
    internalControl: 'INTERNAL_CONTROL',
  },
  accountStatus: {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    closed: 'CLOSED',
  },
  type: {
    directCustomer: 'DIRECT_CUSTOMER',
    partner: 'PARTNER',
    parterCustomer: 'PARTNER_CUSTOMER',
    reseller: 'RESELLER',
    resellerCustomer: 'RESELLER_CUSTOMER',
    internalTestAccount: 'INTERNAL_TEST_ACCOUNT',
    externalTestAccount: 'EXTERNAL_TEST_ACCOUNT',
    brokerChannelPartner: 'BROKER_CHANNEL_PARTNER',
    dataSupplier: 'DATA_SUPPLIER',
    interCompany: 'INTERCOMPANY',
    connectorPartner: 'CONNECTOR_PARTNER',
    affiliate: 'AFFILIATE',
    none: 'NONE',
  },
  subType: {
    silever: 'SILVER',
    gold: 'GOLD',
    platinum: 'PLATINUM',
    none: 'NONE',
  },
  salesChannel: {
    web: 'WEB',
    pos: 'POS',
    ecommerce: 'ECOMMERCE',
    advertisement: 'ADVERTISEMENT',
    teleMarket: 'TELE_MARKET',
    agentCare: 'AGENT_CARE',
    none: 'NONE',
  },
  marketSegment: {
    individal: 'INDIVIDUAL',
    smallEnterprise: 'SMALL_ENTERPRISE',
    smb: 'SMB',
    largeEnterprise: 'LARGE_ENTERPRISE',
    none: 'NONE',
  },
  // -----contact
  roles: {
    billing: 'BILLING',
    payment: 'PAYMENT',
    servide: 'SERVICE',
    soldTo: 'SOLD_TO',
  },
  userCategory: {
    agent: 'AGENT',
    customer: 'CUSTOMER',
  },
  // salutation: {
  //   mr: 'Mr.',
  //   mss: 'Mss.',
  //   mrs: 'Mrs.',
  // },
  phoneType: {
    work: 'WORK',
    home: 'HOME',
    mobile: 'MOBILE',
    fax: 'FAX',
  },
  // -----billing profile
  billingFrequency: {
    monthly: 'MONTHLY',
    biMonthly: 'BI_MONTHLY',
    quarterly: 'QUARTERLY',
    semiAnnual: 'SEMI_ANNUAL',
    annual: 'ANNUAL',
  },
  invoiceType: {
    summary: 'SUMMARY',
    detail: 'DETAIL',
    dteailSuppress: 'DETAIL_SUPPRESS',
  },
  invoiceDelivery: {
    download: 'DOWNLOAD',
    email: 'EMAIL',
    mail: 'MAIL',
  },
  // ----- payment profile
  paymentTerm: {
    net30: 'NET_30',
    net45: 'NET_45',
    net60: 'NET_60',
    net75: 'NET_75',
    net90: 'NET_90',
  },
  paymentMethod: {
    check: 'CHECK',
    creditCard: 'CREDIT_CARD',
    nonPaying: 'NON_PAYING',
  },

  // ar activity
  arType: {
    billAdjustment: 'BILL_ADJUSTMENT',
    transactionAdjustment: 'TRANSACTION_ADJUSTMENT',
    billDispute: 'BILL_DISPUTE',
    transactionDispute: 'TRANSACTION_DISPUTE',
    billSettlement: 'BILL_SETTLEMENT',
    accountWriteOff: 'ACCOUNT_WRITEOFF',
    invoiceWriteOff: 'INVOICE_WRITEOFF',
    transactionSettlement: 'TRANSACTION_SETTLEMENT',
    writeOff: 'WRITE_OFF',
    writeOffReversal: 'WRITE_OFF_REVERSAL',
    payment: 'PAYMENT',
    paymentReversal: 'PAYMENT_REVERSAL',
    refund: 'REFUND',
    chargeback: 'CHARGEBACK',
    tax: 'TAX',
    taxAdjustment: 'TAX_ADJUSTMENT',
    taxSettlement: 'TAX_SETTLEMENT',
    taxDispute: 'TAX_DISPUTE',
    taxWriteOff: 'TAX_WRITEOFF',
    taxWriteOffReversal: 'TAX_WRITEOFF_REVERSAL',
  },

  arTaxRule: {
    withTax: 'WITH_TAX',
    withoutTax: 'WITHOUT_TAX',
    taxOnly: 'TAX_ONLY',
  },
  arCrDrType: {
    credit: 'CREDIT',
    debit: 'DEBIT',
  },

  // customer activity
  source: {
    external: 'EXTERNAL',
    selfCare: 'SELF_CARE',
    agentCare: 'AGENT_CARE',
    coreJobs: 'CORE_JOBS',
    payment: 'PAYMENT',
    paymentSuspense: 'PAYMENT_SUSPENSE',
  },
  entityType: {
    order: 'ORDER',
    customer: 'CUSTOMER',
    contact: 'CONTACT',
    address: 'ADDRESS',
    item: 'ITEM',
    paymentProfile: 'PAYMENT_PROFILE',
    billingProfile: 'BILLING_PROFILE',
    priceOffer: 'PRICE_OFFER',
    bundle: 'BUNDLE',
    package: 'PACKAGE',
    dependency: 'DEPENDENCY',
    arOps: 'AROPS',
    identity: 'IDENTITY',
    tax: 'TAX',
    payment: 'PAYMENT',
    collection: 'COLLECTION',
    integration: 'INTEGRATION',
    paymentSuspense: 'PAYMENT_SUSPENSE',
    paymentReversal: 'PAYMENT_REVERSAL',
  },

  // Pricing Manager
  revenueType: {
    transaction: 'TRANSACTION',
    subscription: 'SUBSCRIPTION',
    professionalService: 'PROFESSIONAL_SERVICES',
    laborExpense: 'LABOR_EXPENSE',
    laborTime: 'LABOR_TIME',
    license: 'LICENSE',
  },
  revenueRecognitionType: {
    immediate: 'IMMEDIATE',
    deferred: 'DEFERRED',
    revenueOnDeferralAuto: 'REVENUE_ON_DEFERRAL_AUTO',
    revenueOnDeferralManual: 'REVENUE_ON_DEFERRAL_MANUAL',
    dailyStraightLineAmortization: 'DAILY_STRAIGHT_LINE_AMORTIZATION',
    monthlyStraightLineAmortization: 'MONTHLY_STRAIGHT_LINE_AMORTIZATION',
    trueUp: 'TRUE_UP',
  },
  status: {
    create: 'CREATE',
    approve: 'APPROVED',
    sellable: 'SELLABLE',
    nonSellable: 'NON_SELLABLE',
    sunset: 'SUNSET',
  },
  unit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarterly: 'QUARTERS',
    years: 'YEARS',
    unbound: 'UNBOUND',
  },
  grantUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarterly: 'QUARTERS',
    years: 'YEARS',
  },
  pricingModel: {
    flat: 'FLAT',
    // flatWithGrants: 'FLAT_WITH_GRANTS',
    tiered: 'TIERED',
    recurring: 'RECURRING',
    // recurringWithGrants: 'RECURRING_WITH_GRANTS',
    recurringTiered: 'RECURRING_TIERED',
    transactionAttributeBased: 'TRANSACTION_ATTRIBUTE_BASED',
    customerAttributeBased: 'CUSTOMER_ATTRIBUTE_BASED',
    usageAttributeBase: 'USAGE_ATTRIBUTE_BASED',
    complex: 'COMPLEX',
  },
  transactionType: {
    oneTime: 'ONE_TIME',
    oneTimeCancel: 'ONE_TIME_CANCEL',
    forwardRecurring: 'FORWARD_RECURRING',
    arrearsRecurring: 'ARREARS_RECURRING',
    usage: 'USAGE',
    billDiscount: 'BILL_DISCOUNT',
    tax: 'TAX',
  },
  serviceType: {
    default: 'DEFAULT',
    voip: 'VOIP',
    cable: 'CABLE',
    utilities: 'UTILITIES',
    retail: 'RETAIL',
    wholesale: 'WHOLESALE',
    wireline: 'WIRELINE',
    telephony: 'TELEPHONY',
    iptv: 'IPTV',
    data: 'DATA',
    professional: 'PROFESSIONAL',
    internet: 'INTERNET',
    homesecurity: 'HOMESECURITY',
  },
  proration: {
    full: 'FULL',
    zero: 'ZERO',
    prorate: 'PRORATE',
  },
  purchaseProration: 'purchaseProration',
  cancelProration: 'cancelProration',
  upgradeProration: 'upgradeProration',
  downgradeProration: 'downgradeProration',
  accountType: 'accountType',
  accountSubType: 'accountSubType',
  dependencyType: {
    mutuallyExclusive: 'MUTUALLY_EXCLUSIVE',
    preRequisite: 'PRE_REQUISITE',
    upgrade: 'UPGRADE',
    downgrade: 'DOWNGRADE',
  },
  billType: {
    regular: 'REGULAR',
    oneTime: 'ONE_TIME',
    regenenrated: 'REGENERATED',
  },
  billStatus: {
    pending: 'PENDING',
    active: 'ACTIVE',
  },
  // payment
  paymentProvider: {
    brainTree: 'BRAINTREE',
  },
  // orders
  orderType: {
    new: 'NEW',
    modify: 'MODIFY',
    suspend: 'SUSPEND',
    resume: 'RESUME',
    cancel: 'CANCEL',
    renew: 'RENEW',
    optIn: 'OPT_IN',
    optOut: 'OPT_OUT',
    upgrade: 'UPGRADE',
    downgrade: 'DOWNGRADE',
  },
  orderStatus: {
    create: 'CREATED',
    pending: 'PENDING',
    cancelled: 'CANCELLED',
    submitted: 'SUBMITTED',
    approved: 'APPROVED',
    processing: 'PROCESSING',
    completed: 'COMPLETED',
    failed: 'FAILED',
  },
  initialTermUnit: {
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
    none: 'NONE',
  },
  renewalTermUnit: {
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
    none: 'NONE',
  },
  trialTermUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
  },
  action: {
    add: 'ADD',
    modify: 'MODIFY',
    cancel: 'CANCEL',
  },
  orderLineStatus: {
    created: 'CREATED',
    edited: 'EDITED',
    cancelled: 'CANCELLED',
    submitted: 'SUBMITTED',
    processing: 'PROCESSING',
    billed: 'BILLED',
    provisioned: 'PROVISIONED',
    completed: 'COMPLETED',
    failed: 'FAILED',
  },
  relativeStartUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
    unbound: 'UNBOUND',
  },
  relativeEndUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
    unbound: 'UNBOUND',
  },
  subscriptionReason: {
    none: 'NONE',
    customerRelocation: 'CUSTOMER_RELOCATION',
    notHappy: 'NOT_HAPPY',
    qos: 'QOS',
    pricing: 'PRICING',
  },
  subscriptionStatus: {
    active: 'ACTIVE',
    suspend: 'SUSPENDED',
    close: 'CLOSED',
  },

  dayOfWeek: {
    SUNDAY: 'SUNDAY',
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
  },

  hourOfDay: {
    1: '01:00:00',
    2: '02:00:00',
    3: '03:00:00',
    4: '04:00:00',
    5: '05:00:00',
    6: '06:00:00',
    7: '07:00:00',
    8: '08:00:00',
    9: '09:00:00',
    10: '10:00:00',
    11: '11:00:00',
    12: '12:00:00',
    13: '13:00:00',
    14: '14:00:00',
    15: '15:00:00',
    16: '16:00:00',
    17: '17:00:00',
    18: '18:00:00',
    19: '19:00:00',
    20: '20:00:00',
    21: '21:00:00',
    22: '22:00:00',
    23: '23:00:00',
    24: '24:00:00',
  },

  recordExpression: {
    DETAIL: 'DETAIL',
  },

  fieldExpression: {
    startDate: 'startDate',
    endDate: 'endDate',
    processDate: 'processDate',
    quantity: 'quantity',
  },

  operator: {
    '-': '-',
    '+': '+',
    '*': '*',
    '/': '/',
    '^': '^',
  },

  roundingMethod: {
    UP: 'UP',
    DOWN: 'DOWN',
    HALF_UP: 'HALF_UP',
    HALF_DOWN: 'HALF_DOWN',
    NEAREST: 'NEAREST',
  },

  selectBoolean: {
    TRUE: 'TRUE',
    FALSE: 'FALSE',
  },

  selectUnit: {
    ONE: 'ONE',
    KILOBYTE: 'KILOBYTE',
    MEGABYTE: 'MEGABYTE',
    GIGABYTE: 'GIGABYTE',
    SECOND: 'SECOND',
    MINUTE: 'MINUTE',
    HOUR: 'HOUR',
    NONE: 'NONE',
  },

  statusRateUnit: {
    active: 'ACTIVE',
    close: 'CLOSED',
  },

  recordIndicator: {
    PREPAID: 'PREPAID',
    POSTPAID: 'POSTPAID',
    PRERATED: 'PRERATED',
  },

  moduleName: {
    ACCOUNT_LOOKUP_MODULE: 'ACCOUNT_LOOKUP_MODULE',
    BALANCE_LOOKUP_MODULE: 'BALANCE_LOOKUP_MODULE',
    RATE_UNIT_MODULE: 'RATE_UNIT_MODULE',
    TIME_UNIT_MODULE: 'TIME_UNIT_MODULE',
    ZONE_UNIT_MODULE: 'ZONE_UNIT_MODULE',
    IMPACT_UNIT_MODULE: 'IMPACT_UNIT_MODULE',
    ROUTER_MODULE: 'ROUTER_MODULE',
    PO_PRICE_UNIT_ANLYS_MODULE: 'PO_PRICE_UNIT_ANLYS_MODULE',
    PO_RATING_MODULE: 'PO_RATING_MODULE',
    PO_DISCOUNT_UNIT_ANLYS_MODULE: 'PO_DISCOUNT_UNIT_ANLYS_MODULE',
    PO_DISCOUNTING_MODULE: 'PO_DISCOUNTING_MODULE',
    PP_SESSION_MGMT_MODULE: 'PP_SESSION_MGMT_MODULE',
    PP_BALANCE_MGMT_MODULE: 'PP_BALANCE_MGMT_MODULE',
    PP_PRICE_UNIT_ANLYS_MODULE: 'PP_PRICE_UNIT_ANLYS_MODULE',
    PP_RATING_MODULE: 'PP_RATING_MODULE',
    PP_DISCOUNT_UNIT_ANLYS_MODULE: 'PP_DISCOUNT_UNIT_ANLYS_MODULE',
    PP_DISCOUNTING_MODULE: 'PP_DISCOUNTING_MODULE',
    REV_SHARE_LOOKUP_MODULE: 'REV_SHARE_LOOKUP_MODULE',
    REV_SHARE_MODULE: 'REV_SHARE_MODULE',
    UPDATE_BALANCE_UNIT_MODULE: 'UPDATE_BALANCE_UNIT_MODULE',
    UPDATE_BILL_UNIT_MODULE: 'UPDATE_BILL_UNIT_MODULE',
    CREATE_TRANS_UNIT_MODULE: 'CREATE_TRANS_UNIT_MODULE',
  },

  streamName: {
    PRE_PROCESSING_SVC: 'PRE_PROCESSING_SVC',
    POSTPAID_RATING_SVC: 'POSTPAID_RATING_SVC',
    PREPAID_RATING_SVC: 'PREPAID_RATING_SVC',
    SHARING_MODEL_SVC: 'SHARING_MODEL_SVC',
    SETTLEMENT_SVC: 'SETTLEMENT_SVC',
    POST_PROCESSING_SVC: 'POST_PROCESSING_SVC',
  },

  volumeTreatment: {
    ACTUAL: 'ACTUAL',
    AVERAGE: 'AVERAGE',
    ANNUALIZE: 'ANNUALIZE',
  },

  tierType: {
    PROGRESSIVE: 'PROGRESSIVE',
    PROSPECTIVE: 'PROSPECTIVE',
  },

  tierReference: {
    QUANTITY: 'QUANTITY',
    BALANCE: 'BALANCE',
  },
  timeUnit: {
    WORKWEEK: 'WORKWEEK',
    WEEKEND: 'WEEKEND',
    PEAKHOURS: 'PEAKHOURS',
    OFFPEAKHOURS: 'OFFPEAKHOURS',
    HOLIDAYSPECIAL: 'HOLIDAYSPECIAL',
    ANYTIME: 'ANYTIME',
    NONE: 'NONE',
  },

  listUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarterly: 'QUARTERS',
    years: 'YEARS',
  },

  dashboardType: {
    CUSTOMER_ONBOARDING: 'CUSTOMER_ONBOARDING',
    CUSTOMER_CHURN: 'CUSTOMER_CHURN',
    TRIAL_TO_PAID_CONVERSION: 'TRIAL_TO_PAID_CONVERSION',
    SUBSCRIPTION_CANCELLATIONS: 'SUBSCRIPTION_CANCELLATIONS',
    BILLED_REVENUE: 'BILLED_REVENUE',
    BILLED_CUSTOMER: 'BILLED_CUSTOMER',
    PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
    PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    ACCOUNTS_WITH_FAILED_PAYMENT: 'ACCOUNTS_WITH_FAILED_PAYMENT',
    COLLECTION_0_30_DAYS: 'COLLECTION_0_30_DAYS',
    COLLECTION_30_60_DAYS: 'COLLECTION_30_60_DAYS',
    COLLECTION_60_90_DAYS: 'COLLECTION_60_90_DAYS',
    COLLECTION_OVER_90_DAYS: 'COLLECTION_OVER_90_DAYS',
    // REVENUE_BY_PRODUCT: 'REVENUE_BY_PRODUCT',
    FAILED_ORDER: 'FAILED_ORDER',
    BILLS_FAILED: 'BILLS_FAILED',
    INVOICES_FAILED: 'INVOICES_FAILED',
    TOTAL_CREDIT_ADJUSTMENTS: 'TOTAL_CREDIT_ADJUSTMENTS',
    TOTAL_DISPUTES: 'TOTAL_DISPUTES',
    TOTAL_SETTLEMENTS: 'TOTAL_SETTLEMENTS',
    JOBS_FAILED: 'JOBS_FAILED',
  },
};
