import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['groupByAccount'])}` : '';
  const getAssetsForecastReport = `getAssetsForecastReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getAssetsForecastReport} {
        organization
        accountId
        serviceId
        serviceName
        instanceId
        loadType
        status
        cutOffDom
        recurringFrequency
        invoiceExhibits
        inAdvanceFlag
        recurringUnit
        recurringType
        revenueData {
          index
          month
          monthRevenue
        }
        startMonth
        monthOneRevenue
        monthTwoRevenue
        monthThreeRevenue
        monthFourRevenue
        monthFiveRevenue
        monthSixRevenue
        monthSevenRevenue
        monthEightRevenue
        monthNineRevenue
        monthTenRevenue
        monthElevenRevenue
        monthTwelveRevenue
      }
    }
  `;
};
