import React, { Component } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import RouteNames from '../App/RouteNames';
import JobConfig from './JobConfig';
import JobSchedule from './JobSchedule';
import reducer from './reducer';
import saga from './saga';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import { makeGetPermissionsJobsManagement } from '../App/selectors';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class JobManagement extends Component {
  componentDidMount() {}

  render() {
    const { permissionsJob } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsJob, listKeys: permissionList.jobsManagement })) return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper job-management">
            <Switch>
              <Route exact path={RouteNames.jobsManagmentJobConfig.path} component={JobConfig} />
              <Route exact path={RouteNames.jobsManagmentJobSchedule.path} component={JobSchedule} />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsJob: makeGetPermissionsJobsManagement() || {},
});
const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({ key: 'jobManagementReducer', reducer });
const withSaga = injectSaga({ key: 'jobManagementSaga', saga });

export default compose(withReducer, withSaga, withConnect)(JobManagement);
