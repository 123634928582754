import constants from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissioSaveQuote = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.SAVE_QUOTE });
};

const checkPermissioSearchQuote = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.SEARCH_QUOTE });
};

const checkPermissioConvertQuote = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.CONVERT_QUOTE });
};

const checkPermissioCancelQuote = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.CANCEL_QUOTE });
};

const checkPermissioApproveQuote = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.APPROVE_QUOTE });
};

const checkPermissionNewQuoteType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.NEW_QUOTE_TYPE });
};

const checkPermissionModifyQuoteType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.MODIFY_QUOTE_TYPE });
};

const checkPermissionCancelQuoteType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.CANCEL_QUOTE_TYPE });
};

const checkPermissionPackagePurchase = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.PACKAGE_PURCHASE });
};

const checkPermissionBundlePurchase = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.BUNDLE_PURCHASE });
};

const checkPermissionAlacartePurchase = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.ALACARTE_PURCHASE });
};

const checkPermissionAddBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.ADD_BUNDLE });
};

const checkPermissionAddPackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.ADD_PACKAGE });
};

const checkPermissionAddPriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.ADD_PRICE_OFFER });
};

const checkPermissionDeleteBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.DELETE_BUNDLE });
};

const checkPermissionDeletePackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.DELETE_PACKAGE });
};

const checkPermissionDeletePriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.DELETE_PRICE_OFFER });
};

const checkPermissionGetQuote = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.GET_QUOTE });
};

const checkPermissionUpgradeQuoteType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.UPGRADE_QUOTE_TYPE });
};

const checkPermissionDowngradeQuoteType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.DOWNGRADE_QUOTE_TYPE });
};

const checkPermissionOverrideQuoteLines = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.OVERRIDE_QUOTE_LINES });
};

const checkPermissionUpdateProvisioningAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.UPDATE_PROVISIONING_ATTRIBUTES });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeSaveQuote = 0;
  let modeSearchQuote = 0;
  let modeConvertQuote = 0;
  let modeCancelQuote = 0;
  let modeApproveQuote = 0;
  let modeNewQuoteType = 0;
  let modeModifyQuoteType = 0;
  let modeCancelQuoteType = 0;
  let modePackagePurchase = 0;
  let modeBundlePurchase = 0;
  let modeAlacartePurchase = 0;
  let modeAddBundle = 0;
  let modeAddPackage = 0;
  let modeAddPriceOffer = 0;
  let modeDeleteBundle = 0;
  let modeDeletePackage = 0;
  let modeDeletePriceOffer = 0;
  let modeGetQuote = 0;
  let modeUpgradeQuoteType = 0;
  let modeDowngradeQuoteType = 0;
  let modeOverrideQuoteLines = 0;
  let modeUpdateProvisioningAttributes = 0;

  modeSaveQuote = checkPermissioSaveQuote({ listPermission });
  modeSearchQuote = checkPermissioSearchQuote({ listPermission });
  modeConvertQuote = checkPermissioConvertQuote({ listPermission });
  modeCancelQuote = checkPermissioCancelQuote({ listPermission });
  modeApproveQuote = checkPermissioApproveQuote({ listPermission });
  modeNewQuoteType = checkPermissionNewQuoteType({ listPermission });
  modeModifyQuoteType = checkPermissionModifyQuoteType({ listPermission });
  modeCancelQuoteType = checkPermissionCancelQuoteType({ listPermission });
  modePackagePurchase = checkPermissionPackagePurchase({ listPermission });
  modeBundlePurchase = checkPermissionBundlePurchase({ listPermission });
  modeAlacartePurchase = checkPermissionAlacartePurchase({ listPermission });
  modeAddBundle = checkPermissionAddBundle({ listPermission });
  modeAddPackage = checkPermissionAddPackage({ listPermission });
  modeAddPriceOffer = checkPermissionAddPriceOffer({ listPermission });
  modeDeleteBundle = checkPermissionDeleteBundle({ listPermission });
  modeDeletePackage = checkPermissionDeletePackage({ listPermission });
  modeDeletePriceOffer = checkPermissionDeletePriceOffer({ listPermission });
  modeGetQuote = checkPermissionGetQuote({ listPermission });
  modeUpgradeQuoteType = checkPermissionUpgradeQuoteType({ listPermission });
  modeDowngradeQuoteType = checkPermissionDowngradeQuoteType({ listPermission });
  modeOverrideQuoteLines = checkPermissionOverrideQuoteLines({ listPermission });
  modeUpdateProvisioningAttributes = checkPermissionUpdateProvisioningAttributes({ listPermission });

  return {
    modeSaveQuote,
    modeSearchQuote,
    modeConvertQuote,
    modeCancelQuote,
    modeApproveQuote,
    modeNewQuoteType,
    modeModifyQuoteType,
    modeCancelQuoteType,
    modePackagePurchase,
    modeBundlePurchase,
    modeAlacartePurchase,
    modeAddBundle,
    modeAddPackage,
    modeAddPriceOffer,
    modeDeleteBundle,
    modeDeletePackage,
    modeDeletePriceOffer,
    modeGetQuote,
    modeUpgradeQuoteType,
    modeDowngradeQuoteType,
    modeOverrideQuoteLines,
    modeUpdateProvisioningAttributes,
  };
};

export {
  checkPermissioSaveQuote,
  checkPermissioSearchQuote,
  checkPermissioConvertQuote,
  checkPermissioCancelQuote,
  checkPermissioApproveQuote,
  checkPermissionNewQuoteType,
  checkPermissionModifyQuoteType,
  checkPermissionCancelQuoteType,
  checkPermissionPackagePurchase,
  checkPermissionBundlePurchase,
  checkPermissionAlacartePurchase,
  checkPermissionAddBundle,
  checkPermissionAddPackage,
  checkPermissionAddPriceOffer,
  checkPermissionDeleteBundle,
  checkPermissionDeletePackage,
  checkPermissionDeletePriceOffer,
  checkPermissionGetQuote,
  checkPermissionUpgradeQuoteType,
  checkPermissionDowngradeQuoteType,
  checkPermissionOverrideQuoteLines,
  checkPermissionUpdateProvisioningAttributes,
  checkPermissionForAllPages,
};
