import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['status', 'apiName'])}` : '';
  const searchBulkActionStats = `searchBulkActionStats(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchBulkActionStats} {
        id
        createdDate
        payload
        errorMessage
        apiName
        status
        fileName
        batchId
      }
    }
  `;
};
