import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import ItemForm from './ItemForm';
import { makeGetItemDetail, makeErrorMessage } from '../selectors';
import { getItemDetail, getPriceOfferDetail, modifyItem, updateItemStatus } from '../actions';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import {
  checkPermissionModifyItem,
  checkPermissionApproveItem,
  checkPermissionSellableItem,
  checkPermissionNonSellableItem,
  checkPermissionSunsetItem,
} from '../CheckPermission';
import {
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
  getTaxCodeConfigSelect,
  getUomConfig,
  getCcpPropertiesConfig,
  getPACProductCodesConfig,
} from '../../App/actions';

class PricingItemEdit extends Component {
  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    this.doGetItemDetail();
  }

  doGetItemDetail = () => {
    const {
      match: {
        params: { id },
      },
      getItemDetail,
    } = this.props;
    getItemDetail(id);
  };

  onSave = (data, cb = null) => {
    const { status, ...dataItem } = data;
    const { itemInfo, modifyItem, updateItemStatus } = this.props;
    const changedStatus = status !== itemInfo.status;
    if (!changedStatus) {
      modifyItem(dataItem, ({ success }) => {
        this.setState({ isSubmitting: false });
        if (cb) cb({ success });
      });
    } else {
      modifyItem(dataItem, ({ success }) => {
        if (success) {
          updateItemStatus({ id: itemInfo.id, status }, () => {
            this.setState({ isSubmitting: false });
            if (cb) cb({ success });
          });
        } else {
          this.setState({ isSubmitting: false });
        }
      });
    }
  };

  render() {
    const {
      itemInfo,
      permissionsPricing,
      t,
      getUniqueProductFamily,
      getUniqueProductLine,
      getUniqueProductSubType,
      getUniqueProductType,
      getTaxCodeConfigSelect,
      getUomConfig,
      location,
      getCcpPropertiesConfig,
      getPACProductCodesConfig,
    } = this.props;
    const { isSubmitting } = this.state;
    let modeModifyItem = 0;
    let modeApproveItem = 0;
    let modeSellableItem = 0;
    let modeNonSellableItem = 0;
    let modeSunsetItem = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeModifyItem = checkPermissionModifyItem({ listPermission: listPermissionPricing });
      modeApproveItem = checkPermissionApproveItem({ listPermission: listPermissionPricing });
      modeSellableItem = checkPermissionSellableItem({ listPermission: listPermissionPricing });
      modeNonSellableItem = checkPermissionNonSellableItem({ listPermission: listPermissionPricing });
      modeSunsetItem = checkPermissionSunsetItem({ listPermission: listPermissionPricing });
    }

    if (modeModifyItem === 0) return '';
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.pricingItem.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.items'), url: RouteNames.pricingItem.path },
                  { name: itemInfo && itemInfo.name ? itemInfo.name : 'Edit' },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editPricingItem')}</h5>
              </div>
              <ItemForm
                item={itemInfo}
                modeModifyItem={modeModifyItem}
                modeApproveItem={modeApproveItem}
                modeSellableItem={modeSellableItem}
                modeNonSellableItem={modeNonSellableItem}
                modeSunsetItem={modeSunsetItem}
                onSubmit={this.onSave}
                isSubmitting={isSubmitting}
                getUniqueProductFamily={getUniqueProductFamily}
                getUniqueProductLine={getUniqueProductLine}
                getUniqueProductSubType={getUniqueProductSubType}
                getUniqueProductType={getUniqueProductType}
                getTaxCodeConfigSelect={getTaxCodeConfigSelect}
                getUomConfig={getUomConfig}
                onCancel={this.doGetItemDetail}
                getCcpPropertiesConfig={getCcpPropertiesConfig}
                getPACProductCodesConfig={getPACProductCodesConfig}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  itemInfo: makeGetItemDetail() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

const mapDispatchToProps = dispatch => ({
  getItemDetail: (e, cb) => dispatch(getItemDetail(e, cb)),
  getPriceOfferDetail: (e, cb) => dispatch(getPriceOfferDetail(e, cb)),
  modifyItem: (e, cb) => dispatch(modifyItem(e, cb)),
  updateItemStatus: (e, cb) => dispatch(updateItemStatus(e, cb)),
  getUniqueProductFamily: (e, cb) => dispatch(getUniqueProductFamily(e, cb)),
  getUniqueProductLine: (e, cb) => dispatch(getUniqueProductLine(e, cb)),
  getUniqueProductSubType: (e, cb) => dispatch(getUniqueProductSubType(e, cb)),
  getUniqueProductType: (e, cb) => dispatch(getUniqueProductType(e, cb)),
  getTaxCodeConfigSelect: (e, cb) => dispatch(getTaxCodeConfigSelect(e, cb)),
  getUomConfig: (e, cb) => dispatch(getUomConfig(e, cb)),
  getCcpPropertiesConfig: (e, cb) => dispatch(getCcpPropertiesConfig(e, cb)),
  getPACProductCodesConfig: (e, cb) => dispatch(getPACProductCodesConfig(e, cb)),
});

PricingItemEdit.propTypes = {
  itemInfo: PropTypes.objectOf(PropTypes.any),
  modifyItem: PropTypes.func.isRequired,
  updateItemStatus: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  getItemDetail: PropTypes.func.isRequired,
};

PricingItemEdit.defaultProps = {
  permissionsPricing: {},
  itemInfo: {},
  match: {},
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'pricingPage'])(compose(withConnect)(PricingItemEdit));
