import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ScaleLoader } from 'react-spinners';
import { Collapse } from 'reactstrap';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { RowContent, RowHeader } from './TableRow';

const Handle = SortableHandle(() => (
  <button type="button" className="sortable-handle-icon no-border">
    <i className="fa fa-bars" />
  </button>
));

const SortableItem = SortableElement(({ row, onRemove, sortingItemKey, children, ...rest }) => {
  return [
    <tr className={`job-config-row ${sortingItemKey === row ? 'dragging' : ''}`}>
      <td>
        <Handle />
      </td>
      <RowContent
        key={`row-${rest.indexItem}`}
        columns={rest.columns}
        item={row}
        onClickRow={rest.onClickRow}
        className={`${rest.rowClass} ${
          rest.dataExpand && rest.listSwitchActive && rest.item.id === rest.dataExpand.id ? 'acitve' : ''
        }`}
        getRowClass={rest.getRowClass}
        indexParent={rest.indexParent}
        indexItem={rest.indexItem}
        ExpandRow={rest.ExpandRow || (() => {})}
        activeTab={rest.indexViewer === rest.idx}
      />
    </tr>,
    <tr key={`key-${rest.indexItem}`}>
      <td colSpan={rest.columns.length + 1} className="p-0">
        <Collapse isOpen={rest.indexViewer === rest.indexItem} className="collapse__content">
          {children}
        </Collapse>
      </td>
    </tr>,
  ];
});

const SortableList = SortableContainer(({ items, isSorting, sortingItemKey, onRemove, children, ...rest }) => {
  return (
    <tbody>
      {items &&
        items.map((row, index) => {
          if (Object.keys(row).length < 2) return '';
          return (
            <SortableItem
              key={`item-${index}`}
              isSorting={isSorting}
              sortingItemKey={sortingItemKey}
              index={index}
              indexItem={index}
              onRemove={onRemove}
              row={row}
              {...rest}
            >
              {children}
            </SortableItem>
          );
        })}
    </tbody>
  );
});

class CollapsibleTableDragDrop extends React.PureComponent {
  state = {
    indexViewer: -1,
    dataTest: [{ header: 'aa' }, { header: 'ccc' }],
  };

  tableRef = React.createRef();

  componentDidMount() {
    this.injectJquery();
  }

  injectJquery = () => {
    const { fixedHeader } = this.props;
    if (!fixedHeader) return null;
    window.jQuery(this.tableRef.current).on('scroll', () => {
      window
        .jQuery(this.tableRef.current)
        .find('thead th')
        .css('transform', `translateY(${window.jQuery(this.tableRef.current).scrollTop()}px)`);
    });
  };

  onClickRow = (e, item, indexItem) => {
    const { indexViewer } = this.state;
    this.setState({ indexViewer: indexViewer === indexItem ? -1 : indexItem });
    const { onClickRow } = this.props;
    if (onClickRow) {
      onClickRow(e, item, indexItem);
    }
  };

  handleUpdateBeforeSortStart = ({ index }) => {
    return new Promise(resolve =>
      this.setState(({ dataTest }) => {
        return {
          sortingItemKey: dataTest[index],
          isSorting: true,
        };
      }, resolve)
    );
  };

  render() {
    const {
      fixedHeader,
      columns,
      data,
      rowClass,
      noDataMessage,
      getRowClass,
      sorted,
      onSort,
      tableClass,
      nullIsLoading,
      isLoading,
      indexParent, // Using when map & render table
      onCheckAll,
      dataExpand,
      listSwitchActive,
      t,
      ExpandRow,
      onClickTd,
      children,
      isSupportRemoveIndex,
      isViewOnly,
      onSortEnd,
      isFixedHeaderTable,
      isNoNeedFixedHeader,
      ...rest
    } = this.props;
    // const { indexViewer } = this.state;
    return (
      <div
        className={`table-responsive mt-15 ${isNoNeedFixedHeader || fixedHeader ? '' : 'sticky-header'} table-form ${tableClass} ${
          fixedHeader ? 'tableFixHead' : ''
        }`}
        ref={this.tableRef}
      >
        <table
          className={`table center-aligned-table mb-0 ${isViewOnly ? 'table-collapsible-viewer' : 'table-collapsible'}`}
        >
          <thead>
            <RowHeader
              columns={[{}, ...columns]}
              sorted={sorted}
              onSort={onSort}
              isLoading={isLoading}
              onCheckAll={onCheckAll}
              t={t}
            />
          </thead>
          {/* <tbody> */}
          {!isLoading && (
            <SortableList
              items={data}
              useDragHandle
              onSortStart={this.onSortStart}
              onSortEnd={onSortEnd}
              onRemove={this.removeJobType}
              isSorting={this.state.isSorting}
              sortingItemKey={this.state.sortingItemKey}
              updateBeforeSortStart={this.handleUpdateBeforeSortStart}
              columns={columns}
              onClickRow={this.onClickRow}
              className={rowClass}
              getRowClass={getRowClass}
              indexParent={indexParent}
              {...rest}
            >
              {children}
            </SortableList>
          )}
          {(isLoading || !data || data.length === 0) && (
            <tr>
              <td colSpan={columns.length + 1} className="text-center">
                {!isLoading && !nullIsLoading && <span>{noDataMessage || t('label.noRecord')}</span>}
                {(isLoading || ((!data || data.length === 0) && nullIsLoading)) && (
                  <ScaleLoader loading size={100} color="#28a745" />
                )}
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  }
}

CollapsibleTableDragDrop.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  data: PropTypes.array.isRequired,
  rowClass: PropTypes.string,
  tableClass: PropTypes.string,
  noDataMessage: PropTypes.string,
  nullIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getRowClass: PropTypes.func,
  onCheckAll: PropTypes.func,
  onSort: PropTypes.func,
  fixedHeader: PropTypes.bool,
  indexParent: PropTypes.number,
};

CollapsibleTableDragDrop.defaultProps = {
  columns: [],
  sorted: {},
  data: [],
  rowClass: '',
  tableClass: '',
  getRowClass: null,
  isLoading: false,
  nullIsLoading: false,
  onSort: null,
  noDataMessage: '',
  fixedHeader: false,
  indexParent: 0,
  onCheckAll: () => {},
};

export default withTranslation('common')(CollapsibleTableDragDrop);
