import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'gatewayName',
    'apiName',
    'requestFlag',
    'index',
    'refIndex',
    'level0Flag',
    'objectType',
  ]);

  const modifyGatewayApiMapping = `modifyGatewayApiMapping(input: ${dataModify})`;
  return gql`
    mutation {
      ${modifyGatewayApiMapping} {
        id
      }
    }
  `;
};
