import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { cloneDeep, sortBy } from 'lodash';
import { roundFloat } from '../../../utils/utils';
import { ArOperationsForm } from '../../../components/ArOperationsHub';
import { adjustmentFieldApply } from '../constant';
import RouteName from '../../App/RouteNames';
import PageTitle from '../../../components/PageTitle';
import {
  makeGetPermissionsArOpsManagement,
  makeGetCurrencyConfig,
  makeGetCcpPropertiesConfig,
} from '../../App/selectors';
import { getCcpPropertiesConfig, getAccountInfo, getCurrencyConfigApp } from '../../App/actions';
import ModalInvoiceApplyPayment from '../../../components/common/ModalInvoiceApplyPayment';
import ModalAccountItems from '../../../components/common/ModalAccountItems';
import ModalAccountId from '../../../components/common/ModalAccountId';
import ModalTransactions from '../../../components/common/ModalTransactions';
import {
  processAdjustment,
  getInvoiceLinesById,
  getARReasonCodeConfig,
  getUnbilledTransactionSummary,
} from '../actions';
import { GenericInput } from '../../../components/common';
import {
  checkPermissionProcessTaxAdjustment,
  checkPermissionProcessAdjustment,
  checkPermissionGetArOpsTaxOptions,
} from '../CheckPermission';
import { getUserId } from '../../../localStorages';

let isDisablePercent = false;

class AdjustmentApply extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dataCreate: {
        userId: getUserId() || 'USER-001',
        source: 'AGENT_CARE',
        arType: 'CREDIT',
        taxRule: 'WITH_TAX',
        type: 'INVOICE_LINE_ADJUSTMENT',
      },
      isOpenModalItem: false,
      isOpenModalInvoice: false,
      isOpenModalAccountId: false,
      isOpenModalTransaction: false,
      invoiceLines: [],
      optionResonCodes: [],
      fixedTaxRuleForAROps: null,
      currencyOptions: [],
      accountDetail: {},
      isPacEnabled: false,
      transactionsList: [],
      isAropsSupportTicket: false,
      isMandateSatCodeForAdjustments: false,
      isFilterInstallmentInvoicesForAR: null,
    };
  }

  componentDidMount() {
    const {
      getARReasonCodeConfig,
      getCcpPropertiesConfig,
      getCurrencyConfigApp,
      currencyConfig,
      ccpPropertyList,
    } = this.props;
    const { currencyOptions } = this.state;
    // getCcpPropertiesConfig('', ({ success, data }) => {
    //   if (success) {
    //     const fixedTaxRuleForAROps = data.ccpPropertyList.find(val => val.property === 'fixedTaxRuleForAROps');
    //     const pacEnabled = data.ccpPropertyList.find(val => val.property === 'pacEnabled');
    //     const aropsSupportTicket = data.ccpPropertyList.find(val => val.property === 'aropsSupportTicket');
    //     const mandateSatCodeForAdjustments = data.ccpPropertyList.find(
    //       val => val.property === 'mandateSatCodeForAdjustments'
    //     );
    //     if (fixedTaxRuleForAROps && fixedTaxRuleForAROps.value) {
    //       dataCreate.taxRule = fixedTaxRuleForAROps.value;
    //     }
    //     this.setState({
    //       fixedTaxRuleForAROps,
    //       dataCreate: { ...dataCreate },
    //       dataCancel: { ...dataCreate },
    //       isPacEnabled: pacEnabled && pacEnabled?.value === 'true',
    //       isAropsSupportTicket: aropsSupportTicket && aropsSupportTicket?.value === 'true',
    //       isMandateSatCodeForAdjustments:
    //         mandateSatCodeForAdjustments && mandateSatCodeForAdjustments?.value === 'true',
    //     });
    //   }
    // });
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitDefaultValue(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitDefaultValue(ccpPropertyList);
    }
    getARReasonCodeConfig('', ({ success, data }) => {
      if (success && data && data.arReasonCodes) {
        const optionResonCodes = data.arReasonCodes
          .filter(val => !!val.customReasonCode)
          .map(val => ({
            label: val.customReasonCode,
            value: val.customReasonCode,
            ...val,
          }));
        this.setState({
          optionResonCodes,
        });
      }
    });
    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            normalLabel: val.name,
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        normalLabel: val.name,
        label: `${val.name} (${val.currencyId})`,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }
  }

  doInitDefaultValue = ccpPropertyList => {
    const { dataCreate } = this.state;
    const fixedTaxRuleForAROps = ccpPropertyList.find(val => val.property === 'fixedTaxRuleForAROps');
    const pacEnabled = ccpPropertyList.find(val => val.property === 'pacEnabled');
    const aropsSupportTicket = ccpPropertyList.find(val => val.property === 'aropsSupportTicket');
    const mandateSatCodeForAdjustments = ccpPropertyList.find(val => val.property === 'mandateSatCodeForAdjustments');
    const defaultTaxRuleForAROps = ccpPropertyList.find(val => val.property === 'defaultTaxRuleForAROps');
    const filterInstallmentInvoicesForAR = ccpPropertyList.find(
      val => val.property === 'filterInstallmentInvoicesForAR'
    );
    dataCreate.taxRule = 'WITHOUT_TAX';
    if (defaultTaxRuleForAROps && defaultTaxRuleForAROps.value) {
      dataCreate.taxRule = defaultTaxRuleForAROps.value;
    }
    if (fixedTaxRuleForAROps && fixedTaxRuleForAROps.value) {
      dataCreate.taxRule = fixedTaxRuleForAROps.value;
    }
    this.setState({
      fixedTaxRuleForAROps,
      dataCreate: { ...dataCreate },
      dataCancel: { ...dataCreate },
      isPacEnabled: pacEnabled && pacEnabled?.value === 'true',
      isAropsSupportTicket: aropsSupportTicket && aropsSupportTicket?.value === 'true',
      isMandateSatCodeForAdjustments: mandateSatCodeForAdjustments && mandateSatCodeForAdjustments?.value === 'true',
      isFilterInstallmentInvoicesForAR:
        filterInstallmentInvoicesForAR && filterInstallmentInvoicesForAR.value === 'true',
    });
  };

  onOpenModal = name => {
    let { isOpenModalItem, isOpenModalInvoice, isOpenModalAccountId } = this.state;
    // const { dataCreate } = this.state;
    if (name === 'itemId') {
      isOpenModalItem = true;
    }
    if (name === 'invoiceId') {
      // if (!dataCreate.accountId) toast('Please input account Id first!', { type: toast.TYPE.WARNING });
      isOpenModalInvoice = true;
    }
    if (name === 'accountId') {
      isOpenModalAccountId = true;
    }
    return this.setState({ isOpenModalItem, isOpenModalInvoice, isOpenModalAccountId });
  };

  onSelectItem = id => {
    const { dataCreate } = this.state;
    dataCreate.itemId = id;
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalItem: false });
  };

  onSelectInvoice = (id, row) => {
    const { dataCreate } = this.state;
    const { getInvoiceLinesById } = this.props;
    if (id) {
      dataCreate.invoiceId = id;
      dataCreate.accountId = row.accountId || null;
      dataCreate.total = row.total || null;
      dataCreate.due = row.due || null;
      dataCreate.amount = null;
      getInvoiceLinesById(id, ({ data, success }) => {
        if (success) {
          this.setState({ invoiceLines: data });
        }
      });
    }
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalInvoice: false }, () => this.doGetAccountInfo());
  };

  onSelectAccountId = id => {
    const { dataCreate } = this.state;
    dataCreate.accountId = id;
    dataCreate.invoiceId = null;

    this.setState({ dataCreate: { ...dataCreate }, isOpenModalAccountId: false }, () => {
      this.doGetAccountInfo();
      if (dataCreate.type === 'UNBILLED_LINE_ADJUSTMENT') this.doGetUnbilledTransactionSummary();
    });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const {
      dataCreate: { invoiceCancellation, amount, percent, type, satCode, due, total, ...rest },
      invoiceLines,
      transactionsList,
    } = this.state;
    const { processAdjustment, t } = this.props;

    let newInvoiceLines = null;
    if (type === 'INVOICE_LINE_ADJUSTMENT' && invoiceLines.length) {
      const invoicesChecked = invoiceLines.filter(val => !!val.isChecked);
      if (invoicesChecked && invoicesChecked.length) {
        newInvoiceLines = invoicesChecked.map(val => {
          const { index, name, amountAdjustment, percentAdjustment } = val;
          return { index, name, amount: amountAdjustment || null, percent: percentAdjustment || null };
        });
      }
    }
    if (type === 'UNBILLED_LINE_ADJUSTMENT' && transactionsList.length) {
      const invoicesChecked = transactionsList.filter(val => !!val.isChecked);
      if (invoicesChecked && invoicesChecked.length) {
        newInvoiceLines = invoicesChecked.map((val, index) => {
          const { name, amountAdjustment, percentAdjustment, transactionId } = val;
          return {
            index: index + 1,
            name,
            amount: amountAdjustment || null,
            percent: percentAdjustment || null,
            transactionId: transactionId || null,
          };
        });
      }
    }
    const payload = {
      type,
      amount: type === 'INVOICE_LINE_ADJUSTMENT' ? null : amount || null,
      percent: type === 'INVOICE_LINE_ADJUSTMENT' ? null : percent || null,
      satCode: rest?.customReason && rest?.customReason?.invoiceCancellation === 'TRUE' ? satCode : null,
      ...rest,
      invoiceLines: newInvoiceLines,
    };
    if (!rest.customReason && !rest.reason) {
      return toast.error(t('message.messgaeErrorReasonCode'));
    }
    processAdjustment(payload);
  };

  onHandleChange = ({ name, value }) => {
    const { dataCreate, optionResonCodes } = this.state;
    if (name === 'percent' && (Number.parseFloat(value) > 100 || Number.parseFloat(value) < 0)) {
      return '';
    }
    dataCreate[name] = value;
    if (name === 'customReason') {
      const optionSelect = optionResonCodes.find(val => val.value === value);
      if (optionSelect) {
        dataCreate.invoiceCancellation = optionSelect.invoiceCancellation ? 'TRUE' : 'FALSE';
        dataCreate.percent = optionSelect.invoiceCancellation ? '100' : null;
        // dataCreate.invoiceId = null;
        isDisablePercent = optionSelect.invoiceCancellation;
      }
      if (!value) dataCreate.invoiceCancellation = null;
    }
    if (name === 'amount' && dataCreate.percent) dataCreate.percent = 0;
    if (name === 'type' && value !== 'TRANSACTION_ADJUSTMENT') dataCreate.numberOfTransactions = null;
    if (name === 'type') {
      if (value === 'UNBILLED_LINE_ADJUSTMENT') {
        dataCreate.taxRule = 'WITHOUT_TAX';
        this.doGetUnbilledTransactionSummary();
      } else {
        dataCreate.taxRule = 'WITH_TAX';
      }
    }
    if (name === 'percent' && dataCreate.amount) delete dataCreate.amount;
    return this.setState({ dataCreate: { ...dataCreate } });
  };

  onBlurAmount = () => {
    const { dataCreate } = this.state;
    if (dataCreate.amount) dataCreate.amount = roundFloat(dataCreate.amount, 2);
    this.setState({ dataCreate: { ...dataCreate } });
  };

  onOpenPopupInfo = () => {
    this.setState({ isOpenModalTransaction: true });
  };

  onHandleChangeInvoiceLine = ({ name, value, item, index }) => {
    const { invoiceLines, dataCreate, transactionsList } = this.state;
    const newData =
      dataCreate.type === 'INVOICE_LINE_ADJUSTMENT' ? cloneDeep(invoiceLines) : cloneDeep(transactionsList);
    try {
      if (name === 'amountAdjustment' && (Number.parseFloat(value) > item.amount || Number.parseFloat(value) < 0)) {
        return '';
      }
      if (name === 'percentAdjustment' && (Number.parseFloat(value) > 100 || Number.parseFloat(value) < 0)) {
        return '';
      }
      let totalAmount = 0;

      newData[index][name] = value;
      newData.forEach(val => {
        const { amountAdjustment, percentAdjustment, amount } = val;
        if (amountAdjustment) totalAmount += Number.parseFloat(amountAdjustment);
        if (percentAdjustment) totalAmount += (Number.parseFloat(amount) * Number.parseFloat(percentAdjustment)) / 100;
      });
      dataCreate.amount = `${totalAmount}`;
      if (dataCreate.type === 'INVOICE_LINE_ADJUSTMENT') {
        this.setState({ invoiceLines: newData, dataCreate });
      } else {
        this.setState({ transactionsList: newData, dataCreate });
      }
    } catch (error) {}
  };

  onSelectInvoiceLine = ({ name, value, item, index, isTransaction }) => {
    const { invoiceLines, transactionsList } = this.state;

    if (!isTransaction) {
      const newData = cloneDeep(invoiceLines);
      newData[index][name] = value;
      this.setState({ invoiceLines: newData });
    }
    if (isTransaction) {
      const newData = cloneDeep(transactionsList);
      newData[index][name] = value;
      this.setState({ transactionsList: newData });
    }
  };

  onCancel = () => {
    const { dataCancel } = this.state;
    this.setState({ dataCreate: { ...dataCancel } });
  };

  doGetAccountInfo = () => {
    const { dataCreate } = this.state;
    this.setState({ accountDetail: { id: dataCreate.accountId } });

    // const { getAccountInfo } = this.props;
    // if (dataCreate.accountId) {
    //   getAccountInfo(dataCreate.accountId, ({ success, data }) => {
    //     this.setState({ accountDetail: success ? data : {} });
    //   });
    // }
  };

  doGetUnbilledTransactionSummary = () => {
    const { getUnbilledTransactionSummary } = this.props;
    const { dataCreate } = this.state;
    if (dataCreate.accountId)
      getUnbilledTransactionSummary(dataCreate.accountId, ({ data }) => {
        this.setState({ transactionsList: data || [] });
      });
  };

  render() {
    const {
      dataCreate,
      isOpenModalItem,
      isOpenModalInvoice,
      isOpenModalAccountId,
      isOpenModalTransaction,
      invoiceLines,
      optionResonCodes,
      fixedTaxRuleForAROps,
      currencyOptions,
      accountDetail,
      isPacEnabled,
      isAropsSupportTicket,
      isMandateSatCodeForAdjustments,
      transactionsList,
      isFilterInstallmentInvoicesForAR,
    } = this.state;
    const { permissionsArOps, t, location } = this.props;
    let modeProcessTaxAdjustment = 0;
    let modeApplyAdjustment = 0;
    let modeGetArOpsTaxOptions = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeApplyAdjustment = checkPermissionProcessAdjustment({ listPermission });
      modeProcessTaxAdjustment = checkPermissionProcessTaxAdjustment({ listPermission });
      modeGetArOpsTaxOptions = checkPermissionGetArOpsTaxOptions({ listPermission });
    }

    const invoicesColumns = [
      {
        name: 'name',
        label: 'common:label.name',
      },
      {
        name: 'appliedStart',
        label: 'common:label.startDate',
      },
      {
        name: 'appliedEnd',
        label: 'common:label.endDate',
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
      },
      {
        name: 'unitPrice',
        label: 'common:label.unitPrice',
      },
      // {
      //   name: 'taxCode',
      //   label: 'common:label.taxCode',
      // },
      {
        name: 'amount',
        label:
          dataCreate.type === 'INVOICE_LINE_ADJUSTMENT' ? 'common:label.invoiceLineAmount' : 'common:label.lineAmount',
      },
      {
        name: 'percentAdjustment',
        label: 'common:label.percentAdjustment',
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.percentAdjustment}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeInvoiceLine({ name, value, item, index: idx })}
              name="percentAdjustment"
              type="number"
              disabled={!item.isChecked || item.amountAdjustment}
              isNoRenderCtrolButton
            />
          );
        },
      },
      {
        name: 'amountAdjustment',
        label: 'common:label.amountAdjustment',
        render: (name, item, idx) => {
          let value = item.amountAdjustment;
          if (item.percentAdjustment) {
            value = (item.amount * Number.parseFloat(item.percentAdjustment)) / 100;
          }
          return (
            <GenericInput
              value={value}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeInvoiceLine({ name, value, item, index: idx })}
              name="amountAdjustment"
              type="number"
              disabled={!item.isChecked}
              readOnly={item.percentAdjustment}
              isNoRenderCtrolButton
            />
          );
        },
      },
    ];

    const invoiceLinesColums = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.isChecked}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onSelectInvoiceLine({ name, value, item, index: idx })}
              name="isChecked"
              type="checkbox"
              checked={item.isChecked}
            />
          );
        },
      },
      ...invoicesColumns,
    ];

    const unbilledTransactionLinesColums = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.isChecked}
              wrapperClass="col-md-12"
              onChange={({ name, value }) =>
                this.onSelectInvoiceLine({ name, value, item, index: idx, isTransaction: true })
              }
              name="isChecked"
              type="checkbox"
              checked={item.isChecked}
            />
          );
        },
      },
      ...invoicesColumns,
    ];

    return (
      <div className="container-fluid">
        <div className="row">
          {modeApplyAdjustment !== 0 && (
            <div className="content-wrapper pl-0 mr-3">
              <PageTitle
                linkTo={{
                  pathname: RouteName.arOperationsAdjustments.path,
                  state: location?.state ? location.state : null,
                }}
                titleBtn={t('label.back')}
                titleRight={t('arOperationPage:sidebar.adjustments')}
              />
              <div className="col-md-12 mb-30">
                <ArOperationsForm
                  title={t('label.applyAdjustment')}
                  listFields={
                    dataCreate.customReason
                      ? adjustmentFieldApply(
                          t,
                          dataCreate.type && dataCreate.type === 'INVOICE_LINE_ADJUSTMENT',
                          dataCreate,
                          optionResonCodes,
                          fixedTaxRuleForAROps,
                          isDisablePercent,
                          isAropsSupportTicket,
                          isMandateSatCodeForAdjustments
                        )
                      : adjustmentFieldApply(
                          t,
                          dataCreate.type && dataCreate.type === 'INVOICE_LINE_ADJUSTMENT',
                          dataCreate,
                          optionResonCodes,
                          fixedTaxRuleForAROps,
                          isDisablePercent,
                          isAropsSupportTicket,
                          isMandateSatCodeForAdjustments
                        ).filter(val => val.name !== 'invoiceCancellation')
                  }
                  data={dataCreate}
                  isApply
                  linkTo={RouteName.arOperationsAdjustments.path}
                  onChange={this.onHandleChange}
                  onClick={this.onOpenModal}
                  onBlurAmount={this.onBlurAmount}
                  onHandleSubmit={this.onHandleSubmit}
                  modeProcessTaxAdjustment={modeProcessTaxAdjustment}
                  modeApplyAdjustment={modeApplyAdjustment}
                  onOpenPopupInfo={this.onOpenPopupInfo}
                  modeGetArOpsTaxOptions={modeGetArOpsTaxOptions}
                  invoiceLinesColums={invoiceLinesColums}
                  invoiceLines={invoiceLines}
                  onCancel={this.onCancel}
                  currencyOptions={currencyOptions}
                  customerInfo={accountDetail}
                  isRenderHeaderForm
                  transactionsList={transactionsList}
                  unbilledTransactionLinesColums={unbilledTransactionLinesColums}
                />
              </div>
            </div>
          )}
          <ModalInvoiceApplyPayment
            isOpen={isOpenModalInvoice}
            onSelect={this.onSelectInvoice}
            accountId={dataCreate.accountId}
            disabledSearchFields={dataCreate.accountId ? ['organization', 'clientId'] : null}
            selectedParentId={dataCreate.invoiceId}
            isAdditionalFilters
            onCancel={() => {
              this.setState({ isOpenModalInvoice: false });
            }}
            isNoSetDefaultDue
            fieldsEnableCheck={
              isPacEnabled ? { folioStatus: ['STAMPED', 'COLLECTION'] } : { status: ['ACTIVE', 'APPROVED'] }
            }
            defaultSearchForm={isPacEnabled ? { folioStatus: 'STAMPED' } : null}
            // fieldsEnableCheck={
            //   dataCreate.invoiceCancellation && dataCreate.invoiceCancellation === 'TRUE'
            //     ? {
            //         status: ['STAMPED'],
            //       }
            //     : null
            // }
            // fieldDisableCheck={
            //   !dataCreate.invoiceCancellation || dataCreate.invoiceCancellation !== 'TRUE'
            //     ? {
            //         status: ['CLOSED', 'CANCELLATION_STAMPING_REQUESTED', 'CANCELLATION_STAMPED'],
            //       }
            //     : null
            // }
            invoiceCancellation={dataCreate.invoiceCancellation}
            filterInstallmentInvoices={isFilterInstallmentInvoicesForAR}
          />
          <ModalAccountId
            isOpen={isOpenModalAccountId}
            onSelect={this.onSelectAccountId}
            accountId={dataCreate.accountId}
            selectedParentId={dataCreate.accountId}
            onCancel={() => {
              this.setState({ isOpenModalAccountId: false });
            }}
          />
          <ModalAccountItems
            isOpen={isOpenModalItem}
            onSelect={this.onSelectItem}
            selectedParentId={dataCreate.itemId}
            accountId={dataCreate.accountId}
            invoiceUnitId={dataCreate.invoiceId}
            onCancel={() => {
              this.setState({ isOpenModalItem: false });
            }}
          />
        </div>
        <ModalTransactions
          accountId={dataCreate.accountId}
          onToggle={() => this.setState({ isOpenModalTransaction: false })}
          isOpen={isOpenModalTransaction}
        />
      </div>
    );
  }
}

AdjustmentApply.propTypes = {
  processAdjustment: PropTypes.func.isRequired,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

AdjustmentApply.defaultProps = {
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    processAdjustment,
    getInvoiceLinesById,
    getARReasonCodeConfig,
    getCcpPropertiesConfig,
    getAccountInfo,
    getCurrencyConfigApp,
    getUnbilledTransactionSummary,
  })(AdjustmentApply)
);
