import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import utils from '../../../utils/utils';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import listCurrency from '../../../constantsApp/currency.json';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import { getARThresholds, modifyARThresholds, createARThresholds } from '../actions';
import { FormWithTableItem, DataTable, GenericInput, ModalViewNote } from '../../../components/common';
import ModalReasonCodeConfig from '../../../components/common/ModalReasonCodeConfig';
import ModalUserGroup from '../../../components/common/ModalUserGroup';
import { messageNotes } from '../constant';
import {
  checkPermissionGetConfigArOpsThresholds,
  checkPermissionModifyConfigArOpsThresholds,
  checkPermissionCreateConfigArOpsThresholds,
} from '../CheckPermission';

class ARThresholds extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    data: [],
    isSearching: true,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    id: null,
    isOpenModalNotes: false,
    wasValidated: false,
    formValid: true,
    isSubmitting: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetARThresholds();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
      if (name === 'arOpsType') {
        dataSubmit[indexDataSubmit].adjustmentARType = null;
        dataSubmit[indexDataSubmit].disputeARType = null;
        dataSubmit[indexDataSubmit].writeOffARType = null;
      }
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    if (name === 'arOpsType') {
      newData[indexItemChange].adjustmentARType = null;
      newData[indexItemChange].disputeARType = null;
      newData[indexItemChange].writeOffARType = null;
    }
    this.setState({ data: newData });
  };

  doGetARThresholds = () => {
    const { getARThresholds } = this.props;
    getARThresholds(({ data }) => {
      this.setState({
        isSearching: false,
        isModify: !!(data && data.id),
        data: data ? data.configArOpsThresholdsList : [],
        id: data ? data.id : null,
        defaultDataFromAPI: data ? data.configArOpsThresholdsList : [],
        wasValidated: false,
      });
    });
  };

  onAddNewProduct = () => {
    let indexNewItem = 0;
    const { data, dataSubmit } = this.state;
    let newData = cloneDeep(data);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      currencyId: null,
      customReasonCode: null,
      minThresholdToSkipNotes: null,
      maxThreshold: null,
      arOpsReason: null,
      arOpsType: null,
      adjustmentARType: null,
      disputeARType: null,
      writeOffARType: null,
    };
    // newData.push(newDataItem);
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ data: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { data, defaultDataFromAPI, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexItemFromAPI = defaultDataFromAPI.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemFromAPI > -1 && indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    if (indexItemFromAPI === -1 && indexItemRemove > -1) {
      newData.splice(indexItemRemove, 1);
      if (indexDataSubmit > -1) {
        dataSubmit.splice(indexDataSubmit, 1);
      }
    }
    this.setState({ data: newData, dataSubmit });
  };

  onHanldeSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit, isModify, id } = this.state;
    const { modifyARThresholds, createARThresholds } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (isModify) {
      const newPayloadModify = {
        configArOpsThresholdsList: dataSubmit,
        id,
      };
      modifyARThresholds(newPayloadModify, ({ success }) => {
        if (success) this.doGetARThresholds();
      });
    } else {
      const dataRemoveIndex = cloneDeep(dataSubmit).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        configArOpsThresholdsList: dataRemoveIndex,
      };
      createARThresholds(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetARThresholds();
        }
      });
    }
  };

  onOpenReasonCode = ({ item }) => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, itemSelect: item });
  };

  onSelectReason = (val, row) => {
    const { data, dataSubmit, itemSelect } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = newData.findIndex(item => item.index === itemSelect.index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === itemSelect.index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit].customReasonCode = row.customReasonCode || '';
    } else {
      dataSubmit.push({ index: itemSelect.index });
      dataSubmit[dataSubmit.length - 1].customReasonCode = row.customReasonCode || '';
    }
    newData[indexItemChange].customReasonCode = row.customReasonCode || '';
    this.setState({ data: newData, isOpen: false });
  };

  onToggleModalNotes = () => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes });
  };

  onSelectGroupId = (id, row) => {
    const { data, dataSubmit, itemSelectUserGroup } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = newData.findIndex(item => item.index === itemSelectUserGroup.index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === itemSelectUserGroup.index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit].userGroupId = row.groupId || '';
    } else {
      dataSubmit.push({ index: itemSelectUserGroup.index });
      dataSubmit[dataSubmit.length - 1].userGroupId = row.groupId || '';
    }
    newData[indexItemChange].userGroupId = row.groupId || '';
    this.setState({ data: newData, isOpenModalUserGroup: false, itemSelectUserGroup: null });
  };

  render() {
    const {
      data,
      isSearching,
      itemSelect,
      isModify,
      isOpen,
      isOpenModalNotes,
      wasValidated,
      itemSelectUserGroup,
      isOpenModalUserGroup,
    } = this.state;
    const { t, permissionsArOps } = this.props;

    let modeCreateConfigArOpsThresholds = 0;
    let modeModifyConfigArOpsThresholds = 0;
    let modeGetConfigArOpsThresholds = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeCreateConfigArOpsThresholds = checkPermissionCreateConfigArOpsThresholds({ listPermission });
      modeModifyConfigArOpsThresholds = checkPermissionModifyConfigArOpsThresholds({ listPermission });
      modeGetConfigArOpsThresholds = checkPermissionGetConfigArOpsThresholds({ listPermission });
    }

    if (!modeGetConfigArOpsThresholds) return '';

    const creditProfileColumns = [
      {
        name: 'arOpsType',
        label: 'label.arAction',
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.arOpsType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="arOpsType"
            required
            type="select"
            tOptions="selections:arAction"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'adjustmentARType',
        label: 'label.arOpsLevel',
        style: { textAlign: 'center', minWidth: '220px' },
        render: (colName, item) => {
          const newTypeGetFromAction = {
            name: '',
            tOptions: '',
          };
          if (item.arOpsType === 'ADJUSTMENT') {
            newTypeGetFromAction.name = 'adjustmentARType';
            newTypeGetFromAction.tOptions = 'selections:adjustmentARType';
          }
          if (item.arOpsType === 'DISPUTE') {
            newTypeGetFromAction.name = 'disputeARType';
            newTypeGetFromAction.tOptions = 'selections:disputeARType';
          }
          if (item.arOpsType === 'WRITEOFF') {
            newTypeGetFromAction.name = 'writeOffARType';
            newTypeGetFromAction.tOptions = 'selections:writeOffARType';
          }
          return (
            <GenericInput
              value={item && item[newTypeGetFromAction.name] ? item[newTypeGetFromAction.name] : item.arOpsLevel}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
              disabled={!item.arOpsType}
              type="select"
              menuPortalTarget
              {...newTypeGetFromAction}
              // tOptions="selections:adjustmentARType"
              // name="adjustmentARType"
            />
          );
        },
      },
      {
        name: 'arOpsReason',
        label: 'label.reasonCode',
        style: { textAlign: 'center', minWidth: '250px' },
        render: (colName, item) => (
          <GenericInput
            value={item.arOpsReason}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="arOpsReason"
            type="select"
            tOptions="selections:reasonConfig"
            menuPortalTarget
            disabled={!!item.customReasonCode}
            isClearable
          />
        ),
      },
      {
        name: 'customReasonCode',
        label: 'label.customReasonCode',
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.customReasonCode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="customReasonCode"
            fa="fa fa-external-link"
            disabled={!!item.arOpsReason}
            onClick={() => this.onOpenReasonCode({ item })}
          />
        ),
      },
      {
        name: 'currencyId',
        label: 'label.currency',
        style: { minWidth: '180px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.currencyId}
            wrapperClass="col-md-12"
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="currencyId"
            options={utils.convertCurrency2Option(listCurrency.currencies)}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        style: { textAlign: 'center', minWidth: '280px' },
        render: (colName, item) => (
          <GenericInput
            value={item.accountType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountType"
            type="select"
            tOptions="selections:accountType"
            menuPortalTarget
            isClearable
          />
        ),
      },
      {
        name: 'userGroupId',
        label: 'label.userGroup',
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.userGroupId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="userGroupId"
            fa="fa fa-external-link"
            onClick={() =>
              this.setState({ isOpenModalUserGroup: true, itemSelectUserGroup: item, indexSelectedUserGroup: idx })
            }
          />
        ),
      },
      {
        name: 'minThresholdToSkipNotes',
        label: 'label.miximumThreshold',
        style: { minWidth: '160px', textAlign: 'center' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.minThresholdToSkipNotes}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="minThresholdToSkipNotes"
            required
          />
        ),
      },
      {
        name: 'maxThreshold',
        label: 'label.maxThreshold',
        style: { minWidth: '160px', textAlign: 'center' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.maxThreshold}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="maxThreshold"
            required
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div className="content-wrapper">
        <div className="col-md-12 mb-30">
          <PageTitle
            linkTo={RouteNames.arOperationsAdjustments.path}
            titleBtn={t('label.back')}
            items={[
              {
                name: t('arOperationPage:sidebar.ARThresholds'),
              },
            ]}
          />
          <FormWithTableItem title={t('arOperationPage:sidebar.ARThresholds')}>
            <form
              onSubmit={modeModifyConfigArOpsThresholds === 2 ? this.onHanldeSubmit : () => {}}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
              noValidate
              ref={this.formRef}
            >
              <div>
                <div className="form-group col-md-12 buttons-attibute">
                  {modeCreateConfigArOpsThresholds === 2 && (
                    <button
                      type="button"
                      disabled={modeCreateConfigArOpsThresholds === 1}
                      className="button button-border black x-small"
                      onClick={this.onAddNewProduct}
                    >
                      +
                      {t('label.addNewThreshold')}
                    </button>
                  )}
                  {modeModifyConfigArOpsThresholds === 2 && (
                    <button
                      type="submit"
                      className="button button-border x-small float-right"
                      // onClick={() => this.onHanldeSubmit()}
                    >
                      {isModify ? t('label.modify') : t('label.createConfig')}
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => this.doGetARThresholds()}
                    className="button button-border black x-small float-right"
                  >
                    {t('label.cancel')}
                  </button>
                  <button
                    type="button"
                    className="button button-border gray x-small float-right"
                    onClick={this.onToggleModalNotes}
                  >
                    {t('label.quickNotes')}
                    <i className="fa fa-external-link ml-2" aria-hidden="true" />
                  </button>
                </div>
                <DataTable
                  columns={creditProfileColumns}
                  isSupportRemoveIndex
                  data={data || []}
                  isLoading={isSearching}
                />
              </div>
              <br />
            </form>
          </FormWithTableItem>
        </div>
        <ModalReasonCodeConfig
          isOpen={isOpen}
          onSelect={this.onSelectReason}
          onCancel={() => this.setState({ isOpen: false })}
          selectedParentId={itemSelect ? itemSelect.indexSelected : null}
        />
        <ModalViewNote isOpen={isOpenModalNotes} message={messageNotes(t)} onToggle={this.onToggleModalNotes} />
        <ModalUserGroup
          isOpen={isOpenModalUserGroup}
          onSelect={this.onSelectGroupId}
          onCancel={() =>
            this.setState({ isOpenModalUserGroup: false, indexSelectedUserGroup: null, itemSelectUserGroup: null })
          }
          selectedParentId={itemSelectUserGroup ? itemSelectUserGroup.userGroupId : null}
        />
      </div>
    );
  }
}

ARThresholds.propTypes = {
  getARThresholds: PropTypes.func.isRequired,
  modifyARThresholds: PropTypes.func.isRequired,
  createARThresholds: PropTypes.func.isRequired,
};

ARThresholds.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getARThresholds, modifyARThresholds, createARThresholds })(ARThresholds)
);
