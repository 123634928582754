import gql from 'graphql-tag';

export default id => {
  const getConfigPymtAllocSeqById = `getConfigPymtAllocSeqById(id:"${id}")`;
  return gql`
    query {
      ${getConfigPymtAllocSeqById} {
        index
        taxType
        invoiceSummaryType
      }
    }
  `;
};
