import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import RouteName from '../../../App/RouteNames';
import TitleFrom from '../../../../components/common/TitleFrom';
import { GenericInput } from '../../../../components/common';

class PaymentGatewayAttributes extends React.PureComponent {
  state = { tenantData: [] };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ tenantData: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ tenantData: nextProps.data });
    }
  }

  onChange = ({ value, name, indexAttr, index }) => {
    try {
      const { tenantData } = this.state;
      const newData = cloneDeep(tenantData);
      newData[index || 0].paymentGatewayAttributes[indexAttr][name] = value;
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { modifyTenant, id, getTenantConfig } = this.props;
    const { tenantData } = this.state;
    modifyTenant({ id, tenantMerchantAccounts: tenantData }, () => getTenantConfig());
  };

  render() {
    const { tenantData } = this.state;
    const indexDataUrlMapping =
      tenantData &&
      tenantData.length &&
      tenantData.findIndex(val => val.paymentGatewayAttributes && val.paymentGatewayAttributes.length > 0);
    let dataUrlMapping = [];
    if (indexDataUrlMapping > -1) {
      dataUrlMapping =
        tenantData[indexDataUrlMapping] && tenantData[indexDataUrlMapping].paymentGatewayAttributes
          ? tenantData[indexDataUrlMapping].paymentGatewayAttributes
          : [];
    }
    const { modeModifyPaymentGatewayMerchant, isRequireField, t } = this.props;
    return (
      <form className="col-md-12 card card-statistics" onSubmit={this.onSubmit}>
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('tenantPage:tenantManagementPage.urlMapping')} />
          </div>
        </div>
        {dataUrlMapping.map((item, index) => (
          <div className="group-flex col-md-12 pl-2" key={index}>
            <GenericInput
              label="label.endPointType"
              type="select"
              value={item.type || null}
              wrapperClass="col-md-6"
              required={isRequireField}
              tOptions="selections:paymentGatewayType"
              disabled={modeModifyPaymentGatewayMerchant === 1}
              onChange={({ name, value }) =>
                this.onChange({ value, name, indexAttr: index, index: indexDataUrlMapping })
              }
              name="type"
            />
            <GenericInput
              label="label.endPointURL"
              value={item.url}
              type="text"
              required={isRequireField}
              disabled={modeModifyPaymentGatewayMerchant === 1}
              onChange={({ name, value }) =>
                this.onChange({ value, name, indexAttr: index, index: indexDataUrlMapping })
              }
              name="url"
              wrapperClass="col-md-6"
            />
          </div>
        ))}
        <div className="col-md-12 text-center mt-3">
          <button type="button" className="button button-border gray x-small">
            {t('label.addNewMapping')}
          </button>
        </div>
        <div className="mt-3 mb-3 col-md-12">
          <button type="submit" className="button button-border ml-2 x-small float-right">
            {t('label.saveConfig')}
          </button>
          <NavLink to={RouteName.dashboard.path} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </NavLink>
        </div>
      </form>
    );
  }
}

PaymentGatewayAttributes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  // onHandleChange: PropTypes.func,
  // index: PropTypes.number.isRequired,
  modeModifyPaymentGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

PaymentGatewayAttributes.defaultProps = {
  data: [],
  // onHandleChange: () => {},
  modeModifyPaymentGatewayMerchant: 2,
  isRequireField: false,
};

export default withTranslation('common')(PaymentGatewayAttributes);
