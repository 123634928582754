import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getPageTotalCount } from '../../../utils/utils';
import { DiscountTriggerSearchForm } from '../../../components/PricingHub';
import { DataTable, TablePagination } from '../../../components/common';
import { searchDiscountTriggers } from '../actions';
import { makeGetDiscountTriggerList } from '../selectors';
import {
  checkPermissionGetDiscountOffer,
  checkPermissionSearchDiscountOffers,
  checkPermissionCeateDiscountOffer,
} from '../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';

const discountTriggerSort = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  evaluationComponent: {
    asc: 'evaluationComponent_ASC',
    desc: 'evaluationComponent_DESC',
  },
};

const DiscountTrigger = ({ searchDiscountTriggers, discountTriggerList, permissionsPricing, location }) => {
  const { t } = useTranslation('common');
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isFirstBack, setIsFirstBack] = useState(true);

  let modeSearchDiscountOffers = 0;
  let modeGetDiscountOffer = 0;
  let modeCreateDiscountOffer = 0;

  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermissionPricing = permissionsPricing.pricingModulePermissions;
    modeGetDiscountOffer = checkPermissionGetDiscountOffer({ listPermission: listPermissionPricing });
    modeSearchDiscountOffers = checkPermissionSearchDiscountOffers({ listPermission: listPermissionPricing });
    modeCreateDiscountOffer = checkPermissionCeateDiscountOffer({ listPermission: listPermissionPricing });
  }

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setPage(0);
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doSearchDiscountTrigger = useCallback(() => {
    setLoading(true);
    let newFilter = filter;
    if (isFirstBack && location?.state?.filter) {
      newFilter = location?.state?.filter;
      setIsFirstBack(false);
      setFilter(location?.state?.filter);
    }
    searchDiscountTriggers(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? discountTriggerSort[sorted.sortCol][sorted.sortDir] : null,
      },
      () => setLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDiscountTriggers, page, size, filter, sorted]);

  useEffect(() => {
    doSearchDiscountTrigger();
  }, [doSearchDiscountTrigger]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: discountTriggerList || [] }));
  }, [page, size, discountTriggerList, totalCount]);

  if (!modeSearchDiscountOffers) return null;

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
    },
    {
      name: 'name',
      label: 'label.name',
      sortable: true,
      render: (colName, item) =>
        modeGetDiscountOffer ? (
          <Link
            className="text-success"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.discountTriggerEdit.path, item.name),
              state: { filter },
            }}
          >
            {item.name}
          </Link>
        ) : (
          <span>{item.name}</span>
        ),
    },
    {
      name: 'evaluationComponent',
      label: 'label.evaluationComponent',
      isRenderT: true,
      sortable: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:evaluationComponent')().find(val => val.value === item.evaluationComponent) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'accumulatorId',
      label: 'label.accumulatorId',
    },
    {
      name: 'comparisonOperator',
      label: 'label.comparisonOperator',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:comparisonOperator')().find(val => val.value === item.comparisonOperator) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'value',
      label: 'label.value',
    },
  ];

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={
          modeCreateDiscountOffer === 2
            ? { pathname: RouteNames.discountTriggerAdd.path, state: { filter } }
            : RouteNames.productFamilyPricing.path
        }
        titleBtn={t(modeCreateDiscountOffer === 2 ? 'label.createNewDiscountTrigger' : 'label.back')}
        items={[
          { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.productFamilyPricing.path },
          { name: t('pricingPage:sidebar.discountTrigger') },
        ]}
      />
      <br />
      <div className="card card-statistics mb-30">
        <div className="card-body">
          <div className="repeater-file">
            <div>
              <DiscountTriggerSearchForm
                onSubmit={onHandleSubmit}
                defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <DataTable
              columns={tableColumns}
              data={discountTriggerList || []}
              isLoading={isLoading}
              onSort={onSortColumn}
              sorted={sorted}
            />
          </div>
        </div>
      </div>
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
    </div>
  );
};
DiscountTrigger.propTypes = {
  searchDiscountTriggers: PropTypes.func.isRequired,
  discountTriggerList: PropTypes.arrayOf(PropTypes.any),
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
};

DiscountTrigger.defaultProps = {
  discountTriggerList: [],
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  discountTriggerList: makeGetDiscountTriggerList() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});
export default connect(mapStateToProps, { searchDiscountTriggers })(DiscountTrigger);
