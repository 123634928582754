import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { makeErrorMessage } from '../selectors';
import { selectPackageIdList } from '../../App/actions';
import ModalMultipleSelector from '../../../components/Modals/ModalMultipleSelector';
import ModalSelector from '../../../components/Modals/ModalSelector';
import { ParentSearchForm } from '../../../components/PricingHub';

class ModalPackageItem extends Component {
  state = {};

  componentDidMount() {}

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
      ],
    };
    const { t, isMultipe } = this.props;
    const ModalSelect = isMultipe ? ModalMultipleSelector : ModalSelector;

    return (
      <ModalSelect
        modalTitle={t('label.choosePackage')}
        isOpen={this.props.isOpen}
        SearchForm={ParentSearchForm}
        searchItems={this.props.selectPackageIdList}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectedItemId={this.props.selectedPackageId}
        isRequired={false}
      />
    );
  }
}

ModalPackageItem.propTypes = {
  isOpen: PropTypes.bool,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedPackageId: PropTypes.any,
  selectPackageIdList: PropTypes.func,
  errorMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
});

export default withTranslation('common')(connect(mapStateToProps, { selectPackageIdList })(ModalPackageItem));
