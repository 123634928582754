import gql from 'graphql-tag';

export default ({ id, page, size }) => gql`
  query {
    getOperatorInvoiceById(input: { id: "${id}", page: ${page}, size: ${size} }) {
      id
      index
      regId
      nir
      nim
      recordDate
      trafficType
      destinationSeries
      originSeries
      callType
      pdic
      acctid
      numCalls
      numSeconds
      billedRate
      recogRate
    }
  }
`;
