import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, TitleFrom, Switch, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getDepartment, modifyDepartment, createDepartment } from '../actions';
import ModalCompanyLocation from '../../../components/common/ModalCompanyLocation';
import ModalCompanyEnterprise from '../../../components/common/ModalCompanyEnterprise';
import ModalBusinessUnits from '../../../components/common/ModalBusinessUnits';

class Department extends React.PureComponent {
  state = {
    data: [],
    wasValidated: false,
    formValid: true,
    showActiveOnly: true,
    isOpenModalName: '',
    locationSelect: {},
    enterpriseSelect: {},
    businessUnitSelect: {},
    dataSubmit: [],
    defaultData: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetDepartment();
    // this.checkExistData();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetDepartment = () => {
    const { getDepartment } = this.props;
    const { showActiveOnly } = this.state;
    getDepartment({ showActiveOnly }, ({ data }) => {
      this.setState({
        data: data || [],
        dataSubmit: [],
      });
    });
    this.checkExistData();
  };

  checkExistData = () => {
    const { getDepartment } = this.props;
    getDepartment({ showActiveOnly: false }, ({ data }) => {
      this.setState({
        isModify: !!data,
        id: data ? data[0].id : null,
        defaultData: data || [],
      });
    });
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit, defaultData } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (defaultData)
        defaultData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        name: '',
        description: '',
        code: '',
        enterprise: null,
        businessUnit: null,
        locationIndex: null,
        status: null,
      };
      newData = [payload, ...newData];
      // newData.push(payload);
      dataSubmit[newData.length - 1] = payload;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit, id } = this.state;
    const { modifyDepartment, createDepartment } = this.props;
    const payload = cloneDeep(data).map(val => {
      const { address, id, ...rest } = val;
      return rest;
    });
    if (isModify) {
      return modifyDepartment({ departmentList: dataSubmit.filter(val => !!val), id }, ({ success }) => {
        if (success) this.doGetDepartment();
      });
    }
    return createDepartment({ departmentList: payload }, ({ success }) => {
      if (success) this.doGetDepartment();
    });
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      if (!dataSubmit[index]) dataSubmit[index] = { index: newData[index].index };
      dataSubmit[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSwitch = () => {
    const { showActiveOnly } = this.state;
    this.setState({ showActiveOnly: !showActiveOnly }, () => this.doGetDepartment());
  };

  onOpenPopup = (name, index) => {
    this.setState({ isOpenModalName: name, indexSelect: index });
  };

  onSelectItem = (value, row) => {
    const { data, isOpenModalName, indexSelect, dataSubmit } = this.state;
    let { locationSelect, enterpriseSelect, businessUnitSelect } = this.state;
    const newData = cloneDeep(data);
    if (!dataSubmit[indexSelect]) dataSubmit[indexSelect] = { index: newData[indexSelect].index };
    if (isOpenModalName === 'locationIndex') {
      if (value) {
        newData[indexSelect].address = row || null;
        newData[indexSelect].locationIndex = value;
        locationSelect = row;
        dataSubmit[indexSelect].locationIndex = value;
      }
      if (!value) {
        newData[indexSelect].locationIndex = null;
        newData[indexSelect].address = null;
        locationSelect = {};
        dataSubmit[indexSelect].locationIndex = null;
      }
    }
    if (isOpenModalName === 'enterprise') {
      if (value) {
        newData[indexSelect].enterprise = value;
        // newData[indexSelect].locationIndex = row.locationIndex;
        // newData[indexSelect].address = row.address || null;
        enterpriseSelect = row || null;
        dataSubmit[indexSelect].enterprise = value;
      }
      if (!value) {
        newData[indexSelect].enterprise = null;
        // newData[indexSelect].locationIndex = null;
        // newData[indexSelect].address = null;
        enterpriseSelect = {};
        dataSubmit[indexSelect].enterprise = null;
      }
    }

    if (isOpenModalName === 'businessUnit') {
      if (value) {
        newData[indexSelect].businessUnit = value;
        newData[indexSelect].enterprise = row.enterprise;
        businessUnitSelect = row || null;
        dataSubmit[indexSelect].businessUnit = value;
        dataSubmit[indexSelect].enterprise = row.enterprise;
      }
      if (!value) {
        newData[indexSelect].businessUnit = null;
        newData[indexSelect].enterprise = null;
        businessUnitSelect = {};
        dataSubmit[indexSelect].businessUnit = null;
        dataSubmit[indexSelect].enterprise = null;
      }
    }

    this.setState({ data: newData, isOpenModalName: '' });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateDepartmentConfig, modeGetDepartmentConfig, modeModifyDepartmentConfig } = permissions;
    if (!modeGetDepartmentConfig) return '';
    const {
      data,
      wasValidated,
      showActiveOnly,
      isOpenModalName,
      locationSelect,
      enterpriseSelect,
      businessUnitSelect,
    } = this.state;
    const listFieldsDepartment = [
      {
        name: 'name',
        label: 'label.name',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.name}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="name"
          />
        ),
      },
      {
        name: 'code',
        label: 'label.code',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.code}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="code"
          />
        ),
      },
      {
        name: 'enterprise',
        label: 'label.enterprise',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.enterprise}
            wrapperClass="col-md-12"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('enterprise', index)}
            onChange={() => {}}
            name="enterprise"
            required
          />
        ),
      },
      {
        name: 'businessUnit',
        label: 'label.businessUnit',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.businessUnit}
            wrapperClass="col-md-12"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('businessUnit', index)}
            onChange={() => {}}
            name="businessUnit"
            required
          />
        ),
      },
      {
        name: 'locationIndex',
        label: 'label.location',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.address ? `${item.address.city}, ${item.address.state}, ${item.address.country}` : ''}
            wrapperClass="col-md-12"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('locationIndex', index)}
            onChange={() => {}}
            name="locationIndex"
            required
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.description}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="description"
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        // required: true,
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.status}
            // required
            wrapperClass="col-md-12"
            type="select"
            tOptions="selections:status"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="status"
            menuPortalTarget
          />
        ),
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.departments')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.departments')} subClass="border-bottom" />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <br />
              <div className="col-md-12 pt-0 pb-5">
                <Switch
                  onChange={this.onChangeSwitch}
                  wrapperClass={showActiveOnly ? 'switch-active float-right' : 'switch-non-active float-right'}
                  rightTitle={t('label.ACTIVE')}
                  checked={showActiveOnly}
                  title={t('label.all')}
                />
                <span className="float-right title-fetch">{`${t('label.fetch')}:`}</span>
              </div>
              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateDepartmentConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.addNewItem}
                  >
                    +
                    {t('label.addNewDepartment')}
                  </button>
                )}
                {modeModifyDepartmentConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeModifyDepartmentConfig === 1 || !data || !data.length}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetDepartment}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable columns={listFieldsDepartment} data={data} isFixedHeaderTable />
            </form>
          </div>
        </div>
        <ModalCompanyLocation
          isOpen={isOpenModalName === 'locationIndex'}
          onSelect={this.onSelectItem}
          selectedParentId={locationSelect.locationIndex}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalCompanyEnterprise
          isOpen={isOpenModalName === 'enterprise'}
          onSelect={this.onSelectItem}
          selectedParentId={enterpriseSelect.enterprise}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalBusinessUnits
          isOpen={isOpenModalName === 'businessUnit'}
          onSelect={this.onSelectItem}
          selectedParentId={businessUnitSelect.businessUnit}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
      </div>
    );
  }
}

Department.propTypes = {
  getDepartment: PropTypes.func,
  modifyDepartment: PropTypes.func,
};

Department.defaultProps = {
  getDepartment: () => {},
  modifyDepartment: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getDepartment,
    modifyDepartment,
    createDepartment,
  })(withRouter(Department))
);
