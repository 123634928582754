export default {
  font: { sz: '13', bold: false, color: { rgb: 'FFFFFF' } },
  fill: { patternType: 'solid', fgColor: { rgb: '00A651' } },
  border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
};

// export default {
//   font: { sz: '13', bold: false, color: { rgb: 'FFFFFF' }, vertAlign: true },
//   fill: { patternType: 'solid', fgColor: { rgb: '00A651' } },
//   border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
//   alignment: { textRotation: 90, vertical: 'center' },
// };
