import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, []);
  return gql`
  query {
    getConfigFailureCodesList(input: ${dataSearch}) {
      value
      description
    }
  }
`;
};
