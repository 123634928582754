import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}`
    : '';
  const searchEmployeeCosting = `searchEmployeeCosting(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchEmployeeCosting} {
        id
        employeeName
        clientName
        jobTitle
        firstPayrollDate
        secondPayrollDate
        startDate
        endDate
        createdDate
        firstSalaryAmount
        secondSalaryAmount
        noOfDaysWorked
        status
        clientJobTitle
        fileName
        clientId
      }
    }
  `;
};
