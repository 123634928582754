import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, uniqBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { TitleFrom, DataTable, GenericInput } from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { saveReratingBatch } from '../../actions';
import ModalMultipleAccountIds from '../../../../components/common/ModalMultipleAccountIds';
import ModalAccountId from '../../../../components/common/ModalAccountId';
import { getUserId } from '../../../../localStorages';

class CreateRerating extends React.PureComponent {
  state = {
    data: { reratingBatchList: [] },
    isLoading: false,
    wasValidated: false,
  };

  onChange = ({ name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[name] = value;
    this.setState({ data: newData });
  };

  onChangeTable = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (value === 'TRUE') newValue = true;
      if (value === 'FALSE') newValue = false;
      newData.reratingBatchList[index][name] = newValue;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.reratingBatchList.splice(index, 1);
    this.setState({ data: newData });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    const { saveReratingBatch, history } = this.props;
    const { data } = this.state;
    const payload = cloneDeep(data);
    if (payload && payload.reratingBatchList && payload.reratingBatchList.length) {
      payload.reratingBatchList = payload.reratingBatchList.map(val => {
        const {
          contacts,
          backoutOnly,
          usageOnly,
          unbilledOnly,
          index,
          startDate,
          clientId,
          accountId,
          id,
          rerateReasonCode,
          ...rest
        } = val;
        return {
          index: '-1',
          startDate: startDate || moment(new Date()).format('YYYY-MM-DD'),
          accountId: id || accountId,
          backoutOnly: backoutOnly ? 'true' : 'false',
          usageOnly: usageOnly ? 'true' : 'false',
          unbilledOnly: unbilledOnly ? 'true' : 'false',
          clientId: clientId || null,
          rerateReasonCode: rerateReasonCode || null,
          organization: contacts && contacts[0] && contacts[0].organization ? contacts[0].organization : null,
        };
      });
    }
    return saveReratingBatch({ ...payload, status: 'PENDING', userId: getUserId() || '' }, ({ success, data }) => {
      if (success) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.reratingModify.path, data.id),
          state: { ...payload, status: 'PENDING', userId: getUserId() || '' },
        });
      }
    });
  };

  onCancel = () => {
    this.setState({ data: { reratingBatchList: [] } });
  };

  onSelectMultipleAccountId = (id, row) => {
    if (!id || !row) return this.setState({ isOpenModalMultipleAccountId: false });
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (row && row.length) {
      const payload = row.map(val => {
        return { ...val, backoutOnly: true, usageOnly: true, unbilledOnly: true };
      });
      newData.reratingBatchList = uniqBy([...newData.reratingBatchList, ...payload], 'id');
      // newData.reratingBatchList = [...newData.reratingBatchList, ...payload];
    }
    return this.setState({ data: newData, isOpenModalMultipleAccountId: false });
  };

  onSelectAccountId = (id, row) => {
    if (!id || !row) return this.setState({ isOpenModalAccountId: false });
    const { data, indexAccountSelected } = this.state;
    const { t } = this.props;
    const newData = cloneDeep(data);
    let isAccountIdExist = false;
    if (newData?.reratingBatchList) {
      isAccountIdExist = !!newData.reratingBatchList.find(val => val.accountId === id);
    }
    if (isAccountIdExist) return toast.error(t('common:message.messageAccountExist'));

    newData.reratingBatchList[indexAccountSelected].accountId = id;
    newData.reratingBatchList[indexAccountSelected].id = id;
    newData.reratingBatchList[indexAccountSelected].clientId = row.clientId;
    newData.reratingBatchList[indexAccountSelected].organization =
      row.contacts && row.contacts[0] && row.contacts[0].organization ? row.contacts[0].organization : null;
    return this.setState({ data: newData, isOpenModalAccountId: false });
  };

  render() {
    const {
      t,
      permissions: { modeCreateReratingBatch },
      location,
    } = this.props;
    if (modeCreateReratingBatch !== 2) return '';
    const { data, isLoading, wasValidated, isOpenModalMultipleAccountId, isOpenModalAccountId } = this.state;
    const listReratingBatchColumns = [
      {
        name: 'id',
        label: 'common:label.batchId',
      },
      {
        name: 'userId',
        label: 'common:label.userId',
        value: getUserId() || '',
      },
      {
        name: 'status',
        label: 'common:label.status',
        type: 'select',
        tOptions: 'selections:reratingBatchStatus',
        disabled: true,
        readOnly: true,
        value: 'PENDING',
      },
    ];
    const tableColumns = [
      {
        name: 'id',
        label: 'label.accountId',
        style: { minWidth: '200px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.id}
              name="id"
              onChange={({ name, value }) => this.onChangeTable({ index, name, value })}
              wrapperClass="col-md-12"
              onClick={() => {
                this.setState({ isOpenModalAccountId: true, indexAccountSelected: index });
              }}
              fa="fa fa-external-link"
              readOnly
            />
          );
        },
      },
      {
        name: 'clientId',
        label: 'label.clientId',
        style: { minWidth: '150px' },
        render: (colName, item) => (
          <GenericInput
            value={item && item.clientId ? item.clientId : ''}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="clientId"
            readOnly
          />
        ),
      },
      {
        name: 'organization',
        label: 'label.organization',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null}
              wrapperClass="col-md-12"
              onChange={() => {}}
              name="organization"
              readOnly
            />
          );
        },
      },
      {
        name: 'startDate',
        label: 'label.startDate',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.startDate || moment(new Date()).format('YYYY-MM-DD')}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="startDate"
          />
        ),
      },
      {
        name: 'backoutOnly',
        label: 'label.backoutOnly',
        style: { minWidth: '120px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.backoutOnly ? 'TRUE' : 'FALSE'}
              name="backoutOnly"
              type="select"
              tOptions="selections:selectBool"
              onChange={({ name, value }) => this.onChangeTable({ index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              // readOnly
              // disabled
            />
          );
        },
      },
      {
        name: 'usageOnly',
        label: 'label.usageOnly',
        style: { minWidth: '120px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.usageOnly ? 'TRUE' : 'FALSE'}
              name="usageOnly"
              type="select"
              tOptions="selections:selectBool"
              onChange={({ name, value }) => this.onChangeTable({ index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              readOnly
              disabled
            />
          );
        },
      },
      {
        name: 'unbilledOnly',
        label: 'label.unbilledOnly',
        style: { minWidth: '120px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.unbilledOnly ? 'TRUE' : 'FALSE'}
              name="unbilledOnly"
              type="select"
              tOptions="selections:selectBool"
              onChange={({ name, value }) => this.onChangeTable({ index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              readOnly
              disabled
            />
          );
        },
      },
      {
        name: 'rerateReasonCode',
        label: 'label.rerateReasonCode',
        style: { minWidth: '220px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.rerateReasonCode}
              name="rerateReasonCode"
              type="select"
              tOptions="selections:rerateReasonCodes"
              onChange={({ name, value }) => this.onChangeTable({ index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={{
            pathname: RouteNames.rerating.path,
            state: location && location.state ? location.state : null,
          }}
          titleBtn={t('label.back')}
          titleRight={t('label.reratingBatch')}
        />
        <br />
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation col-sm-12 mb-30 card card-statistics ${wasValidated ? 'was-validated' : ''}`}
          noValidate
        >
          <TitleFrom title={t('label.reratingBatch')} />
          <div className="col-md-12 pl-3 pb-4 pt-4 row">
            {listReratingBatchColumns.map(item => (
              <GenericInput
                readOnly
                value={data && data[item.name] ? data[item.name] : ''}
                wrapperClass="col-md-4"
                {...item}
              />
            ))}
          </div>
          <div className="form-group col-md-12 buttons-attibute">
            <button
              type="button"
              className="button button-border black x-small"
              onClick={() => {
                this.setState({ isOpenModalMultipleAccountId: true });
              }}
            >
              +
              {t('label.addMoreAccounts')}
            </button>
            {modeCreateReratingBatch === 2 && (
              <button
                type="submit"
                className="button button-border x-small float-right"
                disabled={!data || !data.reratingBatchList || !data.reratingBatchList.length}
              >
                {t('label.create')}
              </button>
            )}
            <button onClick={this.onCancel} type="button" className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
          </div>
          <DataTable
            columns={tableColumns}
            data={data && data.reratingBatchList ? data.reratingBatchList : []}
            isLoading={isLoading}
            isFixedHeaderTable
          />
        </form>
        <ModalMultipleAccountIds
          isOpen={isOpenModalMultipleAccountId}
          onSelect={this.onSelectMultipleAccountId}
          onCancel={() => {
            this.setState({ isOpenModalMultipleAccountId: false });
          }}
          headerTitle={t('label.selectAccountsForRerating')}
          fieldDisableCheck={{
            status: 'CLOSED',
            type: 'CLIENT',
          }}
          isSupportSelectAll
          // defaultSearchParams={{ type: 'DIRECT_CUSTOMER' }}
        />
        <ModalAccountId
          isOpen={isOpenModalAccountId}
          onSelect={this.onSelectAccountId}
          onCancel={() => {
            this.setState({ isOpenModalAccountId: false });
          }}
          headerTitle={t('label.selectAccountsForRerating')}
          fieldDisableCheck={{
            status: 'CLOSED',
            type: 'CLIENT',
          }}
          // defaultSearchParams={{ type: 'DIRECT_CUSTOMER' }}
        />
      </div>
    );
  }
}

CreateRerating.propTypes = {
  saveReratingBatch: PropTypes.func,
  match: PropTypes.objectOf(PropTypes.any),
};

CreateRerating.defaultProps = {
  saveReratingBatch: () => {},
  match: {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    saveReratingBatch,
  })(withRouter(CreateRerating))
);
