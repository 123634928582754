import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import quoteNS from './constants/quoteNS';

export default data => {
  const newData = data;
  const dataCreate = parseToMutationRequest(newData, quoteNS);
  const convertQuote = `convertQuote(input: ${dataCreate})`;
  const qqlStr = gql`
  mutation {
    ${convertQuote} {
      id
    }
  }
`;
  return qqlStr;
};
