import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';

const MilestoneFormItem = ({ listFields, data, ...rest }) => {
  const { t } = useTranslation('common');
  return (
    <div className="col-md-12 pl-3 pb-4 pt-4 row">
      {listFields.map(item => (
        <GenericInput
          value={data && (data[item.name] || data[item.name] === 0) ? data[item.name] : ''}
          wrapperClass="col-md-4"
          {...rest}
          {...item}
        />
      ))}
    </div>
  );
};
MilestoneFormItem.propTypes = {
  accountNum: PropTypes.string || PropTypes.number,
};

MilestoneFormItem.defaultProps = {
  accountNum: '' || null,
};
export default MilestoneFormItem;
