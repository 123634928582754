import gql from 'graphql-tag';
import { parseToTwoLvArrNSMutationRequest } from '../../utils/utils';

export default data => {
  const setCcpProperties = `setCcpProperties(input: ${parseToTwoLvArrNSMutationRequest(data, [])})`;
  return gql`
    mutation {
      ${setCcpProperties} {
        ccpPropertyList {
          property
          value
        }
      }
    }
  `;
};
