import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { getPageTotalCount } from '../../../utils/utils';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { TablePagination, DataTable, ModalDetailsItem, FormCollapse, GenericInput, PasswordField } from '../../common';
import {
  makeGetCustomerActivity,
  makePageActivityParams,
  makeErrorMessageTU,
} from '../../../containers/CustomerPage/selectors';
import PhoneGroup from '../../PhoneGroup';
import CheckFieldRole from '../CheckFieldRole';
import ActivitiesSearchForm from '../ActivitiesSearchForm';
import { getCustomerActivity, setActivityParams, getActivityById } from '../../../containers/CustomerPage/actions';
import { getApiByGatewayNameConfig } from '../../../containers/App/actions';
import constant, { additionalAddressInformation, collapsibleAddress } from '../../../containers/CustomerPage/constants';
import ModalSearchUsers from '../../common/ModalSearchUsers';
import { getUserId } from '../../../localStorages';

const additionalAddressFields = [
  {
    name: 'extraLine',
    label: 'common:label.extraLine',
  },
  {
    name: 'landmark',
    label: 'common:label.landmark',
  },
  // {
  //   name: 'code',
  //   label: 'common:label.code',
  //   isDisable: true,
  // },
];

class ActivitiesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: {
        accountId: props.id,
        startDate: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      isOpenModal: false,
      isSearchDataModal: false,
      itemData: {},
      activeParentTab: {
        // name: 'Recurring Data',
        // isActive: true,
      },
      activeChildrentTab: {},
      apiNamesOptions: [],
      activeTab: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.activity }),
    };
  }

  componentDidMount() {
    this.doSearchActivities();
  }

  doSearchActivities = () => {
    const { getCustomerActivity } = this.props;
    const { page, size, filter, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    this.setState({ isSearching: true });
    getCustomerActivity(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? constant.activitySort[sorted.sortCol][sorted.sortDir] : null,
      },
      () => this.setState({ isSearching: false })
    );
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = cloneDeep(filter);
    const { myActivity, ...rest } = newFilter;
    rest.accountId = id;
    if (myActivity) {
      rest.userId = getUserId();
    }
    this.setState({ filter: rest }, () => this.doSearchActivities());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchActivities());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchActivities());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchActivities());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetActivityById = id => {
    const { getActivityById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    getActivityById(id, ({ data, success }) =>
      this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  onToggleParentTab = activeTabName => {
    const { activeParentTab } = this.state;
    if (activeParentTab.name === activeTabName) {
      this.setState({
        activeParentTab: {
          name: activeTabName,
          isActive: !activeParentTab.isActive,
        },
        activeChildrentTab: {},
      });
    } else {
      this.setState({
        activeParentTab: {
          name: activeTabName,
          isActive: true,
        },
        activeChildrentTab: {},
      });
    }
  };

  onToggleChildrentTab = activeTabName => {
    const { activeChildrentTab } = this.state;
    if (activeChildrentTab.name === activeTabName) {
      this.setState({
        activeChildrentTab: { name: activeTabName, isActive: !activeChildrentTab.isActive },
      });
    } else {
      this.setState({ activeChildrentTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleAddressTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  renderItem = ({ item, data }) => {
    const { t } = this.props;
    const { activeTab } = this.state;
    if (data && data.length && item.key === 'activityCustomAttributes') {
      return (
        <div className="col-md-12 row p-3">
          <div className="col-md-12 row p-0">
            {data &&
              data.length > 0 &&
              data.map((attr, index) => {
                return (
                  <GenericInput
                    key={index}
                    onChange={() => {}}
                    readOnly
                    disabled
                    value={attr && attr.value ? attr.value : ''}
                    label={attr && attr.name ? attr.name : ''}
                  />
                );
              })}
          </div>
        </div>
      );
    }
    if (data && data.length) {
      return data.map(dt => {
        return (
          <div className="col-md-12 row p-3">
            {item.dataFields &&
              item.dataFields.length > 0 &&
              item.dataFields.map(val => {
                if (val.name === 'phones') {
                  return (
                    <PhoneGroup
                      key={val.name}
                      colDiv="col-md-6 p-0"
                      readOnly
                      isDetailModal
                      isDisabled
                      dataPhone={dt ? dt.phones : ''}
                      {...val}
                      onChange={() => {}}
                    />
                  );
                }
                if (val.name === 'last4CC') {
                  return (
                    <PasswordField
                      key={val.name}
                      readOnly
                      disabled
                      value={dt && dt.creditCards && dt.creditCards[0] ? dt.creditCards[0][val.name] : ''}
                      {...val}
                      onChange={() => {}}
                    />
                  );
                }
                if (['cardToken', 'cardExpiry'].indexOf(val.name) > -1) {
                  return (
                    <GenericInput
                      key={val.name}
                      readOnly
                      disabled
                      value={dt && dt.creditCards && dt.creditCards[0] ? dt.creditCards[0][val.name] : ''}
                      {...val}
                      onChange={() => {}}
                    />
                  );
                }
                return (
                  <GenericInput
                    key={val.name}
                    onChange={() => {}}
                    readOnly
                    disabled
                    value={dt ? dt[val.name] : ''}
                    {...val}
                  />
                );
              })}

            {data && data.length && item.key === 'activityAddresses' && (
              <div className="col-md-12">
                {collapsibleAddress.map(cl => {
                  return (
                    <FormCollapse
                      key={cl.title}
                      isActive={activeTab && cl.state === activeTab.name && activeTab.isActive}
                      title={cl.title}
                      state={cl.state}
                      onToggleTab={name => this.onToggleAddressTab(name)}
                      isSubCollapse
                      isActiveSubForm={activeTab && cl.state === activeTab.name && activeTab.isActive}
                    >
                      <div className="col-md-12 row p-3">
                        {[...additionalAddressInformation, ...additionalAddressFields].map(val => (
                          <GenericInput {...val} value={dt[val.name] || null} readOnly disabled />
                        ))}
                      </div>
                    </FormCollapse>
                  );
                })}
              </div>
            )}
            {dt && dt.roles && (
              <div className="col-md-12 row mt-2 mb-3">
                <span>{t('label.roles')}</span>
                {constant.checkRoleGroup.map((role, index) => {
                  return (
                    <CheckFieldRole
                      key={role.label}
                      label={t && role.label ? t(role.label) : role.label || ''}
                      className={role.className}
                      name={role.value}
                      disabled
                      value={dt && dt.roles.indexOf(role.value) > -1}
                      onChange={() => {}}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    }
  };

  onChangeFilter = ({ name, value }) => {
    const { getApiByGatewayNameConfig, t } = this.props;
    const listOptions = t('selections:merchantType')();
    if (name === 'gatewayType') {
      if (value && listOptions.find(val => val.value === value)) {
        getApiByGatewayNameConfig({ gatewayName: value || null }, ({ success, data }) => {
          if (success && data) {
            const apiNamesOptions = data.map(val => ({ label: val.apiName, value: val.apiName }));
            this.setState({ apiNamesOptions });
          }
        });
      } else {
        this.setState({ apiNamesOptions: [] });
      }
    }
  };

  render() {
    const { activity, id, t, currencyOptions, customerInfo } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      isOpenModal,
      itemData,
      isSearchDataModal,
      activeParentTab,
      activeChildrentTab,
      apiNamesOptions,
    } = this.state;

    let newCollapsible = constant.collapsibleMenuAccountActivities.filter(
      val => val.entityType === itemData.entityType
    );

    let dataFileRqRp = cloneDeep(constant.dataFieldActivity);
    // if (itemData.entityType !== 'INTEGRATION') {
    //   dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
    //   dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'request');
    // }
    if (!itemData.request || itemData.request === 'null') {
      // dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
      dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'request');
    }
    if (!itemData.response || itemData.response === 'null') {
      // dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
      dataFileRqRp = dataFileRqRp.filter(val => val.name !== 'response');
    }
    if (itemData.entityType === 'CUSTOMER') {
      newCollapsible = constant.collapsibleMenuAccountActivities.filter(val => val.entityType !== itemData.entityType);
    }
    if ((itemData.request && itemData.request !== 'null') || (itemData.response && itemData.response !== 'null')) {
      newCollapsible = [];
    }

    const newColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <button type="button" className="text-success no-border" onClick={() => this.doGetActivityById(item.id)}>
            {item.id}
          </button>
        ),
      },
      {
        name: 'select',
        label: 'label.view',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetActivityById(item.id)}
          >
            {t('label.view')}
          </button>
        ),
      },
      ...constant.activityColumns,
    ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('label.customerActivity')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <ActivitiesSearchForm
              onSubmit={this.onHandleSubmit}
              defaultFilter={{
                startDate: moment()
                  .startOf('month')
                  .format('YYYY-MM-DD'),
                endDate: moment()
                  .endOf('month')
                  .format('YYYY-MM-DD'),
              }}
              handleInputChange={this.onChangeFilter}
              isMultipleOptionInject
              optionsInject={{
                apiName: apiNamesOptions,
              }}
              onSelectFilter={this.onSelectFilter}
              ModalSearch={ModalSearchUsers}
            />
            <br />
            <div>
              <DataTable
                columns={newColumns}
                data={activity}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={dataFileRqRp}
          title={t('label.customerActivity')}
          isSearch={isSearchDataModal}
          wrapperClass="modal-custom modal-70"
        >
          <div className="pt-4">
            {newCollapsible.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeParentTab.name && activeParentTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={this.onToggleParentTab}
              >
                {constant.collapsibleMenuBeforeAfter.map(val => (
                  <FormCollapse
                    key={val.title}
                    label="First Name"
                    isActive={val.state === activeChildrentTab.name && activeChildrentTab.isActive}
                    isActiveSubForm={val.state === activeChildrentTab.name && activeChildrentTab.isActive}
                    isSubCollapse
                    title={t(val.title) || ''}
                    state={val.state}
                    onToggleTab={this.onToggleChildrentTab}
                  >
                    {this.renderItem({
                      item,
                      data: itemData[val.key] && itemData[val.key][item.key] ? itemData[val.key][item.key] : null,
                    })}
                  </FormCollapse>
                ))}
                {/* {this.renderItem(item)} */}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  activity: makeGetCustomerActivity() || [],
  params: makePageActivityParams() || {},
  errorMessage: makeErrorMessageTU() || '',
});

ActivitiesPage.propTypes = {
  activity: PropTypes.arrayOf(PropTypes.any),
  getCustomerActivity: PropTypes.func.isRequired,
  getApiByGatewayNameConfig: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ActivitiesPage.defaultProps = {
  id: '',
  activity: [],
};

const newActivitiesPage = connect(mapStateToProps, {
  getCustomerActivity,
  setActivityParams,
  getActivityById,
  getApiByGatewayNameConfig,
})(ActivitiesPage);

export default withTranslation(['common', 'selections'])(newActivitiesPage);
