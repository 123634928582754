import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['status', 'index', 'locationIndex', 'type']);
  const modifyLegalEntity = `modifyLegalEntity(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyLegalEntity} {
      id
    }
  }
`;
};
