export default {
  color: {
    blue:'#28a745',
    indigo:'#6610f2',
    purple:'#6f42c1',
    pink:'#e83e8c',
    red:'#dc3545',
    orange:'#fd7e14',
    yellow:'#ffc107',
    green:'#28a745',
    teal:'#20c997',
    cyan:'#17a2b8',
    white:'#fff',
    gray:'#6c757d',
    grayDark:'#343a40',
    primary:'#28a745',
    secondary:'#6c757d',
    success:'#28a745',
    info:'#17a2b8',
    warning:'#ffc107',
    danger:'#dc3545',
    light:'#f8f9fa',
    dark:'#343a40',
  },
  font: {
  },
  text: {
   
  },
  button: {
   
  },
  screen: {},
  section: {},
  padding: {
  },
}


