import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['gatewayName', 'providerName', 'apiName', 'requestFlag', 'index', 'refIndex']);

  const modifyGatewayCanonicalMapCfg = `modifyGatewayCanonicalMapCfg(input: ${dataModify})`;
  return gql`
    mutation {
      ${modifyGatewayCanonicalMapCfg} {
        id
      }
    }
  `;
};
