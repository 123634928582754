import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, TitleFrom, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getCostCenterMap, saveCostCenterMap } from '../actions';
import { getGLAccountConfig } from '../../App/actions';
import { countryList } from '../../../constantsApp/countryList';

class CostCenterMapping extends React.PureComponent {
  state = {
    data: [],
    wasValidated: false,
    dataSubmit: [],
    glSegment1Options: [],
    id: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    const { permissions, getGLAccountConfig } = this.props;
    const { modeGetCostCenterMap } = permissions;
    if (modeGetCostCenterMap) {
      getGLAccountConfig('', ({ success, data }) => {
        let glSegment1Options = [];
        if (success && data && data[0] && data[0].segments && data[0].segments[0]) {
          const itemSelect = data[0].segments.find(val => val.name === 'COST_CENTER');
          if (itemSelect)
            glSegment1Options = itemSelect.segmentValues.map(val => {
              return { label: `${val.code}-${val.name}`, value: val.code };
            });
        }

        this.setState({ glSegment1Options: glSegment1Options ? sortBy(glSegment1Options, ['label']) : [] });
      });
      this.doGetCostCenterMap();
    }
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetCostCenterMap = () => {
    const { getCostCenterMap } = this.props;
    getCostCenterMap('', ({ data }) => {
      this.setState({
        data: data || [],
        dataSubmit: [],
        isModify: !!data,
        id: data && data[0] && data[0]?.id ? data[0]?.id : null,
      });
    });
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (newData)
        newData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        country: '',
        state: '',
        company: '',
        legalEntity: null,
        businessUnit: null,
        marketSegment: null,
        costCenter: null,
      };
      // newData.push(payload);
      newData = [payload, ...newData];
      dataSubmit.push(payload);
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { dataSubmit, id } = this.state;
    const { saveCostCenterMap } = this.props;
    const payload = { ccMapList: dataSubmit.filter(val => !!val) };
    if (id) {
      payload.id = id;
    }
    return saveCostCenterMap(payload, ({ success }) => {
      if (success) this.doGetCostCenterMap();
    });
  };

  onHandleChange = ({ name, value, index, item }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      const indexDataSubmit = dataSubmit.findIndex(val => val.index === item.index);
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit][name] = value;
      } else {
        dataSubmit.push({ index: item.index });
        dataSubmit[dataSubmit.length - 1][name] = value;
      }
      newData[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t, permissions } = this.props;
    const { modeGetCostCenterMap, modeSaveCostCenterMap } = permissions;
    if (!modeGetCostCenterMap) return '';
    const { data, wasValidated, glSegment1Options } = this.state;
    const listFieldsCostCenter = [
      {
        name: 'country',
        label: 'label.country',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.country}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="country"
            options={countryList}
            isClearable
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'state',
        label: 'label.stateProvince',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.state}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="state"
          />
        ),
      },
      {
        name: 'company',
        label: 'label.company',
        style: { textAlign: 'center', minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.company}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="company"
          />
        ),
      },
      {
        name: 'legalEntity',
        label: 'label.legalEntity',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.legalEntity}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="legalEntity"
          />
        ),
      },
      {
        name: 'businessUnit',
        label: 'label.businessUnit',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.businessUnit}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="businessUnit"
          />
        ),
      },
      {
        name: 'marketSegment',
        label: 'label.marketSegment',
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.marketSegment}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="marketSegment"
            type="select"
            tOptions="selections:marketSegment"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'costCenter',
        label: 'label.costCenter',
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.costCenter}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index, item })}
            name="costCenter"
            type="select"
            options={glSegment1Options}
            isClearable
            menuPortalTarget
          />
        ),
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('revenuePage:sidebar.costCenterMapping')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('revenuePage:sidebar.costCenterMapping')} subClass="border-bottom" />
            <br />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeSaveCostCenterMap !== 0 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    disabled={modeSaveCostCenterMap === 1}
                    onClick={this.addNewItem}
                  >
                    +
                    {t('label.addNewCostCenter')}
                  </button>
                )}
                {modeSaveCostCenterMap !== 0 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeSaveCostCenterMap === 1 || !data || !data.length}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  onClick={this.doGetCostCenterMap}
                  type="button"
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable columns={listFieldsCostCenter} data={data} isFixedHeaderTable />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CostCenterMapping.propTypes = {
  getCostCenterMap: PropTypes.func,
  saveCostCenterMap: PropTypes.func,
};

CostCenterMapping.defaultProps = {
  getCostCenterMap: () => {},
  saveCostCenterMap: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCostCenterMap,
    saveCostCenterMap,
    getGLAccountConfig,
  })(withRouter(CostCenterMapping))
);
