import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { FormWithTableItem, GenericInput, ModalUploadFile } from '../../../components/common';
import { parseToMutationRequestPostMethod } from '../../../utils/utils';
import { uploadMultiPartFiles } from '../../App/actions';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import { checkPermissionBatchPaymentUpload } from '../CheckPermission';
import ModalPaymentItemMap from '../../../components/common/ModalPaymentItemMap';
import { getPaymentItemMap } from '../actions';

class UploadPayment extends PureComponent {
  state = {
    data: [],
    isOpenItemMap: false,
    optionFileName: [],
  };

  static getDerivedStateFromProps(props, state) {
    return {
      optionFileName: props.fileNameOptions,
    };
  }

  onAddOtherFile = () => {
    const { data } = this.state;
    let newData = cloneDeep(data);
    let indexNewItem = 0;
    if (newData && newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    } else {
      newData = [];
    }
    newData.push({
      category: 'PAYMENT',
      name: '',
    });
    this.setState({ data: newData });
  };

  doCancelUpload = () => {
    this.setState({ data: [] });
  };

  onCancel = () => {
    this.setState({ isOpen: false, itemSelect: null });
  };

  onHandleUpload = ({ files }) => {
    const { itemSelect, isOpen, indexSelect, data } = this.state;
    const { uploadMultiPartFiles, onRefresh } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      if (itemSelect.name) {
        // query = `{"query": "mutation{uploadMultipartFiles(input: {category: PAYMENT, name: \\“${itemSelect.name}\\“}){absoluteFile}}"}`;
        query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
          { category: 'PAYMENT', name: itemSelect.name },
          // { id: data.id, file: [{ index: 2 }] },
          ['category']
        )}){absoluteFile}}"}`;
      } else {
        query = '{"query": "mutation{uploadMultipartFiles(input: {category: PAYMENT}){absoluteFile}}"}';
      }
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          // const newData = cloneDeep(data);
          // newData.splice(indexSelect, 1);
          if (onRefresh) onRefresh();
          this.setState({ isOpen: !isOpen, itemSelect: null, data: [] });
        }
      });
    } else {
      this.setState({ isOpen: !isOpen, itemSelect: null });
    }
  };

  onToggleModal = ({ itemSelect, index, type }) => {
    if (type) {
      const { isOpenItemMap } = this.state;
      this.setState({ isOpenItemMap: !isOpenItemMap, itemSelect, indexSelect: index });
    } else {
      const { isOpen } = this.state;
      this.setState({ isOpen: !isOpen, itemSelect, indexSelect: index });
    }
  };

  onChangeMediaFile = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectPaymentName = (id, row) => {
    try {
      const { indexSelect, data } = this.state;
      const newData = cloneDeep(data);
      newData[indexSelect].name = row.fileName;
      this.setState({ isOpenItemMap: false, itemSelect: null, indexSelect: null, data: newData });
    } catch (error) {}
  };

  render() {
    const { data, isOpen, itemSelect, isOpenItemMap, optionFileName } = this.state;
    const { t, permissionsPayment } = this.props;
    let modeBatchPaymentUpload = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermissionPricing = permissionsPayment.paymentModulePermissions;
      modeBatchPaymentUpload = checkPermissionBatchPaymentUpload({ listPermission: listPermissionPricing });
    }
    if (!modeBatchPaymentUpload) return '';

    const dataFieldsUpload = [
      {
        label: 'label.category',
        name: 'category',
        type: 'select',
        tOptions: 'selections:categoryUploadFile',
        disabled: true,
      },
      {
        label: 'label.paymentFileName',
        name: 'name',
        // fa: 'fa fa-external-link',
        // modalType: 'fileName'
        type: 'select',
        options: optionFileName,
      },
      {
        label: 'label.selectFile',
        name: 'title',
        fa: 'fa fa-external-link',
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        {/* <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('common:label.payment'), url: RouteNames.paymentConfiguration.path },
            { name: t('label.batchPaymentFileUpload') },
          ]}
        /> */}
        <FormWithTableItem title={`${t('label.batchPaymentFileUpload')}`}>
          <div>
            {data &&
              data.map((img, index) => {
                if (Object.keys(img).length < 2) return '';
                return (
                  <div className="col-md-12 ml-3 row">
                    <div className="col-md-11 row">
                      {dataFieldsUpload.map(val => {
                        return (
                          <GenericInput
                            key={val.label}
                            value={img ? img[val.name] : ''}
                            type={val.isNumber ? 'number' : 'text'}
                            onChange={({ name, value }) => this.onChangeMediaFile({ name, value, index })}
                            wrapperClass="col-md-4"
                            {...val}
                            onClick={
                              val.fa ? () => this.onToggleModal({ itemSelect: img, index, type: val.modalType }) : null
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            <button
              disabled={data && data.length === 1}
              type="button"
              className="button button-border black x-small"
              onClick={this.onAddOtherFile}
            >
              +
              {t('label.uploadAdditionalFiles')}
            </button>
            <button type="button" onClick={() => this.doCancelUpload()} className="button button-border black x-small">
              {t('label.cancel')}
            </button>
          </div>
          <ModalUploadFile
            title={t('label.uploadFile')}
            isOpen={isOpen}
            onCancel={this.onCancel}
            onUpload={this.onHandleUpload}
            itemSelect={itemSelect}
            accept=".csv"
            acceptFileType={['csv']}
          />
          <ModalPaymentItemMap
            isOpen={isOpenItemMap}
            onSelect={this.onSelectPaymentName}
            onCancel={() => {
              this.setState({ isOpenItemMap: false, itemSelect: null });
            }}
            selectedParentId={
              this.state && this.state.itemSelected && this.state.itemSelected.name ? this.state.itemSelected.name : ''
            }
            selectFieldName="index"
          />
        </FormWithTableItem>
      </div>
    );
  }
}

UploadPayment.propTypes = {
  getUploadPaymentCfg: PropTypes.func.isRequired,
  modifyUploadPaymentCfg: PropTypes.func.isRequired,
  createUploadPaymentCfg: PropTypes.func.isRequired,
  getCcpPropertiesConfig: PropTypes.func.isRequired,
  timeUnitCfg: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

UploadPayment.defaultProps = {
  timeUnitCfg: [],
};

const mapStateToProps = createStructuredSelector({
  // timeUnitCfg: makeGetUploadPaymentCfg() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    uploadMultiPartFiles,
    getPaymentItemMap,
  })(UploadPayment)
);
