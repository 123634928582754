import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  operatorDispute: [],
  operatorInvoice: [],
  operatorRecordLog: [],
  tenantDispute: [],
  tenantOperatorRecord: [],
  reratingBatchesList: [],
  customRecordList: [],
  fileMapCfgList: [],
  listUsageStream: [],
  trunkRulesConfig: {},
  pnnPList: [],
  operatorMap: {},
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_OPERATOR_DISPUTE_SUCCESS:
      return state.set('operatorDispute', action.payload);

    case types.SEARCH_OPERATOR_INVOICE_SUCCESS:
      return state.set('operatorInvoice', action.payload);

    case types.SEARCH_OPERATOR_RECORD_LOG_SUCCESS:
      return state.set('operatorRecordLog', action.payload);

    case types.SEARCH_TENANT_DISPUTE_SUCCESS:
      return state.set('tenantDispute', action.payload);

    case types.SEARCH_TENANT_RECORD_SUCCESS:
      return state.set('tenantOperatorRecord', action.payload);

    case types.SEARCH_RERATING_BATCHES_SUCCESS:
      return state.set('reratingBatchesList', action.payload);

    case types.GET_CUSTOM_RECORD_CFG_SUCCESS:
      return state.set('customRecordList', action.payload.customRecCfg);

    case types.GET_USAGE_FILE_MAPPING_CFG_SUCCESS:
      return state.set('fileMapCfgList', action.payload);

    case types.GET_USAGE_STREAM_MAPPING_CFG_SUCCESS:
      return state.set('listUsageStream', action.payload);

    case types.GET_TRUNK_RULES_SUCCESS:
      return state.set('trunkRulesConfig', action.payload);

    case types.GET_CONFIG_PNP_PLAN_NUMBER_SUCCESS:
      return state.set('pnnPList', action.payload);

    case types.GET_OPERATOR_MAP_SUCCESS:
      return state.set('operatorMap', action.payload);

    default:
      return state;
  }
}
