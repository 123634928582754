import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.id'),
  t('label.accountId'),
  t('label.clientName'),
  t('label.startDate'),
  t('label.endDate'),
  t('label.status'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.id ? item.id : '');
      newItem.push(item.accountId ? item.accountId : '');
      newItem.push(item.name ? item.name : '');
      newItem.push(item.startDate ? item.startDate : '');
      newItem.push(item.endDate ? item.endDate : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'timeAndBillingObjectStatus' }));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 6, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
