import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DataTable,
  CheckGroup,
  TablePagination,
  ButtonExport,
  SwitchExport,
  ExcelExport,
} from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { searchExchangeRate, exportExchangeRate } from '../../actions';
import { makeGetExchangeRate, makeGetExchangeRateExportId } from '../../selectors';
import { getPageTotalCount } from '../../../../utils/utils';
import { CurrencyExchangeSearchForm } from '../../../../components/RevenueHub';
import { sortCurency } from '../../constants';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelCurrencyExchange';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfCurrencyExchange';

class CompanySetup extends React.PureComponent {
  state = {
    data: [],
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    idSelectExport: '',
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.exchangeRate,
      totalCount: getPageTotalCount({ ...state, items: props.exchangeRate }),
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchExchangeRate();
      });
    } else {
      this.doSearchExchangeRate();
    }
  }

  doSearchExchangeRate = () => {
    const { searchExchangeRate } = this.props;
    const { filter, page, size, sorted } = this.state;
    if (!filter.status) delete filter.status;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortCurency[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchExchangeRate(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchExchangeRate());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0, sorted: {}, sort: '' }, () => this.doSearchExchangeRate());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchExchangeRate());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchExchangeRate());
  };

  onChangeCheckBox = item => {
    const { idSelectExport } = this.state;
    if (idSelectExport === item.id) {
      return this.setState({ idSelectExport: '' });
    }
    return this.setState({ idSelectExport: item.id });
  };

  doExportExchangeRate = cb => {
    const { exportExchangeRate } = this.props;
    const { idSelectExport } = this.state;
    exportExchangeRate({ id: idSelectExport }, () => cb());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doExportExchangeRate(() => {
      const { isActivePdf } = this.state;
      const { exchangeRateId, t } = this.props;
      if (isActivePdf) {
        convertJson2Pdf({
          data: exchangeRateId && exchangeRateId.exchangeRateList ? exchangeRateId.exchangeRateList : [],
          t,
          title: 'LIST CURRENCY EXCHANGE',
          fileName: `list_currency_exchange_${moment(new Date()).format('YYYY_MM_DD')}`,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  render() {
    const { t, exchangeRateId, permissions, location } = this.props;
    const {
      modeCreateCurrencyExchangeConfig,
      modeGetCurrencyExchangeConfig,
      modeModifyCurrencyExchangeConfig,
    } = permissions;
    if (!modeGetCurrencyExchangeConfig) return '';
    const { data, totalCount, sorted, isSearching, page, size, isActivePdf, idSelectExport, filter } = this.state;
    const tableColumns = [
      {
        name: 'export',
        label: 'label.export',
        render: (colName, item) => {
          return (
            <CheckGroup
              onChange={() => this.onChangeCheckBox(item)}
              value={item.userId}
              name="idSelected"
              checked={idSelectExport === item.id}
            />
          );
        },
      },
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) =>
          modeModifyCurrencyExchangeConfig ? (
            <Link
              className="text-success"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.revenueConfigurationCurrencyExchangeModify.path, item.id),
                state: { filter },
              }}
            >
              {item.id}
            </Link>
          ) : (
            <span>{item.id}</span>
          ),
      },
      {
        name: 'type',
        label: 'label.exchangeRateType',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:exchangeRateType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'effectiveDate',
        label: 'label.effectiveDate',
        sortable: true,
      },
      {
        name: 'view',
        label: t('label.view'),
        render: (colName, item) =>
          modeModifyCurrencyExchangeConfig ? (
            <Link
              className="btn btn-outline-success btn-sm"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.revenueConfigurationCurrencyExchangeModify.path, item.id),
                state: { filter },
              }}
            >
              {t('common:label.view')}
            </Link>
          ) : (
            ''
          ),
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={
            modeCreateCurrencyExchangeConfig === 2
              ? { pathname: RouteNames.revenueConfigurationCurrencyExchangeCreate.path, state: { filter } }
              : RouteNames.revenueConfiguration.path
          }
          titleBtn={modeCreateCurrencyExchangeConfig === 2 ? t('label.createNew') : t('label.back')}
          titleRight={t('revenuePage:sidebar.currencyExchange')}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <h6 className="mb-5">
                  {t('label.toAddCurrency')}
                  <Link
                    className="text-success"
                    to={{
                      pathname: RouteNames.currency.path,
                      state: { backLink: RouteNames.revenueConfigurationCurrencyExchange.path, filter },
                    }}
                  >
                    {t('label.here')}
                  </Link>
                </h6>
                <div>
                  <CurrencyExchangeSearchForm
                    onSubmit={this.onHandleSubmit}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                columns={tableColumns}
                sorted={sorted}
                data={data}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
            </div>
            <div className="mt-3 d-flex mb-4 mr-0 ml-auto pt-3 float-right">
              <div className="ml-3 mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={this.onChangeSwitch}
                  wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdf}
                  rightTitle={t('label.pdf')}
                />
              </div>
              <ButtonExport isExport disabled={!idSelectExport} onExport={() => this.onExport()} />
              <ExcelExport
                element={<button type="button" className="display-none" ref={this.buttonRef} />}
                nameSheet="LIST CURRENCY EXCHANGE"
                isExport
                multiDataSet={convertJson2Sheet({
                  data: exchangeRateId && exchangeRateId.exchangeRateList ? exchangeRateId.exchangeRateList : [],
                  t,
                })}
                disabled={!idSelectExport}
                fileName={`list_currency_exchange_${moment(new Date()).format('YYYY_MM_DD')}`}
              />
            </div>
          </div>
        </div>

        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

CompanySetup.propTypes = {
  exportExchangeRate: PropTypes.func,
  searchExchangeRate: PropTypes.func,
};

CompanySetup.defaultProps = {
  exportExchangeRate: () => {},
  searchExchangeRate: () => {},
};

const mapStateToProps = createStructuredSelector({
  exchangeRate: makeGetExchangeRate() || [],
  exchangeRateId: makeGetExchangeRateExportId() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchExchangeRate,
    exportExchangeRate,
  })(withRouter(CompanySetup))
);
