import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['reExtract']);
  const resendEbsExtract = `resendEbsExtract(input: ${dataModify})`;
  return gql`
  mutation {
    ${resendEbsExtract} {
      batchId
    }
  }
`;
};
