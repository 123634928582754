// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['objectType', 'index', 'length', 'type', 'mandatory', 'category']);

  const ModifyCustomAttributes = `modifyCustomAttributes(input: ${dataCreate})`;
  return gql`
  mutation {
    ${ModifyCustomAttributes} {
      id
      objectType
      attributes {
        index
        name
        length
        type
        mandatory
        attributesLOVs {
          index
          attributeId
          attributeValue
        }
      }
    }
  }
`;
};
