/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, TablePagination, TitleFrom, CollapsibleTable, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { countryList } from '../../../constantsApp/countryList';
import { searchVoicePlan, modifyVoicePlan, createVoicePlan, getRegionMapConfig } from '../actions';
import { getPageTotalCount } from '../../../utils/utils';
import {
  checkPermissionGetVoicePlan,
  checkPermissionModifyVoicePlan,
  checkPermissionCreateVoicePlan,
} from '../CheckPermission';
import ModalOperatorMapList from '../../../components/common/ModalOperatorMapList';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { VoicePlansSearchForm } from '../../../components/BaseConfigurationsHub';

class VoicePlans extends React.PureComponent {
  state = {
    data: { plans: [] },
    wasValidated: false,
    formValid: true,
    activeTab: {},
    showActiveOnly: true,
    isOpenModalName: '',
    locationSelect: {},
    enterpriseSelect: {},
    divisionSelect: {},
    legalEntitySelect: {},
    activePlanIndexTab: -1,
    activeAccountRangesIndexTab: -1,
    page: 0,
    size: 100,
    regionsList: [],
    modifyData: [],
    filter: {},
  };

  formRef = React.createRef();

  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     totalCount: getPageTotalCount({ ...state, items: state.data.plans }),
  //   };
  // }

  componentDidMount() {
    this.doSearchVoicePlan();
    const { getRegionMapConfig } = this.props;
    getRegionMapConfig(({ success, data }) => {
      if (success && data.regions && data.regions.length) {
        const regionsList = data.regions.map(val => ({
          value: `${val.regionId}`,
          label: `${val.regionId} - ${val.description}`,
        }));

        this.setState({ regionsList });
      }
    });
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doSearchVoicePlan = () => {
    const { searchVoicePlan } = this.props;
    const { page, size, activePlanIndexTab, filter } = this.state;
    searchVoicePlan(
      {
        page: page + 1,
        size,
        filter,
      },
      ({ data }) => {
        let listItem = null;
        if (activePlanIndexTab !== -1 && data.plans && data.plans.length && data.plans[activePlanIndexTab]) {
          listItem = data.plans[activePlanIndexTab].searchLdiList || data.plans[activePlanIndexTab].searchLdnList || [];
        }
        this.setState({
          data: data || { plans: [] },
          isModify: !!data,
          totalCount: getPageTotalCount({ ...this.state, items: listItem }),
        });
      }
    );
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, modifyData } = this.state;
    const { modifyVoicePlan, createVoicePlan } = this.props;
    const payload = cloneDeep(data);
    const { id, type, ...rest } = payload;
    const newPayload = cloneDeep(rest);

    if (isModify) {
      newPayload.plans = payload.plans
        .filter(item => !!item.isModify)
        .map(val => {
          const { searchLdiList, searchLdnList, isModify, isModifyList, isNew, ...rest } = val;
          // const itemModify =
          //   modifyData && modifyData.length ? modifyData.find(dt => dt && dt.index === rest.index) : null;
          let indexLdiList = null;
          let indexLdnList = null;
          if (searchLdiList && isModifyList) {
            indexLdiList = cloneDeep(searchLdiList);
          }
          if (searchLdnList && isModifyList) {
            indexLdnList = cloneDeep(searchLdnList);
          }
          return {
            ...rest,
            ldiList: indexLdiList ? [...indexLdiList] : null,
            ldnList: indexLdnList ? [...indexLdnList] : null,
          };
        });
      return modifyVoicePlan(newPayload, ({ success }) => {
        if (success) {
          this.setState({ wasValidated: false, modifyData: [] });
          this.doSearchVoicePlan();
        }
      });
    }

    newPayload.plans = payload.plans.map(val => {
      const { searchLdiList, searchLdnList, isModify, isModifyList, isNew, ...rest } = val;
      let indexLdiList = [];
      let indexLdnList = [];
      if (searchLdiList) {
        indexLdiList = cloneDeep(searchLdiList).map(val => {
          const { index, ...rest } = val;
          return rest;
        });
      }
      if (searchLdnList) {
        indexLdnList = cloneDeep(searchLdnList).map(val => {
          const { index, ...rest } = val;
          return rest;
        });
      }
      return { ...rest, ldiList: [...indexLdiList], ldnList: [...indexLdnList] };
    });
    return createVoicePlan(newPayload, ({ success }) => {
      if (success) {
        this.setState({ wasValidated: false, modifyData: [] });
        this.doSearchVoicePlan();
      }
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onAddNewPlan = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.plans) newData.plans = [];
    newData.plans.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.plans = [
      {
        index: -1,
        // index: lastIndex + 1,
        isNew: true,
        name: '',
        planType: 'LDI',
        status: 'ACTIVE',
        planId: null,
        serialId: null,
        searchLdiList: [],
        searchLdnList: [],
      },
      ...newData.plans,
    ];
    // newData.plans[newData.plans.length] = ;

    this.setState({ data: newData });
  };

  addNewSearchLdiList = index => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (newData.plans[index] && newData.plans[index].planType === 'LDI') {
      if (!newData.plans[index].searchLdiList) newData.plans[index].searchLdiList = [];
      newData.plans[index].searchLdiList.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.plans[index].searchLdiList.push({
        // index: lastIndex + 1,
        // index: -1,
        name: '',
        npaId: '',
        region: '',
        location: '',
        country: '',
        countryCode: '',
      });
      this.setState({ data: newData });
    } else {
      if (!newData.plans[index].searchLdnList) newData.plans[index].searchLdnList = [];
      newData.plans[index].searchLdnList.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.plans[index].searchLdnList.push({
        // index: -1,
        name: '',
        region: '',
        location: '',
        min: null,
        max: null,
      });

      this.setState({ data: newData });
    }
  };

  onToggleSubTabGLplans = (index, item, indexItem) => {
    const { activePlanIndexTab } = this.state;
    if (activePlanIndexTab === indexItem) this.setState({ activePlanIndexTab: -1 });
    if (activePlanIndexTab !== indexItem) {
      this.setState({ activePlanIndexTab: indexItem, size: 20, page: 0 }, () => {
        if (!item.isNew) this.doSearchVoicePlan();
      });
    }
  };

  onHandleChangePlan = ({ name, value, index }) => {
    try {
      const { data, modifyData } = this.state;
      const newData = cloneDeep(data);
      if (!modifyData || !modifyData[index]) modifyData[index] = {};
      modifyData[index].index = newData.plans[index].index;
      newData.plans[index][name] = value;
      newData.plans[index].isModify = true;
      modifyData[index][name] = value;
      this.setState({ data: newData, modifyData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeList = ({ value, name, index, indexParent }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      if (newData.plans[indexParent].planType === 'LDI') {
        newData.plans[indexParent].isModifyList = true;
        newData.plans[indexParent].isModify = true;
        newData.plans[indexParent].searchLdiList[index][name] = value;
      } else {
        newData.plans[indexParent].isModifyList = true;
        newData.plans[indexParent].isModify = true;
        newData.plans[indexParent].searchLdnList[index][name] = value;
      }
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onPageChange = newPage => {
    const { page } = this.state;
    if (newPage === page) return '';
    this.setState({ page: newPage }, () => this.doSearchVoicePlan());
  };

  onSizeChange = size => {
    this.setState({ size }, () => this.doSearchVoicePlan());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchVoicePlan());
  };

  onOpenOperatorModal = ({ item, index }) => {
    this.setState({ isOpenOperator: true, itemOperatorSelected: item, indexOperatorSelected: index });
  };

  onSelectOperator = id => {
    try {
      const { data, activePlanIndexTab, indexOperatorSelected } = this.state;
      const newData = cloneDeep(data);
      if (newData.plans[activePlanIndexTab].planType === 'LDI') {
        newData.plans[activePlanIndexTab].searchLdiList[indexOperatorSelected].npaId = id;
      } else {
        newData.plans[activePlanIndexTab].searchLdnList[indexOperatorSelected].npaId = id;
      }
      this.setState({ data: newData, isOpenOperator: false, itemOperatorSelected: null, indexOperatorSelected: null });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t, permissionPricing } = this.props;
    let modeGetVoicePlan = 0;
    let modeCreateVoicePlan = 0;
    let modeModifyVoicePlan = 0;
    if (permissionPricing && permissionPricing.pricingModulePermissions) {
      const listPermission = permissionPricing.pricingModulePermissions;
      modeCreateVoicePlan = checkPermissionCreateVoicePlan({ listPermission });
      modeGetVoicePlan = checkPermissionGetVoicePlan({ listPermission });
      modeModifyVoicePlan = checkPermissionModifyVoicePlan({ listPermission });
    }
    const {
      page,
      size,
      totalCount,
      isSearching,
      data,
      wasValidated,
      isModify,
      activePlanIndexTab,
      regionsList,
      isOpenOperator,
      itemOperatorSelected,
    } = this.state;
    if (!modeGetVoicePlan) return '';
    const tableSearchLdiList = [
      {
        name: 'name',
        label: 'label.name',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="name"
            required
          />
        ),
      },
      {
        name: 'npaId',
        label: 'label.npaId',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.npaId}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="npaId"
            fa="fa fa-external-link"
            onClick={() => this.onOpenOperatorModal({ item, index })}
          />
        ),
      },
      {
        name: 'region',
        label: 'label.region',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.region}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="region"
            type="select"
            options={regionsList}
            menuPortalTarget
            isCreateOption
          />
        ),
      },
      {
        name: 'location',
        label: 'label.location',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.location}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="location"
          />
        ),
      },
      {
        name: 'country',
        label: 'label.country',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.country}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="country"
            options={countryList}
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'countryCode',
        label: 'label.countryCode',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.countryCode}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="countryCode"
          />
        ),
      },
    ];
    const tableSearchLdnList = [
      {
        name: 'name',
        label: 'label.name',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="name"
            required
          />
        ),
      },
      {
        name: 'region',
        label: 'label.region',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.region}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="region"
            type="select"
            options={regionsList}
            menuPortalTarget
            isCreateOption
          />
        ),
      },
      {
        name: 'location',
        label: 'label.location',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.location}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="location"
          />
        ),
      },
      {
        name: 'min',
        label: 'label.minimum',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.min}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="min"
            type="number"
          />
        ),
      },
      {
        name: 'max',
        label: 'label.maximum',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.max}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeList({ value, name, index, indexParent })}
            name="max"
            type="number"
          />
        ),
      },
    ];
    const headerTableGLplans = [
      {
        name: 'viewDetails',
        label: 'label.viewDetails',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => this.onToggleSubTabGLplans(idx, item, idx)}
          >
            <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'planId ',
        label: 'label.planId',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.planId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangePlan({ value, name, index: idx })}
            name="planId"
            type="number"
          />
        ),
      },
      {
        name: 'planType',
        label: 'label.planType',
        style: { minWidth: '150px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.planType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangePlan({ value, name, index: idx })}
            name="planType"
            type="select"
            menuPortalTarget
            tOptions="selections:planType"
            required
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        style: { minWidth: '150px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangePlan({ value, name, index: idx })}
            name="name"
            required
          />
        ),
      },
      {
        name: 'serialId',
        label: 'label.serialId',
        style: { minWidth: '150px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serialId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangePlan({ value, name, index: idx })}
            name="serialId"
            type="number"
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        style: { minWidth: '150px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangePlan({ value, name, index: idx })}
            name="status"
            type="select"
            tOptions="selections:planStatus"
            menuPortalTarget
            required
          />
        ),
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations') },
            { name: t('baseConfigurationsPage:sidebar.voicePlans') },
          ]}
          isNoDefaultButton
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <VoicePlansSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.voiceLdiLdnPlans')} subClass="border-bottom" />
            <br />

            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="form-group col-md-12 buttons-attibute pt-2">
                {modeCreateVoicePlan === 2 && (
                  <button
                    type="button"
                    className="button button-border ml-3 black x-small"
                    onClick={this.onAddNewPlan}
                  >
                    +
                    {t('label.addNewVoicePlan')}
                  </button>
                )}
                {modeModifyVoicePlan === 2 && (
                  <button
                    type="button"
                    className="button button-border mb-3 x-small float-right mr-4"
                    onClick={this.onHandleSave}
                  >
                    {isModify ? t('label.saveConfig') : t('label.createConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doSearchVoicePlan}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <div className="col-md-12 row p-3">
                <CollapsibleTable
                  columns={headerTableGLplans}
                  indexViewer={activePlanIndexTab}
                  data={data.plans || []}
                  isSupportRemoveIndex
                  isLoading={isSearching}
                  isFixedHeaderTable
                >
                  <div className="col-md-12 mt-3">
                    <DataTable
                      columns={
                        data.plans &&
                        activePlanIndexTab !== -1 &&
                        data.plans[activePlanIndexTab] &&
                        data.plans[activePlanIndexTab].planType === 'LDI'
                          ? tableSearchLdiList
                          : tableSearchLdnList
                      }
                      isSupportRemoveIndex
                      data={
                        data.plans &&
                        data.plans[activePlanIndexTab] &&
                        data.plans[activePlanIndexTab].searchLdiList &&
                        data.plans[activePlanIndexTab].planType === 'LDI'
                          ? data.plans[activePlanIndexTab].searchLdiList
                          : data.plans &&
                            data.plans[activePlanIndexTab] &&
                            data.plans[activePlanIndexTab].searchLdnList &&
                            data.plans[activePlanIndexTab].planType === 'LDN'
                          ? data.plans[activePlanIndexTab].searchLdnList
                          : []
                      }
                      indexParent={activePlanIndexTab}
                    />
                    <button
                      type="button"
                      className="button mb-4 button-border ml-3 black x-small"
                      onClick={evt => this.addNewSearchLdiList(activePlanIndexTab)}
                      disabled={
                        activePlanIndexTab === -1 ||
                        !data.plans[activePlanIndexTab] ||
                        !data.plans[activePlanIndexTab].planType
                      }
                    >
                      +
                      {t('label.addNewRow')}
                    </button>
                    <div className="mb-2 ml-3 mt-2">
                      <TablePagination
                        pageNumber={page}
                        pageSize={size}
                        totalCount={totalCount}
                        onPageChange={this.onPageChange}
                        onSizeChange={this.onSizeChange}
                        menuPortalTarget
                      />
                    </div>
                  </div>
                </CollapsibleTable>
              </div>
            </form>
          </div>
          <br />
        </div>
        <ModalOperatorMapList
          isOpen={isOpenOperator}
          onSelect={this.onSelectOperator}
          onCancel={() => {
            this.setState({ isOpenOperator: false, itemOperatorSelected: null, indexOperatorSelected: null });
          }}
          selectedParentId={itemOperatorSelected && itemOperatorSelected.npaId ? itemOperatorSelected.npaId : ''}
        />
      </div>
    );
  }
}

VoicePlans.propTypes = {
  searchVoicePlan: PropTypes.func,
  modifyVoicePlan: PropTypes.func,
};

VoicePlans.defaultProps = {
  searchVoicePlan: () => {},
  modifyVoicePlan: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchVoicePlan,
    modifyVoicePlan,
    createVoicePlan,
    getRegionMapConfig,
  })(withRouter(VoicePlans))
);
