import gql from 'graphql-tag';

export default () => gql`
  query {
    getTimeUnitCfg(dummy: "") {
      id
      useStartTime
      workWeekCfg {
        id
        startOfWeek
        endOfWeek
      }
      peakHourCfg {
        id
        startTime
        endTime
      }
      holidaySpecialCfgs {
        name
        startDate
        endDate
      }
    }
  }
`;
