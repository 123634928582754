import gql from 'graphql-tag';

export default ({ id, reason, status, executeFutureOrderNow }) => {
  const inputStr = reason
    ? `{ id: "${id}",reason: "${reason}", status: ${status}, executeFutureOrderNow: ${!!executeFutureOrderNow}}`
    : `{ id: "${id}", status: ${status}, executeFutureOrderNow: ${!!executeFutureOrderNow}}`;
  const updateOrderStatus = `updateOrderStatus(input: ${inputStr})`;
  return gql`
  mutation {
    ${updateOrderStatus} {
      id
      reason
      status
    }
  }
`;
};
