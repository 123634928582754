import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'roundingPrecision',
    'roundingMethod',
    'paymentThreshold',
    'paymentExchangeThreshold',
  ]);
  const modifyCurrencyConfig = `modifyCurrencyConfig(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyCurrencyConfig} {
      id
    }
  }
`;
};
