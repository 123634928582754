import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['invoiceExhibits', 'unAssignedCutOff', 'chargeType'])}`
    : '';
  const getBillableServicesForecastReport = `getBillableServicesForecastReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBillableServicesForecastReport} {
        accountId
        commercialName
        billableServiceId
        billableServiceName
        instanceId
        quantity
        amount
        unitPrice
        chargeType
        invoiceExhibits
        exhibitsInvoiced
        recurringFrequency
        recurringUnit
        cutOffDOM
        advanceFlag
        partMonthWaiveOffFlag
        currency
        cutOffDate
        startDate
        endDate
        invoiceCurrency
        invoiceCurrencyAmount
        exchangeRate
        name
      }
    }
  `;
};
