import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataGet = parseToMutationRequest(data, ['gatewayName']);

  const getGatewayCanonicalConfigByName = `getGatewayCanonicalConfigByName(input: ${dataGet} )`;
  return gql`
    query {
      ${getGatewayCanonicalConfigByName} {
        id
        gatewayName
        providerName
        apiName
        requestFlag
        gatewayCanonicalMapCfg {
          index
          sourceName
          canonicalName
          levelOneFlag
          gatewayFieldMapCfg {
            index
            refIndex
            id
            sourceAttribute
            canonicalAttribute
          }
        }
      }
    }
  `;
};
