import gql from 'graphql-tag';

export default payload => {
  return gql`
    query {
      getAccountExchangeRates(accountId: "${payload|| ''}") {
        index
        currency
        split
        startDate
        endDate
        exchangeRate
        threshold
        splitType
      }
    }
  `;
};