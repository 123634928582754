import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import PageTitle from '../../../components/PageTitle';
import FormCollapse from '../../../components/form/FormCollapse';
import constant from './constants';
import PaymentTypes from './PaymentTypes';
import PaymentTerms from './PaymentTerms';
import {
  getPaymentConfigByType,
  modifyPaymentConfigMerchant,
  createPaymentConfigMerchant,
  createPaymentConfigTerm,
  modifyPaymentConfigTerm,
  createPaymentConfigMethod,
  modifyPaymentConfigMethod,
} from '../actions';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import {
  checkPermissionGetPaymentConfiguration,
  checkPermissionCreatePaymentTermsConfig,
  checkPermissionModifyPaymentTermsConfig,
  checkPermissionCreatePaymentMethodConfig,
  checkPermissionModifyPaymentMethodConfig,
} from '../CheckPermission';

class PaymentCofig extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: {
        name: 'Payment Types',
        isActive: true,
      },
    };
  }

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      return this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    }
    return this.setState({ activeTab: { name: activeTabName, isActive: true } });
  };

  renderItem = ({
    item,
    modeModifyPaymentTermsConfig,
    modeCreatePaymentTermsConfig,
    modeModifyPaymentMethodConfig,
    modeCreatePaymentMethodConfig,
  }) => {
    const { state } = item;
    const {
      getPaymentConfigByType,
      // modifyPaymentConfigMerchant,
      // createPaymentConfigMerchant,
      createPaymentConfigMethod,
      modifyPaymentConfigMethod,
      createPaymentConfigTerm,
      modifyPaymentConfigTerm,
    } = this.props;
    switch (state) {
      // case 'Merchant Account':
      //   return (
      //     <MerchantAccount
      //       getPaymentConfigByType={getPaymentConfigByType}
      //       modifyPaymentConfigMerchant={modifyPaymentConfigMerchant}
      //       createPaymentConfigMerchant={createPaymentConfigMerchant}
      //     />
      //   );

      case 'Payment Types':
        return (
          <PaymentTypes
            getPaymentConfigByType={getPaymentConfigByType}
            createPaymentConfigMethod={createPaymentConfigMethod}
            modifyPaymentConfigMethod={modifyPaymentConfigMethod}
            modeModifyPaymentMethodConfig={modeModifyPaymentMethodConfig}
            modeCreatePaymentMethodConfig={modeCreatePaymentMethodConfig}
          />
        );
      case 'Payment Terms':
        return (
          <PaymentTerms
            getPaymentConfigByType={getPaymentConfigByType}
            createPaymentConfigTerm={createPaymentConfigTerm}
            modifyPaymentConfigTerm={modifyPaymentConfigTerm}
            modeModifyPaymentTermsConfig={modeModifyPaymentTermsConfig}
            modeCreatePaymentTermsConfig={modeCreatePaymentTermsConfig}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { activeTab } = this.state;
    const { permissionsPayment, t } = this.props;

    let modeGetPaymentConfiguration = 0;
    let modeCreatePaymentTermsConfig = 0;
    let modeModifyPaymentTermsConfig = 0;
    let modeModifyPaymentMethodConfig = 0;
    let modeCreatePaymentMethodConfig = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeGetPaymentConfiguration = checkPermissionGetPaymentConfiguration({ listPermission });
      modeCreatePaymentTermsConfig = checkPermissionCreatePaymentTermsConfig({ listPermission });
      modeModifyPaymentTermsConfig = checkPermissionModifyPaymentTermsConfig({ listPermission });
      modeModifyPaymentMethodConfig = checkPermissionModifyPaymentMethodConfig({ listPermission });
      modeCreatePaymentMethodConfig = checkPermissionCreatePaymentMethodConfig({ listPermission });
    }

    if (modeGetPaymentConfiguration === 0) return '';
    return (
      <div className="col-sm-12 mb-30">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('common:label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentAdmin'), url: RouteNames.paymentConfiguration.path },
            { name: t('paymentPage:sidebar.configuration') },
          ]}
        />
        <br />
        {constant.collapsibleMenu.map(item => (
          <FormCollapse
            key={item.title}
            label="First Name"
            isActive={item.state === activeTab.name && activeTab.isActive}
            title={item.title}
            state={item.state}
            onToggleTab={this.onToggleTab}
          >
            {this.renderItem({
              item,
              modeModifyPaymentTermsConfig,
              modeCreatePaymentTermsConfig,
              modeModifyPaymentMethodConfig,
              modeCreatePaymentMethodConfig,
            })}
          </FormCollapse>
        ))}
      </div>
    );
  }
}

PaymentCofig.propTypes = {
  getPaymentConfigByType: PropTypes.func.isRequired,
  // modifyPaymentConfigMerchant: PropTypes.func.isRequired,
  // createPaymentConfigMerchant: PropTypes.func.isRequired,
  createPaymentConfigTerm: PropTypes.func.isRequired,
  modifyPaymentConfigTerm: PropTypes.func.isRequired,
  createPaymentConfigMethod: PropTypes.func.isRequired,
  modifyPaymentConfigMethod: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

PaymentCofig.defaultProps = {
  permissionsPayment: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

export default withTranslation(['common'])(
  connect(
    mapStateToProps,
    {
      getPaymentConfigByType,
      modifyPaymentConfigMerchant,
      createPaymentConfigMerchant,
      createPaymentConfigTerm,
      modifyPaymentConfigTerm,
      createPaymentConfigMethod,
      modifyPaymentConfigMethod,
    }
  )(PaymentCofig)
);
