import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchComponent from './SearchComponent';

class ModalBundleSearchFormWithoutStatus extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
  ];
}

ModalBundleSearchFormWithoutStatus.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ModalBundleSearchFormWithoutStatus.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ModalBundleSearchFormWithoutStatus);
