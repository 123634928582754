import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { GenericInput } from '../../common';

class SubForm extends PureComponent {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (isEqual(state.item, props.item)) return null;
    return {
      ...props.item,
      item: { ...props.item },
    };
  }

  render() {
    const { headerTitle, inputs } = this.props;
    const data = this.state || {};
    return (
      <div className="sub-form">
        <div className="card-header">{headerTitle}</div>
        <div className="card-body">
          <div className="form-row">
            {inputs.map(input => {
              return <GenericInput {...input} key={input.name} value={data[input.name]} readOnly disabled />;
            })}
          </div>
        </div>
      </div>
    );
  }
}

SubForm.propTypes = {
  isRemoveable: PropTypes.bool,
  headerTitle: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.any),
};

SubForm.defaultProps = {
  isRemoveable: true,
  headerTitle: null,
  inputs: [],
};
export default SubForm;
