// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import customerVNS from './constants/customerVNS';
// import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  // newData.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, customerVNS);
  const createAccount = `createAccount(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createAccount} {
      id
    }
  }
`;
};
