import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, ['objectType']);
  return gql`
  query {
    getFlexAttributesMapping(input: ${dataSearch}) {
      id
      types {
        index
        provAttributeType
        serviceType
        status
        mapping {
          index
          tag
          flexAttribute
          status
          mandatoryFlag
        }
      }
    }
  }
`;
};
