import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getZeroRateUsageConfig, modifyZeroRateUsageConfig, createZeroRateUsageConfig } from '../actions';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { getDistinctUsageTypes } from '../../App/actions';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import { getIndexData } from '../../../utils/utils';
import ModalSearchItem from '../../../components/common/ModalSearchItem';
import {
  checkPermissionCreateZeroPriceUsageConfig,
  checkPermissionModifyZeroPriceUsageConfig,
  checkPermissionGetZeroPriceUsageConfig,
} from '../CheckPermission';

class ZeroPriceUsage extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    listZeroPriceUsage: [],
    isSearching: true,
    dataSubmit: [],
    isModify: false,
    usageTypeList: [],
  };

  componentDidMount() {
    const { getDistinctUsageTypes } = this.props;
    getDistinctUsageTypes('', ({ success, data }) => {
      if (success) {
        const usageTypeList = data.map(val => ({
          label: val.usageType,
          value: val.usageType,
        }));

        this.setState({ usageTypeList });
      }
    });
    this.doGetZeroPriceUsageCfg();
  }

  onChangeTable = ({ name, value, index }) => {
    const { listZeroPriceUsage, dataSubmit } = this.state;
    const newData = cloneDeep(listZeroPriceUsage);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ listZeroPriceUsage: newData });
  };

  doGetZeroPriceUsageCfg = () => {
    const { getZeroRateUsageConfig } = this.props;
    getZeroRateUsageConfig(({ data }) => {
      this.setState({
        isSearching: false,
        isModify: !!(data && data.id),
        listZeroPriceUsage: data && data.zeroRateUsageConfigList ? data.zeroRateUsageConfigList : [],
        id: data && data.id ? data.id : [],
      });
    });
  };

  onAddNewZeroPriceUsage = () => {
    let indexNewItem = 0;
    const { listZeroPriceUsage } = this.state;
    let newData = cloneDeep(listZeroPriceUsage);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      stream: '',
      source: '',
      usageType: '',
      usageClass: '',
      itemId: '',
      serviceType: null,
    };
    newData = [newDataItem, ...newData];
    this.setState({ listZeroPriceUsage: newData });
  };

  onRemoveItemTable = ({ index }) => {
    try {
      const { listZeroPriceUsage, dataSubmit } = this.state;
      const newData = cloneDeep(listZeroPriceUsage);
      newData[getIndexData({ index, data: newData })] = {
        index: newData[getIndexData({ index, data: newData })].index,
      };
      if (getIndexData({ index, data: dataSubmit }) > -1) {
        dataSubmit[getIndexData({ index, data: newData })] = {
          index: newData[getIndexData({ index, data: newData })].index,
        };
      } else {
        dataSubmit.push({
          index: newData[getIndexData({ index, data: newData })].index,
        });
      }
      this.setState({ listZeroPriceUsage: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onToggle = idx => {
    const { isOpenModalItem, listZeroPriceUsage } = this.state;
    this.setState({ isOpenModalItem: !isOpenModalItem, indexSelect: idx, itemSelect: listZeroPriceUsage[idx] });
  };

  onSelectItemId = (id, row) => {
    const { indexSelect, listZeroPriceUsage } = this.state;
    if (id) {
      listZeroPriceUsage[indexSelect].itemId = id;
      // dataSubmit[indexSelect].description = row.description;
    }
    this.setState({ isOpenModalItem: false, listZeroPriceUsage });
  };

  onHanldeSubmit = () => {
    const { listZeroPriceUsage, isModify, defaultDataFromAPI, id } = this.state;
    const { modifyZeroRateUsageConfig, createZeroRateUsageConfig } = this.props;
    if (isModify) {
      const newPayloadModify = {
        zeroRateUsageConfigList: listZeroPriceUsage,
        id,
      };
      modifyZeroRateUsageConfig(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(listZeroPriceUsage).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        zeroRateUsageConfigList: dataRemoveIndex,
      };
      createZeroRateUsageConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetZeroPriceUsageCfg();
        }
      });
    }
  };

  render() {
    const { listZeroPriceUsage, isSearching, itemSelect, isOpenModalItem, usageTypeList } = this.state;
    const { t, permissionsPricing } = this.props;
    let modeCreateZeroRateConfig = 0;
    let modeModifyZeroRateConfig = 0;
    let modeGetZeroRateConfig = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateZeroRateConfig = checkPermissionCreateZeroPriceUsageConfig({ listPermission: listPermissionPricing });
      modeModifyZeroRateConfig = checkPermissionModifyZeroPriceUsageConfig({ listPermission: listPermissionPricing });
      modeGetZeroRateConfig = checkPermissionGetZeroPriceUsageConfig({ listPermission: listPermissionPricing });
    }
    if (modeGetZeroRateConfig === 0) return '';
    const creditProfileColumns = [
      {
        name: 'stream',
        label: 'label.stream',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item) => (
          <GenericInput
            value={item.stream}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="stream"
            type="select"
            menuPortalTarget
            tOptions="selections:usageRecIndicator"
          />
        ),
      },
      {
        name: 'source',
        label: 'label.source',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.source}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="source"
          />
        ),
      },
      {
        name: 'usageType',
        label: 'label.usageType',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.usageType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="usageType"
            type="select"
            options={usageTypeList}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'usageClass',
        label: 'label.usageClass',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.usageClass}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="usageClass"
          />
        ),
      },
      {
        name: 'itemId',
        label: 'label.itemId',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.itemId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="itemId"
            onClick={() => this.onToggle(idx)}
            fa="fa fa-external-link"
          />
        ),
      },
      {
        name: 'serviceType',
        label: 'label.serviceType',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.serviceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="serviceType"
            type="select"
            menuPortalTarget
            tOptions="selections:serviceType"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataRemoveIndex =
      listZeroPriceUsage && listZeroPriceUsage && listZeroPriceUsage.length
        ? listZeroPriceUsage.filter(item => size(item) > 1)
        : [];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: `${t('baseConfigurationsPage:sidebar.zeroPriceUsage')}` },
          ]}
          isNoDefaultButton
        />
        <FormWithTableItem title={`${t('baseConfigurationsPage:sidebar.zeroPriceUsage')}`} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateZeroRateConfig === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={this.onAddNewZeroPriceUsage}
              >
                +
                {t('label.addNewZeroPriceUsage')}
              </button>
            )}
            {modeModifyZeroRateConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetZeroPriceUsageCfg()}
              className="button button-border black x-small float-right mr-2"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={creditProfileColumns}
              data={newDataRemoveIndex}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <ModalSearchItem
          isOpen={isOpenModalItem}
          onSelect={this.onSelectItemId}
          onCancel={this.onToggle}
          selectedParentId={itemSelect && itemSelect.itemId ? itemSelect.itemId : ''}
        />
      </div>
    );
  }
}

ZeroPriceUsage.propTypes = {
  getZeroRateUsageConfig: PropTypes.func.isRequired,
  modifyZeroRateUsageConfig: PropTypes.func.isRequired,
  createZeroRateUsageConfig: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.object,
};

ZeroPriceUsage.defaultProps = {
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getZeroRateUsageConfig,
    modifyZeroRateUsageConfig,
    createZeroRateUsageConfig,
    getDistinctUsageTypes,
  })(ZeroPriceUsage)
);
