import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, PieCharts } from '../../../components/common';
import { getPieChartDashBoardByType } from '../actions';
import RouteNames from '../../App/RouteNames';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionRevenueBySellingCompany } from '../CheckPermission';
import { makeGetPermissionsDashboard } from '../../App/selectors';

class SellingCompany extends Component {
  state = { data: [] };

  componentDidMount() {
    const { getPieChartDashBoardByType } = this.props;
    getPieChartDashBoardByType({ type: 'CUSTOMER_COUNT_BY_SELLING_COMPANY' }, ({ success, data }) => {
      if (success) this.setState({ data });
    });
  }

  render() {
    const { data } = this.state;
    const { t, permissionsDashboard } = this.props;
    let modeSellingCompany = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeSellingCompany = checkPermissionRevenueBySellingCompany({ listPermission });
    }
    if (modeSellingCompany === 0) return '';
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('dashboardPage:sidebar.sellingCompany') }]} />
            </div>
          </div>
        </div>
        <br />
        {/* <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('dashboardPage:sidebar.sellingCompany')}</h5>
              </div>
              <div className="row mb-5">
                <PieCharts
                  key={data.name}
                  title={t('dashboardPage:sidebar.sellingCompany')}
                  data={data && data.data ? cloneDeep(data.data) : []}
                  wrapperClass="chart-pie m-auto"
                  keySeries="value"
                  keyCategories="mon"
                  style={{ height: 'auto', width: 'auto' }}
                  height="400px"
                  background={defineBackground[data.name] ? defineBackground[data.name] : null}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row mb-5">
          <PieCharts
            key={data.name}
            title={t('dashboardPage:sidebar.sellingCompany')}
            data={data && data.data ? cloneDeep(data.data) : []}
            wrapperClass="chart-pie embrix-chart-pie m-auto"
            subClass="animate__animated animate__zoomIn"
            keySeries="value"
            keyCategories="mon"
            style={{ height: 'auto', width: 'auto' }}
            height="550px"
            background={defineBackground[data.name] ? defineBackground[data.name] : null}
            isToolbar
          />
        </div>
      </div>
    );
  }
}

SellingCompany.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
  getPieChartDashBoardByType: PropTypes.func.isRequired,
};

SellingCompany.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getPieChartDashBoardByType })(SellingCompany));
