import React from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput, CardHeaderForm, FormCollapse } from '../common';

const OrderProvisioningAttributesForm = ({ data, activeTab, onChange, onToggleTab, wrapperClass }) => {
  const { t } = useTranslation('common');
  // if (!data || !data.length) return null;
  return (
    <div className="col-md-12 mb-30">
      <div className="card card-statistics">
        <CardHeaderForm title={t('label.provisioningData')} hideNavTab />
        <div className="card-body">
          {data && data.length > 0 ? (
            data.map((dt, indexService) => {
              const { configProvisioningAttributes } = dt || {};
              const slt = t('selections:serviceType')().find(val => val.value === dt.serviceType);
              return (
                <div className="col-md-12 border p-3 mt-3" key={`key-${indexService}`}>
                  <h5 className="mb-2">{`${t('label.serviceType')}: ${slt ? slt.label : ''}`}</h5>
                  <div>
                    {configProvisioningAttributes &&
                      configProvisioningAttributes.length > 0 &&
                      configProvisioningAttributes.map((att, indexAttribute) => {
                        const { attributesList } = att || {};
                        return (
                          <FormCollapse
                            key={att.id}
                            title={att.name}
                            isActive={
                              att.name === activeTab.name &&
                              activeTab.isActive &&
                              dt.serviceType === activeTab.serviceType
                            }
                            onToggleTab={activeTabName => onToggleTab(activeTabName, dt.serviceType)}
                            state={att.name}
                            wrapperClass="new-order-provisioning"
                            isSubCollapse
                            isActiveSubForm={
                              att.name === activeTab.name &&
                              activeTab.isActive &&
                              dt.serviceType === activeTab.serviceType
                            }
                          >
                            <div className="col-md-12 row p-2">
                              {attributesList &&
                                attributesList.length > 0 &&
                                attributesList.map((item, indexItem) => {
                                  const { attributesLovs } = item || {};
                                  const options = attributesLovs?.length
                                    ? attributesLovs.map(val => ({
                                        label: val.code,
                                        value: val.code,
                                      }))
                                    : null;
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      value={item.attributeValue}
                                      label={`${item.name || ' '}`}
                                      name={item.name}
                                      onChange={({ value }) =>
                                        onChange({
                                          value,
                                          indexService,
                                          indexAttribute,
                                          indexItem,
                                        })
                                      }
                                      wrapperClass="form-group col-md-4"
                                      type={options ? 'select' : 'text'}
                                      options={options}
                                    />
                                  );
                                })}
                            </div>
                          </FormCollapse>
                        );
                      })}
                  </div>
                </div>
              );
            })
          ) : (
            <h6 className="col-md-12 text-center mb-4">{t('label.noRecord')}</h6>
          )}
        </div>
      </div>
    </div>
  );
};

OrderProvisioningAttributesForm.propTypes = {
  data: PropsType.arrayOf(PropsType.any),
  activeTab: PropsType.objectOf(PropsType.any),
  onChange: PropsType.func.isRequired,
  onToggleTab: PropsType.func.isRequired,
  wrapperClass: PropsType.string,
};

OrderProvisioningAttributesForm.defaultProps = {
  data: null,
  activeTab: {},
  wrapperClass: '',
};

export default OrderProvisioningAttributesForm;
