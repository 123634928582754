import gql from 'graphql-tag';

export default () => gql`
  query {
    getExpensesConfig(dummy: "" ) {
      id
      fixedExpenses {
        index
        type
        description
      }
      variableExpenses {
        index
        type
        description
      }
    }
  }
`;
