import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { getPageTotalCount } from 'utils/utils';
import { withTranslation } from 'react-i18next';
import { DataTable, TablePagination, Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import { searchUsers, setParamsListUsers } from '../actions';
import { makeGetListUsers, makePageSearchUsersParams, errorMessageUsers } from '../selectors';

import { UserSearchForm } from '../../../components/OperationHub';
import { makeGetPermissionsUserManagement } from '../../App/selectors';
import {
  checkPermissionCreateUser,
  checkPermissionGetUser,
  checkPermissionModifyUser,
  checkPermissionSearchUser,
} from '../CheckPermission';

const UserSort = {
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const tableColumns = [
  {
    name: 'userId',
    label: 'label.userId',
    sortable: true,
  },
  {
    name: 'type',
    label: 'label.type',
    sortable: true,
  },
  {
    name: 'category',
    label: 'label.category',
    sortable: true,
    style: { minWidth: '100px' },
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:category')().find(val => val.value === item.category) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'status',
    label: 'label.status',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

class UserManagementUser extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state && props.listUsers) {
      return {
        totalCount: getPageTotalCount({ ...state, items: props.listUsers }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchItems();
      });
    } else {
      this.doSearchItems();
    }
  }

  onClickRow = (e, rowItem) => {
    e.preventDefault();
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sorted, filter } = this.state;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && UserSort[sorted.sortCol]
        ? UserSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    this.props.searchUsers(params, isSuccess => {
      this.setState({ isSearching: false, filter, sort });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchItems());
  };

  render() {
    const { listUsers, permissionsUser, t, location } = this.props;
    const { sorted, filter } = this.state;
    let newTableColumns = cloneDeep(tableColumns);

    let modeCreateUser = 0;
    let modeModifyUser = 0;
    let modeGetUser = 0;
    let modeSearchUser = 0;
    if (permissionsUser && permissionsUser.userModulePermissions) {
      const listPermission = permissionsUser.userModulePermissions;
      modeCreateUser = checkPermissionCreateUser({ listPermission });
      modeModifyUser = checkPermissionModifyUser({ listPermission });
      modeGetUser = checkPermissionGetUser({ listPermission });
      modeSearchUser = checkPermissionSearchUser({ listPermission });
    }

    newTableColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.userManagementUserEdit.path, item.id),
              state: { filter },
            }}
          >
            {item.id}
          </Link>
        ),
      },
      ...newTableColumns,
      {
        name: 'action',
        label: 'label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.userManagementUserEdit.path, item.id),
              state: { filter },
            }}
          >
            {t ? t('label.view') : ''}
          </Link>
        ),
      },
    ];

    if (modeSearchUser === 0) return '';

    if (modeModifyUser === 0 || modeGetUser === 0) {
      newTableColumns[0] = {
        name: 'id',
        label: 'label.id',
      };
      newTableColumns.splice(newTableColumns.length - 1, 1);
    }

    const tableConfig = {
      columns: newTableColumns,
      sorted: { ...sorted },
      data: listUsers,
      onSort: this.onSortColumn,
      onClickRow: this.onClickRow,
    };

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              {modeCreateUser === 2 && (
                <Link
                  to={{ pathname: RouteNames.userManagementUserAdd.path, state: { filter } }}
                  className="button x-small"
                >
                  {t('label.createNewUser')}
                </Link>
              )}
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:operationsHub.subMain.userManagement'), url: RouteNames.userManagementRole.path },
                  { name: t('userManagementPage:sidebar.users'), url: RouteNames.userManagementUser.path },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <UserSearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={this.state.isSearching}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} isLoading={this.state.isSearching} />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={this.state.page}
            pageSize={this.state.size}
            totalCount={this.state.totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

UserManagementUser.propTypes = {
  permissionsUser: PropTypes.objectOf(PropTypes.any),
};

UserManagementUser.defaultProps = {
  permissionsUser: {},
};

const mapStateToProps = createStructuredSelector({
  listUsers: makeGetListUsers() || {},
  params: makePageSearchUsersParams() || {},
  errorMessage: errorMessageUsers() || '',
  permissionsUser: makeGetPermissionsUserManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchUsers,
    setParamsListUsers,
  })(UserManagementUser)
);
