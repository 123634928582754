import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormWithTableItem, DataTable, FormCollapse, TitleFrom } from '../../common';

const BalancesForm = ({
  id,
  grantTable,
  accumulatorsTable,
  searchBalanceUnitByAccountId,
  balancesTable,
  balanceUnit,
  currencyOptions,
  customerInfo,
  getUnallocatedPayment,
}) => {
  const { t } = useTranslation('common');

  const [activeTab, setActiveTab] = useState({});
  const [unallocatedPaymentData, setUnallocatedPaymentData] = useState({});

  // const doSearchSubscriptionByAccountId = () => {
  //   searchBalanceUnitByAccountId(id);
  // };

  const doSearchSubscriptionByAccountId = useCallback(() => {
    searchBalanceUnitByAccountId(id);
  }, [searchBalanceUnitByAccountId, id]);

  useEffect(() => {
    doSearchSubscriptionByAccountId();
  }, [doSearchSubscriptionByAccountId]);

  useEffect(() => {
    if (balanceUnit?.balanceUnits?.length) {
      setActiveTab({ name: balanceUnit?.balanceUnits[0].subscriptionId, isActive: true });
    }
  }, [balanceUnit.balanceUnits]);

  useEffect(() => {
    getUnallocatedPayment(id, ({ success, data }) => {
      if (success) setUnallocatedPaymentData(data || {});
    });
  }, [getUnallocatedPayment, id]);

  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  const tablesRender = [
    {
      title: 'common:label.balances',
      dataKey: 'balances',
      columns: [
        ...balancesTable,
        {
          name: 'unallocatedAmount',
          label: 'common:label.unallocatedCredits',
        },
      ],
    },
    {
      title: 'common:label.grants',
      dataKey: 'grants',
      columns: grantTable,
    },
    {
      title: 'common:label.accumulators',
      dataKey: 'accumulators',
      columns: accumulatorsTable,
    },
  ];

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('common:label.balances')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        button={(
          <button type="button" onClick={doSearchSubscriptionByAccountId} className="btn-reload-balances">
            <i className="ti-reload" />
          </button>
)}
      >
        <div className="mb-4 ml-3 mr-3 mt-3">
          {balanceUnit?.balanceUnits?.length &&
            balanceUnit.balanceUnits.map(item => (
              <FormCollapse
                key={item.subscriptionId}
                isActive={activeTab && item.subscriptionId === activeTab.name && activeTab.isActive}
                title={`${t('label.subscription')}: ${item.subscriptionName || ''}`}
                state={item.subscriptionId}
                onToggleTab={onToggleTab}
                noCardBody
                isNoT
              >
                {tablesRender.map(val => (
                  <div className="col-md-12 mt-3">
                    <TitleFrom title={t(val.title)} isTabel />
                    <DataTable
                      columns={val.columns}
                      data={
                        item[val.dataKey] && val.dataKey === 'balances'
                          ? item[val.dataKey].map(val => {
                              return { ...val, ...unallocatedPaymentData };
                            })
                          : item[val.dataKey] || []
                      }
                    />
                  </div>
                ))}
                {/* <div className="col-md-12 mt-3">
                  <TitleFrom title={t('common:label.unallocatedCredits')} isTabel />
                  <DataTable
                    columns={unallocatedPaymentColumns}
                    data={[{ currency: customerInfo?.currency || null, ...unallocatedPaymentData }]}
                  />
                </div> */}
              </FormCollapse>
            ))}
        </div>
      </FormWithTableItem>
    </div>
  );
};

BalancesForm.propTypes = {
  id: PropTypes.string,
  grantTable: PropTypes.arrayOf(PropTypes.any),
  accumulatorsTable: PropTypes.arrayOf(PropTypes.any),
  balancesTable: PropTypes.arrayOf(PropTypes.any),
  currencyOptions: PropTypes.arrayOf(PropTypes.any),
  searchBalanceUnitByAccountId: PropTypes.func.isRequired,
  getUnallocatedPayment: PropTypes.func.isRequired,
  balanceUnit: PropTypes.objectOf(PropTypes.any),
  customerInfo: PropTypes.objectOf(PropTypes.any),
};

BalancesForm.defaultProps = {
  id: '',
  grantTable: [],
  accumulatorsTable: [],
  balancesTable: [],
  balanceUnit: {},
  currencyOptions: [],
  customerInfo: {},
};

export default BalancesForm;
