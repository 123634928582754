import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const colorDefaultList = [
  {
    label: 'label.active',
    color: '#28a745',
  },
  {
    label: 'label.INACTIVE',
    color: 'orange',
  },
  {
    label: 'label.CLOSEDAndCANCELLED',
    color: 'grey',
  },
];

const ColorListGroup = ({ colorList }) => {
  const { t } = useTranslation('common');
  return (
    <div className="col-md-12 row group-label-details mt-2">
      {(colorList || colorDefaultList).map(val => (
        <span className="d-flex">
          <span style={{ backgroundColor: val.color }} className="label-color" />
          <span className="label-title">{t(val.label)}</span>
        </span>
      ))}
    </div>
  );
};

ColorListGroup.propTypes = {
  colorList: PropTypes.arrayOf(PropTypes.any),
};

ColorListGroup.defaultProps = {
  colorList: null,
};

export default ColorListGroup;
