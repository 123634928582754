import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'amount',
    'amountRecognized',
    'billed',
    'salesChannel',
    'marketSegment',
    'accountType',
    'accountSubType',
    'revenueType',
    'revenueRecognitionType',
    'revenueActivityType',
    'transactionType',
    'status',
  ]);
  const createRevenueJournal = `createRevenueJournal(input: ${dataCreate})`;
  // console.log('createRevenueJournal', createRevenueJournal);
  // return createRevenueJournal;
  return gql`
  mutation {
    ${createRevenueJournal} {
      id
    }
  }
`;
};
