import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['type'])}` : '';
  const searchLadingBill = `searchLadingBill(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchLadingBill} {
        id
        type
        accountId
        invoiceId
        expeditionCode
        data
        filePath
        startDate
        endDate
      }
    }
  `;
};
