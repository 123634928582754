import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { searchConfigOperatorMapList } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import SearchForm from './SearchForm';

class ModalOperatorMapList extends Component {
  state = {};

  render() {
    const { t, defaultSearchParams } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'operatorId',
          label: 'common:label.operatorId',
        },
        {
          name: 'operatorName',
          label: 'common:label.operatorName',
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={t('label.chooseOperatorConfig')}
        isOpen={this.props.isOpen}
        SearchForm={SearchForm}
        searchItems={this.props.searchConfigOperatorMapList}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectFieldName="operatorId"
        selectedParentId={this.props.selectedParentId}
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalOperatorMapList.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  searchConfigOperatorMapList: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default withTranslation('common')(connect(null, { searchConfigOperatorMapList })(ModalOperatorMapList));
