import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { DataTable, CardHeaderForm, GenericInput } from '../../../components/common';
import { selectPackageId, selectBundleId, selectPriceOfferId, getBundleByBundleId } from '../../App/actions';
import constants from '../constants';

const tableColumns = [
  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reason')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'common:label.initialTerm',
  },
  {
    name: 'initialTermUnit',
    label: 'common:label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'common:label.renewalTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'common:label.renewalTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialTerm',
    label: 'common:label.trialTerm',
  },
  {
    name: 'trialTermUnit',
    label: 'common:label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

class DowngradeService extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.selectService = [];
    this.ids = [];
  }

  componentDidMount = () => {
    const { quoteDetails } = this.props;
    this.getTypePurchase(quoteDetails.services);
  };

  componentWillReceiveProps(nextProps) {
    const { quoteDetails } = this.props;
    if (JSON.stringify(nextProps.quoteDetails) !== JSON.stringify(quoteDetails)) {
      this.getTypePurchase(nextProps.quoteDetails.services);
    }
  }

  getTypePurchase = data => {
    const bSelected = [];
    const poSelected = [];
    if (!data.length) {
      this.setState({ mode: constants.ModalMode.none });
    } else if (data[0].packageId) {
      this.setState({
        mode: constants.ModalMode.purchasePackage,
      });
    } else if (data[0].bundleId) {
      data.forEach(el => {
        bSelected.push(el.bundleId);
      });
      this.setState({
        mode: constants.ModalMode.purchaseBundle,
      });
    } else {
      data.forEach(el => {
        el.lines.forEach(elm => {
          poSelected.push(elm.priceOfferId);
        });
      });
      this.setState({
        mode: constants.ModalMode.purchaseAlaCarte,
      });
    }
  };

  onClickRow = (ids, list) => {
    const { mode } = this.state;
    if (mode === constants.ModalMode.purchasePackage) {
      if (ids.length > 0) {
        const rows = list.filter(item => item.id === ids[0]);
        this.selectService = rows.map(item => {
          return item.components;
        });
        this.ids = ids;
        return;
      }
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      if (ids.length > 0) {
        const tempServices = ids.map(id => {
          const rows = list.filter(item => item.id === id);

          return rows && rows.length > 0 ? rows[0] : {};
        });
        this.selectService = tempServices;
        this.ids = ids;
        return;
      }
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      if (ids.length > 0) {
        const tempServices = ids.map(id => {
          const rows = list.filter(item => item.id === id);
          return rows && rows.length > 0 ? rows[0] : {};
        });
        this.selectService = tempServices;
        this.ids = ids;
      }
    }
  };

  render() {
    const { quoteDetails, subscriptionMessage, id, groupServices, t, doGetDependentOffers } = this.props;
    const columns = [...tableColumns];
    const subscription = [
      {
        id: quoteDetails.id,
        accountId: quoteDetails.accountId,
        status: quoteDetails.status,
        renewalTerm: quoteDetails.renewalTerm,
        renewalTermUnit: quoteDetails.renewalTermUnit,
        reason: quoteDetails.reason,
        initialTerm: quoteDetails.initialTerm,
        initialTermUnit: quoteDetails.initialTermUnit,
        trialTerm: quoteDetails.trialTerm,
        trialTermUnit: quoteDetails.trialTermUnit,
        effectiveDate: quoteDetails.effectiveDate,
      },
    ];
    const tableConfig = {
      columns,
      data: subscription,
      getRowClass: () => {
        return '';
      },
    };

    const tableColumnsService = [
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        // render: (colName, item, index) => {

        //   return <span>{item.provisioningId}</span>;
        // },
      },
      {
        name: 'action',
        label: 'common:label.action',
      },
      {
        name: 'status',
        label: 'common:label.status',
        render: (colName, item, index, t) => {
          const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'packageName',
        label: 'common:label.packageName',
      },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <CardHeaderForm title={t('label.subscription')} label={t('label.accountNumber')} value={id} />
          <DataTable {...tableConfig} noDataMessage={subscriptionMessage} onClickRow={this.onClickRow} />
          <div className="row">
            <h5 className="card-title title-form" style={{ fontWeight: 'bold' }}>
              {t('label.serviceUnits')}
            </h5>
          </div>
          {map(groupServices, (service, serviceName) => {
            const slt = t('selections:serviceType')().find(val => val.value === service[0].serviceType);
            let newColumns = tableColumnsService;
            if (service[0] && service[0].packageId) {
              newColumns = [
                ...newColumns,
                {
                  name: 'upgradePackage',
                  label: 'common:label.downgradePackage',
                  render: (colName, item, idx) => {
                    return (
                      <GenericInput
                        key="downgradePackage"
                        value={item.newName}
                        wrapperClass="col-md-12"
                        type="text"
                        onChange={() => {}}
                        name="downgradePackage"
                        fa="fa fa-external-link"
                        onClick={() => doGetDependentOffers({ service: item, indexOfItem: idx, serviceName })}
                        disabled={(!item.bundleId && !item.packageId) || item.isDisabledUpgrade}
                      />
                    );
                  },
                },
              ];
            }
            if (service[0] && !service[0].packageId && service[0].bundleId) {
              newColumns = [
                ...newColumns,
                {
                  name: 'upgradeBundle',
                  label: 'common:label.downgradeBundle',
                  render: (colName, item, idx) => {
                    return (
                      <GenericInput
                        key="downgradeBundle"
                        value={item.newName}
                        wrapperClass="col-md-12"
                        type="text"
                        onChange={() => {}}
                        name="downgradeBundle"
                        fa="fa fa-external-link"
                        onClick={() => doGetDependentOffers({ service: item, indexOfItem: idx, serviceName })}
                        disabled={(!item.bundleId && !item.packageId) || item.isDisabledUpgrade}
                      />
                    );
                  },
                },
              ];
            }
            return (
              <div className="col-md-12 pb-3" key={`key-${serviceName}`}>
                {/* <CardHeaderForm
                  title={t('label.services')}
                  label={t('label.serviceType')}
                  value={slt ? slt.label : ''}
                /> */}
                <CardHeaderForm
                  title={`${t('label.serviceType')}: ${slt ? slt.label : ''}`}
                  wrapperClass="font-weight-normal"
                  // label={t('label.serviceType')}
                  // value={slt ? slt.label : ''}
                />
                <DataTable columns={newColumns} data={service} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

DowngradeService.defaultProps = {
  quoteDetails: {},
  subscriptionMessage: '',
  id: '',
  groupServices: {},
  t: () => {},
  doGetDependentOffers: () => {},
};

DowngradeService.propTypes = {
  quoteDetails: PropTypes.objectOf(PropTypes.any),
  subscriptionMessage: PropTypes.string,
  id: PropTypes.string,
  groupServices: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
  doGetDependentOffers: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});
const newDowngradeService = connect(mapStateToProps, {
  selectPackageId,
  selectBundleId,
  selectPriceOfferId,
  getBundleByBundleId,
})(DowngradeService);

export default withTranslation('common')(newDowngradeService);
