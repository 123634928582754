import quoteEnum from 'constantsApp/order/quoteEnum';
import constants from './constants';

export function getMessageModal(mode, detail, t) {
  switch (mode) {
    case constants.ModalMode.purchasePackage:
      return `Do you want to remove package and services? Package cannot be partially removed. ${detail || ''}`;
    case constants.ModalMode.purchaseBundle:
      return `Do you want to remove services and lines? ${detail || ''}`;
    case constants.ModalMode.purchaseAlaCarte:
      return `${
        t ? t('message.messageRemoveLinePurchaseAlaCarte') : 'Do you want to cancel all lines under this service?'
      } ${detail || ''}`;
    default:
      return `Do you want to remove package and services? Package cannot be partially removed. ${detail || ''}`;
  }
}

export const convertLineFromDiscountOffers = (list, lastIndex) => {
  if (list && list.length) {
    return list.map((item, index) => ({
      index: lastIndex + index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: item.startDate,
      endDate: item.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: item.priceOfferId,
      discountOfferId: item.id || null,
      discountOfferName: item.name,
    }));
  }
  return [];
};


export const initServiceWithSelectPackage = ({
  index,
  bundleId,
  packageId,
  serviceType,
  packageName,
  bundleName,
  discountOfferId,
}) => {
  return {
    index,
    serviceType,
    action: 'ADD',
    status: quoteEnum.status.create,
    bundleId,
    packageId,
    packageName,
    bundleName,
    lines: [],
    discountOfferId,
    isPackage: true,
  };
};

const initLineFromBundle = (bundle, component) => {
  return {
    index: component.index,
    action: 'ADD',
    status: 'CREATED',
    reason: null,
    quantity: null,
    priceOverride: null,
    priceOffset: null,
    discountPercent: null,
    startDate: bundle.startDate,
    endDate: bundle.endDate,
    relativeStart: null,
    relativeEnd: null,
    relativeStartUnit: null,
    relativeEndUnit: null,
    priceOfferId: component.priceOfferId,
    discountOfferId: component.discountOfferId || null,
    discountOfferName: component.discountOfferName,
    priceOfferName: component.priceOfferName,
  };
};

export const initServiceWithSelectBundle = (bundle, index) => {
  return {
    index: index + 1,
    serviceType: bundle && bundle.components && bundle.components.length > 0 ? bundle.components[0].serviceType : '',
    action: 'ADD',
    reason: null,
    status: 'CREATED',
    bundleId: bundle.id,
    bundleName: bundle.name,
    packageId: null,
    discountOfferId: bundle.discountOfferId || null,
    discountOfferName: bundle.discountOfferName || null,
    isBundle: true,
    lines:
      bundle && bundle.components
        ? bundle.components.map(component => {
            return initLineFromBundle(bundle, component);
          })
        : [],
  };
};

const initLineFromPriceOffer = (priceOffer, index) => {
  return {
    index,
    action: 'ADD',
    status: 'CREATED',
    reason: null,
    quantity: null,
    priceOverride: null,
    priceOffset: null,
    discountPercent: null,
    startDate: priceOffer.startDate,
    endDate: priceOffer.endDate,
    relativeStart: null,
    relativeEnd: null,
    relativeStartUnit: null,
    relativeEndUnit: null,
    priceOfferId: priceOffer.id,
    priceOfferName: priceOffer.name,
    discountOfferId: priceOffer.discountOfferId || null,
  };
};

export const initServiceFromPriceOffers = (priceOffers, serviceIndex) => {
  return {
    index: serviceIndex,
    serviceType: priceOffers[0].serviceType || null,
    provisioningId: null,
    action: 'ADD',
    reason: null,
    status: 'CREATED',
    bundleId: null,
    packageId: null,
    isPriceOffer: true,
    lines: priceOffers.map((item, i) => {
      return initLineFromPriceOffer(item, i);
    }),
  };
};
