import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getInternetOperatorDataCostReport = `getInternetOperatorDataCostReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getInternetOperatorDataCostReport} {
        lineDate
        operatorId
        operatorName
        contractCapacity
        commitmentBase
        tariff
        rentalAmount
        totalQuantity
        directCommitment
        directTariff
        totalCostDirect
        remainingCommitment
        overageQuantity
        overageCost
        totalCost
      }
    }
  `;
};
