import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const payload = parseToMutationRequest(data, ['bonusPeriodUnit', 'amount', 'index']);
  const modifyEmployeeBonus = `modifyEmployeeBonus(input: ${payload})`;
  return gql`
  mutation {
    ${modifyEmployeeBonus} {
      id
    }
  }
`;
};
