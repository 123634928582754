import { cloneDeep } from 'lodash';
import { convertHeaderExcelFile } from '../exportHelper';
import { formatMoneyValueToExport, redFontExcelFile, getLabelOptions } from '../utils';

const multiDataSetDefault = ({ t, title, columnsTable }) => convertHeaderExcelFile({ title, columnsTable, t });

const convertJson2Sheet = ({ data, t, title, columnsTable, filter }) => {
  const newDataSheetSet = cloneDeep(
    multiDataSetDefault({
      t,
      title,
      columnsTable,
    })
  );
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'inAdvanceFlag') {
          if (filter && filter.groupByAccount === 'TRUE') {
            newItem.push({ value: '' });
          } else newItem.push({ value: item.inAdvanceFlag ? 'TRUE' : 'FALSE' });
        }
        if (val.name !== 'inAdvanceFlag') {
          if (item[val.name] && typeof item[val.name] === 'number') {
            newItem.push({
              value: formatMoneyValueToExport(item[val.name]),
              style: val.isSupportRedFront && item[val.name] < 0 ? redFontExcelFile : null,
            });
          } else if (val.isRenderT) {
            newItem.push({
              value: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
          } else {
            newItem.push({ value: item[val.name] ? item[val.name] : '' });
          }
        }
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
