/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { GenericInput } from '../../common';
import ModalInvoice from '../../../containers/PaymentsPage/PaymentAgent/OneOffPayment/ModalInvoice';
import ModalSelectItemId from '../../../containers/PaymentsPage/PaymentAgent/OneOffPayment/ModalSelectItemId';

const searchFields = [
  {
    label: 'label.startDateWithRank',
    name: 'startDate',
    type: 'date',
  },
  {
    label: 'label.endDateWithRank',
    name: 'endDate',
    type: 'date',
  },
  {
    name: 'sourceCurrency',
    label: 'common:label.paymentCurrency',
  },
  {
    label: 'label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:paymentSuspenseStatus',
    isClearable: true,
  },
  {
    label: 'label.payemntThresholdMoreThan',
    name: 'minThresholdAmount',
    type: 'number',
  },
  {
    label: 'label.payemntThresholdLessThan',
    name: 'maxThresholdAmount',
    type: 'number',
  },
  {
    label: 'label.unallocatedAmountThresholdMoreThan',
    name: 'minUnallocatedPaymentThreshold',
    type: 'number',
  },
  {
    label: 'label.unallocatedAmountThresholdLessThan',
    name: 'maxUnallocatedPaymentThreshold',
    type: 'number',
  },
  {
    label: 'label.paymentSource',
    name: 'paymentSource',
    // options: [],
    // isClearable: true,
    // type: 'select',
  },
  {
    label: 'label.fileName',
    name: 'fileName',
  },
  {
    label: 'label.suspenseReason',
    name: 'suspenseReason',
    type: 'select',
    tOptions: 'selections:suspenseReason',
    isClearable: true,
  },
  {
    label: 'label.organization',
    name: 'organization',
  },
  {
    name: 'accountId',
    label: 'common:label.accountId',
  },
  {
    label: 'label.paymentSuspenseId',
    name: 'id',
  },
  {
    label: 'label.paymentReferenceId',
    name: 'paymentReferenceId',
  },
  {
    label: 'label.paymentMethod',
    name: 'paymentMethod',
    type: 'select',
    tOptions: 'selections:paymentMethod',
    isClearable: true,
  },
  {
    label: 'label.accountType',
    name: 'accountType',
    type: 'select',
    tOptions: 'selections:accountType',
    isClearable: true,
  },
  {
    label: 'label.useExchangeRate',
    name: 'useExchangeRate',
    type: 'number',
  },
];

class SuspenseSearchForm extends React.PureComponent {
  state = {
    filter: { status: 'OPEN' },
    isOpenModalInvoice: false,
    isOpenModalAccount: false,
    currencyOptions: [],
    optionFileName: [],
    listPaymentOptions: [],
  };

  componentDidMount() {
    const { getCurrencyConfigApp, getPaymentItemMap, getBatchPaymentFileConfigSelect, defaultFilter } = this.props;
    getPaymentItemMap('', ({ success, data }) => {
      if (success && data && data.paymentItemMaps) {
        this.setState({
          optionFileName: data.paymentItemMaps.map(val => ({ value: val.fileName, label: val.fileName })),
        });
      }
    });
    getCurrencyConfigApp('', ({ success, data }) => {
      if (success && data && data.currencyListConfig) {
        const currencyOptions = data.currencyListConfig.map(val => ({
          label: `${val.name} (${val.currencyId})`,
          value: val.currencyId,
        }));
        this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
      }
      if (defaultFilter) this.setState({ filter: defaultFilter });
    });

    // getBatchPaymentFileConfigSelect('', ({ success, data }) => {
    //   if (success && data && data.files) {
    //     const listPaymentOptions = data.files.map(val => {
    //       return { label: val.name, value: val.name };
    //     });
    //     this.setState({
    //       listPaymentOptions: [
    //         { label: 'Automated', value: 'Automated' },
    //         { label: 'Manual', value: 'Manual' },
    //         ...listPaymentOptions,
    //       ],
    //     });
    //   }
    // });
  }

  handleReset = () => {
    this.setState({ filter: {} });
  };

  onChange = ({ name, value }) => {
    const { filter } = this.state;
    filter[name] = value || null;
    this.setState({ ...filter });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { filter } = this.state;
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(filter);
    }
  };

  onToogleModalAccount = () => {
    const { isOpenModalAccount } = this.state;
    this.setState({ isOpenModalAccount: !isOpenModalAccount });
  };

  onToogleModalInvoice = () => {
    const { isOpenModalInvoice, filter } = this.state;
    // if (!filter.accountId) return toast('You need to select account first', { type: toast.TYPE.WARNING });
    return this.setState({ isOpenModalInvoice: !isOpenModalInvoice });
  };

  onSelectAccountId = itemId => {
    const { filter } = this.state;
    filter.accountId = itemId;
    this.setState({ ...filter, isOpenModalAccount: false });
  };

  onSelectInvoice = itemId => {
    const { filter } = this.state;
    filter.invoiceUnitId = itemId;
    this.setState({ ...filter, isOpenModalInvoice: false });
  };

  render() {
    const {
      filter,
      isOpenModalInvoice,
      isOpenModalAccount,
      currencyOptions,
      optionFileName,
      listPaymentOptions,
    } = this.state;
    const { t } = this.props;
    return (
      <form className="col-sm-12 mb-30 pl-1" onSubmit={this.handleSubmit}>
        <div className="card-body group-flex">
          {searchFields.map(ip => {
            if (ip.name === 'sourceCurrency') {
              return (
                <GenericInput
                  {...ip}
                  type="select"
                  options={currencyOptions || []}
                  onChange={this.onChange}
                  value={filter[ip.name]}
                  wrapperClass="col-md-3"
                  isClearable
                />
              );
            }
            // if (ip.name === 'paymentSource') {
            //   return (
            //     <GenericInput
            //       {...ip}
            //       type="select"
            //       options={listPaymentOptions || []}
            //       onChange={this.onChange}
            //       value={filter[ip.name]}
            //       wrapperClass="col-md-3"
            //       isClearable
            //     />
            //   );
            // }
            if (ip.name === 'fileType') {
              return (
                <GenericInput
                  {...ip}
                  type="select"
                  options={optionFileName || []}
                  onChange={this.onChange}
                  value={filter[ip.name]}
                  wrapperClass="col-md-3"
                  isClearable
                />
              );
            }
            return (
              <GenericInput {...ip} onChange={this.onChange} value={filter[ip.name] || ''} wrapperClass="col-md-3" />
            );
          })}

          <div className="form-group text-right btn-search-actions col-md-6">
            <br />
            <button type="button" className="button button-border black x-small" onClick={this.handleReset}>
              {t('label.clear')}
            </button>
            <button type="submit" className="button x-small">
              {t('label.search')}
            </button>
          </div>
          <ModalInvoice
            isOpen={isOpenModalInvoice}
            onSelect={this.onSelectInvoice}
            accountId={filter.accountId}
            selectedParentId={filter.invoiceUnitId}
            onCancel={() => {
              this.setState({ isOpenModalInvoice: false });
            }}
          />
          <ModalSelectItemId
            isOpen={isOpenModalAccount}
            selectedId={filter.accountId}
            onSelect={this.onSelectAccountId}
            onCancel={this.onToogleModalAccount}
            toggleModal={this.onToogleModalAccount}
          />
        </div>
      </form>
    );
  }
}

SuspenseSearchForm.propTypes = {
  onSubmit: PropTypes.func,
};

SuspenseSearchForm.defaultProps = {
  onSubmit: () => {},
};

export default withTranslation('common')(SuspenseSearchForm);
