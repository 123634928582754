import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType'])}` : '';
  const searchConfigOperatorMapList = `searchConfigOperatorMapList(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchConfigOperatorMapList} {
        id
        index
        operatorId
        operatorName
        status
        alternateName
        carrierId
      }
    }
  `;
};
