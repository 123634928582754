import * as types from './types';

export function getPaymentConfigByType(payload, cb) {
  return {
    type: types.GET_PAYMENT_CONFIG_BY_TYPE,
    payload,
    cb,
  };
}

export function searchPayments(payload, cb) {
  return {
    type: types.SEARCH_PAYMENTS,
    payload,
    cb,
  };
}

export function searchPaymentsSuccess(payload) {
  return {
    type: types.SEARCH_PAYMENTS_SUCCESS,
    payload,
  };
}

export function searchPaymentsFailure(payload) {
  return {
    type: types.SEARCH_PAYMENTS_FAILURE,
    payload,
  };
}

export function setParamsPayments(payload) {
  return {
    type: types.SET_PARAMS_PAYMENTS,
    payload,
  };
}

export function reversePayment(payload, cb) {
  return {
    type: types.REVERSE_PAYMENT,
    payload,
    cb,
  };
}

export function searchPaymentSuspense(payload, cb) {
  return {
    type: types.SEARCH_PAYMENTS_SUSPENSE,
    payload,
    cb,
  };
}

export function searchPaymentSuspenseSuccess(payload) {
  return {
    type: types.SEARCH_PAYMENTS_SUSPENSE_SUCCESS,
    payload,
  };
}

export function searchPaymentSuspenseFailure(payload) {
  return {
    type: types.SEARCH_PAYMENTS_SUSPENSE_FAILURE,
    payload,
  };
}

export function setParamsPaymentsSuspense(payload) {
  return {
    type: types.SET_PARAMS_PAYMENTS_SUSPENSE,
    payload,
  };
}

export function applyPaymentSuspense(payload, cb) {
  return {
    type: types.APPLY_PAYMENT_SUSPENSE,
    payload,
    cb,
  };
}

// search invoices
export function searchInvoices(payload, cb) {
  return {
    type: types.SEARCH_INVOICES,
    payload,
    cb,
  };
}

export function searchInvoicesSuccess(payload) {
  return {
    type: types.SEARCH_INVOICES_SUCCESS,
    payload,
  };
}

export function searchInvoicesFailure(payload) {
  return {
    type: types.SEARCH_INVOICES_FAILED,
    payload,
  };
}

export function createPaymentConfigMerchant(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_CONFIG_MERCHANT,
    payload,
    cb,
  };
}

export function modifyPaymentConfigMerchant(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_CONFIG_MERCHANT,
    payload,
    cb,
  };
}

export function createPaymentConfigMethod(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_CONFIG_METHOD,
    payload,
    cb,
  };
}

export function modifyPaymentConfigMethod(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_CONFIG_METHOD,
    payload,
    cb,
  };
}

export function createPaymentConfigTerm(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_CONFIG_TERM,
    payload,
    cb,
  };
}

export function modifyPaymentConfigTerm(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_CONFIG_TERM,
    payload,
    cb,
  };
}

export function applyPayment(payload, cb) {
  return {
    type: types.APPLY_PAYMENT,
    payload,
    cb,
  };
}

export function searchAccounts(payload, cb) {
  return {
    type: types.SEARCH_ACCOUNTS,
    payload,
    cb,
  };
}

export function searchAccountsSuccess(payload) {
  return {
    type: types.SEARCH_ACCOUNTS_SUCCESS,
    payload,
  };
}

export function searchAccountsFailure(payload) {
  return {
    type: types.SEARCH_ACCOUNTS_FAILURE,
    payload,
  };
}

export function setParamsAccounts(payload) {
  return {
    type: types.SET_PARAMS_ACCOUNTS,
    payload,
  };
}

export function allocatePayment(payload, cb) {
  return {
    type: types.ALLOCATE_PAYMENT,
    payload,
    cb,
  };
}

export function modifyPaymentSuspense(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_SUSPENSE,
    payload,
    cb,
  };
}

// Payment Surcharge
export function getPaymentSurchargeConfig(payload, cb) {
  return {
    type: types.GET_PAYMENT_SURCHARGE_CONFIG,
    payload,
    cb,
  };
}

export function getPaymentSurchargeConfigSuccess(payload, cb) {
  return {
    type: types.GET_PAYMENT_SURCHARGE_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyPaymentSurchargeConfig(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_SURCHARGE_CONFIG,
    payload,
    cb,
  };
}

export function createPaymentSurchargeConfig(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_SURCHARGE_CONFIG,
    payload,
    cb,
  };
}

// Payment process
export function searchBatchPaymentProcess(payload, cb) {
  return {
    type: types.SEARCH_BATCH_PAYMENT_PROCESS,
    payload,
    cb,
  };
}

export function searchBatchPaymentProcessSuccess(payload, cb) {
  return {
    type: types.SEARCH_BATCH_PAYMENT_PROCESS_SUCCESS,
    payload,
    cb,
  };
}

export function getBatchPaymentDetais(payload, cb) {
  return {
    type: types.GET_BATCH_PAYMENT_DETAIS,
    payload,
    cb,
  };
}

export function processBatchPayment(payload, cb) {
  return {
    type: types.PROCESS_BATCH_PAYMENT,
    payload,
    cb,
  };
}

// Payment Batch File
export function getBatchPaymentFileConfig(payload, cb) {
  return {
    type: types.GET_PAYMENT_BATCH_FILE_CONFIG,
    payload,
    cb,
  };
}

export function getBatchPaymentFileConfigSuccess(payload, cb) {
  return {
    type: types.GET_PAYMENT_BATCH_FILE_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyBatchPaymentFileConfig(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_BATCH_FILE_CONFIG,
    payload,
    cb,
  };
}

export function createBatchPaymentFileConfig(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_BATCH_FILE_CONFIG,
    payload,
    cb,
  };
}

// Payment Item Map
export function getPaymentItemMap(payload, cb) {
  return {
    type: types.GET_PAYMENT_ITEM_MAP,
    payload,
    cb,
  };
}

export function getPaymentItemMapSuccess(payload, cb) {
  return {
    type: types.GET_PAYMENT_ITEM_MAP_SUCCESS,
    payload,
    cb,
  };
}

export function modifyPaymentItemMap(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_ITEM_MAP,
    payload,
    cb,
  };
}

export function createPaymentItemMap(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_ITEM_MAP,
    payload,
    cb,
  };
}

export function getCrossCurrencyPaymentDetails(payload, cb) {
  return {
    type: types.GET_CROSS_CURRENCY_PAYMENT_DETAILS,
    payload,
    cb,
  };
}

export function deletePaymentFile(payload, cb) {
  return {
    type: types.DELETE_PAYMENT_FILE,
    payload,
    cb,
  };
}

export function searchPaymentsReport(payload, cb) {
  return {
    type: types.SEARCH_PAYMENTS_REPORT,
    payload,
    cb,
  };
}

export function searchPaymentSuspenseReport(payload, cb) {
  return {
    type: types.SEARCH_PAYMENTS_SUSPENSE_REPORT,
    payload,
    cb,
  };
}

export function resendPaymentVendorPayment(payload, cb) {
  return {
    type: types.RESEND_PAYMENT_VENDOR_PAYMENT,
    payload,
    cb,
  };
}

export function processBatchPaymentProxy(payload, cb) {
  return {
    type: types.PROCESS_BATCH_PAYMENT_PROXY,
    payload,
    cb,
  };
}

export function searchConfigPaymentAllocation(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_PAYMENT_ALLOCATION,
    payload,
    cb,
  };
}

export function modifyConfigPaymentAllocation(payload, cb) {
  return {
    type: types.MODIFY_CONFIG_PAYMENT_ALLOCATION,
    payload,
    cb,
  };
}

export function createConfigPaymentAllocation(payload, cb) {
  return {
    type: types.CREATE_CONFIG_PAYMENT_ALLOCATION,
    payload,
    cb,
  };
}

export function getConfigPymtAllocSeqById(payload, cb) {
  return {
    type: types.GET_CONFIG_PYMT_ALLOC_SEQ_BY_ID,
    payload,
    cb,
  };
}
