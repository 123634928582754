import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'type', 'fileType', 'status']);
  const modifyTemplate = `modifyTemplate(input: ${dataModify})`;
  return gql`
    mutation {
      ${modifyTemplate} {
        id
        templates {
          index
          type
          filePath
          fileType
          status
          country
          name
          supplementaryFiles {
            index
            description
            filePath
          }
        }
      }
    }
  `;
};
