import React, { useEffect, useState, useCallback } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  FormWithTableItem,
  DataTable,
  FormCollapse,
  CollapsibleTable,
  GenericInput,
  Switch,
  ModalWithItem,
  ModalAccept,
  TablePagination,
} from '../../common';
import PricingModalOrder from '../../common/PricingModalOrder';
import ModalOrderProvisioningAttributes from '../../common/ModalOrderProvisioningAttributes';
import { formatNumberValue, getPageTotalCount, supportShowNumberValue } from '../../../utils/utils';

const accountAssetsSort = {
  bundleName: {
    asc: 'bundleName_ASC',
    desc: 'bundleName_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  offerName: {
    asc: 'offerName_ASC',
    desc: 'offerName_DESC',
  },
  offerOverride: {
    asc: 'offerOverride_ASC',
    desc: 'offerOverride_DESC',
  },
  offerType: {
    asc: 'offerType_ASC',
    desc: 'offerType_DESC',
  },
  packageName: {
    asc: 'packageName_ASC',
    desc: 'packageName_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  serviceUnitId: {
    asc: 'serviceUnitId_ASC',
    desc: 'serviceUnitId_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  subscriptionId: {
    asc: 'subscriptionId_ASC',
    desc: 'subscriptionId_DESC',
  },
};

const collapsibleMenu = [
  {
    title: 'label.equipments',
    state: 'Equipments',
  },
  {
    title: 'label.infrastructureEquipments',
    state: 'Infrastructure Equipments',
  },
  {
    title: 'label.locations',
    state: 'Locations',
  },
  {
    title: 'label.billableService',
    state: 'Billable Service',
  },
  {
    title: 'label.subscriptionData',
    state: 'Subscription Data',
  },
];

// const customerAttributeColumns = [
//   {
//     name: 'name',
//     label: 'label.attributeName',
//   },
//   {
//     name: 'attributeType',
//     label: 'label.attributeType',
//     isRenderT: true,
//     render: (colName, item, t) => {
//       const slt = t ? t('selections:variableTypes')().find(val => val.value === item.type) : '';
//       return <span>{slt ? slt.label : ''}</span>;
//     },
//   },
//   {
//     name: 'length',
//     label: 'label.length',
//   },
//   {
//     name: 'mandatory',
//     label: 'common:label.mandatory',
//   },
// ];

const locationsTable = [
  {
    name: 'omsInternalId',
    label: 'common:label.instanceId',
  },
  {
    name: 'userId',
    label: 'common:label.userId',
  },
  {
    name: 'pointId',
    label: 'common:label.pointId',
  },
  {
    name: 'pointName',
    label: 'common:label.pointName',
  },
  {
    name: 'cpId',
    label: 'common:label.cpId',
  },
  {
    name: 'commercialClasification',
    label: 'common:label.commercialClasification',
  },
  {
    name: 'ownerName',
    label: 'common:label.ownerName',
  },

  {
    name: 'deliveryType',
    label: 'common:label.deliveryType',
  },
  {
    name: 'ring',
    label: 'common:label.ring',
  },
  {
    name: 'floor',
    label: 'common:label.floor',
  },
  {
    name: 'colony',
    label: 'common:label.colony',
  },
  {
    name: 'street',
    label: 'common:label.street',
  },
  {
    name: 'city',
    label: 'common:label.city',
  },
  {
    name: 'state',
    label: 'common:label.state',
  },
  {
    name: 'zipCode',
    label: 'common:label.zipCode',
  },
  {
    name: 'plusCode',
    label: 'common:label.plusCode',
  },
  {
    name: 'country',
    label: 'common:label.country',
  },
  {
    name: 'creationDate',
    label: 'common:label.creationDate',
  },
  {
    name: 'endDate',
    label: 'common:label.endDate',
  },
  {
    name: 'fullName',
    label: 'common:label.fullName',
  },
  {
    name: 'company',
    label: 'common:label.company',
  },
  {
    name: 'latitude',
    label: 'common:label.latitude',
  },
  {
    name: 'longitude',
    label: 'common:label.longitude',
  },
  {
    name: 'ownerId',
    label: 'common:label.ownerId',
  },
  {
    name: 'validationStatus',
    label: 'common:label.validationStatus',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'statusId',
    label: 'common:label.statusId',
  },
  {
    name: 'cp',
    label: 'common:label.cp',
  },
  {
    name: 'floorSite',
    label: 'common:label.floorSite',
  },
  {
    name: 'ringName',
    label: 'common:label.ringName',
  },
];

const historyTable = [
  {
    name: 'orderId',
    label: 'common:label.orderId',
  },
  {
    name: 'crmId',
    label: 'common:label.crmId',
  },
  {
    name: 'provisioningId',
    label: 'common:label.provisioningId',
  },
  {
    name: 'opportunityId',
    label: 'common:label.opportunityId',
  },
  {
    name: 'opportunityName',
    label: 'common:label.opportunityName',
  },
  {
    name: 'orderDate',
    label: 'common:label.orderDate',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  {
    name: 'action',
    label: 'common:label.action',
  },
  {
    name: 'deliveryDate',
    label: 'common:label.deliveryDate',
  },
  {
    name: 'cancellationDate',
    label: 'common:label.cancellationDate',
  },
  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
  },
  {
    name: 'quantity',
    label: 'common:label.quantity',
  },
  {
    name: 'amount',
    label: 'common:label.amount',
  },
  {
    name: 'unitPrice',
    label: 'common:label.unitPrice',
  },
];

const historyDataSelectField = [
  {
    name: 'serviceId',
    label: 'common:label.serviceId',
  },
  {
    name: 'serviceName',
    label: 'common:label.serviceName',
  },
  {
    name: 'omsInternalId',
    label: 'common:label.instanceId',
  },
];

const AssetsForm = ({
  id,
  columns,
  data,
  t,
  getOrderSummaryByAccountId,
  modifyAccountService,
  getBillableServicePricing,
  currencyOptions,
  getOrderHistory,
  saveAccountNonBillables,
  getCcpDateTimeConfig,
  customerInfo,
  searchAccountAssets,
  getCcpPropertiesConfig,
  ccpPropertyList,
  ccpTime,
  // getCustomAttributesByObjectType,
}) => {
  const [activeTab, setActiveTab] = useState({});
  const [orderDetail, setOrderDetail] = useState({});
  const [activeSubTab, setActiveSubTab] = useState({});
  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
  const [pricingData, setPricingData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [alternateDescription, setAlternateDescription] = useState('');
  const [historyItemSelect, setHistoryItemSelect] = useState({});
  const [indexCopyAllChange, setIndexCopyAllChange] = useState(-1);
  const [dataSubmitChange, setDataSubmitChange] = useState({});
  const [currentCcpDateTime, setCurrentCcpDateTime] = useState(null);
  const [isOpenModalCopyChangeToAllRecurring, setIsOpenModalCopyChangeToAllRecurring] = useState(false);
  const [isOpenPricingModalProvisioningAttributes, setIsOpenPricingModalProvisioningAttributes] = useState(false);
  const [itemSelectProvisioningAttributes, setItemSelectProvisioningAttributes] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [sorted, setSorted] = useState({});

  const cutOffDOMOptions = t('selections:cutOffDOM')();

  const isBillableServiceEnabled =
    ccpPropertyList &&
    ccpPropertyList.length &&
    ccpPropertyList.find(ccp => ccp.property === 'billableServiceEnabled') &&
    ccpPropertyList.find(ccp => ccp.property === 'billableServiceEnabled').value === 'false';

  const billableServiceEnabled =
    data.ccpPropertyList && data.ccpPropertyList.find(val => val.property === 'billableServiceEnabled');

  const doGetOrderSummaryByAccountId = useCallback(() => {
    if (billableServiceEnabled && billableServiceEnabled.value === 'true')
      getOrderSummaryByAccountId(id, ({ success, data }) => {
        if (success) setOrderDetail(data);
      });
  }, [billableServiceEnabled, getOrderSummaryByAccountId, id]);

  const doSearchAccountAssets = useCallback(() => {
    searchAccountAssets({
      page: page + 1,
      size,
      filter: { accountId: id },
      sort: !isEmpty(sorted) ? accountAssetsSort[sorted.sortCol][sorted.sortDir] : null,
    });
  }, [searchAccountAssets, page, size, id, sorted]);

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onChangeInfrastructureEquipments = ({ name, value, index }) => {
    const newData = cloneDeep(orderDetail);
    newData.accountInfraNonBillablesSummaries[index][name === 'status' ? 'newStatus' : name] = value;
    setOrderDetail(newData);
  };

  const onChangeEquipments = ({ name, value, index }) => {
    const newData = cloneDeep(orderDetail);
    newData.accountNonBillablesSummaries[index][name === 'status' ? 'newStatus' : name] = value;
    setOrderDetail(newData);
  };

  const onToggleModalCopyChangeToAllRecurring = () => {
    setIsOpenModalCopyChangeToAllRecurring(!isOpenModalCopyChangeToAllRecurring);
  };

  const onApplyInfrastructureEquipments = ({ item, type }) => {
    let payload = {
      id,
      type: type || 'EQUIPMENT',
      omsInternalId: item.omsInternalId,
      externalId: item.externalId,
      status: item.newStatus || item.status || null,
    };
    if (item.isNew) {
      payload = {
        ...payload,
        action: 'ADD',
        assetTag: item.assetTag || null,
        comments: item.comments || null,
        description: item.description || null,
        cost: item.cost || null,
        deliveryDate: item.deliveryDate || null,
        inventoryItemId: item.inventoryItemId || null,
        primaryCode: item.primaryCode || null,
        quantity: item.quantity || null,
        exchangeRate: item.exchangeRate || null,
        statusId: item.statusId || null,
        transactionType: item.transactionType || null,
        orderFlag: item.orderFlag || null,
      };
    } else {
      payload.action = 'CANCEL';
    }
    if (!item.omsInternalId || !item.externalId) {
      return toast.error(t('common:message.mandatory'));
    }
    return saveAccountNonBillables(payload, ({ success }) => {
      if (success) doGetOrderSummaryByAccountId();
    });
  };

  const onAddNewItem = ({ type }) => {
    const newData = cloneDeep(orderDetail);
    const payloadInit = {
      omsInternalId: null,
      status: 'ACTIVE',
      assetTag: null,
      comments: null,
      description: null,
      cost: null,
      deliveryDate: null,
      inventoryItemId: null,
      primaryCode: null,
      quantity: null,
      exchangeRate: null,
      statusId: null,
      transactionType: null,
      externalId: null,
      orderFlag: null,
      isNew: true,
    };
    if (type === 'EQUIPMENT') {
      newData.accountNonBillablesSummaries = [payloadInit, ...newData.accountNonBillablesSummaries];
    } else {
      newData.accountInfraNonBillablesSummaries = [payloadInit, ...newData.accountInfraNonBillablesSummaries];
    }
    setOrderDetail(newData);
  };

  const equipmentsTable = [
    {
      name: 'saveConfig',
      label: 'label.apply',
      render: (colName, item) => (
        <button
          type="button"
          className="button x-small apply-btn"
          onClick={() => onApplyInfrastructureEquipments({ item, type: 'EQUIPMENT' })}
          disabled={item.status === 'CANCELLED'}
        >
          {t('common:label.apply')}
        </button>
      ),
    },
    // {
    //   name: 'action',
    //   label: 'common:label.action',
    //   isRenderT: true,
    //   render: (colName, item, t) => {
    //     const slt = t ? t('selections:orderAction')().find(val => val.value === item.action) : '';
    //     return <span>{slt ? slt.label : ''}</span>;
    //   },
    // },
    {
      name: 'omsInternalId',
      label: 'common:label.instanceId',
      style: { minWidth: '180px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.omsInternalId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="omsInternalId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'assetTag',
      label: 'common:label.assetTag',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.assetTag}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="assetTag"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'description',
      label: 'common:label.description',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="description"
          type="textarea"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'deliveryDate',
      label: 'common:label.deliveryDate',
      style: { minWidth: '180px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.deliveryDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="deliveryDate"
          readOnly={!item.isNew}
          type="date"
          disabled={!item.isNew}
        />
      ),
    },
    {
      name: 'quantity',
      label: 'common:label.quantity',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={supportShowNumberValue(item.quantity)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="quantity"
          readOnly={!item.isNew}
          type="number"
        />
      ),
    },
    {
      name: 'cost',
      label: 'common:label.cost',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={supportShowNumberValue(item.cost)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="cost"
          readOnly={!item.isNew}
          type="number"
        />
      ),
    },

    {
      name: 'primaryCode',
      label: 'common:label.primaryCode',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.primaryCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="primaryCode"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'inventoryItemId',
      label: 'common:label.inventoryItemId',
      style: { minWidth: '190px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.inventoryItemId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="inventoryItemId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'exchangeRate',
      label: 'common:label.exchangeRate',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={supportShowNumberValue(item.exchangeRate)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="exchangeRate"
          readOnly={!item.isNew}
          type="number"
        />
      ),
    },
    {
      name: 'statusId',
      label: 'common:label.statusId',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.statusId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="statusId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'status',
      label: 'common:label.status',
      style: { minWidth: '160px' },
      render: (colName, item, idx) => {
        let options = t('selections:statusSaveAccountNonBillables')();
        if (item?.status === 'ACTIVE' && !item.isNew) {
          options = options.filter(val => val.value !== 'SUSPENDED');
        }
        return (
          <GenericInput
            value={item.newStatus || item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
            name="status"
            options={options}
            type="select"
            menuPortalTarget
            isNoClearable
            disabled={item.status === 'CANCELLED'}
          />
        );
      },
    },
    {
      name: 'transactionType',
      label: 'common:label.transactionType',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.transactionType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="transactionType"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'externalId',
      label: 'common:label.externalId',
      style: { minWidth: '150px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.externalId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="externalId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'comments',
      label: 'common:label.comments',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.comments}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeEquipments({ name, value, index: idx })}
          name="comments"
          readOnly={!item.isNew}
        />
      ),
    },
  ];

  const infrastructureEquipmentsColumns = [
    {
      name: 'saveConfig',
      label: 'label.apply',
      render: (colName, item) => (
        <button
          type="button"
          className="button x-small apply-btn"
          onClick={() => onApplyInfrastructureEquipments({ item, type: 'INFRASTRUCTURE' })}
          disabled={item.status === 'CANCELLED'}
        >
          {t('common:label.apply')}
        </button>
      ),
    },
    {
      name: 'omsInternalId',
      label: 'common:label.instanceId',
      required: true,
      style: { minWidth: '180px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.omsInternalId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="omsInternalId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'assetTag',
      label: 'common:label.assetTag',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.assetTag}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="assetTag"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'description',
      label: 'common:label.description',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="description"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'deliveryDate',
      label: 'common:label.deliveryDate',
      style: { minWidth: '180px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.deliveryDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) =>
            !item.isNew ? {} : onChangeInfrastructureEquipments({ name, value, index: idx })
          }
          name="deliveryDate"
          readOnly={!item.isNew}
          disabled={!item.isNew}
          type="date"
        />
      ),
    },
    {
      name: 'quantity',
      label: 'common:label.quantity',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={supportShowNumberValue(item.quantity)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="quantity"
          readOnly={!item.isNew}
          type="number"
        />
      ),
    },
    {
      name: 'cost',
      label: 'common:label.cost',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={supportShowNumberValue(item.cost)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="cost"
          readOnly={!item.isNew}
          type="number"
        />
      ),
    },

    {
      name: 'primaryCode',
      label: 'common:label.primaryCode',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.primaryCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="primaryCode"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'inventoryItemId',
      label: 'common:label.inventoryItemId',
      style: { minWidth: '190px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.inventoryItemId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="inventoryItemId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'exchangeRate',
      label: 'common:label.exchangeRate',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={supportShowNumberValue(item.exchangeRate)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="exchangeRate"
          readOnly={!item.isNew}
          type="number"
        />
      ),
    },
    {
      name: 'statusId',
      label: 'common:label.statusId',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.statusId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="statusId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'status',
      label: 'common:label.status',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => {
        let options = t('selections:statusSaveAccountNonBillables')();
        if (item?.status === 'ACTIVE' && !item.isNew) {
          options = options.filter(val => val.value !== 'SUSPENDED');
        }
        return (
          <GenericInput
            value={item.newStatus || item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
            name="status"
            options={options}
            type="select"
            menuPortalTarget
            isNoClearable
            disabled={item.status === 'CANCELLED'}
          />
        );
      },
    },
    {
      name: 'transactionType',
      label: 'common:label.transactionType',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.transactionType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="transactionType"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'externalId',
      label: 'common:label.externalId',
      style: { minWidth: '150px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.externalId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="externalId"
          readOnly={!item.isNew}
        />
      ),
    },
    {
      name: 'comments',
      label: 'common:label.comments',
      style: { minWidth: '150px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.comments}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeInfrastructureEquipments({ name, value, index: idx })}
          name="comments"
          readOnly={!item.isNew}
        />
      ),
    },
  ];

  const billableFields = [
    {
      name: 'currency',
      label: 'common:label.currency',
      type: 'select',
      options: currencyOptions,
    },
    {
      name: 'alternateDescription',
      label: 'common:label.alternateDescription',
      type: 'textarea',
      style: { height: '28px' },
      isModify: true,
    },
    {
      name: 'percentile',
      label: 'common:label.percentileValue',
      type: 'number',
      isModify: true,
      // is2Decimal: true,
    },
    {
      name: 'invoiceExhibits',
      label: 'common:label.invoiceExhibits',
      type: 'number',
      isModify: false,
    },
    {
      name: 'skipStartMonth',
      label: 'common:label.invoiceExhibitsSkipStartMonth',
      type: 'select',
      options: t('selections:monthOfYear')().map(val => ({
        value: val.value.toUpperCase(),
        label: val.label.toUpperCase(),
      })),
      menuPortalTarget: true,
    },
    {
      name: 'skipNoOfMonths',
      label: 'common:label.numberofMonthstoSkipInvoiceExhibits',
      type: 'number',
    },
    {
      name: 'exhibitsInvoiced',
      label: 'common:label.exhibitsInvoiced',
      type: 'number',
      isModify: false,
    },
    {
      name: 'recurringType',
      label: 'common:label.recurringType',
      type: 'select',
      tOptions: 'selections:recurringType',
      menuPortalTarget: true,
      isModify: true,
    },
    {
      name: 'recurringUnit',
      label: 'common:label.recurringUnit',
      type: 'select',
      tOptions: 'selections:recurringUnit',
      menuPortalTarget: true,
      isModify: true,
    },
    {
      name: 'recurringFrequency',
      label: 'common:label.recurringFrequency',
      isModify: true,
      type: 'number',
    },
    {
      name: 'advanceFlag',
      label: 'common:label.advanceFlag',
      isModify: true,
      tOptions: 'selections:selectBool',
      menuPortalTarget: true,
      type: 'select',
      isBool: true,
    },
    {
      name: 'cutOffDate',
      label: 'common:label.cutOffDom',
      type: 'select',
      options: cutOffDOMOptions,
      menuPortalTarget: true,
      isModify: true,
      isConvertString: true,
    },
    {
      name: 'partMonthWaiveOffFlag',
      label: 'common:label.partMonthWaive',
      type: 'select',
      tOptions: 'selections:selectBool',
      menuPortalTarget: true,
      isBool: true,
      isModify: true,
    },
    {
      name: 'calendarMonthFlag',
      label: 'common:label.calendarMonth',
      type: 'select',
      tOptions: 'selections:selectBool',
      menuPortalTarget: true,
      isBool: true,
      isModify: true,
    },
    {
      name: 'alignedToCycle',
      label: 'common:label.align2Cycle',
      type: 'select',
      tOptions: 'selections:selectBool',
      menuPortalTarget: true,
      isBool: true,
      isModify: true,
    },
    {
      name: 'isGrant',
      label: 'common:label.grants',
      type: 'select',
      tOptions: 'selections:selectBool',
      menuPortalTarget: true,
      isBool: true,
      isModify: true,
    },
    {
      name: 'newFreqEffectiveDate',
      label: 'common:label.newFrequencyEffectiveDate',
      type: 'monthYear',
      dateFormat: 'MMM-YYYY',
      isModify: true,
      // wrapperClass: 'col-md-4 inner-popover',
    },
    {
      name: 'freqChangeAutoApplyDate',
      label: 'common:label.frequencyChangeAutoApplyDate',
      type: 'date',
      isModify: false,
      // wrapperClass: 'col-md-4 inner-popover',
    },
  ];

  const onToggleSubTab = (index, item, indexItem, key) => {
    if (activeSubTab.index === indexItem) {
      setActiveSubTab({});
      setDataSubmitChange({});
      if (item.isCopyAllChangeRecurring) {
        setIndexCopyAllChange(index);
      } else {
        setIndexCopyAllChange(-1);
      }
    }
    if (activeSubTab.index !== indexItem) {
      setActiveSubTab({ key, index: indexItem });
      if (key === 'billableService' && item) {
        setAlternateDescription(item.alternateDescription);
        if (indexCopyAllChange !== -1) {
          // const newData = cloneDeep(orderDetail);
          // newData.accountServicesSummaries[indexItem].currency =
          //   newData.accountServicesSummaries[indexCopyAllChange].currency;
          // newData.accountServicesSummaries[indexItem].alternateDescription =
          //   newData.accountServicesSummaries[indexCopyAllChange].alternateDescription;
          // newData.accountServicesSummaries[indexItem].percentile =
          //   newData.accountServicesSummaries[indexCopyAllChange].percentile;
          // newData.accountServicesSummaries[indexItem].cutOffDate =
          //   newData.accountServicesSummaries[indexCopyAllChange].cutOffDate;
          // newData.accountServicesSummaries[indexItem].recurringType =
          //   newData.accountServicesSummaries[indexCopyAllChange].recurringType;
          // newData.accountServicesSummaries[indexItem].recurringUnit =
          //   newData.accountServicesSummaries[indexCopyAllChange].recurringUnit;
          // newData.accountServicesSummaries[indexItem].advanceFlag =
          //   newData.accountServicesSummaries[indexCopyAllChange].advanceFlag;
          // newData.accountServicesSummaries[indexItem].partMonthWaiveOffFlag =
          //   newData.accountServicesSummaries[indexCopyAllChange].partMonthWaiveOffFlag;
          // newData.accountServicesSummaries[indexItem].calendarMonthFlag =
          //   newData.accountServicesSummaries[indexCopyAllChange].calendarMonthFlag;
          // newData.accountServicesSummaries[indexItem].alignedToCycle =
          //   newData.accountServicesSummaries[indexCopyAllChange].alignedToCycle;
          // newData.accountServicesSummaries[indexItem].isGrant =
          //   newData.accountServicesSummaries[indexCopyAllChange].isGrant;
          // newData.accountServicesSummaries[indexItem].skipStartMonth =
          //   newData.accountServicesSummaries[indexCopyAllChange].skipStartMonth;
          // newData.accountServicesSummaries[indexItem].skipNoOfMonths =
          //   newData.accountServicesSummaries[indexCopyAllChange].skipNoOfMonths;
          // newData.accountServicesSummaries[indexItem].newFreqEffectiveDate =
          //   newData.accountServicesSummaries[indexCopyAllChange].newFreqEffectiveDate;

          // // handle data submit
          // dataSubmitChange.currency = newData.accountServicesSummaries[indexCopyAllChange].currency;
          // dataSubmitChange.alternateDescription =
          //   newData.accountServicesSummaries[indexCopyAllChange].alternateDescription;
          // dataSubmitChange.percentile = newData.accountServicesSummaries[indexCopyAllChange].percentile;
          // dataSubmitChange.cutOffDate = newData.accountServicesSummaries[indexCopyAllChange].cutOffDate;
          // dataSubmitChange.recurringType = newData.accountServicesSummaries[indexCopyAllChange].recurringType;
          // dataSubmitChange.recurringUnit = newData.accountServicesSummaries[indexCopyAllChange].recurringUnit;
          // dataSubmitChange.advanceFlag = newData.accountServicesSummaries[indexCopyAllChange].advanceFlag;
          // dataSubmitChange.partMonthWaiveOffFlag =
          //   newData.accountServicesSummaries[indexCopyAllChange].partMonthWaiveOffFlag;
          // dataSubmitChange.calendarMonthFlag = newData.accountServicesSummaries[indexCopyAllChange].calendarMonthFlag;
          // dataSubmitChange.alignedToCycle = newData.accountServicesSummaries[indexCopyAllChange].alignedToCycle;
          // dataSubmitChange.isGrant = newData.accountServicesSummaries[indexCopyAllChange].isGrant;
          // dataSubmitChange.skipStartMonth = newData.accountServicesSummaries[indexCopyAllChange].skipStartMonth;
          // dataSubmitChange.skipNoOfMonths = newData.accountServicesSummaries[indexCopyAllChange].skipNoOfMonths;
          // dataSubmitChange.newFreqEffectiveDate =
          //   newData.accountServicesSummaries[indexCopyAllChange].newFreqEffectiveDate;

          setDataSubmitChange(dataSubmitChange);
          // setOrderDetail(newData);
          setIndexCopyAllChange(-1);
        }
      }
    }
  };

  const doGetBillableServicePricing = (serviceId, item) => {
    getBillableServicePricing({ serviceId, accountId: id, omsInternalId: item.omsInternalId }, ({ success, data }) => {
      if (success && data && data.length) {
        setIsOpenPricingModal(true);
        setPricingData(data);
      }
    });
  };

  const onTogglePricing = (index, item, indexItem, key) => {
    doGetBillableServicePricing(item.serviceId, item);
  };

  const onToggleHistory = (index, item, indexItem, key) => {
    getOrderHistory({ accountId: id, omsInternalId: item.omsInternalId }, ({ success, data }) => {
      if (success && data && data.length) {
        setIsOpenHistoryModal(true);
        setHistoryData(data);
        setHistoryItemSelect(item);
      }
    });
  };

  const onChangeLocation = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(orderDetail);
      newData.accountServicesSummaries[activeSubTab.index].locations[index][name] = value;
      dataSubmitChange.locations = newData.accountServicesSummaries[activeSubTab.index].locations;
      setOrderDetail(newData);
      setDataSubmitChange(dataSubmitChange);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSubscriptionAssest = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(orderDetail);
      if (
        (name === 'percentile' || name === 'recurringFrequency' || name === 'invoiceExhibits') &&
        value &&
        (Number.parseFloat(value) > 100 || Number.parseFloat(value) < 0)
      ) {
        return '';
      }
      // if (name === 'cutOffDate' && value && (Number.parseFloat(value) > 31 || Number.parseFloat(value) < 1)) {
      //   return '';
      // }
      newData.accountServicesSummaries[activeSubTab.index][name] =
        name === 'newFreqEffectiveDate' && value ? value.toUpperCase() : value;
      dataSubmitChange[name] = name === 'newFreqEffectiveDate' && value ? value.toUpperCase() : value;
      newData.accountServicesSummaries[activeSubTab.index].isChange = true;
      setDataSubmitChange(dataSubmitChange);
      return setOrderDetail(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  const onChangeIsCopyAllChangeRecurring = () => {
    try {
      const newData = cloneDeep(orderDetail);
      newData.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring = !newData.accountServicesSummaries[
        activeSubTab.index
      ].isCopyAllChangeRecurring;
      if (newData.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring) {
        setIndexCopyAllChange(activeSubTab.index);
      } else {
        setIndexCopyAllChange(-1);
      }
      setIsOpenModalCopyChangeToAllRecurring(false);
      return setOrderDetail(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  const onSaveLocation = () => {
    const newData = cloneDeep(orderDetail);
    try {
      let locations = dataSubmitChange.locations;
      let isMoreOneLength = false;
      let totalPercent = 0;
      if (dataSubmitChange?.locations?.length && dataSubmitChange?.locations?.length > 1) {
        isMoreOneLength = true;
        dataSubmitChange.locations.forEach(val => {
          const { percent, ...rest } = val;
          totalPercent += Number.parseFloat(percent);
        });
      }
      if (isMoreOneLength && totalPercent !== 100) {
        return toast.error(t('common:message.messageLocation'));
      }
      if (dataSubmitChange?.locations?.length && dataSubmitChange?.locations?.length === 1) {
        locations = dataSubmitChange.locations.map(val => {
          const { percent, pointId, ...rest } = val;
          return { ...rest, pointId: pointId || ' ', percent: '100' };
        });
      }
      let payloadMuilLocations = null;
      const currentPayload = {
        id,
        omsInternalId: newData.accountServicesSummaries[activeSubTab.index].omsInternalId,
        locationPercentage: locations || null,
        alternateDescription: dataSubmitChange?.alternateDescription || null,
        percentile: dataSubmitChange?.percentile || null,
        cutOffDate: dataSubmitChange?.cutOffDate || null,
        advanceFlag:
          dataSubmitChange?.advanceFlag === 'TRUE'
            ? 'true'
            : dataSubmitChange?.advanceFlag === false || dataSubmitChange?.advanceFlag === 'FALSE'
            ? 'false'
            : null,
        skipStartMonth: dataSubmitChange?.skipStartMonth || null,
        skipNoOfMonths: dataSubmitChange?.skipNoOfMonths || null,
        recurringUnit: dataSubmitChange?.recurringUnit || null,
        recurringFrequency: dataSubmitChange?.recurringFrequency || null,
        newFreqEffectiveDate: dataSubmitChange?.newFreqEffectiveDate || null,
      };
      if (newData.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring) {
        let newBillable = cloneDeep(newData.accountServicesSummaries);
        // const currentBillables = newData.accountServicesSummaries[activeSubTab.index];
        newBillable = newBillable.filter(
          val => val.omsInternalId !== newData.accountServicesSummaries[activeSubTab.index].omsInternalId
        );
        newBillable = newBillable.filter(
          val =>
            val.cutOffDate !== -2 || (val.cutOffDate === -2 && val.loadType.toUpperCase() !== 'Renta'.toUpperCase())
        );
        payloadMuilLocations = {
          dataSubmit: [
            currentPayload,
            ...[...newBillable].map(val => {
              let { locations } = val;
              locations = locations
                ? locations.map(val => {
                    const { index, percent, pointName, omsInternalId, pointId } = val;
                    return { index, percent, pointName, omsInternalId, pointId };
                  })
                : [];
              return {
                id,
                omsInternalId: val.omsInternalId,
                // locationPercentage: locations || null,
                alternateDescription: dataSubmitChange?.alternateDescription || null,
                percentile: dataSubmitChange?.percentile || null,
                cutOffDate: dataSubmitChange?.cutOffDate || null,
                advanceFlag:
                  dataSubmitChange?.advanceFlag === 'TRUE'
                    ? 'true'
                    : dataSubmitChange?.advanceFlag === false || dataSubmitChange?.advanceFlag === 'FALSE'
                    ? 'false'
                    : null,
                skipStartMonth: dataSubmitChange?.skipStartMonth || null,
                skipNoOfMonths: dataSubmitChange?.skipNoOfMonths || null,
                recurringUnit: dataSubmitChange?.recurringUnit || null,
                recurringFrequency: dataSubmitChange?.recurringFrequency || null,
                newFreqEffectiveDate: dataSubmitChange?.newFreqEffectiveDate || null,
                // locationPercentage: locations
                //   ? locations.map(val => {
                //       const { percent, pointId, ...rest } = val;
                //       return {
                //         ...rest,
                //         pointId: pointId || ' ',
                //         percent: locations && locations.length === 1 ? '100' : percent,
                //       };
                //     })
                //   : [],
              };
            }),
          ],
        };
      }
      modifyAccountService(payloadMuilLocations || currentPayload, () => {
        doGetOrderSummaryByAccountId();
        setDataSubmitChange({});
      });
    } catch (error) {}
  };

  const billableServiceTable = [
    {
      name: 'locations',
      label: 'label.locations',
      render: (colName, item, idx, indexParent, activeTab) => (
        <button
          type="button"
          className="btn-expand-table mr-3"
          onClick={evt => onToggleSubTab(idx, item, idx, 'billableService')}
          disabled={!item.locations || !item.locations.length}
        >
          <i
            className={`fa ${
              activeSubTab && activeSubTab.key === 'billableService' && activeSubTab.index === idx
                ? 'fa-minus'
                : 'fa-plus'
            }`}
          />
        </button>
      ),
    },
    {
      name: 'pricing',
      label: 'label.viewPricing',
      render: (colName, item, idx, indexParent, activeTab) => {
        return (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => onTogglePricing(idx, item, idx, 'pricing')}
          >
            {t('common:label.view')}
          </button>
        );
      },
    },
    {
      name: 'provisioningAttributes',
      label: 'label.viewProvisioningAttributes',
      render: (colName, item, idx, indexParent, activeTab) => {
        return (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => {
              setIsOpenPricingModalProvisioningAttributes(true);
              setItemSelectProvisioningAttributes(item);
            }}
          >
            {t('common:label.view')}
          </button>
        );
      },
    },
    {
      name: 'pricing',
      label: 'label.viewHistory',
      render: (colName, item, idx, indexParent, activeTab) => {
        return (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => onToggleHistory(idx, item, idx, 'pricing')}
          >
            {t('common:label.view')}
          </button>
        );
      },
    },
    {
      name: 'serviceName',
      label: 'common:label.name',
    },
    {
      name: 'serviceType',
      label: 'common:label.serviceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'releaseDate',
      label: 'common:label.releaseDate',
    },
    {
      name: 'quantity',
      label: 'common:label.quantity',
    },
    {
      name: 'servicePrice',
      label: 'common:label.price',
    },
    {
      name: 'capacity',
      label: 'common:label.capacity',
      render: (colName, item) => {
        return <span>{item.capacity !== null ? formatNumberValue(item.capacity) : ''}</span>;
      },
    },
    {
      name: 'clientCircuitId',
      label: 'common:label.clientCircuitId',
    },
    {
      name: 'administrativeState',
      label: 'common:label.administrativeState',
    },
    {
      name: 'operationalState',
      label: 'common:label.operationalState',
    },
    {
      name: 'status',
      label: 'common:label.status',
    },
    {
      name: 'dischargeDate',
      label: 'common:label.dischargeDate',
    },
    {
      name: 'canceledDate',
      label: 'common:label.cancelledDate',
    },
    {
      name: 'serviceId',
      label: 'common:label.serviceId',
    },
    {
      name: 'uom',
      label: 'common:label.unitOfMeasure',
    },
    {
      name: 'parentServiceId',
      label: 'common:label.parentServiceId',
    },
    {
      name: 'parentServiceName',
      label: 'common:label.parentServiceName',
    },
    {
      name: 'omsInternalId',
      label: 'common:label.instanceId',
    },
    {
      name: 'itemId',
      label: 'common:label.itemId',
    },
    {
      name: 'loadType',
      label: 'common:label.loadType',
    },
    {
      name: 'productDescription',
      label: 'common:label.productDescription',
    },
    {
      name: 'serviceDiscount',
      label: 'common:label.serviceDiscount',
    },
    {
      name: 'serviceMovement',
      label: 'common:label.movement',
    },
    {
      name: 'externalServiceId',
      label: 'common:label.externalServiceId',
    },
    {
      name: 'commodityPrice',
      label: 'common:label.commodityPrice',
    },
    {
      name: 'cost',
      label: 'common:label.cost',
    },
    {
      name: 'externalServiceIdOp',
      label: 'common:label.externalServiceIdOp',
    },
    {
      name: 'includedLicenses',
      label: 'common:label.includedLicenses',
    },
    {
      name: 'initialTermUnit',
      label: 'common:label.initialTermUnit',
    },
    {
      name: 'initialTerm',
      label: 'common:label.initialTerm',
    },
    {
      name: 'priceWithTax',
      label: 'common:label.priceWithTax',
    },
    {
      name: 'taxCode',
      label: 'common:label.taxCode',
    },
    {
      name: 'reason',
      label: 'common:label.reason',
    },
    {
      name: 'totalPrice',
      label: 'common:label.totalPrice',
    },
    {
      name: 'medium',
      label: 'common:label.medium',
    },
    {
      name: 'stateService',
      label: 'common:label.stateService',
    },
    {
      name: 'circuitId',
      label: 'common:label.circuitId',
    },
    {
      name: 'alternateDescription',
      label: 'common:label.alternateDescription',
    },
    {
      name: 'validity',
      label: 'common:label.validity',
    },
    {
      name: 'trialTerm',
      label: 'common:label.trialTerm',
    },
    {
      name: 'trialTermUnit',
      label: 'common:label.trialTermUnit',
    },
    {
      name: 'commitmentDollarValue',
      label: 'common:label.commitmentDollarValue',
    },
    {
      name: 'commitmentTerm',
      label: 'common:label.commitmentTerm',
    },
    {
      name: 'internationalDIDCountry',
      label: 'common:label.internationalDIDCountry',
    },
  ];

  const locationsTableColumns = [
    {
      name: 'pointId',
      label: 'common:label.locationId',
      style: { width: '170px', maxWidth: '170px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.pointId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeLocation({ name, value, index: idx })}
          name="pointId"
          required
          readOnly
        />
      ),
    },
    {
      name: 'pointName',
      label: 'common:label.locationName',
      style: { width: '170px', maxWidth: '170px' },
    },
    {
      name: 'percent',
      label: 'common:label.percent',
      style: { width: '200px', maxWidth: '200px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={
            orderDetail &&
            orderDetail.accountServicesSummaries &&
            orderDetail.accountServicesSummaries[activeSubTab.index] &&
            orderDetail.accountServicesSummaries[activeSubTab.index].locations &&
            orderDetail.accountServicesSummaries[activeSubTab.index].locations.length === 1
              ? '100'
              : item.percent
          }
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeLocation({ name, value, index: idx })}
          name="percent"
          type="number"
          required
          disabled={
            orderDetail &&
            orderDetail.accountServicesSummaries &&
            orderDetail.accountServicesSummaries[activeSubTab.index] &&
            orderDetail.accountServicesSummaries[activeSubTab.index].locations &&
            orderDetail.accountServicesSummaries[activeSubTab.index].locations.length === 1
          }
        />
      ),
    },
    // hard viewer only
    {},
  ];

  const onToggleTab = activeTabName => {
    // const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
      // this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  const renderItem = item => {
    let newBillableFields = cloneDeep(billableFields);

    if (
      orderDetail.accountServicesSummaries &&
      orderDetail.accountServicesSummaries[activeSubTab.index] &&
      orderDetail.accountServicesSummaries[activeSubTab.index].loadType &&
      orderDetail.accountServicesSummaries[activeSubTab.index].loadType.toUpperCase() === 'Renta'.toUpperCase()
    ) {
      newBillableFields = newBillableFields.map(val => {
        const { isModify, ...rest } = val;
        if (
          val.name === 'alternateDescription' ||
          val.name === 'advanceFlag' ||
          val.name === 'cutOffDate' ||
          val.name === 'percentile' ||
          val.name === 'recurringUnit' ||
          val.name === 'recurringFrequency' ||
          val.name === 'newFreqEffectiveDate'
        ) {
          return { ...rest, isModify };
        }
        if (
          (val.name === 'skipStartMonth' || val.name === 'skipNoOfMonths') &&
          orderDetail.accountServicesSummaries &&
          orderDetail.accountServicesSummaries[activeSubTab.index] &&
          orderDetail.accountServicesSummaries[activeSubTab.index].exhibitsInvoiced
        ) {
          return { ...rest, isModify: true };
        }
        return { ...rest };
      });
    } else {
      newBillableFields = newBillableFields.map(val => {
        const { isModify, ...rest } = val;
        if (
          val.name === 'currency' ||
          val.name === 'recurringType' ||
          // val.name === 'percentile' ||
          val.name === 'invoiceExhibits' ||
          val.name === 'exhibitsInvoiced' ||
          val.name === 'partMonthWaiveOffFlag' ||
          val.name === 'calendarMonthFlag' ||
          val.name === 'alignedToCycle' ||
          val.name === 'isGrant' ||
          val.name === 'freqChangeAutoApplyDate'
        ) {
          return { ...rest, isModify: false };
        }
        if (
          (val.name === 'recurringFrequency' ||
            val.name === 'recurringUnit' ||
            val.name === 'skipStartMonth' ||
            val.name === 'skipNoOfMonths') &&
          orderDetail.accountServicesSummaries &&
          orderDetail.accountServicesSummaries[activeSubTab.index] &&
          Number.parseFloat(orderDetail.accountServicesSummaries[activeSubTab.index].invoiceExhibits || 0) < 1
        ) {
          return { ...rest, isModify: false };
        }
        if (
          (val.name === 'skipStartMonth' || val.name === 'skipNoOfMonths') &&
          orderDetail.accountServicesSummaries &&
          orderDetail.accountServicesSummaries[activeSubTab.index] &&
          orderDetail.accountServicesSummaries[activeSubTab.index].exhibitsInvoiced
        ) {
          return { ...rest, isModify: true };
        }
        return { ...rest, isModify: true };
      });
    }

    switch (item.state) {
      case 'Subscription Data':
        return (
          <div className="group-collapsible">
            <DataTable columns={columns} data={data} onSort={onSortColumn} sorted={sorted} />
            <div className="mt-3">
              <TablePagination
                pageNumber={page}
                pageSize={size}
                totalCount={totalCount}
                onPageChange={onPageChange}
                onSizeChange={onSizeChange}
              />
            </div>
          </div>
        );
      case 'Billable Service':
        return (
          <div className="group-collapsible">
            <CollapsibleTable
              columns={billableServiceTable}
              indexViewer={
                activeSubTab && activeSubTab.key === 'billableService' && activeSubTab.index !== -1
                  ? activeSubTab.index
                  : -1
              }
              data={orderDetail.accountServicesSummaries || []}
              isSupportRemoveIndex
              isViewOnly
              onClickRow={(idx, item, id) => {}}
              isFixedHeaderTable
            >
              <DataTable
                columns={locationsTableColumns}
                data={
                  orderDetail &&
                  orderDetail.accountServicesSummaries &&
                  activeSubTab &&
                  activeSubTab.key === 'billableService' &&
                  activeSubTab.index !== -1
                    ? orderDetail.accountServicesSummaries[activeSubTab.index].locations
                    : []
                }
              />
              <div className="grp-sumit-rvn-viewer pl-3 row">
                {newBillableFields.map(val => {
                  if (val.isBool) {
                    if (orderDetail.accountServicesSummaries && activeSubTab.index !== -1) {
                      let value = null;
                      let billableSelect = orderDetail.accountServicesSummaries[activeSubTab.index];
                      if (
                        orderDetail &&
                        orderDetail.accountServicesSummaries &&
                        orderDetail.accountServicesSummaries[activeSubTab.index] &&
                        orderDetail.accountServicesSummaries[activeSubTab.index]
                      ) {
                        billableSelect = orderDetail.accountServicesSummaries[activeSubTab.index];
                      }
                      if (
                        billableSelect &&
                        (billableSelect[val.name] === true || billableSelect[val.name] === 'TRUE')
                      ) {
                        value = 'TRUE';
                      }
                      if (
                        billableSelect &&
                        (billableSelect[val.name] === false || billableSelect[val.name] === 'FALSE')
                      ) {
                        value = 'FALSE';
                      }
                      return (
                        <GenericInput
                          value={value}
                          wrapperClass="col-md-4"
                          {...val}
                          readOnly={!val.isModify || customerInfo?.type === 'CLIENT'}
                          onChange={onChangeSubscriptionAssest}
                          disabled={
                            customerInfo?.type === 'CLIENT' ||
                            !val.isModify ||
                            (orderDetail.accountServicesSummaries &&
                              orderDetail.accountServicesSummaries[activeSubTab.index] &&
                              orderDetail.accountServicesSummaries[activeSubTab.index].loadType &&
                              orderDetail.accountServicesSummaries[activeSubTab.index].loadType.toUpperCase() !==
                                'Renta'.toUpperCase()) ||
                            (orderDetail.accountServicesSummaries &&
                              orderDetail.accountServicesSummaries[activeSubTab.index] &&
                              orderDetail.accountServicesSummaries[activeSubTab.index].status === 'CANCELLED')
                          }
                        />
                      );
                    }
                  }
                  if (val.isConvertString) {
                    return (
                      <GenericInput
                        value={
                          orderDetail &&
                          orderDetail.accountServicesSummaries &&
                          orderDetail.accountServicesSummaries[activeSubTab.index] &&
                          orderDetail.accountServicesSummaries[activeSubTab.index][val.name]
                            ? `${orderDetail.accountServicesSummaries[activeSubTab.index][val.name]}`
                            : ''
                        }
                        wrapperClass="col-md-4"
                        {...val}
                        readOnly={!val.isModify || customerInfo?.type === 'CLIENT'}
                        disabled={(!val.isModify && val.type === 'select') || customerInfo?.type === 'CLIENT'}
                        onChange={onChangeSubscriptionAssest}
                      />
                    );
                  }
                  if (val.name === 'freqChangeAutoApplyDate') {
                    const value =
                      orderDetail &&
                      orderDetail.accountServicesSummaries &&
                      orderDetail.accountServicesSummaries[activeSubTab.index] &&
                      (orderDetail.accountServicesSummaries[activeSubTab.index][val.name] ||
                        orderDetail.accountServicesSummaries[activeSubTab.index][val.name] === 0)
                        ? orderDetail.accountServicesSummaries[activeSubTab.index][val.name]
                        : '';
                    if (value && moment(currentCcpDateTime).isAfter(value) && currentCcpDateTime) {
                      return (
                        <GenericInput
                          value="XXXX-XX-XX"
                          wrapperClass={val.wrapperClass ? val.wrapperClass : 'col-md-4'}
                          {...val}
                          type="text"
                          readOnly
                          disabled
                          onChange={() => {}}
                        />
                      );
                    }
                    return (
                      <GenericInput
                        value={value}
                        wrapperClass={val.wrapperClass ? val.wrapperClass : 'col-md-4'}
                        {...val}
                        readOnly
                        disabled
                        onChange={() => {}}
                      />
                    );
                  }
                  return (
                    <GenericInput
                      value={
                        orderDetail &&
                        orderDetail.accountServicesSummaries &&
                        orderDetail.accountServicesSummaries[activeSubTab.index] &&
                        (orderDetail.accountServicesSummaries[activeSubTab.index][val.name] ||
                          orderDetail.accountServicesSummaries[activeSubTab.index][val.name] === 0)
                          ? orderDetail.accountServicesSummaries[activeSubTab.index][val.name]
                          : ''
                      }
                      wrapperClass={val.wrapperClass ? val.wrapperClass : 'col-md-4'}
                      {...val}
                      readOnly={!val.isModify || customerInfo?.type === 'CLIENT'}
                      disabled={
                        (!val.isModify && (val.type === 'select' || val.type === 'date')) ||
                        customerInfo?.type === 'CLIENT'
                      }
                      onChange={onChangeSubscriptionAssest}
                    />
                  );
                })}
              </div>
              <div className="pl-3 d-flex grp-sumit-rvn">
                <div className="col-md-12 d-flex">
                  <Switch
                    onChange={
                      orderDetail.accountServicesSummaries &&
                      orderDetail.accountServicesSummaries[activeSubTab.index] &&
                      !!orderDetail.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring
                        ? onChangeIsCopyAllChangeRecurring
                        : onToggleModalCopyChangeToAllRecurring
                    }
                    name="isCopyAllChangeRecurring"
                    wrapperClass={`pl-2 pt-4 pr-3 ${
                      orderDetail.accountServicesSummaries &&
                      orderDetail.accountServicesSummaries[activeSubTab.index] &&
                      !!orderDetail.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring
                        ? 'switch-active'
                        : 'switch-non-active'
                    }`}
                    title={t('common:label.copyChangeToAll')}
                    checked={
                      orderDetail.accountServicesSummaries &&
                      orderDetail.accountServicesSummaries[activeSubTab.index] &&
                      !!orderDetail.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring
                    }
                    value={
                      orderDetail.accountServicesSummaries &&
                      orderDetail.accountServicesSummaries[activeSubTab.index] &&
                      !!orderDetail.accountServicesSummaries[activeSubTab.index].isCopyAllChangeRecurring
                    }
                    disabled={
                      orderDetail.accountServicesSummaries &&
                      orderDetail.accountServicesSummaries[activeSubTab.index] &&
                      orderDetail.accountServicesSummaries[activeSubTab.index].status === 'CANCELLED'
                    }
                  />
                  <button
                    type="button"
                    onClick={onSaveLocation}
                    className="button button-border ml-2 mb-4 x-small"
                    disabled={
                      (orderDetail.accountServicesSummaries &&
                        orderDetail.accountServicesSummaries[activeSubTab.index] &&
                        orderDetail.accountServicesSummaries[activeSubTab.index].status === 'CANCELLED') ||
                      customerInfo?.type === 'CLIENT'
                    }
                  >
                    {t('common:label.save')}
                  </button>
                </div>
              </div>
            </CollapsibleTable>
          </div>
        );
      case 'Equipments':
        return (
          <div>
            <div className="mt-3 mb-3 ml-3">
              <button
                type="button"
                className="button button-border black x-small"
                onClick={() => {
                  onAddNewItem({ type: 'EQUIPMENT' });
                }}
              >
                +
                {t('common:label.addReplacement')}
              </button>
            </div>
            <DataTable
              isFixedHeaderTable
              columns={equipmentsTable}
              data={orderDetail ? orderDetail.accountNonBillablesSummaries : []}
            />
          </div>
        );
      case 'Locations':
        return (
          <div>
            <DataTable
              isFixedHeaderTable
              columns={locationsTable}
              data={orderDetail ? orderDetail.accountLocationsSummaries : []}
            />
          </div>
        );

      case 'Infrastructure Equipments':
        return (
          <div>
            <div className="mt-3 mb-3 ml-3">
              <button
                type="button"
                className="button button-border black x-small"
                onClick={() => {
                  onAddNewItem({ type: 'INFRASTRUCTURE' });
                }}
              >
                +
                {t('common:label.addReplacement')}
              </button>
            </div>
            <DataTable
              columns={infrastructureEquipmentsColumns}
              data={orderDetail ? orderDetail.accountInfraNonBillablesSummaries : []}
              isFixedHeaderTable
            />
          </div>
        );
      default:
        return null;
    }
  };

  let newCollapsibleMenu = cloneDeep(collapsibleMenu);

  if (!orderDetail || !orderDetail.accountServicesSummaries || isBillableServiceEnabled) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Billable Service');
  }
  if (!orderDetail || !orderDetail.accountNonBillablesSummaries) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Equipments');
  }
  if (!orderDetail || !orderDetail.accountLocationsSummaries) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Locations');
  }
  if (!orderDetail || !orderDetail.accountInfraNonBillablesSummaries) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Infrastructure Equipments');
  }
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  useEffect(() => {
    doSearchAccountAssets();
  }, [doSearchAccountAssets, page, size]);

  useEffect(() => {
    doGetOrderSummaryByAccountId();
    // getAccountAssets(id);
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          setCurrentCcpDateTime(data ? data.ccpTime : null);
        }
      });
    } else {
      setCurrentCcpDateTime(ccpTime || null);
    }
  }, [ccpTime, data, doGetOrderSummaryByAccountId, getCcpDateTimeConfig]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  useEffect(() => {
    if (!ccpPropertyList || !ccpPropertyList.length) getCcpPropertiesConfig();
  }, [ccpPropertyList, getCcpPropertiesConfig]);

  useEffect(() => {
    if (newCollapsibleMenu && newCollapsibleMenu[0])
      setActiveTab({ name: newCollapsibleMenu[0].state, isActive: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('customerPage:sidebarHandleAccountPage.offers')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
      >
        <div className="col-md-12 pt-3 pb-3">
          {(orderDetail.accountServicesSummaries ||
            orderDetail.accountNonBillablesSummaries ||
            orderDetail.accountLocationsSummaries ||
            orderDetail.accountInfraNonBillablesSummaries ||
            data) &&
            newCollapsibleMenu.map(item => {
              return (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={activeTab && item.state === activeTab.name && activeTab.isActive}
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={onToggleTab}
                >
                  {renderItem(item)}
                </FormCollapse>
              );
            })}
        </div>
        {/* <DataTable columns={columns} data={data} /> */}
      </FormWithTableItem>
      <PricingModalOrder
        isOpen={isOpenPricingModal}
        data={pricingData}
        onToggle={() => {
          setPricingData(null);
          setIsOpenPricingModal(false);
        }}
      />
      <ModalWithItem
        isOpen={isOpenHistoryModal}
        modalTitle={t('common:label.historyDetails')}
        onToggle={() => {
          setHistoryData(null);
          setIsOpenHistoryModal(false);
        }}
      >
        <div className="col-md-12">
          <div className="col-md-12 row">
            {historyDataSelectField.map(val => (
              <GenericInput
                value={historyItemSelect[val.name]}
                wrapperClass="col-md-4"
                {...val}
                readOnly
                onChange={() => {}}
              />
            ))}
          </div>
          <br />
          <DataTable columns={historyTable} data={historyData || []} />
        </div>
        <div className="col-md-12 pt-3 pb-3">
          <button
            type="button"
            onClick={() => {
              setHistoryData(null);
              setIsOpenHistoryModal(false);
            }}
            className="button button-border black x-small float-right"
          >
            {t('common:label.cancel')}
          </button>
        </div>
      </ModalWithItem>
      <ModalAccept
        onAcceptModal={onChangeIsCopyAllChangeRecurring}
        isOpen={isOpenModalCopyChangeToAllRecurring}
        message={t('common:message.messageCopyChangeToAllRecurringExcludeUsage')}
        onToggle={onToggleModalCopyChangeToAllRecurring}
      />
      <ModalOrderProvisioningAttributes
        isOpen={isOpenPricingModalProvisioningAttributes}
        itemSelect={itemSelectProvisioningAttributes}
        orderDetail={orderDetail}
        onToggle={() => {
          setIsOpenPricingModalProvisioningAttributes(false);
          setItemSelectProvisioningAttributes(null);
        }}
      />
    </div>
  );
};

AssetsForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.any),
};

AssetsForm.defaultProps = {
  id: '',
  columns: [],
  data: [],
};

export default AssetsForm;
