import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import styleMainHeader from './styleMainHeader';
import styleMainHeaderLastColumn from './styleMainHeaderLastColumn';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({
  t,
  monthOne,
  monthTwo,
  monthThree,
  monthFour,
  monthFive,
  monthSix,
  monthSeven,
  monthEight,
  monthNine,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterOne,
  quarterTwo,
  quarterThree,
  quarterFour,
  title,
}) => [
  {
    xSteps: 5,
    columns: [],
    data: [[{ value: title || 'TIME AND EXPENSE', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.common, // employeeId
      columns.common, // employeeName
      columns.common, // jobTitle
      columns.common, // status
      columns.common, // clientId
      columns.common, // clientName
      columns.common, // clientJobTitle
      columns.common, // monthOneGrossSal
      columns.common, // monthTwoGrossSal
      columns.common, // monthThreeGrossSal
      columns.common, // totalQuarterOneGrossSal

      columns.common, // monthFourGrossSal
      columns.common, // monthFiveGrossSal
      columns.common, // monthSixGrossSal
      columns.common, // totalQuarterTwoGrossSal

      columns.common, // monthSevenGrossSal
      columns.common, // monthEightGrossSal
      columns.common, // monthNineGrossSal
      columns.common, // totalQuarterThreeGrossSal

      columns.common, // monthTenGrossSal
      columns.common, // monthElevenGrossSal
      columns.common, // monthTwelveGrossSal
      columns.common, // totalQuarterFourGrossSal

      columns.common, // monthOneActualCost
      columns.common, // monthTwoActualCost
      columns.common, // monthThreeActualacost
      columns.common, // totalQuarterOneActualCost

      columns.common, // monthFourActualCost
      columns.common, // monthFiveActualCost
      columns.common, // monthSixActualacost
      columns.common, // totalQuarterTwoActualCost

      columns.common, // monthSevenActualCost
      columns.common, // monthEightActualCost
      columns.common, // monthNineActualacost
      columns.common, // totalQuarterThreeActualCost

      columns.common, // monthTenActualCost
      columns.common, // monthElevenActualCost
      columns.common, // monthTwelveActualacost
      columns.common, // totalQuarterFourActualCost

      columns.common, // monthOneRevenueUsd
      columns.common, // monthTwoRevenueUsd
      columns.common, // monthThreeRevenueUsd
      columns.common, // totalQuarterOneRevenueUsd

      columns.common, // monthFourRevenueUsd
      columns.common, // monthFiveRevenueUsd
      columns.common, // monthSixRevenueUsd
      columns.common, // totalQuarterTwoRevenueUsd

      columns.common, // monthSevenRevenueUsd
      columns.common, // monthEightRevenueUsd
      columns.common, // monthNineRevenueUsd
      columns.common, // totalQuarterThreeRevenueUsd

      columns.common, // monthTenRevenueUsd
      columns.common, // monthElevenRevenueUsd
      columns.common, // monthTwelveRevenueUsd
      columns.common, // totalQuarterFourRevenueUsd

      columns.common, // monthOneRevenueMxn
      columns.common, // monthTwoRevenueMxn
      columns.common, // monthThreeRevenueMxn
      columns.common, // totalQuarterOneRevenueMxn

      columns.common, // monthFourRevenueMxn
      columns.common, // monthFiveRevenueMxn
      columns.common, // monthSixRevenueMxn
      columns.common, // totalQuarterTwoRevenueMxn

      columns.common, // monthSevenRevenueMxn
      columns.common, // monthEightRevenueMxn
      columns.common, // monthNineRevenueMxn
      columns.common, // totalQuarterThreeRevenueMxn

      columns.common, // monthTenRevenueMxn
      columns.common, // monthElevenRevenueMxn
      columns.common, // monthTwelveRevenueMxn
      columns.common, // totalQuarterFourRevenueMxn

      columns.common, // monthOneActualIncomeMxn
      columns.common, // monthTwoActualIncomeMxn
      columns.common, // monthThreeActualIncomeMxn
      columns.common, // totalQuarterOneActualIncome

      columns.common, // monthFourActualIncomeMxn
      columns.common, // monthFiveActualIncomeMxn
      columns.common, // monthSixActualIncomeMxn
      columns.common, // totalQuarterTwoActualIncome

      columns.common, // monthSevenActualIncomeMxn
      columns.common, // monthEightActualIncomeMxn
      columns.common, // monthNineActualIncomeMxn
      columns.common, // totalQuarterThreeActualIncome

      columns.common, // monthTenActualIncomeMxn
      columns.common, // monthElevenActualIncomeMxn
      columns.common, // monthTwelveActualIncomeMxn
      columns.common, // totalQuarterFourActualIncome

      columns.common, // monthOneSalaryToBilling
      columns.common, // monthTwoSalaryToBilling
      columns.common, // monthThreeSalaryToBilling
      columns.common, // averageQuarterOneSalaryToBilling

      columns.common, // monthFourSalaryToBilling
      columns.common, // monthFiveSalaryToBilling
      columns.common, // monthSixSalaryToBilling
      columns.common, // averageQuarterTwoSalaryToBilling

      columns.common, // monthSevenSalaryToBilling
      columns.common, // monthEightSalaryToBilling
      columns.common, // monthNineSalaryToBilling
      columns.common, // averageQuarterThreeSalaryToBilling

      columns.common, // monthTenSalaryToBilling
      columns.common, // monthElevenSalaryToBilling
      columns.common, // monthTwelveSalaryToBilling
      columns.common, // averageQuarterFourSalaryToBilling
    ],
    data: [
      // Main column
      [
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.grossSalaryMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.grossSalaryMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.grossSalaryMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.grossSalaryMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actualCostMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actualCostMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actualCostMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actualCostMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueUSD'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueUSD'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueUSD'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueUSD'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actIncomeMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actIncomeMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actIncomeMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actIncomeMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.salaryToBilling'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.salaryToBilling'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.salaryToBilling'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.salaryToBilling'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
      ],
      // Main column
      [
        {
          value: t('label.employeeId'),
          style: styleHeader,
        },
        {
          value: t('label.employeeName'),
          style: styleHeader,
        },
        {
          value: t('label.jobTitle'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
        {
          value: t('label.clientId'),
          style: styleHeader,
        },
        {
          value: t('label.clientName'),
          style: styleHeader,
        },
        {
          value: t('label.clientJobTitle'),
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarterOne || '',
          style: styleHeader,
        },
        {
          value: monthFour || '',
          style: styleHeader,
        },
        {
          value: monthFive || '',
          style: styleHeader,
        },
        {
          value: monthSix || '',
          style: styleHeader,
        },
        {
          value: quarterTwo || '',
          style: styleHeader,
        },
        {
          value: monthSeven || '',
          style: styleHeader,
        },
        {
          value: monthEight || '',
          style: styleHeader,
        },
        {
          value: monthNine || '',
          style: styleHeader,
        },
        {
          value: quarterThree || '',
          style: styleHeader,
        },
        {
          value: monthTen || '',
          style: styleHeader,
        },
        {
          value: monthEleven || '',
          style: styleHeader,
        },
        {
          value: monthTwelve || '',
          style: styleHeader,
        },
        {
          value: quarterFour || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarterOne || '',
          style: styleHeader,
        },
        {
          value: monthFour || '',
          style: styleHeader,
        },
        {
          value: monthFive || '',
          style: styleHeader,
        },
        {
          value: monthSix || '',
          style: styleHeader,
        },
        {
          value: quarterTwo || '',
          style: styleHeader,
        },
        {
          value: monthSeven || '',
          style: styleHeader,
        },
        {
          value: monthEight || '',
          style: styleHeader,
        },
        {
          value: monthNine || '',
          style: styleHeader,
        },
        {
          value: quarterThree || '',
          style: styleHeader,
        },
        {
          value: monthTen || '',
          style: styleHeader,
        },
        {
          value: monthEleven || '',
          style: styleHeader,
        },
        {
          value: monthTwelve || '',
          style: styleHeader,
        },
        {
          value: quarterFour || '',
          style: styleHeader,
        },

        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarterOne || '',
          style: styleHeader,
        },
        {
          value: monthFour || '',
          style: styleHeader,
        },
        {
          value: monthFive || '',
          style: styleHeader,
        },
        {
          value: monthSix || '',
          style: styleHeader,
        },
        {
          value: quarterTwo || '',
          style: styleHeader,
        },
        {
          value: monthSeven || '',
          style: styleHeader,
        },
        {
          value: monthEight || '',
          style: styleHeader,
        },
        {
          value: monthNine || '',
          style: styleHeader,
        },
        {
          value: quarterThree || '',
          style: styleHeader,
        },
        {
          value: monthTen || '',
          style: styleHeader,
        },
        {
          value: monthEleven || '',
          style: styleHeader,
        },
        {
          value: monthTwelve || '',
          style: styleHeader,
        },
        {
          value: quarterFour || '',
          style: styleHeader,
        },

        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarterOne || '',
          style: styleHeader,
        },
        {
          value: monthFour || '',
          style: styleHeader,
        },
        {
          value: monthFive || '',
          style: styleHeader,
        },
        {
          value: monthSix || '',
          style: styleHeader,
        },
        {
          value: quarterTwo || '',
          style: styleHeader,
        },
        {
          value: monthSeven || '',
          style: styleHeader,
        },
        {
          value: monthEight || '',
          style: styleHeader,
        },
        {
          value: monthNine || '',
          style: styleHeader,
        },
        {
          value: quarterThree || '',
          style: styleHeader,
        },
        {
          value: monthTen || '',
          style: styleHeader,
        },
        {
          value: monthEleven || '',
          style: styleHeader,
        },
        {
          value: monthTwelve || '',
          style: styleHeader,
        },
        {
          value: quarterFour || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarterOne || '',
          style: styleHeader,
        },
        {
          value: monthFour || '',
          style: styleHeader,
        },
        {
          value: monthFive || '',
          style: styleHeader,
        },
        {
          value: monthSix || '',
          style: styleHeader,
        },
        {
          value: quarterTwo || '',
          style: styleHeader,
        },
        {
          value: monthSeven || '',
          style: styleHeader,
        },
        {
          value: monthEight || '',
          style: styleHeader,
        },
        {
          value: monthNine || '',
          style: styleHeader,
        },
        {
          value: quarterThree || '',
          style: styleHeader,
        },
        {
          value: monthTen || '',
          style: styleHeader,
        },
        {
          value: monthEleven || '',
          style: styleHeader,
        },
        {
          value: monthTwelve || '',
          style: styleHeader,
        },
        {
          value: quarterFour || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarterOne || '',
          style: styleHeader,
        },
        {
          value: monthFour || '',
          style: styleHeader,
        },
        {
          value: monthFive || '',
          style: styleHeader,
        },
        {
          value: monthSix || '',
          style: styleHeader,
        },
        {
          value: quarterTwo || '',
          style: styleHeader,
        },
        {
          value: monthSeven || '',
          style: styleHeader,
        },
        {
          value: monthEight || '',
          style: styleHeader,
        },
        {
          value: monthNine || '',
          style: styleHeader,
        },
        {
          value: quarterThree || '',
          style: styleHeader,
        },
        {
          value: monthTen || '',
          style: styleHeader,
        },
        {
          value: monthEleven || '',
          style: styleHeader,
        },
        {
          value: monthTwelve || '',
          style: styleHeader,
        },
        {
          value: quarterFour || '',
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({
  data,
  t,
  monthOne,
  monthTwo,
  monthThree,
  monthFour,
  monthFive,
  monthSix,
  monthSeven,
  monthEight,
  monthNine,
  monthTen,
  monthEleven,
  monthTwelve,
  quarter,
  quarterOne,
  quarterTwo,
  quarterThree,
  quarterFour,
  title,
}) => {
  const newDataSheetSet = cloneDeep(
    multiDataSetDefault({
      t,
      monthOne,
      monthTwo,
      monthThree,
      monthFour,
      monthFive,
      monthSix,
      monthSeven,
      monthEight,
      monthNine,
      monthTen,
      monthEleven,
      monthTwelve,
      quarter,
      quarterOne,
      quarterTwo,
      quarterThree,
      quarterFour,
      title,
    })
  );
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.employeeId ? item.employeeId : '' });
      newItem.push({ value: item.employeeName ? item.employeeName : '' });
      newItem.push({ value: item.jobTitle ? item.jobTitle : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'status' }) });
      newItem.push({ value: item.clientId ? item.clientId : '' });
      newItem.push({ value: item.clientName ? item.clientName : '' });
      newItem.push({ value: item.clientJobTitle ? item.clientJobTitle : '' });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterOneGrossSal) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthFourGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthFiveGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthSixGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterTwoGrossSal) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthSevenGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthEightGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthNineGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterThreeGrossSal) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthTenGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthElevenGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwelveGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterFourGrossSal) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthOneActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeActualacost) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterOneActualCost) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthFourActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthFiveActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthSixActualacost) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterTwoActualCost) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthSevenActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthEightActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthNineActualacost) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterThreeActualCost) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthTenActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthElevenActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwelveActualacost) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterFourActualCost) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthOneRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterOneRevenueUsd) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthFourRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthFiveRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthSixRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterTwoRevenueUsd) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthSevenRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthEightRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthNineRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterThreeRevenueUsd) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthTenRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthElevenRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwelveRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterFourRevenueUsd) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthOneRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterOneRevenueMxn) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthFourRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthFiveRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthSixRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterTwoRevenueMxn) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthSevenRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthEightRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthNineRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterThreeRevenueMxn) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthTenRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthElevenRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwelveRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterFourRevenueMxn) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthOneActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterOneActualIncome) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthFourActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthFiveActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthSixActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterTwoActualIncome) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthSevenActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthEightActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthNineActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterThreeActualIncome) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthTenActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthElevenActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwelveActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalQuarterFourActualIncome) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthOneSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.averageQuarterOneSalaryToBilling) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthFourSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthFiveSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthSixSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.averageQuarterTwoSalaryToBilling) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthSevenSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthEightSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthNineSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.averageQuarterThreeSalaryToBilling) });

      newItem.push({ value: formatMoneyValueToExport(item?.monthTenSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthElevenSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwelveSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.averageQuarterFourSalaryToBilling) });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
