import gql from 'graphql-tag';

export default id => {
  const getRevenueRecognitionSummary = `getRevenueRecognitionSummary(journalId: "${id}")`;
  return gql`
    query {
      ${getRevenueRecognitionSummary} {
        journalId
        revenueStartDate
        revenueEndDate
        objectId
        calendarPeriod
        glAccount
        amount
        amountRecognized
        objectType
        revenueRecognitionType
        batchId
      }
    }
  `;
};
