import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class ProductFamilySearchForm extends SearchComponent {
  searchInputs = [
    {
      name: 'productCompany',
      label: 'common:label.productCompany',
    },
    {
      name: 'productFamily',
      label: 'common:label.productFamily',
    },
    {
      name: 'productLine',
      label: 'common:label.productLine',
    },
    {
      name: 'productType',
      label: 'common:label.productType',
    },
    {
      name: 'productSubType',
      label: 'common:label.productSubType',
    },
  ];

  btnSpan = 'col-md-9';
}

ProductFamilySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ProductFamilySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ProductFamilySearchForm);
