export default {
  invoiceDate: {
    asc: 'invoiceDate_ASC',
    desc: 'invoiceDate_DESC',
  },
  due: {
    asc: 'due_ASC',
    desc: 'due_DESC',
  },
  dueDate: {
    asc: 'dueDate_ASC',
    desc: 'dueDate_DESC',
  },
};
