import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { GenericInput, DataTable } from '../../common';
import {
  getAccountExchangeRates,
  createAccountExchangeRates,
  modifyAccountExchangeRates,
} from '../../../containers/CustomerPage/actions';

class XChangeRates extends PureComponent {
  state = {
    isModify: false,
    wasValidated: false,
    isSearching: true,
    data: [],
    dataSubmit: [],
    defaultData: {},
  };

  formRef = React.createRef();

  componentDidMount = () => {
    this.dogetAccountExchangeRates();
  };

  dogetAccountExchangeRates = () => {
    const { getAccountExchangeRates, id } = this.props;
    this.setState({ isSearching: false });
    getAccountExchangeRates(id, ({ success, data }) => {
      if (success) this.setState({ data: data || [], defaultData: data, isModify: !!data });
      this.setState({ isSearching: false });
    });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onHandleChange = ({ name, value, index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ data: newData, dataSubmit });
  };

  onAddNewXChangeRates = () => {
    let indexNewItem = 0;
    const { data, dataSubmit } = this.state;
    let newData = cloneDeep(data);
    if (!newData) newData = [];
    newData.forEach(item => {
      if (item.index > indexNewItem) {
        indexNewItem = item.index;
      }
    });
    if (!newData.length) newData = [];
    const newDataItem = {
      index: indexNewItem + 1,
      currency: '',
      split: '',
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: null,
      exchangeRate: null,
      threshold: null,
      splitType: null,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ data: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { data, isModify } = this.state;
    const { modifyAccountExchangeRates, createAccountExchangeRates, id } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const payload = cloneDeep(data).map(val => ({ ...val, id }));
    if (isModify) {
      return modifyAccountExchangeRates({ dataSubmit: payload }, ({ success }) => {
        if (success) this.dogetAccountExchangeRates();
      });
    }
    return createAccountExchangeRates({ dataSubmit: payload }, ({ success }) => {
      if (success) this.dogetAccountExchangeRates();
    });
  };

  onChangeTable = ({ name, value, index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    let newValue = value;
    if (name === 'split' && value && value.length > 2) {
      const firstValue = value.slice(0, 2);
      const firstValueConvert = Number.parseInt(value.slice(0, 2), 10);
      newValue = `${firstValue}:${firstValueConvert > 90 ? '0' : ''}${100 - firstValueConvert}`;
    }
    if (!dataSubmit[index]) dataSubmit[index] = newData[index];
    newData[index][name] = newValue;
    this.setState({ data: newData, dataSubmit });
  };

  onCheckDuplicateCurrency = () => {
    const { data } = this.state;
    let isDuplicate = false;
    if (data && data.length) {
      data.forEach((val, index) => {
        const { currency, startDate, endDate } = val;
        data.forEach((dt, nIndex) => {
          if (
            dt.currency &&
            dt.currency === currency &&
            dt.startDate === startDate &&
            dt.endDate === endDate &&
            index !== nIndex
          ) {
            isDuplicate = true;
          }
        });
      });
    }
    return isDuplicate;
  };

  render() {
    const { wasValidated, isSearching, data } = this.state;
    const {
      id,
      t,
      modeModifyAccountExchangeRates,
      modeCreateAccountExchangeRates,
      modeGetAccountExchangeRates,
      currencyAccount,
      currencyOptions,
      customerInfo,
    } = this.props;
    if (modeGetAccountExchangeRates === 0) return '';
    const POColumns = [
      {
        name: 'currency',
        label: t('label.xchangeCurrency'),
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.currency}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="currency"
            type="select"
            options={
              currencyAccount && currencyOptions
                ? currencyOptions.filter(val => val.value !== currencyAccount)
                : currencyOptions
            }
            required
            menuPortalTarget
          />
        ),
      },
      {
        name: 'exchangeRate',
        label: t('label.xchangeRate'),
        // required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.exchangeRate}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="exchangeRate"
            // required
          />
        ),
      },
      {
        name: 'threshold',
        label: t('label.payemntThreshold'),
        render: (colName, item, idx) => (
          <GenericInput
            value={item.threshold}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="threshold"
          />
        ),
      },
      {
        name: 'split',
        label: t('label.xchangeSplit'),
        render: (colName, item, idx) => (
          <NumberFormat
            onChange={evt => this.onChangeTable({ name: evt.target.name, value: evt.target.value, index: idx })}
            value={item.split}
            className="form-control input-group"
            name="split"
            format="##:##"
          />
        ),
      },
      {
        name: 'splitType',
        label: t('label.splitType'),
        style: { minWidth: '180px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.splitType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="splitType"
            tOptions="selections:deltaPaymentSplitType"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'startDate',
        label: `${t('label.startDate')} (>)`,
        style: { minWidth: '180px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            key="startDate"
            wrapperClass="col-md-12 inner-popover"
            type="date"
            dateFormat="yyyy-MM-dd"
            value={item.startDate}
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="startDate"
          />
        ),
      },
      {
        name: 'endDate',
        label: `${t('label.endDate')} (<=)`,
        style: { minWidth: '180px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            key="endDate"
            wrapperClass="col-md-12 inner-popover"
            type="date"
            dateFormat="yyyy-MM-dd"
            value={item.endDate}
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="endDate"
          />
        ),
      },
    ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('label.contractedExchangeRates')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <duv className="col-md-12 pb-4">
              <GenericInput
                wrapperClass="col-md-4 m-auto"
                type="text"
                label={t('label.accountCurrency')}
                readOnly
                name="accountCurrency"
                value={
                  currencyOptions && currencyOptions.find(val => val.value === currencyAccount)
                    ? currencyOptions.find(val => val.value === currencyAccount).label
                    : ''
                }
              />
            </duv>
            <form
              onSubmit={modeModifyAccountExchangeRates === 2 ? this.hanldeSubmit : () => {}}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
              noValidate
              ref={this.formRef}
            >
              <div>
                <DataTable columns={POColumns} data={data || []} isLoading={isSearching} />
              </div>
              <br />
              <div className="form-group col-md-12 pb-3 buttons-attibute">
                {modeCreateAccountExchangeRates === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.onAddNewXChangeRates}
                    disabled={modeCreateAccountExchangeRates === 1}
                  >
                    +
                    {t('label.addNewXchangeRate')}
                  </button>
                )}
                {modeModifyAccountExchangeRates === 2 && (
                  <button
                    type="submit"
                    disabled={this.onCheckDuplicateCurrency()}
                    className="button button-border x-small float-right"
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.dogetAccountExchangeRates}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

XChangeRates.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  getAccountDetail: PropTypes.func,
  getAccountExchangeRates: PropTypes.func.isRequired,
  onHandleCreateAttribute: PropTypes.func,
  modifyAccount: PropTypes.func,
  accountNum: PropTypes.string,
  isNewAccount: PropTypes.bool,
};

XChangeRates.defaultProps = {
  accountNum: '',
  account: {},
  isNewAccount: false,
  getAccountDetail: () => {},
  modifyAccount: () => {},
  onHandleCreateAttribute: () => {},
};

const newXChangeRates = connect(mapStateToProps, {
  getAccountExchangeRates,
  createAccountExchangeRates,
  modifyAccountExchangeRates,
})(XChangeRates);

export default withTranslation('common')(newXChangeRates);
