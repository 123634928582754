import gql from 'graphql-tag';

export default () => gql`
  query {
    getUserGroups(dummy: "") {
      id
      index
      groupId
      groupName
      users {
        index
        userId
        firstName
        lastName
        email
      }
    }
  }
`;
