import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['reProcessRecs']);
  const initiateUsageReprocess = `initiateUsageReprocess(input: ${dataModify})`;
  return gql`
  mutation {
    ${initiateUsageReprocess}
  }
`;
};
