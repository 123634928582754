import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['dashboardType', 'index']);
  const createUserPreferences = `createUserPreferences(input: ${dataCreate})`;
  
  return gql`
  mutation {
    ${createUserPreferences} {
      id
    }
  }
`;
};
