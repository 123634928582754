/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';

class ModalJobErrors extends React.Component {
  static defaultProps = {
    jobErrorGroupList: [],
  };

  constructor() {
    super();
    this.state = {};
  }

  onRetry = reason => {
    if (this.props.onRetry) {
      this.props.onRetry({ reason });
    }
  };

  render() {
    const { openModal, toggleModal, jobErrorGroupList, toggleModalDetail, t } = this.props;
    return (
      <Modal
        isOpen={openModal}
        toggle={() => toggleModal()}
        size="lg"
        className="modal-custom modal-custom--error-detail modal-70 bd-example-modal-lg"
      >
        <ModalHeader toggle={() => toggleModal()}>{t('label.jobErrors')}</ModalHeader>
        <ModalBody>
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <div className="card-body card-table-body">
                <table className="table center-aligned-table mb-0">
                  <thead>
                    <tr>
                      <th>{t('label.errorCode')}</th>
                      <th className="text-center">{t('label.numberOfRecordsError')}</th>
                      <th>{t('label.detail')}</th>
                      <th className="table-borderless" />
                    </tr>
                  </thead>
                  <tbody>
                    {jobErrorGroupList &&
                      jobErrorGroupList.map((item, index) => (
                        <tr key={index.toString()}>
                          <td
                            style={
                              {
                                // maxWidth: 200,
                                // overflow: 'hidden',
                                // whiteSpace: 'nowrap',
                                // textOverflow: 'ellipsis',
                              }
                            }
                          >
                            {item.reason}
                          </td>
                          <td className="text-center">{item.count}</td>
                          <td>
                            <i
                              className="fa fa-info-circle ico-info"
                              onClick={() => toggleModalDetail(item.reason)}
                            />
                          </td>
                          <td>
                            <Button color="primary" size="sm" onClick={() => this.onRetry(item.reason)}>
                              {t('label.retry')}
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ModalJobErrors.propTypes = {
  openModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  jobErrorGroupList: PropTypes.array,
  toggleModalDetail: PropTypes.func,
  onRetry: PropTypes.func,
};

export default withTranslation('common')(ModalJobErrors);
