import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'isPricingValueStatic']);
  const modifyProvisioningAttributesConfig = `modifyProvisioningAttributesConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyProvisioningAttributesConfig} {
      id
    }
  }
`;
};
