import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'type', 'dom', 'usageFlag']);
  const createConfigPendingBillingTrigger = `createConfigPendingBillingTrigger(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigPendingBillingTrigger} {
      id
    }
  }
`;
};
