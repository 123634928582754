import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t }) => [
  {
    xSteps: 3,
    columns: [],
    data: [[{ value: 'CLIENTS', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.id,
      columns.accountId,
      columns.common, // name
      columns.common, // startDate
      columns.common, // endDate
      columns.common, // status
    ],
    data: [
      [
        {
          value: t('label.id'),
          style: styleHeader,
        },
        {
          value: t('label.accountId'),
          style: styleHeader,
        },
        {
          value: t('label.clientName'),
          style: styleHeader,
        },
        {
          value: t('label.startDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.id ? item.id : '' });
      newItem.push({ value: item.accountId ? item.accountId : '' });
      newItem.push({ value: item.name ? item.name : '' });
      newItem.push({ value: item.startDate ? item.startDate : '' });
      newItem.push({ value: item.endDate ? item.endDate : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'timeAndBillingObjectStatus' }),
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
