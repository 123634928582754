import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormWithTableItem, DataTable, GenericInput } from '../../../../components/common';
import { convertSymbol2OptionCurrency } from '../../../../constantsApp/symbolList';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { createExchangeRate } from '../../actions';
import { makeGetExchangeRate } from '../../selectors';
import { getUserId } from '../../../../localStorages';
import { supportShowNumberValue } from '../../../../utils/utils';

class CreateCurrency extends React.PureComponent {
  state = {
    data: { exchangeRateList: [] },
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  onChange = ({ name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[name] = value;
    this.setState({ data: newData });
  };

  onChangeTable = ({ index, name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.exchangeRateList[index][name] = value;
    this.setState({ data: newData });
  };

  addNewItem = () => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.exchangeRateList.push({
        sourceCurrency: '',
        targetCurrency: '',
        type: '',
        exchangeRate: '',
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.exchangeRateList.splice(index, 1);
    this.setState({ data: newData });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    const { data } = this.state;
    if (!this.validate(true)) {
      return false;
    }
    const { createExchangeRate } = this.props;
    createExchangeRate(data);
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onCancel = () => {
    this.setState({ data: { exchangeRateList: [] } });
  };

  render() {
    const { t, permissions, location } = this.props;
    const { modeCreateCurrencyExchangeConfig } = permissions;
    if (!modeCreateCurrencyExchangeConfig) return '';
    const { data, wasValidated } = this.state;
    const tableColumns = [
      {
        name: 'sourceCurrency',
        label: 'label.sourceCurrency',
        required: true,
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.sourceCurrency}
              name="sourceCurrency"
              type="select"
              onChange={({ name, value }) => this.onChangeTable({ index: idx, name, value })}
              wrapperClass="col-md-12"
              options={convertSymbol2OptionCurrency([])}
              menuPortalTarget
              required
            />
          );
        },
      },
      {
        name: 'targetCurrency',
        label: 'label.targetCurrency',
        required: true,
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.targetCurrency}
              name="targetCurrency"
              type="select"
              onChange={({ name, value }) => this.onChangeTable({ index: idx, name, value })}
              wrapperClass="col-md-12"
              options={convertSymbol2OptionCurrency([])}
              menuPortalTarget
              required
            />
          );
        },
      },
      {
        name: 'exchangeRateType',
        label: 'label.exchangeRateType',
        required: true,
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.type}
              name="type"
              type="select"
              tOptions="selections:exchangeRateType"
              onChange={({ name, value }) => this.onChangeTable({ index: idx, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              required
            />
          );
        },
      },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        required: true,
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={supportShowNumberValue(item.exchangeRate)}
              name="exchangeRate"
              onChange={({ name, value }) => this.onChangeTable({ index: idx, name, value })}
              wrapperClass="col-md-12"
              required
              type="number"
            />
          );
        },
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: idx })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={{
            pathname: RouteNames.revenueConfigurationCurrencyExchange.path,
            state: location && location.state ? location.state : null,
          }}
          titleBtn={t('label.back')}
          titleRight={t('label.newCurrencyExchangeConfiguration')}
        />
        <br />
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation col-sm-12 mb-30 ${wasValidated ? 'was-validated' : ''}`}
          noValidate
          ref={this.formRef}
        >
          <FormWithTableItem title={t('label.newCurrencyExchangeConfiguration')}>
            <div className="col-md-12 pl-3 pb-4 pt-2 row">
              <GenericInput
                value={data.name}
                label="label.name"
                name="name"
                onChange={this.onChange}
                wrapperClass="col-md-4"
                required
              />
              <GenericInput
                value={getUserId() || data.userId}
                label="label.userId"
                name="userId"
                onChange={this.onChange}
                wrapperClass="col-md-4"
                readOnly
                required
              />
              <GenericInput
                value={data.type}
                label="label.exchangeRateType"
                type="select"
                tOptions="selections:exchangeRateType"
                name="type"
                onChange={this.onChange}
                wrapperClass="col-md-4"
                required
              />
              <GenericInput
                value={data.effectiveDate}
                label="label.effectiveDate"
                name="effectiveDate"
                onChange={this.onChange}
                wrapperClass="col-md-4"
                type="date"
              />
            </div>
            <DataTable columns={tableColumns} data={data && data.exchangeRateList ? data.exchangeRateList : []} />
            <div className="form-group col-md-12 buttons-attibute">
              <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                +
                {t('label.addNewExchangeRate')}
              </button>
              {modeCreateCurrencyExchangeConfig === 2 && (
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                >
                  {t('label.create')}
                </button>
              )}
              <button onClick={this.onCancel} type="button" className="button button-border black x-small float-right">
                {t('label.cancel')}
              </button>
            </div>
          </FormWithTableItem>
        </form>
      </div>
    );
  }
}

CreateCurrency.propTypes = {
  createExchangeRate: PropTypes.func,
};

CreateCurrency.defaultProps = {
  createExchangeRate: () => {},
};

const mapStateToProps = createStructuredSelector({
  exchangeRate: makeGetExchangeRate() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    createExchangeRate,
  })(withRouter(CreateCurrency))
);
