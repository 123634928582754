import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionSearchItems,
  checkPermissionGetItem,
  checkPermissionSearchPriceOffers,
  checkPermissionGetPriceOffer,
  checkPermissionGetDiscountOffer,
  checkPermissionSearchDiscountOffers,
  checkPermissionGetItemServices,
  checkPermissionSearchProductFamily,
} from './CheckPermission';
import { checkPermissionGetProvisioningAttributes } from '../OrderPage/CheckPermission';
import { checkPermissionSearchTopupOffer } from '../BaseConfigurations/CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends PureComponent {
  render() {
    const {
      isExpand,
      location: { pathname },
      permissionsPricing,
      permissionOrder,
    } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsPricing, listKeys: permissionList.pricingManagement }))
      return null;
    const childsItemPricing = [];

    const childAttributesConfig = [];
    let modeSearchItems = 0;
    let modeGetItem = 0;

    let modeSearchPriceOffers = 0;
    let modeGetPriceOffer = 0;
    let modeSearchDiscountOffers = 0;
    let modeGetDiscountOffer = 0;
    let modeSearchItemServices = 0;
    let modeSearchTopupOffer = 0;
    let modeGetProvisioningAttributes = 0;
    let modeSearchProductFamily = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeSearchItems = checkPermissionSearchItems({ listPermission: listPermissionPricing });
      modeGetItem = checkPermissionGetItem({ listPermission: listPermissionPricing });
      modeSearchPriceOffers = checkPermissionSearchPriceOffers({ listPermission: listPermissionPricing });
      modeGetPriceOffer = checkPermissionGetPriceOffer({ listPermission: listPermissionPricing });
      modeGetDiscountOffer = checkPermissionGetDiscountOffer({ listPermission: listPermissionPricing });
      modeSearchDiscountOffers = checkPermissionSearchDiscountOffers({ listPermission: listPermissionPricing });
      modeSearchItemServices = checkPermissionGetItemServices({ listPermission: listPermissionPricing });
      modeSearchTopupOffer = checkPermissionSearchTopupOffer({ listPermission: listPermissionPricing });
      modeSearchProductFamily = checkPermissionSearchProductFamily({ listPermission: listPermissionPricing });
    }

    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeGetProvisioningAttributes = checkPermissionGetProvisioningAttributes({ listPermission });
    }

    const prepaidConfig = [];
    if (modeSearchTopupOffer) {
      prepaidConfig.push({
        icon: 'ti-map-alt',
        label: 'baseConfigurationsPage:sidebar.topupOffers',
        href: RouteNames.topupOffers.path,
      });
    }

    if (modeSearchProductFamily !== 0) {
      childsItemPricing.push({
        icon: 'ti-panel',
        label: 'baseConfigurationsPage:sidebar.productFamily',
        href: RouteNames.productFamilyPricing.path,
      });
    }

    if (modeSearchItems !== 0 && modeGetItem !== 0) {
      childsItemPricing.push({
        icon: 'ti-mobile',
        label: 'pricingPage:sidebar.items',
        href: RouteNames.pricingItem.path,
        isActive: pathname && pathname.indexOf(RouteNames.pricingItem.path) >= 0,
      });
    }

    if (modeSearchItemServices !== 0) {
      childsItemPricing.push({
        icon: 'ti-zip',
        label: 'pricingPage:sidebar.itemServices',
        href: RouteNames.itemServices.path,
        isActive: pathname && pathname.indexOf(RouteNames.pricingItem.path) >= 0,
      });
    }

    if (modeSearchPriceOffers !== 0 && modeGetPriceOffer !== 0) {
      childsItemPricing.push({
        icon: 'ti-gift',
        label: 'pricingPage:sidebar.priceOffer',
        href: RouteNames.pricingPriceOffer.path,
        isActive: pathname && pathname.indexOf(RouteNames.pricingPriceOffer.path) >= 0,
      });
    }

    if (modeSearchDiscountOffers) {
      childsItemPricing.push({
        icon: 'ti-flag',
        label: 'pricingPage:sidebar.discountTrigger',
        href: RouteNames.discountTrigger.path,
        isActive: pathname && pathname.indexOf(RouteNames.discountTrigger.path) >= 0,
        // disable: true,
      });
    }

    if (modeSearchDiscountOffers !== 0 && modeGetDiscountOffer !== 0) {
      childsItemPricing.push({
        icon: 'ti-money',
        label: 'pricingPage:sidebar.discountOffer',
        href: RouteNames.pricingDiscountOffer.path,
        isActive: pathname && pathname.indexOf(RouteNames.pricingDiscountOffer.path) >= 0,
        // disable: true,
      });
    }

    if (modeGetProvisioningAttributes) {
      childAttributesConfig.push({
        icon: 'ti-money',
        label: 'orderPage:sidebar.attributes',
        href: RouteNames.provisioningAttributes.path,
        isActive: pathname && pathname.indexOf(RouteNames.provisioningAttributes.path) >= 0,
        // disable: true,
      });
    }

    const sideProps = {
      isExpand,
      items: [
        // {
        //   isHaveExpand: false,
        //   mainTilte: 'navbar:pricingHub.main',
        //   href: RouteNames.pricingItem.path,
        //   childs: [],
        // },
        {
          isHaveExpand: true,
          mainTilte: 'orderPage:sidebar.provisioningConfig',
          href: RouteNames.provisioningAttributes.path,
          childs: childAttributesConfig,
        },
        // {
        //   isHaveExpand: true,
        //   mainTilte: 'baseConfigurationsPage:sidebar.productFamily',
        //   href: RouteNames.productFamilyPricing.path,
        //   childs: [],
        // },
        {
          isHaveExpand: true,
          mainTilte: 'navbar:pricingHub.subMain.pricingCatalog',
          childs: childsItemPricing,
        },
        {
          isHaveExpand: true,
          mainTilte: 'baseConfigurationsPage:sidebar.prepaidConfig',
          childs: [...prepaidConfig],
        },
      ],
    };
    return <SideBar {...sideProps} />;
  }
}

Sidebar.propTypes = {
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
};

Sidebar.defaultProps = {
  permissionsPricing: {},
  location: {},
  isExpand: true,
};

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsPricing = store.get('auth').toJS().permissionsApp.pricingHubModules;
  const permissionOrder = store.get('auth').toJS().permissionsApp.customerHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsPricing: permissionsPricing.length
      ? permissionsPricing.filter(a => a.moduleName === 'PRICE_MANAGEMENT')[0]
      : {},
    permissionOrder: permissionOrder.length ? permissionOrder.filter(a => a.moduleName === 'ORDER_MANAGEMENT')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
