import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function DragDropConfigExportTemplate({ exportColumns, onToggle, onHandleSave }) {
  const { t } = useTranslation('common');

  const [columns, setColumns] = useState(exportColumns);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
              >
                <div className="title-form">{t(column.name)}</div>

                <div className="body-export-drd">
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={`dnd-container ${snapshot.isDraggingOver ? 'drag-container' : 'drop-container'}`}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable key={item.name} draggableId={item.name} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      className={`item ${snapshot.isDragging ? 'item-drag' : 'item-drop'}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: 'none',
                                        padding: 10,
                                        margin: '0 0 8px 0',
                                        minHeight: '30px',
                                        // backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                        // color: 'white',
                                        opacity: 0.8,
                                        // fontWeight: 'bold',
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {item.isSupportDangerouslySetInnerHTML ? (
                                        <div
                                          className="label-header"
                                          dangerouslySetInnerHTML={{ __html: t(item.label) }}
                                        />
                                      ) : (
                                        t(item.label)
                                      )}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
      <div className="col-md-12 separation-border-top pt-3 mt-3">
        <button type="button" className="button x-small float-right" onClick={() => onHandleSave(columns)}>
          {t('label.save')}
        </button>
        <button type="button" onClick={onToggle} className="button mr-2 button-border black x-small float-right">
          {t('label.cancel')}
        </button>
      </div>
    </div>
  );
}

export default DragDropConfigExportTemplate;
