import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'recordIndicator',
    'sourceFieldPos',
    'targetRecord',
    'serviceType',
  ]);
  const modifyUsageFileMapCfg = `modifyUsageFileMapCfg(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyUsageFileMapCfg} {
      id
    }
  }
`;
};
