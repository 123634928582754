import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { add3Dots } from '../../../utils/utils';

const DotWithTooltipGroup = ({ value, length, wrapperClass }) => {
  return value && value.length > length ? (
    <Tooltip
      placement="bottom"
      overlay={value || ''}
      overlayClassName="tooltip-details"
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
    >
      <div className="content-item">{add3Dots(value, length)}</div>
    </Tooltip>
  ) : (
    <div className={wrapperClass || 'content-item'}>{value}</div>
  );
};

DotWithTooltipGroup.propTypes = {
  value: PropTypes.string,
  wrapperClass: PropTypes.string,
  length: PropTypes.number,
};

DotWithTooltipGroup.defaultProps = {
  value: '',
  length: 0,
  wrapperClass: '',
};

export default DotWithTooltipGroup;
