import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { GenericInput } from '../common';

class SubForm extends PureComponent {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (isEqual(state.item, props.item)) return null;
    return {
      ...props.item,
      item: { ...props.item },
    };
  }

  onChangeItem = () => {
    const { item, ...rest } = this.state;
    const { onChangeItem } = this.props;
    if (onChangeItem) {
      onChangeItem({ ...rest });
    }
  };

  onRemoveItem = e => {
    e.preventDefault();
    const { onRemoveItem } = this.props;
    if (onRemoveItem) {
      onRemoveItem();
    }
  };

  onChange = ({ name, value }) => {
    this.setState({ [name]: value }, () => this.onChangeItem());
  };

  render() {
    const { headerTitle, isRemoveable, inputs, isComplexUsage, index } = this.props;
    return (
      <div className="sub-form">
        <div className="card-header">
          {headerTitle}
          {isRemoveable && (
            <button type="button" className="btn-remove-sub-form" onClick={this.onRemoveItem} disabled={!isRemoveable}>
              <i className="fa fa-trash" />
            </button>
          )}
        </div>
        <div className="card-body">
          <div className="form-row">
            {inputs.map(input => {
              return (
                <GenericInput
                  {...input}
                  disabled={input.disabled}
                  key={input.name}
                  value={this.state[input.name]}
                  onChange={this.onChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

SubForm.propTypes = {
  onChangeItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
  isRemoveable: PropTypes.bool,
  item: PropTypes.object.isRequired,
  headerTitle: PropTypes.string,
};

SubForm.defaultProps = {
  item: {},
  isRemoveable: true,
  onChangeItem: null,
  headerTitle: null,
};
export default SubForm;
