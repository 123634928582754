export default {
  sidebar: {
    aROpsConfig: 'Configuración',
    // aROpsConfig: 'Configuración de Operaciones de CxC',
    adjustments: 'Ajustes',
    disputes: 'Disputas',
    writeOffs: 'Cancelaciones',
    bulkAdjustments: 'Ajustes Masivos',
    GLsetup: 'Configuración Libro Mayor',
    itemMap: 'Mapa de Artículo',
    reasonCode: 'Código de Motivo',
    AROpsGL: 'Libro Mayor Operaciones CxC',
    groupsReason: 'Motivos de Grupo',
    ARThresholds: 'Umbrales CxC',
    folios: 'Folios',
    creditNotesReport: 'Reporte de Notas de Crédito',
  },
};
