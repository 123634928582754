import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { GenericInput, TitleFrom, ModalUploadFile } from '../../../components/common';
import dataField, { tenantSupportFields } from '../constant';
import { getAddressesForm, getBase64 } from '../../../utils/utils';
import { makeTenantProfileConfig } from '../selectors';
import { getTenantProfileConfig } from '../actions';
import RouteName from '../../App/RouteNames';

const CompanyProfile = ({
  modeModifyCompanyProfile,
  isRequireField,
  t,
  data,
  id,
  modifyTenant,
  uploadMultiPartFiles,
  getTenantConfig,
  readFileFromS3,
  s3Config,
  getTenantProfileConfig,
  modeSetLincenseData,
  modeModifyLicenseData,
}) => {
  const [tenantData, setData] = useState(data || {});
  const [idState, setId] = useState(id || {});
  const [fileUploaded, setFileUploaded] = useState(null);
  const [isOpenModalUpload, setOpenModalUpload] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({});
  const [base64FileSelect, setBase64FileSelect] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [fileSelect, setFileSelect] = useState(null);
  const [isUploaded, setisUploaded] = useState(false);
  const [mainTenantData, setMainTenantData] = useState({});
  const [mainTenantDataSubmit, setMainTenantDataSubmit] = useState({});

  useEffect(() => {
    if (window.location.pathname === RouteName.tenantData.path) {
      getTenantProfileConfig();
    }
  }, [getTenantProfileConfig]);

  useEffect(() => {
    const { tenantProfile, id, ...rest } = data || {};
    if (data?.tenantProfile) setData(data?.tenantProfile || {});
    if (data?.id) setId(data?.id || {});
    if (rest) setMainTenantData(rest);
  }, [data]);

  const onChange = ({ name, value }) => {
    tenantData[name] = value;
    dataSubmit[name] = value;
    setData({ ...tenantData });
    setDataSubmit({ ...dataSubmit });
  };

  const onChangeMainData = ({ name, value }) => {
    mainTenantData[name] = value;
    mainTenantDataSubmit[name] = value;
    setMainTenantData({ ...mainTenantData });
    setMainTenantDataSubmit({ ...mainTenantDataSubmit });
  };

  const onHandleSubmit = evt => {
    evt.preventDefault();
    modifyTenant({ id: id || idState, tenantProfile: { ...dataSubmit }, ...mainTenantDataSubmit });
  };

  const onHandleUpload = ({ files }) => {
    if (files && files[0]) {
      const query =
        '{"query": "mutation{uploadMultipartFiles(input: {category: TENANT, tenantSubType: LOGO}){absoluteFile}}"}';
      const formData = new FormData();
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          getTenantProfileConfig();
          setFileUploaded(files[0]);
          setOpenModalUpload(false);
          setisUploaded(true);
        }
      });
    } else {
      setOpenModalUpload(false);
    }
  };

  const onToggleModalUpload = path => {
    if (path) {
      readFileFromS3({ ...s3Config, url: path }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            setBase64FileSelect(result);
            setOpenModalUpload(true);
            setItemSelect(path);
            setFileSelect(data);
          });
          setFileSelect(data);
          setItemSelect(path);
        }
      });
    } else {
      setOpenModalUpload(true);
      setItemSelect(path);
    }
  };

  const { city, state, country } = tenantData;

  let tenantField = cloneDeep(dataField.tenant);
  if (modeSetLincenseData === 0 || modeModifyLicenseData === 0) {
    tenantField = tenantField.filter(item => item.name !== 'licenseKey');
  }

  return (
    <div className="card card-statistics">
      <TitleFrom title={t('tenantPage:tenantManagementPage.tenantData')} />
      <div className="card-body">
        <form className="card-body" onSubmit={onHandleSubmit}>
          <div className="col-md-12 row mb-2 ml-2 mr-2">
            <h6 className="col-md-12 border-bottom mb-2 mt-3">{t('label.tenantMasterData')}</h6>
            <div className="p-2 col-md-12 row">
              {map(tenantField, item => (
                <GenericInput
                  key={item.name}
                  value={mainTenantData && mainTenantData[item.name] ? mainTenantData[item.name] : ''}
                  label={item.label || ''}
                  onChange={onChangeMainData}
                  disabled={modeModifyLicenseData === 1 && item.name === 'licenseKey'}
                  name={item.name}
                  required={isRequireField}
                  wrapperClass="col-md-4"
                />
              ))}
            </div>
          </div>
          <div className="col-md-12 row mb-2 ml-2 mr-2">
            <h6 className="col-md-12 border-bottom mb-2 mt-3">{t('label.tenantProfile')}</h6>
            <div className="p-2 col-md-12 row">
              {map(getAddressesForm({ country, state, city, addresses: dataField.companyProfile }), item => {
                if (item && !isEmpty(item.optionSelect)) {
                  return (
                    <GenericInput
                      key={item.labelFilter}
                      label={item.labelFilter || ''}
                      type="select"
                      value={tenantData[item.fieldSelect] || null}
                      wrapperClass="col-md-4"
                      options={item.optionSelect}
                      disabled={modeModifyCompanyProfile === 1}
                      onChange={onChange}
                      name={item.fieldSelect}
                      required={item.required || isRequireField}
                    />
                  );
                }

                if (item && !isEmpty(item.fa)) {
                  return (
                    <GenericInput
                      key={item.labelFilter}
                      label={item.labelFilter || ''}
                      value={
                        tenantData[item.fieldName]
                          ? tenantData[item.fieldName] || (fileUploaded && fileUploaded.name)
                          : ''
                      }
                      onChange={({ name, value }) => {}}
                      wrapperClass="col-md-4"
                      name={item.fieldName}
                      {...item}
                      onClick={() => onToggleModalUpload(tenantData[item.fieldName])}
                    />
                  );
                }

                return (
                  <GenericInput
                    key={item.labelFilter}
                    value={tenantData[item.fieldName] || ''}
                    label={item.labelFilter || ''}
                    disabled={modeModifyCompanyProfile === 1}
                    type={item.isNumber ? 'number' : 'text'}
                    required={item.required || isRequireField}
                    onChange={onChange}
                    name={item.fieldName}
                    wrapperClass="col-md-4"
                  />
                );
              })}
            </div>
          </div>
          <div className="col-md-12 row mb-2 ml-2 mr-2">
            <h6 className="col-md-12 border-bottom mb-2 mt-3">{t('label.tenantSupport')}</h6>
            <div className="p-2 col-md-12 row">
              {map(tenantSupportFields, item => {
                if (item && !isEmpty(item.optionSelect)) {
                  return (
                    <GenericInput
                      key={item.labelFilter}
                      label={item.labelFilter || ''}
                      type="select"
                      value={tenantData[item.fieldSelect] || null}
                      wrapperClass="col-md-4"
                      options={item.optionSelect}
                      disabled={modeModifyCompanyProfile === 1}
                      onChange={onChange}
                      name={item.fieldSelect}
                      required={item.required || isRequireField}
                    />
                  );
                }

                if (item && !isEmpty(item.fa)) {
                  return (
                    <GenericInput
                      key={item.labelFilter}
                      label={item.labelFilter || ''}
                      value={
                        tenantData[item.fieldName]
                          ? tenantData[item.fieldName] || (fileUploaded && fileUploaded.name)
                          : ''
                      }
                      onChange={({ name, value }) => {}}
                      wrapperClass="col-md-4"
                      name={item.fieldName}
                      {...item}
                      onClick={() => onToggleModalUpload(tenantData[item.fieldName])}
                    />
                  );
                }

                return (
                  <GenericInput
                    key={item.labelFilter}
                    value={tenantData[item.fieldName] || ''}
                    label={item.labelFilter || ''}
                    disabled={modeModifyCompanyProfile === 1}
                    type={item.isNumber ? 'number' : 'text'}
                    required={item.required || isRequireField}
                    onChange={onChange}
                    name={item.fieldName}
                    wrapperClass="col-md-4"
                  />
                );
              })}
            </div>
          </div>
          {/* {base64FileSelect ? (
            <ModalViewMedia
              isOpen={isOpenModalUpload}
              base64FileSelect={base64FileSelect}
              fileSelect={fileSelect}
              onCancel={() => setOpenModalUpload(false)}
              type="IMAGE"
            />
          ) : (
            <ModalUploadFile
              title="label.uploadFile"
              isOpen={isOpenModalUpload}
              onCancel={() => setOpenModalUpload(false)}
              onUpload={onHandleUpload}
              itemSelect={itemSelect}
              base64FileSelect={base64FileSelect}
              fileSelect={fileSelect}
              // isDisabledUpload={!!base64FileSelect}
              type="IMAGE"
              isDisabledUpload={isUploaded}
            />
          )} */}
          <ModalUploadFile
            title="label.uploadFile"
            isOpen={isOpenModalUpload}
            onCancel={() => setOpenModalUpload(false)}
            onUpload={onHandleUpload}
            itemSelect={itemSelect}
            base64FileSelect={base64FileSelect}
            fileSelect={fileSelect}
            // isDisabledUpload={!!base64FileSelect}
            type="IMAGE"
          />
          <div className="mt-2 mb-2">
            {modeModifyCompanyProfile === 2 && (
              <button type="submit" className="button button-border ml-2 x-small float-right">
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => getTenantConfig()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CompanyProfile.propTypes = {
  modifyTenant: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  modeModifyCompanyProfile: PropTypes.number,
  isRequireField: PropTypes.bool,
};

CompanyProfile.defaultProps = {
  modifyTenant: () => {},
  data: {},
  modeModifyCompanyProfile: 2,
  isRequireField: false,
};

const mapStateToProps = createStructuredSelector({
  data: makeTenantProfileConfig() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getTenantProfileConfig })(CompanyProfile));
