import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../components/PricingHub/SearchComponent';

class ThirdPartySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.serviceType',
      name: 'serviceType',
    },
    {
      label: 'label.transactionName',
      name: 'transactionType',
    },
    // {
    //   label: 'label.description',
    //   name: 'description',
    // },
  ];

  btnSpan = 'col-md-6';
}

ThirdPartySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ThirdPartySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ThirdPartySearchForm);
