import gql from 'graphql-tag';

export default id => {
  const getArOpsRequestById = `getArOpsRequestById(id: "${id}")`;
  return gql`
    query {
      ${getArOpsRequestById} {
        id
        accountId
        userId
        invoiceId
        transactionId
        itemId
        notes
        customReason
        ticketNumber
        startDate
        taxCode
        satCode
        status
        numberOfTransactions
        amount
        percent
        generateCreditNoteNow
        type
        crDrType
        source
        reason
        taxRule
        accountWriteoff
        action
        settledAmount
        settlementDate
        lines {
          index
          name
          amount
          percent
          transactionId
        }
      }
    }
  `;
};
