import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectDisscountOfferId } from '../../../containers/App/actions';
import ModalMultipleSelector from '../../Modals/ModalMultipleSelector';
import { ModalDisscountOfferSearchForm } from '../../PricingHub';

class ModalMultipleSelectDisscountOffer extends Component {
  state = {};

  componentDidMount() {}

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
      ],
    };

    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      columns,
      headerTitle,
      fieldDisableCheck,
      isSupportSelectAll,
      selectDisscountOfferId,
      isMultipleOptionInject,
      optionsInject,
      defaultFilter,
      addFirstParamsField,
      defaultSearchParams,
    } = this.props;
    return (
      <ModalMultipleSelector
        modalTitle={headerTitle || t('label.chooseDisscountOffer')}
        isOpen={isOpen}
        SearchForm={ModalDisscountOfferSearchForm}
        searchItems={selectDisscountOfferId}
        tableColumns={columns || tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        fieldDisableCheck={fieldDisableCheck}
        isSupportSelectAll={isSupportSelectAll}
        isMultipleOptionInject={isMultipleOptionInject}
        optionsInject={optionsInject}
        defaultFilter={defaultFilter}
        addFirstParamsField={addFirstParamsField}
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalMultipleSelectDisscountOffer.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedBundleId: PropTypes.any,
  selectDisscountOfferId: PropTypes.func,
  errorMessage: PropTypes.string,
  defaultSearchParams: PropTypes.object,
};

export default withTranslation('common')(connect(null, { selectDisscountOfferId })(ModalMultipleSelectDisscountOffer));
