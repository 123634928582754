import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getBIExtract = `getBIExtract(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBIExtract} {
        clientId
        commercialName
        serviceName
        serviceStatus
        dischargeDate
        releaseDate
        cancelledDate
        nextCutoffDate
        omsInternalId
        orderId
        reasonCode
        completedDate
        incrementedAmount
        finalAmount
        oldAmount
        serviceId
      }
    }
  `;
};
