import gql from 'graphql-tag';

export default () => gql`
  query {
    getAccumulatorsConfig(input: { id: "" }) {
      id
      accumulatorList {
        id
        index
        accumulatorid
        symbol
        name
        roundingMethod
        roundingPrecision
        accumulateQuantity
        expression
      }
    }
  }
`;
