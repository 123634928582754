import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'daysInAdvance',
    'recurringFeeAdvanceDom',
    'accountType',
    'billingDom',
    'monthOffset',
    'month',
  ]);
  const modifyInAdvanceBillingConfig = `modifyInAdvanceBillingConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyInAdvanceBillingConfig} {
      id
    }
  }
`;
};
