import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DataTable, TablePagination } from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { searchRevenueMilestone } from '../../actions';
import { getPageTotalCount } from '../../../../utils/utils';
import { MilestoneSearchForm } from '../../../../components/RevenueHub';
import {
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
} from '../../../App/actions';
import { sortRevenue } from '../../constants';
import { makeGetRevenueMilestone } from '../../selectors';

class MilestoneIndex extends React.PureComponent {
  state = {
    data: [],
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    productFamilyConfig: [],
    productLineConfig: [],
    productSubTypeConfig: [],
    productTypeConfig: [],
  };

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.revenueMilestone,
      totalCount: getPageTotalCount({ ...state, items: props.revenueMilestone }),
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchRevenueMilestone();
      });
    } else {
      this.doSearchRevenueMilestone();
    }
    this.doGetListProductConfig();
  }

  doGetListProductConfig = () => {
    const { getUniqueProductFamily, getUniqueProductLine, getUniqueProductSubType, getUniqueProductType } = this.props;
    this.setState({ productFamily: '', productType: '', productSubType: '', productLine: '' });
    getUniqueProductFamily('', ({ data, success }) => {
      if (success)
        this.setState({
          productFamilyConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productFamily, value: val.productFamily })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductLine('', ({ data, success }) => {
      if (success)
        this.setState({
          productLineConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productLine, value: val.productLine })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductSubType('', ({ data, success }) => {
      if (success)
        this.setState({
          productSubTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productSubType, value: val.productSubType })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductType('', ({ data, success }) => {
      if (success)
        this.setState({
          productTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productType, value: val.productType })),
                  ['label']
                )
              : [],
        });
    });
  };

  doSearchRevenueMilestone = () => {
    const { searchRevenueMilestone } = this.props;
    const { filter, page, size, sorted } = this.state;
    if (!filter.status) delete filter.status;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortRevenue[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchRevenueMilestone(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchRevenueMilestone());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchRevenueMilestone());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchRevenueMilestone());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchRevenueMilestone());
  };

  render() {
    const { t, permissions, location } = this.props;
    const { modeGetMilestoneConfig, modeModifyMilestoneConfig, modeCreateMilestoneConfig } = permissions;
    if (!modeGetMilestoneConfig) return '';
    const {
      data,
      totalCount,
      sorted,
      isSearching,
      page,
      size,
      productFamilyConfig,
      productLineConfig,
      productSubTypeConfig,
      productTypeConfig,
      filter,
    } = this.state;
    const tableColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) =>
          modeModifyMilestoneConfig ? (
            <Link
              className="text-success"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.revenueConfigurationMilestoneModify.path, item.id),
                state: { filter },
              }}
            >
              {item.id}
            </Link>
          ) : (
            <span>{item.id}</span>
          ),
      },
      {
        name: 'name',
        label: 'label.name',
        sortable: true,
      },
      {
        name: 'description',
        label: 'label.description',
      },
      {
        name: 'productFamily',
        label: 'label.productFamily',
        sortable: true,
      },
      {
        name: 'productLine',
        label: 'label.productLine',
        sortable: true,
      },
      {
        name: 'productType',
        label: 'label.productType',
        sortable: true,
      },
      {
        name: 'productSubType',
        label: 'label.productSubType',
        sortable: true,
      },
      {
        name: 'currency',
        label: 'label.currency',
        sortable: true,
      },
      {
        name: 'country',
        label: 'label.country',
        sortable: true,
      },
      {
        name: 'status',
        label: 'label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:milestoneStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'type',
        label: 'label.serviceType',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'unit',
        label: 'label.unit',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:milestoneUnits')().find(val => val.value === item.unit) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'offsetval',
        label: 'label.offsetValue',
      },
      // {
      //   name: 'frequency',
      //   label: 'label.frequency',
      // },
      {
        name: 'view',
        label: t('label.view'),
        render: (colName, item) =>
          modeModifyMilestoneConfig ? (
            <Link
              className="btn btn-outline-success btn-sm"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.revenueConfigurationMilestoneModify.path, item.id),
                state: { filter },
              }}
            >
              {t('common:label.view')}
            </Link>
          ) : (
            ''
          ),
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={
            modeCreateMilestoneConfig === 2
              ? { pathname: RouteNames.revenueConfigurationMilestoneCreate.path, state: { filter } }
              : RouteNames.revenueConfiguration.path
          }
          titleBtn={modeCreateMilestoneConfig === 2 ? t('label.createNew') : t('label.back')}
          titleRight={t('revenuePage:sidebar.milestone')}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <MilestoneSearchForm
                    onSubmit={this.onHandleSubmit}
                    productFamilyConfig={productFamilyConfig}
                    productLineConfig={productLineConfig}
                    productSubTypeConfig={productSubTypeConfig}
                    productTypeConfig={productTypeConfig}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                columns={tableColumns}
                sorted={sorted}
                data={data}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
            </div>
          </div>
        </div>

        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

MilestoneIndex.propTypes = {
  searchRevenueMilestone: PropTypes.func,
  getUniqueProductFamily: PropTypes.func.isRequired,
  getUniqueProductLine: PropTypes.func.isRequired,
  getUniqueProductSubType: PropTypes.func.isRequired,
  getUniqueProductType: PropTypes.func.isRequired,
};

MilestoneIndex.defaultProps = {
  searchRevenueMilestone: () => {},
};

const mapStateToProps = createStructuredSelector({
  revenueMilestone: makeGetRevenueMilestone() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchRevenueMilestone,
    getUniqueProductFamily,
    getUniqueProductLine,
    getUniqueProductSubType,
    getUniqueProductType,
  })(withRouter(MilestoneIndex))
);
