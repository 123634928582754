import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomerAttributePage from '../../CustomerPage/CustomerAttribute';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';

class CustomAttributes extends React.PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.searchAndList.path}
          titleBtn={t('common:label.back')}
          items={[
            {
              name: t('navbar:customerHub.subMain.orderManagement'),
            },
            {
              name: t('common:label.customAttributes'),
            },
          ]}
        />
        <CustomerAttributePage isComponent type="ORDER" />
      </div>
    );
  }
}

CustomAttributes.propTypes = {};

CustomAttributes.defaultProps = {};

export default withTranslation('common')(CustomAttributes);
