import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import arrayMove from 'array-move';
import dataField from '../constant';
import { parseToMutationRequestPostMethod, getBase64 } from '../../../utils/utils';
import { GenericInput, FormCollapse, DataTablDragDrop, ModalUploadFile } from '../../../components/common';

class SelfCareMakerting extends PureComponent {
  state = {
    activeTab: {},
    data: { mediaFiles: [], advertisements: [], keyLinks: [] },
    defaultData: { mediaFiles: [], advertisements: [], keyLinks: [] },
    isOpen: false,
    fileSelect: null,
    base64FileSelect: null,
  };

  componentDidMount() {
    this.getSelfCareConfig();
  }

  getSelfCareConfig = () => {
    const { getTenantSelfCareConfig } = this.props;
    getTenantSelfCareConfig('', ({ data, success }) => {
      if (data && success) {
        this.setState({
          data: data[0],
          defaultData: data[0],
          isCreate: false,
        });
      } else {
        this.setState({ isCreate: true });
      }
    });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onAddOtherImage = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    if (newData.mediaFiles && newData.mediaFiles.length) {
      newData.mediaFiles.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    } else {
      newData.mediaFiles = [];
    }
    newData.mediaFiles.push({
      index: indexNewItem + 1,
      title: null,
      type: 'IMAGE',
      name: null,
      path: null,
    });
    this.setState({ data: newData });
  };

  onChangeMediaFile = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.mediaFiles[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveMediaFile = ({ index }) => {
    try {
      const { data, defaultData } = this.state;
      const { modifyTenantSelfCareConfig } = this.props;
      if (defaultData.mediaFiles && (!defaultData.mediaFiles || !defaultData.mediaFiles[index])) {
        const newData = cloneDeep(data);
        newData.mediaFiles.splice(index, 1);
        this.setState({ data: newData });
      } else {
        const formData = new FormData();
        formData.append(
          'graphql',
          `{"query": "mutation{modifyTenantSelfCareConfig(input: ${parseToMutationRequestPostMethod(
            { id: data.id, file: { index: data.mediaFiles[index].index } },
            // { id: data.id, file: [{ index: 2 }] },
            ['index', 'fileType']
          )}){id, tenantId}}"}`
        );
        modifyTenantSelfCareConfig(formData, () => this.getSelfCareConfig());
        // const newData = cloneDeep(data);
        // newData.mediaFiles[index] = { index: newData.mediaFiles[index].index };
        // this.setState({ data: newData });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSortEndAdvertisement = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const orderedJobList = arrayMove(data.advertisements, oldIndex, newIndex);
    newData.advertisements = orderedJobList;
    this.setState({
      data: newData,
      isSorting: false,
      sortingItemKey: null,
    });
  };

  onRemoveAdvertisement = ({ index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      const findIndex = newData.advertisements.findIndex(val => val.index === index);
      newData.advertisements[findIndex] = { index: newData.advertisements[findIndex].index };
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddAdvertisement = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    if (newData.advertisements) {
      newData.advertisements.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    } else {
      newData.advertisements = [];
    }
    newData.advertisements.push({
      index: indexNewItem + 1,
      header: null,
      shortDescription: null,
    });
    this.setState({ data: newData });
  };

  onChangeTableAdvertisement = ({ index, name, value }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      const findIndex = newData.advertisements.findIndex(val => val.index === index);
      newData.advertisements[findIndex][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSortEndKeyLinksToShare = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const orderedJobList = arrayMove(data.keyLinks, oldIndex, newIndex);
    newData.keyLinks = orderedJobList;
    this.setState({
      data: newData,
      isSorting: false,
      sortingItemKey: null,
    });
  };

  onRemoveKeyLinksToShare = ({ index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      const findIndex = newData.keyLinks.findIndex(val => val.index === index);
      newData.keyLinks[findIndex] = { index: newData.keyLinks[findIndex].index };
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddKeyLinksToShare = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    if (newData.keyLinks) {
      newData.keyLinks.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    } else {
      newData.keyLinks = [];
    }
    newData.keyLinks.push({
      index: indexNewItem + 1,
      header: null,
      shortDescription: null,
    });
    this.setState({ data: newData });
  };

  onChangeTableKeyLinksToShare = ({ index, name, value }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      const findIndex = newData.keyLinks.findIndex(val => val.index === index);
      newData.keyLinks[findIndex][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { data, isCreate } = this.state;
    const { modifyTenantSelfCareConfig, saveSelfcareConfig } = this.props;
    if (!isCreate) {
      const formData = new FormData();
      formData.append(
        'graphql',
        `{"query": "mutation{modifyTenantSelfCareConfig(input: ${parseToMutationRequestPostMethod(
          { id: data.id, keyLinks: data.keyLinks, advertisements: data.advertisements },
          // { id: data.id, file: [{ index: 2 }] },
          ['index', 'fileType']
        )}){id, tenantId}}"}`
      );
      modifyTenantSelfCareConfig(formData, () => this.getSelfCareConfig());
    } else {
      const formData = new FormData();
      formData.append(
        'graphql',
        `{"query": "mutation{SaveTenantSelfCareConfig(input: ${parseToMutationRequestPostMethod(
          {
            keyLinks: data.keyLinks.map(val => {
              const { index, ...rest } = val;
              return rest;
            }),
            advertisements: data.advertisements.map(val => {
              const { index, ...rest } = val;
              return rest;
            }),
            file: { fileType: 'IMAGE' },
          },
          ['index', 'fileType']
        )}){id, tenantId}}"}`
      );
      saveSelfcareConfig(formData, () => this.getSelfCareConfig());
    }
  };

  onToggleModalUpload = itemSelect => {
    const { isOpen } = this.state;
    const { readFileFromS3, s3Config } = this.props;
    if (itemSelect.path) {
      readFileFromS3({ ...s3Config, url: itemSelect.path }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            this.setState({ base64FileSelect: result, isOpen: !isOpen, itemSelect });
          });
          this.setState({ fileSelect: data, itemSelect });
        }
      });
    } else {
      this.setState({ isOpen: !isOpen, itemSelect });
    }
  };

  onCancel = () => {
    this.setState({ base64FileSelect: null, fileSelect: null, isOpen: false, itemSelect: null });
  };

  onHandleUpload = ({ files }) => {
    const { itemSelect, isOpen, data } = this.state;
    const { modifyTenantSelfCareConfig } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      formData.append(
        'graphql',
        `{"query": "mutation{modifyTenantSelfCareConfig(input: {id: \\"${data.id}\\", file: {index: ${itemSelect.index}, fileType: ${itemSelect.type}}}){id, tenantId}}"}`
      );
      formData.append('file', files[0]);
      modifyTenantSelfCareConfig(formData, res => {
        if (res && res.success) {
          this.getSelfCareConfig();
          this.setState({ isOpen: !isOpen, itemSelect: null });
        }
      });
    } else {
      this.setState({ isOpen: !isOpen, itemSelect: null });
    }
  };

  renderItem = item => {
    const { t, modeModifyPropertyDefaults } = this.props;
    const {
      data: { mediaFiles, advertisements, keyLinks },
    } = this.state;
    const headerTableAdvertisement = [
      {
        name: 'header',
        label: 'label.advHeader',
        style: { textAlign: 'center', minWidth: '110px' },
        render: (colName, item) => (
          <GenericInput
            value={item.header}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableAdvertisement({ name, value, index: item.index })}
            name="header"
          />
        ),
      },
      {
        name: 'shortDescription',
        label: 'label.advShortDescription',
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.shortDescription}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableAdvertisement({ name, value, index: item.index })}
            name="shortDescription"
            type="textarea"
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveAdvertisement({ index: item.index })}
                disabled={modeModifyPropertyDefaults !== 2}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const keyLinksToShare = [
      {
        name: 'text',
        label: 'label.linkText',
        render: (colName, item) => (
          <GenericInput
            value={item.text}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableKeyLinksToShare({ name, value, index: item.index })}
            name="text"
          />
        ),
      },
      {
        name: 'url',
        label: 'label.URL',
        style: { textAlign: 'center', minWidth: '110px' },
        render: (colName, item) => (
          <GenericInput
            value={item.url}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableKeyLinksToShare({ name, value, index: item.index })}
            name="url"
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveKeyLinksToShare({ index: item.index })}
                disabled={modeModifyPropertyDefaults !== 2}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    switch (item.state) {
      case 'Media File':
        return (
          <div className="col-md-12 pt-2 pl-2 pb-4">
            {mediaFiles &&
              mediaFiles.map((img, index) => {
                if (Object.keys(img).length < 2) return '';
                return (
                  <div className="col-md-12 row">
                    <div className="col-md-11 row">
                      {item.dataFields.map(val => {
                        return (
                          <GenericInput
                            key={val.label}
                            value={img ? img[val.name] : ''}
                            type={val.isNumber ? 'number' : 'text'}
                            onChange={({ name, value }) => this.onChangeMediaFile({ name, value, index })}
                            wrapperClass="col-md-4"
                            {...val}
                            onClick={val.fa ? () => this.onToggleModalUpload(img) : null}
                          />
                        );
                      })}
                      <div className="col-md-1 mt-3 ml-2">
                        <button
                          type="button"
                          disabled={modeModifyPropertyDefaults !== 2}
                          className="btn-phone"
                          onClick={() => this.onRemoveMediaFile({ index })}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div>
              <br />
              <button type="button" className="button button-border black x-small" onClick={this.onAddOtherImage}>
                +
                {t('label.addAnotherImage')}
              </button>
            </div>
          </div>
        );
      case 'Advertisement':
        return (
          <div className="col-md-12 pt-2 pl-2 pb-4">
            <DataTablDragDrop
              columns={headerTableAdvertisement}
              data={advertisements}
              onSortEnd={this.onSortEndAdvertisement}
            />
            <div>
              <br />
              <button type="button" className="button button-border black x-small" onClick={this.onAddAdvertisement}>
                +
                {t('label.addAnotherAdv')}
              </button>
            </div>
          </div>
        );
      case 'Key Links to Share':
        return (
          <div className="col-md-12 pt-2 pl-2 pb-4">
            <DataTablDragDrop columns={keyLinksToShare} data={keyLinks} onSortEnd={this.onSortEndKeyLinksToShare} />
            <div>
              <br />
              <button type="button" className="button button-border black x-small" onClick={this.onAddKeyLinksToShare}>
                +
                {t('label.addAnotherAdv')}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { t, modeModifyPropertyDefaults } = this.props;
    const { activeTab, isOpen, itemSelect, base64FileSelect, fileSelect } = this.state;
    return (
      <form className="" onSubmit={this.onHandleSubmit}>
        {dataField.collapsibleSelfCareMenu.map(item => (
          <FormCollapse
            key={item.title}
            label="First Name"
            isActive={item.state === activeTab.name && activeTab.isActive}
            title={t(item.title) || ''}
            state={item.state}
            onToggleTab={this.onToggleTab}
          >
            {this.renderItem(item)}
          </FormCollapse>
        ))}
        <div className="mt-3 pb-4">
          {modeModifyPropertyDefaults === 2 && (
            <button type="submit" className="button button-border ml-2 x-small float-right">
              {t('label.saveConfig')}
            </button>
          )}
          <button
            onClick={this.getSelfCareConfig}
            type="button"
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </div>
        <ModalUploadFile
          title="label.uploadFile"
          isOpen={isOpen}
          onCancel={this.onCancel}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          base64FileSelect={base64FileSelect}
          fileSelect={fileSelect}
          type={itemSelect ? itemSelect.type : null}
        />
      </form>
    );
  }
}

SelfCareMakerting.propTypes = {
  onHandleChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  modeModifyPropertyDefaults: PropTypes.number,
  isRequireField: PropTypes.bool,
};

SelfCareMakerting.defaultProps = {
  onHandleChange: () => {},
  data: {},
  modeModifyPropertyDefaults: 2,
  isRequireField: false,
};

export default withTranslation('common')(SelfCareMakerting);
