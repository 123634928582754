import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 2,
    columns: [],
    data: [[{ value: title || 'ACCOUNT STATEMENT', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.common, // description
      columns.common, // taxCode
      columns.common, // quantity
      columns.common, // taxableAmount
      columns.common, // ieps
      columns.common, // iva
      columns.common, // startDate
      columns.common, // endDate
    ],
    data: [
      [
        {
          value: t('label.name'),
          style: styleHeader,
        },
        {
          value: t('label.quantity'),
          style: styleHeader,
        },
        {
          value: t('label.amount'),
          style: styleHeader,
        },
        {
          value: t('label.taxCode'),
          style: styleHeader,
        },
        {
          value: t('label.ieps'),
          style: styleHeader,
        },
        {
          value: t('label.iva'),
          style: styleHeader,
        },
        {
          value: t('label.startDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.description ? item.description : '' });
      newItem.push({ value: formatMoneyValueToExport(item.quantity) });
      newItem.push({ value: formatMoneyValueToExport(item.taxableAmount) });
      newItem.push({ value: item.taxCode ? item.taxCode : '' });
      newItem.push({ value: formatMoneyValueToExport(item.ieps) });
      newItem.push({ value: formatMoneyValueToExport(item.iva) });
      newItem.push({ value: formatMoneyValueToExport(item.appliedStart) });
      newItem.push({ value: formatMoneyValueToExport(item.appliedEnd) });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
