import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import moment from 'moment';
import { DataTable, TablePagination, TitleFrom, CollapsibleTable, GenericInput } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import SavedManualBillsSearchForm from '../../../components/BillingHub/SavedManualBillsSearchForm';
import { getPageTotalCount } from '../../../utils/utils';
import { searchSavedManualInvoice, getPendingManualInvoice, processSavedManualInvoice } from '../actions';
import RouteNames from '../../App/RouteNames';
import { getCcpDateTimeConfig } from '../../App/actions';
import { makeGetPermissionsInvoice } from '../../App/selectors';
import { checkPermissionSearchSavedManualInvoice } from '../CheckPermission';

const billSort = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  clientId: {
    asc: 'clientId_ASC',
    desc: 'clientId_DESC',
  },
  scheduleDate: {
    asc: 'scheduleDate_ASC',
    desc: 'scheduleDate_DESC',
  },
};

const manualPendingBillingColumns = [
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
  },
  {
    name: 'itemId',
    label: 'label.itemId',
  },
  {
    name: 'serviceName',
    label: 'label.billableServiceName',
  },
  {
    name: 'costCenter',
    label: 'label.costCenter',
  },
  {
    name: 'description',
    label: 'label.description',
  },
  {
    name: 'customDescription',
    label: 'label.customDescription',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'currency',
    label: 'label.currency',
  },
  {
    name: 'unitPrice',
    label: 'label.unitPrice',
  },
  {
    name: 'grossAmount',
    label: 'label.grossAmount',
  },
  {
    name: 'discountAmount',
    label: 'label.discountAmount',
  },
  {
    name: 'netAmount',
    label: 'label.netAmount',
  },
];

const SavedManualBills = ({
  searchSavedManualInvoice,
  getPendingManualInvoice,
  getCcpDateTimeConfig,
  processSavedManualInvoice,
  permissionsInvoice,
}) => {
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [activeSubTab, setActiveSubTab] = useState({});
  const [manualPreratedTxnRec, setManualPreratedTxnRec] = useState([]);
  const [ccpTime, setCcpTime] = useState('');
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { t } = useTranslation('common');

  let modeSearchSavedManualInvoice = 0;

  if (permissionsInvoice && permissionsInvoice.invoicingModulePermissions) {
    const listPermission = permissionsInvoice.invoicingModulePermissions;
    modeSearchSavedManualInvoice = checkPermissionSearchSavedManualInvoice({
      listPermission,
    });
  }

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doSearchSavedManualInvoice = useCallback(() => {
    setLoading(true);
    const newFilter = cloneDeep(filter);
    searchSavedManualInvoice(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? billSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if (success && data) setData(data);
      }
    );
  }, [filter, searchSavedManualInvoice, page, size, sorted]);

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const doGetPendingManualInvoice = ({ item }) => {
    getPendingManualInvoice({ id: item.accountId, savedInvoiceId: item.savedInvoiceId }, ({ success, data }) => {
      if (success) {
        setManualPreratedTxnRec(data && data[0] && data[0]?.manualPreratedTxnRec ? data[0]?.manualPreratedTxnRec : []);
      }
    });
  };

  const onToggleSubTab = (index, item, indexItem, key) => {
    if (activeSubTab.index === indexItem) setActiveSubTab({});
    if (activeSubTab.index !== indexItem) {
      doGetPendingManualInvoice({ item });
      setActiveSubTab({ key, index: indexItem });
    }
  };

  const onSelectItem = ({ index, value }) => {
    try {
      const newData = cloneDeep(data);
      newData[index].isChecked = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectAllItem = ({ value }) => {
    try {
      let newData = cloneDeep(data);
      newData = newData.map(val => {
        const { isChecked, ...rest } = val;
        let isCheckedItem = false;
        if (value && rest.scheduleDate && ccpTime && moment(ccpTime).isAfter(rest.scheduleDate)) {
          isCheckedItem = true;
        }
        if (value && !rest.scheduleDate) {
          isCheckedItem = true;
        }
        if (rest.status !== 'PENDING' && rest.status !== 'ERRORED') {
          isCheckedItem = false;
        }
        if (!value) {
          isCheckedItem = false;
        }
        return {
          ...rest,
          isChecked: isCheckedItem,
        };
      });
      setData(newData);
      setIsSelectAll(!isSelectAll);
    } catch (error) {
      console.log(error);
    }
  };

  const doProcessSavedManualInvoice = () => {
    const payload = [];
    const newData = cloneDeep(data);
    if (newData && newData.length) {
      newData.forEach(val => {
        if (val.isChecked) {
          payload.push({ savedInvoiceId: val.savedInvoiceId });
        }
      });
    }
    processSavedManualInvoice({ embrixData: payload }, ({ success }) => {
      if (success) {
        doSearchSavedManualInvoice();
        setIsSelectAll(false);
        setActiveSubTab({});
        setManualPreratedTxnRec([]);
      }
    });
  };

  useEffect(() => {
    doSearchSavedManualInvoice();
  }, [doSearchSavedManualInvoice]);

  useEffect(() => {
    getCcpDateTimeConfig('', ({ success, data }) => {
      if (success) {
        setCcpTime(data.ccpTime);
      }
    });
  }, [getCcpDateTimeConfig]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  if (!modeSearchSavedManualInvoice) return null;

  const tableSavedManualBills = [
    {
      name: 'select',
      label: 'label.select',
      render: (colName, item, index) => (
        <GenericInput
          value={item && item.isChecked}
          name="isSelectItem"
          type="checkbox"
          wrapperClass="col-md-4"
          onChange={({ value }) => onSelectItem({ item, index, value })}
          disabled={
            (ccpTime && item?.scheduleDate && moment(ccpTime).isBefore(item.scheduleDate)) ||
            (item.status !== 'PENDING' && item.status !== 'ERRORED') ||
            modeSearchSavedManualInvoice === 1
          }
        />
      ),
    },
    {
      name: 'lines',
      label: 'label.lines',
      style: { width: 100 },
      render: (colName, item, idx, indexParent, activeTab) => (
        <button type="button" className="btn-expand-table mr-3" onClick={evt => onToggleSubTab(idx, item, idx)}>
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
    // {
    //   name: 'savedInvoiceId',
    //   label: 'label.id',
    // },
    {
      name: 'scheduleDate',
      label: 'label.scheduleDate',
      sortable: true,
    },
    {
      name: 'accountId',
      label: 'label.accountId',
      sortable: true,
    },
    {
      name: 'commercialName',
      label: 'label.commercialName',
    },
    {
      name: 'clientId',
      label: 'label.clientId',
      sortable: true,
    },
    {
      name: 'billStartDate',
      label: 'label.billStartDate',
    },
    {
      name: 'billEndDate',
      label: 'label.billEndDate',
    },
    {
      name: 'errorReason',
      label: 'label.errorReason',
    },
    {
      name: 'status',
      label: 'label.status',
      sortable: true,
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:manualInvoiceStatus')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'substituteInvoice',
      label: 'label.substituteInvoice',
      render: (colName, item) => {
        return <span>{item.substituteInvoice ? 'TRUE' : item.substituteInvoice === false ? 'FALSE' : null}</span>;
      },
    },
    {
      name: 'invoiceId',
      label: 'label.invoiceId',
    },
    {
      name: 'substitutedInvoiceStatus',
      label: 'label.substitutedInvoiceStatus',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t
          ? t('selections:invoiceUnitStatus')().find(val => val.value === item.substitutedInvoiceStatus)
          : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'accountCategory',
      label: 'label.accountCategory',
    },
  ];

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoicingBilling.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
          { name: t('label.searchSavedManualBills') },
        ]}
      />
      <br />
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <TitleFrom title={t('label.searchSavedManualBills')} />
          <br />
          <div className="card-body">
            <div className="repeater-file">
              <div>
                <SavedManualBillsSearchForm onSubmit={onHandleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-statistics h-100 mr-3">
        <br />
        <div className="col-md-12 pt-2 pb-2 d-flex">
          <GenericInput
            value={isSelectAll}
            name="isSelectAll"
            type="checkbox"
            wrapperClass="mr-2 mt-2"
            onChange={({ value }) => onSelectAllItem({ value })}
            label={t('label.selectAll')}
            disabled={modeSearchSavedManualInvoice === 1}
          />
          <button
            className="button x-small mr-2"
            disabled={!data || !data.length || !data.find(val => !!val.isChecked)}
            type="button"
            onClick={doProcessSavedManualInvoice}
          >
            {t('label.process')}
          </button>
        </div>
        <div>
          <div className="group-collapsible">
            <CollapsibleTable
              indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
              columns={tableSavedManualBills}
              data={data || []}
              isLoading={isLoading}
              sorted={sorted}
              isViewOnly
              onSort={onSortColumn}
            >
              <DataTable columns={manualPendingBillingColumns} data={manualPreratedTxnRec || []} />
            </CollapsibleTable>
          </div>
        </div>
      </div>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  permissionsInvoice: makeGetPermissionsInvoice() || {},
});

SavedManualBills.propTypes = {
  searchSavedManualInvoice: PropTypes.func.isRequired,
  getPendingManualInvoice: PropTypes.func.isRequired,
  getCcpDateTimeConfig: PropTypes.func.isRequired,
  processSavedManualInvoice: PropTypes.func.isRequired,
};

SavedManualBills.defaultProps = {};

export default connect(mapStateToProps, {
  searchSavedManualInvoice,
  getPendingManualInvoice,
  getCcpDateTimeConfig,
  processSavedManualInvoice,
})(SavedManualBills);
