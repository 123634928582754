import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, TitleFrom } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getEnterprise, modifyEnterprise, createEnterprise } from '../actions';
import { listFieldsEnterprise } from '../constants';
import ModalCompanyLocation from '../../../components/common/ModalCompanyLocation';

class Enterprise extends React.PureComponent {
  state = {
    data: {},
    wasValidated: false,
    isModify: false,
    formValid: true,
    isOpenModal: false,
    activeTab: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetEnterprises();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetEnterprises = () => {
    const { getEnterprise } = this.props;
    getEnterprise('', ({ data }) => {
      this.setState({
        data: data || {},
        isModify: !!data,
        dataSubmit: data ? { id: data.id } : {},
      });
    });
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit } = this.state;
    const { modifyEnterprise, createEnterprise } = this.props;
    const { type, address, ...payload } = cloneDeep(data);
    if (isModify) {
      return modifyEnterprise({ ...dataSubmit }, ({ success }) => {
        if (success) this.doGetEnterprises();
      });
    }
    return createEnterprise({ ...payload }, ({ success }) => {
      if (success) this.doGetEnterprises();
    });
  };

  onHandleChange = ({ name, value }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    let newValue = value;
    if (value === 'TRUE') newValue = true;
    if (value === 'FALSE') newValue = false;
    newData[name] = newValue;
    dataSubmit[name] = newValue;
    this.setState({ data: newData, dataSubmit });
  };

  onOpenPopup = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  onSelectItem = (index, row) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    if (index) {
      newData.locationIndex = index;
      newData.address = row;
      dataSubmit.locationIndex = index;
    }
    if (!index) {
      newData.locationIndex = null;
      newData.address = null;
      dataSubmit.locationIndex = null;
    }
    this.setState({ data: newData, isOpenModal: false, dataSubmit });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateEnterpriseConfig, modeGetEnterpriseConfig, modeModifyEnterpriseConfig } = permissions;
    if (!modeGetEnterpriseConfig) return '';
    const { data, activeTab, wasValidated, isModify, isOpenModal } = this.state;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.enterprise')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.enterprise')} />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation pb-3 ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="col-md-12 pl-2 pt-2 pb-2 pr-0 row">
                {listFieldsEnterprise.map(val => {
                  if (val.name === 'locationIndex') {
                    return (
                      <GenericInput
                        value={
                          data.address ? `${data.address.city}, ${data.address.state}, ${data.address.country}` : ''
                        }
                        wrapperClass="col-md-4"
                        type={val.tOptions ? 'select' : 'text'}
                        onChange={({ name, value }) => this.onHandleChange({ value, name })}
                        onClick={val.fa ? this.onOpenPopup : () => {}}
                        {...val}
                      />
                    );
                  }
                  return (
                    <GenericInput
                      value={val.isBoolean ? (data[val.name] ? 'TRUE' : 'FALSE') : data[val.name]}
                      wrapperClass="col-md-4"
                      type={val.tOptions ? 'select' : 'text'}
                      onChange={({ name, value }) => this.onHandleChange({ value, name })}
                      onClick={val.fa ? this.onOpenPopup : () => {}}
                      {...val}
                    />
                  );
                })}
              </div>
              <div className="form-group col-md-12 buttons-attibute pt-4 p">
                {((isModify && modeModifyEnterpriseConfig === 2) ||
                  (!isModify && modeCreateEnterpriseConfig === 2)) && (
                  <button type="submit" className="button button-border x-small float-right">
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetEnterprises}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ModalCompanyLocation
          isOpen={isOpenModal}
          onSelect={this.onSelectItem}
          selectedParentId={data.locationIndex}
          onCancel={() => {
            this.setState({ isOpenModal: false });
          }}
        />
      </div>
    );
  }
}

Enterprise.propTypes = {
  getEnterprise: PropTypes.func,
  modifyEnterprise: PropTypes.func,
};

Enterprise.defaultProps = {
  getEnterprise: () => {},
  modifyEnterprise: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getEnterprise,
    modifyEnterprise,
    createEnterprise,
  })(withRouter(Enterprise))
);
