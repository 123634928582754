/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getAccountItems } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import ItemSearchForm from './ItemSearchForm';

class ModalAccountItems extends PureComponent {
  searchHanldeInvoiceId = (payload, cb) => {  
    const { getAccountItems, accountId, invoiceUnitId } = this.props;
    const newPayload = {};
    if (accountId) newPayload.accountId = accountId || '';
    if (invoiceUnitId) newPayload.invoiceUnitId = invoiceUnitId || '';
    if (payload && payload.filter && payload.filter.endDate) newPayload.endDate = payload.filter.endDate || '';
    if (payload && payload.filter && payload.filter.startDate) newPayload.startDate = payload.filter.startDate || '';
    getAccountItems(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'invoiceUnitId',
          label: 'common:label.invoiceUnitId',
        },
        {
          name: 'itemId',
          label: 'common:label.itemId',
        },
        {
          name: 'name',
          label: 'common:label.name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'totalAmount',
          label: 'common:label.amount',
        },
        {
          name: 'productFamily',
          label: 'common:label.productFamily',
        },
        {
          name: 'productLine',
          label: 'common:label.productLine',
        },
        {
          name: 'productType',
          label: 'common:label.productType',
        },
        {
          name: 'productSubType',
          label: 'common:label.productSubType',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseItem')}
        isOpen={isOpen}
        SearchForm={ItemSearchForm}
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName="itemId"
        isNotRenderPagination
        isNeedFetch={isOpen}
      />
    );
  }
}

ModalAccountItems.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getAccountItems: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalAccountItems.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getAccountItems })(ModalAccountItems));
