import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropsType from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import arrayMove from 'array-move';
import RouteName from '../../App/RouteNames';
import { getApprovals, modifyApprovals } from '../actions';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import {
  GenericInput,
  TitleFrom,
  Breadcrumb,
  CollapsibleTable,
  DataTablDragDrop,
  QuickNotesConfig,
} from '../../../components/common';
import ModalUserGroup from '../../../components/common/ModalUserGroup';
import {
  checkPermissionGetConfigApprovals,
  checkPermissionModifyConfigApprovals,
  checkPermissionCreateConfigApprovals,
} from '../CheckPermission';

const Approvals = ({ getApprovals, modifyApprovals, permissionsArOps }) => {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState(-1);
  const [itemGroupSelect, setItemGroupSelect] = useState(null);
  const [indexGroupSelect, setIndexGroupSelect] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState(null);
  const [wasValidated, setValidate] = useState(false);
  const formRef = useRef();

  const doGetUserGroups = useCallback(() => {
    getApprovals(({ success, data }) => {
      if (success && data && data.id) {
        setData(data.approvalObjects);
        setId(data.id);
      }
    });
  }, [getApprovals]);

  useEffect(() => {
    doGetUserGroups();
  }, [doGetUserGroups, getApprovals]);

  let modeCreateConfigApprovals = 0;
  let modeModifyConfigApprovals = 0;
  let modeGetConfigApprovals = 0;

  if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
    const listPermission = permissionsArOps.arOpsModulePermissions;
    modeCreateConfigApprovals = checkPermissionCreateConfigApprovals({ listPermission });
    modeModifyConfigApprovals = checkPermissionModifyConfigApprovals({ listPermission });
    modeGetConfigApprovals = checkPermissionGetConfigApprovals({ listPermission });
  }
  if (!modeGetConfigApprovals) return '';

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onRemoveUser = ({ index }) => {
    const newData = cloneDeep(data);
    newData[activeTab].approvalLevels[index] = {
      index: newData[activeTab].approvalLevels[index].index,
    };
    newData[activeTab].isModified = true;
    setData(newData);
  };

  const onHandleAddApproval = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData[activeTab].approvalLevels) newData[activeTab].approvalLevels = [];
    newData[activeTab].approvalLevels.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData[activeTab].approvalLevels.push({
      index: lastIndex + 1,
      userGroupId: '',
      approvalRequired: 'true',
      approvalCorrespondence: 'true',
      rejectionCorrespondence: 'true',
    });
    setData(newData);
  };

  const onSelect = (id, row) => {
    try {
      const newData = cloneDeep(data);
      const checkExistItem = newData[activeTab].approvalLevels.find(val => val.userGroupId === row.groupId);
      if (checkExistItem) return toast.error(t('message.messageGroupIdExist'));
      newData[activeTab].approvalLevels[indexSelect].userGroupId = row && row.groupId ? row.groupId : '';
      newData[activeTab].approvalLevels[indexSelect].userGroupName = row && row.groupName ? row.groupName : '';
      newData[activeTab].isModified = true;
      setData(newData);
      setIsOpen(false);
    } catch (error) {
      setIsOpen(false);
    }
  };

  const onToggleTab = (index, item, indexItem) => {
    if (activeTab === indexItem) setActiveTab(-1);
    if (activeTab !== indexItem) {
      setActiveTab(indexItem);
      setItemGroupSelect(item);
      setIndexGroupSelect(index);
    }
  };

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      newData[activeTab].approvalLevels[index][name] = newValue;
      newData[activeTab].isModified = true;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const headerTable = [
    {
      name: 'objectType ',
      label: 'label.objectType',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.objectType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="id"
          readOnly
        />
      ),
    },
    {
      name: 'reasonCode',
      label: 'label.reasonCode',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.reasonCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="reasonCode"
        />
      ),
    },
    {
      name: 'customReasonCode',
      label: 'label.customReason',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.customReasonCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="customReasonCode"
        />
      ),
    },
    {
      name: '',
      label: '',
      style: { width: 100 },
      render: (colName, item, idx, indexParent, activeTab) => (
        <button type="button" className="btn-expand-table mr-3">
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
  ];

  const headerTableUser = [
    {
      name: 'userGroupId',
      label: 'label.userGroupId',
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userGroupId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="userGroupId"
          fa="fa fa-external-link"
          onClick={() => {
            setIsOpen(true);
            setItemSelect(item);
            setIndexSelect(idx);
          }}
          required
        />
      ),
    },
    {
      name: 'userGroupName',
      label: 'label.userGroupName',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userGroupName || item.groupName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="userGroupName"
          readOnly
        />
      ),
    },
    {
      name: 'approvalRequired',
      label: 'label.approvalRequired',
      render: (colName, item, idx) => (
        <GenericInput
          value={
            item.approvalRequired === 'true' || item.approvalRequired === true || item.approvalRequired === null
              ? 'TRUE'
              : 'FALSE'
          }
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="approvalRequired"
          type="select"
          tOptions="selections:selectBool"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'approvalCorrespondence',
      label: 'label.approvalCorrespondence',
      render: (colName, item, idx) => (
        <GenericInput
          value={
            item.approvalCorrespondence === 'true' ||
            item.approvalCorrespondence === true ||
            item.approvalCorrespondence === null
              ? 'TRUE'
              : 'FALSE'
          }
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="approvalCorrespondence"
          type="select"
          tOptions="selections:selectBool"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'rejectionCorrespondence',
      label: 'label.rejectionCorrespondence',
      render: (colName, item, idx) => (
        <GenericInput
          value={
            item.rejectionCorrespondence === 'true' ||
            item.rejectionCorrespondence === true ||
            item.rejectionCorrespondence === null
              ? 'TRUE'
              : 'FALSE'
          }
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="rejectionCorrespondence"
          type="select"
          tOptions="selections:selectBool"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveUser({ index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onSortEndAdvertisement = ({ oldIndex, newIndex }) => {
    const newData = cloneDeep(data);
    newData[activeTab].isModified = true;
    const orderedJobList = arrayMove(newData[activeTab].approvalLevels, oldIndex, newIndex);
    newData[activeTab].approvalLevels = orderedJobList;
    setData(newData);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    const groupsPayload = data
      .filter(val => !!val.isModified)
      .map(val => {
        const { id, isModified, approvalLevels, ...rest } = val;
        const payloadApprovalLevels = [];
        if (approvalLevels && approvalLevels.length) {
          approvalLevels
            .filter(val => !!val?.userGroupId)
            .forEach((u, index) => {
              if (u.userGroupId) {
                payloadApprovalLevels.push({
                  userGroupId: u.userGroupId,
                  index: index + 1,
                  approvalRequired: u.approvalRequired === null ? 'true' : u.approvalRequired,
                  approvalCorrespondence: u.approvalCorrespondence === null ? 'true' : u.approvalCorrespondence,
                  rejectionCorrespondence: u.rejectionCorrespondence === null ? 'true' : u.rejectionCorrespondence,
                });
              }
            });
          approvalLevels.forEach(att => {
            if (att.index > payloadApprovalLevels.length) payloadApprovalLevels.push({ index: att.index });
          });
        }
        return {
          ...rest,
          approvalLevels: payloadApprovalLevels,
        };
      });
    const newPayload = {
      id: id || '',
      approvalObjects: groupsPayload,
    };
    return modifyApprovals(newPayload, ({ success }) => {
      if (success) doGetUserGroups();
    });
  };
  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            {/* <NavLink
              className="button x-small"
              to={
                data && data[0] ? RouteName.userManagementApprovalModify.path : RouteName.userManagementApprovalAdd.path
              }
            >
              {t('label.createNewApproval')}
            </NavLink> */}
            {modeCreateConfigApprovals === 2 && (
              <NavLink
                className="button x-small"
                to={
                  data && data[0]
                    ? RouteName.userManagementApprovalModify.path
                    : RouteName.userManagementApprovalAdd.path
                }
              >
                {t('label.createNewApproval')}
              </NavLink>
            )}
            {modeCreateConfigApprovals !== 2 && (
              <NavLink className="button x-small" to={RouteName.userManagementRole.path}>
                {t('label.back')}
              </NavLink>
            )}
            <QuickNotesConfig />
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                {
                  name: t('userManagementPage:sidebar.approvals'),
                  url: RouteName.userManagementApproval.path,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <form
        ref={formRef}
        noValidate
        onSubmit={onSubmit}
        className={`col-md-12 p-2 card card-statistics needs-validation ${wasValidated ? 'was-validated' : ''}`}
      >
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('userManagementPage:sidebar.approvals')} />
          </div>
        </div>
        <CollapsibleTable
          columns={headerTable}
          data={data && data.length ? data : []}
          indexViewer={activeTab}
          onClickRow={onToggleTab}
        >
          <br />
          <div className="col-md-12">
            <DataTablDragDrop
              columns={headerTableUser}
              isSupportRemoveIndex
              data={data && data[activeTab] && data[activeTab].approvalLevels ? data[activeTab].approvalLevels : []}
              onSortEnd={onSortEndAdvertisement}
            />
          </div>
          <div className="form-group col-md-12 buttons-attibute">
            <button type="button" className="button button-border black x-small" onClick={onHandleAddApproval}>
              +
              {t('label.addAnotherApprovalLevel')}
            </button>
          </div>
        </CollapsibleTable>
        <div className="form-group col-md-12 buttons-attibute border-top">
          {modeModifyConfigApprovals === 2 && (
            <button
              type="submit"
              className="button button-border x-small float-right ml-2"
            >
              {t('label.modify')}
            </button>
          )}
          <button
            type="button"
            onClick={() => doGetUserGroups()}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </div>
      </form>
      <ModalUserGroup
        isOpen={isOpen}
        onSelect={onSelect}
        onCancel={() => setIsOpen(false)}
        selectedParentId={itemSelect ? itemSelect.groupId : null}
      />
    </div>
  );
};

Approvals.propTypes = {
  getApprovals: PropsType.func.isRequired,
  modifyApprovals: PropsType.bool.isRequired,
};

Approvals.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default connect(mapStateToProps, { getApprovals, modifyApprovals })(Approvals);
