import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class OperatorRecordLogSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'processStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'processStartEnd',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.operator',
      name: 'operator',
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:operatorFileStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

OperatorRecordLogSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OperatorRecordLogSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OperatorRecordLogSearchForm);
