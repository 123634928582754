import React from 'react';
import { Link } from 'react-router-dom';
import RouteNames from '../App/RouteNames';
import { countryList } from '../../constantsApp/countryList';
import selectConstant from '../../utils/selectConstant';
import { formatMoneyValue } from '../../utils/utils';
import { setAccountId, setCurrency } from '../../localStorages';
import listCurrency from '../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const tableColumnsSerachAccount = [
  {
    name: 'selected',
    label: 'common:label.selected',
    isRenderT: true,
    render: (colName, item, t) => {
      return (
        <Link to={RouteNames.paymentOneOff.path}>
          <button
            type="button"
            onClick={() => {
              setAccountId(item.id);
              setCurrency(item.currency);
            }}
            className="button x-small btn-select-next"
          >
            {t('common:label.next')}
          </button>
        </Link>
      );
    },
  },
  {
    name: 'id',
    label: 'common:label.ACCTNo',
    render: (colName, item) => (
      <Link className="text-success" to={RouteNames.prettifyPath(RouteNames.customerInfo.path, item.id)}>
        {item.id}
      </Link>
    ),
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
    sortable: true,
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].firstName : null;
    },
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
    sortable: true,
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].lastName : null;
    },
  },
  {
    name: 'organization',
    label: 'common:label.organization',
    sortable: true,
    style: { minWidth: '110px' },
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
    },
  },
  {
    name: 'email',
    label: 'common:label.email',
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].email : null;
    },
  },
  {
    name: 'city',
    label: 'common:label.city',
    sortable: true,
    render: (name, item) => {
      return item && item.addresses && item.addresses[0] ? item.addresses[0].city : null;
    },
  },
  {
    name: 'state',
    label: 'common:label.state',
    sortable: true,
    style: { minWidth: '90px' },
    render: (name, item) => {
      return item && item.addresses && item.addresses[0] ? item.addresses[0].state : null;
    },
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const tableColumnsSerachPayment = [
  {
    name: 'paymentSuspenseId',
    label: 'common:label.paymentSuspenseId',
  },
  {
    name: 'paymentDate',
    label: 'common:label.paymentDate',
    sortable: true,
  },
  {
    name: 'createdDate',
    label: 'common:label.createdDate',
    sortable: true,
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    sortable: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'folioStatus',
    label: 'label.folioStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'sourceCurrency',
    label: 'label.paymentCurrency',
  },
  {
    name: 'amount',
    label: 'common:label.paymentAmount',
    sortable: true,
  },
  // {
  //   name: 'sourceCurrencyAmount',
  //   label: 'label.sourceCurrencyAmount',
  // },
  {
    name: 'remainingAmount',
    label: 'common:label.remainingAmount',
  },
  {
    name: 'fileName',
    label: 'common:label.paymentFile',
  },
  {
    name: 'fileDate',
    label: 'common:label.paymentFileDate',
    sortable: true,
    // sortable: true,
  },
  {
    name: 'paymentTransactionType',
    label: 'label.paymentTxtType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t
        ? t('selections:paymentTransactionType')().find(val => val.value === item.paymentTransactionType)
        : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'paymentSource',
    label: 'label.paymentSource',
  },
  // {
  //   name: 'paymentTerm',
  //   label: 'label.paymentTerm',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:paymentTerm')().find(val => val.value === item.paymentTerm) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  // },
  {
    name: 'currency',
    label: 'label.accountCurrency',
  },
  {
    name: 'invoiceUnitId',
    label: 'label.invoiceUnitId',
  },
  {
    name: 'userId',
    label: 'label.userId',
  },
  // {
  //   name: 'suspenseFlag',
  //   label: 'label.suspenseFlag',
  //   render: (colName, item) => {
  //     return <span>{item.suspenseFlag ? 'TRUE' : 'FALSE'}</span>;
  //   },
  // },
  {
    name: 'paymentDescription',
    label: 'label.description',
  },
  {
    name: 'exchangeRate',
    label: 'label.appliedExchangeRate',
    render: (colName, item) => {
      return <span>{item.exchangeRate}</span>;
    },
  },
  {
    name: 'currentDateExchangeRate',
    label: 'label.currentExchangeRate',
    render: (colName, item) => {
      return <span>{item.currentDateExchangeRate}</span>;
    },
  },
  {
    name: 'unRealizedGain',
    label: 'label.unrealizedLossGainInSystemCurrency',
    isSupportDangerouslySetInnerHTML: true,
    style: { textAlign: 'center' },
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.unRealizedGain)}</span>;
    },
  },
  {
    name: 'paymentDateExchangeRate',
    label: 'label.paymentDateExchangeRate',
  },
  // {
  //   name: 'transactionId',
  //   label: 'label.transactionId',
  // },

  // {
  //   name: 'accountId',
  //   label: 'common:label.ACCTNo',
  // },
  // {
  //   name: 'invoiceUnitId',
  //   label: 'common:label.invoiceId',
  //   sortable: true,
  // },
];

const tableColumnsManualAllocation = [
  {
    name: 'id',
    label: 'common:label.invoiceId',
  },
  {
    name: 'invoiceDate',
    label: 'common:label.invoiceDate',
  },
  {
    name: 'dueDate',
    label: 'common:label.dueDate',
  },
  {
    name: 'total',
    label: 'common:label.invoiceAmount',
  },
  {
    name: 'due',
    label: 'common:label.dueAmount',
  },
];

const dataFiledCreditCardPayment = [
  {
    label: 'common:label.last4CC',
    name: 'last4CC',
    required: true,
  },
  {
    label: 'common:label.cardToken',
    name: 'cardToken',
    required: true,
  },
  {
    label: 'common:label.cardExp',
    name: 'cardExpiry',
    required: true,
  },
  {
    label: 'common:label.oneOffAmount',
    name: 'amount',
    type: 'number',
    required: true,
  },
  // {
  //   label: 'common:label.merchant',
  //   name: 'merchant',
  //   required: true,
  // },
  {
    label: 'common:label.applyTo',
    name: 'invoiceUnitId',
    fa: 'fa fa-external-link',
  },
];

const dataFiledInvoicePayment = ({ batchFileOptions, data, isTimeAndBillingDeployment }) => {
  const listField = [
    {
      label: 'common:label.paymentDate',
      name: 'paymentDate',
      type: 'date',
      required: true,
    },
    {
      label: 'common:label.paymentCurrency',
      name: 'currency',
      type: 'select',
      options: currencyOptions,
      disabled: data?.bankAccount,
      required: true,
    },
    {
      label: 'common:label.paymentAmount',
      name: 'amount',
      type: 'number',
      required: true,
    },
    { label: 'common:label.groupId', name: 'groupId' },
    {
      label: 'common:label.selectInvoice',
      name: 'invoiceUnitId',
      fa: 'fa fa-external-link',
      // required: true,
    },
    {
      label: 'common:label.exchangeRateForReporting',
      name: 'exchangeRateForReport',
      type: 'number',
    },
    {
      label: 'common:label.currencyForReporting',
      name: 'currencyIdForReport',
      type: 'select',
      options: currencyOptions,
    },
    {
      label: 'common:label.paymentFile',
      name: 'bankAccount',
      type: 'select',
      options: batchFileOptions || [],
      disabled:
        data?.reference ||
        data?.paymentInvoices[0]?.bank ||
        data?.paymentInvoices[0]?.checkNumber ||
        data?.paymentInvoices[0]?.routingNumber,
    },
    {
      label: 'common:label.notes',
      name: 'note',
      disabled:
        data?.reference ||
        data?.paymentInvoices[0]?.bank ||
        data?.paymentInvoices[0]?.checkNumber ||
        data?.paymentInvoices[0]?.routingNumber,
      type: 'textarea',
    },
    {
      label: 'common:label.BANK',
      name: 'bank',
      disabled: data?.bankAccount || data?.reference || data?.note,
    },
    {
      label: 'common:label.chequeNumber',
      name: 'checkNumber',
      disabled: data?.bankAccount || data?.reference || data?.note,
    },
    {
      label: 'common:label.routingNumber',
      name: 'routingNumber',
      disabled: data?.bankAccount || data?.reference || data?.note,
    },
    {
      label: 'common:label.notesReference',
      name: 'reference',
      disabled:
        data?.bankAccount ||
        data?.paymentInvoices[0]?.bank ||
        data?.paymentInvoices[0]?.checkNumber ||
        data?.paymentInvoices[0]?.routingNumber ||
        data?.note,
      type: 'textarea',
    },
  ];

  if (isTimeAndBillingDeployment) {
    return [
      ...listField,
      // {
      //   label: 'common:label.exchangeRateForReporting',
      //   name: 'exchangeRateForReport',
      //   type: 'number',
      // },
      // {
      //   label: 'common:label.currencyForReporting',
      //   name: 'currencyIdForReport',
      //   type: 'select',
      //   options: currencyOptions,
      //   isClearable: true,
      // },
    ];
  }
  return [
    ...listField,
    // {
    //   label: 'common:label.selectInvoice',
    //   name: 'invoiceUnitId',
    //   fa: 'fa fa-external-link',
    //   // required: true,
    // },
  ];
};

const dataFiledAddress = {
  withAddressesUSForm: [
    {
      fieldName: 'street',
      labelFilter: 'common:label.street',
      required: true,
      type: 'textarea',
    },
    {
      fieldName: 'extraLine',
      labelFilter: 'common:label.extraLine',
    },
    {
      fieldName: 'landmark',
      labelFilter: 'common:label.landmark',
    },
    {
      labelFilter: 'common:label.country',
      fieldSelect: 'country',
      required: true,
      optionSelect: countryList,
    },
    {
      labelFilter: 'common:label.state',
      fieldSelect: 'state',
      required: true,
      optionSelect: selectConstant.stateUSData,
    },
    {
      labelFilter: 'common:label.city',
      fieldSelect: 'city',
      optionSelect: [],
      required: true,
      fieldName: 'city',
    },
    {
      labelFilter: 'common:label.postalCode',
      fieldSelect: 'postalCode',
      fieldName: 'postalCode',
      required: true,
      optionSelect: [],
    },
    {
      fieldName: 'code',
      labelFilter: 'common:label.code',
    },
  ],

  withAddressesNormalForm: [
    {
      fieldName: 'street',
      labelFilter: 'common:label.street',
      required: true,
      type: 'textarea',
    },
    {
      fieldName: 'extraLine',
      labelFilter: 'common:label.extraLine',
    },
    {
      fieldName: 'landmark',
      labelFilter: 'common:label.landmark',
    },
    {
      labelFilter: 'common:label.country',
      fieldSelect: 'country',
      required: true,
      optionSelect: countryList,
    },
    {
      fieldName: 'state',
      labelFilter: 'common:label.state',
      required: true,
    },
    {
      labelFilter: 'common:label.city',
      fieldName: 'city',
      required: true,
    },
    {
      fieldName: 'postalCode',
      labelFilter: 'common:label.postalCode',
      required: true,
    },
    {
      fieldName: 'code',
      labelFilter: 'common:label.code',
    },
  ],
};

const tableColumnsPaymentSuspense = [
  {
    name: 'createdDate',
    label: 'common:label.createdDate',
    sortable: true,
  },
  {
    name: 'paymentDate',
    label: 'common:label.paymentDate',
    sortable: true,
  },
  {
    name: 'status',
    label: 'common:label.suspenseStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'sourceCurrency',
    label: 'common:label.sourceCurrency',
  },
  {
    name: 'amount',
    label: 'common:label.paymentAmount',
    sortable: true,
  },
  {
    name: 'unallocatedAmount',
    label: 'common:label.unallocatedAmount',
  },
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'fileDate',
    label: 'common:label.paymentFileDate',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'common:label.organization',
  },
  {
    name: 'accountId',
    label: 'common:label.accountId',
    sortable: true,
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'paymentSource',
    label: 'label.paymentSource',
  },
  {
    name: 'failureReason',
    label: 'label.failureReason',
    sortable: true,
  },
  // {
  //   name: 'currency',
  //   label: 'common:label.currency',
  // },
  // {
  //   name: 'exchangeRate',
  //   label: 'label.exchangeRate',
  // },
  {
    name: 'invoiceUnitId',
    label: 'label.invoiceUnitId',
  },
  {
    name: 'userId',
    label: 'common:label.userId',
  },
  {
    name: 'paymentDescription',
    label: 'label.paymentDescription',
  },
  {
    name: 'currency',
    label: 'label.accountCurrency',
  },
  {
    name: 'exchangeRate',
    label: 'label.paymentDateExchangeRate',
    render: (colName, item) => {
      return <span>{item.exchangeRate}</span>;
    },
  },
  {
    name: 'currentDateExchangeRate',
    label: 'label.currentExchangeRate',
    render: (colName, item) => {
      return <span>{item.currentDateExchangeRate}</span>;
    },
  },
  {
    name: 'unRealizedGain',
    label: 'label.unrealizedLossGainInSystemCurrency',
    isSupportDangerouslySetInnerHTML: true,
    style: { textAlign: 'center' },
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.unRealizedGain)}</span>;
    },
  },
  // {
  //   name: 'accountId',
  //   label: 'common:label.ACCTNo',
  //   sortable: true,
  // },
  // {
  //   name: 'invoiceUnitId',
  //   label: 'common:label.invoiceId',
  //   sortable: true,
  // },
  {
    name: 'closeReason',
    label: 'common:label.closeSuspenseReason',
  },
];

const groupNamePermissions = {
  createPaymentTermsConfig: 'CREATE_PAYMENT_TERMS_CONFIG',
  modifyPaymentTermsConfig: 'MODIFY_PAYMENT_TERMS_CONFIG',
  createPaymentMethodConfig: 'CREATE_PAYMENT_METHOD_CONFIG',
  modifyPaymentMethodConfig: 'MODIFY_PAYMENT_METHOD_CONFIG',
  createPaymentMerchantConfig: 'CREATE_PAYMENT_MERCHANT_CONFIG',
  modifyPaymentMerchantConfig: 'MODIFY_PAYMENT_MERCHANT_CONFIG',
  getPaymentConfiguration: 'GET_PAYMENT_CONFIGURATION',
  processPayment: 'PROCESS_PAYMENT',
  processPaymentReversal: 'PROCESS_PAYMENT_REVERSAL',
  autoPaymentAllocation: 'AUTO_PAYMENT_ALLOCATION',
  manualPaymentAllocation: 'MANUAL_PAYMENT_ALLOCATION',
  searchPayment: 'SEARCH_PAYMENTS',
  searchPaymentReversals: 'SEARCH_PAYMENT_REVERSALS',
  searchPaymentSuspense: 'SEARCH_PAYMENT_SUSPENSE',
  processPaymentSuspense: 'PROCESS_PAYMENT_SUSPENSE',
  batchPaymentUpload: 'BATCH_PAYMENT_UPLOAD',
  batchPaymentProcess: 'BATCH_PAYMENT_PROCESS',
  getPaymentSurchargeConfig: 'GET_PAYMENT_SURCHARGE_CONFIG',
  modifyPaymentSurchargeConfig: 'MODIFY_PAYMENT_SURCHARGE_CONFIG',
  createPaymentSurchargeConfig: 'CREATE_PAYMENT_SURCHARGE_CONFIG',
  createBatchPaymentFileConfig: 'CREATE_BATCH_PAYMENT_FILE_CONFIG',
  modifyBatchPaymentFileConfig: 'MODIFY_BATCH_PAYMENT_FILE_CONFIG',
  getBatchPaymentFileConfig: 'GET_BATCH_PAYMENT_FILE_CONFIG',
  getPaymentItemMap: 'GET_PAYMENT_ITEM_MAP',
  modifyPaymentItemMap: 'MODIFY_PAYMENT_ITEM_MAP',
  createPaymentItemMap: 'CREATE_PAYMENT_ITEM_MAP',
  GET_PAYMENT_ALLOCATION_CONFIG: 'GET_PAYMENT_ALLOCATION_CONFIG',
  MODIFY_PAYMENT_ALLOCATION_CONFIG: 'MODIFY_PAYMENT_ALLOCATION_CONFIG',
  CREATE_PAYMENT_ALLOCATION_CONFIG: 'CREATE_PAYMENT_ALLOCATION_CONFIG',
  REGENERATE_PAYMENT_FOLIO: 'REGENERATE_PAYMENT_FOLIO',
  VIEW_AR_ACTIVITY_DETAILS: 'VIEW_AR_ACTIVITY_DETAILS',
  RESEND_TO_VENDOR: 'RESEND_TO_VENDOR',
};

const sortBatchPayment = {
  fileName: {
    asc: 'fileName_ASC',
    desc: 'fileName_DESC',
  },
  paymentType: {
    asc: 'paymentType_ASC',
    desc: 'paymentType_DESC',
  },
  paymentStatus: {
    asc: 'paymentStatus_ASC',
    desc: 'paymentStatus_DESC',
  },
  totalRecords: {
    asc: 'totalRecords_ASC',
    desc: 'totalRecords_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

const tableBanamexColumns = [
  {
    name: 'paymentRefId',
    label: 'label.paymentRefId',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    keyOptions: 'batchPaymentStatus',
    render: (colName, item, t) => {
      const slt = t ? t('selections:batchPaymentStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'amount',
    label: 'label.paymentAmount',
  },
  {
    name: 'suspenseAccountFlag',
    label: 'label.suspenseAccountFlag',
    isBool: true,
    render: (colName, item) => {
      return <span>{item.suspenseAccountFlag ? 'TRUE' : item.suspenseAccountFlag === false ? 'FALSE' : null}</span>;
    },
  },
  {
    name: 'paymentId',
    label: 'label.paymentId',
  },
  {
    name: 'recordNumber',
    label: 'label.recordNumber',
  },
  {
    name: 'recordDate',
    label: 'label.recordDate',
  },
  {
    name: 'paymentLimit',
    label: 'label.paymentLimit',
  },
  {
    name: 'reference1',
    label: 'label.reference1',
  },
  {
    name: 'reference2',
    label: 'label.reference2',
  },
  {
    name: 'paymentType',
    label: 'label.paymentType',
  },
  {
    name: 'box',
    label: 'label.box',
  },
  {
    name: 'withdrawal',
    label: 'label.withdrawal',
  },
  {
    name: 'balance',
    label: 'label.balance',
  },
  {
    name: 'authorizeCode',
    label: 'label.authorizeCode',
  },
  {
    name: 'identification',
    label: 'label.identification',
  },
  {
    name: 'batchId',
    label: 'label.batchId',
  },
  {
    name: 'processedDate',
    label: 'label.processedDate',
  },
  {
    name: 'fileName',
    label: 'label.fileName',
  },
];

const tableBanorteColumns = [
  {
    name: 'paymentRefId',
    label: 'label.paymentRefId',
  },
  {
    name: 'status',
    label: 'label.status',
    keyOptions: 'batchPaymentStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:batchPaymentStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'deposit',
    label: 'label.paymentAmount',
    render: (colName, item) => {
      return <span>{item.deposit ? item.deposit : ''}</span>;
    },
  },
  {
    name: 'suspenseAccountFlag',
    label: 'label.suspenseAccountFlag',
    render: (colName, item) => {
      return <span>{item.suspenseAccountFlag ? 'TRUE' : item.suspenseAccountFlag === false ? 'FALSE' : null}</span>;
    },
  },
  {
    name: 'paymentId',
    label: 'label.paymentId',
  },
  {
    name: 'bill',
    label: 'label.bill',
  },
  {
    name: 'operationDate',
    label: 'label.operationDate',
  },
  {
    name: 'recordDate',
    label: 'label.recordDate',
  },
  {
    name: 'reference',
    label: 'label.reference',
  },
  {
    name: 'description',
    label: 'label.description',
  },
  {
    name: 'transactionCode',
    label: 'label.transactionCode',
  },
  {
    name: 'branchOffice',
    label: 'label.branchOffice',
  },
  {
    name: 'withdrawal',
    label: 'label.withdrawal',
    render: (colName, item) => {
      return <span>{item.withdrawal ? item.withdrawal : ''}</span>;
    },
  },
  {
    name: 'balance',
    label: 'label.balance',
    render: (colName, item) => {
      return <span>{item.balance ? item.balance : ''}</span>;
    },
  },
  {
    name: 'movement',
    label: 'label.movement',
  },
  {
    name: 'detailedDescription',
    label: 'label.detailedDescription',
  },
  {
    name: 'cheque',
    label: 'label.cheque',
  },
  {
    name: 'batchId',
    label: 'label.batchId',
  },
  {
    name: 'processedDate',
    label: 'label.processedDate',
  },
  {
    name: 'fileName',
    label: 'label.fileName',
  },
];

const tableBancomerColumns = [
  {
    name: 'paymentRefId',
    label: 'label.paymentRefId',
  },
  {
    name: 'status',
    label: 'label.status',
    keyOptions: 'batchPaymentStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:batchPaymentStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'credit',
    label: 'label.paymentAmount',
  },
  {
    name: 'suspenseAccountFlag',
    label: 'label.suspenseAccountFlag',
    render: (colName, item) => {
      return <span>{item.suspenseAccountFlag ? 'TRUE' : item.suspenseAccountFlag === false ? 'FALSE' : null}</span>;
    },
  },
  {
    name: 'paymentId',
    label: 'label.paymentId',
  },
  {
    name: 'recordDate',
    label: 'label.recordDate',
  },
  {
    name: 'concept',
    label: 'label.concept',
  },
  {
    name: 'charge',
    label: 'label.charge',
  },
  {
    name: 'balance',
    label: 'label.balance',
  },
  {
    name: 'batchId',
    label: 'label.batchId',
  },
  {
    name: 'fileName',
    label: 'label.fileName',
  },
  {
    name: 'processedDate',
    label: 'label.processedDate',
  },
  {
    name: 'transactionId',
    label: 'label.transactionId',
  },
];

const tableSantanderColumns = [
  {
    name: 'paymentRefId',
    label: 'label.paymentRefId',
  },
  {
    name: 'status',
    label: 'label.status',
    keyOptions: 'batchPaymentStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:batchPaymentStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'credit',
    label: 'label.paymentAmount',
  },
  {
    name: 'suspenseAccountFlag',
    label: 'label.suspenseAccountFlag',
    render: (colName, item) => {
      return <span>{item.suspenseAccountFlag ? 'TRUE' : item.suspenseAccountFlag === false ? 'FALSE' : null}</span>;
    },
  },
  {
    name: 'paymentId',
    label: 'label.paymentId',
  },
  {
    name: 'account',
    label: 'label.account',
  },
  {
    name: 'recordDate',
    label: 'label.recordDate',
  },
  {
    name: 'recordTime',
    label: 'label.recordTime',
  },
  {
    name: 'description',
    label: 'label.description',
  },
  {
    name: 'chargeSign',
    label: 'label.chargeSign',
  },
  {
    name: 'balance',
    label: 'label.balance',
  },
  {
    name: 'referenceId',
    label: 'label.referenceId',
  },
  {
    name: 'conceptBank',
    label: 'label.conceptBank',
  },
  {
    name: 'participantName',
    label: 'label.participantName',
  },
  {
    name: 'beneficiaryName',
    label: 'label.beneficiaryName',
  },
  {
    name: 'beneficiary',
    label: 'label.beneficiary',
  },
  {
    name: 'payingAccount',
    label: 'label.payingAccount',
  },
  {
    name: 'payingName',
    label: 'label.payingName',
  },
  {
    name: 'refundCode',
    label: 'label.refundCode',
  },
  {
    name: 'refundReason',
    label: 'label.refundReason',
  },
  {
    name: 'beneficiaryRfc',
    label: 'label.beneficiaryRfc',
  },
  {
    name: 'originatorRfc',
    label: 'label.originatorRfc',
  },
  {
    name: 'trackingKey',
    label: 'label.trackingKey',
  },
  {
    name: 'batchId',
    label: 'label.batchId',
  },
  {
    name: 'fileName',
    label: 'label.fileName',
  },
  {
    name: 'processedDate',
    label: 'label.processedDate',
  },
];

const messageNotes = t => <div dangerouslySetInnerHTML={{ __html: t('message.messgaeNotePaymentSuspense') }} />;

export {
  tableColumnsSerachAccount,
  dataFiledCreditCardPayment,
  dataFiledInvoicePayment,
  dataFiledAddress,
  tableColumnsSerachPayment,
  tableColumnsManualAllocation,
  tableColumnsPaymentSuspense,
  groupNamePermissions,
  sortBatchPayment,
  tableBanamexColumns,
  tableBanorteColumns,
  tableBancomerColumns,
  tableSantanderColumns,
  messageNotes,
};
