import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  DataTable,
  TablePagination,
  SwitchExport,
  ButtonExport,
  EmailGroup,
  ExcelExport,
  ModalWithItem,
  DragDropConfigExportTemplate,
} from '../../../components/common';
import {
  uploadMultiPartFiles,
  getCurrencyConfigApp,
  saveOutputTemplateByType,
  getOutputTemplateByType,
} from '../../App/actions';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { searchAccountingEntry, searchAllAccountingEntry } from '../actions';
import {
  getPageTotalCount,
  validateEmail,
  parseToMutationRequestPostMethod,
  blobToFile,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../utils/utils';
import { AccountingLogSearchForm } from '../../../components/RevenueHub';
import { sortAccountingLog } from '../constants';
import { makeGetAccountingLogs, makeGetListAllAccountingEntry } from '../selectors';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';
import { makeGetCurrencyConfig } from '../../App/selectors';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';

let tableColumnsOutput = null;
let tableColumnsDefault = null;
class AccountingLog extends React.PureComponent {
  state = {
    data: [],
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    emails: [],
    currencyOptions: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.accountingLogs,
      totalCount: getPageTotalCount({ ...state, items: props.accountingLogs }),
    };
  }

  componentDidMount() {
    this.doSearchAccountingEntry();
    this.doGetOutputTemplateByType();
    const { currencyOptions } = this.state;
    const { getCurrencyConfigApp, currencyConfig } = this.props;
    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        label: val.name,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }
  }

  doSearchAccountingEntry = () => {
    const { searchAccountingEntry } = this.props;
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (newFilter.accountingPeriod) {
      newFilter.accountingPeriod = moment(newFilter.accountingPeriod, 'MM-YYYY')
        .format('MMM-YYYY')
        .toUpperCase();
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortAccountingLog[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchAccountingEntry(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchAccountingEntry());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchAccountingEntry());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchAccountingEntry());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchAccountingEntry());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  doGetAccountingEntry = cb => {
    const { filter, sorted } = this.state;
    const { searchAllAccountingEntry } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter,
      sort: !isEmpty(sorted) ? sortAccountingLog[sorted.sortCol][sorted.sortDir] : null,
    };
    searchAllAccountingEntry(payload, () => {
      if (cb) cb();
    });
  };

  onExport = () => {
    this.doGetAccountingEntry(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllAccountingEntry, t } = this.props;
        convertJson2Pdf({
          data: listAllAccountingEntry,
          t,
          title: t('revenuePage:sidebar.accountingLog').toLocaleUpperCase(),
          fileName: `${t('revenuePage:sidebar.accountingLog')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAccountingEntry(() => {
      const { listAllAccountingEntry, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_ACCOUNTING_LOG',
          subject: subject || `Embrix Report ${t('revenuePage:sidebar.accountingLog')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('revenuePage:sidebar.accountingLog')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllAccountingEntry,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              journalStatus: 'revenueJournalStatus',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllAccountingEntry,
          t,
          title: t('revenuePage:sidebar.accountingLog').toLocaleUpperCase(),
          fileName: `${t('revenuePage:sidebar.accountingLog')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('revenuePage:sidebar.accountingLog')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('ACCOUNTING_LOG', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const { t, listAllAccountingEntry } = this.props;
    // const modeGetMilestoneConfig = 2;
    // const modeModifyMilestoneConfig = 2;
    // const modeCreateMilestoneConfig = 2;
    // const { modeGetMilestoneConfig, modeModifyMilestoneConfig, modeCreateMilestoneConfig } = permissions;
    // if (!modeGetMilestoneConfig) return '';
    const {
      data,
      totalCount,
      sorted,
      isSearching,
      page,
      size,
      isActivePdf,
      emails,
      currencyOptions,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const tableColumns = [
      {
        name: 'createdDate',
        label: 'label.date',
        sortable: true,
      },
      {
        name: 'batchId',
        label: 'label.batchId',
      },
      {
        name: 'journalStatus',
        label: 'label.status',
        fieldOption: 'revenueJournalStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:revenueJournalStatus')().find(val => val.value === item.journalStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'drGlAccount',
        label: 'label.drGlAccount',
        sortable: true,
      },
      {
        name: 'drAmount',
        label: 'label.drAmount',
        sortable: true,
      },
      {
        name: 'crGlAccount',
        label: 'label.crGlAccount',
        sortable: true,
      },
      {
        name: 'crAmount',
        label: 'label.crAmount',
        sortable: true,
      },
      {
        name: 'drCurrency',
        label: 'label.drCurrency',
        sortable: true,
      },
      {
        name: 'drAccountType',
        label: 'label.drAccountType',
        sortable: true,
      },
      {
        name: 'crCurrency',
        label: 'label.crCurrency',
        sortable: true,
      },
      {
        name: 'crAccountType',
        label: 'label.crAccountType',
        sortable: true,
      },
      {
        name: 'objectType',
        label: 'label.objectType',
        sortable: true,
      },
      {
        name: 'objectId',
        label: 'label.objectId',
        sortable: true,
      },
      {
        name: 'journalId',
        label: 'label.journalId',
      },
      {
        name: 'accountingcode',
        label: 'label.accountingcode',
      },
      {
        name: 'offsetAccountingCode',
        label: 'label.offsetAccountingCode',
      },
    ];

    tableColumnsDefault = tableColumns;
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    const modeDownloadReport = 2;
    const modeGetMailReport = 2;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.accountingLog.path}
          titleBtn={t('label.back')}
          titleRight={t('revenuePage:sidebar.accountingLog')}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body col-md-12">
              <div className="repeater-file">
                <div>
                  <AccountingLogSearchForm
                    isMultipleOptionInject
                    optionsInject={{
                      drCurrency: currencyOptions,
                      crCurrency: currencyOptions,
                    }}
                    onSubmit={this.onHandleSubmit}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                <button
                  type="submit"
                  onClick={this.onToggleModalExportConfig}
                  className="button x-small mr-2 btn-config ml-2"
                >
                  {/* {t('label.outputConfig')} */}
                  <i className="ti-list" />
                </button>
                {!!modeDownloadReport && (
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                )}
                {!!modeDownloadReport && (
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('revenuePage:sidebar.accountingLog').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: listAllAccountingEntry,
                        t,
                        title: t('revenuePage:sidebar.accountingLog').toLocaleUpperCase(),
                        columnsTable: tableColumnsOutput,
                      })}
                      fileName={`${t('revenuePage:sidebar.accountingLog')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                )}
                {modeGetMailReport !== 0 && (
                  <EmailGroup
                    emails={emails}
                    onChangeEmail={this.onChangeEmail}
                    sendEmail={this.sendEmail}
                    data={data}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                columns={tableColumnsOutput}
                sorted={sorted}
                data={data}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
            </div>
          </div>
        </div>

        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

AccountingLog.propTypes = {
  searchAccountingEntry: PropTypes.func,
};

AccountingLog.defaultProps = {
  searchAccountingEntry: () => {},
};

const mapStateToProps = createStructuredSelector({
  accountingLogs: makeGetAccountingLogs() || [],
  listAllAccountingEntry: makeGetListAllAccountingEntry() || [],
  currencyConfig: makeGetCurrencyConfig() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchAccountingEntry,
    searchAllAccountingEntry,
    uploadMultiPartFiles,
    getCurrencyConfigApp,
    saveOutputTemplateByType,
    getOutputTemplateByType,
  })(withRouter(AccountingLog))
);
