import { call, takeLatest, put } from 'redux-saga/effects';
import { queryRequest } from 'utils/request';
import { userLogin, getUserById } from 'api';
import { toast } from 'react-toastify';
import { isConnecting, isEndConnected } from 'containers/Loader/actions';
import {
  setId,
  setUserId,
  setPassword,
  setFirstTimeLogin,
  setDaysForPasswordChange,
  setEmail,
  setLastPasswordChangeDate,
} from '../../localStorages';
import * as types from './types';
import i18n from '../../i18n';

export function* userLoginSage({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, userLogin(payload)) || {};
    // let response = {userLogin: {id: 'pass', password: 'test', userId: 'userId'}}
    if (response.userLogin) {
      setId(response.userLogin.id);
      setUserId(response.userLogin.userId);
      setPassword(payload.password);
      setFirstTimeLogin(response.userLogin.firstTimeLogin);
      setDaysForPasswordChange(response.userLogin.daysForPasswordChange);
      setLastPasswordChangeDate(response.userLogin.lastPasswordChangeDate);

      const resUser = yield call(queryRequest, getUserById(response.userLogin.id)) || {};
      if (resUser && resUser.getUserById) {
        const email =
          resUser.getUserById.contact && resUser.getUserById.contact.length ? resUser.getUserById.contact[0].email : '';
        setEmail(email);
      }

      yield put(isEndConnected());
      yield cb({ success: true });
      // yield put(push(RouteNames.dashboard.path));
      // toast.success(`Login successfully`);
    } else {
      yield put(isEndConnected());
      // toast.error(i18n.t('common:apiMessage.usernamePasswordNotCorrect'));
      yield cb({ success: false });
    }
  } catch (err) {
    // toast.error(i18n.t('common:apiMessage.usernamePasswordNotCorrect'));
    yield put(isEndConnected());
    yield cb({ success: false });
  }
}

export default function* loginPageSaga() {
  yield takeLatest(types.LOGIN, userLoginSage);
}
