// -----item
export const GET_CHART_OF_ACCOUNT = 'app/pricinghub/get_chart_of_account';
export const SEARCH_ITEMS = 'app/pricinghub/search_items';
export const SEARCH_ITEMS_SUCCESS = 'app/pricinghub/search_items_success';
export const SEARCH_ITEMS_FAILURE = 'app/pricinghub/search_items_failure';

export const SEARCH_ITEMS_ID = 'app/pricinghub/search_items_id';

export const SET_PARAMS_ITEMS = 'app/items/set_params_items';

export const GET_ITEM_DETAIL = 'app/pricinghub/get_item_detail';
export const GET_ITEM_DETAIL_SUCCESS = 'app/pricinghub/get_item_detail_success';
export const GET_ITEM_DETAIL_FAILURE = 'app/pricinghub/get_item_detail_failure';

export const CREATE_ITEM = 'app/pricinghub/create_item';
export const CREATE_ITEM_SUCCESS = 'app/pricinghub/create_item_success';
export const CREATE_ITEM_FAILURE = 'app/pricinghub/create_item_failure';

// ----price-offer
export const SEARCH_PRICE_OFFERS = 'app/pricinghub/search_price_offers';
export const SEARCH_PRICE_OFFERS_SUCCESS = 'app/pricinghub/search_price_offers_success';
export const SEARCH_PRICE_OFFERS_FAILURE = 'app/pricinghub/search_price_offers_failure';

export const SET_PARAMS_PRICE_OFFERS = 'app/price_offers/set_params_price_offers';

export const GET_PRICE_OFFER_DETAIL = 'app/pricinghub/get_price_offer_detail';
export const GET_PRICE_OFFER_DETAIL_SUCCESS = 'app/pricinghub/get_price_offer_detail_success';
export const GET_PRICE_OFFER_DETAIL_FAILURE = 'app/pricinghub/get_price_offer_detail_failure';

export const CREATE_PRICE_OFFER = 'app/pricinghub/create_price_offer';
export const CREATE_PRICE_OFFER_SUCCESS = 'app/pricinghub/create_price_offer_success';
export const CREATE_PRICE_OFFER_FAILURE = 'app/pricinghub/create_price_offer_failure';

// for modify Item
export const MODIFY_ITEM = 'app/pricinghub/modify_item';
export const MODIFY_ITEM_SUCCESS = 'app/pricinghub/modify_item_success';

// for update item status
export const UPDATE_ITEM_STATUS = 'app/pricinghub/update_item_status';
export const UPDATE_ITEM_STATUS_SUCCESS = 'app/pricinghub/update_item_status_success';

// for modify price offer
export const MODIFY_PRICE_OFFER = 'app/pricinghub/modify_price_offer';
export const MODIFY_PRICE_OFFER_SUCCESS = 'app/pricinghub/modify_price_offer_success';

// for update price offer status
export const UPDATE_PRICE_OFFER_STATUS = 'app/pricinghub/update_price_offer_status';
export const UPDATE_PRICE_OFFER_STATUS_SUCCESS = 'app/pricinghub/update_price_offer_status_success';

export const GET_USAGE_CONTAINER_SPEC = 'app/pricinghub/GET_USAGE_CONTAINER_SPEC';

// ----price-offer
export const SEARCH_DISCOUNT_OFFERS = 'app/pricinghub/SEARCH_DISCOUNT_OFFERS';
export const SEARCH_DISCOUNT_OFFERS_SUCCESS = 'app/pricinghub/SEARCH_DISCOUNT_OFFERS_SUCCESS';

// Discount offer
export const CREATE_DISCOUNT_OFFER = 'app/pricinghub/CREATE_DISCOUNT_OFFER';
export const GET_DISCOUNT_OFFER_DETAIL = 'app/pricinghub/GET_DISCOUNT_OFFER_DETAIL';
export const GET_DISCOUNT_OFFER_DETAIL_SUCCESS = 'app/pricinghub/GET_DISCOUNT_OFFER_DETAIL_SUCCESS';
export const MODIFY_DISCOUNT_OFFER = 'app/pricinghub/MODIFY_DISCOUNT_OFFER';
export const UPDATE_DISCOUNT_OFFER_STATUS = 'app/pricinghub/UPDATE_DISCOUNT_OFFER_STATUS';

export const GET_PRODUCT_FAMILY_CONFIG = 'app/base-configurations/GET_PRODUCT_FAMILY_CONFIG';
export const GET_PRODUCT_FAMILY_CONFIG_SUCCESS = 'app/base-configurations/GET_PRODUCT_FAMILY_CONFIG_SUCCESS';
export const MODIFY_PRODUCT_FAMILY_CONFIG = 'app/base-configurations/MODIFY_PRODUCT_FAMILY_CONFIG';
export const CREATE_PRODUCT_FAMILY_CONFIG = 'app/base-configurations/CREATE_PRODUCT_FAMILY_CONFIG';

// Item Services
export const SEARCH_ITEM_SERVICE = 'app/base-configurations/SEARCH_ITEM_SERVICE';
export const SEARCH_ITEM_SERVICE_SUCCESS = 'app/base-configurations/SEARCH_ITEM_SERVICE_SUCCESS';
export const MODIFY_ITEM_SERVICE = 'app/base-configurations/MODIFY_ITEM_SERVICE';
export const CREATE_ITEM_SERVICE = 'app/base-configurations/CREATE_ITEM_SERVICE';
export const SEARCH_ITEM_SERVICE_SELECT = 'app/base-configurations/SEARCH_ITEM_SERVICE_SELECT';
export const SEARCH_ALL_ITEM_SERVICE = 'app/base-configurations/SEARCH_ALL_ITEM_SERVICE';
export const SEARCH_ALL_ITEM_SERVICE_SUCCESS = 'app/base-configurations/SEARCH_ALL_ITEM_SERVICE_SUCCESS';
export const GET_ITEM_SERVICE_PROCESS_STATS = 'app/base-configurations/GET_ITEM_SERVICE_PROCESS_STATS';
export const GET_IETM_SERVICE_FILE_UPLOAD_ERRORS = 'app/base-configurations/GET_IETM_SERVICE_FILE_UPLOAD_ERRORS';

// ----discount trigger
export const SEARCH_DISCOUNT_TRIGGER = 'app/pricinghub/SEARCH_DISCOUNT_TRIGGER';
export const SEARCH_DISCOUNT_TRIGGER_SUCCESS = 'app/pricinghub/SEARCH_DISCOUNT_TRIGGER_SUCCESS';
export const CREATE_DISCOUNT_TRIGGER = 'app/pricinghub/CREATE_DISCOUNT_TRIGGER';
export const MODIFY_DISCOUNT_TRIGGER = 'app/pricinghub/MODIFY_DISCOUNT_TRIGGER';
export const GET_DISCOUNT_TRIGGER_BY_NAME = 'app/pricinghub/GET_DISCOUNT_TRIGGER_BY_NAME';

// ----Audit
export const GET_PRICE_OFFER_AUDIT = 'app/pricinghub/GET_PRICE_OFFER_AUDIT';
export const CONVERT_JSON_TO_PRICE_OFFER = 'app/pricinghub/CONVERT_JSON_TO_PRICE_OFFER';
export const GET_DISCOUNT_OFFER_AUDIT = 'app/pricinghub/GET_DISCOUNT_OFFER_AUDIT';
export const CONVERT_JSON_TO_DISCOUNT_OFFER = 'app/pricinghub/CONVERT_JSON_TO_DISCOUNT_OFFER';

export const SEARCH_PRODUCT_FAMILY_CONFIG = 'app/pricinghub/SEARCH_PRODUCT_FAMILY_CONFIG';
export const SEARCH_PRODUCT_FAMILY_CONFIG_SUCCESS = 'app/pricinghub/SEARCH_PRODUCT_FAMILY_CONFIG_SUCCESS';
