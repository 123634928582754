import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, sortBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../common';
import { paymentSelect } from '../../../constantsApp';

class SearchPaymentActivityForm extends PureComponent {
  constructor(props) {
    super(props);
    let newOptionStatus = props.t ? props.t('selections:statusPayment')() : cloneDeep(paymentSelect.status);
    if (props.modeSearchPaymentReversals === 0) {
      newOptionStatus = newOptionStatus.filter(item => item.value !== 'REVERSED');
    }

    this.state = {
      currencyOptions: [],
      optionFileName: [],
      listPaymentOptions: [],
      searchInputs: [
        {
          label: 'label.startDateWithRank',
          name: 'startDate',
          type: 'date',
        },
        {
          label: 'label.endDateWithRank',
          name: 'endDate',
          type: 'date',
        },
        {
          name: 'sourceCurrency',
          label: 'common:label.paymentCurrency',
          options: [],
          isClearable: true,
          type: 'select',
        },
        // {
        //   name: 'thresholdAmount',
        //   label: 'common:label.payemntThresholdMoreThan',
        //   type: 'number',
        // },
        {
          label: 'common:label.status',
          name: 'status',
          options: newOptionStatus,
          type: 'select',
          isClearable: true,
        },
        {
          label: 'label.payemntThresholdMoreThan',
          name: 'minThresholdAmount',
          type: 'number',
        },
        {
          label: 'label.payemntThresholdLessThan',
          name: 'maxThresholdAmount',
          type: 'number',
        },
        {
          label: 'label.remainingAmountThresholdMoreThan',
          name: 'minRemainingPaymentThreshold',
          type: 'number',
        },
        {
          label: 'label.remainingAmountThresholdLessThan',
          name: 'maxRemainingPaymentThreshold',
          type: 'number',
        },
        {
          label: 'label.paymentSource',
          name: 'paymentSource',
          // options: [],
          // isClearable: true,
          // type: 'select',
        },
        {
          label: 'common:label.fileName',
          name: 'fileName',
        },
        {
          label: 'label.paymentTransactionType',
          name: 'paymentTransactionType',
          tOptions: 'selections:paymentTransactionType',
          isClearable: true,
          type: 'select',
        },
        {
          label: 'label.organization',
          name: 'organization',
        },
        {
          label: 'label.accountId',
          name: 'accountId',
        },
        {
          label: 'label.clientId',
          name: 'clientId',
        },
        {
          label: 'label.paymentReferenceId',
          name: 'referenceId',
        },
        {
          label: 'common:label.invoiceId',
          name: 'invoiceUnitId',
        },
        {
          label: 'label.paymentMethod',
          name: 'paymentMethod',
          type: 'select',
          tOptions: 'selections:paymentMethod',
          isClearable: true,
        },
        {
          label: 'label.accountType',
          name: 'accountType',
          type: 'select',
          tOptions: 'selections:accountType',
          isClearable: true,
        },
        // {
        //   label: 'label.remainingAmountThresholdMoreThanZero',
        //   name: 'remainingPaymentThreshold',
        //   type: 'number',
        // },
        // {
        //   label: 'label.paymentId',
        //   name: 'id',
        // },
        {
          label: 'label.folioStatus',
          name: 'folioStatus',
          tOptions: 'selections:folioStatus',
          isClearable: true,
          type: 'select',
        },
        {
          label: 'label.useExchangeRate',
          name: 'useExchangeRate',
          type: 'number',
        },
        // {
        //   name: 'fileType',
        //   label: 'common:label.fileType',
        //   isClearable: true,
        // },

        // {
        //   name: 'fileDateTime',
        //   label: 'common:label.fileDateTime',
        //   type: 'date',
        //   isClearable: true,
        // },
        // {
        //   label: 'common:label.paymentsAfterThisDate',
        //   name: 'paymentDate',
        //   type: 'date',
        // },
      ],
    };
    this.btnSpan = 'col-md-12';
    this.submitText = 'Search';
    this.clearText = 'Clear';
  }

  componentDidMount() {
    const { getCurrencyConfigApp, getPaymentItemMap, getBatchPaymentFileConfigSelect, defaultFilter } = this.props;
    getPaymentItemMap('', ({ success, data }) => {
      if (success && data && data.paymentItemMaps) {
        this.setState({
          optionFileName: data.paymentItemMaps.map(val => ({ value: val.fileName, label: val.fileName })),
        });
      }
    });
    getCurrencyConfigApp('', ({ success, data }) => {
      if (success && data && data.currencyListConfig) {
        const currencyOptions = data.currencyListConfig.map(val => ({
          label: `${val.name} (${val.currencyId})`,
          value: val.currencyId,
        }));
        this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
      }
    });
    // getBatchPaymentFileConfigSelect('', ({ success, data }) => {
    //   if (success && data && data.files) {
    //     const listPaymentOptions = data.files.map(val => {
    //       return { label: val.name, value: val.name };
    //     });
    //     this.setState({
    //       listPaymentOptions: [
    //         { label: 'Automated', value: 'Automated' },
    //         { label: 'Manual', value: 'Manual' },
    //         ...listPaymentOptions,
    //       ],
    //     });
    //   }
    // });
    if (defaultFilter && Object.keys(defaultFilter).length > 0) {
      this.state.searchInputs.map(input => this.setState({ [input.name]: defaultFilter[input.name] || null }));
    } else {
      this.setDefaultInputs();
    }
  }

  setDefaultInputs = () => {
    this.state.searchInputs.map(input => this.setState({ [input.name]: null }));
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const data = {};
    this.state.searchInputs.map(input => (this.state[input.name] ? (data[input.name] = this.state[input.name]) : null));
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    this.setDefaultInputs();
  };

  render() {
    const { isSubmitting, t } = this.props;
    const { currencyOptions, optionFileName, listPaymentOptions } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          {this.state.searchInputs.map(item => {
            if (item.name === 'sourceCurrency') {
              return (
                <GenericInput
                  {...item}
                  type="select"
                  options={currencyOptions || []}
                  onChange={this.handleInputChange}
                  value={this.state[item.name]}
                  wrapperClass="col-md-3"
                />
              );
            }
            // if (item.name === 'paymentSource') {
            //   return (
            //     <GenericInput
            //       {...item}
            //       type="select"
            //       options={listPaymentOptions || []}
            //       onChange={this.handleInputChange}
            //       value={this.state[item.name]}
            //       wrapperClass="col-md-3"
            //     />
            //   );
            // }
            if (item.name === 'fileType') {
              return (
                <GenericInput
                  {...item}
                  type="select"
                  options={optionFileName || []}
                  onChange={this.handleInputChange}
                  value={this.state[item.name]}
                  wrapperClass="col-md-3"
                />
              );
            }
            return (
              <GenericInput key={item.name} {...item} value={this.state[item.name]} onChange={this.handleInputChange} />
            );
          })}
          <div className={`form-group text-right btn-search-actions ${this.btnSpan}`}>
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t('label.clear')}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t('label.search')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

SearchPaymentActivityForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  modeSearchPaymentReversals: PropTypes.number,
};

SearchPaymentActivityForm.defaultProps = {
  isSubmitting: false,
  onSubmit: () => {},
  modeSearchPaymentReversals: 2,
};

export default withTranslation(['common', 'selections'])(SearchPaymentActivityForm);
