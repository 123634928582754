import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import quoteNS from './constants/quoteNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, quoteNS);
  const updateQuoteStatus = `updateQuoteStatus(input: ${dataCreate})`;
  const qqlStr = gql`
  mutation {
    ${updateQuoteStatus} {
      id
    }
  }
`;
  return qqlStr;
};
