import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import jobScheduleNS from './constants/jobScheduleNS';

export default data => {
  const dataRetry = parseToMutationRequest(data, jobScheduleNS);
  const updateJobScheduleStatus = `updateJobScheduleStatus(input: ${dataRetry})`;
  return gql`
  mutation {
    ${updateJobScheduleStatus} {
      id
      status
      scheduleDate
      userId
      jobScheduleList {
        index
        jobId
        predJobId
        nextJobId
        name
        notes
        userId
        scheduleTime
        type
        scheduleType
        status
        count
        countSuccess
        countError
      }   
    }
  }
`;
};
