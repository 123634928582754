import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}` : '';
  const searchTenantOperatorRecord = `searchTenantOperatorRecord(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchTenantOperatorRecord} {
        id
        fileName
        operator
        processDate
        filePath
        disputeFileName
        disputeId
        invoiceFileName
        invoiceId
        numberOfRecords
        status
    }
  }
  `;
};
