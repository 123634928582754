export default {
  attributeType: {
    VARCHAR: 'VARCHAR',
    INTEGER: 'INTEGER',
    NUMERIC: 'NUMERIC',
    BOOLEAN: 'BOOLEAN',
  },
  mandatory: {
    Y: 'Y',
    N: 'N',
  },
};
