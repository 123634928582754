import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class UsageTransactionDetailSearchFormWithAccountId extends SearchComponent {
  searchInputs = [
    {
      label: 'label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
    },
    {
      label: 'label.sourceSystem',
      name: 'sourceSystem',
      type: 'select',
      tOptions: 'selections:sourceSystem',
      isClearable: true,
    },
    {
      label: 'label.usageType',
      name: 'usageType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'label.provisioningId',
      name: 'provisioningId',
    },
    {
      label: 'label.ratingDescription',
      name: 'ratingDescr',
      type: 'select',
      tOptions: 'selections:ratingDescription',
      isClearable: true,
    },
    {
      label: 'label.destination',
      name: 'destination',
    },
    {
      label: 'label.direction',
      name: 'direction',
      type: 'select',
      tOptions: 'selections:usageDirection',
      isClearable: true,
    },
    {
      label: 'label.fileId',
      name: 'fileId',
      type: 'number',
    },
    {
      label: 'label.batchId',
      name: 'batchId',
    },
    {
      label: 'label.regionId',
      name: 'flexAttribute1',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'label.carrierId',
      name: 'flexAttribute2',
    },
    {
      label: 'label.npaId',
      name: 'flexAttribute3',
    },
    {
      label: 'label.operatorId',
      name: 'flexAttribute6',
    },
    {
      label: 'label.incommingRoute',
      name: 'flexAttribute7',
    },
    {
      label: 'label.origNetworkId',
      name: 'flexAttribute8',
    },
    {
      label: 'label.city',
      name: 'flexAttribute4',
    },
    {
      label: 'label.callType',
      name: 'flexAttribute5',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.billUnitId',
      name: 'billUnitId',
    },
    {
      label: 'label.invoiceUnitId',
      name: 'invoiceUnitId',
    },
    {
      label: 'label.billUnitStatus',
      name: 'billUnitStatus',
      type: 'select',
      tOptions: 'selections:billStatus',
      isClearable: true,
    },
    {
      label: 'label.invoiceUnitStatus',
      name: 'invoiceUnitStatus',
      type: 'select',
      tOptions: 'selections:invoiceStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

UsageTransactionDetailSearchFormWithAccountId.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UsageTransactionDetailSearchFormWithAccountId.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UsageTransactionDetailSearchFormWithAccountId);
