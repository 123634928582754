import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import {
  UsageTransactionDetailSearchForm,
  UsageTransactionDetailSearchFormWithAccountId,
} from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  AccountHeaderFrom,
  TitleFrom,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../../components/common';
import { tabelColumnExportUsageTransactionDetail, sortUsageTransactionDetail } from '../../constants';
import { getUsageTransactionDetailReport, getAllUsageTransactionDetailReport, onResetDefaultData } from '../../actions';
import {
  getRegionMapConfigData,
  getConfigUsageTypeConfig,
  uploadMultiPartFiles,
  saveOutputTemplateByType,
  getOutputTemplateByType,
} from '../../../App/actions';
import { makeGetListUsageTransactionDetail, makeGetListAllUsageTransactionDetail } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';

let tableColumnsOutput = null;
class UsageTransactionDetail extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {
      startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
    },
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listUsageTransactionDetail: [],
    defaultOptionRegion: [],
    defaultOptionUsageType: [],
    listAllUsageTransactionDetailFetch: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listUsageTransactionDetail: props.listUsageTransactionDetail,
      totalCount: getPageTotalCount({ ...state, items: props.listUsageTransactionDetail }),
    };
  }

  componentDidMount() {
    // this.doGetListUsageTransactionDetail();
    const { getRegionMapConfigData, getConfigUsageTypeConfig, onResetDefaultData } = this.props;
    getRegionMapConfigData('', ({ success, data }) => {
      if (success && data && data.regions) {
        const defaultOptionRegion = data.regions.map(val => ({
          value: val.regionId,
          label: `${val.regionId} : ${val.description}`,
        }));
        this.setState({ defaultOptionRegion });
      }
    });
    getConfigUsageTypeConfig('', ({ success, data }) => {
      let usageTypeList = [];
      if (success && data && data.configUsageTypeService) {
        data.configUsageTypeService.forEach(val => {
          usageTypeList = [...usageTypeList, ...val.configUsageTypeList];
        });

        const defaultOptionUsageType = usageTypeList.map(val => {
          return { label: val.usagetype, value: val.usagetype };
        });
        this.setState({
          defaultOptionUsageType: defaultOptionUsageType ? sortBy(defaultOptionUsageType, ['label']) : [],
        });
      }
    });
    this.doGetOutputTemplateByType();
    onResetDefaultData('listUsageTransactionDetail');
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListUsageTransactionDetail();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListUsageTransactionDetail());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListUsageTransactionDetail());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListUsageTransactionDetail();
    });
  };

  doGetListUsageTransactionDetail = () => {
    const { filter, page, size, sorted } = this.state;
    const { getUsageTransactionDetailReport, id } = this.props;
    if (id) filter.accountId = id;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort:
        !isEmpty(sorted) && sortUsageTransactionDetail[sorted.sortCol]
          ? sortUsageTransactionDetail[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getUsageTransactionDetailReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistUsageTransactionDetail = cb => {
    const { filter, sorted } = this.state;
    const { getAllUsageTransactionDetailReport, id } = this.props;
    if (id) filter.accountId = id;
    const page = 1;
    const payload = {
      page,
      size: 2000,
      filter,
      sort: !isEmpty(sorted) ? sortUsageTransactionDetail[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ listAllUsageTransactionDetailFetch: [] });
    this.doGetAllUsageTransactionDetailReport(payload, cb);
    // getAllUsageTransactionDetailReport(payload, () => {
    //   if (cb) cb();
    // });
  };

  doGetAllUsageTransactionDetailReport = (payload, cb) => {
    const { getAllUsageTransactionDetailReport } = this.props;
    const newPayload = payload;
    getAllUsageTransactionDetailReport(newPayload, ({ success, data }) => {
      const { listAllUsageTransactionDetailFetch } = this.state;
      if (success && data && data.length)
        this.setState({ listAllUsageTransactionDetailFetch: [...listAllUsageTransactionDetailFetch, ...data] });
      if (success && data && data.length === 2000) {
        newPayload.page += 1;
        this.doGetAllUsageTransactionDetailReport(newPayload, cb);
      } else if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistUsageTransactionDetail(() => {
      const { isActivePdf, listAllUsageTransactionDetailFetch } = this.state;
      if (isActivePdf) {
        const { t } = this.props;
        convertJson2Pdf({
          data: listAllUsageTransactionDetailFetch,
          t,
          title: t('label.usageData').toLocaleUpperCase(),
          fileName: `${t('label.usageData')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
          fontSize: 2,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistUsageTransactionDetail(() => {
      const { t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf, listAllUsageTransactionDetailFetch } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_BILLED_TRANSACTION_SUMMARY_REPORT',
          subject: subject || `Embrix Report ${t('label.usageData')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.usageData')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllUsageTransactionDetailFetch,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              serviceType: 'serviceType',
              direction: 'usageDirection',
              billUnitStatus: 'billStatus',
              invoiceUnitStatus: 'invoiceStatus',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllUsageTransactionDetailFetch,
          t,
          title: t('label.usageData').toLocaleUpperCase(),
          fileName: `${t('label.usageData')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
          fontSize: 2,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.usageData')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('USAGE_DETAIL_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tabelColumnExportUsageTransactionDetail,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listUsageTransactionDetail,
      defaultOptionUsageType,
      defaultOptionRegion,
      listAllUsageTransactionDetailFetch,
      emails,
      filter,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const { t, id, isRenderHeaderForm, currencyOptions, customerInfo } = this.props;
    let currencyLabel = '';
    if (isRenderHeaderForm && currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }

    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    return (
      <div className="col-md-12">
        {!id && (
          <PageTitle
            linkTo={RouteNames.report.path}
            titleBtn="back"
            items={[{ name: t('label.report'), url: RouteNames.report.path }, { name: t('label.usageData') }]}
          />
        )}
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('label.usageData')} />
          {isRenderHeaderForm && (
            <AccountHeaderFrom
              accountNum={id}
              commercialName={customerInfo?.commercialName || ''}
              isCommercialName={customerInfo?.customerSegment !== 'B2C'}
              currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
            />
          )}
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                {id ? (
                  <UsageTransactionDetailSearchForm
                    onSubmit={this.onHandleSubmitSearch}
                    isMultipleOptionInject
                    defaultFilter={filter}
                    optionsInject={{
                      usageType: defaultOptionUsageType,
                      flexAttribute1: defaultOptionRegion,
                    }}
                  />
                ) : (
                  <UsageTransactionDetailSearchFormWithAccountId
                    onSubmit={this.onHandleSubmitSearch}
                    isMultipleOptionInject
                    optionsInject={{
                      usageType: defaultOptionUsageType,
                      flexAttribute1: defaultOptionRegion,
                    }}
                    defaultFilter={filter}
                  />
                )}
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  {!id && (
                    <button
                      type="submit"
                      onClick={this.onToggleModalExportConfig}
                      className="button x-small mr-2 btn-config"
                    >
                      {/* {t('label.outputConfig')} */}
                      <i className="ti-list" />
                    </button>
                  )}
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('label.usageData').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: listAllUsageTransactionDetailFetch,
                        t,
                        title: t('label.usageData').toLocaleUpperCase(),
                        columnsTable: tableColumnsOutput,
                      })}
                      fileName={`${t('label.usageData')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>

                  <EmailGroup
                    emails={emails}
                    onChangeEmail={this.onChangeEmail}
                    sendEmail={this.sendEmail}
                    data={listUsageTransactionDetail}
                  />
                </div>
                <DataTable
                  columns={tableColumnsOutput}
                  data={
                    listUsageTransactionDetail && listUsageTransactionDetail.length ? listUsageTransactionDetail : []
                  }
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

UsageTransactionDetail.propTypes = {
  getUsageTransactionDetailReport: PropTypes.func,
  getAllUsageTransactionDetailReport: PropTypes.func,
  listAllUsageTransactionDetail: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
};

UsageTransactionDetail.defaultProps = {
  getUsageTransactionDetailReport: () => {},
  getAllUsageTransactionDetailReport: () => {},
  listAllUsageTransactionDetail: [],
};

const mapStateToProps = createStructuredSelector({
  listUsageTransactionDetail: makeGetListUsageTransactionDetail() || [],
  listAllUsageTransactionDetail: makeGetListAllUsageTransactionDetail() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getUsageTransactionDetailReport,
    getAllUsageTransactionDetailReport,
    getRegionMapConfigData,
    getConfigUsageTypeConfig,
    uploadMultiPartFiles,
    saveOutputTemplateByType,
    getOutputTemplateByType,
    onResetDefaultData,
  })(UsageTransactionDetail)
);
