import * as types from './types';

export function searchQuotes(payload, cb) {
  return {
    type: types.SEARCH_QUOTE,
    payload,
    cb,
  };
}

export function searchQuotesSuccess(payload) {
  return {
    type: types.SEARCH_QUOTE_SUCCESS,
    payload,
  };
}

export function getQuoteDetails(payload, cb) {
  return {
    type: types.GET_QUOTE_DETAILS,
    payload,
    cb,
  };
}

export function saveQuote(payload, cb, isComponent) {
  return {
    type: types.SAVE_QUOTE,
    payload,
    cb,
    isComponent,
  };
}

export function getQuote(payload, cb) {
  return {
    type: types.GET_QUOTE,
    payload,
    cb,
  };
}

export function buildOrderProvisioningAttributes(payload, cb) {
  return {
    type: types.BUILD_ORDER_PROVISIONING_ATTRIBUTES,
    payload,
    cb,
  };
}

export function updateQuoteStatus(payload, cb) {
  return {
    type: types.UPDATE_QUOTE_STATUS,
    payload,
    cb,
  };
}

export function convertQuote(payload, cb) {
  return {
    type: types.CONVERT_QUOTE,
    payload,
    cb,
  };
}

export function associateQuoteToAccount(payload, cb) {
  return {
    type: types.ASSOCIATE_QUOTE_TO_ACCOUNT,
    payload,
    cb,
  };
}

export function getQuoteDetailsViewer(payload, cb) {
  return {
    type: types.GET_QUOTE_DETAILS_VIEWER,
    payload,
    cb,
  };
}