import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SearchAccountForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.organization',
      name: 'organization',
    },
  ];

  btnSpan = 'col-md-6';
}

SearchAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchAccountForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SearchAccountForm);
