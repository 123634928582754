/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectInvoiceId } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { InvoiceSearchFormAdditionalFilters, InvoiceSearchFormApplyPayment } from '../../PaymentHub';

const arraySort = {
  dueDate: {
    asc: 'dueDate_ASC',
    desc: 'dueDate_DESC',
  },
  invoiceDate: {
    asc: 'invoiceDate_ASC',
    desc: 'invoiceDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
};

class ModalInvoiceApplyPayment extends PureComponent {
  searchHanldeInvoiceId = (payload, cb) => {
    const { selectInvoiceId, accountId, isNoSetDefaultDue, filterInstallmentInvoices } = this.props;
    const newPayload = { ...payload };
    if (newPayload.filter) {
      if (accountId) newPayload.filter.accountId = accountId || '';
      if (!isNoSetDefaultDue) newPayload.filter.due = 0;
      if (filterInstallmentInvoices) newPayload.filter.filterInstallmentInvoices = filterInstallmentInvoices || null;
    }
    selectInvoiceId(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          sortable: true,
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'accountId',
          label: 'common:label.accountId',
        },
        {
          name: 'type',
          label: 'common:label.type',
        },
        {
          name: 'folioStatus',
          label: 'common:label.folioStatus',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'status',
          label: 'common:label.status',
          sortable: true,
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'invoiceType',
          label: 'common:label.invoiceType',
        },
        {
          name: 'invoiceDate',
          label: 'common:label.invoiceDate',
          sortable: true,
        },
        {
          name: 'dueDate',
          label: 'common:label.dueDate',
          sortable: true,
        },
        {
          name: 'currency',
          label: 'common:label.currency',
        },
        {
          name: 'total',
          label: 'common:label.total',
        },
        {
          name: 'due',
          label: 'common:label.due',
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      isAdditionalFilters,
      disabledSearchFields,
      fieldsEnableCheck,
      invoiceCancellation,
      fieldDisableCheck,
      defaultSearchForm,
      ...rest
    } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectInvoice')}
        isOpen={isOpen}
        SearchForm={
          invoiceCancellation && invoiceCancellation === 'TRUE'
            ? props => <InvoiceSearchFormApplyPayment {...props} disabledSearchFields={disabledSearchFields} />
            : props => (
              <InvoiceSearchFormAdditionalFilters {...rest} {...props} disabledSearchFields={disabledSearchFields} />
              )
        }
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        fieldsEnableCheck={fieldsEnableCheck}
        fieldDisableCheck={fieldDisableCheck}
        arraySort={arraySort}
        defaultSearchForm={defaultSearchForm}
      />
    );
  }
}

ModalInvoiceApplyPayment.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectInvoiceId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalInvoiceApplyPayment.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectInvoiceId })(ModalInvoiceApplyPayment));
