import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderOmsTasks(id: "${id}") {
      id
      name
      status
    }
  }
`;
