import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['subscriptionCategory', 'status'])}` : '';
  const searchSubscriptionByAccountId = `searchSubscriptionByAccountId(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchSubscriptionByAccountId} {
        id
        accountId
        name
        status
        reason
        initialTerm
        initialTermUnit
        renewalTerm
        renewalTermUnit
        trialTerm
        trialTermUnit
        effectiveDate
        inTrialPeriod
        trialStatus
        startDate
        endDate
        trialType
        category
        balanceUnitId 
      }
    }
  `;
};
