/**
 * Customer Value Not String
 */

export default [
  'status',
  'customerSegment',
  'type',
  'subType',
  'salesChannel',
  'marketSegment',
  'reason',
  'billingDom',
  'billingFrequency',
  'invoiceType',
  'invoiceDelivery',
  // 'paymentTerm',
  'paymentMethod',
  'fetchParentOnly',
  'activeSubscription',
  'subscriptionStatus',
  'creditProfileIndex',
  'trialLevels',
  'trialStatus',
  'propagateToBillingAccounts',
  'pauseBilling',
  'pauseInvoicing',
  'collectionAgentAssigned',
  'accountCategory',
  'duration',
  'childAccount',
  'exemptionLevel',
  'index',
  'lowerLimit',
  'upperLimit',
  'serviceType',
  'percent'
];
