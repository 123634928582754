import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import TitleFrom from '../../../components/common/TitleFrom';
import { uploadMultiPartFiles, getOutputTemplateByType, saveOutputTemplateByType } from '../../App/actions';
import { JournalSearchForm, ModalSearchRevRecJournal } from '../../../components/RevenueHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  GenericInput,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../components/common';
import { sortJournal } from '../constants';
import {
  searchRevenueJournal,
  searchAllRevenueJournal,
  modifyRevenueJournal,
  searchRevRecJournal,
  createRevenueJournal,
  recognizeManualRevenue,
  getRevenueRecognitionSummary,
} from '../actions';
import { makeGetListRevenueJournal, makeGetListAllRevenueJournal } from '../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../utils/utils';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelJournal';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfJournal';

const sizeDownloadAll = 1000;

let tableColumnsOutput = null;

class Journal extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listJournals: [],
    emails: [],
    oldValue: [],
    listAllJournalFetch: [],
    createType: null,
    itemSelected: null,
    exportColumnsSave: null,
    isOpenModalExportConfig: false,
    idOutputTemplate: null,
    typeOutputTemplate: null,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.listJournals) return null;
    return {
      listJournals: props.listJournals,
      oldValue: props.listJournals,
      totalCount: getPageTotalCount({ ...state, items: props.listJournals }),
    };
  }

  componentDidMount() {
    // const {
    //   permissions: { modeGetJournalReport },
    // } = this.props;
    this.dosearchRevenueJournal();
    this.doGetOutputTemplateByType();
  }

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('REVENUE_JOURNAL', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({ columns: tableColumnsOutput, data, t }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.dosearchRevenueJournal();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.dosearchRevenueJournal());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.dosearchRevenueJournal());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.dosearchRevenueJournal();
    });
  };

  dosearchRevenueJournal = () => {
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (newFilter.offsetAccount) newFilter.offsetAccount = newFilter.offsetAccount === 'TRUE' ? 'true' : 'false';
    if (newFilter.fullyRecognized) newFilter.fullyRecognized = newFilter.fullyRecognized === 'TRUE' ? 'true' : 'false';
    if (newFilter.accountingPeriod) {
      newFilter.accountingPeriod = moment(newFilter.accountingPeriod, 'MM-YYYY')
        .format('MMM-YYYY')
        .toUpperCase();
    }
    const { searchRevenueJournal } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortJournal[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchRevenueJournal(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAllJournal = cb => {
    const { filter, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (newFilter.offsetAccount) newFilter.offsetAccount = newFilter.offsetAccount === 'TRUE' ? 'true' : 'false';
    if (newFilter.fullyRecognized) newFilter.fullyRecognized = newFilter.fullyRecognized === 'TRUE' ? 'true' : 'false';
    if (newFilter.accountingPeriod) {
      newFilter.accountingPeriod = moment(newFilter.accountingPeriod, 'MM-YYYY')
        .format('MMM-YYYY')
        .toUpperCase();
    }
    const page = 1;
    const payload = {
      page,
      size: sizeDownloadAll,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortJournal[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ listAllJournalFetch: [] });
    this.doSearchAllRevenueJournal(payload, cb);
  };

  doSearchAllRevenueJournal = (payload, cb) => {
    const { searchAllRevenueJournal } = this.props;
    const newPayload = payload;
    searchAllRevenueJournal(newPayload, ({ success, data }) => {
      const { listAllJournalFetch } = this.state;
      if (success && data && data.length) this.setState({ listAllJournalFetch: [...listAllJournalFetch, ...data] });
      if (success && data && data.length === sizeDownloadAll) {
        newPayload.page += 1;
        this.doSearchAllRevenueJournal(newPayload, cb);
      } else if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = ({ columnsTable }) => {
    this.doGetAllJournal(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { t } = this.props;
        const { listAllJournalFetch } = this.state;

        convertJson2Pdf({
          data: listAllJournalFetch,
          t,
          title: t('label.journals').toLocaleUpperCase(),
          fileName: `${t('label.journals')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  // sendEmail = () => {
  //   this.doGetAllJournal(() => {
  //     const { t, uploadMultiPartFiles } = this.props;
  //     const { listAllJournalFetch } = this.state;
  //     const { emails } = this.state;
  //     const pdfFile = convertJson2Pdf({
  //       data: listAllJournalFetch,
  //       t,
  //       title: 'LIST NEW JOURNALS',
  //       fileName: `list_journals_${moment(new Date()).format('YYYY_MM_DD')}`,
  //       isFile: true,
  //     });

  //     const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
  //       { emailAddress: emails.join(', '), type: 'GET_NEW_CUSTOMER_REPORT', subject: 'Test Send report via email' },
  //       ['type']
  //     )})}"}`;

  //     const formData = new FormData();
  //     formData.append('graphql', query);
  //     formData.append('file', blobToFile(pdfFile, 'attachment'));
  //     uploadMultiPartFiles(
  //       formData,
  //       res => {
  //         if (res && res.success) {
  //           if (res.success) this.setState({ emails: [] });
  //         }
  //       },
  //       true
  //     );
  //   });
  // };

  sendEmail = ({ columns, subject, emailBody }) => {
    this.doGetAllJournal(() => {
      const { t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf, listAllJournalFetch } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_NEW_CUSTOMER_REPORT',
          subject: subject || `Embrix Report ${t('label.journals')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.journals')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllJournalFetch,
            t,
            columns,
            listOptionFields: {
              accountType: 'accountType',
              revenueType: 'revenueType',
              revenueRecognitionType: 'revenueRecognitionType',
              revenueActivityType: 'revenueActivityType',
              transactionType: 'transactionType',
              status: 'revenueJournalStatus',
              salesChannel: 'salesChannel',
              marketSegment: 'marketSegment',
              accountSubType: 'accountSubType',
              reportObjectType: 'revenueReportObjectType',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllJournalFetch,
          t,
          title: t('label.journals').toLocaleUpperCase(),
          fileName: `${t('label.journals')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.journals')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  addNewItem = () => {
    try {
      const { listJournals, itemSelected, createType } = this.state;
      let newData = cloneDeep(listJournals);
      const { extractBatchId, revenueActivityType, amountRecognized, ...rest } = itemSelected;
      const payload = {
        revenueActivityType: 'MANUAL',
        amountRecognized: createType === 'CREATE_REV_REC' ? '0' : amountRecognized,
        oldAmountRecognized: amountRecognized,
        createType,
        ...rest,
        isSelected: true,
        isNew: true,
      };
      newData = [
        payload,
        ...newData.map(val => {
          const { isSelected, ...rest } = val;
          return rest;
        }),
      ];
      this.setState({ listJournals: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  onSelectItem = ({ index, item }) => {
    const { listJournals } = this.state;
    let newData = cloneDeep(listJournals);
    if (newData && newData[index].isSelected) {
      newData[index].isSelected = false;
    } else {
      newData = newData.map(val => {
        const { isSelected, ...rest } = val;
        return rest;
      });
      newData[index].isSelected = true;
    }
    this.setState({ listJournals: newData, itemSelected: item || null });
  };

  onChangeItem = ({ name, value, index }) => {
    const { listJournals } = this.state;
    const newData = cloneDeep(listJournals);
    newData[index][name] = moment(value).format('MMM-YYYY');
    this.setState({ listJournals: newData });
  };

  onChangeTable = ({ name, value, index, item }) => {
    const { listJournals } = this.state;
    const newData = cloneDeep(listJournals);
    if (name === 'amountRecognized' && item && item.createType === 'CREATE_REV_REC') {
      const totalAmount = Number.parseFloat(item.amount || 0) - Number.parseFloat(item.oldAmountRecognized || 0);
      if (Number.parseFloat(totalAmount) < Number.parseFloat(value || 0)) {
        newData[index][name] = '0';
        return this.setState({ listJournals: newData });
      }
    }
    newData[index][name] = value;
    return this.setState({ listJournals: newData });
  };

  onHandleModifyRevenueJournal = ({ index }) => {
    const { modifyRevenueJournal } = this.props;
    const { listJournals } = this.state;
    const newData = cloneDeep(listJournals);
    modifyRevenueJournal(
      { id: newData[index].id, accountingPeriod: newData[index].accountingPeriod },
      ({ success }) => {
        if (success) {
          // console.log('asadd');
        }
      }
    );
  };

  onHandleSave = () => {
    const { createRevenueJournal, recognizeManualRevenue } = this.props;
    const { listJournals } = this.state;
    const payload = listJournals[0] || {};
    const { isNew, isSelected, oldAmountRecognized, createType, id, ...newPayload } = payload;
    if (createType === 'CREATE_REV_REC') {
      return recognizeManualRevenue({ id, ...newPayload }, ({ success }) => {
        if (success) this.dosearchRevenueJournal();
      });
    }
    return createRevenueJournal(newPayload, ({ success }) => {
      if (success) this.dosearchRevenueJournal();
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listJournals,
      emails,
      listAllJournalFetch,
      idOpenModaRevRecJournal,
      revItemSelect,
      createType,
      itemSelected,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const {
      t,
      getRevenueRecognitionSummary,
      permissions: { modeCreateManualRecognition },
      isExportConfig,
    } = this.props;
    // if (!modeGetJournalReport) return '';
    let optionsMonthYear = [
      {
        label: moment(new Date())
          .subtract(1, 'months')
          .format('MMM-YYYY'),
        value: moment(new Date())
          .subtract(1, 'months')
          .format('MMM-YYYY'),
      },
      {
        label: moment(new Date()).format('MMM-YYYY'),
        value: moment(new Date()).format('MMM-YYYY'),
      },
      {
        label: moment(new Date())
          .add(1, 'months')
          .format('MMM-YYYY'),
        value: moment(new Date())
          .add(1, 'months')
          .format('MMM-YYYY'),
      },
    ];

    const monthYearT = t('selections:monthOfYear')();
    optionsMonthYear = cloneDeep(optionsMonthYear).map(val => {
      const newVal = cloneDeep(val);
      const itemTranslation = monthYearT.find(it => newVal.value.indexOf(it.value) > -1);
      if (itemTranslation) {
        newVal.label = `${itemTranslation.label}${newVal.label.substring(3, newVal.label.length)}`;
      }
      return newVal;
    });
    const tableColumns = [
      {
        name: 'id',
        label: 'label.id',
        sortable: true,
      },
      {
        name: 'revenueStartDate',
        label: 'label.startDate',
        style: { minWidth: '200px' },
        sortable: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.revenueStartDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="revenueStartDate"
            disabled={!item.isNew}
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'revenueEndDate',
        label: 'label.endDate',
        style: { minWidth: '200px' },
        sortable: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.revenueEndDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="revenueEndDate"
            disabled={!item.isNew}
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'accountingPeriod',
        label: 'common:label.accountingPeriod',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item?.accountingPeriod ? item?.accountingPeriod : null}
              // disabled={!item.isSelected}
              disabled={(!item.isSelected || item.status !== 'PENDING') && !item.isNew}
              name="accountingPeriod"
              // type="monthYear"
              dateFormat="MMM-YYYY"
              menuPortalTarget
              type="select"
              options={optionsMonthYear}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
              isManualValue
            />
          );
        },
      },
      {
        name: 'extractBatchId',
        label: 'label.batchId',
      },
      {
        label: 'common:label.glAccount',
        name: 'glAccount',
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.glAccount}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="glAccount"
            disabled={!item.isNew || item.createType !== 'COPY_TO_CREATE'}
            readOnly={!item.isNew || item.createType !== 'COPY_TO_CREATE'}
          />
        ),
      },
      {
        name: 'costCenter',
        label: 'label.costCenter',
        sortable: true,
      },
      {
        name: 'interCompany',
        label: 'label.interCompany',
      },
      {
        name: 'sourceCurrency',
        label: 'label.sourceCurrency',
      },
      {
        name: 'currency',
        label: 'label.currency',
      },
      {
        name: 'accountId',
        label: 'label.accountId',
        sortable: true,
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'itemName',
        label: 'label.itemName',
      },
      {
        name: 'sourceAmount',
        label: 'label.sourceAmount',
      },
      {
        name: 'amount',
        label: 'label.amount',
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.amount}
            wrapperClass="col-md-12 p-0"
            // type="numberFormat"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="amount"
            disabled={!item.isNew || item.createType !== 'COPY_TO_CREATE'}
            readOnly={!item.isNew || item.createType !== 'COPY_TO_CREATE'}
          />
        ),
      },
      {
        name: 'amountRecognized',
        label: 'label.amountRecognized',
        style: { minWidth: '300px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.amountRecognized}
            wrapperClass="col-md-12 p-0"
            // type="numberFormat"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, item })}
            name="amountRecognized"
            disabled={!item.isNew || item.createType !== 'CREATE_REV_REC'}
            readOnly={!item.isNew || item.createType !== 'CREATE_REV_REC'}
          />
        ),
      },
      {
        name: 'revenueType',
        label: 'label.revenueType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:revenueType')().find(val => val.value === item.revenueType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'revenueRecognitionType',
        label: 'label.revenueRecognitionType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t
            ? t('selections:revenueRecognitionType')().find(val => val.value === item.revenueRecognitionType)
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'revenueActivityType',
        label: 'label.revenueActivityType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t
            ? t('selections:revenueActivityType')().find(val => val.value === item.revenueActivityType)
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'transactionType',
        label: 'label.transactionType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:transactionType')().find(val => val.value === item.transactionType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'status',
        label: 'label.status',
        fieldOption: 'revenueJournalStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:revenueJournalStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'billed',
        label: 'label.billed',
        render: (colName, item, t) => {
          return <span>{`${item.billed ? 'TRUE' : 'FALSE'}`}</span>;
        },
      },
      {
        name: 'transactionId',
        label: 'label.transactionId',
      },
      {
        name: 'arOpsId',
        label: 'label.arOpsId',
      },
      {
        name: 'itemId',
        label: 'label.itemId',
        sortable: true,
      },
      {
        name: 'billUnitId',
        label: 'label.billUnitId',
      },
      {
        name: 'invoiceId',
        label: 'label.invoiceId',
      },
      {
        name: 'invoiceDate',
        label: 'label.invoiceDate',
      },
      {
        name: 'country',
        label: 'label.country',
      },
      {
        label: 'common:label.productLine',
        name: 'productLine',
      },
      {
        label: 'common:label.objectType',
        name: 'reportObjectType',
        fieldOption: 'revenueReportObjectType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t
            ? t('selections:revenueReportObjectType')().find(val => val.value === item.reportObjectType)
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        label: 'common:label.notesId',
        name: 'notesId',
      },
      {
        label: 'common:label.paymentId',
        name: 'paymentId',
      },
      {
        label: 'common:label.subAccount',
        name: 'glSubAccount',
      },
      {
        name: 'glOffset',
        label: 'label.glOffset',
      },
      {
        name: 'salesChannel',
        label: 'label.salesChannel',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'marketSegment',
        label: 'label.marketSegment',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountSubType',
        label: 'label.accountSubType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
      },
      {
        name: 'itemSplitId',
        label: 'label.itemSplitId',
      },
      {
        name: 'sellingCompany',
        label: 'label.sellingCompany',
        sortable: true,
      },
      {
        name: 'legalEntity',
        label: 'label.legalEntity',
        sortable: true,
      },
      {
        name: 'setOfBooks',
        label: 'label.setOfBooks',
        sortable: true,
      },
      {
        name: 'productCompany',
        label: 'label.productCompany',
        sortable: true,
      },
      {
        name: 'accountingCode',
        label: 'label.accountingCode',
      },
      {
        name: 'offsetAccountingCode',
        label: 'label.offsetAccountingCode',
      },
    ];

    const exportColumns = exportColumnsSave || {};

    tableColumnsOutput = tableColumns;

    const newTableColumns = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (colName, item, index) => (
          <GenericInput
            value={item.isSelected}
            name="isSelectPayment"
            type="checkbox"
            // disabled={item.status !== 'PENDING'}
            wrapperClass="col-md-4"
            onChange={({ value }) => this.onSelectItem({ item, index })}
          />
        ),
      },
      {
        name: 'apply',
        label: 'label.apply',
        render: (colName, item, index) => {
          return (
            <button
              type="button"
              className="button x-small"
              disabled={!item.isSelected || item.status !== 'PENDING'}
              onClick={evt => this.onHandleModifyRevenueJournal({ index })}
            >
              {t('common:label.apply')}
            </button>
          );
        },
      },
      {
        name: 'viewRevRec',
        label: 'label.viewRevRec',
        render: (colName, item, index) => {
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              disabled={item.isNew}
              onClick={evt => this.setState({ idOpenModaRevRecJournal: item.id || null, revItemSelect: item })}
            >
              {t('common:label.view')}
            </button>
          );
        },
      },
      ...(exportColumns?.targetFields?.items || []),
    ];
    const modeDownloadReport = 2;
    const modeGetMailReport = 2;

    const optionCreateJournals = t('selections:journalCreate')();
    let isCreateRevRec = true;
    if (
      (itemSelected && Number.parseFloat(itemSelected.amount) === Number.parseFloat(itemSelected.amountRecognized)) ||
      (itemSelected && itemSelected.revenueRecognitionType !== 'REVENUE_ON_DEFERRAL_MANUAL')
    ) {
      // optionCreateJournals = optionCreateJournals.filter(val => val.value !== 'CREATE_REV_REC');
      isCreateRevRec = false;
    }

    return (
      <div className="col-md-12">
        <PageTitle linkTo={RouteNames.journals.path} titleBtn="back" items={[{ name: t('label.journals') }]} />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('label.journals')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <JournalSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport
                        onExport={() => this.onExport({ columnsTable: exportColumns?.targetFields?.items || [] })}
                      />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('label.journals').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllJournalFetch,
                          t,
                          title: t('label.journals').toLocaleUpperCase(),
                          columnsTable: exportColumns?.targetFields?.items || [],
                        })}
                        fileName={`${t('label.journals')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={e => this.sendEmail({ columns: exportColumns?.targetFields?.items || [], ...e })}
                      data={listJournals}
                    />
                  )}
                </div>
                <br />
                <br />
                {!!modeCreateManualRecognition === 2 && (
                  <div className="col-md-12 row pb-3">
                    <GenericInput
                      value={createType}
                      wrapperClass="col-md-3"
                      onChange={({ value }) => this.setState({ createType: value })}
                      name="createType"
                      options={optionCreateJournals}
                      type="select"
                      menuPortalTarget
                      label="label.createOption"
                    />
                    <button
                      type="button"
                      disabled={
                        !createType ||
                        (!isCreateRevRec && createType === 'CREATE_REV_REC') ||
                        modeCreateManualRecognition === 1 ||
                        (listJournals && listJournals.find(val => !!val.isNew))
                      }
                      className="button button-border black x-small mt-4"
                      onClick={this.addNewItem}
                    >
                      +
                      {t('label.addNewRevenueJournals')}
                    </button>
                    <div className="ml-auto mr-0">
                      <button
                        type="button"
                        onClick={this.dosearchRevenueJournal}
                        className="button button-border black x-small mt-4"
                      >
                        {t('label.cancel')}
                      </button>
                      <button
                        type="button"
                        className="button button-border x-small float-right mt-4 ml-2"
                        onClick={this.onHandleSave}
                        disabled={
                          modeCreateManualRecognition === 1 || (listJournals && !listJournals.find(val => !!val.isNew))
                        }
                      >
                        {t('label.saveConfig')}
                      </button>
                    </div>
                  </div>
                )}
                <DataTable
                  columns={newTableColumns}
                  data={listJournals && listJournals.length ? listJournals : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>

        <ModalSearchRevRecJournal
          isOpen={!!idOpenModaRevRecJournal}
          // defaultSearchParams={{ journalId: idOpenModaRevRecJournal }}
          id={idOpenModaRevRecJournal}
          onSelect={this.onSelectItemId}
          onCancel={() => {
            this.setState({ idOpenModaRevRecJournal: null, revItemSelect: null });
          }}
          toggleModal={() => {
            this.setState({ idOpenModaRevRecJournal: null, revItemSelect: null });
          }}
          getRevenueRecognitionSummary={getRevenueRecognitionSummary}
          itemSelect={revItemSelect}
        />
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

Journal.propTypes = {};

Journal.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  listJournals: makeGetListRevenueJournal() || [],
  listAllJournal: makeGetListAllRevenueJournal() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    searchRevenueJournal,
    searchAllRevenueJournal,
    uploadMultiPartFiles,
    modifyRevenueJournal,
    searchRevRecJournal,
    createRevenueJournal,
    recognizeManualRevenue,
    getRevenueRecognitionSummary,
    getOutputTemplateByType,
    saveOutputTemplateByType,
  })(Journal)
);
