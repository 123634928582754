/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropsType from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import RouteName from '../../App/RouteNames';
import { getGroupsReason, modifyGroupsReason } from '../actions';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import {
  GenericInput,
  TitleFrom,
  Breadcrumb,
  CollapsibleTable,
  DataTable,
  QuickNotesConfig,
} from '../../../components/common';
import ModalReasonCodeConfig from '../../../components/common/ModalReasonCodeConfig';
import {
  checkPermissionGetConfigGroupReasonCode,
  checkPermissionModifyConfigGroupReasonCode,
  checkPermissionCreateConfigGroupReasonCode,
} from '../CheckPermission';

const GroupsReason = ({ getGroupsReason, modifyGroupsReason, permissionsArOps }) => {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState(-1);
  const [itemGroupSelect, setItemGroupSelect] = useState(null);
  const [indexGroupSelect, setIndexGroupSelect] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [wasValidated, setValidate] = useState(false);
  const formRef = useRef();

  let modeCreateConfigGroupReasonCode = 0;
  let modeModifyConfigGroupReasonCode = 0;
  let modeGetConfigGroupReasonCode = 0;

  const doGetGroupsReason = () => {
    getGroupsReason(({ success, data }) => {
      if (success) setData(data);
    });
  };

  useEffect(() => {
    doGetGroupsReason();
  }, [getGroupsReason]);

  if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
    const listPermission = permissionsArOps.arOpsModulePermissions;
    modeCreateConfigGroupReasonCode = checkPermissionCreateConfigGroupReasonCode({ listPermission });
    modeModifyConfigGroupReasonCode = checkPermissionModifyConfigGroupReasonCode({ listPermission });
    modeGetConfigGroupReasonCode = checkPermissionGetConfigGroupReasonCode({ listPermission });
  }

  if (!modeGetConfigGroupReasonCode) return '';

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onRemoveUser = ({ index }) => {
    const newData = cloneDeep(data);
    newData.groups[activeTab].reasonCodes[index] = {
      index: newData.groups[activeTab].reasonCodes[index].index,
    };
    setData(newData);
  };

  const onHandleAddReason = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.groups) newData.groups = [];
    if (!newData.groups[activeTab].reasonCodes) newData.groups[activeTab].reasonCodes = [];
    newData.groups[activeTab].reasonCodes.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.groups[activeTab].reasonCodes.push({
      index: lastIndex + 1,
      reasonCodes: null,
      customReasonCode: null,
    });
    setData(newData);
  };

  const onSelect = (id, row) => {
    try {
      const newData = cloneDeep(data);
      newData.groups[activeTab].reasonCodes[indexSelect].index = id;
      newData.groups[activeTab].reasonCodes[indexSelect].reasonCode = row && row.reasonCode ? row.reasonCode : '';
      newData.groups[activeTab].reasonCodes[indexSelect].customReasonCode =
        row && row.customReasonCode ? row.customReasonCode : '';
      newData.groups[activeTab].reasonCodes[indexSelect].description = row && row.description ? row.description : '';
      setData(newData);
      setIsOpen(false);
    } catch (error) {
      setIsOpen(false);
    }
  };

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData.groups[activeTab].reasonCodes[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleTab = (index, item, indexItem) => {
    if (activeTab === indexItem) setActiveTab(-1);
    if (activeTab !== indexItem) {
      setActiveTab(indexItem);
      setItemGroupSelect(item);
      setIndexGroupSelect(index);
    }
  };
  const headerTable = [
    {
      name: 'groupId ',
      label: 'label.groupId',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.groupId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="groupId"
          readOnly
        />
      ),
    },
    {
      name: 'groupName',
      label: 'label.groupName',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.groupName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="groupName"
        />
      ),
    },

    {
      name: '',
      label: 'label.reasons',
      style: { width: 100 },
      render: (colName, item, idx, indexParent, activeTab) => (
        <button type="button" className="btn-expand-table mr-3">
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
  ];

  const headerTableReason = [
    {
      name: 'reasonCode',
      label: 'label.reasonCode',
      render: (colName, item, index) => (
        <GenericInput
          value={item.reasonCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="reasonCode"
          type="select"
          tOptions="selections:reasonConfig"
          menuPortalTarget
          isClearable
          disabled={!!item.customReasonCode}
        />
      ),
    },
    {
      name: 'customReasonCode',
      label: 'label.customReasonCode',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.customReasonCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="customReasonCode"
          readOnly
          disabled={!!item.reasonCode}
          fa="fa fa-external-link"
          onClick={() => {
            setIsOpen(true);
            setItemSelect(item);
            setIndexSelect(idx);
          }}
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="description"
          readOnly
          type="textarea"
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveUser({ index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    const groupsPayload = data.groups
      ? data.groups.map(val => {
          const { id, groupName, reasonCodes, ...rest } = val;
          return {
            ...rest,
            reasonCodes: reasonCodes
              ? reasonCodes.map(u => {
                  if (Object.keys(u).length > 1)
                    return {
                      customReasonCode: u.customReasonCode,
                      reasonCode: u.reasonCode,
                      index: u.index,
                    };
                  return { index: u.index };
                })
              : null,
          };
        })
      : [];
    const newPayload = {
      id: data && data.id ? data.id : '',
      groups: groupsPayload,
    };
    return modifyGroupsReason(newPayload, ({ success }) => {
      if (success) doGetGroupsReason();
    });
  };
  return (
    <div className="content-wrapper">
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            {modeCreateConfigGroupReasonCode === 2 && (
              <NavLink
                className="button x-small"
                to={
                  data && data.id
                    ? RouteName.arOperationsConfigGetReasonGroupModify.path
                    : RouteName.arOperationsConfigGetReasonGroupAdd.path
                }
              >
                {t('label.createNewGroupsReason')}
              </NavLink>
            )}
            {modeCreateConfigGroupReasonCode !== 2 && (
              <NavLink className="button x-small" to={RouteName.arOperationsAdjustments.path}>
                {t('label.back')}
              </NavLink>
            )}
            <QuickNotesConfig />
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                {
                  name: t('arOperationPage:sidebar.groupsReason'),
                  url: RouteName.arOperationsConfigGetReasonGroup.path,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <form
        ref={formRef}
        noValidate
        onSubmit={modeModifyConfigGroupReasonCode === 2 ? onSubmit : () => {}}
        className={`col-md-12 p-2 card card-statistics needs-validation ${wasValidated ? 'was-validated' : ''}`}
      >
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('arOperationPage:sidebar.groupsReason')} />
          </div>
        </div>
        <CollapsibleTable
          columns={headerTable}
          data={data && data.groups && data.groups.length ? data.groups : []}
          indexViewer={activeTab}
          onClickRow={onToggleTab}
        >
          <br />
          <div className="col-md-12">
            <DataTable
              columns={headerTableReason}
              isSupportRemoveIndex
              data={
                data &&
                data.groups &&
                data.groups.length &&
                data.groups[activeTab] &&
                data.groups[activeTab].reasonCodes
                  ? data.groups[activeTab].reasonCodes
                  : []
              }
            />
          </div>
          <div className="form-group col-md-12 buttons-attibute">
            <button type="button" className="button button-border black x-small" onClick={onHandleAddReason}>
              +
              {t('label.addReasonToGroup')}
            </button>
          </div>
        </CollapsibleTable>
        <div className="form-group col-md-12 buttons-attibute border-top">
          {modeModifyConfigGroupReasonCode === 2 && (
            <button type="submit" className="button button-border x-small float-right ml-2">
              {t('label.modify')}
            </button>
          )}
          <button type="button" onClick={doGetGroupsReason} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </form>
      <ModalReasonCodeConfig
        isOpen={isOpen}
        onSelect={onSelect}
        onCancel={() => setIsOpen(false)}
        selectedParentId={itemSelect ? itemSelect.index : null}
      />
    </div>
  );
};

GroupsReason.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

GroupsReason.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default connect(mapStateToProps, { getGroupsReason, modifyGroupsReason })(GroupsReason);
