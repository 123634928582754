import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import OfferForm from './OfferForm';
import { makeGetPriceOfferDetail, makeErrorMessage } from '../selectors';
import { makeGetPermissionsPricingManagement, makeGetCcpTime, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  getPriceOfferDetail,
  modifyPriceOffer,
  updatePriceOfferStatus,
  getUsageContainerSpec,
  getPriceOfferAudit,
  convertJsonToPriceOffer,
} from '../actions';
import {
  checkPermissionModifyPriceOffer,
  checkPermissionApprovePriceOffer,
  checkPermissionSellablePriceOffer,
  checkPermissionNonSellablePriceOffer,
  checkPermissionSunsetPriceOffer,
} from '../CheckPermission';
import {
  getCcpPropertiesConfig,
  getAccumulatorsConfig,
  getGrantsConfig,
  searchProvisioningAttributesConfigItem,
  getCcpDateTimeConfig,
  getConfigUsageTypeConfig,
} from '../../App/actions';

class PriceOfferEdit extends Component {
  state = {
    isSubmitting: false,
    priceOfferInfo: {},
    accumulatorList: [],
    grantList: [],
    usageContainerRecordList: [],
    isShowBilledService: false,
    defaultCurrency: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldPriceOfferInfo === props.priceOfferInfo) return null;
    return {
      priceOfferInfo: props.priceOfferInfo,
      oldPriceOfferInfo: props.priceOfferInfo,
    };
  }

  componentDidMount() {
    const {
      getAccumulatorsConfig,
      getGrantsConfig,
      getUsageContainerSpec,
      getCcpPropertiesConfig,
      getCcpDateTimeConfig,
      ccpTime,
      ccpPropertyList,
    } = this.props;
    this.doGetPriceOfferDetail();
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
    getAccumulatorsConfig(({ data, success }) => {
      if (success) {
        this.setState({ accumulatorList: data && data.accumulatorList ? data.accumulatorList : [] });
      }
    });

    getGrantsConfig(({ data, success }) => {
      if (success) {
        this.setState({ grantList: data && data.length ? data : [] });
      }
    });

    getUsageContainerSpec(({ data, success }) => {
      if (success) {
        this.setState({ usageContainerRecordList: data && data.length ? data : [] });
      }
    });

    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          this.doInitCcpPropertyList(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitCcpPropertyList(ccpPropertyList);
    }
  }

  doInitCcpPropertyList = ccpPropertyList => {
    const billedServiceProperty = ccpPropertyList.find(val => val.property === 'billableServiceEnabled');
    const defaultCurrency = ccpPropertyList.find(val => val.property === 'defaultCurrency');
    this.setState({
      isShowBilledService: !!(billedServiceProperty && billedServiceProperty.value === 'true'),
      defaultCurrency: defaultCurrency?.value ? defaultCurrency.value : null,
    });
  };

  doGetPriceOfferDetail = () => {
    const { getPriceOfferDetail } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    getPriceOfferDetail(id);
  };

  onSave = (data, cb = null) => {
    const { priceOfferInfo, modifyPriceOffer, updatePriceOfferStatus } = this.props;
    const { status, customerPricing = [], pricingType, ...dataItem } = data;
    const newPricing = customerPricing ? [...customerPricing] : [];
    // const num = newPricing.length;
    // Array.from(new Array(10)).map((d, idx) => newPricing.push({ index: num + idx + 1 }));
    if (dataItem.recurringPricing) {
      const newPricing = dataItem.recurringPricing.map(e => {
        const newEle = { ...e };
        delete newEle.unit;
        delete newEle.frequency;
        return newEle;
      });
      dataItem.recurringPricing = newPricing;
    }
    const changedStatus = status !== priceOfferInfo.status;
    this.setState({ isSubmitting: true });
    if (!changedStatus) {
      modifyPriceOffer({ ...dataItem, customerPricing: newPricing }, ({ success }) => {
        this.setState({ isSubmitting: false });
        if (cb) cb({ success });
        if (success) {
          this.doGetPriceOfferDetail();
        }
      });
    } else {
      modifyPriceOffer({ ...dataItem, customerPricing: newPricing }, ({ success }) => {
        if (cb) cb({ success });
        if (success) {
          updatePriceOfferStatus({ id: priceOfferInfo.id, status }, () => {
            this.setState({ isSubmitting: false });
            this.doGetPriceOfferDetail();
          });
        } else {
          this.setState({ isSubmitting: false });
        }
      });
    }
  };

  render() {
    const {
      priceOfferInfo,
      isSubmitting,
      accumulatorList,
      grantList,
      usageContainerRecordList,
      isShowBilledService,
      defaultCurrency,
    } = this.state;
    const {
      permissionsPricing,
      t,
      location,
      getPriceOfferAudit,
      convertJsonToPriceOffer,
      ccpTime,
      match: {
        params: { id },
      },
      searchProvisioningAttributesConfigItem,
      getConfigUsageTypeConfig,
    } = this.props;
    let modeModifyPriceOffer = 0;
    let modeApprovePriceOffer = 0;
    let modeSellablePriceOffer = 0;
    let modeNonSellablePriceOffer = 0;
    let modeSunsetPriceOffer = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeModifyPriceOffer = checkPermissionModifyPriceOffer({ listPermission: listPermissionPricing });
      modeApprovePriceOffer = checkPermissionApprovePriceOffer({ listPermission: listPermissionPricing });
      modeSellablePriceOffer = checkPermissionSellablePriceOffer({ listPermission: listPermissionPricing });
      modeNonSellablePriceOffer = checkPermissionNonSellablePriceOffer({ listPermission: listPermissionPricing });
      modeSunsetPriceOffer = checkPermissionSunsetPriceOffer({ listPermission: listPermissionPricing });
    }
    if (modeModifyPriceOffer === 0) return '';

    const optionsAccumulatorId = accumulatorList.map(item => ({
      label: item.accumulatorid,
      value: item.accumulatorid,
    }));

    const optionsGrantId = grantList.map(item => ({
      label: item.resourceId,
      value: item.resourceId,
    }));

    const { effectiveDate, ...rest } = priceOfferInfo;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.pricingPriceOffer.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.priceOffer'), url: RouteNames.pricingPriceOffer.path },
                  { name: priceOfferInfo && priceOfferInfo.name ? priceOfferInfo.name : 'Edit' },
                ]}
              />
            </div>
          </div>
        </div>
        <br />
        <OfferForm
          item={{ ...rest, effectiveDate: effectiveDate || ccpTime || null }}
          onSubmit={this.onSave}
          modeModifyPriceOffer={modeModifyPriceOffer}
          modeApprovePriceOffer={modeApprovePriceOffer}
          modeSellablePriceOffer={modeSellablePriceOffer}
          modeNonSellablePriceOffer={modeNonSellablePriceOffer}
          modeSunsetPriceOffer={modeSunsetPriceOffer}
          isSubmitting={isSubmitting}
          creatingMode={false}
          optionsAccumulatorId={optionsAccumulatorId}
          optionsGrantId={optionsGrantId}
          usageContainerRecordList={usageContainerRecordList}
          isShowBilledService={isShowBilledService}
          onCancel={this.doGetPriceOfferDetail}
          getAuditData={getPriceOfferAudit}
          id={id}
          convertAuditJsonData={convertJsonToPriceOffer}
          title="label.priceOffer"
          searchProvisioningAttributesConfigItem={searchProvisioningAttributesConfigItem}
          defaultCurrency={defaultCurrency}
          getConfigUsageTypeConfig={getConfigUsageTypeConfig}
        />
      </div>
    );
  }
}

PriceOfferEdit.propTypes = {
  priceOfferInfo: PropTypes.objectOf(PropTypes.any),
  modifyPriceOffer: PropTypes.func.isRequired,
  updatePriceOfferStatus: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  getPriceOfferDetail: PropTypes.func.isRequired,
  getAccumulatorsConfig: PropTypes.func.isRequired,
  getGrantsConfig: PropTypes.func.isRequired,
  getPriceOfferAudit: PropTypes.func.isRequired,
  convertJsonToPriceOffer: PropTypes.func.isRequired,
  searchProvisioningAttributesConfigItem: PropTypes.func.isRequired,
  getConfigUsageTypeConfig: PropTypes.func.isRequired,
};

PriceOfferEdit.defaultProps = {
  permissionsPricing: {},
  priceOfferInfo: {},
  match: {},
};

const mapStateToProps = createStructuredSelector({
  priceOfferInfo: makeGetPriceOfferDetail() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
  ccpTime: makeGetCcpTime() || '',
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

const mapDispatchToProps = dispatch => ({
  getPriceOfferDetail: (e, cb) => dispatch(getPriceOfferDetail(e, cb)),
  modifyPriceOffer: (e, cb) => dispatch(modifyPriceOffer(e, cb)),
  updatePriceOfferStatus: (e, cb) => dispatch(updatePriceOfferStatus(e, cb)),
  getAccumulatorsConfig: cb => dispatch(getAccumulatorsConfig(cb)),
  getGrantsConfig: cb => dispatch(getGrantsConfig(cb)),
  getUsageContainerSpec: cb => dispatch(getUsageContainerSpec(cb)),
  getCcpPropertiesConfig: (e, cb) => dispatch(getCcpPropertiesConfig(e, cb)),
  getPriceOfferAudit: (e, cb) => dispatch(getPriceOfferAudit(e, cb)),
  convertJsonToPriceOffer: (e, cb) => dispatch(convertJsonToPriceOffer(e, cb)),
  searchProvisioningAttributesConfigItem: (e, cb) => dispatch(searchProvisioningAttributesConfigItem(e, cb)),
  getCcpDateTimeConfig: (e, cb) => dispatch(getCcpDateTimeConfig(e, cb)),
  getConfigUsageTypeConfig: (e, cb) => dispatch(getConfigUsageTypeConfig(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'pricingPage'])(compose(withConnect)(PriceOfferEdit));
