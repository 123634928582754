/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ModalSelector from '../../Modals/ModalSelector';
import { formatNumberValue } from '../../../utils/utils';

class ModalSelectBillableService extends PureComponent {
  searchHanldeServiceName = (payload, cb) => {
    const { getOrderSummaryByAccountId, accountId } = this.props;
    if (accountId)
      getOrderSummaryByAccountId(accountId, resp => {
        cb({ success: true, data: resp?.data?.accountServicesSummaries || [] });
      });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'serviceName',
          label: 'common:label.name',
        },
        {
          name: 'alternateDescription',
          label: 'common:label.alternateDescription',
        },
        {
          name: 'serviceType',
          label: 'common:label.serviceType',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'releaseDate',
          label: 'common:label.releaseDate',
        },
        {
          name: 'quantity',
          label: 'common:label.quantity',
        },
        {
          name: 'servicePrice',
          label: 'common:label.price',
          render: (colName, item) => {
            // return <span>{item.servicePrice !== null ? formatNumberValue(item.servicePrice) : ''}</span>;
            return <span>{item.servicePrice !== null ? item.servicePrice : ''}</span>;
          },
        },
        {
          name: 'capacity',
          label: 'common:label.capacity',
          render: (colName, item) => {
            // return <span>{item.capacity !== null ? formatNumberValue(item.capacity) : ''}</span>;
            return <span>{item.capacity !== null ? item.capacity : ''}</span>;
          },
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
        {
          name: 'serviceId',
          label: 'common:label.serviceId',
        },
        {
          name: 'uom',
          label: 'common:label.unitOfMeasure',
        },
        {
          name: 'omsInternalId',
          label: 'common:label.instanceId',
        },
        {
          name: 'loadType',
          label: 'common:label.loadType',
        },
        {
          name: 'productDescription',
          label: 'common:label.productDescription',
        },
        {
          name: 'totalPrice',
          label: 'common:label.totalPrice',
          render: (colName, item) => {
            // return <span>{item.totalPrice !== null ? formatNumberValue(item.totalPrice) : ''}</span>;
            return <span>{item.totalPrice !== null ? item.totalPrice: ''}</span>;
          },
        },
        {
          name: 'recurringUnit',
          label: 'common:label.recurringUnit',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:recurringUnit')().find(val => val.value === item.recurringUnit) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'recurringFrequency',
          label: 'common:label.recurringFrequency',
        },
        {
          name: 'advanceFlag',
          label: 'label.advanceFlag',
          render: (colName, item) => {
            return <span>{item.advanceFlag ? 'TRUE' : item.advanceFlag === false ? 'FALSE' : null}</span>;
          },
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseServiceName')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeServiceName}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="omsInternalId"
      />
    );
  }
}

ModalSelectBillableService.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getOrderSummaryByAccountId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSelectBillableService.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(ModalSelectBillableService);
