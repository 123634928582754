import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size, groupBy, map } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import arrayMove from 'array-move';
import {
  GenericInput,
  FormCollapse,
  TitleFrom,
  DataTablDragDrop,
  CollapsibleTableDragDrop,
  DataTable,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsOrderManagement } from '../../App/selectors';
import { getApiByGatewayNameConfig } from '../../App/actions';
import {
  searchProvisioningTaskSequence,
  modifyProvisioningTaskSequence,
  createProvisioningTaskSequence,
} from '../actions';
import {
  checkPermissionGetProvSequenceTaskConfig,
  checkPermissionModifyProvSequenceTaskConfig,
  checkPermissionCreateProvSequenceTaskConfig,
} from '../CheckPermission';
import { getLastIndex, convertLabelWithExistOptions } from '../../../utils/utils';

const collapsibleMenuSequence = [
  {
    title: 'label.outboundSequence',
    state: 'Outbound Sequence',
  },
  {
    title: 'label.inboundSequence',
    state: 'Inbound Sequence',
  },
];

class ProvisioningSequence extends React.PureComponent {
  state = {
    data: {},
    wasValidated: false,
    activeTab: {},
    activeSequenceTab: {},
    apiNamesOptions: [],
    activeSubTableTab: {},
    activeMainTab: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { getApiByGatewayNameConfig } = this.props;
    this.doGetProvisioningTaskSequenceConfig();
    getApiByGatewayNameConfig({ gatewayName: 'PROVISIONING_GATEWAY' }, ({ success, data }) => {
      if (success && data) {
        const apiNamesOptions = data.map(val => ({ label: val.apiName, value: val.apiName }));
        this.setState({ apiNamesOptions });
      }
    });
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetProvisioningTaskSequenceConfig = () => {
    const { searchProvisioningTaskSequence } = this.props;
    searchProvisioningTaskSequence('', ({ data }) => {
      let newData = [];
      if (data && data.length) {
        newData = data.map(dt => ({ ...dt, isDefault: true, defaultOrderType: dt.orderType }));
      }
      this.setState({
        data: newData || [],
        activeTab: {},
        activeSequenceTab: {},
        activeSubTableTab: {},
        activeMainTab: {},
      });
    });
  };

  addNewItem = ({ index, type }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData[index][type || 'sequences']) newData[index][type || 'sequences'] = [];
      newData[index][type || 'sequences'].forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      if (!type) {
        newData[index][type || 'sequences'].push({
          index: lastIndex + 1,
          apiName: null,
          apiCategory: null,
          status: null,
          onErrorResubmit: 'false',
          validateOnly: 'false',
          queueName: '',
          merchantName: null,
          synchronousResponse: 'false',
        });
      } else {
        newData[index][type || 'sequences'].push({
          index: lastIndex + 1,
          apiName: null,
          serviceTypes: null,
          status: null,
          action: null,
          level: null,
        });
      }

      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewPrerequisiteInboundApis = ({ index }) => {
    try {
      const { data, activeSubTableTab } = this.state;
      const newData = cloneDeep(data);
      if (!newData[index].sequences[activeSubTableTab.index].prerequisiteInboundApis)
        newData[index].sequences[activeSubTableTab.index].prerequisiteInboundApis = [];
      newData[index].sequences[activeSubTableTab.index].prerequisiteInboundApis.push({
        apiName: null,
        index: getLastIndex({ data: newData[index].sequences[activeSubTableTab.index].prerequisiteInboundApis }),
        isNew: true,
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewOderType = () => {
    const { t } = this.props;
    try {
      const { data } = this.state;
      // const listOptions = t('selections:types')();
      let newData = cloneDeep(data);
      if (!newData) newData = [];
      newData.push({
        orderType: null,
        sequences: [],
        isNew: true,
      });
      return this.setState({
        data: newData,
        activeMainTab: { name: 'state-undefined', isActive: true },
        activeTab: { name: `state-${newData.length - 1}`, isActive: true },
      });
      // const existOptions = listOptions.filter(val => newData.map(i => i.orderType).indexOf(val.value) === -1);
      // if (existOptions && existOptions.length) {
      //   newData.push({
      //     orderType: existOptions.filter(val => newData.map(i => i.orderType).indexOf(val.value) === -1)[0].value,
      //     sequences: [],
      //     isNew: true,
      //   });
      //   return this.setState({
      //     data: newData,
      //     activeMainTab: { name: 'state-undefined', isActive: true },
      //     activeTab: { name: `state-${newData.length - 1}`, isActive: true },
      //   });
      // }
      // return toast.error(t('message.orderTypesAlreadyConfigured'));
    } catch (error) {
      return console.log(error);
    }
  };

  onHandleSave = ({ evt, item }) => {
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    this.setState({ wasValidated: false });

    const { modifyProvisioningTaskSequence, createProvisioningTaskSequence } = this.props;
    const { type, isDefault, isNew, keyIndex, defaultOrderType, sequences, ...payload } = cloneDeep(item);
    if (item.id) {
      const { id, isDefault, keyIndex, isNew, defaultOrderType, sequences, ...payloadModify } = cloneDeep(item);
      let newSequences = cloneDeep(sequences);
      if (newSequences && newSequences.length) {
        newSequences = newSequences.map(val => {
          const { prerequisiteInboundApis, ...rest } = val;
          let newPrerequisiteInboundApis = null;
          if (prerequisiteInboundApis && prerequisiteInboundApis.length) {
            const existData = prerequisiteInboundApis.filter(val => !val.isNew);
            const newData = prerequisiteInboundApis
              .filter(val => val.isNew)
              .map((item, index) => {
                const { isNew, ...rest } = item;
                return { ...rest, index: getLastIndex({ data: existData }) + index };
              });

            newPrerequisiteInboundApis = [...existData, ...newData];
          }
          return { ...rest, prerequisiteInboundApis: newPrerequisiteInboundApis };
        });
      }
      return modifyProvisioningTaskSequence({ id, ...payloadModify, sequences: newSequences }, ({ success }) => {
        // if (success) this.doGetProvisioningTaskSequenceConfig();
      });
    }

    let newSequences = cloneDeep(sequences);
    if (newSequences && newSequences.length) {
      newSequences = newSequences.map(val => {
        const { prerequisiteInboundApis, ...rest } = val;
        let newPrerequisiteInboundApis = null;
        if (prerequisiteInboundApis && prerequisiteInboundApis.length) {
          const newData = prerequisiteInboundApis.map((item, index) => {
            const { isNew, ...rest } = item;
            return { ...rest, index: index + 1 };
          });

          newPrerequisiteInboundApis = newData;
        }
        return { ...rest, prerequisiteInboundApis: newPrerequisiteInboundApis };
      });
    }
    return createProvisioningTaskSequence({ ...payload, sequences: newSequences }, ({ success }) => {
      if (success) this.doGetProvisioningTaskSequenceConfig();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onToggleMainTab = name => {
    const { activeMainTab } = this.state;
    if (activeMainTab.name === name) {
      this.setState({ activeMainTab: { name, isActive: !activeMainTab.isActive } });
    } else {
      this.setState({ activeMainTab: { name, isActive: true } });
    }
  };

  onToggleSequenceTab = (name, index) => {
    const { activeSequenceTab } = this.state;
    if (activeSequenceTab.name === name) {
      this.setState({
        activeSequenceTab: { name, isActive: !activeSequenceTab.isActive, indexSelect: index },
      });
    } else {
      this.setState({ activeSequenceTab: { name, isActive: true, indexSelect: index } });
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTable = ({ name, value, index, indexParent, type }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      newData[indexParent][type || 'sequences'][index][name] = newValue;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangePrerequisiteInboundApisTable = ({ name, value, index, indexParent }) => {
    try {
      const { data, activeSubTableTab } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      newData[indexParent].sequences[activeSubTableTab.index].prerequisiteInboundApis[index][name] = newValue;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemovePrerequisiteInboundApisTable = ({ index, indexParent }) => {
    try {
      const { data, activeSubTableTab } = this.state;
      const newData = cloneDeep(data);
      if (newData[indexParent].sequences[activeSubTableTab.index].prerequisiteInboundApis[index].isNew) {
        newData[indexParent].sequences[activeSubTableTab.index].prerequisiteInboundApis.splice(index, 1);
      } else {
        newData[indexParent].sequences[activeSubTableTab.index].prerequisiteInboundApis[index] = {
          index: newData[indexParent].sequences[activeSubTableTab.index].prerequisiteInboundApis[index].index,
        };
      }

      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItemTable = ({ index, indexParent, type }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[indexParent][type || 'sequences'][index] = {
        index: newData[indexParent][type || 'sequences'][index].index,
      };
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveOrderType = ({ index, item }) => {
    const { modifyProvisioningTaskSequence } = this.props;
    if (item && item.id) {
      modifyProvisioningTaskSequence({ id: item.id || null }, () => {
        this.doGetProvisioningTaskSequenceConfig();
      });
    } else {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.splice(index, 1);
      this.setState({ data: newData });
    }
  };

  onSortEnd = ({ oldIndex, newIndex, index, key }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      const orderedList = arrayMove(data[index][key], oldIndex, newIndex);
      newData[index][key] = orderedList;
      this.setState({
        data: newData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleSubTableTab = (index, item, indexItem, key) => {
    const { activeSubTableTab } = this.state;
    if (activeSubTableTab.index === indexItem) this.setState({ activeSubTableTab: {} });
    if (activeSubTableTab.index !== indexItem) {
      this.setState({ activeSubTableTab: { key, index: indexItem } });
    }
  };

  render() {
    const { t, permissionOrder } = this.props;
    let modeCreateProvSequenceTaskConfig = 0;
    let modeGetProvSequenceTaskConfig = 0;
    let modeModifyProvSequenceTaskConfig = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeCreateProvSequenceTaskConfig = checkPermissionCreateProvSequenceTaskConfig({ listPermission });
      modeGetProvSequenceTaskConfig = checkPermissionGetProvSequenceTaskConfig({ listPermission });
      modeModifyProvSequenceTaskConfig = checkPermissionModifyProvSequenceTaskConfig({ listPermission });
    }
    if (!modeGetProvSequenceTaskConfig) return '';
    const {
      data,
      activeTab,
      wasValidated,
      activeSequenceTab,
      apiNamesOptions,
      activeSubTableTab,
      activeMainTab,
    } = this.state;

    const additionalApiGetwayOptions = t('selections:additionalApiGetway')();

    let groupOrderType = null;
    if (data && data.length) {
      const newData = data.map((i, index) => ({ ...i, keyIndex: index }));
      groupOrderType = groupBy(newData, item => {
        return item.defaultOrderType;
      });
    }

    const omsTaskColumns = [
      {
        name: 'prerequisiteInboundSequence',
        label: 'label.prerequisiteInboundSequence',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => this.onToggleSubTableTab(idx, item, idx, 'inboundSequence')}
          >
            <i
              className={`fa ${
                activeSubTableTab && activeSubTableTab.key === 'inboundSequence' && activeSubTableTab.index === idx
                  ? 'fa-minus'
                  : 'fa-plus'
              }`}
            />
          </button>
        ),
      },
      {
        name: 'apiCategory',
        label: 'label.apiCategory',
        style: { minWidth: '130px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.apiCategory}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="apiCategory"
            type="select"
            tOptions="selections:apiCategory"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'apiName',
        label: 'label.apiName',
        style: { minWidth: '260px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.apiName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="apiName"
            type="select"
            // tOptions="selections:gatewayApi"
            options={convertLabelWithExistOptions({
              defaultOptions: t('selections:gatewayApi')(),
              newOptions: [...apiNamesOptions, ...additionalApiGetwayOptions] || [...additionalApiGetwayOptions],
            })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        style: { minWidth: '130px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="status"
            type="select"
            tOptions="selections:commonStatus"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'merchantName',
        label: 'label.merchantName',
        style: { minWidth: '220px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.merchantName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="merchantName"
            type="select"
            tOptions="selections:merchantName"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'synchronousResponse',
        label: 'label.synchronousResponse',
        style: { minWidth: '120px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="synchronousResponse"
            value={item.synchronousResponse === 'true' || item.synchronousResponse === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'onErrorResubmit',
        label: 'label.onErrorResubmit',
        style: { minWidth: '120px' },
        tooltipLabel: 'message.onErrorResubmitNote',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="onErrorResubmit"
            value={item.onErrorResubmit === 'true' || item.onErrorResubmit === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            menuPortalTarget
            disabled={item.errorApiName || item.executeOnError}
          />
        ),
      },
      {
        name: 'executeOnError',
        label: 'label.executeOnError',
        tooltipLabel: 'message.executeOnErrorNote',
        style: { minWidth: '120px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="executeOnError"
            value={item.executeOnError}
            type="checkbox"
            menuPortalTarget
            disabled={
              (item.onErrorResubmit &&
                (item.onErrorResubmit === true ||
                  item.onErrorResubmit === 'true' ||
                  item.onErrorResubmit === 'TRUE')) ||
              item.errorApiName
            }
          />
        ),
      },
      {
        name: 'errorApiName',
        label: 'label.errorApiName',
        tooltipLabel: 'message.errorApiNameNote',
        style: { minWidth: '260px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.errorApiName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="errorApiName"
            type="select"
            tOptions="selections:gatewayApi"
            menuPortalTarget
            disabled={
              (item.onErrorResubmit &&
                (item.onErrorResubmit === true ||
                  item.onErrorResubmit === 'true' ||
                  item.onErrorResubmit === 'TRUE')) ||
              item.executeOnError
            }
          />
        ),
      },
      {
        name: 'validateOnly',
        label: 'label.validateOnly',
        style: { minWidth: '120px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="validateOnly"
            value={item.validateOnly === 'true' || item.validateOnly === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'queueName',
        label: 'label.queueName',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="queueName"
            value={item.queueName}
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveItemTable({ index: idx, indexParent })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const inBoundSequence = [
      {
        name: 'apiName',
        label: 'label.apiName',
        style: { minWidth: '260px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.apiName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent, type: 'inputs' })}
            name="apiName"
            type="select"
            // tOptions="selections:gatewayApi"
            options={convertLabelWithExistOptions({
              defaultOptions: t('selections:gatewayApi')(),
              newOptions: [...apiNamesOptions, ...additionalApiGetwayOptions] || [...additionalApiGetwayOptions],
            })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'serviceTypes',
        label: 'label.serviceType',
        style: { minWidth: '200px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.serviceTypes}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent, type: 'inputs' })}
            name="serviceTypes"
            type="select"
            tOptions="selections:serviceType"
            menuPortalTarget
            isMulti
            isSupportMultiString
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        style: { minWidth: '120px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent, type: 'inputs' })}
            name="status"
            type="select"
            tOptions="selections:commonStatus"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'merchantName',
        label: 'label.merchantName',
        style: { minWidth: '220px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.merchantName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent, type: 'inputs' })}
            name="merchantName"
            type="select"
            tOptions="selections:merchantName"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'action',
        label: 'label.action',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.action}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent, type: 'inputs' })}
            name="action"
            type="select"
            tOptions="selections:orderAction"
            menuPortalTarget
            required
          />
        ),
      },
      {
        name: 'level',
        label: 'label.level',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.level}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent, type: 'inputs' })}
            name="level"
            type="select"
            tOptions="selections:provisioningLevel"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveItemTable({ index: idx, indexParent, type: 'inputs' })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.searchAndList.path}
          titleBtn={t('label.back')}
          titleRight={t('orderPage:sidebar.provisioningSequence')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('common:label.provisioningTaskSequence')} />
            {map(groupOrderType, (newData, orderType) => {
              const optionsOrderType = t('selections:types')();
              let titleHeader = 'null';
              if (orderType) {
                const listSelect = optionsOrderType.filter(op => orderType.split(',').indexOf(op.value) !== -1);
                if (listSelect && listSelect.length) {
                  listSelect.forEach(i => {
                    titleHeader = titleHeader !== 'null' ? `${titleHeader}, ${i.label}` : i.label;
                  });
                }
              }
              return (
                <FormCollapse
                  key={`key-${orderType}`}
                  isActive={`state-${orderType}` === activeMainTab.name && activeMainTab.isActive}
                  title={`${t('label.orderType')}: ${titleHeader || ''}`}
                  state={`state-${orderType}`}
                  onToggleTab={this.onToggleMainTab}
                  isNoT
                >
                  {newData &&
                    newData.length > 0 &&
                    newData.map((item, index) => {
                      if (size(item) < 2) return '';
                      // const optionsServiceType = t('selections:serviceType')();
                      // const typeSelect = optionsServiceType.find(val => val.value === item.serviceType);

                      const optionsServiceType = t('selections:serviceType')();
                      let titleHeaderServiceType = 'null';
                      if (item?.serviceType) {
                        const listSelect = optionsServiceType.filter(
                          op => item.serviceType.split(',').indexOf(op.value) !== -1
                        );
                        if (listSelect && listSelect.length) {
                          listSelect.forEach(i => {
                            titleHeaderServiceType =
                              titleHeaderServiceType !== 'null' ? `${titleHeaderServiceType}, ${i.label}` : i.label;
                          });
                        }
                      }

                      const apiOptionsInBound = [];
                      if (item.inputs && item.inputs.length) {
                        item.inputs.forEach(val => {
                          if (val.apiName) {
                            apiOptionsInBound.push({ label: val.apiName, value: val.apiName });
                          }
                        });
                      }

                      const prerequisiteInboundApisColumns = [
                        {
                          name: 'apiName',
                          label: 'label.apiName',
                          style: { minWidth: '200px' },
                          required: true,
                          render: (colName, item, idx, indexParent) => (
                            <GenericInput
                              value={item.apiName}
                              wrapperClass="col-md-12"
                              onChange={({ name, value }) =>
                                this.onChangePrerequisiteInboundApisTable({
                                  name,
                                  value,
                                  index: idx,
                                  indexParent,
                                })
                              }
                              name="apiName"
                              type="select"
                              // tOptions="selections:gatewayApi"
                              // options={apiOptionsInBound}
                              // options={convertLabelWithExistOptions({
                              //   defaultOptions: t('selections:gatewayApi')(),
                              //   newOptions: apiOptionsInBound,
                              // })}
                              options={convertLabelWithExistOptions({
                                defaultOptions: t('selections:gatewayApi')(),
                                newOptions: [...apiNamesOptions, ...additionalApiGetwayOptions] || [
                                  ...additionalApiGetwayOptions,
                                ],
                              })}
                              menuPortalTarget
                              isCreateOption
                              required
                            />
                          ),
                        },
                        {
                          name: 'remove',
                          label: 'label.remove',
                          render: (colName, item, idx, indexParent) => {
                            return (
                              <div className="form-group col-md-12">
                                <button
                                  type="button"
                                  className="btn-phone"
                                  onClick={() =>
                                    this.onRemovePrerequisiteInboundApisTable({
                                      index: idx,
                                      indexParent,
                                    })
                                  }
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </div>
                            );
                          },
                        },
                      ];

                      return (
                        <form
                          noValidate
                          ref={this.formRef}
                          className={`needs-validation ml-3 mr-3 mt-1 mb-1 ${wasValidated ? 'was-validated' : ''}`}
                        >
                          <FormCollapse
                            key={`key-${item.keyIndex}`}
                            isActive={`state-${item.keyIndex}` === activeTab.name && activeTab.isActive}
                            title={`${t('label.serviceType')}: ${titleHeaderServiceType || ''}`}
                            state={`state-${item.keyIndex}`}
                            onToggleTab={this.onToggleTab}
                            isNoT
                            isSubCollapse
                            isActiveSubForm={`state-${item.keyIndex}` === activeTab.name && activeTab.isActive}
                          >
                            <div className="col-md-12 row p-3">
                              <div className="col-md-12 row">
                                <GenericInput
                                  label="label.serviceType"
                                  name="serviceType"
                                  value={item.serviceType}
                                  type="select"
                                  tOptions="selections:serviceType"
                                  wrapperClass="col-md-3 pb-3"
                                  onChange={evt => this.onHandleChange({ ...evt, index: item.keyIndex })}
                                  isMulti
                                  isSupportMultiString
                                />
                                <GenericInput
                                  label="label.orderType"
                                  name="orderType"
                                  required
                                  value={item.orderType}
                                  type="select"
                                  options={optionsOrderType || []}
                                  wrapperClass="col-md-3 pb-3"
                                  isNoClearable
                                  disabled={item.isDefault}
                                  readOnly={item.isDefault}
                                  onChange={evt => this.onHandleChange({ ...evt, index: item.keyIndex })}
                                />
                                <GenericInput
                                  label="label.provisioningSequence"
                                  name="provisioningSequence"
                                  value={item.provisioningSequence}
                                  type="number"
                                  wrapperClass="col-md-3 pb-3"
                                  onChange={evt => this.onHandleChange({ ...evt, index: item.keyIndex })}
                                />
                                <button
                                  type="button"
                                  className="btn-delete float-right"
                                  onClick={() => this.onRemoveOrderType({ index: item.keyIndex, item })}
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </div>
                              <div className="col-md-12 p-0">
                                {collapsibleMenuSequence.map((val, id) => {
                                  if (
                                    activeSequenceTab.name === 'Outbound Sequence' &&
                                    activeSequenceTab.isActive &&
                                    activeSequenceTab.indexSelect === item.keyIndex
                                  ) {
                                    return (
                                      <FormCollapse
                                        key={`key-${id}`}
                                        isActive={
                                          val.state === activeSequenceTab.name &&
                                          activeSequenceTab.isActive &&
                                          activeSequenceTab.indexSelect === item.keyIndex
                                        }
                                        title={val.title}
                                        state={val.state}
                                        onToggleTab={name => this.onToggleSequenceTab(name, item.keyIndex)}
                                        isSubCollapse
                                        isActiveSubForm={
                                          val.state === activeSequenceTab.name &&
                                          activeSequenceTab.isActive &&
                                          activeSequenceTab.indexSelect === item.keyIndex
                                        }
                                      >
                                        <CollapsibleTableDragDrop
                                          indexParent={item.keyIndex}
                                          columns={omsTaskColumns}
                                          data={item && item.sequences ? item.sequences : []}
                                          isSupportRemoveIndex
                                          isFixedHeaderTable
                                          indexViewer={
                                            activeSubTableTab && activeSubTableTab.index !== -1
                                              ? activeSubTableTab.index
                                              : -1
                                          }
                                          onSortEnd={e =>
                                            this.onSortEnd({ ...e, index: item.keyIndex, key: 'sequences' })
                                          }
                                        >
                                          <div className="col-md-4 m-3">
                                            <DataTable
                                              columns={prerequisiteInboundApisColumns}
                                              data={
                                                item.sequences &&
                                                item.sequences[activeSubTableTab.index] &&
                                                item.sequences[activeSubTableTab.index].prerequisiteInboundApis
                                                  ? item.sequences[activeSubTableTab.index].prerequisiteInboundApis
                                                  : []
                                              }
                                              indexParent={item.keyIndex}
                                              isSupportRemoveIndex
                                            />
                                            <button
                                              type="button"
                                              className="button button-border black x-small ml-2"
                                              onClick={() =>
                                                this.addNewPrerequisiteInboundApis({ index: item.keyIndex })
                                              }
                                            >
                                              +
                                              {t('label.newPrerequisiteInboundSequence')}
                                            </button>
                                          </div>
                                        </CollapsibleTableDragDrop>
                                        <div className="form-group col-md-12 buttons-attibute pt-4">
                                          <button
                                            type="button"
                                            className="button button-border black x-small"
                                            onClick={() => this.addNewItem({ index: item.keyIndex })}
                                          >
                                            +
                                            {t('label.addNewOrderProvisioning')}
                                          </button>
                                        </div>
                                      </FormCollapse>
                                    );
                                  }
                                  return (
                                    <FormCollapse
                                      key={`key-${id}`}
                                      isActive={
                                        val.state === activeSequenceTab.name &&
                                        activeSequenceTab.isActive &&
                                        activeSequenceTab.indexSelect === item.keyIndex
                                      }
                                      title={val.title}
                                      state={val.state}
                                      onToggleTab={name => this.onToggleSequenceTab(name, item.keyIndex)}
                                      isSubCollapse
                                      isActiveSubForm={
                                        val.state === activeSequenceTab.name &&
                                        activeSequenceTab.isActive &&
                                        activeSequenceTab.indexSelect === item.keyIndex
                                      }
                                    >
                                      <DataTablDragDrop
                                        indexParent={item.keyIndex}
                                        columns={inBoundSequence}
                                        data={item && item.inputs ? item.inputs : []}
                                        isSupportRemoveIndex
                                        onSortEnd={e => this.onSortEnd({ ...e, index: item.keyIndex, key: 'inputs' })}
                                      />
                                      <div className="form-group col-md-12 buttons-attibute pt-4">
                                        <button
                                          type="button"
                                          className="button button-border black x-small"
                                          onClick={() => this.addNewItem({ index: item.keyIndex, type: 'inputs' })}
                                        >
                                          +
                                          {t('label.addNewOrderProvisioning')}
                                        </button>
                                      </div>
                                    </FormCollapse>
                                  );
                                })}
                              </div>

                              <div className="form-group col-md-12 buttons-attibute pt-4">
                                {modeModifyProvSequenceTaskConfig === 2 && (
                                  <button
                                    type="button"
                                    onClick={evt => this.onHandleSave({ evt, item })}
                                    className="button button-border x-small float-right"
                                  >
                                    {t('label.saveConfig')}
                                  </button>
                                )}
                              </div>
                            </div>
                          </FormCollapse>
                        </form>
                      );
                    })}
                </FormCollapse>
              );
            })}

            <div className="form-group col-md-12 buttons-attibute pt-4 mb-4">
              {modeCreateProvSequenceTaskConfig === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={this.onAddNewOderType}
                  disabled={
                    modeCreateProvSequenceTaskConfig === 1 || (data && data.length && !!data.find(v => v.isNew))
                  }
                >
                  +
                  {t('label.addNewSequence')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetProvisioningTaskSequenceConfig}
                className="button mr-2 button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

ProvisioningSequence.propTypes = {
  searchProvisioningTaskSequence: PropTypes.func,
  modifyProvisioningTaskSequence: PropTypes.func,
  createProvisioningTaskSequence: PropTypes.func,
};

ProvisioningSequence.defaultProps = {
  searchProvisioningTaskSequence: () => {},
  modifyProvisioningTaskSequence: () => {},
  createProvisioningTaskSequence: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchProvisioningTaskSequence,
    modifyProvisioningTaskSequence,
    createProvisioningTaskSequence,
    getApiByGatewayNameConfig,
  })(withRouter(ProvisioningSequence))
);
