import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigOperatorMap(dummy: "") {
      id
      type
      configOperatorMapList {
        index
        operatorId
        operatorName
      }
    }
  }
`;
