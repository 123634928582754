import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../constantsApp/countryList';
import SearchComponent from './SearchComponent';

class OfferSearchForm extends SearchComponent {
  searchInputs = [
    {
      name: 'id',
      label: 'common:label.id',
      type: 'text',
    },
    {
      name: 'name',
      label: 'common:label.name',
      type: 'text',
    },
    {
      name: 'salesChannel',
      label: 'common:label.salesChannel',
      type: 'select',
      tOptions: 'selections:salesChannel',
      isClearable: true,
    },
    {
      name: 'marketSegment',
      label: 'common:label.marketSegment',
      type: 'select',
      tOptions: 'selections:marketSegment',
      isClearable: true,
    },
    {
      name: 'accountType',
      label: 'common:label.accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      name: 'accountSubType',
      label: 'common:label.accountSubType',
      type: 'select',
      tOptions: 'selections:accountSubType',
      isClearable: true,
    },
    {
      name: 'serviceType',
      label: 'common:label.serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      name: 'pricingModel',
      label: 'common:label.pricingModel',
      type: 'select',
      tOptions: 'selections:pricingModel',
      isClearable: true,
    },
    {
      label: 'label.type',
      name: 'omsTaskType',
      tOptions: 'selections:omsTaskType',
      isClearable: true,
      type: 'select',
    },
    {
      name: 'transactionType',
      label: 'common:label.transactionType',
      type: 'select',
      tOptions: 'selections:transactionType',
      isClearable: true,
    },
    {
      name: 'status',
      label: 'common:label.status',
      type: 'select',
      tOptions: 'selections:statusPricing',
      isClearable: true,
    },
    {
      name: 'serviceAddOn',
      label: 'common:label.serviceAddOn',
      type: 'text',
    },
    {
      name: 'itemId',
      label: 'common:label.itemId',
      type: 'text',
    },
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      name: 'country',
      label: 'common:label.country',
      wrapperClass: 'col-md-3',
      options: countryList,
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.isProvisionable',
      name: 'isProvisionable',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-9';
}

OfferSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OfferSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OfferSearchForm);
