import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'serviceType', 'transactionType']);
  const modifyRateUnitSvcTxnMapCfg = `modifyRateUnitSvcTxnMapCfg(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyRateUnitSvcTxnMapCfg} {
      id
    }
  }
`;
};
