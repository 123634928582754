import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../GenericInput';
// import SubmitForm from '../common/SubmitForm';

class ProvisioningSearchForm extends PureComponent {
  state = {
    modalName: '',
  };

  searchInputs = [
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusProvisioning',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.provisioningId',
      name: 'provisioningId',
    },
  ];

  btnSpan = 'col-md-3';

  submitText = 'Search';

  clearText = 'Clear';

  componentDidMount() {
    this.setDefaultInputs();
  }

  setDefaultInputs = () => {
    const { optionsSelectAttr } = this.props;
    const newInput = [
      {
        label: 'common:label.provisioningType',
        name: 'type',
        options: optionsSelectAttr,
        type: 'select',
        isClearable: true,
      },
      ...this.searchInputs,
    ];
    newInput.map(input => this.setState({ [input.name]: null }));
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit, optionsSelectAttr } = this.props;
    const data = {};
    const newInput = [
      {
        label: 'common:label.provisioningType',
        name: 'type',
        options: optionsSelectAttr,
        type: 'select',
        isClearable: true,
      },
      ...this.searchInputs,
    ];
    newInput.map(input => (this.state[input.name] ? (data[input.name] = this.state[input.name]) : null));
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    // const { onSubmit } = this.props;
    this.setDefaultInputs();
    // if (onSubmit) {
    //   onSubmit({});
    // }
  };

  onSelect = (value, row) => {
    const { modalName } = this.state;
    this.setState({ [modalName]: value, modalName: '' });
  };

  onOpenModal = ({ modalName }) => {
    this.setState({ modalName });
  };

  onToggleModal = () => {
    this.setState({ modalName: '' });
  };

  render() {
    const { isSubmitting, t, optionsSelectAttr } = this.props;
    const newInput = [
      {
        label: 'common:label.provisioningType',
        name: 'type',
        options: optionsSelectAttr,
        type: 'select',
        isClearable: true,
      },
      ...this.searchInputs,
    ];
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          {newInput.map(item => (
            <GenericInput
              key={item.name}
              label={t && t(item.label) ? t(item.label) : item.label}
              value={this.state[item.name] || item.defaultValue}
              onChange={this.handleInputChange}
              onClick={item.fa ? () => this.onOpenModal({ modalName: item.name }) : () => {}}
              {...item}
            />
          ))}
          <div className={`form-group text-right btn-search-actions ${this.btnSpan}`}>
            <label htmlFor="validationCustom01se" />
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t ? t('label.clear') : this.clearText}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t ? t('label.search') : this.submitText}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ProvisioningSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ProvisioningSearchForm.defaultProps = {
  isSubmitting: false,
};

export default ProvisioningSearchForm;
