// Tax config
export const GET_TAX_CODE_CONFIG = 'app/taxation-management/GET_TAX_CODE_CONFIG';
export const GET_TAX_CODE_CONFIG_SUCCESS = 'app/taxation-management/GET_TAX_CODE_CONFIG_SUCCESS';
export const MODIFY_TAX_CODE_CONFIG = 'app/taxation-management/MODIFY_TAX_CODE_CONFIG';
export const CREATE_TAX_CODE_CONFIG = 'app/taxation-management/CREATE_TAX_CODE_CONFIG';

export const GET_3RD_PARTY_TAX_CONFIG = 'app/taxation-management/GET_3RD_PARTY_TAX_CONFIG';
export const GET_3RD_PARTY_TAX_CONFIG_SUCCESS = 'app/taxation-management/GET_3RD_PARTY_TAX_CONFIG_SUCCESS';
export const GET_TAX_TYPE_CONFIG = 'app/tasks/GET_TAX_TYPE_CONFIG';
export const MODIFY_TAX_TYPE_CONFIG = 'app/tasks/MODIFY_TAX_TYPE_CONFIG';
export const CREATE_TAX_TYPE_CONFIG = 'app/tasks/CREATE_TAX_TYPE_CONFIG';