import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GenericInput, CardHeaderForm } from '../common';
import PhoneGroup from '../PhoneGroup';

export default function QuoteDetailForm({
  dataField,
  onHandleChange,
  onChangeCheckBox,
  onChangePhone,
  colItem,
  title,
  accountId,
  children,
  quoteDetail,
  onClick,
}) {
  const { t } = useTranslation('common');
  return (
    <div className="col-md-12 mb-30">
      <div className="card card-statistics">
        {/* <CardHeaderForm
          title={title || 'Quote'}
          label={t('label.accountId')}
          value={accountId}
          hideNavTab={!accountId}
        /> */}

        <div className="card-body">
          <div className="group-flex pb-3">
            {!isEmpty(dataField) &&
              map(dataField, item => {
                if (item && (!isEmpty(item.optionSelect) || item.tOptions)) {
                  return (
                    <GenericInput
                      key={item.label}
                      disabled={item.disabled}
                      type="select"
                      value={!isEmpty(quoteDetail) ? quoteDetail[item.fieldName] : null}
                      wrapperClass={colItem}
                      options={item.optionSelect}
                      onChange={val => onHandleChange({ value: val.value, fieldName: item.fieldName })}
                      name={item.fieldName}
                      {...item}
                    />
                  );
                }

                if (item && item.fieldDate) {
                  return (
                    <GenericInput
                      type="date"
                      key={item.label}
                      disabled={item.disabled}
                      wrapperClass={colItem}
                      label={item.label || ''}
                      name=""
                      dateFormat="yyyy-MM-dd"
                      value={!isEmpty(quoteDetail) ? quoteDetail[item.fieldDate] : null}
                      onChange={e => onHandleChange({ value: e.value, fieldName: item.fieldDate })}
                    />
                  );
                }

                if (item && item.fieldPhone) {
                  return (
                    <PhoneGroup
                      key={item.label}
                      disabled={item.disabled}
                      dataPhone={quoteDetail[item.fieldPhone]}
                      onChange={onChangePhone}
                      colDiv="col-md-12"
                    />
                  );
                }
                if (item && item.checkBox) {
                  return (
                    <GenericInput
                      type="checkbox"
                      key={item.fieldName}
                      label={item.label || ''}
                      className="mt-4 pt-1 col-md-4"
                      name={item.fieldName}
                      checked={quoteDetail[item.fieldName]}
                      value={quoteDetail[item.fieldName]}
                      onChange={({ name, value }) => onChangeCheckBox({ fieldName: name, value })}
                      disabled={item.disabled}
                    />
                  );
                }

                return (
                  <GenericInput
                    key={item.label}
                    type={item.type || 'text'}
                    disabled={item.disabled}
                    value={quoteDetail[item.fieldName] ? quoteDetail[item.fieldName] : ''}
                    label={item.label || ''}
                    // placeholder={item.label || ''}
                    onChange={evt => onHandleChange({ value: evt.value, fieldName: evt.name })}
                    name={item.fieldName}
                    wrapperClass={colItem}
                    fa={item.fa}
                    onClick={item.fa ? onClick : () => {}}
                  />
                );
              })}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

QuoteDetailForm.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  quoteDetail: PropTypes.objectOf(PropTypes.any),
  onChangeCheckBox: PropTypes.func,
  onHandleChange: PropTypes.func,
  colItem: PropTypes.string,
  title: PropTypes.string,
  accountId: PropTypes.string || PropTypes.number,
  onChangePhone: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.any),
};

QuoteDetailForm.defaultProps = {
  colItem: '',
  title: '',
  dataField: [],
  quoteDetail: {},
  onChangeCheckBox: () => {},
  onHandleChange: () => {},
  accountId: '' || null,
  onChangePhone: () => {},
  children: null,
  onClick: () => {},
};
