import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SidebarSupportSubItem } from '../../components/common';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends PureComponent {
  render() {
    const {
      isExpand = true,
      sideProps,
      isMaximizeOne,
      isExpandChild,
      keyRoute,
      isSupportCheckRoute,
      permissionsCustomer,
    } = this.props;
    if (sideProps) sideProps.isExpand = isExpand;
    if (!isHasPermissionWithMenu({ permissions: permissionsCustomer, listKeys: permissionList.customerManagement }))
      return null;
    return (
      <SidebarSupportSubItem
        {...sideProps}
        {...this.props}
        isMaximizeOne={isMaximizeOne}
        isExpandChild={isExpandChild}
        keyRoute={keyRoute}
        isSupportCheckRoute={isSupportCheckRoute}
      />
    );
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsCustomer = store.get('auth').toJS().permissionsApp.customerHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsCustomer: permissionsCustomer.length
      ? permissionsCustomer.filter(a => a.moduleName === 'CUSTOMER_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  isMaximizeOne: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  isMaximizeOne: false,
  sideProps: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
