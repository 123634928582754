import gql from 'graphql-tag';

export default id => gql`
  query {
    getPacInterfaceRecordById(id: "${id}") {
      id
      recordId
      accountId
      csvRecord
      createdDate
      objectDate
      status
      type
      extractType
    }
  }
`;
