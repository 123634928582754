import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../PricingHub/SearchComponent';

class PACProductCodesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.id',
      name: 'id',
    },
    {
      label: 'label.description',
      name: 'description',
    },
  ];

  btnSpan = 'col-md-6';
}

PACProductCodesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

PACProductCodesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(PACProductCodesSearchForm);
