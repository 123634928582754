import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}` : '';
  const searchOperatorRecord = `searchOperatorRecord(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchOperatorRecord} {
        id
        fileName
        operator
        processDate
        filePath
        numberOfRecords
        status
        batchNum
        serviceType
        startDate
        endDate
        startTime
        endTime
    }
  }
  `;
};
