import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class BatchPaymentSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.paymentFileType',
      name: 'paymentType',
      type: 'select',
      tOptions: 'selections:filePaymentType',
    },
    {
      label: 'common:label.name',
      name: 'name',
      type: 'select',
      options: [],
    },
    {
      label: 'common:label.status',
      name: 'paymentStatus',
      type: 'select',
      tOptions: 'selections:batchPaymentFileStatus',
    },
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      name: 'endDate',
      label: 'common:label.endDate',
      type: 'date',
    },
  ];

  btnSpan = 'col-md-9';
}

BatchPaymentSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BatchPaymentSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BatchPaymentSearchForm);
