import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import SubForm from './SubForm';
import TierForm from './TierForm';
import { FormCollapse } from '../../common';

const tierInputs = [
  {
    label: 'label.startRange',
    wrapperClass: 'col-md-4',
    name: 'minimum',
    type: 'number',
    required: true,
  },
  {
    label: 'label.endRange',
    wrapperClass: 'col-md-4',
    name: 'maximum',
    type: 'number',
    required: true,
  },
];

const PricesFormRecurringTiered = ({ item, index, optionsAccumulatorId, optionsGrantId }) => {
  const [activeTab, setActiveTab] = useState({});

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };
  const { t } = useTranslation('common');

  const grantInputs = [
    {
      label: 'label.grantId',
      wrapperClass: 'col-md-4',
      name: 'resourceId',
      type: 'select',
      required: true,
      options: optionsGrantId,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'grantUnit',
      type: 'select',
      required: true,
      tOptions: 'selections:trialTermUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'grantDuration',
      type: 'number',
      required: true,
    },
  ];

  const accumulatorInputs = [
    {
      label: 'label.accumulatorId',
      wrapperClass: 'col-md-4',
      name: 'accumulatorId',
      type: 'select',
      required: true,
      options: optionsAccumulatorId,
      isClearable: true,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'unit',
      type: 'select',
      required: true,
      tOptions: 'selections:accumulatorUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'duration',
      type: 'number',
      required: true,
    },
  ];

  return (
    <div>
      {item &&
        item.length &&
        item.map((val, indexTierPricing) => (
          <div className="card price-panel" key={`recurring-${indexTierPricing}`}>
            <div className="card-header">
              {t('label.price')}
#
              {index + 1}
            </div>
            <div className="card-body">
              {val.tiers &&
                val.tiers
                  .filter(v => size(v) > 1)
                  .map((tier, idx) => {
                    let title = '-';
                    if (tier.minimum && tier.minimum !== '0') {
                      title = `<${tier.minimum}>${title}`;
                    } else {
                      title = `<0>${title}`;
                    }
                    if (tier.maximum && tier.maximum !== '0') {
                      title = `${title}<${tier.maximum}>`;
                    } else {
                      title = `${title}<0>`;
                    }
                    return (
                      <div className="card-body" key={`tier-${index}-${idx}`}>
                        <FormCollapse
                          key={`tier-${idx}`}
                          label="First Name"
                          isActive={activeTab && `tier-${index}-${idx}` === activeTab.name && activeTab.isActive}
                          title={title}
                          state={`tier-${index}-${idx}`}
                          onToggleTab={onToggleTab}
                        >
                          <div className="card-header">
                            {`${t('label.addTier')}#`}
                            {idx + 1}
                          </div>
                          <SubForm
                            headerTitle={`#${idx + 1}`}
                            inputs={tierInputs}
                            onRemoveItem={e => {
                              e.preventDefault();
                            }}
                            item={tier}
                            onChangeItem={() => {}}
                          />
                          <TierForm
                            data={tier}
                            optionsAccumulatorId={optionsAccumulatorId}
                            optionsGrantId={optionsGrantId}
                            grantInputs={grantInputs}
                            accumulatorInputs={accumulatorInputs}
                            isTier={false}
                          />
                        </FormCollapse>
                      </div>
                    );
                  })}
            </div>
          </div>
        ))}
    </div>
  );
};

PricesFormRecurringTiered.propTypes = {
  item: PropTypes.arrayOf(PropTypes.any),
  optionsAccumulatorId: PropTypes.arrayOf(PropTypes.any),
  optionsGrantId: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number.isRequired,
};

PricesFormRecurringTiered.defaultProps = {
  item: [],
  optionsAccumulatorId: [],
  optionsGrantId: [],
};
export default PricesFormRecurringTiered;
