import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['switchName'])}` : '';
  const getRawSmsData = `getRawSmsData(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRawSmsData} {
        endTime
        startTime
        serviceType
        submitDate
        addressSrcDigits
        srcTon
        srcApi
        addressDestDigits
        destTon
        destApi
        scheduleDeliveryTime
        validityPeriod
        protocolId
        priority
        registerDelivery
        replaceIfPresent
        dataCoding
        messageLength
        firstTwentyCharactersOfSMS
        optTag
        optLength
        optVal
        origNetworkId
        networkId
        host
        esmClass
        gsmsiei
        gsmudh
        messageDeliveryStatus
        extIdCinta
        messageId
        newSequenceNumber
        commandId
        commandLength
        pduType
        aspName
        messageStatus
        messageType
      }
    }
  `;
};
