/**
 * Adjustment Value Not String
 */

export default [
  'numberOfTransactions',
  'amount',
  'percent',
  'type',
  'arType',
  'source',
  'taxRule',
  'action',
  'crDrType',
];
//   'reason',

export const enumCreate = [
  'numberOfTransactions',
  'amount',
  'percent',
  'type',
  'arType',
  'source',
  'taxRule',
  'reason',
  'index',
  'action',
];
