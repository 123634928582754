import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GenericInput, DataTable } from '../../../common';

const HolidayConfigurationForm = ({ data, onHandleAddNewHoliday, onChangeTable }) => {
  const { t } = useTranslation('common');
  const tableColumns = [
    {
      name: 'startDate',
      label: 'label.startDate',
      render: (colName, item, idx) => (
        <GenericInput
          value={item && item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : null}
          type="date"
          wrapperClass="col-md-12 row inner-popover"
          onChange={({ name, value }) =>
            onChangeTable({
              name,
              value: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null,
              id: item.id,
              index: idx,
            })
          }
          name="startDate"
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      render: (colName, item, idx) => (
        <GenericInput
          value={item && item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : null}
          type="date"
          wrapperClass="col-md-12 row inner-popover"
          onChange={({ name, value }) =>
            onChangeTable({
              name,
              value: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null,
              id: item.id,
              index: idx,
            })
          }
          name="endDate"
        />
      ),
    },
    {
      name: 'name',
      label: 'label.name',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, id: item.id, index: idx })}
          name="name"
        />
      ),
    },
  ];
  return (
    <div className="col-md-12">
      <br />
      <div className="form-group col-md-12 buttons-attibute">
        <button type="button" className="button button-border black x-small" onClick={onHandleAddNewHoliday}>
          {`+ ${t('label.addNewHoliday')}`}
        </button>
      </div>
      <div className="card-body row">
        <DataTable columns={tableColumns} data={data} isFixedHeaderTable />
      </div>
    </div>
  );
};

HolidayConfigurationForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleAddNewHoliday: PropTypes.func,
  onChangeTable: PropTypes.func,
};

HolidayConfigurationForm.defaultProps = {
  data: [],
  onHandleAddNewHoliday: () => {},
  onChangeTable: () => {},
};

export default HolidayConfigurationForm;
