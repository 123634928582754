import React, { useState, useEffect, useCallback } from 'react';
import PropsType from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../../App/RouteNames';
import { searchZoneUnit } from '../../actions';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';
import { Breadcrumb, DataTable, TablePagination, FormWithTableItem } from '../../../../components/common';
import { ZoneUnitSearchForm } from '../../../../components/BaseConfigurationsHub';
import { getPageTotalCount } from '../../../../utils/utils';
import {
  checkPermissionViewZoneUnit,
  checkPermissionModifyZoneUnit,
  checkPermissionCreateZoneUnit,
} from '../../CheckPermission';

const zoneUnitSort = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

const ZoneUnit = ({ searchZoneUnit, permissionsPricing, location }) => {
  const { t } = useTranslation('common');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [isFirstBack, setIsFirstBack] = useState(true);

  let modeViewZoneUnit = 0;
  let modeCreateZoneUnit = 0;
  let modeModifyZoneUnit = 0;

  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermission = permissionsPricing.pricingModulePermissions;
    modeViewZoneUnit = checkPermissionViewZoneUnit({ listPermission });
    modeCreateZoneUnit = checkPermissionCreateZoneUnit({ listPermission });
    modeModifyZoneUnit = checkPermissionModifyZoneUnit({ listPermission });
  }

  const doSearchZoneUnit = useCallback(() => {
    setLoading(true);
    let newFilter = filter;
    if (isFirstBack && location?.state?.filter) {
      newFilter = location?.state?.filter;
      setIsFirstBack(false);
      setFilter(location?.state?.filter);
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? zoneUnitSort[sorted.sortCol][sorted.sortDir] : null,
    };
    searchZoneUnit(payload, ({ success, data }) => {
      setLoading(false);
      // setFilter(newFilter);
      if (success) setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, searchZoneUnit, size, sorted]);

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  useEffect(() => {
    if (modeViewZoneUnit) {
      doSearchZoneUnit();
    }
  }, [doSearchZoneUnit, modeViewZoneUnit]);

  useEffect(() => {
    if (modeViewZoneUnit) setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount, modeViewZoneUnit]);

  if (!modeViewZoneUnit) return null;

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) =>
        modeModifyZoneUnit === 2 ? (
          <Link
            className="text-success"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.zoneUnitEdit.path, item.id),
              state: { item, filter: { ...filter } },
            }}
          >
            {item.id}
          </Link>
        ) : (
          <span>{item.id}</span>
        ),
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      sortable: true,

      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'name',
      label: 'label.name',
      sortable: true,
    },
    {
      name: 'description',
      label: 'label.description',
    },
    {
      name: 'zoneType',
      label: 'label.zoneType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:zoneType')().find(val => val.value === item.zoneType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'status',
      label: 'label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:genericStatus')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'createdDate',
      label: 'label.createdDate',
      sortable: true,
    },
    {
      name: 'action',
      label: 'label.action',
      render: (colName, item) => (
        <Link
          className="btn btn-outline-success btn-sm"
          to={{ pathname: RouteNames.prettifyPath(RouteNames.zoneUnitEdit.path, item.id), state: { item, filter } }}
        >
          {t('common:label.view')}
        </Link>
      ),
    },
  ];

  const newTableColume = cloneDeep(tableColumns);
  if (!modeModifyZoneUnit) {
    newTableColume.splice(newTableColume.length - 1, 1);
  }

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            {modeCreateZoneUnit === 2 ? (
              <NavLink
                className="button x-small"
                to={{ pathname: RouteNames.zoneUnitAdd.path, state: { filter: { ...filter } } }}
              >
                {t('label.createNew')}
              </NavLink>
            ) : (
              <NavLink className="button x-small" to={RouteNames.currency.path}>
                {t('label.back')}
              </NavLink>
            )}
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
                { name: t('baseConfigurationsPage:sidebar.zoneUnit') },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="repeater-file">
              <div data-repeater-list="group-a">
                <ZoneUnitSearchForm
                  onSubmit={onHandleSubmit}
                  defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormWithTableItem title={t('baseConfigurationsPage:sidebar.zoneUnit')} subClass="border-bottom">
        <DataTable
          columns={newTableColume}
          data={data || []}
          isLoading={isLoading}
          onSort={onSortColumn}
          sorted={sorted}
        />
      </FormWithTableItem>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
    </div>
  );
};

ZoneUnit.propTypes = {
  searchZoneUnit: PropsType.func.isRequired,
  permissionsPricing: PropsType.objectOf(PropsType.any),
};

ZoneUnit.defaultProps = { permissionsPricing: {} };

const mapStateToProps = createStructuredSelector({
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default connect(mapStateToProps, { searchZoneUnit })(ZoneUnit);
