import constants from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateRoles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createRoles });
};

const checkPermissionModifyRoles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyRoles });
};

const checkPermissionSearchRoles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchRoles });
};

const checkPermissionDeleteRoles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deleteRoles });
};

const checkPermissionGetRoles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getRoles });
};

const checkPermissionCreateRoleGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createRoleGroups });
};

const checkPermissionModifyRoleGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyRoleGroups });
};

const checkPermissionSearchRoleGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchRoleGroups });
};

const checkPermissionDeleteRoleGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deleteRoleGroups });
};

const checkPermissionGetRoleGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getRoleGroups });
};

const checkPermissionCreateUser = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createUser });
};

const checkPermissionModifyUser = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyUser });
};

const checkPermissionSearchUser = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchUser });
};

const checkPermissionGetUser = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getUser });
};

const checkPermissionChangeUserStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.changeUserStatus });
};

const checkPermissionChangeRoleStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.changeRoleStatus });
};

const checkPermissionChangeRoleGroupStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.changeRoleGroupStatus });
};

const checkPermissionModifyConfigApprovals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyConfigApprovals });
};

const checkPermissionGetConfigApprovals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getConfigApprovals });
};

const checkPermissionCreateObjectApprovals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createObjectApprovals });
};

const checkPermissionModifyObjectApprovals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyObjectApprovals });
};

const checkPermissionCreateConfigApprovals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createConfigApprovals });
};

const checkPermissionGetObjectApprovals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getObjectApprovals });
};

const checkPermissionCreateConfigUserGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createConfigUserGroups });
};

const checkPermissionModifyConfigUserGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyConfigUserGroups });
};

const checkPermissionGetConfigUserGroups = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getConfigUserGroups });
};

export {
  checkPermissionCreateRoles,
  checkPermissionModifyRoles,
  checkPermissionDeleteRoles,
  checkPermissionGetRoles,
  checkPermissionCreateRoleGroups,
  checkPermissionModifyRoleGroups,
  checkPermissionDeleteRoleGroups,
  checkPermissionGetRoleGroups,
  checkPermissionCreateUser,
  checkPermissionModifyUser,
  checkPermissionGetUser,
  checkPermissionChangeUserStatus,
  checkPermissionChangeRoleStatus,
  checkPermissionChangeRoleGroupStatus,
  checkPermissionSearchRoles,
  checkPermissionSearchRoleGroups,
  checkPermissionSearchUser,
  checkPermissionModifyConfigApprovals,
  checkPermissionGetConfigApprovals,
  checkPermissionCreateObjectApprovals,
  checkPermissionModifyObjectApprovals,
  checkPermissionCreateConfigApprovals,
  checkPermissionGetObjectApprovals,
  checkPermissionCreateConfigUserGroups,
  checkPermissionModifyConfigUserGroups,
  checkPermissionGetConfigUserGroups,
};
