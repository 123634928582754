import React from 'react';
import { size, cloneDeep, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import CustomerNoForm from '../CustomerNoForm';
// import ModalSelectCreditProfileIndex from '../ModalSelectCreditProfileIndex';
import InfoCard from './InfoCard';
import RouteNames from '../../../containers/App/RouteNames';
import { GenericInput } from '../../common';
import ModalCollectionAgentByType from '../../common/ModalCollectionAgentByType';
import { validate, supportShowNumberValue } from '../../../utils/utils';

class InfoForm extends React.PureComponent {
  state = {
    accountInfo: {},
    dataSubmit: {},
    wasValidated: false,
    isModify: false,
    isOpenModalSelectCreditProfile: false,
    isOpenModalCollectionAgent: false,
    numberOfAccounts: null,
    organizationList: [],
    isDisabledStatusChangeEffectiveDate: true,
    ccpTime: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    const { data, doGetAccountInfomation, getSubscription } = this.props;
    if (data.id) {
      this.initValue(data);
      doGetAccountInfomation();
      getSubscription(data.id);
      if (data && data.type === 'CLIENT') this.doGetAccountListByClientId();
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data.id && data !== prevProps.data) {
      this.initValue(data);
    }
  }

  onHandleChange = ({ value = '', name = '' }) => {
    const { accountInfo, dataSubmit } = this.state;
    let { isDisabledStatusChangeEffectiveDate } = this.state;
    let { isModify } = this.state;
    if (name === 'status' && accountInfo[name] !== value) {
      isDisabledStatusChangeEffectiveDate = false;
      this.onGetCcpDateTimeConfig();
    }
    accountInfo[name] = value;
    dataSubmit[name] = value;

    isModify = true;
    this.setState({ ...accountInfo, ...dataSubmit, isModify, isDisabledStatusChangeEffectiveDate });
  };

  onGetCcpDateTimeConfig = () => {
    const { getCcpDateTimeConfig } = this.props;
    const { ccpTime, accountInfo, dataSubmit } = this.state;
    if (!ccpTime) {
      return getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          accountInfo.statusModifiedDate = data ? data.ccpTime : null;
          dataSubmit.statusModifiedDate = data ? data.ccpTime : null;
          this.setState({ ...accountInfo, ...dataSubmit, ccpTime: data ? data.ccpTime : null });
        }
      });
    }
    accountInfo.statusModifiedDate = ccpTime || null;
    dataSubmit.statusModifiedDate = ccpTime || null;
    return this.setState({ ...accountInfo, ...dataSubmit });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit, accountInfo, isDisabledStatusChangeEffectiveDate } = this.state;
    const { modifyAccount, updateAccountStatus, doGetAccountDetails, t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });
    if (dataSubmit.status || dataSubmit.reason) {
      updateAccountStatus({
        id: dataSubmit.id,
        status: accountInfo.status,
        reason: accountInfo.reason,
      });
    }
    if (isDisabledStatusChangeEffectiveDate) delete dataSubmit.statusModifiedDate;
    delete dataSubmit.status;
    delete dataSubmit.reason;
    delete dataSubmit.externalRefId;
    this.setState({ wasValidated: true });
    if (size(dataSubmit) < 2) return;
    modifyAccount(dataSubmit, ({ success }) => {
      if (success) {
        if (dataSubmit && dataSubmit.type === 'CLIENT' && success) this.doGetAccountListByClientId();
        doGetAccountDetails();
      }
      this.setState({ wasValidated: false });
    });
  };

  initValue = account => {
    const { accountInfo, dataSubmit } = this.state;
    accountInfo.customerSegment = account.customerSegment || null;
    accountInfo.accountType = account.accountType || null;
    accountInfo.subType = account.subType || null;
    accountInfo.salesChannel = account.salesChannel || null;
    accountInfo.marketSegment = account.marketSegment || null;
    accountInfo.sellingCompany = account.sellingCompany || null;
    accountInfo.lineOfBusiness = account.lineOfBusiness || null;
    accountInfo.legalEntity = account.legalEntity || null;
    accountInfo.reason = account.reason || null;
    accountInfo.currency = account.currency || null;
    accountInfo.effectiveDate = account.effectiveDate || null;
    accountInfo.status = account.status || null;
    accountInfo.type = account.type || null;
    // accountInfo.creditProfileIndex = account.creditProfileIndex || null;
    accountInfo.salesAgentId = account.salesAgentId || null;
    accountInfo.clientId = account.clientId || null;
    accountInfo.externalRefId = account.externalRefId || null;
    accountInfo.region = account.region || null;
    accountInfo.collectionAgent = account.collectionAgent || null;
    accountInfo.organization = account.organization || null;
    accountInfo.employeeId = account.employeeId || null;
    accountInfo.commercialName = account.commercialName || null;
    accountInfo.taxId = account.taxId || null;
    accountInfo.foreignTaxId = account.foreignTaxId || null;
    accountInfo.name = account.name || null;
    accountInfo.statusModifiedDate = account.statusModifiedDate || null;
    accountInfo.accountCategory = account.accountCategory || null;
    accountInfo.customerId = account.customerId || null;
    accountInfo.partyId = account.partyId || null;
    accountInfo.groupId = account.groupId || null;
    dataSubmit.id = account.id || null;

    this.setState({ ...accountInfo, dataSubmit });
  };

  // onSelectCreditProfileIndex = () => {
  //   const { isOpenModalSelectCreditProfile } = this.state;
  //   this.setState({ isOpenModalSelectCreditProfile: !isOpenModalSelectCreditProfile });
  // };

  // onSelectedIndexCreditProfile = index => {
  //   const { accountInfo, dataSubmit } = this.state;
  //   const newData = cloneDeep(accountInfo);
  //   if (Number.parseInt(index, 10) > -1) {
  //     newData.creditProfileIndex = index;
  //     dataSubmit.creditProfileIndex = index;
  //   }
  //   this.setState({ accountInfo: newData, isOpenModalSelectCreditProfile: false, ...dataSubmit, isModify: true });
  // };

  onChangeNumberOfAccounts = ({ name, value }) => {
    this.setState({ numberOfAccounts: value });
  };

  doGetAccountListByClientId = () => {
    const { selectAccountId, id } = this.props;
    selectAccountId(
      {
        page: 1,
        size: 20,
        filter: {
          clientId: id,
        },
      },
      ({ success, data }) => {
        if (success && data && data.length) {
          const organizationList = data.map(val => {
            const { contacts, id } = val;
            return {
              label: `${id}${val?.name ? `-${val.name}` : ''}${
                contacts && contacts[0] && contacts[0].organization ? `-${contacts[0].organization}` : ''
              }`,
              value: id,
            };
          });
          this.setState({
            organizationList: uniqBy(organizationList, 'label'),
          });
        }
      }
    );
  };

  onCreateBillingAccounts = () => {
    const { numberOfAccounts } = this.state;
    const { createBillingAccounts, id } = this.props;
    createBillingAccounts({ numberOfAccounts, clientId: id }, ({ success }) => {
      if (success) {
        this.doGetAccountListByClientId(true);
      }
    });
  };

  onToggleModalCollectionAgent = () => {
    const { isOpenModalCollectionAgent } = this.state;
    this.setState({ isOpenModalCollectionAgent: !isOpenModalCollectionAgent });
  };

  onSelectedCollectionAgent = id => {
    const { accountInfo, dataSubmit } = this.state;
    const newData = cloneDeep(accountInfo);
    newData.collectionAgent = id;
    dataSubmit.collectionAgent = id;
    this.setState({ accountInfo: newData, isOpenModalCollectionAgent: false, ...dataSubmit, isModify: true });
  };

  onSyncProjectApp = () => {
    const { syncProjectApp, id } = this.props;
    syncProjectApp(id);
  };

  render() {
    const {
      id,
      dataField,
      isDisableCreateOrder,
      isDisableSubmit,
      modeSuspendStatus,
      modeResumeStatus,
      modeCloseStatus,
      accountInformation,
      t,
      doGetAccountDetails,
      currencyOptions,
      customerInfo,
    } = this.props;
    const {
      accountInfo,
      wasValidated,
      isModify,
      isOpenModalSelectCreditProfile,
      numberOfAccounts,
      organizationList,
      isOpenModalCollectionAgent,
      isDisabledStatusChangeEffectiveDate,
    } = this.state;
    let newDataField = cloneDeep(dataField);
    const indexFieldStatus = dataField.findIndex(item => item.fieldSelect === 'status');
    const indexStatusModifiedDate = dataField.findIndex(item => item.fieldDate === 'statusModifiedDate');
    const optionsStatus = cloneDeep(dataField.filter(item => item.fieldSelect === 'status'))[0];
    optionsStatus.optionSelect = t(optionsStatus.tOptions)();
    if (modeSuspendStatus === 0 && optionsStatus.optionSelect) {
      optionsStatus.optionSelect = optionsStatus.optionSelect.filter(item => item.value !== 'INACTIVE');
    }
    if (modeSuspendStatus === 1 && optionsStatus.optionSelect) {
      const findIndexStatusModeSuspend = optionsStatus.optionSelect.findIndex(item => item.value === 'INACTIVE');
      optionsStatus.optionSelect[findIndexStatusModeSuspend].isDisabled = true;
    }

    if (modeResumeStatus === 0 && optionsStatus.optionSelect) {
      optionsStatus.optionSelect = optionsStatus.optionSelect.filter(item => item.value !== 'ACTIVE');
    }
    if (modeResumeStatus === 1 && optionsStatus.optionSelect) {
      const findIndexStatusModeSuspend = optionsStatus.optionSelect.findIndex(item => item.value === 'ACTIVE');
      optionsStatus.optionSelect[findIndexStatusModeSuspend].isDisabled = true;
    }

    if (modeCloseStatus === 0 && optionsStatus.optionSelect) {
      optionsStatus.optionSelect = optionsStatus.optionSelect.filter(item => item.value !== 'CLOSED');
    }
    if (modeCloseStatus === 1 && optionsStatus.optionSelect) {
      const findIndexStatusModeSuspend = optionsStatus.optionSelect.findIndex(item => item.value === 'CLOSED');
      optionsStatus.optionSelect[findIndexStatusModeSuspend].isDisabled = true;
    }

    if (indexFieldStatus) newDataField[indexFieldStatus] = optionsStatus;
    if (indexStatusModifiedDate) newDataField[indexStatusModifiedDate].isDisable = isDisabledStatusChangeEffectiveDate;
    if (accountInfo && accountInfo?.customerSegment === 'B2C') {
      newDataField = newDataField.map(val => {
        if (val.fieldName === 'commercialName' || val.fieldName === 'clientId' || val.fieldName === 'lineOfBusiness') {
          return { ...val, isDisable: true };
        }
        return { ...val };
      });
    }
    if (accountInfo && accountInfo?.type !== 'EMPLOYEE') {
      newDataField = newDataField.map(val => {
        if (val.fieldName === 'employeeId') {
          return { ...val, isDisable: true };
        }
        return { ...val };
      });
    }
    return (
      <form
        onSubmit={this.onHandleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        ref={this.formRef}
        noValidate
      >
        <InfoCard accountInformation={accountInformation} />
        <CustomerNoForm
          colItem="col-md-4"
          dataField={newDataField}
          title={t('label.account')}
          accountNum={id}
          accountDetail={accountInfo}
          onHandleChange={this.onHandleChange}
          onHandleSubmit={this.onHandleSubmit}
          // wasValidated={wasValidated}
          // onSelectCreditProfileIndex={this.onSelectCreditProfileIndex}
          onSelectCollectionAgent={this.onToggleModalCollectionAgent}
          t={t}
          currencyOptions={currencyOptions}
          customerInfo={customerInfo}
        >
          {accountInfo.type === 'CLIENT' && (
            <>
              <div className="col-md-12 row group-create-account">
                <GenericInput
                  value={organizationList && organizationList[0] ? organizationList[0].value : ''}
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => {}}
                  name="clientId"
                  label="label.viewBillingAccounts"
                  readOnly
                  type="select"
                  options={organizationList || []}
                  // disabled={isDisableAll}
                />
                <GenericInput
                  value={supportShowNumberValue(numberOfAccounts)}
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => this.onChangeNumberOfAccounts({ name, value })}
                  name="numberOfAccounts"
                  type="number"
                  required={!wasValidated}
                  label="label.addBillingAccounts"
                />
                <div className="col-md-4 pt-4">
                  <button
                    type="button"
                    className="button button-border x-small mt-1"
                    onClick={() => this.onCreateBillingAccounts()}
                    disabled={!numberOfAccounts}
                  >
                    {t('label.createAccount')}
                  </button>
                </div>
              </div>
              <div className="col-md-12 row group-create-account">
                <GenericInput
                  value="SYNC_PROJECT"
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => {}}
                  name="clientId"
                  label="label.accountActions"
                  readOnly
                  type="select"
                  tOptions="selections:syncAPI"
                  disabled
                />
                <div className="col-md-4 pt-4">
                  <button
                    type="button"
                    className="button button-border x-small mt-1"
                    onClick={() => this.onSyncProjectApp()}
                  >
                    {t('label.submit')}
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="form-group col-md-12 action-buttons embrix-disabled-link">
            {!isDisableSubmit && (
              <button
                disabled={!isModify}
                type="submit"
                className="button button-border x-small float-right"
              >
                {t('label.modify')}
              </button>
            )}
            <button
              type="button"
              onClick={doGetAccountDetails}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
            <Link
              to={{
                pathname: RouteNames.newOrder.path,
                state: {
                  accountId: id,
                  accountCategory: customerInfo?.accountCategory || null,
                  billingProfiles: customerInfo?.billingProfiles ? customerInfo?.billingProfiles[0] : null,
                },
              }}
              className="button button-border gray x-small float-right"
              disabled={isDisableCreateOrder}
            >
              {t('label.createNewOrder')}
            </Link>
          </div>
        </CustomerNoForm>
        {/* <ModalSelectCreditProfileIndex
          isOpen={isOpenModalSelectCreditProfile}
          selectedId={accountInfo.creditProfileIndex || ''}
          onSelect={this.onSelectedIndexCreditProfile}
          onCancel={this.onSelectCreditProfileIndex}
          toggleModal={this.ToggleModal}
        /> */}
        <ModalCollectionAgentByType
          isOpen={isOpenModalCollectionAgent}
          selectedParentId={accountInfo.collectionAgent || null}
          onSelect={this.onSelectedCollectionAgent}
          onCancel={this.onToggleModalCollectionAgent}
          toggleModal={this.onToggleModalCollectionAgent}
          defaultSearchParams={{ category: 'AGENT' }}
        />
      </form>
    );
  }
}

InfoForm.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any),
  accountInformation: PropTypes.objectOf(PropTypes.any),
  modifyAccount: PropTypes.func.isRequired,
  updateAccountStatus: PropTypes.func.isRequired,
  // onSelectCreditProfileIndex: PropTypes.func,
  id: PropTypes.string,
  isDisableCreateOrder: PropTypes.bool,
  isDisableSubmit: PropTypes.bool,
  modeSuspendStatus: PropTypes.number,
  modeResumeStatus: PropTypes.number,
  modeCloseStatus: PropTypes.number,
};

InfoForm.defaultProps = {
  id: '',
  dataField: [],
  data: {},
  accountInformation: {},
  isDisableCreateOrder: false,
  isDisableSubmit: false,
  modeSuspendStatus: 2,
  modeResumeStatus: 2,
  modeCloseStatus: 2,
  // onSelectCreditProfileIndex: () => {},
};

export default withTranslation('common')(InfoForm);
