// eslint-disable-next-line import/prefer-default-export
export const columns = {
  id: { title: '', width: { wpx: 100 } },
  accountId: { title: '', width: { wpx: 200 } },
  company: { title: '', width: { wpx: 200 } },
  firstName: { title: '', width: { wpx: 200 } },
  lastName: { title: '', width: { wpx: 200 } },
  accountType: { title: '', width: { wpx: 200 } },
  startDate: { title: '', width: { wpx: 100 } },
  endDate: { title: '', width: { wpx: 100 } },
  billUnitId: { title: '', width: { wpx: 100 } },
  serviceType: { title: '', width: { wpx: 100 } },
  priceOfferId: { title: '', width: { wpx: 100 } },
  priceOfferName: { title: '', width: { wpx: 180 } },
  itemId: { title: '', width: { wpx: 120 } },
  itemName: { title: '', width: { wpx: 180 } },
  dueDate: { title: '', width: { wpx: 100 } },
  status: { title: '', width: { wpx: 180 } },
  amount: { title: '', width: { wpx: 80 } },
  billType: { title: '', width: { wpx: 80 } },
  billTotal: { title: '', width: { wpx: 60 } },
  billStatus: { title: '', width: { wpx: 80 } },
  paymentMethod: { title: '', width: { wpx: 120 } },
  jobScheduleDate: { title: '', width: { wpx: 130 } },
  jobScheduleId: { title: '', width: { wpx: 130 } },
  jobScheduleStatus: { title: '', width: { wpx: 150 } },
  jobId: { title: '', width: { wpx: 90 } },
  jobStatus: { title: '', width: { wpx: 90 } },
  successCount: { title: '', width: { wpx: 120 } },
  failureCount: { title: '', width: { wpx: 90 } },
  totalCount: { title: '', width: { wpx: 90 } },
  index: { title: '', width: { wpx: 60 } },
  referenceId: { title: '', width: { wpx: 90 } },
  referenceType: { title: '', width: { wpx: 120 } },
  jobType: { title: '', width: { wpx: 150 } },
  jobDate: { title: '', width: { wpx: 100 } },
  failureReason: { title: '', width: { wpx: 100 } },
  subscriptionId: { title: '', width: { wpx: 100 } },
  effectiveDate: { title: '', width: { wpx: 100 } },
  initialTermUnit: { title: '', width: { wpx: 100 } },
  initialTerm: { title: '', width: { wpx: 100 } },
  renewalTermUnit: { title: '', width: { wpx: 100 } },
  renewalTerm: { title: '', width: { wpx: 100 } },
  trialType: { title: '', width: { wpx: 100 } },
  trialLevel: { title: '', width: { wpx: 100 } },
  invoiceDate: { title: '', width: { wpx: 100 } },
  lastActionDate: { title: '', width: { wpx: 130 } },
  invoiceId: { title: '', width: { wpx: 100 } },
  due: { title: '', width: { wpx: 100 } },
  daysInCollection: { title: '', width: { wpx: 130 } },
  paymentTerm: { title: '', width: { wpx: 100 } },
  invoiceUnitId: { title: '', width: { wpx: 100 } },
  paymentDate: { title: '', width: { wpx: 100 } },
  transactionId: { title: '', width: { wpx: 100 } },
  paymentId: { title: '', width: { wpx: 100 } },
  processDate: { title: '', width: { wpx: 100 } },
  arOpsType: { title: '', width: { wpx: 150 } },
  arLevel: { title: '', width: { wpx: 100 } },
  taxTreatment: { title: '', width: { wpx: 100 } },
  arCrDrType: { title: '', width: { wpx: 100 } },
  reason: { title: '', width: { wpx: 300 } },
  recordId: { title: '', width: { wpx: 100 } },
  transactionDate: { title: '', width: { wpx: 100 } },
  netTotal: { title: '', width: { wpx: 100 } },
  provisioningId: { title: '', width: { wpx: 100 } },
  netAmount: { title: '', width: { wpx: 100 } },
  suspenseReason: { title: '', width: { wpx: 100 } },
  total: { title: '', width: { wpx: 60 } },
  nonPayingTotal: { title: '', width: { wpx: 130 } },
  payment: { title: '', width: { wpx: 80 } },
  writeOff: { title: '', width: { wpx: 80 } },
  adjusted: { title: '', width: { wpx: 80 } },
  batchId: { title: '', width: { wpx: 80 } },
  fileName: { title: '', width: { wpx: 150 } },
  fileDate: { title: '', width: { wpx: 150 } },
  source: { title: '', width: { wpx: 200 } },
  totalTransactions: { title: '', width: { wpx: 120 } },
  ratedTransactions: { title: '', width: { wpx: 120 } },
  suspendedTransactions: { title: '', width: { wpx: 120 } },
  erroredTransactions: { title: '', width: { wpx: 120 } },
  skippedTransaction: { title: '', width: { wpx: 120 } },
  pendingTransaction: { title: '', width: { wpx: 120 } },
  quantityUnit: { title: '', width: { wpx: 100 } },
  productLine: { title: '', width: { wpx: 100 } },
  netValue: { title: '', width: { wpx: 100 } },
  grossValue: { title: '', width: { wpx: 100 } },
  usageId: { title: '', width: { wpx: 100 } },
  usageClass: { title: '', width: { wpx: 100 } },
  recordIndicator: { title: '', width: { wpx: 100 } },
  errorCode: { title: '', width: { wpx: 200 } },
  totalErrorTransactions: { title: '', width: { wpx: 100 } },
  totalSuspendTransaction: { title: '', width: { wpx: 100 } },
  productCompany: { title: '', width: { wpx: 130 } },
  glAccount: { title: '', width: { wpx: 100 } },
  productFamily: { title: '', width: { wpx: 100 } },
  productType: { title: '', width: { wpx: 100 } },
  productSubType: { title: '', width: { wpx: 140 } },
  recognizedRevenue: { title: '', width: { wpx: 120 } },
  remainingRevenue: { title: '', width: { wpx: 120 } },
  totalRevenue: { title: '', width: { wpx: 100 } },
  revenueType: { title: '', width: { wpx: 100 } },
  recognizedRevenueType: { title: '', width: { wpx: 100 } },
  billEndDate: { title: '', width: { wpx: 100 } },
  sellingCompany: { title: '', width: { wpx: 100 } },
  organization: { title: '', width: { wpx: 200 } },
  city: { title: '', width: { wpx: 100 } },
  state: { title: '', width: { wpx: 200 } },
  country: { title: '', width: { wpx: 100 } },
  trialTerm: { title: '', width: { wpx: 100 } },
  trialEndDate: { title: '', width: { wpx: 100 } },
  jurisdiction: { title: '', width: { wpx: 100 } },
  billMonth: { title: '', width: { wpx: 100 } },
  taxableAmount: { title: '', width: { wpx: 100 } },
  exemptAmount: { title: '', width: { wpx: 100 } },
  taxCode: { title: '', width: { wpx: 100 } },
  taxAmount: { title: '', width: { wpx: 100 } },
  invoiceStatus: { title: '', width: { wpx: 100 } },
  crdrNoteId: { title: '', width: { wpx: 100 } },
  marketSegment: { title: '', width: { wpx: 100 } },
  termEndDate: { title: '', width: { wpx: 100 } },
  salesChannel: { title: '', width: { wpx: 100 } },
  totalFileRecordCount: { title: '', width: { wpx: 150 } },
  totalFileRecordLoaded: { title: '', width: { wpx: 150 } },
  totalFileRecordErrored: { title: '', width: { wpx: 150 } },
  ratingStatusCount: { title: '', width: { wpx: 150 } },
  ratingErrorCount: { title: '', width: { wpx: 150 } },
  trialTermUnit: { title: '', width: { wpx: 100 } },
  taxDescription: { title: '', width: { wpx: 100 } },
  taxCategory: { title: '', width: { wpx: 100 } },
  totalTaxableAmount: { title: '', width: { wpx: 100 } },
  totalExemptAmount: { title: '', width: { wpx: 100 } },
  accountSubType: { title: '', width: { wpx: 180 } },
  common: { title: '', width: { wpx: 200 } },
  totalDue: { title: '', width: { wpx: 180 } },
  refund: { title: '', width: { wpx: 100 } },
  disputed: { title: '', width: { wpx: 100 } },
  settlement: { title: '', width: { wpx: 100 } },
  invoiceTransferred: { title: '', width: { wpx: 100 } },
  chargeBack: { title: '', width: { wpx: 100 } },
  description: { title: '', width: { wpx: 400 } },
  name: { title: '', width: { wpx: 200 } },
  commonLargColumn: { title: '', width: { wpx: 320 } },
};
