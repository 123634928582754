import gql from 'graphql-tag';

export default id => {
  const getTaskHistoryById = `getTaskHistoryById(id: "${id}")`;
  return gql`
    query {
      ${getTaskHistoryById} {
        id
        accountId
        userId
        parentTaskId
        createdDate
        taskType
        taskCategory
        taskDescription
        ticketId
        status
        notes
        userFirstname
        userLastName
        organization
        userEmail
        accountType
        audits {
          id
          accountId
          userId
          parentTaskId
          modifiedDate
          taskType
          taskCategory
          taskDescription
          ticketId
          status
          notes
          userFirstname
          userLastName
          organization
          userEmail
          accountType
        }
      }
    }
  `;
};
