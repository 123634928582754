import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { DataTable, TablePagination, TitleFrom, ModalWithItem, GenericInput } from '../../../components/common';
import SchedulePatternConfigSearchForm from './SchedulePatternConfigSearchForm';
import { getPageTotalCount } from '../../../utils/utils';
import {
  searchSchedulePattern,
  createSchedulePattern,
  modifySchedulePattern,
  getSchedulePatternById,
} from '../actions';
import { makeGetPermissionsCollectionManagement } from '../../App/selectors';
import {
  checkPermissionCreateCollectionSheduleConfig,
  checkPermissionModifyCollectionSheduleConfig,
  checkPermissionGetCollectionSheduleConfig,
} from '../../Collections/CheckPermission';

const schedulePatternConfigSort = {
  patternName: {
    asc: 'patternName_ASC',
    desc: 'patternName_DESC',
  },
  patternType: {
    asc: 'patternType_ASC',
    desc: 'patternType_DESC',
  },
  scheduleStart: {
    asc: 'scheduleStart_ASC',
    desc: 'scheduleStart_DESC',
  },
  scheduleEnd: {
    asc: 'scheduleEnd_ASC',
    desc: 'scheduleEnd_DESC',
  },
};

const tableSchedulePatternConfig = [
  {
    name: 'patternName',
    label: 'label.patternName',
    sortable: true,
  },
  {
    name: 'patternType',
    label: 'label.patternType',
    sortable: true,
  },
  {
    name: 'scheduleStart',
    label: 'label.scheduleStart',
    sortable: true,
  },
  {
    name: 'scheduleEnd',
    label: 'label.scheduleEnd',
    sortable: true,
  },
];

const scheduleDailyFields = [
  {
    name: 'everyWeekDay',
    label: 'label.everyWeekDay',
    type: 'checkbox',
    className: 'check-field',
    minValue: 1,
  },
  {
    name: 'everyNDay',
    label: 'label.every',
    type: 'number',
    isInteger: true,
    minValue: 1,
    rightLabel: 'label.days',
    wrapperClass: 'col-md-4 input-with-right-label',
  },
  {
    name: 'everyWeekEnd',
    label: 'label.everyWeekEnd',
    type: 'checkbox',
    className: 'check-field',
    minValue: 1,
  },
];

const scheduleWeeklyFields = [
  {
    name: 'everyNWeek',
    label: 'label.every',
    type: 'number',
    isInteger: true,
    minValue: 1,
    rightLabel: 'label.weekS',
    wrapperClass: 'col-md-4 input-with-right-label',
    required: true,
  },
  {
    name: 'everyMonday',
    label: 'label.everyMonday',
    type: 'checkbox',
    className: 'check-field',
  },
  {
    name: 'everyTuesday',
    label: 'label.everyTuesday',
    type: 'checkbox',
    className: 'check-field',
  },
  {
    name: 'everyWednesday',
    label: 'label.everyWednesday',
    type: 'checkbox',
    className: 'check-field',
  },
  {
    name: 'everyThursday',
    label: 'label.everyThursday',
    type: 'checkbox',
    className: 'check-field',
  },
  {
    name: 'everyFriday',
    label: 'label.everyFriday',
    type: 'checkbox',
    className: 'check-field',
  },
  {
    name: 'everySaturday',
    label: 'label.everySaturday',
    type: 'checkbox',
    className: 'check-field',
  },
  {
    name: 'everySunday',
    label: 'label.everySunday',
    type: 'checkbox',
    className: 'check-field',
  },
];

const scheduleMonthlyFields = [
  {
    name: 'dayOfMonth',
    label: 'label.every',
    type: 'number',
    isInteger: true,
    minValue: 1,
    maxValue: 31,
    rightLabel: 'label.monthS',
    wrapperClass: 'col-md-4 input-with-right-label',
    required: true,
  },
  {
    name: 'everyNMonth',
    label: 'label.dayOfMonth',
    type: 'number',
    isInteger: true,
    minValue: 1,
    maxValue: 12,
    rightLabel: ' ',
    wrapperClass: 'col-md-4 input-with-right-label',
    required: true,
  },
];

const scheduleYearlyFields = [
  {
    name: 'everyNYear',
    label: 'label.every',
    type: 'number',
    isInteger: true,
    minValue: 1,
    rightLabel: 'label.yearS',
    wrapperClass: 'col-md-4 input-with-right-label',
    required: true,
  },
  {
    name: 'monthOfYear',
    label: 'label.monthOfYear',
    tOptions: 'selections:monthOfYearWithNumber',
    type: 'select',
    isConverStringValue: true,
  },
  {
    name: 'dayOfMonth',
    label: 'label.dayOfMonth',
    type: 'number',
    isInteger: true,
    minValue: 1,
    maxValue: 31,
  },
];

const defaultPatternType = {
  daily: {
    everyWeekDay: true,
    everyNDay: null,
    everyWeekEnd: false,
  },
  weekly: {
    everyNWeek: 1,
    everyThursday: true,
  },
  monthly: {
    dayOfMonth: 29,
    everyNMonth: 1,
  },
  yearly: {
    everyNYear: 1,
    dayOfMonth: 1,
    monthOfYear: 1,
  },
};

const SchedulePatternConfig = ({
  searchSchedulePattern,
  modifySchedulePattern,
  createSchedulePattern,
  getSchedulePatternById,
  permissionsCollections,
}) => {
  const formRef = useRef();

  let modeCreateCollectionSheduleConfig = 0;
  let modeModifyCollectionSheduleConfig = 0;
  let modeGetCollectionSheduleConfig = 0;

  if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
    const listPermission = permissionsCollections.collectionModulePermissions;
    modeCreateCollectionSheduleConfig = checkPermissionCreateCollectionSheduleConfig({ listPermission });
    modeModifyCollectionSheduleConfig = checkPermissionModifyCollectionSheduleConfig({ listPermission });
    modeGetCollectionSheduleConfig = checkPermissionGetCollectionSheduleConfig({ listPermission });
  }

  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dataSubmit, setDataSubmit] = useState({});
  const [wasValidated, setValidate] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const { t } = useTranslation('common');

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doSearchSchedulePattern = useCallback(() => {
    setLoading(true);
    const newFilter = cloneDeep(filter);
    searchSchedulePattern(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? schedulePatternConfigSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if (success && data) setData(data);
      }
    );
  }, [filter, searchSchedulePattern, page, size, sorted]);

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const dogetSchedulePatternById = id => {
    getSchedulePatternById(id, ({ success, data }) => {
      setDataSubmit(success ? data : {});
    });
  };

  const onToggleModal = ({ item }) => {
    if (!isOpen && item) {
      dogetSchedulePatternById(item.id);
      setIsCreate(false);
    } else {
      setDataSubmit({ patternType: 'DAILY', scheduleDaily: defaultPatternType.daily });
      setIsCreate(true);
    }
    setIsOpen(!isOpen);
  };

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(dataSubmit);
    newData[name] = value;
    if (name === 'patternType' && isCreate && !newData?.scheduleDaily) {
      if (value === 'DAILY') {
        newData.scheduleDaily = defaultPatternType.daily;
      }
      if (value === 'WEEKLY' && !newData?.scheduleWeekly) {
        newData.scheduleWeekly = defaultPatternType.weekly;
      }
      if (value === 'MONTHLY' && !newData?.scheduleMonthly) {
        newData.scheduleMonthly = defaultPatternType.monthly;
      }
      if (value === 'YEARLY' && !newData?.scheduleYearly) {
        newData.scheduleYearly = defaultPatternType.yearly;
      }
    }
    setDataSubmit({ ...newData });
  };

  const onChangeItem = ({ name, value, type }) => {
    const newData = cloneDeep(dataSubmit);
    if (type === 'DAILY') {
      if (!newData.scheduleDaily) newData.scheduleDaily = {};
      newData.scheduleDaily[name] = value;
      if (name === 'everyWeekDay') {
        newData.scheduleDaily.everyWeekEnd = false;
        newData.scheduleDaily.everyNDay = null;
      }
      if (name === 'everyWeekEnd') {
        newData.scheduleDaily.everyWeekDay = false;
        newData.scheduleDaily.everyNDay = null;
      }
    }
    if (type === 'WEEKLY') {
      if (!newData.scheduleWeekly) newData.scheduleWeekly = {};
      newData.scheduleWeekly[name] = value;
    }
    if (type === 'MONTHLY') {
      if (!newData.scheduleMonthly) newData.scheduleMonthly = {};
      newData.scheduleMonthly[name] = value;
    }
    if (type === 'YEARLY') {
      if (!newData.scheduleYearly) newData.scheduleYearly = {};
      newData.scheduleYearly[name] = value;
    }
    setDataSubmit(newData);
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    setValidate(true);

    if (!validate(true)) {
      return false;
    }
    const { id, patternType, scheduleDaily, scheduleWeekly, scheduleMonthly, scheduleYearly, ...rest } = dataSubmit;
    const submitData = id ? modifySchedulePattern : createSchedulePattern;
    let newScheduleDaily = null;
    let newScheduleWeekly = null;
    if (patternType === 'DAILY') {
      const { everyNDay, everyWeekDay, everyWeekEnd } = scheduleDaily || {};
      newScheduleDaily = { everyNDay, everyWeekDay: everyWeekDay || 'false', everyWeekEnd: everyWeekEnd || 'false' };
    }
    if (patternType === 'WEEKLY') {
      const {
        everyNWeek,
        everyMonday,
        everyTuesday,
        everyWednesday,
        everyThursday,
        everyFriday,
        everySaturday,
        everySunday,
      } = scheduleWeekly || {};
      newScheduleWeekly = {
        everyNWeek,
        everyMonday: everyMonday || 'false',
        everyTuesday: everyTuesday || 'false',
        everyWednesday: everyWednesday || 'false',
        everyThursday: everyThursday || 'false',
        everyFriday: everyFriday || 'false',
        everySaturday: everySaturday || 'false',
        everySunday: everySunday || 'false',
      };
      if (
        !everyMonday &&
        !everyTuesday &&
        !everyWednesday &&
        !everyThursday &&
        !everyFriday &&
        !everySaturday &&
        !everySunday
      ) {
        return toast.error(t('message.messageChooseADay'));
      }
    }
    return submitData(
      {
        id: id || null,
        patternType,
        scheduleDaily: newScheduleDaily,
        scheduleWeekly: newScheduleWeekly,
        scheduleMonthly: patternType === 'MONTHLY' ? scheduleMonthly : null,
        scheduleYearly: patternType === 'YEARLY' ? scheduleYearly : null,
        ...rest,
      },
      ({ success }) => {
        if (success) {
          setValidate(false);
          setDataSubmit({});
          setIsOpen(false);
          doSearchSchedulePattern();
        }
      }
    );
  };

  useEffect(() => {
    doSearchSchedulePattern();
  }, [doSearchSchedulePattern]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  const fieldConfig = [
    {
      name: 'patternName',
      label: 'label.patternName',
      required: true,
    },
    {
      name: 'scheduleStart',
      label: 'label.scheduleStart',
      type: 'date',
    },
    {
      name: 'scheduleEnd',
      label: 'label.scheduleEnd',
      type: 'date',
    },
    {
      name: 'patternType',
      label: 'label.patternType',
      tOptions: 'selections:schedulePatternType',
      type: 'select',
      required: true,
      isNoClearable: true,
      disabled: !isCreate,
    },
  ];

  const newTableColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) => (
        <button type="button" className="btn btn-outline-success btn-sm" onClick={() => onToggleModal({ item })}>
          {item.id}
        </button>
      ),
    },
    // {
    //   name: 'edit',
    //   label: 'label.edit',
    //   render: (colName, item) => (
    //     <button type="button" className="btn btn-outline-success btn-sm" onClick={() => onToggleModal({ item })}>
    //       {t('label.edit')}
    //       <i className="ti-pencil ml-1" />
    //     </button>
    //   ),
    // },
    ...tableSchedulePatternConfig,
  ];

  if (!modeGetCollectionSheduleConfig) return null;

  return (
    <div className="col-md-12">
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <TitleFrom title={t('tenantPage:tenantManagementPage.schedulePattern')} />
          <br />
          <div className="card-body">
            <div className="repeater-file">
              <div>
                <SchedulePatternConfigSearchForm onSubmit={onHandleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-statistics h-100 mr-3">
        <br />
        {modeCreateCollectionSheduleConfig === 2 && (
          <div className="col-md-12 pb-3">
            <button
              type="button"
              className="button x-small float-right"
              // onClick={() => this.onHandleSave()}
              onClick={onToggleModal}
            >
              {t('label.createNew')}
            </button>
          </div>
        )}
        <div>
          <DataTable
            columns={newTableColumns}
            onSort={onSortColumn}
            isLoading={isLoading}
            data={data || []}
            sorted={sorted}
          />
        </div>
      </div>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>

      <ModalWithItem
        // wrapperClass="modal-custom modal-70"
        modalTitle={t('tenantPage:tenantManagementPage.schedulePattern')}
        isOpen={isOpen}
        onToggle={onToggleModal}
      >
        <form
          className={`col-md-12 pt-3 pb-3 pt-0 needs-validation ${wasValidated ? 'was-validated' : ''}`}
          onSubmit={handleSubmit}
          noValidate
          ref={formRef}
        >
          <div className="col-md-12 row p-0 m-0">
            {fieldConfig.map(val => {
              return (
                <GenericInput
                  value={dataSubmit[val.name] || null}
                  onChange={onChange}
                  wrapperClass="col-md-4"
                  {...val}
                />
              );
            })}
          </div>
          <div className="col-md-12 row group-details-pattern">
            {dataSubmit.patternType === 'DAILY' && (
              <div className="col-md-12 row">
                <div className="col-md-12 title-patern">{t('label.scheduleDaily')}</div>
                {scheduleDailyFields.map(val => {
                  let isDisabled = false;
                  const { name } = val;
                  if (
                    name === 'everyNDay' &&
                    dataSubmit?.scheduleDaily &&
                    (dataSubmit?.scheduleDaily?.everyWeekEnd || dataSubmit?.scheduleDaily?.everyWeekDay)
                  ) {
                    isDisabled = true;
                  }
                  if (
                    name === 'everyWeekDay' &&
                    dataSubmit?.scheduleDaily &&
                    (dataSubmit?.scheduleDaily?.everyNDay || dataSubmit?.scheduleDaily?.everyWeekEnd)
                  ) {
                    isDisabled = true;
                  }
                  if (
                    name === 'everyWeekEnd' &&
                    dataSubmit?.scheduleDaily &&
                    (dataSubmit?.scheduleDaily?.everyWeekDay || dataSubmit?.scheduleDaily?.everyNDay)
                  ) {
                    isDisabled = true;
                  }
                  return (
                    <GenericInput
                      value={dataSubmit?.scheduleDaily ? dataSubmit?.scheduleDaily[val.name] : null}
                      onChange={({ name, value }) => onChangeItem({ name, value, type: 'DAILY' })}
                      wrapperClass={val.wrapperClass || 'col-md-4'}
                      disabled={isDisabled}
                      {...val}
                    />
                  );
                })}
              </div>
            )}
            {dataSubmit.patternType === 'WEEKLY' && (
              <div className="col-md-12 row">
                <div className="col-md-12 title-patern">{t('label.scheduleWeekly')}</div>
                {scheduleWeeklyFields.map(val => {
                  return (
                    <GenericInput
                      value={dataSubmit?.scheduleWeekly ? dataSubmit?.scheduleWeekly[val.name] : null}
                      onChange={({ name, value }) => onChangeItem({ name, value, type: 'WEEKLY' })}
                      wrapperClass={val.wrapperClass || 'col-md-4'}
                      {...val}
                    />
                  );
                })}
              </div>
            )}
            {dataSubmit.patternType === 'MONTHLY' && (
              <div className="col-md-12 row">
                <div className="col-md-12 title-patern">{t('label.scheduleMonthly')}</div>
                {scheduleMonthlyFields.map(val => {
                  return (
                    <GenericInput
                      value={dataSubmit?.scheduleMonthly ? dataSubmit?.scheduleMonthly[val.name] : null}
                      onChange={({ name, value }) => onChangeItem({ name, value, type: 'MONTHLY' })}
                      wrapperClass={val.wrapperClass || 'col-md-4'}
                      {...val}
                    />
                  );
                })}
              </div>
            )}
            {dataSubmit.patternType === 'YEARLY' && (
              <div className="col-md-12 row">
                <div className="col-md-12 title-patern">{t('label.scheduleYearly')}</div>
                {scheduleYearlyFields.map(val => {
                  let value = dataSubmit?.scheduleYearly ? dataSubmit?.scheduleYearly[val.name] : null;
                  if (val.isConverStringValue) {
                    value = value ? `${value}` : value;
                  }
                  return (
                    <GenericInput
                      value={value}
                      onChange={({ name, value }) => onChangeItem({ name, value, type: 'YEARLY' })}
                      wrapperClass={val.wrapperClass || 'col-md-4'}
                      {...val}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {modeModifyCollectionSheduleConfig === 2 && (
            <button type="submit" className="button button-border x-small ml-2 float-right">
              {t('label.saveConfig')}
            </button>
          )}
          <button type="button" onClick={onToggleModal} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </form>
      </ModalWithItem>
    </div>
  );
};

SchedulePatternConfig.propTypes = {
  searchSchedulePattern: PropTypes.func.isRequired,
  modifySchedulePattern: PropTypes.func.isRequired,
  createSchedulePattern: PropTypes.func.isRequired,
  getSchedulePatternById: PropTypes.func.isRequired,
};

SchedulePatternConfig.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default connect(mapStateToProps, {
  searchSchedulePattern,
  modifySchedulePattern,
  createSchedulePattern,
  getSchedulePatternById,
})(SchedulePatternConfig);
