import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { GenericInput } from '../common';
// import SubmitForm from '../common/SubmitForm';

class SearchComponent extends PureComponent {
  state = {
    modalName: '',
  };

  searchInputs = [];

  btnSpan = 'col-md-12';

  submitText = 'Search';

  clearText = 'Clear';

  componentDidMount() {
    const { defaultFilter } = this.props;
    if (defaultFilter && Object.keys(defaultFilter).length > 0) {
      this.searchInputs.map(input => this.setState({ [input.name]: defaultFilter[input.name] || null }));
    } else {
      this.setDefaultInputs();
    }
  }

  setDefaultInputs = () => {
    const { isSpoortNoClearDisabledField, disabledSearchFields } = this.props;
    let newSearchInput = this.searchInputs;
    if (isSpoortNoClearDisabledField && disabledSearchFields && disabledSearchFields.length) {
      newSearchInput = newSearchInput.filter(val => !disabledSearchFields.includes(val.name));
    }
    newSearchInput
      .filter(val => !val.isNoClear)
      .map(input => this.setState({ [input.name]: input.defaultValue || null }));
  };

  handleInputChange = ({ name, value }) => {
    const { handleInputChange } = this.props;
    this.searchInputs.forEach(val => {
      if (val?.enableFieldsOnChange) {
        map(val?.enableFieldsOnChange, (item, key) => {
          if (item && key === name && item !== value) {
            this.setState({ [val.name]: null });
          }
        });
      }
    });
    if (handleInputChange) handleInputChange({ name, value });
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit } = this.props;
    const data = {};
    this.searchInputs.forEach(input => {
      if (this.state[input.name] || typeof this.state[input.name] === 'boolean') {
        data[input.name] = this.state[input.name];
      }
    });
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onHandleResetAdvancedSearch, onHandleClearInput } = this.props;
    this.setDefaultInputs();
    if (onHandleResetAdvancedSearch) onHandleResetAdvancedSearch();
    if (onHandleClearInput) onHandleClearInput();
    // if (onSubmit) {
    //   onSubmit({});
    // }
  };

  onSelect = (value, row, childField) => {
    const { modalName } = this.state;
    if (childField) {
      return this.setState({ [childField]: value, modalName: '' });
    }
    return this.setState({ [modalName]: value, modalName: '' });
  };

  onSelectChild = (value, row, childField) => {
    return this.setState({ [childField]: value });
  };

  onOpenModal = ({ modalName }) => {
    this.setState({ modalName });
  };

  onToggleModal = () => {
    this.setState({ modalName: '' });
  };

  render() {
    const {
      isSubmitting,
      t,
      ModalSearch,
      keyOptionInject,
      optionsInject,
      isMultipleOptionInject,
      disabledSearchFields,
      readOnlySearchFields,
      children,
      defaultFilterSeted,
    } = this.props;
    const { modalName } = this.state;
    if (keyOptionInject) {
      this.searchInputs.map(val => {
        const newVal = val;
        if (val.name === keyOptionInject) newVal.options = optionsInject;
        return newVal;
      });
    }
    if (isMultipleOptionInject) {
      this.searchInputs.map(val => {
        const newVal = val;
        newVal.options = optionsInject[val.name];
        return newVal;
      });
    }
    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className="form-row">
          {this.searchInputs.map(item => {
            let value = this.state[item.name];
            let isDisableField = false;
            if (defaultFilterSeted && defaultFilterSeted[item.name] && !value) {
              value = defaultFilterSeted[item.name];
            }
            if (item.defaultValue && !value) {
              value = item.defaultValue || null;
            }
            if (item?.enableFieldsOnChange) {
              map(item?.enableFieldsOnChange, (val, key) => {
                if (val && this.state[key] !== val) {
                  isDisableField = true;
                }
              });
            }

            if (item.exclusionField && this.state[item.exclusionField]) {
              isDisableField = true;
            }

            return (
              <GenericInput
                key={item.name}
                isNeedClearable
                {...item}
                label={t && t(item.label) ? t(item.label) : item.label}
                value={value}
                onChange={({ name, value }) =>
                  this.handleInputChange({
                    name,
                    value: value || typeof value === 'boolean' ? value : item.defaultValue || null,
                  })
                }
                disabled={
                  (disabledSearchFields &&
                    disabledSearchFields.length &&
                    disabledSearchFields.indexOf(item.name) > -1) ||
                  isDisableField
                }
                readOnly={
                  (readOnlySearchFields &&
                    readOnlySearchFields.length &&
                    readOnlySearchFields.indexOf(item.name) > -1) ||
                  item.readOnly
                }
                onClick={item.fa ? () => this.onOpenModal({ modalName: item.name }) : () => {}}
              />
            );
          })}
          {ModalSearch && (
            <ModalSearch
              isOpen={modalName}
              onToggle={this.onToggleModal}
              onCancel={this.onToggleModal}
              onSelect={this.onSelect}
              onSelectChild={this.onSelectChild}
              data={this.state}
            />
          )}
          {children}
          <div className={`form-group text-right btn-search-actions ${this.btnSpan}`}>
            <label htmlFor="validationCustom01se" />
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t ? t('label.clear') : this.clearText}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t ? t('label.search') : this.submitText}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

SearchComponent.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchComponent.defaultProps = {
  isSubmitting: false,
};

export default SearchComponent;
