import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'stream', 'serviceType']);
  const modifyZeroRateUsageConfig = `modifyZeroRateUsageConfig(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyZeroRateUsageConfig} {
      id
    }
  }
`;
};
