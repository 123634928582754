import { cloneDeep } from 'lodash';
import { convertHeaderExcelFile, convertBodyExcelFile } from '../exportHelper';

const multiDataSetDefault = ({ t, title, columnsTable }) => convertHeaderExcelFile({ title, columnsTable, t });

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(
    multiDataSetDefault({
      t,
      title,
      columnsTable,
    })
  );
  const newListData =
    data && data.length
      ? data.map(item => {
          return convertBodyExcelFile({ item, columnsTable, t });
        })
      : [];
  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
