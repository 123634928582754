import gql from 'graphql-tag';

export default id => gql`
  query {
    getInvoiceLinesById(id: "${id}") {
      id
      index
      name
      itemId
      taxCode
      amount
      appliedStart
      appliedEnd
      quantity
      unitPrice
    }
  }
`;
