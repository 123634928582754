import gql from 'graphql-tag';

export default ({ id, page, size }) => gql`
  query {
    searchTenantDisputeRecordList(id: "${id}", page: ${page}, size: ${size}) {
      id
      regId
      nir
      nim
      recordDate
      destinationSeries
      originSeries
      callType
      pdic
      acctid
      trafficType
      index
      numCalls
      numRegCalls
      numSeconds
      billedRate
      numRegSeconds
      recogRate
    }
  }
`;
