import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RouteNames from '../../App/RouteNames';
import { getPageTotalCount } from '../../../utils/utils';
import { DataTable, TablePagination, Breadcrumb } from '../../../components/common';
import OrderSort from '../../../constantsApp/order/orderSort';
import SearchOrderList from '../../../components/Orders/SearchOrderList';
import { makeGetListOrders } from '../selectors';
import { searchOrders, setParams } from '../actions';
import { makeGetPermissionsOrderManagement } from '../../App/selectors';
import { checkPermissionSearchOrder, checkPermissionCreateOrder, checkPermissionGetOrder } from '../CheckPermission';

class SearchList extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sorted: {},
    totalCount: null,
    isSearching: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.listOrders) {
      return {
        totalCount: getPageTotalCount({
          ...state,
          totalCount: state.totalCount || props.listOrders.length,
          items: props.listOrders,
        }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchOffers();
      });
    } else {
      this.doSearchOffers();
    }
  }

  onClickRow = e => {
    e.preventDefault();
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchOffers());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchOffers());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchOffers());
  };

  doSearchOffers = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchOrders } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && OrderSort[sorted.sortCol]
        ? OrderSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    searchOrders(params, () => {
      this.setState({ isSearching: false, page, size, filter, sort });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchOffers());
  };

  render() {
    const { listOrders, permissionOrder, t, location } = this.props;
    const { sorted, isSearching, page, totalCount, size, filter } = this.state;
    let modeSearchOrder = 0;
    let modeCreateOrder = 0;
    let modeGetOrder = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeSearchOrder = checkPermissionSearchOrder({ listPermission });
      modeCreateOrder = checkPermissionCreateOrder({ listPermission });
      modeGetOrder = checkPermissionGetOrder({ listPermission });
    }
    const tableColumns = [
      {
        name: 'id',
        label: 'common:label.id',
        sortable: true,
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.orderDetail.path, item.id), state: { filter } }}
          >
            {item.id}
          </Link>
        ),
      },
      {
        name: 'type',
        label: 'common:label.type',
        sortable: true,
        style: { minWidth: '90px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:types')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'crmId',
        label: 'common:label.crmId',
      },
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
        sortable: true,
        style: { minWidth: '120px' },
      },
      {
        name: 'accountId',
        label: 'common:label.accountId',
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        render: (colName, item) => {
          const slt = t('selections:accountType')().find(val => val.value === item.accountType);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'orderSource',
        label: 'label.source',
        render: (colName, item) => {
          const slt = t('selections:orderSource')().find(val => val.value === item.orderSource);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'userId',
        label: 'common:label.userId',
        sortable: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'submittedDate',
        label: 'common:label.submittedDate',
      },
      {
        name: 'action',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.orderDetail.path, item.id), state: { filter } }}
          >
            {t ? t('common:label.view') : ''}
          </Link>
        ),
      },
    ];
    const newTableColume = cloneDeep(tableColumns);

    if (modeSearchOrder === 0) return '';
    if (modeGetOrder === 0) {
      newTableColume[0] = {
        name: 'id',
        label: 'Id',
      };
      newTableColume.splice(newTableColume.length - 1, 1);
    }

    const tableConfig = {
      columns: newTableColume,
      data: listOrders,
      sorted: { ...sorted },
      onSort: this.onSortColumn,
    };
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: modeCreateOrder !== 2 ? RouteNames.dashboard.path : RouteNames.newOrder.path,
                  state: { filter },
                }}
                className="button x-small"
              >
                {modeCreateOrder !== 2 ? t('label.back') : t('label.createNewOrder')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('orderPage:sidebar.searchList') }]} />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <SearchOrderList
                    onSubmit={this.onSubmitSearch}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} onClickRow={this.onClickRow} isLoading={isSearching} />
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            isLoading={isSearching}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

SearchList.propTypes = {
  listOrders: PropTypes.arrayOf(PropTypes.any),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
  searchOrders: PropTypes.func.isRequired,
};

SearchList.defaultProps = {
  listOrders: [],
  permissionOrder: {},
};

const mapStateToProps = createStructuredSelector({
  listOrders: makeGetListOrders() || [],
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

const newSearchList = connect(mapStateToProps, { searchOrders, setParams })(SearchList);

export default withTranslation(['common', 'orderPage'])(newSearchList);
