import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  listTasks: [],
  listAllTasks: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_TASKS:
      return state.set('errorMessage', '');

    case types.SEARCH_TASKS_SUCCESS:
      return state.set('listTasks', action.payload);

    case types.SEARCH_ALL_TASKS_SUCCESS:
      return state.set('listAllTasks', action.payload);

    default:
      return state;
  }
}
