import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { cloneDeep, size } from 'lodash';
import SubForm from '../../../components/form/SubForm';
import ModalBundleItem from '../DependencyItem/ModalBundleItem';
import ModalPricingOffer from '../DependencyItem/ModalPricingOffer';
import ModalDisscountOffer from '../../../components/common/ModalDisscountOffer';

class BundleComponent extends Component {
  state = {
    components: [],
    selectedComponentIndex: null,
    isSelectingBundleId: false,
    isSelectingPricingOfferId: false,
    isSelectingDisscountOfferId: false,
    editableComponentIdx: 0,
    selectedDisscountOfferId: '',
    serviceType: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.components) return null;
    return {
      components: props.components,
      oldValue: props.components,
    };
  }

  onChangeComponents = () => {
    const { components } = this.state;
    const { onChangeComponents } = this.props;
    onChangeComponents(components);
  };

  handleRemoveItem = (idx, item) => {
    const { components } = this.state;
    const { creatingMode } = this.props;
    const newComponents = cloneDeep(components);
    const indexItemRemove = newComponents.findIndex(val => val.index === item.index);
    if (indexItemRemove !== -1) {
      if (creatingMode || item?.isNew) {
        newComponents.splice(idx, 1);
      } else {
        newComponents[idx] = { index: newComponents[idx].index };
      }
      this.setState({ components: newComponents }, () => this.onChangeComponents());
    }
  };

  handleChangeItem = (index, item) => {
    const { components } = this.state;
    const newItem = cloneDeep(item);
    const changedServiceType = components[index].serviceType !== item.serviceType;
    const newComponents = cloneDeep(components);
    if (changedServiceType) {
      newComponents.map(comp => {
        comp.serviceType = newItem.serviceType;
        comp.bundleId = null;
        comp.priceOfferId = null;
        return true;
      });
    } else {
      if (!newItem.priceOfferName || !newItem.priceOfferId) {
        newItem.priceOfferName = null;
        newItem.priceOfferId = null;
      }
      if (!newItem.discountOfferName || !newItem.discountOfferId) {
        newItem.discountOfferName = null;
        newItem.discountOfferId = null;
      }

      newComponents[index] = newItem;
    }
    this.setState({ components: newComponents }, () => this.onChangeComponents());
  };

  handleClickBundleId = (index, input, comp) => {
    if (input.name === 'bundleId') {
      this.setState({
        isSelectingBundleId: true,
        isSelectingPricingOfferId: false,
        selectedBundleId: comp.bundleId,
        selectedComponentIndex: index,
      });
    }
    if (input.name === 'priceOfferId') {
      this.setState({
        isSelectingBundleId: false,
        isSelectingPricingOfferId: true,
        selectedPricingOfferId: comp.priceOfferId,
        selectedComponentIndex: index,
        serviceType: comp.serviceType,
      });
    }
    if (input.name === 'discountOfferId') {
      this.setState({
        isSelectingBundleId: false,
        isSelectingPricingOfferId: false,
        isSelectingDisscountOfferId: true,
        selectedDisscountOfferId: comp.discountOfferId,
        selectedComponentIndex: index,
        serviceType: comp.serviceType,
      });
    }
  };

  onSelectBundleId = bundleId => {
    const { components, selectedComponentIndex } = this.state;
    components[selectedComponentIndex].bundleId = bundleId;
    this.setState({
      components,
      isSelectingBundleId: false,
      isSelectingPricingOfferId: false,
      isSelectingDisscountOfferId: false,
    });
  };

  onSelectPricingOfferId = (pricingOfferId, row) => {
    const { components, selectedComponentIndex } = this.state;
    components[selectedComponentIndex].priceOfferId = pricingOfferId;
    components[selectedComponentIndex].priceOfferName = row.name;
    components[selectedComponentIndex].discountOfferId = null;
    components[selectedComponentIndex].discountOfferName = null;
    this.setState({
      components,
      isSelectingBundleId: false,
      isSelectingPricingOfferId: false,
      isSelectingDisscountOfferId: false,
    });
  };

  onSelectDisscountOfferId = (discountOfferId, row) => {
    const { components, selectedComponentIndex } = this.state;
    components[selectedComponentIndex].discountOfferId = discountOfferId;
    components[selectedComponentIndex].discountOfferName = row.name;
    components[selectedComponentIndex].priceOfferId = null;
    components[selectedComponentIndex].priceOfferName = null;
    this.setState({
      components,
      isSelectingBundleId: false,
      isSelectingPricingOfferId: false,
      isSelectingDisscountOfferId: false,
    });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingBundleId: false, isSelectingPricingOfferId: false, isSelectingDisscountOfferId: false });
  };

  getComponentInputs = (idx, comp) => {
    const { creatingMode } = this.props;
    const componentInputs = [
      {
        label: 'common:label.serviceType',
        wrapperClass: 'col-md-4',
        name: 'serviceType',
        type: 'select',
        tOptions: 'selections:serviceType',
        disabled: idx !== this.state.editableComponentIdx || !this.props.creatingMode,
        isClearable: false,
        required: true,
        menuPortalTarget: true,
      },
      // {
      //   label: 'common:label.priceOfferId',
      //   wrapperClass: 'col-md-4',
      //   name: 'priceOfferId',
      //   onClick: e => this.handleClickBundleId(idx, { name: 'priceOfferId' }, comp),
      //   fa: 'fa fa-external-link',
      // },
      {
        label: 'common:label.priceOfferName',
        wrapperClass: 'col-md-4',
        name: 'priceOfferName',
        onClick: e => this.handleClickBundleId(idx, { name: 'priceOfferId' }, comp),
        fa: 'fa fa-external-link',
        disabled: comp.discountOfferId,
        readOnly: !creatingMode && !comp.discountOfferId,
      },
      {
        label: 'common:label.priceOverride',
        wrapperClass: 'col-md-4',
        name: 'priceOverride',
        type: 'number',
        isNegativeValue: true,
        disabled: comp.discountOfferId || comp.priceOffset,
      },
      {
        label: 'common:label.priceOffset',
        wrapperClass: 'col-md-4',
        name: 'priceOffset',
        type: 'number',
        isNegativeValue: true,
        disabled: comp.discountOfferId || comp.priceOverride,
      },
      {
        label: 'common:label.discountOfferName',
        wrapperClass: 'col-md-4',
        name: 'discountOfferName',
        onClick: e => this.handleClickBundleId(idx, { name: 'discountOfferId' }, comp),
        fa: 'fa fa-external-link',
        disabled: comp.priceOfferId,
        readOnly: !creatingMode && !comp.priceOfferId,
      },
      {
        label: 'common:label.discountAmountOverride',
        wrapperClass: 'col-md-4',
        name: 'discountAmountOverride',
        type: 'number',
        isNegativeValue: true,
        disabled: comp.priceOfferId || comp.discountPercentOverride,
      },
      {
        label: 'common:label.discountPercentOverride',
        wrapperClass: 'col-md-4',
        name: 'discountPercentOverride',
        type: 'number',
        disabled: comp.priceOfferId || comp.discountAmountOverride,
      },
      {
        label: 'common:label.validityUnit',
        wrapperClass: 'col-md-4',
        name: 'validityUnit',
        type: 'select',
        tOptions: 'selections:timeUnit',
        menuPortalTarget: true,
      },
      {
        label: 'common:label.validityDuration',
        wrapperClass: 'col-md-4',
        name: 'validityDuration',
        type: 'number',
      },
      {
        label: 'common:label.bundleName',
        wrapperClass: 'col-md-4',
        name: 'bundleId',
        onClick: e => this.handleClickBundleId(idx, { name: 'bundleId' }, comp),
        fa: 'fa fa-external-link',
        // required: true,
        disabled: true,
      },
      {
        label: 'common:label.serviceAddOn',
        wrapperClass: 'col-md-4',
        name: 'serviceAddOn',
        type: 'text',
        disabled: true,
      },
    ];
    return componentInputs;
  };

  render() {
    const { components = [] } = this.state;
    const { t } = this.props;
    if (!components || components.length === 0) return null;
    let indexComponent = 0;
    return (
      <div className="card nested-section-card">
        <div className="card-header">{t('label.components')}</div>
        <div className="card-body">
          {components.map((comp, idx) => {
            if (size(comp) < 2) return null;
            indexComponent += 1;
            return (
              <SubForm
                key={`component-idx-${idx}`}
                headerTitle={`${t('label.index').toUpperCase()}#${indexComponent}`}
                inputs={this.getComponentInputs(idx, cloneDeep(comp))}
                item={comp}
                index={idx}
                isRemoveable={components.length > 1}
                onRemoveItem={e => this.handleRemoveItem(idx, comp)}
                onChangeItem={e => this.handleChangeItem(idx, e)}
              />
            );
          })}
        </div>
        <ModalBundleItem
          isOpen={this.state.isSelectingBundleId}
          selectedParentId={this.state.selectedBundleId}
          onSelect={this.onSelectBundleId}
          onCancel={this.onCancelSelector}
          defaultSearchParams={{
            serviceType:
              (this.state.components &&
                this.state.components[this.state.selectedComponentIndex] &&
                this.state.components[this.state.selectedComponentIndex].serviceType) ||
              null,
          }}
        />
        <ModalPricingOffer
          isOpen={this.state.isSelectingPricingOfferId}
          selectedParentId={this.state.selectedPricingOfferId}
          onSelect={this.onSelectPricingOfferId}
          onCancel={this.onCancelSelector}
          defaultSearchParams={{
            serviceType:
              (this.state.components &&
                this.state.components[this.state.selectedComponentIndex] &&
                this.state.components[this.state.selectedComponentIndex].serviceType) ||
              null,
            status: 'SELLABLE',
          }}
        />

        <ModalDisscountOffer
          isOpen={this.state.isSelectingDisscountOfferId}
          selectedParentId={this.state.selectedDisscountOfferId}
          onSelect={this.onSelectDisscountOfferId}
          onCancel={this.onCancelSelector}
          defaultSearchParams={{
            status: 'SELLABLE',
            serviceType:
              (this.state.components &&
                this.state.components[this.state.selectedComponentIndex] &&
                this.state.components[this.state.selectedComponentIndex].serviceType) ||
              null,
          }}
        />
      </div>
    );
  }
}

BundleComponent.propTypes = {
  components: PropTypes.any.isRequired,
  creatingMode: PropTypes.bool,
  onChangeComponents: PropTypes.func,
};

BundleComponent.defaultProps = {
  components: [{}],
};
export default withTranslation('common')(BundleComponent);
