import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class EmployeeCostingYearlyPLSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.year',
      name: 'year',
      required: true,
      type: 'year',
      defaultValue: moment().format('YYYY'),
    },
    // {
    //   label: 'label.quarter',
    //   name: 'quarter',
    //   tOptions: 'selections:quarter',
    //   isClearable: true,
    //   type: 'select',
    //   defaultValue: '1',
    // },
    {
      label: 'label.employeeName',
      name: 'employeeName',
    },
    // {
    //   label: 'label.jobTitle',
    //   name: 'jobTitle',
    // },
  ];

  btnSpan = 'col-md-6';
}

EmployeeCostingYearlyPLSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

EmployeeCostingYearlyPLSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(EmployeeCostingYearlyPLSearchForm);
