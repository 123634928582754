const dataFieldViewTaxCode = [
  {
    name: 'productCode',
    label: 'label.code',
    required: true,
  },

  {
    name: 'description',
    label: 'label.description',
    required: true,
  },
  {
    name: 'taxPercent',
    label: 'label.taxPercent',
    required: true,
    type: 'number',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    type: 'date',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
    required: true,
    tOptions: 'selections:jurisdiction',
    type: 'select',
  },
  {
    name: 'jurisdictionList',
    label: 'label.jurisdictionList',
  },
  {
    name: 'excludeList',
    label: 'label.excludeList',
  },
  {
    name: 'minimumThreshold',
    label: 'label.minimumThreshold',
    type: 'number',
  },
  {
    name: 'maximumThreshold',
    label: 'label.maximumThreshold',
    type: 'number',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
    required: true,
  },
  // {
  //   name: 'transactionName',
  //   label: 'label.transactionName',
  // },
  // {
  //   name: 'serviceName',
  //   label: 'label.serviceName',
  // },
  {
    name: 'additionalTaxType',
    label: 'label.additionalTaxType',
  },
  {
    name: 'additionalTaxDescription',
    label: 'label.additionalTaxDescription',
  },
  {
    name: 'additionalTaxRate',
    label: 'label.additionalTaxRate',
    type: 'number',
  },
];

export const groupNamePermissions = {
  createDelayedBillingConfig: 'CREATE_DELAYED_BILLING_CONFIG',
  modifyDelayedBillingConfig: 'MODIFY_DELAYED_BILLING_CONFIG',
  getDelayedBillingConfig: 'GET_DELAYED_BILLING_CONFIG',
  createInAdvanceBillingConfig: 'CREATE_IN_ADVANCE_BILLING_CONFIG',
  modifyInAdvanceBillingConfig: 'MODIFY_IN_ADVANCE_BILLING_CONFIG',
  getInAdvanceBillingConfig: 'GET_IN_ADVANCE_BILLING_CONFIG',
  getPendingBillTriggerConfig: 'GET_PENDING_BILL_TRIGGER_CONFIG',
  modifyPendingBillTriggerConfig: 'MODIFY_PENDING_BILL_TRIGGER_CONFIG',
  createPendingBillTriggerConfig: 'CREATE_PENDING_BILL_TRIGGER_CONFIG',
  getInvoiceReport: 'GET_INVOICE_REPORT',
  testUsage: 'TEST_USAGE',
  SEARCH_LADING_BILL: 'SEARCH_LADING_BILL',
  SEARCH_SAVED_MANUAL_INVOICE: 'SEARCH_SAVED_MANUAL_INVOICE',
  CREATE_EXPENSES_CONFIG: 'CREATE_EXPENSES_CONFIG',
  GET_EXPENSES_CONFIG: 'GET_EXPENSES_CONFIG',
  MODIFY_EXPENSES_CONFIG: 'MODIFY_EXPENSES_CONFIG',
  CREATE_EXPENSE_DATA: 'CREATE_EXPENSE_DATA',
  MODIFY_EXPENSE_DATA: 'MODIFY_EXPENSE_DATA',
  SEARCH_EXPENSE_DATA: 'SEARCH_EXPENSE_DATA',
  CREATE_EMPLOYEE_BONUS: 'CREATE_EMPLOYEE_BONUS',
  MODIFY_EMPLOYEE_BONUS: 'MODIFY_EMPLOYEE_BONUS',
  SEARCH_EMPLOYEE_BONUS: 'SEARCH_EMPLOYEE_BONUS',
};

export { dataFieldViewTaxCode };
