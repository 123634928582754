import gql from 'graphql-tag';

export default id => {
  const getSchedulePatternById = `getSchedulePatternById(id: "${id}")`;
  return gql`
    query {
      ${getSchedulePatternById} {
        id
        patternName
        scheduleStart
        scheduleEnd
        patternType
        scheduleDaily {
          everyNDay
          everyWeekDay
          everyWeekEnd
        }
        scheduleWeekly {
          everyNWeek
          everyMonday
          everyTuesday
          everyWednesday
          everyThursday
          everyFriday
          everySaturday
          everySunday
        }
        scheduleMonthly {
          dayOfMonth
          everyNMonth
        }
        scheduleYearly {
          everyNYear
          dayOfMonth
          monthOfYear
        }
      }
    }
  `;
};
