import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { ModalExpression } from '../../../../components/BaseConfigurationsHub';
import { getRateUnit, modifyRateUnit, createRateUnit } from '../../actions';
import { makeGetReateUnitList } from '../../selectors';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept } from '../../../../components/common';
import { operatorOptions, messageExpression } from '../../constants';
import { dataExpression2Translation } from '../../../../utils/utils';
import {
  checkPermissionCreateRateUnitConfig,
  checkPermissionModifyRateUnitConfig,
  checkPermissionViewRateUnitConfig,
} from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

class RateUnit extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    reateUnitList: [],
    isSearching: false,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    expressionAddNew: null,
    selectFieldExp: null,
    selectRecordExp: null,
    operator: '-',
    isEditExp: false,
    codeSelectExp: null,
    id: null,
    isOpenModalExpression: false,
  };

  componentDidMount() {
    this.doGetRateUnit();
  }

  componentWillReceiveProps(nextProps) {
    const { reateUnitList } = this.props;
    if (reateUnitList && nextProps.reateUnitList && reateUnitList !== nextProps.reateUnitList) {
      this.setState({
        reateUnitList: nextProps.reateUnitList,
        defaultDataFromAPI: nextProps.reateUnitList,
        dataSubmit: [],
      });
    }
  }

  onChangeTable = ({ name, value, code }) => {
    const { reateUnitList, dataSubmit } = this.state;
    const newData = cloneDeep(reateUnitList);
    const indexItemChange = newData.findIndex(item => item.code === code);
    const indexDataSubmit = dataSubmit.findIndex(item => item.code === code);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ code });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ reateUnitList: newData });
  };

  onChangeIsQuantityScalable = ({ name, value, code }) => {
    const { reateUnitList, dataSubmit } = this.state;
    const newData = cloneDeep(reateUnitList);
    const indexItemChange = newData.findIndex(item => item.code === code);
    const indexDataSubmit = dataSubmit.findIndex(item => item.code === code);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value === 'TRUE' ? 'true' : 'false';
    } else {
      dataSubmit.push({ code });
      dataSubmit[dataSubmit.length - 1][name] = value === 'TRUE' ? 'true' : 'false';
    }
    newData[indexItemChange][name] = value === 'TRUE' ? 'true' : 'false';
    this.setState({ reateUnitList: newData });
  };

  doGetRateUnit = () => {
    const { getRateUnit } = this.props;
    getRateUnit(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id), id: data.id ? data.id : '' });
    });
  };

  onAddNewRateUnit = () => {
    const { reateUnitList, dataSubmit } = this.state;
    let newData = cloneDeep(reateUnitList);
    const newDataItem = {
      code: null,
      expression: null,
      unit: null,
      status: 'ACTIVE',
      isQuantityScalable: 'true',
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ reateUnitList: newData, dataSubmit });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index > -1) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = () => {
    try {
      const { reateUnitList, dataSubmit, idItemRemove } = this.state;
      const newData = cloneDeep(reateUnitList);
      if (newData && newData[idItemRemove] && newData[idItemRemove].isNew) {
        newData.splice(idItemRemove, 1);
      } else {
        newData[idItemRemove] = { code: newData[idItemRemove].code };
      }
      this.setState({ reateUnitList: newData, dataSubmit, isOpenModalRemoveItem: false });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSelectField = ({ name, value }) => {
    const { selectRecordExp } = this.state;
    if (name === 'selectRecordExp' && selectRecordExp !== value) {
      return this.setState({ [name]: value, selectFieldExp: '' });
    }
    return this.setState({ [name]: value });
  };

  onAddFieldExp = () => {
    const { selectRecordExp, selectFieldExp } = this.state;
    let { expressionAddNew } = this.state;
    expressionAddNew = `${expressionAddNew}${selectRecordExp}.${selectFieldExp}`;
    this.setState({ expressionAddNew });
  };

  onAddOperator = () => {
    const { operator } = this.state;
    let { expressionAddNew } = this.state;
    expressionAddNew = expressionAddNew ? `${expressionAddNew} ${operator} ` : `${operator} `;
    this.setState({ expressionAddNew });
  };

  onChangeExp = ({ value, name }) => {
    const { expressionAddNew } = this.state;
    let newData = cloneDeep(expressionAddNew);
    newData = value;
    this.setState({ [name]: newData });
  };

  onApplyExpression = () => {
    const { expressionAddNew, reateUnitList, dataSubmit, codeSelectExp } = this.state;
    const { t } = this.props;
    const newData = cloneDeep(reateUnitList);
    const indexExpChange = reateUnitList.findIndex(item => item.code === codeSelectExp);
    const indexDataSubmit = dataSubmit.findIndex(item => item.code === codeSelectExp);
    const newExpression = expressionAddNew;
    const lengthCharsOpenOperator = (newExpression.match(/[(]/g) || []).length;
    const lengthCharsCloseOperator = (newExpression.match(/[)]/g) || []).length;
    if (lengthCharsOpenOperator !== lengthCharsCloseOperator) {
      return toast.error(messageExpression);
    }
    let isMissingSpaceOpenOperator = false;
    let isMissingSpaceCloseOperator = false;
    for (let i = 0; i < expressionAddNew.length; i++) {
      if (expressionAddNew[i] === '(' && i < expressionAddNew.length && expressionAddNew[i + 1] !== ' ')
        isMissingSpaceOpenOperator = true;
      if (expressionAddNew[i] === ')' && i < expressionAddNew.length && expressionAddNew[i - 1] !== ' ')
        isMissingSpaceCloseOperator = true;
    }
    if (isMissingSpaceOpenOperator && isMissingSpaceCloseOperator) {
      return toast.error(t('message.messageErrorSpaceOperator'));
      // return toast.error('Please add spaces after and before the characters: "(", ")"');
    }
    if (isMissingSpaceOpenOperator) {
      return toast.error(t('message.messageErrorSpaceOperator2'));
      // return toast.error('Please add spaces after the character "("');
    }
    if (isMissingSpaceCloseOperator) {
      return toast.error(t('message.messageErrorSpaceOperator3'));
      // return toast.error('Please add spaces before the character ")"');
    }
    const isCharsOpenAndClose = expressionAddNew.charAt(0) === '(';
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit].expression = isCharsOpenAndClose ? expressionAddNew : `( ${expressionAddNew} )`;
    } else {
      dataSubmit.push({ code: codeSelectExp });
      dataSubmit[dataSubmit.length - 1].expression = isCharsOpenAndClose ? expressionAddNew : `( ${expressionAddNew} )`;
    }
    newData[indexExpChange].expression = expressionAddNew;
    // newData[indexExpChange].expression = expressionAddNew.replace(/[()]/g, '');
    return this.setState({
      dataSubmit,
      reateUnitList: newData,
      isOpenModalExpression: false,
      expressionAddNew: '',
      selectFieldExp: '',
      selectRecordExp: '',
      operator: '-',
    });
  };

  onChangeOperator = ({ value }) => {
    this.setState({ operator: value });
  };

  onHanldeSubmit = () => {
    const { reateUnitList, isModify, id } = this.state;
    const { modifyRateUnit, createRateUnit } = this.props;
    if (isModify) {
      const newPayloadModify = {
        id,
        rateUnits: reateUnitList.map(val => {
          const { isNew, ...rest } = val;
          return rest;
        }),
      };
      modifyRateUnit(newPayloadModify);
    } else {
      const newPayloadCreate = {
        id: 'RateUnitConfig',
        rateUnits: reateUnitList.map(val => {
          const { isNew, ...rest } = val;
          return rest;
        }),
      };
      createRateUnit(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetRateUnit();
        }
      });
    }
  };

  onToggleModal = () => {
    this.setState({
      isOpenModalExpression: false,
      expressionAddNew: '',
      selectFieldExp: '',
      selectRecordExp: '',
      operator: '-',
    });
  };

  render() {
    const {
      reateUnitList,
      isSearching,
      isOpenModalRemoveItem,
      expressionAddNew,
      selectFieldExp,
      selectRecordExp,
      isOpenModalExpression,
      operator,
      isEditExp,
      isModify,
      defaultDataFromAPI,
    } = this.state;

    const { t, permissionsPricing } = this.props;

    let modeCreateRateUnitConfig = 0;
    let modeModifyRateUnitConfig = 0;
    let modeViewRateUnitConfig = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeCreateRateUnitConfig = checkPermissionCreateRateUnitConfig({ listPermission });
      modeModifyRateUnitConfig = checkPermissionModifyRateUnitConfig({ listPermission });
      modeViewRateUnitConfig = checkPermissionViewRateUnitConfig({ listPermission });
    }

    if (!modeViewRateUnitConfig) return null;

    const rateUnitColumns = [
      {
        name: 'code',
        label: 'label.rateUnit',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item) => (
          <GenericInput
            value={item.code}
            wrapperClass="col-md-12"
            disabled={defaultDataFromAPI.findIndex(val => val && val.code === item.code) > -1}
            onChange={({ name, value }) => this.onChangeTable({ name, value, code: item.code })}
            name="code"
            type="select"
            tOptions="selections:rateUnit"
            menuPortalTarget
            isCreateOption
          />
        ),
      },
      {
        name: 'unit',
        label: 'label.unit',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.unit || null}
            type="select"
            menuPortalTarget
            options={t('selections:scaleUnit')()}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, code: item.code })}
            name="unit"
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.status || null}
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            options={t('selections:statusRateUnit')()}
            onChange={({ name, value }) => this.onChangeTable({ name, value, code: item.code })}
            name="status"
          />
        ),
      },
      {
        name: 'expression',
        label: 'label.expression',
        style: { textAlign: 'center', minWidth: '280px' },
        render: (colName, item) => (
          <GenericInput
            // value={dataExpression2Translation(item.expression.replace(/[()]/g, ''), t) || ''}
            value={dataExpression2Translation(item.expression, t) || ''}
            wrapperClass="col-md-12 overlap-text-field"
            onChange={({ name, value }) => this.onChangeTable({ name, value, code: item.code })}
            onClick={() => {
              this.setState({
                isOpenModalExpression: true,
                codeSelectExp: item.code,
                expressionAddNew: item.expression,
              });
            }}
            fa="fa fa-external-link"
            name="expression"
            type="textarea"
          />
        ),
      },
      {
        name: 'isQuantityScalable',
        label: 'label.isQuantityScalable',
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.isQuantityScalable === 'true' || item.isQuantityScalable === true ? 'TRUE' : 'FALSE'}
            options={t('selections:selectBool')()}
            type="select"
            menuPortalTarget
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeIsQuantityScalable({ name, value, code: item.code })}
            name="isQuantityScalable"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onToggleModalRemoveItem({ index: idx })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('label.rateUnit') },
          ]}
        />
        <FormWithTableItem title={t('label.rateUnit')} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateRateUnitConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewRateUnit}>
                {` + ${t('label.addNewRateUnit')}`}
              </button>
            )}
            {modeModifyRateUnitConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {isModify ? t('label.modify') : t('label.createConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetRateUnit()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={rateUnitColumns}
              isSupportRemoveIndex
              data={reateUnitList}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message={t('message.deleteItem')}
        />
        <ModalExpression
          onToggle={this.onToggleModal}
          isOpenModalExpression={isOpenModalExpression}
          expressionAddNew={expressionAddNew}
          onChangeExp={this.onChangeExp}
          operator={operator}
          operatorOptions={operatorOptions}
          selectFieldExp={selectFieldExp}
          onAddOperator={this.onAddOperator}
          selectRecordExp={selectRecordExp}
          onChangeSelectField={this.onChangeSelectField}
          onAddFieldExp={this.onAddFieldExp}
          onApplyExpression={this.onApplyExpression}
          onSetOperator={value => {
            this.setState({ operator: value });
          }}
        />
        {/* <ModalWithItem
          modalTitle={t('label.expression')}
          isOpen={isOpenModalExpression}
          onToggle={this.onToggleModal}
        >
          <div className="col-md-12 m-2 expression-container p-3 row">
            <div className="col-md-11 row">
              {expressionAddNew && expressionAddNew.length < 1 && !isEditExp && (
                <span className="placeholder-expression">{`( <${t('label.addField')}> )`}</span>
              )}
              {((expressionAddNew && expressionAddNew.length > 0) || isEditExp) && (
                <ExpressionList
                  data={expressionAddNew}
                  isEditExp={isEditExp}
                  onChangeExp={this.onChangeExp}
                  operator={operator}
                />
              )}
            </div>
            <div className="col-md-1 no-padding">
              {isEditExp ? (
                <button
                  type="button"
                  className="btn-with-ico float-right"
                  onClick={() => {
                    this.setState({ isEditExp: false });
                  }}
                >
                  <i className="fa fa-check-square-o icon-check" aria-hidden="true" />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-with-ico float-right"
                  onClick={() => {
                    this.setState({ isEditExp: true });
                  }}
                >
                  <i className="fa fa-pencil-square-o icon-edit" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-2 mt-4 row ml-4">
            <span className="title-select-operator">{`${t('label.selectOperator')}:`}</span>
            {operatorOptions.map(item => (
              <button
                key={item.key}
                type="button"
                className={`m-1 btn-operator ${operator === item.value ? 'active' : ''}`}
                onClick={() => {
                  this.setState({ operator: item.value });
                }}
              >
                {item.key}
              </button>
            ))}
            <button
              type="button"
              className="button button-border black x-small btn-add-operator"
              onClick={this.onAddOperator}
            >
              {`+ ${t('label.addOperator')}`}
            </button>
          </div>
          <div className="col-md-12 mb-2 mt-4 ml-5 row">
            <GenericInput
              value={selectRecordExp}
              label="label.selectRecord"
              type="select"
              required
              options={t('selections:recordExpression')()}
              wrapperClass="col-md-4"
              onChange={this.onChangeSelectField}
              name="selectRecordExp"
            />
            <GenericInput
              value={selectFieldExp}
              required
              label="label.selectField"
              type="select"
              options={t('selections:fieldExpression')()}
              wrapperClass="col-md-4"
              onChange={this.onChangeSelectField}
              name="selectFieldExp"
            />
            <div className="form-group action-buttons mt-4">
              <button
                type="button"
                disabled={selectFieldExp === '' || selectRecordExp === ''}
                className="button button-border black x-small"
                onClick={this.onAddFieldExp}
              >
                {`+ ${t('label.addField')}`}
              </button>
            </div>
          </div>
          <div className="form-group col-md-12 action-buttons modal-new-item">
            <button type="button" className="button x-small float-right" onClick={this.onApplyExpression}>
              {t('label.apply')}
            </button>
            <button
              type="button"
              className="button x-small float-right"
              onClick={() => {
                this.setState({ isOpenModalExpression: false });
              }}
            >
              {t('label.cancel')}
            </button>
          </div>
        </ModalWithItem> */}
      </div>
    );
  }
}

RateUnit.propTypes = {
  getRateUnit: PropTypes.func.isRequired,
  modifyRateUnit: PropTypes.func.isRequired,
  createRateUnit: PropTypes.func.isRequired,
  reateUnitList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

RateUnit.defaultProps = {
  reateUnitList: [],
};

const mapStateToProps = createStructuredSelector({
  reateUnitList: makeGetReateUnitList() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getRateUnit, modifyRateUnit, createRateUnit })(RateUnit)
);
