import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';
import ModalSelectUserIFromAgent from '../ModalSelectUserIFromAgent';

export default function FormAddCollectionAccount({
  newItem,
  onChange,
  onHandleReassign,
  modeReassignCollectionAgent,
  getListUserCollectionAgentByType,
  dataListUsers,
}) {
  const { t } = useTranslation('common');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className="form-group col-md-12 p-5">
      <div className="col-md-12 d-flex">
        <GenericInput
          name="userId"
          label="common:label.userId"
          value={newItem.userId}
          onClick={() => {
            toggleModal();
            // getListUserCollectionAgentByType();
          }}
          fa="fa fa-external-link"
          onChange={onChange}
          wrapperClass="col-md-4 collection-dropdown"
        />
        <GenericInput
          name="firstName"
          label="common:label.firstName"
          value={newItem.firstName}
          wrapperClass="col-md-4"
          onChange={onChange}
          disabled
        />
        <GenericInput
          label="common:label.lastName"
          type="text"
          onChange={onChange}
          name="lastName"
          value={newItem.lastName}
          wrapperClass="col-md-4"
          disabled
        />
      </div>
      <div className="col-md-12 pt-3 action-buttons">
        <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onHandleReassign}
          // disabled={!isEnableAssignBtn || modeReassignCollectionAgent === 1}
          disabled={modeReassignCollectionAgent === 1}
        >
          {t('label.reAssignAgent')}
        </button>
      </div>
      <ModalSelectUserIFromAgent
        isOpen={isOpenModal}
        itemSelected={newItem.userId}
        onSelect={(evt, id, rowSelect) => {
          onChange({ name: 'userId', value: id, row: rowSelect });
          toggleModal();
        }}
        onCancel={toggleModal}
        toggleModal={toggleModal}
        getListUserCollectionAgentByType={getListUserCollectionAgentByType}
        listData={dataListUsers}
      />
    </div>
  );
}

FormAddCollectionAccount.propTypes = {
  onHandleReassign: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  dataListUsers: PropTypes.arrayOf(PropTypes.any),
  newItem: PropTypes.objectOf(PropTypes.any),
  modeReassignCollectionAgent: PropTypes.number,
  getListUserCollectionAgentByType: PropTypes.func,

};

FormAddCollectionAccount.defaultProps = {
  newItem: {},
  dataListUsers: [],
  onChange: () => {},
  getListUserCollectionAgentByType: () => {},
  modeReassignCollectionAgent: 2,
};
