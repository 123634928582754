import gql from 'graphql-tag';

export default id => gql`
  query {
    getAccountAssets(input: { accountId: "${id}" }) {
      id
      accountId
      subscriptionId
      serviceUnitId
      bundleId
      packageId
      status
      serviceType
      discountPercent
      quantity
      startDate
      endDate
      cycleStartDate
      cycleEndDate
      appliedStartDate
      appliedEndDate
      packageName
      packageDescription
      bundleName
      bundleDescription
      offerName
      offerType
      offerOverride
      offerOffset
    }
  }
`;
