import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class ITXSMSSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
      required: true,
      defaultValue: moment(). startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
      required: true,
      defaultValue: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.operatorId',
      name: 'operatorId',
      fa: 'fa fa-external-link',
    },
  ];

  btnSpan = 'col-md-3';
}

ITXSMSSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ITXSMSSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ITXSMSSearchForm);
