import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class RawFileSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.platform',
      name: 'switchName',
      type: 'select',
      tOptions: 'selections:switchName',
      isClearable: true,
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
    },
    {
      label: 'label.fileName',
      name: 'fileName',
    },
  ];

  btnSpan = 'col-md-12';
}

RawFileSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

RawFileSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(RawFileSearchForm);
