import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep, sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getDataCardFromURL, isCheckValidateForm, validate } from '../../../utils/utils';
import { URL_PAYMENT } from '../../../utils/constants';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { dataSelect, cardTokenFields } from '../../../constantsApp';
import { GenericInput, PasswordField, MonthGroup, CheckField } from '../../common';
import ModalAccept from '../ModalAccept';

class PaymentProfile extends PureComponent {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      paymentData: {},
      dataSubmit: {},
      creditCards: [
        {
          cardToken: '',
          cardExpiry: '',
          last4CC: '',
          merchant: 'qabrain3',
        },
      ],
      isOpenModalDelete: false,
      isModify: false,
      paymentTermOptions: [],
      wasValidated: false,
    };
  }

  componentDidMount() {
    const { creditCards, paymentData, dataSubmit } = this.state;
    const { data, getPaymentConfigByTypeApp } = this.props;
    getPaymentConfigByTypeApp('PAYMENT_TERMS', ({ success, data }) => {
      if (success && data.paymentTerms) {
        const paymentTermOptions = data.paymentTerms.map(val => {
          return { label: val.paymentTerm, value: val.paymentTerm };
        });
        this.setState({ paymentTermOptions: paymentTermOptions ? sortBy(paymentTermOptions, ['label']) : [] });
      }
    });
    if (data.id) {
      this.initValue(data);
    }
    const urlTokenCreditCard = window.location.href;
    if (urlTokenCreditCard) {
      creditCards[0].cardToken = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.token);
      creditCards[0].cardExpiry = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.expiryDate);
      creditCards[0].last4CC = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.last4cc);
    }
    if (creditCards[0].cardToken && creditCards[0].cardExpiry && creditCards[0].last4CC) {
      paymentData.creditCards = creditCards;
      paymentData.paymentMethod = 'CREDIT_CARD';
      dataSubmit.creditCards = creditCards;
      dataSubmit.paymentMethod = 'CREDIT_CARD';
      this.setState({ paymentData, dataSubmit }, () => {
        this.onHandleSubmit(null, true);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (data.id) {
      this.initValue(data);
    }
  }

  initValue = account => {
    const { paymentData, dataSubmit } = this.state;
    paymentData.id = account.id;
    paymentData.paymentMethod = account.paymentMethod || null;
    paymentData.paymentTerm = account.paymentTerm || null;
    paymentData.creditCards = account.creditCards || [{}];
    dataSubmit.id = account.id || '';
    this.setState({ ...paymentData, dataSubmit });
  };

  onHandleChange = ({ value = '', name = '' }) => {
    const { paymentData, dataSubmit } = this.state;
    let { isModify } = this.state;
    paymentData[name] = value;
    dataSubmit[name] = value;
    if (value) isModify = true;
    this.setState({ ...paymentData, ...dataSubmit, isModify });
  };

  onChangeCreditCards = ({ value = '', name = '' }) => {
    const { paymentData, dataSubmit } = this.state;
    if (isEmpty(paymentData.creditCards)) paymentData.creditCards = [{}];
    if (isEmpty(dataSubmit.creditCards)) dataSubmit.creditCards = [{}];
    const newData = cloneDeep(paymentData);
    if (name === 'last4CC') {
      newData.creditCards[0][name] = value.length <= 4 ? value : newData.creditCards[0][name];
      dataSubmit.creditCards[0][name] = value.length <= 4 ? value : dataSubmit.creditCards[0][name];
    } else {
      newData.creditCards[0][name] = value;
      dataSubmit.creditCards[0][name] = value;
    }

    this.setState({ paymentData: newData, ...dataSubmit, isModify: true });
  };

  onHandleSubmit = (evt, isCallback) => {
    if (evt) evt.preventDefault();
    const { dataSubmit, paymentData } = this.state;
    const { modifyAccount, id, history, t, doGetAccountDetails } = this.props;
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    if (paymentData.paymentMethod === 'CREDIT_CARD') {
      if (dataSubmit.creditCards && dataSubmit.creditCards[0]) {
        dataSubmit.creditCards[0].cardToken = paymentData.creditCards[0].cardToken;
        dataSubmit.creditCards[0].merchant = 'qabrain3';
      }
    } else {
      dataSubmit.creditCards = [];
    }
    if (dataSubmit && typeof dataSubmit.propagateToBillingAccounts === 'boolean') {
      dataSubmit.propagateToBillingAccounts = dataSubmit.propagateToBillingAccounts ? 'true' : 'false';
    }
    const newPayload = {
      id,
      paymentProfiles: [dataSubmit],
    };
    this.setState({ wasValidated: true });

    if (isCheckValidateForm()) return toast.error(t('message.mandatory'));
    return modifyAccount(newPayload, ({ success }) => {
      // this.setState({ wasValidated: false });
      if (success) {
        doGetAccountDetails();
      }
      if (isCallback && success) history.push(`/customers/${id}/payment-profile`);
    });
  };

  onDeleteCardToken = () => {
    const { paymentData } = this.state;
    const { id, modifyAccount } = this.props;
    const payloadDeleteToken = {
      id: '',
      paymentProfiles: [{ id: '', creditCards: [{ cardToken: '' }] }],
    };
    try {
      payloadDeleteToken.id = id;
      payloadDeleteToken.paymentProfiles[0].id = paymentData.id;
      payloadDeleteToken.paymentProfiles[0].creditCards[0].cardToken = paymentData.creditCards[0].cardToken;
      paymentData.creditCards = [];
      modifyAccount(payloadDeleteToken, ({ success }) => {
        if (success) {
          this.setState({ ...paymentData });
          window.open(`${URL_PAYMENT}${window.location.href}`, '_self');
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  render() {
    const {
      id,
      isDisabled,
      isExistCardToken,
      isDisableSubmit,
      modeModfifyPayment,
      modeModfifyCreditCard,
      modeAddCreditCard,
      modeDeleteCreditCard,
      t,
      accountType,
      doGetAccountDetails,
      currencyOptions,
      customerInfo,
    } = this.props;
    const { isOpenModalDelete, paymentData, wasValidated, isModify, paymentTermOptions } = this.state;
    if (isEmpty(paymentData)) return '';
    const isCreaditCard = paymentData.paymentMethod === 'CREDIT_CARD';
    if (modeModfifyPayment === 0) return '';
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <form
        onSubmit={this.onHandleSubmit}
        className={`col-md-12 mb-30  ${wasValidated ? 'was-validated' : ''} `}
        ref={this.formRef}
        noValidate
      >
        <div className="card card-statistics">
          <TitleFrom title={t('label.paymentProfile')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <div className="group-flex">
              <GenericInput
                value={paymentData.id}
                label={t('label.id')}
                wrapperClass="col-md-4"
                name="id"
                disabled
                onChange={() => {}}
              />
              <GenericInput
                type="select"
                label={t('label.paymentMethod')}
                wrapperClass="col-md-4"
                value={paymentData.paymentMethod}
                options={dataSelect.paymentMethod.map((item, index) => ({
                  value: item.value,
                  label: t(`label.${item.label}`),
                  isDisabled: index === 2,
                }))}
                name="paymentMethod"
                isClearable
                onChange={this.onHandleChange}
                disabled={isDisabled}
                required
              />
              <GenericInput
                type="select"
                value={paymentData.paymentTerm || null}
                label={t('label.paymentTerm')}
                wrapperClass="col-md-4"
                // tOptions="selections:paymentTerm"
                options={paymentTermOptions || []}
                name="paymentTerm"
                onChange={this.onHandleChange}
                disabled={isDisabled}
                isClearable
              />

              {!isDisabled && isCreaditCard && modeModfifyCreditCard !== 0 && (
                <div className="payment-rigth col-md-12 group-flex">
                  <div className="col-md-4 group-flex">
                    <GenericInput
                      value={!isEmpty(paymentData.creditCards[0]) ? paymentData.creditCards[0].cardToken : ''}
                      label={t('label.creditCardToken')}
                      wrapperClass={`${modeAddCreditCard !== 0 ? 'col-md-7' : 'col-md-12'}`}
                      name="cardToken"
                      disabled={modeModfifyCreditCard === 1}
                      required
                      onChange={this.onChangeCreditCards}
                    />
                    {modeAddCreditCard !== 0 && (
                      <div className="col-md-5">
                        <button
                          type="button"
                          className="button x-small btn-credit-card"
                          disabled={
                            isDisableSubmit ||
                            modeModfifyPayment === 1 ||
                            modeModfifyCreditCard === 1 ||
                            modeAddCreditCard === 1 ||
                            (!isEmpty(paymentData.creditCards[0]) && paymentData.creditCards[0].cardToken)
                          }
                          onClick={() => window.open(`${URL_PAYMENT}${window.location.href}`, '_self')}
                        >
                          {t('label.getToken')}
                        </button>
                      </div>
                    )}
                  </div>

                  <MonthGroup
                    type="date"
                    date={!isEmpty(paymentData.creditCards[0]) ? paymentData.creditCards[0].cardExpiry : ''}
                    label={t('label.creditCardExpiry')}
                    dateFormat="MM/yyyy"
                    wrapperClass="col-md-4"
                    name="cardExpiry"
                    disabled={modeModfifyCreditCard === 1}
                    showMonthYearPicker
                    required
                    onChange={this.onChangeCreditCards}
                  />
                  <PasswordField
                    value={!isEmpty(paymentData.creditCards[0]) ? paymentData.creditCards[0].last4CC : ''}
                    label={t('label.creditCardCVV')}
                    wrapperClass="col-md-4"
                    disabled={modeModfifyCreditCard === 1}
                    name="last4CC"
                    required
                    onChange={this.onChangeCreditCards}
                  />
                </div>
              )}
              {accountType === 'CLIENT' && (
                <CheckField
                  name="propagateToBillingAccounts"
                  id="propagateToBillingAccounts"
                  wrapperClass="col-md-4 mt-2 mb-2"
                  checked={paymentData.propagateToBillingAccounts}
                  // checked={selectName === 'selectAll'}
                  label={t('label.propagateToBillingAccounts')}
                  onChange={evt =>
                    this.onHandleChange({ name: 'propagateToBillingAccounts', value: evt.target.checked })
                  }
                />
              )}
              {!isDisabled && !isDisableSubmit && (
                <div className="form-group col-md-12 action-buttons">
                  <button
                    disabled={isDisableSubmit || modeModfifyPayment === 1 || !isModify}
                    type="submit"
                    className="button button-border x-small float-right"
                  >
                    {t('label.modify')}
                  </button>
                  <button
                    type="button"
                    onClick={doGetAccountDetails}
                    className="button button-border black x-small float-right"
                  >
                    {t('label.cancel')}
                  </button>
                  {modeModfifyCreditCard === 2 && modeDeleteCreditCard === 2 && (
                    <button
                      type="button"
                      className="button button-border x-small float-right"
                      disabled={
                        isDisableSubmit ||
                        modeModfifyPayment === 1 ||
                        modeModfifyCreditCard === 1 ||
                        modeDeleteCreditCard === 1 ||
                        isExistCardToken ||
                        !isCreaditCard
                      }
                      onClick={() => {
                        this.setState({
                          isOpenModalDelete: !isOpenModalDelete,
                        });
                      }}
                    >
                      {t('label.deleteCardToken')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalAccept
          onAcceptDelete={this.onDeleteCardToken}
          isOpen={isOpenModalDelete}
          title="Are you sure you want to delete this card token?"
          onToggle={() => {
            this.setState({
              isOpenModalDelete: !isOpenModalDelete,
            });
          }}
        />
      </form>
    );
  }
}

PaymentProfile.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  modifyAccount: PropTypes.func,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isExistCardToken: PropTypes.bool,
  isDisableSubmit: PropTypes.bool,
  modeModfifyPayment: PropTypes.number,
  modeModfifyCreditCard: PropTypes.number,
  modeAddCreditCard: PropTypes.number,
  modeDeleteCreditCard: PropTypes.number,
};

PaymentProfile.defaultProps = {
  id: '',
  data: {},
  isDisabled: false,
  isExistCardToken: false,
  isDisableSubmit: false,
  history: {},
  modifyAccount: () => {},
  modeModfifyPayment: 2,
  modeModfifyCreditCard: 2,
  modeAddCreditCard: 2,
  modeDeleteCreditCard: 2,
};

export default withTranslation(['common', 'selections'])(PaymentProfile);
