import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.id'),
  t('label.employee'),
  t('label.jobTitle'),
  t('label.clientId'),
  t('label.clientName'),
  t('label.clientJobTitle'),
  t('label.firstPayrollDate'),
  t('label.firstPayrollAmount'),
  t('label.secondPayrollDate'),
  t('label.secondSalaryAmount'),
  t('label.numDaysWorked'),
  t('label.startDate'),
  t('label.endDate'),
  t('label.createdDate'),
  t('label.status'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.id ? item.id : '');
      newItem.push(item.employeeName ? item.employeeName : '');
      newItem.push(item.jobTitle ? item.jobTitle : '');
      newItem.push(item.clientId ? item.clientId : '');
      newItem.push(item.clientName ? item.clientName : '');
      newItem.push(item.clientJobTitle ? item.clientJobTitle : '');
      newItem.push(item.firstPayrollDate ? item.firstPayrollDate : '');
      newItem.push(item.firstSalaryAmount ? item.firstSalaryAmount : '');
      newItem.push(item.secondPayrollDate ? item.secondPayrollDate : '');
      newItem.push(item.secondSalaryAmount ? item.secondSalaryAmount : '');
      newItem.push(item.noOfDaysWorked ? item.noOfDaysWorked : '');
      newItem.push(item.startDate ? item.startDate : '');
      newItem.push(item.endDate ? item.endDate : '');
      newItem.push(item.createdDate ? item.createdDate : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'timeAndBillingObjectStatus' }));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 5, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
