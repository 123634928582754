import gql from 'graphql-tag';

export default () => gql`
  query {
    getFutureCycleCfg(input: {}) {
      id
      futureCycleListCfg {
        accountType
        accountSubType
        salesChannel
        marketSegment
        delay
        index
        billingDom
        billingFrequency
      }
    }
  }
`;
