import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';
import * as types from './types';


export const initialState = fromJS({
  // search roles
  jobConfig: {},
  jobSchedule: {},
  jobErrorGroupList: {},
  ccpTime: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    // -------get jobconfig
    case types.GET_JOBCONFIG: {
      return state.set('errorMessage', '');
    }
    case types.GET_JOBCONFIG_SUCCESS:
      return state.set('jobConfig', action.payload);
    case types.GET_JOBCONFIG_FAILURE:
      return state.set('errorMessage', action.payload);

    case types.GET_JOB_CCP_DATETIME_SUCCESS:
      return state.set('ccpTime', action.payload);
    case types.GET_JOB_CCP_DATETIME_FAILURE:
      return state.set('errorMessage', action.payload);

    case types.MODIFY_JOBCONFIG: {
      return state.set('errorMessage', '');
    }
    case types.MODIFY_JOBCONFIG_SUCCESS:
      return state.set('jobConfig', action.payload);
    case types.MODIFY_JOBCONFIG_FAILURE:
      return state.set('errorMessage', action.payload);

    //  JOB SCHEDULE
    case types.GET_JOBSCHEDULE_SUCCESS:
      return state.set('jobSchedule', action.payload).set('errorMessage', null);
    case types.GET_JOBSCHEDULE_FAILURE:
      return state.set('errorMessage', action.payload).set('jobSchedule', null);

    case types.CREATE_JOBSCHEDULE_SUCCESS:
      return state.set('jobSchedule', action.payload).set('errorMessage', null);
    case types.CREATE_JOBSCHEDULE_FAILURE:
      return state.set('errorMessage', action.payload).set('jobSchedule', null);

    case types.MODIFY_JOBSCHEDULE_SUCCESS:
      return state.set('jobSchedule', action.payload).set('errorMessage', null);
    case types.MODIFY_JOBSCHEDULE_FAILURE:
      return state.set('errorMessage', action.payload);

    case types.PROCESS_JOBSCHEDULE_SUCCESS:
      return state.set('jobSchedule', action.payload).set('errorMessage', null);
    case types.PROCESS_JOBSCHEDULE_FAILURE:
      return state.set('errorMessage', action.payload);

    case types.PROCESS_ALL_JOBSCHEDULE_SUCCESS: {
      const newState = cloneDeep(state.get('jobSchedule'));
      newState.jobScheduleList = newState.jobScheduleList.map(item => {
        const newItem = cloneDeep(item);
        newItem.status = 'COMPLETED';
        return { ...newItem };
      });
      return state.set('jobSchedule', newState).set('errorMessage', null);
    }

    case types.RETRY_JOBSCHEDULE_SUCCESS:
      return state.set('jobSchedule', action.payload).set('errorMessage', null);
    case types.RETRY_JOBSCHEDULE_FAILURE:
      return state.set('errorMessage', action.payload);

    // case types.UPDATE_STATUS_JOBSCHEDULE_SUCCESS:
    //   return state.set('jobSchedule', action.payload).set('errorMessage', null);
    case types.UPDATE_STATUS_JOBSCHEDULE_FAILURE:
      return state.set('errorMessage', action.payload);

    case types.GET_JOB_ERRORS_SUCCESS:
      return state
        .set('jobErrorGroupList', {
          ...state.get('jobErrorGroupList'),
          ...action.payload,
        })
        .set('errorMessage', null);

    case types.GET_JOB_ERRORS_FAILURE:
      return state.set('errorMessage', action.payload);
    default:
      return state;
  }
}
