import React from 'react';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Sidebar from './Sidebar';
import {
  makeGetPermissionsRating,
  makeGetPermissionsBilling,
  makeGetPermissionsCollectionManagement,
} from '../App/selectors';
import constant from './constants';
import { checkPermissionSetCreditLimit, checkPermissionGetDelayedBillingConfig } from './CheckPermission';

const SidebarIndex = ({ permissionsRating, permissionsBilling, permissionsCollections, ...props }) => {
  let modeSetCreditProfile = 0;
  let modeGetDelayedBillingConfig = 0;

  if (permissionsRating && permissionsRating.ratingModulePermissions) {
    const listPermissionRating = permissionsRating.ratingModulePermissions;
    modeSetCreditProfile = checkPermissionSetCreditLimit({ listPermission: listPermissionRating });
  }

  if (permissionsBilling && permissionsBilling.billingModulePermissions) {
    const listPermissionsBilling = permissionsBilling.billingModulePermissions;
    modeGetDelayedBillingConfig = checkPermissionGetDelayedBillingConfig({ listPermission: listPermissionsBilling });
  }

  const newSideProps = cloneDeep(constant.sidePropIndex);
  if (modeSetCreditProfile === 0 && newSideProps.items[0] && newSideProps.items[0].childs) {
    newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
      item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.creditProfile'
    );
  }

  if (modeGetDelayedBillingConfig === 0 && newSideProps.items[0] && newSideProps.items[0].childs) {
    newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
      item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.delayedBilling'
    );
  }

  return <Sidebar sideProps={newSideProps} {...props} />;
};

SidebarIndex.propTypes = {};

SidebarIndex.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default connect(mapStateToProps, {})(SidebarIndex);
