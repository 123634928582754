import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.id'),
  t('label.index'),
  t('label.jobTitle'),
  t('label.startDate'),
  t('label.endDate'),
  t('label.pricingUnit'),
  t('label.workDays'),
  t('label.amount'),
  t('label.status'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.id ? item.id : '');
      newItem.push(item.index !== -1 ? item.index : '');
      newItem.push(item.jobTitle ? item.jobTitle : '');
      newItem.push(item.startDate ? item.startDate : '');
      newItem.push(item.endDate ? item.endDate : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'clientPricingUnit', fieldOption: 'clientPricingUnit' }));
      newItem.push(item.workDays ? formatMoneyValueToExport(item.workDays) : '');
      newItem.push(item.amount ? formatMoneyValueToExport(item.amount) : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'timeAndBillingObjectStatus' }));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 6, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
