import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CardHeaderForm, DataTable, Select, Input } from '../../../components/common';
import { makeGetSubscriptionAssets } from '../selectors';
import './style.scss';

const tableColumnsOriginal = [
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'category',
    label: 'label.category',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionCategory')().find(val => val.value === item.category) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  {
    name: 'subscriptionReason',
    label: 'common:label.subscriptionReason',
    tOptions: 'selections:subscriptionReason',
    types: 'suspend-cancel',
  },
  {
    name: 'initialTermUnit',
    label: 'common:label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'common:label.initialTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'common:label.renewalTermUnit',
    types: 'suspend-cancel-resume',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'common:label.renewalTerm',
    types: 'suspend-cancel-resume',
  },
  {
    name: 'renewalTermUnit',
    label: 'common:label.renewalTermUnit',
    types: ['renew'],
    tOptions: 'selections:initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'common:label.renewalTerm',
    types: ['renew'],
    input: true,
  },
  {
    name: 'trialTermUnit',
    label: 'common:label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialTerm',
    label: 'common:label.trialTerm',
  },
];
const tableUnitColumns = [
  {
    name: 'id',
    label: 'common:label.id',
  },
  {
    name: 'serviceType',
    label: 'common:label.type',
  },
  {
    name: 'parentId',
    label: 'common:label.parentId',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  // {
  //   name: 'packageId',
  //   label: 'common:label.packageId',
  // },
  {
    name: 'packageName',
    label: 'common:label.packageName',
  },
  // {
  //   name: 'bundleId',
  //   label: 'common:label.bundleId',
  // },
  {
    name: 'bundleName',
    label: 'common:label.bundleName',
  },
];
class OrderSubscriptionDetail extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      location: { pathname },
      onChangeField,
      forms,
      t,
    } = nextProps;
    const { type } = prevState;
    const typePath = pathname.replace('/orders/', '');
    if (type !== typePath || forms.renewalTermUnit || forms.renewalTerm) {
      const newTableColumns = tableColumnsOriginal.filter(
        item => !item.types || (item.types && item.types.indexOf(typePath) !== -1)
      );
      const TableColumnsRender = newTableColumns.map(item => {
        const newItem = item;
        if (newItem.options || newItem.tOptions) {
          newItem.render = (name, dataField) => {
            const options = newItem.options || t(newItem.tOptions)();
            return (
              <Select
                menuPortalTarget={document.querySelector('body')}
                options={options}
                value={
                  forms[name]
                    ? options.filter(u => u.value === forms[name])
                    : options.filter(u => u.value === dataField[name])
                }
                onChange={selectedOption => onChangeField({ key: newItem.name, data: selectedOption.value })}
              />
            );
          };
        } else if (newItem.input) {
          newItem.render = (name, dataField) => {
            return (
              <Input
                type="number"
                value={forms[name] || dataField[name]}
                onChange={e => onChangeField({ key: newItem.name, data: e.target.value })}
              />
            );
          };
        }
        return newItem;
      });
      return { tableColumns: [...TableColumnsRender], type: typePath };
    }
    return null;
  }

  state = {
    type: '',
    tableColumns: [],
  };

  render() {
    const { accountNumber, subscriptionAssets, t, isMultiSubscriptionEnabled, subscriptionsList } = this.props;
    // const newSubscription = subscription.filter(item => item.status !== 'CLOSED');
    const { tableColumns } = this.state;
    const tableConfig = {
      columns: tableColumns,
      data: subscriptionsList || [],
    };
    const tableUnitConfig = {
      columns: isMultiSubscriptionEnabled
        ? [
            ...tableUnitColumns,
            {
              name: 'subscriptionName',
              label: 'label.subscriptionName',
            },
          ]
        : tableUnitColumns,
      data: subscriptionAssets || [],
    };
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <CardHeaderForm title={t('label.subscription')} label={t('label.accountNumber')} value={accountNumber} />
          <div className="form-group col-md-12 mt-0">
            <DataTable tableClass="tbl-order-subscription" {...tableConfig} onClickRow={this.onClickRow} />
            <h5 className="card-title title-form" style={{ fontWeight: 'normal' }}>
              {t('label.serviceUnits')}
            </h5>
            <DataTable {...tableUnitConfig} onClickRow={this.onClickRow} />
          </div>
        </div>
      </div>
    );
  }
}
OrderSubscriptionDetail.propTypes = {
  accountNumber: PropTypes.string,
  subscriptionAssets: PropTypes.arrayOf(PropTypes.any),
  subscription: PropTypes.arrayOf(PropTypes.any),
  forms: PropTypes.objectOf(PropTypes.any),
};

OrderSubscriptionDetail.defaultProps = {
  subscription: [],
  accountNumber: '',
  subscriptionAssets: [],
  forms: {},
};

const mapStateToProps = createStructuredSelector({
  subscriptionAssets: makeGetSubscriptionAssets() || [],
});
const newOrderSubscriptionDetail = withRouter(connect(mapStateToProps)(OrderSubscriptionDetail));

export default withTranslation('common')(newOrderSubscriptionDetail);
