import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'invoiceType',
        'accountType',
        'status',
        'minInvoiceThreshold',
        'paymentMethod',
        'invoiceDue',
        'groupBy',
        'maxInvoiceThreshold',
        'maxInvoiceDue',
        'folioStatus',
        'useExchangeRate',
        'billType',
      ])}`
    : '';
  const getInvoiceReport = `getInvoiceReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getInvoiceReport} {
        id
        accountId
        company
        firstName
        lastName
        accountType
        startDate
        endDate
        invoiceType
        invoiceTotal
        invoiceDue
        dueDate
        currency
        description
        invoiceSummaryTotal
        addOnTaxAmount
        addOnTaxRate
        taxAmount
        taxRate
        status
        paymentMethod
        taxid
        totalDiscount
        purchaseOrderId
        clientId
        invoiceDate
        stampingDate
        exchangeRate
        folioStatus
        currentDateExchangeRate
        unRealizedGain
        invoiceNotes
        originalId
        billType
        taxCode
        uom
        uomName
        quantity
        unitPrice
      }
    }
  `;
};
