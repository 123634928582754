import React, { Component } from 'react';
import { size, maxBy, concat } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { AccordionGroup, Accordion, GenericInput, ModalUploadFile } from '../../../components/common';
import AddressForm from './UserForm/AddressForm';
import ContactForm from './UserForm/ContactForm';
import RoleForm from './UserForm/RoleForm';
import RoleGroupForm from './UserForm/RoleGroupForm';
import { getBase64 } from '../../../utils/utils';
import { getId, setAvatarBase64 } from '../../../localStorages';

const listInputs = [
  {
    label: 'label.userId',
    wrapperClass: 'col-md-4',
    name: 'userId',
    required: true,
  },
  {
    label: 'label.userStatus',
    wrapperClass: 'col-md-4',
    name: 'status',
    // required: true,
    type: 'select',
    tOptions: 'selections:status',
  },
  {
    label: 'label.userType',
    wrapperClass: 'col-md-4',
    name: 'type',
    required: true,
    type: 'select',
    tOptions: 'selections:userType',
  },
  {
    label: 'label.userCategory',
    wrapperClass: 'col-md-4',
    name: 'category',
    required: true,
    type: 'select',
    tOptions: 'selections:category',
  },
  {
    label: 'label.userPassword',
    wrapperClass: 'col-md-4',
    name: 'password',
    type: 'password',
    required: true,
  },
  {
    label: 'label.profilePicture',
    name: 'profilePicturePath',
    fa: 'fa fa-external-link',
    wrapperClass: 'col-md-4',
  },
];

const validateFormData = (data, t) => {
  const { address, contact } = data;
  if (!data.id && !data.userId) {
    toast.error(t('message.userIdCannotBeEmpty'));
    return false;
  }

  if (!data.id && !data.password) {
    toast.error(t('message.userPasswordCannotBeEmpty'));
    return false;
  }

  if (!data.id && !data.type) {
    toast.error(t('message.userTypeCannotBeEmpty'));
    return false;
  }
  // if (
  //   !address ||
  //   address.length < 1 ||
  //   !address[0].street ||
  //   !address[0].city ||
  //   !address[0].country ||
  //   !address[0].postalCode
  // ) {
  //   toast.error('User address: street, city, country, postal code cannot be empty');
  //   return false;
  // }

  // if (!contact || contact.length < 1 || !contact[0].firstName || !contact[0].lastName || !contact[0].email) {
  //   toast.error('User contact: first name, city, last name, email cannot be empty');
  //   return false;
  // }

  return true;
};

class UserForm extends Component {
  state = {
    isSubmitting: false,
    wasValidated: false,
    isSelectingRoles: false,
    parentItemId: null,
    roleGroups: [],
    roles: [],
    activeAccordion: '',
    isReadOnly: true,
    isOpenModalUpload: false,
    itemSelect: null,
    base64FileSelect: null,
    fileSelect: null,
    fileUploaded: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    return {
      ...props.item,
      item: props.item,
    };
  }

  onOpenParentSelector = () => {
    this.setState({ isSelectingRoles: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingRoles: false });
  };

  onSelectRoles = (parentItemId, item) => {
    const { roleGroups = [] } = this.state;
    this.setState({ parentItemId, roleGroups: [...roleGroups, { ...item }], isSelectingRoles: false });
  };

  handleInputChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    const { onSubmit, t, onReloadAvatar } = this.props;
    if (!validateFormData(this.state, t)) return false;
    const {
      id,
      userId,
      password,
      type,
      status,
      category,
      address,
      contact,
      roleGroups = [],
      roles = [],
      profilePicturePath,
      fileUploaded,
    } = this.state;
    const lastIndexRole =
      (roles.length && maxBy(roles, item => item.index) && maxBy(roles, item => item.index).index) || 0;
    const lastIndexRoleGroup =
      (roleGroups.length && maxBy(roleGroups, item => item.index) && maxBy(roleGroups, item => item.index).index) || 0;
    const roleDelete = roles.filter(item => size(item) === 1);
    const roleAddNew = roles
      .filter(item => !item.index)
      .map((item, idx) => ({ index: lastIndexRole + 1 + idx, roleId: item.id }));
    const roleEdit = roles.filter(item => item.index > 0 && size(item) > 1);
    const roleGroupEdit = roleGroups.filter(item => item.index > 0 && size(item) > 1);
    const roleGroupAddNew = roleGroups
      .filter(item => !item.index)
      .map((item, idx) => ({ index: lastIndexRoleGroup + 1 + idx, roleGroupId: item.id }));
    const roleGroupDelete = roleGroups.filter(item => size(item) === 1);
    let simpleRoles = roleEdit && roleEdit.map(r => ({ index: r.index, roleId: r.id }));
    let simpleRoleGroups = roleGroupEdit && roleGroupEdit.map(r => ({ index: r.index, roleGroupId: r.id }));
    if (roleDelete.length > 0) {
      simpleRoles = concat(simpleRoles, roleDelete);
    }

    if (roleAddNew.length > 0) {
      simpleRoles = concat(simpleRoles, roleAddNew);
    }

    if (roleGroupDelete.length > 0) {
      simpleRoleGroups = concat(simpleRoleGroups, roleGroupDelete);
    }

    if (roleGroupAddNew.length > 0) {
      simpleRoleGroups = concat(simpleRoleGroups, roleGroupAddNew);
    }

    const data = {
      userId,
      password,
      type,
      status: status || null,
      category,
      contact: contact || null,
      address: address || null,
      roles: simpleRoles,
      roleGroups: simpleRoleGroups,
      profilePicturePath: profilePicturePath || null,
    };
    if (onSubmit) {
      if (this?.props?.id === getId() && fileUploaded) {
        getBase64(fileUploaded, result => {
          onReloadAvatar(result);
          setAvatarBase64(result);
        });
      }
      return onSubmit(data);
    }

    return '';
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onChangeItem = (name, data) => {
    this.setState({ [name]: data });
  };

  onAddNewContact = () => {
    let { contact } = this.state;
    contact = [
      {
        salutation: null,
        firstName: null,
        middleName: null,
        lastName: null,
        position: null,
        organization: null,
        email: null,
        phones: [],
      },
    ];
    this.setState({ contact: [...contact] });
  };

  onAddNewAddress = () => {
    let { address } = this.state;
    address = [
      {
        street: null,
        extraLine: null,
        landmark: null,
        city: null,
        state: null,
        country: null,
        postalCode: null,
      },
    ];
    this.setState({ address: [...address] });
  };

  onCancelData = () => {
    this.setState({
      isSubmitting: false,
      wasValidated: false,
      isSelectingRoles: false,
      parentItemId: null,
      roleGroups: [],
      roles: [],
      activeAccordion: '',
      address: [],
      contact: [],
      userId: null,
      password: null,
      type: null,
      status: null,
      category: null,
    });
  };

  onFocusField = () => {
    this.setState({ isReadOnly: false });
  };

  onToggleModalUpload = path => {
    const { readFileFromS3, s3Config } = this.props;
    if (path) {
      readFileFromS3({ ...s3Config, url: path }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            this.setState({ base64FileSelect: result });
          });
          this.setState({ isOpenModalUpload: true, itemSelect: path, fileSelect: data });
        }
      });
    } else {
      this.setState({ isOpenModalUpload: true, itemSelect: path });
    }
  };

  onHandleUpload = ({ files }) => {
    if (files && files[0]) {
      const { uploadMultiPartFiles, id } = this.props;
      const query =
        '{"query": "mutation{uploadMultipartFiles(input: {category: USER_PROFILE, userProfileType: USER_PROFILE_PICTURE}){absoluteFile}}"}';
      const formData = new FormData();
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          if (res.data.data && res.data.data.data && res.data.data.data.uploadMultipartFiles) {
            try {
              const pathSelect = res.data.data.data.uploadMultipartFiles[0];
              this.setState({
                fileUploaded: files[0],
                profilePicturePath: pathSelect.absoluteFile,
              });
            } catch (error) {
              console.log(error);
              this.setState({
                fileUploaded: files[0],
                profilePicturePath: null,
              });
            }
          }
          this.setState({
            isOpenModalUpload: false,
          });
          // setFileUploaded(files[0]);
          // setOpenModalUpload(false);
          // setisUploaded(true);
        }
      });
    } else {
      this.setState({ isOpenModalUpload: false });
    }
  };

  render() {
    const {
      creatingMode,
      isSubmitting,
      item,
      modeModifyUser,
      modeChangeUserStatus,
      modeCreateUser,
      t,
      onCancel,
      getS3Buctket,
      readFileFromS3,
    } = this.props;
    const {
      roleGroups,
      roles,
      activeAccordion,
      address,
      contact,
      isReadOnly,
      isOpenModalUpload,
      itemSelect,
      base64FileSelect,
      fileSelect,
    } = this.state;
    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation ${this.state.wasValidated ? 'was-validated' : ''}`}
          noValidate
        >
          <div className="form-row mb-2">
            {modeChangeUserStatus === 0 &&
              listInputs
                .filter(item => creatingMode || (item.name !== 'status' && item.name !== 'userId'))
                .map(item => (
                  <GenericInput
                    key={item.name}
                    {...item}
                    value={this.state[item.name]}
                    onChange={this.handleInputChange}
                    onFocus={this.onFocusField}
                    readOnly={isReadOnly}
                    onClick={item.fa ? () => this.onToggleModalUpload(this.state[item.name]) : null}
                  />
                ))}
            {modeChangeUserStatus !== 0 &&
              listInputs
                .filter(item => creatingMode || item.name !== 'userId')
                .map(item => (
                  <GenericInput
                    key={item.name}
                    {...item}
                    value={this.state[item.name]}
                    onChange={this.handleInputChange}
                    disabled={modeChangeUserStatus === 1 && item.name === 'status'}
                    onFocus={this.onFocusField}
                    readOnly={isReadOnly}
                    onClick={item.fa ? () => this.onToggleModalUpload(this.state[item.name]) : null}
                  />
                ))}
          </div>
          <AccordionGroup>
            <Accordion
              title={t('label.userAddress')}
              isOpen={activeAccordion === 'address'}
              onClick={() => {
                this.setState({
                  activeAccordion: activeAccordion === 'address' ? '' : 'address',
                  address: address || [],
                });
              }}
            >
              {address && (
                <AddressForm
                  isOpen
                  item={address || []}
                  onAddNewAddress={this.onAddNewAddress}
                  onChangeItem={e => this.onChangeItem('address', e)}
                />
              )}
            </Accordion>
            <Accordion
              title={t('label.userContact')}
              isOpen={activeAccordion === 'contact'}
              onClick={e => {
                this.setState({
                  activeAccordion: activeAccordion === 'contact' ? '' : 'contact',
                  contact: contact || [],
                });
              }}
            >
              {contact && (
                <ContactForm
                  isOpen
                  item={contact || []}
                  onAddNewContact={this.onAddNewContact}
                  onChangeItem={e => this.onChangeItem('contact', e)}
                />
              )}
            </Accordion>
            <Accordion
              title={t('label.userRoles')}
              isOpen={activeAccordion === 'roles'}
              onClick={e => {
                this.setState({ activeAccordion: activeAccordion === 'roles' ? '' : 'roles', roles: roles || [] });
              }}
              // className={`${!roles || roles.length < 1 ? 'has-error' : ''}`}
              className={`${(!roleGroups || roleGroups.length < 1) && (!roles || !roles.length) ? 'has-error' : ''}`}
            >
              {roles && <RoleForm isOpen roles={roles || []} onChangeItem={e => this.onChangeItem('roles', e)} />}
            </Accordion>
            <Accordion
              title={t('label.userRoleGroups')}
              isOpen={activeAccordion === 'roleGroups'}
              onClick={() => {
                this.setState({
                  activeAccordion: activeAccordion === 'roleGroups' ? '' : 'roleGroups',
                  roleGroups: roleGroups || [],
                });
              }}
              className={`${(!roleGroups || roleGroups.length < 1) && (!roles || !roles.length) ? 'has-error' : ''}`}
            >
              {roleGroups && (
                <RoleGroupForm
                  isOpen
                  roleGroups={roleGroups || []}
                  onChangeItem={e => this.onChangeItem('roleGroups', e)}
                />
              )}
            </Accordion>
          </AccordionGroup>

          <div className="form-group col-md-12 action-buttons">
            <label htmlFor="validationCustom01" />
            <br />
            {isSubmitting && (
              <button type="button" className="button button-border x-small float-right">
                {t('label.save')}
              </button>
            )}
            {!creatingMode && !isSubmitting && modeModifyUser === 2 && (
              <button
                type="submit"
                className="button button-border x-small float-right"
              >
                {t('label.modify')}
              </button>
            )}
            {creatingMode && !isSubmitting && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.create')}
              </button>
            )}
            {onCancel ? (
              <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
                {t('label.cancel')}
              </button>
            ) : (
              <button
                onClick={this.onCancelData}
                type="button"
                className="button button-border black x-small  float-right"
              >
                {t('label.cancel')}
              </button>
            )}
          </div>
        </form>
        <ModalUploadFile
          title="label.uploadFile"
          isOpen={isOpenModalUpload}
          onCancel={() => this.setState({ isOpenModalUpload: false })}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          base64FileSelect={base64FileSelect}
          fileSelect={fileSelect}
          // isDisabledUpload={!!base64FileSelect}
          type="IMAGE"
        />
      </div>
    );
  }
}

UserForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object,
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
};

UserForm.defaultProps = {
  item: {},
  isSubmitting: false,
  creatingMode: false,
};
export default withTranslation('common')(UserForm);
