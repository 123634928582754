import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataCreate = parseOrderMutation(data, ['orderType', 'index', 'omsTasks']);
  const createOmsConfig = `createOmsConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createOmsConfig} {
      id
    }
  }
`;
};
