import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['folioType']);
  const sendFolio = `sendFolio(input: ${dataCreate})`;
  return gql`
  mutation {
    ${sendFolio}
  }
`;
};
