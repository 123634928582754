import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'jurisdiction',
    'taxPercent',
    'minimumThreshold',
    'maximumThreshold',
    'additionalTaxRate',
  ]);
  const modifyTaxConfig = `modifyTaxConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyTaxConfig} {
      id
    }
  }
`;
};
