import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['dataType', 'dataUnit'])}` : '';
  const getTelecomSummaryReport = `getTelecomSummaryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getTelecomSummaryReport} {
        lineDate
        organization
        operatorName
        serviceType
        usageType
        operatorId
        dataType
        dataUnit
        dataValue
      }
    }
  `;
};
