import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import AccountingLog from './AccountingLog';
import Journals from './Journals';
import ERPExtracts from './ERPExtracts';
import { makeGetPermissionsRevenue, makeGetPermissionsReports } from '../App/selectors';
import { AccountingReconciliation } from '../ReportManagement/ReconciliationReports';
import { checkPermissionForAllPages as checkPermissionForAllReportPages } from '../ReportManagement/CheckPermission';
import Sidebar from './Sidebar';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class RevenueManagement extends PureComponent {
  render() {
    const { permissionsRevenue, permissionsReports } = this.props;
    let permissions = {};
    let permissionReports = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsRevenue, listKeys: permissionList.revenue })) return null;

    if (permissionsRevenue && permissionsRevenue.revenueModulePermissions) {
      const listPermission = permissionsRevenue.revenueModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissionReports = checkPermissionForAllReportPages({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.journals.path} component={() => <Journals permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.accountingLog.path}
                component={() => <AccountingLog permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.erpExtracts.path}
                component={() => <ERPExtracts permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueAccountingReconciliation.path}
                component={props => <AccountingReconciliation {...props} permissions={permissionReports} isRevenue />}
              />
            </Switch>
            <br />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'revenueReducer', reducer });
const withSaga = injectSaga({ key: 'revenueSaga', saga });

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

const mapStateToProps = createStructuredSelector({
  permissionsRevenue: makeGetPermissionsRevenue() || {},
  permissionsReports: makeGetPermissionsReports() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducer, withSaga, withReducerReport, withSagaReport, withConnect)(RevenueManagement);
