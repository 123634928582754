import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'stream', 'serviceType']);
  const createZeroRateUsageConfig = `createZeroRateUsageConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createZeroRateUsageConfig} {
      id
    }
  }
`;
};
