import React from 'react';
import PropTypes from 'prop-types';

const CheckFieldRole = ({ label, name, checked, wrapperClass, wrapperLabel, validate, onChange, ...props }) => {
  const inputID =
    props.id ||
    `custom-checkbox-${Math.random()
      .toString()
      .replace('.', '')}`;

  return (
    <div className={`${props.className} ${wrapperClass}`}>
      {wrapperLabel && <span>{wrapperLabel}</span>}
      <div className="check-item">
        <div className="custom-control custom-checkbox">
          <input
            {...props}
            onClick={e => {
              e.stopPropagation();
              return onChange({ name, value: !props.value, event: e });
            }}
            checked={props.value}
            type="checkbox"
            className="custom-control-input"
            id={inputID}
            readOnly
          />
          <label className="custom-control-label" htmlFor={inputID}>
            {label}
          </label>
          {validate && <div className="invalid-checkbox">{validate}</div>}
        </div>
      </div>
    </div>
  );
};

CheckFieldRole.propTypes = {
  wrapperLabel: PropTypes.any,
  label: PropTypes.string,
  validate: PropTypes.string,
};

CheckFieldRole.defaultProps = {
  wrapperLabel: false,
  label: '',
  validate: '',
};
export default CheckFieldRole;
