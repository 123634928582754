import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../../utils/utils';
import { tableColumnsCollectionAgent } from '../../constant';
import { FormWithTableItem, DataTable, TablePagination } from '../../../../components/common';
import { searchCollectionAgentActivity } from '../../actions';
import { makeAgentActivityParams, makeGetAgentActivities, makeErrorAgentActivity } from '../../selectors';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import { checkPermissionSearchCollectionAgentActivity } from '../../CheckPermission';

class AgentSumaryActivity extends Component {
  constructor() {
    super();
    this.state = {
      isSearching: false,
      isActiveNext: false,
      page: 0,
      size: 20,
      totalCount: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.agentActivites }),
    };
  }

  componentDidMount() {
    this.doSearchCollectionAgent();
  }

  doSearchCollectionAgent = () => {
    const { page, size } = this.state;
    const { searchCollectionAgentActivity } = this.props;
    this.setState({ isSearching: true });
    searchCollectionAgentActivity(
      {
        page: page + 1,
        size,
      },
      () => this.setState({ isSearching: false })
    );
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchCollectionAgent());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchCollectionAgent());
  };

  render() {
    const { agentActivites, permissionsCollections, t } = this.props;
    const { page, size, totalCount, isSearching } = this.state;

    let modeSearchCollectionAgentActivity = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeSearchCollectionAgentActivity = checkPermissionSearchCollectionAgentActivity({ listPermission });
    }

    if (modeSearchCollectionAgentActivity === 0) return '';

    return (
      <div className="col-md-12 mb-30">
        <FormWithTableItem title={t('label.collectionAgents')} wrapperClass="collection-form-item">
          <DataTable
            columns={tableColumnsCollectionAgent}
            data={agentActivites}
            onSort={this.onSortColumn}
            isLoading={isSearching}
          />
        </FormWithTableItem>
        <br />
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={this.onPageChange}
          onSizeChange={this.onSizeChange}
        />
      </div>
    );
  }
}

AgentSumaryActivity.propTypes = {
  searchCollectionAgentActivity: PropTypes.func.isRequired,
  agentActivites: PropTypes.arrayOf(PropTypes.any),
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

AgentSumaryActivity.defaultProps = {
  agentActivites: [],
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  agentActivites: makeGetAgentActivities() || [],
  params: makeAgentActivityParams() || {},
  errorMessage: makeErrorAgentActivity() || '',
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default withTranslation('common')(
  connect(
    mapStateToProps,
    {
      searchCollectionAgentActivity,
    }
  )(AgentSumaryActivity)
);
