/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropsType from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../../App/RouteNames';
import { getUom, modifyUom, createUom } from '../../actions';
import { makeGetUom } from '../../selectors';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';
import { GenericInput, FormWithTableItem, DataTable, TablePagination } from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import { getPageTotalCount } from '../../../../utils/utils';
import {
  checkPermissionGetUomConfig,
  checkPermissionModifyUomConfig,
  checkPermissionCreateUomConfig,
} from '../../CheckPermission';
import { UomSearchForm } from '../../../../components/BaseConfigurationsHub';

const sortTypes = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  symbol: {
    asc: 'symbol_ASC',
    desc: 'symbol_DESC',
  },
};

const UMO = ({ getUom, modifyUom, createUom, permissionsPricing }) => {
  const { t } = useTranslation('common');
  const [isSearching, setIsSearching] = useState(false);
  const [isModify, setIsModify] = useState(false);
  const [data, setData] = useState([]);
  const [wasValidated, setValidate] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [id, setId] = useState(null);
  const formRef = useRef();

  const doGetUmo = () => {
    setIsSearching(true);
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    getUom(payload, ({ success, data }) => {
      setIsSearching(false);
      setTotalCount(getPageTotalCount({ totalCount, page, size, items: data.searchList }));
      if (success) {
        setData(data.searchList);
        if (data && data.id) {
          setIsModify(true);
          setId(data.id);
        }
      }
    });
  };

  useEffect(() => {
    doGetUmo();
  }, [getUom, page, size, filter, sorted]);

  let modeGetUomConfig = 0;
  let modeModifyUomConfig = 0;
  let modeCreateUomConfig = 0;
  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermission = permissionsPricing.pricingModulePermissions;
    modeGetUomConfig = checkPermissionGetUomConfig({ listPermission });
    modeModifyUomConfig = checkPermissionModifyUomConfig({ listPermission });
    modeCreateUomConfig = checkPermissionCreateUomConfig({ listPermission });
  }
  if (!modeGetUomConfig) return '';

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleAddUom = () => {
    let newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData) newData = [];
    newData.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData = [
      {
        index: lastIndex + 1,
        unitId: null,
        name: null,
        description: null,
        startDate: null,
        endDate: null,
        symbol: null,
      },
      ...newData,
    ];
    setData(newData);
  };

  const headerTable = [
    {
      name: 'unitId',
      label: 'label.unitId',
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.unitId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="unitId"
          required
        />
      ),
    },
    {
      name: 'name',
      label: 'label.name',
      required: true,
      sortable: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="name"
          required
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="description"
        />
      ),
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.startDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="startDate"
          type="date"
          required
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.endDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="endDate"
          type="date"
        />
      ),
    },
    {
      name: 'symbol',
      label: 'label.symbol',
      sortable: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.symbol}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="symbol"
        />
      ),
    },
  ];

  const onHandleSubmitSearch = filter => {
    setFilter(filter);
    setPage(0);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    const newPayload = cloneDeep(data);
    if (!isModify)
      return createUom({ units: newPayload }, ({ success }) => {
        if (success) doGetUmo();
      });

    return modifyUom({ units: newPayload, id }, ({ success }) => {
      if (success) doGetUmo();
    });
  };
  return (
    <div className="col-md-12 mb-30">
      <PageTitle
        linkTo={RouteNames.currency.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
          { name: `${t('baseConfigurationsPage:sidebar.unitOfMeasure')}` },
        ]}
      />
      <br />
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="repeater-file">
              <div>
                <UomSearchForm onSubmit={onHandleSubmitSearch} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormWithTableItem title={`${t('baseConfigurationsPage:sidebar.unitOfMeasure')}`} subClass="border-bottom">
        <br />

        <form
          ref={formRef}
          noValidate
          className={`col-md-12 p-2 needs-validation ${wasValidated ? 'was-validated' : ''}`}
          onSubmit={modeModifyUomConfig === 2 ? onSubmit : () => {}}
        >
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateUomConfig === 2 && (
              <button
                disabled={modeCreateUomConfig === 1}
                type="button"
                className="button button-border black x-small"
                onClick={onHandleAddUom}
              >
                +
                {t('label.addNewUnit')}
              </button>
            )}
            {modeModifyUomConfig === 2 && (
              <button
                type="submit"
                className="button button-border x-small float-right"
              >
                {isModify ? t('label.saveConfig') : t('label.createConfig')}
              </button>
            )}
            <button type="button" onClick={() => doGetUmo()} className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={headerTable}
              isSupportRemoveIndex
              data={data && data.length ? data : []}
              isLoading={isSearching}
              sorted={sorted}
              onSort={onSortColumn}
              isFixedHeaderTable
            />
          </div>
        </form>
      </FormWithTableItem>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
    </div>
  );
};

UMO.propTypes = {
  getUom: PropsType.func.isRequired,
  modifyUom: PropsType.func.isRequired,
  createUom: PropsType.func.isRequired,
};

UMO.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  uom: makeGetUom() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default connect(mapStateToProps, { getUom, modifyUom, createUom })(UMO);
