import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'percent',
    'percentile',
    'cutOffDate',
    'advanceFlag',
    'skipNoOfMonths',
    'recurringUnit',
    'recurringFrequency',
  ]);
  const indexDataSubmit = dataModify.indexOf('dataSubmit');
  let newDataModify = indexDataSubmit !== -1 ? `${dataModify.replace('{dataSubmit: ', '')}` : dataModify;
  newDataModify = indexDataSubmit !== -1 ? `${newDataModify.substring(0, newDataModify.length - 1)}` : newDataModify;
  const modifyAccountServices = `modifyAccountServices(input: ${newDataModify})`;
  return gql`
  mutation {
    ${modifyAccountServices} {
      id
    }
  }
`;
};
