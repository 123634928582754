import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import 'react-datepicker/dist/react-datepicker.css';

class DateGroup extends React.PureComponent {
  state = { isOpenDatePicker: false };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.value) return null;
    return {
      value: props.value ? moment(props.value).toDate() : null,
      oldValue: props.value,
    };
  }

  onHandleOpenDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (!isOpenDatePicker) this.setState({ isOpenDatePicker: true });
  };

  onHandleCloseDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (isOpenDatePicker) this.setState({ isOpenDatePicker: false });
  };

  handleChange = value => {
    this.setState({ value, isOpenDatePicker: false });
    const { onChange } = this.props;
    if (onChange) {
      if (value) {
        onChange(moment(value).format('YYYY-MM-DD'));
        return;
      }
      onChange(null);
    }
  };

  render() {
    const { isOpenDatePicker } = this.state;
    const { label, wrapperClass, disabled, t, tooltipDetails, ...restProps } = this.props;
    return (
      <div className={`form-group ${wrapperClass}`}>
        {label && (
          <span>
            {t(label)}
            {label && restProps.required && <i className="fa fa-star is-required" />}
            {tooltipDetails && (
            <Tooltip
              trigger={['hover']}
              placement="bottom"
              overlay={tooltipDetails || ''}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
              disabled={disabled}
            >
              <i
                disabled={disabled}
                className="fa fa-question-circle-o ico-question-embrix"
                aria-hidden="true"
              />
            </Tooltip>
          )}
          </span>
        )}
        <DatePicker
          className="form-control"
          open={isOpenDatePicker}
          id="from-date"
          onFocus={this.onHandleOpenDatePicker}
          onBlur={this.onHandleCloseDatePicker}
          onClickOutside={this.onHandleCloseDatePicker}
          selected={this.state.value}
          disabled={disabled}
          isClearable={!disabled}
          autoComplete="off"
          popperModifiers={{
            flip: {
              behavior: ['bottom'],
            },
          }}
          {...restProps}
          onChange={this.handleChange}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={20}
          scrollableYearDropdown
          dropdownMode="select"
        />
        <button
          type="button"
          className="icon-calendar no-border"
          onClick={this.onHandleOpenDatePicker}
          disabled={disabled}
          // onBlur={this.onHandleCloseDatePicker}
        >
          <i className="fa fa-calendar" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

DateGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
};

DateGroup.defaultProps = {
  label: '',
  placeholder: 'Select Date',
  wrapperClass: 'col-md-3',
};

export default withTranslation('common')(DateGroup);
