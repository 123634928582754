import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideBar from '../../components/common/SideBar';
import RouteName from '../App/RouteNames';
import {
  checkPermissionForAllPages,
  checkPermissionGetInvoiceReport,
  checkPermissionSearchSavedManualInvoice,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { checkPermissionForAllPages as checkPermissionForAllPagesReport } from '../ReportManagement/CheckPermission';

class Sidebar extends PureComponent {
  render() {
    const { isExpand = true, permissionsBilling, permissionsReports, permissionsInvoice } = this.props;
    let permissions = {};
    let permissionsReport = {};
    // let modeGetInvoiceReport = 0;
    let modeSearchSavedManualInvoice = 0;
    if (!isHasPermissionWithMenu({ permissions: permissionsBilling, listKeys: permissionList.billing })) return null;
    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermission = permissionsBilling.billingModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissionsReport = checkPermissionForAllPagesReport({ listPermission });
    }

    if (permissionsInvoice && permissionsInvoice.invoicingModulePermissions) {
      const listPermission = permissionsInvoice.invoicingModulePermissions;
      modeSearchSavedManualInvoice = checkPermissionSearchSavedManualInvoice({ listPermission });
    }

    const { modeGetInvoiceReport, modeGetEmployeeCostingPnl } = permissionsReport;

    const {
      modeGetDelayedBilling,
      modeGetInAdvanceBilling,
      modeGetPendingBillTrigger,
      modeGetExpensesConfig,
      modeGetExpenseData,
      modeSearchEmployeeBonus,
    } = permissions;
    const taxconfig = [];
    const invoicingRoute = [];
    const teRoute = [];
    const teReportRoute = [];
    let bulkOperations = [];
    if (modeGetDelayedBilling) {
      taxconfig.push({
        icon: 'ti-image',
        label: 'common:label.delayedBilling',
        href: RouteName.delayedBilling.path,
      });
    }
    if (modeGetPendingBillTrigger) {
      taxconfig.push({
        icon: 'ti-panel',
        label: 'common:label.pendingBillTrigger',
        href: RouteName.pendingBillTrigger.path,
      });
    }

    if (modeGetInAdvanceBilling) {
      taxconfig.push({
        icon: 'ti-image',
        label: 'common:label.advanceBilling',
        href: RouteName.advanceBilling.path,
      });
    }

    if (modeGetExpensesConfig) {
      taxconfig.push({
        icon: 'ti-shift-right-alt',
        label: 'common:label.TEExpenseType',
        href: RouteName.TEExpenseType.path,
      });
    }

    if (modeGetExpenseData) {
      teRoute.push({
        icon: 'ti-menu-alt',
        label: 'common:label.expenseData',
        href: RouteName.TEExpenseData.path,
      });
    }

    if (modeSearchEmployeeBonus) {
      teReportRoute.push({
        icon: 'ti-share',
        label: 'common:label.employeeBonus',
        href: RouteName.employeeBonus.path,
      });
    }

    if (modeGetEmployeeCostingPnl) {
      teReportRoute.push({
        icon: 'ti-receipt',
        label: 'common:label.PLReport',
        href: RouteName.employeeCostingPnl.path,
      });
    }

    if (modeGetEmployeeCostingPnl) {
      teReportRoute.push({
        icon: 'ti-receipt',
        label: 'common:label.PLQuaterlyReport',
        href: RouteName.employeeCostingPnlQuaterly.path,
      });
    }

    if (modeGetEmployeeCostingPnl) {
      teReportRoute.push({
        icon: 'ti-gallery',
        label: 'common:label.PLYearlyReport',
        href: RouteName.employeeYearlyPl.path,
      });
    }

    if (modeGetInvoiceReport) {
      invoicingRoute.push({
        icon: 'ti-harddrives',
        label: 'common:label.invoices',
        href: RouteName.invoicingBilling.path,
      });
    }
    if (modeSearchSavedManualInvoice) {
      invoicingRoute.push({
        icon: 'ti-folder',
        label: 'common:label.savedManualBills',
        href: RouteName.savedManualBills.path,
      });
    }
    if (invoicingRoute && invoicingRoute.length) {
      bulkOperations = [
        {
          isHaveExpand: true,
          mainTilte: 'common:label.bulkOperations',
          childs: [...invoicingRoute],
        },
      ];
    }
    const sidePropDetails = {
      isExpand: false,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'common:label.config',
          childs: [...taxconfig],
          isNoExpand: true,
        },
        {
          isHaveExpand: true,
          mainTilte: 'common:label.tAndE',
          childs: [
            ...teRoute,
            {
              icon: 'ti-user',
              label: 'common:label.client',
              href: RouteName.clientBilling.path,
            },
            {
              icon: 'ti-eye',
              label: 'common:label.employee',
              href: RouteName.employeeBilling.path,
            },
            ...teReportRoute,
          ],
        },
        ...bulkOperations,
      ],
    };

    const newSideProps = cloneDeep(sidePropDetails);
    newSideProps.isExpand = isExpand;
    return <SideBar {...newSideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsBilling = store.get('auth').toJS().permissionsApp.billingHubModules;
  const permissionsReports = store.get('auth').toJS().permissionsApp.opsHubModules;
  const permissionsInvoice = store.get('auth').toJS().permissionsApp.billingHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsBilling: permissionsBilling.length ? permissionsBilling.filter(a => a.moduleName === 'BILLING')[0] : {},
    permissionsReports: permissionsReports.length ? permissionsReports.filter(a => a.moduleName === 'REPORTS')[0] : {},
    permissionsInvoice: permissionsInvoice.length
      ? permissionsInvoice.filter(a => a.moduleName === 'INVOICING')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
  permissionsBilling: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  sideProps: {},
  permissionsBilling: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
