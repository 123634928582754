import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['type', 'action', 'cost', 'quantity', 'exchangeRate', 'orderFlag']);
  const saveAccountNonBillables = `saveAccountNonBillables(input: ${dataModify})`;
  return gql`
  mutation {
    ${saveAccountNonBillables} {
      id
    }
  }
`;
};
