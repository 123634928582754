import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class OperatorDisputeSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.billingStartDate',
      name: 'billingStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.billingEndDate',
      name: 'billingEndDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.originOperator',
      name: 'originOperator',
    },
    {
      label: 'common:label.destinationOperator',
      name: 'destinationOperator',
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:operatorFileStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

OperatorDisputeSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OperatorDisputeSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OperatorDisputeSearchForm);
