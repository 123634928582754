import React from 'react';
import PropTypes from 'prop-types';
import { map, isArray } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { DataTable, CardHeaderForm, ModalAccept, Switch, FormCollapse } from '../../../components/common';
import { selectPackageId, selectBundleId, selectPriceOfferId, getBundleByBundleId } from '../../App/actions';
import {
  ServiceTable,
  PriceOffersSelectModal,
  PriceOffersSearchForm,
  BundelSearchForm,
} from '../../../components/Quotes';
import { getMessageModal } from '../utilities';
import constants from '../constants';
import ChooseKindService from '../CommonSupportQuote/ChooseKindService';

let isFirstToggleTab = false;

const tableColumns = [
  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
  },
  // {
  //   name: 'status',
  //   label: 'common:label.status',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  // },
  {
    name: 'reason',
    label: 'common:label.reason',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reason')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'common:label.initialTerm',
    // isRenderT: true,
    // render: (colName, item, t) => {
    //   const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTerm) : '';
    //   return <span>{slt ? slt.label : ''}</span>;
    // },
  },
  {
    name: 'initialTermUnit',
    label: 'common:label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'common:label.renewalTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'common:label.renewalTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialTerm',
    label: 'common:label.trialTerm',
  },
  {
    name: 'trialTermUnit',
    label: 'common:label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

class OrderModifyInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOverrideLines: {},
      isBundle: false,
      isPackage: false,
      isPriceOffers: false,
      selectTableColumns: [],
      itemRemove: '',
      activeTab: {},
    };
    this.selectService = [];
    this.ids = [];
  }

  componentDidMount = () => {
    const { quoteDetails } = this.props;
    this.getTypePurchase(quoteDetails.services);
    isFirstToggleTab = false;
  };

  componentWillReceiveProps(nextProps) {
    const { quoteDetails } = this.props;
    if (JSON.stringify(nextProps.quoteDetails) !== JSON.stringify(quoteDetails)) {
      this.getTypePurchase(nextProps.quoteDetails.services);
    }
  }

  componentDidUpdate() {
    const { subscriptionName } = this.props;
    if (subscriptionName && !isFirstToggleTab) {
      isFirstToggleTab = true;
      this.onToggleTab(subscriptionName);
    }
  }

  handleClickServicesFormBtn = index => {
    const { isOverrideLines } = this.state;
    isOverrideLines[index] = !isOverrideLines[index];

    this.setState({
      isOverrideLines: { ...isOverrideLines },
    });
  };

  getTypePurchase = data => {
    const bSelected = [];
    const poSelected = [];
    if (!data.length) {
      this.setState({ mode: constants.ModalMode.none });
    } else if (data[0].packageId) {
      this.setState({
        modalTitle: 'Choose Package :',
        buttonText: 'Add Package',
        selectTableColumns: constants.tableSelectPackageColumns,
        mode: constants.ModalMode.purchasePackage,
        selectedItemIds: [data[0].packageId],
        positionTrash: true,
        isBundle: false,
        isPackage: true,
        isPriceOffers: false,
      });
    } else if (data[0].bundleId) {
      data.forEach(el => {
        bSelected.push(el.bundleId);
      });
      this.setState({
        modalTitle: 'Select Bundles :',
        buttonText: 'Add Bundle',
        mode: constants.ModalMode.purchaseBundle,
        selectTableColumns: constants.tableSelectBundleColumns,
        selectedItemIds: bSelected,
        positionTrash: true,
        isBundle: true,
        isPackage: false,
        isPriceOffers: false,
      });
    } else {
      data.forEach(el => {
        if (el.lines && el.lines.length)
          el.lines.forEach(elm => {
            poSelected.push(elm.priceOfferId);
          });
      });
      this.setState({
        modalTitle: 'Select Price Offers :',
        buttonText: 'Add Price Offer',
        mode: constants.ModalMode.purchaseAlaCarte,
        selectTableColumns: constants.tableSelectPriceOfferColumns,
        selectedItemIds: poSelected,
        // positionTrash: false,
        positionTrash: true,
        isBundle: false,
        isPackage: false,
        isPriceOffers: true,
      });
    }
  };

  searchItemsOfSelectModal = (postData, cb) => {
    const { mode } = this.state;
    const { selectPackageId, selectBundleId, selectPriceOfferId } = this.props;
    if (mode === constants.ModalMode.purchasePackage) {
      selectPackageId(postData, cb);
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      selectBundleId(postData, cb);
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      selectPriceOfferId(postData, cb);
    }
  };

  openSelectModal = ({ mode }) => {
    if (mode === constants.ModalMode.purchasePackage) {
      return this.setState({
        isOpenPriceOfferModal: true,
        modalTitle: 'label.choosePackage',
        buttonText: 'Add Package',
        selectTableColumns: constants.tableSelectPackageColumns,
        mode: constants.ModalMode.purchasePackage,
        positionTrash: true,
        isBundle: false,
        isPackage: true,
        isPriceOffers: false,
      });
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      return this.setState({
        isOpenPriceOfferModal: true,
        modalTitle: 'label.chooseBundle',
        buttonText: 'Add Bundle',
        mode: constants.ModalMode.purchaseBundle,
        selectTableColumns: constants.tableSelectBundleColumns,
        positionTrash: true,
        isBundle: true,
        isPackage: false,
        isPriceOffers: false,
      });
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      return this.setState({
        isOpenPriceOfferModal: true,
        modalTitle: 'label.choosePriceOffers',
        buttonText: 'Add Price Offer',
        mode: constants.ModalMode.purchaseAlaCarte,
        selectTableColumns: constants.tableSelectPriceOfferColumns,
        positionTrash: true,
        isBundle: false,
        isPackage: false,
        isPriceOffers: true,
      });
    }
  };

  onSelectModal = e => {
    const { onSelectService } = this.props;
    // const { mode } = this.state;
    // if (JSON.stringify(selectedItemIds) !== JSON.stringify(this.ids)) {
    //   onSelectService({
    //     services: newService,
    //     groupExistService,
    //     ids: this.ids,
    //     mode,
    //   });
    // }
    onSelectService({
      ...e,
    });

    this.setState({
      isOpenPriceOfferModal: false,
    });
  };

  onCancelModal = () => {
    this.setState({
      isOpenPriceOfferModal: false,
    });
  };

  onClickRow = (ids, list) => {
    const { mode } = this.state;
    if (mode === constants.ModalMode.purchasePackage) {
      if (ids.length > 0) {
        const rows = list.filter(item => item.id === ids[0]);
        this.selectService = rows.map(item => {
          return item.components;
        });
        this.ids = ids;
        return;
      }
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      if (ids.length > 0) {
        const tempServices = ids.map(id => {
          const rows = list.filter(item => item.id === id);

          return rows && rows.length > 0 ? rows[0] : {};
        });
        this.selectService = tempServices;
        this.ids = ids;
        return;
      }
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      if (ids.length > 0) {
        const tempServices = ids.map(id => {
          const rows = list.filter(item => item.id === id);
          return rows && rows.length > 0 ? rows[0] : {};
        });
        this.selectService = tempServices;
        this.ids = ids;
      }
    }
  };

  findLastIndex = () => {
    const { quoteDetails } = this.props;
    let lastIndex = 0;
    if (isArray(quoteDetails.services)) {
      for (let i = 0; i < quoteDetails.services.length; i++) {
        if (lastIndex < quoteDetails.services[i].index) {
          lastIndex = quoteDetails.services[i].index;
        }
      }
    }
    return lastIndex;
  };

  onSelectRemoveItem = itemRemove => {
    this.setState({ itemRemove });
    const { onToggle } = this.props;
    onToggle();
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive, isFirstToggleTab: true },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true, isFirstToggleTab: true } });
    }
  };

  render() {
    const {
      quoteDetails,
      subscriptionMessage,
      id,
      addLine,
      onChangeFormLine,
      groupServices,
      onChangeProvisioningId,
      isOpen,
      onToggle,
      onRemoveItem,
      getBundleByBundleId,
      onRemoveLine,
      modeAddBundle,
      modeAddPackage,
      modeAddPriceOffer,
      modeDeletePriceOffer,
      t,
      isReadOnly,
      onChangeModifyService,
      onHandleInitNewLines,
      isSupportPOName,
      modeOverrideQuoteLines,
      onToggleModalDiscounts,
      subscriptionName,
    } = this.props;
    const {
      isOverrideLines,
      mode,
      buttonText,
      modalTitle,
      isOpenPriceOfferModal,
      selectedItemIds,
      selectTableColumns,
      positionTrash,
      isBundle,
      isPackage,
      isPriceOffers,
      itemRemove,
      activeTab,
    } = this.state;

    const isRenderButtonOpenModal =
      (modeAddBundle !== 0 && isBundle) ||
      (modeAddPackage !== 0 && isPackage) ||
      (modeAddPriceOffer !== 0 && isPriceOffers);

    const isDisableButtonOpenModal =
      (modeAddBundle === 1 && isBundle) ||
      (modeAddPackage === 1 && isPackage) ||
      (modeAddPriceOffer === 1 && isPriceOffers);

    // const isRenderTrashBinServiceTable = (modeDeleteBundle === 2 && isBundle) || (modeDeletePackage === 2 && isPackage);
    const isRenderTrashBinServiceTable = true;
    const isRenderTrashBinLineTable = modeDeletePriceOffer === 2 && isPriceOffers;
    const columns = [...tableColumns];
    const subscription = [
      {
        id: quoteDetails.id,
        accountId: quoteDetails.accountId,
        status: quoteDetails.status,
        renewalTerm: quoteDetails.renewalTerm,
        renewalTermUnit: quoteDetails.renewalTermUnit,
        reason: quoteDetails.reason,
        initialTerm: quoteDetails.initialTerm,
        initialTermUnit: quoteDetails.initialTermUnit,
        trialTerm: quoteDetails.trialTerm,
        trialTermUnit: quoteDetails.trialTermUnit,
        effectiveDate: quoteDetails.effectiveDate,
      },
    ];
    const tableConfig = {
      columns,
      data: subscription,
      getRowClass: () => {
        return '';
      },
    };

    return (
      <div className="col-md-12 mb-30">
        {/* <PriceOffersSelectModal
          defaultFilter={{ status: 'SELLABLE' }}
          modalTitle={t(modalTitle)}
          allService={quoteDetails.services}
          isOpen={isOpenPriceOfferModal}
          isMutiSelect
          SearchForm={mode === constants.ModalMode.purchaseBundle ? PriceOffersSearchForm : BundelSearchForm}
          searchItems={this.searchItemsOfSelectModal}
          tableColumns={selectTableColumns}
          onSelect={this.onSelectModal}
          onCancel={this.onCancelModal}
          selectedItemIds={selectedItemIds}
          getBundleByBundleId={getBundleByBundleId}
          isBundle={isBundle}
          isPackage={isPackage}
          isPriceOffers={isPriceOffers}
          lastIndex={this.findLastIndex()}
          onClickRow={this.onClickRow}
          isSupportPOName={isSupportPOName}
        /> */}
        <div className="form-group col-md-12 mt-0 mb-2 text-center">
          <ChooseKindService
            // key={name}
            // id={selectItem.id}
            onSelectService={e => this.onSelectModal({ ...e, name: subscriptionName })}
            mode={mode}
            onChangeMode={mode =>
              this.openSelectModal({ mode, services: quoteDetails.services || [], subscriptionName })
            }
            // modePackagePurchase={modePackagePurchase}
            // modeBundlePurchase={modeBundlePurchase}
            // modeAlacartePurchase={modeAlacartePurchase}
            isMulti
            // data={val}
            // onHandleChange={() => this.onChangeSwitch({ index, name: 'isAddDiscountOffer' })}
            // subscriptionIndex={index}
            // onSelectModalDiscounts={this.onSelectModalDiscounts}
            onSelectModalDiscounts={() => onToggleModalDiscounts({ subscriptionName })}
            services={quoteDetails.services || []}
          />
          {/* {isRenderButtonOpenModal && (
            <button
              type="button"
              className="button button-border x-small"
              disabled={isDisableButtonOpenModal}
              onClick={() => {
                this.openSelectModal(constants.ModalMode.purchasePackage);
              }}
            >
              {t('label.addPackage')}
            </button>
          )}
          {isRenderButtonOpenModal && (
            <button
              type="button"
              className="button button-border x-small"
              disabled={isDisableButtonOpenModal}
              onClick={() => {
                this.openSelectModal(constants.ModalMode.purchaseBundle);
              }}
            >
              {t('label.addBundle')}
            </button>
          )}
          {isRenderButtonOpenModal && (
            <button
              type="button"
              className="button button-border x-small"
              disabled={isDisableButtonOpenModal}
              onClick={() => {
                this.openSelectModal(constants.ModalMode.purchaseAlaCarte);
              }}
            >
              {t('label.addAlaCarte')}
            </button>
          )}
          <button
            type="button"
            className="button button-border x-small"
            disabled={isDisableButtonOpenModal}
            onClick={onToggleModalDiscounts}
          >
            {t('label.addDiscounts')}
          </button> */}
        </div>
        <div className="card card-statistics col-md-12">
          <CardHeaderForm title={t('label.subscription')} label={t('label.accountNumber')} value={id} />
          <DataTable {...tableConfig} noDataMessage={subscriptionMessage} onClickRow={this.onClickRow} />
          <div className="row">
            {/* <h5 className="card-title title-form" style={{ fontWeight: 'bold' }}>
              {t('label.serviceUnits')}
            </h5> */}
            <Switch
              wrapperClass="mt-auto mb-auto ml-3"
              onChange={onChangeModifyService}
              title={t('label.modifyRemoveAsset')}
              checked={!isReadOnly}
            />
          </div>
          <div className="col-md-12 mt-3">
            <FormCollapse
              isActive={activeTab && subscriptionName === activeTab.name && activeTab.isActive}
              title={subscriptionName || 'null'}
              state={subscriptionName}
              isNoT
              onToggleTab={this.onToggleTab}
            >
              {map(groupServices, (service, index) => {
                return (
                  <ServiceTable
                    key={index}
                    isFetchLine={mode === constants.ModalMode.purchasePackage}
                    addLine={(serviceIndex, e) => addLine(index, serviceIndex, e)}
                    isOverrideLines={isOverrideLines[index]}
                    onChangeFormLine={(serviceIndex, e) => onChangeFormLine(index, serviceIndex, e)}
                    onChangeProvisioningId={onChangeProvisioningId}
                    services={service}
                    positionTrash={positionTrash}
                    isRenderTrashBinServiceTable={isRenderTrashBinServiceTable}
                    isRenderTrashBinLineTable={isRenderTrashBinLineTable}
                    handleClickServicesFormBtn={() => this.handleClickServicesFormBtn(index)}
                    onRemoveItem={this.onSelectRemoveItem}
                    onRemoveLine={onRemoveLine}
                    isReadOnly={isReadOnly}
                    onHandleInitNewLines={onHandleInitNewLines}
                    modeOverrideQuoteLines={modeOverrideQuoteLines}
                    isSupportShowAddDiscount
                  />
                );
              })}
            </FormCollapse>
          </div>
          <ModalAccept
            isOpen={isOpen}
            onToggle={onToggle}
            onAcceptModal={() => onRemoveItem({ itemRemove, isBundle, isPackage, isPriceOffers })}
            message={getMessageModal(mode, null, t)}
          />
        </div>
      </div>
    );
  }
}

OrderModifyInfo.defaultProps = {
  t: () => {},
  selectPackageId: () => {},
  selectBundleId: () => {},
  selectPriceOfferId: () => {},
  onSelectService: () => {},
  onToggle: () => {},
  addLine: () => {},
  onChangeFormLine: () => {},
  onChangeProvisioningId: () => {},
  onRemoveItem: () => {},
  onRemoveLine: () => {},
  onHandleInitNewLines: () => {},
  onChangeModifyService: () => {},
  subscriptionMessage: '',
  id: '',
  groupServices: {},
  isOpen: false,
  isBundle: false,
  isReadOnly: false,
  isSupportPOName: false,
  modeDeletePriceOffer: 0,
  modeAddBundle: 0,
  modeAddPackage: 0,
  modeAddPriceOffer: 0,
  quoteDetails: {},
  modeOverrideQuoteLines: 0,
};

OrderModifyInfo.propTypes = {
  t: PropTypes.func,
  quoteDetails: PropTypes.func.isRequired,
  getBundleByBundleId: PropTypes.func.isRequired,
  selectPackageId: PropTypes.func,
  selectBundleId: PropTypes.func,
  selectPriceOfferId: PropTypes.func,
  onSelectService: PropTypes.func,
  onToggle: PropTypes.func,
  addLine: PropTypes.func,
  onChangeFormLine: PropTypes.func,
  onHandleInitNewLines: PropTypes.func,
  onChangeProvisioningId: PropTypes.func,
  onRemoveLine: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onChangeModifyService: PropTypes.func,
  subscriptionMessage: PropTypes.string,
  id: PropTypes.string,
  groupServices: PropTypes.objectOf(PropTypes.any),
  isOpen: PropTypes.bool,
  isBundle: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isSupportPOName: PropTypes.bool,
  modeDeletePriceOffer: PropTypes.number,
  modeAddBundle: PropTypes.number,
  modeAddPackage: PropTypes.number,
  modeAddPriceOffer: PropTypes.number,
  quoteDetails: PropTypes.objectOf(PropTypes.any),
  modeOverrideQuoteLines: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({});
const newOrderModifyInfo = connect(mapStateToProps, {
  selectPackageId,
  selectBundleId,
  selectPriceOfferId,
  getBundleByBundleId,
})(OrderModifyInfo);

export default withTranslation('common')(newOrderModifyInfo);
