import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'scheduleDaily',
    'returnBase64PDF',
    'quantity',
    'unitPrice',
    'grossAmount',
    'discountAmount',
    'netAmount',
    'substituteInvoice',
  ]);
  const saveManualInvoice = `saveManualInvoice(input: ${dataModify})`;
  return gql`
  mutation {
    ${saveManualInvoice} {
      accountId
    }
  }
`;
};
