import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../PricingHub/SearchComponent';

class PNNListSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.idOperador',
      name: 'idOperador',
      type: 'number',
    },
    {
      label: 'label.ido',
      name: 'ido',
      type: 'number',
    },
    {
      label: 'label.ida',
      name: 'ida',
      type: 'number',
    },
    {
      label: 'label.estatus',
      name: 'estatus',
    },
    {
      label: 'label.nir',
      name: 'nir',
    },
    {
      label: 'label.serie',
      name: 'serie',
    },
  ];

  btnSpan = 'col-md-6';
}

PNNListSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

PNNListSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(PNNListSearchForm);
