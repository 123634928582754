import React from 'react';
import PropTypes from 'prop-types';
import { sortBy, cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormWithTableItem } from '../../../../components/common';
import { OneOffPaymentApplyForm } from '../../../../components/PaymentHub';
import {
  applyPayment,
  getBatchPaymentFileConfig,
  getCrossCurrencyPaymentDetails,
  allocatePayment,
} from '../../actions';
import ModalSelectItemId from './ModalSelectItemId';
import RouteNames from '../../../App/RouteNames';
import InvoicePayment from './InvoicePayment';
import PageTitle from '../../../../components/PageTitle';
import CreditCardPayment from './CreditCardPayment';
// import { getAccountId } from '../../../../localStorages';
import {
  makeGetPermissionsPaymentManagement,
  makeGetCurrencyConfig,
  makeGetCcpPropertiesConfig,
} from '../../../App/selectors';
import { getAccountInfo, getCurrencyConfigApp, getCcpPropertiesConfig, getObjectFileById } from '../../../App/actions';
import { checkPermissionProcessPayment } from '../../CheckPermission';

class OneOffPayment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: 'MANUAL_PAYMENT',
      isOpenModal: false,
      data: { accountId: null },
      currencyOptions: [],
      customerInfo: {},
    };
  }

  componentDidMount() {
    const { history, getCurrencyConfigApp, currencyConfig } = this.props;
    const { currencyOptions } = this.state;
    if (localStorage.getItem('isCreditCard'))
      this.setState({ paymentType: 'CREDIT_CARD' }, () => {
        localStorage.removeItem('isCreditCard');
      });
    // if (!getAccountId()) {
    //   toast('You need to select account first', { type: toast.TYPE.WARNING });
    //   history.push(RouteNames.paymentSearchAccount.path);
    // }
    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            normalLabel: val.name,
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        normalLabel: val.name,
        label: `${val.name} (${val.currencyId})`,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }
  }

  onHandleSelectPaymentType = value => {
    this.setState({
      paymentType: value,
    });
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  onChangePaymentMethod = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onChangeAccountId = (accountId, cb) => {
    const { data } = this.state;
    data.accountId = accountId;
    this.setState({ data: { ...data } }, () => this.doGetAccountInfo(cb));
  };

  onSelectItemId = (accountId, row) => {
    const { data } = this.state;
    data.accountId = accountId;
    data.currency = row?.currency || null;
    this.setState({ data: { ...data }, isOpenModal: false }, () => this.doGetAccountInfo());
  };

  doGetAccountInfo = cb => {
    const { data } = this.state;
    const { getAccountInfo } = this.props;
    const newData = cloneDeep(data);
    if (data.accountId) {
      getAccountInfo(data.accountId, ({ success, data }) => {
        if (success) newData.currency = data?.currency || null;
        this.setState({ customerInfo: success ? data : {}, data: { ...newData } }, () => {
          if (cb) cb(data?.currency || null);
        });
      });
    }
  };

  onRemoveItem = ({ index }) => {
    const { listItems } = this.state;
    try {
      const newData = cloneDeep(listItems);
      newData.splice(index, 1);
      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      paymentType,
      isOpenModal,
      ItemId,
      data: { accountId, currency },
      currencyOptions,
      customerInfo,
    } = this.state;
    const {
      applyPayment,
      permissionsPayment,
      t,
      getBatchPaymentFileConfig,
      getCcpPropertiesConfig,
      getCrossCurrencyPaymentDetails,
      getObjectFileById,
      history,
      allocatePayment,
      ccpPropertyList,
    } = this.props;

    let modeProcessPayment = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeProcessPayment = checkPermissionProcessPayment({ listPermission });
    }
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('common:label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentOperations'), url: RouteNames.paymentActivity.path },
            { name: t('paymentPage:sidebar.manualPayment') },
          ]}
        />
        <br />
        <FormWithTableItem
          title={t('paymentPage:sidebar.manualPayment')}
          accountNum={accountId}
          commercialName={customerInfo?.commercialName || ''}
          isCommercialName={customerInfo?.customerSegment !== 'B2C'}
          currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        >
          <OneOffPaymentApplyForm
            onChangePaymentMethod={this.onChangePaymentMethod}
            paymentType={paymentType}
            onToggleModal={this.onToggleModal}
            accountId={accountId}
          />
          {paymentType === 'MANUAL_PAYMENT' ? (
            <InvoicePayment
              accountId={accountId}
              applyPayment={applyPayment}
              modeProcessPayment={modeProcessPayment}
              onChangeAccountId={this.onChangeAccountId}
              getBatchPaymentFileConfig={getBatchPaymentFileConfig}
              getCcpPropertiesConfig={getCcpPropertiesConfig}
              getCrossCurrencyPaymentDetails={getCrossCurrencyPaymentDetails}
              getObjectFileById={getObjectFileById}
              history={history}
              allocatePayment={allocatePayment}
              ccpPropertyList={ccpPropertyList}
              currency={currency}
            />
          ) : (
            <CreditCardPayment
              accountId={accountId}
              applyPayment={applyPayment}
              modeProcessPayment={modeProcessPayment}
              onChangeCreditCardFromWiget={this.onChangeCreditCardFromWiget}
              onChangeAccountId={this.onChangeAccountId}
              getCcpPropertiesConfig={getCcpPropertiesConfig}
              currency={currency}
              getCrossCurrencyPaymentDetails={getCrossCurrencyPaymentDetails}
              ccpPropertyList={ccpPropertyList}
            />
          )}
        </FormWithTableItem>
        <ModalSelectItemId
          isOpen={isOpenModal}
          selectedId={ItemId}
          onSelect={this.onSelectItemId}
          onCancel={this.onToggleModal}
          toggleModal={this.onToggleModal}
          selectedParentId={accountId || null}
        />
      </div>
    );
  }
}

OneOffPayment.propTypes = {
  applyPayment: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

OneOffPayment.defaultProps = {
  history: {},
  permissionsPayment: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation(['common'])(
  connect(mapStateToProps, {
    applyPayment,
    getBatchPaymentFileConfig,
    getAccountInfo,
    getCurrencyConfigApp,
    getCcpPropertiesConfig,
    getCrossCurrencyPaymentDetails,
    getObjectFileById,
    allocatePayment,
  })(OneOffPayment)
);
