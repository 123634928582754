import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import arrayMove from 'array-move';
import {
  DataTable,
  GenericInput,
  TitleFrom,
  TablePagination,
  ModalWithItem,
  DataTablDragDrop,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import {
  searchConfigPaymentAllocation,
  modifyConfigPaymentAllocation,
  createConfigPaymentAllocation,
  getConfigPymtAllocSeqById,
} from '../actions';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import { getTaxTypeConfigSelect } from '../../App/actions';
import {
  validate,
  getPageTotalCount,
  getLastIndex,
  supportRemoveIndexWithSize,
  compareArrayIndexValueChange,
} from '../../../utils/utils';
import {
  checkPermissionGetConfigPaymentAllocation,
  checkPermissionModifyConfigPaymentAllocation,
  checkPermissionCreateConfigPaymentAllocation,
} from '../CheckPermission';

const configPaymentAllocationSort = {
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
};

const AllocationRules = ({
  searchConfigPaymentAllocation,
  modifyConfigPaymentAllocation,
  createConfigPaymentAllocation,
  getConfigPymtAllocSeqById,
  getTaxTypeConfigSelect,
  permissionsPayment,
}) => {
  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [wasValidated, setValidate] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({ status: 'ACTIVE' });
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [taxTypesOptions, setTaxTypesOptions] = useState([]);
  const [defaultData, setDefaultData] = useState(null);

  const { t } = useTranslation('common');

  let modeGetConfigPaymentAllocation = 0;
  let modeModifyConfigPaymentAllocation = 0;
  let modeCreateConfigPaymentAllocation = 0;

  if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
    const listPermissionsPayment = permissionsPayment.paymentModulePermissions;
    modeGetConfigPaymentAllocation = checkPermissionGetConfigPaymentAllocation({
      listPermission: listPermissionsPayment,
    });
    modeModifyConfigPaymentAllocation = checkPermissionModifyConfigPaymentAllocation({
      listPermission: listPermissionsPayment,
    });
    modeCreateConfigPaymentAllocation = checkPermissionCreateConfigPaymentAllocation({
      listPermission: listPermissionsPayment,
    });
  }

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(itemSelect);
    newData[name] = value;
    setItemSelect(newData);
  };

  const addNew = () => {
    setItemSelect({
      isNew: true,
      status: 'ACTIVE',
      paymentAllocationSequences: [
        {
          index: 1,
          taxType: null,
          // percent: null,
          invoiceSummaryType: null,
          isNew: true,
        },
      ],
    });
    setIsOpenModal(true);
  };

  const onRemoveItem = ({ index, item }) => {
    try {
      const newData = cloneDeep(itemSelect);
      if (item.isNew) {
        newData.paymentAllocationSequences.splice(index, 1);
        return setItemSelect(newData);
      }
      newData.paymentAllocationSequences[index] = { index: newData.paymentAllocationSequences[index].index };
      return setItemSelect(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = () => {
    try {
      const newData = cloneDeep(itemSelect);
      if (!newData.paymentAllocationSequences) newData.paymentAllocationSequences = [];
      const payload = {
        index: getLastIndex({ data: newData.paymentAllocationSequences || [] }),
        taxType: null,
        // percent: null,
        invoiceSummaryType: null,
        isNew: true,
      };
      newData.paymentAllocationSequences = [...newData.paymentAllocationSequences, payload];
      setItemSelect(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const doSearchConfigPaymentAllocation = useCallback(() => {
    setLoading(true);
    searchConfigPaymentAllocation(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? configPaymentAllocationSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        setData(success ? data || [] : []);
      }
    );
  }, [filter, page, searchConfigPaymentAllocation, size, sorted]);

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setPage(0);
    setSize(size);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    const payload = cloneDeep(itemSelect);
    let paymentAllocationSequences = null;
    if (payload.paymentAllocationSequences && payload.paymentAllocationSequences.length) {
      paymentAllocationSequences = payload.paymentAllocationSequences;
    }
    if (payload && payload.id && !payload.isNew) {
      return modifyConfigPaymentAllocation(
        {
          id: payload.id,
          name: payload.name,
          status: payload.status,
          paymentAllocationSequences: paymentAllocationSequences
            ? compareArrayIndexValueChange({
                defaultData,
                newData: supportRemoveIndexWithSize({ data: paymentAllocationSequences }),
              })
            : null,
        },
        res => {
          if (res.success) {
            const newData = cloneDeep(data);
            const index = newData.findIndex(v => v.id === res.data.id);
            newData[index] = res.data;
            setData(newData);
            setIsOpenModal(false);
          }
        }
      );
    }
    return createConfigPaymentAllocation(
      {
        paymentAllocationSequences: paymentAllocationSequences
          ? paymentAllocationSequences.map(val => {
              const { index, isNew, ...rest } = val;
              return rest;
            })
          : null,
        id: payload.id || null,
        name: payload.name || null,
        status: payload.status,
      },
      res => {
        if (res.success) {
          // setData([res.data, ...data]);
          doSearchConfigPaymentAllocation();
          setIsOpenModal(false);
        }
      }
    );
  };

  const onToggleModal = ({ item }) => {
    if (item && item.id) doGetConfigPymtAllocSeqById(item);
    setDefaultData(null);
    setIsOpenModal(!isOpenModal);
  };

  const doGetConfigPymtAllocSeqById = item => {
    getConfigPymtAllocSeqById(item.id, ({ success, data }) => {
      setItemSelect({ ...item, paymentAllocationSequences: success ? data || [] : [] });
      setDefaultData(data || null);
    });
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    try {
      const newData = cloneDeep(itemSelect);
      const tempOldIdx = newData.paymentAllocationSequences[oldIndex].index;
      newData.paymentAllocationSequences[oldIndex].index = newData.paymentAllocationSequences[newIndex].index;
      newData.paymentAllocationSequences[newIndex].index = tempOldIdx;
      const orderedList = arrayMove(newData.paymentAllocationSequences, oldIndex, newIndex);
      newData.paymentAllocationSequences = orderedList;
      setItemSelect(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeTable = ({ name, value, index }) => {
    const newData = cloneDeep(itemSelect);
    newData.paymentAllocationSequences[index][name] = value || null;
    setItemSelect(newData);
  };

  useEffect(() => {
    return doSearchConfigPaymentAllocation();
  }, [doSearchConfigPaymentAllocation]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  useEffect(() => {
    getTaxTypeConfigSelect('', ({ success, data }) => {
      if (success && data && data.taxTypes) {
        const taxTypesList = data.taxTypes.map(val => ({
          label: val.code,
          value: val.code,
        }));
        setTaxTypesOptions(taxTypesList);
      }
    });
  }, [getTaxTypeConfigSelect]);

  if (!modeGetConfigPaymentAllocation) return null;

  const tableAllocationRulesColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) =>
        modeModifyConfigPaymentAllocation ? (
          <button className="text-success no-border" type="button" onClick={() => onToggleModal({ item })}>
            {item.id}
          </button>
        ) : (
          <span>{item.id}</span>
        ),
    },
    {
      name: 'name',
      label: 'label.name',
      sortable: true,
    },
    {
      name: 'status',
      label: 'label.status',
      sortable: true,
    },
  ];

  const tableAllocationRulesDetailsColumns = [
    {
      name: 'invoiceSummaryType',
      label: 'label.invoiceSummaryType',
      style: { width: '220px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.invoiceSummaryType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="invoiceSummaryType"
          type="select"
          tOptions="selections:invoiceSummaryType"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'taxType',
      label: 'label.taxType',
      style: { width: '200px' },
      render: (colName, item, index) => {
        return (
          <GenericInput
            value={item.taxType}
            disabled={!item.invoiceSummaryType || item.invoiceSummaryType !== 'TAX'}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeTable({ name, value, index })}
            name="taxType"
            type="select"
            options={taxTypesOptions || []}
            menuPortalTarget
          />
        );
      },
    },
    // {
    //   name: 'percent',
    //   label: 'label.percent',
    //   required: true,
    //   render: (colName, item, index) => (
    //     <GenericInput
    //       value={item.percent}
    //       wrapperClass="col-md-12"
    //       onChange={({ name, value }) => onChangeTable({ name, value, index })}
    //       name="percent"
    //       type="number"
    //       required
    //     />
    //   ),
    // },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button type="button" className="no-border" onClick={() => onRemoveItem({ index, item })}>
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const listDetailFields = [
    { name: 'id', label: 'label.id', readOnly: itemSelect && !!itemSelect.id && !itemSelect.isNew },
    { name: 'name', label: 'label.name', required: true },
    { name: 'status', label: 'label.status', type: 'select', tOptions: 'selections:genericStatus', required: true },
  ];

  return (
    <div className="col-md-12 p-0 mb-30">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('paymentPage:sidebar.paymentAdmin'), url: RouteNames.paymentConfiguration.path },
          { name: t('paymentPage:sidebar.allocationRules') },
        ]}
      />
      <br />
      <div className="card card-statistics h-100 mr-3">
        <TitleFrom title={t('label.paymentAllocationRules')} />
        <br />
        <div className="col-md-12 row p-2 mb-2">
          {modeCreateConfigPaymentAllocation === 2 && (
            <button type="button" onClick={addNew} className="button button-border black x-small">
              +
              {t('label.addNew')}
            </button>
          )}
          <GenericInput
            type="checkbox"
            label="label.displayActiveOnly"
            className="col-md-3 mt-2"
            name="displayActiveOnly"
            // checked={filter && filter.status === 'ACTIVE'}
            value={filter && filter.status === 'ACTIVE'}
            onChange={({ value }) => (value ? setFilter({ status: 'ACTIVE' }) : setFilter({}))}
          />
        </div>
        <div className="group-collapsible">
          <DataTable
            columns={tableAllocationRulesColumns}
            isLoading={isLoading}
            data={data || []}
            onSort={onSortColumn}
            sorted={sorted}
          />
        </div>
      </div>
      <div className="mb-30 mt-2">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalWithItem
        isOpen={isOpenModal}
        onToggle={onToggleModal}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        modalTitle={t('label.paymentAllocationRulesDetails')}
      >
        <form
          onSubmit={modeModifyConfigPaymentAllocation === 2 ? onSubmit : () => {}}
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          ref={formRef}
          noValidate
        >
          <div className="col-md-12 row">
            {listDetailFields.map(val => (
              <GenericInput
                key={val.name}
                {...val}
                value={itemSelect && (itemSelect[val.name] || itemSelect[val.name] === 0) ? itemSelect[val.name] : null}
                onChange={onChange}
              />
            ))}
          </div>
          <div className="col-md-12 mt-3">
            <DataTablDragDrop
              columns={tableAllocationRulesDetailsColumns}
              data={itemSelect && itemSelect.paymentAllocationSequences ? itemSelect.paymentAllocationSequences : []}
              isSupportRemoveIndex
              onSortEnd={e => onSortEnd({ ...e })}
              isSupportIndexStyle
              styleDragItem={{ width: 50 }}
            />
          </div>
          <button type="button" onClick={addNewItem} className="button button-border black x-small">
            +
            {t('label.addNew')}
          </button>

          <div className="col-md-12 row justify-content-end">
            <button type="button" onClick={onToggleModal} className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
            {modeModifyConfigPaymentAllocation === 2 && (
              <button
                className="button x-small mr-2 float-right"
                type="submit"
                disabled={modeModifyConfigPaymentAllocation === 1 && itemSelect && !itemSelect.isNew}
              >
                {t('label.save')}
              </button>
            )}
          </div>
        </form>
      </ModalWithItem>
    </div>
  );
};

AllocationRules.propTypes = {
  searchConfigPaymentAllocation: PropTypes.func.isRequired,
  modifyConfigPaymentAllocation: PropTypes.func.isRequired,
  createConfigPaymentAllocation: PropTypes.func.isRequired,
  getConfigPymtAllocSeqById: PropTypes.func.isRequired,
  getTaxTypeConfigSelect: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

AllocationRules.defaultProps = {
  permissionsPayment: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

export default connect(mapStateToProps, {
  searchConfigPaymentAllocation,
  modifyConfigPaymentAllocation,
  createConfigPaymentAllocation,
  getConfigPymtAllocSeqById,
  getTaxTypeConfigSelect,
})(AllocationRules);
