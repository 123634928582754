import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ ...filter }) => {
  const filterStr = filter ? convertFilterRequestUrl(filter, ['gatewayType']) : '';
  const getActivityEntry = `getActivityEntry(input: ${filterStr})`;
  return gql`
    query {
      ${getActivityEntry} {
        id
        accountId
        userId
        entityType
        entityId
        action
        source
        request
        response
        beforeData {
          activityAccountInfo {
            id
            customerSegment
            type
            subType
            salesChannel
            marketSegment
            sellingCompany
            lineOfBusiness
            legalEntity
            currency
            status
            reason
            effectiveDate
            userId
            parentId
            salesAgentId
            clientId
            userId
            organization
            region
            employeeId
            commercialName
            taxId
            foreignTaxId
            collectionAgent
          }
          activityContacts {
            id
            roles
            salutation
            firstName
            middleName
            lastName
            position
            organization
            email
            createdDate
            identity
            identityDocument
            phones {
              type
              number
            }
          }
          activityAddresses {
            id
            roles
            street
            extraLine
            landmark
            city
            state
            country
            postalCode
            code
            createdDate
            floor
            municipality
            locality
            latitude
            longitude
            neighbourhood
            address1
            address2
            address3
            unit
            district
          }
          activityBillingProfiles {
            id
            parentId
            billingDom
            billingSegment
            billingFrequency
            invoiceType
            invoiceDelivery
            paymentProfileId
            status
            reason
            nextAccountingDate
            nextBillDate
            lastAccountingDate
            lastBillDate
            nextBillUnitId
            lastBillUnitId
            accountId
          }
          activityPaymentProfiles {
            id
            paymentTerm
            paymentMethod
            creditCards {
              cardToken
              cardExpiry
              last4CC
            }
            electronicChecks {
              routingNumber
              accountNumber
              accountName
              accountType
              bank
              contactNumber
            }
          }
          
          activityCustomAttributes {
            name
            value
          }
        }
        afterData {
          activityAccountInfo {
            id
            customerSegment
            type
            subType
            salesChannel
            marketSegment
            sellingCompany
            lineOfBusiness
            legalEntity
            currency
            status
            reason
            effectiveDate
            userId
            parentId
            salesAgentId
            clientId
            userId
            organization
            region
            employeeId
            commercialName
            taxId
            foreignTaxId
            collectionAgent
          }
          activityContacts {
            id
            roles
            salutation
            firstName
            middleName
            lastName
            position
            organization
            email
            createdDate
            identity
            identityDocument
            phones {
              type
              number
            }
          }
          activityAddresses {
            id
            roles
            street
            extraLine
            landmark
            city
            state
            country
            postalCode
            code
            createdDate
            floor
            municipality
            locality
            latitude
            longitude
            neighbourhood
            address1
            address2
            address3
            unit
            district
          }
          activityBillingProfiles {
            id
            parentId
            billingDom
            billingSegment
            billingFrequency
            invoiceType
            invoiceDelivery
            paymentProfileId
            status
            reason
            nextAccountingDate
            nextBillDate
            lastAccountingDate
            lastBillDate
            nextBillUnitId
            lastBillUnitId
            accountId
          }
          activityPaymentProfiles {
            id
            paymentTerm
            paymentMethod
            creditCards {
              cardToken
              cardExpiry
              last4CC
            }
            electronicChecks {
              routingNumber
              accountNumber
              accountName
              accountType
              bank
              contactNumber
            }
          }
          
          activityCustomAttributes {
            name
            value
          }
        }
        activityDate
      }
    }
  `;
};
