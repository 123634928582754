export default {
  sidebar: {
    paymentConfig: 'Configuración',
    paymentAdmin: 'Administración de Pagos',
    configuration: 'Configuración',
    paymentTypes: 'Tipos de Pagos',
    paymentTerms: 'Términos de Pago',
    method: 'Método',
    addPaymentMethod: 'Añadir método de pago',
    apply: 'Aplicar',
    invoiceDateOffset: 'Compensación en Fecha de Factura',
    workingDay: 'Días laborables',
    days: 'Días',
    addPaymentTerm: 'Agregar Plazo de Pago',
    // paymentAgent: 'Agente de Pago',
    paymentOperations: 'Operaciones de pagos',
    searchAccount: 'Buscar Cuenta',
    accounts: 'Cuentas',
    oneOffPayment: 'Pago único',
    viewPaymentActivity: 'Ver actividad de pagos',
    paymentAllocation: 'Asignación de pago',
    paymentSuspense: 'Suspensión de Pago',
    customerRefunds: 'Devoluciones a Clientes',
    manualChargebacks: 'Reembolsos Manuales',
    batchPayments: 'Pagos',
    batchRefunds: 'Reembolsos',
    batchReversals: 'Reversiones',
    paymentHistory: 'Historial de Pagos',
    batchProcessing: 'Procesamiento por Lotes',
    paymentSurcharge: 'Pago de Recargo',
    upload: 'Carga de Archivo de Pago Batch',
    processPayment: 'Proceso de Pago',
    batchPaymentFile: 'Archivo Batch',
    itemMap: 'Mapa de Artículos',
    manualPayment: 'Pago Manual',
    allocationRules: 'Reglas de Asignación',
  },
};
