import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import quoteNS from './constants/quoteNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, quoteNS);
  const getQuote = `getQuote(input: ${dataCreate})`;
  const qqlStr = gql`
  mutation {
    ${getQuote} {
      id
      type
      status
      reason
      accountId
      userId
      clientId
      isPartialFulfillmentAllowed
      effectiveDate
      submittedDate
      initialTerm
      initialTermUnit
      renewalTerm
      renewalTermUnit
      trialTerm
      trialTermUnit
      trialType
      salesAgentId
      oldQuoteDetails {
        netQuote
        grossQuote
        quoteDiscount
        serviceLevelQuoteDetails {
          serviceType
          netQuote
          grossQuote
          quoteDiscount
          action
        }
        priceOfferQuoteDetails {
          priceOfferId
          priceOfferName
          serviceType
          transactionType
          netQuote
          grossQuote
          quoteDiscount
          bundleId
          bundleName
          packageId
          packageName
          proratedCharge
          recurringUnit
          recurringFrequency
          scale
          action
        }
        discountOfferQuoteDetails {
          discountOfferId
          discountOfferName
          serviceType
          transactionType
          amount
          percent
          bundleId
          bundleName
          packageId
          packageName
          proratedDiscount
          recurringUnit
          recurringFrequency
          scale
          action
        }
      }
      newQuoteDetails {
        netQuote
        grossQuote
        quoteDiscount
        serviceLevelQuoteDetails {
          serviceType
          netQuote
          grossQuote
          quoteDiscount
          action
        }
        priceOfferQuoteDetails {
          priceOfferId
          priceOfferName
          serviceType
          transactionType
          netQuote
          grossQuote
          quoteDiscount
          bundleId
          bundleName
          packageId
          packageName
          proratedCharge
          recurringUnit
          recurringFrequency
          scale
          action
        }
        discountOfferQuoteDetails {
          discountOfferId
          discountOfferName
          serviceType
          transactionType
          amount
          percent
          bundleId
          bundleName
          packageId
          packageName
          proratedDiscount
          recurringUnit
          recurringFrequency
          scale
          action
        }
      }
    }
  }
`;
  return qqlStr;
};
