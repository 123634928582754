import React, { Component } from 'react';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import { DataTable, GenericInput } from '../common';

class TableFormLine extends Component {
  state = {};

  render() {
    const { lines, isReadOnly, isUpDownGrade } = this.props;
    const tableColumns = [
      {
        name: 'priceOfferName',
        label: 'common:label.priceOfferName',
      },
      // {
      //   name: 'priceOfferId',
      //   label: 'common:label.priceOfferId',
      // },
      {
        name: 'discountOfferName',
        label: 'common:label.discountOfferName',
      },
      // {
      //   name: 'discountOfferId',
      //   label: 'common:label.discountOfferId',
      // },
      {
        name: 'action',
        label: 'common:label.action',
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
        style: { minWidth: '180px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="quantity"
              wrapperClass="col-md-12"
              value={item.quantity}
              type="number"
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              readOnly={isReadOnly}
              name="quantity"
            />
          );
        },
      },
      {
        name: 'priceOverride',
        label: 'common:label.priceDiscountOverride',
        style: { minWidth: '200px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="priceOverride"
              wrapperClass="col-md-12"
              value={item.priceOverride}
              type="number"
              readOnly={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              name="priceOverride"
            />
          );
        },
      },
      {
        name: 'discountPercent',
        label: 'common:label.discountPercent',
        style: { minWidth: '200px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="discountPercent"
              value={item.discountPercent}
              wrapperClass="col-md-12"
              type="number"
              readOnly={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              name="discountPercent"
            />
          );
        },
      },
      {
        name: 'priceOffset',
        label: 'common:label.priceOffset',
        style: { minWidth: '200px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="priceOffset"
              wrapperClass="col-md-12"
              value={item.priceOffset}
              type="number"
              readOnly={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              name="priceOffset"
            />
          );
        },
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        style: { minWidth: '200px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="startDate"
              wrapperClass="col-md-12 inner-popover"
              type="date"
              dateFormat="yyyy-MM-dd"
              value={item.startDate}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              readOnly={isReadOnly}
              name="startDate"
            />
          );
        },
      },
      {
        name: 'relativeStartUnit',
        label: 'common:label.relativeStartUnit',
        style: { minWidth: '180px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="relativeStartUnit"
              name="relativeStartUnit"
              wrapperClass="col-md-12"
              menuPortalTarget
              type="select"
              value={item.relativeStartUnit || null}
              isClearable
              tOptions="selections:timeUnit"
              readOnly={isReadOnly}
              disabled={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
            />
          );
        },
      },
      {
        name: 'relativeStart',
        label: 'common:label.relativeStart',
        style: { minWidth: '180px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="relativeStart"
              value={item.relativeStart}
              wrapperClass="col-md-12"
              type="number"
              readOnly={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              name="relativeStart"
            />
          );
        },
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        style: { minWidth: '200px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="endDate"
              wrapperClass="col-md-12 inner-popover"
              type="date"
              dateFormat="yyyy-MM-dd"
              value={item.endDate}
              readOnly={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              name="endDate"
            />
          );
        },
      },
      {
        name: 'relativeEndUnit',
        label: 'common:label.relativeEndUnit',
        style: { minWidth: '180px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              key="relativeEndUnit"
              name="relativeEndUnit"
              value={item.relativeEndUnit || null}
              wrapperClass="col-md-12"
              isClearable
              menuPortalTarget
              tOptions="selections:timeUnit"
              type="select"
              readOnly={isReadOnly}
              disabled={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
            />
          );
        },
      },
      {
        name: 'relativeEnd',
        label: 'common:label.relativeEnd',
        style: { minWidth: '180px' },
        render: (colName, item) => {
          const { onChangeFormLine } = this.props;
          return (
            <GenericInput
              wrapperClass="col-md-12"
              key="relativeEnd"
              value={item.relativeEnd}
              type="number"
              readOnly={isReadOnly}
              onChange={({ name, value }) => {
                onChangeFormLine({ fieldName: name, value, lineIndex: item.index });
              }}
              name="relativeEnd"
            />
          );
        },
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusOrderLines')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (colName, item) => {
          const { onRemoveLine } = this.props;
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                disabled={isReadOnly || isUpDownGrade}
                onClick={() => onRemoveLine({ itemLine: item })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const columnTableNew = tableColumns;
    // if (positionTrash || isRemoveTrashFromLine || !isRenderTrashBinLineTable) {
    //   columnTableNew = tableColumns.filter(item => item.name !== 'remove');
    // }
    // columnTableNew = tableColumns.filter(item => item.name !== 'remove');
    const tableConfig = {
      columns: columnTableNew,
      data: lines && lines.length ? lines.filter(item => size(item) > 2) : [],
      sorted: {},
      onSort: this.onSortColumn,
    };
    return (
      <div>
        <DataTable {...tableConfig} onClickRow={this.onClickRow} tableClass="table-focus line-table" />
      </div>
    );
  }
}

TableFormLine.propTypes = {
  service: PropTypes.arrayOf(PropTypes.any),
  lines: PropTypes.arrayOf(PropTypes.any),
  onChangeFormLine: PropTypes.func,
  onRemoveLine: PropTypes.func,
  isRemoveTrashFromLine: PropTypes.bool,
  isRenderTrashBinLineTable: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isUpDownGrade: PropTypes.bool,
};

TableFormLine.defaultProps = {
  onChangeFormLine: () => {},
  onRemoveLine: () => {},
  service: [],
  lines: [],
  isRemoveTrashFromLine: false,
  isRenderTrashBinLineTable: false,
  isReadOnly: false,
  isUpDownGrade: false,
};

export default TableFormLine;
