import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['accountType', 'serviceType', 'billType'])}`
    : '';
  const getUnbilledTransactionReport = `getUnbilledTransactionReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getUnbilledTransactionReport} {
        transactionId
        recordId
        transactionDate
        netTotal
        serviceType
        provisioningId
        accountId
        netAmount
        accountType
        suspenseReason
        failureReason
        company
      }
    }
  `;
};
