import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, title, columnsTable }) => [
  {
    xSteps: columnsTable.length > 4 ? columnsTable.length / 2 - 2 : 1,
    columns: [],
    data: [[{ value: title || 'LIST JOURNALS', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: columnsTable.map(val => columns[val.name] || columns.common),
    // [
    //   columns.common,
    //   columns.startDate,
    //   columns.endDate,
    //   columns.common,
    //   columns.common,
    //   columns.glAccount,
    //   columns.common,
    //   columns.common,
    //   columns.common,
    //   columns.accountId,
    //   columns.accountType,
    //   columns.common,
    //   columns.amount,
    //   columns.common,
    //   columns.revenueType,
    //   columns.common,
    //   columns.common,
    //   columns.common,
    //   columns.status,
    //   columns.common,
    //   columns.transactionId,
    //   columns.common,
    //   columns.itemId,
    //   columns.billUnitId,
    //   columns.invoiceId,
    //   columns.invoiceDate,
    //   columns.common,
    //   columns.country,
    //   columns.productLine,
    //   columns.common, // reportObjectType
    //   columns.common, // notesId
    //   columns.common, // paymentId
    //   columns.common, // subAccount
    //   columns.common, // glOffset
    //   columns.salesChannel,
    //   columns.marketSegment,
    //   columns.accountSubType,
    //   columns.common, // exchangeRate
    //   columns.common,
    //   columns.sellingCompany,
    //   columns.common,
    //   columns.common,
    //   columns.productCompany,
    // ],
    // data: [
    //   [
    //     {
    //       value: t('label.id'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.startDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.endDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountingPeriod'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.batchId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.glAccount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.costCenter'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.interCompany'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.currency'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.itemName'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.amount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.amountRecognized'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.revenueType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.revenueRecognitionType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.revenueActivityType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.transactionType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.status'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billed'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.transactionId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.arOpsId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.itemId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billUnitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.invoiceId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.invoiceDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.country'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.productLine'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.objectType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.notesId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.subAccount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.glOffset'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.salesChannel'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.marketSegment'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountSubType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.exchangeRate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.itemSplitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.sellingCompany'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.legalEntity'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.setOfBooks'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.productCompany'),
    //       style: styleHeader,
    //     },
    //   ],
    // ],
    data: [
      columnsTable.map(val => ({
        value: t(val.label),
        style: styleHeader,
      })),
    ],
  },
];

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, columnsTable }));
  const monthYearT = t('selections:monthOfYear')();
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'accountingPeriod') {
          if (item.accountingPeriod) {
            let newItemSelect = item.accountingPeriod;
            const itemTranslation = monthYearT.find(it => newItemSelect.indexOf(it.value) > -1);
            if (itemTranslation) {
              newItemSelect = `${itemTranslation.label}${newItemSelect.substring(3, newItemSelect.length)}`;
            }
            newItem.push({ value: newItemSelect || '' });
          } else {
            newItem.push({ value: item.accountingPeriod ? item.accountingPeriod : '' });
          }
        }
        if (val.name !== 'accountingPeriod') {
          if (item[val.name] && typeof item[val.name] === 'number') {
            newItem.push({ value: formatMoneyValueToExport(item[val.name]) });
          } else if (val.isRenderT) {
            newItem.push({
              value: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ value: item[val.name] ? 'TRUE' : 'FALSE' });
          } else {
            newItem.push({ value: item[val.name] ? item[val.name] : '' });
          }
        }
      });
      // newItem.push({ value: item.id ? item.id : '' });
      // newItem.push({ value: item.revenueStartDate ? item.revenueStartDate : '' });
      // newItem.push({ value: item.revenueEndDate ? item.revenueEndDate : '' });
      // if (item.accountingPeriod) {
      //   let newItemSelect = item.accountingPeriod;
      //   const itemTranslation = monthYearT.find(it => newItemSelect.indexOf(it.value) > -1);
      //   if (itemTranslation) {
      //     newItemSelect = `${itemTranslation.label}${newItemSelect.substring(3, newItemSelect.length)}`;
      //   }
      //   newItem.push({ value: newItemSelect || '' });
      // } else {
      //   newItem.push({ value: item.accountingPeriod ? item.accountingPeriod : '' });
      // }
      // newItem.push({ value: item.extractBatchId ? item.extractBatchId : '' });
      // newItem.push({ value: item.glAccount ? item.glAccount : '' });
      // newItem.push({ value: item.costCenter ? item.costCenter : '' });
      // newItem.push({ value: item.interCompany ? item.interCompany : '' });
      // newItem.push({ value: item.currency ? item.currency : '' });
      // newItem.push({ value: item.accountId ? item.accountId : '' });
      // newItem.push({ value: getLabelOptions({ item, t, fieldOption: 'accountType', fieldName: 'accountType' }) });
      // newItem.push({ value: item.itemName ? item.itemName : '' });
      // newItem.push({ value: formatMoneyValueToExport(item.amount) });
      // newItem.push({ value: formatMoneyValueToExport(item.amountRecognized) });
      // newItem.push({ value: getLabelOptions({ item, t, fieldOption: 'revenueType', fieldName: 'revenueType' }) });
      // newItem.push({
      //   value: getLabelOptions({ item, t, fieldOption: 'revenueRecognitionType', fieldName: 'revenueRecognitionType' }),
      // });
      // newItem.push({
      //   value: getLabelOptions({ item, t, fieldOption: 'revenueActivityType', fieldName: 'revenueActivityType' }),
      // });
      // newItem.push({
      //   value: getLabelOptions({ item, t, fieldOption: 'transactionType', fieldName: 'transactionType' }),
      // });
      // newItem.push({ value: getLabelOptions({ item, t, fieldOption: 'revenueJournalStatus', fieldName: 'status' }) });
      // newItem.push({ value: item.billed ? 'TRUE' : 'FALSE' });
      // newItem.push({ value: item.transactionId ? item.transactionId : '' });
      // newItem.push({ value: item.arOpsId ? item.arOpsId : '' });
      // newItem.push({ value: item.itemId ? item.itemId : '' });
      // newItem.push({ value: item.billUnitId ? item.billUnitId : '' });
      // newItem.push({ value: item.invoiceId ? item.invoiceId : '' });
      // newItem.push({ value: item.invoiceDate ? item.invoiceDate : '' });
      // newItem.push({ value: item.country ? item.country : '' });
      // newItem.push({ value: item.productLine ? item.productLine : '' });
      // newItem.push({
      //   value: getLabelOptions({ item, t, fieldOption: 'revenueReportObjectType', fieldName: 'reportObjectType' }),
      // });
      // newItem.push({ value: item.notesId ? item.notesId : '' });
      // newItem.push({ value: item.paymentId ? item.paymentId : '' });
      // newItem.push({ value: item.glSubAccount ? item.glSubAccount : '' });
      // newItem.push({ value: item.glOffset ? item.glOffset : '' });
      // newItem.push({ value: getLabelOptions({ item, t, fieldOption: 'salesChannel', fieldName: 'salesChannel' }) });
      // newItem.push({ value: getLabelOptions({ item, t, fieldOption: 'marketSegment', fieldName: 'marketSegment' }) });
      // newItem.push({ value: getLabelOptions({ item, t, fieldOption: 'accountSubType', fieldName: 'accountSubType' }) });
      // newItem.push({ value: item.exchangeRate ? item.exchangeRate : '' });
      // newItem.push({ value: item.itemSplitId ? item.itemSplitId : '' });
      // newItem.push({ value: item.sellingCompany ? item.sellingCompany : '' });
      // newItem.push({ value: item.legalEntity ? item.legalEntity : '' });
      // newItem.push({ value: item.setOfBooks ? item.setOfBooks : '' });
      // newItem.push({ value: item.productCompany ? item.productCompany : '' });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
