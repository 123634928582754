import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import styleMainHeader from './styleMainHeader';
import styleMainHeaderLastColumn from './styleMainHeaderLastColumn';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, monthOne, monthTwo, monthThree, quarter, title }) => [
  {
    xSteps: 5,
    columns: [],
    data: [[{ value: title || 'TIME AND EXPENSE', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.id, // employeeId
      columns.common, // employeeName
      columns.common, // jobTitle
      columns.common, // status
      columns.common, // clientId
      columns.common, // clientName
      columns.common, // clientJobTitle
      columns.common, // monthOneGrossSal
      columns.common, // monthTwoGrossSal
      columns.common, // monthThreeGrossSal
      columns.common, // totalGrossSal
      columns.common, // monthOneActualCost
      columns.common, // monthTwoActualCost
      columns.common, // monthThreeActualacost
      columns.common, // totalActualCost
      columns.common, // monthOneRevenueUsd
      columns.common, // monthTwoRevenueUsd
      columns.common, // monthThreeRevenueUsd
      columns.common, // totalRevenueUsd
      columns.common, // monthOneRevenueMxn
      columns.common, // monthTwoRevenueMxn
      columns.common, // monthThreeRevenueMxn
      columns.common, // totalRevenueMxn
      columns.common, // monthOneActualIncomeMxn
      columns.common, // monthTwoActualIncomeMxn
      columns.common, // monthThreeActualIncomeMxn
      columns.common, // totalActualIncome
      columns.common, // monthOneSalaryToBilling
      columns.common, // monthTwoSalaryToBilling
      columns.common, // monthThreeSalaryToBilling
      columns.common, // averageSalaryToBilling
    ],
    data: [
      // Main column
      [
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.grossSalaryMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actualCostMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueUSD'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.revenueMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.actIncomeMxn'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: t('label.salaryToBilling'),
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeader,
        },
        {
          value: '',
          style: styleMainHeaderLastColumn,
        },
      ],
      // Main column
      [
        {
          value: t('label.employeeId'),
          style: styleHeader,
        },
        {
          value: t('label.employeeName'),
          style: styleHeader,
        },
        {
          value: t('label.jobTitle'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
        {
          value: t('label.clientId'),
          style: styleHeader,
        },
        {
          value: t('label.clientName'),
          style: styleHeader,
        },
        {
          value: t('label.clientJobTitle'),
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarter || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarter || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarter || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarter || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarter || '',
          style: styleHeader,
        },
        {
          value: monthOne || '',
          style: styleHeader,
        },
        {
          value: monthTwo || '',
          style: styleHeader,
        },
        {
          value: monthThree || '',
          style: styleHeader,
        },
        {
          value: quarter || '',
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, monthOne, monthTwo, monthThree, quarter, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, monthOne, monthTwo, monthThree, quarter, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.employeeId ? item.employeeId : '' });
      newItem.push({ value: item.employeeName ? item.employeeName : '' });
      newItem.push({ value: item.jobTitle ? item.jobTitle : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'status' }) });
      newItem.push({ value: item.clientId ? item.clientId : '' });
      newItem.push({ value: item.clientName ? item.clientName : '' });
      newItem.push({ value: item.clientJobTitle ? item.clientJobTitle : '' });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalGrossSal) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeActualacost) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalActualCost) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalRevenueUsd) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalRevenueMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeActualIncomeMxn) });
      newItem.push({ value: formatMoneyValueToExport(item?.totalActualIncome) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthOneSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthTwoSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.monthThreeSalaryToBilling) });
      newItem.push({ value: formatMoneyValueToExport(item?.averageSalaryToBilling) });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
