// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import disputeNS from './constants/disputeNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(data, disputeNS);
  const processDispute = `processDispute(input: ${dataCreate})`;
  return gql`
  mutation {
    ${processDispute} {
      id
    }
  }
`;
};
