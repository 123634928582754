import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CollectionHistorySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      wrapperClass: 'col-md-3 collection-account-date',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusCollections',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

CollectionHistorySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CollectionHistorySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CollectionHistorySearchForm);
