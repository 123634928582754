import gql from 'graphql-tag';

export default () => {
  const getConfigPaymentSurcharge = 'getConfigPaymentSurcharge( dummy: "")';
  return gql`
    query {
      ${getConfigPaymentSurcharge} {
        id
        name
        description
        configPaymentSurchargeList {
          index
          noOfDays
          accountType
          paymentMethod
          surcharge
        }
      }
    }
  `;
};
