import gql from 'graphql-tag';

export default id => {
  const getChildTasksByParentId = `getChildTasksByParentId(id: "${id}")`;
  return gql`
    query {
      ${getChildTasksByParentId} {
        id
        accountId
        userId
        parentTaskId
        createdDate
        taskType
        taskCategory
        taskDescription
        ticketId
        status
        notes
        userFirstname
        userLastName
        organization
        userEmail
        accountType
      }
    }
  `;
};
