import React from 'react';
import PropTypes from 'prop-types';
// import SearchAccounts from './SearchAccounts';
import OneOffPayment from './OneOffPayment';
import ViewPaymentActivity from './ViewPaymentActivity';
import ManualAllocation from './PaymentAllocation';
import PaymentSuspense from './PaymentSuspense';

class PaymentAgent extends React.PureComponent {
  componentWillUnmount() {
    localStorage.removeItem('accountId');
    localStorage.removeItem('currency');
  }

  renderContent() {
    const {
      match: {
        params: { childRoute },
      },
      location,
      history,
    } = this.props;
    switch (childRoute) {
      // case 'search-accounts':
      //   return <SearchAccounts />;
      case 'manual-payment':
        return <OneOffPayment history={history} accountId={location.state ? location.state.accountId : ''} />;
      case 'view-payment-activity':
        return <ViewPaymentActivity history={history} location={location} />;
      case 'payment-allocation':
        return (
          <ManualAllocation
            accountId={location.state ? location.state.accountId : ''}
            paymentId={location.state ? location.state.selectedPayment.id : ''}
            history={history}
          />
        );
      case 'payment-suspense':
        return <PaymentSuspense history={history} location={location} />;
      default:
        // return <SearchAccounts />;
        return null;
    }
  }

  render() {
    return this.renderContent();
  }
}

PaymentAgent.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

PaymentAgent.defaultProps = {
  match: {},
  location: {},
  history: {},
};

export default PaymentAgent;
