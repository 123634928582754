import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'index',
    'quantity',
    'unitPrice',
    'discountAmount',
    'amount',
    'substituteInvoice',
  ]);
  const reprocessInvoice = `reprocessInvoice(input: ${dataCreate})`;
  return gql`
  mutation {
    ${reprocessInvoice} {
      id
    }
  }
`;
};
