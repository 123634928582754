import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { getPageTotalCount } from 'utils/utils';
import { withTranslation } from 'react-i18next';
import { DataTable, TablePagination, Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import dependencySort from '../../../constantsApp/bundleManager/dependencySort';
import { makeGetListDependencies, makeErrorMessage } from '../selectors';
import { searchDependencies, setParamsDependencies } from '../actions';
import { DependencySearchForm } from '../../../components/PricingHub';
import { checkPermissionCreateDependency, checkPermissionModifyDependency } from '../CheckPermission';
import { makeGetPermissionsBundleManagement } from '../../App/selectors';

class DependencyItem extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state && props.listDependencies) {
      return {
        totalCount: getPageTotalCount({ ...state, items: props.listDependencies }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchDependencies();
      });
    } else {
      this.doSearchDependencies();
    }
  }

  onClickRow = e => {
    e.preventDefault();
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchDependencies());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchDependencies());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchDependencies());
  };

  doSearchDependencies = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchDependencies } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && dependencySort[sorted.sortCol]
        ? dependencySort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    searchDependencies(params, () => {
      this.setState({ isSearching: false, filter, sort });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchDependencies());
  };

  render() {
    const { listDependencies, permissionsBundleManagement, t, location } = this.props;
    const { sorted, isSearching, page, size, totalCount, filter } = this.state;
    let modeCreateDependency = 0;
    let modeModifyDependency = 0;
    const tableColumns = [
      {
        name: 'id',
        label: 'common:label.id',
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.dependencyItemEdit.path, item.id), state: { filter } }}
          >
            {item.id}
          </Link>
        ),
      },
      {
        name: 'bundleId',
        label: 'common:label.bundleName',
        render: (colName, item) => {
          const valueViewer = item.bundleId ? item.name : '';
          return <span>{valueViewer}</span>;
        },
      },
      {
        name: 'packageId',
        label: 'common:label.packageName',
        render: (colName, item) => {
          const valueViewer = item.packageId ? item.name : '';
          return <span>{valueViewer}</span>;
        },
      },
      {
        name: 'type',
        label: 'common:label.type',
        isRenderT: true,
        sortable: true,
        render: (colName, item, t) => {
          const dependencyValue =
            item.dependencyLists && item.dependencyLists.length > 0 && item.dependencyLists.map(d => d.type)[0];
          // console.log('dependencyValue', item.id, dependencyValue)
          const slt = t ? t('selections:dependencyType')().find(val => val.value === dependencyValue) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
      },
      {
        name: 'action',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.dependencyItemEdit.path, item.id), state: { filter } }}
          >
            {t ? t('common:label.view') : ''}
          </Link>
        ),
      },
    ];
    const newTableColumns = cloneDeep(tableColumns);

    if (permissionsBundleManagement && permissionsBundleManagement.bundleModulePermissions) {
      const listPermissionBundle = permissionsBundleManagement.bundleModulePermissions;
      modeCreateDependency = checkPermissionCreateDependency({ listPermission: listPermissionBundle });
      modeModifyDependency = checkPermissionModifyDependency({ listPermission: listPermissionBundle });
    }

    if (modeModifyDependency === 0) {
      newTableColumns[0] = {
        name: 'id',
        label: 'Id',
      };
      newTableColumns.splice(newTableColumns.length - 1, 1);
    }

    const tableConfig = {
      columns: newTableColumns,
      sorted: { ...sorted },
      data: listDependencies,
      onSort: this.onSortColumn,
      onClickRow: this.onClickRow,
      isLoading: isSearching,
    };
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={
                  modeCreateDependency !== 2
                    ? RouteNames.bundleItem.path
                    : {
                        pathname: RouteNames.dependencyItemAdd.path,
                        state: { filter },
                      }
                }
                className="button x-small"
              >
                {modeCreateDependency !== 2 ? t('label.back') : t('label.createNewDependency')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.dependency'), url: RouteNames.dependencyItem.path },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <DependencySearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={isSearching}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} isSubmitting={isSearching} />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

DependencyItem.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  searchDependencies: PropTypes.func.isRequired,
  listDependencies: PropTypes.arrayOf(PropTypes.any),
};

DependencyItem.defaultProps = {
  permissionsBundleManagement: {},
  listDependencies: [],
};

const mapStateToProps = createStructuredSelector({
  listDependencies: makeGetListDependencies() || [],
  errorMessage: makeErrorMessage() || '',
  permissionsBundleManagement: makeGetPermissionsBundleManagement() || {},
});

const newDependencyItem = connect(mapStateToProps, { searchDependencies, setParamsDependencies })(DependencyItem);

export default withTranslation('common')(newDependencyItem);
