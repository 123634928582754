import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { EmployeeCostingPNLQuaterSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
} from '../../../../components/common';
import { sortemployeeCostingPNLQuater } from '../../constants';
import { employeeCostingQuarterlyPNLReport, employeeAllCostingQuarterlyPNLReport } from '../../actions';
import { makeGetEmployeeCostingQuarterlyPNL, makeGetListAllEmployeeCostingQuarterlyPNL } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFileRoteColumns,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelemployeeCostingPNLQuater';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfemployeeCostingPNLQuater';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

class EmployeeCostingPNLQuater extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listEmployeeCostingPNLQuater: [],
    dataExpense: null,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listEmployeeCostingPNLQuater: props.listEmployeeCostingPNLQuater,
      totalCount: getPageTotalCount({ ...state, items: props.listEmployeeCostingPNLQuater }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetEmployeeCostingPnl },
      getExpensesConfig,
    } = this.props;
    if (modeGetEmployeeCostingPnl) {
      this.doGetListEmployeeCostingPNLQuater();
      getExpensesConfig('', ({ success, data }) => {
        if (success) this.setState({ dataExpense: data });
      });
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListEmployeeCostingPNLQuater();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListEmployeeCostingPNLQuater());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListEmployeeCostingPNLQuater());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListEmployeeCostingPNLQuater();
    });
  };

  doGetListEmployeeCostingPNLQuater = () => {
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (!newFilter.quarter) newFilter.quarter = '1';
    if (!newFilter.year) newFilter.year = moment().format('YYYY');
    const { employeeCostingQuarterlyPNLReport } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort:
        !isEmpty(sorted) && sortemployeeCostingPNLQuater[sorted.sortCol]
          ? sortemployeeCostingPNLQuater[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    employeeCostingQuarterlyPNLReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistEmployeeCostingPNLQuater = cb => {
    const { filter, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (!newFilter.quarter) newFilter.quarter = '1';
    if (!newFilter.year) newFilter.year = moment().format('YYYY');
    const { employeeAllCostingQuarterlyPNLReport } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortemployeeCostingPNLQuater[sorted.sortCol][sorted.sortDir] : null,
    };
    employeeAllCostingQuarterlyPNLReport(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = ({ columns }) => {
    this.doGetAlllistEmployeeCostingPNLQuater(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllEmployeeCostingPNLQuater, t } = this.props;
        convertJson2Pdf({
          data: listAllEmployeeCostingPNLQuater,
          t,
          title: t('common:label.PLQuaterlyReport').toLocaleUpperCase(),
          fileName: `${t('common:label.PLQuaterlyReport')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: columns,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = (dataExportCSV, { subject, emailBody, columns }) => {
    this.doGetAlllistEmployeeCostingPNLQuater(() => {
      const { listAllEmployeeCostingPNLQuater, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'EMPLOYEE_COSTING_PNL_REPORT',
          subject: subject || `Embrix Report ${t('common:label.PLQuaterlyReport')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFileRoteColumns({
            fileName: `${t('common:label.PLQuaterlyReport')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllEmployeeCostingPNLQuater,
            t,
            columns,
            listOptionFields: { status: 'status' },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllEmployeeCostingPNLQuater,
          t,
          title: t('common:label.PLQuaterlyReport').toLocaleUpperCase(),
          fileName: `${t('common:label.PLQuaterlyReport')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: columns,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('common:label.PLQuaterlyReport')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listEmployeeCostingPNLQuater,
      emails,
      dataExpense,
    } = this.state;
    const {
      listAllEmployeeCostingPNLQuater,
      t,
      permissions: { modeGetEmployeeCostingPnl, modeDownloadReport, modeGetMailReport },
      isBilling,
    } = this.props;

    if (!modeGetEmployeeCostingPnl) return '';

    const fixedExpensesColumns = [];
    const variableExpensesColumns = [];
    if (dataExpense && dataExpense.fixedExpenses) {
      dataExpense.fixedExpenses.forEach((val, index) => {
        fixedExpensesColumns.push({
          label: val.type,
          name: `fixed${index + 1}`,
          isSupportRedFront: true,
        });
      });
    }
    if (dataExpense && dataExpense.variableExpenses) {
      dataExpense.variableExpenses.forEach((val, index) => {
        variableExpensesColumns.push({
          label: val.type,
          name: `variable${index + 1}`,
          isSupportRedFront: true,
        });
      });
    }

    const tabelColumnExportemployeeCostingPNLQuater = [
      {
        name: 'employeeId',
        label: 'label.employeeId',
      },
      {
        name: 'employeeName',
        label: 'label.employeeName',
        sortable: true,
      },
      {
        name: 'jobTitle',
        label: 'label.jobTitle',
        sortable: true,
      },
      {
        name: 'totalExpectedIncome',
        label: 'label.expectedBilledRevenue',
        isSupportRedFront: true,
      },
      {
        name: 'totalActualIncome',
        label: 'label.revenueReceived',
        isSupportRedFront: true,
      },
      {
        name: 'totalGrosssal',
        label: 'label.totalPayroll',
        isSupportRedFront: true,
      },
      {
        name: 'totalPayrollTaxes',
        label: 'label.payrollTaxes',
        isSupportRedFront: true,
      },
      {
        name: 'totalYearEndBonus',
        label: 'label.legalGrossYearEndBonus',
        isSupportRedFront: true,
      },
      {
        name: 'totalActualCost',
        label: 'label.actualCost',
        isSupportRedFront: true,
      },
      {
        name: 'totalGrossProfitAverage',
        label: 'label.grossProfitAverage',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: 'totalGrossMarginAverage',
        label: 'label.grossMarginAverage',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: 'fixedExpenses',
        label: 'label.fixedExpenses',
        isSupportBold: true,
      },
      ...fixedExpensesColumns,
      {
        name: 'totalFixedExpenses',
        label: 'label.totalFixedExpenses',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'variableExpenses',
        label: 'label.variableExpenses',
        isSupportBold: true,
      },
      ...variableExpensesColumns,
      {
        name: 'totalVariableExpenses',
        label: 'label.totalVariableExpenses',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'overheadExpenses',
        label: 'label.overheadExpenses',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'netIncomeMxn',
        label: 'label.netIncomeMXN',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'totalRevenuesUsd',
        label: 'label.totalRevenueUSD',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'grossProfitsUsd',
        label: 'label.grossProfitUSD',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'totalExpensesUsd',
        label: 'label.totalExpensesUSD',
        isSupportRedFront: true,
        isSupportBold: true,
      },
      {
        name: '',
        label: '',
      },
      {
        name: 'netIncomesUsd',
        label: 'label.netIncomeUSD',
        isSupportRedFront: true,
        isSupportBold: true,
      },
    ];

    const dataExportCSV = { isNoEnum: true };
    tabelColumnExportemployeeCostingPNLQuater.forEach(dt => {
      dataExportCSV[dt.name] = t(`${dt.label}`);
    });

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={
            isBilling
              ? [
                  { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
                  { name: t('common:label.PLQuaterlyReport') },
                ]
              : [{ name: t('label.report'), url: RouteNames.report.path }, { name: t('common:label.PLQuaterlyReport') }]
          }
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('common:label.PLQuaterlyReport')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <EmployeeCostingPNLQuaterSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <div className="mt-3 row float-right mb-4 pt-3 pb-3">
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport
                        onExport={() => this.onExport({ columns: tabelColumnExportemployeeCostingPNLQuater })}
                      />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.timeAndExpense').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllEmployeeCostingPNLQuater,
                          t,
                          title: t('common:label.PLQuaterlyReport').toLocaleUpperCase(),
                          columnsTable: tabelColumnExportemployeeCostingPNLQuater,
                        })}
                        fileName={`${t('common:label.PLQuaterlyReport')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={e =>
                        this.sendEmail(dataExportCSV, { ...e, columns: tabelColumnExportemployeeCostingPNLQuater })
                      }
                      data={listEmployeeCostingPNLQuater}
                    />
                  )}
                </div>
                <DataTable
                  columns={tabelColumnExportemployeeCostingPNLQuater}
                  data={
                    listEmployeeCostingPNLQuater && listEmployeeCostingPNLQuater.length
                      ? listEmployeeCostingPNLQuater
                      : []
                  }
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isRoteTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

EmployeeCostingPNLQuater.propTypes = {
  employeeCostingQuarterlyPNLReport: PropTypes.func,
  employeeAllCostingQuarterlyPNLReport: PropTypes.func,
  listAllEmployeeCostingPNLQuater: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
};

EmployeeCostingPNLQuater.defaultProps = {
  employeeCostingQuarterlyPNLReport: () => {},
  employeeAllCostingQuarterlyPNLReport: () => {},
  listAllEmployeeCostingPNLQuater: [],
};

const mapStateToProps = createStructuredSelector({
  listEmployeeCostingPNLQuater: makeGetEmployeeCostingQuarterlyPNL() || [],
  listAllEmployeeCostingPNLQuater: makeGetListAllEmployeeCostingQuarterlyPNL() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    employeeCostingQuarterlyPNLReport,
    employeeAllCostingQuarterlyPNLReport,
    uploadMultiPartFiles,
  })(EmployeeCostingPNLQuater)
);
