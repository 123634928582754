import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { cloneDeep, isEmpty, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount, validate, compareModifyObj } from '../../../../utils/utils';
import PageTitle from '../../../../components/PageTitle';
import { DataTable, TablePagination, TitleFrom, ModalWithItem, GenericInput } from '../../../../components/common';
import { PACProductCodesSearchForm } from '../../../../components/BaseConfigurationsHub';
import RouteNames from '../../../App/RouteNames';
import { searchPacProductCodes, createRegulatoryProductCodes, modifyRegulatoryProductCodes } from '../../actions';
import { tableColumnsPACProductCodes, pacProductCodesSort } from '../../constants';
import {
  checkPermissionCreateRegulatoryProductCode,
  checkPermissionModifyRegulatoryProductCode,
  checkPermissionViewRegulatoryProductCode,
} from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

const listFields = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'description',
    label: 'label.description',
    required: true,
    type: 'textarea',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    type: 'date',
  },
  {
    name: 'includeIva',
    label: 'label.includeIva',
  },
  {
    name: 'includeIeps',
    label: 'label.includeIeps',
  },
  {
    name: 'complement',
    label: 'label.complement',
  },
];

class PACProductCodes extends PureComponent {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    isExpandChild: true,
    pacProductCodesList: [],
    itemDetailsSelect: null,
    wasValidated: false,
    dataList: [],
    isCreate: false,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      pacProductCodesList: props.pacProductCodesList,
      totalCount: getPageTotalCount({ ...state, items: state.dataList }),
    };
  }

  componentDidMount() {
    this.doSearchPacProductCodes();
  }

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchPacProductCodes());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchPacProductCodes());
  };

  onHandleChange = ({ name, value }) => {
    const { filter = {} } = this.state;
    filter[name] = value;
    this.setState({ ...filter });
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchPacProductCodes());
  };

  onToggleModalDetails = () => {
    this.setState({ itemDetailsSelect: null, isCreate: false });
  };

  onAddAndEditItem = ({ item, isCreate }) => {
    this.setState({ itemDetailsSelect: item || null, isCreate: isCreate || false });
  };

  doSearchPacProductCodes = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchPacProductCodes } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? pacProductCodesSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true, dataList: [] });
    searchPacProductCodes(payload, ({ data }) => {
      this.setState({ isSearching: false, dataList: data || [], isCreate: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchPacProductCodes());
  };

  onChange = ({ name, value }) => {
    const { itemDetailsSelect } = this.state;
    const newData = cloneDeep(itemDetailsSelect);
    newData[name] = value;
    this.setState({ itemDetailsSelect: newData });
  };

  onHandleSave = evt => {
    if (evt) evt.preventDefault();
    const { t, createRegulatoryProductCodes, modifyRegulatoryProductCodes } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });
    const { itemDetailsSelect, isCreate, dataList } = this.state;
    if (!isCreate) {
      const newData = cloneDeep(dataList);

      const indexChange = newData.findIndex(v => v.id === itemDetailsSelect.id);
      const modifyPayload = compareModifyObj({ defaultObject: newData[indexChange], objChange: itemDetailsSelect });
      if (size(modifyPayload) < 1) return this.setState({ itemDetailsSelect: null });
      return modifyRegulatoryProductCodes({ id: itemDetailsSelect.id, ...modifyPayload }, ({ success, data }) => {
        if (success) {
          newData[indexChange] = data;
          this.setState({ dataList: [...newData], itemDetailsSelect: null });
        }
      });
    }
    return createRegulatoryProductCodes(itemDetailsSelect, ({ success, data }) => {
      if (success) {
        const newData = cloneDeep(dataList);
        newData.push(data);
        this.setState({ dataList: newData, itemDetailsSelect: null });
      }
    });
  };

  onRemoveItem = ({ item }) => {
    const { modifyRegulatoryProductCodes } = this.props;
    modifyRegulatoryProductCodes(
      { id: item.id },
      () => {
        this.doSearchPacProductCodes();
      },
      true
    );
  };

  render() {
    const { t, permissionsPricing } = this.props;
    const {
      sorted,
      isSearching,
      page,
      size,
      totalCount,
      dataList,
      itemDetailsSelect,
      wasValidated,
      isCreate,
    } = this.state;

    let modeCreateRegulatoryProductCode = 0;
    let modeModifyRegulatoryProductCode = 0;
    let modeViewRegulatoryProductCode = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeCreateRegulatoryProductCode = checkPermissionCreateRegulatoryProductCode({ listPermission });
      modeModifyRegulatoryProductCode = checkPermissionModifyRegulatoryProductCode({ listPermission });
      modeViewRegulatoryProductCode = checkPermissionViewRegulatoryProductCode({ listPermission });
    }

    if (!modeViewRegulatoryProductCode) return null;
    const isHasPermission =
      (isCreate && modeModifyRegulatoryProductCode === 2) || (!isCreate && modeCreateRegulatoryProductCode === 2);

    const newTableColumns = [
      {
        name: 'id',
        label: 'label.id',
        sortable: true,
        render: (colName, item) => (
          <button type="button" className="text-success no-border" onClick={() => this.onAddAndEditItem({ item })}>
            {item.id}
          </button>
        ),
      },
      ...tableColumnsPACProductCodes,
      {
        name: 'view',
        label: 'label.view',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.onAddAndEditItem({ item })}
          >
            {t('label.view')}
          </button>
        ),
      },
      {
        name: 'remove',
        label: 'label.delete',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                disabled={modeModifyRegulatoryProductCode !== 2}
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveItem({ item })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={modeCreateRegulatoryProductCode === 2 ? null : t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.regulatoryProductCodes') },
          ]}
          isNoDefaultButton={modeCreateRegulatoryProductCode === 2}
        >
          {modeCreateRegulatoryProductCode === 2 && (
            <button
              type="button"
              className="button x-small"
              onClick={() => this.onAddAndEditItem({ item: {}, isCreate: true })}
            >
              {`+ ${t('label.add')}`}
            </button>
          )}
        </PageTitle>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <TitleFrom title={t('baseConfigurationsPage:sidebar.regulatoryProductCodes')} />
            <div className="card-body">
              <div className="repeater-file">
                <div className="ml-2 mr-2">
                  <PACProductCodesSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                columns={newTableColumns}
                sorted={sorted}
                data={dataList && dataList.length ? dataList : []}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          isOpen={!!itemDetailsSelect}
          onToggle={this.onToggleModalDetails}
          modalTitle={
            isCreate ? t('label.newRegulatoryProductCodeConfig') : t('label.modifyRegulatoryProductCodeConfig')
          }
          wrapperClass="modal-custom modal-70"
        >
          <form
            onSubmit={this.onHandleSave}
            className={`col-md-12 ${wasValidated ? 'was-validated' : ''} `}
            ref={this.formRef}
            noValidate
          >
            <div className="col-md-12 row">
              {listFields.map(item => (
                <GenericInput
                  {...item}
                  value={itemDetailsSelect ? itemDetailsSelect[item.name] : null}
                  onChange={this.onChange}
                  readOnly={!isCreate && item.name === 'id'}
                />
              ))}
            </div>
            <div className="col-md-12 mt-3 mb-3">
              {isHasPermission && (
                <button type="submit" className="button button-border x-small float-right">
                  {t(isCreate ? 'label.create' : 'label.modify')}
                </button>
              )}
              <button
                type="button"
                onClick={this.onToggleModalDetails}
                className="button button-border black x-small float-right mr-1"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </ModalWithItem>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({ permissionsPricing: makeGetPermissionsPricingManagement() || {} });

PACProductCodes.propTypes = {
  searchPacProductCodes: PropTypes.func,
  createRegulatoryProductCodes: PropTypes.func.isRequired,
  modifyRegulatoryProductCodes: PropTypes.func.isRequired,
};

PACProductCodes.defaultProps = {
  searchPacProductCodes: () => {},
};

const newCustomerIndex = connect(mapStateToProps, {
  searchPacProductCodes,
  createRegulatoryProductCodes,
  modifyRegulatoryProductCodes,
})(PACProductCodes);

export default withTranslation('common')(newCustomerIndex);
