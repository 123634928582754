import gql from 'graphql-tag';

export default id => gql`
  query {
    getRevenueMilestoneById(input: { id: "${id}" }) {
      id
      name
      description
      productFamily
      productLine
      productType
      productSubType
      currency
      country
      status
      type
      unit
      offsetval
      milestones {
        index
        percent
        manual
        frequency
      }
    }
  }
`;
