import { createSelector } from 'reselect';

const selectInvoicesReducer = state => state.get('invoiceReducer');

const makeErrorMessage = () => createSelector(selectInvoicesReducer, invoices => invoices.get('errorMessage'));

const makeGetInvoiceTemplate = () =>
  createSelector(selectInvoicesReducer, invoices => invoices.get('invoiceTemplates'));

export { makeErrorMessage, makeGetInvoiceTemplate };
