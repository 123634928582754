import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles, saveOutputTemplateByType, getOutputTemplateByType } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { EmployeeCostingYearlyPLSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTableGroupHeader,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
} from '../../../../components/common';
import { sortemployeeCostingYearlyPL, tabelColumnExportemployeeCostingYearlyPLExportCSV } from '../../constants';
import { employeeCostingYearlyPNLReport, employeeAllCostingYearlyPNLReport } from '../../actions';
import { makeGetEmployeeCostingYearlyPL, makeGetListAllEmployeeCostingYearlyPL } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelemployeeCostingYearlyPL';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfemployeeCostingYearlyPL';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let monthOne = '';
let monthTwo = '';
let monthThree = '';
let monthFour = '';
let monthFive = '';
let monthSix = '';
let monthSeven = '';
let monthEight = '';
let monthNine = '';
let monthTen = '';
let monthEleven = '';
let monthTwelve = '';
let quarter = '';
const quarterOne = 'Q1';
const quarterTwo = 'Q2';
const quarterThree = 'Q3';
const quarterFour = 'Q4';
class EmployeeCostingYearlyPL extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listEmployeeCostingYearlyPL: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listEmployeeCostingYearlyPL: props.listEmployeeCostingYearlyPL,
      totalCount: getPageTotalCount({ ...state, items: props.listEmployeeCostingYearlyPL }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetEmployeeCostingPnl },
    } = this.props;
    if (modeGetEmployeeCostingPnl) {
      this.doGetListEmployeeCostingYearlyPL();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListEmployeeCostingYearlyPL();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListEmployeeCostingYearlyPL());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListEmployeeCostingYearlyPL());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListEmployeeCostingYearlyPL();
    });
  };

  doGetListEmployeeCostingYearlyPL = () => {
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (!newFilter.quarter) newFilter.quarter = '1';
    if (!newFilter.year) newFilter.year = moment().format('YYYY');
    const { employeeCostingYearlyPNLReport } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort:
        !isEmpty(sorted) && sortemployeeCostingYearlyPL[sorted.sortCol]
          ? sortemployeeCostingYearlyPL[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    employeeCostingYearlyPNLReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistEmployeeCostingYearlyPL = cb => {
    const { filter, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (!newFilter.quarter) newFilter.quarter = '1';
    if (!newFilter.year) newFilter.year = moment().format('YYYY');
    const { employeeAllCostingYearlyPNLReport } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortemployeeCostingYearlyPL[sorted.sortCol][sorted.sortDir] : null,
    };
    employeeAllCostingYearlyPNLReport(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistEmployeeCostingYearlyPL(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllEmployeeCostingYearlyPL, t } = this.props;
        convertJson2Pdf({
          data: listAllEmployeeCostingYearlyPL,
          t,
          title: t('reportPage:sidebar.timeAndExpenseYearlyPL').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.timeAndExpenseYearlyPL')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          monthOne,
          monthTwo,
          monthThree,
          monthFour,
          monthFive,
          monthSix,
          monthSeven,
          monthEight,
          monthNine,
          monthTen,
          monthEleven,
          monthTwelve,
          quarter,
          quarterOne,
          quarterTwo,
          quarterThree,
          quarterFour,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = (dataExportCSV, { subject, emailBody }) => {
    this.doGetAlllistEmployeeCostingYearlyPL(() => {
      const { listAllEmployeeCostingYearlyPL, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'EMPLOYEE_COSTING_PNL_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.timeAndExpenseYearlyPL')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.timeAndExpenseYearlyPL')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: [dataExportCSV, ...listAllEmployeeCostingYearlyPL],
            t,
            columns: tabelColumnExportemployeeCostingYearlyPLExportCSV,
            listOptionFields: { status: 'status' },
            replaceHeaderList: [
              'monthOneGrossSal',
              'monthThreeGrossSal',
              'totalQuarterOneGrossSal',
              'monthFourGrossSal',
              'monthSixGrossSal',
              'totalQuarterTwoGrossSal',
              'monthSevenGrossSal',
              'monthNineGrossSal',
              'totalQuarterThreeGrossSal',
              'monthTenGrossSal',
              'monthTwelveGrossSal',
              'totalQuarterFourGrossSal',
              'monthOneActualCost',
              'monthThreeActualacost',
              'totalQuarterOneActualCost',
              'monthFourActualCost',
              'monthSixActualacost',
              'totalQuarterTwoActualCost',
              'monthSevenActualCost',
              'monthNineActualacost',
              'totalQuarterThreeActualCost',
              'monthTenActualCost',
              'monthTwelveActualacost',
              'totalQuarterFourActualCost',
              'monthOneRevenueUsd',
              'monthThreeRevenueUsd',
              'totalQuarterOneRevenueUsd',
              'monthFourRevenueUsd',
              'monthSixRevenueUsd',
              'totalQuarterTwoRevenueUsd',
              'monthSevenRevenueUsd',
              'monthNineRevenueUsd',
              'totalQuarterThreeRevenueUsd',
              'monthTenRevenueUsd',
              'monthTwelveRevenueUsd',
              'totalQuarterFourRevenueUsd',
              'monthOneRevenueMxn',
              'monthThreeRevenueMxn',
              'totalQuarterOneRevenueMxn',
              'monthFourRevenueMxn',
              'monthSixRevenueMxn',
              'totalQuarterTwoRevenueMxn',
              'monthSevenRevenueMxn',
              'monthNineRevenueMxn',
              'totalQuarterThreeRevenueMxn',
              'monthTenRevenueMxn',
              'monthTwelveRevenueMxn',
              'totalQuarterFourRevenueMxn',
              'monthOneActualIncomeMxn',
              'monthThreeActualIncomeMxn',
              'totalQuarterOneActualIncome',
              'monthFourActualIncomeMxn',
              'monthSixActualIncomeMxn',
              'totalQuarterTwoActualIncome',
              'monthSevenActualIncomeMxn',
              'monthNineActualIncomeMxn',
              'totalQuarterThreeActualIncome',
              'monthTenActualIncomeMxn',
              'monthTwelveActualIncomeMxn',
              'totalQuarterFourActualIncome',
              'monthOneSalaryToBilling',
              'monthThreeSalaryToBilling',
              'averageQuarterOneSalaryToBilling',
              'monthFourSalaryToBilling',
              'monthSixSalaryToBilling',
              'averageQuarterTwoSalaryToBilling',
              'monthSevenSalaryToBilling',
              'monthNineSalaryToBilling',
              'averageQuarterThreeSalaryToBilling',
              'monthTenSalaryToBilling',
              'monthElevenSalaryToBilling',
              'monthTwelveSalaryToBilling',
              'averageQuarterFourSalaryToBilling',
              'averageSalaryToBilling',
              t('label.billableEmployee'),
              t('label.employeeName'),
              t('label.jobTitle'),
              t('label.status'),
              t('label.clientId'),
              t('label.clientName'),
              t('label.clientJobTitle'),
              t('label.employeeId'),
            ],
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllEmployeeCostingYearlyPL,
          t,
          title: t('reportPage:sidebar.timeAndExpenseYearlyPL').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.timeAndExpenseYearlyPL')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          monthOne,
          monthTwo,
          monthThree,
          monthFour,
          monthFive,
          monthSix,
          monthSeven,
          monthEight,
          monthNine,
          monthTen,
          monthEleven,
          monthTwelve,
          quarter,
          quarterOne,
          quarterTwo,
          quarterThree,
          quarterFour,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.timeAndExpenseYearlyPL')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listEmployeeCostingYearlyPL,
      filter,
      emails,
    } = this.state;
    const {
      listAllEmployeeCostingYearlyPL,
      t,
      permissions: { modeGetEmployeeCostingPnl, modeDownloadReport, modeGetMailReport },
      isBilling,
    } = this.props;

    if (!modeGetEmployeeCostingPnl) return '';

    if (listEmployeeCostingYearlyPL && listEmployeeCostingYearlyPL.length) {
      const monthYearT = t('selections:monthOfYear')();
      monthOne = listEmployeeCostingYearlyPL[0].monthOne || '';
      monthTwo = listEmployeeCostingYearlyPL[0].monthTwo || '';
      monthThree = listEmployeeCostingYearlyPL[0].monthThree || '';

      monthFour = listEmployeeCostingYearlyPL[0].monthFour || '';
      monthFive = listEmployeeCostingYearlyPL[0].monthFive || '';
      monthSix = listEmployeeCostingYearlyPL[0].monthSix || '';

      monthSeven = listEmployeeCostingYearlyPL[0].monthSeven || '';
      monthEight = listEmployeeCostingYearlyPL[0].monthEight || '';
      monthNine = listEmployeeCostingYearlyPL[0].monthNine || '';

      monthTen = listEmployeeCostingYearlyPL[0].monthTen || '';
      monthEleven = listEmployeeCostingYearlyPL[0].monthEleven || '';
      monthTwelve = listEmployeeCostingYearlyPL[0].monthTwelve || '';
      if (monthOne) {
        const itemTranslation = monthYearT.find(it => monthOne.toLocaleLowerCase() === it.value.toLocaleLowerCase());
        monthOne = itemTranslation?.label || '';
      }
      if (monthTwo) {
        const itemTranslation = monthYearT.find(it => monthTwo.toLocaleLowerCase() === it.value.toLocaleLowerCase());
        monthTwo = itemTranslation?.label || '';
      }
      if (monthThree) {
        const itemTranslation = monthYearT.find(it => monthThree.toLocaleLowerCase() === it.value.toLocaleLowerCase());
        monthThree = itemTranslation?.label || '';
      }
    }
    quarter = `Q${filter.quarter ? filter.quarter : 1}`;
    const tabelColumnExportemployeeCostingPNL = [
      {
        name: 'employeeId',
        label: 'label.employeeId',
      },
      {
        name: 'employeeName',
        label: 'label.employeeName',
        sortable: true,
      },
      {
        name: 'jobTitle',
        label: 'label.jobTitle',
        sortable: true,
      },
      {
        name: 'status',
        label: 'label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'clientId',
        label: 'label.clientId',
      },
      {
        name: 'clientName',
        label: 'label.clientName',
      },
      {
        name: 'clientJobTitle',
        label: 'label.clientJobTitle',
      },
      {
        name: 'monthOneGrossSal',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoGrossSal',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeGrossSal',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterOneGrossSal',
        label: quarterOne,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFourGrossSal',
        label: monthFour || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFiveGrossSal',
        label: monthFive || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSixGrossSal',
        label: monthSix || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterTwoGrossSal',
        label: quarterTwo,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSevenGrossSal',
        label: monthSeven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthEightGrossSal',
        label: monthEight || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthNineGrossSal',
        label: monthNine || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterThreeGrossSal',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTenGrossSal',
        label: monthTen || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthElevenGrossSal',
        label: monthEleven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwelveGrossSal',
        label: monthTwelve || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterFourGrossSal',
        label: quarterFour,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneActualCost',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoActualCost',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeActualacost',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterOneActualCost',
        label: quarterOne,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFourActualCost',
        label: monthFour || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFiveActualCost',
        label: monthFive || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSixActualacost',
        label: monthSix || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterTwoActualCost',
        label: quarterTwo,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSevenActualCost',
        label: monthSeven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthEightActualCost',
        label: monthEight || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthNineActualacost',
        label: monthNine || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterThreeActualCost',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTenActualCost',
        label: monthTen || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthElevenActualCost',
        label: monthEleven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwelveActualacost',
        label: monthTwelve || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterFourActualCost',
        label: quarterFour,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneRevenueUsd',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoRevenueUsd',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeRevenueUsd',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterOneRevenueUsd',
        label: quarterOne,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFourRevenueUsd',
        label: monthFour || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFiveRevenueUsd',
        label: monthFive || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSixRevenueUsd',
        label: monthSix || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterTwoRevenueUsd',
        label: quarterTwo,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSevenRevenueUsd',
        label: monthSeven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthEightRevenueUsd',
        label: monthEight || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthNineRevenueUsd',
        label: monthNine || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterThreeRevenueUsd',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTenRevenueUsd',
        label: monthTen || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthElevenRevenueUsd',
        label: monthEleven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwelveRevenueUsd',
        label: monthTwelve || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterFourRevenueUsd',
        label: quarterFour,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneRevenueMxn',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoRevenueMxn',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeRevenueMxn',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterOneRevenueMxn',
        label: quarterOne,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFourRevenueMxn',
        label: monthFour || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFiveRevenueMxn',
        label: monthFive || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSixRevenueMxn',
        label: monthSix || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterTwoRevenueMxn',
        label: quarterTwo,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSevenRevenueMxn',
        label: monthSeven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthEightRevenueMxn',
        label: monthEight || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthNineRevenueMxn',
        label: monthNine || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterThreeRevenueMxn',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTenRevenueMxn',
        label: monthTen || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthElevenRevenueMxn',
        label: monthEleven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwelveRevenueMxn',
        label: monthTwelve || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterFourRevenueMxn',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneActualIncomeMxn',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoActualIncomeMxn',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeActualIncomeMxn',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterOneActualIncome',
        label: quarterOne,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFourActualIncomeMxn',
        label: monthFour || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFiveActualIncomeMxn',
        label: monthFive || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSixActualIncomeMxn',
        label: monthSix || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterTwoActualIncome',
        label: quarterTwo,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSevenActualIncomeMxn',
        label: monthSeven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthEightActualIncomeMxn',
        label: monthEight || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthNineActualIncomeMxn',
        label: monthNine || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterThreeActualIncome',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTenActualIncomeMxn',
        label: monthTen || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthElevenActualIncomeMxn',
        label: monthEleven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwelveActualIncomeMxn',
        label: monthTwelve || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalQuarterFourActualIncome',
        label: quarterFour,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneSalaryToBilling',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoSalaryToBilling',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeSalaryToBilling',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'averageQuarterOneSalaryToBilling',
        label: quarterOne,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFourSalaryToBilling',
        label: monthFour || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthFiveSalaryToBilling',
        label: monthFive || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSixSalaryToBilling',
        label: monthSix || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'averageQuarterTwoSalaryToBilling',
        label: quarterTwo,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthSevenSalaryToBilling',
        label: monthSeven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthEightSalaryToBilling',
        label: monthEight || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthNineSalaryToBilling',
        label: monthNine || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'averageQuarterThreeSalaryToBilling',
        label: quarterThree,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTenSalaryToBilling',
        label: monthTen || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthElevenSalaryToBilling',
        label: monthEleven || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwelveSalaryToBilling',
        label: monthTwelve || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'averageQuarterFourSalaryToBilling',
        label: quarterFour,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
    ];

    const dataExportCSV = { isNoEnum: true };
    tabelColumnExportemployeeCostingPNL.forEach(dt => {
      dataExportCSV[dt.name] = t(`${dt.label}`);
    });

    const subHeader = [
      {
        label: '',
        colSpan: 1,
        name: 'employeeId',
      },
      {
        label: '',
        colSpan: 1,
        name: 'employeeName',
      },
      {
        label: '',
        colSpan: 1,
        name: 'jobTitle',
      },
      {
        label: '',
        colSpan: 1,
        name: 'status',
      },
      {
        label: '',
        colSpan: 1,
        name: 'clientId',
      },
      {
        label: '',
        colSpan: 1,
        name: 'clientName',
      },
      {
        label: '',
        colSpan: 1,
        name: 'clientJobTitle',
      },
      {
        label: 'label.grossSalaryMxn',
        colSpan: 4,
        name: 'label.grossSalaryMxn',
      },
      {
        label: 'label.grossSalaryMxn',
        colSpan: 4,
        name: 'label.grossSalaryMxn',
      },
      {
        label: 'label.grossSalaryMxn',
        colSpan: 4,
        name: 'label.grossSalaryMxn',
      },
      {
        label: 'label.grossSalaryMxn',
        colSpan: 4,
        name: 'label.grossSalaryMxn',
      },
      {
        label: 'label.actualCostMxn',
        colSpan: 4,
        name: 'Actual Cost Mxn (Gross salary* 1.2865)',
      },
      {
        label: 'label.actualCostMxn',
        colSpan: 4,
        name: 'Actual Cost Mxn (Gross salary* 1.2865)',
      },
      {
        label: 'label.actualCostMxn',
        colSpan: 4,
        name: 'Actual Cost Mxn (Gross salary* 1.2865)',
      },
      {
        label: 'label.actualCostMxn',
        colSpan: 4,
        name: 'Actual Cost Mxn (Gross salary* 1.2865)',
      },
      {
        label: 'label.revenueUSD',
        colSpan: 4,
        name: 'Revenue USD',
      },
      {
        label: 'label.revenueUSD',
        colSpan: 4,
        name: 'Revenue USD',
      },
      {
        label: 'label.revenueUSD',
        colSpan: 4,
        name: 'Revenue USD',
      },
      {
        label: 'label.revenueUSD',
        colSpan: 4,
        name: 'Revenue USD',
      },
      {
        label: 'label.revenueMxn',
        colSpan: 4,
        name: 'Revenue Mxn',
      },
      {
        label: 'label.revenueMxn',
        colSpan: 4,
        name: 'Revenue Mxn',
      },
      {
        label: 'label.revenueMxn',
        colSpan: 4,
        name: 'Revenue Mxn',
      },
      {
        label: 'label.revenueMxn',
        colSpan: 4,
        name: 'Revenue Mxn',
      },
      {
        label: 'label.actIncomeMxn',
        colSpan: 4,
        name: 'Act Income Mxn (Rev Mxn - Act Cost Mxn)',
      },
      {
        label: 'label.actIncomeMxn',
        colSpan: 4,
        name: 'Act Income Mxn (Rev Mxn - Act Cost Mxn)',
      },
      {
        label: 'label.actIncomeMxn',
        colSpan: 4,
        name: 'Act Income Mxn (Rev Mxn - Act Cost Mxn)',
      },
      {
        label: 'label.actIncomeMxn',
        colSpan: 4,
        name: 'Act Income Mxn (Rev Mxn - Act Cost Mxn)',
      },
      {
        label: 'label.salaryToBilling',
        colSpan: 4,
        name: 'Salary To Billing',
      },
      {
        label: 'label.salaryToBilling',
        colSpan: 4,
        name: 'Salary To Billing',
      },
      {
        label: 'label.salaryToBilling',
        colSpan: 4,
        name: 'Salary To Billing',
      },
      {
        label: 'label.salaryToBilling',
        colSpan: 4,
        name: 'Salary To Billing',
      },
    ];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={
            isBilling
              ? [
                  { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
                  { name: t('common:label.PLYearlyReport') },
                ]
              : [
                  { name: t('label.report'), url: RouteNames.report.path },
                  { name: t('reportPage:sidebar.timeAndExpenseYearlyPL') },
                ]
          }
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.timeAndExpenseYearlyPL')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <EmployeeCostingYearlyPLSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <div className="mt-3 row float-right mb-4 pt-3 pb-3">
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.timeAndExpenseYearly').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllEmployeeCostingYearlyPL,
                          t,
                          monthOne,
                          monthTwo,
                          monthThree,
                          monthFour,
                          monthFive,
                          monthSix,
                          monthSeven,
                          monthEight,
                          monthNine,
                          monthTen,
                          monthEleven,
                          monthTwelve,
                          quarter,
                          quarterOne,
                          quarterTwo,
                          quarterThree,
                          quarterFour,
                          title: t('reportPage:sidebar.timeAndExpenseYearlyPL').toLocaleUpperCase(),
                        })}
                        fileName={`${t('reportPage:sidebar.timeAndExpenseYearlyPL')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={e => this.sendEmail(dataExportCSV, e)}
                      data={listEmployeeCostingYearlyPL}
                    />
                  )}
                </div>
                <DataTableGroupHeader
                  columns={tabelColumnExportemployeeCostingPNL}
                  data={
                    listEmployeeCostingYearlyPL && listEmployeeCostingYearlyPL.length ? listEmployeeCostingYearlyPL : []
                  }
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  // isFixedHeaderTable
                  subHeader={subHeader}
                  // rowClass="text-center"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

EmployeeCostingYearlyPL.propTypes = {
  getemployeeCostingPNL: PropTypes.func,
  employeeAllCostingYearlyPNLReport: PropTypes.func,
  listAllEmployeeCostingYearlyPL: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
};

EmployeeCostingYearlyPL.defaultProps = {
  getemployeeCostingPNL: () => {},
  employeeAllCostingYearlyPNLReport: () => {},
  listAllEmployeeCostingYearlyPL: [],
};

const mapStateToProps = createStructuredSelector({
  listEmployeeCostingYearlyPL: makeGetEmployeeCostingYearlyPL() || [],
  listAllEmployeeCostingYearlyPL: makeGetListAllEmployeeCostingYearlyPL() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, { employeeCostingYearlyPNLReport, employeeAllCostingYearlyPNLReport, uploadMultiPartFiles })(
    EmployeeCostingYearlyPL
  )
);
