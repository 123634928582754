import gql from 'graphql-tag';

export default ({ id, page, size }) => gql`
  query {
    getOperatorDisputeById(input: { id: "${id}", page: ${page}, size: ${size} }) {
      id
      index
      regId
      nir
      nim
      recordDate
      trafficType
      destinationSeries
      originSeries
      callType
      pdic
      acctid
      numCalls
      numRegCalls
      numSeconds
      billedRate
      numRegSeconds
      recogRate
      tenantNumRegCalls
      tenantNumRegSeconds
      tenantRecogRate
      diffCalls
    }
  }
`;
