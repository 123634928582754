import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  // productFamilyList: [],
  currencyList: [],
  grantsList: [],
  accumulatorList: [],
  reateUnitList: [],
  timeUnitCfg: {},
  listRateUnitMap: [],
  taxCodeConfig: [],
  uom: {},
  countryCodes: [],
  pacProductCodesList: [],
  prepaidSystemOffersList: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    // case types.GET_PRODUCT_FAMILY_CONFIG_SUCCESS:
    //   return state.set('productFamilyList', action.payload.productFamilyList);

    case types.GET_CURRENCY_CONFIG_SUCCESS:
      return state.set('currencyList', action.payload.currencyListConfig);

    case types.GET_GRANTS_CONFIG_SUCCESS:
      return state.set('grantsList', action.payload.grantListConfig);

    case types.GET_ACCUMULATORS_CONFIG_SUCCESS:
      return state.set('accumulatorList', action.payload.accumulatorList);

    case types.GET_RATE_UNIT_SUCCESS:
      return state.set('reateUnitList', action.payload.rateUnits);

    case types.GET_TIME_UNIT_CFG_SUCCESS:
      return state.set('timeUnitCfg', action.payload);

    case types.GET_CUSTOM_RECORD_CFG_SUCCESS:
      return state.set('customRecordList', action.payload.customRecCfg);

    case types.GET_RATE_UNIT_MAP_SUCCESS:
      return state.set('listRateUnitMap', action.payload);

    case types.GET_TAX_CODE_CONFIG_SUCCESS_BASE:
      return state.set('taxCodeConfig', action.payload);

    case types.GET_UOM_SUCCESS:
      return state.set('uom', action.payload);

    case types.SEARCH_COUNTRY_CODES_SUCCESS:
      return state.set('countryCodes', action.payload);

    case types.SEARCH_PAC_PRODUCT_CODE_SUCCESS:
      return state.set('pacProductCodesList', action.payload);

    case types.SEARCH_PREPAID_SYSTEM_OFFERS_SUCCESS:
      return state.set('prepaidSystemOffersList', action.payload);

    default:
      return state;
  }
}
