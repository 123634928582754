import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { accountSort } from '../../../../constantsApp';
import { getPageTotalCount } from '../../../../utils/utils';
import { makeGetListAccounts } from '../../selectors';
import { searchAccountsCollection, getCollectionUnitsById } from '../../actions';
import {
  FormWithTableItem,
  DataTable,
  TablePagination,
  ModalWithItem,
  TitleFrom,
  AccountHeaderFrom,
} from '../../../../components/common';
import { makeGetCurrencyConfig } from '../../../App/selectors';
import { getCurrencyConfigApp, getCcpPropertiesConfig } from '../../../App/actions';
import { AccountCollectionSearchForm } from '../../../../components/CollectionsHub';
import { tableColumnsSerachAccount } from '../../constant';
import BillUnits from '../InvoiceUnits';

class SearchAccounts extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isActiveNext: true,
      isSearching: false,
      page: 0,
      size: 20,
      // filter: { userId: 'UserX001' },
      filter: {},
      sort: '',
      sorted: {},
      totalCount: null,
      isOpenModalInvoiceUnits: false,
      itemSelected: null,
      currencyOptions: [],
      // isPacEnabled: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.listAccounts }),
    };
  }

  componentDidMount() {
    const { getCurrencyConfigApp, currencyConfig, location, getCcpPropertiesConfig } = this.props;
    const { currencyOptions } = this.state;
    if (location && location.state && location.state.filter) {
      const { filter, page, size, totalCount, sorted, isOpenModalInvoiceUnits, itemSelected } = location.state || {};
      this.setState({ filter, page, size, totalCount, sorted, isOpenModalInvoiceUnits, itemSelected }, () => {
        this.doSearchAccountCollection();
      });
    } else {
      this.doSearchAccountCollection();
    }
    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            normalLabel: val.name,
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        normalLabel: val.name,
        label: `${val.name} (${val.currencyId})`,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }
    // getCcpPropertiesConfig('', ({ success, data }) => {
    //   let isPacEnabled = false;
    //   if (success) {
    //     const pacEnabledProperty = data.ccpPropertyList.find(val => val.property === 'pacEnabled');
    //     if (pacEnabledProperty && pacEnabledProperty.value) {
    //       isPacEnabled = pacEnabledProperty.value === 'true';
    //     }
    //   }
    //   this.setState({
    //     isPacEnabled,
    //   });
    // });
  }

  doSearchAccountCollection = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchAccountsCollection } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? accountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchAccountsCollection(payload, () => this.setState({ isSearching: false }));
  };

  onHandleSearch = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchAccountCollection());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchAccountCollection());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchAccountCollection());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchAccountCollection());
  };

  onToggleModalInvoiceUnits = ({ item }) => {
    const { isOpenModalInvoiceUnits } = this.state;
    if (!isOpenModalInvoiceUnits) {
      return this.setState({ itemSelected: item, isOpenModalInvoiceUnits: true });
    }
    return this.setState({ itemSelected: null, isOpenModalInvoiceUnits: false });
  };

  render() {
    const { listAccounts, t, location, getCollectionUnitsById } = this.props;
    const {
      isSearching,
      page,
      size,
      totalCount,
      sorted,
      isOpenModalInvoiceUnits,
      itemSelected,
      currencyOptions,
      filter,
      // isPacEnabled,
    } = this.state;

    const newTableColumnsSerachAccount = [
      {
        name: 'viewInvoices',
        label: 'common:label.viewInvoices',
        render: (colName, item) => (
          <button
            className="btn btn-outline-success btn-sm"
            type="button"
            onClick={() => this.onToggleModalInvoiceUnits({ item })}
          >
            {t('label.view')}
          </button>
        ),
      },
      ...tableColumnsSerachAccount,
    ];

    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && itemSelected?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === itemSelected?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <FormWithTableItem title="label.accounts" wrapperClass="collection-form-item">
          <div className="col-sm-12 mb-30 pl-1">
            <div className="card-body">
              <AccountCollectionSearchForm
                onSubmit={this.onHandleSearch}
                defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
              />
              <br />
            </div>
          </div>
          <DataTable
            columns={newTableColumnsSerachAccount}
            sorted={sorted}
            data={listAccounts}
            onSort={this.onSortColumn}
            isLoading={isSearching}
          />
        </FormWithTableItem>
        <br />
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={this.onPageChange}
          onSizeChange={this.onSizeChange}
        />
        <ModalWithItem
          // modalTitle={t('label.stampingRecord')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalInvoiceUnits}
          onToggle={this.onToggleModalInvoiceUnits}
        >
          <TitleFrom title={t('label.invoiceUnitsInCollection')} isTabel />
          <AccountHeaderFrom
            accountNum={itemSelected?.accountId}
            isOrganization={!!itemSelected?.organization}
            organization={itemSelected?.organization}
            // commercialName={commercialName || ''}
            // isCommercialName={isCommercialName}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <BillUnits
            // isPacEnabled={isPacEnabled}
            filter={filter}
            accountId={itemSelected?.accountId}
            id={itemSelected?.accountId}
            page={page}
            size={size}
            totalCount={totalCount}
            sorted={sorted}
            isOpenModalInvoiceUnits={isOpenModalInvoiceUnits}
            itemSelected={itemSelected}
            getCollectionUnitsById={getCollectionUnitsById}
          />
          <button type="button" onClick={this.onToggleModalInvoiceUnits} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

SearchAccounts.propTypes = {
  listAccounts: PropTypes.arrayOf(PropTypes.any),
  searchAccountsCollection: PropTypes.func.isRequired,
};

SearchAccounts.defaultProps = {
  listAccounts: [],
};

const mapStateToProps = createStructuredSelector({
  listAccounts: makeGetListAccounts() || [],
  currencyConfig: makeGetCurrencyConfig() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchAccountsCollection,
    getCurrencyConfigApp,
    getCcpPropertiesConfig,
    getCollectionUnitsById,
  })(SearchAccounts)
);
