export default {
  status: [
    { label: 'CLOSED', value: 'CLOSED' },
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ],

  RevenueTypestatusPricing: [
    { label: 'CREATE', value: 'CREATE' },
    { label: 'APPROVED', value: 'APPROVED' },
    { label: 'SALEABLE', value: 'SALEABLE' },
    { label: 'NON_SALEABLE', value: 'NON_SALEABLE' },
    { label: 'SUNSET', value: 'SUNSET' },
  ],

  customerSegment: [{ label: 'B2B', value: 'B2B' }, { label: 'B2C', value: 'B2C' }, { label: 'B2B2C', value: 'B2B2C' }],

  type: [
    { label: 'DIRECT_CUSTOMER', value: 'DIRECT_CUSTOMER' },
    { label: 'PARTNER', value: 'PARTNER' },
    { label: 'PARTNER_CUSTOMER', value: 'PARTNER_CUSTOMER' },
    { label: 'RESELLER', value: 'RESELLER' },
    { label: 'RESELLER_CUSTOMER', value: 'RESELLER_CUSTOMER' },
    { label: 'INTERNAL_TEST_ACCOUNT', value: 'INTERNAL_TEST_ACCOUNT' },
    { label: 'BROKER_CHANNEL_PARTNER', value: 'BROKER_CHANNEL_PARTNER' },
    { label: 'DATA_SUPPLIER', value: 'DATA_SUPPLIER' },
    { label: 'INTERCOMPANY', value: 'INTERCOMPANY' },
    { label: 'CONNECTOR_PARTNER', value: 'CONNECTOR_PARTNER' },
    { label: 'AFFILIATE', value: 'AFFILIATE' },
    { label: 'NONE', value: 'NONE' },
  ],

  subType: [
    { label: 'SILVER', value: 'SILVER' },
    { label: 'GOLD', value: 'GOLD' },
    { label: 'PLATINUM', value: 'PLATINUM' },
    { label: 'NONE', value: 'NONE' },
  ],

  salesChannel: [
    { label: 'WEB', value: 'WEB' },
    { label: 'POS', value: 'POS' },
    { label: 'ECOMMERCE', value: 'ECOMMERCE' },
    { label: 'ADVERTISEMENT', value: 'ADVERTISEMENT' },
    { label: 'TELE_MARKET', value: 'TELE_MARKET' },
    { label: 'AGENT_CARE', value: 'AGENT_CARE' },
    { label: 'SELFCARE', value: 'SELFCARE' },
    { label: 'NONE', value: 'NONE' },
  ],

  marketSegment: [
    { label: 'INDIVIDUAL', value: 'INDIVIDUAL' },
    { label: 'SMALL_ENTERPRISE', value: 'SMALL_ENTERPRISE' },
    { label: 'SMB', value: 'SMB' },
    { label: 'LARGE_ENTERPRISE', value: 'LARGE_ENTERPRISE' },
    { label: 'NONE', value: 'NONE' },
  ],

  reason: [
    { label: 'NONE', value: 'NONE' },
    { label: 'PAYMENT_FAILURE', value: 'PAYMENT_FAILURE' },
    { label: 'CUSTOMER_REQUEST', value: 'CUSTOMER_REQUEST' },
    { label: 'COLLECTIONS', value: 'COLLECTIONS' },
    { label: 'INTERNAL_CONTROL', value: 'INTERNAL_CONTROL' },
  ],

  RevenueType: [
    { label: 'TRANSACTION', value: 'TRANSACTION' },
    { label: 'SUBSCRIPTION', value: 'SUBSCRIPTION' },
    { label: 'PROFESSIONAL_SERVICES', value: 'PROFESSIONAL_SERVICES' },
    { label: 'LABOR_EXPENSE', value: 'LABOR_EXPENSE' },
    { label: 'LABOR_TIME', value: 'LABOR_TIME' },
    { label: 'LICENSE', value: 'LICENSE' },
  ],

  paymentTerm: [
    { label: 'NET_30', value: 'NET_30' },
    { label: 'NET_45', value: 'NET_45' },
    { label: 'NET_60', value: 'NET_60' },
    { label: 'NET_75', value: 'NET_75' },
    { label: 'NET_90', value: 'NET_90' },
  ],

  paymentMethod: [
    { label: 'CHECK', value: 'CHECK' },
    { label: 'CREDIT_CARD', value: 'CREDIT_CARD' },
    { label: 'NON_PAYING', value: 'NON_PAYING' },
  ],

  billingFrequency: [
    { label: 'MONTHLY', value: 'MONTHLY' },
    { label: 'BI_MONTHLY', value: 'BI_MONTHLY' },
    { label: 'QUARTERLY', value: 'QUARTERLY' },
    { label: 'SEMI_ANNUAL', value: 'SEMI_ANNUAL' },
    { label: 'ANNUAL', value: 'ANNUAL' },
  ],

  invoiceType: [
    { label: 'SUMMARY', value: 'SUMMARY' },
    { label: 'DETAIL', value: 'DETAIL' },
    { label: 'DETAIL_SUPPRESS', value: 'DETAIL_SUPPRESS' },
  ],

  invoiceDelivery: [
    { label: 'DOWNLOAD', value: 'DOWNLOAD' },
    { label: 'EMAIL', value: 'EMAIL' },
    { label: 'MAIL', value: 'MAIL' },
  ],

  countryList: [
    { label: 'Japan', value: 'JPN' },
    { label: 'Viet Nam', value: 'VNM' },
    { label: 'United States', value: 'USA' },
    { label: 'India', value: 'IND' },
    { label: 'Venezuela', value: 'VEN' },
    { label: 'Venezuela', value: 'AFN' },
  ],

  stateUSData: [
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Alaska',
      value: 'AK',
    },
    {
      label: 'American Samoa',
      value: 'AS',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },
    {
      label: 'District Of Columbia',
      value: 'DC',
    },
    {
      label: 'Federated States Of Micronesia',
      value: 'FM',
    },
    {
      label: 'Florida',
      value: 'FL',
    },
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Guam',
      value: 'GU',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Marshall Islands',
      value: 'MH',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Montana',
      value: 'MT',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Northern Mariana Islands',
      value: 'MP',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Palau',
      value: 'PW',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },
  ],
};
