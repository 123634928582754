import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigApprovals(dummy: "") {
      id
      name
      approvalObjects {
        index
        customReasonCode
        objectType
        customReasonCode
        reasonCode
        approvalLevels {
          index
          userGroupId
          groupName
          approvalRequired
          approvalCorrespondence
          rejectionCorrespondence
        }
      }
    }
  }
`;
