import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import itemNS from './constants/exchangeRateNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || '';
  const dataModify = parseToMutationRequest(newData, itemNS);
  const modifyExchangeRate = `modifyExchangeRate(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyExchangeRate} {
      id
    }
  }
`;
};
