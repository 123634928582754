import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'type',
        'recognitionType',
        'offsetAccount',
        'reportObjectType',
        'transactionType',
        'itemType',
      ])}`
    : '';
  const getRevenueRecognitionReport = `getRevenueRecognitionReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRevenueRecognitionReport} {
        id
        productCompany
        glAccount
        productFamily
        productLine
        productType
        productSubType
        itemName
        billEndDate
        accountId
        sellingCompany
        city
        state
        country
        organization
        recognizedRevenue
        remainingRevenue
        totalRevenue
        type
        recognitionType
        revenueStartDate
        revenueEndDate
      }
    }
  `;
};
