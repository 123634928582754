import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class OpenWriteOffSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.arLevel',
      name: 'arLevel',
      tOptions: 'selections:arLevel',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.taxTreatment',
      name: 'taxTreatment',
      type: 'select',
      tOptions: 'selections:taxRule',
      isClearable: true,
    },
    {
      label: 'label.customReason',
      name: 'customReason',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.reason',
      name: 'reason',
      type: 'select',
      tOptions: 'selections:reasonConfig',
      isClearable: true,
    },
    {
      label: 'label.arThreshold',
      name: 'arThreshold',
      type: 'number',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.organization',
      name: 'company',
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      tOptions: 'selections:paymentMethod',
      isClearable: true,
      type: 'select',
    },
    // {
    //   label: 'label.serviceType',
    //   name: 'serviceType',
    //   type: 'select',
    //   tOptions: 'selections:serviceType',
    //   isClearable: true,
    // },
  ];

  btnSpan = 'col-md-6';
}

OpenWriteOffSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OpenWriteOffSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OpenWriteOffSearchForm);
