import React from 'react';
// import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
// import { historySort } from '../../../../constantsApp';
import {
  // FormWithTableItem,
  // DataTable,
  // TablePagination,
  ModalWithItem,
  TitleFrom,
  AccountHeaderFrom,
} from '../../../../components/common';
// import { CollectionHistorySearchForm } from '../../../../components/CollectionsHub';
import { makeGetListHistory } from '../../selectors';
// import { searchCollectionHistory, setParamsCollectionHistory } from '../../actions';
// import RouteNames from '../../../App/RouteNames';
// import { getPageTotalCount } from '../../../../utils/utils';
import { makeGetPermissionsCollectionManagement, makeGetCurrencyConfig } from '../../../App/selectors';
import { checkPermissionSearchCollectionUnit } from '../../CheckPermission';
import { AgeingSummary } from '../../../ReportManagement/CollectionReports';
import CollectionHistoryDetail from './CollectionHistoryDetail';
import { getCurrencyConfigApp } from '../../../App/actions';

class CollectionHistory extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      // isActiveNext: true,
      // isSearching: false,
      // totalCount: null,
      // page: 0,
      // size: 20,
      // filter: {},
      itemSelected: null,
      currencyOptions: [],
    };
  }

  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     totalCount: getPageTotalCount({ ...state, items: props.listHistorys }),
  //   };
  // }

  componentDidMount() {
    const { getCurrencyConfigApp, currencyConfig } = this.props;
    const { currencyOptions } = this.state;
    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            normalLabel: val.name,
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        normalLabel: val.name,
        label: `${val.name} (${val.currencyId})`,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }
  }

  // onSearchCollectionHistory = () => {
  //   const { searchCollectionHistory } = this.props;
  //   const { page, size, filter, sorted } = this.state;
  //   const payload = {
  //     page: page + 1,
  //     size,
  //     filter,
  //     sort: !isEmpty(sorted) ? historySort[sorted.sortCol][sorted.sortDir] : null,
  //   };
  //   this.setState({ isSearching: true });
  //   searchCollectionHistory(payload, () => this.setState({ isSearching: false }));
  // };

  // onHandleSearch = filter => {
  //   this.setState({ filter, page: 0 }, () => this.onSearchCollectionHistory());
  // };

  // onPageChange = page => {
  //   this.setState({ page }, () => this.onSearchCollectionHistory());
  // };

  // onSizeChange = size => {
  //   this.setState({ size, page: 0 }, () => this.onSearchCollectionHistory());
  // };

  // onSortColumn = (sortCol, sortDir) => {
  //   this.setState({ sorted: { sortCol, sortDir } }, () => this.onSearchCollectionHistory());
  // };

  onVToggleiewCollection = ({ item }) => {
    const { isOpenModalCollectionHistoryDetail } = this.state;
    if (!isOpenModalCollectionHistoryDetail) {
      return this.setState({ itemSelected: item, isOpenModalCollectionHistoryDetail: true });
    }
    return this.setState({ itemSelected: null, isOpenModalCollectionHistoryDetail: false });
  };

  render() {
    const { listHistorys, permissionsCollections, t, location } = this.props;
    const {
      // isSearching,
      // totalCount,
      // page,
      // size,
      // sorted,
      // filter,
      itemSelected,
      isOpenModalCollectionHistoryDetail,
      currencyOptions,
    } = this.state;
    let modeSearchCollectionUnit = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeSearchCollectionUnit = checkPermissionSearchCollectionUnit({ listPermission });
    }

    if (modeSearchCollectionUnit === 0) return '';
    // const tableColumnsCollectionHistory = [
    //   {
    //     name: 'accountId',
    //     label: 'common:label.accountId',
    //     sortable: true,
    //     render: (colName, item) => (
    //       <Link
    //         className="text-success"
    //         to={{
    //           pathname: RouteNames.prettifyPath(RouteNames.collectionsHistoryDetails.path, item.id),
    //           state: { filter },
    //         }}
    //       >
    //         {item.accountId}
    //       </Link>
    //     ),
    //   },
    //   {
    //     name: 'invoiceId',
    //     label: 'common:label.invoiceId',
    //   },
    //   {
    //     name: 'lastName',
    //     label: 'common:label.lastName',
    //   },
    //   {
    //     name: 'invoiceDate',
    //     label: 'common:label.invoiceDate',
    //   },
    //   {
    //     name: 'dueDate',
    //     label: 'common:label.dueDate',
    //   },
    //   {
    //     name: 'daysInCollection',
    //     label: 'common:label.daysInCollection',
    //   },
    //   {
    //     name: 'status',
    //     label: 'common:label.status',
    //     sortable: true,
    //     isRenderT: true,
    //     render: (colName, item, t) => {
    //       const slt = t ? t('selections:statusCollections')().find(val => val.value === item.status) : '';
    //       return <span>{slt ? slt.label : ''}</span>;
    //     },
    //   },
    //   {
    //     name: 'lastActionDate',
    //     label: 'common:label.lastActionDate',
    //   },
    //   {
    //     name: 'closedDate',
    //     label: 'common:label.closeDate',
    //   },
    // ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && itemSelected?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === itemSelected?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30 p-0">
        {/* <FormWithTableItem title="label.collectionHistory" wrapperClass="collection-form-item">
          <div className="col-sm-12 mb-30 pl-1">
            <div className="card-body">
              <CollectionHistorySearchForm
                onSubmit={this.onHandleSearch}
                defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
              />
              <br />
            </div>
          </div>
          <DataTable
            columns={tableColumnsCollectionHistory}
            sorted={sorted}
            data={listHistorys}
            onSort={this.onSortColumn}
            isLoading={isSearching}
          />
        </FormWithTableItem>
        <br />
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={this.onPageChange}
          onSizeChange={this.onSizeChange}
        /> */}
        <AgeingSummary
          permissions={{ modeGetAgeingReport: modeSearchCollectionUnit }}
          isCollection
          onViewCollection={this.onVToggleiewCollection}
          defaultFilterSeted={{ groupByType: 'INVOICE' }}
        />

        <ModalWithItem
          // modalTitle={t('label.stampingRecord')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalCollectionHistoryDetail}
          onToggle={this.onVToggleiewCollection}
        >
          <TitleFrom title={t('label.collectionHistoryDetail')} isTabel />
          <AccountHeaderFrom
            accountNum={itemSelected?.accountId}
            isOrganization={!!itemSelected?.organization}
            organization={itemSelected?.organization}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <CollectionHistoryDetail id={itemSelected?.collectionUnitId} />
          <button type="button" onClick={this.onVToggleiewCollection} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

CollectionHistory.propTypes = {
  listHistorys: PropTypes.arrayOf(PropTypes.any),
  searchCollectionHistory: PropTypes.func.isRequired,
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

CollectionHistory.defaultProps = {
  listHistorys: [],
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  listHistorys: makeGetListHistory() || [],
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCurrencyConfigApp,
    // searchCollectionHistory,
    // setParamsCollectionHistory,
  })(CollectionHistory)
);
