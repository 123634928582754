// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'patternType',
    'everyNDay',
    'everyWeekDay',
    'everyWeekEnd',
    'everyNWeek',
    'everyMonday',
    'everyTuesday',
    'everyWednesday',
    'everyThursday',
    'everyFriday',
    'everySaturday',
    'everySunday',
    'dayOfMonth',
    'everyNMonth',
    'everyNYear',
    'dayOfMonth',
    'monthOfYear',
  ]);
  const modifySchedulePattern = `modifySchedulePattern(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifySchedulePattern} {
      id
    }
  }
`;
};
