import { createSelector } from 'reselect';

const selectCollections = state => state.get('jobManagementReducer');

// ----get job config

const makeJobConfig = () =>
  createSelector(selectCollections, item => item.get('jobConfig'));

const makeJobSchedule = () =>
  createSelector(selectCollections, item => item.get('jobSchedule'));
const makeJobScheduleTime = () =>
  createSelector(
    selectCollections,
    item => item.get('jobSchedule').scheduleDate,
  );
const makeJobErrorGroupList = () =>
  createSelector(selectCollections, item => {
    const jobErrorGroupList = item.get('jobErrorGroupList');
    if (jobErrorGroupList) {
      return jobErrorGroupList;
    }
    return null;
  });
const getErrorMessage = () =>
  createSelector(selectCollections, item => item.get('errorMessage'));

export {
  makeJobConfig,
  makeJobSchedule,
  makeJobScheduleTime,
  makeJobErrorGroupList,
  getErrorMessage,
};
