import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { ServiceTable } from '../../../components/Quotes';
import { CardHeaderForm } from '../../../components/common';
import constants from '../constants';

class ChooseService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverrideLines: {},
    };
  }

  handleClickServicesFormBtn = index => {
    const { isOverrideLines } = this.state;
    isOverrideLines[index] = !isOverrideLines[index];
    this.setState({
      isOverrideLines,
    });
  };

  renderServiceTableWithPackage = () => {
    const { isOverrideLines } = this.state;
    const {
      groupServices,
      addLine,
      onChangeFormLine,
      onChangeService,
      onChangeProvisioningId,
      onRemoveLine,
      onRemoveItem,
      modeOverrideQuoteLines,
      isViewOnly,
    } = this.props;

    return map(groupServices, (service, index) => {
      return (
        <ServiceTable
          key={index}
          isFetchLine
          addLine={(serviceIndex, e) => addLine(index, serviceIndex, e)}
          onChangeService={e => {
            onChangeService(index, e);
          }}
          isRemoveTrashFromLine
          onRemoveItem={onRemoveItem}
          onRemoveLine={onRemoveLine}
          onChangeProvisioningId={onChangeProvisioningId}
          isOverrideLines={isOverrideLines[index]}
          onChangeFormLine={(serviceIndex, e) => onChangeFormLine(index, serviceIndex, e)}
          services={service}
          handleClickServicesFormBtn={() => this.handleClickServicesFormBtn(index)}
          modeOverrideQuoteLines={modeOverrideQuoteLines}
          isViewOnly={isViewOnly}
          isSupportShowAddDiscount
        />
      );
    });
  };

  renderServiceTable = () => {
    const { isOverrideLines } = this.state;
    const {
      groupServices,
      addLine,
      onChangeFormLine,
      onChangeService,
      onChangeProvisioningId,
      onRemoveLine,
      onRemoveItem,
      modeOverrideQuoteLines,
      isViewOnly,
    } = this.props;

    return map(groupServices, (service, index) => {
      return (
        <ServiceTable
          key={index}
          isFetchLine={false}
          addLine={(serviceIndex, e) => addLine(index, serviceIndex, e)}
          onChangeService={e => {
            onChangeService(index, e);
          }}
          isRemoveTrashFromLine
          onRemoveLine={onRemoveLine}
          onRemoveItem={onRemoveItem}
          isOverrideLines={isOverrideLines[index]}
          onChangeProvisioningId={onChangeProvisioningId}
          onChangeFormLine={(serviceIndex, e) => onChangeFormLine(index, serviceIndex, e)}
          services={service}
          handleClickServicesFormBtn={() => this.handleClickServicesFormBtn(index)}
          modeOverrideQuoteLines={modeOverrideQuoteLines}
          isViewOnly={isViewOnly}
          isSupportShowAddDiscount
        />
      );
    });
  };

  renderServiceTables = () => {
    const { mode } = this.props;
    switch (mode) {
      case constants.ModalMode.purchasePackage:
        return this.renderServiceTableWithPackage();

      default:
        return this.renderServiceTable();
    }
  };

  render() {
    const { id, t } = this.props;
    return (
      // <div className="col-md-12 mb-30">
      <div className="card card-statistics">
        {/* <CardHeaderForm title={t('label.quote')} label={t('label.accountNumber')} value={id} /> */}
        {this.renderServiceTables()}
        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

ChooseService.propTypes = {
  onRemoveLine: PropTypes.func,
  addLine: PropTypes.func,
  onChangeFormLine: PropTypes.func,
  onChangeService: PropTypes.func,
  onChangeProvisioningId: PropTypes.func,
  onRemoveItem: PropTypes.func,
  groupServices: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  id: PropTypes.string,
  t: PropTypes.func,
  modeOverrideQuoteLines: PropTypes.number,
};

ChooseService.defaultProps = {
  onRemoveLine: () => {},
  addLine: () => {},
  onChangeFormLine: () => {},
  onChangeService: () => {},
  onChangeProvisioningId: () => {},
  onRemoveItem: () => {},
  groupServices: {},
  t: () => {},
  mode: '',
  id: '',
  modeOverrideQuoteLines: 0,
};

const newChooseService = connect(mapStateToProps, {})(ChooseService);

export default withTranslation('common')(newChooseService);
