import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size, cloneDeep, orderBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import constant from '../constants';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getFutureCycleCfg, modifyFutureCycleCfg, createFutureCycleCfg } from '../actions';
import { makeFutureCycleCfg } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import { makeGetPermissionsRating, makeGetPermissionsBilling } from '../../App/selectors';
import {
  checkPermissionGetDelayedBillingConfig,
  checkPermissionModifyDelayedBillingConfig,
  checkPermissionCreateDelayedBillingConfig,
  checkPermissionSetCreditLimit,
} from '../CheckPermission';
import SidebarIndex from '../SidebarIndex';

class DelayedBilling extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    futureCycleListCfg: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.delayedBilling) return null;
    return {
      futureCycleListCfg:
        props.delayedBilling && props.delayedBilling.futureCycleListCfg ? props.delayedBilling.futureCycleListCfg : [],
      oldValue: props.delayedBilling,
      dataSubmit: [],
    };
  }

  componentDidMount() {
    this.doGetFutureCycleCfg();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { futureCycleListCfg, dataSubmit } = this.state;
    const newData = cloneDeep(futureCycleListCfg);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ futureCycleListCfg: newData });
  };

  doGetFutureCycleCfg = () => {
    const { getFutureCycleCfg } = this.props;
    getFutureCycleCfg('', () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewProfile = () => {
    let indexNewItem = 0;
    const { futureCycleListCfg, dataSubmit } = this.state;
    const newData = cloneDeep(futureCycleListCfg);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      salesChannel: 'NONE',
      marketSegment: 'NONE',
      accountType: 'NONE',
      accountSubType: 'NONE',
      delay: 0,
      billingDom: 0,
      billingFrequency: null,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ futureCycleListCfg: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { futureCycleListCfg, dataSubmit } = this.state;
    const newData = cloneDeep(futureCycleListCfg);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ futureCycleListCfg: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit } = this.state;
    const { modifyFutureCycleCfg, createFutureCycleCfg, delayedBilling } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (delayedBilling && delayedBilling.id) {
      const newPayload = {
        id: delayedBilling.id,
        futureCycleListCfg: dataSubmit,
      };
      return modifyFutureCycleCfg(newPayload, ({ success }) => {
        if (success) this.doGetFutureCycleCfg();
      });
    }

    const newPayloadCreate = cloneDeep(dataSubmit).map(item => {
      const newItem = item;
      delete newItem.index;
      return newItem;
    });
    createFutureCycleCfg({ futureCycleListCfg: newPayloadCreate }, ({ success }) => {
      if (success) this.doGetFutureCycleCfg();
    });
  };

  render() {
    const { futureCycleListCfg, isSearching, wasValidated } = this.state;
    const { delayedBilling, permissionsRating, permissionsBilling, t, isComponent } = this.props;
    let modeGetDelayedBillingConfig = 0;
    let modeModifyDelayedBillingConfig = 0;
    let modeCreateDelayedBillingConfig = 0;

    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermissionsBilling = permissionsBilling.billingModulePermissions;
      modeGetDelayedBillingConfig = checkPermissionGetDelayedBillingConfig({ listPermission: listPermissionsBilling });
      modeCreateDelayedBillingConfig = checkPermissionCreateDelayedBillingConfig({
        listPermission: listPermissionsBilling,
      });
      modeModifyDelayedBillingConfig = checkPermissionModifyDelayedBillingConfig({
        listPermission: listPermissionsBilling,
      });
    }

    const delayedBillingColumns = [
      {
        name: 'salesChannel',
        label: t('label.salesChannel'),
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.salesChannel}
            tOptions="selections:salesChannel"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="salesChannel"
            required
          />
        ),
      },
      {
        name: 'marketSegment',
        label: t('label.marketSegment'),
        style: { textAlign: 'center', minWidth: '160px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.marketSegment}
            tOptions="selections:marketSegment"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="marketSegment"
            required
          />
        ),
      },
      {
        name: 'accountType',
        label: t('label.accountType'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.accountType}
            tOptions="selections:accountType"
            wrapperClass="col-md-12"
            menuPortalTarget
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountType"
            required
          />
        ),
      },
      {
        name: 'accountSubType',
        label: t('label.accountSubType'),
        style: { minWidth: '160px', textAlign: 'center' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.accountSubType}
            menuPortalTarget
            wrapperClass="col-md-12"
            tOptions="selections:accountSubType"
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountSubType"
            required
          />
        ),
      },
      {
        name: 'delay',
        label: t('label.delayInterval'),
        style: { textAlign: 'center', minWidth: '110px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.delay}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="delay"
            required
          />
        ),
      },
      {
        name: 'billingDom',
        label: t('label.billingDom'),
        style: { textAlign: 'center', minWidth: '110px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.billingDom}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="billingDom"
            required
          />
        ),
      },
      {
        name: 'billingFrequency',
        label: t('label.billingFrequency'),
        style: { textAlign: 'center', minWidth: '110px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.billingFrequency}
            wrapperClass="col-md-12"
            tOptions="selections:billingFrequency"
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="billingFrequency"
            required
            menuPortalTarget
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const newDataOnlyViewInTable =
      futureCycleListCfg && futureCycleListCfg.length
        ? orderBy(
            futureCycleListCfg.filter(item => size(item) > 1),
            ['index'],
            ['asc']
          )
        : [];

    if (isComponent && modeGetDelayedBillingConfig)
      return (
        <div className="col-md-12">
          <FormWithTableItem title={t('label.delayedBilling')}>
            <form
              onSubmit={this.hanldeSubmit}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
              noValidate
              ref={this.formRef}
            >
              <br />
              <div>
                <DataTable columns={delayedBillingColumns} data={newDataOnlyViewInTable} isLoading={isSearching} />
              </div>
              <br />
              <div className="form-group col-md-12 buttons-attibute">
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProfile}>
                  +
                  {t('label.addDelayedBilling')}
                </button>
                <button type="submit" className="button button-border x-small float-right">
                  {delayedBilling && delayedBilling.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
                <button
                  type="button"
                  onClick={this.doGetFutureCycleCfg}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </FormWithTableItem>
        </div>
      );

    return (
      <div className="container-fluid">
        <div className="row">
          <SidebarIndex />
          {modeGetDelayedBillingConfig !== 0 && (
            <div className="content-wrapper">
              <div className="col-md-12 mb-30">
                <PageTitle
                  linkTo={RouteNames.customers.path}
                  titleBtn={t('common:label.back')}
                  items={[
                    { name: t('label..customerManagement'), url: RouteNames.customers.path },
                    { name: t('label.delayedBilling') },
                  ]}
                />
                <FormWithTableItem title={t('label.delayedBilling')}>
                  <form
                    onSubmit={this.hanldeSubmit}
                    className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                    noValidate
                    ref={this.formRef}
                  >
                    <br />
                    <div>
                      <DataTable
                        columns={delayedBillingColumns}
                        data={newDataOnlyViewInTable}
                        isLoading={isSearching}
                      />
                    </div>
                    <br />
                    <div className="form-group col-md-12 pb-3 buttons-attibute">
                      {modeCreateDelayedBillingConfig === 2 && (
                        <button
                          type="button"
                          className="button button-border black x-small"
                          onClick={this.onAddNewProfile}
                        >
                          +
                          {t('label.addDelayedBilling')}
                        </button>
                      )}
                      {modeModifyDelayedBillingConfig === 2 && (
                        <button
                          type="submit"
                          className="button button-border x-small float-right"
                          // onClick={() => this.hanldeSubmit()}
                        >
                          {delayedBilling && delayedBilling.id ? t('label.saveConfig') : t('label.createConfig')}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={this.doGetFutureCycleCfg}
                        className="button button-border black x-small float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    </div>
                  </form>
                </FormWithTableItem>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DelayedBilling.propTypes = {
  delayedBilling: PropTypes.objectOf(PropTypes.any),
  getFutureCycleCfg: PropTypes.func.isRequired,
  createFutureCycleCfg: PropTypes.func.isRequired,
  modifyFutureCycleCfg: PropTypes.func.isRequired,
  permissionsRating: PropTypes.objectOf(PropTypes.any),
};

DelayedBilling.defaultProps = {
  delayedBilling: {},
  permissionsRating: {},
};
const mapStateToProps = createStructuredSelector({
  delayedBilling: makeFutureCycleCfg() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
});

const newDelayedBilling = connect(mapStateToProps, {
  getFutureCycleCfg,
  modifyFutureCycleCfg,
  createFutureCycleCfg,
})(DelayedBilling);

export default withTranslation('common')(newDelayedBilling);
