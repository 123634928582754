import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DataTable, FormCollapse, FormWithTableItem, GenericInput } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { getExpensesConfig, createExpensesConfig, modifyExpensesConfig } from '../actions';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsBilling } from '../../App/selectors';
import { checkPermissionForAllPages } from '../CheckPermission';
import { getLastIndex, supportRemoveIndexWithSize, compareArrayIndexValueChange } from '../../../utils/utils';

const collapsibleMenu = [
  {
    title: 'common:label.fixedExpenseTypes',
    state: 'fixedExpenseTypes',
  },
  {
    title: 'common:label.variableExpenseTypes',
    state: 'variableExpenseTypes',
  },
];

const TEExpenseType = ({ getExpensesConfig, createExpensesConfig, modifyExpensesConfig, permissionsBilling }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState({});
  const [defaultData, setDefaultData] = useState(null);

  const { t } = useTranslation('common');

  let permissions = {};

  if (permissionsBilling && permissionsBilling.billingModulePermissions) {
    const listPermission = permissionsBilling.billingModulePermissions;
    permissions = checkPermissionForAllPages({
      listPermission,
    });
  }

  const { modeCreateExpensesConfig, modeGetExpensesConfig, modeModifyExpensesConfig } = permissions;

  const doGetExpensesConfig = useCallback(() => {
    setLoading(true);
    getExpensesConfig('', ({ success, data }) => {
      setLoading(false);
      if (success && data) {
        setData(data);
        setDefaultData(data || null);
      } else {
        setData({});
        setDefaultData(null);
      }
    });
  }, [getExpensesConfig]);

  const onChangeTable = ({ name, value, index, key }) => {
    const newData = cloneDeep(data);
    newData[key][index][name] = value;
    setData(newData);
  };

  const onAddNewFixedExpense = () => {
    const newData = cloneDeep(data);
    if (!newData || !newData.fixedExpenses) newData.fixedExpenses = [];
    newData.fixedExpenses.push({
      index: getLastIndex({ data: newData.fixedExpenses }),
      name: null,
      description: null,
      isNew: true,
    });
    setData(newData);
  };

  const onAddNewVariableExpenses = () => {
    const newData = cloneDeep(data);
    if (!newData || !newData.variableExpenses) newData.variableExpenses = [];
    newData.variableExpenses.push({
      index: getLastIndex({ data: newData.variableExpenses }),
      name: null,
      description: null,
      isNew: true,
    });
    setData(newData);
  };

  const onRemoveItem = ({ index, item, key }) => {
    try {
      const newData = cloneDeep(data);
      if (item.isNew) {
        newData[key].splice(index, 1);
        return setData(newData);
      }
      newData[key][index] = { index: newData[key][index].index };
      return setData(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  const fixedExpenseTypesColumns = [
    {
      name: 'type',
      label: 'label.name',
      render: (colName, item, index) => (
        <GenericInput
          value={item.type}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'fixedExpenses' })}
          name="type"
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'fixedExpenses' })}
          name="description"
        />
      ),
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="no-border"
            onClick={() => onRemoveItem({ index, item, key: 'fixedExpenses' })}
            disabled={modeModifyExpensesConfig !== 2}
          >
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const variableExpenseTypesColumns = [
    {
      name: 'type',
      label: 'label.name',
      render: (colName, item, index) => (
        <GenericInput
          value={item.type}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'variableExpenses' })}
          name="type"
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'variableExpenses' })}
          name="description"
        />
      ),
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="no-border"
            onClick={() => onRemoveItem({ index, item, key: 'variableExpenses' })}
            disabled={modeModifyExpensesConfig !== 2}
          >
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  const renderItem = item => {
    switch (item.state) {
      case 'fixedExpenseTypes':
        return (
          <div className="row col-md-12 p-2">
            <DataTable
              columns={fixedExpenseTypesColumns}
              data={data && data?.fixedExpenses ? data.fixedExpenses : []}
              isLoading={isLoading}
              isSupportRemoveIndex
            />
            {modeCreateExpensesConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={onAddNewFixedExpense}>
                {`+ ${t('label.add')}`}
              </button>
            )}
          </div>
        );
      case 'variableExpenseTypes':
        return (
          <div className="row col-md-12 p-2">
            <DataTable
              columns={variableExpenseTypesColumns}
              data={data && data?.variableExpenses ? data.variableExpenses : []}
              isLoading={isLoading}
              isSupportRemoveIndex
            />
            {modeCreateExpensesConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={onAddNewVariableExpenses}>
                {`+ ${t('label.add')}`}
              </button>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const onSubmit = () => {
    const payload = cloneDeep(data);
    const { fixedExpenses, variableExpenses, id } = payload;

    if (id) {
      return modifyExpensesConfig(
        {
          id,
          fixedExpenses: fixedExpenses
            ? compareArrayIndexValueChange({
                defaultData: defaultData ? defaultData.fixedExpenses : null,
                newData: supportRemoveIndexWithSize({ data: fixedExpenses }),
              })
            : null,
          variableExpenses: variableExpenses
            ? compareArrayIndexValueChange({
                defaultData: defaultData ? defaultData.variableExpenses : null,
                newData: supportRemoveIndexWithSize({ data: variableExpenses }),
              })
            : null,
        },
        ({ success }) => {
          if (success) doGetExpensesConfig();
        }
      );
    }

    return createExpensesConfig(
      {
        fixedExpenses: fixedExpenses
          ? fixedExpenses.map(val => {
              const { index, isNew, ...rest } = val;
              return { ...rest };
            })
          : null,
        variableExpenses: variableExpenses
          ? fixedExpenses.map(val => {
              const { index, isNew, ...rest } = val;
              return { ...rest };
            })
          : null,
      },
      ({ success }) => {
        if (success) doGetExpensesConfig();
      }
    );
  };

  useEffect(() => {
    doGetExpensesConfig();
  }, [doGetExpensesConfig]);

  if (!modeGetExpensesConfig) return null;

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoicingBilling.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
          { name: t('label.TEExpenseType') },
        ]}
      />

      <FormWithTableItem title="common:label.TEExpenseType">
        <div className="mb-2">
          <div className="pt-4">
            {collapsibleMenu.map(item => (
              <FormCollapse
                key={item.title}
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={onToggleTab}
              >
                {renderItem(item)}
              </FormCollapse>
            ))}
          </div>
          <div className="col-md-12 mt-3">
            {modeModifyExpensesConfig === 2 && (
              <button type="button" onClick={onSubmit} className="button button-border x-small float-right">
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={doGetExpensesConfig}
              className="button button-border black x-small float-right mr-2"
            >
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </FormWithTableItem>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  permissionsBilling: makeGetPermissionsBilling() || {},
});

TEExpenseType.propTypes = {
  getExpensesConfig: PropTypes.func.isRequired,
  createExpensesConfig: PropTypes.func.isRequired,
  modifyExpensesConfig: PropTypes.func.isRequired,
  permissionsBilling: PropTypes.objectOf(PropTypes.any),
};

TEExpenseType.defaultProps = {
  permissionsBilling: {},
};

export default connect(mapStateToProps, {
  getExpensesConfig,
  createExpensesConfig,
  modifyExpensesConfig,
})(TEExpenseType);
