import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../../components/PricingHub/SearchComponent';

class BarchartSearchForm extends SearchComponent {
  searchInputs = [
    {
      name: 'barChartDashboardType',
      label: 'common:label.barChartDashboardType',
      type: 'select',
      tOptions: 'selections:barChartDashboardType',
      required: true,
      isClearable: true,
      defaultValue: 'USAGE_DATA_BY_SERVICE_TYPE',
    },
    {
      name: 'startDate',
      label: 'common:label.startDate',
      type: 'date',
      required: true,
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
    },
    {
      name: 'endDate',
      label: 'common:label.endDate',
      type: 'date',
      required: true,
      defaultValue: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
    {
      name: 'xaxisParameter',
      label: 'common:label.xaxisParameter',
      type: 'select',
      tOptions: 'selections:xaxisParameter',
      isClearable: true,
      required: true,
      defaultValue: 'SERVICETYPE',
    },
    {
      name: 'yaxisParameter',
      label: 'common:label.yaxisParameter',
      type: 'select',
      tOptions: 'selections:yaxisParameter',
      isClearable: true,
      required: true,
      defaultValue: 'AMOUNT',
    },
    {
      name: 'noOfRecordsLimit',
      label: 'common:label.noOfRecordsLimit',
      type: 'number',
      required: true,
      defaultValue: '10',
    },
    {
      name: 'serviceType',
      label: 'common:label.serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isMulti: true,
      isClearable: true,
      defaultValue: [{ value: 'VOICE', label: 'VOICE' }],
    },
    {
      name: 'usageType',
      label: 'common:label.usageType',
    },
  ];

  btnSpan = 'col-md-12';
}

BarchartSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BarchartSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BarchartSearchForm);
