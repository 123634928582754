import gql from 'graphql-tag';

export default batchId => {
  const getSuspendedFailedBatchDetail = `getSuspendedFailedBatchDetail(batchId: "${batchId}")`;
  return gql`
    query {
      ${getSuspendedFailedBatchDetail} {
        status
        errorMsg
        errorCode
        recordCount
      }
    }
  `;
};
