export default {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  bundleId: {
    asc: 'bundleId_ASC',
    desc: 'bundleId_DESC',
  },

  packageId: {
    asc: 'packageId_ASC',
    desc: 'packageId_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};
