/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectBillInvoice } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import ModalMultipleSelector from '../../Modals/ModalMultipleSelector';
import BillInvoiceSearchForm from './BillInvoiceSearchForm';

class ModalSearchBills extends PureComponent {
  searchHanldeBillInvoiceId = (payload, cb) => {
    const { selectBillInvoice } = this.props;
    const newPayload = { ...payload };

    selectBillInvoice(newPayload, resp => {
      cb({ success: resp.success, data: resp?.data?.billInvoiceData ? resp.data.billInvoiceData || [] : [] });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'invoiceUnitId',
          label: 'label.invoiceId',
        },
        {
          name: 'total',
          label: 'label.total',
        },
        {
          name: 'due',
          label: 'label.due',
        },
        {
          name: 'invoiceStatus',
          label: 'label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.invoiceStatus) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      fieldDisableCheck,
      isMultipe,
      defaultSearchForm,
      selectFieldName,
      defaultSearchParams,
    } = this.props;
    return !isMultipe ? (
      <ModalSelector
        modalTitle={t('label.selectInvoice')}
        isOpen={isOpen}
        SearchForm={BillInvoiceSearchForm}
        searchItems={this.searchHanldeBillInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        fieldDisableCheck={fieldDisableCheck}
        defaultSearchForm={defaultSearchForm}
        selectFieldName={selectFieldName}
        defaultSearchParams={defaultSearchParams}
      />
    ) : (
      <ModalMultipleSelector
        modalTitle={t('label.selectInvoice')}
        isOpen={isOpen}
        SearchForm={BillInvoiceSearchForm}
        searchItems={this.searchHanldeBillInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        fieldDisableCheck={fieldDisableCheck}
        defaultSearchForm={defaultSearchForm}
        selectFieldName={selectFieldName}
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalSearchBills.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectBillInvoice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSearchBills.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectBillInvoice })(ModalSearchBills));
