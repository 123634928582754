import React from 'react';
import { connect } from 'react-redux';
import { size, isEmpty, cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { collectionsEnum } from '../../../../constantsApp';
import { TableCollectionAgent, FormAddCollectionAgent } from '../../../../components/CollectionsHub';
import { getCollectionAgentByType, createCollectionAgent, modifyCollectionAgent } from '../../actions';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import {
  checkPermissionCreateCollectionAgentConfig,
  checkPermissionModifyCollectionAgentConfig,
} from '../../CheckPermission';
import { getTableFromResponeCollections, compareArrayIndexValueChange } from '../../../../utils/utils';

class CollectionAgent extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      collectionAgent: [],
      saveCollectionAgent: [],
      rowSelectedItem: null,
      newAgent: {},
      defaultData: null,
    };
    this.isEmptyListServer = true;
  }

  componentDidMount() {
    const { getCollectionAgentByType } = this.props;
    getCollectionAgentByType(
      {
        type: collectionsEnum.type.collectionAgents,
      },
      response => {
        const listTable = getTableFromResponeCollections(
          response && response.getCollectionAgentByType,
          'collectionAgents'
        );
        this.setState({
          collectionAgent: listTable,
          saveCollectionAgent: listTable,
          defaultData: cloneDeep(listTable),
        });
        if (
          !response.getCollectionAgentByType ||
          !Array.isArray(response.getCollectionAgentByType) ||
          response.getCollectionAgentByType.length <= 0
        ) {
          this.isEmptyListServer = true;
        } else {
          this.isEmptyListServer = false;
        }
      }
    );
  }

  onSelectTableRow = item => {
    let { rowSelectedItem } = this.state;
    if (rowSelectedItem === item) {
      rowSelectedItem = null;
    } else {
      rowSelectedItem = item;
    }
    this.setState({ rowSelectedItem });
  };

  onChangeRowValue = (id, type, val, row) => {
    const { contact } = row;
    const { collectionAgent } = this.state;
    if (contact && contact.length) {
      collectionAgent[id].firstName = contact[0] && contact[0].firstName ? contact[0].firstName : '';
      collectionAgent[id].lastName = contact[0] && contact[0].lastName ? contact[0].lastName : '';
      collectionAgent[id].emailId = contact[0] && contact[0].email ? contact[0].email : '';
    }
    collectionAgent[id][type] = val;
    this.setState({
      collectionAgent: [...collectionAgent],
    });
  };

  onChangeNewValue = ({ name, value, item }) => {
    const { contact } = item;
    const { newAgent } = this.state;
    if (contact && contact.length) {
      newAgent.firstName = contact[0] && contact[0].firstName ? contact[0].firstName : '';
      newAgent.lastName = contact[0] && contact[0].lastName ? contact[0].lastName : '';
      newAgent.emailId = contact[0] && contact[0].email ? contact[0].email : '';
    }
    newAgent[name] = value;
    this.setState({
      newAgent: { ...newAgent },
    });
  };

  resetListIndex = list =>
    list.map((item, id) => ({
      ...item,
      index: id + 1,
    }));

  handleClickAddNewRecord = () => {
    let lastIdx = 1;
    const { collectionAgent } = this.state;
    if (collectionAgent && collectionAgent.length > 0) {
      const sortCollectionAgent = collectionAgent.sort((a, b) => a.id - b.id);
      lastIdx = sortCollectionAgent[sortCollectionAgent.length - 1].index + 1;
    }
    this.setState(pre => ({
      collectionAgent: [
        ...pre.collectionAgent,
        {
          ...pre.newAgent,
          index: lastIdx,
        },
      ],
      newAgent: { userName: '', firstName: '', lastName: '' },
    }));
  };

  handleClickRemoveRecord = item => {
    const { collectionAgent } = this.state;
    const newCollection = cloneDeep(collectionAgent);
    this.setState({
      collectionAgent: newCollection.filter(val => val.index !== item.index),
    });
    // this.setState(pre => ({
    //   collectionAgent: this.resetListIndex(pre.collectionAgent.filter(e => e !== pre.rowSelectedItem)),
    //   rowSelectedItem: null,
    // }));
  };

  handleClickApplyButton = () => {
    const removeAgent = [];
    const { saveCollectionAgent, collectionAgent, defaultData } = this.state;
    const { createCollectionAgent, modifyCollectionAgent } = this.props;
    (saveCollectionAgent || []).map(item => {
      const checkArray = collectionAgent.find(e => e.index === item.index);
      if (!checkArray) {
        removeAgent.push({ index: item.index });
      }
      return null;
    });
    const agentList = (collectionAgent || []).map(item => ({
      index: item.index,
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
    }));
    const data = {
      collectionAgents: [...removeAgent, ...agentList],
    };

    if (this.isEmptyListServer) {
      createCollectionAgent(data, response => {
        const listTable =
          response && response.createCollectionAgent && response.createCollectionAgent.collectionAgents
            ? response.createCollectionAgent.collectionAgents
            : [];
        this.setState({
          collectionAgent: listTable,
          saveCollectionAgent: listTable,
        });

        if (!response.createCollectionAgent) {
          this.isEmptyListServer = true;
        } else {
          this.isEmptyListServer = false;
        }
      });
    } else {
      modifyCollectionAgent(
        {
          collectionAgents: compareArrayIndexValueChange({
            defaultData: defaultData
              ? defaultData.map(val => {
                  const { emailId, ...rest } = val;
                  return { ...rest };
                })
              : null,
            newData: data.collectionAgents || [],
          }),
        },
        response => {
          const listTable =
            response && response.modifyCollectionAgent && response.modifyCollectionAgent.collectionAgents
              ? response.modifyCollectionAgent.collectionAgents
              : [];
          this.setState({
            collectionAgent: listTable,
            saveCollectionAgent: listTable,
          });
          if (!response.modifyCollectionAgent) {
            this.isEmptyListServer = true;
          } else {
            this.isEmptyListServer = false;
          }
        }
      );
    }
  };

  render() {
    const { collectionAgent, rowSelectedItem, newAgent } = this.state;
    const { permissionsCollections, t } = this.props;

    let modeCreateCollectionAgentConfig = 0;
    let modeModifyCollectionAgentConfig = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeCreateCollectionAgentConfig = checkPermissionCreateCollectionAgentConfig({ listPermission });
      modeModifyCollectionAgentConfig = checkPermissionModifyCollectionAgentConfig({ listPermission });
    }

    return (
      <div className="mb-3">
        <TableCollectionAgent
          data={collectionAgent}
          onHandleChangeTable={this.onChangeRowValue}
          // onSelectItem={this.onSelectTableRow}
          // rowSelect={rowSelectedItem}
          onRemove={this.handleClickRemoveRecord}
        />
        {modeCreateCollectionAgentConfig === 2 && (
          <FormAddCollectionAgent
            onChange={this.onChangeNewValue}
            // onRemove={this.handleClickRemoveRecord}
            itemNew={newAgent}
            onAddNew={this.handleClickAddNewRecord}
            modeCreateCollectionAgentConfig={modeCreateCollectionAgentConfig}
            isEnableRemove={isEmpty(rowSelectedItem)}
            isEnableAddBtn={!!(size(newAgent) > 2 && newAgent.userId && newAgent.firstName && newAgent.lastName)}
          />
        )}
        {modeModifyCollectionAgentConfig === 2 && (
          <div className="col-md-12 p-3 pb-5">
            <button
              type="button"
              className="button button-border x-small float-right"
              onClick={this.handleClickApplyButton}
            >
              {t('label.apply')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

CollectionAgent.defaultProps = {
  permissionsCollections: {},
};

CollectionAgent.propTypes = {
  getCollectionAgentByType: PropTypes.func.isRequired,
  createCollectionAgent: PropTypes.func.isRequired,
  modifyCollectionAgent: PropTypes.func.isRequired,
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCollectionAgentByType,
    createCollectionAgent,
    modifyCollectionAgent,
  })(CollectionAgent)
);
