import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { btnCollapse } from '../../../../constantsApp';
import FormCollapse from '../../../../components/form/FormCollapse';
import CollectionActions from './CollectionActions';
import CollectionSchedule from './CollectionScheduleNew';
import CollectionAgent from './CollectionAgent';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import {
  checkPermissionGetCollectionAgentConfig,
  checkPermissionGetCollectionActionConfig,
  checkPermissionGetCollectionSheduleConfig,
} from '../../CheckPermission';

class CollectionCofig extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: { name: btnCollapse.collections[0].state, isActive: true },
    };
  }

  componentDidMount() {}

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  renderItem = item => {
    switch (item.id) {
      case 1:
        return <CollectionActions />;
      case 2:
        return <CollectionSchedule />;
      case 3:
        return <CollectionAgent />;
      default:
        return null;
    }
  };

  render() {
    const { activeTab } = this.state;
    const { permissionsCollections } = this.props;
    let newcollectionsCollapse = cloneDeep(btnCollapse.collections);
    let modeGetCollectionAgentConfig = 0;
    let modeGetCollectionActionConfig = 0;
    let modeGetCollectionSheduleConfig = 0;
    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeGetCollectionAgentConfig = checkPermissionGetCollectionAgentConfig({ listPermission });
      modeGetCollectionActionConfig = checkPermissionGetCollectionActionConfig({ listPermission });
      modeGetCollectionSheduleConfig = checkPermissionGetCollectionSheduleConfig({ listPermission });
    }

    if (modeGetCollectionAgentConfig === 0) {
      newcollectionsCollapse = newcollectionsCollapse.filter(item => item.state !== 'agents');
    }

    if (modeGetCollectionActionConfig === 0) {
      newcollectionsCollapse = newcollectionsCollapse.filter(item => item.state !== 'actions');
    }

    if (modeGetCollectionSheduleConfig === 0) {
      newcollectionsCollapse = newcollectionsCollapse.filter(item => item.state !== 'schedule');
    }

    return (
      <div className="col-md-12 mb-30">
        {newcollectionsCollapse.map(item => (
          <FormCollapse
            key={item.title}
            isActive={item.state === activeTab.name && activeTab.isActive}
            title={item.title}
            state={item.state}
            onToggleTab={this.onToggleTab}
          >
            {this.renderItem(item)}
          </FormCollapse>
        ))}
      </div>
    );
  }
}

CollectionCofig.propTypes = {
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

CollectionCofig.defaultProps = {
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default connect(
  mapStateToProps,
  {}
)(CollectionCofig);
