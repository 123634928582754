import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../components/common';

class ExpressionList extends PureComponent {
  render() {
    const { data, isEditExp, onChangeExp, t } = this.props;
    return isEditExp ? (
      <GenericInput
        wrapperClass="col-md-12"
        name="expressionAddNew"
        type="textarea"
        value={data}
        onChange={onChangeExp}
      />
    ) : (
      <div className="col-md-12 row">
        <span className="data-exp">
          {` ${data.charAt(0) === '(' ? '' : '( '} ${data}${
            data.charAt(data.length - 1) === ')' ? '' : ' )'
          } `}
        </span>
      </div>
    );
  }
}

ExpressionList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  isEditExp: PropTypes.bool,
  onChangeExp: PropTypes.func,
  t: PropTypes.func,
};

ExpressionList.defaultProps = {
  isEditExp: false,
  onChangeExp: () => {},
  data: [],
  t: () => {},
};

export default withTranslation('common')(ExpressionList);
