import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectPriceOfferId } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { ModalOfferSearchForm } from '../../PricingHub';

class ModalPricingOffer extends Component {
  state = {};

  componentDidMount() {}

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
      ],
    };
    const { t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.choosePriceOffer')}
        isOpen={this.props.isOpen}
        SearchForm={ModalOfferSearchForm}
        searchItems={this.props.selectPriceOfferId}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectedItemId={this.props.selectedBundleId}
        defaultSearchParams={this.props.defaultSearchParams}
      />
    );
  }
}

ModalPricingOffer.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedBundleId: PropTypes.any,
  selectPriceOfferId: PropTypes.func,
  errorMessage: PropTypes.string,
  defaultSearchParams: PropTypes.object,
};

export default withTranslation('common')(connect(null, { selectPriceOfferId })(ModalPricingOffer));
