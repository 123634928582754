import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['accountType', 'accountSubType', 'salesChannel', 'marketSegment'])}`
    : '';
  const getSubscriptionContractExpiryReport = `getSubscriptionContractExpiryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getSubscriptionContractExpiryReport} {
        organization
        firstName
        lastName
        sellingCompany
        termEndDate
        salesChannel
        marketSegment
        accountType
        accountSubType
      }
    }
  `;
};
