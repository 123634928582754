/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getPaymentItemMapModal } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalPaymentItemMap extends PureComponent {
  searchHanldeRateUnitId = (payload, cb) => {
    const { getPaymentItemMapModal } = this.props;
    getPaymentItemMapModal(resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'paymentType',
          label: 'common:label.fileType',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:batchPaymentType')().find(val => val.value === item.paymentType) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'fileName',
          label: 'common:label.fileName',
        },
        {
          name: 'itemId',
          label: 'common:label.itemId',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseItem')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeRateUnitId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="index"
      />
    );
  }
}

ModalPaymentItemMap.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getPaymentItemMapModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalPaymentItemMap.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getPaymentItemMapModal })(ModalPaymentItemMap));
