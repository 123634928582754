import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class SavedManualBillsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'label.clientId',
      name: 'clientId',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.scheduleStartDate',
      name: 'scheduleStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.scheduleEndDate',
      name: 'scheduleEndDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:manualInvoiceStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.accountCategory',
      name: 'accountCategory',
    },
  ];

  btnSpan = 'col-md-12';
}

SavedManualBillsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SavedManualBillsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SavedManualBillsSearchForm);
