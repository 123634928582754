import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  searchQuotes,
  getQuoteById,
  getQuote,
  saveQuote,
  updateQuoteStatus,
  convertQuote,
  associateQuoteToAccount,
  getQuoteDetailsViewer,
} from '../../api';
import { queryRequest, mutationRequest } from '../../utils/request';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* searchQuotesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchQuotes({ page, size, filter, sort }));
    if (response.searchQuotes) {
      yield put(actions.searchQuotesSuccess(response.searchQuotes));
    } else {
      yield put(actions.searchQuotesSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getQuoteDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getQuoteById(payload));
    if (response.getQuoteById) {
      if (cb) cb({ success: true, data: response.getQuoteById });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* saveQuoteSaga({ payload, cb, isComponent }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveQuote(payload));
    if (response.saveQuote) {
      if (cb) cb({ success: true, data: response.saveQuote.id });
      if (isComponent) {
        toast.success(i18n.t('common:apiMessage.saveQuoteSuccessfully'));
      } else {
        toast.success(i18n.t('common:apiMessage.createQuoteSuccessfully'));
      }
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* getQuoteSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, getQuote(payload));
    if (response.getQuote) {
      if (cb) cb({ success: true, data: response.getQuote });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* updateQuoteStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateQuoteStatus(payload));
    if (response.updateQuoteStatus) {
      if (cb) cb({ success: true, data: response.updateQuoteStatus });
      toast.success(i18n.t('common:apiMessage.updateQuoteStatusSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* convertQuoteSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, convertQuote(payload));
    if (response.convertQuote) {
      if (cb) cb({ success: true, data: response.convertQuote });
      toast.success(i18n.t('common:apiMessage.convertQuoteSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* associateQuoteToAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, associateQuoteToAccount(payload));
    if (response.associateQuoteToAccount) {
      if (cb) cb({ success: true, data: response.associateQuoteToAccount });
      toast.success(i18n.t('common:apiMessage.associateQuoteToAccountSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* getQuoteDetailViewersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getQuoteDetailsViewer(payload));
    if (response.getQuoteById) {
      if (cb) cb({ success: true, data: response.getQuoteById });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* quotesSaga() {
  yield takeLatest(types.SEARCH_QUOTE, searchQuotesSaga);
  yield takeLatest(types.GET_QUOTE_DETAILS, getQuoteDetailsSaga);
  yield takeLatest(types.GET_QUOTE, getQuoteSaga);
  yield takeLatest(types.SAVE_QUOTE, saveQuoteSaga);
  yield takeLatest(types.UPDATE_QUOTE_STATUS, updateQuoteStatusSaga);
  yield takeLatest(types.CONVERT_QUOTE, convertQuoteSaga);
  yield takeLatest(types.ASSOCIATE_QUOTE_TO_ACCOUNT, associateQuoteToAccountSaga);
  yield takeLatest(types.GET_QUOTE_DETAILS_VIEWER, getQuoteDetailViewersSaga);
}
