import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SideBar } from '../../components/common';
import RouteNames from '../App/RouteNames';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, permissionsCorrespondence } = this.props;
    const childConfig = [];
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsCorrespondence, listKeys: permissionList.correspondence }))
      return null;
    if (permissionsCorrespondence && permissionsCorrespondence.correspondenceModulePermissions) {
      const listPermission = permissionsCorrespondence.correspondenceModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    const { modeGetCorrespondenceTemplate } = permissions;
    if (modeGetCorrespondenceTemplate) {
      childConfig.push({
        icon: 'ti-shield',
        label: 'correspondencePage:sidebar.templates',
        href: RouteNames.correspondenceConfigTemplate.path,
      });
    }
    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'correspondencePage:sidebar.config',
          href: '',
          childs: [...childConfig],
        },
      ],
    };
    return <SideBar {...sideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsOpsHub = store.get('auth').toJS().permissionsApp.opsHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsCorrespondence: permissionsOpsHub.length
      ? permissionsOpsHub.filter(a => a.moduleName === 'CORRESPONDENCE')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsPayment: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
