import gql from 'graphql-tag';

export default () => gql`
  query {
    getRateUnitSvcTxnMapCfg(dummy: "") {
      id
      rateUnitSvcTxnMapListCfg {
        index
        serviceType
        transactionType
        usageClass
        rateUnitCode
      }
    }
  }
`;
