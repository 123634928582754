import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, ['sharingType']);
  return gql`
  query {
    getSharingGroup(input: ${dataSearch}) {
      id
      accountId
      subscriptionId
      priceOfferId
      priceOfferName
      status
      sharingType
      discountOffers {
        index
        discountOfferId
        discountOfferName
      }
    }
  }
`;
};
