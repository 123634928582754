import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { map, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { month2Translation, formatMoneyValue, getMaxMinData, formatNumberValue } from '../../../utils/utils';

const AreaCharts = ({
  title,
  wrapperClass,
  data,
  keySeries,
  keyCategories,
  background,
  style,
  dataType,
  isNeedSupportYear,
  isToolbar,
  subClass,
  isDescription,
  minWidthYAxis,
  offsetX,
  textAnchor,
  fontSizeLabel,
}) => {
  const { t } = useTranslation('common');
  // const listInteger = [];
  const options = {
    xaxis: {
      categories:
        data && data.length > 0
          ? map(data, item =>
              isNeedSupportYear
                ? `${month2Translation(item[keyCategories], t)}-${moment().format('YYYY')}`
                : month2Translation(item[keyCategories], t)
            )
          : [],
    },
    yaxis: {
      labels: {
        align: 'right',
        minWidth: minWidthYAxis || 70,
        formatter: (val, index) => {
          setTimeout(() => {
            const dom = document.getElementsByTagName('tspan');
            const domLine = document.getElementsByTagName('line');
            if (dom) {
              for (let i = 0; i < dom.length; i++) {
                if (dom && dom[i] && dom[i].textContent === 'NaN') dom[i].textContent = '';
              }
            }
            if (domLine) {
              for (let i = 0; i < domLine.length; i++) {
                if (domLine[i].getAttribute('x1') !== '0') {
                  domLine[i].classList.add('embrix-apexcharts-xcrosshairs');
                }
              }
            }
          }, 100);
          if (dataType === 'AMOUNT' || dataType === 'PERCENT') {
            return Number.parseFloat(val) === val ? `$${formatMoneyValue(val)}` : '';
          }
          return Number.parseFloat(val) === val ? formatNumberValue(val) : '';
        },
        offsetX: offsetX || -15,
        style: {
          cssClass: 'embrix-yaxis-label',
          zIndex: 2,
        },
      },
    },
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      toolbar: {
        show: !!isToolbar,
        export: {
          csv: {
            filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
            columnDelimiter: ',',
            headerCategory: keyCategories || 'category',
            headerValue: keySeries || 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          },
          png: {
            filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          },
        },
      },
    },
    colors: [background || '#545454'],
    dataLabels: {
      enabled: true,
      formatter: (value, { seriesIndex, dataPointIndex, w }) => {
        if (dataType === 'AMOUNT' || dataType === 'PERCENT') {
          return `${formatMoneyValue(value)}`;
        }
        return value;
      },
      style: {
        fontSize: data.length > 10 ? fontSizeLabel || '7px' : '12px',
      },
      textAnchor: textAnchor || 'middle',
    },
    markers: {
      size: 3,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [50, 100, 200],
      },
    },

    tooltip: {
      enabled: true,
      theme: 'dark',
      custom({ series, seriesIndex, dataPointIndex, w }) {
        return (
          `${'<div class="arrow_box p-3">' + '<span>'}Value: ${
            dataType === 'AMOUNT'
              ? formatMoneyValue(series[seriesIndex][dataPointIndex])
              : series[seriesIndex][dataPointIndex]
          }</span>` + '</div>'
        );
      },
    },

    noData: {
      text: 'No data to present',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        // color: 'red',
        fontSize: '13px',
        fontFamily: "'Poppins', sans-serif",
        fontStyle: 'italic',
      },
    },
  };

  let typeOfData = 'C';
  if (dataType === 'AMOUNT') {
    typeOfData = '$';
  }
  if (dataType === 'PERCENT') {
    typeOfData = '%';
  }

  const sortData = cloneDeep(data);
  const { max, min, avg } = getMaxMinData(
    map(sortData, item => item[keySeries]),
    dataType === 'AMOUNT'
  );

  useEffect(() => {
    const dom = document.getElementsByTagName('tspan');
    if (dom) {
      for (let i = 0; i < dom.length; i++) {
        if (dom && dom[i] && dom[i].textContent === 'NaN') dom[i].textContent = '';
      }
    }
  }, []);

  return (
    <div className={wrapperClass || 'col-md-4'} style={style}>
      <div className={`mixed-chart ${isDescription ? 'show-description' : ''}`}>
        <h5 className={`p-2 text-center card-title no-border ${subClass || ''}`}>{title}</h5>
        {isDescription && (
          <div className="chart-value">
            <div className={`d-flex ${subClass || ''}`}>
              <div className="number-chart-details max-number">{max}</div>
              <div className="title-number">{t('label.max')}</div>
            </div>
            <div className={`d-flex ${subClass || ''}`}>
              <div className="number-chart-details min-number">{min}</div>
              <div className="title-number">{t('label.min')}</div>
            </div>
            <div className={`d-flex ${subClass || ''}`}>
              <div className="number-chart-details avg-number">{avg}</div>
              <div className="title-number">{t('label.avg')}</div>
            </div>
          </div>
        )}
        <ReactApexChart
          options={options}
          className={`embrix-chart ${subClass || ''} ${sortData.length ? '' : 'no-data-chart'} ${
            data && data.length === 1 ? 'embrix-one-line' : ''
          }`}
          series={
            sortData && sortData.length ? [{ name: title, data: [...map(sortData, item => item[keySeries])] }] : []
          }
          width="100%"
          type="area"
        />
      </div>
    </div>
  );
};

AreaCharts.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  keySeries: PropTypes.string,
  keyCategories: PropTypes.string,
  dataType: PropTypes.string,
};

AreaCharts.defaultProps = {
  title: '',
  wrapperClass: '',
  keySeries: '',
  keyCategories: '',
  data: [],
  dataType: 'COUNT',
};

export default AreaCharts;
