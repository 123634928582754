import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import RouteNames from '../../App/RouteNames';
import {
  getOperatorMapCfg,
  modifyOperatorMapCfg,
  createOperatorMapCfg,
  searchConfigOperatorMapList,
  searchConfigOperatorData,
  searchConfigOperatorProvisioning,
  searchConfigOperatorSms,
  searchConfigOperatorVoice,
} from '../actions';
import { makeGetOperatorMap } from '../selectors';
import {
  FormWithTableItem,
  DataTable,
  CollapsibleTable,
  GenericInput,
  TablePagination,
} from '../../../components/common';
import { getPageTotalCount } from '../../../utils/utils';
import {
  checkPermissionCreateOperatorMapConfig,
  checkPermissionModifyOperatorMapConfig,
  checkPermissionGetOperatorMapConfig,
} from '../CheckPermission';
import { OperatorMapSearchForm } from '../../../components/BaseConfigurationsHub';

const sortTypes = {
  operatorId: {
    asc: 'operatorId_ASC',
    desc: 'operatorId_DESC',
  },
  operatorName: {
    asc: 'operatorName_ASC',
    desc: 'operatorName_DESC',
  },
};
class OperatorMap extends PureComponent {
  state = {
    page: 0,
    size: 20,
    filter: { serviceType: 'VOICE' },
    sort: '',
    sorted: {},
    totalCount: null,
    defaultDataFromAPI: [],
    listOperatorMap: [],
    isSearching: true,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    rateUnitConfig: [],
    isOnChange: false,
    activeSubTab: {},
    pricingData: [],
    provisioningData: [],
    pageOperatorProvisioning: 0,
    sizeOperatorProvisioning: 20,
    pageOperatorPricing: 0,
    sizeOperatorPricing: 20,
    totalCountPricing: null,
    totalCountProvisioning: null,
    oldPricingData: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.listOperatorMap) return null;
    return {
      listOperatorMap: props.listOperatorMap,
      totalCount: getPageTotalCount({ ...state, items: props.listOperatorMap }),
      totalCountPricing: getPageTotalCount({
        totalCount: state.totalCountPricing,
        size: state.sizeOperatorPricing,
        items: state.pricingData,
        page: state.pageOperatorPricing,
      }),
      totalCountProvisioning: getPageTotalCount({
        totalCount: state.totalCountProvisioning,
        size: state.sizeOperatorProvisioning,
        items: state.provisioningData,
        page: state.pageOperatorProvisioning,
      }),
      oldData: props.listOperatorMap,
      dataSubmit: [],
      isOnChange: false,
    };
  }

  componentDidMount() {
    this.doGetOperatorMapCfg();
  }

  onChangeTable = ({ name, value, index }) => {
    const { listOperatorMap, pricingData, provisioningData } = this.state;
    const newData = cloneDeep(listOperatorMap);
    let newPricingData = cloneDeep(pricingData);
    let newProvisioningData = cloneDeep(provisioningData);
    if (name === 'operatorId') {
      newPricingData = newPricingData.map(val => {
        const { operatorId, ...rest } = val;
        return { ...rest, operatorId: value };
      });
      newProvisioningData = newProvisioningData.map(val => {
        const { operatorId, ...rest } = val;
        return { ...rest, operatorId: value };
      });
    }
    newData[index][name] = value;
    newData[index].isModify = true;
    this.setState({
      listOperatorMap: newData,
      isOnChange: true,
      pricingData: newPricingData,
      provisioningData: newProvisioningData,
    });
  };

  doGetOperatorMapCfg = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchConfigOperatorMapList } = this.props;
    if (!filter.serviceType) filter.serviceType = 'VOICE';
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({
      isSearching: true,
      listOperatorMap: [],
      activeSubTab: {},
      pricingData: [],
      provisioningData: [],
      pageOperatorProvisioning: 0,
      sizeOperatorProvisioning: 20,
      pageOperatorPricing: 0,
      sizeOperatorPricing: 20,
      totalCountPricing: null,
      totalCountProvisioning: null,
      oldPricingData: [],
    });
    searchConfigOperatorMapList(payload, ({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.length) });
    });
  };

  doSearchConfigOperatorProvisioning = () => {
    const { searchConfigOperatorProvisioning } = this.props;
    const { pageOperatorProvisioning, sizeOperatorProvisioning, listOperatorMap, activeSubTab } = this.state;
    const payload = {
      page: pageOperatorProvisioning + 1,
      size: sizeOperatorProvisioning,
      filter: {
        operatorId:
          listOperatorMap && listOperatorMap[activeSubTab.index]
            ? listOperatorMap[activeSubTab.index].operatorId
            : null,
      },
    };
    searchConfigOperatorProvisioning(payload, ({ success, data }) => {
      if (success) this.setState({ provisioningData: data || [] });
    });
  };

  doSearchConfigOperatorData = () => {
    const { searchConfigOperatorData } = this.props;
    const { pageOperatorPricing, sizeOperatorPricing, listOperatorMap, activeSubTab, pricingData } = this.state;
    let { oldPricingData } = this.state;
    const payload = {
      page: pageOperatorPricing + 1,
      size: sizeOperatorPricing,
      filter: {
        operatorId:
          listOperatorMap && listOperatorMap[activeSubTab.index]
            ? listOperatorMap[activeSubTab.index].operatorId
            : null,
      },
    };
    searchConfigOperatorData(payload, ({ success, data }) => {
      if (success) {
        if (pricingData && pricingData.length && pricingData[0]?.operatorId) {
          oldPricingData = oldPricingData.filter(val => val?.operatorId !== pricingData[0]?.operatorId);
          oldPricingData = [...oldPricingData, ...pricingData];
        }
        this.setState({
          pricingData:
            data && data.length
              ? data.map(val => {
                  const { id, ...rest } = val;
                  return rest;
                })
              : [],
          oldPricingData,
        });
      }
    });
  };

  doSearchConfigOperatorSms = () => {
    const { searchConfigOperatorSms, searchConfigOperatorVoice } = this.props;
    const { pageOperatorPricing, sizeOperatorPricing, listOperatorMap, activeSubTab, filter, pricingData } = this.state;
    let { oldPricingData } = this.state;
    const doGetData = filter?.serviceType === 'VOICE' ? searchConfigOperatorVoice : searchConfigOperatorSms;
    const payload = {
      page: pageOperatorPricing + 1,
      size: sizeOperatorPricing,
      filter: {
        operatorId:
          listOperatorMap && listOperatorMap[activeSubTab.index]
            ? listOperatorMap[activeSubTab.index].operatorId
            : null,
      },
    };
    doGetData(payload, ({ success, data }) => {
      if (success) {
        if (pricingData && pricingData.length && pricingData[0]?.operatorId) {
          oldPricingData = oldPricingData.filter(val => val?.operatorId !== pricingData[0]?.operatorId);
          oldPricingData = [...oldPricingData, ...pricingData];
        }
        this.setState({
          pricingData:
            data && data.length
              ? data.map(val => {
                  const { id, ...rest } = val;
                  return rest;
                })
              : [],
          oldPricingData,
        });
      }
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetOperatorMapCfg());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetOperatorMapCfg());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetOperatorMapCfg());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetOperatorMapCfg());
  };

  onAddNewOperatorMap = () => {
    const { listOperatorMap, dataSubmit } = this.state;
    let newData = cloneDeep(listOperatorMap);
    const newDataItem = {
      // index: -1,
      operatorId: null,
      operatorName: null,
      status: 'ACTIVE',
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    this.setState({ listOperatorMap: newData, dataSubmit, isOnChange: true });
  };

  onRemoveItemTable = ({ index }) => {
    try {
      const { listOperatorMap, dataSubmit } = this.state;
      const newData = cloneDeep(listOperatorMap);
      if (newData[index] && newData[index].index === -1) {
        newData.splice(index, 1);
      } else {
        newData[index] = {
          index: newData[index].index,
        };
      }
      this.setState({ listOperatorMap: newData, dataSubmit, isOnChange: true });
    } catch (error) {
      console.log(error);
    }
  };

  onHanldeSubmit = () => {
    const { isModify, listOperatorMap, filter, provisioningData, pricingData, oldPricingData } = this.state;
    const { modifyOperatorMapCfg, createOperatorMapCfg } = this.props;
    if (isModify) {
      let newPricingData = cloneDeep(pricingData);
      if (newPricingData && newPricingData.length) {
        newPricingData = newPricingData
          .filter(pr => !!pr.isModify)
          .map(dt => {
            const { isModify, ...rest } = dt;
            return rest;
          });
      }
      let newOldPricingData = cloneDeep(oldPricingData);
      if (newOldPricingData && newOldPricingData.length) {
        newOldPricingData = newOldPricingData
          .filter(pr => !!pr.isModify)
          .map(dt => {
            const { isModify, ...rest } = dt;
            return rest;
          });
      }
      const newPayloadModify = {
        configOperatorMapList: listOperatorMap
          .filter(i => !!i.isModify)
          .map(val => {
            const { id, isNew, isModify, ...rest } = val;
            return { ...rest };
          }),
        id:
          listOperatorMap && listOperatorMap[listOperatorMap.length - 1]
            ? listOperatorMap[listOperatorMap.length - 1].id
            : null,
        serviceType: filter && filter.serviceType ? filter.serviceType : 'VOICE',
        configOperatorSms:
          (filter && filter.serviceType === 'DATA') || (filter && filter.serviceType === 'VOICE')
            ? null
            : [...newOldPricingData, ...newPricingData],
        configOperatorData: filter && filter.serviceType === 'DATA' ? [...newOldPricingData, ...newPricingData] : null,
        configOperatorVoice:
          filter && filter.serviceType === 'VOICE' ? [...newOldPricingData, ...newPricingData] : null,
        configOperatorProvisioning: provisioningData,
      };
      modifyOperatorMapCfg(newPayloadModify, ({ success }) => {
        if (success) this.doGetOperatorMapCfg();
      });
    } else {
      const dataRemoveIndex = cloneDeep(listOperatorMap)
        .filter(i => !!i.isModify)
        .map(item => {
          const newItem = item;
          delete newItem.index;
          delete newItem.isNew;
          delete newItem.isModify;
          return newItem;
        });
      const newPayloadCreate = {
        configOperatorMapList: dataRemoveIndex,
        serviceType: filter && filter.serviceType ? filter.serviceType : 'VOICE',
        configOperatorSms:
          (filter && filter.serviceType === 'DATA') || (filter && filter.serviceType === 'VOICE')
            ? null
            : pricingData.map(val => {
                const { index, isModify, ...rest } = val;
                return { ...rest };
              }),
        configOperatorData:
          filter && filter.serviceType === 'DATA'
            ? pricingData.map(val => {
                const { index, isModify, ...rest } = val;
                return { ...rest };
              })
            : null,
        configOperatorVoice:
          filter && filter.serviceType === 'VOICE'
            ? pricingData.map(val => {
                const { index, isModify, ...rest } = val;
                return { ...rest };
              })
            : null,
        configOperatorProvisioning:
          provisioningData && provisioningData.length
            ? provisioningData.map(val => {
                const { index, ...rest } = val;
                return { ...rest };
              })
            : [],
      };
      createOperatorMapCfg(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetOperatorMapCfg();
        }
      });
    }
  };

  onToggleSubTab = (idx, item, key) => {
    const { activeSubTab, filter } = this.state;
    if (activeSubTab.index === idx && activeSubTab.key === key) this.setState({ activeSubTab: {} });
    if (activeSubTab.index !== idx || activeSubTab.key !== key) {
      this.setState(
        {
          activeSubTab: { key, index: idx },
        },
        () => {
          if (key === 'addProvisioning' && !item.isNew) {
            this.doSearchConfigOperatorProvisioning();
          }
          if (key === 'addPricing' && !item.isNew) {
            if (filter && filter.serviceType === 'DATA') {
              this.doSearchConfigOperatorData();
            } else {
              this.doSearchConfigOperatorSms();
            }
          }
        }
      );
    }
  };

  onAddNewProvisioning = () => {
    const { provisioningData, activeSubTab, listOperatorMap } = this.state;
    const newData = cloneDeep(provisioningData);
    let lastIndex = 0;
    if (newData) {
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
    }
    newData.push({
      // index: lastIndex + 1,
      // index: -1,
      operatorId:
        listOperatorMap && listOperatorMap[activeSubTab.index] ? listOperatorMap[activeSubTab.index].operatorId : null,
      provisioningId: null,
    });

    this.setState({ provisioningData: newData });
  };

  onAddNewPricing = () => {
    const { pricingData, activeSubTab, listOperatorMap, filter } = this.state;
    const newData = cloneDeep(pricingData);
    let lastIndex = 0;
    if (newData) {
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
    }

    if (filter && filter.serviceType === 'DATA') {
      newData.push({
        // index: lastIndex + 1,
        // index: -1,
        operatorId:
          listOperatorMap && listOperatorMap[activeSubTab.index]
            ? listOperatorMap[activeSubTab.index].operatorId
            : null,
        chargeType: null,
        tierMin: null,
        tierMax: null,
        tariff: null,
        contractCapacity: null,
        commitmentBase: null,
        rentalAmount: null,
        exchangeRate: null,
      });
    } else {
      newData.push({
        // index: lastIndex + 1,
        // index: -1,
        operatorId:
          listOperatorMap && listOperatorMap[activeSubTab.index]
            ? listOperatorMap[activeSubTab.index].operatorId
            : null,
        direction: null,
      });
    }

    this.setState({ pricingData: newData });
  };

  onPageChangePricing = page => {
    const { filter, pageOperatorPricing } = this.state;
    if (pageOperatorPricing === page) return '';
    return this.setState({ pageOperatorPricing: page }, () => {
      if (filter && filter.serviceType === 'DATA') {
        this.doSearchConfigOperatorData();
      } else {
        this.doSearchConfigOperatorSms();
      }
    });
  };

  onSizeChangePricing = size => {
    const { filter } = this.state;
    this.setState({ sizeOperatorPricing: size, pageOperatorPricing: 0 }, () => {
      if (filter && filter.serviceType === 'DATA') {
        this.doSearchConfigOperatorData();
      } else {
        this.doSearchConfigOperatorSms();
      }
    });
  };

  onChangePricing = ({ index, name, value }) => {
    const { pricingData } = this.state;
    const newData = cloneDeep(pricingData);
    try {
      newData[index][name] = value;
      newData[index].isModify = true;
      this.setState({ pricingData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProvisioning = ({ index, name, value }) => {
    const { provisioningData } = this.state;
    const newData = cloneDeep(provisioningData);
    try {
      newData[index][name] = value;
      this.setState({ provisioningData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onPageChangeProvisioning = page => {
    const { pageOperatorProvisioning } = this.state;
    if (pageOperatorProvisioning === page) return '';
    return this.setState({ pageOperatorProvisioning: page }, () => this.doSearchConfigOperatorProvisioning());
  };

  onSizeChangeProvisioning = size => {
    this.setState({ sizeOperatorProvisioning: size, pageOperatorProvisioning: 0 }, () =>
      this.doSearchConfigOperatorProvisioning()
    );
  };

  render() {
    const {
      listOperatorMap,
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      activeSubTab,
      filter,
      pricingData,
      provisioningData,
      pageOperatorPricing,
      sizeOperatorPricing,
      pageOperatorProvisioning,
      sizeOperatorProvisioning,
      totalCountPricing,
      totalCountProvisioning,
    } = this.state;

    const { t, permissionsPricing } = this.props;
    let modeCreateOperatorMap = 0;
    let modeModifyOperatorMap = 0;
    let modeGetOperatorMap = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateOperatorMap = checkPermissionCreateOperatorMapConfig({ listPermission: listPermissionPricing });
      modeModifyOperatorMap = checkPermissionModifyOperatorMapConfig({ listPermission: listPermissionPricing });
      modeGetOperatorMap = checkPermissionGetOperatorMapConfig({ listPermission: listPermissionPricing });
    }
    if (!modeGetOperatorMap) return '';
    const creditProfileColumns = [
      // {
      //   name: 'addProvisioning',
      //   label: 'label.addProvisioning',
      //   style: { minWidth: '200px' },
      //   render: (colName, item, idx, indexParent, activeTab) => (
      //     <div className="d-flex col-md-12">
      //       <button
      //         type="button"
      //         className="btn-expand-table mr-3"
      //         onClick={evt => this.onToggleSubTab(idx, item, 'addProvisioning')}
      //       >
      //         <i
      //           className={`fa ${
      //             activeSubTab && activeSubTab.key === 'addProvisioning' && activeSubTab.index === idx
      //               ? 'fa-minus'
      //               : 'fa-plus'
      //           }`}
      //         />
      //       </button>
      //     </div>
      //   ),
      // },
      {
        name: 'addPricing',
        label: 'label.addPricing',
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <div className="d-flex col-md-12">
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={() => this.onToggleSubTab(idx, item, 'addPricing')}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'addPricing' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          </div>
        ),
      },
      {
        name: 'operatorId',
        label: 'label.operatorId',
        sortable: true,
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.operatorId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="operatorId"
          />
        ),
      },
      {
        name: 'operatorName',
        label: 'label.operatorName',
        sortable: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.operatorName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="operatorName"
          />
        ),
      },
      {
        name: 'alternateName',
        label: 'label.alternateName',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.alternateName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="alternateName"
          />
        ),
      },
      {
        name: 'carrierId',
        label: 'label.carrierId',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.carrierId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="carrierId"
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="status"
            type="select"
            tOptions="selections:status"
            menuPortalTarget
          />
        ),
      },
    ];

    let tableColumnSMS = [
      {
        name: 'tariff',
        label: 'label.tariff',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.tariff}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="tariff"
            type="number"
          />
        ),
      },
    ];

    if (filter && (filter.serviceType === 'VOICE' || filter.serviceType === 'SMS')) {
      tableColumnSMS = [
        ...tableColumnSMS,
        {
          name: 'effectiveDate',
          label: 'label.effectiveDate',
          style: { textAlign: 'center', minWidth: '160px' },
          render: (colName, item, idx) => (
            <GenericInput
              value={item.effectiveDate}
              wrapperClass="col-md-12 inner-popover"
              onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
              name="effectiveDate"
              type="date"
              isClearable
            />
          ),
        },
      ];
    }
    tableColumnSMS = [
      ...tableColumnSMS,
      {
        name: 'direction',
        label: 'label.direction',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.direction}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="direction"
            type="select"
            tOptions="selections:usageDirection"
            menuPortalTarget
            isClearable
          />
        ),
      },
    ];

    if (filter && filter.serviceType === 'VOICE') {
      tableColumnSMS = [
        ...tableColumnSMS,
        {
          name: 'trafficType',
          label: 'label.trafficType',
          style: { textAlign: 'center', minWidth: '160px' },
          render: (colName, item, idx) => (
            <GenericInput
              value={item.trafficType}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
              tOptions="selections:trafficType"
              menuPortalTarget
              name="trafficType"
              type="select"
              isClearable
            />
          ),
        },
      ];
    }

    const tableColumnData = [
      {
        name: 'chargeType',
        label: 'label.chargeType',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.chargeType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="chargeType"
          />
        ),
      },
      {
        name: 'tierMin',
        label: 'label.tierMin',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.tierMin}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="tierMin"
            type="number"
          />
        ),
      },
      {
        name: 'tierMax',
        label: 'label.tierMax',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.tierMax}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="tierMax"
            type="number"
          />
        ),
      },
      {
        name: 'tariff',
        label: 'label.tariff',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.tariff}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="tariff"
            type="number"
          />
        ),
      },
      {
        name: 'contractCapacity',
        label: 'label.contractCapacity',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.contractCapacity}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="contractCapacity"
            type="number"
          />
        ),
      },
      {
        name: 'commitmentBase',
        label: 'label.commitmentBase',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.commitmentBase}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="commitmentBase"
            type="number"
          />
        ),
      },
      {
        name: 'rentalAmount',
        label: 'label.rentalAmount',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.rentalAmount}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="rentalAmount"
            type="number"
          />
        ),
      },
      // {
      //   name: 'currency',
      //   label: 'label.currency',
      //   style: { textAlign: 'center', minWidth: '160px' },
      //   render: (colName, item, idx) => (
      //     <GenericInput
      //       value={item.currency}
      //       wrapperClass="col-md-12"
      //       onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
      //       name="currency"
      //     />
      //   ),
      // },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.exchangeRate}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangePricing({ name, value, index: idx })}
            name="exchangeRate"
            type="number"
          />
        ),
      },
    ];

    const tableColumnProvisioning = [
      {
        name: 'provisioningId',
        label: 'label.provisioningId',
        style: { width: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.provisioningId}
            wrapperClass="col-md-3"
            onChange={({ name, value }) => this.onChangeProvisioning({ name, value, index: idx })}
            name="provisioningId"
          />
        ),
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: `${t('label.service')} - ${t('baseConfigurationsPage:sidebar.operatorMap')}` },
          ]}
          isNoDefaultButton
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <OperatorMapSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormWithTableItem title={`${t('label.service')} - ${t('baseConfigurationsPage:sidebar.operatorMap')}`}>
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateOperatorMap === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={this.onAddNewOperatorMap}
              >
                +
                {t('label.addNewOperatorMap')}
              </button>
            )}
            {modeModifyOperatorMap === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetOperatorMapCfg()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            {/* <DataTable
              columns={creditProfileColumns}
              onSort={this.onSortColumn}
              data={listOperatorMap}
              isLoading={isSearching}
              sorted={sorted}
              isSupportRemoveIndex
            /> */}
            <CollapsibleTable
              columns={creditProfileColumns}
              indexViewer={
                activeSubTab &&
                (activeSubTab.key === 'addPricing' || activeSubTab.key === 'addProvisioning') &&
                activeSubTab.index !== -1
                  ? activeSubTab.index
                  : -1
              }
              sorted={sorted}
              onSort={this.onSortColumn}
              onClickRow={() => {}}
              data={listOperatorMap && listOperatorMap.length ? listOperatorMap : []}
              isSupportRemoveIndex
              isFixedHeaderTable
            >
              {activeSubTab.key === 'addPricing' && (!filter || !filter.serviceType || filter.serviceType !== 'DATA') && (
                <div>
                  <DataTable
                    columns={tableColumnSMS}
                    data={pricingData}
                    isLoading={isSearching}
                    sorted={sorted}
                    isSupportRemoveIndex
                  />
                  {/* {filter?.serviceType !== 'VOICE' && ( */}
                  <button
                    type="button"
                    onClick={this.onAddNewPricing}
                    className="button mb-4 button-border ml-3 black x-small"
                  >
                    +
                    {t('label.addNewRow')}
                  </button>
                  <div className="mb-3">
                    <TablePagination
                      pageNumber={pageOperatorPricing}
                      pageSize={sizeOperatorPricing}
                      totalCount={totalCountPricing}
                      onPageChange={this.onPageChangePricing}
                      onSizeChange={this.onSizeChangePricing}
                    />
                  </div>
                </div>
              )}
              {activeSubTab.key === 'addPricing' && filter && filter.serviceType === 'DATA' && (
                <div>
                  <DataTable
                    columns={tableColumnData}
                    data={pricingData}
                    isLoading={isSearching}
                    sorted={sorted}
                    isSupportRemoveIndex
                  />
                  <button
                    type="button"
                    onClick={this.onAddNewPricing}
                    className="button mb-4 button-border ml-3 black x-small"
                  >
                    +
                    {t('label.addNewRow')}
                  </button>
                  <div className="mb-3">
                    <TablePagination
                      pageNumber={pageOperatorPricing}
                      pageSize={sizeOperatorPricing}
                      totalCount={totalCountPricing}
                      onPageChange={this.onPageChangePricing}
                      onSizeChange={this.onSizeChangePricing}
                    />
                  </div>
                </div>
              )}
              {activeSubTab.key === 'addProvisioning' && (
                <div>
                  <DataTable
                    columns={tableColumnProvisioning}
                    data={provisioningData}
                    isLoading={isSearching}
                    sorted={sorted}
                    isSupportRemoveIndex
                  />
                  <button
                    type="button"
                    onClick={this.onAddNewProvisioning}
                    className="button mb-4 button-border ml-3 black x-small"
                  >
                    +
                    {t('label.addNewRow')}
                  </button>
                  <div className="mb-3">
                    <TablePagination
                      pageNumber={pageOperatorProvisioning}
                      pageSize={sizeOperatorProvisioning}
                      totalCount={totalCountProvisioning}
                      onPageChange={this.onPageChangeProvisioning}
                      onSizeChange={this.onSizeChangeProvisioning}
                    />
                  </div>
                </div>
              )}
            </CollapsibleTable>
          </div>
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

OperatorMap.propTypes = {
  searchConfigOperatorMapList: PropTypes.func.isRequired,
  searchConfigOperatorProvisioning: PropTypes.func.isRequired,
  modifyOperatorMapCfg: PropTypes.func.isRequired,
  createOperatorMapCfg: PropTypes.func.isRequired,
  searchConfigOperatorData: PropTypes.func.isRequired,
  searchConfigOperatorSms: PropTypes.func.isRequired,
  searchConfigOperatorVoice: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
};

OperatorMap.defaultProps = {
  permissionsPricing: {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  listOperatorMap: makeGetOperatorMap() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getOperatorMapCfg,
    modifyOperatorMapCfg,
    createOperatorMapCfg,
    searchConfigOperatorMapList,
    searchConfigOperatorData,
    searchConfigOperatorProvisioning,
    searchConfigOperatorSms,
    searchConfigOperatorVoice,
  })(OperatorMap)
);
