import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../utils/utils';
import { adjustmentSort } from '../../../constantsApp';
import { DataTable, TablePagination, ModalWithItem, AccountHeaderFrom } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { AdjustmentsSearchForm } from '../../../components/ArOperationsHub';
import ArOperationsDetails from './ArOperationsDetails';
import Footer from '../../../components/Footer';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import { makeGetListAdjustments, makePageAdjustmentParams, makeErrorMessageAdjustments } from '../selectors';
import { searchArOpsRequests, getArOpsRequestById } from '../actions';
import {
  checkPermissionSearchAdjustment,
  checkPermissionGetAdjustmentDetails,
  checkPermissionProcessAdjustment,
} from '../CheckPermission';
import { adjustmentFieldDetails } from '../constant';
import ArActivity from '../common/ArActivity';

class Adjustments extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isSearching: false,
      filter: {},
      sort: '',
      sorted: {},
      page: 0,
      size: 20,
      totalCount: null,
      isOpenModalItem: false,
      detailsData: {},
      itemDetailsSelect: null,
      listData: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.listData }),
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchArOpsRequests();
      });
    } else {
      this.doSearchArOpsRequests();
    }
  }

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchArOpsRequests());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchArOpsRequests());
  };

  doSearchArOpsRequests = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchArOpsRequests } = this.props;
    const newFilter = cloneDeep(filter);
    newFilter.action = 'ADJUSTMENT';
    this.setState({ isSearching: true });
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? adjustmentSort[sorted.sortCol][sorted.sortDir] : null,
    };

    searchArOpsRequests(payload, ({ data }) => {
      this.setState({ isSearching: false, listData: data || [] });
    });
  };

  onHandleSearch = filter => {
    this.setState({ filter, page: 0 }, () => {
      this.doSearchArOpsRequests();
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchArOpsRequests());
  };

  onToggleModal = () => {
    const { isOpenModalItem } = this.state;
    this.setState({ isOpenModalItem: !isOpenModalItem });
  };

  onToggleModalDetails = () => {
    this.setState({ itemDetailsSelect: null });
  };

  onViewRequestDetails = ({ item }) => {
    const { getArOpsRequestById } = this.props;
    getArOpsRequestById(item.id, ({ success, data }) => {
      if (success) this.setState({ isOpenModalItem: true, detailsData: data || {} });
    });
  };

  onViewItemDetails = ({ item }) => {
    this.setState({ itemDetailsSelect: item || null });
  };

  render() {
    const { errorMessage, permissionsArOps, t, location } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      isOpenModalItem,
      detailsData,
      itemDetailsSelect,
      listData,
      filter,
    } = this.state;
    let modeSearchAdjustment = 0;
    let modeGetAdjustmentDetails = 0;
    let modeApplyAdjustment = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeSearchAdjustment = checkPermissionSearchAdjustment({ listPermission });
      modeGetAdjustmentDetails = checkPermissionGetAdjustmentDetails({ listPermission });
      modeApplyAdjustment = checkPermissionProcessAdjustment({ listPermission });
    }

    const tableColumns = [
      {
        name: 'id',
        label: 'common:label.requestId',
        render: (colName, item) => (
          <button className="text-success no-border" type="button" onClick={() => this.onViewItemDetails({ item })}>
            {item.id || ''}
          </button>
        ),
        // sortable: true,
      },
      {
        name: 'accountId',
        label: 'common:label.accountId',
        // render: (colName, item) => (
        //   <button className="text-success no-border" type="button" onClick={() => this.onViewItemDetails({ item })}>
        //     {item.accountId || ''}
        //   </button>
        // ),
        sortable: true,
      },
      {
        name: 'userId',
        label: 'common:label.userId',
        sortable: true,
      },
      {
        name: 'itemId',
        label: 'common:label.itemId',
        sortable: true,
      },
      {
        name: 'invoiceId',
        label: 'common:label.invoiceId',
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
      },
      {
        name: 'source',
        label: 'common:label.source',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:source')().find(val => val.value === item.source) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t && item.reason ? t('selections:reasonConfig')().find(val => val.value === item.reason) : null;
          return <span>{slt ? slt.label : item?.customReason || null}</span>;
        },
      },
      {
        name: 'type',
        label: 'common:label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'crDrType',
        label: 'common:label.adjustmentType',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:CRDRType')().find(val => val.value === item.crDrType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'amount',
        label: 'common:label.amount',
      },
      {
        name: 'percent',
        label: 'common:label.percent',
      },
      {
        name: 'action',
        label: 'common:label.request',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            className="btn btn-outline-success btn-sm"
            type="button"
            onClick={() => this.onViewRequestDetails({ item })}
          >
            {t ? t('common:label.view') : ''}
          </button>
        ),
      },
      {
        name: 'details',
        label: 'common:label.details',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            className="btn btn-outline-success btn-sm"
            type="button"
            onClick={() => this.onViewItemDetails({ item })}
          >
            {t ? t('common:label.view') : ''}
          </button>
        ),
      },
    ];

    const newTableColumns = cloneDeep(tableColumns);
    if (!modeGetAdjustmentDetails) {
      newTableColumns.splice(0, 1);
      newTableColumns.splice(newTableColumns.length - 1, 1);
      newTableColumns.splice(newTableColumns.length - 1, 1);
    }

    const invoicesColumns = [
      {
        name: 'name',
        label: 'common:label.name',
      },
      {
        name: 'transactionId',
        label: 'common:label.transactionId',
      },
      {
        name: 'amount',
        label:
          detailsData.type === 'INVOICE_LINE_ADJUSTMENT' ? 'common:label.invoiceLineAmount' : 'common:label.lineAmount',
      },
      {
        name: 'percent',
        label: 'common:label.percentAdjustment',
      },
    ];

    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={
            modeSearchAdjustment === 0 || modeApplyAdjustment === 0
              ? '/'
              : { pathname: RouteNames.arOperationsAdjustmentApply.path, state: { filter } }
          }
          titleBtn={
            modeSearchAdjustment === 0 || modeApplyAdjustment === 0
              ? t('label.back')
              : `+ ${t('label.applyAdjustment')}`
          }
          disabled={modeApplyAdjustment === 1}
          items={[{ name: t('arOperationPage:sidebar.adjustments'), url: RouteNames.arOperationsAdjustments.path }]}
        />
        <br />
        {modeSearchAdjustment !== 0 && (
          <div>
            <div className="col-md-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <div className="repeater-file">
                    <AdjustmentsSearchForm
                      onSubmit={this.onHandleSearch}
                      defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <DataTable
                    columns={newTableColumns}
                    data={listData}
                    isSubmitting={isSearching}
                    isLoading={isSearching}
                    onSort={this.onSortColumn}
                    sorted={sorted}
                    errorMessage={errorMessage}
                  />
                </div>
              </div>
            </div>
            <div className="mb-30">
              <TablePagination
                pageNumber={page}
                pageSize={size}
                totalCount={totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
            <Footer className="footer-bottom" />
          </div>
        )}
        <ModalWithItem
          wrapperClass="modal-70 modal-custom"
          modalTitle={t('label.adjustmentRequestDetails')}
          isOpen={isOpenModalItem}
          onToggle={this.onToggleModal}
        >
          <AccountHeaderFrom accountNum={detailsData?.accountId || ''} />
          <div className="col-md-12 mb-30 mt-3">
            <ArOperationsDetails
              listFields={adjustmentFieldDetails}
              data={detailsData}
              isDisabledAllField
              onChange={() => {}}
              onCancel={this.onToggleModal}
              invoiceLinesColums={invoicesColumns}
            />
          </div>
        </ModalWithItem>
        <ModalWithItem
          wrapperClass="modal-70 modal-custom"
          modalTitle={t('label.adjustmentDetails')}
          isOpen={!!itemDetailsSelect}
          onToggle={this.onToggleModalDetails}
        >
          <AccountHeaderFrom accountNum={itemDetailsSelect?.id} />
          <div className="col-md-12 mb-30">
            <ArActivity
              id={itemDetailsSelect?.id}
              type={
                itemDetailsSelect?.type && itemDetailsSelect?.type === 'UNBILLED_LINE_ADJUSTMENT'
                  ? 'UNBILLED_LINE_ADJUSTMENT'
                  : null
              }
              onCancel={this.onToggleModalDetails}
            />
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

Adjustments.propTypes = {
  listAdjustments: PropTypes.arrayOf(PropTypes.any),
  searchArOpsRequests: PropTypes.func,
  errorMessage: PropTypes.string,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

Adjustments.defaultProps = {
  searchArOpsRequests: () => {},
  listAdjustments: [],
  errorMessage: '',
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  listAdjustments: makeGetListAdjustments() || [],
  params: makePageAdjustmentParams() || {},
  errorMessage: makeErrorMessageAdjustments() || '',
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { searchArOpsRequests, getArOpsRequestById })(Adjustments)
);
