
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';

const CardStatistics = ({ children})=> {
  return (
    <div className="card card-statistics h-100 ">
      <div className="card-body">
        <div className="repeater-file">
          {children}
        </div>
      </div>
    </div>    
  )
}

CardStatistics.propTypes = {
  

};

CardStatistics.defaultProps = {
  
  
};

export default CardStatistics