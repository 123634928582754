/**
 * Collections selectors
 */

import { createSelector } from 'reselect';

const selectTaxation = state => state.get('taxationReducer');

const makeGetListTaxCode = () => createSelector(selectTaxation, items => items.get('taxCodeConfig'));

const makeGet3rdPartytaxConfig = () => createSelector(selectTaxation, items => items.get('configCodes'));

export { makeGetListTaxCode, makeGet3rdPartytaxConfig };
