import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';
import customerVNS from './constants/customerVNS';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const newCustomerVNS = customerVNS.filter(en => en !== 'accountCategory');
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, newCustomerVNS)}` : '';
  const searchAccounts = `searchAccounts(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchAccounts} {
        id
        currency
        status
        parentId
        userId
        reason
        type
        salesAgentId
        nonPaying
        clientId
        taxId
        foreignTaxId
        effectiveDate
        collectionAgent
        name
        groupCompany
        accountCategory
        contacts {
          firstName
          lastName
          organization
          email
        }
        addresses {
          city
          state
        }  
      }
    }
  `;
};
