import React from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { paymentSuspenseSort } from '../../../../constantsApp';
import {
  FormWithTableItem,
  DataTable,
  TablePagination,
  GenericInput,
  ModalWithItem,
  ModalAccept,
} from '../../../../components/common';
import { makeGetlistPaymentSuspense, makePagePaymentSuspenseParams } from '../../selectors';
import CheckGroup from '../../../../components/common/CheckGroup';
import { SuspenseSearchForm } from '../../../../components/PaymentHub';
import { tableColumnsPaymentSuspense, messageNotes } from '../../constant';
import { getPageTotalCount, formatMoneyValue, configOutputTemplateByType } from '../../../../utils/utils';
import ModalSelectItemId from '../OneOffPayment/ModalSelectItemId';
import ModalInvoice from '../OneOffPayment/ModalInvoice';
import {
  searchPaymentSuspenseReport,
  applyPaymentSuspense,
  modifyPaymentSuspense,
  getPaymentItemMap,
  getCrossCurrencyPaymentDetails,
} from '../../actions';
import RouteNames from '../../../App/RouteNames';
import {
  getObjectFileById,
  getCurrencyConfigApp,
  getBatchPaymentFileConfigSelect,
  getOutputTemplateByType,
  getCcpPropertiesConfig,
} from '../../../App/actions';
import PageTitle from '../../../../components/PageTitle';
import { makeGetPermissionsPaymentManagement } from '../../../App/selectors';
import { checkPermissionSearchPaymentSuspense, checkPermissionProcessPaymentSuspense } from '../../CheckPermission';
// import ModalViewAllocation from '../../../../components/common/ModalViewAllocation';
import ModalViewPaymentActivity from '../../../../components/common/ModalViewPaymentActivity';
import { getUserId } from '../../../../localStorages';
import { statusDisableFielsdViewFolio } from '../../../../utils/constants';

let tableColumnsDefault = null;

const paymentDetails = [
  {
    name: 'accountId',
    label: 'common:label.accountNumber',
  },
  {
    name: 'paymentDate',
    label: 'common:label.paymentDate',
  },
  {
    name: 'amount',
    label: 'common:label.paymentAmount',
    type: 'number',
  },
  {
    name: 'unallocatedAmount',
    label: 'common:label.unallocatedAmount',
    type: 'number',
  },
  {
    name: 'sourceCurrency',
    label: 'common:label.paymentCurrency',
  },
];

class PaymentSuspense extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      page: 0,
      size: 20,
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      selectedPayment: {},
      filter: { status: 'OPEN' },
      accountId: '',
      invoiceId: '',
      isOpenModalAccount: false,
      isOpenModalInvoice: false,
      dataPaymentSuspense: [],
      isOpenModalSusppense: false,
      listItems: [],
      itemSelectIvoiceId: null,
      isOpenModalViewPaymentActivity: false,
      isSuspense: false,
      typeSelect: null,
      closeSuspenseReason: 'IGNORE AS INVALID PAYMENT',
      accountIdModifySuspense: null,
      isOpenModalCloseSuspense: false,
      isOpenModalAccountIdModify: false,
      isPacEnabled: false,
      dataReverse: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      dataPaymentSuspense: props.listPaymentSuspense,
      totalCount: getPageTotalCount({ ...state, items: props.listPaymentSuspense }),
    };
  }

  componentDidMount() {
    const { location, getCcpPropertiesConfig } = this.props;
    getCcpPropertiesConfig('', ({ success, data }) => {
      let isPacEnabled = false;
      if (success) {
        const pacEnabledProperty = data.ccpPropertyList.find(val => val.property === 'pacEnabled');
        if (pacEnabledProperty && pacEnabledProperty.value) {
          isPacEnabled = pacEnabledProperty.value === 'true';
        }
      }
      this.setState({
        isPacEnabled,
      });
    });
    if (location && location.state && location.state) {
      const {
        filter,
        page,
        size,
        sort,
        sorted,
        totalCount,
        isSearching,
        selectedPayment,
        accountId,
        invoiceId,
        isOpenModalAccount,
        isOpenModalInvoice,
        dataPaymentSuspense,
        isOpenModalSusppense,
        listItems,
        itemSelectIvoiceId,
        isOpenModalViewPaymentActivity,
        isSuspense,
        typeSelect,
        closeSuspenseReason,
        accountIdModifySuspense,
        isOpenModalCloseSuspense,
        isOpenModalAccountIdModify,
        dataReverse,
      } = location.state;
      this.setState(
        {
          filter: filter || {},
          page,
          size,
          sort,
          sorted,
          totalCount,
          isSearching,
          selectedPayment,
          accountId,
          invoiceId,
          isOpenModalAccount,
          isOpenModalInvoice,
          dataPaymentSuspense,
          isOpenModalSusppense,
          listItems,
          itemSelectIvoiceId,
          isOpenModalViewPaymentActivity,
          isSuspense,
          typeSelect,
          closeSuspenseReason,
          accountIdModifySuspense,
          isOpenModalCloseSuspense,
          isOpenModalAccountIdModify,
          dataReverse,
        },
        () => {
          this.doSearchPaymentSuspense();
        }
      );
    } else {
      this.doSearchPaymentSuspense();
    }
    this.doGetOutputTemplateByType();
  }

  doSearchPaymentSuspense = () => {
    const { searchPaymentSuspenseReport } = this.props;
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? paymentSuspenseSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchPaymentSuspenseReport(payload, () =>
      this.setState({
        isSearching: false,
        closeSuspenseReason: 'IGNORE AS INVALID PAYMENT',
        // selectedPayment: {},
        accountIdModifySuspense: null,
        // typeSelect: null,
      })
    );
  };

  onHandleSearch = filter => {
    this.setState({ filter, isSearching: true, page: 0 }, () => {
      this.doSearchPaymentSuspense();
    });
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchPaymentSuspense());
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doSearchPaymentSuspense());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchPaymentSuspense());
  };

  onSelectedPayment = selectedPayment1 => {
    const { selectedPayment } = this.state;
    if (!selectedPayment || selectedPayment.paymentSuspenseId !== selectedPayment1.paymentSuspenseId) {
      this.setState({ selectedPayment: selectedPayment1 });
    } else {
      this.setState({ selectedPayment: null });
    }
  };

  onApplyPaymentSuspense = () => {
    const { applyPaymentSuspense } = this.props;
    const { selectedPayment, listItems } = this.state;
    let firstAccountId = null;
    const payloadItem = listItems.map((val, index) => {
      const { invoiceUnitId, amount, accountId } = val;
      if (index === 0) {
        firstAccountId = accountId;
      }
      return { invoiceUnitId, amount, accountId };
    });
    const payload = {
      accountId: firstAccountId || selectedPayment.accountId || null,
      invoiceUnitId: payloadItem && payloadItem.length ? null : selectedPayment?.invoiceUnitId || null,
      id: selectedPayment.paymentSuspenseId,
      allocationData: payloadItem,
    };
    applyPaymentSuspense(payload, ({ success }) => {
      if (success) {
        this.setState({ isOpenModalSusppense: false, listItems: [], selectedPayment: {} });
        this.doSearchPaymentSuspense();
      }
    });
  };

  closePaymentSuspense = () => {
    const { modifyPaymentSuspense } = this.props;
    const { selectedPayment, closeSuspenseReason } = this.state;
    const payload = {
      id: selectedPayment.paymentSuspenseId,
      status: 'CLOSED',
      closedDate: moment().format('YYYY-MM-DD'),
      closeReason: closeSuspenseReason,
      userId: getUserId(),
    };

    modifyPaymentSuspense(payload, () => {
      this.setState({ isOpenModalCloseSuspense: false });
      this.doSearchPaymentSuspense();
    });
  };

  modifyPaymentSuspense = () => {
    const { modifyPaymentSuspense } = this.props;
    const { selectedPayment, accountIdModifySuspense } = this.state;
    const payload = {
      id: selectedPayment.paymentSuspenseId,
      accountId: accountIdModifySuspense,
    };

    modifyPaymentSuspense(payload, () => {
      this.doSearchPaymentSuspense();
    });
  };

  onToogleModalApplySuspense = () => {
    const { isOpenModalSusppense } = this.state;
    if (isOpenModalSusppense) {
      return this.setState({ isOpenModalSusppense: !isOpenModalSusppense, listItems: [] });
    }
    this.setState({ isOpenModalSusppense: !isOpenModalSusppense });
  };

  onToogleModalAccount = ({ item, index }) => {
    const { isOpenModalAccount } = this.state;
    this.setState({
      isOpenModalAccount: !isOpenModalAccount,
      itemSelectAccountId: item || null,
      indexSelectAccountId: index,
      isOpenModalAccountIdModify: false,
    });
  };

  onToogleModalInvoice = ({ item, index }) => {
    const { isOpenModalInvoice, accountId } = this.state;
    // if (!accountId) return toast('You need to select account first', { type: toast.TYPE.WARNING });
    return this.setState({
      isOpenModalInvoice: !isOpenModalInvoice,
      itemSelectIvoiceId: item || null,
      indexSelectAccountId: index,
    });
  };

  onSelectAccountId = (itemId, row) => {
    const { accountId, indexSelectAccountId, listItems } = this.state;
    // accountId = itemId;
    if (itemId) {
      listItems[indexSelectAccountId].accountId = itemId;
      listItems[indexSelectAccountId].clientId = row?.clientId || null;
      listItems[indexSelectAccountId].organization =
        row?.contacts && row?.contacts[0].organization ? row?.contacts[0].organization : null;
    }
    this.setState({ listItems: [...listItems], isOpenModalAccount: false });
  };

  onSelectAccountIdModify = itemId => {
    const { accountIdModifySuspense } = this.state;
    this.setState({ accountIdModifySuspense: itemId || accountIdModifySuspense, isOpenModalAccountIdModify: false });
  };

  onSelectItem = item => {
    let { accountId, invoiceId, selectedPayment } = this.state;
    const newItem = cloneDeep(item);
    if (newItem.paymentSuspenseId === selectedPayment.paymentSuspenseId) {
      selectedPayment = {};
      accountId = '';
      invoiceId = '';
    } else {
      selectedPayment = { ...newItem };
      selectedPayment.unallocatedAmount = newItem.unallocatedAmount;
      accountId = selectedPayment.accountId;
    }
    this.setState({
      selectedPayment,
      dataReverse: {},
      accountId,
      invoiceId,
      typeSelect: null,
      closeSuspenseReason: 'IGNORE AS INVALID PAYMENT',
      accountIdModifySuspense: null,
    });
  };

  onSelectInvoice = (itemId, row) => {
    const { indexSelectAccountId, listItems, selectedPayment } = this.state;
    const { t } = this.props;
    // let totalAmount = 0;
    // listItems
    //   .filter(val => val.invoiceUnitId !== listItems[indexSelectAccountId].invoiceUnitId)
    //   .forEach(val => {
    //     const { amount } = val;
    //     totalAmount += Number.parseFloat(amount || 0);
    //   });

    const checkExitInvoice = listItems.find(val => val.invoiceUnitId === itemId);
    if (checkExitInvoice) return toast.error(t('message.messageInvoiceIdAleadyExist'));
    // const amountTotalForListRow = selectedPayment.unallocatedAmount - totalAmount;
    if (itemId) {
      listItems[indexSelectAccountId].invoiceUnitId = itemId;
      listItems[indexSelectAccountId].due = row.due;
      listItems[indexSelectAccountId].invoiceDate = row.invoiceDate;
      listItems[indexSelectAccountId].dueDate = row.dueDate;
      listItems[indexSelectAccountId].total = row.total;
      listItems[indexSelectAccountId].type = row.type;
      listItems[indexSelectAccountId].accountId = row.accountId;
      listItems[indexSelectAccountId].clientId = row.clientId;
      listItems[indexSelectAccountId].organization = row.organization;
      listItems[indexSelectAccountId].folioStatus = row.folioStatus;
      this.doGetCrossCurrencyPaymentDetails({
        accountId: row.accountId,
        invoiceId: itemId,
        paymentDate: selectedPayment?.paymentDate,
        paymentCurrency: selectedPayment?.sourceCurrency,
        indexSelectAccountId,
      });
      // if (amountTotalForListRow === 0) {
      //   listItems[indexSelectAccountId].amount = '0';
      // }
      // if (amountTotalForListRow > 0 && Number.parseFloat(amountTotalForListRow) - Number.parseFloat(row.due) < 0) {
      //   listItems[indexSelectAccountId].amount = amountTotalForListRow;
      // }
      // if (Number.parseFloat(amountTotalForListRow) > Number.parseFloat(row.due)) {
      //   listItems[indexSelectAccountId].amount = row.due;
      // }
    }
    this.setState({ listItems: [...listItems], isOpenModalInvoice: false });
  };

  onAddNewItem = () => {
    try {
      const { listItems, selectedPayment } = this.state;
      const newData = cloneDeep(listItems);
      newData.push({
        invoiceUnitId: null,
        amount: null,
        // accountId: newData.length === 0 ? selectedPayment.accountId : newData[newData.length - 1].accountId || null,
        accountId: null,
      });

      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeInvoiceAmount = ({ name, value, index, item }) => {
    const { listItems, selectedPayment } = this.state;
    let totalAmount = 0;
    if (Number.parseFloat(value) < 0) return '';
    listItems
      .filter(val => val.invoiceUnitId !== listItems[index].invoiceUnitId)
      .forEach(val => {
        const { amount } = val;
        totalAmount += Number.parseFloat(amount || 0);
      });
    const amountTotalForListRow = selectedPayment.unallocatedAmount - totalAmount;
    if (amountTotalForListRow < Number.parseFloat(value)) return '';
    if (item.paymentCurrencyInvDue < Number.parseFloat(value)) return '';
    listItems[index].amount = value;
    this.setState({ listItems: [...listItems] });
  };

  doGetObjectFileById = invoiceIdSelect => {
    const { history, getObjectFileById, id } = this.props;
    const {
      filter,
      page,
      size,
      sort,
      sorted,
      totalCount,
      isSearching,
      selectedPayment,
      accountId,
      invoiceId,
      isOpenModalAccount,
      isOpenModalInvoice,
      dataPaymentSuspense,
      isOpenModalSusppense,
      listItems,
      itemSelectIvoiceId,
      isOpenModalViewPaymentActivity,
      isSuspense,
      typeSelect,
      closeSuspenseReason,
      accountIdModifySuspense,
      isOpenModalCloseSuspense,
      isOpenModalAccountIdModify,
      dataReverse,
    } = this.state;
    getObjectFileById(invoiceIdSelect, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceIdSelect),
          state: {
            fromPath: RouteNames.prettifyPath(RouteNames.paymentSuspense.path),
            fileData: data,
            filter,
            page,
            size,
            sort,
            sorted,
            totalCount,
            isSearching,
            selectedPayment,
            accountId,
            invoiceId,
            isOpenModalAccount,
            isOpenModalInvoice,
            dataPaymentSuspense,
            isOpenModalSusppense,
            listItems,
            itemSelectIvoiceId,
            isOpenModalViewPaymentActivity,
            isSuspense,
            typeSelect,
            closeSuspenseReason,
            accountIdModifySuspense,
            isOpenModalCloseSuspense,
            isOpenModalAccountIdModify,
            dataReverse,
          },
        });
      }
    });
  };

  onRemoveItem = ({ index }) => {
    const { listItems } = this.state;
    try {
      const newData = cloneDeep(listItems);
      newData.splice(index, 1);
      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleModalViewPaymentActivity = (item, isSuspense) => {
    const { isOpenModalViewPaymentActivity } = this.state;
    this.setState({ isOpenModalViewPaymentActivity: !isOpenModalViewPaymentActivity, itemSelected: item, isSuspense });
  };

  onToggleModalCloseSuspense = () => {
    const { isOpenModalCloseSuspense } = this.state;
    this.setState({ isOpenModalCloseSuspense: !isOpenModalCloseSuspense });
  };

  doGetCrossCurrencyPaymentDetails = ({ accountId, paymentDate, invoiceId, paymentCurrency, indexSelectAccountId }) => {
    const { getCrossCurrencyPaymentDetails } = this.props;
    getCrossCurrencyPaymentDetails({ accountId, paymentDate, invoiceId, paymentCurrency }, ({ success, data }) => {
      if (success && data) {
        const { listItems, selectedPayment } = this.state;
        if (listItems && listItems[indexSelectAccountId]) {
          listItems[indexSelectAccountId].invoiceCurrencyInvDue = data.invoiceCurrencyInvDue;
          listItems[indexSelectAccountId].paymentCurrencyInvDue = data.paymentCurrencyInvDue;
          listItems[indexSelectAccountId].exchangeRate = data.exchangeRate;
          listItems[indexSelectAccountId].invoiceCurrency = data.invoiceCurrency;

          let totalAmount = 0;
          listItems
            .filter(val => val.invoiceUnitId !== listItems[indexSelectAccountId].invoiceUnitId)
            .forEach(val => {
              const { amount } = val;
              totalAmount += Number.parseFloat(amount || 0);
            });

          const amountTotalForListRow = selectedPayment.unallocatedAmount - totalAmount;
          if (amountTotalForListRow === 0) {
            listItems[indexSelectAccountId].amount = '0';
          }
          if (
            amountTotalForListRow > 0 &&
            Number.parseFloat(amountTotalForListRow) - Number.parseFloat(data.paymentCurrencyInvDue) < 0
          ) {
            listItems[indexSelectAccountId].amount = amountTotalForListRow;
          }
          if (Number.parseFloat(amountTotalForListRow) >= Number.parseFloat(data.paymentCurrencyInvDue)) {
            listItems[indexSelectAccountId].amount = Number.parseFloat(data.paymentCurrencyInvDue);
          }
          if (
            listItems[indexSelectAccountId].amount &&
            Number.parseFloat(listItems[indexSelectAccountId].amount) !== 0
          ) {
            listItems[indexSelectAccountId].amount = Number.parseFloat(listItems[indexSelectAccountId].amount);
          }
          // console.log('listItems', listItems)
        }
        this.setState({ listItems: [...listItems] });
      }
    });
  };

  renderPaymentDetails = ({ selectedPayment, totalAmount, isInvoiceModal }) => {
    return (
      <div className={`col-md-12 row border-bottom pt-3 pb-3 ${isInvoiceModal ? 'pl-3' : ''}`}>
        {paymentDetails.map(val => {
          let value = selectedPayment ? selectedPayment[val.name] : '';
          if (val.name === 'unallocatedAmount' && selectedPayment) {
            value = formatMoneyValue(
              Number.parseFloat(selectedPayment[val.name] || 0) - Number.parseFloat(totalAmount || 0) || null
            );
            if (!value || Number.isNaN(value) || value === 'NaN') value = '0';
          }
          // console.log('asdasd', val.name, value);
          return <GenericInput value={value} onChange={() => {}} wrapperClass="col-md-3" readOnly {...val} />;
        })}
      </div>
    );
  };

  onChangeType = ({ value }) => {
    const { selectedPayment } = this.state;
    this.setState({
      typeSelect: value,
      accountIdModifySuspense: value === 'MODIFY_SUSPENSE' ? selectedPayment?.accountId : null,
      dataReverse: {},
    });
  };

  onChangeCloseSuspenseReason = ({ value }) => {
    this.setState({ closeSuspenseReason: value });
  };

  onChangeAccountIdSuspense = ({ value }) => {
    this.setState({ accountIdModifySuspense: value });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('PAYMENT_SUSPENSE_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({ columns: tableColumnsDefault, data, t }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  render() {
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      selectedPayment,
      accountId,
      isOpenModalInvoice,
      isOpenModalAccount,
      listItems,
      dataPaymentSuspense,
      isOpenModalSusppense,
      itemSelectIvoiceId,
      isOpenModalViewPaymentActivity,
      itemSelected,
      isSuspense,
      typeSelect,
      closeSuspenseReason,
      accountIdModifySuspense,
      isOpenModalCloseSuspense,
      isOpenModalAccountIdModify,
      exportColumnsSave,
      isPacEnabled,
    } = this.state;

    const {
      permissionsPayment,
      t,
      getCurrencyConfigApp,
      getPaymentItemMap,
      getBatchPaymentFileConfigSelect,
      location,
    } = this.props;

    let modeSearchPaymentSuspense = 0;
    let modeProcessPaymentSuspense = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeSearchPaymentSuspense = checkPermissionSearchPaymentSuspense({ listPermission });
      modeProcessPaymentSuspense = checkPermissionProcessPaymentSuspense({ listPermission });
    }

    const exportColumns = exportColumnsSave || {};
    const tableColumnsOutput = exportColumns?.targetFields?.items || [];

    tableColumnsDefault = [
      ...tableColumnsPaymentSuspense,
      {
        name: 'paymentSuspenseId',
        label: 'common:label.paymentSuspenseId',
        render: (colName, item) =>
          item.paymentSuspenseId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
            >
              {item.paymentSuspenseId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'referenceId',
        label: 'common:label.paymentRefId',
        render: (colName, item) =>
          item.referenceId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, false)}
            >
              {item.referenceId}
            </button>
          ) : (
            ''
          ),
      },
    ];
    if (modeSearchPaymentSuspense === 0) return '';

    const enableBtn = !!(selectedPayment && selectedPayment.paymentSuspenseId && selectedPayment.status === 'OPEN');
    const isNotAmount = !!(
      selectedPayment &&
      selectedPayment.paymentSuspenseId &&
      selectedPayment.unallocatedAmount &&
      selectedPayment.unallocatedAmount <= 0
    );

    const tableConfig = {
      columns: [
        {
          name: 'Selected',
          label: 'common:label.selected',
          render: (colName, item) => (
            <CheckGroup
              checked={!isEmpty(selectedPayment) && selectedPayment.paymentSuspenseId === item.paymentSuspenseId}
              name="selectItem"
              type="checkbox"
              onChange={() => this.onSelectItem(item)}
              disabled={item.status === 'CLOSED' || (item.unallocatedAmount && item.unallocatedAmount <= 0)}
            />
          ),
        },
        ...tableColumnsOutput,
        // {
        //   name: 'viewAllocation',
        //   label: 'common:label.viewAllocation',
        //   render: (colName, item) => (
        //     <button
        //       type="button"
        //       className="button x-small btn-sm"
        //       onClick={evt => this.onToggleModalViewPaymentActivity(item)}
        //     >
        //       {t('label.view')}
        //     </button>
        //   ),
        // },
        // ...tableColumnsPaymentSuspense,
      ],
      sorted,
      onSort: this.onSortColumn,
      data: dataPaymentSuspense,
      isLoading: isSearching,
    };

    const invoiceColumns = [
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        label: 'label.invoiceId',
        name: 'invoiceUnitId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item.invoiceUnitId}
              name="invoiceUnitId"
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              onClick={() => this.onToogleModalInvoice({ item, index })}
              fa="fa fa-external-link"
            />
          );
        },
      },
      {
        label: 'label.accountId',
        name: 'accountId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountId}
            name="accountId"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            onClick={() => this.onToogleModalAccount({ item, index })}
            fa={item.invoiceUnitId ? null : 'fa fa-external-link'}
            readOnly={!!item.invoiceUnitId}
            disabled={!!item.invoiceUnitId}
          />
        ),
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'clientId',
        label: 'common:label.clientId',
      },
      {
        name: 'amount',
        label: 'common:label.allocateAmount',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.amount}
            name="amount"
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeInvoiceAmount({ name, value, item, index })}
          />
        ),
      },
      {
        name: 'invoiceCurrency',
        label: 'common:label.accountCurrency',
      },
      {
        name: 'invoiceCurrencyInvDue',
        label: 'common:label.invoiceDueInAccountCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'paymentCurrencyInvDue',
        label: 'common:label.invoiceDueInPaymentCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'exchangeRate',
        label: 'common:label.exchangeRate',
        render: (colName, item) => {
          return <span>{item.exchangeRate}</span>;
        },
      },
      {
        name: 'invoiceUnitId',
        label: 'common:label.invoiceId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              disabled={isPacEnabled && statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
              onClick={() => this.doGetObjectFileById(item.invoiceUnitId)}
            >
              {item.invoiceUnitId}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'common:label.invoiceDate',
      },
      {
        name: 'dueDate',
        label: 'common:label.dueDate',
      },
      {
        name: 'total',
        label: 'common:label.invoiceTotal',
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
    ];

    let totalAmount = 0;
    let isDisableApply = false;
    listItems.forEach(val => {
      const { amount, invoiceUnitId, accountId } = val;
      totalAmount += Number.parseFloat(amount || 0);
      if (!accountId || !invoiceUnitId) isDisableApply = true;
    });

    const paymentSuspenseSelectOptions = t('selections:paymentActionSuspense')();

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('common:label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentOperations'), url: RouteNames.paymentActivity.path },
            { name: t('paymentPage:sidebar.paymentSuspense') },
          ]}
          isShowNoteConfig
          messageNote={messageNotes(t)}
        />
        <br />
        <FormWithTableItem title={t('paymentPage:sidebar.paymentSuspense')}>
          <SuspenseSearchForm
            getPaymentItemMap={getPaymentItemMap}
            getCurrencyConfigApp={getCurrencyConfigApp}
            onSubmit={this.onHandleSearch}
            getBatchPaymentFileConfigSelect={getBatchPaymentFileConfigSelect}
            defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
          />
          <div className="col-md-12 mb-30 pl-3 d-flex">
            <div className="col-md-12 separation-border-top pt-3 d-flex">
              <GenericInput
                value={typeSelect}
                name="type"
                onChange={({ name, value }) => this.onChangeType({ value })}
                wrapperClass="col-md-3 ml-2"
                label="label.action"
                options={paymentSuspenseSelectOptions}
                type="select"
                placeholder="<Select Action>"
                disabled={!selectedPayment || !enableBtn}
                isPlaceholder
                isClearable
              />
              {typeSelect === 'PROCESS_SUSPENSE' && (
                <button
                  type="button"
                  disabled={!enableBtn || modeProcessPaymentSuspense === 1 || isNotAmount}
                  className="button button-border x-small mt-4 default-heigh-btn"
                  // onClick={this.applyPaymentSuspense}
                  onClick={this.onToogleModalApplySuspense}
                >
                  {t('label.processSuspense')}
                </button>
              )}
              {typeSelect === 'CLOSE_SUSPENSE' && (
                <>
                  <GenericInput
                    name="reason"
                    label={t('label.selectCloseSuspenseReason')}
                    value={closeSuspenseReason}
                    tOptions="selections:closeSuspenseReason"
                    type="select"
                    disabled={!enableBtn}
                    onChange={this.onChangeCloseSuspenseReason}
                    wrapperClass="col-md-3 collection-dropdown"
                    menuPortalTarget
                    required
                    isNoClearable
                  />
                  <button
                    type="button"
                    disabled={!enableBtn}
                    className="button button-border x-small mt-4 default-heigh-btn"
                    onClick={this.onToggleModalCloseSuspense}
                  >
                    {t('label.closeSuspense')}
                  </button>
                </>
              )}
              {typeSelect === 'MODIFY_SUSPENSE' && (
                <>
                  <GenericInput
                    name="accountIdModifySuspense"
                    label={t('label.accountId')}
                    value={accountIdModifySuspense}
                    disabled={!enableBtn}
                    onChange={() => {}}
                    onClick={() => this.setState({ isOpenModalAccountIdModify: true })}
                    wrapperClass="col-md-3"
                    fa="fa fa-external-link"
                  />
                  <button
                    type="button"
                    disabled={!enableBtn}
                    className="button button-border x-small mt-4 default-heigh-btn"
                    onClick={this.modifyPaymentSuspense}
                  >
                    {t('label.modifySuspense')}
                  </button>
                </>
              )}
              {/* <div className="col-md-12 pl-0 btn-suspense text-right">
              {modeProcessPaymentSuspense !== 0 && (
                <button
                  type="button"
                  disabled={!enableBtn || modeProcessPaymentSuspense === 1 || isNotAmount}
                  className="button button-border x-small"
                  // onClick={this.applyPaymentSuspense}
                  onClick={this.onToogleModalApplySuspense}
                >
                  {t('label.processSuspense')}
                </button>
              )}
              <button
                type="button"
                disabled={!enableBtn}
                className="button button-border x-small"
                onClick={this.closePaymentSuspense}
              >
                {t('label.closeSuspense')}
              </button>
            </div> */}
            </div>
          </div>
          <DataTable {...tableConfig} isFixedHeaderTable />
          <br />
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalSelectItemId
          isOpen={isOpenModalAccount || isOpenModalAccountIdModify}
          selectedParentId={isOpenModalAccountIdModify ? accountIdModifySuspense : accountId}
          onSelect={isOpenModalAccountIdModify ? this.onSelectAccountIdModify : this.onSelectAccountId}
          onCancel={this.onToogleModalAccount}
          toggleModal={this.onToogleModalAccount}
          defaultSearchForm={isOpenModalAccountIdModify ? { type: 'CLIENT' } : null}
        />
        <ModalInvoice
          isOpen={isOpenModalInvoice}
          onSelect={this.onSelectInvoice}
          accountId={itemSelectIvoiceId ? itemSelectIvoiceId.accountId : null}
          disabledSearchFields={itemSelectIvoiceId?.accountId ? ['organization', 'clientId'] : null}
          isDue
          onCancel={() => {
            this.setState({ isOpenModalInvoice: false });
          }}
          defaultSearchForm={{ folioStatus: 'STAMPED' }}
          isTopChildren
        >
          {this.renderPaymentDetails({ selectedPayment, totalAmount, isInvoiceModal: true })}
        </ModalInvoice>
        <ModalWithItem
          isOpen={isOpenModalSusppense}
          onToggle={this.onToogleModalApplySuspense}
          modalTitle={t('label.paymentAllocation')}
          wrapperClass="modal-70 modal-custom"
        >
          {this.renderPaymentDetails({ selectedPayment, totalAmount, isInvoiceModal: true })}
          <br />
          <br />
          <DataTable columns={invoiceColumns} data={listItems} />
          <div className="col-sm-12 mt-4 mb-30">
            <button type="button" onClick={this.onAddNewItem} className="button mb-4 button-border ml-3 black x-small">
              +
              {t('label.addNewRow')}
            </button>
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={this.onToogleModalApplySuspense}
            >
              {t('label.cancel')}
            </button>
            <button
              type="button"
              className="button button-border x-small mr-2 float-right"
              onClick={this.onApplyPaymentSuspense}
              disabled={isDisableApply}
            >
              {t('label.apply')}
            </button>
          </div>
        </ModalWithItem>
        <ModalViewPaymentActivity
          id={itemSelected?.paymentSuspenseId || null}
          isOpen={isOpenModalViewPaymentActivity}
          onToggle={evt => this.onToggleModalViewPaymentActivity()}
          itemSelected={{ ...itemSelected, paymentRefId: itemSelected?.referenceId }}
          title={isSuspense ? t('label.paymentSuspenseActivity') : t('label.paymentSuspenseReferenceActivity')}
          isSuspense={isSuspense}
          isAdditionalFields
          isSupportDownload
        />
        <ModalAccept
          onAcceptModal={this.closePaymentSuspense}
          isOpen={isOpenModalCloseSuspense}
          message={t('common:message.messageCloseSuspense')}
          onToggle={this.onToggleModalCloseSuspense}
        />
      </div>
    );
  }
}

PaymentSuspense.propTypes = {
  listPaymentSuspense: PropTypes.arrayOf(PropTypes.any),
  searchPaymentSuspenseReport: PropTypes.func.isRequired,
  applyPaymentSuspense: PropTypes.func.isRequired,
  modifyPaymentSuspense: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

PaymentSuspense.defaultProps = {
  listPaymentSuspense: [],
  permissionsPayment: {},
};

const mapStateToProps = createStructuredSelector({
  listPaymentSuspense: makeGetlistPaymentSuspense() || [],
  params: makePagePaymentSuspenseParams() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchPaymentSuspenseReport,
    applyPaymentSuspense,
    modifyPaymentSuspense,
    getObjectFileById,
    getCurrencyConfigApp,
    getPaymentItemMap,
    getCrossCurrencyPaymentDetails,
    getBatchPaymentFileConfigSelect,
    getOutputTemplateByType,
    getCcpPropertiesConfig,
  })(PaymentSuspense)
);
