import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { toast } from 'react-toastify';
import { union, isEmpty, isArray, cloneDeep, uniqBy, uniq, groupBy, map, sumBy, sortBy, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  selectPackageId,
  selectBundleId,
  selectPriceOfferId,
  getBundleByBundleId,
  getCcpDateTimeConfig,
  modifyAccountServiceLocations,
  getAccountDetail,
  selectAccountId,
  getBillableServicePricing,
  modifyOrderBillableServices,
  getCurrencyConfigApp,
  getOrderBillableByIdAndIndex,
  getCcpPropertiesConfig,
  getDiscountOfferById,
} from '../../App/actions';
import { ServiceEditForm, OrderDetailCollapsibleForm, AttributesForm } from '../../../components/Orders';
import PageTitle from '../../../components/PageTitle';
import {
  getMessageModal,
  convertbuildOrderProvisioningAttributesPayload,
  convertbuildOrderProvisioningAttributesNewOrder,
  convertDataProvisioningAttributes,
  convertLineFromDiscountOffers,
  initServiceWithSelectBundle,
  initServiceFromPriceOffers,
} from '../utilities';
import {
  ModalAccept,
  FormCollapse,
  DataTable,
  CollapsibleTable,
  CardHeaderForm,
  GenericInput,
  Switch,
  ModalWithItem,
  ModalViewMessage,
  ModalViewNote,
  ColorListGroup,
} from '../../../components/common';
import { orderEnum } from '../../../constantsApp';
import constants, {
  collapsibleMenu,
  messageNotes,
  messageNotesSplitOrder,
  collapsibleShowTask,
  collapsibleOutInBound,
  taskColumns,
  taskInboundColumns,
  taskOutboundColumns,
  provisionigColumns,
  infrastructureEquipmentsColumns,
  billableFields,
  locationsTable,
  billableServiceTableColumns,
  equipmentsTable,
} from '../constants';
import {
  getOrderDetail,
  updateOrderStatus,
  modifyOrder,
  getBundleDetail,
  createBillingAccounts,
  processSplitOrder,
  saveSplitOpportunity,
  getOrderSplitOpportunity,
  undoProcessSplitOrder,
  buildOrderProvisioningAttributes,
  getOrderOmsTasks,
  getOrderProvisioningOutboundTasks,
  getOrderProvisioningInboundTasks,
} from '../actions';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';
import { makeGetOrderDetail, makeErrorMessage } from '../selectors';
import {
  makeGetPermissionsOrderManagement,
  makeGetCurrencyConfig,
  makeGetCcpPropertiesConfig,
} from '../../App/selectors';
import {
  checkPermissionUpdateOrder,
  checkPermissionCancelOrder,
  checkPermissionSubmitOrder,
  checkPermissionApproveOrder,
  checkPermissionCreateBillingAccount,
  checkPermissionProcessSplitOrder,
  checkPermissionUndoProcessSplitOrder,
} from '../CheckPermission';
import RouteNames from '../../App/RouteNames';
import ModalAccountId from '../../../components/common/ModalAccountId';
import PricingModalOrder from '../../../components/common/PricingModalOrder';
import ModalOrderProvisioningAttributes from '../../../components/common/ModalOrderProvisioningAttributes';
import { convertBooleanObject, getLastIndex } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';
import OrderModifyProvisioningAttributesForm from '../../../components/Orders/OrderModifyProvisioningAttributesForm';
import SubscriptionsForm from './SubscriptionsForm';
import ModalMultipleSelectDisscountOffer from '../../../components/common/ModalMultipleSelectDisscountOffer';
import ChooseKindService from '../CommonSupportOrder/ChooseKindService';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';

let isEdit = false;

class OrderDetail extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      isOverrideLines: {},
      tableColumns: [],
      modalTitle: '',
      mode: null,
      selectedItemIds: [],
      isOpenPriceOfferModal: false,
      isBundle: false,
      isPackage: false,
      isPriceOffers: false,
      isOpenModalDelete: false,
      itemRemove: {},
      serviceTypeRemove: [],
      dataAfterRemoveLine: {},
      activeTab: { name: 'Order Details', isActive: true },
      activeProvisioningTab: {},
      currentCcpDateTime: null,
      activeSubTab: {},
      numberOfAccounts: null,
      clientId: '',
      accountBilling: null,
      isOpenModalSplit: false,
      isOpenModalAccount: false,
      organizationList: [],
      splitData: [],
      splitLocations: [],
      splitEquipments: [],
      infraNonBillables: [],
      typeSelect: '',
      isCreateBillingAccount: false,
      accountBillingIdOptions: null,
      disabledProcess: false,
      isAddMoreAccount: false,
      isMultiAccount: false,
      omsInternalIdSelected: null,
      customAttributes: [],
      activeSubTabPricing: {},
      isParentOrder: false,
      isOpenPricingModal: false,
      pricingData: {},
      isHaveDataSplitViewer: false,
      billableServiceData: {
        advanceFlag: 'FALSE',
        partMonthWaiveOffFlag: 'FALSE',
        recurringType: 'FORWARD',
        recurringUnit: 'MONTHS',
        recurringFrequency: '1',
        calendarMonthFlag: 'TRUE',
        alignedToCycle: 'FALSE',
      },
      oldBillableServiceData: {},
      currencyOptions: [],
      isAccountSelected: [],
      mainData: [],
      defaultCurrency: {},
      isUpdatedExhibits: false,
      invoiceExhibitsChecking: 0,
      customAttributesByObjectType: [],
      isOpenModalUndoProcessSplit: false,
      isOpenModalPendingOrder: false,
      isOpenModalNotes: false,
      messageNote: '',
      isOpenModalNotesSplitOrder: false,
      isOpenModalCopyChangeToAllRecurring: false,
      isOpenModalValidationCutOffUsage: false,
      dataSubmitBillable: {},
      isOpenPricingModalProvisioningAttributes: false,
      itemSelectProvisioningAttributes: null,
      buildOrderProvisioningAttributesList: null,
      provisioningEnabled: null,
      isOpenModalShowTask: false,
      activeShowTasksTab: {},
      activeShowInOutBoundTab: {},
      omsTasksData: null,
      omsInboundTasksData: null,
      omsOutboundTasksData: null,
      splitOpportunityEnabled: {},
      activeTabSubscriptionTab: {},
      subscriptionName: null,
      isFetched: false,
      isBillableServiceEnabled: false,
      dataViewProvisionig: null,
      indexOverrideLine: -1,
      isFirstAddPriceOffer: false,
      activeAttributeTab: {},
    };
  }

  componentDidMount() {
    const { getCcpPropertiesConfig, ccpPropertyList, location } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
        this.doGetOrderDetails(location?.state && location?.state.isFromCreate ? location?.state.data : null);
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
      this.doGetOrderDetails(location?.state && location?.state.isFromCreate ? location?.state.data : null);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orderDetail !== nextProps.orderDetail && nextProps.orderDetail.id) {
      this.initValue(nextProps.orderDetail);
      // this.getTypePurchase(nextProps.orderDetail.services);
    }
  }

  doInitPropertiesConfig = ccpPropertyList => {
    const defaultCurrency = ccpPropertyList.find(val => val.property === 'defaultCurrency');
    const provisioningEnabled = ccpPropertyList.find(val => val.property === 'provisioningEnabled');
    const splitOpportunityEnabled = ccpPropertyList.find(val => val.property === 'splitOpportunityEnabled');
    const billableServiceEnabled = ccpPropertyList.find(val => val.property === 'billableServiceEnabled');
    this.setState({
      defaultCurrency,
      provisioningEnabled,
      splitOpportunityEnabled,
      isBillableServiceEnabled: billableServiceEnabled && billableServiceEnabled.value === 'true',
    });
  };

  onToggleSubscriptionTab = activeTabName => {
    const { activeTabSubscriptionTab } = this.state;
    if (activeTabSubscriptionTab.name === activeTabName) {
      this.setState({
        activeTabSubscriptionTab: { name: activeTabName, isActive: !activeTabSubscriptionTab.isActive },
      });
    } else {
      this.setState({ activeTabSubscriptionTab: { name: activeTabName, isActive: true } });
    }
  };

  doGetOrderDetails = orderCreate => {
    const {
      match: {
        params: { id },
      },
      getOrderDetail,
    } = this.props;
    if (orderCreate && orderCreate.id) {
      this.doInitDetails(orderCreate);
    } else {
      getOrderDetail(id, ({ success, data }) => {
        if (success) {
          this.doInitDetails(data);
        }
      });
    }
  };

  doInitDetails = data => {
    if (data && data.subscriptions) {
      this.onToggleSubscriptionTab(data.subscriptions[0].subscriptionName);
    }
    this.doGetOrderSplitOpportunity();
    this.doBuildOrderProvisioningAttributes();
    this.doGetCustomAttributesByObjectType();
    if (data?.customAttributes) {
      const externalApprovalStatus = data?.customAttributes.find(val => val.name === 'external_approval_status');
      const parentOrder = data?.customAttributes.find(val => val.name === 'parent_order_refId');

      this.setState({ isParentOrder: !!parentOrder?.value, isFirstAddPriceOffer: false });
      if (externalApprovalStatus && externalApprovalStatus.value === 'PENDING') {
        this.setState({ isOpenModalPendingOrder: true });
      }
    }
  };

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState(
        {
          customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
        },
        () => {
          const { orderDetail } = this.props;
          this.setValue(orderDetail?.customAttributes || []);
        }
      );
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState(
            {
              customAttributesByObjectType: (data && data.attributes) || [],
            },
            () => {
              const { orderDetail } = this.props;
              this.setValue(orderDetail?.customAttributes || []);
            }
          );
        }
      }, 'ORDER');
    }
  };

  doRefreshPage = () => {
    this.doGetOrderDetails();
    this.doGetCustomAttributesByObjectType();
    this.setState({ activeSubTab: {} });
  };

  doGetOrderSplitOpportunity = () => {
    const {
      getOrderSplitOpportunity,
      match: {
        params: { id },
      },
    } = this.props;
    const { isBillableServiceEnabled } = this.state;
    if (isBillableServiceEnabled)
      getOrderSplitOpportunity(id, ({ success, data }) => {
        if (success && data?.splitOrder?.order) {
          const splitData = data?.splitOrder?.order.billables;
          let splitAccountsInit = [];
          let isAccountSelected = [];
          splitData.forEach(val => {
            const newVal = cloneDeep(val);
            const { splitAccounts, accountId, ...rest } = newVal;
            if (
              (!newVal.splitAccounts && accountId) ||
              (!accountId && newVal.splitAccounts && newVal.splitAccounts.length === 1)
            ) {
              isAccountSelected = uniq([...isAccountSelected, rest.omsInternalId]);
              splitAccountsInit = [
                ...splitAccountsInit,
                {
                  ...rest,
                  splitAccounts: accountId || newVal.splitAccounts[0].accountId,
                  accountId: accountId || newVal.splitAccounts[0].accountId,
                  disabledPopup: true,
                },
              ];
            }
            if (newVal.splitAccounts && newVal.splitAccounts.length > 1) {
              const splitAccountById = splitAccounts.map(sp => {
                return { ...sp, omsInternalId: rest.omsInternalId, splitAccounts: accountId || sp.accountId };
              });
              splitAccountsInit = [...splitAccountsInit, ...splitAccountById];
            }
          });
          this.setState({ splitData: splitAccountsInit, isAccountSelected, isHaveDataSplitViewer: true });
        }
      });
  };

  setValue = value => {
    const { customAttributesByObjectType } = this.state;
    const sef = this;
    let tempAttributes = [];
    tempAttributes = customAttributesByObjectType.map(item => {
      const temp = sef.getValueFromName(item.name, value);
      return { ...item, value: temp };
      // return item;
    });
    this.setState({
      customAttributesByObjectType: tempAttributes,
    });
  };

  getValueFromName = (name, value, defaultValue) => {
    let result = '';
    value.map(item => {
      if (item.name === name) {
        result = item.value;
      }
    });
    return result || null;
  };

  doGetCcpTimeConfig = () => {
    const { getCcpDateTimeConfig } = this.props;
    getCcpDateTimeConfig('', ({ success, data }) => {
      if (success) {
        this.setState({ currentCcpDateTime: data ? data.ccpTime : null });
      }
    });
  };

  initSplitValue = ({ options }) => {
    try {
      const { orderDetail, accountBillingIdOptions } = this.state;
      const optionData = options || accountBillingIdOptions;
      let isSplitAccount = [];
      // let splitLocations = [];
      // let splitEquipments = [];
      // const infraNonBillables = [];
      let splitData = [];
      if (orderDetail.billables && orderDetail.billables.length > 0) {
        orderDetail.billables.forEach(val => {
          const { serviceId, serviceName, omsInternalId, quantity } = val;
          isSplitAccount = [...isSplitAccount, omsInternalId];
          splitData = [
            ...splitData,
            {
              serviceId,
              serviceName,
              omsInternalId,
              quantity,
              splitAccounts: optionData && optionData.length ? optionData[0].value : '',
              accountId: optionData && optionData.length ? optionData[0].value : '',
              disabledPopup: true,
            },
          ];
        });
      }
      // this.setState({ splitData, splitLocations, splitEquipments, infraNonBillables });
      this.setState({ splitData: cloneDeep(splitData), isAccountSelected: isSplitAccount });
    } catch (error) {}
  };

  initValue = orderDetail => {
    this.setState({
      orderDetail,
    });
  };

  onHandleChange = e => {
    const { orderDetail, currentCcpDateTime } = this.state;
    orderDetail[e.fieldName] = e.value;
    if (!currentCcpDateTime && e.fieldName === 'effectiveDate') {
      this.doGetCcpTimeConfig();
    }
    this.setState({
      orderDetail,
    });
  };

  onChangeCheckBox = ({ fieldName, value }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    newData[fieldName] = value;
    this.setState({
      orderDetail: newData,
    });
  };

  handleClickServicesFormBtn = index => {
    const { isOverrideLines } = this.state;
    let { indexOverrideLine } = this.state;
    isOverrideLines[index] = !isOverrideLines[index];
    if (indexOverrideLine === index) {
      indexOverrideLine = -1;
    } else {
      indexOverrideLine = index;
    }
    this.setState({
      isOverrideLines,
      indexOverrideLine,
    });
  };

  onChangeFormLine = (index, e) => {
    try {
      const { orderDetail } = this.state;
      const newData = cloneDeep(orderDetail);
      const lineIndex = newData.services[index].lines.findIndex(item => {
        return item.index === e.lineIndex;
      });
      newData.services[index].lines[lineIndex][e.fieldName] = e.value;
      this.setState({
        orderDetail: { ...newData },
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateOrderStatus = (status, isSubmitOrder = false) => {
    const {
      match: {
        params: { id },
      },
      updateOrderStatus,
    } = this.props;
    const { orderDetail } = this.state;
    const data = {};
    data.id = id;
    data.status = status;
    if (isSubmitOrder) data.executeFutureOrderNow = orderDetail.executeFutureOrderNow;
    updateOrderStatus(data);
  };

  findLastIndex = () => {
    const { orderDetail } = this.state;
    let lastIndex = 0;
    if (isArray(orderDetail.services)) {
      for (let i = 0; i < orderDetail.services.length; i++) {
        if (lastIndex < orderDetail.services[i].index) {
          lastIndex = orderDetail.services[i].index;
        }
      }
    }
    return lastIndex;
  };

  onDeleteService = item => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    const indexItem = newData.services.findIndex(val => val.index === item.index);
    newData.services[indexItem] = {};
    newData.services[indexItem].index = item.index;
    this.setState({ orderDetail: newData });
  };

  modifyOrder = () => {
    const {
      match: {
        params: { id },
      },
      getOrderDetail,
      t,
    } = this.props;
    const { orderDetail, customAttributesByObjectType, buildOrderProvisioningAttributesList } = this.state;
    const newService = cloneDeep(orderDetail.services);
    const temp = [];
    customAttributesByObjectType.map(item => {
      if (item.value && item.isModify) {
        temp.push({ name: item.name, value: item.value });
      }
    });
    if (newService && newService[0]) {
      for (let i = 0; i < orderDetail.services.length; i++) {
        delete newService[i].bundleName;
        delete newService[i].packageName;
        delete newService[i].isDiscount;
        delete newService[i].isPackage;
        delete newService[i].isBundle;
        delete newService[i].isPriceOffer;
        if (newService[i] && newService[i].lines && newService[i].lines.length) {
          newService[i].lines.forEach((line, indx) => {
            delete newService[i].lines[indx].discountOfferName;
            delete newService[i].lines[indx].priceOfferName;
            if (newService[i]?.lines[indx] && newService[i]?.lines[indx]?.discountOfferId) {
              newService[i].lines[indx].discAmountOverride =
                newService[i].lines[indx].priceOverride || newService[i].lines[indx].discAmountOverride;
              newService[i].lines[indx].discPercentOverride =
                newService[i].lines[indx].discountPercent || newService[i].lines[indx].discPercentOverride;
              delete newService[i].lines[indx].priceOverride;
              delete newService[i].lines[indx].discountPercent;
            }
          });
        }
      }
    }
    const dataOrderInfo = {
      id,
      userId: orderDetail.userId,
      // type: orderEnum.type.modify,
      type: orderDetail.type,
      // effectiveDate: orderDetail.effectiveDate ? moment(orderDetail.effectiveDate).format('YYYY-MM-DD') : null,
      initialTerm: orderDetail.initialTerm || null,
      initialTermUnit: orderDetail.initialTermUnit ? orderDetail.initialTermUnit : null,
      renewalTerm: orderDetail.renewalTerm || null,
      renewalTermUnit: orderDetail.renewalTermUnit ? orderDetail.renewalTermUnit : null,
      crmId: orderDetail.crmId ? orderDetail.crmId : null,
      provisioningId: orderDetail.provisioningId ? orderDetail.provisioningId : null,
      effectiveDate: orderDetail.effectiveDate ? orderDetail.effectiveDate : null,
      services: newService,
      orderCustomAttributes: temp,
      subscriptions: orderDetail.subscriptions,
      provisioningAttributes:
        convertbuildOrderProvisioningAttributesNewOrder(buildOrderProvisioningAttributesList) || null,
    };
    this.props.modifyOrder(dataOrderInfo, ({ success }) => {
      if (success)
        getOrderDetail(orderDetail.id, ({ success, data }) => {
          if (success) {
            if (data?.customAttributes) {
              const externalApprovalStatus = data?.customAttributes.find(
                val => val.name === 'external_approval_status'
              );
              const parentOrder = data?.customAttributes.find(val => val.name === 'parent_order_refId');
              this.setState({ isParentOrder: !!parentOrder?.value });
              this.setValue(orderDetail?.customAttributes || []);
              if (externalApprovalStatus.value === 'PENDING') {
                // toast.error(t('message.messagePendingOrder'));
                this.setState({ isOpenModalPendingOrder: true });
              }
              // if (externalApprovalStatus) {
              //   toast.error(t('message.messagePendingOrder'));
              // }
            }
          }
        });
    });
  };

  getTypePurchase = data => {
    const { t } = this.props;
    const bSelected = [];
    const poSelected = [];
    if (!data.length) {
      this.setState({ mode: constants.ModalMode.none });
    } else if (data[0].packageId) {
      this.setState({
        modalTitle: 'label.choosePackage',
        buttonText: 'label.addPackage',
        tableColumns: constants.tableSelectPackageColumns,
        mode: constants.ModalMode.purchasePackage,
        selectedItemIds: [data[0].packageId],
        isBundle: false,
        isPackage: true,
        isPriceOffers: false,
      });
    } else if (data[0].bundleId) {
      data.forEach(el => {
        bSelected.push(el.bundleId);
      });
      this.setState({
        modalTitle: 'label.selectBundles',
        buttonText: 'label.addBundle',
        mode: constants.ModalMode.purchaseBundle,
        tableColumns: constants.tableSelectBundleColumns,
        selectedItemIds: bSelected,
        isBundle: true,
        isPackage: false,
        isPriceOffers: false,
      });
    } else {
      data.forEach(el => {
        el.lines.forEach(elm => {
          poSelected.push(elm.priceOfferId);
        });
      });
      this.setState({
        modalTitle: 'label.selectPriceOffers',
        buttonText: 'label.addPriceOffer',
        mode: constants.ModalMode.purchaseAlaCarte,
        tableColumns: constants.tableSelectPriceOfferColumns,
        selectedItemIds: poSelected,
        isBundle: false,
        isPackage: false,
        isPriceOffers: true,
      });
    }
  };

  doInitPackageItem = () => {
    const { orderDetail } = this.state;
    const { getBundleByBundleId, getDiscountOfferById } = this.props;
    const newData = cloneDeep(orderDetail);
    const { services } = newData;
    const listBundleId = services.filter(val => !!val.bundleId && val.isPackage).map(item => item.bundleId) || [];
    const listDiscountId =
      services.filter(val => !!val.discountOfferId && val.isPackage).map(item => item.discountOfferId) || [];
    const newDataWithBundleId = [];
    const newDataWithDiscountId = [];
    if (!isEmpty(listBundleId)) {
      listBundleId.map(item =>
        getBundleByBundleId(item, ({ success, data }) => {
          if (success) newDataWithBundleId.push(data.components.map(cp => ({ ...cp, bundleId: item })));
          if (newDataWithBundleId.length === listBundleId.length && success) {
            newDataWithBundleId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(sr => sr.bundleId === val.bundleId && sr.isPackage);
                  if (!services[indexService].lines) services[indexService].lines = [];
                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });
            newData.services = services;

            this.setState({
              orderDetail: newData,
              mode: constants.ModalMode.none,
            });
          }
        })
      );
    }
    if (!isEmpty(listDiscountId)) {
      listDiscountId.map(item =>
        getDiscountOfferById(item, ({ success, data }) => {
          if (success) newDataWithDiscountId.push([{ discountOfferName: data.name || null, discountOfferId: item }]);
          if (newDataWithDiscountId.length === listDiscountId.length && success) {
            newDataWithDiscountId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(
                    sr => sr.discountOfferId === val.discountOfferId && sr.isPackage
                  );
                  if (!services[indexService].lines) services[indexService].lines = [];

                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });

            newData.services = services;
            this.setState({
              orderDetail: newData,
              mode: constants.ModalMode.none,
            });
          }
        })
      );
    }
  };

  onSelectService = ({ services, mode, ids, rows, index, name }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    if (!services || (services && services.length === 0) || !ids || (ids && ids.length === 0)) {
      // newData[index].services = [];
      // newData[index].groupServices = [];

      // this.setState({
      //   subscriptionsData: newData,
      // });
      return;
    }
    const service = [];
    const serviceIndex = 0;
    // newData[index].services = [];
    switch (mode) {
      case constants.ModalMode.purchasePackage: {
        const listExistService = newData.services.filter(val => !val.isPackage || val.subscriptionName !== name);
        let newServices = [];
        newServices = [
          ...listExistService,
          ...services[0].map((item, index) => {
            return {
              index: getLastIndex({ data: listExistService }) + index,
              packageId: ids[0],
              bundleId: item.bundleId,
              bundleName: item.bundleName,
              serviceType: item.serviceType,
              discountOfferId: item.discountOfferId || null,
              action: 'ADD',
              status: 'CREATED',
              // eslint-disable-next-line no-nested-ternary
              packageName: item.name ? item.name : rows && rows.length ? rows[0].name : '',
              subscriptionName: name,
              isPackage: true,
            };
          }),
        ];
        newData.services = newServices;
        this.setState(
          {
            orderDetail: newData,
            mode: constants.ModalMode.none,
          },
          () => this.doInitPackageItem()
        );
        break;
      }
      case constants.ModalMode.purchaseBundle: {
        const listExistService = newData.services.filter(val => !val.isBundle || val.subscriptionName !== name);
        let newServices = [];

        newServices = [
          ...listExistService,
          ...services.map((item, index) => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === item?.components[0]?.serviceType
            );
            return {
              subscriptionName: name,
              provisioningId: existService ? existService.provisioningId : null,
              isBundle: true,
              ...initServiceWithSelectBundle(item, getLastIndex({ data: listExistService }) + index - 1),
            };
          }),
        ];
        newData.services = newServices;

        this.setState({
          orderDetail: newData,
          mode: constants.ModalMode.none,
        });
        break;
      }
      case constants.ModalMode.purchaseAlaCarte: {
        const listExistService = newData.services.filter(val => !val.isPriceOffer || val.subscriptionName !== name);

        let newServices = [];
        const existService = newData.services.find(
          val => val.subscriptionName === name && val.serviceType === services[0]?.serviceType
        );
        const service = groupBy(services, item => {
          return item.serviceType;
        });
        let serviceIndex = 0;

        newServices = [
          ...listExistService,
          ...map(service, item => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === services[0]?.serviceType
            );
            serviceIndex += 1;
            return {
              provisioningId: existService ? existService.provisioningId : null,
              subscriptionName: name,
              isPriceOffer: true,
              ...initServiceFromPriceOffers(item, getLastIndex({ data: listExistService }) + serviceIndex - 1),
            };
          }),
          // {
          //   ...initServiceFromPriceOffers(services, getLastIndex({ data: listExistService })),
          //   provisioningId: existService ? existService.provisioningId : null,
          //   subscriptionName: name,
          //   isPriceOffer: true,
          // },
        ];
        newData.services = newServices;

        this.setState({
          orderDetail: newData,
          mode: constants.ModalMode.none,
        });
        break;
      }
      default:
        break;
    }
  };

  onCancelModal = () => {
    this.setState({
      isOpenPriceOfferModal: false,
    });
  };

  openSelectModal = ({ mode, services, subscriptionName }) => {
    // this.getTypePurchase(services);
    if (mode === constants.ModalMode.purchasePackage) {
      return this.setState({
        isOpenPriceOfferModal: true,
        modalTitle: 'label.choosePackage',
        mode: constants.ModalMode.purchasePackage,
        isBundle: false,
        isPackage: true,
        isPriceOffers: false,
        subscriptionName,
        tableColumns: constants.tableSelectPackageColumns,
      });
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      return this.setState({
        isOpenPriceOfferModal: true,
        modalTitle: 'label.chooseBundle',
        mode: constants.ModalMode.purchaseBundle,
        tableColumns: constants.tableSelectBundleColumns,
        isBundle: true,
        isPackage: false,
        isPriceOffers: false,
        subscriptionName,
      });
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      return this.setState({
        isOpenPriceOfferModal: true,
        modalTitle: 'label.choosePriceOffers',
        mode: constants.ModalMode.purchaseAlaCarte,
        tableColumns: constants.tableSelectPriceOfferColumns,
        isBundle: false,
        isPackage: false,
        isPriceOffers: true,
        subscriptionName,
      });
    }
    // this.setState({
    //   isOpenPriceOfferModal: true,
    //   subscriptionName,
    // });
  };

  onToggleModalShowTask = () => {
    const { isOpenModalShowTask, provisioningEnabled } = this.state;
    this.setState(
      {
        isOpenModalShowTask: !isOpenModalShowTask,
        activeShowTasksTab: {},
        activeShowInOutBoundTab: {},
        omsTasksData: null,
        omsInboundTasksData: null,
        omsOutboundTasksData: null,
      },
      () => {
        if ((!provisioningEnabled || provisioningEnabled.value === 'false') && !isOpenModalShowTask) {
          this.doGetOrderOmsTasks();
        }
      }
    );
  };

  onToggleModalShowProvisionig = ({ item }) => {
    this.setState({
      dataViewProvisionig: item,
    });
  };

  onChangeProvisioningId = ({ name, value, item, subscriptionName }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    for (let i = 0; i < newData.services.length; i++) {
      if (
        newData.services[i].serviceType === item.serviceType &&
        subscriptionName === newData.services[i].subscriptionName
      ) {
        newData.services[i][name] = value;
      }
    }
    this.setState({ orderDetail: newData });
  };

  searchItemsOfSelectModal = (postData, cb) => {
    const { mode } = this.state;
    if (mode === constants.ModalMode.purchasePackage) {
      this.props.selectPackageId(postData, cb);
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      this.props.selectBundleId(postData, cb);
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      this.props.selectPriceOfferId(postData, cb);
    }
  };

  checkEnableButtonByOrderStatus = () => {
    const { orderDetail } = this.state;
    if (
      orderDetail.status === orderEnum.status.create ||
      orderDetail.status === orderEnum.status.pending ||
      orderDetail.status === orderEnum.status.approved ||
      orderDetail.status === orderEnum.status.failed ||
      orderDetail.status === orderEnum.status.provisioningError ||
      orderDetail.status === orderEnum.status.provisioningInitiated ||
      orderDetail.status === orderEnum.status.billingError
    ) {
      return false;
    }
    return true;
  };

  checkEnableEditBillableFields = val => {
    const { orderDetail } = this.state;
    if (
      orderDetail.status === orderEnum.status.provisioningInitiated &&
      (val.name === 'alternateDescription' ||
        val.name === 'percentile' ||
        val.name === 'recurringUnit' ||
        val.name === 'recurringFrequency' ||
        val.name === 'advanceFlag' ||
        val.name === 'cutOffDate' ||
        val.name === 'partMonthWaiveOffFlag')
    ) {
      return false;
    }
    if (
      orderDetail.status === orderEnum.status.create ||
      orderDetail.status === orderEnum.status.pending ||
      orderDetail.status === orderEnum.status.failed ||
      orderDetail.status === orderEnum.status.provisioningError ||
      val.name === 'currency' ||
      val.name === 'alternateDescription' ||
      val.name === 'percentile' ||
      val.name === 'cutOffDate'
    ) {
      return false;
    }
    return true;
  };

  groupBundleServer = services => {
    const dataService = [];
    const allTypeService = union(
      [],
      (services || []).map(item => item.serviceType)
    );

    if (!isEmpty(allTypeService)) {
      allTypeService.forEach(item => {
        const newArrData = services.filter(value => value.serviceType === item);
        dataService.push(newArrData);
      });
    }
    return dataService;
  };

  onHandleRemoveItem = (itemRemove, indexInject) => {
    const { orderDetail } = this.state;
    // // const serviceTypeRemove = [];
    const newData = cloneDeep(orderDetail);
    // console.log('newData.services', newData.services);
    // console.log('itemRemove', itemRemove);
    // const indexService = newData.services.findIndex(val => val.index !== itemRemove.index);
    // // newData.services = newData.services.filter(val => val.index !== itemRemove.index);

    newData.services[indexInject] = {
      index: itemRemove.index,
      subscriptionName: itemRemove.subscriptionName,
      // isRemoved: true,
    };
    // if (mode === constants.ModalMode.purchasePackage) {
    //   serviceTypeRemove = orderDetail.services
    //     .filter(item => item.packageId === itemRemove.packageId)
    //     .map(item => item.serviceType);
    // }
    // this.setState({ itemRemove, isOpenModalDelete: true, serviceTypeRemove, mode });
    this.setState({ orderDetail: newData });
  };

  onRemoveLine = ({ itemService, itemLine, indexOverrideLine }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    // const indexService = newData.services.findIndex(item => item.serviceType === itemService.serviceType);
    const indexLine = newData.services[indexOverrideLine].lines.findIndex(
      item => item.priceOfferId === itemLine.priceOfferId
    );
    if (indexOverrideLine > -1 && indexLine > -1) {
      // const indexLineFromAPI = newData.services[indexOverrideLine].lines[indexLine].index;
      newData.services[indexOverrideLine].lines.splice(indexLine, 1);
    }
    this.setState({ orderDetail: newData, isOpenModalDelete: false });
  };

  onAcceptRemoveItem = () => {
    const { orderDetail, itemRemove, mode, dataAfterRemoveLine } = this.state;
    let newData = cloneDeep(orderDetail);
    if (mode === constants.ModalMode.purchasePackage) {
      newData.services.forEach((item, i) => {
        const indexData = item.index;
        if (item.packageId === itemRemove.packageId) {
          newData.services[i] = {};
          newData.services[i].index = indexData;
        }
      });
    }

    if (mode === constants.ModalMode.purchaseAlaCarte) {
      newData = dataAfterRemoveLine;
    }

    this.setState({ orderDetail: newData, isOpenModalDelete: false, dataAfterRemoveLine: {} });
  };

  onToggleModalDelete = () => {
    this.setState({ isOpenModalDelete: false });
  };

  onChangeLocation = ({ name, value, index }) => {
    try {
      const { orderDetail, activeSubTab } = this.state;
      const newData = cloneDeep(orderDetail);
      newData.billables[activeSubTab.index].locations[index][name] = value;
      this.setState({ orderDetail: newData });
      // setOrderDetail(newData);
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSubscriptionData = ({ name, value, subscriptionData }) => {
    try {
      const { orderDetail } = this.state;
      const newData = cloneDeep(orderDetail);
      const indexSub = newData.subscriptions.findIndex(val => val.index === subscriptionData.index);
      newData.subscriptions[indexSub][name] = value;
      this.setState({ orderDetail: newData });
    } catch (error) {
      console.log(error);
    }
  };

  renderListButton = ({ services, name }) => {
    const { mode } = this.state;
    return (
      <div className="col-md-12 mt-3 p-0 text-center">
        <ChooseKindService
          key={name}
          onSelectService={e => this.onSelectService({ ...e, name })}
          mode={mode}
          onChangeMode={mode => this.openSelectModal({ mode, services: services || [], subscriptionName: name })}
          isMulti
          onSelectModalDiscounts={() => this.onToggleModalDiscounts({ subscriptionName: name })}
          services={services || []}
        />
      </div>
    );
  };

  renderServiceTables = () => {
    const { orderDetail, isOverrideLines, activeTabSubscriptionTab, indexOverrideLine } = this.state;
    const { t } = this.props;
    let newServices = cloneDeep(orderDetail.services) || [];
    if (newServices && newServices.length) {
      newServices = sortBy(
        newServices.map((val, index) => ({ ...val, indexInject: index })).filter(val => size(val) > 3),
        ['subscriptionName']
      );
    } else if (orderDetail.subscriptions) {
      return map(orderDetail.subscriptions, sb => (
        <FormCollapse
          key={sb.subscriptionName}
          isActive={sb.subscriptionName === activeTabSubscriptionTab.name && activeTabSubscriptionTab.isActive}
          title={`${t('label.subscription')}: ${sb.subscriptionName || ''}`}
          state={sb.subscriptionName}
          onToggleTab={this.onToggleSubscriptionTab}
          isNoT
          wrapperClass="col-md-12"
          isSubCollapse
          isActiveSubForm={sb.subscriptionName === activeTabSubscriptionTab.name && activeTabSubscriptionTab.isActive}
        >
          <div className="col-md-12">
            <SubscriptionsForm
              data={sb || {}}
              onHandleChange={e => this.onChangeSubscriptionData({ ...e, subscriptionData: sb })}
              isDisabled={!isEdit}
            />
          </div>
          {orderDetail.status === 'CREATED' && this.renderListButton({ services: [], name: sb.subscriptionName })}
        </FormCollapse>
      ));
    }
    const groupServices = groupBy(newServices, item => {
      return item.subscriptionName;
    });

    // console.log('groupServices', groupServices);

    return map(groupServices, (services, name) => {
      let subscriptionData = {};
      let buttonText = 'label.addPriceOffer';
      let mode = constants.ModalMode.purchaseAlaCarte;
      let isBundle = false;
      if (services[0].packageId) {
        buttonText = 'label.addPackage';
        mode = constants.ModalMode.purchasePackage;
      } else if (services[0].bundleId) {
        buttonText = 'label.addBundle';
        mode = constants.ModalMode.purchaseBundle;
        isBundle = false;
      }
      if (orderDetail.subscriptions) {
        subscriptionData = orderDetail.subscriptions.find(val => val.subscriptionName === name);
      }
      return (
        <FormCollapse
          key={name}
          isActive={name === activeTabSubscriptionTab.name && activeTabSubscriptionTab.isActive}
          title={`${t('label.subscription')}: ${name || ''}`}
          state={name}
          onToggleTab={this.onToggleSubscriptionTab}
          isNoT
          wrapperClass="col-md-12"
          isSubCollapse
          isActiveSubForm={name === activeTabSubscriptionTab.name && activeTabSubscriptionTab.isActive}
        >
          <div className="col-md-12">
            <SubscriptionsForm
              data={subscriptionData || {}}
              onHandleChange={e => this.onChangeSubscriptionData({ ...e, subscriptionData })}
              isDisabled={!isEdit}
            />
            {orderDetail.status === 'CREATED' && this.renderListButton({ services, name })}
            <ServiceEditForm
              // key={index}
              title={t('label.services')}
              // isOverrideLines={isOverrideLines[service.indexInject]}
              onChangeFormLine={this.onChangeFormLine}
              isRemoveTrashFromLine={mode === constants.ModalMode.purchasePackage}
              // isRemoveTrashFromTable={mode === constants.ModalMode.purchaseAlaCarte}
              onChangeProvisioningId={e => this.onChangeProvisioningId({ ...e, subscriptionName: name })}
              onHandleRemoveItem={this.onHandleRemoveItem}
              services={services}
              onRemoveLine={this.onRemoveLine}
              indexOverrideLine={indexOverrideLine}
              handleClickServicesFormBtn={this.handleClickServicesFormBtn}
              isShowLine={orderDetail && (orderDetail.status === 'COMPLETED' || orderDetail.status === 'CANCELLED')}
              isDisabled={!isEdit}
              onToggleModalShowProvisionig={this.onToggleModalShowProvisionig}
            />
          </div>
        </FormCollapse>
      );
    });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  doGetOrderOmsTasks = () => {
    const {
      getOrderOmsTasks,
      match: {
        params: { id },
      },
    } = this.props;
    const { omsTasksData } = this.state;
    if (!omsTasksData) {
      getOrderOmsTasks(id, ({ success, data }) => {
        if (success) this.setState({ omsTasksData: data || [] });
      });
    }
  };

  doGetOrderProvisioningOutboundTasks = () => {
    const {
      getOrderProvisioningOutboundTasks,
      match: {
        params: { id },
      },
    } = this.props;
    const { omsOutboundTasksData } = this.state;
    if (!omsOutboundTasksData) {
      getOrderProvisioningOutboundTasks(id, ({ success, data }) => {
        if (success) this.setState({ omsOutboundTasksData: data || [] });
      });
    }
  };

  doGetOrderProvisioningInboundTasks = () => {
    const {
      getOrderProvisioningInboundTasks,
      match: {
        params: { id },
      },
    } = this.props;

    const { omsInboundTasksData } = this.state;
    if (!omsInboundTasksData) {
      getOrderProvisioningInboundTasks(id, ({ success, data }) => {
        if (success) this.setState({ omsInboundTasksData: data?.provisioning ? data.provisioning || [] : [] });
      });
    }
  };

  onToggleTabShowTask = activeTabName => {
    const { activeShowTasksTab } = this.state;
    if (activeTabName === 'omsTasks') {
      this.doGetOrderOmsTasks();
    }
    if (activeShowTasksTab.name === activeTabName) {
      this.setState({
        activeShowTasksTab: { name: activeTabName, isActive: !activeShowTasksTab.isActive },
      });
    } else {
      this.setState({ activeShowTasksTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleTabInOutboundTask = activeTabName => {
    const { activeShowInOutBoundTab } = this.state;
    if (
      activeTabName === 'outbound' &&
      (!activeShowInOutBoundTab.isActive || activeShowInOutBoundTab.name !== 'outbound')
    ) {
      this.doGetOrderProvisioningOutboundTasks();
    }
    if (
      activeTabName === 'inbound' &&
      (!activeShowInOutBoundTab.isActive || activeShowInOutBoundTab.name !== 'inbound')
    ) {
      this.doGetOrderProvisioningInboundTasks();
    }
    if (activeShowInOutBoundTab.name === activeTabName) {
      this.setState({
        activeShowInOutBoundTab: { name: activeTabName, isActive: !activeShowInOutBoundTab.isActive },
      });
    } else {
      this.setState({ activeShowInOutBoundTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleProvisioningTab = (activeTabName, serviceType) => {
    const { activeProvisioningTab } = this.state;
    if (activeProvisioningTab.name === activeTabName && serviceType === activeProvisioningTab.serviceType) {
      this.setState({
        activeProvisioningTab: { name: activeTabName, isActive: !activeProvisioningTab.isActive, serviceType },
      });
    } else {
      this.setState({ activeProvisioningTab: { name: activeTabName, isActive: true, serviceType } });
    }
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { getCurrencyConfigApp, getOrderBillableByIdAndIndex } = this.props;

    const { activeSubTab, currencyOptions, billableServiceData } = this.state;
    if (activeSubTab.index === indexItem) {
      this.setState({ activeSubTab: {}, billableServiceData: {} });
      if (!billableServiceData.isCopyAllChangeRecurring) {
        this.setState({ oldBillableServiceData: {} });
      }
    }
    if (activeSubTab.index !== indexItem) {
      this.setState({ activeSubTab: { key, index: indexItem }, billableServiceData: {} });
      this.doGetOrderBillableByIdAndIndex({ indexItem });
      if (!currencyOptions || !currencyOptions.length)
        getCurrencyConfigApp('', ({ success, data }) => {
          if (success && data && data.currencyListConfig) {
            const currencyOptions = data.currencyListConfig.map(val => ({
              label: `${val.name} (${val.currencyId})`,
              value: val.currencyId,
            }));
            this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
          }
        });
    }
  };

  doGetOrderBillableByIdAndIndex = ({ indexItem }) => {
    const { getOrderBillableByIdAndIndex } = this.props;
    const { orderDetail } = this.state;
    getOrderBillableByIdAndIndex(
      {
        orderId: orderDetail.id,
        index: orderDetail.billables[indexItem] ? `${orderDetail.billables[indexItem].index}` : '-1',
      },
      ({ success, data }) => {
        if (success && data) {
          if (
            data &&
            data.locations &&
            data.locations.length &&
            orderDetail.billables[indexItem] &&
            orderDetail.billables[indexItem].locations
          ) {
            orderDetail.billables[indexItem].locations = orderDetail.billables[indexItem].locations.map(val => {
              const itemSelect = data.locations.find(i => i.index === val.index);
              return {
                ...val,
                percent: itemSelect && itemSelect.percent ? itemSelect.percent : null,
              };
            });
          }
          this.doInitDataBillable(data);
        }
      }
    );
  };

  doInitDataBillable = data => {
    const { defaultCurrency } = this.state;
    const newData = cloneDeep(data);
    newData.advanceFlag = newData.advanceFlag ? newData.advanceFlag : false;
    newData.cutOffDate = typeof newData.cutOffDate === 'number' ? `${newData.cutOffDate}` : null;
    newData.partMonthWaiveOffFlag = newData.partMonthWaiveOffFlag ? newData.partMonthWaiveOffFlag : false;
    newData.recurringType = newData.recurringType ? newData.recurringType : 'FORWARD';
    newData.recurringUnit = newData.recurringUnit ? newData.recurringUnit : 'MONTHS';
    newData.recurringFrequency = newData.recurringFrequency ? newData.recurringFrequency : '1';
    newData.calendarMonthFlag = newData.calendarMonthFlag ? newData.calendarMonthFlag : true;
    newData.alignedToCycle = newData.alignedToCycle ? newData.alignedToCycle : false;
    newData.currency = newData.currency ? newData.currency : defaultCurrency.value;
    newData.isGrant = newData.isGrant ? newData.isGrant : false;
    this.setState({
      billableServiceData: convertBooleanObject(newData),
      isUpdatedExhibits: false,
      invoiceExhibitsChecking: newData.invoiceExhibits,
      oldBillableServiceData: {},
      dataSubmitBillable: {},
    });
  };

  onTogglePricing = (index, item) => {
    this.doGetBillableServicePricing(item.serviceId, item);
  };

  doGetBillableServicePricing = (serviceId, item) => {
    const {
      getBillableServicePricing,
      match: {
        params: { id },
      },
    } = this.props;
    const { orderDetail } = this.state;
    getBillableServicePricing(
      { serviceId, orderId: id, accountId: orderDetail.accountId, omsInternalId: item.omsInternalId },
      ({ success, data }) => {
        if (success && data && data.length) {
          this.setState({ isOpenPricingModal: true, pricingData: data });
        }
      }
    );
  };

  onToggleProvisioningAttributes = (index, item) => {
    const { isOpenPricingModalProvisioningAttributes } = this.state;
    this.setState({
      isOpenPricingModalProvisioningAttributes: !isOpenPricingModalProvisioningAttributes,
      itemSelectProvisioningAttributes: item || null,
    });
  };

  onOpenModalSplitService = ({ item, type }) => {
    try {
      if (type === 'splitService') {
        const { splitData } = this.state;
        const currentSplit = splitData.filter(val => val.omsInternalId === item.omsInternalId);
        const { serviceId, serviceName, omsInternalId, quantity, totalPrice } = item;
        this.setState({
          splitData:
            currentSplit && currentSplit.length
              ? splitData
              : [
                  ...splitData,
                  {
                    serviceId,
                    serviceName,
                    omsInternalId,
                    quantity,
                    totalPrice: '0',
                    servicePrice: '0',
                    splitAccounts: '',
                  },
                ],
          isOpenModalSplit: true,
          typeSelect: 'splitService',
          totalQuanity: quantity,
          omsInternalIdSelected: omsInternalId,
          mainData: [{ serviceId, serviceName, omsInternalId, quantity, totalPrice }],
        });
      }
    } catch (error) {}
  };

  onChangeSplitAccount = ({ item, type, value }) => {
    try {
      if (type === 'splitService') {
        const { t } = this.props;
        const { splitData, isAccountSelected } = this.state;
        const isExitAccountId = splitData.find(val => val.accountId === value);
        const { serviceId, serviceName, omsInternalId, quantity } = item;

        if (value) {
          this.setState({
            splitData:
              splitData && splitData.length
                ? [
                    ...splitData.filter(val => val.omsInternalId !== omsInternalId),
                    {
                      serviceId,
                      serviceName,
                      omsInternalId,
                      quantity,
                      splitAccounts: value,
                      accountId: value,
                      disabledPopup: true,
                    },
                  ]
                : [
                    {
                      serviceId,
                      serviceName,
                      omsInternalId,
                      quantity,
                      accountId: value,
                      splitAccounts: value,
                      disabledPopup: true,
                    },
                  ],
            typeSelect: 'splitService',
            totalQuanity: quantity,
            isAccountSelected: uniq([...isAccountSelected, omsInternalId]),
          });
        } else {
          this.setState({
            splitData:
              splitData && splitData.length
                ? [...splitData.filter(val => val.omsInternalId !== omsInternalId)]
                : [
                    {
                      serviceId,
                      serviceName,
                      omsInternalId,
                      quantity,
                      accountId: value,
                      splitAccounts: value,
                      disabledPopup: true,
                    },
                  ],
            typeSelect: 'splitService',
            totalQuanity: 0,
            isAccountSelected: uniq([...isAccountSelected, omsInternalId]),
          });
        }
      }
    } catch (error) {}
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onAddNewSplit = () => {
    const { typeSelect } = this.state;
    if (typeSelect === 'splitService') {
      const { splitData, omsInternalIdSelected } = this.state;
      let newData = cloneDeep(splitData).filter(val => val.omsInternalId === omsInternalIdSelected);
      const splitDataNew = cloneDeep(splitData);
      const { serviceId, serviceName, omsInternalId, quantity } = newData[0] || {};
      newData = [
        ...splitDataNew,
        {
          serviceId: serviceId || '',
          omsInternalId: omsInternalId || '',
          quantity: quantity || '',
          serviceName: serviceName || '',
          totalPrice: '0',
          servicePrice: '0',
        },
      ];
      this.setState({ splitData: newData });
    }
  };

  onHandleOpenModalSearchAccount = ({ item, idx }) => {
    this.setState({
      isOpenModalAccount: true,
      indexSelectAccountId: idx,
      accountIdSelect: item && item.accountId ? item.accountId : null,
    });
  };

  onSelectAccountId = (id, row) => {
    const { indexSelectAccountId, splitData, typeSelect } = this.state;
    const { t } = this.props;
    try {
      if (typeSelect === 'splitService') {
        const newData = cloneDeep(splitData);
        newData[indexSelectAccountId].accountId = id;
        newData[indexSelectAccountId].accountDetails = row;
        this.setState({
          isOpenModalAccount: null,
          indexSelectAccountId: null,
          accountIdSelect: null,
          splitData: newData,
        });
      }
    } catch (error) {}
  };

  onHanldeSubmitSplitAccount = isSaveSplit => {
    const {
      processSplitOrder,
      saveSplitOpportunity,
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const {
      splitData,
      orderDetail,
      splitEquipments,
      splitLocations,
      accountBillingIdOptions,
      isMultiAccount,
    } = this.state;
    const newSplitData = splitData.filter(val => !!val.accountId);
    if (
      orderDetail.billables &&
      uniqBy(newSplitData, 'omsInternalId').length !== orderDetail.billables.length &&
      !isSaveSplit
    ) {
      return toast.error(t('message.messageErrorSplitOrder'));
    }
    const splitDataGroup = groupBy(newSplitData, item => {
      return item.omsInternalId;
    });
    let sigleAccountPayload = [];
    let splitAccountsPayload = [];
    map(splitDataGroup, (value, key) => {
      if (value && value.length === 1 && value[0].disabledPopup) {
        const { serviceId, omsInternalId, accountId, quantity } = value[0];
        sigleAccountPayload = [...sigleAccountPayload, { serviceId, omsInternalId, accountId, quantity }];
      }

      if (value && value.length && !value[0].disabledPopup) {
        const { serviceId, omsInternalId } = value[0];
        const splitAccounts = cloneDeep(value || []).map(val => {
          const { accountId, quantity, totalPrice, servicePrice } = val;
          return {
            accountId,
            quantity,
            totalPrice,
            servicePrice: servicePrice || '0',
          };
        });
        splitAccountsPayload = [...splitAccountsPayload, { serviceId, omsInternalId, splitAccounts }];
      }
    });

    const nonBillables = [];
    const infraNonBillables = [];
    const locations = [];
    if (orderDetail.billables && splitData.length && orderDetail.billables.length === 0) {
      return toast.error(
        'Complete the Account Selection for all Equipments, Infrastructure Equipments, Locations, Services'
      );
    }
    cloneDeep(splitEquipments || []).forEach((val, index) => {
      const { accountId } = val;
      if (
        orderDetail &&
        orderDetail.nonBillables &&
        orderDetail.nonBillables.length &&
        orderDetail.nonBillables[index] &&
        orderDetail.nonBillables[index].omsInternalId
      ) {
        nonBillables.push({ omsInternalId: orderDetail.nonBillables[index].omsInternalId, accountId });
      }
    });
    cloneDeep(splitLocations || []).forEach((val, index) => {
      const { accountId } = val;
      if (
        orderDetail &&
        orderDetail.locations &&
        orderDetail.locations.length &&
        orderDetail.locations[index] &&
        orderDetail.locations[index].omsInternalId
      ) {
        locations.push({ omsInternalId: orderDetail.locations[index].omsInternalId, accountId });
      }
    });
    cloneDeep(infraNonBillables || []).forEach((val, index) => {
      const { accountId } = val;
      if (
        orderDetail &&
        orderDetail.infraNonBillables &&
        orderDetail.infraNonBillables.length &&
        orderDetail.infraNonBillables[index] &&
        orderDetail.infraNonBillables[index].omsInternalId
      ) {
        infraNonBillables.push({ omsInternalId: orderDetail.infraNonBillables[index].omsInternalId, accountId });
      }
    });
    const billingAccounts = cloneDeep(newSplitData || []).map(val => {
      const { accountId, accountDetails } = val;
      let newAccountDetails = cloneDeep(accountDetails);
      if (!accountDetails && accountBillingIdOptions && accountBillingIdOptions.length) {
        newAccountDetails = accountBillingIdOptions.find(val => val.value === accountId)
          ? accountBillingIdOptions.find(val => val.value === accountId).accountDetails
          : {};
      }
      return {
        id: accountId,
        currency: newAccountDetails && newAccountDetails.currency ? newAccountDetails.currency : '',
        paymentProfiles:
          newAccountDetails && newAccountDetails.paymentProfiles
            ? newAccountDetails.paymentProfiles.map(val => ({ paymentMethod: val.paymentMethod }))
            : [],
      };
    });
    const payloadOrder = {
      order: {
        accountId: orderDetail.accountId,
        type: orderDetail.type,
        id: orderDetail.id,
        userId: getUserId() || '',
        billables: [...sigleAccountPayload, ...splitAccountsPayload].length
          ? [...sigleAccountPayload, ...splitAccountsPayload]
          : null,
        nonBillables: nonBillables && nonBillables.length ? nonBillables : [],
        infraNonBillables: infraNonBillables && infraNonBillables.length ? infraNonBillables : [],
        locations,
      },
      billingAccounts: billingAccounts && billingAccounts.length ? uniqBy(billingAccounts, 'id') : null,
    };
    if (isSaveSplit)
      saveSplitOpportunity({ orderId: id, splitOrder: payloadOrder }, ({ success }) => {
        if (success) this.doGetOrderSplitOpportunity();
      });
    if (!isSaveSplit) {
      processSplitOrder(payloadOrder, ({ success, data }) => {
        if (success) {
          const newData = cloneDeep(orderDetail);
          newData.status = 'COMPLETED';
          this.setState({
            splitData: [],
            isOpenModalSplit: false,
            disabledProcess: true,
            orderDetail: newData,
            isOpenModalValidationCutOffUsage: false,
          });
        }
      });
    }
  };

  onUndoProcessSplitOrder = () => {
    const {
      undoProcessSplitOrder,
      getOrderDetail,
      match: {
        params: { id },
      },
    } = this.props;
    undoProcessSplitOrder(id, ({ success }) => {
      if (success) {
        getOrderDetail(id);
        this.setState({ isOpenModalUndoProcessSplit: false });
      }
    });
  };

  onSelectSplit = () => {
    this.setState({ wasValidated: true });
    // e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const {
      typeSelect,
      splitData,
      splitEquipments,
      splitLocations,
      totalQuanity,
      infraNonBillables,
      omsInternalIdSelected,
      isAccountSelected,
      mainData,
    } = this.state;
    const { t } = this.props;
    const currentSplit = splitData.filter(val => val.omsInternalId === omsInternalIdSelected);
    const newSplitData = cloneDeep(splitData).map(val => {
      // const { disabledPopup, ...rest } = val;
      return val;
    });
    if (typeSelect === 'splitService' && currentSplit && currentSplit.length) {
      let total = 0;
      let totalAmount = 0;
      currentSplit.forEach(val => {
        const { quantity, totalPrice } = val;
        if (quantity) {
          total += Number.parseFloat(quantity);
        }
        if (totalPrice) {
          totalAmount += Number.parseFloat(totalPrice);
        }
      });
      if (mainData && mainData[0] && totalAmount !== Number.parseFloat(mainData[0].totalPrice)) {
        return toast.error(`${t('message.messageValidTotalAmount')} ${Number.parseFloat(mainData[0].totalPrice)}`);
      }
      if (total !== Number.parseFloat(totalQuanity) && Number.parseFloat(mainData[0].quantity) !== 1) {
        return toast.error(`${t('message.messageValidTotalQuantity')} ${totalQuanity}`);
      }
    }
    this.setState({
      isOpenModalSplit: false,
      isAccountSelected: [...isAccountSelected.filter(val => val !== omsInternalIdSelected)],
      splitData: newSplitData,
    });
  };

  onChangeBillableField = ({ name, value, item }) => {
    const { billableServiceData, dataSubmitBillable } = this.state;
    let { oldBillableServiceData } = this.state;
    const newData = cloneDeep(billableServiceData);
    if (
      (name === 'percentile' || name === 'recurringFrequency' || name === 'invoiceExhibits') &&
      value &&
      (Number.parseFloat(value) > 100 || Number.parseFloat(value) < 0)
    ) {
      return '';
    }
    // if (name === 'cutOffDate' && value && (Number.parseFloat(value) > 31 || Number.parseFloat(value) < 1)) {
    //   return '';
    // }
    newData[name] = value;
    dataSubmitBillable[name] = value;
    if (item && item.loadType.toUpperCase() === 'Renta'.toUpperCase()) {
      oldBillableServiceData = cloneDeep(newData);
    }
    this.setState({ billableServiceData: newData, oldBillableServiceData, dataSubmitBillable });
  };

  onChangeIsCopyAllChangeRecurring = () => {
    try {
      const { billableServiceData } = this.state;
      const newData = cloneDeep(billableServiceData);
      newData.isCopyAllChangeRecurring = !newData.isCopyAllChangeRecurring;
      if (newData.isCopyAllChangeRecurring && Object.keys(newData).length > 2) {
        const { isCopyAllChangeRecurring, ...rest } = newData;
        return this.setState({
          oldBillableServiceData: {
            ...cloneDeep(rest),
          },
          billableServiceData: newData,
          isOpenModalCopyChangeToAllRecurring: false,
        });
      }
      return this.setState({
        oldBillableServiceData: {},
        billableServiceData: newData,
        isOpenModalCopyChangeToAllRecurring: false,
      });
    } catch (error) {
      return console.log(error);
    }
  };

  onModifyOrderBillableServices = isOnly3Field => {
    const { modifyOrderBillableServices } = this.props;
    const { billableServiceData, orderDetail, activeSubTab, dataSubmitBillable } = this.state;
    const { locations, isCopyAllChangeRecurring } = cloneDeep(billableServiceData);
    const payload = cloneDeep(dataSubmitBillable);
    if (payload.advanceFlag) {
      payload.advanceFlag = payload.advanceFlag === 'TRUE' ? 'true' : 'false';
    }
    if (payload.partMonthWaiveOffFlag) {
      payload.partMonthWaiveOffFlag = payload.partMonthWaiveOffFlag === 'TRUE' ? 'true' : 'false';
    }
    if (payload.alignedToCycle) {
      payload.alignedToCycle = payload.alignedToCycle === 'TRUE' ? 'true' : 'false';
    }
    if (payload.calendarMonthFlag) {
      payload.calendarMonthFlag = payload.calendarMonthFlag === 'TRUE' ? 'true' : 'false';
    }
    if (payload.isGrant) {
      payload.isGrant = payload.isGrant === 'TRUE' ? 'true' : 'false';
    }
    const newLocations = {};
    let payloadMuilLocations = null;
    if (
      orderDetail.billables &&
      orderDetail.billables[activeSubTab.index] &&
      orderDetail.billables[activeSubTab.index].locations &&
      orderDetail.billables[activeSubTab.index].locations.length &&
      !isCopyAllChangeRecurring &&
      orderDetail.billables[activeSubTab.index].isChangeLocation
    ) {
      newLocations.locations = orderDetail.billables[activeSubTab.index].locations.map(val => {
        const { index, percent, pointName, omsInternalId, pointId } = val;
        return { index, percent, pointName, omsInternalId, pointId };
      });
    }

    if (
      orderDetail.billables &&
      orderDetail.billables[activeSubTab.index] &&
      orderDetail.billables[activeSubTab.index].locations &&
      orderDetail.billables[activeSubTab.index].locations.length &&
      isCopyAllChangeRecurring
    ) {
      let newBillable = cloneDeep(orderDetail.billables);
      const currentBillables = orderDetail.billables[activeSubTab.index];
      newBillable = newBillable.filter(val => val.index !== orderDetail.billables[activeSubTab.index].index);
      newBillable = newBillable.filter(
        val => val.cutOffDate !== -2 || (val.cutOffDate === -2 && val.loadType.toUpperCase() !== 'Renta'.toUpperCase())
      );
      // newBillable = newBillable.filter(
      //   val => val.loadType.toUpperCase() === 'Renta'.toUpperCase() || Number.parseFloat(val.invoiceExhibits || 0) > 1
      // );
      payloadMuilLocations = {
        dataSubmit: [currentBillables, ...newBillable].map(val => {
          const { locations, isChangeLocation } = val;
          const newPayloadWithLocation = {};
          if (isChangeLocation) {
            newPayloadWithLocation.locations = locations
              ? locations.map(val => {
                  const { index, percent, pointName, omsInternalId, pointId } = val;
                  return { index, percent, pointName, omsInternalId, pointId };
                })
              : [];
          }
          if (isOnly3Field) {
            return {
              currency: payload.currency,
              alternateDescription: payload.alternateDescription,
              invoiceExhibits: payload.invoiceExhibits,
              id: orderDetail.id,
              cutOffDate: payload.cutOffDate,
              index: val.index || '-1',
              ...newPayloadWithLocation,
            };
          }

          return {
            ...payload,
            id: orderDetail.id,
            index: val.index || '-1',
            ...newPayloadWithLocation,
          };
        }),
      };
    }

    if (isOnly3Field) {
      const newPayload = {
        currency: payload.currency,
        alternateDescription: payload.alternateDescription,
        invoiceExhibits: payload.invoiceExhibits,
        cutOffDate: payload.cutOffDate,
        id: orderDetail.id,
        index: orderDetail.billables[activeSubTab.index] ? `${orderDetail.billables[activeSubTab.index].index}` : '-1',
        ...newLocations,
      };
      return modifyOrderBillableServices(payloadMuilLocations || newPayload, ({ success, data }) => {
        if (success) {
          const {
            match: {
              params: { id },
            },
          } = this.props;
          this.props.getOrderDetail(id, () => {
            if (orderDetail.billables[activeSubTab.index]) {
              this.doGetOrderBillableByIdAndIndex({ indexItem: activeSubTab.index });
            }
          });
        }
        if (success && Number.parseFloat(payload.invoiceExhibits) > 0) {
          this.setState({
            isUpdatedExhibits: true,
            invoiceExhibitsChecking: Number.parseFloat(payload.invoiceExhibits),
          });
        }
        if (success && !Number.parseFloat(payload.invoiceExhibits)) {
          this.setState({
            isUpdatedExhibits: true,
            invoiceExhibitsChecking: Number.parseFloat(payload.invoiceExhibits),
          });
        }
        if (data && data.length) {
          this.doInitDataBillable(data[0]);
        }
      });
    }

    const newPayload = {
      ...payload,
      id: orderDetail.id,
      index: orderDetail.billables[activeSubTab.index] ? `${orderDetail.billables[activeSubTab.index].index}` : '-1',
      ...newLocations,
    };
    modifyOrderBillableServices(payloadMuilLocations || newPayload, ({ success, data }) => {
      if (success) {
        const {
          match: {
            params: { id },
          },
        } = this.props;
        this.props.getOrderDetail(id, () => {
          if (orderDetail.billables[activeSubTab.index]) {
            this.doGetOrderBillableByIdAndIndex({ indexItem: activeSubTab.index });
          }
        });
      }
      if (success && Number.parseFloat(payload.invoiceExhibits) > 0) {
        this.setState({
          isUpdatedExhibits: true,
          invoiceExhibitsChecking: Number.parseFloat(payload.invoiceExhibits),
        });
      }
      if (success && !Number.parseFloat(payload.invoiceExhibits)) {
        this.setState({
          isUpdatedExhibits: true,
          invoiceExhibitsChecking: Number.parseFloat(payload.invoiceExhibits),
        });
      }
      if (data && data.length) {
        this.doInitDataBillable(data[0]);
      }
    });
  };

  onChangeLocationPercent = ({ name, value, index }) => {
    const { orderDetail, activeSubTab } = this.state;
    const newData = cloneDeep(orderDetail);
    try {
      newData.billables[activeSubTab.index].locations[index][name] = value;
      newData.billables[activeSubTab.index].isChangeLocation = true;
      const sumTotalPercent = sumBy(newData.billables[activeSubTab.index].locations, val => {
        if (val.percent) return Number.parseFloat(val.percent || 0);
      });
      if (sumTotalPercent > 100) {
        return '';
      }
      this.setState({ orderDetail: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  onToggleModalNotes = ({ data }) => {
    const { isOpenModalNotes } = this.state;
    const { t } = this.props;
    let messageNote = '';
    if (data) {
      messageNote = messageNotes(t);
    }
    this.setState({ isOpenModalNotes: !isOpenModalNotes, messageNote });
  };

  onToggleModalNotesSplitOrder = () => {
    const { isOpenModalNotesSplitOrder } = this.state;
    this.setState({ isOpenModalNotesSplitOrder: !isOpenModalNotesSplitOrder });
  };

  onToggleModalCopyChangeToAllRecurring = () => {
    const { isOpenModalCopyChangeToAllRecurring } = this.state;
    this.setState({ isOpenModalCopyChangeToAllRecurring: !isOpenModalCopyChangeToAllRecurring });
  };

  onToggleModalValidationCutoffUsage = () => {
    const { isOpenModalValidationCutOffUsage } = this.state;
    this.setState({ isOpenModalValidationCutOffUsage: !isOpenModalValidationCutOffUsage });
  };

  doBuildOrderProvisioningAttributes = () => {
    const { buildOrderProvisioningAttributes } = this.props;
    const { orderDetail, provisioningEnabled, isFetched } = this.state;
    if (!isFetched && provisioningEnabled && provisioningEnabled.value === 'true' && orderDetail.id) {
      buildOrderProvisioningAttributes(convertbuildOrderProvisioningAttributesPayload(orderDetail), ({ data }) => {
        this.setState({
          buildOrderProvisioningAttributesList:
            convertDataProvisioningAttributes(data, orderDetail.provisioningAttributes) || null,
          isFetched: true,
        });
      });
    }
  };

  onHandleChangeProvisioningAttributes = ({ value, indexService, indexAttribute, indexItem }) => {
    const { buildOrderProvisioningAttributesList } = this.state;
    try {
      const newAttributes = cloneDeep(buildOrderProvisioningAttributesList);
      newAttributes[indexService].configProvisioningAttributes[indexAttribute].attributesList[
        indexItem
      ].attributeValue = value;

      this.setState({ buildOrderProvisioningAttributesList: newAttributes });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleAttributeTab = activeTabName => {
    const { activeAttributeTab } = this.state;
    if (activeAttributeTab.name === activeTabName) {
      this.setState({
        activeAttributeTab: { name: activeTabName, isActive: !activeAttributeTab.isActive },
      });
    } else {
      this.setState({ activeAttributeTab: { name: activeTabName, isActive: true } });
    }
  };

  renderItem = item => {
    const {
      orderDetail,
      activeSubTab,
      id,
      isOpenModalDelete,
      serviceTypeRemove,
      mode,
      accountBilling,
      accountBillingIdOptions,
      isCreateBillingAccount,
      splitData,
      isAddMoreAccount,
      showActiveOnly,
      isMultiAccount,
      billableServiceData,
      currencyOptions,
      isAccountSelected,
      invoiceExhibitsChecking,
      customAttributesByObjectType,
      isParentOrder,
      isHaveDataSplitViewer,
      activeProvisioningTab,
      buildOrderProvisioningAttributesList,
      activeAttributeTab,
    } = this.state;
    const isSplit =
      (orderDetail.type === 'NEW' ||
        orderDetail.type === 'MODIFY' ||
        orderDetail.type === 'UPGRADE' ||
        orderDetail.type === 'RENEW' ||
        (orderDetail.type === 'TRIAL' && !isParentOrder)) &&
      orderDetail.status !== 'CANCELLED' &&
      orderDetail.status !== 'COMPLETED' &&
      orderDetail.status !== 'PROCESSING' &&
      orderDetail.accountId === orderDetail.clientId &&
      orderDetail.clientId;

    const { t } = this.props;
    const cutOffDOMOptions = t('selections:cutOffDOM')();

    let billableServiceTable = billableServiceTableColumns;
    if (isSplit || (!isSplit && isHaveDataSplitViewer)) {
      billableServiceTable = [
        {
          name: 'splitService',
          label: 'label.splitService',
          render: (colName, item, idx, indexParent, activeTab) => {
            let isDisabled = true;
            let isNoDisabled = true;
            if (isCreateBillingAccount) {
              isDisabled = false;
            }
            if (!isCreateBillingAccount && !isAddMoreAccount && showActiveOnly) {
              isDisabled = false;
            }
            if (accountBillingIdOptions && accountBillingIdOptions.length) {
              isNoDisabled = false;
            }
            if (isHaveDataSplitViewer) {
              isNoDisabled = false;
            }
            return (
              <button
                className="btn btn-outline-success btn-sm"
                onClick={() => this.onOpenModalSplitService({ item, type: 'splitService' })}
                disabled={
                  (isNoDisabled &&
                    (isDisabled ||
                      !accountBilling ||
                      !item.quantity ||
                      // (item.quantity && Number.parseFloat(item.quantity) < 2) ||
                      !isMultiAccount)) ||
                  (splitData &&
                    splitData.length &&
                    splitData.find(val => val.omsInternalId === item.omsInternalId) &&
                    splitData.find(val => val.omsInternalId === item.omsInternalId).disabledPopup) ||
                  !isMultiAccount
                }
                type="button"
              >
                {t('common:label.splitConfig')}
              </button>
            );
          },
        },
        {
          name: 'selectAccount',
          label: 'label.selectAccount',
          style: { minWidth: '280px' },
          render: (colName, item, idx, indexParent, activeTab) => {
            let isDisabled = true;
            if (isCreateBillingAccount) {
              isDisabled = false;
            }
            if (!isCreateBillingAccount && !isAddMoreAccount && showActiveOnly) {
              isDisabled = false;
            }
            if (accountBillingIdOptions && accountBillingIdOptions.length) {
              isDisabled = false;
            }
            return (
              <GenericInput
                value={
                  isAccountSelected.find(i => i === item.omsInternalId) &&
                  splitData &&
                  splitData.length &&
                  splitData.find(val => val.omsInternalId === item.omsInternalId)
                    ? splitData.find(val => val.omsInternalId === item.omsInternalId).splitAccounts || ''
                    : ''
                }
                wrapperClass="col-md-12"
                onChange={({ name, value }) => this.onChangeSplitAccount({ name, value, item, type: 'splitService' })}
                name="accountId"
                options={accountBillingIdOptions || []}
                type="select"
                menuPortalTarget
                required
                disabled={isDisabled && !isCreateBillingAccount}
                isClearable
              />
            );
          },
        },
        ...billableServiceTable,
      ];
    }

    billableServiceTable = [
      {
        name: 'locations',
        label: 'label.locations',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleSubTab(idx, item, idx, 'billableService')}
              // disabled={!item.locations || !item.locations.length}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'billableService' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          );
        },
      },
      {
        name: 'pricing',
        label: 'label.viewPricing',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onTogglePricing(idx, item, idx, 'pricing')}
            >
              {t('label.view')}
            </button>
          );
        },
      },
      {
        name: 'provisioningAttributes',
        label: 'label.viewProvisioningAttributes',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleProvisioningAttributes(idx, item, idx, 'pricing')}
            >
              {t('label.view')}
            </button>
          );
        },
      },
      ...billableServiceTable,
    ];
    const locationsTableColumns = [
      {
        name: 'pointId',
        label: 'common:label.locationId',
        style: { width: '170px', maxWidth: '170px' },
      },
      {
        name: 'pointName',
        label: 'common:label.locationName',
        style: { width: '170px', maxWidth: '170px' },
      },
      {
        name: 'percent',
        label: 'common:label.percent',
        style: { width: '200px', maxWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.percent}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeLocationPercent({ name, value, index: idx })}
            name="percent"
            type="number"
            disabled={
              activeSubTab.index === -1 ||
              !orderDetail.billables[activeSubTab.index].locations ||
              orderDetail.billables[activeSubTab.index].locations.length < 2
            }
          />
        ),
      },
      {},

      // hard viewer only
    ];

    let newBillableFields = cloneDeep(billableFields({ currencyOptions, cutOffDOMOptions }));
    let isOnly3Field = false;
    if (
      // orderDetail.status === 'PENDING' &&
      Number.parseFloat(invoiceExhibitsChecking || 0) <= 1 &&
      orderDetail.billables &&
      activeSubTab &&
      activeSubTab.index !== -1 &&
      orderDetail.billables[activeSubTab.index] &&
      orderDetail.billables[activeSubTab.index].loadType &&
      orderDetail.billables[activeSubTab.index].loadType.toUpperCase() !== 'Renta'.toUpperCase()
    ) {
      newBillableFields = newBillableFields.filter(val => !!val.isNeedCheckStatus);
      isOnly3Field = true;
    }

    let isDisableAll = false;
    if (orderDetail?.customAttributes) {
      const externalApprovalStatus = orderDetail?.customAttributes.find(val => val.name === 'external_approval_status');
      if (externalApprovalStatus) {
        isDisableAll = externalApprovalStatus.value === 'PENDING';
      }
      if (orderDetail.status === 'PROVISIONING_INITIATED') {
        isDisableAll = false;
      }
    }
    let isShowNote = false;
    if (
      !isParentOrder &&
      (orderDetail.status === 'PENDING' ||
        orderDetail.status === 'PROVISIONING_ERROR' ||
        orderDetail.status === 'BILLING_ERROR')
    ) {
      isShowNote = true;
    }

    const messageDetailModalDelete =
      mode === constants.ModalMode.purchasePackage
        ? `${t('label.servicesTypeRemove')}: ${serviceTypeRemove.join(', ')}`
        : '';
    switch (item.state) {
      case 'Order Details':
        return (
          <div>
            <OrderDetailCollapsibleForm
              colItem="col-md-4"
              dataField={
                orderDetail.status === 'CREATED' || orderDetail.status === 'PENDING'
                  ? constants.orderDetailInfoField
                  : constants.orderDetailInfoDisabledField
              }
              orderId={id}
              orderDetail={orderDetail}
              onHandleChange={this.onHandleChange}
              onChangeCheckBox={this.onChangeCheckBox}
              isDisabled={!isEdit}
            >
              <div className="col-md-4 mt-4">
                <button
                  type="button"
                  className="button button-border x-small float-left mt-1 ml-2 mb-3"
                  onClick={() => this.onToggleModalShowTask()}
                >
                  {t('label.orderTasks')}
                </button>
              </div>
              <ModalAccept
                isOpen={isOpenModalDelete}
                onToggle={this.onToggleModalDelete}
                onAcceptModal={this.onAcceptRemoveItem}
                message={getMessageModal(mode, messageDetailModalDelete, t)}
              />
              {this.renderServiceTables()}
            </OrderDetailCollapsibleForm>
          </div>
        );
      case 'Billable Service':
        return (
          <div className="group-collapsible">
            {/* <DataTable columns={billableServiceTable} data={orderDetail.billables || []} /> */}
            <CollapsibleTable
              columns={billableServiceTable}
              indexViewer={
                activeSubTab && activeSubTab.key === 'billableService' && activeSubTab.index !== -1
                  ? activeSubTab.index
                  : -1
              }
              // onClickRow={(idx, item, indexItem) => this.onToggleSubTab(idx, item, indexItem, 'billableService')}
              onClickRow={(idx, item, indexItem) => {}}
              data={orderDetail.billables || []}
              isSupportRemoveIndex
              isViewOnly
              isFixedHeaderTable
            >
              <div className="col-md-12">
                {activeSubTab.index !== -1 &&
                  orderDetail.billables &&
                  orderDetail.billables.length > 0 &&
                  orderDetail.billables[activeSubTab.index] &&
                  orderDetail.billables[activeSubTab.index].locations &&
                  orderDetail.billables[activeSubTab.index].locations.length > 0 && (
                    <DataTable
                      columns={locationsTableColumns}
                      data={
                        orderDetail &&
                        orderDetail.billables &&
                        activeSubTab &&
                        activeSubTab.key === 'billableService' &&
                        activeSubTab.index !== -1
                          ? orderDetail.billables[activeSubTab.index].locations
                          : []
                      }
                    />
                  )}
                <div className="gpr-billable-srv row pb-3 pt-2">
                  {newBillableFields.map((val, index) => {
                    return (
                      <GenericInput
                        key={item.index}
                        value={billableServiceData[val.name] || ''}
                        name={item.name}
                        onChange={({ name, value }) =>
                          this.onChangeBillableField({
                            name,
                            value,
                            item:
                              orderDetail.billables && orderDetail.billables[activeSubTab.index]
                                ? orderDetail.billables[activeSubTab.index]
                                : null,
                          })
                        }
                        wrapperClass="form-group col-md-4"
                        {...val}
                        required={
                          orderDetail.billables &&
                          orderDetail.billables[activeSubTab.index] &&
                          orderDetail.billables[activeSubTab.index].loadType &&
                          orderDetail.billables[activeSubTab.index].loadType.toUpperCase() === 'Renta'.toUpperCase() &&
                          val.name === 'cutOffDate'
                        }
                        disabled={
                          // (orderDetail.status !== 'PENDING' && orderDetail.status !== 'PROVISIONING_INITIATED') ||
                          orderDetail.status !== 'PENDING' ||
                          (orderDetail.status === 'PROVISIONING_INITIATED' && orderDetail.accountType === 'CLIENT') ||
                          this.checkEnableEditBillableFields(val) ||
                          // (orderDetail.billables &&
                          //   orderDetail.billables[activeSubTab.index] &&
                          //   orderDetail.billables[activeSubTab.index].loadType === 'Renta' &&
                          //   !val.isNoDisabled) ||
                          (orderDetail.billables &&
                            orderDetail.billables[activeSubTab.index] &&
                            orderDetail.billables[activeSubTab.index].loadType &&
                            orderDetail.billables[activeSubTab.index].loadType.toUpperCase() !==
                              'Renta'.toUpperCase() &&
                            !val.isNoDisabledOtherType) ||
                          (orderDetail.billables &&
                            orderDetail.billables[activeSubTab.index] &&
                            orderDetail.billables[activeSubTab.index].loadType &&
                            orderDetail.billables[activeSubTab.index].loadType.toUpperCase() ===
                              'Renta'.toUpperCase() &&
                            val.name === 'invoiceExhibits') ||
                          val.disabled
                        }
                        readOnly={
                          val.readOnly ||
                          (orderDetail.status === 'PROVISIONING_INITIATED' && orderDetail.accountType === 'CLIENT')
                        }
                      />
                    );
                  })}
                  <div className="col-md-12 mt-3 d-flex">
                    {activeSubTab &&
                      activeSubTab.index !== -1 &&
                      orderDetail.billables[activeSubTab.index] &&
                      orderDetail.billables[activeSubTab.index].loadType &&
                      (orderDetail.billables[activeSubTab.index].loadType.toUpperCase() === 'Renta'.toUpperCase() ||
                        Number.parseFloat(invoiceExhibitsChecking || 0) > 1) && (
                        <Switch
                          onChange={
                            billableServiceData.isCopyAllChangeRecurring
                              ? this.onChangeIsCopyAllChangeRecurring
                              : this.onToggleModalCopyChangeToAllRecurring
                          }
                          name={`isCopyAllChangeRecurring-${activeSubTab?.index}`}
                          wrapperClass={`pl-2 pt-4 pr-3 ${
                            billableServiceData.isCopyAllChangeRecurring ? 'switch-active' : 'switch-non-active'
                          }`}
                          title={t('common:label.copyChangeToAll')}
                          checked={!!billableServiceData.isCopyAllChangeRecurring}
                          disabled={
                            (orderDetail.billables &&
                              orderDetail.billables[activeSubTab.index] &&
                              orderDetail.billables[activeSubTab.index].status === 'CANCELLED') ||
                            isDisableAll
                          }
                        />
                      )}
                    {isShowNote && (
                      <button
                        type="button"
                        className="button button-border mt-4 mb-4 gray x-small float-right"
                        onClick={evt =>
                          this.onToggleModalNotes({
                            data:
                              orderDetail?.billables && activeSubTab?.index !== -1
                                ? orderDetail.billables[activeSubTab.index]
                                : null,
                          })
                        }
                      >
                        {t('label.quickNotes')}
                        <i className="fa fa-external-link ml-2" aria-hidden="true" />
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={evt => this.onModifyOrderBillableServices(isOnly3Field)}
                      className="button button-border ml-2 mt-4 mb-4 x-small"
                      disabled={
                        (orderDetail.billables &&
                          orderDetail.billables[activeSubTab.index] &&
                          orderDetail.billables[activeSubTab.index].status === 'CANCELLED') ||
                        isDisableAll ||
                        (!isOnly3Field &&
                          !billableServiceData.cutOffDate &&
                          orderDetail.status !== 'PROVISIONING_INITIATED' &&
                          activeSubTab &&
                          activeSubTab.index !== -1 &&
                          orderDetail.billables[activeSubTab.index] &&
                          orderDetail.billables[activeSubTab.index].loadType &&
                          orderDetail.billables[activeSubTab.index].loadType.toUpperCase() === 'Renta'.toUpperCase()) ||
                        (orderDetail.status !== 'PENDING' && orderDetail.status !== 'PROVISIONING_INITIATED')
                      }
                    >
                      {t('label.save')}
                    </button>
                  </div>
                </div>
              </div>
            </CollapsibleTable>
          </div>
        );
      case 'Custom Attributes':
        return (
          <AttributesForm
            customAttributesByObjectType={customAttributesByObjectType}
            onChange={this.onHandleChangeOrderAttribute}
          />
        );
      case 'Equipments':
        return (
          <div>
            <DataTable isFixedHeaderTable columns={equipmentsTable} data={orderDetail.nonBillables || []} />
          </div>
        );
      case 'Locations':
        return (
          <div>
            <DataTable isFixedHeaderTable columns={locationsTable} data={orderDetail.locations || []} />
          </div>
        );
      case 'Infrastructure Equipments':
        return (
          <div>
            <DataTable
              isFixedHeaderTable
              columns={infrastructureEquipmentsColumns}
              data={orderDetail.infraNonBillables || []}
            />
          </div>
        );

      case 'Provisioning Data':
        return (
          <OrderModifyProvisioningAttributesForm
            data={buildOrderProvisioningAttributesList}
            activeTab={activeProvisioningTab}
            onChange={this.onHandleChangeProvisioningAttributes}
            onToggleTab={this.onToggleProvisioningTab}
            isSupportGroupByService
            isDisabled={!isEdit}
          />
        );
      default:
        return null;
    }
  };

  doGetAccountListByClientId = (isCreated, isModify) => {
    const { selectAccountId } = this.props;
    const { orderDetail, numberOfAccounts } = this.state;
    selectAccountId(
      {
        page: 1,
        size: 20,
        filter: {
          clientId: orderDetail.clientId,
        },
      },
      ({ success, data }) => {
        if (success && data && data.length) {
          const organizationList = data.map(val => {
            const { contacts, id } = val;
            return {
              label: `${id}${val?.name ? `-${val.name}` : ''}${
                contacts && contacts[0] && contacts[0].organization ? `-${contacts[0].organization}` : ''
              }`,
              value: id,
            };
          });
          const accountBillingIdOptions = data.map(val => {
            const { contacts, id } = val;
            return {
              label: `${id}${val?.name ? `-${val.name}` : ''}${
                contacts && contacts[0] && contacts[0].organization ? `-${contacts[0].organization}` : ''
              }`,
              value: id,
              accountDetails: val,
            };
          });

          if (numberOfAccounts === '1' && data.length === 1) {
            this.initSplitValue({ options: [{ label: data[0].id, value: data[0].id }] });
          }
          if (isModify && data.length === 1) {
            this.initSplitValue({ options: [{ label: data[0].id, value: data[0].id }] });
          }
          this.setState({
            organizationList: uniqBy(organizationList, 'label'),
            accountBillingIdOptions: uniqBy(accountBillingIdOptions, 'label'),
            isMultiAccount: data.length > 1,
          });
        }
      }
    );
  };

  onChangeSwitch = () => {
    const { showActiveOnly, clientId } = this.state;
    this.setState({ showActiveOnly: !showActiveOnly }, () => {
      if (!showActiveOnly && !clientId) {
        this.doGetAccountListByClientId(false, true);
      }
    });
  };

  onChangeNumberOfAccounts = ({ name, value }) => {
    this.setState({ numberOfAccounts: value });
  };

  onCreateBillingAccounts = () => {
    const { numberOfAccounts, orderDetail } = this.state;
    const { createBillingAccounts } = this.props;
    createBillingAccounts({ numberOfAccounts, clientId: orderDetail.clientId }, ({ success, data }) => {
      if (success) {
        this.doGetAccountListByClientId(true);
      }
      if (success && data && data.length) {
        this.setState({
          accountBilling: data.map(val => {
            const { id, contacts } = val;
            return { label: contacts && contacts[0] && contacts[0].firstName ? contacts[0].firstName : id, value: id };
          }),
          isCreateBillingAccount: true,
        });
      }
    });
  };

  onToggleModalAccount = () => {
    this.setState({
      isOpenModalAccount: false,
      indexSelectAccountId: null,
      accountIdSelect: null,
    });
  };

  onToggleModalUndoProcessSplit = () => {
    const { isOpenModalUndoProcessSplit } = this.state;
    this.setState({ isOpenModalUndoProcessSplit: !isOpenModalUndoProcessSplit });
  };

  onChangeSplitAccounts = ({ name, value, idx }) => {
    const { splitData } = this.state;
    const newData = cloneDeep(splitData);
    try {
      newData[idx][name] = value;
      if (newData[idx] && newData[idx].quantity && newData[idx].totalPrice) {
        newData[idx].servicePrice =
          Number.parseFloat(newData[idx].totalPrice) / Number.parseFloat(newData[idx].quantity);
      } else {
        newData[idx].servicePrice = null;
      }
      this.setState({ splitData: newData });
    } catch (error) {}
  };

  renderItemInOutboundTasks = item => {
    const { t } = this.props;
    const { omsInboundTasksData, omsOutboundTasksData } = this.state;

    switch (item.state) {
      case 'outbound':
        return (
          <div>
            <DataTable columns={taskOutboundColumns} data={omsOutboundTasksData || []} />
          </div>
        );
      case 'inbound':
        return (
          <div>
            <DataTable columns={taskInboundColumns} data={omsInboundTasksData || []} />
          </div>
        );
      default:
        return null;
    }
  };

  renderItemShowTasks = item => {
    const { t } = this.props;
    const { activeShowInOutBoundTab, omsTasksData } = this.state;
    switch (item.state) {
      case 'omsTasks':
        return (
          <div>
            <DataTable columns={taskColumns} data={omsTasksData || []} />
          </div>
        );
      case 'provisioningTasks':
        return (
          <div>
            {collapsibleOutInBound.map(item => {
              return (
                <FormCollapse
                  key={item.title}
                  isActive={
                    activeShowInOutBoundTab &&
                    item.state === activeShowInOutBoundTab.name &&
                    activeShowInOutBoundTab.isActive
                  }
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={this.onToggleTabInOutboundTask}
                  isSubCollapse
                  isActiveSubForm={item.state === activeShowInOutBoundTab.name && activeShowInOutBoundTab.isActive}
                >
                  {this.renderItemInOutboundTasks(item)}
                </FormCollapse>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  renderItemShowOrderTasks = () => {
    const { omsTasksData } = this.state;
    return (
      <div>
        <DataTable columns={taskColumns} data={omsTasksData || []} />
      </div>
    );
  };

  onToggleModalDiscounts = ({ subscriptionName }) => {
    this.setState({ isOpenModalDisscountOffers: true, subscriptionName: subscriptionName || null });
  };

  onSelectMultipleDiscountOffers = ({ row }) => {
    const { orderDetail, subscriptionName } = this.state;
    const newData = cloneDeep(orderDetail);
    let { services } = newData;
    if (row && row.length) {
      if (!services || !services.length) services = [];
      let listDefaultLines = [];
      services = services.filter(val => !val.isDiscount && val.subscriptionName === subscriptionName);
      services.forEach(val => {
        const { lines } = val;
        if (lines && lines.length) {
          listDefaultLines = [...listDefaultLines, ...lines.filter(line => !!line.discountOfferId)];
        }
      });

      const newRows = row.filter(r => !listDefaultLines.find(ln => ln.discountOfferId === r.id));
      const newServices = [];
      if (newRows && newRows.length) {
        const newRowsGroupBy = groupBy(newRows, item => {
          return item.serviceType;
        });
        map(newRowsGroupBy, (value, key) => {
          const newService = {
            index: newServices.length + getLastIndex({ data: services }),
            serviceType: key,
            provisioningId: null,
            action: 'ADD',
            reason: null,
            status: 'CREATED',
            bundleId: null,
            packageId: null,
            lines: [],
            isDiscount: true,
            subscriptionName: subscriptionName || null,
          };
          newService.lines = convertLineFromDiscountOffers(value, getLastIndex({ data: value }));
          newServices.push(newService);
        });
      }
      if (newServices && newServices.length) {
        services = [...services, ...newServices];
      }

      newData.services = services;
      // subscriptionsData[index].stage += subscriptionsData[index].stage;
      // this.doBuildOrderProvisioningAttributes(index);
      this.setState({
        orderDetail: newData,
        isOpenModalDisscountOffers: false,
      });
    }
  };

  render() {
    const {
      match: {
        params: { id },
      },
      getBundleByBundleId,
      permissionOrder,
      t,
      location,
    } = this.props;
    const {
      orderDetail,
      isOpenPriceOfferModal,
      tableColumns,
      modalTitle,
      selectedItemIds,
      mode,
      isBundle,
      isPackage,
      isPriceOffers,
      isOpenModalSplit,
      activeTab,
      currentCcpDateTime,
      showActiveOnly,
      numberOfAccounts,
      splitData,
      organizationList,
      accountBillingIdOptions,
      isOpenModalAccount,
      isCreateBillingAccount,
      disabledProcess,
      wasValidated,
      isAddMoreAccount,
      omsInternalIdSelected,
      isParentOrder,
      isOpenPricingModal,
      pricingData,
      mainData,
      isOpenModalUndoProcessSplit,
      isOpenModalPendingOrder,
      isOpenModalNotes,
      messageNote,
      isHaveDataSplitViewer,
      isOpenModalNotesSplitOrder,
      isOpenModalCopyChangeToAllRecurring,
      isOpenModalValidationCutOffUsage,
      isOpenPricingModalProvisioningAttributes,
      itemSelectProvisioningAttributes,
      buildOrderProvisioningAttributesList,
      provisioningEnabled,
      isOpenModalShowTask,
      activeShowTasksTab,
      splitOpportunityEnabled,
      dataViewProvisionig,
      isOpenModalDisscountOffers,
    } = this.state;
    let modeUpdateOrder = 0;
    let modeCancelOrder = 0;
    let modeSubmitOrder = 0;
    let modeApproveOrder = 0;
    let modeCreateBillingAccount = 0;
    let modeProcessSplitOrder = 0;
    let modeUndoProcessSplitOrder = 0;

    let isDisableAll = false;
    if (orderDetail?.customAttributes) {
      const externalApprovalStatus = orderDetail?.customAttributes.find(val => val.name === 'external_approval_status');
      if (externalApprovalStatus) {
        isDisableAll = externalApprovalStatus.value === 'PENDING';
      }
    }

    const isSplit =
      (orderDetail.type === 'NEW' ||
        orderDetail.type === 'MODIFY' ||
        orderDetail.type === 'UPGRADE' ||
        orderDetail.type === 'RENEW' ||
        (orderDetail.type === 'TRIAL' && !isParentOrder)) &&
      orderDetail.status === 'PENDING' &&
      orderDetail.accountId === orderDetail.clientId &&
      orderDetail.clientId;

    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeUpdateOrder = checkPermissionUpdateOrder({ listPermission });
      modeCancelOrder = checkPermissionCancelOrder({ listPermission });
      modeSubmitOrder = checkPermissionSubmitOrder({ listPermission });
      modeApproveOrder = checkPermissionApproveOrder({ listPermission });
      modeCreateBillingAccount = checkPermissionCreateBillingAccount({ listPermission });
      modeProcessSplitOrder = checkPermissionProcessSplitOrder({ listPermission });
      modeUndoProcessSplitOrder = checkPermissionUndoProcessSplitOrder({ listPermission });
    }
    let newCollapsibleMenu = cloneDeep(collapsibleMenu);

    if (!orderDetail || !orderDetail.billables) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Billable Service');
    }
    if (!orderDetail || !orderDetail.nonBillables) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Equipments');
    }
    if (!orderDetail || !orderDetail.locations) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Locations');
    }
    if (!orderDetail || !orderDetail.infraNonBillables) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Infrastructure Equipments');
    }
    if (!orderDetail || !orderDetail.customAttributes || !orderDetail.customAttributes.length) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Custom Attributes');
    }
    if (
      !buildOrderProvisioningAttributesList ||
      (!buildOrderProvisioningAttributesList.length && provisioningEnabled && provisioningEnabled.value === 'true')
    ) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Provisioning Data');
    }

    let dataSelect = [];
    if (splitData && splitData.length) {
      dataSelect = splitData.map((val, index) => {
        if (val.omsInternalId !== omsInternalIdSelected) {
          return { index };
        }
        return val;
      });
    }

    let isDisabled = true;
    if (isCreateBillingAccount) {
      isDisabled = false;
    }
    if (!isCreateBillingAccount && !isAddMoreAccount && showActiveOnly) {
      isDisabled = false;
    }

    const isEnableUpdateBtn = splitOpportunityEnabled?.value === 'false' && orderDetail.status === 'CREATED';
    const isEnableCancelBtn =
      splitOpportunityEnabled?.value === 'false' &&
      (orderDetail.status === 'CREATED' || orderDetail.status === 'APPROVED');

    isEdit = orderDetail.status === 'CREATED' || orderDetail.status === 'PENDING' || orderDetail.status === 'APPROVED';

    const mainSplitServiceTable = [
      {
        name: 'serviceId',
        label: 'common:label.serviceId',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.serviceId ? item.serviceId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="serviceId"
              readOnly
            />
          );
        },
      },
      {
        name: 'serviceName',
        label: 'common:label.serviceName',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.serviceName ? item.serviceName : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="serviceName"
              readOnly
            />
          );
        },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.quantity ? item.quantity : '0'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="quantity"
              readOnly
            />
          );
        },
      },
      {
        name: 'unitPrice',
        label: 'common:label.unitPrice',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={
                item && item.totalPrice ? Number.parseFloat(item.totalPrice) / Number.parseFloat(item.quantity) : '0'
              }
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="unitPrice"
              readOnly
              // options={accountBilling || []}
            />
          );
        },
      },
      {
        name: 'totalPrice',
        label: 'common:label.amount',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.totalPrice ? item.totalPrice : '0'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="totalPrice"
              readOnly
              // options={accountBilling || []}
            />
          );
        },
      },
    ];
    const splitServiceTable = [
      {
        name: 'accountId',
        label: 'label.accountId',
        required: true,
        style: { minWidth: '200px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.accountId ? item.accountId : ''}
              wrapperClass="col-md-12"
              name="accountId"
              onChange={({ name, value }) => this.onChangeSplitAccounts({ name, value, idx })}
              menuPortalTarget
              options={accountBillingIdOptions || []}
              type="select"
              required
            />
          );
        },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && (item.quantity || item.quantity === 0) ? item.quantity : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onChangeSplitAccounts({ name, value, idx })}
              name="quantity"
              // options={accountBilling || []}
              readOnly={mainData && mainData[0] && Number.parseFloat(mainData[0].quantity) === 1}
            />
          );
        },
      },
      {
        name: 'totalPrice',
        label: 'common:label.amount',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && (item.totalPrice || item.totalPrice === 0) ? item.totalPrice : ''}
              wrapperClass="col-md-12"
              name="totalPrice"
              onChange={({ name, value }) => this.onChangeSplitAccounts({ name, value, idx })}
            />
          );
        },
      },
      {
        name: 'servicePrice',
        label: 'common:label.unitPrice',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              // value={item && item.servicePrice ? item.servicePrice : ''}
              value={item && (item.servicePrice || item.servicePrice === 0) ? item.servicePrice : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="servicePrice"
              readOnly
              // options={accountBilling || []}
            />
          );
        },
      },
    ];

    const isDisabelSplitOrder =
      isOpenModalSplit ||
      (isDisabled && (!isCreateBillingAccount || disabledProcess)) ||
      !splitData.length ||
      // (orderDetail.billables &&
      //   uniqBy(
      //     splitData.filter(val => !!val.accountId),
      //     'omsInternalId'
      //   ).length !== orderDetail.billables.length) ||
      orderDetail.status !== 'PENDING';

    let isShowPopupValidateCutOffUsage = false;
    if (orderDetail.billables && orderDetail.billables.length) {
      orderDetail.billables.forEach(val => {
        if ((val.totalPrice === 0 || val.totalPrice === '0') && val.cutOffDate === -2) {
          isShowPopupValidateCutOffUsage = true;
        }
      });
    }

    const listServicesTypeDefault = t('selections:serviceType')();
    let serviceTypeSelect = '';
    if (dataViewProvisionig && dataViewProvisionig.serviceType) {
      serviceTypeSelect = listServicesTypeDefault.find(val => val.value === dataViewProvisionig.serviceType).label;
    }

    // console.log('isShowPopupValidateCutOffUsage', isShowPopupValidateCutOffUsage);

    return (
      <div>
        <PageTitle
          linkTo={{
            pathname: RouteNames.searchAndList.path,
            state: location && location.state ? location.state : null,
          }}
          titleBtn={t('common:label.back')}
          items={[
            {
              name: t('common:label.modifyOrder'),
              url: RouteNames.searchAndList.path,
            },
            { name: id },
          ]}
        >
          <button
            disabled={orderDetail?.status === 'COMPLETED'}
            type="button"
            className="button button-border x-small"
            onClick={this.doRefreshPage}
          >
            <i className="ti-reload mr-1" />
            {t('label.refresh')}
          </button>
        </PageTitle>
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics">
            <div className="card-body">
              <CardHeaderForm title={t('label.order') || 'Order'} label={t('label.orderId')} value={id} />
              {modeCreateBillingAccount === 2 && isSplit && (
                <div className="col-md-12">
                  <div className="col-md-12 row">
                    <div className="col-md-4 pt-3 row">
                      <div className="ml-3 pr-3">{t('label.splitInMultipleAccounts')}</div>
                      <div className="float-right">
                        <Switch
                          onChange={this.onChangeSwitch}
                          wrapperClass={showActiveOnly ? 'switch-active' : 'switch-non-active'}
                          rightTitle={t('label.yes')}
                          checked={showActiveOnly}
                          title={t('label.no')}
                          disabled={isDisableAll}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 ml-3">
                      <GenericInput
                        value={organizationList && organizationList[0] ? organizationList[0].value : ''}
                        wrapperClass="col-md-12"
                        onChange={({ name, value }) => {}}
                        name="clientId"
                        label="label.viewBillingAccounts"
                        readOnly
                        type="select"
                        options={organizationList || []}
                        disabled={isDisableAll}
                      />
                    </div>
                    {!isParentOrder && orderDetail.status === 'PENDING' && (
                      <button
                        type="button"
                        className="button button-border mt-4 mb-4 gray x-small float-right"
                        onClick={this.onToggleModalNotesSplitOrder}
                      >
                        {t('label.quickNotes')}
                        <i className="fa fa-external-link ml-2" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                  <div className="col-md-12 row ml-3">
                    {showActiveOnly && (orderDetail.type === 'MODIFY' || orderDetail.type === 'UPGRADE') && (
                      <div className="col-md-4 pt-3 d-flex">
                        <div className="ml-2 pr-3">{t('label.addMoreAccounts')}</div>
                        <Switch
                          onChange={() => this.setState({ isAddMoreAccount: !isAddMoreAccount })}
                          wrapperClass={isAddMoreAccount ? 'switch-active' : 'switch-non-active'}
                          rightTitle={t('label.yes')}
                          checked={isAddMoreAccount}
                          name="addMoreAccounts"
                          title={t('label.no')}
                          disabled={isDisableAll}
                        />
                      </div>
                    )}
                    <div className="col-md-4">
                      <GenericInput
                        value={numberOfAccounts}
                        wrapperClass="col-md-12"
                        onChange={({ name, value }) => this.onChangeNumberOfAccounts({ name, value })}
                        name="numberOfAccounts"
                        type="number"
                        required
                        label="label.addBillingAccounts"
                        disabled={!showActiveOnly}
                      />
                    </div>
                    <div className="col-md-4 pt-4">
                      <button
                        type="button"
                        className="button button-border x-small"
                        onClick={() => this.onCreateBillingAccounts()}
                        disabled={!showActiveOnly || modeCreateBillingAccount === 1}
                      >
                        {t('label.createAccount')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-12 pt-3 pb-3">
                {newCollapsibleMenu &&
                  newCollapsibleMenu.length > 0 &&
                  newCollapsibleMenu.map(item => {
                    return (
                      <FormCollapse
                        key={item.title}
                        label="First Name"
                        isActive={activeTab && item.state === activeTab.name && activeTab.isActive}
                        title={t(item.title) || ''}
                        state={item.state}
                        onToggleTab={this.onToggleTab}
                      >
                        {this.renderItem(item)}
                      </FormCollapse>
                    );
                  })}
              </div>
              <div className="form-group col-md-12 mt-4">
                {/* {buttonText && (
                  <button
                    type="button"
                    className="button button-border x-small float-left"
                    onClick={() => this.openSelectModal()}
                    disabled={this.checkEnableButtonByOrderStatus()}
                  >
                    {t(buttonText)}
                  </button>
                )} */}
                {/* {provisioningEnabled && provisioningEnabled.value === 'true' && (
                  <button
                    type="button"
                    className="button button-border x-small float-left"
                    onClick={() => this.onToggleModalShowTask()}
                  >
                    {t('label.showTasks')}
                  </button>
                )} */}
                {modeApproveOrder !== 0 && (
                  <button
                    type="button"
                    className="button button-border x-small ml-1 float-right"
                    disabled={this.checkEnableButtonByOrderStatus() || modeApproveOrder === 1}
                    onClick={() => this.updateOrderStatus(orderEnum.status.approved)}
                  >
                    {t('label.approveOrder')}
                  </button>
                )}
                {modeCancelOrder !== 0 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right"
                    onClick={() => this.updateOrderStatus(orderEnum.status.cancelled)}
                    disabled={
                      (!isEnableCancelBtn &&
                        (this.checkEnableButtonByOrderStatus() || orderDetail.status !== 'PENDING')) ||
                      modeCancelOrder === 1
                    }
                  >
                    {t('label.cancelOrder')}
                  </button>
                )}
                {modeSubmitOrder !== 0 && (
                  <button
                    type="button"
                    className="button button-border  x-small float-right"
                    onClick={() => this.updateOrderStatus(orderEnum.status.submitted, true)}
                    disabled={
                      this.checkEnableButtonByOrderStatus() ||
                      !isDisabelSplitOrder ||
                      modeSubmitOrder === 1 ||
                      (orderDetail.effectiveDate &&
                        currentCcpDateTime &&
                        moment(orderDetail.effectiveDate).isAfter(currentCcpDateTime) &&
                        !orderDetail.executeFutureOrderNow)
                    }
                  >
                    {t('label.submitOrder')}
                  </button>
                )}
                {modeUpdateOrder !== 0 && (
                  <button
                    onClick={() => this.modifyOrder()}
                    type="button"
                    className="button button-border x-small float-right"
                    disabled={
                      (!isEnableUpdateBtn &&
                        (orderDetail.status === 'APPROVED' ||
                          orderDetail.status !== 'PENDING' ||
                          this.checkEnableButtonByOrderStatus())) ||
                      modeUpdateOrder === 1
                    }
                  >
                    {t('label.updateOrder')}
                  </button>
                )}
                {modeUndoProcessSplitOrder !== 0 && !isSplit && !isParentOrder && (
                  <button
                    onClick={this.onToggleModalUndoProcessSplit}
                    type="button"
                    className="button button-border x-small float-right"
                    disabled={
                      [orderEnum.status.provisioningError, orderEnum.status.provisioningInitiated].indexOf(
                        orderDetail.status
                      ) === -1 || modeUndoProcessSplitOrder === 1
                    }
                  >
                    {t('label.undoProcessSplit')}
                  </button>
                )}
                {modeProcessSplitOrder !== 0 && isSplit && (
                  <button
                    onClick={evt => this.onHanldeSubmitSplitAccount(true)}
                    type="button"
                    className="button button-border x-small float-right"
                    disabled={isDisabelSplitOrder || isParentOrder || modeProcessSplitOrder === 1}
                  >
                    {t('label.saveSplit')}
                  </button>
                )}
                {modeProcessSplitOrder !== 0 && isSplit && (
                  <button
                    onClick={evt =>
                      isShowPopupValidateCutOffUsage
                        ? this.onToggleModalValidationCutoffUsage()
                        : this.onHanldeSubmitSplitAccount(false)
                    }
                    type="button"
                    className="button button-border x-small float-right"
                    disabled={isDisabelSplitOrder || isParentOrder || modeProcessSplitOrder === 1}
                  >
                    {t('label.processSplit')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalWithItem modalTitle={t('label.splitService')} isOpen={isOpenModalSplit}>
          <form
            onSubmit={this.handleSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <DataTable columns={mainSplitServiceTable} data={mainData || []} isSupportRemoveIndex />
            <br />
            <br />
            <DataTable columns={splitServiceTable} data={dataSelect || []} isSupportRemoveIndex />
            <br />
            <br />
            <button
              type="button"
              disabled={isHaveDataSplitViewer && !isSplit}
              className="button button-border black x-small"
              onClick={this.onAddNewSplit}
            >
              +
              {t('label.addNewSplitLine')}
            </button>
            <button
              type="button"
              className="button button-border x-small float-right"
              disabled={dataSelect && dataSelect.length && dataSelect.filter(val => !!val.omsInternalId).length < 2}
              onClick={this.onSelectSplit}
            >
              {t('label.select')}
            </button>
            <button
              type="button"
              onClick={() =>
                this.setState({
                  isOpenModalSplit: false,
                  splitLocations: [],
                  omsInternalIdSelected: null,
                  splitEquipments: [],
                })
              }
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </form>
          <br />
        </ModalWithItem>
        <ModalAccountId
          isOpen={isOpenModalAccount}
          onSelect={this.onSelectAccountId}
          onCancel={this.onToggleModalAccount}
          selectedParentId={this.state && this.state.accountIdSelect ? this.state.accountIdSelect : ''}
          defaultSearchParams={{ clientId: orderDetail.clientId }}
        />
        <PricingModalOrder
          isOpen={isOpenPricingModal}
          data={pricingData}
          onToggle={() => this.setState({ isOpenPricingModal: false, pricingData: null })}
        />
        <ModalOrderProvisioningAttributes
          isOpen={isOpenPricingModalProvisioningAttributes}
          itemSelect={itemSelectProvisioningAttributes}
          orderDetail={orderDetail}
          onToggle={() =>
            this.setState({ isOpenPricingModalProvisioningAttributes: false, itemSelectProvisioningAttributes: null })
          }
        />
        <ModalAccept
          onAcceptModal={this.onUndoProcessSplitOrder}
          isOpen={isOpenModalUndoProcessSplit}
          message={t('message.messageUndoProcessSplit')}
          onToggle={this.onToggleModalUndoProcessSplit}
        />
        <ModalViewMessage
          isOpen={isOpenModalPendingOrder}
          message={t('message.messagePendingOrder')}
          wrapperClass="error-message"
          onToggle={() => {
            this.setState({ isOpenModalPendingOrder: false });
          }}
        />
        <ModalViewNote
          wrapperClass="quick-note-order"
          isOpen={isOpenModalNotes}
          message={messageNote}
          onToggle={this.onToggleModalNotes}
          mainClass="modal-lg"
        />
        <ModalViewNote
          wrapperClass="quick-note-order"
          isOpen={isOpenModalNotesSplitOrder}
          message={messageNotesSplitOrder(t)}
          onToggle={this.onToggleModalNotesSplitOrder}
          mainClass="modal-lg"
        />
        <ModalAccept
          onAcceptModal={this.onChangeIsCopyAllChangeRecurring}
          isOpen={isOpenModalCopyChangeToAllRecurring}
          message={t('message.messageCopyChangeToAllRecurringExcludeUsage')}
          onToggle={this.onToggleModalCopyChangeToAllRecurring}
        />
        <ModalAccept
          onAcceptModal={() => this.onHanldeSubmitSplitAccount(false)}
          isOpen={isOpenModalValidationCutOffUsage}
          message={t('message.messageValidationUsageCutoffDom')}
          onToggle={this.onToggleModalValidationCutoffUsage}
        />

        <ModalWithItem
          modalTitle={t('label.orderTask')}
          isOpen={isOpenModalShowTask}
          onToggle={this.onToggleModalShowTask}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        >
          <div>
            <div>
              <ColorListGroup
                colorList={[
                  {
                    label: 'label.listStatusActiveProvisioningTasks',
                    color: '#28a745',
                  },
                  {
                    label: 'label.PENDING/PARTIAL',
                    color: 'orange',
                  },
                  {
                    label: 'label.FAILED/CANCELLED',
                    color: 'red',
                  },
                ]}
              />
            </div>
            {provisioningEnabled && provisioningEnabled.value === 'true'
              ? collapsibleShowTask.map(item => {
                  return (
                    <FormCollapse
                      key={item.title}
                      isActive={
                        activeShowTasksTab && item.state === activeShowTasksTab.name && activeShowTasksTab.isActive
                      }
                      title={t(item.title) || ''}
                      state={item.state}
                      onToggleTab={this.onToggleTabShowTask}
                    >
                      {this.renderItemShowTasks(item)}
                    </FormCollapse>
                  );
                })
              : this.renderItemShowOrderTasks()}
            <div className="col-md-12 border-top">
              <button
                type="button"
                onClick={this.onToggleModalShowTask}
                className="button button-border black x-small float-right mt-5 mb-3"
              >
                {t('label.cancel')}
              </button>
            </div>
          </div>
        </ModalWithItem>

        <ModalWithItem
          modalTitle={t('label.serviceIdentifiers')}
          isOpen={!!dataViewProvisionig}
          onToggle={() => this.onToggleModalShowProvisionig({ item: null })}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        >
          <div className="col-md-12">
            <h6 className="mb-3">{`${t('label.serviceType')}: ${serviceTypeSelect}`}</h6>
            <div className="col-md-12 border-top">
              <div>
                <DataTable
                  columns={provisionigColumns}
                  data={dataViewProvisionig ? dataViewProvisionig.provisioningList : []}
                />
              </div>
              <button
                type="button"
                onClick={() => this.onToggleModalShowProvisionig({ item: null })}
                className="button x-small float-right mt-5 mb-3"
              >
                {t('label.back')}
              </button>
            </div>
          </div>
        </ModalWithItem>
        <ModalMultipleSelectDisscountOffer
          isOpen={isOpenModalDisscountOffers}
          // isMultipleOptionInject
          // optionsInject={{
          //   serviceType: serviceTypeOptions,
          // }}
          // addFirstParamsField={addFirstParamsField}
          onCancel={() => {
            this.setState({ isOpenModalDisscountOffers: false });
            // this.doBuildOrderProvisioningAttributes(index);
          }}
          onSelect={(id, row) => this.onSelectMultipleDiscountOffers({ id, row })}
          // defaultFilter={defaultFilter}
        />
      </div>
    );
  }
}

OrderDetail.propTypes = {
  getOrderDetail: PropTypes.func.isRequired,
  orderDetail: PropTypes.objectOf(PropTypes.any),
  updateOrderStatus: PropTypes.func.isRequired,
  modifyOrder: PropTypes.func.isRequired,
  permissionOrder: PropTypes.objectOf(PropTypes.any),
};

OrderDetail.defaultProps = {
  orderDetail: {},
  permissionOrder: {},
};

const mapStateToProps = createStructuredSelector({
  orderDetail: makeGetOrderDetail() || {},
  errorMessage: makeErrorMessage() || {},
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
});

const newOrderDetail = connect(mapStateToProps, {
  getOrderDetail,
  updateOrderStatus,
  modifyOrder,
  selectPackageId,
  selectBundleId,
  selectPriceOfferId,
  getBundleDetail,
  getBundleByBundleId,
  getCcpDateTimeConfig,
  modifyAccountServiceLocations,
  createBillingAccounts,
  getAccountDetail,
  processSplitOrder,
  selectAccountId,
  getCustomAttributesByObjectType,
  getBillableServicePricing,
  modifyOrderBillableServices,
  getCurrencyConfigApp,
  getOrderBillableByIdAndIndex,
  getCcpPropertiesConfig,
  saveSplitOpportunity,
  getOrderSplitOpportunity,
  undoProcessSplitOrder,
  buildOrderProvisioningAttributes,
  getOrderOmsTasks,
  getOrderProvisioningOutboundTasks,
  getOrderProvisioningInboundTasks,
  getDiscountOfferById,
})(OrderDetail);

export default withTranslation('common')(newOrderDetail);
