import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index']);
  const modifyExpensesConfig = `modifyExpensesConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyExpensesConfig} {
      id
    }
  }
`;
};
