import gql from 'graphql-tag';

export default id => {
  const getEbsExtractDetailsById = `getEbsExtractDetailsById(input: {id: "${id}"})`;
  return gql`
    query {
      ${getEbsExtractDetailsById} {
        index
        segment1
        segment2
        segment3
        segment3Name
        segment4
        segment5
        segment6
        credit
        debit
        batchId
        batchName
        chartOfAccountId
        period
        glDate
        glAccount
        glOffset
        accountingCode
        offsetAccountingCode
        type
      }
    }
  `;
};
