import React, { PureComponent } from 'react';
import { cloneDeep, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { messageNotes } from '../../constants';
import { DataTable, GenericInput, ModalViewNote, FormCollapse } from '../../../../components/common';
import { getIndexData, getLastIndex, validate } from '../../../../utils/utils';

class NewFileMapping extends PureComponent {
  state = {
    isOpenModalNotes: false,
    data: [],
    activeTab: 0,
    wasValidated: false,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.data) return null;
    return {
      data: props.data || null,
      oldValue: props.data,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  onToggleModalNotes = () => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes });
  };

  onHanldeSubmit = evt => {
    const { data } = this.state;
    evt.preventDefault();
    const { onSaveUsageRecCfg, indexExpand, t } = this.props;

    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });
    onSaveUsageRecCfg(
      data.map(val => {
        const { isNew, ...rest } = val;
        return rest;
      }),
      indexExpand
    );
  };

  onHandleChangeField = ({ name, value, idx }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (newData[idx]) newData[idx][name] = value;
    this.setState({ data: newData });
  };

  onMapNextField = idx => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (!newData[idx].usageFileMapRecAttributesCfg) newData[idx].usageFileMapRecAttributesCfg = [];
    if (newData[idx] && newData[idx].usageFileMapRecAttributesCfg) {
      let idNewItem = 0;
      if (newData[idx].usageFileMapRecAttributesCfg.length) {
        newData[idx].usageFileMapRecAttributesCfg.forEach(item => {
          if (item.index > idNewItem) {
            idNewItem = item.index;
          }
        });
      }
      const newDataPush = { index: idNewItem + 1, sourceFieldPos: '', targetField: '' };
      newData[idx].usageFileMapRecAttributesCfg.push(newDataPush);
      // newData[idx].usageFileMapRecAttributesCfg.unshift(newDataPush);
    }
    this.setState({ data: newData });
  };

  onMapNextRecord = () => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      const filterRemoveData = newData.filter(val => size(val) > 2);
      const indexData = filterRemoveData.length - 1;
      const dataCopy = cloneDeep(filterRemoveData[indexData]);
      dataCopy.index = getLastIndex({ data: newData });
      dataCopy.isNew = true;
      newData.push(dataCopy);
      this.setState({ data: newData });
      this.onToggleTab(null, dataCopy, newData.length - 1);
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewRecord = idx => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.push({
      index: newData[idx] && newData[idx].index ? newData[idx].index + 1 : 1,
      sourceRecord: '',
      targetRecord: '',
      recordDelimiter: '',
      usageFileMapRecAttributesCfg: [{ index: 1, sourceFieldPos: '', targetField: '' }],
    });
    this.setState({ data: newData });
  };

  onRemoveRecord = idx => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (newData[idx] && newData[idx]) {
      if (newData[idx].isNew) {
        newData.splice(idx, 1);
      } else {
        newData[idx] = { index: newData[idx].index };
      }
    }
    this.setState({ data: newData, activeTab: -1 });
  };

  onChangeTable = ({ name, value, idx, indexParent }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (newData[indexParent] && newData[indexParent].usageFileMapRecAttributesCfg) {
      newData[indexParent].usageFileMapRecAttributesCfg[
        getIndexData({ data: newData[indexParent].usageFileMapRecAttributesCfg, index: idx })
      ][name] = value;
    }
    this.setState({ data: newData });
  };

  onRemoveItem = (colName, item, idx, indexParent) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (newData[indexParent] && newData[indexParent].usageFileMapRecAttributesCfg) {
      const indexData = getIndexData({ data: newData[indexParent].usageFileMapRecAttributesCfg, index: idx });
      newData[indexParent].usageFileMapRecAttributesCfg[indexData] = {
        index: newData[indexParent].usageFileMapRecAttributesCfg[indexData].index,
      };
    }
    this.setState({ data: newData });
  };

  onToggleTab = (index, item, indexItem) => {
    const { activeTab } = this.state;
    if (activeTab === indexItem) this.setState({ activeTab: -1 });
    if (activeTab !== indexItem) {
      // if (val && val.id) this.doGetAPIById(val.id);
      this.setState({ activeTab: indexItem });
    }
  };

  render() {
    const { isSearching, isOpenModalNotes, data, activeTab, wasValidated } = this.state;
    const {
      onToggle,
      fileMapCfgList,
      t,
      usageContainerRecordList,
      onChangeParentTable,
      item,
      indexExpand,
      onAddNewUsageFile,
      isHasNew,
    } = this.props;

    const dataSelect = activeTab !== -1 && data[activeTab] ? data[activeTab] : {};
    const usageRecRemoveIndex =
      dataSelect && dataSelect.usageFileMapRecAttributesCfg && dataSelect.usageFileMapRecAttributesCfg.length > 0
        ? dataSelect.usageFileMapRecAttributesCfg.filter(item => size(item) > 1)
        : [];
    const columns = [
      {
        name: 'Source Field Number',
        label: 'label.sourceFieldNumber',
        style: { width: '150px' },
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            type="number"
            value={item && (item.sourceFieldPos || item.sourceFieldPos === 0) ? item.sourceFieldPos : ''}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, idx: item.index, indexParent })}
            name="sourceFieldPos"
            required
          />
        ),
      },
      {
        name: 'sourceField',
        label: 'label.selectRecord',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item && item.sourceField ? item.sourceField : ''}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, idx: item.index, indexParent })}
            name="sourceField"
            type="select"
            menuPortalTarget
            options={
              usageContainerRecordList && usageContainerRecordList.length > 0
                ? usageContainerRecordList.map(val => ({ label: val.recordType, value: val.recordType }))
                : []
            }
            required
          />
        ),
      },
      {
        name: 'targetField',
        label: 'label.selectField',
        required: true,
        render: (colName, item, idx, indexParent) => {
          let optionFieldType = [];
          if (item.sourceField) {
            const itemRecordSelect = item.sourceField
              ? usageContainerRecordList.filter(val => val.recordType === item.sourceField)
              : [];

            if (itemRecordSelect && itemRecordSelect.length)
              optionFieldType = itemRecordSelect[0].attributes.map(item => ({
                label: item.attributeName,
                value: item.attributeName,
              }));
          }
          return (
            <GenericInput
              value={item && item.targetField ? item.targetField : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onChangeTable({ name, value, idx: item.index, indexParent })}
              name="targetField"
              type="select"
              menuPortalTarget
              options={optionFieldType}
              required
            />
          );
        },
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent) => (
          <div className="form-group col-md-12">
            <button
              type="button"
              className="btn-phone"
              onClick={() => this.onRemoveItem(colName, item, item.index, indexParent)}
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        ),
      },
    ];

    return (
      <form
        noValidate
        ref={this.formRef}
        onSubmit={this.onHanldeSubmit}
        className={`col-md-12 row m-3 needs-validation ${wasValidated ? 'was-validated' : ''}`}
      >
        <GenericInput
          value={item.id}
          onChange={({ name, value }) => onChangeParentTable({ name, value, id: item.id })}
          name="id"
          readOnly
          label="label.id"
        />
        <GenericInput
          value={item.serviceType || null}
          onChange={({ name, value }) => onChangeParentTable({ name, value, id: item.id })}
          name="serviceType"
          type="select"
          menuPortalTarget
          tOptions="selections:serviceType"
          required
          label="label.serviceType"
        />
        <GenericInput
          type="select"
          value={item.recordIndicator}
          menuPortalTarget
          tOptions="selections:recordIndicator"
          onChange={({ name, value }) => onChangeParentTable({ name, value, id: item.id })}
          name="recordIndicator"
          required
          label="label.recordIndicator"
        />
        <GenericInput
          value={item.source}
          onChange={({ name, value }) => onChangeParentTable({ name, value, id: item.id })}
          name="source"
          required
          label="label.source"
        />
        <GenericInput
          value={item.fileDelimiter}
          onChange={({ name, value }) => onChangeParentTable({ name, value, id: item.id })}
          name="fileDelimiter"
          required
          label="label.fileDelimiter"
        />
        <GenericInput
          value={item.fileDescription}
          onChange={({ name, value }) => onChangeParentTable({ name, value, id: item.id })}
          name="fileDescription"
          required
          label="label.fileDescription"
        />
        <div className="col-md-12 mt-3">
          {/* <div className="card card-statistics form-with-item col-md-12"> */}
          {/* <div className="col-md-12 mb-3 ml-2 mt-3">
          <GenericInput
            value={
              fileMapCfgList && fileMapCfgList[0] && fileMapCfgList[0].fileDelimiter
                ? fileMapCfgList[0].fileDelimiter
                : ''
            }
            label={t('label.fieldDelimiterInFile')}
            wrapperClass="col-md-3 m-auto"
            onChange={() => {}}
            name="File Description"
          />
        </div> */}
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              if (size(item) < 2) return null;
              let title = '';
              if (item.sourceRecord) {
                title = item.sourceRecord;
              }
              if (item.targetRecord) {
                title = `${title}, ${item.targetRecord}`;
              }
              return (
                <FormCollapse
                  key={item.index}
                  isActive={index === activeTab}
                  title={title || 'null'}
                  state={index}
                  onToggleTab={e => this.onToggleTab(e, item, index)}
                  isSubCollapse
                  isActiveSubForm={index === activeTab}
                >
                  {/* <div className="box-shadow p-2 m-2"> */}
                  <div className="col-md-12 mb-3 ml-2 mt-3 row">
                    <GenericInput
                      value={dataSelect && dataSelect.sourceRecord ? dataSelect.sourceRecord : ''}
                      label={t('label.sourceFileRecord')}
                      wrapperClass="col-md-3"
                      onChange={({ name, value }) => this.onHandleChangeField({ name, value, idx: activeTab })}
                      name="sourceRecord"
                      required={index === activeTab}
                    />
                    <GenericInput
                      value={dataSelect && dataSelect.targetRecord ? dataSelect.targetRecord : ''}
                      label={t('label.embrixTargetRecord')}
                      wrapperClass="col-md-3"
                      onChange={({ name, value }) => this.onHandleChangeField({ name, value, idx: activeTab })}
                      name="targetRecord"
                      type="select"
                      menuPortalTarget
                      tOptions="selections:fileRecordType"
                      required={index === activeTab}
                    />
                    <GenericInput
                      value={dataSelect && dataSelect.recordDelimiter ? dataSelect.recordDelimiter : ''}
                      label={t('label.fieldDelimiter')}
                      wrapperClass="col-md-3"
                      onChange={({ name, value }) => this.onHandleChangeField({ name, value, idx: activeTab })}
                      name="recordDelimiter"
                      required={index === activeTab}
                    />
                    <div className="col-md-2 pt-3 text-right">
                      {data.length > 1 && (
                        <button type="button" className="btn-phone" onClick={() => this.onRemoveRecord(activeTab)}>
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3 ml-2 row justify-content-center">
                    <DataTable
                      columns={columns}
                      data={usageRecRemoveIndex}
                      isLoading={isSearching}
                      indexParent={activeTab}
                    />
                  </div>
                  <div className="col-md-12 mb-3 ml-2 row justify-content-center">
                    <button
                      type="button"
                      className="button button-border gray x-small"
                      onClick={() => this.onMapNextField(activeTab)}
                    >
                      {t('label.mapNextField')}
                    </button>
                  </div>
                  {/* </div> */}
                </FormCollapse>
              );
            })}
          {/* <CollapsibleTable
          columns={headerTable}
          data={data && data.length ? data : []}
          indexViewer={activeTab}
          onClickRow={this.onToggleTab}
        >
          <div className="box-shadow p-2 m-2">
            <div className="col-md-12 mb-3 ml-2 row justify-content-center">
              <GenericInput
                value={dataSelect && dataSelect.sourceRecord ? dataSelect.sourceRecord : ''}
                label={t('label.sourceFileRecord')}
                wrapperClass="col-md-3"
                onChange={({ name, value }) => this.onHandleChangeField({ name, value, idx: indexSelected })}
                name="sourceRecord"
                required
              />
              <GenericInput
                value={dataSelect && dataSelect.targetRecord ? dataSelect.targetRecord : ''}
                label={t('label.embrixTargetRecord')}
                wrapperClass="col-md-3"
                onChange={({ name, value }) => this.onHandleChangeField({ name, value, idx: indexSelected })}
                name="targetRecord"
                type="select"
                menuPortalTarget
                tOptions="selections:fileRecordType"
                required
              />
              <GenericInput
                value={dataSelect && dataSelect.recordDelimiter ? dataSelect.recordDelimiter : ''}
                label={t('label.fieldDelimiter')}
                wrapperClass="col-md-3"
                onChange={({ name, value }) => this.onHandleChangeField({ name, value, idx: indexSelected })}
                name="recordDelimiter"
                required
              />
              <div className="col-md-2 pt-3 text-right">
                {data.length > 1 && (
                  <button type="button" className="btn-phone" onClick={() => this.onRemoveRecord(indexSelected)}>
                    <i className="fa fa-trash" />
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-3 ml-2 row justify-content-center">
              <DataTable
                columns={columns}
                data={usageRecRemoveIndex}
                isLoading={isSearching}
                indexParent={indexSelected}
              />
            </div>
            <div className="col-md-12 mb-3 ml-2 row justify-content-center">
              <button
                type="button"
                className="button button-border gray x-small"
                onClick={() => this.onMapNextField(indexSelected)}
              >
                {t('label.mapNextField')}
              </button>
            </div>
          </div>
        </CollapsibleTable> */}
          {/* </div> */}

          <div className="col-md-12 mb-4 mt-4 ml-2 row">
            <button
              type="button"
              className="button button-border m-auto gray x-small"
              onClick={() => this.onMapNextRecord()}
            >
              {t('label.mapNextRecord')}
            </button>
            <button
              type="button"
              className="button button-border black x-small float-right"
              onClick={() => onAddNewUsageFile(true, indexExpand)}
              disabled={isHasNew}
            >
              {`+ ${t('label.copyToAdd')}`}
            </button>
            <button type="submit" className="button button-border x-small float-right ml-2">
              {t('label.saveConfig')}
            </button>
          </div>
          <ModalViewNote isOpen={isOpenModalNotes} message={messageNotes(t)} onToggle={this.onToggleModalNotes} />
        </div>
      </form>
    );
  }
}

NewFileMapping.propTypes = {};

NewFileMapping.defaultProps = {};

export default withTranslation('common')(NewFileMapping);
