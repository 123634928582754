/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectTimeUnit } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalHolidayUnit extends PureComponent {
  searchHanldeRateUnitId = (payload, cb) => {
    const { selectTimeUnit } = this.props;
    selectTimeUnit(payload, resp => {
      cb({ success: true, data: resp.data.holidaySpecialCfgs });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'name',
          label: 'common:label.name',
        },
        {
          name: 'startDate',
          label: 'common:label.startDate',
        },
        {
          name: 'endDate',
          label: 'common:label.endDate',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseItem')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeRateUnitId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="name"
      />
    );
  }
}

ModalHolidayUnit.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectTimeUnit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalHolidayUnit.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectTimeUnit })(ModalHolidayUnit));
