import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { searchProvisioningAttributesConfigItem } from '../../../containers/App/actions';
import ModalSelectorWithTableCollapsible from './ModalSelectorWithTableCollapsible';
import ModalMultipleSelectorWithTableCollapsible from './ModalMultipleSelectorWithTableCollapsible';
import SearchForm from './SearchForm';

class ModalSelectProvisioningName extends Component {
  state = {};

  render() {
    const {
      t,
      defaultSearchParams,
      isOpen,
      searchProvisioningAttributesConfigItem,
      onSelect,
      onCancel,
      selectedParentId,
      isMultipleSelector,
      isNoAttributes,
    } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'name',
          label: 'label.name',
        },
        {
          name: 'description',
          label: 'label.description',
        },
      ],
    };
    return isMultipleSelector ? (
      <ModalMultipleSelectorWithTableCollapsible
        modalTitle={t('label.chooseProvisioningName')}
        isOpen={isOpen}
        SearchForm={SearchForm}
        searchItems={searchProvisioningAttributesConfigItem}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        selectFieldName="name"
        isNoAttributes={isNoAttributes}
      />
    ) : (
      <ModalSelectorWithTableCollapsible
        modalTitle={t('label.chooseProvisioningName')}
        isOpen={isOpen}
        SearchForm={SearchForm}
        searchItems={searchProvisioningAttributesConfigItem}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        selectFieldName="name"
        isNoAttributes={isNoAttributes}
      />
    );
  }
}

ModalSelectProvisioningName.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  searchProvisioningAttributesConfigItem: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default withTranslation('common')(
  connect(null, { searchProvisioningAttributesConfigItem })(ModalSelectProvisioningName)
);
