import gql from 'graphql-tag';

export default id => {
  const resendPaymentVendor = `resendPaymentVendor(input: { id: "${id}" })`;
  return gql`
  mutation {
    ${resendPaymentVendor} {
      id
    }
  }
`;
};
