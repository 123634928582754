import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';

export default function ArOpsFormAdd({ itemNew, onChange, onAddNew, isEnableAddBtn, modeCreateReasonCodeConfig }) {
  const { t } = useTranslation('common');
  return (
    <div className="form-group col-md-12 p-5">
      <div className="col-md-12 d-flex">
        <GenericInput
          name="type"
          label="common:label.arType"
          value={itemNew.type}
          type="select"
          isClearable
          wrapperClass="col-md-3"
          options={t('selections:arType')()}
          onChange={onChange}
        />
        <GenericInput
          label="common:label.reasonCode"
          name="reasonCode"
          value={itemNew.reasonCode}
          type="select"
          isClearable
          onChange={onChange}
          wrapperClass="col-md-3"
          options={t('selections:reasonConfig')()}
        />
        <GenericInput
          label="common:label.taxCode"
          type="text"
          onChange={onChange}
          name="taxCode"
          value={itemNew.taxCode}
          wrapperClass="col-md-3"
        />
        <GenericInput
          label="common:label.glAccount"
          value={itemNew.gLAccount}
          type="text"
          onChange={onChange}
          name="gLAccount"
          wrapperClass="col-md-3"
        />
      </div>
      <div className="col-md-12 pt-3 action-buttons">
        <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onAddNew}
          disabled={!isEnableAddBtn || modeCreateReasonCodeConfig === 1}
        >
          {`+ ${t('label.addNewReason')}`}
        </button>
        {/* <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onRemove}
          disabled={isEnableRemove}
        >
          Remove Reason
        </button> */}
      </div>
    </div>
  );
}

ArOpsFormAdd.propTypes = {
  isEnableAddBtn: PropTypes.bool,
  onChange: PropTypes.func,
  onAddNew: PropTypes.func,
  // onRemove: PropTypes.func,
  // isEnableRemove: PropTypes.bool,
  itemNew: PropTypes.objectOf(PropTypes.any),
  modeCreateReasonCodeConfig: PropTypes.number,
};

ArOpsFormAdd.defaultProps = {
  isEnableAddBtn: false,
  // isEnableRemove: false,
  itemNew: {},
  onChange: () => {},
  // onRemove: () => {},
  onAddNew: () => {},
  modeCreateReasonCodeConfig: 0,
};
