import gql from 'graphql-tag';

export default () => {
  const getOmsConfig = 'getOmsConfig(dummy: "")';
  return gql`
    query {
      ${getOmsConfig} {
        id
        orderType
        tasks {
          index
          name
          description
          omsTasks
        }
      }
    }
  `;
};
