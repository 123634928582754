import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateCorrespondenceTemplate = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_CONFIG_CORRESPONDENCE_TEMPLATE });
};

const checkPermissionGetCorrespondenceTemplate = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_CONFIG_CORRESPONDENCE_TEMPLATE });
};

const checkPermissionModifyCorrespondenceTemplate = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_CONFIG_CORRESPONDENCE_TEMPLATE });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeCreateCorrespondenceTemplate = 0;
  let modeGetCorrespondenceTemplate = 0;
  let modeModifyCorrespondenceTemplate = 0;

  modeCreateCorrespondenceTemplate = checkPermissionCreateCorrespondenceTemplate({ listPermission });
  modeGetCorrespondenceTemplate = checkPermissionGetCorrespondenceTemplate({ listPermission });
  modeModifyCorrespondenceTemplate = checkPermissionModifyCorrespondenceTemplate({ listPermission });

  return {
    modeCreateCorrespondenceTemplate,
    modeGetCorrespondenceTemplate,
    modeModifyCorrespondenceTemplate,
  };
};

export {
  checkPermissionCreateCorrespondenceTemplate,
  checkPermissionGetCorrespondenceTemplate,
  checkPermissionModifyCorrespondenceTemplate,
  checkPermissionForAllPages,
};
