import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { getAccumulatorsConfig, modifyAccumulatorsConfig, createAccumulatorsConfig } from '../../actions';
import { makeGetAccumulatorsListConfig } from '../../selectors';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept } from '../../../../components/common';
import { dataExpression2Translation, supportShowNumberValue } from '../../../../utils/utils';
import { operatorOptions, messageExpression } from '../../constants';
import { ModalExpression } from '../../../../components/BaseConfigurationsHub';
import {
  checkPermissionCreateAccumulatorConfig,
  checkPermissionModifyAccumulatorConfig,
  checkPermissionViewAccumulatorConfig,
} from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

class Accumulators extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    accumulatorsList: [],
    isSearching: false,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    isOpenModalExpression: false,
    indexSelectExp: null,
    expressionAddNew: null,
    selectFieldExp: null,
    selectRecordExp: null,
    operator: '-',
  };

  componentDidMount() {
    this.doGetAccumulatorsConfig();
  }

  componentWillReceiveProps(nextProps) {
    const { accumulatorsList } = this.props;
    if (accumulatorsList && nextProps.accumulatorsList && accumulatorsList !== nextProps.accumulatorsList) {
      this.setState({
        accumulatorsList: nextProps.accumulatorsList,
        defaultDataFromAPI: nextProps.accumulatorsList,
        dataSubmit: [],
      });
    }
  }

  onChangeTable = ({ name, value, index }) => {
    const { accumulatorsList, dataSubmit } = this.state;
    const newData = cloneDeep(accumulatorsList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ accumulatorsList: newData });
  };

  onChangeAccumulateQuantity = ({ name, value, index }) => {
    const { accumulatorsList, dataSubmit } = this.state;
    const newData = cloneDeep(accumulatorsList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value === 'TRUE' ? 'true' : 'false';
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value === 'TRUE' ? 'true' : 'false';
    }
    newData[indexItemChange][name] = value === 'TRUE' ? 'true' : 'false';
    this.setState({ accumulatorsList: newData });
  };

  doGetAccumulatorsConfig = () => {
    const { getAccumulatorsConfig } = this.props;
    getAccumulatorsConfig(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id) });
    });
  };

  onAddNewAccumulators = () => {
    let indexNewItem = 0;
    const { accumulatorsList, dataSubmit } = this.state;
    let newData = cloneDeep(accumulatorsList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      accumulatorid: null,
      symbol: null,
      name: null,
      roundingMethod: null,
      roundingPrecision: null,
      accumulateQuantity: 'true',
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ accumulatorsList: newData, dataSubmit });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = () => {
    const { accumulatorsList, defaultDataFromAPI, dataSubmit, idItemRemove } = this.state;
    const newData = cloneDeep(accumulatorsList);
    const indexItemRemove = newData.findIndex(item => item.index === idItemRemove);
    const indexItemFromAPI = defaultDataFromAPI.findIndex(item => item.index === idItemRemove);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === idItemRemove);
    if (indexItemFromAPI > -1 && indexItemRemove > -1) {
      newData[indexItemRemove] = { index: idItemRemove };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index: idItemRemove };
      } else {
        dataSubmit.push({ index: idItemRemove });
      }
    }
    if (indexItemFromAPI === -1 && indexItemRemove > -1) {
      newData.splice(indexItemRemove, 1);
      if (indexDataSubmit > -1) {
        dataSubmit.splice(indexDataSubmit, 1);
      }
    }
    this.setState({ accumulatorsList: newData, dataSubmit, isOpenModalRemoveItem: false });
  };

  onHanldeSubmit = () => {
    const { dataSubmit, isModify } = this.state;
    const { modifyAccumulatorsConfig, createAccumulatorsConfig } = this.props;
    if (isModify) {
      const newPayloadModify = {
        accumulatorList: dataSubmit,
      };
      modifyAccumulatorsConfig(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(dataSubmit).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        accumulatorList: dataRemoveIndex,
      };
      createAccumulatorsConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetAccumulatorsConfig();
        }
      });
    }
  };

  onChangeExp = ({ value, name }) => {
    const { expressionAddNew } = this.state;
    let newData = cloneDeep(expressionAddNew);
    newData = value;
    this.setState({ [name]: newData });
  };

  onChangeExp = ({ value, name }) => {
    const { expressionAddNew } = this.state;
    let newData = cloneDeep(expressionAddNew);
    newData = value;
    this.setState({ [name]: newData });
  };

  onAddOperator = () => {
    const { operator } = this.state;
    let { expressionAddNew } = this.state;
    expressionAddNew = expressionAddNew ? `${expressionAddNew} ${operator} ` : `${operator} `;
    this.setState({ expressionAddNew });
  };

  onChangeSelectField = ({ name, value }) => {
    const { selectRecordExp } = this.state;
    if (name === 'selectRecordExp' && selectRecordExp !== value) {
      return this.setState({ [name]: value, selectFieldExp: '' });
    }
    return this.setState({ [name]: value });
  };

  onAddFieldExp = () => {
    const { selectRecordExp, selectFieldExp } = this.state;
    let { expressionAddNew } = this.state;
    expressionAddNew = `${expressionAddNew}${selectRecordExp}.${selectFieldExp}`;
    this.setState({ expressionAddNew });
  };

  onToggleModal = () => {
    this.setState({
      isOpenModalExpression: false,
      indexSelectExp: null,
      expressionAddNew: '',
      selectFieldExp: '',
      selectRecordExp: '',
      operator: '-',
    });
  };

  onApplyExpression = () => {
    const { expressionAddNew, accumulatorsList, dataSubmit, indexSelectExp } = this.state;
    const { t } = this.props;
    const newData = cloneDeep(accumulatorsList);
    const indexExpChange = accumulatorsList.findIndex(item => item.index === indexSelectExp);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === indexSelectExp);
    const newExpression = expressionAddNew;
    const lengthCharsOpenOperator = (newExpression.match(/[(]/g) || []).length;
    const lengthCharsCloseOperator = (newExpression.match(/[)]/g) || []).length;
    if (lengthCharsOpenOperator !== lengthCharsCloseOperator) {
      return toast.error(messageExpression);
    }
    let isMissingSpaceOpenOperator = false;
    let isMissingSpaceCloseOperator = false;
    for (let i = 0; i < expressionAddNew.length; i++) {
      if (expressionAddNew[i] === '(' && i < expressionAddNew.length && expressionAddNew[i + 1] !== ' ')
        isMissingSpaceOpenOperator = true;
      if (expressionAddNew[i] === ')' && i < expressionAddNew.length && expressionAddNew[i - 1] !== ' ')
        isMissingSpaceCloseOperator = true;
    }
    if (isMissingSpaceOpenOperator && isMissingSpaceCloseOperator) {
      return toast.error(t('message.messageErrorSpaceOperator'));
      // return toast.error('Please add spaces after and before the characters: "(", ")"');
    }
    if (isMissingSpaceOpenOperator) {
      return toast.error(t('message.messageErrorSpaceOperator2'));
      // return toast.error('Please add spaces after the character "("');
    }
    if (isMissingSpaceCloseOperator) {
      return toast.error(t('message.messageErrorSpaceOperator3'));
      // return toast.error('Please add spaces before the character ")"');
    }
    // const isCharsOpenAndClose = expressionAddNew.charAt(0) === '(';
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit].expression = expressionAddNew;
    } else {
      dataSubmit.push({ index: indexSelectExp });
      dataSubmit[dataSubmit.length - 1].expression = expressionAddNew;
    }
    // newData[indexExpChange].expression = expressionAddNew.replace(/[()]/g, '');
    newData[indexExpChange].expression = expressionAddNew;
    return this.setState({
      dataSubmit,
      accumulatorsList: newData,
      isOpenModalExpression: false,
      expressionAddNew: '',
      selectFieldExp: '',
      selectRecordExp: '',
      operator: '-',
    });
  };

  render() {
    const { t, permissionsPricing } = this.props;
    const {
      accumulatorsList,
      isSearching,
      isOpenModalRemoveItem,
      isModify,
      expressionAddNew,
      selectFieldExp,
      selectRecordExp,
      isOpenModalExpression,
      operator,
    } = this.state;

    let modeCreateAccumulatorConfig = 0;
    let modeModifyAccumulatorConfig = 0;
    let modeViewAccumulatorConfig = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeCreateAccumulatorConfig = checkPermissionCreateAccumulatorConfig({ listPermission });
      modeModifyAccumulatorConfig = checkPermissionModifyAccumulatorConfig({ listPermission });
      modeViewAccumulatorConfig = checkPermissionViewAccumulatorConfig({ listPermission });
    }

    if (!modeViewAccumulatorConfig) return null;

    const creditProfileColumns = [
      {
        name: 'accumulatorid',
        label: 'label.accumulatorId',
        required: true,
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item) => (
          <GenericInput
            value={item.accumulatorid}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accumulatorid"
          />
        ),
      },
      {
        name: 'symbol',
        label: 'label.symbol',
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.symbol || null}
            // menuPortalTarget={document.querySelector('body')}
            type="text"
            // options={convertSymbol2OptionName}
            wrapperClass="col-md-12"
            onChange={evt => this.onChangeTable({ name: 'symbol', value: evt.value, index: item.index })}
            name="symbol"
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="name"
          />
        ),
      },
      {
        name: 'roundingMethod',
        label: 'label.roundingMethod',
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.roundingMethod}
            menuPortalTarget
            tOptions="selections:roundingMethod"
            type="select"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="roundingMethod"
          />
        ),
      },
      {
        name: 'roundingPrecision',
        label: 'label.roundingPrecision',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={supportShowNumberValue(item.roundingPrecision)}
            type="number"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="roundingPrecision"
          />
        ),
      },
      {
        name: 'accumulateQuantity',
        label: 'label.accumulateQuantity',
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.accumulateQuantity === 'true' || item.accumulateQuantity === true ? 'TRUE' : 'FALSE'}
            tOptions="selections:selectBool"
            type="select"
            menuPortalTarget
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeAccumulateQuantity({ name, value, index: item.index })}
            name="accumulateQuantity"
          />
        ),
      },
      {
        name: 'expression',
        label: 'label.expression',
        style: { textAlign: 'center', minWidth: '280px' },
        render: (colName, item) => (
          <GenericInput
            // value={dataExpression2Translation(item.expression.replace(/[()]/g, ''), t) || ''}
            value={dataExpression2Translation(item.expression, t) || ''}
            wrapperClass="col-md-12 overlap-text-field"
            onChange={({ name, value }) => this.onChangeTable({ name, value, code: item.index })}
            onClick={() => {
              this.setState({
                isOpenModalExpression: true,
                indexSelectExp: item.index,
                expressionAddNew: item.expression || '',
              });
            }}
            fa="fa fa-external-link"
            name="expression"
            type="textarea"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onToggleModalRemoveItem({ index: item.index })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataRemoveIndex =
      accumulatorsList && accumulatorsList.length ? accumulatorsList.filter(item => size(item) > 1) : [];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.accumulators') },
          ]}
        />
        <FormWithTableItem title={t('baseConfigurationsPage:sidebar.accumulators')} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateAccumulatorConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewAccumulators}>
                +
                {t('label.addNewAccumulators')}
              </button>
            )}
            {modeModifyAccumulatorConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {isModify ? t('label.modify') : t('label.createConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetAccumulatorsConfig()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={creditProfileColumns}
              data={newDataRemoveIndex}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message={t('label.messageDelete')}
        />
        <ModalExpression
          onToggle={this.onToggleModal}
          isOpenModalExpression={isOpenModalExpression}
          expressionAddNew={expressionAddNew}
          onChangeExp={this.onChangeExp}
          operator={operator}
          operatorOptions={operatorOptions}
          selectFieldExp={selectFieldExp}
          onAddOperator={this.onAddOperator}
          selectRecordExp={selectRecordExp}
          onChangeSelectField={this.onChangeSelectField}
          onAddFieldExp={this.onAddFieldExp}
          onApplyExpression={this.onApplyExpression}
          onSetOperator={value => {
            this.setState({ operator: value });
          }}
        />
      </div>
    );
  }
}

Accumulators.propTypes = {
  getAccumulatorsConfig: PropTypes.func.isRequired,
  modifyAccumulatorsConfig: PropTypes.func.isRequired,
  createAccumulatorsConfig: PropTypes.func.isRequired,
  accumulatorsList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

Accumulators.defaultProps = {
  accumulatorsList: [],
};

const mapStateToProps = createStructuredSelector({
  accumulatorsList: makeGetAccumulatorsListConfig() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getAccumulatorsConfig, modifyAccumulatorsConfig, createAccumulatorsConfig })(Accumulators)
);
