import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 6,
    columns: [],
    data: [[{ value: title || 'FILES ERROR', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.itemId,
      columns.common, // errorMessage
      columns.name, // name
      columns.description, // description
      columns.common, // serviceProductCode
      columns.common, // uom
      columns.common, // chargeType
      columns.status, // status
      columns.common, // isParent
      columns.common, // isChild
      columns.serviceType, // serviceType
      columns.glAccount, // glAccount
      columns.taxCode, // taxCode
      columns.common, // uomName
      columns.common, // productFamily
      columns.common, // productLine
      columns.common, // productType
    ],
    data: [
      [
        {
          value: t('label.itemId'),
          style: styleHeader,
        },
        {
          value: t('label.errorMessage'),
          style: styleHeader,
        },
        {
          value: t('label.name'),
          style: styleHeader,
        },
        {
          value: t('label.description'),
          style: styleHeader,
        },
        {
          value: t('label.serviceProductCode'),
          style: styleHeader,
        },
        {
          value: t('label.uom'),
          style: styleHeader,
        },
        {
          value: t('label.chargeType'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
        {
          value: t('label.isParent'),
          style: styleHeader,
        },
        {
          value: t('label.isChild'),
          style: styleHeader,
        },
        {
          value: t('label.serviceType'),
          style: styleHeader,
        },
        {
          value: t('label.glAccount'),
          style: styleHeader,
        },
        {
          value: t('label.taxCode'),
          style: styleHeader,
        },
        {
          value: t('label.uomName'),
          style: styleHeader,
        },
        {
          value: t('label.productFamily'),
          style: styleHeader,
        },
        {
          value: t('label.productLine'),
          style: styleHeader,
        },
        {
          value: t('label.productType'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.itemId ? item.itemId : '' });
      newItem.push({ value: item.errorMessage ? item.errorMessage : '' });
      newItem.push({ value: item.name ? item.name : '' });
      newItem.push({ value: item.description ? item.description : '' });
      newItem.push({ value: item.serviceProductCode ? item.serviceProductCode : '' });
      newItem.push({ value: item.uom ? item.uom : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'chargeType', fieldOption: 'chargeType' }) });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'itemServiceStatus' }) });
      newItem.push({ value: item.isParent ? 'TRUE' : item.isParent === false ? 'FALSE' : '' });
      newItem.push({ value: item.isChild ? 'TRUE' : item.isChild === false ? 'FALSE' : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'serviceType', fieldOption: 'serviceType' }) });
      newItem.push({ value: item.glAccount ? item.glAccount : '' });
      newItem.push({ value: item.taxCode ? item.taxCode : '' });
      newItem.push({ value: item.uomName ? item.uomName : '' });
      newItem.push({ value: item.productFamily ? item.productFamily : '' });
      newItem.push({ value: item.productLine ? item.productLine : '' });
      newItem.push({ value: item.productType ? item.productType : '' });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
