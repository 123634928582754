import gql from 'graphql-tag';

export default id => gql`
  query {
    getPaymentInstallmentByAccountId(accountId: "${id}") {
      id
      accountId
      status
      numOfInstallment
      daysOffset
      monthOffset
      paymentInstallmentData {
        index
        daysOffset
        monthOffset
        percent
      }
    }
  }
`;
