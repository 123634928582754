import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import { checkPermissionGetJobSchedule, checkPermissionFindJobConfig } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const {
      isExpand = true,
      location: { pathname },
      permissionsJob,
    } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsJob, listKeys: permissionList.jobsManagement })) return null;

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: false,
          mainTilte: 'navbar:operationsHub.main',
          href: RouteNames.jobsManagmentJobConfig.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'jobManagementPage:sidebar.config',
          isExpandChild: true,
          childs: [
            {
              icon: 'ti-settings',
              label: 'jobManagementPage:sidebar.jobScheduleConfig',
              href: RouteNames.jobsManagmentJobConfig.path,
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'navbar:operationsHub.subMain.jobsManagement',
          childs: [
            {
              icon: 'ti-view-list-alt',
              label: 'jobManagementPage:sidebar.jobSchedule',
              href: RouteNames.jobsManagmentJobSchedule.path,
              isActive: pathname && pathname.indexOf(RouteNames.jobsManagmentJobSchedule.path) >= 0,
            },
            // {
            //   icon: 'ti-widget',
            //   label: 'jobManagementPage:sidebar.jobScheduleConfig',
            //   href: RouteNames.jobsManagmentJobConfig.path,
            //   isActive: pathname && pathname.indexOf(RouteNames.jobsManagmentJobConfig.path) >= 0,
            // },
          ],
        },
      ],
    };

    const newSideProps = cloneDeep(sideProps);
    let modeGetJobChedule = 0;
    let modeFindJobConfig = 0;
    const indexJobSchedule = newSideProps.items.findIndex(
      child => child.mainTilte === 'navbar:operationsHub.subMain.jobsManagement'
    );
    if (permissionsJob && permissionsJob.jobsModulePermissions) {
      const listPermission = permissionsJob.jobsModulePermissions;
      modeGetJobChedule = checkPermissionGetJobSchedule({ listPermission });
      modeFindJobConfig = checkPermissionFindJobConfig({ listPermission });
    }

    if (modeGetJobChedule === 0 && indexJobSchedule > -1) {
      newSideProps.items[indexJobSchedule].childs = newSideProps.items[indexJobSchedule].childs.filter(
        item => item.label !== 'jobManagementPage:sidebar.jobScheduleConfig'
      );
    }

    if (modeFindJobConfig === 0 && indexJobSchedule > -1) {
      newSideProps.items[indexJobSchedule].childs = newSideProps.items[indexJobSchedule].childs.filter(
        item => item.label !== 'jobManagementPage:sidebar.jobSchedule'
      );
    }

    return <SideBar {...newSideProps} />;
  }
}

// export default Sidebar;
const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsJob = store.get('auth').toJS().permissionsApp.opsHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsJob: permissionsJob.length ? permissionsJob.filter(a => a.moduleName === 'JOBS_MANAGEMENT')[0] : {},
  };
};

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsJob: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsJob: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
