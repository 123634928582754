import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles.scss';

const NumberInputGroup = ({
  label,
  wrapperClass,
  onOpenPopupInfo,
  onChange,
  isPopupInfo,
  labelPopupInfo,
  ...props
}) => {
  const { t } = useTranslation('common');
  return (
    <div className={`form-group ${wrapperClass}`}>
      {label && (
        <span>
          {t(label)}
          {label && props.required && <i className="fa fa-star is-required" />}
          &nbsp;
        </span>
      )}
      <div className="wr-input d-flex number-form" id={`${props.name}`}>
        <button
          type="button"
          className="minus-number btn-control"
          disabled={props.disabled || props.readOnly}
          onClick={() => {
            onChange({ name: props.name, value: `${Number.parseFloat(props?.value || 0) - 1}` });
          }}
        >
          <i className="ti-minus" />
        </button>
        <NumberFormat
          {...props}
          className="form-control form-control-number input-group input-group-number"
          onValueChange={values => {
            const { value } = values;
            onChange({ name: props.name, value });
          }}
          thousandSeparator
        />
        <button
          type="button"
          className="plus-number btn-control"
          disabled={props.disabled || props.readOnly}
          onClick={() => {
            onChange({ name: props.name, value: `${Number.parseFloat(props?.value || 0) + 1}` });
          }}
        >
          <i className="ti-plus" />
        </button>
        {isPopupInfo && (
          <button type="button" className="button x-small btn-popup" onClick={onOpenPopupInfo}>
            {t(labelPopupInfo)}
          </button>
        )}
      </div>
    </div>
  );
};

NumberInputGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onOpenPopupInfo: PropTypes.func,
  required: PropTypes.bool,
};

NumberInputGroup.defaultProps = {
  label: '',
  onChange: () => {},
  onOpenPopupInfo: () => {},
  type: 'text',
  wrapperClass: 'col-md-3',
  required: false,
};

export default NumberInputGroup;
