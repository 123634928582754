import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchConfigOperatorProvisioning = `searchConfigOperatorProvisioning(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchConfigOperatorProvisioning} {
        index
        operatorId
        provisioningId
      }
    }
  `;
};
