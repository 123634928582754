import * as types from './types';

export function searchOperatorDispute(payload, cb) {
  return {
    type: types.SEARCH_OPERATOR_DISPUTE,
    payload,
    cb,
  };
}

export function searchOperatorDisputeSuccess(payload, cb) {
  return {
    type: types.SEARCH_OPERATOR_DISPUTE_SUCCESS,
    payload,
    cb,
  };
}

export function getOperatorDisputeById(payload, cb) {
  return {
    type: types.GET_OPERATOR_BY_ID,
    payload,
    cb,
  };
}

export function searchOperatorInvoice(payload, cb) {
  return {
    type: types.SEARCH_OPERATOR_INVOICE,
    payload,
    cb,
  };
}

export function searchOperatorInvoiceSuccess(payload, cb) {
  return {
    type: types.SEARCH_OPERATOR_INVOICE_SUCCESS,
    payload,
    cb,
  };
}

export function getOperatorInvoiceById(payload, cb) {
  return {
    type: types.GET_OPERATOR_INVOICE_BY_ID,
    payload,
    cb,
  };
}

export function searchOperatorRecordLog(payload, cb) {
  return {
    type: types.SEARCH_OPERATOR_RECORD_LOG,
    payload,
    cb,
  };
}

export function searchOperatorRecordLogSuccess(payload, cb) {
  return {
    type: types.SEARCH_OPERATOR_RECORD_LOG_SUCCESS,
    payload,
    cb,
  };
}

export function getOperatorRecordLogById(payload, cb) {
  return {
    type: types.GET_OPERATOR_RECORD_LOG_BY_ID,
    payload,
    cb,
  };
}

export function searchTenantDispute(payload, cb) {
  return {
    type: types.SEARCH_TENANT_DISPUTE,
    payload,
    cb,
  };
}

export function searchTenantDisputeSuccess(payload, cb) {
  return {
    type: types.SEARCH_TENANT_DISPUTE_SUCCESS,
    payload,
    cb,
  };
}

export function getTenantDisputeById(payload, cb) {
  return {
    type: types.GET_TENANT_DISPUTE_BY_ID,
    payload,
    cb,
  };
}

export function searchTenantOperatorRecord(payload, cb) {
  return {
    type: types.SEARCH_TENANT_RECORD,
    payload,
    cb,
  };
}

export function searchTenantOperatorRecordSuccess(payload, cb) {
  return {
    type: types.SEARCH_TENANT_RECORD_SUCCESS,
    payload,
    cb,
  };
}

export function getTenantRecordById(payload, cb) {
  return {
    type: types.GET_TENANT_RECORD_BY_ID,
    payload,
    cb,
  };
}

export function operatorInvoiceRecon(payload, cb) {
  return {
    type: types.OPERATOR_INVOICE_RECON,
    payload,
    cb,
  };
}

export function operatorDisputeRecon(payload, cb) {
  return {
    type: types.OPERATOR_DISPUTE_RECON,
    payload,
    cb,
  };
}

export function searchReRatingBatches(payload, cb) {
  return {
    type: types.SEARCH_RERATING_BATCHES,
    payload,
    cb,
  };
}

export function searchReRatingBatchesSuccess(payload, cb) {
  return {
    type: types.SEARCH_RERATING_BATCHES_SUCCESS,
    payload,
    cb,
  };
}

export function saveReratingBatch(payload, cb) {
  return {
    type: types.SAVE_RERATING_BATCH,
    payload,
    cb,
  };
}

export function getReratingBatchById(payload, cb) {
  return {
    type: types.GET_RERATING_BY_ID,
    payload,
    cb,
  };
}

export function modifyReratingBatch(payload, cb) {
  return {
    type: types.MODIFY_RERATING_BATCH,
    payload,
    cb,
  };
}

export function getConfigUsagePlatforms(payload, cb) {
  return {
    type: types.GET_CONFIG_USAGE_PLATFORMS,
    payload,
    cb,
  };
}

export function createConfigUsagePlatform(payload, cb) {
  return {
    type: types.CREATE_CONFIG_USAGE_PLATFORM,
    payload,
    cb,
  };
}

export function modifyConfigUsagePlatform(payload, cb) {
  return {
    type: types.MODIFY_CONFIG_USAGE_PLATFORM,
    payload,
    cb,
  };
}

// Time Custom Record
export function getCustomUsageRecCfg(cb) {
  return {
    type: types.GET_CUSTOM_RECORD_CFG,
    cb,
  };
}

export function getCustomUsageRecCfgSuccess(payload) {
  return {
    type: types.GET_CUSTOM_RECORD_CFG_SUCCESS,
    payload,
  };
}

export function modifyCustomUsageRecCfg(payload) {
  return {
    type: types.MODIFY_CUSTOM_RECORD_CFG,
    payload,
  };
}

export function createCustomUsageRecCfg(payload, cb) {
  return {
    type: types.CREATE_CUSTOM_RECORD_CFG,
    payload,
    cb,
  };
}

// File Mapping
export function getUsageFileMapCfg(cb) {
  return {
    type: types.GET_USAGE_FILE_MAPPING_CFG,
    cb,
  };
}

export function getUsageFileMapCfgSuccess(payload) {
  return {
    type: types.GET_USAGE_FILE_MAPPING_CFG_SUCCESS,
    payload,
  };
}

export function createUsageFileMapCfg(payload, cb) {
  return {
    type: types.CREATE_FILE_MAPPING,
    payload,
    cb,
  };
}

export function modifyUsageFileMapCfg(payload, cb) {
  return {
    type: types.MODIFY_FILE_MAPPING,
    payload,
    cb,
  };
}

// Processing Streams
export function getUsageStreamMap(cb) {
  return {
    type: types.GET_USAGE_STREAM_MAPPING_CFG,
    cb,
  };
}

export function getUsageStreamMapSuccess(payload) {
  return {
    type: types.GET_USAGE_STREAM_MAPPING_CFG_SUCCESS,
    payload,
  };
}

export function createUsageProcMapConfig(payload, cb) {
  return {
    type: types.CREATE_STREAM_MAPPING,
    payload,
    cb,
  };
}

export function modifyUsageProcMapConfig(payload, cb) {
  return {
    type: types.MODIFY_STREAM_MAPPING,
    payload,
    cb,
  };
}

export function searchSuspendedFailedBatch(payload, cb) {
  return {
    type: types.SEARCH_SUSPENDED_FAILED_BATCH,
    payload,
    cb,
  };
}

export function searchDetailRecords(payload, cb) {
  return {
    type: types.SEARCH_DETAIL_RECORDS,
    payload,
    cb,
  };
}

export function updateFileDetailRecord(payload, cb) {
  return {
    type: types.UPLOAD_FILE_DETAIL_RECORD,
    payload,
    cb,
  };
}

export function initiateUsageReprocess(payload, cb) {
  return {
    type: types.INITIATE_USAGE_REPROCESS,
    payload,
    cb,
  };
}

export function initiateReratingProcess(payload, cb) {
  return {
    type: types.INITIATE_RERATING_REPROCESS,
    payload,
    cb,
  };
}

export function getSuspendedFailedBatchDetail(payload, cb) {
  return {
    type: types.GET_SUSPENDED_FAILED_BATCH_DETAIL,
    payload,
    cb,
  };
}

export function createAndProcessUsageRecord(payload, cb) {
  return {
    type: types.CREATE_AND_PROCESS_USAGE_RECORD,
    payload,
    cb,
  };
}

export function getZeroRateUsageConfig(cb) {
  return {
    type: types.GET_ZERO_RATE_USAGE_CONFIG,
    cb,
  };
}

export function createZeroRateUsageConfig(payload, cb) {
  return {
    type: types.CREATE_ZERO_RATE_USAGE_CONFIG,
    payload,
    cb,
  };
}

export function modifyZeroRateUsageConfig(payload, cb) {
  return {
    type: types.MODIFY_ZERO_RATE_USAGE_CONFIG,
    payload,
    cb,
  };
}

// Voice Plan
export function searchVoicePlan(payload, cb) {
  return {
    type: types.GET_VOICE_PLAN,
    payload,
    cb,
  };
}

export function searchVoicePlanSuccess(payload, cb) {
  return {
    type: types.GET_VOICE_PLAN_SUCCESS,
    payload,
    cb,
  };
}

export function modifyVoicePlan(payload, cb) {
  return {
    type: types.MODIFY_VOICE_PLAN,
    payload,
    cb,
  };
}

export function createVoicePlan(payload, cb) {
  return {
    type: types.CREATE_VOICE_PLAN,
    payload,
    cb,
  };
}

export function getRegionMapConfig(cb) {
  return {
    type: types.GET_REGION_MAP_CONFIG,
    cb,
  };
}

export function createRegionMapConfig(payload, cb) {
  return {
    type: types.CREATE_REGION_MAP_CONFIG,
    payload,
    cb,
  };
}

export function modifyRegionMapConfig(payload, cb) {
  return {
    type: types.MODIFY_REGION_MAP_CONFIG,
    payload,
    cb,
  };
}

// Trunk Rules
export function getTrunkRulesConfig(cb) {
  return {
    type: types.GET_TRUNK_RULES,
    cb,
  };
}

export function getTrunkRulesConfigSuccess(payload) {
  return {
    type: types.GET_TRUNK_RULES_SUCCESS,
    payload,
  };
}

export function modifyTrunkRulesConfig(payload, cb) {
  return {
    type: types.MODIFY_TRUNK_RULES,
    payload,
    cb,
  };
}

export function createTrunkRulesConfig(payload, cb) {
  return {
    type: types.CREATE_TRUNK_RULES,
    payload,
    cb,
  };
}

export function getTenantInterConnectIndicator(payload, cb) {
  return {
    type: types.GET_TENANT_INTER_CONNECT_INDICATOR,
    payload,
    cb,
  };
}

export function getTenantTrafficType(payload, cb) {
  return {
    type: types.GET_TENANT_TRAFFIC_TYPE,
    payload,
    cb,
  };
}

export function getTenantTrunkType(payload, cb) {
  return {
    type: types.GET_TENANT_TRUNK_TYPE,
    payload,
    cb,
  };
}

export function getTenantUsageIndicator(payload, cb) {
  return {
    type: types.GET_TENANT_USAGE_INDICATOR,
    payload,
    cb,
  };
}

export function searchTrunkRulesConfigList(payload, cb) {
  return {
    type: types.SEARCH_TRUNK_RULES_CONFIG_LIST,
    payload,
    cb,
  };
}

export function getConfigPnnPlanNumbering(payload, cb) {
  return {
    type: types.GET_CONFIG_PNP_PLAN_NUMBER,
    payload,
    cb,
  };
}

export function getConfigPnnPlanNumberingSuccess(payload) {
  return {
    type: types.GET_CONFIG_PNP_PLAN_NUMBER_SUCCESS,
    payload,
  };
}

// Rate Unit
export function getOperatorMapCfg(cb) {
  return {
    type: types.GET_OPERATOR_MAP,
    cb,
  };
}

export function getOperatorMapSuccess(payload) {
  return {
    type: types.GET_OPERATOR_MAP_SUCCESS,
    payload,
  };
}

export function modifyOperatorMapCfg(payload, cb) {
  return {
    type: types.MODIFY_OPERATOR_MAP,
    payload,
    cb,
  };
}

export function createOperatorMapCfg(payload, cb) {
  return {
    type: types.CREATE_OPERATOR_MAP,
    payload,
    cb,
  };
}

export function searchConfigOperatorMapList(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_OPERATOR_MAP_LIST,
    payload,
    cb,
  };
}

export function searchConfigOperatorData(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_OPERATOR_DATA,
    payload,
    cb,
  };
}

export function searchConfigOperatorProvisioning(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_OPERATOR_PROVISIONING,
    payload,
    cb,
  };
}

export function searchConfigOperatorSms(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_OPERATOR_SMS,
    payload,
    cb,
  };
}

export function searchConfigOperatorVoice(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_OPERATOR_VOICE,
    payload,
    cb,
  };
}
