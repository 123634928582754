import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['objectType'])}` : '';
  const searchObjectApprovals = `searchObjectApprovals(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchObjectApprovals} {
        id
        accountId
        objectId
        createdDate
        objectType
        approvals {
          index
          groupId
          groupName
          userId
          firstName
          lastName
          dateRequested
          dateProcessed
          status
          comments
          notes
        }
      }
    }
  `;
};
