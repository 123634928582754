import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class DependencySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.bundleId',
      name: 'bundleId',
    },
    {
      label: 'common:label.bundleName',
      name: 'bundleName',
    },
    {
      label: 'common:label.packageId',
      name: 'packageId',
    },
    {
      label: 'common:label.packageName',
      name: 'packageName',
    },
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.type',
      name: 'type',
      tOptions: 'selections:dependencyType',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'common:label.isBundle',
      name: 'isBundle',
      type: 'checkbox',
      wrapperClass: 'form-group mt-4 pt-2',
      exclusionField: 'isPackage',
    },
    {
      label: 'common:label.isPackage',
      name: 'isPackage',
      type: 'checkbox',
      wrapperClass: 'form-group mt-4 pt-2',
      exclusionField: 'isBundle',
    },
  ];
}

DependencySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

DependencySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(DependencySearchForm);
