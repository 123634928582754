import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accountType',
        'initialTermUnit',
        'renewalTermUnit',
        'marketSegment',
        'trialType',
      ])}`
    : '';
  const getTrialToPaidSubscriptionReport = `getTrialToPaidSubscriptionReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getTrialToPaidSubscriptionReport} {
        subscriptionId
        accountId
        company
        firstName
        lastName
        effectiveDate
        trialEndDate
        endDate
        sellingCompany
        accountType
        initialTermUnit
        initialTerm
        renewalTermUnit
        renewalTerm
        trialType
        trialTerm
        trialTermUnit
        trialLevel
      }
    }
  `;
};
