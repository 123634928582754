import gql from 'graphql-tag';

export default () => gql`
  query {
    getRateUnit(dummy: "") {
      id
      rateUnits {
        code
        expression
        unit
        status
        isQuantityScalable
      }
    }
  }
`;
