/**
 * Customerpage selectors
 */

import { createSelector } from 'reselect';

const selectCustomers = state => state.get('customerReducer');

const makeGetListCustomers = () => createSelector(selectCustomers, customers => [...customers.get('listCustomers')]);
const makePageCusomterParams = () => createSelector(selectCustomers, customers => customers.get('params').toJS());

const makeAccountDetail = () => createSelector(selectCustomers, customers => customers.get('accountSelected'));
const makeGetPriceUnits = () => createSelector(selectCustomers, customers => [...customers.get('priceUnits')]);

const makeGetServiceUnitsAc = () => createSelector(selectCustomers, customers => [...customers.get('serviceUnitsAc')]);

const makeGetSubscription = () => createSelector(selectCustomers, customers => customers.get('subscription'));

const makeGetBalanceUnit = () => createSelector(selectCustomers, customers => customers.get('balanceUnit'));

const makeGetTransactionUnit = () =>
  createSelector(selectCustomers, customers => [...customers.get('transactionUnits')]);

const makePageTransactionParams = () =>
  createSelector(selectCustomers, customers => customers.get('paramsTransaction').toJS());

const makeGetArActivity = () => createSelector(selectCustomers, customers => [...customers.get('arActivity')]);

const makeArActivityParams = () =>
  createSelector(selectCustomers, customers => customers.get('paramsArActivity').toJS());

const makeGetCustomerActivity = () => createSelector(selectCustomers, customers => [...customers.get('activities')]);

const makePageActivityParams = () =>
  createSelector(selectCustomers, customers => customers.get('paramsActivity').toJS());

const makeGetPendingBills = () => createSelector(selectCustomers, customers => [...customers.get('pendingBills')]);

const makeGetOCBills = () => createSelector(selectCustomers, customers => customers.get('ocBills'));

const makeErrorMessage = () => createSelector(selectCustomers, customers => customers.get('errorMessage'));

const makeErrorMessagePU = () => createSelector(selectCustomers, customers => customers.get('errorPriceUnits'));

const makeErrorMessageSU = () => createSelector(selectCustomers, customers => customers.get('errorServiceUnits'));

const makeErrorMessageSubscription = () =>
  createSelector(selectCustomers, customers => customers.get('errorSubscription'));
const makeErrorMessageBU = () => createSelector(selectCustomers, customers => customers.get('errorBalanceUnits'));
const makeErrorMessageTU = () => createSelector(selectCustomers, customers => customers.get('errorTransactionUnit'));

const makeErrorMessagePendingBill = () =>
  createSelector(selectCustomers, customers => customers.get('errorPendingBill'));

const makeErrorMessageOCBill = () => createSelector(selectCustomers, customers => customers.get('errorOCBill'));

const makeCreditProfile = () => createSelector(selectCustomers, customers => customers.get('creditProfile'));

const makeFutureCycleCfg = () => createSelector(selectCustomers, customers => customers.get('futureCycleCfg'));

const makeGetTransactionUsage = () => createSelector(selectCustomers, customers => customers.get('transactionUsage'));

const makeGetOrderCustomAttributes = () =>
  createSelector(selectCustomers, customers => customers.get('orderCustomAttributes'));

export {
  makeGetListCustomers,
  makePageCusomterParams,
  makeAccountDetail,
  makeGetPriceUnits,
  makeGetServiceUnitsAc,
  makeGetSubscription,
  makeGetBalanceUnit,
  makeGetTransactionUnit,
  makePageTransactionParams,
  makeGetArActivity,
  makeArActivityParams,
  makeGetCustomerActivity,
  makePageActivityParams,
  makeGetPendingBills,
  makeErrorMessage,
  makeErrorMessagePU,
  makeErrorMessageSU,
  makeErrorMessageSubscription,
  makeErrorMessageBU,
  makeErrorMessageTU,
  makeErrorMessagePendingBill,
  makeErrorMessageOCBill,
  makeGetOCBills,
  makeCreditProfile,
  makeFutureCycleCfg,
  makeGetTransactionUsage,
  makeGetOrderCustomAttributes,
};
