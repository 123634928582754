import gql from 'graphql-tag';

export default fileName => {
  const getBanortePaymentByFileName = `getBanortePaymentByFileName(input: {fileName:"${fileName}"})`;
  return gql`
    query {
      ${getBanortePaymentByFileName} {
        paymentRefId
        bill
        operationDate
        recordDate
        reference
        description
        transactionCode
        branchOffice
        deposit
        withdrawal
        balance
        movement
        detailedDescription
        cheque
        accountId
        invoiceId
        status
        errorCode
        suspenseAccountFlag
        paymentId
        batchId
        createdDate
        processedDate
        fileName
      }
    }
  `;
};
