import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class InvoiceSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.invoiceDate',
      name: 'invoiceDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.dueDate',
      name: 'dueDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.due',
      name: 'due',
      type: 'number',
    },
    {
      label: 'common:label.type',
      name: 'type',
      tOptions: 'selections:invoiceUnitType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:invoiceStatus',
      type: 'select',
      isClearable: true,
    },
  ];
}

InvoiceSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

InvoiceSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(InvoiceSearchForm);
