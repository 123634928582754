import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.label));

// const HeaderTable = ({ t, columnsTable }) => [

//   t('label.id'),
//   t('label.startDate'),
//   t('label.endDate'),
//   t('label.accountingPeriod'),
//   t('label.batchId'),
//   t('label.glAccount'),
//   t('label.costCenter'),
//   t('label.interCompany'),
//   t('label.currency'),
//   t('label.accountId'),
//   t('label.accountType'),
//   t('label.itemName'),
//   t('label.amount'),
//   t('label.amountRecognized'),
//   t('label.revenueType'),
//   t('label.revenueRecognitionType'),
//   t('label.revenueActivityType'),
//   t('label.transactionType'),
//   t('label.status'),
//   t('label.billed'),
//   t('label.transactionId'),
//   t('label.arOpsId'),
//   t('label.itemId'),
//   t('label.billUnitId'),
//   t('label.invoiceId'),
//   t('label.invoiceDate'),
//   t('label.country'),
//   t('label.productLine'),
//   t('label.objectType'),
//   t('label.notesId'),
//   t('label.paymentId'),
//   t('label.subAccount'),
//   t('label.glOffset'),
//   t('label.salesChannel'),
//   t('label.marketSegment'),
//   t('label.accountSubType'),
//   t('label.exchangeRate'),
//   t('label.itemSplitId'),
//   t('label.sellingCompany'),
//   t('label.legalEntity'),
//   t('label.setOfBooks'),
//   t('label.productCompany'),
// ];

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const monthYearT = t('selections:monthOfYear')();
  const newListData = data.map(item => {
    const newItem = [];
    if (typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'accountingPeriod') {
          if (item.accountingPeriod) {
            let newItemSelect = item.accountingPeriod;
            const itemTranslation = monthYearT.find(it => newItemSelect.indexOf(it.value) > -1);
            if (itemTranslation) {
              newItemSelect = `${itemTranslation.label}${newItemSelect.substring(3, newItemSelect.length)}`;
            }
            newItem.push({ content: newItemSelect || '' });
          } else {
            newItem.push({ content: item.accountingPeriod ? item.accountingPeriod : '' });
          }
        }
        if (val.name !== 'accountingPeriod') {
          if (item[val.name] && typeof item[val.name] === 'number') {
            newItem.push({ content: formatMoneyValueToExport(item[val.name]) });
          } else if (val.isRenderT) {
            newItem.push({
              content: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
          } else {
            newItem.push({ content: item[val.name] ? item[val.name] : '' });
          }
        }
      });
      // newItem.push(item.id ? item.id : '');
      // newItem.push(item.revenueStartDate ? item.revenueStartDate : '');
      // newItem.push(item.revenueEndDate ? item.revenueEndDate : '');
      // if (item.accountingPeriod) {
      //   let newItemSelect = item.accountingPeriod;
      //   const itemTranslation = monthYearT.find(it => newItemSelect.indexOf(it.value) > -1);
      //   if (itemTranslation) {
      //     newItemSelect = `${itemTranslation.label}${newItemSelect.substring(3, newItemSelect.length)}`;
      //   }
      //   newItem.push(newItemSelect || '');
      // } else {
      //   newItem.push(item.accountingPeriod ? item.accountingPeriod : '');
      // }
      // newItem.push(item.extractBatchId ? item.extractBatchId : '');
      // newItem.push(item.glAccount ? item.glAccount : '');
      // newItem.push(item.costCenter ? item.costCenter : '');
      // newItem.push(item.interCompany ? item.interCompany : '');
      // newItem.push(item.currency ? item.currency : '');
      // newItem.push(item.accountId ? item.accountId : '');
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'accountType', fieldName: 'accountType' }));
      // newItem.push(item.itemName ? item.itemName : '');
      // newItem.push(formatMoneyValueToExport(item.amount));
      // newItem.push(formatMoneyValueToExport(item.amountRecognized));
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'revenueType', fieldName: 'revenueType' }));
      // newItem.push(
      //   getLabelOptions({ item, t, fieldOption: 'revenueRecognitionType', fieldName: 'revenueRecognitionType' })
      // );
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'revenueActivityType', fieldName: 'revenueActivityType' }));
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'transactionType', fieldName: 'transactionType' }));
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'revenueJournalStatus', fieldName: 'status' }));
      // newItem.push(item.billed ? 'TRUE' : 'FALSE');
      // newItem.push(item.transactionId ? item.transactionId : '');
      // newItem.push(item.arOpsId ? item.arOpsId : '');
      // newItem.push(item.itemId ? item.itemId : '');
      // newItem.push(item.billUnitId ? item.billUnitId : '');
      // newItem.push(item.invoiceId ? item.invoiceId : '');
      // newItem.push(item.invoiceDate ? item.invoiceDate : '');
      // newItem.push(item.country ? item.country : '');
      // newItem.push(item.productLine ? item.productLine : '');
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'revenueReportObjectType', fieldName: 'reportObjectType' }));
      // newItem.push(item.notesId ? item.notesId : '');
      // newItem.push(item.paymentId ? item.paymentId : '');
      // newItem.push(item.glSubAccount ? item.glSubAccount : '');
      // newItem.push(item.glOffset ? item.glOffset : '');
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'salesChannel', fieldName: 'salesChannel' }));
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'marketSegment', fieldName: 'marketSegment' }));
      // newItem.push(getLabelOptions({ item, t, fieldOption: 'accountSubType', fieldName: 'accountSubType' }));
      // newItem.push(item.exchangeRate ? item.exchangeRate : '');
      // newItem.push(item.itemSplitId ? item.itemSplitId : '');
      // newItem.push(item.sellingCompany ? item.sellingCompany : '');
      // newItem.push(item.legalEntity ? item.legalEntity : '');
      // newItem.push(item.setOfBooks ? item.setOfBooks : '');
      // newItem.push(item.productCompany ? item.productCompany : '');
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fontSize: 2, cellPadding: 1, fileName, ...rest });
};

export default convertJson2Pdf;
