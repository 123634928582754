import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { size, isEmpty, cloneDeep } from 'lodash';
import { collectionsEnum } from '../../../../constantsApp';
import { TableCollectionConfig, FormAddCollectionAction } from '../../../../components/CollectionsHub';
import { getCollectionActionsByType, createCollectionAction, modifyCollectionAction } from '../../actions';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import {
  checkPermissionCreateCollectionActionConfig,
  checkPermissionModifyCollectionActionConfig,
} from '../../CheckPermission';

import { getTableFromResponeCollections } from '../../../../utils/utils'

class CollectionActions extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      saveCollectionActions: [],
      collectionActions: [],
      rowSelectedActionItem: null,
      newAction: {},
    };

    this.isEmptyListServer = true;
  }

  componentDidMount() {
    const { getCollectionActionsByType } = this.props;
    getCollectionActionsByType(
      {
        type: collectionsEnum.type.collectionAction,
      },
      response => {
        const listTable = getTableFromResponeCollections(response && response.getCollectionActionsByType, 'actionList');
        this.setState({
          collectionActions: listTable,
          saveCollectionActions: listTable,
        });
        if (
          !response.getCollectionActionsByType ||
          !Array.isArray(response.getCollectionActionsByType) ||
          response.getCollectionActionsByType.length <= 0
        ) {
          this.isEmptyListServer = true;
        } else {
          this.isEmptyListServer = false;
        }
      }
    );
  }

  onSelectActionTableRow = item => {
    const { rowSelectedActionItem } = this.state;
    if (rowSelectedActionItem === item) {
      this.setState({ rowSelectedActionItem: null });
    } else {
      this.setState({ rowSelectedActionItem: item });
    }
  };

  updateActionRowValue = (idx, type, val) => {
    const { collectionActions } = this.state;
    collectionActions[idx][type] = val;
    this.setState({ ...collectionActions });
  };

  onChangeNewActionValue = ({ name, value }) => {
    const { newAction } = this.state;
    newAction[name] = value;
    this.setState({ ...newAction });
  };

  // getTableFromRespone = (array, key) => {
  //   if (!array || !Array.isArray(array) || array.length <= 0 || !array[0]) return [];
  //   return array[0][key] || [];
  // };

  handleClickAddNewAction = () => {
    let lastIdx = 1;
    const { collectionActions } = this.state;
    if (collectionActions && collectionActions.length > 0) {
      const sortCollectionActions = collectionActions.sort((a, b) => a.id - b.id);
      lastIdx = sortCollectionActions[sortCollectionActions.length - 1].index + 1;
    }

    this.setState(pre => ({
      collectionActions: [
        ...pre.collectionActions,
        {
          ...pre.newAction,
          index: lastIdx,
        },
      ],
      rowSelectedActionItem: null,
      newAction: { description: '' },
    }));
  };

  handleClickApplyButton = () => {
    const removeAction = [];
    const { saveCollectionActions, collectionActions } = this.state;
    const { createCollectionAction, modifyCollectionAction } = this.props;
    (saveCollectionActions || []).map(item => {
      const checkArray = collectionActions.find(e => e.index === item.index);
      if (!checkArray) {
        removeAction.push({ index: item.index });
      }
      return null;
    });
    const actionList = (collectionActions || []).map(item => ({
      index: item.index,
      description: item.description,
      action: item.action,
    }));
    const data = {
      actionList: [...removeAction, ...actionList],
    };

    if (this.isEmptyListServer) {
      createCollectionAction(data, response => {
        const listTable =
          response && response.createCollectionAction && response.createCollectionAction.actionList
            ? response.createCollectionAction.actionList
            : [];

        this.setState({
          collectionActions: listTable,
          saveCollectionActions: listTable,
        });
        if (!response.createCollectionAction) {
          this.isEmptyListServer = true;
        } else {
          this.isEmptyListServer = false;
        }
      });
    } else {
      modifyCollectionAction(data, response => {
        const listTable =
          response && response.modifyCollectionAction && response.modifyCollectionAction.actionList
            ? response.modifyCollectionAction.actionList
            : [];

        this.setState({
          collectionActions: listTable,
          saveCollectionActions: listTable,
        });
        if (!response.modifyCollectionAction) {
          this.isEmptyListServer = true;
        } else {
          this.isEmptyListServer = false;
        }
      });
    }
  };

  handleClickRemoveAction = item => {
    const { collectionActions } = this.state;
    const newCollection = cloneDeep(collectionActions);
    this.setState({
      collectionActions: newCollection.filter(val => val.index !== item.index),
    });
    // this.setState(pre => ({
    //   collectionActions: pre.collectionActions.filter(e => e !== pre.rowSelectedActionItem),
    //   rowSelectedActionItem: null,
    // }));
  };

  render() {
    const { collectionActions, rowSelectedActionItem, newAction } = this.state;
    const { permissionsCollections, t } = this.props;

    let modeCreateCollectionActionConfig = 0;
    let modeModifyCollectionActionConfig = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeCreateCollectionActionConfig = checkPermissionCreateCollectionActionConfig({ listPermission });
      modeModifyCollectionActionConfig = checkPermissionModifyCollectionActionConfig({ listPermission });
    }

    return (
      <div>
        <TableCollectionConfig
          data={collectionActions}
          // rowSelect={rowSelectedActionItem}
          // onSelectItem={this.onSelectActionTableRow}
          onHandleChangeTable={this.updateActionRowValue}
          handleClickRemoveAction={this.handleClickRemoveAction}
        />
        {modeCreateCollectionActionConfig === 2 && (
          <FormAddCollectionAction
            onChange={this.onChangeNewActionValue}
            itemNew={newAction}
            onAddNew={this.handleClickAddNewAction}
            // onRemove={this.handleClickRemoveAction}
            modeCreateCollectionActionConfig={modeCreateCollectionActionConfig}
            isEnableAddBtn={!!(size(newAction) === 2 && newAction.action && newAction.description)}
            isEnableRemove={isEmpty(rowSelectedActionItem)}
          />
        )}
        {modeModifyCollectionActionConfig === 2 && (
          <div className="col-md-12 p-3 mb-5">
            <button
              type="button"
              className="button button-border x-small float-right"
              onClick={this.handleClickApplyButton}
            >
              {t('label.apply')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

CollectionActions.propTypes = {
  getCollectionActionsByType: PropTypes.func.isRequired,
  createCollectionAction: PropTypes.func.isRequired,
  modifyCollectionAction: PropTypes.func.isRequired,
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

CollectionActions.defaultProps = {
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default withTranslation('common')(
  connect(
    mapStateToProps,
    {
      getCollectionActionsByType,
      createCollectionAction,
      modifyCollectionAction,
    }
  )(CollectionActions)
);
