import gql from 'graphql-tag';

export default payload => {
  return gql`
    query {
      getExternalPOByAccountId(input: { accountId: "${payload|| ''}" }) {
        id
        accountId
        poList{
          index
          purchaseOrderId
          startDate
          endDate
          firstName
          lastName
          poEmailId
          poAmount
          poItems {
            index
            name
            description
            altdescription
            itemid
          }
        }
      }
    }
  `;
};

// poItems {
//   index
//   name
//   description
//   altdescription
//   itemid
// }