import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import RouteName from '../App/RouteNames';
import TaxCodeConfig from './TaxCodeConfig';
import ThirdPartyTaxConfig from './ThirdPartyTaxConfig';
import TaxTypeConfig from './TaxTypeConfig';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class TaxationManagement extends PureComponent {
  render() {
    const { permissionsTaxConfig } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsTaxConfig, listKeys: permissionList.taxation })) return null;
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteName.taxCodeConfig.path} component={TaxCodeConfig} />
              <Route exact path={RouteName.thirdPartyTaxConfig.path} component={ThirdPartyTaxConfig} />
              <Route exact path={RouteName.taxTypeConfig.path} component={TaxTypeConfig} />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsTaxConfig = store.get('auth').toJS().permissionsApp.billingHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsTaxConfig: permissionsTaxConfig.length
      ? permissionsTaxConfig.filter(a => a.moduleName === 'TAXATION')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'taxationReducer', reducer });
const withSaga = injectSaga({ key: 'taxationSaga', saga });

export default compose(withReducer, withSaga, withConnect)(TaxationManagement);
