import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import arrayMove from 'array-move';
import { GenericInput, FormCollapse, TitleFrom, DataTablDragDrop } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsOrderManagement } from '../../App/selectors';
import { searchOms, modifyOms, createOms } from '../actions';
import {
  checkPermissionGetOmsTaskConfig,
  checkPermissionModifyOmsTaskConfig,
  checkPermissionCreateOmsTaskConfig,
} from '../CheckPermission';

class OrderSequence extends React.PureComponent {
  state = {
    data: {},
    wasValidated: false,
    isModify: false,
    activeTab: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetOmsConfig();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetOmsConfig = () => {
    const { searchOms } = this.props;
    searchOms('', ({ data }) => {
      let newData = [];
      if (data && data.length) {
        newData = data.map(dt => ({ ...dt, isDefault: true }));
      }
      this.setState({
        data: newData || [],
        isModify: !!data,
      });
    });
  };

  addNewItem = ({ index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData[index].tasks) newData[index].tasks = [];
      newData[index].tasks.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData[index].tasks.push({
        index: lastIndex + 1,
        name: '',
        description: '',
        omsTasks: null,
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewOderType = () => {
    try {
      const { data } = this.state;
      const { t } = this.props;
      const listOptions = t('selections:types')();
      let newData = cloneDeep(data);
      if (!newData) newData = [];
      const existOptions = listOptions.filter(val => newData.map(i => i.orderType).indexOf(val.value) === -1);
      if (existOptions && existOptions.length) {
        newData.push({
          orderType: existOptions.filter(val => newData.map(i => i.orderType).indexOf(val.value) === -1)[0].value,
          tasks: [],
        });
        return this.setState({ data: newData });
      }
      return toast.error(t('message.orderTypesAlreadyConfigured'));
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = ({ evt, item }) => {
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    // const { data, isModify } = this.state;
    const { modifyOms, createOms } = this.props;
    const { type, isDefault, ...payload } = cloneDeep(item);
    if (item.id) {
      const { id, isDefault, ...payloadModify } = cloneDeep(item);
      return modifyOms({ ...payloadModify }, ({ success }) => {
        if (success) this.doGetOmsConfig();
      });
    }
    return createOms({ ...payload }, ({ success }) => {
      if (success) this.doGetOmsConfig();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTable = ({ name, value, index, indexParent }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[indexParent].tasks[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItemTable = ({ index, indexParent }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[indexParent].tasks[index] = { index: newData[indexParent].tasks[index].index };
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveOrderType = ({ index, item }) => {
    const { modifyOms } = this.props;
    if (item && item.id) {
      modifyOms({ orderType: item.orderType }, () => {
        this.doGetOmsConfig();
      });
    } else {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.splice(index, 1);
      this.setState({ data: newData });
    }
  };

  onSortEnd = ({ oldIndex, newIndex, index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const orderedList = arrayMove(data[index].tasks, oldIndex, newIndex);
    newData[index].tasks = orderedList;
    this.setState({
      data: newData,
    });
  };

  render() {
    const { t, permissionOrder } = this.props;
    let modeCreateOmsConfig = 0;
    let modeGetOmsConfig = 0;
    let modeModifyOmsConfig = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeCreateOmsConfig = checkPermissionCreateOmsTaskConfig({ listPermission });
      modeGetOmsConfig = checkPermissionGetOmsTaskConfig({ listPermission });
      modeModifyOmsConfig = checkPermissionModifyOmsTaskConfig({ listPermission });
    }
    if (!modeGetOmsConfig) return '';
    const { data, activeTab, wasValidated, isModify } = this.state;

    const omsTaskColumns = [
      {
        name: 'omsTasks',
        label: 'label.task',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.omsTasks}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="omsTasks"
            type="select"
            tOptions="selections:omsTasks"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="name"
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="description"
            type="textarea"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveItemTable({ index: idx, indexParent })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.searchAndList.path}
          titleBtn={t('label.back')}
          titleRight={t('orderPage:sidebar.orderSequence')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.orderManagementTaskSequence')} />
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                if (size(item) < 2) return '';
                const optionsOrderType = t('selections:types')();
                const typesList = data.map(dt => dt.orderType).filter(type => type !== item.orderType);

                const listOptions = optionsOrderType.filter(op => typesList && !typesList.includes(op.value));

                const typeSelect = optionsOrderType.find(val => val.value === item.orderType);
                return (
                  <form
                    noValidate
                    ref={this.formRef}
                    // onSubmit={this.onHandleSave}
                    className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                  >
                    <FormCollapse
                      key={`key-${index}`}
                      isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                      title={`${typeSelect ? typeSelect.label : ''}`}
                      state={`state-${index}`}
                      onToggleTab={this.onToggleTab}
                    >
                      <div className="col-md-12 row p-3">
                        <div className="col-md-12 row">
                          <GenericInput
                            label="label.orderType"
                            name="orderType"
                            required
                            value={item.orderType}
                            type="select"
                            options={listOptions || []}
                            wrapperClass="m-auto col-md-4 pl-4 pr-0 pb-3"
                            isNoClearable
                            disabled={item.isDefault}
                            readOnly={item.isDefault}
                            onChange={evt => this.onHandleChange({ ...evt, index })}
                          />
                          <button
                            type="button"
                            className="btn-delete float-right"
                            onClick={evt => this.onRemoveOrderType({ index, item })}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                        {/* <DataTable
                          indexParent={index}
                          columns={omsTaskColumns}
                          data={item && item.tasks ? item.tasks : []}
                          isSupportRemoveIndex
                        /> */}
                        <DataTablDragDrop
                          indexParent={index}
                          columns={omsTaskColumns}
                          data={item && item.tasks ? item.tasks : []}
                          isSupportRemoveIndex
                          onSortEnd={e => this.onSortEnd({ ...e, index })}
                        />
                        <div className="form-group col-md-12 buttons-attibute pt-4">
                          <button
                            type="button"
                            className="button button-border black x-small"
                            onClick={() => this.addNewItem({ index })}
                          >
                            +
                            {t('label.addNewOrderSequence')}
                          </button>
                          {modeModifyOmsConfig === 2 && (
                            <button
                              type="button"
                              onClick={evt => this.onHandleSave({ evt, item })}
                              className="button button-border x-small float-right"
                            >
                              {t('label.saveConfig')}
                            </button>
                          )}
                        </div>
                      </div>
                    </FormCollapse>
                  </form>
                );
              })}

            <div className="form-group col-md-12 buttons-attibute pt-4 mb-4">
              {modeCreateOmsConfig === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={this.onAddNewOderType}
                >
                  +
                  {t('label.addNewOrderType')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetOmsConfig}
                className="button mr-2 button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

OrderSequence.propTypes = {
  searchOms: PropTypes.func,
  modifyOms: PropTypes.func,
  createOms: PropTypes.func,
};

OrderSequence.defaultProps = {
  searchOms: () => {},
  modifyOms: () => {},
  createOms: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchOms,
    modifyOms,
    createOms,
  })(withRouter(OrderSequence))
);
