import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'type',
    'index',
    'daysInAdvance',
    'recurringFeeAdvanceDom',
    'accountType',
    'billingDom',
    'month'
  ]);
  const createInAdvanceBillingConfig = `createInAdvanceBillingConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createInAdvanceBillingConfig} {
      id
    }
  }
`;
};
