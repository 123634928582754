// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'accountType',
    'accountSubType',
    'salesChannel',
    'marketSegment',
    'delay',
  ]);
  const createFutureCycleCfg = `createFutureCycleCfg(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createFutureCycleCfg} {
      id
    }
  }
`;
};
