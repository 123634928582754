import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport, redFontPdfFile } from '../utils';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.exportLabel) || t(val.label));

// const HeaderTable = ({ t }) => [
//   t('label.paymentId'),
//   t('label.paymentReferenceId'),
//   t('label.paymentSuspenseId'),
//   t('label.paymentDate'),
//   t('label.createdDate'),
//   t('label.status'),
//   t('label.folioStatus'),
//   t('label.paymentCurrency'),
//   t('label.paymentAmount'),
//   t('label.remainingAmount'),
//   t('label.paymentFile'),
//   t('label.paymentFileDate'),
//   t('label.paymentTxtType'),
//   t('label.organization'),
//   t('label.accountId'),
//   t('label.firstName'),
//   t('label.lastName'),
//   t('label.accountType'),
//   t('label.paymentMethod'),
//   t('label.paymentSource'),
//   t('label.accountCurrency'),
//   t('label.invoiceUnitId'),
//   t('label.userId'),
//   t('label.paymentDescription'),
//   t('label.appliedExchangeRate'),
//   t('label.currentExchangeRate'),
//   t('label.unrealizedLossGainInSystemCurrencyExport'),
//   t('label.paymentDateExchangeRate'),
// ];

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (item[val.name] && typeof item[val.name] === 'number') {
          newItem.push({
            content: formatMoneyValueToExport(item[val.name]),
            styles: val.isSupportRedFront && item[val.name] < 0 ? redFontPdfFile : null,
          });
        } else if (val.isRenderT) {
          newItem.push({
            content: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
        } else {
          newItem.push({ content: item[val.name] ? item[val.name] : '' });
        }
      });
      // newItem.push(item.paymentId ? item.paymentId : '');
      // newItem.push(item.referenceId ? item.referenceId : '');
      // newItem.push(item.paymentSuspenseId ? item.paymentSuspenseId : '');
      // newItem.push(item.paymentDate ? item.paymentDate : '');
      // newItem.push(item.createdDate ? item.createdDate : '');
      // newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'statusPayment' }));
      // newItem.push(getLabelOptions({ item, t, fieldName: 'folioStatus', fieldOption: 'folioStatus' }));
      // newItem.push(item.sourceCurrency ? item.sourceCurrency : '');
      // newItem.push(item.amount ? item.amount : '');
      // newItem.push(item.remainingAmount ? item.remainingAmount : '');
      // newItem.push(item.fileName ? item.fileName : '');
      // newItem.push(item.fileDate ? item.fileDate : '');
      // newItem.push(
      //   getLabelOptions({ item, t, fieldName: 'paymentTransactionType', fieldOption: 'paymentTransactionType' })
      // );
      // newItem.push(item.company ? item.company : '');
      // newItem.push(item.accountId ? item.accountId : '');
      // newItem.push(item.firstName ? item.firstName : '');
      // newItem.push(item.lastName ? item.lastName : '');
      // newItem.push(getLabelOptions({ item, t, fieldName: 'accountType' }));
      // newItem.push(getLabelOptions({ item, t, fieldName: 'paymentMethod' }));
      // newItem.push(item.paymentSource ? item.paymentSource : '');
      // newItem.push(item.currency ? item.currency : '');
      // newItem.push(item.invoiceUnitId ? item.invoiceUnitId : '');
      // newItem.push(item.userId ? item.userId : '');
      // newItem.push(item.paymentDescription ? item.paymentDescription : '');
      // newItem.push(item.exchangeRate ? item.exchangeRate : '');
      // newItem.push(formatMoneyValueToExport(item.currentDateExchangeRate));
      // newItem.push({
      //   content: formatMoneyValueToExport(item.unRealizedGain),
      //   styles: item.unRealizedGain < 0 ? redFontPdfFile : null,
      // });
      // newItem.push(formatMoneyValueToExport(item.paymentDateExchangeRate));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 3, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
