import React, { useState, useRef, useEffect } from 'react';
import PropsType from 'prop-types';
import { map, isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RouteName from '../../App/RouteNames';
import { modifyUserGroups, getUserGroups } from '../actions';
import { GenericInput, TitleFrom, Breadcrumb, DataTable } from '../../../components/common';
import ModalSearchUsers from '../../../components/common/ModalSearchUsers';

const UserGroupsModify = ({ modifyUserGroups, getUserGroups, history }) => {
  const { t } = useTranslation('common');
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({ groupId: '', groupName: '', users: [] });
  const [modifyData, setModifyData] = useState(null);
  const [wasValidated, setValidate] = useState(false);
  const formRef = useRef();

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  useEffect(() => {
    getUserGroups(({ success, data }) => {
      if (success) {
        setModifyData(data);
      }
    });
  }, [getUserGroups]);

  const onSelect = (id, row) => {
    const newData = cloneDeep(data);
    newData.users[indexSelect].userId = id;
    newData.users[indexSelect].firstName = row && row.contact ? row.contact[0].firstName : '';
    newData.users[indexSelect].lastName = row && row.contact ? row.contact[0].lastName : '';
    newData.users[indexSelect].email = row && row.contact ? row.contact[0].email : '';
    setData(newData);
    setIsOpen(false);
  };

  const onRemoveUser = index => {
    const newData = cloneDeep(data);
    newData.users.splice(index, 1);
    setData(newData);
  };

  const onCancel = () => {
    setData({ groupId: '', groupName: '', users: [] });
  };

  const headerTable = [
    {
      name: 'userId',
      label: 'label.userId',
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="userId"
          fa="fa fa-external-link"
          onClick={() => {
            setIsOpen(true);
            setItemSelect(item);
            setIndexSelect(idx);
          }}
          required
        />
      ),
    },
    {
      name: 'firstName',
      label: 'label.firstName',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.firstName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="firstName"
          readOnly
        />
      ),
    },
    {
      name: 'lastName',
      label: 'label.lastName',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.lastName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="lastName"
          readOnly
        />
      ),
    },
    {
      name: 'email',
      label: 'label.email',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.email}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="email"
          readOnly
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveUser({ index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const onHandleAddUser = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    newData.users.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.users.push({
      index: lastIndex + 1,
      userId: '',
    });
    setData(newData);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    let lastIndex = 0;
    const newData = cloneDeep(data);
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    modifyData.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.users = newData.users.map(val => {
      const { userId, index } = val;
      return { userId, index };
    });
    modifyUserGroups(
      { groups: [{ ...newData, index: lastIndex + 1 }], id: modifyData && modifyData[0] ? modifyData[0].id : '' },
      ({ success }) => {
        if (success) history.push(RouteName.userManagementUserGroups.path);
      }
    );
  };

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            <NavLink className="button x-small" to={RouteName.userManagementUserGroups.path}>
              {t('label.back')}
            </NavLink>
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                {
                  name: t('userManagementPage:sidebar.userGroups'),
                  url: RouteName.userManagementUserGroups.path,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <form
        ref={formRef}
        noValidate
        className={`col-md-12 p-2 card card-statistics needs-validation ${wasValidated ? 'was-validated' : ''}`}
        // onSubmit={onHandleSubmit}
        onSubmit={onSubmit}
      >
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.addNewUserGroups')} />
          </div>
        </div>
        <div className="col-md-12 p-2 row">
          <GenericInput
            value={data.groupId}
            label="label.groupId"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            name="groupId"
            required
          />
          <GenericInput
            value={data.groupName}
            label="label.groupName"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            name="groupName"
            required
          />
        </div>
        <br />
        <div className="col-md-12">
          <DataTable columns={headerTable} data={data.users || []} />
        </div>
        <div className="form-group col-md-12 buttons-attibute">
          <button type="button" className="button button-border black x-small" onClick={onHandleAddUser}>
            +
            {t('label.addUserToGroup')}
          </button>
          <button type="submit" className="button button-border x-small float-right">
            {t('label.create')}
          </button>
          <button type="button" onClick={onCancel} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </form>
      <ModalSearchUsers
        isOpen={isOpen}
        onSelect={onSelect}
        onCancel={() => setIsOpen(false)}
        selectedParentId={itemSelect ? itemSelect.userId : null}
      />
    </div>
  );
};

UserGroupsModify.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

UserGroupsModify.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, { modifyUserGroups, getUserGroups })(UserGroupsModify);
