import React from 'react';
import PropTypes from 'prop-types';
import { remove, cloneDeep, isEmpty, map } from 'lodash';
import { withTranslation } from 'react-i18next';

import CustomerNoForm from './CustomerNoForm';
import { getAddressesForm, validate } from '../../utils/utils';
import FieldsForm, { additionalAddressInformation, collapsibleAddress } from '../../containers/CustomerPage/constants';
import { GenericInput, FormCollapse } from '../common';
import CheckFieldRole from './CheckFieldRole';

class FormAddNewAddress extends React.PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      accountAddress: { roles: [], country: 'USA', state: '', city: '' },
      wasValidated: false,
      activeTab: {},
    };
  }

  onHandleChange = ({ value = '', name = '' }) => {
    const { accountAddress } = this.state;
    accountAddress[name] = value;
    this.setState({ ...accountAddress });
  };

  onChangeRole = name => {
    const { accountAddress } = this.state;
    const newData = cloneDeep(accountAddress);
    if (newData.roles.indexOf(name) > -1) {
      remove(newData.roles, item => item === name);
    } else {
      newData.roles.push(name);
    }
    return this.setState({ accountAddress: newData });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { accountAddress } = this.state;
    const { onAddNewAddress, t } = this.props;
    // if (!accountAddress.id) return toast.error(t('message.mandatory'));
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });

    return onAddNewAddress(accountAddress);
  };

  onToggleAddressTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const { checkRoleGroup, isDisabled, onToggleModal, t, landmarkLabel, extralineLabel } = this.props;
    const {
      accountAddress,
      accountAddress: { country, state, city },
      wasValidated,
      activeTab,
    } = this.state;

    let dataFields = getAddressesForm({
      country,
      state,
      city,
      addresses: FieldsForm.dataFieldNewAddressDetails,
      landmarkLabel,
      extralineLabel,
    });

    const additionalFields = dataFields.filter(val => ['extraLine', 'landmark', 'code'].includes(val.fieldName));
    dataFields = dataFields.filter(val => !['extraLine', 'landmark', 'code'].includes(val.fieldName));

    return (
      <form
        onSubmit={this.onHandleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        ref={this.formRef}
        noValidate
      >
        <CustomerNoForm
          colItem="col-md-4"
          dataField={dataFields}
          accountDetail={accountAddress}
          onHandleChange={this.onHandleChange}
          wasValidated={wasValidated}
          // checkRoleGroup={checkRoleGroup}
          // onChangeCheckBox={this.onChangeRole}
          isDisabled={isDisabled}
          t={t}
          isLeftAddPhoneBtn
          colDivPhone="col-md-8"
          isRequiredPhoneNumber
        >
          <div className="col-md-12">
            {collapsibleAddress.map(cl => {
              return (
                <FormCollapse
                  key={cl.title}
                  isActive={activeTab && cl.state === activeTab.name && activeTab.isActive}
                  title={cl.title}
                  state={cl.state}
                  onToggleTab={this.onToggleAddressTab}
                  isSubCollapse
                  isActiveSubForm={activeTab && cl.state === activeTab.name && activeTab.isActive}
                >
                  <div className="col-md-12 row">
                    {[
                      ...additionalAddressInformation,
                      ...additionalFields.map(i => {
                        const { fieldName, labelFilter, ...rest } = i;
                        return { ...rest, name: fieldName, label: labelFilter };
                      }),
                    ].map(val => (
                      <GenericInput
                        {...val}
                        value={accountAddress[val.name] || null}
                        onChange={({ name, value }) => this.onHandleChange({ name, value })}
                      />
                    ))}
                  </div>
                </FormCollapse>
              );
            })}
            <div className="form-group col-md-12 check-group mt-3">
              {!isEmpty(checkRoleGroup) && <span>{t('common:label.roles')}</span>}
              {checkRoleGroup &&
                map(checkRoleGroup, (ck, idx) => (
                  <CheckFieldRole
                    key={ck.label}
                    label={t && ck.label ? t(ck.label) : ck.label || ''}
                    className={ck.className}
                    name={ck.value}
                    disabled={isDisabled}
                    value={accountAddress.roles && accountAddress.roles.indexOf(ck.value) > -1}
                    onChange={({ name }) => this.onChangeRole(name)}
                  />
                ))}
            </div>
          </div>
          <div className="form-group col-md-12 action-buttons modal-new-item">
            <button type="button" className="button x-small float-right" onClick={this.onHandleSubmit}>
              {t('label.addNewAddress')}
            </button>
            <button type="button" className="button x-small float-right" onClick={onToggleModal}>
              {t('label.cancel')}
            </button>
          </div>
        </CustomerNoForm>
      </form>
    );
  }
}

FormAddNewAddress.propTypes = {
  checkRoleGroup: PropTypes.arrayOf(PropTypes.object),
  onAddNewAddress: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

FormAddNewAddress.defaultProps = {
  checkRoleGroup: [],
  isDisabled: false,
};

export default withTranslation('common')(FormAddNewAddress);
