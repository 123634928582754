import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { TitleFrom } from '../../../components/common';
import { modifyDiscountTrigger, getDiscountTriggerByName } from '../actions';
import { makeGetDiscountTriggerList } from '../selectors';
import DiscountTriggerForm from './DiscountTriggerForm';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { checkPermissionModifyDiscountOffer } from '../CheckPermission';

const DiscountTrigger = ({ modifyDiscountTrigger, getDiscountTriggerByName, permissionsPricing, ...rest }) => {
  const {
    match: {
      params: { name },
    },
    location,
  } = rest;
  const [editData, setEditData] = useState({});
  const { t } = useTranslation('common');

  let modeModifyDiscountOffer = 0;

  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermissionPricing = permissionsPricing.pricingModulePermissions;
    modeModifyDiscountOffer = checkPermissionModifyDiscountOffer({ listPermission: listPermissionPricing });
  }

  const onGetDiscountTriggerByName = useCallback(() => {
    getDiscountTriggerByName(name, ({ success, data }) => {
      if (success) setEditData(data || {});
    });
  }, [getDiscountTriggerByName, name]);

  const onSubmit = payload => {
    return modifyDiscountTrigger(payload, ({ success }) => {
      if (success) {
        onGetDiscountTriggerByName();
      }
    });
  };

  useEffect(() => {
    onGetDiscountTriggerByName();
  }, [onGetDiscountTriggerByName]);

  if (!modeModifyDiscountOffer) return null;

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={{
          pathname: RouteNames.discountTrigger.path,
          state: location && location.state ? location.state : null,
        }}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.productFamilyPricing.path },
          { name: t('pricingPage:sidebar.discountTrigger') },
        ]}
      />
      <br />
      <div className="card card-statistics mb-30">
        <TitleFrom wrapperClass="pl-2" title={t('label.editDiscountTrigger')} />
        <DiscountTriggerForm
          onSubmit={onSubmit}
          data={editData}
          onCancel={onGetDiscountTriggerByName}
          modeModifyDiscountOffer={modeModifyDiscountOffer}
        />
      </div>
    </div>
  );
};
DiscountTrigger.propTypes = {
  modifyDiscountTrigger: PropTypes.func.isRequired,
  getDiscountTriggerByName: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
};

DiscountTrigger.defaultProps = {
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  discountTriggerList: makeGetDiscountTriggerList() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});
export default connect(mapStateToProps, { modifyDiscountTrigger, getDiscountTriggerByName })(DiscountTrigger);
