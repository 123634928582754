import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import moment from 'moment';
import TitleFrom from '../TitleFrom';
import { DataTable, GenericInput, SwitchExport, ExcelExport, ButtonExport } from '..';
import { getPaymentActivity } from '../../../containers/App/actions';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelPaymentSuspenseReferenceActivity';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfPaymentSuspenseReferenceActivity';

let fileName = '';
class ModalViewPaymentActivity extends PureComponent {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
      data: [],
      isActivePdf: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { id, itemSelected } = this.props;
    if (
      (id && id !== prevProps.id) ||
      (itemSelected && itemSelected?.paymentRefId && itemSelected !== prevProps.itemSelected)
    ) {
      this.doGetPaymentActivity();
    }
  }

  doGetPaymentActivity = () => {
    const { getPaymentActivity, id, itemSelected, isSuspense, isPaymentActivity } = this.props;
    this.setState({ isSearching: true });
    let payload = {};
    if (isPaymentActivity) {
      payload = {
        paymentId: isSuspense ? id : null,
        paymentReferenceId: isSuspense ? null : itemSelected?.paymentRefId || null,
      };
    } else {
      payload = {
        paymentSuspenseId: isSuspense ? id : null,
        paymentReferenceId: isSuspense ? null : itemSelected?.paymentRefId || null,
      };
    }
    getPaymentActivity(payload, ({ success, data }) => {
      this.setState({ isSearching: false, data: success ? data : [] });
    });
  };

  onExport = () => {
    const { isActivePdf, data } = this.state;
    if (isActivePdf) {
      const { t } = this.props;
      convertJson2Pdf({
        data: data && data.length ? data : [],
        t,
        title: fileName.toLocaleUpperCase(),
        fileName: `${fileName.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
      });
    } else {
      this.buttonRef.current.click();
    }
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  render() {
    const {
      id,
      t,
      isOpen,
      onToggle,
      itemSelected,
      title,
      isSuspense,
      isRemainingAmount,
      isPaymentActivity,
      isAdditionalFields,
      isSupportDownload,
    } = this.props;
    const { data, isActivePdf, isSearching } = this.state;
    let newColumns = [
      {
        name: 'organization',
        label: 'label.organization',
      },
      {
        name: 'accountId',
        label: 'label.accountId',
      },
      {
        name: 'accountCurrency',
        label: 'label.accountCurrency',
      },
      {
        name: 'invoiceId',
        label: 'label.invoiceId',
      },
      {
        name: 'invoiceDue',
        label: 'label.invoiceDue',
      },
      {
        name: 'allocationDate',
        label: 'label.allocationDate',
      },
      {
        name: 'allocatedAmount',
        label: 'label.allocatedAmountInAccountCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'sourceCurrencyAmount',
        label: isPaymentActivity
          ? 'label.allocatedAmountInPaymentCurrency'
          : 'label.allocatedAmountInPaymentSuspenseCurrency',
        style: { textAlign: 'center' },
        isSupportDangerouslySetInnerHTML: true,
      },
      // {
      //   name: 'sourceCurrencyAmount',
      //   label: 'label.sourceAmount',
      // },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
      },
      {
        name: 'transactionType',
        label: 'label.paymentType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:paymentTransactionType')().find(val => val.value === item.transactionType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ];
    if (isAdditionalFields && !isSuspense) {
      newColumns = [
        ...newColumns,
        {
          name: 'paymentId',
          label: 'label.paymentId',
        },
        {
          name: 'paymentSuspenseId',
          label: 'label.paymentSuspenseId',
        },
        {
          name: 'status',
          label: 'label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'folioStatus',
          label: 'label.folioStatus',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ];
    }
    let newPaymentFields = [];
    if (isRemainingAmount) {
      newPaymentFields = [
        {
          name: 'amount',
          label: 'label.paymentAmount',
          type: 'number',
        },
        {
          name: 'currency',
          label: 'label.paymentCurrency',
        },
        {
          name: 'paymentDate',
          label: 'label.paymentDate',
        },
        {
          name: 'remainingAmount',
          label: 'label.remainingAmount',
          type: 'number',
        },
      ];
    } else {
      newPaymentFields = [
        {
          name: 'amount',
          label: 'label.paymentSuspenseAmount',
          type: 'number',
        },
        {
          name: 'currency',
          label: 'label.paymentSuspenseCurrency',
        },
        {
          name: 'paymentDate',
          label: 'label.paymentSuspenseDate',
        },
        {
          name: 'remainingAmount',
          label: 'label.unallocatedAmount',
          type: 'number',
        },
      ];
    }

    fileName = `${id}`;
    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        className="modal-dialog modal-custom modal-70 bd-example-modal-lg modal-selector modal-lg"
      >
        <div className="col-md-12 p-3 mb-30">
          <div className="card card-statistics">
            <TitleFrom title={title || ''} />
            <div className="card-body">
              {isSupportDownload && !isSuspense && (
                <div className="d-flex float-right">
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                      name={id}
                    />
                  </div>
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={fileName.toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: data && data.length ? data : [],
                        t,
                        title: fileName.toLocaleUpperCase(),
                      })}
                      fileName={`${fileName.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
                        'YYYY_MM_DD'
                      )}`}
                    />
                  </>
                </div>
              )}
              <div className="col-md-12 row">
                {isSuspense &&
                  newPaymentFields.map(val => {
                    if (val.name === 'remainingAmount') {
                      let value = null;
                      if (itemSelected) {
                        value =
                          itemSelected[val.name] || itemSelected[val.name] === 0
                            ? itemSelected[val.name]
                            : itemSelected?.unallocatedAmount || null;
                      }
                      return (
                        <GenericInput value={value} onChange={() => {}} wrapperClass="col-md-3" readOnly {...val} />
                      );
                    }
                    return (
                      <GenericInput
                        value={
                          itemSelected || itemSelected[val.name] || itemSelected[val.name] === 0
                            ? itemSelected[val.name]
                            : ''
                        }
                        onChange={() => {}}
                        wrapperClass="col-md-3"
                        readOnly
                        {...val}
                      />
                    );
                  })}
              </div>
              <br />
              <div>
                <DataTable columns={newColumns} data={data} isLoading={isSearching} />
              </div>
            </div>
          </div>
          <br />
          <div className="form-group col-md-12 action-buttons pt-4">
            <button type="button" onClick={onToggle} className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

ModalViewPaymentActivity.propTypes = {
  getPaymentActivity: PropTypes.func.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
};

ModalViewPaymentActivity.defaultProps = {
  id: '',
  title: '',
};

const newModalViewPaymentActivity = connect(mapStateToProps, {
  getPaymentActivity,
})(ModalViewPaymentActivity);

export default withTranslation('common')(newModalViewPaymentActivity);
