import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import RoleForm from './RoleForm';
import { makeGetlistRoles, makePageRolesParams, errorMessageRoles } from '../selectors';
import { getRoleById, searchRoles, setParamsRoles, modifyRole, searchAllRoles } from '../actions';
import { checkPermissionModifyRoles, checkPermissionChangeRoleStatus } from '../CheckPermission';
import { makeGetPermissionsUserManagement } from '../../App/selectors';

class RoleAdd extends Component {
  state = {
    isSubmitting: false,
    roleDetail: {},
  };

  componentDidMount() {
    this._mounted = true;
    this.loadRoleDetails();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  loadRoleDetails = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getRoleById(
      {
        id,
      },
      response => {
        if (this._mounted && response.success) {
          this.setState({
            roleDetail: { ...response.data },
          });
        }
      }
    );
  };

  onSave = (data, cb = null) => {
    this.props.modifyRole({ rolesInput: data }, ({ success, data }) => {
      this.setState({ isSubmitting: false });
      if (cb) cb(success);
    });
  };

  render() {
    const { roleDetail } = this.state;
    const { permissionsUser, t, location } = this.props;
    let modeModifyRole = 0;
    let modeModifyRoleStatus = 0;
    if (permissionsUser && permissionsUser.userModulePermissions) {
      const listPermission = permissionsUser.userModulePermissions;
      modeModifyRole = checkPermissionModifyRoles({ listPermission });
      modeModifyRoleStatus = checkPermissionChangeRoleStatus({ listPermission });
    }

    if (modeModifyRole === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname:
                    location && location?.state?.backLink
                      ? location.state.backLink
                      : RouteNames.userManagementRole.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  {
                    name: t('navbar:operationsHub.subMain.userManagement'),
                    url: {
                      pathname: RouteNames.userManagementRole.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  {
                    name: t('userManagementPage:sidebar.roles'),
                    url: {
                      pathname: RouteNames.userManagementRole.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  { name: roleDetail && roleDetail.id ? roleDetail.id : t('label.edit') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editRole')}</h5>
              </div>
              <RoleForm
                item={roleDetail}
                modeModifyRole={modeModifyRole}
                modeModifyRoleStatus={modeModifyRoleStatus}
                onSubmit={this.onSave}
                isSubmitting={this.state.isSubmitting}
                onCancel={this.loadRoleDetails}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  listRoles: makeGetlistRoles() || [],
  params: makePageRolesParams() || {},
  errorMessage: errorMessageRoles() || '',
  permissionsUser: makeGetPermissionsUserManagement() || {},
});

const withConnect = connect(mapStateToProps, {
  getRoleById,
  searchRoles,
  setParamsRoles,
  modifyRole,
  searchAllRoles,
});

export default withTranslation('common')(compose(withConnect)(RoleAdd));

// export default RoleAdd;
