/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { searchUsersList } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import UserSearchForm from './UserSearchForm';

class ModalSearchUsers extends PureComponent {
  searchHanldeUser = (payload, cb) => {
    const { searchUsersList } = this.props;
    const newPayload = { ...payload };
    searchUsersList(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'userId',
          label: 'label.userId',
        },
        {
          name: 'firstName',
          label: 'label.firstName',
          render: (colName, item) => {
            return <span>{item && item.contact ? item.contact[0].firstName : ''}</span>;
          },
        },
        {
          name: 'lastName',
          label: 'label.lastName',
          style: { minWidth: '100px' },
          render: (colName, item) => {
            return <span>{item && item.contact ? item.contact[0].lastName : ''}</span>;
          },
        },
        {
          name: 'email',
          label: 'label.email',
          render: (colName, item) => {
            return <span>{item && item.contact ? item.contact[0].email : ''}</span>;
          },
        },
      ],
    };
    const { isOpen, onSelect, onCancel, onToggle, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectUser')}
        isOpen={isOpen}
        SearchForm={UserSearchForm}
        searchItems={this.searchHanldeUser}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        onToggle={onToggle}
        selectedParentId={selectedParentId}
        selectFieldName="userId"
      />
    );
  }
}

ModalSearchUsers.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  searchUsersList: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSearchUsers.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { searchUsersList })(ModalSearchUsers));
