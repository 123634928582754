// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['accountType', 'accountSubType', 'salesChannel', 'marketSegment']);
  let newData = dataModify.replace('{embrixData: ', '');
  newData = `${newData.substring(0, newData.length - 1)}`;
  const modifyCollectionProfiles = `modifyCollectionProfiles(input: ${newData})`;
  return gql`
  mutation {
    ${modifyCollectionProfiles} {
      id
    }
  }
`;
};
