import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index']);
  const modifyProductFamilyConfig = `modifyProductFamilyConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyProductFamilyConfig} {
      id
    }
  }
`;
};
