import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import RoleForm from './RoleForm';
import { createRole } from '../actions';
import { Breadcrumb } from '../../../components/common';

class RoleAdd extends Component {
  state = {
    isSubmitting: false,
    items: [],
    parentItemId: '',
    openModal: false,
    page: 1,
    size: 20,
    isSearching: false,
    newRole: {
      roleGroupRoles: [],
    },
  };

  componentDidMount() {
    const { location } = this.props;
    if (location.state) {
      const { filter, ...rest } = location.state;
      this.setState({ newRole: rest || {} });
    }
  }

  onCreateItem = data => {
    this.setState({ isSubmitting: true });
    const { id, ...rolesInput } = data;
    this.props.createRole({ rolesInput }, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const { t, location } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.userManagementRole.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  {
                    name: t('navbar:operationsHub.subMain.userManagement'),
                    url: {
                      pathname: RouteNames.userManagementRole.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  {
                    name: t('userManagementPage:sidebar.roles'),
                    url: {
                      pathname: RouteNames.userManagementRole.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addRole')}</h5>
              </div>
              <RoleForm
                item={this.state.newRole}
                onSubmit={this.onCreateItem}
                isSubmitting={this.state.isSubmitting}
                creatingMode
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default RoleAdd;
RoleAdd.propTypes = {
  createRole: PropTypes.func,
};

const mapStateToProps = store => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  createRole: (e, cb) => dispatch(createRole(e, cb)),
});

const withConnect = connect(null, mapDispatchToProps);

export default withTranslation('common')(compose(withConnect)(RoleAdd));
