import React, { useState, useEffect, useCallback } from 'react';
import PropsType from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSharingGroup, createSharingGroup, modifySharingGroup } from '../../../containers/CustomerPage/actions';
import { GenericInput, FormWithTableItem, DataTable, ModalWithItem } from '../../common';
import ModalSearchPriceOffer from '../../common/ModalSearchPriceOffer';
import ModalMultipleSelectDisscountOffer from '../../common/ModalMultipleSelectDisscountOffer';
import { getLastIndex } from '../../../utils/utils';

const listFieldsCreate = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'subscriptionId',
    label: 'label.subscriptionId',
    readOnly: true,
  },
  {
    name: 'priceOfferName',
    label: 'label.ownerOffer',
    fa: 'fa fa-external-link',
  },
  {
    name: 'status',
    label: 'label.status',
    type: 'select',
    tOptions: 'selections:genericStatus',
  },
];

const ChargeShare = ({
  getSharingGroup,
  createSharingGroup,
  modifySharingGroup,
  id,
  customerInfo,
  currencyLabel,
  getSubscription,
}) => {
  const { t } = useTranslation('common');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openModalName, setOpenModalName] = useState(null);
  const [data, setData] = useState([]);
  const [dataSubmit, setDataSubmit] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [subscriptionsIdSelect, setSubscriptionsIdSelect] = useState(null);

  const doGetSharingGroup = useCallback(
    subscriptionId => {
      setLoading(true);
      getSharingGroup({ accountId: id, sharingType: 'CHARGE', subscriptionId }, ({ data }) => {
        setLoading(false);
        setData(data || []);
      });
    },
    [getSharingGroup, id]
  );

  useEffect(() => {
    // doGetSharingGroup();
    getSubscription(id, ({ success, data }) => {
      const listOptions = success && data ? data.map(val => ({ label: val.id, value: val.id })) : [];
      setSubscriptionsList(listOptions);
      doGetSharingGroup(listOptions ? listOptions[0].value : null);
      setSubscriptionsIdSelect(listOptions ? listOptions[0].value : null);
    });
  }, [doGetSharingGroup, getSubscription, id]);

  const onSelectItem = (id, row) => {
    const newData = cloneDeep(dataSubmit);
    if (openModalName === 'priceOffer' && id) {
      newData.priceOfferName = row.name || null;
      newData.priceOfferId = row.id || null;
    }
    if (openModalName === 'discountOffer' && id) {
      const newList = row.map(val => ({ discountOfferId: val.id, discountOfferName: val.name, isNew: true }));
      if (!newData.discountOffers) newData.discountOffers = [];
      newData.discountOffers = [...newData.discountOffers, ...newList];
    }
    setDataSubmit(newData);
    setOpenModalName(null);
  };

  const onChangeData = ({ name, value }) => {
    try {
      const newData = cloneDeep(dataSubmit);
      newData[name] = value;
      setDataSubmit(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleModal = () => {
    setIsOpenModal(!isOpenModal);
    setIsEdit(false);
    setDataSubmit({ subscriptionId: subscriptionsIdSelect, status: 'ACTIVE' });
  };

  const onSelectEditItem = ({ item }) => {
    setIsOpenModal(!isOpenModal);
    setDataSubmit(item);
    setIsEdit(true);
  };

  const onChangeSubscriptionId = ({ value }) => {
    if (value === subscriptionsIdSelect) {
      setSubscriptionsIdSelect(value);
      doGetSharingGroup(value);
    }
  };

  const onRemoveDiscountOffer = ({ index }) => {
    try {
      const newData = cloneDeep(dataSubmit);
      if (newData.discountOffers[index].isNew) {
        newData.discountOffers.splice(index, 1);
      } else {
        newData.discountOffers[index] = { index: newData.discountOffers[index].index };
      }
      setDataSubmit(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
    },
    {
      name: 'sharingType',
      label: 'label.sharingType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:sharingType')().find(val => val.value === item.sharingType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'subscriptionId',
      label: 'label.subscriptionId',
    },
    {
      name: 'priceOfferId',
      label: 'label.shareImpactOffer',
    },
    {
      name: 'status',
      label: 'label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:genericStatus')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'view',
      label: 'label.view',
      render: (colName, item) => (
        <button type="button" className="btn btn-outline-success btn-sm" onClick={() => onSelectEditItem({ item })}>
          {t('label.view')}
        </button>
      ),
    },
  ];

  const tableColumnsDiscountOffers = [
    {
      name: 'discountOfferId',
      label: 'label.discountOfferId',
    },
    {
      name: 'discountOfferName',
      label: 'label.discountOfferName',
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button type="button" className="no-border" onClick={() => onRemoveDiscountOffer({ index })}>
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const onToggleModalName = name => {
    setOpenModalName(name || null);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    const newData = cloneDeep(dataSubmit);
    const { discountOffers, ...rest } = newData;
    if (!isEdit) {
      const newDiscountOffers = discountOffers
        ? discountOffers.map(val => ({
            discountOfferId: val.discountOfferId,
            discountOfferName: val.discountOfferName,
          }))
        : null;
      return createSharingGroup(
        { ...rest, accountId: id || null, sharingType: 'CHARGE', discountOffers: newDiscountOffers },
        resp => {
          if (resp.success) {
            // setData([...data, resp.data]);
            setIsOpenModal(null);
            setDataSubmit({});
            doGetSharingGroup();
          }
        }
      );
    }

    const existData = discountOffers ? discountOffers.filter(val => !val.isNew) : [];
    const newDiscountOffers = discountOffers
      ? discountOffers
          .filter(val => val.isNew)
          .map((item, index) => {
            const { isNew, ...rest } = item;
            return { ...rest, index: getLastIndex({ data: existData }) + index };
          })
      : [];

    const discountOffersPayload = [...existData, ...newDiscountOffers];

    return modifySharingGroup(
      { ...rest, discountOffers: discountOffersPayload && discountOffersPayload.length ? discountOffersPayload : null },
      resp => {
        if (resp.success) {
          const newData = cloneDeep(data);
          const indexData = newData.findIndex(i => i.id === resp.data.id);
          newData[indexData] = resp.data;
          setData(newData);
          setIsOpenModal(null);
          setDataSubmit({});
        }
      }
    );
  };

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('customerPage:sidebarHandleAccountPage.chargeShare')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
      >
        <div className="col-md-12 ml-2 mb-3 row">
          <button type="button" className="button button-border black x-small height-btn" onClick={onToggleModal}>
            +
            {t('label.addNew')}
          </button>
          <GenericInput
            name="subscriptionId"
            label="label.subscriptionId"
            type="select"
            options={subscriptionsList}
            value={subscriptionsIdSelect || null}
            onChange={onChangeSubscriptionId}
            wrapperClass="col-md-3 m-auto"
          />
        </div>
        <DataTable isLoading={isLoading} columns={tableColumns} data={data || []} />
      </FormWithTableItem>
      <ModalWithItem
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        modalTitle={isEdit ? t('label.groupDetails') : t('label.createNewGroup')}
        isOpen={isOpenModal}
        onToggle={onToggleModal}
      >
        <div className="col-md-12 row mb-3">
          {listFieldsCreate.map(val => (
            <GenericInput
              {...val}
              value={dataSubmit[val.name] || null}
              onClick={val?.fa ? () => onToggleModalName('priceOffer') : () => {}}
              onChange={onChangeData}
            />
          ))}
        </div>
        <div className="col-md-12 ml-2 mb-3">
          <button
            type="button"
            className="button button-border black x-small"
            onClick={() => onToggleModalName('discountOffer')}
          >
            +
            {t('label.addSharedDiscounts')}
          </button>
        </div>
        <div className="col-md-12">
          <DataTable
            isLoading={isLoading}
            columns={tableColumnsDiscountOffers}
            data={dataSubmit.discountOffers || []}
            isSupportRemoveIndex
          />
        </div>
        <div className="col-md-12 mt-3">
          <button type="button" className="button button-border x-small float-right" onClick={onSubmit}>
            {t('label.submit')}
          </button>
          <button type="button" onClick={onToggleModal} className="button button-border black x-small mr-2 float-right">
            {t('label.cancel')}
          </button>
        </div>
      </ModalWithItem>
      <ModalSearchPriceOffer
        isOpen={openModalName === 'priceOffer'}
        selectedParentId={dataSubmit.priceOfferId}
        onSelect={onSelectItem}
        onCancel={onToggleModalName}
        defaultSearchParams={{
          pricingType: 'SHARING',
        }}
      />

      <ModalMultipleSelectDisscountOffer
        isOpen={openModalName === 'discountOffer'}
        onSelect={onSelectItem}
        onCancel={onToggleModalName}
        defaultSearchParams={{
          discountingType: 'SHARING',
        }}
        fieldDisableCheck={
          dataSubmit?.discountOffers
            ? { id: dataSubmit?.discountOffers.filter(i => size(i) > 1).map(val => val.discountOfferId) }
            : null
        }
      />
    </div>
  );
};

ChargeShare.propTypes = {
  getSharingGroup: PropsType.func.isRequired,
  createSharingGroup: PropsType.func.isRequired,
  modifySharingGroup: PropsType.func.isRequired,
  id: PropsType.string,
  customerInfo: PropsType.objectOf(PropsType.any),
  currencyLabel: PropsType.string,
};

ChargeShare.defaultProps = {
  id: '',
  customerInfo: {},
  currencyLabel: '',
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, { getSharingGroup, createSharingGroup, modifySharingGroup })(ChargeShare);
