export default {
  groupNamePermissions: {
    createRoles: 'CREATE_ROLES',
    modifyRoles: 'MODIFY_ROLES',
    searchRoles: 'SEARCH_ROLES',
    deleteRoles: 'DELETE_ROLES',
    getRoles: 'GET_ROLES',
    createRoleGroups: 'CREATE_ROLE_GROUPS',
    modifyRoleGroups: 'MODIFY_ROLE_GROUPS',
    searchRoleGroups: 'SEARCH_ROLE_GROUPS',
    deleteRoleGroups: 'DELETE_ROLE_GROUPS',
    getRoleGroups: 'GET_ROLES_GROUP',
    createUser: 'CREATE_USER',
    modifyUser: 'MODIFY_USER',
    searchUser: 'SEARCH_USER',
    getUser: 'GET_USER',
    changeUserStatus: 'CHANGE_USER_STATUS',
    changeRoleStatus: 'CHANGE_ROLE_STATUS',
    sunsetRoleGroupStatus: 'CHANGE_ROLE_GROUP_STATUS',
    createConfigApprovals: 'CREATE_CONFIG_APPROVALS',
    modifyConfigApprovals: 'MODIFY_CONFIG_APPROVALS',
    getConfigApprovals: 'GET_CONFIG_APPROVALS',
    createObjectApprovals: 'CREATE_OBJECT_APPROVALS',
    modifyObjectApprovals: 'MODIFY_OBJECT_APPROVALS',
    getObjectApprovals: 'GET_OBJECT_APPROVALS',
    createConfigUserGroups: 'CREATE_CONFIG_USER_GROUPS',
    modifyConfigUserGroups: 'MODIFY_CONFIG_USER_GROUPS',
    getConfigUserGroups: 'GET_CONFIG_USER_GROUPS',
  },
};
