import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ColData = ({ name, item, render, index }) => {
  const { t } = useTranslation(['common', 'selections']);
  const colText = render ? render(name, item, index, t) : item[name];
  return <td>{colText}</td>;
};
ColData.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  render: PropTypes.func,
};
ColData.defaultProps = {
  name: '',
  item: {},
  render: null,
};

const RowHeader = ({ columns, sorted, onSort, isLoading }) => {
  const { t } = useTranslation(['common', 'selections']);
  const hHtml = columns.map((col, idx) => {
    const onSortCol = !isLoading && col.sortable && onSort ? onSort : console.log;
    return (
      <th
        key={`th-${col.name}-${idx}`}
        className={classNames(col.className, {
          sortable: col.sortable,
          sorting: col.name === sorted.sortCol,
          'sorting-desc': col.name === sorted.sortCol && sorted.sortDir === 'desc',
          'sorting-asc': col.name === sorted.sortCol && sorted.sortDir !== 'desc',
        })}
        onClick={e => onSortCol(col.name, sorted.sortDir === 'desc' ? 'asc' : 'desc')}
        style={col.style ? col.style : {}}
        disabled={isLoading}
      >
        {col.label ? t(col.label) : ''}
      </th>
    );
  });
  return <tr className="text-dark">{hHtml}</tr>;
};

RowHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  onSort: PropTypes.func,
  isLoading: PropTypes.bool,
};
RowHeader.defaultProps = {
  columns: [],
  sorted: {},
  isLoading: false,
};

const RowContent = ({ columns, item, className, onClickRow, getRowClass, isExpanding, expandRender, index }) => {
  const rowClassNames = `${className} ${getRowClass ? getRowClass(item) : ''}`;
  const rowCols = columns.map((col, idx) => (
    <ColData key={`${col.key}-${idx}`} index={index} name={col.name} item={item} render={col.render} />
  ));
  if (expandRender) {
    return [
      <tr key={`row-${index}`} className={rowClassNames} onClick={e => onClickRow(e, item)}>
        {rowCols}
      </tr>,
      <tr key={`row-expand-${index}`} style={{ display: !isExpanding ? 'none' : '' }}>
        <td colSpan={columns.length} style={{ border: ' solid 1px #e8e8e8' }}>
          {expandRender(item, index)}
        </td>
      </tr>,
    ];
  }
  return (
    <tr className={rowClassNames} onClick={e => onClickRow(e, item)}>
      {rowCols}
    </tr>
  );
};

RowContent.propTypes = {
  columns: PropTypes.array.isRequired,
  item: PropTypes.object,
  className: PropTypes.string,
  onClickRow: PropTypes.func,
  getRowClass: PropTypes.func,
  isExpanding: PropTypes.bool,
  expandRender: PropTypes.any,
  index: PropTypes.number,
};
RowContent.defaultProps = {
  columns: [],
  index: 0,
  item: {},
  className: '',
  onClickRow: null,
  getRowClass: null,
  isExpanding: false,
  expandRender: null,
};

export { RowHeader, RowContent, ColData };
