import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { GenericInput } from '../common';
import { getAddressesForm } from '../../utils/utils';

const AddressForm = ({ data, onChange, dataFiledAddress }) => {
  const { country, state, city } = data;
  return (
    <div className="d-flex flex-wrap mt-2 ml-2 mb-2">
      {getAddressesForm({ country, state, city, addresses: dataFiledAddress }).map(item => {
        if (item && !isEmpty(item.optionSelect)) {
          return (
            <GenericInput
              key={item.labelFilter}
              label={item.labelFilter || ''}
              type="select"
              isClearable
              value={!isEmpty(data) ? data[item.fieldSelect] : null}
              wrapperClass="col-md-4"
              options={item.optionSelect}
              onChange={onChange}
              name={item.fieldSelect}
            />
          );
        }
        return (
          <GenericInput
            key={item.labelFilter}
            value={!isEmpty(data) ? data[item.fieldName] : ''}
            label={item.labelFilter || ''}
            type="text"
            onChange={onChange}
            name={item.fieldName}
            wrapperClass="col-md-4"
          />
        );
      })}
    </div>
  );
};

AddressForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  dataFiledAddress: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

AddressForm.defaultProps = {
  data: {},
  dataFiledAddress: {},
  onChange: () => {},
};

export default AddressForm;
