import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'exchangeRate', 'threshold', 'splitType']);
  let newDataModify = `${dataModify.replace('{dataSubmit: ', '')}`;
  newDataModify=`${newDataModify.substring(0, newDataModify.length - 1)}`
  const modifyAccountExchangeRates = `modifyAccountExchangeRates(input: ${newDataModify})`;
  return gql`
  mutation {
    ${modifyAccountExchangeRates} {
      index
    }
  }
`;
};
