import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'crAccountType',
    'drAccountType',
    'objectType',
    'transactionType',
    'status',
    'index',
  ]);
  const modifyConfigAccountingRules = `modifyConfigAccountingRules(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigAccountingRules} {
      id
    }
  }
`;
};
