import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getARItemConfig, modifyARItemConfig } from '../actions';
// import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import Footer from '../../../components/Footer';
import ModalItem from '../ModalItem';
import { getIndexData } from '../../../utils/utils';

class ItemMap extends React.PureComponent {
  state = {
    data: [],
    isLoading: false,
    itemConfig: {},
    itemSelected: {},
  };

  componentDidMount() {
    this.doGetARItemConfig();
  }

  doGetARItemConfig = () => {
    const { getARItemConfig } = this.props;
    this.setState({ isLoading: true });
    getARItemConfig('', ({ success, data }) => {
      this.setState({ isLoading: false });
      if (success) {
        this.setState({ data: data && data.arTypeList ? data.arTypeList : [], itemConfig: data });
      }
    });
  };

  onChange = ({ index, name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[getIndexData({ index, data: newData })][name] = value;
    this.setState({ data: newData });
  };

  addNewItem = () => {
    try {
      const { data } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      const newDataItem = {
        index: lastIndex + 1,
        company: null,
        arType: null,
        itemId: null,
      };
      newData = [newDataItem, ...newData];
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[getIndexData({ index, data: newData })] = {
      index: newData[getIndexData({ index, data: newData })].index,
    };
    this.setState({ data: newData });
  };

  onHandleSave = () => {
    const { data, itemConfig } = this.state;
    const { modifyARItemConfig } = this.props;
    const arTypeList = cloneDeep(data);
    const { type } = itemConfig;
    modifyARItemConfig({ type, arTypeList }, () => this.doGetARItemConfig());
  };

  onSelectItem = (selectedParentId, row) => {
    const { itemSelected, data } = this.state;
    const newData = cloneDeep(data);
    if (row) {
      newData[getIndexData({ index: itemSelected.index, data: newData })].itemId = row.id;
    }
    this.setState({ data: newData, isOpenModalItem: false, itemSelected: {} });
  };

  render() {
    const { t } = this.props;
    const { data, isLoading, isOpenModalItem } = this.state;
    const tableColumns = [
      {
        name: 'company',
        label: 'label.company',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.company}
              name="company"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
            />
          );
        },
      },
      {
        name: 'arType',
        label: 'label.ARItemType',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.arType}
              type="select"
              tOptions="selections:arTypeConfig"
              name="arType"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'itemId',
        label: 'label.itemId',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.itemId}
              name="itemId"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              fa="fa fa-external-link"
              onClick={() => this.setState({ isOpenModalItem: true, itemSelected: item })}
            />
          );
        },
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: item.index })}>
              <i className="fa fa-trash" />
            </button>
          );
        },
      },
    ];

    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteNames.arOperationsAdjustments.path}
          titleBtn={t('label.back')}
          titleRight={t('label.AROpsItemMapping')}
        />
        <div className="col-md-12 mb-30">
          <FormWithTableItem title={t('label.AROpsItemMapping')}>
            <div className="form-group col-md-12 buttons-attibute">
              <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                +
                {t('label.addNewItemMapping')}
              </button>
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHandleSave()}
              >
                {t('label.saveConfig')}
              </button>
              <button
                type="button"
                onClick={this.doGetARItemConfig}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <DataTable
              columns={tableColumns}
              data={data && data.length ? data.filter(val => size(val) > 1) : []}
              isLoading={isLoading}
            />
          </FormWithTableItem>
        </div>
        <Footer className="footer-bottom" />
        <ModalItem
          isOpen={isOpenModalItem}
          onSelect={this.onSelectItem}
          onCancel={() => {
            this.setState({ isOpenModalItem: false });
          }}
          defaultSearchParams={{ type: 'RECEIVABLE' }}
        />
      </div>
    );
  }
}

ItemMap.propTypes = {
  getARItemConfig: PropTypes.func,
  modifyARItemConfig: PropTypes.func,
};

ItemMap.defaultProps = {
  getARItemConfig: () => {},
  modifyARItemConfig: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, { getARItemConfig, modifyARItemConfig })(withRouter(ItemMap))
);
