import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.sourceCurrency'),
  t('label.targetCurrency'),
  t('label.type'),
  t('label.exchangeRate'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.sourceCurrency ? item.sourceCurrency : '');
      newItem.push(item.targetCurrency ? item.targetCurrency : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'type', fieldOption: 'exchangeRateType' }));
      newItem.push(item.exchangeRate ? item.exchangeRate : '');
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, ...rest });
};

export default convertJson2Pdf;
