import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['quarter'])}`
    : '';
  const employeeCostingYearlyPNLReport = `employeeCostingYearlyPNLReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${employeeCostingYearlyPNLReport} {
        employeeId
        employeeName
        jobTitle
        clientId
        clientName
        clientJobTitle
        monthOne
        monthTwo
        monthThree
        monthFour
        monthFive
        monthSix
        monthSeven
        monthEight
        monthNine
        monthTen
        monthEleven
        monthTwelve
        quarterOne
        quarterTwo
        quarterThree
        quarterFour
        status
        monthOneGrossSal
        monthTwoGrossSal
        monthThreeGrossSal
        monthFourGrossSal
        monthFiveGrossSal
        monthSixGrossSal
        monthSevenGrossSal
        monthEightGrossSal
        monthNineGrossSal
        monthTenGrossSal
        monthElevenGrossSal
        monthTwelveGrossSal
        totalQuarterOneGrossSal
        totalQuarterTwoGrossSal
        totalQuarterThreeGrossSal
        totalQuarterFourGrossSal
        monthOneActualCost
        monthTwoActualCost
        monthThreeActualacost
        monthFourActualCost
        monthFiveActualCost
        monthSixActualacost
        monthSevenActualCost
        monthEightActualCost
        monthNineActualacost
        monthTenActualCost
        monthElevenActualCost
        monthTwelveActualacost
        totalQuarterOneActualCost
        totalQuarterTwoActualCost
        totalQuarterThreeActualCost
        totalQuarterFourActualCost
        monthOneRevenueUsd
        monthTwoRevenueUsd
        monthThreeRevenueUsd
        monthFourRevenueUsd
        monthFiveRevenueUsd
        monthSixRevenueUsd
        monthSevenRevenueUsd
        monthEightRevenueUsd
        monthNineRevenueUsd
        monthTenRevenueUsd
        monthElevenRevenueUsd
        monthTwelveRevenueUsd
        totalQuarterOneRevenueUsd
        totalQuarterTwoRevenueUsd
        totalQuarterThreeRevenueUsd
        totalQuarterFourRevenueUsd
        monthOneRevenueMxn
        monthTwoRevenueMxn
        monthThreeRevenueMxn
        monthFourRevenueMxn
        monthFiveRevenueMxn
        monthSixRevenueMxn
        monthSevenRevenueMxn
        monthEightRevenueMxn
        monthNineRevenueMxn
        monthTenRevenueMxn
        monthElevenRevenueMxn
        monthTwelveRevenueMxn
        totalQuarterOneRevenueMxn
        totalQuarterTwoRevenueMxn
        totalQuarterThreeRevenueMxn
        totalQuarterFourRevenueMxn
        monthOneActualIncomeMxn
        monthTwoActualIncomeMxn
        monthThreeActualIncomeMxn
        monthFourActualIncomeMxn
        monthFiveActualIncomeMxn
        monthSixActualIncomeMxn
        monthSevenActualIncomeMxn
        monthEightActualIncomeMxn
        monthNineActualIncomeMxn
        monthTenActualIncomeMxn
        monthElevenActualIncomeMxn
        monthTwelveActualIncomeMxn
        totalQuarterOneActualIncome
        totalQuarterTwoActualIncome
        totalQuarterThreeActualIncome
        totalQuarterFourActualIncome
        monthOneSalaryToBilling
        monthTwoSalaryToBilling
        monthThreeSalaryToBilling
        monthFourSalaryToBilling
        monthFiveSalaryToBilling
        monthSixSalaryToBilling
        monthSevenSalaryToBilling
        monthEightSalaryToBilling
        monthNineSalaryToBilling
        monthTenSalaryToBilling
        monthElevenSalaryToBilling
        monthTwelveSalaryToBilling
        averageQuarterOneSalaryToBilling
        averageQuarterTwoSalaryToBilling
        averageQuarterThreeSalaryToBilling
        averageQuarterFourSalaryToBilling
      }
    }
  `;
};
