import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { push } from 'connected-react-router';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import PricingItem from './PricingItem/index';
import PricingItemEdit from './PricingItem/ItemEdit';
import PricingItemAdd from './PricingItem/ItemAdd';
import PricingPriceOffer from './PricingPriceOffer';
import PricingPriceOfferAdd from './PricingPriceOffer/PriceOfferAdd';
import PricingPriceOfferEdit from './PricingPriceOffer/PriceOfferEdit';
import DiscountOffer from './DiscountOffer';
import DiscountOfferAdd from './DiscountOffer/DiscountOfferAdd';
import DiscountOfferEdit from './DiscountOffer/DiscountOfferEdit';
import ProductFamily from './ProductFamily';
import ItemServices from './ItemServices';
import DiscountTrigger from './DiscountTrigger';
import DiscountTriggerAdd from './DiscountTrigger/DiscountTriggerAdd';
import DiscountTriggerEdit from './DiscountTrigger/DiscountTriggerEdit';
// import PricingPriceOfferEdit from './PricingPriceOffer/PriceOfferEdit';
import Sidebar from './Sidebar';
import baseConfigurationsReducer from '../BaseConfigurations/reducer';
import baseConfigurationsSaga from '../BaseConfigurations/saga';
import TopupOffers from '../BaseConfigurations/TopupOffers';
import orderReducer from '../OrderPage/reducer';
import orderSaga from '../OrderPage/saga';
import ProvisioningAttributes from '../OrderPage/ProvisioningAttributesMap';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class PricingManagement extends PureComponent {
  render() {
    const { permissionsPricing } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsPricing, listKeys: permissionList.pricingManagement }))
      return null;
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.pricingItem.path} component={PricingItem} />
              <Route exact path={RouteNames.pricingItemAdd.path} component={PricingItemAdd} />
              <Route exact path={RouteNames.pricingItemEdit.path} component={PricingItemEdit} />
              {/* <Route exact path={``} component={PricingItem} /> */}
              <Route exact path={RouteNames.pricingPriceOffer.path} component={PricingPriceOffer} />
              <Route exact path={RouteNames.pricingPriceOfferAdd.path} component={PricingPriceOfferAdd} />
              <Route exact path={RouteNames.pricingPriceOfferEdit.path} component={PricingPriceOfferEdit} />
              <Route exact path={RouteNames.pricingDiscountOffer.path} component={DiscountOffer} />
              <Route exact path={RouteNames.pricingDiscountAdd.path} component={DiscountOfferAdd} />
              <Route exact path={RouteNames.pricingDiscountEdit.path} component={DiscountOfferEdit} />
              <Route exact path={RouteNames.productFamilyPricing.path} component={ProductFamily} />
              <Route exact path={RouteNames.itemServices.path} component={ItemServices} />
              <Route exact path={RouteNames.discountTrigger.path} component={DiscountTrigger} />
              <Route exact path={RouteNames.discountTriggerAdd.path} component={DiscountTriggerAdd} />
              <Route exact path={RouteNames.discountTriggerEdit.path} component={DiscountTriggerEdit} />
              <Route exact path={RouteNames.topupOffers.path} component={TopupOffers} />
              <Route exact path={RouteNames.provisioningAttributes.path} component={ProvisioningAttributes} />

              {/* <Route exact path={RouteNames.pricingDiscountAdd.path} component={PricingPriceOfferAdd} />
              <Route exact path={RouteNames.pricingDiscountEdit.path} component={PricingPriceOfferEdit} /> */}
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsPricing = store.get('auth').toJS().permissionsApp.pricingHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsPricing: permissionsPricing.length
      ? permissionsPricing.filter(a => a.moduleName === 'PRICE_MANAGEMENT')[0]
      : {},
  };
};

const mapDispatchToProps = dispatch => ({
  push,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'pricingReducer', reducer });
const withSaga = injectSaga({ key: 'pricingSaga', saga });

const withBaseConfigurationsReducer = injectReducer({
  key: 'baseConfigurationsReducer',
  reducer: baseConfigurationsReducer,
});
const withBaseConfigurationsSaga = injectSaga({ key: 'baseConfigurationsSaga', saga: baseConfigurationsSaga });

const withOrderReducer = injectReducer({
  key: 'orderReducer',
  reducer: orderReducer,
});
const withOrderSaga = injectSaga({ key: 'orderSaga', saga: orderSaga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withBaseConfigurationsReducer,
  withBaseConfigurationsSaga,
  withOrderReducer,
  withOrderSaga
)(PricingManagement);
