import gql from 'graphql-tag';

export default company => {
  let searchParams = '';
  if (company) searchParams = `company: "${company}"`;
  return gql`
    query {
      getUniqueProductFamily(input: { ${searchParams} }) {
        productFamily
      }
    }
  `;
};
