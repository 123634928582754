import { groupNamePermissions } from './constant';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateReasonCodeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createReasonCodeConfig });
};

const checkPermissionModifyReasonCodeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyReasonCodeConfig });
};

const checkPermissionGetReasonCodeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getReasonCodeConfig });
};

const checkPermissionProcessAdjustment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processAdjustment });
};

const checkPermissionProcessTaxAdjustment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processTaxAdjustment });
};

const checkPermissionSearchAdjustment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchAdjustment });
};

const checkPermissionGetAdjustmentDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getAdjustmentDetails });
};

const checkPermissionProcessDispute = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processDispute });
};

const checkPermissionProcessTaxDispute = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processTaxDispute });
};

const checkPermissionSearchDispute = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchDispute });
};

const checkPermissionGetDisputeDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getDisputeDetails });
};

const checkPermissionProcessSettlement = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processSettlement });
};

const checkPermissionProcessTaxSettlement = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processTaxSettlement });
};

const checkPermissionSearchSettlement = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchSettlement });
};

const checkPermissionGetSettlementDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getSettlementDetails });
};

const checkPermissionProcessWriteoff = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processWriteoff });
};

const checkPermissionProcessTaxWriteoff = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processTaxWriteoff });
};

const checkPermissionSearchWriteoff = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchWriteoff });
};

const checkPermissionGetWriteoffDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getWriteoffDetails });
};

const checkPermissionProcessWriteoffReversal = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processWriteoffReversal });
};

const checkPermissionProcessTaxWriteoffReversal = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processTaxWriteoffReversal });
};

const checkPermissionSearchWriteoffReversal = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchWriteoffReversal });
};

const checkPermissionGetWriteoffReversalDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getWriteoffReversalDetails });
};

const checkPermissionSearchArOpsUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchArOpsUnit });
};

const checkPermissionGetArOpsTaxOptions = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getArOpsTaxOptions });
};

const checkPermissionCreateConfigArOpsThresholds = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createConfigArOpsThresholds });
};

const checkPermissionModifyConfigArOpsThresholds = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyConfigArOpsThresholds });
};

const checkPermissionGetConfigArOpsThresholds = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getConfigArOpsThresholds });
};

const checkPermissionCreateConfigGroupReasonCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createConfigGroupReasonCode });
};

const checkPermissionModifyConfigGroupReasonCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyConfigGroupReasonCode });
};

const checkPermissionGetConfigGroupReasonCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getConfigGroupReasonCode });
};

const checkPermissionBulkAdjustments = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.BULK_ADJUSTMENT });
};

const checkPermissionCreditNoteApproval = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREDIT_NOTE_APPROVAL });
};

export {
  checkPermissionCreateReasonCodeConfig,
  checkPermissionModifyReasonCodeConfig,
  checkPermissionGetReasonCodeConfig,
  checkPermissionProcessAdjustment,
  checkPermissionProcessTaxAdjustment,
  checkPermissionSearchAdjustment,
  checkPermissionGetAdjustmentDetails,
  checkPermissionProcessDispute,
  checkPermissionProcessTaxDispute,
  checkPermissionSearchDispute,
  checkPermissionGetDisputeDetails,
  checkPermissionProcessSettlement,
  checkPermissionProcessTaxSettlement,
  checkPermissionSearchSettlement,
  checkPermissionGetSettlementDetails,
  checkPermissionProcessWriteoff,
  checkPermissionProcessTaxWriteoff,
  checkPermissionSearchWriteoff,
  checkPermissionGetWriteoffDetails,
  checkPermissionProcessWriteoffReversal,
  checkPermissionProcessTaxWriteoffReversal,
  checkPermissionSearchWriteoffReversal,
  checkPermissionGetWriteoffReversalDetails,
  checkPermissionSearchArOpsUnit,
  checkPermissionGetArOpsTaxOptions,
  checkPermissionCreateConfigArOpsThresholds,
  checkPermissionModifyConfigArOpsThresholds,
  checkPermissionGetConfigArOpsThresholds,
  checkPermissionCreateConfigGroupReasonCode,
  checkPermissionModifyConfigGroupReasonCode,
  checkPermissionGetConfigGroupReasonCode,
  checkPermissionBulkAdjustments,
  checkPermissionCreditNoteApproval,
};
