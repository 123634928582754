import React from 'react';
import PropTypes from 'prop-types';
import { map, isArray } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { DataTable, CardHeaderForm, ModalAccept } from '../../../components/common';
import { selectPackageId, selectBundleId, selectPriceOfferId, getBundleByBundleId } from '../../App/actions';
import {
  ServiceTableUpgradeDowngrade,
  PriceOffersSelectModal,
  PriceOffersSearchForm,
  BundelSearchForm,
} from '../../../components/Orders';
import { getMessageModal } from '../utilities';
import constants from '../constants';

const tableColumns = [
  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reason')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'common:label.initialTerm',
    // isRenderT: true,
    // render: (colName, item, t) => {
    //   const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTerm) : '';
    //   return <span>{slt ? slt.label : ''}</span>;
    // },
  },
  {
    name: 'initialTermUnit',
    label: 'common:label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'common:label.renewalTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'common:label.renewalTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialTerm',
    label: 'common:label.trialTerm',
  },
  {
    name: 'trialTermUnit',
    label: 'common:label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

class OrderModifyInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOverrideLines: {},
      isBundle: false,
      isPackage: false,
      isPriceOffers: false,
      selectTableColumns: [],
      itemRemove: '',
    };
    this.selectService = [];
    this.ids = [];
  }

  componentDidMount = () => {
    const { orderDetail } = this.props;
    this.getTypePurchase(orderDetail.services);
  };

  componentWillReceiveProps(nextProps) {
    const { orderDetail } = this.props;
    if (JSON.stringify(nextProps.orderDetail) !== JSON.stringify(orderDetail)) {
      this.getTypePurchase(nextProps.orderDetail.services);
    }
  }

  handleClickServicesFormBtn = index => {
    const { isOverrideLines } = this.state;
    isOverrideLines[index] = !isOverrideLines[index];

    this.setState({
      isOverrideLines: { ...isOverrideLines },
    });
  };

  getTypePurchase = data => {
    const bSelected = [];
    const poSelected = [];
    if (!data.length) {
      this.setState({ mode: constants.ModalMode.none });
    } else if (data[0].packageId) {
      this.setState({
        modalTitle: 'Choose Package :',
        buttonText: 'Add Package',
        selectTableColumns: constants.tableSelectPackageColumns,
        mode: constants.ModalMode.purchasePackage,
        selectedItemIds: [data[0].packageId],
        positionTrash: true,
        isBundle: false,
        isPackage: true,
        isPriceOffers: false,
      });
    } else if (data[0].bundleId) {
      data.forEach(el => {
        bSelected.push(el.bundleId);
      });
      this.setState({
        modalTitle: 'Select Bundles :',
        buttonText: 'Add Bundle',
        mode: constants.ModalMode.purchaseBundle,
        selectTableColumns: constants.tableSelectBundleColumns,
        selectedItemIds: bSelected,
        positionTrash: true,
        isBundle: true,
        isPackage: false,
        isPriceOffers: false,
      });
    } else {
      data.forEach(el => {
        el.lines.forEach(elm => {
          poSelected.push(elm.priceOfferId);
        });
      });
      this.setState({
        modalTitle: 'Select Price Offers :',
        buttonText: 'Add Price Offer',
        mode: constants.ModalMode.purchaseAlaCarte,
        selectTableColumns: constants.tableSelectPriceOfferColumns,
        selectedItemIds: poSelected,
        // positionTrash: false,
        positionTrash: true,
        isBundle: false,
        isPackage: false,
        isPriceOffers: true,
      });
    }
  };

  searchItemsOfSelectModal = (postData, cb) => {
    const { mode } = this.state;
    if (mode === constants.ModalMode.purchasePackage) {
      this.props.selectPackageId(postData, cb);
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      this.props.selectBundleId(postData, cb);
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      this.props.selectPriceOfferId(postData, cb);
    }
  };

  openSelectModal = () => {
    this.setState({
      isOpenPriceOfferModal: true,
    });
  };

  onSelectModal = (newService, groupExistService) => {
    const { onSelectService } = this.props;
    const { mode } = this.state;
    // if (JSON.stringify(selectedItemIds) !== JSON.stringify(this.ids)) {
    //   onSelectService({
    //     services: newService,
    //     groupExistService,
    //     ids: this.ids,
    //     mode,
    //   });
    // }
    onSelectService({
      services: newService,
      groupExistService,
      ids: this.ids,
      mode,
    });

    this.setState({
      isOpenPriceOfferModal: false,
    });
  };

  onCancelModal = () => {
    this.setState({
      isOpenPriceOfferModal: false,
    });
  };

  onClickRow = (ids, list) => {
    const { mode } = this.state;
    if (mode === constants.ModalMode.purchasePackage) {
      if (ids.length > 0) {
        const rows = list.filter(item => item.id === ids[0]);
        this.selectService = rows.map(item => {
          return item.components;
        });
        this.ids = ids;
        return;
      }
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      if (ids.length > 0) {
        const tempServices = ids.map(id => {
          const rows = list.filter(item => item.id === id);

          return rows && rows.length > 0 ? rows[0] : {};
        });
        this.selectService = tempServices;
        this.ids = ids;
        return;
      }
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      if (ids.length > 0) {
        const tempServices = ids.map(id => {
          const rows = list.filter(item => item.id === id);
          return rows && rows.length > 0 ? rows[0] : {};
        });
        this.selectService = tempServices;
        this.ids = ids;
      }
    }
  };

  findLastIndex = () => {
    const { orderDetail } = this.props;
    let lastIndex = 0;
    if (isArray(orderDetail.services)) {
      for (let i = 0; i < orderDetail.services.length; i++) {
        if (lastIndex < orderDetail.services[i].index) {
          lastIndex = orderDetail.services[i].index;
        }
      }
    }
    return lastIndex;
  };

  onSelectRemoveItem = itemRemove => {
    this.setState({ itemRemove });
    this.props.onToggle();
  };

  render() {
    const {
      orderDetail,
      subscriptionMessage,
      id,
      addLine,
      onChangeFormLine,
      groupServices,
      onChangeProvisioningId,
      isOpen,
      onToggle,
      onRemoveItem,
      getBundleByBundleId,
      onRemoveLine,
      modeDeletePriceOffer,
      t,
      // isReadOnly,
      // onChangeModifyService,
      isBundle,
      onHandleInitNewLines,
      isMultiSubscriptionEnabled,
    } = this.props;
    const {
      isOverrideLines,
      mode,
      // buttonText,
      modalTitle,
      isOpenPriceOfferModal,
      selectedItemIds,
      selectTableColumns,
      positionTrash,
      isPackage,
      isPriceOffers,
      itemRemove,
    } = this.state;

    // const isRenderTrashBinServiceTable = (modeDeleteBundle === 2 && isBundle) || (modeDeletePackage === 2 && isPackage);
    const isRenderTrashBinServiceTable = true;
    const isRenderTrashBinLineTable = modeDeletePriceOffer === 2 && isPriceOffers;
    const columns = [...tableColumns];
    const subscription = [
      {
        id: orderDetail.id,
        accountId: orderDetail.accountId,
        status: orderDetail.status,
        renewalTerm: orderDetail.renewalTerm,
        renewalTermUnit: orderDetail.renewalTermUnit,
        reason: orderDetail.reason,
        initialTerm: orderDetail.initialTerm,
        initialTermUnit: orderDetail.initialTermUnit,
        trialTerm: orderDetail.trialTerm,
        trialTermUnit: orderDetail.trialTermUnit,
        effectiveDate: orderDetail.effectiveDate,
      },
    ];
    const tableConfig = {
      columns,
      data: subscription,
      getRowClass: () => {
        return '';
      },
    };

    return (
      <div className="col-md-12 mb-30">
        <PriceOffersSelectModal
          defaultFilter={{ status: 'SELLABLE' }}
          modalTitle={modalTitle}
          allService={orderDetail.services}
          isOpen={isOpenPriceOfferModal}
          isMutiSelect
          SearchForm={mode === constants.ModalMode.purchaseBundle ? PriceOffersSearchForm : BundelSearchForm}
          searchItems={this.searchItemsOfSelectModal}
          tableColumns={selectTableColumns}
          onSelect={this.onSelectModal}
          onCancel={this.onCancelModal}
          selectedItemIds={selectedItemIds}
          getBundleByBundleId={getBundleByBundleId}
          isBundle={isBundle}
          isPackage={isPackage}
          isPriceOffers={isPriceOffers}
          lastIndex={this.findLastIndex()}
          onClickRow={this.onClickRow}
        />
        <div className="card card-statistics">
          <CardHeaderForm title={t('label.subscription')} label={t('label.accountNumber')} value={id} />
          <DataTable {...tableConfig} noDataMessage={subscriptionMessage} onClickRow={this.onClickRow} />
          <div className="row">
            <h5 className="card-title title-form" style={{ fontWeight: 'bold' }}>
              {t('label.serviceUnits')}
            </h5>
          </div>
          {map(groupServices, (service, index) => {
            const newService = service.filter(val => !!val.newId);
            if (!newService || !newService.length) return '';
            return (
              <ServiceTableUpgradeDowngrade
                key={index}
                isFetchLine={mode === constants.ModalMode.purchasePackage}
                addLine={(serviceIndex, e) => addLine(index, serviceIndex, e)}
                isOverrideLines={isOverrideLines[index]}
                onChangeFormLine={(serviceIndex, e) => onChangeFormLine(index, serviceIndex, e)}
                onChangeProvisioningId={onChangeProvisioningId}
                services={newService}
                positionTrash={positionTrash}
                isRenderTrashBinServiceTable={isRenderTrashBinServiceTable}
                isRenderTrashBinLineTable={isRenderTrashBinLineTable}
                handleClickServicesFormBtn={() => this.handleClickServicesFormBtn(index)}
                onRemoveItem={this.onSelectRemoveItem}
                onRemoveLine={onRemoveLine}
                isReadOnly={false}
                isBundle={isBundle}
                onHandleInitNewLines={onHandleInitNewLines}
                isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
              />
            );
          })}
          <ModalAccept
            isOpen={isOpen}
            onToggle={onToggle}
            onAcceptModal={() => onRemoveItem({ itemRemove, isBundle, isPackage, isPriceOffers })}
            message={getMessageModal(mode, null, t)}
          />
        </div>
      </div>
    );
  }
}
OrderModifyInfo.propTypes = {
  orderDetail: PropTypes.object,
};
const mapStateToProps = createStructuredSelector({});
const newOrderModifyInfo = connect(mapStateToProps, {
  selectPackageId,
  selectBundleId,
  selectPriceOfferId,
  getBundleByBundleId,
})(OrderModifyInfo);

export default withTranslation('common')(newOrderModifyInfo);
