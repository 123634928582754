import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import JobList from './JobList';
import { makeGetPermissionsJobsManagement } from '../../App/selectors';
import {
  checkPermissionCreateJobSchedule,
  checkPermissionGetJobSchedule,
  checkPermissionModifyJobSchedule,
  checkPermissionProcessJobSchedule,
  checkPermissionModifyJob,
  checkPermissionProcessJob,
  checkPermissionReprocessJob,
  checkPermissionGetJobStats,
  checkPermissionModifyJobType,
  checkPermissionRefreshJobSchedule,
} from '../CheckPermission';
import {
  getJobScheduleByDate,
  createJobScheduleByDate,
  modifyJobSchedule,
  processJobSchedule,
  retryJobSchedule,
  updateStatusJobSchedule,
  getCcpDatetime,
  recreateJobScheduleByDate,
  resetSubsequentJobsFromErrorJob,
} from '../actions';
import { makeJobSchedule } from '../selectors';
import { getDateOnJob, setDateOnJob } from '../../../localStorages';

const canPause = jsd => jsd.status !== 'SUSPENDED' && jsd.status !== 'ERROR';
const canProcess = jsd => jsd.status === 'PENDING';
const checkReProcess = listJobs => {
  if (!listJobs || !listJobs.length) return false;
  return listJobs.filter(val => val.status === 'ERROR').length > 0;
};

class JobSchedule extends Component {
  state = {
    selectedDate: getDateOnJob() ? moment(getDateOnJob()).toDate() : moment().toDate(),
    ccpTime: moment(),
    isRefreshDay: false,
    isProcessByDate: false,
    isReprocess: false,
  };

  componentDidMount() {
    const { getCcpDatetime } = this.props;
    this.doGetJobScheduleByDate();
    getCcpDatetime(date => {
      this.setState({ ccpTime: moment(date) });
    });
  }

  doGetJobScheduleByDate = () => {
    const { getJobScheduleByDate } = this.props;
    getJobScheduleByDate({
      date: getDateOnJob() || moment().format('YYYY-MM-DD'),
    });
  };

  onChangeDate = date => {
    const { getJobScheduleByDate } = this.props;
    setDateOnJob(moment(date).format('YYYY-MM-DD'));
    this.setState({
      selectedDate: date,
      isProcessByDate: false,
    });
    getJobScheduleByDate({
      date: moment(date).format('YYYY-MM-DD'),
    });
  };

  onChangeOneDate = days => {
    const { getJobScheduleByDate } = this.props;
    const { selectedDate } = this.state;
    const newDate = moment(selectedDate).add(days, 'days');
    this.setState({
      selectedDate: newDate,
    });
    getJobScheduleByDate({
      date: newDate.format('YYYY-MM-DD'),
    });
  };

  onRefreshJobSchedule = () => {
    const { getJobScheduleByDate, getCcpDatetime } = this.props;
    const { isRefreshDay, selectedDate } = this.state;
    this.setState({ isRefreshDay: !isRefreshDay });
    getJobScheduleByDate({
      date: moment(selectedDate).format('YYYY-MM-DD'),
    });
    getCcpDatetime(date => {
      this.setState({ ccpTime: moment(date) });
    });
  };

  onCreateJobSchedule = () => {
    const { createJobScheduleByDate } = this.props;
    const { selectedDate } = this.state;
    createJobScheduleByDate({
      scheduleDate: moment(selectedDate).format('YYYY-MM-DD'),
      userId: 'abcd',
    });
  };

  onPauseEntire = () => {
    const { jobSchedule } = this.props;
    this.callUpdateStatus({ id: jobSchedule.id, status: 'SUSPENDED' });
  };

  onProcessEntire = () => {
    const { jobSchedule } = this.props;
    this.callProcess({ scheduleDate: jobSchedule.scheduleDate }, true);
  };

  onProcessJob = job => {
    const { jobSchedule } = this.props;
    this.callProcess({
      id: jobSchedule.id,
      scheduleDate: jobSchedule.scheduleDate,
      jobScheduleList: [{ index: job.index }],
    });
  };

  onRetryJob = job => {
    const { jobSchedule } = this.props;
    this.callRetry({
      id: jobSchedule.id,
      scheduleDate: jobSchedule.scheduleDate,
      jobScheduleList: [{ index: job.index, reason: job.reason || null }],
    });
  };

  callRetry = data => {
    const { retryJobSchedule } = this.props;
    retryJobSchedule(data);
  };

  callRetryAll = () => {
    const { retryJobSchedule, jobSchedule, getJobScheduleByDate } = this.props;
    retryJobSchedule({ scheduleDate: jobSchedule.scheduleDate }, () => {
      getJobScheduleByDate({
        date: jobSchedule.scheduleDate,
      });
    });
  };

  callProcess = (data, isAll) => {
    const { processJobSchedule } = this.props;
    processJobSchedule(data, ({ success }) => {
      if (success) this.setState({ isProcessByDate: isAll, isReprocess: false });
      if (!success) this.setState({ isReprocess: true, isProcessByDate: false });
    });
  };

  callUpdateStatus = data => {
    const { updateStatusJobSchedule } = this.props;
    updateStatusJobSchedule(data, ({ success }) => {
      this.setState({ isProcessByDate: true });
    });
  };

  onModifyJob = job => {
    const { jobSchedule, modifyJobSchedule } = this.props;
    modifyJobSchedule({
      id: jobSchedule.id,
      userId: jobSchedule.userId || 'abc',
      jobScheduleList: [
        {
          ...job,
        },
      ],
    });
  };

  onSortJobs = jobs => {
    const { jobSchedule, modifyJobSchedule } = this.props;
    const queuedJobs = [];
    jobs.forEach((job, idx) => {
      queuedJobs.push({
        index: job.index,
        nextJobId: jobs[idx + 1] ? jobs[idx + 1].jobId : '',
        predJobId: idx > 0 ? jobs[idx - 1].jobId : '',
      });
    });

    modifyJobSchedule({
      id: jobSchedule.id,
      userId: jobSchedule.userId || 'abc',
      jobScheduleList: queuedJobs,
    });
  };

  onRecreate = () => {
    const { jobSchedule, recreateJobScheduleByDate } = this.props;
    const { jobScheduleList, userId, scheduleDate, id } = jobSchedule;
    const newjobScheduleList = jobScheduleList.map(job => ({
      index: job.index,
      name: job.name,
      type: job.type,
      scheduleType: job.scheduleType,
    }));
    recreateJobScheduleByDate({ id, jobScheduleList: newjobScheduleList, userId, scheduleDate }, ({ success }) => {
      if (success) {
        this.doGetJobScheduleByDate();
      }
    });
  };

  onResetJobSchedule = () => {
    const { resetSubsequentJobsFromErrorJob, jobSchedule } = this.props;
    resetSubsequentJobsFromErrorJob(jobSchedule.id, ({ success }) => {
      if (success) this.doGetJobScheduleByDate();
    });
  };

  render() {
    const { jobSchedule, permissionsJob, t } = this.props;
    const jobScheduleList = jobSchedule ? jobSchedule.jobScheduleList : [];
    const { ccpTime, selectedDate, isProcessByDate, isReprocess } = this.state;
    const lockedProcess = ccpTime.diff(selectedDate, 'days') < 0;
    let modeCreateJobSchedule = 0;
    let modeGetJobSchedule = 0;
    let modeModifyJobSchedule = 0;
    let modeProcessJobSchedule = 0;
    let modeModifyJob = 0;
    let modeProcessJob = 0;
    let modeReprocessJob = 0;
    let modeGetJobStats = 0;
    let modeModifyJobType = 0;
    let modeRefreshJobSchedule = 0;
    if (permissionsJob && permissionsJob.jobsModulePermissions) {
      const listPermission = permissionsJob.jobsModulePermissions;
      modeCreateJobSchedule = checkPermissionCreateJobSchedule({ listPermission });
      modeGetJobSchedule = checkPermissionGetJobSchedule({ listPermission });
      modeModifyJobSchedule = checkPermissionModifyJobSchedule({ listPermission });
      modeProcessJobSchedule = checkPermissionProcessJobSchedule({ listPermission });
      modeModifyJob = checkPermissionModifyJob({ listPermission });
      modeProcessJob = checkPermissionProcessJob({ listPermission });
      modeReprocessJob = checkPermissionReprocessJob({ listPermission });
      modeGetJobStats = checkPermissionGetJobStats({ listPermission });
      modeModifyJobType = checkPermissionModifyJobType({ listPermission });
      modeRefreshJobSchedule = checkPermissionRefreshJobSchedule({ listPermission });
    }

    if (modeGetJobSchedule === 0) return '';

    const isExistJobError = checkReProcess(
      jobSchedule && jobSchedule.jobScheduleList ? jobSchedule.jobScheduleList : []
    );
    return (
      <div>
        <div className="page-title">
          <div className="col-md-12 mb-30">
            <div className="card cardt card-statistics h-100">
              <div className="card-body">
                <div className="d-block d-sm-flex justify-content-between col-md-12 p-0">
                  <div className="d-block">
                    <h5 className="pt-2 pb-2">
                      {t('label.jobSchedule')}
                      {jobSchedule && (
                        <span className="job-schedule-status">
                          <i
                            className={`fa fa-circle job-status job-status-${
                              jobSchedule.status && !isExistJobError ? jobSchedule.status.toLocaleLowerCase() : 'error'
                            }`}
                            title={`Status: ${jobSchedule.status}`}
                          />
                          &nbsp; &nbsp;
                          {modeRefreshJobSchedule !== 0 && (
                            <button
                              type="button"
                              title="Refresh jobs"
                              className="btn-no-css"
                              disabled={modeRefreshJobSchedule === 1}
                              onClick={() => this.onRefreshJobSchedule()}
                            >
                              <i className="fa fa-refresh" />
                            </button>
                          )}
                        </span>
                      )}
                    </h5>
                  </div>
                  <div className="row mr-3">
                    <div className="box clearfix sm-mb-10">
                      <div className="card-body datepicker-form">
                        <div className="mb-0">
                          <DatePicker
                            selected={selectedDate}
                            onChange={this.onChangeDate}
                            minDate={moment().subtract(3, 'months')}
                            maxDate={moment().add(1, 'months')}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    {!jobSchedule && modeCreateJobSchedule === 2 && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            <button type="button" className="btn btn-info" onClick={this.onCreateJobSchedule}>
                              {t('label.createJobSchedule')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {jobSchedule && jobSchedule.status === 'PENDING' && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            <button type="button" className="btn btn-info" onClick={this.onRecreate}>
                              {t('label.recreate')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {jobSchedule && modeModifyJobSchedule === 2 && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.onPauseEntire}
                              disabled={isProcessByDate || !canPause(jobSchedule) || modeModifyJobSchedule === 1}
                            >
                              {t('label.pause')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {jobSchedule && modeModifyJobSchedule === 2 && modeProcessJobSchedule !== 0 && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            {!isExistJobError ? (
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.onProcessEntire}
                                disabled={
                                  isProcessByDate ||
                                  !canProcess(jobSchedule) ||
                                  lockedProcess ||
                                  modeModifyJobSchedule === 1 ||
                                  modeProcessJobSchedule === 1
                                }
                              >
                                {t('label.process')}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.callRetryAll}
                                disabled={
                                  // isProcessByDate ||
                                  lockedProcess || modeModifyJobSchedule === 1 || modeProcessJobSchedule === 1
                                }
                              >
                                {t('label.reProcess')}
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn btn-success ml-2"
                              onClick={this.onResetJobSchedule}
                              disabled={
                                !jobSchedule?.resetAllowed ||
                                modeModifyJobSchedule === 1 ||
                                modeProcessJobSchedule === 1
                              }
                            >
                              {t('label.resetJobSchedule')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div className="row">
          {jobSchedule && jobSchedule.jobScheduleList && (
            <JobList
              jobs={jobScheduleList}
              onSortJobs={this.onSortJobs}
              onProcessJob={this.onProcessJob}
              onRetryJob={this.onRetryJob}
              onModifyJob={this.onModifyJob}
              lockedProcess={lockedProcess}
              modeModifyJob={modeModifyJob}
              modeProcessJob={modeProcessJob}
              modeReprocessJob={modeReprocessJob}
              modeGetJobStats={modeGetJobStats}
              modeModifyJobType={modeModifyJobType}
            />
          )}
          {!jobSchedule && (
            <div className="text-center text-bold">
              {t('label.noJobScheduleOn')}
              &nbsp;
              <strong>{moment(selectedDate).format('MM/DD/YYYY')}</strong>
            </div>
          )}
        </div>
      </div>
    );
  }
}

JobSchedule.propTypes = {
  getJobScheduleByDate: PropTypes.func.isRequired,
  createJobScheduleByDate: PropTypes.func.isRequired,
  modifyJobSchedule: PropTypes.func.isRequired,
  processJobSchedule: PropTypes.func.isRequired,
  retryJobSchedule: PropTypes.func.isRequired,
  updateStatusJobSchedule: PropTypes.func.isRequired,
  getCcpDatetime: PropTypes.func.isRequired,
  jobSchedule: PropTypes.objectOf(PropTypes.any),
  permissionsJob: PropTypes.objectOf(PropTypes.any),
};

JobSchedule.defaultProps = {
  permissionsJob: {},
  jobSchedule: {},
};

const mapStateToProps = createStructuredSelector({
  jobSchedule: makeJobSchedule() || {},
  permissionsJob: makeGetPermissionsJobsManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getJobScheduleByDate,
    createJobScheduleByDate,
    modifyJobSchedule,
    processJobSchedule,
    retryJobSchedule,
    updateStatusJobSchedule,
    getCcpDatetime,
    recreateJobScheduleByDate,
    resetSubsequentJobsFromErrorJob,
  })(JobSchedule)
);
