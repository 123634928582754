import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { DataTable, GenericInput, TitleFrom } from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import { getConfigUsagePlatforms, createConfigUsagePlatform, modifyConfigUsagePlatform } from '../../actions';
import RouteNames from '../../../App/RouteNames';

const Platforms = ({ getConfigUsagePlatforms, createConfigUsagePlatform, modifyConfigUsagePlatform, permissions }) => {
  const { modeCreateUsagePlatforms, modeModifyUsagePlatforms, modeGetUsagePlatforms } = permissions || {};

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({ configUsagePlatformList: [] });

  const { t } = useTranslation('common');

  const onChangeData = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData.configUsagePlatformList[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = () => {
    try {
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.configUsagePlatformList) newData.configUsagePlatformList = [];
      if (newData)
        newData.configUsagePlatformList.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        name: null,
        description: null,
        serviceType: null,
        interfaceType: null,
        status: null,
        isNew: true,
      };
      newData.configUsagePlatformList = [payload, ...newData.configUsagePlatformList];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const doGetConfigUsagePlatforms = useCallback(() => {
    if (modeGetUsagePlatforms) {
      setLoading(true);
      getConfigUsagePlatforms({}, ({ success, data }) => {
        setLoading(false);
        if (success && data) {
          setData(data);
        }
      });
    }
  }, [getConfigUsagePlatforms, modeGetUsagePlatforms]);

  const onSave = () => {
    const payload = cloneDeep(data);
    const configUsagePlatformList = payload.configUsagePlatformList
      ? payload.configUsagePlatformList.map(val => {
          const { isNew, ...rest } = val;
          return rest;
        })
      : null;
    if (payload && payload.id) {
      return modifyConfigUsagePlatform({ id: payload.id, configUsagePlatformList }, ({ success }) => {
        if (success) doGetConfigUsagePlatforms();
      });
    }
    return createConfigUsagePlatform({ configUsagePlatformList }, ({ success }) => {
      if (success) doGetConfigUsagePlatforms();
    });
  };

  useEffect(() => {
    return doGetConfigUsagePlatforms();
  }, [doGetConfigUsagePlatforms]);

  if (!modeGetUsagePlatforms) return '';

  const tablePlatforms = [
    {
      name: 'name',
      label: 'label.name',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="name"
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.serviceType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="serviceType"
          type="select"
          tOptions="selections:serviceType"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'interfaceType',
      label: 'label.interfaceType',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.interfaceType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="interfaceType"
          type="select"
          tOptions="selections:interfaceType"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'status',
      label: 'label.status',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.status}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="status"
          type="select"
          tOptions="selections:objectStatus"
          menuPortalTarget
          disabled={item.status && item.status === 'CLOSED' && !item.isNew}
        />
      ),
    },
  ];

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[{ name: t('usagePage:sidebar.usageMapping') }, { name: t('label.usagePlatforms') }]}
        isNoDefaultButton
      />
      <br />
      <div className="card card-statistics h-100 mr-3">
        <TitleFrom title={t('label.usagePlatforms')} />
        <br />
        <div>
          <div className="form-group col-md-12 buttons-attibute pt-4">
            {modeCreateUsagePlatforms === 2 && (
              <button type="button" onClick={addNewItem} className="button button-border black x-small">
                +
                {t('label.addNewPlatforms')}
              </button>
            )}
            {modeModifyUsagePlatforms === 2 && (
              <button
                type="button"
                disabled={!data?.configUsagePlatformList}
                onClick={onSave}
                className="button button-border x-small float-right mr-4"
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={doGetConfigUsagePlatforms}
              className="button mr-2 button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div className="group-collapsible">
            <DataTable
              isFixedHeaderTable
              columns={tablePlatforms}
              isLoading={isLoading}
              data={data?.configUsagePlatformList ? data?.configUsagePlatformList : []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Platforms.propTypes = {
  getConfigUsagePlatforms: PropTypes.func.isRequired,
  createConfigUsagePlatform: PropTypes.func.isRequired,
  modifyConfigUsagePlatform: PropTypes.func.isRequired,
};

Platforms.defaultProps = {};

export default connect(null, {
  getConfigUsagePlatforms,
  createConfigUsagePlatform,
  modifyConfigUsagePlatform,
})(Platforms);
