import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class ItemServicesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.orderStartDate',
      name: 'orderStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.orderEndtDate',
      name: 'orderEndtDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.deliveryStartDate',
      name: 'deliveryStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.deliveryEndtDate',
      name: 'deliveryEndtDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.cancellationStartDate',
      name: 'cancellationStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.cancellationEndtDate',
      name: 'cancellationEndtDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:reportStatusItemService',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.operativeStatus',
      name: 'operativeStatus',
      tOptions: 'selections:operativeStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.adminStatus',
      name: 'adminStatus',
      tOptions: 'selections:administrativeStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'label.accounttype',
      name: 'accounttype',
      tOptions: 'selections:accountType',
      type: 'select',
      // defaultValue: 'DIRECT_CUSTOMER',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.delivered',
      name: 'delivered',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.billableServiceName',
      name: 'billableServiceName',
    },
    {
      label: 'label.chargeType',
      name: 'chargeType',
      tOptions: 'selections:chargeTypeNoM',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.invoiceExhibits',
      name: 'invoiceExhibits',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.showChildServices',
      name: 'childServices',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.orderStatus',
      name: 'orderStatus',
      tOptions: 'selections:statusOrder',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'label.billed',
      name: 'billed',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.cutOffDOM',
      name: 'cutOffDOM',
      tOptions: 'selections:cutOffDOM',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-12';
}

ItemServicesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ItemServicesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ItemServicesSearchForm);
