import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['status', 'index', 'locationIndex', 'type']);
  const createDepartment = `createDepartment(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createDepartment} {
      id
    }
  }
`;
};
