import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['notesOffset'])}`
    : '';
  const searchChartOfAccount = `searchChartOfAccount(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchChartOfAccount} {
        id
        index
        name
        accountNumber
        type
        detailType
        description
        notesAccount
        notesName
      }
    }
  `;
};
