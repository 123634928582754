import React from 'react';

const tabelColumnOperatorDispute = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'batchNum',
    label: 'common:label.batchNumber',
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'billingDate',
    label: 'common:label.date',
    sortable: true,
  },
  {
    name: 'originOperator',
    label: 'common:label.originOperator',
    sortable: true,
  },
  {
    name: 'destinationOperator',
    label: 'common:label.destinationOperator',
    sortable: true,
  },
  // {
  //   name: 'filePath',
  //   label: 'common:label.filePath',
  // },
  {
    name: 'numberOfRecords',
    label: 'common:label.numberOfRecords',
  },
  {
    name: 'totalOriginMsg',
    label: 'common:label.totalOriginMessage',
  },
  {
    name: 'totalDestMsg',
    label: 'common:label.totalDestinationMessage',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:operatorFileStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const tabelColumnOperatorInvoice = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'billingDate',
    label: 'common:label.billingDate',
    sortable: true,
  },
  {
    name: 'originOperator',
    label: 'common:label.originOperator',
    sortable: true,
  },
  {
    name: 'destinationOperator',
    label: 'common:label.destinationOperator',
    sortable: true,
  },
  {
    name: 'cutoffDate',
    label: 'common:label.cutoffDate',
  },
  {
    name: 'numberOfRecords',
    label: 'common:label.numberOfRecords',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:operatorFileStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const tabelColumnOperatorRecordLog = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'batchNum',
    label: 'common:label.batchNumber',
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'processDate',
    label: 'common:label.processDate',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'common:label.startDate',
  },
  {
    name: 'endDate',
    label: 'common:label.endDate',
  },
  {
    name: 'startTime',
    label: 'common:label.startTime',
  },
  {
    name: 'endTime',
    label: 'common:label.endTime',
  },
  {
    name: 'operator',
    label: 'common:label.operator',
    sortable: true,
  },
  {
    name: 'numberOfRecords',
    label: 'common:label.numberOfRecords',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:usageRecStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const tabelColumnOperatorTenantDispute = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'billingDate',
    label: 'common:label.date',
    sortable: true,
  },
  {
    name: 'originOperator',
    label: 'common:label.originOperator',
  },
  {
    name: 'destinationOperator',
    label: 'common:label.destinationOperator',
    sortable: true,
  },
  {
    name: 'invoiceFileName',
    label: 'common:label.opInvoiceFile',
  },
  {
    name: 'numberOfRecords',
    label: 'common:label.numberOfRecords',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:operatorFileStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const tabelColumnOperatorTenantRecordLog = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'operator',
    label: 'common:label.operator',
    sortable: true,
  },
  {
    name: 'processDate',
    label: 'common:label.processDate',
    sortable: true,
  },
  {
    name: 'disputeId',
    label: 'common:label.disputeId',
  },
  {
    name: 'disputeFileName',
    label: 'common:label.disputeFileName',
  },
  {
    name: 'invoiceFileName',
    label: 'common:label.opInvoiceFile',
  },
  {
    name: 'invoiceId',
    label: 'common:label.invoiceId',
  },
  {
    name: 'numberOfRecords',
    label: 'common:label.numberOfRecords',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:operatorFileStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const sortOperatorDispute = {
  originOperator: {
    asc: 'originOperator_ASC',
    desc: 'originOperator_DESC',
  },
  destinationOperator: {
    asc: 'destinationOperator_ASC',
    desc: 'destinationOperator_DESC',
  },
  billingDate: {
    asc: 'billingDate_ASC',
    desc: 'billingDate_DESC',
  },
};

const sortOperatorInvoice = {
  originOperator: {
    asc: 'originOperator_ASC',
    desc: 'originOperator_DESC',
  },
  destinationOperator: {
    asc: 'destinationOperator_ASC',
    desc: 'destinationOperator_DESC',
  },
  billingDate: {
    asc: 'billingDate_ASC',
    desc: 'billingDate_DESC',
  },
};

const tabelColumnOperatorDisputeById = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'recordDate',
    label: 'common:label.recordDate',
  },
  {
    name: 'trafficType',
    label: 'common:label.trafficType',
  },
  {
    name: 'callType',
    label: 'common:label.callType',
  },
  {
    name: 'numSeconds',
    label: 'common:label.seconds',
  },
  {
    name: 'numCalls',
    label: 'common:label.numCalls',
  },
  {
    name: 'acctid',
    label: 'common:label.accountId',
  },
  {
    name: 'originSeries',
    label: 'common:label.origin',
  },
  {
    name: 'destinationSeries',
    label: 'common:label.destination',
  },
  {
    name: 'billedRate',
    label: 'common:label.billedRate',
    isNoNeedConvertNumber: true,
  },
  {
    name: 'tenantNumRegCalls',
    label: 'common:label.tenantCalls',
  },
  {
    name: 'tenantNumRegSeconds',
    label: 'common:label.tenantDuration',
  },
  {
    name: 'tenantRecogRate',
    label: 'common:label.tenantRate',
  },
  {
    name: 'diffCalls',
    label: 'common:label.differenceInCalls',
  },
];

const tabelColumnOperatorInvoiceById = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'recordDate',
    label: 'common:label.recordDate',
  },
  {
    name: 'trafficType',
    label: 'common:label.trafficType',
  },
  {
    name: 'callType',
    label: 'common:label.callType',
  },
  {
    name: 'numCalls',
    label: 'common:label.numCalls',
  },
  {
    name: 'numSeconds',
    label: 'common:label.seconds',
  },
  // {
  //   name: 'billedRate',
  //   label: 'common:label.billedRate',
  // },
  {
    name: 'billedRate',
    label: 'common:label.vat',
  },
  {
    name: 'acctid',
    label: 'common:label.accountId',
  },
  {
    name: 'originSeries',
    label: 'common:label.origin',
  },
  {
    name: 'destinationSeries',
    label: 'common:label.destination',
  },
];

const tabelColumnOperatorRecordLogById = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'startDate',
    label: 'common:label.startDateTime',
  },
  {
    name: 'trafficType',
    label: 'common:label.trafficType',
  },
  {
    name: 'callType',
    label: 'common:label.callType',
  },
  {
    name: 'duration',
    label: 'common:label.duration',
  },
  {
    name: 'anumber',
    label: 'common:label.source',
  },
  {
    name: 'bnumber',
    label: 'common:label.destination',
  },
  {
    name: 'messageStatus',
    label: 'common:label.messageStatus',
  },
];

const tabelColumnOperatorTenantDisputeById = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'regId',
    label: 'common:label.regId',
  },
  {
    name: 'nir',
    label: 'common:label.nir',
  },
  {
    name: 'nim',
    label: 'common:label.nim',
  },
  {
    name: 'recordDate',
    label: 'common:label.recordDate',
  },
  {
    name: 'trafficType',
    label: 'common:label.trafficType',
  },
  {
    name: 'callType',
    label: 'common:label.callType',
  },
  {
    name: 'numSeconds',
    label: 'common:label.seconds',
  },
  {
    name: 'numCalls',
    label: 'common:label.numCalls',
  },
  {
    name: 'acctid',
    label: 'common:label.accountId',
  },
  {
    name: 'originSeries',
    label: 'common:label.origin',
  },
  {
    name: 'destinationSeries',
    label: 'common:label.destination',
  },
  {
    name: 'billedRate',
    label: 'common:label.billedRate',
    isNoNeedConvertNumber: true,
  },
  {
    name: 'numRegCalls',
    label: 'common:label.numRegCalls',
  },
  {
    name: 'numRegSeconds',
    label: 'common:label.numRegSeconds',
  },
  {
    name: 'recogRate',
    label: 'common:label.recogRate',
  },
];

const tabelColumnOperatorTenantRecordLogById = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'startDate',
    label: 'common:label.startDate',
  },
  {
    name: 'startTime',
    label: 'common:label.startTime',
  },
  {
    name: 'trafficType',
    label: 'common:label.trafficType',
  },
  {
    name: 'nim',
    label: 'common:label.nim',
  },
  {
    name: 'callType',
    label: 'common:label.callType',
  },
  {
    name: 'anumber',
    label: 'common:label.anumber',
  },
  {
    name: 'bnumber',
    label: 'common:label.bnumber',
  },
  {
    name: 'duration',
    label: 'common:label.duration',
  },
];

const sortOperatorRecordLog = {
  operator: {
    asc: 'operator_ASC',
    desc: 'operator_DESC',
  },
  processDate: {
    asc: 'processDate_ASC',
    desc: 'processDate_DESC',
  },
};

const sortOperatorTenantDispute = {
  billingDate: {
    asc: 'billingDate_ASC',
    desc: 'billingDate_DESC',
  },
  destinationOperator: {
    asc: 'destinationOperator_ASC',
    desc: 'destinationOperator_DESC',
  },
};

const sortOperatorTenantRecordLog = {
  operator: {
    asc: 'operator_ASC',
    desc: 'operator_DESC',
  },
  processDate: {
    asc: 'processDate_ASC',
    desc: 'processDate_DESC',
  },
};

const sortRerating = {
  id: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  batchDate: {
    asc: 'batchDate_ASC',
    desc: 'batchDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const groupNamePermissions = {
  CREATE_RERATING_BATCH: 'CREATE_RERATING_BATCH',
  MODIFY_RERATING_BATCH: 'MODIFY_RERATING_BATCH',
  GET_RERATING_BATCH: 'GET_RERATING_BATCH',
  CREATE_USAGE_PLATFORM_CONFIG: 'CREATE_USAGE_PLATFORM_CONFIG',
  MODIFY_USAGE_PLATFORM_CONFIG: 'MODIFY_USAGE_PLATFORM_CONFIG',
  GET_USAGE_PLATFORM_CONFIG: 'GET_USAGE_PLATFORM_CONFIG',
  EDIT_SUSPENDED_FAILED_USAGE_RECORDS: 'EDIT_SUSPENDED_FAILED_USAGE_RECORDS',
  TEST_USAGE: 'TEST_USAGE',
  createZeroRateConfig: 'CREATE_ZERO_RATE_CONFIG',
  modifyZeroRateConfig: 'MODIFY_ZERO_RATE_CONFIG',
  getZeroRateConfig: 'GET_ZERO_RATE_CONFIG',
  addVoicePlan: 'ADD_VOICE_PLAN',
  modifyVoicePlan: 'MODIFY_VOICE_PLAN',
  searchVoicePlan: 'SEARCH_VOICE_PLAN',
  createRegionMapConfig: 'CREATE_REGION_MAP_CONFIG',
  modifyRegionMapConfig: 'MODIFY_REGION_MAP_CONFIG',
  getRegionMapConfig: 'GET_REGION_MAP_CONFIG',
  getTrunkRuleConfig: 'GET_TRUNK_RULES_CONFIG',
  createTrunkRuleConfig: 'CREATE_TRUNK_RULES_CONFIG',
  modifyTrunkRuleConfig: 'MODIFY_TRUNK_RULES_CONFIG',
  createOperatorMapConfig: 'CREATE_OPERATOR_MAP_CONFIG',
  modifyOperatorMapConfig: 'MODIFY_OPERATOR_MAP_CONFIG',
  getOperatorMapConfig: 'GET_OPERATOR_MAP_CONFIG',
};

const messageNotes = t => (
  <div className="note-content">
    <div>
      <b>1. &nbsp;&nbsp;</b>
      {t('message.usageMappingNoteStep1')}
    </div>
    <div>
      <b>2. &nbsp;&nbsp;</b>
      {t('message.usageMappingNoteStep2')}
    </div>
    <div>
      <b>3. &nbsp;&nbsp;</b>
      {t('message.usageMappingNoteStep3')}
    </div>
    <div>
      <b>4. &nbsp;&nbsp;</b>
      {t('message.usageMappingNoteStep4')}
    </div>
    <div>
      <b>5. &nbsp;&nbsp;</b>
      {t('message.usageMappingNoteStep5')}
    </div>
    <div>
      <b>6. &nbsp;&nbsp;</b>
      {t('message.usageMappingNoteStep6')}
    </div>
  </div>
);

const messageNotesStream = t => (
  <div className="note-content-stream">
    <div>
      <b>1. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep1')}
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {t('message.processingStreamsNoteStep1a')}
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {t('message.processingStreamsNoteStep1b')}
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {t('message.processingStreamsNoteStep1c')}
      </div>
    </div>
    <div>
      <b>2. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep2')}
    </div>
    <div>
      <b>3. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep3')}
    </div>
    <div>
      <b>4. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep4')}
    </div>
    <div>
      <b>5. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep5')}
    </div>
    <div>
      <b>6. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep6')}
    </div>
    <div>
      <b>7. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep7')}
    </div>
    <div>
      <b>8. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep8')}
    </div>
    <div>
      <b>9. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep9')}
    </div>
    <div>
      <b>10. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep10')}
    </div>
    <div>
      <b>11. &nbsp;&nbsp;</b>
      {t('message.processingStreamsNoteStep11')}
    </div>
  </div>
);

const messageNotesReprocessing = t => (
  <div dangerouslySetInnerHTML={{ __html: t('message.messgaeNoteReprocessing') }} />
);

export {
  tabelColumnOperatorDispute,
  sortOperatorDispute,
  tabelColumnOperatorDisputeById,
  tabelColumnOperatorInvoice,
  sortOperatorInvoice,
  tabelColumnOperatorInvoiceById,
  tabelColumnOperatorRecordLog,
  tabelColumnOperatorRecordLogById,
  sortOperatorRecordLog,
  tabelColumnOperatorTenantDispute,
  sortOperatorTenantDispute,
  tabelColumnOperatorTenantDisputeById,
  tabelColumnOperatorTenantRecordLog,
  sortOperatorTenantRecordLog,
  tabelColumnOperatorTenantRecordLogById,
  sortRerating,
  groupNamePermissions,
  messageNotes,
  messageNotesStream,
  messageNotesReprocessing,
};

export const pnnListSort = {
  idOperador: {
    asc: 'idOperador_ASC',
    desc: 'idOperador_DESC',
  },
  ido: {
    asc: 'ido_ASC',
    desc: 'ido_DESC',
  },
  ida: {
    asc: 'ida_ASC',
    desc: 'ida_DESC',
  },
  estatus: {
    asc: 'estatus_ASC',
    desc: 'estatus_DESC',
  },
};
