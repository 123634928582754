import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class UserSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.userId',
      name: 'userId',
    },
    {
      label: 'label.firstName',
      name: 'firstName',
    },
    {
      label: 'label.lastName',
      name: 'lastName',
    },
    {
      label: 'label.email',
      name: 'emailId',
    },
  ];
}

UserSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UserSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UserSearchForm);
