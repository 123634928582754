import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['status', 'objectType', 'transactionType'])}`
    : '';
  const searchConfigAccountingRules = `searchConfigAccountingRules(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchConfigAccountingRules} {
        id
        index
        drItemId
        crItemId
        drGlAccount
        crGlAccount
        description
        crAccountType
        drAccountType
        objectType
        transactionType
        status
        currency
    }
  }
  `;
};
