import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { size, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import PricingForm from './PricingForm';
import { onCheckSelectionsUsage } from '../../../containers/PricingManagement/constants';
import { CheckField, GenericInput } from '../../common';

const selectionsComplex = t => [
  {
    name: 1,
    label: t('label.addCustomAttributes'),
  },
  {
    name: 2,
    label: t('label.addUsageAttributes'),
  },
  {
    name: 3,
    label: t('label.addTransactionAttributes'),
  },
  {
    name: 4,
    label: t('label.addTiers'),
  },
];

const selectionsComplexRecurring = t => [
  {
    name: 1,
    label: t('label.addCustomAttributes'),
  },
  {
    name: 4,
    label: t('label.addTiers'),
  },
];

class PricingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectField: [1, 3],
      pricings: [],
      isShowComponent: true,
      indexShowing: 1,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.pricings === props.pricings && state.pricingModel && props.pricingModel) return null;
    return {
      pricings: props.pricings || [],
      pricingModel: props.pricingModel,
      transactionType: props.transactionType,
      selectField:
        props.pricingModel === 'COMPLEX' &&
        onCheckSelectionsUsage({
          pricing: props.pricings[props.pricings.length - 1],
          transactionType: props.transactionType,
        })
          ? onCheckSelectionsUsage({
              pricing: props.pricings[props.pricings.length - 1],
              transactionType: props.transactionType,
            })
          : [],
    };
  }

  onToggleComponent = () => {
    const { isShowComponent } = this.state;
    this.setState({ isShowComponent: !isShowComponent });
  };

  onToggleShowIndex = index => {
    const { indexShowing } = this.state;
    this.setState({ indexShowing: indexShowing === index + 1 ? 0 : index + 1 });
  };

  render() {
    const { pricings = [], selectField, isShowComponent, indexShowing } = this.state;
    const { pricingModel, optionsAccumulatorId, transactionType, t, ...props } = this.props;
    const requiredTiered =
      pricings[0] &&
      pricings[0].tierType &&
      pricings[0].tierType === 'PROSPECTIVE' &&
      (pricingModel === 'TIERED' || pricingModel === 'COMPLEX');
    const fieldCustomAttribute = [
      {
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        name: 'salesChannel',
        type: 'select',
        tOptions: 'selections:salesChannel',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        name: 'marketSegment',
        type: 'select',
        tOptions: 'selections:marketSegment',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        name: 'accountType',
        type: 'select',
        tOptions: 'selections:accountType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.accountSubType',
        wrapperClass: 'col-md-4',
        name: 'accountSubType',
        type: 'select',
        tOptions: 'selections:accountSubType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.region',
        wrapperClass: 'col-md-4',
        name: 'region',
      },
    ];

    const fieldTransactionAttribute = [
      {
        label: 'label.rateUnit',
        wrapperClass: 'col-md-4',
        name: 'rateUnit',
        fa: 'fa fa-external-link',
      },
      {
        label: 'label.timeUnit',
        wrapperClass: 'col-md-4',
        name: 'timeUnit',
        type: 'select',
        tOptions: 'selections:timeUnitPricing',
        isClearable: true,
        // fa: 'fa fa-external-link',
        menuPortalTarget: true,
      },
      {
        label: 'label.zoneUnit',
        wrapperClass: 'col-md-4',
        name: 'zoneUnit',
      },
      {
        label: 'label.impactUnit',
        wrapperClass: 'col-md-4',
        name: 'impactUnit',
      },
      {
        label: 'label.holidayName',
        wrapperClass: 'col-md-4',
        name: 'holidayName',
        fa: 'fa fa-external-link',
      },
    ];

    const fieldTier = [
      {
        label: 'label.tierReference',
        wrapperClass: 'col-md-4',
        name: 'tierReference',
        type: 'select',
        tOptions: 'selections:tierReference',
        isClearable: true,
        required: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.accumulatorId',
        wrapperClass: 'col-md-4',
        name: 'accumulatorId',
        type: 'select',
        options: optionsAccumulatorId,
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.tierType',
        wrapperClass: 'col-md-4',
        name: 'tierType',
        type: 'select',
        tOptions: 'selections:tierType',
        isClearable: true,
        required: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.evaluationStart',
        wrapperClass: 'col-md-4',
        name: 'evaluationStart',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.evaluationEnd',
        wrapperClass: 'col-md-4',
        name: 'evaluationEnd',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.offsetMonths',
        wrapperClass: 'col-md-4',
        name: 'offsetMonths',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.pricingTerm',
        wrapperClass: 'col-md-4',
        name: 'nextPricingTerm',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.volumeTreatment',
        wrapperClass: 'col-md-4',
        name: 'volumeTreatment',
        type: 'select',
        tOptions: 'selections:volumeTreatment',
        isClearable: true,
        required: requiredTiered,
        disabled: !requiredTiered,
        menuPortalTarget: true,
      },
    ];

    const fieldTierComplex = [
      {
        label: 'label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        required: true,
      },
    ];

    const listFieldsRecurring = [
      {
        label: 'label.recurringUnit',
        wrapperClass: 'col-md-4',
        name: 'unit',
        type: 'select',
        tOptions: 'selections:recurringUnit',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.recurringFrequency',
        wrapperClass: 'col-md-4',
        name: 'frequency',
        type: 'number',
      },
      {
        label: 'label.purchaseProration',
        wrapperClass: 'col-md-4',
        name: 'purchaseProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.cancelProration',
        wrapperClass: 'col-md-4',
        name: 'cancelProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.upgradeProration',
        wrapperClass: 'col-md-4',
        name: 'upgradeProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.downgradeProration',
        wrapperClass: 'col-md-4',
        name: 'downgradeProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      // {
      //   label: 'label.applyInAdvance',
      //   name: 'applyInAdvance',
      //   type: 'select',
      //   tOptions: 'selections:selectBool',
      //   subType: 'boolean',
      // },
    ];

    const PRICING_MODELS = {
      FLAT: {
        title: t('label.flatPricing'),
        inputs: [],
      },
      RECURRING: {
        title: t('label.recurringPricing'),
        inputs: [
          ...listFieldsRecurring,
          {
            label: 'label.applyInAdvance',
            name: 'applyInAdvance',
            type: 'select',
            tOptions: 'selections:selectBool',
            subType: 'boolean',
            menuPortalTarget: true,
          },
        ],
      },
      CUSTOMER_ATTRIBUTE_BASED: {
        title: t('label.customPricing'),
        inputs: fieldCustomAttribute,
      },
      RECURRING_TIERED: {
        title: t('label.recurringTieredPricingModel'),
        inputs: [
          {
            label: 'label.recurringUnit',
            wrapperClass: 'col-md-4',
            name: 'unit',
            type: 'select',
            tOptions: 'selections:recurringUnit',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.recurringFrequency',
            wrapperClass: 'col-md-4',
            name: 'frequency',
            type: 'number',
          },
          {
            label: 'label.purchaseProration',
            wrapperClass: 'col-md-4',
            name: 'purchaseProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.cancelProration',
            wrapperClass: 'col-md-4',
            name: 'cancelProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.upgradeProration',
            wrapperClass: 'col-md-4',
            name: 'upgradeProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.downgradeProration',
            wrapperClass: 'col-md-4',
            name: 'downgradeProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.applyInAdvance',
            name: 'applyInAdvance',
            type: 'select',
            tOptions: 'selections:selectBool',
            subType: 'boolean',
            menuPortalTarget: true,
          },
        ],
      },
      TRANSACTION_ATTRIBUTE_BASED: {
        title: t('label.transactionAttributeBasedPricingModel'),
        inputs: fieldTransactionAttribute,
      },
      TIERED: {
        title: t('label.tieredPricingModel'),
        inputs: [
          {
            label: 'label.name',
            wrapperClass: 'col-md-4',
            name: 'name',
            required: true,
          },
          {
            label: 'label.description',
            wrapperClass: 'col-md-4',
            name: 'description',
            required: true,
          },
          ...fieldTier,
        ],
      },
      COMPLEX_USAGE: {
        title: t('label.complexPricing'),
        inputs: [],
      },
      COMPLEX_RECURRING: {
        title: t('label.complexPricingRecurring'),
        inputs: [],
      },
      USAGE_ATTRIBUTE_BASED: {
        title: t('label.usageAttributeBase'),
        inputs: [
          {
            label: 'label.itemId',
            wrapperClass: 'col-md-3 ml-2 pb-2 pr-5',
            name: 'itemId',
            fa: 'fa fa-external-link',
          },
        ],
      },
    };
    const subInputRecurringTirred =
      pricingModel === 'RECURRING_TIERED'
        ? [
            {
              label: 'label.name',
              wrapperClass: 'col-md-4',
              name: 'name',
              required: true,
            },
            {
              label: 'label.description',
              wrapperClass: 'col-md-4',
              name: 'description',
              required: true,
            },
            {
              label: 'label.tierType',
              wrapperClass: 'col-md-4',
              name: 'tierType',
              type: 'select',
              tOptions: 'selections:tierType',
              isClearable: true,
              required: true,
              menuPortalTarget: true,
            },
            {
              label: 'label.tierReference',
              wrapperClass: 'col-md-4',
              name: 'tierReference',
              type: 'select',
              tOptions: 'selections:tierReference',
              isClearable: true,
              required: true,
              menuPortalTarget: true,
            },
            {
              label: 'label.accumulator',
              wrapperClass: 'col-md-4',
              name: 'accumulatorId',
              type: 'select',
              options: optionsAccumulatorId,
              isClearable: true,
              required: true,
              menuPortalTarget: true,
            },
          ]
        : [];

    let pm = '';
    if (transactionType === 'USAGE' && pricingModel === 'COMPLEX') {
      pm = PRICING_MODELS.COMPLEX_USAGE;
    } else if (
      (transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING') &&
      pricingModel === 'COMPLEX'
    ) {
      pm = PRICING_MODELS.COMPLEX_RECURRING;
    } else {
      pm = cloneDeep(PRICING_MODELS[pricingModel]);
    }
    const isComplexUsage =
      (transactionType === 'USAGE' ||
        transactionType === 'FORWARD_RECURRING' ||
        transactionType === 'ARREARS_RECURRING') &&
      pricingModel === 'COMPLEX';

    const listSelections =
      isComplexUsage && transactionType === 'USAGE' ? selectionsComplex(t) : selectionsComplexRecurring(t);
    const pricingTitle = pm ? pm.title : '';
    if (!pm) return null;
    return (
      <div>
        <div className="card nested-section-card">
          <button
            type="button"
            className={`card-header text-left no-border btn-collapsible ${isShowComponent ? 'active-main' : ''}`}
            onClick={this.onToggleComponent}
          >
            {pricingTitle}
            {isShowComponent ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
          </button>
          <div className={isShowComponent ? 'show-content' : 'hidden-content'}>
            {(transactionType === 'USAGE' ||
              transactionType === 'FORWARD_RECURRING' ||
              transactionType === 'ARREARS_RECURRING') &&
              pricingModel === 'COMPLEX' && [
                <div key="1">
                  {(transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING') && (
                    <div className="col-md-12 p-3 row">
                      {listFieldsRecurring.map(val => {
                        return (
                          <GenericInput
                            key={val.name}
                            value={pricings[0] && pricings[0][val.name]}
                            label={t(val.label)}
                            wrapperClass="col-md-3"
                            disabled
                            readOnly
                            {...val}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>,
                <div key="2" className="col-md-12 p-3 row">
                  <div className="col-md-10 row">
                    {listSelections.map(item => (
                      <div className="col-md-3" key={item.name}>
                        <CheckField
                          checked={selectField.findIndex(val => val === item.name) !== -1}
                          name={item.name}
                          label={item.label}
                          disabled
                          readOnly
                        />
                      </div>
                    ))}
                  </div>
                </div>,
              ]}
            <div className="card-body">
              {pricings &&
                pricings
                  .filter(val => size(val) > (isComplexUsage ? 2 : 1))
                  .map((pricing, pricingIdx) => {
                    let inputFields = pm.inputs;
                    const newSelectField = [];
                    if (
                      (transactionType === 'USAGE' ||
                        transactionType === 'FORWARD_RECURRING' ||
                        transactionType === 'ARREARS_RECURRING') &&
                      pricingModel === 'COMPLEX'
                    ) {
                      if (
                        pricing.salesChannel ||
                        pricing.marketSegment ||
                        pricing.accountType ||
                        pricing.accountSubType
                      ) {
                        newSelectField.push(1);
                        inputFields = inputFields.concat({
                          title: 'label.customerAttributes',
                          listField: fieldCustomAttribute,
                        });
                      }
                      if (
                        pricing.rateUnit ||
                        pricing.timeUnit ||
                        pricing.zoneUnit ||
                        pricing.impactUnit ||
                        pricing.holidayName
                      ) {
                        newSelectField.push(3);
                        inputFields = inputFields.concat({
                          title: 'label.transactionAttributes',
                          listField: fieldTransactionAttribute,
                        });
                      }
                      if (pricing.usageAttributePricingData) {
                        newSelectField.push(2);
                        inputFields = inputFields.concat({
                          title: 'label.usageAttributes',
                          isRenderSubField: true,
                          listField: [],
                        });
                      }
                      if (pricing.tiers && pricing.tiers.length) {
                        newSelectField.push(4);
                        inputFields = inputFields.concat({
                          title: 'label.tiers',
                          listField: [
                            ...fieldTierComplex,
                            ...fieldTier.map(val => {
                              const { required, ...rest } = val;
                              return rest;
                            }),
                          ],
                        });
                      }
                    }
                    return (
                      <PricingForm
                        key={pricingIdx}
                        pricing={pricing}
                        index={pricingIdx}
                        pricingInputs={isComplexUsage ? [] : inputFields}
                        inputFieldsUsageComplex={inputFields}
                        pricingRemoveable={pricings.length > 1}
                        optionsAccumulatorId={optionsAccumulatorId}
                        pricingModel={pricingModel}
                        selectField={newSelectField}
                        checkModel={pricingModel}
                        isComplexUsage={isComplexUsage}
                        subInputRecurringTirred={subInputRecurringTirred}
                        indexShowing={indexShowing}
                        onToggleShowIndex={this.onToggleShowIndex}
                        transactionType={transactionType}
                        {...props}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PricingSection.propTypes = {
  onChangePricings: PropTypes.func.isRequired,
  pricings: PropTypes.arrayOf(PropTypes.object),
  optionsAccumulatorId: PropTypes.arrayOf(PropTypes.any),
  transactionType: PropTypes.string,
  pricingModel: PropTypes.string,
  creatingMode: PropTypes.bool,
  t: PropTypes.func,
};

PricingSection.defaultProps = {
  pricings: [{}],
  creatingMode: true,
  optionsAccumulatorId: [],
  transactionType: '',
  pricingModel: '',
  t: () => {},
};

export default withTranslation('common')(PricingSection);
