import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['status', 'sharingType', 'index']);
  const createSharingGroup = `createSharingGroup(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createSharingGroup} {
      id
      accountId
      subscriptionId
      priceOfferId
      priceOfferName
      status
      sharingType
      discountOffers {
        index
        discountOfferId
        discountOfferName
      }
    }
  }
`;
};
