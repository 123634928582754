import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import AccountStatementSearchForm from '../AccountStatementSearchForm';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  TitleFrom,
  AccountHeaderFrom,
  ButtonExport,
  ModalViewNote,
} from '../../common';
// import ModalInvoice from '../../common/ModalInvoice';
import RouteNames from '../../../containers/App/RouteNames';
// import { tabelColumnExportAccountStatement, sortAccountStatement } from '../../constants';
import { getPageTotalCount, formatMoneyValue } from '../../../utils/utils';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelAccountStatement';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfAccountStatement';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';

class AccountStatement extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    data: [],
    isOpenModalNotes: false,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listAccountStatements: props.listAccountStatements,
      totalCount: getPageTotalCount({ ...state, items: state.data }),
    };
  }

  componentDidMount() {
    // const {
    //   permissions: { modeGetAccountStatementReport },
    // } = this.props;
    // if (modeGetAccountStatementReport) {

    // }
    const { location } = this.props;
    if (location && location.state && location.state) {
      const { filter, page, size, sort, sorted, totalCount } = location.state || {};
      this.setState({ filter, page, size, sort, sorted, totalCount }, () => {
        this.doGetReportAccountStatement();
      });
    } else {
      this.doGetReportAccountStatement();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetReportAccountStatement();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetReportAccountStatement());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportAccountStatement());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetReportAccountStatement();
    });
  };

  doGetReportAccountStatement = () => {
    const { filter, page, size } = this.state;
    const { getAccountStatement, id, getClientAccountStatement, customerInfo } = this.props;
    const doGetDatFromApi =
      customerInfo && customerInfo.type === 'CLIENT' ? getClientAccountStatement : getAccountStatement;
    const payload = {
      page: page + 1,
      accountId: id,
      size,
      filter,
      // sort: !isEmpty(sorted) ? sortAccountStatement[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    doGetDatFromApi(payload, ({ data }) => {
      this.setState({ isSearching: false, data });
    });
  };

  doGetAllAccountStatement = cb => {
    const { filter } = this.state;
    const { getAccountStatement, id, getClientAccountStatement, customerInfo } = this.props;
    const doGetDatFromApi =
      customerInfo && customerInfo.type === 'CLIENT' ? getClientAccountStatement : getAccountStatement;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      accountId: id,
      filter,
      // sort: !isEmpty(sorted) ? sortAccountStatement[sorted.sortCol][sorted.sortDir] : null,
    };
    doGetDatFromApi(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAllAccountStatement(() => {
      const { isActivePdf, data } = this.state;
      if (isActivePdf) {
        const { t, customerInfo } = this.props;
        convertJson2Pdf({
          data: data && data.length ? data : [],
          t,
          title: t('label.accountStatement').toLocaleUpperCase(),
          fileName: `${t('label.accountStatement')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          customerInfo,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  onToggleModalNotes = () => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes });
  };

  doGetObjectFileById = (invoiceId, isInvoice) => {
    const { history, getObjectFileById, id } = this.props;
    const { filter, page, size, sort, sorted, totalCount } = this.state;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: isInvoice
            ? RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId)
            : RouteNames.prettifyPath(RouteNames.viewFileInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.prettifyPath(RouteNames.accountStatement.path, id),
            fileData: data,
            filter,
            page,
            size,
            sort,
            sorted,
            totalCount,
          },
        });
      }
    });
  };

  render() {
    const { isSearching, isActivePdf, sorted, page, size, totalCount, data, isOpenModalNotes } = this.state;
    const {
      t,
      id,
      currencyOptions,
      customerInfo,
      location,
      // permissions: { modeGetAccountStatementReport, modeDownloadReport, modeGetMailReport },
    } = this.props;
    // if (!modeGetAccountStatementReport) return '';
    const currency = [];
    const tableConfigColumns = [];
    if (customerInfo?.type === 'CLIENT') {
      currency.push({
        name: 'currency',
        label: 'label.invoiceCurrency',
      });
    }
    if (customerInfo?.type === 'CLIENT') {
      tableConfigColumns.push({
        name: 'amountInFunctionalCurrency',
        label: 'label.totalFunctionalCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
        render: (colName, item) => {
          return (
            <span className={`${item.amountInFunctionalCurrency < 0 ? 'red-color' : ''}`}>
              {formatMoneyValue(item.amountInFunctionalCurrency)}
            </span>
          );
        },
      });
      tableConfigColumns.push({
        name: 'functionalCurrency',
        label: 'label.functionalCurrency',
      });
      tableConfigColumns.push({
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        render: (colName, item) => {
          return (
            <span className={`${item.exchangeRate < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.exchangeRate)}</span>
          );
        },
      });
    }

    const tabelColumnExportAccountStatement = [
      {
        name: 'accountId',
        label: 'label.accountNumber',
      },
      {
        name: 'lineType',
        label: 'label.documentType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountStatementLineType')().find(val => val.value === item.lineType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'invoiceId',
        label: 'common:label.invoiceId',
        // render: (colName, item) => {
        //   return (
        //     <Link
        //       className="text-success"
        //       to={{
        //         pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, item.invoiceId),
        //         state: {
        //           fromPath: RouteNames.prettifyPath(RouteNames.accountStatement.path, id),
        //         },
        //       }}
        //     >
        //       {item.invoiceId}
        //     </Link>
        //   );
        // },
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              onClick={() => this.doGetObjectFileById(item.invoiceId, true)}
            >
              {item.invoiceId}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'label.invoiceDate',
      },
      ...currency,
      {
        name: 'subTotal',
        label: 'label.subTotal',
        render: (colName, item) => {
          return <span className={`${item.subTotal < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.subTotal)}</span>;
        },
      },
      {
        name: 'taxes',
        label: 'label.taxes',
        render: (colName, item) => {
          return <span className={`${item.taxes < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.taxes)}</span>;
        },
      },
      {
        name: 'amount',
        label: 'label.total',
        render: (colName, item) => {
          return <span className={`${item.amount < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.amount)}</span>;
        },
      },
      ...tableConfigColumns,
      {
        name: 'dueDate',
        label: 'label.dueDate',
      },
      {
        name: 'lineReferenceId',
        label: 'label.folioId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              onClick={() => this.doGetObjectFileById(item.lineReferenceId, false)}
            >
              {item.lineReferenceId}
            </button>
          );
        },
      },
      {
        name: 'lineDate',
        label: 'label.folioDate',
      },
      {
        name: 'folioStatus',
        label: 'label.folioStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'status',
        label: 'label.status',
      },
      {
        name: 'openingBalance',
        label: 'label.openingBalance',
        render: (colName, item) => {
          if (customerInfo?.type === 'CLIENT') {
            return <span />;
          }
          return (
            <span className={`${item.openingBalance < 0 ? 'red-color' : ''}`}>
              {formatMoneyValue(item.openingBalance)}
            </span>
          );
        },
      },
      {
        name: 'closingBalance',
        label: 'label.closingBalance',
        render: (colName, item) => {
          if (customerInfo?.type === 'CLIENT') {
            return <span />;
          }
          return (
            <span className={`${item.closingBalance < 0 ? 'red-color' : ''}`}>
              {formatMoneyValue(item.closingBalance || 0)}
            </span>
          );
        },
      },
    ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }

    return (
      <div className="col-md-12">
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('label.accountStatement')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <AccountStatementSearchForm
                  onSubmit={this.onHandleSubmitSearch}
                  defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}

                  // ModalSearch={props => {
                  //   return (
                  //     <div>
                  //       <ModalInvoice
                  //         isOpen={props.isOpen === 'invoiceId'}
                  //         onSelect={props.onSelect}
                  //         onCancel={props.onToggle}
                  //         accountId={id}
                  //       />
                  //     </div>
                  //   );
                  // }}
                />
              </div>
              <div>
                <div className="mt-3 d-flex float-right mb-4 pt-3">
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('label.accountStatement').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: data && data.length ? data : [],
                        t,
                        customerInfo,
                        title: t('label.accountStatement').toLocaleUpperCase(),
                      })}
                      fileName={`${t('label.accountStatement')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                  <button type="submit" className="button x-small float-right">
                    {t('label.email')}
                  </button>
                </div>
                <br />
                <br />
                <br />
                <div className="col-md-12">
                  <button type="button" className="button button-border gray x-small" onClick={this.onToggleModalNotes}>
                    {t('label.quickNotes')}
                    <i className="fa fa-external-link ml-2" aria-hidden="true" />
                  </button>
                </div>
                <br />
                <DataTable
                  columns={tabelColumnExportAccountStatement}
                  data={data && data.length ? data : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalViewNote
          isOpen={isOpenModalNotes}
          message={t('message.messageNoteAccountStatement')}
          onToggle={this.onToggleModalNotes}
        />
      </div>
    );
  }
}

AccountStatement.propTypes = {};

AccountStatement.defaultProps = {};

export default withTranslation('common')(AccountStatement);
