import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';
import orderNS from './constants/orderNS';

export default data => {
  const dataSearch = parseToMutationRequest(data, orderNS);
  return gql`
  query {
    buildOrderProvisioningAttributes(input: ${dataSearch}) {
      serviceType
      priceOfferId
      configProvisioningAttributes {
        id
        name
        description
        attributesList {
          name
          value
          defaultValue
          uom
          index
          attributesLovs {
            id
            code
          }
        }
      }
    }
  }
`;
};
