import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { messageNotes } from '../../constants';
import { makeGetUsageFileMapCfg } from '../../selectors';
import { getUsageFileMapCfg, createUsageFileMapCfg, modifyUsageFileMapCfg } from '../../actions';
import { getUsageContainerSpecConfig } from '../../../App/actions';
import {
  TitleFrom,
  GenericInput,
  ModalAccept,
  CheckField,
  DataTableWithExpandRow,
  ModalViewNote,
  FormCollapse,
} from '../../../../components/common';
import NewFileMapping from './NewFileMapping';

class ListFileMapping extends PureComponent {
  state = {
    fileMapCfgList: [],
    dataSelected: [],
    isSearching: false,
    isOpenModalRemoveItem: false,
    isModify: false,
    isOpenModalItem: false,
    idSelected: '',
    dataExpand: {},
    isOpenModalNotes: false,
    listSwitchActive: [],
    idxItemCopy: null,
    usageContainerRecordList: [],
    activeTab: {},
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.fileMapCfgList) return null;
    return {
      fileMapCfgList: props.fileMapCfgList || null,
      oldValue: props.fileMapCfgList,
    };
  }

  componentDidMount() {
    const { getUsageContainerSpecConfig } = this.props;
    this.doGetUsageFileMapCfg();
    getUsageContainerSpecConfig(true, ({ success, data }) => {
      if (success) {
        this.setState({ usageContainerRecordList: data && data.length ? data : [] });
      }
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   const { fileMapCfgList } = this.props;
  //   if (fileMapCfgList && nextProps.fileMapCfgList && fileMapCfgList !== nextProps.fileMapCfgList) {
  //     this.setState({
  //       fileMapCfgList: nextProps.fileMapCfgList,
  //     });
  //   }
  // }

  onToggleModalNotes = () => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes });
  };

  onChangeTable = ({ name, value, id }) => {
    const { fileMapCfgList } = this.state;
    const newData = cloneDeep(fileMapCfgList);
    const indexItemChange = newData.findIndex(item => item.id === id);
    newData[indexItemChange][name] = value;
    this.setState({ fileMapCfgList: newData });
  };

  doGetUsageFileMapCfg = () => {
    const { getUsageFileMapCfg } = this.props;
    getUsageFileMapCfg(({ data }) => {
      this.setState({ isSearching: false, isModify: !data || !!data.length, dataExpand: {} }, () => {
        if (data && data.length) this.onToggleTab(data[0].id, true);
      });
    });
  };

  onAddNewUsageFile = (isCopy, idxItemCopy) => {
    let idNewItem = 0;
    const { fileMapCfgList } = this.state;
    let newData = cloneDeep(fileMapCfgList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.id > idNewItem) {
          idNewItem = parseInt(item.id, 10);
        }
      });
    }

    if (!isCopy) {
      const newDataItem = {
        id: null,
        isNew: true,
        fileDelimiter: null,
        source: null,
        fileDescription: null,
        recordIndicator: null,
        serviceType: null,
        usageFileMapRecCfg: [
          {
            sourceRecord: null,
            targetRecord: null,
            recordDelimiter: null,
            usageFileMapRecAttributesCfg: [{ sourceFieldPos: null, targetField: null }],
          },
        ],
      };
      newData = [newDataItem, ...newData];
      return this.setState({ fileMapCfgList: newData }, () => {
        this.onToggleTab(newDataItem.id);
      });
      // newData.push(newDataItem);
    }
    if (isCopy && idxItemCopy > -1 && idxItemCopy !== null) {
      const newDataItem = cloneDeep(fileMapCfgList[idxItemCopy]);
      newDataItem.id = 'null';
      newDataItem.isNew = true;
      newData = [newDataItem, ...newData];
      return this.setState({ fileMapCfgList: newData, activeTab: {} }, () => {
        this.onToggleTab(newDataItem.id);
      });
    }
    return this.setState({ fileMapCfgList: newData });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onHanldeSubmit = id => {
    const { fileMapCfgList, isModify } = this.state;
    const { createUsageFileMapCfg, modifyUsageFileMapCfg } = this.props;
    const newPayload = cloneDeep(fileMapCfgList[id]);
    if (fileMapCfgList[0] && (!isModify || newPayload.isNew)) {
      delete newPayload.isNew;
      if (newPayload.usageFileMapRecCfg && newPayload.usageFileMapRecCfg.length) {
        newPayload.usageFileMapRecCfg = newPayload.usageFileMapRecCfg.map(item => {
          const newItem = item;
          delete newItem.index;
          if (newItem.usageFileMapRecAttributesCfg && newItem.usageFileMapRecAttributesCfg.length) {
            newItem.usageFileMapRecAttributesCfg = newItem.usageFileMapRecAttributesCfg.map(val => {
              const newVal = val;
              delete newVal.index;
              return newVal;
            });
          }
          return newItem;
        });
      }
      createUsageFileMapCfg(newPayload, ({ success, data }) => {
        if (success) this.onToggleTab(data.id);
        this.doGetUsageFileMapCfg();
      });
    } else {
      modifyUsageFileMapCfg(newPayload);
    }
  };

  onHandleOpenDetailFile = idx => {
    const { fileMapCfgList } = this.state;
    this.setState({ isOpenModalItem: true, dataSelected: fileMapCfgList[idx].usageFileMapRecCfg, idSelected: idx });
  };

  onHandleToggleModalItem = () => {
    this.setState({ isOpenModalItem: false });
  };

  onSaveUsageRecCfg = (usageRecCfg, idSelected) => {
    const { fileMapCfgList } = this.state;

    const newData = cloneDeep(fileMapCfgList);
    newData[idSelected].usageFileMapRecCfg = usageRecCfg;
    return this.setState({ fileMapCfgList: newData, isOpenModalItem: false }, () => this.onHanldeSubmit(idSelected));
  };

  onHandleExpandRow = idx => {
    const { fileMapCfgList, dataExpand } = this.state;
    this.setState({ dataExpand: dataExpand === fileMapCfgList[idx] ? {} : fileMapCfgList[idx] });
  };

  onClickRow = idx => {
    const { fileMapCfgList, dataExpand } = this.state;
    if (idx !== -1) {
      return this.setState({ dataExpand: dataExpand === fileMapCfgList[idx] ? {} : fileMapCfgList[idx] });
    }
  };

  onRemoveItem = item => {
    const { modifyUsageFileMapCfg } = this.props;
    const { id, source } = item;
    modifyUsageFileMapCfg({ id, source });
  };

  onRenderChildren = ({ item, idx }) => {
    const { dataExpand, fileMapCfgList, usageContainerRecordList } = this.state;
    const data =
      fileMapCfgList && fileMapCfgList[idx] && fileMapCfgList[idx].usageFileMapRecCfg
        ? fileMapCfgList[idx].usageFileMapRecCfg || []
        : [];
    return (
      <div className="col-md-12">
        {/* <GenericInput
          value={item.id}
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="id"
          readOnly
          label="label.id"
        />
        <GenericInput
          value={item.serviceType || null}
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="serviceType"
          type="select"
          menuPortalTarget
          tOptions="selections:serviceType"
          required
          label="label.serviceType"
        />
        <GenericInput
          type="select"
          value={item.recordIndicator || ''}
          menuPortalTarget
          tOptions="selections:recordIndicator"
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="recordIndicator"
          required
          label="label.recordIndicator"
        />
        <GenericInput
          value={item.source || ''}
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="source"
          required
          label="label.source"
        />
        <GenericInput
          value={item.fileDelimiter || ''}
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="fileDelimiter"
          required
          label="label.fileDelimiter"
        />
        <GenericInput
          value={item.fileDescription || ''}
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="fileDescription"
          required
          label="label.fileDescription"
        /> */}
        <NewFileMapping
          onToggle={this.onHandleToggleModalItem}
          data={data}
          fileMapCfgList={fileMapCfgList}
          onSaveUsageRecCfg={this.onSaveUsageRecCfg}
          indexExpand={idx}
          usageContainerRecordList={usageContainerRecordList}
          item={item}
          onChangeParentTable={this.onChangeTable}
          onAddNewUsageFile={this.onAddNewUsageFile}
          isHasNew={!!(fileMapCfgList && fileMapCfgList.find(val => val.isNew))}
        />
      </div>
    );
    // if (dataExpand.id === item.id)
    //   return (
    //     <>
    //       <tr className="acitve" key="aa">
    //         <td colSpan="1" />
    //         <td colSpan="1">
    //           <GenericInput
    //             value={item.id}
    //             wrapperClass="col-md-12"
    //             onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
    //             name="Id"
    //             readOnly
    //           />
    //         </td>
    //         <td colSpan="1">
    //           <GenericInput
    //             value={item.serviceType || null}
    //             wrapperClass="col-md-12"
    //             onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
    //             name="serviceType"
    //             type="select"
    //             menuPortalTarget
    //             tOptions="selections:serviceType"
    //             required
    //           />
    //         </td>
    //         <td colSpan="1">
    //           <GenericInput
    //             type="select"
    //             value={item.recordIndicator || ''}
    //             menuPortalTarget
    //             tOptions="selections:recordIndicator"
    //             wrapperClass="col-md-12"
    //             onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
    //             name="recordIndicator"
    //             required
    //           />
    //         </td>
    //         <td colSpan="1">
    //           <GenericInput
    //             value={item.source || ''}
    //             wrapperClass="col-md-12"
    //             onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
    //             name="source"
    //             required
    //           />
    //         </td>
    //         <td colSpan="1">
    //           <GenericInput
    //             value={item.fileDelimiter || ''}
    //             wrapperClass="col-md-12"
    //             onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
    //             name="fileDelimiter"
    //             required
    //           />
    //         </td>
    //         <td colSpan="1">
    //           <GenericInput
    //             value={item.fileDescription || ''}
    //             wrapperClass="col-md-12"
    //             onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
    //             name="fileDescription"
    //             required
    //           />
    //         </td>
    //       </tr>
    //       <tr key="bb">
    //         <td colSpan="7">
    //           <NewFileMapping
    //             onToggle={this.onHandleToggleModalItem}
    //             data={dataExpand.usageFileMapRecCfg}
    //             fileMapCfgList={fileMapCfgList}
    //             onSaveUsageRecCfg={this.onSaveUsageRecCfg}
    //             indexExpand={idx}
    //             usageContainerRecordList={usageContainerRecordList}
    //           />
    //         </td>
    //       </tr>
    //     </>
    //   );
    // return '';
  };

  toggleActivateModule = item => {
    const { listSwitchActive } = this.state;
    let newData = cloneDeep(listSwitchActive);

    if (newData.indexOf(item.id) === -1) {
      newData.push(item.id);
    } else {
      newData = newData.filter(val => val !== item.id);
    }

    this.setState({ listSwitchActive: newData, dataExpand: {} });
  };

  onSelectCopy = idx => {
    const { idxItemCopy } = this.state;
    if (idxItemCopy === idx) {
      this.setState({ idxItemCopy: null });
    } else {
      this.setState({ idxItemCopy: idx });
    }
  };

  onToggleTab = (activeTabName, isNeedExpandOne) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && !isNeedExpandOne) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const {
      fileMapCfgList,
      isSearching,
      isOpenModalRemoveItem,
      dataExpand,
      isOpenModalNotes,
      listSwitchActive,
      idxItemCopy,
      activeTab,
    } = this.state;
    const { t } = this.props;

    const columns = [
      {
        name: 'action',
        label: 'label.selected',
        render: (colName, item, idx) => {
          return (
            <CheckField
              name="selectedParentId"
              value={false}
              checked={idxItemCopy === idx}
              onChange={() => this.onSelectCopy(idx)}
            />
          );
        },
      },
      {
        name: 'Id',
        label: 'label.id',
        style: { width: '140px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12  cursor"
            onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
            name="Id"
            onClick={() => this.onClickRow(idx)}
            readOnly
          />
        ),
      },
      {
        name: 'serviceType',
        label: 'label.serviceType',
        style: { textAlign: 'center', minWidth: '150px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            type="select"
            value={item.serviceType}
            menuPortalTarget
            tOptions="selections:serviceType"
            wrapperClass="col-md-12  cursor"
            onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
            name="serviceType"
            disabled
            readOnly
            required
          />
        ),
      },
      {
        name: 'recordIndicator',
        label: 'label.recordIndicator',
        required: true,
        style: { textAlign: 'center', minWidth: '150px' },
        render: (colName, item) => (
          <GenericInput
            type="select"
            value={item.recordIndicator}
            menuPortalTarget
            tOptions="selections:recordIndicator"
            wrapperClass="col-md-12  cursor"
            onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
            name="recordIndicator"
            disabled
            readOnly
            required
          />
        ),
      },
      {
        name: 'source',
        label: 'label.source',
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.source}
            wrapperClass="col-md-12  cursor"
            onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
            name="source"
            readOnly
            required
          />
        ),
      },
      {
        name: 'fileDelimiter',
        label: 'label.fileDelimiter',
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.fileDelimiter}
            wrapperClass="col-md-12  cursor"
            onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
            name="fileDelimiter"
            readOnly
            required
          />
        ),
      },
      {
        name: 'fileDescription',
        label: 'label.fileDescription',
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.fileDescription}
            wrapperClass="col-md-12  cursor"
            onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
            name="fileDescription"
            readOnly
            required
          />
        ),
      },
      {
        name: '',
        render: (colName, item, idx) => {
          return (
            <div className="row">
              <button type="button" className="btn-expand-table mr-3" onClick={() => this.onHandleExpandRow(idx)}>
                <i className={`fa ${item.id === dataExpand.id ? 'fa-minus' : 'fa-plus'}`} />
              </button>
            </div>
          );
        },
      },
    ];

    const newDataRemoveIndex =
      fileMapCfgList && fileMapCfgList.length ? fileMapCfgList.filter(item => size(item) > 1) : [];

    return (
      <div className="card card-statistics form-with-item">
        <div className="d-block d-md-flex justify-content-between mb-3">
          <div className="d-block d-md-flex clearfix sm-mt-20 col-md-12">
            <TitleFrom title={t('label.usageFileMapping')} isTabel />
            <div className="col-md-8">
              <button type="button" className="button x-small mt-2 ml-3">
                {t('label.import')}
              </button>
              <button type="button" className="button x-small mt-2 ml-3">
                {t('label.export')}
              </button>
              <button type="button" className="button x-small mt-2 ml-3">
                {t('label.exportSpecs')}
              </button>
            </div>
          </div>
        </div>
        <div className="form-group col-md-12 mt-4 buttons-attibute">
          <button
            type="button"
            className="button button-border black x-small"
            onClick={evt => this.onAddNewUsageFile()}
            disabled={!!(fileMapCfgList && fileMapCfgList.find(val => val.isNew))}
          >
            {`+ ${t('label.addNewFileMapping')}`}
          </button>
          {/* <button
            type="button"
            className="button button-border black x-small"
            onClick={() => this.onAddNewUsageFile(true)}
            disabled={idxItemCopy === -1 || idxItemCopy === null}
          >
            {t('label.copyToAdd')}
          </button> */}
          <button
            type="button"
            onClick={() => this.doGetUsageFileMapCfg()}
            className="button button-border black x-small float-right mr-2"
          >
            {t('label.cancel')}
          </button>
          <button
            type="button"
            className="button button-border gray x-small float-right"
            onClick={this.onToggleModalNotes}
          >
            {t('label.quickNotes')}
            <i className="fa fa-external-link ml-2" aria-hidden="true" />
          </button>
        </div>
        {/* <form
          noValidate
          ref={this.formRef}
          onSubmit={this.onHandleSave}
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        > */}
        {/* <DataTableWithExpandRow
            columns={columns}
            data={newDataRemoveIndex}
            isLoading={isSearching}
            ExpandRow={this.onRenderChildren}
            rowClass="item-table-file-mapping"
            onClick={(e, item, idx) => this.onClickRow(idx)}
            listSwitchActive={listSwitchActive}
            dataExpand={dataExpand}
            isFixedHeaderTable
          /> */}

        {newDataRemoveIndex.map((item, index) => {
          let title = '';
          if (item.serviceType) {
            title = item.serviceType;
          }
          if (item.recordIndicator) {
            title = `${title}, ${item.recordIndicator}`;
          }
          if (item.source) {
            title = `${title}, ${item.source}`;
          }
          return (
            <FormCollapse
              key={item.id}
              isActive={item.id === activeTab.name && activeTab.isActive}
              title={title || 'null'}
              state={item.id}
              onToggleTab={this.onToggleTab}
            >
              {this.onRenderChildren({ item, idx: index })}
            </FormCollapse>
          );
        })}
        {/* </form> */}
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={() => {}}
          message={t('message.deleteItem')}
        />
        <ModalViewNote isOpen={isOpenModalNotes} message={messageNotes(t)} onToggle={this.onToggleModalNotes} />
      </div>
    );
  }
}

ListFileMapping.propTypes = {
  getUsageFileMapCfg: PropTypes.func.isRequired,
  createUsageFileMapCfg: PropTypes.func.isRequired,
  fileMapCfgList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

ListFileMapping.defaultProps = {
  fileMapCfgList: [],
};

const mapStateToProps = createStructuredSelector({
  fileMapCfgList: makeGetUsageFileMapCfg() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getUsageFileMapCfg,
    createUsageFileMapCfg,
    modifyUsageFileMapCfg,
    getUsageContainerSpecConfig,
  })(ListFileMapping)
);
