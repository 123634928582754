import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RouteNames from '../../App/RouteNames';
import { getPageTotalCount } from '../../../utils/utils';
import { DataTable, TablePagination, Breadcrumb, ModalWithItem } from '../../../components/common';
import quoteSort from '../../../constantsApp/quote/quoteSort';
import { SearchQuotesList, ModalQuoteDetails, SearchAccountForm } from '../../../components/Quotes';
import { makeGetListQuotes } from '../selectors';
import ModalAccountId from '../../../components/common/ModalAccountId';
import {
  searchQuotes,
  getQuoteDetails,
  updateQuoteStatus,
  convertQuote,
  associateQuoteToAccount,
  getQuoteDetailsViewer,
} from '../actions';
import CustomerNew from '../../CustomerPage/CustomerNew';
import { checkPermissionForAllPages } from '../CheckPermission';
import { makeGetPermissionsQuoteManagement } from '../../App/selectors';
import ModifyQuote from '../QuoteModify';
import DowngradeQuote from '../DowngradeQuote';
import UpgradeQuote from '../UpgradeQuote';
import NewQuote from '../NewQuote';

const listEditTypes = ['NEW', 'MODIFY', 'UPGRADE', 'DOWNGRADE'];
const listEditStatus = ['CONVERTED', 'COMPLETED', 'FAILED_TO_CONVERT', 'SUBMITTED'];

class SearchList extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sorted: {},
    totalCount: null,
    isSearching: false,
    quoteDetails: null,
    isOpenModalDetails: false,
    isOpenModalAccountId: false,
    idConvertSelect: null,
    isOpenModalCreateAccount: false,
    isOpenModalSelectAccount: false,
    isOpenModalAssociateQuote: false,
    accountIdAssociateQuote: null,
    isOpenModalViewDetails: false,
    itemEditSelected: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.listQuotes) {
      return {
        totalCount: getPageTotalCount({
          ...state,
          totalCount: state.totalCount || props.listQuotes.length,
          items: props.listQuotes,
        }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location, permissions } = this.props;
    const { modeSearchQuote } = permissions || {};
    if (modeSearchQuote) {
      if (location && location.state && location.state.filter) {
        this.setState({ filter: location.state.filter }, () => {
          this.doSearchQuotes();
        });
      } else {
        this.doSearchQuotes();
      }
    }
  }

  onClickRow = e => {
    e.preventDefault();
  };

  onPageChange = newPage => {
    const { page } = this.state;
    if (newPage !== page) this.setState({ page: newPage }, () => this.doSearchQuotes());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchQuotes());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchQuotes());
  };

  doSearchQuotes = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchQuotes } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && quoteSort[sorted.sortCol]
        ? quoteSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    searchQuotes(params, () => {
      this.setState({ isSearching: false, page, size, filter, sort });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchQuotes());
  };

  doGetQuoteById = id => {
    const { getQuoteDetails } = this.props;
    this.setState({ isOpenModalDetails: true });
    getQuoteDetails(id, ({ data, success }) => this.setState({ quoteDetails: success ? data : null }));
  };

  doGetQuoteDetailsById = id => {
    const { getQuoteDetailsViewer } = this.props;
    getQuoteDetailsViewer(id, ({ data, success }) => {
      this.setState({ quoteDetails: success ? data : null, isOpenModalViewDetails: true });
    });
  };

  onToggleModalDetais = () => {
    this.setState({ isOpenModalDetails: false, isOpenModalViewDetails: false, quoteDetails: null });
  };

  onToggleModalAccountId = () => {
    this.setState({ isOpenModalAccountId: false });
  };

  onToggleModalCreateAccountId = () => {
    const { isOpenModalCreateAccount, idConvertSelect } = this.state;
    const { history } = this.props;
    this.setState({
      isOpenModalCreateAccount: !isOpenModalCreateAccount,
      isOpenModalAccountId: false,
      idConvertSelect: isOpenModalCreateAccount ? null : idConvertSelect,
    });
    if (isOpenModalCreateAccount) history.push(RouteNames.searchQuoteAndList.path);
  };

  onToggleModalSelectAccount = () => {
    const { isOpenModalSelectAccount } = this.state;
    this.setState({ isOpenModalSelectAccount: !isOpenModalSelectAccount });
  };

  onSelectAccountId = id => {
    const { idConvertSelect } = this.state;
    this.doConvertQuote({ id, accountId: idConvertSelect });
  };

  doUpdateQuoteStatus = id => {
    const { updateQuoteStatus } = this.props;
    updateQuoteStatus({ id, status: 'CANCELLED' }, ({ success }) => {
      if (success) this.doSearchQuotes();
    });
  };

  doConvertQuote = ({ id, accountId }) => {
    const { convertQuote, history } = this.props;
    convertQuote({ id, accountId }, ({ success }) => {
      if (success) {
        this.doSearchQuotes();
        this.setState({ accountIdAssociateQuote: accountId, isOpenModalAssociateQuote: true });
      }
    });
    this.setState({
      isOpenModalAccountId: false,
      idConvertSelect: null,
      isOpenModalCreateAccount: false,
      isOpenModalSelectAccount: false,
    });
    history.push(RouteNames.searchQuoteAndList.path);
  };

  onSelectId = ({ id }) => {
    this.setState({ idConvertSelect: id, isOpenModalAccountId: true });
  };

  onToggleModalModalAssociateQuote = () => {
    this.setState({ isOpenModalAssociateQuote: false, accountIdAssociateQuote: null });
  };

  doAssociateQuoteToAccount = () => {
    const { associateQuoteToAccount } = this.props;
    const { accountIdAssociateQuote } = this.state;
    associateQuoteToAccount({ accountId: accountIdAssociateQuote }, () => {
      this.setState({ isOpenModalAssociateQuote: false, accountIdAssociateQuote: null });
    });
  };

  doModifyQuote = ({ item }) => {
    this.setState({ itemEditSelected: item, isOpenModalViewDetails: item && item.type !== 'NEW' });
    if (item && item.type === 'NEW') this.doGetQuoteDetailsById(item.id);
  };

  render() {
    const { listQuotes, t, location, history } = this.props;
    const {
      sorted,
      isSearching,
      page,
      totalCount,
      size,
      filter,
      isOpenModalDetails,
      quoteDetails,
      isOpenModalAccountId,
      isOpenModalCreateAccount,
      isOpenModalSelectAccount,
      isOpenModalAssociateQuote,
      isOpenModalViewDetails,
      itemEditSelected,
    } = this.state;
    const { newQuoteDetails, oldQuoteDetails, accountId } = quoteDetails || {};
    const isHasOldQuote = oldQuoteDetails && oldQuoteDetails.serviceLevelQuoteDetails;

    const { permissionQuote } = this.props;
    let permissions = {};
    if (permissionQuote && permissionQuote.quoteModulePermissions) {
      const listPermission = permissionQuote.quoteModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    const { modeSearchQuote, modeNewQuoteType, modeGetQuote, modeConvertQuote, modeCancelQuoteType } =
      permissions || {};

    // const modeSearchQuote = 2;
    // const modeNewQuoteType = 2;
    // const modeGetQuote = 2;
    // const modeConvertQuote = 2;
    // const modeCancelQuoteType = 2;

    const convertQuoteColumn = modeConvertQuote
      ? [
          {
            name: 'convertToOrder',
            label: 'common:label.convertToOrder',
            render: (colName, item) => (
              <button
                type="button"
                className="button x-small"
                disabled={modeConvertQuote === 1 || item.status === 'CANCELLED' || item.status === 'CONVERTED'}
                onClick={() =>
                  item.accountId
                    ? this.doConvertQuote({ id: item.id, accountId: item.accountId })
                    : this.onSelectId({ id: item.id })
                }
              >
                {t('label.convert')}
              </button>
            ),
          },
        ]
      : [];

    const cancelQuoteColumn = modeCancelQuoteType
      ? [
          {
            name: 'cancel',
            label: 'common:label.cancel',
            render: (colName, item) => (
              <button
                type="button"
                className="button x-small"
                disabled={modeCancelQuoteType === 1 || item.status === 'CANCELLED' || item.status === 'CONVERTED'}
                onClick={() => this.doUpdateQuoteStatus(item.id)}
              >
                {t('label.cancel')}
              </button>
            ),
          },
        ]
      : [];
    const tableColumns = [
      {
        name: 'id',
        label: 'common:label.id',
        sortable: true,
        render: (colName, item) => (
          <button
            type="button"
            className="text-success no-border"
            onClick={() => this.doModifyQuote({ item })}
            disabled={!listEditTypes.includes(item.type) || listEditStatus.includes(item.status)}
          >
            {item.id}
          </button>
        ),
      },
      ...convertQuoteColumn,
      {
        name: 'orderId',
        label: 'common:label.orderId',
      },
      {
        name: 'submittedDate',
        label: 'common:label.submittedDate',
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
        sortable: true,
      },
      {
        name: 'type',
        label: 'common:label.type',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:quoteType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'status',
        label: 'common:label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:quoteStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountId',
        label: 'common:label.accountId',
      },
      {
        name: 'clientId',
        label: 'common:label.clientId',
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'userId',
        label: 'common:label.userId',
        sortable: true,
      },

      {
        name: 'salesAgentId',
        label: 'common:label.salesAgentId',
      },
      {
        name: 'quoteSource',
        label: 'common:label.source',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:quoteSource')().find(val => val.value === item.quoteSource) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'details',
        label: 'common:label.details',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            // onClick={() => this.doGetQuoteDetailsById(item.id)}
            onClick={() => this.doModifyQuote({ item })}
            disabled={!listEditTypes.includes(item.type) || listEditStatus.includes(item.status)}
          >
            {t ? t('common:label.view') : ''}
          </button>
        ),
      },
      {
        name: 'action',
        label: 'common:label.quote',
        isRenderT: true,
        render: (colName, item, t) => (
          <button type="button" className="btn btn-outline-success btn-sm" onClick={() => this.doGetQuoteById(item.id)}>
            {t ? t('common:label.view') : ''}
          </button>
        ),
      },
      ...cancelQuoteColumn,
    ];
    const newTableColume = cloneDeep(tableColumns);

    if (!modeSearchQuote) return '';
    if (!modeGetQuote) {
      newTableColume[0] = {
        name: 'id',
        label: 'Id',
      };
      newTableColume.splice(newTableColume.length - 1, 1);
    }

    const tableConfig = {
      columns: newTableColume,
      data: listQuotes,
      sorted: { ...sorted },
      onSort: this.onSortColumn,
    };

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: modeNewQuoteType !== 2 ? RouteNames.dashboard.path : RouteNames.newQuote.path,
                  state: modeNewQuoteType ? { filter } : null,
                }}
                className="button x-small"
              >
                {modeNewQuoteType !== 2 ? t('label.back') : t('label.createNew')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('quotePage:sidebar.searchList') }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <SearchQuotesList
                    onSubmit={this.onSubmitSearch}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} onClickRow={this.onClickRow} isLoading={isSearching} />
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            isLoading={isSearching}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalQuoteDetails
          isOpenModalDetails={isOpenModalDetails}
          onToggle={this.onToggleModalDetais}
          oldQuoteDetails={oldQuoteDetails}
          newQuoteDetails={newQuoteDetails}
          isHasOldQuote={isHasOldQuote}
          isSupportColor
          accountId={accountId}
        />
        <ModalWithItem
          wrapperClass="modal-80 modal-custom"
          modalTitle={t('label.createNewAccount')}
          isOpen={isOpenModalCreateAccount}
          onToggle={this.onToggleModalCreateAccountId}
        >
          <CustomerNew
            isPopup
            history={history}
            onAddNew={this.onSelectAccountId}
            onToggleModal={this.onToggleModalCreateAccountId}
          />
        </ModalWithItem>
        <ModalWithItem isOpen={isOpenModalAccountId} onToggle={this.onToggleModalAccountId} isCloseBtn>
          <div>
            <h6 className="p-3">{t('message.contentSelectAccountInQuote')}</h6>
            <div className="text-center mt-3 mb-3">
              <button type="button" className="button x-small" onClick={this.onToggleModalSelectAccount}>
                {t('label.chooseExistingAccount')}
              </button>
              <button type="button" className="button x-small" onClick={this.onToggleModalCreateAccountId}>
                {t('label.createNewAccount')}
              </button>
            </div>
          </div>
        </ModalWithItem>
        <ModalAccountId
          isOpen={isOpenModalSelectAccount}
          onSelect={this.onSelectAccountId}
          onCancel={this.onToggleModalSelectAccount}
          searchForm={SearchAccountForm}
          defaultSearchForm={{ subscriptionStatus: 'CLOSED' }}
        />
        <ModalWithItem isOpen={isOpenModalAssociateQuote} onToggle={this.onToggleModalModalAssociateQuote}>
          <div>
            <h6 className="p-3">{t('message.associateAllOtherQuotes')}</h6>
            <div className="text-center mt-3 mb-3">
              <button type="button" className="button x-small" onClick={this.doAssociateQuoteToAccount}>
                {t('label.yes')}
              </button>
              <button type="button" className="button x-small" onClick={this.onToggleModalModalAssociateQuote}>
                {t('label.no')}
              </button>
            </div>
          </div>
        </ModalWithItem>

        <ModalWithItem
          wrapperClass="modal-80 modal-custom"
          modalTitle={t('label.quoteDetails')}
          isOpen={isOpenModalViewDetails}
          onToggle={this.onToggleModalDetais}
        >
          {itemEditSelected && itemEditSelected.type === 'MODIFY' && (
            <ModifyQuote
              permissions={permissions}
              accountId={itemEditSelected?.accountId || null}
              isComponent
              onToggle={this.onToggleModalDetais}
              id={itemEditSelected?.id || null}
              itemEditSelected={itemEditSelected}
            />
          )}
          {itemEditSelected && itemEditSelected.type === 'UPGRADE' && (
            <UpgradeQuote
              permissions={permissions}
              accountId={itemEditSelected?.accountId || null}
              isComponent
              onToggle={this.onToggleModalDetais}
              id={itemEditSelected?.id || null}
              itemEditSelected={itemEditSelected}
            />
          )}
          {itemEditSelected && itemEditSelected.type === 'DOWNGRADE' && (
            <DowngradeQuote
              permissions={permissions}
              accountId={itemEditSelected?.accountId || null}
              isComponent
              onToggle={this.onToggleModalDetais}
              id={itemEditSelected?.id || null}
              itemEditSelected={itemEditSelected}
            />
          )}
          {itemEditSelected && itemEditSelected.type === 'NEW' && (
            <NewQuote
              permissions={permissions}
              accountId={itemEditSelected?.accountId || null}
              isComponent
              onToggle={this.onToggleModalDetais}
              data={quoteDetails}
              id={itemEditSelected?.id || null}
              itemEditSelected={itemEditSelected}
            />
          )}
        </ModalWithItem>
      </div>
    );
  }
}

SearchList.propTypes = {
  listQuotes: PropTypes.arrayOf(PropTypes.any),
  permissions: PropTypes.objectOf(PropTypes.any),
  searchQuotes: PropTypes.func.isRequired,
  getQuoteDetails: PropTypes.func.isRequired,
  associateQuoteToAccount: PropTypes.func.isRequired,
  updateQuoteStatus: PropTypes.func.isRequired,
  convertQuote: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
  permissionQuote: PropTypes.objectOf(PropTypes.any),
};

SearchList.defaultProps = {
  listQuotes: [],
  permissions: {},
  location: {},
  history: {},
  t: () => {},
  permissionQuote: {},
};

const mapStateToProps = createStructuredSelector({
  listQuotes: makeGetListQuotes() || [],
  permissionQuote: makeGetPermissionsQuoteManagement() || {},
});

const newSearchList = connect(mapStateToProps, {
  searchQuotes,
  getQuoteDetails,
  updateQuoteStatus,
  convertQuote,
  associateQuoteToAccount,
  getQuoteDetailsViewer,
})(SearchList);

export default withTranslation(['common', 'quotePage'])(newSearchList);
