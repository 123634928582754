import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { getUserId } from '../../../localStorages';
import AccountTable from '../AccountTable';
import { tabelSubscription, serviceUnitTable, orderListRadioButtons } from '../constants';
import { makeGetSubscription } from '../selectors';
import { StepProgressBar, FormWithTableItem, DataTable, Breadcrumb, GenericInput } from '../../../components/common';
import { getServiceUnitAc, getSubscriptionByAccountId, createOrder } from '../actions';
import { getCcpPropertiesConfig } from '../../App/actions';
import { makeGetCcpPropertiesConfig } from '../../App/selectors';
import { convertSubscriptionDataEdit } from '../utilities';
import RouteNames from '../../App/RouteNames';
import { orderEnum } from '../../../constantsApp';
import { AttributesForm } from '../../../components/Orders';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.manageCreateOrder',
  },
];

class OptIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: {},
      orderDetail: {},
      isMultiSubscriptionEnabled: false,
      selectData: {},
      isPartialFulfillmentAllowed: null,
      customAttributesByObjectType: [],
    };
    this.services = [];
  }

  componentDidMount() {
    this.doGetCcpPropertiesConfig();
  }

  doGetCcpPropertiesConfig = () => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
    }
  };

  doInitPropertiesConfig = ccpPropertyList => {
    const multiSubscriptionEnabled = ccpPropertyList.find(val => val.property === 'multiSubscriptionEnabled');
    const partialProvisioningEnabled = ccpPropertyList.find(val => val.property === 'partialProvisioningEnabled');

    this.setState({
      isMultiSubscriptionEnabled: multiSubscriptionEnabled && multiSubscriptionEnabled?.value === 'true',
      isPartialFulfillmentAllowed: partialProvisioningEnabled && partialProvisioningEnabled?.value === 'true',
    });
  };

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState({
        customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
      });
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState({
            customAttributesByObjectType: (data && data.attributes) || [],
          });
        }
      }, 'ORDER');
    }
  };

  onClickRow = () => {};

  onSelectNext = rowItem => {
    const { orderDetail, isPartialFulfillmentAllowed } = this.state;
    orderDetail.accountId = rowItem.id;
    this.setState(
      {
        selectItem: rowItem,
        orderDetail,
        selectData: { isPartialFulfillmentAllowed: isPartialFulfillmentAllowed || null },
      },
      () => {
        this.onPressNextButton();
        this.doGetCustomAttributesByObjectType();
      }
    );
  };

  onPressBackButton = () => {
    const { history } = this.props;
    history.push(RouteNames.searchAndList.path);
    // const { stage } = this.state;

    // if (stage > 0) {
    //   this.setState({
    //     stage: stage - 1,
    //   });
    // }
  };

  onPressNextButton = () => {
    const { stage, selectItem } = this.state;
    if (selectItem.id) {
      this.setState({
        stage: stage + 1,
      });
      this.doGetSubscriptionAndService();
    }
  };

  doGetSubscriptionAndService = () => {
    const { orderDetail } = this.state;
    const { getServiceUnitAc, getSubscriptionByAccountId } = this.props;
    getServiceUnitAc(orderDetail.accountId, ({ success, data }) => {
      if (success) {
        this.setState({ serviceUnit: data });
      }
    });
    getSubscriptionByAccountId(orderDetail.accountId);
  };

  onHandleChangeOrderForm = e => {
    const { selectData } = this.state;
    const newData = cloneDeep(selectData);
    // if (e.fieldName === 'isPartialFulfillmentAllowed' && e.value) {
    //   newData.billingOnlyFlag = null;
    //   newData.provisioningOnlyFlag = null;
    //   newData.billOrderCharges = null;
    // }
    if (e.fieldName === 'billingOnlyFlag' && e.value) {
      // newData.isPartialFulfillmentAllowed = null;
      newData.provisioningOnlyFlag = null;
      // newData.billOrderCharges = null;
    }
    if (e.fieldName === 'provisioningOnlyFlag' && e.value) {
      // newData.isPartialFulfillmentAllowed = null;
      newData.billingOnlyFlag = null;
      // newData.billOrderCharges = null;
    }
    // if (e.fieldName === 'billOrderCharges' && e.value) {
    //   newData.isPartialFulfillmentAllowed = null;
    //   newData.billingOnlyFlag = null;
    //   newData.provisioningOnlyFlag = null;
    // }
    newData[e.fieldName] = e.value;

    this.setState({
      selectData: newData,
    });
  };

  onPressCreateButton = item => {
    const {
      id,
      accountId,
      name,
      renewalTerm,
      renewalTermUnit,
      initialTerm,
      initialTermUnit,
      trialTerm,
      trialTermUnit,
    } = item;
    const { selectData, customAttributesByObjectType } = this.state;
    const temp = [];
    if (customAttributesByObjectType && customAttributesByObjectType.length)
      customAttributesByObjectType.map(item => {
        if (item.value && item.isModify) {
          temp.push({ name: item.name, value: item.value });
        }
      });

    const payload = {
      // id,
      accountId,
      userId: getUserId(),
      type: orderEnum.type.optOut,
      status: 'CREATED',
      subscriptionName: name,
      subscriptions: name
        ? [
            {
              index: 1,
              subscriptionName: name || null,
              ...convertSubscriptionDataEdit({
                renewalTerm,
                renewalTermUnit,
                initialTerm,
                initialTermUnit,
                trialTerm,
                trialTermUnit,
              }),
            },
          ]
        : null,
      renewalTerm,
      renewalTermUnit,
      initialTerm,
      initialTermUnit,
      trialTerm,
      trialTermUnit,
      isPartialFulfillmentAllowed: selectData.isPartialFulfillmentAllowed || null,
      billingOnlyFlag: selectData.billingOnlyFlag || null,
      provisioningOnlyFlag: selectData.provisioningOnlyFlag || null,
      billOrderCharges: selectData.billOrderCharges || null,
      orderCustomAttributes: temp && temp.length ? temp : null,
    };
    const { createOrder } = this.props;
    createOrder(payload);
  };

  onSelectStep = step => {
    const { stage } = this.state;
    if (step < stage + 1) this.setState({ stage: step - 1 });
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  render() {
    const {
      stage,
      selectItem,
      orderDetail,
      isLoading,
      serviceUnit,
      isMultiSubscriptionEnabled,
      selectData,
      customAttributesByObjectType,
    } = this.state;
    const { t, subscriptions } = this.props;
    const newTableSubscription = [
      ...tabelSubscription,
      {
        name: 'action',
        label: 'label.action',
        render: (colName, item) => (
          <button type="button" onClick={() => this.onPressCreateButton(item)} className="button x-small">
            {t('label.optOut')}
          </button>
        ),
      },
    ];
    const newServiceUnitTable = isMultiSubscriptionEnabled
      ? [
          ...serviceUnitTable,
          {
            name: 'subscriptionName',
            label: 'common:label.subscriptionName',
          },
        ]
      : serviceUnitTable;
    return (
      <div>
        <div className="col-md-12 row p-0">
          <div className="col-sm-6">
            <button type="button" className="button x-small mb-0" onClick={() => this.onPressBackButton(true)}>
              {t('label.back')}
            </button>
          </div>
          <div className="col-sm-6 pr-0">
            <Breadcrumb
              items={[
                {
                  name: t('orderPage:sidebar.optOut'),
                  url: RouteNames.optOut.path,
                },
              ]}
            />
          </div>
        </div>
        {/* {stage >= 1 && (
          <button type="button" className="button x-small" onClick={() => this.onPressBackButton(true)}>
            {t('label.back')}
          </button>
        )} */}
        <div className="col-md-12 row pb-4">
          <div className="col-md-6 pt-0 m-auto">
            <StepProgressBar listSteps={listSteps} stepSelected={stage} onSelectStep={this.onSelectStep} />
          </div>
        </div>
        {stage === 0 && (
          <AccountTable
            type="opt-in"
            subscriptionStatus="ACTIVE"
            trialLevels="SUBSCRIPTION"
            trialStatus="OPT_IN"
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isNoPageTitle
          />
        )}
        {stage === 1 && (
          <div className="col-md-12 mb-30">
            <div className="col-md-12 row justify-content-center mt-2 mb-2">
              {orderListRadioButtons.map(item => (
                <GenericInput
                  key={item.name}
                  disabled={item.disabled}
                  value={selectData[item.name] || null}
                  onChange={({ name, value }) => this.onHandleChangeOrderForm({ fieldName: name, value })}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  {...item}
                />
              ))}
            </div>
            <FormWithTableItem title={t('common:label.subscription')} accountNum={orderDetail.accountId}>
              <DataTable isLoading={isLoading} columns={newTableSubscription} data={subscriptions} />
            </FormWithTableItem>
            <FormWithTableItem title="label.customerAttributes">
              <div className="col-md-12 p-0">
                <AttributesForm
                  customAttributesByObjectType={customAttributesByObjectType}
                  onChange={this.onHandleChangeOrderAttribute}
                />
              </div>
            </FormWithTableItem>
            <FormWithTableItem title={t('common:label.serviceUnits')} accountNum={orderDetail.accountId}>
              <DataTable isLoading={isLoading} columns={newServiceUnitTable} data={serviceUnit} />
            </FormWithTableItem>
          </div>
        )}
      </div>
    );
  }
}

OptIn.propTypes = {
  subscriptions: PropTypes.objectOf(PropTypes.any),
  createOrder: PropTypes.func.isRequired,
  getSubscriptionByAccountId: PropTypes.func.isRequired,
  getServiceUnitAc: PropTypes.func.isRequired,
  t: PropTypes.func,
};

OptIn.defaultProps = {
  subscriptions: {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  subscriptions: makeGetSubscription() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
});

const tNewOrder = connect(mapStateToProps, {
  getServiceUnitAc,
  getSubscriptionByAccountId,
  createOrder,
  getCcpPropertiesConfig,
  getCustomAttributesByObjectType,
})(OptIn);

export default withTranslation('common')(tNewOrder);
