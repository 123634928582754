import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class ExtractBISearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.completedStartDate',
      name: 'completedStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.completedEndDate',
      name: 'completedEndDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.commercialName',
      name: 'commercialName',
    },
  ];

  btnSpan = 'col-md-3';
}

ExtractBISearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ExtractBISearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ExtractBISearchForm);
