import gql from 'graphql-tag';

export default id => gql`
  query {
    getPriceOfferAudit(id: "${id}") {
      id
      name
      status
      effectiveDate
      createdDate
      pricingModel
      transactionType
      pricingDetailsJson
    }
  }
`;
