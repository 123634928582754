import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ModalWithItem } from '../common';
import QuoteItemDetailsForm from './QuoteItemDetailsForm';
import AccountHeaderFrom from '../common/AccountHeaderFrom';

const colorList = [
  {
    label: 'label.CHANGE',
    color: 'orange',
  },
  {
    label: 'label.CANCEL',
    color: 'red',
  },
  {
    label: 'label.NEW',
    color: '#28a745',
  },
];

export default function ModalQuoteDetails({
  isOpenModalDetails,
  onToggle,
  oldQuoteDetails,
  newQuoteDetails,
  isHasOldQuote,
  isSupportColor,
  accountId,
}) {
  const { t } = useTranslation('common');
  return (
    <ModalWithItem
      wrapperClass="modal-80 modal-custom"
      modalTitle={t('label.quoteDetails')}
      isOpen={isOpenModalDetails}
      onToggle={onToggle}
    >
      {accountId && <AccountHeaderFrom accountNum={accountId} />}
      <div className="col-md-12 row group-label-details">
        {isHasOldQuote &&
          isSupportColor &&
          colorList.map(val => (
            <span className="d-flex">
              <span style={{ backgroundColor: val.color }} className="label-color" />
              <span className="label-title">{t(val.label)}</span>
            </span>
          ))}
      </div>
      <div className="col-md-12 pt-3 pb-3 pt-0 row">
        {isHasOldQuote && (
          <QuoteItemDetailsForm
            data={oldQuoteDetails}
            wrapperClass="col-md-6 border-right pr-3"
            label="label.currentCharges"
            isHasOldQuote={isHasOldQuote}
            isLeftSide
            dataCompare={newQuoteDetails}
            isSupportColor={isSupportColor}
            rightData={newQuoteDetails}
          />
        )}
        <QuoteItemDetailsForm
          data={newQuoteDetails}
          wrapperClass={isHasOldQuote ? 'col-md-6 pl-3' : 'col-md-12'}
          label="label.newQuote"
          isHasOldQuote={isHasOldQuote}
          isRightSide
          dataCompare={oldQuoteDetails}
          isSupportColor={isSupportColor}
        />
      </div>
      <div className="col-md-12 mt-3">
        <button type="button" onClick={onToggle} className="button button-border black x-small mr-2 mb-2 float-right">
          {t('label.cancel')}
        </button>
      </div>
    </ModalWithItem>
  );
}

ModalQuoteDetails.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpenModalDetails: PropTypes.bool,
  isHasOldQuote: PropTypes.bool,
  isSupportColor: PropTypes.bool,
  oldQuoteDetails: PropTypes.objectOf(PropTypes.any),
  newQuoteDetails: PropTypes.objectOf(PropTypes.any),
  accountId: PropTypes.string,
};

ModalQuoteDetails.defaultProps = {
  isOpenModalDetails: false,
  isHasOldQuote: false,
  isSupportColor: false,
  oldQuoteDetails: {},
  newQuoteDetails: {},
  accountId: '',
};
