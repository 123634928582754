import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataFilter = parseToMutationRequest(data, []);
  const getCrossCurrencyPaymentDetails = `getCrossCurrencyPaymentDetails( input: ${dataFilter})`;
  return gql`
    query {
      ${getCrossCurrencyPaymentDetails} {
          invoiceId
          invoiceCurrencyInvDue
          paymentCurrencyInvDue
          exchangeRate
          invoiceCurrency
      }
    }
  `;
};
