/**
 * Invoice Value Not String
 */

export default [
  'type',
  'invoiceType',
  'invoiceDelivery',
  'status',
  'total',
  'due',
  'accountType',
  'folioStatus',
  'fetchFinalizedInvoice',
  'filterInstallmentInvoices',
  'fetchPendingDueInvoice',
  'invoiceStatus',
];
