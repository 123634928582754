import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const payload = parseToMutationRequest(data, ['index', 'zoneType', 'serviceType', 'status', 'distanceValue']);
  const modifyZoneUnit = `modifyZoneUnit(input: ${payload})`;
  return gql`
  mutation {
    ${modifyZoneUnit} {
      id
    }
  }
`;
};
