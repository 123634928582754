import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class BulkAdjustmentsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.fileName',
      name: 'fileName',
    },
    {
      label: 'common:label.uploadStartDate',
      name: 'fileUploadStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.uploadEndDate',
      name: 'fileUploadEndDate',
      type: 'date',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-3';
}

BulkAdjustmentsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BulkAdjustmentsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BulkAdjustmentsSearchForm);
