import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { searchDiscountTriggersConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import DiscountTriggerSearchForm from './DiscountTriggerSearchForm';

class ModalSearchDiscountTrigger extends PureComponent {
  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
        },
        {
          name: 'name',
          label: 'label.name',
        },
        {
          name: 'accumulatorId',
          label: 'label.accumulatorId',
        },
        {
          name: 'evaluationComponent',
          label: 'label.evaluationComponent',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t
              ? t('selections:evaluationComponent')().find(val => val.value === item.evaluationComponent)
              : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'comparisonOperator',
          label: 'label.comparisonOperator',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t
              ? t('selections:comparisonOperator')().find(val => val.value === item.comparisonOperator)
              : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'value',
          label: 'label.value',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t, searchDiscountTriggersConfig } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectUser')}
        isOpen={isOpen}
        SearchForm={DiscountTriggerSearchForm}
        searchItems={searchDiscountTriggersConfig}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName="name"
      />
    );
  }
}

ModalSearchDiscountTrigger.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  searchDiscountTriggersConfig: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  t: PropTypes.func,
};

ModalSearchDiscountTrigger.defaultProps = {
  isOpen: false,
  selectedParentId: '',
  t: () => {},
};

export default withTranslation('common')(connect(null, { searchDiscountTriggersConfig })(ModalSearchDiscountTrigger));
