import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';
export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [])}`
    : '';
  const searchConfigCountryCodes = `searchConfigCountryCodes(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchConfigCountryCodes} {
        name
        alpha2
        alpha3
        numcode
        isdcode
        continent
        region1
        region2
      }
    }
  `;
};
