import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import reducerCustomer from '../CustomerPage/reducer';
import sagaCustomer from '../CustomerPage/saga';
import reducerOrder from '../OrderPage/reducer';
import sagaOrder from '../OrderPage/saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import SeachList from './SearchList';
import NewQuote from './NewQuote';
import QuoteModify from './QuoteModify';
import UpgradeQuote from './UpgradeQuote';
import DowngradeQuote from './DowngradeQuote';
import Sidebar from './Sidebar';
import { makeGetPermissionsQuoteManagement } from '../App/selectors';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class QuoteManagement extends PureComponent {
  render() {
    const { permissionQuote } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionQuote, listKeys: permissionList.quoteManagement }))
      return null;
    let permissions = {};
    if (permissionQuote && permissionQuote.quoteModulePermissions) {
      const listPermission = permissionQuote.quoteModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    const { modeNewQuoteType, modeModifyQuoteType, modeUpgradeQuoteType, modeDowngradeQuoteType } = permissions;

    const childs = [];

    if (modeNewQuoteType) {
      childs.push({
        icon: 'fa fa-search',
        label: 'quotePage:sidebar.searchQuote',
        href: RouteNames.searchQuoteAndList.path,
      });
    }

    if (modeNewQuoteType) {
      childs.push({
        icon: 'fa fa-file-text-o',
        label: 'quotePage:sidebar.newQuote',
        href: RouteNames.newQuote.path,
      });
    }

    if (modeModifyQuoteType) {
      childs.push({
        icon: 'ti-pencil-alt',
        label: 'quotePage:sidebar.modifyQuote',
        href: RouteNames.modifyQuote.path,
      });
    }

    if (modeUpgradeQuoteType) {
      childs.push({
        icon: 'ti-export',
        label: 'quotePage:sidebar.upgradeQuote',
        href: RouteNames.upgradeQuote.path,
      });
    }

    if (modeDowngradeQuoteType) {
      childs.push({
        icon: 'ti-download',
        label: 'quotePage:sidebar.downgradeQuote',
        href: RouteNames.downgradeQuote.path,
      });
    }

    const sideProps = {
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'common:label.quote',
          childs,
        },
      ],
    };

    const newSideProps = cloneDeep(sideProps);

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar sideProps={newSideProps} />
          <div className="content-wrapper">
            <Switch>
              <Route
                exact
                path={[RouteNames.searchQuoteAndList.path, RouteNames.searchQuoteAndListChildroute.path]}
                component={props => <SeachList {...props} permissions={permissions} />}
              />
              {/* <Route
                exact
                path={RouteNames.searchQuoteAndListChildroute.path}
                component={props => <SeachList {...props} permissions={permissions} />}
              /> */}
              <Route
                exact
                path={RouteNames.newQuote.path}
                component={props => <NewQuote {...props} permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.modifyQuote.path}
                component={props => <QuoteModify {...props} permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.upgradeQuote.path}
                component={props => <UpgradeQuote {...props} permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.downgradeQuote.path}
                component={props => <DowngradeQuote {...props} permissions={permissions} />}
              />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducerCustomer = injectReducer({ key: 'customerReducer', reducer: reducerCustomer });
const withSagaCustomer = injectSaga({ key: 'customerSaga', saga: sagaCustomer });
const withReducerOrder = injectReducer({ key: 'orderReducer', reducer: reducerOrder });
const withSagaOrder = injectSaga({ key: 'orderSaga', saga: sagaOrder });
const withReducer = injectReducer({ key: 'quoteReducer', reducer });
const withSaga = injectSaga({ key: 'quoteSaga', saga });

const mapStateToProps = createStructuredSelector({
  permissionQuote: makeGetPermissionsQuoteManagement() || {},
});

QuoteManagement.propTypes = {
  permissionQuote: PropTypes.objectOf(PropTypes.any),
};

QuoteManagement.defaultProps = {
  permissionQuote: {},
};

const withConnect = connect(mapStateToProps, null);

const newQuoteManagement = compose(
  withReducerCustomer,
  withSagaCustomer,
  withReducer,
  withSaga,
  withConnect,
  withReducerOrder,
  withSagaOrder
)(QuoteManagement);

export default withTranslation(['quotePage', 'common'])(newQuoteManagement);
