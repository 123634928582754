import { countryList } from '../../constantsApp/countryList';
import selectConstant from '../../utils/selectConstant';

const collapsibleMenuProfile = [
  {
    title: 'label.manageUserProfile',
    state: 'Manage User Profile',
  },
  {
    title: 'label.userContact',
    state: 'User Contacts',
  },
  {
    title: 'label.userAddress',
    state: 'User Address',
  },
];

const dataFiledAddress = {
  withAddressesUSForm: [
    {
      fieldName: 'street',
      labelFilter: 'common:label.street',
      type: 'textarea',
    },
    {
      fieldName: 'extraLine',
      labelFilter: 'common:label.extraLine',
    },
    {
      fieldName: 'landmark',
      labelFilter: 'common:label.landmark',
    },
    {
      labelFilter: 'common:label.country',
      fieldSelect: 'country',
      optionSelect: countryList,
    },
    {
      labelFilter: 'common:label.state',
      fieldSelect: 'state',
      optionSelect: selectConstant.stateUSData,
    },
    {
      labelFilter: 'common:label.city',
      fieldSelect: 'city',
      optionSelect: [],
      fieldName: 'city',
    },
    {
      labelFilter: 'common:label.postalCode',
      fieldSelect: 'postalCode',
      fieldName: 'postalCode',
      optionSelect: [],
    },
  ],

  withAddressesNormalForm: [
    {
      fieldName: 'street',
      labelFilter: 'common:label.street',
      type: 'textarea',
    },
    {
      fieldName: 'extraLine',
      labelFilter: 'common:label.extraLine',
    },
    {
      fieldName: 'landmark',
      labelFilter: 'common:label.landmark',
    },
    {
      labelFilter: 'common:label.country',
      fieldSelect: 'country',
      optionSelect: countryList,
    },
    {
      fieldName: 'state',
      labelFilter: 'common:label.state',
    },
    {
      labelFilter: 'common:label.city',
      fieldName: 'city',
    },
    {
      fieldName: 'postalCode',
      labelFilter: 'common:label.postalCode',
    },
  ],
};

const dataFiledContact = [
  {
    labelFilter: 'common:label.salutation',
    fieldName: 'salutation',
  },
  {
    labelFilter: 'common:label.firstName',
    fieldName: 'firstName',
  },
  {
    labelFilter: 'common:label.middleName',
    fieldName: 'middleName',
  },
  {
    labelFilter: 'common:label.lastName',
    fieldName: 'lastName',
  },
  {
    labelFilter: 'common:label.position',
    fieldName: 'position',
  },
  {
    labelFilter: 'common:label.organization',
    fieldName: 'organization',
  },
  {
    labelFilter: 'common:label.email',
    fieldName: 'email',
  },
  {
    labelFilter: 'common:label.phones',
    fieldPhone: 'phones',
  },
];

export { collapsibleMenuProfile, dataFiledAddress, dataFiledContact };
