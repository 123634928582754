import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import jobNS from './constants/jobNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, jobNS);
  const createJobConfig = `createJobConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createJobConfig} {
      id
      userId
      name
      configJobList {
        index
        jobNames
        userId
      }  
    }
  }
`;
};
