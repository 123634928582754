import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ ...filter }) => {
  const filterStr = filter ? convertFilterRequestUrl(filter, ['showActiveOnly']) : '';
  const getDepartment = `getDepartment(input: ${filterStr})`;
  return gql`
    query {
      ${getDepartment} {
        id
        index
        name
        description
        code
        enterprise
        businessUnit
        locationIndex
        status
        address {
          city
          state
          country
        }
      }
    }
  `;
};
