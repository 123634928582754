import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import PackageForm from './PackageForm';
import { selectPackageId } from '../../App/actions';
import { createPackage } from '../actions';
import { Breadcrumb } from '../../../components/common';

class PackageItemAdd extends Component {
  constructor(props) {
    const { location } = props;
    super(props);
    this.state = {
      isSubmitting: false,
      newPackage: location && location.state ? location.state : {},
      items: [],
      page: 1,
      size: 20,
      isSearching: false,
    };
  }

  componentDidMount() {}

  onCreatePackage = data => {
    const { createPackage } = this.props;
    this.setState({ isSubmitting: true });
    const { components, ...payload } = data;
    const newComponents = components
      ? components.map((e, idx) => {
          const { index, isNew, ...rest } = e;
          return {
            ...rest,
            index: idx + 1,
          };
        })
      : null;
    createPackage({ ...payload, components: newComponents }, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const { newPackage, isSubmitting } = this.state;
    const { t, location } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.packageItem.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.package'), url: RouteNames.packageItem.path },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addPackage')}</h5>
              </div>
              <PackageForm item={newPackage} onSubmit={this.onCreatePackage} isSubmitting={isSubmitting} creatingMode />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PackageItemAdd.propTypes = {
  createPackage: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};

PackageItemAdd.defaultProps = {
  location: {},
};

const mapDispatchToProps = dispatch => ({
  createPackage: (e, cb) => dispatch(createPackage(e, cb)),
  selectPackageId: (e, cb) => dispatch(selectPackageId(e, cb)),
});

const withConnect = connect(null, mapDispatchToProps);

export default withTranslation(['common', 'navbar'])(compose(withConnect)(PackageItemAdd));
