/**
 * Item Value Not String
 */
export default [
  'revenueType',
  'isBundled',
  'isDiscountable',
  'status',
  'revenueRecognitionType',
  'type',
  'index',
  'percent',
  'isProvisionable',
];
