const groupNamePermissions = {
  createItem: 'CREATE_ITEM',
  modifyItem: 'MODIFY_ITEM',
  approveItem: 'APPROVE_ITEM',
  sellableItem: 'SELLABLE_ITEM',
  nonSellableItem: 'NON_SELLABLE_ITEM',
  sunsetItem: 'SUNSET_ITEM',
  searchItems: 'SEARCH_ITEMS',
  getItem: 'GET_ITEM',
  createPriceOffer: 'CREATE_PRICE_OFFER',
  modifyPriceOffer: 'MODIFY_PRICE_OFFER',
  approvePriceOffer: 'APPROVE_PRICE_OFFER',
  sellablePriceOffer: 'SELLABLE_PRICE_OFFER',
  nonSellablePriceOffer: 'NON_SELLABLE_PRICE_OFFER',
  sunsetPriceOffer: 'SUNSET_PRICE_OFFER',
  searchPriceOffers: 'SEARCH_PRICE_OFFERS',
  getPriceOffer: 'GET_PRICE_OFFER',
  createDiscountOffer: 'CREATE_DISCOUNT_OFFER',
  modifyDiscountOffer: 'MODIFY_DISCOUNT_OFFER',
  approveDiscountOffer: 'APPROVE_DISCOUNT_OFFER',
  sellableDiscountOffer: 'SELLABLE_DISCOUNT_OFFER',
  nonSellableDiscountOffer: 'NON_SELLABLE_DISCOUNT_OFFER',
  sunsetDiscountOffer: 'SUNSET_DISCOUNT_OFFER',
  searchDiscountOffers: 'SEARCH_DISCOUNT_OFFERS',
  getDiscountOffer: 'GET_DISCOUNT_OFFER',
  createIteamServices: 'CREATE_ITEM_SERVICES',
  modifyItemServices: 'MODIFY_ITEM_SERVICES',
  searchItemServices: 'SEARCH_ITEM_SERVICES',
  CREATE_PRODUCT_FAMILY: 'CREATE_PRODUCT_FAMILY',
  MODIFY_PRODUCT_FAMILY: 'MODIFY_PRODUCT_FAMILY',
  SEARCH_PRODUCT_FAMILY: 'SEARCH_PRODUCT_FAMILY',
};

const onCheckSelectionsUsage = ({ pricing, transactionType }) => {
  const newSelectField = [];
  if (pricing && (pricing.salesChannel || pricing.marketSegment || pricing.accountType || pricing.accountSubType)) {
    newSelectField.push(1);
  }
  if (
    pricing &&
    (pricing.rateUnit || pricing.timeUnit || pricing.zoneUnit || pricing.impactUnit || pricing.holidayName)
  ) {
    newSelectField.push(3);
  }
  if (pricing && pricing.usageAttributePricingData) {
    newSelectField.push(2);
  }
  if (pricing && pricing.tiers && pricing.tiers.length) {
    newSelectField.push(4);
  }
  return newSelectField.length ? newSelectField : transactionType === 'USAGE' ? [1, 3] : [1];
};

export { groupNamePermissions, onCheckSelectionsUsage };
