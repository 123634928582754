import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSync = parseToMutationRequest(data, []);
  const syncTaskToExternal = `syncTaskToExternal(input: ${dataSync})`;
  return gql`
  mutation {
    ${syncTaskToExternal} {
      id
      accountId
      userId
      parentTaskId
      createdDate
      taskType
      taskCategory
      taskDescription
      ticketId
      status
      notes
      userFirstname
      userLastName
      organization
      userEmail
      accountType
      audits {
        id
        accountId
        userId
        parentTaskId
        modifiedDate
        taskType
        taskCategory
        taskDescription
        ticketId
        status
        notes
        userFirstname
        userLastName
        organization
        userEmail
        accountType
      }
    }
  }
`;
};
