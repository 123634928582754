import { groupNamePermissions } from './constant';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateCollectionAgentConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createCollectionAgentConfig });
};

const checkPermissionModifyCollectionAgentConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyCollectionAgentConfig });
};

const checkPermissionGetCollectionAgentConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getCollectionAgentConfig });
};

const checkPermissionCreateCollectionActionConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createCollectionActionConfig });
};

const checkPermissionModifyCollectionActionConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyCollectionActionConfig });
};

const checkPermissionGetCollectionActionConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getCollectionActionConfig });
};

const checkPermissionCreateCollectionSheduleConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createCollectionSheduleConfig });
};

const checkPermissionModifyCollectionSheduleConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyCollectionSheduleConfig });
};

const checkPermissionGetCollectionSheduleConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getCollectionSheduleConfig });
};

const checkPermissionSearchCollectionAgentActivity = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchCollectionAgentActivity });
};

const checkPermissionSearchAccountsInCollection = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchAccountsInCollection });
};

const checkPermissionReassignCollectionAgent = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.reassignCollectionAgent });
};

const checkPermissionSearchInvoiceUnitsInCollection = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchInvoiceUnitsInCollection });
};

const checkPermissionSearchCollectionUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchCollectionUnit });
};

const checkPermissionCreateCollectionProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_COLLECTION_PROFILE });
};

const checkPermissionModifyCollectionProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_COLLECTION_PROFILE });
};

const checkPermissionSearchCollectionProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_COLLECTION_PROFILE });
};

export {
  checkPermissionCreateCollectionAgentConfig,
  checkPermissionModifyCollectionAgentConfig,
  checkPermissionGetCollectionAgentConfig,
  checkPermissionCreateCollectionActionConfig,
  checkPermissionModifyCollectionActionConfig,
  checkPermissionGetCollectionActionConfig,
  checkPermissionCreateCollectionSheduleConfig,
  checkPermissionModifyCollectionSheduleConfig,
  checkPermissionGetCollectionSheduleConfig,
  checkPermissionSearchCollectionAgentActivity,
  checkPermissionSearchAccountsInCollection,
  checkPermissionReassignCollectionAgent,
  checkPermissionSearchInvoiceUnitsInCollection,
  checkPermissionSearchCollectionUnit,
  checkPermissionCreateCollectionProfile,
  checkPermissionModifyCollectionProfile,
  checkPermissionSearchCollectionProfile,
};
