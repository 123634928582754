import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPageTotalCount, formatMoneyValue } from '../../../utils/utils';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import constant from '../../../containers/CustomerPage/constants';
import { TablePagination, DataTable, ModalDetailsItem, FormCollapse, GenericInput } from '../../common';
import {
  makeGetTransactionUnit,
  makePageTransactionParams,
  makeErrorMessageTU,
} from '../../../containers/CustomerPage/selectors';
import TransactionsSearchForm from '../TransactionsSearchForm';
import {
  getTransactionUnit,
  setTransactionParams,
  getTransactionUnitById,
} from '../../../containers/CustomerPage/actions';
import ModalInvoice from '../../common/ModalInvoice';
import ModalAccountItems from '../../common/ModalAccountItems';

class TransactionsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: { accountId: props.id },
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      isOpenModal: false,
      isSearchDataModal: false,
      itemData: {},
      activeTab: {
        // name: 'Recurring Data',
        // isActive: true,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.transactionUnit }),
    };
  }

  componentDidMount() {
    this.doSearchTransactions();
  }

  doSearchTransactions = () => {
    const { getTransactionUnit } = this.props;
    const { page, size, filter, sorted } = this.state;
    this.setState({ isSearching: true });
    getTransactionUnit(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? constant.transactionSort[sorted.sortCol][sorted.sortDir] : null,
      },
      () => this.setState({ isSearching: false })
    );
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filter: newFilter }, () => this.doSearchTransactions());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchTransactions());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchTransactions());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchTransactions());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetTransactionById = id => {
    const { getTransactionUnitById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true, activeTab: {} });
    getTransactionUnitById(id, ({ data, success }) =>
      this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  renderItem = item => {
    const { itemData } = this.state;
    switch (item.state) {
      case 'Recurring Data':
        return (
          <div className="row col-md-12 p-2">
            {constant.dataFieldRecurringByTransactionId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.recurringData && itemData.recurringData[0] && itemData.recurringData[0][val.name]
                    ? itemData.recurringData[0][val.name]
                    : null
                }
                disabled
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Usage Data':
        return (
          <div className="row col-md-12 p-2">
            {constant.dataFieldUsageTransactionId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.txnUsageData && itemData.txnUsageData && itemData.txnUsageData[val.name]
                    ? val.isNumberFormat
                      ? formatMoneyValue(itemData.txnUsageData[val.name])
                      : itemData.txnUsageData[val.name]
                    : null
                }
                disabled
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableTaxDataByTransactionId} data={itemData.taxData ? itemData.taxData : []} />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12">
            <DataTable
              columns={constant.tableCurrencyTransactionById}
              data={itemData.balances ? itemData.balances : []}
            />
          </div>
        );
      case 'Grants':
        return (
          <div className="col-md-12">
            <DataTable
              columns={constant.tableGrantsByTransactionId}
              data={itemData.txnGrants ? itemData.txnGrants : []}
            />
          </div>
        );
      case 'Accumulator':
        return (
          <div className="col-md-12">
            <DataTable
              columns={constant.tableAccumulatorByTransactionId}
              data={itemData.txnAccumulators ? itemData.txnAccumulators : []}
            />
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { transactionUnit, id, t, currencyOptions, customerInfo, modeViewTransactionDetails } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      isOpenModal,
      itemData,
      isSearchDataModal,
      activeTab,
    } = this.state;

    let newCollapsibleMenu = constant.collapsibleMenuTransactionId;

    if (!itemData.type || ['FORWARD_RECURRING', 'ARREARS_RECURRING'].indexOf(itemData.type) === -1)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Recurring Data');
    if (!itemData.type || itemData.type !== 'USAGE')
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Usage Data');
    if (!itemData.type || itemData.type !== 'TAX')
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');
    if (!itemData.balances || !itemData.balances.length)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Currency');
    if (!itemData.txnGrants || !itemData.txnGrants.length)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Grants');
    if (!itemData.txnAccumulators || !itemData.txnAccumulators.length)
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Accumulator');

    const columns = constant.accountTransactions.billsTable;
    const additionalColumn = [];
    if (modeViewTransactionDetails) {
      additionalColumn.push({
        name: 'select',
        label: 'label.view',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetTransactionById(item.id)}
          >
            {t('label.view')}
          </button>
        ),
      });
    }
    const newColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) =>
          modeViewTransactionDetails ? (
            <button type="button" className="text-success no-border" onClick={() => this.doGetTransactionById(item.id)}>
              {item.id}
            </button>
          ) : (
            <span>{item.id}</span>
          ),
      },
      ...additionalColumn,
      ...columns,
    ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('label.transactions')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <TransactionsSearchForm
              onSubmit={this.onHandleSubmit}
              ModalSearch={props => {
                return (
                  <div>
                    <ModalInvoice
                      isOpen={props.isOpen === 'invoiceUnitId'}
                      onSelect={props.onSelect}
                      onCancel={props.onToggle}
                      accountId={id}
                    />
                    <ModalAccountItems
                      isOpen={props.isOpen === 'itemId'}
                      onSelect={props.onSelect}
                      onCancel={props.onToggle}
                      accountId={id}
                      invoiceUnitId={props.data && props.data.invoiceUnitId ? props.data.invoiceUnitId : ''}
                    />
                  </div>
                );
              }}
            />
            <br />
            <div>
              <DataTable
                columns={newColumns}
                data={transactionUnit}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={constant.dataFieldTransactionById}
          title={t('label.transactions')}
          isSearch={isSearchDataModal}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg"
          isBackBtn
        >
          <div className="pt-4">
            {newCollapsibleMenu.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={this.onToggleTab}
              >
                {this.renderItem(item)}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  transactionUnit: makeGetTransactionUnit() || [],
  params: makePageTransactionParams() || {},
  errorMessage: makeErrorMessageTU() || '',
});

TransactionsPage.propTypes = {
  transactionUnit: PropTypes.arrayOf(PropTypes.any),
  getTransactionUnit: PropTypes.func.isRequired,
  id: PropTypes.string,
};

TransactionsPage.defaultProps = {
  id: '',
  transactionUnit: [],
};

const newTransactionsPage = connect(mapStateToProps, {
  getTransactionUnit,
  setTransactionParams,
  getTransactionUnitById,
})(TransactionsPage);

export default withTranslation('common')(newTransactionsPage);
