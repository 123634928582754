import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchConfigCreditProfile = `searchConfigCreditProfile(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchConfigCreditProfile} {
        id
        customerSegment
        name
        salesChannel
        marketSegment
        accountType
        accountSubType
        profilesList {
          currency
          index
          creditLimit
          accumulatorId
          resourceId
          percentageThreshold
          limitBreachActions {
            index
            action
          }
        }
      }
    }
  `;
};
