import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, map, isEqual } from 'lodash';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../components/common';

const Handle = SortableHandle(() => (
  <button type="button" className="sortable-handle no-border">
    <i className="fa fa-bars" />
  </button>
));
const SortableItem = SortableElement(({ type, onRemove, sortingItemKey, onChangeType }) => {
  return (
    <tr className={`job-config-row ${sortingItemKey === type ? 'dragging' : ''}`}>
      <td>
        <Handle />
      </td>
      <td className="job-name-col" style={{ minWidth: 300 }}>
        <GenericInput
          value={type.dashboardType}
          tOptions="selections:dashboardType"
          type="select"
          wrapperClass="col-md-12"
          name="dashboardType"
          onChange={onChangeType}
          menuPortalTarget
        />
      </td>
      <td className="actions">
        <button type="button" className="no-border" onClick={onRemove}>
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ items, isSorting, sortingItemKey, onRemove, onChangeType }) => {
  return (
    <tbody>
      {items.map((type, index) => (
        <SortableItem
          key={`item-${index}`}
          isSorting={isSorting}
          sortingItemKey={sortingItemKey}
          index={index}
          onRemove={() => onRemove(index)}
          type={type}
          onChangeType={data => onChangeType({ ...data, index })}
        />
      ))}
    </tbody>
  );
});

export class DashboardFormConfig extends React.PureComponent {
  constructor() {
    super();
    this.state = { listConfig: [], oldData: [] };
  }

  static getDerivedStateFromProps(props, state) {
    if (isEqual(state.oldData, props.listConfig)) return null;
    return {
      listConfig: props.listConfig && props.listConfig.length ? props.listConfig : [],
      oldData: props.listConfig && props.listConfig.length ? props.listConfig : [],
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const { onChangeValueForm } = this.props;
    const { listConfig } = this.state;
    onChangeValueForm({ listItem: listConfig });
  };

  onAddDashboardTile = () => {
    const { listConfig } = this.state;
    const { t } = this.props;
    const newListConfig = cloneDeep(listConfig);
    let newDashboardType = cloneDeep(t('selections:dashboardType')());
    map(newListConfig, item => {
      newDashboardType = newDashboardType && newDashboardType.filter(val => val.value !== item.dashboardType);
    });
    if (!newDashboardType || !newDashboardType.length) return toast.error(t('message.noTypeDashboard'));
    newListConfig.push({
      index: newListConfig.length,
      dashboardType: newDashboardType[0].value,
    });
    return this.setState({ listConfig: newListConfig });
  };

  removeDashboardType = index => {
    const { listConfig } = this.state;
    const newData = cloneDeep(listConfig);
    newData.splice(index, 1);
    this.setState({ listConfig: newData });
  };

  handleUpdateBeforeSortStart = ({ index }) => {
    return new Promise(resolve =>
      this.setState(
        ({ listConfig }) => ({
          sortingItemKey: listConfig[index],
          isSorting: true,
        }),
        resolve
      )
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { listConfig } = this.state;
    const orderedList = cloneDeep(arrayMove(listConfig, oldIndex, newIndex));
    this.setState({
      listConfig: [...orderedList],
      isSorting: false,
      sortingItemKey: null,
    });
  };

  onChangeType = ({ value, index }) => {
    const { listConfig } = this.state;
    const { t } = this.props;
    const newData = cloneDeep(listConfig);
    const isExistValue = newData.findIndex(item => item.dashboardType === value) > -1;
    if (isExistValue) return toast.error(t('message.messageDashboardType'));
    newData[index].dashboardType = value;
    return this.setState({ listConfig: newData });
  };

  render() {
    const { listConfig, isSorting, sortingItemKey } = this.state;
    const { t, modeSaveUserPreferences } = this.props;
    const atStyle = { width: '100px' };
    return (
      <div className="form mt-3 mx-3 ">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="table-responsive sticky-header">
              <table className="table center-aligned-table mb-0">
                <thead>
                  <tr>
                    <th />
                    <th>{t('label.dashboardTile')}</th>
                    <th style={atStyle}>{t('label.remove')}</th>
                  </tr>
                </thead>
                {(!listConfig || listConfig.length === 0) && (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center">
                        {t('label.noDashboardTypes')}
                      </td>
                    </tr>
                  </tbody>
                )}
                <SortableList
                  items={listConfig || []}
                  useDragHandle
                  onSortStart={this.onSortStart}
                  onSortEnd={this.onSortEnd}
                  onRemove={this.removeDashboardType}
                  isSorting={isSorting}
                  sortingItemKey={sortingItemKey}
                  updateBeforeSortStart={this.handleUpdateBeforeSortStart}
                  onChangeType={this.onChangeType}
                />
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-center mb-3">
                      <button type="button" className="btn btn-success mb-3" onClick={this.onAddDashboardTile}>
                        {t('label.addADashboardTile')}
                      </button>
                    </td>
                  </tr>
                  {/* <tr className="last-item-config-dashboard">
                    <td colSpan="2" />
                    <td style={atStyle}>
                      {modeSaveUserPreferences !== 0 && (
                        <button
                          type="button"
                          disabled={modeSaveUserPreferences === 1}
                          className="btn btn-primary "
                          onClick={this.onSubmit}
                        >
                          {t('label.savePreference')}
                        </button>
                      )}
                    </td>
                  </tr> */}
                </tfoot>
              </table>
            </div>
            <div className="col-md-12">
              {modeSaveUserPreferences !== 0 && (
                <button
                  type="button"
                  disabled={modeSaveUserPreferences === 1}
                  className="btn btn-primary float-right"
                  onClick={this.onSubmit}
                >
                  {t('label.savePreference')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardFormConfig.propTypes = {
  onChangeValueForm: PropTypes.func,
  modeSaveUserPreferences: PropTypes.number,
};

DashboardFormConfig.defaultProps = {
  onChangeValueForm: () => {},
  modeSaveUserPreferences: 0,
};
export default withTranslation('common')(DashboardFormConfig);
