import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import RouteNames from '../../App/RouteNames';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
} from '../../../utils/utils';
import { uploadMultiPartFiles, getUserByIdDetails } from '../../App/actions';
import {
  DataTable,
  TablePagination,
  Breadcrumb,
  SwitchExport,
  ExcelExport,
  ButtonExport,
  EmailGroup,
  TitleFrom,
  ModalWithItem,
  GenericInput,
} from '../../../components/common';
import ModalAccountId from '../../../components/common/ModalAccountId';
import ModalSearchUsers from '../../../components/common/ModalSearchUsers';
import {
  SearchTasksList,
  SearchTasksListWithoutUserId,
  SearchTasksListWithoutAccountId,
  SearchTasksListWithoutAccountIdUserId,
  DragDropTableByColumns,
} from '../../../components/TaskManagement';
import { makeGetListTasks, makeGetListAllTasks } from '../selectors';
import {
  searchTasks,
  searchAllTasks,
  createTask,
  getTaskHistoryById,
  syncTaskToExternal,
  modifyTask,
  getChildTasksByParentId,
} from '../actions';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';
import { searchTaskColumns, taskSort, listFieldsCreateEditTask, tableChildTask, tableTaskHistory } from '../constants';
import { getUserId, getId } from '../../../localStorages';
import CreateTaskForm from './CreateTaskForm';
import ModalTaskDetailsForm from './ModalTaskDetailsForm';

const convertPayloadSubmit = item => {
  return {
    id: item?.id || null,
    accountId: item?.accountId || null,
    userId: item?.userId || null,
    parentTaskId: item?.parentTaskId || null,
    createdDate: item?.createdDate || null,
    taskType: item?.taskType || null,
    taskCategory: item?.taskCategory || null,
    taskDescription: item?.taskDescription || null,
    ticketId: item?.ticketId || null,
    status: item?.status || null,
    notes: item?.notes || null,
  };
};

class SearchList extends Component {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: {},
      sorted: {},
      totalCount: null,
      isSearching: false,
      dataSubmit: { userId: getUserId(), accountId: props.accountId || null, accountType: props.accountType || null },
      isOpenModalCreateTask: false,
      isOpenModalSelectAccount: false,
      isOpenModalUser: false,
      dataModify: {},
      isOpenModalViewChildTask: false,
      childTaskData: null,
      isOpenModalCreateChildTask: false,
      dataCreateChildTaks: { userId: getUserId() },
      isOpenModalViewChildTaskDetails: false,
      dataSubmitChildTask: {},
      listTasks: [],
      isTableView: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.listTasks) {
      return {
        totalCount: getPageTotalCount({
          ...state,
          totalCount: state.totalCount || props.listTasks.length,
          items: props.listTasks,
        }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchTasks();
      });
    } else {
      this.doSearchTasks();
    }
  }

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchTasks());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchTasks());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchTasks());
  };

  doSearchTasks = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchTasks, permissions, accountId } = this.props;
    const { modeSearchTask, modeTaskAdmin } = permissions || {};
    if (!modeSearchTask) return null;

    const newFilter = cloneDeep(filter);

    if (!modeTaskAdmin) newFilter.userId = getUserId() || null;
    if (accountId) newFilter.accountId = accountId || null;

    const sort =
      sorted && sorted.sortCol && sorted.sortDir && taskSort[sorted.sortCol]
        ? taskSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter: newFilter,
    };
    if (sort) {
      params.sort = sort;
    }
    return searchTasks(params, ({ success, data }) => {
      this.setState({ isSearching: false, page, size, filter, sort, listTasks: success ? data || [] : [] });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchTasks());
  };

  doGetAllListTasks = cb => {
    const { filter, sorted } = this.state;
    const { searchAllTasks, permissions, accountId } = this.props;
    const newFilter = cloneDeep(filter);
    const { modeSearchTask, modeTaskAdmin } = permissions || {};
    if (!modeSearchTask) return null;
    if (!modeTaskAdmin) newFilter.userId = getUserId() || null;
    if (accountId) newFilter.accountId = accountId || null;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? taskSort[sorted.sortCol][sorted.sortDir] : null,
    };
    return searchAllTasks(payload, () => {
      if (cb) cb();
    });
  };

  onExport = tableColumns => {
    this.doGetAllListTasks(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllTasks, t } = this.props;
        convertJson2Pdf({
          data: listAllTasks,
          t,
          title: t('taskPage:sidebar.tasks').toLocaleUpperCase(),
          fileName: `${t('taskPage:sidebar.tasks')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumns,
          fontSize: 5,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onChangeListView = () => {
    const { isTableView } = this.state;
    this.setState({ isTableView: !isTableView });
  };

  sendEmail = (tableColumns, { subject, emailBody }) => {
    this.doGetAllListTasks(() => {
      const { listAllTasks, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_OBJECT_FILES_REPORT',
          subject: subject || `Embrix Report ${t('taskPage:sidebar.tasks')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('taskPage:sidebar.tasks')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllTasks,
            t,
            columns: tableColumns,
            listOptionFields: {
              documentType: 'documentType',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllTasks,
          t,
          title: t('taskPage:sidebar.tasks').toLocaleUpperCase(),
          fileName: `${t('taskPage:sidebar.tasks')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumns,
          fontSize: 10,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('taskPage:sidebar.tasks')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  doGetUserByIdDetails = (id, isChildTask) => {
    const { getUserByIdDetails } = this.props;
    getUserByIdDetails(id || getId(), ({ success, data }) => {
      if (success) {
        if (isChildTask) {
          const { dataCreateChildTaks, dataSubmit } = this.state;
          dataCreateChildTaks.userEmail = data?.contact[0]?.email || null;
          dataCreateChildTaks.userFirstname = data?.contact[0]?.firstName || null;
          dataCreateChildTaks.userLastName = data?.contact[0]?.lastName || null;
          dataCreateChildTaks.organization = data?.contact[0]?.organization || null;
          dataCreateChildTaks.parentTaskId = dataSubmit?.id || null;
          this.setState({ dataCreateChildTaks: { ...dataCreateChildTaks } });
        } else {
          const { dataSubmit } = this.state;
          dataSubmit.userEmail = data?.contact[0]?.email || null;
          dataSubmit.userFirstname = data?.contact[0]?.firstName || null;
          dataSubmit.userLastName = data?.contact[0]?.lastName || null;
          dataSubmit.organization = data?.contact[0]?.organization || null;
          this.setState({ dataSubmit: { ...dataSubmit } });
        }
      }
    });
  };

  onToggleModalCreateTask = () => {
    const { isOpenModalCreateTask } = this.state;
    this.setState({ isOpenModalCreateTask: !isOpenModalCreateTask });
    if (!isOpenModalCreateTask) {
      this.doGetUserByIdDetails();
    }
  };

  onToggleModalCreateChildTask = () => {
    const { isOpenModalCreateChildTask } = this.state;
    this.setState({ isOpenModalCreateChildTask: !isOpenModalCreateChildTask });
    if (!isOpenModalCreateChildTask) {
      this.setState({ dataCreateChildTaks: { userId: getUserId() } });
      this.doGetUserByIdDetails(null, true);
    }
  };

  onToggleModalModifyTask = () => {
    const { isOpenModalModifyTask } = this.state;
    this.setState({ isOpenModalModifyTask: !isOpenModalModifyTask, dataSubmit: { userId: getUserId() } });
  };

  onToggleModalViewChildTask = () => {
    const { isOpenModalViewChildTask } = this.state;
    this.setState({ isOpenModalViewChildTask: !isOpenModalViewChildTask });
  };

  doGetChildTasksByParentId = () => {
    const { getChildTasksByParentId } = this.props;
    const { dataSubmit } = this.state;
    getChildTasksByParentId(dataSubmit?.id || '', ({ data }) => {
      this.setState({ childTaskData: data || null, isOpenModalViewChildTask: true });
    });
  };

  onClickItem = name => {
    if (name === 'accountId') {
      this.onToggleModalSelectAccount();
    }
    if (name === 'userId') {
      this.onToggleModalSelectUserId();
    }
  };

  onToggleModalSelectAccount = () => {
    const { isOpenModalSelectAccount } = this.state;
    this.setState({ isOpenModalSelectAccount: !isOpenModalSelectAccount });
  };

  onToggleModalSelectUserId = () => {
    const { isOpenModalUser } = this.state;
    this.setState({ isOpenModalUser: !isOpenModalUser });
  };

  onSelectUserId = (id, row) => {
    const {
      dataSubmit,
      dataCreateChildTaks,
      isOpenModalCreateChildTask,
      isOpenModalViewChildTaskDetails,
      dataSubmitChildTask,
    } = this.state;
    if (isOpenModalViewChildTaskDetails) {
      if (id) {
        dataSubmitChildTask.userId = id;
        if (row && row?.contact) {
          dataSubmitChildTask.userEmail = row?.contact[0]?.email || null;
          dataSubmitChildTask.userFirstname = row?.contact[0]?.firstName || null;
          dataSubmitChildTask.userLastName = row?.contact[0]?.lastName || null;
          dataSubmitChildTask.organization = row?.contact[0]?.organization || null;
        }
      }
      return this.setState({ isOpenModalUser: false, dataSubmitChildTask: { ...dataSubmitChildTask } });
    }
    if (isOpenModalCreateChildTask) {
      if (id) {
        dataCreateChildTaks.userId = id;
        if (row && row?.contact) {
          dataCreateChildTaks.userEmail = row?.contact[0]?.email || null;
          dataCreateChildTaks.userFirstname = row?.contact[0]?.firstName || null;
          dataCreateChildTaks.userLastName = row?.contact[0]?.lastName || null;
          dataCreateChildTaks.organization = row?.contact[0]?.organization || null;
        }
      }
      return this.setState({ isOpenModalUser: false, dataCreateChildTaks: { ...dataCreateChildTaks } });
    }

    if (id) {
      dataSubmit.userId = id;
      if (row && row?.contact) {
        dataSubmit.userEmail = row?.contact[0]?.email || null;
        dataSubmit.userFirstname = row?.contact[0]?.firstName || null;
        dataSubmit.userLastName = row?.contact[0]?.lastName || null;
        dataSubmit.organization = row?.contact[0]?.organization || null;
      }
    }
    return this.setState({ isOpenModalUser: false, dataSubmit: { ...dataSubmit } });
  };

  onSelectAccountId = (id, row) => {
    const {
      dataSubmit,
      dataCreateChildTaks,
      isOpenModalCreateChildTask,
      isOpenModalViewChildTaskDetails,
      dataSubmitChildTask,
    } = this.state;
    if (isOpenModalViewChildTaskDetails) {
      if (id) {
        dataSubmitChildTask.accountId = id;
        dataSubmitChildTask.accountType = row.type || null;
      }
      return this.setState({ isOpenModalSelectAccount: false, dataSubmitChildTask: { ...dataSubmitChildTask } });
    }
    if (isOpenModalCreateChildTask) {
      if (id) {
        dataCreateChildTaks.accountId = id;
        dataCreateChildTaks.accountType = row.type || null;
      }
      return this.setState({ isOpenModalSelectAccount: false, dataCreateChildTaks: { ...dataCreateChildTaks } });
    }
    if (id) {
      dataSubmit.accountId = id;
      dataSubmit.accountType = row.type || null;
    }
    return this.setState({ isOpenModalSelectAccount: false });
  };

  onChangeData = ({ name, value }) => {
    const { dataSubmit } = this.state;
    dataSubmit[name] = value;
    this.setState({ dataSubmit: { ...dataSubmit } });
  };

  onChangeDataSubmitChildTask = ({ name, value }) => {
    const { dataSubmitChildTask } = this.state;
    dataSubmitChildTask[name] = value;
    this.setState({ dataSubmitChildTask: { ...dataSubmitChildTask } });
  };

  onChangeChildTaskData = ({ name, value }) => {
    const { dataCreateChildTaks } = this.state;
    dataCreateChildTaks[name] = value;
    this.setState({ dataCreateChildTaks: { ...dataCreateChildTaks } });
  };

  onCreateTask = () => {
    const { createTask } = this.props;
    const { dataSubmit } = this.state;
    createTask(convertPayloadSubmit(dataSubmit || {}), ({ success }) => {
      if (success) {
        this.setState({ isOpenModalCreateTask: false, dataSubmit: { userId: getUserId() } });
        this.doSearchTasks();
      }
    });
  };

  onCreateChildTask = () => {
    const { createTask } = this.props;
    const { dataCreateChildTaks } = this.state;
    createTask(convertPayloadSubmit(dataCreateChildTaks || {}), ({ success }) => {
      if (success) {
        this.setState({ isOpenModalCreateChildTask: false, dataCreateChildTaks: { userId: getUserId() } });
        this.doGetChildTasksByParentId();
      }
    });
  };

  doGetTaskHistoryById = (id, isNoHiddenPopup) => {
    const { getTaskHistoryById } = this.props;
    if (!isNoHiddenPopup) this.onToggleModalModifyTask();
    getTaskHistoryById(id, ({ success, data }) => {
      if (success) {
        this.setState({ dataSubmit: data || {} });
      }
    });
  };

  onToggleModalChildModifyTask = () => {
    const { isOpenModalViewChildTaskDetails } = this.state;
    this.setState({ isOpenModalViewChildTaskDetails: !isOpenModalViewChildTaskDetails });
  };

  doGetChildTaskHistoryById = (id, isNoHiddenPopup) => {
    const { getTaskHistoryById } = this.props;
    if (!isNoHiddenPopup) this.onToggleModalChildModifyTask();
    getTaskHistoryById(id, ({ success, data }) => {
      if (success) {
        this.setState({ dataSubmitChildTask: data || {} });
      }
    });
  };

  doSyncTaskToExternal = ({ item, isMain, isMainTaskDetails, isFromChildTaskTable, isFromChildTaskDetails }) => {
    const { syncTaskToExternal } = this.props;
    const { listTasks, childTaskData, dataSubmit } = this.state;
    syncTaskToExternal(convertPayloadSubmit(item), ({ success, data }) => {
      if (success) {
        if (isMain) {
          const newListTask = cloneDeep(listTasks);
          const { audits, ...rest } = data;
          const indexModifyTask = newListTask.findIndex(val => val.id === data.id);
          if (indexModifyTask > -1) {
            newListTask[indexModifyTask] = rest;
          }
          this.setState({ listTasks: newListTask });
        }
        if (isMainTaskDetails) {
          const newListTask = cloneDeep(listTasks);
          const { audits, ...rest } = data;
          const indexModifyTask = newListTask.findIndex(val => val.id === data.id);
          if (indexModifyTask > -1) {
            newListTask[indexModifyTask] = rest;
          }
          this.setState({ dataSubmit: { audits, ...rest }, listTasks: newListTask });
        }

        if (isFromChildTaskTable) {
          const newChildTaskData = cloneDeep(childTaskData);
          const newDataSubmit = cloneDeep(dataSubmit);
          const { audits, ...rest } = data;
          newDataSubmit.audits = audits;
          const indexModifyChildTask = newChildTaskData.findIndex(val => val.id === data.id);
          if (indexModifyChildTask > -1) {
            newChildTaskData[indexModifyChildTask] = rest;
          }
          this.setState({ dataSubmit: newDataSubmit, childTaskData: newChildTaskData });
        }

        if (isFromChildTaskDetails) {
          const newChildTaskData = cloneDeep(childTaskData);
          const { audits, ...rest } = data;
          const indexModifyChildTask = newChildTaskData.findIndex(val => val.id === data.id);
          if (indexModifyChildTask > -1) {
            newChildTaskData[indexModifyChildTask] = rest;
          }
          this.setState({ dataSubmitChildTask: { ...data }, childTaskData: newChildTaskData });
        }
      }
    });
  };

  onModifyTask = ({ dataEdit }) => {
    const { modifyTask } = this.props;
    const { dataSubmit, listTasks } = this.state;
    modifyTask(convertPayloadSubmit(dataEdit || dataSubmit || {}), ({ success, data }) => {
      if (success && data) {
        const newListTask = cloneDeep(listTasks);
        const { audits, ...rest } = data;
        const indexModifyTask = newListTask.findIndex(val => val.id === data.id);
        if (indexModifyTask > -1) {
          newListTask[indexModifyTask] = rest;
        }
        this.setState({ dataSubmit: { audits, ...rest }, listTasks: newListTask });
      }
    });
  };

  onModifyChildTask = () => {
    const { modifyTask } = this.props;
    const { dataSubmitChildTask, childTaskData } = this.state;
    modifyTask(convertPayloadSubmit(dataSubmitChildTask || {}), ({ success, data }) => {
      if (success && data) {
        const newChildTaskData = cloneDeep(childTaskData);
        const { audits, ...rest } = data;
        const indexModifyTask = newChildTaskData.findIndex(val => val.id === data.id);
        if (indexModifyTask > -1) {
          newChildTaskData[indexModifyTask] = rest;
        }
        this.setState({ dataSubmitChildTask: { ...data }, childTaskData: newChildTaskData });
      }
    });
  };

  render() {
    const { permissions, t, location, listAllTasks, isFromCustomer, isTenant } = this.props;
    const {
      sorted,
      isSearching,
      page,
      totalCount,
      size,
      filter,
      emails,
      isActivePdf,
      isOpenModalCreateTask,
      dataSubmit,
      isOpenModalSelectAccount,
      isOpenModalUser,
      isOpenModalModifyTask,
      isOpenModalViewChildTask,
      childTaskData,
      isOpenModalCreateChildTask,
      dataCreateChildTaks,
      dataSubmitChildTask,
      isOpenModalViewChildTaskDetails,
      listTasks,
      isTableView,
    } = this.state;

    const {
      modeSearchTask,
      modeCreateTask,
      modeGetTask,
      modeCreateChildTask,
      modeGetChildTask,
      modeSyncTaskToExternal,
      modeTaskAdmin,
    } = permissions || {};

    const tableColumns = [
      {
        name: 'id',
        label: 'label.idHistory',
        render: (colName, item) => (
          <button type="button" className="text-success no-border" onClick={() => this.doGetTaskHistoryById(item.id)}>
            {item.id}
          </button>
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        sortable: true,
      },
      {
        name: 'ticketId',
        label: 'label.ticketId',
        style: { textAlign: 'center' },
        sortable: true,
        render: (colName, item) =>
          item.ticketId || !modeSyncTaskToExternal ? (
            <span>{item.ticketId}</span>
          ) : (
            <button
              type="button"
              className="button x-small"
              disabled={modeSyncTaskToExternal === 1}
              onClick={() => this.doSyncTaskToExternal({ item, isMain: true })}
            >
              {t ? t('label.create') : ''}
            </button>
          ),
      },
      {
        name: 'createdDate',
        label: 'label.date',
        sortable: true,
      },
      ...searchTaskColumns,
    ];

    const gridColumnsShow = [
      {
        name: 'id',
        label: 'label.idHistory',
        render: (colName, item) => (
          <button type="button" className="text-success no-border" onClick={() => this.doGetTaskHistoryById(item.id)}>
            {item.id}
          </button>
        ),
      },
      {
        name: 'ticketId',
        label: 'label.ticketId',
        style: { textAlign: 'center' },
        sortable: true,
        render: (colName, item) => <span>{item.ticketId}</span>,
      },
      {
        name: 'taskDescription',
        label: 'label.description',
        maxLength: 80,
      },
    ];

    const newTablecolumns = [
      ...tableColumns,
      {
        name: 'action',
        label: 'label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetTaskHistoryById(item.id)}
          >
            {t ? t('label.view') : ''}
          </button>
        ),
      },
    ];

    const tableChildTaskColumns = [
      {
        name: 'id',
        label: 'label.idHistory',
        render: (colName, item) => (
          <button
            type="button"
            className="text-success no-border"
            onClick={() => this.doGetChildTaskHistoryById(item.id)}
          >
            {item.id}
          </button>
        ),
      },
      {
        name: 'status',
        label: 'label.status',
      },
      {
        name: 'ticketId',
        label: 'label.ticketId',
        style: { textAlign: 'center' },
        sortable: true,
        render: (colName, item) =>
          item.ticketId || !modeSyncTaskToExternal ? (
            <span>{item.ticketId}</span>
          ) : (
            <button
              type="button"
              className="button x-small"
              disabled={modeSyncTaskToExternal === 1}
              onClick={() => this.doSyncTaskToExternal({ item, isFromChildTaskTable: true })}
            >
              {t ? t('label.create') : ''}
            </button>
          ),
      },
      ...tableChildTask,
    ];
    if (modeSearchTask === 0) return '';
    if (modeGetTask === 0) {
      newTablecolumns[0] = {
        name: 'id',
        label: 'Id',
      };
      newTablecolumns.splice(newTablecolumns.length - 1, 1);
    }

    const tableConfig = {
      columns: newTablecolumns,
      data: listTasks,
      sorted: { ...sorted },
      onSort: this.onSortColumn,
    };

    let dataSelect = isOpenModalCreateChildTask ? dataCreateChildTaks : dataSubmit;
    if (isOpenModalViewChildTaskDetails) dataSelect = dataSubmitChildTask;

    let SearchList = SearchTasksList;
    if (!modeTaskAdmin) SearchList = SearchTasksListWithoutUserId;
    if (!modeTaskAdmin && isFromCustomer && !isTenant) SearchList = SearchTasksListWithoutAccountIdUserId;
    if (modeTaskAdmin && isFromCustomer && !isTenant) SearchList = SearchTasksListWithoutAccountId;

    return (
      <div>
        {!isFromCustomer ? (
          <div className="page-title">
            <div className="row">
              <div className="col-sm-6">
                {modeCreateTask === 2 ? (
                  <button type="button" className="button x-small" onClick={this.onToggleModalCreateTask}>
                    {t('label.createTask')}
                  </button>
                ) : (
                  <Link
                    to={{
                      pathname: RouteNames.dashboard.path,
                      state: { filter },
                    }}
                    className="button x-small"
                  >
                    {t('label.back')}
                  </Link>
                )}
              </div>
              <div className="col-sm-6">
                <Breadcrumb items={[{ name: t('taskPage:sidebar.tasks') }]} />
              </div>
            </div>
          </div>
        ) : (
          modeCreateTask === 2 && (
            <div className="col-md-12 ml-3 mt-3">
              <button type="button" className="button x-small" onClick={this.onToggleModalCreateTask}>
                {t('label.addNew')}
              </button>
            </div>
          )
        )}
        <br />
        <div className="col-md-12 mb-30">
          <div className={isFromCustomer ? 'col-md-12' : 'card card-statistics h-100'}>
            {!isFromCustomer && <TitleFrom title={t('taskPage:sidebar.tasks')} />}
            <div className="card-body">
              <div className="pl-2">
                <SearchList
                  onSubmit={this.onSubmitSearch}
                  defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                />
              </div>
              <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                {isFromCustomer && (
                  <GenericInput
                    onChange={this.onChangeListView}
                    title={t('label.gridMode')}
                    value={isTableView}
                    rightTitle={t('label.tableMode')}
                    name="isTableView"
                    type="switch"
                    wrapperClass="col-md-3"
                  />
                )}
                {(isTableView || !isFromCustomer) && (
                  <>
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                    <>
                      <ButtonExport onExport={() => this.onExport(tableColumns)} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('taskPage:sidebar.tasks').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllTasks,
                          t,
                          title: t('taskPage:sidebar.tasks').toLocaleUpperCase(),
                          columnsTable: tableColumns,
                        })}
                        fileName={`${t('taskPage:sidebar.tasks')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>

                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={e => this.sendEmail(tableColumns, e)}
                      data={listTasks}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <div className={isFromCustomer && !isTableView ? 'col-md-12' : 'card card-statistics h-100'}>
            <div className="card-body">
              {!isTableView && isFromCustomer ? (
                <DragDropTableByColumns
                  exportColumns={listTasks || []}
                  tableColumns={gridColumnsShow}
                  onSubmit={this.onModifyTask}
                  onView={this.doGetTaskHistoryById}
                />
              ) : (
                <DataTable {...tableConfig} isLoading={isSearching} />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            isLoading={isSearching}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <CreateTaskForm
          listFields={listFieldsCreateEditTask(!!modeTaskAdmin, isFromCustomer && !isTenant).filter(
            item => !item.isNoSupportCreate
          )}
          data={dataSubmit}
          onChangeData={this.onChangeData}
          onClickItem={this.onClickItem}
          onSubmit={this.onCreateTask}
          onToggleModal={this.onToggleModalCreateTask}
          isOpenModal={isOpenModalCreateTask}
          title="label.addNew"
        />
        <ModalTaskDetailsForm
          title={t('label.taskDetails')}
          listFields={listFieldsCreateEditTask(!!modeTaskAdmin, isFromCustomer && !isTenant)}
          data={dataSubmit}
          onChangeData={this.onChangeData}
          onClickItem={this.onClickItem}
          onSubmit={this.onModifyTask}
          onToggleModal={this.onToggleModalModifyTask}
          isOpenModal={isOpenModalModifyTask}
          modeGetChildTask={modeGetChildTask}
          doGetChildTasksByParentId={this.doGetChildTasksByParentId}
          doSyncTaskToExternal={({ item }) => this.doSyncTaskToExternal({ item, isMainTaskDetails: true })}
          modeCreateChildTask={modeCreateChildTask}
          onToggleModalCreateChildTask={this.onToggleModalCreateChildTask}
          tableTaskHistory={tableTaskHistory}
          modeSyncTaskToExternal={modeSyncTaskToExternal}
        />

        <ModalTaskDetailsForm
          title={t('label.childTaskDetails')}
          listFields={listFieldsCreateEditTask(!!modeTaskAdmin)}
          data={dataSubmitChildTask}
          onChangeData={this.onChangeDataSubmitChildTask}
          onClickItem={this.onClickItem}
          onSubmit={this.onModifyChildTask}
          onToggleModal={this.onToggleModalChildModifyTask}
          isOpenModal={isOpenModalViewChildTaskDetails}
          doGetChildTasksByParentId={this.doGetChildTasksByParentId}
          modeGetChildTask={0}
          doSyncTaskToExternal={({ item }) => this.doSyncTaskToExternal({ item, isFromChildTaskDetails: true })}
          modeCreateChildTask={0}
          tableTaskHistory={tableTaskHistory}
          modeSyncTaskToExternal={modeSyncTaskToExternal}
        />
        <ModalWithItem
          wrapperClass="modal-70 modal-custom"
          modalTitle={t('label.childTasks')}
          isOpen={isOpenModalViewChildTask}
          onToggle={this.onToggleModalViewChildTask}
        >
          <div className="col-md-12 mb-2">
            <h6 className="mt-2 mb-2">{t('label.parent')}</h6>
          </div>
          <div className="col-md-12 row">
            {listFieldsCreateEditTask(!!modeTaskAdmin).map(item => {
              if (item.name === 'ticketId') {
                return (
                  <GenericInput
                    {...item}
                    value={dataSubmit[item.name] || null}
                    disabled
                    onClick={item.fa ? () => this.onClickItem(item.name) : null}
                    onChange={this.onChangeData}
                    // labelRightButton="label.create"
                    // isBtnRight={!dataSubmit[item.name]}
                    readOnly
                    // onClickRightButton={() => this.doSyncTaskToExternal({ item: dataSubmit, isNeedFetchById: true })}
                  />
                );
              }
              return (
                <GenericInput
                  {...item}
                  value={dataSubmit[item.name] || null}
                  readOnly
                  disabled
                  onClick={item.fa ? () => this.onClickItem(item.name) : null}
                  onChange={this.onChangeData}
                />
              );
            })}
          </div>
          <div className="col-md-12 border-top mb-4 mt-3">
            <h6 className="mt-2 mb-2">{t('label.childTasks')}</h6>
            <DataTable columns={tableChildTaskColumns} data={childTaskData || []} />
          </div>
          <div className="text-center mt-3 mb-3 col-md-12">
            <button
              type="button"
              className="button button-border black x-small mr-2 float-right"
              onClick={this.onToggleModalViewChildTask}
            >
              {t('label.cancel')}
            </button>
          </div>
        </ModalWithItem>
        <CreateTaskForm
          listFields={listFieldsCreateEditTask(!!modeTaskAdmin).map(val => {
            const { name, ...rest } = val;
            return { ...rest, name, readOnly: name === 'parentTaskId' };
          })}
          data={dataCreateChildTaks}
          onChangeData={this.onChangeChildTaskData}
          onClickItem={this.onClickItem}
          onSubmit={this.onCreateChildTask}
          onToggleModal={this.onToggleModalCreateChildTask}
          isOpenModal={isOpenModalCreateChildTask}
          title="label.createNewChildTask"
        >
          <div className="col-md-12 mb-2">
            <h6 className="mt-2 mb-2">{t('label.parent')}</h6>
          </div>
          <div className="col-md-12 row">
            {listFieldsCreateEditTask(!!modeTaskAdmin).map(item => {
              if (item.name === 'ticketId') {
                return (
                  <GenericInput
                    {...item}
                    value={dataSubmit[item.name] || null}
                    disabled
                    onClick={item.fa ? () => this.onClickItem(item.name) : null}
                    onChange={() => {}}
                    // labelRightButton="label.create"
                    // isBtnRight={!dataSubmit[item.name]}
                    readOnly
                    // onClickRightButton={() => this.doSyncTaskToExternal({ item: dataSubmit, isNeedFetchById: true })}
                  />
                );
              }
              return (
                <GenericInput
                  {...item}
                  value={dataSubmit[item.name] || null}
                  readOnly
                  disabled
                  onClick={item.fa ? () => this.onClickItem(item.name) : null}
                  onChange={this.onChangeData}
                />
              );
            })}
          </div>
          <div className="col-md-12 mb-2 mt-3 border-top">
            <h6 className="mt-2 mb-2">{t('label.createChildTask')}</h6>
          </div>
        </CreateTaskForm>

        <ModalAccountId
          isOpen={isOpenModalSelectAccount}
          onSelect={this.onSelectAccountId}
          onCancel={this.onToggleModalSelectAccount}
          selectedParentId={dataSelect ? dataSelect.accountId : null}
        />
        <ModalSearchUsers
          isOpen={isOpenModalUser}
          onSelect={this.onSelectUserId}
          onCancel={this.onToggleModalSelectUserId}
          selectedParentId={dataSelect ? dataSelect.userId : null}
        />
      </div>
    );
  }
}

SearchList.propTypes = {
  listTasks: PropTypes.arrayOf(PropTypes.any),
  listAllTasks: PropTypes.arrayOf(PropTypes.any),
  permissions: PropTypes.arrayOf(PropTypes.any),
  searchTasks: PropTypes.func.isRequired,
  searchAllTasks: PropTypes.func.isRequired,
  uploadMultiPartFiles: PropTypes.func.isRequired,
  getUserByIdDetails: PropTypes.func.isRequired,
  getChildTasksByParentId: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  getTaskHistoryById: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
  modifyTask: PropTypes.func.isRequired,
  syncTaskToExternal: PropTypes.func.isRequired,
};

SearchList.defaultProps = {
  listTasks: [],
  listAllTasks: [],
  permissions: [],
  location: {},
  history: {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  listTasks: makeGetListTasks() || [],
  listAllTasks: makeGetListAllTasks() || [],
});

const newSearchList = connect(mapStateToProps, {
  searchTasks,
  searchAllTasks,
  uploadMultiPartFiles,
  getUserByIdDetails,
  createTask,
  getTaskHistoryById,
  syncTaskToExternal,
  modifyTask,
  getChildTasksByParentId,
})(SearchList);

export default withTranslation(['common', 'taskPage'])(newSearchList);
