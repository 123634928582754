import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['status', 'chargeType', 'isParent'])}` : '';
  const searchItemServices = `searchItemServices(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchItemServices} {
        id
        name
        description
        serviceType
        serviceProductCode
        uom
        chargeType
        status
        isParent
        isChild
        itemId
        createdDate
        uomName
        glAccount
        taxCode
    }
  }
  `;
};
