import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import TitleFrom from '../../../../components/common/TitleFrom';
import { GenericInput, PasswordField } from '../../../../components/common';
import dataField from '../../constant';

class HttpBasicAttributesForm extends React.PureComponent {
  state = { tenantData: [{}], isReadOnly: true };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ tenantData: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ tenantData: nextProps.data });
    }
  }

  onChange = ({ value, name }) => {
    try {
      const { onHandleChange, index } = this.props;
      const { tenantData } = this.state;
      const newData = cloneDeep(tenantData);
      newData[0][name] = value;
      onHandleChange({ value, name, index, indexAttr: 0, fieldName: 'httpBasicAttributes', isNotIndex: true });
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onFocusPasswordField = () => {
    this.setState({ isReadOnly: false });
  };

  render() {
    const { tenantData, isReadOnly } = this.state;
    const { modeModifyTaxGatewayMerchant, isRequireField, t } = this.props;
    const dataHttpAttr = tenantData[0];

    return (
      <div className="col-md-12 sub-from">
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.httpBasicAttributes')} />
          </div>
        </div>
        <div className="group-flex">
          {dataField.fieldHttpBasicForm.map(item => {
            if (item.isOptionType)
              return (
                <PasswordField
                  key={item.name}
                  label={item.label}
                  value={dataHttpAttr ? dataHttpAttr[item.name] : ''}
                  required={isRequireField || item.required}
                  onChange={this.onChange}
                  disabled={modeModifyTaxGatewayMerchant === 1}
                  name={item.name}
                  wrapperClass="col-md-6"
                  readOnly={isReadOnly}
                  onFocus={this.onFocusPasswordField}
                />
              );
            return (
              <GenericInput
                key={item.name}
                label={item.label}
                value={dataHttpAttr ? dataHttpAttr[item.name] : ''}
                required={isRequireField || item.required}
                type={item.type || 'text'}
                disabled={modeModifyTaxGatewayMerchant === 1}
                onChange={this.onChange}
                name={item.name}
                wrapperClass="col-md-6"
              />
            );
          })}
        </div>
      </div>
    );
  }
}

HttpBasicAttributesForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  modeModifyTaxGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

HttpBasicAttributesForm.defaultProps = {
  data: [{}],
  onHandleChange: () => {},
  modeModifyTaxGatewayMerchant: 2,
  isRequireField: false,
};

export default withTranslation('common')(HttpBasicAttributesForm);
