import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size, cloneDeep, orderBy, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getPaymentItemMap, modifyPaymentItemMap, createPaymentItemMap, getBatchPaymentFileConfig } from '../actions';
import { makeGetPaymentItemMap } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import ModalSearchItem from '../../../components/common/ModalSearchItem';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import {
  checkPermissionGetPaymentItemMap,
  checkPermissionModifyPaymentItemMap,
  checkPermissionCreatePaymentItemMap,
} from '../CheckPermission';

class PaymentItemMap extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    configPaymentItemMapList: [],
    wasValidated: false,
    fileNameOptions: [],
    isOpenModalItem: false,
    itemSelected: null,
    indexSelected: null,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.itemMap) return null;
    return {
      configPaymentItemMapList: props.itemMap && props.itemMap.paymentItemMaps ? props.itemMap.paymentItemMaps : [],
      oldValue: props.itemMap,
      dataSubmit: [],
    };
  }

  componentDidMount() {
    this.doGetPaymentItemMap();
    const { getBatchPaymentFileConfig } = this.props;
    getBatchPaymentFileConfig('', ({ data, success }) => {
      if (success && data && data.files) {
        const fileNameOptions = data.files.map(val => {
          return { value: val.name, label: val.name };
        });

        this.setState({ fileNameOptions: fileNameOptions ? sortBy(fileNameOptions, ['label']) : [] });
      }
    });
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index, cb }) => {
    const { configPaymentItemMapList, dataSubmit } = this.state;
    const newData = cloneDeep(configPaymentItemMapList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ configPaymentItemMapList: newData }, () => {
      if (cb) cb();
    });
  };

  doGetPaymentItemMap = () => {
    const { getPaymentItemMap } = this.props;
    getPaymentItemMap('', () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewProfile = () => {
    let indexNewItem = 0;
    const { configPaymentItemMapList, dataSubmit } = this.state;
    const newData = cloneDeep(configPaymentItemMapList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      itemId: '',
      paymentType: null,
      fileName: null,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ configPaymentItemMapList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { configPaymentItemMapList, dataSubmit } = this.state;
    const newData = cloneDeep(configPaymentItemMapList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ configPaymentItemMapList: newData, dataSubmit });
  };

  onSelectItemId = (id, row) => {
    const { indexSelected } = this.state;
    this.onChangeTable({
      index: indexSelected,
      name: 'itemId',
      value: id,
      cb: () => {
        this.setState({ indexSelected: null, isOpenModalItem: false, itemSelected: null });
      },
    });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { configPaymentItemMapList } = this.state;
    const { modifyPaymentItemMap, createPaymentItemMap, itemMap } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (itemMap && itemMap.id) {
      const newPayload = {
        id: itemMap.id,
        paymentItemMaps: configPaymentItemMapList,
      };
      return modifyPaymentItemMap(newPayload, ({ success }) => {
        if (success) this.doGetPaymentItemMap();
      });
    }

    const newPayloadCreate = cloneDeep(configPaymentItemMapList).map(item => {
      const newItem = item;
      delete newItem.index;
      return newItem;
    });
    createPaymentItemMap({ paymentItemMaps: newPayloadCreate, name: 'PaymentItemMap' }, ({ success }) => {
      if (success) this.doGetPaymentItemMap();
    });
  };

  render() {
    const { configPaymentItemMapList, isSearching, wasValidated, fileNameOptions, isOpenModalItem } = this.state;
    const { itemMap, permissionsPayment, t } = this.props;
    let modeGetPaymentItemMap = 0;
    let modeModifyPaymentItemMap = 0;
    let modeCreatePaymentItemMap = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermissionsPayment = permissionsPayment.paymentModulePermissions;
      modeGetPaymentItemMap = checkPermissionGetPaymentItemMap({
        listPermission: listPermissionsPayment,
      });
      modeCreatePaymentItemMap = checkPermissionCreatePaymentItemMap({
        listPermission: listPermissionsPayment,
      });
      modeModifyPaymentItemMap = checkPermissionModifyPaymentItemMap({
        listPermission: listPermissionsPayment,
      });
    }

    if (!modeGetPaymentItemMap) return '';

    const PaymentItemMapColumns = [
      {
        name: 'fileType',
        label: t('label.fileType'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.paymentType}
            tOptions="selections:batchPaymentType"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="paymentType"
            required
          />
        ),
      },
      {
        name: 'fileName',
        label: t('label.name'),
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.fileName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="fileName"
            type="select"
            options={fileNameOptions}
            required
            menuPortalTarget
          />
        ),
      },
      {
        name: 'itemId',
        label: t('label.itemId'),
        style: { textAlign: 'center', minWidth: '110px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.itemId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="itemId"
            onClick={() => {
              this.setState({ isOpenModalItem: true, indexSelected: item.index, itemSelected: item });
            }}
            required
            fa="fa fa-external-link"
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataOnlyViewInTable =
      configPaymentItemMapList && configPaymentItemMapList.length
        ? orderBy(
            configPaymentItemMapList.filter(item => size(item) > 1),
            ['index'],
            ['asc']
          )
        : [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentAdmin'), url: RouteNames.paymentConfiguration.path },
            { name: t('label.paymentItemMap') },
          ]}
        />
        <FormWithTableItem title={t('label.paymentItemMap')}>
          <form
            onSubmit={modeModifyPaymentItemMap === 2 ? this.hanldeSubmit : () => {}}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <br />
            <div>
              <DataTable columns={PaymentItemMapColumns} data={newDataOnlyViewInTable} isLoading={isSearching} />
            </div>
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreatePaymentItemMap === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProfile}>
                  +
                  {t('label.addNewItemMapping')}
                </button>
              )}
              {modeModifyPaymentItemMap === 2 && (
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                >
                  {itemMap && itemMap.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetPaymentItemMap}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </FormWithTableItem>
        <ModalSearchItem
          isOpen={isOpenModalItem}
          onSelect={this.onSelectItemId}
          onCancel={() => {
            this.setState({ isOpenModalItem: false, indexSelected: null, itemSelected: null });
          }}
          defaultSearchParams={{ type: 'RECEIVABLE' }}
          selectedParentId={
            this.state && this.state.itemSelected && this.state.itemSelected.itemId
              ? this.state.itemSelected.itemId
              : ''
          }
        />
      </div>
    );
  }
}

PaymentItemMap.propTypes = {
  itemMap: PropTypes.objectOf(PropTypes.any),
  getPaymentItemMap: PropTypes.func.isRequired,
  createPaymentItemMap: PropTypes.func.isRequired,
  modifyPaymentItemMap: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

PaymentItemMap.defaultProps = {
  itemMap: {},
  permissionsPayment: {},
};
const mapStateToProps = createStructuredSelector({
  // PaymentItemMap: makeGetAdvanceBillingConfig() || {},
  itemMap: makeGetPaymentItemMap() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

const newPaymentItemMap = connect(mapStateToProps, {
  getPaymentItemMap,
  modifyPaymentItemMap,
  createPaymentItemMap,
  getBatchPaymentFileConfig,
})(PaymentItemMap);

export default withTranslation('common')(newPaymentItemMap);
