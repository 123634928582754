import * as types from './types';

export function getUserById( cb) {
  return {
    type: types.GET_USER_BY_ID,
    cb,
  };
}

export function getUserByIdSuccess(payload) {
  return {
    type: types.GET_USER_BY_ID_SUCCESS,
    payload,
  };
}

export function modifyUser(payload) {
  return {
    type: types.MODIFY_USER,
    payload,
  };
}

