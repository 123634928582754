import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { GenericInput } from '../common';
import TitleFrom from '../common/TitleFrom';
import AccountHeaderFrom from '../common/AccountHeaderFrom';
import PhoneGroup from '../PhoneGroup';
import CheckFieldRole from './CheckFieldRole';

export default function CustomerNoForm({
  dataField,
  onHandleChange,
  onChangeCheckBox,
  onChangePhone,
  colItem,
  title,
  accountNum,
  children,
  modeModifyPhone,
  modeAddPhone,
  modeDeletePhone,
  fieldObj,
  isNewAccount,
  isDisabled,
  isReferenceSwitch,
  isRenderSwitch,
  checkRoleGroup,
  onChangeSwitch,
  accountDetail,
  indexForm,
  messageRole,
  onSelectCreditProfileIndex,
  onSelectCollectionAgent,
  t,
  currencyOptions,
  customerInfo,
  isLeftAddPhoneBtn,
  isShowPhones,
  onChangeIsShowPhone,
  showPhoneName,
  colDivPhone,
  onClickItem,
}) {
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  return (
    <div className="col-md-12 mb-30 form-container">
      <div className="card card-statistics">
        {title && <TitleFrom title={title} />}
        {accountNum && (
          <AccountHeaderFrom
            accountNum={accountNum}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
        )}
        <div className="card-body">
          {isRenderSwitch && (
            <div className="switch-group">
              <div className="checkbox checbox-switch switch-danger">
                <label htmlFor={fieldObj}>
                  <b>
                    {t('common:label.referenceParent')}
:
                  </b>
                  <input
                    id={fieldObj}
                    type="checkbox"
                    name="switch21"
                    onChange={() => onChangeSwitch(fieldObj)}
                    checked={isReferenceSwitch}
                  />
                  <span className="label-switch" />
                </label>
              </div>
            </div>
          )}
          <div className="group-flex">
            {!isEmpty(dataField) &&
              map(dataField, item => {
                if (item && (!isEmpty(item.optionSelect) || item.tOptions)) {
                  let { tOptions, ...newItem } = item;
                  if (!isEmpty(item.optionSelect)) {
                    tOptions = null;
                  }
                  return (
                    <GenericInput
                      key={item.labelFilter}
                      label={t && item.labelFilter ? t(item.labelFilter) : item.labelFilter || ''}
                      type="select"
                      value={accountDetail[item.fieldSelect] || null}
                      wrapperClass={colItem}
                      options={item.optionSelect}
                      onChange={({ name, value }) => onHandleChange({ value, name, fieldObj })}
                      name={item.fieldSelect}
                      disabled={isDisabled || newItem.readOnly}
                      tOptions={tOptions}
                      {...newItem}
                    />
                  );
                }
                if (item && item.fieldDate) {
                  return (
                    <GenericInput
                      type="date"
                      isClearable
                      disabled={item.isDisable || isDisabled}
                      tooltipDetails={t(item.tooltipDetails) || null}
                      name={item.fieldDate}
                      key={item.labelFilter}
                      wrapperClass={colItem}
                      label={t && item.labelFilter ? t(item.labelFilter) : item.labelFilter || ''}
                      dateFormat="yyyy-MM-dd"
                      value={!isEmpty(accountDetail) ? accountDetail[item.fieldDate] : ''}
                      onChange={({ name, value }) => onHandleChange({ value, name, fieldObj })}
                    />
                  );
                }

                if (item && item.fieldPhone) {
                  return (
                    <>
                      <GenericInput
                        label="common:label.phones"
                        value={isShowPhones}
                        name={`isShowPhones-${showPhoneName}`}
                        onChange={onChangeIsShowPhone}
                        wrapperClass="ml-3 mt-4 pt-2"
                        type="switch"
                      />
                      {isShowPhones && (
                        <div className="col-md-12">
                          <PhoneGroup
                            key={item.labelFilter}
                            dataPhone={accountDetail[item.fieldPhone]}
                            onChange={onChangePhone}
                            colDiv={colDivPhone || 'col-md-4'}
                            modeModifyPhone={modeModifyPhone}
                            modeAddPhone={modeAddPhone}
                            modeDeletePhone={modeDeletePhone}
                            isNewAccount={isNewAccount}
                            isDisabled={isDisabled}
                            indexForm={indexForm}
                            isLeftAddPhoneBtn={isLeftAddPhoneBtn}
                            isRequiredPhoneNumber
                          />
                        </div>
                      )}
                    </>
                  );
                }
                let onSearchItem = null;
                if (item.fieldName === 'creditProfileIndex') {
                  onSearchItem = onSelectCreditProfileIndex;
                }
                if (item.fieldName === 'collectionAgent') {
                  onSearchItem = onSelectCollectionAgent;
                }
                if (onClickItem) {
                  onSearchItem = onClickItem;
                }
                return (
                  <GenericInput
                    key={item.labelFilter}
                    value={accountDetail[item.fieldName] ? accountDetail[item.fieldName] : '' || ''}
                    label={t && item.labelFilter ? t(item.labelFilter) : item.labelFilter || ''}
                    type={item.type || 'text'}
                    required={item.required}
                    onChange={({ name, value }) => onHandleChange({ value, name, fieldObj })}
                    name={item.fieldName}
                    wrapperClass={item.itemClassName ? `${item.itemClassName}` : colItem}
                    disabled={item.isDisable || isDisabled}
                    fa={item.fa}
                    onClick={item.fa ? onSearchItem : () => {}}
                    readOnly={item.readOnly}
                    activeAttr={t(item.activeAttr)}
                    deactiveAttr={t(item.deactiveAttr)}
                    minValue={item.minValue}
                    maxValue={item.maxValue}
                    tooltipDetails={item.tooltipDetails ? t(item.tooltipDetails) : null}
                  />
                );
              })}
            <div className="form-group col-md-12 check-group">
              {!isEmpty(checkRoleGroup) && (
                <span>
                  {t('common:label.roles')}
                  {isNewAccount && <i className="fa fa-star is-required" />}
                </span>
              )}
              {!isEmpty(checkRoleGroup) &&
                map(checkRoleGroup, (item, index) => (
                  <CheckFieldRole
                    validate={messageRole && index === 0 ? messageRole : ''}
                    key={item.label}
                    label={t && item.label ? t(item.label) : item.label || ''}
                    className={item.className}
                    name={item.value}
                    disabled={isDisabled}
                    value={accountDetail.roles && accountDetail.roles.indexOf(item.value) > -1}
                    onChange={({ name }) => onChangeCheckBox(name, fieldObj)}
                  />
                ))}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerNoForm.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  accountDetail: PropTypes.objectOf(PropTypes.any),
  customerInfo: PropTypes.objectOf(PropTypes.any),
  indexForm: PropTypes.number,
  onChangeCheckBox: PropTypes.func,
  onHandleChange: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  onChangePhone: PropTypes.func,
  onChangeSwitch: PropTypes.func,
  onSelectCreditProfileIndex: PropTypes.func,
  onSelectCollectionAgent: PropTypes.func,
  checkRoleGroup: PropTypes.arrayOf(PropTypes.object),
  colItem: PropTypes.string,
  isDisabled: PropTypes.bool,
  isNewAccount: PropTypes.bool,
  isReferenceSwitch: PropTypes.bool,
  isRenderSwitch: PropTypes.bool,
  wasValidated: PropTypes.bool,
  title: PropTypes.string,
  fieldObj: PropTypes.string,
  accountNum: PropTypes.string || PropTypes.number,
  messageRole: PropTypes.string,
  modeModifyPhone: PropTypes.number,
  modeAddPhone: PropTypes.number,
  modeDeletePhone: PropTypes.number,
  currencyOptions: PropTypes.arrayOf(PropTypes.object),
};

CustomerNoForm.defaultProps = {
  colItem: '',
  title: '',
  dataField: [],
  accountDetail: {},
  customerInfo: {},
  checkRoleGroup: [],
  isDisabled: false,
  isNewAccount: false,
  isReferenceSwitch: false,
  isRenderSwitch: false,
  wasValidated: false,
  fieldObj: '',
  indexForm: 0,
  onChangeCheckBox: () => {},
  onHandleChange: () => {},
  onChangePhone: () => {},
  onHandleSubmit: () => {},
  onChangeSwitch: () => {},
  onSelectCreditProfileIndex: () => {},
  onSelectCollectionAgent: () => {},
  accountNum: '' || null,
  messageRole: '',
  modeModifyPhone: 2,
  modeAddPhone: 2,
  modeDeletePhone: 2,
  currencyOptions: [],
};
