import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import TenantPage from './TenantPage';
import { makeGetPermissionsTenantManagement } from '../App/selectors';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import taskReducer from '../TaskManagement/reducer';
import taskSaga from '../TaskManagement/saga';

class TenantManagement extends PureComponent {
  render() {
    const { permissionsTenant } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsTenant, listKeys: permissionList.tenantManagement }))
      return null;

    return (
      <Switch>
        <Route exact path="" component={TenantPage} />
      </Switch>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsTenant: makeGetPermissionsTenantManagement() || {},
});

const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({ key: 'tenantManagementReducer', reducer });
const withSaga = injectSaga({ key: 'tenantManagementSaga', saga });

const withReducerTask = injectReducer({ key: 'taskReducer', reducer: taskReducer });
const withSagaTask = injectSaga({ key: 'taskSaga', saga: taskSaga });

export default compose(withReducer, withSaga, withReducerTask, withSagaTask, withConnect)(TenantManagement);
