import gql from 'graphql-tag';

export default () => {
  const getCOA = 'getCOA(dummy: "")';
  return gql`
    query {
      ${getCOA} {
        id
        name
        description
        type
        coaType
        glAccounts {
          index
          name
          description
          glAccount
          glAccountSubType
          glAccountType
        }
      }
    }
  `;
};
