import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class BillableServicesForecastSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'label.billableServiceId',
      name: 'billableServiceId',
    },
    {
      label: 'label.billableServiceName',
      name: 'billableServiceName',
    },
    {
      label: 'label.chargeType',
      name: 'chargeType',
      type: 'select',
      tOptions: 'selections:chargeType',
      isClearable: true,
    },
    {
      label: 'label.billingDate',
      name: 'billingdate',
      type: 'date',
    },
    {
      label: 'label.invoiceExhibits',
      name: 'invoiceExhibits',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
      isNeedClearable: true,
    },
    {
      label: 'label.unAssignedCutOff',
      name: 'unAssignedCutOff',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
      isNeedClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

BillableServicesForecastSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BillableServicesForecastSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BillableServicesForecastSearchForm);
