import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateCompanyConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_LOCATION_CONFIG });
};

const checkPermissionGetCompanyConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_LOCATION_CONFIG });
};

const checkPermissionModifyCompanyConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_LOCATION_CONFIG });
};

const checkPermissionCreateCurrencyExchangeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_CURRENCY_EXCHANGE_CONFIG });
};

const checkPermissionGetCurrencyExchangeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_CURRENCY_EXCHANGE_CONFIG });
};

const checkPermissionModifyCurrencyExchangeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_CURRENCY_EXCHANGE_CONFIG });
};

const checkPermissionCreateMilestoneConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_MILESTONE_CONFIG });
};

const checkPermissionGetMilestoneConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_MILESTONE_CONFIG });
};

const checkPermissionModifyMilestoneConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_MILETSTONE_CONFIG });
};

const checkPermissionCreateGLAccountConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_GL_ACCOUNTS_CONFIG });
};

const checkPermissionGetGLAccountConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_GL_ACCOUNTS_CONFIG });
};

const checkPermissionModifyGLAccountConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_GL_ACCOUNTS_CONFIG });
};

const checkPermissionCreateLedgerMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_LEDGER_MAP });
};

const checkPermissionGetLedgerMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_LEDGER_MAP });
};

const checkPermissionModifyLedgerMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_LEDGER_MAP });
};

const checkPermissionCreateSubLedgerMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_SUB_LEDGER_MAP });
};

const checkPermissionGetSubLedgerMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_SUB_LEDGER_MAP });
};

const checkPermissionModifySubLedgerMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_SUB_LEDGER_MAP });
};

const checkPermissionCreateManualRecognition = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_MANUAL_RECOGNITION });
};

const checkPermissionViewLedger = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_LEDGER });
};

const checkPermissionViewSubLedger = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_SUB_LEDGER });
};

const checkPermissionViewBalanceSheet = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_BALANCE_SHEET });
};

const checkPermissionViewPAndL = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_P_AND_L });
};

const checkPermissionCreateItemSplitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_ITEM_SPLIT_CONFIG });
};

const checkPermissionModifyItemSplitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_ITEM_SPLIT_CONFIG });
};

const checkPermissionGetItemSplitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_ITEM_SPLIT_CONFIG });
};

const checkPermissionCreateCalendarConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_CALENDAR_CONFIG });
};

const checkPermissionGetCalendarConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_CALENDAR_CONFIG });
};

const checkPermissionModifyCalendarConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_CALENDAR_CONFIG });
};

const checkPermissionCreateEnterpriseConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_ENTERPRISE_CONFIG });
};

const checkPermissionGetEnterpriseConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_ENTERPRISE_CONFIG });
};

const checkPermissionModifyEnterpriseConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_ENTERPRISE_CONFIG });
};

const checkPermissionCreateDivisionsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_DIVISIONS_CONFIG });
};

const checkPermissionGetDivisionsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_DIVISIONS_CONFIG });
};

const checkPermissionModifyDivisionsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_DIVISIONS_CONFIG });
};

const checkPermissionCreateLegalEnityConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_LEGAL_ENTITY_CONFIG });
};

const checkPermissionGetLegalEnityConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_LEGAL_ENTITY_CONFIG });
};

const checkPermissionModifyLegalEnityConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_LEGAL_ENTITY_CONFIG });
};

const checkPermissionCreateBusinessUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_BUSINESS_UNIT_CONFIG });
};
const checkPermissionGetBusinessUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_BUSINESS_UNIT_CONFIG });
};
const checkPermissionModifyBusinessUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_BUSINESS_UNIT_CONFIG });
};
const checkPermissionCreateDepartmentConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_DEPARTMENT_CONFIG });
};
const checkPermissionGetDepartmentConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_DEPARTMENT_CONFIG });
};
const checkPermissionModifyDepartmentConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_DEPARTMENT_CONFIG });
};
const checkPermissionCreateCostCenterConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_COST_CENTER_CONFIG });
};
const checkPermissionGetCostCenterConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_COST_CENTER_CONFIG });
};
const checkPermissionModifyCostCenterConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_COST_CENTER_CONFIG });
};
const checkPermissionCreateCoaConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_COA_CONFIG });
};
const checkPermissionGetCoaConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_COA_CONFIG });
};
const checkPermissionModifyCoaConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_COA_CONFIG });
};
const checkPermissionResendERPBatch = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.RESEND_ERP_BATCH });
};


const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeCreateCompanyConfig = 0;
  let modeGetCompanyConfig = 0;
  let modeModifyCompanyConfig = 0;
  let modeCreateCurrencyExchangeConfig = 0;
  let modeGetCurrencyExchangeConfig = 0;
  let modeModifyCurrencyExchangeConfig = 0;
  let modeCreateMilestoneConfig = 0;
  let modeGetMilestoneConfig = 0;
  let modeModifyMilestoneConfig = 0;
  let modeCreateGLAccountConfig = 0;
  let modeGetGLAccountConfig = 0;
  let modeModifyGLAccountConfig = 0;
  let modeCreateLedgerMap = 0;
  let modeGetLedgerMap = 0;
  let modeModifyLedgerMap = 0;
  let modeCreateSubLedgerMap = 0;
  let modeGetSubLedgerMap = 0;
  let modeModifySubLedgerMap = 0;
  let modeCreateManualRecognition = 0;
  let modeViewLedger = 0;
  let modeViewSubLedger = 0;
  let modeViewBalanceSheet = 0;
  let modeViewPAnd = 0;
  let modeCreateItemSplitConfig = 0;
  let modeModifyItemSplitConfig = 0;
  let modeGetItemSplitConfig = 0;
  let modeCreateCalendarConfig = 0;
  let modeGetCalendarConfig = 0;
  let modeModifyCalendarConfig = 0;
  let modeCreateEnterpriseConfig = 0;
  let modeGetEnterpriseConfig = 0;
  let modeModifyEnterpriseConfig = 0;
  let modeCreateDivisionsConfig = 0;
  let modeGetDivisionsConfig = 0;
  let modeModifyDivisionsConfig = 0;
  let modeCreateLegalEnityConfig = 0;
  let modeGetLegalEnityConfig = 0;
  let modeModifyLegalEnityConfig = 0;
  let modeCreateBusinessUnitConfig = 0;
  let modeGetBusinessUnitConfig = 0;
  let modeModifyBusinessUnitConfig = 0;
  let modeCreateDepartmentConfig = 0;
  let modeGetDepartmentConfig = 0;
  let modeModifyDepartmentConfig = 0;
  let modeCreateCostCenterConfig = 0;
  let modeGetCostCenterConfig = 0;
  let modeModifyCostCenterConfig = 0;
  let modeCreateCoaConfig = 0;
  let modeGetCoaConfig = 0;
  let modeModifyCoaConfig = 0;
  let modeResendErpBatch = 0;

  modeCreateCompanyConfig = checkPermissionCreateCompanyConfig({ listPermission });
  modeGetCompanyConfig = checkPermissionGetCompanyConfig({ listPermission });
  modeModifyCompanyConfig = checkPermissionModifyCompanyConfig({ listPermission });
  modeCreateCurrencyExchangeConfig = checkPermissionCreateCurrencyExchangeConfig({ listPermission });
  modeGetCurrencyExchangeConfig = checkPermissionGetCurrencyExchangeConfig({ listPermission });
  modeModifyCurrencyExchangeConfig = checkPermissionModifyCurrencyExchangeConfig({ listPermission });
  modeCreateMilestoneConfig = checkPermissionCreateMilestoneConfig({ listPermission });
  modeGetMilestoneConfig = checkPermissionGetMilestoneConfig({ listPermission });
  modeModifyMilestoneConfig = checkPermissionModifyMilestoneConfig({ listPermission });
  modeCreateGLAccountConfig = checkPermissionCreateGLAccountConfig({ listPermission });
  modeGetGLAccountConfig = checkPermissionGetGLAccountConfig({ listPermission });
  modeModifyGLAccountConfig = checkPermissionModifyGLAccountConfig({ listPermission });
  modeCreateLedgerMap = checkPermissionCreateLedgerMap({ listPermission });
  modeGetLedgerMap = checkPermissionGetLedgerMap({ listPermission });
  modeModifyLedgerMap = checkPermissionModifyLedgerMap({ listPermission });
  modeCreateSubLedgerMap = checkPermissionCreateSubLedgerMap({ listPermission });
  modeGetSubLedgerMap = checkPermissionGetSubLedgerMap({ listPermission });
  modeModifySubLedgerMap = checkPermissionModifySubLedgerMap({ listPermission });
  modeCreateManualRecognition = checkPermissionCreateManualRecognition({ listPermission });
  modeViewLedger = checkPermissionViewLedger({ listPermission });
  modeViewSubLedger = checkPermissionViewSubLedger({ listPermission });
  modeViewBalanceSheet = checkPermissionViewBalanceSheet({ listPermission });
  modeViewPAnd = checkPermissionViewPAndL({ listPermission });
  modeCreateItemSplitConfig = checkPermissionCreateItemSplitConfig({ listPermission });
  modeModifyItemSplitConfig = checkPermissionModifyItemSplitConfig({ listPermission });
  modeGetItemSplitConfig = checkPermissionGetItemSplitConfig({ listPermission });
  modeCreateCalendarConfig = checkPermissionCreateCalendarConfig({ listPermission });
  modeGetCalendarConfig = checkPermissionGetCalendarConfig({ listPermission });
  modeModifyCalendarConfig = checkPermissionModifyCalendarConfig({ listPermission });
  modeCreateEnterpriseConfig = checkPermissionCreateEnterpriseConfig({ listPermission });
  modeGetEnterpriseConfig = checkPermissionGetEnterpriseConfig({ listPermission });
  modeModifyEnterpriseConfig = checkPermissionModifyEnterpriseConfig({ listPermission });
  modeCreateDivisionsConfig = checkPermissionCreateDivisionsConfig({ listPermission });
  modeGetDivisionsConfig = checkPermissionGetDivisionsConfig({ listPermission });
  modeModifyDivisionsConfig = checkPermissionModifyDivisionsConfig({ listPermission });
  modeCreateLegalEnityConfig = checkPermissionCreateLegalEnityConfig({ listPermission });
  modeGetLegalEnityConfig = checkPermissionGetLegalEnityConfig({ listPermission });
  modeModifyLegalEnityConfig = checkPermissionModifyLegalEnityConfig({ listPermission });
  modeCreateBusinessUnitConfig = checkPermissionCreateBusinessUnitConfig({ listPermission });
  modeGetBusinessUnitConfig = checkPermissionGetBusinessUnitConfig({ listPermission });
  modeModifyBusinessUnitConfig = checkPermissionModifyBusinessUnitConfig({ listPermission });
  modeCreateDepartmentConfig = checkPermissionCreateDepartmentConfig({ listPermission });
  modeGetDepartmentConfig = checkPermissionGetDepartmentConfig({ listPermission });
  modeModifyDepartmentConfig = checkPermissionModifyDepartmentConfig({ listPermission });
  modeCreateCostCenterConfig = checkPermissionCreateCostCenterConfig({ listPermission });
  modeGetCostCenterConfig = checkPermissionGetCostCenterConfig({ listPermission });
  modeModifyCostCenterConfig = checkPermissionModifyCostCenterConfig({ listPermission });
  modeCreateCoaConfig = checkPermissionCreateCoaConfig({ listPermission });
  modeGetCoaConfig = checkPermissionGetCoaConfig({ listPermission });
  modeModifyCoaConfig = checkPermissionModifyCoaConfig({ listPermission });
  modeResendErpBatch = checkPermissionResendERPBatch({ listPermission });

  return {
    modeCreateCompanyConfig,
    modeGetCompanyConfig,
    modeModifyCompanyConfig,
    modeCreateCurrencyExchangeConfig,
    modeGetCurrencyExchangeConfig,
    modeModifyCurrencyExchangeConfig,
    modeCreateMilestoneConfig,
    modeGetMilestoneConfig,
    modeModifyMilestoneConfig,
    modeCreateGLAccountConfig,
    modeGetGLAccountConfig,
    modeModifyGLAccountConfig,
    modeCreateLedgerMap,
    modeGetLedgerMap,
    modeModifyLedgerMap,
    modeCreateSubLedgerMap,
    modeGetSubLedgerMap,
    modeModifySubLedgerMap,
    modeCreateManualRecognition,
    modeViewLedger,
    modeViewSubLedger,
    modeViewBalanceSheet,
    modeViewPAnd,
    modeCreateItemSplitConfig,
    modeModifyItemSplitConfig,
    modeGetItemSplitConfig,
    modeCreateCalendarConfig,
    modeGetCalendarConfig,
    modeModifyCalendarConfig,
    modeCreateEnterpriseConfig,
    modeGetEnterpriseConfig,
    modeModifyEnterpriseConfig,
    modeCreateDivisionsConfig,
    modeGetDivisionsConfig,
    modeModifyDivisionsConfig,
    modeCreateLegalEnityConfig,
    modeGetLegalEnityConfig,
    modeModifyLegalEnityConfig,
    modeCreateBusinessUnitConfig,
    modeGetBusinessUnitConfig,
    modeModifyBusinessUnitConfig,
    modeCreateDepartmentConfig,
    modeGetDepartmentConfig,
    modeModifyDepartmentConfig,
    modeCreateCostCenterConfig,
    modeGetCostCenterConfig,
    modeModifyCostCenterConfig,
    modeCreateCoaConfig,
    modeGetCoaConfig,
    modeModifyCoaConfig,
    modeResendErpBatch,
  };
};

export {
  checkPermissionCreateCompanyConfig,
  checkPermissionGetCompanyConfig,
  checkPermissionModifyCompanyConfig,
  checkPermissionCreateCurrencyExchangeConfig,
  checkPermissionGetCurrencyExchangeConfig,
  checkPermissionModifyCurrencyExchangeConfig,
  checkPermissionCreateMilestoneConfig,
  checkPermissionGetMilestoneConfig,
  checkPermissionModifyMilestoneConfig,
  checkPermissionCreateGLAccountConfig,
  checkPermissionGetGLAccountConfig,
  checkPermissionModifyGLAccountConfig,
  checkPermissionCreateLedgerMap,
  checkPermissionGetLedgerMap,
  checkPermissionModifyLedgerMap,
  checkPermissionCreateSubLedgerMap,
  checkPermissionGetSubLedgerMap,
  checkPermissionModifySubLedgerMap,
  checkPermissionCreateManualRecognition,
  checkPermissionViewLedger,
  checkPermissionViewSubLedger,
  checkPermissionViewBalanceSheet,
  checkPermissionViewPAndL,
  checkPermissionCreateItemSplitConfig,
  checkPermissionModifyItemSplitConfig,
  checkPermissionGetItemSplitConfig,
  checkPermissionCreateCalendarConfig,
  checkPermissionGetCalendarConfig,
  checkPermissionModifyCalendarConfig,
  checkPermissionCreateEnterpriseConfig,
  checkPermissionGetEnterpriseConfig,
  checkPermissionModifyEnterpriseConfig,
  checkPermissionCreateDivisionsConfig,
  checkPermissionGetDivisionsConfig,
  checkPermissionModifyDivisionsConfig,
  checkPermissionCreateLegalEnityConfig,
  checkPermissionGetLegalEnityConfig,
  checkPermissionModifyLegalEnityConfig,
  checkPermissionCreateBusinessUnitConfig,
  checkPermissionGetBusinessUnitConfig,
  checkPermissionModifyBusinessUnitConfig,
  checkPermissionCreateDepartmentConfig,
  checkPermissionGetDepartmentConfig,
  checkPermissionModifyDepartmentConfig,
  checkPermissionCreateCostCenterConfig,
  checkPermissionGetCostCenterConfig,
  checkPermissionModifyCostCenterConfig,
  checkPermissionCreateCoaConfig,
  checkPermissionGetCoaConfig,
  checkPermissionModifyCoaConfig,
  checkPermissionForAllPages,
  checkPermissionResendERPBatch,
};
