import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { URL_PAYMENT, statusDisableFielsdViewFolio } from '../../../../utils/constants';
import { GenericInput, DataTable, ModalWithItem } from '../../../../components/common';
import { paymentEnum, cardTokenFields } from '../../../../constantsApp';
import ModalInvoice from './ModalInvoice';
import { getAddressesForm, getDataCardFromURL, validate, formatMoneyValue } from '../../../../utils/utils';
import { dataFiledCreditCardPayment, dataFiledAddress } from '../../constant';
import { getUserId } from '../../../../localStorages';

const fieldCreditCard = ['cardToken', 'cardExpiry', 'last4CC', 'merchant'];

const paymentDetails = [
  {
    name: 'paymentAmount',
    label: 'common:label.paymentAmount',
    type: 'number',
  },
  {
    name: 'amount',
    label: 'common:label.remainingAmount',
    type: 'number',
  },
  {
    name: 'currency',
    label: 'common:label.paymentCurrency',
  },
];

class CreditCardPayment extends React.PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isOpenModalInvoice: false,
      isNewAddress: false,
      isNewCreditCard: false,
      storeAsPrimary: false,
      data: {
        accountId: props.accountId || '',
        currency: props.currency || '',
        paymentCreditCards: [{ merchant: 'qabrain3' }],
        // transactionId: 'TrID001',
        source: 'AGENT_CARE',
        userId: getUserId() || 'UserX002',
        paymentDate: moment().format('YYYY-MM-DD'),
        method: paymentEnum.paymentMethod.CREDIT_CARD,
      },
      dataAddress: { country: '', state: '', city: '' },
      wasValidated: false,
      listItems: [],
      isOpenModalAllocationData: false,
      isMultipleSelect: false,
      isFolioStamping: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.accountId === state?.data?.accountId) return null;
    const newData = state?.data || {};
    newData.accountId = props?.accountId;
    newData.invoiceUnitId = null;
    return {
      data: newData,
      listItems: [],
    };
  }

  componentDidMount() {
    try {
      const urlTokenCreditCard = window.location.href;
      const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
      if (!ccpPropertyList || !ccpPropertyList.length) {
        getCcpPropertiesConfig('', ({ success, data }) => {
          if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
            this.onInitCcpProperty(data.ccpPropertyList);
          }
        });
      } else {
        this.onInitCcpProperty(ccpPropertyList);
      }
      const { data } = this.state;
      let newData = cloneDeep(data);
      newData = localStorage.getItem('dataCallbackPayment')
        ? JSON.parse(localStorage.getItem('dataCallbackPayment'))
        : newData;
      if (urlTokenCreditCard) {
        newData.data.paymentCreditCards[0].cardToken = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.token);
        newData.data.paymentCreditCards[0].cardExpiry = getDataCardFromURL(
          urlTokenCreditCard,
          cardTokenFields.expiryDate
        );
        newData.data.paymentCreditCards[0].last4CC = getDataCardFromURL(urlTokenCreditCard, cardTokenFields.last4cc);
      }
      this.setState(
        {
          data: newData.data,
          isOpenModalInvoice: newData.isOpenModalInvoice,
          isNewAddress: newData.isNewAddress,
          isNewCreditCard: newData.isNewCreditCard,
          storeAsPrimary: newData.storeAsPrimary,
        },
        () => {
          localStorage.removeItem('dataCallbackPayment');
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  }

  onInitCcpProperty = ccpPropertyList => {
    let isPacEnabled = false;
    const pacEnabledProperty = ccpPropertyList.find(val => val.property === 'pacEnabled');
    const batchFolioStampingProperty = ccpPropertyList.find(val => val.property === 'batchFolioStamping');

    if (pacEnabledProperty && pacEnabledProperty.value) {
      isPacEnabled = pacEnabledProperty.value === 'true';
    }
    this.setState({
      isPacEnabled,
      isFolioStamping: batchFolioStampingProperty && batchFolioStampingProperty.value === 'true',
    });
  };

  onToggleInvoice = ({ index, isMultiple }) => {
    const { isOpenModalInvoice } = this.state;
    const { t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({
      isOpenModalInvoice: !isOpenModalInvoice,
      indexSelectAccountId: index,
      isMultipleSelect: isMultiple,
    });
  };

  // onSelectInvoice = (invoiceUnitId, row) => {
  //   const { data } = this.state;
  //   const { onChangeAccountId } = this.props;
  //   data.invoiceUnitId = invoiceUnitId;
  //   data.accountId = row.accountId;
  //   if (onChangeAccountId) onChangeAccountId(row.accountId);
  //   this.setState({ ...data }, () => this.onHandleSubmit());
  // };

  onSelectInvoice = (itemId, row) => {
    const { indexSelectAccountId, listItems, data } = this.state;
    const newData = cloneDeep(data);
    const { t, onChangeAccountId } = this.props;
    const checkExitInvoice = listItems.find(val => val.invoiceUnitId === itemId);
    if (checkExitInvoice) return toast.error(t('message.messageInvoiceIdAleadyExist'));
    if (itemId) {
      listItems[indexSelectAccountId].invoiceUnitId = itemId;
      listItems[indexSelectAccountId].due = row.due;
      listItems[indexSelectAccountId].invoiceDate = row.invoiceDate;
      listItems[indexSelectAccountId].dueDate = row.dueDate;
      listItems[indexSelectAccountId].total = row.total;
      listItems[indexSelectAccountId].type = row.type;
      listItems[indexSelectAccountId].accountId = row.accountId;
      listItems[indexSelectAccountId].clientId = row.clientId;
      listItems[indexSelectAccountId].organization = row.organization;
      listItems[indexSelectAccountId].folioStatus = row.folioStatus;
      if (onChangeAccountId && newData.accountId !== row.accountId) {
        onChangeAccountId(row.accountId, currency => {
          newData.currency = currency || null;
          this.setState({ data: newData });
          this.doGetCrossCurrencyPaymentDetails({
            accountId: row.accountId,
            invoiceId: itemId,
            paymentDate: data?.paymentDate,
            paymentCurrency: currency || null,
            indexSelectAccountId,
          });
        });
      } else {
        this.doGetCrossCurrencyPaymentDetails({
          accountId: row.accountId,
          invoiceId: itemId,
          paymentDate: data?.paymentDate,
          paymentCurrency: data?.currency,
          indexSelectAccountId,
        });
      }
      newData.accountId = row.accountId;
    }
    this.setState({ listItems: [...listItems], isOpenModalInvoice: false, data: newData });
  };

  onSelectMultipleInvoice = (ids, rows) => {
    const { listItems, data } = this.state;
    let listItemsSelect = [];
    if (ids && ids.length) {
      rows.forEach(val => {
        const item = {};
        item.invoiceUnitId = val.id;
        item.due = val.due;
        item.invoiceDate = val.invoiceDate;
        item.dueDate = val.dueDate;
        item.total = val.total;
        item.type = val.type;
        item.accountId = val.accountId;
        item.clientId = val.clientId;
        item.organization = val.organization;
        item.folioStatus = val.folioStatus;
        item.amount = null;
        listItemsSelect.push(item);
      });
    }
    if (listItemsSelect && listItemsSelect.length) {
      if (listItems && listItems.length) {
        listItemsSelect = listItemsSelect.filter(val => !listItems.find(it => it.invoiceUnitId === val.invoiceUnitId));
      }
      listItemsSelect.forEach((it, index) => {
        this.doGetCrossCurrencyPaymentDetails({
          accountId: it.accountId,
          invoiceId: it.invoiceUnitId,
          paymentDate: data?.paymentDate,
          paymentCurrency: data?.currency,
          indexSelectAccountId: listItems.length + index,
          isNoCaulation: true,
        });
      });
    }
    this.setState({ listItems: [...listItems, ...listItemsSelect], isOpenModalInvoice: false });
  };

  doGetCrossCurrencyPaymentDetails = ({ accountId, paymentDate, invoiceId, paymentCurrency, indexSelectAccountId }) => {
    const { getCrossCurrencyPaymentDetails } = this.props;
    const { data } = this.state;
    const newData = cloneDeep(data);
    getCrossCurrencyPaymentDetails({ accountId, paymentDate, invoiceId, paymentCurrency }, ({ success, data }) => {
      if (success && data) {
        const { listItems } = this.state;
        if (listItems && listItems[indexSelectAccountId]) {
          listItems[indexSelectAccountId].invoiceCurrencyInvDue = data.invoiceCurrencyInvDue;
          listItems[indexSelectAccountId].paymentCurrencyInvDue = data.paymentCurrencyInvDue;
          listItems[indexSelectAccountId].exchangeRate = data.exchangeRate;
          listItems[indexSelectAccountId].invoiceCurrency = data.invoiceCurrency;

          let totalAmount = 0;
          listItems
            .filter(val => val.invoiceUnitId !== listItems[indexSelectAccountId].invoiceUnitId)
            .forEach(val => {
              const { amount } = val;
              totalAmount += Number.parseFloat(amount || 0);
            });

          const amountTotalForListRow = Number.parseFloat(newData.amount || 0) - totalAmount;
          if (amountTotalForListRow === 0) {
            listItems[indexSelectAccountId].amount = '0';
          }
          if (
            amountTotalForListRow > 0 &&
            Number.parseFloat(amountTotalForListRow) - Number.parseFloat(data.paymentCurrencyInvDue) < 0
          ) {
            listItems[indexSelectAccountId].amount = amountTotalForListRow;
          }
          if (Number.parseFloat(amountTotalForListRow) >= Number.parseFloat(data.paymentCurrencyInvDue)) {
            listItems[indexSelectAccountId].amount = Number.parseFloat(data.paymentCurrencyInvDue);
          }
          if (
            listItems[indexSelectAccountId].amount &&
            Number.parseFloat(listItems[indexSelectAccountId].amount) !== 0
          ) {
            listItems[indexSelectAccountId].amount = Number.parseFloat(listItems[indexSelectAccountId].amount);
          }
          // console.log('listItems', listItems)
        }
        this.setState({ listItems: [...listItems] });
      }
    });
  };

  onToggleCreditCard = () => {
    const { isNewCreditCard } = this.state;
    this.setState({ isNewCreditCard: !isNewCreditCard });
  };

  onToggleStoreAsPrimary = () => {
    const { storeAsPrimary } = this.state;
    this.setState({ storeAsPrimary: !storeAsPrimary });
  };

  onToggleNewAddress = () => {
    const { isNewAddress } = this.state;
    this.setState({ isNewAddress: !isNewAddress });
  };

  onHandleChange = ({ name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (fieldCreditCard.indexOf(name) > -1) {
      newData.paymentCreditCards[0][name] = value;
      return this.setState({ data: newData });
    }
    newData[name] = value;
    return this.setState({ data: newData });
  };

  onHandleChangeAddress = ({ name, value }) => {
    const { dataAddress } = this.state;
    dataAddress[name] = value;
    this.setState({ ...dataAddress });
  };

  onHandleSubmit = evt => {
    if (evt) evt.preventDefault();
    const { applyPayment, accountId } = this.props;
    const { data, dataAddress, isNewAddress, isNewCreditCard, storeAsPrimary, listItems } = this.state;
    let payload = {};
    payload = cloneDeep(data);
    payload.storeAsPrimary = storeAsPrimary;
    if (isNewAddress) {
      payload.paymentAddresses = dataAddress;
    }
    if (!isNewCreditCard) {
      delete payload.paymentCreditCards;
      payload.cardOnFile = true;
    }
    if (listItems && listItems.length) {
      payload.allocationData = listItems.map(val => {
        const { invoiceUnitId, amount, accountId } = val;
        return { invoiceUnitId, amount, accountId };
      });
    }
    applyPayment(payload, success => {
      if (success) {
        const resetData = {
          accountId: accountId || '',
          paymentCreditCards: [{}],
          // transactionId: 'TrID001',
          source: 'AGENT_CARE',
          userId: getUserId() || 'UserX002',
          paymentDate: moment().format('YYYY-MM-DD'),
          method: paymentEnum.paymentMethod.CREDIT_CARD,
        };
        const resetAddress = {
          country: '',
          state: '',
          city: '',
        };
        this.setState({ data: resetData, dataAddress: resetAddress, isOpenModalInvoice: false, wasValidated: false });
      }
    });
  };

  renderPaymentDetails = ({ totalAmount, isInvoiceModal, isSupportMultiSelect }) => {
    const { data } = this.state;
    const { t } = this.props;

    return (
      <div className={`col-md-12 row border-bottom pt-3 pb-3 ${isInvoiceModal ? 'pl-3' : ''}`}>
        {paymentDetails.map(val => {
          let value = data ? data[val.name] : '';
          if (val.name === 'paymentAmount' && data) {
            value = data.amount || '0';
          }
          if (val.name === 'amount' && data) {
            value = formatMoneyValue(
              Number.parseFloat(data[val.name] || 0) - Number.parseFloat(totalAmount || 0) || null
            );
            if (!value || Number.isNaN(value) || value === 'NaN') value = '0';
          }
          return <GenericInput value={value || ''} onChange={() => {}} wrapperClass="col-md-3" readOnly {...val} />;
        })}
        {isSupportMultiSelect && (
          <button
            type="button"
            className="button button-border x-small mr-2 mt-4 mb-2 float-right"
            onClick={() => this.onToggleInvoice({ isMultiple: true, index: null })}
            disabled={!data.accountId || Number.parseFloat(data.amount) === totalAmount}
          >
            {t('label.selectInvoice')}
          </button>
        )}
      </div>
    );
  };

  onToggleModalInvoiceAllocationData = () => {
    const { isOpenModalAllocationData } = this.state;
    const { t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    return this.setState({ isOpenModalAllocationData: !isOpenModalAllocationData });
  };

  onAddNewItem = () => {
    try {
      const { listItems } = this.state;
      const newData = cloneDeep(listItems);
      newData.push({
        invoiceUnitId: null,
        amount: null,
        accountId: null,
      });

      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { listItems } = this.state;
    try {
      const newData = cloneDeep(listItems);
      newData.splice(index, 1);
      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeInvoiceAmount = ({ name, value, index, item }) => {
    const { listItems, data } = this.state;
    let totalAmount = 0;
    if (Number.parseFloat(value) < 0) return '';
    listItems
      .filter(val => val.invoiceUnitId !== listItems[index].invoiceUnitId)
      .forEach(val => {
        const { amount } = val;
        totalAmount += Number.parseFloat(amount || 0);
      });
    const amountTotalForListRow = Number.parseFloat(data.amount || 0) - totalAmount;
    if (amountTotalForListRow < Number.parseFloat(value)) return '';
    if (item.paymentCurrencyInvDue < Number.parseFloat(value)) return '';
    listItems[index].amount = value;
    this.setState({ listItems: [...listItems] });
  };

  render() {
    const {
      data,
      isOpenModalInvoice,
      dataAddress: { country, state, city },
      dataAddress,
      isNewAddress,
      isNewCreditCard,
      storeAsPrimary,
      wasValidated,
      listItems,
      isOpenModalAllocationData,
      isPacEnabled,
      isMultipleSelect,
      isFolioStamping,
    } = this.state;
    const { modeProcessPayment, t } = this.props;
    let totalAmount = 0;
    let isDisableApply = false;
    listItems.forEach(val => {
      const { amount, invoiceUnitId, accountId } = val;
      totalAmount += Number.parseFloat(amount || 0);
      if (!accountId || !invoiceUnitId) isDisableApply = true;
    });

    const folioStatusFilter = isFolioStamping ? { folioStatus: 'STAMPED' } : {};

    const invoiceColumns = [
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        label: 'label.invoiceId',
        name: 'invoiceUnitId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item.invoiceUnitId}
              name="invoiceUnitId"
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              onClick={() => this.onToggleInvoice({ item, index })}
              fa="fa fa-external-link"
            />
          );
        },
      },
      {
        label: 'label.accountId',
        name: 'accountId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountId}
            name="accountId"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            // onClick={() => this.onToogleModalAccount({ item, index })}
            // fa={item.invoiceUnitId ? null : 'fa fa-external-link'}
            readOnly
            // readOnly={!!item.invoiceUnitId}
            // disabled={!!item.invoiceUnitId}
          />
        ),
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'clientId',
        label: 'common:label.clientId',
      },
      {
        name: 'amount',
        label: 'common:label.allocateAmount',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.amount}
            name="amount"
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeInvoiceAmount({ name, value, item, index })}
          />
        ),
      },
      {
        name: 'invoiceCurrency',
        label: 'common:label.accountCurrency',
      },
      {
        name: 'invoiceCurrencyInvDue',
        label: 'common:label.invoiceDueInAccountCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'paymentCurrencyInvDue',
        label: 'common:label.invoiceDueInPaymentCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'exchangeRate',
        label: 'common:label.exchangeRate',
      },
      {
        name: 'invoiceUnitId',
        label: 'common:label.invoiceId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              disabled={isPacEnabled && statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
              onClick={() => this.doGetObjectFileById(item.invoiceUnitId)}
            >
              {item.invoiceUnitId}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'common:label.invoiceDate',
      },
      {
        name: 'dueDate',
        label: 'common:label.dueDate',
      },
      {
        name: 'total',
        label: 'common:label.invoiceTotal',
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
    ];

    return (
      <div>
        <form
          onSubmit={this.onHandleSubmit}
          className={`form-group col-md-12 ${wasValidated ? 'was-validated' : ''} `}
          noValidate
          ref={this.formRef}
        >
          {/* <TitleFrom title={t('label.creditCardPayment')} /> */}
          <div className="checkbox checbox-switch switch-danger ml-3 mb-2 mt-4">
            <label htmlFor="switch-credit-card">
              <b>{t('label.newCard')}</b>
              <input
                id="switch-credit-card"
                type="checkbox"
                name="switch21"
                onChange={this.onToggleCreditCard}
                checked={isNewCreditCard}
              />
              <span className="label-switch" />
            </label>
            <label className="ml-3" htmlFor="switch-store-as-primary">
              <b>{t('label.storeAsPrimary')}</b>
              <input
                id="switch-store-as-primary"
                type="checkbox"
                name="switch21"
                onChange={this.onToggleStoreAsPrimary}
                checked={storeAsPrimary}
              />
              <span className="label-switch" />
            </label>
          </div>
          {isNewCreditCard ? (
            <div className="col-md-12 ml-4 d-flex flex-wrap">
              {dataFiledCreditCardPayment.map(item => {
                if (item.name === 'cardToken') {
                  return (
                    <div className="col-md-4 group-flex" key="cardToken">
                      <GenericInput
                        value={
                          fieldCreditCard.indexOf(item.name) > -1 &&
                          data.paymentCreditCards[0] &&
                          data.paymentCreditCards[0][item.name]
                            ? data.paymentCreditCards[0][item.name]
                            : data[item.name] || ''
                        }
                        label={t('label.creditCardToken')}
                        wrapperClass="col-md-7"
                        name="cardToken"
                        required
                        onChange={this.onHandleChange}
                      />
                      <a
                        href={`${URL_PAYMENT}${window.location.href}`}
                        className="button x-small btn-credit-card"
                        onClick={() => {
                          localStorage.setItem('dataCallbackPayment', JSON.stringify(this.state));
                          localStorage.setItem('isCreditCard', true);
                        }}
                      >
                        {t('label.getToken')}
                      </a>
                    </div>
                  );
                }
                return (
                  <GenericInput
                    key={item.label}
                    type={item.type || 'text'}
                    fa={item.fa || ''}
                    value={
                      fieldCreditCard.indexOf(item.name) > -1 &&
                      data.paymentCreditCards[0] &&
                      data.paymentCreditCards[0][item.name]
                        ? data.paymentCreditCards[0][item.name]
                        : data[item.name] || ''
                    }
                    onChange={this.onHandleChange}
                    wrapperClass="col-md-4"
                    onClick={() => (item.fa ? this.onToggleInvoice() : {})}
                    {...item}
                  />
                );
              })}
            </div>
          ) : (
            <div className="col-md-12 d-flex ml-4">
              <GenericInput
                label="common:label.oneOffAmount"
                onChange={this.onHandleChange}
                value={data.amount}
                name="amount"
                type="number"
                wrapperClass="col-md-4"
                required
              />
            </div>
          )}
          <div className="checkbox checbox-switch switch-danger ml-3 mt-4 mb-3">
            <label htmlFor="switch-address">
              <b>{t('label.addNewAddress')}</b>
              <input
                id="switch-address"
                type="checkbox"
                name="switch21"
                onChange={this.onToggleNewAddress}
                checked={isNewAddress}
              />
              <span className="label-switch" />
            </label>
          </div>
          {isNewAddress && (
            <div className="d-flex flex-wrap ml-4 mb-5">
              {getAddressesForm({ country, state, city, addresses: dataFiledAddress }).map(item => {
                if (item && !isEmpty(item.optionSelect)) {
                  return (
                    <GenericInput
                      key={item.labelFilter}
                      label={item.labelFilter || ''}
                      type="select"
                      isClearable
                      value={dataAddress[item.fieldSelect] || null}
                      wrapperClass="col-md-4"
                      options={item.optionSelect}
                      onChange={this.onHandleChangeAddress}
                      name={item.fieldSelect}
                      required={item.required}
                    />
                  );
                }
                return (
                  <GenericInput
                    key={item.labelFilter}
                    value={dataAddress[item.fieldName] || null}
                    label={item.labelFilter || ''}
                    type="text"
                    required={item.required}
                    onChange={this.onHandleChangeAddress}
                    name={item.fieldName}
                    wrapperClass="col-md-4"
                  />
                );
              })}
            </div>
          )}
          {modeProcessPayment !== 0 && (
            <div className="form-group col-md-12 btn-add-new mb-5">
              <button
                disabled={modeProcessPayment === 1}
                type="button"
                onClick={this.onToggleModalInvoiceAllocationData}
                className="button x-small float-right"
              >
                {t('label.allocatePayment')}
              </button>
            </div>
          )}
        </form>
        {/* <ModalInvoice
          isOpen={isOpenModalInvoice}
          onSelect={this.onSelectInvoice}
          accountId={data.accountId}
          onCancel={() => {
            this.setState({ isOpenModalInvoice: false });
          }}
          disabledSearchFields={data.accountId ? ['organization', 'clientId'] : null}
          isManualPayment
          defaultSearchForm={data.accountId ? { accountId: data.accountId } : null}
        /> */}
        <ModalWithItem
          isOpen={isOpenModalAllocationData}
          onToggle={this.onToggleModalInvoiceAllocationData}
          modalTitle={t('label.paymentAllocation')}
          wrapperClass="modal-70 modal-custom"
        >
          {this.renderPaymentDetails({ totalAmount, isInvoiceModal: true, isSupportMultiSelect: true })}
          <br />
          <br />
          <DataTable columns={invoiceColumns} data={listItems || []} />
          <div className="col-sm-12 mt-4 mb-30">
            <button
              type="button"
              disabled={Number.parseFloat(data.amount) === totalAmount}
              onClick={this.onAddNewItem}
              className="button mb-4 button-border ml-3 black x-small"
            >
              +
              {t('label.addNewRow')}
            </button>
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={this.onToggleModalInvoiceAllocationData}
            >
              {t('label.cancel')}
            </button>
            <button
              type="button"
              className="button button-border x-small mr-2 float-right"
              onClick={this.onHandleSubmit}
              disabled={isDisableApply}
            >
              {t('label.allocatePayment')}
            </button>
          </div>
        </ModalWithItem>
        <ModalInvoice
          isOpen={isOpenModalInvoice}
          onSelect={isMultipleSelect ? this.onSelectMultipleInvoice : this.onSelectInvoice}
          // accountId={data.accountId}
          onCancel={() => {
            this.setState({ isOpenModalInvoice: false });
          }}
          disabledSearchFields={
            (data.accountId && listItems.length > 1) || isMultipleSelect
              ? ['organization', 'clientId', 'accountId']
              : null
          }
          isTopChildren
          defaultSearchForm={
            data.accountId
              ? { ...folioStatusFilter, accountId: data.accountId, due: data?.amount || null }
              : { ...folioStatusFilter, due: data?.amount || null }
          }
          isManualPayment
          isMulitple={isMultipleSelect}
          isSpoortNoClearDisabledField
        >
          {this.renderPaymentDetails({ totalAmount, isInvoiceModal: true })}
        </ModalInvoice>
      </div>
    );
  }
}

CreditCardPayment.propTypes = {
  accountId: PropTypes.string,
  applyPayment: PropTypes.func.isRequired,
  modeProcessPayment: PropTypes.number,
};

CreditCardPayment.defaultProps = {
  accountId: '',
  modeProcessPayment: 2,
};
export default withTranslation('common')(CreditCardPayment);
