import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../utils/utils';
import AccountSort from '../../../constantsApp/customer/AccountSort';
import PageTitle from '../../../components/PageTitle';
import { DataTable, TablePagination, FormCollapse, GenericInput } from '../../../components/common';
import { AccountSearchForm } from '../../../components/CustomerHub';
import RouteNames from '../../App/RouteNames';
import Footer from '../../../components/Footer';
import { makeGetListCustomers, makeErrorMessage } from '../selectors';
import {
  makeGetPermissionsCustomerManagement,
  makeGetPermissionsRating,
  makeGetPermissionsBilling,
} from '../../App/selectors';
import { getCustomers, setParams, getParentAccountDetail } from '../actions';
import {
  checkPermissionCreateCustomer,
  checkPermissionSearchAccount,
  checkPermissionGetAccount,
} from '../CheckPermission';
import SidebarIndex from '../SidebarIndex';

const detectParentIds = customers => {
  const parentIds = customers
    .filter(c => {
      return c.parentId && (isEmpty(c.contacts) || isEmpty(c.addresses));
    })
    .map(c => c.parentId);
  return parentIds.filter((item, index) => parentIds.indexOf(item) === index);
};

const collapsibleAdvancedSearch = [
  {
    title: 'label.advancedSearch',
    state: 'advancedSearch',
  },
];

class CustomerIndexPage extends PureComponent {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    isExpandChild: true,
    listCustomers: [],
    activeTab: {},
  };

  static getDerivedStateFromProps(props, state) {
    return {
      listCustomers: props.listCustomers,
      totalCount: getPageTotalCount({ ...state, items: props.listCustomers }),
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchAccount();
      });
    } else {
      this.doSearchAccount();
    }
  }

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchAccount());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchAccount());
  };

  onHandleChange = ({ name, value }) => {
    const { filter = {} } = this.state;
    filter[name] = value;
    this.setState({ ...filter });
  };

  onHandleSubmit = filterSearch => {
    const { filter } = this.state;
    const newFilter = cloneDeep(filter);
    this.setState(
      {
        filter: {
          ...filterSearch,
          invoiceId: newFilter?.invoiceId || null,
          paymentId: newFilter?.paymentId || null,
          notesId: newFilter?.notesId || null,
        },
        page: 0,
      },
      () => this.doSearchAccount()
    );
  };

  doSearchAccount = () => {
    const { filter, page, size, sorted } = this.state;
    const { getCustomers, getParentAccountDetail } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.status) delete newFilter.status;
    if (!newFilter.fetchParentOnly) delete newFilter.fetchParentOnly;
    if (newFilter.fetchParentOnly) newFilter.fetchParentOnly = newFilter.fetchParentOnly === 'TRUE' ? 'true' : 'false';
    if (newFilter.collectionAgentAssigned)
      newFilter.collectionAgentAssigned = newFilter.collectionAgentAssigned === 'TRUE' ? 'true' : 'false';
    if (newFilter.childAccount) newFilter.childAccount = newFilter.childAccount === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? AccountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true, listCustomers: [] });
    getCustomers(payload, ({ data }) => {
      if (isEmpty(detectParentIds(data))) this.setState({ isSearching: false });
      detectParentIds(data).map(parentId =>
        getParentAccountDetail(parentId, () => {
          this.setState({ isSearching: false });
        })
      );
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchAccount());
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onChangeAdvancedFilter = ({ name, value }) => {
    const { filter } = this.state;
    const newFilter = cloneDeep(filter);
    newFilter[name] = value;
    this.setState({ filter: newFilter });
  };

  onHandleResetAdvancedSearch = () => {
    this.setState({ filter: {}, activeTab: {} });
  };

  render() {
    const { permissionsCustomer, permissionsRating, t, permissionsBilling, location, isComponent } = this.props;
    const { sorted, isSearching, page, size, totalCount, isExpandChild, listCustomers, filter, activeTab } = this.state;
    const tableColumns = [
      {
        name: 'id',
        label: 'label.ACCTNo',
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.customerInfo.path, item.id),
              state: { filter },
            }}
          >
            {item.id}
          </Link>
        ),
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'type',
        label: 'label.accountType',
        render: (colName, item) => {
          const slt = t('selections:accountType')().find(val => val.value === item.type);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'firstName',
        label: 'label.firstName',
        sortable: true,
        render: (name, item) => {
          return item && item.contacts && item.contacts[0] ? item.contacts[0].firstName : null;
        },
      },
      {
        name: 'lastName',
        label: 'label.lastName',
        sortable: true,
        render: (name, item) => {
          return item && item.contacts && item.contacts[0] ? item.contacts[0].lastName : null;
        },
      },
      {
        name: 'organization',
        label: 'label.organization',
        sortable: true,
        style: { minWidth: '110px' },
        render: (name, item) => {
          return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
        },
      },
      {
        name: 'effectiveDate',
        label: 'label.effectiveDate',
      },
      {
        name: 'taxId',
        label: 'label.taxId',
      },
      {
        name: 'foreignTaxId',
        label: 'label.foreignTaxId',
      },
      {
        name: 'email',
        label: 'label.email',
        render: (name, item) => {
          return item && item.contacts && item.contacts[0] ? item.contacts[0].email : null;
        },
      },
      {
        name: 'city',
        label: 'label.city',
        sortable: true,
        render: (name, item) => {
          return item && item.addresses && item.addresses[0] ? item.addresses[0].city : null;
        },
      },
      {
        name: 'state',
        label: 'label.state',
        sortable: true,
        style: { minWidth: '90px' },
        render: (name, item) => {
          return item && item.addresses && item.addresses[0] ? item.addresses[0].state : null;
        },
      },
      {
        name: 'currency',
        label: 'label.currency',
      },
      {
        name: 'status',
        label: 'label.status',
        render: (colName, item) => {
          const slt = t('selections:status')().find(val => val.value === item.status);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'label.reason',
        render: (colName, item) => {
          const slt = t('selections:reason')().find(val => val.value === item.reason);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'salesAgentId',
        label: 'label.salesAgentId',
      },
      {
        name: 'collectionAgent',
        label: 'label.collectionAgentId',
      },
      {
        name: 'userId',
        label: 'label.userId',
      },
      {
        name: 'groupCompany',
        label: 'label.groupCompany',
      },
      {
        name: 'action',
        label: 'label.action',
        render: (colName, item) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.customerInfo.path, item.id), state: { filter } }}
          >
            {t('common:label.view')}
          </Link>
        ),
      },
    ];
    let modeCreateCustomer = 0;
    let modeSearchAccount = 0;
    let modeGetAccount = 0;

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeCreateCustomer = checkPermissionCreateCustomer({ listPermission });
      modeSearchAccount = checkPermissionSearchAccount({ listPermission });
      modeGetAccount = checkPermissionGetAccount({ listPermission });
    }

    const newTableColume = cloneDeep(tableColumns);
    if (modeGetAccount === 0) {
      newTableColume[0] = {
        name: 'id',
        label: 'label.ACCTNo',
      };
      newTableColume.splice(newTableColume.length - 1, 1);
    }

    if (isComponent)
      return (
        <>
          {modeSearchAccount !== 0 && (
            <div className="col-md-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <div className="repeater-file">
                    <div>
                      <AccountSearchForm
                        onSubmit={this.onHandleSubmit}
                        onHandleResetAdvancedSearch={this.onHandleResetAdvancedSearch}
                        defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                      >
                        <div className="col-md-12 p-0 mt-3">
                          {collapsibleAdvancedSearch.map(item => {
                            return (
                              <FormCollapse
                                key={item.title}
                                label="First Name"
                                isActive={activeTab && item.state === activeTab.name && activeTab.isActive}
                                title={t(item.title) || ''}
                                state={item.state}
                                onToggleTab={this.onToggleTab}
                              >
                                <div className="col-md-12 mt-2 row">
                                  <GenericInput
                                    value={filter?.invoiceId || null}
                                    wrapperClass="col-md-3"
                                    label="label.invoiceId"
                                    name="invoiceId"
                                    disabled={!!filter.paymentId || !!filter.notesId}
                                    onChange={this.onChangeAdvancedFilter}
                                  />
                                  <GenericInput
                                    value={filter?.paymentId || null}
                                    wrapperClass="col-md-3"
                                    label="label.paymentId"
                                    name="paymentId"
                                    disabled={!!filter.invoiceId || !!filter.notesId}
                                    onChange={this.onChangeAdvancedFilter}
                                  />
                                  <GenericInput
                                    value={filter?.notesId || null}
                                    wrapperClass="col-md-3"
                                    label="label.notesId"
                                    name="notesId"
                                    disabled={!!filter.invoiceId || !!filter.paymentId}
                                    onChange={this.onChangeAdvancedFilter}
                                  />
                                </div>
                              </FormCollapse>
                            );
                          })}
                        </div>
                      </AccountSearchForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {modeSearchAccount !== 0 && (
            <div className="col-sm-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <DataTable
                    columns={newTableColume}
                    sorted={sorted}
                    data={listCustomers && listCustomers.length ? listCustomers : []}
                    onSort={this.onSortColumn}
                    isLoading={isSearching}
                  />
                </div>
              </div>
            </div>
          )}
          {modeSearchAccount !== 0 && (
            <div className="mb-30">
              <TablePagination
                pageNumber={page}
                pageSize={size}
                totalCount={totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
          )}
        </>
      );
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper">
            <SidebarIndex />
            {modeSearchAccount !== 0 && (
              <PageTitle
                linkTo={
                  modeCreateCustomer !== 2 ? '/' : { pathname: RouteNames.customersCreateInfo.path, state: { filter } }
                }
                titleBtn={modeCreateCustomer !== 2 ? t('common:label.back') : t('common:label.createNew')}
                items={[{ name: t('common:label.customerManagement'), url: RouteNames.customers.path }]}
              >
                {modeCreateCustomer === 2 && (
                  <Link
                    className="button button-border x-small"
                    to={{ pathname: RouteNames.quickCreateCustomer.path, state: { filter } }}
                  >
                    {t('common:label.quickCreate')}
                  </Link>
                )}
              </PageTitle>
            )}
            <br />
            {modeSearchAccount !== 0 && (
              <div className="col-md-12 mb-30">
                <div className="card card-statistics h-100">
                  <div className="card-body">
                    <div className="repeater-file">
                      <div>
                        <AccountSearchForm
                          onSubmit={this.onHandleSubmit}
                          onHandleResetAdvancedSearch={this.onHandleResetAdvancedSearch}
                          defaultFilter={
                            location && location.state && location.state.filter ? location.state.filter : {}
                          }
                        >
                          <div className="col-md-12 p-0 mt-3">
                            {collapsibleAdvancedSearch.map(item => {
                              return (
                                <FormCollapse
                                  key={item.title}
                                  label="First Name"
                                  isActive={activeTab && item.state === activeTab.name && activeTab.isActive}
                                  title={t(item.title) || ''}
                                  state={item.state}
                                  onToggleTab={this.onToggleTab}
                                >
                                  <div className="col-md-12 mt-2 row">
                                    <GenericInput
                                      value={filter?.invoiceId || null}
                                      wrapperClass="col-md-3"
                                      label="label.invoiceId"
                                      name="invoiceId"
                                      disabled={!!filter.paymentId || !!filter.notesId}
                                      onChange={this.onChangeAdvancedFilter}
                                    />
                                    <GenericInput
                                      value={filter?.paymentId || null}
                                      wrapperClass="col-md-3"
                                      label="label.paymentId"
                                      name="paymentId"
                                      disabled={!!filter.invoiceId || !!filter.notesId}
                                      onChange={this.onChangeAdvancedFilter}
                                    />
                                    <GenericInput
                                      value={filter?.notesId || null}
                                      wrapperClass="col-md-3"
                                      label="label.notesId"
                                      name="notesId"
                                      disabled={!!filter.invoiceId || !!filter.paymentId}
                                      onChange={this.onChangeAdvancedFilter}
                                    />
                                  </div>
                                </FormCollapse>
                              );
                            })}
                          </div>
                        </AccountSearchForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modeSearchAccount !== 0 && (
              <div className="col-sm-12 mb-30">
                <div className="card card-statistics h-100">
                  <div className="card-body">
                    <DataTable
                      columns={newTableColume}
                      sorted={sorted}
                      data={listCustomers && listCustomers.length ? listCustomers : []}
                      onSort={this.onSortColumn}
                      isLoading={isSearching}
                    />
                  </div>
                </div>
              </div>
            )}
            {modeSearchAccount !== 0 && (
              <div className="mb-30">
                <TablePagination
                  pageNumber={page}
                  pageSize={size}
                  totalCount={totalCount}
                  onPageChange={this.onPageChange}
                  onSizeChange={this.onSizeChange}
                />
              </div>
            )}
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  listCustomers: makeGetListCustomers() || [],
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsBilling: makeGetPermissionsBilling() || {},
});

CustomerIndexPage.propTypes = {
  listCustomers: PropTypes.arrayOf(PropTypes.any),
  permissionsCustomer: PropTypes.objectOf(PropTypes.any),
  permissionsRating: PropTypes.objectOf(PropTypes.any),
  getCustomers: PropTypes.func,
  getParentAccountDetail: PropTypes.func.isRequired,
};

CustomerIndexPage.defaultProps = {
  listCustomers: {},
  permissionsCustomer: {},
  permissionsRating: {},
  getCustomers: () => {},
};

const newCustomerIndex = connect(mapStateToProps, {
  getCustomers,
  setParams,
  getParentAccountDetail,
})(CustomerIndexPage);

export default withTranslation(['customerPage', 'common', 'selections'])(newCustomerIndex);
