import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class BillInvoiceSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.invoiceDate',
      name: 'invoiceDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'invoiceStatus',
      tOptions: 'selections:invoiceUnitStatus',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

BillInvoiceSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BillInvoiceSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BillInvoiceSearchForm);
