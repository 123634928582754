import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class AdjustmentsDetailsSearchForm extends SearchComponent {
  searchInputs = [
    // {
    //   label: 'label.id',
    //   name: 'id',
    // },
    {
      label: 'label.itemId',
      name: 'itemId',
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    // {
    //   label: 'label.arType',
    //   name: 'arType',
    //   type: 'select',
    //   tOptions: 'selections:arType',
    //   isClearable: true,
    // },
    {
      label: 'label.arTaxRule',
      name: 'arTaxRule',
      type: 'select',
      tOptions: 'selections:arTaxRule',
      isClearable: true,
    },
    {
      label: 'label.arCrDrType',
      name: 'arCrDrType',
      type: 'select',
      tOptions: 'selections:arCrDrType',
      isClearable: true,
    },

    // {
    //   label: 'label.invoiceUnitId',
    //   name: 'invoiceUnitId',
    // },
  ];

  btnSpan = 'col-md-12';
}

AdjustmentsDetailsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AdjustmentsDetailsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(AdjustmentsDetailsSearchForm);
