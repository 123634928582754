import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'revenueType',
        'revenueRecognitionType',
        'revenueActivityType',
        'status',
        'itemType',
        'reportObjectType',
        'offsetAccount',
        'transactionType',
        'fullyRecognized',
      ])}`
    : '';
  const searchRevenueJournal = `searchRevenueJournal(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchRevenueJournal} {
        id
        referenceId
        revenueStartDate
        revenueEndDate
        sellingCompany
        legalEntity
        setOfBooks
        productCompany
        productLine
        glAccount
        glSubAccount
        costCenter
        interCompany
        glOffset
        country
        currency
        sourceCurrency
        accountId
        transactionId
        arOpsId
        itemId
        billUnitId
        itemName
        itemSplitId
        extractBatchId
        amount
        sourceAmount
        amountRecognized
        billed
        salesChannel
        marketSegment
        accountType
        accountSubType
        revenueType
        revenueRecognitionType
        revenueActivityType
        transactionType
        status
        accountingPeriod
        invoiceId
        invoiceDate
        exchangeRate
        notesId
        paymentId
        reportObjectType
        accountingCode
        offsetAccountingCode
      }
    }
  `;
};
