import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class ModalDisscountOfferSearchForm extends SearchComponent {
  searchInputs = [
    {
      name: 'id',
      label: 'common:label.id',
      type: 'text',
    },
    {
      name: 'name',
      label: 'common:label.name',
      type: 'text',
    },
    {
      name: 'serviceType',
      label: 'common:label.serviceType',
      type: 'select',
      // options: [],
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      name: 'discountingModel',
      label: 'common:label.discountingModel',
      type: 'select',
      tOptions: 'selections:discountingModel',
      isClearable: true,
    },
    {
      name: 'transactionType',
      label: 'common:label.transactionType',
      type: 'select',
      tOptions: 'selections:transactionType',
      isClearable: true,
    },
    {
      name: 'status',
      label: 'common:label.status',
      type: 'select',
      tOptions: 'selections:statusPricing',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

ModalDisscountOfferSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ModalDisscountOfferSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ModalDisscountOfferSearchForm);
