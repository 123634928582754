import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';
import discountOfferNS from './constants/discountOfferNS';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, discountOfferNS)}`
    : '';
  const searchDiscountOffers = `searchDiscountOffers(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchDiscountOffers} {
        id
        name
        description
        itemId
        status
        startDate
        endDate
        startDuration
        startUnit
        endDuration
        endUnit
        discountingModel
        transactionType
        minimumQuantity
        maximumQuantity
        salesChannel
        marketSegment
        accountType
        accountSubType
        serviceType
        serviceAddOn
    }
  }
  `;
};
