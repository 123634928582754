import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PulseLoader } from 'react-spinners';
import injectReducer from '../../utils/injectReducer';
import reducer from './reducer';
import makeLoaderState from './selector';

class Loader extends Component {
  componentDidMount() {}

  render() {
    const { isConnecting } = this.props;
    if (!isConnecting) {
      return <div />;
    }

    return (
      <div id="pre-loader" className="loader">
        <div className="group-loader">
          <img className="animate__animated animate__zoomIn" src="/images/pre-loader/loader-01.svg" alt="" />
          <PulseLoader loading size={13} color="#28a745" />
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  isConnecting: PropTypes.bool,
};
Loader.defaultProps = {
  isConnecting: false,
};

const mapStateToProps = createStructuredSelector({
  isConnecting: makeLoaderState() || false,
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({ key: 'loaderReducer', reducer });

export default compose(
  withReducer,
  withConnect
)(Loader);
