import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'status', 'invoiceSummaryType']);
  const modifyConfigPaymentAllocation = `modifyConfigPaymentAllocation(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigPaymentAllocation} {
      id
      name
      status
    }
  }
`;
};
