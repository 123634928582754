import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import {
  uploadMultiPartFiles,
  getObjectFileById,
  getCurrencyConfigApp,
  getCcpPropertiesConfig,
  getInvoiceUnitByIdSelect,
  getCostCenterConfig,
  getOrderSummaryByAccountIdConfig,
  saveManualInvoiceConfig,
  reprocessInvoiceConfig,
  modifyInvoiceStatusConfig,
  regenerateInvoiceConfig,
  undoInvoiceCancelConfig,
  sendInvoiceConfig,
  undoManualInvoiceConfig,
  modifyInvoiceDueDateConfig,
  resendInvoiceVendorConfig,
  getPacInterfaceRecordByIdConfig,
  getInvoiceInstallmentsByInvoiceIdConfig,
  createPaymentInstallmentConfig,
  saveOutputTemplateByType,
  getOutputTemplateByType,
  bulkProcess,
  getSplitTaxPerLineConfig,
} from '../../../App/actions';
import { makeGetPermissionsInvoice } from '../../../App/selectors';
import {
  checkPermissionSendInvoice,
  checkPermissionRegenerateInvoice,
  checkPermissionApproveInvoice,
  checkPermissionResyncInvoice,
  checkPermissionUndoManualInvoice,
  checkPermissionGenerateInvoice,
  checkPermissionExtendInvoiceDueDate,
  checkPermissionUndoInvoiceCancel,
  checkPermissionResendToVendor,
} from '../../../CustomerPage/CheckPermission';
import TitleFrom from '../../../../components/common/TitleFrom';
import { InvoicingSearchForm } from '../../../../components/ReportHub';
import { getUserId } from '../../../../localStorages';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  ModalWithItem,
  GenericInput,
  Switch,
  FormCollapse,
  CheckField,
  ModalAccept,
  ModalViewNote,
  DragDropConfigExportTemplate,
} from '../../../../components/common';
import ModalAccountId from '../../../../components/common/ModalAccountId';
import ModalSelectBillableService from '../../../../components/common/ModalSelectBillableService';
import ModalSearchItem from '../../../../components/common/ModalSearchItem';
import { sortInvoicing } from '../../constants';
import { getInvoicing, getAllInvoicing } from '../../actions';
import { makeGetListInvoicing, makeGetListAllInvoicing } from '../../selectors';
import {
  getPageTotalCount,
  formatMoneyValue,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  formatNumberCaculation,
  messageCSVStamping,
  messageNotesBills,
  configOutputTemplateByType,
  parseToMutationRequestBulk,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import convertJson2SheetInvoiceSummary from '../../../../utils/ExcelHelper/exportExcelInvoiceSummary';
import convertJson2PdfInvoiceSummary from '../../../../utils/PdfHelper/exportPdfInvoiceSummary';
import { PAGE_SIZE_DOWNLOAD, statusDisableFielsdViewFolio } from '../../../../utils/constants';

let invoiceUnitIdSelected = '';
let oldAccounntId = '';

let tableColumnsDefault = null;
let tableColumnsOutput = null;

const messageNotesAction = t => <div dangerouslySetInnerHTML={{ __html: t('message.messgaeNoteInvoiceAction') }} />;

const checkExistStatus = ({ listItemSelect, statusList }) => {
  return !!listItemSelect.find(val => statusList.indexOf(val.status) !== -1);
};

const checkExistMatchAllStatus = ({ listItemSelect, statusList }) => {
  return !!listItemSelect.find(val => statusList.indexOf(val.status) === -1);
};

const checkExistInvoiceTotal = ({ listItemSelect }) => {
  return !!listItemSelect.find(val => Number.parseFloat(val.invoiceTotal) <= 0);
};

const checkMatchAllBillType = ({ listItemSelect, billTypeList }) => {
  return !!listItemSelect.find(val => billTypeList.indexOf(val.billType) === -1);
};

const checkMatchAllFolioStatus = ({ listItemSelect, folioStatusList }) => {
  return !!listItemSelect.find(val => folioStatusList.indexOf(val.folioStatus) === -1);
};

const checkExistInvoiceDue = ({ listItemSelect }) => {
  return !!listItemSelect.find(val => Number.parseFloat(val.invoiceDue) <= 0);
};

const listInvoiceFields = [
  {
    label: 'label.invoiceId',
    name: 'id',
    readOnly: true,
  },
  {
    label: 'label.invoiceTotal',
    name: 'total',
    readOnly: true,
    isFormatMoneyOfValue: true,
  },
  {
    label: 'label.invoiceDue',
    name: 'due',
    readOnly: true,
    isFormatMoneyOfValue: true,
  },
  {
    label: 'label.dueDate',
    name: 'dueDate',
    readOnly: true,
  },
];

const collapsibleMenuTransactionId = [
  {
    title: 'common:label.invoiceLines',
    state: 'invoiceLines',
  },
  {
    title: 'common:label.taxLines',
    state: 'taxLines',
  },
  {
    title: 'common:label.invoiceSummary',
    state: 'invoiceSummary',
  },
];

const tableTaxLines = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'state',
    label: 'label.state',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
  },
  {
    name: 'amount',
    label: 'label.amount',
    isAmount: true,
  },
  {
    name: 'taxRate',
    label: 'label.taxableRate',
    isAmount: true,
  },
  {
    name: 'taxableAmount',
    label: 'label.taxableAmount',
    isAmount: true,
  },
  {
    name: 'exemptAmount',
    label: 'label.exemptAmount',
    isAmount: true,
  },
];

const listFields = [
  {
    label: 'label.count',
    name: 'numOfInstallment',
    type: 'number',
  },
  {
    label: 'label.daysOffset',
    name: 'daysOffset',
    type: 'number',
  },
  {
    label: 'label.monthOffset',
    name: 'monthOffset',
    type: 'number',
  },
];

const installmentsColumns = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'percent',
    label: 'common:label.percent',
  },
  {
    name: 'daysOffset',
    label: 'common:label.daysOffset',
  },
  {
    name: 'monthsOffset',
    label: 'common:label.monthsOffset',
  },
  {
    name: 'dueDate',
    label: 'common:label.dueDate',
  },
  {
    name: 'paymentDate',
    label: 'common:label.paymentDate',
  },
  {
    name: 'amount',
    label: 'common:label.installmentAmt',
  },
  {
    name: 'paidAmount',
    label: 'common:label.paymentAmt',
  },
  {
    name: 'installmentStatus',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:installmentStatus')().find(val => val.value === item.installmentStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const tableInvoiceSummary = [
  {
    name: 'description',
    label: 'label.name',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'taxableAmount',
    label: 'label.amount',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'ieps',
    label: 'label.ieps',
  },
  {
    name: 'iva',
    label: 'label.iva',
  },
  {
    name: 'appliedStart',
    label: 'label.startDate',
  },
  {
    name: 'appliedEnd',
    label: 'label.endDate',
  },
];

let fileNameExport = '';

class Invoicing extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listInvoicing: [],
    currencyOptions: [],
    primaryTaxType: '',
    addOnTaxType: '',
    paymentInvoiceData: {},
    dataSave: {},
    activeTab: {},
    costCenterOptions: [],
    itemSelectInvoiceLine: [],
    isSelectAll: false,
    isOpenModalAccountId: false,
    accountIdSelected: null,
    isInvoiceApproval: 'false',
    defaultCostCenter: null,
    isEnableBilledServiceName: false,
    isPacEnabled: false,
    indexServiceNameSelect: null,
    isOpenModalServiceName: false,
    indexItemIdSelect: null,
    itemIdSelect: null,
    isOpenModalItem: false,
    wasValidatedInvoice: false,
    isOpenModalProcess: false,
    itemSelect: null,
    invoiceUnitId: null,
    type: null,
    invoiceNotes: '',
    customMessage: '',
    emailId: '',
    poNumber: '',
    dueDate: '',
    noteItem: '',
    wasValidated: false,
    isOpenModalStamping: false,
    stampingsData: [],
    isOpenModalStampingCSVData: false,
    csvData: null,
    paymentInstallmentsData: [],
    isOpenModalInstallments: false,
    dataCreate: null,
    itemSelectInstallments: null,
    noteContent: null,
    isOpenModalNotes: false,
    isOpenModalNotesAction: false,
    listItemSelect: [],
    listInvoiceUnitId: [],
    isActivePdfInvoiceSummary: false,
    invoiceSummaryListPerTax: [],
  };

  buttonRef = React.createRef();

  buttonRefInvoiceSummary = React.createRef();

  formRefInvoice = React.createRef();

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listInvoicing: props.listInvoicing,
      totalCount: getPageTotalCount({ ...state, items: props.listInvoicing }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetInvoiceReport },
      getCurrencyConfigApp,
      getCcpPropertiesConfig,
      getCostCenterConfig,
      isBilling,
    } = this.props;
    if (modeGetInvoiceReport) {
      const { location } = this.props;
      if (location && location.state) {
        const { filter, page, size, sort, sorted, totalCount } = location.state;
        this.setState({ filter, page, size, sort, sorted, totalCount }, () => {
          this.doGetReportInvoicing();
        });
      } else {
        this.doGetReportInvoicing();
      }

      getCcpPropertiesConfig('', ({ success, data }) => {
        let primaryTaxType = '';
        let addOnTaxType = '';
        let isInvoiceApproval = 'false';
        let defaultCostCenter = null;
        let isEnableBilledServiceName = false;
        let isPacEnabled = false;
        if (success) {
          const primaryTaxTypeConfig = data.ccpPropertyList.find(val => val.property === 'primaryTaxType');
          const addOnTaxTypeConfig = data.ccpPropertyList.find(val => val.property === 'addOnTaxType');
          const invoiceApproval = data.ccpPropertyList.find(val => val.property === 'invoiceApproval');
          const defaultCostCenterProperty = data.ccpPropertyList.find(val => val.property === 'defaultCostCenter');
          const billedServiceProperty = data.ccpPropertyList.find(val => val.property === 'billableServiceEnabled');
          const pacEnabledProperty = data.ccpPropertyList.find(val => val.property === 'pacEnabled');
          if (primaryTaxTypeConfig && primaryTaxTypeConfig.value) {
            primaryTaxType = primaryTaxTypeConfig.value;
          }
          if (addOnTaxTypeConfig && addOnTaxTypeConfig.value) {
            addOnTaxType = addOnTaxTypeConfig.value;
          }
          if (invoiceApproval && invoiceApproval.value) {
            isInvoiceApproval = invoiceApproval.value;
          }
          if (defaultCostCenterProperty && defaultCostCenterProperty.value) {
            defaultCostCenter = defaultCostCenterProperty.value;
          }
          if (billedServiceProperty && billedServiceProperty.value) {
            isEnableBilledServiceName = billedServiceProperty.value;
          }
          if (pacEnabledProperty && pacEnabledProperty.value) {
            isPacEnabled = pacEnabledProperty.value === 'true';
          }
        }
        this.setState(
          {
            primaryTaxType,
            addOnTaxType,
            isInvoiceApproval,
            defaultCostCenter,
            isEnableBilledServiceName,
            isPacEnabled,
          },
          () => this.doGetOutputTemplateByType()
        );
      });
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
      getCostCenterConfig({ showActiveOnly: 'true' }, ({ success, data }) => {
        if (success && data && data.length) {
          const costCenterOptions = data.map(val => {
            const { code, name } = val;
            return { label: `${code}-${name}`, value: code };
          });
          this.setState({ costCenterOptions });
        }
      });
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetReportInvoicing();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetReportInvoicing());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportInvoicing());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetReportInvoicing();
    });
  };

  doGetReportInvoicing = () => {
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (newFilter.groupBy) newFilter.groupBy = newFilter.groupBy === 'TRUE' ? 'true' : 'false';
    const { getInvoicing } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) && sortInvoicing[sorted.sortCol] ? sortInvoicing[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getInvoicing(payload, () => {
      this.setState({
        isSearching: false,
      });
    });
  };

  doGetAllListInvoicing = cb => {
    const { filter, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (newFilter.groupBy) newFilter.groupBy = newFilter.groupBy === 'TRUE' ? 'true' : 'false';
    const { getAllInvoicing } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortInvoicing[sorted.sortCol][sorted.sortDir] : null,
    };
    getAllInvoicing(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAllListInvoicing(() => {
      const { isActivePdf, primaryTaxType, addOnTaxType } = this.state;
      if (isActivePdf) {
        const { listAllInvoicing, t, isBilling } = this.props;
        const labelAddOnTaxRate = `${addOnTaxType || ''} ${t('label.taxRate')}`;
        const labelAddOnTaxAmount = `${addOnTaxType || ''} ${t('label.taxAmount')}`;
        const labelPrimaryTaxRate = `${primaryTaxType || ''} ${t('label.taxRate')}`;
        const labelPrimaryTaxAmount = `${primaryTaxType || ''} ${t('label.taxAmount')}`;
        convertJson2Pdf({
          data: listAllInvoicing,
          t,
          title: t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing').toLocaleUpperCase(),
          fileName: `${t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          labelAddOnTaxRate,
          labelAddOnTaxAmount,
          labelPrimaryTaxRate,
          labelPrimaryTaxAmount,
          columnsTable: tableColumnsOutput,
          fontSize: 2,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  onExportInvoiceSummary = () => {
    const { invoiceSummaryListPerTax, isActivePdfInvoiceSummary } = this.state;
    const { t } = this.props;
    if (isActivePdfInvoiceSummary) {
      convertJson2PdfInvoiceSummary({
        data: invoiceSummaryListPerTax && invoiceSummaryListPerTax.length ? invoiceSummaryListPerTax : [],
        t,
        title: t('label.invoiceSummary').toLocaleUpperCase(),
        fileName: fileNameExport,
      });
    } else {
      this.buttonRefInvoiceSummary.current.click();
    }
  };

  doGetObjectFileById = invoiceId => {
    const { history, getObjectFileById, isBilling } = this.props;
    const { filter, page, size, sort, sorted, totalCount } = this.state;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: isBilling ? RouteNames.invoicingBilling.path : RouteNames.invoicingReport.path,
            fileData: data,
            filter,
            page,
            size,
            sort,
            sorted,
            totalCount,
          },
        });
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAllListInvoicing(() => {
      const { listAllInvoicing, t, uploadMultiPartFiles, isBilling } = this.props;
      const { emails, isActivePdf, primaryTaxType, addOnTaxType } = this.state;
      const labelAddOnTaxRate = `${addOnTaxType || ''} ${t('label.taxRate')}`;
      const labelAddOnTaxAmount = `${addOnTaxType || ''} ${t('label.taxAmount')}`;
      const labelPrimaryTaxRate = `${primaryTaxType || ''} ${t('label.taxRate')}`;
      const labelPrimaryTaxAmount = `${primaryTaxType || ''} ${t('label.taxAmount')}`;
      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_INVOICE_REPORT',
          subject: subject || `Embrix Report ${t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllInvoicing,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              invoiceType: 'invoiceType',
              status: 'invoiceUnitStatus',
              paymentMethod: 'paymentMethod',
              billType: 'billUnitType',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllInvoicing,
          t,
          title: t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing').toLocaleUpperCase(),
          fileName: `${t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          labelAddOnTaxRate,
          labelAddOnTaxAmount,
          labelPrimaryTaxRate,
          labelPrimaryTaxAmount,
          columnsTable: tableColumnsOutput,
          fontSize: 2,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  onToggleModalDetails = ({ item }) => {
    const { isOpenModalDetails } = this.state;
    const { getInvoiceUnitByIdSelect, getSplitTaxPerLineConfig, t } = this.props;
    this.setState({ paymentInvoiceData: {} });
    if (!isOpenModalDetails && item) {
      if (item.id) {
        getInvoiceUnitByIdSelect(item.id, ({ success, data }) => {
          invoiceUnitIdSelected = item.id;
          this.setState({ paymentInvoiceData: success ? data : {} });
        });
        fileNameExport = `${item?.accountId}_${item.id ? `${item.id}_` : ''}${t('label.invoiceSummary')
          .toLocaleLowerCase()
          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`;
        getSplitTaxPerLineConfig({ objectId: item.id, objectType: 'INVOICE' }, ({ success, data }) => {
          this.setState({ invoiceSummaryListPerTax: success ? data || [] : [], isActivePdfInvoiceSummary: false });
        });
      }
    }
    oldAccounntId = item?.accountId || null;
    this.setState({ isOpenModalDetails: !isOpenModalDetails, accountIdSelected: item?.accountId || null });
  };

  onAddNewLine = () => {
    const { paymentInvoiceData, costCenterOptions, itemSelectInvoiceLine, defaultCostCenter } = this.state;
    const newData = cloneDeep(paymentInvoiceData);
    const itemSelect = cloneDeep(itemSelectInvoiceLine);
    let lastIndex = 0;
    newData.invoiceTransactionAttributesList.forEach(item => {
      lastIndex += 1;
    });
    let costCenter = null;
    if (
      costCenterOptions &&
      costCenterOptions.length &&
      defaultCostCenter &&
      costCenterOptions.find(val => val.value === defaultCostCenter)
    ) {
      costCenter = costCenterOptions.find(val => val.value === defaultCostCenter).value;
    }
    newData.invoiceTransactionAttributesList.push({
      index: -1,
      name: '',
      quantity: '',
      unitPrice: '',
      amount: '',
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      mappedIndex: lastIndex + 1,
      costCenter,
    });
    itemSelect.push({
      index: -1,
      name: '',
      quantity: '',
      unitPrice: '',
      amount: '',
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      mappedIndex: lastIndex + 1,
      costCenter,
    });
    this.setState({ itemSelectInvoiceLine: itemSelect, paymentInvoiceData: newData });
  };

  onToggleModalItemId = ({ index, item }) => {
    if (item) {
      this.setState({ indexItemIdSelect: index, itemIdSelect: item, isOpenModalItem: true });
    } else {
      this.setState({ indexItemIdSelect: null, itemIdSelect: null, isOpenModalItem: false });
    }
  };

  onChangeItem = ({ name, value, index }) => {
    const { paymentInvoiceData, itemSelectInvoiceLine } = this.state;
    const newData = cloneDeep(paymentInvoiceData);
    newData.invoiceTransactionAttributesList[index][name] = value;
    if (newData.invoiceTransactionAttributesList[index].mappedIndex) {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.mappedIndex === newData.invoiceTransactionAttributesList[index].mappedIndex
      );
      itemSelectInvoiceLine[indexItemSelectChange][name] = value;
    } else {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.index === newData.invoiceTransactionAttributesList[index].index
      );
      itemSelectInvoiceLine[indexItemSelectChange][name] = value;
    }
    this.setState({
      itemSelectInvoiceLine: [...itemSelectInvoiceLine],
      paymentInvoiceData: newData,
    });
  };

  renderItem = item => {
    const {
      itemSelectInvoiceLine,
      isCancelAndSubstitute,
      isEnableBilledServiceName,
      paymentInvoiceData,
      currencyOptions,
      costCenterOptions,
      invoiceSummaryListPerTax,
      isActivePdfInvoiceSummary,
    } = this.state;
    const { t } = this.props;

    const billablesServiceColumn = [];
    if (isCancelAndSubstitute) {
      billablesServiceColumn.push({
        name: 'serviceName',
        label: 'label.billableServiceName',
        style: { minWidth: '280px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item.serviceName}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={() => {}}
              name="serviceName"
              disabled={
                !isEnableBilledServiceName ||
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              fa="fa fa-external-link"
              onClick={() => this.onToggleModalServiceName({ index, item })}
            />
          );
        },
      });
    }
    const tableInvoiceLines = [
      {
        name: 'index',
        label: 'label.index',
      },
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item, index) => {
          return (
            <CheckField
              name="selected"
              value={item && item.isSelected}
              checked={
                item.mappedIndex
                  ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : itemSelectInvoiceLine.find(val => val.index === item.index)
              }
              onChange={evt => this.onSelectItem({ evt, item, index })}
              disabled={!isCancelAndSubstitute}
            />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.name}
              wrapperClass="col-md-12 read-only-disable-field"
              onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
              name="name"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'startDate',
        label: 'label.startDate',
        required: true,
        style: { minWidth: '220px' },
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.startDate || item.appliedStart || moment(new Date()).format('YYYY-MM-DD')}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
              name="startDate"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              type="date"
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'endDate',
        label: 'label.endDate',
        required: true,
        style: { minWidth: '220px' },
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.endDate || item.appliedEnd || moment(new Date()).format('YYYY-MM-DD')}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
              name="endDate"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              type="date"
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'itemId',
        label: 'label.itemId',
        style: { minWidth: '200px' },
        required: true,
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.itemId}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={({ name, value }) => {}}
              name="itemId"
              disabled={
                // item.index !== -1 ||
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              fa="fa fa-external-link"
              required={item.index === -1 && isCheckRequired}
              onClick={() => this.onToggleModalItemId({ index, item })}
            />
          );
        },
      },
      ...billablesServiceColumn,
      {
        name: 'costCenter',
        label: t('label.costCenter'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.costCenter}
            options={costCenterOptions || []}
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
            name="costCenter"
            disabled={
              !isCancelAndSubstitute ||
              (item.mappedIndex
                ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                : !itemSelectInvoiceLine.find(val => val.index === item.index))
            }
          />
        ),
      },
      {
        name: 'quantity',
        label: 'label.quantity',
        required: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.quantity}
              wrapperClass="col-md-12 read-only-disable-field"
              onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
              name="quantity"
              type="number"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'currency',
        label: t('label.currency'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.currency}
            wrapperClass="col-md-12 read-only-disable-field"
            onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
            type="select"
            disabled={
              !isCancelAndSubstitute ||
              (item.mappedIndex
                ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                : !itemSelectInvoiceLine.find(val => val.index === item.index))
            }
            name="currency"
            options={currencyOptions || []}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'unitPrice',
        label: 'label.unitPrice',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.unitPrice}
              wrapperClass="col-md-12 read-only-disable-field"
              onChange={({ name, value }) => this.onChangeItem({ name, value, index })}
              name="unitPrice"
              type="number"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'amount',
        label: 'label.amount',
        render: (colName, item) => {
          return (
            <span>
              {formatMoneyValue(Number.parseFloat(item.unitPrice || 0) * Number.parseFloat(item.quantity || 0))}
            </span>
          );
        },
      },
      {
        name: 'gross',
        label: 'label.gross',
        render: (colName, item) => {
          return <span>{formatMoneyValue(Number.parseFloat(item.gross))}</span>;
        },
      },
      {
        name: 'discount',
        label: 'label.discount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(Number.parseFloat(item.discount))}</span>;
        },
      },
    ];

    switch (item.state) {
      case 'invoiceLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableInvoiceLines}
              data={
                paymentInvoiceData.invoiceTransactionAttributesList
                  ? paymentInvoiceData.invoiceTransactionAttributesList
                  : []
              }
            />
            <br />
            <div className="form-group col-md-12">
              <button
                type="button"
                className="button button-border black x-small"
                disabled={!isCancelAndSubstitute}
                onClick={this.onAddNewLine}
              >
                +
                {t('label.addNewLine')}
              </button>
            </div>
            <br />
          </div>
        );
      case 'taxLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableTaxLines}
              data={paymentInvoiceData.invoiceTaxList ? paymentInvoiceData.invoiceTaxList : []}
            />
          </div>
        );

      case 'invoiceSummary':
        return (
          <div className="col-md-12">
            <div className="mt-3 d-flex mb-4 pt-3 col-md-12">
              <div className="ml-auto mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={() => {
                    this.setState({ isActivePdfInvoiceSummary: !isActivePdfInvoiceSummary });
                  }}
                  wrapperClass={isActivePdfInvoiceSummary ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdfInvoiceSummary}
                  rightTitle={t('label.pdf')}
                  name="SwitchExport"
                />
              </div>
              <>
                <ButtonExport onExport={this.onExportInvoiceSummary} />
                <ExcelExport
                  element={<button type="button" className="display-none" ref={this.buttonRefInvoiceSummary} />}
                  nameSheet={t('label.invoiceSummary').toLocaleUpperCase()}
                  multiDataSet={convertJson2SheetInvoiceSummary({
                    data: invoiceSummaryListPerTax && invoiceSummaryListPerTax.length ? invoiceSummaryListPerTax : [],
                    t,
                    title: t('label.invoiceSummary').toLocaleUpperCase(),
                  })}
                  fileName={fileNameExport}
                />
              </>
            </div>
            <DataTable columns={tableInvoiceSummary} data={invoiceSummaryListPerTax || []} />
          </div>
        );

      default:
        return null;
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({ activeTab: { name: activeTabName, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onHandleSelectAll = ({ value }) => {
    const { paymentInvoiceData } = this.state;
    this.setState({ isSelectAll: value });
    if (value) {
      return this.setState({ itemSelectInvoiceLine: [...paymentInvoiceData.invoiceTransactionAttributesList] });
    }
    return this.setState({ itemSelectInvoiceLine: [] });
  };

  onHandleChangeDataSave = ({ name, value }) => {
    const { dataSave } = this.state;
    const newData = cloneDeep(dataSave);
    newData[name] = value;
    this.setState({ dataSave: newData });
  };

  onSelectAccountId = (id, row) => {
    this.setState({ accountIdSelected: id, isOpenModalAccountId: false });
  };

  onToggleModalServiceName = ({ index, item }) => {
    if (item) {
      this.setState({ indexServiceNameSelect: index, serviceNameSelect: item, isOpenModalServiceName: true });
    } else {
      this.setState({ indexServiceNameSelect: null, serviceNameSelect: null, isOpenModalServiceName: false });
    }
  };

  onSelectItemId = id => {
    const { paymentInvoiceData, indexItemIdSelect, itemSelectInvoiceLine } = this.state;
    const newData = cloneDeep(paymentInvoiceData);
    newData.invoiceTransactionAttributesList[indexItemIdSelect].itemId = id;
    if (newData.invoiceTransactionAttributesList[indexItemIdSelect].mappedIndex) {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.mappedIndex === newData.invoiceTransactionAttributesList[indexItemIdSelect].mappedIndex
      );
      itemSelectInvoiceLine[indexItemSelectChange].itemId = id;
    } else {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.index === newData.invoiceTransactionAttributesList[indexItemIdSelect].index
      );
      itemSelectInvoiceLine[indexItemSelectChange].itemId = id;
    }
    // const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
    //   val => val.mappedIndex === indexItemIdSelect || val.index === indexItemIdSelect
    // );
    // itemSelectInvoiceLine[indexItemSelectChange].itemId = id;
    this.setState({
      itemSelectInvoiceLine: [...itemSelectInvoiceLine],
      paymentInvoiceData: newData,
      isOpenModalItem: false,
    });
  };

  onSelectServiceName = (id, row) => {
    const { indexServiceNameSelect, paymentInvoiceData, itemSelectInvoiceLine } = this.state;
    const newData = cloneDeep(paymentInvoiceData);
    newData.invoiceTransactionAttributesList[indexServiceNameSelect].omsInternalId = row.omsInternalId;
    newData.invoiceTransactionAttributesList[indexServiceNameSelect].serviceName = row.serviceName;
    newData.invoiceTransactionAttributesList[indexServiceNameSelect].itemId = row.serviceId;
    if (newData.invoiceTransactionAttributesList[indexServiceNameSelect].mappedIndex) {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.mappedIndex === newData.invoiceTransactionAttributesList[indexServiceNameSelect].mappedIndex
      );
      itemSelectInvoiceLine[indexItemSelectChange].omsInternalId = row.omsInternalId;
      itemSelectInvoiceLine[indexItemSelectChange].serviceName = row.serviceName;
      itemSelectInvoiceLine[indexItemSelectChange].itemId = row.serviceId;
    } else {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.index === newData.invoiceTransactionAttributesList[indexServiceNameSelect].index
      );
      itemSelectInvoiceLine[indexItemSelectChange].omsInternalId = row.omsInternalId;
      itemSelectInvoiceLine[indexItemSelectChange].serviceName = row.serviceName;
      itemSelectInvoiceLine[indexItemSelectChange].itemId = row.serviceId;
    }
    this.setState({
      itemSelectInvoiceLine: [...itemSelectInvoiceLine],
      paymentInvoiceData: newData,
      indexServiceNameSelect: null,
      serviceNameSelect: null,
      isOpenModalServiceName: false,
    });
  };

  validateInvoice = (out = false) => {
    const formValid = this.formRefInvoice && this.formRefInvoice.current.checkValidity();
    const { elements } = this.formRefInvoice.current;
    const { t } = this.props;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onSaveManualInvoice = evt => {
    evt.preventDefault();
    this.setState({ wasValidatedInvoice: true });
    if (!this.validateInvoice(true)) {
      return false;
    }
    const { itemSelectInvoiceLine, accountIdSelected, dataSave, isSubstituteInvoice } = this.state;
    const { saveManualInvoiceConfig } = this.props;
    const manualPreratedTxnRec = cloneDeep(itemSelectInvoiceLine).map(val => {
      const {
        quantity,
        unitPrice,
        startDate,
        endDate,
        itemId,
        costCenter,
        currency,
        discount,
        appliedEnd,
        appliedStart,
        serviceName,
        omsInternalId,
      } = val;
      return {
        // name,
        quantity,
        unitPrice,
        startDate: startDate || appliedStart || moment(new Date()).format('YYYY-MM-DD'),
        endDate: endDate || appliedEnd || moment(new Date()).format('YYYY-MM-DD'),
        itemId: itemId || null,
        serviceName: serviceName || null,
        omsInternalId: omsInternalId || null,
        grossAmount: formatNumberCaculation(Number.parseFloat(quantity || 0) * Number.parseFloat(unitPrice || 0)),
        netAmount:
          formatNumberCaculation(
            Number.parseFloat(quantity || 0) * Number.parseFloat(unitPrice || 0) - Number.parseFloat(discount)
          ) || 0,
        costCenter: costCenter || null,
        currency: currency || null,
        discountAmount: discount,
        accountId: accountIdSelected || null,
      };
    });
    return saveManualInvoiceConfig(
      {
        manualPreratedTxnRec,
        accountId: accountIdSelected || null,
        scheduleDate: dataSave.scheduleDate || null,
        scheduleDaily: dataSave.scheduleDaily ? (dataSave.scheduleDaily === 'TRUE' ? 'true' : 'false') : null,
        billStartDate: dataSave.invoiceId ? dataSave.startDate : null,
        billEndDate: dataSave.invoiceId ? dataSave.endDate : null,
        substituteInvoice: isSubstituteInvoice === 'TRUE' ? 'true' : 'false',
        // satCode: isSubstituteInvoice === 'TRUE' ? satCode || null : null,
        invoiceId: isSubstituteInvoice === 'TRUE' ? invoiceUnitIdSelected : null,
      },
      ({ success }) => {
        if (success) {
          this.setState({
            itemSelectInvoiceLine: [],
            isCancelAndSubstitute: false,
            isOpenModalDetails: false,
            activeTab: {},
            isSubstituteInvoice: 'FALSE',
            wasValidatedInvoice: false,
            isSelectAll: false,
          });
        }
      }
    );
  };

  doReprocessInvoice = evt => {
    evt.preventDefault();
    this.setState({ wasValidatedInvoice: true });
    if (!this.validateInvoice(true)) {
      return false;
    }
    const { itemSelectInvoiceLine, accountIdSelected, isSubstituteInvoice } = this.state;
    const { reprocessInvoiceConfig } = this.props;
    const newPayload = cloneDeep(itemSelectInvoiceLine).map(val => {
      const {
        index,
        name,
        quantity,
        unitPrice,
        startDate,
        endDate,
        itemId,
        costCenter,
        currency,
        appliedStart,
        appliedEnd,
        serviceName,
        omsInternalId,
      } = val;
      return {
        index,
        name,
        quantity,
        unitPrice,
        amount: formatNumberCaculation(Number.parseFloat(unitPrice || 0) * Number.parseFloat(quantity || 0)),
        startDate: startDate || appliedStart || moment(new Date()).format('YYYY-MM-DD'),
        endDate: endDate || appliedEnd || moment(new Date()).format('YYYY-MM-DD'),
        itemId: itemId || null,
        costCenter: costCenter || null,
        currency: currency || null,
        serviceName: serviceName || null,
        omsInternalId: omsInternalId || null,
      };
    });
    reprocessInvoiceConfig(
      {
        accountId: accountIdSelected || null,
        lines: newPayload,
        invoiceId: invoiceUnitIdSelected,
        substituteInvoice: isSubstituteInvoice === 'TRUE' ? 'true' : 'false',
        // satCode: isSubstituteInvoice === 'TRUE' ? satCode || null : null,
        userId: getUserId(),
      },
      ({ success }) => {
        if (success) {
          this.setState({
            itemSelectInvoiceLine: [],
            isCancelAndSubstitute: false,
            isOpenModalDetails: false,
            activeTab: {},
            isSubstituteInvoice: 'FALSE',
            wasValidatedInvoice: false,
            isSelectAll: false,
            isOpenModalProcess: false,
          });
        }
      }
    );
  };

  doModifyInvoiceStatus = (item, status) => {
    const { modifyInvoiceStatusConfig } = this.props;
    const { invoiceNotes } = this.state;
    if (item.length === 1) {
      const payloads = item.map(val => {
        return { invoiceId: val.id, accountId: val?.accountId || null, status, invoiceNotes: invoiceNotes || null };
      });
      modifyInvoiceStatusConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportInvoicing();
          this.setState({
            invoiceUnitId: null,
            type: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
            invoiceNotes: null,
          });
        }
      });
    } else {
      const payloads = item.map(val => {
        const payload = {
          invoiceId: val.id,
          accountId: val?.accountId || null,
          status,
          invoiceNotes: invoiceNotes || null,
        };
        return parseToMutationRequestBulk(payload);
      });
      let statusConfig = 'APPROVE_INVOICE';
      if (status === 'DISAPPROVED') statusConfig = 'DISAPPROVE_INVOICE';
      if (status === 'SENT') statusConfig = 'RESYNC_INVOICE';
      this.doBulkProcess({
        apiName: statusConfig,
        serviceName: 'modifyInvoiceStatus',
        payloads,
      });
    }
  };

  doRegenerateInvoice = () => {
    const { listInvoiceUnitId, poNumber, customMessage } = this.state;
    const { t, regenerateInvoiceConfig } = this.props;
    if (!listInvoiceUnitId || !listInvoiceUnitId.length) return toast.error(t('message.validateInvoiceNumber'));
    if (listInvoiceUnitId.length === 1) {
      const payloads = listInvoiceUnitId.map(val => {
        return { invoiceId: val, poNumber: poNumber || null, customMessage: customMessage || null };
      });
      return regenerateInvoiceConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportInvoicing();
          this.setState({
            invoiceUnitId: null,
            type: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }
    const payloads = listInvoiceUnitId.map(val => {
      const payload = { invoiceId: val, poNumber: poNumber || null, customMessage: customMessage || null };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'REGENERATE_INVOICE',
      serviceName: 'regenerateInvoice',
      payloads,
    });
  };

  doUndoInvoiceCancel = () => {
    const { undoInvoiceCancelConfig } = this.props;
    const { listItemSelect } = this.state;
    if (listItemSelect.length === 1) {
      const payloads = listItemSelect.map(val => {
        return { id: val.id };
      });
      return undoInvoiceCancelConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportInvoicing();
          this.setState({
            itemSelect: null,
            invoiceUnitId: null,
            type: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }
    const payloads = listItemSelect.map(val => {
      const payload = { id: val.id };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'UNDO_INVOICE_CANCEL',
      serviceName: 'undoInvoiceCancel',
      payloads,
    });
  };

  sendInvoiceToMail = () => {
    const { emailId, listInvoiceUnitId, listItemSelect } = this.state;
    const { t, sendInvoiceConfig } = this.props;
    if (emailId && !validateEmail(emailId)) return toast.error(t('message.validateEmail'));
    if (!listInvoiceUnitId || !listInvoiceUnitId.length) return toast.error(t('message.validateInvoiceNumber'));
    if (listItemSelect.length === 1) {
      const payloads = listItemSelect.map(val => {
        return { accountId: val?.accountId || null, invoiceUnitId: val?.id, emailId };
      });
      return sendInvoiceConfig(payloads[0], ({ success }) => {
        if (success) {
          this.setState({
            itemSelect: null,
            invoiceUnitId: null,
            type: null,
            emailId: '',
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }
    const payloads = listItemSelect.map(val => {
      const payload = { accountId: val?.accountId || null, invoiceUnitId: val?.id, emailId };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'EMAIL_INVOICE',
      serviceName: 'sendInvoice',
      payloads,
    });
  };

  doUndoManualInvoice = () => {
    const { listInvoiceUnitId, listItemSelect } = this.state;
    const { t, undoManualInvoiceConfig } = this.props;
    if (!listInvoiceUnitId || !listInvoiceUnitId.length) return toast.error(t('message.validateInvoiceNumber'));

    if (listItemSelect.length === 1) {
      const payloads = listItemSelect.map(val => {
        return { billUnitid: val?.id };
      });
      return undoManualInvoiceConfig(payloads[0], ({ success }) => {
        if (success) {
          this.setState({
            itemSelect: null,
            invoiceUnitId: null,
            type: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }

    const payloads = listItemSelect.map(val => {
      const payload = { billUnitid: val?.id };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'UNDO_MANUAL_INVOICE',
      serviceName: 'undoInvoice',
      payloads,
    });
  };

  // doInvoiceAccount = () => {
  //   const { t, undoManualInvoiceConfig } = this.props;

  //   invoiceAccount({ billUnitId, accountId: id }, ({ success }) => {
  //     if (success) {
  //       setItemSelect(null);
  //       setInvoiceUnitId('');
  //       setBillUnitId(null);
  //       doGetOCBill();
  //     }
  //   });
  // };

  validate = (out = false) => {
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    const { t } = this.props;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doModifyInvoiceDueDate = (evt, item) => {
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { noteItem, dueDate } = this.state;
    const { modifyInvoiceDueDateConfig } = this.props;
    if (item && item.length === 1) {
      const payloads = item.map(val => {
        return { invoiceId: val.id, invoiceNotes: noteItem, dueDate: dueDate || null };
      });
      return modifyInvoiceDueDateConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportInvoicing();
          this.setState({
            itemSelect: null,
            dueDate: null,
            type: null,
            noteItem: null,
            invoiceUnitId: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }

    const payloads = item.map(val => {
      const payload = { invoiceId: val.id, invoiceNotes: noteItem, dueDate: dueDate || null };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'EXTEND_DUE_DATE',
      serviceName: 'modifyInvoiceDueDate',
      payloads,
    });
  };

  doResendInvoiceVendor = () => {
    const { listItemSelect } = this.state;
    const { resendInvoiceVendorConfig } = this.props;

    if (listItemSelect && listItemSelect.length === 1) {
      const payloads = listItemSelect.map(val => {
        return { id: val.id };
      });

      return resendInvoiceVendorConfig(payloads[0], ({ success }) => {
        if (success) {
          this.doGetReportInvoicing();
          this.setState({
            itemSelect: null,
            dueDate: null,
            type: null,
            noteItem: null,
            invoiceUnitId: null,
            listItemSelect: [],
            listInvoiceUnitId: [],
          });
        }
      });
    }

    const payloads = listItemSelect.map(val => {
      const payload = { id: val.id };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'RESEND_INVOICE_TO_VENDOR',
      serviceName: 'resendInvoiceVendor',
      payloads,
    });
  };

  onToggleModalStamping = item => {
    const { getPacInterfaceRecordByIdConfig } = this.props;
    const { isOpenModalStamping } = this.state;
    if (!isOpenModalStamping && item) {
      getPacInterfaceRecordByIdConfig(item.id, ({ success, data }) => {
        this.setState({ stampingsData: success ? data : [] });
      });
    }
    this.setState({ isOpenModalStamping: !isOpenModalStamping });
  };

  onToggleModalStampingCSVData = item => {
    const { isOpenModalStampingCSVData } = this.state;
    if (!isOpenModalStampingCSVData && item) {
      this.setState({ csvData: item.csvRecord });
    }
    this.setState({ isOpenModalStampingCSVData: !isOpenModalStampingCSVData });
  };

  onOpenModalViewNotes = item => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes, noteContent: item.invoiceNotes });
    // setOpenModalNote(!isOpenModalNotes);
    // setNoteContent(item.invoiceNotes);
  };

  doGetInvoiceInstallmentsByInvoiceId = invoiceUnitId => {
    const { getInvoiceInstallmentsByInvoiceIdConfig } = this.props;
    getInvoiceInstallmentsByInvoiceIdConfig(invoiceUnitId, ({ success, data }) => {
      this.setState({ paymentInstallmentsData: success ? data : [] });
    });
  };

  onToggleModalInstallments = item => {
    const { isOpenModalInstallments } = this.state;
    if (!isOpenModalInstallments && item) {
      invoiceUnitIdSelected = item.id;
      this.doGetInvoiceInstallmentsByInvoiceId(item.id);
    }
    this.setState({
      isOpenModalInstallments: !isOpenModalInstallments,
      dataCreate: null,
      itemSelectInstallments: item || null,
    });
  };

  onCreatePaymentInstallment = () => {
    const { createPaymentInstallmentConfig } = this.props;
    const { dataCreate } = this.state;
    createPaymentInstallmentConfig(dataCreate, ({ success }) => {
      if (success) {
        this.doGetInvoiceInstallmentsByInvoiceId(invoiceUnitIdSelected);
      }
    });
  };

  onHandleChange = ({ name, value }) => {
    const { dataCreate } = this.state;
    const newData = cloneDeep(dataCreate);
    if (value && Number.parseFloat(value) < 0 && !Number.isNaN(value)) return '';
    newData[name] = value;
    this.setState({ dataCreate: newData });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('INVOICING_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  onToggleModalNotesAction = () => {
    const { isOpenModalNotesAction } = this.state;
    this.setState({ isOpenModalNotesAction: !isOpenModalNotesAction });
  };

  doBulkProcess = ({ serviceName, apiName, payloads }) => {
    const { bulkProcess } = this.props;
    bulkProcess({ serviceName, apiName, payloads }, ({ success }) => {
      if (success) {
        this.doGetReportInvoicing();
        this.setState({
          invoiceUnitId: null,
          dueDate: null,
          type: null,
          noteItem: null,
          listItemSelect: [],
          listInvoiceUnitId: [],
          invoiceNotes: null,
        });
      }
    });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listInvoicing,
      currencyOptions,
      primaryTaxType,
      addOnTaxType,
      emails,
      isOpenModalDetails,
      wasValidatedInvoice,
      dataSave,
      accountIdSelected,
      paymentInvoiceData,
      isCancelAndSubstitute,
      isSubstituteInvoice,
      isSelectAll,
      activeTab,
      itemSelectInvoiceLine,
      isOpenModalAccountId,
      isOpenModalServiceName,
      serviceNameSelect,
      isOpenModalItem,
      itemIdSelect,
      isOpenModalProcess,
      itemSelect,
      isInvoiceApproval,
      invoiceUnitId,
      type,
      poNumber,
      customMessage,
      invoiceNotes,
      emailId,
      wasValidated,
      dueDate,
      noteItem,
      isOpenModalStamping,
      stampingsData,
      isOpenModalStampingCSVData,
      csvData,
      isOpenModalInstallments,
      paymentInstallmentsData,
      dataCreate,
      itemSelectInstallments,
      noteContent,
      isOpenModalNotes,
      exportColumnsSave,
      isOpenModalExportConfig,
      isOpenModalNotesAction,
      listInvoiceUnitId,
      listItemSelect,
      isPacEnabled,
    } = this.state;
    const {
      listAllInvoicing,
      t,
      permissions: { modeGetInvoiceReport, modeDownloadReport, modeGetMailReport },
      isBilling,
      getOrderSummaryByAccountIdConfig,
      permissionsInvoice,
      location,
    } = this.props;
    if (!modeGetInvoiceReport) return '';
    const labelAddOnTaxRate = `${addOnTaxType || ''} ${t('label.taxRate')}`;
    const labelAddOnTaxAmount = `${addOnTaxType || ''} ${t('label.taxAmount')}`;
    const labelPrimaryTaxRate = `${primaryTaxType || ''} ${t('label.taxRate')}`;
    const labelPrimaryTaxAmount = `${primaryTaxType || ''} ${t('label.taxAmount')}`;

    let modeApproveInvoice = 0;
    let modeRegenerateInvoice = 0;
    let modeSendInvoice = 0;
    let modeUndoManualInvoice = 0;
    let modeResyncInvoice = 0;
    let modeGenerateInvoice = 0;
    let modeExtendInvoiceDueDate = 0;
    let modeUndoInvoiceCancel = 0;
    let modeResendToVendor = 0;

    if (permissionsInvoice && permissionsInvoice.invoicingModulePermissions) {
      const listPermissionInvoice = permissionsInvoice.invoicingModulePermissions;
      modeSendInvoice = checkPermissionSendInvoice({ listPermission: listPermissionInvoice });
      modeRegenerateInvoice = checkPermissionRegenerateInvoice({ listPermission: listPermissionInvoice });
      modeApproveInvoice = checkPermissionApproveInvoice({ listPermission: listPermissionInvoice });
      modeResyncInvoice = checkPermissionResyncInvoice({ listPermission: listPermissionInvoice });
      modeUndoManualInvoice = checkPermissionUndoManualInvoice({ listPermission: listPermissionInvoice });
      modeGenerateInvoice = checkPermissionGenerateInvoice({ listPermission: listPermissionInvoice });
      modeExtendInvoiceDueDate = checkPermissionExtendInvoiceDueDate({ listPermission: listPermissionInvoice });
      modeUndoInvoiceCancel = checkPermissionUndoInvoiceCancel({ listPermission: listPermissionInvoice });
      modeResendToVendor = checkPermissionResendToVendor({ listPermission: listPermissionInvoice });
    }

    const newColumns = [
      {
        name: 'id',
        label: 'label.id',
        sortable: true,
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              disabled={isPacEnabled && statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
              type="button"
              onClick={() => this.doGetObjectFileById(item.id)}
            >
              {item.id}
            </button>
          );
        },
      },
      {
        name: 'currency',
        label: 'label.currency',
      },
      {
        name: 'status',
        label: 'label.status',
        fieldOption: 'invoiceUnitStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'folioStatus',
        label: 'label.folioStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'invoiceTotal',
        label: 'label.invoiceTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.invoiceTotal)}</span>;
        },
      },
      {
        name: 'clientId',
        label: 'label.clientId',
      },
      {
        name: 'company',
        label: 'label.organization',
      },
      {
        name: 'accountId',
        label: 'label.accountId',
      },
      {
        name: 'firstName',
        label: 'label.firstName',
      },
      {
        name: 'lastName',
        label: 'label.lastName',
      },
      {
        name: 'taxid',
        label: 'label.taxId',
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'startDate',
        label: 'label.billStartDate',
      },
      {
        name: 'endDate',
        label: 'label.billEndDate',
      },
      {
        name: 'invoiceDate',
        label: 'label.invoiceDate',
        // sortable: true,
      },
      {
        name: 'stampingDate',
        label: 'label.stampingDate',
        // sortable: true,
      },
      {
        name: 'dueDate',
        label: 'label.dueDate',
      },
      {
        name: 'description',
        label: 'label.description',
      },
      {
        name: 'invoiceSummaryTotal',
        label: 'label.invoiceSummaryTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.invoiceSummaryTotal)}</span>;
        },
      },
      {
        name: 'totalDiscount',
        label: 'label.discount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.totalDiscount)}</span>;
        },
      },
      {
        name: 'addOnTaxRate',
        label: labelAddOnTaxRate,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.addOnTaxRate)}</span>;
        },
      },
      {
        name: 'addOnTaxAmount',
        label: labelAddOnTaxAmount,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.addOnTaxAmount)}</span>;
        },
      },
      {
        name: 'taxRate',
        label: labelPrimaryTaxRate,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.taxRate)}</span>;
        },
      },
      {
        name: 'taxAmount',
        label: labelPrimaryTaxAmount,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.taxAmount)}</span>;
        },
      },
      {
        name: 'invoiceDue',
        label: 'label.due',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.invoiceDue)}</span>;
        },
      },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        render: (colName, item) => {
          return <span>{item.exchangeRate}</span>;
        },
      },
      {
        name: 'currentDateExchangeRate',
        label: 'label.currentExchangeRate',
        render: (colName, item) => {
          return <span>{item.currentDateExchangeRate}</span>;
        },
      },
      {
        name: 'unRealizedGain',
        label: 'label.unrealizedLossGainInSystemCurrency',
        exportLabel: 'label.unrealizedLossGainInSystemCurrencyExport',
        isSupportRedFront: true,
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.unRealizedGain)}</span>;
        },
      },
      {
        name: 'paymentMethod',
        label: 'label.paymentMethod',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'purchaseOrderId',
        label: 'label.poId',
      },
      {
        name: 'invoiceType',
        label: 'label.invoiceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:invoiceType')().find(val => val.value === item.invoiceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'originalId',
        label: 'label.originalInvoiceRef',
      },
      {
        name: 'billType',
        label: 'label.billType',
        fieldOption: 'billUnitType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:billUnitType')().find(val => val.value === item.billType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'taxCode',
        label: 'label.taxCode',
      },
      {
        name: 'uom',
        label: 'label.uom',
      },
      {
        name: 'uomName',
        label: 'label.uomName',
      },
      {
        name: 'quantity',
        label: 'label.quantity',
      },
      {
        name: 'unitPrice',
        label: 'label.unitPrice',
      },
    ];
    tableColumnsDefault = newColumns;

    const exportColumns = exportColumnsSave || {};

    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    let newColumnsWithView = tableColumnsOutput;
    if (isBilling) {
      newColumnsWithView = [
        {
          name: 'select',
          label: 'common:label.select',
          render: (colName, item) => {
            return (
              <CheckField
                name="select"
                value={!!item.id}
                checked={
                  !!(
                    item.id &&
                    listInvoiceUnitId &&
                    listInvoiceUnitId.length &&
                    listInvoiceUnitId.indexOf(item.id) !== -1
                  )
                }
                onChange={evt => {
                  let newListInvoiceId = cloneDeep(listInvoiceUnitId);
                  let newListItemSelect = cloneDeep(listItemSelect);
                  if (
                    listInvoiceUnitId &&
                    listInvoiceUnitId.length &&
                    listInvoiceUnitId.indexOf(item.id) !== -1 &&
                    !evt.target.checked
                  ) {
                    newListInvoiceId = newListInvoiceId.filter(val => val !== item.id);
                    newListItemSelect = newListItemSelect.filter(val => val.id !== item.id);
                  } else {
                    newListInvoiceId = [...newListInvoiceId, item.id];
                    newListItemSelect = [...newListItemSelect, item];
                  }
                  this.setState({
                    listInvoiceUnitId: newListInvoiceId,
                    listItemSelect: newListItemSelect,
                    type: null,
                  });
                }}
                readOnly
              />
            );
          },
        },
        {
          name: 'stamping',
          label: 'common:label.stamping',
          render: (colName, item) => {
            let isDisableButton = true;
            if (
              item.id &&
              (item.folioStatus === 'STAMPING_REQUESTED' ||
                item.folioStatus === 'STAMPED' ||
                item.folioStatus === 'CANCELLATION_STAMPING_REQUESTED' ||
                item.folioStatus === 'CANCELLATION_STAMPED')
            ) {
              isDisableButton = false;
            }
            return (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                disabled={isDisableButton}
                onClick={() => {
                  this.onToggleModalStamping(item);
                }}
              >
                {t('label.view')}
              </button>
            );
          },
        },
        {
          name: 'details',
          label: 'common:label.details',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={() => {
                this.onToggleModalDetails({ item });
              }}
            >
              {t('label.view')}
            </button>
          ),
        },
        {
          name: 'select',
          label: 'label.action',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              disabled={!item.invoiceNotes}
              onClick={() => {
                this.onOpenModalViewNotes(item);
              }}
            >
              {t('label.notes')}
              <i className="fa fa-external-link ml-2" aria-hidden="true" />
            </button>
          ),
        },
        {
          name: 'installments',
          label: 'common:label.installments',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={() => {
                this.onToggleModalInstallments(item);
              }}
            >
              {t('label.view')}
            </button>
          ),
        },
        ...newColumnsWithView,
      ];
    }

    let optionActions = t('selections:typeActionBill')();
    if (listItemSelect && checkExistStatus({ listItemSelect, statusList: ['CLOSED', 'COLLECTION', 'SENT'] })) {
      optionActions = optionActions.filter(val => val.value !== 'DISAPPROVE');
      optionActions = optionActions.filter(val => val.value !== 'APPROVE');
      optionActions = optionActions.filter(val => val.value !== 'UNDO_MANUAL_INVOICE');
    }
    if (
      (listItemSelect && checkExistStatus({ listItemSelect, statusList: ['PENDING_APPROVAL'] })) ||
      (listItemSelect && checkExistInvoiceTotal({ listItemSelect }))
    ) {
      optionActions = optionActions.filter(val => val.value !== 'EMAIL_INVOICE');
      optionActions = optionActions.filter(val => val.value !== 'UNDO_MANUAL_INVOICE');
      optionActions = optionActions.filter(val => val.value !== 'REGENERATE_INVOICE');
    }
    if (
      (listItemSelect && checkExistStatus({ listItemSelect, statusList: ['APPROVED'] })) ||
      isInvoiceApproval === 'false' ||
      (listItemSelect && checkExistInvoiceTotal({ listItemSelect })) ||
      checkExistMatchAllStatus({ listItemSelect, statusList: ['PENDING_APPROVAL'] })
    ) {
      optionActions = optionActions.filter(val => val.value !== 'APPROVE');
      optionActions = optionActions.filter(val => val.value !== 'DISAPPROVE');
    }
    if (listItemSelect && checkExistStatus({ listItemSelect, statusList: ['DISAPPROVED'] })) {
      optionActions = optionActions.filter(val => val.value !== 'DISAPPROVE');
    }
    if (listItemSelect && checkMatchAllBillType({ listItemSelect, billTypeList: ['MANUAL_BILL'] })) {
      optionActions = optionActions.filter(val => val.value !== 'UNDO_MANUAL_INVOICE');
    }
    if (listItemSelect && checkExistMatchAllStatus({ listItemSelect, statusList: ['SYNC_FAILED'] })) {
      optionActions = optionActions.filter(val => val.value !== 'RESYNC_INVOICE');
    }
    if (
      (listItemSelect &&
        checkMatchAllFolioStatus({
          listItemSelect,
          folioStatusList: ['STAMPING_REQUESTED', 'CANCELLATION_STAMPING_REQUESTED'],
        })) ||
      (listItemSelect && checkExistInvoiceTotal({ listItemSelect })) ||
      !modeResendToVendor
    ) {
      optionActions = optionActions.filter(val => val.value !== 'RESEND_TO_VENDOR');
    }
    // if (
    //   (listItemSelect &&
    //     checkMatchAllFolioStatus({
    //       listItemSelect,
    //       folioStatusList: ['CANCELLATION_STAMPING_REQUESTED', 'STAMPING_REQUESTED'],
    //     })) ||
    //   (listItemSelect && checkExistInvoiceTotal({ listItemSelect }))
    // ) {
    //   optionActions = optionActions.filter(val => val.value !== 'UNDO_INVOICE_CANCEL');
    // }
    if (
      (listItemSelect &&
        checkMatchAllFolioStatus({
          listItemSelect,
          folioStatusList: ['CANCELLATION_STAMPING_REQUESTED'],
        })) ||
      (listItemSelect && checkExistInvoiceTotal({ listItemSelect })) ||
      !modeUndoInvoiceCancel
    ) {
      optionActions = optionActions.filter(val => val.value !== 'UNDO_INVOICE_CANCEL');
    }
    optionActions = optionActions.filter(val => val.value !== 'GENERATE_INVOICE');
    // if (invoiceUnitId) {
    //   optionActions = optionActions.filter(val => val.value !== 'GENERATE_INVOICE');
    // }
    // if (!invoiceUnitId) {
    //   optionActions = optionActions.filter(val => val.value !== 'EMAIL_INVOICE');
    //   optionActions = optionActions.filter(val => val.value !== 'REGENERATE_INVOICE');
    //   optionActions = optionActions.filter(val => val.value !== 'RESEND_TO_VENDOR');
    // }
    if (listItemSelect && checkExistInvoiceDue({ listItemSelect })) {
      optionActions = optionActions.filter(val => val.value !== 'EXTEND_DUE_DATE');
    }

    const stampingRecordColumns = [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:pacRecordStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'extractType',
        label: 'common:label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:pacExtractType')().find(val => val.value === item.extractType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'createdDate',
        label: 'common:label.createdDate',
      },
      {
        name: 'objectDate',
        label: 'common:label.modifiedDate',
      },
      {
        name: 'data',
        label: 'common:label.data',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              this.onToggleModalStampingCSVData(item);
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];

    const isMultipleRows = listItemSelect && listItemSelect.length > 1;

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={isBilling ? RouteNames.invoicingBilling.path : RouteNames.report.path}
          titleBtn="back"
          items={[
            isBilling
              ? { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path }
              : { name: t('label.report'), url: RouteNames.report.path },
            isBilling
              ? { name: t('common:label.invoices') }
              : { name: t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing') },
          ]}
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom
            title={t(isBilling ? 'label.invoices' : isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')}
          />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <InvoicingSearchForm
                  onSubmit={this.onHandleSubmitSearch}
                  isMultipleOptionInject
                  optionsInject={{
                    currency: currencyOptions,
                  }}
                  defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                />
              </div>
              <div className={isBilling ? 'mt-5' : ''}>
                {!isBilling && (
                  <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                    <button
                      type="submit"
                      onClick={this.onToggleModalExportConfig}
                      className="button x-small mr-2 btn-config"
                    >
                      {/* {t('label.outputConfig')} */}
                      <i className="ti-list" />
                    </button>
                    {!!modeDownloadReport && (
                      <div className="ml-auto mt-2 mb-auto mr-3">
                        <SwitchExport
                          onChange={this.onChangeSwitch}
                          wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                          title={t('label.excel')}
                          checked={isActivePdf}
                          rightTitle={t('label.pdf')}
                        />
                      </div>
                    )}
                    {!!modeDownloadReport && (
                      <>
                        <ButtonExport onExport={this.onExport} />
                        <ExcelExport
                          element={<button type="button" className="display-none" ref={this.buttonRef} />}
                          nameSheet={t(
                            isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing'
                          ).toLocaleUpperCase()}
                          multiDataSet={convertJson2Sheet({
                            data: listAllInvoicing,
                            t,
                            labelAddOnTaxRate,
                            labelAddOnTaxAmount,
                            labelPrimaryTaxRate,
                            labelPrimaryTaxAmount,
                            title: t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing').toLocaleUpperCase(),
                            columnsTable: tableColumnsOutput,
                          })}
                          fileName={`${t(isBilling ? 'label.invoices' : 'reportPage:sidebar.invoicing')
                            .toLocaleLowerCase()
                            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                        />
                      </>
                    )}
                    {!!modeGetMailReport && (
                      <EmailGroup
                        emails={emails}
                        onChangeEmail={this.onChangeEmail}
                        sendEmail={this.sendEmail}
                        data={listInvoicing}
                      />
                    )}
                  </div>
                )}
                {isBilling && (
                  <div className="col-md-12 pt-3 pl-2 pb-3 d-flex separation-border-top">
                    <button
                      type="button"
                      className="button button-border gray x-small mt-4 default-heigh-btn mr-3 btn-action-note"
                      onClick={this.onToggleModalNotesAction}
                    >
                      {t('label.quickNotes')}
                      <i className="fa fa-external-link ml-2" aria-hidden="true" />
                    </button>
                    <GenericInput
                      value={type}
                      name="type"
                      onChange={({ name, value }) => {
                        this.setState({
                          invoiceNotes: '',
                          customMessage: '',
                          emailId: null,
                          type: value,
                          dueDate: '',
                          noteItem: '',
                        });
                      }}
                      wrapperClass="col-md-3"
                      label="label.action"
                      options={optionActions}
                      type="select"
                      placeholder="<Select Action>"
                      disabled={!listItemSelect || !listItemSelect.length}
                      isPlaceholder
                      isClearable
                    />
                    {!!modeApproveInvoice && type === 'APPROVE' && (
                      <div className="col-md-9 row">
                        <GenericInput
                          value={invoiceNotes}
                          name="invoiceNotes"
                          onChange={({ name, value }) => {
                            this.setState({ invoiceNotes: value });
                          }}
                          wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                          label="label.notes"
                          type="textarea"
                        />
                        <div className="col-md-3 mt-3 p-2">
                          <button
                            type="button"
                            className="button x-small btn-sm"
                            disabled={modeApproveInvoice === 1 && !listItemSelect}
                            onClick={() => this.doModifyInvoiceStatus(listItemSelect, 'APPROVED')}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.approveInvoice')}
                          </button>
                        </div>
                      </div>
                    )}

                    {!!modeRegenerateInvoice && type === 'REGENERATE_INVOICE' && (
                      <div className="col-md-9 row">
                        <GenericInput
                          value={poNumber}
                          name="emailId"
                          onChange={({ name, value }) => {
                            this.setState({ poNumber: value });
                          }}
                          wrapperClass="col-md-4"
                          label="label.PO"
                        />
                        <GenericInput
                          value={customMessage}
                          name="customMessage"
                          onChange={({ name, value }) => {
                            this.setState({ customMessage: value });
                          }}
                          wrapperClass="col-md-3 mt-auto mb-auto mr-0"
                          label="label.customMessage"
                          type="textarea"
                        />
                        <div>
                          <button
                            className="button x-small mt-4"
                            disabled={modeRegenerateInvoice === 1}
                            type="button"
                            onClick={this.doRegenerateInvoice}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.regenerateInvoice')}
                          </button>
                        </div>
                      </div>
                    )}

                    {!!modeApproveInvoice && type === 'DISAPPROVE' && (
                      <div className="col-md-9 row">
                        <GenericInput
                          value={invoiceNotes}
                          name="invoiceNotes"
                          onChange={({ name, value }) => {
                            this.setState({ invoiceNotes: value });
                          }}
                          wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                          label="label.notes"
                          type="textarea"
                        />
                        <div>
                          <button
                            className="button x-small mt-4"
                            type="button"
                            disabled={modeApproveInvoice === 1}
                            onClick={() => this.doModifyInvoiceStatus(listItemSelect, 'DISAPPROVED')}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.submit')}
                          </button>
                        </div>
                      </div>
                    )}

                    {type === 'UNDO_INVOICE_CANCEL' && (
                      <div className="col-md-9 row">
                        <div>
                          <button
                            className="button x-small mt-4"
                            type="button"
                            onClick={evt => this.doUndoInvoiceCancel()}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.submit')}
                          </button>
                        </div>
                      </div>
                    )}

                    {!!modeSendInvoice && type === 'EMAIL_INVOICE' && (
                      <div className="col-md-9 row">
                        <GenericInput
                          value={emailId}
                          name="emailId"
                          onChange={({ name, value }) => {
                            this.setState({ emailId: value });
                          }}
                          wrapperClass="col-md-4"
                          label="label.overrideMail"
                        />
                        <div>
                          <button
                            className="button x-small mt-4"
                            disabled={modeSendInvoice === 1 || !listItemSelect || !listItemSelect.length}
                            type="button"
                            onClick={this.sendInvoiceToMail}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.sendInvoice')}
                          </button>
                        </div>
                      </div>
                    )}
                    {!!modeUndoManualInvoice && type === 'UNDO_MANUAL_INVOICE' && (
                      <div className="col-md-9 row">
                        <div>
                          <button
                            className="button x-small mt-4"
                            type="button"
                            disabled={modeApproveInvoice === 1}
                            onClick={() => this.doUndoManualInvoice()}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.submit')}
                          </button>
                        </div>
                      </div>
                    )}
                    {!!modeResyncInvoice && type === 'RESYNC_INVOICE' && (
                      <div className="col-md-9 row">
                        <div>
                          <button
                            className="button x-small mt-4"
                            type="button"
                            onClick={() => this.doModifyInvoiceStatus(listItemSelect, 'SENT')}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.submit')}
                          </button>
                        </div>
                      </div>
                    )}
                    {/* {type === 'GENERATE_INVOICE' && modeGenerateInvoice !== 0 && (
                  <div className="col-md-9 row">
                    <div>
                      <button
                        className="button x-small mt-4"
                        type="button"
                        disabled={
                          !itemSelect ||
                          !itemSelect.invoiceTotal ||
                          Number.parseFloat(itemSelect.invoiceTotal) < 0 ||
                          modeGenerateInvoice === 1
                        }
                        onClick={() => this.doInvoiceAccount()}
                      >
                        {t('label.submit')}
                      </button>
                    </div>
                  </div>
                )} */}
                    {!!modeExtendInvoiceDueDate && type === 'EXTEND_DUE_DATE' && (
                      <form
                        ref={this.formRef}
                        noValidate
                        className={`col-md-12 needs-validation ${wasValidated ? 'was-validated' : ''}`}
                        // onSubmit={onHandleSubmit}
                        onSubmit={evt => this.doModifyInvoiceDueDate(evt, listItemSelect)}
                      >
                        <div className="col-md-9 row">
                          <GenericInput
                            value={dueDate}
                            name="dueDate"
                            onChange={({ name, value }) => {
                              this.setState({ dueDate: value });
                            }}
                            wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                            label="label.dueDate"
                            type="date"
                            required
                          />
                          <GenericInput
                            value={noteItem}
                            name="noteItem"
                            onChange={({ name, value }) => {
                              this.setState({ noteItem: value });
                            }}
                            wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                            label="label.notes"
                            type="textarea"
                            required
                          />
                          <div>
                            <button
                              className="button x-small mt-4"
                              type="submit"
                              // onClick={() => doModifyInvoiceDueDate(itemSelect)}
                            >
                              {isMultipleRows ? t('label.processAll') : t('label.submit')}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                    {type === 'RESEND_TO_VENDOR' && (
                      <div className="col-md-9 row">
                        <div>
                          <button
                            className="button x-small mt-4"
                            type="button"
                            onClick={() => this.doResendInvoiceVendor()}
                          >
                            {isMultipleRows ? t('label.processAll') : t('label.submit')}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <DataTable
                  columns={newColumnsWithView}
                  data={listInvoicing && listInvoicing.length ? listInvoicing : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>

        {isBilling && (
          <ModalWithItem
            modalTitle={t('label.invoiceDetails')}
            wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
            isOpen={isOpenModalDetails}
            onToggle={this.onToggleModalDetails}
          >
            <form
              ref={this.formRefInvoice}
              noValidate
              className={`col-md-12 pt-3 pb-3 needs-validation ${wasValidatedInvoice ? 'was-validated' : ''}`}
              // onSubmit={onHandleSubmit}
              // onSubmit={evt => doReprocessInvoice(evt)}
              onSubmit={evt => {
                if (dataSave.scheduleDate && moment(new Date()).isBefore(dataSave.scheduleDate)) {
                  return this.onSaveManualInvoice(evt);
                }
                if (oldAccounntId && oldAccounntId === accountIdSelected) {
                  this.doReprocessInvoice(evt);
                } else {
                  evt.preventDefault();
                  this.setState({ isOpenModalProcess: true });
                }
              }}
            >
              <div className="col-md-12 row pb-1">
                {listInvoiceFields.map(val => (
                  <GenericInput
                    value={
                      val.isFormatMoneyOfValue
                        ? formatMoneyValue(paymentInvoiceData[val.name])
                        : paymentInvoiceData[val.name]
                    }
                    wrapperClass="col-md-4"
                    onChange={({ name, value }) => this.onHandleChange({ value, name })}
                    {...val}
                  />
                ))}
                <Switch
                  onChange={() => {
                    this.setState({ isCancelAndSubstitute: !isCancelAndSubstitute });
                    this.onHandleSelectAll({ name: 'selectAll', value: false });
                  }}
                  name="isCancelAndSubstitute"
                  wrapperClass={`col-md-4 pl-2 pt-4 pr-3 ${
                    isCancelAndSubstitute ? 'switch-active' : 'switch-non-active'
                  }`}
                  title={t('common:label.createNewInvoice')}
                  // rightTitle={t('common:label.merge')}
                  checked={isCancelAndSubstitute}
                  disabled={!invoiceUnitIdSelected}
                  // rightTitle={t('common:label.desperse')}
                />
                <GenericInput
                  value={isSubstituteInvoice === 'TRUE' ? 'TRUE' : 'FALSE'}
                  name="substituteInvoice"
                  onChange={({ name, value }) => this.setState({ isSubstituteInvoice: value })}
                  wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                  label="label.invoiceSubstitution"
                  type="select"
                  tOptions="selections:selectBool"
                  disabled={!isCancelAndSubstitute}
                />
                <GenericInput
                  value={accountIdSelected}
                  name="accountId"
                  onChange={() => {}}
                  wrapperClass="col-md-4 mr-0"
                  label="label.accountId"
                  fa="fa fa-external-link"
                  onClick={() => {
                    this.setState({ isOpenModalAccountId: true });
                  }}
                  disabled={!isCancelAndSubstitute}
                />
                <GenericInput
                  value={dataSave.scheduleDaily}
                  wrapperClass="col-md-4"
                  type="select"
                  onChange={this.onHandleChangeDataSave}
                  name="scheduleDaily"
                  tOptions="selections:selectBool"
                  label="label.scheduleWithDailyJobs"
                  disabled={!isCancelAndSubstitute}
                />
                <GenericInput
                  value={dataSave.scheduleDate}
                  wrapperClass="col-md-4"
                  type="date"
                  onChange={this.onHandleChangeDataSave}
                  name="scheduleDate"
                  label="label.scheduleDate"
                  disabled={!isCancelAndSubstitute}
                />
                <CheckField
                  name="selectAll"
                  id="selectAll"
                  wrapperClass="col-md-4 mt-4 mb-2"
                  checked={isSelectAll}
                  disabled={!isCancelAndSubstitute}
                  // checked={selectName === 'selectAll'}
                  label={t('label.selectAll')}
                  onChange={evt => this.onHandleSelectAll({ name: 'selectAll', value: evt.target.checked })}
                />
              </div>
              <br />
              {collapsibleMenuTransactionId.map(item => (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={item.state === activeTab.name && activeTab.isActive}
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={this.onToggleTab}
                >
                  {this.renderItem(item)}
                </FormCollapse>
              ))}
              <br />
              <button
                type="button"
                onClick={this.onToggleModalDetails}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
              <button
                className="button x-small mr-2 float-right"
                type="submit"
                disabled={!isCancelAndSubstitute || !itemSelectInvoiceLine.length}
              >
                {t('label.process')}
              </button>
              <button
                className="button x-small mr-2 float-right"
                type="button"
                disabled={!isCancelAndSubstitute || !itemSelectInvoiceLine.length}
                onClick={this.onSaveManualInvoice}
              >
                {t('label.save')}
              </button>
            </form>
          </ModalWithItem>
        )}
        {isBilling && (
          <ModalAccountId
            isOpen={isOpenModalAccountId}
            onSelect={this.onSelectAccountId}
            selectedParentId={accountIdSelected}
            onCancel={() => {
              this.setState({ isOpenModalAccountId: false });
            }}
          />
        )}
        {isBilling && (
          <ModalSelectBillableService
            isOpen={isOpenModalServiceName}
            onSelect={this.onSelectServiceName}
            onCancel={this.onToggleModalServiceName}
            selectedParentId={
              serviceNameSelect && serviceNameSelect.omsInternalId ? serviceNameSelect.omsInternalId : ''
            }
            accountId={accountIdSelected || null}
            getOrderSummaryByAccountId={getOrderSummaryByAccountIdConfig}
          />
        )}
        {isBilling && (
          <ModalSearchItem
            isOpen={isOpenModalItem}
            onSelect={this.onSelectItemId}
            onCancel={this.onToggleModalItemId}
            selectedParentId={itemIdSelect && itemIdSelect.itemId ? itemIdSelect.itemId : ''}
            // defaultSearchParams={{ type: 'REVENUE' }}
            defaultSearchForm={{ type: 'RECEIVABLE' }}
            defaultFilter={{ type: 'RECEIVABLE' }}
            isWithType
          />
        )}
        {isBilling && (
          <ModalAccept
            onAcceptModal={evt => this.doReprocessInvoice(evt)}
            isOpen={isOpenModalProcess}
            message={t('common:message.manualInvoiceMessage')}
            onToggle={() => {
              this.setState({ isOpenModalProcess: false });
            }}
          />
        )}

        {isBilling && (
          <ModalWithItem
            modalTitle={t('label.stampingRecord')}
            wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
            isOpen={isOpenModalStamping}
            onToggle={this.onToggleModalStamping}
          >
            <DataTable columns={stampingRecordColumns} data={stampingsData || []} />
            <button type="button" onClick={this.onToggleModalStamping} className="button x-small float-right">
              {t('label.back')}
            </button>
          </ModalWithItem>
        )}

        {isBilling && (
          <ModalWithItem
            modalTitle={t('label.data')}
            wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
            isOpen={isOpenModalStampingCSVData}
            onToggle={this.onToggleModalStampingCSVData}
          >
            <div className="mt-3 mb-5 csv-content">
              {/* {csvData} */}
              {messageCSVStamping(csvData)}
            </div>
            <button type="button" onClick={this.onToggleModalStampingCSVData} className="button x-small float-right">
              {t('label.back')}
            </button>
          </ModalWithItem>
        )}

        {isBilling && (
          <ModalWithItem
            modalTitle={t('label.invoicePaymentInstallments')}
            isOpen={isOpenModalInstallments}
            onToggle={this.onToggleModalInstallments}
          >
            <div className="col-md-12 pt-3 pb-3">
              <div className="col-md-12 row pb-1">
                {dataCreate &&
                  (!paymentInstallmentsData || paymentInstallmentsData.length < 1) &&
                  listFields.map(val => (
                    <GenericInput
                      value={dataCreate[val.name]}
                      wrapperClass="col-md-4"
                      disabled={
                        (val.name === 'monthOffset' && !!dataCreate.daysOffset) ||
                        (val.name === 'daysOffset' && !!dataCreate.monthOffset)
                      }
                      onChange={({ name, value }) => this.onHandleChange({ value, name })}
                      {...val}
                    />
                  ))}
              </div>
              {(!paymentInstallmentsData || paymentInstallmentsData.length < 1) && (
                <div className="form-group col-md-12 buttons-attibute pb-3 mb-4">
                  <button
                    type="submit"
                    className="button button-border x-small float-right"
                    onClick={this.onCreatePaymentInstallment}
                  >
                    {t('label.submit')}
                  </button>
                  <button
                    type="button"
                    className="button button-border black x-small mr-2 float-right"
                    disabled={dataCreate}
                    onClick={() => {
                      this.setState({
                        dataCreate: {
                          invoiceId: invoiceUnitIdSelected,
                          accountId: itemSelectInstallments?.accountId || null,
                          numOfInstallment: '',
                          daysOffset: '',
                          monthOffset: '',
                          status: 'ACTIVE',
                        },
                      });
                    }}
                  >
                    +
                    {t('label.addInstallments')}
                  </button>
                </div>
              )}
              <br />
              <DataTable columns={installmentsColumns} data={paymentInstallmentsData} />
              <button
                type="button"
                onClick={this.onToggleModalInstallments}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </ModalWithItem>
        )}
        <ModalViewNote
          isOpen={isOpenModalNotes}
          message={messageNotesBills(noteContent)}
          onToggle={() => this.setState({ isOpenModalNotes: false })}
        />
        {!isBilling && (
          <ModalWithItem
            onToggle={this.onToggleModalExportConfig}
            isOpen={isOpenModalExportConfig}
            wrapperClass="modal-config"
          >
            <div className="col-md-12 d-flex">
              <div className="column-config" onClick={this.onToggleModalExportConfig} />
              <div className="body-config">
                <DragDropConfigExportTemplate
                  onHandleSave={this.onHandleSaveConfig}
                  exportColumns={exportColumns}
                  onToggle={this.onToggleModalExportConfig}
                />
              </div>
            </div>
          </ModalWithItem>
        )}
        <ModalViewNote
          isOpen={isOpenModalNotesAction}
          message={messageNotesAction(t)}
          onToggle={this.onToggleModalNotesAction}
        />
      </div>
    );
  }
}

Invoicing.propTypes = {
  getInvoicing: PropTypes.func,
  getAllInvoicing: PropTypes.func,
  listAllInvoicing: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

Invoicing.defaultProps = {
  getInvoicing: () => {},
  getAllInvoicing: () => {},
  listAllInvoicing: [],
};

const mapStateToProps = createStructuredSelector({
  listInvoicing: makeGetListInvoicing() || [],
  listAllInvoicing: makeGetListAllInvoicing() || [],
  permissionsInvoice: makeGetPermissionsInvoice() || {},
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getInvoicing,
    getAllInvoicing,
    getObjectFileById,
    getCurrencyConfigApp,
    getCcpPropertiesConfig,
    uploadMultiPartFiles,
    getInvoiceUnitByIdSelect,
    getCostCenterConfig,
    getOrderSummaryByAccountIdConfig,
    saveManualInvoiceConfig,
    reprocessInvoiceConfig,
    modifyInvoiceStatusConfig,
    regenerateInvoiceConfig,
    undoInvoiceCancelConfig,
    sendInvoiceConfig,
    undoManualInvoiceConfig,
    modifyInvoiceDueDateConfig,
    resendInvoiceVendorConfig,
    getPacInterfaceRecordByIdConfig,
    getInvoiceInstallmentsByInvoiceIdConfig,
    createPaymentInstallmentConfig,
    saveOutputTemplateByType,
    getOutputTemplateByType,
    bulkProcess,
    getSplitTaxPerLineConfig,
  })(Invoicing)
);
