import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';
import { countryList } from '../../constantsApp/countryList';
import listCurrency from '../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class BundleSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'common:label.salesChannel',
      name: 'salesChannel',
      type: 'select',
      tOptions: 'selections:salesChannel',
      isClearable: true,
    },
    {
      label: 'common:label.marketSegment',
      name: 'marketSegment',
      type: 'select',
      tOptions: 'selections:marketSegment',
      isClearable: true,
    },
    {
      label: 'common:label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'common:label.accountSubType',
      name: 'accountSubType',
      type: 'select',
      tOptions: 'selections:accountSubType',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:statusPricing',
      isClearable: true,
    },
    {
      label: 'common:label.country',
      name: 'country',
      type: 'select',
      options: countryList,
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
    },
    {
      label: 'common:label.currency',
      name: 'currency',
      type: 'select',
      options: currencyOptions,
    },
  ];

  btnSpan = 'col-md-6';
}

BundleSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BundleSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(BundleSearchForm);
