const groupNamePermissions = {
  createJobSchedule: 'CREATE_JOB_SCHEDULE',
  modifyJobSchedule: 'MODIFY_JOB_SCHEDULE',
  getJobSchedule: 'GET_JOB_SCHEDULE',
  updateJobScheduleStatus: 'UPDATE_JOB_SCHEDULE_STATUS',
  processJobSchedule: 'PROCESS_JOB_SCHEDULE',
  reprocessJobSchedule: 'REPROCESS_JOB_SCHEDULE',
  createJobConfig: 'CREATE_JOB_CONFIG',
  modifyJobConfig: 'MODIFY_JOB_CONFIG',
  findJobConfig: 'FIND_JOB_CONFIG',
  createJob: 'CREATE_JOB',
  modifyJob: 'MODIFY_JOB',
  updateJobStatus: 'UPDATE_JOB_STATUS',
  processJob: 'PROCESS_JOB',
  reprocessJob: 'REPROCESS_JOB',
  getJobStats: 'GET_JOB_STATS',
  modifyJobType: 'MODIFY_JOB_TYPE',
  refreshJobSchedule: 'REFRESH_JOB_SCHEDULE',
};

export { groupNamePermissions };
