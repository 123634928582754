import * as types from './types';

export function getConfigCorrespondence(payload, cb) {
  return {
    type: types.GET_CONFIG_CONRRESPONDENCE,
    payload,
    cb,
  };
}

export function modifyConfigCorrespondence(payload, cb) {
  return {
    type: types.MODIFY_CONFIG_CONRRESPONDENCE,
    payload,
    cb,
  };
}

export function createConfigCorrespondence(payload, cb) {
  return {
    type: types.CREATE_CONFIG_CONRRESPONDENCE,
    payload,
    cb,
  };
}