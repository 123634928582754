import React from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, CardHeaderForm, DataTable } from '../common';

const SubscriptionsForm = ({
  data,
  onChange,
  onAddSubscription,
  onRemoveItem,
  isMultiSubscriptionEnabled,
  isSingleSubscription,
  onHandleChange,
  isMandatorySubscriptionCategory,
  isCreditProfile,
  onOpenCreditProfileModal,
}) => {
  const { t } = useTranslation('common');
  const creditProfileColumn = [];

  if (isCreditProfile) {
    creditProfileColumn.push({
      name: 'creditProfileName',
      label: 'label.creditProfile',
      style: { width: '280px', maxWidth: '280px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.creditProfileName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChange({ name, value, index })}
          name="creditProfileName"
          fa="fa fa-external-link"
          readOnly
          onClick={() => onOpenCreditProfileModal({ index, item })}
        />
      ),
    });
  }

  const subscriptionColumns = [
    {
      name: 'subscriptionName',
      label: 'label.name',
      required: true,
      style: { width: '280px', maxWidth: '280px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.subscriptionName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChange({ name, value, index })}
          name="subscriptionName"
          required
        />
      ),
    },
    {
      name: 'subscriptionCategory',
      label: 'label.category',
      style: { width: '280px', maxWidth: '280px' },
      required: isMandatorySubscriptionCategory,
      render: (colName, item, index) => (
        <GenericInput
          value={item.subscriptionCategory}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChange({ name, value, index })}
          name="subscriptionCategory"
          required={isMandatorySubscriptionCategory}
          readOnly={!isMandatorySubscriptionCategory}
          disabled={!isMandatorySubscriptionCategory}
          type="select"
          tOptions="selections:subscriptionCategory"
          menuPortalTarget
        />
      ),
    },
    ...creditProfileColumn,
    {
      name: 'remove',
      label: 'label.remove',
      style: { width: '80px', maxWidth: '80px' },
      render: (colName, item, index) => (
        <button
          type="button"
          className="btn-delete"
          disabled={!data || !data.length || data.length < 2}
          onClick={() => onRemoveItem({ index })}
        >
          <i className="fa fa-trash" />
        </button>
      ),
    },
  ];
  return (
    <div className="col-md-12 mb-30">
      <div className="card card-statistics col-md-12">
        <CardHeaderForm title={t('label.subscription')} hideNavTab />
        <GenericInput
          label="common:label.singleSubscription"
          value={isSingleSubscription}
          onChange={() => {
            if (!isMultiSubscriptionEnabled) return toast.info(t('message.messageMultiSubsription'));
            return onHandleChange();
          }}
          wrapperClass="col-md-4 ml-4"
          type="switch"
          // disabled={!isMultiSubscriptionEnabled}
        />
        <div className={`card-body ${isCreditProfile ? 'col-md-8' : 'col-md-6'} m-3`}>
          <DataTable columns={subscriptionColumns} data={data || []} />
          <button
            type="button"
            disabled={isSingleSubscription}
            className="button button-border black x-small ml-3"
            onClick={onAddSubscription}
          >
            +
            {t('label.addSubscription')}
          </button>
        </div>
      </div>
    </div>
  );
};

SubscriptionsForm.propTypes = {
  data: PropsType.arrayOf(PropsType.any),
  onChange: PropsType.func.isRequired,
  onAddSubscription: PropsType.func.isRequired,
  onRemoveItem: PropsType.func.isRequired,
};

SubscriptionsForm.defaultProps = {
  data: null,
};

export default SubscriptionsForm;
