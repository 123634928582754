import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['useStartTime', 'startOfWeek', 'endOfWeek', 'endOfWeek']);
  const createTimeUnitCfg = `createTimeUnitCfg(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createTimeUnitCfg} {
      id
    }
  }
`;
};
