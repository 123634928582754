import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { getTenantConfig } from '../actions';
import RouteName from '../../App/RouteNames';
import { makeTenantData } from '../selectors';
import Footer from '../../../components/Footer';
import Sidebar from '../Sidebar';
import { Breadcrumb } from '../../../components/common';
import TenantDetail from '../TenantDetail';
// import TenantCreate from '../TenantCreate';

class TenantPage extends React.PureComponent {
  state = { tenantData: {} };

  static getDerivedStateFromProps(props, state) {
    if (state.tenantData === props.tenantData) return null;
    return {
      tenantData: props.tenantData,
    };
  }

  componentDidMount() {
    // const { getTenantConfig } = this.props;
    // getTenantConfig();
  }

  render() {
    const { tenantData } = this.state;
    const { t, getTenantConfig } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper">
            <Sidebar />
            <div className="page-title">
              <div className="row">
                <div className="col-sm-6">
                  <NavLink className="button x-small" to={RouteName.tenantData.path}>
                    {t('label.back')}
                  </NavLink>
                </div>
                <div className="col-sm-6">
                  <Breadcrumb
                    items={[
                      {
                        name: t('navbar:operationsHub.subMain.tenantManagement'),
                        url: RouteName.tenantManagement.path,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <br />
            {/* {tenantData && tenantData.id ? <TenantDetail tenantData={tenantData} /> : <TenantCreate />} */}
            <TenantDetail tenantData={tenantData} getTenantConfig={getTenantConfig} />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

TenantPage.propTypes = {
  getTenantConfig: PropTypes.func.isRequired,
  tenantData: PropTypes.objectOf(PropTypes.any),
};

TenantPage.defaultProps = {
  tenantData: {},
};

const mapStateToProps = createStructuredSelector({
  tenantData: makeTenantData() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getTenantConfig })(TenantPage));
