import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import CustomerIndexPage from './CustomerIndex';
import CustomerNew from './CustomerNew';
import CustomerDetails from './CustomerDetails';
import CustomerAttribute from './CustomerAttribute';
import CreditProfile from './CreditProfile';
import DelayedBilling from './DelayedBilling';
import Merge from './Merge';
import Split from './Split';
import RouteNames from '../App/RouteNames';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import orderReducer from '../OrderPage/reducer';
import taskReducer from '../TaskManagement/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import orderSaga from '../OrderPage/saga';
import taskSaga from '../TaskManagement/saga';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class CustomerPage extends PureComponent {
  render() {
    const { permissionsCustomer } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsCustomer, listKeys: permissionList.customerManagement }))
      return null;
    return (
      <Switch>
        <Route exact path={RouteNames.customers.path} component={CustomerIndexPage} />
        <Route exact path={RouteNames.customersCreate.path} component={CustomerNew} />
        <Route exact path={RouteNames.customerDetails.path} component={CustomerDetails} />
        <Route exact path={RouteNames.customerAttribute.path} component={CustomerAttribute} />
        <Route exact path={RouteNames.customerAttributeDetails.path} component={CustomerAttribute} />
        <Route exact path={RouteNames.creditProfile.path} component={CreditProfile} />
        <Route exact path={RouteNames.creditProfileDetails.path} component={CreditProfile} />
        <Route exact path={RouteNames.delayedBilling.path} component={DelayedBilling} />
        <Route exact path={RouteNames.merge.path} component={Merge} />
        <Route exact path={RouteNames.split.path} component={Split} />
        {/* <Route exact path={RouteNames.specialPricing.path} component={SpecialPricing} /> */}
      </Switch>
    );
  }
}

const withReducerTask = injectReducer({ key: 'taskReducer', reducer: taskReducer });
const withSagaTask = injectSaga({ key: 'taskSaga', saga: taskSaga });

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

const withReducerOrder = injectReducer({ key: 'orderReducer', reducer: orderReducer });
const withSagaOrder = injectSaga({ key: 'orderSaga', saga: orderSaga });

const withReducer = injectReducer({ key: 'customerReducer', reducer });
const withSaga = injectSaga({ key: 'customerSaga', saga });

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsCustomer = store.get('auth').toJS().permissionsApp.customerHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsCustomer: permissionsCustomer.length
      ? permissionsCustomer.filter(a => a.moduleName === 'CUSTOMER_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducerTask,
  withSagaTask,
  withReducerReport,
  withSagaReport,
  withReducer,
  withSaga,
  withReducerOrder,
  withSagaOrder,
  withConnect
)(CustomerPage);
