/**
 * BundleManagerpage selectors
 */

import { createSelector } from 'reselect';

const selectBundleManager = state => {
  return state.get('bundleReducer');
};

// -----BUNDLES
const makePageBundleParams = () =>
  createSelector(
    selectBundleManager,
    items => items.get('paramsBundles').toJS()
  );

const makeGetListBundles = () => {
  return createSelector(
    selectBundleManager,
    items => [...items.get('listBundles')]
  );
};

const makeErrorMessage = () =>
  createSelector(
    selectBundleManager,
    item => item.get('errorMessage')
  );

const makeGetBundleDetail = () =>
  createSelector(
    selectBundleManager,
    item => item.get('bundleInfo')
  );

// -----PACKAGES
const makePagePackageParams = () =>
  createSelector(
    selectBundleManager,
    items => items.get('paramsPackages').toJS()
  );

const makeGetListPackages = () => {
  return createSelector(
    selectBundleManager,
    items => [...items.get('listPackages')]
  );
};

const makeGetPackageDetail = () =>
  createSelector(
    selectBundleManager,
    item => item.get('packageInfo')
  );

// -----DEPENDENCIES
const makePageDependencyParams = () =>
  createSelector(
    selectBundleManager,
    items => items.get('paramsDependencies').toJS()
  );

const makeGetListDependencies = () => {
  return createSelector(
    selectBundleManager,
    items => [...items.get('listDependencies')]
  );
};

const makeGetDependencyDetail = () =>
  createSelector(
    selectBundleManager,
    item => item.get('dependencyInfo')
  );

export {
  makeGetListBundles,
  makePageBundleParams,
  makeGetBundleDetail,
  makeGetListPackages,
  makePagePackageParams,
  makeGetPackageDetail,
  makeGetListDependencies,
  makePageDependencyParams,
  makeGetDependencyDetail,
  makeErrorMessage,
};
