import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { DataTable } from '../../../components/common';

class RolePermission extends Component {
  state = {
    permissions: [],
    sorted: {},
  };

  static getDerivedStateFromProps(props, state) {
    if (isEqual(state.oldPermission, props.permissions)) return null;
    return {
      permissions: props.permissions,
      oldPermission: [...props.permissions],
    };
  }

  onChangePermissions = () => {
    const { permissions } = this.state;
    const { onChangePermissions } = this.props;
    if (onChangePermissions) {
      onChangePermissions(permissions);
    }
  };

  onChangeInput = (name, item) => {
    const { permissions } = this.state;
    const idx = permissions.findIndex(e => e.index === item.index);
    permissions[idx] = { ...item, type: name };
    this.setState({ permissions, sorted: {} }, () => this.onChangePermissions());
  };

  onCheckAll = evt => {
    const { permissions } = this.state;
    const newPermissions = permissions.map(item => {
      return {
        ...item,
        type: evt.target.name,
      };
    });
    this.setState({ sorted: { sortCol: evt.target.name }, permissions: newPermissions }, () =>
      this.onChangePermissions()
    );
  };

  render() {
    const { permissions } = this.state;
    const namePermissionFirstRow = permissions && permissions[0] && permissions[0].type ? permissions[0].type : '';
    let namePermissionCheckALL = '';
    if (namePermissionFirstRow) {
      namePermissionCheckALL =
        permissions.filter(item => item.type === namePermissionFirstRow).length === permissions.length
          ? namePermissionFirstRow
          : '';
    }

    const tableColumns = [
      {
        name: 'permission',
        label: 'common:label.permission',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t(`permission:${item.permission}`);
          return <span>{slt || item.permission}</span>;
        },
      },
      {
        name: 'READ_ONLY',
        label: 'common:label.read',
        isCheckable: true,
        render: (colName, item) => {
          return (
            <input
              type="radio"
              name={`${item.moduleName}${item.permission}`}
              value="READ_ONLY"
              // {...aProps}
              checked={item.type === 'READ_ONLY'}
              onClick={e => this.onChangeInput('READ_ONLY', item)}
              onChange={() => {}}
            />
          );
        },
      },
      {
        name: 'READ_WRITE',
        label: 'common:label.readWrite',
        isCheckable: true,
        render: (colName, item) => {
          return (
            <input
              type="radio"
              checked={item.type !== 'READ_ONLY' && item.type !== 'NONE'}
              name={`${item.moduleName}${item.permission}`}
              value="READ_WRITE"
              onClick={e => this.onChangeInput('READ_WRITE', item)}
              onChange={() => {}}
            />
          );
        },
      },
      {
        name: 'NONE',
        label: 'common:label.none',
        isCheckable: true,
        render: (colName, item) => {
          // const aProps = item.type === 'NONE' ? { checked: true } : { checked: false };
          return (
            <input
              type="radio"
              // {...aProps}
              checked={item.type === 'NONE' || !item.type}
              name={`${item.moduleName}${item.permission}`}
              value="NONE"
              onClick={e => this.onChangeInput('NONE', item)}
              onChange={() => {}}
            />
          );
        },
      },
      {
        name: 'roleLimit',
        label: 'common:label.limit',
      },
    ];

    const tableConfig = {
      columns: tableColumns,
      data: permissions,
      onCheckAll: this.onCheckAll,
      sorted: { sortCol: namePermissionCheckALL },
    };
    return (
      <div>
        <DataTable {...tableConfig} />
      </div>
    );
  }
}

RolePermission.propTypes = {
  onChangePermissions: PropTypes.func,
  permissions: PropTypes.any,
};

RolePermission.defaultProps = {};

export default RolePermission;
