import gql from 'graphql-tag';

export default () => {
  const getEnterprise = 'getEnterprise(dummy: "")';
  return gql`
    query {
      ${getEnterprise} {
        id
        name
        description
        shortName
        primaryIndustry
        hqCountry
        locationIndex
        type
        address {
          city
          state
          country
        }
      }
    }
  `;
};
