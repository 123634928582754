import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TitleFrom, DataTable, GenericInput, AccountHeaderFrom } from '../../../../components/common';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { getRevenueMilestoneById, modifyRevenueMilestone } from '../../actions';
import { getIndexData } from '../../../../utils/utils';
import { listMilestoneFields } from '../../constants';
import { makeGetRevenueMilestoneItem } from '../../selectors';
import { MilestoneFormItem } from '../../../../components/RevenueHub';
import {
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
} from '../../../App/actions';

class Milestone extends React.PureComponent {
  state = {
    data: {},
    dataSubmit: {},
    isLoading: false,
    isModify: true,
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.revenueMilestoneItem) return null;
    return {
      data: props.revenueMilestoneItem ? props.revenueMilestoneItem : null,
      oldValue: props.revenueMilestoneItem,
    };
  }

  componentDidMount() {
    this.doGetListProductConfig();
    this.doGetRevenueMilestoneById();
  }

  doGetRevenueMilestoneById = () => {
    const { getRevenueMilestoneById } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    getRevenueMilestoneById(id);
  };

  doGetListProductConfig = () => {
    const { getUniqueProductFamily, getUniqueProductLine, getUniqueProductSubType, getUniqueProductType } = this.props;
    this.setState({ productFamily: '', productType: '', productSubType: '', productLine: '' });
    getUniqueProductFamily('', ({ data, success }) => {
      if (success)
        this.setState({
          productFamilyConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productFamily, value: val.productFamily })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductLine('', ({ data, success }) => {
      if (success)
        this.setState({
          productLineConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productLine, value: val.productLine })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductSubType('', ({ data, success }) => {
      if (success)
        this.setState({
          productSubTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productSubType, value: val.productSubType })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductType('', ({ data, success }) => {
      if (success)
        this.setState({
          productTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productType, value: val.productType })),
                  ['label']
                )
              : [],
        });
    });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChange = ({ name, value }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    newData[name] = value;
    dataSubmit[name] = value;
    this.setState({ data: newData, dataSubmit });
  };

  onChangeTable = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (value === 'TRUE') newValue = 'true';
      if (value === 'FALSE') newValue = 'false';
      newData.milestones[getIndexData({ index, data: newData.milestones })][name] = newValue;
      const indexDataSubmit = getIndexData({ index, data: dataSubmit.milestones || [] });
      if (!dataSubmit.milestones || !dataSubmit.milestones.length) dataSubmit.milestones = [];
      if (indexDataSubmit === -1) {
        dataSubmit.milestones.push({ [name]: newValue, index });
      } else {
        dataSubmit.milestones[indexDataSubmit][name] = newValue;
      }

      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.milestones) newData.milestones = [];
      newData.milestones.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      const defaultDataAdd = {
        index: lastIndex + 1,
        percent: 0,
        manual: 'false',
        frequency: 0,
      };
      newData.milestones.push(defaultDataAdd);
      if (!dataSubmit.milestones) dataSubmit.milestones = [];
      dataSubmit.milestones.push(defaultDataAdd);
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    newData.milestones[getIndexData({ index, data: newData.milestones })] = {
      index: newData.milestones[getIndexData({ index, data: newData.milestones })].index,
    };
    const indexDataSubmit = getIndexData({ index, data: dataSubmit.milestones || [] });
    if (indexDataSubmit === -1) {
      if (!dataSubmit.milestones) {
        dataSubmit.milestones = [
          { index: newData.milestones[getIndexData({ index, data: newData.milestones })].index },
        ];
      } else {
        dataSubmit.milestones.push({
          index: newData.milestones[getIndexData({ index, data: newData.milestones })].index,
        });
      }
    }

    this.setState({ data: newData });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    const { modifyRevenueMilestone } = this.props;
    const { data, dataSubmit } = this.state;
    if (!this.validate(true)) {
      return false;
    }
    modifyRevenueMilestone({ ...dataSubmit, id: data.id }, () => this.setState({ wasValidated: false }));
  };

  render() {
    const {
      t,
      match: {
        params: { id },
      },
      permissions,
      location,
    } = this.props;
    const { modeModifyCurrencyExchangeConfig } = permissions;
    if (!modeModifyCurrencyExchangeConfig) return '';
    const {
      data,
      isLoading,
      wasValidated,
      productFamilyConfig,
      productLineConfig,
      productSubTypeConfig,
      productTypeConfig,
    } = this.state;

    const newListFields = cloneDeep(listMilestoneFields);
    if (productFamilyConfig && productFamilyConfig.length) {
      newListFields[2].options = productFamilyConfig;
    }

    if (productLineConfig && productLineConfig.length) {
      newListFields[3].options = productLineConfig;
    }

    if (productSubTypeConfig && productSubTypeConfig.length) {
      newListFields[4].options = productSubTypeConfig;
    }

    if (productTypeConfig && productTypeConfig.length) {
      newListFields[5].options = productTypeConfig;
    }

    const tableColumns = [
      {
        name: 'percentage',
        label: 'label.percentage',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.percent}
              name="percent"
              type="number"
              onChange={({ name, value }) => this.onChangeTable({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'manual',
        label: 'label.manualFlag',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.manual === 'true' || item.manual === true ? 'TRUE' : 'FALSE'}
              name="manual"
              type="select"
              tOptions="selections:selectBool"
              onChange={({ name, value }) => this.onChangeTable({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'frequency',
        label: 'label.frequency',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.frequency}
              name="frequency"
              type="number"
              onChange={({ name, value }) => this.onChangeTable({ index: item.index, name, value })}
              wrapperClass="col-md-12"
            />
          );
        },
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={{
            pathname: RouteNames.revenueConfigurationMilestone.path,
            state: location && location.state ? location.state : null,
          }}
          titleBtn={t('label.back')}
          titleRight={t('label.milestoneDetails')}
        />
        <br />
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation col-sm-12 mb-30 card card-statistics ${wasValidated ? 'was-validated' : ''}`}
          noValidate
          ref={this.formRef}
        >
          <TitleFrom title={t('label.milestoneDetails')} />
          <AccountHeaderFrom title={t('label.id')} accountNum={id} />
          <MilestoneFormItem listFields={newListFields} data={data} onChange={this.onChange} />
          <DataTable
            columns={tableColumns}
            data={data && data.milestones ? data.milestones.filter(val => size(val) > 1) : []}
            isLoading={isLoading}
          />
          <div className="form-group col-md-12 buttons-attibute">
            {modeModifyCurrencyExchangeConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                +
                {t('label.addNewMilestone')}
              </button>
            )}
            {modeModifyCurrencyExchangeConfig === 2 && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.modify')}
              </button>
            )}
            <button
              onClick={this.doGetRevenueMilestoneById}
              type="button"
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

Milestone.propTypes = {
  getRevenueMilestoneById: PropTypes.func,
  modifyRevenueMilestone: PropTypes.func,
  match: PropTypes.objectOf(PropTypes.any),
};

Milestone.defaultProps = {
  getRevenueMilestoneById: () => {},
  modifyRevenueMilestone: () => {},
  match: {},
};

const mapStateToProps = createStructuredSelector({
  revenueMilestoneItem: makeGetRevenueMilestoneItem() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getRevenueMilestoneById,
    modifyRevenueMilestone,
    getUniqueProductFamily,
    getUniqueProductLine,
    getUniqueProductSubType,
    getUniqueProductType,
  })(withRouter(Milestone))
);
