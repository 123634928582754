import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['paymentStatus', 'paymentType'])}` : '';
  const searchBatchPayment = `searchBatchPayment(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchBatchPayment} {
        id
        name
        fileName
        filePath
        fileDateRcvd
        createdDate
        processedDate
        totalTime
        tableName
        paymentType
        paymentStatus
        totalRecords
        errorRecords
        importedRecords
        successRecords
        failedRecords
        setAsideRecords
        pendingRecords
      }
    }
  `;
};

// suspendedRecords
