import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'roundingPrecision', 'grantType', 'roundingMethod']);
  const createGrantsConfig = `createGrantsConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createGrantsConfig} {
      id
    }
  }
`;
};
