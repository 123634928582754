import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { convertBodyPdfFile } from '../exportHelper';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.exportLabel) || t(val.label));

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, fontSize, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const newListData = data.map(item => {
    return convertBodyPdfFile({ item, columnsTable, t });
  });

  const newHeader = [];
  const newBody = [];
  Header.forEach((hd, index) => {
    if (index === 0) {
      newHeader.push(hd);
    } else {
      if (!newBody[index - 1]) newBody[index - 1] = [];
      newBody[index - 1].push({ content: hd });
    }
  });
  newListData.forEach(dt => {
    if (dt && dt.length) {
      dt.forEach((val, idx) => {
        if (idx === 0) {
          newHeader.push(val.content);
        } else {
          if (!newBody[idx - 1]) newBody[idx - 1] = [];
          newBody[idx - 1].push(val);
        }
      });
    }
  });
  return pdfConfig({
    data: newBody,
    Header: newHeader,
    title,
    fileName,
    fontSize: fontSize || 5,
    cellPadding: 2,
    ...rest,
  });
};

export default convertJson2Pdf;
