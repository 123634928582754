import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ModalHeader, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { DataTable, CheckGroup } from '../common';

class ModalSelectUserIFromAgent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userSelected: props.itemSelected || '',
      rowSelect: null,
    };
  }

  onChangeCheckBox = item => {
    this.setState({ userSelected: item.userId || '', rowSelect: item });
  };

  onCancelSelect = () => {
    const { onCancel } = this.props;
    this.setState({ userSelected: '' });
    onCancel();
  };

  render() {
    const { isOpen, onSelect, onCancel, t, listData } = this.props;
    const { isLoading, userSelected, rowSelect } = this.state;
    const tableColumns = [
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item) => {
          return (
            <CheckGroup
              onChange={() => this.onChangeCheckBox(item)}
              value={item.userId}
              name="userSelected"
              checked={item.userId && item.userId === userSelected}
            />
          );
        },
      },
      {
        name: 'id',
        label: 'label.id',
      },
      {
        name: 'userId',
        label: 'label.userId',
        sortable: true,
      },
      {
        name: 'type',
        label: 'label.type',
        sortable: true,
      },
      {
        name: 'category',
        label: 'label.category',
        sortable: true,
        style: { minWidth: '100px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:category')().find(val => val.value === item.category) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'status',
        label: 'label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ];

    return (
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        size="lg"
        className="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        <ModalHeader>{t('label.chooseUser')}</ModalHeader>
        <ModalBody>
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <div className="card-body">
                <DataTable
                  columns={tableColumns}
                  onClickRow={this.onClickRow}
                  nullIsLoading={false}
                  isLoading={isLoading}
                  onSort={this.onSortColumn}
                  fixedHeader
                  tableClass="card-table-body"
                  data={listData}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="text-right">
          <button type="button" className="btn btn-secondary" onClick={this.onCancelSelect}>
            {t('label.cancel')}
          </button>
          <button type="button" className="btn btn-success" onClick={evt => onSelect(evt, userSelected, rowSelect)}>
            {t('label.select')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalSelectUserIFromAgent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  t: PropTypes.func,
  itemSelected: PropTypes.string,
  listData: PropTypes.arrayOf(PropTypes.any),
};

ModalSelectUserIFromAgent.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  t: () => {},
  itemSelected: null,
  listData: [],
};

export default withTranslation(['common', 'selections'])(ModalSelectUserIFromAgent);
