import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../components/PricingHub/SearchComponent';

class SchedulePatternConfigSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.patternName',
      name: 'patternName',
    },
    {
      label: 'common:label.patternType',
      name: 'patternType',
      tOptions: 'selections:schedulePatternType',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-6';
}

SchedulePatternConfigSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SchedulePatternConfigSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SchedulePatternConfigSearchForm);
