import gql from 'graphql-tag';

export default id => gql`
  query {
    getCloudFileDataByFileName(fileName: "${id}") {
      fileName
      fileType
      filePath
      source
      user
      fileUploadDate
      tableName
      fileCategory
      totalCount
      successCount
      failureCount
    }
  }
`;
