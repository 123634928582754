import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class AccountingReconciliationSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.objectStartDate',
      name: 'objectStartDate',
      type: 'date',
    },
    {
      label: 'label.objectEndDate',
      name: 'objectEndDate',
      type: 'date',
    },
    {
      label: 'label.extractStartDate',
      name: 'extractStartDate',
      type: 'date',
    },
    {
      label: 'label.extractEndDate',
      name: 'extractEndDate',
      type: 'date',
    },
    {
      label: 'label.objectType',
      name: 'objectType',
      type: 'select',
      tOptions: 'selections:ebsExtractType',
      isClearable: true,
    },
    {
      label: 'label.extractBatchStatus',
      name: 'extractStatus',
      type: 'select',
      tOptions: 'selections:ebsExtractStatus',
      isClearable: true,
    },
    {
      label: 'label.extractBatch',
      name: 'extractBatch',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
      isNeedClearable: true,
    },
    {
      label: 'label.organization',
      name: 'company',
    },
    {
      label: 'label.batchId',
      name: 'extractBatchId',
    },
    {
      label: 'label.reconcileBatch',
      name: 'reconcileBatch',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
      isNeedClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

AccountingReconciliationSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountingReconciliationSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountingReconciliationSearchForm);
