import React from 'react';
import PropTypes from 'prop-types';
import { GenericInput } from '../common';
import PhoneGroup from '../PhoneGroup';

const ContactForm = ({ data, onChange, onChangePhone, dataFiledContact }) => {
  return (
    <div className="d-flex flex-wrap mt-2 ml-2 mb-2">
      {dataFiledContact.map(item => {
        if (item && item.fieldPhone) {
          return (
            <PhoneGroup
              key={item.labelFilter}
              dataPhone={data[item.fieldPhone]}
              onChange={onChangePhone}
              colDiv="col-md-4"
            />
          );
        }

        return (
          <GenericInput
            key={item.labelFilter}
            value={data[item.fieldName] ? data[item.fieldName] : ''}
            label={item.labelFilter || ''}
            type="text"
            required={item.required}
            onChange={onChange}
            name={item.fieldName}
            wrapperClass="col-md-4"
          />
        );
      })}
    </div>
  );
};

ContactForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  dataFiledContact: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  onChangePhone: PropTypes.func,
};

ContactForm.defaultProps = {
  data: {},
  dataFiledContact: [],
  onChange: () => {},
  onChangePhone: () => {},
};

export default ContactForm;
