import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getDivision } from '../../../containers/RevenueConfiguration/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalCompanyDivision extends PureComponent {
  searchHanldeLocation = (payload, cb) => {
    const { getDivision } = this.props;
    getDivision('', resp => {
      cb({ success: true, data: resp.data ? resp.data : [] });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          label: 'label.name',
          name: 'name',
        },
        {
          label: 'label.description',
          name: 'description',
        },
        {
          label: 'label.shortName',
          name: 'shortName',
        },
        {
          label: 'label.code',
          name: 'code',
        },
        {
          label: 'label.enterprise',
          name: 'enterprise',
        },
        {
          label: 'label.location',
          name: 'locationIndex',
          render: (colName, item) => {
            return (
              <span>{item.address ? `${item.address.city}, ${item.address.state}, ${item.address.country}` : ''}</span>
            );
          },
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.enterpriseDivisions')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeLocation}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="name"
        isRequired={false}
      />
    );
  }
}

ModalCompanyDivision.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getDivision: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalCompanyDivision.defaultProps = {
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getDivision })(ModalCompanyDivision));
