import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'roundingPrecision',
    'roundingMethod',
    'accumulateQuantity',
  ]);
  const modifyAccumulatorsConfig = `modifyAccumulatorsConfig(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyAccumulatorsConfig} {
      id
    }
  }
`;
};
