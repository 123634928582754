import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['type', 'index', 'paymentMethod', 'providerName', 'txnType']);

  const modifyPaymentMethodUrls = `modifyPaymentMethodUrls(input: ${dataModify})`;
  return gql`
    mutation {
      ${modifyPaymentMethodUrls} {
        id
      }
    }
  `;
};
