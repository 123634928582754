import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { groupBy, map } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Breadcrumb, BarChartsGroup, GenericInput } from '../../../components/common';
import { getFolioSummaryDashboard } from '../actions';
import { getObjectFilesReport } from '../../ReportManagement/actions';
import RouteNames from '../../App/RouteNames';
import { listNameTitleUserDashboard } from '../constants';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionGetObjectFiles } from '../../ReportManagement/CheckPermission';
import { makeGetPermissionsReports, makeGetCcpTime } from '../../App/selectors';
import { getCcpDateTimeConfig } from '../../App/actions';

class FolioCount extends Component {
  state = { data: [], folioType: null };

  componentDidMount() {
    this.doGetFolioCountSummaryDashboard();
  }

  doGetFolioCountSummaryDashboard = () => {
    const { ccpTime, getCcpDateTimeConfig } = this.props;
    if (ccpTime) {
      this.doGetObjectFilesReport(ccpTime);
    } else {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          this.doGetObjectFilesReport(data?.ccpTime || null);
        }
      });
    }
  };

  doGetObjectFilesReport = ccpTime => {
    const { getObjectFilesReport } = this.props;
    const { folioType } = this.state;

    if (ccpTime)
      getObjectFilesReport(
        {
          page: 1,
          size: 500,
          filter: {
            startYearMonth: moment(ccpTime)
              .subtract(6, 'months')
              .format('YYYY-MM'),
            endYearMonth: moment(ccpTime).format('YYYY-MM'),
            documentType: folioType || null,
          },
        },
        ({ success, data }) => {
          if (success && data) {
            const newData = groupBy(data, 'yearMonth');
            const dataSetup = [];
            map(newData, (values, key) => {
              if (values && values.length) {
                dataSetup.push({
                  mon: moment(key)
                    .format('MMM-YYYY')
                    .toUpperCase(),
                  folioTypeData: values.map(val => {
                    return { folioType: val.documentType || null, value: val.fileCount || null };
                  }),
                });
              }
            });
            this.setState({ data: dataSetup });
          } else {
            this.setState({ data: [] });
          }
        }
      );
  };

  render() {
    const { data, folioType } = this.state;
    const { t, permissionsReports, isComponent } = this.props;
    let modeFolioCountSummary = 0;
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      modeFolioCountSummary = checkPermissionGetObjectFiles({ listPermission });
    }
    if (modeFolioCountSummary === 0) return '';

    if (isComponent)
      return (
        <BarChartsGroup
          key={data.name}
          title={t(listNameTitleUserDashboard.FOLIO_COUNT)}
          data={data || []}
          wrapperClass="chart-item-dashboard mt-2 mb-5"
          subClass="animate__animated animate__zoomIn"
          keySeries="value"
          keyCategories="mon"
          background={defineBackground[data.name] ? defineBackground[data.name] : null}
          isToolbar
          dataType="AMOUNT"
        />
      );
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t(listNameTitleUserDashboard.FOLIO_COUNT) }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="mb-4">
          <GenericInput
            value={folioType || null}
            wrapperClass="col-md-2 m-auto"
            label="label.folioType"
            name="folioType"
            type="select"
            tOptions="selections:documentType"
            onChange={({ value }) => {
              if (value === folioType) return null;
              return this.setState({ folioType: value || null }, () => this.doGetFolioCountSummaryDashboard());
            }}
          />
        </div>
        <div className="row mb-5">
          <BarChartsGroup
            key={data.name}
            title={t(listNameTitleUserDashboard.FOLIO_COUNT)}
            data={data || []}
            wrapperClass="chart-item embrix-line-barchart-type m-auto"
            subClass="animate__animated animate__zoomIn"
            keySeries="value"
            keyCategories="mon"
            background={defineBackground[data.name] ? defineBackground[data.name] : null}
            isToolbar
            dataType="AMOUNT"
            isDescription
          />
        </div>
      </div>
    );
  }
}

FolioCount.propTypes = {
  permissionsReports: PropTypes.objectOf(PropTypes.any),
  getCcpDateTimeConfig: PropTypes.func.isRequired,
  getObjectFilesReport: PropTypes.func.isRequired,
  ccpTime: PropTypes.string,
  t: PropTypes.func,
};

FolioCount.defaultProps = {
  permissionsReports: {},
  ccpTime: null,
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionsReports: makeGetPermissionsReports() || {},
  ccpTime: makeGetCcpTime() || '',
});

export default withTranslation('common')(
  connect(mapStateToProps, { getFolioSummaryDashboard, getObjectFilesReport, getCcpDateTimeConfig })(FolioCount)
);
