import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'amount',
    'billed',
    'salesChannel',
    'marketSegment',
    'accountType',
    'accountSubType',
    'revenueType',
    'revenueRecognitionType',
    'revenueActivityType',
    'transactionType',
    'status',
  ]);
  const modifyRevenueJournal = `modifyRevenueJournal(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyRevenueJournal} {
      id
    }
  }
`;
};
