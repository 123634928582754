import gql from 'graphql-tag';

export default id => gql`
  query {
    getQuoteById(id: "${id}") {
      id
      type
      status
      reason
      accountId
      userId
      clientId
      isPartialFulfillmentAllowed
      effectiveDate
      submittedDate
      initialTerm
      initialTermUnit
      renewalTerm
      renewalTermUnit
      trialTerm
      trialTermUnit
      trialType
      salesAgentId
      services {
        index
        serviceType
        action
        bundleId
        packageId
        lines {
          index
          action
          quantity
          priceOverride
          priceOffset
          discountPercent
          startDate
          endDate
          relativeStart
          relativeEnd
          relativeStartUnit
          relativeEndUnit
          priceOfferId
          discountOfferId
          priceOfferName
          discountOfferName
          discAmountOverride
          discPercentOverride
        }
      }
    }
  }
`;
