import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType', 'switchName'])}` : '';
  const getFileProcessingStats = `getFileProcessingStats(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getFileProcessingStats} {
        id
        serviceType
        fileName
        fileDate
        sourcePlatform
        cdrFileStageStatsData {
          stage
          pendingCount
          processedCount
          errorCount
          suspendedCount
          ignoredCount
          totalCount
          status
        }
      }
    }
  `;
};
