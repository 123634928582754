import gql from 'graphql-tag';

export default id => {
  const deletePendingManualInvoice = `deletePendingManualInvoice(input: "${id}")`;
  return gql`
  mutation {
    ${deletePendingManualInvoice} {
      accountId
    }
  }
`;
};
