import * as types from './types';

export function searchTasks(payload, cb) {
  return {
    type: types.SEARCH_TASKS,
    payload,
    cb,
  };
}

export function searchTasksSuccess(payload) {
  return {
    type: types.SEARCH_TASKS_SUCCESS,
    payload,
  };
}

export function searchAllTasks(payload, cb) {
  return {
    type: types.SEARCH_ALL_TASKS,
    payload,
    cb,
  };
}

export function searchAllTasksSuccess(payload) {
  return {
    type: types.SEARCH_ALL_TASKS_SUCCESS,
    payload,
  };
}

export function createTask(payload, cb) {
  return {
    type: types.CREATE_TASK,
    payload,
    cb,
  };
}

export function getTaskHistoryById(payload, cb) {
  return {
    type: types.GET_TASK_HISTORY_BY_ID,
    payload,
    cb,
  };
}

export function syncTaskToExternal(payload, cb) {
  return {
    type: types.SYNC_TASK_TO_EXTERNAL,
    payload,
    cb,
  };
}

export function modifyTask(payload, cb) {
  return {
    type: types.MODIFY_TASK,
    payload,
    cb,
  };
}

export function getChildTasksByParentId(payload, cb) {
  return {
    type: types.GET_CHILD_TASK_BY_PARENT_ID,
    payload,
    cb,
  };
}