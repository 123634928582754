import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  DataTable,
  TablePagination,
  TitleFrom,
  GenericInput,
  ModalWithItem,
  CollapsibleTable,
  ModalViewNote,
  SwitchExport,
  ButtonExport,
  ExcelExport,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { ReprocessingSuspendedFailedRecordsSearchForm, DetailRecordsSearchForm } from '../../../components/UsageHub';
import { getPageTotalCount } from '../../../utils/utils';
import {
  searchSuspendedFailedBatch,
  searchDetailRecords,
  updateFileDetailRecord,
  initiateUsageReprocess,
  getSuspendedFailedBatchDetail,
} from '../actions';
import RouteNames from '../../App/RouteNames';
import { messageNotesReprocessing } from '../constants';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';

const suspendedSort = {
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
};

const suspendedDetailsSort = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
  usageType: {
    asc: 'usageType_ASC',
    desc: 'usageType_DESC',
  },
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
};

const ReprocessingSuspendedFailedRecords = ({
  searchSuspendedFailedBatch,
  searchDetailRecords,
  updateFileDetailRecord,
  permissions,
  initiateUsageReprocess,
  getSuspendedFailedBatchDetail,
}) => {
  const buttonRef = useRef();

  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [totalCountDetails, setTotalCountDetails] = useState(null);
  const [pageDetails, setPageDetails] = useState(0);
  const [sizeDetails, setSizeDetails] = useState(20);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [sortedDetails, setSortedDetails] = useState({});
  const [filterDetails, setFilterDetails] = useState({});
  const [data, setData] = useState([]);
  const [isOpenItem, setIsOpenItem] = useState(false);
  const [recordsDetail, setRecordsDetail] = useState([]);
  const [itemSelect, setItemSelect] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [activeTab, setActiveTab] = useState(-1);
  const [suspendedFailedBatchDetail, setSuspendedFailedBatchDetail] = useState([]);
  const [isOpenModalNotes, setIsOpenModalNotes] = useState(false);
  const [messageNotes, setMessageNotes] = useState(false);
  const [isActivePdf, setIsActivePdf] = useState(false);
  const [isSubDetails, setIsSubDetails] = useState(false);
  const [isOpenModalNotesAction, setIsOpenModalNotesAction] = useState(false);

  const [itemSuspendedFailedDetailsSelected, setItemSuspendedFailedDetailsSelected] = useState(false);

  const { t } = useTranslation('common');
  const { modeEditSuspendedFailedUsageRecords } = permissions || {};

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onPageChangeDetails = page => {
    setPageDetails(page);
  };

  const onSizeChangeDetails = size => {
    setSizeDetails(size);
  };

  const onSortColumnDetails = (sortCol, sortDir) => {
    setSortedDetails({ sortCol, sortDir });
  };

  const doSearchSuspendedFailedBatch = useCallback(() => {
    setLoading(true);
    const newFilter = cloneDeep(filter);
    if (!newFilter?.startDate)
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD');

    if (!newFilter?.endDate)
      newFilter.endDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
    searchSuspendedFailedBatch(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? suspendedSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        setData(success && data ? data : []);
      }
    );
  }, [filter, searchSuspendedFailedBatch, page, size, sorted]);

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const onHandleSubmitDetails = filter => {
    setFilterDetails(filter);
  };

  const doSearchDetailRecords = useCallback(
    (item, isErrorCode) => {
      const newFilter = cloneDeep(filterDetails);
      newFilter.batchId = item?.batchId || itemSelect?.batchId || null;
      newFilter.errorCode = isErrorCode ? item?.errorCode || itemSelect?.errorCode : newFilter?.errorCode || null;
      searchDetailRecords(
        {
          page: pageDetails + 1,
          size: sizeDetails,
          filter: newFilter,
          sort: !isEmpty(sortedDetails) ? suspendedDetailsSort[sortedDetails.sortCol][sortedDetails.sortDir] : null,
        },
        ({ success, data }) => {
          setRecordsDetail(success && data && data.length ? data : []);
        }
      );
    },
    [filterDetails, itemSelect, pageDetails, searchDetailRecords, sizeDetails, sortedDetails]
  );

  const onToggleModalDetails = ({ item }) => {
    if (!isOpenItem) {
      doSearchDetailRecords(item);
      setItemSelect(item);
      setIsSubDetails(false);
    }
    setIsOpenItem(!isOpenItem);
  };

  const onToggleModalSubDetails = ({ item }) => {
    if (!isOpenItem) {
      doSearchDetailRecords({ ...item, batchId: itemSuspendedFailedDetailsSelected.batchId }, true);
      setItemSelect({ ...item, batchId: itemSuspendedFailedDetailsSelected.batchId });
      setIsSubDetails(true);
    }
    setIsOpenItem(!isOpenItem);
  };

  const onInitiateUsageReprocess = item => {
    initiateUsageReprocess({ reProcessRecs: 'true', fileName: item.batchId });
  };

  const onInitiateUsageReprocessAll = () => {
    initiateUsageReprocess({ reProcessRecs: 'true', errorCode: filter.errorCode || null }, ({ success }) => {
      if (success) {
        doSearchSuspendedFailedBatch();
      }
    });
  };

  const onInitiateUsageReprocessByItem = item => {
    initiateUsageReprocess({
      reProcessRecs: 'true',
      fileName: itemSuspendedFailedDetailsSelected.batchId,
      errorCode: item?.errorCode || null,
    });
  };

  const onSelectItem = ({ index, value }) => {
    try {
      const newData = cloneDeep(recordsDetail);
      newData[index].isChecked = value;
      if (newData.filter(val => !!val.isChecked).length === newData.length) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
      setRecordsDetail(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectAllItem = ({ value }) => {
    try {
      let newData = cloneDeep(recordsDetail);
      newData = newData.map(val => {
        const { isChecked, ...rest } = val;
        return {
          ...rest,
          isChecked: !!value,
        };
      });
      setRecordsDetail(newData);
      setIsSelectAll(!isSelectAll);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeTable = ({ name, value, index }) => {
    const newData = cloneDeep(recordsDetail);
    newData[index][name] = value;
    setRecordsDetail(newData);
  };

  const doUpdateFileDetailRecord = () => {
    const payload = [];
    const newData = cloneDeep(recordsDetail);
    if (newData && newData.length) {
      newData.forEach(val => {
        if (val.isChecked) {
          payload.push({
            usageId: val.usageId,
            batchId: val.batchId,
            startDate: val.startDate,
            endDate: val.endDate,
            provisioningId: val.provisioningId,
            accountId: val.accountId,
            usageType: val.usageType,
            usageClass: val.usageClass,
            apartyNumber: val.apartyNumber,
            bpartyNumber: val.bpartyNumber,
            cpartyNumber: val.cpartyNumber,
            npaId: val.npaId,
            quantity: val.quantity,
            downloadVolume: val.downloadVolume,
            uploadVolume: val.uploadVolume,
            errorCode: val.errorCode,
          });
        }
      });
    }
    updateFileDetailRecord({ embrixData: payload }, ({ success }) => {
      if (success) {
        setIsOpenItem(false);
        setRecordsDetail([]);
      }
    });
  };

  const doGetSuspendedFailedBatchDetail = ({ batchId }) => {
    getSuspendedFailedBatchDetail(batchId, ({ success, data }) => {
      setSuspendedFailedBatchDetail(success ? data || [] : []);
    });
  };

  const onToggleTab = ({ indexItem, item }) => {
    if (activeTab === indexItem) setActiveTab(-1);
    if (activeTab !== indexItem) {
      setActiveTab(indexItem);
      setItemSuspendedFailedDetailsSelected(item);
      doGetSuspendedFailedBatchDetail({ batchId: item?.batchId });
      // setItemGroupSelect(item);
      // setIndexGroupSelect(index);
    }
  };

  const onToggleModalNotes = ({ isRow }) => {
    if (!isOpenModalNotes) {
      if (isRow) {
        setMessageNotes(t('message.messageReporcessDetails'));
      } else {
        setMessageNotes(t('message.messageReporcess'));
      }
    }
    setIsOpenModalNotes(!isOpenModalNotes);
  };

  const onExport = ({ columns, fileNameExport }) => {
    if (isActivePdf) {
      convertJson2Pdf({
        data: recordsDetail || [],
        t,
        title: fileNameExport.toLocaleUpperCase(),
        fileName: `${fileNameExport.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
        columnsTable: columns,
        fontSize: 3,
      });
    } else {
      buttonRef.current.click();
    }
  };

  const onToggleModalNotesAction = () => {
    setIsOpenModalNotesAction(!isOpenModalNotesAction);
  };

  useEffect(() => {
    if (isFirstRender) {
      doSearchSuspendedFailedBatch();
    } else {
      setIsFirstRender(true);
    }
  }, [doSearchSuspendedFailedBatch, isFirstRender]);

  useEffect(() => {
    if (itemSelect) doSearchDetailRecords(itemSelect);
  }, [pageDetails, sizeDetails, filterDetails, sortedDetails, itemSelect, doSearchDetailRecords]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  useEffect(() => {
    setTotalCountDetails(
      getPageTotalCount({ totalCount: totalCountDetails, page: pageDetails, size: sizeDetails, items: recordsDetail })
    );
  }, [pageDetails, sizeDetails, recordsDetail, totalCountDetails]);

  const tableReprocessingSuspendedFailedRecords = [
    {
      name: 'batchId',
      label: 'label.batchidFileName',
      sortable: true,
      style: { maxWidth: '380px', width: '200px' },
    },
    {
      name: 'sourceSystem',
      label: 'label.sourceSystem',
    },
    {
      name: 'fileDate',
      label: 'label.fileDate',
    },
    {
      name: 'viewRecords',
      label: 'label.viewRecords',
      style: { maxWidth: '380px', width: '200px' },
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onToggleModalDetails({ item });
          }}
        >
          {t('label.viewRecords')}
        </button>
      ),
    },
    {
      name: 'reProcess',
      label: 'label.reProcess',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onInitiateUsageReprocess(item);
          }}
        >
          {t('label.reProcess')}
        </button>
      ),
    },
    {
      name: '',
      label: 'label.details',
      style: { width: 100 },
      render: (colName, item, idx, indexParent, activeTab) => (
        <button onClick={() => onToggleTab({ indexItem: idx, item })} type="button" className="btn-expand-table mr-3">
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
    {
      name: 'reProcess',
      label: '',
      render: () => (
        <button
          type="button"
          className="button button-border gray x-small mt-2 mb-2 ml-2"
          onClick={() => onToggleModalNotes({ isRow: false })}
        >
          {t('label.quickNotes')}
          <i className="fa fa-external-link ml-2" aria-hidden="true" />
        </button>
      ),
    },
  ];

  const detailRecordsColumns = [
    {
      name: 'sourceSystem',
      label: 'label.sourceSystem',
    },
    {
      name: 'usageId',
      label: 'label.usageId',
    },
    {
      name: 'errorCode',
      label: 'label.errorCode',
      // fieldOption: 'errorCodeProcessService',
      // isRenderT: true,
      // render: (colName, item, t) => {
      //   const slt = t ? t('selections:errorCodeProcessService')().find(val => val.value === item.errorCode) : '';
      //   return <span>{slt ? slt.label : ''}</span>;
      // },
    },
    {
      name: 'errorMsg',
      label: 'label.errorMessage',
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      style: { minWidth: '270px' },
      sortable: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.startDate}
          wrapperClass="col-md-12 p-0 inner-popover"
          type="date"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="startDate"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      sortable: true,
      style: { minWidth: '270px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.endDate}
          wrapperClass="col-md-12 p-0 inner-popover"
          type="date"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="endDate"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'provisioningId',
      label: 'label.provisioningId',
      style: { minWidth: '250px' },
      sortable: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.provisioningId}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="provisioningId"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'accountId',
      label: 'label.accountId',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.accountId}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="accountId"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'ratingProvisioningId',
      label: 'label.ratingProvisioningId',
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      fieldOption: 'serviceType',
      sortable: true,
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'usageType',
      label: 'label.usageType',
      sortable: true,
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.usageType}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="usageType"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'usageClass',
      label: 'label.usageClass',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.usageClass}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="usageClass"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'apartyNumber',
      label: 'label.aPartyNumber',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.apartyNumber}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="apartyNumber"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'bpartyNumber',
      label: 'label.bPartyNumber',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.bpartyNumber}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="bpartyNumber"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'cpartyNumber',
      label: 'label.cPartyNumber',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.cpartyNumber}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="cpartyNumber"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'direction',
      label: 'label.direction',
      fieldOption: 'usageDirection',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:usageDirection')().find(val => val.value === item.direction) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'npaId',
      label: 'label.npaId',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.npaId}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="npaId"
          disabled={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
          readOnly={
            !item.isChecked || !modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1
          }
        />
      ),
    },
    {
      name: 'quantity',
      label: 'label.quantity',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.quantity}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="quantity"
          type="number"
          disabled={!item.isChecked || modeEditSuspendedFailedUsageRecords === 1}
          readOnly={!item.isChecked || modeEditSuspendedFailedUsageRecords === 1}
        />
      ),
    },
    {
      name: 'downloadVolume',
      label: 'label.download',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.downloadVolume}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="downloadVolume"
          disabled={!item.isChecked || modeEditSuspendedFailedUsageRecords === 1}
          readOnly={!item.isChecked || modeEditSuspendedFailedUsageRecords === 1}
          type="number"
        />
      ),
    },
    {
      name: 'uploadVolume',
      label: 'label.upload',
      style: { minWidth: '250px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.uploadVolume}
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="uploadVolume"
          disabled={!item.isChecked || modeEditSuspendedFailedUsageRecords === 1}
          readOnly={!item.isChecked || modeEditSuspendedFailedUsageRecords === 1}
          type="number"
        />
      ),
    },
    {
      name: 'recordCount',
      label: 'label.recordCount',
    },
    {
      name: 'incomingRoute',
      label: 'label.incomingRoute',
    },
    {
      name: 'outgoingRoute',
      label: 'label.outgoingRoute',
    },
    {
      name: 'connectionName',
      label: 'label.connectionName',
    },
    {
      name: 'operatorId',
      label: 'label.operatorId',
    },
    {
      name: 'flexattr1',
      label: 'label.flexattr1',
    },
    {
      name: 'flexattr2',
      label: 'label.flexattr2',
    },
    {
      name: 'flexattr3',
      label: 'label.flexattr3',
    },
    {
      name: 'flexattr4',
      label: 'label.flexattr4',
    },
    {
      name: 'flexattr5',
      label: 'label.flexattr5',
    },
    {
      name: 'flexattr6',
      label: 'label.flexattr6',
    },
    {
      name: 'flexattr7',
      label: 'label.flexattr7',
    },
    {
      name: 'flexattr8',
      label: 'label.flexattr8',
    },
    {
      name: 'flexattr9',
      label: 'label.flexattr9',
    },
    {
      name: 'flexattr10',
      label: 'label.flexattr10',
    },
  ];

  const tableSuspendedFailedBatchDetail = [
    {
      name: 'status',
      label: 'label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:usageRecStatus')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'recordCount',
      label: 'label.recordCount',
    },
    {
      name: 'errorCode',
      label: 'label.errorCode',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:errorCodeProcessService')().find(val => val.value === item.errorCode) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'errorMsg',
      label: 'label.errorMessage',
    },
    {
      name: 'viewRecords',
      label: 'label.viewRecords',
      style: { maxWidth: '380px', width: '200px' },
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onToggleModalSubDetails({ item });
          }}
        >
          {t('label.viewRecords')}
        </button>
      ),
    },
    {
      name: 'reProcess',
      label: 'label.reProcess',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onInitiateUsageReprocessByItem(item);
          }}
        >
          {t('label.reProcess')}
        </button>
      ),
    },
    {
      name: 'reProcess',
      label: '',
      render: () => (
        <button
          type="button"
          className="button button-border gray x-small mt-2 mb-2 ml-2"
          onClick={() => onToggleModalNotes({ isRow: true })}
        >
          {t('label.quickNotes')}
          <i className="fa fa-external-link ml-2" aria-hidden="true" />
        </button>
      ),
    },
  ];

  let fileNameExport = itemSelect?.batchId ? `${t('label.CDR')}-${itemSelect?.batchId}` : '';
  if (fileNameExport) {
    if (fileNameExport.lastIndexOf('.') !== -1) {
      fileNameExport = fileNameExport.substring(0, fileNameExport.lastIndexOf('.'));
    }
  }

  let newDetailRecordsColumns = [...detailRecordsColumns];

  if (!isSubDetails) {
    newDetailRecordsColumns = [
      {
        name: 'select',
        label: 'label.select',
        render: (colName, item, index) => (
          <GenericInput
            value={item && item.isChecked}
            name="isSelectItem"
            type="checkbox"
            wrapperClass="col-md-4"
            onChange={({ value }) => onSelectItem({ item, index, value })}
            disabled={!modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1}
          />
        ),
      },
      ...newDetailRecordsColumns,
    ];
  }

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoiceUsage.path },
          { name: t('usagePage:sidebar.reprocessingSuspendedFailedRecords') },
        ]}
        isShowNoteConfig
        messageNote={messageNotesReprocessing(t)}
        isNoDefaultButton
      />
      <br />
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <TitleFrom title={t('label.suspendedFailedBatches')} />
          <br />
          <div className="card-body">
            <div className="repeater-file">
              <div>
                <ReprocessingSuspendedFailedRecordsSearchForm onSubmit={onHandleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-statistics h-100 mr-3">
        <br />
        <div className="col-md-12 pt-2 pb-4 d-flex">
          <button
            className="button x-small mt-4 ml-3 default-heigh-btn"
            // disabled={modeRegenerateInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
            type="button"
            onClick={onInitiateUsageReprocessAll}
            disabled={!data || !data.length}
          >
            {t('label.processAll')}
          </button>
          <button
            type="button"
            className="button button-border gray x-small mt-4 default-heigh-btn mr-2 btn-action-note"
            onClick={onToggleModalNotesAction}
          >
            {t('label.quickNotes')}
            <i className="fa fa-external-link ml-2" aria-hidden="true" />
          </button>
        </div>
        <div>
          <div className="group-collapsible">
            <CollapsibleTable
              columns={tableReprocessingSuspendedFailedRecords}
              data={data || []}
              isLoading={isLoading}
              sorted={sorted}
              onSort={onSortColumn}
              indexViewer={activeTab}
              isViewOnly
            >
              <div className="col-md-12 pt-2 pb-2">
                <DataTable columns={tableSuspendedFailedBatchDetail} data={suspendedFailedBatchDetail || []} />
              </div>
            </CollapsibleTable>
          </div>
        </div>
      </div>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalWithItem
        modalTitle={t('label.detailRecords')}
        isOpen={isOpenItem}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        onToggle={onToggleModalDetails}
        rightTitle={`${t('label.CDR')}-${itemSelect?.batchId}`}
      >
        <div className="card-body">
          <div className="repeater-file">
            <div>
              <DetailRecordsSearchForm onSubmit={onHandleSubmitDetails} />
            </div>
          </div>
        </div>
        <div className="col-md-12 p-0">
          <div className="mt-3 d-flex float-right mb-4 pt-3 pb-3">
            <div className="ml-auto mt-2 mb-auto mr-3">
              <SwitchExport
                onChange={() => {
                  setIsActivePdf(!isActivePdf);
                }}
                wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                title={t('label.excel')}
                checked={isActivePdf}
                rightTitle={t('label.pdf')}
              />
            </div>
            <>
              <ButtonExport onExport={() => onExport({ fileNameExport, columns: detailRecordsColumns })} />
              <ExcelExport
                element={<button type="button" className="display-none" ref={buttonRef} />}
                nameSheet={fileNameExport.toUpperCase()}
                multiDataSet={convertJson2Sheet({
                  data: recordsDetail || [],
                  t,
                  title: fileNameExport.toUpperCase(),
                  columnsTable: detailRecordsColumns,
                })}
                fileName={`${fileNameExport.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
                  'YYYY_MM_DD'
                )}`}
              />
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        {!isSubDetails && (
          <div className="form-group col-md-12 mt-3 buttons-attibute p-0">
            <button
              type="button"
              disabled={!recordsDetail || !recordsDetail.length || !recordsDetail.find(val => !!val.isChecked)}
              className="button button-border x-small float-right"
              onClick={doUpdateFileDetailRecord}
            >
              {t('label.saveConfig')}
            </button>
            <button
              type="button"
              onClick={evt => {
                doSearchDetailRecords();
                setIsSelectAll(false);
              }}
              className="button button-border black x-small float-right mr-2"
            >
              {t('label.cancel')}
            </button>
            <GenericInput
              value={isSelectAll}
              name="isSelectAll"
              type="checkbox"
              wrapperClass="col-md-2 mr-2 mt-2"
              onChange={({ value }) => onSelectAllItem({ value })}
              label={t('label.selectAll')}
              disabled={!modeEditSuspendedFailedUsageRecords || modeEditSuspendedFailedUsageRecords === 1}
            />
          </div>
        )}
        <br />
        <div>
          <DataTable
            sorted={sortedDetails}
            onSort={onSortColumnDetails}
            columns={newDetailRecordsColumns}
            data={recordsDetail || []}
            isLoading={isLoading}
          />
        </div>
        <br />
        <div className="mb-0 mt-3">
          <TablePagination
            pageNumber={pageDetails}
            pageSize={sizeDetails}
            totalCount={totalCountDetails}
            onPageChange={onPageChangeDetails}
            onSizeChange={onSizeChangeDetails}
          />
        </div>

        <div className="col-md-12 pt-3 pb-3">
          <button type="button" onClick={onToggleModalDetails} className="button x-small float-right">
            {t('common:label.back')}
          </button>
        </div>
      </ModalWithItem>
      <ModalViewNote isOpen={isOpenModalNotes} message={messageNotes} onToggle={onToggleModalNotes} />
      <ModalViewNote
        isOpen={isOpenModalNotesAction}
        message={t('message.quickNotesReprocess')}
        onToggle={onToggleModalNotesAction}
      />
    </div>
  );
};

ReprocessingSuspendedFailedRecords.propTypes = {
  searchSuspendedFailedBatch: PropTypes.func.isRequired,
  searchDetailRecords: PropTypes.func.isRequired,
  updateFileDetailRecord: PropTypes.func.isRequired,
};

ReprocessingSuspendedFailedRecords.defaultProps = {};

export default connect(null, {
  searchSuspendedFailedBatch,
  searchDetailRecords,
  updateFileDetailRecord,
  initiateUsageReprocess,
  getSuspendedFailedBatchDetail,
})(ReprocessingSuspendedFailedRecords);
