import gql from 'graphql-tag';

export default id => gql`
  query {
    getAccountById(input: { id: "${id}" }) {
      id
      currency
      customerSegment
      commercialName
    }
  }
`;
