import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import TitleFrom from '../../../../components/common/TitleFrom';
import { GenericInput, PasswordField } from '../../../../components/common';
import dataField from '../../constant';

class OauthAttributesForm extends React.PureComponent {
  state = { tenantData: [{}], isReadOnly: true };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ tenantData: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ tenantData: nextProps.data });
    }
  }

  onChange = ({ value, name }) => {
    try {
      const { onHandleChange, index } = this.props;
      const { tenantData } = this.state;
      const newData = cloneDeep(tenantData);
      newData[0][name] = value;
      onHandleChange({ value, name, index, indexAttr: 0, fieldName: 'oauthAttributes', isNotIndex: true });
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onFocusPasswordField = () => {
    this.setState({ isReadOnly: false });
  };

  render() {
    const { tenantData, isReadOnly } = this.state;

    const dataOauthAttr = tenantData[0];
    const { modeModifyFinanceGatewayMerchant, isRequireField, t } = this.props;
    return (
      <div className="col-md-12 sub-from">
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.oauthAttributesForm')} />
          </div>
        </div>
        <div className="group-flex">
          {dataField.fieldOauthForm.map(item => {
            if (item.isOptionType)
              return (
                <PasswordField
                  key={item.name}
                  label={item.label}
                  value={dataOauthAttr ? dataOauthAttr[item.name] : null}
                  defaultValue={dataOauthAttr ? dataOauthAttr[item.name] : null}
                  onChange={({ value, name }) => this.onChange({ value, name })}
                  required={isRequireField || item.required}
                  disabled={modeModifyFinanceGatewayMerchant === 1}
                  name={item.name}
                  wrapperClass="col-md-6"
                  readOnly={isReadOnly}
                  onFocus={this.onFocusPasswordField}
                />
              );
            return (
              <GenericInput
                key={item.name}
                label={item.label}
                value={dataOauthAttr ? dataOauthAttr[item.name] : null}
                type="text"
                onChange={({ value, name }) => this.onChange({ value, name })}
                required={isRequireField || item.required}
                disabled={modeModifyFinanceGatewayMerchant === 1}
                name={item.name}
                wrapperClass="col-md-6"
              />
            );
          })}
        </div>
      </div>
    );
  }
}

OauthAttributesForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  modeModifyFinanceGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

OauthAttributesForm.defaultProps = {
  data: [],
  onHandleChange: () => {},
  modeModifyFinanceGatewayMerchant: 2,
  isRequireField: false,
};

export default withTranslation('common')(OauthAttributesForm);
