import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import ItemForm from './ItemForm';
import { createItem } from '../actions';
import {
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
  getTaxCodeConfigSelect,
  getUomConfig,
  getCcpDateTimeConfig,
  getCcpPropertiesConfig,
  getPACProductCodesConfig,
} from '../../App/actions';
import { Breadcrumb } from '../../../components/common';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { checkPermissionCreateItem } from '../CheckPermission';

class PricingItemAdd extends Component {
  constructor(props) {
    const { location } = props;
    super(props);
    this.state = {
      isSubmitting: false,
      item: location && location.state ? location.state : {},
    };
  }

  onCreateItem = data => {
    const { createItem } = this.props;
    this.setState({ isSubmitting: true });
    createItem(data, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const { isSubmitting, item } = this.state;
    const {
      permissionsPricing,
      t,
      getUniqueProductFamily,
      getUniqueProductLine,
      getUniqueProductSubType,
      getUniqueProductType,
      getTaxCodeConfigSelect,
      getUomConfig,
      location,
      getCcpDateTimeConfig,
      getCcpPropertiesConfig,
      getPACProductCodesConfig,
    } = this.props;
    let modeCreateItem = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateItem = checkPermissionCreateItem({ listPermission: listPermissionPricing });
    }

    if (modeCreateItem !== 2) return '';
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.pricingItem.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.items'), url: RouteNames.pricingItem.path },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addItem')}</h5>
              </div>
              <ItemForm
                modeCreateItem={modeCreateItem}
                item={item}
                onSubmit={this.onCreateItem}
                isSubmitting={isSubmitting}
                getUniqueProductFamily={getUniqueProductFamily}
                getUniqueProductLine={getUniqueProductLine}
                getUniqueProductSubType={getUniqueProductSubType}
                getUniqueProductType={getUniqueProductType}
                getTaxCodeConfigSelect={getTaxCodeConfigSelect}
                getUomConfig={getUomConfig}
                getCcpDateTimeConfig={getCcpDateTimeConfig}
                getCcpPropertiesConfig={getCcpPropertiesConfig}
                isCreate
                getPACProductCodesConfig={getPACProductCodesConfig}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PricingItemAdd.propTypes = {
  createItem: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
};

PricingItemAdd.defaultProps = {
  permissionsPricing: {},
  location: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

const withConnect = connect(mapStateToProps, {
  createItem,
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
  getTaxCodeConfigSelect,
  getUomConfig,
  getCcpDateTimeConfig,
  getCcpPropertiesConfig,
  getPACProductCodesConfig,
});

export default withTranslation(['common'])(compose(withConnect)(PricingItemAdd));
