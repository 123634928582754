import React from 'react';
import PropTypes from 'prop-types';

const TitleFrom = ({ title, midTitle, subClass, wrapperClass, children, button }) => (
  <div className={`d-block w-100 d-flex ${subClass || ''}`}>
    <h5 className={`card-title title-form ${wrapperClass || ''}`} style={{ paddingBottom: '7px' }}>
      {title}
      {button || null}
    </h5>
    <div className="title-form-child">{children}</div>
    {midTitle && <h5 className="m-auto pr-5">{midTitle}</h5>}
  </div>
);

TitleFrom.propTypes = {
  title: PropTypes.string,
};

TitleFrom.defaultProps = {
  title: '',
};
export default TitleFrom;
