import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DataTable, GenericInput } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import ModalSelectRoles from './ModalSelectRoles';
import { getLastIndex } from '../../../utils/utils';

const listInputs = [
  {
    label: 'common:label.roleGroupName',
    wrapperClass: 'col-md-4',
    name: 'name',
    required: true,
  },
  {
    label: 'common:label.roleGroupStatus',
    wrapperClass: 'col-md-4',
    name: 'status',
    required: true,
    type: 'select',
    tOptions: 'selections:status',
  },
  {
    label: 'common:label.roleGroupType',
    wrapperClass: 'col-md-4',
    name: 'type',
    required: true,
  },
  {
    label: 'common:label.description',
    wrapperClass: 'col-md-4',
    name: 'description',
    required: true,
    type: 'textarea',
  },
];

class PricingItemForm extends Component {
  state = {
    isSubmitting: false,
    wasValidated: false,
    isSelectingRoles: false,
    parentItemId: null,
    roleGroupRoles: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    return {
      ...props.item,
      item: props.item,
    };
  }

  onOpenParentSelector = () => {
    this.setState({ isSelectingRoles: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingRoles: false });
  };

  onSelectRoles = (parentItemId, item) => {
    const { roleGroupRoles = [] } = this.state;
    this.setState({
      parentItemId,
      roleGroupRoles: [...roleGroupRoles, { ...item, isNew: true }],
      isSelectingRoles: false,
    });
  };

  handleInputChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    const { isModifyMode } = this.props;
    const { onSubmit } = this.props;
    const { id, name, description, type, status, roleGroupRoles = [] } = this.state;
    const roleNew = roleGroupRoles && roleGroupRoles.length ? roleGroupRoles.filter(r => r.isNew) : [];
    const oldRole = roleGroupRoles && roleGroupRoles.length ? roleGroupRoles.filter(r => !r.isNew) : [];

    let simpleRoles = [];
    if (!isModifyMode) {
      simpleRoles = roleGroupRoles.map((r, idx) => ({ index: idx + 1, roleId: r.id }));
    } else {
      simpleRoles = [
        ...oldRole.map(r => (r.id ? { index: r.index, roleId: r.id } : { index: r.index })),
        ...roleNew.map((r, idx) => ({ index: idx + getLastIndex({ data: oldRole }), roleId: r.id })),
      ];
    }
    const data = {
      id,
      name: name || null,
      description: description || null,
      type: type || null,
      status: status || null,
      roleGroupRoles: simpleRoles,
    };
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onRemoveRole = (e, role) => {
    e.preventDefault();
    const { roleGroupRoles } = this.state;
    const { isModifyMode } = this.props;
    if (isModifyMode && !role?.isNew) {
      const newRoles = [...roleGroupRoles.filter(item => item.id !== role.id), { index: role.index }];
      return this.setState({ roleGroupRoles: newRoles });
    }
    const newRoles = roleGroupRoles.filter(item => item.id !== role.id);
    return this.setState({ roleGroupRoles: newRoles });
  };

  onCancelData = () => {
    this.setState({
      isSubmitting: false,
      wasValidated: false,
      isSelectingRoles: false,
      parentItemId: null,
      roleGroupRoles: [],
      name: null,
      description: null,
      type: null,
      status: null,
    });
  };

  render() {
    const { roleGroupRoles, wasValidated } = this.state;
    const {
      creatingMode,
      isSubmitting,
      modeModifyRoleGroup,
      modeModifyRoleGroupStatus,
      t,
      onCancel,
      isModifyMode,
      id,
    } = this.props;
    let newListInput = cloneDeep(listInputs);
    const indexStatusOption = newListInput.findIndex(item => item.name === 'status');
    if (modeModifyRoleGroupStatus === 0) {
      newListInput = newListInput.filter(item => item.name !== 'status');
    }

    if (modeModifyRoleGroupStatus === 1) {
      newListInput[indexStatusOption].disabled = true;
    }

    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => (
            <Link
              className="text-success"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.userManagementRoleEdit.path, item.id),
                state: {
                  backLink: isModifyMode
                    ? RouteNames.prettifyPath(RouteNames.userManagementRoleGroupEdit.path, id)
                    : RouteNames.userManagementRoleGroupAdd.path,
                },
              }}
            >
              {item.id}
            </Link>
          ),
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
        {
          name: 'type',
          label: 'common:label.type',
        },
        {
          name: 'name',
          label: 'common:label.name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'action',
          label: 'common:label.action',
          render: (colName, item) => (
            <button className="btn-trash" type="button" onClick={e => this.onRemoveRole(e, item)}>
              <i className="fa fa-trash" />
            </button>
          ),
        },
      ],
      data: roleGroupRoles || [],
    };

    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          noValidate
        >
          <div className="form-row">
            {newListInput.map(item => (
              <GenericInput key={item.name} {...item} value={this.state[item.name]} onChange={this.handleInputChange} />
            ))}
            <div className="col-sm-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <DataTable
                    {...tableConfig}
                    isSupportRemoveIndex
                    isLoading={false}
                    noDataMessage="No record has found!"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group col-md-12 action-buttons">
            <label htmlFor="validationCustom01" />
            <br />
            <button
              type="button"
              className="button button-border x-small float-left"
              onClick={this.onOpenParentSelector}
            >
              {t('label.addRoleToRoleGroup')}
            </button>
            {isSubmitting && (
              <button type="button" className="button button-border x-small float-right">
                {t('label.save')}
              </button>
            )}
            {!creatingMode && !isSubmitting && modeModifyRoleGroup === 2 && (
              <button
                type="submit"
                className="button button-border x-small float-right"
              >
                {t('label.modify')}
              </button>
            )}
            {creatingMode && !isSubmitting && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.create')}
              </button>
            )}
            {/* <Link
              to={RouteNames.userManagementRoleGroup.path}
              className="button button-border black x-small  float-right"
            >
              {t('label.cancel')}
            </Link> */}
            {onCancel ? (
              <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
                {t('label.cancel')}
              </button>
            ) : (
              <button
                onClick={this.onCancelData}
                type="button"
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            )}
          </div>
        </form>
        <ModalSelectRoles
          isOpen={this.state.isSelectingRoles}
          selectedParentId={this.state.parentItemId}
          onSelect={this.onSelectRoles}
          onCancel={this.onCancelSelector}
        />
      </div>
    );
  }
}

PricingItemForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object,
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  modeModifyRoleGroup: PropTypes.number,
  modeModifyRoleGroupStatus: PropTypes.number,
};

PricingItemForm.defaultProps = {
  item: {},
  isSubmitting: false,
  creatingMode: false,
  modeModifyRoleGroup: 0,
  modeModifyRoleGroupStatus: 2,
};
export default withTranslation('common')(PricingItemForm);
