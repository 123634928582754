// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import { enumCreate } from './constants/adjustmentNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, enumCreate);
  const processAdjustment = `processAdjustment(input: ${dataCreate})`;
  return gql`
  mutation {
    ${processAdjustment} {
      id
      invoiceLines {
        id
      }
    }
  }
`;
};
