import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { getPageTotalCount } from '../../../utils/utils';
import TitleFrom from '../TitleFrom';
import AccountHeaderFrom from '../AccountHeaderFrom';
import constant from '../../../containers/CustomerPage/constants';
import { TablePagination, DataTable } from '..';
import TransactionsSearchForm from './TransactionsSearchForm';
import { searchTransactionUnitByAccountId } from '../../../containers/App/actions';
import ModalInvoice from '../ModalInvoice';
import ModalAccountItems from '../ModalAccountItems';

class ModalTransactions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      isOpenModal: false,
      isSearchDataModal: false,
      activeTab: {
        // name: 'Recurring Data',
        // isActive: true,
      },
      transactionUnit: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.transactionUnit }),
    };
  }

  componentDidMount() {
    this.doSearchTransactions();
  }

  doSearchTransactions = () => {
    const { searchTransactionUnitByAccountId, accountId, isOpen } = this.props;
    const { page, size, filter, sorted } = this.state;
    this.setState({ isSearching: true });
    if (isOpen)
      searchTransactionUnitByAccountId(
        {
          page: page + 1,
          size,
          filter: { ...filter, accountId },
          sort: !isEmpty(sorted) ? constant.transactionSort[sorted.sortCol][sorted.sortDir] : null,
        },
        ({ success, data }) => {
          this.setState({ isSearching: false, transactionUnit: success ? data : [] });
        }
      );
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filter: newFilter }, () => this.doSearchTransactions());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchTransactions());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchTransactions());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchTransactions());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetTransactionById = id => {
    const { searchTransactionUnitByAccountId } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true });
    searchTransactionUnitByAccountId(id, ({ data, success }) =>
      this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const { id, t, isOpen, onToggle, accountId } = this.props;
    const { transactionUnit } = this.state;
    const { isSearching, sorted, page, size, totalCount } = this.state;

    const columns = constant.accountTransactions.billsTable;
    const newColumns = [
      {
        name: 'id',
        label: 'label.id',
        // render: (colName, item) => (
        //   <button type="button" className="text-success no-border" onClick={() => this.doGetTransactionById(item.id)}>
        //     {item.id}
        //   </button>
        // ),
      },
      ...columns,
    ];
    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        className="modal-dialog modal-custom modal-70 bd-example-modal-lg modal-selector modal-lg"
      >
        <div className="col-md-12 p-3 mb-30">
          <div className="card card-statistics">
            <TitleFrom title={t('label.transactions')} />
            <AccountHeaderFrom accountNum={id} />
            <div className="card-body">
              <TransactionsSearchForm
                onSubmit={this.onHandleSubmit}
                ModalSearch={props => {
                  return (
                    <div>
                      <ModalInvoice
                        isOpen={props.isOpen === 'invoiceUnitId'}
                        onSelect={props.onSelect}
                        onCancel={props.onToggle}
                        accountId={accountId}
                      />
                      <ModalAccountItems
                        isOpen={props.isOpen === 'itemId'}
                        onSelect={props.onSelect}
                        onCancel={props.onToggle}
                        accountId={accountId}
                        invoiceUnitId={props.data && props.data.invoiceUnitId ? props.data.invoiceUnitId : ''}
                      />
                    </div>
                  );
                }}
              />
              <br />
              <div>
                <DataTable
                  columns={newColumns}
                  data={transactionUnit}
                  isLoading={isSearching}
                  sorted={sorted}
                  onSort={this.onSortColumn}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="col-sm-12 mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
          <div className="form-group col-md-12 action-buttons pt-4">
            <button type="button" onClick={onToggle} className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

ModalTransactions.propTypes = {
  searchTransactionUnitByAccountId: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ModalTransactions.defaultProps = {
  id: '',
};

const newModalTransactions = connect(mapStateToProps, {
  searchTransactionUnitByAccountId,
})(ModalTransactions);

export default withTranslation('common')(newModalTransactions);
