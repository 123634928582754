import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}`
    : '';
  const searchSavedManualInvoice = `searchSavedManualInvoice(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchSavedManualInvoice} {
        accountId
        savedInvoiceId
        clientId
        organization
        commercialName
        errorReason
        scheduleDate
        billStartDate
        billEndDate
        status
        substitutedInvoiceStatus
        invoiceId
        substituteInvoice
        accountCategory
      }
    }
  `;
};
