import gql from 'graphql-tag';

export default ({ type, scheduleDate, reason }) => {
  const getJobErrorDetails = `getJobErrorDetails(input: {
    type: ${type}
    scheduleDate: "${scheduleDate}"
    reason: "${reason}",
  })`;
  return gql`
    query {
      ${getJobErrorDetails} {
        id
        reason
        scheduleDate
        type
        jobs {
          index
          batchId
          reason
          payload
          status
          accountId
        }   
      }
    }
  `;
};
