/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectTimeUnit } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalRateUnit extends PureComponent {
  searchHanldeRateUnitId = (payload, cb) => {
    const { selectTimeUnit } = this.props;
    selectTimeUnit(payload, resp => {
      cb({ success: true, data: [resp.data.peakHourCfg, resp.data.workWeekCfg] });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'startOfWeek',
          label: 'common:label.startOfWeek',
        },
        {
          name: 'endOfWeek',
          label: 'common:label.endOfWeek',
        },
        {
          name: 'startTime',
          label: 'common:label.startTime',
        },
        {
          name: 'endTime',
          label: 'common:label.endTime',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseItem')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeRateUnitId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="code"
      />
    );
  }
}

ModalRateUnit.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectTimeUnit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalRateUnit.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectTimeUnit })(ModalRateUnit));
