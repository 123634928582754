import React from 'react';

export default {
  tableSelectBundleColumns: [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'name',
      label: 'common:label.name',
    },
    {
      name: 'description',
      label: 'common:label.description',
    },
  ],
  tableSelectPackageColumns: [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'name',
      label: 'common:label.name',
    },
    {
      name: 'description',
      label: 'common:label.description',
    },
    {
      name: 'salesChannel',
      label: 'label.salesChannel',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'marketSegment',
      label: 'common:label.marketSegment',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'accountType',
      label: 'common:label.accountType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'accountSubType',
      label: 'common:label.accountSubType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'country',
      label: 'common:label.country',
    },
    {
      name: 'currency',
      label: 'common:label.currency',
    },
    {
      name: 'status',
      label: 'common:label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
  ],
  tableSelectPriceOfferColumns: [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'name',
      label: 'common:label.name',
    },
    {
      name: 'description',
      label: 'common:label.description',
    },
  ],
  ModalMode: {
    purchasePackage: 'purchase_package',
    purchaseBundle: 'purchase_bundle',
    purchaseAlaCarte: 'purchase_ala_carte',
    addDiscounts: 'add_discounts',
    none: 'none',
  },

  groupNamePermissions: {
    SAVE_QUOTE: 'SAVE_QUOTE',
    SEARCH_QUOTE: 'SEARCH_QUOTE',
    CONVERT_QUOTE: 'CONVERT_QUOTE',
    CANCEL_QUOTE: 'CANCEL_QUOTE',
    APPROVE_QUOTE: 'APPROVE_QUOTE',
    NEW_QUOTE_TYPE: 'NEW_QUOTE_TYPE',
    MODIFY_QUOTE_TYPE: 'MODIFY_QUOTE_TYPE',
    CANCEL_QUOTE_TYPE: 'CANCEL_QUOTE_TYPE',
    PACKAGE_PURCHASE: 'PACKAGE_PURCHASE',
    BUNDLE_PURCHASE: 'BUNDLE_PURCHASE',
    ALACARTE_PURCHASE: 'ALACARTE_PURCHASE',
    ADD_BUNDLE: 'ADD_BUNDLE',
    ADD_PACKAGE: 'ADD_PACKAGE',
    ADD_PRICE_OFFER: 'ADD_PRICE_OFFER',
    DELETE_BUNDLE: 'DELETE_BUNDLE',
    DELETE_PACKAGE: 'DELETE_PACKAGE',
    DELETE_PRICE_OFFER: 'DELETE_PRICE_OFFER',
    GET_QUOTE: 'GET_QUOTE',
    UPGRADE_QUOTE_TYPE: 'UPGRADE_QUOTE_TYPE',
    DOWNGRADE_QUOTE_TYPE: 'DOWNGRADE_QUOTE_TYPE',
    OVERRIDE_QUOTE_LINES: 'OVERRIDE_QUOTE_LINES',
    UPDATE_PROVISIONING_ATTRIBUTES: 'UPDATE_PROVISIONING_ATTRIBUTES',
  },
};

export const newQuoteInfoField = [
  {
    label: 'common:label.accountId',
    fieldName: 'accountId',
    disabled: true,
  },
  {
    label: 'common:label.type',
    fieldName: 'type',
    tOptions: 'selections:quoteType',
    isNoClearable: true,
  },
  {
    label: 'common:label.status',
    fieldName: 'status',
    tOptions: 'selections:statusOrder',
    disabled: false,
  },

  {
    label: 'common:label.initialTermUnit',
    fieldName: 'initialTermUnit',
    tOptions: 'selections:initialTermUnit',
    disabled: false,
  },
  {
    label: 'common:label.initialTerm',
    fieldName: 'initialTerm',
    type: 'number',
    disabled: false,
  },
  {
    label: 'common:label.userId',
    fieldName: 'userId',
    disabled: true,
  },
  {
    label: 'common:label.renewalTermUnit',
    fieldName: 'renewalTermUnit',
    tOptions: 'selections:initialTermUnit',
    disabled: false,
  },
  {
    label: 'common:label.renewalTerm',
    fieldName: 'renewalTerm',
    type: 'number',
    disabled: false,
  },
  {
    label: 'common:label.effectiveDate',
    fieldDate: 'effectiveDate',
    disabled: false,
  },
  {
    label: 'common:label.trialTermUnit',
    fieldName: 'trialTermUnit',
    disabled: true,
  },
  {
    label: 'common:label.trialTerm',
    fieldName: 'trialTerm',
    disabled: true,
  },
  {
    label: 'common:label.isPartialFulfillmentAllowed',
    fieldName: 'isPartialFulfillmentAllowed',
    checkBox: true,
    disabled: false,
  },
  {
    label: 'common:label.salesAgentId',
    fieldName: 'salesAgentId',
    fa: 'fa fa-external-link',
  },
];

export const quoteDetailInfoField = [
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.type',
    name: 'type',
    tOptions: 'selections:types',
  },
  {
    label: 'common:label.status',
    name: 'status',
    tOptions: 'selections:statusOrder',
  },
  {
    label: 'common:label.reason',
    name: 'reason',
  },
  {
    label: 'common:label.initialTermUnit',
    name: 'initialTermUnit',
    tOptions: 'selections:initialTermUnit',
  },
  {
    label: 'common:label.initialTerm',
    name: 'initialTerm',
    type: 'number',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
  {
    label: 'common:label.renewalTermUnit',
    name: 'renewalTermUnit',
    tOptions: 'selections:initialTermUnit',
  },
  {
    label: 'common:label.renewalTerm',
    name: 'renewalTerm',
    type: 'number',
  },
  {
    label: 'common:label.effectiveDate',
    name: 'effectiveDate',
    type: 'date',
  },
  {
    label: 'common:label.crmId',
    name: 'crmId',
  },
  {
    label: 'common:label.trialType',
    name: 'trialType',
    tOptions: 'selections:trialTypes',
  },
  {
    label: 'common:label.trialTermUnit',
    name: 'trialTermUnit',
    tOptions: 'selections:initialTermUnit',
  },
  {
    label: 'common:label.trialTerm',
    name: 'trialTerm',
  },
  {
    label: 'common:label.submittedDate',
    name: 'submittedDate',
    type: 'date',
  },
  {
    label: 'common:label.isPartialFulfillmentAllowed',
    name: 'isPartialFulfillmentAllowed',
    type: 'checkbox',
  },
  {
    label: 'common:label.salesAgentId',
    name: 'salesAgentId',
  },
];
