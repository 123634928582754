import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['direction'])}` : '';
  const getVolumeAmountOperatorReport = `getVolumeAmountOperatorReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getVolumeAmountOperatorReport} {
        lineDate
        accountId
        operatorId
        noOfCalls
        noOfMinutes
        noOfSeconds
        amountMxn
        amountUsd
        operatorName
        direction
      }
    }
  `;
};
