import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'regionId']);
  const createRegionMapConfig = `createRegionMapConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createRegionMapConfig} {
      id
    }
  }
`;
};
