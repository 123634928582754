import gql from 'graphql-tag';

export default () => {
  const getTenantConfig = 'getTenantConfig(input: {dummy: ""})';
  return gql`
    query {
      ${getTenantConfig} {
        id
        name
        licenseKey
        vaultUri
        vaultPath
        tenantProfile {
          number
          street
          city
          country
          state
          postalCode
          enquiryEmail
          enquiryPhone
          companyTaxId
          companyTaxRegime
          companyTag
          logoPath
          externalUid
          extraLine
          landMark
          code
        }
        tenantMerchantAccounts {
          index
          type
          name
          startDate
          validity
          authType
          status
          paymentGatewayAttributes {
            index
            type
            url
          }
          taxGatewayAttributes {
            index
            type
            url
          }
          financeGatewayAttributes {
            index
            type
            url
          }
          provisionGatewayAttributes {
            index
            type
            url
          }
          httpBasicAttributes {
            clientId
            clientProfileId
            username
            password
          }
          jwtAttributes {
            authorizationKey
            merchantId
            publicKey
            privateKey
          }
          oauthAttributes {
            clientId
            clientSecret
            companyId
            refreshToken
            accessToken
          }
        }
      }
    }
  `;
};

// tenantPropertyDefaults {
//   customerSegment
//   defaultCurrency
//   sellingCompany
//   productCompany
//   productFamily
//   productLine
//   productType
//   productSubType
//   lineOfBusiness
//   legalEntity
//   organizationUnit
//   setOfBooks
//   taxItemId
//   contactRole
//   addressRole
//   salesChannel
//   marketSegment
//   accountType
//   accountSubType
//   invoiceDelivery
//   invoiceType
//   paymentMethod
//   paymentTerm
//   initialTerm
//   initialTermUnit
//   renewalTerm
//   renewalTermUnit
//   billingDom
//   billingFrequency
//   passwordExpiryInMonths
//   shortBillingCycle
//   paymentAutoAllocation
//   paymentNotification
//   useUsageStartTime
//   orderStatus
//   phoneType
//   selfCareUserType
//   selfCareUserCategory
//   defaultGLAccount
//   batchSizeBilling
//   batchSizeInvoice
//   batchSizePayment
//   batchSizeCommon
//   paymentFailureNotification
//   invoiceDueNotification
//   invoiceReminderThreshold
//   allowUsageProcessing
//   useParentUsagePriceOffer
//   triggerPendingBillForClosedSubs
//   includeSubsOnRegularInvoice
//   includeSubsOnManualInvoice
//   triggerPendingBillNewSubsActivation
//   triggerInvoicingOnNewSubsActivation
//   revenueTracking
//   skipDeleteCriteriaValidation
//   archiveAccountTimeExpiry
// }