import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import TitleFrom from '../../common/TitleFrom';
import { GenericInput } from '../../common';
import MoneyFormat from '../MoneyFormat';

const AdjustmentsHandleForm = ({
  title,
  listFields,
  data,
  onClick,
  isDisabledAllField,
  onChange,
  isApply,
  onBlurAmount,
  onHandleSubmit,
}) => {
  return (
    <div className="card card-statistics form-with-item">
      <div className="d-block d-md-flex justify-content-between">
        <div className="d-block d-md-flex clearfix sm-mt-20">
          <TitleFrom title={title} />
        </div>
      </div>
      <div className="form-group col-md-12 p-2">
        <form className="col-md-12 d-flex group-flex" onSubmit={onHandleSubmit}>
          {listFields.map(item => {
            if (item.options) {
              return (
                <GenericInput
                  key={item.label}
                  label={item.label || ''}
                  placeholder={item.placeholder}
                  value={!isEmpty(data[item.name]) ? data[item.name] : ''}
                  type="select"
                  name={item.name}
                  options={item.options}
                  onChange={onChange}
                  wrapperClass="col-md-4"
                  disabled={item.disabled}
                />
              );
            }

            if (item.type === 'money') {
              return (
                <GenericInput
                  key={item.label}
                  wrapperClass="col-md-4"
                  label={item.label || ''}
                  value={data[item.name]}
                  // thousandSeparator
                  // placeholder="0.00"
                  // prefix=""
                  type="number"
                  onChange={values => onChange({ name: item.name, value: values.value })}
                  // onBlur={onBlurAmount}
                />
              );
            }

            return (
              <GenericInput
                key={item.label}
                label={item.label || ''}
                placeholder={item.placeholder}
                value={!isEmpty(data[item.name]) ? data[item.name] : ''}
                type={`${item.type ? item.type : 'text'}`}
                name={item.name}
                disabled={isDisabledAllField}
                onChange={onChange}
                onClick={() => onClick(item.name)}
                wrapperClass={`${item.wrapperClass ? item.wrapperClass : 'col-md-4'}`}
              />
            );
          })}
          {isApply && (
            <div className="col-md-12 mt-3">
              <button type="submit" className="button button-border x-small float-right">
                Apply
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

AdjustmentsHandleForm.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlurAmount: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  listFields: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any),
  isDisabledAllField: PropTypes.bool,
  isApply: PropTypes.bool,
};

AdjustmentsHandleForm.defaultProps = {
  title: '',
  listFields: [],
  data: {},
  onClick: () => {},
  onChange: () => {},
  onBlurAmount: () => {},
  onHandleSubmit: () => {},
  isDisabledAllField: false,
  isApply: false,
};
export default AdjustmentsHandleForm;
