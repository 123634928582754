import gql from 'graphql-tag';

export default id => gql`
  query {
    getInvoiceTaxSummary(billUnitId: "${id}") {
      index
      city
      state
      jurisdiction
      taxCode
      taxCategory
      itemId
      taxableAmount
      exemptAmount
      amount
      taxRate
    }
  }
`;
