export const SEARCH_QUOTE = 'app/quotes/SEARCH_QUOTE';
export const SEARCH_QUOTE_SUCCESS = 'app/quotes/SEARCH_QUOTE_SUCCESS';

export const GET_QUOTE_DETAILS = 'app/quotes/GET_QUOTE_DETAILS';

export const SAVE_QUOTE = 'app/quotes/SAVE_QUOTE';
export const SAVE_QUOTE_SUCCESS = 'app/quotes/SAVE_QUOTE_SUCCESS';
export const SAVE_QUOTE_FAILURE = 'app/quotes/SAVE_QUOTE_FAILURE';

export const GET_QUOTE = 'app/quotes/GET_QUOTE';
export const BUILD_ORDER_PROVISIONING_ATTRIBUTES = 'BUILD_ORDER_PROVISIONING_ATTRIBUTES';

export const UPDATE_QUOTE_STATUS = 'app/quotes/UPDATE_QUOTE_STATUS';
export const CONVERT_QUOTE = 'app/quotes/CONVERT_QUOTE';
export const ASSOCIATE_QUOTE_TO_ACCOUNT = 'app/quotes/ASSOCIATE_QUOTE_TO_ACCOUNT';
export const GET_QUOTE_DETAILS_VIEWER = 'app/quotes/GET_QUOTE_DETAILS_VIEWER';
