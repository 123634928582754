/**
 * task management selectors
 */

import { createSelector } from 'reselect';

const selectTask = state => state.get('taskReducer');

const makeGetListTasks = () => createSelector(selectTask, tasks => [...tasks.get('listTasks')]);

const makeGetListAllTasks = () => createSelector(selectTask, tasks => [...tasks.get('listAllTasks')]);

export { makeGetListTasks, makeGetListAllTasks };
