import gql from 'graphql-tag';

export default id => gql`
  query {
    getPreviousAccountMerge(accountId: "${id}") {
      type
      updateServiceCapacityOnMove
      effectiveDate
      moveAllAccountServices
      moveAllAccountProvisioningAttributes
      fromAccount {
        accountId
        moveAllServices
        moveProvisioningAttributes
        billables {
          omsInternalId
          serviceId
          serviceName
          quantity
          servicePrice
          updateServiceCapacity
          totalPrice
        }
      }
      toAccount {
        accountId
        moveAllServices
        moveProvisioningAttributes
        billables {
          omsInternalId
          serviceId
          serviceName
          quantity
          servicePrice
          updateServiceCapacity
          totalPrice
        }
      }
    }
  }
`;
