/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getUserGroupsConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalUserGroup extends PureComponent {
  searchHanldeReasonCode = (payload, cb) => {
    const { getUserGroupsConfig } = this.props;
    getUserGroupsConfig(payload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'groupId',
          label: 'label.id',
        },
        {
          name: 'groupName',
          label: 'label.name',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseUserGroup')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeReasonCode}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="groupId"
      />
    );
  }
}

ModalUserGroup.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getARReasonCodeSelectionConfig: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalUserGroup.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getUserGroupsConfig })(ModalUserGroup));
