import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getLocations } from '../../../containers/RevenueConfiguration/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalCompanyLocation extends PureComponent {
  searchHanldeLocation = (payload, cb) => {
    const { getLocations } = this.props;
    getLocations(payload, resp => {
      cb({ success: true, data: resp.data.address });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        // {
        //   name: 'index',
        //   label: 'common:label.index',
        // },
        {
          name: 'street',
          label: 'common:label.street',
        },
        {
          name: 'city',
          label: 'common:label.city',
        },
        {
          name: 'state',
          label: 'common:label.state',
        },
        {
          name: 'country',
          label: 'common:label.country',
        },
        {
          name: 'postalCode',
          label: 'common:label.postalCode',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.companyLocations')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeLocation}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="index"
        isRequired={false}
      />
    );
  }
}

ModalCompanyLocation.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getLocations: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalCompanyLocation.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getLocations })(ModalCompanyLocation));
