/**
 * Collections selectors
 */

import { createSelector } from 'reselect';

const selectCollections = state => state.get('usageReducer');

const makeGetOperatorDispute = () =>
  createSelector(selectCollections, items => (items.get('operatorDispute') ? items.get('operatorDispute') : []));

const makeGetOperatorInvoice = () =>
  createSelector(selectCollections, items => (items.get('operatorInvoice') ? items.get('operatorInvoice') : []));

const makeGetOperatorRecordLog = () =>
  createSelector(selectCollections, items => (items.get('operatorRecordLog') ? items.get('operatorRecordLog') : []));

const makeGetTenantDispute = () =>
  createSelector(selectCollections, items => (items.get('tenantDispute') ? items.get('tenantDispute') : []));

const makeGetTenantOperatorRecord = () =>
  createSelector(selectCollections, items =>
    items.get('tenantOperatorRecord') ? items.get('tenantOperatorRecord') : []
  );

const makeGetReratingBatchesList = () =>
  createSelector(selectCollections, items =>
    items.get('reratingBatchesList') ? items.get('reratingBatchesList') : []
  );

const makeGetCustomRecordCfg = () => createSelector(selectCollections, items => items.get('customRecordList'));

const makeGetUsageFileMapCfg = () => createSelector(selectCollections, items => items.get('fileMapCfgList'));

const makeGetListUsageStream = () => createSelector(selectCollections, items => items.get('listUsageStream'));

const makeGetTrunkRules = () => createSelector(selectCollections, items => items.get('trunkRulesConfig'));

const makeGetPnnpList = () => createSelector(selectCollections, items => items.get('pnnPList'));

const makeGetOperatorMap = () => createSelector(selectCollections, items => items.get('operatorMap'));

export {
  makeGetOperatorDispute,
  makeGetOperatorInvoice,
  makeGetOperatorRecordLog,
  makeGetTenantDispute,
  makeGetTenantOperatorRecord,
  makeGetReratingBatchesList,
  makeGetCustomRecordCfg,
  makeGetUsageFileMapCfg,
  makeGetListUsageStream,
  makeGetTrunkRules,
  makeGetPnnpList,
  makeGetOperatorMap,
};
