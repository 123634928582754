import gql from 'graphql-tag';

export default () => gql`
  query {
    getUsageFileMapCfg(dummy: "") {
      id
      fileDelimiter
      source
      fileDescription
      recordIndicator
      serviceType
      usageFileMapRecCfg {
        index
        sourceRecord
        targetRecord
        recordDelimiter
        usageFileMapRecAttributesCfg {
          index
          sourceFieldPos
          targetField
          sourceField
        }
      }
    }
  }
`;
// targetRecord
