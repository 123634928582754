import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class ARSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.itemId',
      name: 'itemId',
    },
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.source',
      name: 'source',
      tOptions: 'selections:source',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.reason',
      name: 'reason',
      tOptions: 'selections:reasonConfig',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.arType',
      name: 'type',
      tOptions: 'selections:arType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.taxRule',
      name: 'taxRule',
      tOptions: 'selections:taxRule',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:arWriteoffStatus',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

ARSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ARSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ARSearchForm);
