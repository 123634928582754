import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { GenericInput, DataTable, FormCollapse, Switch } from '../../common';
import {
  getPaymentInstallmentByAccountId,
  createPaymentInstallment,
  modifyPaymentInstallment,
} from '../../../containers/CustomerPage/actions';

const listFields = [
  {
    label: 'label.count',
    name: 'numOfInstallment',
    type: 'number',
  },
  {
    label: 'label.daysOffset',
    name: 'daysOffset',
    type: 'number',
  },
  {
    label: 'label.monthOffset',
    name: 'monthOffset',
    type: 'number',
  },
  {
    label: 'label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:status',
    required: true,
  },
];

class PaymentInstallments extends PureComponent {
  state = {
    isModify: false,
    wasValidated: false,
    isSearching: true,
    data: [],
    dataSubmit: [],
    defaultData: {},
    activeTab: {},
    isFixedInstallments: true,
  };

  formRef = React.createRef();

  componentDidMount = () => {
    this.doGetPaymentInstallmentByAccountId();
  };

  doGetPaymentInstallmentByAccountId = () => {
    const { getPaymentInstallmentByAccountId, id } = this.props;
    this.setState({ isSearching: false });
    getPaymentInstallmentByAccountId(id, ({ success, data }) => {
      this.setState({
        data: success ? data || null : null,
        defaultData: success ? data : null,
        isModify: !!data?.id,
        isSearching: false,
      });
      // this.setState({ isSearching: false });
    });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  getValueFromName = (name, value) => {
    let result = '';
    value.map(item => {
      if (item.name === name) {
        result = item.value;
      }
    });
    return result;
  };

  onHandleChange = ({ name, value, index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = index;
    if (value && Number.parseFloat(value) < 0 && !Number.isNaN(value)) return '';
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ data: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ data: newData, dataSubmit });
  };

  onAddNewPaymentInstallments = () => {
    let indexNewItem = 0;
    const { data, dataSubmit } = this.state;
    const { id } = this.props;
    let newData = cloneDeep(data);
    if (!newData) newData = [];
    newData.forEach(item => {
      if (item.index > indexNewItem) {
        indexNewItem = item.index;
      }
    });
    if (!newData.length) newData = [];
    const newDataItem = {
      accountId: id,
      status: null,
      numOfInstallment: null,
      daysOffset: null,
      monthOffset: null,
      paymentInstallmentData: [],
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ data: newData, dataSubmit });
  };

  hanldeSubmit = (evt, index) => {
    evt.preventDefault();
    const { data, isFixedInstallments } = this.state;
    const { modifyPaymentInstallment, createPaymentInstallment } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (data && data[index] && data[index].id) {
      const { paymentInstallmentData, daysOffset, monthOffset, numOfInstallment, ...rest } = data[index];
      const newPayload = {
        paymentInstallmentData: !isFixedInstallments ? paymentInstallmentData : null,
        daysOffset: isFixedInstallments ? daysOffset : null,
        monthOffset: isFixedInstallments ? monthOffset : null,
        numOfInstallment: isFixedInstallments ? numOfInstallment : null,
        ...rest,
      };
      return modifyPaymentInstallment(newPayload, ({ success }) => {
        if (success) this.doGetPaymentInstallmentByAccountId();
      });
    }
    const findActiveData = data ? data.find(val => val.status === 'ACTIVE') : null;
    const { paymentInstallmentData, daysOffset, monthOffset, numOfInstallment, ...rest } = data[index];
    const newPayload = {
      paymentInstallmentData: !isFixedInstallments ? paymentInstallmentData : null,
      daysOffset: isFixedInstallments ? daysOffset : null,
      monthOffset: isFixedInstallments ? monthOffset : null,
      numOfInstallment: isFixedInstallments ? numOfInstallment : null,
      ...rest,
    };
    return createPaymentInstallment(
      { ...newPayload, status: findActiveData ? 'INACTIVE' : 'ACTIVE' },
      ({ success }) => {
        if (success) this.doGetPaymentInstallmentByAccountId();
      }
    );
  };

  onToggleTab = (name, index) => {
    const { activeTab, data } = this.state;
    if (activeTab.name === name) {
      this.setState({
        activeTab: {
          name,
          isActive: !activeTab.isActive,
        },
        isFixedInstallments: !(data && data[index] && data[index].paymentInstallmentData),
      });
    } else {
      this.setState({
        activeTab: {
          name,
          isActive: true,
        },
        isFixedInstallments: !(data && data[index] && data[index].paymentInstallmentData),
      });
    }
  };

  onAddNewPO = ({ index }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    const indexItemChange = index;
    if (newData && !newData[indexItemChange].paymentInstallmentData) {
      newData[indexItemChange].paymentInstallmentData = [];
    }
    newData[indexItemChange].paymentInstallmentData.forEach(item => {
      if (item.index > indexNewItem) {
        indexNewItem = item.index;
      }
    });
    if (!dataSubmit[indexItemChange]) {
      dataSubmit[indexItemChange] = { paymentInstallmentData: [] };
    }
    if (!dataSubmit[indexItemChange].paymentInstallmentData) dataSubmit[indexItemChange].paymentInstallmentData = [];
    const defaultData = cloneDeep(newData[indexItemChange].paymentInstallmentData[0]);
    if (defaultData) defaultData.index = indexNewItem + 1;
    let totalPercent = 0;
    newData[indexItemChange].paymentInstallmentData.forEach((val, idx) => {
      totalPercent += index === idx ? 0 : Number.parseFloat(val.percent || 0);
    });
    if (defaultData && totalPercent + Number.parseFloat(defaultData ? defaultData.percent : 0) >= 100)
      defaultData.percent = '0';
    const newDataItem = cloneDeep(defaultData) || {
      index: indexNewItem + 1,
      daysOffset: null,
      monthOffset: null,
      percent: null,
    };
    newData[indexItemChange].paymentInstallmentData.push(newDataItem);
    dataSubmit[indexItemChange].paymentInstallmentData.push(newDataItem);
    this.setState({ data: newData, dataSubmit });
  };

  onChangeTable = ({ name, value, index, indexParent }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    let totalPercent = 0;
    const indexItemChange = index;
    if (value && Number.parseFloat(value) < 0 && !Number.isNaN(value)) return '';
    if (name === 'percent') {
      newData[indexParent].paymentInstallmentData.forEach((val, idx) => {
        totalPercent += index === idx ? 0 : Number.parseFloat(val.percent || 0);
      });
      if (totalPercent + Number.parseFloat(value || 0) > 100) return '';
    }

    // if (!dataSubmit[indexParent]) dataSubmit[indexParent] = newData[indexParent];
    // const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    newData[indexParent].paymentInstallmentData[indexItemChange][name] = value;

    // dataSubmit[indexDataSubmit].paymentInstallmentData[indexDataSubmit][name] = value;
    this.setState({ data: newData, dataSubmit });
  };

  onChangeSwitchInstallments = () => {
    const { isFixedInstallments } = this.state;
    this.setState({ isFixedInstallments: !isFixedInstallments });
  };

  render() {
    const { wasValidated, isSearching, data, activeTab, isFixedInstallments } = this.state;
    const {
      id,
      t,
      modeCreatePaymentInstallment,
      modeModifyPaymentInstallment,
      modeGetPaymentInstallment,
      currencyOptions,
      customerInfo,
    } = this.props;
    if (modeGetPaymentInstallment === 0) return '';
    const paymentInstallmentsColumns = [
      {
        name: 'index',
        label: t('label.id'),
        render: (colName, item) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            // onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index, indexParent })}
            onChange={() => {}}
            name="index"
            type="number"
          />
        ),
      },
      {
        name: 'percent',
        label: t('label.percent'),
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.percent}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="percent"
            type="number"
            required
          />
        ),
      },
      {
        name: 'daysOffset',
        label: t('label.daysOffset'),
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.daysOffset}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="daysOffset"
            type="number"
            disabled={!!item.monthOffset}
          />
        ),
      },
      {
        name: 'monthOffset',
        label: t('label.monthOffset'),
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.monthOffset}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx, indexParent })}
            name="monthOffset"
            type="number"
            disabled={!!item.daysOffset}
          />
        ),
      },
    ];

    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }

    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('customerPage:sidebarHandleAccountPage.paymentInstallment')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <form
              // onSubmit={this.hanldeSubmit}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
              noValidate
              ref={this.formRef}
            >
              {data &&
                data.map((item, index) => {
                  if (Object.keys(item).length < 2) return '';
                  return (
                    <FormCollapse
                      key={`key-${index}`}
                      isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                      title={`${item.id || '<id>'} - ${item.status || ''}`}
                      state={`state-${index}`}
                      isNoT
                      onToggleTab={name => this.onToggleTab(name, index)}
                    >
                      <div className="col-md-12">
                        <div className="col-md-12 row">
                          <div className="col-md-12 ml-3 pt-3 row">
                            <Switch
                              onChange={this.onChangeSwitchInstallments}
                              name="isFixedInstallments"
                              wrapperClass={`col-md-4 pl-2 pt-4 pr-3 ${
                                isFixedInstallments ? 'switch-active' : 'switch-non-active'
                              }`}
                              title={t('common:label.fixedInstallments')}
                              // rightTitle={t('common:label.merge')}
                              checked={isFixedInstallments}
                              // rightTitle={t('common:label.desperse')}
                            />
                            {isFixedInstallments &&
                              listFields.map(val => (
                                <GenericInput
                                  value={item[val.name]}
                                  wrapperClass="col-md-4"
                                  disabled={
                                    (val.name === 'monthOffset' && !!item.daysOffset) ||
                                    (val.name === 'daysOffset' && !!item.monthOffset)
                                  }
                                  onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
                                  {...val}
                                />
                              ))}
                          </div>
                        </div>
                        {!isFixedInstallments && (
                          <div className="col-md-12 p-4">
                            <DataTable
                              columns={paymentInstallmentsColumns}
                              data={
                                item && item.paymentInstallmentData && item.paymentInstallmentData.length > 0
                                  ? item.paymentInstallmentData.filter(i => size(i) > 1)
                                  : []
                              }
                              isLoading={isSearching}
                              indexParent={index}
                            />
                            {modeCreatePaymentInstallment === 2 && (
                              <button
                                type="button"
                                className="button button-border black x-small"
                                onClick={() => this.onAddNewPO({ index })}
                                disabled={modeCreatePaymentInstallment === 1}
                              >
                                +
                                {t('label.addNewRow')}
                              </button>
                            )}
                          </div>
                        )}
                        {modeModifyPaymentInstallment === 2 && (
                          <div className="col-md-12 pb-3 mb-5">
                            <button
                              type="button"
                              onClick={evt => this.hanldeSubmit(evt, index)}
                              className="button button-border x-small float-right pb-2"
                            >
                              {t('label.saveConfig')}
                            </button>
                          </div>
                        )}
                      </div>
                    </FormCollapse>
                  );
                })}

              {/* <DataTable
                  columns={delayedBillingColumns}
                  data={data && data.length ? data.filter(item => size(item) > 1) : []}
                  isLoading={isSearching}
                /> */}
              <br />
              <div className="form-group col-md-12 pb-3 buttons-attibute">
                {modeCreatePaymentInstallment === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.onAddNewPaymentInstallments}
                    disabled={modeCreatePaymentInstallment === 1}
                  >
                    +
                    {t('label.addNewInstallmentPlan')}
                  </button>
                )}

                <button
                  type="button"
                  onClick={this.doGetPaymentInstallmentByAccountId}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

PaymentInstallments.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  getAccountDetail: PropTypes.func,
  getPaymentInstallmentByAccountId: PropTypes.func.isRequired,
  onHandleCreateAttribute: PropTypes.func,
  modifyAccount: PropTypes.func,
  accountNum: PropTypes.string,
  isNewAccount: PropTypes.bool,
};

PaymentInstallments.defaultProps = {
  accountNum: '',
  account: {},
  isNewAccount: false,
  getAccountDetail: () => {},
  modifyAccount: () => {},
  onHandleCreateAttribute: () => {},
};

const newPaymentInstallments = connect(mapStateToProps, {
  getPaymentInstallmentByAccountId,
  createPaymentInstallment,
  modifyPaymentInstallment,
})(PaymentInstallments);

export default withTranslation('common')(newPaymentInstallments);
