import gql from 'graphql-tag';
import { getUserId } from '../../localStorages';

export default () => gql`
  query {
    getUserDashboards(input: { userId: "${getUserId()}" }) {
      dashboards {
        name
        dataType
        data {
          mon
          value
        }
        pieChartData {
          name
          value
        }
      }
    }
  }
`;
