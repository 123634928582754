import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getTaxCodeConfig,
  modifyTaxConfig,
  createTaxConfig,
  search3rdPartyTaxConfig,
  createTaxTypeConfig,
  getTaxTypeConfig,
  modifyTaxTypeConfig,
} from '../../api';
import { queryRequest, mutationRequest } from '../../utils/request';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

// Tax config
export function* getTaxCodeConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxCodeConfig());
    yield put(isEndConnected());
    if (response.getTaxConfig && response.getTaxConfig) {
      yield put(actions.getTaxCodeConfigSuccess(response.getTaxConfig));
      if (cb) yield cb({ success: true, data: response.getTaxConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTaxCodeConfigSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* modifyTaxCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTaxConfig(payload));
    yield put(actions.getTaxCodeConfig());
    if (response.modifyTaxConfig) {
      if (cb) cb({ success: true, data: response.modifyTaxConfig });
      toast.success(i18n.t('common:apiMessage.modifyTaxCodeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createTaxCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTaxConfig(payload));
    if (response.createTaxConfig) {
      if (cb) cb({ success: true, data: response.createTaxConfig });
      toast.success(i18n.t('common:apiMessage.createTaxCodeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* get3rdPartyTaxConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, search3rdPartyTaxConfig(payload));
    yield put(isEndConnected());
    if (response.search3rdPartyTaxConfig) {
      yield put(actions.get3rdPartyTaxConfigSuccess(response.search3rdPartyTaxConfig));
      if (cb) yield cb({ success: true, data: response.search3rdPartyTaxConfig });
    } else {
      if (cb) yield cb({ success: false });
      yield put(actions.get3rdPartyTaxConfigSuccess([]));
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
    yield put(actions.get3rdPartyTaxConfigSuccess([]));
  }
}

export function* getTaxTypeConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxTypeConfig());
    yield put(isEndConnected());
    if (response.getTaxTypeConfig && response.getTaxTypeConfig) {
      if (cb) yield cb({ success: true, data: response.getTaxTypeConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyTaxTypeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTaxTypeConfig(payload));
    if (response.modifyTaxTypeConfig) {
      if (cb) cb({ success: true, data: response.modifyTaxTypeConfig });
      toast.success(i18n.t('common:apiMessage.modifyTaxTypeConfigSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createTaxTypeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTaxTypeConfig(payload));
    if (response.createTaxTypeConfig) {
      if (cb) cb({ success: true, data: response.createTaxTypeConfig });
      toast.success(i18n.t('common:apiMessage.createTaxTypeConfigSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* taxationSaga() {
  yield takeLatest(types.GET_TAX_CODE_CONFIG, getTaxCodeConfigSaga);
  yield takeLatest(types.MODIFY_TAX_CODE_CONFIG, modifyTaxCodeConfigSaga);
  yield takeLatest(types.CREATE_TAX_CODE_CONFIG, createTaxCodeConfigSaga);
  yield takeLatest(types.GET_3RD_PARTY_TAX_CONFIG, get3rdPartyTaxConfigSaga);
  yield takeLatest(types.GET_TAX_TYPE_CONFIG, getTaxTypeConfigSaga);
  yield takeLatest(types.CREATE_TAX_TYPE_CONFIG, createTaxTypeConfigSaga);
  yield takeLatest(types.MODIFY_TAX_TYPE_CONFIG, modifyTaxTypeConfigSaga);
}
