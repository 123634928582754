import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, BarCharts } from '../../../components/common';
import { getBarchartDashboardByType } from '../actions';
import RouteNames from '../../App/RouteNames';
import defineBackground from '../../../utils/defineBackgroundChart';
import BarchartSearchForm from './BarchartSearchForm';
import { checkPermissionUsageDataByServiceType, checkPermissionUsageDataByCallType } from '../CheckPermission';
import { makeGetPermissionsDashboard } from '../../App/selectors';

class CreditNotes extends Component {
  state = { data: [], filter: null };

  componentDidMount() {
    // console.log('componentDidMount');
    // const { getDashboardsByType } = this.props;
    // getDashboardsByType({ type: 'TOTAL_DEBIT_ADJUSTMENTS' }, ({ success, data }) => {
    //   if (success) this.setState({ data });
    // });
  }

  doGetBarChartDashboardByType = () => {
    const { filter } = this.state;
    const { getBarchartDashboardByType } = this.props;
    const { barChartDashboardType, serviceType, ...rest } = filter;
    let newService = serviceType && serviceType.length ? serviceType : [{ value: 'VOICE', label: 'VOICE' }];
    if (newService && newService.length > 0) {
      newService = newService.map(val => val.value).join(',');
    }
    if (!rest.startDate) {
      rest.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
    }
    if (!rest.endDate) {
      rest.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    if (!rest.noOfRecordsLimit) {
      rest.noOfRecordsLimit = '10';
    }
    if (!rest.yaxisParameter) {
      rest.yaxisParameter = 'AMOUNT';
    }
    if (!rest.xaxisParameter) {
      rest.xaxisParameter = 'SERVICETYPE';
    }
    getBarchartDashboardByType(
      {
        barChartDashboardType: barChartDashboardType || 'USAGE_DATA_BY_SERVICE_TYPE',
        usageDataDashboardInput: { ...rest, serviceType: newService },
      },
      ({ success, data }) => {
        const newData = cloneDeep(data);
        if (success && data && data.length) {
          this.setState({ data: newData });
        } else {
          this.setState({ data: [] });
        }
      }
    );
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter } }, () => {
      this.doGetBarChartDashboardByType();
    });
  };

  render() {
    const { data, filter } = this.state;
    const { t, permissionsDashboard } = this.props;
    let modeUsageDataByServiceType = 0;
    let modeUsageDataByCallType = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeUsageDataByServiceType = checkPermissionUsageDataByServiceType({ listPermission });
      modeUsageDataByCallType = checkPermissionUsageDataByCallType({ listPermission });
    }
    if (modeUsageDataByServiceType === 0 && modeUsageDataByCallType === 0) return '';
    let xLabel = '';
    let yLabel = '';
    const optionsX = t('selections:xaxisParameter')();
    const optionsY = t('selections:yaxisParameter')();
    if (filter && filter.xaxisParameter) {
      const optionSelectX = optionsX.find(val => val.value === filter.xaxisParameter);
      const optionSelectY = optionsY.find(val => val.value === filter.yaxisParameter);
      xLabel = optionSelectX ? optionSelectX.label.toLowerCase() : '';
      yLabel = optionSelectY ? optionSelectY.label.toLowerCase() : '';
      if (xLabel) {
        xLabel = xLabel.charAt(0).toUpperCase() + xLabel.slice(1);
      }
      if (yLabel) {
        yLabel = yLabel.charAt(0).toUpperCase() + yLabel.slice(1);
      }
    }
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('dashboardPage:sidebar.telecomTrafficDashboard') }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('dashboardPage:sidebar.telecomTrafficDashboard')}</h5>
              </div>
              <div className="pl-2">
                <BarchartSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row mb-5 traffic-dashboard">
            <BarCharts
              key={data.name}
              title={t('dashboardPage:sidebar.telecomTrafficDashboard')}
              data={data}
              wrapperClass="chart-item embrix-line-chart-type m-auto"
              subClass="animate__animated animate__zoomIn"
              keySeries="value"
              keyCategories="mon"
              background={defineBackground[data.name] ? defineBackground[data.name] : null}
              dataType="AMOUNT"
              xLabel={xLabel}
              yLabel={yLabel}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreditNotes.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

CreditNotes.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getBarchartDashboardByType })(CreditNotes));
