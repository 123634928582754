import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, []);
  const modifyTask = `modifyTask(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyTask} {
      id
      accountId
      userId
      parentTaskId
      createdDate
      taskType
      taskCategory
      taskDescription
      ticketId
      status
      notes
      userFirstname
      userLastName
      organization
      userEmail
      accountType
      audits {
        id
        accountId
        userId
        parentTaskId
        modifiedDate
        taskType
        taskCategory
        taskDescription
        ticketId
        status
        notes
        userFirstname
        userLastName
        organization
        userEmail
        accountType
      }
    }
  }
`;
};
