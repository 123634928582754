import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { map, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const BarCharts = ({ title, wrapperClass, data, background, style, xLabel, yLabel, subClass }) => {
  const { t } = useTranslation('common');
  // const listInteger = [];
  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        barHeight: data && data.length ? `${(data.length * 100) / 10}%` : '100%',
      },
    },
    xaxis: {
      categories: data && data.length > 0 ? map(data, item => item.name) : [],
    },
    yaxis: {
      categories: data && data.length > 0 ? map(data, item => item.name) : [],
    },
    chart: {
      type: 'bar',
      // stacked: false,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: [background || '#189F00'],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      custom({ series, seriesIndex, dataPointIndex, w }) {
        return (
          `${'<div class="arrow_box p-3">' + '<span>'}Value: ${series[seriesIndex][dataPointIndex]}</span>` + '</div>'
        );
      },
    },
    noData: {
      text: 'No data to present',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        // color: 'red',
        fontSize: '13px',
        fontFamily: "'Poppins', sans-serif",
        fontStyle: 'italic',
      },
    },
  };

  // const sortData = data && data.length ? sortBy(data, value => moment(value.mon, 'MM-YYYY')).reverse() : [];
  const sortData = cloneDeep(data);
  return (
    <div className={wrapperClass || 'col-md-4'} style={style}>
      <div className="mixed-chart">
        <h5 className="p-2 text-center card-title no-border">{title}</h5>
        {/* <span className="label-type">{typeOfData}</span> */}
        {xLabel && <div className="y-title">{xLabel}</div>}
        <ReactApexChart
          options={options}
          className={`embrix-bar-chart ${subClass || ''} ${sortData.length ? '' : 'no-data-chart'}`}
          series={[
            {
              data: data ? data.map(val => val.value) : [],
            },
          ]}
          width="100%"
          type="bar"
        />
        {yLabel && <div className="x-title">{yLabel}</div>}
      </div>
    </div>
  );
};

BarCharts.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  keySeries: PropTypes.string,
  keyCategories: PropTypes.string,
  dataType: PropTypes.string,
};

BarCharts.defaultProps = {
  title: '',
  wrapperClass: '',
  keySeries: '',
  keyCategories: '',
  data: [],
  dataType: 'COUNT',
};

export default BarCharts;
