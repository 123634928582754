/**
 * Orders management selectors
 */

import { createSelector } from 'reselect';

const selectOrders = state => state.get('orderReducer');

const makeGetListOrders = () => createSelector(selectOrders, orders => [...orders.get('listOrders')]);

const makePageOrderParams = () => createSelector(selectOrders, orders => orders.get('params').toJS());

const makeGetOrderDetail = () => createSelector(selectOrders, order => order.get('orderDetail'));

const makeErrorMessage = () => createSelector(selectOrders, order => order.get('errorMessage'));

const makeGetSubscription = () => createSelector(selectOrders, subscription => [...subscription.get('subscription')]);

const makeGetSubscriptionAssets = () =>
  createSelector(selectOrders, subscription => [...subscription.get('subscriptionAssets')]);

const makeGetServiceUnits = () => createSelector(selectOrders, serviceUnits => [...serviceUnits.get('serviceUnits')]);

const makeGetErrorSubscription = () =>
  createSelector(selectOrders, subscription => subscription.get('errorSubscription'));

const makeGetErrorSubscriptionAssets = () =>
  createSelector(selectOrders, subscription => subscription.get('errorSubscriptionAssets'));

const makeGetErrorServiceUnits = () =>
  createSelector(selectOrders, serviceUnits => serviceUnits.get('errorServiceUnits'));

const makeGetPriceUnits = () => createSelector(selectOrders, priceUnits => [...priceUnits.get('priceUnits')]);

const makeGetErrorPriceUnits = () => createSelector(selectOrders, priceUnits => priceUnits.get('errorPriceUnits'));

const makeGetOmsListConfig = () => createSelector(selectOrders, omsList => omsList.get('omsConfig'));

const makeGetOrderReasonCodes = () => createSelector(selectOrders, omsList => omsList.get('orderReason'));

export {
  makeGetListOrders,
  makePageOrderParams,
  makeGetOrderDetail,
  makeErrorMessage,
  makeGetSubscription,
  makeGetSubscriptionAssets,
  makeGetServiceUnits,
  makeGetPriceUnits,
  makeGetErrorSubscription,
  makeGetErrorSubscriptionAssets,
  makeGetErrorServiceUnits,
  makeGetErrorPriceUnits,
  makeGetOmsListConfig,
  makeGetOrderReasonCodes,
};
