import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'regionId']);
  const modifyRegionMapConfig = `modifyRegionMapConfig(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyRegionMapConfig} {
      id
    }
  }
`;
};
