import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['ratingType', 'productLine', 'status'])}`
    : '';
  const getUsageReconciliationReport = `getUsageReconciliationReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getUsageReconciliationReport} {
        batchId
        fileName
        fileDate
        source
        errorCode
        status
        totalFileRecordCount
        totalFileRecordLoaded
        totalFileRecordErrored
        ratingStatusCount
        ratingErrorCount
      }
    }
  `;
};
