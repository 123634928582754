import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { DataTable, GenericInput, TitleFrom, AccountHeaderFrom } from '../../../../components/common';
import { convertSymbol2OptionCurrency } from '../../../../constantsApp/symbolList';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { modifyExchangeRate, getExchangeRateById } from '../../actions';
import { makeGetExchangeRateById } from '../../selectors';
import { getIndexData, supportShowNumberValue } from '../../../../utils/utils';

class ModifyCurrency extends React.PureComponent {
  state = {
    data: {},
    isLoading: false,
    oldValue: {},
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.exchangeRateId) return null;
    return {
      data: props.exchangeRateId ? props.exchangeRateId : null,
      oldValue: props.exchangeRateId,
    };
  }

  componentDidMount() {
    this.doGetExchangeRateById();
  }

  doGetExchangeRateById = () => {
    const {
      getExchangeRateById,
      match: {
        params: { id },
      },
    } = this.props;
    getExchangeRateById({ id });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChange = ({ index, name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.exchangeRateList[getIndexData({ index, data: newData.exchangeRateList })][name] = value;
    this.setState({ data: newData });
  };

  addNewItem = () => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      newData.exchangeRateList.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.exchangeRateList.push({
        index: lastIndex + 1,
        type: '',
        exchangeRate: '',
        sourceCurrency: '',
        targetCurrency: '',
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.exchangeRateList[getIndexData({ index, data: newData.exchangeRateList })] = {
      index: newData.exchangeRateList[getIndexData({ index, data: newData.exchangeRateList })].index,
    };
    this.setState({ data: newData });
  };

  onHandleSave = e => {
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { data } = this.state;
    const { modifyExchangeRate } = this.props;
    const { id, userId, exchangeRateList } = data;
    return modifyExchangeRate({
      id,
      userId,
      exchangeRateList: exchangeRateList.length
        ? exchangeRateList
        : // ? exchangeRateList.map(val => ({ type: val.type, exchangeRate: val.exchangeRate, index: val.index }))
          [],
    });
  };

  render() {
    const { t, permissions, location } = this.props;
    const { modeModifyCurrencyExchangeConfig } = permissions;
    if (!modeModifyCurrencyExchangeConfig) return '';
    const { data, isLoading, wasValidated } = this.state;
    const tableColumns = [
      {
        name: 'sourceCurrency',
        label: 'label.sourceCurrency',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.sourceCurrency}
              name="sourceCurrency"
              type="select"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              options={convertSymbol2OptionCurrency([])}
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'targetCurrency',
        label: 'label.targetCurrency',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.targetCurrency}
              name="targetCurrency"
              type="select"
              options={convertSymbol2OptionCurrency([])}
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'type',
        label: 'label.exchangeRateType',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.type}
              name="type"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="select"
              tOptions="selections:exchangeRateType"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        render: (name, item) => {
          return (
            <GenericInput
              value={supportShowNumberValue(item.exchangeRate)}
              name="exchangeRate"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="number"
            />
          );
        },
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={{
            pathname: RouteNames.revenueConfigurationCurrencyExchange.path,
            state: location && location.state ? location.state : null,
          }}
          titleBtn={t('label.back')}
          titleRight={t('label.currencyExchangeEdit')}
        />
        <br />
        <div className="col-sm-12 mb-30">
          <form
            onSubmit={this.onHandleSave}
            className={`needs-validation col-sm-12 mb-30 card card-statistics ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <TitleFrom
              title={t('label.currencyExchangeEdit')}
              midTitle={`${t('label.effectiveDate')} : ${data.effectiveDate || ''}`}
            />
            <AccountHeaderFrom title={t('label.id')} accountNum={data.id} />
            <br />
            <DataTable
              columns={tableColumns}
              data={data && data.exchangeRateList ? data.exchangeRateList.filter(val => size(val) > 1) : []}
              isLoading={isLoading}
            />
            <div className="form-group col-md-12 buttons-attibute">
              {modeModifyCurrencyExchangeConfig === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                  +
                  {t('label.addNewExchangeRate')}
                </button>
              )}
              {modeModifyCurrencyExchangeConfig === 2 && (
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                >
                  {t('label.modify')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetExchangeRateById}
                className="button mr-2 button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ModifyCurrency.propTypes = {
  getExchangeRateById: PropTypes.func,
  modifyExchangeRate: PropTypes.func,
};

ModifyCurrency.defaultProps = {
  getExchangeRateById: () => {},
  modifyExchangeRate: () => {},
};

const mapStateToProps = createStructuredSelector({
  exchangeRateId: makeGetExchangeRateById() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    modifyExchangeRate,
    getExchangeRateById,
  })(withRouter(ModifyCurrency))
);
