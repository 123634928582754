import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { btnCollapse } from '../../../constantsApp';
import {
  modifyTenant,
  getCcpProperties,
  modifyCcpProperties,
  modifyPaymentMethodUrls,
  getTenantSelfCareConfig,
  saveSelfcareConfig,
  modifyTenantSelfCareConfig,
  getTenantProfileConfig,
  getTenantMerchantDataProjectConfig,
  getTenantMerchantDataPaymentConfig,
  getTenantMerchantDataTaxConfig,
  getTenantMerchantDataFinanceConfig,
  getTenantMerchantDataProvisioningConfig,
  getTenantMerchantDataCustomerSupportConfig,
  getTenantMerchantDataDocumentConfig,
  getTenantMerchantDataOperationConfig,
  getTenantMerchantDataCRMConfig,
  getTenantProjectGateway,
  getTenantCustomerSupportGateway,
  getTenantDocumentGateway,
  getTenantOperationGateway,
  getTenantCRMGateway,
  getGatewayData,
  getTenantProvisionGateway,
  getTenantFinanceGateway,
  getTenantTaxGateway,
  getTenantPaymentGateway,
} from '../actions';
import { getS3Buctket, readFileFromS3, uploadMultiPartFiles, getAwsS3Credential } from '../../App/actions';
import PlatformDefaults from '../FormEdit/PlatformDefaults';
import SelfCareMakerting from '../FormEdit/SelfCareMakerting';
import GatewayForm from '../FormEdit/GatewayForm';
import GatewayNewForm from '../FormEdit/GatewayNewForm';
import TenantForm from '../FormEdit/TenantForm';
import CompanyProfile from '../FormEdit/CompanyProfile';
import { makeTenantData, makeGetCcpProperties } from '../selectors';
import RouteName from '../../App/RouteNames';
import { checkPermissionForAllPages as allPermissionsTasks } from '../../TaskManagement/CheckPermission';
import {
  makeGetPermissionsTenantManagement,
  makeGetS3Config,
  makeGetPermissionsCustomerManagement,
} from '../../App/selectors';
import {
  checkPermissionGetTenantInfo,
  checkPermissionSetLincenseData,
  checkPermissionModifyLicenseData,
  checkPermissionModifyCompanyProfile,
  checkPermissionModifyPaymentGatewayMerchant,
  checkPermissionModifyTaxGatewayMerchant,
  checkPermissionModifyFinanceGatewayMerchant,
  checkPermissionModifyPropertyDefaults,
  checkPermissionModifyDataGatewayProvider,
  checkPermissionModifyCrmGatewayProvider,
  checkPermissionModifyProvisioningGatewayProvider,
} from '../CheckPermission';
import IdSequenceConfig from '../IdSequenceConfig';
import SchedulePatternConfig from '../SchedulePatternConfig';
import Templates from '../Templates';
import SearchTaskList from '../../TaskManagement/SearchTaskList';
import FolioResponseFile from '../FolioResponseFile';

class TenantDetail extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: {
        name: btnCollapse.tenantManagement[0].state,
        isActive: true,
      },
      tenantData: {},
    };
  }

  componentDidMount() {
    const { tenantData, getAwsS3Credential, s3Config } = this.props;
    if (tenantData) {
      this.setState({ tenantData });
    }
    if (!s3Config || !s3Config.accessKey) getAwsS3Credential();
    // getCcpProperties();
    // getPaymentMethodUrls();
  }

  componentWillReceiveProps(nextProps) {
    const { tenantData } = nextProps;
    if (tenantData) {
      this.setState({ tenantData });
    }
  }

  render() {
    const { wasValidated, tenantData } = this.state;
    const {
      permissionsTenant,
      t,
      modifyTenant,
      getTenantConfig,
      getCcpProperties,
      ccpProperties,
      modifyCcpProperties,
      // getPaymentMethodUrls,
      // modifyPaymentMethodUrls,
      // gatewayCanonicalConfig,
      getTenantSelfCareConfig,
      saveSelfcareConfig,
      modifyTenantSelfCareConfig,
      getS3Buctket,
      s3Config,
      readFileFromS3,
      uploadMultiPartFiles,
      getTenantMerchantDataProjectConfig,
      getTenantMerchantDataPaymentConfig,
      getTenantMerchantDataTaxConfig,
      getTenantMerchantDataFinanceConfig,
      getTenantMerchantDataProvisioningConfig,
      getTenantMerchantDataCustomerSupportConfig,
      getTenantMerchantDataDocumentConfig,
      getTenantMerchantDataOperationConfig,
      getTenantMerchantDataCRMConfig,
      getTenantProjectGateway,
      getTenantCustomerSupportGateway,
      getTenantDocumentGateway,
      getTenantOperationGateway,
      getTenantCRMGateway,
      getGatewayData,
      getTenantProvisionGateway,
      // getTenantDataConfig,
      getTenantFinanceGateway,
      getTenantTaxGateway,
      getTenantPaymentGateway,
      permissionsCustomer,
    } = this.props;
    let modeGetTenantInfo = 0;
    let modeSetLincenseData = 0;
    let modeModifyLicenseData = 0;
    let modeModifyCompanyProfile = 0;
    let modeModifyPropertyDefaults = 0;
    let modeModifyModifyCrmGatewayProvider = 0;
    let modeModifyProvisioningGatewayProvider = 0;
    let modeModifyTaxGatewayMerchant = 0;
    let modeModifyPaymentGatewayMerchant = 0;
    let modeModifyFinanceGatewayMerchant = 0;
    let modeModifyModifyDataGatewayProvider = 0;
    let permissionsTasks = {};

    if (permissionsTenant && permissionsTenant.tenantOnboardModulePermissions) {
      const listPermission = permissionsTenant.tenantOnboardModulePermissions;
      modeGetTenantInfo = checkPermissionGetTenantInfo({ listPermission });
      modeSetLincenseData = checkPermissionSetLincenseData({ listPermission });
      modeModifyLicenseData = checkPermissionModifyLicenseData({ listPermission });
      modeModifyCompanyProfile = checkPermissionModifyCompanyProfile({ listPermission });
      modeModifyPropertyDefaults = checkPermissionModifyPropertyDefaults({ listPermission });

      // Gateway
      modeModifyModifyCrmGatewayProvider = checkPermissionModifyCrmGatewayProvider({ listPermission });
      modeModifyProvisioningGatewayProvider = checkPermissionModifyProvisioningGatewayProvider({ listPermission });
      modeModifyTaxGatewayMerchant = checkPermissionModifyTaxGatewayMerchant({ listPermission });
      modeModifyPaymentGatewayMerchant = checkPermissionModifyPaymentGatewayMerchant({ listPermission });
      modeModifyFinanceGatewayMerchant = checkPermissionModifyFinanceGatewayMerchant({ listPermission });
      modeModifyModifyDataGatewayProvider = checkPermissionModifyDataGatewayProvider({ listPermission });
    }

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      permissionsTasks = allPermissionsTasks({ listPermission });
    }

    if (!modeGetTenantInfo) return null;

    return (
      <div className={`col-sm-12 mb-4 ${wasValidated ? 'was-validated' : ''} `}>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantData.path}`}
            component={() => (
              <CompanyProfile
                modeModifyCompanyProfile={modeModifyCompanyProfile}
                modifyTenant={modifyTenant}
                uploadMultiPartFiles={uploadMultiPartFiles}
                getTenantConfig={getTenantConfig}
                s3Config={s3Config}
                readFileFromS3={readFileFromS3}
                modeSetLincenseData={modeSetLincenseData}
                modeModifyLicenseData={modeModifyLicenseData}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantPlatformDefaults.path}`}
            component={() => (
              <PlatformDefaults
                modeModifyPropertyDefaults={modeModifyPropertyDefaults}
                getCcpProperties={getCcpProperties}
                ccpProperties={ccpProperties}
                modifyCcpProperties={modifyCcpProperties}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantSelfCareMakerting.path}`}
            component={() => (
              <SelfCareMakerting
                modeModifyPropertyDefaults={modeModifyPropertyDefaults}
                getCcpProperties={getCcpProperties}
                ccpProperties={ccpProperties}
                modifyCcpProperties={modifyCcpProperties}
                getTenantSelfCareConfig={getTenantSelfCareConfig}
                saveSelfcareConfig={saveSelfcareConfig}
                modifyTenantSelfCareConfig={modifyTenantSelfCareConfig}
                getS3Buctket={getS3Buctket}
                s3Config={s3Config}
                readFileFromS3={readFileFromS3}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.paymentGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataPaymentConfig}
                type="PAYMENT_GATEWAY"
                getTenantURLMapping={getTenantPaymentGateway}
                getGatewayData={getGatewayData}
                gatewayKey="paymentGatewayAttributes"
                tOptionsConfig="selections:paymentGatewayUrlType"
                titleForm={t('label.paymentGatewayURLMapping')}
                title={t('label.requestResponseGatewayMapping')}
                modeModify={modeModifyPaymentGatewayMerchant}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantTaxGateway.path}`}
            component={() => (
              <GatewayNewForm
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataTaxConfig}
                type="TAX_GATEWAY"
                getTenantURLMapping={getTenantTaxGateway}
                getGatewayData={getGatewayData}
                gatewayKey="taxGatewayAttributes"
                tOptionsConfig="selections:taxGetWayType"
                titleForm={t('label.taxGatewayURLMapping')}
                title={t('label.requestResponseTaxMapping')}
                modeModify={modeModifyTaxGatewayMerchant}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantFinanceGateway.path}`}
            component={() => (
              <GatewayNewForm
                modifyTenant={modifyTenant}
                type="FINANCE_GATEWAY"
                getTenantConfig={getTenantMerchantDataFinanceConfig}
                getTenantURLMapping={getTenantFinanceGateway}
                getGatewayData={getGatewayData}
                gatewayKey="financeGatewayAttributes"
                tOptionsConfig="selections:financeGatewayType"
                titleForm={t('label.financeGatewayURLMapping')}
                title={t('label.requestResponseFinanceMapping')}
                modeModify={modeModifyFinanceGatewayMerchant}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantProvisioningGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                type="PROVISIONING_GATEWAY"
                getTenantConfig={getTenantMerchantDataProvisioningConfig}
                getTenantURLMapping={getTenantProvisionGateway}
                getGatewayData={getGatewayData}
                gatewayKey="provisionGatewayAttributes"
                tOptionsConfig="selections:provisionGatewayUrlType"
                titleForm={t('label.provisionGatewayURLMapping')}
                title={t('label.requestResponseProvisionMapping')}
                isProvisioningGateway
                modeModify={modeModifyProvisioningGatewayProvider}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.projectGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataProjectConfig}
                type="PROJECT_GATEWAY"
                getTenantURLMapping={getTenantProjectGateway}
                getGatewayData={getGatewayData}
                gatewayKey="projectGatewayAttributes"
                tOptionsConfig="selections:projectGatewayUrlType"
                titleForm={t('label.projectGatewayURLMapping')}
                title={t('label.requestResponseProjectMapping')}
                modeModify={modeModifyModifyDataGatewayProvider}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.customerSupportGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataCustomerSupportConfig}
                type="CUSTOMER_SUPPORT_GATEWAY"
                getTenantURLMapping={getTenantCustomerSupportGateway}
                getGatewayData={getGatewayData}
                gatewayKey="customerSupportGatewayAttributes"
                tOptionsConfig="selections:customerSupportGatewayUrlType"
                titleForm={t('label.customerSupportGatewayURLMapping')}
                title={t('label.requestResponseCustomerSupportMapping')}
                modeModify={modeModifyModifyDataGatewayProvider}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.documentGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataDocumentConfig}
                type="DOCUMENT_GATEWAY"
                getTenantURLMapping={getTenantDocumentGateway}
                getGatewayData={getGatewayData}
                gatewayKey="documentGatewayAttributes"
                tOptionsConfig="selections:documentGatewayUrlType"
                titleForm={t('label.documentGatewayURLMapping')}
                title={t('label.requestResponseDocumentMapping')}
                modeModify={modeModifyModifyDataGatewayProvider}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.operationGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataOperationConfig}
                type="OPERATION_GATEWAY"
                getTenantURLMapping={getTenantOperationGateway}
                getGatewayData={getGatewayData}
                gatewayKey="operationGatewayAttributes"
                tOptionsConfig="selections:operationGatewayUrlType"
                titleForm={t('label.operationGatewayURLMapping')}
                title={t('label.requestResponseOperationMapping')}
                modeModify={modeModifyModifyDataGatewayProvider}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantCrmGateway.path}`}
            component={() => (
              <GatewayNewForm
                onChangeAttributes={this.onChangeAttributes}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantMerchantDataCRMConfig}
                type="CRM_GATEWAY"
                getTenantURLMapping={getTenantCRMGateway}
                getGatewayData={getGatewayData}
                gatewayKey="crmGatewayAttributes"
                tOptionsConfig="selections:crmGatewayUrlType"
                titleForm={t('label.crmGatewayURLMapping')}
                title={t('label.requestResponseCRMMapping')}
                modeModify={modeModifyModifyCrmGatewayProvider}
              />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantTask.path}`}
            component={() => (
              <div className="card card-statistics">
                <div className="gateway-form">
                  <div className="col-md-12 p-2 card-body">
                    <SearchTaskList permissions={permissionsTasks} isFromCustomer isTenant />
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`${RouteName.tenantSchedulePatternConfig.path}`}
            component={() => <SchedulePatternConfig />}
          />
        </Switch>
        <Switch>
          <Route exact path={RouteName.idSequenceConfig.path} component={() => <IdSequenceConfig />} />
        </Switch>
        <Switch>
          <Route
            exact
            path={RouteName.tenantTemplates.path}
            component={() => <Templates modeModifyPropertyDefaults={modeModifyPropertyDefaults} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={RouteName.folioResponseFile.path}
            component={() => <FolioResponseFile modeModifyPropertyDefaults={modeModifyPropertyDefaults} />}
          />
        </Switch>
      </div>
    );
  }
}

TenantDetail.propTypes = {
  modifyTenant: PropTypes.func,
  // tenantData: PropTypes.objectOf(PropTypes.any),
  permissionsTenant: PropTypes.objectOf(PropTypes.any),
  ccpProperties: PropTypes.objectOf(PropTypes.any),
};

TenantDetail.defaultProps = {
  modifyTenant: () => {},
  // tenantData: {},
  permissionsTenant: {},
  ccpProperties: {},
};

const mapStateToProps = createStructuredSelector({
  // tenantData: makeTenantData() || {},
  permissionsTenant: makeGetPermissionsTenantManagement() || {},
  ccpProperties: makeGetCcpProperties() || {},
  s3Config: makeGetS3Config() || {},
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},

  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    modifyTenant,
    getCcpProperties,
    modifyCcpProperties,
    // getPaymentMethodUrls,
    modifyTenantSelfCareConfig,
    modifyPaymentMethodUrls,
    getTenantSelfCareConfig,
    saveSelfcareConfig,
    getS3Buctket,
    readFileFromS3,
    uploadMultiPartFiles,
    getAwsS3Credential,
    getTenantProfileConfig,
    getTenantMerchantDataProjectConfig,
    getTenantMerchantDataPaymentConfig,
    getTenantMerchantDataTaxConfig,
    getTenantMerchantDataFinanceConfig,
    getTenantMerchantDataProvisioningConfig,
    getTenantMerchantDataCustomerSupportConfig,
    getTenantMerchantDataDocumentConfig,
    getTenantMerchantDataOperationConfig,
    getTenantMerchantDataCRMConfig,
    getTenantProjectGateway,
    getTenantCustomerSupportGateway,
    getTenantDocumentGateway,
    getTenantOperationGateway,
    getTenantCRMGateway,
    getGatewayData,
    getTenantProvisionGateway,
    getTenantFinanceGateway,
    getTenantTaxGateway,
    getTenantPaymentGateway,
  })(TenantDetail)
);
