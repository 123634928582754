import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalViewNote from '../ModalViewNote';

export const messageNotes = t => <div dangerouslySetInnerHTML={{ __html: t('message.quickNotesConfigAllPages') }} />;

const QuickNotesConfig = ({ messageNote }) => {
  const [isOpen, setIsOpen] = useState('');
  const { t } = useTranslation('common');
  return (
    <button type="button" className="button button-border gray x-small ml-2" onClick={() => setIsOpen(!isOpen)}>
      {t('label.quickNotes')}
      <i className="fa fa-external-link ml-2" aria-hidden="true" />
      <ModalViewNote isOpen={isOpen} message={messageNote || messageNotes(t)} onToggle={() => setIsOpen(!isOpen)} />
    </button>
  );
};

QuickNotesConfig.propTypes = {};

QuickNotesConfig.defaultProps = {};

export default QuickNotesConfig;
