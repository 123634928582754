import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormWithTableItem } from '../../common';

const Tasks = ({ id, children, currencyOptions, customerInfo }) => {
  const { t } = useTranslation('common');
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  return (
    <div className="col-md-12 mb-5">
      <FormWithTableItem
        title={t('taskPage:sidebar.tasks')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
      >
        {children}
      </FormWithTableItem>
    </div>
  );
};

Tasks.propTypes = {};

Tasks.defaultProps = {};

export default Tasks;
