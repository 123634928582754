import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../PricingHub/SearchComponent';

class TrunkRulesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.trunkGroup',
      name: 'trunkGroup',
    },
    {
      label: 'label.site',
      name: 'site',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.carrierId',
      name: 'carrierId',
    },
  ];

  btnSpan = 'col-md-12';
}

TrunkRulesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

TrunkRulesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(TrunkRulesSearchForm);
