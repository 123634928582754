import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['status', 'chargeType', 'isParent'])}` : '';
  const searchProductFamilyConfig = `searchProductFamilyConfig(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchProductFamilyConfig} {
        id
        index
        productCompany
        productFamily
        productLine
        productType
        productSubType
      }
   }
  `;
};
