import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../common';

const searchInputs = [
  {
    label: 'common:label.name',
    name: 'name',
  },
  {
    label: 'common:label.productFamily',
    name: 'productFamily',
    type: 'select',
    options: [],
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    type: 'select',
    options: [],
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    type: 'select',
    options: [],
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    type: 'select',
    options: [],
  },
  {
    label: 'common:label.currency',
    name: 'currency',
  },
  {
    label: 'common:label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:milestoneStatus',
  },
  {
    label: 'common:label.serviceType',
    name: 'type',
    type: 'select',
    tOptions: 'selections:serviceType',
  },
];

class MilestoneSearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalName: '',
    };
  }

  componentDidMount() {
    const { defaultFilter } = this.props;
    if (defaultFilter && Object.keys(defaultFilter).length > 0) {
      searchInputs.map(input => this.setState({ [input.name]: defaultFilter[input.name] || null }));
    } else {
      this.setDefaultInputs();
    }
  }

  setDefaultInputs = () => {
    searchInputs.map(input => this.setState({ [input.name]: null }));
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit } = this.props;
    const data = {};
    searchInputs.map(input => (this.state[input.name] ? (data[input.name] = this.state[input.name]) : null));
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    // const { onSubmit } = this.props;
    this.setDefaultInputs();
    // if (onSubmit) {
    //   onSubmit({});
    // }
  };

  onSelect = (value, row) => {
    const { modalName } = this.state;
    this.setState({ [modalName]: value, modalName: '' });
  };

  onOpenModal = ({ modalName }) => {
    this.setState({ modalName });
  };

  onToggleModal = () => {
    this.setState({ modalName: '' });
  };

  render() {
    const {
      isSubmitting,
      t,
      ModalSearch,
      productFamilyConfig,
      productLineConfig,
      productSubTypeConfig,
      productTypeConfig,
    } = this.props;
    if (productFamilyConfig && productFamilyConfig.length) {
      searchInputs[1].options = productFamilyConfig;
    }

    if (productLineConfig && productLineConfig.length) {
      searchInputs[2].options = productLineConfig;
    }

    if (productSubTypeConfig && productSubTypeConfig.length) {
      searchInputs[4].options = productSubTypeConfig;
    }

    if (productTypeConfig && productTypeConfig.length) {
      searchInputs[3].options = productTypeConfig;
    }

    const { modalName } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          {searchInputs.map(item => (
            <GenericInput
              key={item.name}
              {...item}
              label={t && t(item.label) ? t(item.label) : item.label}
              value={this.state[item.name]}
              onChange={this.handleInputChange}
              isNeedClearable
              onClick={item.fa ? () => this.onOpenModal({ modalName: item.name }) : () => {}}
            />
          ))}
          {ModalSearch && (
            <ModalSearch isOpen={modalName} onToggle={this.onToggleModal} onSelect={this.onSelect} data={this.state} />
          )}
          <div className="form-group text-right btn-search-actions col-md-12">
            <label htmlFor="validationCustom01se" />
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t('label.clear')}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t('label.search')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

MilestoneSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

MilestoneSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(MilestoneSearchForm);
