import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  const generatePaymentInstallmentReport = `generatePaymentInstallmentReport(input: ${dataCreate})`;
  return gql`
  mutation {
    ${generatePaymentInstallmentReport} {
      filePath
    }
  }
`;
};
