import { groupBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import { add3Dots } from '../../utils/utils';

const statusList = ['PENDING', 'ACTIVE', 'ASSIGNED', 'CLOSED'];

const onDragEnd = (result, columns, onSubmit) => {
  if (!result.destination) return;
  const { source, destination, draggableId } = result;
  if (onSubmit && destination.droppableId !== source.droppableId) {
    onSubmit({ dataEdit: { id: draggableId, status: destination.droppableId } });
  }

  // if (source.droppableId !== destination.droppableId) {
  //   const sourceColumn = columns[source.droppableId];
  //   const destColumn = columns[destination.droppableId];
  //   const sourceItems = [...sourceColumn.items];
  //   const destItems = [...destColumn.items];
  //   const [removed] = sourceItems.splice(source.index, 1);
  //   destItems.splice(destination.index, 0, removed);
  //   setColumns({
  //     ...columns,
  //     [source.droppableId]: {
  //       ...sourceColumn,
  //       items: sourceItems,
  //     },
  //     [destination.droppableId]: {
  //       ...destColumn,
  //       items: destItems,
  //     },
  //   });
  // } else {
  //   const column = columns[source.droppableId];
  //   const copiedItems = [...column.items];
  //   const [removed] = copiedItems.splice(source.index, 1);
  //   copiedItems.splice(destination.index, 0, removed);
  //   setColumns({
  //     ...columns,
  //     [source.droppableId]: {
  //       ...column,
  //       items: copiedItems,
  //     },
  //   });
  // }
};

function DragDropTableByColumns({ exportColumns, onView, onHandleSave, tableColumns, onSubmit }) {
  const { t } = useTranslation('common');

  const [columns, setColumns] = useState({});
  useEffect(() => {
    if (exportColumns && exportColumns.length) {
      const groupItem = groupBy(exportColumns, 'status');
      const listItem = {};
      statusList.forEach(val => {
        if (!groupItem[val]) listItem[val] = [];
        if (groupItem[val]) listItem[val] = groupItem[val];
      });
      setColumns(listItem);
    }
  }, [exportColumns]);

  return (
    <div className="col-md-12 drag-drop-group">
      <div className='d-flex dnd-group-comuns'>
        <DragDropContext onDragEnd={result => onDragEnd(result, columns, onSubmit)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
                className="body-columns"
              >
                <div className="title-form">{t(`label.${columnId.toLowerCase()}`)}</div>

                <div className="body-export-drd">
                  <Droppable droppableId={columnId} key={`${columnId}-${index}`}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={`dnd-table-container ${
                            snapshot.isDraggingOver ? 'drag-container' : 'drop-container'
                          }`}
                        >
                          {column.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      className={`item ${snapshot.isDragging ? 'item-drag' : 'item-drop'}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: 'none',
                                        padding: 10,
                                        margin: '0 0 8px 0',
                                        minHeight: '30px',
                                        // backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                        // color: 'white',
                                        opacity: 0.8,
                                        // fontWeight: 'bold',
                                        ...provided.draggableProps.style,
                                      }}
                                      onClick={() => onView(item.id)}
                                      aria-hidden="true"
                                    >
                                      {tableColumns &&
                                        tableColumns.length > 0 &&
                                        tableColumns.map(val => (
                                          <div className="d-flex group-item" key={val.name}>
                                            <div className="label-item">{`${t(val.label)}: `}</div>
                                            {val.name === 'taskDescription' &&
                                            item.taskDescription &&
                                            item.taskDescription.length > 80 ? (
                                              <Tooltip
                                                placement="bottom"
                                                overlay={item[val.name] || ''}
                                                overlayClassName="tooltip-details"
                                                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                                              >
                                                <div className="content-item">{add3Dots(item[val.name], 80)}</div>
                                              </Tooltip>
                                            ) : (
                                              <div className="content-item">
                                                {val.render
                                                  ? val.render(val.name, item, val.isRenderT ? t : index)
                                                  : item[val.name] || ''}
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
}

export default DragDropTableByColumns;
