import React, { useState, useRef, useEffect } from 'react';
import PropsType from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import RouteName from '../../App/RouteNames';
import { createGroupsReason } from '../actions';
import { GenericInput, TitleFrom, Breadcrumb, DataTable } from '../../../components/common';
import ModalReasonCodeConfig from '../../../components/common/ModalReasonCodeConfig';
import ModalUserGroup from '../../../components/common/ModalUserGroup';
import { checkPermissionCreateConfigGroupReasonCode } from '../CheckPermission';

const GroupsReasonCreate = ({ createGroupsReason, history, permissionsArOps }) => {
  const { t } = useTranslation('common');
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalUserGroup, setIsOpenModalUserGroup] = useState(false);
  const [data, setData] = useState({ groupId: null, groupName: null, reasonCodes: [] });
  const [wasValidated, setValidate] = useState(false);
  const formRef = useRef();

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  let modeCreateConfigGroupReasonCode = 0;
  if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
    const listPermission = permissionsArOps.arOpsModulePermissions;
    modeCreateConfigGroupReasonCode = checkPermissionCreateConfigGroupReasonCode({ listPermission });
  }

  if (!modeCreateConfigGroupReasonCode) return '';

  const onSelect = (id, row) => {
    const newData = cloneDeep(data);
    newData.reasonCodes[indexSelect].indexSelected = id;
    newData.reasonCodes[indexSelect].reasonCode = row && row.reasonCode ? row.reasonCode : '';
    newData.reasonCodes[indexSelect].customReasonCode = row && row.customReasonCode ? row.customReasonCode : '';
    newData.reasonCodes[indexSelect].description = row && row.description ? row.description : '';
    setData(newData);
    setIsOpen(false);
  };

  const onSelectGroupId = (id, row) => {
    const newData = cloneDeep(data);
    newData.groupId = id;
    newData.groupName = row && row.groupName ? row.groupName : '';
    setData(newData);
    setIsOpenModalUserGroup(false);
  };

  const onRemoveUser = index => {
    const newData = cloneDeep(data);
    newData.reasonCodes.splice(index, 1);
    setData(newData);
  };

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData.reasonCodes[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    setData({ groupId: null, groupName: null, reasonCodes: [] });
  };

  const headerTable = [
    {
      name: 'reasonCode',
      label: 'label.reasonCode',
      render: (colName, item, index) => (
        <GenericInput
          value={item.reasonCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="reasonCode"
          type="select"
          tOptions="selections:reasonConfig"
          menuPortalTarget
          isClearable
          disabled={!!item.customReasonCode}
        />
      ),
    },
    {
      name: 'customReasonCode',
      label: 'label.customReasonCode',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.customReasonCode}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="customReasonCode"
          readOnly
          disabled={!!item.reasonCode}
          fa="fa fa-external-link"
          onClick={() => {
            setIsOpen(true);
            setItemSelect(item);
            setIndexSelect(idx);
          }}
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="description"
          readOnly
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveUser({ index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const onHandleAddReason = () => {
    const newData = cloneDeep(data);
    newData.reasonCodes.push({
      index: '',
    });
    setData(newData);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    const newData = cloneDeep(data);
    const { groupName, ...rest } = newData;
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    rest.reasonCodes = rest.reasonCodes.map(val => {
      const { reasonCode, customReasonCode } = val;
      return { reasonCode, customReasonCode };
    });
    createGroupsReason({ groups: [rest] }, ({ success }) => {
      if (success) history.push(RouteName.arOperationsConfigGetReasonGroup.path);
    });
  };

  return (
    <div className="content-wrapper">
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            <NavLink className="button x-small" to={RouteName.arOperationsConfigGetReasonGroup.path}>
              {t('label.back')}
            </NavLink>
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                {
                  name: t('arOperationPage:sidebar.groupsReason'),
                  url: RouteName.arOperationsConfigGetReasonGroup.path,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <form
        ref={formRef}
        noValidate
        className={`col-md-12 p-2 card card-statistics needs-validation ${wasValidated ? 'was-validated' : ''}`}
        // onSubmit={onHandleSubmit}
        onSubmit={onSubmit}
      >
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.addNewReasonGroups')} />
          </div>
        </div>
        <div className="col-md-12 p-2 row">
          <GenericInput
            value={data.groupId}
            label="label.groupId"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            onClick={() => {
              setIsOpenModalUserGroup(true);
            }}
            readOnly
            name="groupId"
            fa="fa fa-external-link"
            required
          />
          <GenericInput
            value={data.groupName}
            label="label.groupName"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            name="groupName"
            readOnly
            // required
          />
        </div>
        <br />
        <div className="col-md-12">
          <DataTable columns={headerTable} data={data.reasonCodes || []} />
        </div>
        <div className="form-group col-md-12 buttons-attibute">
          <button type="button" className="button button-border black x-small" onClick={onHandleAddReason}>
            +
            {t('label.addReasonToGroup')}
          </button>
          {modeCreateConfigGroupReasonCode === 2 && (
            <button
              type="submit"
              className="button button-border x-small float-right"
              disabled={modeCreateConfigGroupReasonCode === 1}
            >
              {t('label.create')}
            </button>
          )}
          <button onClick={onCancel} type="button" className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </form>
      <ModalReasonCodeConfig
        isOpen={isOpen}
        onSelect={onSelect}
        onCancel={() => setIsOpen(false)}
        selectedParentId={itemSelect ? itemSelect.indexSelected : null}
      />
      <ModalUserGroup
        isOpen={isOpenModalUserGroup}
        onSelect={onSelectGroupId}
        onCancel={() => setIsOpenModalUserGroup(false)}
        selectedParentId={data ? data.groupId : null}
      />
    </div>
  );
};

GroupsReasonCreate.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

GroupsReasonCreate.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default connect(mapStateToProps, { createGroupsReason })(GroupsReasonCreate);
