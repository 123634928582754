import gql from 'graphql-tag';

export default id => {
  const getReratingBatchById = `getReratingBatchById(id: "${id}")`;
  return gql`
    query {
      ${getReratingBatchById} {
        index
        startDate
        accountId
        backoutOnly
        usageOnly
        organization
        clientId
        unbilledOnly
        rerateReasonCode
      }
    }
  `;
};
