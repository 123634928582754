import gql from 'graphql-tag';

export default () => gql`
  query {
    getInAdvanceBillingConfig(dummy: "" ) {
      id
      type
      cycles {
        index
        billingSegment
        daysInAdvance
        recurringFeeAdvanceDom
        accountType
        billingDom
        exceptions {
          index
           month
           moveDate
        }
      }
    }
  }
`;
