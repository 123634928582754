import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class ActiveSubscriptionSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.sellingCompany',
      name: 'sellingCompany',
    },
    {
      label: 'label.initialTermUnit',
      name: 'initialTermUnit',
      type: 'select',
      tOptions: 'selections:initialTermUnit',
      isClearable: true,
    },
    {
      label: 'label.renewalTermUnit',
      name: 'renewalTermUnit',
      type: 'select',
      tOptions: 'selections:initialTermUnit',
      isClearable: true,
    },
    {
      label: 'label.marketSegment',
      name: 'marketSegment',
      tOptions: 'selections:marketSegment',
      isClearable: true,
      type: 'select',
    },
    // {
    //   label: 'label.trialType',
    //   name: 'trialType',
    //   tOptions: 'selections:trialTypes',
    //   isClearable: true,
    //   type: 'select',
    // },
    // {
    //   label: 'label.status',
    //   name: 'status',
    //   tOptions: 'selections:subscriptionStatus',
    //   isClearable: true,
    //   type: 'select',
    // },
  ];

  btnSpan = 'col-md-9';
}

ActiveSubscriptionSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ActiveSubscriptionSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ActiveSubscriptionSearchForm);
