import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class ItemSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
    },
  ];

  btnSpan = 'col-md-6';
}

ItemSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ItemSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ItemSearchForm);
