import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class BillingARSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.monthYear',
      name: 'monthYear',
      type: 'monthYear',
    },
    {
      label: 'label.dueThreshold',
      name: 'dueThreshold',
      type: 'number',
    },
    {
      label: 'label.billType',
      name: 'billType',
      type: 'select',
      tOptions: 'selections:billUnitType',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:invoiceStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.organization',
      name: 'company',
    },
  ];

  btnSpan = 'col-md-6';
}

BillingARSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BillingARSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BillingARSearchForm);
