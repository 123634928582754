import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, uniqBy, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import AccountTable from '../AccountTable';
import { orderListRadioButtons, listFlexAttributes } from '../constants';
import { orderEnum } from '../../../constantsApp';
import {
  StepProgressBar,
  Breadcrumb,
  GenericInput,
  FormWithTableItem,
  DataTable,
  ModalWithItem,
} from '../../../components/common';
import { createOrder, getFlexAttributesMapping } from '../actions';
import { makeGetPermissionsOrderManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import RouteNames from '../../App/RouteNames';
import { searchServiceProvisioningData, getCcpPropertiesConfig } from '../../App/actions';
import { getUserId } from '../../../localStorages';
import { AttributesForm } from '../../../components/Orders';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';
import { getProvisioningDataReport } from '../../ReportManagement/actions';
import { validate } from '../../../utils/utils';
import { checkPermissionMoveProvisioningOrderType } from '../CheckPermission';

let lastStep = 0;

const subTypes = {
  MOVE_PROVISIONING: 'MOVE_DEVICE',
  CHANGE_PROVISIONING: 'CHANGE_DEVICE',
  MOVE_TO_STD_PROVISIONING: 'MOVE_TO_STD_PROVISIONING',
};

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.orderProvisioningData',
  },
  {
    step: 3,
    label: 'label.createOrder',
  },
];

class MoveProvisioning extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: { id: props.location.state ? props.location.state.accountId : '' },
      orderDetail: this.initOrderDetail(props),
      isOpenModal: false,
      isReadOnly: true,
      oldStageSelected: 0,
      customAttributesByObjectType: [],
      actionType: 'MOVE_PROVISIONING',
      provisioningData: [],
      serviceTypeOptions: [],
      isOpenMdalDetails: false,
      detailsData: [],
      wasValidate: false,
      mappingList: null,
      isProvisioningRequired: false,
    };
    this.services = [];
    this.initServiceUnits = {};
  }

  componentDidMount() {
    this.doGetCcpPropertiesConfig();
  }

  onToggle = () => {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
    }));
  };

  doGetCcpPropertiesConfig = () => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
    }
  };

  doInitPropertiesConfig = ccpPropertyList => {
    const provisioningEnabled = ccpPropertyList.find(val => val.property === 'provisioningEnabled');
    this.setState({
      isProvisioningRequired: provisioningEnabled && provisioningEnabled?.value === 'true',
    });
  };

  initOrderDetail = props => {
    return {
      type: orderEnum.type.relocation,
      status: orderEnum.status.create,
      reason: null,
      accountId: props.location.state ? props.location.state.accountId : null,
      userId: getUserId() || null,
      provisioningOnlyFlag: true,
      initialTerm: null,
      initialTermUnit: null,
      renewalTerm: null,
      renewalTermUnit: null,
      services: [],
    };
  };

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState({
        customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
      });
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState({
            customAttributesByObjectType: (data && data.attributes) || [],
          });
        }
      }, 'ORDER');
    }
  };

  onSelectNext = rowItem => {
    const { orderDetail } = this.state;
    orderDetail.accountId = rowItem.id;
    this.setState(
      {
        selectItem: rowItem,
        orderDetail,
        actionType: 'MOVE_PROVISIONING',
      },
      () => {
        this.onPressNextButton();
        this.doGetCustomAttributesByObjectType();
        this.doGetProvisioningDataReport();
      }
    );
  };

  selectAccount = () => {};

  onHandleChangeOrderForm = e => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    if (e.fieldName === 'billingOnlyFlag' && e.value) {
      newData.provisioningOnlyFlag = null;
    }
    if (e.fieldName === 'provisioningOnlyFlag' && e.value) {
      newData.billingOnlyFlag = null;
    }
    newData[e.fieldName] = e.value;

    this.setState({
      orderDetail: newData,
    });
  };

  onPressBackButton = () => {
    const { history } = this.props;
    history.push(RouteNames.searchAndList.path);
  };

  onPressCancelButton = () => {
    this.setState({
      stage: 0,
      oldStageSelected: 0,
    });
  };

  onPressNextButton = () => {
    const { selectItem, stage } = this.state;
    const { t } = this.props;
    this.setState({ wasValidate: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    if (selectItem.id) {
      return this.setState({ stage: stage + 1, oldStageSelected: stage + 1, wasValidate: false });
    }
  };

  onPressCreateButton = () => {
    const { orderDetail, provisioningData, actionType } = this.state;
    const newOrderDetail = cloneDeep(orderDetail);
    let newProvisioningData = cloneDeep(provisioningData);
    newProvisioningData =
      actionType === 'MOVE_PROVISIONING' ? newProvisioningData.filter(val => !!val.isSelected) : newProvisioningData;
    if (newProvisioningData && newProvisioningData.length) {
      newOrderDetail.services = newProvisioningData.map((val, index) => {
        const { serviceType, serviceTypeSelected, type, provisioningList } = val;
        const newProvisioningList = provisioningList ? provisioningList.map(pr => ({ dataType: type, ...pr })) : null;
        if (typeof serviceType === 'number') {
          return {
            serviceType: serviceTypeSelected || serviceType,
            action: 'ADD',
            provisioningList: newProvisioningList,
            index: index + 1,
          };
        }
        return { serviceType, provisioningList: newProvisioningList, action: 'ADD', index: index + 1 };
      });
    }
    const { docreateOrder } = this.props;
    docreateOrder({ ...newOrderDetail, subType: subTypes[actionType] || null }, ({ success, id }) => {
      const { location, history } = this.props;
      if (success) {
        const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;
        if (accountId) {
          history.push(`/customers/${accountId}/services`);
          return;
        }
        history.push(`/orders/${id}/detail`);
      }
    });
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  onHandleChangeActionType = ({ value }) => {
    this.setState({ actionType: value, provisioningData: [], serviceTypeOptions: [] }, () =>
      this.doGetProvisioningDataReport()
    );
  };

  doGetProvisioningDataReport = () => {
    const { getProvisioningDataReport, t } = this.props;
    const { orderDetail, actionType } = this.state;
    getProvisioningDataReport(
      { page: 1, size: 500, filter: { accountId: orderDetail.accountId, status: 'ACTIVE' } },
      ({ success, data }) => {
        let newData = cloneDeep(data);
        if (actionType === 'MOVE_PROVISIONING') {
          if (newData && newData.length) {
            newData = newData.map(val => {
              return { ...val, isSelected: val.serviceType === 'INTERNET' || newData.length === 1 };
            });
          }
          this.setState({ provisioningData: success ? newData || [] : [] });
        } else if (newData && newData.length) {
          const serviceTypeOptions = newData.map(val => {
            const { serviceType } = val;
            const slt = t('selections:serviceType')().find(val => val.value === serviceType);
            return { ...val, label: slt.label || serviceType, value: serviceType, provAttributeType: val.type };
          });
          this.setState({ serviceTypeOptions });
        } else if (!success || !data || !data.types || !data.types.length)
          toast.error(t('common:message.accountNotHaveProvisioning'));
      }
    );
  };

  onAddNew = () => {
    const { provisioningData } = this.state;
    let newData = cloneDeep(provisioningData);
    if (!newData) newData = [];
    newData.push({
      provisioningId: null,
      flexAttr1: null,
      type: null,
    });
    this.setState({ provisioningData: newData });
  };

  onHandleChangeTable = ({ name, value, index }) => {
    try {
      const { provisioningData, serviceTypeOptions } = this.state;
      let newData = cloneDeep(provisioningData);
      if (!newData) newData = [];
      newData[index][name] = value;
      if (name === 'serviceType') {
        const itemSelect = serviceTypeOptions.find(val => val.value === value && !!val.provAttributeType);
        if (itemSelect) {
          newData[index].type = itemSelect.provAttributeType;
          newData[index].serviceTypeSelected = itemSelect.serviceType;
        } else {
          newData[index].type = null;
        }
      }

      if (name === 'type') {
        const itemSelect = serviceTypeOptions.find(
          val => val.serviceType === newData[index].serviceTypeSelected && val.provAttributeType === value
        );
        if (itemSelect) {
          newData[index].serviceType = itemSelect.value;
        }
      }

      this.setState({ provisioningData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    try {
      const { provisioningData } = this.state;
      const newData = cloneDeep(provisioningData);
      newData.splice(index, 1);
      this.setState({ provisioningData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeTableItem = ({ name, value, index }) => {
    try {
      const { detailsData } = this.state;
      let newData = cloneDeep(detailsData);
      if (!newData) newData = [];
      newData[index][name] = value;
      this.setState({ detailsData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveTableItem = ({ index }) => {
    try {
      const { detailsData } = this.state;
      const newData = cloneDeep(detailsData);
      newData.splice(index, 1);
      this.setState({ detailsData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  doGetItemDetails = ({ item, index }) => {
    const { actionType, orderDetail, serviceTypeOptions } = this.state;
    const { searchServiceProvisioningData, getFlexAttributesMapping } = this.props;

    if (actionType === 'MOVE_PROVISIONING') {
      searchServiceProvisioningData(
        {
          page: 1,
          size: 200,
          filter: {
            provisioningId: item.provisioningId || null,
            serviceType: item.serviceType || null,
            accountId: orderDetail.accountId,
            type: item.type || null,
            status: 'ACTIVE',
          },
        },
        resp => {
          const newResp = resp;
          const flexAttrsList = {};
          if (
            newResp.success &&
            newResp.data &&
            newResp.data.types &&
            newResp.data.types.length &&
            newResp.data.types[0].flexAttrMapping
          ) {
            newResp.data.types[0].flexAttrMapping.forEach(val => {
              flexAttrsList[val.flexAttribute] = val.tag;
            });
          }
          if (newResp.data && newResp.data.provisioning && newResp.data.provisioning[0]) {
            newResp.data.provisioning[0] = { ...newResp.data.provisioning[0], ...flexAttrsList };
            this.setState({
              dataResponse: newResp.data.provisioning[0],
              detailsData: newResp.data.provisioning,
              isOpenMdalDetails: true,
            });
          } else {
            this.setState({ dataResponse: null, detailsData: [], isOpenMdalDetails: true });
          }
        }
      );
    } else {
      getFlexAttributesMapping({ objectType: 'SERVICE', subType: item.serviceType }, ({ success, data }) => {
        if (success) {
          if (data?.types && data?.types && data?.types[0].mapping) {
            const dataResponse = {};
            data.types[0].mapping.forEach(val => {
              dataResponse[val.flexAttribute] = val.tag;
            });

            this.setState({
              dataResponse: { ...dataResponse },
              detailsData: item.provisioningList || [],
              isOpenMdalDetails: true,
              indexSelect: index,
              mappingList: data.types[0].mapping,
            });
          } else {
            this.setState({
              detailsData: item.provisioningList || [],
              isOpenMdalDetails: true,
              indexSelect: index,
            });
          }
        }
      });
    }
  };

  onToggleModalDetails = () => {
    this.setState({ isOpenMdalDetails: false, detailsData: [], mappingList: null });
  };

  renderContent = () => {
    const {
      stage,
      selectItem,
      orderDetail,
      customAttributesByObjectType,
      actionType,
      provisioningData,
      serviceTypeOptions,
    } = this.state;
    const { t } = this.props;

    let tableColumns = [
      {
        name: 'serviceType',
        label: 'label.serviceType',
        required: true,
        render: (colName, item, index) => {
          const newServiceTypeOptions = serviceTypeOptions
            ? serviceTypeOptions.filter(val => val.value === item.serviceType && val.provAttributeType === item.type)
            : [];

          return (
            <GenericInput
              value={item.serviceType || null}
              name="serviceType"
              type="select"
              options={
                actionType === 'MOVE_PROVISIONING'
                  ? t('selections:serviceType')()
                  : sortBy(uniqBy([...newServiceTypeOptions, ...serviceTypeOptions] || [], 'serviceType'), 'label')
              }
              onChange={({ name, value }) => this.onHandleChangeTable({ index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              required
              disabled={actionType === 'MOVE_PROVISIONING'}
              readOnly={actionType === 'MOVE_PROVISIONING'}
            />
          );
        },
      },
      {
        name: 'type',
        label: 'label.type',
        render: (colName, item, index) => {
          let newOptionsType =
            serviceTypeOptions && serviceTypeOptions.length
              ? serviceTypeOptions.filter(
                  val => val.serviceType === item.serviceTypeSelected && !!val.provAttributeType
                )
              : [];
          if (newOptionsType && newOptionsType.length) {
            newOptionsType = newOptionsType.map(val => {
              const { provAttributeType, value, label, ...rest } = val;
              return { ...rest, value: provAttributeType, label: provAttributeType, index: value, serviceType: label };
            });
          }

          return (
            <GenericInput
              value={item.type || null}
              wrapperClass="col-md-12"
              menuPortalTarget
              type={actionType === 'MOVE_PROVISIONING' ? 'text' : 'select'}
              name="type"
              options={newOptionsType}
              onChange={({ name, value }) => this.onHandleChangeTable({ index, name, value })}
              disabled={actionType === 'MOVE_PROVISIONING' || (newOptionsType && newOptionsType.length === 1)}
              readOnly={actionType === 'MOVE_PROVISIONING' || (newOptionsType && newOptionsType.length === 1)}
            />
          );
        },
      },
      {
        name: 'view',
        label: 'label.view',
        render: (colName, item, index) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetItemDetails({ item, index })}
            disabled={!actionType || !item.serviceType || !item.type}
          >
            {t('label.view')}
          </button>
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                disabled={actionType === 'MOVE_PROVISIONING'}
                onClick={() => this.onRemoveItem({ index })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    if (actionType === 'MOVE_PROVISIONING')
      tableColumns = [
        {
          name: 'select',
          label: 'label.select',
          render: (colName, item, index) => (
            <GenericInput
              type="checkbox"
              name="isSelected"
              checked={item && item.isSelected}
              value={item && item.isSelected}
              onChange={({ name }) => this.onHandleChangeTable({ index, name, value: !item.isSelected })}
            />
          ),
        },
        ...tableColumns,
      ];

    switch (stage) {
      case 0:
        return (
          <AccountTable
            type="modify-order"
            subscriptionStatus="ACTIVE"
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isNoPageTitle
          />
        );

      case 1:
        return (
          <div className="col-md-12">
            <GenericInput
              value={actionType || null}
              onChange={({ value }) => this.onHandleChangeActionType({ value })}
              wrapperClass="col-md-3 m-auto"
              tOptions="selections:moveProvisioningTypeActions"
              type="select"
              label="label.type"
              isNoClearable
            />
            <FormWithTableItem title="label.moveProvisioning">
              <DataTable columns={tableColumns} data={provisioningData || []} />
              <div className="col-md-12 mb-3 ml-3">
                <button
                  disabled={actionType === 'MOVE_PROVISIONING' || !serviceTypeOptions || !serviceTypeOptions.length}
                  type="button"
                  className="button x-small"
                  onClick={this.onAddNew}
                >
                  +
                  {t('label.add')}
                </button>
              </div>
            </FormWithTableItem>
            <FormWithTableItem title="label.customerAttributes">
              <div className="col-md-12 p-0">
                <AttributesForm
                  customAttributesByObjectType={customAttributesByObjectType}
                  onChange={this.onHandleChangeOrderAttribute}
                />
              </div>
            </FormWithTableItem>
          </div>
        );

      case 2:
        return (
          <>
            <div className="col-md-12 row justify-content-center mt-3 mb-2">
              {orderListRadioButtons.map(item => (
                <GenericInput
                  key={item.name}
                  value={orderDetail[item.name] || null}
                  onChange={({ name, value }) => this.onHandleChangeOrderForm({ fieldName: name, value })}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  {...item}
                  disabled
                />
              ))}
            </div>
          </>
        );

      default:
        return <AccountTable onClickRow={this.onClickRow} selectItem={selectItem} onSelectNext={this.onSelectNext} />;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    const { t } = this.props;
    this.setState({ wasValidate: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    if (step < stage + 1 || step <= oldStageSelected + 1) {
      this.setState({ stage: step - 1, wasValidate: false });
    }
  };

  onChangeModifyService = () => {
    const { isReadOnly } = this.state;
    this.setState({ isReadOnly: !isReadOnly });
  };

  onAddNewItem = () => {
    const { detailsData } = this.state;
    let newData = cloneDeep(detailsData);
    if (!newData) newData = [];
    newData.push({
      provisioningId: null,
    });
    this.setState({ detailsData: newData });
  };

  onSave = () => {
    const { indexSelect, provisioningData, detailsData } = this.state;
    const { t } = this.props;
    this.setState({ wasValidate: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    const newData = cloneDeep(provisioningData);
    newData[indexSelect].provisioningList = detailsData || null;
    this.setState({
      provisioningData: newData,
      isOpenMdalDetails: false,
      indexSelect: null,
      detailsData: null,
      wasValidate: false,
    });
  };

  render() {
    const {
      stage,
      oldStageSelected,
      isOpenMdalDetails,
      dataResponse,
      actionType,
      detailsData,
      wasValidate,
      mappingList,
      provisioningData,
      isProvisioningRequired,
      serviceTypeOptions,
    } = this.state;
    const { t } = this.props;
    const {
      location: { state },
      permissionOrder,
    } = this.props;

    lastStep = 2;
    let modeMoveProvisioningOrderType = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeMoveProvisioningOrderType = checkPermissionMoveProvisioningOrderType({ listPermission });
    }

    if (!modeMoveProvisioningOrderType) return null;

    const provTableColumns = [
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                disabled={actionType === 'MOVE_PROVISIONING'}
                onClick={() => this.onRemoveTableItem({ index })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        name: 'provisioningId',
        label: 'label.provisioningId',
        required: true,
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.provisioningId || null}
            name="provisioningId"
            onChange={({ name, value }) => this.onHandleChangeTableItem({ index, name, value })}
            wrapperClass="col-md-12"
            disabled={actionType === 'MOVE_PROVISIONING'}
            readOnly={actionType === 'MOVE_PROVISIONING'}
            required
          />
        ),
      },
    ];
    if (dataResponse) {
      listFlexAttributes.forEach(att => {
        if (dataResponse[att.label]) {
          const itemMapping = mappingList ? mappingList.find(val => val.tag === dataResponse[att.label]) : null;
          if (!isProvisioningRequired || actionType === 'MOVE_PROVISIONING') {
            provTableColumns.push({
              name: att.name,
              label: dataResponse[att.label],
              style: { minWidth: '200px' },
              required: itemMapping && itemMapping.mandatoryFlag === 'Y',
              render: (colName, item, index) => {
                return (
                  <GenericInput
                    value={item[att.name] || null}
                    name={att.name}
                    onChange={({ name, value }) => this.onHandleChangeTableItem({ index, name, value })}
                    wrapperClass="col-md-12"
                    disabled={actionType === 'MOVE_PROVISIONING'}
                    readOnly={actionType === 'MOVE_PROVISIONING'}
                    required={itemMapping && itemMapping.mandatoryFlag === 'Y'}
                  />
                );
              },
            });
          } else if (itemMapping && itemMapping.mandatoryFlag === 'Y')
            provTableColumns.push({
              name: att.name,
              label: dataResponse[att.label],
              style: { minWidth: '200px' },
              required: itemMapping && itemMapping.mandatoryFlag === 'Y',
              render: (colName, item, index) => {
                return (
                  <GenericInput
                    value={item[att.name] || null}
                    name={att.name}
                    onChange={({ name, value }) => this.onHandleChangeTableItem({ index, name, value })}
                    wrapperClass="col-md-12"
                    disabled={actionType === 'MOVE_PROVISIONING'}
                    readOnly={actionType === 'MOVE_PROVISIONING'}
                    required={itemMapping && itemMapping.mandatoryFlag === 'Y'}
                  />
                );
              },
            });
        }
      });
    }

    return (
      <div className="mb-4">
        <div className="col-md-12 row p-0">
          <div className="col-sm-6">
            {!state && (
              <button type="button" className="button x-small" onClick={() => this.onPressBackButton(true)}>
                {t('label.back')}
              </button>
            )}
            {state && state.backLink && (
              <Link to={state.backLink} type="button" className="button x-small">
                {t('label.back')}
              </Link>
            )}
          </div>
          <div className="col-sm-6 pr-0">
            <Breadcrumb
              items={[
                {
                  name: t('common:label.moveProvisioningOrder'),
                  url: RouteNames.modifyOrder.path,
                },
              ]}
            />
          </div>
        </div>

        <div className="col-md-12 row text-center pb-3 mb-2">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              listSteps={listSteps}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              oldSelected={oldStageSelected}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3 p-m">
            <button
              type="button"
              className="button button-border x-small gray box-shadow-btn"
              onClick={this.onPressCancelButton}
            >
              {t('label.cancel')}
            </button>
            {stage < lastStep ? (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressNextButton}
                disabled={
                  modeMoveProvisioningOrderType !== 2 ||
                  (stage === 1 &&
                    (!provisioningData ||
                      provisioningData.length === 0 ||
                      provisioningData.find(val => !val.serviceType)))
                }
              >
                {t('label.next')}
              </button>
            ) : (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressCreateButton}
                disabled={modeMoveProvisioningOrderType !== 2}
              >
                {t('label.create')}
              </button>
            )}
          </div>
        </div>
        <form noValidate ref={this.formRef} className={`needs-validation ${wasValidate ? 'was-validated' : ''}`}>
          {this.renderContent()}
        </form>
        <ModalWithItem
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenMdalDetails}
          onToggle={this.onToggleModalDetails}
          modalTitle={t('label.viewProvisioningData')}
        >
          <form noValidate ref={this.formRef} className={`needs-validation ${wasValidate ? 'was-validated' : ''}`}>
            <DataTable columns={provTableColumns} data={detailsData || []} />
            <div className="col-md-12 pt-3 pb-3">
              <button
                disabled={
                  actionType === 'MOVE_PROVISIONING' ||
                  modeMoveProvisioningOrderType !== 2 ||
                  !serviceTypeOptions ||
                  !serviceTypeOptions.length
                }
                type="button"
                onClick={this.onAddNewItem}
                className="button x-small"
              >
                +
                {t('label.add')}
              </button>
              <button
                disabled={actionType === 'MOVE_PROVISIONING'}
                type="button"
                className="button button-border x-small float-right"
                onClick={this.onSave}
              >
                {t('label.submit')}
              </button>
              <button
                type="button"
                onClick={this.onToggleModalDetails}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </ModalWithItem>
      </div>
    );
  }
}

MoveProvisioning.propTypes = {
  docreateOrder: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
};

MoveProvisioning.defaultProps = {
  location: {},
  permissionOrder: {},
};

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

const MoveProvisioningT = connect(mapStateToProps, {
  docreateOrder: createOrder,
  getCustomAttributesByObjectType,
  getProvisioningDataReport,
  getFlexAttributesMapping,
  searchServiceProvisioningData,
  getCcpPropertiesConfig,
})(MoveProvisioning);

export default withTranslation('common')(MoveProvisioningT);
