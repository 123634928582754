import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { searchConfigCreditProfile } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
// import SearchForm from './SearchForm';

class ModalSelectCreditProfile extends PureComponent {
  getCreditProfileIndex = (payload, cb) => {
    const { filter, ...rest } = payload;
    const { searchConfigCreditProfile, isOpen } = this.props;
    if (isOpen)
      searchConfigCreditProfile(rest, ({ success, data }) => {
        if (cb)
          cb({
            success,
            data: success && data ? data : [],
          });
      });
  };

  render() {
    const creditProfileColumns = [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'name',
        label: 'common:label.name',
      },
      {
        name: 'customerSegment',
        label: 'common:label.customerSegment',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountSubType',
        label: 'common:label.accountSubType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ];

    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseCreditProfile')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.getCreditProfileIndex}
        tableColumns={creditProfileColumns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName="name"
      />
    );
  }
}

ModalSelectCreditProfile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchConfigCreditProfile: PropTypes.func,
};

ModalSelectCreditProfile.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  searchConfigCreditProfile: () => {},
  selectedParentId: '',
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, { searchConfigCreditProfile })(ModalSelectCreditProfile)
);
