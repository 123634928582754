import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { EmployeeCostingPNLSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTableGroupHeader,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
} from '../../../../components/common';
import { sortemployeeCostingPNL, tabelColumnExportemployeeCostingPNLExportCSV } from '../../constants';
import { employeeCostingPNLReport, employeeAllCostingPNLReport } from '../../actions';
import { makeGetEmployeeCostingPNLt, makeGetAllEmployeeCostingPNL } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelemployeeCostingPNL';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfemployeeCostingPNL';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let monthOne = '';
let monthTwo = '';
let monthThree = '';
let quarter = '';
class EmployeeCostingPNL extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listEmployeeCostingPNL: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listEmployeeCostingPNL: props.listEmployeeCostingPNL,
      totalCount: getPageTotalCount({ ...state, items: props.listEmployeeCostingPNL }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetEmployeeCostingPnl },
    } = this.props;
    if (modeGetEmployeeCostingPnl) {
      this.doGetListEmployeeCostingPNL();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListEmployeeCostingPNL();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListEmployeeCostingPNL());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListEmployeeCostingPNL());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListEmployeeCostingPNL();
    });
  };

  doGetListEmployeeCostingPNL = () => {
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (!newFilter.quarter) newFilter.quarter = '1';
    if (!newFilter.year) newFilter.year = moment().format('YYYY');
    const { employeeCostingPNLReport } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort:
        !isEmpty(sorted) && sortemployeeCostingPNL[sorted.sortCol]
          ? sortemployeeCostingPNL[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    employeeCostingPNLReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistEmployeeCostingPNL = cb => {
    const { filter, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (!newFilter.quarter) newFilter.quarter = '1';
    if (!newFilter.year) newFilter.year = moment().format('YYYY');
    const { employeeAllCostingPNLReport } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortemployeeCostingPNL[sorted.sortCol][sorted.sortDir] : null,
    };
    employeeAllCostingPNLReport(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistEmployeeCostingPNL(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllEmployeeCostingPNL, t } = this.props;
        convertJson2Pdf({
          data: listAllEmployeeCostingPNL,
          t,
          title: t('reportPage:sidebar.timeAndExpensePL').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.timeAndExpensePL')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          monthOne,
          monthTwo,
          monthThree,
          quarter,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = (dataExportCSV, { subject, emailBody }) => {
    this.doGetAlllistEmployeeCostingPNL(() => {
      const { listAllEmployeeCostingPNL, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'EMPLOYEE_COSTING_PNL_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.timeAndExpensePL')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.timeAndExpensePL')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: [dataExportCSV, ...listAllEmployeeCostingPNL],
            t,
            columns: tabelColumnExportemployeeCostingPNLExportCSV,
            listOptionFields: { status: 'status' },
            replaceHeaderList: [
              'monthOneGrossSal',
              'monthThreeGrossSal',
              'totalGrossSal',
              'monthOneActualCost',
              'monthThreeActualacost',
              'totalActualCost',
              'monthOneRevenueUsd',
              'monthThreeRevenueUsd',
              'totalRevenueUsd',
              'monthOneRevenueMxn',
              'monthThreeRevenueMxn',
              'totalRevenueMxn',
              'monthOneActualIncomeMxn',
              'monthThreeActualIncomeMxn',
              'totalActualIncome',
              'monthOneSalaryToBilling',
              'monthThreeSalaryToBilling',
              'averageSalaryToBilling',
              t('label.employeeId'),
              t('label.employeeName'),
              t('label.jobTitle'),
              t('label.status'),
              t('label.clientId'),
              t('label.clientName'),
              t('label.clientJobTitle'),
            ],
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllEmployeeCostingPNL,
          t,
          title: t('reportPage:sidebar.timeAndExpensePL').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.timeAndExpensePL')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          monthOne,
          monthTwo,
          monthThree,
          quarter,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.timeAndExpensePL')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listEmployeeCostingPNL,
      filter,
      emails,
    } = this.state;
    const {
      listAllEmployeeCostingPNL,
      t,
      permissions: { modeGetEmployeeCostingPnl, modeDownloadReport, modeGetMailReport },
      isBilling,
    } = this.props;

    if (!modeGetEmployeeCostingPnl) return '';

    if (listEmployeeCostingPNL && listEmployeeCostingPNL.length) {
      const monthYearT = t('selections:monthOfYear')();
      monthOne = listEmployeeCostingPNL[0].monthOne || '';
      monthTwo = listEmployeeCostingPNL[0].monthTwo || '';
      monthThree = listEmployeeCostingPNL[0].monthThree || '';
      if (monthOne) {
        const itemTranslation = monthYearT.find(it => monthOne.toLocaleLowerCase() === it.value.toLocaleLowerCase());
        monthOne = itemTranslation?.label || '';
      }
      if (monthTwo) {
        const itemTranslation = monthYearT.find(it => monthTwo.toLocaleLowerCase() === it.value.toLocaleLowerCase());
        monthTwo = itemTranslation?.label || '';
      }
      if (monthThree) {
        const itemTranslation = monthYearT.find(it => monthThree.toLocaleLowerCase() === it.value.toLocaleLowerCase());
        monthThree = itemTranslation?.label || '';
      }
    }
    quarter = `Q${filter.quarter ? filter.quarter : 1}`;
    const tabelColumnExportemployeeCostingPNL = [
      {
        name: 'employeeId',
        label: 'label.employeeId',
      },
      {
        name: 'employeeName',
        label: 'label.employeeName',
        sortable: true,
      },
      {
        name: 'jobTitle',
        label: 'label.jobTitle',
        sortable: true,
      },
      {
        name: 'status',
        label: 'label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'clientId',
        label: 'label.clientId',
      },
      {
        name: 'clientName',
        label: 'label.clientName',
      },
      {
        name: 'clientJobTitle',
        label: 'label.clientJobTitle',
      },
      {
        name: 'monthOneGrossSal',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoGrossSal',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeGrossSal',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalGrossSal',
        label: quarter,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneActualCost',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoActualCost',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeActualacost',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalActualCost',
        label: quarter,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneRevenueUsd',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoRevenueUsd',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeRevenueUsd',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalRevenueUsd',
        label: quarter,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneRevenueMxn',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoRevenueMxn',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeRevenueMxn',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalRevenueMxn',
        label: quarter,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneActualIncomeMxn',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoActualIncomeMxn',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeActualIncomeMxn',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'totalActualIncome',
        label: quarter,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthOneSalaryToBilling',
        label: monthOne || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthTwoSalaryToBilling',
        label: monthTwo || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'monthThreeSalaryToBilling',
        label: monthThree || '',
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
      {
        name: 'averageSalaryToBilling',
        label: quarter,
        style: { minWidth: '80px', textAlign: 'center' },
        rowClassItem: 'text-center',
      },
    ];

    const dataExportCSV = { isNoEnum: true };
    tabelColumnExportemployeeCostingPNL.forEach(dt => {
      dataExportCSV[dt.name] = t(`${dt.label}`);
    });

    const subHeader = [
      {
        label: '',
        colSpan: 1,
        name: 'employeeId',
      },
      {
        label: '',
        colSpan: 1,
        name: 'employeeName',
      },
      {
        label: '',
        colSpan: 1,
        name: 'jobTitle',
      },
      {
        label: '',
        colSpan: 1,
        name: 'status',
      },
      {
        label: '',
        colSpan: 1,
        name: 'clientId',
      },
      {
        label: '',
        colSpan: 1,
        name: 'clientName',
      },
      {
        label: '',
        colSpan: 1,
        name: 'clientJobTitle',
      },
      {
        label: 'label.grossSalaryMxn',
        colSpan: 4,
        name: 'label.grossSalaryMxn',
      },

      {
        label: 'label.actualCostMxn',
        colSpan: 4,
        name: 'Actual Cost Mxn (Gross salary* 1.2865)',
      },

      {
        label: 'label.revenueUSD',
        colSpan: 4,
        name: 'Revenue USD',
      },

      {
        label: 'label.revenueMxn',
        colSpan: 4,
        name: 'Revenue Mxn',
      },

      {
        label: 'label.actIncomeMxn',
        colSpan: 4,
        name: 'Act Income Mxn (Rev Mxn - Act Cost Mxn)',
      },

      {
        label: 'label.salaryToBilling',
        colSpan: 4,
        name: 'Salary To Billing',
      },
    ];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={
            isBilling
              ? [
                  { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
                  { name: t('common:label.PLReport') },
                ]
              : [
                  { name: t('label.report'), url: RouteNames.report.path },
                  { name: t('reportPage:sidebar.timeAndExpensePL') },
                ]
          }
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.timeAndExpensePL')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <EmployeeCostingPNLSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <div className="mt-3 row float-right mb-4 pt-3 pb-3">
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.timeAndExpense').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllEmployeeCostingPNL,
                          t,
                          monthOne,
                          monthTwo,
                          monthThree,
                          quarter,
                          title: t('reportPage:sidebar.timeAndExpensePL').toLocaleUpperCase(),
                        })}
                        fileName={`${t('reportPage:sidebar.timeAndExpensePL')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={e => this.sendEmail(dataExportCSV, e)}
                      data={listEmployeeCostingPNL}
                    />
                  )}
                </div>
                <DataTableGroupHeader
                  columns={tabelColumnExportemployeeCostingPNL}
                  data={listEmployeeCostingPNL && listEmployeeCostingPNL.length ? listEmployeeCostingPNL : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  // isFixedHeaderTable
                  subHeader={subHeader}
                  // rowClass="text-center"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

EmployeeCostingPNL.propTypes = {
  getemployeeCostingPNL: PropTypes.func,
  employeeAllCostingPNLReport: PropTypes.func,
  listAllEmployeeCostingPNL: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

EmployeeCostingPNL.defaultProps = {
  getemployeeCostingPNL: () => {},
  employeeAllCostingPNLReport: () => {},
  listAllEmployeeCostingPNL: [],
};

const mapStateToProps = createStructuredSelector({
  listEmployeeCostingPNL: makeGetEmployeeCostingPNLt() || [],
  listAllEmployeeCostingPNL: makeGetAllEmployeeCostingPNL() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, { employeeCostingPNLReport, employeeAllCostingPNLReport, uploadMultiPartFiles })(
    EmployeeCostingPNL
  )
);
