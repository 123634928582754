import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import BundleForm from './BundleForm';
import { makeGetBundleDetail, makeErrorMessage } from '../selectors';
import { getBundleDetail, modifyBundle, updateBundleStatus } from '../actions';
import { Breadcrumb } from '../../../components/common';
import {
  checkPermissionModifyBundle,
  checkPermissionApproveBundle,
  checkPermissionSellableBundle,
  checkPermissionNonSellableBundle,
  checkPermissionSunsetBundle,
} from '../CheckPermission';
import { makeGetPermissionsBundleManagement } from '../../App/selectors';

class BundleItemEdit extends Component {
  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    this.doGetBundleDetail();
  }

  doGetBundleDetail = () => {
    const {
      match: {
        params: { id },
      },
      getBundleDetail,
    } = this.props;
    getBundleDetail(id);
  };

  onSave = (data, cb = null) => {
    const { status, components, ...dataItem } = data;
    const { updateBundleStatus, modifyBundle } = this.props;
    let newComponents = [...components];
    newComponents = newComponents.map(e => {
      const { isNew, ...rest } = e;
      return {
        ...rest,
      };
    });
    const { bundleInfo } = this.props;
    const changedStatus = status !== bundleInfo.status;
    if (!changedStatus) {
      modifyBundle({ ...dataItem, components: newComponents }, ({ success }) => {
        this.setState({ isSubmitting: false });
        if (success) this.doGetBundleDetail();
        if (cb) cb({ success });
      });
    } else {
      modifyBundle({ ...dataItem, components: newComponents }, ({ success }) => {
        if (success) {
          updateBundleStatus({ id: bundleInfo.id, status }, () => {
            this.setState({ isSubmitting: false });
            if (cb) cb({ success });
          });
          this.doGetBundleDetail();
        } else {
          this.setState({ isSubmitting: false });
        }
      });
    }
  };

  render() {
    const { bundleInfo, permissionsBundleManagement, t, location } = this.props;
    const { isSubmitting } = this.state;
    let modeModifyBundle = 0;
    let modeApproveBundle = 0;
    let modeSellableBundle = 0;
    let modeNonSellableBundle = 0;
    let modeSunsetBundle = 0;
    if (permissionsBundleManagement && permissionsBundleManagement.bundleModulePermissions) {
      const listPermissionBundle = permissionsBundleManagement.bundleModulePermissions;
      modeModifyBundle = checkPermissionModifyBundle({ listPermission: listPermissionBundle });
      modeApproveBundle = checkPermissionApproveBundle({ listPermission: listPermissionBundle });
      modeSellableBundle = checkPermissionSellableBundle({ listPermission: listPermissionBundle });
      modeNonSellableBundle = checkPermissionNonSellableBundle({ listPermission: listPermissionBundle });
      modeSunsetBundle = checkPermissionSunsetBundle({ listPermission: listPermissionBundle });
    }
    if (modeModifyBundle === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{ pathname: RouteNames.bundleItem.path, state: location && location.state ? location.state : null }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.bundle'), url: RouteNames.bundleItem.path },
                  { name: bundleInfo && bundleInfo.name ? bundleInfo.name : t('common:label.bundle') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editBundle')}</h5>
              </div>
              <BundleForm
                item={bundleInfo}
                modeModifyBundle={modeModifyBundle}
                modeApproveBundle={modeApproveBundle}
                modeSellableBundle={modeSellableBundle}
                modeNonSellableBundle={modeNonSellableBundle}
                modeSunsetBundle={modeSunsetBundle}
                onSubmit={this.onSave}
                isSubmitting={isSubmitting}
                creatingMode={false}
                onCancel={this.doGetBundleDetail}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BundleItemEdit.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  modifyBundle: PropTypes.func.isRequired,
  updateBundleStatus: PropTypes.func.isRequired,
  getBundleDetail: PropTypes.func.isRequired,
  bundleInfo: PropTypes.objectOf(PropTypes.any),
};

BundleItemEdit.defaultProps = {
  permissionsBundleManagement: {},
  bundleInfo: {},
  match: {},
};

const mapStateToProps = createStructuredSelector({
  bundleInfo: makeGetBundleDetail() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsBundleManagement: makeGetPermissionsBundleManagement() || {},
});

const mapDispatchToProps = dispatch => ({
  getBundleDetail: (e, cb) => dispatch(getBundleDetail(e, cb)),
  modifyBundle: (e, cb) => dispatch(modifyBundle(e, cb)),
  updateBundleStatus: (e, cb) => dispatch(updateBundleStatus(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'bundlePage'])(compose(withConnect)(BundleItemEdit));
