import * as types from './types';

export function getInvoiceById(payload, cb) {
  return {
    type: types.GET_INVOICE_BY_ID,
    payload,
    cb,
  };
}

export function getInvoiceByIdFailed(payload) {
  return {
    type: types.GET_INVOICE_BY_ID_FAILED,
    payload,
  };
}

export function getTenantInvoiceTemplates(payload, cb) {
  return {
    type: types.GET_INVOICE_TEMPLATE,
    payload,
    cb,
  };
}

export function getTenantInvoiceTemplatesSuccess(payload, cb) {
  return {
    type: types.GET_INVOICE_TEMPLATE_SUCCESS,
    payload,
    cb,
  };
}

export function getNotesUnitById(payload, cb) {
  return {
    type: types.GET_NOTE_BY_ID,
    payload,
    cb,
  };
}