import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles, saveOutputTemplateByType, getOutputTemplateByType } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { MRRForecastSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../../components/common';
import { tabelColumnExportMRRForecast, sortMRRForecast } from '../../constants';
import { getMRRForecast, getAllMRRForecast, onResetDefaultData } from '../../actions';
import { makeGetListMRRForecast, makeGetListAllMRRForecast } from '../../selectors';
import {
  getPageTotalCount,
  formatMoneyValue,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let tableColumnsOutput = null;
let tableColumnsDefault = null;

const listMonth = [
  {
    key: 'JAN',
    value: 1,
    name: 'monthOneRevenue',
  },
  {
    key: 'FEB',
    value: 2,
    name: 'monthTwoRevenue',
  },
  {
    key: 'MAR',
    value: 3,
    name: 'monthThreeRevenue',
  },
  {
    key: 'APR',
    value: 4,
    name: 'monthFourRevenue',
  },
  {
    key: 'MAY',
    value: 5,
    name: 'monthFiveRevenue',
  },
  {
    key: 'JUN',
    value: 6,
    name: 'monthSixRevenue',
  },
  {
    key: 'JUL',
    value: 7,
    name: 'monthSevenRevenue',
  },
  {
    key: 'AUG',
    value: 8,
    name: 'monthEightRevenue',
  },
  {
    key: 'SEP',
    value: 9,
    name: 'monthNineRevenue',
  },
  {
    key: 'OCT',
    value: 10,
    name: 'monthTenRevenue',
  },
  {
    key: 'NOV',
    value: 11,
    name: 'monthElevenRevenue',
  },
  {
    key: 'DEC',
    value: 12,
    name: 'monthTwelveRevenue',
  },
];

class MRRForecast extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listMRRForecast: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listMRRForecast: props.listMRRForecast,
      totalCount: getPageTotalCount({ ...state, items: props.listMRRForecast }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetMRRForecastReport },
      onResetDefaultData,
    } = this.props;
    if (modeGetMRRForecastReport) {
      // Re-check permission
      // this.doGetListMRRForecast();
      onResetDefaultData('listMRRForecast');
      this.doGetOutputTemplateByType();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListMRRForecast();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListMRRForecast());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListMRRForecast());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListMRRForecast();
    });
  };

  doGetListMRRForecast = () => {
    const { filter, page, size, sorted } = this.state;
    const { getMRRForecast } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort:
        !isEmpty(sorted) && sortMRRForecast[sorted.sortCol] && sortMRRForecast[sorted.sortCol][sorted.sortDir]
          ? sortMRRForecast[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getMRRForecast(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistMRRForecast = cb => {
    const { filter, sorted } = this.state;
    const { getAllMRRForecast } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter,
      sort:
        !isEmpty(sorted) && sortMRRForecast[sorted.sortCol] && sortMRRForecast[sorted.sortCol][sorted.sortDir]
          ? sortMRRForecast[sorted.sortCol][sorted.sortDir]
          : null,
    };
    getAllMRRForecast(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = newListMonthSorted => {
    this.doGetAlllistMRRForecast(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllMRRForecast, t } = this.props;
        convertJson2Pdf({
          data: listAllMRRForecast,
          t,
          title: t('reportPage:sidebar.MRRForecast').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.MRRForecast')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          listMonth: newListMonthSorted,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ columns, listMonth, subject, emailBody }) => {
    this.doGetAlllistMRRForecast(() => {
      const { listAllMRRForecast, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_MONTHLY_RECURRING_REVENUE',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.MRRForecast')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.MRRForecast')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllMRRForecast,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              salesChannel: 'salesChannel',
              marketSegment: 'marketSegment',
              accountType: 'accountType',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllMRRForecast,
          t,
          title: t('reportPage:sidebar.MRRForecast').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.MRRForecast')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          listMonth,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.MRRForecast')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('MRR_FORECAST_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listMRRForecast,
      emails,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const {
      listAllMRRForecast,
      t,
      permissions: { modeGetMRRForecastReport, modeDownloadReport, modeGetMailReport },
    } = this.props;
    if (!modeGetMRRForecastReport) return '';
    const indexStartMonth =
      listMRRForecast && listMRRForecast.length
        ? listMonth.findIndex(val => val.key.toLowerCase() === listMRRForecast[0].startMonth.toLowerCase())
        : 0;
    const defaultMonth = cloneDeep(listMonth);

    // console.log('listMRRForecast', listMRRForecast);
    // const newListMonth = [
    //   ...defaultMonth.splice(moment().month(), defaultMonth.length - 1),
    //   ...defaultMonth.splice(0, moment().month() - 1),
    // ];

    const newListMonth = [
      ...defaultMonth.splice(indexStartMonth, defaultMonth.length),
      ...defaultMonth.splice(0, indexStartMonth),
    ];

    const newListMonthSorted = newListMonth.map(month => {
      const slt = t
        ? t('selections:monthOfYear')().find(val => val.value.toLowerCase() === month.key.toLowerCase())
        : '';
      return {
        name: month.name,
        label: slt.label,
        key: month.key,
        render: (colName, item) => {
          const dataRevenue = item && item[month.name] !== undefined ? item[month.name] : 0;
          return <span>{formatMoneyValue(dataRevenue)}</span>;
        },
      };
    });

    const newTableColunms = [...tabelColumnExportMRRForecast, ...newListMonthSorted];

    tableColumnsDefault = newTableColunms;
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={[
            { name: t('label.report'), url: RouteNames.report.path },
            { name: t('reportPage:sidebar.MRRForecast') },
          ]}
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.MRRForecast')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <MRRForecastSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}

                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={() => this.onExport(newListMonthSorted)} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.MRRForecast').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllMRRForecast,
                          t,
                          listMonth: newListMonthSorted,
                          title: t('reportPage:sidebar.MRRForecast').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.MRRForecast')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={e => this.sendEmail({ columns: newTableColunms, listMonth: newListMonthSorted, ...e })}
                      data={listMRRForecast}
                    />
                  )}
                </div>
                <DataTable
                  columns={tableColumnsOutput}
                  data={listMRRForecast && listMRRForecast.length ? listMRRForecast : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

MRRForecast.propTypes = {
  getMRRForecast: PropTypes.func,
  getAllMRRForecast: PropTypes.func,
  listAllMRRForecast: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

MRRForecast.defaultProps = {
  getMRRForecast: () => {},
  getAllMRRForecast: () => {},
  listAllMRRForecast: [],
};

const mapStateToProps = createStructuredSelector({
  listMRRForecast: makeGetListMRRForecast() || [],
  listAllMRRForecast: makeGetListAllMRRForecast() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getMRRForecast,
    getAllMRRForecast,
    uploadMultiPartFiles,
    onResetDefaultData,
    getOutputTemplateByType,
    saveOutputTemplateByType,
  })(MRRForecast)
);
