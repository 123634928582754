import gql from 'graphql-tag';

export default () => {
  const getBatchPaymentFileConfig = 'getBatchPaymentFileConfig( dummy: "")';
  return gql`
    query {
      ${getBatchPaymentFileConfig} {
        id
        name
        files {
          index
          accountNumber
          fileType
          name
          currency
          paymentFolio
        }
      }
    }
  `;
};
