import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionForAllPages,
  checkPermissionGetConfigUsageType,
  // checkPermissionSearchTopupOffer,
  checkPermissionViewZoneUnit,
  checkPermissionSearchConfigCountryCodes,
  checkPermissionViewRateUnitConfig,
  checkPermissionGetRateUnitMapConfig,
  checkPermissionViewRegulatoryProductCode,
  checkPermissionViewCurrencyConfig,
  checkPermissionViewGrantsConfig,
  checkPermissionViewAccumulatorConfig,
  checkPermissionGetUomConfig,
  checkPermissionViewTimeUnitConfig,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, permissionsTaxConfig, permissionsPricing, permissionsCustomer } = this.props;
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsPricing, listKeys: permissionList.baseConfigurations }))
      return null;
    if (permissionsTaxConfig && permissionsTaxConfig.taxationModulePermissions) {
      const listPermission = permissionsTaxConfig.taxationModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    let modeGetConfigUsageType = 0;
    let modeViewZoneUnit = 0;
    let modeSearchConfigCountryCodes = 0;
    let modeViewRateUnitConfig = 0;
    let modeGetRateUnitMapConfig = 0;
    let modeViewRegulatoryProductCode = 0;
    let modeViewCurrencyConfig = 0;
    let modeViewGrantsConfig = 0;
    let modeViewAccumulatorConfig = 0;
    let modeGetUomConfig = 0;
    let modeViewTimeUnitConfig = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeGetConfigUsageType = checkPermissionGetConfigUsageType({ listPermission: listPermissionPricing });
      modeViewZoneUnit = checkPermissionViewZoneUnit({ listPermission: listPermissionPricing });
      modeViewRateUnitConfig = checkPermissionViewRateUnitConfig({ listPermission: listPermissionPricing });
      modeGetRateUnitMapConfig = checkPermissionGetRateUnitMapConfig({ listPermission: listPermissionPricing });
      modeViewRegulatoryProductCode = checkPermissionViewRegulatoryProductCode({
        listPermission: listPermissionPricing,
      });
      modeViewCurrencyConfig = checkPermissionViewCurrencyConfig({
        listPermission: listPermissionPricing,
      });
      modeViewGrantsConfig = checkPermissionViewGrantsConfig({
        listPermission: listPermissionPricing,
      });
      modeViewAccumulatorConfig = checkPermissionViewAccumulatorConfig({
        listPermission: listPermissionPricing,
      });
      modeGetUomConfig = checkPermissionGetUomConfig({ listPermission: listPermissionPricing });
      modeViewTimeUnitConfig = checkPermissionViewTimeUnitConfig({ listPermission: listPermissionPricing });
    }

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeSearchConfigCountryCodes = checkPermissionSearchConfigCountryCodes({ listPermission });
    }

    const usageRating = [];
    const resourcesRoutes = [];

    if (modeGetConfigUsageType) {
      usageRating.push({
        icon: 'ti-save',
        label: 'baseConfigurationsPage:sidebar.usageType',
        href: RouteNames.usageType.path,
      });
    }

    if (modeGetUomConfig) {
      usageRating.push({
        icon: 'ti-ruler',
        label: 'baseConfigurationsPage:sidebar.unitOfMeasure',
        href: RouteNames.unitOfMeasure.path,
      });
    }

    if (modeViewRateUnitConfig) {
      usageRating.push({
        icon: 'ti-receipt',
        label: 'baseConfigurationsPage:sidebar.rateUnit',
        href: RouteNames.rateUnit.path,
      });
    }

    if (modeGetRateUnitMapConfig) {
      usageRating.push({
        icon: 'ti-map-alt',
        label: 'baseConfigurationsPage:sidebar.rateUnitMap',
        href: RouteNames.rateUnitMap.path,
      });
    }

    if (modeViewTimeUnitConfig) {
      usageRating.push({
        icon: 'ti-time',
        label: 'baseConfigurationsPage:sidebar.timeUnit',
        href: RouteNames.timeUnit.path,
      });
    }

    if (modeViewZoneUnit) {
      usageRating.push({
        icon: 'ti-calendar',
        label: 'baseConfigurationsPage:sidebar.zoneUnit',
        href: RouteNames.zoneUnit.path,
      });
    }

    if (modeSearchConfigCountryCodes) {
      usageRating.push({
        icon: 'ti-map',
        label: 'baseConfigurationsPage:sidebar.countryCodes',
        href: RouteNames.countryCodes.path,
      });
    }

    if (modeViewRegulatoryProductCode) {
      usageRating.push({
        icon: 'ti-archive',
        label: 'baseConfigurationsPage:sidebar.regulatoryProductCodes',
        href: RouteNames.regulatoryProductCodes.path,
      });
    }

    if (modeViewCurrencyConfig) {
      resourcesRoutes.push({
        icon: 'ti-money',
        label: 'baseConfigurationsPage:sidebar.currency',
        href: RouteNames.currency.path,
      });
    }

    if (modeViewGrantsConfig) {
      resourcesRoutes.push({
        icon: 'ti-briefcase',
        label: 'baseConfigurationsPage:sidebar.grants',
        href: RouteNames.grants.path,
      });
    }

    if (modeViewAccumulatorConfig) {
      resourcesRoutes.push({
        icon: 'ti-package',
        label: 'baseConfigurationsPage:sidebar.accumulators',
        href: RouteNames.accumulators.path,
      });
    }

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: false,
          mainTilte: 'navbar:pricingHub.subMain.baseConfigurations',
          href: RouteNames.baseConfigurations.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'baseConfigurationsPage:sidebar.resources',
          isNoExpand: true,
          childs: [...resourcesRoutes],
        },
        {
          isHaveExpand: true,
          mainTilte: 'baseConfigurationsPage:sidebar.pricingConfig',
          childs: [...usageRating],
        },
      ],
    };
    return <SideBar {...sideProps} isMaximizeOne />;
  }
}

Sidebar.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
};

Sidebar.defaultProps = {
  permissionsBundleManagement: {},
  location: {},
  isExpand: true,
};

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsTaxConfig = store.get('auth').toJS().permissionsApp.billingHubModules;
  const permissionsPricing = store.get('auth').toJS().permissionsApp.pricingHubModules;
  const permissionsCustomer = store.get('auth').toJS().permissionsApp.customerHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsTaxConfig: permissionsTaxConfig.length
      ? permissionsTaxConfig.filter(a => a.moduleName === 'REVENUE')[0]
      : {},
    permissionsPricing: permissionsPricing.length
      ? permissionsPricing.filter(a => a.moduleName === 'PRICE_MANAGEMENT')[0]
      : {},
    permissionsCustomer: permissionsCustomer.length
      ? permissionsCustomer.filter(a => a.moduleName === 'CUSTOMER_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
