import React from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { getPackageDetail, getBundleDetail } from '../../api';
import TableFormService from './TableFormService';
import TableFormLine from './TableFormLine';
import { CardHeaderForm } from '../common';

class ServiceEditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bundleName: '',
      packageName: '',
      needFetch: true,
    };
  }

  async getName(client) {
    const { service, isFetchLine, addLine } = this.props;

    if (service.packageId) {
      const { data } = await client.query({
        query: getPackageDetail(service.packageId),
      });
      if (data && data.getPackageById) {
        this.setState({
          packageName: data.getPackageById.name,
        });
      }
    }

    if (service.bundleId) {
      const { data } = await client.query({
        query: getBundleDetail(service.bundleId),
      });
      if (data && data.getBundleById) {
        if (isFetchLine) {
          addLine(data.getBundleById);
        }
        let linesN = data.getBundleById.components;
        if (linesN) {
          linesN = linesN.map(item => ({ action: 'ADD', status: 'CREATED', ...item }));
        }
        this.setState({
          bundleName: data.getBundleById.name,
          lines: linesN,
          needFetch: false,
        });
      }
    }
  }

  render() {
    const {
      service,
      onChangeFormLine,
      handleClickServicesFormBtn,
      isOverrideLines,
      title,
      isFetchLine,
      t,
    } = this.props;
    const { bundleName, packageName, needFetch, lines } = this.state;
    return (
      <ApolloConsumer>
        {client => {
          if (needFetch) {
            this.getName(client);
          }
          return (
            <div className="col-md-12 pb-3">
              <CardHeaderForm title={title || 'Services'} label="Service Type: " value={service.serviceType} />
              <TableFormService service={service} bundleName={bundleName} packageName={packageName} />
              <div className="form-group col-md-12">
                {!isOverrideLines ? (
                  <button
                    type="button"
                    className="button button-border  x-small float-right"
                    onClick={() => {
                      handleClickServicesFormBtn();
                    }}
                  >
                    {t('label.overrideLines')}
                  </button>
                ) : (
                  <div className="card-body px-2">
                    <CardHeaderForm key="CardHeaderForm" title="Lines" hideNavTab />
                    <TableFormLine
                      key="TableFormLine"
                      lines={isFetchLine ? lines : service.lines}
                      onChangeFormLine={onChangeFormLine}
                    />
                    ,
                    <button
                      key="HideLines"
                      type="button"
                      className="button button-border gray x-small float-right"
                      onClick={() => {
                        handleClickServicesFormBtn();
                      }}
                    >
                      {t('label.hideLines')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </ApolloConsumer>
    );
  }
}

ServiceEditForm.propTypes = {
  title: PropTypes.string,
  isOverrideLines: PropTypes.bool,
  isFetchLine: PropTypes.bool,
  service: PropTypes.objectOf(PropTypes.any),
  onChangeFormLine: PropTypes.func,
  addLine: PropTypes.func,
  handleClickServicesFormBtn: PropTypes.func,
};

ServiceEditForm.defaultProps = {
  title: '',
  isOverrideLines: false,
  isFetchLine: false,
  onChangeFormLine: () => {},
  addLine: () => {},
  handleClickServicesFormBtn: () => {},
  service: {},
};

export default withTranslation('common')(ServiceEditForm);
