import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { DataTable } from '../../../../components/common';
import ModalRoleSelector from '../ModalRoleSelector';

class RoleForm extends Component {
  state = {
    roles: [],
    isSelectingRoles: false,
    parentItemId: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.roles) return null;
    return {
      roles: props.roles || [],
      oldData: props.roles,
    };
  }

  onOpenParentSelector = () => {
    this.setState({ isSelectingRoles: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingRoles: false });
  };

  onSelectRoles = (parentItemId, item) => {
    const { roles = [] } = this.state;
    this.setState({ parentItemId, roles: [...roles, { ...item }], isSelectingRoles: false }, () => this.onChangeItem());
  };

  onRemoveRole = (e, role) => {
    e.preventDefault();
    const { roles } = this.state;
    const newRoles = cloneDeep(roles);
    const indexRoleDelete = newRoles.findIndex(item => item.id === role.id);
    newRoles[indexRoleDelete] = { index: newRoles[indexRoleDelete].index };
    this.setState({ roles: newRoles }, () => this.onChangeItem());
  };

  onChangeItem = () => {
    const { onChangeItem } = this.props;
    if (onChangeItem) {
      const { roles } = this.state;
      onChangeItem(roles);
    }
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value }, () => this.onChangeItem());
  };

  render() {
    const { t } = this.props;
    const { roles, isSelectingRoles, parentItemId } = this.state;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
        },
        {
          name: 'status',
          label: 'label.status',
        },
        {
          name: 'type',
          label: 'label.type',
        },
        {
          name: 'name',
          label: 'label.name',
        },
        {
          name: 'description',
          label: 'label.description',
        },
        {
          name: 'action',
          label: 'label.action',
          render: (colName, item) => (
            <button type="button" className="btn-trash" onClick={e => this.onRemoveRole(e, item)}>
              <i className="fa fa-trash" />
            </button>
          ),
        },
      ],
      data: roles.filter(item => size(item) > 2) || [],
    };

    return (
      <div>
        <div className="form-row">
          <DataTable {...tableConfig} isLoading={false} noDataMessage="No role has found!" />
        </div>
        <div className="form-row">
          <button type="button" className="button button-border x-small float-left" onClick={this.onOpenParentSelector}>
            {t('label.addRole')}
          </button>
        </div>
        <ModalRoleSelector
          isOpen={isSelectingRoles}
          selectedParentId={parentItemId}
          onSelect={this.onSelectRoles}
          onCancel={this.onCancelSelector}
        />
      </div>
    );
  }
}

RoleForm.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.any),
  isOpen: PropTypes.bool,
  onChangeItem: PropTypes.func,
  creatingMode: PropTypes.bool,
};

RoleForm.defaultProps = {
  roles: [],
  isOpen: false,
  creatingMode: true,
  onChangeItem: () => {},
};
export default withTranslation('common')(RoleForm);
