export default {
  sidebarSearchPage: {
    config: {
      title: 'Config',
      subTitle: {
        customAttributes: 'Custom Attributes',
        creditProfile: 'Credit Profile',
        delayedBilling: 'Delayed Billing',
        specialPricing: 'Special Pricing',
        accountMovement: 'Account Movement',
        merge: 'Merge',
        disperse: 'Disperse',
        collectionProfile: 'Collection Profile',
      },
    },
    account: {
      title: 'Account',
      subscriptionData: 'Subscription Data',
      accountData: 'Account Data',
      billingData: 'Billing Data',
      searchAccounts: 'Search Accounts',
    },
  },
  sidebarHandleAccountPage: {
    detailCreate: 'Detail Create',
    accountInfo: 'Account Info',
    contact: 'Contact',
    addresses: 'Addresses',
    paymentProfile: 'Payment Profile',
    billingProfile: 'Billing Profile',
    services: 'Services',
    assets: 'Assets',
    balances: 'Balances',
    bills: 'Bills',
    transactions: 'Transactions',
    ARActivity: 'AR Activity',
    customerActivity: 'Customer Activity',
    hierarchy: 'Hierarchy',
    customAttributes: 'Custom Attributes',
    externalPO: 'External PO',
    accountStatement: 'Account Statement',
    creditNotes: 'Credit/Debit Notes',
    provisioningData: 'Provisioning Data',
    payments: 'Payments',
    xChangeRates: 'xchange Rates',
    paymentInstallment: 'Payment Installment',
    ratedUsage: 'Rated Usage',
    usageRecords: 'Usage Records',
    ladingBill: 'Lading Bill',
    topup: 'Topup',
    offers: 'offers',
    others: 'Others',
    quickCreate: 'Quick Create',
    invoicePaymentInstallment: 'Invoice Payment Installment',
    taxExemptions: 'Tax Exemptions',
    sharing: 'Sharing',
    chargeShare: 'Charge Share',
    discountShare: 'Discount Share',
    subscriptionView: 'Subscription View',
  },
  createAccount: {
    createAccountInfo: 'Create Account Info',
    createContact: 'Create Contact',
    createAddress: 'Create Address',
    createPaymentProfile: 'Create Payment Profile',
    createBillingProfile: 'Create Billing Profile',
    createCustomerAttributes: 'Create Customer Attributes',
    createAccount: 'Create Account',
  },
};
