import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default data => {
  const filterStr = data ? `, input: ${convertFilterRequestUrl(data, ['installmentStatus'])}` : '';
  const getMultiInvPymtInstallmentsByAccountId = `getMultiInvPymtInstallmentsByAccountId(${filterStr})`;
  return gql`
    query {
      ${getMultiInvPymtInstallmentsByAccountId} {
        id
        accountId
        createdDate
        lastPaymentDate
        totalAmount
        totalDue
        paidAmount
        noOfInvoices
        noOfInstallments
        noOfInstallmentsPaid
        installmentStatus
        agreement
        invoices {
          index
          invoiceId
          due
          total
          status
        }
        data {
          index
          dueDate
          paymentDate
          amount
          paidAmount
          installmentStatus
        }
        payments {
          refIndex
          paymentId
          amount
          reversedAmount
        }
      }
    }
  `;
};
