import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index']);
  const createTrunkRulesConfig = `createTrunkRulesConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createTrunkRulesConfig} {
      id
    }
  }
`;
};
