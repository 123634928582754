import gql from 'graphql-tag';

export default fileName => {
  return gql`
    query {
      getItemServiceFileUploadErrors(fileName: "${fileName}") {
        id
        name
        description
        serviceProductCode
        uom
        chargeType
        status
        isParent
        isChild
        serviceType
        itemId
        glAccount
        taxCode
        errorMessage
        uomName
        productFamily
        productLine
        productType
      }
    }
  `;
};
