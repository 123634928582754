import gql from 'graphql-tag';

export default id => gql`
  query {
    getZonesByZoneUnitId(id: "${id}") {
      index
      description
      originField
      originValue
      destinationField
      destinationValue
      distanceField
      distanceValue
      zoneUnit
      startDate
      endDate
    }
  }
`;
