import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Footer = ({ className }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`col-sm-12 mb-30 ${className}`}>
      <footer className="bg-white p-4">
        <div className="row">
          <div className="col-md-6">
            <div className="text-center text-md-left">
              <p className="mb-0">
                {/* &copy; Copyright
              <span id="copyright">{` ${new Date().getFullYear()}.`}</span>
              <NavLink to="/"> EMBRIX </NavLink>
              All Rights Reserved. */}
                {t('label.copyrightText')}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <ul className="text-center text-md-right">
              {/* {t('label.policyText')} */}
              <li className="list-inline-item">
                <NavLink to="/">{t('label.termsConditions')}</NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/">{t('label.PIUsePolicy')}</NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/">{t('label.privacyPolicy')}</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
