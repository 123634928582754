import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['jurisdiction'])}` : '';
  const getVoiceTrafficByLdDistinationReport = `getVoiceTrafficByLdDistinationReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getVoiceTrafficByLdDistinationReport} {
        lineDate
        sciudad
        noOfMinutes
        noOfCalls
      }
    }
  `;
};
