import gql from 'graphql-tag';

export default id => {
  const deletePendingAccountMovementData = `deletePendingAccountMovementData(savedAccountMovementDataId: "${id}")`;
  return gql`
  mutation {
    ${deletePendingAccountMovementData} {
      userId
    }
  }
`;
};
