export default {
  font: { sz: '13', bold: false, color: { rgb: 'FFFFFF' } },
  fill: { patternType: 'solid', fgColor: { rgb: '00A651' } },
  border: {
    top: { style: 'thin', color: { rgb: '00a651' } },
    bottom: { style: 'thin', color: { rgb: '00a651' } },
    left: { style: 'thin', color: { rgb: '00a651' } },
    right: { style: 'thin', color: { rgb: '0d0d0d' } },
  },
};
