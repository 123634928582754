/* eslint-disable react/jsx-tag-spacing */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import PageTitle from '../../../components/PageTitle';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import RouteNames from '../../App/RouteNames';
import {
  searchItemServices,
  modifyItemServices,
  createItemServices,
  searchAllItemServices,
  getItemServiceProcessStats,
  getItemServiceFileUploadErrors,
} from '../actions';
import { makeGetItemServices, makeGetAllItemServices } from '../selectors';
import ModalSelectItem from '../../../components/common/ModalSelectItem';
import {
  FormWithTableItem,
  DataTable,
  GenericInput,
  TablePagination,
  CollapsibleTable,
  Switch,
  ButtonExport,
  SwitchExport,
  ExcelExport,
  ModalUploadFile,
  ButtonExportCSV,
  ModalWithItem,
} from '../../../components/common';
import { getPageTotalCount, parseToMutationRequestPostMethod } from '../../../utils/utils';
import {
  checkPermissionCreateItemServices,
  checkPermissionModifyItemServices,
  checkPermissionGetItemServices,
} from '../CheckPermission';
import { ItemServicesSearchForm } from '../../../components/PricingHub';
import ModalSearchItemServices from '../../../components/common/ModalSearchItemServices';
import ModalSelectPACProductCodes from '../../../components/common/ModalSelectPACProductCodes';
import ModalSelectProvisioningName from '../../../components/common/ModalSelectProvisioningName';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelItemServicesPage';
import convertJson2SheetFilesError from '../../../utils/ExcelHelper/exportExcelItemServicesFilesError';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfItemServicesPage';
import convertJson2PdfFilesError from '../../../utils/PdfHelper/exportPdfItemServicesFilesError';
import { headersCSV, dataCSV } from '../../../utils/CSVHelper/exportCSVItemServices';
import { uploadMultiPartFiles, getUomConfig } from '../../App/actions';

const sortTypes = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  serviceProductCode: {
    asc: 'serviceProductCode_ASC',
    desc: 'serviceProductCode_DESC',
  },
  chargeType: {
    asc: 'chargeType_ASC',
    desc: 'chargeType_DESC',
  },
};
class ItemServices extends PureComponent {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    defaultDataFromAPI: [],
    listItemServices: [],
    isSearching: true,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    rateUnitConfig: [],
    isOnChange: false,
    activeTab: -1,
    isOpenModalId: false,
    itemSelected: null,
    indexSelected: null,
    isFirstSave: false,
    isChangeItem: false,
    isOpenUploadFile: false,
    uomList: [],
    isOpenModalPACProductCodes: false,
    itemPacProductCodeIdSelected: null,
    statsFileData: [],
    isOpenModalStatsFile: false,
    isOpenModalViewFileUploadErrors: false,
    isActivePdfFilesError: false,
    isOpenModalProvisioningName: false,
    itemProvisioningName: null,
  };

  buttonRef = React.createRef();

  buttonExprotFilesErrorRef = React.createRef();

  buttonCSVRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.listItemServices) return null;
    return {
      listItemServices: props.listItemServices,
      totalCount: getPageTotalCount({ ...state, items: props.listItemServices }),
      oldData: props.listItemServices,
      dataSubmit: [],
      isOnChange: false,
    };
  }

  componentDidMount() {
    this.doGetItemServicesCfg();
    const { getUomConfig } = this.props;

    getUomConfig({ page: 1, size: 1000 }, ({ success, data }) => {
      if (success && data && data.searchList) {
        const uomList = data.searchList.map(val => ({
          label: `${val.unitId} : ${val.name}`,
          value: val.unitId,
        }));
        this.setState({ uomList });
      }
    });
  }

  onChangeTable = ({ name, value, index }) => {
    const { listItemServices } = this.state;
    const newData = cloneDeep(listItemServices);
    let newValue = value;
    if (value === 'TRUE') newValue = true;
    if (value === 'FALSE') newValue = false;
    newData[index][name] = newValue;
    this.setState({ listItemServices: newData, isOnChange: true });
  };

  doGetItemServicesCfg = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchItemServices } = this.props;
    if (filter.isParent && filter.isParent === 'TRUE') filter.isParent = 'true';
    if (filter.isParent && filter.isParent === 'FALSE') filter.isParent = 'false';
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true, activeTab: -1 });
    searchItemServices(payload, ({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.length) });
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetItemServicesCfg());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetItemServicesCfg());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetItemServicesCfg());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetItemServicesCfg());
  };

  onAddNewItemServices = () => {
    const { listItemServices, dataSubmit } = this.state;
    let newData = cloneDeep(listItemServices);
    const newDataItem = {
      id: '',
      description: '',
      serviceType: '',
      serviceProductCode: '',
      uom: '',
      chargeType: '',
      itemId: null,
      status: null,
      isParent: null,
      isProvisionable: true,
      provisioningName: null,
      components: [],
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    this.setState({ listItemServices: newData, dataSubmit, isOnChange: true });
  };

  onAddNewComponent = ({ index }) => {
    let indexNewItem = 0;
    const { listItemServices, dataSubmit } = this.state;
    const newData = cloneDeep(listItemServices);
    if (newData[index].components && newData[index].components.length) {
      newData[index].components.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      componentServiceId: '',
      // componentServiceName: '',
      componentQuantity: '',
      isNew: true,
    };
    if (!newData[index].components) newData[index].components = [];
    newData[index].components.push(newDataItem);
    this.setState({ listItemServices: newData, dataSubmit, isOnChange: true });
  };

  onChangeComponents = ({ activeTab, name, value, index }) => {
    try {
      const { listItemServices } = this.state;
      const newData = cloneDeep(listItemServices);
      newData[activeTab].components[index][name] = value;
      this.setState({ listItemServices: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItemTable = ({ index }) => {
    try {
      const { listItemServices, activeTab } = this.state;
      const newData = cloneDeep(listItemServices);
      if (newData[index] && newData[index].index === -1) {
        newData.splice(index, 1);
      } else {
        newData[activeTab].components[index] = {
          index: newData[activeTab].components[index].index,
        };
      }
      this.setState({ listItemServices: newData, isOnChange: true });
    } catch (error) {
      console.log(error);
    }
  };

  onHanldeSubmit = ({ activeTab }) => {
    const { listItemServices } = this.state;
    const { modifyItemServices, createItemServices } = this.props;
    const newPayload = cloneDeep(listItemServices)[activeTab];

    if (!newPayload.isNew) {
      newPayload.isParent = newPayload.isParent === 'TRUE' || newPayload.isParent === true ? 'true' : 'false';
      newPayload.isProvisionable = newPayload.isProvisionable === 'TRUE' || newPayload.isProvisionable === true ? 'true' : 'false';
      if (newPayload.components && newPayload.components.length)
        newPayload.components = newPayload.components.map(val => {
          const { componentServiceName, isParent, isNew, ...rest } = val;
          return { ...rest };
        });
      const { createdDate, glAccount, taxCode, ...restData } = newPayload;
      modifyItemServices(restData, ({ success }) => {
        if (success) this.doGetItemServicesCfg();
      });
    } else {
      const dataCreate = cloneDeep(newPayload);
      delete dataCreate.isNew;
      dataCreate.isParent = dataCreate.isParent === 'TRUE' || dataCreate.isParent === true ? 'true' : 'false';
      dataCreate.isProvisionable = dataCreate.isProvisionable === 'TRUE' || dataCreate.isProvisionable === true ? 'true' : 'false';
      if (dataCreate.components && dataCreate.components.length)
        dataCreate.components = dataCreate.components.map(val => {
          const { index, isNew, componentServiceName, ...rest } = val;
          return { ...rest };
        });
      const { createdDate, glAccount, taxCode, ...restData } = dataCreate;
      createItemServices(restData, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetItemServicesCfg();
        }
      });
    }
  };

  onToggleTab = (index, val, indexItem) => {
    const { activeTab } = this.state;
    if (activeTab === indexItem) {
      this.setState({ activeTab: -1 });
    } else this.setState({ activeTab: indexItem });
  };

  onSelectChangeItem = (id, row) => {
    const { indexSelected, activeTab } = this.state;
    const { listItemServices } = this.state;
    const newData = cloneDeep(listItemServices);
    try {
      newData[activeTab].components[indexSelected].componentServiceId = id;
      newData[activeTab].components[indexSelected].componentServiceName = row.name;
      this.setState({ listItemServices: newData, isOpenModalId: false, indexSelected: false, itemSelected: null });
    } catch (error) {}
  };

  onSelectItem = (id, row) => {
    const { activeTab } = this.state;
    const { listItemServices } = this.state;
    const newData = cloneDeep(listItemServices);
    try {
      let indexNewItem = 0;
      if (newData[activeTab].components && newData[activeTab].components.length) {
        newData[activeTab].components.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      } else {
        newData[activeTab].components = [];
      }
      const newPayload =
        id && id.length
          ? id.map((val, index) => {
              const rowItem = row && row.length ? row.find(i => i.id === val) : {};
              return {
                componentServiceId: val,
                componentServiceName: rowItem.name,
                componentQuantity: '1',
                isNew: true,
                index: indexNewItem + 1 + index,
              };
            })
          : null;
      if (newPayload) newData[activeTab].components = [...newData[activeTab].components, ...newPayload];
      this.setState({ listItemServices: newData, isOpenModalId: false, indexSelected: false, itemSelected: null });
    } catch (error) {}
  };

  onChangeParent = (e, index, isChild) => {
    try {
      const { listItemServices } = this.state;
      // const { modifyItemServices } = this.props;
      const newData = cloneDeep(listItemServices);
      if (isChild) {
        newData[index].isChild = !newData[index].isChild;
        newData[index].itemId = !newData[index].isChild ? newData[index].itemId : null;
        newData[index].chargeType = !newData[index].isChild ? newData[index].chargeType : null;
        this.setState({ listItemServices: newData }, () => {
          // if (!newData[index].isNew)
          //   modifyItemServices({ id: newData[index].id, isParent: newData[index].isParent || 'false' });
        });
      } else {
        newData[index].isParent = !newData[index].isParent;
        this.setState({ listItemServices: newData, activeTab: newData[index].isParent ? index : -1 }, () => {
          // if (!newData[index].isNew)
          //   modifyItemServices({ id: newData[index].id, isParent: newData[index].isParent || 'false' });
        });
      }
    } catch (error) {}
  };

  onToggleModalItemId = ({ item, index }) => {
    this.setState({ itemIdSelected: item, indexItemSelected: index, isOpenModalItem: true });
  };

  onToggleModalPacProductCode = ({ item, index }) => {
    this.setState({ itemPacProductCodeIdSelected: item, indexItemSelected: index, isOpenModalPACProductCodes: true });
  };

  onToggleModalProvisioningName = ({ item, index }) => {
    this.setState({ itemProvisioningName: item, indexItemSelected: index, isOpenModalProvisioningName: true });
  };

  onSelectItemId = (id, row) => {
    try {
      const { listItemServices, indexItemSelected } = this.state;
      const newData = cloneDeep(listItemServices);
      newData[indexItemSelected].itemId = id;
      this.setState({ listItemServices: newData, isOpenModalItem: false });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectPacProductCodeId = (id, row) => {
    try {
      const { listItemServices, indexItemSelected } = this.state;
      const newData = cloneDeep(listItemServices);
      newData[indexItemSelected].serviceProductCode = id;
      this.setState({ listItemServices: newData, isOpenModalPACProductCodes: false });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectProvisioningName = (id, row) => {
    try {
      const { listItemServices, indexItemSelected } = this.state;
      const newData = cloneDeep(listItemServices);
      newData[indexItemSelected].provisioningName = id;
      this.setState({ listItemServices: newData, isOpenModalProvisioningName: false });
    } catch (error) {
      console.log(error);
    }
  };

  doGetAllListItemService = cb => {
    const { filter, sorted } = this.state;
    const { searchAllItemServices } = this.props;
    const payload = {
      page: 1,
      size: 10000,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchAllItemServices(payload, () => {
      if (cb) cb();
    });
  };

  onExport = isCsv => {
    this.doGetAllListItemService(() => {
      const { isActivePdf, uomList } = this.state;
      if (isActivePdf && !isCsv) {
        const { listAllItemServices, t } = this.props;
        convertJson2Pdf({
          data: listAllItemServices,
          t,
          title: t('pricingPage:sidebar.itemServices').toLocaleUpperCase(),
          fileName: `${t('pricingPage:sidebar.itemServices')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          uomList,
        });
      } else if (isCsv) {
        this.buttonCSVRef.current.click();
      } else this.buttonRef.current.click();
    });
  };

  onExportFilesError = isCsv => {
    const { isActivePdfFilesError, fileUploadErrorData } = this.state;
    if (isActivePdfFilesError && !isCsv) {
      const { t } = this.props;
      convertJson2PdfFilesError({
        data: fileUploadErrorData,
        t,
        title: t('label.fileUploadErrors').toLocaleUpperCase(),
        fileName: `${t('label.fileUploadErrors')
          .toLocaleLowerCase()
          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
      });
    } else if (isCsv) {
      this.buttonCSVRef.current.click();
    } else this.buttonExprotFilesErrorRef.current.click();
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onChangeSwitchFilesError = () => {
    const { isActivePdfFilesError } = this.state;
    this.setState({ isActivePdfFilesError: !isActivePdfFilesError });
  };

  onToggleModal = () => {
    const { isOpenUploadFile } = this.state;
    this.setState({ isOpenUploadFile: !isOpenUploadFile });
  };

  onCancelUploadFile = () => {
    this.setState({ isOpenUploadFile: false, itemSelect: null });
  };

  onHandleUpload = ({ files }) => {
    const { isOpenUploadFile } = this.state;
    const { uploadMultiPartFiles } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        { category: 'PRICING', pricingSubType: 'ITEM_SERVICE', name: files[0].name },
        ['category', 'pricingSubType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          // this.doGetItemServicesCfg();
          this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null });
    }
  };

  onToggleModalViewStatsFile = () => {
    const { isOpenModalStatsFile } = this.state;
    if (!isOpenModalStatsFile) {
      this.doGetItemServiceProcessStats();
    }
    this.setState({ isOpenModalStatsFile: !isOpenModalStatsFile });
  };

  onToggleModalViewFileUploadError = () => {
    const { isOpenModalViewFileUploadErrors } = this.state;
    this.setState({ isOpenModalViewFileUploadErrors: !isOpenModalViewFileUploadErrors, isActivePdfFilesError: false });
  };

  doGetItemServiceProcessStats = () => {
    const { getItemServiceProcessStats } = this.props;
    getItemServiceProcessStats('', ({ success, data }) => {
      if (success) this.setState({ statsFileData: data || [] });
    });
  };

  doGetItemServiceFileUploadErrors = item => {
    const { getItemServiceFileUploadErrors } = this.props;
    getItemServiceFileUploadErrors(item.fileName, ({ success, data }) => {
      this.setState({ fileUploadErrorData: success ? data : [] });
      this.onToggleModalViewFileUploadError();
    });
  };

  render() {
    const {
      listItemServices,
      sorted,
      page,
      size,
      totalCount,
      activeTab,
      isOpenModalId,
      isChangeItem,
      isOpenModalItem,
      itemIdSelected,
      isActivePdf,
      isOpenUploadFile,
      itemSelect,
      uomList,
      itemPacProductCodeIdSelected,
      isOpenModalPACProductCodes,
      isSearching,
      isOpenModalStatsFile,
      statsFileData,
      isOpenModalViewFileUploadErrors,
      fileUploadErrorData,
      isActivePdfFilesError,
      isOpenModalProvisioningName,
      itemProvisioningName,
    } = this.state;
    const { t, permissionsPricing, listAllItemServices } = this.props;
    let modeCreateItemServices = 0;
    let modeModifyItemServices = 0;
    let modeGetItemServices = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateItemServices = checkPermissionCreateItemServices({ listPermission: listPermissionPricing });
      modeModifyItemServices = checkPermissionModifyItemServices({ listPermission: listPermissionPricing });
      modeGetItemServices = checkPermissionGetItemServices({ listPermission: listPermissionPricing });
    }
    if (!modeGetItemServices) return '';
    const itemServiceColumns = [
      {
        name: 'saveConfig',
        label: 'label.apply',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item, idx) => (
          <button
            disabled={modeModifyItemServices === 1}
            type="button"
            className="button x-small apply-btn"
            onClick={() => this.onHanldeSubmit({ activeTab: idx })}
          >
            {t('label.apply')}
          </button>
        ),
      },
      {
        name: 'id',
        label: 'label.id',
        // sortable: true,
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="id"
          />
        ),
      },
      {
        name: 'chargeType',
        label: 'label.chargeType',
        sortable: true,
        // required: true,
        style: { textAlign: 'center', minWidth: '90px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.chargeType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="chargeType"
            type="select"
            tOptions="selections:chargeType"
            menuPortalTarget
            disabled={item.isChild === true || item.isChild === 'TRUE'}
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        sortable: true,
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="name"
            required
            // isSupportTooltip
            type="textarea"
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        required: true,
        style: { textAlign: 'center', minWidth: '210px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="description"
            // isSupportTooltip
            type="textarea"
          />
        ),
      },
      {
        name: 'serviceType',
        label: 'label.serviceType',
        sortable: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="serviceType"
            type="select"
            tOptions="selections:serviceType"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'isChild',
        label: 'label.isChild',
        style: { textAlign: 'center', minWidth: '90px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          // const isDisableCollapsible = oldData && oldData[idx] ? !!(oldData[idx].isParent === false) : false;
          return (
            <div className="row">
              <Switch
                onChange={e => this.onChangeParent(e, idx, true)}
                name={`switch-${idx}-child`}
                wrapperClass={
                  item.isChild === true || item.isChild === 'TRUE' ? 'switch-active-green' : 'switch-non-active'
                }
                checked={!!(item.isChild === true || item.isChild === 'TRUE')}
              />
            </div>
          );
        },
      },
      {
        name: 'itemId',
        label: 'label.itemId',
        // required: true,
        // sortable: true,
        style: { textAlign: 'center', minWidth: '170px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.itemId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            onClick={() => this.onToggleModalItemId({ item, index: idx })}
            name="itemId"
            fa="fa fa-external-link"
            disabled={item.isChild === true || item.isChild === 'TRUE'}
          />
        ),
      },
      {
        name: 'serviceProductCode',
        label: 'label.serviceProductCode',
        sortable: true,
        style: { textAlign: 'center', minWidth: '150px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceProductCode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="serviceProductCode"
            fa="fa fa-external-link"
            onClick={() => this.onToggleModalPacProductCode({ item, index: idx })}
          />
        ),
      },
      {
        name: 'provisioningName',
        label: 'label.provisioningName',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.provisioningName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="provisioningName"
            fa="fa fa-external-link"
            onClick={() => this.onToggleModalProvisioningName({ item, index: idx })}
          />
        ),
      },
      {
        name: 'uom',
        label: 'label.uom',
        style: { textAlign: 'center', minWidth: '220px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.uom}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="uom"
            type="select"
            options={uomList}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'uomName',
        label: 'label.uomName',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.uomName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="uomName"
            // readOnly
            // disabled={item.isNew}
          />
        ),
      },
      {
        name: 'glAccount',
        label: 'label.glAccount',
        style: { textAlign: 'center', minWidth: '220px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.glAccount}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="glAccount"
            readOnly
            disabled={item.isNew}
          />
        ),
      },
      {
        name: 'taxCode',
        label: 'label.taxCode',
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.taxCode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="taxCode"
            readOnly
            disabled={item.isNew}
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        sortable: true,
        required: true,
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="status"
            required
            type="select"
            tOptions="selections:statusPricing"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'createdDate',
        label: 'label.createdDate',
        // sortable: true,
        required: true,
        style: { minWidth: '250px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.createdDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => {}}
            name="createdDate"
            readOnly
            disabled={item.isNew}
          />
        ),
      },
      {
        name: 'isProvisionable',
        label: t('label.isProvisionable'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.isProvisionable === 'true' || item.isProvisionable === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            wrapperClass="col-md-12"
            name="isProvisionable"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'isParent',
        label: 'label.isParent',
        style: { textAlign: 'center', minWidth: '100px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          // const isDisableCollapsible = oldData && oldData[idx] ? !!(oldData[idx].isParent === false) : false;
          return (
            <div className="row">
              <Switch
                onChange={e => this.onChangeParent(e, idx)}
                name={`switch-${idx}`}
                wrapperClass={
                  item.isParent === true || item.isParent === 'TRUE' ? 'switch-active-green' : 'switch-non-active'
                }
                checked={!!(item.isParent === true || item.isParent === 'TRUE')}
              />
              <button
                type="button"
                // disabled={!(item.isParent === true || item.isParent === 'TRUE') && item.isNew}
                className={`btn-expand-table mr-3 ${
                  !(item.isParent === true || item.isParent === 'TRUE') && !item.isNew
                    ? 'btn-expand-table-disabled'
                    : ''
                }`}
                // className="btn-expand-table mr-3"
                onClick={e =>
                  item.isParent === true || item.isParent === 'TRUE' ? this.onToggleTab(e, item, idx) : null
                }
              >
                <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
              </button>
            </div>
          );
        },
      },
    ];

    const componentsColumns = [
      {
        name: 'componentServiceId',
        label: 'label.serviceId',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item, idx) => {
          return (
            <GenericInput
              value={item.componentServiceId}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onChangeComponents({ name, value, index: idx, activeTab })}
              name="componentServiceId"
              fa="fa fa-external-link"
              onClick={() => {
                this.setState({ isOpenModalId: true, itemSelected: null, indexSelected: idx, isChangeItem: true });
              }}
            />
          );
        },
      },
      {
        name: 'componentServiceName',
        label: 'label.serviceName',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.componentServiceName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeComponents({ name, value, index: idx, activeTab })}
            name="componentServiceName"
            // readOnly={!item.isNew}
            readOnly
          />
        ),
      },
      {
        name: 'componentQuantity',
        label: 'label.quantity',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.componentQuantity}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeComponents({ name, value, index: idx, activeTab })}
            name="componentQuantity"
            type="number"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: idx })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const statsFileRecordColumns = [
      {
        name: 'fileName',
        label: 'common:label.fileName',
      },
      {
        name: 'fileUploadDate',
        label: 'common:label.fileUploadDate',
      },
      {
        name: 'totalCount',
        label: 'common:label.totalCount',
      },
      {
        name: 'pendingCount',
        label: 'common:label.pendingCount',
      },
      {
        name: 'successCount',
        label: 'common:label.successCount',
      },
      {
        name: 'failureCount',
        label: 'common:label.errorCount',
      },
      {
        name: 'view',
        label: '',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetItemServiceFileUploadErrors(item)}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];

    const itemServiceFileUploadErrorsColumns = [
      {
        name: 'itemId',
        label: 'common:label.itemId',
      },
      {
        name: 'errorMessage',
        label: 'common:label.errorMessage',
      },
      {
        name: 'name',
        label: 'common:label.name',
      },
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'serviceProductCode',
        label: 'common:label.serviceProductCode',
      },
      {
        name: 'uom',
        label: 'common:label.uom',
      },
      {
        name: 'chargeType',
        label: 'common:label.chargeType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:chargeType')().find(val => val.value === item.chargeType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:itemServiceStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'isParent',
        label: 'common:label.isParent',
        render: (colName, item) => {
          return <span>{item.isParent ? 'TRUE' : item.isParent === false ? 'FALSE' : null}</span>;
        },
      },
      {
        name: 'isChild',
        label: 'common:label.isChild',
        render: (colName, item) => {
          return <span>{item.isChild ? 'TRUE' : item.isChild === false ? 'FALSE' : null}</span>;
        },
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'glAccount',
        label: 'common:label.glAccount',
      },
      {
        name: 'taxCode',
        label: 'common:label.taxCode',
      },
      {
        name: 'uomName',
        label: 'common:label.uomName',
      },
      {
        name: 'productFamily',
        label: 'common:label.productFamily',
      },
      {
        name: 'productLine',
        label: 'common:label.productLine',
      },
      {
        name: 'productType',
        label: 'common:label.productType',
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.productFamilyPricing.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.productFamilyPricing.path },
            { name: `${t('pricingPage:sidebar.itemServices')}` },
          ]}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="col-md-12 mt-3">
              <button type="button" className="button x-small float-right mr-2" onClick={this.onToggleModal}>
                {t('label.upload')}
              </button>
              <button
                type="button"
                className="button button-border black x-small float-right mr-2"
                onClick={this.onToggleModalViewStatsFile}
              >
                {t('label.viewUploadFileStatus')}
              </button>
            </div>
            <br />
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <ItemServicesSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex mb-4 pt-3 float-right">
              <div className="ml-auto mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={this.onChangeSwitch}
                  wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdf}
                  rightTitle={t('label.pdf')}
                />
              </div>
              <>
                <ButtonExport onExport={() => this.onExport(false)} />
                <ExcelExport
                  element={<button type="button" className="display-none" ref={this.buttonRef} />}
                  nameSheet={t('pricingPage:sidebar.itemServices').toLocaleUpperCase()}
                  multiDataSet={convertJson2Sheet({
                    data: listAllItemServices,
                    t,
                    uomList,
                    title: t('pricingPage:sidebar.itemServices').toLocaleUpperCase(),
                  })}
                  fileName={`${t('pricingPage:sidebar.itemServices')
                    .toLocaleLowerCase()
                    .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                />
                <ButtonExportCSV onExport={() => this.onExport(true)} btnTitle="CSV" />
                <CsvDownloader
                  filename={`${t('pricingPage:sidebar.itemServices')
                    .toLocaleLowerCase()
                    .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                  datas={dataCSV({ t, data: listAllItemServices || [], uomList })}
                  columns={headersCSV({ t })}
                  className="display-none"
                  separator=";"
                  extension=".csv"
                >
                  <button type="button" className="display-none" ref={this.buttonCSVRef} />
                </CsvDownloader>
              </>
            </div>
          </div>
        </div>
        <FormWithTableItem title={`${t('pricingPage:sidebar.itemServices')}`} subClass="border-bottom">
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateItemServices === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={this.onAddNewItemServices}
              >
                +
                {t('label.addNewServices')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetItemServicesCfg()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div className="group-collapsible">
            <CollapsibleTable
              columns={itemServiceColumns}
              data={cloneDeep(listItemServices)}
              indexViewer={activeTab}
              onClickRow={() => {}}
              sorted={sorted}
              onSort={this.onSortColumn}
              isViewOnly
              isFixedHeaderTable
              isLoading={isSearching}
            >
              <br />
              <div className="component-group">
                <h4 className="pt-2 pb-4 text-center">{t('label.componentServices')}</h4>
                <DataTable
                  columns={cloneDeep(componentsColumns)}
                  isSupportRemoveIndex
                  data={
                    listItemServices && listItemServices[activeTab] && listItemServices[activeTab].components
                      ? cloneDeep(listItemServices[activeTab].components)
                      : []
                  }
                />
                <div className="form-group component-btn-group">
                  <button
                    type="button"
                    className="button button-border black x-small"
                    // onClick={() => this.onAddNewComponent({ index: activeTab })}
                    onClick={() => {
                      this.setState({
                        isOpenModalId: true,
                        itemSelected: null,
                        indexSelected: null,
                        isChangeItem: false,
                      });
                    }}
                  >
                    +
                    {t('label.addNewComponent')}
                  </button>
                  {modeModifyItemServices === 2 && (
                    <button
                      disabled={modeModifyItemServices === 1}
                      type="button"
                      className="button button-border x-small float-right"
                      onClick={() => this.onHanldeSubmit({ activeTab })}
                    >
                      {t('label.saveConfig')}
                    </button>
                  )}
                </div>
              </div>
            </CollapsibleTable>
          </div>
          <br />
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalSearchItemServices
          isOpen={isOpenModalId}
          isChangeItem={isChangeItem}
          onSelect={isChangeItem ? this.onSelectChangeItem : this.onSelectItem}
          onCancel={() => {
            this.setState({ isOpenModalId: false, itemSelected: null, indexSelected: null });
          }}
          selectedParentId={this.state && this.state.itemSelected ? this.state.itemSelected : ''}
        />
        <ModalSelectItem
          isOpen={isOpenModalItem}
          onSelect={this.onSelectItemId}
          onCancel={() => {
            this.setState({ isOpenModalItem: false });
          }}
          selectedParentId={itemIdSelected ? itemIdSelected.itemId : null}
          defaultSearchParams={{ type: 'REVENUE' }}
        />
        <ModalSelectPACProductCodes
          isOpen={isOpenModalPACProductCodes}
          onSelect={this.onSelectPacProductCodeId}
          onCancel={() => {
            this.setState({ isOpenModalPACProductCodes: false });
          }}
          selectedParentId={itemPacProductCodeIdSelected ? itemPacProductCodeIdSelected.serviceProductCode : null}
        />
        <ModalSelectProvisioningName
          isOpen={isOpenModalProvisioningName}
          onSelect={this.onSelectProvisioningName}
          onCancel={() => {
            this.setState({ isOpenModalProvisioningName: false });
          }}
          selectedParentId={itemProvisioningName ? itemProvisioningName.provisioningName : null}
        />
        <ModalUploadFile
          title={t('label.uploadDisputeFile')}
          isOpen={isOpenUploadFile}
          onCancel={this.onCancelUploadFile}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          accept=".csv, text/plain"
          acceptFileType={['csv', 'text']}
          messageValid={t('message.validUploadMessage')}
        />

        <ModalWithItem
          modalTitle={t('label.uploadFileStatus')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStatsFile}
          onToggle={this.onToggleModalViewStatsFile}
        >
          <button type="button" onClick={this.doGetItemServiceProcessStats} className="btn-reload">
            <i className="ti-reload" />
          </button>
          <DataTable columns={statsFileRecordColumns} data={statsFileData || []} />
          <button type="button" onClick={this.onToggleModalViewStatsFile} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
        <ModalWithItem
          modalTitle={t('label.fileUploadErrors')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalViewFileUploadErrors}
          onToggle={this.onToggleModalViewFileUploadError}
        >
          <div className="mt-3 d-flex mb-4 float-right">
            <div className="ml-auto mt-2 mb-auto mr-3">
              <SwitchExport
                onChange={this.onChangeSwitchFilesError}
                wrapperClass={isActivePdfFilesError ? 'switch-active' : 'switch-non-active'}
                title={t('label.excel')}
                checked={isActivePdfFilesError}
                name="isActivePdfFilesError"
                rightTitle={t('label.pdf')}
              />
            </div>
            <>
              <ButtonExport onExport={() => this.onExportFilesError(false)} />
              <ExcelExport
                element={<button type="button" className="display-none" ref={this.buttonExprotFilesErrorRef} />}
                nameSheet={t('label.fileUploadErrors').toLocaleUpperCase()}
                multiDataSet={convertJson2SheetFilesError({
                  data: fileUploadErrorData || [],
                  t,
                  title: t('label.fileUploadErrors').toLocaleUpperCase(),
                })}
                fileName={`${t('label.fileUploadErrors')
                  .toLocaleLowerCase()
                  .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
              />
            </>
          </div>
          <DataTable columns={itemServiceFileUploadErrorsColumns} data={fileUploadErrorData || []} />
          <button type="button" onClick={this.onToggleModalViewFileUploadError} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

ItemServices.propTypes = {
  searchItemServices: PropTypes.func.isRequired,
  modifyItemServices: PropTypes.func.isRequired,
  createItemServices: PropTypes.func.isRequired,
  listItemServices: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  permissionsPricing: PropTypes.object,
};

ItemServices.defaultProps = {
  listItemServices: [],
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  listItemServices: makeGetItemServices() || [],
  listAllItemServices: makeGetAllItemServices() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchItemServices,
    modifyItemServices,
    createItemServices,
    searchAllItemServices,
    uploadMultiPartFiles,
    getUomConfig,
    getItemServiceProcessStats,
    getItemServiceFileUploadErrors,
  })(ItemServices)
);
