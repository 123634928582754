import React from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../../App/RouteNames';
import { getZonesByZoneUnitId, createZoneUnit } from '../../actions';
import { Breadcrumb } from '../../../../components/common';
import ZoneUnitForm from './ZoneUnitForm';
import { checkPermissionCreateZoneUnit } from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

const ZoneUnitCreate = ({ createZoneUnit, history, permissionsPricing, location }) => {
  const { t } = useTranslation('common');

  let modeCreateZoneUnit = 0;

  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermission = permissionsPricing.pricingModulePermissions;
    modeCreateZoneUnit = checkPermissionCreateZoneUnit({ listPermission });
  }

  if (modeCreateZoneUnit !== 2) return null;

  const onSubmit = payload => {
    createZoneUnit(payload, ({ success, data }) => {
      if (success) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.zoneUnitEdit.path, data.id),
          state: { item: payload },
        });
      }
    });
  };

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            <NavLink
              className="button x-small"
              to={{ pathname: RouteNames.zoneUnit.path, state: location && location.state ? location.state : null }}
            >
              {t('label.back')}
            </NavLink>
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
                { name: t('label.zoneUnitAdd') },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <ZoneUnitForm
        defaultData={{ zones: [] }}
        onSubmit={onSubmit}
        isCreate
        isDisabledSave={modeCreateZoneUnit === 1}
      />
    </div>
  );
};

ZoneUnitCreate.propTypes = {
  history: PropsType.objectOf(PropsType.any),
  permissionsPricing: PropsType.objectOf(PropsType.any),
  createZoneUnit: PropsType.func.isRequired,
};

ZoneUnitCreate.defaultProps = { history: {}, permissionsPricing: {} };

const mapStateToProps = createStructuredSelector({
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default connect(mapStateToProps, { getZonesByZoneUnitId, createZoneUnit })(ZoneUnitCreate);
