import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { GenericInput, DataTable, FormCollapse } from '../../common';
import ModalSelectItem from '../../common/ModalSelectItem';
import { getExternalPOByAccountId, createExternalPO, modifyExternalPO } from '../../../containers/CustomerPage/actions';

const listFields = [
  {
    label: 'label.PONumber',
    name: 'purchaseOrderId',
    required: true,
  },
  {
    label: 'label.startDate',
    name: 'startDate',
    required: true,
    type: 'date',
  },
  {
    label: 'label.endDate',
    name: 'endDate',
    type: 'date',
  },
  {
    label: 'label.poAmount',
    name: 'poAmount',
    type: 'number',
  },
  {
    label: 'label.emailId',
    name: 'poEmailId',
  },
  {
    label: 'label.firstName',
    name: 'firstName',
  },
  {
    label: 'label.lastName',
    name: 'lastName',
  },
];

class ExternalPO extends PureComponent {
  state = {
    isModify: false,
    wasValidated: false,
    isSearching: true,
    data: [],
    defaultData: {},
    activeTab: {},
    itemIdSelected: null,
    isOpenModalItem: false,
    indexItemSelected: null,
    indexParentSelectd: null,
  };

  formRef = React.createRef();

  componentDidMount = () => {
    this.doGetExternalPOByAccountId();
  };

  doGetExternalPOByAccountId = () => {
    const { getExternalPOByAccountId, id } = this.props;
    this.setState({ isSearching: false, data: [] });
    getExternalPOByAccountId(id, ({ success, data }) => {
      if (success) this.setState({ data: data.poList, defaultData: data, isModify: !!data.id });
      this.setState({ isSearching: false });
    });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onHandleChange = ({ name, value, index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = newData.findIndex(item => item.index === index);
    newData[indexItemChange][name] = value;
    this.setState({ data: newData });
  };

  onRemoveItemTable = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
    }
    this.setState({ data: newData });
  };

  onAddNewExternalPO = () => {
    let indexNewItem = 0;
    const { data } = this.state;
    let newData = cloneDeep(data);
    if (!newData) newData = [];
    newData.forEach(item => {
      if (item.index > indexNewItem) {
        indexNewItem = item.index;
      }
    });
    if (!newData.length) newData = [];
    const newDataItem = {
      index: indexNewItem + 1,
      purchaseOrderId: null,
      startDate: null,
      endDate: null,
      poItems: [],
    };
    newData.push(newDataItem);
    this.setState({ data: newData, activeTab: { name: `state-${indexNewItem}`, isActive: true } });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { data, isModify, defaultData } = this.state;
    const { modifyExternalPO, createExternalPO, id } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (isModify) {
      const newPayload = {
        id: defaultData.id,
        poList: data,
      };
      return modifyExternalPO(newPayload, ({ success }) => {
        if (success) this.doGetExternalPOByAccountId();
      });
    }
    return createExternalPO({ accountId: id, poList: data }, ({ success }) => {
      if (success) this.doGetExternalPOByAccountId();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onAddNewPO = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    const indexItemChange = newData.findIndex(item => item.index === index);
    if (newData && !newData[indexItemChange].poItems) {
      newData[indexItemChange].poItems = [];
    }
    newData[indexItemChange].poItems.forEach(item => {
      if (item.index > indexNewItem) {
        indexNewItem = item.index;
      }
    });
    const newDataItem = {
      index: indexNewItem + 1,
      name: null,
      description: null,
      altdescription: null,
      itemid: null,
    };
    newData[indexItemChange].poItems.push(newDataItem);
    this.setState({
      data: newData,
    });
  };

  onChangeTable = ({ name, value, index, indexParent }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const indexItemChange = newData[indexParent].poItems.findIndex(item => item.index === index);
    newData[indexParent].poItems[indexItemChange][name] = value;
    this.setState({ data: newData });
  };

  onCancel = () => {
    this.setState({
      isOpenModalItem: false,
      itemIdSelected: null,
    });
  };

  onToggleModalItemId = ({ item, index, indexParent }) => {
    this.setState({
      itemIdSelected: item,
      indexItemSelected: index,
      indexParentSelectd: indexParent,
      isOpenModalItem: true,
    });
  };

  onSelectItemId = id => {
    try {
      const { data, indexItemSelected, indexParentSelectd } = this.state;
      const newData = cloneDeep(data);
      newData[indexParentSelectd].poItems[indexItemSelected].itemid = id;
      this.setState({
        data: newData,
        itemIdSelected: null,
        isOpenModalItem: false,
        indexItemSelected: null,
        indexParentSelectd: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { wasValidated, isSearching, data, activeTab, isOpenModalItem, itemIdSelected } = this.state;
    const {
      id,
      t,
      modeCreateExternalPO,
      modeModifyExternalPO,
      modeGetExternalPO,
      currencyOptions,
      customerInfo,
    } = this.props;
    if (modeGetExternalPO === 0) return '';
    const POColumns = [
      {
        name: 'itemid',
        label: t('label.id'),
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.itemid}
            wrapperClass="col-md-12"
            onChange={() => {}}
            onClick={() => this.onToggleModalItemId({ item, index: idx, indexParent })}
            name="itemid"
            fa="fa fa-external-link"
            readOnly
          />
        ),
      },
      {
        name: 'name',
        label: t('label.name'),
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index, indexParent })}
            name="name"
          />
        ),
      },
      {
        name: 'description',
        label: t('label.description'),
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index, indexParent })}
            name="description"
            type="textarea"
          />
        ),
      },
      {
        name: 'altdescription',
        label: t('label.alternateDescription'),
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.altdescription}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index, indexParent })}
            name="altdescription"
            type="textarea"
          />
        ),
      },
    ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }

    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('label.externalPurchaseOrder')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <form
              onSubmit={modeModifyExternalPO === 2 ? this.hanldeSubmit : () => {}}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
              noValidate
              ref={this.formRef}
            >
              <div>
                {data &&
                  data.map((item, index) => {
                    if (Object.keys(item).length < 2) return '';
                    return (
                      <FormCollapse
                        key={`key-${index}`}
                        isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                        title={`${t('label.PO')} #: ${item.purchaseOrderId || ''}, ${t(
                          'label.startDate'
                        )}: ${item.startDate || ''}`}
                        state={`state-${index}`}
                        isNoT
                        onToggleTab={this.onToggleTab}
                      >
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn-phone float-right mt-2"
                            disabled={item.startDate && moment(item.startDate).diff(new Date(), 'days') < 0}
                            onClick={() => this.onRemoveItemTable({ index: item.index })}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                        <div className="col-md-12 ml-3 row">
                          {listFields.map(val => (
                            <GenericInput
                              value={item[val.name]}
                              wrapperClass="col-md-4"
                              onChange={({ name, value }) => this.onHandleChange({ value, name, index: item.index })}
                              {...val}
                            />
                          ))}
                        </div>
                        <br />
                        <div className="col-md-12 p-4">
                          <DataTable
                            columns={POColumns}
                            data={
                              item && item.poItems && item.poItems.length > 0
                                ? item.poItems.filter(i => size(i) > 1)
                                : []
                            }
                            isLoading={isSearching}
                            indexParent={index}
                          />
                          {modeCreateExternalPO === 2 && (
                            <button
                              type="button"
                              className="button button-border black x-small"
                              onClick={() => this.onAddNewPO({ index: item.index })}
                              disabled={modeCreateExternalPO === 1}
                            >
                              +
                              {t('label.addPOItem')}
                            </button>
                          )}
                        </div>
                      </FormCollapse>
                    );
                  })}
              </div>
              <br />
              <div className="form-group col-md-12 pb-3 buttons-attibute">
                {modeCreateExternalPO === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.onAddNewExternalPO}
                    disabled={modeCreateExternalPO === 1}
                  >
                    +
                    {t('label.addNewExternalPO')}
                  </button>
                )}
                {modeModifyExternalPO === 2 && (
                  <button type="submit" className="button button-border x-small float-right">
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetExternalPOByAccountId}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ModalSelectItem
          isOpen={isOpenModalItem}
          selectedParentId={itemIdSelected ? itemIdSelected.itemId : null}
          onSelect={this.onSelectItemId}
          onCancel={this.onCancel}
          toggleModal={this.onCancel}
          defaultSearchParams={{ type: 'REVENUE' }}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

ExternalPO.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  getAccountDetail: PropTypes.func,
  getExternalPOByAccountId: PropTypes.func.isRequired,
  onHandleCreateAttribute: PropTypes.func,
  modifyAccount: PropTypes.func,
  accountNum: PropTypes.string,
  isNewAccount: PropTypes.bool,
  t: PropTypes.func,
};

ExternalPO.defaultProps = {
  accountNum: '',
  account: {},
  isNewAccount: false,
  getAccountDetail: () => {},
  modifyAccount: () => {},
  onHandleCreateAttribute: () => {},
  t: () => {},
};

const newExternalPO = connect(mapStateToProps, {
  getExternalPOByAccountId,
  createExternalPO,
  modifyExternalPO,
})(ExternalPO);

export default withTranslation('common')(newExternalPO);
