import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const search3rdPartyTaxConfig = `search3rdPartyTaxConfig(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${search3rdPartyTaxConfig} {
        id
        transactionName
        serviceName
        description
        taxcode
        transactionTypeId
        serviceTypeId
      }
    }
  `;
};
