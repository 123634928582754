import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { QuoteDetailForm } from '../../../components/Quotes';
import { newQuoteInfoField } from '../constants';

class QuoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id, quoteDetail, onChangeCheckBox, onHandleChange, onClick, t } = this.props;
    let infoFields = newQuoteInfoField;
    if (quoteDetail && quoteDetail.type === 'TRIAL') {
      infoFields = [
        ...infoFields,
        {
          label: 'common:label.trialType',
          fieldName: 'trialType',
          tOptions: 'selections:trialType',
        },
      ];
    }
    return (
      <QuoteDetailForm
        colItem="col-md-4"
        dataField={infoFields}
        title={t('label.quote')}
        accountId={id}
        quoteDetail={quoteDetail}
        onChangeCheckBox={onChangeCheckBox}
        onHandleChange={onHandleChange}
        onClick={onClick}
      />
    );
  }
}

QuoteForm.propTypes = {
  id: PropTypes.string,
  quoteDetail: PropTypes.objectOf(PropTypes.any),
  onChangeCheckBox: PropTypes.func,
  onHandleChange: PropTypes.func,
  onClick: PropTypes.func,
  t: PropTypes.func,
};

QuoteForm.defaultProps = {
  id: '',
  quoteDetail: {},
  onChangeCheckBox: () => {},
  onHandleChange: () => {},
  onClick: () => {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, {})(withTranslation('common')(QuoteForm));
