import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { getUsageStreamMap, modifyUsageProcMapConfig, createUsageProcMapConfig } from '../../actions';
import { makeGetListUsageStream } from '../../selectors';
import {
  TitleFrom,
  DataTableWithExpandRow,
  GenericInput,
  CheckField,
  ModalViewNote,
  FormCollapse,
} from '../../../../components/common';
import { messageNotesStream } from '../../constants';
import NewProcessingStreams from './NewProcessingStreams';

class ProcessingStreams extends PureComponent {
  state = {
    listUsageStreamMap: [],
    isSearching: false,
    isModify: false,
    dataSelected: [],
    isOpenModalItem: false,
    dataExpand: {},
    listSwitchActive: [],
    isOpenModalNotes: false,
    idxItemCopy: null,
    activeTab: {},
  };

  componentDidMount() {
    this.dogetUsageStreamMap();
  }

  componentWillReceiveProps(nextProps) {
    const { listUsageStreamMap } = this.props;
    if (listUsageStreamMap && nextProps.listUsageStreamMap && listUsageStreamMap !== nextProps.listUsageStreamMap) {
      this.setState({
        listUsageStreamMap: nextProps.listUsageStreamMap,
      });
    }
  }

  onToggleModalNotes = () => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes });
  };

  onChangeTable = ({ name, value, index }) => {
    const { listUsageStreamMap } = this.state;
    const newData = cloneDeep(listUsageStreamMap);
    const indexItemChange = newData.findIndex(item => item.index === index);
    if (value === 'TRUE' || value === 'FALSE') {
      newData[indexItemChange][name] = value === 'TRUE' ? 'true' : 'false';
    } else newData[indexItemChange][name] = value;
    this.setState({ listUsageStreamMap: newData });
  };

  dogetUsageStreamMap = () => {
    const { getUsageStreamMap } = this.props;
    getUsageStreamMap(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id) }, () => {
        if (data && data.length) this.onToggleTab(data[0].id, true);
      });
    });
  };

  onAddNewItem = (isCopy, indexExpand) => {
    let indexNewItem = 0;
    const { listUsageStreamMap } = this.state;
    let newData = cloneDeep(listUsageStreamMap);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    if (!isCopy) {
      const newDataItem = {
        // index: indexNewItem + 1,
        id: `STREAM-MAPPING-${Math.floor(Math.random() * 1000000)}`,
        active: 'true',
        ratingType: null,
        isNew: true,
        usageProcessStreamSeqCfg: [
          {
            index: 1,
            streamName: null,
            active: 'true',
            usageProcessModulesSeqCfg: [{ moduleName: null, active: 'true', logContainer: 'true', index: 1 }],
          },
        ],
      };
      this.onToggleTab(newDataItem.id);
      newData = [newDataItem, ...newData];
    }

    if (isCopy) {
      const newDataItem = cloneDeep(listUsageStreamMap[indexExpand]);
      newDataItem.id = `STREAM-MAPPING-${Math.floor(Math.random() * 1000000)}`;
      newDataItem.isNew = true;
      this.onToggleTab(newDataItem.id);
      newData = [newDataItem, ...newData];
    }
    this.setState({ listUsageStreamMap: newData, dataExpand: newData[0] });
  };

  onHanldeSubmit = id => {
    const { listUsageStreamMap } = this.state;
    const { createUsageProcMapConfig, modifyUsageProcMapConfig } = this.props;
    const newPayload = cloneDeep(listUsageStreamMap[id]);
    if (newPayload.isNew) {
      delete newPayload.isNew;
      delete newPayload.id;
      createUsageProcMapConfig(newPayload, ({ success }) => {
        if (success) this.dogetUsageStreamMap();
      });
    } else {
      modifyUsageProcMapConfig(newPayload, () => this.dogetUsageStreamMap());
    }
  };

  onHandleOpenDetailFile = () => {
    const { listUsageStreamMap } = this.state;
    this.setState({ isOpenModalItem: true, dataSelected: listUsageStreamMap });
  };

  onHandleToggleModalItem = () => {
    this.setState({ isOpenModalItem: false });
  };

  onSaveData = (data, idSelected) => {
    const { listUsageStreamMap } = this.state;
    const newData = cloneDeep(listUsageStreamMap);
    newData[idSelected] = data;
    this.setState({ listUsageStreamMap: newData }, () => this.onHanldeSubmit(idSelected));
  };

  onRemove = data => {
    const { createUsageProcMapConfig, modifyUsageProcMapConfig } = this.props;
    modifyUsageProcMapConfig({ id: data.id, ratingType: data.ratingType }, () => this.dogetUsageStreamMap());
  };

  onRenderChildren = ({ item, idx }) => {
    const { dataExpand, fileMapCfgList, listUsageStreamMap, listSwitchActive } = this.state;
    const { t } = this.props;
    return (
      <NewProcessingStreams
        data={listUsageStreamMap[idx]}
        indexExpand={idx}
        onSaveData={this.onSaveData}
        onRemove={this.onRemove}
        isHasNew={listUsageStreamMap && listUsageStreamMap.length && listUsageStreamMap.find(val => val.isNew)}
        onAddNewItem={indexExpand => this.onAddNewItem(true, indexExpand)}
      />
    );
    // if (dataExpand.id === item.id)
    // return (
    //   <>
    //     <tr>
    //       <td colSpan="5">
    //         <NewProcessingStreams
    //           data={dataExpand}
    //           indexExpand={idx}
    //           onSaveData={this.onSaveData}
    //           onRemove={this.onRemove}
    //         />
    //       </td>
    //     </tr>
    //   </>
    // );
    // return '';
  };

  onClickRow = (e, item) => {
    // const { fileMapCfgList, dataExpand } = this.state;
    // const indexItem = fileMapCfgList.findIndex(val => val.id === item.id);
    // this.setState({ dataExpand: dataExpand === fileMapCfgList[indexItem] ? {} : fileMapCfgList[indexItem] });
  };

  onHandleExpandRow = idx => {
    const { listUsageStreamMap, dataExpand } = this.state;
    this.setState({ dataExpand: dataExpand === listUsageStreamMap[idx] ? {} : listUsageStreamMap[idx] });
  };

  toggleActivateModule = item => {
    const { listSwitchActive } = this.state;
    let newData = cloneDeep(listSwitchActive);

    if (newData.indexOf(item.id) === -1) {
      newData.push(item.id);
    } else {
      newData = newData.filter(val => val !== item.id);
    }

    this.setState({ listSwitchActive: newData });
  };

  onSelectCopy = idx => {
    const { idxItemCopy } = this.state;
    if (idxItemCopy === idx) {
      this.setState({ idxItemCopy: null });
    } else {
      this.setState({ idxItemCopy: idx });
    }
  };

  onToggleTab = (activeTabName, isNeedExpandOne) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && !isNeedExpandOne) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const {
      listUsageStreamMap,
      isSearching,
      isModify,
      isOpenModalItem,
      dataSelected,
      dataExpand,
      listSwitchActive,
      isOpenModalNotes,
      idxItemCopy,
      activeTab,
    } = this.state;
    const { t } = this.props;
    const columns = [
      {
        name: 'action',
        label: 'label.selected',
        render: (colName, item, idx) => {
          return (
            <CheckField
              name="selectedParentId"
              value={false}
              checked={idxItemCopy === idx}
              onChange={() => this.onSelectCopy(idx)}
              disabled={listUsageStreamMap && listUsageStreamMap.length && listUsageStreamMap.find(val => val.isNew)}
            />
          );
        },
      },
      {
        name: 'Id',
        label: 'label.id',
        render: (colName, item) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="Id"
            onClick={() => {}}
            readOnly
          />
        ),
      },
      {
        name: 'Rating Type',
        label: 'label.ratingType',
        style: { minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            type="select"
            value={item.ratingType || null}
            tOptions="selections:recordIndicator"
            menuPortalTarget
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="ratingType"
            readOnly
            disabled
          />
        ),
      },
      {
        name: 'Active',
        label: 'label.active',
        style: { minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.active === 'true' || item.active === true ? 'TRUE' : 'FALSE'}
            type="select"
            menuPortalTarget
            tOptions="selections:selectBool"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="active"
            readOnly
            disabled
          />
        ),
      },
      {
        name: '',
        render: (colName, item, idx) => {
          return (
            <div className="row">
              {/* <Switch
                onChange={() => this.toggleActivateModule(item)}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={36}
                className="accordion-toggle-switcher"
              /> */}
              <button type="button" className="btn-expand-table" onClick={() => this.onHandleExpandRow(idx)}>
                <i className={`fa ${item.id === dataExpand.id ? 'fa-minus' : 'fa-plus'}`} />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.invoiceUsage.path}
          titleBtn={t('label.back')}
          items={[{ name: t('usagePage:sidebar.usageMapping') }, { name: t('label.usageStreamsMapping') }]}
          isNoDefaultButton
        />
        <div className="card card-statistics form-with-item">
          <div className="d-block d-md-flex justify-content-between mb-3">
            <div className="d-block d-md-flex clearfix sm-mt-20 col-md-12">
              <TitleFrom title={t('label.usageStreamsMapping')} isTabel />
              <div className="col-md-8">
                <button type="button" className="button x-small mt-2 ml-3">
                  {t('label.import')}
                </button>
                <button type="button" className="button x-small mt-2 ml-3">
                  {t('label.export')}
                </button>
                <button type="button" className="button x-small mt-2 ml-3">
                  {t('label.exportSpecs')}
                </button>
              </div>
            </div>
          </div>
          <div className="form-group col-md-12 buttons-attibute">
            <button
              type="button"
              className="button button-border black x-small"
              onClick={() => this.onAddNewItem(false)}
              disabled={listUsageStreamMap && listUsageStreamMap.length && listUsageStreamMap.find(val => val.isNew)}
            >
              {`+ ${t('label.addNewStreamMapping')}`}
            </button>
            {/* <button
              type="button"
              className="button button-border black x-small"
              onClick={() => this.onAddNewItem(true)}
              disabled={
                idxItemCopy === -1 ||
                idxItemCopy === null ||
                (listUsageStreamMap && listUsageStreamMap.length && listUsageStreamMap.find(val => val.isNew))
              }
            >
              {`+ ${t('label.copyToAdd')}`}
            </button> */}
            {/* <button
              type="button"
              className="button button-border x-small float-right"
              onClick={() => this.onHanldeSubmit()}
            >
              {isModify ? t('label.saveConfig') : t('label.createConfig')}
            </button> */}
            <button
              type="button"
              onClick={() => this.dogetUsageStreamMap()}
              className="button button-border black x-small float-right mr-2"
            >
              {t('label.cancel')}
            </button>
            <button
              type="button"
              className="button button-border gray x-small float-right"
              onClick={this.onToggleModalNotes}
            >
              {t('label.quickNotes')}
              <i className="fa fa-external-link ml-2" aria-hidden="true" />
            </button>
          </div>
          <div>
            {listUsageStreamMap &&
              listUsageStreamMap.length > 0 &&
              listUsageStreamMap.map((item, index) => {
                let title = `${t('label.streamMapping')}:`;
                if (item.ratingType) {
                  title = `${title} ${item.ratingType}`;
                }
                return (
                  <FormCollapse
                    key={item.id}
                    isActive={item.id === activeTab.name && activeTab.isActive}
                    title={title || 'null'}
                    state={item.id}
                    onToggleTab={this.onToggleTab}
                    isNoT
                  >
                    {this.onRenderChildren({ item, idx: index })}
                  </FormCollapse>
                );
              })}
            {/* <DataTableWithExpandRow
              columns={columns}
              data={listUsageStreamMap && listUsageStreamMap.length ? listUsageStreamMap : []}
              isLoading={isSearching}
              ExpandRow={this.onRenderChildren}
              rowClass="item-table-file-mapping"
              onClickTd={this.onClickRow}
              listSwitchActive={[]}
              dataExpand={dataExpand}
              isFixedHeaderTable
              isSupportAutoAcitve
            /> */}
          </div>
        </div>
        <ModalViewNote isOpen={isOpenModalNotes} message={messageNotesStream(t)} onToggle={this.onToggleModalNotes} />
      </div>
    );
  }
}

ProcessingStreams.propTypes = {
  getUsageStreamMap: PropTypes.func.isRequired,
  modifyUsageProcMapConfig: PropTypes.func.isRequired,
  createUsageProcMapConfig: PropTypes.func.isRequired,
  listUsageStreamMap: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

ProcessingStreams.defaultProps = {
  listUsageStreamMap: [],
};

const mapStateToProps = createStructuredSelector({
  listUsageStreamMap: makeGetListUsageStream() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getUsageStreamMap,
    modifyUsageProcMapConfig,
    createUsageProcMapConfig,
  })(ProcessingStreams)
);
