import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { collectionHistoryFieldDetails, tableColumnsCollectionHistoryDetails } from '../../constant';
import { FormWithTableItem, DataTable, GenericInput } from '../../../../components/common';
// import TitleFrom from '../../../../components/common/TitleFrom';
// import AccountHeaderFrom from '../../../../components/common/AccountHeaderFrom';
import { getCollectionUnitsById } from '../../actions';
import { makeCollectionHistoryDetail } from '../../selectors';

class CollectionHistoryDetail extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { getCollectionUnitsById, id } = this.props;
    this.setState({ isLoading: true });
    getCollectionUnitsById(id, () => this.setState({ isLoading: false }));
  }

  render() {
    const { collectionHistoryDetail, t } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="col-md-12 mb-30">
        {/* <div className="card card-statistics"> */}
        {/* <TitleFrom title={t('label.collectionHistoryDetail')} />
          <AccountHeaderFrom accountNum={collectionHistoryDetail.accountId} /> */}
        <div className="card-body d-flex group-flex">
          {collectionHistoryFieldDetails.map(item => (
            <GenericInput
              key={item.label}
              label={item.label || ''}
              value={collectionHistoryDetail[item.name] || ''}
              type={item.type}
              name={item.name}
              onChange={() => {}}
              wrapperClass={item.wrapperClass || 'col-md-4'}
              disabled
            />
          ))}
          <br />
        </div>
        <div className="col-sm-12 mb-30">
          <FormWithTableItem title={t('label.collectionActionsForInvoice')} wrapperClass="collection-form-history">
            <DataTable
              columns={tableColumnsCollectionHistoryDetails}
              data={collectionHistoryDetail.unitActionList || []}
              onSort={this.onSortColumn}
              isLoading={isLoading}
            />
          </FormWithTableItem>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

CollectionHistoryDetail.propTypes = {
  getCollectionUnitsById: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  collectionHistoryDetail: PropTypes.objectOf(PropTypes.any),
};

CollectionHistoryDetail.defaultProps = {
  collectionHistoryDetail: {},
};

const mapStateToProps = createStructuredSelector({
  collectionHistoryDetail: makeCollectionHistoryDetail() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCollectionUnitsById,
  })(CollectionHistoryDetail)
);
