import React from 'react';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import ScrollToTop from './ScrollToTop';
import Router from './Router';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <ScrollToTop>
      <ToastContainer timeout={3000} position={toast.POSITION.TOP_CENTER} />
      <Helmet>
        <title>EMBRIX</title>
        <meta name="description" content="EMBRIX Application" />
      </Helmet>
      <Router />
    </ScrollToTop>
  );
};

const withSaga = injectSaga({ key: 'rootSaga', saga });

export default compose(withSaga)(App);
