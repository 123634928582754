import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'amount',
    'serviceType',
    'validityTerm',
    'validityTermUnit',
    'applyPayment',
  ]);
  const subscriptionTopUp = `subscriptionTopUp(input: ${dataCreate})`;

  return gql`
  mutation {
    ${subscriptionTopUp} {
      id
    }
  }
`;
};
