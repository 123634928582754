/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getCollectionAgentByTypeConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { getTableFromResponeCollections } from '../../../utils/utils';

class ModalCollectionAgentByType extends PureComponent {
  searchHanldeSearchCollectionAgentByType = (payload, cb) => {
    const { getCollectionAgentByTypeConfig } = this.props;
    getCollectionAgentByTypeConfig(
      {
        type: 'COLLECTION_AGENTS',
      },
      response => {
        const listTable = getTableFromResponeCollections(
          response && response.getCollectionAgentByType,
          'collectionAgents'
        );
        if (cb) cb({ data: listTable, success: true });
      }
    );
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'userId',
          label: 'label.collectionUserId',
        },
        {
          name: 'firstName',
          label: 'label.firstName',
        },
        {
          name: 'lastName',
          label: 'label.lastName',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseCollectionAgent')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeSearchCollectionAgentByType}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="userId"
      />
    );
  }
}

ModalCollectionAgentByType.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getARReasonCodeSelectionConfig: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalCollectionAgentByType.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getCollectionAgentByTypeConfig })(ModalCollectionAgentByType));
