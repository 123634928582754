import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { size, cloneDeep, orderBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Sidebar from '../Sidebar';
import constant from '../constants';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getSpecialPriceByAccountId, modifySpecialPrice, createSpecialPrice } from '../actions';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import {
  makeGetPermissionsRating,
  makeGetPermissionsBilling,
  makeGetPermissionsPricingManagement,
  makeGetPermissionsCustomerManagementSubscription,
} from '../../App/selectors';
import {
  checkPermissionGetDelayedBillingConfig,
  checkPermissionGetSpecialPricingConfig,
  checkPermissionModifySpecialPricingConfig,
  checkPermissionCreateSpecialPricingConfig,
  checkPermissionSetCreditLimit,
} from '../CheckPermission';

class SpecialPricing extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    specialPriceList: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
    specialPrice: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetSpecialPriceByAccountId();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { specialPriceList, dataSubmit } = this.state;
    const newData = cloneDeep(specialPriceList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    let newValue = value;
    if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = newValue;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = newValue;
    }
    newData[indexItemChange][name] = newValue;
    this.setState({ specialPriceList: newData });
  };

  doGetSpecialPriceByAccountId = () => {
    const { getSpecialPriceByAccountId, id } = this.props;
    getSpecialPriceByAccountId(id, ({ success, data }) => {
      if (success) {
        this.setState({
          specialPriceList: data.specialPriceList ? data.specialPriceList : [],
          specialPrice: data,
          dataSubmit: [],
        });
      }
      this.setState({ isSearching: false, specialPriceList: [], specialPrice: null });
    });
  };

  onAddNewProfile = () => {
    let indexNewItem = 0;
    const { specialPriceList, dataSubmit } = this.state;
    const newData = cloneDeep(specialPriceList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      usageType: '',
      usageClass: '',
      bNumberPrefix: '',
      price: 0,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ specialPriceList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { specialPriceList, dataSubmit } = this.state;
    const newData = cloneDeep(specialPriceList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ specialPriceList: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit, specialPrice } = this.state;
    const { modifySpecialPrice, createSpecialPrice, id } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (specialPrice && specialPrice.id) {
      const newPayload = {
        id: specialPrice.id,
        specialPriceList: dataSubmit,
        accountId: id,
      };
      return modifySpecialPrice(newPayload, ({ success }) => {
        if (success) this.doGetSpecialPriceByAccountId();
      });
    }

    const newPayloadCreate = cloneDeep(dataSubmit).map(item => {
      const newItem = item;
      delete newItem.index;
      return newItem;
    });
    createSpecialPrice({ accountId: id, specialPriceList: newPayloadCreate }, ({ success }) => {
      if (success) this.doGetSpecialPriceByAccountId();
    });
  };

  render() {
    const { specialPriceList, isSearching, wasValidated, specialPrice } = this.state;
    const {
      permissionsRating,
      permissionsBilling,
      t,
      isComponent,
      permissionsPricing,
      permissionsCustomerSubscription,
      currencyOptions,
      customerInfo,
      id,
    } = this.props;
    let modeSetCreditProfile = 0;
    let modeGetSpecialPricingConfig = 0;
    let modeModifySpecialPricingConfig = 0;
    let modeCreateSpecialPricingConfig = 0;
    let modeGetDelayedBillingConfig = 0;

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermissionRating = permissionsRating.ratingModulePermissions;
      modeSetCreditProfile = checkPermissionSetCreditLimit({ listPermission: listPermissionRating });
    }
    if (permissionsCustomerSubscription && permissionsCustomerSubscription.subscriptionModulePermissions) {
      const listPermissionsSubscription = permissionsCustomerSubscription.subscriptionModulePermissions;
      modeGetSpecialPricingConfig = checkPermissionGetSpecialPricingConfig({
        listPermission: listPermissionsSubscription,
      });
      modeCreateSpecialPricingConfig = checkPermissionCreateSpecialPricingConfig({
        listPermission: listPermissionsSubscription,
      });
      modeModifySpecialPricingConfig = checkPermissionModifySpecialPricingConfig({
        listPermission: listPermissionsSubscription,
      });
    }

    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermissionsBilling = permissionsBilling.billingModulePermissions;
      modeGetDelayedBillingConfig = checkPermissionGetDelayedBillingConfig({ listPermission: listPermissionsBilling });
    }

    const specialPricingColumns = [
      {
        name: 'usageType',
        label: t('label.usageType'),
        style: { textAlign: 'center', minWidth: '120px' },
        render: (colName, item) => (
          <GenericInput
            value={item.usageType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="usageType"
          />
        ),
      },
      {
        name: 'serviceType',
        label: t('label.serviceType'),
        style: { textAlign: 'center', minWidth: '120px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.serviceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="serviceType"
            required
          />
        ),
      },
      {
        name: 'usageClass',
        label: t('label.usageClass'),
        style: { textAlign: 'center', minWidth: '140px' },
        render: (colName, item) => (
          <GenericInput
            value={item.usageClass}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="usageClass"
          />
        ),
      },
      {
        name: 'eightHundredFlag',
        label: t('label.800Flag'),
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            wrapperClass="col-md-12"
            value={item.eightHundredFlag === 'true' || item.eightHundredFlag === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="eightHundredFlag"
          />
        ),
      },
      {
        name: 'bNumberPrefix',
        label: t('label.callingNumberPrefix'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.bNumberPrefix}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="bNumberPrefix"
            required
          />
        ),
      },
      {
        name: 'price',
        label: t('label.price'),
        style: { minWidth: '160px', textAlign: 'center' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.price}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="price"
            required
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const newSideProps = cloneDeep(constant.sidePropIndex);
    if (modeSetCreditProfile === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.creditProfile'
      );
    }

    if (modeGetDelayedBillingConfig === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.delayedBilling'
      );
    }

    const newDataOnlyViewInTable =
      specialPriceList && specialPriceList.length
        ? orderBy(
            specialPriceList.filter(item => size(item) > 1),
            ['index'],
            ['asc']
          )
        : [];

    if (isComponent && modeGetSpecialPricingConfig) {
      let currencyLabel = '';
      if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
        const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
        currencyLabel = currencySelect ? currencySelect.normalLabel : '';
      }
      return (
        <div className="col-md-12">
          <FormWithTableItem
            accountNum={id}
            title={t('customerPage:sidebarSearchPage.config.subTitle.specialPricing')}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          >
            <form
              onSubmit={this.hanldeSubmit}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
              noValidate
              ref={this.formRef}
            >
              <br />
              <div>
                <DataTable columns={specialPricingColumns} data={newDataOnlyViewInTable} isLoading={isSearching} />
              </div>
              <br />
              <div className="form-group col-md-12 buttons-attibute">
                {modeGetSpecialPricingConfig !== 0 && (
                  <button
                    type="button"
                    disabled={modeGetSpecialPricingConfig === 1}
                    className="button button-border black x-small"
                    onClick={this.onAddNewProfile}
                  >
                    +
                    {t('label.addSpecialPricing')}
                  </button>
                )}
                <button type="submit" className="button button-border x-small float-right">
                  {specialPrice && specialPrice.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
                <button
                  type="button"
                  onClick={this.doGetSpecialPriceByAccountId}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </FormWithTableItem>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar sideProps={newSideProps} />
          {modeGetSpecialPricingConfig !== 0 && (
            <div className="content-wrapper">
              <div className="col-md-12 mb-30">
                <PageTitle
                  linkTo={RouteNames.customers.path}
                  titleBtn={t('common:label.back')}
                  items={[
                    { name: t('label.customerManagement'), url: RouteNames.customers.path },
                    { name: t('customerPage:sidebarSearchPage.config.subTitle.specialPricing') },
                  ]}
                />
                <FormWithTableItem title={t('customerPage:sidebarSearchPage.config.subTitle.specialPricing')}>
                  <form
                    onSubmit={this.hanldeSubmit}
                    className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                    noValidate
                    ref={this.formRef}
                  >
                    <br />
                    <div>
                      <DataTable
                        columns={specialPricingColumns}
                        data={newDataOnlyViewInTable}
                        isLoading={isSearching}
                      />
                    </div>
                    <br />
                    <div className="form-group col-md-12 pb-3 buttons-attibute">
                      {modeCreateSpecialPricingConfig === 2 && (
                        <button
                          type="button"
                          className="button button-border black x-small"
                          onClick={this.onAddNewProfile}
                        >
                          +
                          {t('label.addSpecialPricing')}
                        </button>
                      )}
                      {modeModifySpecialPricingConfig === 2 && (
                        <button
                          type="submit"
                          className="button button-border x-small float-right"
                          // onClick={() => this.hanldeSubmit()}
                        >
                          {specialPrice && specialPrice.id ? t('label.saveConfig') : t('label.createConfig')}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={this.doGetSpecialPriceByAccountId}
                        className="button button-border black x-small float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    </div>
                  </form>
                </FormWithTableItem>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SpecialPricing.propTypes = {
  getSpecialPriceByAccountId: PropTypes.func.isRequired,
  createSpecialPrice: PropTypes.func.isRequired,
  modifySpecialPrice: PropTypes.func.isRequired,
  permissionsRating: PropTypes.objectOf(PropTypes.any),
};

SpecialPricing.defaultProps = {
  permissionsRating: {},
};
const mapStateToProps = createStructuredSelector({
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
  permissionsCustomerSubscription: makeGetPermissionsCustomerManagementSubscription() || {},
});

const newSpecialPricing = connect(mapStateToProps, {
  getSpecialPriceByAccountId,
  modifySpecialPrice,
  createSpecialPrice,
})(SpecialPricing);

export default withTranslation('common')(newSpecialPricing);
