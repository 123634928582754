import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t }) => [
  {
    xSteps: 2,
    columns: [],
    data: [[{ value: 'CLIENT JOBS TITLE', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.id,
      columns.common, // index
      columns.common, // jobTitle
      columns.common, // startDate
      columns.common, // endDate
      columns.common, // pricingUnit
      columns.common, // workDays
      columns.common, // amount
      columns.common, // status
    ],
    data: [
      [
        {
          value: t('label.id'),
          style: styleHeader,
        },
        {
          value: t('label.index'),
          style: styleHeader,
        },
        {
          value: t('label.jobTitle'),
          style: styleHeader,
        },
        {
          value: t('label.startDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.pricingUnit'),
          style: styleHeader,
        },
        {
          value: t('label.workDays'),
          style: styleHeader,
        },
        {
          value: t('label.amount'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.id ? item.id : '' });
      newItem.push({ value: item.index !== -1 ? item.index : '' });
      newItem.push({ value: item.jobTitle ? item.jobTitle : '' });
      newItem.push({ value: item.startDate ? item.startDate : '' });
      newItem.push({ value: item.endDate ? item.endDate : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'clientPricingUnit', fieldOption: 'clientPricingUnit' }),
      });
      newItem.push({ value: item.workDays ? formatMoneyValueToExport(item.workDays) : '' });
      newItem.push({ value: item.amount ? formatMoneyValueToExport(item.amount) : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'timeAndBillingObjectStatus' }),
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
