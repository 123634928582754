import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import arrayMove from 'array-move';
import { customerSelect } from '../../../constantsApp';
import Footer from '../../../components/Footer';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsRating, makeGetPermissionsCustomerManagement } from '../../App/selectors';
import { FormWithTableItem, CollapsibleTableDragDrop, GenericInput, DataTable } from '../../../components/common';
import { getCustomAttributesByObjectType, ModifyCustomAttributes, createCustomAttributes } from '../actions';
import SidebarIndex from '../SidebarIndex';
import {
  checkPermissionCreateCustomAttributes,
  checkPermissionGetCustomAttributes,
  checkPermissionModifyCustomAttributes,
} from '../CheckPermission';

class CustomerAttribute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customAttributes: {},
      defaultLength: 0,
      isCreateAttributes: true,
      isLoading: true,
      activeSubTab: {},
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.doGetCustomAttributesByObjectType();
  }

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, type } = this.props;
    this.setState({ isLoading: true });
    getCustomAttributesByObjectType(({ success, data }) => {
      if (data && success) {
        this.setState({
          customAttributes: data || { attributes: [] },
          isCreateAttributes: false,
          defaultLength: data.attributes.length || 0,
        });
      }
      this.setState({
        isLoading: false,
      });
    }, type || '');
  };

  handleCustomAttributes = () => {
    const { customAttributes, isCreateAttributes } = this.state;
    const { createCustomAttributes, ModifyCustomAttributes, type } = this.props;
    if (isCreateAttributes) {
      const payloadCreate = cloneDeep(customAttributes);
      payloadCreate.objectType = type || null;
      if (payloadCreate.attributes && payloadCreate.attributes.length) {
        payloadCreate.attributes = payloadCreate.attributes.map((e, idx) => {
          const {
            index,
            displayName,
            name,
            description,
            category,
            defaultValue,
            type,
            mandatory,
            attributesLOVs,
            ...rest
          } = e;
          return {
            ...rest,
            description: description || null,
            displayName: displayName || null,
            name: name || displayName || null,
            category: category || null,
            defaultValue: defaultValue || null,
            type: type || null,
            mandatory: mandatory || null,
            index: idx + 1,
            attributesLOVs:
              type === 'ENUM' && attributesLOVs && attributesLOVs.length
                ? attributesLOVs.map(val => {
                    const { isNew, ...rest } = val;
                    return { ...rest };
                  })
                : null,
          };
        });
      }
      return createCustomAttributes(payloadCreate, ({ success, data }) => {
        if (success) {
          this.doGetCustomAttributesByObjectType();
          this.setState({
            // customAttributes: data || { attributes: [] },
            isCreateAttributes: false,
            defaultLength: data.attributes.length || 0,
          });
        }
      });
    }
    const payload = cloneDeep(customAttributes);
    if (payload.attributes && payload.attributes.length) {
      payload.attributes = payload.attributes.map((e, idx) => {
        const {
          index,
          displayName,
          name,
          description,
          category,
          defaultValue,
          type,
          mandatory,
          attributesLOVs,
          ...rest
        } = e;
        const newAttributesLOVs = [];
        if (attributesLOVs && attributesLOVs.length) {
          attributesLOVs
            .filter(val => Object.keys(val).length > 1)
            .forEach((attLovs, idxAtt) => {
              const { index, isNew, ...rest } = attLovs;
              newAttributesLOVs.push({ ...rest, index: idxAtt + 1 });
            });
          // attributesLOVs.forEach(att => {
          //   if (att.index > newAttributesLOVs.length) newAttributesLOVs.push({ index: att.index });
          // });
        }
        return {
          ...rest,
          description: description || null,
          displayName: displayName || null,
          name: name || displayName || null,
          category: category || null,
          defaultValue: defaultValue || null,
          type: type || null,
          mandatory: mandatory || null,
          index: idx + 1,
          attributesLOVs: type === 'ENUM' && newAttributesLOVs && newAttributesLOVs.length ? newAttributesLOVs : null,
        };
      });
      payload.attributes.forEach(att => {
        if (att.index > payload.attributes.length) payload.attributes.push({ index: att.index });
      });
    }

    return ModifyCustomAttributes(payload, ({ success, data }) => {
      if (success) {
        this.doGetCustomAttributesByObjectType();
        this.setState({
          // customAttributes: data || { attributes: [] },
          isCreateAttributes: false,
          defaultLength: data.attributes.length || 0,
        });
      }
    });
  };

  onUpdateValue = (index, name, value) => {
    const { customAttributes, activeSubTab } = this.state;
    const newData = cloneDeep(customAttributes);
    let newValue = value || null;
    if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
    newData.attributes[index][name] = newValue;
    if (name === 'type') {
      newData.attributes[index].defaultValue = '';
      if (value !== 'ENUM' && activeSubTab.index === index && activeSubTab.key === 'viewLov') {
        this.setState({ activeSubTab: {} });
      }
    }
    this.setState({ customAttributes: newData });
  };

  addNewAttribute = () => {
    const { customAttributes } = this.state;
    const newData = cloneDeep(customAttributes);
    if (!newData.attributes) newData.attributes = [];
    const newAttribute = {
      index: newData.attributes.length + 1,
      length: null,
      mandatory: 'N',
      name: null,
      type: null,
      defaultValue: null,
      displayName: null,
      category: 'DEFAULT',
      description: null,
    };
    newData.attributes = [newAttribute, ...newData.attributes];
    // newData.attributes.push(newAttribute);
    this.setState({ customAttributes: newData });
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem) this.setState({ activeSubTab: {} });
    if (activeSubTab.index !== indexItem) {
      this.setState({ activeSubTab: { key, index: indexItem } });
    }
  };

  onChangeLov = ({ name, value, index }) => {
    const { customAttributes, activeSubTab } = this.state;
    const newData = cloneDeep(customAttributes);
    try {
      newData.attributes[activeSubTab.index].attributesLOVs[index][name] = value;
      this.setState({ customAttributes: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveLov = ({ index }) => {
    const { customAttributes, activeSubTab } = this.state;
    const newData = cloneDeep(customAttributes);
    try {
      newData.attributes[activeSubTab.index].attributesLOVs.splice(index, 1);
      this.setState({ customAttributes: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewLov = () => {
    try {
      const { customAttributes, activeSubTab } = this.state;
      const newData = cloneDeep(customAttributes);
      let lastIndex = 0;
      if (!newData.attributes[activeSubTab.index].attributesLOVs)
        newData.attributes[activeSubTab.index].attributesLOVs = [];
      newData.attributes[activeSubTab.index].attributesLOVs.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.attributes[activeSubTab.index].attributesLOVs.push({
        index: lastIndex + 1,
        // index: -1,
        attributeId: null,
        attributeValue: null,
      });
      this.setState({ customAttributes: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSortEndAttribute = ({ oldIndex, newIndex }) => {
    try {
      const { customAttributes } = this.state;
      const newData = cloneDeep(customAttributes);
      const tempIndex = newData.attributes[newIndex].index;
      newData.attributes[newIndex].index = newData.attributes[oldIndex].index;
      newData.attributes[oldIndex].index = tempIndex;
      const orderedAttribute = arrayMove(newData.attributes, oldIndex, newIndex);
      newData.attributes = orderedAttribute;
      this.setState({
        customAttributes: newData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { isCreateAttributes, customAttributes, isLoading, activeSubTab } = this.state;
    const { t, isComponent, permissionsCustomer, currencyOptions, customerInfo, id } = this.props;

    let modeModifyCustomAttributes = 0;
    let modeCreateCustomAttributes = 0;
    let modeGetCustomAttributes = 0;

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;

      modeCreateCustomAttributes = checkPermissionCreateCustomAttributes({ listPermission });
      modeGetCustomAttributes = checkPermissionGetCustomAttributes({ listPermission });
      modeModifyCustomAttributes = checkPermissionModifyCustomAttributes({ listPermission });
    }

    if (!modeGetCustomAttributes) return null;

    const tableColumns = [
      {
        name: 'displayName',
        label: 'label.attributeName',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.displayName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
            name="displayName"
          />
        ),
      },
      {
        name: 'type',
        label: 'label.attributeType',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.type}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
            name="type"
            type="select"
            options={t('selections:variableTypes')()}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'length ',
        label: 'label.length',
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.length}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
            name="length"
            type={item.type === 'VARCHAR' ? 'number' : 'text'}
          />
        ),
      },
      {
        name: 'mandatory ',
        label: 'label.mandatory',
        style: { minWidth: '100px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.mandatory}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
            name="mandatory"
            type="select"
            options={customerSelect.mandatory}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'viewLov ',
        label: 'label.viewLov',
        render: (colName, item, idx, indexParent, activeTab) => {
          if (item.type !== 'ENUM') return '';
          return (
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleSubTab(idx, item, idx, 'viewLov')}
              // disabled={!item.locations || !item.locations.length}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'viewLov' && activeSubTab.index === idx ? 'fa-minus' : 'fa-plus'
                }`}
              />
            </button>
          );
        },
      },
      {
        name: 'defaultValue ',
        label: 'label.defaultValue',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          if (item.type === 'ENUM') {
            return (
              <GenericInput
                value={item.defaultValue || null}
                wrapperClass="col-md-12"
                onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
                name="defaultValue"
                type="select"
                options={
                  item.attributesLOVs
                    ? item.attributesLOVs.map(val => ({
                        label: `${val.attributeId} : ${val.attributeValue}`,
                        value: val.attributeId,
                      }))
                    : []
                }
                menuPortalTarget
              />
            );
          }
          if (item.type === 'BOOLEAN') {
            return (
              <GenericInput
                value={item.defaultValue === 'true' || item.defaultValue === true ? 'TRUE' : 'FALSE'}
                wrapperClass="col-md-12"
                onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
                name="defaultValue"
                type="select"
                tOptions="selections:selectBool"
                menuPortalTarget
              />
            );
          }
          return (
            <GenericInput
              value={item.defaultValue}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
              name="defaultValue"
              type={item.type === 'VARCHAR' ? 'text' : 'number'}
            />
          );
        },
      },
      {
        name: 'description ',
        label: 'label.description',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
            name="description"
            type="textarea"
          />
        ),
      },
      {
        name: 'category ',
        label: 'label.category',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.category}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onUpdateValue(index, name, value)}
            name="category"
            type="select"
            tOptions="selections:customAttributeCategory"
            menuPortalTarget
          />
        ),
      },
    ];

    const lovColumns = [
      {
        name: 'attributeId ',
        label: 'label.name',
        render: (colName, item, index) => (
          <GenericInput
            value={item.attributeId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeLov({ index, name, value })}
            name="attributeId"
          />
        ),
      },
      {
        name: 'attributeValue ',
        label: 'label.description',
        render: (colName, item, index) => (
          <GenericInput
            value={item.attributeValue}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeLov({ index, name, value })}
            name="attributeValue"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveLov({ index: idx, item })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    if (isComponent) {
      let currencyLabel = '';
      if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
        const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
        currencyLabel = currencySelect ? currencySelect.normalLabel : '';
      }
      return (
        <div className="col-md-12">
          <FormWithTableItem
            title={t('label.customAttributes')}
            subClass="border-bottom"
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          >
            <div className="table-responsive mt-15 form-focus">
              <div className="form-group col-md-12 buttons-attibute">
                {modeCreateCustomAttributes === 2 && (
                  <button type="button" className="button button-border black x-small" onClick={this.addNewAttribute}>
                    +
                    {t('label.addNewAttribute')}
                  </button>
                )}
                {modeModifyCustomAttributes === 2 && (
                  <button
                    type="submit"
                    className="button button-border x-small float-right"
                    onClick={() => this.handleCustomAttributes()}
                  >
                    {isCreateAttributes ? t('label.createConfig') : t('label.saveConfig')}
                  </button>
                )}
                <button
                  onClick={this.doGetCustomAttributesByObjectType}
                  type="button"
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <div className="card-body group-collapsible">
                <CollapsibleTableDragDrop
                  columns={tableColumns}
                  indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
                  data={customAttributes.attributes || []}
                  isSupportRemoveIndex
                  isLoading={isLoading}
                  isViewOnly
                  onSortEnd={this.onSortEndAttribute}
                  isFixedHeaderTable
                >
                  <div>
                    <h5 className="pt-3 pb-3 pl-3">{t('label.listOfValues')}</h5>
                    <DataTable
                      isSupportRemoveIndex
                      columns={lovColumns}
                      data={
                        customAttributes &&
                        customAttributes.attributes &&
                        customAttributes.attributes[activeSubTab.index] &&
                        customAttributes.attributes[activeSubTab.index].attributesLOVs &&
                        activeSubTab &&
                        activeSubTab.index !== -1
                          ? customAttributes.attributes[activeSubTab.index].attributesLOVs
                          : []
                      }
                    />
                    <button
                      type="button"
                      className="button button-border black x-small mb-4 ml-2"
                      onClick={this.addNewLov}
                    >
                      +
                      {t('label.addNewRow')}
                    </button>
                  </div>
                </CollapsibleTableDragDrop>
              </div>
            </div>
            <br />
          </FormWithTableItem>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper">
            <div className="col-md-12 mb-30">
              <PageTitle
                linkTo={RouteNames.customers.path}
                titleBtn={t('common:label.back')}
                items={[
                  { name: t('label.customerManagement'), url: RouteNames.customers.path },
                  { name: t('label.customAttributes') },
                ]}
              />
              <FormWithTableItem title={t('label.customAttributes')} subClass="border-bottom">
                <div className="table-responsive mt-15 form-focus">
                  <div className="form-group col-md-12 buttons-attibute">
                    {modeCreateCustomAttributes === 2 && (
                      <button
                        type="button"
                        className="button button-border black x-small"
                        onClick={this.addNewAttribute}
                      >
                        +
                        {t('label.addNewAttribute')}
                      </button>
                    )}
                    {modeModifyCustomAttributes === 2 && (
                      <button
                        type="submit"
                        className="button button-border x-small float-right"
                        onClick={() => this.handleCustomAttributes()}
                      >
                        {isCreateAttributes ? t('label.createConfig') : t('label.saveConfig')}
                      </button>
                    )}

                    <button
                      onClick={this.doGetCustomAttributesByObjectType}
                      type="button"
                      className="button button-border black x-small float-right"
                    >
                      {t('label.cancel')}
                    </button>
                  </div>
                  <div className="card-body group-collapsible">
                    <CollapsibleTableDragDrop
                      columns={tableColumns}
                      indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
                      data={customAttributes.attributes || []}
                      isSupportRemoveIndex
                      isLoading={isLoading}
                      isViewOnly
                      onSortEnd={this.onSortEndAttribute}
                      isFixedHeaderTable
                    >
                      <div>
                        <h5 className="pt-3 pb-3 pl-3">{t('label.listOfValues')}</h5>
                        <DataTable
                          columns={lovColumns}
                          isSupportRemoveIndex
                          data={
                            customAttributes &&
                            customAttributes.attributes &&
                            customAttributes.attributes[activeSubTab.index] &&
                            customAttributes.attributes[activeSubTab.index].attributesLOVs &&
                            activeSubTab &&
                            activeSubTab.index !== -1
                              ? customAttributes.attributes[activeSubTab.index].attributesLOVs
                              : []
                          }
                        />
                        <button
                          type="button"
                          className="button button-border black x-small mb-4 ml-2"
                          onClick={this.addNewLov}
                        >
                          +
                          {t('label.addNewRow')}
                        </button>
                      </div>
                    </CollapsibleTableDragDrop>
                  </div>
                </div>
                <br />
              </FormWithTableItem>
            </div>

            <SidebarIndex />
            <br />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

CustomerAttribute.propTypes = {
  getCustomAttributesByObjectType: PropTypes.func.isRequired,
  createCustomAttributes: PropTypes.func.isRequired,
  ModifyCustomAttributes: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
});

const newCustomerAttribute = connect(mapStateToProps, {
  getCustomAttributesByObjectType,
  ModifyCustomAttributes,
  createCustomAttributes,
})(CustomerAttribute);

export default withTranslation(['common', 'selections'])(newCustomerAttribute);
