import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SideBar } from '../../components/common';
import RouteNames from '../App/RouteNames';
import { checkPermissionForAllPages } from './CheckPermission';
import { checkPermissionForAllPages as checkPermissionForAllReportPages } from '../ReportManagement/CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, permissionsRevenue, permissionsReports } = this.props;
    const childConfig = [];
    let permissions = {};
    let permissionReports = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsRevenue, listKeys: permissionList.revenue })) return null;

    if (permissionsRevenue && permissionsRevenue.revenueModulePermissions) {
      const listPermission = permissionsRevenue.revenueModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissionReports = checkPermissionForAllReportPages({ listPermission });
    }
    const {
      modeGetCompanyConfig,
      modeGetCurrencyExchangeConfig,
      modeGetMilestoneConfig,
      modeGetGLAccountConfig,
    } = permissions;
    const { modeGetAccountingReconciliationReport } = permissionReports;
    // if (modeGetCompanyConfig) {
    //   childConfig.push({
    //     icon: 'ti-package',
    //     label: 'revenuePage:sidebar.companySetup',
    //     href: RouteNames.revenueCompanySetup.path,
    //   });
    // }
    // if (modeGetCurrencyExchangeConfig) {
    //   childConfig.push({
    //     icon: 'ti-money',
    //     label: 'revenuePage:sidebar.currencyExchange',
    //     href: RouteNames.revenueCurrencyExchange.path,
    //   });
    // }
    // if (modeGetMilestoneConfig) {
    //   childConfig.push({
    //     icon: 'ti-power-off',
    //     label: 'revenuePage:sidebar.milestone',
    //     href: RouteNames.revenueMilestone.path,
    //   });
    // }
    // if (modeGetGLAccountConfig) {
    //   childConfig.push({
    //     icon: 'ti-panel',
    //     label: 'revenuePage:sidebar.GLAccounts',
    //     href: RouteNames.revenueGlAccount.path,
    //   });
    // }
    const childConfigReport = [];
    if (modeGetAccountingReconciliationReport) {
      childConfigReport.push({
        icon: 'ti-harddrives',
        label: 'reportPage:sidebar.accountingReconciliationReport',
        href: RouteNames.revenueAccountingReconciliation.path,
      });
    }
    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'revenuePage:sidebar.revenue',
          href: '',
          childs: [
            {
              icon: 'ti-shield',
              label: 'revenuePage:sidebar.journals',
              href: RouteNames.journals.path,
            },
            {
              icon: 'ti-user',
              label: 'revenuePage:sidebar.accountingLog',
              href: RouteNames.accountingLog.path,
            },
            {
              icon: 'ti-package',
              label: 'revenuePage:sidebar.erpExtracts',
              href: RouteNames.erpExtracts.path,
            },
            ...childConfigReport,
          ],
        },
      ],
    };
    return <SideBar {...sideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsRevenue = store.get('auth').toJS().permissionsApp.revenueHubModules;
  const permissionsReports = store.get('auth').toJS().permissionsApp.opsHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsRevenue: permissionsRevenue.length ? permissionsRevenue.filter(a => a.moduleName === 'REVENUE')[0] : {},
    permissionsReports: permissionsReports.length ? permissionsReports.filter(a => a.moduleName === 'REPORTS')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsPayment: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
