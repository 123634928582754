export default {
  sidebar: {
    collectionAdmin: 'Collection Admin',
    collectionConfig: 'Collection Config',
    collectionActions: 'Collection Actions',
    collectionSchedule: 'Collection Schedule',
    collectionAgents: 'Collection Agents',
    searchAccounts: 'Search Accounts',
    invoiceUnits: 'Invoice Units',
    collectionHistory: 'Collection History',
    agentActivity: 'Agent Activity',
    accountsInCollection: 'Accounts in Collection',
    // schedulePatternConfig: 'Schedule Pattern Config',
    collectionAging: 'Collection Aging',
  },
};
