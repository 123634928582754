import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import RoleGroupForm from './RoleGroupForm';
import { makeGetlistRoles, makePageRolesParams, errorMessageRoles } from '../selectors';
import { getRoleGroupById, searchRoles, setParamsRoles, modifyRoleGroup, searchAllRoles } from '../actions';
import { makeGetPermissionsUserManagement } from '../../App/selectors';
import { checkPermissionModifyRoleGroups, checkPermissionChangeRoleGroupStatus } from '../CheckPermission';

const combineRoleGroup = (roleGroup, listRoles) => {
  const { roleGroupRoles = [] } = roleGroup || {};
  let newRoleGroupRoles = listRoles.filter(item => roleGroupRoles.find(d => d.roleId === item.id));
  if (newRoleGroupRoles && newRoleGroupRoles.length) {
    newRoleGroupRoles = newRoleGroupRoles.map(role => {
      const indexRole = roleGroupRoles.find(r => r.roleId === role.id);
      return { ...role, index: indexRole.index };
    });
  }
  return { ...roleGroup, roleGroupRoles: newRoleGroupRoles };
};

class PricingItemEdit extends Component {
  state = {
    isSubmitting: false,
    itemInfo: {},
    allRoles: [],
  };

  componentDidMount() {
    this.doGetRoleGroupById();
  }

  doGetRoleGroupById = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getRoleGroupById({ id }, response => {
      if (response.success && response.data) {
        this.props.searchAllRoles({}, ({ success, data }) => {
          if (success) {
            const roleGroup = combineRoleGroup(response.data, data);
            this.setState({ allRoles: data, itemInfo: roleGroup });
          }
        });
      }
    });
  };

  onSave = (data, cb = null) => {
    this.props.modifyRoleGroup({ roleGroupInput: data }, ({ success, data }) => {
      this.setState({ isSubmitting: false, itemInfo: combineRoleGroup(data, this.state.allRoles) });
    });
  };

  render() {
    const { itemInfo } = this.state;
    const {
      permissionsUser,
      t,
      location,
      match: {
        params: { id },
      },
    } = this.props;
    let modeModifyRoleGroup = 0;
    let modeModifyRoleGroupStatus = 0;

    if (permissionsUser && permissionsUser.userModulePermissions) {
      const listPermission = permissionsUser.userModulePermissions;
      modeModifyRoleGroup = checkPermissionModifyRoleGroups({ listPermission });
      modeModifyRoleGroupStatus = checkPermissionChangeRoleGroupStatus({ listPermission });
    }

    if (modeModifyRoleGroup === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.userManagementRoleGroup.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:operationsHub.subMain.userManagement'), url: RouteNames.userManagementRole.path },
                  {
                    name: t('userManagementPage:sidebar.roleGroups'),
                    url: {
                      pathname: RouteNames.userManagementRoleGroup.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  { name: itemInfo && itemInfo.name ? itemInfo.name : t('label.edit') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editRoleGroup')}</h5>
              </div>
              <RoleGroupForm
                modeModifyRoleGroup={modeModifyRoleGroup}
                modeModifyRoleGroupStatus={modeModifyRoleGroupStatus}
                item={itemInfo}
                onSubmit={this.onSave}
                isSubmitting={this.state.isSubmitting}
                onCancel={this.doGetRoleGroupById}
                isModifyMode
                id={id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  listRoles: makeGetlistRoles() || [],
  params: makePageRolesParams() || {},
  errorMessage: errorMessageRoles() || '',
  permissionsUser: makeGetPermissionsUserManagement() || {},
});

const withConnect = connect(mapStateToProps, {
  getRoleGroupById,
  searchRoles,
  setParamsRoles,
  modifyRoleGroup,
  searchAllRoles,
});

export default withTranslation('common')(compose(withConnect)(PricingItemEdit));

// export default PricingItemEdit;
