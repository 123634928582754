import gql from 'graphql-tag';

export default ({ folioType }) => {
  const getFolioSummaryDashboard = `getFolioSummaryDashboard(folioType: ${folioType})`;
  return gql`
    query {
      ${getFolioSummaryDashboard} {
        mon
        folioTypeData {
          value
          folioType
        }
      }
    }
  `;
};
