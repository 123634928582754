import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['gatewayName', 'apiName', 'requestFlag', 'index', 'level0Flag']);

  const createGatewayApiMapping = `createGatewayApiMapping(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createGatewayApiMapping} {
        id
      }
    }
  `;
};
