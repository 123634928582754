import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default payload => {
  let searchParams = '';
  if (payload) searchParams = `company: "${payload}"`;
  if (payload && typeof payload === 'object') {
    searchParams = convertFilterRequestUrl(payload, []);
    return gql`
    query {
      getUniqueProductType(input: ${searchParams} ) {
        productType
      }
    }
  `;
  }
  return gql`
  query {
    getUniqueProductType(input: { ${searchParams} }) {
      productType
    }
  }
`;
};
