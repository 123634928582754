import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'status',
    'index',
    'costType',
  ]);
  const modifyCostCenter = `modifyCostCenter(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyCostCenter} {
      id
    }
  }
`;
};
