import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getRegR00502Report = `getRegR00502Report(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRegR00502Report} {
        C601
        C602
        C002
        C102
        C205
        C309
        R0050201
      }
    }
  `;
};
