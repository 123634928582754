import React, { useState, useRef, useEffect } from 'react';
import PropsType from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getUsageContainerSpecConfig, getCcpDateTimeConfig } from '../../../App/actions';
import { GenericInput, TitleFrom, DataTable } from '../../../../components/common';
import { validate, getLastIndex } from '../../../../utils/utils';
import { makeGetCcpTime } from '../../../App/selectors';

const listFields = [
  {
    label: 'label.serviceType',
    name: 'serviceType',
    type: 'select',
    tOptions: 'selections:serviceType',
    required: true,
  },
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.zoneType',
    name: 'zoneType',
    type: 'select',
    tOptions: 'selections:zoneType',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
  },
  {
    label: 'label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:genericStatus',
    required: true,
  },
];

const ZoneUnitForm = ({
  defaultData,
  getUsageContainerSpecConfig,
  isCreate,
  onSubmit,
  ccpTime,
  getCcpDateTimeConfig,
  isDisabledSave,
  isSupportScroll,
}) => {
  const { t } = useTranslation('common');

  const [data, setData] = useState({ zones: [] });
  const [wasValidated, setValidate] = useState(false);
  const [usageContainerSpecOptions, setUsageContainerSpecOptions] = useState([]);
  const formRef = useRef();

  const onRemoveUser = ({ index }) => {
    const newData = cloneDeep(data);
    if (newData.zones[index] && newData.zones[index].isNew) {
      newData.zones.splice(index, 1);
    } else {
      newData.zones[index] = { index: newData.zones[index].index };
    }
    setData(newData);
  };

  const onCancel = () => {
    setData(defaultData);
  };

  const onChangeTable = ({ name, value, index }) => {
    const newData = cloneDeep(data);
    try {
      newData.zones[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const headerTable = [
    {
      name: 'originField',
      label: 'label.originField',
      required: true,
      style: { minWidth: '190px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.originField}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="originField"
          type="select"
          options={usageContainerSpecOptions}
          required
          menuPortalTarget
        />
      ),
    },
    {
      name: 'originValue',
      label: 'label.originValue',
      style: { minWidth: '180px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.originValue}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="originValue"
          required
        />
      ),
    },
    {
      name: 'destinationField',
      label: 'label.destinationField',
      style: { minWidth: '190px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.destinationField}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="destinationField"
          type="select"
          options={usageContainerSpecOptions}
          menuPortalTarget
          required
        />
      ),
    },
    {
      name: 'destinationValue',
      label: 'label.destinationValue',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.destinationValue}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="destinationValue"
          required
        />
      ),
    },
    {
      name: 'zoneUnit',
      label: 'label.name',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.zoneUnit}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="zoneUnit"
          required
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.startDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="startDate"
          type="date"
          required
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.endDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="endDate"
          type="date"
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, index) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveUser({ index })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(data);
    if (name === 'name' && newData.name === newData.description && isCreate) {
      newData.description = value;
    }
    newData[name] = value;

    setData(newData);
  };

  const onHandleAddZone = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.zones) newData.zones = [];
    newData.zones.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    let itemCopy = null;
    if (newData.zones && newData.zones.length) {
      itemCopy = newData.zones[0];
    }
    newData.zones = [
      {
        index: lastIndex + 1,
        originField: itemCopy ? itemCopy.originField || null : null,
        destinationField: itemCopy ? itemCopy.destinationField || null : null,
        distanceField: null,
        description: null,
        originValue: null,
        destinationValue: null,
        distanceValue: null,
        zoneUnit: null,
        startDate: ccpTime || null,
        endDate: null,
        isNew: true,
      },
      ...newData.zones,
    ];
    setData(newData);
  };

  const onHandleSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    const newPayload = cloneDeep(data);
    if (!isCreate && newPayload.zones) {
      const existData = newPayload.zones.filter(val => !val.isNew);
      const newData = newPayload.zones
        .filter(val => val.isNew)
        .map((item, index) => {
          const { isNew, ...rest } = item;
          return { ...rest, index: getLastIndex({ data: existData }) + index };
        });

      newPayload.zones = [...existData, ...newData];
    }
    if (isCreate && newPayload.zones) {
      newPayload.zones = newPayload.zones.map(val => {
        const { isNew, index, ...rest } = val;
        return rest;
      });
    }
    setValidate(false);
    return onSubmit(newPayload);
  };

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    getUsageContainerSpecConfig(true, ({ success, data }) => {
      if (success) {
        let usageContainerSpecOptions = [];
        if (data && data.length) {
          const listSelect = data.find(val => val.recordType === 'DETAIL');
          if (listSelect && listSelect.attributes && listSelect.attributes.length) {
            usageContainerSpecOptions = listSelect.attributes.map(val => ({
              label: val.attributeName,
              value: val.attributeName,
            }));
          }
        }
        setUsageContainerSpecOptions(usageContainerSpecOptions);
      }
    });
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
  }, [ccpTime, getCcpDateTimeConfig, getUsageContainerSpecConfig]);

  let newListFileds = cloneDeep(listFields);
  if (!isCreate) {
    newListFileds = [
      {
        label: 'label.id',
        name: 'id',
        readOnly: true,
      },
      ...newListFileds,
    ];
  }
  return (
    <form
      ref={formRef}
      noValidate
      className={`col-md-12 p-2 card card-statistics needs-validation ${wasValidated ? 'was-validated' : ''}`}
      onSubmit={onHandleSubmit}
    >
      <div className="d-block d-md-flex">
        <div className="d-block d-md-flex clearfix sm-mt-20 ">
          <TitleFrom title={t(isCreate ? 'label.zoneUnitAdd' : 'label.zoneUnitEdit')} />
        </div>
      </div>
      <div className="col-md-12 p-2 row">
        {newListFileds.map(val => (
          <GenericInput
            key={val.name}
            value={data[val.name] || null}
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            {...val}
          />
        ))}
      </div>
      <br />
      <div className="form-group col-md-12 buttons-attibute">
        {!isDisabledSave && (
          <button type="button" className="button button-border black x-small" onClick={onHandleAddZone}>
            +
            {t('label.addNewZone')}
          </button>
        )}
        {!isDisabledSave && (
          <button disabled={isDisabledSave} type="submit" className="button button-border x-small float-right">
            {t(isCreate ? 'label.create' : 'label.saveConfig')}
          </button>
        )}
        <button type="button" onClick={onCancel} className="button button-border black x-small float-right">
          {t('label.cancel')}
        </button>
      </div>
      <div className="col-md-12">
        <DataTable
          columns={headerTable}
          data={data.zones || []}
          isSupportRemoveIndex
          isSupportScroll={isSupportScroll}
        />
      </div>
    </form>
  );
};

ZoneUnitForm.propTypes = {
  getUsageContainerSpecConfig: PropsType.func.isRequired,
  getCcpDateTimeConfig: PropsType.func.isRequired,
  defaultData: PropsType.objectOf(PropsType.any),
  isCreate: PropsType.bool,
  onSubmit: PropsType.func,
  ccpTime: PropsType.string,
  isDisabledSave: PropsType.bool,
};

ZoneUnitForm.defaultProps = {
  isCreate: false,
  onSubmit: () => {},
  defaultData: {},
  ccpTime: '',
  isDisabledSave: false,
};

const mapStateToProps = createStructuredSelector({
  ccpTime: makeGetCcpTime() || '',
});

export default connect(mapStateToProps, { getUsageContainerSpecConfig, getCcpDateTimeConfig })(ZoneUnitForm);
