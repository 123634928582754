import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class ParentSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    // {
    //   label: 'common:label.company',
    //   name: 'company',
    // },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusPricing',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.type',
      name: 'type',
      tOptions: 'selections:itemType',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

ParentSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ParentSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ParentSearchForm);
