import gql from 'graphql-tag';

export default fileName => {
  const getBanamexPaymentByFileName = `getBanamexPaymentByFileName(input: {fileName:"${fileName}"})`;
  return gql`
    query {
      ${getBanamexPaymentByFileName} {
        paymentRefId
        recordNumber
        recordDate
        paymentLimit
        reference1
        reference2
        paymentType
        branchOffice
        box
        amount
        authorizeCode
        identification
        accountId
        invoiceId
        status
        errorCode
        suspenseAccountFlag
        paymentId
        batchId
        createdDate
        processedDate
        fileName
        withdrawal
        balance
      }
    }
  `;
};
