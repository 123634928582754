import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { searchObjectApprovals } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import GenericInput from '../GenericInput';

class ModalSearchObjectApprovals extends Component {
  state = {};

  render() {
    const {
      t,
      defaultSearchParams,
      isOpen,
      id,
      searchObjectApprovals,
      onSelect,
      onCancel,
      selectedParentId,
    } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'groupId',
          label: 'common:label.groupId',
        },
        {
          name: 'groupName',
          label: 'common:label.groupName',
        },
        {
          name: 'userId',
          label: 'common:label.userId',
          sortName: 'name',
        },
        {
          name: 'firstName',
          label: 'common:label.firstName',
        },
        {
          name: 'lastName',
          label: 'common:label.lastName',
          sortName: 'lastName',
        },
        {
          name: 'dateRequested',
          label: 'common:label.dateRequested',
          sortName: 'dateRequested',
        },
        {
          name: 'dateProcessed',
          label: 'common:label.dateProcessed',
          sortName: 'dateProcessed',
        },
        {
          name: 'notes',
          label: 'label.notes',
          style: { minWidth: '250px' },
          render: (colName, item) => (
            <GenericInput
              value={item.notes}
              name="comment"
              onChange={({ name, value }) => {}}
              wrapperClass="col-md-12"
              type="textarea"
              readOnly
            />
          ),
        },
        {
          name: 'comments',
          label: 'common:label.comments',
          sortName: 'comments',
          style: { minWidth: '250px' },
          render: (colName, item) => (
            <GenericInput
              value={item.comments}
              name="comment"
              onChange={({ name, value }) => {}}
              wrapperClass="col-md-12"
              type="textarea"
              readOnly
            />
          ),
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={`${t('label.creditNoteData')} ${id}`}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={searchObjectApprovals}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        isNoNeedSelector
        isBack
      />
    );
  }
}

ModalSearchObjectApprovals.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  searchObjectApprovals: PropTypes.func,
};

ModalSearchObjectApprovals.defaultProps = {
  isOpen: false,
  onSelect: () => {},
  onCancel: () => {},
  selectedParentId: '',
  searchObjectApprovals: () => {},
};

export default withTranslation('common')(connect(null, { searchObjectApprovals })(ModalSearchObjectApprovals));
