import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput, FormCollapse } from '../common';
import dataField from '../../containers/TenantManagement/constant';
import {
  JsonWebTokenAttributeForm,
  OauthAttributesForm,
  HttpBasicAttributesForm,
  CustomerAttribute,
  GatewayAttributesForm,
  RequestResponseMapping,
} from '../../containers/TenantManagement/TenantDetail/SubForm';
// import { CustomerAttribute } from './SubForm';

const buttonsList = [
  {
    name: 'merchantData',
    label: 'tenantPage:tenantManagementPage.merchantData',
  },
  {
    name: 'customRecords',
    label: 'tenantPage:tenantManagementPage.customRecords',
  },
  {
    name: 'urlMapping',
    label: 'tenantPage:tenantManagementPage.urlMapping',
  },
  {
    name: 'apiMapping',
    label: 'label.apiMapping',
  },
];

class NewPaymentGateway extends React.PureComponent {
  state = { tenantData: [{}], activeTab: {} };

  componentDidMount() {
    const { data } = this.props;
    if (data.length > 0) this.setState({ tenantData: data });
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.data && nextProps.data.length > 0) {
  //     this.setState({ tenantData: nextProps.data });
  //   }
  // }

  onChange = ({ name, value, index, indexCreate }) => {
    const { onHandleChange, indexData } = this.props;
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    newData[index][name] = value;
    this.setState({ tenantData: newData });
    onHandleChange({ name, value, index: indexData, indexCreate });
  };

  onChangeAttributes = ({ name, value, index, indexAttr, fieldName = '', isNotIndex = false }) => {
    const { tenantData } = this.state;
    const { onChangeAttributes } = this.props;
    onChangeAttributes({ name, value, index, indexAttr, fieldName, isNotIndex });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onChangeSelectData = ({ name, index }) => {
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    newData[index].btnSelect = name;
    this.setState({ tenantData: newData });
  };

  render() {
    const { tenantData, activeTab } = this.state;
    const {
      modeModifyPaymentGatewayMerchant,
      isRequireField,
      modeModifyTaxGatewayMerchant,
      modeModifyFinanceGatewayMerchant,
      dataJwtAttributes,
      indexDataJwtAttributes,
      dataHttpBasicAttributes,
      indexDataHttpBasic,
      dataOauthAttributes,
      indexDataOauthAttributes,
      indexData,
      t,
      dataSelect,
      modifyTenant,
      getTenantDocumentGateway,
      id,
    } = this.props;

    // console.log('dataSelect', dataSelect);
    return (
      <div className="col-md-12 p-2 card-body">
        {tenantData.map((item, index) => (
          <FormCollapse
            key={item.index}
            label="First Name"
            isActive={activeTab && item.index === activeTab.name && activeTab.isActive}
            title={t(item.name) || ''}
            state={item.index}
            onToggleTab={this.onToggleTab}
          >
            <div className="col-md-12 text-center mt-2 mb-4">
              {buttonsList.map(val => (
                <button
                  type="button"
                  className={`button button-border ml-2 x-small ${item.btnSelect === val.name ? 'active' : ''}`}
                  onClick={() => this.onChangeSelectData({ name: val.name, index })}
                >
                  {t(val.label)}
                </button>
              ))}
            </div>
            {(item.btnSelect === 'merchantData' || !item.btnSelect) && (
              <div className={`col-md-12 group-flex mt-2 ${index > 0 ? 'geteway-group' : ''}`} key={index}>
                {dataField.fieldGetwayForm.map(field => {
                  if (field && (!isEmpty(field.options) || field.tOptions)) {
                    return (
                      <GenericInput
                        key={field.name}
                        label={field.label || ''}
                        required={isRequireField}
                        type="select"
                        value={item[field.name] || null}
                        wrapperClass="col-md-4"
                        disabled={field.disabled || modeModifyPaymentGatewayMerchant === 1}
                        onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                        name={field.name}
                        {...field}
                      />
                    );
                  }

                  if (field && field.isFieldDate) {
                    return (
                      <GenericInput
                        key={field.name}
                        label={field.label || ''}
                        type="date"
                        value={item[field.name] || null}
                        required={isRequireField}
                        disabled={modeModifyPaymentGatewayMerchant === 1}
                        wrapperClass="col-md-4"
                        onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                        name={field.name}
                      />
                    );
                  }

                  return (
                    <GenericInput
                      key={field.name}
                      label={field.label || ''}
                      value={item[field.name] || null}
                      required={isRequireField}
                      type={field.isNumber ? 'number' : 'text'}
                      disabled={modeModifyPaymentGatewayMerchant === 1}
                      onChange={({ name, value }) => this.onChange({ value, name, index })}
                      name={field.name}
                      wrapperClass="col-md-4"
                    />
                  );
                })}
                {!isEmpty(tenantData[index]) && item.authType === 'JSON_WEB_TOKEN' && (
                  <JsonWebTokenAttributeForm
                    onHandleChange={this.onChangeAttributes}
                    index={indexDataJwtAttributes}
                    isRequireField={isRequireField}
                    modeModifyPaymentGatewayMerchant={
                      modeModifyPaymentGatewayMerchant ||
                      modeModifyFinanceGatewayMerchant ||
                      modeModifyTaxGatewayMerchant
                    }
                    data={dataJwtAttributes || [{}]}
                  />
                )}

                {!isEmpty(tenantData[index]) && item.authType === 'OAUTH2' && (
                  <OauthAttributesForm
                    onHandleChange={this.onChangeAttributes}
                    index={indexDataOauthAttributes}
                    isRequireField={isRequireField}
                    modeModifyPaymentGatewayMerchant={
                      modeModifyPaymentGatewayMerchant ||
                      modeModifyFinanceGatewayMerchant ||
                      modeModifyTaxGatewayMerchant
                    }
                    data={dataOauthAttributes || [{}]}
                  />
                )}
                {!isEmpty(tenantData[index]) && item.authType === 'HTTP_BASIC' && (
                  <HttpBasicAttributesForm
                    onHandleChange={this.onChangeAttributes}
                    index={index}
                    isRequireField={isRequireField}
                    modeModifyPaymentGatewayMerchant={
                      modeModifyPaymentGatewayMerchant ||
                      modeModifyFinanceGatewayMerchant ||
                      modeModifyTaxGatewayMerchant
                    }
                    data={item.httpBasicAttributes || [{}]}
                  />
                )}

                <div className="mt-3 mb-3 col-md-12">
                  <button type="button" className="button button-border ml-2 x-small float-right">
                    {t('label.saveConfig')}
                  </button>
                  <button
                    type="button"
                    onClick={() => this.doGetTenantMerchantDataConfig()}
                    className="button button-border black x-small float-right"
                  >
                    {t('label.cancel')}
                  </button>
                </div>
              </div>
            )}
            {item.btnSelect === 'customRecords' && <CustomerAttribute />}
            {item.btnSelect === 'urlMapping' && (
              <GatewayAttributesForm
                // data={tenantData.tenantMerchantAccounts}
                modifyTenant={modifyTenant}
                getTenantConfig={getTenantDocumentGateway}
                // id={id || ''}
                gatewayKey="documentGatewayAttributes"
                gatewayType="DOCUMENT_GATEWAY"
                tOptionsConfig="selections:documentGatewayUrlType"
                titleForm={t('label.documentGatewayURLMapping')}
              />
            )}
          </FormCollapse>
        ))}
        {/* {tenantData.map((item, index) => (
          <div className={`col-md-12 group-flex mt-2 ${index > 0 ? 'geteway-group' : ''}`} key={index}>
            {dataField.fieldGetwayForm.map(field => {
              if (field && (!isEmpty(field.options) || field.tOptions)) {
                return (
                  <GenericInput
                    key={field.name}
                    label={field.label || ''}
                    required={isRequireField}
                    type="select"
                    value={item[field.name] || null}
                    wrapperClass="col-md-4"
                    disabled={field.disabled || modeModifyPaymentGatewayMerchant === 1}
                    onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                    name={field.name}
                    {...field}
                  />
                );
              }

              if (field && field.isFieldDate) {
                return (
                  <GenericInput
                    key={field.name}
                    label={field.label || ''}
                    type="date"
                    value={item[field.name] || null}
                    required={isRequireField}
                    disabled={modeModifyPaymentGatewayMerchant === 1}
                    wrapperClass="col-md-4"
                    onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                    name={field.name}
                  />
                );
              }

              return (
                <GenericInput
                  key={field.name}
                  label={field.label || ''}
                  value={item[field.name] || null}
                  required={isRequireField}
                  type={field.isNumber ? 'number' : 'text'}
                  disabled={modeModifyPaymentGatewayMerchant === 1}
                  onChange={({ name, value }) => this.onChange({ value, name, index })}
                  name={field.name}
                  wrapperClass="col-md-4"
                />
              );
            })}
            {!isEmpty(tenantData[index]) && item.authType === 'JSON_WEB_TOKEN' && (
              <JsonWebTokenAttributeForm
                onHandleChange={this.onChangeAttributes}
                index={indexDataJwtAttributes}
                isRequireField={isRequireField}
                modeModifyPaymentGatewayMerchant={
                  modeModifyPaymentGatewayMerchant || modeModifyFinanceGatewayMerchant || modeModifyTaxGatewayMerchant
                }
                data={dataJwtAttributes || [{}]}
              />
            )}

            {!isEmpty(tenantData[index]) && item.authType === 'OAUTH2' && (
              <OauthAttributesForm
                onHandleChange={this.onChangeAttributes}
                index={indexDataOauthAttributes}
                isRequireField={isRequireField}
                modeModifyPaymentGatewayMerchant={
                  modeModifyPaymentGatewayMerchant || modeModifyFinanceGatewayMerchant || modeModifyTaxGatewayMerchant
                }
                data={dataOauthAttributes || [{}]}
              />
            )}
            {!isEmpty(tenantData[index]) && item.authType === 'HTTP_BASIC' && (
              <HttpBasicAttributesForm
                onHandleChange={this.onChangeAttributes}
                index={indexDataHttpBasic}
                isRequireField={isRequireField}
                modeModifyPaymentGatewayMerchant={
                  modeModifyPaymentGatewayMerchant || modeModifyFinanceGatewayMerchant || modeModifyTaxGatewayMerchant
                }
                data={dataHttpBasicAttributes || [{}]}
              />
            )}
          </div>
        ))} */}
      </div>
    );
  }
}

NewPaymentGateway.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onHandleChange: PropTypes.func,
  onChangeAttributes: PropTypes.func,
  isRequireField: PropTypes.bool,
  modeModifyPaymentGatewayMerchant: PropTypes.number,
};

NewPaymentGateway.defaultProps = {
  data: [{}],
  onHandleChange: () => {},
  onChangeAttributes: () => {},
  isRequireField: false,
  modeModifyPaymentGatewayMerchant: 2,
};

export default withTranslation('common')(NewPaymentGateway);
