import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import {
  uploadMultiPartFiles,
  getCcpPropertiesConfig,
  saveOutputTemplateByType,
  getOutputTemplateByType,
} from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { AccountingReconciliationSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  ModalWithItem,
  DragDropConfigExportTemplate,
} from '../../../../components/common';
import { sortAccountingReconciliation } from '../../constants';
import { getAccountingReconciliation, getAllAccountingReconciliation } from '../../actions';
import { makeGetListAccountingReconciliation, makeGetListAllAccountingReconciliation } from '../../selectors';
import {
  getPageTotalCount,
  formatMoneyValue,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let tableColumnsOutput = null;
let tableColumnsDefault = null;
class AccountingReconciliation extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listAccountingReconciliation: [],
    primaryTaxType: '',
    addOnTaxType: '',
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listAccountingReconciliation: props.listAccountingReconciliation,
      totalCount: getPageTotalCount({ ...state, items: props.listAccountingReconciliation }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetAccountingReconciliationReport },
      getCcpPropertiesConfig,
    } = this.props;
    if (modeGetAccountingReconciliationReport) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          let primaryTaxType = '';
          let addOnTaxType = '';
          const primaryTaxTypeConfig = data.ccpPropertyList.find(val => val.property === 'primaryTaxType');
          const addOnTaxTypeConfig = data.ccpPropertyList.find(val => val.property === 'addOnTaxType');
          if (primaryTaxTypeConfig && primaryTaxTypeConfig.value) {
            primaryTaxType = primaryTaxTypeConfig.value;
          }
          if (addOnTaxTypeConfig && addOnTaxTypeConfig.value) {
            addOnTaxType = addOnTaxTypeConfig.value;
          }
          this.setState({
            primaryTaxType,
            addOnTaxType,
          });
        }
      });
      this.doGetReportAccountingReconciliation();
      this.doGetOutputTemplateByType();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetReportAccountingReconciliation();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetReportAccountingReconciliation());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportAccountingReconciliation());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetReportAccountingReconciliation();
    });
  };

  doGetReportAccountingReconciliation = () => {
    const { filter, page, size, sorted } = this.state;
    const { getAccountingReconciliation } = this.props;
    const newFilter = cloneDeep(filter);
    if (newFilter.extractBatch) newFilter.extractBatch = newFilter.extractBatch === 'TRUE' ? 'true' : 'false';
    if (newFilter.reconcileBatch) newFilter.reconcileBatch = newFilter.reconcileBatch === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort:
        !isEmpty(sorted) && sortAccountingReconciliation[sorted.sortCol]
          ? sortAccountingReconciliation[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getAccountingReconciliation(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistAccountingReconciliation = cb => {
    const { filter, sorted } = this.state;
    const { getAllAccountingReconciliation } = this.props;
    const newFilter = cloneDeep(filter);
    if (newFilter.extractBatch) newFilter.extractBatch = newFilter.extractBatch === 'TRUE' ? 'true' : 'false';
    if (newFilter.reconcileBatch) newFilter.reconcileBatch = newFilter.reconcileBatch === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortAccountingReconciliation[sorted.sortCol][sorted.sortDir] : null,
    };
    getAllAccountingReconciliation(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistAccountingReconciliation(() => {
      const { isActivePdf, addOnTaxType, primaryTaxType } = this.state;
      const { t } = this.props;
      const labelAddOnTaxType = `${addOnTaxType || ''} ${t('label.taxAmount')}`;
      const labelPrimaryTaxType = `${primaryTaxType || ''} ${t('label.taxAmount')}`;
      if (isActivePdf) {
        const { listAllAccountingReconciliation, t } = this.props;
        convertJson2Pdf({
          data: listAllAccountingReconciliation,
          t,
          title: t('reportPage:sidebar.accountingReconciliationReport').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.accountingReconciliationReport')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          labelAddOnTaxType,
          labelPrimaryTaxType,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistAccountingReconciliation(() => {
      const { listAllAccountingReconciliation, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf, addOnTaxType, primaryTaxType } = this.state;
      const labelAddOnTaxType = `${addOnTaxType || ''} ${t('label.taxAmount')}`;
      const labelPrimaryTaxType = `${primaryTaxType || ''} ${t('label.taxAmount')}`;
      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_ACCOUNTING_RECONCILIATION_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.accountingReconciliationReport')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.accountingReconciliationReport')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllAccountingReconciliation,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              extractStatus: 'ebsExtractStatus',
              matchStatus: 'matchStatus',
              objectType: 'ebsExtractType',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllAccountingReconciliation,
          t,
          title: t('reportPage:sidebar.accountingReconciliationReport').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.accountingReconciliationReport')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          labelAddOnTaxType,
          labelPrimaryTaxType,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.accountingReconciliationReport')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('ACCOUNTING_RECONCILIATION', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listAccountingReconciliation,
      primaryTaxType,
      addOnTaxType,
      emails,
      isOpenModalExportConfig,
      exportColumnsSave,
    } = this.state;
    const {
      listAllAccountingReconciliation,
      t,
      permissions: { modeGetAccountingReconciliationReport, modeDownloadReport, modeGetMailReport },
      isRevenue,
    } = this.props;
    if (!modeGetAccountingReconciliationReport) return '';
    const labelAddOnTaxType = `${addOnTaxType || ''} ${t('label.taxAmount')}`;
    const labelPrimaryTaxType = `${primaryTaxType || ''} ${t('label.taxAmount')}`;
    // console.log('addOnTaxType, primaryTaxType', addOnTaxType, primaryTaxType);

    const tabelColumnAccountingReconciliation = [
      {
        name: 'extractBatch',
        label: 'label.extractBatch',
        sortable: true,
      },
      {
        name: 'extractDate',
        label: 'label.extractDate',
        sortable: true,
      },
      {
        name: 'extractStatus',
        label: 'label.extractStatus',
        fieldOption: 'ebsExtractStatus',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:ebsExtractStatus')().find(val => val.value === item.extractStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'matchStatus',
        label: 'label.matchStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:matchStatus')().find(val => val.value === item.matchStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'company',
        label: 'label.organization',
        sortable: true,
      },
      {
        name: 'accountId',
        label: 'label.accountId',
        sortable: true,
      },
      {
        name: 'billUnitId',
        label: 'label.billUnitId',
        sortable: true,
      },
      {
        name: 'objectId',
        label: 'label.objectId',
        sortable: true,
      },
      {
        name: 'objectType',
        label: 'label.objectType',
        fieldOption: 'ebsExtractType',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:ebsExtractType')().find(val => val.value === item.objectType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'objectDate',
        label: 'label.objectDate',
        sortable: true,
      },
      {
        name: 'totalSummary',
        label: 'label.totalSummary',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.totalSummary)}</span>;
        },
      },
      {
        name: 'addonTaxAmount',
        label: labelAddOnTaxType,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.addonTaxAmount)}</span>;
        },
      },
      {
        name: 'mainTaxAmount',
        label: labelPrimaryTaxType,
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.mainTaxAmount)}</span>;
        },
      },
      {
        name: 'total',
        label: 'label.total',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.total)}</span>;
        },
      },
      {
        name: 'journalTotal',
        label: 'label.journalTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.journalTotal)}</span>;
        },
      },
      {
        name: 'accountingLogTotal',
        label: 'label.accountingLogTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.accountingLogTotal)}</span>;
        },
      },
      {
        name: 'batchAmount',
        label: 'label.batchAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.batchAmount)}</span>;
        },
      },
      {
        name: 'folioId',
        label: 'label.folioId',
      },
    ];

    tableColumnsDefault = tabelColumnAccountingReconciliation;
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={isRevenue ? RouteNames.accountingLog.path : RouteNames.report.path}
          titleBtn="back"
          items={
            isRevenue
              ? [{ name: t('reportPage:sidebar.accountingReconciliationReport') }]
              : [
                  { name: t('label.report'), url: RouteNames.report.path },
                  { name: t('reportPage:sidebar.accountingReconciliationReport') },
                ]
          }
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.accountingReconciliationReport')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <AccountingReconciliationSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div className={isRevenue ? 'mt-5' : ''}>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  {/* {!isRevenue && ( */}
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config ml-2"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {/* )} */}
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.accountingReconciliationReport').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllAccountingReconciliation,
                          t,
                          labelAddOnTaxType,
                          labelPrimaryTaxType,
                          title: t('reportPage:sidebar.accountingReconciliationReport').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.accountingReconciliationReport')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && !isRevenue && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listAccountingReconciliation}
                    />
                  )}
                </div>
                <DataTable
                  columns={tableColumnsOutput}
                  data={
                    listAccountingReconciliation && listAccountingReconciliation.length
                      ? listAccountingReconciliation
                      : []
                  }
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

AccountingReconciliation.propTypes = {
  getAccountingReconciliation: PropTypes.func,
  getAllAccountingReconciliation: PropTypes.func,
  listAllAccountingReconciliation: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
};

AccountingReconciliation.defaultProps = {
  getAccountingReconciliation: () => {},
  getAllAccountingReconciliation: () => {},
  listAllAccountingReconciliation: [],
};

const mapStateToProps = createStructuredSelector({
  listAccountingReconciliation: makeGetListAccountingReconciliation() || [],
  listAllAccountingReconciliation: makeGetListAllAccountingReconciliation() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getAccountingReconciliation,
    getAllAccountingReconciliation,
    getCcpPropertiesConfig,
    uploadMultiPartFiles,
    saveOutputTemplateByType,
    getOutputTemplateByType,
  })(AccountingReconciliation)
);
