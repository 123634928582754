import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['accountType', 'paymentMethod', 'noOfDays', 'index', 'surcharge']);
  const modifyConfigPaymentSurcharge = `modifyConfigPaymentSurcharge(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigPaymentSurcharge} {
      id
    }
  }
`;
};
