import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ButtonExportCSV = ({ onExport, isExport, btnTitle, ...rest }) => {
  const { t } = useTranslation('common');
  return (
    <button
      type="button"
      id="embrix-btn-export"
      className="button x-small mr-3 mt-0 float-right"
      onClick={onExport}
      {...rest}
    >
      <i className="fa fa-download mr-2" />
      {isExport ? t('label.export') : t(btnTitle) || t('label.download')}
    </button>
  );
};

ButtonExportCSV.propTypes = {
  onExport: PropTypes.func,
  isExport: PropTypes.bool,
};

ButtonExportCSV.defaultProps = {
  onExport: () => {},
  isExport: false,
};

export default ButtonExportCSV;
