import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['dueThreshold', 'billType', 'accountType', 'status'])}`
    : '';
  const getBillInvoiceReconcileReport = `getBillInvoiceReconcileReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBillInvoiceReconcileReport} {
        billId
        startDate
        endDate
        invoiceId
        invoiceDate
        dueDate
        accountId
        firstName
        lastName
        total
        nonPayingTotal
        billTotal
        due
        accountType
        billType
        status
        company
      }
    }
  `;
};
