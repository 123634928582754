import gql from 'graphql-tag';

export default id => gql`
  query {
    getUnbilledTransactionSummary(accountId: "${id}") {
      index
      name
      quantity
      unitPrice
      amount
      scale
      discount
      gross
      appliedStart
      appliedEnd
      itemId
      taxCode
      transactionId
    }
  }
`;
