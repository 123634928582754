import gql from 'graphql-tag';

export default id => gql`
  query {
    getUsedCfdiByTaxRegion(dummy: "${id}") {
      usedCfdi
      usedCfdiName
    }
  }
`;
