import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep, remove, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CustomerNoForm from './CustomerNoForm';
import { validate } from '../../utils/utils';

class FormAddNewContact extends React.PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      accountContact: { roles: ['BILLING'], phones: [{ type: 'HOME', number: '' }] },
      wasValidated: false,
      isShowPhones: false,
    };
  }

  onHandleChange = ({ value = '', name = '' }) => {
    const { accountContact } = this.state;
    accountContact[name] = value;
    this.setState({ ...accountContact });
  };

  onChangeRole = name => {
    const { accountContact } = this.state;
    const newData = cloneDeep(accountContact);
    if (newData.roles.indexOf(name) > -1) {
      remove(newData.roles, item => item === name);
    } else {
      newData.roles.push(name);
    }
    if (newData.roles.length === 0) return '';
    return this.setState({ accountContact: newData });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { accountContact, isShowPhones } = this.state;
    const { onAddNewContact, t } = this.props;
    // if (!accountContact.id) return toast.error(t('message.mandatory'));
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });

    return onAddNewContact({ ...accountContact, isShowPhones });
  };

  onChangePhone = ({ value, fieldName, index, isNewPhone = false, isRemovePhone = false }) => {
    const { accountContact } = this.state;
    const newData = cloneDeep(accountContact);
    if (isNewPhone) {
      const typePhoneArr = ['MOBILE', 'WORK', 'HOME', 'FAX'];
      map(newData.phones, item => {
        const index = typePhoneArr.indexOf(item.type);
        typePhoneArr.splice(index, 1);
      });
      if (isEmpty(typePhoneArr)) {
        return toast('No unique type phone remaining', { type: toast.TYPE.ERROR });
      }
      newData.phones[newData.phones.length] = {
        number: null,
        type: typePhoneArr[0],
      };
    }
    if (isRemovePhone) {
      if (newData.phones.length > 1) {
        newData.phones.splice(index, 1);
      } else {
        toast('Phones can not empty', { type: toast.TYPE.ERROR });
      }
    }
    if (!isEmpty(newData.phones) && !isNewPhone && !isRemovePhone) {
      newData.phones[index][fieldName] = value;
    }
    return this.setState({ accountContact: newData });
  };

  render() {
    const {
      dataField,
      checkRoleGroup,
      isDisabled,
      onToggleModal,
      modeModifyPhone,
      modeAddPhone,
      modeDeletePhone,
      t,
    } = this.props;
    const { accountContact, wasValidated, isShowPhones } = this.state;
    return (
      <form
        onSubmit={this.onHandleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        ref={this.formRef}
        noValidate
      >
        <CustomerNoForm
          colItem="col-md-4"
          dataField={dataField}
          accountDetail={accountContact}
          onHandleChange={this.onHandleChange}
          // wasValidated={wasValidated}
          checkRoleGroup={checkRoleGroup}
          onChangePhone={this.onChangePhone}
          onChangeCheckBox={this.onChangeRole}
          isDisabled={isDisabled}
          modeModifyPhone={modeModifyPhone}
          modeAddPhone={modeAddPhone}
          modeDeletePhone={modeDeletePhone}
          isShowPhones={isShowPhones}
          onChangeIsShowPhone={() => this.setState({ isShowPhones: !isShowPhones })}
          t={t}
          isLeftAddPhoneBtn
          colDivPhone="col-md-8"
          isRequiredPhoneNumber
        />
        <div className="form-group col-md-12 action-buttons modal-new-item">
          <button type="submit" className="button x-small float-right">
            {t('label.addNewContact')}
          </button>
          <button type="button" className="button x-small float-right" onClick={onToggleModal}>
            {t('label.cancel')}
          </button>
        </div>
      </form>
    );
  }
}

FormAddNewContact.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  onAddNewContact: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  checkRoleGroup: PropTypes.arrayOf(PropTypes.object),
  modeModifyPhone: PropTypes.number,
  modeAddPhone: PropTypes.number,
  modeDeletePhone: PropTypes.number,
};

FormAddNewContact.defaultProps = {
  dataField: [],
  checkRoleGroup: [],
  isDisabled: false,
  modeModifyPhone: 2,
  modeAddPhone: 2,
  modeDeletePhone: 2,
};

export default withTranslation('common')(FormAddNewContact);
