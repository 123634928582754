import { toast } from 'react-toastify';

import jobClient from 'config/jobScheduleClient';

// export function mutationRequestJob(mutation) {
//   return jobSchedule
//     .mutate({
//       mutation,
//     })
//     .then(data => {
//       if (data.errors) {
//         return Promise.reject(data.errors[0].message);
//       }
//       return Promise.resolve(data.data);
//     })
//     .catch(err => Promise.reject(err));
// }

// import client from 'config/ApolloClient';
// import { toast } from 'react-toastify';

export function queryRequest(query) {
  return jobClient
    .query({
      query,
    })
    .then(data => {
      if (data.errors) {
        const errorMessage =
          data.graphQLErrors && data.graphQLErrors.length > 0
            ? data.graphQLErrors[0].extensions.errorMessage
            : data.graphQLErrors[0].errorMessage;
        const errorMessage2 = data.graphQLErrors && data.graphQLErrors.length > 0 && data.graphQLErrors[0].message;
        toast.error(errorMessage || errorMessage2);
        return Promise.reject(new Error({ message: errorMessage }));
      }
      return Promise.resolve(data.data);
    })
    .catch(err => {
      const errorMessage =
        err.graphQLErrors && err.graphQLErrors.length > 0
          ? err.graphQLErrors[0].extensions.errorMessage
          : err.graphQLErrors[0].errorMessage;

      const errorMessage2 = err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message;
      toast.error(errorMessage || errorMessage2);
      return Promise.reject(err);
    });
}

export function mutationRequest(mutation) {
  return jobClient
    .mutate({
      mutation,
    })
    .then(data => {
      if (data.errors) {
        const errorMessage =
          data.graphQLErrors && data.graphQLErrors.length > 0
            ? data.graphQLErrors[0].extensions.errorMessage
            : data.graphQLErrors[0].errorMessage;
        const errorMessage2 = data.graphQLErrors && data.graphQLErrors.length > 0 && data.graphQLErrors[0].message;
        toast.error(errorMessage || errorMessage2);
        return Promise.reject(new Error({ message: errorMessage }));
      }
      return Promise.resolve(data.data);
    })
    .catch(err => {
      const errorMessage =
        err.graphQLErrors && err.graphQLErrors.length > 0
          ? err.graphQLErrors[0].extensions.errorMessage
          : err.graphQLErrors[0].errorMessage;

      const errorMessage2 = err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message;
      toast.error(errorMessage || errorMessage2);
      return Promise.reject(err);
    });
}
