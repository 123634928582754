import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, remove, cloneDeep, union, size, findLastIndex, map } from 'lodash';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import CustomerNoForm from '../CustomerNoForm';
import { ModalWithItem, FormCollapse, GenericInput } from '../../common';
import { getAddressesForm, messageRoleBilling, validate } from '../../../utils/utils';
import FormAddNewAddress from '../FormAddNewAddress';
import ModalAccept from '../ModalAccept';
import { additionalAddressInformation, collapsibleAddress } from '../../../containers/CustomerPage/constants';
import CheckFieldRole from '../CheckFieldRole';

class MultiAddressForm extends React.PureComponent {
  state = {
    accountAddress: [],
    dataSubmit: [],
    wasValidated: false,
    isOpenModalNewAddress: false,
    isOpenModalDeleteAddress: false,
    isModify: false,
    indexDeleteAddress: 0,
    landmarkLabel: '',
    extralineLabel: '',
    activeTab: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    const { data, getCcpPropertiesConfig } = this.props;
    if (data) {
      this.setState({ accountAddress: data });
    }

    getCcpPropertiesConfig('', ({ success, data }) => {
      if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
        const landmarkLabelProperty = data.ccpPropertyList.find(val => val.property === 'landmarkLabel');
        const extralineLabelProperty = data.ccpPropertyList.find(val => val.property === 'extralineLabel');
        this.setState({
          landmarkLabel: landmarkLabelProperty && landmarkLabelProperty.value ? landmarkLabelProperty.value : '',
          extralineLabel: extralineLabelProperty && extralineLabelProperty.value ? extralineLabelProperty.value : '',
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ accountAddress: nextProps.data });
    }
  }

  onToggleModalNewAddress = () => {
    const { isOpenModalNewAddress } = this.state;
    this.setState({ isOpenModalNewAddress: !isOpenModalNewAddress });
  };

  onToggleModalDeleteAddress = index => {
    const { isOpenModalDeleteAddress } = this.state;
    this.setState({ isOpenModalDeleteAddress: !isOpenModalDeleteAddress, indexDeleteAddress: index });
  };

  onDeleteAddress = () => {
    const { accountAddress, indexDeleteAddress, dataSubmit } = this.state;
    const newData = cloneDeep(accountAddress);
    dataSubmit[indexDeleteAddress] = { id: accountAddress[indexDeleteAddress].id };
    newData[indexDeleteAddress] = { id: accountAddress[indexDeleteAddress].id };
    this.setState({ accountAddress: newData, isModify: true });
  };

  onHandleChange = ({ value = '', name = '', index }) => {
    const { accountAddress, dataSubmit } = this.state;
    const newData = cloneDeep(accountAddress);
    let { isModify } = this.state;
    newData[index][name] = value;
    if (!dataSubmit[index]) dataSubmit[index] = {};
    dataSubmit[index][name] = value;
    if (value) isModify = true;
    this.setState({ accountAddress: newData, ...dataSubmit, isModify });
  };

  onChangeRole = (name, index) => {
    const { accountAddress, dataSubmit } = this.state;
    const newData = cloneDeep(accountAddress);
    if (newData[index].roles.indexOf(name) > -1) {
      remove(newData[index].roles, item => item === name);
    } else {
      newData[index].roles.push(name);
    }
    if (!dataSubmit[index] || isEmpty(dataSubmit[index][name])) dataSubmit[index] = {};
    dataSubmit[index].roles = newData[index].roles;
    return this.setState({ accountAddress: newData, dataSubmit, isModify: true });
  };

  onAddNewAddress = item => {
    const { accountAddress, dataSubmit } = this.state;
    const newData = cloneDeep(accountAddress);
    newData.push(item);
    dataSubmit[newData.length - 1] = newData[newData.length - 1];
    this.setState({ accountAddress: newData, isOpenModalNewAddress: false, dataSubmit, isModify: true });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit, accountAddress } = this.state;
    const { modifyAccount, id, t, doGetAccountDetails } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });
    const newDataSubmit = [];
    const messageRoleAddress = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountAddress.map(item => item.roles)
        )
      ),
    });
    if (!isEmpty(dataSubmit)) {
      for (let i = 0; i < dataSubmit.length; i++) {
        if (!isEmpty(dataSubmit[i])) {
          newDataSubmit.push(dataSubmit[i]);
          newDataSubmit[newDataSubmit.length - 1].id = accountAddress[i].id || null;
        }
      }
    }
    const newPayload = {
      id,
      addresses: newDataSubmit,
    };
    if (messageRoleAddress) return toast.error(t('message.mandatory'));
    this.setState({ wasValidated: true });
    return modifyAccount(newPayload, ({ success }) => {
      if (success) {
        doGetAccountDetails();
      }
      this.setState({ wasValidated: false });
    });
  };

  onToggleAddressTab = (activeTabName, index) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && activeTab.index === index) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive, index },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true, index } });
    }
  };

  render() {
    const {
      id,
      checkRoleGroup,
      isDisabled,
      modifyAccount,
      isDisableSubmit,
      modeAddAddress,
      modeDeleteAddress,
      t,
      doGetAccountDetails,
      currencyOptions,
      customerInfo,
    } = this.props;
    const {
      accountAddress,
      wasValidated,
      isOpenModalNewAddress,
      isOpenModalDeleteAddress,
      isModify,
      landmarkLabel,
      extralineLabel,
      activeTab,
    } = this.state;
    if (isEmpty(accountAddress)) return '';
    // console.log('asdsad', landmarkLabel, extralineLabel);
    const messageRoleAddress = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountAddress.map(item => item.roles)
        )
      ),
    });
    const isDisableDelete = accountAddress.filter(item => size(item) > 2).length < 2;
    const indexRenderButton = findLastIndex(accountAddress, item => size(item) > 2);
    return (
      <form
        onSubmit={this.onHandleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        ref={this.formRef}
        noValidate
      >
        {accountAddress.map((item, index) => {
          if (size(item) < 2) return null;
          let dataFields = getAddressesForm({
            country: accountAddress[index].country,
            state: accountAddress[index].state,
            city: accountAddress[index].city,
            landmarkLabel,
            extralineLabel,
            isSupportRequired: true,
            isFromAccount: true,
          });
          const additionalFields = dataFields.filter(val => ['extraLine', 'landmark'].includes(val.fieldName));
          dataFields = dataFields.filter(val => !['extraLine', 'landmark'].includes(val.fieldName));

          return (
            <CustomerNoForm
              key={index}
              colItem="col-md-4"
              dataField={dataFields}
              title={!index ? t('label.addresses') : ''}
              accountNum={!index ? id : ''}
              accountDetail={item}
              onHandleChange={({ name, value }) => this.onHandleChange({ name, value, index })}
              onHandleSubmit={this.onHandleSubmit}
              wasValidated={wasValidated}
              // checkRoleGroup={checkRoleGroup}
              // onChangeCheckBox={name => this.onChangeRole(name, index)}
              isDisabled={isDisabled}
              // messageRole={messageRoleAddress}
              t={t}
              currencyOptions={currencyOptions}
              customerInfo={customerInfo}
            >
              <div className="col-md-12">
                {collapsibleAddress.map(cl => {
                  return (
                    <FormCollapse
                      key={cl.title}
                      isActive={
                        activeTab && cl.state === activeTab.name && activeTab.isActive && activeTab.index === index
                      }
                      title={cl.title}
                      state={cl.state}
                      onToggleTab={name => this.onToggleAddressTab(name, index)}
                      isSubCollapse
                      isActiveSubForm={
                        activeTab && cl.state === activeTab.name && activeTab.isActive && activeTab.index === index
                      }
                    >
                      <div className="col-md-12 row">
                        {[
                          ...additionalAddressInformation,
                          ...additionalFields.map(i => {
                            const { fieldName, labelFilter, ...rest } = i;
                            return { ...rest, name: fieldName, label: labelFilter };
                          }),
                        ].map(val => (
                          <GenericInput
                            {...val}
                            value={item[val.name] || null}
                            onChange={({ name, value }) => this.onHandleChange({ name, value, index })}
                          />
                        ))}
                      </div>
                    </FormCollapse>
                  );
                })}
                <div className="col-md-12 row mt-3">
                  {!isEmpty(checkRoleGroup) && (
                    <span>
                      {t('common:label.roles')}
                      {index === 0 && <i className="fa fa-star is-required" />}
                    </span>
                  )}
                  {checkRoleGroup &&
                    map(checkRoleGroup, (ck, idx) => (
                      <CheckFieldRole
                        validate={messageRoleAddress && idx === 0 ? messageRoleAddress : ''}
                        key={ck.label}
                        label={t && ck.label ? t(ck.label) : ck.label || ''}
                        className={ck.className}
                        name={ck.value}
                        disabled={isDisabled}
                        value={item.roles && item.roles.indexOf(ck.value) > -1}
                        onChange={({ name }) => this.onChangeRole(name, index)}
                      />
                    ))}
                </div>
              </div>

              {modeDeleteAddress !== 0 && (
                <div className="form-group col-md-12">
                  <button
                    type="button"
                    className="button button-border black x-small float-right mr-4"
                    onClick={() => this.onToggleModalDeleteAddress(index)}
                    disabled={isDisableDelete}
                  >
                    {t('label.delete')}
                  </button>
                </div>
              )}
              {index === indexRenderButton && (
                <div className="form-group col-md-12 btn-add-new">
                  <div className="form-group col-md-12 action-buttons">
                    {modeAddAddress !== 0 && (
                      <button
                        type="button"
                        className="button x-small"
                        onClick={this.onToggleModalNewAddress}
                        disabled={modeAddAddress === 1}
                      >
                        {t('label.addNewAddress')}
                      </button>
                    )}
                    {!isDisableSubmit && (
                      <button
                        disabled={isDisableSubmit || !isModify}
                        type="submit"
                        className="button button-border x-small float-right"
                      >
                        {t('label.modify')}
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={doGetAccountDetails}
                      className="button button-border black x-small float-right"
                    >
                      {t('label.cancel')}
                    </button>
                  </div>
                </div>
              )}
              <ModalWithItem
                isOpen={isOpenModalNewAddress}
                modalTitle={t('label.addNewAddress')}
                onToggle={this.onToggleModalNewAddress}
                wrapperClass="modal-custom modal-70"
              >
                <FormAddNewAddress
                  checkRoleGroup={checkRoleGroup}
                  modifyAccount={modifyAccount}
                  onToggleModal={this.onToggleModalNewAddress}
                  onAddNewAddress={this.onAddNewAddress}
                  landmarkLabel={landmarkLabel}
                  extralineLabel={extralineLabel}
                />
              </ModalWithItem>

              <ModalAccept
                onAcceptDelete={this.onDeleteAddress}
                isOpen={isOpenModalDeleteAddress}
                title={t('message.deleteAddress')}
                onToggle={this.onToggleModalDeleteAddress}
              />
            </CustomerNoForm>
          );
        })}
      </form>
    );
  }
}

MultiAddressForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  checkRoleGroup: PropTypes.arrayOf(PropTypes.object),
  modifyAccount: PropTypes.func.isRequired,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDisableSubmit: PropTypes.bool,
  modeAddAddress: PropTypes.number,
  modeDeleteAddress: PropTypes.number,
};

MultiAddressForm.defaultProps = {
  id: '',
  data: [],
  checkRoleGroup: [],
  isDisabled: false,
  isDisableSubmit: false,
  modeAddAddress: 2,
  modeDeleteAddress: 2,
};

export default withTranslation('common')(MultiAddressForm);
