import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../utils/utils';
import { DataTable, TablePagination, Breadcrumb, GenericInput } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import { searchRoles, setParamsRoles, getRoleById } from '../actions';
import { makeGetlistRoles, makePageRolesParams, errorMessageRoles } from '../selectors';
import { RoleSearchForm } from '../../../components/OperationHub';
import { makeGetPermissionsUserManagement } from '../../App/selectors';
import { checkPermissionGetRoles, checkPermissionCreateRoles, checkPermissionSearchRoles } from '../CheckPermission';

const RoleSort = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const tableColumns = [
  {
    name: 'status',
    label: 'common:label.status',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:permissionStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'type',
    label: 'common:label.type',
    sortable: true,
  },
  {
    name: 'name',
    label: 'common:label.name',
    sortable: true,
    style: { minWidth: '100px' },
  },
  {
    name: 'description',
    label: 'common:label.description',
  },
];

class UserManagementRole extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    itemSelected: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state && props.listRoles) {
      return {
        totalCount: getPageTotalCount({ ...state, items: props.listRoles }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchItems();
      });
    } else {
      this.doSearchItems();
    }
  }

  // onClickRow = e => {
  //   e.preventDefault();
  // };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchRoles } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && RoleSort[sorted.sortCol]
        ? RoleSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    searchRoles(params, () => {
      this.setState({ isSearching: false, filter, sort });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchItems());
  };

  onSelectItem = ({ item }) => {
    const { itemSelected } = this.state;
    if (itemSelected && itemSelected.id === item.id) {
      this.setState({ itemSelected: null });
    } else {
      this.setState({ itemSelected: item });
    }
  };

  doCopyToCreateRole = () => {
    const { itemSelected } = this.state;
    const { history, getRoleById } = this.props;
    getRoleById(
      {
        id: itemSelected.id,
      },
      response => {
        const {
          arHubModules,
          billingHubModules,
          customerHubModules,
          opsHubModules,
          pricingHubModules,
          revenueHubModules,
          selfCareHubModules,
          mediationHubModules,
          status,
          type,
        } = response.data || {};
        history.push({
          pathname: RouteNames.userManagementRoleAdd.path,
          state: {
            arHubModules,
            billingHubModules,
            customerHubModules,
            opsHubModules,
            pricingHubModules,
            revenueHubModules,
            selfCareHubModules,
            mediationHubModules,
            status,
            type,
          },
        });
      }
    );
  };

  render() {
    const { listRoles, permissionsUser, t, location } = this.props;
    const { sorted, isSearching, page, size, totalCount, itemSelected, filter } = this.state;
    let newColumnTable = cloneDeep(tableColumns);
    let modeCreateRoles = 0;
    let modeGetRoles = 0;
    let modeSearchRoles = 0;

    if (permissionsUser && permissionsUser.userModulePermissions) {
      const listPermission = permissionsUser.userModulePermissions;
      modeCreateRoles = checkPermissionCreateRoles({ listPermission });
      modeGetRoles = checkPermissionGetRoles({ listPermission });
      modeSearchRoles = checkPermissionSearchRoles({ listPermission });
    }

    newColumnTable = [
      {
        name: 'id',
        label: 'common:label.id',
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.userManagementRoleEdit.path, item.id),
              state: { filter },
            }}
          >
            {item.id}
          </Link>
        ),
      },
      ...newColumnTable,
      {
        name: 'action',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{
              pathname: RouteNames.prettifyPath(RouteNames.userManagementRoleEdit.path, item.id),
              state: { filter },
            }}
          >
            {t ? t('common:label.view') : ''}
          </Link>
        ),
      },
    ];

    if (modeSearchRoles === 0) return '';

    if (modeGetRoles === 0) {
      newColumnTable[0] = {
        name: 'id',
        label: 'Id',
      };
      newColumnTable.splice(newColumnTable.length - 1, 1);
    }

    const tableConfig = {
      columns: [
        {
          name: 'select',
          label: 'common:label.select',
          render: (colName, item) => {
            return (
              <GenericInput
                value={!!(itemSelected && item.id === itemSelected.id)}
                name="select"
                type="checkbox"
                wrapperClass="col-md-4"
                onChange={() => this.onSelectItem({ item })}
                disabled={modeCreateRoles !== 2}
              />
            );
          },
        },
        ...newColumnTable,
      ],
      sorted: { ...sorted },
      data: listRoles,
      onSort: this.onSortColumn,
      // onClickRow: this.onClickRow,
    };
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={
                  modeCreateRoles !== 2
                    ? RouteNames.userManagementRole.path
                    : { pathname: RouteNames.userManagementRoleAdd.path, state: { filter } }
                }
                className="button x-small"
              >
                {modeCreateRoles !== 2 ? t('label.back') : t('label.createNewRole')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:operationsHub.subMain.userManagement'), url: RouteNames.userManagementRole.path },
                  { name: t('userManagementPage:sidebar.roles'), url: RouteNames.userManagementRole.path },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <RoleSearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={isSearching}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            {modeCreateRoles === 2 && (
              <div className="col-md-12 pt-4 pb-2 pl-3">
                <button
                  disabled={!itemSelected || !itemSelected.id}
                  type="button"
                  className="button button-border x-small float-right"
                  onClick={this.doCopyToCreateRole}
                >
                  {t('label.copyToCreate')}
                </button>
              </div>
            )}
            <div className="card-body">
              <DataTable {...tableConfig} isLoading={isSearching} />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  listRoles: makeGetlistRoles() || [],
  params: makePageRolesParams() || {},
  errorMessage: errorMessageRoles() || '',
  permissionsUser: makeGetPermissionsUserManagement() || {},
});

UserManagementRole.propTypes = {
  searchRoles: PropTypes.func.isRequired,
  listRoles: PropTypes.arrayOf(PropTypes.any),
  permissionsUser: PropTypes.objectOf(PropTypes.any),
};

UserManagementRole.defaultProps = {
  listRoles: [],
  permissionsUser: {},
};

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchRoles,
    setParamsRoles,
    getRoleById,
  })(UserManagementRole)
);
