import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MultiInputGroup from '../MultiInputGroup';
import GenericInput from '../GenericInput';

const EmailGroup = ({ emails, onChangeEmail, sendEmail, data }) => {
  const { t } = useTranslation('common');
  const [emailData, setEmailData] = useState({});

  const onChange = ({ name, value }) => {
    emailData[name] = value;
    setEmailData({ ...emailData });
  };

  return (
    <div className="col-md-12 email-group row">
      <MultiInputGroup
        label={t('label.emails')}
        value={emails || []}
        inputProps={{
          placeholder: t('label.addAEmail'),
        }}
        onChange={onChangeEmail}
      />
      <GenericInput
        wrapperClass="col-md-3"
        label={t('label.subject')}
        name="subject"
        value={emailData.subject || null}
        onChange={onChange}
        type="textarea"
      />
      <GenericInput
        wrapperClass="col-md-3"
        label={t('label.emailContent')}
        name="emailBody"
        value={emailData.emailBody || null}
        onChange={onChange}
        type="textarea"
      />
      <button
        type="button"
        onClick={() => sendEmail({ ...emailData })}
        disabled={!emails || !emails.length || !data || !data.length}
        className="button x-small float-right btn-send-email mt-4"
      >
        {t('label.email')}
      </button>
    </div>
  );
};

EmailGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  emails: PropTypes.arrayOf(PropTypes.string),
  onChangeEmail: PropTypes.func,
  sendEmail: PropTypes.func,
};

EmailGroup.defaultProps = {
  data: [],
  onChangeEmail: () => {},
  sendEmail: () => {},
  emails: [],
};
export default EmailGroup;
