import gql from 'graphql-tag';

export default () => gql`
  query {
    getProductFamilyConfig(input: { id: "" }) {
      id
      productFamilyList {
        index
        productCompany
        productFamily
        productLine
        productType
        productSubType
      }
    }
  }
`;
