import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import listCurrency from '../../../utils/currency.json';
import utils, { getPageTotalCount } from '../../../utils/utils';
import { GenericInput, TitleFrom, DataTable, TablePagination } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import ModalSearchItem from '../../../components/common/ModalSearchItem';
import { createConfigAccountingRules, modifyConfigAccountingRules, searchConfigAccountingRules } from '../actions';
import { AccountingPoliciesSearchForm } from '../../../components/RevenueHub';
import ModalGLAccounts from '../../../components/common/ModalGLAccounts';

const sortAccountingPolices = {
  crGlAccount: {
    asc: 'crGlAccount_ASC',
    desc: 'crGlAccount_DESC',
  },
  crItemId: {
    asc: 'crItemId_ASC',
    desc: 'crItemId_DESC',
  },
  drGlAccount: {
    asc: 'drGlAccount_ASC',
    desc: 'drGlAccount_DESC',
  },
  drItemId: {
    asc: 'drItemId_ASC',
    desc: 'drItemId_DESC',
  },
  objectType: {
    asc: 'objectType_ASC',
    desc: 'objectType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  transactionType: {
    asc: 'transactionType_ASC',
    desc: 'transactionType_DESC',
  },
  currency: {
    asc: 'currency_ASC',
    desc: 'currency_DESC',
  },
};

class AccountingPolicies extends React.PureComponent {
  state = {
    data: { configAccountingRulesList: [] },
    wasValidated: false,
    defaultData: { configAccountingRulesList: [] },
    indexSelect: null,
    modalName: null,
    isModify: false,
    isSearching: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isFirstSearch: false,
    itemSelect: null,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.data }),
    };
  }

  componentDidMount() {
    this.doGetConfigAccountingRules();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetConfigAccountingRules = () => {
    const { searchConfigAccountingRules } = this.props;
    const { filter, page, size, sorted, isFirstSearch } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortAccountingPolices[sorted.sortCol][sorted.sortDir] : null,
    };
    searchConfigAccountingRules(payload, ({ data }) => {
      if (!isFirstSearch) {
        this.setState({ id: data ? data[0].id : null, isModify: !!(data && data[0].id) });
      }
      this.setState({
        isSearching: false,
        data:
          data && data.length
            ? {
                configAccountingRulesList: data.map(val => {
                  const { id, ...rest } = val;
                  return rest;
                }),
              }
            : { configAccountingRulesList: [] },
        defaultData:
          data && data.length
            ? {
                configAccountingRulesList: data.map(val => {
                  const { id, ...rest } = val;
                  return rest;
                }),
              }
            : { configAccountingRulesList: [] },
        wasValidated: false,
        isFirstSearch: true,
      });
    });
  };

  // doGetConfigAccountingRules = () => {
  //   const { getConfigAccountingRules } = this.props;
  //   getConfigAccountingRules('', ({ data }) => {
  //     this.setState({
  //       data: data || { configAccountingRulesList: [] },
  //       defaultData: data || { configAccountingRulesList: [] },
  //       isModify: !!data,
  //       id: data ? data.id : null,
  //     });
  //   });
  // };

  addNewItem = () => {
    try {
      const { data, defaultData } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.configAccountingRulesList) newData.configAccountingRulesList = [];
      if (defaultData)
        defaultData.configAccountingRulesList.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: -1,
        drItemId: null,
        crItemId: null,
        drGlAccount: null,
        crGlAccount: null,
        description: null,
        crAccountType: null,
        drAccountType: null,
        objectType: null,
        transactionType: null,
        status: null,
        currency: null,
      };
      newData.configAccountingRulesList = [payload, ...newData.configAccountingRulesList];
      // newData.configAccountingRulesList.push(payload);
      // dataSubmit.configAccountingRulesList[newData.length - 1] = payload;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, id } = this.state;
    const { modifyConfigAccountingRules, createConfigAccountingRules } = this.props;

    if (isModify) {
      return modifyConfigAccountingRules(
        { configAccountingRulesList: data.configAccountingRulesList.filter(val => !!val), id },
        ({ success }) => {
          if (success) this.doGetConfigAccountingRules();
        }
      );
    }
    const payloadCreate = cloneDeep(data);
    payloadCreate.configAccountingRulesList = payloadCreate.configAccountingRulesList.map(val => {
      const { index, ...rest } = val;

      return { ...rest };
    });
    return createConfigAccountingRules({ ...payloadCreate }, ({ success }) => {
      if (success) this.doGetConfigAccountingRules();
    });
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.configAccountingRulesList[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onOpenPopup = (name, index, item) => {
    if (name) return this.setState({ modalName: name, indexSelect: index, itemSelect: item });
    return this.setState({ modalName: null, indexSelect: null });
  };

  onSelectItem = (id, row) => {
    try {
      const { modalName, data, indexSelect } = this.state;
      const newData = cloneDeep(data);
      newData.configAccountingRulesList[indexSelect][modalName] = id;
      newData.configAccountingRulesList[indexSelect][modalName] = id;
      this.setState({ modalName: null, indexSelect: null, data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectGlAccount = (id, row) => {
    try {
      const { modalName, data, indexSelect } = this.state;
      const newData = cloneDeep(data);
      newData.configAccountingRulesList[indexSelect][modalName] = id;
      newData.configAccountingRulesList[indexSelect][modalName] = id;
      this.setState({ modalName: null, indexSelect: null, data: newData, itemSelect: null });
    } catch (error) {
      console.log(error);
    }
  };

  onPageChange = newPage => {
    const { page } = this.state;
    if (newPage === page) return '';
    this.setState({ page: newPage }, () => this.doGetConfigAccountingRules());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetConfigAccountingRules());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetConfigAccountingRules());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetConfigAccountingRules());
  };

  render() {
    const { t, permissions } = this.props;
    const {
      modeCreateConfigAccountingRules,
      modeGetConfigAccountingRules,
      modeModifyConfigAccountingRules,
    } = permissions;
    if (!modeGetConfigAccountingRules) return '';
    const {
      data,
      wasValidated,
      isModify,
      modalName,
      isSearching,
      page,
      size,
      totalCount,
      sorted,
      itemSelect,
    } = this.state;
    const listFieldsCOA = [
      {
        name: 'drItemId',
        label: 'label.drItemId',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.drItemId}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="drItemId"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('drItemId', index)}
          />
        ),
      },
      {
        name: 'drGlAccount',
        label: 'label.drGlAccount',
        sortable: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.drGlAccount}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="drGlAccount"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('drGlAccount', index, item)}
            readOnly
          />
        ),
      },
      {
        name: 'drAccountType',
        label: 'label.drAccountType',
        style: { minWidth: '280px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.drAccountType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="drAccountType"
            tOptions="selections:debitAccountType"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'crItemId',
        label: 'label.crItemId',
        style: { minWidth: '230px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.crItemId}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="crItemId"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('crItemId', index)}
          />
        ),
      },
      {
        name: 'crGlAccount',
        label: 'label.crGlAccount',
        sortable: true,
        style: { minWidth: '230px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.crGlAccount}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="crGlAccount"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('crGlAccount', index, item)}
            readOnly
          />
        ),
      },
      {
        name: 'crAccountType',
        label: 'label.crAccountType',
        style: { minWidth: '280px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.crAccountType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="crAccountType"
            tOptions="selections:debitAccountType"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        style: { minWidth: '300px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="description"
            type="textarea"
          />
        ),
      },
      {
        name: 'currency',
        label: 'label.currency',
        style: { minWidth: '210px' },
        sortable: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.currency}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="currency"
            options={utils.convertCurrency2Option(listCurrency.currencies)}
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'objectType',
        label: 'label.objectType',
        style: { minWidth: '300px' },
        sortable: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.objectType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="objectType"
            tOptions="selections:accountingRulesObjectType"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'transactionType',
        label: 'label.transactionType',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.transactionType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="transactionType"
            tOptions="selections:transactionType"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        sortable: true,
        style: { minWidth: '150px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="status"
            tOptions="selections:status"
            type="select"
            menuPortalTarget
          />
        ),
      },
    ];

    let selectedParentId = null;
    if (modalName === 'drGlAccount' && itemSelect && itemSelect.drGlAccount) selectedParentId = itemSelect.drGlAccount;
    if (modalName === 'crGlAccount' && itemSelect && itemSelect.crGlAccount) selectedParentId = itemSelect.crGlAccount;

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('revenuePage:sidebar.accountingConvention')}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <AccountingPoliciesSearchForm
                    onSubmit={this.onHandleSubmit}
                    ModalSearch={props => {
                      return (
                        <div>
                          <ModalGLAccounts
                            isOpen={props.isOpen === 'crGlAccount' || props.isOpen === 'drGlAccount'}
                            onSelect={props.onSelect}
                            onCancel={props.onToggle}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('revenuePage:sidebar.accountingConvention')} subClass="border-bottom" />
            <br />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateConfigAccountingRules === 2 && (
                  <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                    +
                    {t('label.addPolicy')}
                  </button>
                )}
                {modeModifyConfigAccountingRules === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={!data.configAccountingRulesList || !data.configAccountingRulesList.length}
                  >
                    {isModify ? t('label.saveConfig') : t('label.createConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetConfigAccountingRules}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable
                columns={listFieldsCOA}
                data={data.configAccountingRulesList || []}
                isFixedHeaderTable
                isLoading={isSearching}
                onSort={this.onSortColumn}
                sorted={sorted}
              />
            </form>
          </div>
        </div>
        <ModalSearchItem
          isOpen={modalName === 'drItemId' || modalName === 'crItemId'}
          onSelect={this.onSelectItem}
          onCancel={this.onOpenPopup}
          // selectedParentId={this.state && this.state.itemName ? this.state.itemName : ''}
        />
        <ModalGLAccounts
          isOpen={modalName === 'drGlAccount' || modalName === 'crGlAccount'}
          onSelect={this.onSelectGlAccount}
          onCancel={this.onOpenPopup}
          selectedParentId={selectedParentId}
        />
        <div className="mb-30 mt-2">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

AccountingPolicies.propTypes = {
  modifyCOA: PropTypes.func,
  searchConfigAccountingRules: PropTypes.func.isRequired,
};

AccountingPolicies.defaultProps = {
  modifyCOA: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    createConfigAccountingRules,
    modifyConfigAccountingRules,
    searchConfigAccountingRules,
  })(withRouter(AccountingPolicies))
);
