import gql from 'graphql-tag';

export default id => {
  const resendNotesVendor = `resendNotesVendor(input: { id: "${id}" })`;
  return gql`
  mutation {
    ${resendNotesVendor} {
      id
    }
  }
`;
};
