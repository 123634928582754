import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../components/common';
import { orderDataFields } from '../constants';

class OrderData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id, orderDetail, title, onHandleChange, t, onClickField } = this.props;
    return (
      <div className="card card-statistics">
        {/* <CardHeaderForm title={title || 'Order'} label={t('label.accountId')} value={id} /> */}
        <div className="card-body">
          <div className="group-flex pb-3">
            {orderDataFields.map(item => (
              <GenericInput
                key={item.name}
                disabled={item.disabled}
                value={orderDetail[item.name] || null}
                onChange={({ name, value }) => onHandleChange({ fieldName: name, value })}
                wrapperClass={item.wrapperClass || 'col-md-4'}
                {...item}
                readOnly={item.fa}
                onClick={item.fa ? onClickField : () => {}}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

OrderData.propTypes = {};

export default withTranslation('common')(OrderData);
