import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}` : '';
  const getJobScheduleReport = `getJobScheduleReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getJobScheduleReport} {
        jobScheduleDate
        jobScheduleId
        jobScheduleStatus
        jobId
        jobType
        jobStatus
        successCount
        failureCount
        totalCount
      }
    }
  `;
};
