import gql from 'graphql-tag';
import { parseToMutationRequestUser } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequestUser(data, [
    'objectType',
    'reasonCode',
    'index',
    'approvalRequired',
    'approvalCorrespondence',
    'rejectionCorrespondence',
  ]);
  const createConfigApprovalLevel = `createConfigApprovalLevel(input: ${dataModify})`;
  return gql`
  mutation {
    ${createConfigApprovalLevel} {
      id
    }
  }
`;
};
