import { cloneDeep, isArray } from 'lodash';
import RouteNames from '../App/RouteNames';

const customerDashboards = {
  index: 1,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.customerDashboards',
  childs: [],
};

const orderDashboards = {
  index: 2,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.orderDashboards',
  childs: [],
};

const subscriptionDashboards = {
  index: 3,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.subscriptionDashboards',
  childs: [],
};

const usageDashboards = {
  index: 4,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.usageDashboards',
  childs: [
    {
      index: 3,
      icon: 'ti-credit-card',
      label: 'dashboardPage:sidebar.telecomTraffic',
      href: RouteNames.creditNoteDashboard.path,
    },
  ],
};

const billingDashboards = {
  index: 5,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.billingDashboards',
  childs: [],
};

const paymentsDashboards = {
  index: 6,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.paymentsDashboards',
  childs: [],
};

const AROpsDashboards = {
  index: 8,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.arOpsDashboards',
  childs: [],
};

const collectionDashboards = {
  index: 7,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.collectionDashboards',
  childs: [],
};

const revenueDashboards = {
  index: 9,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.revenueDashboards',
  childs: [],
};

const jobsDashboards = {
  index: 10,
  isHaveExpand: true,
  mainTilte: 'dashboardPage:sidebar.jobsDashboards',
  childs: [],
};

const listConfigSidebar = [
  {
    listKey: [
      'CUSTOMER_ONBOARDING',
      'CUSTOMER_CHURN',
      'CUSTOMER_COUNT_BY_ACCOUNT_TYPE',
      'CUSTOMER_COUNT_BY_SELLING_COMPANY',
    ],
    name: 'customerDashboards',
    main: cloneDeep(customerDashboards),
    childs: [],
    subMain: [
      {
        subKey: 'CUSTOMER_ONBOARDING',
        navItem: {
          index: 1,
          icon: 'ti-pencil-alt',
          label: 'dashboardPage:sidebar.newCustomer',
          href: RouteNames.dashboardNewCustomer.path,
        },
      },
      {
        subKey: 'CUSTOMER_CHURN',
        navItem: {
          index: 2,
          icon: 'ti-arrow-left',
          label: 'dashboardPage:sidebar.customerChurn',
          href: RouteNames.dashboardCustomerChurn.path,
        },
      },
      {
        subKey: 'CUSTOMER_COUNT_BY_ACCOUNT_TYPE',
        navItem: {
          index: 3,
          icon: 'ti-user',
          label: 'dashboardPage:sidebar.accountType',
          href: RouteNames.dashboardAccountType.path,
        },
      },
      {
        subKey: 'CUSTOMER_COUNT_BY_SELLING_COMPANY',
        navItem: {
          index: 4,
          icon: 'ti-mouse',
          label: 'dashboardPage:sidebar.sellingCompany',
          href: RouteNames.dashboardSellingCompany.path,
        },
      },
    ],
  },
  {
    listKey: ['TRIAL_TO_PAID_CONVERSION', 'SUBSCRIPTION_CANCELLATIONS', 'ACTIVE_SUBSCRIPTIONS'],
    name: 'subscriptionDashboards',
    main: cloneDeep(subscriptionDashboards),
    subMain: [
      {
        subKey: 'ACTIVE_SUBSCRIPTIONS',
        navItem: {
          index: 1,
          icon: 'ti-gift',
          label: 'dashboardPage:sidebar.acitveSubscriptions',
          href: RouteNames.acitveSubscriptions.path,
        },
      },
      {
        subKey: 'TRIAL_TO_PAID_CONVERSION',
        navItem: {
          index: 2,
          icon: 'ti-panel',
          label: 'dashboardPage:sidebar.trialToPaidConversion',
          // label: 'Trial to paid conversion',
          href: RouteNames.trialToPaidConversion.path,
        },
      },
      {
        subKey: 'SUBSCRIPTION_CANCELLATIONS',
        navItem: {
          index: 3,
          icon: 'ti-close',
          label: 'dashboardPage:sidebar.subscriptionCancellations',
          // label: 'Subscription cancellations',
          href: RouteNames.subscriptionCancellations.path,
        },
      },
    ],
  },
  {
    listKey: ['USAGE_TRANSACTION', 'RATED_USAGE_TRANSACTION'],
    name: 'usageDashboards',
    main: cloneDeep(usageDashboards),
    childs: [],
    subMain: [
      {
        subKey: 'USAGE_TRANSACTION',
        navItem: {
          index: 1,
          icon: 'ti-briefcase',
          label: 'dashboardPage:sidebar.usageTransaction',
          href: RouteNames.usageTransaction.path,
        },
      },
      {
        subKey: 'RATED_USAGE_TRANSACTION',
        navItem: {
          index: 2,
          icon: 'ti-check-box',
          label: 'dashboardPage:sidebar.ratedUsageTransaction',
          href: RouteNames.ratedUsageTransaction.path,
        },
      },
    ],
  },
  {
    listKey: ['BILLED_REVENUE', 'BILLED_CUSTOMER', 'FOLIO_SUMMARY', 'GET_OBJECT_FILES_REPORT'],
    name: 'billingDashboards',
    main: cloneDeep(billingDashboards),
    subMain: [
      {
        subKey: 'BILLED_REVENUE',
        navItem: {
          index: 1,
          icon: 'ti-credit-card',
          label: 'dashboardPage:sidebar.billedRevenue',
          href: RouteNames.billedRevenue.path,
        },
      },
      {
        subKey: 'BILLED_CUSTOMER',
        navItem: {
          index: 2,
          icon: 'ti-face-smile',
          label: 'dashboardPage:sidebar.billedCustomer',
          href: RouteNames.billedCustomer.path,
        },
      },
      {
        subKey: 'FOLIO_SUMMARY',
        navItem: {
          index: 3,
          icon: 'ti-menu',
          label: 'dashboardPage:sidebar.folioSummary',
          href: RouteNames.folioSummary.path,
        },
      },
      {
        subKey: 'GET_OBJECT_FILES_REPORT',
        navItem: {
          index: 4,
          icon: 'ti-layout-grid2',
          label: 'reportPage:sidebar.folioCount',
          href: RouteNames.folioCountSummary.path,
        },
      },
    ],
  },
  {
    listKey: ['PAYMENT_SUCCESS', 'PAYMENT_SUSPENSE', 'PAYMENT_FAILED', 'ACCOUNTS_WITH_FAILED_PAYMENT'],
    name: 'paymentsDashboards',
    main: cloneDeep(paymentsDashboards),
    subMain: [
      {
        subKey: 'PAYMENT_SUCCESS',
        navItem: {
          index: 1,
          icon: 'ti-unlock',
          label: 'dashboardPage:sidebar.paymentSuccess',
          href: RouteNames.paymentSuccess.path,
        },
      },
      {
        index: 2,
        subKey: 'PAYMENT_SUSPENSE',
        navItem: {
          icon: 'ti-gift',
          label: 'dashboardPage:sidebar.paymentSuspense',
          href: RouteNames.paymentSuspenseDashboard.path,
        },
      },
      {
        subKey: 'PAYMENT_FAILED',
        navItem: {
          index: 3,
          icon: 'ti-na',
          label: 'dashboardPage:sidebar.paymentFailed',
          href: RouteNames.paymentFailed.path,
        },
      },
      {
        subKey: 'ACCOUNTS_WITH_FAILED_PAYMENT',
        navItem: {
          index: 4,
          icon: 'ti-face-sad',
          // label: 'Accounts with failed payment',
          label: 'dashboardPage:sidebar.accountsWithFailedPayment',
          href: RouteNames.accountsWithFailedPayment.path,
        },
      },
    ],
  },
  {
    listKey: ['COLLECTION_0_30_DAYS', 'COLLECTION_30_60_DAYS', 'COLLECTION_60_90_DAYS', 'COLLECTION_OVER_90_DAYS'],
    name: 'collectionDashboards',
    main: cloneDeep(collectionDashboards),
    subMain: [
      {
        subKey: 'COLLECTION_0_30_DAYS',
        navItem: {
          index: 1,
          icon: 'ti-receipt',
          label: 'dashboardPage:sidebar.collection030Days',
          href: RouteNames.collection030Days.path,
        },
      },
      {
        subKey: 'COLLECTION_30_60_DAYS',
        navItem: {
          index: 2,
          icon: 'ti-calendar',
          label: 'dashboardPage:sidebar.collection3060Days',
          href: RouteNames.collection3060Days.path,
        },
      },
      {
        subKey: 'COLLECTION_60_90_DAYS',
        navItem: {
          index: 3,
          icon: 'ti-clipboard',
          label: 'dashboardPage:sidebar.collection6090Days',
          href: RouteNames.collection6090Days.path,
        },
      },
      {
        subKey: 'COLLECTION_OVER_90_DAYS',
        navItem: {
          index: 4,
          icon: 'ti-book',
          label: 'dashboardPage:sidebar.collectionOver90Days',
          href: RouteNames.collectionOver90Days.path,
        },
      },
    ],
  },
  {
    listKey: [
      'TOTAL_REVENUE',
      'RECOGNIZED_REVENUE',
      'REVENUE_BY_REVENUE_TYPE',
      'REVENUE_BY_PRODUCT_COMPANY',
      'REVENUE_FORECAST',
      'MONTHLY_RECURRING_REVENUE',
    ],
    name: 'revenueDashboards',
    main: cloneDeep(revenueDashboards),
    subMain: [
      {
        subKey: 'TOTAL_REVENUE',
        navItem: {
          index: 1,
          icon: 'ti-stats-up',
          label: 'dashboardPage:sidebar.totalRevenue',
          href: RouteNames.totalRevenue.path,
        },
      },
      {
        subKey: 'RECOGNIZED_REVENUE',
        navItem: {
          index: 2,
          icon: 'ti-gift',
          label: 'dashboardPage:sidebar.recognizedRevenue',
          href: RouteNames.recognizedRevenue.path,
        },
      },
      {
        subKey: 'REVENUE_BY_REVENUE_TYPE',
        navItem: {
          index: 3,
          icon: 'ti-dashboard',
          label: 'dashboardPage:sidebar.revenueType',
          href: RouteNames.revenueType.path,
        },
      },
      {
        subKey: 'REVENUE_BY_PRODUCT_COMPANY',
        navItem: {
          index: 4,
          icon: 'ti-bar-chart',
          label: 'dashboardPage:sidebar.productCompany',
          href: RouteNames.productCompany.path,
        },
      },
      {
        subKey: 'REVENUE_FORECAST',
        navItem: {
          index: 5,
          icon: 'ti-server',
          label: 'dashboardPage:sidebar.revenueForecast',
          href: RouteNames.revenueForecastDashboard.path,
        },
      },
      {
        subKey: 'MONTHLY_RECURRING_REVENUE',
        navItem: {
          index: 6,
          icon: 'ti-blackboard',
          label: 'dashboardPage:sidebar.monthlyRecurringRevenue',
          href: RouteNames.monthlyRecurringRevenue.path,
        },
      },
    ],
  },
  {
    listKey: ['FAILED_ORDER', 'SUBMITTED_ORDER'],
    name: 'orderDashboards',
    main: cloneDeep(orderDashboards),
    subMain: [
      {
        subKey: 'FAILED_ORDER',
        navItem: {
          index: 1,
          icon: 'ti-package',
          label: 'dashboardPage:sidebar.failedOrder',
          href: RouteNames.failedOrder.path,
        },
      },
      {
        subKey: 'SUBMITTED_ORDER',
        navItem: {
          index: 2,
          icon: 'ti-gallery',
          label: 'dashboardPage:sidebar.submittedOrder',
          href: RouteNames.submittedOrder.path,
        },
      },
    ],
  },
  {
    listKey: ['JOBS_FAILED', 'BILLS_FAILED', 'INVOICES_FAILED', 'TOTAL_JOBS'],
    name: 'jobsDashboards',
    main: cloneDeep(jobsDashboards),
    subMain: [
      {
        subKey: 'JOBS_FAILED',
        navItem: {
          index: 1,
          icon: 'ti-bolt',
          label: 'dashboardPage:sidebar.jobsFailed',
          href: RouteNames.jobsFailed.path,
        },
      },
      {
        subKey: 'BILLS_FAILED',
        navItem: {
          index: 2,
          icon: 'ti-alert',
          label: 'dashboardPage:sidebar.billsFailed',
          href: RouteNames.billsFailed.path,
        },
      },
      {
        subKey: 'INVOICES_FAILED',
        navItem: {
          index: 3,
          icon: 'ti-key',
          label: 'dashboardPage:sidebar.invoicesFailed',
          href: RouteNames.invoicesFailed.path,
        },
      },
      {
        subKey: 'TOTAL_JOBS',
        navItem: {
          index: 4,
          icon: 'ti-pin2',
          label: 'dashboardPage:sidebar.totalJobs',
          href: RouteNames.totalJobs.path,
        },
      },
    ],
  },
  {
    listKey: [
      'TOTAL_CREDIT_ADJUSTMENTS',
      'TOTAL_DISPUTES',
      'TOTAL_SETTLEMENTS',
      'TOTAL_WRITEOFF',
      'TOTAL_DEBIT_ADJUSTMENTS',
    ],
    name: 'AROpsDashboards',
    main: cloneDeep(AROpsDashboards),
    subMain: [
      {
        subKey: 'TOTAL_CREDIT_ADJUSTMENTS',
        navItem: {
          index: 1,
          icon: 'ti-pencil-alt',
          label: 'dashboardPage:sidebar.totalCreditAdjustments',
          href: RouteNames.totalCreditAdjustments.path,
        },
      },
      {
        subKey: 'TOTAL_DEBIT_ADJUSTMENTS',
        navItem: {
          index: 2,
          icon: 'ti-magnet',
          label: 'dashboardPage:sidebar.debitAdjustments',
          href: RouteNames.debitAdjustments.path,
        },
      },
      {
        subKey: 'TOTAL_DISPUTES',
        navItem: {
          index: 3,
          icon: 'ti-layers-alt',
          label: 'dashboardPage:sidebar.totalDisputes',
          href: RouteNames.totalDisputes.path,
        },
      },
      {
        subKey: 'TOTAL_SETTLEMENTS',
        navItem: {
          index: 4,
          icon: 'ti-paint-bucket',
          label: 'dashboardPage:sidebar.totalSettlements',
          href: RouteNames.totalSettlements.path,
        },
      },
      {
        subKey: 'TOTAL_WRITEOFF',
        navItem: {
          index: 5,
          icon: 'ti-support',
          label: 'dashboardPage:sidebar.totalWriteOff',
          href: RouteNames.totalWriteOff.path,
        },
      },
    ],
  },
];

const renderSidebar = ({ listConfig }) => {
  const newItems = [];
  const newListConfigSidebar = cloneDeep(listConfigSidebar);
  if (listConfig && isArray(listConfig) && listConfig.length) {
    listConfig.forEach(elm => {
      const indexMainItem = newItems.findIndex(
        item => item && item.listKey && item.listKey.indexOf(elm.dashboardType) > -1
      );
      const isExistMainItem = newItems.length > 0 && indexMainItem > -1;
      const defaulMainRoute = newListConfigSidebar.filter(item => item.listKey.indexOf(elm.dashboardType) > -1);
      const defaultChildRoute =
        defaulMainRoute && defaulMainRoute.length
          ? defaulMainRoute[0].subMain.filter(val => val.subKey === elm.dashboardType)
          : null;
      if (!isExistMainItem && defaulMainRoute && defaulMainRoute.length) {
        newItems.push({ ...defaulMainRoute[0].main, listKey: defaulMainRoute[0].listKey });
      }

      if (defaultChildRoute && defaultChildRoute.length) {
        const indexChildRoute = newItems.findIndex(item => item.listKey.indexOf(elm.dashboardType) > -1);
        if (indexChildRoute > -1 && newItems[indexChildRoute] && newItems[indexChildRoute].childs) {
          const checkExistChildRoute =
            newItems[indexChildRoute].childs.findIndex(item => item.subKey === elm.dashboardType) > -1;
          if (!checkExistChildRoute) {
            newItems[indexChildRoute].childs.push({
              ...defaultChildRoute[0].navItem,
              subKey: defaultChildRoute[0].subKey,
            });
          }
        }
      }
    });
  }

  return newItems && newItems.length
    ? newItems.map(item => {
        const { listKey, ...rest } = item;
        return rest;
      })
    : [];
};

const listNameTitleUserDashboard = {
  // customer
  CUSTOMER_ONBOARDING: 'common:label.newCustomer',
  CUSTOMER_CHURN: 'dashboardPage:sidebar.customerChurn',

  // Subscription
  ACTIVE_SUBSCRIPTIONS: 'dashboardPage:sidebar.acitveSubscriptions',
  TRIAL_TO_PAID_CONVERSION: 'common:label.trialToPaidConversion',
  SUBSCRIPTION_CANCELLATIONS: 'common:label.subscriptionCancellations',

  // Billing
  BILLED_REVENUE: 'common:label.billedRevenue',
  BILLED_CUSTOMER: 'common:label.billedCustomer',

  // Payments
  PAYMENT_SUCCESS: 'common:label.paymentSuccess',
  PAYMENT_SUSPENSE: 'paymentPage:sidebar.paymentSuspense',
  PAYMENT_FAILED: 'dashboardPage:sidebar.paymentFailed',
  ACCOUNTS_WITH_FAILED_PAYMENT: 'common:label.accountsWithFailedPayment',

  // Collection
  COLLECTION_0_30_DAYS: 'dashboardPage:sidebar.collection030Days',
  COLLECTION_30_60_DAYS: 'dashboardPage:sidebar.collection3060Days',
  COLLECTION_60_90_DAYS: 'dashboardPage:sidebar.collection6090Days',
  COLLECTION_OVER_90_DAYS: 'dashboardPage:sidebar.collectionOver90Days',

  // Revenue
  TOTAL_REVENUE: 'dashboardPage:sidebar.totalRevenue',
  RECOGNIZED_REVENUE: 'dashboardPage:sidebar.recognizedRevenue',
  REVENUE_BY_REVENUE_TYPE: 'dashboardPage:sidebar.revenueType',
  REVENUE_BY_PRODUCT_COMPANY: 'dashboardPage:sidebar.productCompany',
  CUSTOMER_COUNT_BY_ACCOUNT_TYPE: 'dashboardPage:sidebar.accountType',
  CUSTOMER_COUNT_BY_SELLING_COMPANY: 'dashboardPage:sidebar.sellingCompany',
  REVENUE_FORECAST: 'dashboardPage:sidebar.revenueForecast',
  MONTHLY_RECURRING_REVENUE: 'dashboardPage:sidebar.monthlyRecurringRevenue',

  // Orders
  FAILED_ORDER: 'dashboardPage:sidebar.failedOrder',
  SUBMITTED_ORDER: 'dashboardPage:sidebar.submittedOrder',

  // USAGE
  USAGE_TRANSACTION: 'dashboardPage:sidebar.usageTransaction',
  RATED_USAGE_TRANSACTION: 'dashboardPage:sidebar.ratedUsageTransaction',

  // Jobs
  JOBS_FAILED: 'dashboardPage:sidebar.jobsFailed',
  BILLS_FAILED: 'dashboardPage:sidebar.billsFailed',
  INVOICES_FAILED: 'dashboardPage:sidebar.invoicesFailed',
  TOTAL_JOBS: 'dashboardPage:sidebar.totalJobs',

  // AR Ops
  TOTAL_CREDIT_ADJUSTMENTS: 'dashboardPage:sidebar.totalCreditAdjustments',
  TOTAL_DISPUTES: 'dashboardPage:sidebar.totalDisputes',
  TOTAL_SETTLEMENTS: 'dashboardPage:sidebar.totalSettlements',

  TOTAL_WRITEOFF: 'dashboardPage:sidebar.totalWriteOff',
  TOTAL_DEBIT_ADJUSTMENTS: 'dashboardPage:sidebar.debitAdjustments',

  FOLIO_SUMMARY: 'dashboardPage:sidebar.folioSummary',
  FOLIO_COUNT: 'reportPage:sidebar.folioCount',
};

const groupNamePermissions = {
  saveUserPreferences: 'SAVE_USER_PREFERENCES',
  getUserPreferences: 'GET_USER_PREFERENCES',
  customerOnboarding: 'CUSTOMER_ONBOARDING',
  customerChunk: 'CUSTOMER_CHURN',
  trialToPaidConversion: 'TRIAL_TO_PAID_CONVERSION',
  subscriptionCancellations: 'SUBSCRIPTION_CANCELLATIONS',
  billedRevenue: 'BILLED_REVENUE',
  billedCustomer: 'BILLED_CUSTOMER',
  paymentSuccess: 'PAYMENT_SUCCESS',
  paymentSuspense: 'PAYMENT_SUSPENSE',
  paymentFailed: 'PAYMENT_FAILED',
  accountWithFailedPayment: 'ACCOUNTS_WITH_FAILED_PAYMENT',
  collection030days: 'COLLECTION_0_30_DAYS',
  collection3060days: 'COLLECTION_30_60_DAYS',
  collection6090days: 'COLLECTION_60_90_DAYS',
  collectionOver90days: 'COLLECTION_OVER_90_DAYS',
  totalRevenue: 'TOTAL_REVENUE',
  failedOrder: 'FAILED_ORDER',
  billsFailed: 'BILLS_FAILED',
  invoicesFailed: 'INVOICES_FAILED',
  totalCreditAdjustments: 'TOTAL_CREDIT_ADJUSTMENTS',
  totalDisputes: 'TOTAL_DISPUTES',
  totalSettlements: 'TOTAL_SETTLEMENTS',
  jobFailed: 'JOBS_FAILED',
  submittedOrder: 'SUBMITTED_ORDER',
  usageTransaction: 'USAGE_TRANSACTION',
  ratedUsageTransaction: 'RATED_USAGE_TRANSACTION',
  totalJobs: 'TOTAL_JOBS',
  recognizedRevenue: 'RECOGNIZED_REVENUE',
  totalWriteOff: 'TOTAL_WRITEOFF',
  activeSubscriptions: 'ACTIVE_SUBSCRIPTIONS',
  revenueByRevenueType: 'REVENUE_BY_REVENUE_TYPE',
  revenueByProductCompany: 'REVENUE_BY_PRODUCT_COMPANY',
  revenueByAccountType: 'CUSTOMER_COUNT_BY_ACCOUNT_TYPE',
  revenueBySellingCompany: 'CUSTOMER_COUNT_BY_SELLING_COMPANY',
  totalDebitAdjustments: 'TOTAL_DEBIT_ADJUSTMENTS',
  revenueForecast: 'REVENUE_FORECAST',
  monthlyRecurringRevenue: 'MONTHLY_RECURRING_REVENUE',
  usageDataByServiceType: 'USAGE_DATA_BY_SERVICE_TYPE',
  usageDataByCallType: 'USAGE_DATA_BY_CALL_TYPE',
  folioSummary: 'FOLIO_SUMMARY',
  GET_OBJECT_FILES_REPORT: 'GET_OBJECT_FILES_REPORT',
};

const listConfigDefault = [
  { index: 1, dashboardType: 'PAYMENT_SUSPENSE' },
  { index: 2, dashboardType: 'PAYMENT_FAILED' },
  { index: 3, dashboardType: 'ACCOUNTS_WITH_FAILED_PAYMENT' },
  { index: 4, dashboardType: 'COLLECTION_0_30_DAYS' },
  { index: 5, dashboardType: 'COLLECTION_30_60_DAYS' },
  { index: 6, dashboardType: 'COLLECTION_60_90_DAYS' },
  { index: 7, dashboardType: 'COLLECTION_OVER_90_DAYS' },
  { index: 8, dashboardType: 'TOTAL_CREDIT_ADJUSTMENTS' },
  { index: 9, dashboardType: 'TOTAL_DISPUTES' },
  { index: 10, dashboardType: 'TOTAL_SETTLEMENTS' },
  { index: 11, dashboardType: 'TOTAL_REVENUE' },
  { index: 13, dashboardType: 'TOTAL_WRITEOFF' },
  { index: 12, dashboardType: 'BILLS_FAILED' },
  { index: 13, dashboardType: 'INVOICES_FAILED' },
  { index: 14, dashboardType: 'JOBS_FAILED' },
  { index: 15, dashboardType: 'SUBMITTED_ORDER' },
  { index: 16, dashboardType: 'USAGE_TRANSACTION' },
  { index: 17, dashboardType: 'RATED_USAGE_TRANSACTION' },
  { index: 18, dashboardType: 'TOTAL_JOBS' },
  { index: 19, dashboardType: 'RECOGNIZED_REVENUE' },
  { index: 20, dashboardType: 'CUSTOMER_ONBOARDING' },
  { index: 21, dashboardType: 'CUSTOMER_CHURN' },
  { index: 22, dashboardType: 'FAILED_ORDER' },
  { index: 23, dashboardType: 'TRIAL_TO_PAID_CONVERSION' },
  { index: 24, dashboardType: 'SUBSCRIPTION_CANCELLATIONS' },
  { index: 25, dashboardType: 'BILLED_REVENUE' },
  { index: 26, dashboardType: 'BILLED_CUSTOMER' },
  { index: 27, dashboardType: 'FOLIO_SUMMARY' },
  { index: 28, dashboardType: 'GET_OBJECT_FILES_REPORT' },
  { index: 29, dashboardType: 'PAYMENT_SUCCESS' },
  { index: 30, dashboardType: 'ACTIVE_SUBSCRIPTIONS' },
  { index: 31, dashboardType: 'REVENUE_BY_REVENUE_TYPE' },
  { index: 32, dashboardType: 'REVENUE_BY_PRODUCT_COMPANY' },
  { index: 33, dashboardType: 'CUSTOMER_COUNT_BY_ACCOUNT_TYPE' },
  { index: 34, dashboardType: 'CUSTOMER_COUNT_BY_SELLING_COMPANY' },
  { index: 35, dashboardType: 'TOTAL_DEBIT_ADJUSTMENTS' },
  { index: 36, dashboardType: 'REVENUE_FORECAST' },
  { index: 37, dashboardType: 'MONTHLY_RECURRING_REVENUE' },
];

export { renderSidebar, listNameTitleUserDashboard, groupNamePermissions, listConfigDefault };
