import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const payload = parseToMutationRequest(data, ['index', 'zoneType', 'serviceType', 'status', 'distanceValue']);
  const createZoneUnit = `createZoneUnit(input: ${payload})`;
  return gql`
  mutation {
    ${createZoneUnit} {
      id
    }
  }
`;
};
