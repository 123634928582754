import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchComponent from '../PricingHub/SearchComponent';

class UserSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.id',
      name: 'id',
    },
    {
      label: 'label.userId',
      name: 'userId',
    },
    {
      label: 'label.category',
      name: 'category',
      type: 'select',
      tOptions: 'selections:category',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:status',
      isClearable: true,
    },
    {
      label: 'label.type',
      name: 'type',
    },
  ];

  btnSpan = 'col-md-9';
}

UserSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UserSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UserSearchForm);
