import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'planType', 'status', 'planId', 'serialId', 'min', 'max']);
  const createVoicePlan = `createVoicePlan(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createVoicePlan} {
        id
      }
    }
  `;
};
