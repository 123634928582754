/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DataTable, GenericInput } from '../common';

const tableColumns = [
  {
    name: 'action',
    label: 'common:label.action',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  {
    name: 'packageName',
    label: 'common:label.packageName',
  },
  {
    name: 'bundleName',
    label: 'common:label.bundleName',
  },
];

class TableFormService extends Component {
  state = {};

  onClickRow = rowItem => {};

  render() {
    const {
      service,
      bundleName,
      packageName,
      isOverrideLines,
      handleClickServicesFormBtn,
      onHandleRemoveItem,
      isBundleService,
      onChangeProvisioningId,
      isRemoveTrashFromTable,
      t,
      isShowLine,
      isDisabled,
      isModifyOrder,
      onToggleModalShowProvisionig,
    } = this.props;
    const serviceList = isBundleService ? service : [{ ...service }];
    // const serviceList = service;

    const showProvisioningColumn = [];
    if (isModifyOrder) {
      showProvisioningColumn.push({
        name: 'orderProvisioningIds',
        label: t('label.orderProvisioningIds'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                disabled={!item || !item.provisioningList}
                onClick={() => onToggleModalShowProvisionig({ item: item || null })}
              >
                {t('label.view')}
              </button>
            </div>
          );
        },
      });
    }
    const newColumnsTable = [
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        render: (colName, item) => {
          return (
            <GenericInput
              key="provisioningId"
              value={item.provisioningId}
              wrapperClass="col-md-12"
              type="text"
              onChange={({ name, value }) => onChangeProvisioningId({ name, value, item })}
              name="provisioningId"
              disabled={isDisabled}
              readOnly={isDisabled}
            />
          );
        },
      },
      ...tableColumns,
      {
        name: 'alaCarte',
        label: 'common:label.alaCarte',
        className: 'fixed-width-table',
        isRenderT: true,
        render: (colName, item, t) => {
          return <span>{item.isDiscount ? t('label.discountOffers') : t('label.priceOffers')}</span>;
        },
      },
      ...showProvisioningColumn,
      {
        name: 'remove',
        label: '',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                disabled={isDisabled}
                onClick={() => {
                  onHandleRemoveItem(item);
                }}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        name: 'selected',
        label: 'common:label.selected',
        render: (colName, item, index) => {
          if (isOverrideLines) {
            return (
              <button
                key="HideLines"
                type="button"
                className="button button-border gray x-small"
                onClick={() => {
                  handleClickServicesFormBtn(item.indexInject);
                }}
              >
                {t('label.hideLines')}
              </button>
            );
          }
          return (
            <button
              type="button"
              className="button button-border  x-small"
              onClick={() => {
                handleClickServicesFormBtn(item.indexInject);
              }}
            >
              {t(isShowLine ? 'label.showLines' : 'label.overrideLines')}
            </button>
          );
        },
      },
    ];

    const tableConfig = {
      columns: isRemoveTrashFromTable ? newColumnsTable.filter(item => item.name !== 'remove') : newColumnsTable,
      data: serviceList,
      sorted: {},
      onSort: this.onSortColumn,
    };
    return (
      <div>
        <DataTable {...tableConfig} onClickRow={this.onClickRow} />
      </div>
    );
  }
}

export default withTranslation('common')(TableFormService);
