import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ExpandTable, CardHeaderForm } from '../common';
import LineTable from './LinesTable';

class TableFormService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowHideLines: {},
    };
  }

  handleClickServicesFormBtn = index => {
    let { isShowHideLines } = this.state;
    if (isShowHideLines[index]) {
      isShowHideLines[index] = false;
    } else {
      isShowHideLines = {};
      isShowHideLines[index] = true;
    }
    this.setState({
      isShowHideLines,
    });
  };

  render() {
    const {
      services,
      isFetchLine,
      onChangeFormLine,
      addLine,
      positionTrash,
      onToggle,
      onRemoveLine,
      isRemoveTrashFromLine,
      isRenderTrashBinLineTable,
      t,
      isReadOnly,
      onHandleInitNewLines,
      modeOverrideQuoteLines,
      isViewOnly,
      isSupportShowAddDiscount,
    } = this.props;
    const { isShowHideLines } = this.state;
    const newOrderColumn = [];

    if (isSupportShowAddDiscount) {
      newOrderColumn.push({
        name: 'alaCarte',
        label: 'common:label.alaCarte',
        className: 'fixed-width-table',
        render: (colName, item, index, t) => {
          return <span>{item.isDiscount ? t('label.discountOffers') : t('label.priceOffers')}</span>;
        },
      });
    }
    const overrideLinesColumn = modeOverrideQuoteLines
      ? [
          {
            name: 'selected',
            label: 'common:label.selected',
            style: { width: '100px' },
            render: (colName, item, index, t) => {
              const { isShowHideLines } = this.state;
              return (
                <div className="form-group col-md-12">
                  <button
                    type="button"
                    className="button button-border  x-small float-right"
                    disabled={
                      item.action === 'CANCEL' || (isReadOnly && item.isDeafultService) || !modeOverrideQuoteLines
                    }
                    onClick={() => {
                      this.handleClickServicesFormBtn(index);
                      if (onHandleInitNewLines) onHandleInitNewLines({ item, index });
                    }}
                  >
                    {isShowHideLines[index] && t ? t('label.hideLines') : t('label.overrideLines')}
                  </button>
                </div>
              );
            },
          },
        ]
      : [];

    const tableColumns = [
      {
        name: 'action',
        label: 'common:label.action',
        style: { width: '100px' },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
        className: 'fixed-width-table',
      },
      {
        name: 'packageName',
        label: 'common:label.packageName',
        className: 'fixed-width-table',
      },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
        className: 'fixed-width-table',
      },
      ...newOrderColumn,
      ...overrideLinesColumn,
      {
        name: 'remove',
        label: 'common:label.remove',
        className: 'fixed-width-table',
        render: (colName, item) => {
          const { onRemoveItem } = this.props;
          return (
            <button
              type="button"
              className="btn-phone"
              disabled={item.action === 'CANCEL' || (isReadOnly && item.isDeafultService)}
              onClick={() => {
                this.setState({ isShowHideLines: {} });
                onRemoveItem(item);
              }}
            >
              <i className="fa fa-trash" />
            </button>
          );
        },
      },
    ];
    const tableConfig = {
      columns: tableColumns,
      data: services,
      sorted: {},
      onSort: this.onSortColumn,
    };
    const slt = t('selections:serviceType')().find(val => val.value === services[0].serviceType);
    return (
      <div className="col-md-12 pb-3">
        <CardHeaderForm
          title={`${t('label.serviceType')}: ${slt ? slt.label : ''}`}
          wrapperClass="font-weight-normal"
        />
        <ExpandTable
          {...tableConfig}
          onClickRow={this.onClickRow}
          isExpand={cloneDeep(isShowHideLines)}
          positionTrash={positionTrash}
          isReadOnly={isReadOnly}
          tableClass="service-table"
          expandRender={(item, index) => {
            return (
              <LineTable
                key="TableFormLine"
                service={item}
                isFetchLine={isFetchLine}
                isRemoveTrashFromLine={isRemoveTrashFromLine}
                addLine={e => addLine(index, e)}
                positionTrash={positionTrash}
                isRenderTrashBinLineTable={isRenderTrashBinLineTable}
                onToggle={onToggle}
                onRemoveLine={({ itemLine }) => onRemoveLine({ itemLine, itemService: item })}
                onChangeFormLine={e => onChangeFormLine(index, e)}
                isReadOnly={(isReadOnly && item.isDeafultService) || isViewOnly}
              />
            );
          }}
        />
      </div>
    );
  }
}

TableFormService.propTypes = {
  services: PropTypes.arrayOf(PropTypes.any),
  isFetchLine: PropTypes.bool,
  positionTrash: PropTypes.bool,
  onChangeFormLine: PropTypes.func,
  addLine: PropTypes.func,
  onToggle: PropTypes.func,
  onChangeProvisioningId: PropTypes.func,
  onRemoveLine: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onHandleInitNewLines: PropTypes.func,
  isRemoveTrashFromLine: PropTypes.bool,
  isRenderTrashBinServiceTable: PropTypes.bool,
  isRenderTrashBinLineTable: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  t: PropTypes.func,
  modeOverrideQuoteLines: PropTypes.number,
};

TableFormService.defaultProps = {
  services: [],
  isFetchLine: false,
  positionTrash: false,
  isRemoveTrashFromLine: false,
  isRenderTrashBinServiceTable: false,
  isRenderTrashBinLineTable: false,
  isReadOnly: false,
  onChangeFormLine: () => {},
  onHandleInitNewLines: () => {},
  addLine: () => {},
  onToggle: () => {},
  onChangeProvisioningId: () => {},
  onRemoveItem: () => {},
  onRemoveLine: () => {},
  t: () => {},
  modeOverrideQuoteLines: 0,
};

export default withTranslation(['common', 'selections'])(TableFormService);
