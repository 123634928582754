import CryptoJS from 'crypto-js';

const secret = 'embrix_C0rE_Ui_sEcreT_2o24';

const decrypt = value => {
  try {
    const bytes = CryptoJS.AES.decrypt(`${value}`, secret);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};

const encrypt = value => {
  try {
    return CryptoJS.AES.encrypt(`${value}`, secret).toString();
  } catch (error) {
    return null;
  }
};

const hash = value => CryptoJS.SHA3(value).toString();

const listLocalKeys = {
  accountId: hash('accountId'),
  currency: hash('currency'),
  userId: hash('userId'),
  id: hash('id'),
  userName: hash('userName'),
  password: hash('password'),
  isFirstTimeLogin: hash('isFirstTimeLogin'),
  lgB64: hash('lgB64'),
  rounding: hash('rounding'),
  daysForPasswordChange: hash('daysForPasswordChange'),
  lastPasswordChangeDate: hash('lastPasswordChangeDate'),
  email: hash('email'),
  isSSO: hash('isSSO'),
  prfImg: hash('prfImg'),
};

export function getId() {
  const result = decrypt(localStorage.getItem(listLocalKeys.id) ? localStorage.getItem(listLocalKeys.id) : '');
  return result;
}

export function setId(id) {
  localStorage.setItem(listLocalKeys.id, encrypt(id));
}

export function getUserId() {
  const result = decrypt(localStorage.getItem(listLocalKeys.userId) ? localStorage.getItem(listLocalKeys.userId) : '');
  return result;
}

export function setUserId(userId) {
  localStorage.setItem(listLocalKeys.userId, encrypt(userId));
}

export function getPassword() {
  const result = decrypt(
    localStorage.getItem(listLocalKeys.password) ? localStorage.getItem(listLocalKeys.password) : ''
  );
  return result;
}

export function setPassword(password) {
  localStorage.setItem(listLocalKeys.password, encrypt(password));
}

export function getFirstTimeLogin() {
  const result = decrypt(
    localStorage.getItem(listLocalKeys.isFirstTimeLogin) ? localStorage.getItem(listLocalKeys.isFirstTimeLogin) : ''
  );
  return result;
}

export function setFirstTimeLogin(isFirstTimeLogin) {
  localStorage.setItem(listLocalKeys.isFirstTimeLogin, encrypt(isFirstTimeLogin));
}

export function getAccountId() {
  const result = decrypt(
    localStorage.getItem(listLocalKeys.accountId) ? localStorage.getItem(listLocalKeys.accountId) : ''
  );
  return result;
}

export function setAccountId(accountId) {
  localStorage.setItem(listLocalKeys.accountId, encrypt(accountId));
}

export function setCurrency(currency) {
  localStorage.setItem(listLocalKeys.currency, encrypt(currency));
}

export function getDaysForPasswordChange() {
  const result = decrypt(
    localStorage.getItem(listLocalKeys.daysForPasswordChange)
      ? localStorage.getItem(listLocalKeys.daysForPasswordChange)
      : ''
  );
  return result;
}

export function setDaysForPasswordChange(daysForPasswordChange) {
  localStorage.setItem(listLocalKeys.daysForPasswordChange, encrypt(daysForPasswordChange));
}

export function getLastPasswordChangeDate() {
  const result = decrypt(
    localStorage.getItem(listLocalKeys.lastPasswordChangeDate)
      ? localStorage.getItem(listLocalKeys.lastPasswordChangeDate)
      : ''
  );
  return result;
}

export function setLastPasswordChangeDate(lastPasswordChangeDate) {
  localStorage.setItem(listLocalKeys.lastPasswordChangeDate, encrypt(lastPasswordChangeDate));
}

export function getDateOnJob() {
  return localStorage.getItem('jobDate') || '';
}

export function setDateOnJob(date) {
  localStorage.setItem('jobDate', date);
}

export function getEmail() {
  const result = decrypt(localStorage.getItem(listLocalKeys.email) ? localStorage.getItem(listLocalKeys.email) : '');
  return result;
}

export function setEmail(email) {
  localStorage.setItem(listLocalKeys.email, encrypt(email));
}

export function getSSOLogin() {
  const result = decrypt(localStorage.getItem(listLocalKeys.isSSO) ? localStorage.getItem(listLocalKeys.isSSO) : '');
  return result;
}

export function setSSOLogin(isSSO) {
  localStorage.setItem(listLocalKeys.isSSO, encrypt(isSSO));
}

export function getRounding() {
  return localStorage.getItem('rounding') || '';
}

export function setRounding(rounding) {
  localStorage.setItem('rounding', rounding);
}

export function getLogoBase64() {
  const result = decrypt(localStorage.getItem(listLocalKeys.lgB64) ? localStorage.getItem(listLocalKeys.lgB64) : '');
  return result;
}

export function setLogoBase64(lgB64) {
  localStorage.setItem(listLocalKeys.lgB64, encrypt(lgB64));
}

export function getAvatarBase64() {
  const result = decrypt(localStorage.getItem(listLocalKeys.prfImg) ? localStorage.getItem(listLocalKeys.prfImg) : '');
  return result;
}

export function setAvatarBase64(prfImg) {
  localStorage.setItem(listLocalKeys.prfImg, encrypt(prfImg));
}

export function clearAllLocalStorage() {
  const lang = localStorage.getItem('i18nextLng');
  localStorage.clear();
  localStorage.setItem('i18nextLng', lang);
}
