import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class AgeingSummarySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.invoiceStatus',
      name: 'invoiceUnitStatus',
      type: 'select',
      tOptions: 'selections:invoiceStatus',
    },
    {
      label: 'label.organization',
      name: 'sellingCompany',
    },

    {
      label: 'label.groupByType',
      name: 'groupByType',
      tOptions: 'selections:collectionReportGroupByType',
      isClearable: true,
      type: 'select',
      defaultValue: 'ACCOUNT',
      required: true,
    },
    {
      label: 'label.groupCompany',
      name: 'groupCompany',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      tOptions: 'selections:accountType',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.numberOfDaysSinceDueDateMoreThan',
      name: 'startNumberOfDaysSinceDueDate',
      type: 'number',
      enableFieldsOnChange: { groupByType: 'INVOICE' },
      isInteger: true,
    },
    {
      label: 'label.numberOfDaysSinceDueDateLessThan',
      name: 'endNumberOfDaysSinceDueDate',
      type: 'number',
      enableFieldsOnChange: { groupByType: 'INVOICE' },
      isInteger: true,
    },
    {
      label: 'label.functionalCurrency',
      name: 'functionalCurrency',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-12';
}

AgeingSummarySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AgeingSummarySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AgeingSummarySearchForm);
