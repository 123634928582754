import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchComponent from '../PricingHub/SearchComponent';

class UserGroupSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.userId',
      name: 'userId',
    },
  ];

  btnSpan = 'col-md-9';
}

UserGroupSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UserGroupSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UserGroupSearchForm);
