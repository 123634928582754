import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import {
  searchProjectClients,
  searchAllProjectClients,
  createEmployeeCosting,
  modifyProjectClient,
  createProjectClient,
  getProjectClientById,
} from '../actions';
import { makeGetClientProjects, makeGetListALlClientProjects } from '../selectors';
import {
  DataTable,
  GenericInput,
  ButtonExport,
  ButtonExportCSV,
  ExcelExport,
  SwitchExport,
  ModalUploadFile,
  TablePagination,
  ModalWithItem,
  TitleFrom,
} from '../../../components/common';
import { getPageTotalCount, parseToMutationRequestPostMethod } from '../../../utils/utils';
import { ClientSearchForm } from '../../../components/BillingHub';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelClientProject';
import convertJson2SheetJobTitle from '../../../utils/ExcelHelper/exportExcelClientProjectJobTitle';
import { headersCSV, dataCSV } from '../../../utils/CSVHelper/exportCSVClientProject';
import {
  headersCSV as headersCSVJobTitle,
  dataCSV as dataCSVJobTitle,
} from '../../../utils/CSVHelper/exportCSVClientProjectJobTitle';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfClientProject';
import convertJson2PdfJobTitle from '../../../utils/PdfHelper/exportPdfClientProjectJobTitle';
import { uploadMultiPartFiles, getCloudFileDataByFileName } from '../../App/actions';
import ModalAccountId from '../../../components/common/ModalAccountId';

const sortTypes = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  name: {
    asc: 'clientName_ASC',
    desc: 'clientName_DESC',
  },
};

const statsFileRecordColumns = [
  {
    name: 'fileName',
    label: 'common:label.fileName',
  },
  {
    name: 'totalCount',
    label: 'common:label.totalCount',
  },
  {
    name: 'pendingCount',
    label: 'common:label.pendingCount',
  },
  {
    name: 'successCount',
    label: 'common:label.successCount',
  },
  {
    name: 'failureCount',
    label: 'common:label.errorCount',
  },
];

class ClientTE extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    employeeList: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isOpenUploadFile: false,
    isOpenModalClientOnboarding: false,
    projectClientPricing: [],
    itemClientSelect: null,
    isOpenModalAccountId: false,
    itemAccountSelected: null,
    indexSelectAccountId: null,
  };

  formRef = React.createRef();

  buttonRef = React.createRef();

  buttonCSVRef = React.createRef();

  buttonRefJobTitle = React.createRef();

  buttonCSVRefJobTitle = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.employeeList) return null;
    return {
      employeeList: props.employeeList || [],
      totalCount: getPageTotalCount({ ...state, items: props.employeeList }),
      oldData: props.employeeList,
      dataSubmit: [],
      isOnChange: false,
    };
  }

  componentDidMount() {
    this.doSearchProjectClients();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { employeeList } = this.state;
    const newData = cloneDeep(employeeList);
    newData[index][name] = value;
    this.setState({ employeeList: newData });
  };

  onChangeTableJobTitle = ({ name, value, index }) => {
    try {
      const { dataProjectClientPricing } = this.state;
      const newData = cloneDeep(dataProjectClientPricing);
      newData.projectClientPricing[index][name] = value;
      this.setState({ dataProjectClientPricing: newData });
    } catch (error) {
      console.log(error);
    }
  };

  doSearchProjectClients = () => {
    const { searchProjectClients } = this.props;
    const { filter, page, size, sorted } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchProjectClients(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewClient = () => {
    let indexNewItem = 0;
    const { employeeList, dataSubmit } = this.state;
    let newData = cloneDeep(employeeList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      id: null,
      employeeName: null,
      clientName: null,
      jobTitle: null,
      firstPayrollDate: null,
      secondPayrollDate: null,
      startDate: null,
      endDate: null,
      firstSalaryAmount: null,
      secondSalaryAmount: null,
      noOfDaysWorked: null,
      clientJobTitle: null,
      fileName: null,
      clientId: null,
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ employeeList: newData, dataSubmit });
  };

  onAddNewJobTitle = () => {
    let indexNewItem = 0;
    const { dataProjectClientPricing, itemClientSelect } = this.state;
    const newData = cloneDeep(dataProjectClientPricing);
    if (newData?.projectClientPricing?.length) {
      newData.projectClientPricing.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    } else {
      newData.projectClientPricing = [];
    }
    const newDataItem = {
      index: indexNewItem + 1,
      id: itemClientSelect.id || null,
      jobTitle: null,
      startDate: null,
      endDate: null,
      clientPricingUnit: 'MONTH',
      workDays: '30',
      amount: null,
    };
    newData.projectClientPricing = [newDataItem, ...newData.projectClientPricing];
    this.setState({ dataProjectClientPricing: newData });
  };

  onRemoveItemTable = ({ index }) => {
    const { employeeList, dataSubmit } = this.state;
    const newData = cloneDeep(employeeList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ employeeList: newData, dataSubmit });
  };

  onHanldeSave = (evt, index) => {
    evt.preventDefault();
    const { createProjectClient, modifyProjectClient } = this.props;
    const { employeeList } = this.state;
    const newPayloadCreate = cloneDeep(employeeList[index || 0]);
    const { isNew, status, createdDate, ...rest } = newPayloadCreate;
    if (isNew) {
      createProjectClient(rest, ({ success }) => {
        if (success) this.doSearchProjectClients();
      });
    } else {
      const { accountId, ...payloadModify } = rest || {};
      modifyProjectClient(payloadModify, ({ success }) => {
        if (success) this.doSearchProjectClients();
      });
    }
  };

  onToggleModal = () => {
    const { isOpenUploadFile } = this.state;
    this.setState({ isOpenUploadFile: !isOpenUploadFile });
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchProjectClients());
  };

  onHandleUpload = ({ files }) => {
    const { isOpenUploadFile } = this.state;
    const { uploadMultiPartFiles } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        { category: 'TIME_AND_BILLING', timeAndBillingSubType: 'EMPLOYEE_PRICING', name: files[0].name },
        ['category', 'timeAndBillingSubType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          this.setState({
            isOpenUploadFile: !isOpenUploadFile,
            itemSelect: null,
            fileNameUpload: files[0]?.name || '',
          });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null, fileNameUpload: null });
    }
  };

  onCancelUploadFile = () => {
    this.setState({ isOpenUploadFile: false, itemSelect: null });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onChangeSwitchJobTitle = () => {
    const { isActivePdfJobTitle } = this.state;
    this.setState({ isActivePdfJobTitle: !isActivePdfJobTitle });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchProjectClients());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchProjectClients());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchProjectClients());
  };

  doGetAllListClientProjectCosting = cb => {
    const { filter, sorted } = this.state;
    const { searchAllProjectClients } = this.props;
    const payload = {
      page: 1,
      size: 10000,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchAllProjectClients(payload, () => {
      if (cb) cb();
    });
  };

  onExport = isCsv => {
    this.doGetAllListClientProjectCosting(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf && !isCsv) {
        const { listALlEmployees, t } = this.props;
        convertJson2Pdf({
          data: listALlEmployees,
          t,
          title: 'CLIENTS',
          fileName: `list_clients_${moment(new Date()).format('YYYY_MM_DD')}`,
        });
      } else if (isCsv) {
        this.buttonCSVRef.current.click();
      } else this.buttonRef.current.click();
    });
  };

  onExportJobTitle = isCsv => {
    const { isActivePdfJobTitle, dataProjectClientPricing } = this.state;
    if (isActivePdfJobTitle && !isCsv) {
      const { t } = this.props;
      convertJson2PdfJobTitle({
        data: dataProjectClientPricing?.projectClientPricing || [],
        t,
        title: 'CLIENT JOBS TITLE',
        fileName: `list_clients_${moment(new Date()).format('YYYY_MM_DD')}`,
      });
    } else if (isCsv) {
      this.buttonCSVRefJobTitle.current.click();
    } else this.buttonRefJobTitle.current.click();
  };

  onToggleModalViewStatsFile = () => {
    const { isOpenModalStatsFile } = this.state;
    if (!isOpenModalStatsFile) {
      this.doGetStatsFile();
    }
    this.setState({ isOpenModalStatsFile: !isOpenModalStatsFile });
  };

  onToggleModalClientOnboarding = item => {
    const { isOpenModalClientOnboarding } = this.state;
    if (!isOpenModalClientOnboarding) {
      if (item.isNew) {
        this.setState({
          dataProjectClientPricing: { name: item.name, accountId: item?.accountId, projectClientPricing: [] },
        });
      } else {
        this.doGetClientById(item.id);
      }
      this.setState({ itemClientSelect: item });
    }
    this.setState({ isOpenModalClientOnboarding: !isOpenModalClientOnboarding });
  };

  doGetStatsFile = () => {
    const { getCloudFileDataByFileName } = this.props;
    const { fileNameUpload } = this.state;
    getCloudFileDataByFileName(fileNameUpload, ({ success, data }) => {
      if (success) this.setState({ statsFileData: data || [] });
    });
  };

  doGetClientById = id => {
    const { getProjectClientById } = this.props;
    getProjectClientById(id, ({ success, data }) => {
      this.setState({ dataProjectClientPricing: success ? data : {} });
    });
  };

  onResetJobData = () => {
    const { dataProjectClientPricing } = this.state;
    const newData = cloneDeep(dataProjectClientPricing);
    newData.projectClientPricing = [];
    this.setState({ dataProjectClientPricing: newData });
  };

  saveJobTitle = evt => {
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { modifyProjectClient, createProjectClient } = this.props;
    const { dataProjectClientPricing, itemClientSelect } = this.state;
    if (itemClientSelect.isNew) {
      const payloadCreate = cloneDeep(dataProjectClientPricing);
      if (payloadCreate?.createdDate) delete payloadCreate.createdDate;
      if (payloadCreate?.projectClientPricing) {
        payloadCreate.projectClientPricing = payloadCreate.projectClientPricing.map(val => {
          const { index, ...rest } = val;
          return rest;
        });
      }
      return createProjectClient(payloadCreate, ({ success, data }) => {
        if (success) {
          this.doSearchProjectClients();
          this.setState({ isOpenModalClientOnboarding: false });
        }
      });
    }
    const payloadModify = cloneDeep(dataProjectClientPricing);
    if (payloadModify?.status) delete payloadModify.status;
    if (payloadModify?.createdDate) delete payloadModify.createdDate;
    if (payloadModify?.projectClientPricing) {
      payloadModify.projectClientPricing = payloadModify.projectClientPricing.map(val => {
        const { status, ...rest } = val;
        return rest;
      });
    }
    return modifyProjectClient(payloadModify, ({ success, data }) => {
      if (success) {
        this.setState({ isOpenModalClientOnboarding: false });
      }
    });
  };

  onOpenModalAccountId = ({ item, index }) => {
    this.setState({ isOpenModalAccountId: true, itemAccountSelected: item, indexSelectAccountId: index });
  };

  onSelectAccountId = id => {
    const { employeeList, indexSelectAccountId } = this.state;
    const newData = cloneDeep(employeeList);
    newData[indexSelectAccountId].accountId = id;
    this.setState({ employeeList: newData, isOpenModalAccountId: false });
  };

  render() {
    const {
      employeeList,
      isSearching,
      wasValidated,
      isOpenUploadFile,
      itemSelect,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      isOpenModalStatsFile,
      statsFileData,
      fileNameUpload,
      isOpenModalClientOnboarding,
      dataProjectClientPricing,
      itemClientSelect,
      isActivePdfJobTitle,
      isOpenModalAccountId,
      itemAccountSelected,
    } = this.state;
    const { t, listALlEmployees } = this.props;
    const clientTEColumns = [
      {
        name: 'saveConfig',
        label: 'label.apply',
        render: (colName, item, index) => (
          <button
            type="button"
            className="button x-small apply-btn"
            disabled={item.isNew && !item.name}
            onClick={evt => this.onHanldeSave(evt, index)}
          >
            {t('common:label.apply')}
          </button>
        ),
      },
      {
        name: 'id',
        label: t('label.id'),
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="id"
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'accountId',
        label: t('label.accountId'),
        required: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountId}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="accountId"
            required
            fa="fa fa-external-link"
            onClick={() => this.onOpenModalAccountId({ item, index })}
            readOnly={!item.isNew}
            disabled={!item.isNew}
          />
        ),
      },
      {
        name: 'name',
        label: t('label.clientName'),
        style: { minWidth: '200px' },
        required: true,
        sortable: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="name"
            required
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'startDate',
        label: t('label.startDate'),
        style: { minWidth: '250px' },
        sortable: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.startDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="startDate"
            type="date"
            readOnly={!item.isNew}
            disabled={!item.isNew}
          />
        ),
      },
      {
        name: 'endDate',
        label: t('label.endDate'),
        sortable: true,
        style: { minWidth: '250px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.endDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="endDate"
            type="date"
            // readOnly={item.isNew}
            // disabled={item.isNew}
          />
        ),
      },
      {
        name: 'status',
        label: t('label.status'),
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="status"
            required
            tOptions="selections:timeAndBillingObjectStatus"
            type="select"
            menuPortalTarget
            readOnly
            disabled
          />
        ),
      },
      {
        name: 'view',
        label: 'common:label.view',
        render: (colName, item, index) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => this.onToggleModalClientOnboarding(item)}
            disabled={!item.name || !item.accountId}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];

    const jobTitleColumn = [
      {
        name: 'id',
        label: t('label.id'),
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="id"
            readOnly
          />
        ),
      },
      {
        name: 'index',
        label: t('label.index'),
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="index"
            readOnly={!item.isNew}
          />
        ),
      },
      {
        name: 'jobTitle',
        label: t('label.jobTitle'),
        style: { minWidth: '200px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.jobTitle}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="jobTitle"
            required
          />
        ),
      },
      {
        name: 'startDate',
        label: t('label.startDate'),
        style: { minWidth: '250px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.startDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="startDate"
            type="date"
          />
        ),
      },
      {
        name: 'endDate',
        label: t('label.endDate'),
        style: { minWidth: '250px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.endDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="endDate"
            type="date"
          />
        ),
      },
      {
        name: 'createdDate',
        label: t('label.createdDate'),
        style: { minWidth: '250px' },
        render: (colName, item) => (
          <GenericInput
            value={item.createdDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={() => {}}
            name="createdDate"
            type="date"
            readOnly
            disabled
          />
        ),
      },
      {
        name: 'clientPricingUnit',
        label: t('label.pricingUnit'),
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.clientPricingUnit}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="clientPricingUnit"
            tOptions="selections:clientPricingUnit"
            type="select"
            menuPortalTarget
            isNoClearable
          />
        ),
      },
      {
        name: 'workDays',
        label: t('label.workDays'),
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.workDays}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="workDays"
            // type="numberFormat"
            type="number"
          />
        ),
      },
      {
        name: 'amount',
        label: t('label.amount'),
        style: { minWidth: '160px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.amount}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="amount"
            // type="numberFormat"
            type="number"
            required
          />
        ),
      },
      {
        name: 'status',
        label: t('label.status'),
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTableJobTitle({ name, value, index })}
            name="status"
            readOnly
            tOptions="selections:timeAndBillingObjectStatus"
            type="select"
            menuPortalTarget
            disabled
          />
        ),
      },
    ];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.invoicingBilling.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
            { name: t('label.client') },
          ]}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <TitleFrom title={t('label.client')} />
            <div className="col-md-12 mt-3">
              <button type="button" className="button x-small float-right mr-2" onClick={this.onToggleModal}>
                {t('label.upload')}
              </button>
              <button
                type="button"
                className="button button-border black x-small float-right mr-2"
                onClick={this.onToggleModalViewStatsFile}
                disabled={!fileNameUpload}
              >
                {t('label.viewUploadFileDetails')}
              </button>
            </div>
            <br />
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <ClientSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex mb-4 pt-3 float-right">
              <div className="ml-auto mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={this.onChangeSwitch}
                  wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdf}
                  rightTitle={t('label.pdf')}
                />
              </div>
              <>
                <ButtonExport onExport={() => this.onExport(false)} />
                <ExcelExport
                  element={<button type="button" className="display-none" ref={this.buttonRef} />}
                  nameSheet="CLIENTS"
                  multiDataSet={convertJson2Sheet({ data: listALlEmployees, t })}
                  fileName={`list_clients_${moment(new Date()).format('YYYY_MM_DD')}`}
                />
                <ButtonExportCSV onExport={() => this.onExport(true)} btnTitle="CSV" />
                <CsvDownloader
                  filename={`list_clients_${moment(new Date()).format('YYYY_MM_DD')}`}
                  datas={dataCSV({ t, data: listALlEmployees || [] })}
                  columns={headersCSV({ t })}
                  className="display-none"
                  separator=";"
                  extension=".csv"
                >
                  <button type="button" className="display-none" ref={this.buttonCSVRef} />
                </CsvDownloader>
              </>
            </div>
          </div>
        </div>
        <div className="card card-statistics h-100 mr-3">
          <br />
          <form noValidate>
            <div className="form-group col-md-12 buttons-attibute">
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewClient}>
                +
                {t('label.addNewClient')}
              </button>
              <button
                type="button"
                onClick={() => this.doSearchProjectClients()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <br />
            <div>
              <DataTable
                columns={clientTEColumns}
                data={employeeList && employeeList.length ? employeeList : []}
                isLoading={isSearching}
                isFixedHeaderTable
                sorted={sorted}
                onSort={this.onSortColumn}
              />
            </div>
          </form>
        </div>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalUploadFile
          title={t('label.uploadClientContractPricing')}
          isOpen={isOpenUploadFile}
          onCancel={this.onCancelUploadFile}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          // accept=".csv"
        />
        <ModalWithItem
          modalTitle={t('label.uploadFileStatus')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStatsFile}
          onToggle={this.onToggleModalViewStatsFile}
        >
          <button type="button" onClick={this.doGetStatsFile} className="btn-reload">
            <i className="ti-reload" />
          </button>
          <DataTable columns={statsFileRecordColumns} data={statsFileData ? [statsFileData] : []} />
          <button type="button" onClick={this.onToggleModalViewStatsFile} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>

        <ModalWithItem
          modalTitle={t('label.clientOnboarding')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalClientOnboarding}
          onToggle={this.onToggleModalClientOnboarding}
        >
          <div className="col-md-12 pr-0 row">
            <GenericInput
              value={itemClientSelect?.id}
              wrapperClass="col-md-4"
              onChange={() => {}}
              name="chargeType"
              label="label.client"
              readOnly
            />
            <div className="pr-0 d-flex mb-4 pt-3 col-md-8 float-right">
              <div className="ml-auto mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={this.onChangeSwitchJobTitle}
                  wrapperClass={isActivePdfJobTitle ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdfJobTitle}
                  rightTitle={t('label.pdf')}
                  name="isActivePdfJobTitle"
                />
              </div>
              <>
                <ButtonExport onExport={() => this.onExportJobTitle(false)} />
                <ExcelExport
                  element={<button type="button" className="display-none" ref={this.buttonRefJobTitle} />}
                  nameSheet="CLIENTS"
                  multiDataSet={convertJson2SheetJobTitle({
                    data: dataProjectClientPricing?.projectClientPricing || [],
                    t,
                  })}
                  fileName={`list_client_jobs_title_${moment(new Date()).format('YYYY_MM_DD')}`}
                />
                <ButtonExportCSV onExport={() => this.onExportJobTitle(true)} btnTitle="CSV" />
                <CsvDownloader
                  filename={`list_client_jobs_title_${moment(new Date()).format('YYYY_MM_DD')}`}
                  datas={dataCSVJobTitle({ t, data: dataProjectClientPricing?.projectClientPricing || [] })}
                  columns={headersCSVJobTitle({ t })}
                  className="display-none"
                  separator=";"
                  extension=".csv"
                >
                  <button type="button" className="display-none" ref={this.buttonCSVRefJobTitle} />
                </CsvDownloader>
              </>
            </div>
          </div>

          <form
            onSubmit={this.saveJobTitle}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            ref={this.formRef}
            noValidate
          >
            <div className="form-group col-md-12 buttons-attibute">
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewJobTitle}>
                +
                {t('label.addNewJobTitle')}
              </button>
              <button type="submit" className="button button-border x-small float-right">
                {t('label.save')}
              </button>
              <button
                type="button"
                onClick={() =>
                  itemClientSelect.isNew ? this.onResetJobData : this.doGetClientById(itemClientSelect?.id || null)
                }
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <DataTable
              columns={jobTitleColumn}
              data={dataProjectClientPricing?.projectClientPricing ? dataProjectClientPricing.projectClientPricing : []}
            />
          </form>
          <br />
          <button type="button" onClick={this.onToggleModalClientOnboarding} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
        <ModalAccountId
          isOpen={isOpenModalAccountId}
          onSelect={this.onSelectAccountId}
          accountId={itemAccountSelected?.accountId}
          selectedParentId={itemAccountSelected?.accountId}
          defaultSearchParams={{ fetchParentOnly: 'true' }}
          onCancel={() => {
            this.setState({ isOpenModalAccountId: false });
          }}
        />
      </div>
    );
  }
}

ClientTE.propTypes = {
  employeeList: PropTypes.objectOf(PropTypes.any),
  searchProjectClients: PropTypes.func.isRequired,
  createEmployeeCosting: PropTypes.func.isRequired,
  getCloudFileDataByFileName: PropTypes.func.isRequired,
};

ClientTE.defaultProps = {
  employeeList: {},
};
const mapStateToProps = createStructuredSelector({
  employeeList: makeGetClientProjects() || {},
  listALlEmployees: makeGetListALlClientProjects() || [],
});

const newClientTE = connect(mapStateToProps, {
  searchProjectClients,
  getCloudFileDataByFileName,
  createEmployeeCosting,
  searchAllProjectClients,
  uploadMultiPartFiles,
  modifyProjectClient,
  getProjectClientById,
  createProjectClient,
})(ClientTE);

export default withTranslation('common')(newClientTE);
