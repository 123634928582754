import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput, ModalWithItem } from '../../../components/common';

const ModalTopupOffersDetailsForm = ({
  listFields,
  data,
  onChangeData,
  onClickItem,
  onSubmit,
  onToggleModal,
  isOpenModal,
  title,
  isModify,
}) => {
  const { t } = useTranslation('common');
  return (
    <ModalWithItem
      wrapperClass="modal-70 modal-custom"
      modalTitle={t(title)}
      isOpen={isOpenModal}
      onToggle={onToggleModal}
    >
      <div className="col-md-12 row">
        {listFields.map(item => {
          return (
            <GenericInput
              {...item}
              value={data[item.name] || null}
              readOnly={!!item.fa || item.readOnly || (item.name === 'id' && isModify)}
              disabled={item.disabled}
              onClick={item.fa ? () => onClickItem(item.name) : null}
              onChange={onChangeData}
            />
          );
        })}
      </div>
      <div className="col-md-12 mb-3 row">
        <div className="text-center mt-3 mb-3 col-md-12">
          <button type="button" className="button x-small float-right" onClick={onSubmit}>
            {t('label.modify')}
          </button>
          <button type="button" className="button button-border black x-small mr-2 float-right" onClick={onToggleModal}>
            {t('label.cancel')}
          </button>
        </div>
      </div>
    </ModalWithItem>
  );
};
ModalTopupOffersDetailsForm.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  listFields: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  isOpenModal: PropTypes.bool,
  isModify: PropTypes.bool,
  title: PropTypes.string,
};

ModalTopupOffersDetailsForm.defaultProps = {
  listFields: [],
  data: {},
  isOpenModal: false,
  isModify: false,
  title: '',
};

export default ModalTopupOffersDetailsForm;
