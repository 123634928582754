import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { size, sortBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../common';
import PricesForm from './DiscountingForm';
import PricesFormRecurringTiered from './PricesFormRecurringTiered';

const initAtrr = data => {
  let defaultConfig = [];
  if (data && data.length) {
    defaultConfig = data.map(val => {
      const atrr = val.attribute;
      const indxDot = atrr.indexOf('.');
      if (indxDot) {
        return { selectRecord: atrr.substring(0, indxDot), selectField: atrr.substring(indxDot + 1, atrr.length) };
      }
      return {};
    });
  }
  return defaultConfig;
};

class PricingForm extends Component {
  state = {
    dataConfigAttributeBase: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (state.pricing === props.pricing) return null;
    return {
      pricing: props.pricing || {},
      dataConfigAttributeBase: state.dataConfigAttributeBase.length
        ? state.dataConfigAttributeBase
        : initAtrr(props.pricing.usageAttributeDiscountingData),
    };
  }

  onRenderSubinputAttribute = ({
    subInputUsageAtributeBase,
    dataConfigAttributeBase,
    pricing,
    usageContainerRecordList,
  }) => {
    return (
      subInputUsageAtributeBase &&
      subInputUsageAtributeBase.length > 0 &&
      pricing.usageAttributeDiscountingData &&
      pricing.usageAttributeDiscountingData
        .filter(val => size(val) > 1)
        .map((val, idxPrice) => {
          const itemDefaultConfig = dataConfigAttributeBase[idxPrice];
          const itemRecordSelect = itemDefaultConfig
            ? usageContainerRecordList.filter(val => val.recordType === itemDefaultConfig.selectRecord)
            : [];
          let optionFieldType = [];
          if (itemRecordSelect && itemRecordSelect.length)
            optionFieldType = itemRecordSelect[0].attributes.map(item => ({
              label: item.attributeName,
              value: item.attributeName,
            }));
          return (
            <div className="row col-md-12 pb-2" key={`idx-${idxPrice}`}>
              <div className="col-md-12 row p-2">
                <div className="row col-md-11 p-0">
                  {subInputUsageAtributeBase.map((input, idx) => {
                    let newValue = '';
                    if (['selectRecord', 'selectField'].indexOf(input.name) > -1) {
                      newValue = itemDefaultConfig ? itemDefaultConfig[input.name] : '';
                    } else {
                      newValue = val[input.name];
                    }
                    return (
                      <GenericInput
                        key={`pricing-${input.name}-${idx}`}
                        {...input}
                        value={newValue}
                        options={input.name === 'selectField' ? optionFieldType : input.options}
                        readOnly
                        disabled
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })
    );
  };

  render() {
    const {
      index,
      pricingInputs,
      pricingRemoveable,
      priceRemovable,
      creatingMode,
      isComplexUsage,
      subInputRecurringTirred,
      checkModel,
      usageContainerRecordList,
      indexShowing,
      onToggleShowIndex,
      inputFieldsUsageComplex,
      t,
      ...props
    } = this.props;
    const { pricing, dataConfigAttributeBase } = this.state;
    const optionRecordType =
      usageContainerRecordList && usageContainerRecordList.length
        ? sortBy(
            usageContainerRecordList.map(val => ({ label: val.recordType, value: val.recordType })),
            ['label']
          )
        : [];
    const subInputUsageAtributeBase =
      props.discountingModel === 'USAGE_ATTRIBUTE_BASED' || props.discountingModel === 'COMPLEX'
        ? [
            {
              label: 'label.selectRecord',
              wrapperClass: 'col-md-3',
              name: 'selectRecord',
              // required: true,
              type: 'select',
              options: optionRecordType,
              disabled: index > 0 && !isComplexUsage,
              menuPortalTarget: true,
            },
            {
              label: 'label.selectField',
              wrapperClass: 'col-md-3',
              name: 'selectField',
              // required: true,
              type: 'select',
              options: [],
              disabled: index > 0 && !isComplexUsage,
              menuPortalTarget: true,
            },
            {
              label: 'label.field',
              wrapperClass: 'col-md-3',
              name: 'attribute',
              required: true,
              readOnly: true,
              disabled: index > 0 && !isComplexUsage,
            },
            {
              label: 'label.value',
              wrapperClass: 'col-md-3',
              name: 'value',
              required: true,
              // disabled: index > 0,
            },
          ]
        : [];

    if (isComplexUsage && size(pricing) < 11) return '';
    return (
      <div className={props.discountingModel !== 'FLAT' ? 'card pricing-panel' : ''}>
        {props.discountingModel !== 'FLAT' && (
          <button
            type="button"
            className={`card-header text-left no-border btn-collapsible-index ${
              indexShowing === index + 1 ? 'active-main' : ''
            }`}
            onClick={() => onToggleShowIndex(index)}
          >
            {`${t('label.index').toUpperCase()}#${index + 1}`}
            {indexShowing === index + 1 ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
          </button>
        )}
        <div className={`card-body prc-card ${indexShowing === index + 1 ? 'show-content' : 'hidden-content'}`}>
          <div className="form-row mb-4">
            {subInputRecurringTirred &&
              subInputRecurringTirred.map((input, idx) => {
                return (
                  <GenericInput
                    key={`pricing-${input.name}-${idx}`}
                    {...input}
                    value={pricing && pricing.tierDiscounting && pricing.tierDiscounting[0][input.name]}
                    onChange={this.onChangeSubInputRecurringTirred}
                    disabled
                    readOnly
                  />
                );
              })}
            {inputFieldsUsageComplex &&
              inputFieldsUsageComplex.map(val => {
                if (!val.title) return '';
                return (
                  <div className="col-md-12 row" key={val.title}>
                    <h5 className="card-title title-form">{t(val.title)}</h5>
                    <div className="col-md-12 row p-0 m-0">
                      {isComplexUsage && val.isRenderSubField && (
                        <div className="col-md-12">
                          {this.onRenderSubinputAttribute({
                            isComplexUsage,
                            subInputUsageAtributeBase,
                            dataConfigAttributeBase,
                            pricing,
                            usageContainerRecordList,
                            index,
                          })}
                        </div>
                      )}
                      {val.listField &&
                        val.listField.length > 0 &&
                        val.listField.map((input, idx) => {
                          return (
                            <GenericInput
                              key={`pricing-${input.name}-${idx}`}
                              {...input}
                              value={pricing[input.name]}
                              disabled
                              readOnly
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            {pricingInputs &&
              pricingInputs.map((input, idx) => {
                return (
                  <GenericInput
                    key={`pricing-${input.name}-${idx}`}
                    {...input}
                    value={pricing[input.name]}
                    disabled
                    readOnly
                  />
                );
              })}
            {!isComplexUsage &&
              this.onRenderSubinputAttribute({
                isComplexUsage,
                subInputUsageAtributeBase,
                dataConfigAttributeBase,
                pricing,
                usageContainerRecordList,
                index,
              })}
          </div>
          {checkModel !== 'RECURRING_TIERED' && (
            <PricesForm
              item={pricing}
              index={index}
              priceRemovable={priceRemovable}
              creatingMode={creatingMode}
              isComplexUsage={isComplexUsage}
              readOnlyCurrency={checkModel === 'USAGE_ATTRIBUTE_BASED' && index > 0}
              {...props}
            />
          )}
          {checkModel === 'RECURRING_TIERED' && (
            <PricesFormRecurringTiered
              item={pricing.tierDiscounting}
              index={index}
              priceRemovable={priceRemovable}
              creatingMode={creatingMode}
              isComplexUsage={isComplexUsage}
              {...props}
            />
          )}
        </div>
      </div>
    );
  }
}

PricingForm.propTypes = {
  onChangePricing: PropTypes.func.isRequired,
  priceRemovable: PropTypes.bool,
  pricingRemoveable: PropTypes.bool,
  isComplexUsage: PropTypes.bool,
  creatingMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  indexShowing: PropTypes.number.isRequired,
  pricingInputs: PropTypes.arrayOf(PropTypes.any),
  subInputRecurringTirred: PropTypes.arrayOf(PropTypes.any),
  usageContainerRecordList: PropTypes.arrayOf(PropTypes.any),
  inputFieldsUsageComplex: PropTypes.arrayOf(PropTypes.any),
  checkModel: PropTypes.string,
  onToggleShowIndex: PropTypes.func,
  t: PropTypes.func,
};

PricingForm.defaultProps = {
  priceRemovable: true,
  pricingRemoveable: true,
  creatingMode: true,
  pricingInputs: [],
  subInputRecurringTirred: [],
  usageContainerRecordList: [],
  isComplexUsage: false,
  checkModel: '',
  onToggleShowIndex: () => {},
  t: () => {},
  inputFieldsUsageComplex: [],
};
export default withTranslation('common')(PricingForm);
