import gql from 'graphql-tag';

export default () => {
  const getConfigArOpsThresholds = 'getConfigArOpsThresholds( dummy: "")';
  return gql`
    query {
      ${getConfigArOpsThresholds} {
        id
        name
        configArOpsThresholdsList {
          index
          currencyId
          arOpsLevel
          customReasonCode
          minThresholdToSkipNotes
          maxThreshold
          arOpsReason
          arOpsType
          accountType
          userGroupId
        }
      }
    }
  `;
};
