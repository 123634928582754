import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getRegR00106Report = `getRegR00106Report(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRegR00106Report} {
        c601
        c602
        r0010601
        r0010602
        r0010603
        r0010604
        r0010605
        r0010606
        r0010607
        r0010608
        r0010609
        r0010610
        r0010611
        r0010612
        r0010613
      }
    }
  `;
};
