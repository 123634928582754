import gql from 'graphql-tag';

export default () => {
  const getTenantConfig = 'getTenantConfig(input: {dummy: ""})';
  return gql`
    query {
      ${getTenantConfig} {
        id
        id
        name
        licenseKey
        vaultUri
        vaultPath
        tenantProfile {
          number
          street
          city
          country
          state
          postalCode
          enquiryEmail
          enquiryPhone
          companyTaxId
          companyTaxRegime
          companyTag
          logoPath
          externalUid
          extraLine
          landMark
          code
          taxVersion
        }
        tenantMerchantAccounts {
          index
          type
          name
        }
      }
    }
  `;
};
