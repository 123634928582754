/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { searchChartOfAccountConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import SearchForm from './SearchForm';

class ModalGLAccounts extends PureComponent {
  render() {
    const tableConfig = {
      columns: [
        {
          name: 'accountNumber',
          label: 'label.accountNumber',
        },
        {
          name: 'name',
          label: 'label.name',
        },
        {
          name: 'type',
          label: 'label.type',
        },
        {
          name: 'detailType',
          label: 'label.detailType',
        },
        {
          name: 'description',
          label: 'label.description',
        },
        {
          name: 'notesAccount',
          label: 'label.notesAccount',
        },
        {
          name: 'notesName',
          label: 'label.notesName',
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      fieldDisableCheck,
      searchChartOfAccountConfig,
    } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectGLAccount')}
        isOpen={isOpen}
        SearchForm={SearchForm}
        searchItems={searchChartOfAccountConfig}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        fieldDisableCheck={fieldDisableCheck}
        selectFieldName="accountNumber"
      />
    );
  }
}

ModalGLAccounts.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  searchChartOfAccountConfig: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalGLAccounts.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { searchChartOfAccountConfig })(ModalGLAccounts));
