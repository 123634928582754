import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getFutureCycleCfg,
  modifyFutureCycleCfg,
  createFutureCycleCfg,
  getInAdvanceBillingConfig,
  createInAdvanceBillingConfig,
  modifyInAdvanceBillingConfig,
  getConfigPendingBillingTrigger,
  modifyConfigPendingBillingTrigger,
  createConfigPendingBillingTrigger,
  searchEmployeeCosting,
  createEmployeeCosting,
  searchProjectClients,
  // getCloudFileDataByFileName,
  modifyProjectClient,
  createProjectClient,
  getProjectClientById,
  searchSavedManualInvoice,
  getPendingManualInvoice,
  processSavedManualInvoice,
  modifyExpensesConfig,
  createExpensesConfig,
  getExpensesConfig,
  createExpenseData,
  modifyExpenseData,
  searchExpenseData,
  createEmployeeBonus,
  modifyEmployeeBonus,
  searchEmployeeBonus,
} from '../../api';
import { queryRequest, mutationRequest } from '../../utils/request';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getFutureCycleCfgSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFutureCycleCfg());
    if (response.getFutureCycleCfg) {
      yield put(actions.getFutureCycleCfgSuccess(response.getFutureCycleCfg));
      if (cb) cb({ success: true, data: response.getFutureCycleCfg });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyFutureCycleCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyFutureCycleCfg(payload));
    if (response.modifyFutureCycleCfg) {
      if (cb) cb({ success: true, data: response.modifyFutureCycleCfg });
      toast.success(i18n.t('common:apiMessage.modifyDelayedBillingSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createFutureCycleCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createFutureCycleCfg(payload));
    if (response.createFutureCycleCfg) {
      if (cb) cb({ success: true, data: response.createFutureCycleCfg });
      toast.success(i18n.t('common:apiMessage.createDelayedBillingSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getInAdvanceBillingConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInAdvanceBillingConfig());
    if (response.getInAdvanceBillingConfig) {
      yield put(actions.getInAdvanceBillingConfigSuccess(response.getInAdvanceBillingConfig));
      if (cb) cb({ success: true, data: response.getInAdvanceBillingConfig });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyInAdvanceBillingConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyInAdvanceBillingConfig(payload));
    if (response.modifyInAdvanceBillingConfig) {
      if (cb) cb({ success: true, data: response.modifyInAdvanceBillingConfig });
      toast.success(i18n.t('common:apiMessage.modifyAdvanceBillingSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createInAdvanceBillingConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createInAdvanceBillingConfig(payload));
    if (response.createInAdvanceBillingConfig) {
      if (cb) cb({ success: true, data: response.createInAdvanceBillingConfig });
      toast.success(i18n.t('common:apiMessage.createAdvanceBillingSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Pending Bill Trigger
export function* getConfigPendingBillingTriggerSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigPendingBillingTrigger());
    if (response.getConfigPendingBillingTrigger) {
      yield put(actions.getConfigPendingBillingTriggerSuccess(response.getConfigPendingBillingTrigger));
      if (cb) cb({ success: true, data: response.getConfigPendingBillingTrigger });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyConfigPendingBillingTriggerSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigPendingBillingTrigger(payload));
    if (response.modifyConfigPendingBillingTrigger) {
      if (cb) cb({ success: true, data: response.modifyConfigPendingBillingTrigger });
      toast.success(i18n.t('common:apiMessage.modifyPendingBillingTriggerSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createConfigPendingBillingTriggerSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigPendingBillingTrigger(payload));
    if (response.createConfigPendingBillingTrigger) {
      if (cb) cb({ success: true, data: response.createConfigPendingBillingTrigger });
      toast.success(i18n.t('common:apiMessage.createPendingBillingTriggerSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchEmployeeCostingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchEmployeeCosting(payload));
    if (response.searchEmployeeCosting) {
      yield put(actions.searchEmployeeCostingSuccess(response.searchEmployeeCosting));
      if (cb) cb({ success: true, data: response.searchEmployeeCosting });
    } else {
      if (cb) cb({ success: true, data: [] });
      yield put(actions.searchEmployeeCostingSuccess([]));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(actions.searchEmployeeCostingSuccess([]));
    yield put(isEndConnected());
  }
}

export function* searchAllEmployeeCostingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchEmployeeCosting({ page, size, filter, sort }));
    if (response.searchEmployeeCosting) {
      yield put(actions.searchAllEmployeeCostingSuccess(response.searchEmployeeCosting));
      if (cb) yield cb({ success: true, data: response.searchEmployeeCosting });
    } else {
      yield put(actions.searchAllEmployeeCostingSuccess([]));
      if (cb) yield cb({ success: true, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllEmployeeCostingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* createEmployeeCostingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createEmployeeCosting(payload));
    if (response.createEmployeeCosting) {
      if (cb) cb({ success: true, data: response.createEmployeeCosting });
      toast.success(i18n.t('common:apiMessage.createEmployeeSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchProjectClientsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchProjectClients(payload));
    if (response.searchProjectClients) {
      yield put(actions.searchProjectClientsSuccess(response.searchProjectClients));
      if (cb) cb({ success: true, data: response.searchProjectClients });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchAllProjectClientsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchProjectClients({ page, size, filter, sort }));
    if (response.searchProjectClients) {
      yield put(actions.searchAllProjectClientsSuccess(response.searchProjectClients));
      if (cb) yield cb({ success: true, data: response.searchProjectClients });
    } else {
      yield put(actions.searchAllProjectClientsSuccess([]));
      if (cb) yield cb({ success: true, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllProjectClientsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

// export function* getCloudFileDataByFileNameSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(queryRequest, getCloudFileDataByFileName(payload));
//     if (response.getCloudFileDataByFileName) {
//       if (cb) cb({ success: true, data: response.getCloudFileDataByFileName });
//     } else if (cb) cb({ success: false, data: [] });
//     yield put(isEndConnected());
//   } catch (err) {
//     if (cb) cb({ success: false, data: [] });
//     yield put(isEndConnected());
//   }
// }

export function* modifyProjectClientSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyProjectClient(payload));
    if (response.modifyProjectClient) {
      if (cb) cb({ success: true, data: response.modifyProjectClient });
      toast.success(i18n.t('common:apiMessage.modifyClientSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createProjectClientSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createProjectClient(payload));
    if (response.createProjectClient) {
      if (cb) cb({ success: true, data: response.createProjectClient });
      toast.success(i18n.t('common:apiMessage.createClientSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getProjectClientByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProjectClientById(payload));
    if (response.getProjectClientById) {
      if (cb) cb({ success: true, data: response.getProjectClientById });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchSavedManualInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchSavedManualInvoice(payload));
    if (response.searchSavedManualInvoice) {
      if (cb) cb({ success: true, data: response.searchSavedManualInvoice });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getPendingManualInvoiceBillsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPendingManualInvoice(payload));
    if (response.getPendingManualInvoice) {
      if (cb) cb({ success: true, data: response.getPendingManualInvoice });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* processSavedManualInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, processSavedManualInvoice(payload));
    if (response.processSavedManualInvoice) {
      if (cb) cb({ success: true, data: response.processSavedManualInvoice });
      toast.success(i18n.t('common:apiMessage.processSavedManualInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyExpensesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyExpensesConfig(payload));
    if (response.modifyExpensesConfig) {
      if (cb) cb({ success: true, data: response.modifyExpensesConfig });
      toast.success(i18n.t('common:apiMessage.modifyExpensesConfigSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createExpensesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createExpensesConfig(payload));
    if (response.createExpensesConfig) {
      if (cb) cb({ success: true, data: response.createExpensesConfig });
      toast.success(i18n.t('common:apiMessage.createExpensesConfigSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getExpensesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExpensesConfig(payload));
    if (response.getExpensesConfig) {
      if (cb) cb({ success: true, data: response.getExpensesConfig });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyExpenseDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyExpenseData(payload));
    if (response.modifyExpenseData) {
      if (cb) cb({ success: true, data: response.modifyExpenseData });
      toast.success(i18n.t('common:apiMessage.modifyExpenseDataSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createExpenseDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createExpenseData(payload));
    if (response.createExpenseData) {
      if (cb) cb({ success: true, data: response.createExpenseData });
      toast.success(i18n.t('common:apiMessage.createExpenseDataSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchExpenseDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchExpenseData(payload));
    if (response.searchExpenseData) {
      if (cb) cb({ success: true, data: response.searchExpenseData });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyEmployeeBonusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyEmployeeBonus(payload));
    if (response.modifyEmployeeBonus) {
      if (cb) cb({ success: true, data: response.modifyEmployeeBonus });
      toast.success(i18n.t('common:apiMessage.modifyEmployeeBonusSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createEmployeeBonusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createEmployeeBonus(payload));
    if (response.createEmployeeBonus) {
      if (cb) cb({ success: true, data: response.createEmployeeBonus });
      toast.success(i18n.t('common:apiMessage.createEmployeeBonusSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchEmployeeBonusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchEmployeeBonus(payload));
    if (response.searchEmployeeBonus) {
      if (cb) cb({ success: true, data: response.searchEmployeeBonus });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* billingSaga() {
  yield takeLatest(types.GET_FUTURE_CYCLE_CFG_BILLING, getFutureCycleCfgSaga);
  yield takeLatest(types.CREATE_FUTURE_CYCLE_CFG_BILLING, createFutureCycleCfgSaga);
  yield takeLatest(types.MODIFY_FUTURE_CYCLE_CFG_BILLING, modifyFutureCycleCfgSaga);
  yield takeLatest(types.GET_FUTURE_ADVANCE_CFG_BILLING, getInAdvanceBillingConfigSaga);
  yield takeLatest(types.CREATE_FUTURE_ADVANCE_CFG_BILLING, createInAdvanceBillingConfigSaga);
  yield takeLatest(types.MODIFY_FUTURE_ADVANCE_CFG_BILLING, modifyInAdvanceBillingConfigSaga);
  yield takeLatest(types.GET_PENDING_BILL_TRIGGER, getConfigPendingBillingTriggerSaga);
  yield takeLatest(types.MODIFY_PENDING_BILL_TRIGGER, modifyConfigPendingBillingTriggerSaga);
  yield takeLatest(types.CREATE_PENDING_BILL_TRIGGER, createConfigPendingBillingTriggerSaga);
  yield takeLatest(types.SEARCH_EMPLOYEE_COSTING, searchEmployeeCostingSaga);
  yield takeLatest(types.SEARCH_ALL_EMPLOYEE_COSTING, searchAllEmployeeCostingSaga);
  yield takeLatest(types.CREATE_EMPLOYEE_COSTING, createEmployeeCostingSaga);
  yield takeLatest(types.SEARCH_CLIENT_PROJECT, searchProjectClientsSaga);
  yield takeLatest(types.SEARCH_ALL_CLIENT_PROJECT, searchAllProjectClientsSaga);
  // yield takeLatest(types.GET_CLOUD_FILE_DATA_BY_NAME, getCloudFileDataByFileNameSaga);
  yield takeLatest(types.CREATE_PROJECT_CLIENT, createProjectClientSaga);
  yield takeLatest(types.MODIFY_PROJECT_CLIENT, modifyProjectClientSaga);
  yield takeLatest(types.GET_PROJECT_CLIENT_BY_ID, getProjectClientByIdSaga);
  yield takeLatest(types.SEARCH_SAVED_MANUAL_INVOICE, searchSavedManualInvoiceSaga);
  yield takeLatest(types.GET_PENDING_MANUAL_INVOICE_BILLS, getPendingManualInvoiceBillsSaga);
  yield takeLatest(types.PROCESS_SAVED_MANUAL_INVOICE, processSavedManualInvoiceSaga);
  yield takeLatest(types.GET_EXPENSES_CONFIG, getExpensesConfigSaga);
  yield takeLatest(types.CREATE_EXPENSES_CONFIG, createExpensesConfigSaga);
  yield takeLatest(types.MODIFY_EXPENSES_CONFIG, modifyExpensesConfigSaga);
  yield takeLatest(types.CREATE_EXPENSE_DATA, createExpenseDataSaga);
  yield takeLatest(types.MODIFY_EXPENSE_DATA, modifyExpenseDataSaga);
  yield takeLatest(types.SEARCH_EXPENSE_DATA, searchExpenseDataSaga);
  yield takeLatest(types.CREATE_EMPLOYEE_BONUS, createEmployeeBonusSaga);
  yield takeLatest(types.MODIFY_EMPLOYEE_BONUS, modifyEmployeeBonusSaga);
  yield takeLatest(types.SEARCH_EMPLOYEE_BONUS, searchEmployeeBonusSaga);
}
