export default {
  customerHub: {
    main: 'Centro de Clientes',
    subMain: {
      customerManagement: 'Gestión del Cliente',
      quoteManagement: 'Gestión de Cotizaciones',
      orderManagement: 'Gestión de Órdenes',
    },
  },
  pricingHub: {
    main: 'Centro de Precios',
    subMain: {
      baseConfigurations: 'Configuraciones Básicas',
      pricingManagement: 'Gestión de Precios',
      bundleManagement: 'Gestión de Paquetes',
      pricingCatalog:'Catalogo de Precios'
    },
  },
  billingHub: {
    main: 'Centro de Facturación',
    subMain: {
      billing: 'Facturación',
      taxation: 'Impuestos',
      usage: 'Uso',
      invoicing: 'Emisión Facturas',
    },
  },
  arHub: {
    main: 'Centro de CXC',
    subMain: {
      payments: 'Pagos',
      collections: 'Cobranza',
      AROperations: 'Operaciones de CxC',
    },
  },
  operationsHub: {
    main: 'Centro de Operaciones',
    subMain: {
      userManagement: 'Gestión de Usuarios',
      jobsManagement: 'Gestión de Trabajos',
      correspondence: 'Correspondencia',
      reports: 'Reportes',
      dashboard: 'Consola',
      tenantManagement: 'Gestión de Instancia',
      taskManagement: 'Administración de Tareas',
      deployment: 'Implementación',
      performanceTuning: 'Optimización del Rendimiento',
    },
  },

  // billingHub: {
  //   main: 'Centro de Facturación',
  // },

  revenueHub: {
    main: 'Centro de Ingresos',
    subMain: {
      configuration: 'Configuración',
      revenue: 'Ingresos',
    },
  },
  
  selfCareHub: {
    main: 'Centro de Autoatención',
  },

  commonHub: {
    miscellaneous: 'Misceláneos',
    UIDefaults: 'Valores Predeterminados de la Interfaz de Usuario',
    subscriptionManagement: 'Gestión de Suscripciones',
    provisioningManagement: 'Gestión de Aprovisionamiento',
    default: 'Predeterminado',
    enablers: 'Facilitadores',
  },
};
