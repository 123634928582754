import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['jurisdiction'])}` : '';
  const getTaxSummaryReport = `getTaxSummaryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getTaxSummaryReport} {
        state
        country
        billMonth
        taxcode
        jurisdiction
        taxableAmount
        exemptAmount
        taxAmount
        taxDescription
        taxCategory
        currency
      }
    }
  `;
};
