import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DataTable } from '../common';
import RouteName from '../../containers/App/RouteNames';

export default function AROperationsTable({
  data,
  sorted,
  onSort,
  handleClickButton,
  labelButton,
  linkDetail,
  isLoading,
  modeGetDisputeDetails,
  modeGetWriteoffDetails,
  modeProcessWriteoffReversal,
  modeProcessSettlement,
  defaultLabelType,
  isWriteOff,
  newColumns,
  newLastColumns,
}) {
  const tableColumns = [
    {
      label: 'common:label.accountId',
      name: 'accountId',
      sortable: true,
    },
    {
      label: 'common:label.userId',
      sortable: true,
      name: 'userId',
    },
    {
      label: 'common:label.itemId',
      sortable: true,
      name: 'itemId',
    },
    {
      label: 'common:label.invoiceId',
      name: 'invoiceId',
    },
    {
      label: 'common:label.date',
      sortable: true,
      name: 'date',
    },
    {
      label: 'common:label.source',
      name: 'source',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:source')().find(val => val.value === item.source) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'common:label.reason',
      // sortable: true,
      name: 'customReason',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:reasonConfig')().find(val => val.value === item.customReason) : '';
        return <span>{slt ? slt.label : item.customReason || ''}</span>;
      },
    },
    {
      label: defaultLabelType || 'common:label.arType',
      sortable: true,
      name: 'type',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:arType')().find(val => val.value === item.type) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'common:label.amount',
      name: 'amount',
    },
    {
      label: 'common:label.percent',
      name: 'percent',
    },
    {
      label: 'common:label.status',
      name: 'status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t
          ? t(isWriteOff ? 'selections:arWriteoffStatus' : 'selections:disputeStatus')().find(
              val => val.value === item.status
            )
          : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
  ];

  let supportColumns = [];
  if (newColumns) {
    supportColumns = [...supportColumns, ...newColumns];
  }

  supportColumns = [...supportColumns, ...tableColumns];

  if (newLastColumns) {
    supportColumns = [...supportColumns, ...newLastColumns];
  }

  const tableConfig = {
    columns: [
      // {
      //   name: 'id',
      //   label: 'common:label.id',
      //   render: (colName, item) =>
      //     modeGetDisputeDetails !== 0 &&
      //     modeGetWriteoffDetails !== 0 && (
      //       <Link className="text-success" to={RouteName.prettifyPath(linkDetail, item.id)}>
      //         {item.id}
      //       </Link>
      //     ),
      // },
      ...supportColumns,
    ],
    sorted,
    onSort,
    data,
    isLoading,
  };
  if (modeProcessWriteoffReversal === 0 || modeProcessSettlement === 0) {
    tableConfig.columns = tableConfig.columns.filter(item => item.label !== 'Selected');
  }
  return (
    <div>
      <DataTable {...tableConfig} />
    </div>
  );
}

AROperationsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChangeTable: PropTypes.func,
  onSelectItem: PropTypes.func,
  onSort: PropTypes.func,
  handleClickButton: PropTypes.func,
  labelButton: PropTypes.string,
  linkDetail: PropTypes.string,
  sorted: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
  modeGetDisputeDetails: PropTypes.number,
  modeGetWriteoffDetails: PropTypes.number,
  modeProcessWriteoffReversal: PropTypes.number,
  modeProcessSettlement: PropTypes.number,
  defaultLabelType: PropTypes.string,
  isWriteOff: PropTypes.bool,
};

AROperationsTable.defaultProps = {
  data: [],
  onHandleChangeTable: () => {},
  onSelectItem: () => {},
  onSort: () => {},
  handleClickButton: () => {},
  labelButton: '',
  linkDetail: '',
  sorted: {},
  modeGetDisputeDetails: 2,
  modeGetWriteoffDetails: 2,
  modeProcessWriteoffReversal: 2,
  modeProcessSettlement: 2,
  defaultLabelType: '',
  isWriteOff: false,
};
