import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ type, value, ...props }) => {
  const defaultValue = value || (type !== 'number' ? '' : null);
  if (type === 'textarea') {
    return <textarea {...props} defaultValue={defaultValue} />;
  }
  return <input type={type} {...props} defaultValue={defaultValue} />;
};

const CustomInput = ({ ...props }) => {
  return <TextInput className="form-control input-group" {...props} />;
};

TextInput.propTypes = {
  type: PropTypes.string,
};

TextInput.defaultProps = {
  type: 'text',
};

export default CustomInput;
