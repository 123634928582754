import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import moment from 'moment';
import AccountTable from '../AccountTable';
import ModifyService from './ModifyService';
import constants from '../constants';
import { quoteEnum } from '../../../constantsApp';
import { StepProgressBar, Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import { ModalQuoteDetails } from '../../../components/Quotes';
import { saveQuote, getQuote } from '../actions';
import {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  getServiceUnitsBySubscriptionId,
} from '../../OrderPage/actions';
import { getLastIndex } from '../../../utils/utils';
import { convertLineFromDiscountOffers, initServiceWithSelectBundle, initServiceFromPriceOffers } from '../utilities';
import { makeGetServiceUnits, makeGetSubscriptionAssets } from '../../OrderPage/selectors';
import { getCcpDateTimeConfig, getBundleByBundleId, getDiscountOfferById } from '../../App/actions';
import { getUserId } from '../../../localStorages';
import ModalMultipleSelectDisscountOffer from '../../../components/common/ModalMultipleSelectDisscountOffer';

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.manageCreateQuote',
  },
];

class ModifyQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: { id: null },
      quoteDetails: this.initquoteDetails(props),
      subscriptionMessage: '',
      groupServices: [],
      defaultServiceFromAPI: [],
      isOpenModal: false,
      isReadOnly: true,
      ccpTime: null,
      quoteDetailsGet: null,
      isOpenModalDetails: false,
      oldStageSelected: 0,
      isSkip1StStep: !!props.accountId,
    };
    this.services = [];
    this.initServiceUnits = {};
  }

  componentDidMount() {
    const {
      location: { state },
      accountId,
    } = this.props;
    const {
      selectItem: { id },
    } = this.state;
    if (state && state.accountId && id) {
      this.getSubscriptionByAccountId(id);
    }
    if (accountId) this.getSubscriptionByAccountId(accountId);

    this.onGetCcpDateTimeConfig();
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(this.initServiceUnits) !== JSON.stringify(nextProps.subscriptionAssets)) {
      this.initServiceUnits = nextProps.subscriptionAssets;
      this.getServiceUnitsBySubscriptionId(nextProps.subscriptionAssets);
    }
  };

  onGetCcpDateTimeConfig = () => {
    const { getCcpDateTimeConfig } = this.props;
    const { ccpTime } = this.state;
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          this.setState({ ccpTime: data ? data.ccpTime : null });
        }
      });
    }
  };

  onToggle = () => {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
    }));
  };

  initquoteDetails = props => {
    return {
      type: quoteEnum.type.modify,
      accountId: props.location.state ? props.location.state.accountId : '',
      userId: getUserId() || 'TestUserId',
      isPartialFulfillmentAllowed: false,
      effectiveDate: moment(new Date()).format('YYYY-MM-DD'),
      submittedDate: moment(new Date()).format('YYYY-MM-DD'),
      initialTerm: '1',
      initialTermUnit: quoteEnum.initialTermUnit.months,
      renewalTerm: '1',
      renewalTermUnit: quoteEnum.renewalTermUnit.months,
      services: [],
    };
  };

  onClickRow = () => {};

  onSelectNext = rowItem => {
    const { quoteDetails } = this.state;
    quoteDetails.accountId = rowItem.id;
    quoteDetails.salesAgentId = getUserId();
    this.setState(
      {
        selectItem: rowItem,
        quoteDetails,
      },
      () => {
        this.onPressNextButton();
      }
    );
  };

  selectAccount = () => {
    const { selectItem } = this.state;
    if (selectItem && selectItem.id) {
      this.getSubscriptionByAccountId(selectItem.id);
    }
  };

  getSubscriptionByAccountId = id => {
    const { stage } = this.state;
    const { getSubscriptionByAccountId, itemEditSelected } = this.props;
    getSubscriptionByAccountId(id, (success, data) => {
      if (success) {
        this.initquoteDetailsByAccountId(data, itemEditSelected);
        this.setState({
          stage: stage + 1,
          oldStageSelected: stage + 1,
          isSkip1StStep: false,
        });
      } else {
        toast(data, { type: toast.TYPE.ERROR });
      }
    });
  };

  getServiceUnitsBySubscriptionId = data => {
    const { quoteDetails } = this.state;
    let subscriptionName = null;
    let services = [];
    if (data.length) {
      services = data.map((item, index) => {
        const newLine = item.lines || [];
        for (let i = 0; i < newLine.length; i++) {
          newLine[i].action = 'MODIFY';
          // newLine[i].action = 'ADD';
        }
        if (item.subscriptionName) subscriptionName = item.subscriptionName;

        return {
          index: index + 1,
          serviceType: item.serviceType,
          action: 'MODIFY',
          // action: 'ADD',
          bundleId: item.bundleId || '',
          bundleName: item.bundleName || '',
          packageId: item.packageId || '',
          packageName: item.packageName || '',
          lines: newLine,
          isDeafultService: true,
          subscriptionName,
        };
      });
    }
    const groupServices = groupBy(services, item => {
      return item.serviceType;
    });

    this.setState({
      quoteDetails: {
        ...quoteDetails,
        services,
      },
      defaultServiceFromAPI: services,
      groupServices,
      subscriptionName,
    });
  };

  initquoteDetailsByAccountId = (subscription, itemEditSelected) => {
    const { quoteDetails } = this.state;
    if (!subscription || (subscription && subscription.length < 1)) return;
    quoteDetails.accountId = subscription[0].accountId;
    quoteDetails.reason = subscription[0].reason;
    quoteDetails.initialTerm = subscription[0].initialTerm;
    quoteDetails.initialTermUnit = subscription[0].initialTermUnit;
    quoteDetails.trialTerm = subscription[0].trialTerm;
    quoteDetails.trialTermUnit = subscription[0].trialTermUnit;
    quoteDetails.effectiveDate = subscription[0].effectiveDate;
    quoteDetails.salesAgentId = itemEditSelected ? itemEditSelected.salesAgentId : null;
    this.setState({
      quoteDetails,
    });
  };

  onChangeFormLine = (groupIndex, serviceIndex, e) => {
    const { quoteDetails, defaultServiceFromAPI } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(quoteDetails);
    try {
      const indexServiceChange = newData.services.findIndex(
        item => item.index === groupServices[groupIndex][serviceIndex].index
      );
      const lineIndex = newData.services[indexServiceChange].lines.findIndex(item => item.index === e.lineIndex);
      newData.services[indexServiceChange].lines[lineIndex][e.fieldName] = e.value;
      newData.services[indexServiceChange].lines[lineIndex].isModify = true;
      if (
        newData.services &&
        newData.services[indexServiceChange] &&
        !isEmpty(defaultServiceFromAPI[indexServiceChange])
      ) {
        // newData.services[indexServiceChange].lines[lineIndex].action = 'MODIFY';
        newData.services[indexServiceChange].action = 'MODIFY';
      }

      groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      this.setState({
        quoteDetails: newData,
        groupServices,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProvisioningId = ({ name, value, item }) => {
    const { quoteDetails } = this.state;
    let groupServices = [];
    const newData = cloneDeep(quoteDetails);
    for (let i = 0; i < newData.services.length; i++) {
      if (newData.services[i].serviceType === item.serviceType) {
        newData.services[i][name] = value;
      }
    }
    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ quoteDetails: newData, groupServices });
  };

  onPressBackButton = () => {
    const { history } = this.props;
    history.push(RouteNames.searchQuoteAndList.path);
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId || null,
      discountOfferName: component.discountOfferName || null,
    };
  };

  addLine = (groupIndex, serviceIndex, bundle) => {
    const { quoteDetails, groupServices } = this.state;
    try {
      groupServices[groupIndex][serviceIndex].lines = bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          quoteDetails.services.push(service);
          return true;
        });
      });
      this.setState({
        quoteDetails,
        groupServices,
      });
    } catch (error) {
      console.log(error);
    }
  };

  doInitPackageItem = () => {
    const { quoteDetails } = this.state;
    const { getBundleByBundleId, getDiscountOfferById } = this.props;
    const newData = cloneDeep(quoteDetails);
    const { services } = newData;
    const listBundleId = services.filter(val => !!val.bundleId && val.isPackage).map(item => item.bundleId) || [];
    const listDiscountId =
      services.filter(val => !!val.discountOfferId && val.isPackage).map(item => item.discountOfferId) || [];
    const newDataWithBundleId = [];
    const newDataWithDiscountId = [];
    if (!isEmpty(listBundleId)) {
      listBundleId.map(item =>
        getBundleByBundleId(item, ({ success, data }) => {
          if (success) newDataWithBundleId.push(data.components.map(cp => ({ ...cp, bundleId: item })));
          if (newDataWithBundleId.length === listBundleId.length && success) {
            newDataWithBundleId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(sr => sr.bundleId === val.bundleId && sr.isPackage);
                  if (!services[indexService].lines) services[indexService].lines = [];
                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });
            newData.services = services;
            const groupServices = groupBy(newData.services, item => {
              return item.serviceType;
            });
            this.setState({
              quoteDetails: newData,
              groupServices,
            });
          }
        })
      );
    }
    if (!isEmpty(listDiscountId)) {
      listDiscountId.map(item =>
        getDiscountOfferById(item, ({ success, data }) => {
          if (success) newDataWithDiscountId.push([{ discountOfferName: data.name || null, discountOfferId: item }]);
          if (newDataWithDiscountId.length === listDiscountId.length && success) {
            newDataWithDiscountId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(
                    sr => sr.discountOfferId === val.discountOfferId && sr.isPackage
                  );
                  if (!services[indexService].lines) services[indexService].lines = [];

                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });

            newData.services = services;
            const groupServices = groupBy(newData.services, item => {
              return item.serviceType;
            });
            this.setState({
              quoteDetails: newData,
              groupServices,
            });
          }
        })
      );
    }
  };

  onSelectService = ({ services, mode, ids, rows, index, name }) => {
    if (!ids || (ids && ids.length === 0)) {
      return;
    }
    const { quoteDetails, defaultServiceFromAPI } = this.state;
    const groupServices = [];
    const newData = cloneDeep(quoteDetails);

    switch (mode) {
      case constants.ModalMode.purchasePackage: {
        const listExistService = newData.services.filter(val => !val.isPackage || val.subscriptionName !== name);
        let newServices = [];
        newServices = [
          ...listExistService,
          ...services[0].map((item, index) => {
            return {
              index: getLastIndex({ data: listExistService }) + index,
              packageId: ids[0],
              bundleId: item.bundleId,
              bundleName: item.bundleName,
              serviceType: item.serviceType,
              discountOfferId: item.discountOfferId || null,
              action: 'ADD',
              status: 'CREATED',
              // eslint-disable-next-line no-nested-ternary
              packageName: item.name ? item.name : rows && rows.length ? rows[0].name : '',
              subscriptionName: name,
              isPackage: true,
              isNew: true,
            };
          }),
        ];
        newData.services = newServices;
        const groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState(
          {
            quoteDetails: newData,
            groupServices,
          },
          () => this.doInitPackageItem()
        );
        break;
      }
      case constants.ModalMode.purchaseBundle: {
        const listExistService = newData.services.filter(val => !val.isBundle || val.subscriptionName !== name);
        let newServices = [];

        newServices = [
          ...listExistService,
          ...services.map((item, index) => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === item?.components[0]?.serviceType
            );
            return {
              subscriptionName: name,
              provisioningId: existService ? existService.provisioningId : null,
              isBundle: true,
              isNew: true,
              ...initServiceWithSelectBundle(item, getLastIndex({ data: listExistService }) + index - 1),
            };
          }),
        ];
        newData.services = newServices;
        const groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });
        this.setState({
          quoteDetails: newData,
          groupServices,
        });
        break;
      }
      case constants.ModalMode.purchaseAlaCarte: {
        const listExistService = newData.services.filter(val => !val.isPriceOffer || val.subscriptionName !== name);

        let newServices = [];
        // const existService = newData.services.find(
        //   val => val.subscriptionName === name && val.serviceType === services[0]?.serviceType
        // );
        const service = groupBy(services, item => {
          return item.serviceType;
        });
        let serviceIndex = 0;

        newServices = [
          ...listExistService,
          ...map(service, item => {
            const existService = newData.services.find(
              val => val.subscriptionName === name && val.serviceType === services[0]?.serviceType
            );
            serviceIndex += 1;
            return {
              provisioningId: existService ? existService.provisioningId : null,
              subscriptionName: name,
              isPriceOffer: true,
              isNew: true,
              ...initServiceFromPriceOffers(item, getLastIndex({ data: listExistService }) + serviceIndex - 1),
            };
          }),
        ];
        newData.services = newServices;
        const groupServices = groupBy(newData.services, item => {
          return item.serviceType;
        });

        this.setState({
          quoteDetails: newData,
          groupServices,
        });
        break;
      }
      default:
        break;
    }
  };

  onPressNextButton = () => {
    const { selectItem } = this.state;
    if (selectItem.id) {
      this.selectAccount();
    }
  };

  onPressCreateButton = isSave => {
    const { quoteDetails, groupServices, isReadOnly } = this.state;
    const { id, isComponent } = this.props;

    const newQuoteDetail = cloneDeep(quoteDetails);
    newQuoteDetail.services = [];
    delete newQuoteDetail.effectiveDate;
    map(groupServices, serviceGroup => {
      return serviceGroup.map(service => {
        const {
          bundleName,
          packageName,
          isOverrideLines,
          provisioningId,
          status,
          isDiscount,
          ...newService
        } = cloneDeep(service);
        delete newService.bundleName;
        delete newService.isPackage;
        delete newService.isBundle;
        delete newService.isPriceOffer;
        delete newService.discountOfferId;
        delete newService.subscriptionName;
        delete newService.isNew;
        newService.lines = newService.lines
          ? newService.lines.filter(line => line.action !== 'MODIFY' || line.isModify)
          : null;

        if (newService.lines && newService.lines.length) {
          for (let i = 0; i < newService.lines.length; i++) {
            delete newService.lines[i].priceOfferName;
            delete newService.lines[i].discountOfferName;
            delete newService.lines[i].status;
            delete newService.lines[i].isModify;
            delete newService.lines[i].id;

            newService.lines[i].startDate = newService.lines[i].startDate || null;
            newService.lines[i].endDate = newService.lines[i].endDate || null;
            if (newService.lines[i] && newService.lines[i].discountOfferId) {
              newService.lines[i].discAmountOverride = newService.lines[i].priceOverride;
              newService.lines[i].discPercentOverride = newService.lines[i].discountPercent;
              delete newService.lines[i].priceOverride;
              delete newService.lines[i].discountPercent;
            }
          }
          // if (!isOverrideLines) newService.lines = null;
        } else {
          newService.lines = null;
        }
        if (newService.isDeafultService) {
          if (!isReadOnly || newService?.lines?.length) {
            delete newService.isDeafultService;
            if (newService?.lines?.length) newQuoteDetail.services.push(newService);
          }
        } else if (newService?.lines?.length) newQuoteDetail.services.push(newService);
        // else newQuoteDetail.services.push(newService);
        return true;
      });
    });
    const { doSaveQuote, getQuote } = this.props;
    if (isSave) {
      return doSaveQuote({ ...newQuoteDetail, id: id || null }, null, isComponent);
    }
    return getQuote({ ...newQuoteDetail, id: id || null }, ({ success, data }) => {
      if (success) {
        this.setState({ isOpenModalDetails: true, quoteDetailsGet: data || {} });
      }
    });
  };

  onRemoveItem = ({ itemRemove }) => {
    const { quoteDetails, defaultServiceFromAPI, ccpTime } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(quoteDetails);
    let isPackage = false;
    let isBundle = false;
    if (itemRemove.packageId) {
      isPackage = true;
    } else if (itemRemove.bundleId) {
      isBundle = true;
    }
    if (itemRemove.isNew) {
      const indexItemRemove = newData.services.findIndex(item => item.index === itemRemove.index);
      newData.services.splice(indexItemRemove, 1);
    } else {
      if (isBundle) {
        const indexItemRemove = newData.services.findIndex(item => item.index === itemRemove.index);
        if (itemRemove.isDeafultService) {
          if (newData.services[indexItemRemove].lines)
            // delete newData.services[indexItemRemove].lines;
            newData.services[indexItemRemove].lines.forEach((val, i) => {
              newData.services[indexItemRemove].lines[i].action = 'MODIFY';
            });
          newData.services[indexItemRemove].action = 'CANCEL';
        } else {
          newData.services.splice(indexItemRemove, 1);
        }
      }

      if (isPackage) {
        const groupIndex = newData.services.filter(item => item.index === itemRemove.index).map(item => item.index);
        groupIndex.forEach(item => {
          const indexItemRemove = newData.services.findIndex(v => v.index === item);
          if (itemRemove.isDeafultService) {
            // newData.services[indexItemRemove].action = 'CANCEL';
            newData.services[indexItemRemove].action = 'MODIFY';
            if (newData.services[indexItemRemove].lines)
              // delete newData.services[indexItemRemove].lines;
              newData.services[indexItemRemove].lines.forEach((val, i) => {
                newData.services[indexItemRemove].lines[i].action = 'CANCEL';
              });
          } else {
            newData.services.splice(indexItemRemove, 1);
          }
        });
      }

      if (!isBundle && !isPackage) {
        const indexItemRemove = newData.services.findIndex(item => item.provisioningId === itemRemove.provisioningId);
        if (defaultServiceFromAPI.findIndex(item => item.provisioningId === itemRemove.provisioningId) > -1) {
          newData.services[indexItemRemove].action = 'MODIFY';
          if (newData.services[indexItemRemove].lines) {
            // delete newData.services[indexItemRemove].lines;
          }
          newData.services[indexItemRemove].lines.forEach((val, i) => {
            newData.services[indexItemRemove].lines[i].action = 'CANCEL';
            newData.services[indexItemRemove].lines[i].endDate = ccpTime;
          });
        } else {
          newData.services.splice(indexItemRemove, 1);
        }
      }
    }

    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ quoteDetails: newData, groupServices, isOpenModal: false });
  };

  onRemoveLine = ({ itemService, itemLine }) => {
    const { quoteDetails } = this.state;
    const newData = cloneDeep(quoteDetails);
    const indexService = newData.services.findIndex(item => item.index === itemService.index);
    const indexLine = newData.services[indexService].lines.findIndex(
      item => item.priceOfferId === itemLine.priceOfferId
    );
    if (indexService > -1 && indexLine > -1) {
      newData.services[indexService].action = 'MODIFY';
      if (newData.services[indexService].lines[indexLine].status === 'CREATED') {
        newData.services[indexService].lines.splice(indexLine, 1);
      } else {
        newData.services[indexService].lines[indexLine].action = 'CANCEL';
      }
    }
    const groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ quoteDetails: newData, groupServices });
  };

  onHandleInitNewLines = ({ item }) => {
    try {
      const { quoteDetails } = this.state;
      const newData = cloneDeep(quoteDetails);
      const indexService = newData.services.findIndex(val => val.index === item.index);
      newData.services[indexService].isOverrideLines = true;
      const groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      this.setState({ quoteDetails: newData, groupServices });
    } catch (error) {
      console.log('error', error);
    }
  };

  onToggleModalDiscounts = () => {
    this.setState({ isOpenModalDisscountOffers: true });
  };

  renderContent = ({
    modeAddBundle,
    modeAddPackage,
    modeAddPriceOffer,
    modeDeleteBundle,
    modeDeletePackage,
    modeDeletePriceOffer,
    modeOverrideQuoteLines,
  }) => {
    const {
      stage,
      selectItem,
      quoteDetails,
      subscriptionMessage,
      groupServices,
      isOpenModal,
      isReadOnly,
      subscriptionName,
      isSkip1StStep,
    } = this.state;
    switch (stage) {
      case 0:
        return isSkip1StStep ? null : (
          <AccountTable
            type="modify-quote"
            subscriptionStatus="ACTIVE"
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isCheckSalesAgentId
            linkTo={RouteNames.searchQuoteAndList.path}
            isNoPageTitle
          />
        );
      case 1:
        return (
          <ModifyService
            id={selectItem.id}
            modeAddBundle={modeAddBundle}
            modeAddPackage={modeAddPackage}
            modeAddPriceOffer={modeAddPriceOffer}
            modeDeleteBundle={modeDeleteBundle}
            modeDeletePackage={modeDeletePackage}
            modeDeletePriceOffer={modeDeletePriceOffer}
            quoteDetails={quoteDetails}
            groupServices={groupServices}
            subscriptionMessage={subscriptionMessage}
            onChangeFormLine={this.onChangeFormLine}
            onChangeProvisioningId={this.onChangeProvisioningId}
            onSelectService={this.onSelectService}
            addLine={this.addLine}
            isOpen={isOpenModal}
            onRemoveItem={this.onRemoveItem}
            onRemoveLine={this.onRemoveLine}
            onToggle={this.onToggle}
            onChangeModifyService={this.onChangeModifyService}
            isReadOnly={isReadOnly}
            onHandleInitNewLines={this.onHandleInitNewLines}
            isSupportPOName
            modeOverrideQuoteLines={modeOverrideQuoteLines}
            onToggleModalDiscounts={this.onToggleModalDiscounts}
            subscriptionName={subscriptionName}
          />
        );

      default:
        return <AccountTable onClickRow={this.onClickRow} selectItem={selectItem} onSelectNext={this.onSelectNext} />;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    if (step < stage + 1 || step <= oldStageSelected + 1) this.setState({ stage: step - 1, groupServices: [] });
  };

  onChangeModifyService = () => {
    const { isReadOnly } = this.state;
    this.setState({ isReadOnly: !isReadOnly });
  };

  onToggleModalDetais = () => {
    this.setState({ isOpenModalDetails: false, quoteDetailsGet: null });
  };

  onSelectMultipleDiscountOffers = ({ id, row, index }) => {
    const { quoteDetails, subscriptionName } = this.state;
    const newData = cloneDeep(quoteDetails);
    let { services } = newData;
    if (row && row.length) {
      if (!services || !services.length) services = [];
      let listDefaultLines = [];
      services = services.filter(val => !val.isDiscount);
      services.forEach(val => {
        const { lines } = val;
        if (lines && lines.length) {
          listDefaultLines = [...listDefaultLines, ...lines.filter(line => !!line.discountOfferId)];
        }
      });

      const newRows = row.filter(r => !listDefaultLines.find(ln => ln.discountOfferId === r.id));
      const newServices = [];
      if (newRows && newRows.length) {
        const newRowsGroupBy = groupBy(newRows, item => {
          return item.serviceType;
        });
        map(newRowsGroupBy, (value, key) => {
          const newService = {
            index: newServices.length + getLastIndex({ data: services }),
            serviceType: key,
            provisioningId: null,
            action: 'ADD',
            reason: null,
            status: 'CREATED',
            bundleId: null,
            packageId: null,
            lines: [],
            isDiscount: true,
            subscriptionName,
            isNew: true,
          };
          newService.lines = convertLineFromDiscountOffers(value, getLastIndex({ data: value }));
          newServices.push(newService);
        });
      }
      if (newServices && newServices.length) {
        services = [...services, ...newServices];
      }

      newData.services = services;
      const groupServices = groupBy(newData.services, item => {
        return item.serviceType;
      });
      this.setState({
        quoteDetails: newData,
        groupServices,
        isOpenModalDisscountOffers: false,
      });
    }
  };

  render() {
    const { stage, quoteDetailsGet, isOpenModalDetails, oldStageSelected, isOpenModalDisscountOffers } = this.state;
    const { permissions, t, isComponent, onToggle } = this.props;
    const {
      modeModifyQuoteType,
      modeAddBundle,
      modeAddPackage,
      modeAddPriceOffer,
      modeDeleteBundle,
      modeDeletePackage,
      modeDeletePriceOffer,
      modeOverrideQuoteLines,
      modeSaveQuote,
      modeGetQuote,
    } = permissions || {};

    if (!modeModifyQuoteType) {
      return '';
    }

    const { newQuoteDetails, oldQuoteDetails, accountId } = quoteDetailsGet || {};
    const isHasOldQuote = oldQuoteDetails && oldQuoteDetails.serviceLevelQuoteDetails;

    const {
      location: { state },
    } = this.props;

    return (
      <div>
        {!isComponent && (
          <div className="col-md-12 row p-0">
            <div className="col-sm-6">
              {!state && (
                <Link
                  to={state && state.backLink ? state.backLink : RouteNames.searchQuoteAndList.path}
                  type="button"
                  className="button x-small mb-3"
                >
                  {t('label.back')}
                </Link>
              )}
              {state && state.backLink && (
                <Link to={state.backLink} type="button" className="button x-small mb-3">
                  {t('label.back')}
                </Link>
              )}
            </div>
            <div className="col-sm-6 pr-0">
              <Breadcrumb
                items={[
                  {
                    name: t('quotePage:sidebar.modifyQuote'),
                    url: RouteNames.modifyQuote.path,
                  },
                ]}
              />
            </div>
          </div>
        )}

        <div className="col-md-12 row text-center pb-0">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              oldSelected={oldStageSelected}
              listSteps={listSteps}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3">
            <button
              type="button"
              className="button button-border x-small gray  "
              onClick={onToggle || this.onPressBackButton}
            >
              {t('label.cancel')}
            </button>
            {stage < 1 ? (
              <button type="button" className="button button-border x-small " onClick={this.onPressNextButton}>
                {t('label.next')}
              </button>
            ) : (
              <>
                {!!modeGetQuote && (
                  <button
                    type="button"
                    className="button button-border x-small "
                    onClick={() => this.onPressCreateButton(false)}
                    disabled={modeGetQuote === 1}
                  >
                    {t('label.getQuote')}
                  </button>
                )}
                {!!modeSaveQuote && (
                  <button
                    type="button"
                    className="button button-border x-small "
                    onClick={() => this.onPressCreateButton(true)}
                    disabled={modeSaveQuote === 1}
                  >
                    {t('label.save')}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {this.renderContent({
          modeAddBundle,
          modeAddPackage,
          modeAddPriceOffer,
          modeDeleteBundle,
          modeDeletePackage,
          modeDeletePriceOffer,
          modeOverrideQuoteLines,
        })}
        <ModalQuoteDetails
          isOpenModalDetails={isOpenModalDetails}
          onToggle={this.onToggleModalDetais}
          oldQuoteDetails={oldQuoteDetails}
          newQuoteDetails={newQuoteDetails}
          isHasOldQuote={isHasOldQuote}
          isSupportColor
          accountId={accountId}
        />
        <ModalMultipleSelectDisscountOffer
          isOpen={isOpenModalDisscountOffers}
          onCancel={() => {
            this.setState({ isOpenModalDisscountOffers: false });
          }}
          onSelect={(id, row) => this.onSelectMultipleDiscountOffers({ id, row })}
        />
      </div>
    );
  }
}

ModifyQuote.propTypes = {
  getSubscriptionByAccountId: PropTypes.func.isRequired,
  doSaveQuote: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  subscriptionAssets: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  permissions: PropTypes.objectOf(PropTypes.any),
  getQuote: PropTypes.func.isRequired,
  getCcpDateTimeConfig: PropTypes.func.isRequired,
  t: PropTypes.func,
};

ModifyQuote.defaultProps = {
  subscriptionAssets: [],
  location: {},
  permissions: {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  serviceUnits: makeGetServiceUnits(),
  subscriptionAssets: makeGetSubscriptionAssets(),
});

const ModifyQuoteT = connect(mapStateToProps, {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  doSaveQuote: saveQuote,
  getServiceUnitsBySubscriptionId,
  getCcpDateTimeConfig,
  getQuote,
  getBundleByBundleId,
  getDiscountOfferById,
})(ModifyQuote);

export default withTranslation('common')(ModifyQuoteT);
