import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { cloneDeep, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput, FormWithTableItem } from '../../common';
import CustomerNoForm from '../CustomerNoForm';
import { isCheckValidateForm, validate } from '../../../utils/utils';
import ModalAccept from '../../common/ModalAccept';
import ModalSearchCollectionProfile from '../../common/ModalSearchCollectionProfile';

const listReadFields = [
  {
    name: 'lastBillDate',
    label: 'label.lastBillDate',
  },
  {
    name: 'nextBillDate',
    label: 'label.nextBillDate',
  },
  {
    name: 'futureBillDate',
    label: 'label.futureBillDate',
  },
  {
    name: 'lastBillUnitId',
    label: 'label.lastBillId',
  },
  {
    name: 'nextBillUnitId',
    label: 'label.nextBillId',
  },
  {
    name: 'futureBillUnitId',
    label: 'label.futureBillId',
  },
  {
    name: 'lastAccountingDate',
    label: 'label.lastAccountingDate',
  },
  {
    name: 'nextAccountingDate',
    label: 'label.nextAccountingDate',
  },
  {
    name: 'futureAccountingDate',
    label: 'label.futureAccountingDate',
  },
  {
    name: 'futureCycleOpen',
    label: 'label.futureCycleOpen',
  },
  {
    name: 'status',
    label: 'label.status',
    tOptions: 'selections:status',
    type: 'select',
  },
  {
    name: 'reason',
    label: 'label.reason',
    tOptions: 'selections:reason',
    type: 'select',
  },
];

class BillingProfile extends React.PureComponent {
  state = {
    billingData: {},
    dataSubmit: {},
    wasValidated: false,
    isModify: false,
    isOpenModalModify: false,
    otherData: {},
    isOpenModalCollectionProfile: false,
    collectionProfileSelected: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    const { data } = this.props;
    if (data.id) {
      this.initValue(data);
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data.id && data !== prevProps.data) {
      this.initValue(data);
    }
  }

  onHandleChange = ({ value = '', name = '' }) => {
    const { billingData, dataSubmit } = this.state;
    let { isModify } = this.state;
    billingData[name] = value;
    dataSubmit[name] = value;
    if (name === 'billingFrequency' && value === 'DAYS') {
      dataSubmit.billingDom = null;
      billingData.billingDom = null;
    }

    if (value || typeof value === 'boolean') isModify = true;
    this.setState({ ...billingData, ...dataSubmit, isModify });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit } = this.state;
    const { modifyAccount, id, t, doGetAccountDetails } = this.props;
    const newDataSubmit = cloneDeep(dataSubmit);

    if (newDataSubmit) {
      if (typeof newDataSubmit.pauseBilling === 'boolean') {
        newDataSubmit.pauseBilling = newDataSubmit.pauseBilling ? 'true' : 'false';
      }
      if (typeof newDataSubmit.pauseInvoicing === 'boolean') {
        newDataSubmit.pauseInvoicing = newDataSubmit.pauseInvoicing ? 'true' : 'false';
      }
    }
    const newPayload = {
      id,
      billingProfiles: [newDataSubmit],
    };
    this.setState({ wasValidated: true });
    if (isCheckValidateForm()) return toast.error(t('message.mandatory'));
    return modifyAccount(newPayload, ({ success }) => {
      if (success) {
        doGetAccountDetails();
      }
      this.setState({ wasValidated: false, isOpenModalModify: false });
    });
  };

  initValue = account => {
    const { billingData, dataSubmit, otherData } = this.state;
    billingData.id = account.id;
    billingData.parentId = account.parentId || '';
    billingData.billingDom = account.billingDom || '';
    billingData.billingSegment = account.billingSegment || '';
    billingData.billingFrequency = account.billingFrequency || null;
    billingData.invoiceType = account.invoiceType || null;
    billingData.invoiceDelivery = account.invoiceDelivery || null;
    billingData.paymentProfileId = account.paymentProfileId || '';
    billingData.pauseBilling = account.pauseBilling || '';
    billingData.pauseInvoicing = account.pauseInvoicing || '';
    billingData.duration = account.duration || null;
    billingData.collectionProfile = account.collectionProfile || null;
    dataSubmit.id = account.id || '';
    otherData.lastAccountingDate = account.lastAccountingDate || '';
    otherData.nextAccountingDate = account.nextAccountingDate || '';
    otherData.futureAccountingDate = account.futureAccountingDate || '';
    otherData.lastBillDate = account.lastBillDate || '';
    otherData.lastBillUnitId = account.lastBillUnitId || '';
    otherData.nextBillDate = account.nextBillDate || '';
    otherData.nextBillUnitId = account.nextBillUnitId || '';
    otherData.futureCycleOpen = account.futureCycleOpen || '';
    otherData.futureBillDate = account.futureBillDate || '';
    otherData.futureBillUnitId = account.futureBillUnitId || '';
    otherData.status = account.status || '';
    otherData.reason = account.reason || '';
    this.setState({ ...billingData, dataSubmit });
  };

  onToggleModalAccept = evt => {
    const { isOpenModalModify } = this.state;
    const { t } = this.props;
    if (evt) evt.preventDefault();

    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ isOpenModalModify: !isOpenModalModify, wasValidated: false });
  };

  onToggleModalCollectionProfile = ({ itemSelected }) => {
    const { isOpenModalCollectionProfile } = this.state;
    this.setState({
      isOpenModalCollectionProfile: !isOpenModalCollectionProfile,
      collectionProfileSelected: itemSelected || null,
    });
  };

  onSelectCollectionProfile = (id, row) => {
    if (row) {
      const { billingData, dataSubmit } = this.state;
      billingData.collectionProfile = row.collectionProfile;
      dataSubmit.collectionProfile = row.collectionProfile;
      this.setState({
        isOpenModalCollectionProfile: false,
        collectionProfileSelected: null,
        ...billingData,
        ...dataSubmit,
        isModify: true,
      });
    }
  };

  render() {
    const {
      id,
      dataField,
      isNonPaying,
      isDisableSubmit,
      t,
      doGetAccountDetails,
      currencyOptions,
      customerInfo,
      ccpPropertyList,
    } = this.props;
    const {
      billingData,
      wasValidated,
      isModify,
      isOpenModalModify,
      otherData,
      isOpenModalCollectionProfile,
      collectionProfileSelected,
    } = this.state;
    if (isEmpty(billingData)) return '';
    const support293031DayBilling =
      ccpPropertyList && ccpPropertyList.find(val => val.property === 'support29_30_31DayBilling');
    return (
      <form
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        onSubmit={this.onToggleModalAccept}
        ref={this.formRef}
        noValidate
      >
        <CustomerNoForm
          colItem="col-md-4"
          dataField={dataField(billingData, support293031DayBilling && support293031DayBilling.value === 'true')}
          accountDetail={billingData}
          isDisabled={isNonPaying}
          title={t('label.billingProfile')}
          accountNum={id}
          onHandleChange={this.onHandleChange}
          // onHandleSubmit={this.onHandleSubmit}
          onHandleSubmit={this.onToggleModalAccept}
          // wasValidated={wasValidated}
          t={t}
          currencyOptions={currencyOptions}
          customerInfo={customerInfo}
          onClickItem={() => {
            this.onToggleModalCollectionProfile({
              itemSelected: billingData && billingData.collectionProfile ? billingData.collectionProfile : null,
            });
          }}
        >
          {!isNonPaying && !isDisableSubmit && (
            <div className="form-group col-md-12 action-buttons">
              <button
                disabled={isDisableSubmit || !isModify}
                type="submit"
                className="button button-border x-small float-right"
                // onClick={}
              >
                {t('label.modify')}
              </button>
              <button
                type="button"
                onClick={doGetAccountDetails}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          )}
          <ModalAccept
            onAcceptModal={this.onHandleSubmit}
            isOpen={isOpenModalModify}
            message={t('common:message.modifyBillingProfile')}
            onToggle={() => {
              this.setState({
                isOpenModalModify: !isOpenModalModify,
              });
            }}
          />
        </CustomerNoForm>
        <div className="col-md-12 mb-4">
          <FormWithTableItem title={t('label.otherData')}>
            <div className="col-md-12 row pl-3 pr-0 pb-3">
              {listReadFields.map(val => (
                <GenericInput {...val} wrapperClass="col-md-4" value={otherData[val.name] || null} readOnly disabled />
              ))}
            </div>
          </FormWithTableItem>
        </div>
        <ModalSearchCollectionProfile
          isOpen={isOpenModalCollectionProfile}
          onSelect={this.onSelectCollectionProfile}
          onCancel={this.onToggleModalCollectionProfile}
          selectedParentId={collectionProfileSelected || null}
          selectFieldName="collectionProfile"
        />
      </form>
    );
  }
}

BillingProfile.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any),
  modifyAccount: PropTypes.func.isRequired,
  id: PropTypes.string,
  isNonPaying: PropTypes.bool.isRequired,
  isDisableSubmit: PropTypes.bool,
};

BillingProfile.defaultProps = {
  id: '',
  dataField: [],
  data: {},
  isDisableSubmit: false,
};

export default withTranslation(['common', 'selections'])(BillingProfile);
