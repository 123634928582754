import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TitleFrom from '../TitleFrom';
import AccountHeaderFrom from '../AccountHeaderFrom';

const FormWithTableItem = ({
  title,
  accountNum,
  children,
  wrapperClass,
  subClass,
  commercialName,
  isCommercialName,
  currencyLabel,
  button,
}) => {
  const { t } = useTranslation('common');
  return (
    <div className={`card card-statistics form-with-item ${wrapperClass || ''}`}>
      <div className={`d-block d-md-flex justify-content-between ${subClass || ''}`}>
        <div className="d-block d-md-flex clearfix sm-mt-20">
          <TitleFrom title={t(title)} isTabel button={button} />
          <AccountHeaderFrom
            accountNum={accountNum}
            commercialName={commercialName || ''}
            isCommercialName={isCommercialName}
            currencyLabel={currencyLabel}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

FormWithTableItem.propTypes = {
  title: PropTypes.string,
  accountNum: PropTypes.string || PropTypes.number,
  wrapperClass: PropTypes.string,
  subClass: PropTypes.string,
};

FormWithTableItem.defaultProps = {
  title: '',
  accountNum: '' || null,
  wrapperClass: '',
  subClass: '',
};
export default FormWithTableItem;
