const dataFieldViewTaxCode = [
  {
    name: 'productCode',
    label: 'label.code',
    required: true,
  },

  {
    name: 'description',
    label: 'label.description',
    required: true,
  },
  // {
  //   name: 'taxPercent',
  //   label: 'label.taxPercent',
  //   required: true,
  //   type: 'number',
  // },
  {
    name: 'startDate',
    label: 'label.startDate',
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    type: 'date',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
    required: true,
    tOptions: 'selections:jurisdiction',
    type: 'select',
  },
  {
    name: 'jurisdictionList',
    label: 'label.jurisdictionList',
  },
  {
    name: 'excludeList',
    label: 'label.excludeList',
  },
  {
    name: 'minimumThreshold',
    label: 'label.minimumThreshold',
    type: 'number',
  },
  {
    name: 'maximumThreshold',
    label: 'label.maximumThreshold',
    type: 'number',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
    required: true,
  },
  // {
  //   name: 'transactionName',
  //   label: 'label.transactionName',
  // },
  // {
  //   name: 'serviceName',
  //   label: 'label.serviceName',
  // },
  // {
  //   name: 'additionalTaxType',
  //   label: 'label.additionalTaxType',
  // },
  // {
  //   name: 'additionalTaxDescription',
  //   label: 'label.additionalTaxDescription',
  // },
  // {
  //   name: 'additionalTaxRate',
  //   label: 'label.additionalTaxRate',
  //   type: 'number',
  // },
];

export const groupNamePermissions = {
  getTaxconfig: 'GET_TAX_CONFIG',
  modifyTaxconfig: 'MODIFY_TAX_CONFIG',
  createTaxconfig: 'CREATE_TAX_CONFIG',
  createRegionMapConfig: 'CREATE_REGION_MAP_CONFIG',
  modifyRegionMapConfig: 'MODIFY_REGION_MAP_CONFIG',
  getRegionMapConfig: 'GET_REGION_MAP_CONFIG',
  createZeroRateConfig: 'CREATE_ZERO_RATE_CONFIG',
  modifyZeroRateConfig: 'MODIFY_ZERO_RATE_CONFIG',
  getZeroRateConfig: 'GET_ZERO_RATE_CONFIG',
  GET_TAX_TYPE_CONFIG: 'GET_TAX_TYPE_CONFIG',
  MODIFY_TAX_TYPE_CONFIG: 'MODIFY_TAX_TYPE_CONFIG',
  CREATE_TAX_TYPE_CONFIG: 'CREATE_TAX_TYPE_CONFIG',
};

export { dataFieldViewTaxCode };
