import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { TitleFrom } from '../../../components/common';
import { createDiscountTrigger } from '../actions';
import { makeGetDiscountTriggerList } from '../selectors';
import DiscountTriggerForm from './DiscountTriggerForm';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { checkPermissionCeateDiscountOffer } from '../CheckPermission';

const DiscountTrigger = ({ createDiscountTrigger, permissionsPricing, location }) => {
  const { t } = useTranslation('common');
  const onSubmit = payload => {
    return createDiscountTrigger(payload);
  };

  let modeCreateDiscountOffer = 0;

  if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
    const listPermissionPricing = permissionsPricing.pricingModulePermissions;
    modeCreateDiscountOffer = checkPermissionCeateDiscountOffer({ listPermission: listPermissionPricing });
  }

  if (modeCreateDiscountOffer !== 2) return null;

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={{
          pathname: RouteNames.discountTrigger.path,
          state: location && location.state ? location.state : null,
        }}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.productFamilyPricing.path },
          { name: t('pricingPage:sidebar.discountTrigger') },
        ]}
      />
      <br />
      <div className="card card-statistics mb-30">
        <TitleFrom wrapperClass="pl-2" title={t('label.createNewDiscountTrigger')} />

        <DiscountTriggerForm onSubmit={onSubmit} isCreate modeCreateDiscountOffer={modeCreateDiscountOffer} />
      </div>
    </div>
  );
};
DiscountTrigger.propTypes = {
  createDiscountTrigger: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
};

DiscountTrigger.defaultProps = {
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  discountTriggerList: makeGetDiscountTriggerList() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});
export default connect(mapStateToProps, { createDiscountTrigger })(DiscountTrigger);
