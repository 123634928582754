import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
import { getInvoiceUnitById, getTenantInvoiceTemplates, getNotesUnitById } from '../../api';
import { handleError } from '../../utils/utils';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';

export function* getInvoiceByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceUnitById(payload));
    if (response.getInvoiceUnitById) {
      cb(response.getInvoiceUnitById);
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* getTenantInvoiceTemplatesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantInvoiceTemplates(payload));
    if (response.getTenantInvoiceTemplates) {
      yield put(actions.getTenantInvoiceTemplatesSuccess(response.getTenantInvoiceTemplates));
      if (cb) cb({ success: true, data: response.getTenantInvoiceTemplates });
    } else {
      yield put(actions.getTenantInvoiceTemplatesSuccess([]));
      if (cb) cb({ success: false, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getTenantInvoiceTemplatesSuccess([]));
    yield put(isEndConnected());
  }
}

export function* getNoteUnitByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNotesUnitById(payload));
    if (response.getNotesUnitById) {
      cb(response.getNotesUnitById);
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export default function* invoiceSaga() {
  yield takeLatest(types.GET_INVOICE_BY_ID, getInvoiceByIdSaga);
  yield takeEvery(types.GET_INVOICE_TEMPLATE, getTenantInvoiceTemplatesSaga);
  yield takeEvery(types.GET_NOTE_BY_ID, getNoteUnitByIdSaga);
}
