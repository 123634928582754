import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest, mutationRequest } from 'utils/request';
import { toast } from 'react-toastify';
import { getUserByUserId, modifyUser } from '../../api';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';
import { isConnecting, isEndConnected } from '../Loader/actions';

export function* getUserByIdSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUserByUserId());
    if (response.getUserByUserId) {
      yield put(actions.getUserByIdSuccess(response.getUserByUserId));
      if (cb) cb({ success: true, data: response.getUserByUserId });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      if (cb) cb({ success: true, data: null });
    }
    toast(`Get user by id failed! ${err}`, { type: toast.TYPE.ERROR });
  }
}

// ------ modify User
export function* modifyUserSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyUser(payload));
    if (response.modifyUser) {
      yield put(actions.getUserById());
      if (cb) {
        cb({ success: true, data: response.modifyUser });
      }
      toast.success(i18n.t('common:apiMessage.modifyUserSuccessfully'));
    } else if (cb) {
      cb({ success: false, data: {} });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());

    if (cb) {
      cb({ success: false, data: {} });
    }
  }
}

export default function* collectionsSaga() {
  yield takeLatest(types.GET_USER_BY_ID, getUserByIdSaga);
  yield takeLatest(types.MODIFY_USER, modifyUserSaga);
}
