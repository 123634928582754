import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CurrencyExchangeSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.exchangeRateType',
      name: 'type',
      type: 'select',
      tOptions: 'selections:exchangeRateType',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-3';
}

CurrencyExchangeSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CurrencyExchangeSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CurrencyExchangeSearchForm);
