export default {
  sidebar: {
    createNewOrder: 'Create new order',
    searchList: 'Search & List',
    newOrder: 'New Order',
    modifyOrder: 'Modify Order',
    suspendOrder: 'Suspend Order',
    resumeOrder: 'Resume Order',
    cancelOrder: 'Cancel Order',
    renewOrder: 'Renew Order',
    upgradeOrder: 'Upgrade Order',
    downgradeOrder: 'Downgrade Order',
    trialSubsription: 'Trial Subsription',
    optIn: 'Opt-In',
    optOut: 'Opt-Out',
    orderSequence: 'Order Sequence',
    provisioningSequence: 'Provision Sequence',
    // provisioningMapping: 'Provisioning Data Map',
    provisioningMapping: 'Provisioning Data',
    resonCode: 'Reason Codes',
    provisioningAttributes: 'Provisioning Attributes',
    attributes: 'Attributes',
    data: 'Data',
    sequence: 'Sequence',
    searchOrder: 'Search Order',
    provisioningConfig: 'Provisioning Config',
  },
};
