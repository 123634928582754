import * as types from './types';

// export function getProductFamilyConfig(cb) {
//   return {
//     type: types.GET_PRODUCT_FAMILY_CONFIG,
//     cb,
//   };
// }

// export function getProductFamilyConfigSuccess(payload, cb) {
//   return {
//     type: types.GET_PRODUCT_FAMILY_CONFIG_SUCCESS,
//     payload,
//     cb,
//   };
// }

// export function modifyProductFamilyConfig(payload) {
//   return {
//     type: types.MODIFY_PRODUCT_FAMILY_CONFIG,
//     payload,
//   };
// }

// export function createProductFamilyConfig(payload, cb) {
//   return {
//     type: types.CREATE_PRODUCT_FAMILY_CONFIG,
//     payload,
//     cb,
//   };
// }

export function getCurrencyConfig(cb) {
  return {
    type: types.GET_CURRENCY_CONFIG,
    cb,
  };
}

export function getCurrencyConfigSuccess(payload) {
  return {
    type: types.GET_CURRENCY_CONFIG_SUCCESS,
    payload,
  };
}

export function modifyCurrencyConfig(payload) {
  return {
    type: types.MODIFY_CURRENCY_CONFIG,
    payload,
  };
}

export function createCurrencyConfig(payload, cb) {
  return {
    type: types.CREATE_CURRENCY_CONFIG,
    payload,
    cb,
  };
}

export function getGrantsConfig(cb) {
  return {
    type: types.GET_GRANTS_CONFIG,
    cb,
  };
}

export function getGrantsConfigSuccess(payload) {
  return {
    type: types.GET_GRANTS_CONFIG_SUCCESS,
    payload,
  };
}

export function modifyGrantsConfig(payload) {
  return {
    type: types.MODIFY_GRANTS_CONFIG,
    payload,
  };
}

export function createGrantsConfig(payload, cb) {
  return {
    type: types.CREATE_GRANTS_CONFIG,
    payload,
    cb,
  };
}

export function getAccumulatorsConfig(cb) {
  return {
    type: types.GET_ACCUMULATORS_CONFIG,
    cb,
  };
}

export function getAccumulatorsConfigSuccess(payload) {
  return {
    type: types.GET_ACCUMULATORS_CONFIG_SUCCESS,
    payload,
  };
}

export function modifyAccumulatorsConfig(payload) {
  return {
    type: types.MODIFY_ACCUMULATORS_CONFIG,
    payload,
  };
}

export function createAccumulatorsConfig(payload, cb) {
  return {
    type: types.CREATE_ACCUMULATORS_CONFIG,
    payload,
    cb,
  };
}

// Rate Unit
export function getRateUnit(cb) {
  return {
    type: types.GET_RATE_UNIT,
    cb,
  };
}

export function getRateUnitSuccess(payload) {
  return {
    type: types.GET_RATE_UNIT_SUCCESS,
    payload,
  };
}

export function modifyRateUnit(payload) {
  return {
    type: types.MODIFY_RATE_UNIT,
    payload,
  };
}

export function createRateUnit(payload, cb) {
  return {
    type: types.CREATE_RATE_UNIT,
    payload,
    cb,
  };
}

// Rate Unit Map
export function getRateUnitMapCfg(cb) {
  return {
    type: types.GET_RATE_UNIT_MAP,
    cb,
  };
}

export function getRateUnitMapCfgSuccess(payload) {
  return {
    type: types.GET_RATE_UNIT_MAP_SUCCESS,
    payload,
  };
}

export function modifyRateUnitMapCfg(payload) {
  return {
    type: types.MODIFY_RATE_UNIT_MAP,
    payload,
  };
}

export function createRateUnitMapCfg(payload, cb) {
  return {
    type: types.CREATE_RATE_UNIT_MAP,
    payload,
    cb,
  };
}

// Time Unit
export function getTimeUnitCfg(cb) {
  return {
    type: types.GET_TIME_UNIT_CFG,
    cb,
  };
}

export function getTimeUnitCfgSuccess(payload) {
  return {
    type: types.GET_TIME_UNIT_CFG_SUCCESS,
    payload,
  };
}

export function modifyTimeUnitCfg(payload) {
  return {
    type: types.MODIFY_TIME_UNIT_CFG,
    payload,
  };
}

export function createTimeUnitCfg(payload, cb) {
  return {
    type: types.CREATE_TIME_UNIT_CFG,
    payload,
    cb,
  };
}

// tax code
export function getTaxCodeConfig(cb) {
  return {
    type: types.GET_TAX_CODE_CONFIG_BASE,
    cb,
  };
}

export function getTaxCodeConfigSuccess(payload, cb) {
  return {
    type: types.GET_TAX_CODE_CONFIG_SUCCESS_BASE,
    payload,
    cb,
  };
}

export function modifyTaxCodeConfig(payload) {
  return {
    type: types.MODIFY_TAX_CODE_CONFIG_BASE,
    payload,
  };
}

export function createTaxCodeConfig(payload, cb) {
  return {
    type: types.CREATE_TAX_CODE_CONFIG_BASE,
    payload,
    cb,
  };
}

// get tax third party
export function get3rdPartyTaxConfig(cb) {
  return {
    type: types.GET_3RD_PARTY_TAX_CONFIG_BASE,
    cb,
  };
}

export function getUom(payload, cb) {
  return {
    type: types.GET_UOM,
    payload,
    cb,
  };
}

export function getUomSuccess(payload, cb) {
  return {
    type: types.GET_UOM_SUCCESS,
    payload,
    cb,
  };
}

export function modifyUom(payload, cb) {
  return {
    type: types.MODIFY_UOM,
    payload,
    cb,
  };
}

export function createUom(payload, cb) {
  return {
    type: types.CREATE_UOM,
    payload,
    cb,
  };
}

// Usage Type
export function getConfigUsageType(payload, cb) {
  return {
    type: types.GET_USAGE_TYPE,
    payload,
    cb,
  };
}

export function getConfigUsageTypeSuccess(payload, cb) {
  return {
    type: types.GET_USAGE_TYPE_SUCCESS,
    payload,
    cb,
  };
}

export function modifyConfigUsageType(payload, cb) {
  return {
    type: types.MODIFY_USAGE_TYPE,
    payload,
    cb,
  };
}

export function createConfigUsageType(payload, cb) {
  return {
    type: types.CREATE_USAGE_TYPE,
    payload,
    cb,
  };
}

export function searchConfigCountryCodes(payload, cb) {
  return {
    type: types.SEARCH_COUNTRY_CODES,
    payload,
    cb,
  };
}

export function searchConfigCountryCodesSuccess(payload) {
  return {
    type: types.SEARCH_COUNTRY_CODES_SUCCESS,
    payload,
  };
}

export function modifyConfigCountryCode(payload) {
  return {
    type: types.MODIFY_COUNTRY_CODES,
    payload,
  };
}

export function createConfigCountryCode(payload, cb) {
  return {
    type: types.CREATE_COUNTRY_CODES,
    payload,
    cb,
  };
}

export function searchPacProductCodes(payload, cb) {
  return {
    type: types.SEARCH_PAC_PRODUCT_CODE,
    payload,
    cb,
  };
}

export function searchPacProductCodesSuccess(payload) {
  return {
    type: types.SEARCH_PAC_PRODUCT_CODE_SUCCESS,
    payload,
  };
}

export function searchPrepaidSystemOffers(payload, cb) {
  return {
    type: types.SEARCH_PREPAID_SYSTEM_OFFERS,
    payload,
    cb,
  };
}

export function searchPrepaidSystemOffersSuccess(payload, cb) {
  return {
    type: types.SEARCH_PREPAID_SYSTEM_OFFERS_SUCCESS,
    payload,
    cb,
  };
}

export function createPrepaidSystemOffers(payload, cb) {
  return {
    type: types.CREATE_PREPAID_SYSTEM_OFFERS,
    payload,
    cb,
  };
}

export function modifyPrepaidSystemOffers(payload, cb) {
  return {
    type: types.MODIFY_PREPAID_SYSTEM_OFFERS,
    payload,
    cb,
  };
}

export function searchZoneUnit(payload, cb) {
  return {
    type: types.SEARCH_ZONE_UNIT,
    payload,
    cb,
  };
}

export function getZonesByZoneUnitId(payload, cb) {
  return {
    type: types.GET_ZONE_UNIT_BY_ID,
    payload,
    cb,
  };
}

export function createZoneUnit(payload, cb) {
  return {
    type: types.CREATE_ZONE_UNIT,
    payload,
    cb,
  };
}

export function modifyZoneUnit(payload, cb) {
  return {
    type: types.MODIFY_ZONE_UNIT,
    payload,
    cb,
  };
}

export function createRegulatoryProductCodes(payload, cb) {
  return {
    type: types.CREATE_REGULATORY_PRODUCT_CODES,
    payload,
    cb,
  };
}

export function modifyRegulatoryProductCodes(payload, cb, isDelete) {
  return {
    type: types.MODIFY_REGULATORY_PRODUCT_CODES,
    payload,
    cb,
    isDelete,
  };
}
