import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { size, cloneDeep, orderBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getPaymentSurchargeConfig, modifyPaymentSurchargeConfig, createPaymentSurchargeConfig } from '../actions';
import { makeGetPaymentSurcharge } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import {
  checkPermissionGetPaymentSurcharge,
  checkPermissionModifyPaymentSurcharge,
  checkPermissionCreatePaymentSurcharge,
} from '../CheckPermission';

class PaymentSurcharge extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    configPaymentSurchargeList: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.paymentSurcharge) return null;
    return {
      configPaymentSurchargeList:
        props.paymentSurcharge && props.paymentSurcharge.configPaymentSurchargeList
          ? props.paymentSurcharge.configPaymentSurchargeList
          : [],
      oldValue: props.paymentSurcharge,
      dataSubmit: [],
    };
  }

  componentDidMount() {
    this.doGetPaymentSurchargeConfig();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { configPaymentSurchargeList, dataSubmit } = this.state;
    const newData = cloneDeep(configPaymentSurchargeList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ configPaymentSurchargeList: newData });
  };

  doGetPaymentSurchargeConfig = () => {
    const { getPaymentSurchargeConfig } = this.props;
    getPaymentSurchargeConfig('', () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewProfile = () => {
    let indexNewItem = 0;
    const { configPaymentSurchargeList, dataSubmit } = this.state;
    const newData = cloneDeep(configPaymentSurchargeList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      noOfDays: '',
      accountType: null,
      paymentMethod: null,
      surcharge: null,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ configPaymentSurchargeList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { configPaymentSurchargeList, dataSubmit } = this.state;
    const newData = cloneDeep(configPaymentSurchargeList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ configPaymentSurchargeList: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit } = this.state;
    const { modifyPaymentSurchargeConfig, createPaymentSurchargeConfig, paymentSurcharge } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (paymentSurcharge && paymentSurcharge.id) {
      const newPayload = {
        id: paymentSurcharge.id,
        configPaymentSurchargeList: dataSubmit,
      };
      return modifyPaymentSurchargeConfig(newPayload, ({ success }) => {
        if (success) this.doGetPaymentSurchargeConfig();
      });
    }

    const newPayloadCreate = cloneDeep(dataSubmit).map(item => {
      const newItem = item;
      delete newItem.index;
      return newItem;
    });
    createPaymentSurchargeConfig({ configPaymentSurchargeList: newPayloadCreate }, ({ success }) => {
      if (success) this.doGetPaymentSurchargeConfig();
    });
  };

  render() {
    const { configPaymentSurchargeList, isSearching, wasValidated } = this.state;
    const { paymentSurcharge, permissionsPayment, t, isComponent } = this.props;
    let modeGetPaymentSurchargeConfig = 0;
    let modeModifyPaymentSurchargeConfig = 0;
    let modeCreatePaymentSurchargeConfig = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermissionsPayment = permissionsPayment.paymentModulePermissions;
      modeGetPaymentSurchargeConfig = checkPermissionGetPaymentSurcharge({
        listPermission: listPermissionsPayment,
      });
      modeCreatePaymentSurchargeConfig = checkPermissionCreatePaymentSurcharge({
        listPermission: listPermissionsPayment,
      });
      modeModifyPaymentSurchargeConfig = checkPermissionModifyPaymentSurcharge({
        listPermission: listPermissionsPayment,
      });
    }

    if (!modeGetPaymentSurchargeConfig) return '';

    const paymentSurchargeColumns = [
      {
        name: 'accountType',
        label: t('label.accountType'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.accountType}
            tOptions="selections:accountType"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountType"
          />
        ),
      },
      {
        name: 'paymentMethod',
        label: t('label.paymentMethod'),
        style: { textAlign: 'center', minWidth: '110px' },
        // required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.paymentMethod}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="paymentMethod"
            tOptions="selections:paymentMethod"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'noOfDays',
        label: t('label.numberOfDays'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.noOfDays}
            type="number"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="noOfDays"
          />
        ),
      },
      {
        name: 'surcharge',
        label: t('label.surchargeAmount'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.surcharge}
            wrapperClass="col-md-12"
            disabled={item.recurringFeeAdvanceDom}
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="surcharge"
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataOnlyViewInTable =
      configPaymentSurchargeList && configPaymentSurchargeList.length
        ? orderBy(
            configPaymentSurchargeList.filter(item => size(item) > 1),
            ['index'],
            ['asc']
          )
        : [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentAdmin'), url: RouteNames.paymentConfiguration.path },
            { name: t('paymentPage:sidebar.paymentSurcharge') },
          ]}
        />
        <FormWithTableItem title={t('paymentPage:sidebar.paymentSurcharge')}>
          <form
            onSubmit={modeModifyPaymentSurchargeConfig === 2 ? this.hanldeSubmit : () => {}}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <br />
            <div>
              <DataTable columns={paymentSurchargeColumns} data={newDataOnlyViewInTable} isLoading={isSearching} />
            </div>
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreatePaymentSurchargeConfig === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProfile}>
                  +
                  {t('label.addPaymentSurcharge')}
                </button>
              )}
              {modeModifyPaymentSurchargeConfig === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {paymentSurcharge && paymentSurcharge.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetPaymentSurchargeConfig}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </FormWithTableItem>
      </div>
    );
  }
}

PaymentSurcharge.propTypes = {
  paymentSurcharge: PropTypes.objectOf(PropTypes.any),
  getPaymentSurchargeConfig: PropTypes.func.isRequired,
  createPaymentSurchargeConfig: PropTypes.func.isRequired,
  modifyPaymentSurchargeConfig: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

PaymentSurcharge.defaultProps = {
  paymentSurcharge: {},
  permissionsPayment: {},
};
const mapStateToProps = createStructuredSelector({
  // paymentSurcharge: makeGetAdvanceBillingConfig() || {},
  paymentSurcharge: makeGetPaymentSurcharge() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

const newPaymentSurcharge = connect(mapStateToProps, {
  getPaymentSurchargeConfig,
  modifyPaymentSurchargeConfig,
  createPaymentSurchargeConfig,
})(PaymentSurcharge);

export default withTranslation('common')(newPaymentSurcharge);
