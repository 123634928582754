import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'arLevel',
        'taxTreatment',
        'reason',
        'arThreshold',
        'accountType',
        'paymentMethod',
      ])}`
    : '';
  const getWriteoffReport = `getWriteoffReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getWriteoffReport} {
        id
        invoiceId
        accountId
        firstName
        lastName
        company
        processDate
        arLevel
        taxTreatment
        arCrDrType
        amount
        accountType
        paymentMethod
        reason
        writeOffSummaryTotal
        addOnTaxAmount
        addOnTaxRate
        taxAmount
        taxRate
      }
    }
  `;
};
