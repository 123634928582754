import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import RouteNames from '../../App/RouteNames';
import { getPageTotalCount } from '../../../utils/utils';
import { TablePagination, SearchForm, DataTable, Breadcrumb } from '../../../components/common';
import AccountSort from '../../../constantsApp/customer/AccountSort';
import { makeGetListCustomers } from '../../CustomerPage/selectors';
import { searchAccountSelectors, searchAccountInputs } from './constants';
import { getParentAccountDetail, getCustomers } from '../../CustomerPage/actions';
import './styles.scss';

const tableColumns = [
  {
    name: 'id',
    label: 'common:label.ACCTNo',
    render: (colName, item) => (
      <Link className="text-success" to={RouteNames.prettifyPath(RouteNames.customerInfo.path, item.id)}>
        {item.id}
      </Link>
    ),
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
    sortable: true,
    style: { minWidth: '100px' },
    render: (colName, item) => {
      const contact = item.contacts ? item.contacts[0] || {} : {};
      return <span>{contact.firstName}</span>;
    },
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
    sortable: true,
    style: { minWidth: '100px' },
    render: (colName, item) => {
      const contact = item.contacts ? item.contacts[0] || {} : {};
      return <span>{contact.lastName}</span>;
    },
  },
  {
    name: 'organization',
    label: 'common:label.organization',
    sortable: true,
    style: { minWidth: '110px' },
    render: (colName, item) => {
      const contact = item.contacts ? item.contacts[0] || {} : {};
      return <span>{contact.organization}</span>;
    },
  },
  {
    name: 'email',
    label: 'common:label.email',
    render: (colName, item) => {
      const contact = item.contacts ? item.contacts[0] || {} : {};
      return <span>{contact.email}</span>;
    },
  },
  {
    name: 'city',
    label: 'common:label.city',
    sortable: true,
    style: { minWidth: '100px' },
    render: (name, item) => {
      return item && item.addresses && item.addresses[0] ? item.addresses[0].city : null;
    },
  },
  {
    name: 'state',
    label: 'common:label.state',
    sortable: true,
    style: { minWidth: '100px' },
    render: (name, item) => {
      return item && item.addresses && item.addresses[0] ? item.addresses[0].state : null;
    },
  },
  {
    name: 'currency',
    label: 'common:label.currency',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reason')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const detectParentIds = customers => {
  const parentIds = customers
    .filter(c => {
      return c.parentId;
    })
    .map(c => c.parentId);
  return parentIds.filter((item, index) => parentIds.indexOf(item) === index);
};
class TableAccountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: {
        subscriptionStatus: props.subscriptionStatus,
        trialLevel: props.trialLevel,
        trialStatus: props.trialStatus,
      },
      sorted: {},
      totalCount: 0,
      isSearching: true,
      accountList: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      accountList: props.listCustomers,
      totalCount: getPageTotalCount({ ...state, items: props.listCustomers }),
    };
  }

  componentWillReceiveProps = props => {
    const { filter, totalCount, page, size } = this.state;
    if (filter.subscriptionStatus && filter.subscriptionStatus !== props.subscriptionStatus) {
      this.setState(
        {
          filter: {
            ...filter,
            subscriptionStatus: props.subscriptionStatus,
            trialLevel: props.trialLevel,
            trialStatus: props.trialStatus,
          },
        },
        () => {
          this.doSearchOffers();
        }
      );
      return;
    }
    if (props.listCustomers) {
      this.setState({
        accountList: props.listCustomers,
        totalCount: getPageTotalCount({ totalCount, page, size, items: props.listCustomers }),
      });
    }
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchOffers());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchOffers());
  };

  onValueChange = (name, value) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, [name]: value } });
  };

  onSubmitSearch = () => {
    this.setState({ page: 0 }, () => this.doSearchOffers());
  };

  onSubmitClearSearch = filter => {
    const newFilter = cloneDeep(filter);
    const { isSubscriptionStatusFilter } = this.props;
    if (isSubscriptionStatusFilter) {
      newFilter.subscriptionStatus = null;
    }
    this.setState({ filter: newFilter, page: 0 }, () => this.doSearchOffers());
  };

  doSearchOffers = () => {
    const { filter, page, size, sorted } = this.state;
    const { getCustomers, getParentAccountDetail } = this.props;
    if (!filter.status) delete filter.status;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? AccountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getCustomers(payload, ({ data }) => {
      this.setState({ isSearching: false });
      detectParentIds(data).map(parentId => getParentAccountDetail(parentId));
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchOffers());
  };

  getType = () => {
    const { type } = this.props;
    switch (type) {
      case 'modify-quote':
        return 'Modify';
      case 'suspend':
        return 'Suspend';
      case 'resume':
        return 'Resume';
      case 'cancel':
        return 'Cancel';
      case 'renew':
        return 'Renew';
      case 'new-quote':
        return 'Create';

      default:
        return 'Modify';
    }
  };

  getBreadcrums = () => {
    const { type, t } = this.props;
    switch (type) {
      case 'modify-quote':
        return t('quotePage:sidebar.modifyQuote');
      case 'new-quote':
        return t('quotePage:sidebar.newQuote');
      case 'upgrade-quote':
        return t('quotePage:sidebar.upgradeQuote');
      case 'downgrade-quote':
        return t('quotePage:sidebar.downgradeQuote');
      default:
        return t('quotePage:sidebar.modifyQuote');
    }
  };

  render() {
    const { accountList, sorted, isSearching, page, size, totalCount, filter } = this.state;
    const {
      onClickRow,
      onSelectNext,
      modeNewQuoteType,
      t,
      statusRemove,
      isSubscriptionStatusFilter,
      linkTo,
      isNoPageTitle,
      // isCheckSalesAgentId,
    } = this.props;
    let newSelectorSearch = searchAccountSelectors;
    if (statusRemove)
      newSelectorSearch = [
        ...searchAccountSelectors,
        {
          name: 'subscriptionStatus',
          label: 'common:label.subscriptionStatus',
          options: t('selections:subscriptionStatus')().filter(val => statusRemove.indexOf(val.value) === -1),
          type: 'select',
          isClearable: !!isSubscriptionStatusFilter,
        },
      ];
    const tableConfig = {
      columns: [
        {
          name: 'Selected',
          label: 'common:label.selected',
          render: (colName, item) => {
            return (
              <button
                type="button"
                onClick={() => onSelectNext(item)}
                className="button x-small btn-select-next"
                disabled={modeNewQuoteType === 1}
              >
                {t('label.next')}
              </button>
            );
          },
        },
        ...tableColumns,
      ],
      data: accountList,
      sorted: { ...sorted },
      onSort: this.onSortColumn,
    };
    return (
      <div>
        {!isNoPageTitle && (
          <div className="page-title">
            <div className="row">
              <div className="col-sm-6">
                <div className="col-sm-6">
                  <Link className="button x-small" to={linkTo}>
                    {t('common:label.back')}
                  </Link>
                </div>
              </div>
              <div className="col-sm-6">
                <Breadcrumb items={[{ name: this.getBreadcrums() }]} />
              </div>
            </div>
          </div>
        )}
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <SearchForm
                    searchSelectors={newSelectorSearch}
                    isHaveClearButton
                    searchInputs={searchAccountInputs}
                    onClickClearButton={this.onSubmitClearSearch}
                    onSubmit={this.onSubmitSearch}
                    onValueChange={this.onValueChange}
                    filter={filter}
                    isSubmitting={isSearching}
                    statusRemove={statusRemove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                {...tableConfig}
                onClickRow={onClickRow}
                isLoading={isSearching}
                noDataMessage={`${t('message.noAccountSubscription')} ${this.getType()}`}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

TableAccountContainer.propTypes = {
  onClickRow: PropTypes.func,
  onSelectNext: PropTypes.func,
  selectItem: PropTypes.objectOf(PropTypes.any),
  listCustomers: PropTypes.arrayOf(PropTypes.any),
  getCustomers: PropTypes.func,
  subscriptionStatus: PropTypes.string,
  trialLevel: PropTypes.string,
  trialStatus: PropTypes.string,
  type: PropTypes.string,
  getParentAccountDetail: PropTypes.func.isRequired,
  modeNewQuoteType: PropTypes.number,
  statusRemove: PropTypes.arrayOf(PropTypes.any),
  t: PropTypes.func,
  isSubscriptionStatusFilter: PropTypes.bool,
  linkTo: PropTypes.string,
  isCheckSalesAgentId: PropTypes.bool,
};

TableAccountContainer.defaultProps = {
  onClickRow: () => {},
  onSelectNext: () => {},
  listCustomers: {},
  getCustomers: () => {},
  selectItem: null,
  subscriptionStatus: null,
  type: '',
  trialLevel: null,
  trialStatus: null,
  modeNewQuoteType: 2,
  statusRemove: [],
  t: () => {},
  isSubscriptionStatusFilter: false,
  linkTo: undefined,
  isCheckSalesAgentId: false,
};

const mapStateToProps = createStructuredSelector({
  listCustomers: makeGetListCustomers() || [],
});

const newTableAccountContainer = connect(mapStateToProps, { getCustomers, getParentAccountDetail })(
  TableAccountContainer
);

export default withTranslation('common')(newTableAccountContainer);
