import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class FolioDataExtractSearchForm extends SearchComponent {
  searchInputs = [
    {
      name: 'organization',
      label: 'common:label.organization',
    },
    {
      name: 'commercialName',
      label: 'common:label.commercialName',
    },
    {
      name: 'clientId',
      label: 'common:label.clientId',
    },
    {
      name: 'salesAgentId',
      label: 'common:label.salesAgentId',
    },
    {
      name: 'productFamily',
      label: 'common:label.productFamily',
    },
    {
      label: 'label.folioDateMoreThan',
      name: 'folioDate',
      type: 'date',
    },
  ];

  btnSpan = 'col-md-6';
}

FolioDataExtractSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

FolioDataExtractSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(FolioDataExtractSearchForm);
