import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['status', 'sharingType', 'index']);
  const modifySharingGroup = `modifySharingGroup(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifySharingGroup} {
      id
      accountId
      subscriptionId
      priceOfferId
      priceOfferName
      status
      sharingType
      discountOffers {
        index
        discountOfferId
        discountOfferName
      }
    }
  }
`;
};
