import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, FormCollapse, TitleFrom } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getLocations, modifyLocation, createLocation } from '../actions';
import { getAddressesForm } from '../../../utils/utils';

class Location extends React.PureComponent {
  state = {
    data: {},
    wasValidated: false,
    isModify: false,
    formValid: true,
    activeTab: {},
    dataSubmit: {},
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetLocations();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetLocations = () => {
    const { getLocations } = this.props;
    getLocations('', ({ data }) => {
      this.setState({
        data: data || {},
        isModify: !!data,
        dataSubmit: data ? { id: data.id, address: [] } : { address: [] },
      });
    });
  };

  onChange = ({ index, name, value }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData.address[index][name] = value;
      if (!dataSubmit.address[index]) dataSubmit.address[index] = { index: newData.address[index].index };
      dataSubmit.address[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.address) newData.address = [];
      newData.address.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.address.push({
        index: lastIndex + 1,
        street: '',
        extraLine: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        code: '',
      });
      dataSubmit.address[newData.address.length - 1] = {
        index: lastIndex + 1,
        street: '',
        extraLine: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        code: '',
      };
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit } = this.state;
    const { modifyLocation, createLocation } = this.props;
    const { type, ...payload } = cloneDeep(data);
    if (isModify) {
      const { type, ...payloadModify } = cloneDeep(dataSubmit);
      if (payloadModify && payloadModify.address) {
        payloadModify.address = payloadModify.address.filter(val => !!val);
      }
      return modifyLocation({ ...payloadModify }, ({ success }) => {
        if (success) this.doGetLocations();
      });
    }
    return createLocation({ ...payload }, ({ success }) => {
      if (success) this.doGetLocations();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onHandleChange = ({ name, value }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    newData[name] = value;
    dataSubmit[name] = value;
    this.setState({ data: newData, dataSubmit });
  };

  onRemoveAddress = index => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    newData.address[index] = { index: newData.address[index].index };
    dataSubmit.address[index] = { index: newData.address[index].index };
    this.setState({ data: newData, dataSubmit });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateLocationConfig, modeGetLocationConfig, modeModifyLocationConfig } = permissions;
    if (!modeGetLocationConfig) return '';
    const { data, activeTab, wasValidated, isModify } = this.state;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.location')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.location')} />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="col-md-12 pl-2 pt-2 pb-2 pr-0 row">
                <GenericInput
                  label={t('label.name')}
                  value={data.name}
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => this.onHandleChange({ value, name })}
                  name="name"
                />
                <GenericInput
                  label={t('label.description')}
                  value={data.description}
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => this.onHandleChange({ value, name })}
                  name="description"
                />
                <GenericInput
                  label={t('label.type')}
                  value={data.type}
                  wrapperClass="col-md-4"
                  type="select"
                  onChange={({ name, value }) => this.onHandleChange({ value, name })}
                  name="type"
                  tOptions="selections:revenueConfigType"
                  disabled
                  readOnly={isModify}
                />
              </div>
              {data &&
                data.address &&
                data.address.length > 0 &&
                data.address.map((item, index) => {
                  const [id, ...addressFields] = getAddressesForm({
                    country: item.country,
                    state: item.state,
                    city: item.city,
                  });
                  if (size(item) < 2) return '';
                  return (
                    <FormCollapse
                      key={`key-${index}`}
                      isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                      title={`${item.city || ''}-${item.state || ''}-${item.country || ''}`}
                      state={`state-${index}`}
                      onToggleTab={this.onToggleTab}
                    >
                      <div className="col-md-12 row p-3">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn-delete float-right"
                            onClick={evt => this.onRemoveAddress(index)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                        {addressFields.map((field, idxField) => {
                          return (
                            <GenericInput
                              key={field.labelFilter}
                              label={field.labelFilter || ''}
                              value={item[field.fieldName || field.fieldSelect]}
                              wrapperClass="col-md-4"
                              onChange={({ name, value }) => this.onChange({ name, value, index })}
                              name={field.fieldName || field.fieldSelect}
                              type={field.fieldSelect ? 'select' : 'text'}
                              options={field.optionSelect}
                              required={
                                addressFields.length > idxField + 1 &&
                                ['extraLine', 'landmark', 'code'].indexOf(field.fieldName) === -1
                              }
                            />
                          );
                        })}
                      </div>
                    </FormCollapse>
                  );
                })}

              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateLocationConfig === 2 && (
                  <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                    +
                    {t('label.addNewLocation')}
                  </button>
                )}
                {modeModifyLocationConfig === 2 && (
                  <button type="submit" className="button button-border x-small float-right">
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetLocations}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Location.propTypes = {
  getLocations: PropTypes.func,
  modifyLocation: PropTypes.func,
};

Location.defaultProps = {
  getLocations: () => {},
  modifyLocation: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getLocations,
    modifyLocation,
    createLocation,
  })(withRouter(Location))
);
