import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <div
      style={{
        marginRight: 2,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
        <path
          fill="#8891A5"
          fillRule="nonzero"
          d="M5 3.586L8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586z"
        />
      </svg>
    </div>
  </components.DropdownIndicator>
);
const CustomSelect = ({ ...props }) => {
  return (
    <Select
      className="react-select"
      components={{ DropdownIndicator }}
      classNamePrefix="custom-react-select"
      styles={{
        control: props => ({
          ...props,
          backgroundColor: '#f6f7f8',
          border: '0px solid transparent',
          borderRadius: 0,
          minHeight: '27px',
        }),
        indicatorContainer: props => ({
          ...props,
          padding: '4px 8px',
        }),
        indicatorSeparator: props => ({
          ...props,
          marginTop: '7px',
          marginBottom: '7px',
        }),
      }}
      isClearable
      {...props}
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
};

CustomSelect.defaultProps = {
  options: [],
};
export default CustomSelect;
