import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';

export default function FormInvoiceUnit({ data, onChange, onHandleSubmit, isEnableBtn }) {
  const { t } = useTranslation('common');
  return (
    <div className="form-group col-md-12 p-5">
      <div className="col-md-12 row">
        <GenericInput
          name="status"
          label="common:label.collectionUnitStatus"
          value={data.status}
          type="select"
          isClearable
          wrapperClass="col-md-4 collection-dropdown"
          tOptions="selections:statusCollections"
          onChange={onChange}
        />
        <GenericInput
          name="gracePeriodDays"
          label="common:label.gracePeriod"
          value={data.gracePeriodDays}
          type="number"
          wrapperClass="col-md-4"
          disabled={data.status !== 'GRACE_PERIOD_REQUESTED'}
          onChange={onChange}
        />
        <GenericInput
          label="common:label.notes"
          type="textarea"
          onChange={onChange}
          name="notes"
          value={data.notes}
          wrapperClass="col-md-12"
        />
        <div className="col-md-12 pt-4 action-buttons">
          <button
            type="button"
            className="button button-border x-small float-right"
            onClick={onHandleSubmit}
            disabled={!isEnableBtn}
          >
            {t('label.apply')}
          </button>
        </div>
      </div>
    </div>
  );
}

FormInvoiceUnit.propTypes = {
  onChange: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  isEnableBtn: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
};

FormInvoiceUnit.defaultProps = {
  isEnableBtn: false,
  data: {},
  onChange: () => {},
  onHandleSubmit: () => {},
};
