import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'status', 'invoiceSummaryType']);
  const createConfigPaymentAllocation = `createConfigPaymentAllocation(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigPaymentAllocation} {
      id
      name
      status
    }
  }
`;
};
