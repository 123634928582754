import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort, isSearchAll }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}`
    : '';
  const searchQuery = isSearchAll
    ? `searchRoles(page: 1, size: 99999${sortStr}${filterStr} )`
    : `searchRoles(page: ${page}, size: ${size}${sortStr}${filterStr})`;

  return gql`
    query {
      ${searchQuery} {
        id
        name
        description
        type
        status
      }
    }
  `;
};
