import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  let dataModify = parseToMutationRequest(data, [
    'status',
    'leadingZeroes',
    'name',
    'length',
    'index',
    'locationIndex',
    'startingNumber',
    'accountType',
    'refIndex',
    'type',
  ]);
  dataModify = dataModify.replace(/subName/gi, 'name');
  const modifyGLAccountConfig = `modifyGlAccountConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyGLAccountConfig} {
      id
    }
  }
`;
};
