import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest, mutationRequest } from 'utils/request';
import { toast } from 'react-toastify';
import { getConfigCorrespondence, createConfigCorrespondence, modifyConfigCorrespondence } from 'api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import i18n from '../../i18n';

export function* getConfigCorrespondenceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigCorrespondence(payload));
    if (response.getConfigCorrespondence) {
      if (cb) cb({ success: true, data: response.getConfigCorrespondence });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyConfigCorrespondenceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigCorrespondence(payload));
    if (response.modifyConfigCorrespondence) {
      toast.success(i18n.t('common:apiMessage.modifyCorrespondenceTemplateSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyConfigCorrespondence });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createConfigCorrespondenceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigCorrespondence(payload));
    if (response.createConfigCorrespondence) {
      toast.success(i18n.t('common:apiMessage.createCorrespondenceTemplateSuccessfully'));
      if (cb) cb({ success: true, data: response.createConfigCorrespondence });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* correspondenceSaga() {
  yield takeLatest(types.GET_CONFIG_CONRRESPONDENCE, getConfigCorrespondenceSaga);
  yield takeLatest(types.MODIFY_CONFIG_CONRRESPONDENCE, modifyConfigCorrespondenceSaga);
  yield takeLatest(types.CREATE_CONFIG_CONRRESPONDENCE, createConfigCorrespondenceSaga);
}
