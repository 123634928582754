import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, FormCollapse, TitleFrom, Switch } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getDivision, modifyDivision, createDivision } from '../actions';
import { listFieldsDivisions } from '../constants';
import ModalCompanyLocation from '../../../components/common/ModalCompanyLocation';
import ModalCompanyEnterprise from '../../../components/common/ModalCompanyEnterprise';

class Divisions extends React.PureComponent {
  state = {
    data: [],
    wasValidated: false,
    formValid: true,
    activeTab: {},
    showActiveOnly: true,
    isOpenModalName: '',
    dataSubmit: [],
    defaultData: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetDivision();
    // this.checkExistData();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetDivision = () => {
    const { getDivision } = this.props;
    const { showActiveOnly } = this.state;
    getDivision({ showActiveOnly }, ({ data }) => {
      this.setState({
        data: data || [],
        dataSubmit: [],
      });
    });
    this.checkExistData();
  };

  checkExistData = () => {
    const { getDivision } = this.props;
    getDivision({ showActiveOnly: false }, ({ data }) => {
      this.setState({
        isModify: !!data,
        id: data ? data[0].id : null,
        defaultData: data || [],
      });
    });
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit, defaultData } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (defaultData)
        defaultData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payloadAddNew = {
        index: lastIndex + 1,
        name: '',
        description: '',
        shortName: '',
        code: '',
        enterprise: null,
        locationIndex: null,
        autoCreateLegalEntities: false,
        autoCreateBusinessUnits: false,
        status: '',
      };
      newData.push(payloadAddNew);
      dataSubmit[newData.length - 1] = payloadAddNew;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit, id } = this.state;
    const { modifyDivision, createDivision } = this.props;
    const payload = cloneDeep(data).map(val => {
      const { address, id, index, ...rest } = val;
      return rest;
    });
    if (isModify && data) {
      const payloadModify = dataSubmit
        .filter(val => !!val)
        .map(val => {
          const { address, id, ...rest } = val;
          return rest;
        });
      return modifyDivision({ divisionList: payloadModify, id }, ({ success }) => {
        if (success) this.doGetDivision();
      });
    }
    return createDivision({ divisionList: payload }, ({ success }) => {
      if (success) this.doGetDivision();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE';
      newData[index][name] = value;
      if (!dataSubmit[index]) dataSubmit[index] = { index: newData[index].index };
      dataSubmit[index][name] = newValue;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSwitch = () => {
    const { showActiveOnly } = this.state;
    this.setState({ showActiveOnly: !showActiveOnly }, () => this.doGetDivision());
  };

  onOpenPopup = (name, index) => {
    this.setState({ isOpenModalName: name, indexSelect: index });
  };

  onSelectItem = (value, row) => {
    const { data, isOpenModalName, indexSelect, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    if (!dataSubmit[indexSelect]) dataSubmit[indexSelect] = { index: newData[indexSelect].index };
    if (isOpenModalName === 'locationIndex') {
      if (value) {
        newData[indexSelect].address = row || null;
        newData[indexSelect].locationIndex = value;
        dataSubmit[indexSelect].locationIndex = value;
      }
      if (!value) {
        newData[indexSelect].locationIndex = null;
        newData[indexSelect].address = null;
        dataSubmit[indexSelect].locationIndex = null;
      }
    } else {
      if (value) {
        newData[indexSelect].enterprise = value;
        newData[indexSelect].locationIndex = row.locationIndex;
        newData[indexSelect].address = row.address || null;
        dataSubmit[indexSelect].locationIndex = row.locationIndex;
        dataSubmit[indexSelect].enterprise = value;
      }
      if (!value) {
        newData[indexSelect].enterprise = null;
        newData[indexSelect].locationIndex = null;
        newData[indexSelect].address = null;
        dataSubmit[indexSelect].locationIndex = null;
        dataSubmit[indexSelect].enterprise = null;
      }
    }

    this.setState({ data: newData, isOpenModalName: '', dataSubmit });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateDivisionsConfig, modeGetDivisionsConfig, modeModifyDivisionsConfig } = permissions;
    if (!modeGetDivisionsConfig) return '';
    const { data, activeTab, wasValidated, isModify, showActiveOnly, isOpenModalName } = this.state;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.companyDivisions')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.companyDivisions')} />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="col-md-12 pt-0 pb-5">
                <Switch
                  onChange={this.onChangeSwitch}
                  wrapperClass={showActiveOnly ? 'switch-active float-right' : 'switch-non-active float-right'}
                  title={t('label.ACTIVE')}
                  checked={showActiveOnly}
                />
                <span className="float-right title-fetch">{`${t('label.fetch')}:`}</span>
              </div>

              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <FormCollapse
                    key={`key-${index}`}
                    isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                    title={`${item.code || ''}-${item.name || ''}`}
                    state={`state-${index}`}
                    onToggleTab={this.onToggleTab}
                  >
                    <div className="col-md-12 row p-3">
                      {listFieldsDivisions.map((val, idxField) => {
                        if (val.name === 'locationIndex') {
                          return (
                            <GenericInput
                              value={
                                item.address
                                  ? `${item.address.city}, ${item.address.state}, ${item.address.country}`
                                  : ''
                              }
                              wrapperClass="col-md-4"
                              type={val.tOptions ? 'select' : 'text'}
                              onChange={({ name, value }) => this.onHandleChange({ value, name })}
                              onClick={() => this.onOpenPopup(val.name, index)}
                              {...val}
                            />
                          );
                        }
                        return (
                          <GenericInput
                            value={val.isBoolean ? (item[val.name] ? 'TRUE' : 'FALSE') : item[val.name]}
                            wrapperClass="col-md-4"
                            type={val.tOptions ? 'select' : 'text'}
                            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
                            onClick={val.fa ? () => this.onOpenPopup(val.name, index) : () => {}}
                            {...val}
                          />
                        );
                      })}
                    </div>
                  </FormCollapse>
                ))}

              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateDivisionsConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.addNewItem}
                  >
                    +
                    {t('label.addNewDivisions')}
                  </button>
                )}
                {modeModifyDivisionsConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeModifyDivisionsConfig === 1 || !data || !data.length}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetDivision}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ModalCompanyLocation
          isOpen={isOpenModalName === 'locationIndex'}
          onSelect={this.onSelectItem}
          selectedParentId={data.locationIndex}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalCompanyEnterprise
          isOpen={isOpenModalName === 'enterprise'}
          onSelect={this.onSelectItem}
          selectedParentId={data.enterprise}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
      </div>
    );
  }
}

Divisions.propTypes = {
  getDivision: PropTypes.func,
  modifyDivision: PropTypes.func,
};

Divisions.defaultProps = {
  getDivision: () => {},
  modifyDivision: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getDivision,
    modifyDivision,
    createDivision,
  })(withRouter(Divisions))
);
