import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { roundFloat } from '../../../utils/utils';
import RouteNames from '../../App/RouteNames';
import { RevertForm } from '../../../components/ArOperationsHub';
import PageTitle from '../../../components/PageTitle';
import { processWriteoffReversal } from '../actions';

class ReverseWriteOff extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataApply: {},
    };
  }

  componentDidMount() {
    const {
      location: { state },
      history,
    } = this.props;
    if (state) {
      const { writeOffItemSelected } = state; // state push from parent component
      const { dataApply } = this.state;
      dataApply.disputeAmount = writeOffItemSelected.amount;
      dataApply.disputePercent = writeOffItemSelected.percent;
      dataApply.id = writeOffItemSelected.parentId;
      dataApply.userId = writeOffItemSelected.userId || null;
      this.setState({ ...dataApply });
      return;
    }
    history.push('/ar-operations/write-offs');
  }

  onHandleChange = ({ name, value }) => {
    const { dataApply } = this.state;
    dataApply[name] = value;
    this.setState({ ...dataApply });
  };

  onBlurAmount = () => {
    const { dataApply } = this.state;
    dataApply.amount = roundFloat(dataApply.amount, 2);
    this.setState({ dataApply: { ...dataApply } });
  };

  onBlurPercent = () => {
    const { dataApply } = this.state;
    dataApply.percent = roundFloat(dataApply.percent, 2);
    this.setState({ dataApply: { ...dataApply } });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const {
      dataApply: { amount, id, percent, notes, userId },
    } = this.state;
    const { processWriteoffReversal } = this.props;
    if (!amount && !percent) {
      return toast('Field amount or percent cannot be null.', { type: toast.TYPE.ERROR });
    }
    const dataProcess = {
      id,
      amount: amount || null,
      percent: percent || null,
      notes: notes || null,
      userId: userId || null,
    };
    return processWriteoffReversal(dataProcess);
  };

  onCancel = () => {
    this.setState({ dataApply: {} });
  };

  render() {
    const { dataApply } = this.state;
    const {
      t,
      location: { state },
    } = this.props;
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={
            state && state.backLink
              ? { pathname: state.backLink, state }
              : { pathname: RouteNames.arOperationsWriteOffs.path, state }
          }
          titleBtn={t('label.back')}
          titleRight={t('arOperationPage:sidebar.writeOffs')}
        />
        <div className="col-md-12 mb-30">
          <RevertForm
            title={t('label.writeOffReversal')}
            data={dataApply}
            onChange={this.onHandleChange}
            onBlurAmount={this.onBlurAmount}
            onHandleSubmit={this.onHandleSubmit}
            onBlurPercent={this.onBlurPercent}
            linkTo={RouteNames.arOperationsWriteOffs.path}
            onCancel={this.onCancel}
          />
        </div>
      </div>
    );
  }
}

ReverseWriteOff.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  processWriteoffReversal: PropTypes.func.isRequired,
};

ReverseWriteOff.defaultProps = {
  location: {},
  history: {},
};

export default withTranslation('common')(
  connect(null, {
    processWriteoffReversal,
  })(withRouter(ReverseWriteOff))
);
