import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class PaymentSuspenseSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDateWithRank',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDateWithRank',
      name: 'endDate',
      type: 'date',
    },
    {
      name: 'sourceCurrency',
      label: 'common:label.paymentCurrency',
      type: 'select',
      options: [],
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:paymentSuspenseStatus',
      isClearable: true,
    },
    {
      label: 'label.payemntThresholdMoreThan',
      name: 'minThresholdAmount',
      type: 'number',
    },
    {
      label: 'label.payemntThresholdLessThan',
      name: 'maxThresholdAmount',
      type: 'number',
    },
    {
      label: 'label.unallocatedAmountThresholdMoreThan',
      name: 'minUnallocatedPaymentThreshold',
      type: 'number',
    },
    {
      label: 'label.unallocatedAmountThresholdLessThan',
      name: 'maxUnallocatedPaymentThreshold',
      type: 'number',
    },
    {
      label: 'label.paymentSource',
      name: 'paymentSource',
      // options: [],
      // isClearable: true,
      // type: 'select',
    },
    {
      label: 'label.fileName',
      name: 'fileName',
    },
    {
      label: 'label.suspenseReason',
      name: 'suspenseReason',
      type: 'select',
      tOptions: 'selections:suspenseReason',
      isClearable: true,
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      name: 'accountId',
      label: 'common:label.accountId',
    },
    {
      label: 'label.paymentSuspenseId',
      name: 'id',
    },
    {
      label: 'label.paymentReferenceId',
      name: 'paymentReferenceId',
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      type: 'select',
      tOptions: 'selections:paymentMethod',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.useExchangeRate',
      name: 'useExchangeRate',
      type: 'number',
    },
    // {
    //   label: 'label.paymentTransactionType',
    //   name: 'paymentTransactionType',
    //   tOptions: 'selections:paymentTransactionType',
    //   isClearable: true,
    //   type: 'select',
    // },
  ];

  btnSpan = 'col-md-6';
}

PaymentSuspenseSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

PaymentSuspenseSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(PaymentSuspenseSearchForm);
