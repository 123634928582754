import { createSelector } from 'reselect';

const selectCollections = state => state.get('dashboardReducer');

const makeGetDashboardTileConfig = () =>
  createSelector(
    selectCollections,
    item => (item.get('dashboardTileConfig') ? item.get('dashboardTileConfig') : [])
  );

const makeGetUserDashboards = () =>
  createSelector(
    selectCollections,
    item => (item.get('userDashboards') && item.get('userDashboards').length ? item.get('userDashboards') : [])
  );

export { makeGetDashboardTileConfig, makeGetUserDashboards };
