import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getFonatelReport = `getFonatelReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getFonatelReport} {
        accountId
        priceOfferName
        itemName
        invoiceId
        invoiceStartDate
        invoiceEndDate
        invoiceIssued
        invoicePending
        priceUnitStart
        priceUnitEnd
      }
    }
  `;
};
