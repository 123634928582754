import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import {
  getConfigPendingBillingTrigger,
  modifyConfigPendingBillingTrigger,
  createConfigPendingBillingTrigger,
} from '../actions';
import { makePendingBillingTriggerConfig } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import { makeGetPermissionsRating, makeGetPermissionsBilling } from '../../App/selectors';
import {
  checkPermissionGetPendingBillTriggerConfig,
  checkPermissionModifyPendingBillTriggerConfig,
  checkPermissionCreatePendingBillTriggerConfig,
} from '../CheckPermission';

class PendingBillTrigger extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    configPendingBillingTriggerList: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.pendingBillTrigger) return null;
    return {
      configPendingBillingTriggerList:
        props.pendingBillTrigger && props.pendingBillTrigger.configPendingBillingTriggerList
          ? props.pendingBillTrigger.configPendingBillingTriggerList
          : [],
      oldValue: props.pendingBillTrigger,
      dataSubmit: [],
    };
  }

  componentDidMount() {
    this.doGetPendingBillTriggerConfig();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { configPendingBillingTriggerList, dataSubmit } = this.state;
    const newData = cloneDeep(configPendingBillingTriggerList);
    let newValue = value;
    if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = newValue;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = newValue;
    }
    newData[indexItemChange][name] = newValue;
    this.setState({ configPendingBillingTriggerList: newData });
  };

  doGetPendingBillTriggerConfig = () => {
    const { getConfigPendingBillingTrigger } = this.props;
    getConfigPendingBillingTrigger('', () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewItem = () => {
    let indexNewItem = 0;
    const { configPendingBillingTriggerList, dataSubmit } = this.state;
    let newData = cloneDeep(configPendingBillingTriggerList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      dom: '',
      monthOffset: null,
      usageFlag: 'false',
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ configPendingBillingTriggerList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { configPendingBillingTriggerList, dataSubmit } = this.state;
    const newData = cloneDeep(configPendingBillingTriggerList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ configPendingBillingTriggerList: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit } = this.state;
    const { modifyConfigPendingBillingTrigger, createConfigPendingBillingTrigger, pendingBillTrigger } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (pendingBillTrigger && pendingBillTrigger.id) {
      const newPayload = {
        id: pendingBillTrigger.id,
        configPendingBillingTriggerList: dataSubmit,
      };
      return modifyConfigPendingBillingTrigger(newPayload, ({ success }) => {
        if (success) this.doGetPendingBillTriggerConfig();
      });
    }

    const newPayloadCreate = cloneDeep(dataSubmit).map(item => {
      const newItem = item;
      delete newItem.index;
      return newItem;
    });
    createConfigPendingBillingTrigger({ configPendingBillingTriggerList: newPayloadCreate }, ({ success }) => {
      if (success) this.doGetPendingBillTriggerConfig();
    });
  };

  render() {
    const { configPendingBillingTriggerList, isSearching, wasValidated } = this.state;
    const { pendingBillTrigger, permissionsBilling, t } = this.props;
    let modeGetPendingBillTriggerConfig = 0;
    let modeModifyPendingBillTriggerConfig = 0;
    let modeCreatePendingBillTriggerConfig = 0;

    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermissionsBilling = permissionsBilling.billingModulePermissions;
      modeGetPendingBillTriggerConfig = checkPermissionGetPendingBillTriggerConfig({
        listPermission: listPermissionsBilling,
      });
      modeCreatePendingBillTriggerConfig = checkPermissionCreatePendingBillTriggerConfig({
        listPermission: listPermissionsBilling,
      });
      modeModifyPendingBillTriggerConfig = checkPermissionModifyPendingBillTriggerConfig({
        listPermission: listPermissionsBilling,
      });
    }

    if (!modeGetPendingBillTriggerConfig) return '';

    const pendingBillTriggerColumns = [
      {
        name: 'dom',
        label: t('label.dom'),
        style: { textAlign: 'center', minWidth: '110px' },
        // required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.dom}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="dom"
            // required
          />
        ),
      },
      {
        name: 'monthOffset',
        label: t('label.monthOffset'),
        style: { textAlign: 'center', minWidth: '110px' },
        // required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.monthOffset}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="monthOffset"
            // required
          />
        ),
      },
      {
        name: 'usageFlag',
        label: t('label.usageFlag'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.usageFlag === 'true' || item.usageFlag === true ? 'TRUE' : 'FALSE'}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="usageFlag"
            type="select"
            tOptions="selections:selectBool"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    // const newDataOnlyViewInTable =
    //   configPendingBillingTriggerList && configPendingBillingTriggerList.length
    //     ? orderBy(
    //         configPendingBillingTriggerList.filter(item => size(item) > 1),
    //         ['index'],
    //         ['asc']
    //       )
    //     : [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.invoicingBilling.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
            { name: t('label.pendingBillTrigger') },
          ]}
        />
        <FormWithTableItem title={t('label.pendingBillTrigger')} subClass="border-bottom">
          <br />
          <form
            onSubmit={modeModifyPendingBillTriggerConfig === 2 ? this.hanldeSubmit : () => {}}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreatePendingBillTriggerConfig === 2 && (
                <button
                  disabled={modeCreatePendingBillTriggerConfig === 1}
                  type="button"
                  className="button button-border black x-small"
                  onClick={this.onAddNewItem}
                >
                  +
                  {t('label.addPendingBillTrigger')}
                </button>
              )}
              {modeModifyPendingBillTriggerConfig === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {pendingBillTrigger && pendingBillTrigger.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => this.doGetPendingBillTriggerConfig()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <br />
            <div>
              <DataTable
                columns={pendingBillTriggerColumns}
                data={
                  configPendingBillingTriggerList && configPendingBillingTriggerList.length
                    ? configPendingBillingTriggerList
                    : []
                }
                isLoading={isSearching}
                isFixedHeaderTable
              />
            </div>
          </form>
        </FormWithTableItem>
      </div>
    );
  }
}

PendingBillTrigger.propTypes = {
  pendingBillTrigger: PropTypes.objectOf(PropTypes.any),
  getConfigPendingBillingTrigger: PropTypes.func.isRequired,
  createConfigPendingBillingTrigger: PropTypes.func.isRequired,
  modifyConfigPendingBillingTrigger: PropTypes.func.isRequired,
  permissionsRating: PropTypes.objectOf(PropTypes.any),
};

PendingBillTrigger.defaultProps = {
  pendingBillTrigger: {},
  permissionsRating: {},
};
const mapStateToProps = createStructuredSelector({
  // pendingBillTrigger: makePendingBillingTriggerConfig() || {},
  pendingBillTrigger: makePendingBillingTriggerConfig() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
});

const newPendingBillTrigger = connect(mapStateToProps, {
  getConfigPendingBillingTrigger,
  modifyConfigPendingBillingTrigger,
  createConfigPendingBillingTrigger,
})(PendingBillTrigger);

export default withTranslation('common')(newPendingBillTrigger);
