/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import {
  selectMiletstoneId,
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
} from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { MilestoneSearchForm } from '../../RevenueHub';

class ModalMiletstone extends PureComponent {
  state = {
    productFamilyConfig: [],
    productLineConfig: [],
    productSubTypeConfig: [],
    productTypeConfig: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    if (isOpen && prevProps.isOpen !== isOpen) this.doGetListProductConfig();
  }

  doGetListProductConfig = () => {
    const { getUniqueProductFamily, getUniqueProductLine, getUniqueProductSubType, getUniqueProductType } = this.props;
    getUniqueProductFamily('', ({ data, success }) => {
      if (success)
        this.setState({
          productFamilyConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productFamily, value: val.productFamily })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductLine('', ({ data, success }) => {
      if (success)
        this.setState({
          productLineConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productLine, value: val.productLine })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductSubType('', ({ data, success }) => {
      if (success)
        this.setState({
          productSubTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productSubType, value: val.productSubType })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductType('', ({ data, success }) => {
      if (success)
        this.setState({
          productTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productType, value: val.productType })),
                  ['label']
                )
              : [],
        });
    });
  };

  searchHanldeInvoiceId = (payload, cb) => {
    const { selectMiletstoneId } = this.props;
    const newPayload = { ...payload };
    selectMiletstoneId(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
          // render: (colName, item) => (
          //   <Link
          //     className="text-success"
          //     to={RouteNames.prettifyPath(RouteNames.revenueMilestoneModify.path, item.id)}
          //   >
          //     {item.id}
          //   </Link>
          // ),
        },
        {
          name: 'name',
          label: 'label.name',
          sortable: true,
        },
        {
          name: 'description',
          label: 'label.description',
        },
        {
          name: 'productFamily',
          label: 'label.productFamily',
          sortable: true,
        },
        {
          name: 'productLine',
          label: 'label.productLine',
          sortable: true,
        },
        {
          name: 'productType',
          label: 'label.productType',
          sortable: true,
        },
        {
          name: 'productSubType',
          label: 'label.productSubType',
          sortable: true,
        },
        {
          name: 'currency',
          label: 'label.currency',
          sortable: true,
        },
        {
          name: 'country',
          label: 'label.country',
          sortable: true,
        },
        {
          name: 'status',
          label: 'label.status',
          sortable: true,
        },
        {
          name: 'serviceType',
          label: 'label.serviceType',
          sortable: true,
        },
        {
          name: 'unit',
          label: 'label.unit',
        },
        {
          name: 'offsetValue',
          label: 'label.offsetValue',
        },
        {
          name: 'frequency',
          label: 'label.frequency',
        },
        // {
        //   name: 'view',
        //   label: 'label.view',
        //   render: (colName, item) => (
        //     <Link
        //       className="btn btn-outline-success btn-sm"
        //       to={RouteNames.prettifyPath(RouteNames.revenueMilestoneModify.path, item.id)}
        //     >
        //       {t('common:label.view')}
        //     </Link>
        //   ),
        // },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    const { productFamilyConfig, productLineConfig, productSubTypeConfig, productTypeConfig } = this.state;
    return (
      <ModalSelector
        modalTitle={t('label.chooseMiletstone')}
        isOpen={isOpen}
        SearchForm={({ onSubmit }) => (
          <MilestoneSearchForm
            productFamilyConfig={productFamilyConfig}
            productLineConfig={productLineConfig}
            productSubTypeConfig={productSubTypeConfig}
            productTypeConfig={productTypeConfig}
            onSubmit={onSubmit}
          />
        )}
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
      />
    );
  }
}

ModalMiletstone.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectMiletstoneId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalMiletstone.defaultProps = {
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(
  connect(null, {
    selectMiletstoneId,
    getUniqueProductFamily,
    getUniqueProductLine,
    getUniqueProductSubType,
    getUniqueProductType,
  })(ModalMiletstone)
);
