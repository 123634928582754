/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { selectTimeUnit } from '../../../containers/App/actions';
import DataTable from '../DataTable/Table';
import CheckField from '../CheckField';

const columns = (t, isBundle, selectedParentId) => [
  {
    name: 'action',
    label: t('label.selected'),
    render: (colName, item) => {
      return (
        <CheckField
          name="selected"
          id="selected"
          checked={isBundle ? item.bundleId === selectedParentId.id : item.packageId === selectedParentId.id}
          readOnly
        />
      );
    },
  },
  {
    name: isBundle ? 'bundleId' : 'packageId',
    label: 'common:label.id',
  },
  {
    name: 'name',
    label: 'common:label.name',
  },
  {
    name: 'description',
    label: 'common:label.description',
  },
];

class ModalSelectPackageBundle extends PureComponent {
  render() {
    const { isOpen, data, onSelect, onClickItem, selectedParentId, t, toggleModal, isBundle } = this.props;
    const tableConfig = {
      columns: columns(t, isBundle, selectedParentId),
      data,
    };

    return (
      <Modal
        size="lg"
        className="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <ModalHeader>{isBundle ? 'Select Bundle' : 'Select Package'}</ModalHeader>
        <ModalBody>
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <div className="card-body">
                <DataTable
                  {...tableConfig}
                  onClickRow={onClickItem}
                  nullIsLoading={false}
                  onSort={this.onSortColumn}
                  fixedHeader
                  tableClass="card-table-body"
                />
              </div>
            </div>
          </div>

          <ModalFooter className="text-right">
            <button type="button" className="btn btn-secondary" onClick={toggleModal}>
              {t('label.cancel')}
            </button>
            <button type="button" className="btn btn-success" onClick={onSelect}>
              {t('label.select')}
            </button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

ModalSelectPackageBundle.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectTimeUnit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSelectPackageBundle.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectTimeUnit })(ModalSelectPackageBundle));
