import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/private-route';
import RouteNames from './RouteNames';
import Layout from '../Layouts';
import LoginPage from '../Login';
import Dashboard from '../Dashboard';
import CustomerPage from '../CustomerPage';
import Invoice from '../Invoice';
import PricingManagement from '../PricingManagement';
import BundleManagement from '../BundleManagement';
import PaymentsPage from '../PaymentsPage';
import OrderPage from '../OrderPage';
import UserManagement from '../UserManagement';
import JobManagement from '../JobManagement';
import TenantManagement from '../TenantManagement';
import ManageProfile from '../ManageProfile';
import ArOperations from '../ArOperations';
import Collections from '../Collections';
import BaseConfigurations from '../BaseConfigurations';
import ReportManagement from '../ReportManagement';
import RevenueManagemnet from '../RevenueManagemnet';
import RevenueConfiguration from '../RevenueConfiguration';
import TaxationManagement from '../TaxationManagement';
import BillingManagement from '../BillingManagement';
import CorrespondenceManagement from '../CorrespondenceManagement';
import UsageManagement from '../UsageManagement';
import QuotePage from '../QuotePage';
import TaskManagement from '../TaskManagement';

const wrappedRoutes = () => (
  <Layout>
    <Switch>
      <Route path={RouteNames.customers.path} component={CustomerPage} />
      <Route path={RouteNames.invoices.path} component={Invoice} />
      <Route path={RouteNames.viewFileInfo.path} component={Invoice} />
      <Route path={RouteNames.creditNotes.path} component={Invoice} />
      <Route path={RouteNames.orders.path} component={OrderPage} />
      <Route path={RouteNames.pricingManagement.path} component={PricingManagement} />
      <Route path={RouteNames.bundleManagement.path} component={BundleManagement} />
      <Route path={RouteNames.pricingHub.path} component={PricingManagement} />
      <Route path={RouteNames.paymentHub.path} component={PaymentsPage} />
      <Route path={RouteNames.arOperations.path} component={ArOperations} />
      <Route path={RouteNames.collections.path} component={Collections} />
      <Route path={RouteNames.userManagement.path} component={UserManagement} />
      <Route path={RouteNames.jobsManagment.path} component={JobManagement} />
      <Route path={RouteNames.tenantManagement.path} component={TenantManagement} />
      <Route path={RouteNames.manageProfile.path} component={ManageProfile} />
      <Route path={RouteNames.baseConfigurations.path} component={BaseConfigurations} />
      <Route path={RouteNames.report.path} component={ReportManagement} />
      <Route path={RouteNames.revenueConfig.path} component={RevenueManagemnet} />
      <Route path={RouteNames.revenueConfiguration.path} component={RevenueConfiguration} />
      <Route path={RouteNames.taxation.path} component={TaxationManagement} />
      <Route path={RouteNames.billing.path} component={BillingManagement} />
      <Route path={RouteNames.correspondence.path} component={CorrespondenceManagement} />
      <Route path={RouteNames.usage.path} component={UsageManagement} />
      <Route path={RouteNames.quotePage.path} component={QuotePage} />
      <Route path={RouteNames.taskManagement.path} component={TaskManagement} />
      <Route exact path="" component={Dashboard} />
      <Route exact path={RouteNames.dashboard.path} component={Dashboard} />
    </Switch>
  </Layout>
);

const Router = () => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <PrivateRoute path="" component={wrappedRoutes} />
  </Switch>
);

export default Router;
