import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderSummaryByAccountId(input: { accountId: "${id}" }) {
      accountId
      accountServicesSummaries {
        omsInternalId
        serviceId
        serviceName
        parentServiceId
        parentServiceName
        administrativeState
        operationalState
        status
        dischargeDate
        canceledDate
        releaseDate
        quantity
        servicePrice
        uom
        serviceType
        itemId
        totalPrice
        loadType
        productDescription
        serviceDiscount
        medium
        stateService
        serviceMovement
        circuitId
        clientCircuitId
        externalServiceId
        alternateDescription
        commodityPrice
        cost
        externalServiceIdOp
        includedLicenses
        initialTermUnit
        initialTerm
        priceWithTax
        reason
        taxCode
        advanceFlag
        partMonthWaiveOffFlag
        calendarMonthFlag
        alignedToCycle
        recurringFrequency
        recurringType
        recurringUnit
        currency
        isGrant
        invoiceExhibits
        exhibitsInvoiced
        cutOffDate
        percentile
        capacity
        validity
        trialTermUnit
        trialTerm
        skipStartMonth
        skipNoOfMonths
        newFreqEffectiveDate
        freqChangeAutoApplyDate
        internationalDIDCountry
        commitmentTerm
        commitmentDollarValue
        locations {
          pointId
          pointName
          percent
        }
      }
      accountNonBillablesSummaries {
        omsInternalId
        inventoryItemId
        comments
        description
        assetTag
        cost
        deliveryDate
        primaryCode
        quantity
        status
        statusId
        exchangeRate
        transactionType
        externalId
      }
      accountLocationsSummaries {
        omsInternalId
        userId
        pointId
        cpId
        floor
        colony
        street
        city
        state
        zipCode
        plusCode
        country
        creationDate
        endDate
        fullName
        company
        latitude
        longitude
        commercialClasification
        ring
        pointName
        ownerName
        ownerId
        validationStatus
        status
        statusId
        deliveryType
        cp
        floorSite
        ringName
      }
      accountInfraNonBillablesSummaries {
        omsInternalId
        inventoryItemId
        comments
        description
        assetTag
        cost
        deliveryDate
        primaryCode
        quantity
        status
        statusId
        exchangeRate
        transactionType
        externalId
      }
    }
  }
`;
