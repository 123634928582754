import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderProvisioningOutboundTasks(id: "${id}") {
      id
      apiName
      status
      index
      serviceType
      provisioningSequence
    }
  }
`;
