import gql from 'graphql-tag';

export default () => gql`
  query {
    getGrantsConfig(input: { id: "" }) {
      id
      grantListConfig {
        id
        index
        resourceId
        symbol
        name
        roundingMethod
        roundingPrecision
        grantType
      }
    }
  }
`;
