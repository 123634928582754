import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'exchangeRate', 'threshold', 'splitType']);
  let newDataCreate = `${dataCreate.replace('{dataSubmit: ', '')}`;
  newDataCreate=`${newDataCreate.substring(0, newDataCreate.length - 1)}`
  const createAccountExchangeRates = `createAccountExchangeRates(input: ${newDataCreate})`;

  return gql`
  mutation {
    ${createAccountExchangeRates} {
      index
    }
  }
`;
};
