import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import arrayMove from 'array-move';
import { toast } from 'react-toastify';
import { GenericInput, FormCollapse, TitleFrom, CollapsibleTableDragDrop, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getGLSetup, modifyGLSetup, createGLSetup } from '../actions';

class GLSetup extends React.PureComponent {
  state = {
    data: { segments: [], accountRanges: [] },
    wasValidated: false,
    formValid: true,
    activeTab: {},
    showActiveOnly: true,
    isOpenModalName: '',
    locationSelect: {},
    enterpriseSelect: {},
    divisionSelect: {},
    legalEntitySelect: {},
    activeSegmentIndexTab: -1,
    activeAccountRangesIndexTab: -1,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetGLSetup();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetGLSetup = () => {
    const { getGLSetup } = this.props;
    getGLSetup('', ({ data }) => {
      this.setState({
        data: data ? data[0] : { segments: [], accountRanges: [] },
        isModify: !!data,
      });
    });
  };

  onChange = ({ index, name, value }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewItem = () => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.push({
        index: lastIndex + 1,
        name: '',
        description: '',
        shortName: '',
        code: '',
        enterprise: null,
        division: null,
        legalEntity: '',
        locationIndex: null,
        status: '',
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    this.setState({ wasValidated: false });
    const { data, isModify } = this.state;
    const { modifyGLSetup, createGLSetup } = this.props;
    const payload = cloneDeep(data);
    if (isModify) {
      const newPayload = payload;
      newPayload.segments = payload.segments.map(val => {
        const { segmentValues, ...rest } = val;
        let newSegmentValues = null;
        if (segmentValues && segmentValues.length) {
          newSegmentValues = segmentValues.map(sg => {
            const { name, subName, ...rest } = sg;
            let payloadItem = { ...rest };
            if (subName || name) payloadItem = { ...payloadItem, subName: subName || name };
            return { ...payloadItem };
          });
        }
        return { ...rest, segmentValues: newSegmentValues };
      });
      return modifyGLSetup(newPayload, ({ success }) => {
        if (success) this.doGetGLSetup();
      });
    }
    return createGLSetup(payload, ({ success }) => {
      if (success) this.doGetGLSetup();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onOpenPopup = (name, index) => {
    this.setState({ isOpenModalName: name, indexSelect: index });
  };

  onAddNewSegment = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.segments) newData.segments = [];
    newData.segments.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.segments[newData.segments.length] = {
      index: lastIndex + 1,
      length: null,
      leadingZeroes: false,
      name: null,
      segmentValues: [],
    };

    this.setState({ data: newData });
  };

  addNewGLAccountRange = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.accountRanges) newData.accountRanges = [];
    newData.accountRanges.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.accountRanges[newData.accountRanges.length] = {
      index: lastIndex + 1,
      startingNumber: null,
      accountType: null,
      subAccountRanges: [],
    };

    this.setState({ data: newData });
  };

  addNewSegmentValues = index => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.segments[index].segmentValues) newData.segments[index].segmentValues = [];
    newData.segments[index].segmentValues.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.segments[index].segmentValues.push({
      index: lastIndex + 1,
      // refIndex: lastIndex + 1,
      subName: '',
      code: '',
    });

    this.setState({ data: newData });
  };

  onToggleSubTabGLSegments = (index, item, indexItem) => {
    const { activeSegmentIndexTab } = this.state;
    if (activeSegmentIndexTab === indexItem) this.setState({ activeSegmentIndexTab: -1 });
    if (activeSegmentIndexTab !== indexItem) {
      this.setState({ activeSegmentIndexTab: indexItem });
    }
  };

  onToggleSubTabAccountRanges = (index, item, indexItem) => {
    const { activeAccountRangesIndexTab } = this.state;
    if (activeAccountRangesIndexTab === indexItem) this.setState({ activeAccountRangesIndexTab: -1 });
    if (activeAccountRangesIndexTab !== indexItem) {
      this.setState({ activeAccountRangesIndexTab: indexItem });
    }
  };

  onHandleChangeSegment = ({ name, value, index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let newValue = value;
    if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE';
    newData.segments[index][name] = newValue;
    this.setState({ data: newData });
  };

  onRemoveSegment = index => {
    const { data, isModify } = this.state;
    const newData = cloneDeep(data);
    if (!isModify) {
      newData.segments.splice(index, 1);
    } else {
      newData.segments[index] = { index: newData.segments[index].index };
    }
    this.setState({ data: newData });
  };

  onHandleChangeGLSegmentValue = ({ value, name, index, indexParent }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.segments[indexParent].segmentValues[index][name] = value;
    this.setState({ data: newData });
  };

  onRemoveSegmentValues = (indexParent, index) => {
    const { data, isModify } = this.state;
    const newData = cloneDeep(data);
    if (!isModify) {
      newData.segments[indexParent].segmentValues.splice(index, 1);
    } else {
      newData.segments[indexParent].segmentValues[index] = {
        index: newData.segments[indexParent].segmentValues[index].index,
      };
    }
    this.setState({ data: newData });
  };

  onHandleChangeAccountRanges = ({ name, value, index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.accountRanges[index][name] = value;
    this.setState({ data: newData });
  };

  addGLSubAccountRangesValues = index => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData.accountRanges[index].subAccountRanges) newData.accountRanges[index].subAccountRanges = [];
    newData.accountRanges[index].subAccountRanges.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData.accountRanges[index].subAccountRanges.push({
      index: lastIndex + 1,
      // refIndex: lastIndex + 1,
      accountSubType: '',
      startingNumber: null,
    });

    this.setState({ data: newData });
  };

  onHandleChangeSubAccountRanges = ({ value, name, index, indexParent }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData.accountRanges[indexParent].subAccountRanges[index][name] = value;
    this.setState({ data: newData });
  };

  onRemoveAccountRanges = index => {
    const { data, isModify } = this.state;
    const newData = cloneDeep(data);
    if (!isModify) {
      newData.accountRanges.splice(index, 1);
    } else {
      newData.accountRanges[index] = { index: newData.accountRanges[index].index };
    }
    this.setState({ data: newData });
  };

  onRemoveSubAccountRanges = ({ index, indexParent }) => {
    const { data, isModify } = this.state;
    const newData = cloneDeep(data);
    if (!isModify) {
      newData.accountRanges[indexParent].subAccountRanges.splice(index, 1);
    } else {
      newData.accountRanges[indexParent].subAccountRanges[index] = {
        index: newData.accountRanges[indexParent].subAccountRanges[index].index,
      };
    }
    this.setState({ data: newData });
  };

  onChangeData = ({ name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[name] = value;
    this.setState({ data: newData });
  };

  onSortEnd = ({ oldIndex, newIndex, key }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    const tempOldIdx = newData[key][oldIndex].index;
    newData[key][oldIndex].index = newData[key][newIndex].index;
    newData[key][newIndex].index = tempOldIdx;
    newData[key] = arrayMove(newData[key], oldIndex, newIndex);
    this.setState({ data: newData });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateGLAccountConfig, modeGetGLAccountConfig, modeModifyGLAccountConfig } = permissions;
    if (!modeGetGLAccountConfig) return '';
    const tableGLSegmentValues = [
      {
        name: 'index',
        label: 'label.index',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeGLSegmentValue({ value, name, index, indexParent })}
            name="index"
          />
        ),
      },
      {
        name: 'subName',
        label: 'label.name',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.subName || item.name}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeGLSegmentValue({ value, name, index, indexParent })}
            name="subName"
          />
        ),
      },
      {
        name: 'code',
        label: 'label.code',
        required: true,
        render: (colName, item, index, indexParent) => (
          <GenericInput
            value={item.code}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeGLSegmentValue({ value, name, index, indexParent })}
            name="code"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent) => (
          <button type="button" className="btn-delete" onClick={evt => this.onRemoveSegmentValues(indexParent, idx)}>
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];
    const { data, activeTab, wasValidated, isModify, activeSegmentIndexTab, activeAccountRangesIndexTab } = this.state;
    const headerTableGLSegments = [
      {
        name: 'index ',
        label: 'label.index',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            required
            onChange={({ name, value }) => this.onHandleChangeSegment({ value, name, index: idx })}
            name="index"
          />
        ),
      },
      {
        name: 'length',
        label: 'label.length',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.length}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeSegment({ value, name, index: idx })}
            name="length"
            required
          />
        ),
      },
      {
        name: 'leadingZeroes',
        label: 'label.leadingZeroes',
        style: { minWidth: '150px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.leadingZeroes === true ? 'TRUE' : 'FALSE'}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeSegment({ value, name, index: idx })}
            name="leadingZeroes"
            type="select"
            menuPortalTarget
            tOptions="selections:selectBool"
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        style: { minWidth: '150px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeSegment({ value, name, index: idx })}
            name="name"
            type="select"
            tOptions="selections:GlSegmentName"
            menuPortalTarget
            required
          />
        ),
      },
      {
        name: 'segmentValues',
        label: 'label.segmentValues',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => this.onToggleSubTabGLSegments(idx, item, idx)}
          >
            <i className={`fa ${activeSegmentIndexTab === idx ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button type="button" className="btn-delete" onClick={evt => this.onRemoveSegment(idx)}>
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];
    const headerTableGLAccountRanges = [
      {
        name: 'index ',
        label: 'label.index',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            required
            onChange={({ name, value }) => this.onHandleChangeAccountRanges({ value, name, index: idx })}
            name="index"
          />
        ),
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.accountType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeAccountRanges({ value, name, index: idx })}
            name="accountType"
            type="select"
            tOptions="selections:GlAccountType"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'startingNumber',
        label: 'label.startingNumber',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.startingNumber}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChangeAccountRanges({ value, name, index: idx })}
            name="startingNumber"
          />
        ),
      },
      {
        name: 'subAccountRanges',
        label: 'label.subAccountRanges',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => this.onToggleSubTabAccountRanges(idx, item, idx)}
          >
            <i className={`fa ${activeAccountRangesIndexTab === idx ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => (
          <button type="button" className="btn-delete" onClick={evt => this.onRemoveAccountRanges(idx)}>
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];

    const headerTableSubAccountRanges = [
      {
        name: 'index ',
        label: 'label.index',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            required
            onChange={({ name, value }) =>
              this.onHandleChangeSubAccountRanges({ value, name, index: idx, indexParent })
            }
            name="index"
          />
        ),
      },
      {
        name: 'startingNumber',
        label: 'label.startingNumber',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.startingNumber}
            wrapperClass="col-md-12"
            onChange={({ name, value }) =>
              this.onHandleChangeSubAccountRanges({ value, name, index: idx, indexParent })
            }
            name="startingNumber"
          />
        ),
      },
      {
        name: 'accountSubType',
        label: 'label.accountSubType',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.accountSubType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) =>
              this.onHandleChangeSubAccountRanges({ value, name, index: idx, indexParent })
            }
            name="accountSubType"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx, indexParent) => (
          <button
            type="button"
            className="btn-delete"
            onClick={evt => this.onRemoveSubAccountRanges({ indexParent, index: idx })}
          >
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.GLSetup')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.GLSetup')} />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <FormCollapse
                isActive={activeTab.name === 'GL-segments' && activeTab.isActive}
                title="label.GLSegments"
                state="GL-segments"
                onToggleTab={this.onToggleTab}
              >
                <div className="col-md-12 row p-3">
                  <div className="col-md-12 p-3 pb-4">
                    <GenericInput
                      value={data.delimiter}
                      label="Delimiter"
                      wrapperClass="col-md-4"
                      onChange={({ name, value }) => this.onChangeData({ value, name })}
                      name="delimiter"
                    />
                  </div>
                  <CollapsibleTableDragDrop
                    columns={headerTableGLSegments}
                    indexViewer={activeSegmentIndexTab}
                    data={data.segments || []}
                    isSupportRemoveIndex
                    isFixedHeaderTable
                    onSortEnd={e => this.onSortEnd({ ...e, key: 'segments' })}
                  >
                    <div className="col-md-12 mt-3">
                      <h6 className="pb-3 pt-3">{t('label.GLSegmentValues')}</h6>
                      <DataTable
                        columns={tableGLSegmentValues}
                        isSupportRemoveIndex
                        data={
                          data.segments &&
                          data.segments[activeSegmentIndexTab] &&
                          data.segments[activeSegmentIndexTab].segmentValues
                            ? data.segments[activeSegmentIndexTab].segmentValues
                            : []
                        }
                        indexParent={activeSegmentIndexTab}
                      />
                      <button
                        type="button"
                        className="button mb-4 button-border float-right black x-small"
                        onClick={evt => this.addNewSegmentValues(activeSegmentIndexTab)}
                      >
                        +
                        {t('label.addNewSegmentValues')}
                      </button>
                    </div>
                  </CollapsibleTableDragDrop>
                  {modeCreateGLAccountConfig === 2 && (
                    <button
                      type="button"
                      className="button mt-4 button-border float-right black x-small"
                      onClick={this.onAddNewSegment}
                    >
                      +
                      {t('label.addNewSegment')}
                    </button>
                  )}
                </div>
              </FormCollapse>
              <FormCollapse
                isActive={activeTab.name === 'GL-AccountRanges' && activeTab.isActive}
                title="label.GLAccountRanges"
                state="GL-AccountRanges"
                onToggleTab={this.onToggleTab}
              >
                <div className="col-md-12 row p-3">
                  <CollapsibleTableDragDrop
                    columns={headerTableGLAccountRanges}
                    indexViewer={activeAccountRangesIndexTab}
                    data={data.accountRanges || []}
                    isSupportRemoveIndex
                    isFixedHeaderTable
                    onSortEnd={e => this.onSortEnd({ ...e, key: 'accountRanges' })}
                  >
                    <div className="col-md-12 mt-3">
                      <h6 className="pb-3 pt-3">{t('label.GLSubAccountRanges')}</h6>
                      <DataTable
                        columns={headerTableSubAccountRanges}
                        data={
                          data.accountRanges &&
                          data.accountRanges[activeAccountRangesIndexTab] &&
                          data.accountRanges[activeAccountRangesIndexTab].subAccountRanges
                            ? data.accountRanges[activeAccountRangesIndexTab].subAccountRanges
                            : []
                        }
                        isSupportRemoveIndex
                        indexParent={activeAccountRangesIndexTab}
                      />
                      {modeCreateGLAccountConfig === 2 && (
                        <button
                          type="button"
                          className="button mb-4 button-border float-right black x-small"
                          onClick={evt => this.addGLSubAccountRangesValues(activeAccountRangesIndexTab)}
                        >
                          +
                          {t('label.addNewGLSubAccountRange')}
                        </button>
                      )}
                    </div>
                  </CollapsibleTableDragDrop>
                  {modeCreateGLAccountConfig === 2 && (
                    <button
                      type="button"
                      className="button mt-4 button-border float-right black x-small"
                      onClick={this.addNewGLAccountRange}
                    >
                      +
                      {t('label.addNewGLAccountRange')}
                    </button>
                  )}
                </div>
              </FormCollapse>

              <div className="form-group col-md-12 buttons-attibute pt-4">
                {/* {modeCreateGLAccountConfig !== 0 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    disabled={modeCreateGLAccountConfig === 1}
                    onClick={this.addNewItem}
                  >
                    +
                    {t('label.addNewGLSetup')}
                  </button>
                )} */}
                {modeModifyGLAccountConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border mb-3 x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeModifyGLAccountConfig === 1}
                  >
                    {isModify ? t('label.saveConfig') : t('label.createConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetGLSetup}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

GLSetup.propTypes = {
  getGLSetup: PropTypes.func,
  modifyGLSetup: PropTypes.func,
};

GLSetup.defaultProps = {
  getGLSetup: () => {},
  modifyGLSetup: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getGLSetup,
    modifyGLSetup,
    createGLSetup,
  })(withRouter(GLSetup))
);
