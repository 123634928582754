import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'index',
    'roundingPrecision',
    'roundingMethod',
    'paymentThreshold',
    'paymentExchangeThreshold',
  ]);
  const createCurrencyConfig = `createCurrencyConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createCurrencyConfig} {
      id
    }
  }
`;
};
