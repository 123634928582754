import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['dashboardType', 'index']);
  const modifyUserPreferences = `modifyUserPreferences(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyUserPreferences} {
      id
    }
  }
`;
};
