import gql from 'graphql-tag';

export default () => gql`
  query {
    getARReasonCodeConfig(dummy: "") {
      id
      type
      arReasonCodes {
        index
        reasonCode
        customReasonCode
        description
        invoiceCancellation
        fullCredit
        stampingRequired
        objectType
        status
        templateName
      }
    }
  }
`;
