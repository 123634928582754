import gql from 'graphql-tag';

export default ({dummy}) => {
  const getCcpDateTime = `getCcpDateTime(dummy: "${dummy || 'dummy'}")`;
  return gql`
    query {
      ${getCcpDateTime} {
        ccpTime
      }
    }
  `;
};
