import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { withTranslation } from 'react-i18next';
import {
  DataTable,
  TablePagination,
  ModalWithItem,
  GenericInput,
  SwitchExport,
  ButtonExport,
  ExcelExport,
  DragDropConfigExportTemplate,
  ModalDetailsItem,
  FormCollapse,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import {
  searchEbsExtract,
  getEbsExtractDetailsById,
  searchAllEbsExtract,
  resendEbsExtract,
  getActivityEntry,
} from '../actions';
import { getPageTotalCount, splitF, configOutputTemplateByType } from '../../../utils/utils';
import { ERPExtractsSearchForm } from '../../../components/RevenueHub';
import { sortEbsExtract, collapsibleMenuAccountActivities, collapsibleMenuBeforeAfter } from '../constants';
import { makeGetEbsExtract, makeGetAllEbsExtract } from '../selectors';
import { makeGetS3Config, makeGetCcpTime, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  readFileFromS3,
  saveOutputTemplateByType,
  getOutputTemplateByType,
  getCcpDateTimeConfig,
  getCcpPropertiesConfig,
} from '../../App/actions';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';

let tableColumnsOutput = null;
let tableColumnsDefault = null;

const dataFieldRqRp = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'userId',
    label: 'label.userId',
  },
  {
    name: 'entityType',
    label: 'label.entityType',
    isSelect: true,
    tOptions: 'selections:activityType',
  },
  {
    name: 'entityId',
    label: 'label.entityId',
  },
  {
    name: 'action',
    label: 'label.action',
    isSelect: true,
    tOptions: 'selections:activityAction',
  },
  {
    name: 'source',
    label: 'label.source',
    isSelect: true,
    tOptions: 'selections:activitySource',
  },
  {
    name: 'activityDate',
    label: 'label.activityDate',
  },
  {
    name: 'request',
    label: 'label.request',
    type: 'textarea',
    wrapperClass: 'col-md-6',
    style: { minHeight: 200 },
  },
  {
    name: 'response',
    label: 'label.response',
    type: 'textarea',
    wrapperClass: 'col-md-6',
    style: { minHeight: 200 },
  },
];

const batchColumns = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'type',
    label: 'label.type',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:ebsExtractType')().find(val => val.value === item.type) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'segment1',
    label: 'label.segment1',
  },
  {
    name: 'segment2',
    label: 'label.segment2',
  },
  {
    name: 'segment3',
    label: 'label.segment3',
  },
  {
    name: 'segment3Name',
    label: 'label.seg3Name',
  },
  {
    name: 'segment4',
    label: 'label.segment4',
  },
  {
    name: 'segment5',
    label: 'label.segment5',
  },
  {
    name: 'segment6',
    label: 'label.segment6',
  },
  {
    name: 'credit',
    label: 'label.credit',
  },
  {
    name: 'debit',
    label: 'label.debit',
  },
  {
    name: 'period',
    label: 'label.period',
  },
  {
    name: 'glDate',
    label: 'label.glDate',
  },
  {
    name: 'glAccount',
    label: 'label.glAccount',
  },
  {
    name: 'glOffset',
    label: 'label.glOffsetAccount',
  },
  {
    name: 'accountingCode',
    label: 'label.accountingCode',
  },
  {
    name: 'offsetAccountingCode',
    label: 'label.offsetAccountingCode',
  },
];

const batchFileDetails = [
  {
    name: 'batchId',
    label: 'label.batchId',
  },
  {
    name: 'batchName',
    label: 'label.batchName',
  },
  {
    name: 'accountingDate',
    label: 'label.accountingDate',
  },
  {
    name: 'createdDate',
    label: 'label.extractDate',
  },
];

let fileSelect = null;
class ERPExtracts extends React.PureComponent {
  state = {
    data: [],
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    isViewCSVFile: false,
    pathFileSelect: null,
    isOpenBatch: false,
    batchFileData: [],
    itemBatchFile: null,
    isActivePdf: false,
    actionType: null,
    erpExtractGracePeriod: null,
    exportColumnsSaveDetails: null,
    isOpenModalDetailsExportConfig: false,
    activeParentTab: {},
    activeChildrentTab: {},
    itemData: {},
    isOpenModalActivity: false,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.ebsExtract,
      totalCount: getPageTotalCount({ ...state, items: props.ebsExtract }),
    };
  }

  componentDidMount() {
    const { ccpTime, ccpPropertyList, getCcpDateTimeConfig, getCcpPropertiesConfig } = this.props;
    this.doSearchEbsExtract();
    this.doGetOutputTemplateByType();
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          const erpExtractGracePeriodProperty = data.ccpPropertyList.find(
            val => val.property === 'erpExtractGracePeriod'
          );
          this.setState({
            erpExtractGracePeriod:
              erpExtractGracePeriodProperty && erpExtractGracePeriodProperty.value
                ? erpExtractGracePeriodProperty.value
                : null,
          });
        }
      });
    } else {
      const erpExtractGracePeriodProperty = ccpPropertyList.find(val => val.property === 'erpExtractGracePeriod');
      this.setState({
        erpExtractGracePeriod:
          erpExtractGracePeriodProperty && erpExtractGracePeriodProperty.value
            ? erpExtractGracePeriodProperty.value
            : null,
      });
    }
  }

  doSearchEbsExtract = () => {
    const { searchEbsExtract } = this.props;
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    if (newFilter.period) {
      newFilter.period = moment(newFilter.period, 'MM-YYYY')
        .format('MMM-YYYY')
        .toUpperCase();
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortEbsExtract[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchEbsExtract(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchEbsExtract());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchEbsExtract());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchEbsExtract());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchEbsExtract());
  };

  onToggle = () => {
    this.setState({ isViewCSVFile: false, pathFileSelect: null });
  };

  onHanldeViewCSVFile = ({ item }) => {
    const { readFileFromS3, s3Config } = this.props;
    this.setState({ isViewCSVFile: true, pathFileSelect: item.filePath });
    readFileFromS3({ ...s3Config.data, url: item.filePath }, ({ success, data }) => {
      // if (success) {
      fileSelect = data;
      saveAs(fileSelect, splitF(item.filePath));
      //   getBase64(data, result => {
      //     setBase64File(result);
      //   });
      // }
    });
  };

  onHanldeViewBatch = ({ item }) => {
    const { getEbsExtractDetailsById } = this.props;
    const { exportColumnsSaveDetails } = this.state;
    getEbsExtractDetailsById(item.id, ({ success, data }) => {
      if (!exportColumnsSaveDetails) {
        this.doGetOutputDetailsTemplateByType();
      }
      if (success) {
        this.setState({ batchFileData: data, isOpenBatch: true, itemBatchFile: item });
      }
    });
  };

  onHanldeCancelBatch = () => {
    this.setState({ batchFileData: [], isOpenBatch: false, itemBatchFile: null });
  };

  doSearchAllEbsExtract = cb => {
    const { filter, sorted } = this.state;
    const { searchAllEbsExtract } = this.props;
    const newFilter = cloneDeep(filter);
    if (newFilter.period) {
      newFilter.period = moment(newFilter.period, 'MM-YYYY')
        .format('MMM-YYYY')
        .toUpperCase();
    }
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortEbsExtract[sorted.sortCol][sorted.sortDir] : null,
    };
    searchAllEbsExtract(payload, () => {
      if (cb) cb();
    });
  };

  onExport = () => {
    this.doSearchAllEbsExtract(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllEbsExtract, t } = this.props;
        convertJson2Pdf({
          data: listAllEbsExtract,
          t,
          title: t('revenuePage:sidebar.erpExtracts').toLocaleUpperCase(),
          fileName: `${t('revenuePage:sidebar.erpExtracts')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onSelectItem = ({ item }) => {
    const { itemSelected } = this.state;
    if (itemSelected && itemSelected.batchId === item.batchId) {
      this.setState({ itemSelected: null, actionType: null });
    } else {
      this.setState({ itemSelected: item, actionType: null });
    }
  };

  onResendEbsExtract = () => {
    const { resendEbsExtract } = this.props;
    const { itemSelected, actionType } = this.state;
    resendEbsExtract(
      { id: itemSelected.id, reExtract: actionType === 'RE_EXTRACT_RE_SEND' ? 'true' : 'false' },
      ({ success }) => {
        if (success) this.setState({ itemSelected: null, actionType: null });
      }
    );
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('ERP_EXTRACTS', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  doGetOutputDetailsTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('ERP_EXTRACT_DETAILS', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSaveDetails: configOutputTemplateByType({
            columns: batchColumns,
            data,
            t,
          }),
          idDetailsOutputTemplate: data?.id,
          typeDetailsOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onHandleSaveDetailsConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idDetailsOutputTemplate, typeDetailsOutputTemplate } = this.state;
    const payload = {
      id: idDetailsOutputTemplate,
      type: typeDetailsOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputDetailsTemplateByType();
        this.setState({ exportColumnsSaveDetails: columns, isOpenModalDetailsExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  onToggleModalDetailsExportConfig = () => {
    const { isOpenModalDetailsExportConfig } = this.state;
    this.setState({ isOpenModalDetailsExportConfig: !isOpenModalDetailsExportConfig });
  };

  doGetActivityById = ({ item }) => {
    const { getActivityEntry } = this.props;
    this.setState({ isOpenModalActivity: true, isSearchDataModal: true });
    getActivityEntry(
      { entityId: item?.id || null, merchant: item?.merchant || null, gatewayType: item?.gatewayType || null },
      ({ data, success }) => this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  onToggleModalActivity = () => {
    this.setState({ isOpenModalActivity: false, itemData: {} });
  };

  render() {
    const { t, permissions, listAllEbsExtract, ccpTime } = this.props;
    const modeGetMilestoneConfig = 2;
    const modeModifyMilestoneConfig = 2;
    const modeCreateMilestoneConfig = 2;
    const { modeResendErpBatch } = permissions || {};
    // if (!modeGetMilestoneConfig) return '';
    const {
      data,
      totalCount,
      sorted,
      isSearching,
      page,
      size,
      isOpenBatch,
      batchFileData,
      itemBatchFile,
      isActivePdf,
      itemSelected,
      actionType,
      exportColumnsSave,
      isOpenModalExportConfig,
      erpExtractGracePeriod,
      exportColumnsSaveDetails,
      isOpenModalDetailsExportConfig,
      isOpenModalActivity,
      itemData,
      isSearchDataModal,
      activeParentTab,
      activeChildrentTab,
    } = this.state;
    const tableColumns = [
      {
        name: 'batchId',
        label: 'label.batchId',
        sortable: true,
        render: (colName, item) => {
          return (
            <button className="text-success no-border" type="button" onClick={() => this.onHanldeViewBatch({ item })}>
              {item.batchId}
            </button>
          );
        },
      },
      {
        name: 'batchName',
        label: 'label.batchName',
      },
      {
        name: 'accountingDate',
        label: 'label.accountingDate',
      },
      {
        name: 'createdDate',
        label: 'label.extractDate',
        sortable: true,
      },
      {
        name: 'period',
        label: 'label.period',
        sortable: true,
      },
      {
        name: 'totalCreditAmount',
        label: 'label.totalCreditAmount',
      },
      {
        name: 'totalDebitAmount',
        label: 'label.totalDebitAmount',
      },
      {
        name: 'extractStatus',
        label: 'label.status',
        fieldOption: 'ebsExtractStatus',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:ebsExtractStatus')().find(val => val.value === item.extractStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'ledgerId',
        label: 'label.ledgerId',
      },
      {
        name: 'currencyCode',
        label: 'label.currency',
      },
      {
        name: 'type',
        label: 'label.type',
        sortable: true,
        isRenderT: true,
        fieldOption: 'ebsExtractType',
        render: (colName, item, t) => {
          const slt = t ? t('selections:ebsExtractType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'count',
        label: 'label.count',
      },
      {
        name: 'category',
        label: 'label.category',
      },
      {
        name: 'source',
        label: 'label.source',
      },
      {
        name: 'filePath',
        label: 'label.extractFile',
        render: (colName, item) => {
          if (item.filePath)
            return (
              <button
                className="text-success no-border"
                type="button"
                onClick={() => this.onHanldeViewCSVFile({ item })}
              >
                {t('label.downloadFile')}
              </button>
            );
          return '';
        },
      },
      {
        name: 'actualFlag',
        label: 'label.actualFlag',
      },
      {
        name: 'externalRefId',
        label: 'label.externalRefId',
      },
      {
        name: 'inputDate',
        label: 'label.dataProcessDate',
      },
    ];

    tableColumnsDefault = tableColumns;
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    const tableColumnsDetails = exportColumnsSaveDetails?.targetFields?.items || [];

    const newTableColumns = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (colName, item) => {
          let newCcpTime = moment(ccpTime);
          if (erpExtractGracePeriod && Number(erpExtractGracePeriod)) {
            newCcpTime = moment(newCcpTime).subtract(Number.parseInt(erpExtractGracePeriod, 10), 'days');
          }
          const isLockSelect = item?.accountingDate && moment(newCcpTime).diff(item.accountingDate, 'days') > 0;

          return (
            <GenericInput
              value={!!(itemSelected && item.id === itemSelected.id)}
              name="select"
              type="checkbox"
              disabled={
                !item.accountingDate ||
                isLockSelect ||
                item?.extractStatus === 'REGENERATED' ||
                item?.extractStatus === 'RESENT'
              }
              wrapperClass="col-md-4"
              onChange={() => this.onSelectItem({ item })}
            />
          );
        },
      },
      {
        name: 'action',
        label: 'label.details',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.onHanldeViewBatch({ item })}
          >
            {t('label.view')}
          </button>
        ),
      },
      {
        name: 'activity',
        label: 'label.activity',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetActivityById({ item })}
            disabled={item?.extractStatus === 'PENDING'}
          >
            {t('label.view')}
          </button>
        ),
      },
      ...tableColumnsOutput,
    ];

    let optionActionType = t('selections:ERPExtractsAction')();
    if (itemSelected && itemSelected.extractStatus !== 'SENT') {
      optionActionType = optionActionType.filter(val => val.value !== 'RE_SEND');
    }

    let newCollapsible = collapsibleMenuAccountActivities.filter(val => val.entityType === itemData.entityType);

    if (itemData.entityType === 'CUSTOMER') {
      newCollapsible = collapsibleMenuAccountActivities.filter(val => val.entityType !== itemData.entityType);
    }
    if ((itemData.request && itemData.request !== 'null') || (itemData.response && itemData.response !== 'null')) {
      newCollapsible = [];
    }

    let newDataFieldRqRp = cloneDeep(dataFieldRqRp);

    if (!itemData.request || itemData.request === 'null') {
      newDataFieldRqRp = newDataFieldRqRp.filter(val => val.name !== 'request');
    }
    if (!itemData.response || itemData.response === 'null') {
      newDataFieldRqRp = newDataFieldRqRp.filter(val => val.name !== 'response');
    }

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.accountingLog.path}
          titleBtn={t('label.back')}
          titleRight={t('revenuePage:sidebar.erpExtracts')}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <ERPExtractsSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0">
              <div className="mt-3 row mb-4 pt-3 pb-3 mr-0">
                <button
                  type="submit"
                  onClick={this.onToggleModalExportConfig}
                  className="button x-small mr-2 btn-config ml-2"
                >
                  {/* {t('label.outputConfig')} */}
                  <i className="ti-list" />
                </button>
                <div className="ml-auto mt-2 mb-auto mr-3">
                  <SwitchExport
                    onChange={this.onChangeSwitch}
                    wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                    title={t('label.excel')}
                    checked={isActivePdf}
                    rightTitle={t('label.pdf')}
                  />
                </div>
                <>
                  <ButtonExport onExport={this.onExport} />
                  <ExcelExport
                    element={<button type="button" className="display-none" ref={this.buttonRef} />}
                    nameSheet={t('revenuePage:sidebar.erpExtracts').toLocaleUpperCase()}
                    multiDataSet={convertJson2Sheet({
                      data: listAllEbsExtract,
                      t,
                      title: t('revenuePage:sidebar.erpExtracts').toLocaleUpperCase(),
                      columnsTable: tableColumnsOutput,
                    })}
                    fileName={`${t('revenuePage:sidebar.erpExtracts')
                      .toLocaleLowerCase()
                      .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                  />
                </>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                columns={newTableColumns}
                sorted={sorted}
                data={data}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
              {!!modeResendErpBatch && (
                <div className="col-md-12 row pb-3 pt-3 pl-2">
                  <GenericInput
                    value={actionType}
                    name="actionType"
                    onChange={({ value }) => this.setState({ actionType: value })}
                    wrapperClass="col-md-3 mt-auto mb-auto mr-0"
                    label="label.action"
                    options={optionActionType || []}
                    type="select"
                    disabled={!itemSelected || !itemSelected.id || modeResendErpBatch === 1}
                  />
                  <div>
                    <button
                      className="button x-small mt-4"
                      disabled={!itemSelected || !itemSelected.id || !actionType || modeResendErpBatch === 1}
                      type="button"
                      onClick={this.onResendEbsExtract}
                    >
                      {t('label.submit')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        {/* 
        <ModalViewCSVFile
          onToggle={this.onToggle}
          config={s3Config}
          isOpen={isViewCSVFile}
          pathFileSelect={pathFileSelect}
        /> */}
        <ModalWithItem
          isOpen={isOpenBatch}
          onToggle={this.onHanldeCancelBatch}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          modalTitle={t('label.ERPExtractsDetails')}
        >
          <div className="col-md-12 row border-bottom pt-3 pb-3">
            {batchFileDetails.map(val => {
              return (
                <GenericInput
                  value={itemBatchFile ? itemBatchFile[val.name] : ''}
                  onChange={() => {}}
                  wrapperClass="col-md-3"
                  readOnly
                  {...val}
                />
              );
            })}
          </div>
          <div className="col-md-12 mt-2 mb-2">
            <button
              type="submit"
              onClick={this.onToggleModalDetailsExportConfig}
              className="button x-small mr-2 btn-config ml-2"
            >
              <i className="ti-list" />
            </button>
          </div>
          <DataTable columns={tableColumnsDetails} data={batchFileData || []} />
          <div className="col-sm-12 mb-30 mt-30 text-right">
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={this.onHanldeCancelBatch}
            >
              {t('label.cancel')}
            </button>
          </div>
        </ModalWithItem>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
        <ModalWithItem
          onToggle={this.onToggleModalDetailsExportConfig}
          isOpen={isOpenModalDetailsExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalDetailsExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveDetailsConfig}
                exportColumns={exportColumnsSaveDetails}
                onToggle={this.onToggleModalDetailsExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>

        <ModalDetailsItem
          isOpen={isOpenModalActivity}
          data={itemData}
          onToggle={this.onToggleModalActivity}
          dataField={newDataFieldRqRp}
          title={t('label.ERPExtractActivity')}
          isSearch={isSearchDataModal}
          wrapperClass="modal-custom modal-70"
        >
          <div className="pt-4">
            {newCollapsible.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeParentTab.name && activeParentTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={this.onToggleParentTab}
              >
                {collapsibleMenuBeforeAfter.map(val => (
                  <FormCollapse
                    key={val.title}
                    label="First Name"
                    isActive={val.state === activeChildrentTab.name && activeChildrentTab.isActive}
                    isActiveSubForm={val.state === activeChildrentTab.name && activeChildrentTab.isActive}
                    isSubCollapse
                    title={t(val.title) || ''}
                    state={val.state}
                    onToggleTab={this.onToggleChildrentTab}
                  >
                    {this.renderItem({
                      item,
                      data: itemData[val.key] && itemData[val.key][item.key] ? itemData[val.key][item.key] : null,
                    })}
                  </FormCollapse>
                ))}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
      </div>
    );
  }
}

ERPExtracts.propTypes = {
  searchEbsExtract: PropTypes.func,
};

ERPExtracts.defaultProps = {
  searchEbsExtract: () => {},
};

const mapStateToProps = createStructuredSelector({
  ebsExtract: makeGetEbsExtract() || [],
  s3Config: makeGetS3Config() || {},
  listAllEbsExtract: makeGetAllEbsExtract() || [],
  ccpTime: makeGetCcpTime() || '',
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchEbsExtract,
    readFileFromS3,
    getEbsExtractDetailsById,
    searchAllEbsExtract,
    resendEbsExtract,
    saveOutputTemplateByType,
    getOutputTemplateByType,
    getCcpDateTimeConfig,
    getCcpPropertiesConfig,
    getActivityEntry,
  })(withRouter(ERPExtracts))
);
