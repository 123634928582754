import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { getGrantsConfig, modifyGrantsConfig, createGrantsConfig } from '../../actions';
import { makeGetGrantsListConfig } from '../../selectors';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept } from '../../../../components/common';
import {
  checkPermissionCreateGrantsConfig,
  checkPermissionModifyGrantsConfig,
  checkPermissionViewGrantsConfig,
} from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

class Grants extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    grantsList: [],
    isSearching: false,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
  };

  componentDidMount() {
    this.doGetGrantsConfig();
  }

  componentWillReceiveProps(nextProps) {
    const { grantsList } = this.props;
    if (grantsList && nextProps.grantsList && grantsList !== nextProps.grantsList) {
      this.setState({
        grantsList: nextProps.grantsList,
        defaultDataFromAPI: nextProps.grantsList,
        dataSubmit: [],
      });
    }
  }

  onChangeTable = ({ name, value, index }) => {
    const { grantsList, dataSubmit } = this.state;
    const newData = cloneDeep(grantsList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ grantsList: newData });
  };

  doGetGrantsConfig = () => {
    const { getGrantsConfig } = this.props;
    getGrantsConfig(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id) });
    });
  };

  onAddNewGrant = () => {
    let indexNewItem = 0;
    const { grantsList, dataSubmit } = this.state;
    let newData = cloneDeep(grantsList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      resourceId: null,
      symbol: null,
      name: null,
      roundingMethod: null,
      roundingPrecision: null,
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ grantsList: newData, dataSubmit });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = () => {
    const { grantsList, defaultDataFromAPI, dataSubmit, idItemRemove } = this.state;
    const newData = cloneDeep(grantsList);
    const indexItemRemove = newData.findIndex(item => item.index === idItemRemove);
    const indexItemFromAPI = defaultDataFromAPI.findIndex(item => item.index === idItemRemove);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === idItemRemove);
    if (indexItemFromAPI > -1 && indexItemRemove > -1) {
      newData[indexItemRemove] = { index: idItemRemove };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index: idItemRemove };
      } else {
        dataSubmit.push({ index: idItemRemove });
      }
    }
    if (indexItemFromAPI === -1 && indexItemRemove > -1) {
      newData.splice(indexItemRemove, 1);
      if (indexDataSubmit > -1) {
        dataSubmit.splice(indexDataSubmit, 1);
      }
    }
    this.setState({ grantsList: newData, dataSubmit, isOpenModalRemoveItem: false });
  };

  onHanldeSubmit = () => {
    const { dataSubmit, isModify } = this.state;
    const { modifyGrantsConfig, createGrantsConfig } = this.props;
    if (isModify) {
      const newPayloadModify = {
        grantListConfig: dataSubmit,
      };
      modifyGrantsConfig(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(dataSubmit).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });

      const newPayloadCreate = {
        grantListConfig: dataRemoveIndex,
      };
      createGrantsConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetGrantsConfig();
        }
      });
    }
  };

  render() {
    const { grantsList, isSearching, isOpenModalRemoveItem, isModify } = this.state;
    const { t, permissionsPricing } = this.props;

    let modeCreateGrantsConfig = 0;
    let modeModifyGrantsConfig = 0;
    let modeViewGrantsConfig = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeViewGrantsConfig = checkPermissionViewGrantsConfig({
        listPermission,
      });
      modeCreateGrantsConfig = checkPermissionCreateGrantsConfig({
        listPermission,
      });
      modeModifyGrantsConfig = checkPermissionModifyGrantsConfig({
        listPermission,
      });
    }

    if (!modeViewGrantsConfig) return null;

    const creditProfileColumns = [
      {
        name: 'resourceId',
        label: 'label.grantId',
        required: true,
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item) => (
          <GenericInput
            value={item.resourceId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="resourceId"
          />
        ),
      },
      {
        name: 'symbol',
        label: 'label.symbol',
        required: true,
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => {
          return (
            <GenericInput
              value={item.symbol || null}
              type="text"
              // options={convertSymbol2OptionName}
              // menuPortalTarget={document.querySelector('body')}
              wrapperClass="col-md-12"
              onChange={evt => this.onChangeTable({ name: 'symbol', value: evt.value, index: item.index })}
              name="symbol"
            />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="name"
          />
        ),
      },
      {
        name: 'grantType',
        label: 'label.grantType',
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.grantType}
            menuPortalTarget
            tOptions="selections:grantTypeConfig"
            type="select"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="grantType"
          />
        ),
      },
      {
        name: 'roundingMethod',
        label: 'label.roundingMethod',
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.roundingMethod}
            menuPortalTarget
            tOptions="selections:roundingMethod"
            type="select"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="roundingMethod"
          />
        ),
      },
      {
        name: 'roundingPrecision',
        label: 'label.roundingPrecision',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.roundingPrecision}
            type="number"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="roundingPrecision"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onToggleModalRemoveItem({ index: item.index })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataRemoveIndex = grantsList && grantsList.length ? grantsList.filter(item => size(item) > 1) : [];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.grants') },
          ]}
        />
        <FormWithTableItem title={t('baseConfigurationsPage:sidebar.grants')} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateGrantsConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewGrant}>
                +
                {t('label.addNewGrant')}
              </button>
            )}
            {modeModifyGrantsConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {isModify ? t('label.modify') : t('label.createConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetGrantsConfig()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={creditProfileColumns}
              data={newDataRemoveIndex}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message={t('label.messageDelete')}
        />
      </div>
    );
  }
}

Grants.propTypes = {
  getGrantsConfig: PropTypes.func.isRequired,
  modifyGrantsConfig: PropTypes.func.isRequired,
  createGrantsConfig: PropTypes.func.isRequired,
  grantsList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

Grants.defaultProps = {
  grantsList: [],
};

const mapStateToProps = createStructuredSelector({
  grantsList: makeGetGrantsListConfig() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getGrantsConfig, modifyGrantsConfig, createGrantsConfig })(Grants)
);
