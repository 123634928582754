import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { searchProductFamilyConfig, modifyProductFamilyConfig, createProductFamilyConfig } from '../actions';
import { makeGetProductFamilyConfig } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept, TablePagination } from '../../../components/common';
import { getPageTotalCount, validate, compareArrayIndexValueChange } from '../../../utils/utils';
import { ProductFamilySearchForm } from '../../../components/PricingHub';
import {
  checkPermissionCreateProductFamily,
  checkPermissioModifyProductFamily,
  checkPermissionSearchProductFamily,
} from '../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';

const sortProductFamilyConfig = {
  productCompany: {
    asc: 'productCompany_ASC',
    desc: 'productCompany_DESC',
  },
  productFamily: {
    asc: 'productFamily_ASC',
    desc: 'productFamily_DESC',
  },
};

class ProductFamily extends PureComponent {
  formRef = React.createRef();

  state = {
    defaultDataFromAPI: [],
    productFamilyList: [],
    isSearching: true,
    isOpenModalRemoveItem: false,
    isModify: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    wasValidated: false,
    isFirstSearch: false,
  };

  componentDidMount() {
    this.doGetProductFamilyConfig();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.productFamilyList) return null;
    return {
      productFamilyList: props.productFamilyList,
      defaultDataFromAPI: props.productFamilyList,
      totalCount: getPageTotalCount({ ...state, items: state.productFamilyList }),
      oldValue: props.productFamilyList,
    };
  }

  onChangeTable = ({ name, value, index }) => {
    const { productFamilyList } = this.state;
    const newData = cloneDeep(productFamilyList);
    newData[index][name] = value;
    this.setState({ productFamilyList: newData });
  };

  doGetProductFamilyConfig = () => {
    const { searchProductFamilyConfig } = this.props;
    const { filter, page, size, sorted, isFirstSearch } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortProductFamilyConfig[sorted.sortCol][sorted.sortDir] : null,
    };
    searchProductFamilyConfig(payload, ({ data }) => {
      if (!isFirstSearch) {
        this.setState({ isModify: !!(data && data[0].id) });
      }
      this.setState({ isSearching: false });
    });
  };

  onAddNewProduct = () => {
    const { productFamilyList } = this.state;
    let newData = cloneDeep(productFamilyList);

    const newDataItem = {
      index: -1,
      productCompany: null,
      productFamily: null,
      productLine: null,
      productType: null,
      productSubType: null,
    };
    newData = [newDataItem, ...newData];
    this.setState({ productFamilyList: newData });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = () => {
    const { productFamilyList, defaultDataFromAPI, idItemRemove } = this.state;
    const newData = cloneDeep(productFamilyList);
    const indexItemRemove = newData.findIndex(item => item.index === idItemRemove);
    const indexItemFromAPI = defaultDataFromAPI.findIndex(item => item.index === idItemRemove);
    if (indexItemFromAPI > -1 && indexItemRemove > -1) {
      newData[indexItemRemove] = { index: idItemRemove };
    }
    if (indexItemFromAPI === -1 && indexItemRemove > -1) {
      newData.splice(indexItemRemove, 1);
    }
    this.setState({ productFamilyList: newData, isOpenModalRemoveItem: false });
  };

  onHanldeSubmit = evt => {
    const { isModify, productFamilyList, defaultDataFromAPI } = this.state;
    const { modifyProductFamilyConfig, createProductFamilyConfig, t } = this.props;
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });
    if (isModify) {
      const newPayloadModify = {
        productFamilyList: compareArrayIndexValueChange({
          defaultData: defaultDataFromAPI,
          newData: productFamilyList,
        }),
      };
      modifyProductFamilyConfig(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(productFamilyList).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        productFamilyList: dataRemoveIndex,
      };
      createProductFamilyConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetProductFamilyConfig();
        }
      });
    }
  };

  onPageChange = newPage => {
    const { page } = this.state;
    if (newPage === page) return '';
    this.setState({ page: newPage }, () => this.doGetProductFamilyConfig());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetProductFamilyConfig());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetProductFamilyConfig());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetProductFamilyConfig());
  };

  render() {
    const {
      productFamilyList,
      isSearching,
      isOpenModalRemoveItem,
      isModify,
      page,
      size,
      totalCount,
      sorted,
      wasValidated,
    } = this.state;
    const { t, permissionsPricing } = this.props;

    let modeCreateProductFamily = 0;
    let modeModifyProductFamily = 0;
    let modeSearchProductFamily = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeCreateProductFamily = checkPermissionCreateProductFamily({ listPermission });
      modeModifyProductFamily = checkPermissioModifyProductFamily({ listPermission });
      modeSearchProductFamily = checkPermissionSearchProductFamily({ listPermission });
    }

    if (!modeSearchProductFamily) return null;

    const creditProfileColumns = [
      {
        name: 'productCompany',
        label: 'label.productCompany',
        style: { textAlign: 'center', minWidth: '130px' },
        sortable: true,
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.productCompany}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index, item })}
            name="productCompany"
            required
          />
        ),
      },
      {
        name: 'productFamily',
        label: 'label.productFamily',
        sortable: true,
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.productFamily}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index, item })}
            name="productFamily"
            required
          />
        ),
      },
      {
        name: 'productLine',
        label: 'label.productLine',
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.productLine}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index, item })}
            name="productLine"
            required
          />
        ),
      },
      {
        name: 'productType',
        label: 'label.productType',
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.productType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index, item })}
            name="productType"
            required
          />
        ),
      },
      {
        name: 'productSubType',
        label: 'label.productSubType',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.productSubType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index, item })}
            name="productSubType"
            required
          />
        ),
      },

      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                disabled={modeModifyProductFamily !== 2}
                onClick={() => this.onToggleModalRemoveItem({ index: item.index })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.productFamilyPricing.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.productFamilyPricing.path },
            { name: t('baseConfigurationsPage:sidebar.productFamily') },
          ]}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <ProductFamilySearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormWithTableItem title={t('baseConfigurationsPage:sidebar.productFamily')} subClass="border-bottom">
          <form
            ref={this.formRef}
            onSubmit={this.onHanldeSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
          >
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateProductFamily === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProduct}>
                  +
                  {t('label.addNewProduct')}
                </button>
              )}
              {modeModifyProductFamily === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {isModify ? t('label.modify') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => this.doGetProductFamilyConfig()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <div>
              <DataTable
                columns={creditProfileColumns}
                data={productFamilyList}
                isLoading={isSearching}
                isFixedHeaderTable
                sorted={sorted}
                onSort={this.onSortColumn}
                isSupportRemoveIndex
              />
            </div>
            <br />
          </form>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message="Are you sure you want to delete this Item?"
        />
        <div className="mb-30 mt-2">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

ProductFamily.propTypes = {
  searchProductFamilyConfig: PropTypes.func.isRequired,
  modifyProductFamilyConfig: PropTypes.func.isRequired,
  createProductFamilyConfig: PropTypes.func.isRequired,
  productFamilyList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

ProductFamily.defaultProps = {
  productFamilyList: [],
};

const mapStateToProps = createStructuredSelector({
  productFamilyList: makeGetProductFamilyConfig() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { searchProductFamilyConfig, modifyProductFamilyConfig, createProductFamilyConfig })(
    ProductFamily
  )
);
