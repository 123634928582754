import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['type', 'index', 'arType']);
  const modifyARItemConfig = `modifyARItemConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyARItemConfig} {
      id
    }
  }
`;
};
