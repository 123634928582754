export const GET_AR_REASON_CODE_REVENUE = 'revenue/revenueManagement/GET_AR_REASON_CODE_REVENUE';
export const MODIFY_AR_REASON_CODE_REVENUE = 'revenue/revenueManagement/MODIFY_AR_REASON_CODE_REVENUE';
export const CREATE_AR_REASON_CODE_REVENUE = 'revenue/revenueManagement/CREATE_AR_REASON_CODE_REVENUE';
export const SEARCH_REVENUE_MILESTONE = 'revenue/revenueManagement/SEARCH_REVENUE_MILESTONE';
export const SEARCH_REVENUE_MILESTONE_SUCCESS = 'revenue/revenueManagement/SEARCH_REVENUE_MILESTONE_SUCCESS';
export const GET_REVENUE_MILESTONE_BY_ID = 'revenue/revenueManagement/GET_REVENUE_MILESTONE_BY_ID';
export const GET_REVENUE_MILESTONE_BY_ID_SUCCESS = 'revenue/revenueManagement/GET_REVENUE_MILESTONE_BY_ID_SUCCESS';
export const MODIFY_REVENUE_MILESTONE = 'revenue/revenueManagement/MODIFY_REVENUE_MILESTONE';
export const CREATE_REVENUE_MILESTONE = 'revenue/revenueManagement/CREATE_REVENUE_MILESTONE';
export const SEARCH_EXCHANGE_RATE = 'revenue/revenueManagement/SEARCH_EXCHANGE_RATE';
export const SEARCH_EXCHANGE_RATE_SUCCESS = 'revenue/revenueManagement/SEARCH_EXCHANGE_RATE_SUCCESS';
export const GET_EXCHANGE_RATE_BY_ID = 'revenue/revenueManagement/GET_EXCHANGE_RATE_BY_ID';
export const GET_EXCHANGE_RATE_BY_ID_SUCCESS = 'revenue/revenueManagement/GET_EXCHANGE_RATE_BY_ID_SUCCESS';
export const MODIFY_EXCHANGE_RATE = 'revenue/revenueManagement/MODIFY_EXCHANGE_RATE';
export const CREATE_EXCHANGE_RATE = 'revenue/revenueManagement/CREATE_EXCHANGE_RATE';
export const EXPORT_EXCHANGE_RATE_BY_ID = 'revenue/revenueManagement/EXPORT_EXCHANGE_RATE_BY_ID';
export const EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS = 'revenue/revenueManagement/EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS';
export const SEARCH_ACCOUNTING_ENTRY = 'revenue/revenueManagement/SEARCH_ACCOUNTING_ENTRY';
export const SEARCH_ACCOUNTING_ENTRY_SUCCESS = 'revenue/revenueManagement/SEARCH_ACCOUNTING_ENTRY_SUCCESS';
export const SEARCH_EBS_EXTRACT = 'revenue/revenueManagement/SEARCH_EBS_EXTRACT';
export const SEARCH_EBS_EXTRACT_SUCCESS = 'revenue/revenueManagement/SEARCH_EBS_EXTRACT_SUCCESS';
export const GET_EBS_EXTRACT_BY_BATCH_ID = 'revenue/revenueManagement/GET_EBS_EXTRACT_BY_BATCH_ID';

export const SEARCH_REVENUE_JOURNAL = 'revenue/revenueManagement/SEARCH_REVENUE_JOURNAL';
export const SEARCH_REVENUE_JOURNAL_SUCCESS = 'revenue/revenueManagement/SEARCH_REVENUE_JOURNAL_SUCCESS';
export const SEARCH_ALL_REVENUE_JOURNAL = 'revenue/revenueManagement/SEARCH_ALL_REVENUE_JOURNAL';
export const SEARCH_ALL_REVENUE_JOURNAL_SUCCESS = 'revenue/revenueManagement/SEARCH_ALL_REVENUE_JOURNAL_SUCCESS';

export const SEARCH_ALL_ACCOUNT_ENTRY = 'revenue/revenueManagement/SEARCH_ALL_ACCOUNT_ENTRY';
export const SEARCH_ALL_ACCOUNT_ENTRY_SUCCESS = 'revenue/revenueManagement/SEARCH_ALL_ACCOUNT_ENTRY_SUCCESS';

export const MODIFY_REVENUE_JOURNAL = 'revenue/revenueManagement/MODIFY_REVENUE_JOURNAL';
export const SEARCH_ALL_EBS_EXTRACT = 'revenue/revenueManagement/SEARCH_ALL_EBS_EXTRACT';
export const SEARCH_ALL_EBS_EXTRACT_SUCCESS = 'revenue/revenueManagement/SEARCH_ALL_EBS_EXTRACT_SUCCESS';
export const SEARCH_REV_REC_JOURNAL = 'revenue/revenueManagement/SEARCH_REV_REC_JOURNAL';
export const CREATE_REVENUE_JOURNAL = 'revenue/revenueManagement/CREATE_REVENUE_JOURNAL';
export const RECOGNIZE_MANUAL_REVENUE = 'revenue/revenueManagement/RECOGNIZE_MANUAL_REVENUE';
export const RESEND_EBS_EXTRACT = 'revenue/revenueManagement/RESEND_EBS_EXTRACT';
export const GET_REVENUE_RECOGNITIION_SUMMARY = 'revenue/revenueManagement/GET_REVENUE_RECOGNITIION_SUMMARY';
export const GET_ACTIVITY_ENTRY = 'revenue/revenueManagement/GET_ACTIVITY_ENTRY';