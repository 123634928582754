import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, cloneDeep, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { DataTable } from '../../../../components/common';
import ModalRoleGroupSelector from '../ModalRoleGroupSelector';

class RoleGroupForm extends Component {
  state = {
    roleGroups: [],
    isSelectingRoles: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (isEqual(state.oldData, props.roleGroups)) return null;
    return {
      roleGroups: props.roleGroups ? [...props.roleGroups] : [],
      oldData: props.roleGroups,
    };
  }

  onOpenParentSelector = () => {
    this.setState({ isSelectingRoles: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingRoles: false });
  };

  onSelectRoleGroup = (parentItemId, item) => {
    const { roleGroups = [] } = this.state;
    this.setState({ parentItemId, roleGroups: [...roleGroups, { ...item }], isSelectingRoles: false }, () =>
      this.onChangeItem()
    );
  };

  onRemoveRole = (e, role) => {
    e.preventDefault();
    const { roleGroups } = this.state;
    const newRoleGroups = cloneDeep(roleGroups);
    const indexRoleDelete = newRoleGroups.findIndex(item => item.id === role.id);
    newRoleGroups[indexRoleDelete] = { index: newRoleGroups[indexRoleDelete].index };
    this.setState({ roleGroups: newRoleGroups }, () => this.onChangeItem());
  };

  onChangeItem = () => {
    const { onChangeItem } = this.props;

    if (onChangeItem) {
      const { roleGroups } = this.state;
      onChangeItem(roleGroups);
    }
  };

  render() {
    const { roleGroups, isSelectingRoles, parentItemId } = this.state;
    const { t } = this.props;

    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.id',
        },
        {
          name: 'status',
          label: 'label.status',
        },
        {
          name: 'type',
          label: 'label.type',
        },
        {
          name: 'name',
          label: 'label.name',
        },
        {
          name: 'description',
          label: 'label.description',
        },
        {
          name: 'action',
          label: 'label.action',
          render: (colName, item) => (
            <button type="button" className="btn-trash" onClick={e => this.onRemoveRole(e, item)}>
              <i className="fa fa-trash" />
            </button>
          ),
        },
      ],
      data: roleGroups.filter(item => size(item) > 2) || [],
    };
    return (
      <div>
        <div className="form-row">
          <DataTable {...tableConfig} isLoading={false} noDataMessage="No role group!" />
        </div>
        <div className="form-row">
          <button type="button" className="button button-border x-small float-left" onClick={this.onOpenParentSelector}>
            {t('label.addRoleGroup')}
          </button>
        </div>
        <ModalRoleGroupSelector
          isOpen={isSelectingRoles}
          selectedParentId={parentItemId}
          onSelect={this.onSelectRoleGroup}
          onCancel={this.onCancelSelector}
        />
      </div>
    );
  }
}

RoleGroupForm.propTypes = {
  roleGroups: PropTypes.arrayOf(PropTypes.any),
  isOpen: PropTypes.bool,
  onChangeItem: PropTypes.func,
  creatingMode: PropTypes.bool,
};

RoleGroupForm.defaultProps = {
  roleGroups: [],
  isOpen: false,
  creatingMode: true,
  onChangeItem: () => {},
};
export default withTranslation('common')(RoleGroupForm);
