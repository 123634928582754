import gql from 'graphql-tag';

export default ({ accountId, omsInternalId }) => gql`
  query {
    getOrderHistory(input: { accountId: "${accountId}", omsInternalId: "${omsInternalId}" }) {
      orderId
      crmId
      provisioningId
      status
      orderDate
      cancellationDate
      effectiveDate
      deliveryDate
      reason
      action
      quantity
      amount
      unitPrice
      opportunityId
      opportunityName
    }
  }
`;
