import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { GenericInput } from '../common';
import dataField from '../../containers/TenantManagement/constant';
import {
  TaxGatewayAttributesForm,
  HttpBasicAttributesForm,
} from '../../containers/TenantManagement/TenantDetail/SubForm';

export default class TaxGateway extends React.PureComponent {
  state = { tenantData: [{}] };

  componentDidMount() {
    const { data } = this.props;
    if (data.length > 0) this.setState({ tenantData: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.length > 0) {
      this.setState({ tenantData: nextProps.data });
    }
  }

  onChange = ({ name, value, index, indexCreate }) => {
    const { onHandleChange } = this.props;
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    newData[index][name] = value;
    this.setState({ tenantData: newData });
    onHandleChange({ name, value, index, indexCreate });
  };

  onChangeAttributes = ({ name, value, index, indexAttr, fieldName = '', isNotIndex = false }) => {
    const { tenantData } = this.state;
    const { onChangeAttributes } = this.props;
    tenantData[index][fieldName][indexAttr][name] = value;
    this.setState({ tenantData });
    onChangeAttributes({ name, value, index, indexAttr, fieldName, isNotIndex });
  };

  render() {
    const { tenantData } = this.state;
    const { modeModifyTaxGatewayMerchant, isRequireField } = this.props;
    return (
      <div>
        {tenantData.map((item, index) => (
          <div className={`col-md-12 group-flex mt-2 ${index > 0 ? 'geteway-group' : ''}`} key={index}>
            {dataField.fieldGetwayForm.map(field => {
              if (field && (!isEmpty(field.options) || field.tOptions)) {
                return (
                  <GenericInput
                    key={field.name}
                    label={field.label || ''}
                    type="select"
                    value={item[field.name] || null}
                    wrapperClass="col-md-4"
                    required={isRequireField}
                    disabled={field.disabled || modeModifyTaxGatewayMerchant === 1}
                    onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 0 })}
                    name={field.name}
                    {...field}
                  />
                );
              }

              if (field && field.isFieldDate) {
                return (
                  <GenericInput
                    key={field.name}
                    label={field.label || ''}
                    type="date"
                    required={isRequireField}
                    value={item[field.name] || null}
                    disabled={modeModifyTaxGatewayMerchant === 1}
                    wrapperClass="col-md-4"
                    onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 0 })}
                    name={field.name}
                  />
                );
              }

              return (
                <GenericInput
                  key={field.name}
                  label={field.label || ''}
                  value={item[field.name] || null}
                  required={isRequireField}
                  type={field.isNumber ? 'number' : 'text'}
                  onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 0 })}
                  name={field.name}
                  wrapperClass="col-md-4"
                />
              );
            })}

            {!isEmpty(tenantData[index]) && !isEmpty(tenantData[index].taxGatewayAttributes) && (
              <TaxGatewayAttributesForm
                onHandleChange={this.onChangeAttributes}
                index={index}
                isRequireField={isRequireField}
                modeModifyTaxGatewayMerchant={modeModifyTaxGatewayMerchant}
                data={tenantData[index].taxGatewayAttributes || []}
              />
            )}

            {!isEmpty(tenantData[index]) && !isEmpty(tenantData[index].httpBasicAttributes) && (
              <HttpBasicAttributesForm
                onHandleChange={this.onChangeAttributes}
                index={index}
                isRequireField={isRequireField}
                modeModifyTaxGatewayMerchant={modeModifyTaxGatewayMerchant}
                data={tenantData[index].httpBasicAttributes || []}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

TaxGateway.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onHandleChange: PropTypes.func,
  onChangeAttributes: PropTypes.func,
  modeModifyTaxGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

TaxGateway.defaultProps = {
  data: [{}],
  onHandleChange: () => {},
  onChangeAttributes: () => {},
  modeModifyTaxGatewayMerchant: 2,
  isRequireField: false,
};
