import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { validateEmail } from '../../../utils/utils';

const MultiInputGroup = ({ label, wrapperClass, onOpenPopupInfo, onChange, inputProps, ...props }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`form-group ${wrapperClass}`}>
      {label && (
        <span className="label-item">
          {t(label)}
          {label && props.required && <i className="fa fa-star is-required" />}
          &nbsp;
        </span>
      )}
      <div className="wr-input" id={`${props.name}`}>
        <TagsInput
          addOnBlur
          addKeys={[9, 13, 32]}
          onChange={onChange}
          className="form-control input-group"
          {...props}
          inputProps={{
            ...inputProps,
            onBlur: evt => {
              const { value } = props;
              if (evt?.target?.value) {
                if (validateEmail(evt?.target?.value)) {
                  onChange([...value, evt?.target?.value]);
                }
              }
            },
          }}
        />
      </div>
    </div>
  );
};

MultiInputGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onOpenPopupInfo: PropTypes.func,
  required: PropTypes.bool,
};

MultiInputGroup.defaultProps = {
  label: '',
  onChange: () => {},
  onOpenPopupInfo: () => {},
  type: 'text',
  wrapperClass: 'col-md-3',
  required: false,
};

export default MultiInputGroup;
