import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FocusSelectTable, FocusInputTable } from '../../common';

const headers = ['label.arType', 'label.reasonCode', 'label.taxCode', 'label.glAccount', 'label.remove'];

export default function ArOpsConfigTable({ data, onRemove, onHandleChangeTable }) {
  const { t } = useTranslation('common');
  return (
    <div className="table-responsive mt-15 table-form sticky-header form-focus">
      <table className="table center-aligned-table mb-0">
        <thead>
          <tr>
            {headers.map(item => (
              <th key={item}>{t(item)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <FocusSelectTable
                value={t('selections:arType')().find(val => val.value === item.type)}
                options={t('selections:arType')()}
                name="type"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              />
              <FocusSelectTable
                value={t('selections:reasonConfig')().find(val => val.value === item.reasonCode)}
                options={t('selections:reasonConfig')()}
                name="reasonCode"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              />
              <FocusInputTable
                value={item.taxCode}
                name="taxCode"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              />
              <FocusInputTable
                value={item.gLAccount}
                name="gLAccount"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              />
              <td>
                <button type="button" className="btn-trash" onClick={() => onRemove(item)}>
                  <i className="fa fa-trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ArOpsConfigTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChangeTable: PropTypes.func,
  onRemove: PropTypes.func,
};

ArOpsConfigTable.defaultProps = {
  data: [],
  onHandleChangeTable: () => {},
  onRemove: () => {},
};
