import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['serviceType']);
  const createPrepaidSystemOffers = `createPrepaidSystemOffers(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createPrepaidSystemOffers} {
      id
    }
  }
`;
};
