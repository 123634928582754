import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  // const processSavedManualInvoice = `processSavedManualInvoice(input: ${dataCreate})`;
  let newData = dataCreate.replace('{embrixData: ', '');
  newData = `${newData.substring(0, newData.length - 1)}`;
  const processSavedManualInvoice = `processSavedManualInvoice(input: ${newData})`;
  return gql`
  mutation {
    ${processSavedManualInvoice}
  }
`;
};
