import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { saveAs } from 'file-saver';
import { CsvToHtmlTable } from 'react-csv-to-table';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import TitleFrom from '../../../components/common/TitleFrom';
import { OperatorInvoiceSearchForm } from '../../../components/UsageHub';
import { DataTable, TablePagination, ModalWithItem, ModalUploadFile, GenericInput } from '../../../components/common';
import { tabelColumnOperatorInvoice, sortOperatorInvoice, tabelColumnOperatorInvoiceById } from '../constants';
import { searchOperatorInvoice, getOperatorInvoiceById, operatorInvoiceRecon } from '../actions';
import { makeGetOperatorInvoice } from '../selectors';
import { getPageTotalCount, getBase64, splitF, parseToMutationRequestPostMethod } from '../../../utils/utils';
import { makeGetS3Config } from '../../App/selectors';
import { readFileFromS3, uploadMultiPartFiles } from '../../App/actions';

let fileSelect = null;
let pathSelect = null;
let idSelect = '';

class Invoice extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listOperatorInvoice: [],
    isOpenMdalDetails: false,
    itemDetails: [],
    pageItem: 0,
    sizeItem: 20,
    itemSelect: {},
    totalCountItem: null,
    base64File: null,
    isOpenMdalDownload: false,
    isOpenUploadFile: false,
    serviceType: null,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listOperatorInvoice: props.listOperatorInvoice,
      totalCount: getPageTotalCount({ ...state, items: props.listOperatorInvoice }),
    };
  }

  componentDidMount() {
    this.doSearchOperatorInvoice();
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doSearchOperatorInvoice();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchOperatorInvoice());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchOperatorInvoice());
  };

  onPageChangeItem = page => {
    const { pageItem } = this.state;
    if (pageItem === page) return '';
    this.setState({ pageItem: page }, () => this.doGetOperatorInvoiceById());
  };

  onSizeChangeItem = size => {
    this.setState({ sizeItem: size, page: 0 }, () => this.doGetOperatorInvoiceById());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doSearchOperatorInvoice();
    });
  };

  doSearchOperatorInvoice = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchOperatorInvoice } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortOperatorInvoice[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchOperatorInvoice(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doReadFileFromS3 = ({ config, path }) => {
    const { readFileFromS3 } = this.props;
    readFileFromS3({ ...config.data, url: path }, ({ success, data }) => {
      if (success) {
        fileSelect = data;
        pathSelect = path;
        this.handleFiles(data);
        getBase64(data, result => {
          this.setState({ base64File: result });
        });
      }
    });
  };

  downloadFile = path => {
    const { s3Config } = this.props;
    this.doReadFileFromS3({
      path,
      config: { data: s3Config },
    });
  };

  saveFileSelect = () => {
    saveAs(fileSelect, `${splitF(pathSelect)}`);
  };

  doGetOperatorInvoiceById = (id, item) => {
    idSelect = id || idSelect;
    const { pageItem, sizeItem, totalCountItem, itemSelect } = this.state;
    const { getOperatorInvoiceById } = this.props;
    const payload = {
      page: pageItem + 1,
      size: sizeItem,
      id: idSelect,
    };
    getOperatorInvoiceById(payload, ({ success, data }) => {
      if (success) {
        this.setState({
          itemDetails: data,
          isOpenMdalDetails: true,
          itemSelect: item || itemSelect,
          totalCountItem: getPageTotalCount({
            totalCount: totalCountItem || data.length,
            size: sizeItem,
            page: pageItem,
            items: data,
          }),
        });
      } else {
        this.setState({ itemDetails: [], isOpenMdalDetails: true, itemSelect: item });
      }
    });
  };

  handleFiles = () => {
    if (!fileSelect) return;
    const reader = new FileReader();
    reader.onload = () => this.setState({ isCsv: true, csvData: reader.result });
    reader.readAsText(fileSelect);
  };

  onCancelUploadFile = () => {
    this.setState({ isOpenUploadFile: false, itemSelect: null });
  };

  onToggleModal = () => {
    const { isOpenUploadFile } = this.state;
    this.setState({ isOpenUploadFile: !isOpenUploadFile });
  };

  onHandleUpload = ({ files }) => {
    const { isOpenUploadFile, serviceType } = this.state;
    const { uploadMultiPartFiles } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        {
          category: 'INTERCONNECT',
          interconnectSubType: 'INVOICE',
          serviceType: serviceType || null,
          name: files[0].name,
        },
        ['category', 'interconnectSubType', 'serviceType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          this.doSearchOperatorInvoice();
          this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null, serviceType: null });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null, serviceType: null });
    }
  };

  doOperatorInvoiceRecon = ({ fileName }) => {
    const { operatorInvoiceRecon } = this.props;
    operatorInvoiceRecon(fileName);
  };

  render() {
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      listOperatorInvoice,
      isOpenMdalDetails,
      itemDetails,
      itemSelect,
      sizeItem,
      pageItem,
      totalCountItem,
      isOpenMdalDownload,
      csvData,
      isOpenUploadFile,
      serviceType,
    } = this.state;
    const { t } = this.props;

    const newTabelColumnOperatorDispute = [
      {
        name: 'id',
        label: 'common:label.id',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm no-border"
            onClick={() => this.doGetOperatorInvoiceById(item.id, item)}
          >
            {item.id}
          </button>
        ),
      },
      ...tabelColumnOperatorInvoice,
      {
        name: 'view',
        label: 'common:label.viewRecords',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetOperatorInvoiceById(item.id, item)}
          >
            {t('label.viewRecords')}
          </button>
        ),
      },
      {
        name: 'download',
        label: 'common:label.viewFiles',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            disabled={!item.filePath}
            onClick={() => {
              this.downloadFile(item.filePath);
              this.setState({ isOpenMdalDownload: true });
            }}
          >
            {t('label.viewFiles')}
          </button>
        ),
      },
      {
        name: 'reconcile',
        label: 'common:label.reconcile',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            disabled={!item.fileName}
            onClick={() => {
              this.doOperatorInvoiceRecon({ fileName: item.fileName });
            }}
          >
            {t('label.reconcile')}
          </button>
        ),
      },
    ];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.invoiceUsage.path}
          titleBtn="back"
          items={[{ name: t('usagePage:sidebar.operator') }, { name: t('label.operatorInvoiceFile') }]}
          isNoDefaultButton
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('label.operatorInvoiceFile')} />
          <div className="col-md-12">
            <button type="button" className="button x-small float-right mr-2" onClick={this.onToggleModal}>
              {t('label.upload')}
            </button>
          </div>
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <OperatorInvoiceSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <br />
                <DataTable
                  columns={newTabelColumnOperatorDispute}
                  data={listOperatorInvoice && listOperatorInvoice.length ? listOperatorInvoice : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>

        <ModalWithItem
          modalTitle={t('label.operatorInvoiceFile')}
          isOpen={isOpenMdalDetails}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          onToggle={() => {
            this.setState({ isOpenMdalDetails: false, itemDetails: [] });
          }}
        >
          <div className="col-md-12 row pb-3">
            <GenericInput
              value={itemSelect ? itemSelect.id : ''}
              wrapperClass="col-md-4"
              onChange={() => {}}
              name="fileId"
              readOnly
              label="label.fileId"
            />
            <GenericInput
              value={itemSelect ? itemSelect.fileName : ''}
              wrapperClass="col-md-4"
              onChange={() => {}}
              name="fileName"
              readOnly
              label="label.fileName"
            />
          </div>
          <DataTable
            columns={tabelColumnOperatorInvoiceById}
            data={itemDetails && itemDetails.length ? itemDetails : []}
            sorted={sorted}
            isLoading={isSearching}
          />
          <br />
          <TablePagination
            pageNumber={pageItem}
            pageSize={sizeItem}
            totalCount={totalCountItem}
            onPageChange={this.onPageChangeItem}
            onSizeChange={this.onSizeChangeItem}
          />
          <div className="text-right">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                this.setState({ isOpenMdalDetails: false, itemDetails: [] });
              }}
            >
              {t('common:label.cancel')}
            </button>
          </div>
          <br />
        </ModalWithItem>
        <ModalWithItem
          modalTitle={t('label.operatorInvoiceFile')}
          isOpen={isOpenMdalDownload}
          onToggle={() => {
            this.setState({ isOpenMdalDownload: false });
          }}
        >
          <div className="col-md-12 d-flex p-0">
            <div>
              <button
                type="button"
                className="button x-small"
                onClick={() => {
                  this.setState({ isOpenMdalDownload: false });
                }}
              >
                {t('label.back')}
              </button>
            </div>
            <div className="group-btn-invoice text-right" style={{ width: '96%' }}>
              <button
                type="button"
                disabled={!csvData}
                className="button x-small"
                onClick={() => this.saveFileSelect()}
              >
                {t('label.download')}
              </button>
            </div>
          </div>
          <CsvToHtmlTable data={csvData} csvDelimiter="," tableClassName="table table-striped table-hover" />
        </ModalWithItem>
        <ModalUploadFile
          title={t('label.uploadInvoiceFile')}
          isOpen={isOpenUploadFile}
          onCancel={this.onCancelUploadFile}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          accept=".csv, text/plain"
          acceptFileType={['csv', 'text']}
          messageValid={t('message.validUploadMessage')}
        >
          <div className="col-md-12 row pb-3">
            <GenericInput
              value="INTERCONNECT"
              wrapperClass="col-md-4"
              onChange={() => {}}
              name="fileCategory"
              readOnly
              label="label.fileCategory"
            />
            <GenericInput
              value="INVOICE"
              wrapperClass="col-md-4"
              onChange={() => {}}
              name="fileType"
              readOnly
              label="label.fileType"
            />
            <GenericInput
              value={serviceType}
              wrapperClass="col-md-4"
              onChange={({ value }) => {
                this.setState({ serviceType: value });
              }}
              name="serviceType"
              type="select"
              tOptions="selections:serviceType"
              label="label.serviceType"
            />
          </div>
        </ModalUploadFile>
      </div>
    );
  }
}

Invoice.propTypes = {
  searchOperatorInvoice: PropTypes.func.isRequired,
  readFileFromS3: PropTypes.func.isRequired,
  getOperatorInvoiceById: PropTypes.func.isRequired,
  uploadMultiPartFiles: PropTypes.func.isRequired,
  operatorInvoiceRecon: PropTypes.func.isRequired,
};

Invoice.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  listOperatorInvoice: makeGetOperatorInvoice() || [],
  s3Config: makeGetS3Config() || {},
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    searchOperatorInvoice,
    readFileFromS3,
    getOperatorInvoiceById,
    uploadMultiPartFiles,
    operatorInvoiceRecon,
  })(Invoice)
);
