import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.name'),
  t('label.quantity'),
  t('label.amount'),
  t('label.taxCode'),
  t('label.ieps'),
  t('label.iva'),
  t('label.startDate'),
  t('label.endDate'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.description ? item.description : '');
      newItem.push(formatMoneyValueToExport(item.quantity));
      newItem.push(formatMoneyValueToExport(item.taxableAmount))
      newItem.push(item.taxCode ? item.taxCode : '');
      newItem.push(formatMoneyValueToExport(item.ieps));
      newItem.push(formatMoneyValueToExport(item.iva));
      newItem.push(formatMoneyValueToExport(item.appliedStart));
      newItem.push(formatMoneyValueToExport(item.appliedEnd));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, ...rest });
};

export default convertJson2Pdf;
