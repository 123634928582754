import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { RowContent, RowHeader } from './TableRow';

const Handle = SortableHandle(() => (
  <button type="button" className="sortable-handle-icon no-border">
    <i className="fa fa-bars" />
  </button>
));

const SortableItem = SortableElement(
  ({ row, onRemove, sortingItemKey, isSupportIndexStyle, styleDragItem, ...rest }) => {
    const { t } = useTranslation('common');
    return (
      <tr
        style={isSupportIndexStyle ? { zIndex: 999999999 } : null}
        className={`job-config-row ${sortingItemKey === row ? 'dragging' : ''}`}
      >
        <td style={styleDragItem ? { ...styleDragItem } : null}>
          <Handle />
        </td>
        <RowContent
          key={`row-${rest.idx}`}
          columns={rest.columns}
          item={row}
          onClickRow={rest.onClickRow}
          className={rest.rowClass}
          getRowClass={rest.getRowClass}
          indexParent={rest.indexParent}
          indexItem={rest.indexItem}
          {...rest}
        />
      </tr>
    );
  }
);

const SortableList = SortableContainer(({ items, isSorting, sortingItemKey, onRemove, ...rest }) => {
  return (
    <tbody>
      {items &&
        items.map((row, index) => {
          if (Object.keys(row).length < 2) return '';
          return (
            <SortableItem
              key={`item-${index}`}
              isSorting={isSorting}
              sortingItemKey={sortingItemKey}
              index={index}
              indexItem={index}
              onRemove={onRemove}
              row={row}
              {...rest}
            />
          );
        })}
    </tbody>
  );
});

class Table extends React.PureComponent {
  state = { dataTest: [{ header: 'aa' }, { header: 'ccc' }] };

  tableRef = React.createRef();

  componentDidMount() {
    this.injectJquery();
  }

  injectJquery = () => {
    const { fixedHeader } = this.props;
    if (!fixedHeader) return null;
    window.jQuery(this.tableRef.current).on('scroll', () => {
      window
        .jQuery(this.tableRef.current)
        .find('thead th')
        .css('transform', `translateY(${window.jQuery(this.tableRef.current).scrollTop()}px)`);
    });
  };

  onClickRow = (e, item) => {
    const { onClickRow } = this.props;
    if (onClickRow) {
      onClickRow(e, item);
    }
  };

  handleUpdateBeforeSortStart = ({ index }) => {
    return new Promise(resolve =>
      this.setState(
        ({ dataTest }) => ({
          sortingItemKey: dataTest[index],
          isSorting: true,
        }),
        resolve
      )
    );
  };

  render() {
    const {
      fixedHeader,
      columns,
      data,
      rowClass,
      noDataMessage,
      getRowClass,
      sorted,
      onSort,
      tableClass,
      nullIsLoading,
      isLoading,
      indexParent, // Using when map & render table
      onCheckAll,
      t,
      onClickRow,
      isSupportRemoveIndex,
      onSortEnd,
      ...rest
    } = this.props;
    const { dataTest } = this.state;
    return (
      <div
        className={`table-responsive mt-15 table-form ${tableClass} ${fixedHeader ? 'tableFixHead' : 'sticky-header'}`}
        ref={this.tableRef}
      >
        <table className="table center-aligned-table mb-0">
          <thead>
            <RowHeader
              columns={[{}, ...columns]}
              sorted={sorted}
              onSort={onSort}
              isLoading={isLoading}
              onCheckAll={onCheckAll}
              t={t}
            />
          </thead>
          <SortableList
            items={data}
            useDragHandle
            onSortStart={this.onSortStart}
            onSortEnd={onSortEnd}
            onRemove={this.removeJobType}
            isSorting={this.state.isSorting}
            sortingItemKey={this.state.sortingItemKey}
            updateBeforeSortStart={this.handleUpdateBeforeSortStart}
            columns={columns}
            onClickRow={this.onClickRow}
            className={rowClass}
            getRowClass={getRowClass}
            indexParent={indexParent}
            {...rest}
          />
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  data: PropTypes.array.isRequired,
  rowClass: PropTypes.string,
  tableClass: PropTypes.string,
  noDataMessage: PropTypes.string,
  nullIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getRowClass: PropTypes.func,
  onCheckAll: PropTypes.func,
  onSort: PropTypes.func,
  fixedHeader: PropTypes.bool,
  indexParent: PropTypes.number,
};

Table.defaultProps = {
  columns: [],
  sorted: {},
  data: [],
  rowClass: '',
  tableClass: '',
  getRowClass: null,
  isLoading: false,
  nullIsLoading: false,
  onSort: null,
  noDataMessage: '',
  fixedHeader: false,
  indexParent: 0,
  onCheckAll: () => {},
};

export default withTranslation('common')(Table);
