import gql from 'graphql-tag';
import { getUserId } from '../../localStorages';

export default () => gql`
  query {
    getUserPreferencesByUserId(input: { userId: "${getUserId()}" }) {
      id
      dashboards {
        index
        dashboardType
      }
    }
  }
`;
