export default {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  invoiceUnitId: {
    asc: 'invoiceUnitId_ASC',
    desc: 'invoiceUnitId_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  paymentDate: {
    asc: 'paymentDate_ASC',
    desc: 'paymentDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  fileName: {
    asc: 'fileName_ASC',
    desc: 'fileName_DESC',
  },
  fileDateTime: {
    asc: 'fileDateTime_ASC',
    desc: 'fileDateTime_DESC',
  },
  amount: {
    asc: 'amount_ASC',
    desc: 'amount_DESC',
  },
  paymentId: {
    asc: 'paymentId_ASC',
    desc: 'paymentId_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
  fileDate: {
    asc: 'fileDate_ASC',
    desc: 'fileDate_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};
