// import React from 'react';
import gql from 'graphql-tag';
import { parseToUserManagementMutationRequest } from 'utils/utils';
import CreateRoleNS from './constants/CreateRoleNS';

export default data => {
  const dataCreate = parseToUserManagementMutationRequest(
    data,
    CreateRoleNS.object,
    CreateRoleNS.array,
  );
  const createRole = `createRole(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createRole} {
      id
      name
      description
      type
      status
    }
  }
`;
};
