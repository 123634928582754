import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { CheckField, GenericInput, Switch, DataTable } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import ModalParentItem from './ModalParentItem';
import ModalGLAccount from './ModalGLAccount';
import ModalMiletstone from '../../../components/common/ModalMiletstone';
import { countryList } from '../../../constantsApp/countryList';
import { getIndexData } from '../../../utils/utils';
import ModalSelectItem from '../../../components/common/ModalSelectItem';

class PricingItemForm extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      wasValidated: false,
      formValid: true,
      isSelectingParent: false,
      parentItemId: null,
      productFamilyConfig: [],
      productLineConfig: [],
      productSubTypeConfig: [],
      productTypeConfig: [],
      taxCodeList: [],
      uomList: [],
      isSelectingMilestone: false,
      isRevenueSplit: false,
      itemSelect: {},
      isOpenModalItem: false,
      country: null,
      revenueType: 'SUBSCRIPTION',
      revenueRecognitionType: 'IMMEDIATE',
      // revenueRecognitionType: null,
      type: 'REVENUE',
      status: 'SELLABLE',
      isBundled: false,
      isDiscountable: false,
      isProvisionable: true,
      isNeedFetchProductFamily: true,
      isNeedFetchProductLine: true,
      isNeedFetchProductSubType: true,
      isNeedFetchProductType: true,
      taxCodeOptions: [],
    };
    this.timeoutSearch = 0;
  }

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    return {
      ...props.item,
      item: props.item,
    };
  }

  componentDidMount() {
    const { getTaxCodeConfigSelect, getUomConfig, getCcpPropertiesConfig, isCreate } = this.props;
    if (!isCreate) this.doGetListProductConfig();
    const { glAccount } = this.state;

    getTaxCodeConfigSelect(({ success, data }) => {
      if (success && data && data.productCodes) {
        const taxCodeList = data.productCodes.map(val => ({
          label: val.productCode,
          value: val.productCode,
        }));
        this.setState({ taxCodeList });
      }
    });
    if (!glAccount && isCreate)
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          const defaultGLAccountProperty = data.ccpPropertyList.find(val => val.property === 'defaultGLAccount');
          this.setState({
            glAccount:
              defaultGLAccountProperty && defaultGLAccountProperty.value ? defaultGLAccountProperty.value : null,
          });
        }
      });
    getUomConfig({ page: 1, size: 1000 }, ({ success, data }) => {
      if (success && data && data.searchList) {
        const uomList = data.searchList.map(val => ({
          label: val.unitId,
          value: val.unitId,
        }));
        this.setState({ uomList });
      }
    });
    this.doGetCcpTimeConfig();
    if (!isCreate) this.doGetPACProductCodesConfig();
  }

  doGetPACProductCodesConfig = () => {
    const { getPACProductCodesConfig } = this.props;
    const { taxCodeOptions } = this.state;
    if (!taxCodeOptions || !taxCodeOptions.length)
      getPACProductCodesConfig({ page: 1, size: 500 }, ({ success, data }) => {
        if (success && data && data.length) {
          const taxCodeList = data.map(val => ({
            label: `${val.id} (${val.description})`,
            value: val.id,
          }));
          this.setState({ taxCodeOptions: taxCodeList });
        }
      });
  };

  doGetListProductConfig = () => {
    this.doGetUniqueProductFamily();
    this.dogetUniqueProductLine();
    this.doGetUniqueProductSubType();
    this.doGetUniqueProductType();
  };

  doGetCcpTimeConfig = () => {
    const { getCcpDateTimeConfig } = this.props;
    if (getCcpDateTimeConfig) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          this.setState({ startDate: data ? data.ccpTime : null, defaultCcpTime: data ? data.ccpTime : null });
        }
      });
    }
  };

  doGetUniqueProductFamily = () => {
    const { getUniqueProductFamily } = this.props;
    const { company, isNeedFetchProductFamily } = this.state;
    if (isNeedFetchProductFamily)
      getUniqueProductFamily(company || null, ({ data, success }) => {
        if (success)
          this.setState({
            productFamilyConfig:
              data && data.length ? data.map(val => ({ label: val.productFamily, value: val.productFamily })) : [],
            isNeedFetchProductFamily: false,
          });
      });
  };

  dogetUniqueProductLine = () => {
    const { getUniqueProductLine } = this.props;
    const { company, productFamily, isNeedFetchProductLine } = this.state;
    if (isNeedFetchProductLine)
      getUniqueProductLine({ company: company || null, productFamily: productFamily || null }, ({ data, success }) => {
        if (success)
          this.setState({
            productLineConfig:
              data && data.length ? data.map(val => ({ label: val.productLine, value: val.productLine })) : [],
            isNeedFetchProductLine: false,
          });
      });
  };

  doGetUniqueProductSubType = () => {
    const { getUniqueProductSubType } = this.props;
    const { company, productFamily, productLine, productType, isNeedFetchProductSubType } = this.state;
    if (isNeedFetchProductSubType)
      getUniqueProductSubType(
        {
          company: company || null,
          productFamily: productFamily || null,
          productLine: productLine || null,
          productType: productType || null,
        },
        ({ data, success }) => {
          if (success)
            this.setState({
              productSubTypeConfig:
                data && data.length ? data.map(val => ({ label: val.productSubType, value: val.productSubType })) : [],
              isNeedFetchProductSubType: false,
            });
        }
      );
  };

  doGetUniqueProductType = () => {
    const { getUniqueProductType } = this.props;
    const { company, productFamily, productLine, isNeedFetchProductType } = this.state;
    if (isNeedFetchProductType)
      getUniqueProductType(
        {
          company: company || null,
          productFamily: productFamily || null,
          productLine: productLine || null,
        },
        ({ data, success }) => {
          if (success)
            this.setState({
              productTypeConfig:
                data && data.length ? data.map(val => ({ label: val.productType, value: val.productType })) : [],
              isNeedFetchProductType: false,
            });
        }
      );
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onOpenParentSelector = () => {
    this.setState({ isSelectingParent: true });
  };

  onOpenGlAccountSelector = () => {
    this.setState({ isSelectingGLAccount: true });
  };

  onOpenMilestone = () => {
    this.setState({ isSelectingMilestone: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingParent: false });
  };

  onCancelGlAccount = () => {
    this.setState({ isSelectingGLAccount: false });
  };

  onSelectParentId = parentItemId => {
    this.setState({ parentItemId, isSelectingParent: false });
  };

  onSelectGLAccount = (id, glAccount) => {
    this.setState({ glAccount: glAccount.accountNumber, isSelectingGLAccount: false });
  };

  onSelectMilestone = (id, milestone) => {
    this.setState({ milestoneId: milestone.id, isSelectingMilestone: false });
  };

  onCancelMilestone = () => {
    this.setState({ isSelectingMilestone: false });
  };

  handleInputChange = ({ name, value }) => {
    let { description } = this.state;
    const { isCreate } = this.props;
    if (name === 'revenueRecognitionType') {
      return this.setState({ [name]: value, milestoneId: null });
    }

    if (name === 'type' && value !== 'REVENUE' && isCreate) {
      return this.setState({
        [name]: value,
        productCode: null,
        revenueType: null,
        revenueRecognitionType: null,
        taxCode: null,
        uom: null,
        isDiscountable: null,
        isProvisionable: null,
      });
    }

    if (name === 'name' && isCreate && this.state.name === description) {
      description = value;
      return this.setState({ [name]: value, description });
    }

    if (name === 'company' && isCreate && this.state.company !== value) {
      return this.setState({
        [name]: value,
        productFamily: null,
        productLine: null,
        productType: null,
        productSubType: null,
        isNeedFetchProductFamily: true,
        isNeedFetchProductLine: true,
        isNeedFetchProductSubType: true,
        isNeedFetchProductType: true,
      });
    }
    if (name === 'productFamily' && isCreate && this.state.productFamily !== value) {
      return this.setState({
        [name]: value,
        productLine: null,
        productType: null,
        productSubType: null,
        isNeedFetchProductLine: true,
        isNeedFetchProductSubType: true,
        isNeedFetchProductType: true,
      });
    }
    if (name === 'productLine' && isCreate && this.state.productLine !== value) {
      return this.setState({
        [name]: value,
        productType: null,
        productSubType: null,
        isNeedFetchProductSubType: true,
        isNeedFetchProductType: true,
      });
    }
    if (name === 'productType' && isCreate && this.state.productType !== value) {
      return this.setState({
        [name]: value,
        productSubType: null,
        isNeedFetchProductSubType: true,
      });
    }
    return this.setState({ [name]: value });
  };

  onToggleRevenueSplit = () => {
    const { isRevenueSplit } = this.state;
    this.setState({ isRevenueSplit: !isRevenueSplit });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { onSubmit } = this.props;
    const {
      id,
      name,
      description,
      company,
      revenueType,
      productFamily,
      productLine,
      productType,
      revenueRecognitionType,
      productSubType,
      parentItemId,
      isBundled,
      externalId,
      externalName,
      glAccount,
      taxCode,
      isDiscountable,
      startDate,
      endDate,
      status,
      country,
      milestoneId,
      itemSplit,
      type,
      uom,
      isProvisionable,
      productCode,
      glOffset,
      accountingCode,
      offsetAccountingCode,
    } = this.state;
    let newCountry = cloneDeep(country);

    const countryDom = document.querySelector("input[name='country']");
    if (countryDom) {
      newCountry = countryDom.value && countryDom.value.length > 3 ? null : newCountry;
    }
    const data = {
      id: id || null,
      name: name || null,
      description: description || null,
      company: company || null,
      revenueType,
      productFamily: productFamily || null,
      revenueRecognitionType: revenueRecognitionType || null,
      productLine: productLine || null,
      productType,
      productSubType: productSubType || null,
      parentItemId: parentItemId || '',
      isBundled: isBundled || 'false',
      externalId: externalId || null,
      externalName: externalName || null,
      glAccount: glAccount || null,
      taxCode: taxCode || null,
      isDiscountable: isDiscountable || 'false',
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      status,
      country: newCountry || null,
      milestoneId: milestoneId || null,
      itemSplit: itemSplit && itemSplit.length ? itemSplit : null,
      type: type || null,
      uom: uom || null,
      productCode: productCode || null,
      isProvisionable: isProvisionable || 'false',
      glOffset: glOffset || null,
      accountingCode: accountingCode || null,
      offsetAccountingCode: offsetAccountingCode || null,
    };
    if (onSubmit) {
      onSubmit(data, success => {
        this.setState({ wasValidated: !success });
      });
    }
  };

  onClickCheckbox = e => {
    e.stopPropagation();
    const {
      target: { name },
    } = e;
    e.target.checked = !this.state[name];
    if (name === 'isBundled' && e.target.checked === false) {
      return this.setState({
        [name]: !this.state[name],
        itemSplit: [],
        isRevenueSplit: false,
      });
    }
    return this.setState({
      [name]: !this.state[name],
    });
  };

  addNewItemSplit = () => {
    let { itemSplit } = this.state;
    if (!itemSplit) itemSplit = [];
    let lastIndex = 0;
    itemSplit.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    itemSplit.push({
      index: lastIndex + 1,
      itemId: '',
      percent: '0',
    });
    this.setState({ itemSplit });
  };

  onChangeTable = ({ name, value, index }) => {
    try {
      const { itemSplit } = this.state;
      const newData = cloneDeep(itemSplit);
      newData[getIndexData({ index, data: newData })][name] = value;
      this.setState({ itemSplit: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectItem = ({ item }) => {
    this.setState({ itemSelect: item, isOpenModalItem: true });
  };

  onCancelItemId = () => {
    this.setState({ itemSelect: {}, isOpenModalItem: false });
  };

  onSelectItemId = id => {
    try {
      const { itemSplit, itemSelect } = this.state;
      const newData = cloneDeep(itemSplit);
      newData[getIndexData({ index: itemSelect.index, data: newData })].itemId = id;
      this.setState({ itemSplit: newData, itemSelect: {}, isOpenModalItem: false });
    } catch (error) {
      console.log(error);
    }
  };

  onCancelData = () => {
    const { defaultCcpTime } = this.state;
    this.setState({
      name: null,
      description: null,
      company: null,
      productFamily: null,
      productLine: null,
      productType: null,
      productSubType: null,
      parentItemId: null,
      isBundled: false,
      externalId: null,
      externalName: null,
      glAccount: null,
      taxCode: null,
      isDiscountable: false,
      startDate: defaultCcpTime || null,
      endDate: null,
      country: null,
      milestoneId: null,
      itemSplit: null,
      uom: null,
      revenueType: 'SUBSCRIPTION',
      revenueRecognitionType: 'IMMEDIATE',
      type: 'REVENUE',
      status: 'SELLABLE',
      isProvisionable: true,
    });
  };

  render() {
    const {
      modeCreateItem,
      modeModifyItem,
      modeApproveItem,
      modeSellableItem,
      modeNonSellableItem,
      modeSunsetItem,
      t,
      isCreate,
      onCancel,
    } = this.props;
    const {
      wasValidated,
      item,
      isBundled,
      isDiscountable,
      isSelectingParent,
      parentItemId,
      isSelectingGLAccount,
      glAccount,
      milestoneId,
      productFamilyConfig,
      productLineConfig,
      productSubTypeConfig,
      productTypeConfig,
      isSelectingMilestone,
      revenueRecognitionType,
      isRevenueSplit,
      itemSplit,
      itemSelect,
      isOpenModalItem,
      taxCodeList,
      uomList,
      isProvisionable,
      taxCodeOptions,
      type,
    } = this.state;

    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;
    if (dataCopy2Create.name) delete dataCopy2Create.name;
    if (dataCopy2Create.description) delete dataCopy2Create.description;

    let newStatusOptionsWithPermission = cloneDeep(t('selections:statusPricing')());

    if (modeApproveItem === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'APPROVED');
    if (modeSellableItem === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SELLABLE');
    if (modeNonSellableItem === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'NON_SELLABLE');
    if (modeSunsetItem === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SUNSET');

    if (modeApproveItem === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'APPROVED')
      ].isDisabled = true;
    if (modeSellableItem === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SELLABLE')
      ].isDisabled = true;
    if (modeNonSellableItem === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'NON_SELLABLE')
      ].isDisabled = true;
    if (modeSunsetItem === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SUNSET')
      ].isDisabled = true;

    const listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
      },
      {
        label: 'common:label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'common:label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        type: 'textarea',
        required: true,
      },
      {
        label: 'common:label.company',
        wrapperClass: 'col-md-4',
        name: 'company',
      },
      {
        label: 'common:label.productFamily',
        wrapperClass: 'col-md-4',
        name: 'productFamily',
        type: 'select',
        options: productFamilyConfig,
        onFocus: isCreate ? this.doGetUniqueProductFamily : () => {},
      },
      {
        label: 'common:label.productLine',
        wrapperClass: 'col-md-4',
        name: 'productLine',
        type: 'select',
        options: productLineConfig,
        onFocus: isCreate ? this.dogetUniqueProductLine : () => {},
      },
      {
        label: 'common:label.productType',
        wrapperClass: 'col-md-4',
        name: 'productType',
        type: 'select',
        options: productTypeConfig,
        onFocus: isCreate ? this.doGetUniqueProductType : () => {},
      },
      {
        label: 'common:label.productSubType',
        wrapperClass: 'col-md-4',
        name: 'productSubType',
        type: 'select',
        options: productSubTypeConfig,
        onFocus: isCreate ? this.doGetUniqueProductSubType : () => {},
      },
      {
        name: 'type',
        label: 'common:label.itemType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:itemType',
        type: 'select',
        isClearable: true,
      },
      {
        name: 'revenueType',
        label: 'common:label.revenueType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:revenueType',
        isClearable: true,
        type: 'select',
        disabled: type !== 'REVENUE',
      },
      {
        label: 'common:label.revenueRecognitionType',
        wrapperClass: 'col-md-4',
        name: 'revenueRecognitionType',
        type: 'select',
        tOptions: 'selections:revenueRecognitionType',
        // disabled: true,
        disabled: type !== 'REVENUE',
      },
      {
        label: 'common:label.milestoneId',
        wrapperClass: 'col-md-4',
        name: 'milestoneId',
        fa: 'fa-external-link',
        onClick: this.onOpenMilestone,
        disabled: revenueRecognitionType !== 'MILESTONE',
      },
      {
        label: 'common:label.taxCode',
        wrapperClass: 'col-md-4',
        name: 'taxCode',
        // required: true,
        type: 'select',
        options: taxCodeList,
        disabled: type !== 'REVENUE',
      },
      {
        label: 'common:label.productCode',
        wrapperClass: 'col-md-4',
        name: 'productCode',
        type: 'select',
        options: taxCodeOptions,
        onFocus: isCreate ? this.doGetPACProductCodesConfig : () => {},
        disabled: type !== 'REVENUE',
      },
      {
        label: 'common:label.uom',
        wrapperClass: 'col-md-4',
        name: 'uom',
        type: 'select',
        options: uomList,
        disabled: type !== 'REVENUE',
      },
      {
        label: 'common:label.glAccount',
        wrapperClass: 'col-md-4',
        name: 'glAccount',
        onClick: this.onOpenGlAccountSelector,
        fa: 'fa-external-link',
        required: true,
      },
      {
        label: 'common:label.glOffsetAccount',
        wrapperClass: 'col-md-4',
        name: 'glOffset',
      },
      {
        label: 'common:label.accountingCode',
        wrapperClass: 'col-md-4',
        name: 'accountingCode',
      },
      {
        label: 'common:label.offsetAccountingCode',
        wrapperClass: 'col-md-4',
        name: 'offsetAccountingCode',
      },
      {
        label: 'common:label.externalId',
        wrapperClass: 'col-md-4',
        name: 'externalId',
      },
      {
        label: 'common:label.externalName',
        wrapperClass: 'col-md-4',
        name: 'externalName',
      },
      {
        name: 'parentItemId',
        label: 'common:label.parentItemId',
        onClick: this.onOpenParentSelector,
        wrapperClass: 'col-md-4',
        fa: 'fa-external-link',
      },
      {
        name: 'status',
        label: 'common:label.status',
        wrapperClass: 'col-md-4',
        options: newStatusOptionsWithPermission,
        isClearable: true,
        type: 'select',
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        isClearable: true,
        type: 'date',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        isClearable: true,
        type: 'date',
      },
      {
        name: 'country',
        label: 'common:label.country',
        wrapperClass: 'col-md-4 country-embrix',
        options: countryList,
        isClearable: true,
        type: 'select',
      },
    ];

    const tableColumns = [
      {
        name: 'itemId',
        label: 'label.itemId',
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={item.itemId}
              name="itemId"
              // onChange={({ name, value }) => this.onChangeTable({ index: item.index, name, value })}
              onChange={() => {}}
              wrapperClass="col-md-12"
              required
              fa="fa-external-link"
              onClick={() => this.onSelectItem({ item })}
            />
          );
        },
      },
      {
        name: 'percent',
        label: 'label.percentage',
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={item.percent}
              name="percent"
              onChange={({ name, value }) => this.onChangeTable({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="number"
              required
            />
          );
        },
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          noValidate
          ref={this.formRef}
        >
          <div className="form-row">
            {listInputs.map(input => (
              <GenericInput
                {...input}
                key={input.name}
                value={this.state[input.name]}
                onChange={this.handleInputChange}
                // onBlur={input.name === 'company' ? () => this.doGetListProductConfig() : () => {}}
              />
            ))}

            <div className="form-group col-md-4 mt-2 p-0 check-group check-group-item">
              <CheckField
                label={t('label.isDiscountable')}
                name="isDiscountable"
                checked={!!isDiscountable}
                value
                onClick={this.onClickCheckbox}
                id="isDiscountable"
                disabled={type !== 'REVENUE'}
              />
              <CheckField
                name="isBundled"
                label={t('label.isBundled')}
                checked={isBundled}
                onClick={this.onClickCheckbox}
                value
                id="isBundled"
              />
              <CheckField
                name="isProvisionable"
                label={t('label.isProvisionable')}
                checked={isProvisionable}
                onClick={this.onClickCheckbox}
                value
                id="isProvisionable"
                disabled={type !== 'REVENUE'}
              />
              {isBundled && (
                <div className="ml-3 revenue-siwtch">
                  <Switch
                    onChange={this.onToggleRevenueSplit}
                    subTitle={t('label.addRevenueSplitConfiguration')}
                    checked={isRevenueSplit || (itemSplit && itemSplit.length > 0)}
                    name="isRevenueSplit"
                  />
                </div>
              )}
            </div>
            {isBundled && (isRevenueSplit || (itemSplit && itemSplit.length > 0)) && (
              <div className="mt-2 col-md-12">
                <DataTable columns={tableColumns} data={itemSplit || []} />
              </div>
            )}
            <div className="form-group col-md-12 action-buttons">
              <label htmlFor="validationCustom01" />
              <br />
              {(isRevenueSplit || (itemSplit && itemSplit.length > 0)) && (
                <button type="button" className="button button-border black x-small" onClick={this.addNewItemSplit}>
                  +
                  {t('label.addAdditionalSplitRow')}
                </button>
              )}
              {item && item.id && modeModifyItem === 2 && (
                <Link
                  to={{ pathname: RouteNames.pricingItemAdd.path, state: dataCopy2Create }}
                  className="button button-border gray x-small float-right"
                >
                  {t('label.copyToCreate')}
                </Link>
              )}
              {item && item.id && modeModifyItem === 2 && (
                <button
                  type="submit"
                  disabled={modeModifyItem === 1}
                  className="button button-border x-small float-right"
                >
                  {t('label.modify')}
                </button>
              )}
              {!(item && item.id) && modeCreateItem === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {t('label.create')}
                </button>
              )}

              {onCancel ? (
                <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
                  {t('label.cancel')}
                </button>
              ) : (
                <button
                  onClick={this.onCancelData}
                  type="button"
                  className="button button-border black x-small  float-right"
                >
                  {t('label.cancel')}
                </button>
              )}
            </div>
          </div>
        </form>
        <ModalParentItem
          isOpen={isSelectingParent}
          selectedParentId={parentItemId}
          onSelect={this.onSelectParentId}
          onCancel={this.onCancelSelector}
        />
        <ModalGLAccount
          isOpen={isSelectingGLAccount}
          selectedParentId={glAccount}
          onSelect={this.onSelectGLAccount}
          onCancel={this.onCancelGlAccount}
        />
        <ModalMiletstone
          isOpen={isSelectingMilestone}
          selectedParentId={milestoneId}
          onSelect={this.onSelectMilestone}
          onCancel={this.onCancelMilestone}
        />
        <ModalSelectItem
          isOpen={isOpenModalItem}
          selectedParentId={itemSelect.itemId}
          onSelect={this.onSelectItemId}
          onCancel={this.onCancelItemId}
        />
      </div>
    );
  }
}

PricingItemForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  modeCreateItem: PropTypes.number,
  modeModifyItem: PropTypes.number,
  modeApproveItem: PropTypes.number,
  modeSellableItem: PropTypes.number,
  modeNonSellableItem: PropTypes.number,
  modeSunsetItem: PropTypes.number,
};

PricingItemForm.defaultProps = {
  onSubmit: () => {},
  item: {},
  isSubmitting: false,
  modeCreateItem: 0,
  modeModifyItem: 0,
  modeApproveItem: 2,
  modeSellableItem: 2,
  modeNonSellableItem: 2,
  modeSunsetItem: 2,
};

export default withTranslation(['common'])(PricingItemForm);
