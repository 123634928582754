import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  const regenerateInvoice = `regenerateInvoice(input: ${dataCreate})`;
  return gql`
  mutation {
    ${regenerateInvoice} {
      id
    }
  }
`;
};
