/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectAccountId } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { SearchAccountForm } from '../../PaymentHub';

class ModalAccountId extends PureComponent {
  searchHanldeInvoiceId = (payload, cb) => {
    const { selectAccountId } = this.props;
    const newPayload = { ...payload };
    selectAccountId(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.ACCTNo',
        },
        {
          name: 'name',
          label: 'common:label.name',
        },
        {
          name: 'firstName',
          label: 'common:label.firstName',
          sortable: true,
          render: (name, item) => {
            return item && item.contacts && item.contacts[0] ? item.contacts[0].firstName : null;
          },
        },
        {
          name: 'lastName',
          label: 'common:label.lastName',
          sortable: true,
          render: (name, item) => {
            return item && item.contacts && item.contacts[0] ? item.contacts[0].lastName : null;
          },
        },
        {
          name: 'organization',
          label: 'common:label.organization',
          sortable: true,
          style: { minWidth: '110px' },
          render: (name, item) => {
            return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
          },
        },
        {
          name: 'email',
          label: 'common:label.email',
          render: (name, item) => {
            return item && item.contacts && item.contacts[0] ? item.contacts[0].email : null;
          },
        },
        {
          name: 'city',
          label: 'common:label.city',
          sortable: true,
          render: (name, item) => {
            return item && item.addresses && item.addresses[0] ? item.addresses[0].city : null;
          },
        },
        {
          name: 'state',
          label: 'common:label.state',
          sortable: true,
          style: { minWidth: '90px' },
          render: (name, item) => {
            return item && item.addresses && item.addresses[0] ? item.addresses[0].state : null;
          },
        },
        {
          name: 'status',
          label: 'common:label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      defaultSearchParams,
      columns,
      headerTitle,
      fieldDisableCheck,
      searchForm,
      defaultSearchForm,
    } = this.props;
    return (
      <ModalSelector
        modalTitle={headerTitle || t('label.chooseAccountId')}
        isOpen={isOpen}
        SearchForm={searchForm || SearchAccountForm}
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={columns || tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        fieldDisableCheck={fieldDisableCheck}
        defaultSearchForm={defaultSearchForm}
      />
    );
  }
}

ModalAccountId.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectAccountId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalAccountId.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectAccountId })(ModalAccountId));
