import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class SearchTasksListWithoutAccountIdUserId extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.clientId',
      name: 'clientId',
    },
    {
      label: 'common:label.ticketId',
      name: 'ticketId',
    },
    {
      label: 'common:label.userEmail',
      name: 'userEmail',
    },
    {
      label: 'common:label.status',
      name: 'status',
    },
    {
      label: 'common:label.startDateWithRank',
      name: 'startDateWithRank',
      type: 'date',
    },
    {
      label: 'common:label.endDateWithRank',
      name: 'endDateWithRank',
      type: 'date',
    },
    {
      label: 'common:label.firstName',
      name: 'userFirstname',
    },
    {
      label: 'common:label.lastName',
      name: 'userLastName',
    },
    {
      label: 'common:label.parentTaskId',
      name: 'parentTaskId',
    },
    {
      label: 'common:label.includeChildTask',
      name: 'includeChildTask',
      type: 'switch',
      wrapperClass: 'col-md-3 mt-4',
    },
  ];

  btnSpan = 'col-md-3';
}

SearchTasksListWithoutAccountIdUserId.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchTasksListWithoutAccountIdUserId.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(SearchTasksListWithoutAccountIdUserId);
