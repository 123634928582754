import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import DependencyForm from './DependencyForm';
import { createDependency } from '../actions';
import { Breadcrumb } from '../../../components/common';
import { supportRemoveIndexWithSize } from '../../../utils/utils';

class DependencyItemAdd extends Component {
  constructor(props) {
    const { location } = props;
    super(props);
    this.state = {
      isSubmitting: false,
      newDepedency: location && location.state ? location.state : {},
    };
  }

  onCreateDependency = data => {
    const { createDependency } = this.props;
    this.setState({ isSubmitting: true });
    const { dependencyLists, ...rest } = data;
    createDependency({ ...rest, dependencyLists: supportRemoveIndexWithSize({ data: dependencyLists }) }, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const { newDepedency, isSubmitting } = this.state;
    const { t, location } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.dependencyItem.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.dependency'), url: RouteNames.dependencyItem.path },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addDependency')}</h5>
              </div>
              <DependencyForm
                item={newDepedency}
                onSubmit={this.onCreateDependency}
                isSubmitting={isSubmitting}
                creatingMode
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DependencyItemAdd.propTypes = {
  createDependency: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};

DependencyItemAdd.defaultProps = {
  location: {},
};

const mapDispatchToProps = dispatch => ({
  createDependency: (e, cb) => dispatch(createDependency(e, cb)),
});

const withConnect = connect(null, mapDispatchToProps);

export default withTranslation('common')(compose(withConnect)(DependencyItemAdd));
