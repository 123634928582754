import * as types from './types';

export function getARReasonCodeConfigRevenue(payload, cb) {
  return {
    type: types.GET_AR_REASON_CODE_REVENUE,
    payload,
    cb,
  };
}

export function modifyARReasonCodeConfigRevenue(payload, cb) {
  return {
    type: types.MODIFY_AR_REASON_CODE_REVENUE,
    payload,
    cb,
  };
}

export function createARReasonCodeConfigRevenue(payload, cb) {
  return {
    type: types.CREATE_AR_REASON_CODE_REVENUE,
    payload,
    cb,
  };
}

export function searchRevenueMilestone(payload, cb) {
  return {
    type: types.SEARCH_REVENUE_MILESTONE,
    payload,
    cb,
  };
}

export function searchRevenueMilestoneSuccess(payload, cb) {
  return {
    type: types.SEARCH_REVENUE_MILESTONE_SUCCESS,
    payload,
    cb,
  };
}

export function getRevenueMilestoneById(payload, cb) {
  return {
    type: types.GET_REVENUE_MILESTONE_BY_ID,
    payload,
    cb,
  };
}

export function getRevenueMilestoneByIdSuccess(payload, cb) {
  return {
    type: types.GET_REVENUE_MILESTONE_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function modifyRevenueMilestone(payload, cb) {
  return {
    type: types.MODIFY_REVENUE_MILESTONE,
    payload,
    cb,
  };
}

export function createRevenueMilestone(payload, cb) {
  return {
    type: types.CREATE_REVENUE_MILESTONE,
    payload,
    cb,
  };
}

// Exchange Rate
export function searchExchangeRate(payload, cb) {
  return {
    type: types.SEARCH_EXCHANGE_RATE,
    payload,
    cb,
  };
}

export function searchExchangeRateSuccess(payload, cb) {
  return {
    type: types.SEARCH_EXCHANGE_RATE_SUCCESS,
    payload,
    cb,
  };
}

export function getExchangeRateById(payload, cb) {
  return {
    type: types.GET_EXCHANGE_RATE_BY_ID,
    payload,
    cb,
  };
}

export function getExchangeRateByIdSuccess(payload, cb) {
  return {
    type: types.GET_EXCHANGE_RATE_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function modifyExchangeRate(payload, cb) {
  return {
    type: types.MODIFY_EXCHANGE_RATE,
    payload,
    cb,
  };
}

export function createExchangeRate(payload, cb) {
  return {
    type: types.CREATE_EXCHANGE_RATE,
    payload,
    cb,
  };
}

export function exportExchangeRate(payload, cb) {
  return {
    type: types.EXPORT_EXCHANGE_RATE_BY_ID,
    payload,
    cb,
  };
}

export function exportExchangeRateSuccess(payload, cb) {
  return {
    type: types.EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function getLocations(payload, cb) {
  return {
    type: types.GET_LOCATION,
    payload,
    cb,
  };
}

export function modifyLocation(payload, cb) {
  return {
    type: types.MODIFY_LOCATION,
    payload,
    cb,
  };
}

export function createLocation(payload, cb) {
  return {
    type: types.CREATE_LOCATION,
    payload,
    cb,
  };
}

export function getEnterprise(payload, cb) {
  return {
    type: types.GET_ENTERPRISE,
    payload,
    cb,
  };
}

export function modifyEnterprise(payload, cb) {
  return {
    type: types.MODIFY_ENTERPRISE,
    payload,
    cb,
  };
}

export function createEnterprise(payload, cb) {
  return {
    type: types.CREATE_ENTERPRISE,
    payload,
    cb,
  };
}

export function getDivision(payload, cb) {
  return {
    type: types.GET_DIVISION,
    payload,
    cb,
  };
}

export function modifyDivision(payload, cb) {
  return {
    type: types.MODIFY_DIVISION,
    payload,
    cb,
  };
}

export function createDivision(payload, cb) {
  return {
    type: types.CREATE_DIVISION,
    payload,
    cb,
  };
}

export function getLegalEntity(payload, cb) {
  return {
    type: types.GET_LEGAL_ENTITY,
    payload,
    cb,
  };
}

export function modifyLegalEntity(payload, cb) {
  return {
    type: types.MODIFY_LEGAL_ENTITY,
    payload,
    cb,
  };
}

export function createLegalEntity(payload, cb) {
  return {
    type: types.CREATE_LEGAL_ENTITY,
    payload,
    cb,
  };
}

export function getBusinessUnits(payload, cb) {
  return {
    type: types.GET_BUSINESS_UNITS,
    payload,
    cb,
  };
}

export function modifyBusinessUnits(payload, cb) {
  return {
    type: types.MODIFY_BUSINESS_UNITS,
    payload,
    cb,
  };
}

export function createBusinessUnits(payload, cb) {
  return {
    type: types.CREATE_BUSINESS_UNITS,
    payload,
    cb,
  };
}

export function getGLSetup(payload, cb) {
  return {
    type: types.GET_GL_SETUP,
    payload,
    cb,
  };
}

export function modifyGLSetup(payload, cb) {
  return {
    type: types.MODIFY_GL_SETUP,
    payload,
    cb,
  };
}

export function createGLSetup(payload, cb) {
  return {
    type: types.CREATE_GL_SETUP,
    payload,
    cb,
  };
}

export function getDepartment(payload, cb) {
  return {
    type: types.GET_DEPARTMENT,
    payload,
    cb,
  };
}

export function modifyDepartment(payload, cb) {
  return {
    type: types.MODIFY_DEPARTMENT,
    payload,
    cb,
  };
}

export function createDepartment(payload, cb) {
  return {
    type: types.CREATE_DEPARTMENT,
    payload,
    cb,
  };
}

export function getCostCenter(payload, cb) {
  return {
    type: types.GET_COST_CENTER,
    payload,
    cb,
  };
}

export function modifyCostCenter(payload, cb) {
  return {
    type: types.MODIFY_COST_CENTER,
    payload,
    cb,
  };
}

export function createCostCenter(payload, cb) {
  return {
    type: types.CREATE_COST_CENTER,
    payload,
    cb,
  };
}

export function getCalendar(payload, cb) {
  return {
    type: types.GET_CALENDAR,
    payload,
    cb,
  };
}

export function modifyCalendar(payload, cb) {
  return {
    type: types.MODIFY_CALENDAR,
    payload,
    cb,
  };
}

export function createCalendar(payload, cb) {
  return {
    type: types.CREATE_CALENDAR,
    payload,
    cb,
  };
}

export function getCOA(payload, cb) {
  return {
    type: types.GET_COA,
    payload,
    cb,
  };
}

export function modifyCOA(payload, cb) {
  return {
    type: types.MODIFY_COA,
    payload,
    cb,
  };
}

export function createCOA(payload, cb) {
  return {
    type: types.CREATE_COA,
    payload,
    cb,
  };
}

export function getConfigAccountingRules(payload, cb) {
  return {
    type: types.GET_CONFIG_ACCOUNTING_RULES,
    payload,
    cb,
  };
}

export function modifyConfigAccountingRules(payload, cb) {
  return {
    type: types.MODIFY_CONFIG_ACCOUNTING_RULES,
    payload,
    cb,
  };
}

export function createConfigAccountingRules(payload, cb) {
  return {
    type: types.CREATE_CONFIG_ACCOUNTING_RULES,
    payload,
    cb,
  };
}

export function getChartOfAccount(payload, cb) {
  return {
    type: types.GET_CHART_OF_ACCOUNT_REVENUE,
    payload,
    cb,
  };
}

export function modifyChartOfAccount(payload, cb) {
  return {
    type: types.MODIFY_CHART_OF_ACCOUNT_REVENUE,
    payload,
    cb,
  };
}

export function createChartOfAccount(payload, cb) {
  return {
    type: types.CREATE_CHART_OF_ACCOUNT_REVENUE,
    payload,
    cb,
  };
}

export function getCostCenterMap(payload, cb) {
  return {
    type: types.GET_COST_CENTER_MAP,
    payload,
    cb,
  };
}

export function saveCostCenterMap(payload, cb) {
  return {
    type: types.SAVE_COST_CENTER_MAP,
    payload,
    cb,
  };
}

export function searchAllGLAccounts(payload, cb) {
  return {
    type: types.SEARCH_ALL_GL_ACCOUNTS,
    payload,
    cb,
  };
}

export function searchAllGLAccountsSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_GL_ACCOUNTS_SUCCESS,
    payload,
    cb,
  };
}

export function searchConfigAccountingRules(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_ACCOUNTING_RULES,
    payload,
    cb,
  };
}
