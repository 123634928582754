import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import {
  FormWithTableItem,
  DataTable,
  ModalWithItem,
  GenericInput,
  FormCollapse,
  ModalViewSingleFile,
} from '../../common';
import InvoicePaymentInstallmentSearchForm from '../InvoicePaymentInstallmentSearchForm';
import { getPageTotalCount, validate, formatNumberCaculation } from '../../../utils/utils';
import ModalSearchBills from '../../common/ModalSearchBills';
import pdfExport from '../../../utils/exportPdfInstallment';

const commonFields = [
  {
    name: 'noOfInvoices',
    label: 'label.noOfInvoices',
  },
  {
    name: 'totalAmount',
    label: 'label.totalAmount',
  },
  {
    name: 'paidAmount',
    label: 'label.totalPaid',
  },
  {
    name: 'totalDue',
    label: 'label.totalDue',
  },
  {
    name: 'noOfInstallments',
    label: 'label.noOfInstallments',
  },
  {
    name: 'noOfInstallmentsPaid',
    label: 'label.noOfInstallmentsPaid',
  },
  {
    name: 'installmentStatus',
    label: 'label.status',
    type: 'select',
    tOptions: 'selections:installmentStatus',
  },
];

const listFileds = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'createdDate',
    label: 'label.createdDate',
  },
  ...commonFields,
];

const collapsibleDetails = [
  {
    title: 'label.installmentDetails',
    state: 'installmentDetails',
  },
  {
    title: 'label.invoicesInInstallment',
    state: 'invoicesInInstallment',
  },
  {
    title: 'label.installmentPayments',
    state: 'installmentPayments',
  },
];

const listFieldsCreate = [
  {
    name: 'noOfInstallments',
    label: 'label.numberOfInstallments',
    type: 'number',
    required: true,
  },
  {
    name: 'daysOffset',
    label: 'label.daysOffset',
    type: 'number',
  },
  {
    name: 'monthOffset',
    label: 'label.monthsSOffset',
    type: 'number',
  },
];

const installmentDetailsColumns = [
  {
    name: 'index',
    label: 'label.installmentNo',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'paidAmount',
    label: 'label.amountPaid',
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
  },
  {
    name: 'paymentDate',
    label: 'label.paymentDate',
  },
  {
    name: 'installmentStatus',
    label: 'label.status',
    fieldOption: 'installmentStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:installmentStatus')().find(val => val.value === item.installmentStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const invoicesInInstallmentColumns = [
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'total',
    label: 'label.invoiceAmount',
  },
  {
    name: 'due',
    label: 'label.due',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    fieldOption: 'invoiceUnitStatus',
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const installmentPaymentsColumns = [
  {
    name: 'refIndex',
    label: 'label.installmentNo',
  },
  {
    name: 'paymentId',
    label: 'label.paymentId',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'reversedAmount',
    label: 'label.reversedAmount',
  },
];

const invoicesColumnsCreate = [
  {
    name: 'invoiceUnitId',
    label: 'label.invoiceId',
  },
  {
    name: 'total',
    label: 'label.total',
  },
  {
    name: 'due',
    label: 'label.due',
  },
  {
    name: 'invoiceStatus',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.invoiceStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const InvoicePaymentInstallment = ({
  id,
  getMultiInvPymtInstallmentsByAccountId,
  currencyOptions,
  setupMultiInvoicePaymentInstallment,
  customerInfo,
  ccpTime,
  getCcpDateTimeConfig,
  generatePaymentInstallmentReport,
}) => {
  const formRef = useRef();

  const [totalCount, setTotalCount] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [itemSelected, setitemSelected] = useState({});
  const [dataSubmit, setDataSubmit] = useState({});
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isOpenSelectInvoice, setIsOpenSelectInvoice] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [wasValidated, setValidate] = useState(false);
  const [isFixedInstallments, setIsFixedInstallments] = useState(true);
  const [pathFile, setPathFile] = useState(null);

  const { t } = useTranslation('common');

  let totalDue = 0;
  if (dataSubmit?.invoices) {
    dataSubmit.invoices.forEach(val => {
      if (val.due) totalDue += val.due;
    });
  }

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doGetMultiInvPymtInstallmentsByAccountId = useCallback(() => {
    setLoading(true);
    const newFilter = cloneDeep(filter);
    if (!newFilter.accountId) newFilter.accountId = id;
    getMultiInvPymtInstallmentsByAccountId(newFilter, ({ success, data }) => {
      setLoading(false);
      if (success && data) setData(data);
    });
  }, [filter, getMultiInvPymtInstallmentsByAccountId, id]);

  const onHandleSubmit = filter => {
    const newFilter = cloneDeep(filter);
    newFilter.accountId = id;
    setFilter(filter);
  };

  const onToggleModal = ({ item }) => {
    setitemSelected(item);
    if (!isOpenModalDetails) {
      setActiveTab({ name: 'installmentDetails', isActive: true });
    } else {
      setActiveTab({});
    }
    setIsOpenModalDetails(!isOpenModalDetails);
  };

  const onToggleModalInvoice = () => {
    setIsOpenSelectInvoice(!isOpenSelectInvoice);
  };

  const onSelectInvoice = (ids, rows) => {
    const newData = cloneDeep(dataSubmit);
    if (ids && ids.length) {
      if (!newData.invoices) newData.invoices = [];
      newData.invoices = [...newData.invoices, ...rows];
    }
    setDataSubmit(newData);
    setIsOpenSelectInvoice(false);
  };

  const onToggleModalCreate = () => {
    setDataSubmit({});
    setIsOpenModalCreate(!isOpenModalCreate);
  };

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    const { invoices, installmentData, ...rest } = dataSubmit;

    return setupMultiInvoicePaymentInstallment(
      {
        ...rest,
        accountId: id,
        invoices: invoices ? invoices.map(val => ({ id: val.invoiceUnitId })) : null,
        installmentData: installmentData
          ? installmentData.map((dt, index) => {
              const { amount, ...rest } = dt;
              return {
                ...rest,
                index: index + 1,
                amount: amount || (rest.percent ? formatNumberCaculation((rest.percent * totalDue) / 100) : null),
              };
            })
          : null,
      },
      ({ success }) => {
        if (success) {
          doGetMultiInvPymtInstallmentsByAccountId();
          setDataSubmit({});
          setIsOpenModalCreate(!isOpenModalCreate);
        }
      }
    );
  };

  const onChangeCreateData = ({ name, value }) => {
    const newData = cloneDeep(dataSubmit);
    newData[name] = value;
    setDataSubmit(newData);
  };

  const onRemoveItem = ({ index }) => {
    const newData = cloneDeep(dataSubmit);
    newData.invoices.splice(index, 1);
    setDataSubmit(newData);
  };

  const onRemoveInstallment = ({ index }) => {
    const newData = cloneDeep(dataSubmit);
    newData.installmentData.splice(index, 1);
    setDataSubmit(newData);
  };

  const onChangeSwitch = () => {
    const newData = cloneDeep(dataSubmit);
    if (isFixedInstallments) {
      newData.installmentData = null;
    } else {
      newData.noOfInstallments = null;
      newData.daysOffset = null;
      newData.monthOffset = null;
    }
    setDataSubmit(newData);
    setIsFixedInstallments(!isFixedInstallments);
  };

  const onAddNewInstallmentData = () => {
    const newData = cloneDeep(dataSubmit);
    if (!newData.installmentData) newData.installmentData = [];
    newData.installmentData = [
      ...newData.installmentData,
      {
        dueDate: null,
        daysOffset: null,
        monthOffset: null,
        amount: null,
        percent: null,
      },
    ];
    setDataSubmit(newData);
  };

  const onChangeTable = ({ name, value, index }) => {
    const newData = cloneDeep(dataSubmit);
    newData.installmentData[index][name] = value;
    setDataSubmit(newData);
  };

  const onViewFile = path => {
    setPathFile(path);
  };

  const onReport = item => {
    generatePaymentInstallmentReport({ id: item.id, accountId: id }, ({ success, data }) => {
      if (success) onViewFile(data.filePath);
    });
  };

  useEffect(() => {
    doGetMultiInvPymtInstallmentsByAccountId();
  }, [doGetMultiInvPymtInstallmentsByAccountId]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, items: data }));
  }, [data, totalCount]);

  useEffect(() => {
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
  }, [ccpTime, getCcpDateTimeConfig]);

  const columnsCreateInstallments = [
    {
      name: 'percent',
      label: 'label.percent',
      type: 'number',
      render: (colName, item, index) => (
        <GenericInput
          value={item.percent}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="percent"
          type="number"
          disabled={!!item.amount}
        />
      ),
    },
    {
      name: 'amount',
      label: 'label.amount',
      type: 'number',
      render: (colName, item, index) => (
        <GenericInput
          value={item.amount || (item.percent ? formatNumberCaculation((item.percent * totalDue) / 100) : null)}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="amount"
          type="number"
          disabled={!!item.percent}
        />
      ),
    },
    {
      name: 'dueDate',
      label: 'label.date',
      type: 'date',
      render: (colName, item, index) => (
        <GenericInput
          value={item.dueDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="dueDate"
          type="date"
          disabled={!!item.daysOffset || !!item.monthOffset}
        />
      ),
    },
    {
      name: 'daysOffset',
      label: 'label.daysOffset',
      type: 'number',
      render: (colName, item, index) => (
        <GenericInput
          value={item.daysOffset}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="daysOffset"
          type="number"
          disabled={!!item.dueDate || !!item.monthOffset}
        />
      ),
    },
    {
      name: 'monthOffset',
      label: 'label.monthsSOffset',
      type: 'number',
      render: (colName, item, index) => (
        <GenericInput
          value={item.monthOffset}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="monthOffset"
          type="number"
          disabled={!!item.dueDate || !!item.daysOffset}
        />
      ),
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button type="button" className="no-border" onClick={() => onRemoveInstallment({ index })}>
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
      style: { width: '100px' },
      render: (colName, item) => (
        <button className="text-success no-border" type="button" onClick={() => onToggleModal({ item })}>
          {item.id}
        </button>
      ),
    },
    {
      name: 'createdDate',
      label: 'label.createdDate',
    },
    {
      name: 'noOfInvoices',
      label: 'label.noOfInvoices',
    },
    {
      name: 'totalAmount',
      label: 'label.totalAmount',
    },
    {
      name: 'paidAmount',
      label: 'label.totalPaid',
    },
    {
      name: 'totalDue',
      label: 'label.totalDue',
    },
    {
      name: 'noOfInstallments',
      label: 'label.noOfInstallments',
    },
    {
      name: 'noOfInstallmentsPaid',
      label: 'label.noOfInstallmentsPaid',
    },
    {
      name: 'installmentStatus',
      label: 'label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:installmentStatus')().find(val => val.value === item.installmentStatus) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'agreement',
      label: 'label.agreement',
      render: (colName, item) => {
        return item.agreement ? (
          <button type="button" className="btn btn-outline-success btn-sm" onClick={() => onViewFile(item.agreement)}>
            {t('label.view')}
          </button>
        ) : null;
      },
    },
    {
      name: 'report',
      label: 'label.report',
      render: (colName, item) => {
        return item.agreement ? (
          <button type="button" className="btn btn-outline-success btn-sm" onClick={() => onReport(item)}>
            {t('label.view')}
          </button>
        ) : null;
      },
    },
  ];

  const newInvoiceCreateColumns = [
    ...invoicesColumnsCreate,
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button type="button" className="no-border" onClick={() => onRemoveItem({ index })}>
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const renderItem = item => {
    switch (item.state) {
      case 'installmentDetails':
        return (
          <div className="row col-md-12 p-2">
            <DataTable columns={installmentDetailsColumns} data={itemSelected ? itemSelected.data || [] : []} />
          </div>
        );
      case 'invoicesInInstallment':
        return (
          <div className="row col-md-12 p-2">
            <DataTable columns={invoicesInInstallmentColumns} data={itemSelected ? itemSelected.invoices || [] : []} />
          </div>
        );

      case 'installmentPayments':
        return (
          <div className="row col-md-12 p-2">
            <DataTable columns={installmentPaymentsColumns} data={itemSelected ? itemSelected.payments || [] : []} />
          </div>
        );

      default:
        return null;
    }
  };

  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('customerPage:sidebarHandleAccountPage.invoicePaymentInstallment')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        key="pendingBills"
      >
        <div className="card-body ml-2">
          <InvoicePaymentInstallmentSearchForm onSubmit={onHandleSubmit} />
        </div>
        <br />
        <div className="col-md-12 ml-3">
          <button type="button" className="button button-border black x-small" onClick={onToggleModalCreate}>
            +
            {t('label.addNewInstallmentPlan')}
          </button>
        </div>
        <br />
        <DataTable
          isLoading={isLoading}
          onSort={onSortColumn}
          sorted={sorted}
          columns={tableColumns}
          data={data || []}
        />
      </FormWithTableItem>
      <ModalWithItem
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        modalTitle={t('label.installmentDetails')}
        isOpen={isOpenModalDetails}
        onToggle={onToggleModal}
      >
        <div className="col-md-12">
          <button
            type="button"
            onClick={() => {
              pdfExport({
                title: t('label.installmentPlan'),
                item: { ...customerInfo, ...itemSelected },
                t,
                detailsFields: commonFields,
                installmentDetailsColumns,
                invoicesInInstallmentColumns,
                installmentPaymentsColumns,
                ccpTime,
                fileName: itemSelected?.id || null,
              });
            }}
            className="button x-small float-right"
          >
            {t('label.download')}
          </button>
        </div>
        <div className="col-md-12 row">
          {listFileds.map(item => (
            <GenericInput {...item} value={itemSelected ? itemSelected[item.name] : ''} readOnly disabled />
          ))}
        </div>
        <div className="mt-3">
          {collapsibleDetails.map(item => (
            <FormCollapse
              key={item.title}
              label="First Name"
              isActive={item.state === activeTab.name && activeTab.isActive}
              title={t(item.title) || ''}
              state={item.state}
              onToggleTab={onToggleTab}
            >
              {renderItem(item)}
            </FormCollapse>
          ))}
          <div className="col-md-12 mt-3">
            <button type="button" onClick={onToggleModal} className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </ModalWithItem>
      <ModalWithItem
        modalTitle={t('label.addMultipleInvoicePaymentInstallment')}
        isOpen={isOpenModalCreate}
        onToggle={onToggleModalCreate}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        <form ref={formRef} noValidate className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}>
          <GenericInput
            name="fixedInstallments"
            label="common:label.fixedInstallments"
            value={isFixedInstallments}
            onChange={onChangeSwitch}
            type="switch"
            wrapperClass="pl-0 col-md-3 mb-3"
          />
          {!isFixedInstallments && (
            <div className="col-md-12 row">
              <h6 className="mb-3 col-md-12">{t('label.addInstallmentData')}</h6>
              <DataTable
                columns={columnsCreateInstallments}
                data={dataSubmit ? dataSubmit.installmentData || [] : []}
              />
              <div className="col-md-12 mt-3 ml-2">
                <button type="button" className="button button-border black x-small" onClick={onAddNewInstallmentData}>
                  +
                  {t('label.addNewRow')}
                </button>
              </div>
            </div>
          )}
          {isFixedInstallments && (
            <div className="col-md-12 row">
              {listFieldsCreate.map(item => (
                <GenericInput
                  {...item}
                  value={
                    dataSubmit && (dataSubmit[item.name] || dataSubmit[item.name] === 0) ? dataSubmit[item.name] : ''
                  }
                  onChange={onChangeCreateData}
                  disabled={
                    (item.name === 'daysOffset' && !!dataSubmit.monthOffset) ||
                    (item.name === 'monthOffset' && !!dataSubmit.daysOffset)
                  }
                />
              ))}
            </div>
          )}
          <div>
            <div className="row col-md-12 border-top mt-4">
              <button
                type="button"
                onClick={onToggleModalInvoice}
                className="button button-border x-small mb-3 mt-3 ml-2"
              >
                {t('label.selectInvoice')}
              </button>
              <h6 className="col-md-12 ml-2">{`${t('label.totalDue')}: ${totalDue}`}</h6>
              <DataTable columns={newInvoiceCreateColumns} data={dataSubmit ? dataSubmit.invoices || [] : []} />
            </div>
            <div className="col-md-12 mt-3">
              <button type="button" onClick={onSubmit} className="button button-border x-small float-right">
                {t('label.create')}
              </button>
              <button
                type="button"
                onClick={onToggleModalCreate}
                className="button button-border black x-small float-right mr-2"
              >
                {t('label.cancel')}
              </button>
            </div>
          </div>
        </form>
      </ModalWithItem>
      <ModalSearchBills
        isOpen={isOpenSelectInvoice}
        onSelect={onSelectInvoice}
        onCancel={onToggleModalInvoice}
        defaultSearchParams={{
          accountId: id,
          billingProfileId: 'PRIMARY',
          filterInstallmentInvoices: true,
          fetchPendingDueInvoice: true,
        }}
        isMultipe
        selectFieldName="invoiceUnitId"
        fieldDisableCheck={
          dataSubmit?.invoices ? { invoiceUnitId: dataSubmit?.invoices.map(val => val.invoiceUnitId) } : null
        }
      />
      <ModalViewSingleFile pathFile={pathFile} isOpen={!!pathFile} onToggle={() => setPathFile(null)} title="" />
    </div>
  );
};

InvoicePaymentInstallment.propTypes = {
  id: PropTypes.string,
  getObjectFileById: PropTypes.func.isRequired,
  getMultiInvPymtInstallmentsByAccountId: PropTypes.func.isRequired,
};

InvoicePaymentInstallment.defaultProps = {
  id: '',
};

export default InvoicePaymentInstallment;
