import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, groupBy, map, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import { GenericInput, FormCollapse } from '../../common';
import { getCustomAttributesByObjectType } from '../../../containers/CustomerPage/actions';
import { isCheckValidateForm } from '../../../utils/utils';

class CustomerAttribute extends PureComponent {
  constructor() {
    super();
    this.state = {
      customAttributesByObjectType: [],
      isModify: false,
      wasValidated: false,
      activeTab: {},
      nIdOperatorOptions: [],
      carrierVoiceOptions: [],
      carrierSMSOptions: [],
      usedCfdiByTaxRegionOptions: [],
    };
  }

  componentDidMount = () => {
    const { getAccountDetail, getCustomAttributesByObjectType } = this.props;
    if (getAccountDetail) {
      getAccountDetail();
    }
    getCustomAttributesByObjectType(({ data }) => {
      if (data) {
        this.setState(
          {
            customAttributesByObjectType: (data && data.attributes) || [],
          },
          () => {
            const { account } = this.props;
            this.setValue(account.customAttributes || []);
          }
        );
      }
    });
  };

  doInitnIdOperatorOptions = () => {
    const { customAttributesByObjectType } = this.state;
    const { getConfigOperatorByServiceType, t } = this.props;
    if (customAttributesByObjectType && customAttributesByObjectType.length) {
      const N_ID_OPERADOR = customAttributesByObjectType.find(
        val => val.name.toLowerCase().replace(/ /g, '') === 'N_ID_OPERADOR'.toLowerCase().replace(/ /g, '')
      );
      const serviceType = customAttributesByObjectType.find(
        val => val.name.toLowerCase().replace(/ /g, '') === 'operatorServiceType'.toLowerCase().replace(/ /g, '')
      );
      const serviceTypeAllOptions = t('selections:serviceType')();
      let valueSelect = '';
      if (serviceType && serviceType?.value) {
        valueSelect = serviceTypeAllOptions.find(sr => sr.value === serviceType.value)
          ? serviceTypeAllOptions.find(sr => sr.value === serviceType.value).value
          : '';
      }
      if (N_ID_OPERADOR && valueSelect) {
        getConfigOperatorByServiceType(valueSelect, ({ success, data }) => {
          if (success && data?.configOperatorMapList?.length) {
            const nIdOperatorOptions = data?.configOperatorMapList
              .filter(val => !!val.operatorId)
              .map(val => ({
                label: `${val.operatorId} ${val.operatorName ? `: ${val.operatorName}` : ''}`,
                value: val.operatorId,
              }));
            this.setState({ nIdOperatorOptions: nIdOperatorOptions ? sortBy(nIdOperatorOptions, ['label']) : [] });
          }
        });
      }
    }
  };

  setValue = value => {
    const { customAttributesByObjectType } = this.state;
    const { isNewAccount } = this.props;
    const sef = this;
    let tempAttributes = [];
    tempAttributes = customAttributesByObjectType.map(item => {
      const temp = sef.getValueFromName(item.name, value, isNewAccount ? item.defaultValue : '');
      return { ...item, value: temp };
      // return item;
    });
    this.setState(
      {
        customAttributesByObjectType: tempAttributes,
      },
      () => {
        this.doInitnIdOperatorOptions();
      }
    );
  };

  getValueFromName = (name, value, defaultValue) => {
    let result = '';
    value.forEach(item => {
      if (item.name === name) {
        result = item.value;
      }
    });
    return result || defaultValue || null;
  };

  onHandleChange = (value, index) => {
    try {
      const { customAttributesByObjectType } = this.state;
      let { usedCfdiByTaxRegionOptions } = this.state;
      const { onHandleCreateAttribute } = this.props;
      const newData = cloneDeep(customAttributesByObjectType);
      const indexChange = newData.findIndex(val => val.index === index);
      let { isModify } = this.state;
      let newValue = value;
      if (value === 'TRUE') newValue = 'true';
      if (value === 'FALSE') newValue = 'false';
      if (
        newData[indexChange] &&
        (newData[indexChange].displayName.toLowerCase().replace(/ /g, '') ===
          'Metodo Pago'.toLowerCase().replace(/ /g, '') ||
          newData[indexChange].name.toLowerCase().replace(/ /g, '') === 'Metodo Pago'.toLowerCase().replace(/ /g, '') ||
          newData[indexChange].displayName.toLowerCase().replace(/ /g, '') ===
            'Método de Pago'.toLowerCase().replace(/ /g, '') ||
          newData[indexChange].name.toLowerCase().replace(/ /g, '') ===
            'Método de Pago'.toLowerCase().replace(/ /g, ''))
      ) {
        const formaPagoIndex = customAttributesByObjectType.findIndex(
          val =>
            (val.displayName &&
              val.displayName.toLowerCase().replace(/ /g, '') === 'Forma Pago'.toLowerCase().replace(/ /g, '')) ||
            (val.name && val.name.toLowerCase().replace(/ /g, '') === 'Forma Pago'.toLowerCase().replace(/ /g, '')) ||
            (val.displayName &&
              val.displayName.toLowerCase().replace(/ /g, '') === 'Forma De Pago'.toLowerCase().replace(/ /g, '')) ||
            (val.name && val.name.toLowerCase().replace(/ /g, '') === 'Forma De Pago'.toLowerCase().replace(/ /g, ''))
        );
        if (formaPagoIndex !== -1) {
          newData[formaPagoIndex].value = value === 'PPD' ? '99' : null;
          newData[formaPagoIndex].isModify = true;
        }
        if (formaPagoIndex !== -1 && value === 'PUE') {
          newData[formaPagoIndex].value = '3';
          newData[formaPagoIndex].isModify = true;
        }

        const tipoDePagoIndex = customAttributesByObjectType.findIndex(
          val =>
            (val.displayName &&
              val.displayName.toLowerCase().replace(/ /g, '') === 'Tipo De Pago'.toLowerCase().replace(/ /g, '')) ||
            (val.name && val.name.toLowerCase().replace(/ /g, '') === 'Tipo De Pago'.toLowerCase().replace(/ /g, ''))
        );
        if (tipoDePagoIndex !== -1) {
          newData[tipoDePagoIndex].value = value === 'PPD' ? 'NO IDENTIFICADO' : null;
          newData[tipoDePagoIndex].isModify = true;
        }
        if (tipoDePagoIndex !== -1 && value === 'PUE') {
          newData[tipoDePagoIndex].value = 'TRANSFERENCIA';
          newData[tipoDePagoIndex].isModify = true;
        }
      }
      newData[indexChange].value = newValue;
      newData[indexChange].isModify = true;
      if (
        newData[indexChange].name.toLowerCase().replace(/ /g, '') === 'regimenFiscal'.toLowerCase().replace(/ /g, '')
      ) {
        const S_CFDI_index = customAttributesByObjectType.findIndex(
          val => val.name.toLowerCase().replace(/ /g, '') === 'S_CFDI'.toLowerCase().replace(/ /g, '')
        );
        if (S_CFDI_index !== -1) {
          newData[S_CFDI_index].value = null;
          usedCfdiByTaxRegionOptions = [];
        }
      }
      if (value) isModify = true;
      if (!value) delete newData[indexChange].value;
      this.setState(
        {
          customAttributesByObjectType: newData,
          isModify,
          usedCfdiByTaxRegionOptions,
        },
        () => {
          if (newData[indexChange].name === 'operatorServiceType') {
            if (value) this.doInitnIdOperatorOptions();
            if (!value) this.setState({ nIdOperatorOptions: [] });
          }
        }
      );

      if (onHandleCreateAttribute) onHandleCreateAttribute(newData);
    } catch (error) {
      console.log(error);
    }
  };

  modifiAccount = () => {
    const { customAttributesByObjectType } = this.state;
    const { account, modifyAccount, getAccountDetail, t } = this.props;
    const temp = [];
    customAttributesByObjectType.map(item => {
      if (item.value && item.isModify) {
        temp.push({ name: item.name, value: item.value });
      }
    });
    const accountDataPost = {
      id: account && account.id,
      customAttributes: temp,
    };
    this.setState({ wasValidated: true });
    if (isCheckValidateForm()) return toast.error(t('message.mandatory'));
    modifyAccount(accountDataPost, () => {
      getAccountDetail();
    });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onClickCarrierSMS = () => {
    const { customAttributesByObjectType, carrierSMSOptions } = this.state;
    const { getConfigOperatorByServiceType } = this.props;
    if (customAttributesByObjectType && customAttributesByObjectType.length) {
      const carrierSMS = customAttributesByObjectType.find(
        val => val.name.toLowerCase().replace(/ /g, '') === 'carrierSMS'.toLowerCase().replace(/ /g, '')
      );

      if (carrierSMS && (!carrierSMSOptions || !carrierSMSOptions.length)) {
        getConfigOperatorByServiceType('SMS', ({ success, data }) => {
          if (success && data?.configOperatorMapList?.length) {
            const carrierSMSOptionsList = data?.configOperatorMapList
              .filter(val => !!val.operatorId)
              .map(val => ({
                label: `${val.operatorId} ${val.operatorName ? `: ${val.operatorName}` : ''}`,
                value: val.operatorId,
              }));
            this.setState({ carrierSMSOptions: carrierSMSOptionsList ? sortBy(carrierSMSOptionsList, ['label']) : [] });
          }
        });
      }
    }
  };

  onClickCarrierVoice = () => {
    const { customAttributesByObjectType, carrierVoiceOptions } = this.state;
    const { getConfigOperatorByServiceType } = this.props;
    if (customAttributesByObjectType && customAttributesByObjectType.length) {
      const carrierVoice = customAttributesByObjectType.find(
        val => val.name.toLowerCase().replace(/ /g, '') === 'carrierVoice'.toLowerCase().replace(/ /g, '')
      );

      if (carrierVoice && (!carrierVoiceOptions || !carrierVoiceOptions.length)) {
        getConfigOperatorByServiceType('VOICE', ({ success, data }) => {
          if (success && data?.configOperatorMapList?.length) {
            const carrierVoiceOptionsList = data?.configOperatorMapList
              .filter(val => !!val.operatorId)
              .map(val => ({
                label: `${val.operatorId} ${val.operatorName ? `: ${val.operatorName}` : ''}`,
                value: val.operatorId,
              }));
            this.setState({
              carrierVoiceOptions: carrierVoiceOptionsList ? sortBy(carrierVoiceOptionsList, ['label']) : [],
            });
          }
        });
      }
    }
  };

  onFetchtUsedCfdiByTaxRegion = () => {
    const { customAttributesByObjectType, usedCfdiByTaxRegionOptions } = this.state;
    const { getUsedCfdiByTaxRegion } = this.props;
    if (customAttributesByObjectType && customAttributesByObjectType.length) {
      const S_CFDI = customAttributesByObjectType.find(
        val => val.name.toLowerCase().replace(/ /g, '') === 'S_CFDI'.toLowerCase().replace(/ /g, '')
      );
      const regimenFiscal = customAttributesByObjectType.find(
        val => val.name.toLowerCase().replace(/ /g, '') === 'regimenFiscal'.toLowerCase().replace(/ /g, '')
      );

      if (
        S_CFDI &&
        regimenFiscal &&
        regimenFiscal.value &&
        (!usedCfdiByTaxRegionOptions || !usedCfdiByTaxRegionOptions.length)
      ) {
        getUsedCfdiByTaxRegion(regimenFiscal.value || '', ({ success, data }) => {
          if (success && data?.length) {
            const usedCfdiByTaxRegionOptionsList = data
              .filter(val => !!val.usedCfdi)
              .map(val => ({
                label: `${val.usedCfdi} ${val.usedCfdiName ? `: ${val.usedCfdiName}` : ''}`,
                value: val.usedCfdi,
              }));
            this.setState({
              usedCfdiByTaxRegionOptions: usedCfdiByTaxRegionOptionsList
                ? sortBy(usedCfdiByTaxRegionOptionsList, ['label'])
                : [],
            });
          }
        });
      }
    }
  };

  render() {
    const {
      customAttributesByObjectType,
      isModify,
      wasValidated,
      activeTab,
      nIdOperatorOptions,
      carrierVoiceOptions,
      carrierSMSOptions,
      usedCfdiByTaxRegionOptions,
    } = this.state;
    const {
      accountNum,
      isNewAccount,
      t,
      modeModifyCustomAttributes,
      modeCreateCustomAttributes,
      modeGetCustomAttributes,
      currencyOptions,
      customerInfo,
    } = this.props;
    if (modeGetCustomAttributes === 0) return '';
    let isPPD = false;
    let isPUE = false;
    
    if (customAttributesByObjectType && customAttributesByObjectType.length) {
      const metodoPagoItem = customAttributesByObjectType.find(
        val =>
          (val.displayName &&
            val.displayName.toLowerCase().replace(/ /g, '') === 'Metodo Pago'.toLowerCase().replace(/ /g, '')) ||
          (val.name && val.name.toLowerCase().replace(/ /g, '') === 'Metodo Pago'.toLowerCase().replace(/ /g, '')) ||
          (val.name && val.name.toLowerCase().replace(/ /g, '') === 'Método de Pago'.toLowerCase().replace(/ /g, '')) ||
          (val.displayName &&
            val.displayName.toLowerCase().replace(/ /g, '') === 'Método de Pago'.toLowerCase().replace(/ /g, ''))
      );
      if (metodoPagoItem && metodoPagoItem.value === 'PPD') {
        isPPD = true;
      }
      if (metodoPagoItem && metodoPagoItem.value === 'PUE') {
        isPUE = true;
      }
    }
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          {!isNewAccount && <TitleFrom title={t('label.customAttributes')} />}
          {!isNewAccount && (
            <AccountHeaderFrom
              accountNum={accountNum}
              commercialName={customerInfo?.commercialName || ''}
              isCommercialName={customerInfo?.customerSegment !== 'B2C'}
              currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
            />
          )}
          <div className="card-body">
            <form className={`group-flex ${wasValidated ? 'was-validated' : ''} `} noValidate id="form">
              <div className="col-md-12">
                {customAttributesByObjectType &&
                  customAttributesByObjectType.length > 0 &&
                  map(
                    groupBy(
                      sortBy(customAttributesByObjectType, val => val.category),
                      item => {
                        return item.category;
                      }
                    ),
                    (listAttribute, key) => {
                      return (
                        <FormCollapse
                          key={key}
                          title={key}
                          isActive={key === activeTab.name && activeTab.isActive}
                          onToggleTab={this.onToggleTab}
                          state={key}
                          isSubCollapse
                          isActiveSubForm={key === activeTab.name && activeTab.isActive}
                        >
                          <div className="col-md-12 row pb-2 pt-2 pl-0 pr-0">
                            {listAttribute &&
                              listAttribute.length > 0 &&
                              map(listAttribute, (item, index) => {
                                if (item.type === 'BOOLEAN') {
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      required={item.mandatory === 'Y'}
                                      value={item.value === 'true' || item.value === true ? 'TRUE' : 'FALSE'}
                                      label={`${item.displayName || ' '}`}
                                      subLabel="Boolean(true/false)"
                                      name={item.name}
                                      onChange={({ value }) => this.onHandleChange(value, item.index)}
                                      wrapperClass="form-group col-md-4"
                                      type="select"
                                      tOptions="selections:selectBool"
                                      tooltipDetails={item.description}
                                    />
                                  );
                                }
                                if (item.name === 'carrierVoice') {
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      required={item.mandatory === 'Y'}
                                      value={item.value}
                                      label={`${item.displayName || ' '}`}
                                      subLabel="ENNUM"
                                      name={item.name}
                                      onChange={({ value }) => this.onHandleChange(value, item.index)}
                                      wrapperClass="form-group col-md-4"
                                      type="select"
                                      options={carrierVoiceOptions || []}
                                      tooltipDetails={item.description}
                                      onFocus={this.onClickCarrierVoice}
                                    />
                                  );
                                }
                                if (item.name === 'carrierSMS') {
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      required={item.mandatory === 'Y'}
                                      value={item.value}
                                      label={`${item.displayName || ' '}`}
                                      subLabel="ENNUM"
                                      name={item.name}
                                      onChange={({ value }) => this.onHandleChange(value, item.index)}
                                      wrapperClass="form-group col-md-4"
                                      type="select"
                                      options={carrierSMSOptions || []}
                                      tooltipDetails={item.description}
                                      onFocus={this.onClickCarrierSMS}
                                    />
                                  );
                                }
                                if (item.name === 'S_CFDI') {
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      required={item.mandatory === 'Y'}
                                      value={item.value}
                                      label={`${item.displayName || ' '}`}
                                      subLabel="ENNUM"
                                      name={item.name}
                                      onChange={({ value }) => this.onHandleChange(value, item.index)}
                                      wrapperClass="form-group col-md-4"
                                      type="select"
                                      options={usedCfdiByTaxRegionOptions || []}
                                      tooltipDetails={item.description}
                                      onFocus={this.onFetchtUsedCfdiByTaxRegion}
                                    />
                                  );
                                }
                                if (item.name === 'N_ID_OPERADOR') {
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      required={item.mandatory === 'Y'}
                                      value={item.value}
                                      label={`${item.displayName || ' '}`}
                                      subLabel="ENNUM"
                                      name={item.name}
                                      onChange={({ value }) => this.onHandleChange(value, item.index)}
                                      wrapperClass="form-group col-md-4"
                                      type="select"
                                      options={nIdOperatorOptions || []}
                                      tooltipDetails={item.description}
                                    />
                                  );
                                }
                                let options = item?.attributesLOVs?.length
                                  ? item?.attributesLOVs?.map(val => ({
                                      label: `${val.attributeId} : ${val.attributeValue}`,
                                      value: val.attributeId,
                                    }))
                                  : [];
                                // if (
                                //   isPPD &&
                                //   (item.displayName.toLowerCase().replace(/ /g, '') === 'Tipo De Pago'.toLowerCase().replace(/ /g, '') ||
                                //     item.name === 'Tipo De Pago'.toLowerCase().replace(/ /g, ''))
                                // ) {
                                //   options = options.filter(val => val.value === 'NO IDENTIFICADO');
                                // }
                                // if (
                                //   isPUE &&
                                //   (item.displayName.toLowerCase().replace(/ /g, '') === 'Tipo De Pago'.toLowerCase().replace(/ /g, '') ||
                                //     item.name === 'Tipo De Pago'.toLowerCase().replace(/ /g, ''))
                                // ) {
                                //   options = options.filter(val => val.value !== 'NO IDENTIFICADO');
                                // }
                                if (
                                  isPUE &&
                                  ((item.displayName &&
                                    item.displayName.toLowerCase().replace(/ /g, '') ===
                                      'Forma Pago'.toLowerCase().replace(/ /g, '')) ||
                                    (item.name &&
                                      item.name.toLowerCase().replace(/ /g, '') ===
                                        'Forma Pago'.toLowerCase().replace(/ /g, '')) ||
                                    (item.displayName &&
                                      item.displayName.toLowerCase().replace(/ /g, '') ===
                                        'Forma De Pago'.toLowerCase().replace(/ /g, '')) ||
                                    (item.name &&
                                      item.name.toLowerCase().replace(/ /g, '') ===
                                        'Forma De Pago'.toLowerCase().replace(/ /g, '')))
                                ) {
                                  options = options.filter(val => val.value !== '99');
                                }
                                if (
                                  isPPD &&
                                  ((item.displayName &&
                                    item.displayName.toLowerCase().replace(/ /g, '') ===
                                      'Forma Pago'.toLowerCase().replace(/ /g, '')) ||
                                    (item.name &&
                                      item.name.toLowerCase().replace(/ /g, '') ===
                                        'Forma Pago'.toLowerCase().replace(/ /g, '')) ||
                                    (item.displayName &&
                                      item.displayName.toLowerCase().replace(/ /g, '') ===
                                        'Forma De Pago'.toLowerCase().replace(/ /g, '')) ||
                                    (item.name &&
                                      item.name.toLowerCase().replace(/ /g, '') ===
                                        'Forma De Pago'.toLowerCase().replace(/ /g, '')))
                                ) {
                                  options = options.filter(val => val.value === '99');
                                }
                                return (
                                  <GenericInput
                                    key={item.index}
                                    value={item.value}
                                    label={item.displayName || ' '}
                                    required={item.mandatory === 'Y'}
                                    subLabel={`${
                                      item.type
                                        ? item.type.charAt(0).toLowerCase() + item.type.toLowerCase().slice(1)
                                        : ''
                                    }${item.type === 'VARCHAR' ? '(255)' : ''}`}
                                    type={item.type === 'ENUM' ? 'select' : item.type === 'VARCHAR' ? 'text' : 'number'}
                                    options={options}
                                    onChange={({ value }) => this.onHandleChange(value, item.index)}
                                    name={item.name}
                                    wrapperClass="form-group col-md-4"
                                    tooltipDetails={item.description}
                                  />
                                );
                              })}
                          </div>
                        </FormCollapse>
                      );
                    }
                  )}
              </div>

              {!isNewAccount && (
                <div className="form-group col-md-12  pt-4 pr-3 buttons-attibute">
                  {(modeModifyCustomAttributes === 2 || isNewAccount) && (
                    <button
                      disabled={!isModify}
                      type="button"
                      className="button button-border x-small float-right"
                      onClick={() => {
                        this.modifiAccount();
                      }}
                    >
                      {t('label.modify')}
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

CustomerAttribute.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  getAccountDetail: PropTypes.func,
  getCustomAttributesByObjectType: PropTypes.func.isRequired,
  onHandleCreateAttribute: PropTypes.func,
  modifyAccount: PropTypes.func,
  accountNum: PropTypes.string,
  isNewAccount: PropTypes.bool,
  getConfigOperatorByServiceType: PropTypes.func.isRequired,
  getUsedCfdiByTaxRegion: PropTypes.func.isRequired,
  t: PropTypes.objectOf(PropTypes.any),
};

CustomerAttribute.defaultProps = {
  accountNum: '',
  account: {},
  isNewAccount: false,
  getAccountDetail: () => {},
  modifyAccount: () => {},
  onHandleCreateAttribute: () => {},
  t: {},
};

const newCustomerAttribute = connect(mapStateToProps, {
  getCustomAttributesByObjectType,
})(CustomerAttribute);

export default withTranslation('common')(newCustomerAttribute);
