import gql from 'graphql-tag';

export default () => gql`
  query {
    getCurrencyConfig(input: { id: "" }) {
      id
      currencyListConfig {
        index
        currencyId
        symbol
        name
        roundingMethod
        roundingPrecision
        paymentThreshold
        paymentExchangeThreshold
      }
    }
  }
`;
