import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, TitleFrom, Switch, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getCostCenter, modifyCostCenter, createCostCenter } from '../actions';
import ModalCompanyEnterprise from '../../../components/common/ModalCompanyEnterprise';
import ModalDepartment from '../../../components/common/ModalDepartment';

class CostCenter extends React.PureComponent {
  state = {
    data: [],
    wasValidated: false,
    formValid: true,
    activeTab: {},
    showActiveOnly: true,
    isOpenModalName: '',
    enterpriseSelect: {},
    departmentSelect: {},
    dataSubmit: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetCostCenter();
    // this.checkExistData();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetCostCenter = () => {
    const { getCostCenter } = this.props;
    const { showActiveOnly } = this.state;
    getCostCenter({ showActiveOnly }, ({ data }) => {
      this.setState({
        data: data || [],
        dataSubmit: [],
        isModify: !!data,
      });
    });
    this.checkExistData();
  };

  checkExistData = () => {
    const { getCostCenter } = this.props;
    getCostCenter({ showActiveOnly: false }, ({ data }) => {
      this.setState({
        isModify: !!data,
        id: data ? data[0].id : null,
      });
    });
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (newData)
        newData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        name: '',
        description: '',
        code: '',
        enterprise: null,
        department: null,
        costType: null,
        status: null,
      };
      // newData.push(payload);
      newData = [payload, ...newData];
      dataSubmit[newData.length - 1] = payload;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, id, dataSubmit } = this.state;
    const { modifyCostCenter, createCostCenter } = this.props;
    const payload = cloneDeep(data).map(val => {
      const { address, id, ...rest } = val;
      return rest;
    });
    if (isModify) {
      return modifyCostCenter({ ccList: dataSubmit.filter(val => !!val), id }, ({ success }) => {
        if (success) this.doGetCostCenter();
      });
    }
    return createCostCenter({ ccList: payload }, ({ success }) => {
      if (success) this.doGetCostCenter();
    });
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      if (!dataSubmit[index]) dataSubmit[index] = { index: newData[index].index };
      newData[index][name] = value;
      dataSubmit[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSwitch = () => {
    const { showActiveOnly } = this.state;
    this.setState({ showActiveOnly: !showActiveOnly }, () => this.doGetCostCenter());
  };

  onOpenPopup = (name, index) => {
    this.setState({ isOpenModalName: name, indexSelect: index });
  };

  onSelectItem = (value, row) => {
    const { data, isOpenModalName, indexSelect, dataSubmit } = this.state;
    let { locationSelect, enterpriseSelect, departmentSelect } = this.state;
    const newData = cloneDeep(data);
    if (!dataSubmit[indexSelect]) dataSubmit[indexSelect] = { index: newData[indexSelect].index };
    if (isOpenModalName === 'enterprise') {
      if (value) {
        newData[indexSelect].enterprise = value;
        // newData[indexSelect].locationIndex = row.locationIndex;
        // newData[indexSelect].address = row.address || null;
        enterpriseSelect = row || null;
        dataSubmit[indexSelect].enterprise = value;
      }
      if (!value) {
        newData[indexSelect].enterprise = null;
        // newData[indexSelect].locationIndex = null;
        // newData[indexSelect].address = null;
        enterpriseSelect = {};
        dataSubmit[indexSelect].enterprise = null;
      }
    }

    if (isOpenModalName === 'department') {
      if (value) {
        newData[indexSelect].department = value;
        newData[indexSelect].enterprise = row.enterprise;
        departmentSelect = row || null;
        dataSubmit[indexSelect].department = value;
        dataSubmit[indexSelect].enterprise = row.enterprise;
      }
      if (!value) {
        newData[indexSelect].department = null;
        newData[indexSelect].enterprise = null;
        departmentSelect = {};
        dataSubmit[indexSelect].department = null;
        dataSubmit[indexSelect].enterprise = null;
      }
    }

    this.setState({ data: newData, isOpenModalName: '' });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateCostCenterConfig, modeGetCostCenterConfig, modeModifyCostCenterConfig } = permissions;
    if (!modeGetCostCenterConfig) return '';
    const { data, wasValidated, showActiveOnly, isOpenModalName, enterpriseSelect, departmentSelect } = this.state;
    const listFieldsCostCenter = [
      {
        name: 'name',
        label: 'label.name',
        required: true,
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.name}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="name"
          />
        ),
      },
      {
        name: 'code',
        label: 'label.code',
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.code}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="code"
          />
        ),
      },
      {
        name: 'enterprise',
        label: 'label.enterprise',
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.enterprise}
            wrapperClass="col-md-12"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('enterprise', index)}
            onChange={() => {}}
            name="enterprise"
            required
          />
        ),
      },
      {
        name: 'department',
        label: 'label.department',
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.department}
            wrapperClass="col-md-12"
            fa="fa fa-external-link"
            onClick={() => this.onOpenPopup('department', index)}
            onChange={() => {}}
            name="department"
            required
          />
        ),
      },
      {
        name: 'costType',
        label: 'label.costType',
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.costType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="costType"
            type="select"
            tOptions="selections:costType"
            menuPortalTarget
            required
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.description}
            required
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="description"
            type="textarea"
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        // required: true,
        style: { minWidth: '120px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.status}
            // required
            wrapperClass="col-md-12"
            type="select"
            tOptions="selections:status"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="status"
            menuPortalTarget
          />
        ),
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.costCenter')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.costCenter')} subClass="border-bottom" />
            <br />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="col-md-12 pt-0 pb-5">
                <Switch
                  onChange={this.onChangeSwitch}
                  wrapperClass={showActiveOnly ? 'switch-active float-right' : 'switch-non-active float-right'}
                  rightTitle={t('label.ACTIVE')}
                  checked={showActiveOnly}
                  title={t('label.all')}
                />
                <span className="float-right title-fetch">{`${t('label.fetch')}:`}</span>
              </div>
              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateCostCenterConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.addNewItem}
                  >
                    +
                    {t('label.addNewCostCenter')}
                  </button>
                )}
                {modeModifyCostCenterConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeModifyCostCenterConfig === 1 || !data || !data.length}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  onClick={this.doGetCostCenter}
                  type="button"
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable columns={listFieldsCostCenter} data={data} isFixedHeaderTable />
            </form>
          </div>
        </div>
        <ModalCompanyEnterprise
          isOpen={isOpenModalName === 'enterprise'}
          onSelect={this.onSelectItem}
          selectedParentId={enterpriseSelect.enterprise}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalDepartment
          isOpen={isOpenModalName === 'department'}
          onSelect={this.onSelectItem}
          selectedParentId={departmentSelect.department}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
      </div>
    );
  }
}

CostCenter.propTypes = {
  getCostCenter: PropTypes.func,
  modifyCostCenter: PropTypes.func,
};

CostCenter.defaultProps = {
  getCostCenter: () => {},
  modifyCostCenter: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCostCenter,
    modifyCostCenter,
    createCostCenter,
  })(withRouter(CostCenter))
);
