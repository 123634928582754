import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapsible from '../common/Collapsible';

const FormCollapse = ({
  children,
  title,
  isActive,
  isActiveSubForm,
  onToggleTab,
  state,
  noCardBody,
  isSubCollapse,
  wrapperClass,
  isNoT,
  statusBar,
  className,
}) => {
  const { t } = useTranslation(['common', 'paymentPage']);
  if (noCardBody) {
    return (
      <div className={`form-collapse ${wrapperClass || ''}`}>
        <Col md={12} lg={12}>
          <Card>
            <Collapsible
              title={isNoT ? title : t(title)}
              isSubCollapse={isSubCollapse}
              isActiveSubForm={isActiveSubForm}
              className={`boxed ${className || ''}`}
              isActive={isActive}
              onToggleTab={onToggleTab}
              state={state}
              statusBar={statusBar}
            >
              {children}
            </Collapsible>
          </Card>
        </Col>
      </div>
    );
  }
  return (
    <div className={`form-collapse ${wrapperClass || ''}`}>
      <Col md={12} lg={12}>
        {/* <Card> */}
        <div className="embrix-card-collapsible">
          <Collapsible
            title={isNoT ? title : t(title)}
            isSubCollapse={isSubCollapse}
            className={`boxed ${className || ''}`}
            isActive={isActive}
            onToggleTab={onToggleTab}
            isActiveSubForm={isActiveSubForm}
            state={state}
            statusBar={statusBar}
          >
            {children}
          </Collapsible>
        </div>
        {/* </Card> */}
      </Col>
    </div>
  );
};

FormCollapse.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  isActiveSubForm: PropTypes.bool,
  noCardBody: PropTypes.bool,
  isSubCollapse: PropTypes.bool,
  onToggleTab: PropTypes.func,
  state: PropTypes.string,
  wrapperClass: PropTypes.string,
  children: PropTypes.any,
  statusBar: PropTypes.string,
};

FormCollapse.defaultProps = {
  title: '',
  isActiveSubForm: false,
  isActive: false,
  noCardBody: false,
  isSubCollapse: false,
  state: '',
  onToggleTab: () => {},
  wrapperClass: '',
  statusBar: '',
};

export default FormCollapse;
