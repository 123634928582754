import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, ['offerType', 'dependencyType']);
  return gql`
  query {
    getDependentOffers(input: ${dataSearch}) {
      packageId
      bundleId
      name
      dependencyType
      offerType
      dependentOffers {
        index
        packageId
        bundleId
        type
        name
        description
      }
    }
  }
`;
};
