import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, []);
  const undoInvoice = `undoInvoice(input: ${dataModify})`;
  return gql`
  mutation {
    ${undoInvoice} {
      id
    }
  }
`;
};
