import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty, cloneDeep, uniqBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getPageTotalCount, formatNumberValue } from '../../../utils/utils';
import AccountSort from '../../../constantsApp/customer/AccountSort';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import ProvisioningDataSearchForm from '../../../components/CustomerHub/ProvisioningDataSearchForm';
import ModalSearchItemServices from '../../../components/common/ModalSearchItemServices';
import ModalAccountId from '../../../components/common/ModalAccountId';
import ModalSelectPendingMerge from '../../../components/common/ModalSelectPendingMerge';
import ModalMultipleAccountIds from '../../../components/common/ModalMultipleAccountIds';
import {
  FormCollapse,
  TablePagination,
  GenericInput,
  Switch,
  CollapsibleTable,
  DataTable,
  ModalAccept,
  TitleFrom,
  ModalWithItem,
  CheckField,
} from '../../../components/common';
import MergeSearchForm from './MergeSearchForm';
import Footer from '../../../components/Footer';
import constant from '../constants';
import SidebarIndex from '../SidebarIndex';
import { makeGetListCustomers, makeErrorMessage } from '../selectors';
import {
  makeGetPermissionsCustomerManagement,
  makeGetPermissionsRating,
  makeGetPermissionsBilling,
} from '../../App/selectors';
import { searchServiceProvisioningData } from '../../App/actions';
import {
  getCustomers,
  setParams,
  getParentAccountDetail,
  getOrderSummaryByAccountId,
  updateBillingAccount,
  saveAccountMovementData,
} from '../actions';
import {
  checkPermissionCreateCustomer,
  checkPermissionSearchAccount,
  checkPermissionGetAccount,
  checkPermissionSetCreditLimit,
  checkPermissionGetDelayedBillingConfig,
} from '../CheckPermission';
import Split from '../Split';
import { getUserId } from '../../../localStorages';

const detectParentIds = customers => {
  const parentIds = customers
    .filter(c => {
      return c.parentId && (isEmpty(c.contacts) || isEmpty(c.addresses));
    })
    .map(c => c.parentId);
  return parentIds.filter((item, index) => parentIds.indexOf(item) === index);
};

const collapsibleMenuProvisioning = [
  {
    title: 'label.selectRange',
    state: 'Select Range',
  },
];

const collapsibleMenu = [
  {
    title: 'label.billableService',
    state: 'Billable Service',
  },
  {
    title: 'label.locations',
    state: 'Locations',
  },
];

const locationsTableColumns = [
  {
    name: 'pointId',
    label: 'common:label.locationId',
    style: { width: '170px', maxWidth: '170px' },
  },
  {
    name: 'pointName',
    label: 'common:label.locationName',
    style: { width: '170px', maxWidth: '170px' },
  },
  {
    name: 'percent',
    label: 'common:label.percent',
    style: { width: '200px', maxWidth: '200px' },
  },
  // hard viewer only
  {},
];

const accountColumns = [
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'organization',
    label: 'common:label.organization',
    style: { minWidth: '110px' },
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
    },
  },
  {
    name: 'id',
    label: 'label.accountId',
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'paying',
    label: 'label.paying',
    style: { minWidth: '90px' },
    render: (name, item) => {
      return (
        <span>
          {item &&
          item.paymentProfiles &&
          item.paymentProfiles[0] &&
          item.paymentProfiles[0].paymentMethod &&
          item.paymentProfiles[0].paymentMethod !== 'NON_PAYING'
            ? 'TRUE'
            : 'FALSE'}
        </span>
      );
    },
  },
  {
    name: 'parentId',
    label: 'label.parentId',
  },
];

const provisioningDataSort = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
};

class Merge extends PureComponent {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    isExpandChild: true,
    listCustomers: [],
    isMerge: false,
    isSearched: false,
    parentIdSelected: null,
    activeSubTab: {},
    activeTab: [],
    orderSummary: {},
    listItems: [],
    effectiveDate: null,
    moveAllAccountServices: true,
    moveProvisioningAttributes: 'FALSE',
    isOpenModalAccept: false,
    isMergeSelect: true,
    isWithinOrganization: false,
    totalQuantity: 0,
    totalAmount: 0,
    totalUnitPrice: 0,
    dataMerge: { updateServiceCapacityOnMove: 'FALSE' },
    isUndoPreviousMerge: false,
    isOpenModalDisperse: false,
    accountIdDisperse: null,
    isOpenModalProvisioning: false,
    itemProvisioningSelect: null,
    indexProvisioningSelect: null,
    dataResponseProvisioning: null,
    isFirstSearch: true,
    dataProvisioning: [],
    filterProvisioning: {},
    selectName: '',
    activeTabProvisioning: {},
    totalCountProvisioning: null,
    sizeProvisioning: 20,
    pageProvisioning: 0,
    sortProvisioning: '',
    sortedProvisioning: {},
    provisioningSelectRangeData: [],
    capacityMergeUpdate: null,
    indexBillableProvisioningSelect: null,
    selectOption: 'NEW_MERGE',
    notesAccountMovement: null,
    isOpenSelectPendingMerge: false,
    savedAccountMovementDataId: null,
    activeLocationSubTab: {},
    wasValidatedSave: false,
    wasValidated: false,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listCustomers: props.listCustomers,
      totalCount: getPageTotalCount({ ...state, items: props.listCustomers }),
    };
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('common:message.mandatory'));
    }
    return formValid;
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchAccount());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchAccount());
  };

  onHandleChange = ({ name, value }) => {
    const { filter = {} } = this.state;
    filter[name] = value;
    this.setState({ ...filter });
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchAccount());
  };

  doGetOrderSummaryByAccountId = id => {
    const { getOrderSummaryByAccountId } = this.props;

    getOrderSummaryByAccountId(id, ({ success, data }) => {
      if (success) {
        const { listItems } = this.state;
        const newData = cloneDeep(data);
        let listBillables = [];
        if (listItems && listItems.length) {
          listItems.forEach(val => {
            const { billables } = val;
            if (billables) listBillables = [...listBillables, ...billables];
          });
        }
        if (
          newData &&
          newData.accountServicesSummaries &&
          newData.accountServicesSummaries.length &&
          listBillables.length
        ) {
          newData.accountServicesSummaries = newData.accountServicesSummaries.map(ac => {
            const itemBillables = listBillables.find(val => val.omsInternalId === ac.omsInternalId);
            if (itemBillables) {
              return { ...ac, moveProvisioningAttributes: itemBillables.moveProvisioningAttributes };
            }
            return { ...ac };
          });
        }
        this.setState({ orderSummary: newData });
      }
    });
  };

  doSearchAccount = () => {
    const { filter, page, size, sorted } = this.state;
    const { getCustomers, getParentAccountDetail } = this.props;
    if (!filter.status) delete filter.status;
    if (!filter.type) filter.type = 'DIRECT_CUSTOMER';
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? AccountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({
      isSearching: true,
      listCustomers: [],
      isFirstFetch: true,
      activeSubTab: {},
      activeTab: [],
      orderSummary: {},
    });
    getCustomers(payload, ({ data }) => {
      if (isEmpty(detectParentIds(data))) {
        this.setState({ isSearching: false, isSearched: true });
        const { listCustomers } = this.state;
        this.setState({ isSearching: false, listItems: listCustomers });
      }
      detectParentIds(data).map(parentId =>
        getParentAccountDetail(parentId, () => {
          const { listCustomers } = this.state;
          this.setState({ isSearching: false, listItems: listCustomers });
        })
      );
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchAccount());
  };

  onChangeSwitchMerge = () => {
    const { isMergeSelect } = this.state;
    this.setState({ isMergeSelect: !isMergeSelect });
  };

  onChangeSwitchWithinOrganization = () => {
    const { isWithinOrganization } = this.state;
    this.setState({
      isWithinOrganization: !isWithinOrganization,
      activeSubTab: {},
      activeTab: [],
      orderSummary: {},
      listItems: [],
      effectiveDate: null,
      moveAllAccountServices: true,
      moveProvisioningAttributes: 'FALSE',
      isOpenModalAccept: false,
      isMergeSelect: true,
      totalQuantity: 0,
      totalAmount: 0,
      totalUnitPrice: 0,
      dataMerge: { updateServiceCapacityOnMove: 'FALSE' },
    });
  };

  onChangeDataMerge = ({ name, value }) => {
    const { dataMerge } = this.state;
    dataMerge[name] = value;
    this.setState({ dataMerge: { ...dataMerge } });
  };

  onChangeSelectOptions = ({ value }) => {
    this.setState({ selectOption: value, isUndoPreviousMerge: value === 'UNDO_PREVIOUS_DISPERSE' });
  };

  onHandleSelectParent = ({ name, value, item }) => {
    const { listItems } = this.state;
    const newData = cloneDeep(listItems).map(val => {
      if (val.id === item.id && !item.parentIdSelected) return { ...val, parentIdSelected: true };
      return { ...val, parentIdSelected: false };
    });
    this.setState({ listItems: cloneDeep(newData), moveProvisioningAttributes: 'FALSE' });
  };

  onHandleChangeChild = ({ name, value, item }) => {
    const { listItems } = this.state;
    const newData = cloneDeep(listItems);
    const indexChild = listItems.findIndex(val => val.id === item.id);
    if (indexChild !== -1) {
      newData[indexChild][name] = !newData[indexChild][name];
      if (!newData[indexChild][name]) newData[indexChild].moveAllServices = false;
    }

    this.setState({ listItems: cloneDeep(newData) });
  };

  onRemoveBilledService = ({ index }) => {
    try {
      const { listItems } = this.state;
      const newData = cloneDeep(listItems);
      newData.splice(index, 1);
      this.setState({ listItems: cloneDeep(newData) });
    } catch (error) {
      console.log(error);
    }
  };

  onChangePar = ({ name, value, item }) => {
    const { listItems } = this.state;
    const newData = cloneDeep(listItems);
    const indexChild = listItems.findIndex(val => val.id === item.id);
    if (indexChild !== -1) {
      newData[indexChild].isChild = !newData[indexChild].isChild;
    }
    this.setState({ listItems: cloneDeep(newData) });
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem) this.setState({ activeSubTab: {}, activeTab: [] });
    if (activeSubTab.index !== indexItem) {
      // setActiveSubTab({ key, index: indexItem });
      this.doGetOrderSummaryByAccountId(item.id);
      this.setState({
        activeSubTab: { key, index: indexItem },
        activeTab: [
          { name: 'Billable Service', isActive: true },
          { name: 'Locations', isActive: true },
        ],
      });
      // this.setState({ activeSegmentIndexTab: indexItem });
    }
  };

  onToggleLocationSubTab = (index, item, indexItem, key) => {
    const { activeLocationSubTab } = this.state;
    if (activeLocationSubTab.index === indexItem) this.setState({ activeLocationSubTab: {} });
    if (activeLocationSubTab.index !== indexItem) {
      this.setState({
        activeLocationSubTab: { key, index: indexItem },
      });
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab && activeTab.find(val => val.name === activeTabName)) {
      this.setState({
        activeTab: activeTab.filter(val => val.name !== activeTabName),
      });
    } else {
      this.setState({ activeTab: cloneDeep([...activeTab, { name: activeTabName, isActive: true }]) });
    }
  };

  onToggleTabProvisioning = activeTabName => {
    const { activeTabProvisioning } = this.state;
    if (activeTabProvisioning.name === activeTabName) {
      this.setState({
        activeTabProvisioning: { name: activeTabName, isActive: !activeTabProvisioning.isActive },
      });
    } else {
      this.setState({ activeTabProvisioning: { name: activeTabName, isActive: true } });
    }
  };

  onHandleSelectBilledService = ({ name, value, item }) => {
    const { listItems, activeSubTab } = this.state;
    let { totalQuantity, totalAmount, totalUnitPrice } = this.state;
    if (!item.serviceId) {
      return toast.error('Service id can not be null');
    }
    const newData = cloneDeep(listItems);
    try {
      if (!newData[activeSubTab.index].billables) newData[activeSubTab.index].billables = [];
      const indexItem = newData[activeSubTab.index].billables.findIndex(i => i.omsInternalId === item.omsInternalId);
      if (indexItem > -1) {
        if (
          newData[activeSubTab.index].billables[indexItem] &&
          newData[activeSubTab.index].billables[indexItem].updateServiceCapacity &&
          newData[activeSubTab.index].billables[indexItem].updateServiceCapacity === 'TRUE'
        ) {
          totalQuantity = Number.parseFloat(totalQuantity) - Number.parseFloat(item.quantity);
          totalAmount =
            Number.parseFloat(totalAmount) - Number.parseFloat(item.servicePrice) * Number.parseFloat(item.quantity);
          totalUnitPrice = Number.parseFloat(totalAmount) / Number.parseFloat(totalQuantity);
        }
        newData[activeSubTab.index].billables = newData[activeSubTab.index].billables.filter(
          bl => bl.omsInternalId !== item.omsInternalId
        );
      } else {
        newData[activeSubTab.index].billables.push({
          serviceId: item.serviceId,
          updateServiceCapacity: 'FALSE',
          omsInternalId: item.omsInternalId,
          serviceName: item.serviceName,
          servicePrice: item.servicePrice,
          quantity: item.quantity,
          totalPrice: item.totalPrice,
          uom: item.uom,
          capacity: item.capacity,
        });
      }
      this.setState({
        listItems: cloneDeep(newData),
        totalQuantity,
        totalAmount,
        totalUnitPrice,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeCapacityMerge = ({ name, value, item }) => {
    const { listItems, activeSubTab, orderSummary } = this.state;
    let { totalQuantity, totalAmount, totalUnitPrice, dataMerge } = this.state;
    const newData = cloneDeep(listItems);
    try {
      if (!newData[activeSubTab.index].billables) newData[activeSubTab.index].billables = [];
      const indexItem = newData[activeSubTab.index].billables.findIndex(i => i.omsInternalId === item.omsInternalId);
      if (value === 'TRUE') {
        totalQuantity = Number.parseFloat(totalQuantity) + Number.parseFloat(item.quantity);
        totalAmount =
          Number.parseFloat(totalAmount) + Number.parseFloat(item.servicePrice) * Number.parseFloat(item.quantity);
        totalUnitPrice = Number.parseFloat(totalAmount) / Number.parseFloat(totalQuantity);
        // const itemToggle = newData[activeSubTab.index].billables.find(val => val.updateServiceCapacity === 'TRUE');
        // if (itemToggle) {
        //   const itemSelected = orderSummary.accountServicesSummaries.find(
        //     val => val.omsInternalId === itemToggle.omsInternalId
        //   );
        //   if (itemSelected) {
        //     totalQuantity = Number.parseFloat(totalQuantity) - Number.parseFloat(itemSelected.quantity);
        //     totalAmount =
        //       Number.parseFloat(totalAmount) -
        //       Number.parseFloat(itemSelected.servicePrice) * Number.parseFloat(itemSelected.quantity);
        //     totalUnitPrice = Number.parseFloat(totalAmount) / Number.parseFloat(totalQuantity);
        //   }
        // }

        // newData[activeSubTab.index].billables = newData[activeSubTab.index].billables.map(val => {
        //   const { updateServiceCapacity, ...rest } = val;
        //   return { ...rest, updateServiceCapacity: 'FALSE' };
        // });
      } else {
        totalQuantity = Number.parseFloat(totalQuantity) - Number.parseFloat(item.quantity);
        totalAmount =
          Number.parseFloat(totalAmount) - Number.parseFloat(item.servicePrice) * Number.parseFloat(item.quantity);
        totalUnitPrice = Number.parseFloat(totalAmount) / Number.parseFloat(totalQuantity);
      }
      if (name === 'updateServiceCapacity' && value === 'TRUE') {
        dataMerge.newSelectCapacityMergeServiceForParentUom = item.uom;
      }
      if (
        name === 'updateServiceCapacity' &&
        value === 'FALSE' &&
        newData[activeSubTab.index].billables[indexItem][name] === 'TRUE'
      ) {
        dataMerge.newSelectCapacityMergeServiceForParentUom = null;
      }
      if (indexItem > -1) {
        newData[activeSubTab.index].billables[indexItem][name] = value;
      }
      this.setState({
        listItems: cloneDeep(newData),
        totalQuantity,
        totalAmount,
        totalUnitPrice,
        dataMerge: { ...dataMerge },
      });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeMoveProvisioning = ({ name, value, index, item }) => {
    const { listItems, activeSubTab, orderSummary } = this.state;
    const newData = cloneDeep(listItems);
    try {
      if (newData[activeSubTab.index]?.billables?.length) {
        const indexChange = newData[activeSubTab.index].billables.findIndex(
          val => val.omsInternalId === item.omsInternalId
        );
        if (indexChange !== -1) newData[activeSubTab.index].billables[indexChange][name] = value;
      }
      orderSummary.accountServicesSummaries[index][name] = value;
      this.setState({ listItems: newData, orderSummary: cloneDeep(orderSummary) });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSelectLocation = ({ name, value, item }) => {
    const { listItems, activeSubTab } = this.state;
    const newData = cloneDeep(listItems);
    try {
      if (!newData[activeSubTab.index].location) newData[activeSubTab.index].location = [];
      const indexItem = newData[activeSubTab.index].location.findIndex(i => i.omsInternalId === item.omsInternalId);
      if (indexItem > -1) {
        newData[activeSubTab.index].location = newData[activeSubTab.index].location.filter(
          bl => bl.omsInternalId !== item.omsInternalId
        );
      } else {
        newData[activeSubTab.index].location.push({ omsInternalId: item.omsInternalId });
      }
      this.setState({ listItems: cloneDeep(newData) });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSubmitMerge = ({ isSave = false }) => {
    const {
      totalQuantity,
      totalAmount,
      totalUnitPrice,
      capacityMergeUpdate,
      listItems,
      effectiveDate,
      dataMerge,
      notesAccountMovement,
      savedAccountMovementDataId,
    } = this.state;
    let moveAllAccountProvisioningAttributes = 'true';
    const { updateBillingAccount, saveAccountMovementData, t } = this.props;
    const fromAccount = [];
    const toAccount = [];
    let moveAllAccountServices = 'true';
    this.setState({ wasValidatedSave: isSave });
    listItems.forEach(val => {
      if (val.isChild) {
        const {
          id,
          moveAllServices,
          billables,
          moveProvisioningAttributes,
          clientId,
          contacts,
          // provisioningMovementTypes,
        } = val;
        if (!moveAllServices) {
          fromAccount.push({
            accountId: id,
            moveAllServices: 'true',
            moveProvisioningAttributes: moveProvisioningAttributes === 'TRUE' ? 'true' : 'false',
            clientId: clientId || null,
            organization: contacts && contacts[0].organization ? contacts[0].organization : null,
          });
        }
        if (moveAllServices) {
          fromAccount.push({
            accountId: id,
            moveAllServices: 'false',
            clientId: clientId || null,
            organization: contacts && contacts[0].organization ? contacts[0].organization : null,
            billables:
              billables && billables.length
                ? billables.map(val => {
                    const {
                      updateServiceCapacity,
                      moveProvisioningAttributes,
                      oldProvDataMoveTypeSelect,
                      oldFilterProvisioning,
                      oldDataProvisioning,
                      oldProvisioningSelectRangeData,
                      oldSelectName,
                      provisioningMovementTypes,
                      ...rest
                    } = val;
                    let newProvisioningMovementTypes = [];
                    if (val.provisioningMovementTypes && val.provisioningMovementTypes.length) {
                      const dataProvDataMoveTypeAll = val.provisioningMovementTypes.filter(
                        dtPr => dtPr.provDataMoveType === 'ALL'
                      );

                      if (dataProvDataMoveTypeAll) {
                        val.provisioningMovementTypes.forEach(type => {
                          const indexOtherAllType = dataProvDataMoveTypeAll.findIndex(
                            dtAll => type.provDataMoveType !== 'ALL' && type.type === dtAll.type
                          );
                          if (indexOtherAllType === -1)
                            newProvisioningMovementTypes = [...newProvisioningMovementTypes, { ...type }];
                        });
                      }
                    }
                    return {
                      ...rest,
                      updateServiceCapacity: updateServiceCapacity === 'TRUE' ? 'true' : 'false',
                      provisioningMovementTypes:
                        newProvisioningMovementTypes && newProvisioningMovementTypes.length
                          ? newProvisioningMovementTypes
                          : null,
                      moveProvisioningAttributes: moveProvisioningAttributes === 'TRUE' ? 'true' : 'false',
                    };
                  })
                : null,
            moveProvisioningAttributes: moveProvisioningAttributes === 'TRUE' ? 'true' : 'false',
          });
          moveAllAccountServices = 'false';
        }
        if (
          !moveProvisioningAttributes ||
          moveProvisioningAttributes === 'FALSE' ||
          moveProvisioningAttributes === 'false'
        ) {
          moveAllAccountProvisioningAttributes = 'false';
        }
      }
      if (val.parentIdSelected) {
        const { id, moveAllServices, billables, clientId, contacts } = val;
        if (!moveAllServices) {
          toAccount.push({
            accountId: id,
            moveAllServices: 'true',
            clientId: clientId || null,
            organization: contacts && contacts[0].organization ? contacts[0].organization : val?.organization || null,
            billables:
              dataMerge.updateServiceCapacityOnMove === 'TRUE'
                ? [
                    {
                      serviceId: dataMerge.selectCapacityMergeServiceForParent,
                      serviceName: dataMerge.selectCapacityMergeServiceForParentName,
                      uom: dataMerge?.newSelectCapacityMergeServiceForParentUom || null,
                      capacity: capacityMergeUpdate || null,
                      updateServiceCapacity: 'true',
                      quantity: totalQuantity || null,
                      servicePrice: totalUnitPrice || null,
                      totalPrice: totalAmount || null,
                    },
                  ]
                : null,
          });
        }
        if (moveAllServices)
          toAccount.push({
            accountId: id,
            moveAllServices: 'false',
            clientId: clientId || null,
            organization: contacts && contacts[0].organization ? contacts[0].organization : val?.organization || null,
            billables:
              billables && billables.length
                ? billables.map(val => {
                    const { updateServiceCapacity, ...rest } = val;
                    return {
                      ...rest,
                      updateServiceCapacity: updateServiceCapacity === 'TRUE' ? 'true' : 'false',
                    };
                  })
                : null,
          });
      }
    });
    const payload = {
      effectiveDate: effectiveDate || null,
      type: 'MERGE',
      moveAllAccountServices,
      moveAllAccountProvisioningAttributes,
      updateServiceCapacityOnMove: dataMerge.updateServiceCapacityOnMove === 'TRUE' ? 'true' : 'false',
      fromAccount,
      toAccount,
    };

    if (isSave) {
      if (!notesAccountMovement) return toast.error(t('common:message.messageNoteSavedPendingAccountMovement'));
      return saveAccountMovementData(
        {
          ...payload,
          savedAccountMovementDataId: savedAccountMovementDataId || null,
          notes: notesAccountMovement,
          userId: getUserId() || null,
        },
        ({ success }) => {
          if (success) {
            this.setState({
              listItems: [],
              notesAccountMovement: null,
              isOpenSelectPendingMerge: false,
              dataMerge: { updateServiceCapacityOnMove: 'FALSE' },
              totalQuantity: 0,
              totalAmount: 0,
              totalUnitPrice: 0,
              capacity: null,
              activeSubTab: {},
              activeTab: [],
              wasValidatedSave: false,
            });
          }
        }
      );
    }
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      this.setState({ isOpenModalAccept: false });
      return false;
    }
    return updateBillingAccount(
      { ...payload, notes: notesAccountMovement, savedAccountMovementDataId: savedAccountMovementDataId || null },
      ({ success, data }) => {
        this.setState({ isOpenModalAccept: false, wasValidated: !success });
      }
    );
  };

  onChangeSwitchWithinUndoPreviousMerge = () => {
    const { isUndoPreviousMerge } = this.state;
    this.setState({
      isUndoPreviousMerge: !isUndoPreviousMerge,
    });
  };

  renderItem = item => {
    const { orderSummary, activeSubTab, listItems, dataMerge, activeLocationSubTab } = this.state;
    const { t } = this.props;
    const billableServiceTable = [
      {
        name: 'select',
        label: 'common:label.select',
        isRenderT: true,
        render: (name, item) => {
          const itemSelected = listItems[activeSubTab.index] || {};
          const isChecked =
            itemSelected && itemSelected.billables && itemSelected.billables.length
              ? itemSelected.billables.find(val => val.omsInternalId === item.omsInternalId)
              : false;
          return (
            <GenericInput
              value={isChecked}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleSelectBilledService({ name, value, item })}
              name={item.accountId}
              type="checkbox"
              checked={isChecked}
              disabled={item.status !== 'ACTIVE'}
            />
          );
        },
      },
      {
        name: 'locations',
        label: 'label.viewLocations',
        render: (colName, item, idx) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => this.onToggleLocationSubTab(idx, item, idx, 'locations')}
            disabled={!item.locations || !item.locations.length}
          >
            <i
              className={`fa ${
                activeLocationSubTab && activeLocationSubTab.key === 'locations' && activeLocationSubTab.index === idx
                  ? 'fa-minus'
                  : 'fa-plus'
              }`}
            />
          </button>
        ),
      },
      {
        name: 'omsInternalId',
        label: 'common:label.instanceId',
      },
      {
        name: 'status',
        label: 'common:label.status',
      },
      {
        name: 'serviceId',
        label: 'common:label.serviceId',
      },
      {
        name: 'serviceName',
        label: 'common:label.serviceName',
      },
      {
        name: 'capacity',
        label: 'common:label.capacity',
        render: (colName, item) => {
          return <span>{formatNumberValue(item.capacity)}</span>;
        },
      },
      {
        name: 'uom',
        label: 'common:label.unitOfMeasure',
        // render: () => {
        //   return <span>{dataMerge?.selectCapacityMergeServiceForParentUom || ''}</span>;
        // },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
      },
      {
        name: 'servicePrice',
        label: 'common:label.price',
      },
      {
        name: 'deliveryDate',
        label: 'common:label.deliveryDate',
      },
      {
        name: 'select',
        label: 'common:label.capacityMerge',
        isRenderT: true,
        style: { minWidth: '200px' },
        render: (name, item) => {
          const itemSelected = listItems[activeSubTab.index] || {};
          const itemChecked =
            itemSelected && itemSelected.billables && itemSelected.billables.length
              ? itemSelected.billables.find(val => val.omsInternalId === item.omsInternalId)
              : false;
          return (
            <GenericInput
              value={itemChecked && itemChecked.updateServiceCapacity === 'TRUE' ? 'TRUE' : 'FALSE'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeCapacityMerge({ value, name, item })}
              name="updateServiceCapacity"
              type="select"
              // label="label.capacityMerge"
              tOptions="selections:selectBool"
              menuPortalTarget
              disabled={!itemChecked || dataMerge.updateServiceCapacityOnMove === 'FALSE'}
            />
          );
        },
      },
      {
        name: 'moveProvisioning',
        label: 'label.moveProvisioning',
        style: { minWidth: '110px' },
        render: (name, item, index) => {
          const itemSelected = listItems[activeSubTab.index] || {};
          const itemChecked =
            itemSelected && itemSelected.billables && itemSelected.billables.length
              ? itemSelected.billables.find(val => val.omsInternalId === item.omsInternalId)
              : false;
          // if (item.isChild && item.moveAllServices)
          return (
            <GenericInput
              value={item.moveProvisioningAttributes || 'FALSE'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeMoveProvisioning({ value, name, index, item })}
              name="moveProvisioningAttributes"
              type="select"
              tOptions="selections:selectBool"
              menuPortalTarget
              disabled={
                (itemSelected &&
                  itemSelected.paymentProfiles &&
                  itemSelected.paymentProfiles[0] &&
                  itemSelected.paymentProfiles[0].paymentMethod &&
                  itemSelected.paymentProfiles[0].paymentMethod === 'NON_PAYING') ||
                !itemChecked
              }
            />
          );

          // return '';
        },
      },
      {
        name: 'selectProvisioning',
        label: 'label.selectProvisioning',
        render: (name, item, index) => {
          const itemSelected = listItems[activeSubTab.index] || {};
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onTogglepProvisioning({ name, item: itemSelected, index, itemDetails: item })}
              disabled={!item.moveProvisioningAttributes || item.moveProvisioningAttributes === 'FALSE'}
            >
              {t('common:label.selectProvisioning')}
            </button>
          );
        },
      },
    ];

    const locationsTable = [
      {
        name: 'omsInternalId',
        label: 'common:label.instanceId',
      },
      {
        name: 'pointId',
        label: 'common:label.pointId',
      },
      {
        name: 'pointName',
        label: 'common:label.pointName',
      },
      {
        name: 'city',
        label: 'common:label.city',
      },
      {
        name: 'state',
        label: 'common:label.state',
      },
      {
        name: 'zipCode',
        label: 'common:label.zipCode',
      },

      {
        name: 'country',
        label: 'common:label.country',
      },
    ];
    switch (item.state) {
      case 'Billable Service':
        return (
          <div className="group-collapsible">
            <CollapsibleTable
              columns={billableServiceTable}
              indexViewer={
                activeLocationSubTab && activeLocationSubTab.key === 'locations' && activeLocationSubTab.index !== -1
                  ? activeLocationSubTab.index
                  : -1
              }
              data={orderSummary.accountServicesSummaries || []}
              isSupportRemoveIndex
              isViewOnly
              onClickRow={(idx, item, id) => {}}
            >
              <DataTable
                columns={locationsTableColumns}
                data={
                  orderSummary &&
                  orderSummary.accountServicesSummaries &&
                  activeLocationSubTab &&
                  activeLocationSubTab.key === 'locations' &&
                  activeLocationSubTab.index !== -1
                    ? orderSummary.accountServicesSummaries[activeLocationSubTab.index].locations
                    : []
                }
              />
            </CollapsibleTable>
            {/* <DataTable columns={billableServiceTable} data={orderSummary.accountServicesSummaries || []} /> */}
          </div>
        );
      case 'Locations':
        return (
          <div className="group-collapsible">
            <DataTable columns={locationsTable} data={orderSummary.accountLocationsSummaries || []} />
          </div>
        );
      default:
        return null;
    }
  };

  onAddMoreAccountToMerge = () => {
    this.setState({ isOpenModalAccountId: true, isMultiAccount: true, isSearched: true });
  };

  onSelectAccountId = (id, row) => {
    if (!id || !row) return this.setState({ isOpenModalAccountId: false });
    const { listItems, indexAccountSelected, isMultiAccount, isOpenModalDisperse, isOpenModalSaved } = this.state;
    if (isOpenModalSaved) {
      return this.setState({ accountIdSaved: id, isOpenModalSaved: false });
    }
    if (isOpenModalDisperse) {
      return this.setState({ accountIdDisperse: id, isOpenModalDisperse: false });
    }
    let newData = cloneDeep(listItems);
    if (isMultiAccount) {
      newData = uniqBy([...listItems, ...row], 'id');
    } else {
      newData[indexAccountSelected] = row;
    }

    return this.setState({ listItems: cloneDeep(newData), isOpenModalAccountId: false });
  };

  onChangeQuantity = ({ name, value }) => {
    const { totalUnitPrice, totalAmount } = this.state;
    let newTotalUnitPrice = totalUnitPrice;
    if (totalAmount && value && Number.parseFloat(value) > 0) {
      newTotalUnitPrice = Number.parseFloat(totalAmount) / Number.parseFloat(value);
    }

    this.setState({ totalQuantity: value, totalUnitPrice: newTotalUnitPrice });
  };

  onTogglepProvisioning = ({ item, index, itemDetails }) => {
    const { listItems, activeSubTab } = this.state;
    let { filterProvisioning, dataProvisioning, selectName, provisioningSelectRangeData } = this.state;
    try {
      const indexSelect = listItems[activeSubTab.index]?.billables.findIndex(
        val => val.omsInternalId === itemDetails.omsInternalId
      );
      if (
        indexSelect > -1 &&
        listItems &&
        listItems[activeSubTab.index] &&
        listItems[activeSubTab.index]?.billables[indexSelect]?.oldFilterProvisioning
      ) {
        filterProvisioning = cloneDeep(listItems[activeSubTab.index].billables[indexSelect]?.oldFilterProvisioning);
      } else {
        filterProvisioning = {};
      }
      if (
        indexSelect > -1 &&
        listItems &&
        listItems[activeSubTab.index].billables[indexSelect] &&
        listItems[activeSubTab.index]?.billables[indexSelect]?.oldDataProvisioning
      ) {
        dataProvisioning = cloneDeep(listItems[activeSubTab.index].billables[indexSelect]?.oldDataProvisioning);
      } else {
        dataProvisioning = [];
      }
      if (
        indexSelect > -1 &&
        listItems &&
        listItems[activeSubTab.index].billables[indexSelect] &&
        listItems[activeSubTab.index]?.billables[indexSelect]?.oldSelectName
      ) {
        selectName = listItems[activeSubTab.index].billables[indexSelect]?.oldSelectName;
      } else {
        selectName = null;
      }
      if (
        indexSelect > -1 &&
        listItems &&
        listItems[activeSubTab.index]?.billables[indexSelect] &&
        listItems[activeSubTab.index]?.billables[indexSelect]?.oldProvisioningSelectRangeData
      ) {
        provisioningSelectRangeData =
          listItems[activeSubTab.index].billables[indexSelect]?.oldProvisioningSelectRangeData;
      } else {
        provisioningSelectRangeData = [];
      }
      this.setState({
        isOpenModalProvisioning: true,
        itemProvisioningSelect: item,
        indexProvisioningSelect: activeSubTab.index,
        indexBillableProvisioningSelect: indexSelect,
        filterProvisioning,
        dataProvisioning,
        selectName,
        provisioningSelectRangeData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  doSearchProvisioningData = () => {
    const { searchServiceProvisioningData } = this.props;
    const {
      pageProvisioning,
      sizeProvisioning,
      filterProvisioning,
      sorted,
      isFirstSearch,
      itemProvisioningSelect,
      totalCountProvisioning,
      indexProvisioningSelect,
      listItems,
    } = this.state;
    if (!filterProvisioning || !filterProvisioning.serviceType) filterProvisioning.serviceType = 'VOICE';
    if (!filterProvisioning || !filterProvisioning.type) filterProvisioning.type = 'TRUNK';
    if (!filterProvisioning || !filterProvisioning.status) filterProvisioning.status = 'ACTIVE';
    if (!filterProvisioning || !filterProvisioning.accountId) filterProvisioning.accountId = itemProvisioningSelect.id;
    this.setState({ dataResponse: null, dataProvisioning: [], isFirstSearch: false });
    searchServiceProvisioningData(
      {
        page: pageProvisioning + 1,
        size: sizeProvisioning,
        filter: filterProvisioning,
        sort: !isEmpty(sorted) ? provisioningDataSort[sorted.sortCol][sorted.sortDir] : null,
      },
      resp => {
        const newResp = resp;
        const flexAttrsList = {};
        if (
          newResp.success &&
          newResp.data &&
          newResp.data.types &&
          newResp.data.types.length &&
          newResp.data.types[0].flexAttrMapping
        ) {
          newResp.data.types[0].flexAttrMapping.forEach(val => {
            flexAttrsList[val.flexAttribute] = val.tag;
          });
        }
        if (newResp.data && newResp.data.provisioning && newResp.data.provisioning[0]) {
          newResp.data.provisioning[0] = { ...newResp.data.provisioning[0], ...flexAttrsList };
          const newDataProvisioning = cloneDeep(newResp.data.provisioning);
          // const provisioningSelectRangeData = [];
          // if (
          //   listItems[indexProvisioningSelect].provisioningMovementTypes &&
          //   listItems[indexProvisioningSelect].provisioningMovementTypes.provDataMoveType === 'INDIVIDUAL' &&
          //   listItems[indexProvisioningSelect].provisioningMovementTypes.provDataList
          // ) {
          //   newDataProvisioning = cloneDeep(newDataProvisioning).map(val => {
          //     if (
          //       listItems[indexProvisioningSelect].provisioningMovementTypes.provDataList.find(
          //         i => i.provisioningId === val.provisioningId
          //       )
          //     ) {
          //       return { ...val, isSelected: true };
          //     }
          //     return { ...val };
          //   });
          // }

          // if (
          //   listItems[indexProvisioningSelect].provisioningMovementTypes &&
          //   listItems[indexProvisioningSelect].provisioningMovementTypes.provDataMoveType === 'ALL'
          // ) {
          //   newDataProvisioning = cloneDeep(newDataProvisioning).map(val => {
          //     return { ...val, isSelected: true };
          //   });
          // }
          // if (
          //   listItems[indexProvisioningSelect].provisioningMovementTypes &&
          //   listItems[indexProvisioningSelect].provisioningMovementTypes.provDataMoveType === 'RANGE' &&
          //   listItems[indexProvisioningSelect].provisioningMovementTypes.provDataList
          // ) {
          //   provisioningSelectRangeData = listItems[indexProvisioningSelect].provisioningMovementTypes.provDataList;
          // }
          this.setState({
            dataResponse: newResp.data.provisioning[0],
            dataProvisioning: newDataProvisioning,
            isFirstSearch: false,
            totalCountProvisioning: getPageTotalCount({
              totalCount: totalCountProvisioning,
              size: sizeProvisioning,
              page: pageProvisioning,
              items: newDataProvisioning,
            }),
            // provisioningSelectRangeData,
          });
        } else {
          this.setState({
            dataResponse: null,
            totalCountProvisioning: getPageTotalCount({
              totalCount: totalCountProvisioning,
              size: sizeProvisioning,
              page: pageProvisioning,
              items: newResp.data.provisioning,
            }),
            dataProvisioning: [],
            isFirstSearch: false,
          });
        }
      }
    );
  };

  onSortColumnProvisioning = (sortCol, sortDir) => {
    this.setState({ sortedProvisioning: { sortCol, sortDir } }, () => this.doSearchProvisioningData());
  };

  onSizeChangeProvisioning = size => {
    this.setState({ sizeProvisioning: size, page: 0 }, () => this.doSearchProvisioningData());
  };

  onPageChangeProvisioning = page => {
    const { pageProvisioning } = this.state;
    if (pageProvisioning === page) return '';
    this.setState({ pageProvisioning: page }, () => this.doSearchProvisioningData());
  };

  onHandleSubmitProvisioning = filter => {
    const { itemProvisioningSelect } = this.state;
    const newFilter = filter;
    newFilter.accountId = itemProvisioningSelect.id;
    this.setState({ filterProvisioning: newFilter }, () => this.doSearchProvisioningData());
  };

  onCloseProvisioning = () => {
    this.setState({ isOpenModalProvisioning: false, itemProvisioningSelect: null, indexProvisioningSelect: null });
  };

  onSelectCheckBox = ({ name, evt }) => {
    const { selectName, dataProvisioning } = this.state;
    let newDataProvisioning = cloneDeep(dataProvisioning);
    if (name === 'selectAll' && evt.target.checked && selectName !== name) {
      newDataProvisioning = newDataProvisioning.map(val => {
        return { ...val, isSelected: true };
      });
    } else {
      newDataProvisioning = newDataProvisioning.map(val => {
        const { isSelected, ...rest } = val;
        return { ...rest };
      });
    }
    if (selectName === name) return this.setState({ selectName: '', dataProvisioning: newDataProvisioning });

    return this.setState({ selectName: name, dataProvisioning: newDataProvisioning });
  };

  onAddRange = () => {
    const { provisioningSelectRangeData, filterProvisioning } = this.state;
    let newData = cloneDeep(provisioningSelectRangeData);
    newData = [
      ...newData,
      {
        startRange: filterProvisioning && filterProvisioning.startRange ? filterProvisioning.startRange : '',
        endRange: filterProvisioning && filterProvisioning.endRange ? filterProvisioning.endRange : '',
      },
    ];
    this.setState({ provisioningSelectRangeData: newData });
  };

  onSelectProvisioning = () => {
    const {
      dataProvisioning,
      provisioningSelectRangeData,
      indexProvisioningSelect,
      selectName,
      listItems,
      filterProvisioning,
      indexBillableProvisioningSelect,
    } = this.state;
    const { t } = this.props;
    const newListItems = cloneDeep(listItems);
    if (selectName === 'selectAll') {
      if (!newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes) {
        newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes = [];
      }
      newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].provisioningMovementTypes = newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].provisioningMovementTypes.filter(val => val.provDataMoveType !== 'ALL');
      newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes.push({
        type: filterProvisioning.type || 'TRUNK',
        provDataMoveType: 'ALL',
      });
      newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].oldProvDataMoveTypeSelect =
        'ALL';
    }
    if (selectName === 'selectSpecific') {
      if (!newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes) {
        newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes = [];
      }
      newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].provisioningMovementTypes = newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].provisioningMovementTypes.filter(val => val.provDataMoveType !== 'INDIVIDUAL');
      newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes.push({
        type: filterProvisioning.type || 'TRUNK',
        provDataMoveType: 'INDIVIDUAL',
        provDataList: dataProvisioning
          .filter(val => !!val.isSelected)
          .map(val => {
            const { provisioningId } = val;
            return { provisioningId };
          }),
      });
      newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].oldProvDataMoveTypeSelect =
        'INDIVIDUAL';
    }
    if (selectName === 'selectRange') {
      if (!provisioningSelectRangeData || !provisioningSelectRangeData.length) {
        return toast.error(t('common:message.useStartEndRange'));
      }
      if (!newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes) {
        newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes = [];
      }
      newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].provisioningMovementTypes = newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].provisioningMovementTypes.filter(val => val.provDataMoveType !== 'RANGE');
      newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].provisioningMovementTypes.push({
        type: filterProvisioning.type || 'DID',
        provDataMoveType: 'RANGE',
        provDataList: provisioningSelectRangeData,
      });
      newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].oldProvDataMoveTypeSelect =
        'RANGE';
      newListItems[indexProvisioningSelect].billables[
        indexBillableProvisioningSelect
      ].oldProvisioningSelectRangeData = provisioningSelectRangeData;
    }
    newListItems[indexProvisioningSelect].billables[
      indexBillableProvisioningSelect
    ].oldFilterProvisioning = filterProvisioning;
    newListItems[indexProvisioningSelect].billables[
      indexBillableProvisioningSelect
    ].oldDataProvisioning = dataProvisioning;
    newListItems[indexProvisioningSelect].billables[indexBillableProvisioningSelect].oldSelectName = selectName;

    this.setState({
      listItems: newListItems,
      // dataProvisioning: [],
      provisioningSelectRangeData: [],
      indexProvisioningSelect: null,
      // selectName: null,
      isOpenModalProvisioning: false,
    });
  };

  onSelectProvisioningId = ({ evt, item, index }) => {
    const { dataProvisioning } = this.state;
    const newData = cloneDeep(dataProvisioning);
    newData[index].isSelected = evt.target.checked;
    this.setState({ dataProvisioning: newData });
  };

  onSelectPendingMerge = (id, row) => {
    if (row) {
      const { dataMerge } = this.state;
      let { capacityMergeUpdate, totalQuantity, totalAmount, totalUnitPrice } = this.state;
      const newListItems = [];
      const { fromAccount, toAccount, effectiveDate, updateServiceCapacityOnMove, notes, savedAccountMovementDataId } =
        row || {};
      dataMerge.updateServiceCapacityOnMove = updateServiceCapacityOnMove === true ? 'TRUE' : 'FALSE';
      if (fromAccount && fromAccount.length) {
        fromAccount.forEach(val => {
          const { accountId, organization, moveProvisioningAttributes, moveAllServices, billables, ...rest } = val;
          const newBillables =
            billables && billables.length
              ? billables.map(bl => {
                  const { updateServiceCapacity, moveProvisioningAttributes, provisioningMovementTypes, ...rest } = bl;
                  let oldProvisioningSelectRangeData = [];
                  if (provisioningMovementTypes && provisioningMovementTypes.length) {
                    const provisioningMovementTypesRange = provisioningMovementTypes.filter(
                      val => val.provDataMoveType === 'RANGE'
                    );
                    if (provisioningMovementTypesRange && provisioningMovementTypesRange.length) {
                      provisioningMovementTypesRange.forEach(pr => {
                        const { provDataList } = pr;
                        if (provDataList && provDataList.length)
                          oldProvisioningSelectRangeData = [...oldProvisioningSelectRangeData, ...provDataList];
                      });
                    }
                  }
                  return {
                    ...rest,
                    updateServiceCapacity: updateServiceCapacity === true ? 'TRUE' : 'FALSE',
                    moveProvisioningAttributes: moveProvisioningAttributes === true ? 'TRUE' : 'FALSE',
                    provisioningMovementTypes,
                    oldProvisioningSelectRangeData:
                      oldProvisioningSelectRangeData && oldProvisioningSelectRangeData.length
                        ? oldProvisioningSelectRangeData
                        : null,
                  };
                })
              : [];
          newListItems.push({
            ...rest,
            id: accountId,
            isChild: true,
            contacts: [{ organization }],
            moveProvisioningAttributes: moveProvisioningAttributes === true ? 'TRUE' : 'FALSE',
            moveAllServices: !moveAllServices,
            billables: newBillables,
          });
        });
      }
      if (toAccount && toAccount.length) {
        toAccount.forEach(val => {
          const { moveAllServices, accountId, billables, organization, ...rest } = val;
          if (billables && billables.length) {
            billables.forEach(bl => {
              const { serviceId, serviceName, uom, capacity, quantity, servicePrice, totalPrice } = bl;
              dataMerge.selectCapacityMergeServiceForParent = serviceId;
              dataMerge.selectCapacityMergeServiceForParentName = serviceName;
              dataMerge.newSelectCapacityMergeServiceForParentUom = uom;
              capacityMergeUpdate = capacity;
              totalQuantity = quantity;
              totalUnitPrice = servicePrice;
              totalAmount = totalPrice;
            });
          }
          newListItems.push({
            ...rest,
            billables,
            id: accountId,
            parentIdSelected: true,
            moveAllServices: !val.moveAllServices,
            contacts: [{ organization }],
          });
        });
      }
      this.setState({
        listItems: newListItems,
        effectiveDate: effectiveDate || null,
        isOpenSelectPendingMerge: false,
        isSearched: true,
        dataMerge: { ...dataMerge },
        capacityMergeUpdate,
        totalQuantity,
        totalAmount,
        totalUnitPrice,
        notesAccountMovement: notes || null,
        savedAccountMovementDataId: savedAccountMovementDataId || null,
      });
    } else {
      this.setState({ isOpenSelectPendingMerge: false });
    }
  };

  render() {
    const { permissionsCustomer, permissionsRating, t, permissionsBilling } = this.props;
    const {
      page,
      size,
      totalCount,
      isExpandChild,
      listItems,
      effectiveDate,
      isSearched,
      activeSubTab,
      activeTab,
      isOpenModalAccept,
      isMergeSelect,
      isWithinOrganization,
      dataMerge,
      isOpenModalBilledService,
      isOpenModalAccountId,
      isMultiAccount,
      totalQuantity,
      totalAmount,
      totalUnitPrice,
      isUndoPreviousMerge,
      accountIdDisperse,
      isOpenModalDisperse,
      isOpenModalProvisioning,
      isSearchingProvisioning,
      sortedProvisioning,
      dataProvisioning,
      selectName,
      activeTabProvisioning,
      pageProvisioning,
      sizeProvisioning,
      totalCountProvisioning,
      provisioningSelectRangeData,
      filterProvisioning,
      capacityMergeUpdate,
      selectOption,
      notesAccountMovement,
      isOpenSelectPendingMerge,
      isOpenModalSaved,
      accountIdSaved,
      wasValidatedSave,
      wasValidated,
    } = this.state;

    // console.log('listItems', listItems);

    const tableColumns = [
      {
        name: 'clientId',
        label: 'label.clientId',
        style: { minWidth: '100px' },
        render: (colName, item) => (
          <GenericInput
            value={item && item.clientId ? item.clientId : ''}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="clientId"
            readOnly
          />
        ),
      },
      {
        name: 'organization',
        label: 'label.organization',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="organization"
              readOnly
            />
          );
        },
      },
      {
        name: 'id',
        label: 'label.accountId',
        style: { minWidth: '180px' },
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item && item.id ? item.id : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="accountId"
              fa={isWithinOrganization ? '' : 'fa fa-external-link'}
              onClick={() =>
                isWithinOrganization
                  ? {}
                  : this.setState({ isOpenModalAccountId: true, indexAccountSelected: idx, isMultiAccount: false })
              }
              readOnly={isWithinOrganization}
            />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'paying',
        label: 'label.paying',
        style: { minWidth: '90px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.nonPaying ? 'FALSE' : 'TRUE'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="paying"
              readOnly
            />
          );
        },
      },
      {
        name: 'parentId',
        label: 'label.parentId',
        style: { minWidth: '100px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.parentId}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="parentId"
              readOnly
            />
          );
        },
      },
      {
        name: 'selectParent',
        label: 'label.selectParent',
        render: (name, item) => {
          return (
            <GenericInput
              value={item.parentIdSelected}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleSelectParent({ name, value, item })}
              type="checkbox"
              name={item.id}
              checked={item.parentIdSelected}
              disabled={item.isChild}
            />
          );
        },
      },
      {
        name: 'selectChild',
        label: 'label.selectChild',
        render: (name, item) => {
          return (
            <GenericInput
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeChild({ name: 'isChild', value, item })}
              name={`selectChild-${item.id}`}
              type="checkbox"
              checked={item.isChild}
              value={item.isChild}
              disabled={
                (item &&
                  item.paymentProfiles &&
                  item.paymentProfiles[0] &&
                  item.paymentProfiles[0].paymentMethod &&
                  item.paymentProfiles[0].paymentMethod === 'NON_PAYING') ||
                item.parentIdSelected
              }
            />
          );
        },
      },
      {
        name: 'particalMerge',
        label: 'label.particalMerge',
        style: { minWidth: '200px' },
        render: (colName, item, idx, indexParent, activeTab) => (
          <div className="d-flex col-md-12">
            <Switch
              onChange={({ name, value }) => this.onHandleChangeChild({ name: 'moveAllServices', value, item })}
              name={`moveAllServices-${item.id}`}
              wrapperClass={`col-md-5 ${item.moveAllServices ? 'switch-active' : 'switch-non-active'}`}
              // disabled={!item.isChild || dataMerge.updateServiceCapacityOnMove === 'FALSE'}
              disabled={!item.isChild}
              checked={item.moveAllServices}
            />
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleSubTab(idx, item, idx, 'particalMerge')}
              disabled={
                !item.isChild ||
                (item &&
                  item.paymentProfiles &&
                  item.paymentProfiles[0] &&
                  item.paymentProfiles[0].paymentMethod &&
                  item.paymentProfiles[0].paymentMethod === 'NON_PAYING')
              }
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'particalMerge' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          </div>
        ),
      },
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (name, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveBilledService({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      // {
      //   name: 'moveProvisioning',
      //   label: 'label.moveProvisioning',
      //   style: { minWidth: '110px' },
      //   render: (name, item) => {
      //     if (item.isChild && item.moveAllServices)
      //       return (
      //         <GenericInput
      //           value={item.moveProvisioningAttributes || 'FALSE'}
      //           wrapperClass="col-md-12"
      //           onChange={({ name, value }) => this.onChangeProvisioning({ value, item })}
      //           name="moveProvisioningAttributes"
      //           type="select"
      //           tOptions="selections:selectBool"
      //           menuPortalTarget
      //           disabled={
      //             item &&
      //             item.paymentProfiles &&
      //             item.paymentProfiles[0] &&
      //             item.paymentProfiles[0].paymentMethod &&
      //             item.paymentProfiles[0].paymentMethod === 'NON_PAYING'
      //           }
      //         />
      //       );

      //     return '';
      //   },
      // },
      // {
      //   name: 'selectProvisioning',
      //   label: 'label.selectProvisioning',
      //   render: (name, item, index) => {
      //     return (
      //       <button
      //         type="button"
      //         className="btn btn-outline-success btn-sm"
      //         onClick={evt => this.onTogglepProvisioning({ name, item, index })}
      //         disabled={!item.moveProvisioningAttributes || item.moveProvisioningAttributes === 'FALSE'}
      //       >
      //         {t('common:label.selectProvisioning')}
      //       </button>
      //     );
      //   },
      // },
    ];

    const newListItems = cloneDeep(listItems);
    let provisioningIdSelected = [];
    let provisioningIdSelectedByRange = [];
    let isDisableAddRangeAndCheckCurrentAccount = false;
    if (activeSubTab && activeSubTab.index !== -1 && newListItems && newListItems.length) {
      newListItems.forEach(dt => {
        const { billables } = dt;
        if (billables && billables.length) {
          billables.forEach(val => {
            const { provisioningMovementTypes } = val;
            if (provisioningMovementTypes && provisioningMovementTypes.length) {
              const individualSelected = provisioningMovementTypes.filter(pr => pr.provDataMoveType === 'INDIVIDUAL');
              const rangeSelected = provisioningMovementTypes.filter(pr => pr.provDataMoveType === 'RANGE');
              if (individualSelected && individualSelected.length) {
                let provDataList = [];
                individualSelected.forEach(i => {
                  provDataList = [...provDataList, ...i.provDataList];
                });
                provisioningIdSelected = [...provisioningIdSelected, ...provDataList.map(i => i.provisioningId)];
              }
              if (rangeSelected && rangeSelected.length) {
                rangeSelected.forEach(i => {
                  provisioningIdSelectedByRange = [...provisioningIdSelectedByRange, ...i.provDataList];
                });
              }
            }
          });
        }
      });
    }
    if (provisioningIdSelectedByRange && provisioningIdSelectedByRange.length) {
      provisioningIdSelectedByRange.forEach(val => {
        if (filterProvisioning?.startRange && filterProvisioning?.endRange && val?.startRange && val?.endRange) {
          if (
            Number.parseFloat(filterProvisioning?.startRange) <= Number.parseFloat(val?.endRange) &&
            Number.parseFloat(filterProvisioning?.startRange) >= Number.parseFloat(val?.startRange)
          ) {
            isDisableAddRangeAndCheckCurrentAccount = true;
          }
          if (
            Number.parseFloat(filterProvisioning?.endRange) <= Number.parseFloat(val?.endRange) &&
            Number.parseFloat(filterProvisioning?.endRange) >= Number.parseFloat(val?.startRange)
          ) {
            isDisableAddRangeAndCheckCurrentAccount = true;
          }
        }
      });
    }
    if (provisioningIdSelected && provisioningIdSelected.length) {
      provisioningIdSelected.forEach(val => {
        if (filterProvisioning?.startRange && filterProvisioning?.endRange && val) {
          if (
            !Number.isNaN(val) &&
            Number.parseFloat(filterProvisioning?.startRange) <= Number.parseFloat(val) &&
            Number.parseFloat(filterProvisioning?.endRange) >= Number.parseFloat(val)
          ) {
            isDisableAddRangeAndCheckCurrentAccount = true;
          }
        }
      });
    }

    const columnsProvisioning = [
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item, index) => {
          let isDisableRangeData = false;
          if (
            provisioningSelectRangeData &&
            provisioningSelectRangeData.length &&
            item.provisioningId &&
            !Number.isNaN(item.provisioningId)
          ) {
            provisioningSelectRangeData.forEach(val => {
              if (
                Number.parseFloat(item.provisioningId) >= Number.parseFloat(val.startRange) &&
                Number.parseFloat(item.provisioningId) <= Number.parseFloat(val.endRange)
              ) {
                isDisableRangeData = true;
              }
            });
          }
          if (
            provisioningIdSelected &&
            provisioningIdSelected.length &&
            provisioningIdSelected.indexOf(item.provisioningId) !== -1
          ) {
            isDisableRangeData = true;
          }

          if (
            provisioningIdSelectedByRange &&
            provisioningIdSelectedByRange.length &&
            item.provisioningId &&
            !Number.isNaN(item.provisioningId)
          ) {
            provisioningIdSelectedByRange.forEach(val => {
              if (
                Number.parseFloat(item.provisioningId) >= Number.parseFloat(val.startRange) &&
                Number.parseFloat(item.provisioningId) <= Number.parseFloat(val.endRange)
              ) {
                isDisableRangeData = true;
              }
            });
          }
          return (
            <CheckField
              name="selected"
              value={item && item.isSelected}
              checked={item && item.isSelected}
              disabled={selectName !== 'selectSpecific' || isDisableRangeData}
              onChange={evt => this.onSelectProvisioningId({ evt, item, index })}
            />
          );
        },
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'type',
        label: 'common:label.type',
        sortable: true,
      },
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        sortable: true,
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
        sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
    ];

    const columnsProvisioningSelectRange = [
      {
        name: 'startRange',
        label: t('label.startingNumber'),
        render: (colName, item) => {
          return (
            <GenericInput
              value={item.startRange}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              // label="label.startingNumber"
              name="startRange"
              type="number"
              readOnly
            />
          );
        },
      },
      {
        name: 'endRange',
        label: t('label.endingNumber'),
        render: (colName, item) => {
          return (
            <GenericInput
              value={item.endRange}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              // label="label.endRange"
              name="endRange"
              type="number"
              readOnly
            />
          );
        },
      },
    ];

    let modeCreateCustomer = 0;
    let modeSearchAccount = 0;
    let modeGetAccount = 0;

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeCreateCustomer = checkPermissionCreateCustomer({ listPermission });
      modeSearchAccount = checkPermissionSearchAccount({ listPermission });
      modeGetAccount = checkPermissionGetAccount({ listPermission });
    }

    const newTableColumn = cloneDeep(tableColumns);
    if (modeGetAccount === 0) {
      newTableColumn[0] = {
        name: 'id',
        label: 'label.ACCTNo',
      };
      newTableColumn.splice(newTableColumn.length - 1, 1);
    }

    let isShowSplit = false;
    if (isUndoPreviousMerge) isShowSplit = true;

    const lastRange =
      provisioningSelectRangeData && provisioningSelectRangeData[provisioningSelectRangeData.length - 1]
        ? provisioningSelectRangeData[provisioningSelectRangeData.length - 1].endRange
        : 0;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper">
            {/* <Sidebar
              sideProps={newSideProps}
              isSupportCheckRoute
              isExpandChild={isExpandChild}
              keyRoute="accountMovement"
            /> */}
            <SidebarIndex isSupportCheckRoute isExpandChild={isExpandChild} keyRoute="accountMovement" />
            {modeSearchAccount !== 0 && (
              <PageTitle
                linkTo={RouteNames.customers.path}
                titleBtn={t('common:label.back')}
                items={[{ name: t('sidebarSearchPage.config.subTitle.merge') }]}
              />
            )}
            <br />
            {modeSearchAccount !== 0 && (
              <form
                className={`col-md-12 mb-30 needs-validation ${wasValidated ? 'was-validated' : ''}`}
                noValidate
                ref={this.formRef}
              >
                <div className="card card-statistics h-100">
                  <TitleFrom title={t('common:label.billingAccountMovementMerge')} />
                  <div className="col-md-12 pl-2 pb-3 row">
                    <GenericInput
                      value={selectOption}
                      wrapperClass="col-md-4"
                      onChange={({ name, value }) => this.onChangeSelectOptions({ value, name })}
                      name="selectOptions"
                      type="select"
                      label="label.selectOptions"
                      tOptions="selections:mergeSelectOptions"
                      menuPortalTarget
                      isNoClearable
                    />
                  </div>
                  <div className="col-md-12 pl-2 pb-3 row">
                    <GenericInput
                      value={dataMerge.updateServiceCapacityOnMove}
                      wrapperClass="col-md-4"
                      onChange={({ name, value }) => this.onChangeDataMerge({ value, name })}
                      name="updateServiceCapacityOnMove"
                      type="select"
                      label="label.capacityMerge"
                      tOptions="selections:selectBool"
                      menuPortalTarget
                    />
                    {dataMerge.updateServiceCapacityOnMove === 'TRUE' && (
                      <>
                        <GenericInput
                          value={
                            dataMerge.selectCapacityMergeServiceForParent
                              ? `${dataMerge.selectCapacityMergeServiceForParent}-${dataMerge.selectCapacityMergeServiceForParentName}`
                              : ''
                          }
                          wrapperClass="col-md-4"
                          onChange={({ name, value }) => {}}
                          name="selectCapacityMergeServiceForParent"
                          label="label.selectCapacityMergeServiceForParent"
                          fa="fa fa-external-link"
                          onClick={() => this.setState({ isOpenModalBilledService: true })}
                          required
                        />
                        <GenericInput
                          value={dataMerge.newSelectCapacityMergeServiceForParentUom || ''}
                          wrapperClass="col-md-4"
                          label="label.unitOfMeasure"
                          // onChange={({ name, value }) => this.setState({ totalQuantity: value })}
                          onChange={({ name, value }) => {}}
                          name="quantity"
                          readOnly
                        />
                        <GenericInput
                          value={capacityMergeUpdate}
                          wrapperClass="col-md-4"
                          label="label.capacity"
                          onChange={({ name, value }) => this.setState({ capacityMergeUpdate: value })}
                          name="capacity"
                          type="number"
                          disabled={!dataMerge.selectCapacityMergeServiceForParent}
                        />
                        <GenericInput
                          value={totalQuantity || '0'}
                          wrapperClass="col-md-4"
                          // onChange={({ name, value }) => this.setState({ totalQuantity: value })}
                          onChange={this.onChangeQuantity}
                          name="quantity"
                          label="label.quantity"
                          type="number"
                        />
                        <GenericInput
                          value={totalUnitPrice || '0'}
                          wrapperClass="col-md-4"
                          onChange={({ name, value }) => {}}
                          label="label.unitPrice"
                          name="unitPrice"
                          type="number"
                          readOnly
                        />
                        <GenericInput
                          value={totalAmount || '0'}
                          wrapperClass="col-md-4"
                          onChange={({ name, value }) => {}}
                          label="label.amount"
                          name="amount"
                          type="number"
                          readOnly
                        />
                      </>
                    )}
                    <Switch
                      onChange={this.onChangeSwitchWithinOrganization}
                      name="isWithinOrganization"
                      wrapperClass={`col-md-4 pl-2 pt-4 pr-3 ${
                        isWithinOrganization ? 'switch-active' : 'switch-non-active'
                      }`}
                      title={t('common:label.withinOrganization')}
                      // rightTitle={t('common:label.merge')}
                      checked={isWithinOrganization}
                      // rightTitle={t('common:label.desperse')}
                    />
                  </div>
                  <div className="col-md-12 row mb-3">
                    {isUndoPreviousMerge && (
                      <GenericInput
                        value={accountIdDisperse || false}
                        wrapperClass="col-md-4 ml-2"
                        onChange={({ name, value }) => {}}
                        name="selectAccountToUndo"
                        label="label.selectAccountToUndo"
                        fa="fa fa-external-link"
                        onClick={() => this.setState({ isOpenModalDisperse: true })}
                        required
                      />
                    )}
                  </div>
                  {selectOption === 'SELECT_SAVED_MOVEMENT' && (
                    <div className="col-md-12 row mb-3 pt-3 separation-border">
                      <GenericInput
                        value={accountIdSaved || null}
                        wrapperClass="col-md-4 ml-2"
                        onChange={({ name, value }) => {}}
                        name="selectAccountSaved"
                        label="label.selectAccount"
                        fa="fa fa-external-link"
                        onClick={() => this.setState({ isOpenModalSaved: true, isMultiAccount: false })}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => this.setState({ isOpenSelectPendingMerge: true })}
                        className="button mt-4 x-small float-right"
                        disabled={!accountIdSaved}
                      >
                        {t('common:label.view')}
                      </button>
                    </div>
                  )}

                  {isWithinOrganization && (
                    <div className="card-body">
                      <div className="repeater-file">
                        <div>
                          <MergeSearchForm onSubmit={this.onHandleSubmit} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
            {modeSearchAccount !== 0 && !isShowSplit && (
              <div className="col-sm-12 mb-30">
                <div className="card card-statistics h-100">
                  <div className="card-body group-collapsible">
                    <CollapsibleTable
                      columns={newTableColumn}
                      isNoNeedFixedHeader
                      indexViewer={
                        activeSubTab && activeSubTab.key === 'particalMerge' && activeSubTab.index !== -1
                          ? activeSubTab.index
                          : -1
                      }
                      onClickRow={(idx, item, indexItem) => {}}
                      data={listItems && listItems.length && isSearched ? listItems : []}
                      isSupportRemoveIndex
                      isViewOnly
                    >
                      <div className="col-md-12 pt-3 pb-3">
                        {collapsibleMenu &&
                          collapsibleMenu.length > 0 &&
                          collapsibleMenu.map(item => {
                            return (
                              <FormCollapse
                                key={item.title}
                                label="First Name"
                                isActive={activeTab && activeTab.find(val => val.name === item.state)}
                                title={t(item.title) || ''}
                                state={item.state}
                                onToggleTab={this.onToggleTab}
                              >
                                {this.renderItem(item)}
                              </FormCollapse>
                            );
                          })}
                      </div>
                    </CollapsibleTable>
                    {!isWithinOrganization && (
                      <div>
                        <button
                          type="button"
                          onClick={this.onAddMoreAccountToMerge}
                          className="button button-border black x-small mt-3 mb-3"
                        >
                          +
                          {t('common:label.addMoreAccountToMerge')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!isShowSplit && (
              <div className="col-sm-12 mb-30">
                <div className="card card-statistics h-100">
                  <div className="card-body">
                    <form
                      noValidate
                      className={`col-md-12 ml-3 row needs-validation ${wasValidatedSave ? 'was-validated' : ''}`}
                    >
                      <GenericInput
                        value={effectiveDate}
                        label="label.effectiveDate"
                        name="effectiveDate"
                        minDate={new Date()}
                        type="date"
                        wrapperClass="col-md-4"
                        onChange={({ value }) => this.setState({ effectiveDate: value })}
                      />
                      <GenericInput
                        value={notesAccountMovement}
                        label="label.notes"
                        name="notesAccountMovement"
                        wrapperClass="col-md-4"
                        type="textarea"
                        required={wasValidatedSave}
                        onChange={({ value }) => this.setState({ notesAccountMovement: value })}
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => this.setState({ isOpenModalAccept: !isOpenModalAccept })}
                          className="button mt-4 x-small float-right"
                          disabled={!listItems || !listItems.length || !listItems.find(val => !!val.parentIdSelected)}
                        >
                          {t('common:label.merge')}
                        </button>
                        <button
                          type="button"
                          onClick={() => this.onHandleSubmitMerge({ isSave: true })}
                          className="button mt-4 x-small float-right mr-2"
                          disabled={!listItems || !listItems.length || !listItems.find(val => !!val.parentIdSelected)}
                        >
                          {t('common:label.save')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {isShowSplit && !isOpenModalDisperse && (
              <Split
                isComponent
                accountIdDisperse={accountIdDisperse}
                typeActivity="UNDO_DISPERSE"
                labelFromMerge={t('common:label.merge')}
                typeSubmit="MERGE"
              />
            )}
            {modeSearchAccount !== 0 && (
              <div className="mb-30">
                <TablePagination
                  pageNumber={page}
                  pageSize={size}
                  totalCount={totalCount}
                  onPageChange={() => this.onPageChange}
                  onSizeChange={this.onSizeChange}
                />
              </div>
            )}
            <Footer className="footer-bottom" />
          </div>
        </div>
        <ModalAccept
          isOpen={isOpenModalAccept}
          message={t('common:message.mergeCompleted')}
          onToggle={() => this.setState({ isOpenModalAccept: !isOpenModalAccept })}
          onAcceptModal={this.onHandleSubmitMerge}
        />
        <ModalSearchItemServices
          isOpen={isOpenModalBilledService}
          onSelect={(id, row) => {
            const { dataMerge } = this.state;
            dataMerge.selectCapacityMergeServiceForParent = id;
            dataMerge.selectCapacityMergeServiceForParentName = row.name;
            dataMerge.selectCapacityMergeServiceForParentUom = row.uom;
            this.setState({ dataMerge: { ...dataMerge }, isOpenModalBilledService: false });
          }}
          title={t('common:label.selectCapacityMergeBillableService')}
          onCancel={() => {
            this.setState({ isOpenModalBilledService: false });
          }}
          isChangeItem
          selectedParentId={
            this.state && this.state.dataMerge.selectCapacityMergeServiceForParent
              ? this.state.dataMerge.selectCapacityMergeServiceForParent
              : ''
          }
        />
        {isMultiAccount ? (
          <ModalMultipleAccountIds
            isOpen={isOpenModalAccountId}
            onSelect={this.onSelectAccountId}
            onCancel={() => {
              this.setState({ isOpenModalAccountId: false });
            }}
            columns={accountColumns}
            defaultSearchParams={{ type: 'DIRECT_CUSTOMER' }}
          />
        ) : (
          <ModalAccountId
            isOpen={isOpenModalAccountId || isOpenModalDisperse || isOpenModalSaved}
            onSelect={this.onSelectAccountId}
            onCancel={() => {
              this.setState({ isOpenModalAccountId: false, isOpenModalDisperse: false, isOpenModalSaved: false });
            }}
            defaultSearchParams={{ type: 'DIRECT_CUSTOMER' }}
            columns={accountColumns}
          />
        )}
        <ModalWithItem
          modalTitle={t('customerPage:sidebarHandleAccountPage.provisioningData')}
          isOpen={isOpenModalProvisioning}
          onToggle={this.onCloseProvisioning}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        >
          <ProvisioningDataSearchForm
            onSubmit={this.onHandleSubmitProvisioning}
            defaultFilter={{ status: 'ACTIVE', ...filterProvisioning }}
          />
          <br />
          <div className="col-md-12 d-flex">
            <CheckField
              name="selectAll"
              id="selectAll"
              checked={selectName === 'selectAll'}
              label={t('common:label.selectAll')}
              onChange={evt => this.onSelectCheckBox({ name: 'selectAll', evt })}
            />
            <CheckField
              name="selectSpecific"
              id="selectSpecific"
              checked={selectName === 'selectSpecific'}
              label={t('common:label.selectSpecific')}
              onChange={evt => this.onSelectCheckBox({ name: 'selectSpecific', evt })}
            />
            <CheckField
              name="selectRange"
              id="selectRange"
              checked={selectName === 'selectRange'}
              label={t('common:label.selectRange')}
              onChange={evt => this.onSelectCheckBox({ name: 'selectRange', evt })}
              disabled={!filterProvisioning || filterProvisioning.type !== 'DID'}
            />
            <button
              type="button"
              onClick={this.onAddRange}
              className="button x-small ml-3 float-right"
              disabled={
                selectName !== 'selectRange' ||
                !filterProvisioning.startRange ||
                !filterProvisioning.endRange ||
                Number.parseFloat(filterProvisioning.startRange) <= Number.parseFloat(lastRange) ||
                dataProvisioning.length < 1 ||
                isDisableAddRangeAndCheckCurrentAccount
              }
            >
              {t('common:label.addRangeSelected')}
            </button>
          </div>
          <br />
          <div>
            {selectName === 'selectRange' &&
              collapsibleMenuProvisioning.map(item => (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={item.state === activeTabProvisioning.name && activeTabProvisioning.isActive}
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={this.onToggleTabProvisioning}
                >
                  <div className="col-md-12 row mt-3">
                    <DataTable columns={columnsProvisioningSelectRange} data={provisioningSelectRangeData || []} />
                  </div>
                </FormCollapse>
              ))}
          </div>
          <br />
          <div>
            <DataTable
              columns={columnsProvisioning}
              data={dataProvisioning || []}
              isLoading={isSearchingProvisioning}
              sorted={sortedProvisioning}
              onSort={this.onSortColumnProvisioning}
            />
          </div>
          <div className="col-sm-12">
            <TablePagination
              pageNumber={pageProvisioning}
              pageSize={sizeProvisioning}
              totalCount={totalCountProvisioning}
              onPageChange={this.onPageChangeProvisioning}
              onSizeChange={this.onSizeChangeProvisioning}
            />
          </div>
          <div className="text-right">
            <button type="button" className="btn btn-secondary mr-2" onClick={this.onCloseProvisioning}>
              {t('common:label.cancel')}
            </button>
            <button type="button" className="btn btn-success" onClick={this.onSelectProvisioning}>
              {t('common:label.select')}
            </button>
          </div>
        </ModalWithItem>
        <ModalSelectPendingMerge
          isOpen={isOpenSelectPendingMerge}
          onSelect={this.onSelectPendingMerge}
          onCancel={() => {
            this.setState({ isOpenSelectPendingMerge: false });
          }}
          headerTitle={t('common:label.savedPendingMergeRequest')}
          defaultSearchParams={{ type: 'MERGE', accountId: accountIdSaved }}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  listCustomers: makeGetListCustomers() || [],
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsBilling: makeGetPermissionsBilling() || {},
});

Merge.propTypes = {
  listCustomers: PropTypes.arrayOf(PropTypes.any),
  permissionsCustomer: PropTypes.objectOf(PropTypes.any),
  permissionsRating: PropTypes.objectOf(PropTypes.any),
  getCustomers: PropTypes.func,
  getParentAccountDetail: PropTypes.func.isRequired,
  saveAccountMovementData: PropTypes.func.isRequired,
};

Merge.defaultProps = {
  listCustomers: {},
  permissionsCustomer: {},
  permissionsRating: {},
  getCustomers: () => {},
};

const newMerge = connect(mapStateToProps, {
  getCustomers,
  setParams,
  getParentAccountDetail,
  getOrderSummaryByAccountId,
  updateBillingAccount,
  searchServiceProvisioningData,
  saveAccountMovementData,
})(Merge);

export default withTranslation(['customerPage', 'common', 'selections'])(newMerge);
