import constants from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateTask = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.CREATE_TASK });
};

const checkPermissionModifyTask = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.MODIFY_TASK });
};

const checkPermissionSearchTask = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.SEARCH_TASKS });
};

const checkPermissionCreateChildTask = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.CREATE_CHILD_TASK });
};

const checkPermissionGetChildTask = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.GET_CHILD_TASKS });
};

const checkPermissionSyncTaskToExternal = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.SYNC_TASK_TO_EXTERNAL });
};

const checkPermissionSyncTaskFromExternal = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.SYNC_TASK_STATUS_FROM_EXTERNAL });
};

const checkPermissionTaskAdmin = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.TASK_ADMIN });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeSearchTask = 0;
  let modeCreateTask = 0;
  let modeGetTask = 0;
  let modeCreateChildTask = 0;
  let modeGetChildTask = 0;
  let modeSyncTaskToExternal = 0;
  let modeSyncTaskFromExternal = 0;
  let modeTaskAdmin = 0;

  modeSearchTask = checkPermissionCreateTask({ listPermission });
  modeCreateTask = checkPermissionModifyTask({ listPermission });
  modeGetTask = checkPermissionSearchTask({ listPermission });
  modeCreateChildTask = checkPermissionGetChildTask({ listPermission });
  modeGetChildTask = checkPermissionSyncTaskToExternal({ listPermission });
  modeSyncTaskToExternal = checkPermissionSyncTaskToExternal({ listPermission });
  modeSyncTaskFromExternal = checkPermissionSyncTaskFromExternal({ listPermission });
  modeTaskAdmin = checkPermissionTaskAdmin({ listPermission });

  return {
    modeSearchTask,
    modeCreateTask,
    modeGetTask,
    modeCreateChildTask,
    modeGetChildTask,
    modeSyncTaskToExternal,
    modeSyncTaskFromExternal,
    modeTaskAdmin,
  };
};

export {
  checkPermissionCreateTask,
  checkPermissionModifyTask,
  checkPermissionSearchTask,
  checkPermissionCreateChildTask,
  checkPermissionGetChildTask,
  checkPermissionSyncTaskToExternal,
  checkPermissionSyncTaskFromExternal,
  checkPermissionForAllPages,
  checkPermissionTaskAdmin,
};
