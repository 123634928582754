import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['expiryDays', 'accountType'])}` : '';
  const getSubscriptionReport = `getSubscriptionReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getSubscriptionReport} {
        accountId
        company
        firstName
        lastName
        accountType
        subscriptionId
        effectiveDate
        startDate
        endDate
        initialTermUnit
        initialTerm
        renewalTermUnit
        renewalTerm
        status
      }
    }
  `;
};
