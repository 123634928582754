import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createItem });
};

const checkPermissionModifyItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyItem });
};

const checkPermissionApproveItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.approveItem });
};

const checkPermissionSellableItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sellableItem });
};

const checkPermissionNonSellableItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.nonSellableItem });
};

const checkPermissionSunsetItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sunsetItem });
};

const checkPermissionSearchItems = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchItems });
};

const checkPermissionGetItem = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getItem });
};

const checkPermissionCeatePriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPriceOffer });
};

const checkPermissionModifyPriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPriceOffer });
};

const checkPermissionApprovePriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.approvePriceOffer });
};

const checkPermissionSellablePriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sellablePriceOffer });
};

const checkPermissionNonSellablePriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.nonSellablePriceOffer });
};

const checkPermissionSunsetPriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sunsetPriceOffer });
};

const checkPermissionSearchPriceOffers = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchPriceOffers });
};

const checkPermissionGetPriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPriceOffer });
};

const checkPermissionCeateDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createDiscountOffer });
};

const checkPermissionModifyDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyDiscountOffer });
};

const checkPermissionApproveDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.approveDiscountOffer });
};

const checkPermissionSellableDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sellableDiscountOffer });
};

const checkPermissionNonSellableDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.nonSellableDiscountOffer });
};

const checkPermissionSunsetDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sunsetDiscountOffer });
};

const checkPermissionSearchDiscountOffers = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchDiscountOffers });
};

const checkPermissionGetDiscountOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getDiscountOffer });
};

const checkPermissionCreateItemServices = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createIteamServices });
};

const checkPermissionModifyItemServices = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyItemServices });
};

const checkPermissionGetItemServices = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchItemServices });
};

const checkPermissionCreateProductFamily= ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_PRODUCT_FAMILY });
};

const checkPermissioModifyProductFamily = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_PRODUCT_FAMILY });
};

const checkPermissionSearchProductFamily = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_PRODUCT_FAMILY });
};

export {
  checkPermissionCreateItem,
  checkPermissionModifyItem,
  checkPermissionApproveItem,
  checkPermissionSellableItem,
  checkPermissionNonSellableItem,
  checkPermissionSunsetItem,
  checkPermissionSearchItems,
  checkPermissionGetItem,
  checkPermissionCeatePriceOffer,
  checkPermissionModifyPriceOffer,
  checkPermissionApprovePriceOffer,
  checkPermissionSellablePriceOffer,
  checkPermissionNonSellablePriceOffer,
  checkPermissionSunsetPriceOffer,
  checkPermissionSearchPriceOffers,
  checkPermissionGetPriceOffer,
  checkPermissionCeateDiscountOffer,
  checkPermissionModifyDiscountOffer,
  checkPermissionApproveDiscountOffer,
  checkPermissionSellableDiscountOffer,
  checkPermissionNonSellableDiscountOffer,
  checkPermissionSunsetDiscountOffer,
  checkPermissionSearchDiscountOffers,
  checkPermissionGetDiscountOffer,
  checkPermissionCreateItemServices,
  checkPermissionModifyItemServices,
  checkPermissionGetItemServices,
  checkPermissionCreateProductFamily,
  checkPermissioModifyProductFamily,
  checkPermissionSearchProductFamily,
};
