import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class UnbilledTransactionsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.monthYear',
      name: 'monthYear',
      type: 'monthYear',
    },
    {
      label: 'label.billType',
      name: 'billType',
      type: 'select',
      tOptions: 'selections:billUnitType',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      label: 'label.organization',
      name: 'company',
    },
  ];

  btnSpan = 'col-md-9';
}

UnbilledTransactionsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UnbilledTransactionsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UnbilledTransactionsSearchForm);
