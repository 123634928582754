import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accounttype',
        'delivered',
        'invoiceExhibits',
        'childServices',
        'chargeType',
        'orderStatus',
        'billed',
        'cutOffDOM',
      ])}`
    : '';
  const getBillableServicesReport = `getBillableServicesReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBillableServicesReport} {
        accountId
        commercialName
        billableServiceId
        billableServiceName
        orderDate
        cancellationDate
        deliveryDate
        adminStatus
        operativeStatus
        loadType
        instanceId
        status
        quantity
        amount
        unitPrice
        isParent
        delivered
        accounttype
        chargeType
        recurringType
        invoiceExhibits
        exhibitsInvoiced
        recurringFrequency
        cutOffDOM
        isChild
        advanceFlag
        isGrant
        crmId
        provisioningId
        orderType
        orderStatus
        orderAction
        currency
        isDispersed
        isMerged
        opportunityName
        termEndDate
        nextCutOffDate
        initialTerm
        partMonthWaiveOffFlag
        isTraffic
        location1
        location2
        reason
        billed
        name
        recurringUnit
        uom
        capacity
        circuitId
        clientCircuitId
      }
    }
  `;
};
