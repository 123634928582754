import { createSelector } from 'reselect';

const selectCollections = state => state.get('tenantManagementReducer');
// ----search Tenant Data
const makeTenantData = () => createSelector(selectCollections, item => item.get('tenantData'));

const makeGetCcpProperties = () => createSelector(selectCollections, item => item.get('ccpProperties'));

const makeGetPaymentMethodUrls = () => createSelector(selectCollections, item => item.get('getPaymentMethodUrls'));

const makeGetGatewayCanonicalConfigByName = () =>
  createSelector(selectCollections, item => item.get('getGatewayCanonicalConfigByName'));

const makeGetGatewayApiMapByName = () => createSelector(selectCollections, item => item.get('gatewayApiMapByName'));

const makeGetListIdSequenceConfig = () => createSelector(selectCollections, item => item.get('idSequenceConfig'));

const makeTenantDataConfig = () => createSelector(selectCollections, item => item.get('tenantDataConfig'));
const makeTenantProfileConfig = () => createSelector(selectCollections, item => item.get('tenantProfileConfig'));
const makeTenantMerchantDataConfig = () =>
  createSelector(selectCollections, item => item.get('tenantMerchantDataConfig'));
const makeGetTenantDataGateway = () => createSelector(selectCollections, item => item.get('tenantDataGateway'));

export {
  makeTenantData,
  makeGetCcpProperties,
  makeGetPaymentMethodUrls,
  makeGetGatewayCanonicalConfigByName,
  makeGetGatewayApiMapByName,
  makeGetListIdSequenceConfig,
  makeTenantDataConfig,
  makeTenantProfileConfig,
  makeTenantMerchantDataConfig,
  makeGetTenantDataGateway,
};
