import gql from 'graphql-tag';

export default () => gql`
  query {
    getARItemConfig(dummy: "") {
      id
      type
      arTypeList {
        index
        arType
        itemId
        company
      }
    }
  }
`;
