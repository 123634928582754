import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderProvisioningInboundTasks(id: "${id}") {
      id
      provisioning {
        apiName
        status
        serviceTypes
      }
    }
  }
`;
