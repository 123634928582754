import React from 'react';
import PropTypes from 'prop-types';

const CardHeaderForm = ({ title, label, value, hideNavTab, wrapperClass }) => (
  <div>
    {title && (
      <div className="d-block w-100">
        <h5 className={`card-title title-form ${wrapperClass || ''}`} style={{ paddingBottom: '3px' }}>
          {title}
        </h5>
      </div>
    )}
    {!hideNavTab && (
      <div className="d-block d-md-flex nav-tabs-custom">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <div className="nav-link">{label}</div>
          </li>
          <li className="nav-item">
            <div className="nav-link">{value}</div>
          </li>
        </ul>
      </div>
    )}
  </div>
);

CardHeaderForm.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  hideNavTab: PropTypes.bool,
};

CardHeaderForm.defaultProps = {
  title: '',
  label: '',
  value: '',
  hideNavTab: false,
};
export default CardHeaderForm;
