import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, AreaCharts } from '../../../components/common';
import { getDashboardsByType } from '../actions';
import RouteNames from '../../App/RouteNames';
import { listNameTitleUserDashboard } from '../constants';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionRecognizedRevenue } from '../CheckPermission';
import { makeGetPermissionsDashboard } from '../../App/selectors';

class RecongnizedRevenue extends Component {
  state = { data: [] };

  componentDidMount() {
    const { getDashboardsByType } = this.props;
    getDashboardsByType({ type: 'RECOGNIZED_REVENUE' }, ({ success, data }) => {
      if (success) this.setState({ data });
    });
  }

  render() {
    const { data } = this.state;
    const { t, permissionsDashboard } = this.props;
    let modeRecongnizedRevenue = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeRecongnizedRevenue = checkPermissionRecognizedRevenue({ listPermission });
    }
    if (modeRecongnizedRevenue === 0) return '';
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('dashboardPage:sidebar.recognizedRevenue') }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="row mb-5">
          <AreaCharts
            key={data.name}
            title={t(listNameTitleUserDashboard.RECOGNIZED_REVENUE)}
            data={data && data.data ? cloneDeep(data.data) : []}
            wrapperClass="chart-item embrix-line-chart-type m-auto"
            subClass="animate__animated animate__zoomIn"
            keySeries="value"
            keyCategories="mon"
            background={defineBackground[data.name] ? defineBackground[data.name] : null}
            dataType="AMOUNT"
            isToolbar
            isDescription
            minWidthYAxis={100}
            offsetX={-30}
          />
        </div>
      </div>
    );
  }
}

RecongnizedRevenue.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

RecongnizedRevenue.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getDashboardsByType })(RecongnizedRevenue));
