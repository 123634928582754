export default [
  'unit',
  'frequency',
  'status',
  'startDuration',
  'startUnit',
  'endDuration',
  'endUnit',
  'discountingModel',
  'transactionType',
  'minimumQuantity',
  'maximumQuantity',
  'salesChannel',
  'marketSegment',
  'accountType',
  'accountSubType',
  'serviceType',
  'index',
  'refIndex',
  'amount',
  'isQuantityScalable',
  'cancelProration',
  'purchaseProration',
  'purchaseProration',
  'upgradeProration',
  'downgradeProration',
  'tierType',
  'tierReference',
  'volumeTreatment',
  'evaluationStart',
  'evaluationEnd',
  'offsetMonths',
  'nextDiscountingTerm',
  'maximum',
  'minimum',
  'grantDuration',
  'grantUnit',
  'duration',
  'timeUnit',
  'discountingType',
  'percent',
  'useAccumulatorBalance',
  'flatRate',
];
