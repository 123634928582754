import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class ChildrentAccountSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.firstName',
      name: 'firstName',
    },
    {
      label: 'label.lastName',
      name: 'lastName',
    },
    {
      label: 'label.city',
      name: 'city',
    },
    {
      label: 'label.state',
      name: 'state',
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'status',
      type: 'select',
      wrapperClass: 'drd-modal col-md-3',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

ChildrentAccountSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ChildrentAccountSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ChildrentAccountSearchForm);
