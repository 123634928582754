import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../../utils/utils';

export default ({ page, size, filter }) => {
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['planType', 'planStatus'])}` : '';
  const searchVoicePlan = `searchVoicePlan(page: ${page}, size: ${size}${filterStr})`;
  return gql`
    query {
      ${searchVoicePlan} {
        id
        type
        plans {
        index
        name
        planType
        status
        planId
        serialId
        searchLdiList {
          index
          name
          npaId
          region
          location
          country
          countryCode
        }
        searchLdnList {
          index
          name
          region
          location
          min
          max
        }
      }
    }
  }
  `;
};
