import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../PricingHub/SearchComponent';

class CountryCodesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.name',
      name: 'name',
    },
    {
      label: 'label.alpha2',
      name: 'alpha2',
    },
    {
      label: 'label.alpha3',
      name: 'alpha3',
    },
  ];

  btnSpan = 'col-md-3';
}

CountryCodesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CountryCodesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CountryCodesSearchForm);
