import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  DataTable,
  FormCollapse,
  FormWithTableItem,
  GenericInput,
  TablePagination,
  ModalWithItem,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import TEExpenseDataSearchForm from '../../../components/BillingHub/TEExpenseDataSearchForm';
import { searchExpenseData, createExpenseData, modifyExpenseData, getExpensesConfig } from '../actions';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsBilling } from '../../App/selectors';
import { checkPermissionForAllPages } from '../CheckPermission';
import {
  getLastIndex,
  supportRemoveIndexWithSize,
  compareArrayIndexValueChange,
  getPageTotalCount,
  validate,
} from '../../../utils/utils';

const dataSort = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
};

const collapsibleMenu = [
  {
    title: 'common:label.fixedExpenseTypes',
    state: 'fixedExpenseTypes',
  },
  {
    title: 'common:label.variableExpenseTypes',
    state: 'variableExpenseTypes',
  },
];

const TEExpenseData = ({
  searchExpenseData,
  createExpenseData,
  modifyExpenseData,
  permissionsBilling,
  getExpensesConfig,
}) => {
  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [activeTab, setActiveTab] = useState({});
  const [defaultData, setDefaultData] = useState(null);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [wasValidated, setValidate] = useState(false);
  const [defaultDataConfig, setDefaultDataConfig] = useState(false);

  const { t } = useTranslation('common');

  let permissions = {};

  if (permissionsBilling && permissionsBilling.billingModulePermissions) {
    const listPermission = permissionsBilling.billingModulePermissions;
    permissions = checkPermissionForAllPages({
      listPermission,
    });
  }

  const { modeCreateExpenseData, modeGetExpenseData, modeModifyExpenseData } = permissions;

  const isHasPermission = (isEdit && modeModifyExpenseData === 2) || (!isEdit && modeCreateExpenseData === 2);
  const doGetExpensesConfig = useCallback(() => {
    setLoading(true);
    searchExpenseData(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? dataSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if (success && data) {
          setData(data);
          setItemSelect(null);
          setActiveTab({});
          setDefaultData(null);
          setValidate(false);
        } else {
          setData([]);
          setItemSelect(null);
          setActiveTab({});
          setDefaultData(null);
          setValidate(false);
        }
      }
    );
  }, [filter, page, searchExpenseData, size, sorted]);

  const onChangeTable = ({ name, value, index, key }) => {
    const newData = cloneDeep(itemSelect);
    newData[key][index][name] = value;
    setItemSelect(newData);
  };

  const onChangeData = ({ name, value }) => {
    const newData = cloneDeep(itemSelect);
    newData[name] = value;
    setItemSelect(newData);
  };

  const onAddNewFixedExpenseData = () => {
    const newData = cloneDeep(itemSelect);
    if (!newData || !newData.fixedExpenseData) newData.fixedExpenseData = [];
    newData.fixedExpenseData.push({
      index: getLastIndex({ data: newData.fixedExpenseData }),
      name: null,
      amount: '0.0',
      isNew: true,
    });
    setItemSelect(newData);
  };

  const onAddNewVariableExpenseData = () => {
    const newData = cloneDeep(itemSelect);
    if (!newData || !newData.variableExpenseData) newData.variableExpenseData = [];
    newData.variableExpenseData.push({
      index: getLastIndex({ data: newData.variableExpenseData }),
      name: null,
      amount: '0.0',
      isNew: true,
    });
    setItemSelect(newData);
  };

  const onRemoveItem = ({ index, item, key }) => {
    try {
      const newData = cloneDeep(itemSelect);
      if (item.isNew) {
        newData[key].splice(index, 1);
        return setItemSelect(newData);
      }
      newData[key][index] = { index: newData[key][index].index };
      return setItemSelect(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  const fixedExpenseTypesColumns = [
    {
      name: 'type',
      label: 'label.name',
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.type}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'fixedExpenseData' })}
          name="type"
          required
        />
      ),
    },
    {
      name: 'amount',
      label: 'label.amount',
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.amount}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'fixedExpenseData' })}
          name="amount"
          type="number"
          required
        />
      ),
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="no-border"
            onClick={() => onRemoveItem({ index, item, key: 'fixedExpenseData' })}
            disabled={!isHasPermission}
          >
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const variableExpenseTypesColumns = [
    {
      name: 'type',
      label: 'label.name',
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.type}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'variableExpenseData' })}
          name="type"
          required
        />
      ),
    },
    {
      name: 'amount',
      label: 'label.amount',
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.amount}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'variableExpenseData' })}
          name="amount"
          type="number"
          required
        />
      ),
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="no-border"
            onClick={() => onRemoveItem({ index, item, key: 'variableExpenseData' })}
            disabled={!isHasPermission}
          >
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  const renderItem = item => {
    switch (item.state) {
      case 'fixedExpenseTypes':
        return (
          <div className="row col-md-12 p-2">
            <DataTable
              columns={fixedExpenseTypesColumns}
              data={itemSelect && itemSelect?.fixedExpenseData ? itemSelect.fixedExpenseData : []}
              isLoading={isLoading}
              isSupportRemoveIndex
            />
            {isHasPermission && (
              <button type="button" className="button button-border black x-small" onClick={onAddNewFixedExpenseData}>
                {`+ ${t('label.add')}`}
              </button>
            )}
          </div>
        );
      case 'variableExpenseTypes':
        return (
          <div className="row col-md-12 p-2">
            <DataTable
              columns={variableExpenseTypesColumns}
              data={itemSelect && itemSelect?.variableExpenseData ? itemSelect.variableExpenseData : []}
              isLoading={isLoading}
              isSupportRemoveIndex
            />
            {isHasPermission && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={onAddNewVariableExpenseData}
              >
                {`+ ${t('label.add')}`}
              </button>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const onSubmit = () => {
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    const payload = cloneDeep(itemSelect);
    const { fixedExpenseData, variableExpenseData, id, startDate, expensePeriodUnit, splitEvenly } = payload;

    if (isEdit) {
      return modifyExpenseData(
        {
          id,
          startDate: startDate || null,
          expensePeriodUnit: expensePeriodUnit || null,
          splitEvenly,
          fixedExpenseData: fixedExpenseData
            ? compareArrayIndexValueChange({
                defaultData: defaultData ? defaultData.fixedExpenseData : null,
                newData: supportRemoveIndexWithSize({ data: fixedExpenseData }),
              })
            : null,
          variableExpenseData: variableExpenseData
            ? compareArrayIndexValueChange({
                defaultData: defaultData ? defaultData.variableExpenseData : null,
                newData: supportRemoveIndexWithSize({ data: variableExpenseData }),
              })
            : null,
        },
        ({ success }) => {
          if (success) doGetExpensesConfig();
        }
      );
    }

    return createExpenseData(
      {
        startDate: startDate || null,
        expensePeriodUnit: expensePeriodUnit || null,
        splitEvenly,
        id: id || null,
        fixedExpenseData: fixedExpenseData
          ? fixedExpenseData.map(val => {
              const { index, isNew, ...rest } = val;
              return { ...rest };
            })
          : null,
        variableExpenseData: variableExpenseData
          ? variableExpenseData.map(val => {
              const { index, isNew, ...rest } = val;
              return { ...rest };
            })
          : null,
      },
      ({ success }) => {
        if (success) doGetExpensesConfig();
      }
    );
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) => (
        <button
          className="text-success no-border"
          type="button"
          onClick={() => {
            setItemSelect(item);
            setDefaultData(item);
            setIsEdit(true);
          }}
        >
          {item.id}
        </button>
      ),
    },
    {
      label: 'common:label.expensePeriodUnit',
      name: 'expensePeriodUnit',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:expensePeriodUnit')().find(val => val.value === item.expensePeriodUnit) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      sortable: true,
    },
    {
      name: 'id',
      label: 'label.view',
      render: (colName, item) => (
        <button
          className="btn btn-outline-success btn-sm"
          type="button"
          onClick={() => {
            setItemSelect(item);
            setDefaultData(item);
            setIsEdit(true);
          }}
        >
          {t('label.view')}
        </button>
      ),
    },
  ];

  const mainFileds = [
    {
      name: 'id',
      label: 'label.id',
    },
    {
      name: 'expensePeriodUnit',
      label: 'label.expensePeriodUnit',
      type: 'select',
      tOptions: 'selections:expensePeriodUnit',
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      type: 'date',
    },
  ];

  const onToggleModal = () => {
    setItemSelect(null);
    setActiveTab({});
  };

  const onAddNew = () => {
    if (!defaultDataConfig) {
      getExpensesConfig('', ({ success, data }) => {
        if (success && data) {
          setDefaultDataConfig(data);
          setItemSelect({
            splitEvenly: true,
            fixedExpenseData: data?.fixedExpenses
              ? data.fixedExpenses.map(val => {
                  const { type } = val;
                  return { type, amount: '0.0', isNew: true };
                })
              : null,
            variableExpenseData: data?.variableExpenses
              ? data.variableExpenses.map(val => {
                  const { type } = val;
                  return { type, amount: '0.0', isNew: true };
                })
              : null,
          });
          setIsEdit(false);
        } else {
          setItemSelect({ splitEvenly: true });
          setIsEdit(false);
        }
      });
    } else {
      setItemSelect({
        splitEvenly: true,
        fixedExpenseData: defaultDataConfig?.fixedExpenses
          ? defaultDataConfig.fixedExpenses.map(val => {
              const { type } = val;
              return { type, amount: '0.0' };
            })
          : null,
        variableExpenseData: defaultDataConfig?.variableExpenses
          ? defaultDataConfig.variableExpenses.map(val => {
              const { type } = val;
              return { type, amount: '0.0' };
            })
          : null,
      });
      setIsEdit(false);
    }
  };

  useEffect(() => {
    doGetExpensesConfig();
  }, [doGetExpensesConfig]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  if (!modeGetExpenseData) return null;

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoicingBilling.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
          { name: t('label.TEExpenseData') },
        ]}
      />
      <FormWithTableItem title="common:label.expenseData">
        <div className="card-body ml-2">
          <TEExpenseDataSearchForm onSubmit={onHandleSubmit} />
        </div>
      </FormWithTableItem>
      <FormWithTableItem>
        <div className="col-md-12 ml-3 mb-3">
          {modeCreateExpenseData === 2 && (
            <button type="button" className="button x-small" onClick={onAddNew}>
              {`+ ${t('label.add')}`}
            </button>
          )}
        </div>
        <DataTable
          isLoading={isLoading}
          onSort={onSortColumn}
          sorted={sorted}
          columns={tableColumns}
          data={data || []}
        />
      </FormWithTableItem>

      <div className="mb-30 mt-3">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalWithItem
        isOpen={!!itemSelect}
        onToggle={onToggleModal}
        modalTitle={isEdit ? t('label.TEData') : t('label.addTEExpenseData')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        <form ref={formRef} noValidate className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}>
          <div className="mb-2">
            <div className="col-md-12 row">
              {mainFileds.map(val => (
                <GenericInput
                  readOnly={val.name === 'id' && isEdit}
                  {...val}
                  value={itemSelect ? itemSelect[val.name] : null}
                  onChange={onChangeData}
                />
              ))}
            </div>
            <div className="pt-2">
              {collapsibleMenu.map(item => (
                <FormCollapse
                  key={item.title}
                  isActive={item.state === activeTab.name && activeTab.isActive}
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={onToggleTab}
                >
                  {renderItem(item)}
                </FormCollapse>
              ))}
            </div>
            <div className="col-md-12 mt-3">
              {isHasPermission && (
                <button type="button" onClick={onSubmit} className="button button-border x-small float-right">
                  {t('label.saveConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={onToggleModal}
                className="button button-border black x-small float-right mr-2"
              >
                {t('label.cancel')}
              </button>
            </div>
          </div>
        </form>
      </ModalWithItem>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  permissionsBilling: makeGetPermissionsBilling() || {},
});

TEExpenseData.propTypes = {
  searchExpenseData: PropTypes.func.isRequired,
  createExpenseData: PropTypes.func.isRequired,
  modifyExpenseData: PropTypes.func.isRequired,
  getExpensesConfig: PropTypes.func.isRequired,
  permissionsBilling: PropTypes.objectOf(PropTypes.any),
};

TEExpenseData.defaultProps = {
  permissionsBilling: {},
};

export default connect(mapStateToProps, {
  searchExpenseData,
  createExpenseData,
  modifyExpenseData,
  getExpensesConfig,
})(TEExpenseData);
