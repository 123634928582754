import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['status', 'index', 'costType']);
  const createCostCenter = `createCostCenter(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createCostCenter} {
      id
    }
  }
`;
};
