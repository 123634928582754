import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ ...filter }) => {
  const filterStr = filter ? convertFilterRequestUrl(filter, ['showActiveOnly']) : '';
  const getLegalEntity = `getLegalEntity(input: ${filterStr})`;
  return gql`
    query {
      ${getLegalEntity} {
        id
        index
        name
        description
        shortName
        code
        enterprise
        division
        registrationId
        reportingId
        taxId
        altId
        locationIndex
        status
        address {
          city
          state
          country
        }
      }
    }
  `;
};
