import React from 'react';
import PropsType from 'prop-types';
import { cloneDeep, groupBy, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GenericInput, FormCollapse } from '../common';

const OrderModifyProvisioningAttributesForm = ({ data, activeTab, onChange, onToggleTab, isDisabled }) => {
  const { t } = useTranslation('common');
  let newData = cloneDeep(data);
  if (newData && newData.length) {
    newData = newData.map((val, index) => ({ ...val, indexService: index }));
    newData = groupBy(newData, item => {
      return item.serviceType;
    });
  }
  return (
    <div className="col-md-12 mb-30">
      <div className="card card-statistics">
        <div className="card-body">
          {data && data.length > 0 ? (
            map(newData, (services, serviceType) => {
              let newConfigProvisioningAttributes = [];
              if (services && services.length) {
                services.forEach(val => {
                  const { configProvisioningAttributes } = val;
                  newConfigProvisioningAttributes = [
                    ...newConfigProvisioningAttributes,
                    ...configProvisioningAttributes.map((dt, index) => ({
                      ...dt,
                      indexService: val.indexService,
                      indexAttribute: index,
                    })),
                  ];
                });
              }
              const slt = t('selections:serviceType')().find(val => val.value === serviceType);
              return (
                <div className="col-md-12 border p-3 mt-3" key={`key-${serviceType}`}>
                  <h5 className="mb-2">{`${t('label.serviceType')}: ${slt ? slt.label : ''}`}</h5>
                  <div>
                    {newConfigProvisioningAttributes &&
                      newConfigProvisioningAttributes.length > 0 &&
                      newConfigProvisioningAttributes.map(att => {
                        const { attributesList } = att || {};
                        return (
                          <FormCollapse
                            key={att.id}
                            title={att.name}
                            isActive={
                              att.name === activeTab.name && activeTab.isActive && serviceType === activeTab.serviceType
                            }
                            onToggleTab={activeTabName => onToggleTab(activeTabName, serviceType)}
                            state={att.name}
                            wrapperClass="new-order-provisioning"
                            isSubCollapse
                            isActiveSubForm={
                              att.name === activeTab.name && activeTab.isActive && serviceType === activeTab.serviceType
                            }
                          >
                            <div className="col-md-12 row p-2">
                              {attributesList &&
                                attributesList.length > 0 &&
                                attributesList.map((item, indexItem) => {
                                  const { attributesLovs } = item || {};
                                  const options = attributesLovs?.length
                                    ? attributesLovs.map(val => ({
                                        label: val.code,
                                        value: val.code,
                                      }))
                                    : null;
                                  return (
                                    <GenericInput
                                      key={item.index}
                                      value={item.attributeValue}
                                      label={`${item.name || ' '}`}
                                      name={item.name}
                                      onChange={({ value }) =>
                                        onChange({
                                          value,
                                          indexService: att.indexService,
                                          indexAttribute: att.indexAttribute,
                                          indexItem,
                                        })
                                      }
                                      wrapperClass="form-group col-md-4"
                                      type={options ? 'select' : 'text'}
                                      options={options}
                                      disabled={isDisabled}
                                    />
                                  );
                                })}
                            </div>
                          </FormCollapse>
                        );
                      })}
                  </div>
                </div>
              );
            })
          ) : (
            <h6 className="col-md-12 text-center mb-4">{t('label.noRecord')}</h6>
          )}
        </div>
      </div>
    </div>
  );
};

OrderModifyProvisioningAttributesForm.propTypes = {
  data: PropsType.arrayOf(PropsType.any),
  activeTab: PropsType.objectOf(PropsType.any),
  onChange: PropsType.func.isRequired,
  onToggleTab: PropsType.func.isRequired,
  isDisabled: PropsType.bool,
};

OrderModifyProvisioningAttributesForm.defaultProps = {
  data: null,
  activeTab: {},
  isDisabled: false,
};

export default OrderModifyProvisioningAttributesForm;
