import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../components/common';
import { newSubscriptionsInfoField } from '../constants';

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { orderDetail, onHandleChange } = this.props;
    return (
      <div className="card card-statistics">
        <div className="card-body">
          <div className="group-flex pb-3">
            {newSubscriptionsInfoField.map(item => (
              <GenericInput
                key={item.name}
                value={orderDetail ? orderDetail[item.name] : null}
                onChange={({ name, value }) => onHandleChange({ name, value })}
                wrapperClass={item.wrapperClass || 'col-md-4'}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

OrderForm.propTypes = {};

export default withTranslation('common')(OrderForm);
