import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PasswordField } from '../common';

const PasswordForm = ({ isShowFieldPassword, data, onChange, onTogglePassword, wrapperClass, children }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${wrapperClass || 'col-md-12'} mb-30 form-container`}>
      <div className="card card-statistics" />
      <div className="col-md-12 d-flex mt-3">
        <div className="form-group btn-password">
          <button type="button" className=" button button-border x-small" onClick={onTogglePassword}>
            {t('label.resetPassword')}
          </button>
        </div>
        {isShowFieldPassword && (
          <PasswordField
            value={data.password || null}
            label={t('label.password')}
            onChange={onChange}
            name="password"
            wrapperClass="col-md-4"
          />
        )}
        {children}
      </div>
    </div>
  );
};

PasswordForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  isShowFieldPassword: PropTypes.bool,
  onChange: PropTypes.func,
  onTogglePassword: PropTypes.func,
  wrapperClass: PropTypes.string,
};

PasswordForm.defaultProps = {
  data: {},
  isShowFieldPassword: false,
  onChange: () => {},
  onTogglePassword: () => {},
  wrapperClass: '',
};

export default PasswordForm;
