import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'accountType',
    'paymentMethod',
    'objectType',
    'customerSegment',
    'sequenceSeed',
    'minimumLength',
    'index',
  ]);

  const modifyCustomDBSeq = `modifyCustomDBSeq(input: ${dataModify})`;
  return gql`
    mutation {
      ${modifyCustomDBSeq} {
        id
      }
    }
  `;
};
