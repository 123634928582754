import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class GLAccountSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.number',
      name: 'accountNumber',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'common:label.type',
      name: 'type',
    },
    {
      label: 'common:label.detailType',
      name: 'detailType',
    },
  ];

  btnSpan = 'col-md-12';
}

GLAccountSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

GLAccountSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(GLAccountSearchForm);
