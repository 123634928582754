import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class JobFailedSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.failureReason',
      name: 'failureReason',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.jobType',
      name: 'jobType',
      tOptions: 'selections:jobTypes',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-9';
}

JobFailedSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

JobFailedSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(JobFailedSearchForm);
