import React from 'react';
import PropsType from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

class ModalAccept extends React.Component {
  onAccept = () => {
    const { onAcceptDelete, onToggle} = this.props;
    onToggle();
    onAcceptDelete();
  };

  render() {
    const { isOpen, onToggle, title, t } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={onToggle}>
        <ModalBody>{title}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onToggle}>
            {t('label.no')}
          </Button>
          <Button color="primary" onClick={this.onAccept}>
            {t('label.yes')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalAccept.propTypes = {
  onAcceptDelete: PropsType.func.isRequired,
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
};

ModalAccept.defaultProps = {
  title: '',
};

export default withTranslation('common')(ModalAccept);
