import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles, saveOutputTemplateByType, getOutputTemplateByType } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { OnDemandSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../../components/common';
import { tabelColumnExportOnDemand, sortOnDemand, tabelColumnExportOnDemandDetails } from '../../constants';
import {
  getDataServiceReportData,
  getListAllDataServiceReportData,
  onResetDefaultData,
  getDataServiceReportDetails,
  getAllDataServiceReportDetails,
} from '../../actions';
import { makeGetListServiceReportData, makeGetListAllServiceReportData } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import ModalCallType from '../../../../components/common/ModalCallType';
import ModalSearchMultiOrganization from '../../../../components/common/ModalSearchMultiOrganization';
import ModalMultipleAccountIds from '../../../../components/common/ModalMultipleAccountIds';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let tableColumnsOutput = null;

let fileNameExport = '';

class OnDemand extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listOnDemand: [],
    listDataServiceReportDetails: [],
    listAllDataServiceReportDetails: [],
    isOpenModalDetails: false,
    isActivePdfDetails: false,
    itemSelect: null,
    pageDetails: 0,
    sizeDetails: 20,
    totalCountDetails: null,
  };

  buttonRef = React.createRef();

  buttonDetailsRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listOnDemand: props.listOnDemand,
      totalCount: getPageTotalCount({ ...state, items: props.listOnDemand }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetDataServiceReport },
      onResetDefaultData,
    } = this.props;
    if (modeGetDataServiceReport) {
      this.doGetOutputTemplateByType();
      onResetDefaultData('listServiceReportData');
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListOnDemand();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListOnDemand());
  };

  onPageChangeDetails = pageChange => {
    const { pageDetails } = this.state;
    if (pageDetails === pageChange) return '';
    return this.setState({ pageDetails: pageChange }, () => this.doGetDataServiceReportDetails());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListOnDemand());
  };

  onSizeChangeDetails = size => {
    this.setState({ sizeDetails: size, pageDetails: 0 }, () => this.doGetDataServiceReportDetails());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListOnDemand();
    });
  };

  doGetListOnDemand = () => {
    const { filter, page, size, sorted } = this.state;
    const { getDataServiceReportData } = this.props;
    if (!filter.startDate) {
      filter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!filter.endDate) {
      filter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) && sortOnDemand[sorted.sortCol] ? sortOnDemand[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getDataServiceReportData(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistOnDemand = cb => {
    const { filter, sorted } = this.state;
    const { getListAllDataServiceReportData } = this.props;
    if (!filter.startDate) {
      filter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!filter.endDate) {
      filter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter,
      sort: !isEmpty(sorted) ? sortOnDemand[sorted.sortCol][sorted.sortDir] : null,
    };
    getListAllDataServiceReportData(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onChangeSwitchDetails = () => {
    const { isActivePdfDetails } = this.state;
    this.setState({ isActivePdfDetails: !isActivePdfDetails });
  };

  onExport = () => {
    this.doGetAlllistOnDemand(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllOnDemand, t } = this.props;
        convertJson2Pdf({
          data: listAllOnDemand,
          t,
          title: t('reportPage:sidebar.onDemand').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.onDemand')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
          fontSize: 7,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  doGetDataServiceReportDetails = () => {
    const { pageDetails, sizeDetails, itemSelect, totalCountDetails } = this.state;
    const { getDataServiceReportDetails } = this.props;
    const payload = {
      page: pageDetails + 1,
      size: sizeDetails,
      filter: {
        provisioningId: itemSelect?.ipAddress,
        recordDate: itemSelect?.lineDate,
      },
    };
    this.setState({ isSearching: true });
    getDataServiceReportDetails(payload, ({ success, data }) => {
      this.setState({
        listDataServiceReportDetails: success ? data : [],
        isSearching: false,
        totalCountDetails: getPageTotalCount({
          page: pageDetails,
          size: sizeDetails,
          totalCount: totalCountDetails || data.length,
          items: data,
        }),
      });
    });
  };

  doGetAlllistOnDemandDetails = cb => {
    const { itemSelect } = this.state;
    const { getAllDataServiceReportDetails } = this.props;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: {
        provisioningId: itemSelect?.ipAddress,
        recordDate: itemSelect?.lineDate,
      },
    };
    getAllDataServiceReportDetails(payload, ({ success, data }) => {
      this.setState({ listAllDataServiceReportDetails: success ? data : [], isSearching: false }, () => {
        if (cb) cb();
      });
    });
  };

  onExportDetails = () => {
    this.doGetAlllistOnDemandDetails(() => {
      const { isActivePdfDetails, listAllDataServiceReportDetails } = this.state;
      if (isActivePdfDetails) {
        const { t } = this.props;
        convertJson2Pdf({
          data: listAllDataServiceReportDetails,
          t,
          title: fileNameExport?.toLocaleUpperCase(),
          fileName: `${fileNameExport?.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
            'YYYY_MM_DD'
          )}`,
          columnsTable: tabelColumnExportOnDemandDetails,
          fontSize: 7,
        });
      } else {
        this.buttonDetailsRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistOnDemand(() => {
      const { listAllOnDemand, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'DATA_SERVICE_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.onDemand')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.onDemand')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllOnDemand,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllOnDemand,
          t,
          title: t('reportPage:sidebar.onDemand').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.onDemand')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
          fontSize: 7,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.onDemand')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('DATA_CUSTOMER_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tabelColumnExportOnDemand,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  onToggleModalDetails = item => {
    const { isOpenModalDetails } = this.state;
    if (!isOpenModalDetails) {
      this.setState(
        {
          isOpenModalDetails: !isOpenModalDetails,
          itemSelect: item,
          totalCountDetails: null,
          pageDetails: 0,
          sizeDetails: 20,
          listDataServiceReportDetails: [],
          listAllDataServiceReportDetails: [],
          isActivePdfDetails: false,
        },
        () => {
          this.doGetDataServiceReportDetails();
          fileNameExport = item?.ipAddress || '';
        }
      );
    } else this.setState({ isOpenModalDetails: !isOpenModalDetails, itemSelect: null });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listOnDemand,
      emails,
      exportColumnsSave,
      isOpenModalExportConfig,
      isOpenModalDetails,
      listDataServiceReportDetails,
      listAllDataServiceReportDetails,
      isActivePdfDetails,
      totalCountDetails,
      pageDetails,
      sizeDetails,
    } = this.state;
    const {
      listAllOnDemand,
      t,
      permissions: { modeGetDataServiceReport, modeDownloadReport, modeGetMailReport },
    } = this.props;
    if (!modeGetDataServiceReport) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    const newTableColumns = [
      ...tableColumnsOutput,
      {
        name: 'details',
        label: 'common:label.details',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => {
              this.onToggleModalDetails(item);
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={[{ name: t('label.report'), url: RouteNames.report.path }, { name: t('reportPage:sidebar.onDemand') }]}
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.onDemand')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <OnDemandSearchForm
                  onSubmit={this.onHandleSubmitSearch}
                  ModalSearch={props => {
                    return (
                      <div>
                        <ModalCallType
                          isOpen={props.isOpen === 'usageType'}
                          onSelect={props.onSelect}
                          onCancel={props.onToggle}
                        />
                        <ModalSearchMultiOrganization
                          isOpen={props.isOpen === 'organization'}
                          onSelect={props.onSelect}
                          onCancel={props.onToggle}
                          selectFieldName="organization"
                          // childrenField="accountId"
                          // onSelectChild={props.onSelectChild}
                          // selectChildFieldName="id"
                        />
                        <ModalMultipleAccountIds
                          isOpen={props.isOpen === 'accountId'}
                          onSelect={props.onSelect}
                          onCancel={props.onToggle}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.onDemand').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllOnDemand,
                          t,
                          title: t('reportPage:sidebar.onDemand').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.onDemand')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listOnDemand}
                    />
                  )}
                </div>
                <DataTable
                  columns={newTableColumns}
                  data={listOnDemand && listOnDemand.length ? listOnDemand : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>

        <ModalWithItem
          onToggle={this.onToggleModalDetails}
          modalTitle={fileNameExport?.toLocaleUpperCase()}
          isOpen={isOpenModalDetails}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isSupportTopBackButton
        >
          <div className="d-flex float-right pb-3">
            <div className="ml-auto mt-2 mb-auto mr-3">
              <SwitchExport
                onChange={this.onChangeSwitchDetails}
                wrapperClass={isActivePdfDetails ? 'switch-active' : 'switch-non-active'}
                title={t('label.excel')}
                checked={isActivePdfDetails}
                rightTitle={t('label.pdf')}
                name="isActivePdfDetails"
              />
            </div>
            <>
              <ButtonExport
                onExport={() => this.onExportDetails({ colums: tabelColumnExportOnDemandDetails, fileNameExport })}
              />
              <ExcelExport
                element={<button type="button" className="display-none" ref={this.buttonDetailsRef} />}
                nameSheet={fileNameExport?.toLocaleUpperCase()}
                multiDataSet={convertJson2Sheet({
                  data: listAllDataServiceReportDetails || [],
                  t,
                  title: fileNameExport?.toLocaleUpperCase(),
                  columnsTable: tabelColumnExportOnDemandDetails,
                })}
                fileName={`${fileNameExport?.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
                  'YYYY_MM_DD'
                )}`}
              />
            </>
          </div>

          <DataTable
            isFixedHeaderTable
            columns={tabelColumnExportOnDemandDetails}
            data={listDataServiceReportDetails || []}
            onSort={this.onSortColumn}
            isLoading={isSearching}
          />
          <br />
          <div className="mb-15">
            <TablePagination
              pageNumber={pageDetails}
              pageSize={sizeDetails}
              totalCount={totalCountDetails}
              onPageChange={this.onPageChangeDetails}
              onSizeChange={this.onSizeChangeDetails}
            />
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

OnDemand.propTypes = {
  getDataServiceReportData: PropTypes.func,
  getListAllDataServiceReportData: PropTypes.func,
  listAllOnDemand: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

OnDemand.defaultProps = {
  getDataServiceReportData: () => {},
  getListAllDataServiceReportData: () => {},
  listAllOnDemand: [],
};

const mapStateToProps = createStructuredSelector({
  listOnDemand: makeGetListServiceReportData() || [],
  listAllOnDemand: makeGetListAllServiceReportData() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getDataServiceReportData,
    getListAllDataServiceReportData,
    uploadMultiPartFiles,
    saveOutputTemplateByType,
    getOutputTemplateByType,
    onResetDefaultData,
    getDataServiceReportDetails,
    getAllDataServiceReportDetails,
  })(OnDemand)
);
