import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import quoteNS from './constants/quoteNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  if (!newData.salesAgentId) newData.salesAgentId = getUserId() || null;
  const dataCreate = parseToMutationRequest(newData, quoteNS);
  const saveQuote = `saveQuote(input: ${dataCreate})`;
  const qqlStr = gql`
  mutation {
    ${saveQuote} {
      id
    }
  }
`;
  return qqlStr;
};
