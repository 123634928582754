import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { DataTable, GenericInput, ModalUploadFile, CollapsibleTable } from '../../../components/common';
import {
  getLastIndex,
  validate,
  getBase64,
  parseToMutationRequestPostMethod,
  // supportRemoveIndexWithSize,
  // compareArrayIndexValueChange,
} from '../../../utils/utils';
import { uploadMultiPartFiles, readFileFromS3 } from '../../App/actions';
import { makeGetS3Config } from '../../App/selectors';
import { countryList } from '../../../constantsApp/countryList';
import { getTemplate, createTemplate, modifyTemplate } from '../actions';

const invoiceTemplateObjType = ['INVOICE', 'LADING_BILL', 'TRANSFER_DOCUMENT', 'RETENTION'];
const creditNoteTemplateObjType = ['CREDIT_NOTE'];
const debitNoteTemplateObjType = ['DEBIT_NOTE'];
const paymentTemplateObjType = ['PAYMENT', 'PAYMENT_AGREEMENT', 'PAYMENT_INSTALLMENT_REPORT'];

const newRow = ({ data }) => ({
  index: getLastIndex({ data }),
  filePath: null,
  type: null,
  fileType: null,
  status: null,
  country: null,
});

const Templates = ({
  getTemplate,
  createTemplate,
  modifyTemplate,
  readFileFromS3,
  s3Config,
  uploadMultiPartFiles,
  modeModifyPropertyDefaults,
}) => {
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [base64FileSelect, setBase64FileSelect] = useState(null);
  const [fileSelect, setFileSelect] = useState(null);
  const [isOpenModalUploadFile, setIsOpenModalUploadFile] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [activeTabTable, setActiveTabTable] = useState(-1);
  const [isSubTable, setIsSubTable] = useState(false);
  // const [defaultData, setDefaultData] = useState(false);

  const { t } = useTranslation('common');

  const onChangeTable = ({ name, value, index }) => {
    const newData = cloneDeep(submitData);
    try {
      newData.templates[index][name] = value || null;
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onAddNew = () => {
    const newData = cloneDeep(submitData);
    if (!newData || !newData.templates) newData.templates = [];
    newData.templates = [newRow({ data: newData.templates }), ...newData.templates];
    setSubmitData(newData);
  };

  const addSupplementaryFile = () => {
    try {
      const newData = cloneDeep(submitData);
      if (!newData.templates[activeTabTable].supplementaryFiles)
        newData.templates[activeTabTable].supplementaryFiles = [];
      newData.templates[activeTabTable].supplementaryFiles.push({
        index: getLastIndex({ data: newData.templates[activeTabTable].supplementaryFiles }),
        description: null,
        filePath: null,
      });
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSubTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(submitData);
      if (!newData.templates[activeTabTable].supplementaryFiles)
        newData.templates[activeTabTable].supplementaryFiles = [];
      newData.templates[activeTabTable].supplementaryFiles[index][name] = value;
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleModalUpload = ({ itemSelect, index, isSubTable }) => {
    if (itemSelect.filePath) {
      readFileFromS3({ ...s3Config, url: itemSelect.filePath }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            setBase64FileSelect(result);
            setIsOpenModalUploadFile(true);
          });
          setFileSelect(data);
          setItemSelect(itemSelect);
          setIndexSelect(index);
        }
      });
    } else {
      setIsOpenModalUploadFile(true);
      setItemSelect(itemSelect);
      setIndexSelect(index);
      setIsSubTable(!!isSubTable);
    }
  };

  const onToggleTableTab = indexItem => {
    if (activeTabTable === indexItem) return setActiveTabTable(-1);
    if (activeTabTable !== indexItem) {
      return setActiveTabTable(indexItem);
    }
    return setActiveTabTable(-1);
  };

  const tableDetailsColumns = [
    {
      name: 'description',
      label: 'label.description',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeSubTable({ name, value, index })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'filePath',
      label: 'label.filePath',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.filePath}
          wrapperClass="col-md-12"
          onClick={() => onToggleModalUpload({ itemSelect: item || {}, index, isSubTable: true })}
          name="filePath"
          fa="fa fa-external-link"
        />
      ),
    },
  ];

  const tableTemplatesColumns = [
    {
      name: 'type',
      label: 'label.objectType',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.type}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="type"
          type="select"
          menuPortalTarget
          tOptions="selections:templateType"
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'fileType',
      label: 'label.fileType',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.fileType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="fileType"
          type="select"
          menuPortalTarget
          tOptions="selections:templateFileType"
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'name',
      label: 'label.name',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="name"
          type="text"
          required
        />
      ),
    },
    {
      name: 'filePath',
      label: 'label.filePath',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.filePath}
          wrapperClass="col-md-12"
          onClick={() => onToggleModalUpload({ itemSelect: item || {}, index })}
          name="filePath"
          type="textarea"
          fa="fa fa-external-link"
          // readOnly
          required
          disabled={!item.type}
        />
      ),
    },
    {
      name: 'country',
      label: 'label.country',
      style: { minWidth: '120px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.country}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="country"
          type="select"
          options={countryList}
          menuPortalTarget
        />
      ),
    },
    {
      name: 'status',
      label: 'label.status',
      style: { minWidth: '120px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.status}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="status"
          type="select"
          menuPortalTarget
          tOptions="selections:templateFileStatus"
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'supplementaryFiles',
      label: 'label.supplementaryFiles',
      render: (colName, item, idx, indexParent, activeTab) => (
        <button
          type="button"
          className="btn-expand-table mr-3"
          disabled={!submitData.templates || !submitData.templates[idx].type || !submitData.templates[idx].filePath}
          onClick={() => onToggleTableTab(idx)}
        >
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
  ];

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    const newPayload = cloneDeep(submitData);
    if (newPayload && newPayload.id)
      return modifyTemplate(
        {
          id: newPayload.id,
          // templates: newPayload.templates
          //   ? compareArrayIndexValueChange({
          //       defaultData: defaultData?.templates ? defaultData.templates : null,
          //       newData: supportRemoveIndexWithSize({ data: newPayload.templates }),
          //     })
          //   : null,
          templates: newPayload.templates || null,
        },
        ({ success, data }) => {
          if (success) setSubmitData(data || {});
        }
      );

    let newTemp = cloneDeep(newPayload.templates);
    if (newTemp && newTemp.length) {
      newTemp = newTemp.map(val => {
        const { supplementaryFiles, ...rest } = val;
        let newSupplementaryFiles = null;
        if (supplementaryFiles && supplementaryFiles.length) {
          newSupplementaryFiles = supplementaryFiles.map(sp => {
            const { index, ...rs } = sp;
            return rs;
          });
        }
        return { ...rest, supplementaryFiles: newSupplementaryFiles };
      });
    }

    return createTemplate({ templates: newTemp || null }, ({ success, data }) => {
      if (success) setSubmitData(data || {});
    });
  };

  const onCancel = () => {
    setBase64FileSelect(null);
    setFileSelect(null);
    setIsOpenModalUploadFile(false);
    setItemSelect(null);
  };

  const onHandleUpload = ({ files }) => {
    if (files && files[0]) {
      let tenantSubType = null;
      const newItemSelect = isSubTable ? cloneDeep(submitData.templates[activeTabTable]) : cloneDeep(itemSelect);
      if (newItemSelect && newItemSelect.type && invoiceTemplateObjType.includes(newItemSelect.type)) {
        tenantSubType = 'INVOICE_TEMPLATE';
      }
      if (newItemSelect && newItemSelect.type && creditNoteTemplateObjType.includes(newItemSelect.type)) {
        tenantSubType = 'CREDITNOTE_TEMPLATE';
      }
      if (newItemSelect && newItemSelect.type && debitNoteTemplateObjType.includes(newItemSelect.type)) {
        tenantSubType = 'DEBITNOTE_TEMPLATE';
      }
      if (newItemSelect && newItemSelect.type && paymentTemplateObjType.includes(newItemSelect.type)) {
        tenantSubType = 'PAYMENT_TEMPLATE';
      }
      const query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        { category: 'TENANT', tenantSubType: tenantSubType || null, name: files[0].name },
        ['category', 'tenantSubType']
      )}){absoluteFile}}"}`;
      const formData = new FormData();
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          if (res.data.data && res.data.data.data && res.data.data.data.uploadMultipartFiles) {
            try {
              const pathSelect = res.data.data.data.uploadMultipartFiles[0];
              const newData = cloneDeep(submitData);
              if (!isSubTable) newData.templates[indexSelect].filePath = pathSelect.absoluteFile;
              if (isSubTable)
                newData.templates[activeTabTable].supplementaryFiles[indexSelect].filePath = pathSelect.absoluteFile;
              setSubmitData(newData);
              setIsOpenModalUploadFile(false);
            } catch (error) {
              console.log(error);
            }
          }
          setIsOpenModalUploadFile(false);
        }
      });
    }
    setIsOpenModalUploadFile(false);
  };

  const doGetTemplate = useCallback(() => {
    getTemplate('', ({ success, data }) => {
      setSubmitData(success ? data || {} : {});
      // setDefaultData(data || null);
    });
  }, [getTemplate]);

  useEffect(() => {
    doGetTemplate();
  }, [doGetTemplate]);

  return (
    <div className="card card-statistics col-md-12">
      <form
        ref={formRef}
        noValidate
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        onSubmit={onSubmit}
      >
        <div className="col-md-12 pl-2 pr-2 pb-3 mt-4">
          {modeModifyPropertyDefaults === 2 && (
            <button type="button" className="button button-border black x-small" onClick={onAddNew}>
              +
              {t('label.addNew')}
            </button>
          )}
          {modeModifyPropertyDefaults === 2 && (
            <button type="submit" className="button button-border x-small float-right">
              {t('label.saveConfig')}
            </button>
          )}
          <button type="button" className="button button-border black x-small float-right" onClick={doGetTemplate}>
            {t('label.cancel')}
          </button>
        </div>
        <CollapsibleTable
          columns={tableTemplatesColumns}
          data={submitData && submitData.templates ? submitData.templates : []}
          isSupportRemoveIndex
          indexViewer={activeTabTable}
        >
          <div className="col-md-12 mb-3">
            <DataTable
              columns={tableDetailsColumns}
              data={
                submitData &&
                activeTabTable !== -1 &&
                submitData?.templates[activeTabTable] &&
                submitData?.templates[activeTabTable].supplementaryFiles
                  ? submitData?.templates[activeTabTable].supplementaryFiles
                  : []
              }
            />
            {modeModifyPropertyDefaults === 2 && (
              <button type="button" className="button button-border black x-small" onClick={addSupplementaryFile}>
                +
                {t('label.addSupplementaryFile')}
              </button>
            )}
          </div>
        </CollapsibleTable>
      </form>
      <ModalUploadFile
        title="label.uploadFile"
        isOpen={isOpenModalUploadFile}
        onCancel={onCancel}
        onUpload={onHandleUpload}
        itemSelect={itemSelect}
        base64FileSelect={base64FileSelect}
        fileSelect={fileSelect}
        type={itemSelect ? itemSelect.type : null}
        pathFile={itemSelect ? itemSelect.filePath : null}
        isSupportDownload
        isTopBtn
      />
    </div>
  );
};

Templates.propTypes = {
  readFileFromS3: PropTypes.func.isRequired,
  uploadMultiPartFiles: PropTypes.func.isRequired,
  getTemplate: PropTypes.func.isRequired,
  createTemplate: PropTypes.func.isRequired,
  modifyTemplate: PropTypes.func.isRequired,
  s3Config: PropTypes.objectOf(PropTypes.any),
};

Templates.defaultProps = {
  s3Config: {},
};

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
});

export default connect(mapStateToProps, {
  uploadMultiPartFiles,
  readFileFromS3,
  getTemplate,
  createTemplate,
  modifyTemplate,
})(Templates);
