import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
// import ProductFamily from './ProductFamily';
import Currency from './Resources/Currency';
import Grants from './Resources/Grants';
import Accumulators from './Resources/Accumulators';
import RateUnit from './UsageRating/RateUnit';
import RateUnitMap from './UsageRating/RateUnitMap';
import TimeUnit from './UsageRating/TimeUnit';
// import TaxCodeConfig from './TaxCodeConfig';
// import ThirdPartyTaxConfig from './ThirdPartyTaxConfig';
// import RegionMapping from './UsageRating/RegionMapping';
// import ZeroPriceUsage from './UsageRating/ZeroPriceUsage';
// import OperatorMap from './UsageRating/OperatorMap';
// import TrunkRules from './UsageRating/TrunkRules';
import UMO from './UsageRating/umo';
import UsageType from './UsageRating/UsageType';
import CountryCodes from './UsageRating/CountryCodes';
// import PNNList from './UsageRating/PNNList';
import PACProductCodes from './UsageRating/PACProductCodes';
import TopupOffers from './TopupOffers';
import ZoneUnit from './UsageRating/ZoneUnit';
import ZoneUnitModify from './UsageRating/ZoneUnit/ZoneUnitModify';
import ZoneUnitCreate from './UsageRating/ZoneUnit/ZoneUnitCreate';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class BaseConfigurations extends PureComponent {
  render() {
    const { permissionsPricing } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsPricing, listKeys: permissionList.baseConfigurations }))
      return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.currency.path} component={Currency} />
              <Route exact path={RouteNames.grants.path} component={Grants} />
              <Route exact path={RouteNames.accumulators.path} component={Accumulators} />
              <Route exact path={RouteNames.rateUnit.path} component={RateUnit} />
              <Route exact path={RouteNames.rateUnitMap.path} component={RateUnitMap} />
              <Route exact path={RouteNames.timeUnit.path} component={TimeUnit} />
              <Route exact path={RouteNames.unitOfMeasure.path} component={UMO} />
              <Route exact path={RouteNames.usageType.path} component={UsageType} />
              <Route exact path={RouteNames.countryCodes.path} component={CountryCodes} />
              <Route exact path={RouteNames.regulatoryProductCodes.path} component={PACProductCodes} />
              <Route exact path={RouteNames.zoneUnit.path} component={ZoneUnit} />
              <Route exact path={RouteNames.zoneUnitAdd.path} component={ZoneUnitCreate} />
              <Route exact path={RouteNames.zoneUnitEdit.path} component={ZoneUnitModify} />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsPricing = store.get('auth').toJS().permissionsApp.pricingHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsPricing: permissionsPricing.length
      ? permissionsPricing.filter(a => a.moduleName === 'PRICE_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'baseConfigurationsReducer', reducer });
const withSaga = injectSaga({ key: 'baseConfigurationsSaga', saga });

export default compose(withReducer, withSaga, withConnect)(BaseConfigurations);
