export default {
  customerHub: {
    main: 'Customer Hub',
    subMain: {
      customerManagement: 'Customer Management',
      quoteManagement: 'Quote Management',
      orderManagement: 'Order Management',
    },
  },
  pricingHub: {
    main: 'Pricing Hub',
    subMain: {
      baseConfigurations: 'Base Configurations',
      pricingManagement: 'Pricing Management',
      bundleManagement: 'Bundle Management',
      pricingCatalog:'Pricing Catalog'
    },
  },
  billingHub: {
    main: 'Billing Hub',
    subMain: {
      billing: 'Billing',
      taxation: 'Taxation',
      usage: 'Usage',
      invoicing: 'Invoicing',
    },
  },
  arHub: {
    main: 'AR Hub',
    subMain: {
      payments: 'Payments',
      collections: 'Collections',
      AROperations: 'AR Operations',
    },
  },
  operationsHub: {
    main: 'Operations Hub',
    subMain: {
      userManagement: 'User Management',
      jobsManagement: 'Jobs Management',
      correspondence: 'Correspondence',
      reports: 'Reports',
      dashboard: 'Dashboard',
      tenantManagement: 'Tenant Management',
      taskManagement: 'Task Management',
      deployment: 'Deployment',
      performanceTuning: 'Performance Tuning',
    },
  },

  // billingHub: {
  //   main: 'Billing Hub',
  // },

  revenueHub: {
    main: 'Revenue Hub',
    subMain: {
      configuration: 'Configuration',
      revenue: 'Revenue',
    },
  },

  selfCareHub: {
    main: 'Self Care Hub',
  },

  commonHub: {
    miscellaneous: 'Miscellaneous',
    UIDefaults: 'UI Defaults',
    subscriptionManagement: 'Subscription Management',
    provisioningManagement: 'Provisioning Management',
    default: 'Default',
    enablers: 'Enablers',
  },
};
