import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../PricingHub/SearchComponent';


class ReprocessingSuspendedFailedRecordsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
      defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
      defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
    },
    {
      label: 'common:label.batchidFileName',
      name: 'batchId',
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      label: 'common:label.errorCode',
      name: 'errorCode',
      type: 'select',
      tOptions: 'selections:errorCodeProcessService',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

ReprocessingSuspendedFailedRecordsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ReprocessingSuspendedFailedRecordsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ReprocessingSuspendedFailedRecordsSearchForm);
