import gql from 'graphql-tag';

export default ({ filter }) => {
  return gql`
  query {
    getPendingAccountMovementData(input: {accountId: "${filter.accountId}" type: ${filter.type || null}}) {
      type
      updateServiceCapacityOnMove
      effectiveDate
      notes
      moveAllAccountServices
      moveAllAccountProvisioningAttributes
      savedAccountMovementDataId
      userId
      fromAccount {
        accountId
        moveAllServices
        moveProvisioningAttributes
        clientId
        organization
        billables {
          omsInternalId
          serviceId
          serviceName
          quantity
          servicePrice
          updateServiceCapacity
          totalPrice
          moveProvisioningAttributes
          capacity
          uom
          provisioningMovementTypes {
            type
            provDataMoveType
            provDataList {
              provisioningId
              startRange
              endRange
            }
          }
        }
      }
      toAccount {
        accountId
        moveAllServices
        moveProvisioningAttributes
        clientId
        organization
        billables {
          omsInternalId
          serviceId
          serviceName
          quantity
          servicePrice
          updateServiceCapacity
          totalPrice
          moveProvisioningAttributes
          capacity
          uom
          provisioningMovementTypes {
            type
            provDataMoveType
            provDataList {
              provisioningId
              startRange
              endRange
            }
          }
        }
      }
    }
  }
`;
};
