import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles, saveOutputTemplateByType, getOutputTemplateByType } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { InternetOperatorDataCostSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../../components/common';
import { tabelColumnExportInternetOperatorDataCost, sortInternetOperatorDataCost } from '../../constants';
import { getInternetOperatorDataCost, getListAllInternetOperatorDataCost, onResetDefaultData } from '../../actions';
import { makeGetListInternetOperatorDataCost, makeGetListAllInternetOperatorDataCost } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelInternetOperatorDataCost';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfInternetOperatorDataCost';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let tableColumnsOutput = null;

class InternetOperatorDataCost extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listInternetOperatorDataCost: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listInternetOperatorDataCost: props.listInternetOperatorDataCost,
      totalCount: getPageTotalCount({ ...state, items: props.listInternetOperatorDataCost }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetInternetOperatorDataCost },
      onResetDefaultData,
    } = this.props;
    if (modeGetInternetOperatorDataCost) {
      this.doGetOutputTemplateByType();
      onResetDefaultData('listInternetOperatorDataCost');
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListInternetOperatorDataCost();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListInternetOperatorDataCost());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListInternetOperatorDataCost());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListInternetOperatorDataCost();
    });
  };

  doGetListInternetOperatorDataCost = () => {
    const { filter, page, size, sorted } = this.state;
    const { getInternetOperatorDataCost } = this.props;
    if (!filter.startDate) {
      filter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!filter.endDate) {
      filter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const payload = {
      page: page + 1,
      size,
      filter,
      sort:
        !isEmpty(sorted) && sortInternetOperatorDataCost[sorted.sortCol]
          ? sortInternetOperatorDataCost[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getInternetOperatorDataCost(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistInternetOperatorDataCost = cb => {
    const { filter, sorted } = this.state;
    const { getListAllInternetOperatorDataCost } = this.props;
    if (!filter.startDate) {
      filter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!filter.endDate) {
      filter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter,
      sort: !isEmpty(sorted) ? sortInternetOperatorDataCost[sorted.sortCol][sorted.sortDir] : null,
    };
    getListAllInternetOperatorDataCost(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistInternetOperatorDataCost(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllInternetOperatorDataCost, t } = this.props;
        convertJson2Pdf({
          data: listAllInternetOperatorDataCost,
          t,
          title: t('reportPage:sidebar.internetOperatorDataCost').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.internetOperatorDataCost')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistInternetOperatorDataCost(() => {
      const { listAllInternetOperatorDataCost, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'INTERNET_OPERATOR_DATA_COST_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.internetOperatorDataCost')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.internetOperatorDataCost')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllInternetOperatorDataCost,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllInternetOperatorDataCost,
          t,
          title: t('reportPage:sidebar.internetOperatorDataCost').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.internetOperatorDataCost')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.internetOperatorDataCost')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('DATA_OPERATOR_COST_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tabelColumnExportInternetOperatorDataCost,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listInternetOperatorDataCost,
      emails,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const {
      listAllInternetOperatorDataCost,
      t,
      permissions: { modeGetInternetOperatorDataCost, modeDownloadReport, modeGetMailReport },
    } = this.props;
    if (!modeGetInternetOperatorDataCost) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={[
            { name: t('label.report'), url: RouteNames.report.path },
            { name: t('reportPage:sidebar.internetOperatorDataCost') },
          ]}
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.internetOperatorDataCost')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <InternetOperatorDataCostSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.internetOperatorDataCost').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllInternetOperatorDataCost,
                          t,
                          title: t('reportPage:sidebar.internetOperatorDataCost').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.internetOperatorDataCost')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listInternetOperatorDataCost}
                    />
                  )}
                </div>
                <DataTable
                  columns={tableColumnsOutput}
                  data={
                    listInternetOperatorDataCost && listInternetOperatorDataCost.length
                      ? listInternetOperatorDataCost
                      : []
                  }
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

InternetOperatorDataCost.propTypes = {
  getInternetOperatorDataCost: PropTypes.func,
  getListAllInternetOperatorDataCost: PropTypes.func,
  listAllInternetOperatorDataCost: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
};

InternetOperatorDataCost.defaultProps = {
  getInternetOperatorDataCost: () => {},
  getListAllInternetOperatorDataCost: () => {},
  listAllInternetOperatorDataCost: [],
};

const mapStateToProps = createStructuredSelector({
  listInternetOperatorDataCost: makeGetListInternetOperatorDataCost() || [],
  listAllInternetOperatorDataCost: makeGetListAllInternetOperatorDataCost() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getInternetOperatorDataCost,
    getListAllInternetOperatorDataCost,
    uploadMultiPartFiles,
    saveOutputTemplateByType,
    getOutputTemplateByType,
    onResetDefaultData,
  })(InternetOperatorDataCost)
);
