import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({
  t,
  monthOne,
  monthTwo,
  monthThree,
  monthFour,
  monthFive,
  monthSix,
  monthSeven,
  monthEight,
  monthNine,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterOne,
  quarterTwo,
  quarterThree,
  quarterFour,
}) => [
  // { content: t('label.employeeId'), colSpan: 2, },
  t('label.employeeId'),
  t('label.employeeName'),
  t('label.jobTitle'),
  t('label.status'),
  t('label.clientId'),
  t('label.clientName'),
  t('label.clientJobTitle'),
  monthOne,
  monthTwo,
  monthThree,
  quarterOne,
  monthFour,
  monthFive,
  monthSix,
  quarterTwo,
  monthSeven,
  monthEight,
  monthNine,
  quarterThree,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterFour,
  monthOne,
  monthTwo,
  monthThree,
  quarterOne,
  monthFour,
  monthFive,
  monthSix,
  quarterTwo,
  monthSeven,
  monthEight,
  monthNine,
  quarterThree,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterFour,
  monthOne,
  monthTwo,
  monthThree,
  quarterOne,
  monthFour,
  monthFive,
  monthSix,
  quarterTwo,
  monthSeven,
  monthEight,
  monthNine,
  quarterThree,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterFour,
  monthOne,
  monthTwo,
  monthThree,
  quarterOne,
  monthFour,
  monthFive,
  monthSix,
  quarterTwo,
  monthSeven,
  monthEight,
  monthNine,
  quarterThree,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterFour,
  monthOne,
  monthTwo,
  monthThree,
  quarterOne,
  monthFour,
  monthFive,
  monthSix,
  quarterTwo,
  monthSeven,
  monthEight,
  monthNine,
  quarterThree,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterFour,
  monthOne,
  monthTwo,
  monthThree,
  quarterOne,
  monthFour,
  monthFive,
  monthSix,
  quarterTwo,
  monthSeven,
  monthEight,
  monthNine,
  quarterThree,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterFour,
];

const convertJson2Pdf = ({
  data,
  t,
  title,
  fileName,
  monthOne,
  monthTwo,
  monthThree,
  monthFour,
  monthFive,
  monthSix,
  monthSeven,
  monthEight,
  monthNine,
  monthTen,
  monthEleven,
  monthTwelve,
  quarterOne,
  quarterTwo,
  quarterThree,
  quarterFour,
  ...rest
}) => {
  const Header = cloneDeep(
    HeaderTable({
      t,
      monthOne,
      monthTwo,
      monthThree,
      monthFour,
      monthFive,
      monthSix,
      monthSeven,
      monthEight,
      monthNine,
      monthTen,
      monthEleven,
      monthTwelve,
      quarterOne,
      quarterTwo,
      quarterThree,
      quarterFour,
    })
  );
  const mainHeader = [
    { content: '', colSpan: 7 },
    { content: t('label.grossSalaryMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.grossSalaryMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.grossSalaryMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.grossSalaryMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actualCostMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actualCostMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actualCostMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actualCostMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueUSD'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueUSD'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueUSD'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueUSD'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.revenueMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actIncomeMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actIncomeMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actIncomeMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.actIncomeMxn'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.salaryToBilling'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.salaryToBilling'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.salaryToBilling'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
    { content: t('label.salaryToBilling'), colSpan: 4, styles: { halign: 'center', cellWidth: 7 } },
  ];
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ content: item.employeeId ? item.employeeId : '' });
      newItem.push({ content: item.employeeName ? item.employeeName : '' });
      newItem.push({ content: item.jobTitle ? item.jobTitle : '' });
      newItem.push({ content: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'status' }) });
      newItem.push({ content: item.clientId ? item.clientId : '' });
      newItem.push({ content: item.clientName ? item.clientName : '' });
      newItem.push({ content: item.clientJobTitle ? item.clientJobTitle : '' });
      newItem.push({ content: formatMoneyValueToExport(item?.monthOneGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwoGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthThreeGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterOneGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFourGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFiveGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthSixGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterTwoGrossSal) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthSevenGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthEightGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthNineGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterThreeGrossSal) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthTenGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthElevenGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwelveGrossSal) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterFourGrossSal) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthOneActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwoActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthThreeActualacost) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterOneActualCost) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthFourActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFiveActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthSixActualacost) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterTwoActualCost) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthSevenActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthEightActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthNineActualacost) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterThreeActualCost) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthTenActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthElevenActualCost) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwelveActualacost) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterFourActualCost) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthOneRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwoRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthThreeRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterOneRevenueUsd) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthFourRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFiveRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthSixRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterTwoRevenueUsd) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthSevenRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthEightRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthNineRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterThreeRevenueUsd) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthTenRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthElevenRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwelveRevenueUsd) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterFourRevenueUsd) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthOneRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwoRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthThreeRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterOneRevenueMxn) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthFourRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFiveRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthSixRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterTwoRevenueMxn) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthSevenRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthEightRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthNineRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterThreeRevenueMxn) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthTenRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthElevenRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwelveRevenueMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterFourRevenueMxn) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthOneActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwoActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthThreeActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterOneActualIncome) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthFourActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFiveActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthSixActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterTwoActualIncome) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthSevenActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthEightActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthNineActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterThreeActualIncome) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthTenActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthElevenActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwelveActualIncomeMxn) });
      newItem.push({ content: formatMoneyValueToExport(item?.totalQuarterFourActualIncome) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthOneSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwoSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthThreeSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.averageQuarterOneSalaryToBilling) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthFourSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthFiveSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthSixSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.averageQuarterTwoSalaryToBilling) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthSevenSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthEightSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthNineSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.averageQuarterThreeSalaryToBilling) });

      newItem.push({ content: formatMoneyValueToExport(item?.monthTenSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthElevenSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.monthTwelveSalaryToBilling) });
      newItem.push({ content: formatMoneyValueToExport(item?.averageQuarterFourSalaryToBilling) });
    }
    return newItem;
  });
  return pdfConfig({
    data: newListData,
    Header,
    mainHeader,
    title,
    fileName,
    fontSize: 1,
    cellPadding: 1,
    cellWidth: 'wrap',
    ...rest,
  });
};

export default convertJson2Pdf;
