import gql from 'graphql-tag';
import { parseToMutationRequestUser } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequestUser(data, [
    'status',
    'type',
    'index',
  ]);
  const modifyUser = `modifyUser(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyUser} {
      id
      userId
    }
  }
`;
};
