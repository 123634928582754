/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectRateUnitId } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { InvoiceSearchForm } from '../../PaymentHub';

class ModalRateUnit extends PureComponent {
  searchHanldeRateUnitId = (payload, cb) => {
    const { selectRateUnitId } = this.props;
    selectRateUnitId(payload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'code',
          label: 'common:label.rateUnit',
        },
        {
          name: 'unit',
          label: 'common:label.unit',
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
        {
          name: 'isQuantityScalable',
          label: 'common:label.isQuantityScalable',
          render: (colName, item, t) => {
            return <span>{item.isQuantityScalable === true ? 'TRUE' : 'FALSE'}</span>;
          },
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseItem')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeRateUnitId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="code"
      />
    );
  }
}

ModalRateUnit.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectRateUnitId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalRateUnit.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectRateUnitId })(ModalRateUnit));
