import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class InvoiceSearchFormAdditionalFilters extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.invoiceDate',
      name: 'invoiceDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.dueDate',
      name: 'dueDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.due',
      name: 'due',
      type: 'number',
    },
    {
      label: 'common:label.type',
      name: 'type',
      tOptions: 'selections:invoiceUnitType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.folioStatus',
      name: 'folioStatus',
      tOptions: 'selections:folioStatus',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.clientId',
      name: 'clientId',
    },
    {
      label: 'common:label.invoiceId',
      name: 'id',
    },
  ];
}

InvoiceSearchFormAdditionalFilters.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

InvoiceSearchFormAdditionalFilters.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(InvoiceSearchFormAdditionalFilters);
