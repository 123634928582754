import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ExpandTable, CardHeaderForm, GenericInput } from '../common';
import LineTable from './LinesTable';
import { makeGetCcpPropertiesConfig } from '../../containers/App/selectors';

class TableFormService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowHideLines: {},
    };
  }

  handleClickServicesFormBtn = index => {
    let { isShowHideLines } = this.state;
    if (isShowHideLines[index]) {
      isShowHideLines[index] = false;
    } else {
      isShowHideLines = {};
      isShowHideLines[index] = true;
    }
    this.setState({
      isShowHideLines,
    });
  };

  render() {
    const {
      services,
      isFetchLine,
      onChangeFormLine,
      addLine,
      positionTrash,
      onToggle,
      onRemoveLine,
      isRemoveTrashFromLine,
      isRenderTrashBinLineTable,
      t,
      isReadOnly,
      onHandleInitNewLines,
      isMultiSubscriptionEnabled,
      onChangeData,
      subscriptionsData,
      isReadMultiSubscriptionEnabled,
      subscriptionName,
      isModifyOrder,
      isPackage,
      onSubmitSuspendResume,
      isViewOnly,
      isSupportShowAddDiscount,
      doGetItemDetails,
      isSupportViewProvisioning,
      ccpPropertyList,
      isDisabledRemove,
    } = this.props;
    const { isShowHideLines } = this.state;
    const subscriptionColumns = [];
    const newOrderColumn = [];
    const provisioningDataColumn = [];

    const provisioningIdRequired =
      ccpPropertyList && ccpPropertyList.length
        ? ccpPropertyList.find(val => val.property === 'provisioningIdRequired')
        : null;

    if (provisioningIdRequired && provisioningIdRequired.value === 'true') {
      provisioningDataColumn.push({
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        style: { width: '220px' },
        render: (colName, item, index) => {
          const { onChangeProvisioningId, services } = this.props;
          const indexRenderInput = services.findIndex(item => item.action !== 'CANCEL');
          if (index === indexRenderInput) {
            return (
              <GenericInput
                key="provisioningId"
                value={item.provisioningId}
                wrapperClass="col-md-12"
                type="text"
                onChange={({ name, value }) => onChangeProvisioningId({ name, value, item })}
                name="provisioningId"
                readOnly={(isReadOnly && item.isDeafultService) || isViewOnly}
              />
            );
          }
          return <span>{item.provisioningId}</span>;
        },
      });
    }

    if (isSupportShowAddDiscount) {
      newOrderColumn.push({
        name: 'alaCarte',
        label: 'common:label.alaCarte',
        className: 'fixed-width-table',
        render: (colName, item, index, t) => {
          return <span>{item.isDiscount ? t('label.discountOffers') : t('label.priceOffers')}</span>;
        },
      });
    }

    if (isSupportViewProvisioning) {
      provisioningDataColumn.push({
        name: 'view',
        label: 'label.view',
        render: (colName, item, index) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => doGetItemDetails({ item, index })}
          >
            {t('label.view')}
          </button>
        ),
      });
    }
    const suspendResumeColumn = [];
    if (isMultiSubscriptionEnabled) {
      subscriptionColumns.push(
        isReadMultiSubscriptionEnabled
          ? {
              name: 'subscriptionName',
              label: 'common:label.subscriptionName',
              className: 'fixed-width-table',
              required: true,
              render: (colName, item) => {
                return subscriptionName ? <span>{subscriptionName}</span> : <span>{item.subscriptionName || ''}</span>;
              },
            }
          : {
              name: 'subscriptionName',
              label: 'common:label.subscriptionName',
              style: { width: '180px' },
              required: true,
              render: (colName, item, index) => {
                return (
                  <GenericInput
                    value={item.subscriptionName}
                    wrapperClass="col-md-12"
                    type="select"
                    onChange={e => onChangeData(index, e)}
                    name="subscriptionName"
                    options={
                      subscriptionsData && subscriptionsData.length
                        ? subscriptionsData.map(val => ({ label: val.subscriptionName, value: val.subscriptionName }))
                        : []
                    }
                    menuPortalTarget
                    required
                  />
                );
              },
            }
      );
    }
    if (isModifyOrder) {
      suspendResumeColumn.push({
        name: 'selected',
        label: 'label.suspendResume',
        style: { width: '100px' },
        render: (colName, item, index, t) => {
          let type = null;
          let label = null;
          if (item.action === 'RESUME' || item.action === 'SUSPEND') {
            type = 'MODIFY';
            label = 'label.undo';
          } else if (item.status === 'ACTIVE') {
            type = 'SUSPEND';
            label = 'label.suspend';
          } else {
            type = 'RESUME';
            label = 'label.resume';
          }
          return (
            <button
              type="button"
              className="button button-border x-small"
              disabled={(item.status !== 'ACTIVE' && item.status !== 'SUSPENDED') || item.isPackage}
              onClick={() => {
                onSubmitSuspendResume({ item, type });
              }}
            >
              {t(label)}
            </button>
          );
        },
      });
    }

    const tableColumns = [
      ...provisioningDataColumn,
      {
        name: 'action',
        label: 'common:label.action',
        className: 'fixed-width-table',
      },
      {
        name: 'status',
        label: 'common:label.status',
        className: 'fixed-width-table',
        render: (colName, item, index, t) => {
          const slt = t
            ? t(isModifyOrder ? 'selections:subscriptionStatus' : 'selections:statusOrder')().find(
                val => val.value === item.status
              )
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
        className: 'fixed-width-table',
      },
      {
        name: 'packageName',
        label: 'common:label.packageName',
        className: 'fixed-width-table',
      },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
        className: 'fixed-width-table',
      },
      ...newOrderColumn,
      ...subscriptionColumns,
      {
        name: 'selected',
        label: '',
        style: { width: '100px' },
        render: (colName, item, index, t) => {
          const { isShowHideLines } = this.state;
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="button button-border  x-small"
                disabled={item.action === 'CANCEL' || (isReadOnly && item.isDeafultService)}
                onClick={() => {
                  this.handleClickServicesFormBtn(index);
                  if (onHandleInitNewLines) onHandleInitNewLines({ item, index });
                }}
              >
                {isShowHideLines[index] && t ? t('label.hideLines') : t('label.overrideLines')}
              </button>
            </div>
          );
        },
      },
      ...suspendResumeColumn,
      {
        name: 'remove',
        label: 'common:label.remove',
        className: 'fixed-width-table',
        render: (colName, item) => {
          const { onRemoveItem } = this.props;
          return (
            <button
              type="button"
              className="btn-phone"
              disabled={item.action === 'CANCEL' || (isReadOnly && item.isDeafultService) || isDisabledRemove}
              onClick={() => {
                this.setState({ isShowHideLines: {} });
                onRemoveItem(item);
              }}
            >
              <i className="fa fa-trash" />
            </button>
          );
        },
      },
    ];

    const tableConfig = {
      columns: tableColumns,
      data: services,
      sorted: {},
      onSort: this.onSortColumn,
    };
    const slt = t('selections:serviceType')().find(val => val.value === services[0].serviceType);
    return (
      <div className="col-md-12 pb-0">
        <CardHeaderForm
          title={`${t('label.serviceType')}: ${slt ? slt.label : ''}`}
          wrapperClass="font-weight-normal"
          hideNavTab
        />
        <ExpandTable
          {...tableConfig}
          onClickRow={this.onClickRow}
          isExpand={cloneDeep(isShowHideLines)}
          positionTrash={positionTrash}
          isReadOnly={isReadOnly || isViewOnly}
          tableClass="service-table"
          expandRender={(item, index) => {
            return (
              <LineTable
                key="TableFormLine"
                service={item}
                isFetchLine={isFetchLine}
                isRemoveTrashFromLine={isRemoveTrashFromLine}
                addLine={e => addLine(index, e)}
                positionTrash={positionTrash}
                isRenderTrashBinLineTable={isRenderTrashBinLineTable}
                onToggle={onToggle}
                onRemoveLine={({ itemLine }) => onRemoveLine({ itemLine, itemService: item })}
                onChangeFormLine={e => onChangeFormLine(index, e)}
                isReadOnly={(isReadOnly && item.isDeafultService) || isViewOnly}
                isModifyOrder={isModifyOrder}
              />
            );
          }}
        />
      </div>
    );
  }
}

TableFormService.propTypes = {
  services: PropTypes.arrayOf(PropTypes.any),
  isFetchLine: PropTypes.bool,
  positionTrash: PropTypes.bool,
  onChangeFormLine: PropTypes.func,
  addLine: PropTypes.func,
  onToggle: PropTypes.func,
  onChangeProvisioningId: PropTypes.func,
  onRemoveLine: PropTypes.func,
  onRemoveItem: PropTypes.func,
  isRemoveTrashFromLine: PropTypes.bool,
  isRenderTrashBinServiceTable: PropTypes.bool,
  isRenderTrashBinLineTable: PropTypes.bool,
};

TableFormService.defaultProps = {
  services: [],
  isFetchLine: false,
  positionTrash: false,
  isRemoveTrashFromLine: false,
  isRenderTrashBinServiceTable: false,
  isRenderTrashBinLineTable: false,
  onChangeFormLine: () => {},
  addLine: () => {},
  onToggle: () => {},
  onChangeProvisioningId: () => {},
  onRemoveItem: () => {},
  onRemoveLine: () => {},
};

const mapStateToProps = createStructuredSelector({
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation(['common', 'selections'])(connect(mapStateToProps)(TableFormService));
