import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import UserForm from './UserForm';
import {
  makeUserDetail,
  makeGetlistRoles,
  makePageRolesParams,
  makeGetlistRoleGroups,
  makePageRoleGroupsParams,
  errorMessageRoleGroups,
} from '../selectors';
import {
  getUserById,
  modifyUser,
  searchRoles,
  setParamsRoles,
  searchRoleGroups,
  setParamsRoleGroups,
  searchAllRoles,
  searchAllRoleGroups,
} from '../actions';
import {
  getS3Buctket,
  readFileFromS3,
  getAwsS3Credential,
  uploadMultiPartFiles,
  onReloadAvatar,
} from '../../App/actions';
import { makeGetPermissionsUserManagement, makeGetS3Config } from '../../App/selectors';
import {
  checkPermissionModifyUser,
  checkPermissionChangeUserStatus,
  checkPermissionCreateUser,
} from '../CheckPermission';

const combineRoles = (userData, listRoles) => {
  const { roles = [] } = userData;
  const newRoles =
    (roles && roles.length && listRoles && listRoles.filter(item => roles.find(d => d.roleId === item.id))) || [];
  const newRoleWithIndex = newRoles.map(item => ({
    ...item,
    index: roles[roles.findIndex(val => val.roleId === item.id)].index,
  }));
  return { ...userData, roles: newRoleWithIndex || [] };
};

const combineRoleGroups = (userData, listRoleGroups) => {
  const { roleGroups = [] } = userData;
  const newRoleGroups =
    (roleGroups &&
      roleGroups.length &&
      listRoleGroups &&
      listRoleGroups.filter(item => roleGroups.find(d => d.roleGroupId === item.id))) ||
    [];
  const newRoleGroupsWithIndex = newRoleGroups.map(item => ({
    ...item,
    index: roleGroups[roleGroups.findIndex(val => val.roleGroupId === item.id)].index,
  }));
  return { ...userData, roleGroups: newRoleGroupsWithIndex || [] };
};

class UserEdit extends Component {
  state = {
    isSubmitting: false,
    userDetail: {
      roles: [],
      roleGroups: [],
    },
    allRoles: [],
    allRoleGroups: [],
  };

  componentDidMount() {
    const { s3Config, getAwsS3Credential } = this.props;
    this.doGetUserById();
    if (!s3Config || !s3Config.accessKey) getAwsS3Credential();
  }

  doGetUserById = () => {
    const {
      match: {
        params: { id },
      },
      getUserById,
      searchAllRoles,
      searchAllRoleGroups,
    } = this.props;
    getUserById({ id }, response => {
      if (response.success) {
        this.setState({ userDetail: { ...response.data } });
        searchAllRoles({}, ({ success, data }) => {
          if (success) {
            const userDetail = combineRoles(this.state.userDetail, data);
            this.setState({ allRoles: data, userDetail });
          }
        });
        searchAllRoleGroups(({ success, data }) => {
          if (success) {
            const userDetail = combineRoleGroups(this.state.userDetail, data);
            this.setState({ allRoleGroups: data, userDetail });
          }
        });
      }
    });
  };

  getDeletedItems = (olds, newData) => {
    const newString = JSON.stringify(newData);
    return olds.filter(itm => !newString.includes(itm.id)).map((item, idx) => ({ index: idx + 1, roleId: null }));
  };

  onSave = (data, cb = null) => {
    const { userDetail } = this.state;
    const { modifyUser } = this.props;
    const { password, roles, roleGroups, ...modifyUserInput } = data;
    if (password !== userDetail.password) {
      modifyUserInput.password = password;
    }
    modifyUserInput.roles = roles;
    modifyUserInput.roleGroups = roleGroups;
    modifyUser({ modifyUserInput }, ({ success, data }) => {
      if (success) this.doGetUserById();
      this.setState({ isSubmitting: false });
    });
    // if (deletedRoles.length || deletedRolesgroup.length) {
    //   modifyUserInput.roles = deletedRoles.length ? deletedRoles : roles;
    //   modifyUserInput.roleGroups = deletedRolesgroup.length ? deletedRolesgroup : roleGroups;
    //   modifyUser({ modifyUserInput }, ({ success, data }) => {
    //     this.setState({ isSubmitting: false });
    //     modifyUserInput.roles = roles;
    //     modifyUserInput.roleGroups = roleGroups;
    //     modifyUser({ modifyUserInput }, ({ success, data }) => {
    //       this.setState({ isSubmitting: false });
    //     });
    //   });
    // } else {
    //   modifyUser({ modifyUserInput }, ({ success, data }) => {
    //     this.setState({ isSubmitting: false });
    //   });
    // }
  };

  render() {
    const { userDetail, isSubmitting } = this.state;
    const {
      permissionsUser,
      t,
      getS3Buctket,
      readFileFromS3,
      uploadMultiPartFiles,
      onReloadAvatar,
      match: {
        params: { id },
      },
      location,
    } = this.props;
    let modeModifyUser = 0;
    let modeChangeUserStatus = 0;
    let modeCreateUser = 0;

    if (permissionsUser && permissionsUser.userModulePermissions) {
      const listPermission = permissionsUser.userModulePermissions;
      modeModifyUser = checkPermissionModifyUser({ listPermission });
      modeChangeUserStatus = checkPermissionChangeUserStatus({ listPermission });
      modeCreateUser = checkPermissionCreateUser({ listPermission });
    }

    if (modeModifyUser === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.userManagementUser.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:operationsHub.subMain.userManagement'), url: RouteNames.userManagementRole.path },
                  {
                    name: t('userManagementPage:sidebar.users'),
                    url: {
                      pathname: RouteNames.userManagementUser.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  { name: userDetail && userDetail.id ? userDetail.id : 'Edit' },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editUser')}</h5>
                <div className="d-block d-md-flex nav-tabs-custom">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <span className="nav-link">{userDetail.userId}</span>
                    </li>
                    <li className="nav-item">
                      <span className="nav-link">{userDetail.category}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <UserForm
                item={userDetail}
                onSubmit={this.onSave}
                isSubmitting={isSubmitting}
                modeModifyUser={modeModifyUser}
                modeChangeUserStatus={modeChangeUserStatus}
                modeCreateUser={modeCreateUser}
                onCancel={this.doGetUserById}
                getS3Buctket={getS3Buctket}
                readFileFromS3={readFileFromS3}
                uploadMultiPartFiles={uploadMultiPartFiles}
                id={id}
                onReloadAvatar={onReloadAvatar}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userDetail: makeUserDetail() || {},
  listRoles: makeGetlistRoles() || [],
  paramsRoles: makePageRolesParams() || {},
  listRoleGroups: makeGetlistRoleGroups() || [],
  paramsRoleGroups: makePageRoleGroupsParams() || {},
  errorMessage: errorMessageRoleGroups() || '',
  permissionsUser: makeGetPermissionsUserManagement() || '',
  s3Config: makeGetS3Config() || {},
});

UserEdit.propTypes = {
  permissionsUser: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  getUserById: PropTypes.func.isRequired,
  modifyUser: PropTypes.func.isRequired,
  searchAllRoles: PropTypes.func.isRequired,
  searchAllRoleGroups: PropTypes.func.isRequired,
};

UserEdit.defaultProps = {
  permissionsUser: {},
  match: {},
};

const withConnect = connect(mapStateToProps, {
  getUserById,
  modifyUser,
  searchRoles,
  setParamsRoles,
  searchRoleGroups,
  setParamsRoleGroups,
  searchAllRoles,
  searchAllRoleGroups,
  getS3Buctket,
  readFileFromS3,
  getAwsS3Credential,
  uploadMultiPartFiles,
  onReloadAvatar,
});

export default withTranslation('common')(compose(withConnect)(UserEdit));
