import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import UserForm from './UserForm';
import { createUser } from '../actions';
import { makeGetS3Config } from '../../App/selectors';
import {
  getS3Buctket,
  readFileFromS3,
  getAwsS3Credential,
  uploadMultiPartFiles,
  onReloadAvatar,
} from '../../App/actions';
import { Breadcrumb } from '../../../components/common';

class UserAdd extends Component {
  state = {
    isSubmitting: false,
    items: [],
    parentItemId: '',
    openModal: false,
    page: 1,
    size: 20,
    isSearching: false,
    newRoleGroup: {
      roleGroupRoles: [],
    },
  };

  componentDidMount() {
    const { getAwsS3Credential, s3Config } = this.props;
    if (!s3Config || !s3Config.accessKey) getAwsS3Credential();
  }

  onCreateUser = data => {
    this.setState({ isSubmitting: true });
    const { id, ...userInput } = data;
    this.props.createUser({ userInput }, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const newData = {};
    const { t, getS3Buctket, readFileFromS3, uploadMultiPartFiles, onReloadAvatar, location } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.userManagementUser.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:operationsHub.subMain.userManagement'), url: RouteNames.userManagementRole.path },
                  {
                    name: t('userManagementPage:sidebar.users'),
                    url: {
                      pathname: RouteNames.userManagementUser.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addUser')}</h5>
              </div>
              <UserForm
                item={this.state.newRoleGroup}
                onSubmit={this.onCreateUser}
                isSubmitting={this.state.isSubmitting}
                creatingMode
                getS3Buctket={getS3Buctket}
                readFileFromS3={readFileFromS3}
                uploadMultiPartFiles={uploadMultiPartFiles}
                onReloadAvatar={onReloadAvatar}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default UserAdd;
UserAdd.propTypes = {
  createUser: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
});

// const mapDispatchToProps = dispatch => ({
//   createUser: (e, cb)=> (dispatch(createUser(e,cb))),
// });

const withConnect = connect(mapStateToProps, {
  createUser,
  getS3Buctket,
  readFileFromS3,
  getAwsS3Credential,
  uploadMultiPartFiles,
  onReloadAvatar,
});

export default withTranslation('common')(compose(withConnect)(UserAdd));
