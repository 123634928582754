import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'type',
    'index',
    'locationIndex',
    'autoCreateLegalEntities',
    'autoCreateBusinessUnits',
  ]);
  const modifyEnterprise = `modifyEnterprise(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyEnterprise} {
      id
    }
  }
`;
};
