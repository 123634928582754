import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class ModalOfferSearchFormWithoutStatus extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
  ];

  btnSpan = 'col-md-6';
}

ModalOfferSearchFormWithoutStatus.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ModalOfferSearchFormWithoutStatus.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ModalOfferSearchFormWithoutStatus);
