import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'type',
    'moveAllAccountServices',
    'moveAllAccountProvisioningAttributes',
    'moveProvisioningAttributes',
    'moveAllServices',
    'updateServiceCapacity',
    'updateServiceCapacityOnMove',
    'quantity',
    'servicePrice',
    'totalPrice',
    'paymentMethod',
    'provDataMoveType',
    'index',
    'capacity',
  ]);
  const saveAccountMovementData = `saveAccountMovementData(input: ${dataModify})`;
  return gql`
  mutation {
    ${saveAccountMovementData} {
      userId
    }
  }
`;
};
