import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataGet = parseToMutationRequest(data, ['gatewayName']);
  const getApiByGatewayName = `getApiByGatewayName(input: ${dataGet})`;
  return gql`
    query {
      ${getApiByGatewayName} {
        apiName
        apiType
        apiProtocol
        payloadMapOnly
      }
    }
  `;
};
