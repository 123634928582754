import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'status',
    'backoutOnly',
    'usageOnly',
    'index',
    'unbilledOnly',
    'rerateReasonCode',
  ]);
  const modifyReratingBatch = `modifyReratingBatch(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyReratingBatch} {
      id
    }
  }
`;
};
