import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class OpenCloseSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.invoiceDateWithRank',
      name: 'invoiceDate',
      type: 'date',
    },
    {
      label: 'label.invoiceStatus',
      name: 'invoiceStatus',
      tOptions: 'selections:invoiceUnitStatus',
      type: 'select',
      wrapperClass: 'col-md-3',
      isClearable: true,
    },
    {
      label: 'common:label.finalizedInvoice',
      name: 'fetchFinalizedInvoice',
      type: 'checkbox',
      wrapperClass: 'form-group mt-4 pt-2',
    },
    // {
    //   label: 'common:label.invoicesWithInstallment',
    //   name: 'filterInstallmentInvoices',
    //   type: 'checkbox',
    //   wrapperClass: 'form-group mt-4 pt-2',
    // },
    {
      label: 'common:label.openInvoices',
      name: 'fetchPendingDueInvoice',
      type: 'checkbox',
      wrapperClass: 'form-group mt-4 pt-2',
    },
  ];

  btnSpan = 'col-md-12';
}

OpenCloseSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OpenCloseSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OpenCloseSearchForm);
