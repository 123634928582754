import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CommissionsCancellationSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.cancelDateMoreThan',
      name: 'cancellationStartDate',
      type: 'date',
    },
    {
      label: 'label.cancelDateLessThan',
      name: 'cancellationEndtDate',
      type: 'date',
    },
    {
      name: 'salesAgentId',
      label: 'common:label.salesAgentId',
    },
  ];

  btnSpan = 'col-md-3';
}

CommissionsCancellationSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CommissionsCancellationSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CommissionsCancellationSearchForm);
