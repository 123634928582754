import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['type', 'coaType', 'index', 'glAccountType']);
  const modifyCOA = `modifyCOA(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyCOA} {
      id
    }
  }
`;
};
