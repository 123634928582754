import gql from 'graphql-tag';

export default fileName => {
  const getBancomerPaymentByFileName = `getBancomerPaymentByFileName(input: {fileName:"${fileName}"})`;
  return gql`
    query {
      ${getBancomerPaymentByFileName} {
        paymentRefId
        recordDate
        concept
        charge
        credit
        balance
        accountId
        invoiceId
        status
        errorCode
        suspenseAccountFlag
        paymentId
        batchId
        fileName
        createdDate
        processedDate
        transactionId
      }
    }
  `;
};
