import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import DependencyForm from './DependencyForm';
import { makeGetDependencyDetail, makeErrorMessage } from '../selectors';
import { getDependencyDetail, modifyDependency } from '../actions';
import { Breadcrumb } from '../../../components/common';
import {
  checkPermissionGetBundleDependency,
  checkPermissionGetPackageDependency,
  checkPermissionModifyDependency,
} from '../CheckPermission';
import { makeGetPermissionsBundleManagement } from '../../App/selectors';
import { supportRemoveIndexWithSize } from '../../../utils/utils';

class DependencyItemEdit extends Component {
  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    this.doGetDependencyDetail();
  }

  doGetDependencyDetail = () => {
    const {
      match: {
        params: { id },
      },
      getDependencyDetail,
    } = this.props;
    getDependencyDetail(id);
  };

  onSave = (data, cb = null) => {
    const { modifyDependency } = this.props;
    const { dependencyLists, ...rest } = data;
    const newComponents = [...dependencyLists];
    modifyDependency({ ...rest, dependencyLists: supportRemoveIndexWithSize({ data: newComponents }) }, success => {
      this.setState({ isSubmitting: false });
      if (cb) cb({ success });
    });
  };

  render() {
    const { dependencyInfo, permissionsBundleManagement, t, location } = this.props;
    const { isSubmitting } = this.state;
    let modeGetBundleDependency = 0;
    let modeGetPackageDependency = 0;
    let modeModifyDependency = 0;

    if (permissionsBundleManagement && permissionsBundleManagement.bundleModulePermissions) {
      const listPermissionBundle = permissionsBundleManagement.bundleModulePermissions;
      modeGetBundleDependency = checkPermissionGetBundleDependency({ listPermission: listPermissionBundle });
      modeGetPackageDependency = checkPermissionGetPackageDependency({ listPermission: listPermissionBundle });
      modeModifyDependency = checkPermissionModifyDependency({ listPermission: listPermissionBundle });
    }

    if (modeModifyDependency === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.dependencyItem.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.dependency'), url: RouteNames.dependencyItem.path },
                  { name: dependencyInfo && dependencyInfo.id ? dependencyInfo.id : 'Edit' },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editDependency')}</h5>
              </div>
              <DependencyForm
                item={dependencyInfo}
                onSubmit={this.onSave}
                modeGetBundleDependency={modeGetBundleDependency}
                modeGetPackageDependency={modeGetPackageDependency}
                modeModifyDependency={modeModifyDependency}
                isSubmitting={isSubmitting}
                creatingMode={false}
                onCancel={this.doGetDependencyDetail}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DependencyItemEdit.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  modifyDependency: PropTypes.func.isRequired,
  getDependencyDetail: PropTypes.func.isRequired,
  dependencyInfo: PropTypes.objectOf(PropTypes.any),
};

DependencyItemEdit.defaultProps = {
  permissionsBundleManagement: {},
  dependencyInfo: {},
  match: {},
};

const mapStateToProps = createStructuredSelector({
  dependencyInfo: makeGetDependencyDetail() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsBundleManagement: makeGetPermissionsBundleManagement() || {},
});

const mapDispatchToProps = dispatch => ({
  getDependencyDetail: (e, cb) => dispatch(getDependencyDetail(e, cb)),
  modifyDependency: (e, cb) => dispatch(modifyDependency(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation('common')(compose(withConnect)(DependencyItemEdit));
