import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['type'])}` : '';
  const searchNotesUnits = `searchNotesUnits(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchNotesUnits} {
        id
        accountId
        notesDate
        type
        total
        status
        notes
        currency
        reasonDescription
        invoiceCancellation
        ticketNumber
        invoiceId
        folioStatus
      }
    }
  `;
};
