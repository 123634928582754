import gql from 'graphql-tag';

export default name => gql`
  query {
    getDiscountTriggerByName(name: "${name}") {
      id
      name
      evaluationComponent
      comparisonOperator
      accumulatorId
      usageContainerField
      value
    }
  }
`;
