import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['countLimit', 'amountLimit'])}` : '';
  const getNewSmsReportData = `getNewSmsReportData(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getNewSmsReportData} {
        lineDate
        accountId
        organization
        noOfSms
        amountMxn
        amountUsd
      }
    }
  `;
};
