import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';

export default function FormAddCollectionSchedule({
  itemNew,
  onChange,
  onAddNew,
  // onRemove,
  isEnableAddBtn,
  // isEnableRemove,
  listOptionActions,
  modeCreateCollectionSheduleConfig,
}) {
  const { t } = useTranslation('common');

  return (
    <div className="form-group col-md-12 p-2">
      <div className="col-md-12 row">
        {/* <GenericInput
          name="offsetdays"
          label="common:label.offsetDays"
          value={itemNew.offsetdays}
          type="number"
          onChange={onChange}
          wrapperClass="col-md-3"
        />
        <GenericInput
          name="actionWorkingDay"
          label="common:label.workingDay"
          value={itemNew.actionWorkingDay}
          type="select"
          onChange={onChange}
          wrapperClass="col-md-3"
          tOptions="selections:collectionActionWorkingDay"
        />
        <GenericInput
          name="action"
          label="common:label.action"
          value={itemNew.action}
          type="select"
          isClearable
          wrapperClass="col-md-3"
          // options={t('selections:action')()}
          options={listOptionActions}
          onChange={onChange}
        />
        <GenericInput
          label="common:label.description"
          onChange={onChange}
          name="description"
          value={itemNew.description}
          wrapperClass="col-md-3"
          type="textarea"
        /> */}
        <button
          type="button"
          className="button button-border x-small float-right height-btn mt-4"
          onClick={onAddNew}
          disabled={modeCreateCollectionSheduleConfig === 1}
        >
          {`+ ${t('label.addNew')}`}
        </button>
      </div>
    </div>
  );
}

FormAddCollectionSchedule.propTypes = {
  isEnableAddBtn: PropTypes.bool,
  onChange: PropTypes.func,
  onAddNew: PropTypes.func,
  // onRemove: PropTypes.func,
  // isEnableRemove: PropTypes.bool,
  listOptionActions: PropTypes.arrayOf(PropTypes.any),
  itemNew: PropTypes.objectOf(PropTypes.any),
  modeCreateCollectionSheduleConfig: PropTypes.number,
};

FormAddCollectionSchedule.defaultProps = {
  isEnableAddBtn: false,
  // isEnableRemove: false,
  itemNew: {},
  onChange: () => {},
  // onRemove: () => {},
  onAddNew: () => {},
  listOptionActions: [],
  modeCreateCollectionSheduleConfig: 0,
};
