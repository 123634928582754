import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormWithTableItem, DataTable, FormCollapse, CollapsibleTable } from '../../common';

const tableAttributeColumns = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'priceOfferName',
    label: 'label.offerName',
  },
  {
    name: 'bundleName',
    label: 'label.bundleName',
  },
  {
    name: 'packageName',
    label: 'label.packageName',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:genericStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: '',
    label: '',
    style: { width: 20 },
    render: (colName, item, idx, indexParent, activeTab) => (
      <button type="button" className="btn-expand-table mr-3">
        <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
      </button>
    ),
  },
];

const tableColumnsAttributesData = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'value',
    label: 'label.value',
  },
];

const Attributes = ({ id, currencyOptions, customerInfo, getProvisioningByAccountId }) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState({});
  const [activeTabTable, setActiveTabTable] = useState(-1);

  const { t } = useTranslation('common');

  const doGetProvisioningByAccountId = useCallback(() => {
    setLoading(true);
    getProvisioningByAccountId(id, ({ success, data }) => {
      setLoading(false);
      if (success) setData(data || []);
    });
  }, [getProvisioningByAccountId, id]);

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  const onToggleTableTab = (index, item, indexItem) => {
    if (activeTabTable === indexItem) return setActiveTabTable(-1);
    if (activeTab !== indexItem) {
      return setActiveTabTable(indexItem);
    }
    return setActiveTabTable(-1);
  };

  useEffect(() => {
    doGetProvisioningByAccountId();
  }, [doGetProvisioningByAccountId]);

  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }
  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('label.attributes')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        key="pendingBills"
      >
        {data &&
          data.length > 0 &&
          data.map(item => (
            <FormCollapse
              key={item.subscriptionId}
              isActive={activeTab && item.subscriptionId === activeTab.name && activeTab.isActive}
              title={`${t('label.subscriptionId')}: ${item.subscriptionId}`}
              state={item.subscriptionId}
              onToggleTab={onToggleTab}
              isNoT
            >
              <CollapsibleTable
                columns={tableAttributeColumns}
                data={item.provisioningAttributes || []}
                isLoading={isLoading}
                indexViewer={activeTabTable}
                onClickRow={onToggleTableTab}
                isViewOnly
                tableClass="support-sub-table"
              >
                <div className="col-md-12 pt-2 pb-2">
                  <DataTable
                    columns={tableColumnsAttributesData}
                    data={
                      item.provisioningAttributes &&
                      item.provisioningAttributes[activeTabTable] &&
                      item.provisioningAttributes[activeTabTable].provisioningAttributesData
                        ? item.provisioningAttributes[activeTabTable].provisioningAttributesData
                        : []
                    }
                  />
                </div>
              </CollapsibleTable>
            </FormCollapse>
          ))}
      </FormWithTableItem>
    </div>
  );
};

Attributes.propTypes = {
  id: PropTypes.string,
  getProvisioningByAccountId: PropTypes.func.isRequired,
};

Attributes.defaultProps = {
  id: '',
};

export default Attributes;
