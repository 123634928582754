import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, FormCollapse, TitleFrom, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getCalendar, modifyCalendar, createCalendar } from '../actions';
import { listFieldsCalendar } from '../constants';

const listFieldsPeriods = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'adjustingPeriodCount',
    label: 'label.adjustingPeriodCount',
    render: (colName, item) => {
      return <span>{item.adjustingPeriod ? 'TRUE' : 'FALSE'}</span>;
    },
  },
  {
    name: 'quarter',
    label: 'label.quarter',
  },
];

class Calendar extends React.PureComponent {
  state = {
    data: [],
    wasValidated: false,
    formValid: true,
    activeTab: {},
    dataPeriods: null,
    dataSubmit: {},
    calendarPeriodsForYear: moment().format('YYYY'),
    idSelect: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetCalendar();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetCalendar = (calendarPeriodsForYear, cb, idSelect) => {
    const { getCalendar } = this.props;
    const payload = calendarPeriodsForYear ? { calendarPeriodsForYear, id: idSelect || null } : {};
    getCalendar(payload, ({ data }) => {
      if (!cb) {
        this.setState({
          data: data || [],
          isModify: !!data,
          dataSubmit: [],
        });
      }
      if (cb) {
        cb(data);
      }
    });
  };

  onChange = ({ index, name, value }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.address[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit, isModify } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      const payload = {
        // index: lastIndex + 1,
        name: '',
        description: '',
        startDate: null,
        prefix: '',
        delimiter: null,
        frequency: null,
        adjustingPeriodCount: '',
        unit: '',
        status: null,
      };
      if (isModify) {
        payload.addNextYearPeriods = true;
      }
      newData.push(payload);
      dataSubmit[newData.length - 1] = payload;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit } = this.state;
    const { modifyCalendar, createCalendar } = this.props;
    const payload = cloneDeep(data).map(val => {
      const { periods, id, ...rest } = val;
      return rest;
    });
    if (isModify) {
      const newPayloadModify = dataSubmit
        .filter(val => !!val)
        .map(val => {
          const { addNextYearPeriods, ...rest } = val;
          return { ...rest, addNextYearPeriods: addNextYearPeriods ? 'true' : 'false' };
        });
      return modifyCalendar(newPayloadModify, ({ success }) => {
        if (success) this.doGetCalendar();
      });
    }
    return createCalendar(payload, ({ success }) => {
      if (success) this.doGetCalendar();
    });
  };

  onToggleTab = (name, idSelect) => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState(
        {
          activeTab: { name, isActive: !activeTab.isActive },
          idSelect,
          dataPeriods: null,
          calendarPeriodsForYear: moment().format('YYYY'),
        },
        () => {
          if (!activeTab.isActive && idSelect) {
            this.onFetchPeriods();
          }
        }
      );
    } else {
      this.setState(
        {
          activeTab: { name, isActive: true },
          idSelect,
          dataPeriods: null,
          calendarPeriodsForYear: moment().format('YYYY'),
        },
        () => {
          if (idSelect) {
            this.onFetchPeriods();
          }
        }
      );
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE';
      if (!dataSubmit[index]) dataSubmit[index] = { id: newData[index].id };
      newData[index][name] = newValue;
      dataSubmit[index][name] = newValue;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeYearFetch = ({ value }) => {
    this.setState({ calendarPeriodsForYear: value });
  };

  onFetchPeriods = () => {
    const { calendarPeriodsForYear, idSelect } = this.state;
    this.doGetCalendar(
      calendarPeriodsForYear,
      data => {
        this.setState({ dataPeriods: data && data[0] && data[0].periods ? data[0].periods : [] });
      },
      idSelect
    );
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateCalendarConfig, modeGetCalendarConfig, modeModifyCalendarConfig } = permissions;
    if (!modeGetCalendarConfig) return '';
    const { data, activeTab, wasValidated, calendarPeriodsForYear, dataPeriods, isModify } = this.state;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.calendar')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.calendar')} />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <FormCollapse
                    key={`key-${index}`}
                    isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                    title={`${item.name || '-'}`}
                    state={`state-${index}`}
                    onToggleTab={name => this.onToggleTab(name, item.id)}
                  >
                    <div className="col-md-12 row p-3">
                      {[
                        ...listFieldsCalendar,
                        {
                          label: 'label.addNextYearPeriods',
                          name: 'addNextYearPeriods',
                          type: 'select',
                          tOptions: 'selections:selectBool',
                          disabled: !isModify,
                          isBoolean: true,
                        },
                      ].map((val, idxField) => {
                        if (val.name === 'locationIndex') {
                          return (
                            <GenericInput
                              value={
                                item.address
                                  ? `${item.address.city}, ${item.address.state}, ${item.address.country}`
                                  : ''
                              }
                              wrapperClass="col-md-4"
                              type={val.tOptions ? 'select' : 'text'}
                              onChange={({ name, value }) => this.onHandleChange({ value, name })}
                              {...val}
                            />
                          );
                        }
                        return (
                          <GenericInput
                            value={val.isBoolean ? (item[val.name] ? 'TRUE' : 'FALSE') : item[val.name]}
                            wrapperClass="col-md-4"
                            type={val.tOptions ? 'select' : 'text'}
                            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
                            {...val}
                          />
                        );
                      })}
                    </div>
                    <div className="col-md-12">
                      <h6>{t('label.calendarPeriods')}</h6>
                      <div className="col-md-12" style={{ zIndex: 2 }}>
                        <div className="float-right">
                          <button
                            type="button"
                            className="button float-right x-small mt-4"
                            disabled={!item.id || !calendarPeriodsForYear}
                            onClick={this.onFetchPeriods}
                          >
                            {t('label.fetch')}
                          </button>
                        </div>
                        <GenericInput
                          value={calendarPeriodsForYear || null}
                          label="label.calendarYear"
                          wrapperClass="col-md-3 float-right"
                          type="year"
                          onChange={({ name, value }) => this.onHandleChangeYearFetch({ value, name, index })}
                          name="calendarPeriodsForYear"
                        />
                      </div>
                      <div className="col-md-12">
                        <DataTable
                          tableClass="pt-3"
                          columns={listFieldsPeriods}
                          // data={dataPeriods || item.periods || []}
                          data={dataPeriods || []}
                          isNoNeedFixedHeader
                        />
                      </div>
                      <br />
                    </div>
                  </FormCollapse>
                ))}

              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateCalendarConfig === 2 && (
                  <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                    +
                    {t('label.addNewCalendar')}
                  </button>
                )}
                {modeModifyCalendarConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  onClick={evt => this.doGetCalendar()}
                  type="button"
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  getCalendar: PropTypes.func,
  modifyCalendar: PropTypes.func,
};

Calendar.defaultProps = {
  getCalendar: () => {},
  modifyCalendar: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCalendar,
    modifyCalendar,
    createCalendar,
  })(withRouter(Calendar))
);
