import navbar from './navbar';
import common from './common';
import customerPage from './customer-management-page';
import orderPage from './order-management-page';
import pricingPage from './pricing-management-page';
import bundlePage from './bundle-management-page';
import paymentPage from './payment-page';
import collectionPage from './collection-page';
import arOperationPage from './ar-operation-page';
import userManagementPage from './user-management-page';
import jobManagementPage from './job-management';
import selections from './selections';
import baseConfigurationsPage from './base-configurations-page';
import dashboardPage from './dashboard-page';
import permission from './permission';
import reportPage from './report-page';
import tenantPage from './tenant-management';
import revenuePage from './revenue-page';
import correspondencePage from './correspondence-management';
import usagePage from './usage-page';
import quotePage from './quote-management-page';
import taskPage from './task-management-page';

export default {
  common,
  navbar,
  customerPage,
  orderPage,
  pricingPage,
  bundlePage,
  paymentPage,
  collectionPage,
  arOperationPage,
  userManagementPage,
  selections,
  jobManagementPage,
  baseConfigurationsPage,
  dashboardPage,
  permission,
  reportPage,
  tenantPage,
  revenuePage,
  correspondencePage,
  usagePage,
  quotePage,
  taskPage,
};
