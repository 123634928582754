import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'crAccountType',
    'drAccountType',
    'objectType',
    'transactionType',
    'status',
  ]);
  const createConfigAccountingRules = `createConfigAccountingRules(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigAccountingRules} {
      id
    }
  }
`;
};
