import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { queryRequest, mutationRequest } from '../../utils/request';
import RouteNames from '../App/RouteNames';
import {
  searchBundlesList,
  getBundleDetail,
  modifyBundle,
  updateBundleStatus,
  createBundle,
  searchPackagesList,
  getPackageDetail,
  modifyPackage,
  updatePackageStatus,
  createPackage,
  searchDependencies,
  getDependencyDetail,
  modifyDependency,
  createDependency,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

// ------ BUNDLES
export function* searchListBundles({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchBundlesList({ page, size, filter, sort }));
    if (response.searchBundles) {
      if (cb) cb({ success: true, data: response.searchBundles });

      yield put(actions.searchBundlesSuccess(response.searchBundles));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });

    // toast('Failed to fetch bundles. Please try again or check your network!', { type: toast.TYPE.ERROR });
    if (cb) cb();
  }
}

export function* getBundleSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBundleDetail(payload));
    if (response.getBundleById) {
      yield put(actions.getBundleDetailSuccess(response.getBundleById));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Cannot get detail for bundle ${payload}`, { type: toast.TYPE.ERROR });
  }
}

export function* modifyBundleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, modifyBundle(dataCreate));
    yield put(actions.modifyBundleSuccess(response.modifyBundle));
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyBundleSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // toast('Bundle updated failed!', { type: toast.TYPE.ERROR });
    if (cb) yield cb({ success: false });
  }
}

export function* createBundleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createBundle(dataCreate));
    yield put(actions.createBundleSuccess());
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    if (response.createBundle && response.createBundle.id) {
      yield put(push(RouteNames.prettifyPath(RouteNames.bundleItemEdit.path, response.createBundle.id)));
      toast.success(i18n.t('common:apiMessage.createBundleSuccessfully'));
    } else {
      // toast('Bundle created failed!', { type: toast.TYPE.ERROR });
    }
  } catch (err) {
    yield put(isEndConnected());
    // toast('Bundle created failed!', { type: toast.TYPE.ERROR });
    if (cb) yield cb({ success: false });
  }
}

export function* updateBundleStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateBundleStatus(payload));
    yield put(actions.updateBundleStatusSuccess(response.updateBundleStatus));
    yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.updateBundleStatusSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // toast('Bundle status updated failed!', { type: toast.TYPE.ERROR });
    cb({ success: false });
  }
}

// ------ PACKAGES
export function* searchListPackages({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchPackagesList({ page, size, filter, sort }));
    if (response.searchPackages) {
      yield put(actions.searchPackagesSuccess(response.searchPackages));
    } else {
      // toast('Failed to fetch packages. Please try again or check your network!', { type: toast.TYPE.ERROR });
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    // toast('Failed to fetch packages. Please try again or check your network!', { type: toast.TYPE.ERROR });
    if (cb) cb();
  }
}

export function* getPackageSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPackageDetail(payload));
    if (response.getPackageById) {
      yield put(actions.getPackageDetailSuccess(response.getPackageById));
    } else {
      // toast(`Cannot get detail for package ${payload}`, { type: toast.TYPE.ERROR });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Cannot get detail for package ${payload}`, { type: toast.TYPE.ERROR });
  }
}

export function* modifyPackageSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { newStatus, ...dataCreate } = payload;
    const response = yield call(mutationRequest, modifyPackage(dataCreate));
    yield put(actions.modifyPackageSuccess({ ...response.modifyPackage, status: newStatus }));
    yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyPackageSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // toast('Package updated failed!', { type: toast.TYPE.ERROR });
    cb({ success: false });
  }
}

export function* createPackageSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createPackage(dataCreate));
    yield put(actions.createPackageSuccess());
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    if (response.createPackage && response.createPackage.id) {
      yield put(push(RouteNames.prettifyPath(RouteNames.packageItemEdit.path, response.createPackage.id)));
      toast.success(i18n.t('common:apiMessage.createPackageSuccessfully'));
    } else {
      // toast('Package created failed!', { type: toast.TYPE.ERROR });
    }
  } catch (err) {
    yield put(isEndConnected());
    // toast('Package created failed!', { type: toast.TYPE.ERROR });
    if (cb) yield cb({ success: false });
  }
}

export function* updatePackageStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updatePackageStatus(payload));
    yield put(actions.updatePackageStatusSuccess(response.updatePackageStatus));
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.updatePackageStatusSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // toast('Package status updated failed!', { type: toast.TYPE.ERROR });
    if (cb) yield cb({ success: false });
  }
}

// ------DEPENDENCIES
export function* searchListDependencies({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchDependencies({ page, size, filter, sort }));
    if (response.searchDependencies) {
      yield put(actions.searchDependenciesSuccess(response.searchDependencies));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getDependencySaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDependencyDetail(payload));
    if (response.getDependencyById) {
      yield put(actions.getDependencyDetailSuccess(response.getDependencyById));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Cannot get detail for dependency ${payload}`, { type: toast.TYPE.ERROR });
  }
}

export function* modifyDependencySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, modifyDependency(dataCreate));
    yield put(actions.modifyDependencySuccess(response.modifyDependency));
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyDependencySuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // toast('Dependency updated failed!', { type: toast.TYPE.ERROR });
    if (cb) yield cb({ success: false });
  }
}

export function* createDependencySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createDependency(dataCreate));
    yield put(actions.createDependencySuccess());
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    if (response.createDependency && response.createDependency.id) {
      yield put(push(RouteNames.prettifyPath(RouteNames.dependencyItemEdit.path, response.createDependency.id)));
      toast.success(i18n.t('common:apiMessage.createDependencySuccessfully'));
    } else {
      // toast('Dependency created failed!', { type: toast.TYPE.ERROR });
    }
  } catch (err) {
    yield put(isEndConnected());
    // toast('Dependency created failed!', { type: toast.TYPE.ERROR });
    if (cb) yield cb({ success: false });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* bundleSaga() {
  yield takeLatest(types.SEARCH_BUNDLES, searchListBundles);
  yield takeLatest(types.GET_BUNDLE_DETAIL, getBundleSaga);
  yield takeLatest(types.MODIFY_BUNDLE, modifyBundleSaga);
  yield takeLatest(types.CREATE_BUNDLE, createBundleSaga);
  yield takeLatest(types.UPDATE_BUNDLE_STATUS, updateBundleStatusSaga);

  yield takeLatest(types.SEARCH_PACKAGES, searchListPackages);
  yield takeLatest(types.GET_PACKAGE_DETAIL, getPackageSaga);
  yield takeLatest(types.MODIFY_PACKAGE, modifyPackageSaga);
  yield takeLatest(types.CREATE_PACKAGE, createPackageSaga);
  yield takeLatest(types.UPDATE_PACKAGE_STATUS, updatePackageStatusSaga);

  yield takeLatest(types.SEARCH_DEPENDENCIES, searchListDependencies);
  yield takeLatest(types.GET_DEPENDENCY_DETAIL, getDependencySaga);
  yield takeLatest(types.MODIFY_DEPENDENCY, modifyDependencySaga);
  yield takeLatest(types.CREATE_DEPENDENCY, createDependencySaga);
}
