import selectConstant from './selectConstant';
import { countryList } from '../constantsApp/countryList';

export default {
  withAddressesUSForm: ({ isSupportRequired, isFromAccount }) => {
    const unitField = [];
    const districtField = [];
    if (isFromAccount) {
      unitField.push({
        fieldName: 'unit',
        labelFilter: 'common:label.unit',
      });
    }
    if (districtField) {
      districtField.push({
        fieldName: 'district',
        labelFilter: 'common:label.district',
      });
    }
    return [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        isDisable: true,
      },
      ...unitField,
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        type: 'textarea',
        required: !!isSupportRequired,
      },
      {
        fieldName: 'extraLine',
        labelFilter: 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        optionSelect: countryList,
      },
      {
        labelFilter: 'common:label.state',
        fieldSelect: 'state',
        optionSelect: selectConstant.stateUSData,
        isCreateOption: true,
        required: !!isSupportRequired,
      },
      ...districtField,
      {
        labelFilter: 'common:label.city',
        fieldSelect: 'city',
        optionSelect: [],
        fieldName: 'city',
        isCreateOption: true,
        required: !!isSupportRequired,
      },
      {
        labelFilter: 'common:label.postalCode',
        fieldSelect: 'postalCode',
        fieldName: 'postalCode',
        optionSelect: [],
        isCreateOption: true,
        required: !!isSupportRequired,
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ];
  },

  withAddressesNormalForm: ({ landmarkLabel, extralineLabel, isSupportRequired, isFromAccount }) => {
    const unitField = [];
    const districtField = [];
    if (isFromAccount) {
      unitField.push({
        fieldName: 'unit',
        labelFilter: 'common:label.unit',
      });
    }
    if (districtField) {
      districtField.push({
        fieldName: 'district',
        labelFilter: 'common:label.district',
      });
    }
    return [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        isDisable: true,
      },
      ...unitField,
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        type: 'textarea',
        required: !!isSupportRequired,
      },
      {
        fieldName: 'extraLine',
        labelFilter: extralineLabel || 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: landmarkLabel || 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        optionSelect: countryList,
      },
      {
        fieldName: 'state',
        labelFilter: 'common:label.state',
        required: !!isSupportRequired,
      },
      ...districtField,
      {
        labelFilter: 'common:label.city',
        fieldName: 'city',
        required: !!isSupportRequired,
      },
      {
        fieldName: 'postalCode',
        labelFilter: 'common:label.postalCode',
        required: !!isSupportRequired,
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ];
  },
};
