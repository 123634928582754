import React, { Component } from 'react';
import { unionBy, isEmpty, size as sizeLodash, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPageTotalCount, getTableFromResponeCollections } from '../../../../utils/utils';
import { accountSort, collectionsEnum } from '../../../../constantsApp';
import { tableColumnsAccount } from '../../constant';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import { FormWithTableItem, DataTable, TablePagination } from '../../../../components/common';
import CheckGroup from '../../../../components/common/CheckGroup';
import { AccountCollectionSearchForm, FormAddCollectionAccount } from '../../../../components/CollectionsHub';
import { searchAccountsCollection, getCollectionAgentByType, reassignCollectionAgent } from '../../actions';
import { makeGetListAccounts } from '../../selectors';
import {
  checkPermissionSearchAccountsInCollection,
  checkPermissionReassignCollectionAgent,
} from '../../CheckPermission';
import { searchUserByUserIdCollection } from '../../../App/actions';

class AccountCollection extends Component {
  constructor() {
    super();
    this.state = {
      isSearching: false,
      isActiveNext: false,
      page: 0,
      size: 20,
      totalCount: null,
      filter: {},
      collectionAgents: [],
      agentSelected: {},
      rowSelected: {},
      sort: '',
      newReassign: {},
      dataListUsers: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.listAccounts }),
    };
  }

  componentDidMount() {
    this.doSearchCollectionAccount();
    this.getCollectionAgentByType();
    this.doGetCollectionAgentByType();
  }

  doGetCollectionAgentByType = (payload, cb) => {
    const { getCollectionAgentByType, searchUserByUserIdCollection } = this.props;
    getCollectionAgentByType(
      {
        type: collectionsEnum.type.collectionAgents,
      },
      response => {
        const listTable = getTableFromResponeCollections(
          response && response.getCollectionAgentByType,
          'collectionAgents'
        );
        if (listTable && listTable.length) {
          const dataListUsers = [];
          listTable.forEach(val => {
            if (val.userId)
              searchUserByUserIdCollection(val.userId, ({ success, data }) => {
                if (success) {
                  dataListUsers.push(data);
                  this.setState({ dataListUsers: cloneDeep(dataListUsers) });
                }
              });
          });
        }
      }
    );
  };

  doSearchCollectionAccount = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchAccountsCollection } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? accountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchAccountsCollection(payload, () => this.setState({ isSearching: false }));
  };

  getCollectionAgentByType = () => {
    const { getCollectionAgentByType } = this.props;
    getCollectionAgentByType(
      {
        type: collectionsEnum.type.collectionAgents,
      },
      response => {
        if (response.getCollectionAgentByType && response.getCollectionAgentByType.length) {
          const collectionAgents = response.getCollectionAgentByType[0].collectionAgents;
          this.setState({
            collectionAgents: unionBy(collectionAgents, 'userId'),
          });
        }
      }
    );
  };

  onHandleSearch = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchCollectionAccount());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchCollectionAccount());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchCollectionAccount());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchCollectionAccount());
  };

  onChangeAgent = ({ name, value, row }) => {
    const { contact } = row;
    const { agentSelected } = this.state;
    if (contact && contact.length) {
      agentSelected.firstName = contact[0] && contact[0].firstName ? contact[0].firstName : '';
      agentSelected.lastName = contact[0] && contact[0].lastName ? contact[0].lastName : '';
    }
    agentSelected[name] = value;
    // agentSelected.firstName = 'Hardcoded-firstName';
    // agentSelected.lastName = 'Hardcoded-firstName';
    this.setState({ ...agentSelected });
  };

  onSelectedRow = item => {
    let { rowSelected } = this.state;
    if (item.index !== rowSelected.index) {
      rowSelected = item;
    } else {
      rowSelected = {};
    }
    this.setState({ rowSelected });
  };

  onHandleReassign = () => {
    const { reassignCollectionAgent } = this.props;
    const { rowSelected, agentSelected } = this.state;
    const payloadRequest = {
      accountId: rowSelected.accountId,
      userId: rowSelected.userId,
      newUserId: agentSelected.userId,
    };

    reassignCollectionAgent(payloadRequest, ({ success }) => {
      this.setState({ isReassigning: false, userIdSelect: null });
      if (success) {
        this.doSearchCollectionAccount();
        this.setState({ rowSelected: {}, agentSelected: {} });
      }
    });
  };

  render() {
    const { listAccounts, permissionsCollections } = this.props;
    const {
      isSearching,
      collectionAgents,
      agentSelected,
      rowSelected,
      sorted,
      page,
      size,
      totalCount,
      dataListUsers,
    } = this.state;
    let modeSearchAccountsInCollection = 0;
    let modeReassignCollectionAgent = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeSearchAccountsInCollection = checkPermissionSearchAccountsInCollection({ listPermission });
      modeReassignCollectionAgent = checkPermissionReassignCollectionAgent({ listPermission });
    }

    if (modeSearchAccountsInCollection === 0) return '';

    const tableConfig = {
      columns: [
        {
          name: 'Selected',
          label: 'common:label.selected',
          render: (colName, item) => {
            return (
              <CheckGroup
                type="checkbox"
                name={item.accountId}
                checked={rowSelected.accountId === item.accountId}
                onChange={() => this.onSelectedRow(item)}
              />
            );
          },
        },
        ...tableColumnsAccount,
      ],
      sorted,
      onSort: this.onSortColumn,
      data: listAccounts,
      isLoading: isSearching,
    };

    return (
      <div className="col-md-12 mb-30">
        <FormWithTableItem title="Accounts" wrapperClass="collection-form-item">
          <div className="col-sm-12 mb-30 pl-1">
            <div className="card-body">
              <AccountCollectionSearchForm onSubmit={this.onHandleSearch} />
              <br />
            </div>
          </div>
          <DataTable {...tableConfig} isFixedHeaderTable />
          {modeReassignCollectionAgent !== 0 && (
            <FormAddCollectionAccount
              // optionUserId={collectionAgents.map(el => ({
              //   value: el.userId,
              //   label: el.userId,
              // }))}
              onChange={this.onChangeAgent}
              modeReassignCollectionAgent={modeReassignCollectionAgent}
              newItem={agentSelected}
              isEnableAssignBtn={rowSelected.accountId && sizeLodash(agentSelected) === 3}
              onHandleReassign={this.onHandleReassign}
              getListUserCollectionAgentByType={this.doGetCollectionAgentByType}
              dataListUsers={dataListUsers}
            />
          )}
        </FormWithTableItem>
        <br />
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={this.onPageChange}
          onSizeChange={this.onSizeChange}
        />
      </div>
    );
  }
}

AccountCollection.propTypes = {
  searchAccountsCollection: PropTypes.func.isRequired,
  listAccounts: PropTypes.arrayOf(PropTypes.any),
  getCollectionAgentByType: PropTypes.func.isRequired,
  reassignCollectionAgent: PropTypes.func.isRequired,
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

AccountCollection.defaultProps = {
  listAccounts: [],
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  listAccounts: makeGetListAccounts() || [],
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default connect(mapStateToProps, {
  searchAccountsCollection,
  getCollectionAgentByType,
  reassignCollectionAgent,
  searchUserByUserIdCollection,
})(AccountCollection);
