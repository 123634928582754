import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { FormWithTableItem, DataTable, TablePagination, ModalWithItem, GenericInput } from '../../common';
import { getPageTotalCount, validate } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';
import RouteNames from '../../../containers/App/RouteNames';

const subscriptionSort = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const listFields = [
  {
    name: 'amount',
    label: 'label.amount',
    type: 'number',
    required: true,
  },
  {
    name: 'validityTermUnit',
    label: 'label.validityTerm',
    type: 'select',
    tOptions: 'selections:termUnit',
    required: true,
  },
  {
    name: 'validityTerm',
    label: 'label.termX',
    type: 'number',
    required: true,
  },
  {
    name: 'applyPayment',
    label: 'label.subscriptionOrAddonPurchase',
    type: 'checkbox',
    wrapperClass: 'mt-4 pt-2 col-md-3',
  },
];

const Topup = ({ id, searchSubscriptionByAccountId, currencyOptions, customerInfo, subscriptionTopUp, history }) => {
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);

  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [data, setData] = useState([]);
  const [isOpenModalSubmit, setIsOpenModalSubmit] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);

  const { t } = useTranslation('common');

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doSearchSubscriptionByAccountId = useCallback(() => {
    setLoading(false);
    const newFilter = {};
    if (!newFilter.accountId) newFilter.accountId = id;
    searchSubscriptionByAccountId(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? subscriptionSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if ((!data || !data.length) && page === 0) {
          toast.info(t('message.noTopupMessage'));
        }
        if (success && data) setData(data);
      }
    );
  }, [id, searchSubscriptionByAccountId, page, size, sorted, t]);

  useEffect(() => {
    doSearchSubscriptionByAccountId();
  }, [doSearchSubscriptionByAccountId]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  const onSelectItem = ({ item }) => {
    setItemSelect({ ...item, validityTermUnit: 'MONTHS', validityTerm: '1' });
    setIsOpenModalSubmit(true);
  };

  const onChangeDataSubmit = ({ name, value }) => {
    itemSelect[name] = value;
    setItemSelect({ ...itemSelect });
  };

  const onToggleModalSubmit = () => {
    setIsOpenModalSubmit(false);
    setItemSelect(null);
  };

  const onSubscriptionTopUp = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    return subscriptionTopUp(
      {
        subscriptionId: itemSelect.id || null,
        accountId: id || null,
        userId: getUserId() || null,
        amount: itemSelect.amount || null,
        validityTerm: itemSelect.validityTerm || null,
        validityTermUnit: itemSelect.validityTermUnit || null,
        applyPayment:
          itemSelect.applyPayment || typeof itemSelect.applyPayment === 'boolean' ? itemSelect.applyPayment : null,
      },
      ({ success }) => {
        if (success) {
          history.push(RouteNames.prettifyPath(RouteNames.customerDetailsBalances.path, id));
        }
      }
    );
  };

  const tableTopup = [
    {
      name: 'select',
      label: 'label.select',
      render: (colName, item) => (
        <GenericInput
          type="checkbox"
          name={item.id}
          checked={itemSelect && item.id === itemSelect.id}
          value={itemSelect && item.id === itemSelect.id}
          disabled={!item.category || item.category !== 'PREPAID'}
          onChange={() => onSelectItem({ item })}
        />
      ),
    },
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) =>
        !item.category || item.category !== 'PREPAID' ? (
          <>{item.id}</>
        ) : (
          <button
            className="btn btn-outline-success btn-sm no-border"
            type="button"
            onClick={() => onSelectItem({ item })}
          >
            {item.id}
          </button>
        ),
    },
    {
      name: 'name',
      label: 'label.name',
    },
    {
      name: 'category',
      label: 'label.category',
    },
    {
      name: 'effectiveDate',
      label: 'label.effectiveDate',
      sortable: true,
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      sortable: true,
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      sortable: true,
    },
    {
      name: 'status',
      label: 'label.status',
      sortable: true,
    },
  ];
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }
  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('customerPage:sidebarHandleAccountPage.topup')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        key="pendingBills"
      >
        <DataTable isLoading={isLoading} onSort={onSortColumn} sorted={sorted} columns={tableTopup} data={data || []} />
      </FormWithTableItem>
      <div className="mb-30 mt-3">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>

      <ModalWithItem isOpen={isOpenModalSubmit} onToggle={onToggleModalSubmit} modalTitle={t('label.topupDetails')}>
        <form
          ref={formRef}
          noValidate
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          onSubmit={onSubscriptionTopUp}
        >
          <div className="col-md-12 row">
            {listFields.map(val => (
              <GenericInput
                {...val}
                value={itemSelect && (itemSelect[val.name] || itemSelect[val.name] === 0) ? itemSelect[val.name] : null}
                onChange={onChangeDataSubmit}
              />
            ))}
          </div>
          <div className="col-sm-12 mt-4 mb-30">
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={onToggleModalSubmit}
            >
              {t('label.cancel')}
            </button>
            <button type="submit" className="button x-small mr-2 float-right">
              {t('label.submit')}
            </button>
          </div>
        </form>
      </ModalWithItem>
    </div>
  );
};

Topup.propTypes = {
  id: PropTypes.string,
  subscriptionTopUp: PropTypes.func.isRequired,
  searchSubscriptionByAccountId: PropTypes.func.isRequired,
};

Topup.defaultProps = {
  id: '',
};

export default Topup;
