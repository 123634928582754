import React from 'react';

export const checkListPendingBillCharges = [
  {
    name: 'ALL',
    label: 'label.all',
  },
  {
    name: 'SELECTIVE',
    label: 'label.selective',
  },
  {
    name: 'NON_USAGE',
    label: 'label.nonUsage',
  },
  {
    name: 'USAGE',
    label: 'label.usage',
  },
];

export const installmentsColumns = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'percent',
    label: 'common:label.percent',
  },
  {
    name: 'daysOffset',
    label: 'common:label.daysOffset',
  },
  {
    name: 'monthsOffset',
    label: 'common:label.monthsOffset',
  },
  {
    name: 'dueDate',
    label: 'common:label.dueDate',
  },
  {
    name: 'paymentDate',
    label: 'common:label.paymentDate',
  },
  {
    name: 'amount',
    label: 'common:label.installmentAmt',
  },
  {
    name: 'paidAmount',
    label: 'common:label.paymentAmt',
  },
  {
    name: 'installmentStatus',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:installmentStatus')().find(val => val.value === item.installmentStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const listFields = [
  {
    label: 'label.count',
    name: 'numOfInstallment',
    type: 'number',
  },
  {
    label: 'label.daysOffset',
    name: 'daysOffset',
    type: 'number',
  },
  {
    label: 'label.monthOffset',
    name: 'monthOffset',
    type: 'number',
  },
];

export const listInvoiceFields = [
  {
    label: 'label.invoiceId',
    name: 'id',
    readOnly: true,
  },
  {
    label: 'label.invoiceTotal',
    name: 'total',
    readOnly: true,
    isFormatMoneyOfValue: true,
  },
  {
    label: 'label.invoiceDue',
    name: 'due',
    readOnly: true,
    isFormatMoneyOfValue: true,
  },
  {
    label: 'label.dueDate',
    name: 'dueDate',
    readOnly: true,
  },
];

export const collapsibleMenuTransactionId = [
  {
    title: 'common:label.invoiceLines',
    state: 'invoiceLines',
  },
  {
    title: 'common:label.taxLines',
    state: 'taxLines',
  },
  {
    title: 'common:label.invoiceSummary',
    state: 'invoiceSummary',
  },
];

export const tableTaxLines = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'state',
    label: 'label.state',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
  },
  {
    name: 'amount',
    label: 'label.amount',
    isAmount: true,
  },
  {
    name: 'taxRate',
    label: 'label.taxableRate',
    isAmount: true,
  },
  {
    name: 'taxableAmount',
    label: 'label.taxableAmount',
    isAmount: true,
  },
  {
    name: 'exemptAmount',
    label: 'label.exemptAmount',
    isAmount: true,
  },
  {
    name: 'payment',
    label: 'label.payment',
  },
  {
    name: 'invoiceSummaryType',
    label: 'label.summaryType',
    fieldOption: 'invoiceSummaryType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceSummaryType')().find(val => val.value === item.invoiceSummaryType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tableInvoiceSummary = [
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'itemId',
    label: 'label.itemId',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'unitPrice',
    label: 'label.unitPrice',
  },
  {
    name: 'scale',
    label: 'label.scale',
  },
  {
    name: 'gross',
    label: 'label.gross',
  },
  {
    name: 'discount',
    label: 'label.discount',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'appliedStart',
    label: 'label.startDate',
  },
  {
    name: 'appliedEnd',
    label: 'label.endDate',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'payment',
    label: 'label.payment',
  },
  {
    name: 'invoiceSummaryType',
    label: 'label.summaryType',
    fieldOption: 'invoiceSummaryType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceSummaryType')().find(val => val.value === item.invoiceSummaryType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountingCode',
    label: 'label.accountingCode',
  },
];
export const billSort = {
  dueDate: {
    asc: 'dueDate_ASC',
    desc: 'dueDate_DESC',
  },
  invoiceDate: {
    asc: 'invoiceDate_ASC',
    desc: 'invoiceDate_DESC',
  },
  invoiceStatus: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

export const searchFiltersOpenClose = [
  {
    title: 'common:label.searchFilters',
    state: 'searchFilters',
  },
];
