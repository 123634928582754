import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class SearchQuotesList extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:quoteStatus',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.type',
      name: 'type',
      tOptions: 'selections:quoteType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.orderId',
      name: 'orderId',
    },
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.clientId',
      name: 'clientId',
    },
    {
      label: 'common:label.salesAgentId',
      name: 'salesAgentId',
    },
    {
      label: 'common:label.submittedStartDate',
      name: 'submittedStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.submittedEndDate',
      name: 'submittedEndDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.effectiveStartDate',
      name: 'effectiveStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.effectiveEndDate',
      name: 'effectiveEndDate',
      type: 'date',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

SearchQuotesList.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchQuotesList.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(SearchQuotesList);
