import * as types from './types';

// FutureCycleCfg
export function getFutureCycleCfg(payload, cb) {
  return {
    type: types.GET_FUTURE_CYCLE_CFG_BILLING,
    payload,
    cb,
  };
}

export function getFutureCycleCfgSuccess(payload, cb) {
  return {
    type: types.GET_FUTURE_CYCLE_CFG_SUCCESS_BILLING,
    payload,
    cb,
  };
}

export function modifyFutureCycleCfg(payload, cb) {
  return {
    type: types.MODIFY_FUTURE_CYCLE_CFG_BILLING,
    payload,
    cb,
  };
}

export function createFutureCycleCfg(payload, cb) {
  return {
    type: types.CREATE_FUTURE_CYCLE_CFG_BILLING,
    payload,
    cb,
  };
}

// Advance Billing
export function getInAdvanceBillingConfig(payload, cb) {
  return {
    type: types.GET_FUTURE_ADVANCE_CFG_BILLING,
    payload,
    cb,
  };
}

export function getInAdvanceBillingConfigSuccess(payload, cb) {
  return {
    type: types.GET_FUTURE_ADVANCE_CFG_SUCCESS_BILLING,
    payload,
    cb,
  };
}

export function modifyInAdvanceBillingConfig(payload, cb) {
  return {
    type: types.MODIFY_FUTURE_ADVANCE_CFG_BILLING,
    payload,
    cb,
  };
}

export function createInAdvanceBillingConfig(payload, cb) {
  return {
    type: types.CREATE_FUTURE_ADVANCE_CFG_BILLING,
    payload,
    cb,
  };
}

// Pending Billing
export function getConfigPendingBillingTrigger(payload, cb) {
  return {
    type: types.GET_PENDING_BILL_TRIGGER,
    payload,
    cb,
  };
}

export function getConfigPendingBillingTriggerSuccess(payload, cb) {
  return {
    type: types.GET_PENDING_BILL_TRIGGER_SUCCESS,
    payload,
    cb,
  };
}

export function modifyConfigPendingBillingTrigger(payload, cb) {
  return {
    type: types.MODIFY_PENDING_BILL_TRIGGER,
    payload,
    cb,
  };
}

export function createConfigPendingBillingTrigger(payload, cb) {
  return {
    type: types.CREATE_PENDING_BILL_TRIGGER,
    payload,
    cb,
  };
}

export function searchEmployeeCosting(payload, cb) {
  return {
    type: types.SEARCH_EMPLOYEE_COSTING,
    payload,
    cb,
  };
}

export function searchEmployeeCostingSuccess(payload, cb) {
  return {
    type: types.SEARCH_EMPLOYEE_COSTING_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllEmployeeCosting(payload, cb) {
  return {
    type: types.SEARCH_ALL_EMPLOYEE_COSTING,
    payload,
    cb,
  };
}

export function searchAllEmployeeCostingSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_EMPLOYEE_COSTING_SUCCESS,
    payload,
    cb,
  };
}

export function createEmployeeCosting(payload, cb) {
  return {
    type: types.CREATE_EMPLOYEE_COSTING,
    payload,
    cb,
  };
}

export function searchProjectClients(payload, cb) {
  return {
    type: types.SEARCH_CLIENT_PROJECT,
    payload,
    cb,
  };
}

export function searchProjectClientsSuccess(payload, cb) {
  return {
    type: types.SEARCH_CLIENT_PROJECT_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllProjectClients(payload, cb) {
  return {
    type: types.SEARCH_ALL_CLIENT_PROJECT,
    payload,
    cb,
  };
}

export function searchAllProjectClientsSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_CLIENT_PROJECT_SUCCESS,
    payload,
    cb,
  };
}

// export function getCloudFileDataByFileName(payload, cb) {
//   return {
//     type: types.GET_CLOUD_FILE_DATA_BY_NAME,
//     payload,
//     cb,
//   };
// }

export function modifyProjectClient(payload, cb) {
  return {
    type: types.MODIFY_PROJECT_CLIENT,
    payload,
    cb,
  };
}

export function createProjectClient(payload, cb) {
  return {
    type: types.CREATE_PROJECT_CLIENT,
    payload,
    cb,
  };
}

export function getProjectClientById(payload, cb) {
  return {
    type: types.GET_PROJECT_CLIENT_BY_ID,
    payload,
    cb,
  };
}

export function searchSavedManualInvoice(payload, cb) {
  return {
    type: types.SEARCH_SAVED_MANUAL_INVOICE,
    payload,
    cb,
  };
}

export function getPendingManualInvoice(payload, cb) {
  return {
    type: types.GET_PENDING_MANUAL_INVOICE_BILLS,
    payload,
    cb,
  };
}

export function processSavedManualInvoice(payload, cb) {
  return {
    type: types.PROCESS_SAVED_MANUAL_INVOICE,
    payload,
    cb,
  };
}

export function modifyExpensesConfig(payload, cb) {
  return {
    type: types.MODIFY_EXPENSES_CONFIG,
    payload,
    cb,
  };
}

export function createExpensesConfig(payload, cb) {
  return {
    type: types.CREATE_EXPENSES_CONFIG,
    payload,
    cb,
  };
}

export function getExpensesConfig(payload, cb) {
  return {
    type: types.GET_EXPENSES_CONFIG,
    payload,
    cb,
  };
}

export function createExpenseData(payload, cb) {
  return {
    type: types.CREATE_EXPENSE_DATA,
    payload,
    cb,
  };
}

export function modifyExpenseData(payload, cb) {
  return {
    type: types.MODIFY_EXPENSE_DATA,
    payload,
    cb,
  };
}

export function searchExpenseData(payload, cb) {
  return {
    type: types.SEARCH_EXPENSE_DATA,
    payload,
    cb,
  };
}

export function createEmployeeBonus(payload, cb) {
  return {
    type: types.CREATE_EMPLOYEE_BONUS,
    payload,
    cb,
  };
}

export function modifyEmployeeBonus(payload, cb) {
  return {
    type: types.MODIFY_EMPLOYEE_BONUS,
    payload,
    cb,
  };
}

export function searchEmployeeBonus(payload, cb) {
  return {
    type: types.SEARCH_EMPLOYEE_BONUS,
    payload,
    cb,
  };
}
