import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'thresholdAmount',
        'accountType',
        'paymentMethod',
        'suspenseReason',
        'status',
        'useExchangeRate',
        'minThresholdAmount',
        'minUnallocatedPaymentThreshold',
        'maxThresholdAmount',
        'maxUnallocatedPaymentThreshold',
      ])}`
    : '';
  const getPaymentSuspenseReport = `getPaymentSuspenseReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getPaymentSuspenseReport} {
        accountId
        company
        firstName
        lastName
        accountType
        paymentDate
        paymentSuspenseId
        transactionId
        invoiceUnitId
        paymentMethod
        amount
        failureReason
        paymentTerm
        currency
        sourceCurrency
        referenceId
        paymentSource
        paymentSourceId
        paymentDescription
        organization
        sourceCurrencyAmount
        exchangeRate
        fileName
        fileDate
        userId
        unallocatedAmount
        status
        createdDate
        currentDateExchangeRate
        unRealizedGain
        closeReason
      }
    }
  `;
};
