import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateJobSchedule = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createJobSchedule });
};

const checkPermissionModifyJobSchedule = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyJobSchedule });
};

const checkPermissionGetJobSchedule = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getJobSchedule });
};

const checkPermissionUpdateJobScheduleStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.updateJobScheduleStatus });
};

const checkPermissionProcessJobSchedule = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processJobSchedule });
};

const checkPermissionReprocessJobSchedule = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.reprocessJobSchedule });
};

const checkPermissionCreateJob = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createJob });
};

const checkPermissionModifyJob = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyJob });
};

const checkPermissionUpdateJobStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.updateJobStatus });
};

const checkPermissionProcessJob = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processJob });
};

const checkPermissionReprocessJob = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.reprocessJob });
};

const checkPermissionCreateJobConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createJobConfig });
};

const checkPermissionModifyJobConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyJobConfig });
};

const checkPermissionFindJobConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.findJobConfig });
};

const checkPermissionGetJobStats = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getJobStats });
};

const checkPermissionModifyJobType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyJobType });
};

const checkPermissionRefreshJobSchedule = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.refreshJobSchedule });
};

export {
  checkPermissionCreateJobSchedule,
  checkPermissionModifyJobSchedule,
  checkPermissionGetJobSchedule,
  checkPermissionUpdateJobScheduleStatus,
  checkPermissionProcessJobSchedule,
  checkPermissionReprocessJobSchedule,
  checkPermissionCreateJob,
  checkPermissionModifyJob,
  checkPermissionUpdateJobStatus,
  checkPermissionProcessJob,
  checkPermissionReprocessJob,
  checkPermissionCreateJobConfig,
  checkPermissionModifyJobConfig,
  checkPermissionFindJobConfig,
  checkPermissionGetJobStats,
  checkPermissionModifyJobType,
  checkPermissionRefreshJobSchedule,
};
