import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.id'),
  t('label.batchId'),
  t('label.startDate'),
  t('label.endDate'),
  t('label.amount'),
  t('label.amountRecognized'),
  t('label.glAccount'),
  t('label.calendarPeriod'),
  t('label.revenueRecognitionType'),
  t('label.objectId'),
  t('label.objectType'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.journalId ? item.journalId : '');
      newItem.push(item.batchId ? item.batchId : '');
      newItem.push(item.revenueStartDate ? item.revenueStartDate : '');
      newItem.push(item.revenueEndDate ? item.revenueEndDate : '');
      newItem.push(formatMoneyValueToExport(item.amount));
      newItem.push(formatMoneyValueToExport(item.amountRecognized));
      newItem.push(item.glAccount ? item.glAccount : '');
      newItem.push(item.calendarPeriod ? item.calendarPeriod : '');
      newItem.push(
        getLabelOptions({ item, t, fieldName: 'revenueRecognitionType', fieldOption: 'revenueRecognitionType' })
      );
      newItem.push(item.objectId ? item.objectId : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'objectType', fieldOption: 'revenueObjectType' }));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, ...rest });
};

export default convertJson2Pdf;
