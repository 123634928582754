import gql from 'graphql-tag';

export default ({ id, page, size }) => gql`
  query {
    searchTenantOperatorRecordList(id: "${id}", page: ${page}, size: ${size}) {
      id
      startDate
      startTime
      trafficType
      nim
      callType
      index
      anumber
      bnumber
      duration
    }
  }
`;
