import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { PaymentGateway } from '../../../components/TenantHub';
import { TitleFrom } from '../../../components/common';
import { makeTenantMerchantDataConfig } from '../selectors';
import { getTenantMerchantDataConfig } from '../actions';

class GetewayFormFixed extends React.PureComponent {
  state = {
    tenantData: [{}, {}, {}],
    idState: null,
  };

  componentDidMount() {
    const { data } = this.props;
    this.doGetTenantMerchantDataConfig();
    this.setState({
      tenantData: data && data?.tenantMerchantAccounts?.length ? data?.tenantMerchantAccounts : [{}, {}, {}, {}, {}],
      idState: data?.id || null,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        tenantData:
          nextProps.data && nextProps?.data?.tenantMerchantAccounts?.length
            ? nextProps?.data?.tenantMerchantAccounts
            : [{}, {}, {}, {}, {}],
        idState: nextProps?.data?.id || null,
      });
    }
  }

  doGetTenantMerchantDataConfig = () => {
    const { getTenantMerchantDataConfig, getTenantConfig } = this.props;
    if (getTenantConfig) return getTenantConfig();
    return getTenantMerchantDataConfig();
  };

  onChange = ({ name, value, index }) => {
    const { onHandleChange, type } = this.props;
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    if (!newData[index]) newData[index] = { type };
    newData[index][name] = value;
    this.setState({ tenantData: newData });
    // onHandleChange({ name, value, index });
  };

  onChangeAttributes = ({ name, value, index, indexAttr, fieldName = '' }) => {
    try {
      const { tenantData } = this.state;
      // const { onChangeAttributes } = this.props;
      if (!tenantData[index][fieldName]) tenantData[index][fieldName] = [];
      if (!tenantData[index][fieldName][indexAttr]) tenantData[index][fieldName][indexAttr] = {};
      tenantData[index][fieldName][indexAttr][name] = value;
      this.setState({ tenantData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { modifyTenant, id, type } = this.props;
    const { tenantData, idState } = this.state;
    let newData = cloneDeep(tenantData);
    let lastIndex = 0;
    if (newData && newData.length) {
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
    }
    newData = newData.map(val => {
      const newVal = cloneDeep(val);
      if (!newVal.index) {
        newVal.index = lastIndex;
      }
      return newVal;
    });

    const itemSelect = newData.filter(item => item.type === type);
    modifyTenant({ id: id || idState, tenantMerchantAccounts: itemSelect });
  };

  render() {
    const { activeTab, tenantData } = this.state;
    const {
      modeSetPaymentGatewayMerchant,
      // modeSetTaxGatewayMerchant,
      // modeSetFinanceGatewayMerchant,
      // modeModifyPaymentGatewayMerchant,
      modeModifyTaxGatewayMerchant,
      // modeModifyFinanceGatewayMerchant,
      t,
      type,
    } = this.props;

    // if (modeSetPaymentGatewayMerchant === 0 || modeModifyPaymentGatewayMerchant === 0) {
    //   newCollapse = newCollapse.filter(item => item.state !== 'Payment Gateway');
    // }

    // if (modeSetTaxGatewayMerchant === 0 || modeModifyTaxGatewayMerchant === 0) {
    //   newCollapse = newCollapse.filter(item => item.state !== 'Tax Gateway');
    // }

    // if (modeSetFinanceGatewayMerchant === 0 || modeModifyFinanceGatewayMerchant === 0) {
    //   newCollapse = newCollapse.filter(item => item.state !== 'Finance Gateway');
    // }

    let dataHttpBasicAttributes = [];
    let dataJwtAttributes = [];
    let dataOauthAttributes = [];
    // let dataJwtAttributes = [];

    const indexDataHttpBasic = tenantData.findIndex(item => item.type === type);
    if (indexDataHttpBasic > -1) dataHttpBasicAttributes = tenantData[indexDataHttpBasic].httpBasicAttributes;
    const indexDataJwtAttributes = tenantData.findIndex(item => item.type === type);
    if (indexDataJwtAttributes > -1) dataJwtAttributes = tenantData[indexDataJwtAttributes].jwtAttributes;

    const indexDataOauthAttributes = tenantData.findIndex(item => item.type === type);
    if (indexDataOauthAttributes > -1) dataOauthAttributes = tenantData[indexDataOauthAttributes].oauthAttributes;

    if (modeSetPaymentGatewayMerchant === 0) return '';

    const dataSelect = tenantData.filter(item => item.type === type) || [];
    return (
      <form className="card card-statistics" onSubmit={this.onSubmit}>
        <TitleFrom title={t('tenantPage:tenantManagementPage.merchantData')} />
        <div className="gateway-form">
          <PaymentGateway
            {...this.props}
            onChangeAttributes={this.onChangeAttributes}
            data={tenantData.filter(item => item.type === type) || []}
            indexData={
              tenantData && tenantData.findIndex(item => item.type === type) !== -1
                ? tenantData.findIndex(item => item.type === type)
                : tenantData && tenantData.length
            }
            modeModifyTaxGatewayMerchant={modeModifyTaxGatewayMerchant}
            dataHttpBasicAttributes={
              dataSelect && dataSelect[0]?.httpBasicAttributes ? dataSelect[0]?.httpBasicAttributes : null
            }
            indexDataHttpBasic={indexDataHttpBasic > -1 ? indexDataHttpBasic : 0}
            indexDataJwtAttributes={indexDataJwtAttributes > -1 ? indexDataJwtAttributes : 0}
            dataJwtAttributes={dataSelect && dataSelect[0]?.jwtAttributes ? dataSelect[0]?.jwtAttributes : null}
            dataOauthAttributes={dataSelect && dataSelect[0]?.oauthAttributes ? dataSelect[0]?.oauthAttributes : null}
            indexDataOauthAttributes={indexDataOauthAttributes}
            onHandleChange={this.onChange}
          />
        </div>
        <div className="mt-3 mb-3 col-md-12">
          <button type="submit" className="button button-border ml-2 x-small float-right">
            {t('label.saveConfig')}
          </button>
          <button
            type="button"
            onClick={() => this.doGetTenantMerchantDataConfig()}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </div>
      </form>
    );
  }
}

GetewayFormFixed.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onHandleChange: PropTypes.func,
  onChangeAttributes: PropTypes.func,
  modeSetPaymentGatewayMerchant: PropTypes.number,
  modeSetTaxGatewayMerchant: PropTypes.number,
  modeSetFinanceGatewayMerchant: PropTypes.number,
  modeModifyPaymentGatewayMerchant: PropTypes.number,
  modeModifyTaxGatewayMerchant: PropTypes.number,
  modeModifyFinanceGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

GetewayFormFixed.defaultProps = {
  data: [{}, {}, {}],
  onHandleChange: () => {},
  onChangeAttributes: () => {},
  modeSetPaymentGatewayMerchant: 2,
  modeSetTaxGatewayMerchant: 2,
  modeSetFinanceGatewayMerchant: 2,
  modeModifyPaymentGatewayMerchant: 2,
  modeModifyTaxGatewayMerchant: 2,
  modeModifyFinanceGatewayMerchant: 2,
  isRequireField: false,
};

const mapStateToProps = createStructuredSelector({
  data: makeTenantMerchantDataConfig() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getTenantMerchantDataConfig })(GetewayFormFixed));
