import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import reducerCustomer from '../CustomerPage/reducer';
import reducerReport from '../ReportManagement/reducer';
import sagaCustomer from '../CustomerPage/saga';
import sagaReport from '../ReportManagement/saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import SeachList from './SearchList';
// import NewOrder from './NewOrder';
import NewOrder from './NewOrder';
// import TrialSubscription from './TrialSubscription';
import TrialSubscription from './TrialSubscription';
import OrderModify from './OrderModify';
import CancelSubscription from './OrderSubscription/CancelSubscription';
import RenewSubscription from './OrderSubscription/RenewSubscription';
import SuspendSubscription from './OrderSubscription/SuspendSubscription';
import ResumeSubscription from './OrderSubscription/ResumeSubscription';
import Sidebar from './Sidebar';
import OrderDetail from './OrderDetail';
import OptIn from './OptIn';
import OptOut from './OptOut';
import UpgradeOrder from './UpgradeOrder';
import DowngradeOrder from './DowngradeOrder';
import OrderSequence from './OrderSequence';
import ProvisioningSequence from './ProvisioningSequence';
import ProvisioningMapping from './ProvisioningMapping';
import CustomAttributes from './CustomAttributes';
import OrderReasonCodes from './OrderReasonCodes';
import MoveProvisioning from './MoveProvisioning';
import { makeGetPermissionsOrderManagement } from '../App/selectors';
import {
  checkPermissionCreateOrder,
  checkPermissionNewOrderType,
  checkPermissionModifyOrderType,
  checkPermissionSuspendOrderType,
  checkPermissionResumeOrderType,
  checkPermissionCancelOrderType,
  checkPermissionGetOmsTaskConfig,
  checkPermissionGetProvSequenceTaskConfig,
  checkPermissionGetOrderReasonCode,
  checkPermissionGetProvisioningAttributes,
  checkPermissionMoveProvisioningOrderType,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class OrderManagement extends PureComponent {
  render() {
    const { permissionOrder, t } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionOrder, listKeys: permissionList.orderManagement }))
      return null;
    let modeCreateOrder = 0;
    let modeNewOrderType = 0;
    let modeModifyOrderType = 0;
    let modeSuspendOrderType = 0;
    let modeResumeOrderType = 0;
    let modeCancelOrderType = 0;
    let modeGetOmsConfig = 0;
    let modeGetProvSequenceTaskConfig = 0;
    let modeGetOrderReasonCode = 0;
    let modeGetProvisioningAttributes = 0;
    let modeMoveProvisioningOrderType = 0;
    const configOrderSidebar = [];
    const configProvisioningSidebar = [];
    const orderSidebar = [];

    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeCreateOrder = checkPermissionCreateOrder({ listPermission });
      modeNewOrderType = checkPermissionNewOrderType({ listPermission });
      modeModifyOrderType = checkPermissionModifyOrderType({ listPermission });
      modeSuspendOrderType = checkPermissionSuspendOrderType({ listPermission });
      modeResumeOrderType = checkPermissionResumeOrderType({ listPermission });
      modeCancelOrderType = checkPermissionCancelOrderType({ listPermission });
      modeCancelOrderType = checkPermissionCancelOrderType({ listPermission });
      modeGetOmsConfig = checkPermissionGetOmsTaskConfig({ listPermission });
      modeGetProvSequenceTaskConfig = checkPermissionGetProvSequenceTaskConfig({ listPermission });
      modeGetOrderReasonCode = checkPermissionGetOrderReasonCode({ listPermission });
      modeGetProvisioningAttributes = checkPermissionGetProvisioningAttributes({ listPermission });
      modeMoveProvisioningOrderType = checkPermissionMoveProvisioningOrderType({ listPermission });
    }

    orderSidebar.push({
      icon: 'fa fa-search',
      label: 'orderPage:sidebar.searchOrder',
      href: RouteNames.searchAndList.path,
    });

    if (modeCreateOrder === 2 || modeNewOrderType === 2) {
      orderSidebar.push({
        icon: 'fa fa-file-text-o',
        label: 'orderPage:sidebar.newOrder',
        href: RouteNames.newOrder.path,
      });
    }

    if (modeModifyOrderType) {
      orderSidebar.push({
        icon: 'ti-pencil-alt',
        label: 'orderPage:sidebar.modifyOrder',
        href: RouteNames.modifyOrder.path,
      });
    }

    if (modeMoveProvisioningOrderType) {
      orderSidebar.push({
        icon: 'ti-move',
        label: 'common:label.moveProvisioningOrder',
        href: RouteNames.moveProvisioning.path,
      });
    }

    if (modeSuspendOrderType) {
      orderSidebar.push({
        icon: 'fa fa-pause-circle-o',
        label: 'orderPage:sidebar.suspendOrder',
        href: RouteNames.suspendOrder.path,
      });
    }

    // if (modeResumeOrderType) {
    //   orderSidebar.push({
    //     icon: 'ti-mobile',
    //     label: 'orderPage:sidebar.suspendOrder',
    //     href: RouteNames.suspendOrder.path,
    //   });
    // }

    if (modeResumeOrderType) {
      orderSidebar.push({
        icon: 'ti-check-box',
        label: 'orderPage:sidebar.resumeOrder',
        href: RouteNames.resumeOrder.path,
      });
    }

    if (modeCancelOrderType) {
      orderSidebar.push({
        icon: 'ti-close',
        label: 'orderPage:sidebar.cancelOrder',
        href: RouteNames.cancelOrder.path,
      });
    }

    orderSidebar.push({
      icon: 'ti-reload',
      label: 'orderPage:sidebar.renewOrder',
      href: RouteNames.renewOrder.path,
    });

    if (modeCreateOrder === 2 || modeNewOrderType === 2) {
      orderSidebar.push({
        icon: 'ti-ruler-alt',
        label: 'orderPage:sidebar.trialSubsription',
        href: RouteNames.trialSubscription.path,
      });
    }

    configOrderSidebar.push({
      icon: 'ti-panel',
      label: 'customerPage:sidebarHandleAccountPage.customAttributes',
      href: RouteNames.customAttributes.path,
    });
    if (modeGetOrderReasonCode)
      configOrderSidebar.push({
        icon: 'ti-blackboard',
        label: 'orderPage:sidebar.resonCode',
        href: RouteNames.orderResonCode.path,
      });
    if (modeGetOmsConfig) {
      configOrderSidebar.push({
        icon: 'ti-unlock',
        label: 'orderPage:sidebar.sequence',
        href: RouteNames.orderSequence.path,
      });
    }
    // if (modeGetProvisioningAttributes) {
    //   configProvisioningSidebar.push({
    //     icon: 'ti-menu',
    //     label: 'orderPage:sidebar.attributes',
    //     href: RouteNames.provisioningAttributes.path,
    //   });
    // }
    if (modeGetProvSequenceTaskConfig) {
      configProvisioningSidebar.push({
        icon: 'ti-map',
        label: 'orderPage:sidebar.data',
        href: RouteNames.provisioningMapping.path,
      });
    }
    if (modeGetProvSequenceTaskConfig) {
      configProvisioningSidebar.push({
        icon: 'ti-panel',
        label: 'orderPage:sidebar.sequence',
        href: RouteNames.provisioningSequence.path,
      });
    }

    const sideProps = {
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'common:label.config',
          isHiddenItems: false,
          subMain: [
            {
              subMainTitle: 'common:label.order',
              key: 'order',
              childs: configOrderSidebar,
            },
            {
              subMainTitle: 'common:label.provisioning',
              key: 'provisioning',
              childs: configProvisioningSidebar,
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'common:label.order',
          childs: [
            ...orderSidebar,
            {
              icon: 'fa fa-thumbs-o-up',
              label: 'orderPage:sidebar.optIn',
              href: RouteNames.optIn.path,
              // disable: true,
            },
            {
              icon: 'fa fa-thumbs-o-down',
              label: 'orderPage:sidebar.optOut',
              href: RouteNames.optOut.path,
              // disable: true,
            },
            {
              icon: 'fa fa-arrow-up',
              label: 'orderPage:sidebar.upgradeOrder',
              href: RouteNames.upgradeOrder.path,
              // disable: true,
            },
            {
              icon: 'fa fa-arrow-down',
              label: 'orderPage:sidebar.downgradeOrder',
              href: RouteNames.downgradeOrder.path,
            },
          ],
        },
      ],
    };

    const newSideProps = cloneDeep(sideProps);

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar sideProps={newSideProps} />
          <div className="content-wrapper">
            <Switch>
              {/* <Route exact path="/orders" component={SeachList} /> */}
              <Route exact path={RouteNames.searchAndList.path} component={SeachList} />
              {/* <Route exact path={RouteNames.newOrder.path} component={NewOrder} /> */}
              <Route exact path={RouteNames.newOrder.path} component={NewOrder} />
              <Route exact path={RouteNames.orderDetail.path} component={OrderDetail} />
              <Route exact path={RouteNames.modifyOrder.path} component={OrderModify} />
              {/* <Route exact path="/orders/suspend" component={props => <OrderSubscription isSuspend {...props} />} />
              <Route exact path="/orders/resume" component={props => <OrderSubscription isResume {...props} />} />
              <Route exact path="/orders/cancel" component={props => <OrderSubscription isCancel {...props} />} />
              <Route exact path="/orders/renew" component={props => <OrderSubscription {...props} />} /> */}
              {/* <Route exact path="/orders/suspend" component={Sus} /> */}
              <Route exact path={RouteNames.suspendOrder.path} component={SuspendSubscription} />
              <Route exact path={RouteNames.resumeOrder.path} component={ResumeSubscription} />
              <Route exact path={RouteNames.cancelOrder.path} component={CancelSubscription} />
              <Route exact path={RouteNames.renewOrder.path} component={RenewSubscription} />
              <Route exact path={RouteNames.trialSubscription.path} component={TrialSubscription} />
              <Route exact path={RouteNames.optIn.path} component={OptIn} />
              <Route exact path={RouteNames.optOut.path} component={OptOut} />
              <Route exact path={RouteNames.upgradeOrder.path} component={UpgradeOrder} />
              <Route exact path={RouteNames.downgradeOrder.path} component={DowngradeOrder} />
              <Route exact path={RouteNames.orderSequence.path} component={OrderSequence} />
              <Route exact path={RouteNames.provisioningSequence.path} component={ProvisioningSequence} />
              <Route exact path={RouteNames.provisioningMapping.path} component={ProvisioningMapping} />
              <Route exact path={RouteNames.customAttributes.path} component={CustomAttributes} />
              <Route exact path={RouteNames.orderResonCode.path} component={OrderReasonCodes} />
              <Route exact path={RouteNames.moveProvisioning.path} component={MoveProvisioning} />
              {/* <Route exact path={RouteNames.provisioningAttributes.path} component={ProvisioningAttributesMap} /> */}
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducerCustomer = injectReducer({ key: 'customerReducer', reducer: reducerCustomer });
const withSagaCustomer = injectSaga({ key: 'customerSaga', saga: sagaCustomer });
const withReducer = injectReducer({ key: 'orderReducer', reducer });
const withSaga = injectSaga({ key: 'orderSaga', saga });
const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reducerReport });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: sagaReport });

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

OrderManagement.propTypes = {
  permissionOrder: PropTypes.objectOf(PropTypes.any),
};

OrderManagement.defaultProps = {
  permissionOrder: {},
};

const withConnect = connect(mapStateToProps, null);

const newOrderManagement = compose(
  withReducerCustomer,
  withSagaCustomer,
  withReducerReport,
  withSagaReport,
  withReducer,
  withSaga,
  withConnect
)(OrderManagement);

export default withTranslation(['orderPage', 'common'])(newOrderManagement);
