import React, { useState, useEffect, useRef } from 'react';
import PropsType from 'prop-types';
import { map, isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import GenericInput from '../GenericInput';
import { validate } from '../../../utils/utils';

const ModalEditItem = ({ title, isOpen, onToggle, data, dataField, isSearch, children, onSave, wrapperClass }) => {
  const formRef = useRef();
  const { t } = useTranslation('common');
  const [itemData, setData] = useState(cloneDeep(data));
  const [wasValidated, setValidate] = useState(false);

  const onChangeData = ({ name, value }) => {
    const newData = cloneDeep(itemData);
    newData[name] = value;
    setData(newData);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    return onSave({ data: itemData });
  };

  useEffect(() => {
    setData(cloneDeep(data));
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={`modal-lg info-form ${wrapperClass || ''}`}>
      <form ref={formRef} noValidate className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {isSearch ? (
            <div className="text-center">
              <PulseLoader size={13} color="#28a745" />
            </div>
          ) : (
            <div className="row col-md-12">
              {!isEmpty(dataField) &&
                map(dataField, item => {
                  if (item && item.isSelect) {
                    return (
                      <GenericInput
                        key={item.name}
                        label={t && item.label ? t(item.label) : item.label || ''}
                        type="select"
                        value={itemData[item.name]}
                        onChange={onChangeData}
                        {...item}
                      />
                    );
                  }

                  if (item && item.fieldDate) {
                    return (
                      <GenericInput
                        key={item.name}
                        type="date"
                        isClearable
                        label={t && item.label ? t(item.label) : item.label || ''}
                        dateFormat="yyyy-MM-dd"
                        value={itemData[item.name]}
                        onChange={onChangeData}
                        {...item}
                      />
                    );
                  }

                  return (
                    <GenericInput
                      key={item.name}
                      value={itemData[item.name]}
                      label={t && item.label ? t(item.label) : item.label || ''}
                      type={item.type || 'text'}
                      onChange={onChangeData}
                      {...item}
                    />
                  );
                })}
            </div>
          )}
          {children}
        </ModalBody>
        <ModalFooter>
          <button onClick={onToggle} type="button" className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
          <button type="button" className="button x-small" onClick={onSubmit}>
            {t('label.saveConfig')}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalEditItem.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

ModalEditItem.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

export default ModalEditItem;
