import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'accountType',
    'paymentMethod',
    'objectType',
    'customerSegment',
    'sequenceSeed',
    'minimumLength',
    'index',
  ]);

  const createCustomDBSeq = `createCustomDBSeq(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createCustomDBSeq} {
        id
      }
    }
  `;
};
