import gql from 'graphql-tag';

export default id => gql`
  query {
    getQuoteById(id: "${id}") {
      id
      type
      status
      reason
      accountId
      userId
      clientId
      isPartialFulfillmentAllowed
      effectiveDate
      submittedDate
      initialTerm
      initialTermUnit
      renewalTerm
      renewalTermUnit
      trialTerm
      trialTermUnit
      trialType
      salesAgentId
      oldQuoteDetails {
        netQuote
        grossQuote
        quoteDiscount
        serviceLevelQuoteDetails {
          serviceType
          netQuote
          grossQuote
          quoteDiscount
          action
        }
        priceOfferQuoteDetails {
          priceOfferId
          priceOfferName
          serviceType
          transactionType
          netQuote
          grossQuote
          quoteDiscount
          bundleId
          bundleName
          packageId
          packageName
          proratedCharge
          recurringUnit
          recurringFrequency
          scale
          action
        }
        discountOfferQuoteDetails {
          discountOfferId
          discountOfferName
          serviceType
          transactionType
          amount
          percent
          bundleId
          bundleName
          packageId
          packageName
          proratedDiscount
          recurringUnit
          recurringFrequency
          scale
          action
        }
      }
      newQuoteDetails {
        netQuote
        grossQuote
        quoteDiscount
        serviceLevelQuoteDetails {
          serviceType
          netQuote
          grossQuote
          quoteDiscount
          action
        }
        priceOfferQuoteDetails {
          priceOfferId
          priceOfferName
          serviceType
          transactionType
          netQuote
          grossQuote
          quoteDiscount
          bundleId
          bundleName
          packageId
          packageName
          proratedCharge
          recurringUnit
          recurringFrequency
          scale
          action
        }
        discountOfferQuoteDetails {
          discountOfferId
          discountOfferName
          serviceType
          transactionType
          amount
          percent
          bundleId
          bundleName
          packageId
          packageName
          proratedDiscount
          recurringUnit
          recurringFrequency
          scale
          action
        }
      }
    }
  }
`;
