import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { cloneDeep, size } from 'lodash';
import { getLastIndex } from '../../../utils/utils';
import SubForm from '../../../components/form/SubForm';
import ModalBundleItem from './ModalBundleItem';
import ModalPackageItem from './ModalPackageItem';

class BundleComponent extends Component {
  state = {
    components: [],
    selectedComponentIndex: null,
    isSelectingBundleId: false,
    isSelectingPackageItemId: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldComponents === props.components && state.lockedType === props.lockedType) return null;
    return {
      components: props.components,
      oldComponents: [...props.components],
    };
  }

  onChangeComponents = () => {
    const { components } = this.state;
    const { onChangeComponents } = this.props;
    const newComponents = cloneDeep(components).map(val => {
      const { bundleName, packageName, ...rest } = val;
      return rest;
    });

    onChangeComponents(newComponents);
  };

  handleRemoveItem = (idx, item) => {
    const { components } = this.state;
    const { creatingMode } = this.props;
    if (creatingMode || (components[idx] && components[idx].isNew)) {
      components.splice(idx, 1);
    } else {
      components[idx] = { index: components[idx].index };
    }

    this.setState({ components }, () => this.onChangeComponents());
  };

  handleChangeItem = (index, item) => {
    const { components } = this.state;
    components[index] = item;
    this.setState({ components }, () => this.onChangeComponents());
  };

  handleClickBundleId = (index, input, comp) => {
    if (input.name === 'bundleName') {
      this.setState({
        isSelectingBundleId: true,
        isSelectingPackageItemId: false,
        selectedBundleId: comp.bundleId,
        selectedComponentIndex: index,
      });
    } else if (input.name === 'packageName') {
      this.setState({
        isSelectingBundleId: false,
        isSelectingPackageItemId: true,
        selectedPackageItemId: comp.packageId,
        selectedComponentIndex: index,
      });
    }
  };

  onSelectBundleId = (bundleId, rows) => {
    const { components, selectedComponentIndex } = this.state;
    const { onAddNewComponentFromSelect, creatingMode } = this.props;
    if (rows && rows.length) {
      const [r, ...rest] = rows;
      components[selectedComponentIndex].bundleId = r.id;
      components[selectedComponentIndex].name = r.name;
      if (rest && rest.length && onAddNewComponentFromSelect) {
        const listNewItems = rest.map((v, index) => {
          const { name, id } = v;
          if (creatingMode) {
            return {
              name,
              bundleId: id,
              type: components[selectedComponentIndex].type || null,
              isNew: true,
            };
          }
          return {
            index: getLastIndex({ data: components }) + index,
            isNew: true,
            name,
            bundleId: id,
            type: components[selectedComponentIndex].type || null,
          };
        });
        onAddNewComponentFromSelect(listNewItems);
      }
    } else {
      components[selectedComponentIndex].bundleId = null;
      components[selectedComponentIndex].name = null;
    }
    this.setState({ components, isSelectingBundleId: false, isSelectingPackageItemId: false }, () =>
      this.onChangeComponents()
    );
  };

  onSelectPackageItemId = (packageIds, rows) => {
    const { components, selectedComponentIndex } = this.state;
    const { onAddNewComponentFromSelect, creatingMode } = this.props;

    if (rows && rows.length) {
      const [r, ...rest] = rows;
      components[selectedComponentIndex].packageId = r.id;
      components[selectedComponentIndex].name = r.name;
      if (rest && rest.length && onAddNewComponentFromSelect) {
        const listNewItems = rest.map((v, index) => {
          const { name, id } = v;
          if (creatingMode) {
            return {
              isNew: true,
              name,
              packageId: id,
              type: components[selectedComponentIndex].type || null,
            };
          }
          return {
            index: getLastIndex({ data: components }) + index,
            isNew: true,
            name,
            packageId: id,
            type: components[selectedComponentIndex].type || null,
          };
        });
        onAddNewComponentFromSelect(listNewItems);
      }
    } else {
      components[selectedComponentIndex].packageId = null;
      components[selectedComponentIndex].name = null;
    }
    this.setState({ components, isSelectingBundleId: false, isSelectingPackageItemId: false }, () =>
      this.onChangeComponents()
    );
  };

  onCancelSelector = () => {
    this.setState({ isSelectingBundleId: false, isSelectingPackageItemId: false });
  };

  getComponentInputs = (idx, comp) => {
    const componentInputs = [
      {
        label: 'common:label.type',
        wrapperClass: 'col-md-4',
        name: 'type',
        type: 'select',
        isClearable: true,
        required: true,
        tOptions: 'selections:dependencyType',
        menuPortalTarget: true,
      },
      {
        label: 'common:label.bundleName',
        wrapperClass: 'col-md-4',
        name: 'bundleName',
        type: 'text',
        disabled: !!comp.packageId || this.props.lockedType === 'packageId',
        readOnly: !!comp.packageId || this.props.lockedType === 'packageId',
        fa: 'fa fa-external-link',
      },
      {
        label: 'common:label.packageName',
        wrapperClass: 'col-md-4',
        name: 'packageName',
        type: 'text',
        disabled: !!comp.bundleId || this.props.lockedType === 'bundleId',
        readOnly: !!comp.bundleId || this.props.lockedType === 'bundleId',
        fa: 'fa fa-external-link',
      },
    ];
    const wrapperInputs = componentInputs.map(input => {
      if (input.name === 'packageName' || input.name === 'bundleName') {
        input.onClick = e => this.handleClickBundleId(idx, input, comp);
        return input;
      }
      return input;
    });
    return wrapperInputs;
  };

  render() {
    const { t } = this.props;
    const { components = [] } = this.state;
    if (!components || components.length === 0) return null;
    return (
      <div className="card nested-section-card">
        <div className="card-header">{t('label.dependencyList')}</div>
        <div className="card-body">
          {components.map((comp, idx) => {
            if (size(comp) < 2) return '';
            const newComp = cloneDeep(comp);
            if (comp.bundleId && comp.name && !comp.bundleName) {
              newComp.bundleName = comp.name;
            }
            if (comp.packageId && comp.name && !comp.packageName) {
              newComp.packageName = comp.name;
            }
            return (
              <SubForm
                key={`component-idx-${idx}`}
                headerTitle={`${t('label.index').toUpperCase()}#${idx + 1}`}
                inputs={this.getComponentInputs(idx, newComp)}
                item={newComp}
                index={idx}
                isRemoveable={components.length > 1}
                onRemoveItem={e => this.handleRemoveItem(idx, newComp)}
                onChangeItem={e => this.handleChangeItem(idx, e)}
              />
            );
          })}
        </div>
        <ModalBundleItem
          isOpen={this.state.isSelectingBundleId}
          selectedParentId={this.state.selectedBundleId}
          onSelect={this.onSelectBundleId}
          onCancel={this.onCancelSelector}
          isRequired={false}
          isMultipe
        />
        <ModalPackageItem
          isOpen={this.state.isSelectingPackageItemId}
          selectedParentId={this.state.selectedPackageItemId}
          onSelect={this.onSelectPackageItemId}
          onCancel={this.onCancelSelector}
          isRequired={false}
          isMultipe
        />
      </div>
    );
  }
}

BundleComponent.propTypes = {
  components: PropTypes.any.isRequired,
  lockedType: PropTypes.any,
  onChangeComponents: PropTypes.func,
};

BundleComponent.defaultProps = {
  components: [{}],
};

export default withTranslation('common')(BundleComponent);
