import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['apiName', 'status']);
  const bulkProcess = `bulkProcess(input: ${dataModify})`;
  return gql`
  mutation {
    ${bulkProcess}
  }
`;
};
