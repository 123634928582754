import React from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ModalViewMessage = ({ isOpen, message, onToggle, wrapperClass }) => {
  const { t } = useTranslation('common');
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalBody className={wrapperClass || ''}>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onToggle}>
          {t('label.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalViewMessage.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  message: PropsType.string,
  wrapperClass: PropsType.string,
};

ModalViewMessage.defaultProps = {
  message: '',
  wrapperClass: '',
};

export default ModalViewMessage;
