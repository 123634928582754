export default {
  sidebarSearchPage: {
    config: {
      title: 'Configuración',
      subTitle: {
        customAttributes: 'Atributos Personalizados',
        creditProfile: 'Pefil Crediticio',
        delayedBilling: 'Facturación Retrasada',
        specialPricing: 'Precios Especiales',
        // specialPricing: 'Special Pricing',
        accountMovement: 'Movimiento de Cuenta',
        merge: 'Fusionar',
        disperse: 'Dispersar',
        collectionProfile: 'Collection Profile',
      },
    },
    account: {
      title: 'Cuenta',
      subscriptionData: 'Datos de Suscripción',
      accountData: 'Datos de la Cuenta',
      billingData: 'Datos de Facturación',
      searchAccounts: 'Buscar Cuentas',
    },
  },
  sidebarHandleAccountPage: {
    detailCreate: 'Creación Detallada',
    accountInfo: 'Informacion de Cuenta',
    contact: 'Contacto',
    addresses: 'Direcciones',
    paymentProfile: 'Perfil de pago',
    billingProfile: 'Perfil de Facturación',
    services: 'Servicios',
    assets: 'Activos',
    balances: 'Saldos',
    bills: 'Facturas',
    transactions: 'Transacciones',
    ARActivity: 'Actividad CxC',
    customerActivity: 'Actividad del Cliente',
    hierarchy: 'Jerarquía',
    // hierarchy: 'Hierarchy',
    customAttributes: 'Atributos Personalizados',
    externalPO: 'Orden de Compra Externa',
    accountStatement: 'Estado de Cuenta',
    // creditNotes: 'Notas de Crédito',
    creditNotes: 'Notas de Crédito / Débito',
    provisioningData: 'Datos de Aprovisionamiento',
    payments: 'Pagos',
    xChangeRates: 'Tipos de Cambio',
    paymentInstallment: 'Cuota de Pago',
    ratedUsage: 'Uso Tasado',
    usageRecords: 'Registros de Uso',
    ladingBill: 'Carta Porte',
    topup: 'Recarga',
    offers: 'Ofertas',
    others: 'Otros',
    quickCreate: 'Creación Rápida',
    invoicePaymentInstallment: 'Plazos de Pago de Facturas',
    taxExemptions: 'Exenciones Fiscales',
    sharing: 'Compartiendo',
    chargeShare: 'Cargo Compartido',
    discountShare: 'Descuento Compartido',
    subscriptionView: 'Vista de Suscripcion',
  },
  createAccount: {
    createAccountInfo: 'Crear Información de Cuenta',
    createContact: 'Crear Contacto',
    createAddress: 'Crear Dirección',
    createPaymentProfile: 'Crear Perfil de Pago',
    createBillingProfile: 'Crear Perfil de Facturación',
    createCustomerAttributes: 'Crear atributos del cliente',
    createAccount: 'Crear Cuenta',
  },
};
