import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['accountType', 'paymentMethod', 'noOfDays', 'index', 'surcharge']);
  const createConfigPaymentSurcharge = `createConfigPaymentSurcharge(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigPaymentSurcharge} {
      id
    }
  }
`;
};