export default {
  type: {
    regular: 'REGULAR',
    corrective: 'CORRECTIVE',
    regenerated: 'REGENERATED'
  },
  status: {
    active: 'ACTIVE',
    closed: 'CLOSED',
    collection: 'COLLECTION',
  },
};
