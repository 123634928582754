import { cloneDeep } from 'lodash';
import { convertHeaderExcelFile, convertBodyExcelFile } from '../exportHelper';

const multiDataSetDefault = ({ t, title, columnsTable, data }) =>
  convertHeaderExcelFile({ title, columnsTable, t, defaultPositionHeader: data ? data.length / 2 : 1 });

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(
    multiDataSetDefault({
      t,
      title,
      columnsTable,
      data,
    })
  );
  const newListData =
    data && data.length
      ? data.map(item => {
          return convertBodyExcelFile({ item, columnsTable, t });
        })
      : [];
  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  if (newDataSheetSet[1].data) {
    const newData = newDataSheetSet[1].data;
    const newDataConvert = [];
    if (newData && newData[0]) {
      const [listItem, ...rest] = newData;
      listItem.forEach((item, index) => {
        const { style, ...it } = item;
        newDataConvert[index] = [];
        newDataConvert[index].push({
          ...it,
          style: index === 0 ? style : it.isSupportBold ? { font: { bold: true } } : null,
        });
        if (rest && rest.length) {
          rest.forEach(rst => {
            if (rst && rst.length) {
              rst.forEach((lt, indexLt) => {
                if (index === indexLt)
                  newDataConvert[index].push({
                    ...lt,
                    style: index === 0 || style?.textColor === '#D00000' ? style : lt.style || null,
                  });
              });
            }
          });
        }
      });
    }
    newDataSheetSet[1].data = newDataConvert;
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
