import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, QuickNotesConfig } from '../common';

const PageTitle = ({
  linkTo,
  titleBtn,
  titleRight,
  items,
  disabled,
  isShowNoteConfig,
  children,
  messageNote,
  isNoDefaultButton,
}) => {
  const { t } = useTranslation('common');
  return (
    <div className="page-title">
      <div className="row">
        <div className="col-sm-6">
          {!isNoDefaultButton && (
            <NavLink className="button x-small" to={linkTo} disabled={disabled}>
              {titleBtn || t('label.createNew')}
            </NavLink>
          )}
          {isShowNoteConfig && <QuickNotesConfig messageNote={messageNote} />}
          {children}
        </div>
        <div className="col-sm-6">
          {isEmpty(items) ? (
            <ol className="breadcrumb pt-0 float-left float-sm-right ">
              <li className="breadcrumb-item">
                <NavLink to="/" className="default-color">
                  {t('label.home')}
                </NavLink>
              </li>
              <li className="breadcrumb-item active">{titleRight || 'Accounts'}</li>
            </ol>
          ) : (
            <Breadcrumb items={items} />
          )}
        </div>
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  linkTo: PropTypes.string,
  titleBtn: PropTypes.string,
  titleRight: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

PageTitle.defaultProps = {
  linkTo: '',
  titleBtn: '',
  titleRight: '',
  items: [],
  disabled: false,
};

export default PageTitle;
