import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../components/common';
import { newSubscriptionsDetailsField } from '../constants';

class SubscriptionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, onHandleChange, isDisabled } = this.props;
    return (
      <div className="card card-statistics">
        <div className="card-body">
          <div className="group-flex pb-3">
            {newSubscriptionsDetailsField.map(item => (
              <GenericInput
                key={item.name}
                value={data ? data[item.name] || null : null}
                onChange={({ name, value }) => onHandleChange({ name, value })}
                wrapperClass={item.wrapperClass || 'col-md-4'}
                disabled={isDisabled}
                readOnly={isDisabled}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

SubscriptionsForm.propTypes = {};

export default withTranslation('common')(SubscriptionsForm);
