import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';
import { CheckField, DataTable, TablePagination } from '../../../components/common';
import { getPageTotalCount } from '../../../utils/utils';

class SelectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: props.defaultFilter,
      sort: '',
      totalCount: null,
      isSearching: false,
      listItems: [],
      saveListItems: [],
      selectedItemIds: [],
      initSelectedParentId: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initSelectedParentId === props.selectedItemIds) return null;
    return {
      selectedItemIds: props.selectedItemIds,
      initSelectedParentId: props.selectedItemIds,
    };
  }

  componentDidUpdate(prevProps) {
    const { mode, isNeedSupportRefetch } = this.props;
    if (mode && prevProps.mode !== mode && isNeedSupportRefetch) this.doSearchItems();
  }

  onClickRow = (e, rowItem) => {
    e.preventDefault();
    const { selectedItemIds, listItems, saveListItems } = this.state;
    const { onClickRow, isSingleSelect } = this.props;
    const index = selectedItemIds.findIndex(item => item === rowItem.id);
    if (index === -1) {
      if (isSingleSelect) {
        this.setState({ selectedItemIds: [rowItem.id] }, () => {
          if (onClickRow) {
            onClickRow([rowItem.id], listItems);
          }
        });
        return;
      }
      selectedItemIds.push(rowItem.id);
    } else {
      selectedItemIds.splice(index, 1);
    }
    this.setState({ selectedItemIds }, () => {
      if (onClickRow) {
        onClickRow(selectedItemIds, saveListItems);
      }
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sort, filter, totalCount, saveListItems } = this.state;
    const { mode, isNeedSupportRefetch, searchItems } = this.props;
    if (isNeedSupportRefetch && mode === 'none') return null;
    this.setState({ isSearching: true });
    const filterData = {};
    map(filter, (item, key) => {
      if (item && item !== '') {
        filterData[key] = item;
      }
    });

    searchItems(
      {
        page: page + 1,
        size,
        filter: filterData,
      },
      resp => {
        const listItems = resp.success ? resp.data : [];
        this.setState({
          isSearching: false,
          page,
          size,
          filter,
          sort,
          listItems: [...listItems],
          saveListItems: [...saveListItems, ...listItems],
          totalCount: getPageTotalCount({ page, size, totalCount: totalCount || listItems.length, items: listItems }),
        });
      }
    );
  };

  render() {
    const { listItems, selectedItemIds, size, isSearching, page, totalCount } = this.state;
    const { tableColumns, SearchForm, children, modalTitle, t } = this.props;
    const columns = [
      {
        name: 'Selected',
        label: 'label.selected',
        render: (colName, item) => {
          return (
            <CheckField
              name="selectedItemIds"
              value={item.id}
              checked={selectedItemIds.findIndex(value => value === item.id) !== -1}
              readOnly
            />
          );
        },
      },
      ...tableColumns,
    ];
    const tableConfig = {
      columns,
      data: listItems,
      getRowClass: item => {
        return selectedItemIds.findIndex(value => value === item.id) !== -1 ? 'selected-row' : '';
      },
    };
    return (
      <div>
        <h6 className="mb-3">{t(modalTitle)}</h6>
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <SearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={isSearching}
                    isHaveClearButton
                    onClickClearButton={this.onSubmitSearch}
                  />
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} onClickRow={this.onClickRow} isLoading={isSearching} />
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-0">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

SelectTable.propTypes = {
  selectedItemIds: PropTypes.any,
  tableColumns: PropTypes.array.isRequired,
  defaultFilter: PropTypes.object,
  searchItems: PropTypes.func.isRequired,
  SearchForm: PropTypes.any.isRequired,
};
SelectTable.defaultProps = {
  selectedItemIds: [],
  defaultFilter: {},
};

export default withTranslation('common')(SelectTable);
