import React from 'react';
import PropsType from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class ModalAccept extends React.Component {
  state = {};

  render() {
    const { isOpen, message, onToggle, onAcceptModal, t, headerMessage, isWarning } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={onToggle}>
        {headerMessage && (
          <ModalHeader>
            {isWarning && <i className="fa fa-exclamation-triangle warning-ico" />}
            <span className="modal-accept-message-header">{headerMessage}</span>
          </ModalHeader>
        )}
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onToggle}>
            {t('label.no')}
          </Button>
          <Button color="primary" onClick={onAcceptModal}>
            {t('label.yes')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalAccept.propTypes = {
  onToggle: PropsType.func.isRequired,
  onAcceptModal: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  message: PropsType.string,
  headerMessage: PropsType.string,
};

ModalAccept.defaultProps = {
  message: '',
  headerMessage: '',
};

export default withTranslation('common')(ModalAccept);
