import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../common';
import listCurrency from '../../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class TierForm extends PureComponent {
  state = { data: { discounts: [], accumulators: [] } };

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (data) this.setState({ data });
  }

  render() {
    const { accumulatorInputs, isComplexUsage, index, t, discountingModel, transactionType } = this.props;

    const { data } = this.state;
    return (
      <div>
        <div>
          {data &&
            data.discounts &&
            data.discounts.length > 0 &&
            data.discounts.map((price, idx) => {
              const priceInputs = [
                {
                  label: 'label.currency',
                  wrapperClass: 'col-md-3',
                  name: 'currencyId',
                  type: 'select',
                  required: true,
                  options: currencyOptions,
                  disabled: isComplexUsage && index > 1,
                  menuPortalTarget: true,
                },
                {
                  label: 'label.amount',
                  wrapperClass: 'col-md-3',
                  name: 'amount',
                  type: 'number',
                  disabled: price.percent,
                  isNegativeValue: true,
                },
                {
                  label: 'label.percent',
                  wrapperClass: 'col-md-3',
                  name: 'percent',
                  type: 'number',
                  disabled: price.amount,
                  isNegativeValue: true,
                },
              ];
              if (
                (discountingModel === 'TIERED' || discountingModel === 'COMPLEX') &&
                (transactionType === 'USAGE' || transactionType === 'VOLUME_DISCOUNT')
              ) {
                priceInputs.push({
                  label: 'label.flatRate',
                  wrapperClass: 'col-md-3 mt-4 pt-2',
                  name: 'flatRate',
                  type: 'checkbox',
                });
              }
              if (price) {
                return (
                  <div key={`discount-${idx}`}>
                    <div className="card-header">{`${t('label.discount')}#${idx + 1}`}</div>
                    <div className="row col-md-12 p-3">
                      {priceInputs.map(item => (
                        <GenericInput
                          key={`discounts-${item.name}`}
                          {...item}
                          value={price[item.name]}
                          disabled
                          readOnly
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {data &&
            data.accumulators &&
            data.accumulators.length > 0 &&
            data.accumulators.map((accumulator, idx) => {
              if (accumulator && size(accumulator) > 2) {
                return (
                  <div key={`accumulators-${idx}`}>
                    <div className="card-header">{`Accumulator#${idx + 1}`}</div>
                    <div className="row col-md-12 p-3" key={`grant-${idx}`}>
                      {accumulatorInputs.map(item => (
                        <GenericInput
                          key={`accumulators-${item.name}`}
                          {...item}
                          value={accumulator[item.name]}
                          disabled
                          readOnly
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
        </div>
      </div>
    );
  }
}

TierForm.propTypes = {
  isRemoveable: PropTypes.bool,
  isComplexUsage: PropTypes.bool,
  headerTitle: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  accumulatorInputs: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  t: PropTypes.func,
  discountingModel: PropTypes.string,
  transactionType: PropTypes.string,
};

TierForm.defaultProps = {
  isRemoveable: true,
  isComplexUsage: false,
  headerTitle: null,
  data: {},
  accumulatorInputs: [],
  t: () => {},
  discountingModel: '',
  transactionType: '',
};

export default withTranslation('common')(TierForm);
