export default {
  sidebar: {
    // operator: 'Operador',
    operatorRecon: 'Operator Recon',
    recordLog: 'Log de registro operativo',
    invoice: 'Factura de Operación',
    dispute: 'Disputa Operativa',
    tenant: 'Instancia',
    tenantDispute: 'Disputa de Instancia ',
    tenantRecordLog: 'Log de Registro de Instancia',
    rerating: 'Recalificación',
    config: 'Configuración',
    usageMapping: 'Uso Cartografía',
    // usageMapping: 'Usage Mapping',
    platforms: 'Plataformas',
    reprocessingSuspendedFailedRecords: 'Reprocesamiento (Registros Suspendidos/Fallidos)',
    testUsage: 'Prueba de Uso',
    usageOps: 'Operaciones de Uso (Tráfico)',
    mediationSetup: 'Configuración de Mediación',
    operatorProcess: 'Proceso del Operador',
    usageConfig: 'Configuración de Uso (Tráfico)',
  },
};
