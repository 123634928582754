import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  dashboardTileConfig: [],
  userDashboards: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_PREFERENCES_SUCCESS:
      return state.set('dashboardTileConfig', action.payload);

    case types.GET_USER_DASHBOARDS_SUCCESS:
      return state.set('userDashboards', action.payload);

    default:
      return state;
  }
}
