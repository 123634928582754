import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'paymentType']);
  const modifyPaymentItemMap = `modifyPaymentItemMap(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyPaymentItemMap} {
      id
    }
  }
`;
};
