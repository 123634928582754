import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateReratingBatch = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_RERATING_BATCH });
};

const checkPermissionModifyReratingBatch = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_RERATING_BATCH });
};

const checkPermissionGetReratingBatch = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_RERATING_BATCH });
};

const checkPermissionCreateUsagePlatforms = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_USAGE_PLATFORM_CONFIG });
};
const checkPermissionModifyUsagePlatforms = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_USAGE_PLATFORM_CONFIG });
};
const checkPermissionGetUsagePlatforms = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_USAGE_PLATFORM_CONFIG });
};
const checkPermissionEditSuspendedFailedUsageRecords = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.EDIT_SUSPENDED_FAILED_USAGE_RECORDS });
};
const checkPermissionTestUsage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.TEST_USAGE });
};

const checkPermissionCreateZeroPriceUsageConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createZeroRateConfig });
};

const checkPermissionModifyZeroPriceUsageConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyZeroRateConfig });
};

const checkPermissionGetZeroPriceUsageConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getZeroRateConfig });
};

const checkPermissionCreateVoicePlan = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.addVoicePlan });
};

const checkPermissionModifyVoicePlan = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyVoicePlan });
};

const checkPermissionGetVoicePlan = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchVoicePlan });
};

const checkPermissionCreateRegionMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createRegionMapConfig });
};

const checkPermissionModifyRegionMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyRegionMapConfig });
};

const checkPermissionGetRegionMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRegionMapConfig });
};

const checkPermissionCreateTrunkRuleConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createTrunkRuleConfig });
};

const checkPermissionModifyTrunkRuleConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyTrunkRuleConfig });
};

const checkPermissionGetTrunkRuleConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTrunkRuleConfig });
};

const checkPermissionCreateOperatorMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createOperatorMapConfig });
};

const checkPermissionModifyOperatorMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyOperatorMapConfig });
};

const checkPermissionGetOperatorMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getOperatorMapConfig });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeCreateReratingBatch = 0;
  let modeModifyReratingBatch = 0;
  let modeGetReratingBatch = 0;
  let modeEditSuspendedFailedUsageRecords = 0;
  let modeTestUsage = 0;

  let modeGetZeroRateConfig = 0;
  let modeCreateZeroRateConfig = 0;
  let modeModifyZeroRateConfig = 0;

  let modeGetVoicePlan = 0;
  let modeCreateVoicePlan = 0;
  let modeModifyVoicePlan = 0;

  let modeGetRegionMapConfig = 0;
  let modeCreateRegionMapConfig = 0;
  let modeModifyRegionMapConfig = 0;

  let modeGetTrunkRuleConfig = 0;
  let modeCreateTrunkRuleConfig = 0;
  let modeModifyTrunkRuleConfig = 0;

  let modeGetOperatorMapConfig = 0;
  let modeCreateOperatorMapConfig = 0;
  let modeModifyOperatorMapConfig = 0;

  modeCreateReratingBatch = checkPermissionCreateReratingBatch({ listPermission });
  modeModifyReratingBatch = checkPermissionModifyReratingBatch({ listPermission });
  modeGetReratingBatch = checkPermissionGetReratingBatch({ listPermission });
  modeEditSuspendedFailedUsageRecords = checkPermissionEditSuspendedFailedUsageRecords({ listPermission });
  modeTestUsage = checkPermissionTestUsage({ listPermission });

  modeGetZeroRateConfig = checkPermissionCreateZeroPriceUsageConfig({ listPermission });
  modeCreateZeroRateConfig = checkPermissionModifyZeroPriceUsageConfig({ listPermission });
  modeModifyZeroRateConfig = checkPermissionGetZeroPriceUsageConfig({ listPermission });

  modeGetVoicePlan = checkPermissionGetVoicePlan({ listPermission });
  modeCreateVoicePlan = checkPermissionCreateVoicePlan({ listPermission });
  modeModifyVoicePlan = checkPermissionModifyVoicePlan({ listPermission });

  modeGetRegionMapConfig = checkPermissionGetRegionMapConfig({ listPermission });
  modeCreateRegionMapConfig = checkPermissionCreateRegionMapConfig({ listPermission });
  modeModifyRegionMapConfig = checkPermissionModifyRegionMapConfig({ listPermission });

  modeGetTrunkRuleConfig = checkPermissionGetTrunkRuleConfig({ listPermission });
  modeCreateTrunkRuleConfig = checkPermissionCreateTrunkRuleConfig({ listPermission });
  modeModifyTrunkRuleConfig = checkPermissionModifyTrunkRuleConfig({ listPermission });

  modeGetOperatorMapConfig = checkPermissionGetOperatorMapConfig({ listPermission });
  modeCreateOperatorMapConfig = checkPermissionCreateOperatorMapConfig({ listPermission });
  modeModifyOperatorMapConfig = checkPermissionModifyOperatorMapConfig({ listPermission });

  return {
    modeCreateReratingBatch,
    modeModifyReratingBatch,
    modeGetReratingBatch,
    modeEditSuspendedFailedUsageRecords,
    modeTestUsage,
    modeGetZeroRateConfig,
    modeCreateZeroRateConfig,
    modeModifyZeroRateConfig,
    modeGetVoicePlan,
    modeCreateVoicePlan,
    modeModifyVoicePlan,
    modeGetRegionMapConfig,
    modeCreateRegionMapConfig,
    modeModifyRegionMapConfig,
    modeGetTrunkRuleConfig,
    modeCreateTrunkRuleConfig,
    modeModifyTrunkRuleConfig,
    modeGetOperatorMapConfig,
    modeCreateOperatorMapConfig,
    modeModifyOperatorMapConfig,
  };
};

export {
  checkPermissionForAllPages,
  checkPermissionCreateReratingBatch,
  checkPermissionGetReratingBatch,
  checkPermissionCreateUsagePlatforms,
  checkPermissionModifyUsagePlatforms,
  checkPermissionGetUsagePlatforms,
  checkPermissionEditSuspendedFailedUsageRecords,
  checkPermissionTestUsage,
  checkPermissionCreateZeroPriceUsageConfig,
  checkPermissionModifyZeroPriceUsageConfig,
  checkPermissionGetZeroPriceUsageConfig,
  checkPermissionCreateVoicePlan,
  checkPermissionModifyVoicePlan,
  checkPermissionGetVoicePlan,
  checkPermissionCreateRegionMapConfig,
  checkPermissionModifyRegionMapConfig,
  checkPermissionGetRegionMapConfig,
  checkPermissionCreateTrunkRuleConfig,
  checkPermissionModifyTrunkRuleConfig,
  checkPermissionGetTrunkRuleConfig,
  checkPermissionCreateOperatorMapConfig,
  checkPermissionModifyOperatorMapConfig,
  checkPermissionGetOperatorMapConfig,
};
