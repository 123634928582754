import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GenericInput, ModalWithItem } from '../common';
import ExpressionList from './UsageRating/RateUnit/ExpressionList';
import { getUsageContainerSpecConfig } from '../../containers/App/actions';

const ModalExpression = ({
  onToggle,
  isOpenModalExpression,
  expressionAddNew,
  onChangeExp,
  operator,
  operatorOptions,
  selectFieldExp,
  onAddOperator,
  selectRecordExp,
  onChangeSelectField,
  onAddFieldExp,
  onApplyExpression,
  onSetOperator,
  getUsageContainerSpecConfig,
}) => {
  const { t } = useTranslation('common');
  const [isEditExp, setIsEditExp] = useState(true);
  const [usageContainerRecordList, setUsageContainerRecordList] = useState(null);

  useEffect(() => {
    getUsageContainerSpecConfig(true, ({ success, data }) => {
      if (success && data?.length) {
        setUsageContainerRecordList(data);
      }
    });
  }, [getUsageContainerSpecConfig]);

  let optionFieldType = [];
  if (selectRecordExp) {
    const itemRecordSelect =
      selectRecordExp && usageContainerRecordList
        ? usageContainerRecordList.filter(val => val.recordType === selectRecordExp)
        : [];

    if (itemRecordSelect && itemRecordSelect.length)
      optionFieldType = itemRecordSelect[0].attributes.map(item => ({
        label: item.attributeName,
        value: item.attributeName,
      }));
  }

  return (
    <ModalWithItem modalTitle={t('label.expression')} isOpen={isOpenModalExpression} onToggle={onToggle}>
      <div className="col-md-12 m-2 expression-container p-3 row">
        <div className="col-md-11 row">
          {expressionAddNew && expressionAddNew.length < 1 && !isEditExp && (
            <span className="placeholder-expression">{`( <${t('label.addField')}> )`}</span>
          )}
          {((expressionAddNew && expressionAddNew.length > 0) || isEditExp) && (
            <ExpressionList
              data={expressionAddNew}
              isEditExp={isEditExp}
              onChangeExp={onChangeExp}
              operator={operator}
            />
          )}
        </div>
        <div className="col-md-1 no-padding">
          {isEditExp ? (
            <button
              type="button"
              className="btn-with-ico float-right"
              onClick={() => {
                setIsEditExp(false);
              }}
            >
              <i className="fa fa-check-square-o icon-check" aria-hidden="true" />
            </button>
          ) : (
            <button
              type="button"
              className="btn-with-ico float-right"
              onClick={() => {
                setIsEditExp(true);
              }}
            >
              <i className="fa fa-pencil-square-o icon-edit" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
      <div className="col-md-12 mb-2 mt-4 row ml-4">
        <span className="title-select-operator">{`${t('label.selectOperator')}:`}</span>
        {operatorOptions.map(item => (
          <button
            key={item.key}
            type="button"
            className={`m-1 btn-operator ${operator === item.value ? 'active' : ''}`}
            onClick={() => {
              onSetOperator(item.value);
              // this.setState({ operator: item.value });
            }}
          >
            {item.key}
          </button>
        ))}
        <button
          type="button"
          disabled={!operator}
          className="button button-border black x-small btn-add-operator"
          onClick={onAddOperator}
        >
          {`+ ${t('label.addOperator')}`}
        </button>
      </div>
      <div className="col-md-12 mb-2 mt-4 ml-5 row">
        <GenericInput
          value={selectRecordExp}
          label="label.selectRecord"
          type="select"
          required
          // options={t('selections:recordExpression')()}
          options={
            usageContainerRecordList && usageContainerRecordList.length > 0
              ? usageContainerRecordList.map(val => ({ label: val.recordType, value: val.recordType }))
              : []
          }
          wrapperClass="col-md-4"
          onChange={onChangeSelectField}
          name="selectRecordExp"
        />
        <GenericInput
          value={selectFieldExp}
          required
          label="label.selectField"
          type="select"
          // options={t('selections:fieldExpression')()}
          options={optionFieldType || []}
          wrapperClass="col-md-4"
          onChange={onChangeSelectField}
          name="selectFieldExp"
        />
        <div className="form-group action-buttons mt-4">
          <button
            type="button"
            disabled={!selectFieldExp || !selectRecordExp || selectFieldExp === '' || selectRecordExp === ''}
            className="button button-border black x-small"
            onClick={onAddFieldExp}
          >
            {`+ ${t('label.addField')}`}
          </button>
        </div>
      </div>
      <div className="form-group col-md-12 action-buttons modal-new-item">
        <button type="button" className="button x-small float-right" onClick={onApplyExpression}>
          {t('label.apply')}
        </button>
        <button type="button" className="button button-border black x-small float-right" onClick={onToggle}>
          {t('label.cancel')}
        </button>
      </div>
    </ModalWithItem>
  );
};

ModalExpression.propTypes = {
  operatorOptions: PropTypes.arrayOf(PropTypes.any),
  onSetOperator: PropTypes.func,
  getUsageContainerSpecConfig: PropTypes.func.isRequired,
  onApplyExpression: PropTypes.func,
  onAddFieldExp: PropTypes.func,
  onChangeSelectField: PropTypes.func,
  selectRecordExp: PropTypes.string,
  selectFieldExp: PropTypes.string,
  operator: PropTypes.string,
  onAddOperator: PropTypes.func,
  onChangeExp: PropTypes.func,
  onToggle: PropTypes.func,
  expressionAddNew: PropTypes.string,
  isOpenModalExpression: PropTypes.bool,
};

ModalExpression.defaultProps = {
  operatorOptions: [],
  onSetOperator: () => {},
  onApplyExpression: () => {},
  onAddFieldExp: () => {},
  onChangeSelectField: () => {},
  onAddOperator: () => {},
  selectRecordExp: '',
  selectFieldExp: '',
  operator: '',
  expressionAddNew: '',
  onChangeExp: () => {},
  onToggle: () => {},
  isOpenModalExpression: false,
};

export default connect(null, {
  getUsageContainerSpecConfig,
})(ModalExpression);
