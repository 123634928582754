export default {
  font: { sz: '13', bold: false, color: { rgb: 'FFFFFF' } },
  fill: { patternType: 'solid', fgColor: { rgb: '00A651' } },
  border: {
    top: { style: 'think', color: '000000' },
    bottom: { style: 'think', color: '000000' },
    left: { style: 'think', color: '00a651' },
    right: { style: 'think', color: '00a651' },
  },
};
