import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class TaxSummarySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.taxCode',
      name: 'taxcode',
      type: 'select',
      options: [],
      isClearable: true,
      isCreateOption: true,
    },
    {
      label: 'label.taxCategory',
      name: 'taxCategory',
    },
    {
      label: 'label.currency',
      name: 'currency',
    },
    {
      label: 'label.taxJurisdictionLevel',
      name: 'jurisdiction',
      type: 'select',
      tOptions: 'selections:jurisdiction',
      isClearable: true,
    },
    {
      label: 'label.state',
      name: 'state',
    },
    {
      label: 'label.country',
      name: 'country',
    },
    {
      label: 'label.organization',
      name: 'company',
    },
  ];

  btnSpan = 'col-md-9';
}

TaxSummarySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

TaxSummarySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(TaxSummarySearchForm);
