import * as types from './types';

// tax code
export function getTaxCodeConfig(cb) {
  return {
    type: types.GET_TAX_CODE_CONFIG,
    cb,
  };
}

export function getTaxCodeConfigSuccess(payload, cb) {
  return {
    type: types.GET_TAX_CODE_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyTaxCodeConfig(payload) {
  return {
    type: types.MODIFY_TAX_CODE_CONFIG,
    payload,
  };
}

export function createTaxCodeConfig(payload, cb) {
  return {
    type: types.CREATE_TAX_CODE_CONFIG,
    payload,
    cb,
  };
}

// get tax third party
export function get3rdPartyTaxConfig(payload, cb) {
  return {
    type: types.GET_3RD_PARTY_TAX_CONFIG,
    payload,
    cb,
  };
}

export function get3rdPartyTaxConfigSuccess(payload, cb) {
  return {
    type: types.GET_3RD_PARTY_TAX_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function getTaxTypeConfig(payload, cb) {
  return {
    type: types.GET_TAX_TYPE_CONFIG,
    payload,
    cb,
  };
}

export function createTaxTypeConfig(payload, cb) {
  return {
    type: types.CREATE_TAX_TYPE_CONFIG,
    payload,
    cb,
  };
}

export function modifyTaxTypeConfig(payload, cb) {
  return {
    type: types.MODIFY_TAX_TYPE_CONFIG,
    payload,
    cb,
  };
}
