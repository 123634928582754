import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPageTotalCount } from '../../../utils/utils';
import TitleFrom from '../../common/TitleFrom';
import AccountHeaderFrom from '../../common/AccountHeaderFrom';
import constant from '../../../containers/CustomerPage/constants';
import { TablePagination, DataTable, CheckField, GenericInput, ModalWithItem, FormCollapse } from '../../common';
import { makeGetArActivity, makeErrorMessageTU } from '../../../containers/CustomerPage/selectors';
import CreditNotesSearchForm from '../CreditNotesSearchForm';
import { searchNotesUnits, modifyNotesUnit, getNotesUnitbyId } from '../../../containers/CustomerPage/actions';
import RouteNames from '../../../containers/App/RouteNames';
import ModalSearchObjectApprovals from '../../common/ModalSearchObjectApprovals';
import { getUserId } from '../../../localStorages';
import { statusDisableFielsdViewFolio } from '../../../utils/constants';

const arActivitySort = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  notesDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  type: {
    asc: 'arNotestype_ASC',
    desc: 'arNotestype_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
};

const collapsibleMenuCreditDebitNotes = [
  {
    title: 'common:label.notesSummaryLines',
    state: 'notesSummaryLines',
  },
  {
    title: 'common:label.taxLines',
    state: 'taxLines',
  },
];

const listCreditDebitNoteFields = [
  {
    label: 'label.notesId',
    name: 'id',
    readOnly: true,
  },
  {
    label: 'label.reason',
    name: 'reasonDescription',
    readOnly: true,
  },
  {
    label: 'label.invoiceId',
    name: 'invoiceId',
    readOnly: true,
  },
];

const tableTaxLinesCreditDebitNote = [
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'state',
    label: 'label.state',
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdiction',
  },
  {
    name: 'taxCode',
    label: 'label.taxCode',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
  },
  {
    name: 'amount',
    label: 'label.amount',
    isAmount: true,
  },
  {
    name: 'taxRate',
    label: 'label.taxableRate',
    isAmount: true,
  },
  {
    name: 'taxableAmount',
    label: 'label.taxableAmount',
    isAmount: true,
  },
  {
    name: 'exemptAmount',
    label: 'label.exemptAmount',
    isAmount: true,
  },
];

const messageCSVStamping = content => {
  if (content && content.search(/\r/) === -1) return <div>{content}</div>;
  const newContent = content ? content.split(/\r/g) : content;
  return <div>{newContent && newContent.length ? newContent.map(val => <div>{val}</div>) : ''}</div>;
};

class CreditNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: { accountId: props.id },
      sort: '',
      sorted: {},
      creditData: [],
      isSearching: false,
      totalCount: null,
      creditIdSelected: null,
      actionSelected: null,
      idOpenModalNote: false,
      comments: '',
      notes: '',
      itemSelect: null,
      isOpenModalStamping: false,
      stampingsData: [],
      csvData: null,
      isOpenModalStampingCSVData: false,
      activeTab: {},
      creditDebitNoteData: null,
      itemSelected: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.creditData }),
    };
  }

  componentDidMount() {
    this.doSearchArActivity();
  }

  doSearchArActivity = () => {
    const { searchNotesUnits } = this.props;
    const { page, size, filter, sorted } = this.state;
    this.setState({ isSearching: true });
    searchNotesUnits(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? arActivitySort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        this.setState({
          isSearching: false,
          creditData: success ? data : [],
          creditIdSelected: null,
          actionSelected: null,
        });
      }
    );
  };

  onHandleSubmit = filter => {
    const { id } = this.props;
    const newFilter = filter;
    newFilter.accountId = id;
    this.setState({ filter: newFilter }, () => this.doSearchArActivity());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchArActivity());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchArActivity());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchArActivity());
  };

  onSubmitUpdateStatus = () => {
    const { modifyNotesUnit } = this.props;
    const { creditIdSelected, actionSelected, comments, notes } = this.state;
    const payload = { status: actionSelected, id: creditIdSelected, userId: getUserId(), comments };
    if (actionSelected === 'APPROVED') payload.notes = notes;
    modifyNotesUnit(payload, ({ success }) => {
      if (success) {
        this.doSearchArActivity();
        this.setState({ actionSelected: null, creditIdSelected: null, comments: null, notes: null });
      }
    });
  };

  doGetObjectFileById = invoiceId => {
    const { getObjectFileById, id, history } = this.props;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.creditNotesInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.prettifyPath(RouteNames.customerDetailsCreditNotes.path, id),
            fileData: data,
          },
        });
      }
    });
  };

  doGetInvoiceStampingByInvoiceId = invoiceUnitId => {
    const { getPacInterfaceRecordById } = this.props;
    getPacInterfaceRecordById(invoiceUnitId, ({ success, data }) => {
      this.setState({ stampingsData: success ? data : [] });
    });
  };

  onToggleModalStamping = item => {
    const { isOpenModalStamping } = this.state;
    if (!isOpenModalStamping && item) {
      this.doGetInvoiceStampingByInvoiceId(item.id);
    }
    this.setState({ isOpenModalStamping: !isOpenModalStamping });
  };

  onToggleModalStampingCSVData = item => {
    const { isOpenModalStampingCSVData } = this.state;
    if (!isOpenModalStampingCSVData && item) {
      this.setState({ csvData: item.csvRecord });
    }
    this.setState({ isOpenModalStampingCSVData: !isOpenModalStampingCSVData });
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({ activeTab: { name: activeTabName, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleModalDetails = item => {
    const { isOpenModalDetails } = this.state;
    const { getNotesUnitbyId } = this.props;
    if (!isOpenModalDetails && item) {
      if (item.id) {
        getNotesUnitbyId(item.id, ({ success, data }) => {
          this.setState({ creditDebitNoteData: success ? data || null : null, itemSelected: item });
        });
      }
    }
    this.setState({ isOpenModalDetails: !isOpenModalDetails });
  };

  renderItem = item => {
    const { creditDebitNoteData, itemSelected } = this.state;
    const tableCreditDebitLines = [
      {
        name: 'index',
        label: 'label.index',
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'appliedStart',
        label: 'label.startDate',
      },
      {
        name: 'appliedEnd',
        label: 'label.endDate',
      },
      {
        name: 'itemId',
        label: 'label.itemId',
      },
      {
        name: 'currency',
        label: 'label.currency',
        render: () => {
          return <span>{itemSelected ? itemSelected.currency : ''}</span>;
        },
      },
      {
        name: 'amount',
        label: 'label.amount',
      },
      {
        name: 'taxRule',
        label: 'label.taxRule',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:taxRule')().find(val => val.value === item.taxRule) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'notesType',
        label: 'label.notesType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:notesCrDrType')().find(val => val.value === item.notesType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ];

    switch (item.state) {
      case 'notesSummaryLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableCreditDebitLines}
              data={creditDebitNoteData?.notesSummaryList ? creditDebitNoteData.notesSummaryList : []}
            />
          </div>
        );
      case 'taxLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableTaxLinesCreditDebitNote}
              data={creditDebitNoteData?.invoiceTaxList ? creditDebitNoteData.invoiceTaxList : []}
            />
          </div>
        );
      default:
        return null;
    }
  };

  onHandleResendToVendor = () => {
    const { resendNotesVendor } = this.props;
    const { creditIdSelected } = this.state;
    resendNotesVendor(creditIdSelected, ({ success }) => {
      if (success) {
        this.doSearchArActivity();
        this.setState({ actionSelected: null, creditIdSelected: null, comments: null, notes: null });
      }
    });
  };

  render() {
    const { id, t, currencyOptions, customerInfo, modeGetCreditNoteReport, modeCreditNoteApproval } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      creditData,
      comments,
      creditIdSelected,
      actionSelected,
      idOpenModalNote,
      notes,
      itemSelect,
      isOpenModalStamping,
      csvData,
      stampingsData,
      isOpenModalStampingCSVData,
      activeTab,
      isOpenModalDetails,
      itemSelected,
    } = this.state;

    if (!modeGetCreditNoteReport) return null;

    let creditNotesSelectOptions = modeCreditNoteApproval ? t('selections:creditNoteActions')() : [];
    if (itemSelect && itemSelect.status !== 'PENDING_APPROVAL') {
      creditNotesSelectOptions = creditNotesSelectOptions.filter(
        val => ['APPROVED', 'DISAPPROVED'].indexOf(val.value) === -1
      );
    }
    if (
      itemSelect &&
      ['CANCELLATION_STAMPING_REQUESTED', 'STAMPING_REQUESTED'].indexOf(itemSelect.folioStatus) === -1
    ) {
      creditNotesSelectOptions = creditNotesSelectOptions.filter(val => val.value !== 'RESEND_TO_VENDOR');
    }

    const columns = constant.creditNotesTable;
    const selectColumn = [];
    if (modeCreditNoteApproval) {
      selectColumn.push({
        name: 'select',
        label: 'common:label.select',
        render: (colName, item) => {
          return (
            <CheckField
              name="select"
              value={!!item.id}
              // disabled={item.status !== 'PENDING_APPROVAL'}
              onChange={() => {
                if (creditIdSelected === item.id) {
                  this.setState({ creditIdSelected: null, itemSelect: null });
                } else {
                  this.setState({
                    creditIdSelected: item.id,
                    actionSelected: null,
                    itemSelect: cloneDeep(item),
                    notes: item.notes,
                  });
                }
              }}
              checked={!!(item.id && creditIdSelected === item.id)}
              readOnly
            />
          );
        },
      });
    }
    const newColumns = [
      ...selectColumn,
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <button
            className="text-success no-border"
            // disabled={statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
            type="button"
            onClick={() => this.doGetObjectFileById(item.id)}
          >
            {item.id}
          </button>
          // <Link
          //   className="text-success"
          //   to={{
          //     pathname: RouteNames.prettifyPath(RouteNames.creditNotesInfo.path, item.id),
          //     state: {
          //       fromPath: RouteNames.prettifyPath(RouteNames.customerDetailsCreditNotes.path, id),
          //     },
          //   }}
          // >
          //   {item.id}
          // </Link>
        ),
      },
      {
        name: 'accountId',
        label: 'label.accountId',
      },
      {
        name: 'stamping',
        label: 'common:label.stamping',
        render: (colName, item) => {
          let isDisableButton = true;
          if (
            item.folioStatus === 'STAMPING_REQUESTED' ||
            item.folioStatus === 'STAMPED' ||
            item.folioStatus === 'CANCELLATION_STAMPING_REQUESTED' ||
            item.folioStatus === 'CANCELLATION_STAMPED'
            // ||
            // item.folioStatus === 'CLOSED' ||
            // item.folioStatus === 'COLLECTION'
          ) {
            isDisableButton = false;
          }
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              disabled={isDisableButton}
              onClick={() => {
                this.onToggleModalStamping(item);
              }}
            >
              {t('label.view')}
            </button>
          );
        },
      },
      {
        name: 'details',
        label: 'common:label.details',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={evt => {
              this.onToggleModalDetails(item);
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
      {
        name: 'select',
        label: 'label.action',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              this.setState({ idOpenModalNote: item.id });
            }}
          >
            {t('label.notes')}
            <i className="fa fa-external-link ml-2" aria-hidden="true" />
          </button>
        ),
      },
      ...columns,
      {
        name: 'notes',
        label: 'label.notes',
        style: { minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.notes}
            name="comment"
            onChange={({ name, value }) => {}}
            wrapperClass="col-md-12"
            type="textarea"
            readOnly
          />
        ),
      },
    ];

    const stampingRecordColumns = [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:pacRecordStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'extractType',
        label: 'common:label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:pacExtractType')().find(val => val.value === item.extractType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'createdDate',
        label: 'common:label.createdDate',
      },
      {
        name: 'objectDate',
        label: 'common:label.modifiedDate',
      },
      {
        name: 'data',
        label: 'common:label.data',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              this.onToggleModalStampingCSVData(item);
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];
    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }
    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics">
          <TitleFrom title={t('customerPage:sidebarHandleAccountPage.creditNotes')} />
          <AccountHeaderFrom
            accountNum={id}
            commercialName={customerInfo?.commercialName || ''}
            isCommercialName={customerInfo?.customerSegment !== 'B2C'}
            currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          />
          <div className="card-body">
            <CreditNotesSearchForm onSubmit={this.onHandleSubmit} />
            <br />
            <div>
              <DataTable
                columns={newColumns}
                data={creditData || []}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
              />
              <div className="col-md-12 p-3 pb-4 d-flex">
                {!!modeCreditNoteApproval && (
                  <GenericInput
                    value={actionSelected}
                    name="type"
                    onChange={({ name, value }) => {
                      this.setState({ actionSelected: value, notes: value === 'APPROVED' ? itemSelect.notes : null });
                    }}
                    wrapperClass="col-md-3"
                    label="label.action"
                    options={creditNotesSelectOptions || []}
                    type="select"
                    placeholder="<Select Action>"
                    disabled={!creditIdSelected}
                    isPlaceholder
                    isClearable
                  />
                )}
                {actionSelected && creditIdSelected && actionSelected !== 'RESEND_TO_VENDOR' && (
                  <>
                    {actionSelected === 'APPROVED' && (
                      <GenericInput
                        value={notes}
                        name="notes"
                        onChange={({ name, value }) => this.setState({ notes: value })}
                        wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                        label="label.notes"
                        type="textarea"
                      />
                    )}
                    <GenericInput
                      value={comments}
                      name="comment"
                      onChange={({ name, value }) => this.setState({ comments: value })}
                      wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                      label="label.comments"
                      type="textarea"
                    />

                    <div className="col-md-3">
                      <button
                        className="button x-small mt-4"
                        // disabled={modeRegenerateInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
                        type="button"
                        disabled={modeCreditNoteApproval === 1}
                        onClick={this.onSubmitUpdateStatus}
                      >
                        {actionSelected === 'APPROVED' ? t('label.approve') : t('label.reject')}
                      </button>
                    </div>
                  </>
                )}
                {actionSelected && creditIdSelected && actionSelected === 'RESEND_TO_VENDOR' && (
                  <div className="col-md-3">
                    <button
                      className="button x-small mt-4"
                      // disabled={modeRegenerateInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
                      type="button"
                      disabled={modeCreditNoteApproval === 1}
                      onClick={this.onHandleResendToVendor}
                    >
                      {t('label.submit')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalSearchObjectApprovals
          isOpen={!!idOpenModalNote}
          defaultSearchParams={{ objectId: idOpenModalNote }}
          id={idOpenModalNote}
          onSelect={this.onSelectItemId}
          onCancel={() => {
            this.setState({ idOpenModalNote: null });
          }}
          toggleModal={() => {
            this.setState({ idOpenModalNote: null });
          }}
        />

        {/* Modal  getPacInterfaceRecordById  */}
        <ModalWithItem
          modalTitle={t('label.stampingRecord')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStamping}
          onToggle={this.onToggleModalStamping}
        >
          <DataTable columns={stampingRecordColumns} data={stampingsData || []} />
          <button type="button" onClick={this.onToggleModalStamping} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>

        <ModalWithItem
          modalTitle={t('label.data')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStampingCSVData}
          onToggle={this.onToggleModalStampingCSVData}
        >
          <div className="mt-3 mb-5 csv-content">
            {/* {csvData} */}
            {messageCSVStamping(csvData)}
          </div>
          <button type="button" onClick={this.onToggleModalStampingCSVData} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>

        <ModalWithItem
          modalTitle={t('label.notesSummary')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalDetails}
          onToggle={this.onToggleModalDetails}
        >
          <div className="col-md-12 row pb-1">
            {listCreditDebitNoteFields.map(val => (
              <GenericInput
                value={itemSelected && itemSelected[val.name] ? itemSelected[val.name] : ''}
                wrapperClass="col-md-4"
                onChange={() => {}}
                readOnly
                {...val}
              />
            ))}
          </div>
          <br />
          {collapsibleMenuCreditDebitNotes.map(item => (
            <FormCollapse
              key={item.title}
              label="First Name"
              isActive={item.state === activeTab.name && activeTab.isActive}
              title={t(item.title) || ''}
              state={item.state}
              onToggleTab={this.onToggleTab}
            >
              {this.renderItem(item)}
            </FormCollapse>
          ))}
          <br />
          <button
            type="button"
            onClick={this.onToggleModalDetails}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  arActivity: makeGetArActivity() || [],
  errorMessage: makeErrorMessageTU() || '',
});

CreditNotes.propTypes = {
  getArActivity: PropTypes.func.isRequired,
  id: PropTypes.string,
};

CreditNotes.defaultProps = {
  id: '',
};

const newCreditNotes = connect(mapStateToProps, {
  searchNotesUnits,
  modifyNotesUnit,
  getNotesUnitbyId,
})(CreditNotes);

export default withTranslation(['common', 'selections'])(newCreditNotes);
