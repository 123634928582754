/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getDistinctOrganization } from '../../../containers/App/actions';
import ModalMultipleSelector from '../../Modals/ModalMultipleSelector';
import SearchAccountForm from './SearchAccountForm';

class ModalSearchMultiOrganization extends PureComponent {
  searchHanldeAccounts = (payload, cb) => {
    const { getDistinctOrganization } = this.props;
    const newPayload = { ...payload };
    getDistinctOrganization(newPayload, resp => {
      // const newData = [];
      // if (resp.success && resp.data && resp.data.length > 0) {
      //   newData = resp.data.map(val => {
      //     const { contacts, ...rest } = val;
      //     return { ...rest, organization: contacts && contacts[0] ? contacts[0].organization : null };
      //   });
      // }
      cb({ success: resp.success, data: resp.data });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'organization',
          label: 'common:label.organization',
          // sortable: true,
          style: { minWidth: '110px' },
          // render: (name, item) => {
          //   return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
          // },
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      selectFieldName,
      childrenField,
      onSelectChild,
      selectChildFieldName,
    } = this.props;
    return (
      <ModalMultipleSelector
        modalTitle={t('label.organization')}
        isOpen={isOpen}
        SearchForm={SearchAccountForm}
        searchItems={this.searchHanldeAccounts}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName={selectFieldName}
        childrenField={childrenField}
        onSelectChild={onSelectChild}
        selectChildFieldName={selectChildFieldName}
      />
    );
  }
}

ModalSearchMultiOrganization.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getDistinctOrganization: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSearchMultiOrganization.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getDistinctOrganization })(ModalSearchMultiOrganization));
