import React from 'react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { selectInvoiceId, getCcpPropertiesConfig } from '../../App/actions';
import RouteNames from '../../App/RouteNames';
import { roundFloat } from '../../../utils/utils';
import { writeOffFieldApply } from '../constant';
import { processWriteoff, getARReasonCodeConfig } from '../actions';
import { WriteOffForm } from '../../../components/ArOperationsHub';
import PageTitle from '../../../components/PageTitle';
import ModalInvoice from '../../../components/common/ModalInvoice';
import ModalAccountId from '../../../components/common/ModalAccountId';
import { makeGetPermissionsArOpsManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  checkPermissionProcessTaxWriteoff,
  checkPermissionProcessWriteoff,
  checkPermissionGetArOpsTaxOptions,
} from '../CheckPermission';
import { getUserId } from '../../../localStorages';

let isDisablePercent = false;
class WriteOffsApply extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      // dataCreate: { type: 'ACCOUNT_WRITEOFF', source: 'AGENT_CARE' },
      dataCreate: { userId: getUserId() || 'USER-001', type: 'INVOICE_WRITEOFF', source: 'AGENT_CARE' },
      dataInvoiceTable: [],
      isAccountWriteOff: false,
      isOpenModalAccountId: false,
      isOpenModalInvoice: false,
      optionResonCodes: [],
      fixedTaxRuleForAROps: null,
      dataCancel: {},
      isFilterInstallmentInvoicesForAR: null,
    };
  }

  componentDidMount() {
    // this.doSearchInvoiceId();
    const { getARReasonCodeConfig, getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitDefaultValue(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitDefaultValue(ccpPropertyList);
    }
    getARReasonCodeConfig('', ({ success, data }) => {
      if (success && data && data.arReasonCodes) {
        const optionResonCodes = data.arReasonCodes
          .filter(val => !!val.customReasonCode)
          .map(val => ({
            label: val.customReasonCode,
            value: val.customReasonCode,
            ...val,
          }));
        this.setState({
          optionResonCodes,
        });
      }
    });
  }

  doInitDefaultValue = ccpPropertyList => {
    const { dataCreate } = this.state;
    const fixedTaxRuleForAROps = ccpPropertyList.find(val => val.property === 'fixedTaxRuleForAROps');
    const defaultTaxRuleForAROps = ccpPropertyList.find(val => val.property === 'defaultTaxRuleForAROps');
    const filterInstallmentInvoicesForAR = ccpPropertyList.find(
      val => val.property === 'filterInstallmentInvoicesForAR'
    );
    dataCreate.taxRule = 'WITHOUT_TAX';
    if (defaultTaxRuleForAROps && defaultTaxRuleForAROps.value) {
      dataCreate.taxRule = defaultTaxRuleForAROps.value;
    }
    if (fixedTaxRuleForAROps && fixedTaxRuleForAROps.value) {
      dataCreate.taxRule = fixedTaxRuleForAROps.value;
    }
    this.setState({
      fixedTaxRuleForAROps,
      dataCreate: { ...dataCreate },
      dataCancel: { ...dataCreate },
      isFilterInstallmentInvoicesForAR:
        filterInstallmentInvoicesForAR && filterInstallmentInvoicesForAR.value === 'true',
    });
  };

  onHandleChange = ({ name, value }) => {
    const { dataCreate, optionResonCodes } = this.state;
    if (name === 'percent' && (Number.parseFloat(value) > 100 || Number.parseFloat(value) < 0)) {
      return '';
    }
    dataCreate[name] = value;
    if (name === 'customReason') {
      const optionSelect = optionResonCodes.find(val => val.value === value);
      if (optionSelect) {
        dataCreate.invoiceCancellation = optionSelect.invoiceCancellation ? 'TRUE' : 'FALSE';
        dataCreate.percent = optionSelect.fullCredit ? '100' : null;
        isDisablePercent = !!optionSelect.fullCredit;
      }
    }
    if (name === 'amount' && dataCreate.percent) dataCreate.percent = 0;
    if (name === 'percent' && dataCreate.amount) delete dataCreate.amount;
    return this.setState({ dataCreate: { ...dataCreate } });
  };

  onBlurInput = evt => {
    const { dataCreate } = this.state;
    if (evt.target.name === 'accountId') {
      dataCreate.invoiceId = null;
      // this.doSearchInvoiceId();
    }
    if (evt.target.name === 'percent') {
      if (!dataCreate.percent) return;
      dataCreate.percent = roundFloat(dataCreate.percent, 2);
    }
    this.setState({ ...dataCreate });
  };

  // doSearchInvoiceId = () => {
  //   const { dataCreate } = this.state;
  //   const { selectInvoiceId } = this.props;
  //   selectInvoiceId(
  //     { page: 1, size: 5, filter: { due: 0, accountId: dataCreate.accountId ? dataCreate.accountId : null } },
  //     ({ data, success }) => {
  //       if (success) this.setState({ dataInvoiceTable: data });
  //     }
  //   );
  // };

  onClickRowTable = ({ invoice }) => {
    const { dataCreate } = this.state;
    dataCreate.invoiceId = dataCreate.invoiceId === invoice.id ? null : invoice.id;
    dataCreate.accountId = dataCreate.invoiceId ? invoice.accountId : null;
    this.setState({ ...dataCreate });
  };

  onBlurAmount = () => {
    const { dataCreate } = this.state;
    if (!dataCreate.amount) return;
    dataCreate.amount = roundFloat(dataCreate.amount, 2);
    this.setState({ dataCreate: { ...dataCreate } });
  };

  onChangeSwitch = () => {
    const { isAccountWriteOff, dataCreate } = this.state;
    if (!isAccountWriteOff) dataCreate.type = 'ACCOUNT_WRITEOFF';
    if (isAccountWriteOff) dataCreate.type = 'INVOICE_WRITEOFF';
    dataCreate.invoiceId = '';
    this.setState({ isAccountWriteOff: !isAccountWriteOff, dataCreate: { ...dataCreate } });
  };

  onSelectAccountId = id => {
    const { dataCreate } = this.state;
    dataCreate.accountId = id;
    dataCreate.invoiceId = null;
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalAccountId: false });
  };

  onSelectInvoice = (id, row) => {
    const { dataCreate } = this.state;
    dataCreate.invoiceId = id;
    dataCreate.accountId = row.accountId || null;
    this.setState({ dataCreate: { ...dataCreate }, isOpenModalInvoice: false });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const {
      dataCreate: { invoiceCancellation, ...rest },
      isAccountWriteOff,
    } = this.state;
    const { processWriteoff, t } = this.props;
    if (!rest.customReason && !rest.reason) {
      return toast.error(t('message.messgaeErrorReasonCode'));
    }
    if (isAccountWriteOff && !rest.reason && !rest.customReason)
      return toast('Please input reason field!', { type: toast.TYPE.ERROR });

    if (
      (!rest.reason || !rest.taxRule || !rest.accountId || !rest.invoiceId || !rest.source || !rest.type) &&
      !isAccountWriteOff &&
      !rest.amount &&
      !rest.percent
    ) {
      return toast('Please check fields: reason, taxRule, accountId, invoiceId, source, type, amount, percent!', {
        type: toast.TYPE.ERROR,
      });
    }

    if (isAccountWriteOff) {
      rest.accountWriteoff = true;
      delete rest.taxRule;
      delete rest.invoiceId;
    }

    return processWriteoff(rest, () => {});
  };

  onOpenModal = name => {
    let { isOpenModalInvoice, isOpenModalAccountId } = this.state;
    // const { dataCreate } = this.state;
    // console.log('asdasd', name)
    if (name === 'invoiceId') {
      // if (!dataCreate.accountId) toast('Please input account Id first!', { type: toast.TYPE.WARNING });
      isOpenModalInvoice = true;
    }
    if (name === 'accountId') {
      isOpenModalAccountId = true;
    }
    return this.setState({ isOpenModalInvoice, isOpenModalAccountId });
  };

  onCancel = () => {
    const { dataCancel } = this.state;
    this.setState({ dataCreate: { ...dataCancel }, isAccountWriteOff: false });
  };

  render() {
    const { t, location } = this.props;
    const {
      dataCreate,
      dataInvoiceTable,
      isAccountWriteOff,
      isOpenModalAccountId,
      isOpenModalInvoice,
      optionResonCodes,
      fixedTaxRuleForAROps,
      isFilterInstallmentInvoicesForAR,
    } = this.state;
    const messageErrorTable =
      dataCreate.accountId && isEmpty(dataInvoiceTable) ? `No record has found for ${dataCreate.accountId}` : '';
    const { permissionsArOps } = this.props;
    let modeProcessTaxWriteoff = 0;
    let modeProcessWriteoff = 0;
    let modeGetArOpsTaxOptions = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeProcessTaxWriteoff = checkPermissionProcessTaxWriteoff({ listPermission });
      modeProcessWriteoff = checkPermissionProcessWriteoff({ listPermission });
      modeGetArOpsTaxOptions = checkPermissionGetArOpsTaxOptions({ listPermission });
    }
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={{
            pathname: RouteNames.arOperationsWriteOffs.path,
            state: location?.state ? location.state : null,
          }}
          titleBtn={t('label.back')}
          titleRight={t('arOperationPage:sidebar.writeOffs')}
        />
        {modeProcessWriteoff !== 0 && (
          <div className="col-md-12 mb-30">
            <WriteOffForm
              title={t('label.applyWriteOff')}
              listFields={
                dataCreate.customReason
                  ? writeOffFieldApply(
                      dataCreate,
                      optionResonCodes,
                      fixedTaxRuleForAROps,
                      isDisablePercent,
                      isAccountWriteOff
                    )
                  : writeOffFieldApply(
                      dataCreate,
                      optionResonCodes,
                      fixedTaxRuleForAROps,
                      isDisablePercent,
                      isAccountWriteOff
                    ).filter(val => val.name !== 'invoiceCancellation')
              }
              data={dataCreate}
              isApply
              onChange={this.onHandleChange}
              onBlurAmount={this.onBlurAmount}
              onHandleSubmit={this.onHandleSubmit}
              onBlur={this.onBlurInput}
              invoiceData={dataInvoiceTable}
              onClickRowTable={this.onClickRowTable}
              onClick={this.onOpenModal}
              isAccountWriteOff={isAccountWriteOff}
              onChangeSwitch={this.onChangeSwitch}
              messageErrorTable={messageErrorTable}
              modeProcessWriteoff={modeProcessWriteoff}
              modeProcessTaxWriteoff={modeProcessTaxWriteoff}
              modeGetArOpsTaxOptions={modeGetArOpsTaxOptions}
              onCancel={this.onCancel}
            />
          </div>
        )}
        <ModalInvoice
          isOpen={isOpenModalInvoice}
          onSelect={this.onSelectInvoice}
          accountId={dataCreate.accountId}
          selectedParentId={dataCreate.invoiceId}
          isAdditionalFilters
          disabledSearchFields={dataCreate.accountId ? ['organization', 'clientId'] : null}
          onCancel={() => {
            this.setState({ isOpenModalInvoice: false });
          }}
          filterInstallmentInvoices={isFilterInstallmentInvoicesForAR}
        />
        <ModalAccountId
          isOpen={isOpenModalAccountId}
          onSelect={this.onSelectAccountId}
          accountId={dataCreate.accountId}
          selectedParentId={dataCreate.accountId}
          onCancel={() => {
            this.setState({ isOpenModalAccountId: false });
          }}
        />
      </div>
    );
  }
}

WriteOffsApply.propTypes = {
  selectInvoiceId: PropTypes.func.isRequired,
  processWriteoff: PropTypes.func.isRequired,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

WriteOffsApply.defaultProps = {
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    selectInvoiceId,
    processWriteoff,
    getARReasonCodeConfig,
    getCcpPropertiesConfig,
  })(withRouter(WriteOffsApply))
);
