import gql from 'graphql-tag';

export default () => {
  const getProvSequenceConfig = 'getProvSequenceConfig(dummy: "")';
  return gql`
    query {
      ${getProvSequenceConfig} {
        id
        orderType
        serviceType
        provisioningSequence
        sequences {
          index
          apiName
          apiCategory
          status
          onErrorResubmit
          validateOnly
          queueName
          merchantName
          synchronousResponse
          errorApiName
          prerequisiteInboundApis {
            index
            apiName
          }
        }
        inputs {
          index
          apiName
          serviceTypes
          status
          action
          level
          merchantName
        }
      }
    }
  `;
};
