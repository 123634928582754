import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'scheduleDaily',
    'quantity',
    'unitPrice',
    'grossAmount',
    'discountAmount',
    'netAmount',
    'substituteInvoice'
  ]);
  const manualInvoiceService = `manualInvoiceService(input: ${dataCreate})`;
  return gql`
  mutation {
    ${manualInvoiceService} {
      id
    }
  }
`;
};
