import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ ...filter }) => {
  const filterStr = filter ? convertFilterRequestUrl(filter, ['showActiveOnly']) : '';
  const getCostCenter = `getCostCenter(input: ${filterStr})`;
  return gql`
    query {
      ${getCostCenter} {
        id
        index
        name
        description
        code
        enterprise
        department
        status
        costType
      }
    }
  `;
};
