import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { SideBar } from '../../components/common';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionGetPaymentConfiguration,
  checkPermissionSearchPayment,
  checkPermissionProcessPaymentSuspense,
  checkPermissionBatchPaymentUpload,
  checkPermissionBatchPaymentProcess,
  checkPermissionGetPaymentSurcharge,
  checkPermissionGetBatchPaymentFile,
  checkPermissionGetPaymentItemMap,
  checkPermissionGetConfigPaymentAllocation,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, permissionsPayment } = this.props;
    let modeBatchPaymentUpload = 0;
    let modeProcessPayment = 0;
    const childConfig = [];
    let modeGetPaymentSurcharge = 0;
    let modeGetBatchPaymentFile = 0;
    let modeGetPaymentItemMap = 0;
    let modeGetConfigPaymentAllocation = 0;
    if (!isHasPermissionWithMenu({ permissions: permissionsPayment, listKeys: permissionList.payments }))
      return null;
    const paymentChild = [];
    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermissionPricing = permissionsPayment.paymentModulePermissions;
      modeBatchPaymentUpload = checkPermissionBatchPaymentUpload({ listPermission: listPermissionPricing });
      modeProcessPayment = checkPermissionBatchPaymentProcess({ listPermission: listPermissionPricing });
      modeGetPaymentSurcharge = checkPermissionGetPaymentSurcharge({ listPermission: listPermissionPricing });
      modeGetBatchPaymentFile = checkPermissionGetBatchPaymentFile({ listPermission: listPermissionPricing });
      modeGetPaymentItemMap = checkPermissionGetPaymentItemMap({ listPermission: listPermissionPricing });
      modeGetConfigPaymentAllocation = checkPermissionGetConfigPaymentAllocation({
        listPermission: listPermissionPricing,
      });
    }

    if (modeGetConfigPaymentAllocation) {
      paymentChild.push({
        icon: 'ti-settings',
        label: 'paymentPage:sidebar.allocationRules',
        href: RouteNames.allocationRules.path,
      });
    }

    if (modeProcessPayment) {
      childConfig.push({
        icon: 'ti-bag',
        label: 'paymentPage:sidebar.processPayment',
        href: RouteNames.processPayment.path,
      });
    }

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'paymentPage:sidebar.paymentAdmin',
          isHiddenChild: true,
          isNoExpand: true,
          childs: [
            {
              icon: 'ti-credit-card',
              label: 'paymentPage:sidebar.paymentConfig',
              href: RouteNames.paymentConfiguration.path,
            },
            modeGetBatchPaymentFile
              ? {
                  icon: 'ti-image',
                  label: 'paymentPage:sidebar.batchPaymentFile',
                  href: RouteNames.batchPaymentFile.path,
                }
              : null,
            modeGetPaymentItemMap
              ? {
                  icon: 'ti-shine',
                  label: 'paymentPage:sidebar.itemMap',
                  href: RouteNames.paymentItemMap.path,
                }
              : null,
            modeGetPaymentSurcharge
              ? {
                  icon: 'ti-briefcase',
                  label: 'paymentPage:sidebar.paymentSurcharge',
                  href: RouteNames.paymentSurcharge.path,
                }
              : null,
            ...paymentChild,
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'paymentPage:sidebar.paymentOperations',
          isHiddenChild: true,
          childs: [
            // {
            //   icon: 'ti-search',
            //   label: 'paymentPage:sidebar.searchAccount',
            //   href: RouteNames.paymentSearchAccount.path,
            // },
            {
              icon: 'ti-save-alt',
              label: 'paymentPage:sidebar.manualPayment',
              href: RouteNames.paymentOneOff.path,
              // wrapperClass: 'child-item',
            },
            {
              icon: 'ti-pencil',
              label: 'paymentPage:sidebar.paymentHistory',
              href: RouteNames.paymentActivity.path,
              // wrapperClass: 'child-item',
            },
            // {
            //   icon: 'ti-layout',
            //   label: 'paymentPage:sidebar.paymentAllocation',
            //   href: RouteNames.paymentAllocation.path,
            //   disable: true,
            // },
            {
              icon: 'ti-folder',
              label: 'paymentPage:sidebar.paymentSuspense',
              href: RouteNames.paymentSuspense.path,
            },
            // {
            //   icon: 'ti-control-shuffle',
            //   label: 'paymentPage:sidebar.customerRefunds',
            //   href: RouteNames.paymentCustomerRefunds.path,
            //   disable: true,
            // },
            // {
            //   icon: 'ti-share',
            //   label: 'paymentPage:sidebar.manualChargebacks',
            //   href: RouteNames.paymentManualChargebacks.path,
            //   disable: true,
            // },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'paymentPage:sidebar.batchProcessing',
          isHiddenChild: true,
          isHiddenItems: true,
          childs: [
            ...childConfig,
            // modeBatchPaymentUpload
            //   ? {
            //       icon: 'ti-bag',
            //       label: 'paymentPage:sidebar.upload',
            //       href: RouteNames.uploadPayment.path,
            //     }
            //   : null,
            // modeProcessPayment
            //   ? {
            //       icon: 'ti-bag',
            //       label: 'paymentPage:sidebar.processPayment',
            //       href: RouteNames.processPayment.path,
            //     }
            //   : null,
          ],
          // subMain: [
          //   {
          //     subMainTitle: 'paymentPage:sidebar.batchPayments',
          //     key: 'batchPayments',
          //     isHiddenItems: true,
          //     childs: [
          //       // modeBatchPaymentUpload
          //       //   ? {
          //       //       icon: 'ti-bag',
          //       //       label: 'paymentPage:sidebar.upload',
          //       //       href: RouteNames.uploadPayment.path,
          //       //     }
          //       //   : null,
          //       modeProcessPayment
          //         ? {
          //             icon: 'ti-bag',
          //             label: 'paymentPage:sidebar.processPayment',
          //             href: RouteNames.processPayment.path,
          //           }
          //         : null,
          //     ],
          //   },
          //   // {
          //   //   subMainTitle: 'paymentPage:sidebar.batchRefunds',
          //   //   key: 'batchRefunds',
          //   //   isHiddenItems: true,
          //   //   childs: [],
          //   //   disable: true,
          //   // },
          //   // {
          //   //   subMainTitle: 'paymentPage:sidebar.batchReversals',
          //   //   key: 'batchReversals',
          //   //   isHiddenItems: true,
          //   //   childs: [],
          //   //   disable: true,
          //   // },
          // ],
        },
        // {
        //   isHaveExpand: true,
        //   mainTilte: 'paymentPage:sidebar.batchRefunds',
        //   isHiddenItems: true,
        //   href: RouteNames.batchRefunds.path,
        //   disable: true,
        // },
        // {
        //   isHaveExpand: true,
        //   mainTilte: 'paymentPage:sidebar.batchReversals',
        //   isHiddenItems: true,
        //   href: RouteNames.batchReversals.path,
        //   disable: true,
        // },
      ],
    };

    const newSideProps = cloneDeep(sideProps);

    let modeGetPaymentConfiguration = 0;
    let modeSearchPayment = 0;
    let modeProcessPaymentSuspense = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeGetPaymentConfiguration = checkPermissionGetPaymentConfiguration({ listPermission });
      modeSearchPayment = checkPermissionSearchPayment({ listPermission });
      modeProcessPaymentSuspense = checkPermissionProcessPaymentSuspense({ listPermission });
    }

    if (modeGetPaymentConfiguration === 0) {
      newSideProps.items.splice(0, 1);
    }

    const indexPaymentAgent = newSideProps.items.findIndex(
      child => child.mainTilte === 'paymentPage:sidebar.paymentOperations'
    );

    if (modeSearchPayment === 0 && indexPaymentAgent > -1) {
      newSideProps.items[indexPaymentAgent].childs = newSideProps.items[indexPaymentAgent].childs.filter(
        item => item.label !== 'paymentPage:sidebar.viewPaymentActivity'
      );
    }

    if (modeProcessPaymentSuspense === 0 && indexPaymentAgent > -1) {
      newSideProps.items[indexPaymentAgent].childs = newSideProps.items[indexPaymentAgent].childs.filter(
        item => item.label !== 'paymentPage:sidebar.paymentSuspense'
      );
    }

    return <SideBar {...newSideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsPayment = store.get('auth').toJS().permissionsApp.arHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsPayment: permissionsPayment.length ? permissionsPayment.filter(a => a.moduleName === 'PAYMENT')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsPayment: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
