import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { strTwoFractionDigits } from '../../../utils/utils';
import { ArOperationsForm } from '../../../components/ArOperationsHub';
import PageTitle from '../../../components/PageTitle';
import { disputeFieldDetails } from '../constant';
import RouteName from '../../App/RouteNames';
import { getArOpsRequestById } from '../actions';
import { makeErrorMessageDisputes } from '../selectors';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import { checkPermissionGetDisputeDetails } from '../CheckPermission';

class DisputeDetail extends React.PureComponent {
  state = {
    disputeData: {},
  };

  componentDidMount() {
    this.doGetDisputeDetail();
  }

  doGetDisputeDetail = () => {
    const {
      match: {
        params: { id },
      },
      getArOpsRequestById,
    } = this.props;
    getArOpsRequestById(id, ({ data }) => this.setState({ disputeData: data }));
  };

  convertValueViewOnly = () => {
    const { disputeData } = this.state;
    const newData = { ...disputeData };
    // newData.percent = strTwoFractionDigits(newData.percent);
    // if (!newData.settledAmount) newData.settledAmount = 0.0;
    // if (!newData.amount) newData.amount = 0.0;
    // newData.settledAmount = `$ ${newData.settledAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    // newData.amount = `$ ${newData.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    return newData;
  };

  render() {
    const { errorMessage, permissionsArOps, t } = this.props;
    const disputeDetailViewOnly = this.convertValueViewOnly();
    let modeGetAdjustmentDetails = 0;
    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeGetAdjustmentDetails = checkPermissionGetDisputeDetails({ listPermission });
    }

    return (
      <div className="content-wrapper">
        {modeGetAdjustmentDetails !== 0 && (
          <div className="mr-4">
            <PageTitle
              linkTo={RouteName.arOperationsDisputes.path}
              titleBtn={t('label.back')}
              titleRight={t('arOperationPage:sidebar.disputes')}
            />
            <div className="col-md-112 mb-30">
              <ArOperationsForm
                title={t('label.disputeDetail')}
                listFields={disputeFieldDetails(t)}
                data={disputeDetailViewOnly}
                isDisabledAllField
                onChange={() => {}}
                linkTo={RouteName.arOperationsDisputes.path}
                errorMessage={errorMessage}
                onCancel={this.doGetDisputeDetail}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

DisputeDetail.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  getArOpsRequestById: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

DisputeDetail.defaultProps = {
  match: {},
  errorMessage: '',
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessageDisputes() || '',
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getArOpsRequestById })(DisputeDetail));
