import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t }) => [
  {
    xSteps: 3,
    columns: [],
    data: [[{ value: 'GL ACCOUNTS', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.common, // accountNumber
      columns.common, // name
      columns.common, // type
      columns.common, // detailType
      columns.description, // description
      columns.common, // notesAccount
      columns.common, // notesName
    ],
    data: [
      [
        {
          value: t('label.accountNumber'),
          style: styleHeader,
        },
        {
          value: t('label.name'),
          style: styleHeader,
        },
        {
          value: t('label.type'),
          style: styleHeader,
        },
        {
          value: t('label.detailType'),
          style: styleHeader,
        },
        {
          value: t('label.description'),
          style: styleHeader,
        },
        {
          value: t('label.notesAccount'),
          style: styleHeader,
        },
        {
          value: t('label.notesName'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  if (!data || !data.length) return '';
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.accountNumber ? item.accountNumber : '' });
      newItem.push({ value: item.name ? item.name : '' });
      newItem.push({ value: item.type ? item.type : '' });
      newItem.push({ value: item.detailType ? item.detailType : '' });
      newItem.push({ value: item.description ? item.description : '' });
      newItem.push({ value: item.notesAccount ? item.notesAccount : '' });
      newItem.push({ value: item.notesName ? item.notesName : '' });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
