import gql from 'graphql-tag';

export default () => gql`
  query {
    getZeroRateUsageConfig(dummy: "") {
      id
      description
      name
      zeroRateUsageConfigList {
        index
        source
        stream
        usageType
        usageClass
        itemId
        serviceType
      }
    }
  }
`;
