import React from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AccordionGroup = ({ children }) => {
  return <div className="accordion gray plus-icon round">{children}</div>;
};
AccordionGroup.propTypes = {
  children: PropTypes.any.isRequired,
};

AccordionGroup.defaultProps = {
  children: '',
};

const Accordion = ({ title, children, isOpen, onClick, className, disabled, switcher }) => {
  const newProps = disabled ? { disabled: true } : { onClick };
  const { t } = useTranslation('common');
  return (
    <div className={`acd-group ${isOpen ? 'acd-active' : ''} ${className || ''}`}>
      <a className="acd-heading" {...newProps}>
        {t(title)}
        {switcher || ''}
      </a>
      <Collapse isOpen={isOpen} className="acd-des col-md-12">
        {children}
      </Collapse>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired,
  switcher: PropTypes.any,
};

Accordion.defaultProps = {
  switcher: null,
  isOpen: false,
  title: '',
  children: '',
};

export { Accordion, AccordionGroup };
