import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionGetTaxConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTaxconfig });
};

const checkPermissionCreateTaxConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createTaxconfig });
};

const checkPermissionModifyTaxConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyTaxconfig });
};

const checkPermissionGetTaxTypeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_TAX_TYPE_CONFIG });
};

const checkPermissionModifyTaxTypeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_TAX_TYPE_CONFIG });
};

const checkPermissionCreateTaxTypeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createTaxconfig });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeGetTaxConfig = 0;
  let modeCreateTaxConfig = 0;
  let modeModifyTaxConfig = 0;

  let modeGetTaxTypeConfig = 0;
  let modeCreateTaxTypeConfig = 0;
  let modeModifyTaxTypeConfig = 0;

  modeGetTaxConfig = checkPermissionGetTaxConfig({ listPermission });
  modeCreateTaxConfig = checkPermissionCreateTaxConfig({ listPermission });
  modeModifyTaxConfig = checkPermissionModifyTaxConfig({ listPermission });

  modeGetTaxTypeConfig = checkPermissionGetTaxTypeConfig({ listPermission });
  modeCreateTaxTypeConfig = checkPermissionCreateTaxTypeConfig({ listPermission });
  modeModifyTaxTypeConfig = checkPermissionModifyTaxTypeConfig({ listPermission });

  return {
    modeGetTaxConfig,
    modeCreateTaxConfig,
    modeModifyTaxConfig,
    modeGetTaxTypeConfig,
    modeCreateTaxTypeConfig,
    modeModifyTaxTypeConfig,
  };
};

export {
  checkPermissionGetTaxConfig,
  checkPermissionCreateTaxConfig,
  checkPermissionModifyTaxConfig,
  checkPermissionForAllPages,
  checkPermissionGetTaxTypeConfig,
  checkPermissionCreateTaxTypeConfig,
  checkPermissionModifyTaxTypeConfig,
};
