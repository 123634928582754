import gql from 'graphql-tag';

export default () => {
  const getConfigUsagePlatforms = 'getConfigUsagePlatforms(dummy: "")';
  return gql`
    query {
      ${getConfigUsagePlatforms} {
        id
        name
        configUsagePlatformList {
          index
          name
          description
          serviceType
          interfaceType
          status
        }
      }
    }
  `;
};
