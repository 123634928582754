/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropsType from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import RouteName from '../../App/RouteNames';
import { getUserGroups, modifyUserGroups, searchUserGroups } from '../actions';
import { makeGetUserGroups } from '../selectors';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import {
  GenericInput,
  TitleFrom,
  Breadcrumb,
  CollapsibleTable,
  DataTable,
  QuickNotesConfig,
  TablePagination,
} from '../../../components/common';
import { UserGroupSearchForm } from '../../../components/OperationHub';
import ModalSearchUsers from '../../../components/common/ModalSearchUsers';
import { getPageTotalCount } from '../../../utils/utils';
import {
  checkPermissionCreateConfigUserGroups,
  checkPermissionModifyConfigUserGroups,
  checkPermissionGetConfigUserGroups,
} from '../CheckPermission';

const userGroupsSort = {
  groupName: {
    asc: 'groupName_ASC',
    desc: 'groupName_DESC',
  },
};

const UserGroups = ({ userGroups, getUserGroups, modifyUserGroups, permissionsArOps, searchUserGroups, location }) => {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState(-1);
  const [itemGroupSelect, setItemGroupSelect] = useState(null);
  const [indexGroupSelect, setIndexGroupSelect] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(userGroups);
  const [wasValidated, setValidate] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [isFirstBack, setIsFirstBack] = useState(true);

  const formRef = useRef();

  const doGetUserGroups = () => {
    setLoading(true);
    let newFilter = filter;
    if (isFirstBack && location?.state?.filter) {
      newFilter = location?.state?.filter;
      setIsFirstBack(false);
      setFilter(location?.state?.filter);
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? userGroupsSort[sorted.sortCol][sorted.sortDir] : null,
    };
    searchUserGroups(payload, ({ success, data }) => {
      setLoading(false);
      if (success) setData(data);
    });
  };

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  useEffect(() => {
    doGetUserGroups();
  }, [getUserGroups, filter, page, size, sorted]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  let modeGetConfigUserGroups = 0;
  let modeModifyConfigUserGroups = 0;
  let modeCreateConfigUserGroups = 0;
  if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
    const listPermission = permissionsArOps.arOpsModulePermissions;
    modeGetConfigUserGroups = checkPermissionGetConfigUserGroups({ listPermission });
    modeModifyConfigUserGroups = checkPermissionModifyConfigUserGroups({ listPermission });
    modeCreateConfigUserGroups = checkPermissionCreateConfigUserGroups({ listPermission });
  }

  if (!modeGetConfigUserGroups) return '';

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onRemoveUser = ({ index }) => {
    try {
      const newData = cloneDeep(data);
      newData[activeTab].isModified = true;
      if (newData[activeTab].users[index].isNew) {
        newData[activeTab].users.splice(index, 1);
      } else {
        newData[activeTab].users[index] = {
          index: newData[activeTab].users[index].index,
        };
      }

      setData(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onHandleAddUser = () => {
    const newData = cloneDeep(data);
    let lastIndex = 0;
    if (!newData[activeTab].users) newData[activeTab].users = [];
    newData[activeTab].users.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
    newData[activeTab].isModified = true;
    newData[activeTab].users.push({
      index: lastIndex + 1,
      userId: null,
      isNew: true,
    });
    setData(newData);
  };

  const onSelect = (id, row) => {
    try {
      const newData = cloneDeep(data);
      newData[activeTab].isModified = true;
      newData[activeTab].users[indexSelect].userId = id;
      newData[activeTab].users[indexSelect].firstName = row && row.contact ? row.contact[0].firstName : '';
      newData[activeTab].users[indexSelect].lastName = row && row.contact ? row.contact[0].lastName : '';
      newData[activeTab].users[indexSelect].email = row && row.contact ? row.contact[0].email : '';
      setData(newData);
      setIsOpen(false);
    } catch (error) {
      setIsOpen(false);
    }
  };

  const onToggleTab = (index, item, indexItem) => {
    if (activeTab === indexItem) setActiveTab(-1);
    if (activeTab !== indexItem) {
      setActiveTab(indexItem);
      setItemGroupSelect(item);
      setIndexGroupSelect(index);
      // if (val && val.id) this.doGetAPIById(val.id);
      // this.setState({ activeTab: indexItem, indexSelect: item.index, indexSelected: indexItem });
    }
  };
  const headerTable = [
    {
      name: 'id ',
      label: 'label.id',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.groupId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="id"
          readOnly
        />
      ),
    },
    {
      name: 'groupName',
      label: 'label.name',
      sortable: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.groupName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="groupName"
        />
      ),
    },

    {
      name: '',
      label: 'label.users',
      style: { width: 100 },
      render: (colName, item, idx, indexParent, activeTab) => (
        <button type="button" className="btn-expand-table mr-3">
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
  ];

  const headerTableUser = [
    {
      name: 'userId',
      label: 'label.userId',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="userId"
          fa="fa fa-external-link"
          onClick={() => {
            setIsOpen(true);
            setItemSelect(item);
            setIndexSelect(idx);
          }}
          required
        />
      ),
    },
    {
      name: 'firstName',
      label: 'label.firstName',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.firstName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="firstName"
          readOnly
        />
      ),
    },
    {
      name: 'lastName',
      label: 'label.lastName',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.lastName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="lastName"
          readOnly
        />
      ),
    },
    {
      name: 'email',
      label: 'label.email',
      style: { minWidth: '230px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.email}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="email"
          readOnly
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveUser({ index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    const groupsPayload = data
      .filter(val => !!val.isModified)
      .map(val => {
        const { id, users, isModified, ...rest } = val;
        const payloadUser = [];
        if (users && users.length) {
          users
            .filter(val => !!val?.userId)
            .forEach((u, index) => {
              if (u.userId) {
                payloadUser.push({
                  userId: u.userId,
                  index: index + 1,
                });
              }
            });
          users
            .filter(val => !val.isNew)
            .forEach(att => {
              if (att.index > payloadUser.length) payloadUser.push({ index: att.index });
            });
        }
        return {
          ...rest,
          users: payloadUser,
        };
      });
    const newPayload = {
      id: data && data[0].id ? data[0].id : '',
      groups: groupsPayload,
    };
    return modifyUserGroups(newPayload, ({ success }) => {
      if (success) doGetUserGroups();
    });
  };

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            {modeCreateConfigUserGroups === 2 && (
              <NavLink
                className="button x-small"
                to={
                  data && data.id
                    ? RouteName.userManagementUserGroupsModify.path
                    : { pathname: RouteName.userManagementUserGroupsAdd.path, state: { filter } }
                }
              >
                {t('label.createNewUserGroups')}
              </NavLink>
            )}
            {modeCreateConfigUserGroups !== 2 && (
              <NavLink className="button x-small" to={RouteName.userManagementRole.path}>
                {t('label.back')}
              </NavLink>
            )}
            <QuickNotesConfig />
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                {
                  name: t('userManagementPage:sidebar.userGroups'),
                  url: RouteName.userManagementUserGroups.path,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="repeater-file">
              <div data-repeater-list="group-a">
                <UserGroupSearchForm
                  onSubmit={onHandleSubmit}
                  defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        ref={formRef}
        noValidate
        onSubmit={onSubmit}
        className={`col-md-12 mb-30 needs-validation mb-30 ${wasValidated ? 'was-validated' : ''}`}
      >
        <div className="card card-statistics h-100">
          <div className="d-block d-md-flex">
            <div className="d-block d-md-flex clearfix sm-mt-20 ">
              <TitleFrom title={t('userManagementPage:sidebar.userGroups')} />
            </div>
          </div>
          <CollapsibleTable
            columns={headerTable}
            data={data && data.length ? data : []}
            indexViewer={activeTab}
            onClickRow={onToggleTab}
            onSort={onSortColumn}
          >
            <br />
            <div className="col-md-12">
              <DataTable
                columns={headerTableUser}
                isSupportRemoveIndex
                data={data && data[activeTab] && data[activeTab].users ? data[activeTab].users : []}
              />
            </div>
            <div className="form-group col-md-12 buttons-attibute">
              {modeModifyConfigUserGroups === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={onHandleAddUser}
                >
                  +
                  {t('label.addUserToGroup')}
                </button>
              )}
            </div>
          </CollapsibleTable>
          <div className="form-group col-md-12 buttons-attibute border-top">
            {modeModifyConfigUserGroups === 2 && (
              <button
                type="submit"
                className="button button-border x-small float-right ml-2"
              >
                {t('label.modify')}
              </button>
            )}
            <button
              type="button"
              onClick={() => doGetUserGroups()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </form>
      <br />
      <div className="mb-30">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalSearchUsers
        isOpen={isOpen}
        onSelect={onSelect}
        onCancel={() => setIsOpen(false)}
        selectedParentId={itemSelect ? itemSelect.userId : null}
      />
    </div>
  );
};

UserGroups.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

UserGroups.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

const mapStateToProps = createStructuredSelector({
  userGroups: makeGetUserGroups() || {},
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default connect(mapStateToProps, { getUserGroups, modifyUserGroups, searchUserGroups })(UserGroups);
