import React from 'react';
import PropTypes from 'prop-types';
import { GenericInput } from '../../../common';

const WorkWeekConfigurationForm = ({ onChange, data }) => (
  <div className="col-md-12">
    <div className="card-body row">
      <GenericInput
        label="label.startDayOfWeek"
        type="select"
        value={data.startOfWeek || null}
        wrapperClass="col-md-4"
        tOptions="selections:dayOfWeek"
        onChange={onChange}
        name="startOfWeek"
      />
      <GenericInput
        label="label.endDayOfWeek"
        value={data.endOfWeek || null}
        type="select"
        wrapperClass="col-md-4"
        onChange={onChange}
        tOptions="selections:dayOfWeek"
        name="endOfWeek"
      />
    </div>
  </div>
);

WorkWeekConfigurationForm.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
};

WorkWeekConfigurationForm.defaultProps = {
  onChange: () => {},
  data: {},
};

export default WorkWeekConfigurationForm;
