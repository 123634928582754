import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t }) => [
  {
    xSteps: 1,
    columns: [],
    data: [[{ value: 'LIST CURRENCY EXCHANGE', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [columns.common, columns.common, columns.effectiveDate, columns.common],
    data: [
      [
        {
          value: t('label.sourceCurrency'),
          style: styleHeader,
        },
        {
          value: t('label.targetCurrency'),
          style: styleHeader,
        },
        {
          value: t('label.type'),
          style: styleHeader,
        },
        {
          value: t('label.exchangeRate'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.sourceCurrency ? item.sourceCurrency : '' });
      newItem.push({ value: item.targetCurrency ? item.targetCurrency : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'type', fieldOption: 'exchangeRateType' }) });
      newItem.push({ value: item.exchangeRate ? item.exchangeRate : '' });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
