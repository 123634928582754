import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../utils/utils';
import { DataTable, TablePagination, Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import bundleSort from '../../../constantsApp/bundleManager/bundleSort';
import { makeGetListBundles, makeErrorMessage } from '../selectors';
import { searchBundles, setParamsBundles } from '../actions';
import { BundleSearchForm } from '../../../components/PricingHub';
import {
  checkPermissionSearchBundles,
  checkPermissionModifyBundle,
  checkPermissionGetBundle,
  checkPermissionCreateBundle,
} from '../CheckPermission';
import { makeGetPermissionsBundleManagement } from '../../App/selectors';

class BundleItem extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state && props.listBundles) {
      return {
        totalCount: getPageTotalCount({ ...state, items: props.listBundles }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchBundles();
      });
    } else {
      this.doSearchBundles();
    }
  }

  onClickRow = e => {
    e.preventDefault();
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchBundles());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchBundles());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchBundles());
  };

  doSearchBundles = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchBundles } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && bundleSort[sorted.sortCol]
        ? bundleSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    searchBundles(params, () => {
      this.setState({ isSearching: false, filter });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchBundles());
  };

  render() {
    const { listBundles, permissionsBundleManagement, t, location } = this.props;
    const { sorted, isSearching, page, size, totalCount, filter } = this.state;
    let modeSearchBundles = 0;
    let modeGetBundle = 0;
    let modeCreateBundle = 0;
    let modeModifyBundle = 0;
    const tableColumns = [
      {
        name: 'id',
        label: 'common:label.id',
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.bundleItemEdit.path, item.id), state: { filter } }}
          >
            {item.id}
          </Link>
        ),
      },
      {
        name: 'name',
        label: 'common:label.name',
        sortName: 'name',
        sortable: true,
        style: { minWidth: '120px' },
      },
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        sortable: true,
        style: { minWidth: '120px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        sortable: true,
        style: { minWidth: '130px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        sortable: true,
        style: { minWidth: '120px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountSubType',
        label: 'common:label.accountSubType',
        sortable: true,
        style: { minWidth: '140px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'country',
        label: 'common:label.country',
        sortable: true,
        style: { minWidth: '90px' },
      },
      {
        name: 'currency',
        label: 'common:label.currency',
        sortable: true,
        style: { minWidth: '90px' },
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        sortable: true,
        style: { minWidth: '120px' },
      },
      {
        name: 'action',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.bundleItemEdit.path, item.id), state: { filter } }}
          >
            {t('label.view')}
          </Link>
        ),
      },
    ];

    const newTableColumns = cloneDeep(tableColumns);

    if (permissionsBundleManagement && permissionsBundleManagement.bundleModulePermissions) {
      const listPermissionBundle = permissionsBundleManagement.bundleModulePermissions;
      modeSearchBundles = checkPermissionSearchBundles({ listPermission: listPermissionBundle });
      modeGetBundle = checkPermissionGetBundle({ listPermission: listPermissionBundle });
      modeCreateBundle = checkPermissionCreateBundle({ listPermission: listPermissionBundle });
      modeModifyBundle = checkPermissionModifyBundle({ listPermission: listPermissionBundle });
    }
    if (modeSearchBundles === 0 || modeGetBundle === 0) return '';
    if (modeModifyBundle === 0) {
      newTableColumns[0] = {
        name: 'id',
        label: 'common:label.id',
      };
      newTableColumns.splice(newTableColumns.length - 1, 1);
    }

    const tableConfig = {
      columns: newTableColumns,
      sorted: { ...sorted },
      data: listBundles,
      onSort: this.onSortColumn,
      onClickRow: this.onClickRow,
    };

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={
                  modeCreateBundle !== 2
                    ? RouteNames.dashboard.path
                    : { pathname: RouteNames.bundleItemAdd.path, state: { filter } }
                }
                className="button x-small"
              >
                {modeCreateBundle !== 2 ? t('label.back') : t('label.createNewBundle')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.bundle') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <BundleSearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={isSearching}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} isLoading={isSearching} />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

BundleItem.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  searchBundles: PropTypes.func.isRequired,
  listBundles: PropTypes.arrayOf(PropTypes.any),
};

BundleItem.defaultProps = {
  permissionsBundleManagement: {},
  listBundles: [],
};

const mapStateToProps = createStructuredSelector({
  listBundles: makeGetListBundles() || [],
  errorMessage: makeErrorMessage() || '',
  permissionsBundleManagement: makeGetPermissionsBundleManagement() || {},
});

const newBundleItem = connect(mapStateToProps, { searchBundles, setParamsBundles })(BundleItem);

export default withTranslation(['common', 'navbar', 'bundlePage'])(newBundleItem);
