/**
 * Bundle Value Not String
 */

export default [
  'status',
  'minimumQuantity',
  'maximumQuantity',
  'salesChannel',
  'marketSegment',
  'accountType',
  'accountSubType',
  'index',
  'validityDuration',
  'validityUnit',
  'serviceType',
  'priceOverride',
  'priceOffset',
  'discountAmountOverride',
  'discountPercentOverride',
];
