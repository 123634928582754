import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = data.map(val =>
    parseToMutationRequest(val, ['status', 'index', 'frequency', 'adjustingPeriodCount', 'unit', 'status'])
  );
  const createCalendarConfig = `createCalendarConfig(input: [${dataCreate}])`;
  return gql`
  mutation {
    ${createCalendarConfig} {
      id
    }
  }
`;
};
