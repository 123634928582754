import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['jurisdiction', 'invoiceStatus', 'objectType'])}`
    : '';
  const getTaxDetailReport = `getTaxDetailReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getTaxDetailReport} {
        accountId
        invoiceId
        crdrNoteId
        state
        country
        taxcode
        billEndDate
        invoiceStatus
        jurisdiction
        taxableAmount
        exemptAmount
        taxAmount
        taxDescription
        taxCategory
        currency
        company
      }
    }
  `;
};
