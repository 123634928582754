import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchServiceCancellationExtract = `searchServiceCancellationExtract(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchServiceCancellationExtract} {
        cancelTypeId
        cancelType
        clientId
        commercialName
        personalEV
        salesAgentId
        salesAgentName
        supportTicket
        cancellationDate
        serviceId
        serviceName
        accountId
        instanceId
        cancellationReasonId
        cancellationReason
        proratedAmount
        serviceType
      }
    }
  `;
};
