import gql from 'graphql-tag';

export default dummy => {
  const getJobScheduleByDate = `getJobScheduleByDate(input: {scheduleDate: "${dummy ||
    ''}"})`;
  return gql`
    query {
      ${getJobScheduleByDate} {
        id
        status
        scheduleDate
        userId
        resetAllowed
        jobScheduleList {
          index
          jobId
          predJobId
          nextJobId
          name
          notes
          userId
          scheduleTime
          type
          scheduleType
          status
          count
          countSuccess
          countError
        }       
      }
    }
  `;
};
