import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataTable, ModalWithItem } from '../common';
import OfferForm from './PricingForm/OfferForm';
import DiscountForm from './DiscountingForm/DiscountForm';

export default function AuditHistoryForm({
  isDisccountOffer,
  getAuditData,
  id,
  convertAuditJsonData,
  optionsAccumulatorId,
  optionsGrantId,
  usageContainerRecordList,
  isShowBilledService,
  isOpenItem,
}) {
  const { t } = useTranslation('common');

  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onToggleModal = () => {
    setIsOpenModal(false);
    setJsonData({});
  };

  const doConvertAuditJsonData = item => {
    convertAuditJsonData({ id: item.id, effectiveDate: item.effectiveDate }, ({ success, data }) => {
      if (success) {
        setIsOpenModal(true);
        setJsonData(data || {});
      }
    });
  };

  const auditHistoryColumns = [
    {
      name: 'id',
      label: 'label.id',
    },
    {
      name: 'name',
      label: 'label.name',
    },
    {
      name: 'createdDate',
      label: 'label.createdDate',
    },
    {
      name: 'effectiveDate',
      label: 'label.effectiveDate',
    },
    {
      name: 'status',
      label: 'label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    isDisccountOffer
      ? {
          name: 'discountingModel',
          label: 'label.discountingModel',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:discountingModel')().find(val => val.value === item.discountingModel) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        }
      : {
          name: 'pricingModel',
          label: 'label.pricingModel',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:pricingModel')().find(val => val.value === item.pricingModel) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
    {
      name: 'transactionType',
      label: 'label.transactionType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:transactionType')().find(val => val.value === item.transactionType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'view',
      label: 'common:label.view',
      render: (colName, item) => (
        <button type="button" className="btn btn-outline-success btn-sm" onClick={() => doConvertAuditJsonData(item)}>
          {t('label.viewDetails')}
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (!isFetched && isOpenItem) {
      setIsFetched(true);
      getAuditData(id, ({ success, data }) => {
        if (success) {
          setData(data || []);
        }
      });
    }
  }, [getAuditData, id, isDisccountOffer, isFetched, isOpenItem]);

  const renderForm = useMemo(() => {
    return (
      <>
        <DataTable columns={auditHistoryColumns} data={data && data.length ? data : []} />
        <ModalWithItem
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModal}
          onToggle={onToggleModal}
          modalTitle={t('label.auditHistoryDetails')}
        >
          {!isDisccountOffer && (
            <OfferForm
              onCancel={onToggleModal}
              item={jsonData}
              optionsAccumulatorId={optionsAccumulatorId}
              optionsGrantId={optionsGrantId}
              usageContainerRecordList={usageContainerRecordList}
              isShowBilledService={isShowBilledService}
            />
          )}
          {isDisccountOffer && (
            <DiscountForm
              onCancel={onToggleModal}
              item={jsonData}
              optionsAccumulatorId={optionsAccumulatorId}
              optionsGrantId={optionsGrantId}
              usageContainerRecordList={usageContainerRecordList}
            />
          )}
        </ModalWithItem>
      </>
    );
  }, [
    auditHistoryColumns,
    data,
    isDisccountOffer,
    isOpenModal,
    isShowBilledService,
    jsonData,
    optionsAccumulatorId,
    optionsGrantId,
    t,
    usageContainerRecordList,
  ]);

  return <div className="col-md-12 p-0 mt-3 mb-3">{renderForm}</div>;
}

AuditHistoryForm.propTypes = {
  getAuditData: PropTypes.func.isRequired,
  convertAuditJsonData: PropTypes.func.isRequired,
  isDisccountOffer: PropTypes.bool,
  id: PropTypes.string,
  optionsAccumulatorId: PropTypes.arrayOf(PropTypes.any),
  optionsGrantId: PropTypes.arrayOf(PropTypes.any),
  usageContainerRecordList: PropTypes.arrayOf(PropTypes.any),
  isShowBilledService: PropTypes.bool,
  isOpenItem: PropTypes.bool,
};

AuditHistoryForm.defaultProps = {
  id: '',
  isDisccountOffer: false,
  optionsAccumulatorId: [],
  optionsGrantId: [],
  usageContainerRecordList: [],
  isShowBilledService: false,
  isOpenItem: false,
};
