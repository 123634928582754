import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.batchId'),
  t('label.fileName'),
  t('label.fileDate'),
  t('label.source'),
  t('label.startDate'),
  t('label.endDate'),
  t('label.provisioningId'),
  t('label.quantityUnit'),
  // t('label.productLine'),
  t('label.usageId'),
  t('label.usageClass'),
  t('label.recordIndicator'),
  t('label.grossValue'),
  t('label.netValue'),
  t('label.itemId'),
  t('label.status'),
  t('label.errorCode'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.batchId ? item.batchId : '');
      newItem.push(item.fileName ? item.fileName : '');
      newItem.push(item.fileDate ? item.fileDate : '');
      newItem.push(item.source ? item.source : '');
      newItem.push(item.startDate ? item.startDate : '');
      newItem.push(item.endDate ? item.endDate : '');
      newItem.push(item.provisioningId ? item.provisioningId : '');
      newItem.push(item.quantityUnit ? item.quantityUnit : '');
      // newItem.push(getLabelOptions({ item, t, fieldName: 'productLine', fieldOption: 'serviceType' }));
      newItem.push(item.usageId ? item.usageId : '');
      newItem.push(item.usageClass ? item.usageClass : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'recordIndicator', fieldOption: 'usageRecIndicator' }));
      newItem.push(item.grossValue ? item.grossValue : '');
      newItem.push(item.netValue ? item.netValue : '');
      newItem.push(item.itemId ? item.itemId : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'usageRecStatus' }));
      newItem.push(item.errorCode ? item.errorCode : '');
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 5, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
