import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.client',
      name: 'client',
    },
    {
      label: 'label.employee',
      name: 'employeeId',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:timeAndBillingObjectStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.startDateWithRank',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDateWithRank',
      name: 'endDate',
      type: 'date',
    },
  ];

  btnSpan = 'col-md-9';
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SearchForm);
