import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index']);
  const createUom = `createUom(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createUom} {
        id
      }
    }
  `;
};
