import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
// import { customerSelect } from '../../../constantsApp';
import { FormWithTableItem, FocusInputTable, FocusSelectTable } from '../../../../components/common';
import {
  getCustomAttributesByObjectTypeTenant,
  ModifyCustomAttributesTenant,
  createCustomAttributesTenant,
} from '../../actions';

const headers = ['label.attributeName', 'label.attributeType', 'label.length', 'label.mandatory'];
class CustomerAttribute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customAttributes: {},
      isCreateAttributes: true,
      isLoading: true,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.doGetAttribute();
  }

  doGetAttribute = () => {
    const { getCustomAttributesByObjectTypeTenant } = this.props;
    this.setState({ isLoading: true, customAttributes: {} });
    getCustomAttributesByObjectTypeTenant(({ success, data }) => {
      if (data && success) {
        this.setState({
          customAttributes: data || { attributes: [] },
          isCreateAttributes: false,
        });
      }
      this.setState({ isLoading: false });
    });
  };

  handleCustomAttributes = () => {
    const { customAttributes, isCreateAttributes } = this.state;
    const { createCustomAttributesTenant, ModifyCustomAttributesTenant } = this.props;
    const payload = cloneDeep(customAttributes);
    if (isCreateAttributes) {
      if (payload.attributes && payload.attributes.length)
        payload.attributes = payload.attributes.map(val => {
          const { isNew, ...rest } = val;
          return rest;
        });
      return createCustomAttributesTenant(payload, ({ success, data }) => {
        if (success) {
          this.setState({
            customAttributes: data || { attributes: [] },
            isCreateAttributes: false,
          });
        }
      });
    }
    if (payload.attributes && payload.attributes.length) {
      payload.attributes = payload.attributes.map((val, idx) => {
        const { isNew, index, ...rest } = val;
        return { ...rest, index: idx + 1 };
      });
      payload.attributes.forEach(att => {
        if (att.index > payload.attributes.length) payload.attributes.push({ index: att.index });
      });
    }
    return ModifyCustomAttributesTenant(payload, ({ success, data }) => {
      if (success) {
        this.setState({
          customAttributes: data || { attributes: [] },
          isCreateAttributes: false,
        });
      }
    });
  };

  onUpdateValue = (index, name, value) => {
    const { customAttributes } = this.state;
    const newData = cloneDeep(customAttributes);
    newData.attributes[index][name] = value;
    this.setState({ customAttributes: newData });
  };

  addNewAttribute = () => {
    const { customAttributes } = this.state;
    const newData = cloneDeep(customAttributes);
    if (!newData.attributes) newData.attributes = [];
    const newAttribute = {
      index: newData.attributes.length + 1,
      length: null,
      mandatory: 'N',
      name: '',
      type: '',
      isNew: true,
    };
    newData.attributes = [newAttribute, ...newData.attributes];
    // newData.attributes.push(newAttribute);

    this.setState({ customAttributes: newData });
  };

  renderRowChild = (row, id) => {
    const { t } = this.props;
    if (row.isNew) {
      return (
        <tr key={id}>
          <td>
            <input
              type="text"
              value={row.name}
              name="name"
              onChange={evt => {
                this.onUpdateValue(id, evt.target.name, evt.target.value);
              }}
              className="form-control input-group"
            />
          </td>
          <td className="attribute-select">
            <Select
              value={t('selections:variableTypes')().find(e => e.value === row.type)}
              onChange={val => {
                this.onUpdateValue(id, 'type', val.value);
              }}
              options={t('selections:variableTypes')()}
              className="react-select"
              classNamePrefix="custom-react-select"
              isClearable
            />
          </td>

          <td>
            <input
              type={row.type === 'VARCHAR' ? 'number' : 'text'}
              value={row.length || null}
              name="length"
              disabled={row.type !== 'VARCHAR'}
              onChange={evt => {
                this.onUpdateValue(id, evt.target.name, evt.target.value);
              }}
              className="form-control input-group"
            />
          </td>
          <td className="attribute-select">
            <Select
              value={{ value: row.mandatory, label: row.mandatory }}
              onChange={val => {
                this.onUpdateValue(id, 'mandatory', val.value);
              }}
              options={t('selections:mandatory')()}
              className="react-select"
              classNamePrefix="custom-react-select"
              isClearable
            />
          </td>
        </tr>
      );
    }
    return (
      <tr key={id}>
        <FocusInputTable
          value={row.name}
          name="name"
          index={id}
          onUpdateValue={(index, name, value) => {
            this.onUpdateValue(index, name, value);
          }}
        />

        <FocusSelectTable
          value={t('selections:variableTypes')().find(e => e.value === row.type)}
          options={t('selections:variableTypes')()}
          name="type"
          index={id}
          onUpdateValue={(index, name, value) => {
            this.onUpdateValue(index, name, value);
          }}
        />
        {row.type === 'VARCHAR' ? (
          <FocusInputTable
            type="number"
            value={row.length}
            name="length"
            index={id}
            onUpdateValue={(index, name, value) => {
              this.onUpdateValue(index, name, value);
            }}
          />
        ) : (
          <td>{row.length || ''}</td>
        )}

        <FocusSelectTable
          value={{ value: row.mandatory, label: row.mandatory }}
          options={t('selections:mandatory')()}
          name="mandatory"
          index={id}
          onUpdateValue={(index, name, value) => {
            this.onUpdateValue(index, name, value);
          }}
        />
      </tr>
    );
  };

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <thead>
        <tr>
          {headers.map(item => (
            <th key={item} className="w-25">
              {t(item) || ''}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  renderContent = () => {
    const { customAttributes, isLoading } = this.state;
    const { t } = this.props;
    if (customAttributes.attributes && customAttributes.attributes.length) {
      return <tbody>{customAttributes.attributes.map(this.renderRowChild)}</tbody>;
    }
    return (
      <tbody>
        <tr>
          <td className="text-center" colSpan={4}>
            {!isLoading && <span>{t('label.noRecord')}</span>}
            {isLoading && <ScaleLoader loading size={100} color="#28a745" />}
          </td>
        </tr>
      </tbody>
    );
  };

  render() {
    const { isCreateAttributes } = this.state;
    const { t, modeModify } = this.props;
    return (
      <div className="col-md-12">
        <FormWithTableItem title={t('label.customAttributes')} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeModify === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.addNewAttribute}>
                +
                {t('label.addNewAttribute')}
              </button>
            )}
            {modeModify === 2 && (
              <button
                type="submit"
                className="button button-border x-small float-right ml-2"
                onClick={() => this.handleCustomAttributes()}
              >
                {isCreateAttributes ? t('label.createConfig') : t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetAttribute()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div className="table-responsive mt-15 ml-3 pr-4 form-focus table-form sticky-header">
            <table className="table center-aligned-table mb-0">
              {this.renderHeaderTable()}
              {this.renderContent()}
            </table>
          </div>
          <br />
        </FormWithTableItem>
      </div>
    );
  }
}

CustomerAttribute.propTypes = {
  getCustomAttributesByObjectTypeTenant: PropTypes.func.isRequired,
  createCustomAttributesTenant: PropTypes.func.isRequired,
  ModifyCustomAttributesTenant: PropTypes.func.isRequired,
};

const newCustomerAttribute = connect(null, {
  getCustomAttributesByObjectTypeTenant,
  ModifyCustomAttributesTenant,
  createCustomAttributesTenant,
})(CustomerAttribute);

export default withTranslation(['common', 'selections'])(newCustomerAttribute);
