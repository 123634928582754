import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchUom = `searchUom(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchUom} {
        id
        searchList {
          index
          unitId
          name
          description
          symbol
          startDate
          endDate
        }
      }
    }
  `;
};
