import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class AccountStatementSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.invoiceUnitId',
      name: 'invoiceId',
      // fa: 'fa fa-external-link',
    }, 
    {
      label: 'common:label.folioStatus',
      name: 'folioStatus',
      tOptions: 'selections:folioStatus',
      type: 'select',
      isClearable: true,
    }, 
  ];

  btnSpan = 'col-md-12';
}

AccountStatementSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountStatementSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(AccountStatementSearchForm);
