import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  data: {},
});

export default function(state = initialState, action) {
  switch (action.type) {
    // -------Search Roles
    case types.GET_USER_BY_ID_SUCCESS: {
      return state.set('data', action.payload);
    }

    case 'asd':
      return state.set('listRoles', action.payload);

    default:
      return state;
  }
}
