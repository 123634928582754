import gql from 'graphql-tag';

export default () => {
  const getLocations = 'getLocations(dummy: "")';
  return gql`
    query {
      ${getLocations} {
        id
        name
        description
        type
        address {
          index
          street
          extraLine
          landmark
          city
          state
          country
          postalCode
          code
        }
      }
    }
  `;
};
