import { groupNamePermissions } from './constant';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreatePaymentTermsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPaymentTermsConfig });
};

const checkPermissionModifyPaymentTermsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentTermsConfig });
};

const checkPermissionCreatePaymentMethodConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPaymentMethodConfig });
};

const checkPermissionModifyPaymentMethodConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentMethodConfig });
};

const checkPermissionCreatePaymentMerchantConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPaymentMerchantConfig });
};

const checkPermissionModifyPaymentMerchantConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentMerchantConfig });
};

const checkPermissionGetPaymentConfiguration = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaymentConfiguration });
};

const checkPermissionProcessPayment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processPayment });
};

const checkPermissionProcessPaymentReversal = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processPaymentReversal });
};

const checkPermissionAutoPaymentAllocation = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.autoPaymentAllocation });
};

const checkPermissionManualPaymentAllocation = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.manualPaymentAllocation });
};

const checkPermissionSearchPayment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchPayment });
};

const checkPermissionSearchPaymentReversals = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchPaymentReversals });
};

const checkPermissionSearchPaymentSuspense = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchPaymentSuspense });
};

const checkPermissionProcessPaymentSuspense = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.processPaymentSuspense });
};

const checkPermissionBatchPaymentUpload = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.batchPaymentUpload });
};

const checkPermissionBatchPaymentProcess = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.batchPaymentProcess });
};

const checkPermissionGetPaymentSurcharge = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaymentSurchargeConfig });
};

const checkPermissionModifyPaymentSurcharge = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentSurchargeConfig });
};

const checkPermissionCreatePaymentSurcharge = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPaymentSurchargeConfig });
};

const checkPermissionGetBatchPaymentFile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBatchPaymentFileConfig });
};

const checkPermissionModifyBatchPaymentFile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyBatchPaymentFileConfig });
};

const checkPermissionCreateBatchPaymentFile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createBatchPaymentFileConfig });
};

const checkPermissionGetPaymentItemMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaymentItemMap });
};

const checkPermissionModifyPaymentItemMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentItemMap });
};

const checkPermissionCreatePaymentItemMap = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPaymentItemMap });
};

const checkPermissionGetConfigPaymentAllocation = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_PAYMENT_ALLOCATION_CONFIG });
};

const checkPermissionModifyConfigPaymentAllocation = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_PAYMENT_ALLOCATION_CONFIG });
};

const checkPermissionCreateConfigPaymentAllocation = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_PAYMENT_ALLOCATION_CONFIG });
};

const checkPermissionRegeneratePaymentFolio = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.REGENERATE_PAYMENT_FOLIO });
};

const checkPermissiontViewARActivityDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_AR_ACTIVITY_DETAILS });
};

const checkPermissiontResendToVendor = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.RESEND_TO_VENDOR });
};

export {
  checkPermissionCreatePaymentTermsConfig,
  checkPermissionModifyPaymentTermsConfig,
  checkPermissionCreatePaymentMethodConfig,
  checkPermissionModifyPaymentMethodConfig,
  checkPermissionCreatePaymentMerchantConfig,
  checkPermissionModifyPaymentMerchantConfig,
  checkPermissionGetPaymentConfiguration,
  checkPermissionProcessPayment,
  checkPermissionProcessPaymentReversal,
  checkPermissionAutoPaymentAllocation,
  checkPermissionManualPaymentAllocation,
  checkPermissionSearchPayment,
  checkPermissionSearchPaymentReversals,
  checkPermissionSearchPaymentSuspense,
  checkPermissionProcessPaymentSuspense,
  checkPermissionBatchPaymentUpload,
  checkPermissionBatchPaymentProcess,
  checkPermissionGetPaymentSurcharge,
  checkPermissionModifyPaymentSurcharge,
  checkPermissionCreatePaymentSurcharge,
  checkPermissionGetBatchPaymentFile,
  checkPermissionModifyBatchPaymentFile,
  checkPermissionCreateBatchPaymentFile,
  checkPermissionGetPaymentItemMap,
  checkPermissionModifyPaymentItemMap,
  checkPermissionCreatePaymentItemMap,
  checkPermissionGetConfigPaymentAllocation,
  checkPermissionModifyConfigPaymentAllocation,
  checkPermissionCreateConfigPaymentAllocation,
  checkPermissionRegeneratePaymentFolio,
  checkPermissiontViewARActivityDetails,
  checkPermissiontResendToVendor,
};
