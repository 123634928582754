import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep, map, lte } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from 'utils/utils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CheckField, DataTable, TablePagination, CollapsibleTable, GenericInput } from '..';

class ModalMultipleSelectorWithTableCollapsible extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: 21,
    isSearching: false,
    listItems: [],
    selectedParentId: [],
    selectedChildrenId: [],
    rowItem: null,
    initSelectedParentId: null,
    activeSubTab: {},
    activeChildSubTab: {},
  };

  static getDerivedStateFromProps(props, state) {
    if (state.initSelectedParentId === props.selectedParentId) return null;
    return {
      selectedParentId: props.selectedParentId || [],
      initSelectedParentId: props.selectedParentId,
      isMultiple: props.isMultiple,
      rowItem: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isNeedFetch, isOpen } = this.props;
    if ((isNeedFetch && isNeedFetch !== prevProps.isNeedFetch) || (isOpen && prevProps.isOpen !== isOpen))
      this.doSearchItems();
  }

  onSelectParent = e => {
    e.preventDefault();
    const { onSelect, childrenField, onSelectChild } = this.props;
    const { selectedParentId, selectedChildrenId } = this.state;
    if (selectedChildrenId && childrenField) onSelectChild(selectedChildrenId, this.state.rowItem, childrenField);
    if (onSelect) {
      this.setState({ filter: {} });
      onSelect(selectedParentId, this.state.rowItem);

      this.setState({ filter: {}, selectedParentId: [], rowItem: null });
    }
  };

  onCancel = e => {
    e.preventDefault();
    const { onCancel } = this.props;
    if (onCancel) {
      this.setState({ filter: {} });
      onCancel(false);
    }
  };

  onUnselectParent = e => {
    e.preventDefault();
    const { onSelect, isRequired } = this.props;
    if (isRequired) return false;
    this.setState({ selectedParentId: [] });
    if (onSelect) {
      this.setState({ filter: {} });
      onSelect(null);
    }
  };

  onClickRow = (e, row) => {
    e.preventDefault();
    const { fieldDisableCheck } = this.props;
    let isDisable = false;
    if (fieldDisableCheck) {
      map(fieldDisableCheck, (value, key) => {
        if (row[key] === value) isDisable = true;
      });
    }
    if (isDisable) return '';
    const { selectedParentId, selectedChildrenId, rowItem } = this.state;
    const { selectFieldName, childrenField, selectChildFieldName } = this.props;
    let newRowItem = cloneDeep(rowItem) || [];

    let newSelectedParentId = cloneDeep(selectedParentId);
    let newSelectedChildrenId = cloneDeep(selectedChildrenId);
    if (childrenField && newSelectedChildrenId && newSelectedChildrenId.indexOf(row[selectChildFieldName]) > -1) {
      newSelectedChildrenId = newSelectedChildrenId.filter(val => val !== row[selectChildFieldName]);
    }
    if (childrenField && newSelectedChildrenId && newSelectedChildrenId.indexOf(row[selectChildFieldName]) === -1) {
      newSelectedChildrenId = [...newSelectedChildrenId, row[selectChildFieldName]];
    }
    if (newSelectedParentId && newSelectedParentId.indexOf(row[selectFieldName]) > -1) {
      newSelectedParentId = newSelectedParentId.filter(val => val !== row[selectFieldName]);
      newRowItem = newRowItem.filter(val => val[selectFieldName] !== row[selectFieldName]);
      return this.setState({
        selectedParentId: newSelectedParentId,
        rowItem: newRowItem,
        selectedChildrenId: [...newSelectedChildrenId],
      });
    }
    newSelectedParentId = [...newSelectedParentId, row[selectFieldName]];
    return this.setState({
      selectedParentId: [...newSelectedParentId],
      rowItem: [...newRowItem, row],
      selectedChildrenId: [...newSelectedChildrenId],
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sort, filter = {}, totalCount, sorted } = this.state;
    this.setState({ isSearching: true });
    const { arraySort, defaultSearchParams, addFirstParamsField } = this.props;
    let filteredParams = { ...filter };
    if (defaultSearchParams) {
      filteredParams = { ...filteredParams, ...defaultSearchParams };
    }
    if (addFirstParamsField && !filteredParams[addFirstParamsField.name]) {
      filteredParams[addFirstParamsField.name] = addFirstParamsField.value;
    }
    this.props.searchItems(
      {
        page: page + 1,
        size,
        filter: filteredParams,
        sort: !isEmpty(sorted) && !isEmpty(arraySort) ? arraySort[sorted.sortCol][sorted.sortDir] : null,
      },
      resp => {
        const listItems = resp.success ? resp.data : [];
        this.setState({
          isSearching: false,
          filter,
          sort,
          listItems,
          totalCount: getPageTotalCount({ page, size, totalCount, items: listItems }),
        });
      }
    );
  };

  onHandleSelectAll = isChecked => {
    if (!isChecked) {
      return this.setState({ selectedParentId: [], selectedChildrenId: [], rowItem: null });
    }
    const { listItems } = this.state;
    let { selectedParentId, selectedChildrenId, rowItem } = this.state;
    const { fieldDisableCheck, selectFieldName } = this.props;
    // let selectedParentId = [];
    // let selectedChildrenId = [];
    // let rowItem = [];
    if (listItems && listItems.length) {
      listItems.forEach(item => {
        let isDisable = false;
        if (fieldDisableCheck) {
          map(fieldDisableCheck, (value, key) => {
            if (item[key] === value) isDisable = true;
          });
        }
        if (!isDisable) {
          selectedParentId = [...selectedParentId, item[selectFieldName]];
          selectedChildrenId = [...selectedChildrenId, item[selectFieldName]];
          rowItem = rowItem ? [...rowItem, item] : [item];
        }
        this.setState({ selectedParentId, selectedChildrenId, rowItem });
      });
    }
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem) this.setState({ activeSubTab: {} });
    if (activeSubTab.index !== indexItem) {
      this.setState({ activeSubTab: { key, index: indexItem } });
    }
  };

  onToggleChildSubTab = (index, item, indexItem, key) => {
    const { activeChildSubTab } = this.state;
    if (activeChildSubTab.index === indexItem) this.setState({ activeChildSubTab: {} });
    if (activeChildSubTab.index !== indexItem) {
      this.setState({ activeChildSubTab: { key, index: indexItem } });
    }
  };

  onChangeTable = ({ index, name, value }) => {
    try {
      const { listItems, activeSubTab } = this.state;
      const newData = cloneDeep(listItems);
      newData[activeSubTab.index].attributesList[index][name] = value;
      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { listItems, sorted, activeSubTab, activeChildSubTab } = this.state;
    const {
      tableColumns,
      modalTitle,
      isNoNeedSelector,
      SearchForm,
      isRequired,
      toggleModal,
      t,
      isNotRenderPagination,
      fieldDisableCheck,
      isSupportSelectAll,
      defaultFilter,
      isMultipleOptionInject,
      optionsInject,
      isNoAttributes,
    } = this.props;

    const viewLovColumn = [];
    if (!isNoAttributes) {
      viewLovColumn.push({
        name: 'viewLov ',
        label: 'label.attributes',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleSubTab(idx, item, idx, 'addAttributes')}
              // disabled={!item.locations || !item.locations.length}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'addAttributes' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          );
        },
      });
    }

    const columns = [
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item) => {
          let isDisable = false;
          if (fieldDisableCheck) {
            map(fieldDisableCheck, (value, key) => {
              if (item[key] === value) isDisable = true;
            });
          }
          return (
            <CheckField
              name="selectedParentId"
              value={item[this.props.selectFieldName]}
              checked={
                item[this.props.selectFieldName] &&
                this.state.selectedParentId &&
                this.state.selectedParentId.indexOf(item[this.props.selectFieldName]) !== -1
              }
              disabled={isDisable}
              readOnly
              onClick={e => this.onClickRow(e, item)}
            />
          );
        },
      },
      ...viewLovColumn,
      ...tableColumns,
    ];
    if (isNoNeedSelector) columns.splice(0, 1);
    const tableConfig = {
      columns,
      data: listItems,
      getRowClass: item => {
        return item[this.props.selectFieldName] === this.state.selectedParentId ? 'selected-row' : '';
      },
      sorted,
    };

    const headerTableAttributesList = [
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'value',
        label: 'label.value',
        render: (name, item, idx) => {
          return (
            <GenericInput
              value={item.value}
              name="value"
              onChange={({ name, value }) => this.onChangeTable({ index: idx, name, value })}
              wrapperClass="col-md-12"
            />
          );
        },
      },
      {
        name: 'viewLov ',
        label: 'label.viewLov',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleChildSubTab(idx, item, idx, 'viewLov')}
              // disabled={!item.locations || !item.locations.length}
            >
              <i
                className={`fa ${
                  activeChildSubTab && activeChildSubTab.key === 'viewLov' && activeChildSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          );
        },
      },
    ];

    const headerTableViewLov = [
      {
        name: 'code',
        label: 'label.code',
      },
      {
        name: 'description',
        label: 'label.description',
      },
    ];

    let isSelectAll = false;

    if (listItems && listItems.length && isSupportSelectAll) {
      isSelectAll = true;
      listItems.forEach(item => {
        let isDisable = false;
        if (fieldDisableCheck) {
          map(fieldDisableCheck, (value, key) => {
            if (item[key] === value) isDisable = true;
          });
        }
        if (
          !isDisable &&
          item[this.props.selectFieldName] &&
          this.state.selectedParentId &&
          this.state.selectedParentId.indexOf(item[this.props.selectFieldName]) === -1
        ) {
          isSelectAll = false;
        }
      });
    }

    return (
      <Modal
        size="lg"
        className="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={this.props.isOpen}
        toggle={toggleModal}
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          {SearchForm && (
            <div className="col-md-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <div className="repeater-file">
                    <div data-repeater-list="group-a">
                      <SearchForm
                        onSubmit={this.onSubmitSearch}
                        isSubmitting={this.state.isSearching}
                        defaultFilter={defaultFilter || null}
                        isMultipleOptionInject={isMultipleOptionInject}
                        optionsInject={optionsInject}
                        defaultFilterSeted={defaultFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              {isSupportSelectAll && (
                <CheckField
                  name="selectAll"
                  id="selectAll"
                  wrapperClass="col-md-4 mt-4 mb-2"
                  value={isSelectAll}
                  checked={isSelectAll}
                  label={t('label.selectAll')}
                  onChange={evt => this.onHandleSelectAll(evt.target.checked)}
                />
              )}
              <div className="card-body">
                <div className="group-collapsible">
                  <CollapsibleTable
                    {...tableConfig}
                    isSupportRemoveIndex
                    indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
                    // onClickRow={this.onClickRow}
                    nullIsLoading={false}
                    isLoading={this.state.isSearching}
                    onSort={this.onSortColumn}
                    tableClass="card-table-body"
                    isViewOnly
                    isNoNeedFixedHeader
                  >
                    <CollapsibleTable
                      columns={headerTableAttributesList}
                      indexViewer={activeChildSubTab && activeChildSubTab.index !== -1 ? activeChildSubTab.index : -1}
                      isNoNeedFixedHeader
                      data={
                        listItems &&
                        listItems.length &&
                        activeSubTab &&
                        activeSubTab.index !== -1 &&
                        listItems[activeSubTab.index]
                          ? listItems[activeSubTab.index].attributesList || []
                          : []
                      }
                    >
                      <DataTable
                        columns={headerTableViewLov}
                        isNoNeedFixedHeader
                        data={
                          listItems &&
                          listItems.length &&
                          activeSubTab &&
                          activeSubTab.index !== -1 &&
                          activeChildSubTab &&
                          activeChildSubTab.index !== -1 &&
                          listItems[activeSubTab.index] &&
                          listItems[activeSubTab.index].attributesList &&
                          listItems[activeSubTab.index]?.attributesList[activeChildSubTab.index]?.attributesLovs
                            ? listItems[activeSubTab.index]?.attributesList[activeChildSubTab.index]?.attributesLovs ||
                              []
                            : []
                        }
                      />
                    </CollapsibleTable>
                  </CollapsibleTable>
                </div>
              </div>
            </div>
          </div>
          {!isNotRenderPagination && (
            <div className="mb-30">
              <TablePagination
                pageNumber={this.state.page}
                pageSize={this.state.size}
                totalCount={this.state.totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
          )}
          {!isNoNeedSelector && (
            <ModalFooter className="text-right">
              <button type="button" className="btn btn-secondary" onClick={this.onCancel}>
                {t('label.cancel')}
              </button>
              {!isRequired && (
                <button type="button" className="btn btn-primary" onClick={this.onUnselectParent}>
                  {t('label.unselect')}
                </button>
              )}

              <button type="button" className="btn btn-success" onClick={this.onSelectParent}>
                {t('label.select')}
              </button>
            </ModalFooter>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ModalMultipleSelectorWithTableCollapsible.propTypes = {
  selectFieldName: PropTypes.string,
  isOpen: PropTypes.bool,
  isRequired: PropTypes.bool,
  isMultiple: PropTypes.bool,
  selectedParentId: PropTypes.any,
  tableColumns: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
  SearchForm: PropTypes.any,
  defaultSearchParams: PropTypes.any,
};
ModalMultipleSelectorWithTableCollapsible.defaultProps = {
  selectFieldName: 'id',
  isRequired: true,
  isMultiple: false,
};

export default withTranslation('common')(ModalMultipleSelectorWithTableCollapsible);
