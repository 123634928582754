import React from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalViewNote = ({ isOpen, message, onToggle, wrapperClass, mainClass }) => {
  const { t } = useTranslation('common');
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={mainClass || ''}>
      <ModalHeader>{t('label.quickNotes')}</ModalHeader>
      <ModalBody className={wrapperClass || ''}>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onToggle}>
          {t('label.ok')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalViewNote.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  message: PropsType.string,
  wrapperClass: PropsType.string,
  mainClass: PropsType.string,
};

ModalViewNote.defaultProps = {
  wrapperClass: '',
  message: '',
  mainClass: '',
};

export default ModalViewNote;
