import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GenericInput, ModalWithItem } from '../../../components/common';
import ExpressionList from './ExpressionList';

const operatorOptions = [
  { key: '+', value: '+' },
  { key: '-', value: '-' },
  { key: '*', value: '*' },
  { key: '/', value: '/' },
  { key: '(', value: '(' },
  { key: ')', value: ')' },
  { key: 'min', value: 'min ( DETAIL.upload , DETAIL.download )' },
  { key: 'max', value: 'max ( DETAIL.upload , DETAIL.download )' },
  { key: 'avg', value: 'avg ( DETAIL.upload , DETAIL.download )' },
];

const ModalExpression = ({ onToggle, isOpenModalExpression, onApplyExpression, data, options }) => {
  const { t } = useTranslation('common');
  const [isEditExp, setIsEditExp] = useState(true);
  const [operator, setOperator] = useState('');
  const [expressionAddNew, setExpressionAddNew] = useState('');
  const [selectFieldExp, setSelectFieldExp] = useState(null);

  const onAddOperator = () => {
    const newExpressionAddNew = `${expressionAddNew} ${operator} `;
    setExpressionAddNew(newExpressionAddNew);
  };

  const onAddFieldExp = () => {
    const newExpressionAddNew = `${expressionAddNew}${selectFieldExp}`;
    setExpressionAddNew(newExpressionAddNew);
  };

  const onChangeExp = ({ value }) => {
    let newData = expressionAddNew;
    newData = value;
    setExpressionAddNew(newData);
  };

  useEffect(() => {
    setExpressionAddNew(data);
    setSelectFieldExp(null);
    setOperator('');
  }, [data]);

  return (
    // <ModalWithItem modalTitle={t('label.expression')} isOpen={isOpenModalExpression} onToggle={onToggle}>
    <ModalWithItem modalTitle={data || options[0].label} isOpen={isOpenModalExpression} onToggle={onToggle}>
      <div className="col-md-12 m-2 expression-container p-3 row">
        <div className="col-md-11 row">
          {expressionAddNew && expressionAddNew.length < 1 && !isEditExp && (
            <span className="placeholder-expression">{`( <${t('label.addField')}> )`}</span>
          )}
          {((expressionAddNew && expressionAddNew.length > 0) || isEditExp) && (
            <ExpressionList
              data={expressionAddNew}
              isEditExp={isEditExp}
              onChangeExp={onChangeExp}
              operator={operator}
            />
          )}
        </div>
        <div className="col-md-1 no-padding">
          {isEditExp ? (
            <button
              type="button"
              className="btn-with-ico float-right"
              onClick={() => {
                setIsEditExp(false);
              }}
            >
              <i className="fa fa-check-square-o icon-check" aria-hidden="true" />
            </button>
          ) : (
            <button
              type="button"
              className="btn-with-ico float-right"
              onClick={() => {
                setIsEditExp(true);
              }}
            >
              <i className="fa fa-pencil-square-o icon-edit" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
      <div className="col-md-12 mb-2 mt-4 row ml-4">
        <span className="title-select-operator">{`${t('label.selectOperator')}:`}</span>
        {operatorOptions.map(item => (
          <button
            key={item.key}
            type="button"
            className={`m-1 btn-operator ${operator === item.value ? 'active' : ''}`}
            onClick={() => {
              setOperator(item.value);
            }}
          >
            {item.key}
          </button>
        ))}
        <button
          type="button"
          disabled={!operator}
          className="button button-border black x-small btn-add-operator"
          onClick={onAddOperator}
        >
          {`+ ${t('label.addOperator')}`}
        </button>
      </div>
      <div className="col-md-12 mb-2 mt-4 ml-5 row">
        <GenericInput
          value={selectFieldExp}
          label="label.selectField"
          type="select"
          required
          options={options}
          wrapperClass="col-md-4"
          onChange={({ value }) => setSelectFieldExp(value)}
          name="selectFieldExp"
        />
        <div className="form-group action-buttons mt-4">
          <button
            type="button"
            disabled={!selectFieldExp}
            className="button button-border black x-small"
            onClick={onAddFieldExp}
          >
            {`+ ${t('label.addField')}`}
          </button>
        </div>
      </div>
      <div className="form-group col-md-12 action-buttons modal-new-item">
        <button
          type="button"
          className="button x-small float-right"
          onClick={() => onApplyExpression({ data: expressionAddNew })}
        >
          {t('label.apply')}
        </button>
        <button type="button" className="button button-border black x-small float-right" onClick={onToggle}>
          {t('label.cancel')}
        </button>
      </div>
    </ModalWithItem>
  );
};

ModalExpression.propTypes = {
  onToggle: PropTypes.func,
  onApplyExpression: PropTypes.func,
  data: PropTypes.string,
  isOpenModalExpression: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
};

ModalExpression.defaultProps = {
  onToggle: () => {},
  onApplyExpression: () => {},
  isOpenModalExpression: false,
  data: '',
  options: [],
};

export default connect(null, {})(ModalExpression);
