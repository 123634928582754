import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['reProcessRecs']);
  let newData = dataModify.replace('{embrixData: ', '');
  newData = `${newData.substring(0, newData.length - 1)}`;
  const initiateReratingProcess = `initiateReratingProcess(input: ${newData})`;
  return gql`
  mutation {
    ${initiateReratingProcess}
  }
`;
};
