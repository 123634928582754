import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeGetPaymentMethodUrls } from '../../selectors';
import { modifyPaymentMethodUrls, getPaymentMethodUrls } from '../../actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { GenericInput, CollapsibleTable, FormCollapse } from '../../../../components/common';
import { getIndexData } from '../../../../utils/utils';

const headerTable = [
  {
    name: 'paymentMethod ',
    label: 'label.paymentMethod',
    render: (colName, item) => (
      <GenericInput
        value={item.paymentMethod}
        wrapperClass="col-md-12"
        onChange={() => {}}
        name="gatewayName"
        readOnly
      />
    ),
  },
  {
    name: 'providerName',
    label: 'label.providerName',
    render: (colName, item) => (
      <GenericInput
        value={item.providerName}
        wrapperClass="col-md-12"
        onChange={() => {}}
        name="providerName"
        readOnly
      />
    ),
  },
  {
    name: '',
    label: '',
    style: { width: 20 },
    render: (colName, item, idx, indexParent, activeTab) => (
      <button type="button" className="btn-expand-table mr-3">
        <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
      </button>
    ),
  },
];

class URLMappingPaymentGeteway extends React.PureComponent {
  state = { methodData: [], activeTab: -1, indexSelect: -1 };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.paymentMethodUrls) return null;
    return {
      methodData: props.paymentMethodUrls ? props.paymentMethodUrls : [],
      oldValue: props.paymentMethodUrls,
    };
  }

  componentDidMount() {
    this.doGetMethod();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ methodData: nextProps.data });
    }
  }

  doGetMethod = isCancel => {
    const { getPaymentMethodUrls, modeGetPaymentMethodUrl } = this.props;
    if (isCancel) this.setState({ indexSelect: -1 });
    if (modeGetPaymentMethodUrl)
      getPaymentMethodUrls('', ({ success, data }) => {
        if (success && data?.paymentMethods) {
          this.onToggleTab({
            index: 0,
            item: data?.paymentMethods ? data.paymentMethods[0] : null,
            indexItem: 0,
          });
        }
      });
  };

  onChangePaymentMethods = ({ value, name, index }) => {
    try {
      const { methodData } = this.state;
      const newData = cloneDeep(methodData);
      newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })][name] = value;
      this.setState({ methodData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangePaymentUrls = ({ value, name, index, indexUrl }) => {
    try {
      const { methodData } = this.state;
      const newData = cloneDeep(methodData);
      newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })].paymentUrls[indexUrl][name] = value;
      this.setState({ methodData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { modifyPaymentMethodUrls } = this.props;
    const { methodData } = this.state;
    const { id, ...rest } = methodData;
    if (rest.paymentMethods) {
      rest.paymentMethods = rest.paymentMethods.map(val => {
        const { isNew, ...dt } = val;
        return { ...dt };
      });
    }
    // const newPayload =
    modifyPaymentMethodUrls({ ...rest });
  };

  // onToggleTab = index => {
  //   const { activeTab } = this.state;
  //   if (activeTab === index) this.setState({ activeTab: -1 });
  //   if (activeTab !== index) this.setState({ activeTab: index });
  // };

  onAddNewMethod = () => {
    try {
      const { methodData } = this.state;
      const { merchantName } = this.props;
      const newData = cloneDeep(methodData);
      let index = 0;
      newData.paymentMethods.forEach(val => {
        if (index < val.index) index = val.index;
      });
      const dataNew = {
        index: index + 1,
        paymentMethod: 'CHECK',
        providerName: merchantName || null,
        isNew: true,
        paymentUrls: [
          {
            index: 1,
            txnType: 'PAYMENT',
            endpointUrl: null,
          },
        ],
      };
      newData.paymentMethods.push(dataNew);
      this.setState({ methodData: newData });
      this.onToggleTab({
        index: newData.paymentMethods.length - 1,
        item: dataNew,
        indexItem: newData.paymentMethods.length - 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  addNewEndPointMapping = ({ index }) => {
    try {
      const { methodData } = this.state;
      const newData = cloneDeep(methodData);
      let indexNextItem = 0;
      newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })].paymentUrls.forEach(val => {
        if (indexNextItem < val.index) indexNextItem = val.index;
      });
      const dataNew = {
        index: indexNextItem + 1,
        txnType: 'PAYMENT',
        endpointUrl: '',
      };
      newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })].paymentUrls.push(dataNew);
      this.setState({ methodData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleRemovePaymentMothods = index => {
    try {
      const { methodData } = this.state;
      const newData = cloneDeep(methodData);
      if (newData && newData[index] && newData[index].isNew) {
        newData.splice(index, 1);
        return this.setState({ methodData: newData });
      }
      newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })] = {
        index: newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })].index,
      };
      return this.setState({ methodData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleRemoveURL = ({ index, idx }) => {
    try {
      const { methodData } = this.state;
      const newData = cloneDeep(methodData);
      newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })].paymentUrls[idx] = {
        index: newData.paymentMethods[getIndexData({ data: newData.paymentMethods, index })].paymentUrls[idx].index,
      };
      this.setState({ methodData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleTab = ({ index, item, indexItem }) => {
    const { activeTab } = this.state;
    if (activeTab === indexItem) this.setState({ activeTab: -1 });
    if (activeTab !== indexItem) {
      if (item) this.setState({ indexSelect: item.index });
      this.setState({ activeTab: indexItem });
    }
  };

  render() {
    const { methodData, activeTab, indexSelect } = this.state;
    const {
      modeModifyPaymentGatewayMerchant,
      isRequireField,
      t,
      modeCreatePaymentMethodUrl,
      modeModifyPaymentMethodUrl,
      modeGetPaymentMethodUrl,
    } = this.props;
    if (modeGetPaymentMethodUrl === 0) return '';
    const dataSelect =
      indexSelect !== -1 && methodData.paymentMethods
        ? methodData.paymentMethods.find(val => val.index === indexSelect)
        : {};
    return (
      <form className="col-md-12 card card-statistics" onSubmit={this.onSubmit}>
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.paymentGatewayURLMapping')} />
          </div>
        </div>
        {methodData &&
          methodData.paymentMethods &&
          methodData &&
          methodData.paymentMethods.map((val, index) => {
            if (size(val) < 2) return null;
            let title = '';
            if (val.paymentMethod) {
              const slt = t ? t('selections:paymentMethod')().find(dt => dt.value === val.paymentMethod) : '';
              title = `${title} ${slt ? slt.label : ''}`;
            }
            // if (val.providerName) {
            //   const slt = t ? t('selections:merchantName')().find(dt => dt.value === val.providerName) : '';
            //   title = `${title} ${slt ? `- ${slt.label}` : ''}`;
            // }
            return (
              <FormCollapse
                key={index}
                isActive={index === activeTab}
                isActiveSubForm={index === activeTab}
                isSubCollapse
                title={title || 'null'}
                state={index}
                onToggleTab={() => this.onToggleTab({ index, item: val, indexItem: index })}
              >
                <div className="col-md-12 row p-3 justify-content-center">
                  <GenericInput
                    label="label.paymentMethod"
                    type="select"
                    value={dataSelect ? dataSelect.paymentMethod : null}
                    wrapperClass="col-md-4"
                    required={isRequireField}
                    tOptions="selections:paymentMethod"
                    disabled={modeModifyPaymentGatewayMerchant === 1}
                    onChange={({ name, value }) =>
                      this.onChangePaymentMethods({ value, name, index: dataSelect.index })
                    }
                    name="paymentMethod"
                    menuPortalTarget
                  />
                  {/* <GenericInput
              label="label.providerName"
              value={dataSelect ? dataSelect.providerName : ''}
              type="select"
              required={isRequireField}
              disabled={modeModifyPaymentGatewayMerchant === 1}
              onChange={({ name, value }) => this.onChangePaymentMethods({ value, name, index: dataSelect.index })}
              name="providerName"
              tOptions="selections:merchantName"
              wrapperClass="col-md-4"
              menuPortalTarget
            /> */}
                  <button
                    type="button"
                    className="btn-delete"
                    onClick={() => this.onHandleRemovePaymentMothods(dataSelect.index)}
                  >
                    <i className="fa fa-trash mt-4" />
                  </button>
                </div>
                <div>
                  {dataSelect &&
                    dataSelect.paymentUrls &&
                    dataSelect.paymentUrls
                      // .filter(i => size(i) > 2)
                      .map((payment, idx) => {
                        if (size(payment) < 2) return '';
                        return (
                          <div className="col-md-12 row justify-content-center" key={`key-${idx}`}>
                            <div className="col-md-11 justify-content-center row">
                              <GenericInput
                                label="label.transactionType"
                                type="select"
                                value={payment.txnType || null}
                                wrapperClass="col-md-4"
                                required={isRequireField}
                                tOptions="selections:txnTypeTenant"
                                disabled={modeModifyPaymentGatewayMerchant === 1}
                                onChange={({ name, value }) =>
                                  this.onChangePaymentUrls({ value, name, index: dataSelect.index, indexUrl: idx })
                                }
                                name="txnType"
                                menuPortalTarget
                              />
                              <GenericInput
                                label="label.endPointURL"
                                value={payment.endpointUrl}
                                type="text"
                                required={isRequireField}
                                disabled={modeModifyPaymentGatewayMerchant === 1}
                                onChange={({ name, value }) =>
                                  this.onChangePaymentUrls({ value, name, index: dataSelect.index, indexUrl: idx })
                                }
                                name="endpointUrl"
                                wrapperClass="col-md-4"
                              />
                              <button
                                type="button"
                                className="btn-delete"
                                onClick={() => this.onHandleRemoveURL({ index: dataSelect.index, idx })}
                              >
                                <i className="fa fa-trash mt-4" />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  <div className="col-md-12 text-center mt-3 mb-3">
                    <button
                      type="button"
                      className="button button-border gray x-small"
                      onClick={() => this.addNewEndPointMapping({ index: dataSelect.index })}
                    >
                      {t('label.addNewEndPointMapping')}
                    </button>
                  </div>
                </div>
              </FormCollapse>
            );
          })}

        {modeCreatePaymentMethodUrl === 2 && (
          <div className="col-md-12 text-center mt-4">
            <button type="button" className="button button-border gray x-small" onClick={this.onAddNewMethod}>
              {t('label.addNewPaymentMethodMapping')}
            </button>
          </div>
        )}
        <div className="mt-3 mb-3 col-md-12">
          {modeModifyPaymentMethodUrl === 2 && (
            <button type="submit" className="button button-border ml-2 x-small float-right">
              {t('label.saveConfig')}
            </button>
          )}
          <button type="button" onClick={this.doGetMethod} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </form>
    );
  }
}

URLMappingPaymentGeteway.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  getPaymentMethodUrls: PropTypes.func.isRequired,
  modifyPaymentMethodUrls: PropTypes.func.isRequired,
  // index: PropTypes.number.isRequired,
  modeModifyPaymentGatewayMerchant: PropTypes.number,
  modeGetPaymentMethodUrl: PropTypes.number,
  modeCreatePaymentMethodUrl: PropTypes.number,
  modeModifyPaymentMethodUrl: PropTypes.number,
  isRequireField: PropTypes.bool,
  t: PropTypes.func,
};

URLMappingPaymentGeteway.defaultProps = {
  data: [],
  // onHandleChange: () => {},
  modeModifyPaymentGatewayMerchant: 2,
  isRequireField: false,
  modeGetPaymentMethodUrl: 0,
  modeCreatePaymentMethodUrl: 0,
  modeModifyPaymentMethodUrl: 0,
  t: () => {},
};

// export default withTranslation('common')(URLMappingPaymentGeteway);

const mapStateToProps = createStructuredSelector({
  paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { modifyPaymentMethodUrls, getPaymentMethodUrls })(URLMappingPaymentGeteway)
);
