import React, { useEffect, useState, useCallback } from 'react';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { createStructuredSelector } from 'reselect';
import XMLViewer from 'react-xml-viewer';
import { getBase64, splitF } from '../../../utils/utils';
import { readFileFromS3 } from '../../../containers/App/actions';
import { makeGetS3Config } from '../../../containers/App/selectors';

let fileSelect = null;

const ModalViewSingleFile = ({ readFileFromS3, pathFile, isOpen, onToggle, title, s3Config }) => {
  const { t } = useTranslation('common');
  const [base64FilePdf, setBase64FileHtml] = useState(null);
  const [isXML, setIsXML] = useState(false);
  const [xmlData, setXmlData] = useState(null);

  const doGetFileFromS3 = useCallback(() => {
    if (isOpen) {
      readFileFromS3({ ...s3Config, url: pathFile || null }, ({ success, data }) => {
        if (success) {
          fileSelect = data;
          if (data && data.type.indexOf('xml') !== -1) {
            setIsXML(true);
            const reader = new FileReader();
            reader.onload = () => {
              setXmlData(reader.result);
            };
            reader.readAsText(data);
          }
          getBase64(data, result => {
            setBase64FileHtml(result);
          });
        }
      });
    }
  }, [isOpen, pathFile, readFileFromS3, s3Config]);

  const downloadFile = (theBlob, fileName) => {
    saveAs(theBlob, fileName);
  };

  useEffect(() => {
    doGetFileFromS3();
  }, [doGetFileFromS3]);

  const onToggleModal = () => {
    setIsXML(false);
    onToggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggleModal} className="modal-lg info-form">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="col-md-12 d-flex">
          <div>
            <button type="button" className="button x-small ml-4" onClick={onToggleModal}>
              {t('label.back')}
            </button>
          </div>
          <div className="group-btn-invoice text-right mr-3" style={{ width: '96%' }}>
            <button type="button" className="button x-small" onClick={() => downloadFile(fileSelect, splitF(pathFile))}>
              {t('label.download')}
            </button>
          </div>
        </div>
        {!isXML && (
          <iframe
            type="application/pdf"
            src={base64FilePdf}
            title="report_view"
            style={{ width: '95%', minHeight: '740px', marginLeft: '20px' }}
          />
        )}
        {isXML && (
          <div
            className="xml-viewer"
            style={{ width: '95%', minHeight: '740px', marginLeft: '20px', overflow: 'auto' }}
          >
            <XMLViewer xml={xmlData} />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

ModalViewSingleFile.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
};

ModalViewSingleFile.defaultProps = {
  title: '',
};

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
});

export default connect(mapStateToProps, { readFileFromS3 })(ModalViewSingleFile);
