import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideBar from '../../components/common/SideBar';
import RouteName from '../App/RouteNames';
import {
  checkPermissionSearchAccountsInCollection,
  checkPermissionSearchCollectionAgentActivity,
  checkPermissionSearchCollectionUnit,
  checkPermissionSearchCollectionProfile,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends PureComponent {
  render() {
    const { isExpand = true, permissionsCollections } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsCollections, listKeys: permissionList.collections }))
      return null;

    let modeSearchAccountsInCollection = 0;
    let modeSearchCollectionAgentActivity = 0;
    let modeSearchCollectionUnit = 0;
    let modeGetCollectionProfile = 0;

    const childRoute = [];

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeSearchAccountsInCollection = checkPermissionSearchAccountsInCollection({ listPermission });
      modeSearchCollectionAgentActivity = checkPermissionSearchCollectionAgentActivity({ listPermission });
      modeSearchCollectionUnit = checkPermissionSearchCollectionUnit({ listPermission });
      modeGetCollectionProfile = checkPermissionSearchCollectionProfile({ listPermission });
    }

    if (modeGetCollectionProfile) {
      childRoute.push({
        icon: 'fa fa-list-alt',
        label: 'common:label.profile',
        href: RouteName.collectionProfile.path,
      });
    }

    const sidePropDetails = {
      isExpand: false,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'collectionPage:sidebar.collectionConfig',
          isNoExpand: true,
          childs: [
            ...childRoute,
            {
              icon: 'ti-mouse',
              label: 'common:label.actions',
              href: RouteName.collectionActionsConfig.path,
            },
            {
              icon: 'ti-calendar',
              label: 'common:label.schedule',
              href: RouteName.collectionSchedule.path,
            },
            {
              icon: 'ti-package',
              label: 'common:label.agents',
              href: RouteName.collectionAgents.path,
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'collectionPage:sidebar.collectionAdmin',
          isNoExpand: true,
          childs: [
            // {
            //   icon: 'ti-unlock',
            //   label: 'collectionPage:sidebar.collectionConfig',
            //   href: RouteName.collectionsConfig.path,
            // },
            {
              icon: 'ti-panel',
              label: 'common:label.actions',
              href: RouteName.collectionsAccounts.path,
            },
            {
              icon: 'ti-user',
              label: 'collectionPage:sidebar.agentActivity',
              href: RouteName.collectionsActivitySummary.path,
            },
            // {
            //   icon: 'ti-menu-alt',
            //   label: 'collectionPage:sidebar.schedulePatternConfig',
            //   href: RouteName.schedulePatternConfig.path,
            // },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'collectionPage:sidebar.collectionAgents',
          childs: [
            {
              icon: 'ti-unlock',
              label: 'collectionPage:sidebar.accountsInCollection',
              href: RouteName.collectionsSearchAccount.path,
            },
            // {
            //   icon: 'ti-panel',
            //   label: 'collectionPage:sidebar.invoiceUnits',
            //   href: RouteName.collectionsInvoiceUnits.path,
            //   disable: true,
            // },
            {
              icon: 'ti-user',
              label: 'collectionPage:sidebar.collectionAging',
              href: RouteName.collectionsHistory.path,
            },
          ],
        },
      ],
    };

    const newSideProps = cloneDeep(sidePropDetails);
    newSideProps.isExpand = isExpand;
    const indexPaymentAdminSideProps = newSideProps.items.findIndex(child => child.mainTilte === 'Collection Admin');
    const indexPaymentAgentSideProps = newSideProps.items.findIndex(child => child.mainTilte === 'Collection Agent');

    if (modeSearchAccountsInCollection === 0 && indexPaymentAdminSideProps > -1) {
      newSideProps.items[indexPaymentAdminSideProps].childs = newSideProps.items[
        indexPaymentAdminSideProps
      ].childs.filter(item => item.label !== 'collectionPage:sidebar.searchAccounts');
    }

    if (modeSearchCollectionAgentActivity === 0 && indexPaymentAdminSideProps > -1) {
      newSideProps.items[indexPaymentAdminSideProps].childs = newSideProps.items[
        indexPaymentAdminSideProps
      ].childs.filter(item => item.label !== 'collectionPage:sidebar.agentActivity');
    }

    if (modeSearchCollectionUnit === 0 && indexPaymentAgentSideProps > -1) {
      newSideProps.items[indexPaymentAgentSideProps].childs = newSideProps.items[
        indexPaymentAgentSideProps
      ].childs.filter(item => item.label !== 'collectionPage:sidebar.collectionHistory');
    }

    return <SideBar {...newSideProps} isExpandChild />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsCollections = store.get('auth').toJS().permissionsApp.arHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsCollections: permissionsCollections.length
      ? permissionsCollections.filter(a => a.moduleName === 'COLLECTION')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  sideProps: {},
  permissionsCollections: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
