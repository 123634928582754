import gql from 'graphql-tag';

export default id => gql`
  query {
    getSpecialPriceByAccountId(input: { accountId: "${id}" }) {
      id
      name
      description
      accountId
      specialPriceList {
        index
        eightHundredFlag
        bNumberPrefix
        usageType
        serviceType
        usageClass
        price
      }
    }
  }
`;
