import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ ...filter }) => {
  const filterStr = filter ? convertFilterRequestUrl(filter, ['showActiveOnly']) : '';
  const getCalendar = `getCalendar(input: ${filterStr})`;
  return gql`
    query {
      ${getCalendar} {
        id
        name
        description
        startDate
        prefix
        delimiter
        frequency
        adjustingPeriodCount
        unit
        status
        periods {
          id
          index
          name
          startDate
          endDate
          adjustingPeriod
          quarter
        }
      }
    }
  `;
};
