import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GenericInput, DataTable } from '../../../components/common';

const ArOperationsDetails = ({
  listFields,
  data,
  onClick,
  isDisabledAllField,
  onChange,
  onBlurAmount,
  onOpenPopupInfo,
  modeGetArOpsTaxOptions,
  invoiceLinesColums,
  onCancel,
}) => {
  const { t } = useTranslation('common');
  const newListFields = cloneDeep(listFields);

  return (
    <div className="form-group col-md-12 p-2 row">
      {newListFields.map(item => {
        if (!isEmpty(item.options) || item.tOptions) {
          return (
            <GenericInput
              key={item.label}
              label={item.label || ''}
              value={data[item.name]}
              wrapperClass={item.wrapperClass || 'col-md-4'}
              type="select"
              onChange={onChange}
              name={item.name}
              disabled={
                item.disabled ||
                (item.name === 'percent' && data.amount) ||
                (modeGetArOpsTaxOptions !== 2 && item.name === 'taxRule')
              }
              readOnly
              {...item}
            />
          );
        }

        if (item.type === 'money') {
          return (
            <GenericInput
              key={item.label}
              wrapperClass={item.wrapperClass || 'col-md-4'}
              label={item.label || ''}
              value={data[item.name]}
              disabled={item.disabled || isDisabledAllField || (item.name === 'amount' && data.percent)}
              // thousandSeparator
              // placeholder="0.00"
              // prefix="$ "
              type="number"
              onChange={values => onChange({ name: item.name, value: values.value })}
              // onBlur={onBlurAmount}
              readOnly
            />
          );
        }

        return (
          <GenericInput
            key={item.label}
            label={item.label || ''}
            placeholder={item.placeholder}
            value={(!isEmpty(data) && data[item.name]) || ''}
            type={`${item.type ? item.type : 'text'}`}
            name={item.name}
            disabled={isDisabledAllField || item.disabled || (item.name === 'percent' && data.amount)}
            onChange={onChange}
            onClick={() => onClick(item.name)}
            wrapperClass={item.wrapperClass || 'col-md-4'}
            fa={item.isPopup ? 'fa fa-external-link' : ''}
            onOpenPopupInfo={onOpenPopupInfo}
            readOnly
            {...item}
          />
        );
      })}
      <div className="col-md-12">
        <h4 className="p-2 pb-3 text-center">{t('label.invoiceLineDetails')}</h4>
        <DataTable columns={invoiceLinesColums} data={data && data.lines ? data.lines : []} />
      </div>

      <div className="col-md-12 mt-3">
        <button type="button" onClick={onCancel} className="button button-border black x-small mr-2 float-right">
          {t('label.cancel')}
        </button>
      </div>
    </div>
  );
};

ArOperationsDetails.propTypes = {
  onChange: PropTypes.func,
  onBlurAmount: PropTypes.func,
  onClick: PropTypes.func,
  listFields: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any),
  isDisabledAllField: PropTypes.bool,
  modeGetArOpsTaxOptions: PropTypes.number,
  onOpenPopupInfo: PropTypes.func,
  invoiceLinesColums: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
};

ArOperationsDetails.defaultProps = {
  listFields: [],
  data: {},
  onChange: () => {},
  onClick: () => {},
  onBlurAmount: () => {},
  onOpenPopupInfo: () => {},
  onCancel: () => {},
  isDisabledAllField: false,
  modeGetArOpsTaxOptions: 2,
  invoiceLinesColums: [],
};
export default ArOperationsDetails;
