import gql from 'graphql-tag';

export default () => {
  const getConfigAccountingRules = 'getConfigAccountingRules(dummy: "")';
  return gql`
    query {
      ${getConfigAccountingRules} {
        id
        name
        configAccountingRulesList {
          index
          drItemId
          crItemId
          drGlAccount
          crGlAccount
          description
          crAccountType
          drAccountType
          objectType
          transactionType
          status
          currency
        }
      }
    }
  `;
};
