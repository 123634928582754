import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, []);
  const invoiceAccount = `invoiceAccount(input: ${dataModify})`;
  return gql`
  mutation {
    ${invoiceAccount} {
      id
    }
  }
`;
};
