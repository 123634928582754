import gql from 'graphql-tag';

export default () => gql`
  query {
    getRegionMapConfig(dummy: "") {
      id
      description
      name
      regions {
        index
        description
        longDistanceType
        regionId
      }
    }
  }
`;
