/**
 * Collections selectors
 */

import { createSelector } from 'reselect';

const selectCollections = state => state.get('revenueConfigReducer');

const makeGetRevenueMilestone = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('revenueMilestone').length ? revenue.get('revenueMilestone') : []
  );

const makeGetRevenueMilestoneItem = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('revenueMilestoneItem') ? revenue.get('revenueMilestoneItem') : {}
  );

const makeGetExchangeRate = () =>
  createSelector(selectCollections, revenue => (revenue.get('exchangeRate') ? revenue.get('exchangeRate') : {}));

const makeGetExchangeRateById = () =>
  createSelector(selectCollections, revenue => (revenue.get('exchangeRateId') ? revenue.get('exchangeRateId') : {}));

const makeGetExchangeRateExportId = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('exchangeRateExportId') ? revenue.get('exchangeRateExportId') : {}
  );

const makeGetAllGLAccounts = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('allGLAccountsList') ? revenue.get('allGLAccountsList') : {}
  );

export {
  makeGetRevenueMilestone,
  makeGetRevenueMilestoneItem,
  makeGetExchangeRate,
  makeGetExchangeRateById,
  makeGetExchangeRateExportId,
  makeGetAllGLAccounts,
};
