import _ from 'lodash';
import dataEnum from './dataEnum';

const tranformObjToArr = obj => _.map(obj, n => ({ value: n, label: n }));
export default {
  // customer
  accountReason: tranformObjToArr(dataEnum.accountReason),
  accountStatus: tranformObjToArr(dataEnum.accountStatus),
  customerSegment: tranformObjToArr(dataEnum.customerSegment),
  accountType: tranformObjToArr(dataEnum.type),
  accountSubType: tranformObjToArr(dataEnum.subType),
  salesChannel: tranformObjToArr(dataEnum.salesChannel),
  marketSegment: tranformObjToArr(dataEnum.marketSegment),
  accountRoles: tranformObjToArr(dataEnum.roles),
  // salutation: tranformObjToArr(dataEnum.salutation),
  phoneType: tranformObjToArr(dataEnum.phoneType),
  billingFrequency: tranformObjToArr(dataEnum.billingFrequency),
  invoiceType: tranformObjToArr(dataEnum.invoiceType),
  invoiceDelivery: tranformObjToArr(dataEnum.invoiceDelivery),
  paymentTerm: tranformObjToArr(dataEnum.paymentTerm),
  paymentMethod: tranformObjToArr(dataEnum.paymentMethod),
  source: tranformObjToArr(dataEnum.source),
  entityType: tranformObjToArr(dataEnum.entityType),
  arType: tranformObjToArr(dataEnum.arType),
  arTaxRule: tranformObjToArr(dataEnum.arTaxRule),
  arCrDrType: tranformObjToArr(dataEnum.arCrDrType),
  // pricing management
  revenueType: tranformObjToArr(dataEnum.revenueType),
  revenueRecognitionType: tranformObjToArr(dataEnum.revenueRecognitionType),
  boolean: tranformObjToArr(dataEnum.boolean),
  statusPricing: tranformObjToArr(dataEnum.status),
  unit: tranformObjToArr(dataEnum.unit),
  grantUnit: tranformObjToArr(dataEnum.grantUnit),
  pricingModel: tranformObjToArr(dataEnum.pricingModel),
  transactionType: tranformObjToArr(dataEnum.transactionType),
  serviceType: tranformObjToArr(dataEnum.serviceType),
  proration: tranformObjToArr(dataEnum.proration),
  dependencyType: tranformObjToArr(dataEnum.dependencyType),
  billType: tranformObjToArr(dataEnum.billType),
  billStatus: tranformObjToArr(dataEnum.billStatus),

  // User management
  userCategory: tranformObjToArr(dataEnum.userCategory),

  // payment
  paymentProvider: tranformObjToArr(dataEnum.paymentProvider),

  // order
  orderType: tranformObjToArr(dataEnum.orderType),
  orderStatus: tranformObjToArr(dataEnum.orderStatus),
  initialTermUnit: tranformObjToArr(dataEnum.initialTermUnit),
  renewalTermUnit: tranformObjToArr(dataEnum.renewalTermUnit),
  trialTermUnit: tranformObjToArr(dataEnum.trialTermUnit),
  action: tranformObjToArr(dataEnum.action),
  orderLineStatus: tranformObjToArr(dataEnum.orderLineStatus),
  relativeStartUnit: tranformObjToArr(dataEnum.relativeStartUnit),
  relativeEndUnit: tranformObjToArr(dataEnum.relativeEndUnit),
  subscriptionReason: tranformObjToArr(dataEnum.subscriptionReason),

  subscriptionStatus: tranformObjToArr(dataEnum.subscriptionStatus),

  dayOfWeek: tranformObjToArr(dataEnum.dayOfWeek),
  hourOfDay: tranformObjToArr(dataEnum.hourOfDay),
  recordExpression: tranformObjToArr(dataEnum.recordExpression),
  fieldExpression: tranformObjToArr(dataEnum.fieldExpression),
  operator: tranformObjToArr(dataEnum.operator),
  roundingMethod: tranformObjToArr(dataEnum.roundingMethod),
  selectBoolean: tranformObjToArr(dataEnum.selectBoolean),
  selectUnit: tranformObjToArr(dataEnum.selectUnit),
  statusRateUnit: tranformObjToArr(dataEnum.statusRateUnit),
  recordIndicator: tranformObjToArr(dataEnum.recordIndicator),
  moduleName: tranformObjToArr(dataEnum.moduleName),
  streamName: tranformObjToArr(dataEnum.streamName),
  volumeTreatment: tranformObjToArr(dataEnum.volumeTreatment),
  tierType: tranformObjToArr(dataEnum.tierType),
  tierReference: tranformObjToArr(dataEnum.tierReference),
  timeUnit: tranformObjToArr(dataEnum.timeUnit),
  listUnit: tranformObjToArr(dataEnum.listUnit),
  dashboardType:  tranformObjToArr(dataEnum.dashboardType),
};
