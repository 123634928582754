import gql from 'graphql-tag';

export default () => {
  const getCustomDBSeq = 'getCustomDBSeq(dummy: "")';
  return gql`
    query {
      ${getCustomDBSeq} {
        id
        name
        description
        customDBSeqObject {
          id
          index
          customPaymentMethod
          sellingCompany
          prefix
          separator
          sequenceName
          legalEntity
          accountType
          paymentMethod
          objectType
          customerSegment
          sequenceSeed
          sequenceInUse
          minimumLength
          state
          country
        }
      }
    }
  `;
};
