import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'arLevel',
        'reason',
        'taxTreatment',
        'arThreshold',
        'accountType',
        'paymentMethod',
      ])}`
    : '';
  const getDisputeReport = `getDisputeReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getDisputeReport} {
        id
        accountId
        firstName
        lastName
        company
        processDate
        arLevel
        taxTreatment
        arCrDrType
        amount
        accountType
        paymentMethod
        reason
      }
    }
  `;
};
