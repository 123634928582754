
import _ from 'lodash'

const parseToMutationRequest = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return  `${key}: ${parseArrToString(value, key, arrNS)}`;
    }
    if (_.isObject(value)) {
      return `${key}: ${parseToMutationRequest(value, arrNS)}`;
    }
    if(value || value === 0){
      return `${key}: ${parseValueString(value, key, arrNS)}`;
    }
   
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};
const parseValueString = (value, key, arrNS) => {
 
  if (arrNS.includes(key)) {
    return `${value}`;
  }
  return `"${value}"`;
};
const parseArrToString = (value, key, arrNS) => {
  const arrItem = _.map(value, (childValue, childKey) => {
    if (_.isArray(childValue)) {
      return `${parseArrToString(childValue, childKey, arrNS)}`;
    }
    if (_.isObject(childValue)) {
      return `${parseToMutationRequest(childValue, arrNS)}`;
    }
    return `${parseValueString(childValue, childKey, arrNS)}`;
  });
  return `[${arrItem.filter(el => !!el).join(', ')}]`;
};
export default parseToMutationRequest