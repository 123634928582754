import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SuspendedFailedRecordsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'datetime',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'datetime',
      isClearable: true,
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      label: 'common:label.provisioningId',
      name: 'provisioningId',
    },
    {
      label: 'common:label.usageType',
      name: 'usageType',
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:usageRecStatusFailedRecord',
      isClearable: true,
    },
    {
      label: 'common:label.errorCode',
      name: 'errorCode',
      type: 'select',
      tOptions: 'selections:errorCodeProcessService',
    },
  ];

  btnSpan = 'col-md-3';
}

SuspendedFailedRecordsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SuspendedFailedRecordsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SuspendedFailedRecordsSearchForm);
