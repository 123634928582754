import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['quarter'])}`
    : '';
  const employeeCostingQuarterlyPNLReport = `employeeCostingQuarterlyPNLReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${employeeCostingQuarterlyPNLReport} {
        employeeId
        employeeName
        jobTitle
        totalExpectedIncome
        totalActualIncome
        totalGrosssal
        totalPayrollTaxes
        totalYearEndBonus
        totalActualCost
        totalGrossProfitAverage
        totalGrossMarginAverage
        fixed1
        fixed2
        fixed3
        fixed4
        fixed5
        fixed6
        fixed7
        fixed8
        fixed9
        fixed10
        fixed11
        fixed12
        totalFixedExpenses
        variable1
        variable2
        variable3
        variable4
        variable5
        variable6
        variable7
        variable8
        variable9
        variable10
        variable11
        variable12
        totalVariableExpenses
        overheadExpenses
        netIncomeMxn
        exchangeRate
        totalRevenuesUsd
        grossProfitsUsd
        totalExpensesUsd
        netIncomesUsd
      }
    }
  `;
};
