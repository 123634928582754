import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getPageTotalCount, formatMoneyValue } from '../../../utils/utils';
import constant from '../../CustomerPage/constants';
import { TablePagination, DataTable, ModalDetailsItem, FormCollapse, GenericInput } from '../../../components/common';
import { AdjustmentsDetailsSearchForm } from '../../../components/ArOperationsHub';
import { getArOpsUnitById } from '../../CustomerPage/actions';
import { searchAdjustments } from '../actions';

const arActivitySort = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  arType: {
    asc: 'arType_ASC',
    desc: 'arType_DESC',
  },
  reason: {
    asc: 'reason_ASC',
    desc: 'reason_ASC',
  },
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
  itemId: {
    asc: 'itemId_ASC',
    desc: 'itemId_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
};

const arActivitiesTable = [
  {
    name: 'startDate',
    label: 'common:label.startDate',
  },
  {
    name: 'amount',
    label: 'common:label.amount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amount)}</span>;
    },
  },
  {
    name: 'type',
    label: 'common:label.type',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arType')().find(val => val.value === item.type) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'source',
    label: 'common:label.source',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arSource')().find(val => val.value === item.source) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'invoiceId',
    label: 'common:label.invoiceId',
  },
  {
    name: 'itemId',
    label: 'common:label.itemId',
    sortable: true,
  },
  {
    name: 'arType',
    label: 'common:label.arCrDrType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arCrDrType')().find(val => val.value === item.arType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'taxRule',
    label: 'common:label.arTaxRule',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arTaxRule')().find(val => val.value === item.taxRule) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
    sortable: true,
  },
  // {
  //   name: 'invoiceCancellation',
  //   label: 'common:label.invoiceCancellation',
  //   render: (colName, item) => {
  //     return <span>{item?.invoiceCancellation ? 'TRUE' : item?.invoiceCancellation === false ? 'FALSE' : ''}</span>;
  //   },
  // },
];

class ArActivity extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: { arOpsRequestId: props.id, type: props.type || null },
      sort: '',
      sorted: {},
      isSearching: false,
      totalCount: null,
      isOpenModal: false,
      isSearchDataModal: false,
      data: [],
      itemData: {},
      activeTab: {
        // name: 'Recurring Data',
        // isActive: true,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.arActivity }),
    };
  }

  componentDidMount() {
    this.doSearchArActivity();
  }

  doSearchArActivity = () => {
    const { searchAdjustments, searchData } = this.props;
    const { page, size, filter, sorted } = this.state;
    const serachAPI = searchData || searchAdjustments;
    this.setState({ isSearching: true });
    serachAPI(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? arActivitySort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ data }) => {
        this.setState({ isSearching: false, data: data || [] });
      }
    );
  };

  onHandleSubmit = filter => {
    const { id, type } = this.props;
    const newFilter = filter;
    newFilter.arOpsRequestId = id;
    newFilter.type = type;
    this.setState({ filter: newFilter }, () => this.doSearchArActivity());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchArActivity());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchArActivity());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchArActivity());
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  };

  doGetArOpsUnitById = id => {
    const { getArOpsUnitById } = this.props;
    this.setState({ isOpenModal: true, isSearchDataModal: true, activeTab: {} });
    getArOpsUnitById(id, ({ data, success }) =>
      this.setState({ itemData: success ? data : {}, isSearchDataModal: false })
    );
  };

  renderItem = item => {
    const { itemData } = this.state;
    switch (item.state) {
      case 'AR Ops Data':
        return (
          <div className="row col-md-12 p-2">
            {constant.dataFieldArOpsDataId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.arData && itemData.arData[0] && itemData.arData[0][val.name]
                    ? itemData.arData[0][val.name]
                    : null
                }
                disabled
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableOpsTaxData} data={itemData.taxData ? itemData.taxData : []} />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableCurrencyArOpsDataId} data={itemData.balances ? itemData.balances : []} />
          </div>
        );

      default:
        return null;
    }
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const { t, onCancel, id } = this.props;
    const {
      isSearching,
      sorted,
      page,
      size,
      totalCount,
      isOpenModal,
      itemData,
      isSearchDataModal,
      activeTab,
      data,
    } = this.state;
    let newCollapsibleMenu = constant.collapsibleMenuArOpsUnitById;
    if (!itemData.arData) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'AR Ops Data');
    }
    if (!itemData.taxData) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');
    }
    if (!itemData.balances) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Currency');
    }

    // if (
    //   !itemData.type ||
    //   [
    //     'BILL_ADJUSTMENT',
    //     'TRANSACTION_ADJUSTMENT',
    //     'BILL_DISPUTE',
    //     'TRANSACTION_DISPUTE',
    //     'BILL_SETTLEMENT',
    //     'ACCOUNT_WRITEOFF',
    //     'INVOICE_WRITEOFF',
    //     'TRANSACTION_SETTLEMENT',
    //     'WRITE_OFF',
    //     'WRITE_OFF_REVERSAL',
    //   ].indexOf(itemData.type) === -1
    // )
    //   newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'AR Ops Data');
    // if (
    //   !itemData.type ||
    //   ['TAX_ADJUSTMENT', 'TAX_DISPUTE', 'TAX_SETTLEMENT', 'TAX_WRITEOFF', 'TAX_WRITEOFF_REVERSAL', 'TAX'].indexOf(
    //     itemData.type
    //   ) === -1
    // )
    //   newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');

    const newColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <button type="button" className="text-success no-border" onClick={() => this.doGetArOpsUnitById(item.id)}>
            {item.id}
          </button>
        ),
      },
      {
        name: 'refId',
        label: 'label.requestId',
        render: (colName, item) => <span>{item.refId || id || null}</span>,
      },
      {
        name: 'requestActivityId',
        label: 'label.lineReference',
      },
      ...arActivitiesTable,
      {
        name: 'select',
        label: 'label.view',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.doGetArOpsUnitById(item.id)}
            // onClick={() => this.doGetArOpsUnitById('AR-503004')}
          >
            {t('label.view')}
          </button>
        ),
      },
    ];

    return (
      <div className="col-md-12 mb-30 mt-4">
        {/* <div className="card card-statistics"> */}
        <div className="card-body">
          <AdjustmentsDetailsSearchForm onSubmit={this.onHandleSubmit} />
          <br />
          <div>
            <DataTable
              columns={newColumns}
              data={data || []}
              isLoading={isSearching}
              sorted={sorted}
              onSort={this.onSortColumn}
            />
          </div>
        </div>
        {/* </div> */}
        <br />
        <div className="col-sm-12 mb-0 page-size-modal">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalDetailsItem
          isOpen={isOpenModal}
          data={itemData}
          onToggle={this.onToggleModal}
          dataField={constant.dataFieldArOpsUnitById}
          title={t('label.aRActivity')}
          isSearch={isSearchDataModal}
          wrapperClass="modal-custom modal-70"
        >
          <div className="pt-4">
            {newCollapsibleMenu.map(item => (
              <FormCollapse
                key={item.title}
                label="First Name"
                isActive={item.state === activeTab.name && activeTab.isActive}
                title={t(item.title) || ''}
                state={item.state}
                onToggleTab={this.onToggleTab}
              >
                {this.renderItem(item)}
              </FormCollapse>
            ))}
          </div>
        </ModalDetailsItem>
        <div className="col-md-12 mt-3">
          <button type="button" onClick={onCancel} className="button button-border black x-small mr-2 float-right">
            {t('label.cancel')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

ArActivity.propTypes = {
  arActivity: PropTypes.arrayOf(PropTypes.any),
  searchAdjustments: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ArActivity.defaultProps = {
  id: '',
  arActivity: [],
};

const newArActivity = connect(mapStateToProps, {
  searchAdjustments,
  getArOpsUnitById,
})(ArActivity);

export default withTranslation(['common', 'selections'])(newArActivity);
