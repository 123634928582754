import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['unit', 'status', 'isQuantityScalable']);
  const createRateUnit = `createRateUnit(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createRateUnit} {
        id
      }
    }
  `;
};
