import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { get3rdPartyTaxConfig } from '../actions';
import { makeGet3rdPartytaxConfig } from '../selectors';
import { getPageTotalCount } from '../../../utils/utils';
import { DataTable, TablePagination } from '../../../components/common';
import ThirdPartySearchForm from './ThirdPartySearchForm';

const sortColumn = {
  serviceName: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  transactionName: {
    asc: 'transactionType_ASC',
    desc: 'transactionType_DESC',
  },
};

class ThirdPartyTaxConfig extends PureComponent {
  state = {
    configCodes: [],
    isSearching: true,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
  };

  componentDidMount() {
    this.doGetTaxCodeConfig();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      configCodes: props.configCodes,
      totalCount: getPageTotalCount({ ...state, items: props.configCodes }),
    };
  }

  doGetTaxCodeConfig = () => {
    const { get3rdPartyTaxConfig } = this.props;
    const { filter, page, size, sorted } = this.state;
    if (!filter.status) delete filter.status;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortColumn[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    get3rdPartyTaxConfig(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetTaxCodeConfig());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetTaxCodeConfig());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetTaxCodeConfig());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetTaxCodeConfig());
  };

  render() {
    const { configCodes, isSearching, page, size, totalCount, sorted } = this.state;
    const { t } = this.props;
    const creditProfileColumns = [
      {
        name: 'serviceName',
        label: 'label.serviceType',
        sortable: true,
      },
      {
        name: 'transactionName',
        label: 'label.transactionName',
        sortable: true,
      },
      {
        name: 'serviceTypeId',
        label: 'label.serviceId',
      },
      {
        name: 'transactionTypeId',
        label: 'label.transactionId',
      },
    ];
    const newDataRemoveIndex = configCodes;
    return (
      <div className="col-md-12 mb-30 p-0">
        <PageTitle
          linkTo={RouteNames.taxCodeConfig.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:billingHub.subMain.taxation'), url: RouteNames.taxCodeConfig.path },
            { name: t('baseConfigurationsPage:sidebar.thirdPartyTaxConfig') },
          ]}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <ThirdPartySearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <FormWithTableItem title={t('baseConfigurationsPage:sidebar.thirdPartyTaxConfig')}> */}
        {/* <FormWithTableItem title=""> */}
        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div>
                <DataTable
                  columns={creditProfileColumns}
                  sorted={sorted}
                  onSort={this.onSortColumn}
                  data={newDataRemoveIndex}
                  isLoading={isSearching}
                />
              </div>
              <br />
            </div>
          </div>
        </div>

        {/* </FormWithTableItem> */}
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

ThirdPartyTaxConfig.propTypes = {
  get3rdPartyTaxConfig: PropTypes.func.isRequired,
};

ThirdPartyTaxConfig.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  configCodes: makeGet3rdPartytaxConfig() || [],
});

export default withTranslation('common')(connect(mapStateToProps, { get3rdPartyTaxConfig })(ThirdPartyTaxConfig));
