import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import PaymentConfiguration from './PaymentConfiguration';
import PaymentAgent from './PaymentAgent';
import PaymentSurcharge from './PaymentSurcharge';
// import UploadPayment from './UploadPayment';
import ProcessPayment from './ProcessPayment';
import BatchPaymentFile from './BatchPaymentFile';
import PaymentItemMap from './PaymentItemMap';
import AllocationRules from './AllocationRules';
import Sidebar from './Sidebar';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { makeGetPermissionsPaymentManagement } from '../App/selectors';

class PaymentManagement extends PureComponent {
  render() {
    const { permissionsPayment } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsPayment, listKeys: permissionList.payments })) return null;
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.allocationRules.path} component={AllocationRules} />
              <Route exact path={RouteNames.paymentConfiguration.path} component={PaymentConfiguration} />
              <Route exact path={RouteNames.paymentAgent.path} component={PaymentAgent} />
              <Route exact path={RouteNames.paymentSurcharge.path} component={PaymentSurcharge} />
              <Route exact path={RouteNames.batchPaymentFile.path} component={BatchPaymentFile} />
              {/* <Route exact path={RouteNames.uploadPayment.path} component={UploadPayment} /> */}
              <Route exact path={RouteNames.processPayment.path} component={ProcessPayment} />
              <Route exact path={RouteNames.paymentItemMap.path} component={PaymentItemMap} />
              <Route exact path={RouteNames.paymentItemMap.path} component={PaymentItemMap} />
            </Switch>
            <br />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({ key: 'paymentsReducer', reducer });
const withSaga = injectSaga({ key: 'paymentsSaga', saga });

export default compose(withReducer, withSaga, withConnect)(PaymentManagement);
