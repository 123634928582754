import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import dataField from '../constant';
import { GenericInput, FormCollapse } from '../../../components/common';

const PlatformDefaults = ({
  modeModifyPropertyDefaults,
  data,
  // modifyTenant,
  getCcpProperties,
  ccpProperties,
  modifyCcpProperties,
  id,
  t,
}) => {
  const [ccpPropertiesData, setCcpPropertiesData] = useState(ccpProperties || {});
  const [dataSubmitCcpProperties, setDataSubmitCcpProperties] = useState([]);
  const [activeTab, setActiveTab] = useState({ state: 'CUSTOMER HUB', isActive: true });
  useEffect(() => {
    if (ccpProperties && !ccpProperties.ccpPropertyList) getCcpProperties();
  }, [ccpProperties, getCcpProperties]);
  if (!ccpProperties) return '';

  const onHandleSubmit = evt => {
    evt.preventDefault();
    if (dataSubmitCcpProperties && dataSubmitCcpProperties.length) {
      modifyCcpProperties({ ccpPropertyList: dataSubmitCcpProperties });
    }
  };

  const onToggleTab = name => {
    if (activeTab.name === name) {
      setActiveTab({ name, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name, isActive: true });
    }
  };

  const onChangeCcpProperties = ({ name, value, indexValue }) => {
    try {
      let newValue = value;
      const indexItem = indexValue > -1 ? indexValue : ccpPropertiesData.ccpPropertyList.length;
      if (value === 'TRUE') newValue = 'true';
      if (value === 'FALSE') newValue = 'false';
      if (name === 'monthEndCloseThreshold' && (Number.parseFloat(value) < 1 || Number.parseFloat(value) > 7)) {
        return '';
      }
      if (!ccpPropertiesData.ccpPropertyList[indexItem]) {
        ccpPropertiesData.ccpPropertyList[indexItem] = { property: name };
      }
      ccpPropertiesData.ccpPropertyList[indexItem].value = newValue;
      const indexItemSubmit = dataSubmitCcpProperties.findIndex(val => `${val.property}` === name);
      if (indexItemSubmit !== -1) {
        dataSubmitCcpProperties[indexItemSubmit].value = newValue;
      } else {
        dataSubmitCcpProperties.push({ property: name, value: newValue });
      }
      setCcpPropertiesData({ ...ccpPropertiesData });
      setDataSubmitCcpProperties([...dataSubmitCcpProperties]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="" onSubmit={onHandleSubmit}>
      {dataField.collapsiblePlatformDefault.map(item => (
        <FormCollapse
          key={item.title}
          label="First Name"
          isActive={item.state === activeTab.name && activeTab.isActive}
          title={t(item.title) || ''}
          state={item.state}
          onToggleTab={onToggleTab}
        >
          <div className="col-md-12 row p-3">
            {item.ccpPropertiesFields &&
              item.ccpPropertiesFields.map(val => {
                if (val.isTitle) {
                  return <h6 className="col-md-12 border-bottom mb-3 mt-3 text-center">{t(val.title)}</h6>;
                }
                const indexValue =
                  ccpPropertiesData &&
                  ccpPropertiesData.ccpPropertyList &&
                  ccpPropertiesData.ccpPropertyList.length &&
                  ccpPropertiesData.ccpPropertyList.findIndex(i => i.property === val.name);
                let value = indexValue > -1 ? ccpPropertiesData.ccpPropertyList[indexValue].value : '';
                if (value === 'true' || value === 'false')
                  value = value === 'true' || value === true ? 'TRUE' : 'FALSE';
                if (val && (!isEmpty(val.options) || val.tOptions)) {
                  return (
                    <GenericInput
                      key={val.label}
                      label={val.label || ''}
                      type="select"
                      value={value || null}
                      wrapperClass="col-md-4"
                      disabled={modeModifyPropertyDefaults === 1}
                      onChange={({ name, value }) => onChangeCcpProperties({ indexValue, name, value })}
                      name={val.name}
                      {...val}
                    />
                  );
                }
                return (
                  <GenericInput
                    key={val.label}
                    value={value}
                    label={val.label || ''}
                    type={val.isNumber ? 'number' : 'text'}
                    onChange={({ name, value }) => onChangeCcpProperties({ indexValue, name, value })}
                    disabled={modeModifyPropertyDefaults === 1}
                    name={val.name}
                    wrapperClass="col-md-4"
                    {...val}
                  />
                );
              })}
          </div>
        </FormCollapse>
      ))}
      <div className="mt-3 pb-4">
        {modeModifyPropertyDefaults === 2 && (
          <button
            type="submit"
            className="button button-border ml-2 x-small float-right"
            disabled={!dataSubmitCcpProperties.length}
          >
            {t('label.saveConfig')}
          </button>
        )}
        <button
          type="button"
          onClick={() => getCcpProperties()}
          className="button button-border black x-small float-right"
        >
          {t('label.cancel')}
        </button>
      </div>
    </form>
  );
};

PlatformDefaults.propTypes = {
  onHandleChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  modeModifyPropertyDefaults: PropTypes.number,
  isRequireField: PropTypes.bool,
};

PlatformDefaults.defaultProps = {
  onHandleChange: () => {},
  data: {},
  modeModifyPropertyDefaults: 2,
  isRequireField: false,
};

export default withTranslation('common')(PlatformDefaults);
