export default {
  tenantManagementPage: {
    tenantData: 'Tenant Data',
    tenantProfile: 'Tenant Profile',
    platformDefaults: 'Platform Defaults',
    paymentGateway: 'Payment Gateway',
    taxGateway: 'Tax Gateway',
    financeGateway: 'Finance Gateway',
    merchantData: 'Merchant Data',
    customRecords: 'Custom Records',
    urlMapping: 'URL Mapping',
    requestResponseMapping: 'Request/Response Mapping',
    selfCareMarketing: 'SelfCare Marketing',
    provisioningGateway: 'Provisioning Gateway',
    idSequenceConfig: 'Id Sequence Config',
    projectGateway: 'Project Gateway',
    customerSupportGateway: 'Customer Support Gateway',
    documentGateway: 'Document Gateway',
    operationGateway: 'Operation Gateway',
    crmGateway: 'CRM Gateway',
    config: 'Config',
    schedulePattern: 'Schedule Pattern',
    tenantConfig: 'Tenant Config',
    policyEnablers: 'Policy Enablers',
    templates: 'Templates',
    productSupportTask: 'Product Support Task',
    folioResponseFile: 'Folio Response File',
  },
};
