// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'accountType',
    'accountSubType',
    'salesChannel',
    'marketSegment',
    'delay',
    'index',
    'billingDom',
    'billingFrequency',
  ]);
  const modifyFutureCycleCfg = `modifyFutureCycleCfg(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyFutureCycleCfg} {
      id
    }
  }
`;
};
