import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { columns } from './commonFields';
import { formatMoneyValueToExport } from '../utils';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 7,
    columns: [],
    data: [[{ value: title || 'DATA OPERATOR COST', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
      columns.common,
    ],
    data: [
      [
        {
          value: t('label.operatorName'),
          style: styleHeader,
        },
        {
          value: t('label.date'),
          style: styleHeader,
        },
        {
          value: t('label.capacity'),
          style: styleHeader,
        },
        {
          value: t('label.commitment'),
          style: styleHeader,
        },
        {
          value: t('label.tariff'),
          style: styleHeader,
        },
        {
          value: t('label.rental'),
          style: styleHeader,
        },
        {
          value: t('label.quantity'),
          style: styleHeader,
        },
        {
          value: t('label.directCommitment'),
          style: styleHeader,
        },
        {
          value: t('label.directTariff'),
          style: styleHeader,
        },
        {
          value: t('label.directCost'),
          style: styleHeader,
        },
        {
          value: t('label.commitmentLeft'),
          style: styleHeader,
        },
        {
          value: t('label.overageQuantity'),
          style: styleHeader,
        },
        {
          value: t('label.overageCost'),
          style: styleHeader,
        },
        {
          value: t('label.totalCost'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.operatorName ? item.operatorName : '' });
      newItem.push({ value: item.lineDate ? item.lineDate : '' });
      newItem.push({
        value:
          item.contractCapacity || typeof item.contractCapacity === 'number'
            ? formatMoneyValueToExport(item.contractCapacity)
            : '',
      });
      newItem.push({
        value:
          item.commitmentBase || typeof item.commitmentBase === 'number'
            ? formatMoneyValueToExport(item.commitmentBase)
            : '',
      });
      newItem.push({
        value: item.tariff || typeof item.tariff === 'number' ? formatMoneyValueToExport(item.tariff) : '',
      });
      newItem.push({
        value:
          item.rentalAmount || typeof item.rentalAmount === 'number' ? formatMoneyValueToExport(item.rentalAmount) : '',
      });
      newItem.push({
        value:
          item.totalQuantity || typeof item.totalQuantity === 'number'
            ? formatMoneyValueToExport(item.totalQuantity)
            : '',
      });
      newItem.push({
        value:
          item.directCommitment || typeof item.directCommitment === 'number'
            ? formatMoneyValueToExport(item.directCommitment)
            : '',
      });
      newItem.push({
        value:
          item.directTariff || typeof item.directTariff === 'number' ? formatMoneyValueToExport(item.directTariff) : '',
      });
      newItem.push({
        value:
          item.totalCostDirect || typeof item.totalCostDirect === 'number'
            ? formatMoneyValueToExport(item.totalCostDirect)
            : '',
      });
      newItem.push({
        value:
          item.commitmentLeft || typeof item.commitmentLeft === 'number'
            ? formatMoneyValueToExport(item.commitmentLeft)
            : '',
      });
      newItem.push({
        value:
          item.overageQuantity || typeof item.overageQuantity === 'number'
            ? formatMoneyValueToExport(item.overageQuantity)
            : '',
      });
      newItem.push({
        value:
          item.overageCost || typeof item.overageCost === 'number' ? formatMoneyValueToExport(item.overageCost) : '',
      });
      newItem.push({
        value: item.totalCost || typeof item.totalCost === 'number' ? formatMoneyValueToExport(item.totalCost) : '',
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
