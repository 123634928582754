import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SideBar from '../Sidebar';
import PageTitle from '../../../components/PageTitle';
import RouteName from '../../App/RouteNames';
import SearchAccounts from './SearchAccounts';
// import BillUnits from './InvoiceUnits';
import Footer from '../../../components/Footer';
import CollectionHistory from './CollectionHistory';
// import CollectionHistoryDetail from './CollectionHistory/CollectionHistoryDetail';

class CollectionAgent extends React.PureComponent {
  renderContent() {
    const {
      match: {
        params: { childRoute, id },
      },
    } = this.props;
    switch (childRoute) {
      case 'search-accounts':
        return <SearchAccounts {...this.props} />;
      // case 'invoice-units':
      //   return <BillUnits />;
      case 'collection-aging':
        return <CollectionHistory {...this.props} />;
      // return <CollectionHistoryDetail id={id} />;
      default:
        return <SearchAccounts {...this.props} />;
    }
  }

  render() {
    const {
      t,
      match: {
        params: { childRoute, id },
      },
      location,
    } = this.props;
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={{
            pathname:
              childRoute === 'collection-history' && id
                ? RouteName.collectionsHistory.path
                : RouteName.collectionsConfig.path,
            state: location && location.state ? location.state : null,
          }}
          titleBtn={t('label.back')}
          titleRight={t('label.collections')}
        />
        <br />
        {this.renderContent()}
        <Footer className="footer-bottom" />
      </div>
    );
  }
}

CollectionAgent.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

CollectionAgent.defaultProps = {
  match: {},
};

export default withTranslation('common')(CollectionAgent);
