import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class AROpsUnitNoInvoiceSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.itemId',
      name: 'itemId',
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.arType',
      name: 'arType',
      type: 'select',
      tOptions: 'selections:arType',
      isClearable: true,
    },
    {
      label: 'label.arTaxRule',
      name: 'arTaxRule',
      type: 'select',
      tOptions: 'selections:taxRule',
      isClearable: true,
    },
    {
      label: 'label.arCrDrType',
      name: 'arCrDrType',
      type: 'select',
      tOptions: 'selections:arCrDrType',
      isClearable: true,
    },
    {
      label: 'common:label.requestId',
      name: 'arOpsRequestId',
    },
  ];

  btnSpan = 'col-md-6';
}

AROpsUnitNoInvoiceSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AROpsUnitNoInvoiceSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(AROpsUnitNoInvoiceSearchForm);
