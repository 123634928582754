import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { columns } from './commonFields';
import { formatMoneyValueToExport, getLabelOptions } from '../utils';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 6,
    columns: [],
    data: [[{ value: title, style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.organization,
      columns.accountId,
      columns.common, // accountCurrency
      columns.common, // invoiceId
      columns.common, // invoiceDue
      columns.common, // allocationDate
      columns.commonLargColumn, // allocatedAmount
      columns.commonLargColumn, // sourceCurrencyAmount
      columns.common, // exchangeRate
      columns.common, // transactionType
      columns.common, // paymentId
      columns.common, // paymentSuspenseId
      columns.common, // status
      columns.common, // folioStatus
    ],
    data: [
      [
        {
          value: t('label.organization'),
          style: styleHeader,
        },
        {
          value: t('label.accountId'),
          style: styleHeader,
        },
        {
          value: t('label.accountCurrency'),
          style: styleHeader,
        },
        {
          value: t('label.invoiceId'),
          style: styleHeader,
        },
        {
          value: t('label.invoiceDue'),
          style: styleHeader,
        },
        {
          value: t('label.allocationDate'),
          style: styleHeader,
        },
        {
          value: t('label.allocatedAmountInAccountCurrencyExport'),
          style: styleHeader,
        },
        {
          value: t('label.allocatedAmountInPaymentSuspenseCurrencyExport'),
          style: styleHeader,
        },
        {
          value: t('label.exchangeRate'),
          style: styleHeader,
        },
        {
          value: t('label.transactionType'),
          style: styleHeader,
        },
        {
          value: t('label.paymentId'),
          style: styleHeader,
        },
        {
          value: t('label.paymentSuspenseId'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
        {
          value: t('label.folioStatus'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.organization ? item.organization : '' });
      newItem.push({ value: item.accountId ? item.accountId : '' });
      newItem.push({ value: item.accountCurrency ? item.accountCurrency : '' });
      newItem.push({ value: item.invoiceId ? item.invoiceId : '' });
      newItem.push({ value: formatMoneyValueToExport(item.invoiceDue) });
      newItem.push({ value: item.allocationDate ? item.allocationDate : '' });
      newItem.push({ value: formatMoneyValueToExport(item.allocatedAmount) });
      newItem.push({ value: formatMoneyValueToExport(item.sourceCurrencyAmount) });
      newItem.push({ value: formatMoneyValueToExport(item.exchangeRate) });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'transactionType', fieldOption: 'paymentTransactionType' }),
      });
      newItem.push({ value: item.paymentId ? item.paymentId : '' });
      newItem.push({ value: item.paymentSuspenseId ? item.paymentSuspenseId : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'statusPayment' }),
      });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'folioStatus', fieldOption: 'folioStatus' }),
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
