import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class InvoicingSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.invoiceTotalMoreThan',
      name: 'minInvoiceThreshold',
      type: 'number',
    },
    {
      label: 'label.invoiceTotalLessThan',
      name: 'maxInvoiceThreshold',
      type: 'number',
    },
    {
      label: 'label.dueDateMoreThan',
      name: 'dueStartDate',
      type: 'date',
    },
    {
      label: 'label.dueDateLessThan',
      name: 'dueEndDate',
      type: 'date',
    },
    {
      label: 'label.invoiceDueMoreThan',
      name: 'invoiceDue',
      type: 'number',
    },
    {
      label: 'label.invoiceDueLessThan',
      name: 'maxInvoiceDue',
      type: 'number',
    },
    {
      label: 'label.currency',
      name: 'currency',
      options: [],
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.organization',
      name: 'company',
    },
    {
      label: 'label.invoiceId',
      name: 'id',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.invoiceStatus',
      name: 'status',
      tOptions: 'selections:invoiceUnitStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.invoiceType',
      name: 'invoiceType',
      type: 'select',
      tOptions: 'selections:invoiceType',
      isClearable: true,
    },
    {
      label: 'label.groupBy',
      name: 'groupBy',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      tOptions: 'selections:paymentMethod',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.billEndMoreThan',
      name: 'billStartDate',
      type: 'date',
    },
    {
      label: 'label.billEndLessThan',
      name: 'billEndDate',
      type: 'date',
    },
    {
      label: 'label.folioStatus',
      name: 'folioStatus',
      tOptions: 'selections:folioStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.useExchangeRate',
      name: 'useExchangeRate',
      type: 'number',
    },
    {
      label: 'label.billType',
      name: 'billType',
      tOptions: 'selections:billUnitType',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-6';
}

InvoicingSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

InvoicingSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(InvoicingSearchForm);
