import React, { PureComponent } from 'react';
import PropsType from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { saveAs } from 'file-saver';
import 'filepond/dist/filepond.min.css';

class ModalViewMedia extends PureComponent {
  downloadFile = (theBlob, fileName) => {
    saveAs(theBlob, fileName);
  };

  render() {
    const { fileSelect, isOpen, onCancel, t, base64FileSelect, type, fileNameDownload } = this.props;
    return (
      <Modal isOpen={isOpen} className="modal-lg info-form">
        <ModalHeader />
        <ModalBody>
          <div className="preview-group p-2">
            {base64FileSelect && type === 'IMAGE' && (
              <img
                src={base64FileSelect}
                className="new-pdf-images"
                alt="img-avatar"
                height="200 !important"
                // width="200 !important"
              />
            )}
            {base64FileSelect && type === 'VIDEO' && (
              <video controls height="200 !important" key={base64FileSelect} autoPlay controlsList="nodownload">
                <source src={base64FileSelect} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            )}
            {base64FileSelect && type === 'HTML' && (
              <iframe
                type="application/pdf"
                src={base64FileSelect}
                title="report_view"
                style={{ width: '95%', minHeight: '300px', marginLeft: '20px' }}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.downloadFile(fileSelect, fileNameDownload || 'company_logo')}>
            {t('label.download')}
          </Button>
          <button type="button" className="btn btn-secondary" onClick={onCancel}>
            {t('label.cancel')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalViewMedia.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

ModalViewMedia.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

export default withTranslation('common')(ModalViewMedia);
