import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { columns } from './commonFields';
import { formatMoneyValueToExport, getLabelOptions } from '../utils';

const multiDataSetDefault = ({ t, title, columnsTable }) => [
  {
    xSteps: columnsTable.length > 4 ? columnsTable.length / 2 - 2 : 1,
    columns: [],
    data: [[{ value: title || 'BILLABLE SERVICES', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: columnsTable.map(val => columns[val.name] || columns.common),
    data: [
      columnsTable.map(val => ({
        value: t(val.label),
        style: styleHeader,
      })),
    ],
    // columns: [
    //   columns.common, // commercialName
    //   columns.accountId, // accountId
    //   columns.name, // name
    //   columns.common, // billableServiceName
    //   columns.common, // deliveryDate
    //   columns.common, // cancellationDate
    //   columns.common, // orderDate
    //   columns.common, // nextCutOffDate
    //   columns.common, // cutOffDOM
    //   columns.common, // recurringFrequency
    //   columns.common, // recurringUnit
    //   columns.status, // status
    //   columns.common, // quantity
    //   columns.common, // invoiceExhibits
    //   columns.common, // exhibitsInvoiced
    //   columns.common, // unitPrice
    //   columns.amount, // amount
    //   columns.common, // capacity
    //   columns.common, // uom
    //   columns.common, // currency
    //   columns.common, // operativeStatus
    //   columns.common, // adminStatus
    //   columns.common, // circuitId
    //   columns.common, // clientCircuitId
    //   columns.common, // loadType
    //   columns.common, // billableServiceId
    //   columns.common, // instanceId
    //   columns.common, // partMonthWaiveOffFlag
    //   columns.common, // moveOrder
    //   columns.common, // isTraffic
    //   columns.common, // inAdvanceFlag
    //   columns.common, // contractTerm
    //   columns.common, // termEndDate
    //   columns.common, // isParent
    //   columns.common, // dispersed
    //   columns.common, // merged
    //   columns.common, // chargeType
    //   columns.common, // location1
    //   columns.reason, // reason
    //   columns.common, // orderType
    //   columns.common, // orderStatus
    //   columns.common, // crmId
    //   columns.common, // provisioningId
    //   columns.common, // opportunity
    //   columns.common, // location2
    //   columns.common, // billed
    // ],
    // data: [
    //   [
    //     {
    //       value: t('label.commercialName'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.name'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billableServiceName'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.deliveryDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.cancellationDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.orderDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.cutOffDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.cutOffDOM'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billingFrequency'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billingFrequencyUnit'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.status'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.quantity'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.invoiceExhibits'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.exhibitsInvoiced'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.unitPrice'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.amount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.capacity'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.unitOfMeasure'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.currency'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.operativeStatus'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.adminStatus'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.circuitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.clientCircuitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.loadType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billableServiceId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.instanceId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.partMonthWaiveOffFlag'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.moveOrder'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.trafficFlag'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.inAdvanceFlag'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.contractTerm'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.termEndDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.isParent'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.dispersed'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.merged'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.chargeType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.location1'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.reason'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.orderType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.orderStatus'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.crmId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.provisioningId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.opportunity'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.location2'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billed'),
    //       style: styleHeader,
    //     },
    //   ],
    // ],
  },
];

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'cutOffDOM') {
          if (item.cutOffDOM === -2) {
            const cutOffDOMOptions = t('selections:cutOffDOM')();
            const lastItem = cutOffDOMOptions[0];
            newItem.push({ value: lastItem.label || '' });
          } else newItem.push({ value: `${item.cutOffDOM || ''}` });
        }
        if (val.name !== 'cutOffDOM') {
          if (item[val.name] && typeof item[val.name] === 'number') {
            newItem.push({ value: formatMoneyValueToExport(item[val.name]) });
          } else if (val.isRenderT) {
            newItem.push({
              value: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
          } else {
            newItem.push({ value: item[val.name] ? item[val.name] : '' });
          }
        }
      });

      // newItem.push({ value: item.commercialName ? item.commercialName : '' });
      // newItem.push({ value: item.accountId ? item.accountId : '' });
      // newItem.push({ value: item.name ? item.name : '' });
      // newItem.push({ value: item.billableServiceName ? item.billableServiceName : '' });
      // newItem.push({ value: item.deliveryDate ? item.deliveryDate : '' });
      // newItem.push({ value: item.cancellationDate ? item.cancellationDate : '' });
      // newItem.push({ value: item.orderDate ? item.orderDate : '' });
      // newItem.push({ value: item.nextCutOffDate ? item.nextCutOffDate : '' });
      // if (item.cutOffDOM === -2) {
      //   const cutOffDOMOptions = t('selections:cutOffDOM')();
      //   const lastItem = cutOffDOMOptions[0];
      //   newItem.push({ value: lastItem.label || '' });
      // } else newItem.push({ value: `${item.cutOffDOM || ''}` });
      // newItem.push({
      //   value: typeof item.recurringFrequency === 'number' ? formatMoneyValueToExport(item.recurringFrequency) : '',
      // });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'recurringUnit', fieldOption: 'recurringUnit' }) });
      // newItem.push({ value: item.status ? item.status : '' });
      // newItem.push({ value: typeof item.quantity === 'number' ? formatMoneyValueToExport(item.quantity) : '' });
      // newItem.push({ value: item.invoiceExhibits ? item.invoiceExhibits : '' });
      // newItem.push({ value: item.exhibitsInvoiced ? item.exhibitsInvoiced : '' });
      // newItem.push({ value: typeof item.unitPrice === 'number' ? formatMoneyValueToExport(item.unitPrice) : '' });
      // newItem.push({ value: typeof item.amount === 'number' ? formatMoneyValueToExport(item.amount) : '' });
      // newItem.push({ value: typeof item.capacity === 'number' ? formatMoneyValueToExport(item.capacity) : '' });
      // newItem.push({ value: item.uom ? item.uom : '' });
      // newItem.push({ value: item.currency ? item.currency : '' });
      // newItem.push({ value: item.operativeStatus ? item.operativeStatus : '' });
      // newItem.push({ value: item.adminStatus ? item.adminStatus : '' });
      // newItem.push({ value: item.circuitId ? item.circuitId : '' });
      // newItem.push({ value: item.clientCircuitId ? item.clientCircuitId : '' });
      // newItem.push({ value: item.loadType ? item.loadType : '' });
      // newItem.push({ value: item.billableServiceId ? item.billableServiceId : '' });
      // newItem.push({ value: item.instanceId ? item.instanceId : '' });
      // newItem.push({
      //   value: item.partMonthWaiveOffFlag ? 'TRUE' : item.partMonthWaiveOffFlag === false ? 'FALSE' : '',
      // });
      // newItem.push({ value: item.moveOrder ? item.moveOrder : '' });
      // newItem.push({ value: item.isTraffic ? 'TRUE' : item.isTraffic === false ? 'FALSE' : '' });
      // newItem.push({ value: item.advanceFlag ? 'TRUE' : item.advanceFlag === false ? 'FALSE' : '' });
      // newItem.push({ value: typeof item.initialTerm === 'number' ? formatMoneyValueToExport(item.initialTerm) : '' });
      // newItem.push({ value: item.termEndDate ? item.termEndDate : '' });
      // newItem.push({ value: item.isParent ? 'TRUE' : item.isParent === false ? 'FALSE' : '' });
      // newItem.push({ value: item.isDispersed ? item.isDispersed : '' });
      // newItem.push({ value: item.isMerged ? item.isMerged : '' });
      // newItem.push({ value: item.chargeType ? item.chargeType : '' });
      // newItem.push({ value: item.location1 ? item.location1 : '' });
      // newItem.push({ value: item.reason ? item.reason : '' });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'orderType', fieldOption: 'types' }) });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'orderStatus', fieldOption: 'statusOrder' }) });
      // newItem.push({ value: item.crmId ? item.crmId : '' });
      // newItem.push({ value: item.provisioningId ? item.provisioningId : '' });
      // newItem.push({ value: item.opportunityName ? item.opportunityName : '' });
      // newItem.push({ value: item.location2 ? item.location2 : '' });
      // newItem.push({ value: item.billed ? 'TRUE' : item.billed === false ? 'FALSE' : '' });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
