import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import RoleGroupForm from './RoleGroupForm';
import { createRoleGroup } from '../actions';
import { Breadcrumb } from '../../../components/common';

class PricingItemAdd extends Component {
  state = {
    isSubmitting: false,
    items: [],
    parentItemId: '',
    openModal: false,
    page: 1,
    size: 20,
    isSearching: false,
    newRoleGroup: {
      roleGroupRoles: [],
    },
  };

  onCreateItem = data => {
    this.setState({ isSubmitting: true });
    const { id, ...roleGroupInput } = data;
    this.props.createRoleGroup({ roleGroupInput }, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const newData = {};
    const { t, location } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.userManagementRoleGroup.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  {
                    name: t('navbar:operationsHub.subMain.userManagement'),
                    url: {
                      pathname: RouteNames.userManagementRole.path,
                    },
                  },
                  {
                    name: t('userManagementPage:sidebar.roleGroups'),
                    url: {
                      pathname: RouteNames.userManagementRoleGroup.path,
                      state: location && location.state ? location.state : null,
                    },
                  },
                  { name: t('label.addRoleGroup') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addRoleGroup')}</h5>
              </div>
              <RoleGroupForm
                item={this.state.newRoleGroup}
                onSubmit={this.onCreateItem}
                isSubmitting={this.state.isSubmitting}
                creatingMode
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default PricingItemAdd;
PricingItemAdd.propTypes = {
  createRoleGroup: PropTypes.func,
};

const mapStateToProps = store => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  createRoleGroup: (e, cb) => dispatch(createRoleGroup(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation('common')(compose(withConnect)(PricingItemAdd));
