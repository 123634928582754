import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';
import listCurrency from '../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class AccountingPoliciesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.currency',
      name: 'currency',
      type: 'select',
      options: currencyOptions,
      isClearable: true,
    },
    {
      label: 'common:label.objectType',
      name: 'objectType',
      tOptions: 'selections:accountingRulesObjectType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:genericStatus',
      isClearable: true,
      isCreateOption: true,
    },
    {
      label: 'common:label.crGlAccount',
      name: 'crGlAccount',
      fa: 'fa fa-external-link',
    },
    {
      label: 'common:label.drGlAccount',
      name: 'drGlAccount',
      fa: 'fa fa-external-link',
    },
  ];

  btnSpan = 'col-md-9';
}

AccountingPoliciesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountingPoliciesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountingPoliciesSearchForm);
