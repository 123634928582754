import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataTable, GenericInput, TitleFrom } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { getTaxTypeConfig, createTaxTypeConfig, modifyTaxTypeConfig } from '../actions';
import RouteNames from '../../App/RouteNames';
import { getCcpDateTimeConfig } from '../../App/actions';
import { makeGetCcpTime, makeGetPermissionsTaxConfig } from '../../App/selectors';
import { validate } from '../../../utils/utils';
import { checkPermissionForAllPages } from '../CheckPermission';
import ModalSearchItem from '../../../components/common/ModalSearchItem';

// const listFields = [
//   {
//     name: 'id',
//     label: 'label.id',
//   },
//   {
//     name: 'name',
//     label: 'label.name',
//     required: true,
//   },
// ];

const TaxTypeConfig = ({
  getTaxTypeConfig,
  createTaxTypeConfig,
  modifyTaxTypeConfig,
  getCcpDateTimeConfig,
  permissionsTaxConfig,
  ccpTime,
}) => {
  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({ taxTypes: [] });
  const [wasValidated, setValidate] = useState(false);
  // const [isModify, setIsModify] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);

  const { t } = useTranslation('common');

  let permissions = {};
  if (permissionsTaxConfig && permissionsTaxConfig.taxationModulePermissions) {
    const listPermission = permissionsTaxConfig.taxationModulePermissions;
    permissions = checkPermissionForAllPages({ listPermission });
  }

  const { modeGetTaxTypeConfig, modeCreateTaxTypeConfig, modeModifyTaxTypeConfig } = permissions;

  const onChangeData = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData.taxTypes[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(data);
    newData[name] = value;
    setData(newData);
  };

  const addNewItem = () => {
    try {
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.taxTypes) newData.taxTypes = [];
      if (newData)
        newData.taxTypes.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        name: null,
        description: null,
        serviceType: null,
        interfaceType: null,
        status: null,
        startDate: ccpTime || null,
      };
      newData.taxTypes = [payload, ...newData.taxTypes];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const doGetTaxTypeConfig = useCallback(() => {
    if (modeGetTaxTypeConfig) {
      setLoading(true);
      getTaxTypeConfig({}, ({ success, data }) => {
        setLoading(false);
        if (success && data) {
          setData(data);
          // if (data.id) setIsModify(true);
        }
      });
    }
  }, [getTaxTypeConfig, modeGetTaxTypeConfig]);

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    const payload = cloneDeep(data);
    if (payload && payload.id) {
      return modifyTaxTypeConfig({ id: payload.id, taxTypes: payload.taxTypes }, ({ success }) => {
        if (success) doGetTaxTypeConfig();
      });
    }
    return createTaxTypeConfig(
      { taxTypes: payload.taxTypes, id: payload.id || null, name: payload.name || null },
      ({ success }) => {
        if (success) doGetTaxTypeConfig();
      }
    );
  };

  const onToggleModalItemId = ({ item }) => {
    setItemSelect(item || null);
  };

  const onSelectItemId = id => {
    try {
      const newData = cloneDeep(data);
      const indexItem = newData.taxTypes.findIndex(val => val.index === itemSelect.index);
      newData.taxTypes[indexItem].itemId = id || null;
      setData(newData);
      setItemSelect(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return doGetTaxTypeConfig();
  }, [doGetTaxTypeConfig]);

  useEffect(() => {
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
  }, [ccpTime, getCcpDateTimeConfig]);

  if (!modeGetTaxTypeConfig) return '';

  const tablePlatforms = [
    {
      name: 'code',
      label: 'label.code',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.code}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index })}
          name="code"
          required
        />
      ),
    },
    {
      name: 'name',
      label: 'label.name',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index })}
          name="name"
          required
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'taxType',
      label: 'label.type',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.taxType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index })}
          name="taxType"
          type="select"
          tOptions="selections:taxType"
          menuPortalTarget
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'itemId',
      label: 'label.itemId',
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item) => {
        return (
          <GenericInput
            value={item.itemId}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="itemId"
            fa="fa fa-external-link"
            onClick={() => onToggleModalItemId({ item })}
            required
          />
        );
      },
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      style: { textAlign: 'center', minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.startDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeData({ name, value, index })}
          name="startDate"
          type="date"
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      style: { minWidth: '160px', textAlign: 'center' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.endDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeData({ name, value, index })}
          name="endDate"
          type="date"
        />
      ),
    },
  ];

  return (
    <div className="col-md-12 p-0 mb-30">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('usagePage:sidebar.usageMapping') },
          { name: t('baseConfigurationsPage:sidebar.taxTypeConfig') },
        ]}
      />
      <br />
      <div className="card card-statistics h-100 mr-3">
        <TitleFrom title={t('baseConfigurationsPage:sidebar.taxTypeConfig')} />
        <br />
        <form ref={formRef} noValidate className={`${wasValidated ? 'was-validated' : ''}`} onSubmit={onSubmit}>
          {/* <div className="col-md-12 row ml-2 mb-3">
            {listFields.map(val => (
              <GenericInput {...val} value={data[val.name] || null} readOnly={isModify} onChange={onChange} />
            ))}
          </div> */}
          <div className="form-group col-md-12 buttons-attibute pt-4">
            {modeCreateTaxTypeConfig === 2 && (
              <button type="button" onClick={addNewItem} className="button button-border black x-small">
                +
                {t('label.addNew')}
              </button>
            )}
            {modeModifyTaxTypeConfig === 2 && (
              <button
                type="submit"
                disabled={modeModifyTaxTypeConfig === 1 || !data?.taxTypes || !data?.taxTypes.length}
                className="button button-border x-small float-right mr-4"
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={doGetTaxTypeConfig}
              className="button mr-2 button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div className="group-collapsible">
            <DataTable
              isFixedHeaderTable
              columns={tablePlatforms}
              isLoading={isLoading}
              data={data?.taxTypes ? data?.taxTypes : []}
            />
          </div>
        </form>
      </div>
      <ModalSearchItem
        isOpen={!!itemSelect}
        onSelect={onSelectItemId}
        onCancel={onToggleModalItemId}
        selectedParentId={itemSelect && itemSelect.itemId ? itemSelect.itemId : ''}
        disabledSearchFields={['type']}
        defaultSearchForm={{ type: 'LIABILITY' }}
        defaultFilter={{ type: 'LIABILITY' }}
        isWithType
      />
    </div>
  );
};

TaxTypeConfig.propTypes = {
  getTaxTypeConfig: PropTypes.func.isRequired,
  createTaxTypeConfig: PropTypes.func.isRequired,
  modifyTaxTypeConfig: PropTypes.func.isRequired,
  getCcpDateTimeConfig: PropTypes.func.isRequired,
  permissionsTaxConfig: PropTypes.objectOf(PropTypes.any),
  ccpTime: PropTypes.string,
};

TaxTypeConfig.defaultProps = {
  permissionsTaxConfig: {},
  ccpTime: '',
};

const mapStateToProps = createStructuredSelector({
  ccpTime: makeGetCcpTime() || '',
  permissionsTaxConfig: makeGetPermissionsTaxConfig() || {},
});

export default connect(mapStateToProps, {
  getTaxTypeConfig,
  createTaxTypeConfig,
  modifyTaxTypeConfig,
  getCcpDateTimeConfig,
})(TaxTypeConfig);
