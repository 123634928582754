import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataCreate = parseOrderMutation(data, ['orderType', 'index', 'orderProration', 'alignToCycle', 'waiveCancel']);
  const createOrderReasonCodes = `createOrderReasonCodes(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createOrderReasonCodes} {
      id
    }
  }
`;
};
