import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles, saveOutputTemplateByType, getOutputTemplateByType } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { AgeingSummarySearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../../components/common';
import { tabelColumnExportAgeingSummary, sortAgeingSummary } from '../../constants';
import { getAgeingSummary, getListAllAgeingSummary } from '../../actions';
import { makeGetListAgeingSummary, makeGetListAllAgeingSummary } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let tableColumnsOutput = null;

class AgeingSummary extends Component {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: props?.defaultFilterSeted
        ? props.defaultFilterSeted
        : { groupByType: 'ACCOUNT', functionalCurrency: 'TRUE' },
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      listAgeingSummary: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      listAgeingSummary: props.listAgeingSummary,
      totalCount: getPageTotalCount({ ...state, items: props.listAgeingSummary }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetAgeingReport },
    } = this.props;
    if (modeGetAgeingReport) {
      this.doGetOutputTemplateByType();
      this.doGetAgeingSummary();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetAgeingSummary();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetAgeingSummary());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetAgeingSummary());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetAgeingSummary();
    });
  };

  doGetAgeingSummary = () => {
    const { filter, page, size, sorted } = this.state;
    const { getAgeingSummary, defaultFilterSeted } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.groupByType)
      newFilter.groupByType = defaultFilterSeted?.groupByType ? defaultFilterSeted?.groupByType : 'ACCOUNT';
    if (newFilter.functionalCurrency)
      newFilter.functionalCurrency = newFilter.functionalCurrency === 'TRUE' ? 'true' : 'false';
    // const newFilter = filter.ageingThreshold ? filter : { ...filter, ageingThreshold: 0 };
    const payload = {
      page: page + 1,
      size,
      filter: { ...newFilter },
      sort:
        !isEmpty(sorted) && sortAgeingSummary[sorted.sortCol]
          ? sortAgeingSummary[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getAgeingSummary(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistAgeingSummary = cb => {
    const { filter, sorted } = this.state;
    const { getListAllAgeingSummary, defaultFilterSeted } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.groupByType)
      newFilter.groupByType = defaultFilterSeted?.groupByType ? defaultFilterSeted?.groupByType : 'ACCOUNT';
    if (newFilter.functionalCurrency)
      newFilter.functionalCurrency = newFilter.functionalCurrency === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: { ...newFilter },
      sort: !isEmpty(sorted) ? sortAgeingSummary[sorted.sortCol][sorted.sortDir] : null,
    };
    getListAllAgeingSummary(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistAgeingSummary(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllAgeingSummary, t } = this.props;
        convertJson2Pdf({
          data: listAllAgeingSummary,
          t,
          title: t('reportPage:sidebar.ageingInvoice').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.ageingInvoice')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistAgeingSummary(() => {
      const { listAllAgeingSummary, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_AGEING_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.ageingInvoice')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.ageingInvoice')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllAgeingSummary,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllAgeingSummary,
          t,
          title: t('reportPage:sidebar.ageingInvoice').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.ageingInvoice')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.ageingInvoice')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('AGEING_INVOICE_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tabelColumnExportAgeingSummary,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listAgeingSummary,
      emails,
      filter,
      exportColumnsSave,
      isOpenModalExportConfig,
    } = this.state;
    const {
      listAllAgeingSummary,
      t,
      permissions: { modeGetAgeingReport, modeDownloadReport, modeGetMailReport },
      isCollection,
      onViewCollection,
      defaultFilterSeted,
    } = this.props;
    if (!modeGetAgeingReport) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    // let newTableColumns = [...tabelColumnExportAgeingSummary];
    let newTableColumns = tableColumnsOutput;

    if (isCollection) {
      newTableColumns = [
        {
          name: 'view',
          label: 'common:label.viewActions',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              disabled={!item?.collectionUnitId}
              onClick={() => {
                onViewCollection({ item });
              }}
            >
              {t('label.view')}
            </button>
          ),
        },
        ...newTableColumns,
      ];
    }
    return (
      <div className="col-md-12">
        {!isCollection && (
          <>
            <PageTitle
              linkTo={RouteNames.report.path}
              titleBtn="back"
              items={[
                { name: t('label.report'), url: RouteNames.report.path },
                { name: t('reportPage:sidebar.ageingInvoice') },
              ]}
            />
            <br />
          </>
        )}

        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.ageingInvoice')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <AgeingSummarySearchForm
                  onSubmit={this.onHandleSubmitSearch}
                  defaultFilterSeted={defaultFilterSeted}
                  defaultFilter={filter}
                />
              </div>
              <div>
                <div className="mt-3 row mb-4 pt-3 pb-3">
                  {!isCollection && (
                    <button
                      type="submit"
                      onClick={this.onToggleModalExportConfig}
                      className="button x-small mr-2 btn-config"
                    >
                      {/* {t('label.outputConfig')} */}
                      <i className="ti-list" />
                    </button>
                  )}
                  {!!modeDownloadReport && !isCollection && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && !isCollection && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.ageingInvoice').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllAgeingSummary,
                          t,
                          title: t('reportPage:sidebar.ageingInvoice').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.ageingInvoice')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && !isCollection && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listAgeingSummary}
                    />
                  )}
                </div>
                {isCollection && <br />}
                <DataTable
                  columns={newTableColumns}
                  data={listAgeingSummary && listAgeingSummary.length ? listAgeingSummary : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        {!isCollection && (
          <ModalWithItem
            onToggle={this.onToggleModalExportConfig}
            isOpen={isOpenModalExportConfig}
            wrapperClass="modal-config"
          >
            <div className="col-md-12 d-flex">
              <div className="column-config" onClick={this.onToggleModalExportConfig} />
              <div className="body-config">
                <DragDropConfigExportTemplate
                  onHandleSave={this.onHandleSaveConfig}
                  exportColumns={exportColumns}
                  onToggle={this.onToggleModalExportConfig}
                />
              </div>
            </div>
          </ModalWithItem>
        )}
      </div>
    );
  }
}

AgeingSummary.propTypes = {
  getAgeingSummary: PropTypes.func,
  getListAllAgeingSummary: PropTypes.func,
  listAllAgeingSummary: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

AgeingSummary.defaultProps = {
  getAgeingSummary: () => {},
  getListAllAgeingSummary: () => {},
  listAllAgeingSummary: [],
};

const mapStateToProps = createStructuredSelector({
  listAgeingSummary: makeGetListAgeingSummary() || [],
  listAllAgeingSummary: makeGetListAllAgeingSummary() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getAgeingSummary,
    getListAllAgeingSummary,
    uploadMultiPartFiles,
    saveOutputTemplateByType,
    getOutputTemplateByType,
  })(AgeingSummary)
);
