import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { searchRoles } from '../actions';

import ModalSelector from '../../../components/Modals/ModalSelector';
import { RoleSearchForm } from '../../../components/OperationHub';

class ModalParentPricing extends Component {
  state = {};

  render() {
    const { t } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'company',
          label: 'common:label.company',
          sortName: 'company',
        },

        {
          name: 'status',
          label: 'common:label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:permissionStatus')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={t('common:label.chooseRoles')}
        isOpen={this.props.isOpen}
        SearchForm={RoleSearchForm}
        searchItems={this.props.searchRoles}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectedParentId={this.props.selectedParentId}
      />
    );
  }
}

ModalParentPricing.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  searchRoles: PropTypes.func,
  errorMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(
    mapStateToProps,
    { searchRoles }
  )(ModalParentPricing)
);
