export default {
  sidebar: {
    newQuote: 'Nueva Cotización',
    searchList: 'Buscar & Listar',
    modifyQuote: 'Modificar Cotización',
    upgradeQuote: 'Cotización para Subir de Categoría ',
    downgradeQuote: 'Cotización para Bajar de Categoría ',
    searchQuote: 'Buscar Cotización',
  },
};
