import gql from 'graphql-tag';
import { parseToMutationRequestUser } from 'utils/utils';

export default data => {
  const isCreateUser = true;
  const dataCreate = parseToMutationRequestUser(data, ['index'], isCreateUser);
  const createUserGroup = `createUserGroup(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createUserGroup} {
        id
      }
    }
  `;
};
