import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getLogoBase64 } from '../../localStorages';

const pdfConfig = ({
  data,
  Header,
  title,
  mainHeader,
  fileName,
  fontSize,
  cellPadding,
  isFile,
  lineWidth,
  cellWidth,
}) => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'landscape'; // portrait or landscape
  const doc = jsPDF(orientation, unit, size);
  const headers = Header;
  const content = {
    startY: 70,
    head: mainHeader ? [mainHeader, headers] : [headers],
    body: data && data.length ? data : [],
    margin: { left: 5, right: 5, top: 40 },
    styles: {
      fontSize: fontSize || 6,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles: {
      fillColor: '#00A651',
      textColor: '#ffffff',
    },
    didDrawPage: data => {
      if (getLogoBase64()) {
        doc.addImage(getLogoBase64(), 'JPEG', data.settings.margin.left, 8, 40, 23);
      }
    },
  };
  doc.text(title, 340, 40);
  doc.autoTable(content);
  if (isFile) {
    const file = doc.output('blob');
    return file;
  }

  doc.save(fileName);
};

export default pdfConfig;
