import { cloneDeep } from 'lodash';
import { getLabelOptions } from '../utils';

const headersCSV = ({ t }) => [
  { displayName: t('label.id'), id: 'id' },
  { displayName: t('label.chargeType'), id: 'chargeType' },
  { displayName: t('label.name'), id: 'name' },
  { displayName: t('label.description'), id: 'description' },
  { displayName: t('label.serviceType'), id: 'serviceType' },
  { displayName: t('label.isChild'), id: 'isChild', isBool: true },
  { displayName: t('label.itemId'), id: 'itemId' },
  { displayName: t('label.serviceProductCode'), id: 'serviceProductCode' },
  { displayName: t('label.uom'), id: 'uom' },
  { displayName: t('label.uomName'), id: 'uomName' },
  { displayName: t('label.glAccount'), id: 'glAccount' },
  { displayName: t('label.taxCode'), id: 'taxCode' },
  { displayName: t('label.status'), id: 'status', fieldOption: 'statusPricing' },
  { displayName: t('label.createdDate'), id: 'createdDate' },
  { displayName: t('label.isParent'), id: 'isParent', isBool: true },
];

const dataCSV = ({ data, t, uomList }) => {
  if (!data || !data.length) return [];
  let newData = cloneDeep(data);
  if (headersCSV && newData && newData.length) {
    headersCSV({ t }).forEach(val => {
      if (val.isBool) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = newDt[val.id] ? 'true' : 'false';
          return newDt;
        });
      }
      if (val.isNumber) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = Number.parseFloat(newDt[val.id] || 0);
          return newDt;
        });
      }
      if (val.id === 'uom' && uomList && uomList.length) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = uomList.find(val => val.value === newDt.uom)
            ? uomList.find(val => val.value === newDt.uom).label
            : '';
          return newDt;
        });
      }
      if (val.fieldOption) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = getLabelOptions({ item: newDt, t, fieldName: 'status', fieldOption: val.fieldOption });
          return newDt;
        });
      }
    });
  }
  return newData;
};

export { headersCSV, dataCSV };
