const groupNamePermissions = {
  createBundle: 'CREATE_BUNDLE',
  modifyBundle: 'MODIFY_BUNDLE',
  approveBundle: 'APPROVE_BUNDLE',
  sellableBundle: 'SELLABLE_BUNDLE',
  nonSellableBundle: 'NON_SELLABLE_BUNDLE',
  sunsetBundle: 'SUNSET_BUNDLE',
  searchBundles: 'SEARCH_BUNDLE',
  getBundle: 'GET_BUNDLE',
  createPackage: 'CREATE_PACKAGE',
  modifyPackage: 'MODIFY_PACKAGE',
  approvePackage: 'APPROVE_PACKAGE',
  sellablePackage: 'SELLABLE_PACKAGE',
  nonSellablePackage: 'NON_SELLABLE_PACKAGE',
  sunsetPackage: 'SUNSET_PACKAGE',
  searchPackage: 'SEARCH_PACKAGE',
  getPackage: 'GET_PACKAGE',
  createDependency: 'CREATE_DEPENDENCY',
  modifyDependency: 'MODIFY_DEPENDENCY',
  getBundleDependency: 'GET_BUNDLE_DEPENDENCY',
  getPackageDependency: 'GET_PACKAGE_DEPENDENCY',
};

export { groupNamePermissions };
