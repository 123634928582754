import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['percent']);
  const modifyAccountServiceLocations = `modifyAccountServiceLocations(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyAccountServiceLocations} {
      id
    }
  }
`;
};
