import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionGetGatewayCanonical,
  checkPermissionGetPaymentMethodUrl,
  checkPermissionGetCustomDBConfig,
  checkPermissionGetTenantInfo,
  checkPermissionGetFolioResponse,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { checkPermissionForAllPages as allPermissionsTasks } from '../TaskManagement/CheckPermission';

class Sidebar extends PureComponent {
  render() {
    const { isExpand = true, permissionsTenant, permissionsCustomer, permissionsInvoice } = this.props;

    if (!isHasPermissionWithMenu({ permissions: permissionsTenant, listKeys: permissionList.tenantManagement }))
      return null;

    let modeGetTenantInfo = 0;
    let modeGetFolioResponse = 0;

    const sideConfig = [
      {
        isHaveExpand: false,
        mainTilte: 'navbar:operationsHub.subMain.tenantManagement',
        childs: [],
      },
      {
        isHaveExpand: true,
        mainTilte: 'tenantPage:tenantManagementPage.tenantData',
        href: RouteNames.tenantData.path,
        childs: [],
      },
    ];
    let configItem = [];
    const taskItem = [];
    let modeGetGatewayCanonical = 0;
    let modeGetPaymentMethodUrl = 0;
    let modeGetCustomDBConfig = 0;
    let permissionsTasks = {};

    if (permissionsTenant && permissionsTenant.tenantOnboardModulePermissions) {
      const listPermission = permissionsTenant.tenantOnboardModulePermissions;
      modeGetGatewayCanonical = checkPermissionGetGatewayCanonical({ listPermission });
      modeGetPaymentMethodUrl = checkPermissionGetPaymentMethodUrl({ listPermission });
      modeGetTenantInfo = checkPermissionGetTenantInfo({ listPermission });
    }
    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeGetCustomDBConfig = checkPermissionGetCustomDBConfig({ listPermission });
    }

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      permissionsTasks = allPermissionsTasks({ listPermission });
    }

    if (permissionsInvoice && permissionsInvoice.invoicingModulePermissions) {
      const listPermission = permissionsInvoice.invoicingModulePermissions;
      modeGetFolioResponse = checkPermissionGetFolioResponse({
        listPermission,
      });
    }

    if (modeGetCustomDBConfig) {
      configItem.push({
        icon: 'ti-settings',
        label: 'tenantPage:tenantManagementPage.idSequenceConfig',
        href: RouteNames.idSequenceConfig.path,
      });
    }

    if (permissionsTasks?.modeSearchTask) {
      taskItem.push({
        icon: 'ti-menu-alt',
        label: 'tenantPage:tenantManagementPage.productSupportTask',
        href: RouteNames.tenantTask.path,
      });
    }
    configItem = [
      ...configItem,
      {
        icon: 'ti-calendar',
        label: 'tenantPage:tenantManagementPage.schedulePattern',
        href: RouteNames.tenantSchedulePatternConfig.path,
      },
      {
        icon: 'ti-truck',
        label: 'tenantPage:tenantManagementPage.selfCareMarketing',
        href: RouteNames.tenantSelfCareMakerting.path,
      },
      {
        icon: 'fa fa-user-secret',
        label: 'tenantPage:tenantManagementPage.policyEnablers',
        href: RouteNames.tenantPlatformDefaults.path,
      },
    ];

    if (modeGetFolioResponse) {
      configItem.push({
        icon: 'ti-layers-alt',
        label: 'tenantPage:tenantManagementPage.folioResponseFile',
        href: RouteNames.folioResponseFile.path,
      });
    }

    const sidePropDetails = {
      isExpand: true,
      items: [
        ...sideConfig,
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.tenantConfig',
          childs: [
            ...configItem,
            {
              icon: 'ti-agenda',
              label: 'tenantPage:tenantManagementPage.templates',
              href: RouteNames.tenantTemplates.path,
            },
            ...taskItem,
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.crmGateway',
          href: RouteNames.tenantCrmGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.provisioningGateway',
          href: RouteNames.tenantProvisioningGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.taxGateway',
          href: RouteNames.tenantTaxGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.paymentGateway',
          href: RouteNames.paymentGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.financeGateway',
          href: RouteNames.tenantFinanceGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.documentGateway',
          href: RouteNames.documentGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.customerSupportGateway',
          href: RouteNames.customerSupportGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.operationGateway',
          href: RouteNames.operationGateway.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'tenantPage:tenantManagementPage.projectGateway',
          href: RouteNames.projectGateway.path,
          childs: [],
        },
      ],
    };

    sidePropDetails.isExpand = isExpand;
    sidePropDetails.items =
      modeGetGatewayCanonical === 0
        ? sidePropDetails.items.map(val => {
            const newVal = cloneDeep(val);
            if (newVal.childs)
              newVal.childs = newVal.childs.filter(
                i => i.label !== 'tenantPage:tenantManagementPage.requestResponseMapping'
              );

            return newVal;
          })
        : sidePropDetails.items;

    sidePropDetails.items =
      modeGetPaymentMethodUrl === 0
        ? sidePropDetails.items.map(val => {
            const newVal = cloneDeep(val);
            if (newVal.childs)
              newVal.childs = newVal.childs.filter(i => i.label !== 'tenantPage:tenantManagementPage.urlMapping');

            return newVal;
          })
        : sidePropDetails.items;

    if (!modeGetTenantInfo) return null;

    return <SideBar {...sidePropDetails} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsTenant = store.get('auth').toJS().permissionsApp.opsHubModules;
  const permissionsCustomer = store.get('auth').toJS().permissionsApp.customerHubModules;
  const permissionsInvoice = store.get('auth').toJS().permissionsApp.billingHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsTenant: permissionsTenant.length
      ? permissionsTenant.filter(a => a.moduleName === 'TENANT_ONBOARDING')[0]
      : {},
    permissionsCustomer: permissionsCustomer.length
      ? permissionsCustomer.filter(a => a.moduleName === 'CUSTOMER_MANAGEMENT')[0]
      : {},
    permissionsInvoice: permissionsInvoice.length
      ? permissionsInvoice.filter(a => a.moduleName === 'INVOICING')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  sideProps: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
