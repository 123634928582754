import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import ModalWithItem from '../ModalWithItem';
import DataTable from '../DataTable/Table';
import GenericInput from '../GenericInput';
import FormCollapse from '../../form/FormCollapse';
import { getBillableServicePricingHistory } from '../../../containers/App/actions';

const collapsibleMenuPricing = [
  {
    title: 'label.usageTypePricing',
    state: 'usageTypePricing',
  },
  {
    title: 'label.npaPricing',
    state: 'npaPricing',
  },
  {
    title: 'label.tierPricing',
    state: 'tierPricing',
  },
  {
    title: 'label.limitPricing',
    state: 'limitPricing',
  },
];

const columnsTypePricing = [
  {
    name: 'name',
    label: 'common:label.name',
  },
  {
    name: 'currency',
    label: 'common:label.currency',
  },
  {
    name: 'price',
    label: 'common:label.price',
  },
  {
    name: 'uom',
    label: 'common:label.unit',
  },
  {
    name: 'network',
    label: 'common:label.network',
  },
];

const columnsNpaPricing = [
  {
    name: 'npaCountry',
    label: 'common:label.npaCountry',
  },
  {
    name: 'npaId',
    label: 'common:label.npaId',
  },
  {
    name: 'eightHundredFlag',
    label: 'common:label.800Flag',
    render: (colName, item) => {
      if (item.eightHundredFlag) {
        return (
          <span>
            <i className="fa fa-check ti-flag-check-ico" />
          </span>
        );
      }
      return <span>{item.eightHundredFlag === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'price',
    label: 'common:label.price',
  },
];

const columnsTierPricing = [
  {
    name: 'minimum',
    label: 'common:label.tierMinimum',
  },
  {
    name: 'maximum',
    label: 'common:label.tierMaximum',
  },
  {
    name: 'price',
    label: 'common:label.price',
  },
];

const columnsLimitPricing = [
  {
    name: 'price',
    label: 'common:label.price',
  },
  {
    name: 'includedDID',
    label: 'common:label.includedDID',
  },
  {
    name: 'includedQuantity',
    label: 'common:label.includedQuantity',
  },
  {
    name: 'overage',
    label: 'common:label.overagePrice',
  },
  {
    name: 'commitment',
    label: 'common:label.commitment',
  },
  {
    name: 'priceOverCommitment',
    label: 'common:label.priceOverCommitment',
  },
];

const convertDateViewer = ({ orderDate, startDate, endDate, t }) => {
  let subTitleHistoryOrder = '';
  let subTitleHistoryValidity = `${t('label.validity')} (`;
  const monthYearT = t('selections:monthOfYearFull')();
  if (orderDate) {
    const monthOrderDate = moment(startDate).format('MMMM');
    const monthSelect = monthYearT.find(val => val.value.toLocaleUpperCase() === monthOrderDate.toLocaleUpperCase());
    subTitleHistoryOrder = `${t('label.orderDate')} - ${moment(orderDate).format('DD-MMMM')}`;
    if (monthSelect && monthSelect.value && subTitleHistoryOrder) {
      subTitleHistoryOrder = subTitleHistoryOrder.replace(
        monthSelect.value.toLocaleUpperCase(),
        monthSelect.label.toLocaleUpperCase()
      );
    }
  }
  if (startDate) {
    const monthStartDate = moment(startDate).format('MMMM');
    const monthSelect = monthYearT.find(val => val.value.toLocaleUpperCase() === monthStartDate.toLocaleUpperCase());
    subTitleHistoryValidity = `${subTitleHistoryValidity}${moment(startDate).format('DD-MMMM-YYYY')}`;
    if (monthSelect && monthSelect.value && subTitleHistoryValidity) {
      subTitleHistoryValidity = subTitleHistoryValidity.replace(
        monthSelect.value.toLocaleUpperCase(),
        monthSelect.label.toLocaleUpperCase()
      );
    }
  }
  if (endDate) {
    const monthEndDate = moment(endDate).format('MMMM');
    const monthSelect = monthYearT.find(val => val.value.toLocaleUpperCase() === monthEndDate.toLocaleUpperCase());
    subTitleHistoryValidity = `${subTitleHistoryValidity} - ${moment(endDate).format('DD-MMMM-YYYY')}`;

    if (monthSelect && monthSelect.value && subTitleHistoryValidity) {
      subTitleHistoryValidity = subTitleHistoryValidity.replace(
        monthSelect.value.toLocaleUpperCase(),
        monthSelect.label.toLocaleUpperCase()
      );
    }
  } else {
    subTitleHistoryValidity = `${subTitleHistoryValidity} - ${t('label.never')}`;
  }
  return { subTitleHistoryOrder, subTitleHistoryValidity };
};

class PricingModalOrder extends React.PureComponent {
  state = {
    activeTab: {},
    isOpenHistoryModal: false,
    dataPricingHistory: {},
    activeHistoryTab: {},
    activeHistorySubTab: {},
  };

  componentDidMount() {
    this.setState({
      activeTab: {},
      isOpenHistoryModal: false,
      dataPricingHistory: {},
      activeHistoryTab: {},
      activeHistorySubTab: {},
    });
  }

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleHistoryTab = activeTabName => {
    const { activeHistoryTab } = this.state;
    if (activeHistoryTab.name === activeTabName) {
      this.setState({
        activeHistoryTab: { name: activeTabName, isActive: !activeHistoryTab.isActive },
      });
    } else {
      this.setState({ activeHistoryTab: { name: activeTabName, isActive: true } });
    }
  };

  onToggleHistorySubTab = (activeTabName, index) => {
    const { activeHistorySubTab } = this.state;
    if (activeHistorySubTab.name === activeTabName && activeHistorySubTab.index === index) {
      this.setState({
        activeHistorySubTab: { name: activeTabName, isActive: !activeHistorySubTab.isActive, index },
      });
    } else {
      this.setState({ activeHistorySubTab: { name: activeTabName, isActive: true, index } });
    }
  };

  renderItemPricing = (item, isHistory) => {
    const { data } = this.props;
    const { dataPricingHistory } = this.state;
    const newData = isHistory ? dataPricingHistory : cloneDeep(data);
    switch (item.state) {
      case 'usageTypePricing':
        return (
          <div className="group-collapsible">
            <DataTable
              columns={columnsTypePricing}
              data={newData && newData[0] && newData[0].usageTypePricing ? newData[0].usageTypePricing : []}
            />
          </div>
        );
      case 'npaPricing':
        return (
          <div>
            <DataTable
              columns={columnsNpaPricing}
              data={newData && newData[0] && newData[0].specialPricings ? newData[0].specialPricings : []}
            />
          </div>
        );
      case 'tierPricing':
        return (
          <div>
            <DataTable
              columns={columnsTierPricing}
              data={newData && newData[0] && newData[0].tierPricing ? newData[0].tierPricing : []}
            />
          </div>
        );
      case 'limitPricing':
        return (
          <div className="col-md-12 row pt-3 pl-2">
            {columnsLimitPricing.map(val => (
              <GenericInput
                value={newData && newData[0] && newData[0].limitPricing ? newData[0].limitPricing[val.name] : null}
                onChange={() => {}}
                wrapperClass="col-md-4"
                readOnly
                {...val}
              />
            ))}
            {/* <DataTable
              columns={columnsLimitPricing}
              data={data && data[0] && data[0].limitPricing ? data[0].limitPricing : []}
            /> */}
          </div>
        );
      default:
        return null;
    }
  };

  renderItemPricingHistory = item => {
    const { dataPricingHistory, activeHistorySubTab } = this.state;
    const { t } = this.props;
    switch (item.state) {
      case 'usageTypePricing': {
        if (dataPricingHistory && dataPricingHistory.length) {
          return dataPricingHistory.map((dt, index) => {
            const { subTitleHistoryOrder, subTitleHistoryValidity } = convertDateViewer({
              orderDate: dt && dt?.orderDate,
              startDate: dt && dt?.startDate,
              endDate: dt && dt?.endDate,
              t,
            });
            return (
              <FormCollapse
                key={`${item.title}-${index}`}
                label="First Name"
                isActive={
                  activeHistorySubTab &&
                  item.state === activeHistorySubTab.name &&
                  activeHistorySubTab.isActive &&
                  index === activeHistorySubTab.index
                }
                title={(
                  <div>
                    <span className="">{subTitleHistoryOrder}</span>
                    <span className="validity-pricing">{`${subTitleHistoryValidity})`}</span>
                  </div>
)}
                state={item.state}
                isNoT
                onToggleTab={activeTabName => this.onToggleHistorySubTab(activeTabName, index)}
              >
                <div className="group-collapsible">
                  <DataTable columns={columnsTypePricing} data={dt?.usageTypePricing ? dt.usageTypePricing : []} />
                </div>
              </FormCollapse>
            );
          });
        }
        return '';
      }
      case 'npaPricing':
        if (dataPricingHistory && dataPricingHistory.length) {
          return dataPricingHistory.map((dt, index) => {
            const { subTitleHistoryOrder, subTitleHistoryValidity } = convertDateViewer({
              orderDate: dt && dt?.orderDate,
              startDate: dt && dt?.startDate,
              endDate: dt && dt?.endDate,
              t,
            });
            return (
              <FormCollapse
                key={`${item.title}-${index}`}
                label="First Name"
                isActive={
                  activeHistorySubTab &&
                  item.state === activeHistorySubTab.name &&
                  activeHistorySubTab.isActive &&
                  index === activeHistorySubTab.index
                }
                title={(
                  <div>
                    <span className="">{subTitleHistoryOrder}</span>
                    <span className="validity-pricing">{`${subTitleHistoryValidity})`}</span>
                  </div>
)}
                state={item.state}
                isNoT
                onToggleTab={activeTabName => this.onToggleHistorySubTab(activeTabName, index)}
              >
                <div className="group-collapsible">
                  <DataTable columns={columnsNpaPricing} data={dt?.specialPricings ? dt.specialPricings : []} />
                </div>
              </FormCollapse>
            );
          });
        }
        return '';
      // return (
      //    <div>
      //     <DataTable
      //       columns={columnsNpaPricing}
      //       data={newData && newData[0] && newData[0].specialPricings ? newData[0].specialPricings : []}
      //     />
      //   </div>
      // );
      case 'tierPricing':
        if (dataPricingHistory && dataPricingHistory.length) {
          return dataPricingHistory.map((dt, index) => {
            const { subTitleHistoryOrder, subTitleHistoryValidity } = convertDateViewer({
              orderDate: dt && dt?.orderDate,
              startDate: dt && dt?.startDate,
              endDate: dt && dt?.endDate,
              t,
            });
            return (
              <FormCollapse
                key={`${item.title}-${index}`}
                label="First Name"
                isActive={
                  activeHistorySubTab &&
                  item.state === activeHistorySubTab.name &&
                  activeHistorySubTab.isActive &&
                  index === activeHistorySubTab.index
                }
                title={(
                  <div>
                    <span className="">{subTitleHistoryOrder}</span>
                    <span className="validity-pricing">{`${subTitleHistoryValidity})`}</span>
                  </div>
)}
                state={item.state}
                isNoT
                onToggleTab={activeTabName => this.onToggleHistorySubTab(activeTabName, index)}
              >
                <div>
                  <DataTable columns={columnsTierPricing} data={dt?.tierPricing ? dt.tierPricing : []} />
                </div>
              </FormCollapse>
            );
          });
        }
        return '';
      // return (
      //   <div>
      //     <DataTable
      //       columns={columnsTierPricing}
      //       data={newData && newData[0] && newData[0].tierPricing ? newData[0].tierPricing : []}
      //     />
      //   </div>
      // );
      case 'limitPricing':
        if (dataPricingHistory && dataPricingHistory.length) {
          return dataPricingHistory.map((dt, index) => {
            const { subTitleHistoryOrder, subTitleHistoryValidity } = convertDateViewer({
              orderDate: dt && dt?.orderDate,
              startDate: dt && dt?.startDate,
              endDate: dt && dt?.endDate,
              t,
            });
            return (
              <FormCollapse
                key={`${item.title}-${index}`}
                label="First Name"
                isActive={
                  activeHistorySubTab &&
                  item.state === activeHistorySubTab.name &&
                  activeHistorySubTab.isActive &&
                  index === activeHistorySubTab.index
                }
                title={(
                  <div>
                    <span className="">{subTitleHistoryOrder}</span>
                    <span className="validity-pricing">{`${subTitleHistoryValidity})`}</span>
                  </div>
)}
                state={item.state}
                isNoT
                onToggleTab={activeTabName => this.onToggleHistorySubTab(activeTabName, index)}
              >
                <div className="col-md-12 row pt-3 pl-2">
                  {columnsLimitPricing.map(val => (
                    <GenericInput
                      value={dt?.limitPricing ? dt.limitPricing[val.name] : null}
                      onChange={() => {}}
                      wrapperClass="col-md-4"
                      readOnly
                      {...val}
                    />
                  ))}
                </div>
              </FormCollapse>
            );
          });
        }
        return '';
      // return (
      //   <div className="col-md-12 row pt-3 pl-2">
      //     {columnsLimitPricing.map(val => (
      //       <GenericInput
      //         value={newData && newData[0] && newData[0].limitPricing ? newData[0].limitPricing[val.name] : null}
      //         onChange={() => {}}
      //         wrapperClass="col-md-4"
      //         readOnly
      //         {...val}
      //       />
      //     ))}
      //     {/* <DataTable
      //       columns={columnsLimitPricing}
      //       data={data && data[0] && data[0].limitPricing ? data[0].limitPricing : []}
      //     /> */}
      //   </div>
      // );
      default:
        return null;
    }
  };

  onViewHistory = () => {
    const { data, getBillableServicePricingHistory } = this.props;
    if (data && data[0]) {
      getBillableServicePricingHistory(
        {
          serviceId: data[0]?.serviceId || null,
          accountId: data[0]?.accountId || null,
          omsInternalId: data[0]?.omsInternalId,
        },
        ({ success, data }) => {
          this.setState({ dataPricingHistory: success ? data || {} : {} });
          this.setState({ isOpenHistoryModal: !!success });
        }
      );
    }
  };

  onToggleHistoryModal = () => {
    this.setState({ isOpenHistoryModal: false, activeHistoryTab: {} });
  };

  render() {
    const { isOpen, t, onToggle, data } = this.props;
    const { activeTab, isOpenHistoryModal, dataPricingHistory, activeHistoryTab } = this.state;
    let newCollapsibleMenu = cloneDeep(collapsibleMenuPricing);
    let newCollapsibleMenuHistory = cloneDeep(collapsibleMenuPricing);
    if (!data || !data[0] || !data[0].usageTypePricing) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'usageTypePricing');
    }
    if (!data || !data[0] || !data[0].specialPricings) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'npaPricing');
    }
    if (!data || !data[0] || !data[0].tierPricing) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'tierPricing');
    }
    if (!data || !data[0] || !data[0].limitPricing) {
      newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'limitPricing');
    }

    if (!dataPricingHistory || !dataPricingHistory[0] || !dataPricingHistory[0].usageTypePricing) {
      newCollapsibleMenuHistory = newCollapsibleMenuHistory.filter(val => val.state !== 'usageTypePricing');
    }
    if (!dataPricingHistory || !dataPricingHistory[0] || !dataPricingHistory[0].specialPricings) {
      newCollapsibleMenuHistory = newCollapsibleMenuHistory.filter(val => val.state !== 'npaPricing');
    }
    if (!dataPricingHistory || !dataPricingHistory[0] || !dataPricingHistory[0].tierPricing) {
      newCollapsibleMenuHistory = newCollapsibleMenuHistory.filter(val => val.state !== 'tierPricing');
    }
    if (!dataPricingHistory || !dataPricingHistory[0] || !dataPricingHistory[0].limitPricing) {
      newCollapsibleMenuHistory = newCollapsibleMenuHistory.filter(val => val.state !== 'limitPricing');
    }

    const { subTitleHistoryOrder, subTitleHistoryValidity } = convertDateViewer({
      orderDate: data && data[0] && data[0]?.orderDate,
      startDate: data && data[0] && data[0]?.startDate,
      endDate: data && data[0] && data[0]?.endDate,
      t,
    });

    return (
      <>
        <ModalWithItem
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          modalTitle={t('label.pricingDetails')}
          isOpen={isOpen}
          onToggle={onToggle}
        >
          {newCollapsibleMenu &&
            newCollapsibleMenu.length > 0 &&
            newCollapsibleMenu.map(item => {
              return (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={activeTab && item.state === activeTab.name && activeTab.isActive}
                  // title={`${t(item.title)}  (${subTitle})` || ''}
                  title={(
                    <div style={{ width: '700px', display: 'flex' }}>
                      <div className="col-md-3 p-0">{t(item.title)}</div>
                      <div className="col-md-4 p-0 title-pricing-details">{subTitleHistoryOrder}</div>
                      <div className="col-md-5 p-0 title-pricing-details">{`${subTitleHistoryValidity})`}</div>
                    </div>
)}
                  isNoT
                  state={item.state}
                  onToggleTab={this.onToggleTab}
                >
                  {this.renderItemPricing(item)}
                </FormCollapse>
              );
            })}
          <div className="col-md-12 pt-3 pb-3">
            <button type="button" onClick={onToggle} className="button button-border black x-small float-right">
              {t('label.cancel')}
            </button>
            <button type="button" onClick={this.onViewHistory} className="button x-small mr-2 float-right">
              {t('label.viewHistory')}
            </button>
          </div>
        </ModalWithItem>
        <ModalWithItem
          modalTitle={t('label.pricingDetails')}
          isOpen={isOpenHistoryModal}
          onToggle={this.onToggleHistoryModal}
        >
          {newCollapsibleMenuHistory &&
            newCollapsibleMenuHistory.length > 0 &&
            newCollapsibleMenuHistory.map(item => {
              return (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={activeHistoryTab && item.state === activeHistoryTab.name && activeHistoryTab.isActive}
                  title={t(item.title) || ''}
                  state={item.state}
                  isNoT
                  onToggleTab={this.onToggleHistoryTab}
                >
                  {this.renderItemPricingHistory(item)}
                </FormCollapse>
              );
            })}
          <div className="col-md-12 pt-3 pb-3">
            <button
              type="button"
              onClick={this.onToggleHistoryModal}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
        </ModalWithItem>
      </>
    );
  }
}

PricingModalOrder.propTypes = {
  wrapperClass: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

PricingModalOrder.defaultProps = {
  label: '',
  name: '',
  wrapperClass: 'col-md-3',
  date: '',
  onChange: () => {},
};

export default withTranslation('common')(connect(null, { getBillableServicePricingHistory })(PricingModalOrder));
