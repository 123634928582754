/**
 * Collections selectors
 */

import { createSelector } from 'reselect';

const selectTaxation = state => state.get('billingReducer');

const makeFutureCycleCfg = () => createSelector(selectTaxation, customers => customers.get('futureCycleCfg'));

const makeGetAdvanceBillingConfig = () =>
  createSelector(selectTaxation, customers => customers.get('advanceBillingConfig'));

const makePendingBillingTriggerConfig = () =>
  createSelector(selectTaxation, customers => customers.get('pendingBillingTrigger'));

const makeGetEmployees = () => createSelector(selectTaxation, customers => customers.get('employees'));

const makeGetListALlEmployees = () => createSelector(selectTaxation, customers => customers.get('listALlEmployees'));

const makeGetClientProjects = () => createSelector(selectTaxation, customers => customers.get('clientProject'));

const makeGetListALlClientProjects = () =>
  createSelector(selectTaxation, customers => customers.get('listALlClientProject'));

export {
  makeFutureCycleCfg,
  makeGetAdvanceBillingConfig,
  makePendingBillingTriggerConfig,
  makeGetEmployees,
  makeGetListALlEmployees,
  makeGetClientProjects,
  makeGetListALlClientProjects,
};
