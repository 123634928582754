export default {
  sidebar: {
    createNewOrder: 'Crear nuevo Orden',
    searchList: 'Buscar & Listar',
    newOrder: 'Nueva Orden',
    modifyOrder: 'Modificar Orden',
    suspendOrder: 'Suspender Orden',
    resumeOrder: 'Reanudar Orden',
    cancelOrder: 'Cancelar Orden',
    renewOrder: 'Renovar Orden',
    upgradeOrder: 'Orden para Ascender de Categoría',
    downgradeOrder: 'Orden de Reducción de Categoría',
    trialSubsription: 'Suscripción de Prueba',
    optIn: 'Adhesión',
    optOut: 'Exclusión',
    orderSequence: 'Secuencia de Orden',
    provisioningSequence: 'Secuencia de Aprovisionamiento',
    // provisioningMapping: 'Mapeo de Aprovisionamiento',
    provisioningMapping: 'Datos de Aprovisionamiento',
    resonCode: 'Códigos de Motivo',
    provisioningAttributes: 'Atributos de Aprovisionamiento',
    attributes: 'Atributos',
    data: 'Datos',
    sequence: 'Secuencia',
    searchOrder: 'Buscar Orden',
    provisioningConfig: 'Configuración de Aprovisionamiento',
  },
};
