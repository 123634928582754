
import { fromJS } from 'immutable';

import * as types from "./constants";

// The initial state of the App
export const initialState = fromJS({
  sidebarExpanded: true 
});

function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case types.LAYOUT_TOGGLE_SIDEBAR:
      return state
        .set("sidebarExpanded", !state.toJS().sidebarExpanded)
    case types.LAYOUT_SHOW_SIDEBAR:
      return state.set("sidebarExpanded", true)
    case types.LAYOUT_HIDE_SIDEBAR:
      return state.set("sidebarExpanded", false)

    default:
      return state;
  }
}

export default layoutReducer;
