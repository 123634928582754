import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormWithTableItem } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { ArOpsConfigTable, ArOpsFormAdd } from '../../../components/ArOperationsHub';
import RouteNames from '../../App/RouteNames';
import { getArOpsConfigByType, updateArOpsConfigByType } from '../actions';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import Footer from '../../../components/Footer';
import {
  checkPermissionCreateReasonCodeConfig,
  checkPermissionModifyReasonCodeConfig,
  checkPermissionGetReasonCodeConfig,
} from '../CheckPermission';

class AROpsConfig extends React.PureComponent {
  state = {
    AROpsConfig: [],
    saveAROpsConfig: [],
    itemNew: {
      type: null,
      reasonCode: null,
      taxCode: null,
      gLAccount: null,
    },
    reasonSelected: {},
  };

  componentDidMount() {
    this.doGetArOpsConfigByType();
  }

  doGetArOpsConfigByType = () => {
    const { getArOpsConfigByType } = this.props;
    getArOpsConfigByType(response => {
      const listTable = this.getTableFromRespone(response, 'reasonCodes');
      this.setState({
        AROpsConfig: listTable,
        saveAROpsConfig: [...listTable],
        itemNew: {
          type: null,
          reasonCode: null,
          taxCode: null,
          gLAccount: null,
        },
      });
    });
  };

  getTableFromRespone = (result, key) => {
    if (!result) return [];

    return result[key] || [];
  };

  updateItemNewValue = ({ name, value }) => {
    const { itemNew } = this.state;
    itemNew[name] = value;
    this.setState({ itemNew: _.cloneDeep(itemNew) });
  };

  addNewReason = () => {
    const { AROpsConfig, itemNew } = this.state;
    AROpsConfig.push(itemNew);

    this.setState({
      AROpsConfig,
      itemNew: {
        type: null,
        reasonCode: null,
        taxCode: null,
        gLAccount: null,
      },
    });
  };

  onSelectReason = reasonSelectedRs => {
    const { reasonSelected } = this.state;
    if (reasonSelected === reasonSelectedRs) {
      this.setState({ reasonSelected: {} });
    } else {
      this.setState({ reasonSelected: reasonSelectedRs });
    }
  };

  // removeSelectedReason = () => {
  //   const { reasonSelected, AROpsConfig } = this.state;
  //   const newAROpsConfig = AROpsConfig.filter(el => el.index !== reasonSelected.index);

  //   this.setState({
  //     reasonSelected: {},
  //     AROpsConfig: newAROpsConfig,
  //   });
  // };
  removeSelectedReason = item => {
    const { AROpsConfig } = this.state;
    const newAROpsConfig = AROpsConfig.filter(el => el.index !== item.index);

    this.setState({
      reasonSelected: {},
      AROpsConfig: newAROpsConfig,
    });
  };

  onHandleChangeTable = (index, name, value) => {
    const { AROpsConfig } = this.state;
    AROpsConfig[index][name] = value;
  };

  handleClickApplyButton = () => {
    const { AROpsConfig, saveAROpsConfig } = this.state;
    const { updateArOpsConfigByType } = this.props;
    const removeRows = [];

    const reasonCodes = (AROpsConfig || []).map((item, index) => ({
      index: index + 1,
      taxCode: item.taxCode,
      gLAccount: item.gLAccount,
      type: item.type,
      reasonCode: item.reasonCode,
    }));

    (saveAROpsConfig || []).map(item => {
      const checkArray = reasonCodes.find(e => e.index === item.index);
      if (!checkArray) {
        removeRows.push({ index: item.index });
      }
      return null;
    });

    const data = {
      arOpsConfigType: 'REASON_CODES',
      reasonCodes: [...removeRows, ...reasonCodes],
    };

    updateArOpsConfigByType(data, response => {
      const listTable = response && response.reasonCodes ? response.reasonCodes : [];
      this.setState({
        AROpsConfig: listTable,
        saveAROpsConfig: [...listTable],
      });
    });
  };

  render() {
    const { AROpsConfig, itemNew, reasonSelected } = this.state;
    const { permissionsArOps, t } = this.props;
    const isEnableAddBtn = !!(itemNew.type && itemNew.reasonCode);
    const isEnableRemove = !reasonSelected.reasonCode;

    let modeCreateReasonCodeConfig = 0;
    let modeModifyReasonCodeConfig = 0;
    let modeGetReasonCodeConfig = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeCreateReasonCodeConfig = checkPermissionCreateReasonCodeConfig({ listPermission });
      modeModifyReasonCodeConfig = checkPermissionModifyReasonCodeConfig({ listPermission });
      modeGetReasonCodeConfig = checkPermissionGetReasonCodeConfig({ listPermission });
    }

    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteNames.arOperationsAdjustments.path}
          titleBtn={t('label.back')}
          titleRight={t('label.AROpsGL')}
        />
        {modeGetReasonCodeConfig !== 0 && (
          <div className="col-md-12 mb-30">
            <FormWithTableItem title={t('label.AROpsGL')}>
              <ArOpsConfigTable
                data={AROpsConfig}
                onRemove={this.removeSelectedReason}
                onHandleChangeTable={this.onHandleChangeTable}
              />
              {modeCreateReasonCodeConfig === 2 && (
                <ArOpsFormAdd
                  onChange={this.updateItemNewValue}
                  itemNew={itemNew}
                  onAddNew={this.addNewReason}
                  onRemove={this.removeSelectedReason}
                  isEnableAddBtn={isEnableAddBtn}
                  modeCreateReasonCodeConfig={modeCreateReasonCodeConfig}
                  isEnableRemove={isEnableRemove}
                />
              )}
              {modeModifyReasonCodeConfig === 2 && (
                <div className="col-md-12 p-3">
                  <button
                    type="button"
                    className="button button-border x-small float-right"
                    onClick={this.handleClickApplyButton}
                  >
                    {t('label.apply')}
                  </button>
                  <button
                    type="button"
                    onClick={() => this.doGetArOpsConfigByType()}
                    className="button button-border black x-small float-right mr-2"
                  >
                    {t('label.cancel')}
                  </button>
                </div>
              )}
            </FormWithTableItem>
          </div>
        )}
        <Footer className="footer-bottom" />
      </div>
    );
  }
}

AROpsConfig.propTypes = {
  getArOpsConfigByType: PropTypes.func,
  updateArOpsConfigByType: PropTypes.func,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

AROpsConfig.defaultProps = {
  getArOpsConfigByType: () => {},
  updateArOpsConfigByType: () => {},
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getArOpsConfigByType, updateArOpsConfigByType })(withRouter(AROpsConfig))
);
