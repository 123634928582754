import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'type',
    'status',
    'total',
    'nonPayingTotal',
    'billTotal',
    'selectivePendingBillFilter',
  ]);
  const runPendingBill = `runPendingBill(input: ${dataCreate})`;
  return gql`
  mutation {
    ${runPendingBill} {
      id
    }
  }
`;
};
