import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';
import orderNS from './constants/orderNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.order.userId = getUserId() || newData.userId || '';
  const dataCreate = parseToMutationRequest(newData, orderNS);
  const indexDataType = dataCreate.indexOf('dataType');
  const newDataCreate = indexDataType !== -1 ? `${dataCreate.replace(/dataType/gi, 'type')}` : dataCreate;
  const processAccountAndOrder = `processAccountAndOrder(input: ${newDataCreate})`;

  const qqlStr = gql`
  mutation {
    ${processAccountAndOrder} {
      orderId
    }
  }
`;
  return qqlStr;
};
