import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'taxType']);
  const modifyTaxTypeConfig = `modifyTaxTypeConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyTaxTypeConfig} {
      id
    }
  }
`;
};
