import React from 'react';
import PropsType from 'prop-types';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { PasswordField } from '../../../components/common';
import { getFirstTimeLogin, getPassword, setFirstTimeLogin } from '../../../localStorages';

const FieldModal = [
  {
    label: 'Old Password',
    name: 'oldPassword',
  },
  {
    label: 'New Password',
    name: 'newPassword',
  },
  {
    label: 'Confirm New password',
    name: 'confirmPassword',
  },
];

class ModalChangePassword extends React.Component {
  state = {
    isOpen: getFirstTimeLogin() === 'true',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    wasValidated: false,
  };

  onAccept = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const { handleChangePassword, t } = this.props;
    if (!oldPassword || !newPassword || !confirmPassword) return toast.error(t('message.mandatory'));
    if (oldPassword !== getPassword()) return toast.error(t('message.oldPassNotMatch'));
    if (newPassword !== confirmPassword) return toast.error(t('message.confirmPasswordIncorrectly'));
    if (newPassword === oldPassword) return toast.error(t('message.oldPassNotSame'));
    this.setState({ wasValidated: true });
    return handleChangePassword(newPassword, success => {
      if (success) this.setState({ isOpen: false });
      this.setState({ wasValidated: false });
    });
  };

  onCancelModal = () => {
    const { isOpen } = this.state;
    setFirstTimeLogin('false');
    this.setState({ isOpen: !isOpen });
  };

  onHandleChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { isOpen, wasValidated, ...state } = this.state;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>Change password on the first time login</ModalHeader>
        <ModalBody>
          <form className={`${wasValidated ? 'was-validated' : ''}`} noValidate>
            {FieldModal.map(item => (
              <PasswordField
                key={item.name}
                label={item.label}
                name={item.name}
                wrapperClass="col-md-12"
                required
                value={state[item.name]}
                onChange={this.onHandleChange}
              />
            ))}
          </form>
        </ModalBody>
        <ModalFooter>
          <button type="button" onClick={this.onAccept} className="button button-border x-small float-right">
            Update
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalChangePassword.propTypes = {
  handleChangePassword: PropsType.func.isRequired,
};

ModalChangePassword.defaultProps = {};

export default withTranslation('common')(ModalChangePassword);
