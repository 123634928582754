import gql from 'graphql-tag';

export default ({ type, scheduleDate }) => {
  const getJobErrors = `getJobErrors(input: {
    type: ${type}
    scheduleDate: "${scheduleDate}"
  })`;
  return gql`
    query {
      ${getJobErrors} {
        index
        jobId
        name
        userId
        type
        status
        countError
        jobErrorGroupList {
          reason
          count
        }
      }
    }
  `;
};
