import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['expiryDays', 'accountType', 'trialLevel', 'trialType'])}`
    : '';
  const getTrialSubscriptionReport = `getTrialSubscriptionReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getTrialSubscriptionReport} {
        accountId
        company
        firstName
        lastName
        accountType
        sellingCompany
        subscriptionId
        effectiveDate
        startDate
        endDate
        initialTermUnit
        initialTerm
        renewalTermUnit
        renewalTerm
        trialTermUnit
        trialTerm
        trialLevel
        trialType
        status
      }
    }
  `;
};
