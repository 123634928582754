import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { searchOrdersConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import SearchForm from './SearchForm';

class ModalSelectOrder extends PureComponent {
  doSearchOrders = (payload, cb) => {
    const { filter, ...rest } = payload;
    const { searchOrdersConfig, isOpen, accountId } = this.props;
    if (isOpen)
      searchOrdersConfig({ ...rest, filter: { ...filter, accountId: accountId || null } }, ({ success, data }) => {
        if (cb)
          cb({
            success,
            data: success && data ? data : [],
          });
      });
  };

  render() {
    const orderColumns = [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'type',
        label: 'common:label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:types')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'crmId',
        label: 'common:label.crmId',
      },
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
      },
      {
        name: 'accountId',
        label: 'common:label.accountId',
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        render: (colName, item) => {
          const slt = t('selections:accountType')().find(val => val.value === item.accountType);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'orderSource',
        label: 'label.source',
        render: (colName, item) => {
          const slt = t('selections:orderSource')().find(val => val.value === item.orderSource);
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'userId',
        label: 'common:label.userId',
        sortable: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'submittedDate',
        label: 'common:label.submittedDate',
      },
    ];

    const { isOpen, onSelect, onCancel, selectedParentId, t, defaultSearchParams } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectOrder')}
        isOpen={isOpen}
        SearchForm={SearchForm}
        searchItems={this.doSearchOrders}
        tableColumns={orderColumns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName="id"
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalSelectOrder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchOrdersConfig: PropTypes.func,
};

ModalSelectOrder.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  searchOrdersConfig: () => {},
  selectedParentId: '',
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(connect(mapStateToProps, { searchOrdersConfig })(ModalSelectOrder));
