import gql from 'graphql-tag';

export default id => {
  const getGatewayApiMapById = `getGatewayApiMapById(input: {id: "${id}" })`;
  return gql`
    query {
      ${getGatewayApiMapById} {
        id
        gatewayName
        providerName
        apiName
        objectType
        payloadTemplatePath
        request {
          index
          sourceName
          canonicalName
        }
        response {
          index
          sourceName
          canonicalName
        }
      }
    }
  `;
};
