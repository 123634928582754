import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.accountId'),
  t('label.organization'),
  t('label.firstName'),
  t('label.lastName'),
  t('label.accountType'),
  t('label.trialTerm'),
  t('label.trialTermUnit'),
  t('label.trialLevel'),
  t('label.trialType'),
  t('label.subscriptionId'),
  t('label.effectiveDate'),
  t('label.sellingCompany'),
  t('label.startDate'),
  t('label.endDate'),
  t('label.initialTermUnit'),
  t('label.initialTerm'),
  t('label.renewalTermUnit'),
  t('label.renewalTerm'),
  t('label.status'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.accountId ? item.accountId : '');
      newItem.push(item.company ? item.company : '');
      newItem.push(item.firstName ? item.firstName : '');
      newItem.push(item.lastName ? item.lastName : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'accountType' }));
      newItem.push(item.trialTerm ? item.trialTerm : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'trialTermUnit' }));
      newItem.push(getLabelOptions({ item, t, fieldName: 'trialType', fieldOption: 'trialTypes' }));
      newItem.push(getLabelOptions({ item, t, fieldName: 'trialLevel' }));
      newItem.push(item.subscriptionId ? item.subscriptionId : '');
      newItem.push(item.effectiveDate ? item.effectiveDate : '');
      newItem.push(item.sellingCompany ? item.sellingCompany : '');
      newItem.push(item.startDate ? item.startDate : '');
      newItem.push(item.endDate ? item.endDate : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'initialTermUnit' }));
      newItem.push(item.initialTerm ? item.initialTerm : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'renewalTermUnit', fieldOption: 'initialTermUnit' }));
      newItem.push(item.renewalTerm ? item.renewalTerm : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'subscriptionStatus' }));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 5, ...rest });
};

export default convertJson2Pdf;
