import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  GenericInput,
  TitleFrom,
  TablePagination,
  DataTable,
  ButtonExport,
  ExcelExport,
  SwitchExport,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getPageTotalCount } from '../../../utils/utils';
import { GLAccountSearchForm } from '../../../components/RevenueHub';
import { getChartOfAccount, modifyChartOfAccount, createChartOfAccount, searchAllGLAccounts } from '../actions';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelGLAccounts';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfGLAccounts';
import { makeGetAllGLAccounts } from '../selectors';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';

const sortTypes = {
  accountNumber: {
    asc: 'accountNumber_ASC',
    desc: 'accountNumber_DESC',
  },
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};
class GLAccounts extends React.PureComponent {
  state = {
    data: { chartOfAccounts: [] },
    wasValidated: false,
    formValid: true,
    dataSubmit: { chartOfAccounts: [] },
    defaultData: { chartOfAccounts: [] },
    isSearching: true,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
  };

  formRef = React.createRef();

  buttonRef = React.createRef();

  componentDidMount() {
    this.doGetGLAccounts();
    // this.checkExistData();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetGLAccounts = () => {
    const { filter, page, size, sorted } = this.state;
    const newFilter = cloneDeep(filter);
    const { getChartOfAccount } = this.props;
    if (newFilter.notesOffset) newFilter.notesOffset = newFilter.notesOffset === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true, data: { chartOfAccounts: [] } });
    getChartOfAccount(payload, ({ data }) => {
      this.setState({
        data: data ? { chartOfAccounts: data } : { chartOfAccounts: [] },
        dataSubmit: { chartOfAccounts: [] },
        id: data && data[0] && data[0].id ? data[0].id : null,
        isModify: !!data,
        defaultData: data || { chartOfAccounts: [] },
        isSearching: false,
        totalCount: getPageTotalCount({ ...this.state, items: data }),
      });
    });
    // this.checkExistData();
  };

  // checkExistData = () => {
  //   const { getGLAccounts } = this.props;
  //   getGLAccounts({ showActiveOnly: false }, ({ data }) => {
  //     this.setState({
  //       isModify: !!data,
  //       id: data ? data[0].id : null,
  //       defaultData: data || [],
  //     });
  //   });
  // };

  addNewItem = () => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      // let lastIndex = 0;
      if (!newData || !newData.chartOfAccounts) newData.chartOfAccounts = [];
      // if (newData)
      //   newData.chartOfAccounts.forEach(val => {
      //     if (val.index > lastIndex) lastIndex = val.index;
      //   });
      const payload = {
        index: null,
        name: '',
        accountNumber: '',
        type: '',
        detailType: null,
        description: null,
        notesAccount: null,
        notesName: null,
      };
      // newData.chartOfAccounts.push(payload);
      newData.chartOfAccounts = [payload, ...newData.chartOfAccounts];
      dataSubmit.chartOfAccounts[newData.length - 1] = payload;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit, id } = this.state;
    const { modifyChartOfAccount, createChartOfAccount } = this.props;
    if (isModify) {
      return modifyChartOfAccount(
        { chartOfAccounts: dataSubmit.chartOfAccounts.filter(val => !!val), id },
        ({ success }) => {
          if (success) this.doGetGLAccounts();
        }
      );
    }
    return createChartOfAccount({ ...data, name: 'COA', userId: 'userId' }, ({ success }) => {
      if (success) this.doGetGLAccounts();
    });
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData.chartOfAccounts[index][name] = value;
      if (!dataSubmit.chartOfAccounts) dataSubmit.chartOfAccounts = [];
      if (!dataSubmit.chartOfAccounts[index])
        dataSubmit.chartOfAccounts[index] = { index: newData.chartOfAccounts[index].index };
      dataSubmit.chartOfAccounts[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetGLAccounts());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetGLAccounts());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetGLAccounts());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetGLAccounts());
  };

  doSearchAllGLAccounts = cb => {
    const { filter, sorted } = this.state;
    const { searchAllGLAccounts } = this.props;
    const newFilter = cloneDeep(filter);
    if (newFilter.notesOffset) newFilter.notesOffset = newFilter.notesOffset === 'TRUE' ? 'true' : 'false';
    const payload = {
      page: 1,
       size: PAGE_SIZE_DOWNLOAD,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchAllGLAccounts(payload, () => {
      if (cb) cb();
    });
  };

  onExport = () => {
    this.doSearchAllGLAccounts(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllGLAccounts, t } = this.props;
        convertJson2Pdf({
          data: listAllGLAccounts,
          t,
          title: 'GL ACCOUNTS',
          fileName: `gl_accounts_${moment(new Date()).format('YYYY_MM_DD')}`,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  render() {
    const { t, permissions, listAllGLAccounts } = this.props;
    const { modeCreateGLAccountConfig, modeGetGLAccountConfig, modeModifyGLAccountConfig } = permissions;
    if (!modeGetGLAccountConfig) return '';
    const { data, wasValidated, isSearching, sorted, page, size, totalCount, isActivePdf } = this.state;
    const listFieldsGLAccounts = [
      {
        name: 'accountNumber',
        label: 'label.accountNumber',
        style: { minWidth: '120px' },
        sortable: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountNumber}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="accountNumber"
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        sortable: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="name"
          />
        ),
      },

      {
        name: 'type',
        label: 'label.type',
        sortable: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.type}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="type"
          />
        ),
      },
      {
        name: 'detailType',
        label: 'label.detailType',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.detailType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="detailType"
            type="select"
            tOptions="selections:itemType"
            menuPortalTarget
            isCreateOption
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="description"
            type="textarea"
          />
        ),
      },
      {
        name: 'notesAccount',
        label: 'label.notesAccount',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.notesAccount}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="notesAccount"
          />
        ),
      },
      {
        name: 'notesName',
        label: 'label.notesName',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.notesName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="notesName"
          />
        ),
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('revenuePage:sidebar.GLAccounts')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('revenuePage:sidebar.GLAccounts')} subClass="border-bottom" />
            <div className="col-md-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <div className="repeater-file">
                    <div>
                      <GLAccountSearchForm onSubmit={this.onHandleSubmit} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 p-0">
                <div className="mt-3 d-flex float-rightpt-3">
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet="GL ACCOUNTS"
                      multiDataSet={convertJson2Sheet({ data: listAllGLAccounts, t })}
                      fileName={`gl_accounts_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                </div>
              </div>
            </div>
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateGLAccountConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.addNewItem}
                  >
                    +
                    {t('label.addNewGLAccount')}
                  </button>
                )}
                {modeModifyGLAccountConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeModifyGLAccountConfig === 1 || !data || !data.chartOfAccounts}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetGLAccounts}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable
                columns={listFieldsGLAccounts}
                data={data ? data.chartOfAccounts : []}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
                isFixedHeaderTable
              />
            </form>
          </div>
          <div className="mb-30 mt-3">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

GLAccounts.propTypes = {
  getChartOfAccount: PropTypes.func,
  modifyChartOfAccount: PropTypes.func,
};

GLAccounts.defaultProps = {
  getChartOfAccount: () => {},
  modifyChartOfAccount: () => {},
};

const mapStateToProps = createStructuredSelector({
  listAllGLAccounts: makeGetAllGLAccounts() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getChartOfAccount,
    modifyChartOfAccount,
    createChartOfAccount,
    searchAllGLAccounts,
  })(withRouter(GLAccounts))
);
