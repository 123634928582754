import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import SearchTaskList from './SearchTaskList';
import Sidebar from './Sidebar';
import { makeGetPermissionsCustomerManagement } from '../App/selectors';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class TaskManagement extends PureComponent {
  render() {
    const { permissionsCustomer } = this.props;
    let permissions = {};

    if (!isHasPermissionWithMenu({ permissions: permissionsCustomer, listKeys: permissionList.taskManagement }))
      return null;

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    const { modeSearchTask } = permissions || {};

    const childItem = [];

    if (modeSearchTask) {
      childItem.push({
        icon: 'ti-menu-alt',
        label: 'taskPage:sidebar.tasks',
        href: RouteNames.tasksList.path,
      });
    }

    const sideProps = {
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'navbar:operationsHub.subMain.taskManagement',
          childs: [
            ...childItem,
            {
              icon: 'ti-folder',
              label: 'taskPage:sidebar.tickets',
              href: RouteNames.ticketsList.path,
              disable: true,
            },
          ],
        },
      ],
    };

    const newSideProps = cloneDeep(sideProps);

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar sideProps={newSideProps} />
          <div className="content-wrapper">
            <Switch>
              <Route
                exact
                path={RouteNames.tasksList.path}
                component={props => <SearchTaskList {...props} permissions={permissions} />}
              />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'taskReducer', reducer });
const withSaga = injectSaga({ key: 'taskSaga', saga });

const mapStateToProps = createStructuredSelector({
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
});

TaskManagement.propTypes = {
  permissionsCustomer: PropTypes.objectOf(PropTypes.any),
};

TaskManagement.defaultProps = {
  permissionsCustomer: {},
};

const withConnect = connect(mapStateToProps, null);

const newTaskManagement = compose(withReducer, withSaga, withConnect)(TaskManagement);

export default withTranslation(['taskPage', 'common'])(newTaskManagement);
