import gql from 'graphql-tag';

export default () => gql`
  query {
    getUsageStreamMap(dummy: "Hello") {
      id
      active
      ratingType
      usageProcessStreamSeqCfg {
        streamName
        index
        active
        usageProcessModulesSeqCfg {
          moduleName
          active
          logContainer
          index
        }
      }
    }
  }
`;
