import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size, cloneDeep, orderBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getOrderReasonCodes, modifyOrderReasonCodes, createOrderReasonCodes } from '../actions';
import { makeGetOrderReasonCodes } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import ModalSearchItem from '../../../components/common/ModalSearchItem';
import { makeGetPermissionsOrderManagement } from '../../App/selectors';
import {
  checkPermissionGetOrderReasonCode,
  checkPermissionModifyOrderReasonCode,
  checkPermissionCreateOrderReasonCode,
} from '../CheckPermission';

class OrderReasonCodes extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    configOrderReasonCodesList: [],
    wasValidated: false,
    fileNameOptions: [],
    isOpenModalItem: false,
    itemSelected: null,
    indexSelected: null,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.orderReasonCode) return null;
    return {
      configOrderReasonCodesList:
        props.orderReasonCode && props.orderReasonCode.orderReasonCodes ? props.orderReasonCode.orderReasonCodes : [],
      oldValue: props.orderReasonCode,
      dataSubmit: [],
    };
  }

  componentDidMount() {
    this.doGetOrderReasonCodes();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index, cb }) => {
    const { configOrderReasonCodesList, dataSubmit } = this.state;
    const newData = cloneDeep(configOrderReasonCodesList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    if (value === 'TRUE' || value === 'FALSE') {
      newData[indexItemChange][name] = value === 'TRUE' ? 'true' : 'false';
    } else newData[indexItemChange][name] = value;
    this.setState({ configOrderReasonCodesList: newData }, () => {
      if (cb) cb();
    });
  };

  doGetOrderReasonCodes = () => {
    const { getOrderReasonCodes } = this.props;
    getOrderReasonCodes('', () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewReasonCode = () => {
    let indexNewItem = 0;
    const { configOrderReasonCodesList, dataSubmit } = this.state;
    const newData = cloneDeep(configOrderReasonCodesList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      reasonCode: '',
      description: null,
      orderType: 'CANCEL',
      orderProration: 'true',
      alignToCycle: null,
      waiveCancel: null,
      endDate: null,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ configOrderReasonCodesList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { configOrderReasonCodesList, dataSubmit } = this.state;
    const newData = cloneDeep(configOrderReasonCodesList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ configOrderReasonCodesList: newData, dataSubmit });
  };

  onSelectItemId = (id, row) => {
    const { indexSelected } = this.state;
    this.onChangeTable({
      index: indexSelected,
      name: 'itemId',
      value: id,
      cb: () => {
        this.setState({ indexSelected: null, isOpenModalItem: false, itemSelected: null });
      },
    });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { configOrderReasonCodesList } = this.state;
    const { modifyOrderReasonCodes, createOrderReasonCodes, orderReasonCode } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (orderReasonCode && orderReasonCode.id) {
      const newPayload = {
        id: orderReasonCode.id,
        orderReasonCodes: configOrderReasonCodesList,
      };
      return modifyOrderReasonCodes(newPayload, ({ success }) => {
        if (success) this.doGetOrderReasonCodes();
      });
    }

    const newPayloadCreate = cloneDeep(configOrderReasonCodesList).map(item => {
      const newItem = item;
      delete newItem.index;
      return newItem;
    });
    createOrderReasonCodes({ orderReasonCodes: newPayloadCreate }, ({ success }) => {
      if (success) this.doGetOrderReasonCodes();
    });
  };

  render() {
    const { configOrderReasonCodesList, isSearching, wasValidated, fileNameOptions, isOpenModalItem } = this.state;
    const { orderReasonCode, permissionOrder, t } = this.props;
    let modeGetOrderReasonCodes = 0;
    let modeModifyOrderReasonCodes = 0;
    let modeCreateOrderReasonCodes = 0;

    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermissionsPayment = permissionOrder.orderModulePermissions;
      modeGetOrderReasonCodes = checkPermissionGetOrderReasonCode({
        listPermission: listPermissionsPayment,
      });
      modeCreateOrderReasonCodes = checkPermissionCreateOrderReasonCode({
        listPermission: listPermissionsPayment,
      });
      modeModifyOrderReasonCodes = checkPermissionModifyOrderReasonCode({
        listPermission: listPermissionsPayment,
      });
    }
    if (!modeGetOrderReasonCodes) return '';

    const OrderReasonCodesColumns = [
      {
        name: 'orderType',
        label: t('label.orderType'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.orderType}
            tOptions="selections:types"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="orderType"
            required
          />
        ),
      },
      {
        name: 'reasonCode',
        label: t('label.reasonCode'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.reasonCode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="reasonCode"
            required
            isSupportTooltip
          />
        ),
      },
      {
        name: 'description',
        label: t('label.description'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="description"
            required
            isSupportTooltip
          />
        ),
      },
      {
        name: 'orderProration',
        label: t('label.orderProration'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.orderProration === 'true' || item.orderProration === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            wrapperClass="col-md-12"
            name="orderProration"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'alignToCycle',
        label: t('label.alignToCycle'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.alignToCycle === 'true' || item.alignToCycle === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            wrapperClass="col-md-12"
            name="alignToCycle"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'waiveCancel',
        label: t('label.waiveCancel'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.waiveCancel === 'true' || item.waiveCancel === true ? 'TRUE' : 'FALSE'}
            type="select"
            tOptions="selections:selectBool"
            wrapperClass="col-md-12"
            name="waiveCancel"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'endDate',
        label: t('label.endDate'),
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.endDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="endDate"
            type="date"
          />
        ),
      },
      // {
      //   name: 'remove',
      //   label: t('label.remove'),
      //   render: (colName, item) => {
      //     return (
      //       <div className="form-group col-md-12">
      //         <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
      //           <i className="fa fa-trash" />
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ];
    const newDataOnlyViewInTable =
      configOrderReasonCodesList && configOrderReasonCodesList.length
        ? orderBy(
            configOrderReasonCodesList.filter(item => size(item) > 1),
            ['index'],
            ['asc']
          )
        : [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.searchAndList.path}
          titleBtn={t('label.back')}
          items={[{ name: t('orderPage:sidebar.resonCode'), url: RouteNames.searchAndList.path }]}
        />
        <FormWithTableItem title={t('label.orderResonCode')}>
          <form
            onSubmit={this.hanldeSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <br />
            <div>
              <DataTable columns={OrderReasonCodesColumns} data={newDataOnlyViewInTable} isLoading={isSearching} />
            </div>
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateOrderReasonCodes === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={this.onAddNewReasonCode}
                >
                  +
                  {t('label.addNewReasonCode')}
                </button>
              )}
              {modeModifyOrderReasonCodes === 2 && (
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                >
                  {orderReasonCode && orderReasonCode.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetOrderReasonCodes}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </FormWithTableItem>
        <ModalSearchItem
          isOpen={isOpenModalItem}
          onSelect={this.onSelectItemId}
          onCancel={() => {
            this.setState({ isOpenModalItem: false, indexSelected: null, itemSelected: null });
          }}
          defaultSearchParams={{ type: 'RECEIVABLE' }}
          selectedParentId={
            this.state && this.state.itemSelected && this.state.itemSelected.itemId
              ? this.state.itemSelected.itemId
              : ''
          }
        />
      </div>
    );
  }
}

OrderReasonCodes.propTypes = {
  orderReasonCode: PropTypes.objectOf(PropTypes.any),
  getOrderReasonCodes: PropTypes.func.isRequired,
  createOrderReasonCodes: PropTypes.func.isRequired,
  modifyOrderReasonCodes: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

OrderReasonCodes.defaultProps = {
  orderReasonCode: {},
  permissionsPayment: {},
};
const mapStateToProps = createStructuredSelector({
  // OrderReasonCodes: makeGetAdvanceBillingConfig() || {},
  orderReasonCode: makeGetOrderReasonCodes() || {},
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

const newOrderReasonCodes = connect(mapStateToProps, {
  getOrderReasonCodes,
  modifyOrderReasonCodes,
  createOrderReasonCodes,
})(OrderReasonCodes);

export default withTranslation('common')(newOrderReasonCodes);
