export default {
  sidebar: {
    config: 'Configuración',
    dashboard: 'Dashboard',
    userPreferences: 'Preferencias Del Usuario',
    myDashboard: 'Mi Dashboard',
    customerDashboards: 'Dashboards de Clientes',
    orderDashboards: 'Dashboards de Ordenes',
    subscriptionDashboards: 'Dashboards de Suscripción',
    usageDashboards: 'Tableros de Uso',
    billingDashboards: 'Dashboards de Facturación',
    paymentsDashboards: 'Dashboards de Pagos',
    arOpsDashboards: 'Dashboards de Operaciones de CxC',
    collectionDashboards: 'Dashboards de Cobranza',
    revenueDashboards: 'Dashboards de Ingresos',
    jobsDashboards: 'Dashboards de Trabajos',
    newCustomer: 'Nuevo Cliente',
    customerChurn: 'Rotación de Clientes',
    trialToPaidConversion: 'Conversión de prueba a paga',
    subscriptionCancellations: 'Cancelaciones De Suscripciones',
    billedRevenue: 'Ingresos Facturados',
    billedCustomer: 'Cliente Facturado',
    paymentSuccess: 'Pago Exitoso',
    paymentSuspense: 'Suspensión de Pago',
    paymentFailed: 'Pago Fallido',
    accountsWithFailedPayment: 'Cuentas Con Pago Fallido',
    collection030Days: 'Cobranza 0-30 Días',
    collection3060Days: 'Cobranza 30-60 Días',
    collection6090Days: 'Cobranza 60-90 Días',
    collectionOver90Days: 'Cobranza >90 Días',
    totalRevenue: 'Total Ingresos',
    recognizedRevenue: 'Ingresos Reconocidos',
    failedOrder: 'Orden Fallida',
    submittedOrder: 'Orden Enviada',
    jobsFailed: 'Trabajos Fallidos',
    billsFailed: 'Facturación Fallida',
    invoicesFailed: 'Facturas Fallidas',
    totalJobs: 'Ingresos Reconocidos',
    // totalCreditAdjustments: 'Ajustes Totales De Crédito',
    totalCreditAdjustments: 'Ajustes de Crédito',
    // totalDisputes: 'Disputas Totales',
    totalDisputes: 'Disputas',
    // totalSettlements: 'Asentamientos Totales',
    totalSettlements: 'Asentamientos',
    usageTransaction: 'Transacciones De Uso Recibidas',
    ratedUsageTransaction: 'Transacción de Uso Tasada',
    totalWriteOff: 'Cancelación Neta',
    acitveSubscriptions: 'Suscripciones Activas',
    revenueType: 'Tipo de Ingresos',
    productCompany: 'Empresa de Producto',
    accountType: 'Tipo de Cuenta',
    sellingCompany: 'Empresa Vendedora',
    debitAdjustments: 'Ajustes de Débito',
    revenueForecast: 'Pronóstico de Ingresos',
    monthlyRecurringRevenue: 'Ingreso Mensual Recurrente',
    telecomTrafficDashboard: 'Dashboard de Tráfico de Telecomunicaciones',
    telecomTraffic: 'Tráfico de Telecomunicaciones',
    folioSummary: 'Resumen de Folio',
  },
};
