import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'arOpsType',
        'amount',
        'taxTreatment',
        'arCrDrType',
        'arThreshold',
        'accountType',
        'paymentMethod',
        'arLevel',
        'reason',
      ])}`
    : '';
  const getArOpsReport = `getArOpsReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getArOpsReport} {
        id
        invoiceId
        accountId
        firstName
        lastName
        company
        creditNotesId
        processDate
        arLevel
        reason
        taxTreatment
        arCrDrType
        amount
        summaryAmount
        addOnTaxAmount
        addOnTaxRate
        taxAmount
        taxRate
        accountType
        arOpsType
        paymentMethod
      }
    }
  `;
};
