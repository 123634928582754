export const GET_TENANT_CONFIG = './GET_TENANT_CONFIG';
export const GET_TENANT_CONFIG_SUCCESS = './GET_TENANT_CONFIG_SUCCESS';
export const MODIFY_TENANT = './MODIFY_TENANT';
export const CREATE_TENANT = './CREATE_TENANT';
export const MODIFY_TENANT_SUCCESS = './MODIFY_TENANT_SUCCESS';
export const GET_CCP_PROPERTIES = 'GET_CCP_PROPERTIES';
export const GET_CCP_PROPERTIES_SUCCESS = 'GET_CCP_PROPERTIES_SUCCESS';
export const MODIFY_CCP_PROPERTIES = 'MODIFY_CCP_PROPERTIES';
export const GET_PAYMENT_METHOD_URL = 'GET_PAYMENT_METHOD_URL';
export const GET_PAYMENT_METHOD_URL_SUCCESS = 'GET_PAYMENT_METHOD_URL_SUCCESS';
export const MODIFY_PAYMENT_METHOD_URL = 'MODIFY_PAYMENT_METHOD_URL';
export const GET_CUSTOM_ATTRIBUTES_TENANT = 'app/customers/getCustomAttributesTenant';
export const MODIFY_CUSTOM_ATTRIBUTES_TENANT = 'app/customers/modifyCustomAttributesTenant';
export const CREATE_CUSTOM_ATTRIBUTES_TENANT = 'app/customers/createCustomAttributesTenant';
export const GET_GATEWAY_CANONICAL_CONFIG_BY_NAME = 'GET_GATEWAY_CANONICAL_CONFIG_BY_NAME';
export const GET_GATEWAY_CANONICAL_CONFIG_BY_NAME_SUCCESS = 'GET_GATEWAY_CANONICAL_CONFIG_BY_NAME_SUCCESS';
export const GET_GATEWAY_CANONICAL_BY_OBJECT_TYPE = 'GET_GATEWAY_CANONICAL_BY_OBJECT_TYPE';
export const MODIFY_GETWAY_CANONICAL_CONFIG = 'MODIFY_GETWAY_CANONICAL_CONFIG';
export const GET_GATEWAY_API_MAP_BY_NAME = 'GET_GATEWAY_API_MAP_BY_NAME';
export const GET_GATEWAY_API_MAP_BY_NAME_SUCCESS = 'GET_GATEWAY_API_MAP_BY_NAME_SUCCESS';
export const GET_GETEWAY_API_MAP_BY_ID = 'GET_GETEWAY_API_MAP_BY_ID';
export const GET_GETEWAY_API_MAP_BY_ID_SUCCESS = 'GET_GETEWAY_API_MAP_BY_ID_SUCCESS';
export const MODIFY_GETEWAY_API_MAPPING = 'MODIFY_GETEWAY_API_MAPPING';
export const CREATE_GETEWAY_API_MAPPING = 'CREATE_GETEWAY_API_MAPPING';
export const GET_API_BY_GETEWAY_NAME = 'GET_API_BY_GETEWAY_NAME';
export const SAVE_SELF_CARE_CONFIG = 'SAVE_SELF_CARE_CONFIG';
export const GET_TENANT_SELF_CARE_CONFIG = 'GET_TENANT_SELF_CARE_CONFIG';
export const MODIFY_TENANT_SELF_CARE_CONFIG = 'MODIFY_TENANT_SELF_CARE_CONFIG';
export const GET_ID_SEQUENCE_CONFIG = 'GET_ID_SEQUENCE_CONFIG';
export const GET_ID_SEQUENCE_CONFIG_SUCCESS = 'GET_ID_SEQUENCE_CONFIG_SUCCESS';
export const MODIFY_ID_SEQUENCE_CONFIG = 'MODIFY_ID_SEQUENCE_CONFIG';
export const CREATE_ID_SEQUENCE_CONFIG = 'CREATE_ID_SEQUENCE_CONFIG';
export const GET_TENANT_DATA_CONFIG = 'GET_TENANT_DATA_CONFIG';
export const GET_TENANT_DATA_CONFIG_SUCCESS = 'GET_TENANT_DATA_CONFIG_SUCCESS';
export const GET_TENANT_PROFILE_CONFIG = 'GET_TENANT_PROFILE_CONFIG';
export const GET_TENANT_PROFILE_CONFIG_SUCCESS = 'GET_TENANT_PROFILE_CONFIG_SUCCESS';
export const GET_TENANT_MERCHANT_DATA_CONFIG = 'GET_TENANT_MERCHANT_DATA_CONFIG';
export const GET_TENANT_MERCHANT_DATA_CONFIG_SUCCESS = 'GET_TENANT_MERCHANT_DATA_CONFIG_SUCCESS';
export const GET_TENANT_MERCHANT_DATA_PROJECT_CONFIG = 'GET_TENANT_MERCHANT_DATA_PROJECT_CONFIG';
export const GET_TENANT_MERCHANT_DATA_PAYMENT_CONFIG = 'GET_TENANT_MERCHANT_DATA_PAYMENT_CONFIG';
export const GET_TENANT_MERCHANT_DATA_TAX_CONFIG = 'GET_TENANT_MERCHANT_DATA_TAX_CONFIG';
export const GET_TENANT_MERCHANT_DATA_FINANCE_CONFIG = 'GET_TENANT_MERCHANT_DATA_FINANCE_CONFIG';
export const GET_TENANT_MERCHANT_DATA_PROVISIONING_CONFIG = 'GET_TENANT_MERCHANT_DATA_PROVISIONING_CONFIG';
export const GET_TENANT_MERCHANT_DATA_CUSTOMER_SUPPORT_CONFIG = 'GET_TENANT_MERCHANT_DATA_CUSTOMER_SUPPORT_CONFIG';
export const GET_TENANT_MERCHANT_DATA_DOCUMENT_CONFIG = 'GET_TENANT_MERCHANT_DATA_DOCUMENT_CONFIG';
export const GET_TENANT_MERCHANT_DATA_OPERATION_CONFIG = 'GET_TENANT_MERCHANT_DATA_OPERATION_CONFIG';
export const GET_TENANT_MERCHANT_DATA_CRM_CONFIG = 'GET_TENANT_MERCHANT_DATA_CRM_CONFIG';
export const GET_TENANT_FINANCE_GATEWAY = 'GET_TENANT_FINANCE_GATEWAY';
export const GET_TENANT_CRM_GATEWAY = 'GET_TENANT_CRM_GATEWAY';
export const GET_TENANT_CUSTOMER_SPORT_GATEWAY = 'GET_TENANT_CUSTOMER_SPORT_GATEWAY';
export const GET_TENANT_DOCUMENT_GATEWAY = 'GET_TENANT_DOCUMENT_GATEWAY';
export const GET_TENANT_OPERATION_GATEWAY = 'GET_TENANT_OPERATION_GATEWAY';
export const GET_TENANT_PAYMENT_GATEWAY = 'GET_TENANT_PAYMENT_GATEWAY';
export const GET_TENANT_PROJECT_GATEWAY = 'GET_TENANT_PROJECT_GATEWAY';
export const GET_TENANT_PROVISION_GATEWAY = 'GET_TENANT_PROVISION_GATEWAY';
export const GET_TENANT_TAX_GATEWAY = 'GET_TENANT_TAX_GATEWAY';
export const SEARCH_SCHEDULE_PATTERN = 'app/collection/SEARCH_SCHEDULE_PATTERN';
export const CREATE_SCHEDULE_PATTERN = 'app/collection/CREATE_SCHEDULE_PATTERN';
export const MODIFY_SCHEDULE_PATTERN = 'app/collection/MODIFY_SCHEDULE_PATTERN';
export const GET_SCHEDULE_PATTERN_BY_ID = 'app/collection/GET_SCHEDULE_PATTERN_BY_ID';
export const GET_GATEWAY_DATA = 'app/collection/GET_GATEWAY_DATA';
export const GET_GATEWAY_DATA_SUCCESS = 'app/collection/GET_GATEWAY_DATA_SUCCESS';

export const GET_TEMPLATE = 'app/tenant/GET_TEMPLATE';
export const CREATE_TEMPLATE = 'app/tenant/CREATE_TEMPLATE';
export const MODIFY_TEMPLATE = 'app/tenant/MODIFY_TEMPLATE';

export const GET_FOLIO_RESPONSE = 'app/tenant/GET_FOLIO_RESPONSE';
export const CREATE_FOLIO_RESPONSE = 'app/tenant/CREATE_FOLIO_RESPONSE';
export const MODIFY_FOLIO_RESPONSE = 'app/tenant/MODIFY_FOLIO_RESPONSE';
