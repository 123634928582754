import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import DashboardFormConfig from './DashboardFormConfig';
import { getUserPreferences, modifyUserPreferences, createUserPreferences } from '../actions';
import { makeGetDashboardTileConfig } from '../selectors';
import { makeGetPermissionsDashboard } from '../../App/selectors';
import { getUserId } from '../../../localStorages';
import { Breadcrumb } from '../../../components/common';
import { checkPermissionSaveUserPreferences, checkPermissionGetUserPreferences } from '../CheckPermission';

class UserPreferences extends Component {
  state = {
    isCreate: false,
  };

  componentDidMount() {
    const { getUserPreferences } = this.props;
    getUserPreferences('', ({ isCreate }) => {
      this.setState({ isCreate: !!isCreate });
    });
  }

  onSubmit = ({ listItem }) => {
    const { isCreate } = this.state;
    const { modifyUserPreferences, createUserPreferences, listConfig, getUserPreferences } = this.props;
    const newDashboardTypeListType = listItem.map((e, idx) => ({
      index: idx + 1,
      dashboardType: e.dashboardType,
    }));

    if (isCreate) {
      return createUserPreferences({ userId: getUserId(), dashboards: newDashboardTypeListType }, ({ success }) => {
        if (success) {
          getUserPreferences();
          this.setState({ isCreate: false });
        }
      });
    }
    const dashboardTypeLength = newDashboardTypeListType.length;
    if (listConfig && listConfig.length > 0) {
      listConfig.forEach(jb => {
        if (jb.index > dashboardTypeLength) newDashboardTypeListType.push({ index: jb.index });
      });
    }
    return modifyUserPreferences({ userId: getUserId(), dashboards: newDashboardTypeListType }, ({ success }) => {
      if (success) getUserPreferences();
    });
  };

  render() {
    const { listConfig, t, permissionsDashboard } = this.props;
    let modeSaveUserPreferences = 0;
    let modeGetUserPreferences = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeSaveUserPreferences = checkPermissionSaveUserPreferences({ listPermission });
      modeGetUserPreferences = checkPermissionGetUserPreferences({ listPermission });
    }

    if (modeGetUserPreferences === 0) return '';
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userManagementUser.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('label.dashboardConfig') }]} />
            </div>
          </div>
        </div>
        {(!listConfig || listConfig.length === 0) && (
          <div className="text-center">{t('message.dashboardNoConfigMessage')}</div>
        )}
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.dashboardConfig')}</h5>
              </div>
              <DashboardFormConfig
                listConfig={listConfig && listConfig.length ? listConfig : []}
                onChangeValueForm={this.onSubmit}
                modeSaveUserPreferences={modeSaveUserPreferences}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserPreferences.propTypes = {
  listConfig: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  getUserPreferences: PropTypes.func.isRequired,
  createUserPreferences: PropTypes.func.isRequired,
  modifyUserPreferences: PropTypes.func.isRequired,
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

UserPreferences.defaultProps = {
  listConfig: [],
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  listConfig: makeGetDashboardTileConfig() || [],
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});
export default withTranslation('common')(
  connect(mapStateToProps, { getUserPreferences, createUserPreferences, modifyUserPreferences })(UserPreferences)
);
