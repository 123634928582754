/**
 * WriteOff Value Not String
 */

export default [
  'type',
  'reason',
  'source',
  'taxRule',
  'amount',
  'percent',
  'recoveryAmount',
  'status',
  'accountWriteoff',
];
