import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class PaymentsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.paymentDate',
      name: 'paymentDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:statusPayment',
      isClearable: true,
    },
    {
      label: 'common:label.paymentRefId',
      name: 'paymentRefId',
    },
    {
      label: 'common:label.invoiceId',
      name: 'invoiceUnitId',
    },
  ];

  btnSpan = 'col-md-12';
}

PaymentsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

PaymentsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(PaymentsSearchForm);
