import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const payload = parseToMutationRequest(data, ['serviceType']);
  const modifyPrepaidSystemOffers = `modifyPrepaidSystemOffers(input: ${payload})`;
  return gql`
  mutation {
    ${modifyPrepaidSystemOffers} {
      id
      topupOfferId
      topupOfferName
      paymentOfferId
      paymentOfferName
      burnRateOfferId
      burnRateOfferName
      serviceType
    }
  }
`;
};
