import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  revenueMilestone: [],
  revenueMilestoneItem: [],
  exchangeRate: [],
  exchangeRateId: [],
  exchangeRateExportId: [],
  allGLAccountsList: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_REVENUE_MILESTONE_SUCCESS:
      return state.set('revenueMilestone', action.payload);

    case types.GET_REVENUE_MILESTONE_BY_ID_SUCCESS:
      return state.set('revenueMilestoneItem', action.payload);

    case types.SEARCH_EXCHANGE_RATE_SUCCESS:
      return state.set('exchangeRate', action.payload);

    case types.GET_EXCHANGE_RATE_BY_ID_SUCCESS:
      return state.set('exchangeRateId', action.payload);

    case types.EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS:
      return state.set('exchangeRateExportId', action.payload);

    case types.SEARCH_ALL_GL_ACCOUNTS_SUCCESS:
      return state.set('allGLAccountsList', action.payload);

    default:
      return state;
  }
}
