import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, t } = this.props;
    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: true,
          mainTilte: t('label.manageProfile'),
          childs: [],
        },
      ],
    };
    return <SideBar {...sideProps} />;
  }
}

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  sideProps: {},
};

// export default Sidebar;
const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };

  return {
    isExpand: sidebarExpanded,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));
