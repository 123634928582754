import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AccordionGroup, Accordion } from '../../../components/common';
import Permission from './Permission';

const buildPermission = (mod, data) => {
  const permissions =
    mod &&
    mod.permissions.map((pm, index) => {
      const existpm =
        (data && data[mod.moduleFieldName] && data[mod.moduleFieldName].find(d => d.permission === pm)) || {};
      return {
        index: index + 1,
        moduleName: mod.moduleName,
        permission: pm,
        type: existpm && existpm.type ? existpm.type : 'NONE',
        roleLimit: existpm && existpm.roleLimit,
      };
    });
  return permissions;
};
const buildModulePermissions = (config, data) => {
  // console.log(config, data)
  const permissionKeys = {};
  config &&
    config.childs.map(m => {
      permissionKeys[m.moduleFieldName] = null;
    });
  const hub = config.childs.map((mod, index) => {
    return {
      index: index + 1,
      ...permissionKeys,
      hubName: mod.hubName,
      moduleName: mod.moduleName,
      [mod.moduleFieldName]: buildPermission(
        mod,
        data && data.length > 0 && data.find(d => d.moduleName === mod.moduleName)
      ),
    };
  });
  return hub;
};
class HubModule extends Component {
  state = {
    data: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.data) return null;
    const enabled = props.data && props.data.length > 0;
    return {
      enabled,
      data: props.config && props.data && buildModulePermissions(props.config || {}, props.data),
      oldData: props.data || [],
    };
  }

  onClick = (e, hub) => {
    e.preventDefault();
    const { moduleName } = this.state;
    if (moduleName !== hub.code) {
      this.setState({ moduleName: hub.code });
    } else {
      this.setState({ moduleName: null });
    }
  };

  onChangePermissions = (md, db) => {
    const { data } = this.state;
    const { onChangeHub } = this.props;
    const idx = data.findIndex(d => d.moduleName === md.moduleName);
    if (idx >= 0) {
      data[idx][md.code] = db;
      this.setState({ data }, () => onChangeHub(data));
    }
  };

  render() {
    const { data } = this.state;
    const { config } = this.props;

    return (
      <AccordionGroup>
        {config.childs.map((md, index) => {
          const moduleData = data && data.length > 0 && data.find(d => d.moduleName === md.moduleName);
          return (
            <Accordion
              key={`${md.code}`}
              title={md.title}
              isOpen={this.state.moduleName === md.code}
              onClick={e => this.onClick(e, md)}
            >
              <Permission
                permissions={moduleData ? moduleData[md.moduleFieldName] : []}
                onChangePermissions={d => this.onChangePermissions(md, d)}
              />
            </Accordion>
          );
        })}
      </AccordionGroup>
    );
  }
}

HubModule.propTypes = {
  onChangeHub: PropTypes.func,
  data: PropTypes.any,
  config: PropTypes.any,
};

HubModule.defaultProps = {};
export default HubModule;
