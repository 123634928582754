import React from 'react';
import PropsType from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Switch } from '../../../common';

class ModalQuestion extends React.Component {
  state = {};

  render() {
    const { isOpen, onChangeSwitch, isActiveSwitch, onSubmit, t } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalBody>
          <Switch
            onChange={onChangeSwitch}
            wrapperClass={isActiveSwitch ? 'switch-active' : 'switch-non-active'}
            title={t('label.createCustomizedRecords')}
            checked={isActiveSwitch}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            {t('label.submit')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalQuestion.propTypes = {
  onSubmit: PropsType.func.isRequired,
  onChangeSwitch: PropsType.func.isRequired,
  // onAcceptModal: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  isActiveSwitch: PropsType.bool,
};

ModalQuestion.defaultProps = {
  isActiveSwitch: false,
};

export default withTranslation('common')(ModalQuestion);
