import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'active',
    'ratingType',
    'streamName',
    'index',
    'active',
    'moduleName',
    'logContainer',
  ]);
  const modifyUsageProcMapConfig = `modifyUsageProcMapConfig(input: ${dataModify})`;

  return gql`
  mutation {
    ${modifyUsageProcMapConfig} {
      id
    }
  }
`;
};
