import flagUS from '../Assets/images/icons/flag-us-round.png';
import flagSpain from '../Assets/images/icons/flag-spain-round.png';

const listLangue = [
  {
    key: 'en-US',
    name: 'English',
    flag: flagUS,
  },
  {
    key: 'es',
    name: 'Español',
    flag: flagSpain,
  },
];

const itemLangSelected = key => {
  const item = listLangue.find(item => item.key === key);
  return item || listLangue[0];
};

const listLangNotSelect = key => {
  const item = listLangue.filter(item => item.key !== key);
  return item;
};

export { itemLangSelected, listLangNotSelect };
