import gql from 'graphql-tag';

export default type => {
  const getPaymentConfigByType = `getPaymentConfigByType(input: {paymentConfigType:${type}})`;
  return gql`
    query {
      ${getPaymentConfigByType} {
        id
        paymentConfigType
        paymentMethods {
          index
          method
        }
        paymentTerms {
          index
          paymentTerm
          days
          offset
          paymentWorkingDay
        }
      }
    }
  `;
};

// paymentMerchants {
//   index
//   provider
//   token
//   id
// }