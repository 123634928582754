import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { getRateUnitMapCfg, modifyRateUnitMapCfg, createRateUnitMapCfg, getRateUnit } from '../../actions';
import { makeGetListRateUnitMap } from '../../selectors';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept } from '../../../../components/common';
import { getIndexData } from '../../../../utils/utils';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';
import {
  checkPermissionCreateRateUnitMapConfig,
  checkPermissionModifyRateUnitMapConfig,
  checkPermissionGetRateUnitMapConfig,
} from '../../CheckPermission';

class RateUnitMap extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    listRateUnitMap: [],
    isSearching: true,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    rateUnitConfig: [],
  };

  componentDidMount() {
    this.doGetRateUnitMapCfg();
    const { getRateUnit } = this.props;
    getRateUnit(({ data, success }) => {
      if (success) {
        this.setState({ rateUnitConfig: data && data.rateUnits && data.rateUnits.length ? data.rateUnits : [] });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { listRateUnitMap } = this.props;
    if (
      listRateUnitMap &&
      nextProps.listRateUnitMap &&
      nextProps.listRateUnitMap.rateUnitSvcTxnMapListCfg &&
      listRateUnitMap !== nextProps.listRateUnitMap.rateUnitSvcTxnMapListCfg
    ) {
      this.setState({
        listRateUnitMap: nextProps.listRateUnitMap.rateUnitSvcTxnMapListCfg,
        defaultDataFromAPI: nextProps.listRateUnitMap,
        dataSubmit: [],
      });
    }
  }

  onChangeTable = ({ name, value, index }) => {
    const { listRateUnitMap, dataSubmit } = this.state;
    const newData = cloneDeep(listRateUnitMap);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ listRateUnitMap: newData });
  };

  doGetRateUnitMapCfg = () => {
    const { getRateUnitMapCfg } = this.props;
    getRateUnitMapCfg(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id) });
    });
  };

  onAddNewRateUnitMap = () => {
    let indexNewItem = 0;
    const { listRateUnitMap, dataSubmit } = this.state;
    let newData = cloneDeep(listRateUnitMap);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      serviceType: '',
      transactionType: '',
      usageClass: '',
      rateUnitCode: '',
    };
    newData = [newDataItem, ...newData];
    // newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ listRateUnitMap: newData, dataSubmit });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = ({ index }) => {
    try {
      const { listRateUnitMap, dataSubmit } = this.state;
      const newData = cloneDeep(listRateUnitMap);
      newData[getIndexData({ index, data: newData })] = {
        index: newData[getIndexData({ index, data: newData })].index,
      };
      if (getIndexData({ index, data: dataSubmit }) > -1) {
        dataSubmit[getIndexData({ index, data: newData })] = {
          index: newData[getIndexData({ index, data: newData })].index,
        };
      } else {
        dataSubmit.push({
          index: newData[getIndexData({ index, data: newData })].index,
        });
      }
      this.setState({ listRateUnitMap: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHanldeSubmit = () => {
    const { dataSubmit, isModify, defaultDataFromAPI } = this.state;
    const { modifyRateUnitMapCfg, createRateUnitMapCfg } = this.props;

    if (isModify) {
      const newPayloadModify = {
        rateUnitSvcTxnMapListCfg: dataSubmit,
        id: defaultDataFromAPI.id,
      };
      modifyRateUnitMapCfg(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(dataSubmit).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        rateUnitSvcTxnMapListCfg: dataRemoveIndex,
      };
      createRateUnitMapCfg(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetProductFamilyConfig();
        }
      });
    }
  };

  render() {
    const { listRateUnitMap, isSearching, isOpenModalRemoveItem, isModify, rateUnitConfig } = this.state;
    const { t, permissionsPricing } = this.props;

    let modeCreateRateUnitMapConfig = 0;
    let modeModifyRateUnitMapConfig = 0;
    let modeGetRateUnitMapConfig = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeCreateRateUnitMapConfig = checkPermissionCreateRateUnitMapConfig({ listPermission });
      modeModifyRateUnitMapConfig = checkPermissionModifyRateUnitMapConfig({ listPermission });
      modeGetRateUnitMapConfig = checkPermissionGetRateUnitMapConfig({ listPermission });
    }

    if (!modeGetRateUnitMapConfig) return null;
    const creditProfileColumns = [
      {
        name: 'serviceType',
        label: 'label.serviceType',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item) => (
          <GenericInput
            value={item.serviceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="serviceType"
            type="select"
            menuPortalTarget
            tOptions="selections:serviceType"
          />
        ),
      },
      {
        name: 'transactionType',
        label: 'label.transactionType',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.transactionType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="transactionType"
            type="select"
            menuPortalTarget
            tOptions="selections:transactionType"
          />
        ),
      },
      {
        name: 'usageClass',
        label: 'label.usageClass',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.usageClass}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="usageClass"
          />
        ),
      },
      {
        name: 'rateUnitCode',
        label: 'label.rateUnit',
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.rateUnitCode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="rateUnitCode"
            type="select"
            menuPortalTarget
            options={
              rateUnitConfig && rateUnitConfig.length
                ? sortBy(
                    rateUnitConfig.map(val => ({ label: val.code, value: val.code })),
                    ['label']
                  )
                : []
            }
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataRemoveIndex =
      listRateUnitMap && listRateUnitMap && listRateUnitMap.length
        ? listRateUnitMap.filter(item => size(item) > 1)
        : [];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: `${t('label.service')} - ${t('baseConfigurationsPage:sidebar.rateUnitMap')}` },
          ]}
        />
        <FormWithTableItem
          title={`${t('label.service')} - ${t('baseConfigurationsPage:sidebar.rateUnitMap')}`}
          subClass="border-bottom"
        >
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateRateUnitMapConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewRateUnitMap}>
                +
                {t('label.addNewRateUnitMap')}
              </button>
            )}
            {modeModifyRateUnitMapConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetRateUnitMapCfg()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={creditProfileColumns}
              data={newDataRemoveIndex}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message="Are you sure you want to delete this Item?"
        />
      </div>
    );
  }
}

RateUnitMap.propTypes = {
  getRateUnitMapCfg: PropTypes.func.isRequired,
  getRateUnit: PropTypes.func.isRequired,
  modifyRateUnitMapCfg: PropTypes.func.isRequired,
  createRateUnitMapCfg: PropTypes.func.isRequired,
  listRateUnitMap: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

RateUnitMap.defaultProps = {
  listRateUnitMap: [],
};

const mapStateToProps = createStructuredSelector({
  listRateUnitMap: makeGetListRateUnitMap() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getRateUnit, getRateUnitMapCfg, modifyRateUnitMapCfg, createRateUnitMapCfg })(RateUnitMap)
);
