import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { DataTable, GenericInput } from '../common';

const listDetailsFields = [
  {
    label: 'label.gross',
    name: 'grossQuote',
  },
  {
    label: 'label.discount',
    name: 'quoteDiscount',
  },
  {
    label: 'label.quote',
    name: 'netQuote',
  },
];

const QuoteItemDetailsForm = ({
  data,
  wrapperClass,
  label,
  isHasOldQuote,
  isRightSide,
  dataCompare,
  isSupportColor,
  isLeftSide,
  rightData,
}) => {
  const { t } = useTranslation('common');
  const { serviceLevelQuoteDetails, priceOfferQuoteDetails, discountOfferQuoteDetails } = dataCompare || {};
  let maxHeightService = null;
  let maxHeightPriceOffer = null;
  let maxHeightDiscountOffer = null;

  if (isHasOldQuote) {
    if (data?.serviceLevelQuoteDetails && serviceLevelQuoteDetails) {
      if (data?.serviceLevelQuoteDetails.length > serviceLevelQuoteDetails.length) {
        maxHeightService = data?.serviceLevelQuoteDetails.length * 45;
      }
      if (data?.serviceLevelQuoteDetails.length < serviceLevelQuoteDetails.length) {
        maxHeightService = serviceLevelQuoteDetails.length * 45;
      }
    }
  }

  if (isHasOldQuote) {
    if (data?.priceOfferQuoteDetails && priceOfferQuoteDetails) {
      if (data?.priceOfferQuoteDetails.length > priceOfferQuoteDetails.length) {
        maxHeightPriceOffer = data?.priceOfferQuoteDetails.length * 45;
      }
      if (data?.priceOfferQuoteDetails.length < priceOfferQuoteDetails.length) {
        maxHeightPriceOffer = priceOfferQuoteDetails.length * 45;
      }
    }
  }

  if (isHasOldQuote) {
    if (data?.discountOfferQuoteDetails && discountOfferQuoteDetails) {
      if (data?.discountOfferQuoteDetails.length > discountOfferQuoteDetails.length) {
        maxHeightDiscountOffer = data?.discountOfferQuoteDetails.length * 45;
      }
      if (data?.discountOfferQuoteDetails.length < discountOfferQuoteDetails.length) {
        maxHeightDiscountOffer = discountOfferQuoteDetails.length * 45;
      }
    }
  }

  const serviceSummaryColumns = [
    {
      label: 'label.serviceType',
      name: 'serviceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        let color = 'none';
        if (isHasOldQuote && isSupportColor) {
          const itemSelect = serviceLevelQuoteDetails
            ? serviceLevelQuoteDetails.find(val => val.serviceType === item.serviceType)
            : null;
          if (itemSelect) {
            map(itemSelect, (value, name) => {
              if (item[name] !== value) color = 'orange';
            });
          }
          if (isRightSide) {
            if (!itemSelect) {
              color = '#28a745';
            }
            if (item.quoteDiscount === 0 && item.netQuote === 0) {
              color = 'red';
            }
          }
          if (isLeftSide) {
            if (!itemSelect || (item.quoteDiscount === 0 && item.netQuote === 0)) {
              color = 'red';
            } else {
              const itemRight =
                rightData.serviceLevelQuoteDetails &&
                rightData.serviceLevelQuoteDetails.find(val => val.serviceType === item.serviceType);
              if (itemRight.quoteDiscount === 0 && itemRight.netQuote === 0) {
                color = 'red';
              }
            }
          }
        }

        return <span style={{ color }}>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'label.gross',
      name: 'grossQuote',
    },
    {
      label: 'label.discount',
      name: 'quoteDiscount',
    },
    {
      label: 'label.quote',
      name: 'netQuote',
    },
  ];

  const priceOfferColumns = [
    {
      label: 'label.priceOffer',
      name: 'priceOfferName',
      render: (colName, item) => {
        let color = 'none';
        if (isHasOldQuote && isSupportColor) {
          const itemSelect = priceOfferQuoteDetails
            ? priceOfferQuoteDetails.find(
                val =>
                  val.priceOfferId === item.priceOfferId &&
                  val.bundleId === item.bundleId &&
                  val.packageId === item.packageId
              )
            : null;
          if (itemSelect) {
            map(itemSelect, (value, name) => {
              if (item[name] !== value) color = 'orange';
            });
          }
          if (isRightSide) {
            if (!itemSelect) {
              color = '#28a745';
            }
            if (item.grossQuote === 0 && item.netQuote === 0 && item.quoteDiscount === 0) {
              color = 'red';
            }
          }
          if (isLeftSide) {
            if (!itemSelect || (item.grossQuote === 0 && item.netQuote === 0 && item.quoteDiscount === 0)) {
              color = 'red';
            } else {
              const itemRight =
                rightData.priceOfferQuoteDetails &&
                rightData.priceOfferQuoteDetails.find(
                  val =>
                    val.priceOfferId === item.priceOfferId &&
                    val.bundleId === item.bundleId &&
                    val.packageId === item.packageId
                );
              if (itemRight.grossQuote === 0 && itemRight.netQuote === 0 && itemRight.quoteDiscount === 0) {
                color = 'red';
              }
            }
          }
        }
        return <span style={{ color }}>{item.priceOfferName}</span>;
      },
    },
    {
      label: 'label.bundle',
      name: 'bundleName',
    },
    {
      label: 'label.package',
      name: 'packageName',
    },
    {
      label: 'label.service',
      name: 'serviceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'label.transaction',
      name: 'transactionType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:transactionType')().find(val => val.value === item.transactionType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'label.gross',
      name: 'grossQuote',
    },
    {
      label: 'label.discount',
      name: 'quoteDiscount',
    },
    {
      label: 'label.quote',
      name: 'netQuote',
    },
  ];

  const discountOfferColumns = [
    {
      label: 'label.discountOffer',
      name: 'discountOfferName',
      render: (colName, item) => {
        let color = 'none';
        if (isHasOldQuote && isSupportColor) {
          const itemSelect = discountOfferQuoteDetails
            ? discountOfferQuoteDetails.find(
                val =>
                  val.discountOfferId === item.discountOfferId &&
                  val.bundleId === item.bundleId &&
                  val.packageId === item.packageId
              )
            : null;
          if (itemSelect) {
            map(itemSelect, (value, name) => {
              if (item[name] !== value) color = 'orange';
            });
          }
          if (isRightSide) {
            if (!itemSelect) {
              color = '#28a745';
            }
            if (item.amount === 0 && item.percent === 0) {
              color = 'red';
            }
          }
          if (isLeftSide) {
            if (!itemSelect || (item.amount === 0 && item.percent === 0)) {
              color = 'red';
            } else {
              const itemRight =
                rightData.discountOfferQuoteDetails &&
                rightData.discountOfferQuoteDetails.find(
                  val =>
                    val.discountOfferId === item.discountOfferId &&
                    val.bundleId === item.bundleId &&
                    val.packageId === item.packageId
                );
              if (itemRight.amount === 0 && itemRight.percent === 0) {
                color = 'red';
              }
            }
          }
        }
        return <span style={{ color }}>{item.discountOfferName}</span>;
      },
    },
    {
      label: 'label.bundle',
      name: 'bundleName',
    },
    {
      label: 'label.package',
      name: 'packageName',
    },
    {
      label: 'label.service',
      name: 'serviceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'label.transaction',
      name: 'transactionType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:transactionType')().find(val => val.value === item.transactionType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'label.amount',
      name: 'amount',
    },
    {
      label: 'label.percentage',
      name: 'percent',
    },
  ];

  return (
    <div className={wrapperClass || 'col-md-6'}>
      <h6>{label ? t(label) : ''}</h6>
      <div className="col-md-12 pl-2 mt-3 mb-2 row">
        {listDetailsFields.map(item => (
          <GenericInput
            key={item.name}
            readOnly
            disabled
            {...item}
            wrapperClass="col-md-4"
            value={data ? data[item.name] : ''}
          />
        ))}
      </div>
      <div className="col-md-12 p-0 mb-2">
        <h6 className="text-bold mt-3 mb-2">{t('label.serviceSummary')}</h6>
        <div style={{ height: `${maxHeightService}px`, overflow: 'auto', maxHeight: '150px' }}>
          <DataTable columns={serviceSummaryColumns} data={data?.serviceLevelQuoteDetails || []} />
        </div>
      </div>
      <div className="col-md-12 p-0 mt-3">
        <h6 className="text-bold mt-3 mb-2">{t('label.lineSummary')}</h6>
        <div style={{ height: `${maxHeightPriceOffer}px`, overflow: 'auto', maxHeight: '300px' }}>
          <DataTable columns={priceOfferColumns} data={data?.priceOfferQuoteDetails || []} />
        </div>
      </div>
      <div className="col-md-12 p-0 mt-5">
        <div style={{ height: `${maxHeightDiscountOffer}px`, overflow: 'auto', maxHeight: '300px' }}>
          <DataTable columns={discountOfferColumns} data={data?.discountOfferQuoteDetails || []} />
        </div>
      </div>
    </div>
  );
};
QuoteItemDetailsForm.propTypes = {
  wrapperClass: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
  isHasOldQuote: PropTypes.bool,
  isRightSide: PropTypes.bool,
  isSupportColor: PropTypes.bool,
  isLeftSide: PropTypes.bool,
  dataCompare: PropTypes.objectOf(PropTypes.any),
  rightData: PropTypes.objectOf(PropTypes.any),
};

QuoteItemDetailsForm.defaultProps = {
  wrapperClass: '' || null,
  data: {},
  label: '',
  isHasOldQuote: false,
  isRightSide: false,
  isSupportColor: false,
  isLeftSide: false,
  dataCompare: {},
  rightData: {},
};
export default QuoteItemDetailsForm;
