import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import { PulseLoader } from 'react-spinners';
import { createStructuredSelector } from 'reselect';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import { GenericInput } from '../../components/common';
import { itemLangSelected } from '../../utils/listLangue';
import { userLogin } from './action';
import { getDataCardFromURL, getBase64 } from '../../utils/utils';
import { getCcpPropertiesConfig, getTenantDefaultConfig, readFileFromS3, getAwsS3Credential } from '../App/actions';
import { makeGetS3Config } from '../App/selectors';
import '../../Assets/css/style.css';
import LogoPng from '../../Assets/images/logos/logo-01.svg';
import {
  getSSOLogin,
  setSSOLogin,
  setId,
  setUserId,
  setFirstTimeLogin,
  setDaysForPasswordChange,
  getAccountId,
  getUserId,
  getId,
  clearAllLocalStorage,
} from '../../localStorages';

const optionsLang = [
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: 'Español',
    value: 'es',
  },
];

class LoginPage extends Component {
  state = {
    userName: '',
    password: '',
    lang: localStorage.getItem('i18nextLng') || 'en-US',
    isNeedGetCCp: true,
    base64Logo: null,
    isLoadingLogo: false,
  };

  componentDidMount() {
    clearAllLocalStorage();
    const { getCcpPropertiesConfig, history, s3Config, getTenantDefaultConfig, getAwsS3Credential } = this.props;
    const { isNeedGetCCp } = this.state;
    const url = window.location.href;
    this.setState({ isLoadingLogo: true });
    getCcpPropertiesConfig('', ({ data, success }) => {
      getTenantDefaultConfig(({ success, data }) => {
        if (success && data && data.tenantProfile && data.tenantProfile.logoPath) {
          const pathFile = data.tenantProfile.logoPath;
          if (!s3Config || !s3Config.accessKey) {
            getAwsS3Credential('', ({ success, data }) => {
              if (success && data) {
                this.doReadFileFromConfig(data, pathFile);
              } else {
                this.setState({ isLoadingLogo: false });
              }
            });
          } else {
            this.doReadFileFromConfig(s3Config, pathFile);
          }
        } else {
          this.setState({ isLoadingLogo: false });
        }
      });
      this.setState({ isNeedGetCCp: false });
      if (success) {
        const ssoEnabled = data.ccpPropertyList.find(val => val.property === 'ssoEnabled');
        if (
          ssoEnabled &&
          ssoEnabled.value &&
          ssoEnabled.value === 'true' &&
          !getSSOLogin() &&
          !getDataCardFromURL(url, 'rolesId=', 'rolesId=') &&
          isNeedGetCCp
        ) {
          setSSOLogin('isFirstLogin');
          window.open(
            `https://service-sso.develop.embrix.org/api/sso/login/callback?callbackURL=${window.location.href}`,
            '_self'
          );
        }
        if (
          ssoEnabled &&
          ssoEnabled.value &&
          ssoEnabled.value === 'true' &&
          getDataCardFromURL(url, 'rolesId=', 'rolesId=')
        ) {
          setSSOLogin('isFirstLogin');
          setId(getDataCardFromURL(url, 'rolesId=', 'rolesId='));
          setUserId(getDataCardFromURL(url, 'user=', 'user='));
          setFirstTimeLogin(false);
          setDaysForPasswordChange(0);
          history.push('/dashboard/me');
        }
      }
    });
  }

  doReadFileFromConfig = (config, pathFile) => {
    const { readFileFromS3 } = this.props;
    readFileFromS3({ ...config, url: pathFile }, ({ success, data }) => {
      if (success) {
        getBase64(data, result => {
          this.setState({ base64Logo: result, isLoadingLogo: false });
        });
      } else {
        this.setState({ isLoadingLogo: false });
      }
    });
  };

  onChangeLanguage = ({ value }) => {
    const { i18n } = this.props;
    this.setState({ lang: value });
    i18n.changeLanguage(value);
  };

  handInputChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { userName, password } = this.state;
    const {
      location: { state },
      history,
      userLogin,
    } = this.props;
    userLogin({ userName, password }, ({ success }) => {
      if (success) {
        if (state) {
          history.push(state.currentUrl);
        } else {
          history.push('/dashboard/me');
        }
      }
      this.setState({
        isLogin: false,
      });
    });
  };

  render() {
    const { lang, base64Logo, isLoadingLogo } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="page_loader" />
        <div className="login-3 tab-box">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-pad-0 form-section">
                <div className="login-inner-form">
                  <div className="details">
                    {isLoadingLogo ? (
                      <PulseLoader size={13} color="#28a745" />
                    ) : (
                      base64Logo && (
                        <img src={base64Logo} alt="logo" className="login-logo animate__animated animate__slideInUp" />
                      )
                    )}

                    <h3>{t('label.signEmbrix')}</h3>
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          name="userName"
                          className="input-text"
                          placeholder={t('label.username')}
                          onChange={this.handInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          className="input-text"
                          placeholder={t('label.password')}
                          onChange={this.handInputChange}
                        />
                      </div>
                      <div className="checkbox clearfix row">
                        <div className="form-check checkbox-theme col-md-6">
                          <input className="form-check-input" type="checkbox" value="" id="rememberMe" />
                          <label className="form-check-label" htmlFor="rememberMe">
                            {t('label.rememberMe')}
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn-md btn-theme btn-block">
                          {t('label.login')}
                        </button>
                      </div>
                    </form>
                    {t('label.embrixDetailLogin')}
                  </div>
                  <div className="text-right">
                    <GenericInput
                      label=""
                      value={itemLangSelected(lang || 'en-US').key}
                      type="select"
                      name="lang"
                      options={optionsLang}
                      onChange={this.onChangeLanguage}
                      wrapperClass="col-md-4 group-select-lang"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-pad-0 bg-img none-992">
                <div className="informeson" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
});

const withConnect = connect(mapStateToProps, {
  userLogin,
  getCcpPropertiesConfig,
  push,
  getTenantDefaultConfig,
  readFileFromS3,
  getAwsS3Credential,
});

const withSaga = injectSaga({ key: 'auth', saga });

export default withTranslation('common')(compose(withSaga, withConnect)(LoginPage));
