import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default data => {
  const filterStr = data ? `, input: ${convertFilterRequestUrl(data, [])}` : '';
  const getProvisioningInstanceData = `getProvisioningInstanceData(${filterStr})`;
  return gql`
    query {
      ${getProvisioningInstanceData} {
        instanceId
        serviceName
      }
    }
  `;
};
