const countryList = [
  {
    label: 'United States',
    value: 'USA',
    code: '840',
  },
  {
    label: 'Afghanistan',
    value: 'AFG',
    code: '004',
  },
  {
    label: 'Åland Islands',
    value: 'ALA',
    code: '248',
  },
  {
    label: 'Albania',
    value: 'ALB',
    code: '008',
  },
  {
    label: 'Algeria',
    value: 'DZA',
    code: '012',
  },
  {
    label: 'American Samoa',
    value: 'ASM',
    code: '016',
  },
  {
    label: 'Andorra',
    value: 'AND',
    code: '020',
  },
  {
    label: 'Angola',
    value: 'AGO',
    code: '024',
  },
  {
    label: 'Anguilla',
    value: 'AIA',
    code: '660',
  },
  {
    label: 'Antarctica',
    value: 'ATA',
    code: '010',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'ATG',
    code: '028',
  },
  {
    label: 'Argentina',
    value: 'ARG',
    code: '032',
  },
  {
    label: 'Armenia',
    value: 'ARM',
    code: '051',
  },
  {
    label: 'Aruba',
    value: 'ABW',
    code: '533',
  },
  {
    label: 'Australia',
    value: 'AUS',
    code: '036',
  },
  {
    label: 'Austria',
    value: 'AUT',
    code: '040',
  },
  {
    label: 'Azerbaijan',
    value: 'AZE',
    code: '031',
  },
  {
    label: 'Bahamas',
    value: 'BHS',
    code: '044',
  },
  {
    label: 'Bahrain',
    value: 'BHR',
    code: '048',
  },
  {
    label: 'Bangladesh',
    value: 'BGD',
    code: '050',
  },
  {
    label: 'Barbados',
    value: 'BRB',
    code: '052',
  },
  {
    label: 'Belarus',
    value: 'BLR',
    code: '112',
  },
  {
    label: 'Belgium',
    value: 'BEL',
    code: '056',
  },
  {
    label: 'Belize',
    value: 'BLZ',
    code: '084',
  },
  {
    label: 'Benin',
    value: 'BEN',
    code: '204',
  },
  {
    label: 'Bermuda',
    value: 'BMU',
    code: '060',
  },
  {
    label: 'Bhutan',
    value: 'BTN',
    code: '064',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'BOL',
    code: '068',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
    code: '535',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BIH',
    code: '070',
  },
  {
    label: 'Botswana',
    value: 'BWA',
    code: '072',
  },
  {
    label: 'Bouvet Island',
    value: 'BVT',
    code: '074',
  },
  {
    label: 'Brazil',
    value: 'BRA',
    code: '076',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IOT',
    code: '086',
  },
  {
    label: 'Brunei Darussalam',
    value: 'BRN',
    code: '096',
  },
  {
    label: 'Bulgaria',
    value: 'BGR',
    code: '100',
  },
  {
    label: 'Burkina Faso',
    value: 'BFA',
    code: '854',
  },
  {
    label: 'Burundi',
    value: 'BDI',
    code: '108',
  },
  {
    label: 'Cabo Verde',
    value: 'CPV',
    code: '132',
  },
  {
    label: 'Cambodia',
    value: 'KHM',
    code: '116',
  },
  {
    label: 'Cameroon',
    value: 'CMR',
    code: '120',
  },
  {
    label: 'Canada',
    value: 'CAN',
    code: '124',
  },
  {
    label: 'Cayman Islands',
    value: 'CYM',
    code: '136',
  },
  {
    label: 'Central African Republic',
    value: 'CAF',
    code: '140',
  },
  {
    label: 'Chad',
    value: 'TCD',
    code: '148',
  },
  {
    label: 'Chile',
    value: 'CHL',
    code: '152',
  },
  {
    label: 'China',
    value: 'CHN',
    code: '156',
  },
  {
    label: 'Christmas Island',
    value: 'CXR',
    code: '162',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CCK',
    code: '166',
  },
  {
    label: 'Colombia',
    value: 'COL',
    code: '170',
  },
  {
    label: 'Comoros',
    value: 'COM',
    code: '174',
  },
  {
    label: 'Congo',
    value: 'COG',
    code: '178',
  },
  {
    label: 'Congo, Democratic Republic of the',
    value: 'COD',
    code: '180',
  },
  {
    label: 'Cook Islands',
    value: 'COK',
    code: '184',
  },
  {
    label: 'Costa Rica',
    value: 'CRI',
    code: '188',
  },
  {
    label: "Côte d'Ivoire",
    value: 'CIV',
    code: '384',
  },
  {
    label: 'Croatia',
    value: 'HRV',
    code: '191',
  },
  {
    label: 'Cuba',
    value: 'CUB',
    code: '192',
  },
  {
    label: 'Curaçao',
    value: 'CUW',
    code: '531',
  },
  {
    label: 'Cyprus',
    value: 'CYP',
    code: '196',
  },
  {
    label: 'Czechia',
    value: 'CZE',
    code: '203',
  },
  {
    label: 'Denmark',
    value: 'DNK',
    code: '208',
  },
  {
    label: 'Djibouti',
    value: 'DJI',
    code: '262',
  },
  {
    label: 'Dominica',
    value: 'DMA',
    code: '212',
  },
  {
    label: 'Dominican Republic',
    value: 'DOM',
    code: '214',
  },
  {
    label: 'Ecuador',
    value: 'ECU',
    code: '218',
  },
  {
    label: 'Egypt',
    value: 'EGY',
    code: '818',
  },
  {
    label: 'El Salvador',
    value: 'SLV',
    code: '222',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GNQ',
    code: '226',
  },
  {
    label: 'Eritrea',
    value: 'ERI',
    code: '232',
  },
  {
    label: 'Estonia',
    value: 'EST',
    code: '233',
  },
  {
    label: 'Eswatini',
    value: 'SWZ',
    code: '748',
  },
  {
    label: 'Ethiopia',
    value: 'ETH',
    code: '231',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FLK',
    code: '238',
  },
  {
    label: 'Faroe Islands',
    value: 'FRO',
    code: '234',
  },
  {
    label: 'Fiji',
    value: 'FJI',
    code: '242',
  },
  {
    label: 'Finland',
    value: 'FIN',
    code: '246',
  },
  {
    label: 'France',
    value: 'FRA',
    code: '250',
  },
  {
    label: 'French Guiana',
    value: 'GUF',
    code: '254',
  },
  {
    label: 'French Polynesia',
    value: 'PYF',
    code: '258',
  },
  {
    label: 'French Southern Territories',
    value: 'ATF',
    code: '260',
  },
  {
    label: 'Gabon',
    value: 'GAB',
    code: '266',
  },
  {
    label: 'Gambia',
    value: 'GMB',
    code: '270',
  },
  {
    label: 'Georgia',
    value: 'GEO',
    code: '268',
  },
  {
    label: 'Germany',
    value: 'DEU',
    code: '276',
  },
  {
    label: 'Ghana',
    value: 'GHA',
    code: '288',
  },
  {
    label: 'Gibraltar',
    value: 'GIB',
    code: '292',
  },
  {
    label: 'Greece',
    value: 'GRC',
    code: '300',
  },
  {
    label: 'Greenland',
    value: 'GRL',
    code: '304',
  },
  {
    label: 'Grenada',
    value: 'GRD',
    code: '308',
  },
  {
    label: 'Guadeloupe',
    value: 'GLP',
    code: '312',
  },
  {
    label: 'Guam',
    value: 'GUM',
    code: '316',
  },
  {
    label: 'Guatemala',
    value: 'GTM',
    code: '320',
  },
  {
    label: 'Guernsey',
    value: 'GGY',
    code: '831',
  },
  {
    label: 'Guinea',
    value: 'GIN',
    code: '324',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GNB',
    code: '624',
  },
  {
    label: 'Guyana',
    value: 'GUY',
    code: '328',
  },
  {
    label: 'Haiti',
    value: 'HTI',
    code: '332',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'HMD',
    code: '334',
  },
  {
    label: 'Holy See',
    value: 'VAT',
    code: '336',
  },
  {
    label: 'Honduras',
    value: 'HND',
    code: '340',
  },
  {
    label: 'Hong Kong',
    value: 'HKG',
    code: '344',
  },
  {
    label: 'Hungary',
    value: 'HUN',
    code: '348',
  },
  {
    label: 'Iceland',
    value: 'ISL',
    code: '352',
  },
  {
    label: 'India',
    value: 'IND',
    code: '356',
  },
  {
    label: 'Indonesia',
    value: 'IDN',
    code: '360',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'IRN',
    code: '364',
  },
  {
    label: 'Iraq',
    value: 'IRQ',
    code: '368',
  },
  {
    label: 'Ireland',
    value: 'IRL',
    code: '372',
  },
  {
    label: 'Isle of Man',
    value: 'IMN',
    code: '833',
  },
  {
    label: 'Israel',
    value: 'ISR',
    code: '376',
  },
  {
    label: 'Italy',
    value: 'ITA',
    code: '380',
  },
  {
    label: 'Jamaica',
    value: 'JAM',
    code: '388',
  },
  {
    label: 'Japan',
    value: 'JPN',
    code: '392',
  },
  {
    label: 'Jersey',
    value: 'JEY',
    code: '832',
  },
  {
    label: 'Jordan',
    value: 'JOR',
    code: '400',
  },
  {
    label: 'Kazakhstan',
    value: 'KAZ',
    code: '398',
  },
  {
    label: 'Kenya',
    value: 'KEN',
    code: '404',
  },
  {
    label: 'Kiribati',
    value: 'KIR',
    code: '296',
  },
  {
    label: "Korea (Democratic People's Republic of)",
    value: 'PRK',
    code: '408',
  },
  {
    label: 'Korea, Republic of',
    value: 'KOR',
    code: '410',
  },
  {
    label: 'Kuwait',
    value: 'KWT',
    code: '414',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KGZ',
    code: '417',
  },
  {
    label: "Lao People's Democratic Republic",
    value: 'LAO',
    code: '418',
  },
  {
    label: 'Latvia',
    value: 'LVA',
    code: '428',
  },
  {
    label: 'Lebanon',
    value: 'LBN',
    code: '422',
  },
  {
    label: 'Lesotho',
    value: 'LSO',
    code: '426',
  },
  {
    label: 'Liberia',
    value: 'LBR',
    code: '430',
  },
  {
    label: 'Libya',
    value: 'LBY',
    code: '434',
  },
  {
    label: 'Liechtenstein',
    value: 'LIE',
    code: '438',
  },
  {
    label: 'Lithuania',
    value: 'LTU',
    code: '440',
  },
  {
    label: 'Luxembourg',
    value: 'LUX',
    code: '442',
  },
  {
    label: 'Macao',
    value: 'MAC',
    code: '446',
  },
  {
    label: 'Madagascar',
    value: 'MDG',
    code: '450',
  },
  {
    label: 'Malawi',
    value: 'MWI',
    code: '454',
  },
  {
    label: 'Malaysia',
    value: 'MYS',
    code: '458',
  },
  {
    label: 'Maldives',
    value: 'MDV',
    code: '462',
  },
  {
    label: 'Mali',
    value: 'MLI',
    code: '466',
  },
  {
    label: 'Malta',
    value: 'MLT',
    code: '470',
  },
  {
    label: 'Marshall Islands',
    value: 'MHL',
    code: '584',
  },
  {
    label: 'Martinique',
    value: 'MTQ',
    code: '474',
  },
  {
    label: 'Mauritania',
    value: 'MRT',
    code: '478',
  },
  {
    label: 'Mauritius',
    value: 'MUS',
    code: '480',
  },
  {
    label: 'Mayotte',
    value: 'MYT',
    code: '175',
  },
  {
    label: 'Mexico',
    value: 'MEX',
    code: '484',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'FSM',
    code: '583',
  },
  {
    label: 'Moldova, Republic of',
    value: 'MDA',
    code: '498',
  },
  {
    label: 'Monaco',
    value: 'MCO',
    code: '492',
  },
  {
    label: 'Mongolia',
    value: 'MNG',
    code: '496',
  },
  {
    label: 'Montenegro',
    value: 'MNE',
    code: '499',
  },
  {
    label: 'Montserrat',
    value: 'MSR',
    code: '500',
  },
  {
    label: 'Morocco',
    value: 'MAR',
    code: '504',
  },
  {
    label: 'Mozambique',
    value: 'MOZ',
    code: '508',
  },
  {
    label: 'Myanmar',
    value: 'MMR',
    code: '104',
  },
  {
    label: 'Namibia',
    value: 'NAM',
    code: '516',
  },
  {
    label: 'Nauru',
    value: 'NRU',
    code: '520',
  },
  {
    label: 'Nepal',
    value: 'NPL',
    code: '524',
  },
  {
    label: 'Netherlands',
    value: 'NLD',
    code: '528',
  },
  {
    label: 'New Caledonia',
    value: 'NCL',
    code: '540',
  },
  {
    label: 'New Zealand',
    value: 'NZL',
    code: '554',
  },
  {
    label: 'Nicaragua',
    value: 'NIC',
    code: '558',
  },
  {
    label: 'Niger',
    value: 'NER',
    code: '562',
  },
  {
    label: 'Nigeria',
    value: 'NGA',
    code: '566',
  },
  {
    label: 'Niue',
    value: 'NIU',
    code: '570',
  },
  {
    label: 'Norfolk Island',
    value: 'NFK',
    code: '574',
  },
  {
    label: 'North Macedonia',
    value: 'MKD',
    code: '807',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MNP',
    code: '580',
  },
  {
    label: 'Norway',
    value: 'NOR',
    code: '578',
  },
  {
    label: 'Oman',
    value: 'OMN',
    code: '512',
  },
  {
    label: 'Pakistan',
    value: 'PAK',
    code: '586',
  },
  {
    label: 'Palau',
    value: 'PLW',
    code: '585',
  },
  {
    label: 'Palestine, State of',
    value: 'PSE',
    code: '275',
  },
  {
    label: 'Panama',
    value: 'PAN',
    code: '591',
  },
  {
    label: 'Papua New Guinea',
    value: 'PNG',
    code: '598',
  },
  {
    label: 'Paraguay',
    value: 'PRY',
    code: '600',
  },
  {
    label: 'Peru',
    value: 'PER',
    code: '604',
  },
  {
    label: 'Philippines',
    value: 'PHL',
    code: '608',
  },
  {
    label: 'Pitcairn',
    value: 'PCN',
    code: '612',
  },
  {
    label: 'Poland',
    value: 'POL',
    code: '616',
  },
  {
    label: 'Portugal',
    value: 'PRT',
    code: '620',
  },
  {
    label: 'Puerto Rico',
    value: 'PRI',
    code: '630',
  },
  {
    label: 'Qatar',
    value: 'QAT',
    code: '634',
  },
  {
    label: 'Réunion',
    value: 'REU',
    code: '638',
  },
  {
    label: 'Romania',
    value: 'ROU',
    code: '642',
  },
  {
    label: 'Russian Federation',
    value: 'RUS',
    code: '643',
  },
  {
    label: 'Rwanda',
    value: 'RWA',
    code: '646',
  },
  {
    label: 'Saint Barthélemy',
    value: 'BLM',
    code: '652',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
    code: '654',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KNA',
    code: '659',
  },
  {
    label: 'Saint Lucia',
    value: 'LCA',
    code: '662',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MAF',
    code: '663',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'SPM',
    code: '666',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VCT',
    code: '670',
  },
  {
    label: 'Samoa',
    value: 'WSM',
    code: '882',
  },
  {
    label: 'San Marino',
    value: 'SMR',
    code: '674',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'STP',
    code: '678',
  },
  {
    label: 'Saudi Arabia',
    value: 'SAU',
    code: '682',
  },
  {
    label: 'Senegal',
    value: 'SEN',
    code: '686',
  },
  {
    label: 'Serbia',
    value: 'SRB',
    code: '688',
  },
  {
    label: 'Seychelles',
    value: 'SYC',
    code: '690',
  },
  {
    label: 'Sierra Leone',
    value: 'SLE',
    code: '694',
  },
  {
    label: 'Singapore',
    value: 'SGP',
    code: '702',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'SXM',
    code: '534',
  },
  {
    label: 'Slovakia',
    value: 'SVK',
    code: '703',
  },
  {
    label: 'Slovenia',
    value: 'SVN',
    code: '705',
  },
  {
    label: 'Solomon Islands',
    value: 'SLB',
    code: '090',
  },
  {
    label: 'Somalia',
    value: 'SOM',
    code: '706',
  },
  {
    label: 'South Africa',
    value: 'ZAF',
    code: '710',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
    code: '239',
  },
  {
    label: 'South Sudan',
    value: 'SSD',
    code: '728',
  },
  {
    label: 'Spain',
    value: 'ESP',
    code: '724',
  },
  {
    label: 'Sri Lanka',
    value: 'LKA',
    code: '144',
  },
  {
    label: 'Sudan',
    value: 'SDN',
    code: '729',
  },
  {
    label: 'Surilabel',
    value: 'SUR',
    code: '740',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJM',
    code: '744',
  },
  {
    label: 'Sweden',
    value: 'SWE',
    code: '752',
  },
  {
    label: 'Switzerland',
    value: 'CHE',
    code: '756',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SYR',
    code: '760',
  },
  {
    label: 'Taiwan, Province of China',
    value: 'TWN',
    code: '158',
  },
  {
    label: 'Tajikistan',
    value: 'TJK',
    code: '762',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZA',
    code: '834',
  },
  {
    label: 'Thailand',
    value: 'THA',
    code: '764',
  },
  {
    label: 'Timor-Leste',
    value: 'TLS',
    code: '626',
  },
  {
    label: 'Togo',
    value: 'TGO',
    code: '768',
  },
  {
    label: 'Tokelau',
    value: 'TKL',
    code: '772',
  },
  {
    label: 'Tonga',
    value: 'TON',
    code: '776',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TTO',
    code: '780',
  },
  {
    label: 'Tunisia',
    value: 'TUN',
    code: '788',
  },
  {
    label: 'Turkey',
    value: 'TUR',
    code: '792',
  },
  {
    label: 'Turkmenistan',
    value: 'TKM',
    code: '795',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TCA',
    code: '796',
  },
  {
    label: 'Tuvalu',
    value: 'TUV',
    code: '798',
  },
  {
    label: 'Uganda',
    value: 'UGA',
    code: '800',
  },
  {
    label: 'Ukraine',
    value: 'UKR',
    code: '804',
  },
  {
    label: 'United Arab Emirates',
    value: 'ARE',
    code: '784',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
    code: '826',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UMI',
    code: '581',
  },
  {
    label: 'Uruguay',
    value: 'URY',
    code: '858',
  },
  {
    label: 'Uzbekistan',
    value: 'UZB',
    code: '860',
  },
  {
    label: 'Vanuatu',
    value: 'VUT',
    code: '548',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'VEN',
    code: '862',
  },
  {
    label: 'Viet Nam',
    value: 'VNM',
    code: '704',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'VGB',
    code: '092',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'VIR',
    code: '850',
  },
  {
    label: 'Wallis and Futuna',
    value: 'WLF',
    code: '876',
  },
  {
    label: 'Western Sahara',
    value: 'ESH',
    code: '732',
  },
  {
    label: 'Yemen',
    value: 'YEM',
    code: '887',
  },
  {
    label: 'Zambia',
    value: 'ZMB',
    code: '894',
  },
  {
    label: 'Zimbabwe',
    value: 'ZWE',
    code: '716',
  },
];

const stateUSData = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export { countryList, stateUSData };
