export const SEARCH_ACCOUNTS = 'app/payments/search_accounts';
export const SEARCH_ACCOUNTS_SUCCESS = 'app/payments/search_accounts_success';
export const SEARCH_ACCOUNTS_FAILURE = 'app/payments/search_accounts_failure';
export const SET_PARAMS_ACCOUNTS = 'app/payments/set_params_account';

export const GET_PAYMENT_CONFIG_BY_TYPE = 'app/payments/get_payment_config_by_type';
export const CREATE_PAYMENT_CONFIG_MERCHANT = 'app/payments/create_payment_config_merchant';
export const MODIFY_PAYMENT_CONFIG_MERCHANT = 'app/payments/modify_payment_config_merchant';
export const CREATE_PAYMENT_CONFIG_METHOD = 'app/payments/create_payment_config_method';
export const MODIFY_PAYMENT_CONFIG_METHOD = 'app/payments/modify_payment_config_method';
export const CREATE_PAYMENT_CONFIG_TERM = 'app/payments/create_payment_config_term';
export const MODIFY_PAYMENT_CONFIG_TERM = 'app/payments/modify_payment_config_term';

export const SEARCH_PAYMENTS = 'app/payments/search_payments';
export const SEARCH_PAYMENTS_SUCCESS = 'app/payments/search_payments_success';
export const SEARCH_PAYMENTS_FAILURE = 'app/payments/search_payments_failure';
export const SET_PARAMS_PAYMENTS = 'app/payments/set_params_payments';

export const SEARCH_PAYMENTS_SUSPENSE = 'app/payments/search_payments_suspense';
export const SEARCH_PAYMENTS_SUSPENSE_SUCCESS = 'app/payments/search_payments_suspense_success';
export const APPLY_PAYMENT_SUSPENSE = 'app/payments/applyPaymentSuspense';

export const SEARCH_PAYMENTS_SUSPENSE_FAILURE = 'app/payments/search_payments_suspense_failure';
export const SET_PARAMS_PAYMENTS_SUSPENSE = 'app/payments/set_params_payments_suspense';

export const REVERSE_PAYMENT = 'app/payments/reverse_payments';

export const SEARCH_INVOICES = 'app/payments/search_invoices';
export const SEARCH_INVOICES_SUCCESS = 'app/payments/search_invoices_success';
export const SEARCH_INVOICES_FAILED = 'app/payments/search_invoices_failed';

export const APPLY_PAYMENT = 'app/payment/apply_payment';
export const ALLOCATE_PAYMENT = 'app/payment/allocate_payment';
export const MODIFY_PAYMENT_SUSPENSE = 'app/payment/modify_payment_suspense';

// Payment Surcharge
export const GET_PAYMENT_SURCHARGE_CONFIG = 'app/customers/GET_PAYMENT_SURCHARGE_CONFIG';
export const GET_PAYMENT_SURCHARGE_CONFIG_SUCCESS = 'app/customers/GET_PAYMENT_SURCHARGE_CONFIG_SUCCESS';
export const MODIFY_PAYMENT_SURCHARGE_CONFIG = 'app/customers/MODIFY_PAYMENT_SURCHARGE_CONFIG';
export const CREATE_PAYMENT_SURCHARGE_CONFIG = 'app/customers/CREATE_PAYMENT_SURCHARGE_CONFIG';

// Payment Process
export const SEARCH_BATCH_PAYMENT_PROCESS = 'app/customers/SEARCH_BATCH_PAYMENT_PROCESS';
export const SEARCH_BATCH_PAYMENT_PROCESS_SUCCESS = 'app/customers/SEARCH_BATCH_PAYMENT_PROCESS_SUCCESS';
export const GET_BATCH_PAYMENT_DETAIS = 'app/customers/GET_BATCH_PAYMENT_DETAIS';
export const PROCESS_BATCH_PAYMENT = 'app/customers/PROCESS_BATCH_PAYMENT';

// Payment Batch File
export const GET_PAYMENT_BATCH_FILE_CONFIG = 'app/payment/GET_PAYMENT_BATCH_FILE_CONFIG';
export const GET_PAYMENT_BATCH_FILE_CONFIG_SUCCESS = 'app/payment/GET_PAYMENT_BATCH_FILE_CONFIG_SUCCESS';
export const MODIFY_PAYMENT_BATCH_FILE_CONFIG = 'app/payment/MODIFY_PAYMENT_BATCH_FILE_CONFIG';
export const CREATE_PAYMENT_BATCH_FILE_CONFIG = 'app/payment/CREATE_PAYMENT_BATCH_FILE_CONFIG';

// Payment Item Map
export const GET_PAYMENT_ITEM_MAP = 'app/payment/GET_PAYMENT_ITEM_MAP';
export const GET_PAYMENT_ITEM_MAP_SUCCESS = 'app/payment/GET_PAYMENT_ITEM_MAP_SUCCESS';
export const MODIFY_PAYMENT_ITEM_MAP = 'app/payment/MODIFY_PAYMENT_ITEM_MAP';
export const CREATE_PAYMENT_ITEM_MAP = 'app/payment/CREATE_PAYMENT_ITEM_MAP';
export const GET_CROSS_CURRENCY_PAYMENT_DETAILS = 'app/payment/GET_CROSS_CURRENCY_PAYMENT_DETAILS';
export const DELETE_PAYMENT_FILE = 'app/payment/DELETE_PAYMENT_FILE';
export const SEARCH_PAYMENTS_REPORT = 'app/payments/SEARCH_PAYMENTS_REPORT';
export const SEARCH_PAYMENTS_SUSPENSE_REPORT = 'app/payments/SEARCH_PAYMENTS_SUSPENSE_REPORT';
export const RESEND_PAYMENT_VENDOR_PAYMENT = 'app/payments/RESEND_PAYMENT_VENDOR_PAYMENT';

export const PROCESS_BATCH_PAYMENT_PROXY = 'app/customers/PROCESS_BATCH_PAYMENT_PROXY';

export const SEARCH_CONFIG_PAYMENT_ALLOCATION = 'app/payment/SEARCH_CONFIG_PAYMENT_ALLOCATION';
export const MODIFY_CONFIG_PAYMENT_ALLOCATION = 'app/payment/MODIFY_CONFIG_PAYMENT_ALLOCATION';
export const CREATE_CONFIG_PAYMENT_ALLOCATION = 'app/payment/CREATE_CONFIG_PAYMENT_ALLOCATION';
export const GET_CONFIG_PYMT_ALLOC_SEQ_BY_ID = 'app/payment/GET_CONFIG_PYMT_ALLOC_SEQ_BY_ID';
