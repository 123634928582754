import React from 'react';
import PropTypes from 'prop-types';

const Switch = ({ title, wrapperClass, onChange, name, subTitle, rightTitle, activeAttr, deactiveAttr, ...props }) => (
  <div
    className={`checkbox checbox-switch switch-danger ${
      props.checked ? 'switch-active' : 'switch-non-active'
    }  ${wrapperClass || ''} ${activeAttr || deactiveAttr ? 'swith-with-label' : ''}`}
  >
    {title && <b>{title || ''}</b>}
    {subTitle && <span>{subTitle || ''}</span>}
    <label htmlFor={name || 'switch'}>
      <input id={name || 'switch'} type="checkbox" name={name} onChange={onChange} {...props} />
      <span className="label-switch" active-attr={activeAttr || ''} deactive-attr={deactiveAttr || ''} />
    </label>
    <b className="ml-2 pt-3">{rightTitle || ''}</b>
  </div>
);

Switch.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  onChange: () => {},
  title: '',
  wrapperClass: '',
};
export default Switch;
