import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { cloneDeep, size } from 'lodash';
import arrayMove from 'array-move';
import { withTranslation, useTranslation } from 'react-i18next';
import TableChildren from './TableChildren';
import { Switch, GenericInput } from '../../../common';

const HandleParent = SortableHandle(() => (
  <button type="button" className="sortable-handle-icon no-border">
    <i className="fa fa-bars" />
  </button>
));

const SortableItemParent = SortableElement(({ data, onRemove, onChange, onUpdateValue, indexItem }) => {
  // console.log('index', indexItem);
  const { t } = useTranslation('common');
  const [isFullSize, setIsFullSize] = useState(false);
  const onChangeSize = () => setIsFullSize(!isFullSize);
  const listOptionsStreamName = t('selections:streamName')();
  const slt = listOptionsStreamName.find(val => val.value === data.streamName);
  // useEffect(() => {
  //   setIsFullSize(indexItem === 0);
  // }, [indexItem]);
  return (
    <div className="col-md-12 mb-1 mt-1 ml-1 row p-1">
      <div className={`col-md-12 row collapsible ${isFullSize ? 'collapsible-full-size' : 'collapsible-close-size'}`}>
        <div className="drag-icon">
          <HandleParent />
        </div>
        <div className="no-padding ml-auto control-group" onClick={onChangeSize}>
          <span className="panel__title">{`${t('label.stream')}: ${slt?.label || ''}`}</span>
          <button type="button" className="float-right btn-control" onClick={onChangeSize}>
            <i className={`fa ${isFullSize ? 'fa-minus' : 'fa-plus'}`} aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className={`col-md-12 mt-2 mr-2 row justify-content-center ${isFullSize ? 'full-screen' : 'close-item'}`}>
        <GenericInput
          value={data.streamName}
          label={t('label.stream')}
          type="select"
          wrapperClass="col-md-4"
          options={listOptionsStreamName}
          onChange={({ name, value }) => onChange({ name, value, index: data.index })}
          name="streamName"
          menuPortalTarget
          required
        />
        <Switch
          title={t('label.active')}
          onChange={evt => onChange({ name: 'active', value: evt.target.checked, index: data.index })}
          checked={data.active === 'true' || data.active === true}
          name={`activeStream-${data.index}`}
          wrapperClass="switch-stream"
        />
        <button
          type="button"
          className="float-right btn-phone mr-4 mt-3"
          onClick={() => onRemove({ index: data.index, item: data })}
        >
          <i className="fa fa-trash" />
        </button>
      </div>
      <span className={`item-stream ${isFullSize ? 'full-screen' : 'close-item'}`}>
        <TableChildren
          data={data.usageProcessModulesSeqCfg}
          onUpdateValue={dataUpdate => onUpdateValue({ dataUpdate, index: data.index })}
        />
      </span>
    </div>
  );
});

const SortableListParent = SortableContainer(({ items, ...props }) => {
  return (
    <div className="col-md-12 mt-2">
      {items.map((data, index) => (
        <SortableItemParent key={`item-${index}`} index={index} indexItem={index} data={data} {...props} />
      ))}
    </div>
  );
});

class ProcessingStreamsChildrenForm extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.data) {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  onHandleChagneTable = ({ name, value, index }) => {
    const { data } = this.state;
    const indexChagne = data.findIndex(item => item.index === index);
    if (indexChagne !== -1) {
      if (typeof value === 'boolean') {
        data[indexChagne][name] = value === true ? 'true' : 'false';
      } else data[indexChagne][name] = value;
    }
    this.setState({ ...data });
  };

  onAddNewStream = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      streamName: null,
      active: 'true',
      isNew: true,
      usageProcessModulesSeqCfg: [{ moduleName: null, active: 'true', logContainer: 'true', index: 1, isNew: true }],
    };

    newData.push(newDataItem);
    this.setState({ data: newData }, () => this.onHanldeUpdateProcessingValue());
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    let newData = cloneDeep(data);
    const tempOldIdx = newData[oldIndex].index;
    newData[oldIndex].index = newData[newIndex].index;
    newData[newIndex].index = tempOldIdx;
    newData = arrayMove(newData, oldIndex, newIndex);
    this.setState({ data: newData }, () => this.onHanldeUpdateProcessingValue());
  };

  onRemoveItem = ({ index, item }) => {
    const { data } = this.state;
    let newData = cloneDeep(data);
    newData = newData.filter(item => item.index !== index);
    if (!item?.isNew) newData.push({ index });

    this.setState({ data: newData }, () => this.onHanldeUpdateProcessingValue());
  };

  onUpdateValue = ({ dataUpdate, index }) => {
    const { data } = this.state;
    const indexChagne = data.findIndex(item => item.index === index);
    if (indexChagne !== -1) data[indexChagne].usageProcessModulesSeqCfg = dataUpdate;
    this.setState({ ...data }, () => this.onHanldeUpdateProcessingValue());
  };

  onHanldeUpdateProcessingValue = () => {
    const { data } = this.state;
    const { onUpdateProcessingValue } = this.props;
    onUpdateProcessingValue({ dataProcess: data });
  };

  render() {
    const { data } = this.state;
    const { t } = this.props;
    const newDataRemoveIndex = data && data.length ? data.filter(item => size(item) > 1) : [];
    return [
      <SortableListParent
        key="1"
        items={newDataRemoveIndex}
        useDragHandle
        onSortEnd={this.onSortEnd}
        onRemove={this.onRemoveItem}
        onChange={this.onHandleChagneTable}
        onUpdateValue={this.onUpdateValue}
      />,
      <div key="2" className="col-md-12 row justify-content-center btn-group-sort">
        <button type="button" className="button button-border gray x-small mb-2" onClick={this.onAddNewStream}>
          {t('label.addAnotherStream')}
        </button>
      </div>,
    ];
  }
}

ProcessingStreamsChildrenForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onUpdateProcessingValue: PropTypes.func,
};

ProcessingStreamsChildrenForm.defaultProps = {
  data: [],
  onUpdateProcessingValue: () => {},
};

export default withTranslation('common')(ProcessingStreamsChildrenForm);
