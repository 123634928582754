import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCcpPropertiesConfig } from '../../../containers/App/actions';
import { makeGetCcpPropertiesConfig } from '../../../containers/App/selectors';

const AccountHeaderFrom = ({
  accountNum,
  title,
  currencyLabel,
  commercialName,
  isCommercialName,
  isOrganization,
  organization,
  ccpPropertyList,
  getCcpPropertiesConfig,
}) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!ccpPropertyList || !ccpPropertyList.length) getCcpPropertiesConfig();
  }, [ccpPropertyList, getCcpPropertiesConfig]);

  const isB2CProperty =
    ccpPropertyList &&
    ccpPropertyList.length &&
    ccpPropertyList.find(ccp => ccp.property === 'customerSegment') &&
    ccpPropertyList.find(ccp => ccp.property === 'customerSegment').value === 'B2C';

  return accountNum ? (
    <div className="d-block d-md-flex nav-tabs-custom account-info header-act-info">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {isCommercialName && commercialName && !isB2CProperty && (
          <li className="nav-item item-info-header d-flex">
            <div
              className="nav-link acct-info-label"
              id="months-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="months"
              aria-selected="true"
            >
              {`${title || t('label.commName')}:`}
            </div>
            <div
              className="nav-link"
              id="year-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="year"
              aria-selected="false"
            >
              {`${commercialName}`}
            </div>
          </li>
        )}
        {isOrganization && organization && (
          <li className="nav-item item-info-header d-flex">
            <div
              className="nav-link acct-info-label"
              id="months-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="months"
              aria-selected="true"
            >
              {`${title || t('label.organization')}:`}
            </div>
            <div
              className="nav-link"
              id="year-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="year"
              aria-selected="false"
            >
              {`${organization}`}
            </div>
          </li>
        )}
        {/* {isCommercialName && (
          <li className="nav-item comm-name-label right-item-info">
            <div
              className="nav-link"
              id="year-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="year"
              aria-selected="false"
            >
              {`${commercialName}`}
            </div>
          </li>
        )} */}
        <li className="nav-item item-info-header d-flex">
          <div
            className="nav-link acct-info-label"
            id="months-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="months"
            aria-selected="true"
          >
            {`${title || t('label.accountNumber')}:`}
          </div>
          <div
            className="nav-link"
            id="year-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="year"
            aria-selected="false"
          >
            {`${accountNum} ${currencyLabel || ''}`}
          </div>
        </li>
      </ul>
    </div>
  ) : (
    ''
  );
};
AccountHeaderFrom.propTypes = {
  title: PropTypes.string,
  currencyLabel: PropTypes.string,
  accountNum: PropTypes.string,
  commercialName: PropTypes.string,
  isCommercialName: PropTypes.bool,
  isOrganization: PropTypes.bool,
  organization: PropTypes.string,
  ccpPropertyList: PropTypes.arrayOf(PropTypes.any),
};

AccountHeaderFrom.defaultProps = {
  title: '',
  accountNum: '' || null,
  currencyLabel: '' || null,
  commercialName: '' || null,
  isCommercialName: false,
  isOrganization: false,
  organization: '' || null,
  ccpPropertyList: [],
};

const mapStateToProps = createStructuredSelector({
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default connect(mapStateToProps, { getCcpPropertiesConfig })(AccountHeaderFrom);
