import gql from 'graphql-tag';

export default () => gql`
  query {
    getTenantInterConnectIndicator(dummy: "") {
      tenantDetailList {
        name
        value
      }
    }
  }
`;
