import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SubscriptionExpirySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.expiryDays',
      name: 'expiryDays',
      type: 'number',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

SubscriptionExpirySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SubscriptionExpirySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SubscriptionExpirySearchForm);
