import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class SearchTopupOffers extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      tOptions: 'selections:serviceType',
      type: 'select',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

SearchTopupOffers.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchTopupOffers.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(SearchTopupOffers);
