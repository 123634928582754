import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'index',
    'roundingPrecision',
    'roundingMethod',
    'accumulateQuantity',
  ]);
  const createAccumulatorsConfig = `createAccumulatorsConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createAccumulatorsConfig} {
      id
    }
  }
`;
};
