import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import JobConfigForm from './JobConfigForm';
import { getJobConfig, modifyJobConfig, createJobConfig } from '../actions';
import { makeJobConfig } from '../selectors';
import { Breadcrumb } from '../../../components/common';
import { makeGetPermissionsJobsManagement } from '../../App/selectors';
import {
  checkPermissionCreateJobConfig,
  checkPermissionModifyJobConfig,
  checkPermissionFindJobConfig,
} from '../CheckPermission';

class JobConfig extends Component {
  state = {
    isCreate: false,
  };

  componentDidMount() {
    this.props.getJobConfig('', ({ isCreate }) => this.setState({ isCreate }));
  }

  onSubmit = jobList => {
    const { modifyJobConfig, createJobConfig } = this.props;
    const { isCreate } = this.state;
    const newJobs = jobList.configJobList.map((e, idx) => ({
      index: idx + 1,
      jobNames: e.jobNames,
    }));
    const {
      jobConfig: { userId, configJobList },
    } = this.props;
    const jobLength = newJobs.length;
    if (configJobList && configJobList.length > 0) {
      configJobList.forEach(jb => {
        if (jb.index > jobLength) newJobs.push({ index: jb.index });
      });
    }
    const newConfig = { userId, configJobList: newJobs };
    if (isCreate) {
      return createJobConfig({ jobConfig: newConfig });
    }
    return modifyJobConfig({ jobConfig: newConfig });
  };

  render() {
    const { jobConfig, permissionsJob, t } = this.props;

    let modeCreateJobConfig = 0;
    let modeModifyJobConfig = 0;
    let modeFindJobConfig = 0;

    if (permissionsJob && permissionsJob.jobsModulePermissions) {
      const listPermission = permissionsJob.jobsModulePermissions;
      modeCreateJobConfig = checkPermissionCreateJobConfig({ listPermission });
      modeModifyJobConfig = checkPermissionModifyJobConfig({ listPermission });
      modeFindJobConfig = checkPermissionFindJobConfig({ listPermission });
    }

    if (modeFindJobConfig === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.jobsManagmentJobSchedule.path} className="button x-small">
                {t('common:label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('jobManagementPage:sidebar.jobScheduleConfig') }]} />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('jobManagementPage:sidebar.jobScheduleConfig')}</h5>
              </div>
              <JobConfigForm
                jobConfig={jobConfig}
                modeCreateJobConfig={modeCreateJobConfig}
                modeModifyJobConfig={modeModifyJobConfig}
                onChangeValueForm={this.onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

JobConfig.propTypes = {
  getJobConfig: PropTypes.func,
  jobConfig: PropTypes.any,
  modifyJobConfig: PropTypes.func,
  permissionsJob: PropTypes.objectOf(PropTypes.any),
};

JobConfig.defaultProps = {
  permissionsJob: {},
};

const mapStateToProps = createStructuredSelector({
  jobConfig: makeJobConfig() || {},
  permissionsJob: makeGetPermissionsJobsManagement() || {},
});
export default withTranslation('jobManagementPage')(
  connect(
    mapStateToProps,
    { getJobConfig, modifyJobConfig, createJobConfig }
  )(JobConfig)
);
