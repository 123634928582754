import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['accountType', 'accountSubType', 'marketSegment', 'salesChannel'])}`
    : '';
  const getRevenueForecastReport = `getRevenueForecastReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRevenueForecastReport} {
        accountId
        organization
        firstName
        lastName
        sellingCompany
        salesChannel
        marketSegment
        accountType
        accountSubType
        revenueData {
          index
          month
          monthRevenue
        }
        startMonth
        monthOneRevenue
        monthTwoRevenue
        monthThreeRevenue
        monthFourRevenue
        monthFiveRevenue
        monthSixRevenue
        monthSevenRevenue
        monthEightRevenue
        monthNineRevenue
        monthTenRevenue
        monthElevenRevenue
        monthTwelveRevenue
      }
    }
  `;
};
