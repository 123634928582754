import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { InputGroup, SelectGroup, DateGroup } from '../common';
import SubmitForm from '../common/SubmitForm';

const searchInputs = [
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
];

const searchSelectors = [
  {
    name: 'type',
    label: 'common:label.type',
    tOptions: 'selections:types',
  },
  {
    name: 'status',
    label: 'common:label.status',
    tOptions: 'selections:statusOrder',
  },
];
const dateSelector = [
  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
  },
  {
    name: 'submittedDate',
    label: 'common:label.submittedDate',
  },
];
class OfferSearchForm extends Component {
  state = {};

  componentDidMount() {
    searchInputs.map(e => this.setState({ [e.name]: '' }));
    searchSelectors.map(e => this.setState({ [e.name]: null }));
    dateSelector.map(e => this.setState({ [e.name]: null }));
  }

  handleInputChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  handleSelectChange = (name, e) => {
    this.setState({ [name]: e ? e.value : '' });
  };

  handleDateChange = (name, e) => {
    this.setState({ [name]: e || '' });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(this.state);
    }
  };

  render() {
    const { isSubmitting, t } = this.props;
    return (
      <SubmitForm
        onSubmit={this.handleSubmit}
        isSubmitting={isSubmitting}
        submitText={t('label.search')}
        btnSearchSpan="col-md-12"
        isNoNeedPermission
      >
        {searchInputs.map(item => (
          <InputGroup key={item.name} {...item} onChange={this.handleInputChange} />
        ))}
        {searchSelectors.map(item => (
          <SelectGroup
            key={item.name}
            options={t(item.tOptions)()}
            {...item}
            value={t(item.tOptions)().find(e => e.value === this.state[item.name])}
            onChange={e => this.handleSelectChange(item.name, e)}
            isClearable
          />
        ))}
        {dateSelector.map(item => (
          <DateGroup key={item.name} {...item} onChange={e => this.handleDateChange(item.name, e)} isClearable />
        ))}
      </SubmitForm>
    );
  }
}

OfferSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OfferSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OfferSearchForm);
