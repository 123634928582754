import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

const CheckGroup = ({ label, checked, wrapperClass, wrapperLabel, validate, tooltipDetails, ...props }) => {
  const inputID =
    props.id ||
    `custom-checkbox-${Math.random()
      .toString()
      .replace('.', '')}`;
  const { t } = useTranslation(['common', 'selections']);
  return (
    <div className={`${props.className} ${wrapperClass}`}>
      {wrapperLabel && <span>{wrapperLabel}</span>}
      <div className={props.checkItemClass || 'check-item'}>
        <div className="custom-control custom-checkbox">
          {checked && (
            <input
              {...props}
              checked={checked}
              defaultChecked
              type="checkbox"
              className="custom-control-input"
              id={inputID}
            />
          )}
          {!checked && (
            <input
              {...props}
              checked={checked}
              type="checkbox"
              className="custom-control-input"
              id={inputID}
              readOnly
            />
          )}
          <label className="custom-control-label" htmlFor={inputID}>
            {t(label)}
            &nbsp;
            {tooltipDetails && (
              <Tooltip
                trigger={['hover']}
                placement="bottom"
                overlay={tooltipDetails ? t(tooltipDetails) : ''}
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                overlayClassName="tooltip-details"
              >
                <i className="ti-info-alt ico-info-tooltip" aria-hidden="true" />
              </Tooltip>
            )}
          </label>
          {validate && <div className="invalid-checkbox">{validate}</div>}
        </div>
      </div>
    </div>
  );
};

CheckGroup.propTypes = {
  wrapperLabel: PropTypes.any,
  label: PropTypes.string,
  validate: PropTypes.string,
};

CheckGroup.defaultProps = {
  wrapperLabel: false,
  label: '',
  validate: '',
};
export default CheckGroup;
