import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideBar from '../../components/common/SideBar';
import RouteName from '../App/RouteNames';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends PureComponent {
  render() {
    const { isExpand = true, permissionsTaxConfig } = this.props;
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsTaxConfig, listKeys: permissionList.taxation }))
      return null;
    if (permissionsTaxConfig && permissionsTaxConfig.taxationModulePermissions) {
      const listPermission = permissionsTaxConfig.taxationModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    const { modeGetTaxConfig, modeGetTaxTypeConfig } = permissions;

    const taxconfig = [];

    if (modeGetTaxTypeConfig) {
      taxconfig.push({
        icon: 'ti-panel',
        label: 'baseConfigurationsPage:sidebar.taxTypeConfig',
        href: RouteName.taxTypeConfig.path,
      });
    }

    if (modeGetTaxConfig !== 0) {
      taxconfig.push({
        icon: 'ti-server',
        label: 'baseConfigurationsPage:sidebar.taxCodeConfig',
        href: RouteName.taxCodeConfig.path,
      });
    }

    const sidePropDetails = {
      isExpand: false,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'baseConfigurationsPage:sidebar.taxation',
          childs: [
            ...taxconfig,
            {
              icon: 'ti-panel',
              label: 'baseConfigurationsPage:sidebar.thirdPartyTaxConfig',
              href: RouteName.thirdPartyTaxConfig.path,
            },
          ],
        },
      ],
    };

    const newSideProps = cloneDeep(sidePropDetails);
    newSideProps.isExpand = isExpand;
    return <SideBar {...newSideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsTaxConfig = store.get('auth').toJS().permissionsApp.billingHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsTaxConfig: permissionsTaxConfig.length
      ? permissionsTaxConfig.filter(a => a.moduleName === 'TAXATION')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  sideProps: {},
  permissionsCollections: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
