import React from 'react';
import PropTypes from 'prop-types';

const CheckField = ({ label, checked, wrapperClass, validate, ...props }) => {
  const inputID =
    props.id ||
    `custom-checkbox-${Math.random()
      .toString()
      .replace('.', '')}`;
  return (
    <div className={`check-item ${wrapperClass || ''}`}>
      <div className="custom-control custom-checkbox">
        {checked && (
          <input
            {...props}
            checked={checked}
            defaultChecked
            type="checkbox"
            className="custom-control-input"
            id={inputID}
          />
        )}
        {!checked && (
          <input {...props} checked={checked} type="checkbox" className="custom-control-input" id={inputID} />
        )}
        <label className="custom-control-label" htmlFor={inputID}>
          {label}
        </label>
        {validate && <div className="invalid-feedback">{validate}</div>}
      </div>
    </div>
  );
};

CheckField.propTypes = {
  label: PropTypes.string,
  validate: PropTypes.string,
};

CheckField.defaultProps = {
  label: '',
  validate: '',
};
export default CheckField;
