import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'barChartDashboardType',
    'xaxisParameter',
    'yaxisParameter',
    'noOfRecordsLimit',
  ]);
  const getBarchartDashboardByType = `getBarchartDashboardByType(input: ${dataCreate})`;
  return gql`
    query {
      ${getBarchartDashboardByType} {
        name
        dataType
        header
        barChartDashboardData {
          name
          value
        }
      }
    }
  `;
};
