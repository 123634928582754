import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, cloneDeep, uniqBy, union } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AccountTable from '../AccountTable';
import ModifyService from './ModifyService';
import UpgradeService from './UpgradeService';
import { getBundleByBundleId, getPackageByPackageId } from '../../App/actions';
import { quoteEnum } from '../../../constantsApp';
import { StepProgressBar, Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import { saveQuote, getQuote } from '../actions';
import { ModalQuoteDetails } from '../../../components/Quotes';
import {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  getServiceUnitsBySubscriptionId,
  getDependentOffers,
} from '../../OrderPage/actions';
import { makeGetServiceUnits, makeGetSubscriptionAssets } from '../../OrderPage/selectors';
import ModalSelectPackageBundle from '../../../components/common/ModalSelectPackageBundle';
import { getUserId } from '../../../localStorages';

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.selectUpgradeOptions',
  },
  {
    step: 3,
    label: 'label.manageCreateQuote',
  },
];

let listServicesDefault = [];
let ids = [];
let packageNames = [];
let odlPackageNames = [];
let odlPackageIds = [];
let provisioningIds = [];

class UpgradeQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: { id: null },
      quoteDetails: this.initQuoteDetails(props),
      subscriptionMessage: '',
      groupServices: [],
      defaultServiceFromAPI: [],
      isOpenModal: false,
      isReadOnly: true,
      isNextStep: false,
      isOpenModalSelect: false,
      isBundle: false,
      itemSelect: {},
      indexOfItemSelect: null,
      serviceNameSelect: null,
      quoteDetailsGet: null,
      isOpenModalDetails: false,
      oldStageSelected: 0,
      isSkip1StStep: !!props.accountId,
    };
    this.services = [];
    this.initServiceUnits = {};
  }

  componentDidMount() {
    const {
      location: { state },
      accountId,
      itemEditSelected,
    } = this.props;
    const {
      selectItem: { id },
    } = this.state;
    if (state && state.accountId && id) {
      this.getSubscriptionByAccountId(id);
    }
    if (accountId) this.getSubscriptionByAccountId(accountId);
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(this.initServiceUnits) !== JSON.stringify(nextProps.subscriptionAssets)) {
      this.initServiceUnits = nextProps.subscriptionAssets;
      this.getServiceUnitsBySubscriptionId(nextProps.subscriptionAssets);
    }
  };

  onToggle = () => {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
    }));
  };

  initQuoteDetails = props => {
    return {
      type: quoteEnum.type.upgrade,
      accountId: props.location.state ? props.location.state.accountId : null,
      userId: getUserId() || 'TestUserId',
      isPartialFulfillmentAllowed: false,
      effectiveDate: moment(new Date()).format('YYYY-MM-DD'),
      submittedDate: moment(new Date()).format('YYYY-MM-DD'),
      initialTerm: '1',
      initialTermUnit: quoteEnum.initialTermUnit.months,
      renewalTerm: '1',
      renewalTermUnit: quoteEnum.renewalTermUnit.months,
      services: [],
    };
  };

  onSelectNext = rowItem => {
    const { quoteDetails } = this.state;
    quoteDetails.accountId = rowItem.id;
    quoteDetails.salesAgentId = rowItem.salesAgentId;
    this.setState(
      {
        selectItem: rowItem,
        quoteDetails,
      },
      () => {
        this.onPressNextButton();
      }
    );
  };

  selectAccount = () => {
    const { selectItem } = this.state;
    if (selectItem && selectItem.id) {
      this.getSubscriptionByAccountId(selectItem.id);
    }
  };

  doGetDependentOffers = ({ service, isChecking, indexOfItem, serviceName }) => {
    this.setState({ indexOfItemSelect: indexOfItem, serviceNameSelect: serviceName });
    const { getDependentOffers, t } = this.props;
    if (service && service.length && isChecking) {
      service.forEach((val, index) => {
        const payload = {
          bundleId: val.packageId || (!val.packageId && !val.bundleId) ? null : val.bundleId,
          packageId: val.packageId || null,
          dependencyType: 'UPGRADE',
          offerType: val.packageId ? 'PACKAGE' : 'BUNDLE',
        };
        getDependentOffers(payload, ({ data, success }) => {
          if (success) {
            if (index === service.length - 1 && !data.dependentOffers) {
              toast.error(t('message.messageNoUpgrade'));
              this.setState({ isNextStep: false });
            }
          }
        });
      });
    }
    if (service && !isChecking) {
      const payload = {
        bundleId: service.packageId ? null : service.bundleId || null,
        packageId: service.packageId || null,
        dependencyType: 'UPGRADE',
        offerType: service.packageId ? 'PACKAGE' : 'BUNDLE',
      };
      this.setState({ isBundle: !service.packageId });
      getDependentOffers(payload, ({ data, success }) => {
        if (success) {
          if (!data.dependentOffers) {
            toast.error(t('message.messageNoUpgrade'));
          } else {
            this.setState({
              isOpenModalSelect: true,
              dataModal: data,
            });
          }
        }
      });
    }
  };

  getSubscriptionByAccountId = id => {
    const { stage } = this.state;
    const { getSubscriptionByAccountId, itemEditSelected } = this.props;
    getSubscriptionByAccountId(id, (success, data) => {
      if (success) {
        this.initQuoteDetailsByAccountId(data, itemEditSelected);
        this.setState({
          stage: stage + 1,
          oldStageSelected: stage + 1,
        });
      } else {
        toast(data, { type: toast.TYPE.ERROR });
      }
    });
  };

  getServiceUnitsBySubscriptionId = data => {
    const { t } = this.props;

    const { quoteDetails, stage } = this.state;
    let services = [];
    let listExistIds = [];
    let isNextStep = false;
    if (data.length) {
      services = data.map((item, index) => {
        const isBundleId = !item.packageId;
        const newLine = item.lines || [];
        for (let i = 0; i < newLine.length; i++) {
          newLine[i].action = 'ADD';
          // newLine[i].action = 'ADD';
        }
        isNextStep = !!item.bundleId || !!item.packageId;
        const newService = {
          index: index + 1,
          serviceType: item.serviceType,
          action: 'ADD',
          // action: 'ADD',
          status: 'CREATED',
          bundleId: item.bundleId || null,
          bundleName: item.bundleName || null,
          packageId: item.packageId || null,
          packageName: item.packageName || null,
          lines: newLine,
          isDeafultService: true,
          isDisabledUpgrade:
            (isBundleId && listExistIds.indexOf(item.bundleId) > -1) ||
            (!isBundleId && listExistIds.indexOf(item.packageId) > -1),
        };
        listExistIds = isBundleId ? union(listExistIds, [item.bundleId]) : union(listExistIds, [item.packageId]);
        return newService;
      });
    }
    const groupServices = groupBy(services, item => {
      return item.serviceType;
    });

    if (!isNextStep && stage > 0) toast.error(t('message.messageUpDownOrder'));

    this.setState({
      quoteDetails: {
        ...quoteDetails,
        services,
      },
      defaultServiceFromAPI: services,
      groupServices,
    });
  };

  initQuoteDetailsByAccountId = (subscription, itemEditSelected) => {
    const { quoteDetails } = this.state;
    if (!subscription || (subscription && subscription.length < 1)) return;
    quoteDetails.accountId = subscription[0].accountId;
    quoteDetails.reason = subscription[0].reason;
    quoteDetails.initialTerm = subscription[0].initialTerm;
    quoteDetails.initialTermUnit = subscription[0].initialTermUnit;
    quoteDetails.trialTerm = subscription[0].trialTerm;
    quoteDetails.trialTermUnit = subscription[0].trialTermUnit;
    quoteDetails.effectiveDate = subscription[0].effectiveDate;
    quoteDetails.salesAgentId = itemEditSelected ? itemEditSelected.salesAgentId : null;
    this.setState({
      quoteDetails,
      isNextStep: false,
    });
  };

  onChangeFormLine = (groupIndex, serviceIndex, e) => {
    // const { quoteDetails, defaultServiceFromAPI } = this.state;
    const { groupServices } = this.state;
    const newGroupServices = cloneDeep(groupServices);
    try {
      const indexChange = newGroupServices[groupIndex][serviceIndex].lines.findIndex(val => val.index === e.lineIndex);
      newGroupServices[groupIndex][serviceIndex].lines[indexChange][e.fieldName] = e.value;
      this.setState({ groupServices: newGroupServices });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProvisioningId = ({ name, value, item }) => {
    const { groupServices } = this.state;
    const newGroupServices = cloneDeep(groupServices);
    try {
      // const indexService = newGroupServices[item.serviceType].findIndex(val => val.index === item.index);
      newGroupServices[item.serviceType].forEach((val, index) => {
        newGroupServices[item.serviceType][index][name] = value;
      });

      this.setState({ groupServices: newGroupServices });
    } catch (error) {
      console.log(error);
    }
  };

  onPressBackButton = () => {
    const { stage } = this.state;
    if (stage > 0) {
      this.setState({
        stage: stage - 1,
        oldStageSelected: stage - 1,
      });
    }
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId,
    };
  };

  addLine = (groupIndex, serviceIndex, bundle) => {
    const { quoteDetails, groupServices } = this.state;
    try {
      groupServices[groupIndex][serviceIndex].lines = bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          quoteDetails.services.push(service);
          return true;
        });
      });
      this.setState({
        quoteDetails,
        groupServices,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onPressNextButton = () => {
    const { selectItem, stage, groupServices, isBundle, quoteDetails } = this.state;
    if (selectItem.id && stage < 1) {
      return this.selectAccount();
    }
    if (stage === 1) {
      ids = [];
      packageNames = [];
      odlPackageNames = [];
      odlPackageIds = [];
      provisioningIds = [];
      listServicesDefault = [];
      if (!isBundle) {
        this.setState({ quoteDetails });
        let listService = [];
        map(groupServices, item => {
          if (item && item.length)
            provisioningIds = cloneDeep([
              ...provisioningIds,
              { serviceType: item[0].serviceType, provisioningId: item[0].provisioningId },
            ]);
          const newService = item.filter(val => !!val.newId);
          if (newService && newService.length) listService = [...listService, ...newService];
        });
        if (listService && listService.length)
          listService.forEach((ser, index) => {
            this.doGetPackageUpgradeQuote({ item: ser, id: ser.newId, isLastItem: listService.length === index + 1 });
          });
      }
      // return this.setState({ dataStep2: groupServices, stage: stage + 1, oldStageSelected: stage + 1 });
      return this.setState({ stage: stage + 1, oldStageSelected: stage + 1 });
    }

    return this.setState({
      stage: stage + 1,
      oldStageSelected: stage + 1,
    });
  };

  onPressCreateButton = isSave => {
    const { quoteDetails, groupServices, isBundle } = this.state;
    const newQuoteDetails = cloneDeep(quoteDetails);
    newQuoteDetails.services = [];
    delete newQuoteDetails.effectiveDate;
    map(groupServices, serviceGroup => {
      return serviceGroup
        .filter(val => !!val.newName)
        .map(service => {
          const {
            bundleName,
            packageName,
            newId,
            newName,
            isOverrideLines,
            isDeafultService,
            status,
            provisioningId,
            ...newService
          } = cloneDeep(service);
          if (isBundle) {
            newService.currentOfferId = newService.bundleId;
            newService.bundleId = newId;
          }
          if (!isBundle) {
            newService.currentOfferId = newService.packageId;
            newService.packageId = newId;
          }
          if (newService.lines && newService.lines.length) {
            for (let i = 0; i < newService.lines.length; i++) {
              delete newService.lines[i].priceOfferName;
              delete newService.lines[i].discountOfferName;
              delete newService.lines[i].status;
              delete newService.lines[i].id;

              if (newService.lines[i] && newService.lines[i].discountOfferId) {
                newService.lines[i].discAmountOverride = newService.lines[i].priceOverride;
                newService.lines[i].discPercentOverride = newService.lines[i].discountPercent;
                delete newService.lines[i].priceOverride;
                delete newService.lines[i].discountPercent;
              }
            }
          }
          if (!isOverrideLines) newService.lines = null;
          newQuoteDetails.services.push(newService);
          return true;
        });
    });
    const { doSaveQuote, getQuote, id, isComponent } = this.props;
    if (isSave) {
      return doSaveQuote({ ...newQuoteDetails, id: id || null }, null, isComponent);
    }
    return getQuote({ ...newQuoteDetails, id: id || null }, ({ success, data }) => {
      if (success) {
        this.setState({ isOpenModalDetails: true, quoteDetailsGet: data || {} });
      }
    });
  };

  onRemoveItem = ({ itemRemove, isBundle = false, isPackage = false }) => {
    const { quoteDetails, defaultServiceFromAPI } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(quoteDetails);
    if (isBundle) {
      const indexItemRemove = newData.services.findIndex(item => item.index === itemRemove.index);
      if (defaultServiceFromAPI.findIndex(item => item.index === itemRemove.index) > -1) {
        if (newData.services[indexItemRemove].lines) delete newData.services[indexItemRemove].lines;
        newData.services[indexItemRemove].action = 'CANCEL';
      } else {
        newData.services.splice(indexItemRemove, 1);
      }
    }

    if (isPackage) {
      const groupIndex = newData.services.filter(item => item.index === itemRemove.index).map(item => item.index);
      groupIndex.forEach(item => {
        const indexItemRemove = newData.services.findIndex(v => v.index === item);
        if (defaultServiceFromAPI.findIndex(value => value.index === item) > -1) {
          // newData.services[indexItemRemove].action = 'CANCEL';
          newData.services[indexItemRemove].action = 'MODIFY';
          if (newData.services[indexItemRemove].lines)
            newData.services[indexItemRemove].lines.forEach((val, i) => {
              newData.services[indexItemRemove].lines[i].action = 'CANCEL';
            });
        } else {
          newData.services.splice(indexItemRemove, 1);
        }
      });
    }

    if (!isBundle && !isPackage) {
      const indexItemRemove = newData.services.findIndex(item => item.provisioningId === itemRemove.provisioningId);
      if (defaultServiceFromAPI.findIndex(item => item.provisioningId === itemRemove.provisioningId) > -1) {
        newData.services[indexItemRemove].action = 'MODIFY';
        if (newData.services[indexItemRemove].lines)
          newData.services[indexItemRemove].lines.forEach((val, i) => {
            newData.services[indexItemRemove].lines[i].action = 'CANCEL';
          });
      } else {
        newData.services.splice(indexItemRemove, 1);
      }
    }

    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ quoteDetails: newData, groupServices, isOpenModal: false });
  };

  onRemoveLine = () => {};

  onClickItem = (evt, row) => {
    evt.preventDefault();
    const { itemSelect } = this.state;
    const isBundle = !row.packageId;
    if (
      (isBundle && row.bundleId && itemSelect.id === row.bundleId) ||
      (!isBundle && itemSelect.id === row.packageId)
    ) {
      return this.setState({ itemSelect: {} });
    }
    if (!isBundle) {
      return this.setState({ itemSelect: { id: row.packageId, name: row.name } });
    }
    return this.setState({ itemSelect: { id: row.bundleId, name: row.name } });
  };

  onSelectItem = () => {
    const { indexOfItemSelect, itemSelect, serviceNameSelect, groupServices } = this.state;
    const newGroupService = cloneDeep(groupServices);
    if (itemSelect.id) {
      newGroupService[serviceNameSelect][indexOfItemSelect].newId = itemSelect.id;
      newGroupService[serviceNameSelect][indexOfItemSelect].newName = itemSelect.name;
    }
    this.setState({ isOpenModalSelect: false, groupServices: newGroupService, isNextStep: true });
  };

  toggleModal = () => {
    const { isOpenModalSelect } = this.state;
    this.setState({ isOpenModalSelect: !isOpenModalSelect, itemSelect: {} });
  };

  onHandleInitNewLines = ({ id, item }) => {
    try {
      const { groupServices, isBundle } = this.state;
      const newGroupServices = cloneDeep(groupServices);
      const { getBundleByBundleId } = this.props;
      if (isBundle) {
        getBundleByBundleId(id, ({ success, data }) => {
          if (success) {
            const indexService = newGroupServices[item.serviceType].findIndex(val => val.index === item.index);
            if (indexService !== -1) {
              newGroupServices[item.serviceType][indexService].isOverrideLines = true;
              newGroupServices[item.serviceType][indexService].lines =
                data && data.components
                  ? data.components.map(component => {
                      return this.initLineFromBundle(data, component);
                    })
                  : [];
            }
            this.setState({ groupServices: newGroupServices });
          }
        });
      } else {
        const indexService = newGroupServices[item.serviceType].findIndex(val => val.index === item.index);
        newGroupServices[item.serviceType][indexService].isOverrideLines = true;
        this.setState({ groupServices: newGroupServices });
      }
    } catch (error) {
      console.log(error);
    }
  };

  doGetPackageUpgradeQuote = ({ id, item, isLastItem }) => {
    const { getBundleByBundleId, getPackageByPackageId } = this.props;
    getPackageByPackageId(id, ({ success, data }) => {
      if (success) {
        let newListService = cloneDeep(listServicesDefault);
        if (data.components && data.components.length) {
          data.components.forEach(() => {
            ids = cloneDeep([...ids, id]);
            packageNames = cloneDeep([...packageNames, data.name]);
            odlPackageNames = cloneDeep([...odlPackageNames, item.packageName]);
            odlPackageIds = cloneDeep([...odlPackageIds, item.packageId]);
            provisioningIds = cloneDeep([
              ...provisioningIds,
              { serviceType: item.serviceType, provisioningId: item.provisioningId },
            ]);
          });
        }
        newListService = [...newListService, ...data.components];
        listServicesDefault = newListService;
        if (isLastItem) {
          const listServiceInit = newListService.map((val, index) => {
            return {
              index: index + 1,
              packageId: odlPackageIds[index],
              bundleId: val.bundleId,
              provisioningId: provisioningIds.find(ids => ids.serviceType === val.serviceType)
                ? provisioningIds.find(ids => ids.serviceType === val.serviceType).provisioningId
                : '',
              action: 'ADD',
              bundleName: val.bundleName,
              serviceType: val.serviceType,
              packageName: odlPackageNames[index],
              lines: [],
              newId: ids[index],
              newName: packageNames[index],
            };
          });
          if (listServiceInit && listServiceInit.length) {
            listServiceInit.forEach((bundle, idx) => {
              getBundleByBundleId(bundle.bundleId, dataBundle => {
                if (dataBundle.success) {
                  if (dataBundle.data.components && dataBundle.data.components.length) {
                    listServiceInit[idx].bundleName = dataBundle.data.name;
                    dataBundle.data.components.forEach((val, j) => {
                      listServiceInit[idx].lines[j] = {};
                      listServiceInit[idx].lines[j].index = j + 1;
                      listServiceInit[idx].lines[j].priceOfferId = val.priceOfferId || null;
                      listServiceInit[idx].lines[j].action = 'ADD';
                      listServiceInit[idx].lines[j].priceOfferName = val.priceOfferName;
                      listServiceInit[idx].lines[j].discountOfferName = val.discountOfferName;
                    });
                  }
                }
              });
            });
          }
          const checkDuplicateService = uniqBy(listServiceInit, v =>
            [v.serviceType, v.packageId, v.currentOfferId].join()
          );
          const newGroupServices = groupBy(checkDuplicateService, item => {
            return item.serviceType;
          });
          this.setState({ groupServices: newGroupServices });
        }
      }
    });
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      status: 'CREATED',
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId,
      discountOfferName: component.discountOfferName,
      priceOfferName: component.priceOfferName,
    };
  };

  initServiceWithSelectPackage = ({ index, bundleId, packageId, serviceType, packageName, bundleName }) => {
    return {
      index,
      serviceType,
      action: 'ADD',
      status: quoteEnum.status.create,
      bundleId,
      packageId,
      packageName,
      bundleName,
      lines: [],
    };
  };

  renderContent = ({ modeDeletePriceOffer, modeOverrideQuoteLines }) => {
    const {
      stage,
      selectItem,
      quoteDetails,
      subscriptionMessage,
      groupServices,
      isNextStep,
      isOpenModal,
      isReadOnly,
      itemSelect,
      isBundle,
      isSkip1StStep,
    } = this.state;
    switch (stage) {
      case 0:
        return isSkip1StStep ? null : (
          <AccountTable
            type="upgrade-quote"
            subscriptionStatus="ACTIVE"
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isCheckSalesAgentId
            linkTo={RouteNames.searchQuoteAndList.path}
            isNoPageTitle
          />
        );
      case 1:
        return (
          <UpgradeService
            id={selectItem.id}
            quoteDetails={quoteDetails}
            groupServices={groupServices}
            subscriptionMessage={subscriptionMessage}
            onChangeFormLine={this.onChangeFormLine}
            onChangeProvisioningId={this.onChangeProvisioningId}
            addLine={this.addLine}
            isOpen={isOpenModal}
            onRemoveItem={this.onRemoveItem}
            onRemoveLine={this.onRemoveLine}
            onToggle={this.onToggle}
            onChangeModifyService={this.onChangeModifyService}
            isReadOnly={isReadOnly}
            isNextStep={isNextStep}
            doGetDependentOffers={this.doGetDependentOffers}
            itemSelect={itemSelect}
          />
        );

      case 2:
        return (
          <ModifyService
            id={selectItem.id}
            modeDeletePriceOffer={modeDeletePriceOffer}
            quoteDetails={quoteDetails}
            groupServices={groupServices}
            subscriptionMessage={subscriptionMessage}
            onChangeFormLine={this.onChangeFormLine}
            onChangeProvisioningId={this.onChangeProvisioningId}
            addLine={this.addLine}
            isOpen={isOpenModal}
            onRemoveItem={this.onRemoveItem}
            onRemoveLine={this.onRemoveLine}
            onToggle={this.onToggle}
            onChangeModifyService={this.onChangeModifyService}
            isReadOnly={isReadOnly}
            isBundle={isBundle}
            onHandleInitNewLines={this.onHandleInitNewLines}
            modeOverrideQuoteLines={modeOverrideQuoteLines}
          />
        );

      default:
        return <AccountTable onClickRow={this.onClickRow} selectItem={selectItem} onSelectNext={this.onSelectNext} />;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    if (step < stage + 1 || step <= oldStageSelected + 1)
      this.setState({
        stage: step - 1,
        // groupServices: step === 1 ? [] : dataStep2,
        // itemSelect: step === 1 ? {} : itemSelect,
      });
  };

  onChangeModifyService = () => {
    const { isReadOnly } = this.state;
    this.setState({ isReadOnly: !isReadOnly });
  };

  onToggleModalDetais = () => {
    this.setState({ isOpenModalDetails: false, quoteDetailsGet: null });
  };

  render() {
    const {
      stage,
      quoteDetailsGet,
      isOpenModalDetails,
      itemSelect,
      isNextStep,
      isOpenModalSelect,
      dataModal,
      isBundle,
      oldStageSelected,
    } = this.state;
    const { permissions, t, isComponent, onToggle } = this.props;

    const { modeModifyQuoteType, modeDeletePriceOffer, modeOverrideQuoteLines, modeSaveQuote, modeGetQuote } =
      permissions || {};

    if (!modeModifyQuoteType) {
      return '';
    }

    const { newQuoteDetails, oldQuoteDetails, accountId } = quoteDetailsGet || {};
    const isHasOldQuote = oldQuoteDetails && oldQuoteDetails.serviceLevelQuoteDetails;

    return (
      <div>
        {!isComponent && (
          <div className="col-md-12 row p-0">
            <div className="col-sm-6">
              <Link to={RouteNames.searchQuoteAndList.path} type="button" className="button x-small mb-3">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6 pr-0">
              <Breadcrumb
                items={[
                  {
                    name: t('quotePage:sidebar.upgradeQuote'),
                    url: RouteNames.upgradeQuote.path,
                  },
                ]}
              />
            </div>
          </div>
        )}
        <div className="col-md-12 row text-center pb-4">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              oldSelected={oldStageSelected}
              listSteps={listSteps}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3">
            <button
              type="button"
              className="button button-border x-small gray  "
              onClick={onToggle || this.onPressBackButton}
            >
              {t('label.cancel')}
            </button>
            {stage < 2 ? (
              <button
                type="button"
                className="button button-border x-small "
                onClick={this.onPressNextButton}
                disabled={!isNextStep}
              >
                {t('label.next')}
              </button>
            ) : (
              <>
                {!!modeGetQuote && (
                  <button
                    type="button"
                    className="button button-border x-small "
                    onClick={() => this.onPressCreateButton(false)}
                    disabled={modeGetQuote === 1}
                  >
                    {t('label.getQuote')}
                  </button>
                )}
                {!!modeSaveQuote && (
                  <button
                    type="button"
                    className="button button-border x-small "
                    onClick={() => this.onPressCreateButton(true)}
                    disabled={modeSaveQuote === 1}
                  >
                    {t('label.save')}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {this.renderContent({
          modeDeletePriceOffer,
          modeOverrideQuoteLines,
        })}

        {/* <div className="form-group col-md-12 mt-4 justify-content-center d-flex row ">

        </div> */}
        <ModalSelectPackageBundle
          isOpen={isOpenModalSelect}
          onSelect={this.onSelectItem}
          isBundle={isBundle}
          toggleModal={this.toggleModal}
          data={dataModal && dataModal.dependentOffers.length ? dataModal.dependentOffers : []}
          selectedParentId={itemSelect || null}
          onClickItem={this.onClickItem}
          onCancel={() => {
            this.setState({ isOpenModalSelect: false });
          }}
        />

        <ModalQuoteDetails
          isOpenModalDetails={isOpenModalDetails}
          onToggle={this.onToggleModalDetais}
          oldQuoteDetails={oldQuoteDetails}
          newQuoteDetails={newQuoteDetails}
          isHasOldQuote={isHasOldQuote}
          isSupportColor
          accountId={accountId}
        />
      </div>
    );
  }
}

UpgradeQuote.propTypes = {
  getSubscriptionByAccountId: PropTypes.func.isRequired,
  getQuote: PropTypes.func.isRequired,
  doSaveQuote: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  subscriptionAssets: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  permissions: PropTypes.objectOf(PropTypes.any),
  getDependentOffers: PropTypes.func.isRequired,
  getBundleByBundleId: PropTypes.func.isRequired,
  getPackageByPackageId: PropTypes.func.isRequired,
  t: PropTypes.func,
};

UpgradeQuote.defaultProps = {
  subscriptionAssets: [],
  location: {},
  permissions: {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  serviceUnits: makeGetServiceUnits(),
  subscriptionAssets: makeGetSubscriptionAssets(),
});

const UpgradeQuoteT = connect(mapStateToProps, {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  doSaveQuote: saveQuote,
  getServiceUnitsBySubscriptionId,
  getDependentOffers,
  getBundleByBundleId,
  getPackageByPackageId,
  getQuote,
})(UpgradeQuote);

export default withTranslation('common')(UpgradeQuoteT);
