import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

let secondsValue = null;

const CustomTimeInput = ({ date, value, onChange }) => {
  let newValue = value;
  if (date) newValue = moment(date).format('HH:mm:ss');
  return (
    <input
      type="time"
      step={2}
      value={newValue}
      onChange={e => {
        secondsValue = e.target.value;
        onChange(e.target.value);
      }}
      // style={{ border: 'solid 1px pink' }}
    />
  );
};

class DateGroup extends React.PureComponent {
  state = { isOpenDatePicker: false };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.value) return null;
    return {
      value: props.value ? moment(props.value).toDate() : null,
      oldValue: props.value,
    };
  }

  onHandleOpenDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (!isOpenDatePicker) this.setState({ isOpenDatePicker: true });
  };

  onHandleCloseDatePicker = () => {
    const { isOpenDatePicker } = this.state;
    if (isOpenDatePicker) this.setState({ isOpenDatePicker: false });
  };

  handleChange = value => {
    this.setState({ value, isOpenDatePicker: false });
    const { onChange } = this.props;
    try {
      if (onChange) {
        if (value) {
          if (secondsValue) {
            return onChange(
              moment(`${moment(value).format('YYYY-MM-DD')} ${secondsValue || '00:00:00'}`).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            );
          }
          return onChange(
            moment(value).format('YYYY-MM-DD HH:mm:ss')
          );
        }
        onChange(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    // const { isOpenDatePicker } = this.state;
    const { label, wrapperClass, disabled, t, ...restProps } = this.props;
    secondsValue = null;
    return (
      <div className={`form-group ${wrapperClass}`}>
        {label && (
          <span>
            {t(label)}
            {label && restProps.required && <i className="fa fa-star is-required" />}
          </span>
        )}
        <DatePicker
          className="form-control"
          // open={isOpenDatePicker}
          id="from-date"
          // dateFormat="MM/dd/yyyy h:mm aa"
          // onFocus={this.onHandleOpenDatePicker}
          // onClickOutside={this.onHandleCloseDatePicker}
          selected={this.state.value}
          disabled={disabled}
          isClearable={!disabled}
          autoComplete="off"
          dateFormat="YYYY-MM-DD"
          timeFormat="HH:mm:ss"
          popperModifiers={{
            flip: {
              behavior: ['bottom'],
            },
          }}
          {...restProps}
          onChange={this.handleChange}
          showMonthDropdown
          showYearDropdown
          // yearDropdownItemNumber={20}
          scrollableYearDropdown
          dropdownMode="select"
          timeInputLabel="Time:"
          showTimeInput
          customTimeInput={<CustomTimeInput />}
        />
        <button
          type="button"
          className="icon-calendar no-border"
          onClick={this.onHandleOpenDatePicker}
          disabled={disabled}
          // onBlur={this.onHandleCloseDatePicker}
        >
          <i className="fa fa-calendar" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

DateGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
};

DateGroup.defaultProps = {
  label: '',
  placeholder: 'Select Date',
  wrapperClass: 'col-md-3',
};

export default withTranslation('common')(DateGroup);
