import gql from 'graphql-tag';

export default id => gql`
  query {
    searchBalanceUnitByAccountId(input: { id: "${id}" }) {
      accountId
      balanceUnits {
        subscriptionId
        subscriptionName
        balances {
          currencyId
          amount
          creditLimit
        }
        grants {
          index
          resourceId
          grantAmount
          amountUsed
          startDate
          endDate
          grantName
          grantType
        }
        accumulators {
          index
          accumulatorId
          amount
          startDate
          endDate
          accumulatorName
          accumulateQuantity
        }
      }
    }
  }
`;
