import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, FormCollapse, TitleFrom, Switch } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getBusinessUnits, modifyBusinessUnits, createBusinessUnits } from '../actions';
import { listFieldsBusinessUnits } from '../constants';
import ModalCompanyLocation from '../../../components/common/ModalCompanyLocation';
import ModalCompanyEnterprise from '../../../components/common/ModalCompanyEnterprise';
import ModalCompanyDivision from '../../../components/common/ModalCompanyDivision';
import ModalLegalEnities from '../../../components/common/ModalLegalEnities';

class BusinessUnits extends React.PureComponent {
  state = {
    data: [],
    wasValidated: false,
    formValid: true,
    activeTab: {},
    showActiveOnly: true,
    isOpenModalName: '',
    locationSelect: {},
    enterpriseSelect: {},
    divisionSelect: {},
    legalEntitySelect: {},
    dataSubmit: [],
    defaultData: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetBusinessUnits();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetBusinessUnits = () => {
    const { getBusinessUnits } = this.props;
    const { showActiveOnly } = this.state;
    getBusinessUnits({ showActiveOnly }, ({ data }) => {
      this.setState({
        data: data || [],
        dataSubmit: [],
      });
    });
    this.checkExistData();
  };

  checkExistData = () => {
    const { getBusinessUnits } = this.props;
    getBusinessUnits({ showActiveOnly: false }, ({ data }) => {
      this.setState({
        isModify: !!data,
        id: data ? data[0].id : null,
        defaultData: data || [],
      });
    });
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit, defaultData } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (defaultData)
        defaultData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        name: '',
        description: '',
        shortName: '',
        code: '',
        enterprise: null,
        division: null,
        legalEntity: '',
        locationIndex: null,
        status: '',
      };
      newData.push(payload);
      dataSubmit[newData.length - 1] = payload;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit, id } = this.state;
    const { modifyBusinessUnits, createBusinessUnits } = this.props;
    const payload = cloneDeep(data).map(val => {
      const { address, id, ...rest } = val;
      return rest;
    });
    if (isModify) {
      return modifyBusinessUnits({ buList: dataSubmit.filter(val => !!val), id }, ({ success }) => {
        if (success) this.doGetBusinessUnits();
      });
    }
    return createBusinessUnits({ buList: payload }, ({ success }) => {
      if (success) this.doGetBusinessUnits();
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData[index][name] = value;
      if (!dataSubmit[index]) dataSubmit[index] = { index: newData[index].index };
      dataSubmit[index][name] = value;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSwitch = () => {
    const { showActiveOnly } = this.state;
    this.setState({ showActiveOnly: !showActiveOnly }, () => this.doGetBusinessUnits());
  };

  onOpenPopup = (name, index) => {
    this.setState({ isOpenModalName: name, indexSelect: index });
  };

  onSelectItem = (value, row) => {
    const { data, isOpenModalName, indexSelect, dataSubmit } = this.state;
    let { locationSelect, enterpriseSelect, divisionSelect, legalEntitySelect } = this.state;
    const newData = cloneDeep(data);
    if (!dataSubmit[indexSelect]) dataSubmit[indexSelect] = { index: newData[indexSelect].index };
    if (isOpenModalName === 'locationIndex') {
      if (value) {
        newData[indexSelect].address = row || null;
        newData[indexSelect].locationIndex = value;
        locationSelect = row;
        dataSubmit[indexSelect].locationIndex = value;
      }
      if (!value) {
        newData[indexSelect].locationIndex = null;
        newData[indexSelect].address = null;
        locationSelect = {};
        dataSubmit[indexSelect].locationIndex = null;
      }
    }
    if (isOpenModalName === 'enterprise') {
      if (value) {
        newData[indexSelect].enterprise = value;
        newData[indexSelect].locationIndex = row.locationIndex;
        newData[indexSelect].address = row.address || null;
        enterpriseSelect = row || null;
        dataSubmit[indexSelect].enterprise = value;
        dataSubmit[indexSelect].locationIndex = row.locationIndex;
      }
      if (!value) {
        newData[indexSelect].enterprise = null;
        newData[indexSelect].locationIndex = null;
        newData[indexSelect].address = null;
        enterpriseSelect = {};
        dataSubmit[indexSelect].enterprise = null;
        dataSubmit[indexSelect].locationIndex = null;
      }
    }

    if (isOpenModalName === 'division') {
      if (value) {
        newData[indexSelect].division = value;
        newData[indexSelect].enterprise = row.enterprise;
        divisionSelect = row || null;
        dataSubmit[indexSelect].division = value;
        dataSubmit[indexSelect].enterprise = row.enterprise;
      }
      if (!value) {
        newData[indexSelect].division = null;
        newData[indexSelect].enterprise = null;
        divisionSelect = {};
        dataSubmit[indexSelect].division = null;
        dataSubmit[indexSelect].enterprise = null;
      }
    }

    if (isOpenModalName === 'legalEntity') {
      if (value) {
        newData[indexSelect].legalEntity = value;
        newData[indexSelect].enterprise = row.enterprise;
        newData[indexSelect].division = row.division;
        legalEntitySelect = row || null;
        dataSubmit[indexSelect].legalEntity = value;
        dataSubmit[indexSelect].enterprise = row.enterprise;
        dataSubmit[indexSelect].division = row.division;
      }
      if (!value) {
        newData[indexSelect].legalEntity = null;
        newData[indexSelect].enterprise = null;
        newData[indexSelect].division = null;
        legalEntitySelect = {};
        dataSubmit[indexSelect].legalEntity = null;
        dataSubmit[indexSelect].enterprise = null;
        dataSubmit[indexSelect].division = null;
      }
    }

    this.setState({
      data: newData,
      isOpenModalName: '',
      dataSubmit,
      locationSelect,
      enterpriseSelect,
      divisionSelect,
      legalEntitySelect,
    });
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateBusinessUnitConfig, modeGetBusinessUnitConfig, modeModifyBusinessUnitConfig } = permissions;
    if (!modeGetBusinessUnitConfig) return '';
    const {
      data,
      activeTab,
      wasValidated,
      showActiveOnly,
      isOpenModalName,
      locationSelect,
      enterpriseSelect,
      divisionSelect,
      legalEntitySelect,
    } = this.state;
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('label.businessUnits')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('label.businessUnits')} />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="col-md-12 pt-0 pb-5">
                <Switch
                  onChange={this.onChangeSwitch}
                  wrapperClass={showActiveOnly ? 'switch-active float-right' : 'switch-non-active float-right'}
                  rightTitle={t('label.ACTIVE')}
                  checked={showActiveOnly}
                  title={t('label.all')}
                />
                <span className="float-right title-fetch">{`${t('label.fetch')}:`}</span>
              </div>

              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <FormCollapse
                    key={`key-${index}`}
                    isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                    title={`${item.code || ''}-${item.name || ''}`}
                    state={`state-${index}`}
                    onToggleTab={this.onToggleTab}
                  >
                    <div className="col-md-12 row p-3">
                      {listFieldsBusinessUnits.map(val => {
                        if (val.name === 'locationIndex') {
                          return (
                            <GenericInput
                              value={
                                item.address
                                  ? `${item.address.city}, ${item.address.state}, ${item.address.country}`
                                  : ''
                              }
                              wrapperClass="col-md-4"
                              type={val.tOptions ? 'select' : 'text'}
                              onChange={({ name, value }) => this.onHandleChange({ value, name })}
                              onClick={() => this.onOpenPopup(val.name, index)}
                              {...val}
                            />
                          );
                        }
                        return (
                          <GenericInput
                            value={val.isBoolean ? (item[val.name] ? 'TRUE' : 'FALSE') : item[val.name]}
                            wrapperClass="col-md-4"
                            type={val.tOptions ? 'select' : 'text'}
                            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
                            onClick={val.fa ? () => this.onOpenPopup(val.name, index) : () => {}}
                            {...val}
                          />
                        );
                      })}
                    </div>
                  </FormCollapse>
                ))}

              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateBusinessUnitConfig === 2 && (
                  <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                    +
                    {t('label.addNewBusinessUnits')}
                  </button>
                )}
                {modeModifyBusinessUnitConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={!data || !data.length}
                  >
                    {t('label.saveConfig')}
                  </button>
                )}
                <button
                  onClick={this.doGetBusinessUnits}
                  type="button"
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ModalCompanyLocation
          isOpen={isOpenModalName === 'locationIndex'}
          onSelect={this.onSelectItem}
          selectedParentId={locationSelect.locationIndex}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalCompanyEnterprise
          isOpen={isOpenModalName === 'enterprise'}
          onSelect={this.onSelectItem}
          selectedParentId={enterpriseSelect.enterprise}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalCompanyDivision
          isOpen={isOpenModalName === 'division'}
          onSelect={this.onSelectItem}
          selectedParentId={divisionSelect.division}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
        <ModalLegalEnities
          isOpen={isOpenModalName === 'legalEntity'}
          onSelect={this.onSelectItem}
          selectedParentId={legalEntitySelect.legalEntity}
          onCancel={() => {
            this.setState({ isOpenModalName: '' });
          }}
        />
      </div>
    );
  }
}

BusinessUnits.propTypes = {
  getBusinessUnits: PropTypes.func,
  modifyBusinessUnits: PropTypes.func,
};

BusinessUnits.defaultProps = {
  getBusinessUnits: () => {},
  modifyBusinessUnits: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getBusinessUnits,
    modifyBusinessUnits,
    createBusinessUnits,
  })(withRouter(BusinessUnits))
);
