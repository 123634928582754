import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'poAmount']);
  const modifyExternalPO = `modifyExternalPO(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyExternalPO} {
      id
    }
  }
`;
};
