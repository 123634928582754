import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class VoiceExcessTrafficReportSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
      required: true,
      defaultValue: moment(). startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
      required: true,
      defaultValue: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.callType',
      name: 'usageType',
      fa: 'fa fa-external-link',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
      fa: 'fa fa-external-link',
    },
    {
      label: 'label.organization',
      name: 'organization',
      fa: 'fa fa-external-link',
    },
  ];

  btnSpan = 'col-md-9';
}

VoiceExcessTrafficReportSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

VoiceExcessTrafficReportSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(VoiceExcessTrafficReportSearchForm);
