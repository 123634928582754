// -------BUNDLES
// search bundles
export const SEARCH_BUNDLES = 'app/pricinghub/search_bundles';
export const SEARCH_BUNDLES_SUCCESS = 'app/pricinghub/search_bundles_success';
export const SEARCH_BUNDLES_FAILURE = 'app/pricinghub/search_bundles_failure';
export const SEARCH_BUNDLES_ID = 'app/pricinghub/search_bundles_id';
export const SET_PARAMS_BUNDLES = 'app/bundles/set_params_bundles';

// get bundle
export const GET_BUNDLE_DETAIL = 'app/pricinghub/get_bundle_detail';
export const GET_BUNDLE_DETAIL_SUCCESS = 'app/pricinghub/get_bundle_detail_success';
export const GET_BUNDLE_DETAIL_FAILURE = 'app/pricinghub/get_bundle_detail_failure';

// modify bundle
export const MODIFY_BUNDLE = 'app/pricinghub/modify_bundle';
export const MODIFY_BUNDLE_SUCCESS = 'app/pricinghub/modify_bundle_success';

// update bundle status
export const UPDATE_BUNDLE_STATUS = 'app/pricinghub/update_bundle_status';
export const UPDATE_BUNDLE_STATUS_SUCCESS = 'app/pricinghub/update_bundle_status_success';

// create bundle
export const CREATE_BUNDLE = 'app/pricinghub/create_bundle';
export const CREATE_BUNDLE_SUCCESS = 'app/pricinghub/create_bundle_success';
export const CREATE_BUNDLE_FAILURE = 'app/pricinghub/create_bundle_failure';

// -------PACKAGES
// search packages
export const SEARCH_PACKAGES = 'app/pricinghub/search_packages';
export const SEARCH_PACKAGES_SUCCESS = 'app/pricinghub/search_packages_success';
export const SEARCH_PACKAGES_FAILURE = 'app/pricinghub/search_packages_failure';
export const SEARCH_PACKAGES_ID = 'app/pricinghub/search_packages_id';
export const SET_PARAMS_PACKAGES = 'app/packages/set_params_packages';

// get package
export const GET_PACKAGE_DETAIL = 'app/pricinghub/get_package_detail';
export const GET_PACKAGE_DETAIL_SUCCESS = 'app/pricinghub/get_package_detail_success';
export const GET_PACKAGE_DETAIL_FAILURE = 'app/pricinghub/get_package_detail_failure';

// modify package
export const MODIFY_PACKAGE = 'app/pricinghub/modify_package';
export const MODIFY_PACKAGE_SUCCESS = 'app/pricinghub/modify_package_success';

// update package status
export const UPDATE_PACKAGE_STATUS = 'app/pricinghub/update_package_status';
export const UPDATE_PACKAGE_STATUS_SUCCESS = 'app/pricinghub/update_package_status_success';

// create package
export const CREATE_PACKAGE = 'app/pricinghub/create_package';
export const CREATE_PACKAGE_SUCCESS = 'app/pricinghub/create_package_success';
export const CREATE_PACKAGE_FAILURE = 'app/pricinghub/create_package_failure';

// -------DEPENDENCIES
// search dependencies
export const SEARCH_DEPENDENCIES = 'app/pricinghub/search_dependencies';
export const SEARCH_DEPENDENCIES_SUCCESS = 'app/pricinghub/search_dependencies_success';
export const SEARCH_DEPENDENCIES_FAILURE = 'app/pricinghub/search_dependencies_failure';
export const SEARCH_DEPENDENCIES_ID = 'app/pricinghub/search_dependencies_id';
export const SET_PARAMS_DEPENDENCIES = 'app/dependencies/set_params_dependencies';

// get dependency
export const GET_DEPENDENCY_DETAIL = 'app/pricinghub/get_dependency_detail';
export const GET_DEPENDENCY_DETAIL_SUCCESS = 'app/pricinghub/get_dependency_detail_success';
export const GET_DEPENDENCY_DETAIL_FAILURE = 'app/pricinghub/get_dependency_detail_failure';

// modify dependency
export const MODIFY_DEPENDENCY = 'app/pricinghub/modify_dependency';
export const MODIFY_DEPENDENCY_SUCCESS = 'app/pricinghub/modify_dependency_success';

// update dependency status
export const UPDATE_DEPENDENCY_STATUS = 'app/pricinghub/update_dependency_status';
export const UPDATE_DEPENDENCY_STATUS_SUCCESS = 'app/pricinghub/update_dependency_status_success';

// create dependency
export const CREATE_DEPENDENCY = 'app/pricinghub/create_dependency';
export const CREATE_DEPENDENCY_SUCCESS = 'app/pricinghub/create_dependency_success';
export const CREATE_DEPENDENCY_FAILURE = 'app/pricinghub/create_dependency_failure';
