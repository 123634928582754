import gql from 'graphql-tag';

export default id => gql`
  query {
    getPaymentAllocation(paymentId: "${id}") {
      organization
      accountId
      invoiceId
      allocationDate
      allocatedAmount
      sourceCurrency
      sourceCurrencyAmount
      exchangeRate
      transactionType
    }
  }
`;
