import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CollectionProfileSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.collectionProfile',
      name: 'collectionProfile',
    },
    {
      label: 'common:label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
    },
    {
      label: 'common:label.accountSubType',
      name: 'accountSubType',
      type: 'select',
      tOptions: 'selections:accountSubType',
    },
    {
      label: 'common:label.marketSegment',
      name: 'marketSegment',
      type: 'select',
      tOptions: 'selections:marketSegment',
    },
    {
      label: 'common:label.salesChannel',
      name: 'salesChannel',
      type: 'select',
      tOptions: 'selections:salesChannel',
    },
  ];

  btnSpan = 'col-md-9';
}

CollectionProfileSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CollectionProfileSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CollectionProfileSearchForm);
