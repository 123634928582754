import gql from 'graphql-tag';

export default () => gql`
  query {
    getTaxTypeConfig(dummy: "") {
      id
      name
      createdDate
      taxTypes {
        index
        code
        taxType
        name
        description
        startDate
        endDate
        itemId
      }
    }
  }
`;
