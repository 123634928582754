import gql from 'graphql-tag';

export default id => gql`
  query {
    getProvisioningByAccountId(accountId: "${id}") {
      id
      accountId
      subscriptionId
      provisioningAttributes {
        id
        serviceUnitId
        priceUnitId
        priceOfferId
        priceOfferName
        bundleId
        bundleName
        packageId
        packageName
        provisioningName
        index
        serviceType
        status
        provisioningAttributesData {
          index
          name
          value
        }
      }
    }
  }
`;
