import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { FormWithTableItem, DataTable, TablePagination } from '../../common';
import LadingBillSearchForm from '../LadingBillSearchForm';
import { getPageTotalCount } from '../../../utils/utils';
import ModalViewFile from '../../common/ModalViewFile';

const billSort = {
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};

const LadingBill = ({
  id,
  getObjectFileById,
  searchLadingBill,
  modeSearchLandingBill,
  currencyOptions,
  customerInfo,
}) => {
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [pathFile, setPathFile] = useState(null);
  const [htmlFile, setHtmlFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [isOpenFile, setIsOpenFile] = useState(false);

  const { t } = useTranslation('common');

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doSearchLadingBill = useCallback(() => {
    setLoading(true);
    const newFilter = cloneDeep(filter);
    if (!newFilter.accountId) newFilter.accountId = id;
    searchLadingBill(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? billSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if (success && data) setData(data);
      }
    );
  }, [filter, id, searchLadingBill, page, size, sorted]);

  const onHandleSubmit = filter => {
    const newFilter = cloneDeep(filter);
    newFilter.accountId = id;
    setFilter(filter);
  };

  useEffect(() => {
    doSearchLadingBill();
  }, [doSearchLadingBill]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  const onSelectFile = ({ item }) => {
    getObjectFileById(item.id, data => {
      let pdfPath = '';
      let htmlPath = '';
      if (data && data.length) {
        const pdfData = data.find(val => val.fileType === 'PDF');
        const htmlData = data.find(val => val.fileType === 'HTML');
        if (pdfData) {
          pdfPath = pdfData.path;
        }
        if (htmlData) {
          htmlPath = htmlData.path;
        }
        setPathFile(pdfPath);
        setHtmlFile(htmlPath);
        setIsOpenFile(true);
        setFileData(data);
      }
    });
  };
  if (!modeSearchLandingBill) return '';

  const tableLadingBill = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) => (
        <button
          className="btn btn-outline-success btn-sm no-border"
          type="button"
          onClick={() => onSelectFile({ item })}
          disabled={modeSearchLandingBill === 1}
        >
          {item.id}
        </button>
      ),
    },
    {
      name: 'type',
      label: 'label.type',
      sortable: true,
    },
    {
      name: 'invoiceId',
      label: 'label.invoiceId',
    },
    {
      name: 'expeditionCode',
      label: 'label.expeditionCode',
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      sortable: true,
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      sortable: true,
    },
  ];
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }
  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('label.billOfLading')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        key="pendingBills"
      >
        <div className="card-body ml-2">
          <LadingBillSearchForm onSubmit={onHandleSubmit} />
        </div>
        <br />
        <DataTable
          isLoading={isLoading}
          onSort={onSortColumn}
          sorted={sorted}
          columns={tableLadingBill}
          data={data || []}
        />
      </FormWithTableItem>
      <div className="mb-30 mt-3">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>

      <ModalViewFile
        title={t('label.billOfLading')}
        isOpen={isOpenFile}
        pathFile={pathFile}
        htmlFile={htmlFile}
        fileData={fileData}
        onToggle={() => {
          setPathFile(null);
          setHtmlFile(null);
          setIsOpenFile(false);
        }}
      />
    </div>
  );
};

LadingBill.propTypes = {
  id: PropTypes.string,
  getObjectFileById: PropTypes.func.isRequired,
  searchLadingBill: PropTypes.func.isRequired,
};

LadingBill.defaultProps = {
  id: '',
};

export default LadingBill;
