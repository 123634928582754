import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { cloneDeep, size } from 'lodash';
import SubForm from '../../../components/form/SubForm';
import ModalBundleItem from '../DependencyItem/ModalBundleItem';
import ModalDisscountOffer from '../../../components/common/ModalDisscountOffer';

class PackageComponent extends Component {
  state = {
    components: [],
    selectedComponentIndex: null,
    isSelectingBundleId: false,
    isSelectingPricingOfferId: false,
    isSelectingDisscountOfferId: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.components) return null;
    return {
      components: props.components,
      oldValue: props.components,
    };
  }

  onChangeComponents = () => {
    const { components } = this.state;
    const { onChangeComponents } = this.props;
    onChangeComponents(components);
  };

  handleRemoveItem = (idx, item) => {
    const { components } = this.state;
    const { creatingMode } = this.props;
    const newComponents = cloneDeep(components);
    const indexItemRemove = newComponents.findIndex(val => val.index === item.index);
    if (indexItemRemove !== -1) {
      if (creatingMode || item?.isNew) {
        newComponents.splice(indexItemRemove, 1);
      } else {
        newComponents[indexItemRemove] = { index: newComponents[indexItemRemove].index };
      }
      this.setState({ components: newComponents }, () => this.onChangeComponents());
    }
  };

  handleChangeItem = (index, item) => {
    const { components } = this.state;
    const newItem = cloneDeep(item);
    if (!newItem.bundleName || !newItem.bundleId) {
      newItem.bundleName = null;
      newItem.bundleId = null;
    }
    if (!newItem.discountOfferName || !newItem.discountOfferId) {
      newItem.discountOfferName = null;
      newItem.discountOfferId = null;
    }
    components[index] = newItem;
    this.setState({ components }, () => this.onChangeComponents());
  };

  handleClickBundleId = (index, input, comp) => {
    if (input.name === 'bundleName') {
      this.setState({
        isSelectingBundleId: true,
        selectedBundleId: comp.bundleId,
        selectedComponentIndex: index,
      });
    }
    if (input.name === 'discountOfferName') {
      this.setState({
        isSelectingDisscountOfferId: true,
        selectedDisscountOfferId: comp.discountOfferId,
        selectedComponentIndex: index,
      });
    }
  };

  onSelectBundleId = (bundleId, row) => {
    const { components, selectedComponentIndex } = this.state;
    components[selectedComponentIndex].bundleId = bundleId;
    components[selectedComponentIndex].bundleName = row.name;
    components[selectedComponentIndex].discountOfferId = null;
    components[selectedComponentIndex].discountOfferName = null;
    this.setState({ components, isSelectingBundleId: false, isSelectingDisscountOfferId: false });
  };

  onSelectDisscountOfferId = (discountOfferId, row) => {
    const { components, selectedComponentIndex } = this.state;
    components[selectedComponentIndex].discountOfferId = discountOfferId;
    components[selectedComponentIndex].discountOfferName = row.name;
    components[selectedComponentIndex].bundleId = null;
    components[selectedComponentIndex].bundleName = null;
    this.setState({
      components,
      isSelectingBundleId: false,
      isSelectingDisscountOfferId: false,
    });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingBundleId: false, isSelectingDisscountOfferId: false });
  };

  getComponentInputs = (idx, comp) => {
    // const wrapperInputs = componentInputs.map(input => {
    //   if (input.name === 'bundleName' || input.name === 'discountOfferName') {
    //     input.onClick = e => this.handleClickBundleId(idx, input, comp);
    //     return input;
    //   }
    //   return input;
    // });
    // return wrapperInputs;

    const { creatingMode } = this.props;

    const componentInputs = [
      {
        label: 'common:label.serviceType',
        wrapperClass: 'col-md-4',
        name: 'serviceType',
        type: 'select',
        tOptions: 'selections:serviceType',
        isClearable: true,
        required: true,
        menuPortalTarget: true,
      },
      // {
      //   label: 'common:label.bundleId',
      //   wrapperClass: 'col-md-4',
      //   name: 'bundleId',
      //   required: true,
      //   type: 'text',
      //   fa: 'fa fa-external-link',
      // },
      {
        label: 'common:label.bundleName',
        wrapperClass: 'col-md-4',
        name: 'bundleName',
        // required: true,
        type: 'text',
        fa: 'fa fa-external-link',
        onClick: () => this.handleClickBundleId(idx, { name: 'bundleName' }, comp),
        disabled: comp.discountOfferId,
        readOnly: !creatingMode && !comp.discountOfferId,
      },
      {
        label: 'common:label.discountOfferName',
        wrapperClass: 'col-md-4',
        name: 'discountOfferName',
        type: 'text',
        fa: 'fa fa-external-link',
        onClick: () => this.handleClickBundleId(idx, { name: 'discountOfferName' }, comp),
        disabled: comp.bundleId,
        readOnly: !creatingMode && !comp.bundleId,
      },
      {
        label: 'common:label.discountAmountOverride',
        wrapperClass: 'col-md-4',
        name: 'discountAmountOverride',
        type: 'number',
        isNegativeValue: true,
        disabled: comp.bundleId || comp.discountPercentOverride,
      },
      {
        label: 'common:label.discountPercentOverride',
        wrapperClass: 'col-md-4',
        name: 'discountPercentOverride',
        type: 'number',
        disabled: comp.bundleId || comp.discountAmountOverride,
      },
      {
        label: 'common:label.validityUnit',
        wrapperClass: 'col-md-4',
        name: 'validityUnit',
        type: 'select',
        tOptions: 'selections:trialTermUnit',
        menuPortalTarget: true,
      },
      {
        label: 'common:label.validityDuration',
        wrapperClass: 'col-md-4',
        name: 'validityDuration',
        type: 'number',
      },
      {
        label: 'common:label.serviceAddOn',
        wrapperClass: 'col-md-4',
        name: 'serviceAddOn',
        type: 'text',
        disabled: true,
      },
    ];

    return componentInputs;
  };

  render() {
    const { t } = this.props;
    const { components = [] } = this.state;
    if (!components || components.length === 0) return null;
    return (
      <div className="card nested-section-card">
        <div className="card-header">{t('label.components')}</div>
        <div className="card-body">
          {components.map((comp, idx) => {
            if (size(comp) < 2) return null;
            return (
              <SubForm
                key={`component-idx-${idx}`}
                headerTitle={`INDEX#${idx + 1}`}
                inputs={this.getComponentInputs(idx, comp)}
                item={comp}
                index={idx}
                isRemoveable={components.length > 1}
                onRemoveItem={e => this.handleRemoveItem(idx, comp)}
                onChangeItem={e => this.handleChangeItem(idx, e)}
              />
            );
          })}
        </div>
        <ModalBundleItem
          isOpen={this.state.isSelectingBundleId}
          selectedParentId={this.state.selectedBundleId}
          onSelect={this.onSelectBundleId}
          onCancel={this.onCancelSelector}
          defaultSearchParams={{
            serviceType:
              (this.state.components &&
                this.state.components[this.state.selectedComponentIndex] &&
                this.state.components[this.state.selectedComponentIndex].serviceType) ||
              null,
            status: 'SELLABLE',
          }}
        />
        <ModalDisscountOffer
          isOpen={this.state.isSelectingDisscountOfferId}
          selectedParentId={this.state.selectedDisscountOfferId}
          onSelect={this.onSelectDisscountOfferId}
          onCancel={this.onCancelSelector}
          defaultSearchParams={{
            status: 'SELLABLE',
            serviceType:
              (this.state.components &&
                this.state.components[this.state.selectedComponentIndex] &&
                this.state.components[this.state.selectedComponentIndex].serviceType) ||
              null,
          }}
        />
      </div>
    );
  }
}

PackageComponent.propTypes = {
  components: PropTypes.any.isRequired,
  onChangeComponents: PropTypes.func,
  creatingMode: PropTypes.bool,
};

PackageComponent.defaultProps = {
  components: [{}],
  creatingMode: false,
};
export default withTranslation('common')(PackageComponent);
