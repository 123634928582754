import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { customerSelect } from '../../../../constantsApp';
import { makeGetCustomRecordCfg } from '../../selectors';
import { getCustomUsageRecCfg, modifyCustomUsageRecCfg, createCustomUsageRecCfg } from '../../actions';
import { convertPayloadCustomUsageRecCfg } from '../../../../utils/utils';
import { FormWithTableItem, GenericInput, FocusSelectTable, FocusInputTable } from '../../../../components/common';

class CustomRecords extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    customRecordList: [],
    isSearching: false,
    defaultLength: 0,
    isModify: false,
    isDataFromAPI: false,
    id: '',
  };

  componentDidMount() {
    this.doGetCustomRecordCfg();
  }

  componentWillReceiveProps(nextProps) {
    const { customRecordList } = this.props;
    if (customRecordList && nextProps.customRecordList && customRecordList !== nextProps.customRecordList) {
      this.setState({
        customRecordList: nextProps.customRecordList,
        defaultDataFromAPI: nextProps.customRecordList,
      });
    }
  }

  doGetCustomRecordCfg = () => {
    const { getCustomUsageRecCfg } = this.props;
    this.setState({ customRecordList: [], defaultDataFromAPI: [] });
    getCustomUsageRecCfg(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id), id: data && data.id ? data.id : '' });
    });
  };

  validateField = () => {
    const { customRecordList } = this.state;
    const { t } = this.props;
    let messageValidte = '';
    if (customRecordList && customRecordList.length) {
      customRecordList.forEach(item => {
        if (Number.parseInt(item.recordNumber, 10) <= 100000 || Number.parseInt(item.recordNumber, 10) > 200000) {
          messageValidte = t('message.messageRecordsConfig');
        }
      });
    }
    if (messageValidte) toast.error(messageValidte);
    return !!messageValidte;
  };

  onHanldeSubmit = () => {
    const { customRecordList, isModify, id } = this.state;
    const { createCustomUsageRecCfg, modifyCustomUsageRecCfg } = this.props;
    if (!this.validateField()) {
      if (!isModify) {
        const dataRemoveIndex = cloneDeep(customRecordList);
        dataRemoveIndex.forEach((item, i) => {
          delete dataRemoveIndex[i].index;
        });
        const newPayloadCreate = {
          customRecCfg: dataRemoveIndex,
        };
        return createCustomUsageRecCfg(newPayloadCreate);
      }
      const newPayload = {
        customRecCfg: customRecordList,
      };
      newPayload.id = id;
      return modifyCustomUsageRecCfg(convertPayloadCustomUsageRecCfg(newPayload));
    }
  };

  onAddNewRecord = () => {
    const { customRecordList } = this.state;
    let indexNewItem = 0;
    const newData = cloneDeep(customRecordList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    newData.push({ customRecAttributesCfg: [], index: indexNewItem + 1 });
    this.setState({ customRecordList: newData });
  };

  onChangeConfigRecord = ({ name, value, index }) => {
    const { customRecordList } = this.state;
    const newData = cloneDeep(customRecordList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    newData[indexItemChange][name] = value;
    this.setState({ customRecordList: newData });
  };

  onUpdateValue = (index, name, value, indexRecord) => {
    const { customRecordList } = this.state;
    const newData = cloneDeep(customRecordList);
    const indexAttr = newData.findIndex(item => item.index === indexRecord);
    if (name === 'mandatory') {
      newData[indexAttr].customRecAttributesCfg[index][name] = value === 'Y' ? 'true' : 'false';
    } else {
      newData[indexAttr].customRecAttributesCfg[index][name] = value;
    }

    this.setState({ customRecordList: newData });
  };

  addNewAttribute = ({ index }) => {
    const { customRecordList } = this.state;
    const newData = cloneDeep(customRecordList);
    const indexAttr = newData.findIndex(item => item.index === index);
    const newAttribute = {
      length: '0',
      mandatory: 'true',
      name: '',
      type: '',
    };
    if (!newData[indexAttr].customRecAttributesCfg) newData[indexAttr].customRecAttributesCfg = [];

    newData[indexAttr].customRecAttributesCfg.push(newAttribute);
    this.setState({ customRecordList: newData });
  };

  onRemoveItem = idx => {
    const { customRecordList } = this.state;
    const newData = cloneDeep(customRecordList);
    newData[idx] = { index: customRecordList[idx].index };
    this.setState({ customRecordList: newData });
  };

  renderRowChild = (row, id, indexRecord) => {
    const { defaultLength } = this.state;
    const { t } = this.props;
    if (id >= defaultLength) {
      return (
        <tr key={id}>
          <td>
            <input
              type="text"
              value={row.name}
              name="name"
              onChange={evt => {
                this.onUpdateValue(id, evt.target.name, evt.target.value, indexRecord);
              }}
              className="form-control input-group"
            />
          </td>
          <td className="attribute-select">
            <Select
              value={{ value: row.type, label: row.type }}
              onChange={val => {
                this.onUpdateValue(id, 'type', val.value, indexRecord);
              }}
              options={t('selections:attributeTypes')()}
              className="react-select"
              classNamePrefix="custom-react-select"
              isClearable
            />
          </td>

          <td>
            <input
              type={row.type === 'VARCHAR' ? 'number' : 'text'}
              value={row.length && row.length !== '0' ? row.length : ''}
              name="length"
              disabled={row.type !== 'VARCHAR'}
              onChange={evt => {
                this.onUpdateValue(id, evt.target.name, evt.target.value, indexRecord);
              }}
              className="form-control input-group"
            />
          </td>
          <td className="attribute-select">
            <Select
              value={{
                value: row.mandatory === 'true' ? 'Y' : 'N',
                label: row.mandatory === 'true' || row.mandatory === true ? 'Y' : 'N',
              }}
              onChange={val => {
                this.onUpdateValue(id, 'mandatory', val.value, indexRecord);
              }}
              options={t('selections:mandatory')()}
              className="react-select"
              classNamePrefix="custom-react-select"
              isClearable
            />
          </td>
        </tr>
      );
    }
    return (
      <tr key={id}>
        <FocusInputTable
          value={row.name}
          name="name"
          index={id}
          onUpdateValue={(index, name, value) => {
            this.onUpdateValue(id, name, value, indexRecord);
          }}
        />

        <FocusSelectTable
          value={{ value: row.type, label: row.type }}
          options={customerSelect.attributeType}
          name="type"
          index={id}
          onUpdateValue={(index, name, value) => {
            this.onUpdateValue(id, name, value, indexRecord);
          }}
        />
        {row.type === 'VARCHAR' ? (
          <FocusInputTable
            type="number"
            value={row.length}
            name="length"
            index={id}
            onUpdateValue={(index, name, value) => {
              this.onUpdateValue(id, name, value, indexRecord);
            }}
          />
        ) : (
          <td>{row.length || ''}</td>
        )}

        <FocusSelectTable
          value={{ value: row.mandatory, label: row.mandatory }}
          options={customerSelect.mandatory}
          name="mandatory"
          index={id}
          onUpdateValue={(index, name, value) => {
            this.onUpdateValue(id, name, value, indexRecord);
          }}
        />
      </tr>
    );
  };

  renderContent = ({ item }) => {
    const { t } = this.props;
    const { isLoading } = this.state;
    if (item.customRecAttributesCfg && item.customRecAttributesCfg.length) {
      return <tbody>{item.customRecAttributesCfg.map((row, id) => this.renderRowChild(row, id, item.index))}</tbody>;
    }
    return (
      <tbody>
        <tr>
          <td className="text-center" colSpan={4}>
            {!isLoading && <span>{t('message.noRecord')}</span>}
            {isLoading && <ScaleLoader loading size={100} color="#28a745" />}
          </td>
        </tr>
      </tbody>
    );
  };

  renderHeaderTable = () => {
    const { t } = this.props;
    const headers = [t('label.attributeName'), t('label.attributeType'), t('label.length'), t('label.mandatory')];
    return (
      <thead>
        <tr>
          {headers.map(item => (
            <th key={item} className="w-25">
              {item}
              <i className="fa fa-star is-required" />
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  render() {
    const { customRecordList, isModify } = this.state;
    const { t } = this.props;
    const dataRemoveIndex = customRecordList.filter(item => size(item) > 1);
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.invoiceUsage.path}
          titleBtn={t('label.back')}
          items={[{ name: t('usagePage:sidebar.usageMapping') }, { name: t('label.customRecords') }]}
          isNoDefaultButton
        />
        <FormWithTableItem title={t('label.customRecords')}>
          {dataRemoveIndex.map((item, idx) => (
            <FormWithTableItem title="" wrapperClass="m-3" key={item.index}>
              {dataRemoveIndex.length > 1 && (
                <div className="col-md-12">
                  <button type="button" className="btn-first-phone float-right" onClick={() => this.onRemoveItem(idx)}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              <div className="col-md-12 mb-2 justify-content-center row">
                <GenericInput
                  value={item.recordNumber}
                  type="number"
                  min="100001"
                  max="200000"
                  label="label.recordNumber"
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => this.onChangeConfigRecord({ name, value, index: item.index })}
                  name="recordNumber"
                />
                <GenericInput
                  value={item.recordType}
                  label="label.recordType"
                  wrapperClass="col-md-4"
                  onChange={({ name, value }) => this.onChangeConfigRecord({ name, value, index: item.index })}
                  name="recordType"
                />
              </div>
              <div className="table-responsive pt-2 pl-5 pr-5 form-focus">
                <table className="table center-aligned-table mb-0">
                  {this.renderHeaderTable()}
                  {this.renderContent({ item })}
                </table>
                <button
                  type="button"
                  className="button button-border mt-3 black x-small"
                  onClick={() => this.addNewAttribute({ index: item.index })}
                >
                  {`+ ${t('label.addNewAttribute')}`}
                </button>
              </div>
              <br />
            </FormWithTableItem>
          ))}
          <div className="form-group col-md-12 buttons-attibute text-center">
            <button type="button" className="button button-border gray x-small" onClick={this.onAddNewRecord}>
              {`+ ${t('label.addNewRecord')}`}
            </button>
          </div>
          <div className="form-group col-md-12 action-buttons buttons-attibute mt-3">
            <button
              type="button"
              className="button button-border x-small float-right"
              onClick={() => this.onHanldeSubmit()}
            >
              {isModify ? t('label.saveConfig') : t('label.createConfig')}
            </button>
            <button
              type="button"
              onClick={() => this.doGetCustomRecordCfg()}
              className="button button-border black x-small float-right mr-2"
            >
              {t('label.cancel')}
            </button>
          </div>
        </FormWithTableItem>
      </div>
    );
  }
}

CustomRecords.propTypes = {
  getCustomUsageRecCfg: PropTypes.func.isRequired,
  modifyCustomUsageRecCfg: PropTypes.func.isRequired,
  createCustomUsageRecCfg: PropTypes.func.isRequired,
  customRecordList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

CustomRecords.defaultProps = {
  customRecordList: [],
};

const mapStateToProps = createStructuredSelector({
  customRecordList: makeGetCustomRecordCfg() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getCustomUsageRecCfg, modifyCustomUsageRecCfg, createCustomUsageRecCfg })(CustomRecords)
);
