import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size, cloneDeep, orderBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import listCurrency from '../../../utils/currency.json';
import utils from '../../../utils/utils';
import { getBatchPaymentFileConfig, modifyBatchPaymentFileConfig, createBatchPaymentFileConfig } from '../actions';
import { makeGetBatchPaymentFile } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import {
  checkPermissionGetBatchPaymentFile,
  checkPermissionModifyBatchPaymentFile,
  checkPermissionCreateBatchPaymentFile,
} from '../CheckPermission';
import { getCcpPropertiesConfig } from '../../App/actions';

class BatchPaymentFile extends PureComponent {
  state = {
    isSearching: true,
    dataSubmit: [],
    configBatchPaymentFileList: [],
    wasValidated: false,
    defaultCurrency: null,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.batchPaymentFile) return null;
    return {
      configBatchPaymentFileList:
        props.batchPaymentFile && props.batchPaymentFile.files ? props.batchPaymentFile.files : [],
      oldValue: props.batchPaymentFile,
      dataSubmit: [],
    };
  }

  componentDidMount() {
    this.doGetBatchPaymentFileConfig();
    const { getCcpPropertiesConfig } = this.props;
    getCcpPropertiesConfig('', ({ success, data }) => {
      if (success) {
        const defaultCurrency = data.ccpPropertyList.find(val => val.property === 'currency');
        this.setState({
          defaultCurrency,
        });
      }
    });
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { configBatchPaymentFileList, dataSubmit } = this.state;
    const newData = cloneDeep(configBatchPaymentFileList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    if (name === 'accountNumber') {
      newData[indexItemChange].name = `${newData[indexItemChange].fileType} ${
        value ? value.substring(value.length - 4, value.length) : ''
      }`;
    }
    this.setState({ configBatchPaymentFileList: newData });
  };

  doGetBatchPaymentFileConfig = () => {
    const { getBatchPaymentFileConfig } = this.props;
    getBatchPaymentFileConfig('', () => {
      this.setState({ isSearching: false });
    });
  };

  onAddNewProfile = () => {
    let indexNewItem = 0;
    const { configBatchPaymentFileList, dataSubmit, defaultCurrency } = this.state;
    const newData = cloneDeep(configBatchPaymentFileList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      accountNumber: '',
      fileType: null,
      name: null,
      currency: defaultCurrency ? defaultCurrency.value : '',
      paymentFolio: true,
    };
    newData.push(newDataItem);
    dataSubmit.push(newDataItem);
    this.setState({ configBatchPaymentFileList: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    const { configBatchPaymentFileList, dataSubmit } = this.state;
    const newData = cloneDeep(configBatchPaymentFileList);
    const indexItemRemove = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexItemRemove > -1) {
      newData[indexItemRemove] = { index };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index };
      } else {
        dataSubmit.push({ index });
      }
    }
    this.setState({ configBatchPaymentFileList: newData, dataSubmit });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { configBatchPaymentFileList } = this.state;
    const { modifyBatchPaymentFileConfig, createBatchPaymentFileConfig, batchPaymentFile } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (batchPaymentFile && batchPaymentFile.id) {
      const newPayload = {
        id: batchPaymentFile.id,
        files:
          configBatchPaymentFileList && configBatchPaymentFileList.length
            ? configBatchPaymentFileList.map(val => {
                const { paymentFolio, ...rest } = val;
                if (size(rest) === 1) {
                  return { ...rest };
                }
                return {
                  ...rest,
                  paymentFolio:
                    paymentFolio === 'TRUE' || paymentFolio === true || paymentFolio === null ? 'true' : 'false',
                };
              })
            : null,
      };
      return modifyBatchPaymentFileConfig(newPayload, ({ success }) => {
        if (success) this.doGetBatchPaymentFileConfig();
      });
    }

    const newPayloadCreate = cloneDeep(configBatchPaymentFileList).map(item => {
      const newItem = item;
      delete newItem.index;
      if (size(newItem) === 1) {
        return { ...newItem };
      }
      newItem.paymentFolio =
        item.paymentFolio === 'TRUE' || item.paymentFolio === true || item.paymentFolio === null ? 'true' : 'false';
      return newItem;
    });
    createBatchPaymentFileConfig({ files: newPayloadCreate, name: 'batchPaymentFile' }, ({ success }) => {
      if (success) this.doGetBatchPaymentFileConfig();
    });
  };

  render() {
    const { configBatchPaymentFileList, isSearching, wasValidated } = this.state;
    const { batchPaymentFile, permissionsPayment, t } = this.props;
    let modeGetBatchPaymentFileConfig = 0;
    let modeModifyBatchPaymentFileConfig = 0;
    let modeCreateBatchPaymentFileConfig = 0;

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermissionsPayment = permissionsPayment.paymentModulePermissions;
      modeGetBatchPaymentFileConfig = checkPermissionGetBatchPaymentFile({
        listPermission: listPermissionsPayment,
      });
      modeCreateBatchPaymentFileConfig = checkPermissionCreateBatchPaymentFile({
        listPermission: listPermissionsPayment,
      });
      modeModifyBatchPaymentFileConfig = checkPermissionModifyBatchPaymentFile({
        listPermission: listPermissionsPayment,
      });
    }

    if (!modeGetBatchPaymentFileConfig) return '';

    const batchPaymentFileColumns = [
      {
        name: 'fileType',
        label: t('label.fileType'),
        style: { textAlign: 'center', minWidth: '200px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.fileType}
            tOptions="selections:batchPaymentType"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="fileType"
            required
          />
        ),
      },
      {
        name: 'accountNumber',
        label: t('label.accountNumber'),
        style: { textAlign: 'center', minWidth: '110px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.accountNumber}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountNumber"
            required
          />
        ),
      },
      {
        name: 'name',
        label: t('label.name'),
        style: { textAlign: 'center', minWidth: '180px' },
        required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="name"
            readOnly
            required
          />
        ),
      },
      {
        name: 'currency',
        label: t('label.currency'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.currency}
            wrapperClass="col-md-12"
            menuPortalTarget
            options={utils.convertCurrency2Option(listCurrency.currencies)}
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="currency"
          />
        ),
      },
      {
        name: 'paymentFolio',
        label: t('label.paymentFolio'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={
              item.paymentFolio === 'TRUE' || item.paymentFolio === true || item.paymentFolio === null
                ? 'TRUE'
                : 'FALSE'
            }
            type="select"
            tOptions="selections:selectBool"
            name="paymentFolio"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataOnlyViewInTable =
      configBatchPaymentFileList && configBatchPaymentFileList.length
        ? orderBy(
            configBatchPaymentFileList.filter(item => size(item) > 1),
            ['index'],
            ['asc']
          )
        : [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentAdmin'), url: RouteNames.paymentConfiguration.path },
            { name: t('label.paymentFilesConfiguration') },
          ]}
        />
        <FormWithTableItem title={t('label.paymentFilesConfiguration')}>
          <form
            onSubmit={modeModifyBatchPaymentFileConfig === 2 ? this.hanldeSubmit : () => {}}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <br />
            <div>
              <DataTable columns={batchPaymentFileColumns} data={newDataOnlyViewInTable} isLoading={isSearching} />
            </div>
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateBatchPaymentFileConfig === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProfile}>
                  +
                  {t('label.addNewFileTypeMapping')}
                </button>
              )}
              {modeModifyBatchPaymentFileConfig === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {batchPaymentFile && batchPaymentFile.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={this.doGetBatchPaymentFileConfig}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </FormWithTableItem>
      </div>
    );
  }
}

BatchPaymentFile.propTypes = {
  batchPaymentFile: PropTypes.objectOf(PropTypes.any),
  getBatchPaymentFileConfig: PropTypes.func.isRequired,
  createBatchPaymentFileConfig: PropTypes.func.isRequired,
  modifyBatchPaymentFileConfig: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

BatchPaymentFile.defaultProps = {
  batchPaymentFile: {},
  permissionsPayment: {},
};
const mapStateToProps = createStructuredSelector({
  // BatchPaymentFile: makeGetAdvanceBillingConfig() || {},
  batchPaymentFile: makeGetBatchPaymentFile() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

const newBatchPaymentFile = connect(mapStateToProps, {
  getBatchPaymentFileConfig,
  modifyBatchPaymentFileConfig,
  createBatchPaymentFileConfig,
  getCcpPropertiesConfig,
})(BatchPaymentFile);

export default withTranslation('common')(newBatchPaymentFile);
