/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getPendingAccountMovementData, deletePendingAccountMovementData } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalSelectPendingMerge extends PureComponent {
  state = {
    isNeedFetch: false,
  };

  onRemoveItem = ({ item }) => {
    const { deletePendingAccountMovementData } = this.props;
    this.setState({ isNeedFetch: false });
    deletePendingAccountMovementData(item.savedAccountMovementDataId, ({ success }) => {
      if (success) this.setState({ isNeedFetch: true });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'userId',
          label: 'common:label.userId',
        },
        {
          name: 'effectiveDate',
          label: 'common:label.effectiveDate',
        },
        {
          name: 'notes',
          label: 'common:label.notes',
        },
        {
          name: 'remove',
          label: 'label.remove',
          render: (colName, item) => {
            return (
              <div className="form-group col-md-12">
                <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ item })}>
                  <i className="fa fa-trash" />
                </button>
              </div>
            );
          },
        },
      ],
    };
    const { isNeedFetch } = this.state;
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      defaultSearchParams,
      headerTitle,
      fieldDisableCheck,
      getPendingAccountMovementData,
    } = this.props;
    return (
      <ModalSelector
        modalTitle={headerTitle || t('label.savedPendingMergeRequest')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={getPendingAccountMovementData}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        fieldDisableCheck={fieldDisableCheck}
        isNotRenderPagination
        isNeedFetch={isNeedFetch}
      />
    );
  }
}

ModalSelectPendingMerge.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getPendingAccountMovementData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalSelectPendingMerge.defaultProps = {
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(
  connect(null, { getPendingAccountMovementData, deletePendingAccountMovementData })(ModalSelectPendingMerge)
);
