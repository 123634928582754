import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class TaxExemptionSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.firstName',
      name: 'firstName',
    },
    {
      label: 'label.lastName',
      name: 'lastName',
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      label: 'label.taxType',
      name: 'taxType',
      type: 'select',
      options: [],
    },
    {
      label: 'label.productCode',
      name: 'productCode',
      type: 'select',
      options: [],
    },
  ];

  btnSpan = 'col-md-12';
}

TaxExemptionSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

TaxExemptionSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(TaxExemptionSearchForm);
