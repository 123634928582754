import React, { PureComponent } from 'react';
import { isEmpty, cloneDeep, union } from 'lodash';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SelectTable from './SelectTable';

class ModalSelector extends PureComponent {
  state = { newServicesSelect: [], groupExistService: [] };

  componentDidMount() {}

  onSelect = e => {
    e.preventDefault();
    const { onSelect } = this.props;
    const { newServicesSelect, groupExistService } = this.state;
    if (onSelect) {
      onSelect(newServicesSelect, groupExistService);
    }
  };

  onCancel = e => {
    e.preventDefault();
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(false);
    }
  };

  onClickRow = (arrItem, listItem) => {
    const {
      lastIndex,
      isBundle,
      isPackage,
      isPriceOffers,
      allService,
      onClickRow,
      getBundleByBundleId,
      isSupportPOName,
    } = this.props;
    const listItemSelected =
      listItem.filter(item => arrItem.indexOf(item.id) > -1).map(value => value.components) || [];
    const packageSelect = listItem.filter(item => arrItem.indexOf(item.id) > -1) || [];
    const newServices = [];
    if (isPackage && !isEmpty(listItemSelected)) {
      const listPackageId = [];
      const listBundleIdPackage = [];
      const listServiceType = [];
      let nameServiceExist = [];
      const serviceExistObj = [];
      const lastItemSelect = listItemSelected.length > 1 ? listItemSelected[listItemSelected.length - 1] : [];
      const dataCheckExistService = cloneDeep(listItemSelected);
      dataCheckExistService.splice(listItemSelected.length - 1, 1);
      let groupServiceTypeItemSelected = [];
      dataCheckExistService.forEach(item => {
        groupServiceTypeItemSelected = union(
          groupServiceTypeItemSelected,
          item.map(value => value.serviceType)
        );
      });

      const lastItemChecked = lastItemSelect.filter(
        item => groupServiceTypeItemSelected.indexOf(item.serviceType) === -1
      );

      if (!isEmpty(lastItemChecked) && listItemSelected.length > 1) {
        nameServiceExist = lastItemSelect
          .filter(item => groupServiceTypeItemSelected.indexOf(item.serviceType) !== -1)
          .map(value => value.serviceType);
        listItemSelected[listItemSelected.length - 1] = lastItemChecked;
      }
      if (isEmpty(lastItemChecked) && listItemSelected.length > 1) {
        nameServiceExist = lastItemSelect.map(item => item.serviceType);
        listItemSelected.splice(listItemSelected.length - 1, 1);
      }

      for (let k = 0; k < listItemSelected.length; k++) {
        if (!isEmpty(listItemSelected[k])) {
          for (let c = 0; c < listItemSelected[k].length; c++) {
            const indexServiceSelect = allService.findIndex(
              value => value.serviceType === listItemSelected[k][c].serviceType
            );
            const serviceTypeActionAdd = allService
              .filter(item => item.action !== 'CANCEL')
              .map(value => value.serviceType);

            if (indexServiceSelect === -1 || serviceTypeActionAdd.indexOf(listItemSelected[k][c].serviceType) === -1) {
              listBundleIdPackage.push(listItemSelected[k][c].bundleId);
              listServiceType.push(listItemSelected[k][c].serviceType);
              listPackageId.push(arrItem[k]);
            } else if (nameServiceExist.indexOf(listItemSelected[k][c].serviceType) === -1) {
              nameServiceExist.push(listItemSelected[k][c].serviceType);
              serviceExistObj.push({
                bundleId: listItemSelected[k][c].bundleId,
                serviceType: listItemSelected[k][c].serviceType,
                packageId: arrItem[k],
              });
            }
          }
        }
      }

      if (!isEmpty(nameServiceExist)) {
        toast.info(`Existing service with Package/Bundle/Price-offers - remove old assets first.
        Services type exist: ${nameServiceExist.join(', ')}`);
      }
      if (!isEmpty(listBundleIdPackage)) {
        const newDataWithBundleId = [];
        listBundleIdPackage.map(item =>
          getBundleByBundleId(item, ({ success, data }) => {
            newDataWithBundleId.push(data);
            if (newDataWithBundleId.length === listBundleIdPackage.length && success) {
              for (let i = 0; i < listBundleIdPackage.length; i++) {
                const indexBundle = newDataWithBundleId.findIndex(bd => bd.id === listBundleIdPackage[i]);
                newServices[i] = {};
                newServices[i].index = lastIndex + i + 1;
                newServices[i].packageId = listPackageId[i];
                newServices[i].packageName = packageSelect.find(val => val.id === listPackageId[i]).name;
                newServices[i].bundleId = listBundleIdPackage[i];
                newServices[i].bundleName = newDataWithBundleId[indexBundle]
                  ? newDataWithBundleId[indexBundle].name
                  : '';
                newServices[i].action = 'ADD';
                newServices[i].status = 'CREATED';
                newServices[i].lines = [];
                newServices[i].serviceType = listServiceType[i] || null;
                newServices[i].provisioningId = '';

                if (!isEmpty(newDataWithBundleId[indexBundle]) && newDataWithBundleId[indexBundle].components) {
                  for (let j = 0; j < newDataWithBundleId[indexBundle].components.length; j++) {
                    newServices[i].lines[j] = {};
                    newServices[i].lines[j].index = j + 1;
                    newServices[i].lines[j].priceOfferId = newDataWithBundleId[i].components[j].priceOfferId;
                    newServices[i].lines[j].priceOfferName = newDataWithBundleId[i].components[j].priceOfferName;
                    newServices[i].lines[j].discountOfferId =
                      newDataWithBundleId[i].components[j].discountOfferId || null;
                    newServices[i].lines[j].discountOfferName =
                      newDataWithBundleId[i].components[j].discountOfferName || null;
                    newServices[i].lines[j].action = 'ADD';
                    newServices[i].lines[j].status = 'CREATED';
                  }
                }
              }
            }
          })
        );
      }
      this.setState({ newServicesSelect: newServices, groupExistService: serviceExistObj });
    }
    if (isBundle) {
      const listBundleId = listItem.filter(item => arrItem.indexOf(item.id) > -1).map(value => value.id) || [];
      for (let i = 0; i < listItemSelected.length; i++) {
        const itemChoose = listItem.filter(item => item.id === arrItem[i]);
        newServices[i] = {};
        newServices[i].index = lastIndex + i + 1;
        newServices[i].serviceType =
          listItemSelected[i] && listItemSelected[i][0] ? listItemSelected[i][0].serviceType : null;
        newServices[i].bundleId = listBundleId[i];
        newServices[i].action = 'ADD';
        newServices[i].status = 'CREATED';
        newServices[i].bundleName = itemChoose && itemChoose[0] ? itemChoose[0].name : '';
        const FilterProvisioningId = allService.filter(item => item.serviceType === newServices[i].serviceType);
        newServices[i].provisioningId = !isEmpty(FilterProvisioningId) ? FilterProvisioningId[0].provisioningId : '';
        newServices[i].lines = [];
        for (let j = 0; j < listItemSelected[i].length; j++) {
          newServices[i].lines[j] = {};
          newServices[i].lines[j].index = j + 1;
          newServices[i].lines[j].priceOfferId = listItemSelected[i][j].priceOfferId;
          newServices[i].lines[j].priceOfferName = listItemSelected[i][j].priceOfferName;
          newServices[i].lines[j].discountOfferId = listItemSelected[i][j].discountOfferId || null;
          newServices[i].lines[j].discountOfferName = listItemSelected[i][j].discountOfferName || null;
          newServices[i].lines[j].action = 'ADD';
          newServices[i].lines[j].status = 'CREATED';
        }
      }
      this.setState({ newServicesSelect: newServices });
    }
    if (isPriceOffers) {
      const listItemSelectedPriceOffer = listItem.filter(item => arrItem.indexOf(item.id) > -1);
      listItemSelectedPriceOffer.forEach((element, i) => {
        const indexServieExist = allService.findIndex(item => item.serviceType === element.serviceType);
        if (indexServieExist > -1) {
          newServices[i] = cloneDeep(allService[indexServieExist]);
          newServices[i].action = 'MODIFY';
          const indexLineChange = newServices[i].lines.findIndex(v => v.priceOfferId === element.id);
          if (indexLineChange > -1) {
            newServices[i].lines[indexLineChange].action = 'ADD';
          } else {
            let lastIndexLine = 0;
            newServices[i].lines.forEach(item => {
              if (item.index > lastIndexLine) lastIndexLine = item.index;
            });
            let newDataLine = {
              index: lastIndexLine + 1,
              priceOfferId: element.id,
              action: 'ADD',
              status: 'CREATED',
            };
            if (isSupportPOName) {
              newDataLine = { ...newDataLine, priceOfferName: element.name };
            }
            newServices[i].lines.push(newDataLine);
          }
        } else {
          newServices[i] = {};
          newServices[i].index = lastIndex + i + 1;
          newServices[i].action = 'ADD';
          newServices[i].provisioningId = '';
          newServices[i].serviceType = element.serviceType;
          newServices[i].status = 'CREATED';
          newServices[i].lines = [{}];
          newServices[i].lines[0].index = 1;
          newServices[i].lines[0].priceOfferId = element.id;
          newServices[i].lines[0].discountOfferId = element.discountOfferId || null;
          newServices[i].lines[0].discountOfferName = element.discountOfferName || null;
          newServices[i].lines[0].action = 'ADD';
          newServices[i].lines[0].status = 'CREATED';

          if (isSupportPOName) {
            newServices[i].lines[0].priceOfferName = element.name;
          }
        }
      });
      this.setState({ newServicesSelect: newServices });
    }
    if (onClickRow) onClickRow(arrItem, listItem);
  };

  render() {
    const { selectedItemIds } = this.state;
    const { tableColumns, modalTitle, SearchForm, searchItems, isSingleSelect, defaultFilter, t, isOpen } = this.props;

    return (
      <Modal size="lg" className="modal-custom modal-70 bd-example-modal-lg" isOpen={isOpen} toggle={this.onCancel}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <SelectTable
            defaultFilter={defaultFilter}
            isSingleSelect={isSingleSelect}
            selectedItemIds={selectedItemIds}
            tableColumns={tableColumns}
            onClickRow={this.onClickRow}
            searchItems={searchItems}
            SearchForm={SearchForm}
          />
          <ModalFooter className="text-right">
            <button type="button" className="btn btn-secondary" onClick={this.onCancel}>
              {t('label.cancel')}
            </button>
            <button type="button" className="btn btn-success" onClick={this.onSelect}>
              {t('label.select')}
            </button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

ModalSelector.propTypes = {
  lastIndex: PropTypes.number,
  isBundle: PropTypes.bool,
  isPackage: PropTypes.bool,
  isPriceOffers: PropTypes.bool,
  isSupportPOName: PropTypes.bool,
  tableColumns: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
  getBundleByBundleId: PropTypes.func.isRequired,
  SearchForm: PropTypes.objectOf(PropTypes.any),
  allService: PropTypes.arrayOf(PropTypes.any),
  defaultFilter: PropTypes.arrayOf(PropTypes.any),
  t: PropTypes.func,
  onClickRow: PropTypes.func,
  modalTitle: PropTypes.string,
  isSingleSelect: PropTypes.bool,
  isOpen: PropTypes.bool,
};
ModalSelector.defaultProps = {
  allService: [],
  defaultFilter: [],
  tableColumns: [],
  lastIndex: -1,
  isBundle: false,
  isPackage: false,
  isPriceOffers: false,
  isSupportPOName: false,
  isSingleSelect: false,
  isOpen: false,
  onClickRow: () => {},
  modalTitle: '',
  t: () => {},
  SearchForm: undefined,
};

export default withTranslation('common')(ModalSelector);
