import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  let dataCreate = parseToMutationRequest(data, [
    'status',
    'leadingZeroes',
    'length',
    'index',
    'locationIndex',
    'startingNumber',
    'accountType',
    'refIndex',
    'type',
    'name'
  ]);
  dataCreate = dataCreate.replace(/subName/gi, 'name')
  const createGlAccountConfig = `createGlAccountConfig(input: [${dataCreate}])`;

  return gql`
  mutation {
    ${createGlAccountConfig} {
      id
    }
  }
`;
};
