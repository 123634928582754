import gql from 'graphql-tag';

export default () => gql`
  query {
    getTrunkRulesConfig(dummy: "") {
      id
      type
      trunkRulesConfigList {
        index
        trunkGroup
        site
        trafficType
        service
        trunkType
        accountId
        interConnectIndicator
        usageIndicator
        carrierId
        interConnectType
        usageType
      }
    }
  }
`;
