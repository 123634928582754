import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  searchTasks,
  createTask,
  getTaskHistoryById,
  syncTaskToExternal,
  modifyTask,
  getChildTasksByParentId,
} from '../../api';
import { queryRequest, mutationRequest } from '../../utils/request';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* searchTasksSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchTasks({ page, size, filter, sort }));
    if (response.searchTasks) {
      yield put(actions.searchTasksSuccess(response.searchTasks));
      if (cb) cb({ success: true, data: response.searchTasks });
    } else {
      yield put(actions.searchTasksSuccess([]));
      if (cb) cb({ success: true, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* searchAllTasksSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchTasks({ page, size, filter, sort }));
    if (response.searchTasks) {
      yield put(actions.searchAllTasksSuccess(response.searchTasks));
    } else {
      yield put(actions.searchAllTasksSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* createTaskSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTask(payload));
    if (response.createTask) {
      toast.success(i18n.t('common:apiMessage.createTaskSuccessfully'));
      if (cb) cb({ success: true, data: response.createTask });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getTaskHistoryByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaskHistoryById(payload));
    if (response.getTaskHistoryById) {
      if (cb) cb({ success: true, data: response.getTaskHistoryById });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* syncTaskToExternalSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, syncTaskToExternal(payload));
    if (response.syncTaskToExternal) {
      toast.success(i18n.t('common:apiMessage.syncTaskToExternalSuccessfully'));
      if (cb) cb({ success: true, data: response.syncTaskToExternal });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyTaskSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTask(payload));
    if (response.modifyTask) {
      toast.success(i18n.t('common:apiMessage.modifyTaskSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyTask });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getChildTasksByParentIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getChildTasksByParentId(payload));
    if (response.getChildTasksByParentId) {
      if (cb) cb({ success: true, data: response.getChildTasksByParentId });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* quotesSaga() {
  yield takeLatest(types.SEARCH_TASKS, searchTasksSaga);
  yield takeLatest(types.SEARCH_ALL_TASKS, searchAllTasksSaga);
  yield takeLatest(types.CREATE_TASK, createTaskSaga);
  yield takeLatest(types.GET_TASK_HISTORY_BY_ID, getTaskHistoryByIdSaga);
  yield takeLatest(types.SYNC_TASK_TO_EXTERNAL, syncTaskToExternalSaga);
  yield takeLatest(types.MODIFY_TASK, modifyTaskSaga);
  yield takeLatest(types.GET_CHILD_TASK_BY_PARENT_ID, getChildTasksByParentIdSaga);
}
