import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, []);
  let newData = dataModify.replace('{countryCodesConfig: ', '');
  newData = `${newData.substring(0, newData.length - 1)}`;
  const modifyConfigCountryCode = `modifyConfigCountryCode(input: ${newData})`;
  return gql`
  mutation {
    ${modifyConfigCountryCode} {
      name
    }
  }
`;
};
