import gql from 'graphql-tag';

export default ({ id, page, size }) => gql`
  query {
    getOperatorRecordById(input: { id: "${id}", page: ${page}, size: ${size} }) {
      id
      startDate
      startTime
      trafficType
      nim
      callType
      index
      anumber
      bnumber
      duration
      messageStatus
    }
  }
`;
