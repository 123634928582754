export default {
  sidebar: {
    config: 'Config',
    dashboard: 'Dashboard',
    userPreferences: 'User Preferences',
    myDashboard: 'My dashboard',
    customerDashboards: 'Customer Dashboards',
    orderDashboards: 'Order Dashboards',
    subscriptionDashboards: 'Subscription Dashboards',
    usageDashboards: 'Usage Dashboards',
    billingDashboards: 'Billing Dashboards',
    paymentsDashboards: 'Payments Dashboards',
    arOpsDashboards: 'AR Ops Dashboards',
    collectionDashboards: 'Collection Dashboards',
    revenueDashboards: 'Revenue Dashboards',
    jobsDashboards: 'Jobs Dashboards',
    newCustomer: 'New Customer',
    customerChurn: 'Customer Churn',
    trialToPaidConversion: 'Trial To Paid Conversion',
    subscriptionCancellations: 'Subscription Cancellations',
    billedRevenue: 'Billed Revenue',
    billedCustomer: 'Billed Customer',
    paymentSuccess: 'Payment Success',
    paymentSuspense: 'Payment Suspense',
    paymentFailed: 'Payment Failed',
    accountsWithFailedPayment: 'Accounts With Failed Payment',
    collection030Days: 'Collection 0-30 Days',
    collection3060Days: 'Collection 30-60 Days',
    collection6090Days: 'Collection 60-90 Days',
    collectionOver90Days: 'Collection > 90 Days',
    totalRevenue: 'Total Revenue',
    recognizedRevenue: 'Recognized Revenue',
    failedOrder: 'Failed Order',
    submittedOrder: 'Submitted Order',
    jobsFailed: 'Jobs Failed',
    billsFailed: 'Bills Failed',
    invoicesFailed: 'Invoices Failed',
    totalJobs: 'Total Jobs',
    // totalCreditAdjustments: 'Total Credit Adjustments',
    totalCreditAdjustments: 'Credit Adjustments',
    totalDisputes: 'Disputes',
    // totalDisputes: 'Total Disputes',
    // totalSettlements: 'Total Settlements',
    totalSettlements: 'Settlements',
    usageTransaction: 'Usage Transactions Received',
    ratedUsageTransaction: 'Rated Usage Transaction',
    // totalWriteOff: 'Total Cancelaciones',
    totalWriteOff: 'Net Write Off',
    acitveSubscriptions: 'Active Subscriptions',
    revenueType: 'Revenue Type',
    productCompany: 'Product Company',
    accountType: 'Account Type',
    sellingCompany: 'Selling Company',
    debitAdjustments: 'Debit Adjustments',
    revenueForecast: 'Revenue Forecast',
    monthlyRecurringRevenue: 'MRR Forecast',
    telecomTrafficDashboard: 'Telecom Traffic Dashboard',
    telecomTraffic: 'Telecom Traffic',
    folioSummary: 'Folio Summary',
  },
};
