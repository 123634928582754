import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [])}`
    : '';
  const searchUserGroups = `searchUserGroups(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchUserGroups} {
        id
        index
        groupId
        groupName
        users {
          index
          userId
          firstName
          lastName
          email
        }
      }
    }
  `;
};
