import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SubscriptionContractExpirySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.sellingCompany',
      name: 'sellingCompany',
    },
    {
      label: 'label.salesChannel',
      name: 'salesChannel',
      type: 'select',
      tOptions: 'selections:salesChannel',
      isClearable: true,
    },
    {
      label: 'label.marketSegment',
      name: 'marketSegment',
      tOptions: 'selections:marketSegment',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.accountSubType',
      name: 'accountSubType',
      type: 'select',
      tOptions: 'selections:accountSubType',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

SubscriptionContractExpirySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SubscriptionContractExpirySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SubscriptionContractExpirySearchForm);
