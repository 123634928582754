import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { size } from 'lodash';
import { DataTable, GenericInput } from '../common';

const tableColumns = [
  {
    name: 'action',
    label: 'label.action',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'label.reason',
  },
  {
    name: 'packageName',
    label: 'label.packageName',
  },
  {
    name: 'bundleName',
    label: 'Bundle Name',
  },
  // {
  //   name: 'bundleId',
  //   label: 'label.bundleId',
  // },
];

class TableFormServiceBundle extends PureComponent {
  state = {};

  render() {
    const {
      service,
      itemSelectOverride,
      onHandleShowLineService,
      onChangeProvisioningId,
      onDeleteService,
      t,
      isShowLine,
      isDisabled,
      onToggleModalShowProvisionig,
      isModifyOrder,
    } = this.props;

    const showProvisioningColumn = [];
    if (isModifyOrder) {
      showProvisioningColumn.push({
        name: 'orderProvisioningIds',
        label: t('label.orderProvisioningIds'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                disabled={!item || !item.provisioningList}
                onClick={() => onToggleModalShowProvisionig({ item: item || null })}
              >
                {t('label.view')}
              </button>
            </div>
          );
        },
      });
    }
    const tableConfig = {
      columns: [
        {
          name: 'provisioningId',
          label: t('label.provisioningId'),
          render: (colName, item) => {
            if (service[0].index === item.index)
              return (
                <GenericInput
                  key="quantity"
                  wrapperClass="col-md-12"
                  value={item.provisioningId}
                  type="text"
                  onChange={({ name, value }) => onChangeProvisioningId({ name, value, item })}
                  name="provisioningId"
                  disabled={isDisabled}
                  readOnly={isDisabled}
                />
              );

            return <span>{item.provisioningId}</span>;
          },
        },
        ...tableColumns,
        ...showProvisioningColumn,
        {
          name: 'action',
          label: t('label.remove'),
          render: (colName, item) => {
            return (
              <div className="form-group col-md-12">
                <button type="button" className="btn-phone" disabled={isDisabled} onClick={() => onDeleteService(item)}>
                  <i className="fa fa-trash" />
                </button>
              </div>
            );
          },
        },
        {
          name: 'selected',
          label: t('label.selected'),
          render: (colName, item) => {
            return (
              <button
                key="HideLines"
                type="button"
                className="button button-border gray x-small"
                onClick={() => {
                  onHandleShowLineService(item);
                }}
              >
                {itemSelectOverride.index === item.index
                  ? t('label.hideLines')
                  : t(isShowLine ? 'label.showLines' : 'label.overrideLines')}
              </button>
            );
          },
        },
      ],
      data: service.filter(value => size(value) > 2),
      sorted: {},
      onSort: this.onSortColumn,
    };
    return (
      <div>
        <DataTable {...tableConfig} onClickRow={this.onClickRow} />
      </div>
    );
  }
}

TableFormServiceBundle.propTypes = {
  itemSelectOverride: PropTypes.objectOf(PropTypes.any),
  service: PropTypes.arrayOf(PropTypes.any),
  onHandleShowLineService: PropTypes.func,
  onChangeProvisioningId: PropTypes.func,
  onDeleteService: PropTypes.func,
};

TableFormServiceBundle.defaultProps = {
  onHandleShowLineService: () => {},
  onChangeProvisioningId: () => {},
  onDeleteService: () => {},
  itemSelectOverride: {},
  service: [],
};

export default withTranslation('common')(TableFormServiceBundle);
