import gql from 'graphql-tag';
import { parseToMutationRequestUser } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequestUser(data, ['index']);
  const modifyUserGroup = `modifyUserGroup(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyUserGroup} {
      id
    }
  }
`;
};
