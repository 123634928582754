/**
 * Collections selectors
 */

import { createSelector } from 'reselect';

const selectCollections = state => state.get('revenueReducer');

const makeGetRevenueMilestone = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('revenueMilestone').length ? revenue.get('revenueMilestone') : []
  );

const makeGetRevenueMilestoneItem = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('revenueMilestoneItem') ? revenue.get('revenueMilestoneItem') : {}
  );

const makeGetExchangeRate = () =>
  createSelector(selectCollections, revenue => (revenue.get('exchangeRate') ? revenue.get('exchangeRate') : {}));

const makeGetExchangeRateById = () =>
  createSelector(selectCollections, revenue => (revenue.get('exchangeRateId') ? revenue.get('exchangeRateId') : {}));

const makeGetExchangeRateExportId = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('exchangeRateExportId') ? revenue.get('exchangeRateExportId') : {}
  );

const makeGetAccountingLogs = () =>
  createSelector(selectCollections, revenue => (revenue.get('accountingLogs') ? revenue.get('accountingLogs') : {}));

const makeGetEbsExtract = () =>
  createSelector(selectCollections, revenue => (revenue.get('ebsExtract') ? revenue.get('ebsExtract') : {}));

// Distination
const makeGetListRevenueJournal = () => createSelector(selectCollections, items => items.get('listRevenueJournal'));
const makeGetListAllRevenueJournal = () =>
  createSelector(selectCollections, items => items.get('listAllRevenueJournal'));

const makeGetListAllAccountingEntry = () =>
  createSelector(selectCollections, items =>
    items.get('listAllAccountingEntry') ? items.get('listAllAccountingEntry') : []
  );

const makeGetAllEbsExtract = () =>
  createSelector(selectCollections, revenue =>
    revenue.get('listAllEbsExtract') ? revenue.get('listAllEbsExtract') : []
  );

export {
  makeGetRevenueMilestone,
  makeGetRevenueMilestoneItem,
  makeGetExchangeRate,
  makeGetExchangeRateById,
  makeGetExchangeRateExportId,
  makeGetAccountingLogs,
  makeGetEbsExtract,
  makeGetListRevenueJournal,
  makeGetListAllRevenueJournal,
  makeGetListAllAccountingEntry,
  makeGetAllEbsExtract,
};
