import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import listCurrency from '../../../constantsApp/currency.json';
import SubForm from './SubForm';
import TierForm from './TierForm';
import { GenericInput, FormCollapse } from '../../common';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const tierInputs = [
  {
    label: 'label.startRange',
    wrapperClass: 'col-md-4',
    name: 'minimum',
    type: 'number',
    required: true,
  },
  {
    label: 'label.endRange',
    wrapperClass: 'col-md-4',
    name: 'maximum',
    type: 'number',
    required: true,
  },
];

const renderTier = ({
  t,
  item,
  index,
  isComplexUsage,
  optionsAccumulatorId,
  optionsGrantId,
  grantInputs,
  accumulatorInputs,
  isNotTier,
  onToggleTab,
  activeTab,
  discountingModel,
  transactionType,
}) => {
  return (
    item.tiers &&
    item.tiers
      .filter(v => size(v) > 1)
      .map((tier, idx) => {
        let title = '-';
        if (tier.minimum && tier.minimum !== '0') {
          title = `<${tier.minimum}>${title}`;
        } else {
          title = `<0>${title}`;
        }
        if (tier.maximum && tier.maximum !== '0') {
          title = `${title}<${tier.maximum}>`;
        } else {
          title = `${title}<0>`;
        }
        return (
          <div className={!isNotTier ? 'card-body' : ''} key={`tier-${index}-${idx}`}>
            <FormCollapse
              key={`tier-${idx}`}
              label="First Name"
              isActive={activeTab && `tier-${index}-${idx}` === activeTab.name && activeTab.isActive}
              title={title}
              state={`tier-${index}-${idx}`}
              onToggleTab={onToggleTab}
            >
              {!isNotTier && (
                <div className="card-header">
                  {`${t('label.addTier')}#`}
                  {idx + 1}
                </div>
              )}
              <SubForm
                headerTitle={`#${idx + 1}`}
                inputs={
                  !isComplexUsage
                    ? tierInputs
                    : tierInputs.map(val => {
                        const { required, ...rest } = val;
                        return rest;
                      })
                }
                onRemoveItem={e => {
                  e.preventDefault();
                }}
                item={tier}
                isComplexUsage={isComplexUsage}
                index={index}
              />
              <TierForm
                data={tier}
                isTier
                optionsAccumulatorId={optionsAccumulatorId}
                optionsGrantId={optionsGrantId}
                grantInputs={grantInputs}
                accumulatorInputs={accumulatorInputs}
                isComplexUsage={isComplexUsage}
                index={index}
                discountingModel={discountingModel}
                transactionType={transactionType}
              />
            </FormCollapse>
          </div>
        );
      })
  );
};

const PricesForm = ({
  item,
  index,
  creatingMode,
  optionsAccumulatorId,
  optionsGrantId,
  discountingModel,
  isComplexUsage,
  readOnlyCurrency,
  selectField,
  transactionType,
}) => {
  const [activeTab, setActiveTab] = useState({});

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };
  const { t } = useTranslation('common');
  if (!item.discounts && !item.tiers) return '';

  const grantInputs = [
    {
      label: 'label.grantId',
      wrapperClass: 'col-md-4',
      name: 'resourceId',
      type: 'select',
      required: true,
      options: optionsGrantId,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'grantUnit',
      type: 'select',
      required: true,
      tOptions: 'selections:grantUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'grantDuration',
      type: 'number',
      required: true,
    },
  ];

  const accumulatorInputs = [
    {
      label: 'label.accumulatorId',
      wrapperClass: 'col-md-4',
      name: 'accumulatorId',
      type: 'select',
      required: true,
      options: optionsAccumulatorId,
      isClearable: true,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'unit',
      type: 'select',
      required: true,
      tOptions: 'selections:accumulatorUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'duration',
      type: 'number',
      required: true,
    },
  ];
  if (discountingModel === 'TRANSACTION_ATTRIBUTE_BASED') {
    return (
      <div className="card price-panel">
        <TierForm
          data={item}
          optionsAccumulatorId={optionsAccumulatorId}
          optionsGrantId={optionsGrantId}
          grantInputs={grantInputs}
          accumulatorInputs={accumulatorInputs}
          discountingModel={discountingModel}
          transactionType={transactionType}
        />
      </div>
    );
  }

  const isComplexWithTier = isComplexUsage && selectField.indexOf(4) > -1;

  return (
    <div>
      <div className="card price-panel">
        {discountingModel !== 'FLAT' && (
          <div className="card-header">
            {t('label.discount')}
#
            {index + 1}
          </div>
        )}
        <div className="card-body">
          {item &&
            item.discounts &&
            item.discounts.map((price, idx) => {
              const priceInputs = [
                {
                  label: 'common:label.currency',
                  wrapperClass: 'col-md-4',
                  name: 'currencyId',
                  type: 'select',
                  options: currencyOptions,
                  disabled: index > 1 && !isComplexUsage,
                  readOnly: readOnlyCurrency,
                  menuPortalTarget: true,
                },
                {
                  label: 'common:label.amount',
                  wrapperClass: 'col-md-4',
                  name: 'amount',
                  type: 'number',
                  disabled: !!price.percent,
                  isNegativeValue: true,
                },
                {
                  label: 'common:label.percent',
                  wrapperClass: 'col-md-4',
                  name: 'percent',
                  type: 'number',
                  disabled: !!price.amount,
                  isNegativeValue: true,
                },
              ];
              return (
                <div className="card-body" key={`discounts-${idx}`}>
                  {discountingModel !== 'FLAT' && (
                    <div className="card-header">
                      {`${t('label.discount')}#`}
                      {index + 1}
                    </div>
                  )}
                  <SubForm
                    key={`${index}-${idx}-sub-form`}
                    headerTitle={`#${idx + 1}`}
                    inputs={priceInputs}
                    onRemoveItem={e => {
                      e.preventDefault();
                    }}
                    item={price}
                    isComplexUsage={isComplexUsage}
                    index={index}
                  />
                </div>
              );
            })}
          {item &&
            item.accumulators &&
            item.accumulators.length > 0 &&
            item.accumulators.map((accumulator, idx) => {
              if (accumulator && size(accumulator) > 2) {
                return (
                  <div key={`accumulators-${idx}`}>
                    <div className="card-header">{`Accumulator#${idx + 1}`}</div>
                    <div className="row col-md-12 p-3" key={`grant-${idx}`}>
                      {accumulatorInputs.map(item => (
                        <GenericInput
                          key={`accumulators-${item.name}`}
                          {...item}
                          value={accumulator[item.name]}
                          readOnly
                          disabled
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {(!isComplexUsage || isComplexWithTier) &&
            renderTier({
              item,
              index,
              isComplexUsage,
              creatingMode,
              optionsAccumulatorId,
              optionsGrantId,
              grantInputs,
              accumulatorInputs,
              t,
              onToggleTab,
              activeTab,
              discountingModel,
              transactionType,
            })}
        </div>
      </div>
      <div>
        {isComplexUsage && !isComplexWithTier && (
          <div>
            {renderTier({
              item,
              index,
              isComplexUsage,
              creatingMode,
              optionsAccumulatorId,
              optionsGrantId,
              grantInputs,
              accumulatorInputs,
              isNotTier: true,
              t,
              onToggleTab,
              activeTab,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

PricesForm.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  optionsAccumulatorId: PropTypes.arrayOf(PropTypes.any),
  optionsGrantId: PropTypes.arrayOf(PropTypes.any),
  selectField: PropTypes.arrayOf(PropTypes.any),
  discountingModel: PropTypes.string,
  index: PropTypes.number.isRequired,
  isComplexUsage: PropTypes.bool,
  creatingMode: PropTypes.bool,
  readOnlyCurrency: PropTypes.bool,
  transactionType: PropTypes.string,
};

PricesForm.defaultProps = {
  item: {},
  optionsAccumulatorId: [],
  optionsGrantId: [],
  selectField: [],
  discountingModel: '',
  isComplexUsage: true,
  creatingMode: true,
  readOnlyCurrency: true,
  transactionType: '',
};
export default PricesForm;
