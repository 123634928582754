/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { searchAccounts } from '../../actions';
import AccountSort from '../../../../constantsApp/customer/AccountSort';
import ModalSelector from '../../../../components/Modals/ModalSelector';
import { SearchAccountForm } from '../../../../components/PaymentHub';

class ModalParentId extends PureComponent {
  render() {
    const { isOpen, searchAccounts, onSelect, onCancel, selectedParentId, t, defaultSearchForm } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'label.ACCTNo',
        },
        {
          name: 'firstName',
          label: 'label.firstName',
          sortable: true,
          render: (colName, item) => {
            const contact = item.contacts ? item.contacts[0] || {} : {};
            return <span>{contact.firstName}</span>;
          },
        },
        {
          name: 'lastName',
          label: 'label.lastName',
          sortable: true,
          render: (colName, item) => {
            const contact = item.contacts ? item.contacts[0] || {} : {};
            return <span>{contact.lastName}</span>;
          },
        },
        {
          name: 'organization',
          label: 'label.organization',
          sortable: true,
          render: (colName, item) => {
            const contact = item.contacts ? item.contacts[0] || {} : {};
            return <span>{contact.organization}</span>;
          },
        },
        {
          name: 'city',
          label: 'label.city',
          sortable: true,
          render: (colName, item) => {
            const addresses = item.addresses ? item.addresses[0] || {} : {};
            return <span>{addresses.city}</span>;
          },
        },
        {
          name: 'state',
          label: 'label.state',
          sortable: true,
          render: (colName, item) => {
            const address = item.addresses ? item.addresses[0] || {} : {};
            return <span>{address.state}</span>;
          },
        },
        {
          name: 'status',
          label: 'label.status',
          render: (colName, item) => {
            const slt = t('selections:status')().find(val => val.value === item.status);
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };

    return (
      <ModalSelector
        modalTitle={t('label.chooseAccount')}
        isOpen={isOpen}
        SearchForm={SearchAccountForm}
        searchItems={searchAccounts}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        arraySort={AccountSort}
        defaultSearchForm={defaultSearchForm}
      />
    );
  }
}

ModalParentId.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  searchAccounts: PropTypes.func,
};

ModalParentId.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  searchAccounts: () => {},
  selectedParentId: '',
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(connect(mapStateToProps, { searchAccounts })(ModalParentId));
