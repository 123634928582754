import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigCreditProfile(input: { dummy: "" }) {
      id
      profilesList {
        customerSegment
        name
        salesChannel
        marketSegment
        accountType
        accountSubType
        currency
        index
        creditLimit
      }
    }
  }
`;
