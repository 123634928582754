export default {
  suspend: {
    type: 'SUSPEND',
    accountId: '',
    subscriptionReason: '',
    userId: 'USR-0001',
    status: 'CREATED',
  },
  resume: {
    type: 'RESUME',
    accountId: '',
    userId: 'USR-0001',
  },
  cancel: {
    type: 'CANCEL',
    accountId: '',
    subscriptionReason: '',
    userId: 'USR-0001',
    status: 'CREATED',
  },
  renew: {
    type: 'RENEW',
    accountId: '',
    renewalTermUnit: '',
    renewalTerm: '',
    userId: 'USR-0001',
    status: 'CREATED',
  },
};
