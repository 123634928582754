import gql from 'graphql-tag';

export default fileName => {
  const getSantanderPaymentByFileName = `getSantanderPaymentByFileName(input: {fileName:"${fileName}"})`;
  return gql`
    query {
      ${getSantanderPaymentByFileName} {
        paymentRefId
        account
        recordDate
        recordTime
        description
        chargeSign
        credit
        balance
        referenceId
        conceptBank
        participantName
        beneficiaryName
        beneficiary
        payingAccount
        payingName
        refundCode
        refundReason
        beneficiaryRfc
        originatorRfc
        trackingKey
        accountId
        invoiceId
        status
        errorCode
        suspenseAccountFlag
        paymentId
        batchId
        fileName
        createdDate
        processedDate
      }
    }
  `;
};
