import React from 'react';
import PropsType from 'prop-types';
import { map, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import GenericInput from '../GenericInput';

const ModalDetailsItem = ({
  title,
  isOpen,
  onToggle,
  data,
  dataField,
  isSearch,
  children,
  wrapperClass,
  isBackBtn,
}) => {
  const { t } = useTranslation('common');
  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={wrapperClass || 'modal-lg info-form'}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {isSearch ? (
          <div className="text-center">
            <PulseLoader size={13} color="#28a745" />
          </div>
        ) : (
          <div className="row col-md-12">
            {!isEmpty(dataField) &&
              map(dataField, item => {
                if (item && item.isSelect) {
                  return (
                    <GenericInput
                      key={item.name}
                      label={t && item.label ? t(item.label) : item.label || ''}
                      type="select"
                      value={data[item.name]}
                      onChange={() => {}}
                      disabled
                      {...item}
                    />
                  );
                }

                if (item && item.fieldDate) {
                  return (
                    <GenericInput
                      key={item.name}
                      type="date"
                      isClearable
                      disabled
                      label={t && item.label ? t(item.label) : item.label || ''}
                      dateFormat="yyyy-MM-dd"
                      value={data[item.name]}
                      onChange={() => {}}
                      {...item}
                    />
                  );
                }

                return (
                  <GenericInput
                    key={item.name}
                    value={data[item.name]}
                    label={t && item.label ? t(item.label) : item.label || ''}
                    type={item.type || 'text'}
                    onChange={() => {}}
                    disabled
                    {...item}
                  />
                );
              })}
          </div>
        )}
        {children}
      </ModalBody>
      <ModalFooter>
        {isBackBtn ? (
          <button type="button" onClick={onToggle} className="button x-small float-right">
            {t('label.back')}
          </button>
        ) : (
          <Button color="primary" onClick={onToggle}>
            {t('label.ok')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ModalDetailsItem.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

ModalDetailsItem.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

export default ModalDetailsItem;
