/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { withTranslation } from 'react-i18next';
import { btnCollapse } from 'constantsApp';
import { GenericInput, AccordionGroup, Accordion } from '../../../components/common';
import RouteNames from '../../App/RouteNames';

import HubModule from './HubModule';

const cleanArrayIndex = arr => {
  return arr.map((a, idx) => {
    return {
      ...a,
      index: idx + 1,
    };
  });
};
const listInputs = [
  {
    label: 'common:label.roleName',
    wrapperClass: 'col-md-6',
    name: 'name',
    required: true,
  },
  {
    label: 'common:label.roleStatus',
    wrapperClass: 'col-md-6',
    name: 'status',
    required: true,
    type: 'select',
    tOptions: 'selections:status',
  },
  {
    label: 'common:label.roleType',
    wrapperClass: 'col-md-6',
    name: 'type',
    required: true,
  },
  {
    label: 'common:label.description',
    wrapperClass: 'col-md-6',
    name: 'description',
    required: true,
    type: 'textarea',
  },
];

class RoleForm extends Component {
  state = {
    formValid: true,
    wasValidated: false,
    arHubModules: null,
    billingHubModules: null,
    customerHubModules: null,
    opsHubModules: null,
    pricingHubModules: null,
    revenueHubModules: null,
    selfCareHubModules: null,
    mediationHubModules: null,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    return {
      ...props.item,
      item: props.item,
    };
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  handleInputChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { onSubmit } = this.props;
    const {
      arHubModules,
      billingHubModules,
      customerHubModules,
      description,
      id,
      name,
      opsHubModules,
      pricingHubModules,
      revenueHubModules,
      selfCareHubModules,
      mediationHubModules,
      status,
      type,
    } = this.state;
    if (onSubmit) {
      onSubmit(
        {
          arHubModules: arHubModules && arHubModules.length > 0 ? cleanArrayIndex(arHubModules) : [],
          billingHubModules:
            billingHubModules && billingHubModules.length > 0 ? cleanArrayIndex(billingHubModules) : [],
          customerHubModules:
            customerHubModules && customerHubModules.length > 0 ? cleanArrayIndex(customerHubModules) : [],
          description,
          id,
          name,
          opsHubModules: opsHubModules && opsHubModules.length > 0 ? cleanArrayIndex(opsHubModules) : [],
          pricingHubModules:
            pricingHubModules && pricingHubModules.length > 0 ? cleanArrayIndex(pricingHubModules) : [],
          revenueHubModules:
            revenueHubModules && revenueHubModules.length > 0 ? cleanArrayIndex(revenueHubModules) : [],
          selfCareHubModules:
            selfCareHubModules && selfCareHubModules.length > 0 ? cleanArrayIndex(selfCareHubModules) : [],
          mediationHubModules:
            mediationHubModules && mediationHubModules.length > 0 ? cleanArrayIndex(mediationHubModules) : [],
          status,
          type,
        },
        success => {
          this.setState({ wasValidated: !success });
        }
      );
    }
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onRemoveRole = (e, role) => {
    e.preventDefault();
    const { roleGroupRoles } = this.state;
    const newRoles = roleGroupRoles.filter(item => item.id !== role.id);
    this.setState({ roleGroupRoles: newRoles });
  };

  onToggleModule = (e, hub) => {
    e.preventDefault();
    if (!this.state[hub.hubFieldName]) return;
    const { openingHub } = this.state;
    if (openingHub !== hub.code) {
      this.setState({ openingHub: hub.code });
    } else {
      this.setState({ openingHub: null });
    }
  };

  onChangeHub = (hub, data) => {
    this.setState({ [hub.hubFieldName]: [...data] });
  };

  toggleActivateHubModule = (hub, val, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (val) {
      this.setState({ [hub.hubFieldName]: this.initPermission(hub, 'READ_ONLY'), openingHub: hub.code });
    } else {
      this.setState({ [hub.hubFieldName]: null, openingHub: null });
    }
  };

  initPermission = (hub = {}, type) => {
    const { childs } = hub;
    if (!childs) return;
    return childs.map((item, index) => {
      const permissions = item.permissions.map((itm, idx) => ({
        index: idx + 1,
        moduleName: item.moduleName,
        permission: itm,
        type,
      }));
      return {
        index: index + 1,
        [item.moduleFieldName]: permissions,
        hubName: item.hubName,
        moduleName: item.moduleName,
      };
    });
  };

  onCancelData = () => {
    this.setState({
      formValid: true,
      wasValidated: false,
      arHubModules: null,
      billingHubModules: null,
      customerHubModules: null,
      opsHubModules: null,
      pricingHubModules: null,
      revenueHubModules: null,
      selfCareHubModules: null,
      openingHub: null,
      description: null,
      name: null,
      status: null,
      type: null,
    });
  };

  render() {
    const { creatingMode, isSubmitting, modeModifyRole, modeModifyRoleStatus, t, onCancel } = this.props;
    let newListInput = cloneDeep(listInputs);
    const indexStatusOption = newListInput.findIndex(item => item.name === 'status');
    if (modeModifyRoleStatus === 0) {
      newListInput = newListInput.filter(item => item.name !== 'status');
    }

    if (modeModifyRoleStatus === 1) {
      newListInput[indexStatusOption].disabled = true;
    }

    const {
      arHubModules,
      billingHubModules,
      customerHubModules,
      opsHubModules,
      pricingHubModules,
      revenueHubModules,
      selfCareHubModules,
      mediationHubModules,
      status,
      type,
    } = this.state;

    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={`needs-validation ${this.state.wasValidated ? 'was-validated' : ''}`}
          noValidate
          ref={this.formRef}
        >
          <div className="form-row">
            {newListInput.map(input => (
              <GenericInput
                key={input.name}
                {...input}
                value={this.state[input.name] || ''}
                onChange={this.handleInputChange}
              />
            ))}
          </div>
          <AccordionGroup>
            {btnCollapse.rolesDetails.map(rd => (
              <Accordion
                key={rd.code}
                title={rd.title}
                isOpen={rd.code === this.state.openingHub}
                onClick={e => this.onToggleModule(e, rd)}
                disabled={!this.state[rd.hubFieldName] || this.state[rd.hubFieldName].length === 0}
                switcher={
                  <Switch
                    onChange={(val, e) => this.toggleActivateHubModule(rd, val, e)}
                    checked={!!this.state[rd.hubFieldName]}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={36}
                    className="accordion-toggle-switcher"
                  />
                }
              >
                <HubModule
                  config={rd}
                  data={this.state[rd.hubFieldName] ? this.state[rd.hubFieldName] || [] : []}
                  onChangeHub={dd => this.onChangeHub(rd, dd)}
                />
              </Accordion>
            ))}
          </AccordionGroup>
          <div />
          <div className="form-group col-md-12 action-buttons">
            <label htmlFor="validationCustom01" />
            <br />
            {/* {!creatingMode && !isSubmitting && (
              <Link
                to={{
                  pathname: RouteNames.userManagementRoleAdd.path,
                  state: {
                    arHubModules,
                    billingHubModules,
                    customerHubModules,
                    opsHubModules,
                    pricingHubModules,
                    revenueHubModules,
                    selfCareHubModules,
                    mediationHubModules,
                    status,
                    type,
                  },
                }}
                className="button button-border gray x-small float-right"
              >
                {t('label.copyToCreate')}
              </Link>
            )} */}
            {isSubmitting && (
              <button type="button" className="button button-border x-small float-right">
                {t('common:label.modify')}
              </button>
            )}
            {!creatingMode && !isSubmitting && modeModifyRole === 2 && (
              <button type="submit" className="button button-border x-small float-right">
                {t('common:label.modify')}
              </button>
            )}
            {creatingMode && !isSubmitting && (
              <button type="submit" className="button button-border x-small float-right">
                {t('common:label.create')}
              </button>
            )}
            {onCancel ? (
              <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
                {t('label.cancel')}
              </button>
            ) : (
              <button
                onClick={this.onCancelData}
                type="button"
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            )}
            {/* <Link to={RouteNames.userManagementRole.path} className="button button-border black x-small  float-right">
              {t('common:label.cancel')}
            </Link> */}
          </div>
        </form>
      </div>
    );
  }
}

RoleForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.object,
  isSubmitting: PropTypes.bool,
  modeModifyRole: PropTypes.number,
  modeModifyRoleStatus: PropTypes.number,
};

RoleForm.defaultProps = {
  item: {},
  isSubmitting: false,
  modeModifyRole: 0,
  modeModifyRoleStatus: 2,
};
export default withTranslation('common')(RoleForm);
