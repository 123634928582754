import gql from 'graphql-tag';

export default ({ id, savedInvoiceId }) => gql`
  query {
    getPendingManualInvoice(input:{ accountId: "${id}" ${savedInvoiceId ? `savedInvoiceId: "${savedInvoiceId}"` : ''} }) {
      accountId
      txnDate
      savedManualInvoiceId
      billStartDate
      billEndDate
      invoiceId
      scheduleDaily
      returnBase64PDF
      scheduleDate
      errorReason
      status
      substituteInvoice
      substitutedInvoiceStatus
      satCode
      manualPreratedTxnRec {
        txnId
        accountId
        txnDate
        startDate
        endDate
        serviceType
        itemId
        description
        customDescription
        provisioningId
        currency
        quantity
        unitPrice
        grossAmount
        discountAmount
        netAmount
        costCenter
        serviceName
        omsInternalId
      }
    }
  }
`;
