/**
 * PricingManagerpage selectors
 */

import { createSelector } from 'reselect';

const selectPricingManager = state => {
  return state.get('pricingReducer');
};

// ----item
const makePageItemParams = () => createSelector(selectPricingManager, items => items.get('paramsItems').toJS());

const makeGetListItems = () => {
  return createSelector(selectPricingManager, items => [...items.get('listItems')]);
};

const makeGetItemDetail = () => createSelector(selectPricingManager, item => item.get('itemInfo'));

// ----price-offer
const makePagePriceOfferParams = () =>
  createSelector(selectPricingManager, prices => prices.get('paramsPriceOffers').toJS());

const makeGetListPriceOffers = () => createSelector(selectPricingManager, prices => [...prices.get('listPriceOffers')]);

const makeGetPriceOfferDetail = () => createSelector(selectPricingManager, price => price.get('priceOfferInfo'));

const makeErrorMessage = () => createSelector(selectPricingManager, item => item.get('errorMessage'));

const makeGetListDiscountOffers = () =>
  createSelector(selectPricingManager, prices => [...prices.get('listDiscountOffers')]);

const makeGetDiscountOfferDetail = () =>
  createSelector(selectPricingManager, prices => prices.get('discountOfferInfo'));

const makeGetProductFamilyConfig = () => createSelector(selectPricingManager, items => items.get('productFamilyList'));

const makeGetItemServices = () => createSelector(selectPricingManager, items => items.get('itemServices'));
const makeGetAllItemServices = () => createSelector(selectPricingManager, items => items.get('allItemServices'));

const makeGetDiscountTriggerList = () =>
  createSelector(selectPricingManager, items => items.get('discountTriggerList'));

export {
  makeGetListItems,
  makePageItemParams,
  makeGetItemDetail,
  makeGetListPriceOffers,
  makePagePriceOfferParams,
  makeGetPriceOfferDetail,
  makeErrorMessage,
  makeGetListDiscountOffers,
  makeGetDiscountOfferDetail,
  makeGetProductFamilyConfig,
  makeGetItemServices,
  makeGetAllItemServices,
  makeGetDiscountTriggerList,
};
