import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index', 'recordIndicator', 'sourceFieldPos', 'targetRecord', 'serviceType']);
  const createUsageFileMapCfg = `createUsageFileMapCfg(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createUsageFileMapCfg} {
      id
    }
  }
`;
};
