import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { selectPackageId, selectBundleId, selectPriceOfferId } from '../../App/actions';
import { ServiceTable } from '../../../components/Orders';
import { updateOrderStatus, modifyOrder } from '../actions';
import { makeErrorMessage } from '../selectors';
import constants from '../constants';

class ServiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverrideLines: {},
    };
  }

  componentDidMount = () => {};

  componentWillReceiveProps() {}

  handleClickServicesFormBtn = index => {
    const { isOverrideLines } = this.state;
    isOverrideLines[index] = !isOverrideLines[index];
    this.setState({
      isOverrideLines,
    });
  };

  renderServiceTableWithPackage = () => {
    const { isOverrideLines } = this.state;
    const {
      groupServices,
      addLine,
      onChangeFormLine,
      onChangeService,
      onChangeProvisioningId,
      onRemoveLine,
      onRemoveItem,
      isMultiSubscriptionEnabled,
      onChangeData,
      subscriptionsData,
      subscriptionName,
      isReadMultiSubscriptionEnabled,
      isViewOnly,
      doGetItemDetails,
      isSupportViewProvisioning,
    } = this.props;

    return map(groupServices, (service, index) => {
      return (
        <ServiceTable
          key={index}
          isFetchLine
          addLine={(serviceIndex, e) => addLine(index, serviceIndex, e)}
          onChangeService={e => {
            onChangeService(index, e);
          }}
          isRemoveTrashFromLine
          onRemoveItem={onRemoveItem}
          onRemoveLine={onRemoveLine}
          onChangeProvisioningId={onChangeProvisioningId}
          isOverrideLines={isOverrideLines[index]}
          onChangeFormLine={(serviceIndex, e) => onChangeFormLine(index, serviceIndex, e)}
          services={service}
          handleClickServicesFormBtn={() => this.handleClickServicesFormBtn(index)}
          isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
          onChangeData={(serviceIndex, e) => onChangeData({ groupIndex: index, serviceIndex, e })}
          subscriptionsData={subscriptionsData}
          subscriptionName={subscriptionName}
          isReadMultiSubscriptionEnabled={isReadMultiSubscriptionEnabled}
          isViewOnly={isViewOnly}
          isSupportShowAddDiscount
          doGetItemDetails={doGetItemDetails}
          isSupportViewProvisioning={isSupportViewProvisioning}
        />
      );
    });
  };

  renderServiceTable = () => {
    const { isOverrideLines } = this.state;
    const {
      groupServices,
      addLine,
      onChangeFormLine,
      onChangeService,
      onChangeProvisioningId,
      onRemoveLine,
      onRemoveItem,
      isMultiSubscriptionEnabled,
      onChangeData,
      subscriptionsData,
      subscriptionName,
      isReadMultiSubscriptionEnabled,
      isViewOnly,
      doGetItemDetails,
      isSupportViewProvisioning,
    } = this.props;

    return map(groupServices, (service, index) => {
      return (
        <ServiceTable
          key={index}
          isFetchLine={false}
          addLine={(serviceIndex, e) => addLine(index, serviceIndex, e)}
          onChangeService={e => {
            onChangeService(index, e);
          }}
          isRemoveTrashFromLine
          onRemoveLine={onRemoveLine}
          onRemoveItem={onRemoveItem}
          isOverrideLines={isOverrideLines[index]}
          onChangeProvisioningId={onChangeProvisioningId}
          onChangeFormLine={(serviceIndex, e) => onChangeFormLine(index, serviceIndex, e)}
          services={service}
          handleClickServicesFormBtn={() => this.handleClickServicesFormBtn(index)}
          isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
          // onChangeData={onChangeData}
          onChangeData={(serviceIndex, e) => onChangeData({ groupIndex: index, serviceIndex, e })}
          subscriptionsData={subscriptionsData}
          subscriptionName={subscriptionName}
          isReadMultiSubscriptionEnabled={isReadMultiSubscriptionEnabled}
          isViewOnly={isViewOnly}
          isSupportShowAddDiscount
          doGetItemDetails={doGetItemDetails}
          isSupportViewProvisioning={isSupportViewProvisioning}
        />
      );
    });
  };

  renderServiceTables = () => {
    const { mode } = this.props;
    switch (mode) {
      case constants.ModalMode.purchasePackage:
        return this.renderServiceTableWithPackage();

      default:
        return this.renderServiceTable();
    }
  };

  render() {
    const { id, t } = this.props;
    return (
      <div className="card card-statistics">
        {/* <CardHeaderForm title={t('label.order')} label={t('label.accountNumber')} value={id} /> */}
        {this.renderServiceTables()}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || {},
});

ServiceForm.propTypes = {
  onRemoveLine: PropTypes.func,
};

ServiceForm.defaultProps = {
  onRemoveLine: () => {},
};

const newServiceForm = connect(mapStateToProps, {
  updateOrderStatus,
  modifyOrder,
  selectPackageId,
  selectBundleId,
  selectPriceOfferId,
})(ServiceForm);

export default withTranslation('common')(newServiceForm);
