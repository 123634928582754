import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchFolioDataExtract = `searchFolioDataExtract(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchFolioDataExtract} {
        clientId
        commercialName
        iCareClassif
        sGiro
        salesAgent
        salesAgentId
        sTipoMercado
        uom
        serviceName
        omsInternalId
        familyName
        familyId
        subFamily1Name
        subFamily1Id
        subFamily2Name
        subFamily2Id
        companyName
        city
        folioId
        organization
        segmentId
        productFamily
        productType
        productLine
        folioDate
        crmClientId
        locationId
        nIdPersonalEvCierre
        salesConsultant
        sFunctionEvCierre
        stampingDate
        invoiceTotal
        invoiceTotalInDestCur
        quantity
        unitPrice
        exchangeRate
        billableServiceId
        glAccount
      }
    }
  `;
};
