import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';
import activitiesNS from './constants/activitiesNS';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, activitiesNS)}` : '';
  const searchActivities = `searchActivities(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchActivities} {
        id
        userId
        entityType
        entityId
        action
        source
        activityDate
        gatewayType
        merchantName
        apiName
      }
    }
  `;
};
