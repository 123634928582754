import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalWithItem from '../ModalWithItem';
import DataTable from '../DataTable/Table';
import { getBillableServiceProvisioningAttributes } from '../../../containers/App/actions';

const columnsAttributes = [
  {
    name: 'name',
    label: 'common:label.name',
  },
  {
    name: 'value',
    label: 'common:label.value',
  },
];

class ModalOrderProvisioningAttributes extends React.PureComponent {
  state = {
    provisioningAttributesData: [],
  };

  componentDidUpdate(prevProps) {
    const { itemSelect, orderDetail, getBillableServiceProvisioningAttributes } = this.props;
    if (itemSelect && itemSelect.omsInternalId && itemSelect.omsInternalId !== prevProps?.itemSelect?.omsInternalId) {
      getBillableServiceProvisioningAttributes(
        {
          accountId: orderDetail.accountId || null,
          omsInternalId: itemSelect.omsInternalId || null,
          orderId: orderDetail.id || null,
        },
        ({ success, data }) => {
          this.setState({
            provisioningAttributesData: success && data?.provisioningAttributes ? data?.provisioningAttributes : [],
          });
        }
      );
    }
  }

  render() {
    const { isOpen, t, onToggle } = this.props;
    const { provisioningAttributesData } = this.state;
    return (
      <ModalWithItem
        // wrapperClass="modal-custom modal-70"
        modalTitle={t('label.provisioningAttributes')}
        isOpen={isOpen}
        onToggle={onToggle}
      >
        <div className="col-md-12 pt-3 pb-3 pt-0">
          <DataTable columns={columnsAttributes} data={provisioningAttributesData || []} />
          <button type="button" onClick={onToggle} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </ModalWithItem>
    );
  }
}

ModalOrderProvisioningAttributes.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

ModalOrderProvisioningAttributes.defaultProps = {
  isOpen: false,
};

export default withTranslation('common')(
  connect(null, { getBillableServiceProvisioningAttributes })(ModalOrderProvisioningAttributes)
);
