import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CurrencyInput from 'react-currency-input';

const CurrencyFormat = ({ label, wrapperClass, onValueChange, ...props }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`form-group ${wrapperClass}`}>
      <span>{t(label)}</span>
      <CurrencyInput
        className="form-control input-group"
        onChangeEvent={(event, maskedvalue, floatvalue) => onValueChange({ value: floatvalue })}
        decimalSeparator="."
        thousandSeparator=","
        // prefix="$"
        {...props}
      />
    </div>
  );
};

CurrencyFormat.propTypes = {
  label: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
};

CurrencyFormat.defaultProps = {
  wrapperClass: 'col-md-3',
};

export default CurrencyFormat;
