import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SubmitForm = ({
  children,
  isSubmitting,
  submitText,
  onSubmit,
  btnSpan,
  isHaveClearButton,
  onClickClearButton,
  modeModifyPaymentTermsConfig,
  modeModifyPaymentMethodConfig,
  isPaymentType,
  isPaymentTerms,
  isNoNeedPermission,
}) => {
  const { t } = useTranslation('common');
  const isHasPermission =
    isNoNeedPermission ||
    (isPaymentTerms && modeModifyPaymentTermsConfig === 2) ||
    (isPaymentType && modeModifyPaymentMethodConfig === 2);
  return (
    <form onSubmit={isHasPermission ? onSubmit : () => {}}>
      <div className="form-row">
        {children}
        <div className={`form-group text-right btn-search-actions ${btnSpan}`}>
          <label htmlFor="validationCustom01se" />
          <br />
          {isHaveClearButton && (
            <button
              type="button"
              className="button button-border black x-small ml-auto"
              disabled={isSubmitting}
              onClick={onClickClearButton}
            >
              {t('label.clear')}
            </button>
          )}
          {isHasPermission && (
            <button
              type="submit"
              className="button x-small float-right"
              disabled={isSubmitting || modeModifyPaymentTermsConfig === 1 || modeModifyPaymentMethodConfig === 1}
            >
              {submitText}
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

SubmitForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickClearButton: PropTypes.func,
  isSubmitting: PropTypes.bool,
  btnSpan: PropTypes.string,
  submitText: PropTypes.string,
  isHaveClearButton: PropTypes.bool,
  // children: PropTypes.any.isRequired,
  modeModifyPaymentTermsConfig: PropTypes.number,
  modeModifyPaymentMethodConfig: PropTypes.number,
};

SubmitForm.defaultProps = {
  isSubmitting: false,
  isHaveClearButton: false,
  btnSpan: 'col-md-12',
  submitText: 'Apply',
  onClickClearButton: () => {},
  modeModifyPaymentTermsConfig: 0,
  modeModifyPaymentMethodConfig: 0,
};

export default SubmitForm;
