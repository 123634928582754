import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';

export default function FormAddCollectionAction({
  itemNew,
  onChange,
  onAddNew,
  isEnableAddBtn,
  modeCreateCollectionActionConfig,
}) {
  const { t } = useTranslation(['common', 'selections']);
  return (
    <div className="form-group col-md-12 p-5">
      <div className="col-md-12 d-flex">
        <GenericInput
          name="action"
          label="common:label.action"
          value={itemNew.action}
          type="select"
          isClearable
          wrapperClass="col-md-6"
          options={t('selections:collectionAction')()}
          onChange={onChange}
        />
        <GenericInput
          label="common:label.description"
          type="text"
          onChange={onChange}
          name="description"
          value={itemNew.description}
          wrapperClass="col-md-6"
        />
      </div>
      <div className="col-md-12 pt-3 action-buttons">
        <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onAddNew}
          disabled={!isEnableAddBtn || modeCreateCollectionActionConfig === 1}
        >
          {`+ ${t('label.addNewAction')}`}
        </button>
        {/* <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onRemove}
          disabled={isEnableRemove}
        >
          Delete Action
        </button> */}
      </div>
    </div>
  );
}

FormAddCollectionAction.propTypes = {
  isEnableAddBtn: PropTypes.bool,
  onChange: PropTypes.func,
  onAddNew: PropTypes.func,
  itemNew: PropTypes.objectOf(PropTypes.any),
  modeCreateCollectionActionConfig: PropTypes.number,
};

FormAddCollectionAction.defaultProps = {
  isEnableAddBtn: false,
  itemNew: {},
  onChange: () => {},
  onAddNew: () => {},
  modeCreateCollectionActionConfig: 0,
};
