import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, []);
  return gql`
  query {
    getSplitTaxPerLine(input: ${dataSearch}) {
      description
      taxCode
      quantity
      taxableAmount
      ieps
      iva
      appliedStart
      appliedEnd
    }
  }
`;
};
