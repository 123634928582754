import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataProcess = parseToMutationRequest(data, ['formatType']);
  const bulkPaymentProcess = `bulkPaymentProcess(input: ${dataProcess})`;
  return gql`
  mutation {
    ${bulkPaymentProcess}
  }
`;
};
