import constants from './constants';
import { getTypePermission } from '../../utils/utils';

// common permissions - Begin //
const checkPermissionSearchOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchOrder });
};

const checkPermissionCreateOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createOrder });
};

const checkPermissionGetOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getOrder });
};

// common permissions - End //

// Order deatils permissions - Begin //
const checkPermissionUpdateOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.updateOrder });
};

const checkPermissionCancelOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.cancelOrder });
};

const checkPermissionSubmitOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.submitOrder });
};

// Order deatils permissions - End //
const checkPermissionNewOrderType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.newOrderType });
};

const checkPermissionModifyOrderType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyOrderType });
};

const checkPermissionSuspendOrderType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.suspendOrderType });
};

const checkPermissionResumeOrderType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.resumeOrderType });
};

const checkPermissionCancelOrderType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.cancelOrderType });
};

const checkPermissionPackagePurchase = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.packagePurchase });
};

const checkPermissionBundlePurchase = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.bundlePurchase });
};

const checkPermissionAlacartePurchase = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.alacartePurchase });
};

const checkPermissionAddBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addBundle });
};

const checkPermissionAddPackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addPackage });
};

const checkPermissionAddPriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addPriceOffer });
};

const checkPermissionDeleteBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deleteBundle });
};

const checkPermissionDeletePackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deletePackage });
};

const checkPermissionDeletePriceOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deletePriceOffer });
};

const checkPermissionApproveOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.approveOffer });
};

const checkPermissionGetOmsTaskConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getOmsTaskConfig });
};

const checkPermissionModifyOmsTaskConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyOmsTaskConfig });
};

const checkPermissionCreateOmsTaskConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createOmsTaskConfig });
};

const checkPermissionGetProvSequenceTaskConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getProvSequenceTaskConfig });
};

const checkPermissionModifyProvSequenceTaskConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyProvSequenceTaskConfig });
};

const checkPermissionCreateProvSequenceTaskConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createProvSequenceTaskConfig });
};

const checkPermissionGetFlexAttributeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getFlexAttributeConfig });
};

const checkPermissionModifyFlexAttributeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyFlexAttributeConfig });
};

const checkPermissionCreateFlexAttributeConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createFlexAttributeConfig });
};

const checkPermissionCreateBillingAccount = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createBillingAccount });
};

const checkPermissionProcessSplitOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.processSplitOrder });
};

const checkPermissionGetOrderReasonCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getOrderReasonCode });
};

const checkPermissionModifyOrderReasonCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyOrderReasonCode });
};

const checkPermissionCreateOrderReasonCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createOrderReasonCode });
};

const checkPermissionUndoProcessSplitOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.undoProcessSplitOrder });
};

const checkPermissionCreateProvisioningAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.CREATE_PROV_ATTRIBUTE_CONFIG });
};

const checkPermissionModifyProvisioningAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.MODIFY_PROV_ATTRIBUTE_CONFIG });
};

const checkPermissionGetProvisioningAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.GET_PROV_ATTRIBUTE_CONFIG });
};

const checkPermissionMoveProvisioningOrderType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.MOVE_PROVISIONING_ORDER_TYPE });
};

export {
  checkPermissionSearchOrder,
  checkPermissionCreateOrder,
  checkPermissionGetOrder,
  checkPermissionUpdateOrder,
  checkPermissionCancelOrder,
  checkPermissionSubmitOrder,
  checkPermissionNewOrderType,
  checkPermissionModifyOrderType,
  checkPermissionSuspendOrderType,
  checkPermissionResumeOrderType,
  checkPermissionCancelOrderType,
  checkPermissionPackagePurchase,
  checkPermissionBundlePurchase,
  checkPermissionAlacartePurchase,
  checkPermissionAddBundle,
  checkPermissionAddPackage,
  checkPermissionAddPriceOffer,
  checkPermissionDeleteBundle,
  checkPermissionDeletePackage,
  checkPermissionDeletePriceOffer,
  checkPermissionApproveOrder,
  checkPermissionGetOmsTaskConfig,
  checkPermissionModifyOmsTaskConfig,
  checkPermissionCreateOmsTaskConfig,
  checkPermissionGetProvSequenceTaskConfig,
  checkPermissionModifyProvSequenceTaskConfig,
  checkPermissionCreateProvSequenceTaskConfig,
  checkPermissionGetFlexAttributeConfig,
  checkPermissionModifyFlexAttributeConfig,
  checkPermissionCreateFlexAttributeConfig,
  checkPermissionCreateBillingAccount,
  checkPermissionProcessSplitOrder,
  checkPermissionGetOrderReasonCode,
  checkPermissionModifyOrderReasonCode,
  checkPermissionCreateOrderReasonCode,
  checkPermissionUndoProcessSplitOrder,
  checkPermissionCreateProvisioningAttributes,
  checkPermissionModifyProvisioningAttributes,
  checkPermissionGetProvisioningAttributes,
  checkPermissionMoveProvisioningOrderType,
};
