import gql from 'graphql-tag';

export default type => gql`
  query {
    getCustomAttributesByObjectType(input: { objectType: ${type || 'ACCOUNT'} }) {
      id
      objectType
      attributes {
        index
        name
        length
        type
        mandatory
        displayName
        defaultValue
        category
        description
        attributesLOVs {
          index
          attributeId
          attributeValue
        }
      }
    }
  }
`;
