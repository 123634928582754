import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  FormWithTableItem,
  DataTable,
  TablePagination,
  ModalDetailsItem,
  FormCollapse,
  GenericInput,
} from '../../common';
import AROpsUnitSearchForm from '../AROpsUnitSearchForm';
import AROpsUnitNoInvoiceSearchForm from '../AROpsUnitNoInvoiceSearchForm';
import { getPageTotalCount } from '../../../utils/utils';
import constant from '../../../containers/CustomerPage/constants';
import { makeGetPermissionsArOpsManagement } from '../../../containers/App/selectors';

import {
  makeGetArActivity,
  makeArActivityParams,
  makeErrorMessageTU,
} from '../../../containers/CustomerPage/selectors';
import { getArActivity, getArOpsUnitById } from '../../../containers/CustomerPage/actions';

const arActivitySort = {
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  arType: {
    asc: 'arType_ASC',
    desc: 'arType_DESC',
  },
  arCrDrType: {
    asc: 'arCrDrType_ASC',
    desc: 'arCrDrType_DESC',
  },
  source: {
    asc: 'source_ASC',
    desc: 'source_DESC',
  },
  itemId: {
    asc: 'itemId_ASC',
    desc: 'itemId_DESC',
  },
  invoiceUnitId: {
    asc: 'invoiceUnitId_ASC',
    desc: 'invoiceUnitId_ASC',
  },
};

const AROpsUnit = ({
  id,
  getArActivity,
  currencyOptions,
  customerInfo,
  getArOpsUnitById,
  modeSearchArOpsUnit,
  invoiceSelect,
  isComponent,
}) => {
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [isSearchDataModal, setIsSearchDataModal] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [itemData, setItemData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { t } = useTranslation('common');

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const doSearchArOpsUnit = useCallback(() => {
    setLoading(true);
    const newFilter = cloneDeep(filter);

    if (!newFilter.accountId) newFilter.accountId = id;
    if (invoiceSelect) newFilter.invoiceUnitId = invoiceSelect ? invoiceSelect.invoiceUnitId : null;

    getArActivity(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? arActivitySort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if (success && data) setData(data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, id, getArActivity, page, size, sorted]);

  const onHandleSubmit = filter => {
    const newFilter = cloneDeep(filter);
    newFilter.accountId = id;
    setFilter(filter);
  };

  const renderItem = item => {
    switch (item.state) {
      case 'AR Ops Data':
        return (
          <div className="row col-md-12 p-2">
            {constant.dataFieldArOpsDataId.map(val => (
              <GenericInput
                key={val.name}
                value={
                  itemData.arData && itemData.arData[0] && itemData.arData[0][val.name]
                    ? itemData.arData[0][val.name]
                    : null
                }
                disabled
                onChange={() => {}}
                {...val}
              />
            ))}
          </div>
        );
      case 'Tax Data':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableOpsTaxData} data={itemData.taxData ? itemData.taxData : []} />
          </div>
        );
      case 'Currency':
        return (
          <div className="col-md-12">
            <DataTable columns={constant.tableCurrencyArOpsDataId} data={itemData.balances ? itemData.balances : []} />
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    doSearchArOpsUnit();
  }, [doSearchArOpsUnit]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  if (!modeSearchArOpsUnit) return null;

  const doGetArOpsUnitById = ({ item }) => {
    setIsOpenModal(true);
    setIsSearchDataModal(true);
    setActiveTab({});
    getArOpsUnitById(item?.id || null, ({ data, success }) => {
      setIsSearchDataModal(false);
      setItemData(success ? data : {});
    });
  };
  // if (!modeSearchLandingBill) return '';
  const columns = constant.accountArActivities.arActivitiesTable;
  let newCollapsibleMenu = constant.collapsibleMenuArOpsUnitById;

  if (!itemData.arData) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'AR Ops Data');
  }
  if (!itemData.taxData) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Tax Data');
  }
  if (!itemData.balances) {
    newCollapsibleMenu = newCollapsibleMenu.filter(val => val.state !== 'Currency');
  }

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) => (
        <button
          disabled={modeSearchArOpsUnit === 1}
          className="text-success no-border"
          type="button"
          onClick={() => doGetArOpsUnitById({ item })}
        >
          {item.id}
        </button>
      ),
    },
    {
      name: 'id',
      label: 'label.view',
      render: (colName, item) => (
        <button
          disabled={modeSearchArOpsUnit === 1}
          className="btn btn-outline-success btn-sm"
          type="button"
          onClick={() => doGetArOpsUnitById({ item })}
        >
          {t('label.view')}
        </button>
      ),
    },
    ...columns,
  ];
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  const RenderGroupTable = () => (
    <>
      <div className="card-body ml-2">
        {invoiceSelect ? (
          <AROpsUnitNoInvoiceSearchForm onSubmit={onHandleSubmit} />
        ) : (
          <AROpsUnitSearchForm onSubmit={onHandleSubmit} />
        )}
      </div>
      <br />
      <DataTable isLoading={isLoading} onSort={onSortColumn} sorted={sorted} columns={tableColumns} data={data || []} />
    </>
  );

  return (
    <div className="col-md-12 mb-30">
      {!isComponent ? (
        <FormWithTableItem
          title={t('label.arOpsUnits')}
          accountNum={id}
          commercialName={customerInfo?.commercialName || ''}
          isCommercialName={customerInfo?.customerSegment !== 'B2C'}
          currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
          key="pendingBills"
        >
          {RenderGroupTable()}
        </FormWithTableItem>
      ) : (
        RenderGroupTable()
      )}
      <div className={isComponent ? 'mb-30 mt-3 page-size-modal' : 'mb-30 mt-3'}>
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalDetailsItem
        isOpen={isOpenModal}
        data={itemData}
        onToggle={() => setIsOpenModal(false)}
        dataField={constant.dataFieldArOpsUnitById}
        title={t('label.aRActivity')}
        isSearch={isSearchDataModal}
        wrapperClass="modal-custom modal-70"
        isBackBtn
      >
        <div className="pt-4">
          {newCollapsibleMenu.map(item => (
            <FormCollapse
              key={item.title}
              isActive={item.state === activeTab.name && activeTab.isActive}
              title={t(item.title) || ''}
              state={item.state}
              onToggleTab={onToggleTab}
            >
              {renderItem(item)}
            </FormCollapse>
          ))}
        </div>
      </ModalDetailsItem>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  arActivity: makeGetArActivity() || [],
  params: makeArActivityParams() || {},
  errorMessage: makeErrorMessageTU() || '',
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

AROpsUnit.propTypes = {
  id: PropTypes.string,
  getArActivity: PropTypes.func.isRequired,
  getArOpsUnitById: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.any),
  customerInfo: PropTypes.objectOf(PropTypes.any),
  modeSearchArOpsUnit: PropTypes.number,
  invoiceSelect: PropTypes.objectOf(PropTypes.any),
  isComponent: PropTypes.bool,
};

AROpsUnit.defaultProps = {
  id: '',
  currencyOptions: [],
  customerInfo: {},
  modeSearchArOpsUnit: 0,
  invoiceSelect: null,
  isComponent: false,
};

export default connect(mapStateToProps, {
  getArActivity,
  getArOpsUnitById,
})(AROpsUnit);
