import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class SearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.operatorId',
      name: 'operatorId',
    },
    {
      label: 'common:label.operatorName',
      name: 'operatorName',
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      tOptions: 'selections:serviceType',
      type: 'select',
      isClearable: true,
    },
  ];
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SearchForm);
