import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'serviceType',
        'direction',
        'fileId',
        'billUnitStatus',
        'invoiceUnitStatus',
      ])}`
    : '';
  const getUsageTransactionDetailReport = `getUsageTransactionDetailReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getUsageTransactionDetailReport} {
        id
        accountId
        zoneUnit
        impactUnit
        holidayName
        code
        transactionId
        batchid
        usageid
        provisioningId
        usageType
        usageClass
        perfBatchId
        ratingDescr
        source
        destination
        routingNumber
        sourceSystem
        flexAttribute0
        flexAttribute1
        flexAttribute2
        flexAttribute3
        flexAttribute4
        flexAttribute5
        flexAttribute6
        flexAttribute7
        flexAttribute8
        flexAttribute9
        accountType
        accountSubType
        marketSegment
        salesChannel
        fileId
        quantity
        netAmount
        grossAmount
        perUsageAmount
        perUsageQuantity
        rateUnit
        timeUnit
        direction
        serviceType
        startDate
        endDate
        billUnitId
        billUnitStatus
        invoiceUnitId
        invoiceUnitStatus
        scaleUnit
        uploadVolume
        downloadVolume
      }
    }
  `;
};
