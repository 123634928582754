import gql from 'graphql-tag';

export default () => gql`
  query {
    getCustomUsageRecCfg(dummy: "") {
      id
      customRecCfg {
        index
        recordType
        recordNumber
        customRecAttributesCfg {
          index
          name
          type
          length
          mandatory
        }
      }
    }
  }
`;
