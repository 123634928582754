import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'planType', 'status', 'planId', 'serialId', 'min', 'max']);
  const modifyVoicePlan = `modifyVoicePlan(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyVoicePlan} {
      id
    }
  }
`;
};
