import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionGetDelayedBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getDelayedBillingConfig });
};

const checkPermissionModifyDelayedBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyDelayedBillingConfig });
};

const checkPermissionCreateDelayedBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createDelayedBillingConfig });
};

const checkPermissionGetInAdvanceBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getInAdvanceBillingConfig });
};

const checkPermissionModifyInAdvanceBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyInAdvanceBillingConfig });
};

const checkPermissionCreateInAdvanceBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createInAdvanceBillingConfig });
};

const checkPermissionGetPendingBillTriggerConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPendingBillTriggerConfig });
};

const checkPermissionModifyPendingBillTriggerConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPendingBillTriggerConfig });
};

const checkPermissionCreatePendingBillTriggerConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPendingBillTriggerConfig });
};

const checkPermissionGetInvoiceReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getInvoiceReport });
};

const checkPermissionSearchLandingBill = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_LADING_BILL });
};

const checkPermissionSearchSavedManualInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_SAVED_MANUAL_INVOICE });
};

const checkPermissionCreateExpensesConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_EXPENSES_CONFIG });
};

const checkPermissionGetExpensesConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_EXPENSES_CONFIG });
};

const checkPermissionModifyExpensesConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_EXPENSES_CONFIG });
};

const checkPermissionCreateExpenseData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_EXPENSE_DATA });
};

const checkPermissionGetExpenseData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_EXPENSE_DATA });
};

const checkPermissionModifyExpenseData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_EXPENSE_DATA });
};

const checkPermissionCreateEmployeeBonus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_EMPLOYEE_BONUS });
};

const checkPermissionSearchEmployeeBonus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_EMPLOYEE_BONUS });
};

const checkPermissionModifyEmployeeBonus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_EMPLOYEE_BONUS });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeGetDelayedBilling = 0;
  let modeCreateDelayedBilling = 0;
  let modeModifyDelayedBilling = 0;

  let modeGetInAdvanceBilling = 0;
  let modeCreateInAdvanceBilling = 0;
  let modeModifyInAdvanceBilling = 0;

  let modeGetPendingBillTrigger = 0;
  let modeCreatePendingBillTrigger = 0;
  let modeModifyPendingBillTrigger = 0;
  let modeMSearchLandingBill = 0;
  let modeSearchSavedManualInvoice = 0;

  let modeCreateExpensesConfig = 0;
  let modeGetExpensesConfig = 0;
  let modeModifyExpensesConfig = 0;

  let modeCreateExpenseData = 0;
  let modeGetExpenseData = 0;
  let modeModifyExpenseData = 0;

  let modeCreateEmployeeBonus = 0;
  let modeSearchEmployeeBonus = 0;
  let modeModifyEmployeeBonus = 0;

  modeGetDelayedBilling = checkPermissionGetDelayedBillingConfig({ listPermission });
  modeCreateDelayedBilling = checkPermissionCreateDelayedBillingConfig({ listPermission });
  modeModifyDelayedBilling = checkPermissionModifyDelayedBillingConfig({ listPermission });

  modeGetInAdvanceBilling = checkPermissionGetInAdvanceBillingConfig({ listPermission });
  modeCreateInAdvanceBilling = checkPermissionCreateInAdvanceBillingConfig({ listPermission });
  modeModifyInAdvanceBilling = checkPermissionModifyInAdvanceBillingConfig({ listPermission });

  modeGetPendingBillTrigger = checkPermissionGetPendingBillTriggerConfig({ listPermission });
  modeCreatePendingBillTrigger = checkPermissionCreatePendingBillTriggerConfig({ listPermission });
  modeModifyPendingBillTrigger = checkPermissionModifyPendingBillTriggerConfig({ listPermission });
  modeMSearchLandingBill = checkPermissionSearchLandingBill({ listPermission });
  modeSearchSavedManualInvoice = checkPermissionSearchSavedManualInvoice({ listPermission });
  modeCreateExpensesConfig = checkPermissionCreateExpensesConfig({ listPermission });
  modeGetExpensesConfig = checkPermissionGetExpensesConfig({ listPermission });
  modeModifyExpensesConfig = checkPermissionModifyExpensesConfig({ listPermission });

  modeCreateExpenseData = checkPermissionCreateExpenseData({ listPermission });
  modeGetExpenseData = checkPermissionGetExpenseData({ listPermission });
  modeModifyExpenseData = checkPermissionModifyExpenseData({ listPermission });

  modeCreateEmployeeBonus = checkPermissionCreateEmployeeBonus({ listPermission });
  modeSearchEmployeeBonus = checkPermissionSearchEmployeeBonus({ listPermission });
  modeModifyEmployeeBonus = checkPermissionModifyEmployeeBonus({ listPermission });

  return {
    modeGetDelayedBilling,
    modeCreateDelayedBilling,
    modeModifyDelayedBilling,
    modeGetInAdvanceBilling,
    modeCreateInAdvanceBilling,
    modeModifyInAdvanceBilling,
    modeGetPendingBillTrigger,
    modeCreatePendingBillTrigger,
    modeModifyPendingBillTrigger,
    modeMSearchLandingBill,
    modeSearchSavedManualInvoice,
    modeCreateExpensesConfig,
    modeGetExpensesConfig,
    modeModifyExpensesConfig,
    modeCreateExpenseData,
    modeGetExpenseData,
    modeModifyExpenseData,
    modeCreateEmployeeBonus,
    modeSearchEmployeeBonus,
    modeModifyEmployeeBonus,
  };
};

export {
  checkPermissionGetDelayedBillingConfig,
  checkPermissionCreateDelayedBillingConfig,
  checkPermissionModifyDelayedBillingConfig,
  checkPermissionForAllPages,
  checkPermissionGetInAdvanceBillingConfig,
  checkPermissionCreateInAdvanceBillingConfig,
  checkPermissionModifyInAdvanceBillingConfig,
  checkPermissionGetPendingBillTriggerConfig,
  checkPermissionModifyPendingBillTriggerConfig,
  checkPermissionCreatePendingBillTriggerConfig,
  checkPermissionGetInvoiceReport,
  checkPermissionSearchLandingBill,
  checkPermissionSearchSavedManualInvoice,
  checkPermissionCreateExpensesConfig,
  checkPermissionGetExpensesConfig,
  checkPermissionModifyExpensesConfig,
  checkPermissionCreateExpenseData,
  checkPermissionGetExpenseData,
  checkPermissionModifyExpenseData,
  checkPermissionCreateEmployeeBonus,
  checkPermissionSearchEmployeeBonus,
  checkPermissionModifyEmployeeBonus,
};
