import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import moment from 'moment';
import SearchComponent from '../PricingHub/SearchComponent';

class ActivitiesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
      // defaultValue: moment()
      //   .startOf('month')
      //   .format('YYYY-MM-DD'),
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
      // defaultValue: moment()
      //   .endOf('month')
      //   .format('YYYY-MM-DD'),
    },
    {
      label: 'common:label.source',
      name: 'source',
      type: 'select',
      tOptions: 'selections:activitySource',
      isClearable: true,
    },
    {
      label: 'common:label.entityType',
      name: 'entityType',
      type: 'select',
      tOptions: 'selections:entityType',
      isClearable: true,
    },
    {
      label: 'common:label.action',
      name: 'action',
      type: 'select',
      tOptions: 'selections:activityAction',
      isClearable: true,
    },
    {
      label: 'common:label.gatewayType',
      name: 'gatewayType',
      type: 'select',
      tOptions: 'selections:gatewayType',
      isClearable: true,
    },
    {
      label: 'common:label.gatewayMerchant',
      name: 'merchantName',
      type: 'select',
      tOptions: 'selections:merchantName',
      isClearable: true,
    },
    {
      label: 'common:label.apiName',
      name: 'apiName',
      type: 'select',
      options: [],
      isClearable: true,
      isCreateOption: true,
    },
    {
      label: 'common:label.userId',
      name: 'userId',
      fa: 'fa fa-external-link',
      exclusionField: 'myActivity',
    },
    {
      label: 'common:label.myActivity',
      name: 'myActivity',
      type: 'checkbox',
      wrapperClass: 'form-group col-md-3 mt-4 pt-2',
      exclusionField: 'userId',
    },
  ];

  btnSpan = 'col-md-6';
}

ActivitiesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ActivitiesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ActivitiesSearchForm);
