import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataFilter = parseToMutationRequest(data, []);
  const getPaymentActivity = `getPaymentActivity( input: ${dataFilter} )`;
  return gql`
    query {
      ${getPaymentActivity} {
        organization
        accountId
        invoiceId
        allocationDate
        sourceCurrency
        accountCurrency
        sourceCurrencyAmount
        exchangeRate
        allocatedAmount
        transactionType
        invoiceDue
        folioStatus
        status
        paymentId
        paymentSuspenseId
      }
    }
  `;
};
