import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { columns } from './commonFields';
import { getLabelOptions } from '../utils';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 7,
    columns: [],
    data: [[{ value: title || 'USAGE DETAIL', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.batchId,
      columns.fileName,
      columns.fileDate,
      columns.source,
      columns.startDate,
      columns.endDate,
      columns.provisioningId,
      columns.quantityUnit,
      // columns.productLine,
      columns.usageId,
      columns.usageClass,
      columns.recordIndicator,
      columns.grossValue,
      columns.netValue,
      columns.itemId,
      columns.status,
      columns.errorCode,
    ],
    data: [
      [
        {
          value: t('label.batchId'),
          style: styleHeader,
        },
        {
          value: t('label.fileName'),
          style: styleHeader,
        },
        {
          value: t('label.fileDate'),
          style: styleHeader,
        },
        {
          value: t('label.source'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.provisioningId'),
          style: styleHeader,
        },
        {
          value: t('label.quantityUnit'),
          style: styleHeader,
        },
        // {
        //   value: t('label.productLine'),
        //   style: styleHeader,
        // },
        {
          value: t('label.usageId'),
          style: styleHeader,
        },
        {
          value: t('label.usageClass'),
          style: styleHeader,
        },
        {
          value: t('label.recordIndicator'),
          style: styleHeader,
        },
        {
          value: t('label.grossValue'),
          style: styleHeader,
        },
        {
          value: t('label.netValue'),
          style: styleHeader,
        },
        {
          value: t('label.itemId'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
        {
          value: t('label.errorCode'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.batchId ? item.batchId : '' });
      newItem.push({ value: item.fileName ? item.fileName : '' });
      newItem.push({ value: item.fileDate ? item.fileDate : '' });
      newItem.push({ value: item.source ? item.source : '' });
      newItem.push({ value: item.startDate ? item.startDate : '' });
      newItem.push({ value: item.endDate ? item.endDate : '' });
      newItem.push({ value: item.provisioningId ? item.provisioningId : '' });
      newItem.push({ value: item.quantityUnit ? item.quantityUnit : '' });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'productLine', fieldOption: 'serviceType' }) });
      newItem.push({ value: item.usageId ? item.usageId : '' });
      newItem.push({ value: item.usageClass ? item.usageClass : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'recordIndicator', fieldOption: 'usageRecIndicator' }),
      });
      newItem.push({ value: item.grossValue ? item.grossValue : '' });
      newItem.push({ value: item.netValue ? item.netValue : '' });
      newItem.push({ value: item.itemId ? item.itemId : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'usageRecStatus' }) });
      newItem.push({ value: item.errorCode ? item.errorCode : '' });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
