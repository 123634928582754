/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class ModalJobStats extends React.Component {
  static defaultProps = {
    stats: {},
  };

  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { openModal, toggleModal, stats, toggleModalDetail, t } = this.props;
    return (
      <Modal
        isOpen={openModal}
        toggle={() => toggleModal()}
        size="lg"
        className="modal-custom modal-custom--error-detail modal-70 bd-example-modal-lg"
      >
        <ModalHeader toggle={() => toggleModal()}>{t('label.jobStats')}</ModalHeader>

        <ModalBody>
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <div className="card-body card-table-body">
                <table className="table center-aligned-table mb-0">
                  <tbody>
                    <tr>
                      <th>{t('label.totalRecord')}</th>
                      <td>{stats.count || 0}</td>
                    </tr>
                    <tr>
                      <th>{t('label.sucessfulRecords')}</th>
                      <td>{stats.countSuccess || 0}</td>
                    </tr>
                    <tr>
                      <th>{t('label.errorRecords')}</th>
                      <td>
                        <span>{stats.countError || 0}</span>
                        {stats.countError > 0 && (
                          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                          <i
                            className="fa fa-info-circle ico-info ml-1"
                            onClick={toggleModalDetail}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ModalJobStats.propTypes = {
  openModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  stats: PropTypes.object,
  toggleModalDetail: PropTypes.func,
};

export default withTranslation('common')(ModalJobStats);
