const symbolList = [
  {
    symbol: '$',
    name: 'US Dollar',
    number: '840',
    code: 'USD',
  },
  {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    number: '124',
    code: 'CAD',
  },
  {
    symbol: '€',
    name: 'Euro',
    code: 'EUR',
    number: '978',
  },
  {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    code: 'AED',
    number: '784',
  },
  {
    symbol: 'Af',
    name: 'Afghan Afghani',
    code: 'AFN',
    number: '971',
  },
  {
    symbol: 'ALL',
    name: 'Albanian Lek',
    code: 'ALL',
    number: '008',
  },
  {
    symbol: 'AMD',
    name: 'Armenian Dram',
    code: 'AMD',
    number: '051',
  },
  {
    symbol: 'AR$',
    name: 'Argentine Peso',
    code: 'ARS',
    number: '032',
  },
  {
    symbol: 'AU$',
    name: 'Australian Dollar',
    code: 'AUD',
    number: '036',
  },
  {
    symbol: 'man.',
    name: 'Azerbaijani Manat',
    code: 'AZN',
    number: '944',
  },
  {
    symbol: 'KM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    code: 'BAM',
    number: '977',
  },
  {
    symbol: 'Tk',
    name: 'Bangladeshi Taka',
    code: 'BDT',
    number: '050',
  },
  {
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    code: 'BGN',
    number: '975',
  },
  {
    symbol: 'BD',
    name: 'Bahraini Dinar',
    code: 'BHD',
    number: '048',
  },
  {
    symbol: 'FBu',
    name: 'Burundian Franc',
    code: 'BIF',
    number: '108',
  },
  {
    symbol: 'BN$',
    name: 'Brunei Dollar',
    code: 'BND',
    number: '096',
  },
  {
    symbol: 'Bs',
    name: 'Bolivian Boliviano',
    code: 'BOB',
    number: '068',
  },
  {
    symbol: 'R$',
    name: 'Brazilian Real',
    code: 'BRL',
    number: '986',
  },
  {
    symbol: 'BWP',
    name: 'Botswanan Pula',
    code: 'BWP',
    number: '072',
  },
  {
    symbol: 'BYR',
    name: 'Belarusian Ruble',
    code: 'BYR',
    number: '933',
  },
  {
    symbol: 'BZ$',
    name: 'Belize Dollar',
    code: 'BZD',
    number: '084',
  },
  {
    symbol: 'CDF',
    name: 'Congolese Franc',
    code: 'CDF',
    number: '976',
  },
  {
    symbol: 'CHF',
    name: 'Swiss Franc',
    code: 'CHF',
    number: '756',
  },
  {
    symbol: 'CL$',
    name: 'Chilean Peso',
    code: 'CLP',
    number: '152',
  },
  {
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    code: 'CNY',
    number: '156',
  },
  {
    symbol: 'CO$',
    name: 'Colombian Peso',
    code: 'COP',
    number: '170',
  },
  {
    symbol: '₡',
    name: 'Costa Rican Colón',
    code: 'CRC',
    number: '188',
  },
  {
    symbol: 'CV$',
    name: 'Cape Verdean Escudo',
    code: 'CVE',
    number: '132',
  },
  {
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    code: 'CZK',
    number: '203',
  },
  {
    symbol: 'Fdj',
    name: 'Djiboutian Franc',
    code: 'DJF',
    number: '262',
  },
  {
    symbol: 'Dkr',
    name: 'Danish Krone',
    code: 'DKK',
    number: '208',
  },
  {
    symbol: 'RD$',
    name: 'Dominican Peso',
    code: 'DOP',
    number: '214',
  },
  {
    symbol: 'DA',
    name: 'Algerian Dinar',
    code: 'DZD',
    number: '012',
  },
  {
    symbol: 'Ekr',
    name: 'Estonian Kroon',
    code: 'EEK',
    number: '233',
  },
  {
    symbol: 'EGP',
    name: 'Egyptian Pound',
    code: 'EGP',
    number: '818',
  },
  {
    symbol: 'Nfk',
    name: 'Eritrean Nakfa',
    code: 'ERN',
    number: '232',
  },
  {
    symbol: 'Br',
    name: 'Ethiopian Birr',
    code: 'ETB',
    number: '230',
  },
  {
    symbol: '£',
    name: 'British Pound Sterling',
    code: 'GBP',
    number: '826',
  },
  {
    symbol: 'GEL',
    name: 'Georgian Lari',
    code: 'GEL',
    number: '981',
  },
  {
    symbol: 'GH₵',
    name: 'Ghanaian Cedi',
    code: 'GHS',
    number: '936',
  },
  {
    symbol: 'FG',
    name: 'Guinean Franc',
    code: 'GNF',
    number: '324',
  },
  {
    symbol: 'GTQ',
    name: 'Guatemalan Quetzal',
    code: 'GTQ',
    number: '320',
  },
  {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    code: 'HKD',
    number: '344',
  },
  {
    symbol: 'HNL',
    name: 'Honduran Lempira',
    code: 'HNL',
    number: '340',
  },
  {
    symbol: 'kn',
    name: 'Croatian Kuna',
    code: 'HRK',
    number: '191',
  },
  {
    symbol: 'Ft',
    name: 'Hungarian Forint',
    code: 'HUF',
    number: '348',
  },
  {
    symbol: 'Rp',
    name: 'Indonesian Rupiah',
    code: 'IDR',
    number: '360',
  },
  {
    symbol: '₪',
    name: 'Israeli New Sheqel',
    code: 'ILS',
    number: '376',
  },
  {
    symbol: 'Rs',
    name: 'Indian Rupee',
    code: 'INR',
    number: '356',
  },
  {
    symbol: 'IQD',
    name: 'Iraqi Dinar',
    code: 'IQD',
    number: '368',
  },
  {
    symbol: 'IRR',
    name: 'Iranian Rial',
    code: 'IRR',
    number: '364',
  },
  {
    symbol: 'Ikr',
    name: 'Icelandic Króna',
    code: 'ISK',
    number: '352',
  },
  {
    symbol: 'J$',
    name: 'Jamaican Dollar',
    code: 'JMD',
    number: '388',
  },
  {
    symbol: 'JD',
    name: 'Jordanian Dinar',
    code: 'JOD',
    number: '400',
  },
  {
    symbol: '¥',
    name: 'Japanese Yen',
    code: 'JPY',
    number: '392',
  },
  {
    symbol: 'Ksh',
    name: 'Kenyan Shilling',
    code: 'KES',
    number: '404',
  },
  {
    symbol: 'KHR',
    name: 'Cambodian Riel',
    code: 'KHR',
    number: '116',
  },
  {
    symbol: 'CF',
    name: 'Comorian Franc',
    code: 'KMF',
    number: '174',
  },
  {
    symbol: '₩',
    name: 'South Korean Won',
    code: 'KRW',
    number: '410',
  },
  {
    symbol: 'KD',
    name: 'Kuwaiti Dinar',
    code: 'KWD',
    number: '414',
  },
  {
    symbol: 'KZT',
    name: 'Kazakhstani Tenge',
    code: 'KZT',
    number: '398',
  },
  {
    symbol: 'LB£',
    name: 'Lebanese Pound',
    code: 'LBP',
    number: '422',
  },
  {
    symbol: 'SLRs',
    name: 'Sri Lankan Rupee',
    code: 'LKR',
    number: '144',
  },
  {
    symbol: 'Lt',
    name: 'Lithuanian Litas',
    code: 'LTL',
    number: '440',
  },
  {
    symbol: 'Ls',
    name: 'Latvian Lats',
    code: 'LVL',
    number: '428',
  },
  {
    symbol: 'LD',
    name: 'Libyan Dinar',
    code: 'LYD',
    number: '434',
  },
  {
    symbol: 'MAD',
    name: 'Moroccan Dirham',
    code: 'MAD',
    number: '504',
  },
  {
    symbol: 'MDL',
    name: 'Moldovan Leu',
    code: 'MDL',
    number: '498',
  },
  {
    symbol: 'MGA',
    name: 'Malagasy Ariary',
    code: 'MGA',
    number: '969',
  },
  {
    symbol: 'MKD',
    name: 'Macedonian Denar',
    code: 'MKD',
    number: '807',
  },
  {
    symbol: 'MMK',
    name: 'Myanma Kyat',
    code: 'MMK',
    number: '104',
  },
  {
    symbol: 'MOP$',
    name: 'Macanese Pataca',
    code: 'MOP',
    number: '446',
  },
  {
    symbol: 'MURs',
    name: 'Mauritian Rupee',
    code: 'MUR',
    number: '480',
  },
  {
    symbol: 'MX$',
    name: 'Mexican Peso',
    code: 'MXN',
    number: '484',
  },
  {
    symbol: 'RM',
    name: 'Malaysian Ringgit',
    code: 'MYR',
    number: '458',
  },
  {
    symbol: 'MTn',
    name: 'Mozambican Metical',
    code: 'MZN',
    number: '943',
  },
  {
    symbol: 'N$',
    name: 'Namibian Dollar',
    code: 'NAD',
    number: '516',
  },
  {
    symbol: '₦',
    name: 'Nigerian Naira',
    code: 'NGN',
    number: '566',
  },
  {
    symbol: 'C$',
    name: 'Nicaraguan Córdoba',
    code: 'NIO',
    number: '558',
  },
  {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    code: 'NOK',
    number: '578',
  },
  {
    symbol: 'NPRs',
    name: 'Nepalese Rupee',
    code: 'NPR',
    number: '524',
  },
  {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    code: 'NZD',
    number: '554',
  },
  {
    symbol: 'OMR',
    name: 'Omani Rial',
    code: 'OMR',
    number: '512',
  },
  {
    symbol: 'B/.',
    name: 'Panamanian Balboa',
    code: 'PAB',
    number: '590',
  },
  {
    symbol: 'S/.',
    name: 'Peruvian Nuevo Sol',
    code: 'PEN',
    number: '604',
  },
  {
    symbol: '₱',
    name: 'Philippine Peso',
    code: 'PHP',
    number: '608',
  },
  {
    symbol: 'PKRs',
    name: 'Pakistani Rupee',
    code: 'PKR',
    number: '586',
  },
  {
    symbol: 'zł',
    name: 'Polish Zloty',
    code: 'PLN',
    number: '985',
  },
  {
    symbol: '₲',
    name: 'Paraguayan Guarani',
    code: 'PYG',
    number: '600',
  },
  {
    symbol: 'QR',
    name: 'Qatari Rial',
    code: 'QAR',
    number: '634',
  },
  {
    symbol: 'RON',
    name: 'Romanian Leu',
    code: 'RON',
    number: '946',
  },
  {
    symbol: 'din.',
    name: 'Serbian Dinar',
    code: 'RSD',
    number: '941',
  },
  {
    symbol: 'RUB',
    name: 'Russian Ruble',
    code: 'RUB',
    number: '643',
  },
  {
    symbol: 'RWF',
    name: 'Rwandan Franc',
    code: 'RWF',
    number: '646',
  },
  {
    symbol: 'SR',
    name: 'Saudi Riyal',
    code: 'SAR',
    number: '682',
  },
  {
    symbol: 'SDG',
    name: 'Sudanese Pound',
    code: 'SDG',
    number: '938',
  },
  {
    symbol: 'Skr',
    name: 'Swedish Krona',
    code: 'SEK',
    number: '752',
  },
  {
    symbol: 'S$',
    name: 'Singapore Dollar',
    code: 'SGD',
    number: '702',
  },
  {
    symbol: 'Ssh',
    name: 'Somali Shilling',
    code: 'SOS',
    number: '706',
  },
  {
    symbol: 'SY£',
    name: 'Syrian Pound',
    code: 'SYP',
    number: '760',
  },
  {
    symbol: '฿',
    name: 'Thai Baht',
    code: 'THB',
    number: '764',
  },
  {
    symbol: 'DT',
    name: 'Tunisian Dinar',
    code: 'TND',
    number: '788',
  },
  {
    symbol: 'T$',
    name: 'Tongan Paʻanga',
    code: 'TOP',
    number: '776',
  },
  {
    symbol: 'TL',
    name: 'Turkish Lira',
    code: 'TRY',
    number: '949',
  },
  {
    symbol: 'TT$',
    name: 'Trinidad and Tobago Dollar',
    code: 'TTD',
    number: '780',
  },
  {
    symbol: 'NT$',
    name: 'New Taiwan Dollar',
    code: 'TWD',
    number: '901',
  },
  {
    symbol: 'TSh',
    name: 'Tanzanian Shilling',
    code: 'TZS',
    number: '834',
  },
  {
    symbol: '₴',
    name: 'Ukrainian Hryvnia',
    code: 'UAH',
    number: '980',
  },
  {
    symbol: 'USh',
    name: 'Ugandan Shilling',
    code: 'UGX',
    number: '800',
  },
  {
    symbol: '$U',
    name: 'Uruguayan Peso',
    code: 'UYU',
    number: '858',
  },
  {
    symbol: 'UZS',
    name: 'Uzbekistan Som',
    code: 'UZS',
    number: '860',
  },
  {
    symbol: 'Bs.F.',
    name: 'Venezuelan Bolívar',
    code: 'VEF',
    number: '937',
  },
  {
    symbol: '₫',
    name: 'Vietnamese Dong',
    code: 'VND',
    number: '704',
  },
  {
    symbol: 'FCFA',
    name: 'CFA Franc BEAC',
    code: 'XAF',
    number: '950',
  },
  {
    symbol: 'CFA',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
    number: '952',
  },
  {
    symbol: 'YR',
    name: 'Yemeni Rial',
    code: 'YER',
    number: '886',
  },
  {
    symbol: 'R',
    name: 'South African Rand',
    code: 'ZAR',
    number: '710',
  },
  {
    symbol: 'ZK',
    name: 'Zambian Kwacha',
    code: 'ZMK',
    number: '894',
  },
];

const convertSymbol2OptionName = symbolList.map(item => ({
  label: `${item.name} (${item.symbol})`,
  value: item.symbol,
}));

const convertSymbol2OptionSymbol = symbolList.map(item => ({ label: item.symbol, value: item.symbol }));

const convertSymbol2OptionCurrency = listCurrencyExist =>
  symbolList.map(item => ({
    label: `${item.name} (${item.code})`,
    value: item.code,
    isDisabled: listCurrencyExist.map(item => item.currencyId).indexOf(item.code) > -1,
  }));

const findIndexCurrency = code =>
  symbolList.filter(item => item.code === code)[0]
    ? parseInt(symbolList.filter(item => item.code === code)[0].number, 10)
    : '';

const findSymbolCurrency = code =>
  symbolList.filter(item => item.code === code)[0] ? symbolList.filter(item => item.code === code)[0].symbol : '';

export {
  symbolList,
  convertSymbol2OptionSymbol,
  convertSymbol2OptionName,
  convertSymbol2OptionCurrency,
  findIndexCurrency,
  findSymbolCurrency,
};
