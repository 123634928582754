import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'serviceType',
    'quantity',
    'unit',
    'uploadVolume',
    'downloadVolume',
    'accumulatorAmount',
  ]);
  const createAndProcessUsageRecord = `createAndProcessUsageRecord(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createAndProcessUsageRecord} {
      provisioningId
      ratingResult
    }
  }
`;
};
