import gql from 'graphql-tag';

export default () => {
  const getPaymentItemMap = 'getPaymentItemMap( dummy: "")';
  return gql`
    query {
      ${getPaymentItemMap} {
        id
        name
        paymentItemMaps {
          index
          fileName
          itemId
          paymentType
        }
      }
    }
  `;
};
