import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TitleFrom from '../common/TitleFrom';
import { GenericInput } from '../common';
import RouteNames from '../../containers/App/RouteNames';
import { supportShowNumberValue } from '../../utils/utils';

const RevertForm = ({ title, data, onChange, onHandleSubmit, linkTo, onCancel }) => {
  const { t } = useTranslation('common');
  const isDisableAmount = !isEmpty(data) && data.disputeAmount === 0 && data.disputePercent !== 0;
  return (
    <div className="card card-statistics form-with-item">
      <div className="d-block d-md-flex justify-content-between">
        <div className="d-block d-md-flex clearfix sm-mt-20">
          <TitleFrom title={title} />
        </div>
      </div>
      <div className="form-group col-md-12 p-2">
        <form className="col-md-12 d-flex group-flex" onSubmit={onHandleSubmit}>
          <GenericInput
            wrapperClass="col-md-6"
            label={t('label.amount')}
            name="amount"
            value={data.amount}
            type="number"
            // decimalSeparator="."
            // thousandSeparator=","
            // prefix="$"
            disabled={isDisableAmount || data.percent}
            onChange={onChange}
          />
          {/* <GenericInput
            label={t('label.writeOffAmount')}
            value={data.disputeAmount || ''}
            name="disputeAmount"
            disabled
            onChange={onChange}
            wrapperClass="col-md-6"
          /> */}
          <GenericInput
            wrapperClass="col-md-6"
            label={t('label.percent')}
            type="number"
            name="percent"
            value={supportShowNumberValue(data.percent)}
            disabled={data.amount}
            onChange={onChange}
          />
          {/* <GenericInput
            label={t('label.writeOffPercent')}
            value={data.disputePercent || ''}
            name="disputePercent"
            disabled
            onChange={onChange}
            wrapperClass="col-md-6"
          /> */}
          <GenericInput
            label={t('label.notes')}
            value={data.notes}
            name="notes"
            type="textarea"
            onChange={onChange}
            wrapperClass="col-md-12"
          />
          <div className="col-md-12 mt-3">
            <button type="submit" className="button button-border x-small float-right">
              {t('label.apply')}
            </button>
            {onCancel ? (
              <button type="button" onClick={onCancel} className="button button-border black x-small mr-2 float-right">
                {t('label.cancel')}
              </button>
            ) : (
              <Link
                to={linkTo || RouteNames.arOperationsDisputes.path}
                className="button button-border black x-small mr-2 float-right"
              >
                {t('label.cancel')}
              </Link>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

RevertForm.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  linkTo: PropTypes.string,
  onHandleSubmit: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
};

RevertForm.defaultProps = {
  title: '',
  data: {},
  onChange: () => {},
  linkTo: undefined,
  onCancel: () => {},
  onHandleSubmit: () => {},
};

export default RevertForm;
