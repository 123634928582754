import React from 'react';
import PropTypes from 'prop-types';
import { GenericInput } from '../../common';

const OneOffPaymentApplyForm = ({ onChangePaymentMethod, paymentType, onToggleModal, accountId }) => {
  return (
    <div className="form-group col-md-12 ml-3">
      <div className="col-md-12 d-flex">
        <GenericInput
          tOptions="selections:paymentMethodAdmin"
          type="select"
          name="paymentType"
          label="label.paymentMethod"
          value={paymentType}
          onChange={onChangePaymentMethod}
        />
        <GenericInput
          name="accountId"
          label="label.accountId"
          value={accountId}
          onClick={onToggleModal}
          fa="fa fa-external-link"
          readOnly
        />
      </div>
    </div>
  );
};

OneOffPaymentApplyForm.propTypes = {
  onChangePaymentMethod: PropTypes.func,
  paymentType: PropTypes.string,
  accountId: PropTypes.string,
  onToggleModal: PropTypes.func.isRequired,
};

OneOffPaymentApplyForm.defaultProps = {
  onChangePaymentMethod: () => {},
  paymentType: '',
  accountId: '',
};
export default OneOffPaymentApplyForm;
