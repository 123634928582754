import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class ReratingSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDateMoreThanAndEqual',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDateLessThan',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:reratingBatchStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

ReratingSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ReratingSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ReratingSearchForm);
