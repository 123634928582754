import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['paymentMethod', 'accountType']);
  const moveAccount = `moveAccount(input: ${dataModify})`;
  return gql`
  mutation {
    ${moveAccount} {
      id
    }
  }
`;
};
