import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'type', 'dom', 'usageFlag', 'monthOffset']);
  const modifyConfigPendingBillingTrigger = `modifyConfigPendingBillingTrigger(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigPendingBillingTrigger} {
      id
    }
  }
`;
};
