import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../components/PricingHub/SearchComponent';

class MergeSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'label.commercialName',
      name: 'commercialName',
    },
  ];

  btnSpan = 'col-md-6';
}

MergeSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

MergeSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(MergeSearchForm);
