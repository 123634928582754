import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getRegR00506Report = `getRegR00506Report(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRegR00506Report} {
        C601
        C602
        C104
        R0050601
        R0050602
      }
    }
  `;
};
