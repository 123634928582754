import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class SearchOrderList extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.orderId',
      name: 'orderId',
    },
    {
      label: 'common:label.type',
      name: 'type',
      isClearable: true,
      tOptions: 'selections:types',
      type: 'select',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusOrder',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.crmId',
      name: 'crmId',
    },
    {
      label: 'common:label.provisioningOrderId',
      name: 'provisioningId',
    },
    {
      label: 'common:label.accountType',
      name: 'accountType',
      tOptions: 'selections:accountType',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.clientId',
      name: 'clientId',
    },
    {
      label: 'common:label.effectiveStartDate',
      name: 'effectiveStartDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.effectiveEndDate',
      name: 'effectiveEndDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.submittedDate',
      name: 'submittedDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.externalId',
      name: 'externalId',
    },
  ];

  btnSpan = 'col-md-3';
}

SearchOrderList.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SearchOrderList.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(SearchOrderList);
