export default {
  // customer
  CUSTOMER_ONBOARDING: '#189F00',
  CUSTOMER_CHURN: '#879C02',

  // Subscription
  TRIAL_TO_PAID_CONVERSION: '#B5381C',
  ACTIVE_SUBSCRIPTIONS: '#800F00',
  SUBSCRIPTION_CANCELLATIONS: '#1E0CD5',

  // Billing
  BILLED_CUSTOMER: '#006E9D',
  BILLED_REVENUE: '#566F00',

  // Payments
  PAYMENT_SUCCESS: '#008893',
  PAYMENT_SUSPENSE: '#002CC2',
  PAYMENT_FAILED: '#73D953',
  ACCOUNTS_WITH_FAILED_PAYMENT: '#005CBF',

  // Collection
  COLLECTION_0_30_DAYS: '#440070',
  COLLECTION_30_60_DAYS: '#007785',
  COLLECTION_60_90_DAYS: '#2DCC14',
  COLLECTION_OVER_90_DAYS: '#F28110',

  // Revenue
  TOTAL_REVENUE: '#390CD2',
  RECOGNIZED_REVENUE: '#00B8E5',
  REVENUE_FORECAST: '#B40255',
  MONTHLY_RECURRING_REVENUE: '#690BB8',

  // Orders
  FAILED_ORDER: '#948200',
  SUBMITTED_ORDER: '#9E0071',

  // Jobs
  JOBS_FAILED: '#BDBB14',
  BILLS_FAILED: '#E4E807',
  INVOICES_FAILED: '#00C6A5',
  TOTAL_JOBS: '#9DD600',

  // AR Ops
  TOTAL_CREDIT_ADJUSTMENTS: '#376700',
  TOTAL_DISPUTES: '#448482',
  TOTAL_SETTLEMENTS: '#7E4500',
  TOTAL_DEBIT_ADJUSTMENTS: '#088400',

  // Usage
  USAGE_TRANSACTION: '#221EFF',
  RATED_USAGE_TRANSACTION: '#F96B05',
};
