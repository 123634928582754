import gql from 'graphql-tag';

export default () => {
  const getOrderReasonCodes = 'getOrderReasonCodes(dummy: "")';
  return gql`
    query {
      ${getOrderReasonCodes} {
        id
        name
        createdDate
        orderReasonCodes {
          index
          reasonCode
          description
          orderType
          orderProration
          alignToCycle
          waiveCancel
          endDate
        }
      }
    }
  `;
};
