import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 7,
    columns: [],
    data: [[{ value: title || 'LIST TRIAL SUBSCRIPTION', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.accountId,
      columns.company,
      columns.firstName,
      columns.lastName,
      columns.accountType,
      columns.trialTerm,
      columns.trialTermUnit,
      columns.trialType,
      columns.trialLevel,
      columns.subscriptionId,
      columns.effectiveDate,
      columns.sellingCompany,
      columns.startDate,
      columns.endDate,
      columns.initialTermUnit,
      columns.initialTerm,
      columns.renewalTermUnit,
      columns.renewalTerm,
      columns.status,
    ],
    data: [
      [
        {
          value: t('label.accountId'),
          style: styleHeader,
        },
        {
          value: t('label.organization'),
          style: styleHeader,
        },
        {
          value: t('label.firstName'),
          style: styleHeader,
        },
        {
          value: t('label.lastName'),
          style: styleHeader,
        },
        {
          value: t('label.accountType'),
          style: styleHeader,
        },
        {
          value: t('label.trialTerm'),
          style: styleHeader,
        },
        {
          value: t('label.trialTermUnit'),
          style: styleHeader,
        },
        {
          value: t('label.trialType'),
          style: styleHeader,
        },
        {
          value: t('label.trialLevel'),
          style: styleHeader,
        },
        {
          value: t('label.subscriptionId'),
          style: styleHeader,
        },
        {
          value: t('label.effectiveDate'),
          style: styleHeader,
        },
        {
          value: t('label.sellingCompany'),
          style: styleHeader,
        },
        {
          value: t('label.startDate'),
          style: styleHeader,
        },
        {
          value: t('label.endDate'),
          style: styleHeader,
        },
        {
          value: t('label.initialTermUnit'),
          style: styleHeader,
        },
        {
          value: t('label.initialTerm'),
          style: styleHeader,
        },
        {
          value: t('label.renewalTermUnit'),
          style: styleHeader,
        },
        {
          value: t('label.renewalTerm'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.accountId ? item.accountId : '' });
      newItem.push({ value: item.company ? item.company : '' });
      newItem.push({ value: item.firstName ? item.firstName : '' });
      newItem.push({ value: item.lastName ? item.lastName : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'accountType' }) });
      newItem.push({ value: item.trialTerm ? item.trialTerm : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'trialTermUnit' }) });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'trialType', fieldOption: 'trialTypes' }) });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'trialLevel' }) });
      newItem.push({ value: item.subscriptionId ? item.subscriptionId : '' });
      newItem.push({ value: item.effectiveDate ? item.effectiveDate : '' });
      newItem.push({ value: item.sellingCompany ? item.sellingCompany : '' });
      newItem.push({ value: item.startDate ? item.startDate : '' });
      newItem.push({ value: item.endDate ? item.endDate : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'initialTermUnit' }) });
      newItem.push({ value: item.initialTerm ? item.initialTerm : '' });
      newItem.push({
        value: getLabelOptions({ item, t, fieldName: 'renewalTermUnit', fieldOption: 'initialTermUnit' }),
      });
      newItem.push({ value: item.renewalTerm ? item.renewalTerm : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'subscriptionStatus' }) });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
