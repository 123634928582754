/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectInvoiceId } from '../../../App/actions';
import ModalSelector from '../../../../components/Modals/ModalSelector';
import ModalMultipleSelector from '../../../../components/Modals/ModalMultipleSelector';

import { InvoiceSearchFormAdditionalFilters, InvoiceSearchManualPayment } from '../../../../components/PaymentHub';

class ModalInvoice extends PureComponent {
  searchHanldeInvoiceId = (payload, cb) => {
    const { selectInvoiceId, accountId, isDue } = this.props;
    const newPayload = { ...payload };
    if (newPayload.filter && !newPayload.filter.accountId) {
      newPayload.filter.accountId = accountId || null;
      if (isDue) newPayload.filter.due = '0';
    }
    selectInvoiceId(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'accountId',
          label: 'common:label.accountId',
        },
        {
          name: 'type',
          label: 'common:label.type',
        },
        {
          name: 'folioStatus',
          label: 'common:label.folioStatus',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
        {
          name: 'invoiceType',
          label: 'common:label.invoiceType',
        },
        {
          name: 'invoiceDate',
          label: 'common:label.invoiceDate',
        },
        {
          name: 'dueDate',
          label: 'common:label.dueDate',
        },
        {
          name: 'currency',
          label: 'common:label.currency',
        },
        {
          name: 'total',
          label: 'common:label.total',
        },
        {
          name: 'due',
          label: 'common:label.due',
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      disabledSearchFields,
      children,
      isTopChildren,
      defaultSearchForm,
      isManualPayment,
      isMulitple,
      isSpoortNoClearDisabledField,
    } = this.props;

    return !isMulitple ? (
      <ModalSelector
        modalTitle={t('label.chooseInvoice')}
        isOpen={isOpen}
        SearchForm={props =>
          isManualPayment ? (
            <InvoiceSearchManualPayment
              {...props}
              isSpoortNoClearDisabledField={isSpoortNoClearDisabledField}
              disabledSearchFields={disabledSearchFields}
            />
          ) : (
            <InvoiceSearchFormAdditionalFilters
              {...props}
              disabledSearchFields={disabledSearchFields}
              isSpoortNoClearDisabledField={isSpoortNoClearDisabledField}
            />
          )
        }
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isTopChildren={isTopChildren}
        defaultSearchForm={defaultSearchForm}
      >
        {children}
      </ModalSelector>
    ) : (
      <ModalMultipleSelector
        modalTitle={t('label.chooseInvoice')}
        isOpen={isOpen}
        SearchForm={props =>
          isManualPayment ? (
            <InvoiceSearchManualPayment
              {...props}
              isSpoortNoClearDisabledField={isSpoortNoClearDisabledField}
              disabledSearchFields={disabledSearchFields}
            />
          ) : (
            <InvoiceSearchFormAdditionalFilters
              {...props}
              isSpoortNoClearDisabledField={isSpoortNoClearDisabledField}
              disabledSearchFields={disabledSearchFields}
            />
          )
        }
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isTopChildren={isTopChildren}
        defaultSearchForm={defaultSearchForm}
      >
        {children}
      </ModalMultipleSelector>
    );
  }
}

ModalInvoice.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectInvoiceId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalInvoice.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectInvoiceId })(ModalInvoice));
