import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

class DateGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date ? moment(props.date).toDate() : null,
    };
  }


  handleChange = date => {
    this.setState({date})
    const { onChange} = this.props
    if(onChange){ 
      if(date){
        onChange(moment(date).format('YYYY-MM-DD'))
        return
      }
      onChange('')
    }
  };

  render() {
    const { label, wrapperClass, ...restProps } = this.props;
    return (
      <div className={`form-group ${wrapperClass}`}>

        <DatePicker
          className="form-control"
          {...restProps}
          selected={this.state.date}
          onChange={this.handleChange}
          autoComplete="off"
        />
      </div>
    );
  }
}



DateGroup.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func 
};

DateGroup.defaultProps = {
  label: '',
  placeholder: 'Select Date',
  wrapperClass: 'col-md-12'
};

export default DateGroup;
