import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class NewCustomerSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.accountSubType',
      name: 'accountSubType',
      type: 'select',
      tOptions: 'selections:accountSubType',
      isClearable: true,
    },
    {
      label: 'label.salesChannel',
      name: 'salesChannel',
      tOptions: 'selections:salesChannel',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.marketSegment',
      name: 'marketSegment',
      tOptions: 'selections:marketSegment',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.country',
      name: 'country',
    },
    {
      label: 'label.state',
      name: 'state',
    },
    {
      label: 'label.city',
      name: 'city',
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
  ];

  btnSpan = 'col-md-6';
}

NewCustomerSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

NewCustomerSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(NewCustomerSearchForm);
