import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { makeErrorMessage } from '../selectors';
import { selectItemsId } from '../../App/actions';
import ModalSelector from '../../../components/Modals/ModalSelector';
import { ParentSearchForm } from '../../../components/PricingHub';

class ModalParentItem extends Component {
  state = {};

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'company',
          label: 'common:label.company',
          sortName: 'company',
        },

        {
          name: 'status',
          label: 'common:label.status',
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle="Choose Parent Item"
        isOpen={this.props.isOpen}
        SearchForm={ParentSearchForm}
        searchItems={this.props.selectItemsId}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectedParentId={this.props.selectedParentId}
      />
    );
  }
}

ModalParentItem.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  selectItemsId: PropTypes.func,
  errorMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
});

export default connect(mapStateToProps, { selectItemsId })(ModalParentItem);
