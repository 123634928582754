import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'status',
    'numOfInstallment',
    'daysOffset',
    'monthOffset',
    'index',
    'percent',
  ]);
  const modifyPaymentInstallment = `modifyPaymentInstallment(input: ${dataCreate})`;

  return gql`
  mutation {
    ${modifyPaymentInstallment} {
      id
    }
  }
`;
};
