import React from 'react';
import { formatNumberValue } from '../../utils/utils';

const statusColor = {
  greenColor: ['COMPLETED', 'SKIPPED', 'NOT_REQUIRED'],
  orangeColor: ['PENDING', 'PARTIAL'],
  redColor: ['FAILED', 'CANCELLED'],
};

export default {
  orderDetailInfoField: [
    {
      label: 'common:label.accountId',
      fieldName: 'accountId',
      disabled: true,
    },
    {
      label: 'common:label.type',
      fieldName: 'type',
      tOptions: 'selections:types',
      disabled: true,
    },
    {
      label: 'common:label.status',
      fieldName: 'status',
      tOptions: 'selections:statusOrder',
      disabled: true,
    },
    {
      label: 'common:label.reason',
      fieldName: 'reason',
      disabled: false,
    },
    // {
    //   label: 'common:label.initialTermUnit',
    //   fieldName: 'initialTermUnit',
    //   tOptions: 'selections:initialTermUnit',
    //   disabled: false,
    // },
    // {
    //   label: 'common:label.initialTerm',
    //   fieldName: 'initialTerm',
    //   type: 'number',
    //   disabled: false,
    // },
    {
      label: 'common:label.userId',
      fieldName: 'userId',
      disabled: true,
    },
    // {
    //   label: 'common:label.renewalTermUnit',
    //   fieldName: 'renewalTermUnit',
    //   tOptions: 'selections:initialTermUnit',
    //   disabled: false,
    // },
    // {
    //   label: 'common:label.renewalTerm',
    //   fieldName: 'renewalTerm',
    //   type: 'number',
    //   disabled: false,
    // },

    {
      label: 'common:label.effectiveDate',
      fieldDate: 'effectiveDate',
      disabled: false,
    },
    {
      label: 'common:label.crmId',
      fieldName: 'crmId',
      disabled: false,
    },
    {
      label: 'common:label.provisioningId',
      fieldName: 'provisioningId',
      disabled: false,
    },
    {
      label: 'common:label.submitOrderAdvance',
      fieldName: 'executeFutureOrderNow',
      checkBox: true,
      disabled: false,
    },
    // {
    //   label: 'common:label.trialType',
    //   fieldName: 'trialType',
    //   tOptions: 'selections:trialTypes',
    //   disabled: true,
    // },
    // {
    //   label: 'common:label.trialTermUnit',
    //   fieldName: 'trialTermUnit',
    //   tOptions: 'selections:initialTermUnit',
    //   disabled: true,
    // },
    // {
    //   label: 'common:label.trialTerm',
    //   fieldName: 'trialTerm',
    //   disabled: true,
    // },

    {
      label: 'common:label.submittedDate',
      fieldDate: 'submittedDate',
      disabled: true,
    },
    {
      label: 'common:label.isPartialFulfillmentAllowed',
      fieldName: 'isPartialFulfillmentAllowed',
      checkBox: true,
      disabled: false,
    },
    {
      label: 'common:label.provisioningOnlyFlag',
      fieldName: 'provisioningOnlyFlag',
      disabled: true,
      isBool: true,
      tOptions: 'selections:selectBool',
    },
    {
      label: 'common:label.salesAgentId',
      fieldName: 'salesAgentId',
      disabled: true,
    },
  ],

  orderDetailInfoDisabledField: [
    {
      label: 'common:label.accountId',
      fieldName: 'accountId',
      disabled: true,
    },
    {
      label: 'common:label.type',
      fieldName: 'type',
      disabled: true,
    },
    {
      label: 'common:label.status',
      fieldName: 'status',
      tOptions: 'selections:statusOrder',
      disabled: true,
    },
    {
      label: 'common:label.reason',
      fieldName: 'reason',
      disabled: true,
    },
    // {
    //   label: 'common:label.trialType',
    //   fieldName: 'trialType',
    //   tOptions: 'selections:trialTypes',
    //   disabled: true,
    // },
    // {
    //   label: 'common:label.initialTermUnit',
    //   fieldName: 'initialTermUnit',
    //   tOptions: 'selections:initialTermUnit',
    //   disabled: true,
    // },
    // {
    //   label: 'common:label.initialTerm',
    //   fieldName: 'initialTerm',
    //   type: 'number',
    //   disabled: true,
    // },
    {
      label: 'common:label.userId',
      fieldName: 'userId',
      disabled: true,
    },
    // {
    //   label: 'common:label.renewalTermUnit',
    //   fieldName: 'renewalTermUnit',
    //   tOptions: 'selections:initialTermUnit',
    //   disabled: true,
    // },
    // {
    //   label: 'common:label.renewalTerm',
    //   fieldName: 'renewalTerm',
    //   type: 'number',
    //   disabled: true,
    // },

    {
      label: 'common:label.effectiveDate',
      fieldDate: 'effectiveDate',
      disabled: true,
    },
    {
      label: 'common:label.crmId',
      fieldName: 'crmId',
      disabled: true,
    },
    {
      label: 'common:label.provisioningId',
      fieldName: 'provisioningId',
      disabled: true,
    },
    // {
    //   label: 'common:label.trialTermUnit',
    //   fieldName: 'trialTermUnit',
    //   disabled: true,
    // },
    // {
    //   label: 'common:label.trialTerm',
    //   fieldName: 'trialTerm',
    //   disabled: true,
    // },

    {
      label: 'common:label.submittedDate',
      fieldDate: 'submittedDate',
      disabled: true,
    },
    {
      label: 'common:label.isPartialFulfillmentAllowed',
      fieldName: 'isPartialFulfillmentAllowed',
      checkBox: true,
      disabled: true,
    },
    {
      label: 'common:label.provisioningOnlyFlag',
      fieldName: 'provisioningOnlyFlag',
      disabled: true,
      isBool: true,
      tOptions: 'selections:selectBool',
    },
    {
      label: 'common:label.salesAgentId',
      fieldName: 'salesAgentId',
      disabled: true,
    },
  ],
  newOrderInfoField: [
    {
      label: 'common:label.accountId',
      fieldName: 'accountId',
      disabled: true,
    },
    {
      label: 'common:label.type',
      fieldName: 'type',
      disabled: true,
    },
    {
      label: 'common:label.status',
      fieldName: 'status',
      tOptions: 'selections:statusOrder',
      disabled: false,
    },

    {
      label: 'common:label.initialTermUnit',
      fieldName: 'initialTermUnit',
      tOptions: 'selections:initialTermUnit',
      disabled: false,
    },
    {
      label: 'common:label.initialTerm',
      fieldName: 'initialTerm',
      type: 'number',
      disabled: false,
    },
    {
      label: 'common:label.userId',
      fieldName: 'userId',
      disabled: true,
    },
    {
      label: 'common:label.renewalTermUnit',
      fieldName: 'renewalTermUnit',
      tOptions: 'selections:initialTermUnit',
      disabled: false,
    },
    {
      label: 'common:label.renewalTerm',
      fieldName: 'renewalTerm',
      type: 'number',
      disabled: false,
    },
    {
      label: 'common:label.effectiveDate',
      fieldDate: 'effectiveDate',
      disabled: false,
    },
    {
      label: 'common:label.trialTermUnit',
      fieldName: 'trialTermUnit',
      disabled: true,
    },
    {
      label: 'common:label.trialTerm',
      fieldName: 'trialTerm',
      disabled: true,
    },

    {
      label: 'common:label.isPartialFulfillmentAllowed',
      fieldName: 'isPartialFulfillmentAllowed',
      checkBox: true,
      disabled: false,
    },
  ],
  newTrialSubscription: [
    {
      label: 'common:label.accountId',
      fieldName: 'accountId',
      disabled: true,
    },
    {
      label: 'common:label.type',
      fieldName: 'type',
      disabled: true,
    },
    {
      label: 'common:label.status',
      fieldName: 'status',
      tOptions: 'selections:statusOrder',
      disabled: false,
    },

    {
      label: 'common:label.initialTermUnit',
      fieldName: 'initialTermUnit',
      tOptions: 'selections:initialTermUnit',
      disabled: false,
    },
    {
      label: 'common:label.initialTerm',
      fieldName: 'initialTerm',
      type: 'number',
      disabled: false,
    },
    {
      label: 'common:label.userId',
      fieldName: 'userId',
      disabled: true,
    },
    {
      label: 'common:label.renewalTermUnit',
      fieldName: 'renewalTermUnit',
      tOptions: 'selections:initialTermUnit',
      disabled: false,
    },
    {
      label: 'common:label.renewalTerm',
      fieldName: 'renewalTerm',
      type: 'number',
      disabled: false,
    },
    {
      label: 'common:label.effectiveDate',
      fieldDate: 'effectiveDate',
      disabled: false,
    },
    {
      label: 'common:label.trialType',
      fieldName: 'trialType',
      tOptions: 'selections:trialTypes',
    },
    {
      label: 'common:label.trialTermUnit',
      fieldName: 'trialTermUnit',
      tOptions: 'selections:trialTermUnit',
    },
    {
      label: 'common:label.trialTerm',
      fieldName: 'trialTerm',
      type: 'number',
    },

    {
      label: 'common:label.isPartialFulfillmentAllowed',
      fieldName: 'isPartialFulfillmentAllowed',
      checkBox: true,
      disabled: false,
    },
  ],
  tableSelectBundleColumns: [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'name',
      label: 'common:label.name',
    },
    {
      name: 'description',
      label: 'common:label.description',
    },
  ],
  tableSelectPackageColumns: [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'name',
      label: 'common:label.name',
    },
    {
      name: 'description',
      label: 'common:label.description',
    },
    {
      name: 'salesChannel',
      label: 'label.salesChannel',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'marketSegment',
      label: 'common:label.marketSegment',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'accountType',
      label: 'common:label.accountType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'accountSubType',
      label: 'common:label.accountSubType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'country',
      label: 'common:label.country',
    },
    {
      name: 'currency',
      label: 'common:label.currency',
    },
    {
      name: 'status',
      label: 'common:label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
  ],
  tableSelectPriceOfferColumns: [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'name',
      label: 'common:label.name',
    },
    {
      name: 'description',
      label: 'common:label.description',
    },
  ],
  ModalMode: {
    purchasePackage: 'purchase_package',
    purchaseBundle: 'purchase_bundle',
    purchaseAlaCarte: 'purchase_ala_carte',
    addDiscounts: 'add_discounts',
    none: 'none',
  },

  groupNamePermissions: {
    searchOrder: 'SEARCH_ORDER',
    createOrder: 'CREATE_ORDER',
    getOrder: 'GET_ORDER',
    updateOrder: 'UPDATE_ORDER',
    cancelOrder: 'CANCEL_ORDER',
    submitOrder: 'SUBMIT_ORDER',
    newOrderType: 'NEW_ORDER_TYPE',
    modifyOrderType: 'MODIFY_ORDER_TYPE',
    suspendOrderType: 'SUSPEND_ORDER_TYPE',
    resumeOrderType: 'RESUME_ORDER_TYPE',
    cancelOrderType: 'CANCEL_ORDER_TYPE',
    packagePurchase: 'PACKAGE_PURCHASE',
    bundlePurchase: 'BUNDLE_PURCHASE',
    alacartePurchase: 'ALACARTE_PURCHASE',
    addBundle: 'ADD_BUNDLE',
    addPackage: 'ADD_PACKAGE',
    addPriceOffer: 'ADD_PRICE_OFFER',
    deleteBundle: 'DELETE_BUNDLE',
    deletePackage: 'DELETE_PACKAGE',
    deletePriceOffer: 'DELETE_PRICE_OFFER',
    approveOffer: 'APPROVE_ORDER',
    getOmsTaskConfig: 'GET_OMS_TASK_CONFIG',
    modifyOmsTaskConfig: 'MODIFY_OMS_TASK_CONFIG',
    createOmsTaskConfig: 'CREATE_OMS_TASK_CONFIG',
    createProvSequenceTaskConfig: 'CREATE_PROV_SEQUENCE_CONFIG',
    modifyProvSequenceTaskConfig: 'MODIFY_PROV_SEQUENCE_CONFIG',
    getProvSequenceTaskConfig: 'GET_PROV_SEQUENCE_CONFIG',
    createFlexAttributeConfig: 'CREATE_FLEX_ATTRIBUTE_CONFIG',
    modifyFlexAttributeConfig: 'MODIFY_FLEX_ATTRIBUTE_CONFIG',
    getFlexAttributeConfig: 'GET_FLEX_ATTRIBUTES_CONFIG',
    createBillingAccount: 'CREATE_BILLING_ACCOUNT',
    processSplitOrder: 'PROCESS_SPLIT_ORDER',
    getOrderReasonCode: 'GET_ORDER_REASON_CODES_CONFIG',
    modifyOrderReasonCode: 'MODIFY_ORDER_REASON_CODES_CONFIG',
    createOrderReasonCode: 'CREATE_ORDER_REASON_CODES_CONFIG',
    undoProcessSplitOrder: 'UNDO_PROCESS_SPLIT_ORDER',
    CREATE_PROV_ATTRIBUTE_CONFIG: 'CREATE_PROV_ATTRIBUTE_CONFIG',
    MODIFY_PROV_ATTRIBUTE_CONFIG: 'MODIFY_PROV_ATTRIBUTE_CONFIG',
    GET_PROV_ATTRIBUTE_CONFIG: 'GET_PROV_ATTRIBUTE_CONFIG',
    MOVE_PROVISIONING_ORDER_TYPE: 'MOVE_PROVISIONING_ORDER_TYPE',
  },
};

export const tabelSubscription = [
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'category',
    label: 'label.category',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionCategory')().find(val => val.value === item.category) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTermUnit',
    label: 'label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'label.initialTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'label.renewalTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'label.renewalTerm',
  },
  {
    name: 'trialType',
    label: 'common:label.trialType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialTypes')().find(val => val.value === item.trialType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialStatus',
    label: 'common:label.trialStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialStatus')().find(val => val.value === item.trialStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialTermUnit',
    label: 'label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialTerm',
    label: 'label.trialTerm',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
];

export const serviceUnitTable = [
  {
    name: 'id',
    label: 'common:label.id',
  },
  {
    name: 'type',
    label: 'common:label.type',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.type) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'parentId',
    label: 'common:label.parentId',
  },
  {
    name: 'provisioningId',
    label: 'common:label.provisioningId',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  {
    name: 'bundleName',
    label: 'common:label.bundleName',
  },
  {
    name: 'packageName',
    label: 'common:label.packageName',
  },
];

export const typeFileds = [
  {
    name: 'provAttributeType',
    label: 'common:label.provisioningAttributeType',
    required: true,
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    tOptions: 'selections:serviceType',
    type: 'select',
    menuPortalTarget: true,
  },
  {
    name: 'status',
    label: 'common:label.status',
    tOptions: 'selections:objectStatus',
    type: 'select',
    menuPortalTarget: true,
  },
];

export const collapsibleMenu = [
  {
    title: 'label.orderData',
    state: 'Order Details',
  },
  {
    title: 'customerPage:sidebarHandleAccountPage.customAttributes',
    state: 'Custom Attributes',
  },
  {
    title: 'label.equipments',
    state: 'Equipments',
  },
  {
    title: 'label.infrastructureEquipments',
    state: 'Infrastructure Equipments',
  },
  {
    title: 'label.locations',
    state: 'Locations',
  },
  {
    title: 'label.billableService',
    state: 'Billable Service',
  },
  {
    title: 'label.provisioningData',
    state: 'Provisioning Data',
  },
];

export const customerAttributeColumns = [
  {
    name: 'name',
    label: 'label.attributeName',
  },
  {
    name: 'attributeType',
    label: 'label.attributeType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:variableTypes')().find(val => val.value === item.value) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'length',
    label: 'label.length',
  },
  {
    name: 'mandatory',
    label: 'common:label.mandatory',
  },
];

export const messageNotes = t => <div dangerouslySetInnerHTML={{ __html: t('message.quickNotesBillableService') }} />;
export const messageNotesSplitOrder = t => (
  <div dangerouslySetInnerHTML={{ __html: t('message.quickNotesSplitOrder') }} />
);

export const collapsibleShowTask = [
  {
    title: 'label.omsTasks',
    state: 'omsTasks',
  },
  {
    title: 'label.provisioningTasks',
    state: 'provisioningTasks',
  },
];

export const collapsibleOutInBound = [
  {
    title: 'label.outbound',
    state: 'outbound',
  },
  {
    title: 'label.inbound',
    state: 'inbound',
  },
];

export const taskColumns = [
  {
    name: 'name',
    label: 'common:label.taskName',
    render: (colName, item) => {
      let color = null;
      if (statusColor.greenColor.includes(item.status)) {
        color = '#28a745';
      }
      if (statusColor.redColor.includes(item.status)) {
        color = 'red';
      }
      if (statusColor.orangeColor.includes(item.status)) {
        color = 'orange';
      }
      return <span style={color ? { color } : null}>{item.name}</span>;
    },
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
];

export const taskInboundColumns = [
  {
    name: 'apiName',
    label: 'common:label.taskName',
    render: (colName, item) => {
      let color = null;
      if (statusColor.greenColor.includes(item.status)) {
        color = '#28a745';
      }
      if (statusColor.redColor.includes(item.status)) {
        color = 'red';
      }
      if (statusColor.orangeColor.includes(item.status)) {
        color = 'orange';
      }
      return <span style={color ? { color } : null}>{item.apiName}</span>;
    },
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:provisioningStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'serviceTypes',
    label: 'common:label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const optionsServiceType = t('selections:serviceType')();
      let titleHeaderServiceType = '';
      if (item?.serviceTypes) {
        const listSelect = optionsServiceType.filter(op => item.serviceTypes.split(',').indexOf(op.value) !== -1);
        if (listSelect && listSelect.length) {
          listSelect.forEach(i => {
            titleHeaderServiceType = titleHeaderServiceType ? `${titleHeaderServiceType}, ${i.label}` : i.label;
          });
        }
      }

      return <span>{titleHeaderServiceType || ''}</span>;
    },
  },
];

export const taskOutboundColumns = [
  {
    name: 'apiName',
    label: 'common:label.taskName',
    render: (colName, item) => {
      let color = null;
      if (statusColor.greenColor.includes(item.status)) {
        color = '#28a745';
      }
      if (statusColor.redColor.includes(item.status)) {
        color = 'red';
      }
      if (statusColor.orangeColor.includes(item.status)) {
        color = 'orange';
      }
      return <span style={color ? { color } : null}>{item.apiName}</span>;
    },
  },
  {
    name: 'index',
    label: 'common:label.taskSequence',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:provisioningStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const optionsServiceType = t('selections:serviceType')();
      let titleHeaderServiceType = '';
      if (item?.serviceType) {
        const listSelect = optionsServiceType.filter(op => item.serviceType.split(',').indexOf(op.value) !== -1);
        if (listSelect && listSelect.length) {
          listSelect.forEach(i => {
            titleHeaderServiceType = titleHeaderServiceType ? `${titleHeaderServiceType}, ${i.label}` : i.label;
          });
        }
      }

      return <span>{titleHeaderServiceType || ''}</span>;
    },
  },
  {
    name: 'provisioningSequence',
    label: 'common:label.serviceProvisioningSequence',
  },
];

export const orderDataFields = [
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    label: 'label.type',
    name: 'type',
    tOptions: 'selections:types',
    type: 'select',
    disabled: true,
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:statusOrder',
    type: 'select',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
    disabled: true,
  },
  {
    name: 'referenceOrder',
    label: 'label.referenceOrder',
    fa: 'fa fa-external-link',
  },
];

export const newSubscriptionsInfoField = [
  // {
  //   label: 'common:label.accountId',
  //   name: 'accountId',
  //   disabled: true,
  // },
  // {
  //   label: 'common:label.type',
  //   name: 'type',
  //   disabled: true,
  // },
  // {
  //   label: 'common:label.status',
  //   name: 'status',
  //   tOptions: 'selections:statusOrder',
  //   type: 'select',
  // },

  {
    label: 'common:label.initialTermUnit',
    name: 'initialTermUnit',
    tOptions: 'selections:initialTermUnit',
    type: 'select',
  },
  {
    label: 'common:label.initialTerm',
    name: 'initialTerm',
    type: 'number',
  },
  // {
  //   label: 'common:label.userId',
  //   name: 'userId',
  //   disabled: true,
  // },
  {
    label: 'common:label.renewalTermUnit',
    name: 'renewalTermUnit',
    tOptions: 'selections:initialTermUnit',
    type: 'select',
  },
  {
    label: 'common:label.renewalTerm',
    name: 'renewalTerm',
    type: 'number',
  },
  {
    label: 'common:label.effectiveDate',
    name: 'effectiveDate',
    type: 'date',
  },
  {
    label: 'common:label.trialTermUnit',
    name: 'trialTermUnit',
    disabled: true,
  },
  {
    label: 'common:label.trialTerm',
    name: 'trialTerm',
    disabled: true,
  },
];

export const newSubscriptionsDetailsField = [
  {
    label: 'common:label.initialTermUnit',
    name: 'initialTermUnit',
    tOptions: 'selections:initialTermUnit',
    type: 'select',
  },
  {
    label: 'common:label.initialTerm',
    name: 'initialTerm',
    type: 'number',
  },
  {
    label: 'common:label.renewalTermUnit',
    name: 'renewalTermUnit',
    tOptions: 'selections:initialTermUnit',
    type: 'select',
  },
  {
    label: 'common:label.renewalTerm',
    name: 'renewalTerm',
    type: 'number',
  },
  {
    label: 'common:label.trialType',
    name: 'trialType',
    tOptions: 'selections:trialTypes',
    type: 'select',
    disabled: true,
  },
  {
    label: 'common:label.trialTermUnit',
    name: 'trialTermUnit',
    disabled: true,
  },
  {
    label: 'common:label.trialTerm',
    name: 'trialTerm',
    disabled: true,
  },
];

export const newTrialSubscriptionsInfoField = [
  // {
  //   label: 'common:label.accountId',
  //   name: 'accountId',
  //   disabled: true,
  // },
  // {
  //   label: 'common:label.type',
  //   name: 'type',
  //   disabled: true,
  // },
  // {
  //   label: 'common:label.status',
  //   name: 'status',
  //   tOptions: 'selections:statusOrder',
  //   type: 'select',
  // },

  {
    label: 'common:label.initialTermUnit',
    name: 'initialTermUnit',
    tOptions: 'selections:initialTermUnit',
    type: 'select',
  },
  {
    label: 'common:label.initialTerm',
    name: 'initialTerm',
    type: 'number',
  },
  // {
  //   label: 'common:label.userId',
  //   name: 'userId',
  //   disabled: true,
  // },
  {
    label: 'common:label.renewalTermUnit',
    name: 'renewalTermUnit',
    tOptions: 'selections:initialTermUnit',
    type: 'select',
  },
  {
    label: 'common:label.renewalTerm',
    name: 'renewalTerm',
    type: 'number',
  },
  {
    label: 'common:label.effectiveDate',
    name: 'effectiveDate',
    type: 'date',
  },
  {
    label: 'common:label.trialTermUnit',
    name: 'trialTermUnit',
    type: 'select',
    isClearable: true,
    tOptions: 'selections:trialTermUnit',
  },
  {
    label: 'common:label.trialTerm',
    name: 'trialTerm',
    type: 'number',
  },
  {
    label: 'label.trialType',
    name: 'trialType',
    tOptions: 'selections:trialTypes',
    isClearable: true,
    type: 'select',
    required: true,
  },
  {
    label: 'common:label.trialToPaidNow',
    name: 'trialToPaidNow',
    type: 'checkbox',
    wrapperClass: 'col-md-4 mt-2 pt-2',
  },
];

export const provisionigColumns = [
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
  },
  {
    name: 'type',
    label: 'label.type',
  },
  {
    name: 'action',
    label: 'label.orderAction',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
];

export const infrastructureEquipmentsColumns = [
  {
    name: 'action',
    label: 'common:label.action',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:orderAction')().find(val => val.value === item.action) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'omsInternalId',
    label: 'common:label.instanceId',
  },
  {
    name: 'assetTag',
    label: 'common:label.assetTag',
  },
  {
    name: 'description',
    label: 'common:label.description',
  },
  {
    name: 'deliveryDate',
    label: 'common:label.deliveryDate',
  },
  {
    name: 'quantity',
    label: 'common:label.quantity',
  },
  {
    name: 'cost',
    label: 'common:label.cost',
  },

  {
    name: 'primaryCode',
    label: 'common:label.primaryCode',
  },
  {
    name: 'inventoryItemId',
    label: 'common:label.inventoryItemId',
  },
  {
    name: 'exchangeRate',
    label: 'common:label.exchangeRate',
  },
  {
    name: 'statusId',
    label: 'common:label.statusId',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'transactionType',
    label: 'common:label.transactionType',
  },
  {
    name: 'externalId',
    label: 'common:label.externalId',
  },
  {
    name: 'comments',
    label: 'common:label.comments',
  },
];

export const billableFields = ({ currencyOptions, cutOffDOMOptions }) => [
  {
    name: 'currency',
    label: 'common:label.currency',
    type: 'select',
    options: currencyOptions,
    menuPortalTarget: true,
    isNoDisabledOtherType: true,
    isNeedCheckStatus: true,
    disabled: true,
    readOnly: true,
  },
  {
    name: 'alternateDescription',
    label: 'common:label.alternateDescription',
    type: 'textarea',
    style: { height: '28px' },
    isNoDisabledOtherType: true,
    isNeedCheckStatus: true,
  },
  {
    name: 'percentile',
    label: 'common:label.percentileValue',
    type: 'number',
    isNoDisabledOtherType: true,
    // is2Decimal: true,
  },
  {
    name: 'invoiceExhibits',
    label: 'common:label.invoiceExhibits',
    type: 'number',
    isNoDisabledOtherType: true,
    isNeedCheckStatus: true,
  },
  {
    name: 'recurringType',
    label: 'common:label.recurringType',
    type: 'select',
    tOptions: 'selections:recurringType',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'recurringUnit',
    label: 'common:label.recurringUnit',
    type: 'select',
    tOptions: 'selections:recurringUnit',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'recurringFrequency',
    label: 'common:label.recurringFrequency',
    type: 'number',
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'advanceFlag',
    label: 'common:label.advanceFlag',
    type: 'select',
    tOptions: 'selections:selectBool',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'cutOffDate',
    label: 'common:label.cutOffDom',
    type: 'select',
    menuPortalTarget: true,
    isNoDisabledOtherType: true,
    options: cutOffDOMOptions,
    isNeedCheckStatus: true,
  },
  {
    name: 'partMonthWaiveOffFlag',
    label: 'common:label.partMonthWaive',
    type: 'select',
    tOptions: 'selections:selectBool',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'calendarMonthFlag',
    label: 'common:label.calendarMonth',
    type: 'select',
    tOptions: 'selections:selectBool',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'alignedToCycle',
    label: 'common:label.align2Cycle',
    type: 'select',
    tOptions: 'selections:selectBool',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
  {
    name: 'isGrant',
    label: 'common:label.grants',
    type: 'select',
    tOptions: 'selections:selectBool',
    menuPortalTarget: true,
    // isNoDisabled: true,
    isNoDisabledOtherType: true,
  },
];

export const locationsTable = [
  {
    name: 'action',
    label: 'common:label.action',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:orderAction')().find(val => val.value === item.action) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'omsInternalId',
    label: 'common:label.instanceId',
  },
  {
    name: 'userId',
    label: 'common:label.userId',
  },
  {
    name: 'pointId',
    label: 'common:label.pointId',
  },
  {
    name: 'pointName',
    label: 'common:label.pointName',
  },
  {
    name: 'cpId',
    label: 'common:label.cpId',
  },
  {
    name: 'commercialClasification',
    label: 'common:label.commercialClasification',
  },
  {
    name: 'ownerName',
    label: 'common:label.ownerName',
  },

  {
    name: 'deliveryType',
    label: 'common:label.deliveryType',
  },
  {
    name: 'ring',
    label: 'common:label.ring',
  },
  {
    name: 'floor',
    label: 'common:label.floor',
  },
  {
    name: 'colony',
    label: 'common:label.colony',
  },
  {
    name: 'street',
    label: 'common:label.street',
  },
  {
    name: 'city',
    label: 'common:label.city',
  },
  {
    name: 'state',
    label: 'common:label.state',
  },
  {
    name: 'zipCode',
    label: 'common:label.zipCode',
  },
  {
    name: 'plusCode',
    label: 'common:label.plusCode',
  },
  {
    name: 'country',
    label: 'common:label.country',
  },
  {
    name: 'creationDate',
    label: 'common:label.creationDate',
  },
  {
    name: 'endDate',
    label: 'common:label.endDate',
  },
  {
    name: 'fullName',
    label: 'common:label.fullName',
  },
  {
    name: 'company',
    label: 'common:label.company',
  },
  {
    name: 'latitude',
    label: 'common:label.latitude',
  },
  {
    name: 'longitude',
    label: 'common:label.longitude',
  },
  {
    name: 'ownerId',
    label: 'common:label.ownerId',
  },
  {
    name: 'validationStatus',
    label: 'common:label.validationStatus',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'statusId',
    label: 'common:label.statusId',
  },
  {
    name: 'cp',
    label: 'common:label.cp',
  },
  {
    name: 'floorSite',
    label: 'common:label.floorSite',
  },
  {
    name: 'ringName',
    label: 'common:label.ringName',
  },
];

export const billableServiceTableColumns = [
  {
    name: 'serviceName',
    label: 'common:label.name',
  },
  {
    name: 'action',
    label: 'common:label.action',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:orderAction')().find(val => val.value === item.action) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'releaseDate',
    label: 'common:label.releaseDate',
  },
  {
    name: 'quantity',
    label: 'common:label.quantity',
  },
  {
    name: 'servicePrice',
    label: 'common:label.price',
    render: (colName, item) => {
      return <span>{item.servicePrice !== null ? formatNumberValue(item.servicePrice) : ''}</span>;
    },
  },
  {
    name: 'capacity',
    label: 'common:label.capacity',
    render: (colName, item) => {
      return <span>{item.capacity !== null ? formatNumberValue(item.capacity) : ''}</span>;
    },
  },
  {
    name: 'uom',
    label: 'common:label.unitOfMeasure',
  },
  {
    name: 'clientCircuitId',
    label: 'common:label.clientCircuitId',
  },
  {
    name: 'administrativeState',
    label: 'common:label.administrativeState',
  },
  {
    name: 'operationalState',
    label: 'common:label.operationalState',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'dischargeDate',
    label: 'common:label.dischargeDate',
  },
  {
    name: 'cancelledDate',
    label: 'common:label.cancelledDate',
  },
  {
    name: 'serviceId',
    label: 'common:label.serviceId',
  },
  {
    name: 'loadType',
    label: 'common:label.loadType',
  },
  {
    name: 'productDescription',
    label: 'common:label.productDescription',
  },
  {
    name: 'itemId',
    label: 'common:label.itemId',
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTermUnit',
    label: 'common:label.initialTermUnit',
  },
  {
    name: 'initialTerm',
    label: 'common:label.initialTerm',
  },
  {
    name: 'priceWithTax',
    label: 'common:label.priceWithTax',
  },
  {
    name: 'taxCode',
    label: 'common:label.taxCode',
  },
  {
    name: 'parentServiceId',
    label: 'common:label.parentServiceId',
  },
  {
    name: 'parentServiceName',
    label: 'common:label.parentServiceName',
  },
  {
    name: 'omsInternalId',
    label: 'common:label.instanceId',
  },
  {
    name: 'serviceDiscount',
    label: 'common:label.serviceDiscount',
  },
  {
    name: 'serviceMovement',
    label: 'common:label.movement',
  },
  {
    name: 'externalServiceId',
    label: 'common:label.externalServiceId',
  },
  {
    name: 'commodityPrice',
    label: 'common:label.commodityPrice',
  },
  {
    name: 'cost',
    label: 'common:label.cost',
  },
  {
    name: 'externalServiceIdOp',
    label: 'common:label.externalServiceIdOp',
  },
  {
    name: 'includedLicenses',
    label: 'common:label.includedLicenses',
  },
  {
    name: 'notes',
    label: 'common:label.notes',
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },
  {
    name: 'validity',
    label: 'common:label.validity',
  },
  {
    name: 'trialTerm',
    label: 'common:label.trialTerm',
  },
  {
    name: 'trialTermUnit',
    label: 'common:label.trialTermUnit',
  },
  {
    name: 'commitmentDollarValue',
    label: 'common:label.commitmentDollarValue',
  },
  {
    name: 'commitmentTerm',
    label: 'common:label.commitmentTerm',
  },
  {
    name: 'internationalDIDCountry',
    label: 'common:label.internationalDIDCountry',
  },
];

export const equipmentsTable = [
  {
    name: 'action',
    label: 'common:label.action',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:orderAction')().find(val => val.value === item.action) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'omsInternalId',
    label: 'common:label.instanceId',
  },
  {
    name: 'assetTag',
    label: 'common:label.assetTag',
  },
  {
    name: 'description',
    label: 'common:label.description',
  },
  {
    name: 'deliveryDate',
    label: 'common:label.deliveryDate',
  },
  {
    name: 'quantity',
    label: 'common:label.quantity',
  },
  {
    name: 'cost',
    label: 'common:label.cost',
  },

  {
    name: 'primaryCode',
    label: 'common:label.primaryCode',
  },
  {
    name: 'inventoryItemId',
    label: 'common:label.inventoryItemId',
  },
  {
    name: 'exchangeRate',
    label: 'common:label.exchangeRate',
  },
  {
    name: 'statusId',
    label: 'common:label.statusId',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'transactionType',
    label: 'common:label.transactionType',
  },
  {
    name: 'externalId',
    label: 'common:label.externalId',
  },
  {
    name: 'comments',
    label: 'common:label.comments',
  },
];

export const orderListRadioButtons = [
  {
    label: 'label.allowPartialProvisioning',
    name: 'isPartialFulfillmentAllowed',
    type: 'checkbox',
    wrapperClass: 'mr-3',
    tooltipDetails: 'message.isPartialFulfillmentAllowedNote',
  },
  {
    label: 'label.billForOrder',
    name: 'billOrderCharges',
    type: 'checkbox',
    wrapperClass: 'mr-3',
    tooltipDetails: 'message.billOrderChargesNote',
  },
  {
    label: 'label.billingOnly',
    name: 'billingOnlyFlag',
    type: 'checkbox',
    wrapperClass: 'mr-3',
    tooltipDetails: 'message.billingOnlyNote',
  },
  {
    label: 'label.provisioningOnly',
    name: 'provisioningOnlyFlag',
    type: 'checkbox',
    wrapperClass: 'mr-3',
    tooltipDetails: 'message.provisioningOnlyNote',
  },
];

export const listFlexAttributes = [
  {
    name: 'flexAttr1',
    label: 'FLEXATTR1',
  },
  {
    name: 'flexAttr2',
    label: 'FLEXATTR2',
  },
  {
    name: 'flexAttr3',
    label: 'FLEXATTR3',
  },
  {
    name: 'flexAttr4',
    label: 'FLEXATTR4',
  },
  {
    name: 'flexAttr5',
    label: 'FLEXATTR5',
  },
  {
    name: 'flexAttr6',
    label: 'FLEXATTR6',
  },
  {
    name: 'flexAttr7',
    label: 'FLEXATTR7',
  },
  {
    name: 'flexAttr8',
    label: 'FLEXATTR8',
  },
  {
    name: 'flexAttr9',
    label: 'FLEXATTR9',
  },
  {
    name: 'flexAttr10',
    label: 'FLEXATTR10',
  },
  {
    name: 'flexAttr11',
    label: 'FLEXATTR11',
  },
  {
    name: 'flexAttr12',
    label: 'FLEXATTR12',
  },
];
