import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  const createRegulatoryProductCodes = `createRegulatoryProductCodes(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createRegulatoryProductCodes} {
      id
      description
      startDate
      endDate
      includeIva
      includeIeps
      complement
    }
  }
`;
};
