import gql from 'graphql-tag';
import { parseToMutationRequestUser } from 'utils/utils';

export default data => {
  const isCreateUser = true;
  const dataCreate = parseToMutationRequestUser(
    data,
    ['status', 'type', 'index'],
    isCreateUser,
  );
  const createUser = `createUser(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createUser} {
        id
      }
    }
  `;
};
