import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../common';
import listCurrency from '../../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class TierForm extends PureComponent {
  state = { data: { prices: [], grants: [], accumulators: [] } };

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (data) this.setState({ data });
  }

  render() {
    const { grantInputs, accumulatorInputs, isComplexUsage, index, t, transactionType, pricingModel } = this.props;

    const priceInputs = [
      {
        label: 'label.currency',
        wrapperClass: 'col-md-4',
        name: 'currencyId',
        type: 'select',
        required: true,
        options: currencyOptions,
        disabled: isComplexUsage && index > 0,
        menuPortalTarget: true,
      },
      {
        label: 'label.amount',
        wrapperClass: 'col-md-4',
        name: 'amount',
        type: 'number',
        required: true,
        isNegativeValue: true,
      },
    ];

    if (
      (pricingModel === 'TIERED' || pricingModel === 'COMPLEX') &&
      (transactionType === 'USAGE' || transactionType === 'VOLUME_DISCOUNT')
    ) {
      priceInputs.push({
        label: 'label.flatRate',
        wrapperClass: 'col-md-4 mt-4 pt-2',
        name: 'flatRate',
        type: 'checkbox',
      });
    }

    const { data } = this.state;
    return (
      <div>
        <div>
          {data &&
            data.prices &&
            data.prices.length > 0 &&
            data.prices.map((price, idx) => {
              if (price) {
                return (
                  <div key={`price-${idx}`}>
                    <div className="card-header">{`${t('label.price')}#${idx + 1}`}</div>
                    <div className="row col-md-12 p-3">
                      {priceInputs.map(item => (
                        <GenericInput
                          key={`prices-${item.name}`}
                          disabled
                          readOnly
                          {...item}
                          value={price[item.name]}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {data &&
            data.grants &&
            data.grants.length > 0 &&
            data.grants.map((grant, idx) => {
              if (grant && size(grant) > 1) {
                return (
                  <div key={`grant-${idx}`}>
                    <div className="card-header">{`Grant#${idx + 1}`}</div>
                    <div className="row col-md-12 p-3">
                      {grantInputs.map(item => (
                        <GenericInput
                          key={`grants-${item.name}`}
                          disabled
                          readOnly
                          {...item}
                          value={grant[item.name]}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {data &&
            data.accumulators &&
            data.accumulators.length > 0 &&
            data.accumulators.map((accumulator, idx) => {
              if (accumulator && size(accumulator) > 2) {
                return (
                  <div key={`accumulators-${idx}`}>
                    <div className="card-header">{`Accumulator#${idx + 1}`}</div>
                    <div className="row col-md-12 p-3" key={`grant-${idx}`}>
                      {accumulatorInputs.map(item => (
                        <GenericInput
                          key={`accumulators-${item.name}`}
                          disabled
                          readOnly
                          {...item}
                          value={accumulator[item.name]}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
        </div>
      </div>
    );
  }
}

TierForm.propTypes = {
  isRemoveable: PropTypes.bool,
  isComplexUsage: PropTypes.bool,
  headerTitle: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  accumulatorInputs: PropTypes.arrayOf(PropTypes.any),
  grantInputs: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  t: PropTypes.func,
  pricingModel: PropTypes.string,
  transactionType: PropTypes.string,
};

TierForm.defaultProps = {
  isRemoveable: true,
  isComplexUsage: false,
  headerTitle: null,
  data: {},
  accumulatorInputs: [],
  grantInputs: [],
  t: () => {},
  pricingModel: '',
  transactionType: '',
};

export default withTranslation('common')(TierForm);
