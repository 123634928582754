import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import TemplatesConfig from './TemplatesConfig';
import { makeGetPermissionsCorrespondence } from '../App/selectors';
import Sidebar from './Sidebar';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class CorrespondenceManagement extends PureComponent {
  render() {
    const { permissionsCorrespondence } = this.props;
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsCorrespondence, listKeys: permissionList.correspondence }))
      return null;

    if (permissionsCorrespondence && permissionsCorrespondence.correspondenceModulePermissions) {
      const listPermission = permissionsCorrespondence.correspondenceModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route
                exact
                path={RouteNames.correspondenceConfigTemplate.path}
                component={() => <TemplatesConfig permissions={permissions} />}
              />
            </Switch>
            <br />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'correspondenceReducer', reducer });
const withSaga = injectSaga({ key: 'correspondenceSaga', saga });

const mapStateToProps = createStructuredSelector({
  permissionsCorrespondence: makeGetPermissionsCorrespondence() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducer, withSaga, withConnect)(CorrespondenceManagement);
