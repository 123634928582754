import gql from 'graphql-tag';

export default ({ id, status }) => gql`
  query {
    getSubscriptionAssets(input: { subscriptionId: "${id}", subscriptionStatus: ${status} }) {
      serviceType
      status
      reason
      provisioningId
      packageId
      packageName
      bundleId
      bundleName
      id
      subscriptionName
      lines {
        id
        index
        status
        reason
        quantity
        priceOverride
        priceOffset
        discountPercent
        startDate
        endDate
        priceOfferId
        priceOfferName
        discountOfferId
        discountOfferName
      }
    }
  }
`;
