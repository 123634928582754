/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {} from 'reactstrap';
import { map } from 'lodash';
import { CheckField, DataTable, TablePagination } from '../common';
import { getPageTotalCount } from '../../utils/utils';

class SelectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: props.defaultFilter,
      sort: '',
      totalCount: null,
      isSearching: false,
      listItems: [],
      saveListItems: [],
      selectedItemIds: [],
      initSelectedParentId: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initSelectedParentId === props.selectedItemIds) return null;
    return {
      selectedItemIds: [],
      initSelectedParentId: props.selectedItemIds,
    };
  }

  componentDidMount() {
    // this.doSearchItem();
  }

  // doSearchItem = () => {
  //   const { page, size, filter, totalCount } = this.state;
  //   const filterData = {};
  //   map(filter, (item, key) => {
  //     if (item && item !== '') {
  //       filterData[key] = item;
  //     }
  //   });
  //   this.props.searchItems(
  //     {
  //       page,
  //       size,
  //       filter: filterData,
  //     },
  //     resp => {
  //       this.setState({
  //         isSearching: true,
  //         listItems: resp.success ? resp.data : [],
  //         saveListItems: resp.success ? resp.data : [],
  //         totalCount: getPageTotalCount({ page, size, totalCount: totalCount || resp.data.length, items: resp.data }),
  //       });
  //     }
  //   );
  // };

  onClickRow = (e, rowItem) => {
    e.preventDefault();
    const { selectedItemIds, listItems, saveListItems } = this.state;
    const { onClickRow, isSingleSelect } = this.props;
    const index = selectedItemIds.findIndex(item => item === rowItem.id);
    if (index === -1) {
      if (isSingleSelect) {
        this.setState({ selectedItemIds: [rowItem.id] }, () => {
          if (onClickRow) {
            onClickRow([rowItem.id], listItems);
          }
        });
        return;
      }
      selectedItemIds.push(rowItem.id);
    } else {
      selectedItemIds.splice(index, 1);
    }
    this.setState({ selectedItemIds }, () => {
      if (onClickRow) {
        onClickRow(selectedItemIds, saveListItems);
      }
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sort, filter, totalCount, saveListItems } = this.state;
    this.setState({ isSearching: true });
    const filterData = {};
    map(filter, (item, key) => {
      if (item && item !== '') {
        filterData[key] = item;
      }
    });

    this.props.searchItems(
      {
        page: page + 1,
        size,
        filter: filterData,
      },
      resp => {
        const listItems = resp.success ? resp.data : [];
        this.setState({
          isSearching: false,
          page,
          size,
          filter,
          sort,
          listItems: [...listItems],
          saveListItems: [...saveListItems, ...listItems],
          totalCount: getPageTotalCount({ page, size, totalCount: totalCount || listItems.length, items: listItems }),
        });
      }
    );
  };

  render() {
    const { listItems, selectedItemIds, size } = this.state;
    const { tableColumns, SearchForm } = this.props;
    const columns = [
      {
        name: 'Selected',
        label: 'label.selected',
        render: (colName, item) => {
          return (
            <CheckField
              name="selectedItemIds"
              value={item.id}
              checked={selectedItemIds.findIndex(value => value === item.id) !== -1}
              readOnly
            />
          );
        },
      },
      ...tableColumns,
    ];
    const tableConfig = {
      columns,
      data: listItems,
      getRowClass: item => {
        return this.state.selectedItemIds.findIndex(value => value === item.id) !== -1 ? 'selected-row' : '';
      },
    };
    return (
      <div>
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <SearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={this.state.isSearching}
                    isHaveClearButton
                    onClickClearButton={this.onSubmitSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} onClickRow={this.onClickRow} isLoading={this.state.isSearching} />
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={this.state.page}
            pageSize={size}
            totalCount={this.state.totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

SelectTable.propTypes = {
  selectedItemIds: PropTypes.any,
  tableColumns: PropTypes.array.isRequired,
  defaultFilter: PropTypes.object,
  searchItems: PropTypes.func.isRequired,
  SearchForm: PropTypes.any.isRequired,
};
SelectTable.defaultProps = {
  selectedItemIds: [],
  defaultFilter: {},
};

export default SelectTable;
