import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const payload = parseToMutationRequest(data, ['index', 'folioType', 'fileType', 'mandatory']);
  const modifyFolioResponse = `modifyFolioResponse(input: ${payload})`;
  return gql`
    mutation {
      ${modifyFolioResponse} {
        id
        folioType
        folioResponseFiles {
          index
          name
          fileType
          mandatory
        }
      }
    }
  `;
};
