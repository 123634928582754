import RouteNames from '../containers/App/RouteNames';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const URL_PAYMENT =
  process?.env?.REACT_APP_URL_PAYMENT || 'https://payment-gateway.dev.embrix.org/vault?callbackUrl=';
export const typePermissons = {
  readOnly: 'READ_ONLY',
  readWrite: 'READ_WRITE',
  none: 'NONE',
};

export const statusDisableFielsdViewFolio = [
  'STAMPED',
  'CANCELLATION_STAMPING_REQUESTED',
  'CANCELLATION_STAMPED',
  'AWAITING_CANCELLATION_STAMPING',
];

export const PAGE_SIZE_DOWNLOAD = 10000000;

export const permissionList = {
  customerManagement: {
    moduleName: 'CUSTOMER_MANAGEMENT',
    moduleKey: 'customerHubModules',
    subPermissionKey: 'customerModulePermissions',
  },
  quoteManagement: {
    moduleName: 'QUOTE_MANAGEMENT',
    moduleKey: 'customerHubModules',
    subPermissionKey: 'quoteModulePermissions',
  },
  orderManagement: {
    moduleName: 'ORDER_MANAGEMENT',
    moduleKey: 'customerHubModules',
    subPermissionKey: 'orderModulePermissions',
  },
  billing: {
    moduleName: 'BILLING',
    moduleKey: 'billingHubModules',
    subPermissionKey: 'billingModulePermissions',
  },
  taxation: {
    moduleName: 'TAXATION',
    moduleKey: 'billingHubModules',
    subPermissionKey: 'taxationModulePermissions',
  },
  usage: {
    moduleName: 'RATING',
    moduleKey: 'billingHubModules',
    subPermissionKey: 'ratingModulePermissions',
  },
  baseConfigurations: {
    moduleName: 'PRICE_MANAGEMENT',
    moduleKey: 'pricingHubModules',
    subPermissionKey: 'pricingModulePermissions',
  },
  pricingManagement: {
    moduleName: 'PRICE_MANAGEMENT',
    moduleKey: 'pricingHubModules',
    subPermissionKey: 'pricingModulePermissions',
  },
  bundleManagement: {
    moduleName: 'BUNDLE_MANAGEMENT',
    moduleKey: 'pricingHubModules',
    subPermissionKey: 'bundleModulePermissions',
  },
  payments: {
    moduleName: 'PAYMENT',
    moduleKey: 'arHubModules',
    subPermissionKey: 'paymentModulePermissions',
  },
  collections: {
    moduleName: 'COLLECTION',
    moduleKey: 'arHubModules',
    subPermissionKey: 'collectionModulePermissions',
  },
  AROperations: {
    moduleName: 'AR_OPS',
    moduleKey: 'arHubModules',
    subPermissionKey: 'arOpsModulePermissions',
  },
  revenue: {
    moduleName: 'REVENUE',
    moduleKey: 'revenueHubModules',
    subPermissionKey: 'revenueModulePermissions',
  },
  userManagement: {
    moduleName: 'USER_MANAGEMENT',
    moduleKey: 'opsHubModules',
    subPermissionKey: 'userModulePermissions',
  },
  jobsManagement: {
    moduleName: 'JOBS_MANAGEMENT',
    moduleKey: 'opsHubModules',
    subPermissionKey: 'jobsModulePermissions',
  },
  correspondence: {
    moduleName: 'CORRESPONDENCE',
    moduleKey: 'opsHubModules',
    subPermissionKey: 'correspondenceModulePermissions',
  },
  reports: {
    moduleName: 'REPORTS',
    moduleKey: 'opsHubModules',
    subPermissionKey: 'reportModulePermissions',
  },
  dashboard: {
    moduleName: 'DASHBOARD',
    moduleKey: 'opsHubModules',
    subPermissionKey: 'dashboardModulePermissions',
  },
  tenantManagement: {
    moduleName: 'TENANT_ONBOARDING',
    moduleKey: 'opsHubModules',
    subPermissionKey: 'tenantOnboardModulePermissions',
  },
  taskManagement: {
    moduleName: 'CUSTOMER_MANAGEMENT',
    moduleKey: 'customerHubModules',
    subPermissionKey: 'customerModulePermissions',
  },
};

export const navLinkItems = {
  customerHubModules: {
    links: [
      {
        path: RouteNames.customers.path,
        label: 'customerHub.subMain.customerManagement',
        ...permissionList.customerManagement,
      },
      {
        path: RouteNames.searchQuoteAndList.path,
        label: 'customerHub.subMain.quoteManagement',
        ...permissionList.quoteManagement,
      },
      {
        path: RouteNames.searchAndList.path,
        label: 'customerHub.subMain.orderManagement',
        ...permissionList.orderManagement,
      },
    ],
  },

  billingHubModules: {
    links: [
      {
        path: RouteNames.invoicingBilling.path,
        label: 'billingHub.subMain.billing',
        ...permissionList.billing,
      },
      {
        path: RouteNames.taxTypeConfig.path,
        label: 'billingHub.subMain.taxation',
        ...permissionList.taxation,
      },
      {
        path: RouteNames.reprocessingSuspendedFailedRecords.path,
        label: 'billingHub.subMain.usage',
        ...permissionList.usage,
      },
    ],
  },

  pricingHubModules: {
    links: [
      {
        path: RouteNames.currency.path,
        label: 'pricingHub.subMain.baseConfigurations',
        ...permissionList.baseConfigurations,
      },
      {
        path: RouteNames.productFamilyPricing.path,
        label: 'pricingHub.subMain.pricingManagement',
        ...permissionList.pricingManagement,
      },
      {
        path: RouteNames.bundleItem.path,
        label: 'pricingHub.subMain.bundleManagement',
        ...permissionList.bundleManagement,
      },
    ],
  },

  arHubModules: {
    links: [
      {
        path: RouteNames.paymentActivity.path,
        label: 'arHub.subMain.payments',
        ...permissionList.payments,
      },
      {
        path: RouteNames.collections.path,
        label: 'arHub.subMain.collections',
        ...permissionList.collections,
      },
      {
        path: RouteNames.arOperationsAdjustments.path,
        label: 'arHub.subMain.AROperations',
        ...permissionList.AROperations,
      },
    ],
  },

  revenueHubModules: {
    links: [
      {
        path: RouteNames.revenueConfigurationLocation.path,
        label: 'revenueHub.subMain.configuration',
        ...permissionList.revenue,
      },
      {
        path: RouteNames.journals.path,
        label: 'revenueHub.subMain.revenue',
        ...permissionList.revenue,
      },
    ],
  },

  opsHubModules: {
    links: [
      {
        path: RouteNames.userManagementRole.path,
        label: 'operationsHub.subMain.userManagement',
        ...permissionList.userManagement,
      },
      {
        path: RouteNames.jobsManagmentJobSchedule.path,
        label: 'operationsHub.subMain.jobsManagement',
        ...permissionList.jobsManagement,
      },
      {
        path: RouteNames.correspondenceConfigTemplate.path,
        label: 'operationsHub.subMain.correspondence',
        ...permissionList.correspondence,
      },
      {
        path: RouteNames.report.path,
        label: 'operationsHub.subMain.reports',
        ...permissionList.reports,
      },
      {
        path: RouteNames.myDashboard.path,
        label: 'operationsHub.subMain.dashboard',
        ...permissionList.dashboard,
      },
      {
        path: RouteNames.tenantData.path,
        label: 'operationsHub.subMain.tenantManagement',
        ...permissionList.tenantManagement,
      },
      {
        path: RouteNames.tasksList.path,
        label: 'operationsHub.subMain.taskManagement',
        ...permissionList.taskManagement,
      },
    ],
  },
};
