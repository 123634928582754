import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['ratingType', 'productLine'])}` : '';
  const getUsageSummaryReport = `getUsageSummaryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getUsageSummaryReport} {
        batchId
        fileDate
        source
        totalTxns
        ratedTxns
        suspendedTxns
        erroredTxns
        pendingTxns
      }
    }
  `;
};
