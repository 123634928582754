import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataTable, GenericInput, TitleFrom, CollapsibleTable } from '../../../components/common';
import { getFolioResponse, createFolioResponse, modifyFolioResponse } from '../actions';
import { validate, supportRemoveIndexWithSize, compareArrayIndexValueChange } from '../../../utils/utils';
import { makeGetPermissionsInvoice } from '../../App/selectors';
import {
  checkPermissionCreateFolioResponse,
  checkPermissionModifyFolioResponse,
  checkPermissionGetFolioResponse,
} from '../CheckPermission';

const FolioResponseFile = ({ getFolioResponse, createFolioResponse, modifyFolioResponse, permissionsInvoice }) => {
  const formRef = useRef();

  let modeCreateFolioResponse = 0;
  let modeModifyFolioResponse = 0;
  let modeGetFolioResponse = 0;

  if (permissionsInvoice && permissionsInvoice.invoicingModulePermissions) {
    const listPermission = permissionsInvoice.invoicingModulePermissions;
    modeCreateFolioResponse = checkPermissionCreateFolioResponse({
      listPermission,
    });
    modeModifyFolioResponse = checkPermissionModifyFolioResponse({
      listPermission,
    });
    modeGetFolioResponse = checkPermissionGetFolioResponse({
      listPermission,
    });
  }

  const modeGetUsageFolioResponseFile = 2;

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [activeTabTable, setActiveTabTable] = useState(-1);
  const [wasValidated, setValidate] = useState(false);
  const [defaultData, setDefaultData] = useState(null);

  const { t } = useTranslation('common');

  const onChangeData = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewSubItem = () => {
    try {
      const newData = cloneDeep(data);
      if (!newData[activeTabTable].folioResponseFiles) newData[activeTabTable].folioResponseFiles = [];
      const payload = {
        name: null,
        fileType: null,
        isNew: true,
      };
      newData[activeTabTable].folioResponseFiles = [payload, ...newData[activeTabTable].folioResponseFiles];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeDataSubTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      newData[activeTabTable].folioResponseFiles[index][name] = newValue;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveItem = ({ index, item }) => {
    const newData = cloneDeep(data);
    try {
      if (item.isNew) {
        newData[activeTabTable].folioResponseFiles.splice(index, 1);
      } else {
        newData[activeTabTable].folioResponseFiles[index] = {
          index: newData[activeTabTable].folioResponseFiles[index].index,
        };
      }
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteItem = ({ index, item }) => {
    const newData = cloneDeep(data);
    try {
      if (item.isNew) {
        newData.splice(index, 1);
        setData(newData);
        setActiveTabTable(-1);
      } else {
        modifyFolioResponse(
          {
            id: item.id,
            isDelete: true,
          },
          () => {
            doGetFolioResponse();
            setActiveTabTable(-1);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = () => {
    try {
      let newData = cloneDeep(data);
      if (!newData) newData = [];
      const payload = {
        folioType: null,
        isNew: true,
      };
      newData = [payload, ...newData];
      setActiveTabTable(0);
      setDefaultData(payload);
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const doGetFolioResponse = useCallback(
    item => {
      if (modeGetUsageFolioResponseFile) {
        setLoading(true);
        getFolioResponse({}, ({ success, data }) => {
          setLoading(false);
          if (success && data) {
            if (item?.id) {
              const indexItem = data.findIndex(dt => dt.id === item.id);
              onToggleTableTab(indexItem);
            }
            setData(data);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFolioResponse, modeGetUsageFolioResponseFile]
  );

  const onClearData = () => {
    const newData = cloneDeep(data);
    newData[activeTabTable] = defaultData;
    setData(newData);
  };

  const onSave = () => {
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    const payload = cloneDeep(data)[activeTabTable];

    if (payload && payload.id) {
      const { folioType, folioResponseFiles, id } = payload;
      return modifyFolioResponse(
        {
          id,
          folioType,
          folioResponseFiles: compareArrayIndexValueChange({
            defaultData: defaultData ? defaultData.folioResponseFiles : null,
            newData: supportRemoveIndexWithSize({ data: folioResponseFiles }),
          }),
        },
        ({ success, data }) => {
          if (success) doGetFolioResponse(data);
        }
      );
    }
    const { folioType, folioResponseFiles } = payload;
    const newFolioResponseFiles =
      folioResponseFiles && folioResponseFiles.length
        ? folioResponseFiles.map(val => {
            const { index, isNew, ...rest } = val;
            return rest;
          })
        : null;
    return createFolioResponse({ folioType, folioResponseFiles: newFolioResponseFiles }, ({ success, data }) => {
      if (success) doGetFolioResponse(data);
    });
  };

  useEffect(() => {
    return doGetFolioResponse();
  }, [doGetFolioResponse]);

  if (!modeGetFolioResponse) return '';

  const onToggleTableTab = indexItem => {
    if (activeTabTable === indexItem) return setActiveTabTable(-1);
    if (activeTabTable !== indexItem) {
      setDefaultData(data[indexItem]);
      return setActiveTabTable(indexItem);
    }
    return setActiveTabTable(-1);
  };

  const tableFolioResponseFile = [
    {
      name: 'folioType',
      label: 'label.folioType',
      // style: { width: '100px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.folioType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="folioType"
          type="select"
          tOptions="selections:folioType"
          menuPortalTarget
          required
          isNoClearable
        />
      ),
    },
    {
      name: 'addFiles',
      label: 'label.addFiles',
      render: (colName, item, idx, indexParent, activeTab) => (
        <button
          type="button"
          className="btn-expand-table mr-3"
          onClick={() => {
            setDefaultData(item);
            onToggleTableTab(idx);
          }}
        >
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onDeleteItem({ item, index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const subTableFolioResponseFile = [
    {
      name: 'name',
      label: 'label.name',
      style: { maxWidth: '100px' },
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
          name="name"
          required
        />
      ),
    },
    {
      name: 'fileType',
      label: 'label.fileType',
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.fileType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
          name="fileType"
          type="select"
          tOptions="selections:objectFileType"
          menuPortalTarget
          required
          isNoClearable
        />
      ),
    },
    {
      name: 'mandatory',
      label: 'label.mandatory',
      render: (name, item, idx) => {
        return (
          <GenericInput
            value={item.mandatory === 'true' || item.mandatory === true ? 'TRUE' : 'FALSE'}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
            name="mandatory"
            type="select"
            tOptions="selections:selectBool"
            menuPortalTarget
            isNoClearable
          />
        );
      },
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveItem({ index: idx, item })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="col-md-12 p-0">
      <div className="card card-statistics h-100 mr-3">
        <TitleFrom title={t('tenantPage:tenantManagementPage.folioResponseFile')} />
        <div className="col-md-8 m-auto">
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateFolioResponse === 2 && (
              <button type="button" onClick={addNewItem} className="button x-small">
                +
                {t('label.add')}
              </button>
            )}
            <button
              type="button"
              onClick={doGetFolioResponse}
              className="button mr-2 button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <form ref={formRef} noValidate className={`${wasValidated ? 'was-validated' : ''}`}>
            <div className="group-collapsible">
              <CollapsibleTable
                columns={tableFolioResponseFile}
                data={data || []}
                isSupportRemoveIndex
                indexViewer={activeTabTable}
                isLoading={isLoading}
              >
                <div className="col-md-12 pt-2 pb-3 mb-4">
                  <button type="button" onClick={addNewSubItem} className="button x-small mb-3 ml-2">
                    +
                    {t('label.add')}
                  </button>
                  <DataTable
                    columns={subTableFolioResponseFile}
                    data={
                      activeTabTable !== -1 && data[activeTabTable] && data[activeTabTable].folioResponseFiles
                        ? data[activeTabTable].folioResponseFiles
                        : []
                    }
                    isSupportRemoveIndex
                  />
                  <div className="col-md-12 pt-2 pb-3">
                    {modeModifyFolioResponse === 2 && (
                      <button type="button" onClick={onSave} className="button button-border x-small float-right mr-4">
                        {t('label.saveConfig')}
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={onClearData}
                      className="button mr-2 button-border black x-small float-right"
                    >
                      {t('label.clear')}
                    </button>
                  </div>
                </div>
              </CollapsibleTable>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

FolioResponseFile.propTypes = {
  getFolioResponse: PropTypes.func.isRequired,
  createFolioResponse: PropTypes.func.isRequired,
  modifyFolioResponse: PropTypes.func.isRequired,
};

FolioResponseFile.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionsInvoice: makeGetPermissionsInvoice() || {},
});

export default connect(mapStateToProps, {
  getFolioResponse,
  createFolioResponse,
  modifyFolioResponse,
})(FolioResponseFile);
