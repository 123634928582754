import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, cloneDeep, uniqBy, union } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import moment from 'moment';
import AccountTable from '../AccountTable';
import ModifyService from './ModifyService';
import UpgradeService from './UpgradeService';
import { getBundleByBundleId, getPackageByPackageId, getCcpPropertiesConfig } from '../../App/actions';
import { orderEnum } from '../../../constantsApp';
import {
  StepProgressBar,
  ModalSelectSubscription,
  Breadcrumb,
  GenericInput,
  FormWithTableItem,
} from '../../../components/common';
import {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  createOrder,
  getServiceUnitsBySubscriptionId,
  getDependentOffers,
  buildOrderProvisioningAttributes,
} from '../actions';
import { makeGetServiceUnits, makeGetSubscriptionAssets } from '../selectors';
import { makeGetPermissionsOrderManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import { checkPermissionModifyOrderType, checkPermissionDeletePriceOffer } from '../CheckPermission';
import ModalSelectPackageBundle from '../../../components/common/ModalSelectPackageBundle';
import { getUserId } from '../../../localStorages';
import { orderListRadioButtons } from '../constants';
import {
  convertbuildOrderProvisioningAttributesPayload,
  convertbuildOrderProvisioningAttributesNewOrder,
  convertbuildOrderProvisioningAttributesWithDefaultValue,
  convertSubscriptionDataEdit,
} from '../utilities';
import OrderProvisioningAttributesForm from '../../../components/Orders/OrderProvisioningAttributesForm';
import RouteNames from '../../App/RouteNames';
import { AttributesForm } from '../../../components/Orders';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';

let lastStep = 0;

const listStepsWithProvisioning = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.selectUpgradeOptions',
  },
  {
    step: 3,
    label: 'label.manageAndOverride',
  },
  {
    step: 4,
    label: 'label.provisioningDataAndCreateOrder',
  },
];

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.selectUpgradeOptions',
  },
  {
    step: 3,
    label: 'label.manageCreateOrder',
  },
];

let listServicesDefault = [];
let ids = [];
let packageNames = [];
let odlPackageNames = [];
let odlPackageIds = [];
let provisioningIds = [];

class UpgradeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: { id: props.location.state ? props.location.state.accountId : '' },
      orderDetail: this.initOrderDetail(props),
      subscriptionMessage: '',
      groupServices: [],
      defaultServiceFromAPI: [],
      isOpenModal: false,
      isModify: false,
      isReadOnly: true,
      isNextStep: false,
      isOpenModalSelect: false,
      isBundle: false,
      itemSelect: {},
      indexOfItemSelect: null,
      serviceNameSelect: null,
      isModifyLines: false,
      isOpenModalSubscription: false,
      isMultiSubscriptionEnabled: false,
      subscriptionData: [],
      provisioningEnabled: false,
      activeTab: {},
      buildOrderProvisioningAttributesList: null,
      oldStageSelected: 0,
      isPartialFulfillmentAllowed: null,
      customAttributesByObjectType: [],
    };
    this.services = [];
    this.initServiceUnits = {};
  }

  componentDidMount() {
    this.doGetCcpPropertiesConfig();
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(this.initServiceUnits) !== JSON.stringify(nextProps.subscriptionAssets)) {
      this.initServiceUnits = nextProps.subscriptionAssets;
      this.getServiceUnitsBySubscriptionId(nextProps.subscriptionAssets);
    }
  };

  doInitFromAccountScreen = () => {
    const {
      location: { state },
      getSubscriptionAssets,
    } = this.props;
    const {
      selectItem: { id },
    } = this.state;
    if (state && state.accountId && id) {
      this.initOrderDetailByAccountId([state.data || {}]);
      getSubscriptionAssets({ id: state?.data?.id || null, status: state?.data?.status || null });
      this.setState({ stage: 1, oldStageSelected: 1 });
    }
  };

  doGetCcpPropertiesConfig = () => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
    }
  };

  doInitPropertiesConfig = ccpPropertyList => {
    const multiSubscriptionEnabled = ccpPropertyList.find(val => val.property === 'multiSubscriptionEnabled');
    const provisioningEnabled = ccpPropertyList.find(val => val.property === 'provisioningEnabled');
    const partialProvisioningEnabled = ccpPropertyList.find(val => val.property === 'partialProvisioningEnabled');

    this.setState(
      {
        isMultiSubscriptionEnabled: multiSubscriptionEnabled && multiSubscriptionEnabled?.value === 'true',
        provisioningEnabled: provisioningEnabled && provisioningEnabled?.value === 'true',
        isPartialFulfillmentAllowed: partialProvisioningEnabled && partialProvisioningEnabled?.value === 'true',
      },
      () => this.doInitFromAccountScreen()
    );
  };

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState({
        customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
      });
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState({
            customAttributesByObjectType: (data && data.attributes) || [],
          });
        }
      }, 'ORDER');
    }
  };

  onToggle = () => {
    this.setState(prevState => ({
      isOpenModal: !prevState.isOpenModal,
    }));
  };

  initOrderDetail = props => {
    return {
      type: orderEnum.type.upgrade,
      status: orderEnum.status.create,
      reason: '',
      accountId: props.location.state ? props.location.state.accountId : '',
      userId: getUserId() || 'TestUserId',
      isPartialFulfillmentAllowed: false,
      effectiveDate: moment(new Date()).format('YYYY-MM-DD'),
      submittedDate: moment(new Date()).format('YYYY-MM-DD'),
      initialTerm: '1',
      initialTermUnit: orderEnum.initialTermUnit.months,
      renewalTerm: '1',
      renewalTermUnit: orderEnum.renewalTermUnit.months,
      services: [],
    };
  };

  onSelectNext = rowItem => {
    const { orderDetail } = this.state;
    orderDetail.accountId = rowItem.id;
    this.setState(
      {
        selectItem: rowItem,
        orderDetail,
      },
      () => {
        this.onPressNextButton();
        this.doGetCustomAttributesByObjectType();
      }
    );
  };

  selectAccount = () => {
    const { selectItem } = this.state;
    if (selectItem && selectItem.id) {
      this.getSubscriptionByAccountId(selectItem.id);
    }
  };

  doGetDependentOffers = ({ service, isChecking, indexOfItem, serviceName }) => {
    this.setState({ indexOfItemSelect: indexOfItem, serviceNameSelect: serviceName });
    const { getDependentOffers, t } = this.props;
    if (service && service.length && isChecking) {
      service.forEach((val, index) => {
        const payload = {
          bundleId: val.packageId || (!val.packageId && !val.bundleId) ? null : val.bundleId,
          packageId: val.packageId || null,
          dependencyType: 'UPGRADE',
          offerType: val.packageId ? 'PACKAGE' : 'BUNDLE',
        };
        getDependentOffers(payload, ({ data, success }) => {
          if (success) {
            if (index === service.length - 1 && !data.dependentOffers) {
              toast.error(t('message.messageNoUpgrade'));
              this.setState({ isNextStep: false });
            }
          }
        });
      });
    }
    if (service && !isChecking) {
      const payload = {
        bundleId: service.packageId ? null : service.bundleId || null,
        packageId: service.packageId || null,
        dependencyType: 'UPGRADE',
        offerType: service.packageId ? 'PACKAGE' : 'BUNDLE',
      };
      this.setState({ isBundle: !service.packageId });
      getDependentOffers(payload, ({ data, success }) => {
        if (success) {
          if (!data.dependentOffers) {
            toast.error(t('message.messageNoUpgrade'));
          } else {
            this.setState({
              isOpenModalSelect: true,
              dataModal: data,
            });
          }
        }
      });
    }
  };

  getSubscriptionByAccountId = id => {
    const { stage, isMultiSubscriptionEnabled } = this.state;
    const { getSubscriptionByAccountId } = this.props;
    getSubscriptionByAccountId(
      id,
      (success, data) => {
        if (success) {
          const isMulti =
            data &&
            data.length > 1 &&
            isMultiSubscriptionEnabled &&
            data.filter(val => val.status !== 'CLOSED') &&
            data.filter(val => val.status !== 'CLOSED').length > 1;
          if (!isMulti) this.initOrderDetailByAccountId(data);

          this.setState({
            stage: stage + 1,
            isOpenModalSubscription: isMulti,
            subscriptionData: data || [],
            oldStageSelected: stage + 1,
          });
        } else {
          toast(data, { type: toast.TYPE.ERROR });
        }
      },
      true
    );
  };

  getServiceUnitsBySubscriptionId = data => {
    const { t } = this.props;

    const { orderDetail, stage } = this.state;
    let services = [];
    const defaultIndexService = [];
    let listExistIds = [];
    let isNextStep = false;
    if (data.length) {
      services = data.map((item, index) => {
        const isBundleId = !item.packageId;
        const newLine = item.lines || [];
        for (let i = 0; i < newLine.length; i++) {
          newLine[i].action = 'ADD';
          // newLine[i].action = 'ADD';
        }
        isNextStep = !!item.bundleId || !!item.packageId;
        const newService = {
          index: index + 1,
          serviceType: item.serviceType,
          provisioningId: item.provisioningId || '',
          action: 'ADD',
          // action: 'ADD',
          reason: item.reason || '',
          status: 'CREATED',
          bundleId: item.bundleId || '',
          bundleName: item.bundleName || '',
          packageId: item.packageId || '',
          packageName: item.packageName || '',
          subscriptionName: item.subscriptionName || null,
          lines: newLine,
          isDeafultService: true,
          isDisabledUpgrade:
            (isBundleId && listExistIds.indexOf(item.bundleId) > -1) ||
            (!isBundleId && listExistIds.indexOf(item.packageId) > -1),
        };
        listExistIds = isBundleId ? union(listExistIds, [item.bundleId]) : union(listExistIds, [item.packageId]);
        return newService;
      });
    }
    const groupServices = groupBy(services, item => {
      return item.serviceType;
    });

    if (!isNextStep && stage > 0) toast.error(t('message.messageUpDownOrder'));

    this.setState({
      orderDetail: {
        ...orderDetail,
        services,
      },
      defaultServiceFromAPI: services,
      defaultGroupServiceFromAPI: groupServices,
      groupServices,
      defaultIndexService,
    });
  };

  initOrderDetailByAccountId = subscription => {
    const { orderDetail, isPartialFulfillmentAllowed } = this.state;
    if (!subscription || (subscription && subscription.length < 1)) return;
    orderDetail.accountId = subscription[0].accountId;
    orderDetail.reason = subscription[0].reason;
    orderDetail.renewalTerm = subscription[0].renewalTerm;
    orderDetail.renewalTermUnit = subscription[0].renewalTermUnit;
    orderDetail.initialTerm = subscription[0].initialTerm;
    orderDetail.initialTermUnit = subscription[0].initialTermUnit;
    orderDetail.trialTerm = subscription[0].trialTerm;
    orderDetail.trialTermUnit = subscription[0].trialTermUnit;
    orderDetail.effectiveDate = subscription[0].effectiveDate;
    orderDetail.subscriptionName = subscription[0].name;
    orderDetail.category = subscription[0].category;
    orderDetail.isPartialFulfillmentAllowed = isPartialFulfillmentAllowed || null;
    this.setState({
      orderDetail,
      isNextStep: false,
    });
  };

  onChangeFormLine = (groupIndex, serviceIndex, e) => {
    // const { orderDetail, defaultServiceFromAPI } = this.state;
    const { groupServices } = this.state;
    const newGroupServices = cloneDeep(groupServices);
    try {
      const indexChange = newGroupServices[groupIndex][serviceIndex].lines.findIndex(val => val.index === e.lineIndex);
      newGroupServices[groupIndex][serviceIndex].lines[indexChange][e.fieldName] = e.value;
      this.setState({ groupServices: newGroupServices });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProvisioningId = ({ name, value, item }) => {
    const { groupServices } = this.state;
    const newGroupServices = cloneDeep(groupServices);
    try {
      newGroupServices[item.serviceType].forEach((val, index) => {
        newGroupServices[item.serviceType][index][name] = value;
        newGroupServices[item.serviceType][index].isChangedId = true;
      });

      this.setState({ groupServices: newGroupServices, isModify: true });
    } catch (error) {
      console.log(error);
    }
  };

  onPressBackButton = () => {
    const { history } = this.props;
    history.push(RouteNames.searchAndList.path);
    // const { stage, dataStep2, itemSelect } = this.state;
    // if (stage > 0) {
    //   this.setState({
    //     stage: stage - 1,
    //     groupServices: stage - 1 === 1 ? dataStep2 : [],
    //     itemSelect: stage - 1 === 1 ? itemSelect : {},
    //     isOpenModalSubscription: false,
    //   });
    // }
  };

  onPressCancelButton = () => {
    this.setState({
      stage: 0,
      oldStageSelected: 0,
    });
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId,
    };
  };

  addLine = (groupIndex, serviceIndex, bundle) => {
    const { orderDetail, groupServices } = this.state;
    try {
      groupServices[groupIndex][serviceIndex].lines = bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          orderDetail.services.push(service);
          return true;
        });
      });
      this.setState({
        orderDetail,
        groupServices,
        isModify: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onPressNextButton = () => {
    const { selectItem, stage, groupServices, isBundle, orderDetail, provisioningEnabled } = this.state;
    if (selectItem.id && stage < 1) {
      return this.selectAccount();
    }
    if (stage === 1) {
      ids = [];
      packageNames = [];
      odlPackageNames = [];
      odlPackageIds = [];
      provisioningIds = [];
      listServicesDefault = [];
      if (!isBundle) {
        this.setState({ orderDetail });
        let listService = [];
        map(groupServices, item => {
          if (item && item.length)
            provisioningIds = cloneDeep([
              ...provisioningIds,
              { serviceType: item[0].serviceType, provisioningId: item[0].provisioningId },
            ]);
          const newService = item.filter(val => !!val.newId);
          if (newService && newService.length) listService = [...listService, ...newService];
        });
        if (listService && listService.length)
          listService.forEach((ser, index) => {
            this.doGetPackageUpgradeOrder({ item: ser, id: ser.newId, isLastItem: listService.length === index + 1 });
          });
      }
      return this.setState({ dataStep2: groupServices, stage: stage + 1, oldStageSelected: stage + 1 });
    }

    if (provisioningEnabled && stage === 2) {
      this.doBuildOrderProvisioningAttributes();
    }

    return this.setState({
      stage: stage + 1,
      oldStageSelected: stage + 1,
    });
  };

  onPressCreateButton = () => {
    const {
      orderDetail,
      groupServices,
      isBundle,
      buildOrderProvisioningAttributesList,
      customAttributesByObjectType,
    } = this.state;
    const newOrderDetail = cloneDeep(orderDetail);
    newOrderDetail.services = [];
    delete newOrderDetail.effectiveDate;
    delete newOrderDetail.category;
    newOrderDetail.isPartialFulfillmentAllowed = newOrderDetail.isPartialFulfillmentAllowed || null;
    newOrderDetail.billingOnlyFlag = newOrderDetail.billingOnlyFlag || null;
    newOrderDetail.provisioningOnlyFlag = newOrderDetail.provisioningOnlyFlag || null;
    newOrderDetail.billOrderCharges = newOrderDetail.billOrderCharges || null;

    newOrderDetail.provisioningAttributes = convertbuildOrderProvisioningAttributesNewOrder(
      buildOrderProvisioningAttributesList
    );
    map(groupServices, serviceGroup => {
      return serviceGroup
        .filter(val => !!val.newName)
        .map(service => {
          const {
            bundleName,
            packageName,
            newId,
            newName,
            isOverrideLines,
            isDeafultService,
            isChangedId,
            ...newService
          } = cloneDeep(service);
          if (isBundle) {
            newService.currentOfferId = newService.bundleId;
            newService.bundleId = newId;
          }
          if (!isBundle) {
            newService.currentOfferId = newService.packageId;
            newService.packageId = newId;
          }
          if (newService.lines && newService.lines.length) {
            for (let i = 0; i < newService.lines.length; i++) {
              newService.lines[i].status = 'CREATED';
              delete newService.lines[i].priceOfferName;
              delete newService.lines[i].discountOfferName;
              delete newService.lines[i].id;
              if (newService.lines[i] && newService.lines[i].discountOfferId) {
                newService.lines[i].discAmountOverride = newService.lines[i].priceOverride;
                newService.lines[i].discPercentOverride = newService.lines[i].discountPercent;
                delete newService.lines[i].priceOverride;
                delete newService.lines[i].discountPercent;
              }
            }
          }
          if (!isOverrideLines) newService.lines = null;
          newOrderDetail.services.push(newService);
          return true;
        });
    });
    const { docreateOrder } = this.props;

    const temp = [];
    if (customAttributesByObjectType && customAttributesByObjectType.length)
      customAttributesByObjectType.map(item => {
        if (item.value && item.isModify) {
          temp.push({ name: item.name, value: item.value });
        }
      });

    docreateOrder(
      {
        ...newOrderDetail,
        orderCustomAttributes: temp && temp.length ? temp : null,
        subscriptions: newOrderDetail?.subscriptionName
          ? [
              {
                index: 1,
                subscriptionName: newOrderDetail?.subscriptionName || null,
                ...convertSubscriptionDataEdit(newOrderDetail),
              },
            ]
          : null,
      },
      () => {}
    );
  };

  onRemoveItem = ({ itemRemove, isBundle = false, isPackage = false, isPriceOffer = false }) => {
    const { orderDetail, defaultServiceFromAPI } = this.state;
    let { groupServices } = this.state;
    const newData = cloneDeep(orderDetail);
    if (isBundle) {
      const indexItemRemove = newData.services.findIndex(item => item.index === itemRemove.index);
      if (defaultServiceFromAPI.findIndex(item => item.index === itemRemove.index) > -1) {
        if (newData.services[indexItemRemove].lines) delete newData.services[indexItemRemove].lines;
        newData.services[indexItemRemove].action = 'CANCEL';
      } else {
        newData.services.splice(indexItemRemove, 1);
      }
    }

    if (isPackage) {
      const groupIndex = newData.services.filter(item => item.index === itemRemove.index).map(item => item.index);
      groupIndex.forEach(item => {
        const indexItemRemove = newData.services.findIndex(v => v.index === item);
        if (defaultServiceFromAPI.findIndex(value => value.index === item) > -1) {
          // newData.services[indexItemRemove].action = 'CANCEL';
          newData.services[indexItemRemove].action = 'MODIFY';
          if (newData.services[indexItemRemove].lines)
            newData.services[indexItemRemove].lines.forEach((val, i) => {
              newData.services[indexItemRemove].lines[i].action = 'CANCEL';
            });
        } else {
          newData.services.splice(indexItemRemove, 1);
        }
      });
    }

    if (!isBundle && !isPackage) {
      const indexItemRemove = newData.services.findIndex(item => item.provisioningId === itemRemove.provisioningId);
      if (defaultServiceFromAPI.findIndex(item => item.provisioningId === itemRemove.provisioningId) > -1) {
        newData.services[indexItemRemove].action = 'MODIFY';
        if (newData.services[indexItemRemove].lines)
          newData.services[indexItemRemove].lines.forEach((val, i) => {
            newData.services[indexItemRemove].lines[i].action = 'CANCEL';
          });
      } else {
        newData.services.splice(indexItemRemove, 1);
      }
    }

    groupServices = groupBy(newData.services, item => {
      return item.serviceType;
    });
    this.setState({ orderDetail: newData, groupServices, isOpenModal: false, isModify: true });
  };

  onRemoveLine = ({ itemService, itemLine }) => {};

  onClickItem = (evt, row) => {
    evt.preventDefault();
    const { itemSelect } = this.state;
    const isBundle = !row.packageId;
    if (
      (isBundle && row.bundleId && itemSelect.id === row.bundleId) ||
      (!isBundle && itemSelect.id === row.packageId)
    ) {
      return this.setState({ itemSelect: {} });
    }
    if (!isBundle) {
      return this.setState({ itemSelect: { id: row.packageId, name: row.name } });
    }
    return this.setState({ itemSelect: { id: row.bundleId, name: row.name } });
  };

  onSelectItem = () => {
    const { indexOfItemSelect, itemSelect, serviceNameSelect, groupServices } = this.state;
    const newGroupService = cloneDeep(groupServices);
    if (itemSelect.id) {
      newGroupService[serviceNameSelect][indexOfItemSelect].newId = itemSelect.id;
      newGroupService[serviceNameSelect][indexOfItemSelect].newName = itemSelect.name;
    }
    this.setState({ isOpenModalSelect: false, groupServices: newGroupService, isNextStep: true });
  };

  toggleModal = () => {
    const { isOpenModalSelect } = this.state;
    this.setState({ isOpenModalSelect: !isOpenModalSelect, itemSelect: {} });
  };

  onHandleInitNewLines = ({ id, item }) => {
    try {
      const { groupServices, isBundle } = this.state;
      const newGroupServices = cloneDeep(groupServices);
      const { getBundleByBundleId, getPackageByPackageId } = this.props;
      if (isBundle) {
        getBundleByBundleId(id, ({ success, data }) => {
          if (success) {
            const indexService = newGroupServices[item.serviceType].findIndex(val => val.index === item.index);
            if (indexService !== -1) {
              newGroupServices[item.serviceType][indexService].isOverrideLines = true;
              newGroupServices[item.serviceType][indexService].lines =
                data && data.components
                  ? data.components.map(component => {
                      return this.initLineFromBundle(data, component);
                    })
                  : [];
            }
            this.setState({ groupServices: newGroupServices });
          }
        });
      } else {
        const indexService = newGroupServices[item.serviceType].findIndex(val => val.index === item.index);
        newGroupServices[item.serviceType][indexService].isOverrideLines = true;
        this.setState({ groupServices: newGroupServices });
      }
    } catch (error) {
      console.log(error);
    }
  };

  doGetPackageUpgradeOrder = ({ id, item, isLastItem }) => {
    const { getBundleByBundleId, getPackageByPackageId } = this.props;
    getPackageByPackageId(id, ({ success, data }) => {
      if (success) {
        let newListService = cloneDeep(listServicesDefault);
        if (data.components && data.components.length) {
          data.components.forEach(val => {
            ids = cloneDeep([...ids, id]);
            packageNames = cloneDeep([...packageNames, data.name]);
            odlPackageNames = cloneDeep([...odlPackageNames, item.packageName]);
            odlPackageIds = cloneDeep([...odlPackageIds, item.packageId]);
            provisioningIds = cloneDeep([
              ...provisioningIds,
              { serviceType: item.serviceType, provisioningId: item.provisioningId },
            ]);
          });
        }
        newListService = [...newListService, ...data.components];
        listServicesDefault = newListService;
        if (isLastItem) {
          const listServiceInit = newListService.map((val, index) => {
            return {
              index: index + 1,
              packageId: odlPackageIds[index],
              bundleId: val.bundleId,
              provisioningId: provisioningIds.find(ids => ids.serviceType === val.serviceType)
                ? provisioningIds.find(ids => ids.serviceType === val.serviceType).provisioningId
                : '',
              action: 'ADD',
              status: 'CREATED',
              bundleName: val.bundleName,
              serviceType: val.serviceType,
              reason: '',
              packageName: odlPackageNames[index],
              subscriptionName: item.subscriptionName || '',
              lines: [],
              newId: ids[index],
              newName: packageNames[index],
            };
          });
          if (listServiceInit && listServiceInit.length) {
            listServiceInit.forEach((bundle, idx) => {
              getBundleByBundleId(bundle.bundleId, dataBundle => {
                if (dataBundle.success) {
                  if (dataBundle.data.components && dataBundle.data.components.length) {
                    listServiceInit[idx].bundleName = dataBundle.data.name;
                    dataBundle.data.components.forEach((val, j) => {
                      listServiceInit[idx].lines[j] = {};
                      listServiceInit[idx].lines[j].index = j + 1;
                      listServiceInit[idx].lines[j].priceOfferId = val.priceOfferId || '';
                      listServiceInit[idx].lines[j].action = 'ADD';
                      listServiceInit[idx].lines[j].status = 'CREATED';
                      listServiceInit[idx].lines[j].priceOfferName = val.priceOfferName;
                      listServiceInit[idx].lines[j].discountOfferName = val.discountOfferName;
                    });
                  }
                }
              });
            });
          }
          const checkDuplicateService = uniqBy(listServiceInit, v =>
            [v.serviceType, v.packageId, v.currentOfferId].join()
          );
          const newGroupServices = groupBy(checkDuplicateService, item => {
            return item.serviceType;
          });
          this.setState({ groupServices: newGroupServices });
        }
      }
    });
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      status: 'CREATED',
      reason: '',
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId,
      discountOfferName: component.discountOfferName,
      priceOfferName: component.priceOfferName,
    };
  };

  initServiceWithSelectPackage = ({ index, bundleId, packageId, serviceType, packageName, bundleName }) => {
    return {
      index,
      serviceType,
      action: 'ADD',
      status: orderEnum.status.create,
      bundleId,
      packageId,
      packageName,
      bundleName,
      lines: [],
    };
  };

  doBuildOrderProvisioningAttributes = () => {
    const { buildOrderProvisioningAttributes } = this.props;
    const { orderDetail } = this.state;
    buildOrderProvisioningAttributes(convertbuildOrderProvisioningAttributesPayload(orderDetail), ({ data }) => {
      this.setState({
        buildOrderProvisioningAttributesList: convertbuildOrderProvisioningAttributesWithDefaultValue(data) || null,
      });
    });
  };

  onHandleChangeProvisioningAttributes = ({ value, indexService, indexAttribute, indexItem }) => {
    const { buildOrderProvisioningAttributesList } = this.state;
    try {
      const newAttributes = cloneDeep(buildOrderProvisioningAttributesList);
      newAttributes[indexService].configProvisioningAttributes[indexAttribute].attributesList[
        indexItem
      ].attributeValue = value;

      this.setState({ buildOrderProvisioningAttributesList: newAttributes });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleTab = (activeTabName, serviceType) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && serviceType === activeTab.serviceType) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive, serviceType },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true, serviceType } });
    }
  };

  onHandleChangeOrderForm = e => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    // if (e.fieldName === 'isPartialFulfillmentAllowed' && e.value) {
    //   newData.billingOnlyFlag = null;
    //   newData.provisioningOnlyFlag = null;
    //   newData.billOrderCharges = null;
    // }
    if (e.fieldName === 'billingOnlyFlag' && e.value) {
      // newData.isPartialFulfillmentAllowed = null;
      newData.provisioningOnlyFlag = null;
      // newData.billOrderCharges = null;
    }
    if (e.fieldName === 'provisioningOnlyFlag' && e.value) {
      // newData.isPartialFulfillmentAllowed = null;
      newData.billingOnlyFlag = null;
      // newData.billOrderCharges = null;
    }
    // if (e.fieldName === 'billOrderCharges' && e.value) {
    //   newData.isPartialFulfillmentAllowed = null;
    //   newData.billingOnlyFlag = null;
    //   newData.provisioningOnlyFlag = null;
    // }
    newData[e.fieldName] = e.value;

    this.setState({
      orderDetail: newData,
    });
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  renderContent = ({ modeDeletePriceOffer }) => {
    const {
      stage,
      selectItem,
      orderDetail,
      subscriptionMessage,
      groupServices,
      isNextStep,
      isOpenModal,
      isReadOnly,
      itemSelect,
      isBundle,
      isMultiSubscriptionEnabled,
      buildOrderProvisioningAttributesList,
      activeTab,
      customAttributesByObjectType,
    } = this.state;
    switch (stage) {
      case 0:
        return (
          <AccountTable
            type="upgrade-order"
            subscriptionStatus="ACTIVE"
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isNoPageTitle
          />
        );
      case 1:
        return (
          <UpgradeService
            id={selectItem.id}
            orderDetail={orderDetail}
            groupServices={groupServices}
            subscriptionMessage={subscriptionMessage}
            onChangeFormLine={this.onChangeFormLine}
            onChangeProvisioningId={this.onChangeProvisioningId}
            addLine={this.addLine}
            isOpen={isOpenModal}
            onRemoveItem={this.onRemoveItem}
            onRemoveLine={this.onRemoveLine}
            onToggle={this.onToggle}
            onChangeModifyService={this.onChangeModifyService}
            isReadOnly={isReadOnly}
            isNextStep={isNextStep}
            doGetDependentOffers={this.doGetDependentOffers}
            itemSelect={itemSelect}
            isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
          />
        );

      case 2:
        return (
          <>
            <ModifyService
              id={selectItem.id}
              modeDeletePriceOffer={modeDeletePriceOffer}
              orderDetail={orderDetail}
              groupServices={groupServices}
              subscriptionMessage={subscriptionMessage}
              onChangeFormLine={this.onChangeFormLine}
              onChangeProvisioningId={this.onChangeProvisioningId}
              addLine={this.addLine}
              isOpen={isOpenModal}
              onRemoveItem={this.onRemoveItem}
              onRemoveLine={this.onRemoveLine}
              onToggle={this.onToggle}
              onChangeModifyService={this.onChangeModifyService}
              isReadOnly={isReadOnly}
              isBundle={isBundle}
              onHandleInitNewLines={this.onHandleInitNewLines}
              isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
            />
            <FormWithTableItem title="label.customerAttributes">
              <div className="col-md-12 p-0">
                <AttributesForm
                  customAttributesByObjectType={customAttributesByObjectType}
                  onChange={this.onHandleChangeOrderAttribute}
                />
              </div>
            </FormWithTableItem>
          </>
        );

      case 3:
        return (
          <>
            <div className="col-md-12 row justify-content-center mt-2 mb-2">
              {orderListRadioButtons.map(item => (
                <GenericInput
                  key={item.name}
                  disabled={item.disabled}
                  value={orderDetail[item.name] || null}
                  onChange={({ name, value }) => this.onHandleChangeOrderForm({ fieldName: name, value })}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  {...item}
                />
              ))}
            </div>
            {buildOrderProvisioningAttributesList && (
              <OrderProvisioningAttributesForm
                data={buildOrderProvisioningAttributesList}
                activeTab={activeTab}
                onChange={e => this.onHandleChangeProvisioningAttributes({ ...e })}
                onToggleTab={this.onToggleTab}
              />
            )}
          </>
        );

      default:
        return <AccountTable onClickRow={this.onClickRow} selectItem={selectItem} onSelectNext={this.onSelectNext} />;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    if (step < stage + 1 || step <= oldStageSelected + 1) {
      this.setState({ stage: step - 1 });
    }
  };

  onChangeModifyService = () => {
    const { isReadOnly } = this.state;
    this.setState({ isReadOnly: !isReadOnly });
  };

  render() {
    const {
      stage,
      itemSelect,
      isNextStep,
      isOpenModalSelect,
      dataModal,
      isBundle,
      isOpenModalSubscription,
      subscriptionData,
      provisioningEnabled,
      oldStageSelected,
    } = this.state;
    const { permissionOrder, t, getSubscriptionAssets } = this.props;
    let modeModifyOrderType = 0;
    let modeDeletePriceOffer = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeModifyOrderType = checkPermissionModifyOrderType({ listPermission });
      modeDeletePriceOffer = checkPermissionDeletePriceOffer({ listPermission });
    }
    if (modeModifyOrderType === 0) {
      return '';
    }

    lastStep = provisioningEnabled ? 3 : 2;

    return (
      <div>
        <div className="col-md-12 row p-0">
          <div className="col-sm-6">
            <button type="button" className="button x-small" onClick={() => this.onPressBackButton(true)}>
              {t('label.back')}
            </button>
          </div>
          <div className="col-sm-6 pr-0">
            <Breadcrumb
              items={[
                {
                  name: t('orderPage:sidebar.upgradeOrder'),
                  url: RouteNames.upgradeOrder.path,
                },
              ]}
            />
          </div>
        </div>
        <div className="col-md-12 row text-center pb-4">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              listSteps={provisioningEnabled ? listStepsWithProvisioning : listSteps}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              oldSelected={oldStageSelected}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3 p-m">
            <button
              type="button"
              className="button button-border x-small gray box-shadow-btn"
              onClick={this.onPressCancelButton}
            >
              {t('label.cancel')}
            </button>
            {stage < lastStep ? (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressNextButton}
                disabled={!isNextStep}
              >
                {t('label.next')}
              </button>
            ) : (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressCreateButton}
                // disabled={modeModifyOrderType === 1 || !isModify || isReadOnly}
              >
                {t('label.create')}
              </button>
            )}
          </div>
        </div>
        {/* <div className="form-group col-md-12 mt-4 justify-content-center d-flex row mb-3">
       
        </div> */}
        {this.renderContent({
          modeDeletePriceOffer,
        })}
        <ModalSelectPackageBundle
          isOpen={isOpenModalSelect}
          onSelect={this.onSelectItem}
          isBundle={isBundle}
          toggleModal={this.toggleModal}
          data={dataModal && dataModal.dependentOffers.length ? dataModal.dependentOffers : []}
          selectedParentId={itemSelect || ''}
          onClickItem={this.onClickItem}
          onCancel={() => {
            this.setState({ isOpenModalSelect: false });
          }}
        />
        <ModalSelectSubscription
          onToggle={() => {
            this.onPressBackButton();
            this.initServiceUnits = {};
          }}
          isOpen={isOpenModalSubscription}
          data={subscriptionData}
          onSelect={data => {
            this.setState({ isOpenModalSubscription: false });
            this.initOrderDetailByAccountId([data]);
            getSubscriptionAssets({ id: data.id || null, status: data.status || null });
          }}
        />
      </div>
    );
  }
}

UpgradeOrder.propTypes = {
  getSubscriptionByAccountId: PropTypes.func.isRequired,
  docreateOrder: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  subscriptionAssets: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
};

UpgradeOrder.defaultProps = {
  subscriptionAssets: [],
  location: {},
  permissionOrder: {},
};

const mapStateToProps = createStructuredSelector({
  serviceUnits: makeGetServiceUnits(),
  subscriptionAssets: makeGetSubscriptionAssets(),
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
});

const UpgradeOrderT = connect(mapStateToProps, {
  getSubscriptionByAccountId,
  getSubscriptionAssets,
  docreateOrder: createOrder,
  getServiceUnitsBySubscriptionId,
  getDependentOffers,
  getBundleByBundleId,
  getPackageByPackageId,
  getCcpPropertiesConfig,
  buildOrderProvisioningAttributes,
  getCustomAttributesByObjectType,
})(UpgradeOrder);

export default withTranslation('common')(UpgradeOrderT);
