export const GET_AR_REASON_CODE_REVENUE = 'GET_AR_REASON_CODE_REVENUE';
export const MODIFY_AR_REASON_CODE_REVENUE = 'MODIFY_AR_REASON_CODE_REVENUE';
export const CREATE_AR_REASON_CODE_REVENUE = 'CREATE_AR_REASON_CODE_REVENUE';
export const SEARCH_REVENUE_MILESTONE = 'SEARCH_REVENUE_MILESTONE';
export const SEARCH_REVENUE_MILESTONE_SUCCESS = 'SEARCH_REVENUE_MILESTONE_SUCCESS';
export const GET_REVENUE_MILESTONE_BY_ID = 'GET_REVENUE_MILESTONE_BY_ID';
export const GET_REVENUE_MILESTONE_BY_ID_SUCCESS = 'GET_REVENUE_MILESTONE_BY_ID_SUCCESS';
export const MODIFY_REVENUE_MILESTONE = 'MODIFY_REVENUE_MILESTONE';
export const CREATE_REVENUE_MILESTONE = 'CREATE_REVENUE_MILESTONE';
export const SEARCH_EXCHANGE_RATE = 'SEARCH_EXCHANGE_RATE';
export const SEARCH_EXCHANGE_RATE_SUCCESS = 'SEARCH_EXCHANGE_RATE_SUCCESS';
export const GET_EXCHANGE_RATE_BY_ID = 'GET_EXCHANGE_RATE_BY_ID';
export const GET_EXCHANGE_RATE_BY_ID_SUCCESS = 'GET_EXCHANGE_RATE_BY_ID_SUCCESS';
export const MODIFY_EXCHANGE_RATE = 'MODIFY_EXCHANGE_RATE';
export const CREATE_EXCHANGE_RATE = 'CREATE_EXCHANGE_RATE';
export const EXPORT_EXCHANGE_RATE_BY_ID = 'EXPORT_EXCHANGE_RATE_BY_ID';
export const EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS = 'EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS';
export const GET_LOCATION = 'GET_LOCATION';
export const MODIFY_LOCATION = 'MODIFY_LOCATION';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const GET_ENTERPRISE = 'GET_ENTERPRISE';
export const MODIFY_ENTERPRISE = 'MODIFY_ENTERPRISE';
export const CREATE_ENTERPRISE = 'CREATE_ENTERPRISE';
export const GET_DIVISION = 'GET_DIVISION';
export const MODIFY_DIVISION = 'MODIFY_DIVISION';
export const CREATE_DIVISION = 'CREATE_DIVISION';
export const GET_LEGAL_ENTITY = 'GET_LEGAL_ENTITY';
export const MODIFY_LEGAL_ENTITY = 'MODIFY_LEGAL_ENTITY';
export const CREATE_LEGAL_ENTITY = 'CREATE_LEGAL_ENTITY';
export const GET_BUSINESS_UNITS = 'GET_BUSINESS_UNITS';
export const MODIFY_BUSINESS_UNITS = 'MODIFY_BUSINESS_UNITS';
export const CREATE_BUSINESS_UNITS = 'CREATE_BUSINESS_UNITS';
export const GET_GL_SETUP = 'GET_GL_SETUP';
export const MODIFY_GL_SETUP = 'MODIFY_GL_SETUP';
export const CREATE_GL_SETUP = 'CREATE_GL_SETUP';
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const MODIFY_DEPARTMENT = 'MODIFY_DEPARTMENT';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const GET_COST_CENTER = 'GET_COST_CENTER';
export const MODIFY_COST_CENTER = 'MODIFY_COST_CENTER';
export const CREATE_COST_CENTER = 'CREATE_COST_CENTER';
export const GET_CALENDAR = 'GET_CALENDAR';
export const MODIFY_CALENDAR = 'MODIFY_CALENDAR';
export const CREATE_CALENDAR = 'CREATE_CALENDAR';
export const GET_COA = 'GET_COA';
export const MODIFY_COA = 'MODIFY_COA';
export const CREATE_COA = 'CREATE_COA';
export const GET_CONFIG_ACCOUNTING_RULES = 'GET_CONFIG_ACCOUNTING_RULES';
export const MODIFY_CONFIG_ACCOUNTING_RULES = 'MODIFY_CONFIG_ACCOUNTING_RULES';
export const CREATE_CONFIG_ACCOUNTING_RULES = 'CREATE_CONFIG_ACCOUNTING_RULES';
export const GET_CHART_OF_ACCOUNT_REVENUE = 'GET_CHART_OF_ACCOUNT_REVENUE';
export const MODIFY_CHART_OF_ACCOUNT_REVENUE = 'MODIFY_CHART_OF_ACCOUNT_REVENUE';
export const CREATE_CHART_OF_ACCOUNT_REVENUE = 'CREATE_CHART_OF_ACCOUNT_REVENUE';
export const GET_COST_CENTER_MAP = 'GET_COST_CENTER_MAP';
export const SAVE_COST_CENTER_MAP = 'SAVE_COST_CENTER_MAP';
export const SEARCH_ALL_GL_ACCOUNTS = 'SEARCH_ALL_GL_ACCOUNTS';
export const SEARCH_ALL_GL_ACCOUNTS_SUCCESS = 'SEARCH_ALL_GL_ACCOUNTS_SUCCESS';
export const SEARCH_CONFIG_ACCOUNTING_RULES = 'SEARCH_CONFIG_ACCOUNTING_RULES';
