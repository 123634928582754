import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t, colums }) => [...colums.map(cl => t(cl.label))];

const convertJson2Pdf = ({ data, t, title, fileName, colums, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, colums }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      if (item && typeof item === 'object') {
        if (colums && colums.length) {
          colums.forEach(cl => {
            if (cl.isRenderT) {
              newItem.push(getLabelOptions({ item, t, fieldName: cl.name, fieldOption: cl.keyOptions }));
            }
            if (cl.isBool) {
              newItem.push(item[cl.name] ? 'TRUE' : item[cl.name] === false ? 'FALSE' : '');
            }
            if (!cl.isBool && !cl.isRenderT) {
              if (item[cl.name] && typeof item[cl.name] === 'number') {
                newItem.push(formatMoneyValueToExport(item[cl.name]));
              } else newItem.push(item[cl.name] ? item[cl.name] : '');
            }
          });
        }
      }
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 5, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
