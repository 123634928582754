// adjustments
export const GET_AROPS_CONFIG_BY_TYPE = 'app/arhub/getArOpsConfigByType';
export const UPDATE_AROPS_CONFIG_BY_TYPE = 'app/arhub/update_arops_config_by_type';
// adjustments
export const SEARCH_ADJUSTMENTS = 'app/arhub/search_adjustments';
export const SEARCH_ADJUSTMENTS_SUCCESS = 'app/arhub/search_adjustments_success';
export const SEARCH_ADJUSTMENTS_FAILURE = 'app/arhub/search_adjustments_failure';

export const SET_PARAMS_ADJUSTMENTS = 'app/arhub/set_params_adjustments';

export const PROCESS_ADJUSTMENT = 'app/arhub/process_adjustment';
export const PROCESS_ADJUSTMENT_SUCCESS = 'app/arhub/process_adjustment_success';
export const PROCESS_ADJUSTMENT_FAILURE = 'app/arhub/process_adjustment_failure';

export const GET_ADJUSTMENT_DETAIL = 'app/arhub/get_adjustment_detail';
export const GET_ADJUSTMENT_DETAIL_SUCCESS = 'app/arhub/get_adjustment_detail_success';
export const GET_ADJUSTMENT_DETAIL_FAILED = 'app/arhub/get_adjustment_detail_failed';

// disputes
export const SEARCH_DISPUTES = 'app/arhub/search_disputes';
export const SEARCH_DISPUTES_SUCCESS = 'app/arhub/search_disputes_success';
export const SEARCH_DISPUTES_FAILURE = 'app/arhub/search_disputes_failure';

export const SET_PARAMS_DISPUTES = 'app/items/set_params_disputes';

export const PROCESS_DISPUTE = 'app/arhub/process_dispute';

export const GET_DISPUTE_DETAIL = 'app/arhub/get_dispute_detail';
export const GET_DISPUTE_DETAIL_SUCCESS = 'app/arhub/get_dispute_detail_success';
export const GET_DISPUTE_DETAIL_FAILED = 'app/arhub/get_dispute_detail_failed';

export const PROCESS_SETTLEMENT = 'app/arhub/process_settlement';
export const PROCESS_SETTLEMENT_SUCCESS = 'app/arhub/process_settlement_success';
export const PROCESS_SETTLEMENT_FAILURE = 'app/arhub/process_settlement_failure';

// writeoffs
export const SEARCH_WRITE_OFFS = 'app/arhub/search_write_offs';
export const SEARCH_WRITE_OFFS_SUCCESS = 'app/arhub/search_write_offs_success';
export const SEARCH_WRITE_OFFS_FAILURE = 'app/arhub/search_write_offs_failure';

export const SET_PARAMS_WRITE_OFFS = 'app/items/set_params_write_offs';

export const GET_WRITE_OFFS_DETAIL = 'app/arhub/get_write_offs_detail';
export const GET_WRITE_OFFS_DETAIL_SUCCESS = 'app/arhub/get_write_offs_detail_success';
export const GET_WRITE_OFFS_DETAIL_FAILED = 'app/arhub/get_write_offs_detail_failed';
export const PROCESS_REVERSE_WRITE_OFF = 'app/arhub/process_reverse_write_off';
export const PROCESS_WRITE_OFF = 'app/arhub/process_write_off';

export const GET_AR_ITEM_CONFIG = 'app/arhub/GET_AR_ITEM_CONFIG';
export const MODIFY_AR_ITEM_CONFIG = 'app/arhub/MODIFY_AR_ITEM_CONFIG';
export const CREATE_AR_ITEM_CONFIG = 'app/arhub/CREATE_AR_ITEM_CONFIG';

export const GET_AR_REASON_CODE = 'app/arhub/GET_AR_REASON_CODE';
export const MODIFY_AR_REASON_CODE = 'app/arhub/MODIFY_AR_REASON_CODE';
export const CREATE_AR_REASON_CODE = 'app/arhub/CREATE_AR_REASON_CODE';

export const GET_GROUPS_REASON = 'app/arhub/GET_GROUPS_REASON';
export const MODIFY_GROUPS_REASON = 'app/arhub/MODIFY_GROUPS_REASON';
export const CREATE_GROUPS_REASON = 'app/arhub/CREATE_GROUPS_REASON';

export const GET_AR_THRESHOLDS = 'app/arhub/GET_AR_THRESHOLDS';
export const MODIFY_AR_THRESHOLDS = 'app/arhub/MODIFY_AR_THRESHOLDS';
export const CREATE_AR_THRESHOLDS = 'app/arhub/CREATE_AR_THRESHOLDS';
export const GET_INVOICE_LINES_BY_ID = 'app/arhub/GET_INVOICE_LINES_BY_ID';
export const SEARCH_BULK_ADJUSTMENT_UPLOAD_STATS = 'app/arhub/SEARCH_BULK_ADJUSTMENT_UPLOAD_STATS';
export const SEARCH_BULK_ACTION_STATS = 'app/arhub/SEARCH_BULK_ACTION_STATS';
export const SEARCH_CLOUD_FILE_UPLOAD_DATA = 'app/arhub/SEARCH_CLOUD_FILE_UPLOAD_DATA';
export const GET_UNBILLED_TRANSACTION_SUMMARY = 'app/arhub/GET_UNBILLED_TRANSACTION_SUMMARY';
export const SEARCH_AR_OPS_REQUESTS = 'app/arhub/SEARCH_AR_OPS_REQUESTS';
export const GET_AR_OPS_REQUEST_BY_ID = 'app/arhub/GET_AR_OPS_REQUEST_BY_ID';
