import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getLogoBase64 } from '../../localStorages';
import { bodyToHeaderFontPdfFile, getLabelOptions } from '../utils';

const stylesSpace = {
  content: '',
  styles: {
    height: 0,
    minCellHeight: 0,
    cellPadding: 0,
    fillColor: 'white',
  },
};

const pdfConfig = ({
  title,
  fileName,
  fontSize,
  cellPadding,
  isFile,
  lineWidth,
  cellWidth,
  item,
  t,
  detailsFields,
  installmentDetailsColumns,
  invoicesInInstallmentColumns,
  installmentPaymentsColumns,
  ccpTime,
}) => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'portrait'; // portrait or landscape
  const doc = jsPDF(orientation, unit, size);

  const margin = { left: 20, right: 20, top: 2, bottom: 1 };

  const headStyles = {
    fillColor: '#00A651',
    textColor: '#FFFFFF',
    fontSize: 11,
  };
  const content = {
    startY: 60,
    head: [],
    body: [],
    styles: {
      fontSize: fontSize || 6,
      textColor: '#000000',
      cellPadding: cellPadding || 10,
      lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles: {
      fillColor: '#00A651',
      textColor: '#ffffff',
    },
    didDrawPage: data => {
      if (getLogoBase64()) {
        doc.addImage(getLogoBase64(), 'JPEG', data.settings.margin.left, 8, 60, 30);
      }
    },
  };
  const accountDetails = {
    startY: 10,
    body: [
      [{ content: `${t('label.accountId')}: ${item.accountId}` }],
      [
        {
          content: `${t('label.customerName')}: ${
            item?.contacts && item?.contacts[0] ? `${item?.contacts[0].firstName} ${item?.contacts[0].lastName}` : ''
          }`,
        },
      ],
      [
        {
          content: `${t('label.emailId')}: ${item?.contacts && item?.contacts[0] ? item?.contacts[0].email : ''}`,
        },
      ],
      [
        {
          content: `${t('label.phone')}: ${
            item?.contacts && item?.contacts[0] && item?.contacts[0].phones && item?.contacts[0]?.phones[0]?.number
              ? item?.contacts[0].phones[0].number
              : ''
          }`,
        },
      ],
    ],
    margin: { left: 420, right: 5, top: 20 },
    theme: 'plain',
    styles: {
      fontSize: 8,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles: {
      fillColor: '#00A651',
      textColor: '#ffffff',
    },
  };

  const headerInstallment = {
    head: [[`${t('label.installmentId')} - ${item.id || ''}`]],
    body: [],
    margin,
    theme: 'plain',
    styles: {
      fontSize: 13,
      textColor: '#000000',
      cellPadding: cellPadding || 0,
      lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles: {
      // fillColor: '#d5d8e0',
      // textColor: '#36383d',
      fontSize: 11,
    },
  };

  const installmentDefaultData = installmentDetailsColumns.map(val =>
    val
      ? {
          content: t(val.label),
          styles: bodyToHeaderFontPdfFile,
        }
      : ''
  );
  const spaceInstallmentDefaultData = installmentDetailsColumns.map(() => stylesSpace);

  const bodyInstallmentDetails = {
    head: [installmentDetailsColumns.map((val, index) => (index === 0 ? t('label.installmentDetails') : ''))],
    margin,
    body: item?.data
      ? [
          spaceInstallmentDefaultData,
          installmentDefaultData,
          ...item?.data.map(val => {
            return installmentDetailsColumns.map(i => {
              if (i.fieldOption) {
                return getLabelOptions({ item: val, t, fieldName: i.name, fieldOption: i.fieldOption });
              }
              return val ? val[i.name] : '';
            });
          }),
        ]
      : [installmentDefaultData],
    styles: {
      fontSize: 9,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles,
  };

  const installmentInvoiceDefaultData = invoicesInInstallmentColumns.map(val => ({
    content: t(val.label),
    styles: bodyToHeaderFontPdfFile,
  }));
  const spaceInvoiceInstallmentDefaultData = invoicesInInstallmentColumns.map(() => stylesSpace);

  const bodyInvoicesInInstallment = {
    head: [invoicesInInstallmentColumns.map((val, index) => (index === 0 ? t('label.invoicesInInstallment') : ''))],
    margin,
    body: item?.invoices
      ? [
          spaceInvoiceInstallmentDefaultData,
          installmentInvoiceDefaultData,
          ...item?.invoices.map(val => {
            return invoicesInInstallmentColumns.map(item => {
              if (item.fieldOption) {
                return getLabelOptions({ item: val, t, fieldName: item.name, fieldOption: item.fieldOption });
              }
              return val ? val[item.name] : '';
            });
          }),
        ]
      : [installmentInvoiceDefaultData],
    styles: {
      fontSize: 9,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      // lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles,
  };

  const installmentPaymentsData = installmentPaymentsColumns.map(val =>
    val
      ? {
          content: t(val.label),
          styles: bodyToHeaderFontPdfFile,
        }
      : ''
  );
  const spaceInstallmentPaymentsData = installmentPaymentsColumns.map(() => stylesSpace);

  const bodyPaymentsData = {
    head: [installmentPaymentsColumns.map((val, index) => (index === 0 ? t('label.payments') : ''))],
    margin,
    body: item?.payments
      ? [
          spaceInstallmentPaymentsData,
          installmentPaymentsData,
          ...item?.payments.map(val => {
            return installmentPaymentsColumns.map(i => {
              if (i.fieldOption) {
                return getLabelOptions({ item: val, t, fieldName: i.name, fieldOption: i.fieldOption });
              }
              return val ? val[i.name] : '';
            });
          }),
        ]
      : [installmentPaymentsData],
    styles: {
      fontSize: 9,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      lineWidth: lineWidth || 0,
      cellWidth,
    },
    headStyles,
  };

  const newDataFields = [];
  if (detailsFields && detailsFields.length) {
    detailsFields.forEach((val, index) => {
      if (index < detailsFields.length - 1) {
        newDataFields.push(val);
        newDataFields.push('');
      } else {
        newDataFields.push(val);
      }
    });
  }

  const details = {
    // startY: 40,
    body: [
      newDataFields.map(val => {
        if (!val) return '';
        return `${t(val.label)}`;
      }),
      newDataFields.map(val => {
        if (!val) return '';
        return { content: `${item[val.name]}`, styles: { halign: 'center', lineWidth: 1 } };
      }),
    ],
    // margin: { left: 10, right: 5, top: 0 },
    theme: 'plain',
    styles: {
      fontSize: 10,
      textColor: '#000000',
      cellPadding: cellPadding || 1,
      lineWidth: lineWidth || 0,
    },
    headStyles: {
      // fillColor: '#00A651',
      // textColor: '#ffffff',
    },
  };

  const signature = {
    // startY: 510,
    head: ['', '', '', ''],
    body: [
      [{ content: '____________________________________', styles: { padding: { top: 300 } } }],
      [{ content: `${t('label.signature')}` }],
    ],
    margin: { left: 340, right: 5, top: 400 },
    theme: 'plain',
    styles: {
      fontSize: 9,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      lineWidth: lineWidth || 0,
      cellWidth,
      halign: 'center',
      margin: { top: 400 },
    },
    headStyles: {
      // fillColor: '#00A651',
      // textColor: '#ffffff',
    },
  };

  const date = {
    // startY: 530,
    body: [[{ content: `${t('label.date')}: ${ccpTime || ''}` }]],
    margin: { left: 20, right: 5, top: 300 },
    theme: 'plain',
    styles: {
      fontSize: 9,
      textColor: '#000000',
      cellPadding: cellPadding || 3,
      lineWidth: lineWidth || 0,
      cellWidth,
      margin: { top: 300 },
    },
    headStyles: {
      fillColor: '#00A651',
      textColor: '#ffffff',
    },
  };
  doc.text(title, 200, 40);
  doc.autoTable(accountDetails);
  doc.autoTable(content);
  doc.autoTable(headerInstallment);
  doc.autoTable(details);
  // doc.autoTable(headerInstallmentDetails);
  doc.autoTable(bodyInstallmentDetails);
  // doc.autoTable(headerInvoicesInInstallment);
  doc.autoTable(bodyInvoicesInInstallment);
  doc.autoTable(bodyPaymentsData);
  doc.autoTable(signature);
  doc.autoTable(date);
  if (isFile) {
    const file = doc.output('blob');
    return file;
  }

  doc.save(fileName);
};

export default pdfConfig;
