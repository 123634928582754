import gql from 'graphql-tag';

export default () => gql`
  query {
    getConfigUsageType(dummy: "") {
      id
      type
      configUsageTypeService {
        serviceType
        status
        index
        configUsageTypeList {
          usagetype
          description
          status
          index
        }
      }
    }
  }
`;
