import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [])}`
    : '';
  const searchProvisioningAttributesConfig = `searchProvisioningAttributesConfig(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchProvisioningAttributesConfig} {
        id
        name
        description
        attributesList {
          index
          name
          value
          defaultValue
          isPricingValueStatic
          attributesLovs {
            index
            code
            description
          }
        }
      }
    }
  `;
};
