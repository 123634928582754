import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { cloneDeep, map, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import PageTitle from '../../components/PageTitle';
import FormCollapse from '../../components/form/FormCollapse';
import { collapsibleMenuProfile, dataFiledAddress, dataFiledContact } from './constant';
import { ModalUploadFile, GenericInput } from '../../components/common';
import { getUserById, modifyUser } from './actions';
import { makeGetUserById } from './selectors';
import { getBase64 } from '../../utils/utils';
import { getS3Buctket, readFileFromS3, uploadMultiPartFiles, onReloadAvatar } from '../App/actions';
import { PasswordForm, AddressForm, ContactForm } from '../../components/ManageProfileHub';
import Sidebar from './Sidebar';
import { setAvatarBase64 } from '../../localStorages';

class ManageProfile extends PureComponent {
  state = {
    activeTab: {
      name: 'Manage User Profile',
      isActive: true,
    },
    isShowFieldPassword: false,
    userProfile: {},
    payloadModify: {},
    isOpenModalUpload: false,
    itemSelect: null,
    base64FileSelect: null,
    fileSelect: null,
    fileUploaded: null,
  };

  componentDidMount() {
    const { getUserById } = this.props;
    getUserById();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userDetails) {
      this.setState({ userProfile: nextProps.userDetails });
    }
  }

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onTogglePassword = () => {
    const { isShowFieldPassword } = this.state;
    this.setState({ isShowFieldPassword: !isShowFieldPassword });
  };

  onHandleChange = ({ name, value }) => {
    const { userProfile, payloadModify } = this.state;
    userProfile[name] = value;
    payloadModify[name] = value;
    this.setState({ ...userProfile, payloadModify });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { modifyUser } = this.props;
    const { payloadModify, userProfile, profilePicturePath, fileUploaded } = this.state;
    const newPayload = cloneDeep(payloadModify);
    newPayload.userId = userProfile.userId || null;
    newPayload.profilePicturePath = profilePicturePath || null;
    if (fileUploaded) {
      getBase64(fileUploaded, result => {
        onReloadAvatar(result);
        setAvatarBase64(result);
      });
    }
    modifyUser(newPayload);
  };

  onHandleChangeAddress = ({ name, value }) => {
    const { userProfile, payloadModify } = this.state;
    const newData = cloneDeep(userProfile);
    if (!newData.address || !newData.address.length) newData.address = [{}];
    newData.address[0][name] = value;
    if (!payloadModify.address || !payloadModify.address[0]) payloadModify.address = [{}];
    payloadModify.address[0][name] = value;
    this.setState({ userProfile: newData, payloadModify });
  };

  onHandleChangeContact = ({ name, value }) => {
    const { userProfile, payloadModify } = this.state;
    const newData = cloneDeep(userProfile);
    if (!newData.contact || !newData.contact.length) newData.contact = [{ phones: [] }];
    newData.contact[0][name] = value;
    if (!payloadModify.contact || !payloadModify.contact[0]) payloadModify.contact = [{}];
    payloadModify.contact[0][name] = value;
    this.setState({ userProfile: newData, payloadModify });
  };

  onChangePhone = ({ value, fieldName, index, isNewPhone = false, isRemovePhone = false }) => {
    const { userProfile, payloadModify } = this.state;
    const { modifyUser } = this.props;
    const newData = cloneDeep(userProfile);
    if (!newData.contact || !newData.contact.length) newData.contact = [{ phones: [] }];
    if (isNewPhone) {
      const typePhoneArr = ['MOBILE', 'WORK', 'HOME', 'FAX'];
      map(newData.contact[0].phones, item => {
        const index = typePhoneArr.indexOf(item.type);
        typePhoneArr.splice(index, 1);
      });
      if (isEmpty(typePhoneArr)) {
        return toast('No unique type phone remaining', { type: toast.TYPE.ERROR });
      }
      newData.contact[0].phones[newData.contact[0].phones.length] = {
        number: null,
        type: typePhoneArr[0],
      };
    }
    if (isRemovePhone) {
      if (newData.contact[0].phones.length > 1) {
        try {
          const payloadRemovePhone = {
            userId: userProfile.userId || null,
            contact: [{ phones: [{ type: newData.contact[0].phones[index].type }] }],
          };
          modifyUser(payloadRemovePhone);
        } catch (err) {
          // console.log('err', err);
        }
        newData.contact[0].phones.splice(index, 1);
      } else {
        toast('Phones can not empty', { type: toast.TYPE.ERROR });
      }
    }
    if (!isEmpty(newData.contact[0].phones) && !isNewPhone && !isRemovePhone) {
      newData.contact[0].phones[index][fieldName] = value;
      if (!payloadModify.contact || !payloadModify.contact[0]) payloadModify.contact = [{}];
      payloadModify.contact[0].phones = newData.contact[0].phones;
    }
    return this.setState({ userProfile: newData, ...payloadModify });
  };

  onToggleModalUpload = path => {
    const { readFileFromS3, s3Config } = this.props;
    if (path) {
      readFileFromS3({ ...s3Config, url: path }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            this.setState({ base64FileSelect: result });
          });
          this.setState({ isOpenModalUpload: true, itemSelect: path, fileSelect: data });
        }
      });
    } else {
      this.setState({ isOpenModalUpload: true, itemSelect: path });
    }
  };

  onHandleUpload = ({ files }) => {
    if (files && files[0]) {
      const { uploadMultiPartFiles, id } = this.props;
      const query =
        '{"query": "mutation{uploadMultipartFiles(input: {category: USER_PROFILE, userProfileType: USER_PROFILE_PICTURE}){absoluteFile}}"}';
      const formData = new FormData();
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          if (res.data.data && res.data.data.data && res.data.data.data.uploadMultipartFiles) {
            try {
              const pathSelect = res.data.data.data.uploadMultipartFiles[0];
              this.setState({
                fileUploaded: files[0],
                profilePicturePath: pathSelect.absoluteFile,
              });
            } catch (error) {
              console.log(error);
              this.setState({
                fileUploaded: files[0],
                profilePicturePath: null,
              });
            }
          }
          this.setState({
            isOpenModalUpload: false,
          });
          // setFileUploaded(files[0]);
          // setOpenModalUpload(false);
          // setisUploaded(true);
        }
      });
    } else {
      this.setState({ isOpenModalUpload: false });
    }
  };

  renderItem = item => {
    const { isShowFieldPassword, userProfile } = this.state;
    switch (item.state) {
      case 'Manage User Profile':
        return (
          <div className="col-md-12 row">
            <PasswordForm
              isShowFieldPassword={isShowFieldPassword}
              data={userProfile}
              onChange={this.onHandleChange}
              onTogglePassword={this.onTogglePassword}
              wrapperClass="col-md-12"
            >
              <GenericInput
                key={item.name}
                label="label.profilePicture"
                value={userProfile.profilePicturePath || null}
                onChange={this.handleInputChange}
                fa="fa fa-external-link"
                wrapperClass="col-md-4"
                readOnly
                onClick={() => this.onToggleModalUpload(userProfile.profilePicturePath)}
              />
            </PasswordForm>
          </div>
        );
      case 'User Address':
        return (
          <AddressForm
            data={userProfile.address && userProfile.address[0] ? userProfile.address[0] : {}}
            onChange={this.onHandleChangeAddress}
            dataFiledAddress={dataFiledAddress}
          />
        );
      case 'User Contacts':
        return (
          <ContactForm
            dataFiledContact={dataFiledContact}
            data={userProfile.contact && userProfile.contact[0] ? userProfile.contact[0] : {}}
            onChange={this.onHandleChangeContact}
            onChangePhone={this.onChangePhone}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { activeTab, isOpenModalUpload, itemSelect, base64FileSelect, fileSelect } = this.state;
    const { t } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <PageTitle
              linkTo={RouteNames.dashboard.path}
              titleBtn={t('common:label.back')}
              items={[
                { name: t('label.profile'), url: RouteNames.manageProfile.path },
                { name: t('label.manageProfile') },
              ]}
            />
            <br />
            <form className="col-sm-12" onSubmit={this.onHandleSubmit} noValidate>
              {collapsibleMenuProfile.map(item => (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={item.state === activeTab.name && activeTab.isActive}
                  title={item.title}
                  state={item.state}
                  onToggleTab={this.onToggleTab}
                >
                  {this.renderItem(item)}
                </FormCollapse>
              ))}
              <div className="form-group col-md-12 btn-modify">
                <button type="submit" className="button x-small float-right">
                  {t('label.modify')}
                </button>
              </div>
            </form>
            <Footer className="footer-bottom" />
          </div>
        </div>
        <ModalUploadFile
          title="label.uploadFile"
          isOpen={isOpenModalUpload}
          onCancel={() => this.setState({ isOpenModalUpload: false })}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          base64FileSelect={base64FileSelect}
          fileSelect={fileSelect}
          // isDisabledUpload={!!base64FileSelect}
          type="IMAGE"
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userDetails: makeGetUserById() || {},
});

const withConnect = connect(mapStateToProps, {
  getUserById,
  modifyUser,
  getS3Buctket,
  readFileFromS3,
  uploadMultiPartFiles,
  onReloadAvatar,
});

ManageProfile.propTypes = {
  userDetails: PropTypes.objectOf(PropTypes.any),
  modifyUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
};

ManageProfile.defaultProps = {
  userDetails: {},
};

const withReducer = injectReducer({ key: 'manageProfileReducer', reducer });
const withSaga = injectSaga({ key: 'manageProfileSaga', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(ManageProfile));
