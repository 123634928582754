import React, { Component } from 'react';
// import jsreport from 'jsreport-browser-client-dist';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import XMLViewer from 'react-xml-viewer';
// import { tokenReport } from '../../../config/token';
// import serverReport from '../../../config/serverReport';
import PageTitle from '../../../components/PageTitle';
import { getInvoiceById, getTenantInvoiceTemplates, getNotesUnitById } from '../actions';
import { getBase64, splitF } from '../../../utils/utils';
import {
  getAccountDetail,
  getS3Buctket,
  readFileFromS3,
  getAwsS3Credential,
  getObjectFileById,
} from '../../App/actions';
import { makeGetS3Config } from '../../App/selectors';
import { makeErrorMessage, makeGetInvoiceTemplate } from '../selectors';

let fileSelect = null;
let pthSelect = null;

class InvoiceLayout extends Component {
  constructor() {
    super();
    this.state = {
      contentReportUrl: null,
      base64FilePdf: null,
      base64FileHtml: null,
      base64File: null,
      isHtml: false,
      fileData: null,
      isXML: false,
      xmlData: null,
    };
    this.dataInvoice = null;
  }

  componentDidMount() {
    const { getAwsS3Credential, s3Config } = this.props;
    if (!s3Config || !s3Config.accessKey) {
      getAwsS3Credential('', config => this.doGetTenantIvoice(config));
    } else {
      this.doGetTenantIvoice({ data: s3Config });
    }
  }

  doGetTenantIvoice = config => {
    const {
      match: {
        params: { id },
      },
      location: { state },
      getTenantInvoiceTemplates,
      getObjectFileById,
    } = this.props;
    getTenantInvoiceTemplates('', () => {
      if (state && state.fileData && state.fileData.length) {
        let pdfPath = '';
        const pdfData = state.fileData.find(val => val.fileType === 'PDF');
        if (pdfData) {
          pdfPath = pdfData.path;
          this.doReadFileFromS3({ config, path: pdfPath });
        }
        this.setState({ fileData: state.fileData });
      } else {
        getObjectFileById(id, data => {
          let pdfPath = '';
          if (data && data.length) {
            const pdfData = data.find(val => val.fileType === 'PDF');
            if (pdfData) {
              pdfPath = pdfData.path;
            }
            this.setState({ fileData: data });
          }
          if (pdfPath) this.doReadFileFromS3({ config, path: pdfPath });
        });
      }
    });
  };

  doReadFileFromS3 = ({ config, path }) => {
    const { readFileFromS3 } = this.props;
    readFileFromS3({ ...config.data, url: path }, ({ success, data }) => {
      if (success) {
        const { isXML } = this.state;
        fileSelect = data;
        pthSelect = path;
        if (isXML) {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({ xmlData: reader.result });
          };
          reader.readAsText(fileSelect);
        }
        getBase64(data, result => {
          this.setState({ base64File: result });
        });
      }
    });
  };

  handleClickButton = path => {
    const { s3Config } = this.props;
    this.doReadFileFromS3({ path, config: { data: s3Config } });
  };

  downloadFile = (theBlob, fileName) => {
    saveAs(theBlob, fileName);
  };

  render() {
    const {
      errorMessage,
      t,
      location: { state },
    } = this.props;
    if (errorMessage) {
      return (
        <div className="m-t">
          <p className="txt-error">{errorMessage}</p>
        </div>
      );
    }
    const { fileData, base64FilePdf, base64FileHtml, isHtml, base64File, isXML, xmlData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper invoice-page">
            <div className="pb-2">
              <PageTitle
                linkTo={{ pathname: state && state.fromPath ? state.fromPath : '', state }}
                titleBtn={t('common:label.back')}
                items={[{ name: t('common:label.invoice') }]}
              />
            </div>
            {fileData && fileData.length && (
              <div className="group-btn-invoice text-right mr-3" style={{ width: '85%' }}>
                {fileData.map(val => (
                  <button
                    type="button"
                    className="button x-small"
                    onClick={() => {
                      this.handleClickButton(val.path || null);
                      if (val.fileType === 'XML') {
                        this.setState({ isXML: true });
                      } else {
                        this.setState({ isXML: false });
                      }
                    }}
                  >
                    {val.fileType}
                  </button>
                ))}
                <button
                  type="button"
                  className="button x-small"
                  onClick={() => this.downloadFile(fileSelect, splitF(pthSelect))}
                >
                  {t('label.download')}
                </button>
              </div>
            )}
            {/* <div className="group-btn-invoice text-right mr-3" style={{ width: '85%' }}>
              <button type="button" className="button x-small" onClick={() => this.handleClickButton(0)}>
                HTML
              </button>
              <button type="button" className="button x-small" onClick={() => this.handleClickButton(1)}>
                PDF
              </button>
            </div> */}
            {!isXML && (
              <iframe
                type="application/pdf"
                src={base64File || (isHtml ? base64FileHtml : base64FilePdf)}
                title="report_view"
                style={{ width: '85%', minHeight: '640px' }}
              />
            )}
            {isXML && (
              <div className="xml-viewer" style={{ width: '85%', minHeight: '740px', overflow: 'auto' }}>
                <XMLViewer xml={xmlData} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
InvoiceLayout.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  getInvoiceById: PropTypes.func,
  getAccountDetail: PropTypes.func,
  getTenantInvoiceTemplates: PropTypes.func,
  getNotesUnitById: PropTypes.func,
  errorMessage: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
  invoiceTemplates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};
InvoiceLayout.defaultProps = {
  match: {},
  getInvoiceById: () => {},
  getAccountDetail: () => {},
  getTenantInvoiceTemplates: () => {},
  getNotesUnitById: () => {},
  errorMessage: '',
  invoiceTemplates: [],
  location: {},
};
const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
  invoiceTemplates: makeGetInvoiceTemplate() || [],
  s3Config: makeGetS3Config() || {},
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getInvoiceById,
    getAccountDetail,
    getTenantInvoiceTemplates,
    getNotesUnitById,
    getS3Buctket,
    readFileFromS3,
    getAwsS3Credential,
    getObjectFileById,
  })(InvoiceLayout)
);
