import gql from 'graphql-tag';

export default serviceType => gql`
  query {
    getConfigOperatorByServiceType(input: { serviceType: ${serviceType} }) {
      id
      serviceType
      configOperatorMapList {
        id
        index
        operatorId
        operatorName
        status
      }
    }
  }
`;
