import React, { Component } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import InvoiceLayout from './Layouts';
import saga from './saga';
import reducer from './reducer';

class InvoiceRoute extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <Route exact path="/invoices/:id" component={InvoiceLayout} />
        <Route exact path="/credit-notes/:id" component={InvoiceLayout} />
        <Route exact path="/view-file/:id" component={InvoiceLayout} />
      </Switch>
    );
  }
}

const withSaga = injectSaga({ key: 'invoiceRouteSaga', saga });
const withReducer = injectReducer({ key: 'invoiceReducer', reducer });

export default compose(withReducer, withSaga)(InvoiceRoute);
