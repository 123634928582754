import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputGroup from '../InputGroup';
import DateGroup from '../DateGroup';
import DateTimeGroup from '../DateTimeGroup';
import CheckGroup from '../CheckGroup';
import Switch from '../Switch';
import SelectGroup from '../SelectGroup';
import MonthYearGroup from '../MonthYearGroup';
import CreatableSelectGroup from '../CreatableSelectGroup';
import MultiSelectGroup from '../MultiSelectGroup';
import YearGroup from '../YearGroup';
import NumberInputGroup from '../NumberInputGroup';
import NumberInputGroupHelper from '../NumberInputGroupHelper';

const GenericInput = ({
  type,
  id,
  name,
  onChange,
  isCreateOption,
  isPlaceholder,
  isManualValue,
  isMulti,
  isNegativeValue,
  ...props
}) => {
  const { t } = useTranslation('selections');
  const inputId =
    id ||
    `${type}-${Math.random()
      .toString()
      .replace('.', '')}`;
  // const onPaste = e => {
  //   e.persist();
  //   let avalue = e.clipboardData.getData('Text');
  //   avalue = avalue ? avalue.trim() : null;
  //   if (onChange) {
  //     setTimeout(() => {
  //       onChange({ name, value: avalue, event: e });
  //     }, 4);
  //   }
  // };
  if (type === 'select') {
    const options = props && props.tOptions ? [...t(props.tOptions)()] : props.options;
    const defaultValueFromOption = options && options.length ? options.find(val => val.value === props.value) : null;
    if (isMulti) {
      return (
        <MultiSelectGroup
          {...props}
          name={name}
          id={inputId}
          placeholder={isPlaceholder ? props.placeholder : null}
          options={options}
          // value={
          //   !isManualValue
          //     ? props && props.tOptions && t
          //       ? [...t(props.tOptions)()].find(e => e.value === props.value)
          //       : props.options && props.options.find(e => e.value === props.value)
          //     : {
          //         label: props.value,
          //         value: props.value,
          //       }
          // }
          isDisabled={props.disabled}
          onChange={e => onChange({ name, value: e ? e.value : '' })}
        />
      );
    }
    return isCreateOption ? (
      <CreatableSelectGroup
        {...props}
        name={name}
        id={inputId}
        placeholder={null}
        options={options}
        value={props.value ? defaultValueFromOption || { label: props.value, value: props.value } : null}
        isDisabled={props.disabled}
        onChange={e => onChange({ name, value: e ? e.value : '' })}
        isNoClearable={props.isNoClearable || (props.tOptions && props.tOptions === 'selections:selectBool')}
      />
    ) : (
      <SelectGroup
        {...props}
        name={name}
        id={inputId}
        placeholder={isPlaceholder ? props.placeholder : null}
        options={options}
        value={
          !isManualValue
            ? props && props.tOptions && t
              ? [...t(props.tOptions)()].find(e => e.value === props.value)
              : props.options && props.options.find(e => e.value === props.value)
            : {
                label: props.value,
                value: props.value,
              }
        }
        isDisabled={props.disabled}
        onChange={e => onChange({ name, value: e ? e.value : '' })}
        isNoClearable={
          props.isNoClearable ||
          (props.tOptions && props.tOptions === 'selections:selectBool' && !props.isNeedClearable)
        }
      />
    );
  }
  if (type === 'date') {
    return (
      <DateGroup
        {...props}
        name={name}
        id={inputId}
        value={props.value}
        onChange={e => {
          onChange({ name, value: e });
        }}
      />
    );
  }
  if (type === 'datetime') {
    return (
      <DateTimeGroup
        {...props}
        name={name}
        id={inputId}
        value={props.value}
        onChange={e => onChange({ name, value: e })}
      />
    );
  }

  if (type === 'monthYear') {
    return (
      <MonthYearGroup
        {...props}
        name={name}
        id={inputId}
        value={props.value}
        onChange={e => onChange({ name, value: e })}
      />
    );
  }

  if (type === 'year') {
    return (
      <YearGroup {...props} name={name} id={inputId} value={props.value} onChange={e => onChange({ name, value: e })} />
    );
  }
  if (type === 'checkbox') {
    return (
      <CheckGroup
        {...props}
        name={name}
        id={inputId}
        checked={props.value}
        onClick={e => onChange({ name, value: !props.value, event: e })}
      />
    );
  }

  if (type === 'switch') {
    return (
      <Switch
        onChange={e => onChange({ name, value: !props.value, event: e })}
        name={name}
        title={t(props.label)}
        checked={props.value}
        {...props}
        wrapperClass={props.wrapperClass || 'col-md-3 mt-3 pl-1'}
      />
    );
  }

  if (type === 'numberFormat') {
    return (
      <NumberInputGroup
        {...props}
        name={name}
        id={inputId}
        onChange={e => {
          if (type === 'number' && e.value && Number.parseFloat(e.value) < 0) {
            return '';
          }
          if (type === 'number' && props.is2Decimal && e.value && Number.parseFloat(e.value * 100) % 1 !== 0) {
            return '';
          }
          onChange({ name, value: e.value, event: e });
        }}
      />
    );
  }

  if (type === 'number') {
    return (
      <NumberInputGroupHelper
        type={type}
        {...props}
        name={name}
        id={inputId}
        value={props.value || props.value === 0 || props.value === '0' ? props.value : ''}
        isNegativeValue={isNegativeValue}
        onChange={e => {
          if (type === 'number' && e.target.value && Number.parseFloat(e.target.value) < 0 && !isNegativeValue) {
            return '';
          }
          if (
            type === 'number' &&
            props.minValue &&
            props.maxValue &&
            e.target.value &&
            (Number.parseFloat(e.target.value) < 0 ||
              Number.parseFloat(e.target.value) < props.minValue ||
              Number.parseFloat(e.target.value) > props.maxValue)
          ) {
            return '';
          }
          if (
            type === 'number' &&
            props.minValue &&
            e.target.value &&
            (Number.parseFloat(e.target.value) < 0 || Number.parseFloat(e.target.value) < props.minValue)
          ) {
            return '';
          }
          if (
            type === 'number' &&
            e.target.value &&
            props.isInteger &&
            (Number.parseFloat(e.target.value) % 1 !== 0 || e.target.value.indexOf('.') !== -1)
          ) {
            return '';
          }
          if (
            type === 'number' &&
            props.is2Decimal &&
            e.target.value &&
            Number.parseFloat(e.target.value * 100) % 1 !== 0
          ) {
            return '';
          }
          onChange({ name, value: e.target.value, event: e });
        }}
      />
    );
  }

  if (type === 'showLabelOption') {
    const options = t(props.tOptions)();
    const { value, ...rest } = props;
    let newValue = value;
    const valueOption = options.find(op => op.value === props.value);
    if (valueOption) newValue = valueOption.label;
    return <InputGroup value={newValue || null} type={type} {...rest} name={name} id={inputId} onChange={() => {}} />;
  }

  return (
    <InputGroup
      type={type}
      {...props}
      name={name}
      id={inputId}
      value={props.value || props.value === 0 || props.value === '0' ? props.value : null}
      onChange={e => {
        if (type === 'number' && e.target.value && Number.parseFloat(e.target.value) < 0) {
          return '';
        }
        if (
          type === 'number' &&
          props.is2Decimal &&
          e.target.value &&
          Number.parseFloat(e.target.value * 100) % 1 !== 0
        ) {
          return '';
        }
        if (props.isNoEnter && e && (e.keyCode === 13 || e.nativeEvent.inputType === 'insertLineBreak')) {
          return false;
        }
        if (props.isNullValue) {
          return onChange({ name, value: e.target.value || null, event: e });
        }
        onChange({ name, value: e.target.value, event: e });
      }}
    />
  );
};

GenericInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

GenericInput.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
};
export default GenericInput;
