import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType', 'status'])}` : '';
  const searchDetailRecords = `searchDetailRecords(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchDetailRecords} {
        usageId
        batchId
        startDate
        endDate
        accountId
        serviceUnitId
        provisioningId
        usageType
        usageClass
        itemId
        apartyNumber
        bpartyNumber
        cpartyNumber
        npaId
        quantity
        downloadVolume
        uploadVolume
        direction
        errorCode
        errorMsg
        sourceSystem
        recordCount
        serviceType
        ratingProvisioningId
        incomingRoute
        outgoingRoute
        connectionName
        operatorId
        flexattr1
        flexattr2
        flexattr3
        flexattr4
        flexattr5
        flexattr6
        flexattr7
        flexattr8
        flexattr9
        flexattr10
      }
    }
  `;
};
