import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class AccountCollectionSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.accountId',
      name: 'accountId',
    },
    {
      label: 'common:label.clientId',
      name: 'clientId',
    },
    {
      label: 'common:label.organization',
      name: 'organization',
    },
    {
      label: 'common:label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusCollections',
      type: 'select',
      isClearable: true,
    },
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.collectionEntryDateMoreThan',
      name: 'startDate',
      type: 'date',
      wrapperClass: 'col-md-3',
    },
    {
      label: 'common:label.collectionEntryDateLessThan',
      name: 'endDate',
      type: 'date',
      wrapperClass: 'col-md-3',
    },
    {
      label: 'common:label.accountCategory',
      name: 'accountCategory',
      tOptions: 'selections:accountCategory',
      type: 'select',
    },
  ];

  btnSpan = 'col-md-9';
}

AccountCollectionSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountCollectionSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountCollectionSearchForm);
