import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class ArOpsActivitySearchFormNoInvoiceId extends SearchComponent {
  searchInputs = [
    {
      label: 'label.type',
      name: 'type',
      type: 'select',
      tOptions: 'selections:arType',
      isClearable: true,
    },
    {
      label: 'label.arCrDrType',
      name: 'arCrDrType',
      type: 'select',
      tOptions: 'selections:arCrDrType',
      isClearable: true,
    },
    {
      label: 'label.reason',
      name: 'reason',
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'label.itemId',
      name: 'itemId',
    },
    {
      label: 'label.userId',
      name: 'userId',
    },
    {
      label: 'label.source',
      name: 'source',
      type: 'select',
      tOptions: 'selections:arSource',
      isClearable: true,
    },
    {
      label: 'label.arTaxRule',
      name: 'arTaxRule',
      type: 'select',
      tOptions: 'selections:taxRule',
      isClearable: true,
    },
    {
      label: 'label.action',
      name: 'action',
      type: 'select',
      tOptions: 'selections:arAction',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

ArOpsActivitySearchFormNoInvoiceId.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ArOpsActivitySearchFormNoInvoiceId.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(ArOpsActivitySearchFormNoInvoiceId);
