import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['evaluationComponent'])}` : '';
  const searchDiscountTriggers = `searchDiscountTriggers(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchDiscountTriggers} {
        id
        name
        evaluationComponent
        comparisonOperator
        accumulatorId
        usageContainerField
        value
      }
    }
  `;
};
