import gql from 'graphql-tag';

export default () => {
  return gql`
    query {
      getItemServiceProcessStats(dummy: "") {
        status
        fileName
        successCount
        failureCount
        pendingCount
        totalCount
        fileUploadDate
      }
    }
  `;
};
