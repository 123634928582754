import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t }) => [
  t('label.operatorName'),
  t('label.date'),
  t('label.capacity'),
  t('label.commitment'),
  t('label.tariff'),
  t('label.rental'),
  t('label.quantity'),
  t('label.directCommitment'),
  t('label.directTariff'),
  t('label.directCost'),
  t('label.commitmentLeft'),
  t('label.overageQuantity'),
  t('label.overageCost'),
  t('label.totalCost'),
];

const convertJson2Pdf = ({ data, t, title, fileName, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.operatorName ? item.operatorName : '');
      newItem.push(item.lineDate ? item.lineDate : '');
      newItem.push(
        item.contractCapacity || typeof item.contractCapacity === 'number'
          ? formatMoneyValueToExport(item.contractCapacity)
          : ''
      );
      newItem.push(
        item.commitmentBase || typeof item.commitmentBase === 'number'
          ? formatMoneyValueToExport(item.commitmentBase)
          : ''
      );
      newItem.push(item.tariff || typeof item.tariff === 'number' ? formatMoneyValueToExport(item.tariff) : '');
      newItem.push(
        item.rentalAmount || typeof item.rentalAmount === 'number' ? formatMoneyValueToExport(item.rentalAmount) : ''
      );
      newItem.push(
        item.totalQuantity || typeof item.totalQuantity === 'number' ? formatMoneyValueToExport(item.totalQuantity) : ''
      );
      newItem.push(
        item.directCommitment || typeof item.directCommitment === 'number'
          ? formatMoneyValueToExport(item.directCommitment)
          : ''
      );
      newItem.push(
        item.directTariff || typeof item.directTariff === 'number' ? formatMoneyValueToExport(item.directTariff) : ''
      );
      newItem.push(
        item.totalCostDirect || typeof item.totalCostDirect === 'number'
          ? formatMoneyValueToExport(item.totalCostDirect)
          : ''
      );
      newItem.push(
        item.commitmentLeft || typeof item.commitmentLeft === 'number'
          ? formatMoneyValueToExport(item.commitmentLeft)
          : ''
      );
      newItem.push(
        item.overageQuantity || typeof item.overageQuantity === 'number'
          ? formatMoneyValueToExport(item.overageQuantity)
          : ''
      );
      newItem.push(
        item.overageCost || typeof item.overageCost === 'number' ? formatMoneyValueToExport(item.overageCost) : ''
      );
      newItem.push(
        item.totalCost || typeof item.totalCost === 'number' ? formatMoneyValueToExport(item.totalCost) : ''
      );
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 5, ...rest });
};

export default convertJson2Pdf;
