import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';

import { makeErrorMessage } from '../selectors';
import { selectItemsId } from '../../App/actions';

import ModalSelector from '../../../components/Modals/ModalSelector';
import { ParentSearchForm } from '../../../components/PricingHub';

class ModalParentPricing extends Component {
  state = {};

  render() {
    const { t, defaultSearchParams, defaultSearchForm } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'company',
          label: 'common:label.company',
          sortName: 'company',
        },

        {
          name: 'status',
          label: 'common:label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={t('label.chooseItemId')}
        isOpen={this.props.isOpen}
        SearchForm={ParentSearchForm}
        searchItems={this.props.selectItemsId}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectedParentId={this.props.selectedParentId}
        defaultSearchParams={defaultSearchParams}
        defaultSearchForm={defaultSearchForm}
      />
    );
  }
}

ModalParentPricing.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  selectItemsId: PropTypes.func,
  errorMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
});

export default withTranslation('common')(connect(mapStateToProps, { selectItemsId })(ModalParentPricing));
