/**
 * Quotes management selectors
 */

import { createSelector } from 'reselect';

const selectQuotes = state => state.get('quoteReducer');

const makeGetListQuotes = () => createSelector(selectQuotes, quotes => [...quotes.get('listQuotes')]);

export { makeGetListQuotes };
