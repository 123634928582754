import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'orderType',
        'accountType',
        'billStatus',
        'minBillThreshold',
        'billType',
        'billingDom',
        'paymentMethod',
      ])}`
    : '';
  const getBillProductSummaryReport = `getBillProductSummaryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getBillProductSummaryReport} {
        id
        accountId
        productCompany
        productLine
        productFamily
        productGroup
        productSubGroup
        itemId
        itemName
        total
        accountType
        billType
        billStatus
        billingDom
        paymentMethod
      }
    }
  `;
};
