import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { GenericInput } from '../common';
import { getGLAccountConfig, getCurrencyConfigApp } from '../../containers/App/actions';

const configLabel = {
  ACCOUNT: 'label.glAccount',
};

const searchInputs = [
  {
    label: 'common:label.currency',
    name: 'currency',
    options: [],
    isClearable: true,
    type: 'select',
  },
  {
    label: 'common:label.accountingPeriod',
    name: 'accountingPeriod',
    type: 'monthYear',
    isClearable: true,
  },
  {
    label: 'label.startDateWithRankMoreThan',
    name: 'revenueStartDate',
    type: 'date',
  },
  {
    label: 'label.endDateWithRankLessThan',
    name: 'revenueEndDate',
    type: 'date',
  },
  {
    label: 'label.batchId',
    name: 'extractBatchId',
  },
  {
    label: 'common:label.fullyRecognized',
    name: 'fullyRecognized',
    type: 'select',
    tOptions: 'selections:selectBool',
    isClearable: true,
  },
  {
    label: 'common:label.revenueType',
    name: 'revenueType',
    type: 'select',
    tOptions: 'selections:revenueType',
  },
  {
    label: 'common:label.revenueRecognitionType',
    name: 'revenueRecognitionType',
    type: 'select',
    tOptions: 'selections:revenueRecognitionType',
  },
  {
    label: 'common:label.revenueActivityType',
    name: 'revenueActivityType',
    type: 'select',
    tOptions: 'selections:revenueActivityType',
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:revenueJournalStatus',
    isClearable: true,
    type: 'select',
  },
  {
    label: 'common:label.glSegment1',
    name: 'glSegment1',
    type: 'select',
  },
  {
    label: 'common:label.glSegment2',
    name: 'glSegment2',
    type: 'select',
  },
  {
    label: 'common:label.glSegment3',
    name: 'glSegment3',
  },
  {
    label: 'common:label.offsetAccount',
    name: 'offsetAccount',
    type: 'select',
    tOptions: 'selections:selectBool',
    isClearable: true,
  },
  {
    label: 'common:label.transactionType',
    name: 'transactionType',
    type: 'select',
    tOptions: 'selections:transactionType',
    isClearable: true,
  },
  {
    label: 'label.itemType',
    name: 'itemType',
    tOptions: 'selections:itemType',
    isClearable: true,
    type: 'select',
  },
  {
    label: 'label.itemId',
    name: 'itemId',
  },
  {
    label: 'label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.objectType',
    name: 'reportObjectType',
    type: 'select',
    tOptions: 'selections:revenueReportObjectType',
    isClearable: true,
  },
  {
    label: 'label.legalEntity',
    name: 'legalEntity',
  },
  {
    label: 'label.productCompany',
    name: 'productCompany',
  },
  {
    label: 'label.sellingCompany',
    name: 'sellingCompany',
  },
  {
    label: 'label.setOfBooks',
    name: 'setOfBooks',
  },
  {
    label: 'label.invoiceId',
    name: 'invoiceId',
  },
  {
    label: 'label.organization',
    name: 'organization',
  },
  {
    label: 'label.commercialName',
    name: 'commercialName',
  },
  // {
  //   label: 'label.objectType',
  //   name: 'reportObjectType',
  //   type: 'select',
  //   tOptions: 'selections:revenueReportObjectType',
  //   isClearable: true,
  // },
  {
    label: 'label.notesId',
    name: 'notesId',
  },
  {
    label: 'label.paymentId',
    name: 'paymentId',
  },
];
class JournalSearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalName: '',
      glSegment1Options: [],
      glSegment2Options: [],
      currencyOptions: [],
      glSegment1Name: '',
      glSegment2Name: '',
      glSegment3Name: '',
    };
  }

  componentDidMount() {
    this.setDefaultInputs();
    const { getGLAccountConfig, getCurrencyConfigApp } = this.props;
    const { currencyOptions } = this.state;
    getGLAccountConfig('', ({ success, data }) => {
      let glSegment1Options = [];
      let glSegment2Options = [];
      let glSegment1Name = '';
      let glSegment2Name = '';
      let glSegment3Name = '';

      if (success && data && data[0] && data[0].segments && data[0].segments[0] && data[0].segments[0].segmentValues) {
        glSegment1Options = data[0].segments[0].segmentValues.map(val => {
          return { label: `${val.code}-${val.name}`, value: val.code };
        });
      }
      if (success && data && data[0] && data[0].segments && data[0].segments[1] && data[0].segments[1].segmentValues) {
        glSegment2Options = data[0].segments[1].segmentValues.map(val => {
          return { label: `${val.code}-${val.name}`, value: val.code };
        });
      }
      if (success && data && data[0] && data[0].segments && data[0].segments[0]) {
        glSegment1Name = data[0].segments[0] ? data[0].segments[0].name : '';
      }
      if (success && data && data[0] && data[0].segments && data[0].segments[1]) {
        glSegment2Name = data[0].segments[1] ? data[0].segments[1].name : '';
      }
      if (success && data && data[0] && data[0].segments && data[0].segments[2]) {
        glSegment3Name = data[0].segments[2] ? data[0].segments[2].name : '';
      }
      this.setState({
        glSegment1Options: sortBy(glSegment1Options, ['label']),
        glSegment2Options: sortBy(glSegment2Options, ['label']),
        glSegment1Name,
        glSegment2Name,
        glSegment3Name,
      });
    });
    getCurrencyConfigApp('', ({ success, data }) => {
      if (success && data && data.currencyListConfig) {
        const currencyOptions = data.currencyListConfig.map(val => ({
          label: `${val.name} (${val.currencyId})`,
          value: val.currencyId,
        }));
        this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
      }
    });
  }

  setDefaultInputs = () => {
    searchInputs.map(input => this.setState({ [input.name]: null }));
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit } = this.props;
    const data = {};
    searchInputs.map(input => (this.state[input.name] ? (data[input.name] = this.state[input.name]) : null));
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    // const { onSubmit } = this.props;
    this.setDefaultInputs();
    // if (onSubmit) {
    //   onSubmit({});
    // }
  };

  onSelect = (value, row) => {
    const { modalName } = this.state;
    this.setState({ [modalName]: row.name, modalName: '' });
  };

  onOpenModal = ({ modalName }) => {
    this.setState({ modalName });
  };

  onToggleModal = () => {
    this.setState({ modalName: '' });
  };

  render() {
    const { isSubmitting, t } = this.props;
    const {
      glSegment1Options,
      glSegment2Options,
      currencyOptions,
      glSegment1Name,
      glSegment2Name,
      glSegment3Name,
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          {searchInputs.map(item => {
            if (item.name === 'glSegment1') {
              return (
                <GenericInput
                  key={item.name}
                  {...item}
                  label={configLabel[glSegment1Name] ? t(configLabel[glSegment1Name]) : glSegment1Name}
                  value={this.state[item.name]}
                  onChange={this.handleInputChange}
                  options={glSegment1Options || []}
                />
              );
            }
            if (item.name === 'glSegment2') {
              return (
                <GenericInput
                  key={item.name}
                  {...item}
                  label={configLabel[glSegment2Name] ? t(configLabel[glSegment2Name]) : glSegment2Name}
                  value={this.state[item.name]}
                  onChange={this.handleInputChange}
                  options={glSegment2Options || []}
                />
              );
            }
            if (item.name === 'glSegment3') {
              return (
                <GenericInput
                  key={item.name}
                  {...item}
                  label={configLabel[glSegment3Name] ? t(configLabel[glSegment3Name]) : glSegment3Name}
                  value={this.state[item.name]}
                  onChange={this.handleInputChange}
                />
              );
            }
            if (item.name === 'currency') {
              return (
                <GenericInput
                  key={item.name}
                  {...item}
                  value={this.state[item.name]}
                  onChange={this.handleInputChange}
                  options={currencyOptions || []}
                />
              );
            }
            return (
              <GenericInput
                key={item.name}
                {...item}
                isNeedClearable
                label={t && t(item.label) ? t(item.label) : item.label}
                value={this.state[item.name]}
                onChange={this.handleInputChange}
                onClick={item.fa ? () => this.onOpenModal({ modalName: item.name }) : () => {}}
              />
            );
          })}
          <div className="form-group text-right btn-search-actions col-md-12">
            <label htmlFor="validationCustom01se" />
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t('label.clear')}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t('label.search')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

JournalSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

JournalSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(
  connect(null, {
    getGLAccountConfig,
    getCurrencyConfigApp,
  })(JournalSearchForm)
);
