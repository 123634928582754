export default {
  sidebar: {
    // operator: 'Operator',
    operatorRecon: 'Operator Recon',
    recordLog: 'Op Record Log',
    invoice: 'Op Invoice',
    dispute: 'Op Dispute',
    tenant: 'Tenant',
    tenantDispute: 'Tenant Dispute',
    tenantRecordLog: 'Tenant Record Log',
    rerating: 'Rerating',
    config: 'Config',
    usageMapping: 'Usage Mapping',
    platforms: 'Platforms',
    reprocessingSuspendedFailedRecords: 'Reprocessing (Suspended/Failed Records)',
    testUsage: 'Test Usage',
    usageOps: 'Usage Ops',
    mediationSetup: 'Mediation Setup',
    operatorProcess: 'Operator Process',
    usageConfig: 'Usage Config',
  },
};
