import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['evaluationComponent', 'comparisonOperator']);
  const createDiscountTrigger = `createDiscountTrigger(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createDiscountTrigger} {
      id
      name
    }
  }
`;
};
