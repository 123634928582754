// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index']);
  const modifyGroupReasonCode = `modifyGroupReasonCode(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyGroupReasonCode} {
      id
    }
  }
`;
};
