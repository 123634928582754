import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, []);
  const deletePaymentFile = `deletePaymentFile(input: ${dataModify})`;
  return gql`
  mutation {
    ${deletePaymentFile}
  }
`;
};
