import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RouteNames from '../../App/RouteNames';
import { getPageTotalCount } from '../../../utils/utils';
import { DataTable, TablePagination, Breadcrumb, TitleFrom } from '../../../components/common';
import { SearchTopupOffers } from '../../../components/BaseConfigurationsHub';
import { makeGetPrepaidSystemOffersList } from '../selectors';
import { searchPrepaidSystemOffers, createPrepaidSystemOffers, modifyPrepaidSystemOffers } from '../actions';
import { searchTopupOffersColumns, prepaidSystemOffersSort, listTopupOffersFields } from '../constants';
import ModalTopupOffersForm from './ModalTopupOffersForm';
import ModalSearchPriceOffer from '../../../components/common/ModalSearchPriceOffer';
import {
  checkPermissionSearchTopupOffer,
  checkPermissionCreateTopupOffer,
  checkPermissionModifyTopupOffer,
} from '../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';

const defaultData = {
  id: null,
  topupOfferId: null,
  topupOfferName: null,
  paymentOfferId: null,
  paymentOfferName: null,
  burnRateOfferId: null,
  burnRateOfferName: null,
  serviceType: null,
};

class TopupOffers extends Component {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      filter: {},
      sorted: {},
      totalCount: null,
      isSearching: false,
      dataSubmit: {},
      prepaidSystemOffersList: [],
      fieldNameSelected: null,
      isModify: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.prepaidSystemOffersList) {
      return {
        totalCount: getPageTotalCount({
          ...state,
          totalCount: state.totalCount || props.prepaidSystemOffersList.length,
          items: props.prepaidSystemOffersList,
        }),
      };
    }
    return {};
  }

  componentDidMount() {
    this.doSearchTopupOffers();
  }

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchTopupOffers());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchTopupOffers());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchTopupOffers());
  };

  doSearchTopupOffers = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchPrepaidSystemOffers } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && prepaidSystemOffersSort[sorted.sortCol]
        ? prepaidSystemOffersSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    return searchPrepaidSystemOffers(params, ({ success, data }) => {
      this.setState({
        isSearching: false,
        page,
        size,
        filter,
        sort,
        prepaidSystemOffersList: success ? data || [] : [],
      });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchTopupOffers());
  };

  onToggleModalTopupOffers = ({ item, isModify }) => {
    const { isOpenModalTopupOffers } = this.state;
    this.setState({
      isOpenModalTopupOffers: !isOpenModalTopupOffers,
      dataSubmit: isOpenModalTopupOffers ? {} : cloneDeep(item),
      isModify,
    });
  };

  onClickItem = name => {
    this.setState({ fieldNameSelected: name });
  };

  onChangeData = ({ name, value }) => {
    const { dataSubmit } = this.state;
    dataSubmit[name] = value;
    this.setState({ dataSubmit: { ...dataSubmit } });
  };

  onSubmit = () => {
    const { modifyPrepaidSystemOffers, createPrepaidSystemOffers } = this.props;
    const { dataSubmit, isModify } = this.state;
    if (isModify) {
      return modifyPrepaidSystemOffers(dataSubmit, ({ success, data }) => {
        if (success && data) {
          const { prepaidSystemOffersList } = this.state;
          const indexItem = prepaidSystemOffersList.findIndex(val => val.id === data.id);
          if (indexItem || indexItem === 0) {
            prepaidSystemOffersList[indexItem] = data;
          }
          this.setState({
            fieldNameSelected: null,
            dataSubmit: {},
            prepaidSystemOffersList: [...prepaidSystemOffersList],
            isOpenModalTopupOffers: false,
            isModify: false,
          });
        }
      });
    }
    return createPrepaidSystemOffers(dataSubmit, (success, data) => {
      if (success && data) {
        this.doSearchTopupOffers();
        this.setState({
          fieldNameSelected: null,
          dataSubmit: {},
          isOpenModalTopupOffers: false,
          isModify: false,
        });
      }
    });
  };

  onRemoveItem = ({ item }) => {
    const { modifyPrepaidSystemOffers } = this.props;
    return modifyPrepaidSystemOffers({ id: item.id }, ({ success, data }) => {
      if (success && data) {
        const { prepaidSystemOffersList } = this.state;
        this.setState({
          fieldNameSelected: null,
          dataSubmit: {},
          prepaidSystemOffersList: [...prepaidSystemOffersList].filter(val => val.id !== item.id),
          isOpenModalTopupOffers: false,
          isModify: false,
        });
      }
    });
  };

  onSelectPricingOfferId = (id, row) => {
    const { fieldNameSelected, dataSubmit } = this.state;
    if (!id) return null;
    dataSubmit[fieldNameSelected] = id;
    if (fieldNameSelected === 'topupOfferId') {
      dataSubmit.topupOfferName = row.name;
    }
    if (fieldNameSelected === 'paymentOfferId') {
      dataSubmit.paymentOfferName = row.name;
    }
    if (fieldNameSelected === 'burnRateOfferId') {
      dataSubmit.burnRateOfferName = row.name;
    }
    return this.setState({ fieldNameSelected: null, dataSubmit });
  };

  render() {
    const { permissionsPricing, t, location } = this.props;
    const {
      sorted,
      isSearching,
      page,
      totalCount,
      size,
      filter,
      dataSubmit,
      isOpenModalTopupOffers,
      prepaidSystemOffersList,
      fieldNameSelected,
      isModify,
    } = this.state;

    let modeSearchTopupOffer = 0;
    let modeCreateTopupOffer = 0;
    let modeModifyTopupOffer = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeSearchTopupOffer = checkPermissionSearchTopupOffer({ listPermission: listPermissionPricing });
      modeCreateTopupOffer = checkPermissionCreateTopupOffer({ listPermission: listPermissionPricing });
      modeModifyTopupOffer = checkPermissionModifyTopupOffer({ listPermission: listPermissionPricing });
    }

    if (!modeSearchTopupOffer) return null;

    const tableColumns = [
      {
        name: 'id',
        label: 'label.id',
        render: (colName, item) => (
          <button
            type="button"
            className="text-success no-border"
            disabled={modeModifyTopupOffer === 1}
            onClick={() => this.onToggleModalTopupOffers({ item, isModify: true })}
          >
            {item.id}
          </button>
        ),
      },
      ...searchTopupOffersColumns,
      {
        name: 'action',
        label: 'label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.onToggleModalTopupOffers({ item, isModify: true })}
            disabled={modeModifyTopupOffer === 1}
          >
            {t ? t('label.view') : ''}
          </button>
        ),
      },
      {
        name: 'delete',
        label: 'label.delete',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            type="button"
            className="no-border"
            disabled={modeModifyTopupOffer === 1}
            onClick={() => this.onRemoveItem({ item })}
          >
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];

    if (modeModifyTopupOffer === 0) {
      tableColumns[0] = {
        name: 'id',
        label: 'Id',
      };
      tableColumns.splice(tableColumns.length - 2, 2);
    }

    const tableConfig = {
      columns: tableColumns,
      data: prepaidSystemOffersList,
      sorted: { ...sorted },
      onSort: this.onSortColumn,
    };

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              {modeCreateTopupOffer === 2 ? (
                <button
                  type="button"
                  className="button x-small"
                  onClick={() => this.onToggleModalTopupOffers({ item: defaultData, isModify: false })}
                  disabled={modeCreateTopupOffer === 1}
                >
                  +
                  {t('label.addNew')}
                </button>
              ) : (
                <Link
                  to={{
                    pathname: RouteNames.productFamilyPricing.path,
                    state: { filter },
                  }}
                  className="button x-small"
                >
                  {t('label.back')}
                </Link>
              )}
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('baseConfigurationsPage:sidebar.topupOffers') }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <TitleFrom title={t('baseConfigurationsPage:sidebar.topupOffers')} />
            <div className="card-body">
              <div className="pl-2">
                <SearchTopupOffers
                  onSubmit={this.onSubmitSearch}
                  defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} isLoading={isSearching} />
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            isLoading={isSearching}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalTopupOffersForm
          title={isModify ? t('label.modifyTopupOfferConfig') : t('label.newTopupOfferConfig')}
          listFields={listTopupOffersFields}
          data={dataSubmit}
          onChangeData={this.onChangeData}
          onClickItem={this.onClickItem}
          onSubmit={this.onSubmit}
          onToggleModal={this.onToggleModalTopupOffers}
          isOpenModal={isOpenModalTopupOffers}
          isModify={isModify}
        />
        <ModalSearchPriceOffer
          isOpen={!!fieldNameSelected}
          selectedParentId={fieldNameSelected ? dataSubmit[fieldNameSelected] || null : null}
          onSelect={this.onSelectPricingOfferId}
          onCancel={() => this.setState({ fieldNameSelected: null })}
          defaultSearchParams={{
            pricingType: 'SYSTEM',
          }}
        />
      </div>
    );
  }
}

TopupOffers.propTypes = {
  prepaidSystemOffersList: PropTypes.arrayOf(PropTypes.any),
  searchPrepaidSystemOffers: PropTypes.func.isRequired,
  createPrepaidSystemOffers: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
  modifyPrepaidSystemOffers: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
};

TopupOffers.defaultProps = {
  prepaidSystemOffersList: [],
  location: {},
  history: {},
  t: () => {},
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  prepaidSystemOffersList: makeGetPrepaidSystemOffersList() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

const newTopupOffers = connect(mapStateToProps, {
  searchPrepaidSystemOffers,
  createPrepaidSystemOffers,
  modifyPrepaidSystemOffers,
})(TopupOffers);

export default withTranslation(['common', 'taskPage'])(newTopupOffers);
