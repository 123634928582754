/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const LOGIN_REQUEST = 'LOGIN/REQUEST';
export const LOGIN_SUCCESS = 'LOGIN/SUCCESS';
export const LOGIN_FAILURE = 'LOGIN/FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT/REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT/SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT/FAILURE';

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_REQUEST_SUCCESS = 'LOAD_USER_REQUEST/SUCCESS';
export const LOAD_USER_REQUEST_FAILURE = 'LOAD_USER_REQUEST/FAILURE';

// =====================================
// old actions
// =====================================

export const SELECT_ITEMS_ID = 'app/select/select_items_id';
export const SELECT_PRICE_OFFER_ID = 'app/select/select_price_offer_id';
export const SELECT_BUNDLE_ID = 'app/select/select_bundle_id';
export const SELECT_PACKAGE_ID = 'app/select/select_package_id';
export const SELECT_ACCOUNT_ID = 'app/select/select_account_id';
export const SELECT_INVOICE_UNITS_ID = 'app/select/select_invoice_units_id';

export const GET_BUNDLE_BY_BUNDLE_ID = 'app/get_price_offer_by_bundle_id';
export const SELECT_BILL_UNITS_ID = 'app/select/select_bill_units_id';

export const GET_ACCOUNT_DETAIL = 'app/get_account_detail';
export const LOGOUT = 'app/LOGOUT';
export const CHECK_LOGIN_USER = 'app/CHECK_LOGIN_USER';
export const HANDLE_CHANGE_PASSWORD = 'app/HANDLE_CHANGE_PASSWORD';

// permission
export const GET_ROLE_AND_ROLE_GROUP_BY_ID = 'app/GET_ROLE_AND_ROLE_GROUP_BY_ID';
export const GET_ROLE_BY_ID = 'app/GET_ROLE_BY_ID';
export const GET_ROLE_ID_BY_ROLEGROUP = 'app/GET_ROLE_ID_BY_ROLEGROUP';
export const GET_PERMISSIONS_BY_ID_SUCCESS = 'app/GET_PERMISSIONS_BY_ID_SUCCESS';
export const REMOVE_PERMISSIONS = 'app/REMOVE_PERMISSIONS';

export const CHECK_AUTH = 'app/CHECK_AUTH';
export const SEARCH_USER_BY_TYPE_AGENT = 'app/SEARCH_USER_BY_TYPE_AGENT';
export const SEARCH_USER_BY_USER_ID_COLLECTION = 'app/SEARCH_USER_BY_USER_ID_COLLECTION';

export const GET_USAGE_CONTAINER_SPEC_CONFIG = 'app/GET_USAGE_CONTAINER_SPEC_CONFIG';

export const GET_UNIQUE_PRODUCT_FAMILY = 'app/GET_UNIQUE_PRODUCT_FAMILY';
export const GET_UNIQUE_PRODUCT_LINE = 'app/GET_UNIQUE_PRODUCT_LINE';
export const GET_UNIQUE_PRODUCT_SUB_TYPE = 'app/GET_UNIQUE_PRODUCT_SUB_TYPE';
export const GET_UNIQUE_PRODUCT_TYPE = 'app/GET_UNIQUE_PRODUCT_TYPE';

export const GET_ACCOUNT_ITEMS = 'app/select/GET_ACCOUNT_ITEMS';

export const SELECT_RATE_UNITS_ID = 'app/select/select_rate_units_id';
export const SELECT_TIME_UNITS_ID = 'app/select/select_time_units_id';
export const GET_TRANSACTION_UNIT_BY_ACCOUNT_ID = 'app/select/GET_TRANSACTION_UNIT_BY_ACCOUNT_ID';
export const SELECT_MILETSTONE_ID = 'app/select/SELECT_MILETSTONE_ID';
export const GET_CCP_PROPERTIES_CONFIG = 'app/select/GET_CCP_PROPERTIES_CONFIG';
export const GET_CCP_PROPERTIES_CONFIG_SUCESS = 'app/select/GET_CCP_PROPERTIES_CONFIG_SUCESS';

export const GET_PACKAGE_BY_PACKAGE_ID = 'app/select/GET_PACKAGE_BY_PACKAGE_ID';

export const SELECT_DISSCOUNT_OFFER_ID = 'app/select/SELECT_DISSCOUNT_OFFER_ID';

export const GET_S3_BUCKET = 'app/select/GET_S3_BUCKET';
export const GET_S3_BUCKET_SUCESS = 'app/select/GET_S3_BUCKET_SUCESS';
export const READ_FILE_FROM_S3 = 'app/select/READ_FILE_FROM_S3';
export const UPLOAD_MULTI_PART_FILE = 'app/select/UPLOAD_MULTI_PART_FILE';
export const SEARCH_CONFIG_OPERATOR_MAP_LIST = 'app/select/SEARCH_CONFIG_OPERATOR_MAP_LIST';
export const SEARCH_USERS_LIST = 'app/select/SEARCH_USERS_LIST';

export const GET_AR_REASON_CODE_SELECTION = 'app/select/GET_AR_REASON_CODE_SELECTION';
export const GET_USER_GROUPS_CONFIG = 'app/select/GET_USER_GROUPS_CONFIG';

export const SEARCH_OBJECT_APPROVALS = 'app/select/SEARCH_OBJECT_APPROVALS';
export const SEARCH_SERVICE_PROVISIONING_DATA = 'app/select/SEARCH_SERVICE_PROVISIONING_DATA';
export const GET_FLEX_ATTRIBUTE_MAPPING_CONFIG = 'app/select/GET_FLEX_ATTRIBUTE_MAPPING_CONFIG';

export const GET_DISTINCT_USAGE_TYPES = 'app/select/GET_DISTINCT_USAGE_TYPES';
export const GET_CCP_DATE_TIME_CONFIG = 'app/select/GET_CCP_DATE_TIME_CONFIG';
export const GET_CCP_DATE_TIME_CONFIG_SUCCESS = 'app/select/GET_CCP_DATE_TIME_CONFIG_SUCCESS';
export const GET_DISTINCT_ORGANIZATION = 'app/select/GET_DISTINCT_ORGANIZATION';

export const GET_AWS_S3_CREDENTIAL = 'app/select/GET_AWS_S3_CREDENTIAL';
export const MODIFY_ACCOUNT_SERVICE_LOCATION = 'app/select/MODIFY_ACCOUNT_SERVICE_LOCATION';
export const MODIFY_ACCOUNT_SERVICE = 'app/select/MODIFY_ACCOUNT_SERVICE';
export const GET_PAYMENT_ITEM_MAP_MODAL = 'app/select/GET_PAYMENT_ITEM_MAP_MODAL';

export const SEARCH_PAYMENT_BY_ACCOUNT = 'app/select/SEARCH_PAYMENT_BY_ACCOUNT';
export const GET_OBJECT_FILE_BY_ID = 'congero/invoices/GET_OBJECT_FILE_BY_ID';
export const GET_TENANT_DEFAULT_CONFIG = 'congero/app/GET_TENANT_DEFAULT_CONFIG';
export const GET_TENANT_DEFAULT_CONFIG_SUCCESS = 'congero/app/GET_TENANT_DEFAULT_CONFIG_SUCCESS';
export const SEARCH_ITEM_SERVICE = 'congero/app/SEARCH_ITEM_SERVICE';
export const REVERSE_PAYMENT_SERVICE = 'congero/app/REVERSE_PAYMENT_SERVICE';
export const ALLOCATE_PAYMENT_SERVICE = 'congero/app/ALLOCATE_PAYMENT_SERVICE';
export const GET_TAX_CODE_CONFIG_SELECT = 'congero/app/GET_TAX_CODE_CONFIG_SELECT';
export const GET_UOM_CONFIG = 'congero/app/GET_UOM_CONFIG';
export const GET_BILLABLE_SERVICE_PRICING = 'congero/app/GET_BILLABLE_SERVICE_PRICING';
export const MODIFY_BILLABLE_SERVICE = 'congero/app/MODIFY_BILLABLE_SERVICE';
export const GET_CURRENCY_CONFIG = 'congero/app/GET_CURRENCY_CONFIG';
export const GET_CURRENCY_CONFIG_SUCCESS = 'congero/app/GET_CURRENCY_CONFIG_SUCCESS';
export const GET_ORDER_BILLABLE_BY_INDEX_AND_ID = 'congero/app/GET_ORDER_BILLABLE_BY_INDEX_AND_ID';
export const RE_GENERATE_PAYMENT = 'congero/app/RE_GENERATE_PAYMENT';
export const GET_USAGE_TYPE_CONFIG = 'congero/app/GET_USAGE_TYPE_CONFIG';
export const GET_REGION_MAP_CONFIG_DATA = 'congero/app/GET_REGION_MAP_CONFIG_DATA';
export const GET_PROVISIONING_INSTANCE_DATA = 'app/select/GET_PROVISIONING_INSTANCE_DATA';
export const UPDATE_PROVISIONING_DATA = 'app/select/UPDATE_PROVISIONING_DATA';
export const UPDATE_PAYMENT_STATUS = 'app/select/UPDATE_PAYMENT_STATUS';
export const GET_GL_ACCOUNT_CONFIG = 'app/select/GET_GL_ACCOUNT_CONFIG';
export const GET_PAYMENT_FILE_CONFIG = 'congero/app/GET_PAYMENT_FILE_CONFIG';
export const GET_INVOICE_UNIT_BY_ID = 'congero/app/GET_INVOICE_UNIT_BY_ID';
export const GET_PAC_PRODUCT_CODES_CONFIG = 'app/select/GET_PAC_PRODUCT_CODES_CONFIG';
export const GET_PAYMENT_CONFIG_BY_TYPE = 'app/select/GET_PAYMENT_CONFIG_BY_TYPE';
export const GET_COLLECTION_AGENT_BY_TYPE = 'app/select/GET_COLLECTION_AGENT_BY_TYPE';
export const GET_COST_CENTER_CONFIG = 'app/select/GET_COST_CENTER_CONFIG';
export const GET_PENDING_ACCOUNT_MOVEMENT_DATA = 'app/select/GET_PENDING_ACCOUNT_MOVEMENT_DATA';
export const DELETE_PENDING_ACCOUNT_MOVEMENT_DATA = 'app/select/DELETE_PENDING_ACCOUNT_MOVEMENT_DATA';
export const GET_PAYMENT_ALLOCATION = 'app/select/GET_PAYMENT_ALLOCATION';
export const GET_CONFIG_OPERATOR_BY_SERVICE_TYPE = 'app/select/GET_CONFIG_OPERATOR_BY_SERVICE_TYPE';
export const GET_ACCOUNT_INFO = 'app/select/GET_ACCOUNT_INFO';
export const GET_PAYMENT_ACTIVITY = 'app/payment/GET_PAYMENT_ACTIVITY';
export const GET_ORDER_SUMMARY_BY_ACCOUNT_ID_CONFIG = 'app/payment/GET_ORDER_SUMMARY_BY_ACCOUNT_ID_CONFIG';
export const SAVE_MANUAL_INVOICE_CONFIG = 'app/payment/SAVE_MANUAL_INVOICE_CONFIG';
export const RE_PROCESS_INVOICE_CONFIG = 'app/payment/RE_PROCESS_INVOICE_CONFIG';
export const MODIFY_INVOICE_STATUS_CONFIG = 'app/payment/MODIFY_INVOICE_STATUS_CONFIG';
export const REGENERATE_INVOICE_CONFIG = 'app/payment/REGENERATE_INVOICE_CONFIG';
export const UNDO_INVOICE_CANCEL_CONFIG = 'app/payment/UNDO_INVOICE_CANCEL_CONFIG';
export const SEND_INVOICE_CONFIG = 'app/payment/SEND_INVOICE_CONFIG';
export const UNDO_MANUAL_INVOICE_CONFIG = 'app/payment/UNDO_MANUAL_INVOICE_CONFIG';
export const MODIFY_INVOICE_DUE_DATE_CONFIG = 'app/payment/MODIFY_INVOICE_DUE_DATE_CONFIG';
export const RESEND_INVOICE_VENDOR_CONFIG = 'app/payment/RESEND_INVOICE_VENDOR_CONFIG';
export const GET_NOTES_UNIT_BY_ID_CONFIG = 'app/payment/GET_NOTES_UNIT_BY_ID_CONFIG';
export const MODIFY_NOTES_UNIT_CONFIG = 'app/payment/MODIFY_NOTES_UNIT_CONFIG';
export const RESEND_NOTES_VENDOR_CONFIG = 'app/payment/RESEND_NOTES_VENDOR_CONFIG';
export const GET_BILLABLE_SERVICE_PRICING_HISTORY = 'app/payment/GET_BILLABLE_SERVICE_PRICING_HISTORY';
export const GET_PAC_INTERFACE_RECORD_BY_ID_CONFIG = 'app/payment/GET_PAC_INTERFACE_RECORD_BY_ID_CONFIG';
export const SEND_FOLIO = 'app/payment/SEND_FOLIO';
export const GET_INVOICE_INSTALLMENTS_BY_INVOICEID_CONFIG = 'app/payment/GET_INVOICE_INSTALLMENTS_BY_INVOICEID_CONFIG';
export const CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID_CONFIG =
  'app/payment/CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID_CONFIG';
export const GET_BILLABLE_SERVICE_PROVISIONING_ATTRIBUTES = 'app/payment/GET_BILLABLE_SERVICE_PROVISIONING_ATTRIBUTES';
export const GET_CONNFIG_USAGE_PLATFORM_LIST = 'app/payment/GET_CONNFIG_USAGE_PLATFORM_LIST';
export const GET_OUTPUT_TEMPLATE_BY_TYPE = 'app/payment/GET_OUTPUT_TEMPLATE_BY_TYPE';
export const SAVE_OUTPUT_TEMPLATE_BY_TYPE = 'app/payment/SAVE_OUTPUT_TEMPLATE_BY_TYPE';
export const BULK_PROCESS = 'app/payment/BULK_PROCESS';
export const GET_CONFIG_FAILURE_CODES = 'app/payment/GET_CONFIG_FAILURE_CODES';
export const GET_CONFIG_IDD = 'app/payment/GET_CONFIG_IDD';
export const GET_CONFIG_IDO = 'app/payment/GET_CONFIG_IDO';
export const GET_CONFIG_INCOMING_OUTGOING_ROUTE = 'app/payment/GET_CONFIG_INCOMING_OUTGOING_ROUTE';
export const GET_SPLIT_TAX_PER_LINE_CONFIG = 'app/payment/GET_SPLIT_TAX_PER_LINE_CONFIG';
export const GET_CONFIG_FAILURE_CODES_LIST = 'app/payment/GET_CONFIG_FAILURE_CODES_LIST';
export const GET_CONFIG_SRESERVED = 'app/payment/GET_CONFIG_SRESERVED';
export const GET_CONFIG_ORGANIZATION = 'app/payment/GET_CONFIG_ORGANIZATION';
export const SEARCH_PROVISIONING_ATTRIBUTES_CONFIG_ITEM = 'app/payment/SEARCH_PROVISIONING_ATTRIBUTES_CONFIG_ITEM';
export const ON_RELOAD_AVATAR = 'app/payment/ON_RELOAD_AVATAR';
export const SEARCH_DISCOUNT_OFFERS_SELECT = 'app/payment/SEARCH_DISCOUNT_OFFERS_SELECT';
export const SEARCH_DISCOUNT_TRIGGER_CONFIG = 'app/payment/SEARCH_DISCOUNT_TRIGGER_CONFIG';
export const GET_USER_BY_ID_DETAILS = 'app/user/GET_USER_BY_ID_DETAILS';
export const GET_API_BY_GETEWAY_NAME_CONFIG = 'app/user/GET_API_BY_GETEWAY_NAME_CONFIG';
export const GET_CLOUD_FILE_DATA_BY_NAME = 'GET_CLOUD_FILE_DATA_BY_NAME';
export const VIEW_BULK_ADJUSTMENT_UPLOAD_STATS = 'VIEW_BULK_ADJUSTMENT_UPLOAD_STATS';
export const SEARCH_CONFIG_CREDIT_PROFILE = 'SEARCH_CONFIG_CREDIT_PROFILE';

export const GET_ACCUMULATORS_CONFIG = 'app/pricinghub/GET_ACCUMULATORS_CONFIG';
export const GET_GRANTS_CONFIG = 'app/pricinghub/GET_GRANTS_CONFIG';
export const GET_DISCOUNT_OFFER_BY_ID = 'app/pricing/GET_DISCOUNT_OFFER_BY_ID';

export const SELECT_BUNDLE_ID_LIST = 'app/select/select_bundle_id_LIST';
export const SELECT_PACKAGE_ID_LIST = 'app/select/select_package_id_LIST';
export const SEARCH_COLLECTION_PROFILE_CONFIG = 'app/select/SEARCH_COLLECTION_PROFILE_CONFIG';
export const SELECT_BILL_INVOICE = 'app/select/SELECT_BILL_INVOICE';
export const GET_TAX_TYPE_CONFIG_SELECT = 'GET_TAX_TYPE_CONFIG_SELECT';
export const SEARCH_CHART_OF_ACCOUNT_CONFIG = 'app/select/SEARCH_CHART_OF_ACCOUNT_CONFIG';
export const SEARCH_ORDERS_CONFIG = 'SEARCH_ORDERS_CONFIG';
export const SEARCH_EMPLOYEE_COSTING_CONFIG = 'app/select/SEARCH_EMPLOYEE_COSTING_CONFIG';
export const GET_TEMPLATE_CONFIG = 'app/select/GET_TEMPLATE_CONFIG';
