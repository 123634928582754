/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getARReasonCodeSelectionConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';

class ModalReasonCodeConfig extends PureComponent {
  searchHanldeReasonCode = (payload, cb) => {
    const { getARReasonCodeSelectionConfig } = this.props;
    getARReasonCodeSelectionConfig(payload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'reasonCode',
          label: 'label.reasonCode',
        },
        {
          name: 'customReasonCode',
          label: 'label.customReasonCode',
        },
        {
          name: 'description',
          label: 'label.description',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseReasonCode')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeReasonCode}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="index"
      />
    );
  }
}

ModalReasonCodeConfig.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getARReasonCodeSelectionConfig: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalReasonCodeConfig.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getARReasonCodeSelectionConfig })(ModalReasonCodeConfig));
