import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['accountType', 'status', 'reason'])}` : '';
  const searchAccountReport = `searchAccountReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchAccountReport} {
        id
        company
        firstName
        lastName
        accountType
        country
        state
        city
        externalId
        email
        contactNumber
        zip
        reason
        status
        commercialName
        effectiveDate
        cancellationDate
        rfc
        customAccountType
      }
    }
  `;
};
