import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { ApolloConsumer } from 'react-apollo';
import TableFormService from './TableFormService';
import TableFormLine from './TableFormLine';
import { CardHeaderForm, ExpandTable, GenericInput } from '../common';
import LineTable from './LinesTable';

class ServiceEditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowHideLines: {},
    };
  }

  handleClickServicesFormBtn = index => {
    let { isShowHideLines } = this.state;
    if (isShowHideLines[index]) {
      isShowHideLines[index] = false;
    } else {
      isShowHideLines = {};
      isShowHideLines[index] = true;
    }
    this.setState({
      isShowHideLines: { ...isShowHideLines },
    });
  };

  render() {
    const {
      services,
      onChangeFormLine,
      handleClickServicesFormBtn,
      isOverrideLines,
      title,
      onChangeProvisioningId,
      isRemoveTrashFromLine,
      isRemoveTrashFromTable,
      onHandleRemoveItem,
      onRemoveLine,
      t,
      isModifyOrder,
      isDisabled,
      indexOverrideLine,
      onToggleModalShowProvisionig,
      ...rest
    } = this.props;
    const { isShowHideLines } = this.state;
    const subscriptionColumns = [];
    const newOrderColumn = [];

    const suspendResumeColumn = [];
    // if (isMultiSubscriptionEnabled) {
    //   subscriptionColumns.push(
    //     isReadMultiSubscriptionEnabled
    //       ? {
    //           name: 'subscriptionName',
    //           label: 'common:label.subscriptionName',
    //           className: 'fixed-width-table',
    //           required: true,
    //           render: (colName, item) => {
    //             return subscriptionName ? <span>{subscriptionName}</span> : <span>{item.subscriptionName || ''}</span>;
    //           },
    //         }
    //       : {
    //           name: 'subscriptionName',
    //           label: 'common:label.subscriptionName',
    //           style: { width: '180px' },
    //           required: true,
    //           render: (colName, item, index) => {
    //             return (
    //               <GenericInput
    //                 value={item.subscriptionName}
    //                 wrapperClass="col-md-12"
    //                 type="select"
    //                 onChange={e => onChangeData(index, e)}
    //                 name="subscriptionName"
    //                 options={
    //                   subscriptionsData && subscriptionsData.length
    //                     ? subscriptionsData.map(val => ({ label: val.subscriptionName, value: val.subscriptionName }))
    //                     : []
    //                 }
    //                 menuPortalTarget
    //                 required
    //               />
    //             );
    //           },
    //         }
    //   );
    // }

    const tableColumns = [
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        style: { width: '220px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              key="provisioningId"
              value={item.provisioningId}
              wrapperClass="col-md-12"
              type="text"
              onChange={({ name, value }) => onChangeProvisioningId({ name, value, item })}
              name="provisioningId"
              readOnly={isDisabled}
            />
          );
        },
      },
      {
        name: 'action',
        label: 'common:label.action',
        className: 'fixed-width-table',
      },
      {
        name: 'status',
        label: 'common:label.status',
        className: 'fixed-width-table',
        render: (colName, item, index, t) => {
          const slt = t
            ? t(isModifyOrder ? 'selections:subscriptionStatus' : 'selections:statusOrder')().find(
                val => val.value === item.status
              )
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
        className: 'fixed-width-table',
      },
      // {
      //   name: 'packageId',
      //   label: 'common:label.packageId',
      // },
      {
        name: 'packageName',
        label: 'common:label.packageName',
        className: 'fixed-width-table',
      },
      // {
      //   name: 'bundleId',
      //   label: 'common:label.bundleId',
      // },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
        className: 'fixed-width-table',
      },
      {
        name: 'alaCarte',
        label: 'common:label.alaCarte',
        className: 'fixed-width-table',
        render: (colName, item, index, t) => {
          return <span>{item.isDiscount ? t('label.discountOffers') : t('label.priceOffers')}</span>;
        },
      },
      {
        name: 'orderProvisioningIds',
        label: t('label.orderProvisioningIds'),
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                disabled={!item || !item.provisioningList}
                onClick={() => onToggleModalShowProvisionig({ item: item || null })}
              >
                {t('label.view')}
              </button>
            </div>
          );
        },
      },
      ...subscriptionColumns,
      {
        name: 'selected',
        // label: 'common:label.selected',
        label: '',
        style: { width: '100px' },
        render: (colName, item, index, t) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="button button-border  x-small"
                // disabled={item.action === 'CANCEL' || (isReadOnly && item.isDeafultService)}
                onClick={() => {
                  this.handleClickServicesFormBtn(item.indexInject);
                  // if (onHandleInitNewLines) onHandleInitNewLines({ item, index });
                }}
              >
                {isShowHideLines[item.indexInject] && t ? t('label.hideLines') : t('label.overrideLines')}
              </button>
            </div>
          );
        },
      },
      ...suspendResumeColumn,
      {
        name: 'remove',
        label: 'common:label.remove',
        className: 'fixed-width-table',
        render: (colName, item) => {
          return (
            <button
              type="button"
              className="btn-phone"
              disabled={isDisabled}
              onClick={() => {
                this.setState({ isShowHideLines: {} });
                onHandleRemoveItem(item, item.indexInject);
              }}
            >
              <i className="fa fa-trash" />
            </button>
          );
        },
      },
    ];

    return (
      <ApolloConsumer>
        {() => {
          // client => {
          // if (needFetch) {
          //   this.getName(client);
          // }

          const servicesGrpupBy = groupBy(services, item => {
            return item.serviceType;
          });
          return map(servicesGrpupBy, data => {
            if (data && !data.filter(val => size(val) > 5).length) {
              return null;
            }
            let slt = {};

            if (data && data.length) {
              slt = t('selections:serviceType')().find(val => val.value === data[0].serviceType);
            }

            const tableConfig = {
              columns: tableColumns,
              data,
              sorted: {},
              onSort: this.onSortColumn,
            };

            return (
              <div className="col-md-12 ">
                <CardHeaderForm
                  title={`${t('label.serviceType')}: ${slt ? slt.label : ''}`}
                  wrapperClass="font-weight-normal"
                  // label={`${t('label.services')}:`}
                  // value={slt ? slt.label : ''}
                  hideNavTab
                />
                <ExpandTable
                  {...tableConfig}
                  onClickRow={this.onClickRow}
                  supportExpandWithItem={isShowHideLines}
                  keySupport="indexInject"
                  // positionTrash={positionTrash}
                  isReadOnly={isDisabled}
                  tableClass="service-table"
                  isSupportRemoveIndex
                  sizeRemove={5}
                  expandRender={(item, index) => {
                    return (
                      <LineTable
                        key="TableFormLine"
                        service={item}
                        // isFetchLine={isFetchLine}
                        isRemoveTrashFromLine={isRemoveTrashFromLine}
                        // addLine={e => addLine(index, e)}
                        // positionTrash={positionTrash}
                        // isRenderTrashBinLineTable={isRenderTrashBinLineTable}
                        // onToggle={onToggle}
                        onRemoveLine={({ itemLine }) => onRemoveLine({ itemLine, indexOverrideLine: item.indexInject })}
                        onChangeFormLine={e => onChangeFormLine(item.indexInject, e)}
                        isReadOnly={isDisabled}
                        isModifyOrder={isModifyOrder}
                      />
                    );
                  }}
                />

                {/* {data &&
                  data.length > 0 &&
                  data.map(item => (
                    <>
                      <TableFormService
                        {...rest}
                        service={item}
                        handleClickServicesFormBtn={handleClickServicesFormBtn}
                        isOverrideLines={isOverrideLines}
                        bundleName={bundleName}
                        onChangeProvisioningId={onChangeProvisioningId}
                        onHandleRemoveItem={onHandleRemoveItem}
                        packageName={packageName}
                        isRemoveTrashFromTable={isRemoveTrashFromTable}
                        isDisabled={isDisabled}
                        isModifyOrder={isModifyOrder}
                      />
                      {indexOverrideLine === item.indexInject && (
                        <div className="form-group col-md-12">
                          <div className="card-body px-2">
                            <CardHeaderForm key="CardHeaderForm" title={t('label.line')} hideNavTab />
                            <TableFormLine
                              key="TableFormLine"
                              lines={item && item.lines ? item.lines || [] : []}
                              onChangeFormLine={onChangeFormLine}
                              onRemoveLine={({ itemLine }) =>
                                onRemoveLine({ itemLine, itemService: item, indexOverrideLine })
                              }
                              isRemoveTrashFromLine={isRemoveTrashFromLine}
                              isModifyOrder={isModifyOrder}
                              isReadOnly={isDisabled}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))} */}
              </div>
            );
          });
        }}
      </ApolloConsumer>
    );
  }
}

ServiceEditForm.propTypes = {
  title: PropTypes.string,
  isOverrideLines: PropTypes.bool,
  service: PropTypes.objectOf(PropTypes.any),
  onChangeFormLine: PropTypes.func,
  handleClickServicesFormBtn: PropTypes.func,
};

ServiceEditForm.defaultProps = {
  title: '',
  isOverrideLines: false,
  onChangeFormLine: () => {},
  handleClickServicesFormBtn: () => {},
  service: {},
};

export default withTranslation('common')(ServiceEditForm);
