import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ScaleLoader } from 'react-spinners';
import { RowContent, RowHeader } from './TableRow';

class DataTableWithExpandRow extends React.PureComponent {
  tableRef = React.createRef();

  componentDidMount() {
    this.injectJquery();
  }

  injectJquery = () => {
    const { fixedHeader } = this.props;
    if (!fixedHeader) return null;
    window.jQuery(this.tableRef.current).on('scroll', () => {
      window
        .jQuery(this.tableRef.current)
        .find('thead th')
        .css('transform', `translateY(${window.jQuery(this.tableRef.current).scrollTop()}px)`);
    });
  };

  onClickRow = (e, item) => {
    const { onClickRow } = this.props;
    if (onClickRow) {
      onClickRow(e, item);
    }
  };

  render() {
    const {
      fixedHeader,
      columns,
      data,
      rowClass,
      noDataMessage,
      getRowClass,
      sorted,
      onSort,
      tableClass,
      nullIsLoading,
      isLoading,
      indexParent, // Using when map & render table
      onCheckAll,
      dataExpand,
      listSwitchActive,
      t,
      ExpandRow,
      onClickTd,
      isFixedHeaderTable,
    } = this.props;
    return (
      <div
        className={`table-responsive mt-15 ${
          isFixedHeaderTable ? 'sticky-header' : 'sticky-header'
        } table-form ${tableClass} ${fixedHeader ? 'tableFixHead' : ''}`}
        ref={this.tableRef}
      >
        <table className="table center-aligned-table mb-0">
          <thead>
            <RowHeader
              columns={columns}
              sorted={sorted}
              onSort={onSort}
              isLoading={isLoading}
              onCheckAll={onCheckAll}
              t={t}
            />
          </thead>
          <tbody>
            {!isLoading &&
              data &&
              data.map((item, idx) => [
                <RowContent
                  key={`row-${idx}`}
                  columns={columns}
                  item={item}
                  onClickRow={(e, item) => this.onClickRow(e, item, idx)}
                  className={`${rowClass} ${
                    dataExpand && listSwitchActive && item.id === dataExpand.id ? 'acitve' : ''
                  }`}
                  getRowClass={getRowClass}
                  indexParent={indexParent}
                  indexItem={idx}
                  ExpandRow={ExpandRow}
                  onClickTd={onClickTd}
                />,
                <ExpandRow key={`key-${idx}`} item={item} idx={idx} />,
              ])}
            {(isLoading || !data || data.length === 0) && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  {!isLoading && !nullIsLoading && <span>{noDataMessage || t('label.noRecord')}</span>}
                  {(isLoading || ((!data || data.length === 0) && nullIsLoading)) && (
                    <ScaleLoader loading size={100} color="#28a745" />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

DataTableWithExpandRow.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  data: PropTypes.array.isRequired,
  rowClass: PropTypes.string,
  tableClass: PropTypes.string,
  noDataMessage: PropTypes.string,
  nullIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getRowClass: PropTypes.func,
  onCheckAll: PropTypes.func,
  onSort: PropTypes.func,
  fixedHeader: PropTypes.bool,
  indexParent: PropTypes.number,
};

DataTableWithExpandRow.defaultProps = {
  columns: [],
  sorted: {},
  data: [],
  rowClass: '',
  tableClass: '',
  getRowClass: null,
  isLoading: false,
  nullIsLoading: false,
  onSort: null,
  noDataMessage: '',
  fixedHeader: false,
  indexParent: 0,
  onCheckAll: () => {},
};

export default withTranslation('common')(DataTableWithExpandRow);
