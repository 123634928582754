import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GenericInput, CardHeaderForm } from '../common';
import PhoneGroup from '../PhoneGroup';

export default function OrderDetailCollapsibleForm({
  dataField,
  onHandleChange,
  onChangeCheckBox,
  onChangePhone,
  colItem,
  title,
  orderId,
  children,
  orderDetail,
  isDisabled,
}) {
  const { t } = useTranslation('common');
  return (
    <div className="group-flex pb-3">
      {!isEmpty(dataField) &&
        map(dataField, item => {
          if (item && item.isBool) {
            return (
              <GenericInput
                key={item.label}
                type="select"
                value={
                  orderDetail && orderDetail[item.fieldName]
                    ? 'TRUE'
                    : orderDetail && orderDetail[item.fieldName] === false
                    ? 'FALSE'
                    : null
                }
                wrapperClass={colItem}
                options={item.optionSelect}
                onChange={val => onHandleChange({ value: val.value, fieldName: item.fieldName })}
                name={item.fieldName}
                {...item}
                disabled={item.disabled || isDisabled}
              />
            );
          }
          if (item && (!isEmpty(item.optionSelect) || item.tOptions)) {
            return (
              <GenericInput
                key={item.label}
                type="select"
                value={!isEmpty(orderDetail) ? orderDetail[item.fieldName] : null}
                wrapperClass={colItem}
                options={item.optionSelect}
                onChange={val => onHandleChange({ value: val.value, fieldName: item.fieldName })}
                name={item.fieldName}
                {...item}
                disabled={item.disabled || isDisabled}
              />
            );
          }

          if (item && item.fieldDate) {
            return (
              <GenericInput
                type="date"
                key={item.label}
                disabled={item.disabled || isDisabled}
                wrapperClass={colItem}
                label={item.label || ''}
                name=""
                dateFormat="yyyy-MM-dd"
                value={!isEmpty(orderDetail) ? orderDetail[item.fieldDate] : null}
                onChange={e => onHandleChange({ value: e.value, fieldName: item.fieldDate })}
              />
            );
          }

          if (item && item.fieldPhone) {
            return (
              <PhoneGroup
                key={item.label}
                disabled={item.disabled || isDisabled}
                dataPhone={orderDetail[item.fieldPhone]}
                onChange={onChangePhone}
                colDiv="col-md-12"
              />
            );
          }
          if (item && item.checkBox) {
            return (
              <GenericInput
                type="checkbox"
                key={item.fieldName}
                label={item.label || ''}
                className="mt-4 pt-1 col-md-4"
                name={item.fieldName}
                checked={orderDetail[item.fieldName]}
                value={orderDetail[item.fieldName]}
                onChange={({ name, value }) => onChangeCheckBox({ fieldName: name, value })}
                disabled={item.disabled || isDisabled}
              />
            );
          }

          return (
            <GenericInput
              key={item.label}
              type={item.type || 'text'}
              disabled={item.disabled || isDisabled}
              value={orderDetail[item.fieldName] ? orderDetail[item.fieldName] : ''}
              label={item.label || ''}
              // placeholder={item.label || ''}
              onChange={evt => onHandleChange({ value: evt.value, fieldName: evt.name })}
              name={item.fieldName}
              wrapperClass={colItem}
            />
          );
        })}
      {children}
      {/* </div> */}
      {/* </div>
      </div> */}
    </div>
  );
}

OrderDetailCollapsibleForm.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  orderDetail: PropTypes.objectOf(PropTypes.any),
  onChangeCheckBox: PropTypes.func,
  onHandleChange: PropTypes.func,
  colItem: PropTypes.string,
  title: PropTypes.string,
  orderId: PropTypes.string || PropTypes.number,
  onChangePhone: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.any),
};

OrderDetailCollapsibleForm.defaultProps = {
  colItem: '',
  title: '',
  dataField: [],
  orderDetail: {},
  onChangeCheckBox: () => {},
  onHandleChange: () => {},
  orderId: '' || null,
  onChangePhone: () => {},
  children: null,
};
