import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getDistinctUsageTypes } from '../../../containers/App/actions';
import ModalMultipleSelector from '../../Modals/ModalMultipleSelector';

class ModalCallType extends PureComponent {
  searchHanldeLocation = (payload, cb) => {
    const { getDistinctUsageTypes } = this.props;
    getDistinctUsageTypes('', resp => {
      cb({ success: true, data: resp.data ? resp.data : [] });
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          label: 'label.callType',
          name: 'usageType',
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, t } = this.props;
    return (
      <ModalMultipleSelector
        modalTitle={t('label.callType')}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.searchHanldeLocation}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        isNotRenderPagination
        selectFieldName="usageType"
        isRequired={false}
        isMultiple
      />
    );
  }
}

ModalCallType.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getDistinctUsageTypes: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalCallType.defaultProps = {
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { getDistinctUsageTypes })(ModalCallType));
