import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataSearch = parseToMutationRequest(data, []);
  return gql`
  query {
    getBillableServicePricing(input: ${dataSearch}) {
      id
      orderId
      opportunityId
      omsInternalId
      serviceId
      accountId
      pricingType
      tierType
      serviceType
      startDate
      endDate
      orderDate
      tierPricing {
        index
        minimum
        maximum
        price
      }
      usageTypePricing {
        index
        name
        price
        currency
        uom
        network
      }
      limitPricing {
        commitment
        includedDID
        includedQuantity
        overage
        priceOverCommitment
        price
      }
      specialPricings {
        index
        npaId
        eightHundredFlag
        price
        npaCountry
      }
    }
  }
`;
};
