import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TableFormLine from './TableFormLine';
import { CardHeaderForm } from '../common';

class LinesTable extends React.PureComponent {
  render() {
    const { service, isFetchLine, t, ...props } = this.props;
    // const { needFetch, lines } = this.state;
    return (
      <div className="card-body px-2 w-100">
        <CardHeaderForm key="CardHeaderForm" title={t('label.line')} hideNavTab />
        <TableFormLine key="TableFormLine" lines={service.lines} {...props} />
      </div>
    );
  }
}

LinesTable.propTypes = {
  title: PropTypes.string,
  isOverrideLines: PropTypes.bool,
  isFetchLine: PropTypes.bool,
  service: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
  onChangeFormLine: PropTypes.func,
  handleClickServicesFormBtn: PropTypes.func,
};

LinesTable.defaultProps = {
  title: '',
  isOverrideLines: false,
  isFetchLine: false,
  onChangeFormLine: () => {},
  handleClickServicesFormBtn: () => {},
  service: {},
  t: () => {},
};

export default withTranslation('common')(LinesTable);
