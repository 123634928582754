import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { queryRequest, mutationRequest } from 'utils/request';
import { toast } from 'react-toastify';

import {
  searchRoles,
  getRoleById,
  createRole,
  modifyRole,
  searchRoleGroups,
  getRoleGroupById,
  modifyRoleGroup,
  createRoleGroup,
  searchUsers,
  getUserById,
  modifyUser,
  createUser,
  getUserGroups,
  createUserGroup,
  modifyUserGroup,
  getConfigApprovals,
  modifyConfigApprovalLevel,
  createConfigApprovalLevel,
  searchUserGroups,
} from 'api';
import { resetNotification, setNotification } from 'containers/Notification/actions';
import { NotificationTypes } from 'constantsApp';
import RouteNames from '../App/RouteNames';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

// ------ search roles
export function* searchRolesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchRoles({ page, size, filter, sort }));
    if (response.searchRoles) {
      yield put(actions.searchRolesSuccess(response.searchRoles));
    }
    if (cb) cb({ success: true, data: response.searchRoles });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      if (cb) cb({ success: false });
    }

    yield put(isEndConnected());
  }
}

// ------ search Role by Id
export function* getRoleByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { id } = payload;
    const response = yield call(queryRequest, getRoleById(id));
    if (response.getRoleById) {
      if (cb) {
        const data = response.getRoleById;
        Object.keys(data).map(key => {
          if (Array.isArray(data[key]) && data[key].length === 0) {
            data[key] = null;
          }
        });
        cb({ success: true, data });
      }
    } else if (cb) {
      cb({ success: false });
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ createRoleSaga
export function* createRoleSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { rolesInput } = payload;
    const response = yield call(mutationRequest, createRole(rolesInput));
    if (response.createRole) {
      if (cb) {
        cb({ success: true, response: response.createRole });
      }
      toast.success(i18n.t('common:apiMessage.createRoleSuccessfully'));
      yield put(push(RouteNames.prettifyPath(RouteNames.userManagementRoleEdit.path, response.createRole.id)));
    } else {
      if (cb) {
        cb({ success: false });
      }
      // toast.error('Create Role failed')
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    // toast.error('Create Role failed')

    yield put(isEndConnected());
  }
}
// ------ modify Role
export function* modifyRoleSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { rolesInput } = payload;
    const response = yield call(mutationRequest, modifyRole(rolesInput));
    if (response.modifyRole) {
      if (cb) {
        cb({ success: true, response: response.modifyRole });
      }
      toast.success(i18n.t('common:apiMessage.modifyRoleSuccessfully'));
    } else {
      if (cb) {
        cb({ success: false });
      }
      // toast.error('Modify Role failed')
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    // toast.error('Modify Role failed')
    yield put(isEndConnected());
  }
}
export function* searchRoleGroupsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchRoleGroups({ page, size, filter, sort }));

    if (response.searchRoleGroups) {
      yield put(actions.searchRoleGroupsSuccess(response.searchRoleGroups));
    } else {
      yield put(
        actions.searchRoleGroupsFailure('Failed to fetch role groups. Please try again or check your network!')
      );
    }
    if (cb) {
      cb({ success: true, data: response.searchRoleGroups });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }

    yield put(isEndConnected());

    yield put(actions.searchRoleGroupsFailure('Failed to fetch role groups. Please try again or check your network!'));
  }
}
// search all role

export function* searchAllRolesSaga({ cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(queryRequest, searchRoles({ isSearchAll: true }));
    if (response.searchRoles) {
      if (cb) {
        cb({ success: true, data: response.searchRoles });
      }
    }
    if (cb) {
      cb({ success: false, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    console.log(err);
    if (cb) {
      cb({ success: false, data: [] });
    }

    yield put(isEndConnected());
    // toast.error('Failed to fetch role groups. Please try again or check your network!')
  }
}

// search all role groups
export function* searchAllRoleGroupsSaga({ cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(queryRequest, searchRoleGroups({ isSearchAll: true }));
    if (response.searchRoleGroups) {
      if (cb) {
        cb({ success: true, data: response.searchRoleGroups });
      }
    } else {
      // toast.error('Failed to fetch role groups. Please try again or check your network!')
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false, data: [] });
    }
    yield put(isEndConnected());
    // toast.error('Failed to fetch role groups. Please try again or check your network!')
  }
}

// ------ get Role Group
export function* getRoleGroupByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { id } = payload;
    const response = yield call(queryRequest, getRoleGroupById(id));
    if (response.getRoleGroupById) {
      if (cb) {
        cb({ success: true, data: response.getRoleGroupById });
      }
    } else {
      if (cb) {
        cb({ success: false });
      }
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'Failed to fetch role groups. Please try again or check your network!',
        })
      );
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: 'Failed to fetch role groups. Please try again or check your network!',
      })
    );
  }
}

// ------ modify Role Group
export function* modifyRoleGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { roleGroupInput } = payload;
    const response = yield call(mutationRequest, modifyRoleGroup(roleGroupInput));
    if (response.modifyRoleGroup) {
      if (cb) {
        cb({ success: true, data: response.modifyRoleGroup });
      }
      toast.success(i18n.t('common:apiMessage.modifyRoleGroupSuccessfully'));
    } else if (cb) {
      cb({ success: false });
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// createRoleGroup
export function* createRoleGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { roleGroupInput } = payload;
    const response = yield call(mutationRequest, createRoleGroup(roleGroupInput));
    if (response.createRoleGroup) {
      if (cb) {
        cb({ success: true, response: response.createRoleGroup });
      }
      yield put(
        push(RouteNames.prettifyPath(RouteNames.userManagementRoleGroupEdit.path, response.createRoleGroup.id))
      );
      toast.success(i18n.t('common:apiMessage.createRoleGroupSuccessfully'));
    } else if (cb) {
      cb({ success: false });
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}
// ------ get list users
export function* searchUsersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchUsers({ page, size, filter, sort }));
    if (response.searchUsers) {
      yield put(actions.searchUserSuccess(response.searchUsers));
    } else {
      yield put(actions.searchUsersFailure('Failed to fetch users. Please try again or check your network!'));
    }
    if (cb) {
      cb();
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb();
    }
    yield put(actions.searchUsersFailure('Failed to fetch users. Please try again or check your network!'));
  }
}

// ------ get user by Id
export function* getUserByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { id } = payload;
    const response = yield call(queryRequest, getUserById(id));
    if (response.getUserById) {
      if (cb) {
        cb({ data: response.getUserById, success: true });
      }
      yield put({
        type: types.GET_USER_BY_ID_SUCCESS,
        payload: response.getUserById,
      });
    } else {
      if (cb) {
        cb({}, false);
      }
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: 'Failed to fetch user. Please try again or check your network!',
        })
      );
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({}, false);
    }

    yield put(isEndConnected());

    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: 'Failed to fetch user. Please try again or check your network!',
      })
    );
  }
}

// ------ modify User
export function* modifyUserSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { modifyUserInput } = payload;
    const response = yield call(mutationRequest, modifyUser(modifyUserInput));
    if (response.modifyUser) {
      if (cb) {
        cb({ success: true, data: response.modifyUser });
      }
      toast.success(i18n.t('common:apiMessage.modifyUserSuccessfully'));
    } else {
      if (cb) {
        cb({ success: false, data: {} });
      }
      // toast.error('Modify User failed');
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false, data: {} });
    }
    // toast.error('Modify User failed')
    yield put(isEndConnected());
  }
}

// create User
export function* createUserSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const { userInput } = payload;
    const response = yield call(mutationRequest, createUser(userInput));
    if (response.createUser) {
      if (cb) {
        cb({ success: true, response: response.createUser });
      }
      toast.success(i18n.t('common:apiMessage.createUserSuccessfully'));
      yield put(push(RouteNames.prettifyPath(RouteNames.userManagementUserEdit.path, response.createUser.id)));
    } else {
      if (cb) {
        cb({ success: false });
      }
      // toast.error("Create user failed")
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    // toast.error("Create user failed")
    yield put(isEndConnected());
  }
}

// User Groups
export function* getUserGroupsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUserGroups());
    yield put(isEndConnected());
    if (response.getUserGroups && response.getUserGroups) {
      yield put(actions.getUserGroupsSuccess(response.getUserGroups));
      if (cb) yield cb({ success: true, data: response.getUserGroups });
    } else {
      yield put(actions.getUserGroupsSuccess({}));
      if (cb) yield cb({ success: false, data: null });
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUserGroupsSuccess({}));
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyUserGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyUserGroup(payload));
    if (response.modifyUserGroup) {
      if (cb) cb({ success: true, data: response.modifyUserGroup });
      toast.success(i18n.t('common:apiMessage.modifyUserGroupSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createUserGroupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createUserGroup(payload));
    if (response.createUserGroup) {
      toast.success(i18n.t('common:apiMessage.createUserGroupSuccessfully'));
      if (cb) cb({ success: true, data: response.createUserGroup });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Approval
export function* getApprovalsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigApprovals());
    yield put(isEndConnected());
    if (response.getConfigApprovals && response.getConfigApprovals) {
      if (cb) yield cb({ success: true, data: response.getConfigApprovals });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyApprovalsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigApprovalLevel(payload));
    if (response.modifyConfigApprovalLevel) {
      if (cb) cb({ success: true, data: response.modifyConfigApprovalLevel });
      toast.success(i18n.t('common:apiMessage.modifyApprovalSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createApprovalsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigApprovalLevel(payload));
    if (response.createConfigApprovalLevel) {
      toast.success(i18n.t('common:apiMessage.createApprovalSuccessfully'));
      if (cb) cb({ success: true, data: response.createConfigApprovalLevel });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchUserGroupsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchUserGroups(payload));
    if (response.searchUserGroups) {
      if (cb) cb({ success: true, data: response.searchUserGroups });
    } else if (cb) {
        if (cb) cb({ success: true, data: [] });
      }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      if (cb) cb({ success: false, data: null });
    }

    yield put(isEndConnected());
  }
}

export default function* collectionsSaga() {
  yield takeLatest(types.SEARCH_ROLES, searchRolesSaga);
  yield takeLatest(types.SEARCH_ALL_ROLES, searchAllRolesSaga);
  yield takeLatest(types.SEARCH_ALL_ROLE_GROUPS, searchAllRoleGroupsSaga);
  yield takeLatest(types.CREATE_ROLE, createRoleSaga);
  yield takeLatest(types.GET_ROLES_BY_ID, getRoleByIdSaga);
  yield takeLatest(types.MODIFY_ROLE, modifyRoleSaga);
  yield takeLatest(types.SEARCH_ROLE_GROUPS, searchRoleGroupsSaga);
  yield takeLatest(types.GET_ROLE_GROUP_BY_ID, getRoleGroupByIdSaga);
  yield takeLatest(types.MODIFY_ROLE_GROUP, modifyRoleGroupSaga);
  yield takeLatest(types.CREATE_ROLE_GROUP, createRoleGroupSaga);
  yield takeLatest(types.SEARCH_USERS, searchUsersSaga);
  yield takeLatest(types.GET_USER_BY_ID, getUserByIdSaga);
  yield takeLatest(types.MODIFY_USER, modifyUserSaga);
  yield takeLatest(types.CREATE_USER, createUserSaga);
  yield takeLatest(types.GET_USER_GROUPS, getUserGroupsSaga);
  yield takeLatest(types.MODIFY_USER_GROUPS, modifyUserGroupSaga);
  yield takeLatest(types.CREATE_USER_GROUPS, createUserGroupSaga);
  yield takeLatest(types.GET_APPROVAL, getApprovalsSaga);
  yield takeLatest(types.MODIFY_APPROVAL, modifyApprovalsSaga);
  yield takeLatest(types.CREATE_APPROVAL, createApprovalsSaga);
  yield takeLatest(types.SEARCH_USER_GROUPS, searchUserGroupsSaga);
}
