import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'status',
    'numOfInstallment',
    'daysOffset',
    'monthOffset',
    'index',
    'percent',
  ]);
  const createPaymentInstallment = `createPaymentInstallment(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createPaymentInstallment} {
      id
    }
  }
`;
};
