import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';
import listCurrency from '../../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class BillingTrackerSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.year',
      name: 'year',
      type: 'year',
      // defaultValue: moment().format('YYYY'),
      // required: true,
    },
    {
      label: 'label.clientAccountId',
      name: 'accountId',
    },
    {
      label: 'label.exchangeCurrency',
      name: 'exchangeCurrency',
      type: 'select',
      options: currencyOptions,
      isClearable: true,
      defaultValue: 'MXN',
      required: true,
    },
    {
      label: 'label.month',
      name: 'month',
      type: 'select',
      tOptions: 'selections:monthOfYearUpperCase',
      isClearable: true,
    },
    {
      label: 'label.billStart',
      name: 'billStart',
      type: 'date',
    },
    {
      label: 'label.billEnd',
      name: 'billEnd',
      type: 'date',
    },
  ];

  btnSpan = 'col-md-6';
}

BillingTrackerSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BillingTrackerSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BillingTrackerSearchForm);
