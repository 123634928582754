import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { queryRequest, mutationRequest } from 'utils/request';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import {
  getARReasonCodeConfig,
  modifyARReasonCodeConfig,
  createARReasonCodeConfig,
  searchRevenueMilestone,
  getRevenueMilestoneById,
  modifyRevenueMilestone,
  createRevenueMilestone,
  searchExchangeRate,
  getExchangeRate,
  createExchangeRate,
  modifyExchangeRate,
  exportExchangeRate,
  searchAccountingEntry,
  searchEbsExtract,
  getEbsExtractDetailsById,
  searchRevenueJournal,
  modifyRevenueJournal,
  searchRevRecJournal,
  createRevenueJournal,
  recognizeManualRevenue,
  resendEbsExtract,
  getRevenueRecognitionSummary,
  getActivityEntry,
} from 'api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getARReasonCodeConfigRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getARReasonCodeConfig(payload));
    if (response.getARReasonCodeConfig) {
      if (cb) cb({ success: true, data: response.getARReasonCodeConfig });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyARReasonCodeConfigRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyARReasonCodeConfig(payload));
    if (response.modifyARReasonCodeConfig) {
      toast.success(i18n.t('common:apiMessage.modifyReasonCodeSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyARReasonCodeConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createARReasonCodeConfigRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createARReasonCodeConfig(payload));
    if (response.createARReasonCodeConfig) {
      toast.success(i18n.t('common:apiMessage.createReasonCodeSuccessfully'));
      if (cb) cb({ success: true, data: response.createARReasonCodeConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchRevenueMilestoneSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRevenueMilestone(payload));
    if (response.searchRevenueMilestone) {
      yield put(actions.searchRevenueMilestoneSuccess(response.searchRevenueMilestone));
      if (cb) cb({ success: true, data: response.searchRevenueMilestone });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getRevenueMilestoneByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueMilestoneById(payload));
    if (response.getRevenueMilestoneById) {
      yield put(actions.getRevenueMilestoneByIdSuccess(response.getRevenueMilestoneById));
      if (cb) cb({ success: true, data: response.getRevenueMilestoneById });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyRevenueMilestoneSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRevenueMilestone(payload));
    if (response.modifyRevenueMilestone) {
      if (cb) cb({ success: true, data: response.modifyRevenueMilestone });
      toast.success(i18n.t('common:apiMessage.modifyMilestoneSuccessfully'));
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createRevenueMilestoneSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRevenueMilestone(payload));
    if (response.createRevenueMilestone) {
      toast.success(i18n.t('common:apiMessage.createMilestoneSuccessfully'));
      yield put(push(`/revenue-config/milestone/modify/${response.createRevenueMilestone.id}`));
      if (cb) cb({ success: true, data: response.createRevenueMilestone });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchExchangeRate(payload));
    if (response.searchExchangeRate) {
      yield put(actions.searchExchangeRateSuccess(response.searchExchangeRate));
      if (cb) cb({ success: true, data: response.searchExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getExchangeRateByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExchangeRate(payload));
    if (response.getExchangeRate) {
      yield put(actions.getExchangeRateByIdSuccess(response.getExchangeRate));
      if (cb) cb({ success: true, data: response.getExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyExchangeRate(payload));
    if (response.modifyExchangeRate) {
      toast.success(i18n.t('common:apiMessage.modifyExchangeRateSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createExchangeRate(payload));
    if (response.createExchangeRate) {
      toast.success(i18n.t('common:apiMessage.createExchangeRateSuccessfully'));
      yield put(push(`/revenue-config/milestone/modify/${response.createExchangeRate.id}`));
      if (cb) cb({ success: true, data: response.createExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* exportExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, exportExchangeRate(payload));
    if (response.exportExchangeRate) {
      yield put(actions.exportExchangeRateSuccess(response.exportExchangeRate));
      if (cb) cb({ success: true, data: response.exportExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* searchAccountingEntrySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchAccountingEntry(payload));
    if (response.searchAccountingEntry) {
      yield put(actions.searchAccountingEntrySuccess(response.searchAccountingEntry));
      if (cb) cb({ success: true, data: response.searchAccountingEntry });
    } else {
      yield put(actions.searchAccountingEntrySuccess([]));
      if (cb) cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchEbsExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchEbsExtract(payload));
    if (response.searchEbsExtract) {
      yield put(actions.searchEbsExtractSuccess(response.searchEbsExtract));
      if (cb) cb({ success: true, data: response.searchEbsExtract });
    } else {
      yield put(actions.searchEbsExtractSuccess([]));
      if (cb) cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getEbsExtractDetailsByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getEbsExtractDetailsById(payload));
    if (response.getEbsExtractDetailsById) {
      if (cb) cb({ success: true, data: response.getEbsExtractDetailsById });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchRevenueJournalSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRevenueJournal(payload));
    if (response.searchRevenueJournal && response.searchRevenueJournal) {
      yield put(actions.searchRevenueJournalSuccess(response.searchRevenueJournal));
      if (cb) yield cb({ success: true, data: response.searchRevenueJournal });
    } else {
      yield put(actions.searchRevenueJournalSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchRevenueJournalSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchAllRevenueJournalSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRevenueJournal(payload));
    if (response.searchRevenueJournal && response.searchRevenueJournal) {
      yield put(actions.searchAllRevenueJournalSuccess(response.searchRevenueJournal));
      if (cb) yield cb({ success: true, data: response.searchRevenueJournal });
    } else {
      yield put(actions.searchAllRevenueJournalSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllRevenueJournalSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchAllAccountingEntrySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchAccountingEntry(payload));
    if (response.searchAccountingEntry) {
      yield put(actions.searchAllAccountingEntrySuccess(response.searchAccountingEntry));
      if (cb) yield cb({ success: true, data: response.searchAccountingEntry });
    } else {
      yield put(actions.searchAllAccountingEntrySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllAccountingEntrySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* modifyRevenueJournalSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRevenueJournal(payload));
    if (response.modifyRevenueJournal) {
      toast.success(i18n.t('common:apiMessage.modifyRevenueJournalSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifyRevenueJournal });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchALLEbsExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchEbsExtract(payload));
    if (response.searchEbsExtract) {
      yield put(actions.searchAllEbsExtractSuccess(response.searchEbsExtract));
      if (cb) cb({ success: true, data: response.searchEbsExtract });
    } else {
      yield put(actions.searchAllEbsExtractSuccess([]));
      if (cb) cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchRevRecJournalSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRevRecJournal(payload));
    if (response.searchRevRecJournal) {
      if (cb) cb({ success: true, data: response.searchRevRecJournal });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createRevenueJournalSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRevenueJournal(payload));
    if (response.createRevenueJournal) {
      toast.success(i18n.t('common:apiMessage.createRevenueJournalSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createRevenueJournal });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* recognizeManualRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, recognizeManualRevenue(payload));
    if (response.recognizeManualRevenue) {
      toast.success(i18n.t('common:apiMessage.recognizeManualRevenueSuccessfully'));
      if (cb) yield cb({ success: true, data: response.recognizeManualRevenue });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* resendEbsExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, resendEbsExtract(payload));
    if (response.resendEbsExtract) {
      toast.success(i18n.t('common:apiMessage.resendEbsExtractSuccessfully'));
      if (cb) yield cb({ success: true, data: response.resendEbsExtract });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getRevenueRecognitionSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueRecognitionSummary(payload));
    if (response.getRevenueRecognitionSummary) {
      if (cb) cb({ success: true, data: response.getRevenueRecognitionSummary });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getActivityEntrySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getActivityEntry(payload));
    if (response.getActivityEntry) {
      if (cb) cb({ success: true, data: response.getActivityEntry });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* paymentSaga() {
  yield takeLatest(types.GET_AR_REASON_CODE_REVENUE, getARReasonCodeConfigRevenueSaga);
  yield takeLatest(types.MODIFY_AR_REASON_CODE_REVENUE, modifyARReasonCodeConfigRevenueSaga);
  yield takeLatest(types.CREATE_AR_REASON_CODE_REVENUE, createARReasonCodeConfigRevenueSaga);
  yield takeLatest(types.SEARCH_REVENUE_MILESTONE, searchRevenueMilestoneSaga);
  yield takeLatest(types.GET_REVENUE_MILESTONE_BY_ID, getRevenueMilestoneByIdSaga);
  yield takeLatest(types.MODIFY_REVENUE_MILESTONE, modifyRevenueMilestoneSaga);
  yield takeLatest(types.CREATE_REVENUE_MILESTONE, createRevenueMilestoneSaga);
  yield takeLatest(types.SEARCH_EXCHANGE_RATE, searchExchangeRateSaga);
  yield takeLatest(types.MODIFY_EXCHANGE_RATE, modifyExchangeRateSaga);
  yield takeLatest(types.CREATE_EXCHANGE_RATE, createExchangeRateSaga);
  yield takeLatest(types.GET_EXCHANGE_RATE_BY_ID, getExchangeRateByIdSaga);
  yield takeLatest(types.EXPORT_EXCHANGE_RATE_BY_ID, exportExchangeRateSaga);
  yield takeLatest(types.SEARCH_ACCOUNTING_ENTRY, searchAccountingEntrySaga);
  yield takeLatest(types.SEARCH_EBS_EXTRACT, searchEbsExtractSaga);
  yield takeLatest(types.GET_EBS_EXTRACT_BY_BATCH_ID, getEbsExtractDetailsByIdSaga);
  yield takeLatest(types.SEARCH_REVENUE_JOURNAL, searchRevenueJournalSaga);
  yield takeLatest(types.SEARCH_ALL_REVENUE_JOURNAL, searchAllRevenueJournalSaga);
  yield takeLatest(types.SEARCH_ALL_ACCOUNT_ENTRY, searchAllAccountingEntrySaga);
  yield takeLatest(types.MODIFY_REVENUE_JOURNAL, modifyRevenueJournalSaga);
  yield takeLatest(types.SEARCH_ALL_EBS_EXTRACT, searchALLEbsExtractSaga);
  yield takeLatest(types.SEARCH_REV_REC_JOURNAL, searchRevRecJournalSaga);
  yield takeLatest(types.CREATE_REVENUE_JOURNAL, createRevenueJournalSaga);
  yield takeLatest(types.RECOGNIZE_MANUAL_REVENUE, recognizeManualRevenueSaga);
  yield takeLatest(types.RESEND_EBS_EXTRACT, resendEbsExtractSaga);
  yield takeLatest(types.GET_REVENUE_RECOGNITIION_SUMMARY, getRevenueRecognitionSummarySaga);
  yield takeLatest(types.GET_ACTIVITY_ENTRY, getActivityEntrySaga);
}
