import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, findIndex, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import TableFormServiceBundle from './TableFormServiceBundle';
import TableFormLine from './TableFormLine';
import { CardHeaderForm } from '../common';

class ServiceEditBundleForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      itemSelectOverride: {},
    };
  }

  componentDidMount() {
    const { service } = this.props;
    const newService = cloneDeep(service);
    const serviceFilterIndex = newService.filter(item => item.serviceType) || [];
    this.setState({ data: serviceFilterIndex });
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    if (nextProps.service && nextProps.service !== data && data.length && !data[0].bundleName) {
      const serviceFilterIndex = nextProps.service.filter(item => item.serviceType) || [];
      this.setState({ data: serviceFilterIndex });
    }
  }

  onHandleShowLineService = item => {
    let { itemSelectOverride } = this.state;
    if (itemSelectOverride.index === item.index) {
      itemSelectOverride = {};
    } else {
      itemSelectOverride = item;
    }
    this.setState({ itemSelectOverride });
  };

  onChangeFormLine = values => {
    try {
      const { allDataService, onChangeFormLine } = this.props;
      const { itemSelectOverride } = this.state;
      const indexValueService = findIndex(allDataService, item => item.index === itemSelectOverride.index);
      const indexLines = itemSelectOverride.lines.findIndex(val => val.index === values.lineIndex);
      itemSelectOverride.lines[indexLines][values.fieldName] = values.value;
      this.setState({ itemSelectOverride: { ...itemSelectOverride } });
      onChangeFormLine(indexValueService, values);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { service, onDeleteService, onChangeProvisioningId, title, t, isDisabled, ...rest } = this.props;
    const { itemSelectOverride } = this.state;
    const serviceFilterIndex = service.filter(item => item.serviceType);
    if (serviceFilterIndex.length < 1) return '';
    const slt = t('selections:serviceType')().find(val => val.value === serviceFilterIndex[0].serviceType);
    return (
      <div className="col-md-12 ">
        <CardHeaderForm
          title={`${t('label.serviceType')}: ${slt ? slt.label : ''}`}
          wrapperClass="font-weight-normal"
          // label={`${t('label.services')}:`}
          // value={slt ? slt.label : ''}
          hideNavTab
        />
        <TableFormServiceBundle
          service={service}
          onHandleShowLineService={this.onHandleShowLineService}
          itemSelectOverride={itemSelectOverride}
          onDeleteService={onDeleteService}
          onChangeProvisioningId={onChangeProvisioningId}
          isDisabled={isDisabled}
          {...rest}
        />
        {!isEmpty(itemSelectOverride) && (
          <div className="form-group col-md-12">
            <div className="card-body px-2">
              <CardHeaderForm key="CardHeaderForm" title="Lines" hideNavTab />
              <TableFormLine
                isReadOnly={isDisabled}
                lines={itemSelectOverride.lines || []}
                onChangeFormLine={this.onChangeFormLine}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ServiceEditBundleForm.propTypes = {
  getBundleDetail: PropTypes.func.isRequired,
  onDeleteService: PropTypes.func,
  service: PropTypes.arrayOf(PropTypes.any),
  allDataService: PropTypes.arrayOf(PropTypes.any),
  onChangeFormLine: PropTypes.func,
};

ServiceEditBundleForm.defaultProps = {
  onChangeFormLine: () => {},
  onDeleteService: () => {},
  service: [],
  allDataService: [],
};

export default withTranslation('common')(ServiceEditBundleForm);
