import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GenericInput, SubmitForm } from '../common';

const searchInputs = [
  {
    label: 'common:label.id',
    name: 'id',
  },
  {
    label: 'common:label.name',
    name: 'name',
  },
  {
    label: 'common:label.salesChannel',
    name: 'salesChannel',
    tOptions: 'selections:salesChannel',
    type: 'select',
  },
  {
    label: 'common:label.serviceType',
    name: 'serviceType',
    tOptions: 'selections:serviceType',
    type: 'select',
  },
  {
    label: 'common:label.marketSegment',
    name: 'marketSegment',
    tOptions: 'selections:marketSegment',
    type: 'select',
  },
  {
    label: 'common:label.accountType',
    name: 'accountType',
    tOptions: 'selections:accountType',
    type: 'select',
  },
  {
    label: 'common:label.accountSubType',
    name: 'accountSubType',
    tOptions: 'selections:accountSubType',
    type: 'select',
  },
  {
    label: 'common:label.country',
    name: 'country',
  },
  {
    label: 'common:label.currency',
    name: 'currency',
  },
  {
    label: 'common:label.status',
    disabled: true,
    name: 'status',
    tOptions: 'selections:statusPricing',
    type: 'select',
  },
];

class PriceOffersSearchForm extends Component {
  state = {
    status: 'SELLABLE',
  };

  componentDidMount() {
    searchInputs.map(e => {
      if (!e.disabled) {
        this.setState({ [e.name]: '' });
      }
      return true;
    });
  }

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(this.state);
    }
  };

  onClickClearButton = () => {
    const { onClickClearButton } = this.props;
    searchInputs.forEach(item => {
      if (!item.disabled) {
        this.setState({ [item.name]: '' });
      }
    });
    onClickClearButton({});
  };

  render() {
    const { isSubmitting, t } = this.props;

    return (
      <SubmitForm
        {...this.props}
        onSubmit={this.handleSubmit}
        isSubmitting={isSubmitting}
        onClickClearButton={this.onClickClearButton}
        btnSearchSpan="col-md-12"
        submitText={t('label.search')}
        isNoNeedPermission
      >
        {searchInputs.map(item => (
          <GenericInput key={item.name} {...item} onChange={this.handleInputChange} value={this.state[item.name]} />
        ))}
      </SubmitForm>
    );
  }
}

PriceOffersSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  onClickClearButton: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

PriceOffersSearchForm.defaultProps = {
  isSubmitting: false,
  onSubmit: () => {},
  onClickClearButton: () => {},
};

export default withTranslation('common')(PriceOffersSearchForm);
