import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accountType',
        'accountSubType',
        'marketSegment',
        'salesChannel',
      ])}`
    : '';
  const searchCollectionProfiles = `searchCollectionProfiles(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchCollectionProfiles} {
        id
        collectionProfile
        accountType
        accountSubType
        marketSegment
        salesChannel
      }
    }
  `;
};
