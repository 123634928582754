import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

const MoneyFormat = ({ label, wrapperClass, ...props }) => {
  const { t } = useTranslation('common');
  return (
    <div className={`form-group ${wrapperClass}`}>
      <span>{t(label)}</span>
      <NumberFormat className="form-control input-group" {...props} />
    </div>
  );
};

MoneyFormat.propTypes = {
  label: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
};

MoneyFormat.defaultProps = {
  wrapperClass: 'col-md-3',
};

export default MoneyFormat;
