import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'serviceType', 'interfaceType', 'status']);
  const modifyConfigUsagePlatform = `modifyConfigUsagePlatform(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigUsagePlatform} {
      id
    }
  }
`;
};
