import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'thresholdAmount',
        'accountType',
        'paymentMethod',
        'status',
        'paymentTransactionType',
        'category',
        'remainingPaymentThreshold',
        'folioStatus',
        'useExchangeRate',
        'minThresholdAmount',
        'minRemainingPaymentThreshold',
        'maxThresholdAmount',
        'maxRemainingPaymentThreshold',
        'groupByPaymentSource',
      ])}`
    : '';
  const getPaymentsReport = `getPaymentsReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getPaymentsReport} {
        accountId
        company
        firstName
        lastName
        accountType
        paymentDate
        createdDate
        paymentId
        transactionId
        invoiceUnitId
        externalTransId
        paymentMethod
        amount
        status
        paymentTerm
        paymentTransactionType
        currency
        sourceCurrency
        referenceId
        paymentSource
        paymentSourceId
        paymentDescription
        organization
        sourceCurrencyAmount
        exchangeRate
        suspenseFlag
        fileName
        paymentSuspenseId
        folioId
        userId
        fileDate
        remainingAmount
        folioStatus
        currentDateExchangeRate
        paymentDateExchangeRate
        unRealizedGain
      }
    }
  `;
};
