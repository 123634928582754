import { columns } from './ExcelHelper/commonFields';
import styleHeader from './ExcelHelper/styleHeader';
import { getLabelOptions, formatMoneyValueToExport, redFontExcelFile, redFontPdfFile } from './utils';

const positionHeader = ({ columnsTable }) => {
  if (columnsTable.length > 6) {
    return Number.parseInt(columnsTable.length / 2 - 1, 10);
  }
  if (columnsTable.length > 4) {
    return Number.parseInt(columnsTable.length / 2, 10);
  }
  if (columnsTable.length > 2) {
    return 1;
  }
  return 0;
};

export const convertHeaderExcelFile = ({ title, columnsTable, t, defaultPositionHeader }) => {
  return [
    {
      xSteps: defaultPositionHeader || positionHeader({ columnsTable }),
      columns: [],
      data: [[{ value: title, style: { font: { sz: '20', bold: true } } }]],
    },
    {
      columns: columnsTable.map(val => columns[val.name] || columns.common),
      data: [
        columnsTable.map(val => ({
          value: t(val.exportLabel) || t(val.label),
          style: styleHeader,
          isSupportBold: val.isSupportBold,
        })),
      ],
    },
  ];
};

export const convertBodyExcelFile = ({ item, columnsTable, t }) => {
  const newItem = [];
  if (item && typeof item === 'object') {
    columnsTable.forEach(val => {
      if (val.name === 'cutOffDOM') {
        if (item.cutOffDOM === -2) {
          const cutOffDOMOptions = t('selections:cutOffDOM')();
          const lastItem = cutOffDOMOptions[0];
          newItem.push({ value: lastItem.label || '' });
        } else newItem.push({ value: `${item.cutOffDOM || ''}` });
      }
      if (val.name !== 'cutOffDOM') {
        if (item[val.name] && typeof item[val.name] === 'number') {
          newItem.push({
            value: formatMoneyValueToExport(item[val.name]),
            style: val.isSupportRedFront && item[val.name] < 0 ? redFontExcelFile : null,
          });
        } else if (val.isRenderT) {
          newItem.push({
            value: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
        } else {
          newItem.push({ value: item[val.name] && typeof item[val.name] !== 'object' ? item[val.name] : '' });
        }
      }
    });
  }
  return newItem;
};

export const convertBodyPdfFile = ({ item, columnsTable, t }) => {
  const newItem = [];
  if (item && typeof item === 'object') {
    columnsTable.forEach(val => {
      if (val.name === 'cutOffDOM') {
        if (item.cutOffDOM === -2) {
          const cutOffDOMOptions = t('selections:cutOffDOM')();
          const lastItem = cutOffDOMOptions[0];
          newItem.push({ content: lastItem.label || '', ...val });
        } else newItem.push({ content: `${item.cutOffDOM || ''}` });
      }
      if (val.name !== 'cutOffDOM') {
        if (item[val.name] && typeof item[val.name] === 'number') {
          newItem.push({
            content: formatMoneyValueToExport(item[val.name]),
            styles: val.isSupportRedFront && item[val.name] < 0 ? redFontPdfFile : null,
            ...val,
          });
        } else if (val.isRenderT) {
          newItem.push({
            content: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
            ...val,
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
        } else {
          newItem.push({
            content: item[val.name] && typeof item[val.name] !== 'object' ? item[val.name] : '',
            ...val,
          });
        }
      }
    });
  }
  return newItem;
};
