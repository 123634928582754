import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createBundle });
};

const checkPermissionModifyBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyBundle });
};

const checkPermissionApproveBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.approveBundle });
};

const checkPermissionSellableBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sellableBundle });
};

const checkPermissionNonSellableBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.nonSellableBundle });
};

const checkPermissionSunsetBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sunsetBundle });
};

const checkPermissionSearchBundles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchBundles });
};

const checkPermissionGetBundle = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBundle });
};

const checkPermissionCreatePackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPackage });
};

const checkPermissionModifyPackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPackage });
};

const checkPermissionApprovePackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.approvePackage });
};

const checkPermissionSellablePackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sellablePackage });
};

const checkPermissionNonSellablePackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.nonSellablePackage });
};

const checkPermissionSunsetPackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.sunsetPackage });
};

const checkPermissionSearchPackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchPackage });
};

const checkPermissionGetPackage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPackage });
};

const checkPermissionCreateDependency = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createDependency });
};

const checkPermissionModifyDependency = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyDependency });
};

const checkPermissionGetBundleDependency = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBundleDependency });
};

const checkPermissionGetPackageDependency = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPackageDependency });
};

export {
  checkPermissionCreateBundle,
  checkPermissionModifyBundle,
  checkPermissionApproveBundle,
  checkPermissionSellableBundle,
  checkPermissionNonSellableBundle,
  checkPermissionSunsetBundle,
  checkPermissionSearchBundles,
  checkPermissionGetBundle,
  checkPermissionCreatePackage,
  checkPermissionModifyPackage,
  checkPermissionApprovePackage,
  checkPermissionSellablePackage,
  checkPermissionNonSellablePackage,
  checkPermissionSunsetPackage,
  checkPermissionSearchPackage,
  checkPermissionGetPackage,
  checkPermissionCreateDependency,
  checkPermissionModifyDependency,
  checkPermissionGetBundleDependency,
  checkPermissionGetPackageDependency,
};
