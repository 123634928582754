import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { GenericInput } from '../../common';
import ModalSearchItem from '../../common/ModalSearchItem';
import {
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
  getGLAccountConfig,
} from '../../../containers/App/actions';

const searchInputs = [
  {
    label: 'label.startDate',
    name: 'startDate',
    type: 'date',
  },
  {
    label: 'label.endDate',
    name: 'endDate',
    type: 'date',
  },
  {
    label: 'common:label.itemType',
    name: 'itemType',
    type: 'select',
    tOptions: 'selections:itemType',
  },
  {
    label: 'common:label.itemName',
    name: 'itemName',
    fa: 'fa fa-external-link',
  },
  {
    label: 'common:label.objectType',
    name: 'reportObjectType',
    type: 'select',
    tOptions: 'selections:revenueReportObjectType',
  },
  {
    label: 'common:label.transactionType',
    name: 'transactionType',
    type: 'select',
    tOptions: 'selections:transactionType',
  },
  {
    label: 'common:label.productCompany',
    name: 'productCompany',
    readOnly: true,
  },
  {
    label: 'common:label.glAccount',
    name: 'glAccount',
  },
  {
    label: 'common:label.glSegment1',
    name: 'glSegment1',
    type: 'select',
  },
  {
    label: 'common:label.glSegment2',
    name: 'glSegment2',
    type: 'select',
  },
  {
    label: 'common:label.glSegment3',
    name: 'glSegment3',
  },
  {
    label: 'common:label.offsetAccount',
    name: 'offsetAccount',
    type: 'select',
    tOptions: 'selections:selectBool',
    isClearable: true,
  },
  {
    label: 'common:label.revenueType',
    name: 'type',
    type: 'select',
    tOptions: 'selections:revenueType',
  },
  {
    label: 'common:label.revenueRecognitionType',
    name: 'recognitionType',
    type: 'select',
    tOptions: 'selections:revenueRecognitionType',
  },
  {
    label: 'common:label.productFamily',
    name: 'productFamily',
    type: 'select',
    options: [],
    isClearable: true,
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    type: 'select',
    options: [],
    isClearable: true,
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    type: 'select',
    options: [],
    isClearable: true,
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    type: 'select',
    options: [],
    isClearable: true,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
];

class RevenueRecognitionSearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalName: '',
      glSegment1Options: [],
      glSegment2Options: [],
    };
  }

  componentDidMount() {
    this.setDefaultInputs();
    this.doGetListProductConfig();
    const { getGLAccountConfig } = this.props;
    getGLAccountConfig('', ({ success, data }) => {
      let glSegment1Options = [];
      let glSegment2Options = [];
      if (success && data && data[0] && data[0].segments && data[0].segments[0] && data[0].segments[0].segmentValues) {
        glSegment1Options = data[0].segments[0].segmentValues.map(val => {
          return { label: `${val.code}-${val.name}`, value: val.code };
        });
      }
      if (success && data && data[0] && data[0].segments && data[0].segments[1] && data[0].segments[1].segmentValues) {
        glSegment2Options = data[0].segments[1].segmentValues.map(val => {
          return { label: `${val.code}-${val.name}`, value: val.code };
        });
      }
      this.setState({ glSegment1Options, glSegment2Options });
    });
  }

  doGetListProductConfig = () => {
    const { getUniqueProductFamily, getUniqueProductLine, getUniqueProductSubType, getUniqueProductType } = this.props;
    this.setState({ productFamily: '', productType: '', productSubType: '', productLine: '' });
    getUniqueProductFamily('', ({ data, success }) => {
      if (success)
        this.setState({
          productFamilyConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productFamily, value: val.productFamily })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductLine('', ({ data, success }) => {
      if (success)
        this.setState({
          productLineConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productLine, value: val.productLine })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductSubType('', ({ data, success }) => {
      if (success)
        this.setState({
          productSubTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productSubType, value: val.productSubType })),
                  ['label']
                )
              : [],
        });
    });
    getUniqueProductType('', ({ data, success }) => {
      if (success)
        this.setState({
          productTypeConfig:
            data && data.length
              ? sortBy(
                  data.map(val => ({ label: val.productType, value: val.productType })),
                  ['label']
                )
              : [],
        });
    });
  };

  setDefaultInputs = () => {
    searchInputs.map(input => this.setState({ [input.name]: null }));
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit } = this.props;
    const data = {};
    searchInputs.map(input => (this.state[input.name] ? (data[input.name] = this.state[input.name]) : null));
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    // const { onSubmit } = this.props;
    this.setDefaultInputs();
    // if (onSubmit) {
    //   onSubmit({});
    // }
  };

  onSelect = (value, row) => {
    const { modalName } = this.state;
    this.setState({ [modalName]: row.name, modalName: '' });
  };

  onOpenModal = ({ modalName }) => {
    this.setState({ modalName });
  };

  onToggleModal = () => {
    this.setState({ modalName: '' });
  };

  render() {
    const { isSubmitting, t } = this.props;
    const {
      productFamilyConfig,
      productLineConfig,
      productSubTypeConfig,
      productTypeConfig,
      glSegment1Options,
      glSegment2Options,
    } = this.state;
    if (productFamilyConfig && productFamilyConfig.length) {
      searchInputs[7].options = productFamilyConfig;
    }

    if (productLineConfig && productLineConfig.length) {
      searchInputs[8].options = productLineConfig;
    }

    if (productSubTypeConfig && productSubTypeConfig.length) {
      searchInputs[9].options = productSubTypeConfig;
    }

    if (productTypeConfig && productTypeConfig.length) {
      searchInputs[10].options = productTypeConfig;
    }

    const { modalName } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          {searchInputs.map(item => {
            if (item.name === 'glSegment1') {
              return (
                <GenericInput
                  key={item.name}
                  {...item}
                  label={t && t(item.label) ? t(item.label) : item.label}
                  value={this.state[item.name]}
                  onChange={this.handleInputChange}
                  options={glSegment1Options || []}
                />
              );
            }
            if (item.name === 'glSegment2') {
              return (
                <GenericInput
                  key={item.name}
                  {...item}
                  label={t && t(item.label) ? t(item.label) : item.label}
                  value={this.state[item.name]}
                  onChange={this.handleInputChange}
                  options={glSegment2Options || []}
                />
              );
            }
            return (
              <GenericInput
                key={item.name}
                {...item}
                label={t && t(item.label) ? t(item.label) : item.label}
                value={this.state[item.name]}
                onChange={this.handleInputChange}
                onClick={item.fa ? () => this.onOpenModal({ modalName: item.name }) : () => {}}
              />
            );
          })}
          <div className="form-group text-right btn-search-actions col-md-12">
            <label htmlFor="validationCustom01se" />
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t('label.clear')}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t('label.search')}
            </button>
          </div>
        </div>
        <ModalSearchItem
          isOpen={modalName === 'itemName'}
          onSelect={this.onSelect}
          onCancel={this.onToggleModal}
          selectedParentId={this.state && this.state.itemName ? this.state.itemName : ''}
        />
      </form>
    );
  }
}

RevenueRecognitionSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

RevenueRecognitionSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(
  connect(null, {
    getUniqueProductFamily,
    getUniqueProductLine,
    getUniqueProductSubType,
    getUniqueProductType,
    getGLAccountConfig,
  })(RevenueRecognitionSearchForm)
);
