import gql from 'graphql-tag';

export default isSourcePricing => gql`
  query {
    getUsageContainerSpec(isSourcePricing: ${!isSourcePricing}) {
      id
      usageContainerRecord {
        recordId
        recordType
        attributes {
          attributeName
        }
      }
    }
  }
`;
