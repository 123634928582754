export default {
  sidebar: {
    aROpsConfig: 'AR Ops Config',
    adjustments: 'Adjustments',
    disputes: 'Disputes',
    writeOffs: 'Write-offs',
    bulkAdjustments: 'Bulk Adjustments',
    GLsetup: 'GL Setup',
    itemMap: 'Item Map',
    reasonCode: 'Reason Code',
    AROpsGL: 'AR Ops GL',
    groupsReason: 'Group Reasons',
    ARThresholds: 'AR Thresholds',
    folios: 'Folios',
    creditNotesReport: 'Credit Notes Report',
  },
};
