import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'index',
    'status',
    'chargeType',
    'isParent',
    'componentQuantity',
    'isChild',
    'serviceType',
    'isProvisionable',
  ]);
  const createItemServices = `createItemServices(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createItemServices} {
      id
    }
  }
`;
};
