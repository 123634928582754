import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['idOperador', 'ido', 'ida'])}` : '';
  const getConfigPnnPlanNumbering = `getConfigPnnPlanNumbering(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getConfigPnnPlanNumbering} {
        claveCensal
        poblacion
        municipio
        estado
        central
        presuscripcion
        asl
        nir
        serie
        numeracionInicial
        numeracionFinal
        tipoRed
        modalidad
        estatus
        razonSocial
        nombreCorto
        oficioReserva
        fechaReserva
        oficioAsignacion
        fechaAsignacion
        fechaConsolidacion
        fechaMigracion
        puntoEntrega
        region
        ocupacion
        idOperador
        nirAnterior
        ido
        ida
      }
    }
  `;
};
