import gql from 'graphql-tag';

export default () => {
  const getFolioResponse = 'getFolioResponse(dummy: "")';
  return gql`
    query {
      ${getFolioResponse} {
        id
        folioType
        folioResponseFiles {
          index
          name
          fileType
          mandatory
        }
      }
    }
  `;
};
