import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import discountNS from './constants/discountOfferNS';

export default data => {
  let newPriceOfferNS = discountNS;
  if (data && data.discountingModel === 'COMPLEX') {
    newPriceOfferNS = newPriceOfferNS.filter(item => item !== 'timeUnit');
  }
  const dataCreate = parseToMutationRequest(data, newPriceOfferNS);
  const createDiscountOffer = `createDiscountOffer(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createDiscountOffer} {
      id
    }
  }
`;
};
