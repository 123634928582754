import gql from 'graphql-tag';

export default id => gql`
  query {
    getPreviousAccountMovement(input: {activityId: "${id}"}) {
      type
      updateServiceCapacityOnMove
      effectiveDate
      moveAllAccountServices
      moveAllAccountProvisioningAttributes
      fromAccount {
        accountId
        moveAllServices
        moveProvisioningAttributes
        clientId
        organization
        billables {
          omsInternalId
          serviceId
          serviceName
          quantity
          servicePrice
          updateServiceCapacity
          totalPrice
        }
        paymentProfile {
          paymentTerm
          paymentMethod
        }
      }
      toAccount {
        accountId
        moveAllServices
        moveProvisioningAttributes
        clientId
        organization
        billables {
          omsInternalId
          serviceId
          serviceName
          quantity
          servicePrice
          updateServiceCapacity
          totalPrice
        }
      }
    }
  }
`;
