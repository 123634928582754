import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class NonBroadsoftZeroDurationCDRSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.switchName',
      name: 'switchName',
      type: 'select',
      tOptions: 'selections:switchNameRawNonBroadsoft',
      isClearable: true,
      defaultValue: 'DIDWW',
      required: true,
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'datetime',
      required: true,
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'datetime',
      required: true,
      defaultValue: moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      label: 'label.organization',
      name: 'clientName',
      options: [],
      type: 'select',
    },
    {
      label: 'label.aPartyNumber',
      name: 'aPartyNumber',
    },
    {
      label: 'label.aPartyNumberStartRange',
      name: 'aPartyNumberStartRange',
    },
    {
      label: 'label.aPartyNumberEndRange',
      name: 'aPartyNumberEndRange',
    },
    {
      label: 'label.bPartyNumber',
      name: 'bPartyNumber',
    },
    {
      label: 'label.bPartyNumberStartRange',
      name: 'bPartyNumberStartRange',
    },
    {
      label: 'label.bPartyNumberEndRange',
      name: 'bPartyNumberEndRange',
    },
    {
      label: 'label.incomingRoute',
      name: 'incomingRoute',
      type: 'select',
      options: [],
    },
    {
      label: 'label.outgoingRoute',
      name: 'outgoingRoute',
      type: 'select',
      options: [],
    },
    {
      label: 'label.failureReason',
      name: 'failureCode',
      type: 'select',
      options: [],
      isMulti: true,
      isSupportMultiString: true,
    },
    {
      label: 'label.sourceIpAddress',
      name: 'sourceIpAddress',
    },
    {
      label: 'label.destinationIpAddress',
      name: 'destinationIpAddress',
    },

    // {
    //   label: 'label.durationMinutesStart',
    //   name: 'durationMinutesStart',
    //   type: 'number',
    // },
    // {
    //   label: 'label.durationMinutesEnd',
    //   name: 'durationMinutesEnd',
    //   type: 'number',
    // },

    // {
    //   label: 'label.idd',
    //   name: 'idd',
    // },
    // {
    //   label: 'label.IDO',
    //   name: 'ido',
    // },
  ];

  btnSpan = 'col-md-3';
}

NonBroadsoftZeroDurationCDRSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

NonBroadsoftZeroDurationCDRSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(NonBroadsoftZeroDurationCDRSearchForm);
