import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accountType',
        'billType',
        'status',
        'paymentMethod',
        'minBillThreshold',
      ])}`
    : '';
  const getCustomerBillReport = `getCustomerBillReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getCustomerBillReport} {
        id
        accountId
        company
        firstName
        lastName
        accountType
        startDate
        endDate
        billType
        billTotal
        status
        paymentMethod
        dueDate
        billingProfileId
        total
        nonPayingTotal
        due
        groupId
        invoiceId
      }
    }
  `;
};
