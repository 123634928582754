import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import fieldConstant from '../../../containers/CustomerPage/constants';
import { formatMoneyValue } from '../../../utils/utils';

const InfoCard = ({ accountInformation }) => {
  const { t } = useTranslation('common');
  return (
    <div className="row col-md-12">
      {fieldConstant.fieldsInfoCard.map((item, index) => (
        <div className={`col-md-3 group-info-card ${index === 3 || index === 7 ? 'last-child' : ''}`} key={item.name}>
          <div className="card">
            <div className="d-flex flex-row">
              <div className="p-6" style={{ backgroundColor: item.bgColor }}>
                <h4 className="text-white box m-b-0 icon-info">
                  <i className="fa fa-line-chart" />
                </h4>
              </div>
              <div className="align-self-center m-l-20">
                <div className="m-b-0" style={{ color: item.bgColor, fontSize: '16px', margin: '5px 0px -5px 20px' }}>
                  {item.isNumberFormat
                    ? formatMoneyValue(accountInformation[item.name]) || 0
                    : accountInformation[item.name]}
                </div>
                <div className="text-muted m-b-0" style={{ fontSize: '13px', margin: '-5px 0px 5px 20px' }}>
                  {t(item.label)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

InfoCard.propTypes = {
  accountInformation: PropTypes.objectOf(PropTypes.any),
};

InfoCard.defaultProps = {
  accountInformation: {},
};

export default InfoCard;
