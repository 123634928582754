import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'index',
    'advanceFlag',
    'recurringFrequency',
    'partMonthWaiveOffFlag',
    'recurringType',
    'recurringUnit',
    'alignedToCycle',
    'calendarMonthFlag',
    'percent',
    'isGrant',
    'invoiceExhibits',
    'cutOffDate',
    'percentile',
  ]);
  const indexDataSubmit = dataModify.indexOf('dataSubmit');
  let newDataModify = indexDataSubmit !== -1 ? `${dataModify.replace('{dataSubmit: ', '')}` : dataModify;
  newDataModify = indexDataSubmit !== -1 ? `${newDataModify.substring(0, newDataModify.length - 1)}` : newDataModify;
  const modifyOrderBillableServices = `modifyOrderBillableServices(input: ${newDataModify})`;

  // console.log('modifyOrderBillableServices', modifyOrderBillableServices);
  // return modifyOrderBillableServices;
  return gql`
  mutation {
    ${modifyOrderBillableServices} {
      id
      index
      currency
      advanceFlag
      recurringFrequency
      partMonthWaiveOffFlag
      calendarMonthFlag
      alignedToCycle
      alternateDescription
      recurringType
      recurringUnit
      isGrant
      invoiceExhibits
      cutOffDate
      percentile
      locations {
        index
        percent
      }
    }
  }
`;
};
