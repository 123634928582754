import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, DataTable, ModalWithItem, TitleFrom } from '../../../../components/common';
import { formatMoneyValue, validate } from '../../../../utils/utils';
import ModalInvoice from './ModalInvoice';
import { dataFiledInvoicePayment } from '../../constant';
import { paymentEnum } from '../../../../constantsApp';
import { getUserId } from '../../../../localStorages';
import RouteNames from '../../../App/RouteNames';
import { statusDisableFielsdViewFolio } from '../../../../utils/constants';

const fieldInvoices = ['bank', 'checkNumber', 'routingNumber'];
const paymentDetails = [
  {
    name: 'paymentAmount',
    label: 'common:label.paymentAmount',
    type: 'number',
  },
  {
    name: 'amount',
    label: 'common:label.remainingAmount',
    type: 'number',
  },
  {
    name: 'currency',
    label: 'common:label.paymentCurrency',
  },
];

class InvoicePayment extends React.PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isOpenModalInvoice: false,
      isNewAddress: false,
      data: {
        accountId: props.accountId || '',
        paymentInvoices: [{}],
        // transactionId: 'TrID001',
        source: 'AGENT_CARE',
        userId: getUserId() || 'UserX002',
        paymentDate: moment().format('YYYY-MM-DD'),
        method: paymentEnum.paymentMethod.CHECK,
        currency: props.currency || localStorage.getItem('currency') || null,
      },
      dataAddress: { country: '', state: '', city: '' },
      batchFileOptions: [],
      isTimeAndBillingDeployment: false,
      isOpenModalAllocationData: false,
      listItems: [],
      wasValidated: false,
      isMultipleSelect: false,
      isFolioStamping: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.accountId === state?.data?.accountId) return null;
    const newData = state?.data || {};
    newData.accountId = props?.accountId;
    newData.currency = props?.currency;
    newData.invoiceUnitId = null;
    return {
      data: newData,
      listItems: [],
    };
  }

  componentDidMount() {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    this.doGetBatchPaymentFileConfig();
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          this.onInitCcpProperty(data.ccpPropertyList);
        }
      });
    } else {
      this.onInitCcpProperty(ccpPropertyList);
    }
  }

  onInitCcpProperty = ccpPropertyList => {
    let isPacEnabled = false;
    const timeAndBillingDeployment = ccpPropertyList.find(val => val.property === 'timeAndBillingDeployment');
    const pacEnabledProperty = ccpPropertyList.find(val => val.property === 'pacEnabled');
    const batchFolioStampingProperty = ccpPropertyList.find(val => val.property === 'batchFolioStamping');
    if (pacEnabledProperty && pacEnabledProperty.value) {
      isPacEnabled = pacEnabledProperty.value === 'true';
    }
    this.setState({
      isTimeAndBillingDeployment: !!(timeAndBillingDeployment && timeAndBillingDeployment.value === 'true'),
      isPacEnabled,
      isFolioStamping: batchFolioStampingProperty && batchFolioStampingProperty.value === 'true',
    });
  };

  doGetBatchPaymentFileConfig = () => {
    const { getBatchPaymentFileConfig } = this.props;
    getBatchPaymentFileConfig('', ({ success, data }) => {
      if (success && data && data?.files?.length) {
        const batchFileOptions = data.files.map(val => {
          const { name, currency } = val;
          return { label: name, value: name, currency };
        });
        this.setState({ batchFileOptions });
      }
    });
  };

  onToggleInvoice = ({ index, isMultiple }) => {
    const { isOpenModalInvoice } = this.state;
    this.setState({
      isOpenModalInvoice: !isOpenModalInvoice,
      indexSelectAccountId: index,
      isMultipleSelect: isMultiple,
    });
  };

  onToggleModalInvoiceAllocationData = () => {
    const { isOpenModalAllocationData } = this.state;
    const { t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    return this.setState({ isOpenModalAllocationData: !isOpenModalAllocationData });
  };

  onHandleChange = ({ name, value }) => {
    const { data, batchFileOptions } = this.state;
    const newData = cloneDeep(data);
    if (fieldInvoices.indexOf(name) > -1) {
      newData.paymentInvoices[0][name] = value || null;
      return this.setState({ data: newData });
    }
    newData[name] = value || null;
    if (name === 'bankAccount') {
      const itemSelect = batchFileOptions.find(val => val.value === value);
      newData.currency = itemSelect?.currency ? itemSelect.currency : null;
    }
    return this.setState({ data: newData });
  };

  onHandleChangeAddress = ({ name, value }) => {
    const { dataAddress } = this.state;
    dataAddress[name] = value;
    this.setState({ ...dataAddress });
  };

  onToggleNewAddress = () => {
    const { isNewAddress } = this.state;
    this.setState({ isNewAddress: !isNewAddress });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { applyPayment, accountId, t } = this.props;
    const { data, dataAddress, isNewAddress, listItems } = this.state;
    let payload = {};
    payload = cloneDeep(data);
    if (isNewAddress) {
      payload.paymentAddresses = dataAddress;
    }

    if (payload.note) {
      payload.reference = payload.note;
      delete payload.note;
    }

    if (listItems && listItems.length) {
      payload.allocationData = listItems.map(val => {
        const { invoiceUnitId, amount, accountId } = val;
        return { invoiceUnitId, amount, accountId };
      });
    }
    applyPayment(payload, dt => {
      if (dt && dt.success) {
        const resetData = {
          accountId: accountId || '',
          paymentInvoices: [{}],
          // transactionId: 'TrID001',
          source: 'AGENT_CARE',
          userId: getUserId() || 'UserX002',
          paymentDate: moment().format('YYYY-MM-DD'),
          method: paymentEnum.paymentMethod.CHECK,
          currency: localStorage.getItem('currency') || null,
        };
        const resetAddress = {
          country: '',
          state: '',
          city: '',
        };
        // if (dt?.data?.id) {
        //   this.onAllocatePayment(dt?.data?.id);
        // } else {
        //   toast.error(t('message.messagePaymentGoneSuspense'));
        // }
        this.setState({ data: resetData, dataAddress: resetAddress, listItems: [], isOpenModalAllocationData: false });
      }
    });
  };

  // onAllocatePayment = id => {
  //   const { allocatePayment } = this.props;
  //   const { listItems } = this.state;
  //   const payloadRequest = {
  //     id,
  //     userId: getUserId() || 'UserX001',
  //     allocationData:
  //       listItems && listItems.length
  //         ? listItems.map(val => {
  //             const { amount, invoiceUnitId, accountId } = val;
  //             return { amount, invoiceUnitId, accountId };
  //           })
  //         : [],
  //   };
  //   allocatePayment(payloadRequest, ({ success }) => {
  //     if (success) {
  //       this.setState({ selectedPayment: {}, dataReverse: {}, listItems: [], isOpenModalAllocationData: false });
  //     }
  //   });
  // };

  renderPaymentDetails = ({ totalAmount, isInvoiceModal, isSupportMultiSelect }) => {
    const { data } = this.state;
    const { t } = this.props;

    return (
      <div className={`col-md-12 row border-bottom pt-3 pb-3 ${isInvoiceModal ? 'pl-3' : ''}`}>
        {paymentDetails.map(val => {
          let value = data ? data[val.name] : '';
          if (val.name === 'paymentAmount' && data) {
            value = data.amount || '0';
          }
          if (val.name === 'amount' && data) {
            value = formatMoneyValue(
              Number.parseFloat(data[val.name] || 0) - Number.parseFloat(totalAmount || 0) || null
            );
            if (!value || Number.isNaN(value) || value === 'NaN') value = '0';
          }
          // console.log('asdasd', val.name, value);
          return <GenericInput value={value || ''} onChange={() => {}} wrapperClass="col-md-3" readOnly {...val} />;
        })}
        {isSupportMultiSelect && (
          <button
            type="button"
            className="button button-border x-small mr-2 mt-4 mb-2 float-right"
            onClick={() => this.onToggleInvoice({ isMultiple: true, index: null })}
            disabled={!data.accountId || Number.parseFloat(data.amount) === totalAmount}
          >
            {t('label.selectInvoice')}
          </button>
        )}
      </div>
    );
  };

  onAddNewItem = () => {
    try {
      const { listItems } = this.state;
      const newData = cloneDeep(listItems);
      newData.push({
        invoiceUnitId: null,
        amount: null,
        accountId: null,
      });

      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { listItems } = this.state;
    try {
      const newData = cloneDeep(listItems);
      newData.splice(index, 1);
      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectInvoice = (itemId, row) => {
    const { indexSelectAccountId, listItems, data } = this.state;
    const newData = cloneDeep(data);
    const { t, onChangeAccountId } = this.props;
    const checkExitInvoice = listItems.find(val => val.invoiceUnitId === itemId);
    if (checkExitInvoice) return toast.error(t('message.messageInvoiceIdAleadyExist'));
    if (itemId) {
      listItems[indexSelectAccountId].invoiceUnitId = itemId;
      listItems[indexSelectAccountId].due = row.due;
      listItems[indexSelectAccountId].invoiceDate = row.invoiceDate;
      listItems[indexSelectAccountId].dueDate = row.dueDate;
      listItems[indexSelectAccountId].total = row.total;
      listItems[indexSelectAccountId].type = row.type;
      listItems[indexSelectAccountId].accountId = row.accountId;
      listItems[indexSelectAccountId].clientId = row.clientId;
      listItems[indexSelectAccountId].organization = row.organization;
      listItems[indexSelectAccountId].folioStatus = row.folioStatus;
      if (onChangeAccountId && newData.accountId !== row.accountId) {
        onChangeAccountId(row.accountId, currency => {
          newData.currency = currency || null;
          this.setState({ data: newData });
          this.doGetCrossCurrencyPaymentDetails({
            accountId: row.accountId,
            invoiceId: itemId,
            paymentDate: data?.paymentDate,
            paymentCurrency: currency || null,
            indexSelectAccountId,
          });
        });
      } else {
        this.doGetCrossCurrencyPaymentDetails({
          accountId: row.accountId,
          invoiceId: itemId,
          paymentDate: data?.paymentDate,
          paymentCurrency: data?.currency,
          indexSelectAccountId,
        });
      }
      newData.accountId = row.accountId;
    }
    this.setState({ listItems: [...listItems], isOpenModalInvoice: false, data: newData });
  };

  onSelectMultipleInvoice = (ids, rows) => {
    const { listItems, data } = this.state;
    let listItemsSelect = [];
    if (ids && ids.length) {
      rows.forEach(val => {
        const item = {};
        item.invoiceUnitId = val.id;
        item.due = val.due;
        item.invoiceDate = val.invoiceDate;
        item.dueDate = val.dueDate;
        item.total = val.total;
        item.type = val.type;
        item.accountId = val.accountId;
        item.clientId = val.clientId;
        item.organization = val.organization;
        item.folioStatus = val.folioStatus;
        item.amount = null;
        listItemsSelect.push(item);
      });
    }
    if (listItemsSelect && listItemsSelect.length) {
      if (listItems && listItems.length) {
        listItemsSelect = listItemsSelect.filter(val => !listItems.find(it => it.invoiceUnitId === val.invoiceUnitId));
      }

      return this.setState({ listItems: [...listItems, ...listItemsSelect], isOpenModalInvoice: false }, () => {
        listItemsSelect.forEach((it, index) => {
          this.doGetCrossCurrencyPaymentDetails({
            accountId: it.accountId,
            invoiceId: it.invoiceUnitId,
            paymentDate: data?.paymentDate,
            paymentCurrency: data?.currency,
            indexSelectAccountId: listItems.length + index,
            // isNoCaulation: true,
          });
        });
      });
    }
    return this.setState({ listItems: [...listItems, ...listItemsSelect], isOpenModalInvoice: false });
  };

  doGetCrossCurrencyPaymentDetails = ({
    accountId,
    paymentDate,
    invoiceId,
    paymentCurrency,
    indexSelectAccountId,
    isNoCaulation,
  }) => {
    const { getCrossCurrencyPaymentDetails } = this.props;
    const { data } = this.state;
    const newData = cloneDeep(data);
    getCrossCurrencyPaymentDetails({ accountId, paymentDate, invoiceId, paymentCurrency }, ({ success, data }) => {
      if (success && data) {
        const { listItems } = this.state;
        if (listItems && listItems[indexSelectAccountId]) {
          listItems[indexSelectAccountId].invoiceCurrencyInvDue = data.invoiceCurrencyInvDue;
          listItems[indexSelectAccountId].paymentCurrencyInvDue = data.paymentCurrencyInvDue;
          listItems[indexSelectAccountId].exchangeRate = data.exchangeRate;
          listItems[indexSelectAccountId].invoiceCurrency = data.invoiceCurrency;

          if (!isNoCaulation) {
            let totalAmount = 0;
            listItems
              .filter(val => val.invoiceUnitId !== listItems[indexSelectAccountId].invoiceUnitId)
              .forEach(val => {
                const { amount } = val;
                totalAmount += Number.parseFloat(amount || 0);
              });

            const amountTotalForListRow = Number.parseFloat(newData.amount || 0) - totalAmount;
            if (amountTotalForListRow === 0) {
              listItems[indexSelectAccountId].amount = '0';
            }
            if (
              amountTotalForListRow > 0 &&
              Number.parseFloat(amountTotalForListRow) - Number.parseFloat(data.paymentCurrencyInvDue) < 0
            ) {
              listItems[indexSelectAccountId].amount = amountTotalForListRow;
            }
            if (Number.parseFloat(amountTotalForListRow) >= Number.parseFloat(data.paymentCurrencyInvDue)) {
              listItems[indexSelectAccountId].amount = Number.parseFloat(data.paymentCurrencyInvDue);
            }
            if (
              listItems[indexSelectAccountId].amount &&
              Number.parseFloat(listItems[indexSelectAccountId].amount) !== 0
            ) {
              listItems[indexSelectAccountId].amount = Number.parseFloat(listItems[indexSelectAccountId].amount);
            }
          }
          // console.log('listItems', listItems)
        }
        this.setState({ listItems: [...listItems] });
      }
    });
  };

  onChangeInvoiceAmount = ({ name, value, index, item }) => {
    const { listItems, data } = this.state;
    let totalAmount = 0;
    if (Number.parseFloat(value) < 0) return '';
    listItems
      .filter(val => val.invoiceUnitId !== listItems[index].invoiceUnitId)
      .forEach(val => {
        const { amount } = val;
        totalAmount += Number.parseFloat(amount || 0);
      });
    const amountTotalForListRow = Number.parseFloat(data.amount || 0) - totalAmount;
    if (amountTotalForListRow < Number.parseFloat(value)) return '';
    if (item.paymentCurrencyInvDue < Number.parseFloat(value)) return '';
    listItems[index].amount = value;
    this.setState({ listItems: [...listItems] });
  };

  doGetObjectFileById = invoiceId => {
    const { history, getObjectFileById, id } = this.props;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.prettifyPath(RouteNames.paymentOneOff.path),
            fileData: data,
            data: this.state.data,
          },
        });
      }
    });
  };

  render() {
    const {
      data,
      isOpenModalInvoice,
      isNewAddress,
      dataAddress: { country, state, city },
      dataAddress,
      batchFileOptions,
      isTimeAndBillingDeployment,
      isOpenModalAllocationData,
      listItems,
      isPacEnabled,
      wasValidated,
      isMultipleSelect,
      isFolioStamping,
    } = this.state;
    const { modeProcessPayment, t } = this.props;
    let totalAmount = 0;
    let isDisableApply = false;
    listItems.forEach(val => {
      const { amount, invoiceUnitId, accountId } = val;
      totalAmount += Number.parseFloat(amount || 0);
      if (!accountId || !invoiceUnitId) isDisableApply = true;
    });

    const folioStatusFilter = isFolioStamping ? { folioStatus: 'STAMPED' } : {};

    const invoiceColumns = [
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        label: 'label.invoiceId',
        name: 'invoiceUnitId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item.invoiceUnitId}
              name="invoiceUnitId"
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              onClick={() => this.onToggleInvoice({ item, index })}
              fa="fa fa-external-link"
            />
          );
        },
      },
      {
        label: 'label.accountId',
        name: 'accountId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountId}
            name="accountId"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            // onClick={() => this.onToogleModalAccount({ item, index })}
            // fa={item.invoiceUnitId ? null : 'fa fa-external-link'}
            readOnly
            // readOnly={!!item.invoiceUnitId}
            // disabled={!!item.invoiceUnitId}
          />
        ),
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'clientId',
        label: 'common:label.clientId',
      },
      {
        name: 'amount',
        label: 'common:label.allocateAmount',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.amount}
            name="amount"
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeInvoiceAmount({ name, value, item, index })}
          />
        ),
      },
      {
        name: 'invoiceCurrency',
        label: 'common:label.accountCurrency',
      },
      {
        name: 'invoiceCurrencyInvDue',
        label: 'common:label.invoiceDueInAccountCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'paymentCurrencyInvDue',
        label: 'common:label.invoiceDueInPaymentCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'exchangeRate',
        label: 'common:label.exchangeRate',
      },
      {
        name: 'invoiceUnitId',
        label: 'common:label.invoiceId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              disabled={isPacEnabled && statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
              onClick={() => this.doGetObjectFileById(item.invoiceUnitId)}
            >
              {item.invoiceUnitId}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'common:label.invoiceDate',
      },
      {
        name: 'dueDate',
        label: 'common:label.dueDate',
      },
      {
        name: 'total',
        label: 'common:label.invoiceTotal',
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
    ];
    return (
      <div>
        <form
          onSubmit={this.onHandleSubmit}
          className={`form-group col-md-12 ${wasValidated ? 'was-validated' : ''} `}
          noValidate
          ref={this.formRef}
        >
          {/* <TitleFrom title={t('label.invoicePaymentType')} /> */}
          <div className="col-md-12 ml-4 d-flex flex-wrap mt-3">
            {dataFiledInvoicePayment({ batchFileOptions, data, isTimeAndBillingDeployment }).map((item, index) => {
              return (
                <>
                  {index === 7 && (
                    <TitleFrom title={t('label.options')} wrapperClass="card-title title-form pl-0 mt-3" />
                  )}
                  {(index === 9 || index === 12) && (
                    <div className="separation-border-lower col-md-12 mt-4 mb-2 pl-3 text-center">
                      <span className="middle-text-with-border">{t('label.or')}</span>
                    </div>
                  )}
                  <GenericInput
                    key={item.label}
                    onClick={() => (item.fa ? this.onToggleModalInvoiceAllocationData() : {})}
                    onBlur={() => (item.fa ? this.setState({ wasValidated: false }) : {})}
                    type={item.type || 'text'}
                    value={
                      fieldInvoices.indexOf(item.name) > -1 ? data.paymentInvoices[0][item.name] : data[item.name] || ''
                    }
                    onChange={this.onHandleChange}
                    fa={item.fa || ''}
                    wrapperClass="col-md-4"
                    {...item}
                  />
                </>
              );
            })}
          </div>
        </form>
        <ModalWithItem
          isOpen={isOpenModalAllocationData}
          onToggle={this.onToggleModalInvoiceAllocationData}
          modalTitle={t('label.paymentAllocation')}
          wrapperClass="modal-70 modal-custom"
        >
          {this.renderPaymentDetails({ totalAmount, isInvoiceModal: true, isSupportMultiSelect: true })}
          <br />
          <br />
          <DataTable columns={invoiceColumns} data={listItems || []} />
          <div className="col-sm-12 mt-4 mb-30">
            <button
              type="button"
              disabled={Number.parseFloat(data.amount) === totalAmount}
              onClick={this.onAddNewItem}
              className="button mb-4 button-border ml-3 black x-small"
            >
              +
              {t('label.addNewRow')}
            </button>
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={this.onToggleModalInvoiceAllocationData}
            >
              {t('label.cancel')}
            </button>
            <button
              type="button"
              className="button button-border x-small mr-2 float-right"
              onClick={this.onHandleSubmit}
              disabled={isDisableApply}
            >
              {t('label.allocatePayment')}
            </button>
          </div>
        </ModalWithItem>
        <ModalInvoice
          isOpen={isOpenModalInvoice}
          onSelect={isMultipleSelect ? this.onSelectMultipleInvoice : this.onSelectInvoice}
          // accountId={data.accountId}
          onCancel={() => {
            this.setState({ isOpenModalInvoice: false });
          }}
          disabledSearchFields={
            (data.accountId && listItems.length > 1) || isMultipleSelect
              ? ['organization', 'clientId', 'accountId']
              : null
          }
          isTopChildren
          defaultSearchForm={
            data.accountId
              ? { ...folioStatusFilter, accountId: data.accountId, due: data?.amount || null }
              : { ...folioStatusFilter, due: data?.amount || null }
          }
          isManualPayment
          isMulitple={isMultipleSelect}
          isSpoortNoClearDisabledField
        >
          {this.renderPaymentDetails({ totalAmount, isInvoiceModal: true })}
        </ModalInvoice>
        <div className="form-group col-md-12 mb-5">
          <button
            disabled={modeProcessPayment === 1 || isOpenModalAllocationData}
            type="button"
            onClick={this.onToggleModalInvoiceAllocationData}
            className="button x-small float-right"
          >
            {t('label.allocatePayment')}
          </button>
        </div>
      </div>
    );
  }
}

InvoicePayment.propTypes = {
  accountId: PropTypes.string,
  applyPayment: PropTypes.func.isRequired,
  modeProcessPayment: PropTypes.number,
};

InvoicePayment.defaultProps = {
  accountId: '',
  modeProcessPayment: 2,
};
export default withTranslation('common')(InvoicePayment);
