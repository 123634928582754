import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RouteNames from '../../App/RouteNames';
import {
  manualInvoiceService,
  getPendingManualInvoice,
  deletePendingManualInvoice,
  saveManualInvoice,
  getOrderSummaryByAccountId,
} from '../actions';
import {
  Switch,
  DataTable,
  GenericInput,
  ModalViewNote,
  CollapsibleTable,
  TitleFrom,
  FormWithTableItem,
  // ModalAccept,
} from '../../../components/common';
import { getCostCenterConfig, getCcpPropertiesConfig } from '../../App/actions';
import ModalSearchItem from '../../../components/common/ModalSearchItem';
import ModalSelectBillableService from '../../../components/common/ModalSelectBillableService';
import { formatMoneyValue, formatNumberCaculation, validateNewLine } from '../../../utils/utils';

const messageNotesManualBilling = t => (
  <div className="note-content-stream">
    <div>
      <b>1. &nbsp;&nbsp;</b>
      {t('message.manualBillingStep1')}
    </div>
    <div>
      <b>2. &nbsp;&nbsp;</b>
      {t('message.manualBillingStep2')}
    </div>
    <div>
      <b>3. &nbsp;&nbsp;</b>
      {t('message.manualBillingStep3')}
    </div>
    <div>
      <b>4. &nbsp;&nbsp;</b>
      {t('message.manualBillingStep4')}
    </div>
    <div>
      <b>5. &nbsp;&nbsp;</b>
      {t('message.manualBillingStep5')}
    </div>
  </div>
);

const defaultItem = {
  startDate: null,
  endDate: null,
  serviceType: null,
  itemId: null,
  description: null,
  quantity: null,
  unitPrice: null,
  grossAmount: 0,
  discountAmount: 0,
  netAmount: 0,
  // costCenter: null,
};
class ManualBilling extends PureComponent {
  state = {
    dataSubmit: [],
    isOpenModalItem: false,
    txnDate: null,
    scheduleDaily: false,
    itemSelect: {},
    formValid: true,
    isOpenModalNotes: false,
    billStartDate: null,
    billEndDate: null,
    ccpTime: null,
    pendingManualInvoice: [],
    activeSubTab: {},
    isTransaction: false,
    isCreateNew: false,
    costCenterOptions: [],
    isEnableBilledServiceName: false,
    isOpenModalServiceName: false,
    // isOpenModalWarning: false,
    defaultCostCenter: null,
  };

  formRef = React.createRef();

  formRefTransaction = React.createRef();

  componentDidMount() {
    const { getCcpDateTimeConfig, getCostCenterConfig, getCcpPropertiesConfig, ccpTime } = this.props;
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success) {
          this.setState({
            ccpTime: data.ccpTime,
            billStartDate: data.ccpTime || null,
            billEndDate: data.ccpTime || null,
          });
        }
      });
    } else {
      this.setState({
        ccpTime,
        billStartDate: ccpTime || null,
        billEndDate: ccpTime || null,
      });
    }
    getCostCenterConfig({ showActiveOnly: 'true' }, ({ success, data }) => {
      if (success && data && data.length) {
        const costCenterOptions = data.map(val => {
          const { code, name } = val;
          return { label: `${code}-${name}`, value: code };
        });
        this.setState({ costCenterOptions: costCenterOptions ? sortBy(costCenterOptions, ['label']) : [] });
      }
    });
    getCcpPropertiesConfig('', ({ success, data }) => {
      if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
        const billedServiceProperty = data.ccpPropertyList.find(val => val.property === 'billableServiceEnabled');
        const defaultCostCenter = data.ccpPropertyList.find(val => val.property === 'defaultCostCenter');
        this.setState({
          isEnableBilledServiceName: !!(billedServiceProperty && billedServiceProperty.value === 'true'),
          defaultCostCenter: defaultCostCenter && defaultCostCenter.value ? defaultCostCenter.value : null,
        });
      }
    });
    this.doGetPendingManualInvoice();
  }

  doGetPendingManualInvoice = () => {
    const { getPendingManualInvoice, id } = this.props;
    getPendingManualInvoice({ id }, ({ success, data }) => {
      this.setState({
        pendingManualInvoice: success ? data || [] : [],
        isCreateNew: !(success && data && data.length),
      });
    });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  validateTransaction = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRefTransaction && this.formRefTransaction.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRefTransaction.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { dataSubmit } = this.state;
    const newData = cloneDeep(dataSubmit);
    newData[index][name] = value;

    if (name === 'quantity' || name === 'unitPrice' || name === 'discountAmount') {
      newData[index].grossAmount =
        newData[index].quantity && newData[index].unitPrice
          ? formatNumberCaculation(
              Number.parseFloat(newData[index].quantity) * Number.parseFloat(newData[index].unitPrice)
            )
          : 0;

      newData[index].netAmount =
        newData[index].quantity && newData[index].unitPrice
          ? formatNumberCaculation(
              Number.parseFloat(newData[index].quantity) * Number.parseFloat(newData[index].unitPrice) -
                Number.parseFloat(newData[index].discountAmount)
            ) || 0
          : 0;
    }
    this.setState({ dataSubmit: newData });
  };

  onChangeTableTransaction = ({ name, value, index }) => {
    // console.log('name, value', name, value);
    try {
      const { pendingManualInvoice, activeSubTab } = this.state;
      const newData = cloneDeep(pendingManualInvoice);
      newData[activeSubTab.index].manualPreratedTxnRec[index][name] = value;
      if (name === 'quantity' || name === 'unitPrice' || name === 'discountAmount') {
        newData[activeSubTab.index].manualPreratedTxnRec[index].grossAmount =
          newData[activeSubTab.index].manualPreratedTxnRec[index].quantity &&
          newData[activeSubTab.index].manualPreratedTxnRec[index].unitPrice
            ? formatNumberCaculation(
                Number.parseFloat(newData[activeSubTab.index].manualPreratedTxnRec[index].quantity) *
                  Number.parseFloat(newData[activeSubTab.index].manualPreratedTxnRec[index].unitPrice)
              )
            : 0;

        newData[activeSubTab.index].manualPreratedTxnRec[index].netAmount =
          newData[activeSubTab.index].manualPreratedTxnRec[index].quantity &&
          newData[activeSubTab.index].manualPreratedTxnRec[index].unitPrice
            ? formatNumberCaculation(
                Number.parseFloat(newData[activeSubTab.index].manualPreratedTxnRec[index].quantity) *
                  Number.parseFloat(newData[activeSubTab.index].manualPreratedTxnRec[index].unitPrice) -
                  Number.parseFloat(newData[activeSubTab.index].manualPreratedTxnRec[index].discountAmount)
              ) || 0
            : 0;
      }
      this.setState({ pendingManualInvoice: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTablePendingManualTransaction = ({ name, value, index }) => {
    try {
      const { pendingManualInvoice } = this.state;
      const newData = cloneDeep(pendingManualInvoice);
      newData[index][name] = value;
      this.setState({ pendingManualInvoice: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewManualTransaction = () => {
    const { dataSubmit, defaultCostCenter, costCenterOptions, txnDate, ccpTime } = this.state;
    const { id, currencyAccount } = this.props;
    let costCenter = null;
    const newData = cloneDeep(dataSubmit);
    if (
      costCenterOptions &&
      costCenterOptions.length &&
      defaultCostCenter &&
      costCenterOptions.find(val => val.value === defaultCostCenter)
    ) {
      costCenter = costCenterOptions.find(val => val.value === defaultCostCenter).value;
    }
    const newDataItem = {
      accountId: id,
      ...defaultItem,
      startDate: txnDate || ccpTime || null,
      endDate: txnDate || ccpTime || null,
      quantity: '1',
      costCenter,
      currency: currencyAccount || null,
    };
    newData.push(newDataItem);
    this.setState({ dataSubmit: newData });
  };

  addNewTransaction = () => {
    try {
      const { id, currencyAccount } = this.props;
      const { pendingManualInvoice, activeSubTab, defaultCostCenter, costCenterOptions, ccpTime } = this.state;
      const newData = cloneDeep(pendingManualInvoice);
      let costCenter = null;
      if (
        costCenterOptions &&
        costCenterOptions.length &&
        defaultCostCenter &&
        costCenterOptions.find(val => val.value === defaultCostCenter)
      ) {
        costCenter = costCenterOptions.find(val => val.value === defaultCostCenter).value;
      }
      const newDataItem = {
        accountId: id,
        ...defaultItem,
        costCenter,
        startDate:
          newData && newData[activeSubTab.index] && newData[activeSubTab.index].txnDate
            ? newData[activeSubTab.index].txnDate
            : ccpTime || null,
        endDate:
          newData && newData[activeSubTab.index] && newData[activeSubTab.index].txnDate
            ? newData[activeSubTab.index].txnDate
            : ccpTime || null,
        // endDate: txnDate || ccpTime || null,
        quantity: '1',
        currency: currencyAccount || null,
        isNew: true,
      };
      if (!newData[activeSubTab.index].manualPreratedTxnRec) newData[activeSubTab.index].manualPreratedTxnRec = [];
      newData[activeSubTab.index].manualPreratedTxnRec.push(newDataItem);
      this.setState({ pendingManualInvoice: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewTransactionPending = () => {
    const { id } = this.props;
    const { pendingManualInvoice } = this.state;
    const newData = cloneDeep(pendingManualInvoice);
    newData.push({
      billStartDate: null,
      billEndDate: null,
      accountId: id,
      isNew: true,
      scheduleDate: null,
    });
    this.setState({ pendingManualInvoice: newData });
  };

  onRemoveItemTable = idx => {
    const { dataSubmit } = this.state;
    const newData = cloneDeep(dataSubmit);
    newData.splice(idx, 1);
    this.setState({ dataSubmit: newData });
  };

  onRemoveItemTableTransaction = idx => {
    try {
      const { pendingManualInvoice, activeSubTab } = this.state;
      const newData = cloneDeep(pendingManualInvoice);
      newData[activeSubTab.index].manualPreratedTxnRec.splice(idx, 1);
      this.setState({ pendingManualInvoice: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItemNewTransaction = (idx, isNew) => {
    try {
      const { pendingManualInvoice } = this.state;
      const newData = cloneDeep(pendingManualInvoice);
      if (isNew) {
        newData.splice(idx, 1);
        this.setState({ pendingManualInvoice: newData });
      } else {
        const { deletePendingManualInvoice } = this.props;
        deletePendingManualInvoice(newData[idx].savedManualInvoiceId, ({ success }) => {
          if (success) this.doGetPendingManualInvoice();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  hanldeSubmit = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { manualInvoiceService, id, history, doGetPendingOCBills } = this.props;
    const { txnDate, scheduleDaily, dataSubmit, billStartDate, billEndDate, scheduleDate } = this.state;
    const newsSheduleDaily = scheduleDaily === 'TRUE';
    manualInvoiceService(
      {
        txnDate,
        billStartDate: billStartDate || null,
        billEndDate: billEndDate || null,
        scheduleDaily: newsSheduleDaily,
        accountId: id,
        scheduleDate: scheduleDate || null,
        manualPreratedTxnRec:
          dataSubmit && dataSubmit.length
            ? dataSubmit.map(val => {
                const { description, customDescription, ...rest } = val;
                return { ...rest, description: customDescription };
              })
            : null,
      },
      ({ success }) => {
        doGetPendingOCBills();
        if (success) history.push(RouteNames.prettifyPath(RouteNames.customerDetailsBills.path, id));
      }
    );
  };

  onHanldeProcess = (e, index) => {
    e.preventDefault();
    this.setState({ wasValidatedTranSaction: true });
    if (!this.validateTransaction(true)) {
      return false;
    }
    const { manualInvoiceService } = this.props;
    const { pendingManualInvoice } = this.state;
    const data = cloneDeep(pendingManualInvoice[index]);
    const { errorReason, status, substitutedInvoiceStatus, ...newPayload } = data;
    if (newPayload.manualPreratedTxnRec) {
      newPayload.manualPreratedTxnRec = newPayload.manualPreratedTxnRec.map(val => {
        const { description, customDescription, isNew, ...rest } = val;
        return { ...rest, description: customDescription || description };
      });
    }
    return manualInvoiceService(newPayload, ({ success }) => {
      if (success) {
        this.doGetPendingManualInvoice();
        this.setState({ wasValidatedTranSaction: false, activeSubTab: {} });
      }
    });
  };

  onSaveManualInvoice = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { saveManualInvoice, id } = this.props;
    const { txnDate, scheduleDaily, dataSubmit, billStartDate, billEndDate, scheduleDate } = this.state;
    const newsSheduleDaily = scheduleDaily === 'TRUE';
    saveManualInvoice(
      {
        txnDate,
        billStartDate: billStartDate || null,
        billEndDate: billEndDate || null,
        scheduleDaily: newsSheduleDaily,
        accountId: id,
        scheduleDate: scheduleDate || null,
        manualPreratedTxnRec:
          dataSubmit && dataSubmit.length
            ? dataSubmit.map(val => {
                const { description, customDescription, ...rest } = val;
                return { ...rest, description: customDescription };
              })
            : null,
      },
      ({ success }) => {
        if (success) {
          this.setState({
            dataSubmit: [],
            txnDate: null,
            scheduleDaily: false,
            itemSelect: {},
            formValid: true,
            wasValidated: false,
            scheduleDate: null,
          });
          this.doGetPendingManualInvoice();
        } else {
          this.setState({
            wasValidated: false,
          });
        }
      }
    );
  };

  onSaveManualInvoiceItem = (e, index) => {
    e.preventDefault();
    this.setState({ wasValidatedTranSaction: true });
    if (!this.validateTransaction(true)) {
      return false;
    }
    const { saveManualInvoice, t } = this.props;
    const { pendingManualInvoice } = this.state;
    let isNeedValidate = false;
    const data = cloneDeep(pendingManualInvoice[index]);
    const { errorReason, status, substitutedInvoiceStatus, ...newPayload } = data;
    if (newPayload.manualPreratedTxnRec) {
      newPayload.manualPreratedTxnRec = newPayload.manualPreratedTxnRec.map(val => {
        if (
          !val.itemId ||
          (!val.quantity && Number.parseFloat(val.quantity) !== 0) ||
          (!val.unitPrice && Number.parseFloat(val.unitPrice) !== 0)
        ) {
          isNeedValidate = true;
        }
        const { description, customDescription, isNew, ...rest } = val;
        return { ...rest, description: customDescription };
      });
    }
    if (isNeedValidate) return toast.error(t('message.mandatory'));
    return saveManualInvoice(newPayload, ({ success }) => {
      if (success) {
        this.doGetPendingManualInvoice();
      }
      this.setState({
        wasValidatedTranSaction: false,
        activeSubTab: {},
      });
    });
  };

  onToggle = idx => {
    const { isOpenModalItem, dataSubmit } = this.state;
    this.setState({
      isOpenModalItem: !isOpenModalItem,
      indexSelect: idx,
      itemSelect: dataSubmit[idx],
      isTransaction: false,
    });
  };

  onToggleModalServiceName = idx => {
    const { isOpenModalServiceName, dataSubmit } = this.state;
    if (!isOpenModalServiceName) {
      this.setState({
        isOpenModalServiceName: !isOpenModalServiceName,
        indexSelect: idx,
        itemSelect: dataSubmit[idx],
        isTransaction: false,
      });
    }
  };

  onToggleItemTransaction = idx => {
    try {
      const { isOpenModalItem, pendingManualInvoice, activeSubTab } = this.state;
      this.setState({
        isOpenModalItem: !isOpenModalItem,
        indexSelect: idx,
        itemSelect: pendingManualInvoice[activeSubTab.index].manualPreratedTxnRec[idx],
        isTransaction: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleTransactionModalServiceName = idx => {
    try {
      const { isOpenModalServiceName, pendingManualInvoice, activeSubTab } = this.state;
      this.setState({
        isOpenModalServiceName: !isOpenModalServiceName,
        indexSelect: idx,
        itemSelect: pendingManualInvoice[activeSubTab.index].manualPreratedTxnRec[idx],
        isTransaction: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onSelectItemId = (id, row) => {
    const { indexSelect, dataSubmit, isTransaction, pendingManualInvoice, activeSubTab } = this.state;
    const newData = cloneDeep(pendingManualInvoice);
    if (id && !isTransaction) {
      dataSubmit[indexSelect].itemId = id;
      dataSubmit[indexSelect].description = row.description;
      dataSubmit[indexSelect].customDescription = row.description;
      dataSubmit[indexSelect].serviceName = null;
      dataSubmit[indexSelect].omsInternalId = null;
      this.setState({ isOpenModalItem: false, dataSubmit });
    }
    if (id && isTransaction) {
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].itemId = id;
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].description = row.description;
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].customDescription = row.description;
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].serviceName = null;
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].omsInternalId = null;
      this.setState({ isOpenModalItem: false, pendingManualInvoice: newData });
    }
  };

  onSelectServiceName = (id, row) => {
    const { indexSelect, dataSubmit, isTransaction, pendingManualInvoice, activeSubTab } = this.state;
    const newData = cloneDeep(pendingManualInvoice);
    if (id && !isTransaction) {
      dataSubmit[indexSelect].omsInternalId = row.omsInternalId;
      dataSubmit[indexSelect].serviceName = row.serviceName;
      dataSubmit[indexSelect].itemId = row.serviceId;
      dataSubmit[indexSelect].description = row.serviceName;
      dataSubmit[indexSelect].customDescription = row.serviceName;
      // if (!dataSubmit[indexSelect].customDescription) {
      //   dataSubmit[indexSelect].description = row.serviceName;
      //   dataSubmit[indexSelect].customDescription = row.serviceName;
      // }
      this.setState({ isOpenModalServiceName: false, dataSubmit });
    }
    if (id && isTransaction) {
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].omsInternalId = row.omsInternalId;
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].serviceName = row.serviceName;
      newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].itemId = row.serviceId;
      if (!newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].customDescription) {
        newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].description = row.serviceName;
        newData[activeSubTab.index].manualPreratedTxnRec[indexSelect].customDescription = row.serviceName;
      }

      this.setState({ isOpenModalServiceName: false, pendingManualInvoice: newData });
    }
  };

  onChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onToggleModalNotes = () => {
    const { isOpenModalNotes } = this.state;
    this.setState({ isOpenModalNotes: !isOpenModalNotes });
  };

  onCancel = () => {
    this.doGetPendingManualInvoice();
    this.setState({
      dataSubmit: [],
      isOpenModalItem: false,
      txnDate: null,
      scheduleDaily: false,
      itemSelect: {},
      formValid: true,
      isOpenModalNotes: false,
      billStartDate: null,
      billEndDate: null,
      scheduleDate: null,
    });
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem) this.setState({ activeSubTab: {} });
    if (activeSubTab.index !== indexItem) {
      this.setState({
        activeSubTab: { key, index: indexItem },
      });
    }
  };

  // onHandleSubmitModalWarning = () => {
  //   this.setState({ isOpenModalServiceName: true, isOpenModalWarning: false });
  // };

  render() {
    const {
      dataSubmit,
      isOpenModalItem,
      itemSelect,
      scheduleDaily,
      txnDate,
      billStartDate,
      billEndDate,
      wasValidated,
      isOpenModalNotes,
      pendingManualInvoice,
      activeSubTab,
      isSearching,
      wasValidatedTranSaction,
      isCreateNew,
      costCenterOptions,
      scheduleDate,
      isEnableBilledServiceName,
      isOpenModalServiceName,
      // isOpenModalWarning,
    } = this.state;
    const { t, id, currencyOptions, getOrderSummaryByAccountId, customerInfo } = this.props;

    const manualBillingColumns = [
      {
        name: 'txnDate',
        label: t('label.startDate'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.startDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="startDate"
          />
        ),
      },
      {
        name: 'txnDate',
        label: t('label.endDate'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.endDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="endDate"
          />
        ),
      },
      {
        name: 'serviceType',
        label: t('label.serviceType'),
        style: { minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceType}
            tOptions="selections:serviceType"
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="serviceType"
          />
        ),
      },
      {
        name: 'itemId',
        label: t('label.itemId'),
        style: { minWidth: '220px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.itemId}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="itemId"
            onClick={() => this.onToggle(idx)}
            fa="fa fa-external-link"
            required
          />
        ),
      },
      {
        name: 'serviceName',
        label: t('label.billableServiceName'),
        style: { minWidth: '280px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceName}
            wrapperClass="col-md-12 p-0"
            onChange={() => {}}
            name="serviceName"
            onClick={() => this.onToggleModalServiceName(idx)}
            fa="fa fa-external-link"
            disabled={!isEnableBilledServiceName}
          />
        ),
      },
      {
        name: 'costCenter',
        label: t('label.costCenter'),
        style: { minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.costCenter}
            options={costCenterOptions || []}
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="costCenter"
          />
        ),
      },
      {
        name: 'description',
        label: t('label.description'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.description}
            type="textarea"
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="description"
            readOnly
          />
        ),
      },
      {
        name: 'customDescription',
        label: t('label.customDescription'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.customDescription}
            type="textarea"
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="customDescription"
            isNoEnter
            message="message.messageValidNewLine"
          />
        ),
      },
      {
        name: 'quantity',
        label: t('label.quantity'),
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.quantity}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="quantity"
            type="number"
            required
          />
        ),
      },
      {
        name: 'currency',
        label: t('label.currency'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.currency}
            options={currencyOptions || []}
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="currency"
          />
        ),
      },
      {
        name: 'unitPrice',
        label: t('label.unitPrice'),
        style: { minWidth: '200px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.unitPrice}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="unitPrice"
            type="number"
            required
          />
        ),
      },
      {
        name: 'grossAmount',
        label: t('label.grossAmount'),
        style: { minWidth: '200px' },
        // render: (colName, item, idx) => <span>{formatMoneyValue(item.grossAmount)}</span>,
      },
      {
        name: 'discountAmount',
        label: t('label.discountAmount'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.discountAmount}
            wrapperClass="col-md-12 p-0"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="discountAmount"
          />
        ),
      },
      {
        name: 'netAmount',
        label: t('label.netAmount'),
        style: { minWidth: '200px' },
        // render: (colName, item, idx) => <span>{formatMoneyValue(item.netAmount)}</span>,
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12 p-0">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable(idx)}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const manualPendingBillingColumns = [
      {
        name: 'txnDate',
        label: t('label.startDate'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.startDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="startDate"
          />
        ),
      },
      {
        name: 'txnDate',
        label: t('label.endDate'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.endDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            type="date"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="endDate"
          />
        ),
      },
      {
        name: 'serviceType',
        label: t('label.serviceType'),
        style: { minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceType}
            tOptions="selections:serviceType"
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="serviceType"
          />
        ),
      },
      {
        name: 'itemId',
        label: t('label.itemId'),
        style: { minWidth: '220px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.itemId}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="itemId"
            onClick={() => this.onToggleItemTransaction(idx)}
            fa="fa fa-external-link"
            required
          />
        ),
      },
      {
        name: 'serviceName',
        label: t('label.billableServiceName'),
        style: { minWidth: '280px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceName}
            wrapperClass="col-md-12 p-0"
            onChange={() => {}}
            name="serviceName"
            onClick={() => this.onToggleTransactionModalServiceName(idx)}
            fa="fa fa-external-link"
            disabled={!isEnableBilledServiceName}
          />
        ),
      },
      {
        name: 'costCenter',
        label: t('label.costCenter'),
        style: { minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.costCenter}
            options={costCenterOptions || []}
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="costCenter"
          />
        ),
      },
      {
        name: 'description',
        label: t('label.description'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.description}
            type="textarea"
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="description"
            readOnly
          />
        ),
      },
      {
        name: 'customDescription',
        label: t('label.customDescription'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.customDescription || item.description}
            type="textarea"
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="customDescription"
            isNoEnter
            message="message.messageValidNewLine"
          />
        ),
      },
      {
        name: 'quantity',
        label: t('label.quantity'),
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.quantity}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="quantity"
            type="number"
            required
          />
        ),
      },
      {
        name: 'currency',
        label: t('label.currency'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.currency}
            options={currencyOptions || []}
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="currency"
          />
        ),
      },
      {
        name: 'unitPrice',
        label: t('label.unitPrice'),
        style: { minWidth: '200px' },
        required: true,
        render: (colName, item, idx) => (
          <GenericInput
            value={item.unitPrice}
            wrapperClass="col-md-12 p-0"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="unitPrice"
            type="number"
            required
          />
        ),
      },
      {
        name: 'grossAmount',
        label: t('label.grossAmount'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => <span>{formatMoneyValue(item.grossAmount)}</span>,
      },
      {
        name: 'discountAmount',
        label: t('label.discountAmount'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.discountAmount}
            wrapperClass="col-md-12 p-0"
            type="number"
            onChange={({ name, value }) => this.onChangeTableTransaction({ name, value, index: idx })}
            name="discountAmount"
          />
        ),
      },
      {
        name: 'netAmount',
        label: t('label.netAmount'),
        style: { minWidth: '200px' },
        render: (colName, item, idx) => <span>{formatMoneyValue(item.netAmount)}</span>,
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12 p-0">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTableTransaction(idx)}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const tablePendingManualInvoiceColumns = [
      {
        name: 'lines',
        label: 'label.lines',
        style: { minWidth: 100, width: 100 },
        render: (colName, item, idx, indexParent, activeTab) => (
          <button type="button" className="btn-expand-table mr-3" onClick={evt => this.onToggleSubTab(idx, item, idx)}>
            <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'save',
        label: 'label.save',
        style: { minWidth: '100px', width: '100px' },
        render: (colName, item, index) => {
          return (
            <button
              type="button"
              className="button x-small apply-btn"
              disabled={!item.manualPreratedTxnRec || !item.manualPreratedTxnRec.length}
              onClick={evt => this.onSaveManualInvoiceItem(evt, index)}
            >
              {t('common:label.save')}
            </button>
          );
        },
      },
      {
        name: 'process',
        label: 'label.process',
        style: { minWidth: '100px', width: '100px' },
        render: (colName, item, index) => (
          <button
            type="button"
            className="button x-small apply-btn"
            disabled={item.isNew}
            onClick={evt => this.onHanldeProcess(evt, index)}
          >
            {t('common:label.process')}
          </button>
        ),
      },
      {
        name: 'scheduleDate',
        label: 'label.scheduleDate',
        style: { minWidth: '200px', width: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.scheduleDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            onChange={({ name, value }) => this.onChangeTablePendingManualTransaction({ name, value, index })}
            name="scheduleDate"
            type="date"
          />
        ),
      },
      {
        name: 'billStartDate',
        label: 'label.billStartDate',
        style: { minWidth: '200px', width: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.billStartDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            onChange={({ name, value }) => this.onChangeTablePendingManualTransaction({ name, value, index })}
            name="billStartDate"
            type="date"
          />
        ),
      },
      {
        name: 'billEndDate',
        label: 'label.billEndDate',
        style: { minWidth: '200px', width: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.billEndDate}
            wrapperClass="col-md-12 p-0 inner-popover"
            onChange={({ name, value }) => this.onChangeTablePendingManualTransaction({ name, value, index })}
            name="billEndDate"
            type="date"
          />
        ),
      },
      {
        name: 'errorReason',
        label: 'label.errorReason',
        style: { minWidth: '200px', width: '100px' },
      },
      {
        name: 'status',
        label: 'label.status',
        style: { minWidth: '200px', width: '100px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:manualInvoiceStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'substituteInvoice',
        label: 'label.substituteInvoice',
        style: { minWidth: '100px', width: '100px' },
        render: (colName, item) => {
          return <span>{item.substituteInvoice ? 'TRUE' : item.substituteInvoice === false ? 'FALSE' : null}</span>;
        },
      },
      {
        name: 'invoiceId',
        label: 'label.invoiceId',
        style: { minWidth: '100px', width: '100px' },
      },
      {
        name: 'substitutedInvoiceStatus',
        label: 'label.substitutedInvoiceStatus',
        style: { minWidth: '150px', width: '150px' },
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t
            ? t('selections:invoiceUnitStatus')().find(val => val.value === item.substitutedInvoiceStatus)
            : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12 p-0">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onRemoveItemNewTransaction(idx, item.isNew)}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    let currencyLabel = '';
    if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
      const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
      currencyLabel = currencySelect ? currencySelect.normalLabel : '';
    }

    return (
      <div className="col-md-12 mb-5">
        <FormWithTableItem
          title={t('label.newManualInvoice')}
          subClass="border-bottom"
          accountNum={id}
          commercialName={customerInfo?.commercialName || ''}
          isCommercialName={customerInfo?.customerSegment !== 'B2C'}
          currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        >
          {/* <FormWithTableItem title={t('label.newManualInvoice')}> */}
          {/* <div className="card card-statistics form-with-item"> */}
          <div className="d-block d-md-flex justify-content-between">
            <div className="col-md-12 d-flex">
              {/* <h5 className="card-title title-form">{t('label.newManualInvoice')}</h5> */}
              {isEnableBilledServiceName && (
                <button
                  type="button"
                  className="button button-border gray x-small button-quick-note-billing"
                  onClick={this.onToggleModalNotes}
                >
                  {t('label.quickNotes')}
                  <i className="fa fa-external-link ml-2" aria-hidden="true" />
                </button>
              )}
              <Switch
                onChange={() => {
                  this.setState({ isCreateNew: !isCreateNew });
                }}
                wrapperClass={`switch-billing ${
                  isCreateNew ? 'switch-active float-right' : 'switch-non-active float-right'
                }`}
                title={t('label.createNew')}
                checked={isCreateNew}
              />
            </div>
          </div>
          <br />
          <div className="mb-2">
            {isCreateNew && (
              <form
                className={`needs-validation col-sm-12 mb-30 ${wasValidated ? 'was-validated' : ''}`}
                noValidate
                ref={this.formRef}
              >
                <div className="col-md-12 row p-3 pb-5">
                  <GenericInput
                    value={billStartDate}
                    wrapperClass="col-md-4"
                    type="date"
                    onChange={this.onChange}
                    name="billStartDate"
                    label="label.billStartDate"
                  />
                  <GenericInput
                    value={billEndDate}
                    wrapperClass="col-md-4"
                    type="date"
                    onChange={this.onChange}
                    name="billEndDate"
                    label="label.billEndDate"
                  />
                  <GenericInput
                    value={txnDate}
                    wrapperClass="col-md-4"
                    type="date"
                    onChange={this.onChange}
                    name="txnDate"
                    label="label.transactionDate"
                  />
                  <GenericInput
                    value={scheduleDaily}
                    wrapperClass="col-md-4"
                    type="select"
                    onChange={this.onChange}
                    name="scheduleDaily"
                    tOptions="selections:selectBool"
                    label="label.scheduleWithDailyJobs"
                  />
                  <GenericInput
                    value={scheduleDate}
                    wrapperClass="col-md-4"
                    type="date"
                    onChange={this.onChange}
                    name="scheduleDate"
                    label="label.scheduleDate"
                  />
                </div>
                <div>
                  <DataTable columns={manualBillingColumns} data={dataSubmit} />
                  <br />
                </div>
                <div className="form-group col-md-12 buttons-attibute">
                  <button
                    type="button"
                    className="button button-border black x-small ml-2"
                    onClick={this.addNewManualTransaction}
                  >
                    +
                    {t('label.addNewManualTransaction')}
                  </button>
                  <button
                    type="button"
                    onClick={this.hanldeSubmit}
                    className="button button-border x-small ml-2 float-right"
                    disabled={!dataSubmit || !dataSubmit.length}
                  >
                    {t('label.runManualBill')}
                  </button>
                  <button
                    onClick={this.onCancel}
                    type="button"
                    className="button button-border black x-small float-right"
                  >
                    {t('label.cancel')}
                  </button>
                  <button
                    type="button"
                    // disabled={(pendingManualInvoice && !pendingManualInvoice.find(val => !!val.isNew)) || isDisableSave}
                    onClick={this.onSaveManualInvoice}
                    className="button x-small mr-2 float-right"
                    disabled={!dataSubmit || !dataSubmit.length}
                  >
                    {t('label.save')}
                  </button>
                </div>
              </form>
            )}
            <form
              className={`needs-validation col-sm-12 mb-30 table-responsive form-focus ${
                wasValidatedTranSaction ? 'was-validated' : ''
              }`}
              noValidate
              ref={this.formRefTransaction}
            >
              <TitleFrom title={t('label.pendingManualInvoices')} />
              <div className="group-collapsible">
                <CollapsibleTable
                  indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
                  columns={tablePendingManualInvoiceColumns}
                  data={pendingManualInvoice || []}
                  isLoading={isSearching}
                  isViewOnly
                  isFixedHeaderTable
                >
                  <DataTable
                    columns={manualPendingBillingColumns}
                    isSupportRemoveIndex
                    data={
                      pendingManualInvoice &&
                      pendingManualInvoice[activeSubTab.index] &&
                      pendingManualInvoice[activeSubTab.index].manualPreratedTxnRec &&
                      activeSubTab &&
                      activeSubTab.index !== -1
                        ? pendingManualInvoice[activeSubTab.index].manualPreratedTxnRec
                        : []
                    }
                  />
                  {pendingManualInvoice &&
                    pendingManualInvoice[activeSubTab.index] &&
                    activeSubTab &&
                    activeSubTab.index !== -1 && (
                      <button
                        type="button"
                        className="button button-border black x-small mb-4 ml-2"
                        onClick={this.addNewTransaction}
                      >
                        +
                        {t('label.addNewManualTransaction')}
                      </button>
                    )}
                </CollapsibleTable>
              </div>
            </form>
            <br />
            {/* </div> */}
          </div>
          <ModalSearchItem
            isOpen={isOpenModalItem}
            onSelect={this.onSelectItemId}
            onCancel={this.onToggle}
            selectedParentId={itemSelect && itemSelect.itemId ? itemSelect.itemId : ''}
            defaultSearchParams={{ type: 'REVENUE', status: 'SELLABLE' }}
            disabledSearchFields={['status']}
            defaultSearchForm={{ status: 'SELLABLE' }}
            defaultFilter={{ status: 'SELLABLE' }}
          />
          <ModalSelectBillableService
            isOpen={isOpenModalServiceName}
            onSelect={this.onSelectServiceName}
            onCancel={() => {
              this.setState({ isOpenModalServiceName: false });
            }}
            selectedParentId={itemSelect && itemSelect.omsInternalId ? itemSelect.omsInternalId : ''}
            accountId={id}
            getOrderSummaryByAccountId={getOrderSummaryByAccountId}
          />
          <ModalViewNote
            isOpen={isOpenModalNotes}
            message={messageNotesManualBilling(t)}
            onToggle={this.onToggleModalNotes}
          />
          {/* <ModalAccept
          onAcceptModal={this.onHandleSubmitModalWarning}
          isOpen={isOpenModalWarning}
          message={t('common:message.warningMessageSelectServiceName')}
          onToggle={() => {
            this.setState({
              isOpenModalWarning: !isOpenModalWarning,
            });
          }}
          headerMessage={t('label.warning')}
          isWarning
        /> */}
        </FormWithTableItem>
      </div>
    );
  }
}

ManualBilling.propTypes = {
  manualInvoiceService: PropTypes.func.isRequired,
  getCcpDateTimeConfig: PropTypes.func.isRequired,
};

const newManualBilling = connect(null, {
  manualInvoiceService,
  getPendingManualInvoice,
  deletePendingManualInvoice,
  saveManualInvoice,
  getCostCenterConfig,
  getCcpPropertiesConfig,
  getOrderSummaryByAccountId,
})(ManualBilling);

export default withTranslation('common')(newManualBilling);
