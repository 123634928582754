import gql from 'graphql-tag';

export default id => gql`
  query {
    getDiscountOfferAudit(id: "${id}") {
      id
      name
      status
      effectiveDate
      createdDate
      discountingModel
      transactionType
      discountingDetailsJson
    }
  }
`;
