import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'type',
    'reasonCode',
    'invoiceCancellation',
    'fullCredit',
    'objectType',
    'status',
    'stampingRequired',
  ]);
  const createARReasonCodeConfig = `createARReasonCodeConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createARReasonCodeConfig} {
      id
    }
  }
`;
};
