import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class ERPExtractsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDateWithRank',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDateWithRank',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.accountingPeriod',
      name: 'period',
      type: 'monthYear',
      isClearable: true,
    },
    {
      label: 'common:label.objectType',
      name: 'type',
      type: 'select',
      tOptions: 'selections:ebsExtractType',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      type: 'select',
      name: 'extractStatus',
      tOptions: 'selections:ebsExtractStatus',
      isClearable: true,
    },
    {
      label: 'common:label.batchId',
      name: 'batchId',
    },
  ];

  btnSpan = 'col-md-6';
}

ERPExtractsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ERPExtractsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ERPExtractsSearchForm);
