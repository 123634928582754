import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { makeErrorMessage } from '../selectors';
import { getChartOfAccount } from '../actions';
import ModalSelector from '../../../components/Modals/ModalSelector';
import { GLAccountSearchForm } from '../../../components/PricingHub';


class ModalGLAccount extends Component {
  state = {};

  render() {
    const { t } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'accountNumber',
          label: 'common:label.accountNumber',
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'type',
          label: 'common:label.type',
        },
        {
          name: 'detailType',
          label: 'common:label.detailType',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={t('label.chooseGLAccount')}
        isOpen={this.props.isOpen}
        SearchForm={GLAccountSearchForm}
        searchItems={this.props.getChartOfAccount}
        tableColumns={tableConfig.columns}
        onSelect={this.props.onSelect}
        onCancel={this.props.onCancel}
        toggleModal={this.props.onCancel}
        selectedParentId={this.props.selectedParentId}
        selectFieldName="accountNumber"
      />
    );
  }
}

ModalGLAccount.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.any,
  getChartOfAccount: PropTypes.func,
  errorMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
});

export default withTranslation('common')(connect(mapStateToProps, { getChartOfAccount })(ModalGLAccount));
