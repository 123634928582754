import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../../components/PricingHub/SearchComponent';

class SplitSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.organization',
      name: 'organization',
    },
  ];

  btnSpan = 'col-md-6';
}

SplitSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

SplitSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(SplitSearchForm);
