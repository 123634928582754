import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'minThresholdToSkipNotes',
    'arOpsReason',
    'maxThreshold',
    'index',
    'arOpsType',
    'adjustmentARType',
    'disputeARType',
    'writeOffARType',
    'accountType',
  ]);
  const modifyConfigArOpsThresholds = `modifyConfigArOpsThresholds(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigArOpsThresholds} {
      id
    }
  }
`;
};
