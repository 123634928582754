import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { strTwoFractionDigits } from '../../../utils/utils';
import { ArOperationsForm } from '../../../components/ArOperationsHub';
import { getArOpsRequestById } from '../actions';
import { writeOffFieldDetails } from '../constant';
import PageTitle from '../../../components/PageTitle';
import { makeWiteOffsDetail, makeErrorMessageWriteOffs } from '../selectors';
import RouteName from '../../App/RouteNames';

class WriteOffDetail extends React.PureComponent {
  state = {
    writeOffData: {},
  };

  componentDidMount() {
    this.doGetWriteOffDetail();
  }

  doGetWriteOffDetail = () => {
    const {
      match: {
        params: { id },
      },
      getArOpsRequestById,
    } = this.props;
    getArOpsRequestById(id, ({ data }) => {
      this.setState({ writeOffData: data || {} });
    });
  };

  convertValueViewOnly = () => {
    const { writeOffData } = this.state;
    const newData = { ...writeOffData };
    // newData.percent = strTwoFractionDigits(newData.percent);
    // if (!newData.recoveryAmount) newData.recoveryAmount = 0.0;
    // if (!newData.amount) newData.amount = 0.0;
    // newData.recoveryAmount = `$ ${newData.recoveryAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    // newData.amount = `$ ${newData.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    return newData;
  };

  render() {
    const { errorMessage = {}, t } = this.props;
    const writeOffsDetailViewOnly = this.convertValueViewOnly();
    return (
      <div className="content-wrapper">
        <PageTitle linkTo={RouteName.arOperationsWriteOffs.path} titleBtn={t('label.back')} />
        <div className="col-md-12 mb-30">
          <ArOperationsForm
            title={t('label.writeOffDetail')}
            listFields={writeOffFieldDetails}
            data={writeOffsDetailViewOnly}
            isDisabledAllField
            onChange={() => {}}
            errorMessage={errorMessage}
            linkTo={RouteName.arOperationsWriteOffs.path}
            onCancel={this.doGetWriteOffDetail}
          />
        </div>
      </div>
    );
  }
}

WriteOffDetail.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  getArOpsRequestById: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

WriteOffDetail.defaultProps = {
  match: {},
  errorMessage: '',
};

const mapStateToProps = createStructuredSelector({
  writeOffsDetail: makeWiteOffsDetail() || {},
  errorMessage: makeErrorMessageWriteOffs() || '',
});

export default withTranslation('common')(connect(mapStateToProps, { getArOpsRequestById })(WriteOffDetail));
