import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType'])}` : '';
  const searchPrepaidSystemOffers = `searchPrepaidSystemOffers(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchPrepaidSystemOffers} {
        id
        topupOfferId
        topupOfferName
        paymentOfferId
        paymentOfferName
        burnRateOfferId
        burnRateOfferName
        serviceType
      }
    }
  `;
};
