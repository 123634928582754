import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import AccountSort from '../../../constantsApp/customer/AccountSort';
import { makeErrorMessage } from '../selectors';
import { getCustomersParentOnly, getCustomersNoParentFilter } from '../actions';
import ModalSelector from '../../../components/Modals/ModalSelector';
import { AccountSearchForm } from '../../../components/CustomerHub';

class ModalParentId extends PureComponent {
  render() {
    const {
      isOpen,
      getCustomersParentOnly,
      getCustomersNoParentFilter,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      isNoParentFilter,
    } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: t('label.ACCTNo'),
        },
        {
          name: 'firstName',
          label: t('label.firstName'),
          sortable: true,
          render: (colName, item) => {
            const contact = item.contacts ? item.contacts[0] || {} : {};
            return <span>{contact.firstName}</span>;
          },
        },
        {
          name: 'lastName',
          label: t('label.lastName'),
          sortable: true,
          render: (colName, item) => {
            const contact = item.contacts ? item.contacts[0] || {} : {};
            return <span>{contact.lastName}</span>;
          },
        },
        {
          name: 'organization',
          label: t('label.organization'),
          sortable: true,
          render: (colName, item) => {
            const contact = item.contacts ? item.contacts[0] || {} : {};
            return <span>{contact.organization}</span>;
          },
        },
        {
          name: 'city',
          label: t('label.city'),
          sortable: true,
          render: (colName, item) => {
            const addresses = item.addresses ? item.addresses[0] || {} : {};
            return <span>{addresses.city}</span>;
          },
        },
        {
          name: 'state',
          label: t('label.state'),
          sortable: true,
          render: (colName, item) => {
            const address = item.addresses ? item.addresses[0] || {} : {};
            return <span>{address.state}</span>;
          },
        },
        {
          name: 'status',
          label: 'label.status',
          render: (colName, item) => {
            const slt = t('selections:status')().find(val => val.value === item.status);
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };

    return (
      <ModalSelector
        modalTitle={t('label.chooseAccount')}
        isOpen={isOpen}
        SearchForm={AccountSearchForm}
        searchItems={isNoParentFilter ? getCustomersNoParentFilter : getCustomersParentOnly}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        arraySort={AccountSort}
      />
    );
  }
}

ModalParentId.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  getCustomersParentOnly: PropTypes.func,
  getCustomersNoParentFilter: PropTypes.func,
};

ModalParentId.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  getCustomersParentOnly: () => {},
  getCustomersNoParentFilter: () => {},
  selectedParentId: '',
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
});

const newModalSelectItem = connect(mapStateToProps, { getCustomersParentOnly, getCustomersNoParentFilter })(
  ModalParentId
);

export default withTranslation(['common', 'selections'])(newModalSelectItem);
