import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'accountType',
        'accountSubType',
        'salesChannel',
        'marketSegment',
        'reason',
      ])}`
    : '';
  const getAccountClosureReport = `getAccountClosureReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getAccountClosureReport} {
        id
        company
        firstName
        lastName
        accountType
        accountSubType
        salesChannel
        marketSegment
        country
        state
        city
        effectiveDate
        reason
        cancellationDate
        commercialName
        rfc
        customAccountType
      }
    }
  `;
};
