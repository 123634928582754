import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getInAdvanceBillingConfig, modifyInAdvanceBillingConfig, createInAdvanceBillingConfig } from '../actions';
import { makeGetAdvanceBillingConfig } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput, CollapsibleTable } from '../../../components/common';
import { makeGetPermissionsRating, makeGetPermissionsBilling } from '../../App/selectors';
import {
  checkPermissionGetInAdvanceBillingConfig,
  checkPermissionModifyInAdvanceBillingConfig,
  checkPermissionCreateInAdvanceBillingConfig,
} from '../CheckPermission';
import { supportShowNumberValue, supportRemoveIndexWithSize } from '../../../utils/utils';

class InAdvanceBilling extends PureComponent {
  state = {
    isSearching: true,
    futureCycleListCfg: [],
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
    activeSubTab: {},
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.advanceBilling) return null;
    return {
      futureCycleListCfg: props.advanceBilling && props.advanceBilling.cycles ? props.advanceBilling.cycles : [],
      oldValue: props.advanceBilling,
    };
  }

  componentDidMount() {
    this.doGetInAdvanceBillingConfig();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { futureCycleListCfg } = this.state;
    const newData = cloneDeep(futureCycleListCfg);
    const indexItemChange = newData.findIndex(item => item.index === index);
    newData[indexItemChange][name] = value;
    this.setState({ futureCycleListCfg: newData });
  };

  doGetInAdvanceBillingConfig = () => {
    const { getInAdvanceBillingConfig } = this.props;
    getInAdvanceBillingConfig('', () => {
      this.setState({ isSearching: false, wasValidated: false });
    });
  };

  onAddNewProfile = () => {
    let indexNewItem = 0;
    const { futureCycleListCfg } = this.state;
    let newData = cloneDeep(futureCycleListCfg);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      billingSegment: null,
      daysInAdvance: null,
      recurringFeeAdvanceDom: null,
      accountType: 'NONE',
      billingDom: 0,
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    this.setState({ futureCycleListCfg: newData });
  };

  onRemoveItemTable = ({ item, index }) => {
    const { futureCycleListCfg } = this.state;
    const newData = cloneDeep(futureCycleListCfg);
    if (item.isNew) {
      newData.splice(index, 1);
    } else {
      newData[index] = { index: newData[index].index };
    }
    this.setState({ futureCycleListCfg: newData });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { futureCycleListCfg } = this.state;
    const { modifyInAdvanceBillingConfig, createInAdvanceBillingConfig, advanceBilling } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    if (advanceBilling && advanceBilling.id) {
      const newPayload = {
        id: advanceBilling.id,
        cycles: futureCycleListCfg
          ? supportRemoveIndexWithSize({ data: futureCycleListCfg }).map(item => {
              const { exceptions, ...newItem } = item;
              return { ...newItem, exceptions: exceptions ? supportRemoveIndexWithSize({ data: exceptions }) : null };
            })
          : null,
      };
      return modifyInAdvanceBillingConfig(newPayload, ({ success }) => {
        if (success) this.doGetInAdvanceBillingConfig();
      });
    }

    const newPayloadCreate = cloneDeep(futureCycleListCfg).map(item => {
      const { index, exceptions, isNew, ...newItem } = item;
      return { ...newItem, exceptions: exceptions ? supportRemoveIndexWithSize({ data: exceptions }) : null };
    });
    createInAdvanceBillingConfig({ cycles: newPayloadCreate }, ({ success }) => {
      if (success) this.doGetInAdvanceBillingConfig();
    });
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem) this.setState({ activeSubTab: {} });
    if (activeSubTab.index !== indexItem) {
      // setActiveSubTab({ key, index: indexItem });
      // this.doGetOrderSummaryByAccountId(item.id);
      this.setState({
        activeSubTab: { key, index: indexItem },
      });
      // this.setState({ activeSegmentIndexTab: indexItem });
    }
  };

  onChangeExceptions = ({ name, value, index }) => {
    const { futureCycleListCfg, activeSubTab } = this.state;
    const newData = cloneDeep(futureCycleListCfg);
    try {
      if (name === 'moveDate') {
        newData[activeSubTab.index].exceptions[index][name] = value
          ? moment(value)
              .format('DD-MMM')
              .toLocaleUpperCase()
          : null;
      } else newData[activeSubTab.index].exceptions[index][name] = value;
      this.setState({ futureCycleListCfg: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewExceptions = () => {
    try {
      const { futureCycleListCfg, activeSubTab } = this.state;
      const newData = cloneDeep(futureCycleListCfg);
      let lastIndex = 0;
      if (!newData[activeSubTab.index].exceptions) newData[activeSubTab.index].exceptions = [];
      newData[activeSubTab.index].exceptions.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData[activeSubTab.index].exceptions.push({
        index: lastIndex + 1,
        month: null,
        moveDate: null,
        isNew: true,
      });
      this.setState({ futureCycleListCfg: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveExceptions = ({ index, item }) => {
    try {
      const { futureCycleListCfg, activeSubTab } = this.state;
      const newData = cloneDeep(futureCycleListCfg);
      if (item.isNew) {
        newData[activeSubTab.index].exceptions.splice(index, 1);
      } else {
        newData[activeSubTab.index].exceptions[index] = { index: newData[activeSubTab.index].exceptions[index].index };
      }
      this.setState({ futureCycleListCfg: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { futureCycleListCfg, isSearching, wasValidated, activeSubTab } = this.state;
    const { advanceBilling, permissionsBilling, t } = this.props;
    let modeGetInAdvanceBillingConfig = 0;
    let modeModifyInAdvanceBillingConfig = 0;
    let modeCreateInAdvanceBillingConfig = 0;

    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermissionsBilling = permissionsBilling.billingModulePermissions;
      modeGetInAdvanceBillingConfig = checkPermissionGetInAdvanceBillingConfig({
        listPermission: listPermissionsBilling,
      });
      modeCreateInAdvanceBillingConfig = checkPermissionCreateInAdvanceBillingConfig({
        listPermission: listPermissionsBilling,
      });
      modeModifyInAdvanceBillingConfig = checkPermissionModifyInAdvanceBillingConfig({
        listPermission: listPermissionsBilling,
      });
    }

    if (!modeGetInAdvanceBillingConfig) return '';

    const advanceBillingColumns = [
      {
        name: 'billingDom',
        label: t('label.billingDom'),
        style: { textAlign: 'center', minWidth: '110px' },
        // required: true,
        render: (colName, item) => (
          <GenericInput
            value={item.billingDom}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="billingDom"
            // required
          />
        ),
      },
      {
        name: 'billingSegment',
        label: t('label.billingSegment'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            value={item.billingSegment}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="billingSegment"
          />
        ),
      },
      {
        name: 'accountType',
        label: t('label.accountType'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.accountType}
            tOptions="selections:accountType"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="accountType"
          />
        ),
      },
      {
        name: 'daysInAdvance',
        label: t('label.daysInAdvance'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.daysInAdvance}
            wrapperClass="col-md-12"
            disabled={item.recurringFeeAdvanceDom}
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="daysInAdvance"
          />
        ),
      },
      {
        name: 'recurringFeeAdvanceDom',
        label: t('label.recurringFeeAdvanceDom'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.recurringFeeAdvanceDom}
            disabled={item.daysInAdvance}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="recurringFeeAdvanceDom"
          />
        ),
      },
      {
        name: 'exceptions',
        label: 'label.exceptions',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button type="button" className="btn-expand-table mr-3" onClick={evt => this.onToggleSubTab(idx, item, idx)}>
            <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index, item })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const exceptionsColumns = [
      {
        name: 'month ',
        label: 'label.month',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={supportShowNumberValue(item.month)}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeExceptions({ index, name, value })}
            name="month"
            required
            type="number"
          />
        ),
      },
      {
        name: 'moveDate ',
        label: 'label.moveDate',
        render: (colName, item, index) => (
          <GenericInput
            value={item.moveDate}
            wrapperClass="col-md-12 inner-popover"
            onChange={({ name, value }) => this.onChangeExceptions({ index, name, value })}
            name="moveDate"
            type="date"
            dateFormat="DD-MMM"
            isUpperCase
          />
        ),
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveExceptions({ index, item })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    // const newDataOnlyViewInTable =
    //   futureCycleListCfg && futureCycleListCfg.length ? futureCycleListCfg.filter(item => size(item) > 1) : [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.delayedBilling.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.delayedBilling.path },
            { name: t('label.advanceBilling') },
          ]}
        />
        <FormWithTableItem title={t('label.advanceBilling')} subClass="border-bottom">
          <br />
          <form
            onSubmit={modeModifyInAdvanceBillingConfig === 2 ? this.hanldeSubmit : () => {}}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateInAdvanceBillingConfig === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProfile}>
                  +
                  {t('label.addInAdvanceBilling')}
                </button>
              )}
              {modeModifyInAdvanceBillingConfig === 2 && (
                <button type="submit" className="button button-border x-small float-right">
                  {advanceBilling && advanceBilling.id ? t('label.saveConfig') : t('label.createConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => this.doGetInAdvanceBillingConfig()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <br />
            <div className="table-responsive mt-15 form-focus">
              <div className="group-collapsible">
                <CollapsibleTable
                  indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
                  columns={advanceBillingColumns}
                  data={futureCycleListCfg}
                  isLoading={isSearching}
                  isViewOnly
                  isFixedHeaderTable
                  isSupportRemoveIndex
                >
                  <DataTable
                    columns={exceptionsColumns}
                    data={
                      futureCycleListCfg &&
                      futureCycleListCfg[activeSubTab.index] &&
                      futureCycleListCfg[activeSubTab.index].exceptions &&
                      activeSubTab &&
                      activeSubTab.index !== -1
                        ? futureCycleListCfg[activeSubTab.index].exceptions
                        : []
                    }
                    isSupportRemoveIndex
                  />
                  <button
                    type="button"
                    className="button button-border black x-small mb-4 ml-2"
                    onClick={this.addNewExceptions}
                  >
                    +
                    {t('label.addExceptions')}
                  </button>
                </CollapsibleTable>
              </div>
            </div>
          </form>
        </FormWithTableItem>
      </div>
    );
  }
}

InAdvanceBilling.propTypes = {
  advanceBilling: PropTypes.objectOf(PropTypes.any),
  getInAdvanceBillingConfig: PropTypes.func.isRequired,
  createInAdvanceBillingConfig: PropTypes.func.isRequired,
  modifyInAdvanceBillingConfig: PropTypes.func.isRequired,
  permissionsRating: PropTypes.objectOf(PropTypes.any),
};

InAdvanceBilling.defaultProps = {
  advanceBilling: {},
  permissionsRating: {},
};
const mapStateToProps = createStructuredSelector({
  // advanceBilling: makeGetAdvanceBillingConfig() || {},
  advanceBilling: makeGetAdvanceBillingConfig() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
});

const newInAdvanceBilling = connect(mapStateToProps, {
  getInAdvanceBillingConfig,
  modifyInAdvanceBillingConfig,
  createInAdvanceBillingConfig,
})(InAdvanceBilling);

export default withTranslation('common')(newInAdvanceBilling);
