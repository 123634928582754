import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';

const HeaderTable = ({ t, monthOne, monthTwo, monthThree, quarter }) => [
  // { content: t('label.employeeId'), colSpan: 2, },
  t('label.employeeId'),
  t('label.employeeName'),
  t('label.jobTitle'),
  t('label.status'),
  t('label.clientId'),
  t('label.clientName'),
  t('label.clientJobTitle'),
  monthOne,
  monthTwo,
  monthThree,
  quarter,
  monthOne,
  monthTwo,
  monthThree,
  quarter,
  monthOne,
  monthTwo,
  monthThree,
  quarter,
  monthOne,
  monthTwo,
  monthThree,
  quarter,
  monthOne,
  monthTwo,
  monthThree,
  quarter,
  monthOne,
  monthTwo,
  monthThree,
  quarter,
];

const convertJson2Pdf = ({ data, t, title, fileName, monthOne, monthTwo, monthThree, quarter, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, monthOne, monthTwo, monthThree, quarter }));
  const mainHeader = [
    { content: '', colSpan: 7 },
    { content: t('label.grossSalaryMxn'), colSpan: 4, styles: { halign: 'center' } },
    { content: t('label.actualCostMxn'), colSpan: 4, styles: { halign: 'center' } },
    { content: t('label.revenueUSD'), colSpan: 4, styles: { halign: 'center' } },
    { content: t('label.revenueMxn'), colSpan: 4, styles: { halign: 'center' } },
    { content: t('label.actIncomeMxn'), colSpan: 4, styles: { halign: 'center' } },
    { content: t('label.salaryToBilling'), colSpan: 4, styles: { halign: 'center' } },
  ];
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.employeeId ? item.employeeId : '');
      newItem.push(item.employeeName ? item.employeeName : '');
      newItem.push(item.jobTitle ? item.jobTitle : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'status' }));
      newItem.push(item.clientId ? item.clientId : '');
      newItem.push(item.clientName ? item.clientName : '');
      newItem.push(item.clientJobTitle ? item.clientJobTitle : '');
      newItem.push(formatMoneyValueToExport(item.monthOneGrossSal));
      newItem.push(formatMoneyValueToExport(item.monthTwoGrossSal));
      newItem.push(formatMoneyValueToExport(item.monthThreeGrossSal));
      newItem.push(formatMoneyValueToExport(item.totalGrossSal));
      newItem.push(formatMoneyValueToExport(item.monthOneActualCost));
      newItem.push(formatMoneyValueToExport(item.monthTwoActualCost));
      newItem.push(formatMoneyValueToExport(item.monthThreeActualacost));
      newItem.push(formatMoneyValueToExport(item.totalActualCost));
      newItem.push(formatMoneyValueToExport(item.monthOneRevenueUsd));
      newItem.push(formatMoneyValueToExport(item.monthTwoRevenueUsd));
      newItem.push(formatMoneyValueToExport(item.monthThreeRevenueUsd));
      newItem.push(formatMoneyValueToExport(item.totalRevenueUsd));
      newItem.push(formatMoneyValueToExport(item.monthOneRevenueMxn));
      newItem.push(formatMoneyValueToExport(item.monthTwoRevenueMxn));
      newItem.push(formatMoneyValueToExport(item.monthThreeRevenueMxn));
      newItem.push(formatMoneyValueToExport(item.totalRevenueMxn));
      newItem.push(formatMoneyValueToExport(item.monthOneActualIncomeMxn));
      newItem.push(formatMoneyValueToExport(item.monthTwoActualIncomeMxn));
      newItem.push(formatMoneyValueToExport(item.monthThreeActualIncomeMxn));
      newItem.push(formatMoneyValueToExport(item.totalActualIncome));
      newItem.push(formatMoneyValueToExport(item.monthOneSalaryToBilling));
      newItem.push(formatMoneyValueToExport(item.monthTwoSalaryToBilling));
      newItem.push(formatMoneyValueToExport(item.monthThreeSalaryToBilling));
      newItem.push(formatMoneyValueToExport(item.averageSalaryToBilling));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, mainHeader, title, fileName, fontSize: 4, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
