import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CollectionAllSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.dueThreshold',
      name: 'dueThreshold',
      type: 'number',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.groupCompany',
      name: 'groupCompany',
    },
  ];

  btnSpan = 'col-md-3';
}

CollectionAllSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CollectionAllSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CollectionAllSearchForm);
