import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, TitleFrom, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getCOA, modifyCOA, createCOA } from '../actions';

class GLChartOfAccount extends React.PureComponent {
  state = {
    data: { glAccounts: [] },
    wasValidated: false,
    dataSubmit: { glAccounts: [] },
    defaultData: { glAccounts: [] },
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetCOA();
    // this.checkExistData();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetCOA = () => {
    const { getCOA } = this.props;
    getCOA('', ({ data }) => {
      this.setState({
        data: data && data.length ? data[0] : { glAccounts: [] },
        dataSubmit: { glAccounts: [] },
        defaultData: data && data.length ? data[0] : { glAccounts: [] },
        id: data ? data[0].id : null,
        isModify: data ? !!data[0].id : false,
      });
    });
    // this.checkExistData();
  };

  checkExistData = () => {
    const { getCOA } = this.props;
    getCOA('', ({ data }) => {
      this.setState({
        isModify: !!data,
        id: data ? data[0].id : null,
        defaultData: data || { glAccounts: [] },
      });
    });
  };

  addNewItem = () => {
    try {
      const { data, dataSubmit, defaultData } = this.state;
      const newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData.glAccounts) newData.glAccounts = [];
      if (data)
        data.glAccounts.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        index: lastIndex + 1,
        name: '',
        description: '',
        glAccountType: '',
        glAccountSubType: null,
        glAccount: null,
      };
      newData.glAccounts = [payload, ...newData.glAccounts];
      // newData.glAccounts.push(payload);
      dataSubmit.glAccounts[newData.length - 1] = payload;
      this.setState({ data: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { data, isModify, dataSubmit, id } = this.state;
    const { modifyCOA, createCOA } = this.props;

    if (isModify) {
      return modifyCOA(
        { glAccounts: data.glAccounts, description: data.description, name: data.name, coaType: data.coaType, id },
        ({ success }) => {
          if (success) this.doGetCOA();
        }
      );
    }
    return createCOA({ ...data, type: 'GL_ACCOUNT_SEGMENT' }, ({ success }) => {
      if (success) this.doGetCOA();
    });
  };

  onHandleChangeInput = ({ name, value }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData[name] = value;
      dataSubmit[name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChange = ({ name, value, index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData.glAccounts[index][name] = value;
      if (!dataSubmit.glAccounts[index]) dataSubmit.glAccounts[index] = { index: newData.glAccounts[index].index };
      dataSubmit.glAccounts[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    try {
      const { data, dataSubmit } = this.state;
      const newData = cloneDeep(data);
      newData.glAccounts[index] = { index: newData.glAccounts[index].index };
      if (!dataSubmit.glAccounts[index]) dataSubmit.glAccounts[index] = { index: newData.glAccounts[index].index };
      // dataSubmit.glAccounts[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateCoaConfig, modeGetCoaConfig, modeModifyCoaConfig } = permissions;
    if (!modeGetCoaConfig) return '';
    const { data, wasValidated, isModify } = this.state;
    const listFieldsCOA = [
      {
        name: 'index',
        label: 'label.index',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.index}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="index"
            readOnly
            required
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="name"
            required
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        render: (colName, item, index) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="description"
          />
        ),
      },
      {
        name: 'glAccountType',
        label: 'label.glAccountType',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.glAccountType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="glAccountType"
            tOptions="selections:GlAccountType"
            type="select"
            required
            menuPortalTarget
          />
        ),
      },
      {
        name: 'glAccountSubType',
        label: 'label.glAccountSubType',
        render: (colName, item, index) => (
          <GenericInput
            value={item.glAccountSubType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="glAccountSubType"
          />
        ),
      },
      {
        name: 'glAccount',
        label: 'label.glAccount',
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.glAccount}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onHandleChange({ value, name, index })}
            name="glAccount"
            required
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => (
          <button
            type="button"
            disabled={modeModifyCoaConfig !== 2}
            className="btn-delete"
            onClick={evt => this.onRemoveItem({ index: idx })}
          >
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];
    const fieldsList = [
      {
        label: 'label.name',
        name: 'name',
        required: true,
        wrapperClass: 'col-md-4',
      },
      {
        label: 'label.description',
        name: 'description',
        required: true,
        wrapperClass: 'col-md-4',
      },
      {
        label: 'label.coaType',
        name: 'coaType',
        required: true,
        tOptions: 'selections:costType',
        type: 'select',
        wrapperClass: 'col-md-4',
      },
    ];
    return (
      <div>
        <PageTitle
          linkTo={RouteNames.revenueConfigurationLocation.path}
          titleBtn={t('label.back')}
          titleRight={t('revenuePage:sidebar.chartOfAccount')}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics mt-4">
            <TitleFrom title={t('revenuePage:sidebar.chartOfAccount')} subClass="border-bottom" />
            <br />
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="col-md-12 pl-2 row">
                {fieldsList.map(val => (
                  <GenericInput
                    value={data[val.name]}
                    wrapperClass="col-md-12"
                    onChange={({ name, value }) => this.onHandleChangeInput({ value, name })}
                    {...val}
                  />
                ))}
              </div>
              <br />
              <div className="form-group col-md-12 buttons-attibute pt-4">
                {modeCreateCoaConfig === 2 && (
                  <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                    +
                    {t('label.addNewGLAccountToCOA')}
                  </button>
                )}
                {modeModifyCoaConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-4"
                    onClick={this.onHandleSave}
                    disabled={modeModifyCoaConfig === 1 || !data.glAccounts || !data.glAccounts.length}
                  >
                    {isModify ? t('label.saveConfig') : t('label.createConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetCOA}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable isSupportRemoveIndex columns={listFieldsCOA} data={data.glAccounts || []} isFixedHeaderTable />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

GLChartOfAccount.propTypes = {
  getCOA: PropTypes.func,
  modifyCOA: PropTypes.func,
};

GLChartOfAccount.defaultProps = {
  getCOA: () => {},
  modifyCOA: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCOA,
    modifyCOA,
    createCOA,
  })(withRouter(GLChartOfAccount))
);
