import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { strTwoFractionDigits } from '../../../utils/utils';
import { ArOperationsForm } from '../../../components/ArOperationsHub';
import { adjustmentFieldDetails, invoiceLinesColumnsInit } from '../constant';
import RouteName from '../../App/RouteNames';
import PageTitle from '../../../components/PageTitle';
import { getArOpsRequestById } from '../actions';
import { makeErrorMessageAdjustments } from '../selectors';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import { checkPermissionGetAdjustmentDetails } from '../CheckPermission';

class AdjustmentDetail extends React.PureComponent {
  constructor() {
    super();
    this.state = { adjustmentData: {} };
  }

  componentDidMount() {
    this.doGetAdjustmentDetail();
  }

  doGetAdjustmentDetail = () => {
    const {
      match: {
        params: { id },
      },
      getArOpsRequestById,
    } = this.props;
    getArOpsRequestById(id, ({ data }) => {
      this.setState({ adjustmentData: data || {} });
    });
  };

  convertValueViewOnly = () => {
    const { adjustmentData } = this.state;
    const newData = { ...adjustmentData };
    // newData.percent = strTwoFractionDigits(newData.percent);
    // if (!newData.amount) newData.amount = 0.0;
    // newData.amount = `$ ${newData.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    return newData;
  };

  render() {
    const { errorMessage, permissionsArOps, t } = this.props;
    let modeGetAdjustmentDetails = 0;
    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeGetAdjustmentDetails = checkPermissionGetAdjustmentDetails({ listPermission });
    }
    if (errorMessage || modeGetAdjustmentDetails === 0) {
      return (
        <div className="container-fluid">
          <div className="row">
            {modeGetAdjustmentDetails !== 0 && <div className="content-wrapper">{errorMessage}</div>}
          </div>
        </div>
      );
    }
    const adjustmentDetailViewOnly = this.convertValueViewOnly();
    const invoiceLinesColums = invoiceLinesColumnsInit(adjustmentDetailViewOnly);
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteName.arOperationsAdjustments.path}
          titleBtn={t('label.back')}
          titleRight={t('arOperationPage:sidebar.adjustments')}
        />
        <div className="col-md-12 mb-30">
          <ArOperationsForm
            title={t('label.adjustmentDetails')}
            listFields={adjustmentFieldDetails}
            data={adjustmentDetailViewOnly}
            isDisabledAllField
            linkTo={RouteName.arOperationsAdjustments.path}
            onChange={() => {}}
            onCancel={this.doGetAdjustmentDetail}
            invoiceLinesColums={invoiceLinesColums}
            isView
            invoiceLines={adjustmentDetailViewOnly?.lines || []}
            isNoCancel
          />
        </div>
      </div>
    );
  }
}

AdjustmentDetail.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  getArOpsRequestById: PropTypes.func,
  errorMessage: PropTypes.string,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

AdjustmentDetail.defaultProps = {
  match: {},
  getArOpsRequestById: () => {},
  errorMessage: '',
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessageAdjustments() || '',
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getArOpsRequestById })(AdjustmentDetail));
