import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [
        'groupByType',
        'status',
        'accountType',
        'folioStatus',
        'startNumberOfDaysSincePaymentDate',
        'endNumberOfDaysSincePaymentDate',
        'functionalCurrency',
      ])}`
    : '';
  const getPaymentAgeingReport = `getPaymentAgeingReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getPaymentAgeingReport} {
        amount
        totalRemainingAmount
        total0To15
        total15To30
        total30To60
        total60To90
        total90To120
        total120To150
        total150To180
        total180To270
        total270To290
        total290To365
        total365AndMore
        numberOfDaysSincePaymentDate
        accountId
        paymentId
        paymentSuspenseId
        organization
        paymentDate
        currency
        groupCompany
        salesConsultant
        collectionAgent
        accountCategory
        salesFunction
        status
        folioStatus
      }
    }
  `;
};
