import React from 'react';
// import PropTypes from 'prop-types';
import { cloneDeep, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import {
  getGatewayApiMapByName,
  getGatewayCanonicalByObjectType,
  modifyGatewayCanonicalMapCfg,
  getGatewayApiMapById,
  createGatewayApiMapping,
  modifyGatewayApiMapping,
  getApiByGatewayName,
} from '../../actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { makeGetGatewayApiMapByName } from '../../selectors';
import { GenericInput, FormCollapse, DataTable, ModalUploadFile } from '../../../../components/common';
import { parseToMutationRequestPostMethod, getBase64 } from '../../../../utils/utils';
import { readFileFromS3, uploadMultiPartFiles } from '../../../App/actions';
import { makeGetS3Config } from '../../../App/selectors';

const objectType = {
  ACCOUNT: 'ACCOUNT',
  BILLING: 'BILLING',
  BUNDLE: 'BUNDLE',
  CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
  DOCUMENT: 'DOCUMENT',
  FINANCE: 'FINANCE',
  ITEM: 'ITEM',
  ITEM_SERVICE: 'ITEM_SERVICE',
  OPERATION: 'OPERATION',
  ORDER: 'ORDER',
  PACKAGE: 'PACKAGE',
  PAYMENT: 'PAYMENT',
  PRICE_OFFER: 'PRICE_OFFER',
  PROJECT: 'PROJECT',
  PROVISIONING_ATTRIBUTES: 'PROVISIONING_ATTRIBUTES',
  PROVISIONING_REQUEST: 'PROVISIONING_REQUEST',
  PROVISIONING_RESPONSE: 'PROVISIONING_RESPONSE',
  GET_REASON_CODES: 'REASON_CODES',
  REASON_CODES: 'REASON_CODES',
  SERVICE: 'SERVICE',
  TAX: 'TAX',
  USAGE: 'USAGE',
  INVOICE: 'INVOICE',
  ACCOUNT_AND_ORDER: 'ACCOUNT_AND_ORDER',
};

class RequestResponseMapping extends React.PureComponent {
  state = {
    data: [],
    activeTab: -1,
    activeChildTab: {},
    dataConfig: {},
    itemSelect: {},
    activeChildTabResponse: {},
    optionsApi: [],
    isOpenUploadFile: false,
    itemSelectUpload: null,
  };

  // static getDerivedStateFromProps(props, state) {
  //   if (state.oldValue === props.gatewayApiMapByName || !props.gatewayApiMapByName.length) return null;
  //   return {
  //     data: props.gatewayApiMapByName,
  //     oldValue: props.gatewayApiMapByName,
  //   };
  // }

  componentDidMount() {
    const { getGatewayCanonicalByObjectType, typeConfig, getApiByGatewayName, gatewayName } = this.props;
    this.doGetGatewayApiMapByName();
    // getGatewayCanonicalByObjectType({ type: typeConfig }, ({ success, data }) => {
    //   if (success) {
    //     let dataOption = null;
    //     if (typeConfig === 'ORDER' && data.orderCanonicalSpec) {
    //       dataOption = {
    //         request: data.orderCanonicalSpec,
    //         response: data.orderCanonicalSpec,
    //       };
    //     }
    //     this.setState({
    //       dataConfig:
    //         dataOption ||
    //         data.paymentCanonical ||
    //         data.taxCanonical ||
    //         data.financeCanonical ||
    //         data.orderCanonicalSpec ||
    //         data.customerSupportCanonicalSpec ||
    //         data.documentCanonicalSpec ||
    //         data.operationCanonicalSpec ||
    //         data.projectCanonicalSpec,
    //     });
    //   }
    // });
    getApiByGatewayName({ gatewayName }, ({ success, data }) => {
      if (success) {
        this.setState({
          optionsApi: data.map(val => ({
            label: val.apiName,
            value: val.apiName,
            apiType: val.apiType,
            apiProtocol: val.apiProtocol,
            payloadMapOnly: val.payloadMapOnly,
          })),
        });
      }
    });
  }

  doGetGatewayCanonicalByObjectType = type => {
    const { getGatewayCanonicalByObjectType } = this.props;
    getGatewayCanonicalByObjectType({ type }, ({ success, data }) => {
      if (success) {
        let dataOption = null;
        if (type === 'ORDER' && data.orderCanonicalSpec && data.orderCanonicalSpec.attributes) {
          dataOption = {
            request: data.orderCanonicalSpec,
            response: data.orderCanonicalSpec,
          };
        }
        if (data?.accountCanonicalSpec && data?.accountCanonicalSpec.attributes) {
          dataOption = {
            request: data.accountCanonicalSpec,
            response: data.accountCanonicalSpec,
          };
        }
        if (data?.itemCanonicalSpec && data?.itemCanonicalSpec.attributes) {
          dataOption = {
            request: data.itemCanonicalSpec,
            response: data.itemCanonicalSpec,
          };
        }
        if (data?.bundleCanonicalSpec && data?.bundleCanonicalSpec.attributes) {
          dataOption = {
            request: data.bundleCanonicalSpec,
            response: data.bundleCanonicalSpec,
          };
        }
        if (data?.packageCanonicalSpec && data?.packageCanonicalSpec.attributes) {
          dataOption = {
            request: data.packageCanonicalSpec,
            response: data.packageCanonicalSpec,
          };
        }
        if (data?.priceOfferCanonicalSpec && data?.priceOfferCanonicalSpec.attributes) {
          dataOption = {
            request: data.priceOfferCanonicalSpec,
            response: data.priceOfferCanonicalSpec,
          };
        }
        if (data?.provisioningResponseCanonicalSpec && data?.provisioningResponseCanonicalSpec.attributes) {
          dataOption = {
            request: data.provisioningResponseCanonicalSpec,
            response: data.provisioningResponseCanonicalSpec,
          };
        }
        if (data?.customRecord && data?.customRecord.attributes) {
          dataOption = {
            request: data.customRecord,
            response: data.customRecord,
          };
        }
        this.setState({
          dataConfig:
            dataOption ||
            data.taxCanonical ||
            data.paymentCanonical ||
            data.billingCanonicalSpec ||
            data.financeCanonical ||
            data.accountCanonicalSpec ||
            data.orderCanonicalSpec ||
            data.itemCanonicalSpec ||
            data.bundleCanonicalSpec ||
            data.packageCanonicalSpec ||
            data.priceOfferCanonicalSpec ||
            data.provisioningResponseCanonicalSpec ||
            data.documentCanonicalSpec ||
            data.customerSupportCanonicalSpec ||
            data.documentCanonicalSpec ||
            data.operationCanonicalSpec ||
            data.projectCanonicalSpec ||
            data.accountAndOrderCanonicalSpec ||
            data.invoiceCanonicalSpec ||
            data.customRecord,
        });
      }
    });
  };

  doGetGatewayApiMapByName = isNoExpand => {
    const { getGatewayApiMapByName, gatewayName, merchantName } = this.props;
    getGatewayApiMapByName({ gatewayName, merchantName: merchantName || null }, ({ success, data }) => {
      if (success) {
        this.setState({ data });
        if (data && data[0]) if (!isNoExpand) this.onToggleTab({ index: 0, val: data[0], indexItem: 0 });
        // console.log('data', data);
        // if (data && data.length && data[0].objectType && objectType[data[0].objectType]) {
        //   this.doGetGatewayCanonicalByObjectType(objectType[data[0].objectType]);
        // }
      }
    });
  };

  onHandleUpload = ({ files }) => {
    const { isOpenUploadFile } = this.state;
    const { uploadMultiPartFiles, type } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        {
          category: 'INTEGRATION',
          tenantSubType: 'INTEGRATION_PAYLOAD_TEMPLATE',
          integrationSubType: type || null,
        },
        ['category', 'tenantSubType', 'integrationSubType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          if (res.data.data && res.data.data.data && res.data.data.data.uploadMultipartFiles) {
            try {
              const { itemSelect } = this.state;
              const pathSelect = res.data.data.data.uploadMultipartFiles[0];
              itemSelect.payloadTemplatePath = pathSelect.absoluteFile;
              this.setState({ itemSelect: { ...itemSelect } });
            } catch (error) {
              console.log(error);
            }
          }
          this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelectUpload: null, serviceType: null });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelectUpload: null, serviceType: null });
    }
  };

  onCancelUploadFile = () => {
    this.setState({ isOpenUploadFile: false, itemSelectUpload: null });
  };

  onToggleTab = ({ index, val, indexItem }) => {
    const { activeTab } = this.state;
    if (activeTab === indexItem) this.setState({ activeTab: -1 });
    if (activeTab !== indexItem) {
      if (val && val.id) this.doGetAPIById(val.id);
      if (val.objectType) this.doGetGatewayCanonicalByObjectType(objectType[val.objectType]);
      this.setState({ activeTab: indexItem });
    }
  };

  onToggleModalUpload = itemSelect => {
    const { isOpenUploadFile } = this.state;
    const { readFileFromS3, s3Config } = this.props;
    if (itemSelect.payloadTemplatePath) {
      readFileFromS3({ ...s3Config, url: itemSelect.payloadTemplatePath }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            this.setState({
              base64FileSelect: result,
              isOpenUploadFile: !isOpenUploadFile,
              itemSelectUpload: itemSelect,
            });
          });
          this.setState({ fileSelect: data, itemSelectUpload: itemSelect });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelectUpload: itemSelect });
    }
  };

  doGetAPIById = id => {
    const { getGatewayApiMapById } = this.props;
    getGatewayApiMapById(id, ({ success, data }) => {
      if (success) this.setState({ itemSelect: data });
    });
  };

  onToggleChildTab = ({ index, childIndex }) => {
    const { activeChildTab } = this.state;
    if (activeChildTab.index === index && activeChildTab.childIndex === childIndex) {
      this.setState({ activeChildTab: {} });
    } else {
      this.setState({ activeChildTab: { index, childIndex } });
    }
  };

  onToggleChildTabResponse = ({ index, childIndex }) => {
    const { activeChildTabResponse } = this.state;
    if (activeChildTabResponse.index === index && activeChildTabResponse.childIndex === childIndex) {
      this.setState({ activeChildTabResponse: {} });
    } else {
      this.setState({ activeChildTabResponse: { index, childIndex } });
    }
  };

  onChangeApiMapping = ({ value, name }) => {
    try {
      const { itemSelect } = this.state;
      const newData = cloneDeep(itemSelect);
      if (newData[name] !== value && value && name === 'objectType') this.doGetGatewayCanonicalByObjectType(value);
      newData[name] = value;

      this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeRequestResponse = ({ value, name, indexUrl, type }) => {
    try {
      const { itemSelect } = this.state;
      const newData = cloneDeep(itemSelect);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'false' : 'true';
      if (type === 'request') newData.request[indexUrl][name] = newValue;
      if (type === 'response') newData.response[indexUrl][name] = newValue;
      this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeCanonicalMapCfg = ({ value, name, index, indexUrl }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      newData[index].gatewayCanonicalMapCfg[indexUrl][name] = newValue;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTable = ({ name, value, index, childIndex, item, type }) => {
    try {
      const { itemSelect } = this.state;
      const newData = cloneDeep(itemSelect);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      if (type === 'request') {
        const indexItem = newData.request[childIndex].level1Mapping.findIndex(val => val.index === item.index);
        newData.request[childIndex].level1Mapping[indexItem][name] = newValue;
      }

      if (type === 'response') {
        const indexItem = newData.response[childIndex].level1Mapping.findIndex(val => val.index === item.index);
        newData.response[childIndex].level1Mapping[indexItem][name] = newValue;
      }

      this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = index => {
    const { itemSelect } = this.state;
    const newPayload = cloneDeep(itemSelect);
    this.handleDataSubmit(newPayload);
  };

  onHandleRemoveCanonicalConfig = index => {
    try {
      const { itemSelect, data } = this.state;
      if (itemSelect && itemSelect.isCreate) {
        const newData = cloneDeep(data);
        newData.splice(index, 1);
        return this.setState({ data: newData, itemSelect: {}, activeTab: {} });
      }
      let newData = cloneDeep(itemSelect);
      newData = {
        id: newData.id,
      };
      const newPayload = cloneDeep(newData);
      this.handleDataSubmit(newPayload, 'remove');
      return this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  handleDataSubmit = (payload, type) => {
    const { modifyGatewayApiMapping, createGatewayApiMapping } = this.props;
    const newPayload = cloneDeep(payload);
    if (newPayload && newPayload.isCreate) {
      const { isCreate, ...rest } = newPayload;
      return createGatewayApiMapping(rest, ({ success, data }) => {
        if (success) {
          this.doGetAPIById(data.id);
          this.doGetGatewayApiMapByName(true);
        }
      });
    }
    if (type === 'remove') {
      modifyGatewayApiMapping(newPayload, ({ success }) => {
        if (success) this.doGetGatewayApiMapByName(true);
      });
    } else {
      modifyGatewayApiMapping(newPayload, ({ success }) => {
        if (success) this.doGetAPIById(newPayload.id);
      });
    }
    return '';
  };

  onHanldeRemoveRequestResponse = ({ index, idx, type }) => {
    try {
      const { itemSelect } = this.state;
      const newData = cloneDeep(itemSelect);
      if (type === 'request')
        newData.request[idx] = {
          index: newData.request[idx].index,
        };
      if (type === 'response')
        newData.response[idx] = {
          index: newData.response[idx].index,
        };
      this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewItemTable = ({ idx, type }) => {
    try {
      const { itemSelect } = this.state;
      const newData = cloneDeep(itemSelect);
      if (type === 'request') {
        if (!newData.request[idx].level1Mapping) newData.request[idx].level1Mapping = [];
        let lastIndex = 0;
        newData.request[idx].level1Mapping.forEach(elm => {
          if (lastIndex < elm.index) lastIndex = elm.index;
        });
        newData.request[idx].level1Mapping.push({
          index: lastIndex + 1,
          sourceName: null,
          canonicalName: null,
        });
      }
      if (type === 'response') {
        if (!newData.response[idx].level1Mapping) newData.response[idx].level1Mapping = [];
        let lastIndex = 0;
        newData.response[idx].level1Mapping.forEach(elm => {
          if (lastIndex < elm.index) lastIndex = elm.index;
        });
        newData.response[idx].level1Mapping.push({
          index: lastIndex + 1,
          sourceName: null,
          canonicalName: null,
        });
      }
      this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewRequestResponse = ({ idx, type }) => {
    try {
      const { itemSelect } = this.state;
      const newData = cloneDeep(itemSelect);
      if (type === 'request') {
        if (!newData.request) newData.request = [];
        let lastIndex = 0;
        newData.request.forEach(elm => {
          if (lastIndex < elm.index) lastIndex = elm.index;
        });
        newData.request.push({
          index: lastIndex + 1,
          sourceName: null,
          canonicalName: null,
          // level0Flag: 'true',
          // level1Mapping: null,
        });
      }
      if (type === 'response') {
        if (!newData.response) newData.response = [];
        let lastIndex = 0;
        newData.response.forEach(elm => {
          if (lastIndex < elm.index) lastIndex = elm.index;
        });
        newData.response.push({
          index: lastIndex + 1,
          sourceName: null,
          canonicalName: null,
          // level0Flag: 'true',
          // level1Mapping: null,
        });
      }

      this.setState({ itemSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  addNewAPIMapping = () => {
    const { data, itemSelect } = this.state;
    const { gatewayName, merchantName } = this.props;
    const newData = cloneDeep(data);
    let newItemSelect = cloneDeep(itemSelect);
    const defaultData = {
      isCreate: true,
      gatewayName: `${gatewayName}`,
      providerName: merchantName || null,
      apiName: null,
      request: [
        {
          index: 1,
          sourceName: null,
          canonicalName: null,
        },
      ],
      response: [
        {
          index: 1,
          sourceName: null,
          canonicalName: null,
        },
      ],
    };
    newData.push(defaultData);

    newItemSelect = defaultData;
    this.setState({ data: newData, itemSelect: newItemSelect });
    this.onToggleTab({ index: newData.length - 1, val: newItemSelect, indexItem: newData.length - 1 });
  };

  onResetDataCreate = () => {
    const { itemSelect } = this.state;
    const { gatewayName } = this.props;
    let newItemSelect = cloneDeep(itemSelect);
    const defaultData = {
      isCreate: true,
      gatewayName: `${gatewayName}`,
      providerName: null,
      apiName: null,
      request: [
        {
          index: 1,
          sourceName: null,
          canonicalName: null,
        },
      ],
      response: [
        {
          index: 1,
          sourceName: '',
          canonicalName: '',
        },
      ],
    };
    newItemSelect = defaultData;
    this.setState({ itemSelect: newItemSelect });
  };

  render() {
    const { t, title, modeModify } = this.props;
    const {
      data,
      activeTab,
      activeChildTab,
      dataConfig,
      itemSelect,
      activeChildTabResponse,
      optionsApi,
      isOpenUploadFile,
      base64FileSelect,
      fileSelect,
      itemSelectUpload,
    } = this.state;
    const tableColumns = type => [
      {
        name: 'sourceName',
        label: 'label.sourceAttribute',
        render: (colName, item, idx) => (
          <GenericInput
            type="text"
            value={item.sourceName || null}
            wrapperClass="col-md-12"
            onChange={({ name, value }) =>
              this.onChangeRequestResponse({
                name,
                value,
                indexUrl: idx,
                item,
                type,
              })
            }
            name="sourceName"
          />
        ),
      },
      {
        name: 'canonicalName',
        label: 'label.canonicalAttribute',
        render: (colName, item, idx) => (
          <GenericInput
            type="select"
            value={item.canonicalName || null}
            options={
              dataConfig && dataConfig[type] && dataConfig[type].attributes
                ? dataConfig[type].attributes.map(attr => ({
                    label: attr.attributeName,
                    value: attr.attributeName,
                  }))
                : []
            }
            isManualValue
            menuPortalTarget
            wrapperClass="col-md-12"
            onChange={({ name, value }) =>
              this.onChangeRequestResponse({
                name,
                value,
                indexUrl: idx,
                item,
                type,
              })
            }
            name="canonicalName"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onHanldeRemoveRequestResponse({ idx, item, type })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const newOptionAPI =
      optionsApi && optionsApi.length
        ? optionsApi.map(val => {
            const defaultAPI = t('selections:gatewayApi')().find(i => i.value === val.value);
            if (defaultAPI) return defaultAPI;
            return val;
          })
        : [];

    return (
      <form>
        <div className="col-md-12 p-2 card card-statistics">
          <div className="d-block d-md-flex">
            <div className="d-block d-md-flex clearfix sm-mt-20 ">
              <TitleFrom title={title} />
            </div>
          </div>

          {data &&
            data.length > 0 &&
            data.map((val, index) => {
              let title = '';
              if (val.gatewayName) {
                const slt = t ? t('selections:gatewayName')().find(dt => dt.value === val.gatewayName) : '';
                title = `${title} ${slt ? slt.label : ''}`;
              }
              // if (val.providerName) {
              //   const slt = t ? t('selections:merchantName')().find(dt => dt.value === val.providerName) : '';
              //   title = `${title} ${slt ? `- ${slt.label}` : ''}`;
              // }
              const optionsApiDefault = t ? [...optionsApi, ...t('selections:gatewayApi')()] : [...optionsApi];

              let apiProtocol = '';
              let apiType = '';
              let payloadMapOnly = false;
              if (val.apiName) {
                const slt = optionsApiDefault.find(dt => dt.value === val.apiName);

                title = `${title} ${slt ? `- ${slt.label}` : ''}`;
              }
              if (itemSelect.apiName) {
                const slt = optionsApiDefault.find(dt => dt.value === itemSelect.apiName);
                apiProtocol = slt.apiProtocol;
                apiType = slt.apiType;
                payloadMapOnly = slt.payloadMapOnly;
              }
              return (
                <FormCollapse
                  key={index}
                  isActive={index === activeTab}
                  isActiveSubForm={index === activeTab}
                  isSubCollapse
                  title={title || 'null'}
                  state={index}
                  onToggleTab={() => this.onToggleTab({ index, val, indexItem: index })}
                >
                  <div className="col-md-12 text-right">
                    {modeModify === 2 && (
                      <button
                        type="button"
                        className="btn-delete"
                        onClick={() => this.onHandleRemoveCanonicalConfig(index)}
                      >
                        <i className="fa fa-trash mt-4" />
                      </button>
                    )}
                  </div>
                  <div className="col-md-12 row p-3">
                    <GenericInput
                      label="label.gatewayName"
                      type="select"
                      value={itemSelect.gatewayName || null}
                      wrapperClass="col-md-3"
                      tOptions="selections:gatewayName"
                      onChange={({ name, value }) => this.onChangeApiMapping({ value, name })}
                      name="gatewayName"
                      disabled
                      menuPortalTarget
                    />
                    <GenericInput
                      label="label.apiName"
                      value={itemSelect.apiName}
                      type="select"
                      onChange={({ name, value }) => this.onChangeApiMapping({ value, name })}
                      name="apiName"
                      // tOptions="selections:gatewayApi"
                      // isManualValue
                      options={newOptionAPI ? uniqBy(newOptionAPI, 'value') : []}
                      wrapperClass="col-md-3"
                      menuPortalTarget
                    />
                    <GenericInput
                      label="label.objectType"
                      value={itemSelect.objectType}
                      type="select"
                      onChange={({ name, value }) => this.onChangeApiMapping({ value, name })}
                      name="objectType"
                      tOptions="selections:objectType"
                      isManualValue
                      options={optionsApi ? uniqBy(optionsApi, 'value') : []}
                      wrapperClass="col-md-3"
                      menuPortalTarget
                    />
                    <GenericInput
                      label="label.apiProtocol"
                      value={apiProtocol}
                      onChange={() => {}}
                      name="apiProtocol"
                      isManualValue
                      wrapperClass="col-md-3"
                      readOnly
                      disabled
                    />
                    <GenericInput
                      label="label.apiType"
                      value={apiType}
                      onChange={() => {}}
                      name="apiType"
                      isManualValue
                      wrapperClass="col-md-3"
                      readOnly
                      disabled
                    />
                    <GenericInput
                      label="label.payloadTemplate"
                      value={itemSelect.payloadTemplatePath}
                      onClick={() => this.onToggleModalUpload(itemSelect)}
                      name="payloadTemplatePath"
                      wrapperClass="col-md-3"
                      readOnly
                      fa="fa fa-external-link"
                    />
                    <GenericInput
                      label="label.payloadMapOnly"
                      value={payloadMapOnly || false}
                      name="payloadMapOnly"
                      wrapperClass="col-md-3 mt-4 pt-2"
                      readOnly
                      type="checkbox"
                    />
                  </div>
                  <div className="pr-0 col-md-12">
                    {/* {itemSelect.request && ( */}
                    <FormCollapse
                      key={itemSelect && itemSelect.request ? itemSelect.request.toString() : 1}
                      title="Request"
                      isActive={activeChildTab.childIndex === 0}
                      onToggleTab={() => this.onToggleChildTab({ childIndex: 0 })}
                      state={`state-${0}`}
                      wrapperClass="col-md-12 pr-0"
                      isActiveSubForm={activeChildTab.childIndex === 0}
                      isSubCollapse
                    >
                      <DataTable
                        columns={tableColumns('request')}
                        data={itemSelect && itemSelect.request ? itemSelect.request : []}
                        isSupportRemoveIndex
                      />
                      <div className="col-md-12 pt-3 pb-3 row justify-content-center">
                        <button
                          type="button"
                          className="button button-border gray x-small"
                          onClick={() => this.addNewRequestResponse({ type: 'request' })}
                        >
                          {t('label.addNewRequestMapping')}
                        </button>
                      </div>
                    </FormCollapse>
                    {/* )} */}
                    {/* {itemSelect.response && ( */}
                    <FormCollapse
                      key={`key-${itemSelect && itemSelect.response ? itemSelect.response.toString() : 2}`}
                      title="Response"
                      isActive={activeChildTabResponse.childIndex === 1}
                      onToggleTab={() => this.onToggleChildTabResponse({ childIndex: 1 })}
                      state={`state-${0}`}
                      wrapperClass="col-md-12 pr-0"
                      isActiveSubForm={activeChildTabResponse.childIndex === 1}
                      isSubCollapse
                    >
                      <DataTable
                        columns={tableColumns('response')}
                        data={itemSelect ? itemSelect.response : []}
                        isSupportRemoveIndex
                      />
                      <div className="col-md-12 pt-3 pb-3 row justify-content-center">
                        <button
                          type="button"
                          className="button button-border gray x-small"
                          onClick={() => this.addNewRequestResponse({ type: 'response' })}
                        >
                          {t('label.addNewResponseMapping')}
                        </button>
                      </div>
                    </FormCollapse>
                  </div>

                  <div className="mt-3 mb-3 pb-4 col-md-12">
                    {modeModify === 2 && (
                      <button
                        type="button"
                        className="button button-border ml-2 x-small float-right"
                        onClick={() => this.onSubmit()}
                      >
                        {t('label.saveConfig')}
                      </button>
                    )}
                    <button
                      type="button"
                      disabled={itemSelect.isCreate}
                      onClick={!itemSelect.isCreate ? () => this.doGetAPIById(itemSelect.id) : this.onResetDataCreate}
                      className="button button-border black x-small float-right"
                    >
                      {t('label.cancel')}
                    </button>
                    <br />
                  </div>
                </FormCollapse>
              );
            })}
          {modeModify === 2 && (
            <div className="col-md-12 text-center mt-3 mb-3">
              <button
                type="button"
                className="button button-border gray x-small"
                onClick={() => this.addNewAPIMapping()}
              >
                {t('label.addNewAPIMapping')}
              </button>
            </div>
          )}
        </div>
        <ModalUploadFile
          title="label.uploadFile"
          isOpen={isOpenUploadFile}
          onCancel={this.onCancelUploadFile}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelectUpload}
          base64FileSelect={base64FileSelect}
          fileSelect={fileSelect}
          type={itemSelectUpload ? itemSelectUpload.type : null}
        />
      </form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  gatewayApiMapByName: makeGetGatewayApiMapByName() || {},
  s3Config: makeGetS3Config() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getGatewayApiMapByName,
    getGatewayCanonicalByObjectType,
    modifyGatewayCanonicalMapCfg,
    getGatewayApiMapById,
    createGatewayApiMapping,
    modifyGatewayApiMapping,
    getApiByGatewayName,
    readFileFromS3,
    uploadMultiPartFiles,
  })(RequestResponseMapping)
);
