import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, PieCharts } from '../../../components/common';
import { getPieChartDashBoardByType } from '../actions';
import RouteNames from '../../App/RouteNames';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionRevenueByRevenueType } from '../CheckPermission';
import { makeGetPermissionsDashboard } from '../../App/selectors';

class RevenueType extends Component {
  state = { data: [] };

  componentDidMount() {
    const { getPieChartDashBoardByType } = this.props;
    getPieChartDashBoardByType({ type: 'REVENUE_BY_REVENUE_TYPE' }, ({ success, data }) => {
      if (success) this.setState({ data });
    });
  }

  render() {
    const { data } = this.state;
    const { t, permissionsDashboard } = this.props;
    let modeRevenueType = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeRevenueType = checkPermissionRevenueByRevenueType({ listPermission });
    }
    if (modeRevenueType === 0) return '';
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('dashboardPage:sidebar.revenueType') }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="row mb-5">
          <PieCharts
            key={data.name}
            title={t('dashboardPage:sidebar.revenueType')}
            data={data && data.data ? cloneDeep(data.data) : []}
            wrapperClass="chart-pie embrix-chart-pie m-auto"
            subClass="animate__animated animate__zoomIn"
            keySeries="value"
            keyCategories="mon"
            style={{ height: 'auto', width: 'auto' }}
            height="550px"
            background={defineBackground[data.name] ? defineBackground[data.name] : null}
            isToolbar
          />
        </div>
      </div>
    );
  }
}

RevenueType.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
  getPieChartDashBoardByType: PropTypes.func.isRequired,
};

RevenueType.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getPieChartDashBoardByType })(RevenueType));
