/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon from '../../../Assets/images/icons/icon.png';
import './style.scss';

const onClickItem = (event, item) => {
  if (item.disable) {
    event.preventDefault();
  }
};

const SideChildItem = ({ item, ishaveIcon, isExpand }) => {
  const { t } = useTranslation();
  if (!item) return '';
  return (
    <div className={`${item.disable ? 'disable' : ''}`}>
      <NavLink to={item.href} onClick={evt => onClickItem(evt, item)}>
        <div className="pull-left ">
          <i className={item.icon} />
          <span className="right-nav-text">{t(item.label) || item.label}</span>
        </div>
        <div className={classNames('icon-hover', { invisiable: !isExpand })}>
          <img src={icon} style={{ width: item.widthRightIcon || 20, height: 'auto' }} alt="" />
        </div>
        <div className="clearfix" />
      </NavLink>
    </div>
  );
};

const CollapseNavi = ({
  item,
  isExpand,
  backLink,
  href,
  isHiddenChild,
  disable,
  index,
  indexMaximize,
  onHandleSetMaximize,
}) => {
  const { t } = useTranslation();
  const [isExpandCollapse, setExpand] = useState(isHiddenChild);

  useEffect(() => {
    return setExpand(isHiddenChild);
  }, [isHiddenChild]);

  if (!item) return '';
  return (
    <li key={item.mainTilte} className={`item-nav ${item.disable ? 'disable' : ''}`}>
      {item.childs && item.childs.length ? (
        <a
          className={classNames(
            `right-nav-text nav-main cursor ${
              item.childs && item.childs.length
                ? 'remove-active-nav-bar'
                : item.href === window.location.pathname
                ? 'active'
                : ''
            }`,
            { invisiable: !isExpand }
          )}
          // onClick={() => setExpand(!isExpandCollapse)}
          onClick={() => onHandleSetMaximize(index)}
          aria-expanded={!isExpandCollapse}
          to={`${backLink || href || '#'}`}
        >
          <div className="pull-left">
            {item.icon && <i className={item.icon} />}
            <span className="right-nav-text">{t(item.mainTilte) || item.mainTilte}</span>
          </div>
          <div className="pull-right pull-right-persist">
            {item && item.childs && item.childs.length > 0 && <i className="ti-plus" />}
          </div>
          <div className="clearfix" />
        </a>
      ) : (
        <Link
          className={classNames(
            `right-nav-text nav-main cursor ${
              item.childs && item.childs.length
                ? 'remove-active-nav-bar'
                : item.href === window.location.pathname
                ? 'active'
                : ''
            }`
            // { invisiable: !isExpand }
          )}
          // onClick={() => setExpand(!isExpandCollapse)}
          onClick={() => onHandleSetMaximize(index)}
          // aria-expanded={!isExpandCollapse}
          to={`${backLink || href || '#'}`}
        >
          <div className="pull-left">
            {item.icon && <i className={`icon-nav-item ${item.icon}`} />}
            <span className={classNames('right-nav-text nav-main-item', { invisiable: !isExpand })}>
              {t(item.mainTilte) || item.mainTilte}
            </span>
          </div>
          <div className={classNames('icon-hover', { invisiable: !isExpand })}>
            <img src={icon} style={{ width: item.widthRightIcon || 20, height: 'auto' }} alt="" />
          </div>
          <div className="pull-right pull-right-persist">
            {item && item.childs && item.childs.length > 0 && <i className="ti-plus" />}
          </div>
          <div className="clearfix" />
        </Link>
      )}

      <div className={classNames({ 'display-none': isExpandCollapse })}>
        {item.childs &&
          item.childs.length > 0 &&
          item.childs.map((child, index) => {
            return <SideChildItem key={index} item={child} isExpand={isExpand} ishaveIcon />;
          })}
      </div>
    </li>
  );
};

const RenderSubmain = ({
  item,
  isExpand,
  backLink,
  href,
  isHiddenChild,
  onHandleSetMaximize,
  index,
  keyExpandChild,
}) => {
  const { t } = useTranslation();
  const [isExpandCollapse, setExpand] = useState(!isHiddenChild);
  const [keyExpandCollapseChild, setExpandChild] = useState(keyExpandChild || '');

  useEffect(() => {
    return setExpand(isHiddenChild);
  }, [isHiddenChild]);

  useEffect(() => {
    return setExpandChild(keyExpandChild);
  }, [keyExpandChild]);

  if (!item) return '';
  return (
    <li className="item-nav">
      <a
        className={classNames(
          `right-nav-text nav-main cursor ${item.subMain && item.subMain.length ? 'remove-active-nav-bar' : ''}`,
          { invisiable: !isExpand }
        )}
        onClick={() => {
          setExpand(!isExpandCollapse);
          onHandleSetMaximize(index);
        }}
        aria-expanded={!isExpandCollapse}
        to={`${backLink || href || '#'}`}
      >
        <div className="pull-left">
          <span className="right-nav-text">{t(item.mainTilte) || item.mainTilte}</span>
        </div>
        <div className="pull-right pull-right-persist">
          {item && item.subMain && item.subMain.length > 0 && (
            <i className={isExpandCollapse ? 'ti-plus-sub-nav' : 'ti-subtract'} />
          )}
        </div>
        <div className="clearfix" />
      </a>
      {isExpandCollapse &&
        item.subMain &&
        item.subMain.length > 0 &&
        item.subMain
          .filter(valItem => !!valItem)
          .map(sub => {
            return (
              <li key={sub.key} className={`item-nav ${sub.disable ? 'disable' : ''}`}>
                {sub.href ? (
                  <Link
                    className={classNames(
                      `right-nav-text nav-main cursor ${
                        sub.childs && sub.childs.length ? 'remove-active-nav-bar' : ''
                      } ${window.location.pathname === sub.href ? 'active' : ''}`,
                      { invisiable: !isExpand }
                    )}
                    onClick={() => setExpandChild(sub.key === keyExpandCollapseChild ? '' : sub.key)}
                    // aria-expanded={keyExpandCollapseChild === sub.key && !isHiddenChild}
                    aria-expanded={keyExpandCollapseChild === sub.key && !isHiddenChild}
                    to={sub.href}
                  >
                    <div className="pull-left">
                      {sub.icon && <i className={`${sub.icon} mr-0`} />}
                      {sub.subMainTitle && <span className="right-nav-text ml-2">{t(sub.subMainTitle)}</span>}
                      {sub.singleItemTitle && <span className="right-nav-text">{t(sub.singleItemTitle)}</span>}
                    </div>
                    <div className="pull-right pull-right-persist">
                      {item && sub.childs && sub.childs.length > 0 && (
                        <i className={keyExpandCollapseChild === sub.key ? 'ti-plus-sub-nav' : 'ti-subtract'} />
                      )}
                    </div>
                    <div className="clearfix" />
                  </Link>
                ) : (
                  <a
                    className={classNames(
                      `right-nav-text nav-main cursor ${
                        sub.childs && sub.childs.length ? 'remove-active-nav-bar' : ''
                      } ${window.location.pathname === sub.href ? 'active' : ''}`,
                      { invisiable: !isExpand }
                    )}
                    onClick={() => setExpandChild(sub.key === keyExpandCollapseChild ? '' : sub.key)}
                    // aria-expanded={keyExpandCollapseChild === sub.key && !isHiddenChild}
                    aria-expanded={keyExpandCollapseChild === sub.key && !isHiddenChild}
                    // to={sub.href}
                  >
                    <div className="pull-left">
                      {sub.icon && <i className={`${sub.icon} mr-0`} />}
                      {sub.subMainTitle && <span className="right-nav-text ml-2">{t(sub.subMainTitle)}</span>}
                      {sub.singleItemTitle && <span className="right-nav-text">{t(sub.singleItemTitle)}</span>}
                    </div>
                    <div className="pull-right pull-right-persist">
                      {item && sub.childs && sub.childs.length > 0 && (
                        <i className={keyExpandCollapseChild === sub.key ? 'ti-plus-sub-nav' : 'ti-subtract'} />
                      )}
                    </div>
                    <div className="clearfix" />
                  </a>
                )}
                <div className={classNames({ 'display-none': keyExpandCollapseChild !== sub.key })}>
                  {sub.childs &&
                    sub.childs.length > 0 &&
                    sub.childs.map((child, index) => {
                      return <SideChildItem key={index} item={child} isExpand={isExpand} ishaveIcon />;
                    })}
                </div>
              </li>
            );
          })}
    </li>
  );
};

const SidebarSupportSubItem = props => {
  const { items, isExpand } = props;
  const { t } = useTranslation();
  const [indexMaximize, setMaximize] = useState(null);
  const [isSubMain, setIsSubMain] = useState(false);
  const [isFirstSetExpand, setIsFirstSetExpand] = useState(false);
  const [keyExpandChild, setKeyExpandChild] = useState(null);
  const onHandleSetMaximize = (index, isSubMain) => {
    setIsSubMain(isSubMain);
    if (index === indexMaximize || isSubMain) {
      return setMaximize(null);
    }
    return setMaximize(index);
  };
  useEffect(() => {
    const pathName = window.location.pathname;
    if (items && items.length) {
      items.forEach((el, index) => {
        const { childs, subMain } = el || {};
        if (
          childs &&
          childs.length &&
          childs.find(val => val.href === pathName) &&
          !isFirstSetExpand &&
          !el.isNoExpand
        ) {
          setIsFirstSetExpand(true);
          setMaximize(index);
        }
        if (subMain && !isFirstSetExpand) {
          subMain.forEach((sb, idx) => {
            const { childs, key } = sb || {};
            if (
              childs &&
              childs.length &&
              childs[0] !== null &&
              childs.find(val => val.href === pathName) &&
              !el.isNoExpand
            ) {
              setIsSubMain(true);
              setMaximize(null);
              setKeyExpandChild(key || null);
              setIsFirstSetExpand(true);
            }
          });
        }
      });
    }
  }, [isFirstSetExpand, items]);
  return (
    // <div className="side-menu-fixed" style={{ width: !isExpand ? 44 : '' }}>
    <div className={`side-menu-fixed ${isExpand ? 'expanded-sidebar' : ''}`}>
      <div className="scrollbar side-menu-bg">
        <ul className="nav navbar-nav side-menu" id="sidebarnav">
          {items.map((item, index) => {
            if (item.isHaveExpand && !item.subMain) {
              return (
                <CollapseNavi
                  key={index}
                  href={item.href}
                  item={item}
                  isExpand={isExpand}
                  disable={item.disable}
                  onHandleSetMaximize={onHandleSetMaximize}
                  index={index}
                  isHiddenChild={indexMaximize !== index}
                  indexMaximize={indexMaximize}
                  {...props}
                />
              );
            }

            if (item.subMain) {
              return (
                <RenderSubmain
                  onHandleSetMaximize={index => onHandleSetMaximize(index, true)}
                  index={index}
                  key={index}
                  item={item}
                  isExpand={isExpand}
                  isHiddenChild={isSubMain && indexMaximize !== index}
                  {...props}
                  keyExpandChild={keyExpandChild}
                />
              );
            }

            return [
              <li
                key={index}
                className={classNames('mt-10 mb-10 text-muted pl-4 font-medium menu-title', {
                  'display-none': !isExpand,
                })}
              >
                {t(item.mainTilte)}
              </li>,
              item.childs &&
                item.childs.length > 0 &&
                item.childs.map((child, childIndex) => {
                  return <SideChildItem key={childIndex} item={child} ishaveIcon isExpand={isExpand} />;
                }),
            ];
          })}
        </ul>
      </div>
    </div>
  );
};

SidebarSupportSubItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  isExpand: PropTypes.bool,
};
SidebarSupportSubItem.defaultProps = {
  items: [],
  isExpand: false,
};

CollapseNavi.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
  backLink: PropTypes.string,
  href: PropTypes.string,
  isHiddenChild: PropTypes.bool,
};
CollapseNavi.defaultProps = {
  item: {},
  isExpand: false,
  backLink: '',
  href: '',
  isHiddenChild: false,
};

SideChildItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
  ishaveIcon: PropTypes.bool,
};
SideChildItem.defaultProps = {
  item: {},
  isExpand: false,
  ishaveIcon: false,
};

export default SidebarSupportSubItem;
