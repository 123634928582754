import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DataTable, FormWithTableItem, GenericInput, TablePagination, ModalWithItem } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import TEExpenseDataSearchForm from '../../../components/BillingHub/TEExpenseDataSearchForm';
import { searchEmployeeBonus, createEmployeeBonus, modifyEmployeeBonus } from '../actions';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsBilling } from '../../App/selectors';
import { checkPermissionForAllPages } from '../CheckPermission';
import {
  getLastIndex,
  supportRemoveIndexWithSize,
  compareArrayIndexValueChange,
  getPageTotalCount,
  validate,
} from '../../../utils/utils';
import ModalSelectEmployee from '../../../components/common/ModalSelectEmployee';

const dataSort = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
};

const EmployeeBonus = ({ searchEmployeeBonus, createEmployeeBonus, modifyEmployeeBonus, permissionsBilling }) => {
  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [defaultData, setDefaultData] = useState(null);
  const [sorted, setSorted] = useState({});
  const [filter, setFilter] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [wasValidated, setValidate] = useState(false);
  const [subItemSelect, setSubItemSelect] = useState(null);
  const [indexSelected, setIndexSelected] = useState(null);

  const { t } = useTranslation('common');

  let permissions = {};

  if (permissionsBilling && permissionsBilling.billingModulePermissions) {
    const listPermission = permissionsBilling.billingModulePermissions;
    permissions = checkPermissionForAllPages({
      listPermission,
    });
  }

  const { modeCreateEmployeeBonus, modeSearchEmployeeBonus, modeModifyEmployeeBonus } = permissions;

  const isHasPermission = (isEdit && modeModifyEmployeeBonus === 2) || (!isEdit && modeCreateEmployeeBonus === 2);
  const doGetExpensesConfig = useCallback(() => {
    setLoading(true);
    searchEmployeeBonus(
      {
        page: page + 1,
        size,
        filter,
        sort: !isEmpty(sorted) ? dataSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if (success && data) {
          setData(data);
          setItemSelect(null);
          setDefaultData(null);
          setValidate(false);
        } else {
          setData([]);
          setItemSelect(null);
          setDefaultData(null);
          setValidate(false);
        }
      }
    );
  }, [filter, page, searchEmployeeBonus, size, sorted]);

  const onChangeTable = ({ name, value, index, key }) => {
    const newData = cloneDeep(itemSelect);
    newData[key][index][name] = value;
    setItemSelect(newData);
  };

  const onChangeData = ({ name, value }) => {
    const newData = cloneDeep(itemSelect);
    newData[name] = value;
    setItemSelect(newData);
  };

  const onAddNewEmployeeBonusData = () => {
    const newData = cloneDeep(itemSelect);
    if (!newData || !newData.employeeBonusData) newData.employeeBonusData = [];
    newData.employeeBonusData.push({
      index: getLastIndex({ data: newData.employeeBonusData }),
      name: null,
      amount: '0.0',
      isNew: true,
    });
    setItemSelect(newData);
  };

  const onRemoveItem = ({ index, item, key }) => {
    try {
      const newData = cloneDeep(itemSelect);
      if (item.isNew) {
        newData[key].splice(index, 1);
        return setItemSelect(newData);
      }
      newData[key][index] = { index: newData[key][index].index };
      return setItemSelect(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  const onSelectItem = (id, row) => {
    try {
      const newData = cloneDeep(itemSelect);
      newData.employeeBonusData[indexSelected].employeeId = id;
      newData.employeeBonusData[indexSelected].employeeName = row.employeeName;
      setSubItemSelect(null);
      setIndexSelected(null);
      return setItemSelect(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  const onToggleSelectItem = ({ item, index }) => {
    setSubItemSelect(item);
    setIndexSelected(index);
  };

  const onCancelSelector = () => {
    setSubItemSelect(null);
  };

  const employeeBonusDataColumns = [
    {
      name: 'employeeName',
      label: 'label.employeeName',
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.employeeName}
          wrapperClass="col-md-12"
          onChange={() => {}}
          name="employeeName"
          required
          fa="fa fa-external-link"
          onClick={() => onToggleSelectItem({ item, index })}
        />
      ),
    },
    {
      name: 'amount',
      label: 'label.amount',
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.amount}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'employeeBonusData' })}
          name="amount"
          type="number"
          required
        />
      ),
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="no-border"
            onClick={() => onRemoveItem({ index, item, key: 'employeeBonusData' })}
            disabled={!isHasPermission}
          >
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const onSubmit = () => {
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    const payload = cloneDeep(itemSelect);
    const { employeeBonusData, id, startDate, bonusPeriodUnit } = payload;

    if (isEdit) {
      return modifyEmployeeBonus(
        {
          id,
          startDate: startDate || null,
          bonusPeriodUnit: bonusPeriodUnit || null,
          employeeBonusData: employeeBonusData
            ? compareArrayIndexValueChange({
                defaultData: defaultData ? defaultData.employeeBonusData : null,
                newData: supportRemoveIndexWithSize({ data: employeeBonusData }),
              })
            : null,
        },
        ({ success }) => {
          if (success) doGetExpensesConfig();
        }
      );
    }

    return createEmployeeBonus(
      {
        startDate: startDate || null,
        bonusPeriodUnit: bonusPeriodUnit || null,
        id: id || null,
        employeeBonusData: employeeBonusData
          ? employeeBonusData.map(val => {
              const { index, isNew, ...rest } = val;
              return { ...rest };
            })
          : null,
      },
      ({ success }) => {
        if (success) doGetExpensesConfig();
      }
    );
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onHandleSubmit = filter => {
    setFilter(filter);
  };

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) => (
        <button
          className="text-success no-border"
          type="button"
          onClick={() => {
            setItemSelect(item);
            setDefaultData(item);
            setIsEdit(true);
          }}
        >
          {item.id}
        </button>
      ),
    },
    {
      label: 'common:label.bonusPeriodUnit',
      name: 'bonusPeriodUnit',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:bonusPeriodUnit')().find(val => val.value === item.bonusPeriodUnit) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      sortable: true,
    },
    {
      name: 'id',
      label: 'label.view',
      render: (colName, item) => (
        <button
          className="btn btn-outline-success btn-sm"
          type="button"
          onClick={() => {
            setItemSelect(item);
            setDefaultData(item);
            setIsEdit(true);
          }}
        >
          {t('label.view')}
        </button>
      ),
    },
  ];

  const mainFileds = [
    {
      name: 'id',
      label: 'label.id',
    },
    {
      name: 'bonusPeriodUnit',
      label: 'label.bonusPeriodUnit',
      type: 'select',
      tOptions: 'selections:bonusPeriodUnit',
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      type: 'date',
    },
  ];

  const onToggleModal = () => {
    setItemSelect(null);
  };

  const onAddNew = () => {
    setItemSelect({});
    setIsEdit(false);
  };

  useEffect(() => {
    doGetExpensesConfig();
  }, [doGetExpensesConfig]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  if (!modeSearchEmployeeBonus) return null;

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoicingBilling.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
          { name: t('label.employeeBonus') },
        ]}
      />
      <FormWithTableItem title="common:label.employeeBonus">
        <div className="card-body ml-2">
          <TEExpenseDataSearchForm onSubmit={onHandleSubmit} />
        </div>
      </FormWithTableItem>
      <FormWithTableItem>
        <div className="col-md-12 ml-3 mb-3">
          {modeCreateEmployeeBonus === 2 && (
            <button type="button" className="button x-small" onClick={onAddNew}>
              {`+ ${t('label.add')}`}
            </button>
          )}
        </div>
        <DataTable
          isLoading={isLoading}
          onSort={onSortColumn}
          sorted={sorted}
          columns={tableColumns}
          data={data || []}
        />
      </FormWithTableItem>

      <div className="mb-30 mt-3">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalWithItem
        isOpen={!!itemSelect}
        onToggle={onToggleModal}
        modalTitle={isEdit ? t('label.employeeBonus') : t('label.addEmployeeBonus')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        <form ref={formRef} noValidate className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}>
          <div className="mb-2">
            <div className="col-md-12 row">
              {mainFileds.map(val => (
                <GenericInput
                  readOnly={val.name === 'id' && isEdit}
                  {...val}
                  value={itemSelect ? itemSelect[val.name] : null}
                  onChange={onChangeData}
                />
              ))}
            </div>
            <div className="pt-2">
              <div className="row col-md-12 p-2">
                <DataTable
                  columns={employeeBonusDataColumns}
                  data={itemSelect && itemSelect?.employeeBonusData ? itemSelect.employeeBonusData : []}
                  isLoading={isLoading}
                  isSupportRemoveIndex
                />
                {isHasPermission && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={onAddNewEmployeeBonusData}
                  >
                    {`+ ${t('label.add')}`}
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              {isHasPermission && (
                <button type="button" onClick={onSubmit} className="button button-border x-small float-right">
                  {t('label.saveConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={onToggleModal}
                className="button button-border black x-small float-right mr-2"
              >
                {t('label.cancel')}
              </button>
            </div>
          </div>
        </form>
      </ModalWithItem>
      <ModalSelectEmployee
        isOpen={!!subItemSelect}
        selectedParentId={subItemSelect && subItemSelect.id}
        onSelect={onSelectItem}
        onCancel={onCancelSelector}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  permissionsBilling: makeGetPermissionsBilling() || {},
});

EmployeeBonus.propTypes = {
  searchEmployeeBonus: PropTypes.func.isRequired,
  createEmployeeBonus: PropTypes.func.isRequired,
  modifyEmployeeBonus: PropTypes.func.isRequired,
  permissionsBilling: PropTypes.objectOf(PropTypes.any),
};

EmployeeBonus.defaultProps = {
  permissionsBilling: {},
};

export default connect(mapStateToProps, {
  searchEmployeeBonus,
  createEmployeeBonus,
  modifyEmployeeBonus,
})(EmployeeBonus);
