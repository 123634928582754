import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ ...filter }) => {
  const filterStr = filter ? convertFilterRequestUrl(filter, ['showActiveOnly']) : '';
  const getDivision = `getDivision(input: ${filterStr})`;
  return gql`
    query {
      ${getDivision} {
        id
        index
        name
        description
        shortName
        code
        enterprise
        locationIndex
        status
        address {
          city
          state
          country
        }
      }
    }
  `;
};
