import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { searchItemServiceSelect } from '../../../containers/App/actions';
import ModalMultipleSelector from '../../Modals/ModalMultipleSelector';
import ModalSelector from '../../Modals/ModalSelector';
import ItemServicesSearchForm from './ItemServicesSearchForm';

class ModalsearchItemServiceSelect extends Component {
  state = {};

  doGetItemServicesCfg = (payload, cb) => {
    const { searchItemServiceSelect } = this.props;
    const newPayload = { ...payload };
    if (newPayload.filter && newPayload.filter.isParent && newPayload.filter.isParent === 'TRUE')
      newPayload.filter.isParent = 'true';
    if (newPayload.filter && newPayload.filter.isParent && newPayload.filter.isParent === 'FALSE')
      newPayload.filter.isParent = 'false';
    searchItemServiceSelect(newPayload, resp => {
      cb({ success: resp.success, data: resp.data });
    });
  };

  render() {
    const { t, defaultSearchParams, isOpen, onSelect, onCancel, selectedParentId, isChangeItem, title } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
        },
        {
          name: 'name',
          label: 'common:label.name',
          sortName: 'name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'chargeType',
          label: 'common:label.chargeType',
        },
        {
          name: 'uom',
          label: 'common:label.uom',
        },
      ],
    };
    return isChangeItem ? (
      <ModalSelector
        modalTitle={title || t('label.componentServices')}
        isOpen={isOpen}
        SearchForm={ItemServicesSearchForm}
        searchItems={this.doGetItemServicesCfg}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        selectFieldName="id"
      />
    ) : (
      <ModalMultipleSelector
        modalTitle={t('label.componentServices')}
        isOpen={isOpen}
        SearchForm={ItemServicesSearchForm}
        searchItems={this.doGetItemServicesCfg}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        selectFieldName="id"
      />
    );
  }
}

ModalsearchItemServiceSelect.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  searchItemServiceSelect: PropTypes.func,
};

ModalsearchItemServiceSelect.defaultProps = {
  isOpen: false,
  onSelect: () => {},
  onCancel: () => {},
  selectedParentId: '',
  searchItemServiceSelect: () => {},
};

export default withTranslation('common')(connect(null, { searchItemServiceSelect })(ModalsearchItemServiceSelect));
