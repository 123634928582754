import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'active',
    'ratingType',
    'streamName',
    'index',
    'active',
    'moduleName',
    'logContainer',
  ]);
  const createUsageProcMapConfig = `createUsageProcMapConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createUsageProcMapConfig} {
      id
    }
  }
`;
};
