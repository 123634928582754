import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import BundleForm from './BundleForm';
import { selectBundleId } from '../../App/actions';
import { createBundle } from '../actions';
import { Breadcrumb } from '../../../components/common';

class BundleItemAdd extends Component {
  constructor(props) {
    const { location } = props;
    super(props);
    this.state = {
      isSubmitting: false,
      newBundle: location && location.state ? location.state : {},
    };
  }

  onCreateBundle = data => {
    this.setState({ isSubmitting: true });
    const { createBundle } = this.props;
    // const newPayload = cloneDeep(data);
    const { components, ...payload } = data;
    const newComponents = components
      ? components.map((e, idx) => {
          const { index, isNew, ...rest } = e;
          return {
            ...rest,
            index: idx + 1,
          };
        })
      : null;
    createBundle({ ...payload, components: newComponents }, () => {
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const { newBundle, isSubmitting } = this.state;
    const { t, location } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{ pathname: RouteNames.bundleItem.path, state: location && location.state ? location.state : null }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: 'Bundle Management', url: RouteNames.bundleItem.path },
                  { name: 'Bundles', url: RouteNames.bundleItem.path },
                  { name: t('label.addBundle') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.addBundle')}</h5>
              </div>
              <BundleForm item={newBundle} onSubmit={this.onCreateBundle} isSubmitting={isSubmitting} creatingMode />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BundleItemAdd.propTypes = {
  createBundle: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
};

BundleItemAdd.defaultProps = {
  location: {},
};

const mapDispatchToProps = dispatch => ({
  createBundle: (e, cb) => dispatch(createBundle(e, cb)),
  selectBundleId: (e, cb) => dispatch(selectBundleId(e, cb)),
});

const withConnect = connect(null, mapDispatchToProps);

export default withTranslation('common')(compose(withConnect)(BundleItemAdd));
