import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FocusInputTable } from '../../common';
import ModalSelectUserId from '../ModalSelectUserId';

const headers = ['label.userId', 'label.firstName', 'label.lastName', 'label.email', 'label.remove'];

export default function TableCollectionAgent({ data, onHandleChangeTable, onRemove }) {
  const { t } = useTranslation('common');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idSelect, setIdSelect] = useState('');
  const [indexSelect, setIndexSelect] = useState('');

  const toggleModal = index => {
    setIsOpenModal(!isOpenModal);
    setIndexSelect(index);
  };

  return (
    <div className="table-responsive sticky-header mt-15 form-focus">
      <table className="table center-aligned-table mb-0">
        <thead>
          <tr>
            {headers.map(item => (
              <th key={item}>{t(item)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <FocusInputTable
                value={item.userId}
                // options={uniqBy(data, 'userId').map(el => ({
                //   value: el.userId,
                //   label: el.userId,
                // }))}
                name="userId"
                fa="fa fa-external-link"
                onClick={() => toggleModal(index)}
                index={index}
                // onUpdateValue={(i, name, value) => {
                //   onHandleChangeTable(i, name, value);
                // }}
              />
              {/* <FocusInputTable
                value={item.firstName || ''}
                name="firstName"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              /> */}
              <td>{item.firstName || ''}</td>
              {/* <FocusInputTable
                value={item.lastName || ''}
                name="lastName"
                index={index}
                onUpdateValue={(i, name, value) => {
                  onHandleChangeTable(i, name, value);
                }}
              /> */}
              <td>{item.lastName || ''}</td>
              <td>{item.emailId || ''}</td>
              <td>
                <button type="button" className="btn-trash" onClick={() => onRemove(item)}>
                  <i className="fa fa-trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalSelectUserId
        isOpen={isOpenModal}
        selectedParentId={idSelect}
        onSelect={(id, row) => {
          onHandleChangeTable(indexSelect, 'userId', row.userId, row);
          setIdSelect(id);
          toggleModal();
        }}
        onCancel={toggleModal}
        toggleModal={toggleModal}
        defaultSearchParams={{ category: 'AGENT' }}
      />
    </div>
  );
}

TableCollectionAgent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChangeTable: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
};

TableCollectionAgent.defaultProps = {
  data: [],
  onHandleChangeTable: () => {},
};
