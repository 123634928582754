import React, { useState, useRef } from 'react';
import PropsType from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RouteName from '../../App/RouteNames';
import { createApprovals } from '../actions';
import { GenericInput, TitleFrom, Breadcrumb, DataTable } from '../../../components/common';
import ModalReasonCodeConfig from '../../../components/common/ModalReasonCodeConfig';
import ModalUserGroup from '../../../components/common/ModalUserGroup';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import { checkPermissionCreateConfigApprovals } from '../CheckPermission';

const ApprovalsCreate = ({ createApprovals, history, permissionsArOps }) => {
  const { t } = useTranslation('common');
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReasonCode, setIsOpenReasonCode] = useState(false);
  const [data, setData] = useState({ reasonCode: '', objectType: '', customReasonCode: '', approvalLevels: [] });
  const [wasValidated, setValidate] = useState(false);
  const formRef = useRef();

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  let modeCreateConfigApprovals = 0;

  if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
    const listPermission = permissionsArOps.arOpsModulePermissions;
    modeCreateConfigApprovals = checkPermissionCreateConfigApprovals({ listPermission });
  }
  if (modeCreateConfigApprovals !== 2) return '';

  const onSelect = (id, row) => {
    const newData = cloneDeep(data);
    newData.approvalLevels[indexSelect].userGroupId = row && row.groupId ? row.groupId : '';
    newData.approvalLevels[indexSelect].userGroupName = row && row.groupName ? row.groupName : '';
    setData(newData);
    setIsOpen(false);
  };

  const onRemoveApproval = index => {
    const newData = cloneDeep(data);
    newData.approvalLevels.splice(index, 1);
    setData(newData);
  };

  const onSelectReason = (val, row) => {
    const newData = cloneDeep(data);
    newData.customReasonCode = row.customReasonCode || '';
    setData(newData);
    setIsOpenReasonCode(false);
  };

  const onChangeTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      let newValue = value;
      if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
      newData.approvalLevels[index][name] = newValue;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const headerTable = [
    {
      name: 'userGroupId',
      label: 'label.userGroupId',
      required: true,
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userGroupId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => this.onChangeTable({ name, value, id: item.id })}
          name="userGroupId"
          fa="fa fa-external-link"
          onClick={() => {
            setIsOpen(true);
            setItemSelect(item);
            setIndexSelect(idx);
          }}
          required
        />
      ),
    },
    {
      name: 'userGroupName',
      label: 'label.userGroupName',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userGroupName}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => {}}
          name="userGroupName"
          readOnly
        />
      ),
    },
    {
      name: 'approvalRequired',
      label: 'label.approvalRequired',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.approvalRequired === 'true' || item.approvalRequired === true ? 'TRUE' : 'FALSE'}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="approvalRequired"
          type="select"
          tOptions="selections:selectBool"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'approvalCorrespondence',
      label: 'label.approvalCorrespondence',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.approvalCorrespondence === 'true' || item.approvalCorrespondence === true ? 'TRUE' : 'FALSE'}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="approvalCorrespondence"
          type="select"
          tOptions="selections:selectBool"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'rejectionCorrespondence',
      label: 'label.rejectionCorrespondence',
      render: (colName, item, idx) => (
        <GenericInput
          value={item.rejectionCorrespondence === 'true' || item.rejectionCorrespondence === true ? 'TRUE' : 'FALSE'}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index: idx })}
          name="rejectionCorrespondence"
          type="select"
          tOptions="selections:selectBool"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, idx) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveApproval({ index: idx })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const onChange = ({ name, value }) => {
    const newData = cloneDeep(data);
    newData[name] = value;
    setData({ ...newData });
  };

  const onHandleAddApproval = () => {
    const newData = cloneDeep(data);
    newData.approvalLevels.push({
      userGroupId: '',
      approvalRequired: 'true',
      approvalCorrespondence: 'true',
      rejectionCorrespondence: 'true',
    });
    setData(newData);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    const newData = cloneDeep(data);
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    if (newData.customReasonCode) delete newData.reasonCode;
    if (newData.reasonCode) delete newData.customReasonCode;
    newData.approvalLevels = newData.approvalLevels.map(val => {
      const { userGroupId, approvalRequired, approvalCorrespondence, rejectionCorrespondence } = val;
      return { userGroupId, approvalRequired, approvalCorrespondence, rejectionCorrespondence };
    });
    createApprovals({ approvalObjects: [newData] }, ({ success }) => {
      if (success) history.push(RouteName.userManagementApproval.path);
    });
  };

  const onCancel = () => {
    setData({ reasonCode: '', objectType: '', customReasonCode: '', approvalLevels: [] });
  };

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-sm-6">
            <NavLink className="button x-small" to={RouteName.userManagementApproval.path}>
              {t('label.back')}
            </NavLink>
          </div>
          <div className="col-sm-6">
            <Breadcrumb
              items={[
                {
                  name: t('userManagementPage:sidebar.approvals'),
                  url: RouteName.userManagementApproval.path,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <br />
      <form
        ref={formRef}
        noValidate
        className={`col-md-12 p-2 card card-statistics needs-validation ${wasValidated ? 'was-validated' : ''}`}
        // onSubmit={onHandleSubmit}
        onSubmit={onSubmit}
      >
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={t('label.addNewApproval')} />
          </div>
        </div>
        <div className="col-md-12 p-2 row">
          <GenericInput
            value={data.objectType}
            label="label.objectType"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            name="objectType"
            type="select"
            tOptions="selections:objectTypeApproval"
            menuPortalTarget
          />
          <GenericInput
            value={data.reasonCode}
            disabled={!!data.customReasonCode}
            isClearable
            label="label.reasonCode"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            name="reasonCode"
            type="select"
            tOptions="selections:reasonConfig"
            menuPortalTarget
          />
          <GenericInput
            value={data.customReasonCode}
            disabled={!!data.reasonCode}
            label="label.customReason"
            wrapperClass="col-md-4"
            onChange={({ name, value }) => onChange({ name, value })}
            name="customReasonCode"
            fa="fa fa-external-link"
            onClick={() => setIsOpenReasonCode(true)}
          />
        </div>
        <br />
        <div className="col-md-12">
          <DataTable columns={headerTable} data={data.approvalLevels || []} />
        </div>
        <div className="form-group col-md-12 buttons-attibute">
          <button type="button" className="button button-border black x-small" onClick={onHandleAddApproval}>
            +
            {t('label.addAnotherApprovalLevel')}
          </button>
          {modeCreateConfigApprovals === 2 && (
            <button type="submit" className="button button-border x-small float-right">
              {t('label.create')}
            </button>
          )}
          <button onClick={onCancel} type="button" className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </form>
      <ModalReasonCodeConfig
        isOpen={isOpenReasonCode}
        onSelect={onSelectReason}
        onCancel={() => setIsOpenReasonCode(false)}
        selectedParentId={data ? data.reasonCode : null}
      />
      <ModalUserGroup
        isOpen={isOpen}
        onSelect={onSelect}
        onCancel={() => setIsOpen(false)}
        selectedParentId={itemSelect ? itemSelect.groupId : null}
      />
    </div>
  );
};

ApprovalsCreate.propTypes = {
  createApprovals: PropsType.func.isRequired,
};

ApprovalsCreate.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default connect(mapStateToProps, { createApprovals })(ApprovalsCreate);
