import gql from 'graphql-tag';

export default id => gql`
  query {
    getInvoiceTransactionSummary(billUnitId: "${id}") {
      index
      name
      quantity
      unitPrice
      amount
      scale
      discount
      gross
      appliedStart
      appliedEnd
      itemId
    }
  }
`;
