import gql from 'graphql-tag';

export default id => gql`
  query {
    getProjectClientById(id: "${id}") {
      id
      name
      status
      startDate
      endDate
      projectClientPricing {
        id
        index
        jobTitle
        startDate
        endDate
        clientPricingUnit
        workDays
        amount
        status
      }
    }
  }
`;
