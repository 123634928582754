import React from 'react';
import { formatMoneyValue, formatNumberValue } from '../../utils/utils';

export const tabelColumnExportNewCustomer = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'company',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
  },
  {
    name: 'rfc',
    label: 'label.rfc',
  },
  {
    name: 'customAccountType',
    label: 'label.customAccountType',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountSubType',
    label: 'label.accountSubType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'salesChannel',
    label: 'label.salesChannel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'marketSegment',
    label: 'label.marketSegment',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'country',
    label: 'label.country',
    sortable: true,
  },
  {
    name: 'state',
    label: 'label.state',
    sortable: true,
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
];

export const tabelColumnExportAccountClosure = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'company',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
  },
  {
    name: 'cancellationDate',
    label: 'label.cancellationDate',
  },
  {
    name: 'rfc',
    label: 'label.rfc',
  },
  {
    name: 'customAccountType',
    label: 'label.customAccountType',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountSubType',
    label: 'label.accountSubType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountSubType')().find(val => val.value === item.accountSubType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'salesChannel',
    label: 'label.salesChannel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'marketSegment',
    label: 'label.marketSegment',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'country',
    label: 'label.country',
    sortable: true,
  },
  {
    name: 'state',
    label: 'label.state',
    sortable: true,
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
  {
    name: 'reason',
    label: 'label.reason',
    isRenderT: true,
    sortable: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reason')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
];

export const tabelColumnOrder = [
  {
    name: 'id',
    label: 'label.id',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'orderType',
    label: 'label.orderType',
    fieldOption: 'types',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:types')().find(val => val.value === item.orderType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'status',
    label: 'label.orderStatus',
    fieldOption: 'statusOrder',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
  {
    name: 'subscriptionReason',
    label: 'label.subscriptionReason',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionReason')().find(val => val.value === item.subscriptionReason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'orderSubType',
    label: 'label.orderSubType',
  },
  {
    name: 'orderLevel',
    label: 'label.orderLevel',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'crmId',
    label: 'label.crmId',
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
  },
  {
    name: 'orderSource',
    label: 'label.orderSource',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:orderSource')().find(val => val.value === item.orderSource) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'userId',
    label: 'label.userId',
  },
  {
    name: 'submittedDate',
    label: 'label.submittedDate',
  },
  {
    name: 'reason',
    label: 'label.reason',
  },
  {
    name: 'provisioningStartDate',
    label: 'label.provisioningStartDate',
  },
  {
    name: 'serviceProvisionedDate',
    label: 'label.provisioningConfirmationDate',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnFailedPayment = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'paymentDate',
    label: 'label.paymentDate',
    sortable: true,
  },
  {
    name: 'invoiceUnitId',
    label: 'label.invoiceUnitId',
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'amount',
    label: 'label.amount',
    sortable: true,
  },
  {
    name: 'failureReason',
    label: 'label.failureReason',
    sortable: true,
  },
  {
    name: 'paymentTerm',
    label: 'label.paymentTerm',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentTerm')().find(val => val.value === item.paymentTerm) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportBilling = [
  {
    name: 'id',
    label: 'label.id',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'groupId',
    label: 'label.groupId',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'billType',
    label: 'label.billType',
    fieldOption: 'billUnitType',
    // sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:billUnitType')().find(val => val.value === item.billType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'total',
    label: 'label.total',
  },
  {
    name: 'nonPayingTotal',
    label: 'label.nonPayingTotal',
  },
  {
    name: 'billTotal',
    label: 'label.billTotal',
  },
  {
    name: 'status',
    label: 'label.billStatus',
    // sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
];

export const tabelColumnExportBillProductSummary = [
  {
    name: 'id',
    label: 'label.id',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'productCompany',
    label: 'label.productCompany',
    sortable: true,
  },
  {
    name: 'productLine',
    label: 'label.productLine',
  },
  {
    name: 'productFamily',
    label: 'label.productFamily',
  },
  {
    name: 'productGroup',
    label: 'label.productGroup',
  },
  {
    name: 'productSubGroup',
    label: 'label.productSubGroup',
    // sortable: true,
  },
  {
    name: 'itemId',
    label: 'label.itemId',
    sortable: true,
  },
  {
    name: 'itemName',
    label: 'label.itemName',
  },
  {
    name: 'total',
    label: 'label.total',
    // sortable: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'billType',
    label: 'label.billType',
    fieldOption: 'billUnitType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:billUnitType')().find(val => val.value === item.billType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'billStatus',
    label: 'label.billStatus',
    fieldOption: 'invoiceStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.billStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'billingDom',
    label: 'label.billingDom',
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportBillTransactionSummary = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'billUnitId',
    label: 'label.billUnitId',
    sortable: true,
  },
  {
    name: 'billUnitStatus',
    label: 'label.billUnitStatus',
  },
  {
    name: 'invoiceUnitId',
    label: 'label.invoiceUnitId',
  },
  {
    name: 'status',
    label: 'label.invoiceUnitStatus',
    fieldOption: 'invoiceStatus',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'billableServiceId',
    label: 'label.billableServiceId',
  },
  {
    name: 'billableServiceName',
    label: 'label.billableServiceName',
  },
  {
    name: 'transactionDescription',
    label: 'label.description',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'priceOfferId',
    label: 'label.priceOfferId',
  },
  {
    name: 'priceOfferName',
    label: 'label.priceOfferName',
  },
  {
    name: 'itemId',
    label: 'label.itemId',
  },
  {
    name: 'itemName',
    label: 'label.itemName',
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
];

export const tabelColumnExportAgeingSummary = [
  { name: 'accountId', label: 'label.accountId' },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'accountCategory',
    label: 'label.type',
  },
  {
    name: 'collectionAgent',
    label: 'label.collectionAgent',
  },
  {
    name: 'salesConsultant',
    label: 'label.salesExecutive',
  },
  {
    name: 'salesFunction',
    label: 'label.salesFunction',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'dueDate',
    label: 'label.invoiceDueDate',
  },
  {
    name: 'totalDue',
    label: 'label.invoiceDue',
  },
  {
    name: 'currency',
    label: 'label.currency',
  },
  {
    name: 'total0To15',
    label: 'label.015days',
  },
  {
    name: 'total15To30',
    label: 'label.1530days',
  },
  {
    name: 'total30To60',
    label: 'label.3060days',
  },
  {
    name: 'total60To90',
    label: 'label.6090days',
  },
  {
    name: 'total90To120',
    label: 'label.90120days',
  },
  {
    name: 'total120To150',
    label: 'label.120150days',
  },
  {
    name: 'total150To180',
    label: 'label.150180days',
  },
  {
    name: 'total180To270',
    label: 'label.180270days',
  },
  {
    name: 'total270To290',
    label: 'label.270290days',
  },
  {
    name: 'total290To365',
    label: 'label.290365days',
  },
  {
    name: 'total365AndMore',
    label: 'label.365OverDays',
  },
  {
    name: 'groupCompany',
    label: 'label.groupCompany',
  },
  {
    name: 'numberOfDaysSinceDueDate',
    label: 'label.numberOfDaysSinceDueDate',
  },
  {
    name: 'collectionUnitId',
    label: 'label.collectionUnitId',
  },
  {
    name: 'collectionUnitStatus',
    label: 'label.collectionUnitStatus',
  },
];

export const tabelColumnExportJobSchedule = [
  {
    name: 'jobScheduleDate',
    label: 'label.jobScheduleDate',
    sortable: true,
  },
  {
    name: 'jobScheduleId',
    label: 'label.jobScheduleId',
  },
  {
    name: 'jobScheduleStatus',
    label: 'label.jobScheduleStatus',
    fieldOption: 'jobStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jobStatus')().find(val => val.value === item.jobScheduleStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'jobId',
    label: 'label.jobId',
  },
  {
    name: 'jobType',
    label: 'label.jobType',
    fieldOption: 'jobTypes',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jobTypes')().find(val => val.value === item.jobType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'jobStatus',
    label: 'label.jobStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jobStatus')().find(val => val.value === item.jobStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'successCount',
    label: 'label.successCount',
  },
  {
    name: 'failureCount',
    label: 'label.failureCount',
  },
  {
    name: 'totalCount',
    label: 'label.totalCount',
  },
];

export const tabelColumnExportJobFailed = [
  {
    name: 'jobId',
    label: 'label.jobId',
  },
  {
    name: 'index',
    label: 'label.index',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'referenceId',
    label: 'label.referenceId',
  },
  {
    name: 'referenceType',
    label: 'label.referenceType',
  },
  // {
  //   name: 'accountType',
  //   label: 'label.accountType',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  //   sortable: true,
  // },
  {
    name: 'jobType',
    label: 'label.jobType',
    fieldOption: 'jobTypes',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jobTypes')().find(val => val.value === item.jobType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'jobDate',
    label: 'label.jobDate',
    sortable: true,
  },
  {
    name: 'failureReason',
    label: 'label.failureReason',
    sortable: true,
  },
];

export const tabelColumnExportTrialSubscription = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'trialTerm',
    label: 'label.trialTerm',
  },
  {
    name: 'trialTermUnit',
    label: 'label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialType',
    label: 'label.trialType',
    fieldOption: 'trialTypes',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialTypes')().find(val => val.value === item.trialType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'trialLevel',
    label: 'label.trialLevel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialLevel')().find(val => val.value === item.trialLevel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'subscriptionId',
    label: 'label.subscriptionId',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'initialTermUnit',
    label: 'label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'label.initialTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'label.renewalTermUnit',
    fieldOption: 'initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'label.renewalTerm',
  },
  {
    name: 'status',
    label: 'label.status',
    fieldOption: 'subscriptionStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportSubscriptionExpiry = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'subscriptionId',
    label: 'label.subscriptionId',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'initialTermUnit',
    label: 'label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'label.initialTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'label.renewalTermUnit',
    fieldOption: 'initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'label.renewalTerm',
  },
  {
    name: 'status',
    label: 'label.status',
    fieldOption: 'subscriptionStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportAllCollection = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
    sortable: true,
  },
  {
    name: 'invoiceDate',
    label: 'label.invoiceDate',
  },
  {
    name: 'lastActionDate',
    label: 'label.lastActionDate',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'due',
    label: 'label.due',
    sortable: true,
  },
  {
    name: 'daysInCollection',
    label: 'label.daysInCollection',
    sortable: true,
  },
  {
    name: 'paymentTerm',
    label: 'label.paymentTerm',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentTerm')().find(val => val.value === item.paymentTerm) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'groupCompany',
    label: 'label.groupCompany',
  },
];

export const tabelColumnExportAROpsDetails = [
  {
    name: 'id',
    label: 'label.id',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'processDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'arOpsType',
    label: 'label.arOpsType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arOpsType')().find(val => val.value === item.arOpsType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'arLevel',
    label: 'label.arLevel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arLevel')().find(val => val.value === item.arLevel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'taxTreatment',
    label: 'label.taxTreatment',
    fieldOption: 'taxRule',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:taxRule')().find(val => val.value === item.taxTreatment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'arCrDrType',
    label: 'label.credit_debit',
    fieldOption: 'CRDRType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:CRDRType')().find(val => val.value === item.arCrDrType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'label.reason',
    sortable: true,
    // isRenderT: true,
    // render: (colName, item, t) => {
    //   const slt = t ? t('selections:reasonConfig')().find(val => val.value === item.reason) : '';
    //   return <span>{slt ? slt.label : ''}</span>;
    // },
  },
  {
    name: 'summaryAmount',
    label: 'label.writeOffSummaryTotal',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.summaryAmount)}</span>;
    },
  },
  {
    name: 'addOnTaxRate',
    label: 'label.addOnTaxRate',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.addOnTaxRate)}</span>;
    },
  },
  {
    name: 'addOnTaxAmount',
    label: 'label.addOnTaxAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.addOnTaxAmount)}</span>;
    },
  },
  {
    name: 'taxRate',
    label: 'label.mainTaxRate',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.taxRate)}</span>;
    },
  },
  {
    name: 'taxAmount',
    label: 'label.mainTaxAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.taxAmount)}</span>;
    },
  },
  {
    name: 'amount',
    label: 'label.amount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amount)}</span>;
    },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportOpenDispute = [
  {
    name: 'id',
    label: 'label.id',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'processDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'arLevel',
    label: 'label.arLevel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arLevel')().find(val => val.value === item.arLevel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'taxTreatment',
    label: 'label.taxTreatment',
    fieldOption: 'taxRule',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:taxRule')().find(val => val.value === item.taxTreatment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'arCrDrType',
    label: 'label.credit_debit',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:CRDRType')().find(val => val.value === item.arCrDrType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'label.reason',
    sortable: true,
    fieldOption: 'reasonConfig',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reasonConfig')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportOpenWriteOff = [
  {
    name: 'id',
    label: 'label.id',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'processDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'arLevel',
    label: 'label.arLevel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:arLevel')().find(val => val.value === item.arLevel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'taxTreatment',
    label: 'label.taxTreatment',
    fieldOption: 'taxRule',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:taxRule')().find(val => val.value === item.taxTreatment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'arCrDrType',
    label: 'label.credit_debit',
    fieldOption: 'CRDRType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:CRDRType')().find(val => val.value === item.arCrDrType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'label.reason',
    sortable: true,
    // isRenderT: true,
    // render: (colName, item, t) => {
    //   const slt = t ? t('selections:reasonConfig')().find(val => val.value === item.reason) : '';
    //   return <span>{slt ? slt.label : ''}</span>;
    // },
  },
  {
    name: 'writeOffSummaryTotal',
    label: 'label.writeOffSummaryTotal',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.writeOffSummaryTotal)}</span>;
    },
  },
  {
    name: 'addOnTaxRate',
    label: 'label.addOnTaxRate',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.addOnTaxRate)}</span>;
    },
  },
  {
    name: 'addOnTaxAmount',
    label: 'label.addOnTaxAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.addOnTaxAmount)}</span>;
    },
  },
  {
    name: 'taxRate',
    label: 'label.mainTaxRate',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.taxRate)}</span>;
    },
  },
  {
    name: 'taxAmount',
    label: 'label.mainTaxAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.taxAmount)}</span>;
    },
  },
  {
    name: 'amount',
    label: 'label.amount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amount)}</span>;
    },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'paymentMethod',
    label: 'label.paymentMethod',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnUnbilledTransactions = [
  {
    name: 'transactionId',
    label: 'label.transactionId',
  },
  // {
  //   name: 'recordId',
  //   label: 'label.recordId',
  // },
  {
    name: 'transactionDate',
    label: 'label.transactionDate',
    sortable: true,
  },
  // {
  //   name: 'netTotal',
  //   label: 'label.netTotal',
  // },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'netAmount',
    label: 'label.netAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.netAmount)}</span>;
    },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  // {
  //   name: 'suspenseReason',
  //   label: 'label.suspenseReason',
  //   sortable: true,
  // },
  // {
  //   name: 'failureReason',
  //   label: 'label.failureReason',
  //   sortable: true,
  // },
];

export const tabelColumnBillingInvoicing = [
  {
    name: 'billId',
    label: 'label.billId',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'invoiceDate',
    label: 'label.invoiceDate',
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  // {
  //   name: 'company',
  //   label: 'label.organization',
  // },
  {
    name: 'total',
    label: 'label.total',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.total)}</span>;
    },
  },
  {
    name: 'nonPayingTotal',
    label: 'label.nonPayingTotal',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.nonPayingTotal)}</span>;
    },
  },
  {
    name: 'billTotal',
    label: 'label.billTotal',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.billTotal)}</span>;
    },
  },
  {
    name: 'due',
    label: 'label.due',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.due)}</span>;
    },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'billType',
    label: 'label.billType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:billUnitType')().find(val => val.value === item.billType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    fieldOption: 'invoiceStatus',
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
];

export const tabelColumnBillingAR = [
  {
    name: 'billId',
    label: 'label.billId',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'invoiceDate',
    label: 'label.invoiceDate',
  },
  {
    name: 'dueDate',
    label: 'label.dueDate',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },

  {
    name: 'total',
    label: 'label.total',
    isAmount: true,
  },
  {
    name: 'nonPayingTotal',
    label: 'label.nonPayingTotal',
    isAmount: true,
  },
  {
    name: 'billTotal',
    label: 'label.billTotal',
    isAmount: true,
  },
  {
    name: 'due',
    label: 'label.due',
    sortable: true,
    isAmount: true,
    style: { minWidth: '120px' },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'billType',
    label: 'label.billType',
    fieldOption: 'invoiceUnitType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceUnitType')().find(val => val.value === item.billType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'status',
    label: 'label.status',
    sortable: true,
  },
  {
    name: 'payment',
    label: 'label.payment',
    isAmount: true,
  },
  {
    name: 'writeoff',
    label: 'label.writeOff',
    isAmount: true,
  },
  {
    name: 'adjusted',
    label: 'label.adjusted',
    isAmount: true,
  },
  {
    name: 'refund',
    label: 'label.refund',
    isAmount: true,
  },
  {
    name: 'disputed',
    label: 'label.disputed',
    isAmount: true,
  },
  {
    name: 'settlement',
    label: 'label.settlement',
    isAmount: true,
  },
  {
    name: 'invoiceTransferred',
    label: 'label.invoiceTransferred',
    isAmount: true,
  },
  {
    name: 'chargeBack',
    label: 'label.chargeBack',
    isAmount: true,
  },
];

export const tabelColumnExportUsageSummary = [
  {
    name: 'batchId',
    label: 'label.batchId',
    sortable: true,
  },
  // {
  //   name: 'fileName',
  //   label: 'label.fileName',
  //   sortable: true,
  // },
  {
    name: 'fileDate',
    label: 'label.fileDate',
    sortable: true,
  },
  {
    name: 'source',
    label: 'label.source',
    sortable: true,
  },
  {
    name: 'totalTxns',
    label: 'label.totalTransactions',
  },
  {
    name: 'ratedTxns',
    label: 'label.ratedTransactions',
  },
  {
    name: 'suspendedTxns',
    label: 'label.suspendedTransactions',
    sortable: true,
  },
  {
    name: 'erroredTxns',
    label: 'label.erroredTransactions',
    sortable: true,
  },
  // {
  //   name: 'skippedTxns',
  //   label: 'label.skippedTransaction',
  // },
  {
    name: 'pendingTxns',
    label: 'label.pendingTransaction',
  },
];

export const tabelColumnExportUsageDetail = [
  {
    name: 'batchId',
    label: 'label.batchId',
    sortable: true,
  },
  {
    name: 'fileName',
    label: 'label.fileName',
    sortable: true,
  },
  {
    name: 'fileDate',
    label: 'label.fileDate',
    sortable: true,
  },
  {
    name: 'source',
    label: 'label.source',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
    sortable: true,
  },
  {
    name: 'quantityUnit',
    label: 'label.quantityUnit',
  },
  // {
  //   name: 'productLine',
  //   label: 'label.productLine',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:serviceType')().find(val => val.value === item.productLine) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  // },
  {
    name: 'usageId',
    label: 'label.usageId',
  },
  {
    name: 'usageClass',
    label: 'label.usageClass',
  },
  // {
  //   name: 'serviceType',
  //   label: 'label.serviceType',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  // },
  {
    name: 'recordIndicator',
    label: 'label.recordIndicator',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:usageRecIndicator')().find(val => val.value === item.recordIndicator) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'grossValue',
    label: 'label.grossValue',
  },
  {
    name: 'netValue',
    label: 'label.netValue',
  },
  {
    name: 'itemId',
    label: 'label.itemId',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:usageRecStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
    sortable: true,
  },
];

export const tabelColumnExportUsageReconciliation = [
  {
    name: 'fileName',
    label: 'label.fileNameBatchId',
    sortable: true,
  },
  {
    name: 'fileDate',
    label: 'label.fileDate',
    sortable: true,
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'sourcePlatform',
    label: 'label.sourcePlatform',
    sortable: true,
  },
];

export const tabelColumnCdrFileStageStatsData = [
  {
    name: 'stage',
    label: 'label.stage',
  },
  {
    name: 'status',
    label: 'label.status',
  },
  {
    name: 'totalCount',
    label: 'label.totalCount',
  },
  {
    name: 'pendingCount',
    label: 'label.pendingCount',
  },
  {
    name: 'processedCount',
    label: 'label.processedCount',
  },
  {
    name: 'errorCount',
    label: 'label.errorCount',
  },
  {
    name: 'suspendedCount',
    label: 'label.suspendedCount',
  },
  {
    name: 'ignoredCount',
    label: 'label.ignoredCount',
  },
];

export const tabelColumnExportRevenueSummary = [
  {
    name: 'productCompany',
    label: 'label.productCompany',
    sortable: true,
  },
  {
    label: 'common:label.glAccount',
    name: 'glAccount',
    sortable: true,
  },
  {
    name: 'productFamily',
    label: 'label.productFamily',
    sortable: true,
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    sortable: true,
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    sortable: true,
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    sortable: true,
  },
  {
    name: 'itemName',
    label: 'label.itemName',
    sortable: true,
  },
  {
    label: 'common:label.recognitionRevenue',
    name: 'recognitionType',
    fieldOption: 'revenueRecognitionType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:revenueRecognitionType')().find(val => val.value === item.recognitionType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'remainingRevenue',
    label: 'label.remainingRevenue',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.remainingRevenue)}</span>;
    },
  },
  {
    name: 'totalRevenue',
    label: 'label.totalRevenue',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.totalRevenue)}</span>;
    },
  },
];

export const tabelColumnExportRevenueRecognition = [
  // {
  //   name: 'date',
  //   label: 'label.date',
  //   sortable: true,
  // },
  {
    name: 'type',
    label: 'label.revenueType',
    fieldOption: 'revenueType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:revenueType')().find(val => val.value === item.type) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'recognitionType',
    label: 'label.revenueRecognitionType',
    fieldOption: 'revenueRecognitionType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:revenueRecognitionType')().find(val => val.value === item.recognitionType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'productCompany',
    label: 'label.productCompany',
    sortable: true,
  },
  {
    label: 'common:label.glAccount',
    name: 'glAccount',
    sortable: true,
  },
  {
    name: 'productFamily',
    label: 'label.productFamily',
    sortable: true,
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    sortable: true,
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    sortable: true,
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    sortable: true,
  },
  {
    name: 'itemName',
    label: 'label.itemName',
    sortable: true,
  },
  {
    label: 'common:label.billEndDate',
    name: 'billEndDate',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'state',
    label: 'label.state',
    sortable: true,
  },
  {
    name: 'country',
    label: 'label.country',
    sortable: true,
  },
  {
    name: 'recognizedRevenue',
    label: 'label.recognizedRevenue',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.recognizedRevenue)}</span>;
    },
  },
  {
    name: 'remainingRevenue',
    label: 'label.remainingRevenue',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.remainingRevenue)}</span>;
    },
  },
  {
    name: 'totalRevenue',
    label: 'label.totalRevenue',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.totalRevenue)}</span>;
    },
  },
];

export const tabelColumnExportActiveSubscription = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  // {
  //   name: 'trialType',
  //   label: 'label.trialType',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:trialTypes')().find(val => val.value === item.trialType) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  //   // sortable: true,
  // },
  {
    name: 'subscriptionId',
    label: 'label.subscriptionId',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    // sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'initialTermUnit',
    label: 'label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'label.initialTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'label.renewalTermUnit',
    fieldOption: 'initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'label.renewalTerm',
  },
  {
    name: 'status',
    label: 'label.status',
    fieldOption: 'subscriptionStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:subscriptionStatus')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportJobDetail = [
  {
    name: 'jobScheduleDate',
    label: 'label.jobScheduleDate',
    sortable: true,
  },
  {
    name: 'jobType',
    label: 'label.jobType',
    fieldOption: 'jobTypes',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jobTypes')().find(val => val.value === item.jobType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },

  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'jobStatus',
    label: 'label.status',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jobStatus')().find(val => val.value === item.jobStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
  },
];

export const tabelColumnExportTaxSummary = [
  {
    name: 'state',
    label: 'label.state',
    sortable: true,
  },
  {
    name: 'country',
    label: 'label.country',
    sortable: true,
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdictionLevel',
    fieldOption: 'jurisdiction',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jurisdiction')().find(val => val.value === item.jurisdiction) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'billMonth',
    label: 'label.billMonth',
    sortable: true,
  },
  {
    name: 'taxableAmount',
    label: 'label.totalTaxableAmount',
  },

  {
    name: 'exemptAmount',
    label: 'label.totalExemptAmount',
  },
  {
    name: 'taxcode',
    label: 'label.taxCode',
    sortable: true,
  },
  {
    name: 'currency',
    label: 'label.currency',
  },
  {
    name: 'taxDescription',
    label: 'label.taxDescription',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
  },
  {
    name: 'taxAmount',
    label: 'label.taxAmount',
  },
];

export const tabelColumnExportTaxDetail = [
  {
    name: 'state',
    label: 'label.state',
    sortable: true,
  },
  {
    name: 'country',
    label: 'label.country',
    sortable: true,
  },
  {
    name: 'jurisdiction',
    label: 'label.jurisdictionLevel',
    fieldOption: 'jurisdiction',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:jurisdiction')().find(val => val.value === item.jurisdiction) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'billEndDate',
    label: 'label.billEndDate',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },

  {
    name: 'crdrNoteId',
    label: 'label.crdrNoteId',
  },
  {
    name: 'invoiceStatus',
    label: 'label.invoiceStatus',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.invoiceUnitStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'taxableAmount',
    label: 'label.totalTaxableAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.taxableAmount)}</span>;
    },
  },
  {
    name: 'exemptAmount',
    label: 'label.totalExemptAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.exemptAmount)}</span>;
    },
  },
  {
    name: 'taxcode',
    label: 'label.taxCode',
    sortable: true,
  },
  {
    name: 'taxDescription',
    label: 'label.taxDescription',
  },
  {
    name: 'taxCategory',
    label: 'label.taxCategory',
  },
  {
    name: 'taxAmount',
    label: 'label.taxAmount',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.taxAmount)}</span>;
    },
  },
  {
    name: 'currency',
    label: 'label.currency',
  },
];

export const tabelColumnExportSubscriptionContractExpiry = [
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'firstName',
    label: 'label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    sortable: true,
  },
  {
    name: 'salesChannel',
    label: 'label.salesChannel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'marketSegment',
    label: 'label.marketSegment',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'termEndDate',
    label: 'label.termEndDate',
  },
];

export const tabelColumnExportTrialToPaidSubscription = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'company',
    label: 'label.organization',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'trialTerm',
    label: 'label.trialTerm',
  },
  {
    name: 'trialTermUnit',
    label: 'label.trialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'trialType',
    label: 'label.trialType',
    fieldOption: 'trialTypes',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialTypes')().find(val => val.value === item.trialType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'trialLevel',
    label: 'label.trialLevel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:trialLevel')().find(val => val.value === item.trialLevel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    sortable: true,
  },
  {
    name: 'trialEndDate',
    label: 'label.trialEndDate',
  },
  {
    name: 'subscriptionId',
    label: 'label.subscriptionId',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'initialTermUnit',
    label: 'label.initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'label.initialTerm',
  },
  {
    name: 'renewalTermUnit',
    label: 'label.renewalTermUnit',
    fieldOption: 'initialTermUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'renewalTerm',
    label: 'label.renewalTerm',
  },
];

export const tabelColumnExportRevenueForecast = [
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'firstName',
    label: 'label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    sortable: true,
  },
  {
    name: 'salesChannel',
    label: 'label.salesChannel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'marketSegment',
    label: 'label.marketSegment',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportMRRForecast = [
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'firstName',
    label: 'label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'sellingCompany',
    label: 'label.sellingCompany',
    sortable: true,
  },
  {
    name: 'salesChannel',
    label: 'label.salesChannel',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:salesChannel')().find(val => val.value === item.salesChannel) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'marketSegment',
    label: 'label.marketSegment',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:marketSegment')().find(val => val.value === item.marketSegment) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportVoiceCustomerCallTypeSummary = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'noOfCalls',
    label: 'label.noOfCalls',
  },
  {
    name: 'noOfMinutes',
    label: 'label.totalMinutes',
    sortable: true,
  },
  {
    name: 'amountUsd',
    label: 'label.amountUsd',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountUsd)}</span>;
    },
  },
  {
    name: 'amountMxn',
    label: 'label.amountMxn',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountMxn)}</span>;
    },
  },
  {
    name: 'usageType',
    label: 'label.callType',
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
];

export const tabelColumnExportVoiceOperatorCallTypeSummary = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'noOfCalls',
    label: 'label.noOfCalls',
  },
  {
    name: 'noOfMinutes',
    label: 'label.totalMinutes',
    sortable: true,
  },
  {
    name: 'noOfSeconds',
    label: 'label.totalSeconds',
  },
  {
    name: 'amountUsd',
    label: 'label.amountUsd',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountUsd)}</span>;
    },
  },
  {
    name: 'amountMxn',
    label: 'label.amountMxn',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountMxn)}</span>;
    },
  },
  // {
  //   name: 'usageType',
  //   label: 'label.callType',
  // },
  {
    name: 'operatorId',
    label: 'label.operatorId',
  },
  {
    name: 'operatorName',
    label: 'label.operatorName',
  },
  {
    name: 'direction',
    label: 'label.direction',
    isRenderT: true,
    fieldOption: 'usageDirection',
    render: (colName, item, t) => {
      const slt = t ? t('selections:usageDirection')().find(val => val.value === item.direction) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportVoiceExcessTrafficReport = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'usageType',
    label: 'label.callType',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'noOfCalls',
    label: 'label.noOfCalls',
  },
  {
    name: 'noOfMinutes',
    label: 'label.totalMinutes',
    sortable: true,
  },
  // {
  //   name: 'noOfSeconds',
  //   label: 'label.totalSeconds',
  // },
  {
    name: 'amountMxn',
    label: 'label.amountMxn',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountMxn)}</span>;
    },
  },
  {
    name: 'amountUsd',
    label: 'label.amountUsd',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountUsd)}</span>;
    },
  },
  // {
  //   name: 'operatorId',
  //   label: 'label.operatorId',
  // },
  // {
  //   name: 'operatorName',
  //   label: 'label.operatorName',
  // },
];

export const tabelColumnExportVoiceTrafficByLdDistination = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'noOfCalls',
    label: 'label.noOfCalls',
  },
  {
    name: 'noOfMinutes',
    label: 'label.totalMinutes',
    sortable: true,
  },
  {
    name: 'sciudad',
    label: 'label.location',
  },
];

export const tabelColumnExportOnDemand = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'ipAddress',
    label: 'label.ipAddress',
    sortable: true,
  },
  {
    name: 'volumeUpload',
    label: 'label.volumeUpload',
  },
  {
    name: 'volumeDownload',
    label: 'label.volumeDownload',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'recordCount',
    label: 'label.recordCount',
  },
];

export const tabelColumnExportOnDemandDetails = [
  {
    name: 'provisioningId',
    label: 'label.ipAddress',
  },
  {
    name: 'recordDate',
    label: 'label.recordDate',
  },
  {
    name: 'batchId',
    label: 'label.fileName',
  },
  {
    name: 'uploadVolume',
    label: 'label.uploadVolume',
  },
  {
    name: 'downloadVolume',
    label: 'label.downloadVolume',
  },
];

export const tabelColumnExportNewSmsReportData = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'noOfSms',
    label: 'label.count',
    sortable: true,
  },
  {
    name: 'amountMxn',
    label: 'label.amountMxn',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountMxn)}</span>;
    },
  },
  {
    name: 'amountUsd',
    label: 'label.amountUsd',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountUsd)}</span>;
    },
  },
];

export const tabelColumnExportVoiceITXRawReport = [
  {
    name: 'lineDayOfMonth',
    label: 'label.lineDayOfMonth',
    sortable: true,
  },
  {
    name: 'operatorId',
    label: 'label.operatorId',
  },
  {
    name: 'operatorName',
    label: 'label.operatorName',
  },
  {
    name: 'noOfMinutes',
    label: 'label.totalMinutes',
    sortable: true,
  },
  {
    name: 'noOfSeconds',
    label: 'label.totalSeconds',
    sortable: true,
  },
  {
    name: 'noOfCalls',
    label: 'label.count',
    sortable: true,
  },
];

export const tabelColumnExportITXSMS = [
  {
    name: 'lineDayOfMonth',
    label: 'label.lineDayOfMonth',
    sortable: true,
  },
  {
    name: 'operatorId',
    label: 'label.operatorId',
  },
  {
    name: 'operatorName',
    label: 'label.operatorName',
  },
  {
    name: 'originalNetworkId',
    label: 'label.originalNetworkId',
    sortable: true,
  },
  {
    name: 'noOfSms',
    label: 'label.count',
    sortable: true,
  },
];

export const tabelColumnExportVoiceICallTypeSummary = [
  {
    name: 'callType',
    label: 'label.callType',
    sortable: true,
  },
  {
    name: 'noOfCalls',
    label: 'label.count',
  },
  {
    name: 'amountMxn',
    label: 'label.amountMxn',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountMxn)}</span>;
    },
  },
  {
    name: 'amountUsd',
    label: 'label.amountUsd',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amountUsd)}</span>;
    },
  },
  {
    name: 'costMxn',
    label: 'label.costMxn',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.costMxn)}</span>;
    },
  },
  {
    name: 'costUsd',
    label: 'label.costUsd',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.costUsd)}</span>;
    },
  },
];

export const tabelColumnExportInternetOperatorDataCost = [
  {
    name: 'operatorName',
    label: 'label.operatorName',
    sortable: true,
  },
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'contractCapacity',
    label: 'label.capacity',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.contractCapacity)}</span>;
    },
  },
  {
    name: 'commitmentBase',
    label: 'label.commitment',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.commitmentBase)}</span>;
    },
  },
  {
    name: 'tariff',
    label: 'label.tariff',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.tariff)}</span>;
    },
  },
  {
    name: 'rentalAmount',
    label: 'label.rental',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.rentalAmount)}</span>;
    },
  },
  {
    name: 'totalQuantity',
    label: 'label.quantity',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.totalQuantity)}</span>;
    },
  },
  {
    name: 'directCommitment',
    label: 'label.directCommitment',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.directCommitment)}</span>;
    },
  },
  {
    name: 'directTariff',
    label: 'label.directTariff',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.directTariff)}</span>;
    },
  },
  {
    name: 'totalCostDirect',
    label: 'label.directCost',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.totalCostDirect)}</span>;
    },
  },
  // {
  //   name: 'commitmentLeft',
  //   label: 'label.commitmentLeft',
  //   render: (colName, item) => {
  //     return <span>{formatMoneyValue(item.commitmentLeft)}</span>;
  //   },
  // },
  {
    name: 'overageQuantity',
    label: 'label.overageQuantity',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.overageQuantity)}</span>;
    },
  },
  {
    name: 'overageCost',
    label: 'label.overageCost',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.overageCost)}</span>;
    },
  },
  {
    name: 'totalCost',
    label: 'label.totalCost',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.totalCost)}</span>;
    },
  },
];

export const tabelColumnExportTecoSummary = [
  {
    name: 'lineDate',
    label: 'label.date',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'operatorId',
    label: 'label.operatorId',
  },
  {
    name: 'operatorName',
    label: 'label.operatorName',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
  },
  {
    name: 'usageType',
    label: 'label.usageType',
  },
  {
    name: 'dataType',
    label: 'label.dataType',
    fieldOption: 'xaxisParameter',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:xaxisParameter')().find(val => val.value === item.dataType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'dataUnit',
    label: 'label.dataUnit',
    fieldOption: 'yaxisParameter',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:yaxisParameter')().find(val => val.value === item.dataUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'dataValue',
    label: 'label.dataValue',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.dataValue)}</span>;
    },
  },
];

export const tabelColumnExportAccountReport = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'externalId',
    label: 'label.externalId',
  },
  {
    name: 'company',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
  },
  {
    name: 'cancellationDate',
    label: 'label.cancellationDate',
  },
  {
    name: 'rfc',
    label: 'label.rfc',
  },
  {
    name: 'customAccountType',
    label: 'label.customAccountType',
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountType',
    label: 'label.accountType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'email',
    label: 'label.email',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'contactNumber',
    label: 'label.contactNumber',
  },
  {
    name: 'country',
    label: 'label.country',
    sortable: true,
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'zip',
    label: 'label.zip',
  },
  {
    name: 'state',
    label: 'label.state',
    sortable: true,
  },
  {
    name: 'reason',
    label: 'label.reason',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:reason')().find(val => val.value === item.reason) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

export const tabelColumnExportRawFile = [
  {
    name: 'fileId',
    label: 'label.fileId',
  },
  {
    name: 'fileName',
    label: 'label.fileName',
  },
  {
    name: 'sourcePlatform',
    label: 'label.platform',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.dateTime',
    sortable: true,
  },
  {
    name: 'TotalInputRecords',
    label: 'label.totalInputRecords',
    sortable: true,
  },
  {
    name: 'TotalImportedRecords',
    label: 'label.totalImportedRecords',
    sortable: true,
  },
  {
    name: 'TotalErrorRecords',
    label: 'label.totalErrorRecords',
    sortable: true,
  },
];

export const tabelColumnExportUsageTransactionDetail = [
  {
    name: 'id',
    label: 'label.usageId',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'fileId',
    label: 'label.fileId',
    sortable: true,
  },
  {
    name: 'sourceSystem',
    label: 'label.sourceSystem',
    sortable: true,
  },
  {
    name: 'batchid',
    label: 'label.batchid',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'source',
    label: 'label.source',
    sortable: true,
  },
  {
    name: 'destination',
    label: 'label.destination',
    sortable: true,
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
    sortable: true,
  },
  {
    name: 'routingNumber',
    label: 'label.routingNumber',
    sortable: true,
  },
  {
    name: 'direction',
    label: 'label.direction',
    fieldOption: 'usageDirection',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:usageDirection')().find(val => val.value === item.direction) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  // {
  //   name: 'ratingBatchId',
  //   label: 'label.ratingBatchId',
  // },
  {
    name: 'usageType',
    label: 'label.usageType',
    sortable: true,
  },
  {
    name: 'ratingDescr',
    label: 'label.ratingDescription',
    sortable: true,
  },
  {
    name: 'rateUnit',
    label: 'label.rateUnit',
  },
  {
    name: 'scaleUnit',
    label: 'label.scaleUnit',
  },
  {
    name: 'quantity',
    label: 'label.ratedQuantity',
  },
  {
    name: 'grossAmount',
    label: 'label.grossAmount',
  },
  {
    name: 'netAmount',
    label: 'label.netAmount',
  },
  {
    name: 'perUsageQuantity',
    label: 'label.perUsageQuantity',
  },
  {
    name: 'perUsageAmount',
    label: 'label.perUsageAmount',
  },
  {
    label: 'label.regionId',
    name: 'flexAttribute1',
  },
  {
    label: 'label.carrierId',
    name: 'flexAttribute2',
  },
  {
    label: 'label.npaId',
    name: 'flexAttribute3',
  },
  {
    label: 'label.operatorId',
    name: 'flexAttribute6',
  },
  {
    label: 'label.incommingRoute',
    name: 'flexAttribute7',
  },
  {
    label: 'label.origNetworkId',
    name: 'flexAttribute8',
  },
  {
    label: 'label.city',
    name: 'flexAttribute4',
  },
  {
    label: 'label.callType',
    name: 'flexAttribute5',
  },
  {
    label: 'label.billUnitId',
    name: 'billUnitId',
  },
  {
    label: 'label.billUnitStatus',
    name: 'billUnitStatus',
    fieldOption: 'billStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:billStatus')().find(val => val.value === item.billUnitStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    label: 'label.invoiceUnitId',
    name: 'invoiceUnitId',
  },
  {
    label: 'label.invoiceUnitStatus',
    name: 'invoiceUnitStatus',
    fieldOption: 'invoiceStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.invoiceUnitStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    label: 'label.uploadVolume',
    name: 'uploadVolume',
  },
  {
    label: 'label.downloadVolume',
    name: 'downloadVolume',
  },
];

export const tabelColumnExportItemServices = [
  {
    name: 'commercialName',
    label: 'label.commercialName',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'billableServiceName',
    label: 'label.billableServiceName',
    sortable: true,
  },
  {
    name: 'deliveryDate',
    label: 'label.deliveryDate',
    sortable: true,
  },
  {
    name: 'cancellationDate',
    label: 'label.cancellationDate',
  },
  {
    name: 'orderDate',
    label: 'label.orderDate',
  },
  {
    name: 'nextCutOffDate',
    label: 'label.cutOffDate',
  },
  {
    name: 'cutOffDOM',
    label: 'label.cutOffDOM',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:cutOffDOM')().find(val => val.value === `${item.cutOffDOM}`) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'recurringFrequency',
    label: 'label.billingFrequency',
    render: (colName, item) => {
      return <span>{formatNumberValue(item.recurringFrequency)}</span>;
    },
  },
  {
    name: 'recurringUnit',
    label: 'label.billingFrequencyUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:recurringUnit')().find(val => val.value === `${item.recurringUnit}`) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'status',
    label: 'label.status',
    sortable: true,
  },
  {
    name: 'quantity',
    label: 'label.quantity',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.quantity)}</span>;
    },
  },
  {
    name: 'invoiceExhibits',
    label: 'label.invoiceExhibits',
  },
  {
    name: 'exhibitsInvoiced',
    label: 'label.exhibitsInvoiced',
  },
  {
    name: 'unitPrice',
    label: 'label.unitPrice',
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.unitPrice)}</span>;
    },
  },
  {
    name: 'amount',
    label: 'label.amount',
    sortable: true,
    render: (colName, item) => {
      return <span>{formatMoneyValue(item.amount)}</span>;
    },
  },
  {
    name: 'capacity',
    label: 'label.capacity',
  },
  {
    name: 'uom',
    label: 'label.unitOfMeasure',
  },
  {
    name: 'currency',
    label: 'label.currency',
  },
  {
    name: 'operativeStatus',
    label: 'label.operativeStatus',
    sortable: true,
  },
  {
    name: 'adminStatus',
    label: 'label.adminStatus',
    sortable: true,
  },
  {
    name: 'circuitId',
    label: 'label.circuitId',
  },
  {
    name: 'clientCircuitId',
    label: 'label.clientCircuitId',
  },
  {
    name: 'loadType',
    label: 'label.loadType',
  },
  {
    name: 'billableServiceId',
    label: 'label.billableServiceId',
  },
  {
    name: 'instanceId',
    label: 'label.instanceId',
  },
  {
    name: 'partMonthWaiveOffFlag',
    label: 'label.partMonthWaiveOffFlag',
    render: (colName, item) => {
      return <span>{item.partMonthWaiveOffFlag ? 'TRUE' : item.partMonthWaiveOffFlag === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'moveOrder',
    label: 'label.moveOrder',
  },
  {
    name: 'isTraffic',
    label: 'label.trafficFlag',
    render: (colName, item) => {
      return <span>{item.isTraffic ? 'TRUE' : item.isTraffic === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'advanceFlag',
    label: 'label.inAdvanceFlag',
    render: (colName, item) => {
      return <span>{item.advanceFlag ? 'TRUE' : item.advanceFlag === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'initialTerm',
    label: 'label.contractTerm',
    render: (colName, item) => {
      return <span>{formatNumberValue(item.initialTerm)}</span>;
    },
  },
  {
    name: 'termEndDate',
    label: 'label.termEndDate',
  },
  {
    name: 'isParent',
    label: 'label.isParent',
    render: (colName, item) => {
      return <span>{item.isParent ? 'TRUE' : 'FALSE'}</span>;
    },
  },
  {
    name: 'isDispersed',
    label: 'label.dispersed',
  },
  {
    name: 'isMerged',
    label: 'label.merged',
  },
  {
    name: 'chargeType',
    label: 'label.chargeType',
  },
  {
    name: 'location1',
    label: 'label.location1',
  },
  {
    name: 'reason',
    label: 'label.reason',
  },
  {
    name: 'orderType',
    label: 'label.orderType',
    fieldOption: 'types',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:types')().find(val => val.value === item.orderType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'orderStatus',
    label: 'label.orderStatus',
    isRenderT: true,
    fieldOption: 'statusOrder',
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.orderStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'crmId',
    label: 'label.crmId',
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
  },

  {
    name: 'opportunityName',
    label: 'label.opportunity',
  },
  {
    name: 'location2',
    label: 'label.location2',
  },
  {
    name: 'billed',
    label: 'label.billed',
    render: (colName, item) => {
      return <span>{item.billed ? 'TRUE' : item.billed === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'isGrant',
    label: 'label.isGrant',
    render: (colName, item) => {
      return <span>{item.isGrant ? 'TRUE' : item.isGrant === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'orderAction',
    label: 'label.orderAction',
  },
];

export const tabelColumnExportRawCdrData = [
  {
    name: 'serviceProvider',
    label: 'label.sServiceProvider',
  },
  {
    name: 'userNumber',
    label: 'label.sUserNumber',
  },
  {
    name: 'groupNumber',
    label: 'label.sGroupNumber',
  },
  {
    name: 'direction',
    label: 'label.sDirection',
  },
  {
    name: 'callingNumber',
    label: 'label.sCallingNumber',
  },
  {
    name: 'calledNumber',
    label: 'label.sCalledNumber',
  },
  {
    name: 'startTime',
    label: 'label.sStartTime',
  },
  {
    name: 'answerIndicator',
    label: 'label.sAnswerIndicator',
  },
  {
    name: 'answerTime',
    label: 'label.sAnswerTime',
    sortable: true,
  },
  {
    name: 'releaseTime',
    label: 'label.sReleaseTime',
    sortable: true,
  },
  {
    name: 'terminationCause',
    label: 'label.sTerminationCause',
  },
  {
    name: 'dailedDigits',
    label: 'label.sDailedDigits',
  },
  {
    name: 'callCategory',
    label: 'label.sCallCategory',
  },
  {
    name: 'networkCallType',
    label: 'label.sNetworkCallType',
  },
  {
    name: 'networkTranslatedNumber',
    label: 'label.sNetworkTranslatedNumber',
  },
  {
    name: 'releasingParty',
    label: 'label.sReleasingParty',
  },
  {
    name: 'route',
    label: 'label.sRoute',
  },
  {
    name: 'networkCallId',
    label: 'label.sNetworkCallId',
  },
  {
    name: 'codec',
    label: 'label.sCodec',
  },
  {
    name: 'accessDeviceAddress',
    label: 'label.sAccessDeviceAddress',
  },
  {
    name: 'group',
    label: 'label.sGroup',
  },
  {
    name: 'department',
    label: 'label.sDepartment',
  },
  {
    name: 'authorizationCode',
    label: 'label.sAuthorizationCode',
  },
  {
    name: 'originalCalledNumber',
    label: 'label.sOriginalCalledNumber',
  },
  {
    name: 'originalCalledPresentationIndicator',
    label: 'label.sOriginalCalledPresentationIndicator',
  },
  {
    name: 'originalCalledReason',
    label: 'label.sOriginalCalledReason',
  },
  {
    name: 'redirectingNumber',
    label: 'label.sRedirectingNumber',
  },
  {
    name: 'redirectingPresentationIndicator',
    label: 'label.sRedirectingPresentationIndicator',
  },
  {
    name: 'redirectingReason',
    label: 'label.sRedirectingReason',
  },
  {
    name: 'chargeIndicator',
    label: 'label.sChargeIndicator',
  },
  {
    name: 'voicePortalInvocationTime',
    label: 'label.sVoicePortalInvocationTime',
  },
  {
    name: 'extAnswerDateTime',
    label: 'label.dExtAnswerDateTime',
  },
  {
    name: 'extDurationMinutes',
    label: 'label.nExtDurationMinutes',
    sortable: true,
  },
  {
    name: 'extDurationSeconds',
    label: 'label.nExtDurationSeconds',
    sortable: true,
  },
  {
    name: 'sreserved',
    label: 'label.sReserved',
  },
  {
    name: 'suserid',
    label: 'label.sUserid',
  },
  {
    name: 'sotherPartyName',
    label: 'label.sOtherPartyName',
  },
  // {
  //   name: 'incomingRoute',
  //   label: 'label.incomingRoute',
  // },
  // {
  //   name: 'outgoingRoute',
  //   label: 'label.outgoingRoute',
  // },
  // {
  //   name: 'callStatus',
  //   label: 'label.callStatus',
  // },
  // {
  //   name: 'terminatingAccess',
  //   label: 'label.terminatingAccess',
  // },
  // {
  //   name: 'extIddAbc',
  //   label: 'label.extIddAbc',
  // },
  // {
  //   name: 'extIddBcd',
  //   label: 'label.extIddBcd',
  // },
];

export const tabelColumnExportRawSmsData = [
  {
    name: 'startTime',
    label: 'label.startDate',
  },
  {
    name: 'endTime',
    label: 'label.endDate',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
  },
  {
    name: 'submitDate',
    label: 'label.submitDate',
    sortable: true,
  },
  {
    name: 'addressSrcDigits',
    label: 'label.addressSrcDigits',
  },
  {
    name: 'srcTon',
    label: 'label.srcTon',
  },
  {
    name: 'srcApi',
    label: 'label.srcApi',
  },
  {
    name: 'addressDestDigits',
    label: 'label.addressDestDigits',
  },
  {
    name: 'destTon',
    label: 'label.destTon',
  },
  {
    name: 'destApi',
    label: 'label.destApi',
  },
  {
    name: 'scheduleDeliveryTime',
    label: 'label.scheduleDeliveryTime',
    sortable: true,
  },
  {
    name: 'validityPeriod',
    label: 'label.validityPeriod',
  },
  {
    name: 'protocolId',
    label: 'label.protocolId',
  },
  {
    name: 'priority',
    label: 'label.priority',
  },
  {
    name: 'registerDelivery',
    label: 'label.registerDelivery',
  },
  {
    name: 'replaceIfPresent',
    label: 'label.replacePresent',
  },
  {
    name: 'dataCoding',
    label: 'label.dataCoding',
  },
  {
    name: 'messageLength',
    label: 'label.messageLength',
  },
  {
    name: 'firstTwentyCharactersOfSMS',
    label: 'label.first20Chars',
  },
  {
    name: 'optTag',
    label: 'label.optTag',
  },
  {
    name: 'optLength',
    label: 'label.optLength',
  },
  {
    name: 'optVal',
    label: 'label.optVal',
  },
  {
    name: 'origNetworkId',
    label: 'label.origNetworkId',
  },
  {
    name: 'networkId',
    label: 'label.networkId',
  },
  {
    name: 'host',
    label: 'label.host',
  },
  {
    name: 'esmClass',
    label: 'label.esmClass',
  },
  {
    name: 'gsmsiei',
    label: 'label.gsmsiei',
  },
  {
    name: 'gsmudh',
    label: 'label.gsmudh',
  },
  {
    name: 'messageDeliveryStatus',
    label: 'label.messageDeliveryStatus',
    sortable: true,
  },
  {
    name: 'extIdCinta',
    label: 'label.extIdCinta',
  },
  {
    name: 'messageId',
    label: 'label.messageId',
  },
  {
    name: 'newSequenceNumber',
    label: 'label.sequenceNumber',
  },
  {
    name: 'commandId',
    label: 'label.commandId',
  },
  {
    name: 'commandLength',
    label: 'label.commandLength',
  },
  {
    name: 'pduType',
    label: 'label.pduType',
  },
  {
    name: 'aspName',
    label: 'label.aspName',
  },
  {
    name: 'messageStatus',
    label: 'label.messageStatus',
  },
  {
    name: 'messageType',
    label: 'label.messageType',
  },
];

export const tabelColumnExportRawNonBroadsoft = [
  {
    name: 'answerTime',
    label: 'label.answerTime',
    sortable: true,
  },
  {
    name: 'extIddAbc',
    label: 'label.extIddAbc',
  },
  {
    name: 'extIdoBcd',
    label: 'label.extIdoBcd',
  },
  {
    name: 'callingNumber',
    label: 'label.callingNumber',
  },
  {
    name: 'calledNumber',
    label: 'label.calledNumber',
  },
  {
    name: 'incomingRoute',
    label: 'label.incomingRoute',
  },
  {
    name: 'outgoingRoute',
    label: 'label.outgoingRoute',
  },
  {
    name: 'sreserved',
    label: 'label.sreserved',
  },
  {
    name: 'callStatus',
    label: 'label.callStatus',
  },
  {
    name: 'terminatingAccess',
    label: 'label.terminatingAccess',
  },
  {
    name: 'failureCode',
    label: 'label.failureCode',
  },
  {
    name: 'disconnectReason',
    label: 'label.disconnectReason',
  },
  {
    name: 'commercial',
    label: 'label.commercial',
  },
  // {
  //   name: 'startDate',
  //   label: 'label.startDate',
  // },
  // {
  //   name: 'endDate',
  //   label: 'label.endDate',
  // },
  {
    name: 'callDuration',
    label: 'label.callDuration',
  },
  {
    name: 'extDurationMinutes',
    label: 'label.extDurationMinutes',
    sortable: true,
  },
  {
    name: 'extDurationSeconds',
    label: 'label.extDurationSeconds',
    sortable: true,
  },
  {
    name: 'partySipCallIdZero',
    label: 'label.partySipCallIdZero',
  },
  {
    name: 'partySipCallIdOne',
    label: 'label.partySipCallIdOne',
  },
];

export const tabelColumnExportServicesForcecast = filter => [
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'serviceName',
    label: 'label.serviceName',
    sortable: true,
  },
  {
    name: 'serviceId',
    label: 'label.serviceId',
  },
  {
    name: 'instanceId',
    label: 'label.instanceId',
  },
  {
    name: 'cutOffDom',
    label: 'label.cutOffDom',
    fieldOption: 'cutOffDOM',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:cutOffDOM')().find(val => val.value === `${item.cutOffDom}`) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'inAdvanceFlag',
    label: 'label.inAdvanceFlag',
    render: (colName, item) => {
      if (filter && filter.groupByAccount === 'TRUE') return '';
      return <span>{item.inAdvanceFlag ? 'TRUE' : 'FALSE'}</span>;
    },
  },
  {
    name: 'loadType',
    label: 'label.loadType',
  },
  {
    name: 'recurringUnit',
    label: 'label.recurringUnit',
  },
  {
    name: 'recurringFrequency',
    label: 'label.recurringFrequency',
  },
  {
    name: 'recurringType',
    label: 'label.recurringType',
  },
  {
    name: 'invoiceExhibits',
    label: 'label.invoiceExhibits',
  },
  {
    name: 'status',
    label: 'label.status',
  },
];

export const tabelColumnExportBillableServicesForecast = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
    sortable: true,
  },
  {
    name: 'billableServiceId',
    label: 'label.billableServiceId',
    sortable: true,
  },
  {
    name: 'billableServiceName',
    label: 'label.billableServiceName',
    sortable: true,
  },
  {
    name: 'currency',
    label: 'label.currency',
    sortable: true,
  },
  {
    name: 'cutOffDate',
    label: 'label.cutOffDate',
  },
  {
    name: 'cutOffDOM',
    label: 'label.cutOffDOM',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:cutOffDOM')().find(val => val.value === `${item.cutOffDOM}`) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'instanceId',
    label: 'label.instanceId',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'unitPrice',
    label: 'label.unitPrice',
  },
  {
    name: 'amount',
    label: 'label.amount',
    sortable: true,
  },
  {
    name: 'recurringFrequency',
    label: 'label.recurringFrequency',
  },
  {
    name: 'recurringUnit',
    label: 'label.recurringUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:recurringUnit')().find(val => val.value === item.recurringUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'startDate',
    label: 'label.startDate',
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'invoiceCurrency',
    label: 'label.invoiceCurrency',
  },
  {
    name: 'invoiceCurrencyAmount',
    label: 'label.invoiceCurrencyAmount',
  },
  {
    name: 'exchangeRate',
    label: 'label.exchangeRatedUsed',
  },
  {
    name: 'advanceFlag',
    label: 'label.advanceFlag',
    render: (colName, item) => {
      return <span>{item.advanceFlag ? 'TRUE' : item.advanceFlag === false ? 'FALSE' : null}</span>;
    },
  },
  {
    name: 'partMonthWaiveOffFlag',
    label: 'label.partMonthWaiveOffFlag',
    render: (colName, item) => {
      return <span>{item.partMonthWaiveOffFlag ? 'TRUE' : item.partMonthWaiveOffFlag === false ? 'FALSE' : ''}</span>;
    },
  },
  {
    name: 'chargeType',
    label: 'label.chargeType',
  },
  {
    name: 'invoiceExhibits',
    label: 'label.invoiceExhibits',
  },
  {
    name: 'exhibitsInvoiced',
    label: 'label.exhibitsInvoiced',
  },
];

export const tabelColumnExportBillObjectFilesSummary = [
  {
    name: 'documentType',
    label: 'label.documentType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:documentType')().find(val => val.value === item.documentType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'yearMonth',
    label: 'label.yearMonth',
    sortable: true,
  },
  {
    name: 'fileCount',
    label: 'label.countOfFiles',
    sortable: true,
  },
];

export const tabelColumnExportProvisioningData = [
  {
    name: 'clientId',
    label: 'label.clientId',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
    sortable: true,
  },
  {
    name: 'type',
    label: 'label.type',
    // fieldOption: 'typeProvisioning',
    sortable: true,
    // isRenderT: true,
    // render: (colName, item, t) => {
    //   const slt = t ? t('selections:typeProvisioning')().find(val => val.value === item.type) : '';
    //   return <span>{slt ? slt.label : ''}</span>;
    // },
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'status',
    label: 'label.status',
    sortable: true,
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
  },
  {
    name: 'instanceId',
    label: 'label.instanceId',
  },
];

export const tabelColumnExportBillingTracker = ({ currency, t }) => [
  {
    name: 'clientName',
    label: 'label.clientName',
    sortable: true,
  },
  {
    name: 'clientAccountId',
    label: 'label.clientAccountId',
  },
  {
    name: 'month',
    label: 'label.month',
    sortable: true,
  },
  {
    name: 'year',
    label: 'label.year',
  },
  {
    name: 'billStart',
    label: 'label.billingPeriodStart',
    sortable: true,
  },
  {
    name: 'billEnd',
    label: 'label.billingPeriodEnd',
    sortable: true,
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'billedAmount',
    label: 'label.billedAmount',
  },
  {
    name: 'exchangeRate',
    label: 'label.exchangeRate',
  },
  {
    name: 'billedExchangeAmount',
    label: `${t('label.billedAmount')} ${currency}`,
  },
  {
    name: 'paymentDate',
    label: 'label.paymentDate',
  },
  {
    name: 'paymentAmount',
    label: 'label.paymentAmount',
  },
  {
    name: 'billDue',
    label: 'label.billDue',
  },
  {
    name: 'comments',
    label: 'label.comments',
  },
];

export const tabelColumnExportemployeeCostingYearlyPLExportCSV = [
  {
    name: 'employeeId',
    label: 'label.employeeId',
  },
  {
    name: 'employeeName',
    label: 'label.employeeName',
  },
  {
    name: 'jobTitle',
    label: 'label.jobTitle',
    sortable: true,
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'clientName',
    label: 'label.clientName',
  },
  {
    name: 'clientJobTitle',
    label: 'label.clientJobTitle',
  },
  {
    name: 'monthOneGrossSal',
    label: 'monthOneGrossSal',
  },
  {
    name: 'monthTwoGrossSal',
    label: 'label.grossSalaryMxn',
  },
  {
    name: 'monthThreeGrossSal',
    label: 'monthThreeGrossSal',
  },
  {
    name: 'totalQuarterOneGrossSal',
    label: 'totalQuarterOneGrossSal',
  },
  {
    name: 'monthFourGrossSal',
    label: 'monthFourGrossSal',
  },
  {
    name: 'monthFiveGrossSal',
    label: 'label.grossSalaryMxn',
  },
  {
    name: 'monthSixGrossSal',
    label: 'monthSixGrossSal',
  },
  {
    name: 'totalQuarterTwoGrossSal',
    label: 'totalQuarterTwoGrossSal',
  },
  {
    name: 'monthSevenGrossSal',
    label: 'monthSevenGrossSal',
  },
  {
    name: 'monthEightGrossSal',
    label: 'label.grossSalaryMxn',
  },
  {
    name: 'monthNineGrossSal',
    label: 'monthNineGrossSal',
  },
  {
    name: 'totalQuarterThreeGrossSal',
    label: 'totalQuarterThreeGrossSal',
  },
  {
    name: 'monthTenGrossSal',
    label: 'monthTenGrossSal',
  },
  {
    name: 'monthElevenGrossSal',
    label: 'label.grossSalaryMxn',
  },
  {
    name: 'monthTwelveGrossSal',
    label: 'monthTwelveGrossSal',
  },
  {
    name: 'totalQuarterFourGrossSal',
    label: 'totalQuarterFourGrossSal',
  },
  {
    name: 'monthOneActualCost',
    label: 'monthOneActualCost',
  },
  {
    name: 'monthTwoActualCost',
    label: 'label.actualCostMxn',
  },
  {
    name: 'monthThreeActualacost',
    label: 'monthThreeActualacost',
  },
  {
    name: 'totalQuarterOneActualCost',
    label: 'totalQuarterOneActualCost',
  },
  {
    name: 'monthFourActualCost',
    label: 'monthFourActualCost',
  },
  {
    name: 'monthFiveActualCost',
    label: 'label.actualCostMxn',
  },
  {
    name: 'monthSixActualacost',
    label: 'monthSixActualacost',
  },
  {
    name: 'totalQuarterTwoActualCost',
    label: 'totalQuarterTwoActualCost',
  },
  {
    name: 'monthSevenActualCost',
    label: 'monthSevenActualCost',
  },
  {
    name: 'monthEightActualCost',
    label: 'label.actualCostMxn',
  },
  {
    name: 'monthNineActualacost',
    label: 'monthNineActualacost',
  },
  {
    name: 'totalQuarterThreeActualCost',
    label: 'totalQuarterThreeActualCost',
  },
  {
    name: 'monthTenActualCost',
    label: 'monthTenActualCost',
  },
  {
    name: 'monthElevenActualCost',
    label: 'label.actualCostMxn',
  },
  {
    name: 'monthTwelveActualacost',
    label: 'monthTwelveActualacost',
  },
  {
    name: 'totalQuarterFourActualCost',
    label: 'totalQuarterFourActualCost',
  },
  {
    name: 'monthOneRevenueUsd',
    label: 'monthOneRevenueUsd',
  },
  {
    name: 'monthTwoRevenueUsd',
    label: 'label.revenueUSD',
  },
  {
    name: 'monthThreeRevenueUsd',
    label: 'monthThreeRevenueUsd',
  },
  {
    name: 'totalQuarterOneRevenueUsd',
    label: 'totalQuarterOneRevenueUsd',
  },
  {
    name: 'monthFourRevenueUsd',
    label: 'monthFourRevenueUsd',
  },
  {
    name: 'monthFiveRevenueUsd',
    label: 'label.revenueUSD',
  },
  {
    name: 'monthSixRevenueUsd',
    label: 'monthSixRevenueUsd',
  },
  {
    name: 'totalQuarterTwoRevenueUsd',
    label: 'totalQuarterTwoRevenueUsd',
  },
  {
    name: 'monthSevenRevenueUsd',
    label: 'monthSevenRevenueUsd',
  },
  {
    name: 'monthEightRevenueUsd',
    label: 'label.revenueUSD',
  },
  {
    name: 'monthNineRevenueUsd',
    label: 'monthNineRevenueUsd',
  },
  {
    name: 'totalQuarterThreeRevenueUsd',
    label: 'totalQuarterThreeRevenueUsd',
  },
  {
    name: 'monthTenRevenueUsd',
    label: 'monthTenRevenueUsd',
  },
  {
    name: 'monthElevenRevenueUsd',
    label: 'label.revenueUSD',
  },
  {
    name: 'monthTwelveRevenueUsd',
    label: 'monthTwelveRevenueUsd',
  },
  {
    name: 'totalQuarterFourRevenueUsd',
    label: 'totalQuarterFourRevenueUsd',
  },
  {
    name: 'monthOneRevenueMxn',
    label: 'monthOneRevenueMxn',
  },
  {
    name: 'monthTwoRevenueMxn',
    label: 'label.revenueMxn',
  },
  {
    name: 'monthThreeRevenueMxn',
    label: 'monthThreeRevenueMxn',
  },
  {
    name: 'totalQuarterOneRevenueMxn',
    label: 'totalQuarterOneRevenueMxn',
  },
  {
    name: 'monthFourRevenueMxn',
    label: 'monthFourRevenueMxn',
  },
  {
    name: 'monthFiveRevenueMxn',
    label: 'label.revenueMxn',
  },
  {
    name: 'monthSixRevenueMxn',
    label: 'monthSixRevenueMxn',
  },
  {
    name: 'totalQuarterTwoRevenueMxn',
    label: 'totalQuarterTwoRevenueMxn',
  },
  {
    name: 'monthSevenRevenueMxn',
    label: 'monthSevenRevenueMxn',
  },
  {
    name: 'monthEightRevenueMxn',
    label: 'label.revenueMxn',
  },
  {
    name: 'monthNineRevenueMxn',
    label: 'monthNineRevenueMxn',
  },
  {
    name: 'totalQuarterThreeRevenueMxn',
    label: 'totalQuarterThreeRevenueMxn',
  },
  {
    name: 'monthTenRevenueMxn',
    label: 'monthTenRevenueMxn',
  },
  {
    name: 'monthElevenRevenueMxn',
    label: 'label.revenueMxn',
  },
  {
    name: 'monthTwelveRevenueMxn',
    label: 'monthTwelveRevenueMxn',
  },
  {
    name: 'totalQuarterFourRevenueMxn',
    label: 'totalQuarterFourRevenueMxn',
  },
  {
    name: 'monthOneActualIncomeMxn',
    label: 'monthOneActualIncomeMxn',
  },
  {
    name: 'monthTwoActualIncomeMxn',
    label: 'label.actIncomeMxn',
  },
  {
    name: 'monthThreeActualIncomeMxn',
    label: 'monthThreeActualIncomeMxn',
  },
  {
    name: 'totalQuarterOneActualIncome',
    label: 'totalQuarterOneActualIncome',
  },
  {
    name: 'monthFourActualIncomeMxn',
    label: 'monthFourActualIncomeMxn',
  },
  {
    name: 'monthFiveActualIncomeMxn',
    label: 'label.actIncomeMxn',
  },
  {
    name: 'monthSixActualIncomeMxn',
    label: 'monthSixActualIncomeMxn',
  },
  {
    name: 'totalQuarterTwoActualIncome',
    label: 'totalQuarterTwoActualIncome',
  },
  {
    name: 'monthSevenActualIncomeMxn',
    label: 'monthSevenActualIncomeMxn',
  },
  {
    name: 'monthEightActualIncomeMxn',
    label: 'label.actIncomeMxn',
  },
  {
    name: 'monthNineActualIncomeMxn',
    label: 'monthNineActualIncomeMxn',
  },
  {
    name: 'totalQuarterThreeActualIncome',
    label: 'totalQuarterThreeActualIncome',
  },
  {
    name: 'monthTenActualIncomeMxn',
    label: 'monthTenActualIncomeMxn',
  },
  {
    name: 'monthElevenActualIncomeMxn',
    label: 'label.actIncomeMxn',
  },
  {
    name: 'monthTwelveActualIncomeMxn',
    label: 'monthTwelveActualIncomeMxn',
  },
  {
    name: 'totalQuarterFourActualIncome',
    label: 'totalQuarterFourActualIncome',
  },
  {
    name: 'monthOneSalaryToBilling',
    label: 'monthOneSalaryToBilling',
  },
  {
    name: 'monthTwoSalaryToBilling',
    label: 'label.salaryToBilling',
  },
  {
    name: 'monthThreeSalaryToBilling',
    label: 'monthThreeSalaryToBilling',
  },
  {
    name: 'averageQuarterOneSalaryToBilling',
    label: 'averageQuarterOneSalaryToBilling',
  },
  {
    name: 'monthFourSalaryToBilling',
    label: 'monthFourSalaryToBilling',
  },
  {
    name: 'monthFiveSalaryToBilling',
    label: 'label.salaryToBilling',
  },
  {
    name: 'monthSixSalaryToBilling',
    label: 'monthSixSalaryToBilling',
  },
  {
    name: 'averageQuarterTwoSalaryToBilling',
    label: 'averageQuarterTwoSalaryToBilling',
  },
  {
    name: 'monthSevenSalaryToBilling',
    label: 'monthSevenSalaryToBilling',
  },
  {
    name: 'monthEightSalaryToBilling',
    label: 'label.salaryToBilling',
  },
  {
    name: 'monthNineSalaryToBilling',
    label: 'monthNineSalaryToBilling',
  },
  {
    name: 'averageQuarterThreeSalaryToBilling',
    label: 'averageQuarterThreeSalaryToBilling',
  },
  {
    name: 'monthTenSalaryToBilling',
    label: 'monthTenSalaryToBilling',
  },
  {
    name: 'monthElevenSalaryToBilling',
    label: 'label.salaryToBilling',
  },
  {
    name: 'monthTwelveSalaryToBilling',
    label: 'monthTwelveSalaryToBilling',
  },
  {
    name: 'averageQuarterFourSalaryToBilling',
    label: 'averageQuarterFourSalaryToBilling',
  },
];

export const tabelColumnExportCommitmentData = [
  {
    name: 'organization',
    label: 'label.organization',
    sortable: true,
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'startDate',
    label: 'label.commitmentStart',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.commitmentEnd',
    sortable: true,
  },
  {
    name: 'commitmentAmount',
    label: 'label.commitmentAmount',
  },
  {
    name: 'accumulatorId',
    label: 'label.commitmentTrackerId',
  },

  {
    name: 'accumulatorBalance',
    label: 'label.commitmentTrackerBalance',
  },
];

export const tabelColumnExportAgeingPayment = [
  { name: 'accountId', label: 'label.accountId' },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'accountCategory',
    label: 'label.type',
  },
  {
    name: 'collectionAgent',
    label: 'label.collectionAgent',
  },
  {
    name: 'salesConsultant',
    label: 'label.salesExecutive',
  },
  {
    name: 'salesFunction',
    label: 'label.salesFunction',
  },
  {
    name: 'paymentDate',
    label: 'label.paymentDate',
  },
  {
    name: 'paymentId',
    label: 'label.paymentId',
  },
  {
    name: 'paymentSuspenseId',
    label: 'label.paymentSuspenseId',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'totalRemainingAmount',
    label: 'label.remainingAmount',
    sortable: true,
  },
  {
    name: 'status',
    label: 'label.status',
    fieldOption: 'statusPayment',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'folioStatus',
    label: 'label.folioStatus',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'currency',
    label: 'label.currency',
  },
  {
    name: 'total0To15',
    label: 'label.015days',
  },
  {
    name: 'total15To30',
    label: 'label.1530days',
  },
  {
    name: 'total30To60',
    label: 'label.3060days',
  },
  {
    name: 'total60To90',
    label: 'label.6090days',
  },
  {
    name: 'total90To120',
    label: 'label.90120days',
  },
  {
    name: 'total120To150',
    label: 'label.120150days',
  },
  {
    name: 'total150To180',
    label: 'label.150180days',
  },
  {
    name: 'total180To270',
    label: 'label.180270days',
  },
  {
    name: 'total270To290',
    label: 'label.270290days',
  },
  {
    name: 'total290To365',
    label: 'label.290365days',
  },
  {
    name: 'total365AndMore',
    label: 'label.365OverDays',
  },
  {
    name: 'groupCompany',
    label: 'label.groupCompany',
  },
  {
    name: 'numberOfDaysSincePaymentDate',
    label: 'label.numberOfDaysSincePaymentDate',
  },
];

export const tabelColumnExportCommissionsCancellation = [
  {
    name: 'cancelTypeId',
    label: 'label.cancelTypeId',
  },
  {
    name: 'cancelType',
    label: 'label.cancelType',
  },
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
    sortable: true,
  },
  {
    name: 'personalEV',
    label: 'label.personalEV',
  },
  {
    name: 'salesAgentId',
    label: 'label.salesAgentId',
  },
  {
    name: 'salesAgentName',
    label: 'label.salesAgentName',
    sortable: true,
  },
  {
    name: 'supportTicket',
    label: 'label.supportTicket',
  },
  {
    name: 'cancellationDate',
    label: 'label.cancelDate',
    sortable: true,
  },
  {
    name: 'serviceId',
    label: 'label.serviceId',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'instanceId',
    label: 'label.serviceInstanceId',
  },
  {
    name: 'cancellationReasonId',
    label: 'label.cancelReasonId',
  },
  {
    name: 'cancellationReason',
    label: 'label.cancelReason',
    sortable: true,
  },
  {
    name: 'proratedAmount',
    label: 'label.proratedAmount',
    sortable: true,
  },
];

export const tabelColumnExportFolioDataExtract = [
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
  },
  {
    name: 'iCareClassif',
    label: 'label.careClassification',
  },
  {
    name: 'sGiro',
    label: 'label.giro',
  },
  {
    name: 'salesConsultant',
    label: 'label.salesConsultant',
  },
  {
    name: 'sTipoMercado',
    label: 'label.tipoMercado',
  },
  {
    name: 'uom',
    label: 'label.uom',
  },
  {
    name: 'serviceName',
    label: 'label.serviceName',
  },
  {
    name: 'omsInternalId',
    label: 'label.serviceInstance',
  },
  {
    name: 'subFamily2Id',
    label: 'label.subFamily2Id',
  },
  {
    name: 'subFamily2Name',
    label: 'label.subFamily2Name',
  },
  {
    name: 'subFamily1Id',
    label: 'label.subFamily1Id',
  },
  {
    name: 'subFamily1Name',
    label: 'label.subFamily1Name',
  },
  {
    name: 'familyId',
    label: 'label.familyId',
  },
  {
    name: 'familyName',
    label: 'label.familyName',
  },
  {
    name: 'companyName',
    label: 'label.companyName',
  },
  {
    name: 'city',
    label: 'label.city',
  },
  {
    name: 'folioId',
    label: 'label.folioId',
  },
  {
    name: 'organization',
    label: 'label.organization',
  },
  {
    name: 'segmentId',
    label: 'label.segmentId',
  },
  {
    name: 'productFamily',
    label: 'label.productFamily',
  },
  {
    name: 'productType',
    label: 'label.productType',
  },
  {
    name: 'productLine',
    label: 'label.productLine',
  },
  {
    name: 'folioDate',
    label: 'label.folioDate',
    sortable: true,
  },
  {
    name: 'invoiceTotal',
    label: 'label.invoiceTotal',
  },
  {
    name: 'invoiceTotalInDestCur',
    label: 'label.invoiceTotalInUSD',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'unitPrice',
    label: 'label.unitPrice',
  },
  {
    name: 'crmClientId',
    label: 'label.crmClientId',
  },
  {
    name: 'locationId',
    label: 'label.locationId',
  },
  {
    name: 'nIdPersonalEvCierre',
    label: 'label.personalEV',
  },
  {
    name: 'salesAgent',
    label: 'label.salesAgent',
  },
  {
    name: 'sFunctionEvCierre',
    label: 'label.functionEV',
  },
  {
    name: 'salesAgentId',
    label: 'label.salesAgentId',
  },
  {
    name: 'stampingDate',
    label: 'label.stampingDate',
  },
  {
    name: 'exchangeRate',
    label: 'label.exchangeRate',
  },
  {
    name: 'billableServiceId',
    label: 'label.billableServiceId',
  },
  {
    name: 'glAccount',
    label: 'label.glAccount',
  },
];

export const tabelColumnExportBroadsoftZeroDurationCdrs = [
  {
    name: 'serviceProvider',
    label: 'label.sServiceProvider',
  },
  {
    name: 'userNumber',
    label: 'label.sUserNumber',
  },
  {
    name: 'groupNumber',
    label: 'label.sGroupNumber',
  },
  {
    name: 'direction',
    label: 'label.sDirection',
  },
  {
    name: 'callingNumber',
    label: 'label.sCallingNumber',
  },
  {
    name: 'calledNumber',
    label: 'label.sCalledNumber',
  },
  {
    name: 'startTime',
    label: 'label.sStartTime',
  },
  {
    name: 'answerIndicator',
    label: 'label.sAnswerIndicator',
  },
  {
    name: 'answerTime',
    label: 'label.sAnswerTime',
    sortable: true,
  },
  {
    name: 'releaseTime',
    label: 'label.sReleaseTime',
    sortable: true,
  },
  {
    name: 'terminationCause',
    label: 'label.sTerminationCause',
  },
  {
    name: 'dailedDigits',
    label: 'label.sDailedDigits',
  },
  {
    name: 'callCategory',
    label: 'label.sCallCategory',
  },
  {
    name: 'networkCallType',
    label: 'label.sNetworkCallType',
  },
  {
    name: 'networkTranslatedNumber',
    label: 'label.sNetworkTranslatedNumber',
  },
  {
    name: 'releasingParty',
    label: 'label.sReleasingParty',
  },
  {
    name: 'route',
    label: 'label.sRoute',
  },
  {
    name: 'networkCallId',
    label: 'label.sNetworkCallId',
  },
  {
    name: 'codec',
    label: 'label.sCodec',
  },
  {
    name: 'accessDeviceAddress',
    label: 'label.sAccessDeviceAddress',
  },
  {
    name: 'group',
    label: 'label.sGroup',
  },
  {
    name: 'department',
    label: 'label.sDepartment',
  },
  {
    name: 'authorizationCode',
    label: 'label.sAuthorizationCode',
  },
  {
    name: 'originalCalledNumber',
    label: 'label.sOriginalCalledNumber',
  },
  {
    name: 'originalCalledPresentationIndicator',
    label: 'label.sOriginalCalledPresentationIndicator',
  },
  {
    name: 'originalCalledReason',
    label: 'label.sOriginalCalledReason',
  },
  {
    name: 'redirectingNumber',
    label: 'label.sRedirectingNumber',
  },
  {
    name: 'redirectingPresentationIndicator',
    label: 'label.sRedirectingPresentationIndicator',
  },
  {
    name: 'redirectingReason',
    label: 'label.sRedirectingReason',
  },
  {
    name: 'chargeIndicator',
    label: 'label.sChargeIndicator',
  },
  {
    name: 'voicePortalInvocationTime',
    label: 'label.sVoicePortalInvocationTime',
  },
  {
    name: 'extAnswerDateTime',
    label: 'label.dExtAnswerDateTime',
  },
  {
    name: 'extDurationMinutes',
    label: 'label.nExtDurationMinutes',
    sortable: true,
  },
  {
    name: 'extDurationSeconds',
    label: 'label.nExtDurationSeconds',
    sortable: true,
  },
  {
    name: 'sreserved',
    label: 'label.sReserved',
  },
  {
    name: 'suserid',
    label: 'label.sUserid',
  },
  {
    name: 'sotherPartyName',
    label: 'label.sOtherPartyName',
  },
];

export const tabelColumnExportNonBroadsoftZeroDurationCDR = [
  {
    name: 'answerTime',
    label: 'label.answerTime',
    sortable: true,
  },
  {
    name: 'extIddAbc',
    label: 'label.extIddAbc',
  },
  {
    name: 'extIdoBcd',
    label: 'label.extIdoBcd',
  },
  {
    name: 'callingNumber',
    label: 'label.callingNumber',
  },
  {
    name: 'calledNumber',
    label: 'label.calledNumber',
  },
  {
    name: 'incomingRoute',
    label: 'label.incomingRoute',
  },
  {
    name: 'outgoingRoute',
    label: 'label.outgoingRoute',
  },
  {
    name: 'sreserved',
    label: 'label.sreserved',
  },
  {
    name: 'callStatus',
    label: 'label.callStatus',
  },
  {
    name: 'terminatingAccess',
    label: 'label.terminatingAccess',
  },
  {
    name: 'failureCode',
    label: 'label.failureCode',
  },
  {
    name: 'disconnectReason',
    label: 'label.disconnectReason',
  },
  {
    name: 'commercial',
    label: 'label.commercial',
  },
  {
    name: 'startTime',
    label: 'label.startTime',
  },
  {
    name: 'endTime',
    label: 'label.endTime',
  },
  {
    name: 'direction',
    label: 'label.direction',
  },
  {
    name: 'callDuration',
    label: 'label.callDuration',
  },
  {
    name: 'extDurationMinutes',
    label: 'label.extDurationMinutes',
    sortable: true,
  },
  {
    name: 'extDurationSeconds',
    label: 'label.extDurationSeconds',
    sortable: true,
  },
];

export const tabelColumnExportSuspendedFailedRecords = [
  {
    name: 'sourceSystem',
    label: 'label.sourceSystem',
  },
  {
    name: 'usageId',
    label: 'label.usageId',
  },
  {
    name: 'errorCode',
    label: 'label.errorCode',
    fieldOption: 'errorCodeProcessService',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:errorCodeProcessService')().find(val => val.value === item.errorCode) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'errorMsg',
    label: 'label.errorMessage',
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'provisioningId',
    label: 'label.provisioningId',
    sortable: true,
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'ratingProvisioningId',
    label: 'label.ratingProvisioningId',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    sortable: true,
  },
  {
    name: 'usageType',
    label: 'label.usageType',
    sortable: true,
  },
  {
    name: 'usageClass',
    label: 'label.usageClass',
  },
  {
    name: 'apartyNumber',
    label: 'label.aPartyNumber',
  },
  {
    name: 'bpartyNumber',
    label: 'label.bPartyNumber',
  },
  {
    name: 'cpartyNumber',
    label: 'label.cPartyNumber',
  },
  {
    name: 'direction',
    label: 'label.direction',
    fieldOption: 'usageDirection',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:usageDirection')().find(val => val.value === item.direction) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'npaId',
    label: 'label.npaId',
  },
  {
    name: 'quantity',
    label: 'label.quantity',
  },
  {
    name: 'downloadVolume',
    label: 'label.download',
  },
  {
    name: 'uploadVolume',
    label: 'label.upload',
  },
  {
    name: 'recordCount',
    label: 'label.recordCount',
  },
];

export const tabelColumnExportExtractBI = [
  {
    name: 'clientId',
    label: 'label.clientId',
    sortable: true,
  },
  {
    name: 'commercialName',
    label: 'label.commercialName',
    sortable: true,
  },
  {
    name: 'serviceName',
    label: 'label.serviceName',
  },
  {
    name: 'serviceStatus',
    label: 'label.serviceStatus',
  },
  {
    name: 'dischargeDate',
    label: 'label.dischargeDate',
  },
  {
    name: 'releaseDate',
    label: 'label.releaseDate',
  },
  {
    name: 'cancelledDate',
    label: 'label.cancelledDate',
  },
  {
    name: 'nextCutoffDate',
    label: 'label.nextCutoffDate',
  },
  {
    name: 'omsInternalId',
    label: 'label.omsInternalId',
  },
  {
    name: 'orderId',
    label: 'label.orderId',
  },
  {
    name: 'reasonCode',
    label: 'label.reasonCode',
  },
  {
    name: 'completedDate',
    label: 'label.completedDate',
    sortable: true,
  },
  {
    name: 'incrementedAmount',
    label: 'label.incrementedAmount',
    sortable: true,
  },
  {
    name: 'finalAmount',
    label: 'label.finalAmount',
    sortable: true,
  },
  {
    name: 'oldAmount',
    label: 'label.oldAmount',
  },
  {
    name: 'serviceId',
    label: 'label.serviceId',
  },
];

export const tabelColumnExportR00105 = [
  {
    name: 'c601',
    label: 'label.C601',
  },
  {
    name: 'c602',
    label: 'label.C602',
  },
  {
    name: 'c003',
    label: 'label.C003',
  },
  {
    name: 'c006',
    label: 'label.C006',
  },
  {
    name: 'c101',
    label: 'label.C101',
  },
  {
    name: 'c102',
    label: 'label.C102',
  },
  {
    name: 'c103',
    label: 'label.C103',
  },
  {
    name: 'r0010501',
    label: 'label.R0010501',
  },
];

export const tabelColumnExportR00106 = [
  {
    name: 'c601',
    label: 'label.C601',
  },
  {
    name: 'c602',
    label: 'label.C602',
  },
  {
    name: 'r0010601',
    label: 'label.R0010601',
  },
  {
    name: 'r0010602',
    label: 'label.R0010602',
  },
  {
    name: 'r0010603',
    label: 'label.R0010603',
  },
  {
    name: 'r0010604',
    label: 'label.R0010604',
  },
  {
    name: 'r0010605',
    label: 'label.R0010605',
  },
  {
    name: 'r0010606',
    label: 'label.R0010606',
  },
  {
    name: 'r0010607',
    label: 'label.R0010607',
  },
  {
    name: 'r0010608',
    label: 'label.R0010608',
  },
  {
    name: 'r0010609',
    label: 'label.R0010609',
  },
  {
    name: 'r0010610',
    label: 'label.R0010610',
  },
  {
    name: 'r0010611',
    label: 'label.R0010611',
  },
  {
    name: 'r0010612',
    label: 'label.R0010612',
  },
  {
    name: 'r0010613',
    label: 'label.R0010613',
  },
];

export const tabelColumnExportR00501 = [
  {
    name: 'C601',
    label: 'label.C601',
  },
  {
    name: 'C602',
    label: 'label.C602',
  },
  {
    name: 'C102',
    label: 'label.C102',
  },
  {
    name: 'C103',
    label: 'label.C103',
  },
  {
    name: 'R0050101',
    label: 'label.R0050101',
  },
];

export const tabelColumnExportR00502 = [
  {
    name: 'C601',
    label: 'label.C601',
  },
  {
    name: 'C602',
    label: 'label.C602',
  },
  {
    name: 'C002',
    label: 'label.C002',
  },
  {
    name: 'C102',
    label: 'label.C102',
  },
  {
    name: 'C205',
    label: 'label.C205',
  },
  {
    name: 'C309',
    label: 'label.C309',
  },
  {
    name: 'R0050201',
    label: 'label.R0050201',
  },
];

export const tabelColumnExportR00503 = [
  {
    name: 'C601',
    label: 'label.C601',
  },
  {
    name: 'C602',
    label: 'label.C602',
  },
  {
    name: 'C002',
    label: 'label.C002',
  },
  // {
  //   name: 'C102',
  //   label: 'label.C102',
  // },
  {
    name: 'R0050301',
    label: 'label.R0050301',
  },
  {
    name: 'R0050301',
    label: 'label.R0050302',
  },
  {
    name: 'R0050303',
    label: 'label.R0050303',
  },
  {
    name: 'R0050304',
    label: 'label.R0050304',
  },
];

export const tabelColumnExportR00504 = [
  {
    name: 'C601',
    label: 'label.C601',
  },
  {
    name: 'C602',
    label: 'label.C602',
  },
  {
    name: 'C205',
    label: 'label.C205',
  },
  {
    name: 'C309',
    label: 'label.C309',
  },
  {
    name: 'R0050401',
    label: 'label.R0050401',
  },
];

export const tabelColumnExportR00505 = [
  {
    name: 'C601',
    label: 'label.C601',
  },
  {
    name: 'C602',
    label: 'label.C602',
  },
  {
    name: 'R0050501',
    label: 'label.R0050501',
  },
  {
    name: 'R0050502',
    label: 'label.R0050502',
  },
  {
    name: 'R0050503',
    label: 'label.R0050503',
  },
];

export const tabelColumnExportR00506 = [
  {
    name: 'C601',
    label: 'label.C601',
  },
  {
    name: 'C602',
    label: 'label.C602',
  },
  {
    name: 'C104',
    label: 'label.C104',
  },
  {
    name: 'R0050601',
    label: 'label.R0050601',
  },
  {
    name: 'R0050602',
    label: 'label.R0050602',
  },
];

export const tabelColumnExportR00401 = [
  {
    name: 'c601',
    label: 'label.C601',
  },
  {
    name: 'c602',
    label: 'label.C602',
  },
  {
    name: 'c101',
    label: 'label.C101',
  },
  {
    name: 'c102',
    label: 'label.C102',
  },
  {
    name: 'c103',
    label: 'label.C103',
  },
  {
    name: 'r0040101',
    label: 'label.R0040101',
  },
];

export const tabelColumnExportR00405 = [
  {
    name: 'c601',
    label: 'label.C601',
  },
  {
    name: 'c602',
    label: 'label.C602',
  },
  {
    name: 'c103',
    label: 'label.C103',
  },
  {
    name: 'r0040501',
    label: 'label.R0040501',
  },
  {
    name: 'r0040502',
    label: 'label.R0040502',
  },
  {
    name: 'r0040503',
    label: 'label.R0040503',
  },
];

export const tabelColumnExportCreditBureau = [
  {
    name: 'description',
    label: 'label.description',
  },
];

export const tabelColumnExportTaxExemption = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'label.lastName',
    sortable: true,
  },
  {
    name: 'startDate',
    label: 'label.startDate',
    sortable: true,
  },
  {
    name: 'endDate',
    label: 'label.endDate',
    sortable: true,
  },
  {
    name: 'taxType',
    label: 'label.taxType',
    sortable: true,
  },
  {
    name: 'productCode',
    label: 'label.productCode',
  },
  {
    name: 'certificateNumber',
    label: 'label.certificateId',
  },
  {
    name: 'lowerLimit',
    label: 'label.lowerLimit',
  },
  {
    name: 'upperLimit',
    label: 'label.upperLimit',
  },
  {
    name: 'fileName',
    label: 'label.fileName',
  },
  {
    name: 'exemptionPercentage',
    label: 'label.percentage',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    fieldOption: 'serviceType',
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
    sortable: true,
  },
];

export const tabelColumnExportFonatelReport = [
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'priceOfferName',
    label: 'label.offerName',
  },
  {
    name: 'priceUnitStart',
    label: 'label.offerStart',
  },
  {
    name: 'priceUnitEnd',
    label: 'label.offerEnd',
  },
  {
    name: 'itemName',
    label: 'label.item',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'invoiceStartDate',
    label: 'label.invoiceStart',
    sortable: true,
  },
  {
    name: 'invoiceEndDate',
    label: 'label.invoiceEnd',
    sortable: true,
  },
  {
    name: 'invoiceIssued',
    label: 'label.invoiceIssued',
  },
  {
    name: 'invoicePending',
    label: 'label.invoicePending',
  },
];

export const tabelColumnExportPHCReport = [
  {
    name: 'accountId',
    label: 'label.accountId',
  },
  {
    name: 'firstName',
    label: 'label.firstName',
  },
  {
    name: 'lastName',
    label: 'label.lastName',
  },
  {
    name: 'invoiceId',
    label: 'label.invoiceId',
  },
  {
    name: 'amount',
    label: 'label.amount',
  },
  {
    name: 'invoicePaid',
    label: 'label.invoicePaid',
  },
  {
    name: 'invoicePeriodStart',
    label: 'label.invoiceStart',
    sortable: true,
  },
  {
    name: 'invoicePeriodEnd',
    label: 'label.invoiceEnd',
    sortable: true,
  },
  {
    name: 'serviceEffectiveDate',
    label: 'label.serviceEffectiveDate',
    sortable: true,
  },
  {
    name: 'priceOfferName',
    label: 'label.offerName',
  },
  {
    name: 'priceUnitStartDate',
    label: 'label.offerStart',
    sortable: true,
  },
  {
    name: 'priceUnitEndDate',
    label: 'label.offerEnd',
    sortable: true,
  },
  {
    name: 'priceUnitStatus',
    label: 'label.priceUnitStatus',
  },
  {
    name: 'isIPTVService',
    label: 'label.isIPTVService',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
  },
  {
    name: 'invoicePending',
    label: 'label.invoicePending',
  },
  {
    name: 'invoiceIssued',
    label: 'label.invoiceIssued',
  },
  {
    name: 'lastArAction',
    label: 'label.lastArAction',
  },
];

export const tabelColumnExportServiceReconReport = [
  {
    name: 'accountId',
    label: 'label.accountId',
    sortable: true,
  },
  {
    name: 'contractDate',
    label: 'label.contractDate',
    sortable: true,
  },
  {
    name: 'contractApprovedDate',
    label: 'label.contractApprovedDate',
  },
  {
    name: 'provisioningApprovedDate',
    label: 'label.provisioningApprovedDate',
  },
  {
    name: 'packageName',
    label: 'label.packageName',
  },
  {
    name: 'packageId',
    label: 'label.packageId',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
    isRenderT: true,
    fieldOption: 'serviceType',
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'bundleName',
    label: 'label.bundleName',
  },
  {
    name: 'bundleId',
    label: 'label.bundleId',
  },
  {
    name: 'priceOfferName',
    label: 'label.offerName',
  },
  {
    name: 'priceOfferId',
    label: 'label.offerId',
  },
];

export const sortNewCustomer = {
  company: {
    asc: 'company_ASC',
    desc: 'company_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
};

export const sortAccountClosure = {
  company: {
    asc: 'company_ASC',
    desc: 'company_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  reason: {
    asc: 'reason_ASC',
    desc: 'reason_DESC',
  },
};

export const sortInvoicing = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortPayment = {
  paymentDate: {
    asc: 'paymentDate_ASC',
    desc: 'paymentDate_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  paymentId: {
    asc: 'paymentId_ASC',
    desc: 'paymentId_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  amount: {
    asc: 'amount_ASC',
    desc: 'amount_DESC',
  },
  fileDate: {
    asc: 'fileDate_ASC',
    desc: 'fileDate_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

export const sortFailedPayment = {
  paymentDate: {
    asc: 'paymentDate_ASC',
    desc: 'paymentDate_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  failureReason: {
    asc: 'failureReason_ASC',
    desc: 'failureReason_DESC',
  },
  amount: {
    asc: 'amount_ASC',
    desc: 'amount_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
};

export const sortBilling = {
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  billType: {
    asc: 'billType_ASC',
    desc: 'billType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  due: {
    asc: 'due_ASC',
    desc: 'due_DESC',
  },
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
};

export const sortAgeingSummary = {
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
  totalDue: {
    asc: 'totalDue_ASC',
    desc: 'totalDue_DESC',
  },
};

export const sortOrder = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  orderType: {
    asc: 'orderType_ASC',
    desc: 'orderType_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
};

export const sortBillProductSummary = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  productCompany: {
    asc: 'productCompany_ASC',
    desc: 'productCompany_DESC',
  },
  itemId: {
    asc: 'itemId_ASC',
    desc: 'itemId_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  billType: {
    asc: 'billType_ASC',
    desc: 'billType_DESC',
  },
  billingDom: {
    asc: 'billDom_ASC',
    desc: 'billDom_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
};

export const sortBillTransactionSummary = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  billUnitId: {
    asc: 'billUnitId_ASC',
    desc: 'billUnitId_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

export const sortJobSchedule = {
  jobScheduleDate: {
    asc: 'jobScheduleDate_ASC',
    desc: 'jobScheduleDate_DESC',
  },
  jobScheduleStatus: {
    asc: 'jobSchedulestatus_ASC',
    desc: 'jobSchedulestatus_DESC',
  },
};

export const sortJobFailed = {
  jobType: {
    asc: 'jobType_ASC',
    desc: 'jobType_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  failureReason: {
    asc: 'failureReason_ASC',
    desc: 'failureReason_DESC',
  },
  jobDate: {
    asc: 'jobDate_ASC',
    desc: 'jobDate_DESC',
  },
};

export const sortTrialSubscription = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  trialLevel: {
    asc: 'trialLevel_ASC',
    desc: 'trialLevel_DESC',
  },
  trialType: {
    asc: 'trialType_ASC',
    desc: 'trialType_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
};

export const sortSubscriptionExpiry = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortAllCollection = {
  dueDate: {
    asc: 'dueDate_ASC',
    desc: 'dueDate_DESC',
  },
  lastActionDate: {
    asc: 'lastActionDate_ASC',
    desc: 'lastActionDate_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  due: {
    asc: 'due_ASC',
    desc: 'due_DESC',
  },
  daysInCollection: {
    asc: 'daysInCollection_ASC',
    desc: 'daysInCollection_DESC',
  },
};

export const sortPaymentSuspense = {
  paymentDate: {
    asc: 'paymentDate_ASC',
    desc: 'paymentDate_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  failureReason: {
    asc: 'failureReason_ASC',
    desc: 'failureReason_DESC',
  },
  amount: {
    asc: 'amount_ASC',
    desc: 'amount_DESC',
  },
  fileDate: {
    asc: 'fileDate_ASC',
    desc: 'fileDate_DESC',
  },
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

export const sortAROpsDetails = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  processDate: {
    asc: 'processDate_ASC',
    desc: 'processDate_DESC',
  },
  arOpsType: {
    asc: 'arOpsType_ASC',
    desc: 'arOpsType_DESC',
  },
  arCrDrType: {
    asc: 'arCrDrType_ASC',
    desc: 'arCrDrType_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  reason: {
    asc: 'reason_ASC',
    desc: 'reason_DESC',
  },
};

export const sortOpenDispute = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  processDate: {
    asc: 'processDate_ASC',
    desc: 'processDate_DESC',
  },
  arCrDrType: {
    asc: 'arCrDrType_ASC',
    desc: 'arCrDrType_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
};

export const sortOpenWriteOff = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  processDate: {
    asc: 'processDate_ASC',
    desc: 'processDate_DESC',
  },
  arCrDrType: {
    asc: 'arCrDrType_ASC',
    desc: 'arCrDrType_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  paymentMethod: {
    asc: 'paymentMethod_ASC',
    desc: 'paymentMethod_DESC',
  },
  reason: {
    asc: 'reason_ASC',
    desc: 'reason_DESC',
  },
};

export const sortUnbilledTransactions = {
  transactionDate: {
    asc: 'transactionDate_ASC',
    desc: 'transactionDate_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  suspenseReason: {
    asc: 'suspenseReason_ASC',
    desc: 'suspenseReason_DESC',
  },
  failureReason: {
    asc: 'failureReason_ASC',
    desc: 'failureReason_DESC',
  },
};

export const sortBillingInvoicing = {
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  billType: {
    asc: 'billType_ASC',
    desc: 'billType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  due: {
    asc: 'due_ASC',
    desc: 'due_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortBillingAR = {
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  billType: {
    asc: 'billType_ASC',
    desc: 'billType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  due: {
    asc: 'due_ASC',
    desc: 'due_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortUsageSummary = {
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  fileName: {
    asc: 'fileName_ASC',
    desc: 'fileName_DESC',
  },
  fileDate: {
    asc: 'fileDate_ASC',
    desc: 'fileDate_DESC',
  },
  source: {
    asc: 'source_ASC',
    desc: 'source_DESC',
  },
  suspendedTxns: {
    asc: 'suspendedTxn_ASC',
    desc: 'suspendedTxn_DESC',
  },
  erroredTxns: {
    asc: 'erroredTxn_ASC',
    desc: 'erroredTxn_DESC',
  },
};

export const sortUsageDetail = {
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  fileName: {
    asc: 'fileName_ASC',
    desc: 'fileName_DESC',
  },
  fileDate: {
    asc: 'fileDate_ASC',
    desc: 'fileDate_DESC',
  },
  source: {
    asc: 'source_ASC',
    desc: 'source_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  errorCode: {
    asc: 'errorCode_ASC',
    desc: 'errorCode_DESC',
  },
  provisioningId: {
    asc: 'provisioningID_ASC',
    desc: 'provisioningID_DESC',
  },
};

export const sortUsageReconciliation = {
  fileDate: {
    asc: 'fileDate_ASC',
    desc: 'fileDate_DSC',
  },
  fileName: {
    asc: 'fileName_ASC',
    desc: 'fileName_DSC',
  },
  sourcePlatform: {
    asc: 'switchName_ASC',
    desc: 'switchName_DSC',
  },
  // source: {
  //   asc: 'source_ASC',
  //   desc: 'source_DESC',
  // },
  // errorCode: {
  //   asc: 'errorCode_ASC',
  //   desc: 'errorCode_DESC',
  // },
  // status: {
  //   asc: 'status_ASC',
  //   desc: 'status_DESC',
  // },
  // ratingStatusCount: {
  //   asc: 'ratingStatusCount_ASC',
  //   desc: 'ratingStatusCount_DESC',
  // },
  // ratingErrorCount: {
  //   asc: 'ratingErrorCount_ASC',
  //   desc: 'ratingErrorCount_DESC',
  // },
};

export const sortRevenueSummary = {
  productCompany: {
    asc: 'productCompany_ASC',
    desc: 'productCompany_DESC',
  },
  glAccount: {
    asc: 'glAccount_ASC',
    desc: 'glAccount_DESC',
  },
  productFamily: {
    asc: 'productFamily_ASC',
    desc: 'productFamily_DESC',
  },
  productLine: {
    asc: 'productLine_ASC',
    desc: 'productLine_DESC',
  },
  productType: {
    asc: 'productType_ASC',
    desc: 'productType_DESC',
  },
  productSubType: {
    asc: 'productSubType_ASC',
    desc: 'productSubType_DESC',
  },
  itemName: {
    asc: 'itemName_ASC',
    desc: 'itemName_DESC',
  },
};

export const sortRevenueRecognition = {
  productCompany: {
    asc: 'productCompany_ASC',
    desc: 'productCompany_DESC',
  },
  glAccount: {
    asc: 'glAccount_ASC',
    desc: 'glAccount_DESC',
  },
  productFamily: {
    asc: 'productFamily_ASC',
    desc: 'productFamily_DESC',
  },
  productLine: {
    asc: 'productLine_ASC',
    desc: 'productLine_DESC',
  },
  productType: {
    asc: 'productType_ASC',
    desc: 'productType_DESC',
  },
  productSubType: {
    asc: 'productSubType_ASC',
    desc: 'productSubType_DESC',
  },
  itemName: {
    asc: 'itemName_ASC',
    desc: 'itemName_DESC',
  },
  billEndDate: {
    asc: 'billEndDate_ASC',
    desc: 'billEndDate_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
};

export const sortActiveSubscription = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  trialTyp: {
    asc: 'trialType_ASC',
    desc: 'trialType_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortJobDetail = {
  jobScheduleDate: {
    asc: 'jobScheduleDate_ASC',
    desc: 'jobScheduleDate_DESC',
  },
  jobType: {
    asc: 'jobType_ASC',
    desc: 'jobType_DESC',
  },
  jobStatus: {
    asc: 'jobStatus_ASC',
    desc: 'jobStatus_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
};

export const sortTaxSummary = {
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  jurisdiction: {
    asc: 'jurisdiction_ASC',
    desc: 'jurisdiction_DESC',
  },
  billMonth: {
    asc: 'billMonth_ASC',
    desc: 'billMonth_DESC',
  },
  taxcode: {
    asc: 'taxcode_ASC',
    desc: 'taxcode_DESC',
  },
};

export const sortTaxDetail = {
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  jurisdiction: {
    asc: 'jurisdiction_ASC',
    desc: 'jurisdiction_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  taxcode: {
    asc: 'taxcode_ASC',
    desc: 'taxcode_DESC',
  },
  invoiceStatus: {
    asc: 'invoiceStatus_ASC',
    desc: 'invoiceStatus_DESC',
  },
  billEndDate: {
    asc: 'billEndDate_ASC',
    desc: 'billEndDate_DESC',
  },
};

export const sortSubscriptionContractExpiry = {
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  firstName: {
    asc: 'firstName_ASC',
    desc: 'firstName_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  salesChannel: {
    asc: 'salesChannel_ASC',
    desc: 'salesChannel_DESC',
  },
  marketSegment: {
    asc: 'marketSegment_ASC',
    desc: 'marketSegment_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountSubType: {
    asc: 'accountSubType_ASC',
    desc: 'accountSubType_DESC',
  },
};

export const sortTrialToPaidSubscription = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  trialType: {
    asc: 'trialType_ASC',
    desc: 'trialType_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  trialLevel: {
    asc: 'trialLevel_ASC',
    desc: 'trialLevel_DESC',
  },
};

export const sortRevenueForecast = {
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  firstName: {
    asc: 'firstName_ASC',
    desc: 'firstName_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  salesChannel: {
    asc: 'salesChannel_ASC',
    desc: 'salesChannel_DESC',
  },
  marketSegment: {
    asc: 'marketSegment_ASC',
    desc: 'marketSegment_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountSubType: {
    asc: 'accountSubType_ASC',
    desc: 'accountSubType_DESC',
  },
};

export const sortMRRForecast = {
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  firstName: {
    asc: 'firstName_ASC',
    desc: 'firstName_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
  sellingCompany: {
    asc: 'sellingCompany_ASC',
    desc: 'sellingCompany_DESC',
  },
  salesChannel: {
    asc: 'salesChannel_ASC',
    desc: 'salesChannel_DESC',
  },
  marketSegment: {
    asc: 'marketSegment_ASC',
    desc: 'marketSegment_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  accountSubType: {
    asc: 'accountSubType_ASC',
    desc: 'accountSubType_DESC',
  },
};

export const sortVoiceCustomerCallTypeSummary = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  noOfCalls: {
    asc: 'noOfCalls_ASC',
    desc: 'noOfCalls_DESC',
  },
  amountMxn: {
    asc: 'amountMxn_ASC',
    desc: 'amountMxn_DESC',
  },
};

export const sortVoiceOperatorCallTypeSummary = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  noOfCalls: {
    asc: 'noOfCalls_ASC',
    desc: 'noOfCalls_DESC',
  },
  amountMxn: {
    asc: 'amountMxn_ASC',
    desc: 'amountMxn_DESC',
  },
};

export const sortVoiceExcessTrafficReport = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  noOfMinutes: {
    asc: 'noOfMinutes_ASC',
    desc: 'noOfMinutes_DESC',
  },
};

export const sortVoiceTrafficByLdDistination = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  noOfMinutes: {
    asc: 'noOfMinutes_ASC',
    desc: 'noOfMinutes_DESC',
  },
};

export const sortOnDemand = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  ipAddress: {
    asc: 'iPAddress_ASC',
    desc: 'iPAddress_DESC',
  },
  quantity: {
    asc: 'quantity_ASC',
    desc: 'quantity_DESC',
  },
};

export const sortNewSmsReportData = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  noOfSms: {
    asc: 'noOfSms_ASC',
    desc: 'noOfSms_DESC',
  },
  amountMXN: {
    asc: 'amountMXN_ASC',
    desc: 'amountMxn_DESC',
  },
};

export const sortVoiceITXRawReport = {
  lineDayOfMonth: {
    asc: 'lineDayOfMonth_ASC',
    desc: 'lineDayOfMonth_DESC',
  },
  noOfMinutes: {
    asc: 'noOfMinutes_ASC',
    desc: 'noOfMinutes_DESC',
  },
  noOfSeconds: {
    asc: 'noOfSeconds_ASC',
    desc: 'noOfSeconds_DESC',
  },
  noOfCalls: {
    asc: 'noOfCalls_ASC',
    desc: 'noOfCalls_DESC',
  },
};

export const sortITXSMS = {
  lineDayOfMonth: {
    asc: 'lineDayOfMonth_ASC',
    desc: 'lineDayOfMonth_DESC',
  },
  noOfSms: {
    asc: 'noOfSms_ASC',
    desc: 'noOfSms_DESC',
  },
  noOfSeconds: {
    asc: 'noOfSeconds_ASC',
    desc: 'noOfSeconds_DESC',
  },
  originalNetworkId: {
    asc: 'originalNetworkId_ASC',
    desc: 'originalNetworkId_DESC',
  },
};

export const sortCreditNotes = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  total: {
    asc: 'total_ASC',
    desc: 'total_DESC',
  },
  notesDate: {
    asc: 'notesDate_ASC',
    desc: 'notesDate_DESC',
  },
  company: {
    asc: 'company_ASC',
    desc: 'company_DESC',
  },
};

export const sortVoiceICallTypeSummary = {
  callType: {
    asc: 'callType_ASC',
    desc: 'callType_DESC',
  },
  amountMXN: {
    asc: 'amountMXN_ASC',
    desc: 'amountMXN_DESC',
  },
  costMXN: {
    asc: 'costMXN_ASC',
    desc: 'costMXN_DESC',
  },
};

export const sortInternetOperatorDataCost = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  operatorName: {
    asc: 'operatorName_ASC',
    desc: 'operatorName_DESC',
  },
  totalCost: {
    asc: 'totalCost_ASC',
    desc: 'totalCost_DESC',
  },
};

export const sortTecoSummary = {
  lineDate: {
    asc: 'lineDate_ASC',
    desc: 'lineDate_DESC',
  },
  dataValue: {
    asc: 'dataValue_ASC',
    desc: 'dataValue_DESC',
  },
};

export const sortAccountReport = {
  company: {
    asc: 'company_ASC',
    desc: 'company_DESC',
  },
  accountType: {
    asc: 'accountType_ASC',
    desc: 'accountType_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  state: {
    asc: 'state_ASC',
    desc: 'state_DESC',
  },
};

export const sortRawFile = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  sourcePlatform: {
    asc: 'switchName_ASC',
    desc: 'switchName_DESC',
  },
  TotalInputRecords: {
    asc: 'totalInputRecords_ASC',
    desc: 'totalInputRecords_DESC',
  },
  TotalImportedRecords: {
    asc: 'totalImportedRecords_ASC',
    desc: 'totalImportedRecords_DESC',
  },
  TotalErrorRecords: {
    asc: 'totalErrorRecords_ASC',
    desc: 'totalErrorRecords_DESC',
  },
};

export const sortUsageTransactionDetail = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  sourceSystem: {
    asc: 'sourceSystem_ASC',
    desc: 'sourceSystem_DESC',
  },
  usageType: {
    asc: 'usageType_ASC',
    desc: 'usageType_DESC',
  },
  usageClass: {
    asc: 'usageClass_ASC',
    desc: 'usageClass_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
  ratingDescr: {
    asc: 'ratingDescr_ASC',
    desc: 'ratingDescr_DESC',
  },
  source: {
    asc: 'source_ASC',
    desc: 'source_DESC',
  },
  destination: {
    asc: 'destination_ASC',
    desc: 'destination_DESC',
  },
  routingNumber: {
    asc: 'routingNumber_ASC',
    desc: 'routingNumber_DESC',
  },
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  usageDirection: {
    asc: 'usageDirection_ASC',
    desc: 'usageDirection_DESC',
  },
  fileId: {
    asc: 'fileId_ASC',
    desc: 'fileId_DESC',
  },
};

export const sortItemServices = {
  commercialName: {
    asc: 'commercialName_ASC',
    desc: 'commercialName_DESC',
  },
  operativeStatus: {
    asc: 'operativeStatus_ASC',
    desc: 'operativeStatus_DESC',
  },
  adminStatus: {
    asc: 'adminStatus_ASC',
    desc: 'adminStatus_DESC',
  },
  releaseDate: {
    asc: 'releaseDate_ASC',
    desc: 'releaseDate_DESC',
  },
  deliveryDate: {
    asc: 'deliveryDate_ASC',
    desc: 'deliveryDate_DESC',
  },
  amount: {
    asc: 'amount_ASC',
    desc: 'amount_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  billableServiceName: {
    asc: 'billableServiceName_ASC',
    desc: 'billableServiceName_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  company: {
    asc: 'company_ASC',
    desc: 'company_DESC',
  },
};

export const sortAccountingReconciliation = {
  company: {
    asc: 'company_ASC',
    desc: 'company_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  billUnitId: {
    asc: 'billUnitId_ASC',
    desc: 'billUnitId_DESC',
  },
  objectDate: {
    asc: 'objectDate_ASC',
    desc: 'objectDate_DESC',
  },
  objectId: {
    asc: 'objectId_ASC',
    desc: 'objectId_DESC',
  },
  objectType: {
    asc: 'objectType_ASC',
    desc: 'objectType_DESC',
  },
  extractBatch: {
    asc: 'extractBatch_ASC',
    desc: 'extractBatch_DESC',
  },
  extractDate: {
    asc: 'extractDate_ASC',
    desc: 'extractDate_DESC',
  },
  extractStatus: {
    asc: 'extractStatus_ASC',
    desc: 'extractStatus_DESC',
  },
};

export const sortRawCdrData = {
  answerTime: {
    asc: 'answerTime_ASC',
    desc: 'answerTime_DESC',
  },
  releaseTime: {
    asc: 'releaseTime_ASC',
    desc: 'releaseTime_DESC',
  },
  durationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  durationSeconds: {
    asc: 'durationSeconds_ASC',
    desc: 'durationSeconds_DESC',
  },
};

export const sortRawSmsData = {
  submitDate: {
    asc: 'submitDate_ASC',
    desc: 'submitDate_DESC',
  },
  scheduleDeliveryTime: {
    asc: 'scheduleDeliveryTime_ASC',
    desc: 'scheduleDeliveryTime_DESC',
  },
  durationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  messageDeliveryStatus: {
    asc: 'messageDeliveryStatus_ASC',
    desc: 'messageDeliveryStatus_DESC',
  },
};

export const sortRawNonBroadsoft = {
  answerTime: {
    asc: 'answerTime_ASC',
    desc: 'answerTime_DESC',
  },
  releaseTime: {
    asc: 'releaseTime_ASC',
    desc: 'releaseTime_DESC',
  },
  extDurationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  extDurationSeconds: {
    asc: 'durationSeconds_ASC',
    desc: 'durationSeconds_DESC',
  },
};

export const sortServicesForcecast = {
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  serviceName: {
    asc: 'serviceName_ASC',
    desc: 'serviceName_DESC',
  },
};

export const sortemployeeCostingPNL = {
  employeeName: {
    asc: 'employeeName_ASC',
    desc: 'employeeName_DESC',
  },
  jobTitle: {
    asc: 'jobTitle_ASC',
    desc: 'jobTitle_DESC',
  },
};

export const sortemployeeCostingPNLQuater = {
  employeeName: {
    asc: 'employeeName_ASC',
    desc: 'employeeName_DESC',
  },
  jobTitle: {
    asc: 'jobTitle_ASC',
    desc: 'jobTitle_DESC',
  },
};

export const sortemployeeCostingYearlyPL = {
  employeeName: {
    asc: 'employeeName_ASC',
    desc: 'employeeName_DESC',
  },
  jobTitle: {
    asc: 'jobTitle_ASC',
    desc: 'jobTitle_DESC',
  },
};

export const sortBillableServicesForecast = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  commercialName: {
    asc: 'commercialName_ASC',
    desc: 'commercialName_DESC',
  },
  billableServiceId: {
    asc: 'billableServiceId_ASC',
    desc: 'billableServiceId_DESC',
  },
  billableServiceName: {
    asc: 'billableServiceName_ASC',
    desc: 'billableServiceName_DESC',
  },
  amount: {
    asc: 'amount_ASC',
    desc: 'amount_DESC',
  },
  currency: {
    asc: 'currency_ASC',
    desc: 'currency_DESC',
  },
};

export const sortBillObjectFilesSummary = {
  documentType: {
    asc: 'documentType_ASC',
    desc: 'documentType_DESC',
  },
  yearMonth: {
    asc: 'yearMonth_ASC',
    desc: 'yearMonth_DESC',
  },
  fileCount: {
    asc: 'fileCount_ASC',
    desc: 'fileCount_DESC',
  },
};

export const tabelColumnExportemployeeCostingPNLExportCSV = [
  {
    name: 'employeeId',
    label: 'label.employeeId',
  },
  {
    name: 'employeeName',
    label: 'label.employeeName',
    sortable: true,
  },
  {
    name: 'jobTitle',
    label: 'label.jobTitle',
    sortable: true,
  },
  {
    name: 'status',
    label: 'label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'clientName',
    label: 'label.clientName',
  },
  {
    name: 'clientJobTitle',
    label: 'label.clientJobTitle',
  },
  {
    name: 'monthOneGrossSal',
    label: 'monthOneGrossSal',
  },
  {
    name: 'monthTwoGrossSal',
    label: 'label.grossSalaryMxn',
  },
  {
    name: 'monthThreeGrossSal',
    label: 'monthThreeGrossSal',
  },
  {
    name: 'totalGrossSal',
    label: 'totalGrossSal',
  },
  {
    name: 'monthOneActualCost',
    label: 'monthOneActualCost',
  },
  {
    name: 'monthTwoActualCost',
    label: 'label.actualCostMxn',
  },
  {
    name: 'monthThreeActualacost',
    label: 'monthThreeActualacost',
  },
  {
    name: 'totalActualCost',
    label: 'totalActualCost',
  },
  {
    name: 'monthOneRevenueUsd',
    label: 'monthOneRevenueUsd',
  },
  {
    name: 'monthTwoRevenueUsd',
    label: 'label.revenueUSD',
  },
  {
    name: 'monthThreeRevenueUsd',
    label: 'monthThreeRevenueUsd',
  },
  {
    name: 'totalRevenueUsd',
    label: 'totalRevenueUsd',
  },
  {
    name: 'monthOneRevenueMxn',
    label: 'monthOneRevenueMxn',
  },
  {
    name: 'monthTwoRevenueMxn',
    label: 'label.revenueMxn',
  },
  {
    name: 'monthThreeRevenueMxn',
    label: 'monthThreeRevenueMxn',
  },
  {
    name: 'totalRevenueMxn',
    label: 'totalRevenueMxn',
  },
  {
    name: 'monthOneActualIncomeMxn',
    label: 'monthOneActualIncomeMxn',
  },
  {
    name: 'monthTwoActualIncomeMxn',
    label: 'label.actIncomeMxn',
  },
  {
    name: 'monthThreeActualIncomeMxn',
    label: 'monthThreeActualIncomeMxn',
  },
  {
    name: 'totalActualIncome',
    label: 'totalActualIncome',
  },
  {
    name: 'monthOneSalaryToBilling',
    label: 'monthOneSalaryToBilling',
  },
  {
    name: 'monthTwoSalaryToBilling',
    label: 'label.salaryToBilling',
  },
  {
    name: 'monthThreeSalaryToBilling',
    label: 'monthThreeSalaryToBilling',
  },
  {
    name: 'averageSalaryToBilling',
    label: 'averageSalaryToBilling',
  },
];

export const sortProvisioningData = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
  clientId: {
    asc: 'clientId_ASC',
    desc: 'clientId_DESC',
  },
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
};

export const sortBillingTracker = {
  clientName: {
    asc: 'clientName_ASC',
    desc: 'clientName_DESC',
  },
  month: {
    asc: 'month_ASC',
    desc: 'month_DESC',
  },
  billStart: {
    asc: 'billStart_ASC',
    desc: 'billStart_DESC',
  },
  billEnd: {
    asc: 'billEnd_ASC',
    desc: 'billEnd_DESC',
  },
};

export const sortCommitmentData = {
  organization: {
    asc: 'organization_ASC',
    desc: 'organization_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortAgeingPayment = {
  totalRemainingAmount: {
    asc: 'totalRemainingAmount_ASC',
    desc: 'totalRemainingAmount_DESC',
  },
};

export const sortCommissionsCancellation = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  commercialName: {
    asc: 'commercialName_ASC',
    desc: 'commercialName_DESC',
  },
  salesAgentName: {
    asc: 'salesAgentName_ASC',
    desc: 'salesAgentName_DESC',
  },
  cancellationDate: {
    asc: 'cancellationDate_ASC',
    desc: 'cancellationDate_DESC',
  },
  cancellationReason: {
    asc: 'cancellationReason_ASC',
    desc: 'cancellationReason_DESC',
  },
  proratedAmount: {
    asc: 'proratedAmount_ASC',
    desc: 'proratedAmount_DESC',
  },
};

export const sortFolioDataExtract = {
  folioDate: {
    asc: 'folioDate_ASC',
    desc: 'folioDate_DESC',
  },
};

export const sortBroadsoftZeroDurationCdrs = {
  answerTime: {
    asc: 'answerTime_ASC',
    desc: 'answerTime_DESC',
  },
  releaseTime: {
    asc: 'releaseTime_ASC',
    desc: 'releaseTime_DESC',
  },
  extDurationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  extDurationSeconds: {
    asc: 'durationSeconds_ASC',
    desc: 'durationSeconds_DESC',
  },
};

export const sortNonBroadsoftZeroDurationCDR = {
  answerTime: {
    asc: 'answerTime_ASC',
    desc: 'answerTime_DESC',
  },
  releaseTime: {
    asc: 'releaseTime_ASC',
    desc: 'releaseTime_DESC',
  },
  extDurationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  extDurationSeconds: {
    asc: 'durationSeconds_ASC',
    desc: 'durationSeconds_DESC',
  },
};

export const sortSuspendedFailedRecords = {
  batchId: {
    asc: 'batchId_ASC',
    desc: 'batchId_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  usageType: {
    asc: 'usageType_ASC',
    desc: 'usageType_DESC',
  },
};

export const sortExtractBI = {
  clientId: {
    asc: 'clientId_ASC',
    desc: 'clientId_DESC',
  },
  commercialName: {
    asc: 'commercialName_ASC',
    desc: 'commercialName_DESC',
  },
  incrementedAmount: {
    asc: 'incrementedAmount_ASC',
    desc: 'incrementedAmount_DESC',
  },
  finalAmount: {
    asc: 'finalAmount_ASC',
    desc: 'finalAmount_DESC',
  },
  completedDate: {
    asc: 'completedDate_ASC',
    desc: 'completedDate_DESC',
  },
};

export const sortR = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
};

export const sortTaxExemption = {
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  taxType: {
    asc: 'taxType_ASC',
    desc: 'taxType_DESC',
  },
  firstName: {
    asc: 'firstName_ASC',
    desc: 'firstName_DESC',
  },
  lastName: {
    asc: 'lastName_ASC',
    desc: 'lastName_DESC',
  },
};

export const sortFonatelReport = {
  invoiceStartDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  invoiceEndDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
};

export const sortPHCReport = {
  invoicePeriodStart: {
    asc: 'invoicePeriodStart_ASC',
    desc: 'invoicePeriodStart_DESC',
  },
  invoicePeriodEnd: {
    asc: 'invoicePeriodEnd_ASC',
    desc: 'invoicePeriodEnd_DESC',
  },
  serviceEffectiveDate: {
    asc: 'serviceEffectiveDate_ASC',
    desc: 'serviceEffectiveDate_DESC',
  },
  priceUnitStartDate: {
    asc: 'priceUnitStartDate_ASC',
    desc: 'priceUnitStartDate_DESC',
  },
  priceUnitEndDate: {
    asc: 'priceUnitEndDate_ASC',
    desc: 'priceUnitEndDate_DESC',
  },
};

export const sortServiceReconReport = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  contractDate: {
    asc: 'contractdate_ASC',
    desc: 'contractdate_DESC',
  },
};

export const groupNamePermissions = {
  createReportStylesheet: 'CREATE_REPORT_STYLESHEET',
  modifyReportStylesheet: 'MODIFY_REPORT_STYLESHEET',
  viewReportStylesheet: 'VIEW_REPORT_STYLESHEET',
  editReportData: 'EDIT_REPORT_DATA',
  viewReportData: 'VIEW_REPORT_DATA',
  getNewCustomerReport: 'GET_NEW_CUSTOMER_REPORT',
  getAccountClosureReport: 'GET_ACCOUNT_CLOSURE_REPORT',
  getOrderReport: 'GET_ORDER_REPORT',
  getTrialSubscriptionReport: 'GET_TRIAL_SUBSCRIPTION_REPORT',
  getPaidSubscriptionReport: 'GET_PAID_SUBSCRIPTION_REPORT',
  getTrialToPaidSubscriptionReport: 'GET_TRIAL_TO_PAID_SUBSCRIPTION_REPORT',
  getUnbilledTransactionReport: 'GET_UNBILLED_TRANSACTION_REPORT',
  getUsageSummaryReport: 'GET_USAGE_SUMMARY_REPORT',
  getUsageDetailReport: 'GET_USAGE_DETAIL_REPORT',
  getUsageReconciliationReport: 'GET_USAGE_RECONCILIATION_REPORT',
  getCustomerBillingReport: 'GET_CUSTOMER_BILLING_REPORT',
  getBilledTransactionSummaryReport: 'GET_BILLED_TRANSACTION_SUMMARY_REPORT',
  getBillProductSummaryReport: 'GET_BILL_PRODUCT_SUMMARY_REPORT',
  getInvoiceReport: 'GET_INVOICE_REPORT',
  getTaxReport: 'GET_TAX_REPORT',
  getBillingInvoicingReconReport: 'GET_BILLING_INVOICING_RECON_REPORT',
  getJobScheduleReport: 'GET_JOBSCHEDULE_REPORT',
  getFailedJobsReport: 'GET_FAILED_JOBS_REPORT',
  getJobDetailReport: 'GET_JOB_DETAIL_REPORT',
  getPaymentReport: 'GET_PAYMENT_REPORT',
  getFailedPaymentReport: 'GET_FAILED_PAYMENT_REPORT',
  getPaymentSuspenseReport: 'GET_PAYMENT_SUSPENSE_REPORT',
  getFailedPaymentCustomerReport: 'GET_FAILED_PAYMENT_CUSTOMER_REPORT',
  getInvoicingArReconReport: 'GET_INVOICING_AR_RECON_REPORT',
  collection030days: 'GET_COLLECTION_0_30_DAYS_REPORT',
  collection3060days: 'GET_COLLECTION_30_60_DAYS_REPORT',
  collection6090days: 'GET_COLLECTION_60_90_DAYS_REPORT',
  collectionGT90days: 'GET_COLLECTION_GT_90_DAYS_REPORT',
  getAgeingReport: 'GET_AGEING_REPORT',
  getArOPSReport: 'GET_AROPS_REPORT',
  getWriteOffReport: 'GET_WRITEOFF_REPORT',
  getDisputeReport: 'GET_DISPUTE_REPORT',
  getRevenueSummaryByItemReport: 'GET_REVENUE_SUMMARY_BY_ITEM_REPORT',
  getRevenueRecognitionByItemReport: 'GET_REVENUE_RECOGNITION_BY_ITEM_REPORT',
  getTaxSummaryReport: 'GET_TAX_SUMMARY_REPORT',
  getTaxDetailReport: 'GET_TAX_DETAIL_REPORT',
  getSubscriptionReport: 'GET_SUBSCRIPTION_REPORT',
  getRevenueRecognitionReport: 'GET_REVENUE_RECOGNITION_REPORT',
  getRevenueSummaryReport: 'GET_REVENUE_SUMMARY_REPORT',
  getActiveSubscriptionReport: 'GET_ACTIVE_SUBSCRIPTION_REPORT',
  downloadReport: 'DOWNLOAD_REPORT_DATA',
  getActiveTrialSubscriptionReport: 'GET_ACTIVE_TRIAL_SUBSCRIPTION_REPORT',
  getServiceBillingReport: 'GET_SERVICE_BILLING_REPORT',
  mailReport: 'MAIL_REPORT_DATA',
  getInvoicingPaymentReconReport: 'GET_INVOICING_PAYMENT_RECON_REPORT',
  getSubscriptionContractExpiryReport: 'GET_SUBSCRIPTION_CONTRACT_EXPIRY_REPORT',
  getRevenueForecastReport: 'GET_REVENUE_FORECAST_REPORT',
  getMonthlyRecurringRevenueReport: 'GET_MONTHLY_RECURRING_REVENUE',
  getVolumeAmountByUsageType: 'GET_VOLUME_AMOUNT_BY_USAGE_TYPE',
  getVolumeAmountPerOperator: 'GET_VOLUME_AMOUNT_PER_OPERATOR',
  getExcessVoiceTrafficReport: 'EXCESS_VOICE_TRAFFIC_REPORT',
  getVoiceTrafficByLDDestinationReport: 'VOICE_TRAFFIC_BY_LD_DESTINATION_REPORT',
  getDataServiceReport: 'DATA_SERVICE_REPORT',
  getNewSMSReport: 'NEW_SMS_REPORT',
  getRawInterconnectVoiceReport: 'RAW_INTERCONNECT_VOICE_REPORT',
  getVoiceCallTypeSummaryReport: 'VOICE_CALL_TYPE_SUMMARY_REPORT',
  getRawInterconnectSMSReport: 'RAW_INTERCONNECT_SMS_REPORT',
  getCreditNoteReport: 'GET_CREDIT_NOTE_REPORT',
  getBillableServiceReport: 'BILLABLE_SERVICES_REPORT',
  getInternetOperatorDataCostReport: 'INTERNET_OPERATOR_DATA_COST_REPORT',
  telecomSummaryReport: 'TELECOM_SUMMARY_REPORT',
  searchAccountReport: 'SEARCH_ACCOUNT_REPORT',
  searchMediationFileStatus: 'SEARCH_MEDIATION_FILE_STATUS',
  getAccountingReconciliationReport: 'GET_ACCOUNTING_RECONCILIATION_REPORT',
  getRawCdrReport: 'GET_RAW_CDR_REPORT',
  getRawSmsReport: 'GET_RAW_SMS_REPORT',
  // GET_BROADSOFT_RAW_CDR_REPORT: 'GET_BROADSOFT_RAW_CDR_REPORT',
  GET_NON_BROADSOFT_RAW_CDR_REPORT: 'GET_NON_BROADSOFT_RAW_CDR_REPORT',
  GET_ASSETS_FORECAST_REPORT: 'GET_ASSETS_FORECAST_REPORT',
  EMPLOYEE_COSTING_PNL_REPORT: 'EMPLOYEE_COSTING_PNL_REPORT',
  BILLABLE_SERVICES_FORECAST_REPORT: 'BILLABLE_SERVICES_FORECAST_REPORT',
  GET_OBJECT_FILES_REPORT: 'GET_OBJECT_FILES_REPORT',
  GET_PROVISIONING_DATA_REPORT: 'GET_PROVISIONING_DATA_REPORT',
  BILLING_TRACKER_REPORT: 'BILLING_TRACKER_REPORT',
  COMMITMENT_REPORT: 'COMMITMENT_REPORT',
  GET_PAYMENT_AGEING_REPORT: 'GET_PAYMENT_AGEING_REPORT',
  SEARCH_SERVICE_CANCELLATION_EXTRACT: 'SEARCH_SERVICE_CANCELLATION_EXTRACT',
  SEARCH_KPI_EXTRACT: 'SEARCH_KPI_EXTRACT',
  GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT: 'GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT',
  GET_BROADSOFT_ZERODURATION_CDRS_REPORT: 'GET_BROADSOFT_ZERODURATION_CDRS_REPORT',
  GET_SUSPENDED_FAILED_RECORDS_REPORT: 'GET_SUSPENDED_FAILED_RECORDS_REPORT',
  GET_BI_EXTRACT: 'GET_BI_EXTRACT',
  GET_REG_R001_05_REPORT: 'GET_REG_R001_05_REPORT',
  GET_REG_R001_06_REPORT: 'GET_REG_R001_06_REPORT',
  GET_REG_R004_01_REPORT: 'GET_REG_R004_01_REPORT',
  GET_REG_R004_05_REPORT: 'GET_REG_R004_05_REPORT',
  GET_REG_R005_01_REPORT: 'GET_REG_R005_01_REPORT',
  GET_REG_R005_02_REPORT: 'GET_REG_R005_02_REPORT',
  GET_REG_R005_03_REPORT: 'GET_REG_R005_03_REPORT',
  GET_REG_R005_04_REPORT: 'GET_REG_R005_04_REPORT',
  GET_REG_R005_05_REPORT: 'GET_REG_R005_05_REPORT',
  GET_REG_R005_06_REPORT: 'GET_REG_R005_06_REPORT',
  GET_CREDIT_BUREAU_REPORT: 'GET_CREDIT_BUREAU_REPORT',
  PUSH_KPI_EXTRACT: 'PUSH_KPI_EXTRACT',
  TAX_EXEMPTION_REPORT: 'TAX_EXEMPTION_REPORT',
  FONATEL_REPORT: 'FONATEL_REPORT',
  PHC_ASSET_REPORT: 'PHC_ASSET_REPORT',
  SERVICE_RECON_REPORT: 'SERVICE_RECON_REPORT',
};
