import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep, remove, map, union, size, findLastIndex, isObject } from 'lodash';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import CustomerNoForm from '../CustomerNoForm';
import { ModalWithItem } from '../../common';
import ModalAccept from '../ModalAccept';
import FormAddNewContact from '../FormAddNewContact';
import { isCheckValidateForm, messageRoleBilling, validate } from '../../../utils/utils';

class MultiContactForm extends React.PureComponent {
  state = {
    accountContact: [],
    dataSubmit: [],
    wasValidated: false,
    isOpenModalNewContact: false,
    isOpenModalDeleteContact: false,
    indexDeleteContact: 0,
    isModify: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      const newData = data.map(val => {
        return { ...val, isShowPhones: !!val.phones };
      });
      this.setState({ accountContact: newData });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ accountContact: nextProps.data, dataSubmit: [] });
    }
  }

  onToggleModalNewContact = () => {
    const { isOpenModalNewContact } = this.state;
    this.setState({ isOpenModalNewContact: !isOpenModalNewContact });
  };

  onToggleModalDeleteContact = index => {
    const { isOpenModalDeleteContact } = this.state;
    this.setState({ isOpenModalDeleteContact: !isOpenModalDeleteContact, indexDeleteContact: index });
  };

  onDeleteContact = () => {
    const { accountContact, indexDeleteContact, dataSubmit } = this.state;
    const newData = cloneDeep(accountContact);
    dataSubmit[indexDeleteContact] = { id: accountContact[indexDeleteContact].id };
    newData[indexDeleteContact] = { id: accountContact[indexDeleteContact].id };
    this.setState({ accountContact: newData, isModify: true });
  };

  onHandleChange = ({ value = '', name = '', index }) => {
    const { accountContact, dataSubmit } = this.state;
    const newData = cloneDeep(accountContact);
    let { isModify } = this.state;
    newData[index][name] = value;
    if (!isObject(dataSubmit[index])) dataSubmit[index] = {};
    dataSubmit[index][name] = value;
    if (value) isModify = true;
    this.setState({ accountContact: newData, ...dataSubmit, isModify });
  };

  onChangeRole = (name, index) => {
    const { accountContact, dataSubmit } = this.state;
    const newData = cloneDeep(accountContact);
    if (newData[index].roles.indexOf(name) > -1) {
      remove(newData[index].roles, item => item === name);
    } else {
      newData[index].roles.push(name);
    }
    if (newData[index]?.roles?.length === 0) return '';
    if (!isObject(dataSubmit[index])) dataSubmit[index] = {};
    dataSubmit[index].roles = newData[index].roles;
    return this.setState({ accountContact: newData, ...dataSubmit, isModify: true });
  };

  onAddNewContact = item => {
    const { accountContact, dataSubmit } = this.state;
    const newData = cloneDeep(accountContact);
    newData.push(item);
    dataSubmit[newData.length - 1] = newData[newData.length - 1];
    this.setState({ accountContact: newData, isOpenModalNewContact: false, dataSubmit, isModify: true });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { dataSubmit, accountContact } = this.state;
    const { modifyAccount, id, t, doGetAccountDetails } = this.props;
    const newDataSubmit = [];
    this.setState({ wasValidated: true });
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });
    const messageRoleContact = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountContact.map(item => item.roles)
        )
      ),
    });
    if (isCheckValidateForm() || messageRoleContact) return toast.error(t('message.mandatory'));

    if (!isEmpty(dataSubmit)) {
      for (let i = 0; i < dataSubmit.length; i++) {
        if (!isEmpty(dataSubmit[i])) {
          if (dataSubmit[i] && dataSubmit[i].isShowPhones === false) {
            dataSubmit[i].phones = null;
          }
          const { isShowPhones, ...rest } = dataSubmit[i] || {};
          newDataSubmit.push(rest);
          if (!newDataSubmit[newDataSubmit.length - 1] || !newDataSubmit[newDataSubmit.length - 1].id) {
            newDataSubmit[newDataSubmit.length - 1].id = accountContact[i].id || null;
          }
        }
      }
    }
    const newPayload = {
      id,
      contacts: newDataSubmit,
    };
    return modifyAccount(newPayload, ({ success }) => {
      if (success) {
        doGetAccountDetails();
      }
      this.setState({ wasValidated: false });
    });
  };

  onChangePhone = ({ value, fieldName, index, isNewPhone = false, isRemovePhone = false, indexContact }) => {
    const { accountContact, dataSubmit } = this.state;
    const newData = cloneDeep(accountContact);
    if (isNewPhone) {
      const typePhoneArr = ['MOBILE', 'WORK', 'HOME', 'FAX'];
      map(newData[indexContact].phones, item => {
        const index = typePhoneArr.indexOf(item.type);
        typePhoneArr.splice(index, 1);
      });
      if (isEmpty(typePhoneArr)) {
        return toast('No unique type phone remaining', { type: toast.TYPE.ERROR });
      }
      newData[indexContact].phones[newData[indexContact].phones.length] = {
        number: null,
        type: typePhoneArr[0],
      };
    }
    if (isRemovePhone) {
      if (!isObject(dataSubmit[indexContact])) dataSubmit[indexContact] = {};
      const dataRemovePhone = cloneDeep(newData);
      dataRemovePhone[indexContact].phones[index] = { type: newData[indexContact].phones[index].type };
      dataSubmit[indexContact].phones = dataRemovePhone[indexContact].phones;
      newData[indexContact].phones.splice(index, 1);
    }
    if (!isEmpty(newData[indexContact].phones) && !isNewPhone && !isRemovePhone) {
      newData[indexContact].phones[index][fieldName] = value;
      if (!dataSubmit[indexContact] || isEmpty(dataSubmit[indexContact].phones)) dataSubmit[indexContact] = {};
      dataSubmit[indexContact].phones = newData[indexContact].phones;
    }
    return this.setState({ accountContact: newData, isModify: true, dataSubmit });
  };

  onChangeIsShowPhone = ({ index }) => {
    const { accountContact, dataSubmit } = this.state;
    const newData = cloneDeep(accountContact);
    try {
      newData[index].isShowPhones = !newData[index].isShowPhones;
      if (!isObject(dataSubmit[index])) dataSubmit[index] = {};
      dataSubmit[index].isShowPhones = newData[index].isShowPhones;
      this.setState({ accountContact: newData, isModify: true, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      id,
      dataField,
      dataFieldNew,
      checkRoleGroup,
      isDisabled,
      isDisableSubmit,
      modeModifyPhone,
      modeAddPhone,
      modeDeletePhone,
      modeDeleteContact,
      modeAddContact,
      doGetAccountDetails,
      t,
      customerInfo,
      currencyOptions,
    } = this.props;
    const { accountContact, wasValidated, isOpenModalNewContact, isOpenModalDeleteContact, isModify } = this.state;
    if (isEmpty(accountContact)) return '';
    const messageRoleContact = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountContact.map(item => item.roles)
        )
      ),
    });
    const isDisableDelete = accountContact.filter(item => size(item) > 2).length < 2;
    const indexRenderButton = findLastIndex(accountContact, item => size(item) > 2);

    return (
      <form
        onSubmit={this.onHandleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        ref={this.formRef}
        noValidate
      >
        {accountContact.map((item, index) => {
          if (size(item) < 2) return null;
          return (
            <CustomerNoForm
              key={index}
              colItem="col-md-4"
              dataField={dataField}
              title={!index ? t('label.contacts') : ''}
              accountNum={!index ? id : ''}
              accountDetail={item}
              onHandleChange={({ name, value }) => this.onHandleChange({ name, value, index })}
              onHandleSubmit={this.onHandleSubmit}
              wasValidated={wasValidated}
              checkRoleGroup={checkRoleGroup}
              onChangePhone={({ ...props }) => this.onChangePhone({ ...props, indexContact: index })}
              onChangeCheckBox={name => this.onChangeRole(name, index)}
              isDisabled={isDisabled}
              modeModifyPhone={modeModifyPhone}
              modeAddPhone={modeAddPhone}
              modeDeletePhone={modeDeletePhone}
              messageRole={messageRoleContact}
              t={t}
              customerInfo={customerInfo}
              currencyOptions={currencyOptions}
              isLeftAddPhoneBtn
              isShowPhones={item.isShowPhones}
              showPhoneName={index}
              onChangeIsShowPhone={() => this.onChangeIsShowPhone({ index })}
            >
              {modeDeleteContact !== 0 && (
                <div className="form-group col-md-12">
                  <button
                    type="button"
                    className="button button-border black x-small float-right mr-4"
                    onClick={() => this.onToggleModalDeleteContact(index)}
                    disabled={isDisableDelete || modeDeleteContact === 1}
                  >
                    {t('label.delete')}
                  </button>
                </div>
              )}
              {index === indexRenderButton && (
                <div className="form-group col-md-12 btn-add-new">
                  <div className="form-group col-md-12 action-buttons">
                    {modeAddContact !== 0 && (
                      <button
                        type="button"
                        className="button x-small"
                        onClick={this.onToggleModalNewContact}
                        disabled={modeAddContact === 1}
                      >
                        {t('label.addNewContact')}
                      </button>
                    )}
                    {!isDisableSubmit && (
                      <button
                        disabled={isDisableSubmit || !isModify}
                        type="submit"
                        className="button button-border x-small float-right"
                      >
                        {t('label.modify')}
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={doGetAccountDetails}
                      className="button button-border black x-small float-right"
                    >
                      {t('label.cancel')}
                    </button>
                  </div>
                </div>
              )}
            </CustomerNoForm>
          );
        })}
        <ModalWithItem
          isOpen={isOpenModalNewContact}
          modalTitle={t('label.addNewContact')}
          onToggle={this.onToggleModalNewContact}
          wrapperClass="modal-custom modal-70"
        >
          <FormAddNewContact
            dataField={dataFieldNew}
            checkRoleGroup={checkRoleGroup}
            onAddNewContact={this.onAddNewContact}
            onToggleModal={this.onToggleModalNewContact}
            modeModifyPhone={modeModifyPhone}
            modeAddPhone={modeAddPhone}
            modeDeletePhone={modeDeletePhone}
          />
        </ModalWithItem>
        <ModalAccept
          onAcceptDelete={this.onDeleteContact}
          isOpen={isOpenModalDeleteContact}
          title="Are you sure you want to delete this Contact?"
          onToggle={this.onToggleModalDeleteContact}
        />
      </form>
    );
  }
}

MultiContactForm.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.object),
  dataFieldNew: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.any),
  modifyAccount: PropTypes.func.isRequired,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDisableSubmit: PropTypes.bool,
  checkRoleGroup: PropTypes.arrayOf(PropTypes.object),
  modeModifyPhone: PropTypes.number,
  modeAddPhone: PropTypes.number,
  modeDeletePhone: PropTypes.number,
  modeDeleteContact: PropTypes.number,
  modeAddContact: PropTypes.number,
};

MultiContactForm.defaultProps = {
  id: '',
  dataField: [],
  dataFieldNew: [],
  data: [],
  checkRoleGroup: [],
  isDisabled: false,
  isDisableSubmit: false,
  modeModifyPhone: 2,
  modeAddPhone: 2,
  modeDeletePhone: 2,
  modeDeleteContact: 2,
  modeAddContact: 2,
};

export default withTranslation('common')(MultiContactForm);
