import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { cloneDeep, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatNumberValue, getPageTotalCount } from '../../../utils/utils';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import ModalSearchItemServices from '../../../components/common/ModalSearchItemServices';
import ModalAccountId from '../../../components/common/ModalAccountId';
import ModalSelectPendingMerge from '../../../components/common/ModalSelectPendingMerge';
import ModalMultipleAccountIds from '../../../components/common/ModalMultipleAccountIds';
import {
  FormCollapse,
  GenericInput,
  Switch,
  CollapsibleTable,
  DataTable,
  TitleFrom,
  ModalWithItem,
  CheckField,
  TablePagination,
} from '../../../components/common';
import ProvisioningDataSearchForm from '../../../components/CustomerHub/ProvisioningDataSearchForm';
import { searchServiceProvisioningData } from '../../App/actions';
import Footer from '../../../components/Footer';
import constant from '../constants';
import SidebarIndex from '../SidebarIndex';
import { makeGetListCustomers, makeErrorMessage } from '../selectors';
import SplitSearchForm from './SplitSearchForm';
import {
  makeGetPermissionsCustomerManagement,
  makeGetPermissionsRating,
  makeGetPermissionsBilling,
} from '../../App/selectors';
import {
  getCustomers,
  setParams,
  getParentAccountDetail,
  getOrderSummaryByAccountId,
  updateBillingAccount,
  getPreviousAccountMerge,
  getPreviousAccountMovement,
  getCustomerActivity,
  saveAccountMovementData,
  getAccountDetail,
} from '../actions';
import {
  checkPermissionCreateCustomer,
  checkPermissionSearchAccount,
  checkPermissionGetAccount,
  checkPermissionSetCreditLimit,
  checkPermissionGetDelayedBillingConfig,
} from '../CheckPermission';
import { getUserId } from '../../../localStorages';

const collapsibleSplitMenu = [
  {
    title: 'label.toAccount',
    state: 'To Account',
  },
  {
    title: 'label.fromAccount',
    state: 'From Account',
  },
];

const collapsibleMenuPreviousMerge = [
  {
    title: 'label.billableService',
    state: 'Billable Service',
  },
  {
    title: 'label.locations',
    state: 'Locations',
  },
];

const inputFields = [
  {
    name: 'moveAllAccountServices',
    label: 'label.moveAllServices',
    inputType: 'boolean',
  },
  {
    name: 'moveAllAccountProvisioningAttributes',
    label: 'label.moveProvisioning',
    inputType: 'boolean',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
  },
  {
    name: 'updateServiceCapacityOnMove',
    label: 'label.capacityMerge',
    inputType: 'boolean',
  },
];

const accountColumns = [
  {
    name: 'clientId',
    label: 'label.clientId',
  },
  {
    name: 'organization',
    label: 'common:label.organization',
    style: { minWidth: '110px' },
    render: (name, item) => {
      return item && item.contacts && item.contacts[0] ? item.contacts[0].organization : null;
    },
  },
  {
    name: 'id',
    label: 'label.accountId',
  },
  {
    name: 'name',
    label: 'label.name',
  },
  {
    name: 'nonPaying',
    label: 'label.paying',
    style: { minWidth: '90px' },
    render: (name, item) => {
      return <span>{item.nonPaying ? 'FALSE' : 'TRUE'}</span>;
    },
  },
  {
    name: 'parentId',
    label: 'label.parentId',
  },
];

const collapsibleMenuProvisioning = [
  {
    title: 'label.selectRange',
    state: 'Select Range',
  },
];

const provisioningDataSort = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  provisioningId: {
    asc: 'provisioningId_ASC',
    desc: 'provisioningId_DESC',
  },
};

const locationsTableColumns = [
  {
    name: 'pointId',
    label: 'common:label.locationId',
    style: { width: '170px', maxWidth: '170px' },
  },
  {
    name: 'pointName',
    label: 'common:label.locationName',
    style: { width: '170px', maxWidth: '170px' },
  },
  {
    name: 'percent',
    label: 'common:label.percent',
    style: { width: '200px', maxWidth: '200px' },
  },
  // hard viewer only
  {},
];

class Split extends PureComponent {
  state = {
    isExpandChild: true,
    activeSubTab: {},
    orderSummary: {},
    listItems: [],
    effectiveDate: null,
    isOpenModalAccept: false,
    isMergeSelect: true,
    isWithinOrganization: false,
    isUndoPreviousMerge: false,
    dataMerge: { updateServiceCapacityOnMove: '' },
    activeMainTab: {},
    accountSplit: {},
    listAccounts: [],
    undoPreviousMergeData: {},
    withinOrganizationData: {},
    isSelectAccountFromBillable: false,
    indexSelectAccountFromBillable: null,
    activeTab: [],
    isOpenModalBilledService: false,
    indexSelectBilledService: null,
    activitiesList: [],
    activitySelectId: null,
    isOpenModalProvisioning: false,
    itemProvisioningSelect: null,
    indexProvisioningSelect: null,
    dataResponseProvisioning: null,
    isFirstSearch: true,
    dataProvisioning: [],
    filterProvisioning: {},
    selectName: '',
    activeTabProvisioning: {},
    totalCountProvisioning: null,
    sizeProvisioning: 20,
    pageProvisioning: 0,
    sortProvisioning: '',
    sortedProvisioning: {},
    provisioningSelectRangeData: [],
    notesAccountMovement: null,
    isOpenSelectPendingMerge: false,
    savedAccountMovementDataId: null,
    selectOption: 'NEW_DISPERSE',
    wasValidatedSave: false,
  };

  componentDidMount() {
    const { accountIdDisperse } = this.props;
    if (accountIdDisperse) {
      this.setState({ isUndoPreviousMerge: true });
      this.doGetCustomerActivity(accountIdDisperse);
    }
  }

  doGetOrderSummaryByAccountId = id => {
    const { getOrderSummaryByAccountId } = this.props;
    getOrderSummaryByAccountId(id, ({ success, data }) => {
      if (success) {
        this.setState({ withinOrganizationData: data });
        this.doSearchAccount({ status: 'ACTIVE' });
      }
    });
  };

  doGetCustomerActivity = id => {
    const { getCustomerActivity, typeActivity } = this.props;
    const { accountSplit } = this.state;
    this.setState({
      activeSubTab: {},
      activeTab: [],
      orderSummary: {},
      listItems: [],
      effectiveDate: null,
      moveAllAccountServices: true,
      moveProvisioningAttributes: '',
      isOpenModalAccept: false,
      isMergeSelect: true,
      totalQuantity: 0,
      totalAmount: 0,
      totalUnitPrice: 0,
      dataMerge: { updateServiceCapacityOnMove: '' },
      activitiesList: [],
      isOpenModalProvisioning: false,
      itemProvisioningSelect: null,
      indexProvisioningSelect: null,
      dataResponseProvisioning: null,
      isFirstSearch: true,
      dataProvisioning: [],
      filterProvisioning: {},
      selectName: '',
      activeTabProvisioning: {},
      totalCountProvisioning: null,
      sizeProvisioning: 20,
      pageProvisioning: 0,
      sortProvisioning: '',
      sortedProvisioning: {},
      provisioningSelectRangeData: [],
    });
    getCustomerActivity(
      {
        page: 1,
        size: 100,
        // filter: { accountId: id || accountSplit.id, action: 'UNDO_MERGE' },
        filter: { accountId: id || accountSplit.id, action: typeActivity || 'UNDO_MERGE' },
      },
      ({ success, data }) => {
        if (success) this.setState({ activitiesList: data });
      }
    );
  };

  doSearchAccount = ({ status }) => {
    const { getCustomers } = this.props;
    const { accountSplit } = this.state;
    const filter = {
      type: 'DIRECT_CUSTOMER',
      organization:
        accountSplit && accountSplit.contacts && accountSplit.contacts[0] && accountSplit.contacts[0].organization
          ? accountSplit.contacts[0].organization
          : '',
    };
    if (status) filter.status = status;
    const payload = {
      page: 1,
      size: 10000,
      filter,
      sort: null,
    };
    this.setState({
      isSearching: true,
      listCustomers: [],
      isFirstFetch: true,
      activeSubTab: {},
      activeTab: [],
      orderSummary: {},
    });
    getCustomers(payload, ({ data }) => {
      if (data) {
        this.setState({
          listAccounts: data.map(val => ({
            label: `${val.id}${val?.name ? `-${val.name}` : ''}${
              accountSplit && accountSplit.contacts && accountSplit.contacts[0] && accountSplit.contacts[0].organization
                ? `-${accountSplit.contacts[0].organization}`
                : ''
            }`,
            value: val.id,
            ...val,
          })),
        });
      }
    });
  };

  onChangeSwitchMerge = () => {
    const { isMergeSelect } = this.state;
    this.setState({ isMergeSelect: !isMergeSelect });
  };

  onChangeSwitchWithinOrganization = () => {
    const { isWithinOrganization, accountSplit } = this.state;
    if (!isWithinOrganization) this.doGetOrderSummaryByAccountId(accountSplit.id);
    this.setState({
      isWithinOrganization: !isWithinOrganization,
      activeSubTab: {},
      orderSummary: {},
      listItems: [],
      effectiveDate: null,
      isOpenModalAccept: false,
      isMergeSelect: true,
      dataMerge: { updateServiceCapacityOnMove: '' },
    });
  };

  doGetPreviousAccountMovement = id => {
    const { getPreviousAccountMovement } = this.props;
    const { isUndoPreviousMerge } = this.state;
    if (id && isUndoPreviousMerge) {
      getPreviousAccountMovement(id, ({ success, data }) => {
        if (success) this.setState({ undoPreviousMergeData: data });
      });
    }
    this.setState({
      activeSubTab: {},
      activeTab: [],
      orderSummary: {},
      listItems: [],
      effectiveDate: null,
      moveAllAccountServices: true,
      moveProvisioningAttributes: '',
      isOpenModalAccept: false,
      isMergeSelect: true,
      totalQuantity: 0,
      totalAmount: 0,
      totalUnitPrice: 0,
      dataMerge: { updateServiceCapacityOnMove: '' },
    });
  };

  onChangeSwitchWithinUndoPreviousMerge = () => {
    const { isUndoPreviousMerge, accountSplit } = this.state;
    if (accountSplit && accountSplit.id) this.doGetCustomerActivity();
    this.setState({
      isUndoPreviousMerge: !isUndoPreviousMerge,
      activeSubTab: {},
      activeTab: [],
      orderSummary: {},
      listItems: [],
      effectiveDate: null,
      moveAllAccountServices: true,
      moveProvisioningAttributes: '',
      isOpenModalAccept: false,
      isMergeSelect: true,
      totalQuantity: 0,
      totalAmount: 0,
      totalUnitPrice: 0,
      dataMerge: { updateServiceCapacityOnMove: '' },
    });
  };

  onChangeDataMerge = ({ name, value }) => {
    const { dataMerge } = this.state;
    dataMerge[name] = value;
    this.setState({ dataMerge: { ...dataMerge } });
  };

  onChangeProvisioning = ({ value, item }) => {
    try {
      const { listItems } = this.state;
      const newData = cloneDeep(listItems);
      const indexItem = newData.findIndex(val => val.id === item.id);
      newData[indexItem].moveProvisioningAttributes = value;
      this.setState({ listItems: cloneDeep(newData) });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleSubTab = (index, item, indexItem, key) => {
    const { activeSubTab } = this.state;
    if (activeSubTab.index === indexItem && activeSubTab.key === key) return this.setState({ activeSubTab: {} });
    return this.setState({
      activeSubTab: { key, index: indexItem },
    });
  };

  onToggleMainTab = activeTabName => {
    const { activeMainTab } = this.state;
    if (activeMainTab.name === activeTabName) {
      this.setState({
        activeMainTab: { name: activeTabName, isActive: !activeMainTab.isActive },
      });
    } else {
      this.setState({ activeMainTab: { name: activeTabName, isActive: true } });
    }
  };

  renderItem = item => {
    const { activeSubTab, undoPreviousMergeData } = this.state;
    const accountColumns = [
      {
        name: 'id',
        label: 'label.accountId',
        style: { minWidth: '280px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.accountId ? item.accountId : ''}
              wrapperClass="col-md-12"
              onChange={() => {}}
              name="accountId"
              readOnly
            />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'moveAllServices',
        label: 'label.moveAllServices',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.moveAllServices ? 'TRUE' : 'FALSE'}
              wrapperClass="col-md-12"
              onChange={() => {}}
              name="moveAllServices"
              readOnly
            />
          );
        },
      },
      {
        name: 'moveProvisioning',
        label: 'label.moveProvisioning',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.moveProvisioningAttributes ? 'TRUE' : 'FALSE'}
              wrapperClass="col-md-12"
              onChange={() => {}}
              name="moveProvisioning"
              readOnly
            />
          );
        },
      },
      {
        name: 'viewBillables',
        label: 'label.viewBillables',
        style: { minWidth: '200px' },
        render: (colName, item, idx) => (
          <div className="d-flex col-md-12">
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleSubTab(idx, item, idx, 'particalMerge')}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'particalMerge' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          </div>
        ),
      },
    ];

    const billablesColumns = [
      {
        name: 'omsInternalId',
        label: 'common:label.instanceId',
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.omsInternalId ? item.omsInternalId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="moveProvisioning"
              readOnly
            />
          );
        },
      },
      {
        name: 'serviceId',
        label: 'common:label.serviceId',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.serviceId ? item.serviceId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="serviceId"
              readOnly
            />
          );
        },
      },
      {
        name: 'serviceName',
        label: 'common:label.serviceName',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.serviceName ? item.serviceName : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="serviceName"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'deliveryDate',
        label: 'common:label.deliveryDate',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.deliveryDate ? item.deliveryDate : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="deliveryDate"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.quantity ? item.quantity : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="quantity"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'servicePrice',
        label: 'common:label.price',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.servicePrice ? item.servicePrice : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="servicePrice"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'totalPrice',
        label: 'common:label.amount',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.totalPrice ? item.totalPrice : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="totalPrice"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'updateServiceCapacity',
        label: 'common:label.capacityMerge',
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.updateServiceCapacity ? 'TRUE' : 'FALSE'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="updateServiceCapacity"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
    ];

    switch (item.state) {
      case 'To Account':
        return (
          <div className="group-collapsible">
            <CollapsibleTable
              columns={accountColumns}
              indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
              onClickRow={(idx, item, indexItem) => {}}
              data={undoPreviousMergeData && undoPreviousMergeData.toAccount ? undoPreviousMergeData.toAccount : []}
              isSupportRemoveIndex
              isViewOnly
            >
              <DataTable
                columns={billablesColumns}
                data={
                  undoPreviousMergeData.toAccount &&
                  activeSubTab.index !== -1 &&
                  undoPreviousMergeData.toAccount[activeSubTab.index] &&
                  undoPreviousMergeData.toAccount[activeSubTab.index].billables
                    ? undoPreviousMergeData.toAccount[activeSubTab.index].billables
                    : []
                }
              />
            </CollapsibleTable>
          </div>
        );
      case 'From Account':
        return (
          <div className="group-collapsible">
            <CollapsibleTable
              columns={accountColumns}
              indexViewer={
                activeSubTab && activeSubTab.key === 'particalMerge' && activeSubTab.index !== -1
                  ? activeSubTab.index
                  : -1
              }
              onClickRow={(idx, item, indexItem) => {}}
              data={undoPreviousMergeData && undoPreviousMergeData.fromAccount ? undoPreviousMergeData.fromAccount : []}
              isSupportRemoveIndex
              isViewOnly
            >
              <DataTable
                columns={billablesColumns}
                data={
                  undoPreviousMergeData.fromAccount &&
                  activeSubTab.index !== -1 &&
                  undoPreviousMergeData.fromAccount[activeSubTab.index] &&
                  undoPreviousMergeData.fromAccount[activeSubTab.index].billables
                    ? undoPreviousMergeData.fromAccount[activeSubTab.index].billables
                    : []
                }
              />
            </CollapsibleTable>
          </div>
        );
      default:
        return null;
    }
  };

  onAddMoreMultiAccountToSplit = () => {
    this.setState({ isOpenModalAccountId: true, isMultiAccount: true, isSearched: true });
  };

  onSelectAccountId = (id, row) => {
    const { t, getAccountDetail } = this.props;
    const {
      isUndoPreviousMerge,
      isSelectAccountFromBillable,
      withinOrganizationData,
      indexSelectAccountFromBillable,
      activeSubTab,
    } = this.state;
    if (!isSelectAccountFromBillable) {
      if (!isUndoPreviousMerge && row && row.id) {
        this.doGetOrderSummaryByAccountId(row.id);
      }
      if (isUndoPreviousMerge && row && row.id) {
        this.setState({ undoPreviousMergeData: {} });
        this.doGetCustomerActivity(row.id);

        // getPreviousAccountMerge(row.id, ({ success, data }) => {
        //   if (success) this.setState({ undoPreviousMergeData: data });
        // });
      }
      if (id && row) {
        getAccountDetail(row.id, ({ success, data }) => {
          if (success && data) {
            const { accountSplit } = this.state;
            accountSplit.commercialName = data.commercialName;
            this.setState({ accountSplit: { ...accountSplit } });
          }
        });
      }
      this.setState({ accountSplit: row, isOpenModalAccountId: false });
    } else {
      const newData = cloneDeep(withinOrganizationData);
      if (row && row.id) {
        const accountIdExist = newData.accountServicesSummaries[activeSubTab.index].accountsList.find(
          val => val.accountId === id
        );
        if (accountIdExist) return toast.error(t('common:message.messageAccountExist'));
        newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectAccountFromBillable].accountId =
          row.id;
        newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectAccountFromBillable].nonPaying =
          row.nonPaying;
        newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectAccountFromBillable].parentId =
          row.parentId;
        newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectAccountFromBillable].clientId =
          row.clientId;
        newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectAccountFromBillable].name =
          row.name;
        newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectAccountFromBillable].organization =
          row.contacts && row.contacts[0] && row.contacts[0].organization ? row.contacts[0].organization : null;
      }
      this.setState({
        withinOrganizationData: newData,
        isOpenModalAccountId: false,
        isSelectAccountFromBillable: false,
        indexSelectAccountFromBillable: null,
      });
    }
  };

  onHandleSubmitSplit = ({ isSave = false }) => {
    const { updateBillingAccount, typeSubmit, t, saveAccountMovementData } = this.props;
    const {
      undoPreviousMergeData,
      isUndoPreviousMerge,
      withinOrganizationData,
      accountSplit,
      activitySelectId,
      notesAccountMovement,
      savedAccountMovementDataId,
    } = this.state;
    this.setState({ wasValidatedSave: isSave });
    if (isUndoPreviousMerge) {
      const {
        moveAllAccountServices,
        moveAllAccountProvisioningAttributes,
        fromAccount,
        toAccount,
        type,
        updateServiceCapacityOnMove,
        ...rest
      } = undoPreviousMergeData;
      const newFromAccount = fromAccount
        ? fromAccount.map(val => {
            const {
              accountId,
              moveAllServices,
              moveProvisioningAttributes,
              billables,
              paymentProfile,
              clientId,
              organization,
            } = val;
            return {
              accountId,
              moveAllServices: moveAllServices ? 'true' : 'false',
              moveProvisioningAttributes: moveProvisioningAttributes ? 'true' : 'false',
              paymentProfile: paymentProfile || null,
              clientId: clientId || null,
              organization: organization || null,
              billables: billables
                ? billables.map(i => ({
                    serviceId: i.serviceId,
                    omsInternalId: i.omsInternalId,
                    serviceName: i.serviceName,
                    quantity: i.quantity,
                    servicePrice: i.servicePrice,
                    totalPrice: i.totalPrice,
                    uom: i.uom,
                    updateServiceCapacity: i.updateServiceCapacity ? 'true' : 'false',
                    moveProvisioningAttributes: moveProvisioningAttributes ? 'true' : 'false',
                  }))
                : null,
            };
          })
        : [];
      const newToAccount = toAccount
        ? toAccount.map(val => {
            const {
              accountId,
              moveAllServices,
              moveProvisioningAttributes,
              billables,
              clientId,
              organization,
              paymentProfile,
            } = val;
            return {
              accountId,
              moveAllServices: moveAllServices ? 'true' : 'false',
              moveProvisioningAttributes: moveProvisioningAttributes ? 'true' : 'false',
              paymentProfile: paymentProfile || null,
              clientId: clientId || null,
              organization: organization || null,
              billables: billables
                ? billables.map(i => ({
                    serviceId: i.serviceId,
                    omsInternalId: i.omsInternalId,
                    serviceName: i.serviceName,
                    quantity: i.quantity,
                    servicePrice: i.servicePrice,
                    totalPrice: i.totalPrice,
                    uom: i.uom,
                    updateServiceCapacity: i.updateServiceCapacity ? 'true' : 'false',
                    moveProvisioningAttributes: moveProvisioningAttributes ? 'true' : 'false',
                  }))
                : null,
            };
          })
        : [];
      const payload = {
        moveAllAccountServices: moveAllAccountServices ? 'true' : 'false',
        updateServiceCapacityOnMove: updateServiceCapacityOnMove
          ? 'true'
          : updateServiceCapacityOnMove === null
          ? updateServiceCapacityOnMove
          : 'false',
        moveAllAccountProvisioningAttributes: moveAllAccountProvisioningAttributes ? 'true' : 'false',
        activityId: activitySelectId,
        fromAccount: newToAccount,
        toAccount: newFromAccount,
        type: typeSubmit || 'DISPERSE',
        ...rest,
      };

      updateBillingAccount(payload);
    } else {
      try {
        let updateServiceCapacityOnMove = 'false';
        let isNeedValidationTotalAmount = false;
        const fromAccountPayload = {
          accountId: accountSplit.id,
          moveAllServices: 'true',
          moveProvisioningAttributes: 'false',
          clientId: accountSplit.clientId ? accountSplit.clientId : null,
          organization:
            accountSplit && accountSplit.contacts && accountSplit.contacts[0] && accountSplit.contacts[0].organization
              ? accountSplit.contacts[0].organization
              : null,
          billables: [],
        };
        const toAccountPayload = [];
        const serviceSelect = withinOrganizationData.accountServicesSummaries.find(val => !!val.isSelected);
        const serviceSelectArr = withinOrganizationData.accountServicesSummaries.filter(val => !!val.isSelected);

        if (serviceSelectArr.length === 1) {
          if (serviceSelect && serviceSelect.accountsList && serviceSelect.accountsList.length) {
            let totalAmount = 0;
            serviceSelect.accountsList.forEach(val => {
              if (val.capacitySplit === 'TRUE') {
                updateServiceCapacityOnMove = 'true';
              }
              if (val.totalPrice) totalAmount += Number.parseFloat(val.totalPrice);
            });
            if (totalAmount !== 0 && totalAmount !== Number.parseFloat(serviceSelect.totalPrice)) {
              isNeedValidationTotalAmount = true;
            }
            serviceSelect.accountsList.forEach((val, index) => {
              toAccountPayload[index] = {};
              toAccountPayload[index].accountId = val.accountId;
              toAccountPayload[index].clientId = val.clientId;
              toAccountPayload[index].organization =
                val.contacts && val.contacts[0] ? val.contacts[0].organization : val?.organization || null;
              toAccountPayload[index].moveProvisioningAttributes = val.moveProvisioning === 'TRUE' ? 'true' : 'false';
              let newProvisioningMovementTypes = [];
              if (val.provisioningMovementTypes && val.provisioningMovementTypes.length) {
                const dataProvDataMoveTypeAll = val.provisioningMovementTypes.filter(
                  val => val.provDataMoveType === 'ALL'
                );

                if (dataProvDataMoveTypeAll) {
                  val.provisioningMovementTypes.forEach(pr => {
                    const indexOtherAllType = dataProvDataMoveTypeAll.findIndex(
                      dt => pr.provDataMoveType !== 'ALL' && pr.type === dt.type
                    );
                    if (indexOtherAllType === -1)
                      newProvisioningMovementTypes = [...newProvisioningMovementTypes, { ...pr }];
                  });
                }
              }

              toAccountPayload[index].billables = [
                {
                  serviceId: val.serviceId || serviceSelect.serviceId,
                  serviceName: val.serviceName || serviceSelect.serviceName,
                  totalPrice: val.totalPrice,
                  omsInternalId: serviceSelect.omsInternalId,
                  // updateServiceCapacity: updateServiceCapacityOnMove,
                  updateServiceCapacity: val.capacitySplit === 'TRUE' ? 'true' : 'false',
                  quantity: val.quantity,
                  servicePrice: val.servicePrice,
                  uom: val.uom || null,
                  capacity: val.capacity || null,
                  provisioningMovementTypes:
                    newProvisioningMovementTypes && newProvisioningMovementTypes.length
                      ? newProvisioningMovementTypes
                      : null,
                  moveProvisioningAttributes: val.moveProvisioning === 'TRUE' ? 'true' : 'false',
                },
              ];
            });
          }
          if (serviceSelect) {
            fromAccountPayload.billables = [
              {
                serviceId: serviceSelect.serviceId,
                serviceName: serviceSelect.serviceName,
                totalPrice: serviceSelect.totalPrice,
                quantity: serviceSelect.quantity,
                servicePrice: serviceSelect.servicePrice,
                omsInternalId: serviceSelect.omsInternalId,
                uom: serviceSelect.uom,
                updateServiceCapacity: updateServiceCapacityOnMove,
              },
            ];
          }
        } else {
          serviceSelectArr.forEach(service => {
            let totalAmount = 0;
            let updateServiceCapacity = 'false';
            if (service.accountsList && service.accountsList.length)
              service.accountsList.forEach(val => {
                if (val.capacitySplit === 'TRUE') {
                  updateServiceCapacityOnMove = 'true';
                  updateServiceCapacity = 'true';
                }
                if (val.totalPrice) totalAmount += Number.parseFloat(val.totalPrice);
              });

            if (totalAmount !== 0 && totalAmount !== Number.parseFloat(service.totalPrice)) {
              isNeedValidationTotalAmount = true;
            }
            if (service.accountsList && service.accountsList.length)
              service.accountsList.forEach((val, index) => {
                toAccountPayload.push({});

                toAccountPayload[toAccountPayload.length - 1].accountId = val.accountId;
                toAccountPayload[toAccountPayload.length - 1].clientId = val.clientId;
                toAccountPayload[toAccountPayload.length - 1].organization =
                  val.contacts && val.contacts[0] ? val.contacts[0].organization : val?.organization || null;
                toAccountPayload[toAccountPayload.length - 1].moveProvisioningAttributes =
                  val.moveProvisioning === 'TRUE' ? 'true' : 'false';
                toAccountPayload[toAccountPayload.length - 1].billables = [
                  {
                    serviceId: val.serviceId || service.serviceId,
                    serviceName: val.serviceName || service.serviceName,
                    totalPrice: val.totalPrice,
                    omsInternalId: service.omsInternalId,
                    // updateServiceCapacity: val.updateServiceCapacity ? 'true' : 'false',
                    updateServiceCapacity: val.capacitySplit === 'TRUE' ? 'true' : 'false',
                    quantity: val.quantity,
                    servicePrice: val.servicePrice,
                    provisioningMovementTypes: val.provisioningMovementTypes ? val.provisioningMovementTypes : null,
                    moveProvisioningAttributes: val.moveProvisioning === 'TRUE' ? 'true' : 'false',
                    uom: val.uom || null,
                    capacity: val.capacity || null,
                  },
                ];
              });
            fromAccountPayload.billables.push({
              serviceId: service.serviceId,
              serviceName: service.serviceName,
              totalPrice: service.totalPrice,
              quantity: service.quantity,
              servicePrice: service.servicePrice,
              omsInternalId: service.omsInternalId,
              uom: service.uom,
              // updateServiceCapacity: updateServiceCapacityOnMove,
              updateServiceCapacity,
            });
          });
        }

        if (isNeedValidationTotalAmount) {
          return toast.error(t('common:message.errorTotalAmountSplit'));
        }
        const payload = {
          moveAllAccountServices: 'false',
          updateServiceCapacityOnMove,
          moveAllAccountProvisioningAttributes: 'false',
          fromAccount: fromAccountPayload,
          toAccount: toAccountPayload,
          type: typeSubmit || 'DISPERSE',
        };

        if (isSave) {
          if (!notesAccountMovement) return toast.error(t('common:message.messageNoteSavedPendingAccountMovement'));
          return saveAccountMovementData(
            {
              ...payload,
              savedAccountMovementDataId: savedAccountMovementDataId || null,
              notes: notesAccountMovement,
              userId: getUserId() || null,
            },
            ({ success }) => {
              if (success) {
                this.setState({
                  notesAccountMovement: null,
                  isOpenSelectPendingMerge: false,
                  activeSubTab: {},
                  activeTab: [],
                  wasValidatedSave: false,
                });
              }
            }
          );
        }

        updateBillingAccount({
          ...payload,
          notes: notesAccountMovement,
          savedAccountMovementDataId: savedAccountMovementDataId || null,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  onAddAccountToSplit = () => {
    const { withinOrganizationData, activeSubTab } = this.state;
    const newData = cloneDeep(withinOrganizationData);
    try {
      if (!newData.accountServicesSummaries[activeSubTab.index].accountsList)
        newData.accountServicesSummaries[activeSubTab.index].accountsList = [];
      const { quantity, totalPrice, servicePrice } = newData.accountServicesSummaries[activeSubTab.index];
      if (newData.accountServicesSummaries[activeSubTab.index].accountsList.length) {
        newData.accountServicesSummaries[activeSubTab.index].accountsList.push({
          accountId: '',
          clientId: '',
          organization: '',
          parentId: '',
          paying: '',
          capacitySplit: 'FALSE',
          splitService: '',
          quantity: Number.parseFloat(quantity || 0) === 1 ? '1' : '0',
          totalPrice: '0',
          servicePrice: '0',
          moveProvisioning: 'FALSE',
          uom: newData.accountServicesSummaries[activeSubTab.index].uom || null,
        });
      } else
        newData.accountServicesSummaries[activeSubTab.index].accountsList.push({
          accountId: '',
          clientId: '',
          organization: '',
          parentId: '',
          paying: '',
          capacitySplit: 'FALSE',
          splitService: '',
          quantity: quantity || '1',
          totalPrice: totalPrice || '0',
          servicePrice: servicePrice || '0',
          moveProvisioning: 'FALSE',
          uom: newData.accountServicesSummaries[activeSubTab.index].uom || null,
        });
      this.setState({ withinOrganizationData: newData });
    } catch (error) {}
  };

  onHandleSelectBilledService = ({ name, value, item, index }) => {
    const { withinOrganizationData } = this.state;
    try {
      const newData = cloneDeep(withinOrganizationData);
      // newData.accountServicesSummaries = newData.accountServicesSummaries.map(val => {
      //   const { isSelected, ...rest } = val;
      //   return rest;
      // });
      if (newData.accountServicesSummaries[index].isSelected) {
        newData.accountServicesSummaries[index].isSelected = false;
      } else {
        newData.accountServicesSummaries[index].isSelected = true;
      }
      this.setState({ withinOrganizationData: newData });
    } catch (error) {}
  };

  onSelectAcitity = ({ name, value, item, index }) => {
    const { activitiesList } = this.state;
    let activitySelectId = null;
    try {
      const newData = cloneDeep(activitiesList);
      // newData = newData.map(val => {
      //   const { isSelected, ...rest } = val;
      //   return { ...rest, isSelected: false };
      // });
      newData[index].isSelected = !newData[index].isSelected;
      if (value) {
        activitySelectId = newData[index].id;
        this.doGetPreviousAccountMovement(newData[index].id);
      }
      this.setState({ activitiesList: newData, activitySelectId });
    } catch (error) {}
  };

  onHandleChangeSelectBilledService = ({ name, value, item, index }) => {
    const { withinOrganizationData, activeSubTab, listAccounts } = this.state;
    const newData = cloneDeep(withinOrganizationData);
    try {
      if (name === 'accountId') {
        const accountSelect = listAccounts.find(val => val.id === value);
        if (accountSelect) {
          newData.accountServicesSummaries[activeSubTab.index].accountsList[index].nonPaying = accountSelect.nonPaying;
          newData.accountServicesSummaries[activeSubTab.index].accountsList[index].parentId = accountSelect.parentId;
          newData.accountServicesSummaries[activeSubTab.index].accountsList[index].clientId = accountSelect.clientId;
          newData.accountServicesSummaries[activeSubTab.index].accountsList[index].name = accountSelect.name;
          newData.accountServicesSummaries[activeSubTab.index].accountsList[index].contacts = accountSelect.contacts
            ? accountSelect.contacts
            : null;
        }
      }
      if (name === 'quantity' && value) {
        newData.accountServicesSummaries[activeSubTab.index].accountsList[index].servicePrice =
          Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].accountsList[index].totalPrice) /
          Number.parseFloat(value);
      }

      if (
        value &&
        name === 'totalPrice' &&
        Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].accountsList[index].quantity) > 0
      ) {
        newData.accountServicesSummaries[activeSubTab.index].accountsList[index].servicePrice =
          Number.parseFloat(value) /
          Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].accountsList[index].quantity);
      }

      if ((name === 'quantity' || name === 'totalPrice') && Number.parseFloat(value) < 0) {
        return '';
      }
      newData.accountServicesSummaries[activeSubTab.index].accountsList[index][name] = value;
      if (name === 'capacitySplit' && value === 'FALSE') {
        newData.accountServicesSummaries[activeSubTab.index].accountsList[index].capacity = null;
      }
      let totalAmount = 0;
      let totalUnitPrice = 0;
      let totalQuantity = 0;
      if (newData.accountServicesSummaries[activeSubTab.index]) {
        newData.accountServicesSummaries[activeSubTab.index].accountsList.forEach(val => {
          const { totalPrice, servicePrice, quantity } = val;
          if (totalPrice) totalAmount += Number.parseFloat(totalPrice);
          if (servicePrice) totalUnitPrice += Number.parseFloat(servicePrice);
          if (quantity) totalQuantity += Number.parseFloat(quantity);
        });
      }
      if (totalAmount > Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].totalPrice)) {
        return '';
      }
      // console.log('value5', value);
      if (
        name === 'quantity' &&
        totalQuantity > Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].quantity) &&
        Number.parseFloat(value) > 1 &&
        Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].quantity) === 1
      ) {
        return '';
      }
      // console.log('value6', value, newData.accountServicesSummaries[activeSubTab.index].servicePrice);
      // if (totalUnitPrice > Number.parseFloat(newData.accountServicesSummaries[activeSubTab.index].servicePrice)) {
      //   return '';
      // }
      // console.log('value8', value, totalUnitPrice);
      // newData.accountServicesSummaries[activeSubTab.index].quantity = totalQuantity;
      // newData.accountServicesSummaries[activeSubTab.index].servicePrice = totalUnitPrice;
      return this.setState({ withinOrganizationData: newData });
    } catch (error) {}
  };

  onRemoveBilledService = ({ index }) => {
    try {
      const { listAccounts, withinOrganizationData, activeSubTab } = this.state;
      const newData = cloneDeep(withinOrganizationData);
      const newListAccounts = cloneDeep(listAccounts);
      newListAccounts.splice(index, 1);
      newData.accountServicesSummaries[activeSubTab.index].accountsList.splice(index, 1);
      this.setState({ listAccounts: newListAccounts, withinOrganizationData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleChangeSelectSplitService = (id, row) => {
    const { withinOrganizationData, activeSubTab, indexSelectBilledService } = this.state;
    const newData = cloneDeep(withinOrganizationData);
    try {
      newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectBilledService].serviceId = id;
      newData.accountServicesSummaries[activeSubTab.index].accountsList[indexSelectBilledService].serviceName =
        row.name;
      this.setState({
        withinOrganizationData: newData,
        isOpenModalBilledService: false,
        indexSelectBilledService: null,
      });
    } catch (error) {}
  };

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab && activeTab.find(val => val.name === activeTabName)) {
      this.setState({
        activeTab: activeTab.filter(val => val.name !== activeTabName),
      });
    } else {
      this.setState({ activeTab: cloneDeep([...activeTab, { name: activeTabName, isActive: true }]) });
    }
  };

  renderPreviousMergeItem = item => {
    const {
      activeSubTab,
      withinOrganizationData,
      isWithinOrganization,
      listAccounts,
      activeLocationSubTab,
    } = this.state;
    const { t } = this.props;
    let accountList = [];
    let isSelectCapacity = false;
    if (
      withinOrganizationData &&
      withinOrganizationData.accountServicesSummaries &&
      withinOrganizationData.accountServicesSummaries[activeSubTab.index] &&
      withinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList
    ) {
      accountList = withinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList;
    }

    let accountListCheck = [];
    if (
      withinOrganizationData &&
      withinOrganizationData.accountServicesSummaries &&
      withinOrganizationData.accountServicesSummaries.length
    ) {
      withinOrganizationData.accountServicesSummaries.forEach(val => {
        const { accountsList, isSelected, omsInternalId } = val;
        if (
          accountsList &&
          isSelected &&
          activeSubTab.index !== -1 &&
          withinOrganizationData?.accountServicesSummaries[activeSubTab.index] &&
          withinOrganizationData?.accountServicesSummaries[activeSubTab.index].omsInternalId !== omsInternalId
        )
          accountListCheck = [...accountListCheck, ...accountsList];
      });
      isSelectCapacity = accountListCheck.find(val => val.capacitySplit === 'TRUE');
    }
    const billablesColumns = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.isSelected || false}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleSelectBilledService({ name, value, item, index })}
              name="select"
              type="checkbox"
              checked={item.isSelected || false}
              disabled={item.status !== 'ACTIVE'}
            />
          );
        },
      },
      {
        name: 'locations',
        label: 'label.viewLocations',
        render: (colName, item, idx) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={evt => this.onToggleSubTab(idx, item, idx, 'locations')}
            disabled={!item.locations || !item.locations.length}
          >
            <i
              className={`fa ${
                activeSubTab && activeSubTab.key === 'locations' && activeSubTab.index === idx ? 'fa-minus' : 'fa-plus'
              }`}
            />
          </button>
        ),
      },
      {
        name: 'omsInternalId',
        label: 'common:label.instanceId',
        style: { minWidth: '180px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.omsInternalId ? item.omsInternalId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="moveProvisioning"
              readOnly
            />
          );
        },
      },
      {
        name: 'status',
        label: 'common:label.status',
      },
      {
        name: 'serviceId',
        label: 'common:label.serviceId',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.serviceId ? item.serviceId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="serviceId"
              readOnly
            />
          );
        },
      },
      {
        name: 'serviceName',
        label: 'common:label.serviceName',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.serviceName ? item.serviceName : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="serviceName"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'uom',
        label: 'common:label.unitOfMeasure',
        style: { minWidth: '130px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.uom ? item.uom : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="uom"
              readOnly
            />
          );
        },
      },
      {
        name: 'capacity',
        label: 'common:label.capacity',
        style: { minWidth: '130px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.capacity ? formatNumberValue(item.capacity) : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="capacity"
              readOnly
            />
          );
        },
      },
      {
        name: 'deliveryDate',
        label: 'common:label.deliveryDate',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.deliveryDate ? item.deliveryDate : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="deliveryDate"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.quantity ? item.quantity : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="quantity"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'servicePrice',
        label: 'common:label.price',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item.servicePrice}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="servicePrice"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'totalPrice',
        label: 'common:label.amount',
        style: { minWidth: '180px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item.totalPrice}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="totalPrice"
              // options={accountBilling || []}
              readOnly
            />
          );
        },
      },
      {
        name: 'split',
        label: 'label.split',
        style: { minWidth: '200px' },
        render: (colName, item, idx, indexParent, activeTab) => (
          <div className="d-flex col-md-12">
            <button
              type="button"
              className="btn-expand-table mr-3"
              onClick={evt => this.onToggleSubTab(idx, item, idx, 'particalMerge')}
              disabled={item.status !== 'ACTIVE'}
            >
              <i
                className={`fa ${
                  activeSubTab && activeSubTab.key === 'particalMerge' && activeSubTab.index === idx
                    ? 'fa-minus'
                    : 'fa-plus'
                }`}
              />
            </button>
          </div>
        ),
      },
    ];
    const columnsAccountSplit = [
      {
        name: 'accountId',
        label: 'label.accountId',
        style: { minWidth: '280px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item && item.accountId ? item.accountId : ''}
              wrapperClass="col-md-12"
              name="accountId"
              type={isWithinOrganization ? 'select' : ''}
              options={listAccounts}
              fa={isWithinOrganization ? '' : 'fa fa-external-link'}
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              menuPortalTarget
              readOnly={!isWithinOrganization}
              onClick={() => {
                this.setState({ isSelectAccountFromBillable: true, indexSelectAccountFromBillable: index });
              }}
            />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'clientId',
        label: 'label.clientId',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.clientId ? item.clientId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="clientId"
              readOnly
            />
          );
        },
      },
      {
        name: 'organization',
        label: 'label.organization',
        style: { minWidth: '200px' },
        render: (name, item) => {
          let organization = item && item.organization ? item.organization : '';
          if (item && item.contacts && item.contacts[0] && item.contacts[0].organization) {
            organization = item.contacts[0].organization;
          }
          return (
            <GenericInput
              value={organization}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="organization"
              readOnly
            />
          );
        },
      },
      {
        name: 'parentId',
        label: 'label.parentId',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item && item.parentId ? item.parentId : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="parentId"
              readOnly
            />
          );
        },
      },
      {
        name: 'nonPaying',
        label: 'label.paying',
        style: { minWidth: '200px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item && item.nonPaying ? 'FALSE' : 'TRUE'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              name="nonPaying"
              type="select"
              tOptions="selections:selectBool"
              menuPortalTarget
              readOnly
              disabled
            />
          );
        },
      },
      {
        name: 'capacitySplit',
        label: 'label.capacitySplit',
        style: { minWidth: '200px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item && item.capacitySplit ? item.capacitySplit : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              name="capacitySplit"
              type="select"
              tOptions="selections:selectBool"
              disabled={isSelectCapacity && item.capacitySplit === 'FALSE'}
              menuPortalTarget
              isNoClearable
            />
          );
        },
      },
      {
        name: 'serviceId',
        label: 'label.splitService',
        style: { minWidth: '200px' },
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item && item.serviceId ? `${item.serviceId}-${item.serviceName || ''}` : ''}
              wrapperClass="col-md-12"
              name="serviceId"
              onChange={({ name, value }) => {}}
              fa="fa fa-external-link"
              readOnly={item.capacitySplit === 'FALSE' || item.capacitySplit === 'false'}
              disabled={item.capacitySplit === 'FALSE' || item.capacitySplit === 'false'}
              onClick={() => {
                this.setState({ isOpenModalBilledService: true, indexSelectBilledService: index });
              }}
            />
          );
        },
      },
      {
        name: 'uom',
        label: 'common:label.unitOfMeasure',
        style: { minWidth: '130px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item && item.uom ? item.uom : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="uom"
              readOnly
            />
          );
        },
      },
      {
        name: 'capacity',
        label: 'common:label.capacity',
        style: { minWidth: '130px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item && item.capacity ? formatNumberValue(item.capacity) : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              name="capacity"
              type="number"
              disabled={
                !item?.capacitySplit ||
                item.capacitySplit === 'FALSE' ||
                item.capacitySplit === 'false' ||
                !item?.serviceId
              }
            />
          );
        },
      },
      {
        name: 'totalPrice',
        label: 'common:label.amount',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item && item.totalPrice ? item.totalPrice : '0'}
              wrapperClass="col-md-12"
              name="totalPrice"
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              type="number"
            />
          );
        },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item && item.quantity ? item.quantity : ''}
              wrapperClass="col-md-12"
              name="quantity"
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              type="number"
            />
          );
        },
      },
      {
        name: 'servicePrice',
        label: 'common:label.price',
        style: { minWidth: '200px' },
        render: (colName, item, idx, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.servicePrice ? item.servicePrice : '0'}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              name="servicePrice"
              // options={accountBilling || []}
              readOnly
              type="number"
            />
          );
        },
      },
      {
        name: 'moveProvisioning',
        label: 'label.moveProvisioning',
        style: { minWidth: '200px' },
        render: (colName, item, index, indexParent, activeTab) => {
          return (
            <GenericInput
              value={item && item.moveProvisioning ? item.moveProvisioning : ''}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onHandleChangeSelectBilledService({ name, value, item, index })}
              name="moveProvisioning"
              type="select"
              tOptions="selections:selectBool"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'selectProvisioning',
        label: 'label.selectProvisioning',
        render: (name, item, index) => {
          return (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onTogglepProvisioning({ name, item, index })}
              disabled={!item.moveProvisioning || item.moveProvisioning === 'FALSE'}
            >
              {t('common:label.selectProvisioning')}
            </button>
          );
        },
      },
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (name, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveBilledService({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const locationsTable = [
      {
        name: 'omsInternalId',
        label: 'common:label.instanceId',
      },
      {
        name: 'pointId',
        label: 'common:label.pointId',
      },
      {
        name: 'pointName',
        label: 'common:label.pointName',
      },
      {
        name: 'city',
        label: 'common:label.city',
      },
      {
        name: 'state',
        label: 'common:label.state',
      },
      {
        name: 'zipCode',
        label: 'common:label.zipCode',
      },

      {
        name: 'country',
        label: 'common:label.country',
      },
    ];
    switch (item.state) {
      case 'Billable Service':
        return (
          <CollapsibleTable
            columns={billablesColumns}
            indexViewer={activeSubTab && activeSubTab.index !== -1 ? activeSubTab.index : -1}
            onClickRow={(idx, item, indexItem) => {}}
            data={
              withinOrganizationData && withinOrganizationData.accountServicesSummaries
                ? withinOrganizationData.accountServicesSummaries
                : []
            }
            isSupportRemoveIndex
            isViewOnly
          >
            {activeSubTab?.key && activeSubTab.key === 'locations' ? (
              <DataTable
                columns={locationsTableColumns}
                data={
                  withinOrganizationData &&
                  withinOrganizationData.accountServicesSummaries &&
                  activeSubTab &&
                  activeSubTab.key === 'locations' &&
                  withinOrganizationData.accountServicesSummaries[activeSubTab.index] &&
                  activeSubTab.index !== -1
                    ? withinOrganizationData.accountServicesSummaries[activeSubTab.index].locations
                    : []
                }
              />
            ) : (
              <>
                <DataTable columns={columnsAccountSplit} data={accountList || []} />
                <div>
                  <button
                    type="button"
                    onClick={this.onAddAccountToSplit}
                    className="button button-border black x-small mt-3 mb-3"
                  >
                    +
                    {t('common:label.addMoreAccountToSplit')}
                  </button>
                </div>
              </>
            )}
          </CollapsibleTable>
        );
      case 'Locations':
        return (
          <div className="group-collapsible">
            <DataTable columns={locationsTable} data={withinOrganizationData.accountLocationsSummaries || []} />
          </div>
        );
      default:
        return null;
    }
  };

  onToggleTabProvisioning = activeTabName => {
    const { activeTabProvisioning } = this.state;
    if (activeTabProvisioning.name === activeTabName) {
      this.setState({
        activeTabProvisioning: { name: activeTabName, isActive: !activeTabProvisioning.isActive },
      });
    } else {
      this.setState({ activeTabProvisioning: { name: activeTabName, isActive: true } });
    }
  };

  onTogglepProvisioning = ({ item, index }) => {
    const { withinOrganizationData, activeSubTab } = this.state;
    let { filterProvisioning, dataProvisioning, selectName, provisioningSelectRangeData } = this.state;
    const newWithinOrganizationData = cloneDeep(withinOrganizationData);
    try {
      if (
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index] &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]
          ?.oldFilterProvisioning
      ) {
        filterProvisioning = cloneDeep(
          newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]
            ?.oldFilterProvisioning
        );
      } else {
        filterProvisioning = {};
      }
      if (
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index] &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]?.oldDataProvisioning
      ) {
        dataProvisioning = cloneDeep(
          newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]
            ?.oldDataProvisioning
        );
      } else {
        dataProvisioning = [];
      }
      if (
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index] &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]?.oldSelectName
      ) {
        selectName =
          newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]?.oldSelectName;
      } else {
        selectName = null;
      }

      if (
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index] &&
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]
          ?.oldProvisioningSelectRangeData
      ) {
        provisioningSelectRangeData =
          newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[index]
            ?.oldProvisioningSelectRangeData;
      } else {
        provisioningSelectRangeData = [];
      }
      this.setState({
        isOpenModalProvisioning: true,
        itemProvisioningSelect: item,
        indexProvisioningSelect: index,
        filterProvisioning,
        dataProvisioning,
        selectName,
        provisioningSelectRangeData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  doSearchProvisioningData = () => {
    const { searchServiceProvisioningData } = this.props;
    const {
      pageProvisioning,
      sizeProvisioning,
      filterProvisioning,
      sorted,
      isFirstSearch,
      itemProvisioningSelect,
      totalCountProvisioning,
      accountSplit,
      withinOrganizationData,
      activeSubTab,
      indexProvisioningSelect,
    } = this.state;
    if (!filterProvisioning || !filterProvisioning.serviceType) filterProvisioning.serviceType = 'VOICE';
    if (!filterProvisioning || !filterProvisioning.type) filterProvisioning.type = 'TRUNK';
    if ((!filterProvisioning || !filterProvisioning.status) && isFirstSearch) filterProvisioning.status = 'ACTIVE';
    if (!filterProvisioning || !filterProvisioning.accountId) filterProvisioning.accountId = accountSplit.id;
    this.setState({ dataResponse: null, dataProvisioning: [], isFirstSearch: false });
    searchServiceProvisioningData(
      {
        page: pageProvisioning + 1,
        size: sizeProvisioning,
        filter: filterProvisioning,
        sort: !isEmpty(sorted) ? provisioningDataSort[sorted.sortCol][sorted.sortDir] : null,
      },
      resp => {
        const newResp = resp;
        const flexAttrsList = {};
        if (
          newResp.success &&
          newResp.data &&
          newResp.data.types &&
          newResp.data.types.length &&
          newResp.data.types[0].flexAttrMapping
        ) {
          newResp.data.types[0].flexAttrMapping.forEach(val => {
            flexAttrsList[val.flexAttribute] = val.tag;
          });
        }
        if (newResp.data && newResp.data.provisioning && newResp.data.provisioning[0]) {
          newResp.data.provisioning[0] = { ...newResp.data.provisioning[0], ...flexAttrsList };
          let newDataProvisioning = cloneDeep(newResp.data.provisioning);
          const provisioningSelectRangeData = [];
          const newWithinOrganizationData = cloneDeep(withinOrganizationData);
          if (
            newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
              .provisioningMovementTypes &&
            newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
              .provisioningMovementTypes.provDataMoveType === 'INDIVIDUAL' &&
            newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
              .provisioningMovementTypes.provDataList
          ) {
            newDataProvisioning = cloneDeep(newDataProvisioning).map(val => {
              if (
                newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
                  indexProvisioningSelect
                ].provisioningMovementTypes.provDataList.find(i => i.provisioningId === val.provisioningId)
              ) {
                return { ...val, isSelected: true };
              }
              return { ...val };
            });
          }
          if (
            newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
              .provisioningMovementTypes &&
            newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
              .provisioningMovementTypes.provDataMoveType === 'ALL'
          ) {
            newDataProvisioning = cloneDeep(newDataProvisioning).map(val => {
              return { ...val, isSelected: true };
            });
          }
          // if (
          //   newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
          //     .provisioningMovementTypes &&
          //   newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
          //     .provisioningMovementTypes.provDataMoveType === 'RANGE' &&
          //   newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
          //     .provisioningMovementTypes.provDataList
          // ) {
          //   provisioningSelectRangeData =
          //     newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
          //       indexProvisioningSelect
          //     ].provisioningMovementTypes.provDataList;
          // }
          this.setState({
            dataResponse: newResp.data.provisioning[0],
            dataProvisioning: newDataProvisioning,
            isFirstSearch: false,
            totalCountProvisioning: getPageTotalCount({
              totalCount: totalCountProvisioning,
              size: sizeProvisioning,
              page: pageProvisioning,
              items: newResp.data.provisioning,
            }),
            // provisioningSelectRangeData,
          });
        } else {
          this.setState({
            dataResponse: null,
            totalCountProvisioning: getPageTotalCount({
              totalCount: totalCountProvisioning,
              size: sizeProvisioning,
              page: pageProvisioning,
              items: newResp.data.provisioning,
            }),
            dataProvisioning: [],
            isFirstSearch: false,
          });
        }
      }
    );
  };

  onSortColumnProvisioning = (sortCol, sortDir) => {
    this.setState({ sortedProvisioning: { sortCol, sortDir } }, () => this.doSearchProvisioningData());
  };

  onSizeChangeProvisioning = size => {
    this.setState({ sizeProvisioning: size, page: 0 }, () => this.doSearchProvisioningData());
  };

  onPageChangeProvisioning = page => {
    const { pageProvisioning } = this.state;
    if (pageProvisioning === page) return '';
    this.setState({ pageProvisioning: page }, () => this.doSearchProvisioningData());
  };

  onHandleSubmitProvisioning = filter => {
    const { accountSplit } = this.state;
    const newFilter = filter;
    newFilter.accountId = accountSplit.id;
    this.setState({ filterProvisioning: newFilter }, () => this.doSearchProvisioningData());
  };

  onCloseProvisioning = () => {
    this.setState({ isOpenModalProvisioning: false, itemProvisioningSelect: null, indexProvisioningSelect: null });
  };

  onSelectCheckBox = ({ name, evt }) => {
    const { selectName, dataProvisioning } = this.state;
    let newDataProvisioning = cloneDeep(dataProvisioning);
    if (name === 'selectAll' && evt.target.checked && selectName !== name) {
      newDataProvisioning = newDataProvisioning.map(val => {
        return { ...val, isSelected: true };
      });
    } else {
      newDataProvisioning = newDataProvisioning.map(val => {
        const { isSelected, ...rest } = val;
        return { ...rest };
      });
    }
    if (selectName === name) return this.setState({ selectName: '', dataProvisioning: newDataProvisioning });

    return this.setState({ selectName: name, dataProvisioning: newDataProvisioning });
  };

  onAddRange = () => {
    const { provisioningSelectRangeData, filterProvisioning } = this.state;
    let newData = cloneDeep(provisioningSelectRangeData);
    newData = [
      ...newData,
      {
        startRange: filterProvisioning && filterProvisioning.startRange ? filterProvisioning.startRange : '',
        endRange: filterProvisioning && filterProvisioning.endRange ? filterProvisioning.endRange : '',
      },
    ];
    this.setState({ provisioningSelectRangeData: newData });
  };

  onSelectProvisioningId = ({ evt, item, index }) => {
    const { dataProvisioning } = this.state;
    const newData = cloneDeep(dataProvisioning);
    newData[index].isSelected = evt.target.checked;
    this.setState({ dataProvisioning: newData });
  };

  onSelectProvisioning = () => {
    const {
      dataProvisioning,
      provisioningSelectRangeData,
      indexProvisioningSelect,
      selectName,
      listItems,
      filterProvisioning,
      withinOrganizationData,
      activeSubTab,
    } = this.state;
    const { t } = this.props;
    const newWithinOrganizationData = cloneDeep(withinOrganizationData);

    let provisioningIdSelectedByRange = [];
    let provisioningIdSelected = [];
    if (
      newWithinOrganizationData?.accountServicesSummaries[activeSubTab.index]?.accountsList[indexProvisioningSelect]
        ?.provisioningMovementTypes?.length
    ) {
      const provisioningMovementTypes =
        newWithinOrganizationData?.accountServicesSummaries[activeSubTab.index]?.accountsList[indexProvisioningSelect]
          ?.provisioningMovementTypes;
      const rangeSelected = provisioningMovementTypes.filter(pr => pr.provDataMoveType === 'RANGE');
      const individualSelected = provisioningMovementTypes.filter(pr => pr.provDataMoveType === 'INDIVIDUAL');
      if (individualSelected && individualSelected.length) {
        let provDataList = [];
        individualSelected.forEach(i => {
          provDataList = [...provDataList, ...i.provDataList];
        });
        provisioningIdSelected = [...provisioningIdSelected, ...provDataList.map(i => i.provisioningId)];
      }
      rangeSelected.forEach(i => {
        provisioningIdSelectedByRange = [...provisioningIdSelectedByRange, ...i.provDataList];
      });
      if (provisioningIdSelectedByRange && provisioningIdSelectedByRange.length) {
        const listStartRange = provisioningIdSelectedByRange.map(prs => prs.startRange);
        if (
          provisioningSelectRangeData &&
          provisioningSelectRangeData.length &&
          provisioningSelectRangeData.find(val => listStartRange.indexOf(val.startRange) > -1) &&
          selectName === 'selectRange'
        ) {
          return this.setState({ isOpenModalProvisioning: false });
        }
      }
      if (
        provisioningIdSelected &&
        provisioningIdSelected.length &&
        selectName === 'selectSpecific' &&
        dataProvisioning &&
        dataProvisioning.length
      ) {
        const dataSelected = dataProvisioning
          .filter(val => !!val.isSelected)
          .map(val => {
            const { provisioningId } = val;
            return provisioningId;
          });
        if (dataSelected && dataSelected.length && dataSelected.find(dt => provisioningIdSelected.indexOf(dt) > -1)) {
          return this.setState({ isOpenModalProvisioning: false });
        }
      }
    }

    if (selectName === 'selectAll') {
      if (
        !newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
          .provisioningMovementTypes
      ) {
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
          indexProvisioningSelect
        ].provisioningMovementTypes = [];
      }
      // newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      //   indexProvisioningSelect
      // ].provisioningMovementTypes = newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      //   indexProvisioningSelect
      // ].provisioningMovementTypes.filter(val => val.provDataMoveType !== 'ALL');
      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].provisioningMovementTypes.push({
        type: filterProvisioning.type || 'TRUNK',
        provDataMoveType: 'ALL',
      });
      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].oldProvDataMoveTypeSelect = 'ALL';
    }
    if (selectName === 'selectSpecific') {
      if (
        !newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
          .provisioningMovementTypes
      ) {
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
          indexProvisioningSelect
        ].provisioningMovementTypes = [];
      }
      // newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      //   indexProvisioningSelect
      // ].provisioningMovementTypes = newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      //   indexProvisioningSelect
      // ].provisioningMovementTypes.filter(val => val.provDataMoveType !== 'INDIVIDUAL');
      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].provisioningMovementTypes.push({
        type: filterProvisioning.type || 'TRUNK',
        provDataMoveType: 'INDIVIDUAL',
        provDataList: dataProvisioning
          .filter(val => !!val.isSelected)
          .map(val => {
            const { provisioningId } = val;
            return { provisioningId };
          }),
      });
      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].oldProvDataMoveTypeSelect = 'INDIVIDUAL';
    }
    if (selectName === 'selectRange') {
      if (!provisioningSelectRangeData || !provisioningSelectRangeData.length) {
        return toast.error(t('common:message.useStartEndRange'));
      }
      if (
        !newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[indexProvisioningSelect]
          .provisioningMovementTypes
      ) {
        newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
          indexProvisioningSelect
        ].provisioningMovementTypes = [];
      }

      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].provisioningMovementTypes.push({
        type: filterProvisioning.type || 'DID',
        provDataMoveType: 'RANGE',
        provDataList: provisioningSelectRangeData,
      });
      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].oldProvDataMoveTypeSelect = 'RANGE';
      newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
        indexProvisioningSelect
      ].oldProvisioningSelectRangeData = provisioningSelectRangeData;
    }
    newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      indexProvisioningSelect
    ].oldFilterProvisioning = filterProvisioning;
    newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      indexProvisioningSelect
    ].oldDataProvisioning = dataProvisioning;
    newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList[
      indexProvisioningSelect
    ].oldSelectName = selectName;
    this.setState({
      withinOrganizationData: newWithinOrganizationData,
      // dataProvisioning: [],
      provisioningSelectRangeData: [],
      indexProvisioningSelect: null,
      // selectName: null,
      isOpenModalProvisioning: false,
    });
  };

  onChangeSelectOptions = ({ value }) => {
    if (value === 'UNDO_PREVIOUS_DISPERSE') this.onChangeSwitchWithinUndoPreviousMerge();
    this.setState({ selectOption: value, isUndoPreviousMerge: value === 'UNDO_PREVIOUS_DISPERSE' });
  };

  onSelectPendingMerge = (id, row) => {
    const { withinOrganizationData } = this.state;
    const newData = cloneDeep(withinOrganizationData);
    let accountServicesSummaries = newData?.accountServicesSummaries || [];
    accountServicesSummaries = accountServicesSummaries.map(val => {
      const { accountsList, ...rest } = val;
      return { ...rest, accountsList: [] };
    });
    const { fromAccount, toAccount, effectiveDate, updateServiceCapacityOnMove, notes, savedAccountMovementDataId } =
      row || {};
    if (toAccount && toAccount.length) {
      toAccount.forEach(val => {
        const {
          accountId,
          clientId,
          moveAllServices,
          moveProvisioningAttributes,
          organization,
          billables,
          serviceId,
          serviceName,
          totalPrice,
          quantity,
          servicePrice,
          uom,
          capacity,
          omsInternalId,
          ...rest
        } = val;
        if (billables && billables.length && accountServicesSummaries.length) {
          billables.forEach(bl => {
            const { provisioningMovementTypes } = bl;
            let oldProvisioningSelectRangeData = [];
            if (provisioningMovementTypes && provisioningMovementTypes.length) {
              const provisioningMovementTypesRange = provisioningMovementTypes.filter(
                val => val.provDataMoveType === 'RANGE'
              );
              if (provisioningMovementTypesRange && provisioningMovementTypesRange.length) {
                provisioningMovementTypesRange.forEach(pr => {
                  const { provDataList } = pr;
                  if (provDataList && provDataList.length)
                    oldProvisioningSelectRangeData = [...oldProvisioningSelectRangeData, ...provDataList];
                });
              }
            }
            const indexBillableSelect = accountServicesSummaries.findIndex(id => id.omsInternalId === bl.omsInternalId);
            if (indexBillableSelect !== -1) {
              accountServicesSummaries[indexBillableSelect].isSelected = true;
              if (!accountServicesSummaries[indexBillableSelect].accountsList) {
                accountServicesSummaries[indexBillableSelect].accountsList = [];
              }
              accountServicesSummaries[indexBillableSelect].accountsList.push({
                accountId,
                clientId,
                contacts: [{ organization }],
                serviceId: bl?.updateServiceCapacity === true ? bl.serviceId : null,
                serviceName: bl?.updateServiceCapacity === true ? bl.serviceName : null,
                totalPrice: bl.totalPrice || null,
                omsInternalId,
                capacitySplit: bl?.updateServiceCapacity === true ? 'TRUE' : 'FALSE',
                quantity: bl.quantity || null,
                servicePrice: bl.servicePrice || null,
                provisioningMovementTypes,
                uom: bl.uom || null,
                capacity: bl.capacity || null,
                moveProvisioning: bl.moveProvisioningAttributes === true ? 'TRUE' : 'FALSE',
                oldProvisioningSelectRangeData:
                  oldProvisioningSelectRangeData && oldProvisioningSelectRangeData.length
                    ? oldProvisioningSelectRangeData
                    : null,
              });
            }
          });
        }
      });
    }
    newData.accountServicesSummaries = accountServicesSummaries;
    this.setState({
      effectiveDate: effectiveDate || null,
      isOpenSelectPendingMerge: false,
      notesAccountMovement: notes || null,
      savedAccountMovementDataId: savedAccountMovementDataId || null,
      withinOrganizationData: newData,
    });
  };

  render() {
    const { permissionsCustomer, permissionsRating, t, permissionsBilling, isComponent, labelFromMerge } = this.props;
    const {
      isExpandChild,
      listItems,
      effectiveDate,
      activeSubTab,
      isOpenModalAccept,
      isWithinOrganization,
      isOpenModalBilledService,
      isOpenModalAccountId,
      isMultiAccount,
      isUndoPreviousMerge,
      activeMainTab,
      accountSplit,
      undoPreviousMergeData,
      withinOrganizationData,
      listAccounts,
      isSelectAccountFromBillable,
      activeTab,
      activitiesList,
      isOpenModalProvisioning,
      isSearchingProvisioning,
      sortedProvisioning,
      dataProvisioning,
      selectName,
      activeTabProvisioning,
      pageProvisioning,
      sizeProvisioning,
      totalCountProvisioning,
      provisioningSelectRangeData,
      filterProvisioning,
      notesAccountMovement,
      isOpenSelectPendingMerge,
      selectOption,
      wasValidatedSave,
    } = this.state;
    let modeCreateCustomer = 0;
    let modeSearchAccount = 0;
    let modeGetAccount = 0;

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeCreateCustomer = checkPermissionCreateCustomer({ listPermission });
      modeSearchAccount = checkPermissionSearchAccount({ listPermission });
      modeGetAccount = checkPermissionGetAccount({ listPermission });
    }

    const activityColumns = [
      {
        name: 'select',
        label: 'common:label.select',
        render: (name, item, index) => {
          return (
            <GenericInput
              value={item.isSelected || false}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => this.onSelectAcitity({ name, value, item, index })}
              name="select"
              type="checkbox"
              checked={item.isSelected || false}
            />
          );
        },
      },
      {
        name: 'id',
        label: 'label.activityId',
      },
      {
        name: 'action',
        label: 'common:label.action',
      },
      {
        name: 'activityDate',
        label: 'common:label.activityDate',
        style: { minWidth: '100px' },
      },
    ];

    const lastRange =
      provisioningSelectRangeData && provisioningSelectRangeData[provisioningSelectRangeData.length - 1]
        ? provisioningSelectRangeData[provisioningSelectRangeData.length - 1].endRange
        : 0;

    const newWithinOrganizationData = cloneDeep(withinOrganizationData);
    let provisioningIdSelected = [];
    let provisioningIdSelectedByRange = [];
    let isDisableAddRangeAndCheckCurrentAccount = false;
    if (
      activeSubTab &&
      activeSubTab.index !== -1 &&
      newWithinOrganizationData.accountServicesSummaries
      // &&
      // newWithinOrganizationData.accountServicesSummaries[activeSubTab.index] &&
      // newWithinOrganizationData.accountServicesSummaries[activeSubTab.index].accountsList
    ) {
      newWithinOrganizationData.accountServicesSummaries.forEach(dt => {
        const { accountsList } = dt;
        if (accountsList && accountsList.length) {
          accountsList.forEach(val => {
            const { provisioningMovementTypes } = val;
            if (provisioningMovementTypes && provisioningMovementTypes.length) {
              const individualSelected = provisioningMovementTypes.filter(pr => pr.provDataMoveType === 'INDIVIDUAL');
              const rangeSelected = provisioningMovementTypes.filter(pr => pr.provDataMoveType === 'RANGE');
              if (individualSelected && individualSelected.length) {
                let provDataList = [];
                individualSelected.forEach(i => {
                  provDataList = [...provDataList, ...i.provDataList];
                });
                provisioningIdSelected = [...provisioningIdSelected, ...provDataList.map(i => i.provisioningId)];
              }
              if (rangeSelected && rangeSelected.length) {
                rangeSelected.forEach(i => {
                  provisioningIdSelectedByRange = [...provisioningIdSelectedByRange, ...i.provDataList];
                });
              }
            }
          });
        }
      });
    }
    if (provisioningIdSelectedByRange && provisioningIdSelectedByRange.length) {
      provisioningIdSelectedByRange.forEach(val => {
        if (filterProvisioning?.startRange && filterProvisioning?.endRange && val?.startRange && val?.endRange) {
          if (
            Number.parseFloat(filterProvisioning?.startRange) <= Number.parseFloat(val?.endRange) &&
            Number.parseFloat(filterProvisioning?.startRange) >= Number.parseFloat(val?.startRange)
          ) {
            isDisableAddRangeAndCheckCurrentAccount = true;
          }
          if (
            Number.parseFloat(filterProvisioning?.endRange) <= Number.parseFloat(val?.endRange) &&
            Number.parseFloat(filterProvisioning?.endRange) >= Number.parseFloat(val?.startRange)
          ) {
            isDisableAddRangeAndCheckCurrentAccount = true;
          }
        }
      });
    }
    if (provisioningIdSelected && provisioningIdSelected.length) {
      provisioningIdSelected.forEach(val => {
        if (filterProvisioning?.startRange && filterProvisioning?.endRange && val) {
          if (
            !Number.isNaN(val) &&
            Number.parseFloat(filterProvisioning?.startRange) <= Number.parseFloat(val) &&
            Number.parseFloat(filterProvisioning?.endRange) >= Number.parseFloat(val)
          ) {
            isDisableAddRangeAndCheckCurrentAccount = true;
          }
        }
      });
    }
    const columnsProvisioning = [
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item, index) => {
          let isDisableRangeData = false;
          if (
            provisioningSelectRangeData &&
            provisioningSelectRangeData.length &&
            item.provisioningId &&
            !Number.isNaN(item.provisioningId)
          ) {
            provisioningSelectRangeData.forEach(val => {
              if (
                Number.parseFloat(item.provisioningId) >= Number.parseFloat(val.startRange) &&
                Number.parseFloat(item.provisioningId) <= Number.parseFloat(val.endRange)
              ) {
                isDisableRangeData = true;
              }
            });
          }
          if (
            provisioningIdSelected &&
            provisioningIdSelected.length &&
            provisioningIdSelected.indexOf(item.provisioningId) !== -1
          ) {
            isDisableRangeData = true;
          }

          if (
            provisioningIdSelectedByRange &&
            provisioningIdSelectedByRange.length &&
            item.provisioningId &&
            !Number.isNaN(item.provisioningId)
          ) {
            provisioningIdSelectedByRange.forEach(val => {
              if (
                Number.parseFloat(item.provisioningId) >= Number.parseFloat(val.startRange) &&
                Number.parseFloat(item.provisioningId) <= Number.parseFloat(val.endRange)
              ) {
                isDisableRangeData = true;
              }
            });
          }
          return (
            <CheckField
              name="selectedParentId"
              value={item && item.isSelected}
              checked={item && item.isSelected}
              disabled={selectName !== 'selectSpecific' || isDisableRangeData}
              onChange={evt => this.onSelectProvisioningId({ evt, item, index })}
            />
          );
        },
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'type',
        label: 'common:label.type',
        sortable: true,
      },
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
        sortable: true,
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
        sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
    ];

    const columnsProvisioningSelectRange = [
      {
        name: 'startRange',
        label: t('label.startingNumber'),
        render: (colName, item) => {
          return (
            <GenericInput
              value={item.startRange}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              // label="label.startingNumber"
              name="startRange"
              type="number"
              readOnly
            />
          );
        },
      },
      {
        name: 'endRange',
        label: t('label.endingNumber'),
        render: (colName, item) => {
          return (
            <GenericInput
              value={item.endRange}
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              // label="label.endRange"
              name="endRange"
              type="number"
              readOnly
            />
          );
        },
      },
    ];

    return (
      <div className={isComponent ? 'pb-4' : 'container-fluid'}>
        <div className="row">
          <div className={isComponent ? 'col-md-12 pl-0' : 'content-wrapper'}>
            {!isComponent && (
              <>
                <SidebarIndex isSupportCheckRoute isExpandChild={isExpandChild} keyRoute="accountMovement" />
                <PageTitle
                  linkTo={RouteNames.customers.path}
                  titleBtn={t('common:label.back')}
                  items={[{ name: t('sidebarSearchPage.config.subTitle.disperse') }]}
                />
                <br />
              </>
            )}

            {!isComponent && (
              <div className="col-md-12 mb-30">
                <div className="card card-statistics h-100">
                  <TitleFrom title={t('common:label.billingAccountMovementDisperse')} />
                  <div className="col-md-12 pl-2 pb-3 row">
                    <GenericInput
                      value={accountSplit && accountSplit.id ? accountSplit.id : ''}
                      wrapperClass="col-md-3"
                      onChange={({ name, value }) => {}}
                      name="updateServiceCapacityOnMove"
                      label="label.selectAccountToSplit"
                      fa="fa fa-external-link"
                      onClick={() => {
                        this.setState({ isOpenModalAccountId: true });
                      }}
                    />
                    <GenericInput
                      value={selectOption}
                      wrapperClass="col-md-3"
                      onChange={({ name, value }) => this.onChangeSelectOptions({ value, name })}
                      name="selectOptions"
                      type="select"
                      label="label.selectOptions"
                      tOptions="selections:disperseSelectOptions"
                      menuPortalTarget
                      isNoClearable
                    />
                    {selectOption === 'SELECT_SAVED_MOVEMENT' && (
                      <button
                        type="button"
                        onClick={() => this.setState({ isOpenSelectPendingMerge: true })}
                        className="button mt-4 mr-3 x-small"
                        disabled={!accountSplit.id}
                      >
                        {t('common:label.view')}
                      </button>
                    )}
                    {isWithinOrganization && (
                      <>
                        <GenericInput
                          value={
                            accountSplit &&
                            accountSplit.contacts &&
                            accountSplit.contacts[0] &&
                            accountSplit.contacts[0].organization
                              ? accountSplit.contacts[0].organization
                              : ''
                          }
                          wrapperClass="col-md-3"
                          onChange={({ name, value }) => {}}
                          name="organization"
                          label="label.organization"
                          readOnly
                        />
                        <GenericInput
                          value={accountSplit && accountSplit.commercialName ? accountSplit.commercialName : ''}
                          wrapperClass="col-md-3"
                          onChange={({ name, value }) => {}}
                          name="commercialName"
                          label="label.commercialName"
                          readOnly
                        />
                      </>
                    )}
                    {/* <Switch
                      onChange={this.onChangeSwitchWithinUndoPreviousMerge}
                      name="isUndoPreviousMerge"
                      wrapperClass={`col-md-3 pl-2 pt-4 pr-3 ${
                        isUndoPreviousMerge ? 'switch-active' : 'switch-non-active'
                      }`}
                      title={t('common:label.undoPreviousMerge')}
                      checked={isUndoPreviousMerge}
                      disabled={isWithinOrganization}
                    /> */}
                    <Switch
                      onChange={this.onChangeSwitchWithinOrganization}
                      name="isWithinOrganization"
                      wrapperClass={`col-md-3 pl-2 pt-4 pr-3 ${
                        isWithinOrganization ? 'switch-active' : 'switch-non-active'
                      }`}
                      title={t('common:label.withinOrganization')}
                      checked={isWithinOrganization}
                      disabled={isUndoPreviousMerge}
                    />
                  </div>
                  {!isWithinOrganization && !undoPreviousMergeData && (
                    <div className="card-body">
                      <div className="repeater-file">
                        <div>
                          <SplitSearchForm onSubmit={this.onHandleSubmit} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="col-sm-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body group-collapsible">
                  {isUndoPreviousMerge && (
                    <div>
                      <DataTable columns={activityColumns} data={activitiesList || []} />
                      <br />
                      <div className="col-md-12 row">
                        {inputFields.map(input => {
                          return (
                            <GenericInput
                              value={
                                // eslint-disable-next-line no-nested-ternary
                                input.inputType === 'boolean'
                                  ? undoPreviousMergeData[input.name]
                                    ? 'TRUE'
                                    : 'FALSE'
                                  : undoPreviousMergeData[input.name] || ''
                              }
                              wrapperClass="col-md-4"
                              onChange={({ value }) => this.setState({ moveAllServices: value })}
                              {...input}
                            />
                          );
                        })}
                      </div>
                      {collapsibleSplitMenu.map(item => {
                        return (
                          <FormCollapse
                            key={item.title}
                            label="First Name"
                            isActive={activeMainTab && item.state === activeMainTab.name && activeMainTab.isActive}
                            title={t(item.title) || ''}
                            state={item.state}
                            onToggleTab={this.onToggleMainTab}
                          >
                            {this.renderItem(item)}
                          </FormCollapse>
                        );
                      })}
                    </div>
                  )}
                  {!isUndoPreviousMerge && (
                    <div>
                      {collapsibleMenuPreviousMerge &&
                        collapsibleMenuPreviousMerge.length > 0 &&
                        collapsibleMenuPreviousMerge.map(item => {
                          return (
                            <FormCollapse
                              key={item.title}
                              label="First Name"
                              isActive={activeTab && activeTab.find(val => val.name === item.state)}
                              title={t(item.title) || ''}
                              state={item.state}
                              onToggleTab={this.onToggleTab}
                            >
                              {this.renderPreviousMergeItem(item)}
                            </FormCollapse>
                          );
                        })}
                    </div>
                  )}
                  <div className="col-md-12 row mt-3 pr-0">
                    <form
                      noValidate
                      className={`col-md-12 row needs-validation ${wasValidatedSave ? 'was-validated' : ''}`}
                    >
                      {(selectOption === 'SELECT_SAVED_MOVEMENT' || selectOption === 'NEW_DISPERSE') && (
                        <GenericInput
                          value={notesAccountMovement}
                          label="label.notes"
                          name="notesAccountMovement"
                          wrapperClass="col-md-5"
                          type="textarea"
                          required={wasValidatedSave}
                          onChange={({ value }) => this.setState({ notesAccountMovement: value })}
                        />
                      )}
                      {(selectOption === 'SELECT_SAVED_MOVEMENT' || selectOption === 'NEW_DISPERSE') && (
                        <button
                          type="button"
                          onClick={() => this.onHandleSubmitSplit({ isSave: true })}
                          className="button mt-4 mb-3 x-small"
                          disabled={
                            ((!undoPreviousMergeData || !undoPreviousMergeData.fromAccount) &&
                              !withinOrganizationData.accountServicesSummaries) ||
                            (withinOrganizationData.accountServicesSummaries &&
                              !withinOrganizationData.accountServicesSummaries.find(val => !!val.isSelected))
                          }
                        >
                          {t('common:label.save')}
                        </button>
                      )}
                      <button
                        type="button"
                        disabled={
                          ((!undoPreviousMergeData || !undoPreviousMergeData.fromAccount) &&
                            !withinOrganizationData.accountServicesSummaries) ||
                          (withinOrganizationData.accountServicesSummaries &&
                            !withinOrganizationData.accountServicesSummaries.find(val => !!val.isSelected))
                        }
                        onClick={this.onHandleSubmitSplit}
                        className={`button mt-4 mb-3 x-small float-right ${
                          selectOption === 'UNDO_PREVIOUS_DISPERSE' ? 'ml-auto mr-0' : ''
                        }`}
                      >
                        {labelFromMerge || t('common:label.disperse')}
                      </button>
                    </form>
                    {/* <div className="col-md-6 pr-0">
                      <button
                        type="button"
                        disabled={
                          ((!undoPreviousMergeData || !undoPreviousMergeData.fromAccount) &&
                            !withinOrganizationData.accountServicesSummaries) ||
                          (withinOrganizationData.accountServicesSummaries &&
                            !withinOrganizationData.accountServicesSummaries.find(val => !!val.isSelected))
                        }
                        onClick={this.onHandleSubmitSplit}
                        className="button mt-4 x-small float-right"
                      >
                        {labelFromMerge || t('common:label.disperse')}
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {!isComponent && <Footer className="footer-bottom" />}
          </div>
        </div>
        <ModalSearchItemServices
          isOpen={isOpenModalBilledService}
          onSelect={this.onHandleChangeSelectSplitService}
          title={t('common:label.selectCapacityMergeBillableService')}
          onCancel={() => {
            this.setState({ isOpenModalBilledService: false });
          }}
          isChangeItem
        />
        {isMultiAccount ? (
          <ModalMultipleAccountIds
            isOpen={isOpenModalAccountId}
            onSelect={this.onSelectAccountId}
            onCancel={() => {
              this.setState({ isOpenModalAccountId: false });
            }}
            columns={accountColumns}
            defaultSearchParams={{ type: 'DIRECT_CUSTOMER' }}
          />
        ) : (
          <ModalAccountId
            isOpen={isOpenModalAccountId || isSelectAccountFromBillable}
            onSelect={this.onSelectAccountId}
            onCancel={() => {
              this.setState({
                isOpenModalAccountId: false,
                isSelectAccountFromBillable: false,
                indexSelectAccountFromBillable: null,
              });
            }}
            columns={accountColumns}
            defaultSearchParams={{ type: 'DIRECT_CUSTOMER' }}
          />
        )}
        <ModalWithItem
          modalTitle={t('customerPage:sidebarHandleAccountPage.provisioningData')}
          isOpen={isOpenModalProvisioning}
          onToggle={this.onCloseProvisioning}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        >
          <ProvisioningDataSearchForm
            onSubmit={this.onHandleSubmitProvisioning}
            defaultFilter={{ status: 'ACTIVE', ...filterProvisioning }}
          />
          <br />
          <div className="col-md-12 d-flex">
            <CheckField
              name="selectAll"
              id="selectAll"
              checked={selectName === 'selectAll'}
              label={t('common:label.selectAll')}
              onChange={evt => this.onSelectCheckBox({ name: 'selectAll', evt })}
            />
            <CheckField
              name="selectSpecific"
              id="selectSpecific"
              checked={selectName === 'selectSpecific'}
              label={t('common:label.selectSpecific')}
              onChange={evt => this.onSelectCheckBox({ name: 'selectSpecific', evt })}
            />
            <CheckField
              name="selectRange"
              id="selectRange"
              checked={selectName === 'selectRange'}
              label={t('common:label.selectRange')}
              onChange={evt => this.onSelectCheckBox({ name: 'selectRange', evt })}
              disabled={!filterProvisioning || filterProvisioning.type !== 'DID'}
            />
            <button
              type="button"
              onClick={this.onAddRange}
              className="button x-small ml-3 float-right"
              disabled={
                selectName !== 'selectRange' ||
                !filterProvisioning.startRange ||
                !filterProvisioning.endRange ||
                Number.parseFloat(filterProvisioning.startRange) <= Number.parseFloat(lastRange) ||
                dataProvisioning.length < 1 ||
                isDisableAddRangeAndCheckCurrentAccount
              }
            >
              {t('common:label.addRangeSelected')}
            </button>
          </div>
          <br />
          <div>
            {selectName === 'selectRange' &&
              collapsibleMenuProvisioning.map(item => (
                <FormCollapse
                  key={item.title}
                  label="First Name"
                  isActive={
                    activeTabProvisioning && item.state === activeTabProvisioning.name && activeTabProvisioning.isActive
                  }
                  title={t(item.title) || ''}
                  state={item.state}
                  onToggleTab={this.onToggleTabProvisioning}
                >
                  <div className="col-md-12 row mt-3">
                    <DataTable columns={columnsProvisioningSelectRange} data={provisioningSelectRangeData || []} />
                  </div>
                </FormCollapse>
              ))}
          </div>
          <br />
          <div>
            <DataTable
              columns={columnsProvisioning}
              data={dataProvisioning || []}
              isLoading={isSearchingProvisioning}
              sorted={sortedProvisioning}
              onSort={this.onSortColumnProvisioning}
            />
          </div>
          <div className="col-sm-12">
            <TablePagination
              pageNumber={pageProvisioning}
              pageSize={sizeProvisioning}
              totalCount={totalCountProvisioning}
              onPageChange={this.onPageChangeProvisioning}
              onSizeChange={this.onSizeChangeProvisioning}
            />
          </div>
          <div className="text-right">
            <button type="button" className="btn btn-secondary mr-2" onClick={this.onCloseProvisioning}>
              {t('common:label.cancel')}
            </button>
            <button type="button" className="btn btn-success" onClick={this.onSelectProvisioning}>
              {t('common:label.select')}
            </button>
          </div>
        </ModalWithItem>
        <ModalSelectPendingMerge
          isOpen={isOpenSelectPendingMerge}
          onSelect={this.onSelectPendingMerge}
          onCancel={() => {
            this.setState({ isOpenSelectPendingMerge: false });
          }}
          headerTitle={t('common:label.savedPendingDisperseRequest')}
          defaultSearchParams={{ type: 'DISPERSE', accountId: accountSplit.id }}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  listCustomers: makeGetListCustomers() || [],
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsBilling: makeGetPermissionsBilling() || {},
});

Split.propTypes = {
  listCustomers: PropTypes.arrayOf(PropTypes.any),
  permissionsCustomer: PropTypes.objectOf(PropTypes.any),
  permissionsRating: PropTypes.objectOf(PropTypes.any),
  getCustomers: PropTypes.func,
  getParentAccountDetail: PropTypes.func.isRequired,
};

Split.defaultProps = {
  listCustomers: {},
  permissionsCustomer: {},
  permissionsRating: {},
  getCustomers: () => {},
};

const newMerge = connect(mapStateToProps, {
  getCustomers,
  setParams,
  getParentAccountDetail,
  getOrderSummaryByAccountId,
  updateBillingAccount,
  getPreviousAccountMerge,
  getCustomerActivity,
  getPreviousAccountMovement,
  searchServiceProvisioningData,
  saveAccountMovementData,
  getAccountDetail,
})(Split);

export default withTranslation(['customerPage', 'common', 'selections'])(newMerge);
