import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['index']);
  const createProductFamilyConfig = `createProductFamilyConfig(input: ${dataCreate})`;

  return gql`
  mutation {
    ${createProductFamilyConfig} {
      id
    }
  }
`;
};
