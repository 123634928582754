import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';
import itemNS from './constants/revenueNS';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, itemNS)}` : '';
  const searchRevenueMilestone = `searchRevenueMilestone(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchRevenueMilestone} {
      id
      name
      description
      productFamily
      productLine
      productType
      productSubType
      currency
      country
      status
      type
      unit
      offsetval
      milestones {
        index
        percent
        manual
        frequency
      }
    }
  }
  `;
};
