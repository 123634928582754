import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TitleFrom from '../../common/TitleFrom';
import { GenericInput } from '../../common';
import RouteNames from '../../../containers/App/RouteNames';

// const fieldDisable = ['amount', 'accountId', 'percent', 'taxRule'];
const fieldDisable = ['amount', 'percent', 'taxRule'];

const WriteOffForm = ({
  title,
  listFields,
  data,
  onChange,
  isApply,
  onBlurAmount,
  onHandleSubmit,
  onBlur,
  isAccountWriteOff,
  onChangeSwitch,
  modeProcessWriteoff,
  modeProcessTaxWriteoff,
  onClick,
  linkTo,
  modeGetArOpsTaxOptions,
  onCancel,
}) => {
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);
  const { t } = useTranslation('common');
  const newListFields = cloneDeep(listFields);
  const indexArType = newListFields.findIndex(item => item.name === 'type');
  if (modeProcessTaxWriteoff === 0 && indexArType > -1) {
    newListFields[indexArType].options = newListFields[indexArType].options.filter(val => val.value !== 'TAX');
  }
  if (modeProcessTaxWriteoff === 1 && indexArType > -1) {
    const indexOptionTax = newListFields[indexArType].options.findIndex(val => val.value === 'TAX');
    newListFields[indexArType].options[indexOptionTax].isDisabled = true;
  }
  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    return onHandleSubmit(evt);
  };
  return (
    <div className="card card-statistics form-with-item">
      <div className="d-block d-md-flex justify-content-between">
        <div className="d-block d-md-flex clearfix sm-mt-20">
          <TitleFrom title={title} />
        </div>
      </div>
      {/* <div className="form-group col-md-12">
        <div className="d-block d-md-flex clearfix sm-mt-20">
          <TitleFrom title={t('label.unpaidBills')} />
        </div>
        <DataTable
          columns={tableColumns}
          data={invoiceData}
          noDataMessage={messageErrorTable}
          onClickRow={(e, item) => onClickRowTable({ invoice: item })}
          getRowClass={item => {
            return data.invoiceId === item.id ? 'row_active' : '';
          }}
        />
      </div> */}
      <div className="form-group col-md-12 p-3">
        <div className="checkbox checbox-switch switch-danger">
          <label htmlFor="switch">
            <b>{t('label.accountWriteoff')}</b>
            <input id="switch" type="checkbox" name="switch21" onChange={onChangeSwitch} checked={isAccountWriteOff} />
            <span className="label-switch" />
          </label>
        </div>
      </div>
      <div className="form-group col-md-12 p-2">
        <form
          ref={formRef}
          noValidate
          className={`col-md-12 d-flex group-flex needs-validation ${wasValidated ? 'was-validated' : ''}`}
          // onSubmit={onHandleSubmit}
          onSubmit={onSubmit}
        >
          {newListFields.map(item => {
            if (!isEmpty(item.options) || item.tOptions) {
              return (
                <GenericInput
                  key={item.label}
                  label={item.label || ''}
                  placeholder={item.placeholder}
                  value={data[item.name]}
                  type="select"
                  name={item.name}
                  onChange={onChange}
                  wrapperClass="col-md-4"
                  disabled={
                    item.disabled ||
                    (isAccountWriteOff && fieldDisable.indexOf(item.name) > -1) ||
                    (modeGetArOpsTaxOptions !== 2 && item.name === 'taxRule')
                  }
                  {...item}
                />
              );
            }

            if (item.type === 'money') {
              return (
                <GenericInput
                  key={item.label}
                  wrapperClass="col-md-4"
                  label={item.label || ''}
                  value={data[item.name]}
                  type="number"
                  // thousandSeparator
                  // placeholder="0.00"
                  // prefix="$ "
                  onChange={values => onChange({ name: item.name, value: values.value })}
                  // onBlur={onBlurAmount}
                  disabled={
                    item.disabled ||
                    (isAccountWriteOff && fieldDisable.indexOf(item.name) > -1) ||
                    (item.name === 'amount' && data.percent)
                  }
                />
              );
            }

            if (item.type === 'number') {
              return (
                <GenericInput
                  key={item.label}
                  wrapperClass="col-md-4"
                  label={item.label || ''}
                  value={data[item.name]}
                  type="number"
                  // thousandSeparator
                  // placeholder="0.00"
                  // prefix="$ "
                  onChange={values => onChange({ name: item.name, value: values.value })}
                  // onBlur={onBlurAmount}
                  onBlur={evt => onBlur(evt)}
                  disabled={
                    item.disabled ||
                    (isAccountWriteOff && fieldDisable.indexOf(item.name) > -1) ||
                    (item.name === 'percent' && data.amount)
                  }
                />
              );
              // return (
              //   <div className="form-group col-md-4" key={item.label}>
              //     <span>{t(item.label)}</span>
              //     <input
              //       className="form-control input-group"
              //       name={item.name}
              //       type={item.type}
              //       value={data[item.name] || ''}
              //       onBlur={evt => onBlur(evt)}
              //       onChange={evt => onChange({ name: item.name, value: evt.target.value })}
              //       disabled={
              //         item.disabled ||
              //         (isAccountWriteOff && fieldDisable.indexOf(item.name) > -1) ||
              //         (item.name === 'percent' && data.amount)
              //       }
              //     />
              //   </div>
              // );
            }

            return (
              <GenericInput
                key={item.label}
                placeholder={item.placeholder}
                value={(!isEmpty(data) && data[item.name])}
                type={`${item.type ? item.type : 'text'}`}
                disabled={item.disabled || (isAccountWriteOff && fieldDisable.indexOf(item.name) > -1)}
                onChange={onChange}
                onBlur={evt => onBlur(evt)}
                onClick={() => onClick(item.name)}
                wrapperClass={`${item.wrapperClass ? item.wrapperClass : 'col-md-4'}`}
                fa={item.isPopup || item.fa ? 'fa fa-external-link' : ''}
                {...item}
              />
            );
          })}
          {isApply && (
            <div className="col-md-12 mt-3">
              <button
                type="submit"
                disabled={modeProcessWriteoff === 1}
                className="button button-border x-small float-right"
              >
                {t('label.apply')}
              </button>
              {onCancel ? (
                <button
                  type="button"
                  onClick={onCancel}
                  className="button button-border black x-small mr-2 float-right"
                >
                  {t('label.cancel')}
                </button>
              ) : (
                <Link
                  to={linkTo || RouteNames.arOperationsWriteOffs.path}
                  className="button button-border black x-small mr-2 float-right"
                >
                  {t('label.cancel')}
                </Link>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

WriteOffForm.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onBlurAmount: PropTypes.func,
  onBlur: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  onChangeSwitch: PropTypes.func,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  listFields: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any),
  isApply: PropTypes.bool,
  isAccountWriteOff: PropTypes.bool,
  modeProcessWriteoff: PropTypes.number,
  modeProcessTaxWriteoff: PropTypes.number,
  modeGetArOpsTaxOptions: PropTypes.number,
  linkTo: PropTypes.string,
};

WriteOffForm.defaultProps = {
  title: '',
  listFields: [],
  data: {},
  onChange: () => {},
  onBlur: () => {},
  onBlurAmount: () => {},
  onHandleSubmit: () => {},
  onClick: () => {},
  onChangeSwitch: () => {},
  onCancel: () => {},
  isApply: false,
  isAccountWriteOff: false,
  modeProcessWriteoff: 2,
  modeProcessTaxWriteoff: 2,
  modeGetArOpsTaxOptions: 2,
  linkTo: '',
};
export default WriteOffForm;
