/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectInvoiceId } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import { InvoiceSearchForm, InvoiceSearchFormAdditionalFilters } from '../../PaymentHub';

class ModalInvoice extends PureComponent {
  searchHanldeInvoiceId = (payload, cb) => {
    const { selectInvoiceId, accountId, isNoSetDefaultDue, filterInstallmentInvoices } = this.props;
    const newPayload = { ...payload };
    if (newPayload.filter) {
      if (accountId) newPayload.filter.accountId = accountId || '';
      if (filterInstallmentInvoices) newPayload.filter.filterInstallmentInvoices = filterInstallmentInvoices || null;
      if (!isNoSetDefaultDue) newPayload.filter.due = 0;
    }
    selectInvoiceId(newPayload, resp => {
      cb(resp);
    });
  };

  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'accountId',
          label: 'common:label.accountId',
        },
        {
          name: 'type',
          label: 'common:label.type',
        },
        {
          name: 'status',
          label: 'common:label.status',
        },
        {
          name: 'invoiceType',
          label: 'common:label.invoiceType',
        },
        {
          name: 'invoiceDate',
          label: 'common:label.invoiceDate',
        },
        {
          name: 'dueDate',
          label: 'common:label.dueDate',
        },
        {
          name: 'due',
          label: 'common:label.due',
        },
      ],
    };
    const {
      isOpen,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      isAdditionalFilters,
      disabledSearchFields,
      fieldDisableCheck,
    } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectInvoice')}
        isOpen={isOpen}
        SearchForm={
          isAdditionalFilters
            ? props => <InvoiceSearchFormAdditionalFilters {...props} disabledSearchFields={disabledSearchFields} />
            : InvoiceSearchForm
        }
        searchItems={this.searchHanldeInvoiceId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        fieldDisableCheck={fieldDisableCheck}
      />
    );
  }
}

ModalInvoice.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  selectInvoiceId: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalInvoice.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectInvoiceId })(ModalInvoice));
