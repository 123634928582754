import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class PaymentSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDateWithRank',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDateWithRank',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.paymentCurrency',
      name: 'sourceCurrency',
      options: [],
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:statusPayment',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.payemntThresholdMoreThan',
      name: 'minThresholdAmount',
      type: 'number',
    },
    {
      label: 'label.payemntThresholdLessThan',
      name: 'maxThresholdAmount',
      type: 'number',
    },
    {
      label: 'label.remainingAmountThresholdMoreThan',
      name: 'minRemainingPaymentThreshold',
      type: 'number',
    },
    {
      label: 'label.remainingAmountThresholdLessThan',
      name: 'maxRemainingPaymentThreshold',
      type: 'number',
    },
    {
      label: 'label.paymentSource',
      name: 'paymentSource',
      // options: [],
      // isClearable: true,
      // type: 'select',
    },
    {
      label: 'common:label.fileName',
      name: 'fileName',
    },
    {
      label: 'label.paymentTransactionType',
      name: 'paymentTransactionType',
      tOptions: 'selections:paymentTransactionType',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.clientId',
      name: 'clientId',
    },
    {
      label: 'label.paymentReferenceId',
      name: 'referenceId',
    },
    {
      label: 'common:label.invoiceId',
      name: 'invoiceUnitId',
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      type: 'select',
      tOptions: 'selections:paymentMethod',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.folioStatus',
      name: 'folioStatus',
      tOptions: 'selections:folioStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.useExchangeRate',
      name: 'useExchangeRate',
      type: 'number',
    },
    {
      name: 'paymentSourceId',
      label: 'common:label.paymentSourceId',
    },
    {
      name: 'groupByPaymentSource',
      label: 'common:label.groupByPaymentSource',
      type: 'checkbox',
      wrapperClass: 'form-group mt-4 pt-2',
    },
  ];

  btnSpan = 'col-md-12';
}

PaymentSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

PaymentSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(PaymentSearchForm);
