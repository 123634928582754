import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { queryRequest, mutationRequest } from 'utils/request';
import { toast } from 'react-toastify';
import {
  getPaymentConfigByType,
  createPaymentConfig,
  modifyPaymentConfig,
  searchPayment,
  reversePayment,
  searchPaymentSuspense,
  searchInvoiceUnits,
  applyPayment,
  applyPaymentSuspense,
  viewListCustomerOnly,
  allocatePayment,
  modifyPaymentSuspense,
  getConfigPaymentSurcharge,
  createConfigPaymentSurcharge,
  modifyConfigPaymentSurcharge,
  searchBatchPayment,
  getBanamexPaymentByFileName,
  getBanortePaymentByFileName,
  getBancomerPaymentByFileName,
  getSantanderPaymentByFileName,
  processBatchPayment,
  getBatchPaymentFileConfig,
  modifyBatchPaymentFileConfig,
  createBatchPaymentFileConfig,
  getPaymentItemMap,
  modifyPaymentItemMap,
  createPaymentItemMap,
  getCrossCurrencyPaymentDetails,
  deletePaymentFile,
  getPaymentsReport,
  getPaymentSuspenseReport,
  resendPaymentVendor,
  bulkPaymentProcess,
  searchConfigPaymentAllocation,
  modifyConfigPaymentAllocation,
  createConfigPaymentAllocation,
  getConfigPymtAllocSeqById,
} from 'api';
import { resetNotification, setNotification } from 'containers/Notification/actions';
import { NotificationTypes } from 'constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { mutationRequest as mutationRequestJob } from '../../utils/reuqestJob';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* searchAccountsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, viewListCustomerOnly({ page, size, filter, sort }));
    if (response.searchAccounts) {
      yield put(actions.searchAccountsSuccess(response.searchAccounts));
    } else {
      yield put(actions.searchAccountsSuccess([]));
      yield put(actions.searchAccountsFailure('Failed to fetch accounts. Please try again or check your network!'));
    }
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchAccounts });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAccountsFailure('Failed to fetch accounts. Please try again or check your network!'));
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getPaymentConfigTypeSaga({ payload, cb }) {
  yield put(isConnecting());

  try {
    const response = yield call(queryRequest, getPaymentConfigByType(payload));

    if (response) {
      if (cb) cb({ success: true, response: response.getPaymentConfigByType });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* createPaymentConfigMerchantSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, createPaymentConfig(payload));
    if (response && response.createPaymentConfig) {
      if (cb) cb({ success: true });
      toast.success(i18n.t('common:apiMessage.createPaymentConfigSuccessfully'));
    }

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* modifyPaymentConfigMerchantSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, modifyPaymentConfig(payload));
    yield put(isEndConnected());
    if (response && response.modifyPaymentConfig) {
      if (cb) cb({ success: true });
      toast.success(i18n.t('common:apiMessage.modifyPaymentConfigSuccessfully'));
    } else {
      cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* createPaymentConfigMethodSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, createPaymentConfig(payload));
    if (response && response.createPaymentConfig) {
      if (cb) cb({ success: true, response: response.createPaymentConfig });
      toast.success(i18n.t('common:apiMessage.createPaymentConfigSuccessfully'));
    } else if (cb) cb({ success: false });

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* modifyPaymentConfigMethodSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, modifyPaymentConfig(payload));
    yield put(isEndConnected());
    if (response && response.modifyPaymentConfig) {
      if (cb) cb({ success: true, response: response.modifyPaymentConfig });
      toast.success(i18n.t('common:apiMessage.modifyPaymentConfigSuccessfully'));
    } else if (cb) cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* createPaymentConfigTermSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, createPaymentConfig(payload));
    if (response && response.createPaymentConfig) {
      if (cb) cb({ success: true, response: response.createPaymentConfig });
      toast.success(i18n.t('common:apiMessage.createPaymentConfigSuccessfully'));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* modifyPaymentConfigTermSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, modifyPaymentConfig(payload));
    if (response && response.modifyPaymentConfig) {
      yield cb({ success: true, response: response.modifyPaymentConfig });
      toast.success(i18n.t('common:apiMessage.modifyPaymentConfigSuccessfully'));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

// ------ search payment
export function* searchPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchPayment({ page, size, filter, sort }));
    if (response.searchPayment) {
      yield put(actions.searchPaymentsSuccess(response.searchPayment));
    } else {
      yield put(actions.searchPaymentsSuccess([]));
      yield put(actions.searchPaymentsFailure('Failed to fetch payments. Please try again or check your network!'));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchPaymentsFailure('Failed to fetch payments. Please try again or check your network!'));
    if (cb) cb();
  }
}

// reverse payment
export function* reversePaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, reversePayment(payload));
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.paymentReversedSuccessfully'));
    if (cb) cb({ success: true, data: response.reversePayment });
  } catch (err) {
    yield put(isEndConnected());
  }
  if (cb) cb({ success: false });
}

// search Payment Suspense
export function* searchPaymentSuspenseSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchPaymentSuspense({ page, size, filter, sort }));
    if (response.searchPaymentSuspense) {
      yield put(actions.searchPaymentSuspenseSuccess(response.searchPaymentSuspense));
    } else {
      yield put(actions.searchPaymentSuspenseSuccess([]));
      yield put(
        actions.searchPaymentSuspenseFailure(
          'Failed to fetch payments susepense. Please try again or check your network!'
        )
      );
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      actions.searchPaymentSuspenseFailure(
        'Failed to fetch payments susepense. Please try again or check your network!'
      )
    );
    if (cb) cb();
  }
}

//

export function* applyPaymentSuspenseSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());

  try {
    const response = yield call(mutationRequest, applyPaymentSuspense(payload));
    if (response && response.applyPaymentSuspense) {
      yield put(isEndConnected());
      toast('Apply payment suspense successfully!', { type: toast.TYPE.SUCCESS });
      if (cb) cb({ success: true, data: response.applyPaymentSuspense });
    } else {
      toast('Apply payment suspense failed!', { type: toast.TYPE.ERROR });
      if (cb) cb({ success: false });
    }
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
    // toast(`Apply payment suspense failed! ${err}`, { type: toast.TYPE.ERROR });
  }
}
// ------ search invoices
export function* searchInvoicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchInvoiceUnits({ page, size, filter, sort }));
    if (response.searchInvoiceUnits) {
      yield put(actions.searchInvoicesSuccess(response.searchInvoiceUnits));
    } else {
      yield put(actions.searchInvoicesSuccess([]));
      yield put(actions.searchInvoicesFailure('Failed to fetch invoices. Please try again or check your network!'));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchInvoicesFailure('Failed to fetch invoices. Please try again or check your network!'));
    if (cb) cb();
  }
}

export function* applyPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, applyPayment(payload));
    if (response && response.applyPayment) {
      if (cb) cb({ success: true, data: response.applyPayment });
      yield put(isEndConnected());
      if (response.applyPayment.paymentSuspenseId) {
        toast.success(i18n.t('common:apiMessage.paymentHasGoneToSuspense'));
      } else {
        toast.success(i18n.t('common:apiMessage.paymentIsSuccessful'));
      }
    }
  } catch (err) {
    yield put(isEndConnected());
    cb(false);
    // toast(`Apply payment failed! ${err}`, { type: toast.TYPE.ERROR });
  }
}

export function* allocatePaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(mutationRequest, allocatePayment(payload));
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.paymentAllocatedSuccessful'));
    if (cb) cb({ success: true, data: response.allocatePayment });
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Payment allocated failed${err}`, { type: toast.TYPE.SUCCESS });
  }
  if (cb) cb({ success: false });
}

export function* modifiedPaymentSuspenseSaga({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());

  try {
    const response = yield call(mutationRequest, modifyPaymentSuspense(payload));
    if (response && response.modifyPaymentSuspense) {
      yield put(isEndConnected());
      toast.success(i18n.t('common:apiMessage.modifyPaymentSuspenseSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyPaymentSuspense });
    } else if (cb) cb({ success: false });
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getPaymentSurchargeConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigPaymentSurcharge());
    if (response.getConfigPaymentSurcharge) {
      yield put(actions.getPaymentSurchargeConfigSuccess(response.getConfigPaymentSurcharge));
      if (cb) cb({ success: true, data: response.getConfigPaymentSurcharge });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyPaymentSurchargeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigPaymentSurcharge(payload));
    if (response.modifyConfigPaymentSurcharge) {
      if (cb) cb({ success: true, data: response.modifyConfigPaymentSurcharge });
      toast.success(i18n.t('common:apiMessage.modifyPaymentSurchargeSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createPaymentSurchargeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigPaymentSurcharge(payload));
    if (response.createConfigPaymentSurcharge) {
      if (cb) cb({ success: true, data: response.createConfigPaymentSurcharge });
      toast.success(i18n.t('common:apiMessage.createPaymentSurchargeSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchBatchPaymentProcessSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchBatchPayment(payload));
    if (response.searchBatchPayment) {
      yield put(actions.searchBatchPaymentProcessSuccess(response.searchBatchPayment));
      if (cb) cb({ success: true, data: response.searchBatchPayment });
    } else {
      yield put(actions.searchBatchPaymentProcessSuccess([]));
      if (cb) cb({ success: true, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getBatchPaymentDetaisSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    let response = null;
    const { paymentType, fileName } = payload;
    switch (paymentType) {
      case 'BANAMEX':
        response = yield call(queryRequest, getBanamexPaymentByFileName(fileName));
        response = response.getBanamexPaymentByFileName;
        break;
      case 'BANCOMER':
        response = yield call(queryRequest, getBancomerPaymentByFileName(fileName));
        response = response.getBancomerPaymentByFileName;
        break;
      case 'BANORTE':
        response = yield call(queryRequest, getBanortePaymentByFileName(fileName));
        response = response.getBanortePaymentByFileName;
        break;
      case 'SANTANDER':
        response = yield call(queryRequest, getSantanderPaymentByFileName(fileName));
        response = response.getSantanderPaymentByFileName;
        break;
      default:
      // return response;
      // code block
    }
    if (response) {
      if (cb) cb({ success: true, data: response });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* processBatchPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, processBatchPayment(payload));
    if (response.processBatchPayment) {
      if (cb) cb({ success: true, data: response.processBatchPayment });
      toast.success(i18n.t('common:apiMessage.processBatchPaymentSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Payment Batch File
export function* getBatchPaymentFileConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBatchPaymentFileConfig());
    if (response.getBatchPaymentFileConfig) {
      yield put(actions.getBatchPaymentFileConfigSuccess(response.getBatchPaymentFileConfig));
      if (cb) cb({ success: true, data: response.getBatchPaymentFileConfig });
    } else {
      if (cb) cb({ success: true, data: [] });
      yield put(actions.getBatchPaymentFileConfigSuccess({}));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyBatchPaymentFileConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyBatchPaymentFileConfig(payload));
    if (response.modifyBatchPaymentFileConfig) {
      if (cb) cb({ success: true, data: response.modifyBatchPaymentFileConfig });
      toast.success(i18n.t('common:apiMessage.modifyBatchPaymentSuccessfully'));
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createBatchPaymentFileConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createBatchPaymentFileConfig(payload));
    if (response.createBatchPaymentFileConfig) {
      if (cb) cb({ success: true, data: response.createBatchPaymentFileConfig });
      toast.success(i18n.t('common:apiMessage.createBatchPaymentSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Payment Item Map
export function* getPaymentItemMapSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentItemMap());
    if (response.getPaymentItemMap) {
      yield put(actions.getPaymentItemMapSuccess(response.getPaymentItemMap));
      if (cb) cb({ success: true, data: response.getPaymentItemMap });
    } else {
      if (cb) cb({ success: true, data: [] });
      yield put(actions.getPaymentItemMapSuccess({}));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyPaymentItemMapSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyPaymentItemMap(payload));
    if (response.modifyPaymentItemMap) {
      if (cb) cb({ success: true, data: response.modifyPaymentItemMap });
      toast.success(i18n.t('common:apiMessage.modifyItemMapSuccessfully'));
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createPaymentItemMapSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createPaymentItemMap(payload));
    if (response.createPaymentItemMap) {
      if (cb) cb({ success: true, data: response.createPaymentItemMap });
      toast.success(i18n.t('common:apiMessage.createItemMapSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getCrossCurrencyPaymentDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCrossCurrencyPaymentDetails(payload));
    if (response.getCrossCurrencyPaymentDetails) {
      if (cb) cb({ success: true, data: response.getCrossCurrencyPaymentDetails });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* deletePaymentFileSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, deletePaymentFile(payload));
    if (response.deletePaymentFile) {
      if (cb) cb({ success: true, data: response.deletePaymentFile });
      toast.success(i18n.t('common:apiMessage.deletePaymentFileSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// ------ search paymentReport
export function* searchPaymentReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getPaymentsReport({ page, size, filter, sort }));
    if (response.getPaymentsReport) {
      yield put(actions.searchPaymentsSuccess(response.getPaymentsReport));
    } else {
      yield put(actions.searchPaymentsSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

// ------ search PaymentSuspense
export function* searchPaymentSuspenseReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getPaymentSuspenseReport({ page, size, filter, sort }));
    if (response.getPaymentSuspenseReport) {
      yield put(actions.searchPaymentSuspenseSuccess(response.getPaymentSuspenseReport));
    } else {
      yield put(actions.searchPaymentSuspenseSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* resendPaymentVendorPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, resendPaymentVendor(payload));
    if (response.resendPaymentVendor) {
      toast.success(i18n.t('common:apiMessage.resendPaymentVendorSuccessfully'));
      if (cb) cb({ success: true, data: response.resendPaymentVendor });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* processBatchPaymentProxySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequestJob, bulkPaymentProcess(payload));
    toast.success(i18n.t('common:apiMessage.processBatchPaymentSuccessfully'));
    if (cb) cb({ success: true, data: [] });
    // if (response.bulkPaymentProcess) {
    //   if (cb) cb({ success: true, data: response.bulkPaymentProcess });
    //   toast.success(i18n.t('common:apiMessage.processBatchPaymentSuccessfully'));
    // } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Payment Item Map
export function* searchConfigPaymentAllocationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigPaymentAllocation(payload));
    if (response.searchConfigPaymentAllocation) {
      if (cb) cb({ success: true, data: response.searchConfigPaymentAllocation });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyConfigPaymentAllocationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigPaymentAllocation(payload));
    if (response.modifyConfigPaymentAllocation) {
      if (cb) cb({ success: true, data: response.modifyConfigPaymentAllocation });
      toast.success(i18n.t('common:apiMessage.modifyConfigPaymentAllocationSuccessfully'));
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createConfigPaymentAllocationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigPaymentAllocation(payload));
    if (response.createConfigPaymentAllocation) {
      if (cb) cb({ success: true, data: response.createConfigPaymentAllocation });
      toast.success(i18n.t('common:apiMessage.createConfigPaymentAllocationSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getConfigPymtAllocSeqByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigPymtAllocSeqById(payload));
    if (response.getConfigPymtAllocSeqById) {
      if (cb) cb({ success: true, data: response.getConfigPymtAllocSeqById });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* paymentSaga() {
  yield takeLatest(types.SEARCH_ACCOUNTS, searchAccountsSaga);
  yield takeEvery(types.GET_PAYMENT_CONFIG_BY_TYPE, getPaymentConfigTypeSaga);
  yield takeLatest(types.CREATE_PAYMENT_CONFIG_MERCHANT, createPaymentConfigMerchantSaga);
  yield takeLatest(types.MODIFY_PAYMENT_CONFIG_MERCHANT, modifyPaymentConfigMerchantSaga);
  yield takeLatest(types.CREATE_PAYMENT_CONFIG_METHOD, createPaymentConfigMethodSaga);
  yield takeLatest(types.MODIFY_PAYMENT_CONFIG_METHOD, modifyPaymentConfigMethodSaga);
  yield takeLatest(types.CREATE_PAYMENT_CONFIG_TERM, createPaymentConfigTermSaga);
  yield takeLatest(types.MODIFY_PAYMENT_CONFIG_TERM, modifyPaymentConfigTermSaga);
  yield takeLatest(types.SEARCH_PAYMENTS, searchPaymentSaga);
  yield takeLatest(types.REVERSE_PAYMENT, reversePaymentSaga);
  yield takeLatest(types.SEARCH_PAYMENTS_SUSPENSE, searchPaymentSuspenseSaga);
  yield takeLatest(types.APPLY_PAYMENT_SUSPENSE, applyPaymentSuspenseSaga);
  yield takeLatest(types.SEARCH_INVOICES, searchInvoicesSaga);
  yield takeLatest(types.APPLY_PAYMENT, applyPaymentSaga);
  yield takeLatest(types.ALLOCATE_PAYMENT, allocatePaymentSaga);
  yield takeLatest(types.MODIFY_PAYMENT_SUSPENSE, modifiedPaymentSuspenseSaga);
  yield takeLatest(types.GET_PAYMENT_SURCHARGE_CONFIG, getPaymentSurchargeConfigSaga);
  yield takeLatest(types.MODIFY_PAYMENT_SURCHARGE_CONFIG, modifyPaymentSurchargeConfigSaga);
  yield takeLatest(types.CREATE_PAYMENT_SURCHARGE_CONFIG, createPaymentSurchargeConfigSaga);
  yield takeLatest(types.SEARCH_BATCH_PAYMENT_PROCESS, searchBatchPaymentProcessSaga);
  yield takeEvery(types.GET_BATCH_PAYMENT_DETAIS, getBatchPaymentDetaisSaga);
  yield takeEvery(types.PROCESS_BATCH_PAYMENT, processBatchPaymentSaga);
  yield takeLatest(types.GET_PAYMENT_BATCH_FILE_CONFIG, getBatchPaymentFileConfigSaga);
  yield takeEvery(types.MODIFY_PAYMENT_BATCH_FILE_CONFIG, modifyBatchPaymentFileConfigSaga);
  yield takeEvery(types.CREATE_PAYMENT_BATCH_FILE_CONFIG, createBatchPaymentFileConfigSaga);
  yield takeLatest(types.GET_PAYMENT_ITEM_MAP, getPaymentItemMapSaga);
  yield takeEvery(types.MODIFY_PAYMENT_ITEM_MAP, modifyPaymentItemMapSaga);
  yield takeEvery(types.CREATE_PAYMENT_ITEM_MAP, createPaymentItemMapSaga);
  yield takeEvery(types.GET_CROSS_CURRENCY_PAYMENT_DETAILS, getCrossCurrencyPaymentDetailsSaga);
  yield takeEvery(types.DELETE_PAYMENT_FILE, deletePaymentFileSaga);
  yield takeEvery(types.SEARCH_PAYMENTS_REPORT, searchPaymentReportSaga);
  yield takeEvery(types.SEARCH_PAYMENTS_SUSPENSE_REPORT, searchPaymentSuspenseReportSaga);
  yield takeLatest(types.RESEND_PAYMENT_VENDOR_PAYMENT, resendPaymentVendorPaymentSaga);
  yield takeLatest(types.PROCESS_BATCH_PAYMENT_PROXY, processBatchPaymentProxySaga);
  yield takeLatest(types.SEARCH_CONFIG_PAYMENT_ALLOCATION, searchConfigPaymentAllocationSaga);
  yield takeLatest(types.MODIFY_CONFIG_PAYMENT_ALLOCATION, modifyConfigPaymentAllocationSaga);
  yield takeLatest(types.CREATE_CONFIG_PAYMENT_ALLOCATION, createConfigPaymentAllocationSaga);
  yield takeLatest(types.GET_CONFIG_PYMT_ALLOC_SEQ_BY_ID, getConfigPymtAllocSeqByIdSaga);
}
