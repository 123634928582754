import React from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { paymentSort } from '../../../../constantsApp';
import {
  getPageTotalCount,
  formatMoneyValue,
  validateEmail,
  configOutputTemplateByType,
  parseToMutationRequestBulk,
} from '../../../../utils/utils';
import { SearchPaymentActivityForm } from '../../../../components/PaymentHub';
import {
  FormWithTableItem,
  DataTable,
  TablePagination,
  GenericInput,
  ModalWithItem,
  ModalViewNote,
} from '../../../../components/common';
import { getAccountId, getUserId } from '../../../../localStorages';
import CheckGroup from '../../../../components/common/CheckGroup';
import { makeGetlistPayments, makePagePaymentsParams } from '../../selectors';
import {
  searchPaymentsReport,
  setParamsPayments,
  reversePayment,
  allocatePayment,
  getPaymentItemMap,
  getCrossCurrencyPaymentDetails,
  resendPaymentVendorPayment,
} from '../../actions';
import { tableColumnsSerachPayment } from '../../constant';
import RouteNames from '../../../App/RouteNames';
import PageTitle from '../../../../components/PageTitle';
import { makeGetPermissionsPaymentManagement } from '../../../App/selectors';
import {
  getObjectFileById,
  getCurrencyConfigApp,
  getBatchPaymentFileConfigSelect,
  sendFolio,
  getAccountDetail,
  getOutputTemplateByType,
  bulkProcess,
  getCcpPropertiesConfig,
} from '../../../App/actions';
import {
  checkPermissionProcessPaymentReversal,
  checkPermissionAutoPaymentAllocation,
  checkPermissionManualPaymentAllocation,
  checkPermissionSearchPayment,
  checkPermissionSearchPaymentReversals,
} from '../../CheckPermission';
import ModalViewPaymentFile from '../../../../components/common/ModalViewPaymentFile';
import ModalViewPaymentActivity from '../../../../components/common/ModalViewPaymentActivity';
import ModalInvoice from '../OneOffPayment/ModalInvoice';
import { statusDisableFielsdViewFolio } from '../../../../utils/constants';

const messageNotesAction = t => <div dangerouslySetInnerHTML={{ __html: t('message.messgaeNotePaymentAction') }} />;

const paymentDetails = [
  {
    name: 'amount',
    label: 'common:label.paymentAmount',
    type: 'number',
  },
  {
    name: 'remainingAmount',
    label: 'common:label.remainingAmount',
    type: 'number',
  },
  {
    name: 'currency',
    label: 'common:label.paymentCurrency',
  },
];

const checkExistStatus = ({ listItemSelect, statusList }) => {
  return !!listItemSelect.find(val => statusList.indexOf(val.status) !== -1);
};

const checkMatchAllFolioStatus = ({ listItemSelect, folioStatusList }) => {
  return !!listItemSelect.find(val => folioStatusList.indexOf(val.folioStatus) === -1);
};

const checkExistPdfFile = ({ listItemSelect }) => {
  return !!listItemSelect.find(val => !val.isExistPdfFile);
};

const checkExistAllFolioStatus = ({ listItemSelect, folioStatusList }) => {
  return !!listItemSelect.find(val => folioStatusList.indexOf(val.folioStatus) !== -1);
};

const checkExistRemainingAmount = ({ listItemSelect }) => {
  return !!listItemSelect.find(val => Number.parseFloat(val.remainingAmount) <= 0);
};

const checkExistRemainingAmountEqualAmount = ({ listItemSelect }) => {
  return !!listItemSelect.find(val => Number.parseFloat(val.remainingAmount) === Number.parseFloat(val.amount * -1));
};

const checkSameAsPaymentDateCurrency = ({ listItemSelect }) => {
  const { paymentDate, currency, remainingAmount } = listItemSelect[0] || {};
  return !!listItemSelect.find(
    val => val.paymentDate !== paymentDate || val.currency !== currency || val.remainingAmount !== remainingAmount
  );
};

let tableColumnsDefault = null;
class ViewPaymentActivity extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isSearching: false,
      selectedPayment: {},
      page: 0,
      size: 20,
      sort: '',
      sorted: {},
      totalCount: null,
      filter: { accountId: null },
      dataReverse: {},
      isOpenFile: false,
      pathFile: null,
      paymentFileData: [],
      isOpenModalViewPaymentActivity: false,
      isActivityViewer: false,
      listItems: [],
      isOpenModalAllocationData: false,
      isExistPdfFile: false,
      listPaymentSelect: [],
      isPacEnabled: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: props.listPayments }),
    };
  }

  componentDidMount() {
    const { location, getCcpPropertiesConfig } = this.props;
    // if (!getAccountId()) {
    //   toast('You need to select account first', { type: toast.TYPE.WARNING });
    //   history.push(RouteNames.paymentSearchAccount.path);
    //   return;
    // }
    this.doGetOutputTemplateByType();
    getCcpPropertiesConfig('', ({ success, data }) => {
      let isPacEnabled = false;
      if (success) {
        const pacEnabledProperty = data.ccpPropertyList.find(val => val.property === 'pacEnabled');
        if (pacEnabledProperty && pacEnabledProperty.value) {
          isPacEnabled = pacEnabledProperty.value === 'true';
        }
      }
      this.setState({
        isPacEnabled,
      });
    });
    // const { location } = this.props;
    if (location && location.state && location.state) {
      const {
        filter,
        selectedPayment,
        page,
        size,
        sort,
        sorted,
        totalCount,
        dataReverse,
        isOpenFile,
        pathFile,
        paymentFileData,
        isOpenModalViewPaymentActivity,
        isActivityViewer,
        listItems,
        isOpenModalAllocationData,
        isExistPdfFile,
        listPaymentSelect,
      } = location.state || {};
      this.setState(
        {
          filter: filter || {},
          selectedPayment,
          page,
          size,
          sort,
          sorted,
          totalCount,
          dataReverse,
          isOpenFile,
          pathFile,
          paymentFileData,
          isOpenModalViewPaymentActivity,
          isActivityViewer,
          listItems,
          isOpenModalAllocationData,
          isExistPdfFile,
          listPaymentSelect,
        },
        () => {
          this.doSearchPayment();
        }
      );
    } else {
      this.doSearchPayment();
    }
  }

  doSearchPayment = () => {
    const { searchPaymentsReport } = this.props;
    this.setState({ isSearching: true });
    const { filter, page, size, sorted } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? paymentSort[sorted.sortCol][sorted.sortDir] : null,
    };
    searchPaymentsReport(payload, () =>
      this.setState({
        isSearching: false,
      })
    );
  };

  onHandleSearch = filter => {
    const newFilter = filter;
    // newFilter.accountId = getAccountId();
    this.setState({ filter: newFilter, isSearching: true, page: 0 }, () => {
      this.doSearchPayment();
    });
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchPayment());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchPayment());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchPayment());
  };

  onSelectedPayment = selectedPayment1 => {
    const { selectedPayment } = this.state;
    if (!selectedPayment || selectedPayment.paymentId !== selectedPayment1.paymentId) {
      this.setState({ selectedPayment: selectedPayment1 });
    } else {
      this.setState({ selectedPayment: null });
    }
  };

  onSelectItem = item => {
    let { listPaymentSelect } = this.state;
    if (listPaymentSelect.find(val => val.paymentId === item.paymentId)) {
      listPaymentSelect = listPaymentSelect.filter(val => val.paymentId !== item.paymentId);
    } else {
      this.onHanldeCheckPDFFile({ item });
      listPaymentSelect = [...listPaymentSelect, item];
    }
    this.setState({ listPaymentSelect, dataReverse: {}, typeSelect: '' });
  };

  onHandleReverse = () => {
    const { reversePayment } = this.props;
    const { listPaymentSelect, dataReverse } = this.state;
    const payloadRequest = dataReverse;
    if (listPaymentSelect.length === 1) {
      const payloads = listPaymentSelect.map(val => {
        return {
          ...payloadRequest,
          id: val.paymentId,
          reversedAmount: Number.parseFloat(val.amount) * -1,
          userId: getUserId() || 'UserX001',
        };
      });
      return reversePayment(payloads[0], ({ success }) => {
        if (success) {
          this.setState({
            selectedPayment: {},
            dataReverse: {},
            listItems: [],
            isOpenModalAllocationData: false,
            listPaymentSelect: [],
            typeSelect: null,
          });
          this.doSearchPayments();
        }
      });
    }

    const payloads = listPaymentSelect.map(val => {
      const payload = {
        ...payloadRequest,
        id: val.paymentId,
        reversedAmount: Number.parseFloat(val.amount) * -1,
        userId: getUserId() || 'UserX001',
      };
      return parseToMutationRequestBulk(payload, ['reversedAmount']);
    });
    return this.doBulkProcess({
      apiName: 'REVERSE_PAYMENT',
      serviceName: 'reversePayment',
      payloads,
    });

    // const payloadRequest = dataReverse;
    // payloadRequest.id = selectedPayment.paymentId;
    // payloadRequest.reversedAmount = Number.parseFloat(selectedPayment.amount) * -1;
    // payloadRequest.userId = getUserId() || 'UserX001';
    // reversePayment(payloadRequest, ({ success }) => {
    //   if (success) {
    //     this.setState({ selectedPayment: {}, dataReverse: {} });
    //   }
    // });
  };

  onChangeReverse = ({ name, value }) => {
    const { dataReverse } = this.state;
    dataReverse[name] = value;
    this.setState({ ...dataReverse });
  };

  onAutoAllocate = () => {
    const { allocatePayment } = this.props;
    const { listPaymentSelect } = this.state;
    if (listPaymentSelect.length === 1) {
      const payloads = listPaymentSelect.map(val => {
        return { id: val.paymentId, userId: getUserId() || 'UserX001' };
      });
      return allocatePayment(payloads[0], ({ success }) => {
        if (success) {
          this.doSearchPayment();
          this.setState({
            selectedPayment: {},
            dataReverse: {},
            listItems: [],
            isOpenModalAllocationData: false,
            listPaymentSelect: [],
            typeSelect: null,
          });
        }
      });
    }
    const payloads = listPaymentSelect.map(val => {
      const payload = { id: val.paymentId, userId: getUserId() || 'UserX001' };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'AUTO_ALLOCATE',
      serviceName: 'allocatePayment',
      payloads,
    });
    // const payloadRequest = {
    //   id: selectedPayment.paymentId,
    //   userId: getUserId() || 'UserX001',
    // };
  };

  onAllocatePayment = () => {
    const { allocatePayment } = this.props;
    const { listPaymentSelect, listItems } = this.state;
    if (listPaymentSelect.length === 1) {
      const payloads = listPaymentSelect.map(val => {
        return {
          id: val.paymentId,
          userId: getUserId() || 'UserX001',
          allocationData:
            listItems && listItems.length
              ? listItems.map(val => {
                  const { amount, invoiceUnitId, accountId } = val;
                  return { amount, invoiceUnitId, accountId };
                })
              : [],
        };
      });
      return allocatePayment(payloads[0], ({ success }) => {
        if (success) {
          this.doSearchPayment();
          this.setState({
            selectedPayment: {},
            dataReverse: {},
            listItems: [],
            isOpenModalAllocationData: false,
            listPaymentSelect: [],
            typeSelect: null,
          });
        }
      });
    }

    const payloads = listPaymentSelect.map(val => {
      const payload = {
        id: val.paymentId,
        userId: getUserId() || 'UserX001',
        allocationData:
          listItems && listItems.length
            ? listItems.map(val => {
                const { amount, invoiceUnitId, accountId } = val;
                return { amount: amount || 0, invoiceUnitId, accountId };
              })
            : [],
      };
      return parseToMutationRequestBulk(payload, ['amount']);
    });
    return this.doBulkProcess({
      apiName: 'ALLOCATE_PAYMENT',
      serviceName: 'allocatePayment',
      payloads,
    });
  };

  onHandleManual = () => {
    const { selectedPayment } = this.state;
    const { history } = this.props;
    const location = {
      pathname: '/payment/payment-agent/payment-allocation',
      state: { accountId: selectedPayment.accountId, selectedPayment },
    };
    history.push(location);
  };

  onToggleViewPaymentFile = () => {
    this.setState({ isOpenFile: false });
  };

  onHanldeViewPaymentFile = ({ item }) => {
    const { getObjectFileById } = this.props;
    getObjectFileById(item.paymentId, data => {
      let pdfPath = '';
      let htmlPath = '';
      if (data && data.length) {
        const pdfData = data.find(val => val.fileType === 'PDF');
        const htmlData = data.find(val => val.fileType === 'HTML');
        if (pdfData) {
          pdfPath = pdfData.path;
        }
        if (htmlData) {
          htmlPath = htmlData.path;
        }
        this.setState({ pathFile: pdfPath, isOpenFile: true, htmlFile: htmlPath, paymentFileData: data });
      }
    });
  };

  onToggleModalViewPaymentActivity = (item, isActivityViewer) => {
    const { isOpenModalViewPaymentActivity } = this.state;
    this.setState({
      isOpenModalViewPaymentActivity: !isOpenModalViewPaymentActivity,
      itemSelected: item,
      isActivityViewer,
    });
  };

  renderPaymentDetails = ({ totalAmount, isInvoiceModal }) => {
    const { listPaymentSelect } = this.state;
    const selectedPayment = listPaymentSelect[0] || {};
    return (
      <div className={`col-md-12 row border-bottom pt-3 pb-3 ${isInvoiceModal ? 'pl-3' : ''}`}>
        {paymentDetails.map(val => {
          let value = selectedPayment ? selectedPayment[val.name] : '';
          if (val.name === 'remainingAmount' && selectedPayment) {
            value = formatMoneyValue(
              Number.parseFloat(selectedPayment[val.name] || 0) - Number.parseFloat(totalAmount || 0) || null
            );
            if (!value || Number.isNaN(value) || value === 'NaN') value = '0';
          }
          // console.log('asdasd', val.name, value);
          return <GenericInput value={value} onChange={() => {}} wrapperClass="col-md-3" readOnly {...val} />;
        })}
      </div>
    );
  };

  onToggleModalInvoiceAllocationData = () => {
    const { isOpenModalAllocationData } = this.state;
    this.setState({ isOpenModalAllocationData: !isOpenModalAllocationData, listItems: [] });
  };

  onAddNewItem = () => {
    try {
      const { listItems } = this.state;
      const newData = cloneDeep(listItems);
      newData.push({
        invoiceUnitId: null,
        amount: null,
        accountId: null,
      });

      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { listItems } = this.state;
    try {
      const newData = cloneDeep(listItems);
      newData.splice(index, 1);
      this.setState({ listItems: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleInvoice = ({ index }) => {
    const { isOpenModalInvoice } = this.state;
    this.setState({ isOpenModalInvoice: !isOpenModalInvoice, indexSelectAccountId: index });
  };

  onSelectInvoice = (itemId, row) => {
    const { indexSelectAccountId, listItems, listPaymentSelect } = this.state;
    const selectedPayment = listPaymentSelect[0] || {};
    const newData = cloneDeep(selectedPayment);
    const { t } = this.props;
    // let totalAmount = 0;
    // listItems
    //   .filter(val => val.invoiceUnitId !== listItems[indexSelectAccountId].invoiceUnitId)
    //   .forEach(val => {
    //     const { amount } = val;
    //     totalAmount += Number.parseFloat(amount || 0);
    //   });

    const checkExitInvoice = listItems.find(val => val.invoiceUnitId === itemId);
    if (checkExitInvoice) return toast.error(t('message.messageInvoiceIdAleadyExist'));
    // const amountTotalForListRow = selectedPayment.unallocatedAmount - totalAmount;
    if (itemId) {
      listItems[indexSelectAccountId].invoiceUnitId = itemId;
      listItems[indexSelectAccountId].due = row.due;
      listItems[indexSelectAccountId].invoiceDate = row.invoiceDate;
      listItems[indexSelectAccountId].dueDate = row.dueDate;
      listItems[indexSelectAccountId].total = row.total;
      listItems[indexSelectAccountId].type = row.type;
      listItems[indexSelectAccountId].accountId = row.accountId;
      listItems[indexSelectAccountId].clientId = row.clientId;
      listItems[indexSelectAccountId].organization = row.organization;
      listItems[indexSelectAccountId].folioStatus = row.folioStatus;
      if (selectedPayment?.paymentDate) {
        this.doGetCrossCurrencyPaymentDetails({
          accountId: row.accountId,
          invoiceId: itemId,
          paymentDate: selectedPayment?.paymentDate,
          paymentCurrency: selectedPayment?.currency,
          indexSelectAccountId,
        });
      }
      // if (amountTotalForListRow === 0) {
      //   listItems[indexSelectAccountId].amount = '0';
      // }
      // if (amountTotalForListRow > 0 && Number.parseFloat(amountTotalForListRow) - Number.parseFloat(row.due) < 0) {
      //   listItems[indexSelectAccountId].amount = amountTotalForListRow;
      // }
      // if (Number.parseFloat(amountTotalForListRow) > Number.parseFloat(row.due)) {
      //   listItems[indexSelectAccountId].amount = row.due;
      // }
    }
    this.setState({ listItems: [...listItems], isOpenModalInvoice: false, data: newData });
  };

  doGetCrossCurrencyPaymentDetails = ({ accountId, paymentDate, invoiceId, paymentCurrency, indexSelectAccountId }) => {
    const { getCrossCurrencyPaymentDetails } = this.props;
    const { listPaymentSelect } = this.state;
    const selectedPayment = listPaymentSelect[0] || {};
    const newData = cloneDeep(selectedPayment);
    getCrossCurrencyPaymentDetails({ accountId, paymentDate, invoiceId, paymentCurrency }, ({ success, data }) => {
      if (success && data) {
        const { listItems } = this.state;
        if (listItems && listItems[indexSelectAccountId]) {
          listItems[indexSelectAccountId].invoiceCurrencyInvDue = data.invoiceCurrencyInvDue;
          listItems[indexSelectAccountId].paymentCurrencyInvDue = data.paymentCurrencyInvDue;
          listItems[indexSelectAccountId].exchangeRate = data.exchangeRate;
          listItems[indexSelectAccountId].invoiceCurrency = data.invoiceCurrency;

          let totalAmount = 0;
          listItems
            .filter(val => val.invoiceUnitId !== listItems[indexSelectAccountId].invoiceUnitId)
            .forEach(val => {
              const { amount } = val;
              totalAmount += Number.parseFloat(amount || 0);
            });

          const amountTotalForListRow = Number.parseFloat(newData.remainingAmount || 0) - totalAmount;
          if (amountTotalForListRow === 0) {
            listItems[indexSelectAccountId].amount = '0';
          }
          if (
            amountTotalForListRow > 0 &&
            Number.parseFloat(amountTotalForListRow) - Number.parseFloat(data.paymentCurrencyInvDue) < 0
          ) {
            listItems[indexSelectAccountId].amount = amountTotalForListRow;
          }
          if (Number.parseFloat(amountTotalForListRow) >= Number.parseFloat(data.paymentCurrencyInvDue)) {
            listItems[indexSelectAccountId].amount = Number.parseFloat(data.paymentCurrencyInvDue);
          }
          if (
            listItems[indexSelectAccountId].amount &&
            Number.parseFloat(listItems[indexSelectAccountId].amount) !== 0
          ) {
            listItems[indexSelectAccountId].amount = Number.parseFloat(listItems[indexSelectAccountId].amount);
          }
          // console.log('listItems', listItems)
        }
        this.setState({ listItems: [...listItems] });
      }
    });
  };

  onChangeInvoiceAmount = ({ name, value, index, item }) => {
    const { listItems, listPaymentSelect } = this.state;
    const selectedPayment = listPaymentSelect[0] || {};

    let totalAmount = 0;
    if (Number.parseFloat(value) < 0) return '';
    listItems
      .filter(val => val.invoiceUnitId !== listItems[index].invoiceUnitId)
      .forEach(val => {
        const { amount } = val;
        totalAmount += Number.parseFloat(amount || 0);
      });
    const amountTotalForListRow = selectedPayment.remainingAmount - totalAmount;
    if (amountTotalForListRow < Number.parseFloat(value)) return '';
    if (item.paymentCurrencyInvDue < Number.parseFloat(value)) return '';
    listItems[index].amount = value;
    this.setState({ listItems: [...listItems] });
  };

  onChangePaymentType = ({ value }) => {
    this.setState({ typeSelect: value });
  };

  onHandleResendToVendor = () => {
    const { resendPaymentVendorPayment } = this.props;
    const { listPaymentSelect } = this.state;

    if (listPaymentSelect.length === 1) {
      const payloads = listPaymentSelect.map(val => {
        return val.paymentId;
      });

      return resendPaymentVendorPayment(payloads[0], ({ success }) => {
        if (success) {
          this.setState({
            selectedPayment: {},
            dataReverse: {},
            listItems: [],
            isOpenModalAllocationData: false,
            listPaymentSelect: [],
            typeSelect: null,
          });
          this.doSearchPayments();
        }
      });
    }
    const payloads = listPaymentSelect.map(val => {
      const payload = { id: val.paymentId };
      return parseToMutationRequestBulk(payload);
    });
    return this.doBulkProcess({
      apiName: 'RESEND_PAYMENT_TO_VENDOR',
      serviceName: 'resendPaymentVendor',
      payloads,
    });
  };

  onHanldeSendEmail = () => {
    const { sendFolio, getAccountDetail } = this.props;
    const { listPaymentSelect, dataReverse } = this.state;
    if (dataReverse?.emailId) {
      if (listPaymentSelect.length === 1) {
        const payloads = listPaymentSelect.map(val => {
          return {
            folioId: val.paymentId || null,
            accountId: val.accountId || null,
            folioType: 'PAYMENT_FOLIO',
            emailId: dataReverse?.emailId || null,
          };
        });

        return sendFolio(payloads[0], ({ success }) => {
          if (success) {
            this.setState({
              selectedPayment: {},
              dataReverse: {},
              listItems: [],
              isOpenModalAllocationData: false,
              listPaymentSelect: [],
              typeSelect: null,
            });
            this.doSearchPayments();
          }
        });
      }
      const payloads = listPaymentSelect.map(val => {
        const payload = {
          folioId: val.paymentId || null,
          accountId: val.accountId || null,
          folioType: 'PAYMENT_FOLIO',
          emailId: dataReverse?.emailId || null,
        };
        return parseToMutationRequestBulk(payload, ['folioType']);
      });
      return this.doBulkProcess({
        apiName: 'EMAIL_PAYMENT_FOLIO',
        serviceName: 'sendFolio',
        payloads,
      });
    }
    if (listPaymentSelect.length === 1) {
      getAccountDetail(listPaymentSelect[0]?.accountId, ({ success, data }) => {
        const emailId = success && data?.contacts && data?.contacts[0]?.email ? data?.contacts[0]?.email : null;
        sendFolio(
          {
            folioId: listPaymentSelect[0].paymentId || null,
            accountId: listPaymentSelect[0].accountId || null,
            folioType: 'PAYMENT_FOLIO',
            emailId: emailId || null,
          },
          () => {
            this.setState({
              selectedPayment: {},
              dataReverse: {},
              listItems: [],
              isOpenModalAllocationData: false,
              listPaymentSelect: [],
              typeSelect: null,
            });
          }
        );
      });
    } else {
      let payloadList = [];
      listPaymentSelect.forEach(val => {
        getAccountDetail(val.accountId, ({ success, data }) => {
          if (success) {
            const emailId = success && data?.contacts && data?.contacts[0]?.email ? data?.contacts[0]?.email : null;
            payloadList = [
              ...payloadList,
              {
                folioId: val.paymentId || null,
                accountId: val.accountId || null,
                folioType: 'PAYMENT_FOLIO',
                emailId: emailId || null,
              },
            ];

            if (payloadList.length === listPaymentSelect.length) {
              const payloads = payloadList.map(val => {
                const payload = {
                  folioId: val.paymentId || null,
                  accountId: val.accountId || null,
                  folioType: 'PAYMENT_FOLIO',
                  emailId: val?.emailId || null,
                };
                return parseToMutationRequestBulk(payload, ['folioType']);
              });
              return this.doBulkProcess({
                apiName: 'EMAIL_PAYMENT_FOLIO',
                serviceName: 'sendFolio',
                payloads,
              });
            }
          }
        });
      });
    }
  };

  onHanldeCheckPDFFile = ({ item }) => {
    const { getObjectFileById } = this.props;
    getObjectFileById(item.paymentId, data => {
      if (data && data.length) {
        const { listPaymentSelect } = this.state;
        const pdfData = data.find(val => val.fileType === 'PDF');
        const indexCheck = listPaymentSelect.findIndex(val => val.paymentId === item.paymentId);
        listPaymentSelect[indexCheck].isExistPdfFile = pdfData;
        this.setState({ isExistPdfFile: pdfData, listPaymentSelect: [...listPaymentSelect] });
      }
    });
  };

  doGetObjectFileById = invoiceId => {
    const { history, getObjectFileById, id } = this.props;
    const {
      filter,
      selectedPayment,
      page,
      size,
      sort,
      sorted,
      totalCount,
      dataReverse,
      isOpenFile,
      pathFile,
      paymentFileData,
      isOpenModalViewPaymentActivity,
      isActivityViewer,
      listItems,
      isOpenModalAllocationData,
      isExistPdfFile,
      listPaymentSelect,
    } = this.state;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.paymentActivity.path,
            fileData: data,
            filter,
            selectedPayment,
            page,
            size,
            sort,
            sorted,
            totalCount,
            dataReverse,
            isOpenFile,
            pathFile,
            paymentFileData,
            isOpenModalViewPaymentActivity,
            isActivityViewer,
            listItems,
            isOpenModalAllocationData,
            isExistPdfFile,
            listPaymentSelect,
          },
        });
      }
    });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('PAYMENT_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({ columns: tableColumnsDefault, data, t }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  doBulkProcess = ({ serviceName, apiName, payloads }) => {
    const { bulkProcess } = this.props;
    bulkProcess({ serviceName, apiName, payloads }, ({ success }) => {
      if (success) {
        this.doSearchPayment();
        this.setState({
          selectedPayment: {},
          dataReverse: {},
          listItems: [],
          isOpenModalAllocationData: false,
          listPaymentSelect: [],
          typeSelect: null,
        });
      }
    });
  };

  onToggleModalNotesAction = () => {
    const { isOpenModalNotesAction } = this.state;
    this.setState({ isOpenModalNotesAction: !isOpenModalNotesAction });
  };

  render() {
    const {
      listPayments,
      permissionsPayment,
      t,
      getPaymentItemMap,
      getCurrencyConfigApp,
      getBatchPaymentFileConfigSelect,
      location,
    } = this.props;
    const {
      isSearching,
      selectedPayment,
      sorted,
      page,
      size,
      totalCount,
      dataReverse,
      isOpenFile,
      pathFile,
      htmlFile,
      paymentFileData,
      isOpenModalViewPaymentActivity,
      itemSelected,
      isActivityViewer,
      isOpenModalAllocationData,
      listItems,
      isOpenModalInvoice,
      typeSelect,
      isExistPdfFile,
      exportColumnsSave,
      listPaymentSelect,
      isOpenModalNotesAction,
      isPacEnabled,
    } = this.state;

    let modeProcessPaymentReversal = 0;
    let modeAutoPaymentAllocation = 0;
    let modeManualPaymentAllocation = 0;
    let modeSearchPayment = 0;
    let modeSearchPaymentReversals = 0;
    let paymentHistorySelectOptions = t('selections:paymentActionHistory')();
    if (
      listPaymentSelect &&
      (checkExistRemainingAmount({ listItemSelect: listPaymentSelect }) ||
        !checkExistStatus({ listItemSelect: listPaymentSelect, statusList: ['OPEN', 'COLLECTION', 'REVERSED'] }))
    ) {
      paymentHistorySelectOptions = paymentHistorySelectOptions.filter(val => val.value !== 'ALLOCATE_PAYMENT');
      paymentHistorySelectOptions = paymentHistorySelectOptions.filter(val => val.value !== 'AUTO_ALLOCATE');
    }

    if (
      listPaymentSelect &&
      (checkMatchAllFolioStatus({
        listItemSelect: listPaymentSelect,
        folioStatusList: ['STAMPED'],
      }) ||
        checkExistPdfFile({ listItemSelect: listPaymentSelect }))
    ) {
      paymentHistorySelectOptions = paymentHistorySelectOptions.filter(val => val.value !== 'EMAIL_FOLIO');
    }

    if (
      listPaymentSelect &&
      checkMatchAllFolioStatus({
        listItemSelect: listPaymentSelect,
        folioStatusList: ['STAMPING_REQUESTED', 'CANCELLATION_STAMPING_REQUESTED'],
      })
    ) {
      paymentHistorySelectOptions = paymentHistorySelectOptions.filter(val => val.value !== 'RESEND_TO_VENDOR');
    }

    if (
      listPaymentSelect &&
      listPaymentSelect.length &&
      (checkExistRemainingAmountEqualAmount({ listItemSelect: listPaymentSelect }) ||
        // selectedPayment?.paymentTransactionType === 'PAYMENT_REVERSAL' ||
        checkExistStatus({ listItemSelect: listPaymentSelect, statusList: ['REVERSED', 'REVERSED_TO_SUSPENSE'] }) ||
        // selectedPayment?.status === 'REVERSED' ||
        // selectedPayment?.status === 'REVERSED_TO_SUSPENSE' ||
        checkExistAllFolioStatus({
          listItemSelect: listPaymentSelect,
          folioStatusList: ['CANCELLATION_STAMPED', 'CANCELLATION_STAMPING_REQUESTED'],
        }))
      // selectedPayment?.folioStatus === 'CANCELLATION_STAMPING_REQUESTED' ||
      // selectedPayment?.folioStatus === 'CANCELLATION_STAMPED'
    ) {
      paymentHistorySelectOptions = paymentHistorySelectOptions.filter(val => val.value !== 'REVERSE_PAYMENT');
    }

    if (!listPaymentSelect || !listPaymentSelect.length) {
      paymentHistorySelectOptions = [];
    }

    if (
      listPaymentSelect &&
      listPaymentSelect.length > 1 &&
      checkSameAsPaymentDateCurrency({ listItemSelect: listPaymentSelect })
    ) {
      paymentHistorySelectOptions = paymentHistorySelectOptions.filter(val => val.value !== 'ALLOCATE_PAYMENT');
    }

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeProcessPaymentReversal = checkPermissionProcessPaymentReversal({ listPermission });
      modeAutoPaymentAllocation = checkPermissionAutoPaymentAllocation({ listPermission });
      modeManualPaymentAllocation = checkPermissionManualPaymentAllocation({ listPermission });
      modeSearchPayment = checkPermissionSearchPayment({ listPermission });
      modeSearchPaymentReversals = checkPermissionSearchPaymentReversals({ listPermission });
    }

    let totalAmount = 0;
    let isDisableApply = false;
    listItems.forEach(val => {
      const { amount, invoiceUnitId, accountId } = val;
      totalAmount += Number.parseFloat(amount || 0);
      if (!accountId || !invoiceUnitId) isDisableApply = true;
    });

    const invoiceColumns = [
      {
        name: 'remove',
        label: 'common:label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        label: 'label.invoiceId',
        name: 'invoiceUnitId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              value={item.invoiceUnitId}
              name="invoiceUnitId"
              wrapperClass="col-md-12"
              onChange={({ name, value }) => {}}
              onClick={() => this.onToggleInvoice({ item, index })}
              fa="fa fa-external-link"
            />
          );
        },
      },
      {
        label: 'label.accountId',
        name: 'accountId',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountId}
            name="accountId"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            // onClick={() => this.onToogleModalAccount({ item, index })}
            // fa={item.invoiceUnitId ? null : 'fa fa-external-link'}
            readOnly
            // readOnly={!!item.invoiceUnitId}
            // disabled={!!item.invoiceUnitId}
          />
        ),
      },
      {
        name: 'organization',
        label: 'common:label.organization',
      },
      {
        name: 'clientId',
        label: 'common:label.clientId',
      },
      {
        name: 'amount',
        label: 'common:label.allocateAmount',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.amount}
            name="amount"
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeInvoiceAmount({ name, value, item, index })}
          />
        ),
      },
      {
        name: 'invoiceCurrency',
        label: 'common:label.accountCurrency',
      },
      {
        name: 'invoiceCurrencyInvDue',
        label: 'common:label.invoiceDueInAccountCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'paymentCurrencyInvDue',
        label: 'common:label.invoiceDueInPaymentCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
      },
      {
        name: 'exchangeRate',
        label: 'common:label.exchangeRate',
        render: (colName, item) => {
          return <span>{item.exchangeRate}</span>;
        },
      },
      {
        name: 'invoiceUnitId',
        label: 'common:label.invoiceId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border"
              type="button"
              disabled={isPacEnabled && statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
              onClick={() => this.doGetObjectFileById(item.invoiceUnitId)}
            >
              {item.invoiceUnitId}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'common:label.invoiceDate',
      },
      {
        name: 'dueDate',
        label: 'common:label.dueDate',
      },
      {
        name: 'total',
        label: 'common:label.invoiceTotal',
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
    ];

    tableColumnsDefault = [
      ...tableColumnsSerachPayment,
      {
        name: 'paymentId',
        label: 'common:label.paymentId',
        sortable: true,
        render: (colName, item) =>
          item.paymentId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              // onClick={() => this.onHanldeViewPaymentFile({ item })}
              onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
            >
              {item.paymentId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'referenceId',
        label: 'common:label.paymentReferenceId',
        render: (colName, item) =>
          item.referenceId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, false)}
            >
              {item.referenceId}
            </button>
          ) : (
            ''
          ),
      },
    ];
    const exportColumns = exportColumnsSave || {};

    const tableColumnsOutput = exportColumns?.targetFields?.items || [];
    if (modeSearchPayment === 0) return '';
    const tableConfig = {
      columns: [
        {
          name: 'Selected',
          label: 'common:label.selected',
          render: (colName, item) => (
            <CheckGroup
              checked={
                listPaymentSelect &&
                listPaymentSelect.length &&
                listPaymentSelect.find(val => val.paymentId === item.paymentId)
              }
              name="selectItem"
              type="checkbox"
              onChange={() => this.onSelectItem(item)}
              disabled={
                !item?.paymentId ||
                item?.paymentTransactionType === 'PAYMENT_REVERSAL' ||
                item?.status === 'REVERSE_ALLOCATED'
              }
            />
          ),
        },
        {
          name: 'viewFolio',
          label: 'common:label.viewFolio',
          render: (colName, item) => (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              // onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
              onClick={() => this.onHanldeViewPaymentFile({ item })}
              disabled={!item?.paymentId || statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
            >
              {t('label.view')}
            </button>
          ),
        },
        ...tableColumnsOutput,
        // {
        //   name: 'id',
        //   label: 'common:label.paymentTxnId',
        //   render: (colName, item) => (
        //     <button
        //       className="btn btn-outline-success btn-sm no-border"
        //       type="button"
        //       onClick={() => this.onHanldeViewPaymentFile({ item })}
        //     >
        //       {item.id}
        //     </button>
        //   ),
        // },
        // {
        //   name: 'status',
        //   label: 'common:label.status',
        //   sortable: true,
        //   isRenderT: true,
        //   render: (colName, item, t) => {
        //     const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
        //     return <span>{slt ? slt.label : ''}</span>;
        //   },
        // },
      ],
      sorted,
      onSort: this.onSortColumn,
      data: modeSearchPaymentReversals === 0 ? listPayments.filter(item => item.status !== 'REVERSED') : listPayments,
      isLoading: isSearching,
    };

    const isMultipleRows = listPaymentSelect && listPaymentSelect.length > 1;

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.paymentActivity.path}
          titleBtn={t('common:label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentOperations'), url: RouteNames.paymentActivity.path },
            { name: t('paymentPage:sidebar.viewPaymentActivity') },
          ]}
        />
        <br />
        <FormWithTableItem wrapperClass="mt-0" title={t('label.paymentHistory')} accountNum={getAccountId()}>
          <div className="col-sm-12 mb-30 pl-2">
            <div className="card-body">
              <SearchPaymentActivityForm
                getPaymentItemMap={getPaymentItemMap}
                getCurrencyConfigApp={getCurrencyConfigApp}
                onSubmit={this.onHandleSearch}
                modeSearchPaymentReversals={modeSearchPaymentReversals}
                getBatchPaymentFileConfigSelect={getBatchPaymentFileConfigSelect}
                defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
              />
              <br />
            </div>
          </div>
          <div className="pt-3 action-buttons row mb-3 ml-2 mr-1 separation-border-top">
            <button
              type="button"
              className="button button-border gray x-small mt-4 default-heigh-btn mr-3 btn-action-note"
              onClick={this.onToggleModalNotesAction}
            >
              {t('label.quickNotes')}
              <i className="fa fa-external-link ml-2" aria-hidden="true" />
            </button>
            <GenericInput
              value={typeSelect}
              name="type"
              onChange={({ name, value }) => this.onChangePaymentType({ value })}
              wrapperClass="col-md-2 ml-2"
              label="label.action"
              options={paymentHistorySelectOptions}
              type="select"
              placeholder="<Select Action>"
              disabled={!listPaymentSelect}
              isPlaceholder
              isClearable
              menuPortalTarget
            />
            {modeManualPaymentAllocation !== 0 && typeSelect === 'ALLOCATE_PAYMENT' && (
              <button
                type="button"
                className="button button-border x-small float-right mt-4"
                onClick={this.onToggleModalInvoiceAllocationData}
                disabled={
                  isEmpty(listPaymentSelect) ||
                  // selectedPayment.folioStatus === 'REVERSED' ||
                  modeManualPaymentAllocation === 1
                }
              >
                {isMultipleRows ? t('label.processAll') : t('label.manualAllocation')}
              </button>
            )}
            {modeAutoPaymentAllocation !== 0 && typeSelect === 'AUTO_ALLOCATE' && (
              <button
                type="button"
                className="button button-border x-small float-right mt-4"
                onClick={this.onAutoAllocate}
                disabled={modeAutoPaymentAllocation === 1}
              >
                {isMultipleRows ? t('label.processAll') : t('label.autoAllocate')}
              </button>
            )}
            {typeSelect === 'RESEND_TO_VENDOR' && (
              <button
                type="button"
                className="button button-border x-small float-right mt-4"
                onClick={this.onHandleResendToVendor}
                disabled={isEmpty(listPaymentSelect)}
              >
                {isMultipleRows ? t('label.processAll') : t('label.submit')}
              </button>
            )}
            {typeSelect === 'REVERSE_PAYMENT' && (
              <>
                <GenericInput
                  name="reason"
                  label={t('label.paymentReversalReason')}
                  value={dataReverse.reason}
                  tOptions="selections:paymentReversalReason"
                  type="select"
                  menuPortalTarget
                  disabled={
                    isEmpty(listPaymentSelect)
                    // ||
                    // selectedPayment.status === 'CLOSED' ||
                    // selectedPayment.status === 'REVERSED'
                  }
                  onChange={this.onChangeReverse}
                  wrapperClass="col-md-3 collection-dropdown"
                />
                <GenericInput
                  name="notes"
                  label={t('label.notes')}
                  value={dataReverse.notes}
                  wrapperClass="col-md-3"
                  type="textarea"
                  disabled={
                    isEmpty(listPaymentSelect)
                    // ||
                    // selectedPayment.status === 'CLOSED' ||
                    // selectedPayment.status === 'REVERSED'
                  }
                  onChange={this.onChangeReverse}
                />
                {modeProcessPaymentReversal !== 0 && (
                  <button
                    type="button"
                    className="button button-border x-small float-right mt-4 btn-reverse-payment"
                    disabled={
                      !dataReverse.notes ||
                      !dataReverse.reason ||
                      isEmpty(listPaymentSelect) ||
                      // selectedPayment.folioStatus === 'CLOSED' ||
                      // selectedPayment.folioStatus === 'REVERSED' ||
                      modeProcessPaymentReversal === 1
                    }
                    onClick={this.onHandleReverse}
                  >
                    {isMultipleRows ? t('label.processAll') : t('label.reversePayment')}
                  </button>
                )}
              </>
            )}
            {typeSelect === 'EMAIL_FOLIO' && (
              <>
                <GenericInput
                  name="emailId"
                  label={t('label.overrideMail')}
                  value={dataReverse.emailId}
                  onChange={this.onChangeReverse}
                  wrapperClass="col-md-3 collection-dropdown"
                />
                <button
                  type="button"
                  className="button button-border x-small float-right mt-4"
                  disabled={isEmpty(listPaymentSelect) || (dataReverse.emailId && !validateEmail(dataReverse.emailId))}
                  onClick={this.onHanldeSendEmail}
                >
                  {isMultipleRows ? t('label.processAll') : t('label.submit')}
                </button>
              </>
            )}
          </div>
          <DataTable {...tableConfig} isFixedHeaderTable />

          {/* <div className="col-md-12 mb-30 pl-3 d-flex" /> */}
        </FormWithTableItem>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalViewPaymentFile
          title={t('label.paymentFile')}
          isOpen={isOpenFile}
          pathFile={pathFile}
          htmlFile={htmlFile}
          onToggle={this.onToggleViewPaymentFile}
          paymentFileData={paymentFileData}
        />
        <ModalViewPaymentActivity
          id={itemSelected?.paymentId || null}
          isOpen={isOpenModalViewPaymentActivity}
          onToggle={evt => this.onToggleModalViewPaymentActivity()}
          itemSelected={{ ...itemSelected, paymentRefId: itemSelected?.referenceId }}
          title={isActivityViewer ? t('label.paymentActivity') : t('label.paymentReferenceActivity')}
          isSuspense={isActivityViewer}
          isRemainingAmount
          isPaymentActivity
          isAdditionalFields
          isSupportDownload
        />
        <ModalWithItem
          isOpen={isOpenModalAllocationData}
          onToggle={this.onToggleModalInvoiceAllocationData}
          modalTitle={t('label.paymentAllocation')}
          wrapperClass="modal-70 modal-custom"
        >
          {this.renderPaymentDetails({ totalAmount, isInvoiceModal: true })}
          <br />
          <br />
          <DataTable columns={invoiceColumns} data={listItems || []} />
          <div className="col-sm-12 mt-4 mb-30">
            <button type="button" onClick={this.onAddNewItem} className="button mb-4 button-border ml-3 black x-small">
              +
              {t('label.addNewRow')}
            </button>
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={this.onToggleModalInvoiceAllocationData}
            >
              {t('label.cancel')}
            </button>
            <button
              type="button"
              className="button button-border x-small mr-2 float-right"
              onClick={this.onAllocatePayment}
              disabled={isDisableApply}
            >
              {t('label.allocatePayment')}
            </button>
          </div>
        </ModalWithItem>
        <ModalInvoice
          isOpen={isOpenModalInvoice}
          onSelect={this.onSelectInvoice}
          // accountId={selectedPayment.accountId}
          onCancel={() => {
            this.setState({ isOpenModalInvoice: false });
          }}
          disabledSearchFields={selectedPayment.accountId ? ['organization', 'clientId'] : null}
          isTopChildren
          defaultSearchForm={{ folioStatus: 'STAMPED' }}
        >
          {this.renderPaymentDetails({ totalAmount, isInvoiceModal: true })}
        </ModalInvoice>
        <ModalViewNote
          isOpen={isOpenModalNotesAction}
          message={messageNotesAction(t)}
          onToggle={this.onToggleModalNotesAction}
        />
      </div>
    );
  }
}

ViewPaymentActivity.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  listPayments: PropTypes.arrayOf(PropTypes.any),
  searchPaymentsReport: PropTypes.func.isRequired,
  reversePayment: PropTypes.func.isRequired,
  allocatePayment: PropTypes.func.isRequired,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

ViewPaymentActivity.defaultProps = {
  history: {},
  listPayments: [],
  permissionsPayment: {},
};

const mapStateToProps = createStructuredSelector({
  listPayments: makeGetlistPayments() || [],
  params: makePagePaymentsParams() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    searchPaymentsReport,
    setParamsPayments,
    reversePayment,
    allocatePayment,
    getObjectFileById,
    getCurrencyConfigApp,
    getPaymentItemMap,
    getCrossCurrencyPaymentDetails,
    getBatchPaymentFileConfigSelect,
    resendPaymentVendorPayment,
    sendFolio,
    getAccountDetail,
    getOutputTemplateByType,
    bulkProcess,
    getCcpPropertiesConfig,
  })(ViewPaymentActivity)
);
