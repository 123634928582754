import { createSelector } from 'reselect';

const selectBaseConfigurations = state => {
  return state.get('baseConfigurationsReducer');
};

// const makeGetProductFamilyConfig = () =>
//   createSelector(selectBaseConfigurations, items => items.get('productFamilyList'));

const makeGetCurrencyListConfig = () => createSelector(selectBaseConfigurations, items => items.get('currencyList'));

const makeGetGrantsListConfig = () => createSelector(selectBaseConfigurations, items => items.get('grantsList'));

const makeGetAccumulatorsListConfig = () =>
  createSelector(selectBaseConfigurations, items => items.get('accumulatorList'));

const makeGetReateUnitList = () => createSelector(selectBaseConfigurations, items => items.get('reateUnitList'));

const makeGetTimeUnitCfg = () => createSelector(selectBaseConfigurations, items => items.get('timeUnitCfg'));

const makeGetListRateUnitMap = () => createSelector(selectBaseConfigurations, items => items.get('listRateUnitMap'));

const makeGetListTaxCode = () => createSelector(selectBaseConfigurations, items => items.get('taxCodeConfig'));

const makeGetUom = () => createSelector(selectBaseConfigurations, items => items.get('uom'));

const makeGetCountryCodes = () => createSelector(selectBaseConfigurations, items => items.get('countryCodes'));

const makeGetPacProductCodesList = () =>
  createSelector(selectBaseConfigurations, items => items.get('pacProductCodesList'));

const makeGetPrepaidSystemOffersList = () =>
  createSelector(selectBaseConfigurations, items => items.get('prepaidSystemOffersList'));

export {
  // makeGetProductFamilyConfig,
  makeGetCurrencyListConfig,
  makeGetGrantsListConfig,
  makeGetAccumulatorsListConfig,
  makeGetReateUnitList,
  makeGetTimeUnitCfg,
  makeGetListRateUnitMap,
  makeGetListTaxCode,
  makeGetUom,
  makeGetCountryCodes,
  makeGetPacProductCodesList,
  makeGetPrepaidSystemOffersList,
};
