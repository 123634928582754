import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';
import { RowContent, RowHeader } from './TableRow';

class Table extends React.PureComponent {
  onClickRow = (e, item) => {
    const { onClickRow } = this.props;
    if (onClickRow) {
      onClickRow(e, item);
    }
  };

  render() {
    const {
      columns,
      data,
      rowClass,
      noDataMessage,
      getRowClass,
      sorted,
      onSort,
      tableClass,
      nullIsLoading,
      isLoading,
      isExpand,
      expandRender,
      supportExpandWithItem,
      keySupport,
      isSupportRemoveIndex,
      sizeRemove,
    } = this.props;
    return (
      <div className={`table-responsive sticky-header mt-15 table-form ${tableClass}`}>
        <table className="table center-aligned-table mb-0">
          <thead>
            <RowHeader columns={columns} sorted={sorted} onSort={onSort} isLoading={isLoading} />
          </thead>
          <tbody>
            {!isLoading &&
              data &&
              data.map((item, idx) => {
                if (isSupportRemoveIndex && item && Object.keys(item).length < (sizeRemove || 2)) return '';
                return (
                  <RowContent
                    key={`row-${idx}`}
                    index={idx}
                    columns={columns}
                    item={item}
                    onClickRow={this.onClickRow}
                    className={rowClass}
                    getRowClass={getRowClass}
                    isExpanding={
                      (isExpand && isExpand[idx]) || (supportExpandWithItem && supportExpandWithItem[item[keySupport]])
                    }
                    expandRender={expandRender}
                  />
                );
              })}
            {(isLoading || !data || data.length === 0) && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  {!isLoading && !nullIsLoading && <span>{noDataMessage || 'Not found'}</span>}
                  {(isLoading || ((!data || data.length === 0) && nullIsLoading)) && (
                    <ScaleLoader loading size={100} color="#28a745" />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  data: PropTypes.array.isRequired,
  rowClass: PropTypes.string,
  tableClass: PropTypes.string,
  noDataMessage: PropTypes.string,
  nullIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getRowClass: PropTypes.func,
  onSort: PropTypes.func,
  isShowOverrideLines: PropTypes.object,
};

Table.defaultProps = {
  columns: [],
  sorted: {},
  data: [],
  rowClass: '',
  tableClass: '',
  getRowClass: null,
  isLoading: false,
  nullIsLoading: false,
  onSort: null,
  noDataMessage: 'Not found',
};
export default Table;
