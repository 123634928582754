import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GenericInput, DataTable, AccountHeaderFrom, TitleFrom } from '../common';
import RouteNames from '../../containers/App/RouteNames';

const ArOperationsForm = ({
  title,
  listFields,
  data,
  onClick,
  isDisabledAllField,
  onChange,
  isApply,
  onBlurAmount,
  onHandleSubmit,
  modeProcessTaxAdjustment,
  modeProcessTaxDispute,
  modeProcessDispute,
  modeApplyAdjustment,
  onOpenPopupInfo,
  modeGetArOpsTaxOptions,
  linkTo,
  invoiceLinesColums,
  invoiceLines,
  onCancel,
  currencyOptions,
  customerInfo,
  isRenderHeaderForm,
  transactionsList,
  unbilledTransactionLinesColums,
  isView,
  isNoCancel,
}) => {
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);
  const { t } = useTranslation('common');
  const newListFields = cloneDeep(listFields);
  const indexArType = newListFields.findIndex(item => item.name === 'type');
  if ((modeProcessTaxAdjustment === 0 || modeProcessTaxDispute === 0) && indexArType > -1) {
    newListFields[indexArType].options = newListFields[indexArType].options.filter(val => val.value !== 'TAX');
  }
  if ((modeProcessTaxAdjustment === 1 || modeProcessTaxDispute === 1) && indexArType > -1) {
    const indexOptionTax = newListFields[indexArType].options.findIndex(val => val.value === 'TAX');
    newListFields[indexArType].options[indexOptionTax].isDisabled = true;
  }

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    return onHandleSubmit(evt);
  };
  let currencyLabel = '';
  if (isRenderHeaderForm && currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }
  return (
    <div className="card card-statistics form-with-item">
      <div className="d-block d-md-flex justify-content-between">
        <div className="d-block d-md-flex clearfix sm-mt-20">
          <TitleFrom title={title} />
          {isRenderHeaderForm && (
            <AccountHeaderFrom
              accountNum={customerInfo?.id || ''}
              commercialName={customerInfo?.commercialName || ''}
              isCommercialName={customerInfo?.customerSegment !== 'B2C'}
              currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
            />
          )}
        </div>
      </div>
      <div className="form-group col-md-12 p-2">
        <form
          ref={formRef}
          noValidate
          className={`col-md-12 d-flex group-flex needs-validation ${wasValidated ? 'was-validated' : ''}`}
          // className="col-md-12 d-flex group-flex"
          onSubmit={onSubmit}
          // onSubmit={onHandleSubmit}
        >
          {newListFields.map(item => {
            if (!isEmpty(item.options) || item.tOptions) {
              return (
                <GenericInput
                  key={item.label}
                  label={item.label || ''}
                  value={data[item.name]}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  type="select"
                  onChange={onChange}
                  name={item.name}
                  disabled={
                    item.disabled ||
                    (item.name === 'percent' && data.amount) ||
                    (modeGetArOpsTaxOptions !== 2 && item.name === 'taxRule')
                  }
                  {...item}
                />
              );
            }

            if (item.type === 'money') {
              return (
                <GenericInput
                  key={item.label}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  label={item.label || ''}
                  value={data[item.name]}
                  disabled={item.disabled || (item.name === 'amount' && data.percent)}
                  // thousandSeparator
                  // placeholder="0.00"
                  // prefix="$ "
                  type="number"
                  onChange={values => onChange({ name: item.name, value: values.value })}
                  // onBlur={onBlurAmount}
                />
              );
            }

            // if (item.type === 'number') {
            //   return (
            //     <div className={`form-group ${item.wrapperClass || 'col-md-4'}`} key={item.label}>
            //       <span>
            //         {t(item.label)}
            //         {/* <button type="button" className="no-border btn-eye-label" onClick={onToggleModalEye}>
            //           <i className="ti-eye icon-show-password" />
            //         </button> */}
            //       </span>
            //       <input
            //         className="form-control input-group"
            //         name={item.name}
            //         type={item.type}
            //         value={data[item.name] || ''}
            //         disabled={item.disabled || (item.name === 'percent' && data.amount)}
            //         onChange={evt => onChange({ name: item.name, value: evt.target.value })}
            //       />
            //     </div>
            //   );
            // }

            return (
              <GenericInput
                key={item.label}
                label={item.label || ''}
                placeholder={item.placeholder}
                value={(!isEmpty(data) && data[item.name])}
                type={`${item.type ? item.type : 'text'}`}
                name={item.name}
                disabled={isDisabledAllField || item.disabled || (item.name === 'percent' && data.amount)}
                onChange={onChange}
                onClick={() => onClick(item.name)}
                wrapperClass={item.wrapperClass || 'col-md-4'}
                fa={item.isPopup ? 'fa fa-external-link' : ''}
                onOpenPopupInfo={onOpenPopupInfo}
                {...item}
              />
            );
          })}
          {data && data.type && (isApply || isView) && data?.type === 'INVOICE_LINE_ADJUSTMENT' && data?.invoiceId && (
            <div className="col-md-12">
              <h4 className="p-2 pb-3 text-center">{t('label.invoiceLineDetails')}</h4>
              <DataTable columns={invoiceLinesColums} data={invoiceLines} />
            </div>
          )}
          {data && data.type && (isApply || isView) && data?.type === 'UNBILLED_LINE_ADJUSTMENT' && data?.accountId && (
            <div className="col-md-12">
              <h4 className="p-2 pb-3 text-center">{t('label.pendingBillLines')}</h4>
              <DataTable columns={unbilledTransactionLinesColums} data={transactionsList} />
            </div>
          )}
          {isApply && (
            <div className="col-md-12 mt-3">
              <button
                type="submit"
                disabled={modeApplyAdjustment === 1 || modeProcessDispute === 1}
                className="button button-border x-small float-right"
              >
                {t('label.apply')}
              </button>
              {onCancel
                ? !isNoCancel && (
                <button
                  type="button"
                  onClick={onCancel}
                  className="button button-border black x-small mr-2 float-right"
                >
                  {t('label.cancel')}
                </button>
                  )
                : !isNoCancel && (
                <Link
                  to={linkTo || RouteNames.arOperationsDisputes.path}
                  className="button button-border black x-small mr-2 float-right"
                >
                  {t('label.cancel')}
                </Link>
                  )}
            </div>
          )}
          {!isApply && (
            <div className="col-md-12 mt-3">
              {onCancel
                ? !isNoCancel && (
                <button
                  type="button"
                  onClick={onCancel}
                  className="button button-border black x-small mr-2 float-right"
                >
                  {t('label.cancel')}
                </button>
                  )
                : !isNoCancel && (
                <Link
                  to={linkTo || RouteNames.arOperationsDisputes.path}
                  className="button button-border black x-small mr-2 float-right"
                >
                  {t('label.cancel')}
                </Link>
                  )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

ArOperationsForm.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onBlurAmount: PropTypes.func,
  onClick: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  listFields: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any),
  isApply: PropTypes.bool,
  isDisabledAllField: PropTypes.bool,
  modeProcessTaxAdjustment: PropTypes.number,
  modeProcessTaxDispute: PropTypes.number,
  modeProcessDispute: PropTypes.number,
  modeApplyAdjustment: PropTypes.number,
  modeGetArOpsTaxOptions: PropTypes.number,
  onOpenPopupInfo: PropTypes.func,
  linkTo: PropTypes.string,
  invoiceLinesColums: PropTypes.arrayOf(PropTypes.object),
  invoiceLines: PropTypes.arrayOf(PropTypes.object),
  currencyOptions: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  customerInfo: PropTypes.objectOf(PropTypes.any),
  isRenderHeaderForm: PropTypes.bool,
};

ArOperationsForm.defaultProps = {
  title: '',
  listFields: [],
  data: {},
  onChange: () => {},
  onClick: () => {},
  onBlurAmount: () => {},
  onHandleSubmit: () => {},
  onOpenPopupInfo: () => {},
  onCancel: () => {},
  isApply: false,
  isDisabledAllField: false,
  modeProcessTaxAdjustment: 2,
  modeProcessTaxDispute: 2,
  modeProcessDispute: 2,
  modeApplyAdjustment: 2,
  modeGetArOpsTaxOptions: 2,
  linkTo: undefined,
  invoiceLinesColums: [],
  invoiceLines: [],
  currencyOptions: [],
  customerInfo: [],
  isRenderHeaderForm: false,
};
export default ArOperationsForm;
