import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OrgDiagram } from 'basicprimitivesreact';
import { PageFitMode, Enabled, GroupByType } from 'basicprimitives';

const colorDefaultList = [
  {
    label: 'label.active',
    color: '#28a745',
  },
  {
    label: 'label.INACTIVE',
    color: 'orange',
  },
  {
    label: 'label.CLOSEDAndCANCELLED',
    color: 'grey',
  },
];

const FamilyChartGroup = ({ items, wrapperClass, colorList, normalUsSize, normalOtherLangSize }) => {
  const { t } = useTranslation('common');

  const isEngUs = !localStorage.getItem('i18nextLng') || localStorage.getItem('i18nextLng') === 'en-US';
  const config = {
    pageFitMode: PageFitMode.None,
    hasSelectorCheckbox: Enabled.False,
    arrowsDirection: GroupByType.Children,
    defaultTemplateName: 'largeSize',
    lineItemsInterval: 30,
    lineLevelShift: 50,
    normalLevelShift: 25,
    // linesColor: 'black',
    linesWidth: 2,
    templates: [
      {
        name: 'smallSize',
        itemSize: isEngUs ? { width: 160, height: 50 } : { width: 280, height: 50 },
        onItemRender: ({ context }) => {
          const { className, title, description, onClickBox } = context;
          return (
            <div
              aria-hidden="true"
              onClick={onClickBox || null}
              className={`family-chart-group ${className || ''} ${onClickBox ? 'cursor' : ''}`}
            >
              <div className="title">
                {title}
                {onClickBox && <i className="fa fa-external-link ml-3" aria-hidden="true" />}
              </div>
              <div className="description">{description}</div>
            </div>
          );
        },
      },
      {
        name: 'normalSize',
        itemSize: isEngUs
          ? normalUsSize || { width: 160, height: 70 }
          : normalOtherLangSize || { width: 260, height: 70 },
        onItemRender: ({ context }) => {
          const { className, title, description, onClickBox } = context;
          return (
            <div
              aria-hidden="true"
              onClick={onClickBox || null}
              className={`family-chart-group ${className || ''} ${onClickBox ? 'cursor' : ''}`}
            >
              <div className="title">
                {title}
                {onClickBox && <i className="fa fa-external-link ml-3" aria-hidden="true" />}
              </div>
              <div className="description">{description}</div>
            </div>
          );
        },
      },
      {
        name: 'largeSize',
        itemSize: isEngUs ? { width: 300, height: 120 } : { width: 350, height: 120 },
        onItemRender: ({ context }) => {
          const { className, title, description, onClickBox } = context;
          return (
            <div
              aria-hidden="true"
              onClick={onClickBox || null}
              className={`family-chart-group ${className || ''} ${onClickBox ? 'cursor' : ''}`}
            >
              <div className="title">
                {title}
                {onClickBox && <i className="fa fa-external-link ml-3" aria-hidden="true" />}
              </div>
              <div className="description">{description}</div>
            </div>
          );
        },
      },
    ],
    items,
  };

  return (
    <div className={`family-group ${wrapperClass || ''}`}>
      <div className="col-md-12 row group-label-details mt-2">
        {(colorList || colorDefaultList).map(val => (
          <span className="d-flex">
            <span style={{ backgroundColor: val.color }} className="label-color" />
            <span className="label-title">{t(val.label)}</span>
          </span>
        ))}
      </div>

      <OrgDiagram centerOnCursor config={config} />
    </div>
  );
};

FamilyChartGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  context: PropTypes.objectOf(PropTypes.any),
  wrapperClass: PropTypes.string,
};

FamilyChartGroup.defaultProps = {
  items: [],
  context: {},
  wrapperClass: '',
};

export default FamilyChartGroup;
