import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ExpandTable, CardHeaderForm } from '../common';
import LineTable from './LinesTable';

class ServiceTableUpgradeDowngrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowHideLines: {},
    };
  }

  handleClickServicesFormBtn = index => {
    let { isShowHideLines } = this.state;
    if (isShowHideLines[index]) {
      isShowHideLines[index] = false;
    } else {
      isShowHideLines = {};
      isShowHideLines[index] = true;
    }
    this.setState({
      isShowHideLines,
    });
  };

  render() {
    const {
      services,
      isFetchLine,
      onChangeFormLine,
      addLine,
      positionTrash,
      onToggle,
      onRemoveLine,
      isRemoveTrashFromLine,
      isRenderTrashBinLineTable,
      t,
      isReadOnly,
      isBundle,
      onHandleInitNewLines,
      modeOverrideQuoteLines,
    } = this.props;
    const { isShowHideLines } = this.state;

    const overrideLinesColumn = modeOverrideQuoteLines
      ? [
          {
            name: 'selected',
            label: 'common:label.selected',
            style: { width: '100px' },
            render: (colName, item, index, t) => {
              const { isShowHideLines } = this.state;
              return (
                <div className="form-group col-md-12">
                  <button
                    type="button"
                    className="button button-border  x-small float-right"
                    disabled={item.action === 'CANCEL' || !item.newId || !modeOverrideQuoteLines}
                    onClick={() => {
                      this.handleClickServicesFormBtn(index);
                      if (!isShowHideLines[index] && onHandleInitNewLines) {
                        onHandleInitNewLines({ id: item.newId, item, index });
                      }
                    }}
                  >
                    {isShowHideLines[index] && t ? t('label.hideLines') : t('label.overrideLines')}
                  </button>
                </div>
              );
            },
          },
        ]
      : [];

    const tableColumns = [
      {
        name: 'action',
        label: 'common:label.action',
        className: 'fixed-width-table',
      },
      {
        name: 'reason',
        label: 'common:label.reason',
        className: 'fixed-width-table',
      },
      {
        name: 'packageName',
        label: 'common:label.packageName',
        className: 'fixed-width-table',
        render: (colName, item) => {
          return <span>{!isBundle ? item.newName : ''}</span>;
        },
      },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
        className: 'fixed-width-table',
        render: (colName, item) => {
          return <span>{isBundle ? item.newName : item.bundleName}</span>;
        },
      },
      ...overrideLinesColumn,
    ];
    const tableConfig = {
      columns: tableColumns,
      data: services,
      sorted: {},
      onSort: this.onSortColumn,
    };
    const slt = t('selections:serviceType')().find(val => val.value === services[0].serviceType);

    const listUpgradeService = services && services.length ? services.filter(val => !!val.newName) : [];
    // console.log('listUpgradeService', listUpgradeService)
    return (
      <div className="col-md-12 pb-3">
        {/* <CardHeaderForm title={t('label.services')} label={t('label.serviceType')} value={slt ? slt.label : ''} /> */}
        {/* {listUpgradeService &&
          listUpgradeService.length > 0 &&
          listUpgradeService.map(sv => {
            return (
           
            );
          })} */}

        <CardHeaderForm
          title={`${t('label.serviceType')}: ${slt ? slt.label : ''}`}
          wrapperClass="font-weight-normal"
          // label={t('label.serviceType')}
          // value={slt ? slt.label : ''}
        />
        <h6 className="detail-up-down-order">
          {`Upgrading ${isBundle ? 'Bundle' : 'Package'} from ${
            isBundle ? listUpgradeService[0].bundleName : listUpgradeService[0].packageName
          } to ${listUpgradeService[0].newName}`}
        </h6>
        <ExpandTable
          {...tableConfig}
          onClickRow={this.onClickRow}
          isExpand={cloneDeep(isShowHideLines)}
          positionTrash={positionTrash}
          tableClass="service-table"
          expandRender={(item, index) => {
            return (
              <LineTable
                key="TableFormLine"
                service={item}
                isFetchLine={isFetchLine}
                isRemoveTrashFromLine={isRemoveTrashFromLine}
                addLine={e => addLine(index, e)}
                positionTrash={positionTrash}
                isRenderTrashBinLineTable={isRenderTrashBinLineTable}
                onToggle={onToggle}
                onRemoveLine={({ itemLine }) => onRemoveLine({ itemLine, itemService: item })}
                onChangeFormLine={e => onChangeFormLine(index, e)}
                isReadOnly={isReadOnly && item.isDeafultService}
                isUpDownGrade
              />
            );
          }}
        />
      </div>
    );
  }
}

ServiceTableUpgradeDowngrade.propTypes = {
  services: PropTypes.arrayOf(PropTypes.any),
  isFetchLine: PropTypes.bool,
  positionTrash: PropTypes.bool,
  onChangeFormLine: PropTypes.func,
  addLine: PropTypes.func,
  onToggle: PropTypes.func,
  onChangeProvisioningId: PropTypes.func,
  onRemoveLine: PropTypes.func,
  onRemoveItem: PropTypes.func,
  isRemoveTrashFromLine: PropTypes.bool,
  isRenderTrashBinServiceTable: PropTypes.bool,
  isRenderTrashBinLineTable: PropTypes.bool,
  t: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isBundle: PropTypes.bool,
  onHandleInitNewLines: PropTypes.func,
  modeOverrideQuoteLines: PropTypes.number,
};

ServiceTableUpgradeDowngrade.defaultProps = {
  services: [],
  isFetchLine: false,
  positionTrash: false,
  isRemoveTrashFromLine: false,
  isRenderTrashBinServiceTable: false,
  isRenderTrashBinLineTable: false,
  isReadOnly: false,
  isBundle: false,
  onChangeFormLine: () => {},
  addLine: () => {},
  onToggle: () => {},
  onChangeProvisioningId: () => {},
  onHandleInitNewLines: () => {},
  onRemoveItem: () => {},
  onRemoveLine: () => {},
  t: () => {},
  modeOverrideQuoteLines: 0,
};

export default withTranslation(['common', 'selections'])(ServiceTableUpgradeDowngrade);
