import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CreditNotesSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.reason',
      name: 'reason',
      type: 'select',
      tOptions: 'selections:reasonConfig',
      isClearable: true,
    },
    {
      label: 'label.customReason',
      name: 'customReason',
      isClearable: true,
      type: 'select',
      options: [],
    },
    {
      label: 'label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:noteStatus',
      isClearable: true,
    },
    {
      label: 'label.type',
      name: 'arType',
      type: 'select',
      tOptions: 'selections:arOpsType',
      isClearable: true,
    },
    {
      label: 'label.minimumThreshold',
      name: 'minimumThreshold',
      type: 'number',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.groupByNote',
      name: 'groupByNote',
      type: 'select',
      tOptions: 'selections:selectBool',
      isClearable: true,
    },
    {
      label: 'label.organization',
      name: 'company',
    },
    {
      label: 'label.commercialName',
      name: 'commercialName',
    },
    {
      label: 'label.invoiceCancellation',
      name: 'invoiceCancelllation',
      type: 'select',
      tOptions: 'selections:selectBool',
      isClearable: true,
    },
    {
      label: 'label.invoiceStatus',
      name: 'invoiceStatus',
      type: 'select',
      tOptions: 'selections:invoiceUnitStatus',
      isClearable: true,
    },
    {
      label: 'label.folioStatus',
      name: 'folioStatus',
      type: 'select',
      tOptions: 'selections:folioStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-6';
}

CreditNotesSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CreditNotesSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CreditNotesSearchForm);
