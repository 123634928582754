import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectItemsId } from '../App/actions';
import ModalSelector from '../../components/Modals/ModalSelector';
import { ItemSearchForm } from '../../components/ArOperationsHub';

class ModalItem extends PureComponent {
  render() {
    const tableConfig = {
      columns: [
        {
          name: 'id',
          label: 'common:label.id',
          render: (colName, item) => <span className="">{item.id}</span>,
        },
        {
          name: 'name',
          label: 'common:label.name',
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'company',
          label: 'common:label.company',
        },
        {
          name: 'status',
          label: 'common:label.status',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    const { isOpen, onSelect, onCancel, selectedParentId, selectItemsId, t, defaultSearchParams } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseItem')}
        isOpen={isOpen}
        SearchForm={ItemSearchForm}
        searchItems={selectItemsId}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  isOpen: PropTypes.bool,
  selectItemsId: PropTypes.func.isRequired,
};

ModalItem.defaultProps = {
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { selectItemsId })(ModalItem));
