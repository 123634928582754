import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const getRegR00401Report = `getRegR00401Report(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRegR00401Report} {
        c601
        c602
        c101
        c102
        c103
        r0040101
      }
    }
  `;
};
