import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['quarter'])}`
    : '';
  const employeeCostingPNLReport = `employeeCostingPNLReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${employeeCostingPNLReport} {
        employeeId
        employeeName
        status
        jobTitle
        clientId
        clientName
        clientJobTitle
        monthOne
        monthTwo
        monthThree
        quarter
        monthOneGrossSal
        monthTwoGrossSal
        monthThreeGrossSal
        totalGrossSal
        monthOneActualCost
        monthTwoActualCost
        monthThreeActualacost
        totalActualCost
        monthOneRevenueUsd
        monthTwoRevenueUsd
        monthThreeRevenueUsd
        totalRevenueUsd
        monthOneRevenueMxn
        monthTwoRevenueMxn
        monthThreeRevenueMxn
        totalRevenueMxn
        monthOneActualIncomeMxn
        monthTwoActualIncomeMxn
        monthThreeActualIncomeMxn
        totalActualIncome
        monthOneSalaryToBilling
        monthTwoSalaryToBilling
        monthThreeSalaryToBilling
        averageSalaryToBilling
      }
    }
  `;
};
