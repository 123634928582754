import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class CommitmentDataSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.commitmentTerm',
      name: 'commitmentTerm',
      type: 'select',
      tOptions: 'selections:commitmentTerm',
      isClearable: true,
      required: true,
      defaultValue: 'LATEST_TERM',
    },
    {
      label: 'label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
      required: true,
      defaultValue: 'ALL',
    },
    {
      label: 'label.organization',
      name: 'organization',
    },
    {
      label: 'label.clientId',
      name: 'clientId',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.groupBy',
      name: 'groupBy',
      tOptions: 'selections:selectBool',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-6';
}

CommitmentDataSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CommitmentDataSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CommitmentDataSearchForm);
