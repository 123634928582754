import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import ModalSelector from '../../Modals/ModalSelector';
import { SwitchExport, ExcelExport, ButtonExport } from '../../common';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelRevenueRecognitionJournals';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfRevenueRecognitionJournals';

class ModalSearchRevRecJournal extends Component {
  state = {
    isActivePdf: false,
    data: [],
  };

  buttonRef = React.createRef();

  doGetRevenueRecognitionSummary = (payload, cb) => {
    const { getRevenueRecognitionSummary, id } = this.props;
    getRevenueRecognitionSummary(id, ({ success, data }) => {
      this.setState({ data: success ? data : [] });
      if (cb) cb({ success, data });
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    const { isActivePdf, data } = this.state;
    if (isActivePdf) {
      const { t } = this.props;
      convertJson2Pdf({
        data: data && data.length ? data : [],
        t,
        title: t('label.revenueRecognitionJournals').toLocaleUpperCase(),
        fileName: `${t('label.revenueRecognitionJournals')
          .toLocaleLowerCase()
          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
      });
    } else {
      this.buttonRef.current.click();
    }
  };

  render() {
    const {
      t,
      defaultSearchParams,
      isOpen,
      id,
      searchRevRecJournal,
      onSelect,
      onCancel,
      selectedParentId,
      itemSelect,
      children,
      isTopChildren,
    } = this.props;
    const { isActivePdf, data } = this.state;
    const tableConfig = {
      columns: [
        {
          name: 'journalId',
          label: 'common:label.id',
        },
        {
          name: 'batchId',
          label: 'label.batchId',
        },
        {
          name: 'revenueStartDate',
          label: 'common:label.startDate',
        },
        {
          name: 'revenueEndDate',
          label: 'common:label.endDate',
        },
        {
          name: 'amount',
          label: 'common:label.amount',
        },
        {
          name: 'amountRecognized',
          label: 'common:label.amountRecognized',
        },
        {
          name: 'glAccount',
          label: 'common:label.glAccount',
        },
        {
          name: 'calendarPeriod',
          label: 'common:label.calendarPeriod',
        },
        {
          name: 'revenueRecognitionType',
          label: 'common:label.revenueRecognitionType',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t
              ? t('selections:revenueRecognitionType')().find(val => val.value === item.revenueRecognitionType)
              : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'objectId',
          label: 'common:label.objectId',
        },
        {
          name: 'objectType',
          label: 'common:label.objectType',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:revenueObjectType')().find(val => val.value === item.objectType) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
      ],
    };
    return (
      <ModalSelector
        modalTitle={`${t('label.revenueRecognitionJournals')}`}
        isOpen={isOpen}
        SearchForm={null}
        searchItems={this.doGetRevenueRecognitionSummary}
        tableColumns={tableConfig.columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        defaultSearchParams={defaultSearchParams}
        isNoNeedSelector
        isBack
        isNotRenderPagination
        isTopChildren
      >
        <div className="mt-3 d-flex mb-4 pt-3 col-md-12">
          <div className="ml-auto mt-2 mb-auto mr-3">
            <SwitchExport
              onChange={this.onChangeSwitch}
              wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
              title={t('label.excel')}
              checked={isActivePdf}
              rightTitle={t('label.pdf')}
              name="SwitchExport"
            />
          </div>
          <>
            <ButtonExport onExport={this.onExport} />
            <ExcelExport
              element={<button type="button" className="display-none" ref={this.buttonRef} />}
              nameSheet={t('label.revenueRecognitionJournals').toLocaleUpperCase()}
              multiDataSet={convertJson2Sheet({
                data: data && data.length ? data : [],
                t,
                title: t('label.revenueRecognitionJournals').toLocaleUpperCase(),
              })}
              fileName={`${t('label.revenueRecognitionJournals')
                .toLocaleLowerCase()
                .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
            />
          </>
        </div>
      </ModalSelector>
    );
  }
}

ModalSearchRevRecJournal.propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  searchRevRecJournal: PropTypes.func,
};

ModalSearchRevRecJournal.defaultProps = {
  isOpen: false,
  onSelect: () => {},
  onCancel: () => {},
  selectedParentId: '',
  searchRevRecJournal: () => {},
};

export default withTranslation('common')(ModalSearchRevRecJournal);
