import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import {
  uploadMultiPartFiles,
  getBatchPaymentFileConfigSelect,
  getCurrencyConfigApp,
  saveOutputTemplateByType,
  getOutputTemplateByType,
} from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { PaymentSuspenseSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
  DragDropConfigExportTemplate,
  ModalWithItem,
} from '../../../../components/common';
import { sortPaymentSuspense } from '../../constants';
import { getPaymentSuspense, getAllPaymentSuspense } from '../../actions';
import { makeGetListPaymentSuspense, makeGetListAllPaymentSuspense } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  formatMoneyValue,
  configOutputTemplateByType,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
// import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';
import ModalViewPaymentActivity from '../../../../components/common/ModalViewPaymentActivity';

let tableColumnsOutput = null;
let tableColumnsDefault = null;

const sizeDownloadAll = 300;

class PaymentSuspense extends Component {
  state = {
    isActivePdf: false,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listPaymentSuspense: [],
    isOpenModalViewPaymentActivity: false,
    isSuspense: false,
    listAllPaymentSuspenseFetch: [],
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      listPaymentSuspense: props.listPaymentSuspense,
      totalCount: getPageTotalCount({ ...state, items: props.listPaymentSuspense }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetPaymentSuspenseReport },
      getBatchPaymentFileConfigSelect,
      getCurrencyConfigApp,
    } = this.props;
    if (modeGetPaymentSuspenseReport) {
      this.doGetReportPaymentSuspense();
      this.doGetOutputTemplateByType();
      // getBatchPaymentFileConfigSelect('', ({ success, data }) => {
      //   if (success && data && data.files) {
      //     const listPaymentOptions = data.files.map(val => {
      //       return { label: val.name, value: val.name };
      //     });
      //     this.setState({
      //       listPaymentOptions: [
      //         { label: 'Automated', value: 'Automated' },
      //         { label: 'Manual', value: 'Manual' },
      //         ...listPaymentOptions,
      //       ],
      //     });
      //   }
      // });
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetReportPaymentSuspense();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetReportPaymentSuspense());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportPaymentSuspense());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetReportPaymentSuspense();
    });
  };

  doGetReportPaymentSuspense = () => {
    const { filter, page, size, sorted } = this.state;
    const { getPaymentSuspense } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort:
        !isEmpty(sorted) && sortPaymentSuspense[sorted.sortCol]
          ? sortPaymentSuspense[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getPaymentSuspense(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistPaymentSuspense = cb => {
    const { filter, sorted } = this.state;
    const payload = {
      page: 1,
      size: sizeDownloadAll,
      filter,
      sort: !isEmpty(sorted) ? sortPaymentSuspense[sorted.sortCol][sorted.sortDir] : null,
    };
    // getAllPaymentSuspense(payload, () => {
    //   if (cb) cb();
    // });
    this.setState({ listAllPaymentSuspenseFetch: [] });
    this.doSearchAllPaymentSuspense(payload, cb);
  };

  doSearchAllPaymentSuspense = (payload, cb) => {
    const { getAllPaymentSuspense } = this.props;
    const newPayload = payload;
    getAllPaymentSuspense(newPayload, ({ success, data }) => {
      const { listAllPaymentSuspenseFetch } = this.state;
      if (success && data && data.length)
        this.setState({ listAllPaymentSuspenseFetch: [...listAllPaymentSuspenseFetch, ...data] });
      if (success && data && data.length === sizeDownloadAll) {
        newPayload.page += 1;
        this.doSearchAllPaymentSuspense(newPayload, cb);
      } else if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistPaymentSuspense(() => {
      const { isActivePdf, listAllPaymentSuspenseFetch } = this.state;
      if (isActivePdf) {
        const { t } = this.props;
        convertJson2Pdf({
          data: listAllPaymentSuspenseFetch,
          t,
          title: t('reportPage:sidebar.paymentSuspense').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.paymentSuspense')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistPaymentSuspense(() => {
      const { t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf, listAllPaymentSuspenseFetch } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_ACTIVE_SUBSCRIPTION_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.paymentSuspense')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.paymentSuspense')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllPaymentSuspenseFetch,
            t,
            columns: tableColumnsOutput,
            listOptionFields: {
              accountType: 'accountType',
              paymentMethod: 'paymentMethod',
              paymentTerm: 'paymentTerm',
              status: 'statusPayment',
            },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllPaymentSuspenseFetch,
          t,
          title: t('reportPage:sidebar.paymentSuspense').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.paymentSuspense')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
          fontSize: 3,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.paymentSuspense')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  onToggleModalViewPaymentActivity = (item, isSuspense) => {
    const { isOpenModalViewPaymentActivity } = this.state;
    this.setState({ isOpenModalViewPaymentActivity: !isOpenModalViewPaymentActivity, itemSelected: item, isSuspense });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('PAYMENT_SUSPENSE_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumnsDefault,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  onHandleSaveConfig = columns => {
    const { saveOutputTemplateByType } = this.props;
    const { idOutputTemplate, typeOutputTemplate } = this.state;
    const payload = {
      id: idOutputTemplate,
      type: typeOutputTemplate,
      source: columns.sourceFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
      target: columns.targetFields.items.map((val, index) => {
        return { name: val.name, index: index + 1 };
      }),
    };

    saveOutputTemplateByType(payload, ({ success }) => {
      if (success) {
        this.doGetOutputTemplateByType();
        this.setState({ exportColumnsSave: columns, isOpenModalExportConfig: false });
      }
    });
  };

  onToggleModalExportConfig = () => {
    const { isOpenModalExportConfig } = this.state;
    this.setState({ isOpenModalExportConfig: !isOpenModalExportConfig });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listPaymentSuspense,
      listPaymentOptions,
      currencyOptions,
      emails,
      isOpenModalViewPaymentActivity,
      itemSelected,
      isSuspense,
      isOpenModalExportConfig,
      exportColumnsSave,
      listAllPaymentSuspenseFetch,
    } = this.state;
    const {
      t,
      permissions: { modeGetPaymentSuspenseReport, modeDownloadReport, modeGetMailReport },
    } = this.props;
    if (!modeGetPaymentSuspenseReport) return '';
    const tabelColumnPaymentSuspense = [
      {
        name: 'paymentSuspenseId',
        label: 'label.paymentSuspenseId',
        render: (colName, item) =>
          item.paymentSuspenseId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, true)}
            >
              {item.paymentSuspenseId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'referenceId',
        label: 'label.paymentReferenceId',
        render: (colName, item) =>
          item.referenceId ? (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={evt => this.onToggleModalViewPaymentActivity(item, false)}
            >
              {item.referenceId}
            </button>
          ) : (
            ''
          ),
      },
      {
        name: 'createdDate',
        label: 'label.createdDate',
        sortable: true,
      },
      {
        name: 'paymentDate',
        label: 'label.paymentDate',
        sortable: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        fieldOption: 'statusPayment',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'sourceCurrency',
        label: 'label.sourceCurrency',
      },
      {
        name: 'amount',
        label: 'label.paymentAmount',
        sortable: true,
      },
      {
        name: 'unallocatedAmount',
        label: 'common:label.unallocatedAmount',
      },
      {
        name: 'fileName',
        label: 'common:label.fileName',
      },
      {
        name: 'fileDate',
        label: 'common:label.paymentFileDate',
        sortable: true,
      },
      {
        name: 'organization',
        label: 'label.organization',
      },
      {
        name: 'accountId',
        label: 'label.accountId',
        sortable: true,
      },
      {
        name: 'firstName',
        label: 'label.firstName',
      },
      {
        name: 'lastName',
        label: 'label.lastName',
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountType')().find(val => val.value === item.accountType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'paymentMethod',
        label: 'label.paymentMethod',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.paymentMethod) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'paymentSource',
        label: 'label.paymentSource',
      },
      {
        name: 'paymentSourceId',
        label: 'label.paymentSourceId',
      },
      {
        name: 'failureReason',
        label: 'label.failureReason',
        sortable: true,
      },

      // {
      //   name: 'exchangeRate',
      //   label: 'label.exchangeRate',
      // },
      {
        name: 'invoiceUnitId',
        label: 'label.invoiceUnitId',
      },
      {
        name: 'userId',
        label: 'label.userId',
      },
      {
        name: 'paymentDescription',
        label: 'label.paymentDescription',
      },
      {
        name: 'currency',
        label: 'label.accountCurrency',
      },
      {
        name: 'exchangeRate',
        label: 'label.paymentDateExchangeRate',
        render: (colName, item) => {
          return <span>{item.exchangeRate}</span>;
        },
      },
      {
        name: 'currentDateExchangeRate',
        label: 'label.currentExchangeRate',
        render: (colName, item) => {
          return <span>{item.currentDateExchangeRate}</span>;
        },
      },
      {
        name: 'unRealizedGain',
        label: 'label.unrealizedLossGainInSystemCurrency',
        isSupportDangerouslySetInnerHTML: true,
        exportLabel: 'label.unrealizedLossGainInSystemCurrencyExport',
        isSupportRedFront: true,
        style: { textAlign: 'center' },
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.unRealizedGain)}</span>;
        },
      },
      {
        name: 'closeReason',
        label: 'label.closeSuspenseReason',
      },
    ];

    tableColumnsDefault = tabelColumnPaymentSuspense;
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];

    return (
      <div className="col-md-12">
        <PageTitle
          linkTo={RouteNames.report.path}
          titleBtn="back"
          items={[
            { name: t('label.report'), url: RouteNames.report.path },
            { name: t('reportPage:sidebar.paymentSuspense') },
          ]}
        />
        <br />
        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.paymentSuspense')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <PaymentSuspenseSearchForm
                  isMultipleOptionInject
                  optionsInject={{
                    // paymentSource: listPaymentOptions,
                    sourceCurrency: currencyOptions,
                  }}
                  onSubmit={this.onHandleSubmitSearch}
                />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  <button
                    type="submit"
                    onClick={this.onToggleModalExportConfig}
                    className="button x-small mr-2 btn-config"
                  >
                    {/* {t('label.outputConfig')} */}
                    <i className="ti-list" />
                  </button>
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.paymentSuspense').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllPaymentSuspenseFetch,
                          t,
                          title: t('reportPage:sidebar.paymentSuspense').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.paymentSuspense')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listPaymentSuspense}
                    />
                  )}
                </div>
                <DataTable
                  columns={tableColumnsOutput}
                  data={listPaymentSuspense && listPaymentSuspense.length ? listPaymentSuspense : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalViewPaymentActivity
          id={itemSelected?.paymentSuspenseId || null}
          isOpen={isOpenModalViewPaymentActivity}
          onToggle={evt => this.onToggleModalViewPaymentActivity()}
          itemSelected={{ ...itemSelected, paymentRefId: itemSelected?.referenceId || null }}
          title={isSuspense ? t('label.paymentSuspenseActivity') : t('label.paymentSuspenseReferenceActivity')}
          isSuspense={isSuspense}
        />
        <ModalWithItem
          onToggle={this.onToggleModalExportConfig}
          isOpen={isOpenModalExportConfig}
          wrapperClass="modal-config"
        >
          <div className="col-md-12 d-flex">
            <div className="column-config" onClick={this.onToggleModalExportConfig} />
            <div className="body-config">
              <DragDropConfigExportTemplate
                onHandleSave={this.onHandleSaveConfig}
                exportColumns={exportColumns}
                onToggle={this.onToggleModalExportConfig}
              />
            </div>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

PaymentSuspense.propTypes = {
  getPaymentSuspense: PropTypes.func,
  getAllPaymentSuspense: PropTypes.func,
  listAllPaymentSuspense: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

PaymentSuspense.defaultProps = {
  getPaymentSuspense: () => {},
  getAllPaymentSuspense: () => {},
  listAllPaymentSuspense: [],
};

const mapStateToProps = createStructuredSelector({
  listPaymentSuspense: makeGetListPaymentSuspense() || [],
  listAllPaymentSuspense: makeGetListAllPaymentSuspense() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getPaymentSuspense,
    getAllPaymentSuspense,
    getBatchPaymentFileConfigSelect,
    getCurrencyConfigApp,
    uploadMultiPartFiles,
    saveOutputTemplateByType,
    getOutputTemplateByType,
  })(PaymentSuspense)
);
