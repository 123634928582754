import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['status'])}`
    : '';
  const searchEmployeeBonus = `searchEmployeeBonus(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchEmployeeBonus} {
        id
        startDate
        bonusPeriodUnit
        employeeBonusData {
          index
          employeeId
          employeeName
          amount
        }
      }
    }
  `;
};
