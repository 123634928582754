import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataGet = parseToMutationRequest(data, ['gatewayName', 'merchantName']);
  const getGatewayApiMapByName = `getGatewayApiMapByName(input: ${dataGet})`;
  return gql`
    query {
      ${getGatewayApiMapByName} {
        id
        gatewayName
        providerName
        apiName
        objectType
      }
    }
  `;
};
