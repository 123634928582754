import gql from 'graphql-tag';

export default () => {
  const getPaymentMethodUrls = 'getPaymentMethodUrls(input: { type: PAYMENT_METHOD_URL })';
  return gql`
    query {
      ${getPaymentMethodUrls} {
        id
        type
        paymentMethods {
          index
          paymentMethod
          providerName
          paymentUrls {
            index
            txnType
            endpointUrl
          }
        }
      }
    }
  `;
};
