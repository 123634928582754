import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { dataSelect } from '../../../../constantsApp';
import { GenericInput } from '../../../../components/common';

const contactInputs = [
  {
    label: 'label.salutation',
    wrapperClass: 'col-md-4',
    name: 'salutation',
    // required: true,
  },
  {
    label: 'label.firstName',
    wrapperClass: 'col-md-4',
    name: 'firstName',
    required: true,
  },
  {
    label: 'label.middleName',
    wrapperClass: 'col-md-4',
    name: 'middleName',
    // required: true,
  },
  {
    label: 'label.lastName',
    wrapperClass: 'col-md-4',
    name: 'lastName',
    required: true,
  },
  {
    label: 'label.position',
    wrapperClass: 'col-md-4',
    name: 'position',
    // required: true,
  },
  {
    label: 'label.organization',
    wrapperClass: 'col-md-4',
    name: 'organization',
    // required: true,
  },
  {
    label: 'label.email',
    wrapperClass: 'col-md-4',
    name: 'email',
    type: 'email',
    required: true,
  },
];

class ContactForm extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    const item = props.item && props.item.length > 0 ? props.item[0] : {};

    return {
      ...item,
      phones: item && item.phones ? item.phones : [],
      item: props.item || [],
    };
  }

  onChangeItem = () => {
    const { onChangeItem } = this.props;
    if (onChangeItem) {
      const { item, ...values } = this.state;
      onChangeItem([{ ...values }]);
    }
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value }, () => this.onChangeItem());
  };

  handlePhoneInput = (index, { name, value }) => {
    const { phones } = this.state;
    const phone = phones[index];
    phone[name] = value;
    phones[index] = phone;
    this.setState({ phones: [...phones] }, () => this.onChangeItem());
  };

  onAddPhone = e => {
    e.preventDefault();
    const { phones } = this.state;
    const typePhoneArr = ['MOBILE', 'WORK', 'HOME', 'FAX'];
    map(phones, item => {
      const index = typePhoneArr.indexOf(item.type);
      typePhoneArr.splice(index, 1);
    });
    if (isEmpty(typePhoneArr)) {
      return toast('No unique type phone remaining', { type: toast.TYPE.ERROR });
    }

    return this.setState({ phones: [...phones, { number: '', type: typePhoneArr[0] }] });
  };

  onRemovePhone = (e, index) => {
    e.preventDefault();
    const { phones } = this.state;
    phones.splice(index, 1);
    this.setState({ phones });
  };

  render() {
    const { t, onAddNewContact } = this.props;
    const { item } = this.state;
    return (
      <div className="form-row">
        {item &&
          item.length > 0 &&
          contactInputs.map(input => (
            <GenericInput
              key={input.name}
              {...input}
              value={this.state[input.name]}
              onChange={this.handleInputChange}
            />
          ))}
        <div className="col-md-4">
          {this.state.phones.map((phone, idx) => {
            return (
              <div className="row" key={`phone-idx-${idx}`}>
                <GenericInput
                  value={this.state.phones[idx].number}
                  type="tel"
                  // placeholder="Phone Number"
                  label={t('label.phone')}
                  name="number"
                  required
                  wrapperClass="col-md-6"
                  onChange={e => this.handlePhoneInput(idx, e)}
                />
                <GenericInput
                  value={this.state.phones[idx].type}
                  type="select"
                  placeholder="Phone Type"
                  label=" "
                  wrapperClass="col-md-5"
                  name="type"
                  onChange={e => this.handlePhoneInput(idx, e)}
                  // tOptions="selections:phoneType"
                  options={dataSelect.phoneType.map(item => ({
                    value: item.value,
                    label: t('selections:phoneType')().find(val => val.value === item.label).label,
                    isDisabled: this.state.phones.map(item => item.type).indexOf(item.value) > -1,
                  }))}
                />
                <div className="form-group col-md-1 d-flex align-items-end">
                  <button type="button" className="btn-trash" onClick={e => this.onRemovePhone(e, idx, phone)}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              </div>
            );
          })}
          {item && item.length > 0 && (
            <button
              type="button"
              className="button button-border x-small float-left btn-add-phone-user"
              onClick={this.onAddPhone}
            >
              {t('label.addPhone')}
            </button>
          )}
        </div>
        <div className="col-md-12">
          <button
            disabled={item && item.length > 0}
            type="button"
            className="button button-border black x-small mt-3"
            onClick={onAddNewContact}
          >
            +
            {t('label.addNewContact')}
          </button>
        </div>
      </div>
    );
  }
}

ContactForm.propTypes = {
  item: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  onChangeItem: PropTypes.func,
  creatingMode: PropTypes.bool,
};

ContactForm.defaultProps = {
  item: {},
  isOpen: false,
  creatingMode: true,
};
export default withTranslation('common')(ContactForm);
