import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep, sortBy } from 'lodash';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import { renderSidebar, listConfigDefault } from './constants';
import { checkPermissionForAllPages, checkPermissionGetUserPreferences } from './CheckPermission';
import { checkPermissionGetObjectFiles } from '../ReportManagement/CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

const Sidebar = props => {
  const { isExpand = true, permissionsDashboard, permissionsReports } = props;

  let listKeyShowing = [];
  let modeGetUserPreferences = 0;
  let modeGetFolioCount = 0;
  if (!isHasPermissionWithMenu({ permissions: permissionsDashboard, listKeys: permissionList.dashboard })) return null;

  if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
    const listPermission = permissionsDashboard.dashboardModulePermissions;
    listKeyShowing = checkPermissionForAllPages({ listPermission });
    modeGetUserPreferences = checkPermissionGetUserPreferences({ listPermission });
  }

  if (permissionsReports && permissionsReports.reportModulePermissions) {
    const listPermission = permissionsReports.reportModulePermissions;
    modeGetFolioCount = checkPermissionGetObjectFiles({ listPermission });
    if (modeGetFolioCount) listKeyShowing.push('GET_OBJECT_FILES_REPORT');
  }

  const newListConfig = listConfigDefault.filter(val => listKeyShowing.indexOf(val.dashboardType) > -1);
  const newSideProps = sortBy(cloneDeep(renderSidebar({ listConfig: newListConfig })), ['index']);
  const newSidePropsSort = cloneDeep(newSideProps);
  if (newSidePropsSort && newSidePropsSort.length) {
    newSidePropsSort.forEach((val, index) => {
      if (val && val.childs && val.childs.length > 1) {
        newSidePropsSort[index].childs = sortBy(val.childs, ['index']);
      }
    });
  }

  const sideProps = {
    isExpand,
    items: [
      {
        isHaveExpand: true,
        mainTilte: 'dashboardPage:sidebar.dashboard',
        href: RouteNames.userPreferences.path,
        childs: [],
      },
      {
        isHaveExpand: true,
        mainTilte: 'dashboardPage:sidebar.config',
        childs: [
          {
            icon: 'ti-settings',
            label: 'dashboardPage:sidebar.userPreferences',
            href: RouteNames.userPreferences.path,
          },
        ],
      },
      {
        isHaveExpand: true,
        mainTilte: 'dashboardPage:sidebar.myDashboard',
        childs: [],
        href: RouteNames.myDashboard.path,
      },
      ...newSidePropsSort,
    ],
  };

  if (modeGetUserPreferences === 0)
    sideProps.items = sideProps.items.filter(item => item.mainTilte !== 'dashboardPage:sidebar.config');

  return <SideBar {...sideProps} />;
};

// export default Sidebar;
const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const dashboarReducer = store.get('dashboardReducer');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsDashboard = store.get('auth').toJS().permissionsApp.opsHubModules;
  const permissionsReports = store.get('auth').toJS().permissionsApp.opsHubModules;
  return {
    isExpand: sidebarExpanded,
    listConfig: dashboarReducer ? dashboarReducer.toJS().dashboardTileConfig : {},
    permissionsDashboard: permissionsDashboard.length
      ? permissionsDashboard.filter(a => a.moduleName === 'DASHBOARD')[0]
      : {},
    permissionsReports: permissionsReports.length ? permissionsReports.filter(a => a.moduleName === 'REPORTS')[0] : {},
  };
};

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  location: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  location: {},
  permissionsDashboard: {},
};

export default connect(mapStateToProps, {})(withRouter(Sidebar));
