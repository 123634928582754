/* eslint-disable radix */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _, { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import AccountTable from '../AccountTable';
import { getSubscriptionByAccountId, createOrder, getSubscriptionAssets } from '../actions';
import OrderSubscriptionDetail from './OrderSubscriptionDetail';
import dataForm from './dataForm';
import { makeGetSubscription } from '../selectors';
import { makeGetPermissionsOrderManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  checkPermissionSuspendOrderType,
  checkPermissionResumeOrderType,
  checkPermissionCancelOrderType,
} from '../CheckPermission';
import { orderEnum } from '../../../constantsApp';
import {
  StepProgressBar,
  ModalSelectSubscription,
  Breadcrumb,
  GenericInput,
  FormWithTableItem,
} from '../../../components/common';
import { getCcpPropertiesConfig } from '../../App/actions';
import { convertSubscriptionDataEdit } from '../utilities';
import RouteNames from '../../App/RouteNames';
import { orderListRadioButtons } from '../constants';
import { AttributesForm } from '../../../components/Orders';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';

const listSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.manageCreateOrder',
  },
];

class CancelSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectItem: {},
      stage: 0,
      type: '',
      forms: {},
      dataSubscription: {},
      isMultiSubscriptionEnabled: false,
      subscriptionsList: [],
      isPartialFulfillmentAllowed: null,
    };
  }

  componentDidMount() {
    this.doGetCcpPropertiesConfig();
  }

  doGetCcpPropertiesConfig = () => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
    }
  };

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState({
        customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
      });
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState({
            customAttributesByObjectType: (data && data.attributes) || [],
          });
        }
      }, 'ORDER');
    }
  };

  doInitPropertiesConfig = ccpPropertyList => {
    const multiSubscriptionEnabled = ccpPropertyList.find(val => val.property === 'multiSubscriptionEnabled');
    const partialProvisioningEnabled = ccpPropertyList.find(val => val.property === 'partialProvisioningEnabled');

    this.setState(
      {
        isMultiSubscriptionEnabled: multiSubscriptionEnabled && multiSubscriptionEnabled?.value === 'true',
        isPartialFulfillmentAllowed: partialProvisioningEnabled && partialProvisioningEnabled?.value === 'true',
      },
      () => {
        const {
          location: { state, pathname },
          getSubscriptionAssets,
        } = this.props;
        const { selectItem, type } = this.state;
        const typePath = pathname.replace('/orders/', '');

        if (type !== typePath) {
          const data = dataForm[typePath];
          if (selectItem && selectItem.id) {
            data.accountId = selectItem.id;
          }
          if (state && state.accountId) {
            selectItem.id = state.accountId;
            data.accountId = state.accountId;
            this.setState({ forms: data, type: typePath, selectItem: { ...selectItem } }, () => {
              this.doInitSubscription([state.data || {}]);
              getSubscriptionAssets({ id: state?.data?.id || null, status: state?.data?.status || null });
            });
          } else {
            this.setState({ forms: data, type: typePath, selectItem: { ...selectItem } }, () =>
              this.doGetSubcription()
            );
          }
        }
      }
    );
  };

  onClickRow = () => {};

  onSelectNext = rowItem => {
    const { forms, isPartialFulfillmentAllowed } = this.state;
    forms.accountId = rowItem.id;
    forms.isPartialFulfillmentAllowed = isPartialFulfillmentAllowed || null;
    this.setState(
      {
        selectItem: rowItem,
        forms,
      },
      () => {
        this.onPressNextButton();
        this.doGetCustomAttributesByObjectType();
      }
    );
  };

  onPressBackButton = () => {
    const { stage } = this.state;
    const {
      location: { state },
      history,
    } = this.props;
    if (state && state.accountId) {
      const redirectUrl = `/customers/${state.accountId}/services`;
      history.push(redirectUrl);
      return;
    }
    if (stage > 0) {
      this.setState({
        stage: stage - 1,
        isOpenModalSubscription: false,
        forms: {},
        dataSubscription: {},
        isMultiSubscriptionEnabled: false,
        subscriptionsList: [],
      });
    }
  };

  onChangeField = ({ key, data }) => {
    const { forms, dataSubscription } = this.state;
    const newForms = { ...forms, [key]: data };
    if (_.some(newForms, _.isEmpty)) {
      this.setState({
        forms: newForms,
      });
    } else if (
      newForms.renewalTermUnit === dataSubscription.renewalTermUnit &&
      newForms.renewalTerm === dataSubscription.renewalTerm.toString()
    ) {
      this.setState({
        forms: newForms,
      });
    } else {
      this.setState({
        forms: newForms,
      });
    }
  };

  onHandleChangeOrderForm = e => {
    const { forms } = this.state;
    const newData = _.cloneDeep(forms);
    if (e.fieldName === 'billingOnlyFlag' && e.value) {
      newData.provisioningOnlyFlag = null;
    }
    if (e.fieldName === 'provisioningOnlyFlag' && e.value) {
      newData.billingOnlyFlag = null;
    }
    newData[e.fieldName] = e.value;

    this.setState({
      forms: newData,
    });
  };

  onPressNextButton = () => {
    this.doGetSubcription();
  };

  doInitSubscription = data => {
    const { forms } = this.state;
    const dataList = data.filter(item => item.status !== 'CLOSED');
    const dataCurrent = dataList && dataList.length ? dataList[0] : {};
    this.setState({
      stage: 1,
      dataSubscription: dataCurrent,
      subscriptionsList: dataList,
      forms: {
        ...forms,
        renewalTermUnit: dataCurrent.renewalTermUnit,
        subscriptionName: dataCurrent.name || null,
        renewalTerm:
          dataCurrent.renewalTerm || dataCurrent.renewalTerm === 0 ? dataCurrent.renewalTerm.toString() : null,
        initialTerm:
          dataCurrent.initialTerm || dataCurrent.initialTerm === 0 ? dataCurrent.initialTerm.toString() : null,
        initialTermUnit: dataCurrent.initialTermUnit ? dataCurrent.initialTermUnit.toString() : null,
        trialTerm: dataCurrent.trialTerm || dataCurrent.trialTerm === 0 ? dataCurrent.trialTerm.toString() : null,
        trialTermUnit: dataCurrent.trialTermUnit ? dataCurrent.trialTermUnit.toString() : null,
      },
    });
  };

  doGetSubcription = () => {
    const { stage, selectItem, isMultiSubscriptionEnabled } = this.state;
    const { doGetSubscriptionByAccountId } = this.props;
    if (selectItem.id) {
      if (stage === 0) {
        doGetSubscriptionByAccountId(
          selectItem.id,
          (status, data) => {
            if (status) {
              const isMulti =
                data &&
                data.length > 1 &&
                isMultiSubscriptionEnabled &&
                data.filter(val => val.status !== 'CLOSED') &&
                data.filter(val => val.status !== 'CLOSED').length > 1;
              if (!isMulti) this.doInitSubscription(data);

              this.setState({
                stage: stage + 1,
                isOpenModalSubscription: isMulti,
                subscriptionData: data || [],
              });
            } else {
              toast(data, { type: toast.TYPE.ERROR });
            }
          },
          true
        );
      }
    }
  };

  onPressCreateButton = () => {
    const { doCreateOrder } = this.props;
    const { forms, customAttributesByObjectType } = this.state;
    const temp = [];
    if (customAttributesByObjectType && customAttributesByObjectType.length)
      customAttributesByObjectType.map(item => {
        if (item.value && item.isModify) {
          temp.push({ name: item.name, value: item.value });
        }
      });

    doCreateOrder({
      ...forms,
      type: forms.type ? forms.type : orderEnum.type.cancel,
      orderCustomAttributes: temp && temp.length ? temp : null,
      subscriptions: [{ index: 1, subscriptionName: forms.subscriptionName, ...convertSubscriptionDataEdit(forms) }],
    });
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  renderContent = () => {
    const {
      stage,
      selectItem,
      type,
      forms,
      isMultiSubscriptionEnabled,
      subscriptionsList,
      customAttributesByObjectType,
    } = this.state;
    const { isResume } = this.props;

    switch (stage) {
      case 0:
        return (
          <AccountTable
            subscriptionStatus={!isResume ? 'ACTIVE' : 'SUSPENDED'}
            statusRemove={isResume ? ['ACTIVE'] : null}
            type={type}
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            onSelectNext={this.onSelectNext}
            isNoPageTitle
          />
        );
      case 1:
        return (
          <>
            <div className="col-md-12 row justify-content-center mt-3 mb-2">
              {orderListRadioButtons.map(item => (
                <GenericInput
                  key={item.name}
                  disabled={item.disabled}
                  value={forms[item.name] || null}
                  onChange={({ name, value }) => this.onHandleChangeOrderForm({ fieldName: name, value })}
                  wrapperClass={item.wrapperClass || 'col-md-4'}
                  {...item}
                />
              ))}
            </div>
            <OrderSubscriptionDetail
              isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
              onChangeField={this.onChangeField}
              accountNumber={selectItem.id}
              forms={forms}
              subscriptionsList={subscriptionsList}
            />
            <FormWithTableItem title="label.customerAttributes">
              <div className="col-md-12 p-0">
                <AttributesForm
                  customAttributesByObjectType={customAttributesByObjectType}
                  onChange={this.onHandleChangeOrderAttribute}
                />
              </div>
            </FormWithTableItem>
          </>
        );
      default:
        return null;
    }
  };

  onSelectStep = step => {
    const { stage } = this.state;
    if (step < stage + 1)
      this.setState({
        stage: step - 1,
        forms: {},
        dataSubscription: {},
        isMultiSubscriptionEnabled: false,
        subscriptionsList: [],
      });
  };

  render() {
    const { stage, type, isOpenModalSubscription, subscriptionData } = this.state;
    const { subscription = {}, isSuspend, permissionOrder, isResume, t, getSubscriptionAssets } = this.props;
    const isCancel = true;
    const {
      location: { state },
    } = this.props;

    let modeSuspendOrder = 0;
    let modeResumeOrderType = 0;
    let modeCancelOrderType = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeSuspendOrder = checkPermissionSuspendOrderType({ listPermission });
      modeResumeOrderType = checkPermissionResumeOrderType({ listPermission });
      modeCancelOrderType = checkPermissionCancelOrderType({ listPermission });
    }

    if (modeSuspendOrder === 0 && isSuspend) return '';
    if (modeResumeOrderType === 0 && isResume) return '';
    if (modeCancelOrderType === 0 && isCancel) return '';
    return (
      <div>
        <div className="col-md-12 row p-0">
          <div className="col-sm-6">
            <Link
              to={state && state.backLink ? state.backLink : RouteNames.searchAndList.path}
              type="button"
              className="button x-small"
            >
              {t('label.back')}
            </Link>
          </div>
          <div className="col-sm-6 pr-0">
            <Breadcrumb
              items={[
                {
                  name: t('orderPage:sidebar.cancelOrder'),
                  url: RouteNames.cancelOrder.path,
                },
              ]}
            />
          </div>
        </div>
        {/* {!state && stage >= 1 && (
          <button type="button" className="button x-small mb-3" onClick={() => this.onPressBackButton(true)}>
            {t('label.back')}
          </button>
        )}
        {state && state.backLink && (
          <Link to={state.backLink} type="button" className="button x-small mb-3">
            {t('label.back')}
          </Link>
        )} */}
        <div className="col-md-12 row text-center pb-3">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar listSteps={listSteps} stepSelected={stage} onSelectStep={this.onSelectStep} />
          </div>
          <div className="col-md-3 p-m">
            <button
              type="button"
              className="button button-border x-small gray box-shadow-btn"
              onClick={this.onPressBackButton}
            >
              {t('label.cancel')}
            </button>
            {stage < 1 ? (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressNextButton}
              >
                {t('label.next')}
              </button>
            ) : (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressCreateButton}
                disabled={
                  (subscription && subscription[0] && subscription[0].status !== 'SUSPENDED' && type === 'resume') ||
                  (modeSuspendOrder === 1 && isSuspend) ||
                  (modeResumeOrderType === 1 && isResume) ||
                  (modeCancelOrderType === 1 && isCancel)
                }
              >
                {t('label.create')}
              </button>
            )}
          </div>
        </div>
        {/* <div className="form-group col-md-12 mt-4 justify-content-center d-flex row mb-3">
          
        </div> */}
        {this.renderContent()}

        <ModalSelectSubscription
          onToggle={() => {
            this.onPressBackButton();
            this.initServiceUnits = {};
          }}
          isOpen={isOpenModalSubscription}
          data={subscriptionData}
          onSelect={data => {
            this.setState({ isOpenModalSubscription: false });
            this.doInitSubscription([data]);
            getSubscriptionAssets({ id: data.id || null, status: data.status || null });
          }}
        />
      </div>
    );
  }
}

CancelSubscription.propTypes = {
  doGetSubscriptionByAccountId: PropTypes.func.isRequired,
  doCreateOrder: PropTypes.func.isRequired,
  subscription: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  location: PropTypes.objectOf(PropTypes.any),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
  isSuspend: PropTypes.bool,
  isResume: PropTypes.bool,
  isCancel: PropTypes.bool,
};

CancelSubscription.defaultProps = {
  location: {},
  subscription: [],
  permissionOrder: {},
  isSuspend: false,
  isResume: false,
  isCancel: false,
};

const mapStateToProps = createStructuredSelector({
  subscription: makeGetSubscription(),
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
});

const newCancelSubscription = connect(mapStateToProps, {
  doGetSubscriptionByAccountId: getSubscriptionByAccountId,
  doCreateOrder: createOrder,
  getCcpPropertiesConfig,
  getSubscriptionAssets,
  getCustomAttributesByObjectType,
})(CancelSubscription);

export default withTranslation('common')(newCancelSubscription);
