import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions, formatMoneyValueToExport, redFontExcelFile } from '../utils';
import { columns } from './commonFields';

const multiDataSetDefault = ({ t, title, columnsTable }) => [
  {
    xSteps: columnsTable.length > 4 ? columnsTable.length / 2 - 2 : 1,
    columns: [],
    data: [[{ value: title || 'LIST PAYMENTS', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: columnsTable.map(val => columns[val.name] || columns.common),
    data: [
      columnsTable.map(val => ({
        value: t(val.exportLabel) || t(val.label),
        style: styleHeader,
      })),
    ],
    // columns: [
    //   columns.paymentId, // Payment Id
    //   columns.common, // paymentReferenceId
    //   columns.common, // paymentSuspenseId
    //   columns.paymentDate, // Payment Date
    //   columns.paymentDate, // createdDate
    //   columns.common, // Status
    //   columns.common, // folioStatus
    //   columns.common, // Source Currency
    //   columns.common, // paymentAmount
    //   columns.common, // remainingAmount
    //   columns.common, // paymentFile
    //   columns.common, // paymentFileDate
    //   columns.common, // Payment Txt Type
    //   columns.company, // Company
    //   columns.accountId, // Account Id
    //   columns.firstName, // First Name
    //   columns.lastName, // Last Name
    //   columns.accountType, // Account Type
    //   columns.common, // Payment Method
    //   columns.common, // Payment Source
    //   columns.common, // Currency
    //   columns.common, // InvoiceUnit Id
    //   columns.common, // userId
    //   columns.common, // Payment Description
    //   columns.common, // Exchange Rate
    //   columns.common, // currentExchangeRate
    //   columns.common, // realizedLossGain
    //   columns.common, // paymentDateExchangeRate
    // ],
    // data: [
    //   [
    //     {
    //       value: t('label.paymentId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentReferenceId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentSuspenseId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.createdDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.status'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.folioStatus'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentCurrency'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentAmount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.remainingAmount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentFile'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentFileDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentTxtType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.organization'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.firstName'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.lastName'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentMethod'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentSource'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountCurrency'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.invoiceUnitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.userId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.description'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.appliedExchangeRate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.currentExchangeRate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.unrealizedLossGainInSystemCurrencyExport'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentDateExchangeRate'),
    //       style: styleHeader,
    //     },
    //   ],
    // ],
  },
];

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (item[val.name] && typeof item[val.name] === 'number') {
          newItem.push({
            value: formatMoneyValueToExport(item[val.name]),
            style: val.isSupportRedFront && item[val.name] < 0 ? redFontExcelFile : null,
          });
        } else if (val.isRenderT) {
          newItem.push({
            value: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
        } else {
          newItem.push({ value: item[val.name] ? item[val.name] : '' });
        }
      });

      // newItem.push({ value: item.paymentId ? item.paymentId : '' });
      // newItem.push({ value: item.referenceId ? item.referenceId : '' });
      // newItem.push({ value: item.paymentSuspenseId ? item.paymentSuspenseId : '' });
      // newItem.push({ value: item.paymentDate ? item.paymentDate : '' });
      // newItem.push({ value: item.createdDate ? item.createdDate : '' });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'statusPayment' }) });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'folioStatus', fieldOption: 'folioStatus' }) });
      // newItem.push({ value: item.sourceCurrency ? item.sourceCurrency : '' });
      // newItem.push({ value: item.amount ? item.amount : '' });
      // newItem.push({ value: item.remainingAmount ? item.remainingAmount : '' });
      // newItem.push({ value: item.fileName ? item.fileName : '' });
      // newItem.push({ value: item.fileDate ? item.fileDate : '' });
      // newItem.push({
      //   value: getLabelOptions({ item, t, fieldName: 'paymentTransactionType', fieldOption: 'paymentTransactionType' }),
      // });
      // newItem.push({ value: item.company ? item.company : '' });
      // newItem.push({ value: item.accountId ? item.accountId : '' });
      // newItem.push({ value: item.firstName ? item.firstName : '' });
      // newItem.push({ value: item.lastName ? item.lastName : '' });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'accountType' }) });
      // newItem.push({ value: getLabelOptions({ item, t, fieldName: 'paymentMethod' }) });
      // newItem.push({ value: item.paymentSource ? item.paymentSource : '' });
      // newItem.push({ value: item.currency ? item.currency : '' });
      // newItem.push({ value: item.invoiceUnitId ? item.invoiceUnitId : '' });
      // newItem.push({ value: item.userId ? item.userId : '' });
      // newItem.push({ value: item.paymentDescription ? item.paymentDescription : '' });
      // newItem.push({ value: item.exchangeRate ? item.exchangeRate : '' });
      // newItem.push({
      //   value: formatMoneyValueToExport(item.currentDateExchangeRate),
      // });
      // newItem.push({
      //   value: formatMoneyValueToExport(item.unRealizedGain),
      //   style: item.unRealizedGain < 0 ? redFontExcelFile : null,
      // });
      // newItem.push({
      //   value: formatMoneyValueToExport(item.paymentDateExchangeRate),
      // });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
