export default {
  type: {
    new: 'NEW',
    modify: 'MODIFY',
    suspend: 'SUSPEND',
    resume: 'RESUME',
    cancel: 'CANCEL',
    renew: 'RENEW',
    optIn: 'OPT_IN',
    optOut: 'OPT_OUT',
    upgrade: 'UPGRADE',
    downgrade: 'DOWNGRADE',
    trial: 'TRIAL',
    relocation: 'RELOCATION',
  },
  status: {
    create: 'CREATED',
    pending: 'PENDING',
    cancelled: 'CANCELLED',
    submitted: 'SUBMITTED',
    approved: 'APPROVED',
    processing: 'PROCESSING',
    completed: 'COMPLETED',
    failed: 'FAILED',
    provisioningError: 'PROVISIONING_ERROR',
    billingError: 'BILLING_ERROR',
    provisioningInitiated: 'PROVISIONING_INITIATED',
  },
  initialTermUnit: {
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
  },
  renewalTermUnit: {
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
  },
  trialTermUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
  },
  serviceType: {
    default: 'DEFAULT',
    voip: 'VOIP',
    cable: 'CABLE',
    utilities: 'UTILITIES',
    retail: 'RETAIL',
    wholesale: 'WHOLESALE',
    wireline: 'WIRELINE',
    telephony: 'TELEPHONY',
    iptv: 'IPTV',
    data: 'DATA',
    professional: 'PROFESSIONAL',
    internet: 'INTERNET',
    homesecurity: 'HOMESECURITY',
  },
  action: {
    add: 'ADD',
    modify: 'MODIFY',
    cancel: 'CANCEL',
  },
  orderLineStatus: {
    created: 'CREATED',
    edited: 'EDITED',
    cancelled: 'CANCELLED',
    submitted: 'SUBMITTED',
    processing: 'PROCESSING',
    billed: 'BILLED',
    provisioned: 'PROVISIONED',
    completed: 'COMPLETED',
    failed: 'FAILED',
  },
  relativeStartUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
    unbound: 'UNBOUND',
  },
  relativeEndUnit: {
    days: 'DAYS',
    weeks: 'WEEKS',
    months: 'MONTHS',
    quarters: 'QUARTERS',
    years: 'YEARS',
    unbound: 'UNBOUND',
  },
  subscriptionReason: {
    none: 'NONE',
    customerRelocation: 'CUSTOMER_RELOCATION',
    notHappy: 'NOT_HAPPY',
    qos: 'QOS',
    pricing: 'PRICING',
  },
  subscriptionStatus: {
    active: 'ACTIVE',
    suspend: 'SUSPENDED',
    close: 'CLOSED',
  },
};
