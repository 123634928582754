import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  taxCodeConfig: [],
  configCodes: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    // collection units

    case types.GET_TAX_CODE_CONFIG_SUCCESS:
      return state.set('taxCodeConfig', action.payload);

    case types.GET_3RD_PARTY_TAX_CONFIG_SUCCESS:
      return state.set('configCodes', action.payload);

    default:
      return state;
  }
}
