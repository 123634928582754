export default {
  tenantManagementPage: {
    tenantData: 'Datos de Instancia',
    tenantProfile: 'Perfil de Instancia',
    platformDefaults: 'Valores Predeterminados de la Plataforma',
    paymentGateway: 'Gateway de Pagos',
    taxGateway: 'Gateway de Impuestos',
    financeGateway: 'Gateway Financiero',
    merchantData: 'Datos del Comerciante',
    customRecords: 'Registro Personalizado',
    urlMapping: 'Mapeo de URL',
    requestResponseMapping: 'Mapeo de Solicitud/Respuesta',
    selfCareMarketing: 'Marketing en Autoatención',
    provisioningGateway: 'Gateway de Aprovisionamiento',
    idSequenceConfig: 'Configuración de Secuencia de Id',
    projectGateway: 'Gateway de Proyectos',
    customerSupportGateway: 'Gateway de Soporte a Clientes',
    documentGateway: 'Gateway de Documentos',
    operationGateway: 'Gateway de Operaciones',
    crmGateway: 'Gateway de CRM',
    config: 'Configuración',
    schedulePattern: 'Patrón de Programación',
    tenantConfig: 'Configuración de Instancia',
    policyEnablers: 'Habilitadores de Políticas',
    templates: 'Plantillas',
    productSupportTask: 'Tarea de Soporte de Producto',
    folioResponseFile: 'Folio Response File',
  },
};
