import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from '../../../utils/utils';
import { pnnListSort } from '../constants';
import PageTitle from '../../../components/PageTitle';
import { DataTable, TablePagination, TitleFrom } from '../../../components/common';
import { PNNListSearchForm } from '../../../components/BaseConfigurationsHub';
import RouteNames from '../../App/RouteNames';
import { makeGetPnnpList } from '../selectors';
import { getConfigPnnPlanNumbering } from '../actions';

class PNNList extends PureComponent {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    isExpandChild: true,
    pnnpList: [],
  };

  static getDerivedStateFromProps(props, state) {
    return {
      pnnpList: props.pnnpList,
      totalCount: getPageTotalCount({ ...state, items: props.pnnpList }),
    };
  }

  componentDidMount() {
    this.doGetConfigPnnPlanNumbering();
  }

  onPageChange = page => {
    this.setState({ page }, () => this.doGetConfigPnnPlanNumbering());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetConfigPnnPlanNumbering());
  };

  onHandleChange = ({ name, value }) => {
    const { filter = {} } = this.state;
    filter[name] = value;
    this.setState({ ...filter });
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doGetConfigPnnPlanNumbering());
  };

  doGetConfigPnnPlanNumbering = () => {
    const { filter, page, size, sorted } = this.state;
    const { getConfigPnnPlanNumbering } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? pnnListSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true, pnnpList: [] });
    getConfigPnnPlanNumbering(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doGetConfigPnnPlanNumbering());
  };

  render() {
    const { t } = this.props;
    const { sorted, isSearching, page, size, totalCount, pnnpList } = this.state;
    const tableColumns = [
      {
        name: 'claveCensal',
        label: 'label.claveCensal',
      },
      {
        name: 'poblacion',
        label: 'label.poblacion',
      },
      {
        name: 'municipio',
        label: 'label.municipio',
      },
      {
        name: 'estado',
        label: 'label.estado',
      },
      {
        name: 'central',
        label: 'label.central',
      },
      {
        name: 'presuscripcion',
        label: 'label.presuscripcion',
      },
      {
        name: 'asl',
        label: 'label.asl',
      },
      {
        name: 'nir',
        label: 'label.nir',
      },
      {
        name: 'serie',
        label: 'label.serie',
      },
      {
        name: 'numeracionInicial',
        label: 'label.numeracionInicial',
      },
      {
        name: 'numeracionFinal',
        label: 'label.numeracionFinal',
      },
      {
        name: 'tipoRed',
        label: 'label.tipoRed',
      },
      {
        name: 'modalidad',
        label: 'label.modalidad',
      },
      {
        name: 'estatus',
        label: 'label.estatus',
        sortable: true,
      },
      {
        name: 'razonSocial',
        label: 'label.razonSocial',
      },
      {
        name: 'nombreCorto',
        label: 'label.nombreCorto',
      },
      {
        name: 'oficioReserva',
        label: 'label.oficioReserva',
      },
      {
        name: 'fechaReserva',
        label: 'label.fechaReserva',
      },
      {
        name: 'oficioAsignacion',
        label: 'label.oficioAsignacion',
      },
      {
        name: 'fechaAsignacion',
        label: 'label.fechaAsignacion',
      },
      {
        name: 'fechaConsolidacion',
        label: 'label.fechaConsolidacion',
      },
      {
        name: 'fechaMigracion',
        label: 'label.fechaMigracion',
      },
      {
        name: 'puntoEntrega',
        label: 'label.puntoEntrega',
      },
      {
        name: 'region',
        label: 'label.region',
      },
      {
        name: 'ocupacion',
        label: 'label.ocupacion',
      },
      {
        name: 'idOperador',
        label: 'label.idOperador',
        sortable: true,
      },
      {
        name: 'nirAnterior',
        label: 'label.nirAnterior',
      },
      {
        name: 'ido',
        label: 'label.ido',
        sortable: true,
      },
      {
        name: 'ida',
        label: 'label.ida',
        sortable: true,
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.PNNList') },
          ]}
          isNoDefaultButton
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <TitleFrom title={t('baseConfigurationsPage:sidebar.PNNList')} />
            <div className="card-body">
              <div className="repeater-file">
                <div className="pl-2">
                  <PNNListSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable
                columns={tableColumns}
                sorted={sorted}
                data={pnnpList && pnnpList.length ? pnnpList : []}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  pnnpList: makeGetPnnpList() || [],
});

PNNList.propTypes = {
  pnnpList: PropTypes.arrayOf(PropTypes.any),
  getConfigPnnPlanNumbering: PropTypes.func.isRequired,
};

PNNList.defaultProps = {
  pnnpList: [],
};

const newCustomerIndex = connect(mapStateToProps, {
  getConfigPnnPlanNumbering,
})(PNNList);

export default withTranslation(['common'])(newCustomerIndex);
