import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['countLimit', 'amountLimit'])}` : '';
  const getRawInterconnectSmsReportData = `getRawInterconnectSmsReportData(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getRawInterconnectSmsReportData} {
        lineDayOfMonth
        operatorId
        operatorName
        originalNetworkId
        noOfSms
      }
    }
  `;
};
