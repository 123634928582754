import * as types from './types';

export function getARReasonCodeConfigRevenue(payload, cb) {
  return {
    type: types.GET_AR_REASON_CODE_REVENUE,
    payload,
    cb,
  };
}

export function modifyARReasonCodeConfigRevenue(payload, cb) {
  return {
    type: types.MODIFY_AR_REASON_CODE_REVENUE,
    payload,
    cb,
  };
}

export function createARReasonCodeConfigRevenue(payload, cb) {
  return {
    type: types.CREATE_AR_REASON_CODE_REVENUE,
    payload,
    cb,
  };
}

export function searchRevenueMilestone(payload, cb) {
  return {
    type: types.SEARCH_REVENUE_MILESTONE,
    payload,
    cb,
  };
}

export function searchRevenueMilestoneSuccess(payload, cb) {
  return {
    type: types.SEARCH_REVENUE_MILESTONE_SUCCESS,
    payload,
    cb,
  };
}

export function getRevenueMilestoneById(payload, cb) {
  return {
    type: types.GET_REVENUE_MILESTONE_BY_ID,
    payload,
    cb,
  };
}

export function getRevenueMilestoneByIdSuccess(payload, cb) {
  return {
    type: types.GET_REVENUE_MILESTONE_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function modifyRevenueMilestone(payload, cb) {
  return {
    type: types.MODIFY_REVENUE_MILESTONE,
    payload,
    cb,
  };
}

export function createRevenueMilestone(payload, cb) {
  return {
    type: types.CREATE_REVENUE_MILESTONE,
    payload,
    cb,
  };
}

// Exchange Rate
export function searchExchangeRate(payload, cb) {
  return {
    type: types.SEARCH_EXCHANGE_RATE,
    payload,
    cb,
  };
}

export function searchExchangeRateSuccess(payload, cb) {
  return {
    type: types.SEARCH_EXCHANGE_RATE_SUCCESS,
    payload,
    cb,
  };
}

export function getExchangeRateById(payload, cb) {
  return {
    type: types.GET_EXCHANGE_RATE_BY_ID,
    payload,
    cb,
  };
}

export function getExchangeRateByIdSuccess(payload, cb) {
  return {
    type: types.GET_EXCHANGE_RATE_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function modifyExchangeRate(payload, cb) {
  return {
    type: types.MODIFY_EXCHANGE_RATE,
    payload,
    cb,
  };
}

export function createExchangeRate(payload, cb) {
  return {
    type: types.CREATE_EXCHANGE_RATE,
    payload,
    cb,
  };
}

export function exportExchangeRate(payload, cb) {
  return {
    type: types.EXPORT_EXCHANGE_RATE_BY_ID,
    payload,
    cb,
  };
}

export function exportExchangeRateSuccess(payload, cb) {
  return {
    type: types.EXPORT_EXCHANGE_RATE_BY_ID_SUCCESS,
    payload,
    cb,
  };
}


export function searchAccountingEntry(payload, cb) {
  return {
    type: types.SEARCH_ACCOUNTING_ENTRY,
    payload,
    cb,
  };
}

export function searchAccountingEntrySuccess(payload, cb) {
  return {
    type: types.SEARCH_ACCOUNTING_ENTRY_SUCCESS,
    payload,
    cb,
  };
}

export function searchEbsExtract(payload, cb) {
  return {
    type: types.SEARCH_EBS_EXTRACT,
    payload,
    cb,
  };
}

export function searchEbsExtractSuccess(payload, cb) {
  return {
    type: types.SEARCH_EBS_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

export function getEbsExtractDetailsById(payload, cb) {
  return {
    type: types.GET_EBS_EXTRACT_BY_BATCH_ID,
    payload,
    cb,
  };
}

// Revenue Journal
export function searchRevenueJournal(payload, cb) {
  return {
    type: types.SEARCH_REVENUE_JOURNAL,
    payload,
    cb,
  };
}

export function searchRevenueJournalSuccess(payload, cb) {
  return {
    type: types.SEARCH_REVENUE_JOURNAL_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllRevenueJournal(payload, cb) {
  return {
    type: types.SEARCH_ALL_REVENUE_JOURNAL,
    payload,
    cb,
  };
}

export function searchAllRevenueJournalSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_REVENUE_JOURNAL_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllAccountingEntry(payload, cb) {
  return {
    type: types.SEARCH_ALL_ACCOUNT_ENTRY,
    payload,
    cb,
  };
}

export function searchAllAccountingEntrySuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_ACCOUNT_ENTRY_SUCCESS,
    payload,
    cb,
  };
}

export function modifyRevenueJournal(payload, cb) {
  return {
    type: types.MODIFY_REVENUE_JOURNAL,
    payload,
    cb,
  };
}


export function searchAllEbsExtract(payload, cb) {
  return {
    type: types.SEARCH_ALL_EBS_EXTRACT,
    payload,
    cb,
  };
}

export function searchAllEbsExtractSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_EBS_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

export function searchRevRecJournal(payload, cb) {
  return {
    type: types.SEARCH_REV_REC_JOURNAL,
    payload,
    cb,
  };
}

export function createRevenueJournal(payload, cb) {
  return {
    type: types.CREATE_REVENUE_JOURNAL,
    payload,
    cb,
  };
}

export function recognizeManualRevenue(payload, cb) {
  return {
    type: types.RECOGNIZE_MANUAL_REVENUE,
    payload,
    cb,
  };
}

export function resendEbsExtract(payload, cb) {
  return {
    type: types.RESEND_EBS_EXTRACT,
    payload,
    cb,
  };
}

export function getRevenueRecognitionSummary(payload, cb) {
  return {
    type: types.GET_REVENUE_RECOGNITIION_SUMMARY,
    payload,
    cb,
  };
}

export function getActivityEntry(payload, cb) {
  return {
    type: types.GET_ACTIVITY_ENTRY,
    payload,
    cb,
  };
}