import { cloneDeep } from 'lodash';
import { getLabelOptions } from '../utils';

const headersCSV = ({ t }) => {
  if (!t) return '';
  return [
    { displayName: t('label.id'), id: 'id' },
    { displayName: t('label.employee'), id: 'employeeName' },
    { displayName: t('label.jobTitle'), id: 'jobTitle' },
    { displayName: t('label.clientId'), id: 'clientId' },
    { displayName: t('label.clientName'), id: 'clientName' },
    { displayName: t('label.clientJobTitle'), id: 'clientJobTitle' },
    { displayName: t('label.firstPayrollDate'), id: 'firstPayrollDate' },
    { displayName: t('label.firstPayrollAmount'), id: 'firstSalaryAmount' },
    { displayName: t('label.secondPayrollDate'), id: 'secondPayrollDate' },
    { displayName: t('label.secondSalaryAmount'), id: 'secondSalaryAmount' },
    { displayName: t('label.noOfDaysWorked'), id: 'numDaysWorked' },
    { displayName: t('label.startDate'), id: 'startDate' },
    { displayName: t('label.endDate'), id: 'endDate' },
    { displayName: t('label.createdDate'), id: 'createdDate' },
    { displayName: t('label.status'), id: 'status', fieldOption: 'timeAndBillingObjectStatus' },
  ];
};

const dataCSV = ({ data, t }) => {
  if (!data || !data.length) return [];
  let newData = cloneDeep(data);
  if (headersCSV && newData && newData.length) {
    headersCSV({ t }).forEach(val => {
      if (val.isBool) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = newDt[val.id] ? 'true' : 'false';
          return newDt;
        });
      }
      if (val.isNumber) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = Number.parseFloat(newDt[val.id] || 0);
          return newDt;
        });
      }
      if (val.fieldOption) {
        newData = newData.map(dt => {
          const newDt = cloneDeep(dt);
          newDt[val.id] = getLabelOptions({ item: newDt, t, fieldName: 'status', fieldOption: val.fieldOption });
          return newDt;
        });
      }
    });
  }
  return newData;
};

export { headersCSV, dataCSV };
