import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../../components/common';
import listCurrency from '../../../../constantsApp/currency.json';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class TierForm extends PureComponent {
  state = { data: { prices: [], grants: [], accumulators: [] } };

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (data) this.setState({ data });
  }

  onChange = ({ name, value, idx, objName }) => {
    const { onUpdateTierItem, isTier, onUpdateDataPricing, onChangeRecurringPriceStructure } = this.props;
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[objName][idx][name] = value;
    if (isTier && onUpdateTierItem) onUpdateTierItem(newData);
    if (!isTier && onUpdateDataPricing) onUpdateDataPricing(newData);
    if (onChangeRecurringPriceStructure) onChangeRecurringPriceStructure(newData);
    this.setState({ data: newData });
  };

  render() {
    const {
      onChangePricingProps,
      onAddCurrency,
      onAddGrants,
      onAddAccumulators,
      handleRemovePrice,
      handleRemoveGrants,
      handleRemoveAccumulators,
      optionsAccumulatorId,
      optionsGrantId,
      grantInputs,
      accumulatorInputs,
      isComplexUsage,
      index,
      t,
      transactionType,
      pricingModel,
    } = this.props;

    // const accumulatorInputs = [
    //   {
    //     label: 'label.accumulatorId',
    //     wrapperClass: 'col-md-4',
    //     name: 'accumulatorId',
    //     type: 'select',
    //     required: true,
    //     options: optionsAccumulatorId,
    //     isClearable: true,
    //   },
    //   {
    //     label: 'label.amount',
    //     wrapperClass: 'col-md-4',
    //     name: 'amount',
    //     type: 'number',
    //     required: true,
    //   },
    //   {
    //     label: 'label.validityUnit',
    //     wrapperClass: 'col-md-4',
    //     name: 'unit',
    //     type: 'select',
    //     required: true,
    //     tOptions: 'selections:trialTermUnit',
    //   },
    //   {
    //     label: 'label.validityDuration',
    //     wrapperClass: 'col-md-4',
    //     name: 'duration',
    //     type: 'number',
    //     required: true,
    //   },
    // ];

    // const grantInputs = [
    //   {
    //     label: 'label.grantId',
    //     wrapperClass: 'col-md-4',
    //     name: 'resourceId',
    //     type: 'select',
    //     required: true,
    //     options: optionsGrantId,
    //   },
    //   {
    //     label: 'label.amount',
    //     wrapperClass: 'col-md-4',
    //     name: 'amount',
    //     type: 'number',
    //     required: true,
    //   },
    //   {
    //     label: 'label.validityUnit',
    //     wrapperClass: 'col-md-4',
    //     name: 'grantUnit',
    //     type: 'select',
    //     required: true,
    //     tOptions: 'selections:grantUnit',
    //   },
    //   {
    //     label: 'label.validityDuration',
    //     wrapperClass: 'col-md-4',
    //     name: 'grantDuration',
    //     type: 'number',
    //     required: true,
    //   },
    // ];

    const priceInputs = [
      {
        label: 'label.currency',
        wrapperClass: 'col-md-4',
        name: 'currencyId',
        type: 'select',
        required: true,
        options: currencyOptions,
        disabled: isComplexUsage && index > 0,
        menuPortalTarget: true,
      },
      {
        label: 'label.amount',
        wrapperClass: 'col-md-4',
        name: 'amount',
        type: 'number',
        required: true,
        isNegativeValue: true,
      },
    ];

    if (
      (pricingModel === 'TIERED' || pricingModel === 'COMPLEX') &&
      (transactionType === 'USAGE' || transactionType === 'VOLUME_DISCOUNT')
    ) {
      priceInputs.push({
        label: 'label.flatRate',
        wrapperClass: 'col-md-4 mt-4 pt-2',
        name: 'flatRate',
        type: 'checkbox',
      });
    }

    const { data } = this.state;
    return (
      <div>
        <div>
          {data &&
            data.prices &&
            data.prices.length > 0 &&
            data.prices.map((price, idx) => {
              if (price) {
                return (
                  <div key={`price-${idx}`}>
                    <div className="card-header">
                      {`${t('label.price')}#${idx + 1}`}
                      {data.prices.filter(i => size(i) > 1).length > 1 && (
                        <button type="button" className="btn-rm-card" onClick={() => handleRemovePrice(idx)}>
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                    <div className="row col-md-12 p-3">
                      {priceInputs.map(item => (
                        <GenericInput
                          key={`prices-${item.name}`}
                          disabled={item.disabled}
                          {...item}
                          value={price[item.name]}
                          onChange={({ name, value }) => this.onChange({ name, value, idx, objName: 'prices' })}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {data &&
            data.grants &&
            data.grants.length > 0 &&
            data.grants.map((grant, idx) => {
              if (grant && size(grant) > 1) {
                return (
                  <div key={`grant-${idx}`}>
                    <div className="card-header">
                      {`Grant#${idx + 1}`}
                      {data.grants.filter(i => size(i) > 1).length > 0 && (
                        <button type="button" className="btn-rm-card" onClick={() => handleRemoveGrants(idx)}>
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                    <div className="row col-md-12 p-3">
                      {grantInputs.map(item => (
                        <GenericInput
                          key={`grants-${item.name}`}
                          disabled={item.disabled}
                          {...item}
                          value={grant[item.name]}
                          onChange={({ name, value }) => this.onChange({ name, value, idx, objName: 'grants' })}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {data &&
            data.accumulators &&
            data.accumulators.length > 0 &&
            data.accumulators.map((accumulator, idx) => {
              if (accumulator && size(accumulator) > 2) {
                return (
                  <div key={`accumulators-${idx}`}>
                    <div className="card-header">
                      {`Accumulator#${idx + 1}`}
                      {data.accumulators.filter(i => size(i) > 2).length > 0 && (
                        <button type="button" className="btn-rm-card" onClick={() => handleRemoveAccumulators(idx)}>
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                    <div className="row col-md-12 p-3" key={`grant-${idx}`}>
                      {accumulatorInputs.map(item => (
                        <GenericInput
                          key={`accumulators-${item.name}`}
                          disabled={item.disabled}
                          {...item}
                          value={accumulator[item.name]}
                          onChange={({ name, value }) => this.onChange({ name, value, idx, objName: 'accumulators' })}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          <div className="row col-md-12 pl-3 pb-2">
            <button
              type="button"
              className="button button-border mt-3 black x-small"
              onClick={onAddCurrency}
              disabled={data.prices && data.prices.length > 0}
            >
              {`+ ${t('label.addNewCurrency')}`}
            </button>
            {/* {data.grants && ( */}
            <button
              type="button"
              className="button button-border mt-3 black x-small"
              onClick={onAddGrants}
              disabled={data?.grants && data.grants.filter(val => size(val) > 1).length >= optionsGrantId.length}
            >
              {`+ ${t('label.addNewGrant')}`}
            </button>
            {/* // )} */}
            {/* {data.accumulators && ( */}
            <button
              type="button"
              className="button button-border mt-3 black x-small"
              onClick={onAddAccumulators}
              disabled={
                data?.accumulators &&
                data.accumulators.filter(val => size(val) > 1).length >= optionsAccumulatorId.length
              }
            >
              {`+ ${t('label.addNewAccumulators')}`}
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(TierForm);
