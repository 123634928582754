import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['objectType', 'journalStatus'])}` : '';
  const searchAccountingEntry = `searchAccountingEntry(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
    ${searchAccountingEntry} {
      id
      journalId
      drAccountType
      drGlAccount
      drCurrency
      drAmount
      crAccountType
      crGlAccount
      crCurrency
      crAmount
      objectId
      createdDate
      objectType
      batchId
      journalStatus
      accountingCode
      offsetAccountingCode
    }
  }
  `;
};
