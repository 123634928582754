import React from 'react';
import { cloneDeep, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { invoiceSort } from '../../../../constantsApp';
import { FormWithTableItem, DataTable } from '../../../../components/common';
import { makeGetInvoices, errorMsgInvoices } from '../../selectors';
import { searchInvoices, allocatePayment } from '../../actions';
import { tableColumnsManualAllocation } from '../../constant';
import RouteNames from '../../../App/RouteNames';
import PageTitle from '../../../../components/PageTitle';

class ManualAllocation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 20,
      dataSubmit: [],
    };
    this.filter = {
      accountId: props.accountId || '',
      due: 0,
      status: 'ACTIVE',
    };
    this.sort = 'invoiceDate_ASC';
  }

  componentDidMount() {
    const { page, size } = this.state;
    this.props.searchInvoices({
      page,
      size,
      filter: this.filter,
      sort: this.sort,
    });
  }

  handlePage = pageOffset => {
    const { page, size } = this.state;

    this.setState({ page: page + pageOffset });
    this.props.searchInvoices(
      {
        page: page + pageOffset,
        size,
        filter: this.filter,
        sort: this.sort,
      },
      () => {
        // this.setState({ selectedInvoice: null });
      }
    );
  };

  handleSize = size => {
    this.setState({ size, page: 1 });
    this.props.searchInvoices(
      {
        page: 1,
        size,
        filter: this.filter,
        sort: this.sort,
      },
      () => {
        // this.setState({ selectedInvoice: null });
      }
    );
  };

  handleSortInvoice = (key, value) => {
    this.props.searchInvoices(
      {
        page: 1,
        size: 20,
        filter: this.filter,
        sort: invoiceSort[key][value],
      },
      () => {
        // this.setState({ page: 1, size: 20, selectedInvoice: null });
        this.setState({ page: 1, size: 20 });
      }
    );
    this.sort = invoiceSort[key][value];
  };

  onClickAllocate = () => {
    const { paymentId, allocatePayment, history } = this.props;
    const { dataSubmit } = this.state;
    const data = {
      id: paymentId,
      userId: 'UserX001',
      allocationData: dataSubmit.length ? dataSubmit : null,
    };

    allocatePayment(data, ({ success }) => {
      if (success) {
        history.push('/payment/payment-agent/view-payment-activity');
      }
    });
  };

  onHandleChangeAmount = (evt, id) => {
    const { dataSubmit } = this.state;
    const newData = cloneDeep(dataSubmit);
    const indexAmount = findIndex(dataSubmit, item => item.invoiceUnitId === id);
    if (!evt.target.value) {
      newData.splice(indexAmount, 1);
    } else if (indexAmount > -1) {
      newData[indexAmount].amount = evt.target.value;
    } else {
      newData.push({ invoiceUnitId: id, amount: evt.target.value });
    }

    this.setState({ dataSubmit: newData });
  };

  render() {
    const { invoices, t } = this.props;
    const { isSearching, sorted, dataSubmit } = this.state;

    const tableConfig = {
      columns: [
        ...tableColumnsManualAllocation,
        {
          name: 'Apply Amount',
          label: 'Apply Amount',
          render: (colName, item) => (
            <input
              type="number"
              value={dataSubmit.amount}
              onChange={evt => this.onHandleChangeAmount(evt, item.id)}
              className="form-control"
            />
          ),
        },
      ],
      sorted,
      onSort: this.onSortColumn,
      data: invoices,
      isLoading: isSearching,
    };

    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('common:label.back')}
          items={[
            { name: t('paymentPage:sidebar.paymentOperations'), url: RouteNames.paymentActivity.path },
            { name: t('paymentPage:sidebar.paymentAllocation') },
          ]}
        />
        <br />
        <FormWithTableItem title={t('label.manualAllocation')} accountNum={this.props.accountId}>
          <DataTable {...tableConfig} />
          <div className="col-md-12 pt-3 action-buttons">
            <button
              type="button"
              className="button button-border x-small float-right"
              onClick={this.onClickAllocate}
              disabled={!invoices || !invoices.length}
            >
              {t('label.allocate')}
            </button>
          </div>
          <br />
        </FormWithTableItem>
      </div>
    );
  }
}

ManualAllocation.propTypes = {
  location: PropTypes.object,
  invoices: PropTypes.array,
  searchInvoices: PropTypes.func,
  errorMessage: PropTypes.string,
  allocatePayment: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  invoices: makeGetInvoices() || [],
  errorMessage: errorMsgInvoices() || '',
});

export default withTranslation('common')(
  connect(mapStateToProps, { searchInvoices, allocatePayment })(ManualAllocation)
);
