import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';

class ModalJobErrorDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { openModal, toggleModal, errorJobs, t } = this.props;
    return (
      <Modal
        isOpen={openModal}
        toggle={() => toggleModal()}
        size="lg"
        className="modal-custom modal-custom--error-detail modal-70 bd-example-modal-lg"
      >
        <ModalHeader toggle={() => toggleModal()}>{t('label.jobErrorDetails')}</ModalHeader>

        <ModalBody>
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <div className="card-body card-table-body">
                <Table className="table">
                  <thead>
                    <tr>
                      <th>{t('label.accountId')}</th>
                      <th className="text-center">{t('label.entityType')}</th>
                      <th>{t('label.entityId')}</th>
                      <th>{t('label.payload')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorJobs.map((item, index) => (
                      <tr key={index.toString()}>
                        <td>{item.accountId}</td>
                        <td className="text-center">{item.reason}</td>
                        <td>{item.batchId}</td>
                        <td> </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ModalJobErrorDetails.propTypes = {
  openModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  errorJobs: PropTypes.array,
};

export default withTranslation('common')(ModalJobErrorDetails);
