import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from './SearchComponent';

class ModalOfferSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'common:label.status',
      name: 'status',
      tOptions: 'selections:statusPricing',
      wrapperClass: 'drd-modal col-md-3',
      type: 'select',
      isClearable: true,
    },
  ];
}

ModalOfferSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ModalOfferSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ModalOfferSearchForm);
