import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class ObjectFilesSummarySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startYearMonth',
      name: 'startYearMonth',
      type: 'monthYear',
      dateFormat: 'YYYY-MM',
      defaultValue: moment(new Date())
        .subtract(1, 'months')
        .format('YYYY-MM'),
      required: true,
    },
    {
      label: 'label.endYearMonth',
      name: 'endYearMonth',
      type: 'monthYear',
      dateFormat: 'YYYY-MM',
      defaultValue: moment(new Date()).format('YYYY-MM'),
      required: true,
    },
    {
      label: 'label.documentType',
      name: 'documentType',
      type: 'select',
      tOptions: 'selections:documentType',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-3';
}

ObjectFilesSummarySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ObjectFilesSummarySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ObjectFilesSummarySearchForm);
