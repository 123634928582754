import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class FonatelReportSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
  ];

  btnSpan = 'col-md-3';
}

FonatelReportSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

FonatelReportSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(FonatelReportSearchForm);
