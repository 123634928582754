import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
// import { roundFloat } from '../../../utils/utils';
import { settleADisputeFields } from '../constant';
import PageTitle from '../../../components/PageTitle';
import { RevertForm } from '../../../components/ArOperationsHub';
import { processSettlement } from '../actions';

class SettleADispute extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataApply: {},
      disputeSelected: {},
      dataField: settleADisputeFields,
    };
  }

  componentDidMount() {
    this.convertDataFields();
    const {
      location: { state },
      history,
    } = this.props;
    if (state) {
      const { disputeSelected } = state; // state push from parent component
      const { dataApply } = this.state;
      dataApply.disputeAmount = disputeSelected.amount || null;
      dataApply.disputePercent = disputeSelected.percent || null;
      dataApply.userId = disputeSelected.userId || null;
      this.setState({ ...dataApply, disputeSelected });
      return;
    }
    history.push('/ar-operations/disputes');
  }

  onHandleChange = ({ name, value }) => {
    const { dataApply } = this.state;
    dataApply[name] = value;
    this.setState({ ...dataApply });
  };

  onBlurAmount = () => {
    // const { dataApply } = this.state;
    // dataApply.amount = roundFloat(dataApply.amount, 2);
    // this.setState({ dataApply: { ...dataApply } });
  };

  onBlurPercent = () => {
    // const { dataApply } = this.state;
    // dataApply.percent = roundFloat(dataApply.percent, 2);
    // this.setState({ dataApply: { ...dataApply } });
  };

  onHandleSubmit = evt => {
    evt.preventDefault();
    const { dataApply, disputeSelected } = this.state;
    const { processSettlement } = this.props;
    if (!dataApply.amount && !dataApply.percent) {
      return toast('field amount or percent cannot be null.', { type: toast.TYPE.ERROR });
    }
    const dataProcess = {
      id: disputeSelected.parentId,
      amount: dataApply.amount || null,
      userId: dataApply.userId || null,
      percent: dataApply.percent || null,
      notes: dataApply.notes || null,
    };

    return processSettlement(dataProcess);
  };

  convertDataFields = () => {
    const { disputeSelected, dataField } = this.state;
    const enableAmount = disputeSelected.amount || (!disputeSelected.amount && !disputeSelected.percent);
    dataField.map((item, index) => {
      if (item.name === 'percent' && enableAmount) settleADisputeFields[index].disabled = true;
      if (item.name === 'amount' && !enableAmount) settleADisputeFields[index].disabled = true;
      return dataField;
    });
    this.setState({ ...dataField });
  };

  onCancel = () => {
    this.setState({ dataApply: {} });
  };

  render() {
    const { dataApply } = this.state;
    const {
      t,
      location: { state },
    } = this.props;
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={
            state && state.backLink
              ? { pathname: state.backLink, state }
              : { pathname: RouteNames.arOperationsDisputes.path, state }
          }
          titleBtn={t('label.back')}
        />
        <div className="col-md-12 mb-30">
          <RevertForm
            title={t('label.settleADispute')}
            data={dataApply}
            onChange={this.onHandleChange}
            onBlurAmount={this.onBlurAmount}
            onBlurPercent={this.onBlurPercent}
            onHandleSubmit={this.onHandleSubmit}
            onCancel={this.onCancel}
          />
        </div>
      </div>
    );
  }
}

SettleADispute.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  processSettlement: PropTypes.func.isRequired,
};

SettleADispute.defaultProps = {
  history: {},
  location: {},
};

export default withTranslation('common')(
  connect(null, {
    processSettlement,
  })(withRouter(SettleADispute))
);
