import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'type',
    'index',
    'reasonCode',
    'invoiceCancellation',
    'fullCredit',
    'objectType',
    'status',
    'stampingRequired',
  ]);
  const modifyARReasonCodeConfig = `modifyARReasonCodeConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyARReasonCodeConfig} {
      id
    }
  }
`;
};
