import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { formatMoneyValueToExport, getLabelOptions } from '../utils';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.label));

// const HeaderTable = ({ t }) => [
//   t('label.commercialName'),
//   t('label.accountId'),
//   t('label.name'),
//   t('label.billableServiceName'),
//   t('label.deliveryDate'),
//   t('label.cancellationDate'),
//   t('label.orderDate'),
//   t('label.cutOffDate'),
//   t('label.cutOffDOM'),
//   t('label.billingFrequency'),
//   t('label.billingFrequencyUnit'),
//   t('label.status'),
//   t('label.quantity'),
//   t('label.invoiceExhibits'),
//   t('label.exhibitsInvoiced'),
//   t('label.unitPrice'),
//   t('label.amount'),
//   t('label.capacity'),
//   t('label.unitOfMeasure'),
//   t('label.currency'),
//   t('label.operativeStatus'),
//   t('label.adminStatus'),
//   t('label.circuitId'),
//   t('label.clientCircuitId'),
//   t('label.loadType'),
//   t('label.billableServiceId'),
//   t('label.instanceId'),
//   t('label.partMonthWaiveOffFlag'),
//   t('label.moveOrder'),
//   t('label.trafficFlag'),
//   t('label.inAdvanceFlag'),
//   t('label.contractTerm'),
//   t('label.termEndDate'),
//   t('label.isParent'),
//   t('label.dispersed'),
//   t('label.merged'),
//   t('label.chargeType'),
//   t('label.location1'),
//   t('label.reason'),
//   t('label.orderType'),
//   t('label.orderStatus'),
//   t('label.crmId'),
//   t('label.provisioningId'),
//   t('label.opportunity'),
//   t('label.location2'),
//   t('label.billed'),
// ];

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'cutOffDOM') {
          if (item.cutOffDOM === -2) {
            const cutOffDOMOptions = t('selections:cutOffDOM')();
            const lastItem = cutOffDOMOptions[0];
            newItem.push({ content: lastItem.label || '' });
          } else newItem.push({ content: `${item.cutOffDOM || ''}` });
        }
        if (val.name !== 'cutOffDOM') {
          if (item[val.name] && typeof item[val.name] === 'number') {
            newItem.push({ content: formatMoneyValueToExport(item[val.name]) });
          } else if (val.isRenderT) {
            newItem.push({
              content: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
          } else {
            newItem.push({ content: item[val.name] ? item[val.name] : '' });
          }
        }
      });
      // newItem.push(item.commercialName ? item.commercialName : '');
      // newItem.push(item.accountId ? item.accountId : '');
      // newItem.push(item.name ? item.name : '');
      // newItem.push(item.billableServiceName ? item.billableServiceName : '');
      // newItem.push(item.deliveryDate ? item.deliveryDate : '');
      // newItem.push(item.cancellationDate ? item.cancellationDate : '');
      // newItem.push(item.orderDate ? item.orderDate : '');
      // newItem.push(item.nextCutOffDate ? item.nextCutOffDate : '');
      // if (item.cutOffDOM === -2) {
      //   const cutOffDOMOptions = t('selections:cutOffDOM')();
      //   const lastItem = cutOffDOMOptions[0];
      //   newItem.push(lastItem.label || '');
      // } else newItem.push(`${item.cutOffDOM || ''}`);
      // newItem.push(
      //   typeof item.recurringFrequency === 'number' ? formatMoneyValueToExport(item.recurringFrequency) : ''
      // );
      // newItem.push(getLabelOptions({ item, t, fieldName: 'recurringUnit', fieldOption: 'recurringUnit' }));
      // newItem.push(item.status ? item.status : '');
      // newItem.push(typeof item.quantity === 'number' ? formatMoneyValueToExport(item.quantity) : '');
      // newItem.push(item.invoiceExhibits ? item.invoiceExhibits : '');
      // newItem.push(item.exhibitsInvoiced ? item.exhibitsInvoiced : '');
      // newItem.push(typeof item.unitPrice === 'number' ? formatMoneyValueToExport(item.unitPrice) : '');
      // newItem.push(typeof item.amount === 'number' ? formatMoneyValueToExport(item.amount) : '');
      // newItem.push(typeof item.capacity === 'number' ? formatMoneyValueToExport(item.capacity) : '');
      // newItem.push(item.uom ? item.uom : '');
      // newItem.push(item.currency ? item.currency : '');
      // newItem.push(item.operativeStatus ? item.operativeStatus : '');
      // newItem.push(item.adminStatus ? item.adminStatus : '');
      // newItem.push(item.circuitId ? item.circuitId : '');
      // newItem.push(item.clientCircuitId ? item.clientCircuitId : '');
      // newItem.push(item.loadType ? item.loadType : '');
      // newItem.push(item.billableServiceId ? item.billableServiceId : '');
      // newItem.push(item.instanceId ? item.instanceId : '');
      // newItem.push(item.partMonthWaiveOffFlag ? 'TRUE' : item.partMonthWaiveOffFlag === false ? 'FALSE' : '');
      // newItem.push(item.moveOrder ? item.moveOrder : '');
      // newItem.push(item.isTraffic ? 'TRUE' : item.isTraffic === false ? 'FALSE' : '');
      // newItem.push(item.advanceFlag ? 'TRUE' : item.advanceFlag === false ? 'FALSE' : '');
      // newItem.push(typeof item.initialTerm === 'number' ? formatMoneyValueToExport(item.initialTerm) : '');
      // newItem.push(item.termEndDate ? item.termEndDate : '');
      // newItem.push(item.isParent ? 'TRUE' : item.isParent === false ? 'FALSE' : '');
      // newItem.push(item.isDispersed ? item.isDispersed : '');
      // newItem.push(item.isMerged ? item.isMerged : '');
      // newItem.push(item.chargeType ? item.chargeType : '');
      // newItem.push(item.location1 ? item.location1 : '');
      // newItem.push(item.reason ? item.reason : '');
      // newItem.push(getLabelOptions({ item, t, fieldName: 'orderType', fieldOption: 'types' }));
      // newItem.push(getLabelOptions({ item, t, fieldName: 'orderStatus', fieldOption: 'statusOrder' }));
      // newItem.push(item.crmId ? item.crmId : '');
      // newItem.push(item.provisioningId ? item.provisioningId : '');
      // newItem.push(item.opportunityName ? item.opportunityName : '');
      // newItem.push(item.location2 ? item.location2 : '');
      // newItem.push(item.billed ? 'TRUE' : item.billed === false ? 'FALSE' : '');
      // newItem.push(item.reason ? item.reason : '');
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 2, cellPadding: 1, ...rest });
};

export default convertJson2Pdf;
