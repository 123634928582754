import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['index', 'unit', 'status', 'isQuantityScalable']);
  const modifyRateUnit = `modifyRateUnit(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyRateUnit} {
      id
    }
  }
`;
};
