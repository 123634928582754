import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarSupportSubItem from '../../components/common/SidebarSupportSubItem';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand, sideProps, permissionsQuote } = this.props;
    sideProps.isExpand = isExpand;
    if (!isHasPermissionWithMenu({ permissions: permissionsQuote, listKeys: permissionList.quoteManagement }))
      return null;
    return <SidebarSupportSubItem {...sideProps} isMaximizeOne />;
  }
}

// export default Sidebar;
const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsQuote = store.get('auth').toJS().permissionsApp.customerHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsQuote: permissionsQuote.length
      ? permissionsQuote.filter(a => a.moduleName === 'QUOTE_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  sideProps: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: true,
  sideProps: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
