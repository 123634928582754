import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, difference, isNumeric } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { getCurrencyConfig, modifyCurrencyConfig, createCurrencyConfig } from '../../actions';
import { makeGetCurrencyListConfig } from '../../selectors';
import {
  convertSymbol2OptionCurrency,
  findIndexCurrency,
  findSymbolCurrency,
} from '../../../../constantsApp/symbolList';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept } from '../../../../components/common';
import { supportShowNumberValue } from '../../../../utils/utils';
import {
  checkPermissionCreateCurrencyConfig,
  checkPermissionModifyCurrencyConfig,
  checkPermissionViewCurrencyConfig,
} from '../../CheckPermission';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';

class Currency extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    currencyList: [],
    isSearching: false,
    isOpenModalRemoveItem: false,
    isModify: false,
  };

  componentDidMount() {
    this.doGetCurrencyConfig();
  }

  componentWillReceiveProps(nextProps) {
    const { currencyList } = this.props;
    if (currencyList && nextProps.currencyList && currencyList !== nextProps.currencyList) {
      this.setState({
        currencyList: nextProps.currencyList,
        defaultDataFromAPI: nextProps.currencyList,
      });
    }
  }

  onChangeTable = ({ name, value, index }) => {
    const { currencyList } = this.state;
    const newData = cloneDeep(currencyList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    newData[indexItemChange][name] = value;
    this.setState({ currencyList: newData });
  };

  onChangeCurrency = ({ name, value, index }) => {
    const { currencyList } = this.state;
    const newData = cloneDeep(currencyList);
    const indexItemChange = newData.findIndex(item => item.index === index);
    newData[indexItemChange][name] = value;
    newData[indexItemChange].index = findIndexCurrency(value) || 0;
    newData[indexItemChange].symbol = findSymbolCurrency(value) || '';
    this.setState({ currencyList: newData });
  };

  doGetCurrencyConfig = () => {
    const { getCurrencyConfig } = this.props;
    getCurrencyConfig(({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id) });
    });
  };

  onAddNewCurrency = () => {
    let indexNewItem = 0;
    const { currencyList } = this.state;
    let newData = cloneDeep(currencyList);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      currencyId: null,
      symbol: null,
      name: null,
      roundingMethod: null,
      roundingPrecision: null,
      paymentThreshold: '0',
      paymentExchangeThreshold: '0',
    };
    newData = [newDataItem, ...newData];
    // newData.push(newDataItem);
    this.setState({ currencyList: newData });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onRemoveItemTable = () => {
    const { currencyList, idItemRemove } = this.state;
    const newData = cloneDeep(currencyList);
    const indexItemRemove = newData.findIndex(item => item.index === idItemRemove);
    if (indexItemRemove > -1) {
      newData.splice(indexItemRemove, 1);
    }
    this.setState({ currencyList: newData, isOpenModalRemoveItem: false });
  };

  onHanldeSubmit = () => {
    const { isModify, currencyList, defaultDataFromAPI } = this.state;
    const { modifyCurrencyConfig, createCurrencyConfig } = this.props;
    if (isModify) {
      const newPayloadModify = {
        currencyListConfig:
          currencyList && currencyList.length
            ? currencyList.map(val => {
                const { paymentExchangeThreshold, paymentThreshold, ...rest } = val;
                return {
                  ...rest,
                  paymentExchangeThreshold:
                    paymentExchangeThreshold || Number.parseFloat(paymentExchangeThreshold) === 0
                      ? paymentExchangeThreshold
                      : '0',
                  paymentThreshold:
                    paymentThreshold || Number.parseFloat(paymentThreshold) === 0 ? paymentThreshold : '0',
                };
              })
            : null,
      };
      const listIndexRemove = difference(
        defaultDataFromAPI.map(item => item.index),
        currencyList.map(val => val.index)
      );
      if (listIndexRemove.length) {
        listIndexRemove.forEach(value => {
          newPayloadModify.currencyListConfig.push({ index: value });
        });
      }
      modifyCurrencyConfig(newPayloadModify);
    } else {
      const newPayloadCreate = {
        currencyListConfig: currencyList,
      };
      createCurrencyConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetCurrencyConfig();
        }
      });
    }
  };

  render() {
    const { currencyList, isSearching, isOpenModalRemoveItem, isModify } = this.state;
    const {
      t,
      location: { state },
      permissionsPricing,
    } = this.props;
    let modeCreateCurrencyConfig = 0;
    let modeModifyCurrencyConfig = 0;
    let modeViewCurrencyConfig = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeViewCurrencyConfig = checkPermissionViewCurrencyConfig({
        listPermission,
      });
      modeCreateCurrencyConfig = checkPermissionCreateCurrencyConfig({
        listPermission,
      });
      modeModifyCurrencyConfig = checkPermissionModifyCurrencyConfig({
        listPermission,
      });
    }

    if (!modeViewCurrencyConfig) return null;

    const creditProfileColumns = [
      {
        name: 'currencyId',
        label: 'label.currencyId',
        required: true,
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item) => (
          <GenericInput
            type="select"
            value={item.currencyId || null}
            wrapperClass="col-md-12"
            options={convertSymbol2OptionCurrency(currencyList)}
            menuPortalTarget
            onChange={evt => this.onChangeCurrency({ name: 'currencyId', value: evt.value, index: item.index })}
            name="currencyId"
          />
        ),
      },
      {
        name: 'symbol',
        label: 'label.symbol',
        required: true,
        // style: { textAlign: 'center', minWidth: '180px'},
        render: (colName, item) => {
          return (
            <span style={{ textAlign: 'center' }}>{item.symbol}</span>
            // <GenericInput
            //   value={item.symbol || null}
            //   type="select"
            //   options={convertSymbol2OptionName}
            //   menuPortalTarget
            //   wrapperClass="col-md-12"
            //   onChange={evt => this.onChangeTable({ name: 'symbol', value: evt.value, index: item.index })}
            //   name="symbol"
            // />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="name"
          />
        ),
      },
      {
        name: 'roundingMethod',
        label: 'label.roundingMethod',
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            type="select"
            value={item.roundingMethod}
            menuPortalTarget
            tOptions="selections:roundingMethod"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="roundingMethod"
          />
        ),
      },
      {
        name: 'roundingPrecision',
        label: 'label.roundingPrecision',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={supportShowNumberValue(item.roundingPrecision)}
            type="number"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="roundingPrecision"
          />
        ),
      },
      {
        name: 'paymentThreshold',
        label: 'label.payemntThreshold',
        tooltipLabel: 'label.tooltipLabelPaymentThreshold',
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.paymentThreshold || '0'}
            type="number"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="paymentThreshold"
          />
        ),
      },
      {
        name: 'paymentExchangeThreshold',
        label: 'label.paymentXchangeThreshold',
        tooltipLabel: 'label.tooltipLabelPaymentXchangeThreshold',
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item) => (
          <GenericInput
            value={item.paymentExchangeThreshold || '0'}
            type="number"
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="paymentExchangeThreshold"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-phone"
                onClick={() => this.onToggleModalRemoveItem({ index: item.index })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={
            state && state.backLink ? { pathname: state.backLink, state: state || null } : RouteNames.currency.path
          }
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.currency') },
          ]}
        />
        <FormWithTableItem title={t('baseConfigurationsPage:sidebar.currency')} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateCurrencyConfig === 2 && (
              <button type="button" className="button button-border black x-small" onClick={this.onAddNewCurrency}>
                +
                {t('label.addNewCurrency')}
              </button>
            )}
            {modeModifyCurrencyConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {isModify ? t('label.modify') : t('label.createConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetCurrencyConfig()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={creditProfileColumns}
              data={currencyList && currencyList.length ? currencyList : []}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message={t('label.messageDelete')}
        />
      </div>
    );
  }
}

Currency.propTypes = {
  getCurrencyConfig: PropTypes.func.isRequired,
  modifyCurrencyConfig: PropTypes.func.isRequired,
  createCurrencyConfig: PropTypes.func.isRequired,
  currencyList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

Currency.defaultProps = {
  currencyList: [],
};

const mapStateToProps = createStructuredSelector({
  currencyList: makeGetCurrencyListConfig() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getCurrencyConfig, modifyCurrencyConfig, createCurrencyConfig })(Currency)
);
