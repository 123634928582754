import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GenericInput } from '../../../common';
import { dataSelect } from '../../../../constantsApp';

const PeakHoursConfigurationForm = ({ onChange, data }) => (
  <div className="col-md-12">
    <div className="card-body row">
      <GenericInput
        label="label.startHourOfDay"
        type="select"
        value={data && data.startTime ? moment(data.startTime).format('HH:mm:ss') : null}
        wrapperClass="col-md-4"
        options={dataSelect.hourOfDay}
        onChange={onChange}
        name="startTime"
      />
      <GenericInput
        label="label.endHourOfDay"
        value={data && data.endTime ? moment(data.endTime).format('HH:mm:ss') : null}
        type="select"
        wrapperClass="col-md-4"
        onChange={onChange}
        options={dataSelect.hourOfDay}
        name="endTime"
      />
    </div>
  </div>
);

PeakHoursConfigurationForm.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
};

PeakHoursConfigurationForm.defaultProps = {
  onChange: () => {},
  data: {},
};

export default PeakHoursConfigurationForm;
