import { createSelector } from 'reselect';

const selectCollections = state => state.get('manageProfileReducer');

const makeGetUserById = () =>
  createSelector(
    selectCollections,
    item => item.get('data')
  );

export { makeGetUserById };
