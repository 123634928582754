import gql from 'graphql-tag';

export default () => {
  const getGroupReasonCodes = 'getGroupReasonCodes( dummy: "")';
  return gql`
    query {
      ${getGroupReasonCodes} {
        id
        name
        groups {
          index
          groupId
          groupName
          reasonCodes {
            index
            reasonCode
            customReasonCode
            description
          }
        }
      }
    }
  `;
};
