import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionSearchBundles,
  checkPermissionGetBundle,
  checkPermissionSearchPackage,
  checkPermissionGetPackage,
} from './CheckPermission';

class Sidebar extends Component {
  state = {};

  render() {
    const {
      isExpand = true,
      location: { pathname },
      permissionsBundleManagement,
    } = this.props;

    let childsItemBundle = [
      {
        icon: 'ti-mobile',
        label: 'bundlePage:sidebar.bundle',
        href: RouteNames.bundleItem.path,
        isActive: pathname && pathname.indexOf(RouteNames.bundleItem.path) >= 0,
      },
      {
        icon: 'ti-gift',
        label: 'bundlePage:sidebar.package',
        href: RouteNames.packageItem.path,
        isActive: pathname && pathname.indexOf(RouteNames.packageItem.path) >= 0,
      },
      {
        icon: 'ti-money',
        label: 'bundlePage:sidebar.dependency',
        href: RouteNames.dependencyItem.path,
        isActive: pathname && pathname.indexOf(RouteNames.dependencyItem.path) >= 0,
      },
    ];
    let modeSearchBundles = 0;
    let modeGetBundle = 0;
    let modeSearchPackage = 0;
    let modeGetPackage = 0;

    if (permissionsBundleManagement && permissionsBundleManagement.bundleModulePermissions) {
      const listPermissionBundle = permissionsBundleManagement.bundleModulePermissions;
      modeSearchBundles = checkPermissionSearchBundles({ listPermission: listPermissionBundle });
      modeGetBundle = checkPermissionGetBundle({ listPermission: listPermissionBundle });
      modeSearchPackage = checkPermissionSearchPackage({ listPermission: listPermissionBundle });
      modeGetPackage = checkPermissionGetPackage({ listPermission: listPermissionBundle });
    }

    if (modeSearchBundles === 0 || modeGetBundle === 0) {
      childsItemBundle = childsItemBundle.filter(item => item.label !== 'bundlePage:sidebar.bundle');
    }

    if (modeSearchPackage === 0 || modeGetPackage === 0) {
      childsItemBundle = childsItemBundle.filter(item => item.label !== 'bundlePage:sidebar.package');
    }

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: false,
          mainTilte: 'navbar:pricingHub.main',
          href: RouteNames.bundleItem.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'navbar:pricingHub.subMain.bundleManagement',
          childs: childsItemBundle,
        },
      ],
    };
    return <SideBar {...sideProps} />;
  }
}

Sidebar.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
};

Sidebar.defaultProps = {
  permissionsBundleManagement: {},
  location: {},
  isExpand: true,
};

// export default Sidebar;
const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsBundleManagement = store.get('auth').toJS().permissionsApp.pricingHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsBundleManagement: permissionsBundleManagement.length
      ? permissionsBundleManagement.filter(a => a.moduleName === 'BUNDLE_MANAGEMENT')[0]
      : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
