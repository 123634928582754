export default [
  {
    method: 'NET_30',
    description: 'Payment is due after 30 days',
  },
  {
    method: 'NET_45',
    description: 'Payment is due after 45 days',
  },
  {
    method: 'NET_60',
    description: 'Payment is due after 60 days',
  },
  {
    method: 'NET_75',
    description: 'Payment is due after 75 days',
  },
  {
    method: 'NET_90',
    description: 'Payment is due after 90 days',
  },
];
