import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const billingTrackerReport = `billingTrackerReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${billingTrackerReport} {
        clientName
        clientAccountId
        month
        year
        paymentDate
        paymentAmount
        invoiceId
        comments
        exchangeRate
        billedExchangeAmount
        paymentDate
        billDue
        billedAmount
        billedExchangeAmount
        clientAccountId
        billStart
        billEnd
      }
    }
  `;
};
