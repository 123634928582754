import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../../constantsApp/countryList';
import { getIdSequenceConfig, modifyIdSequenceConfig, createIdSequenceConfig } from '../actions';
import { makeGetListIdSequenceConfig } from '../selectors';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept } from '../../../components/common';
import {
  checkPermissionCreateCustomDBConfig,
  checkPermissionModifyCustomDBConfig,
  checkPermissionGetCustomDBConfig,
} from '../CheckPermission';
import { makeGetPermissionsCustomerManagement } from '../../App/selectors';

class IdSequenceConfig extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    listIdSequenceConfig: [],
    isSearching: true,
    isOpenModalRemoveItem: false,
    dataSubmit: [],
    isModify: false,
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.listIdSequenceConfig) return null;
    return {
      listIdSequenceConfig:
        props.listIdSequenceConfig && props.listIdSequenceConfig.customDBSeqObject
          ? props.listIdSequenceConfig.customDBSeqObject
          : [],
      oldValue: props.listIdSequenceConfig,
      dataSubmit: [],
      defaultDataFromAPI: props.listIdSequenceConfig,
    };
  }

  componentDidMount() {
    this.doGetIdSequenceConfig();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { listIdSequenceConfig } = this.state;
    const newData = cloneDeep(listIdSequenceConfig);
    newData[index][name] = value;
    this.setState({ listIdSequenceConfig: newData });
  };

  doGetIdSequenceConfig = () => {
    const { getIdSequenceConfig } = this.props;
    getIdSequenceConfig('', ({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.id) });
    });
  };

  onAddNewIdSequenceConfig = () => {
    let indexNewItem = 0;
    const { listIdSequenceConfig, dataSubmit } = this.state;
    let newData = cloneDeep(listIdSequenceConfig);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      objectType: null,
      paymentMethod: null,
      customPaymentMethod: null,
      sellingCompany: null,
      legalEntity: null,
      customerSegment: null,
      prefix: null,
      separator: null,
      seedNumber: null,
      minimumLength: null,
      sequenceName: null,
      state: null,
      country: null,
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ listIdSequenceConfig: newData, dataSubmit });
  };

  onToggleModalRemoveItem = ({ index = '' }) => {
    const { isOpenModalRemoveItem } = this.state;
    let { idItemRemove } = this.state;
    if (index) {
      idItemRemove = index;
    }
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, idItemRemove });
  };

  onHanldeSubmit = e => {
    e.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { listIdSequenceConfig, isModify, defaultDataFromAPI } = this.state;
    const { modifyIdSequenceConfig, createIdSequenceConfig } = this.props;

    if (isModify) {
      const newPayloadModify = {
        customDBSeqObject: listIdSequenceConfig.map(val => {
          const { sequenceInUse, ...rest } = val;
          return rest;
        }),
        id: defaultDataFromAPI.id,
      };
      modifyIdSequenceConfig(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(listIdSequenceConfig).map(item => {
        const newItem = item;
        delete newItem.index;
        delete newItem.sequenceInUse;
        return newItem;
      });
      const newPayloadCreate = {
        customDBSeqObject: dataRemoveIndex,
      };
      createIdSequenceConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetIdSequenceConfig();
        }
      });
    }
  };

  render() {
    const { listIdSequenceConfig, isSearching, isOpenModalRemoveItem, wasValidated } = this.state;
    const { t, permissionsCustomer } = this.props;

    let modeCreateCustomDBConfig = 0;
    let modeModifyCustomDBConfig = 0;
    let modeGetCustomDBConfig = 0;
    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeCreateCustomDBConfig = checkPermissionCreateCustomDBConfig({ listPermission });
      modeModifyCustomDBConfig = checkPermissionModifyCustomDBConfig({ listPermission });
      modeGetCustomDBConfig = checkPermissionGetCustomDBConfig({ listPermission });
    }

    if (!modeGetCustomDBConfig) {
      return '';
    }
    const creditProfileColumns = [
      {
        name: 'objectType',
        label: 'label.objectType',
        style: { minWidth: '200px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.objectType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="objectType"
            type="select"
            menuPortalTarget
            tOptions="selections:customDBSeqObjectType"
            required
          />
        ),
      },
      {
        name: 'paymentMethod',
        label: 'label.paymentMethod',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.paymentMethod}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="paymentMethod"
            type="select"
            menuPortalTarget
            tOptions="selections:paymentMethod"
          />
        ),
      },
      {
        name: 'customPaymentMethod',
        label: 'label.customPaymentMethod',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.customPaymentMethod}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="customPaymentMethod"
          />
        ),
      },
      {
        name: 'state',
        label: 'label.state',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.state}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="state"
          />
        ),
      },
      {
        name: 'country',
        label: 'label.country',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.country}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="country"
            options={countryList}
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'sellingCompany',
        label: 'label.sellingCompany',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.sellingCompany}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="sellingCompany"
          />
        ),
      },
      {
        name: 'legalEntity',
        label: 'label.legalEntity',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.legalEntity}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="legalEntity"
          />
        ),
      },
      {
        name: 'customerSegment',
        label: 'label.customerSegment',
        style: { minWidth: '190px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.customerSegment}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="customerSegment"
            type="select"
            menuPortalTarget
            tOptions="selections:customerSegment"
          />
        ),
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="accountType"
            type="select"
            menuPortalTarget
            tOptions="selections:accountType"
          />
        ),
      },
      {
        name: 'prefix',
        label: 'label.prefix',
        style: { minWidth: '130px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.prefix}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="prefix"
          />
        ),
      },
      {
        name: 'separator',
        label: 'label.separator',
        style: { minWidth: '130px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.separator}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="separator"
          />
        ),
      },
      {
        name: 'sequenceInUse',
        label: 'label.sequenceInUse',
        style: { minWidth: '130px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.sequenceInUse === true ? 'TRUE' : 'FALSE'}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => {}}
            name="sequenceInUse"
            readOnly
            disabled
            type="select"
            tOptions="selections:selectBool"
          />
        ),
      },
      {
        name: 'sequenceName',
        label: 'label.sequenceName',
        style: { minWidth: '130px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.sequenceName}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="sequenceName"
            required
          />
        ),
      },
      {
        name: 'sequenceSeed',
        label: 'label.sequenceSeed',
        style: { minWidth: '130px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.sequenceSeed}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="sequenceSeed"
            type="number"
            required
          />
        ),
      },
      {
        name: 'minimumLength',
        label: 'label.minimumLength',
        style: { minWidth: '130px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.minimumLength}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="minimumLength"
            type="number"
          />
        ),
      },
    ];
    const newDataRemoveIndex =
      listIdSequenceConfig && listIdSequenceConfig && listIdSequenceConfig.length
        ? listIdSequenceConfig.filter(item => size(item) > 1)
        : [];
    return (
      <div className="col-md-12 mb-30">
        <FormWithTableItem title={t('tenantPage:tenantManagementPage.idSequenceConfig')} subClass="border-bottom">
          <form
            noValidate
            ref={this.formRef}
            onSubmit={this.onHanldeSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          >
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateCustomDBConfig === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={this.onAddNewIdSequenceConfig}
                >
                  +
                  {t('label.addNewRow')}
                </button>
              )}
              {modeModifyCustomDBConfig === 2 && (
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                >
                  {t('label.saveConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => this.doGetIdSequenceConfig()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <div>
              <DataTable
                columns={creditProfileColumns}
                data={newDataRemoveIndex}
                isLoading={isSearching}
                isFixedHeaderTable
              />
            </div>
            <br />
          </form>
        </FormWithTableItem>
        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message="Are you sure you want to delete this Item?"
        />
      </div>
    );
  }
}

IdSequenceConfig.propTypes = {
  getIdSequenceConfig: PropTypes.func.isRequired,
  modifyIdSequenceConfig: PropTypes.func.isRequired,
  createIdSequenceConfig: PropTypes.func.isRequired,
  listIdSequenceConfig: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

IdSequenceConfig.defaultProps = {
  listIdSequenceConfig: [],
};

const mapStateToProps = createStructuredSelector({
  listIdSequenceConfig: makeGetListIdSequenceConfig() || {},
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getIdSequenceConfig, modifyIdSequenceConfig, createIdSequenceConfig })(IdSequenceConfig)
);
