import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getRegionMapConfig, modifyRegionMapConfig, createRegionMapConfig } from '../actions';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import { getIndexData } from '../../../utils/utils';
import {
  checkPermissionCreateRegionMapConfig,
  checkPermissionModifyRegionMapConfig,
  checkPermissionGetRegionMapConfig,
} from '../CheckPermission';

class RegionMapping extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    listRegionMapping: [],
    isSearching: true,
    dataSubmit: [],
    isModify: false,
  };

  componentDidMount() {
    this.doGetRegionMappingCfg();
  }

  onChangeTable = ({ name, value, index }) => {
    const { listRegionMapping, dataSubmit } = this.state;
    const newData = cloneDeep(listRegionMapping);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ listRegionMapping: newData });
  };

  doGetRegionMappingCfg = () => {
    const { getRegionMapConfig } = this.props;
    getRegionMapConfig(({ data }) => {
      this.setState({
        isSearching: false,
        isModify: !!(data && data.id),
        listRegionMapping: data && data.regions ? data.regions : [],
        id: data && data.id ? data.id : [],
      });
    });
  };

  onAddNewRegionMapping = () => {
    let indexNewItem = 0;
    const { listRegionMapping, dataSubmit } = this.state;
    let newData = cloneDeep(listRegionMapping);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      description: '',
      longDistanceType: '',
      regionId: null,
    };
    newData = [newDataItem, ...newData];
    dataSubmit.push(newDataItem);
    this.setState({ listRegionMapping: newData, dataSubmit });
  };

  onRemoveItemTable = ({ index }) => {
    try {
      const { listRegionMapping, dataSubmit } = this.state;
      const newData = cloneDeep(listRegionMapping);
      newData[getIndexData({ index, data: newData })] = {
        index: newData[getIndexData({ index, data: newData })].index,
      };
      if (getIndexData({ index, data: dataSubmit }) > -1) {
        dataSubmit[getIndexData({ index, data: newData })] = {
          index: newData[getIndexData({ index, data: newData })].index,
        };
      } else {
        dataSubmit.push({
          index: newData[getIndexData({ index, data: newData })].index,
        });
      }
      this.setState({ listRegionMapping: newData, dataSubmit });
    } catch (error) {
      console.log(error);
    }
  };

  onHanldeSubmit = () => {
    const { dataSubmit, isModify, defaultDataFromAPI, id } = this.state;
    const { modifyRegionMapConfig, createRegionMapConfig } = this.props;
    if (isModify) {
      const newPayloadModify = {
        regions: dataSubmit,
        id,
      };
      modifyRegionMapConfig(newPayloadModify);
    } else {
      const dataRemoveIndex = cloneDeep(dataSubmit).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        regions: dataRemoveIndex,
      };
      createRegionMapConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetRegionMappingCfg();
        }
      });
    }
  };

  render() {
    const { listRegionMapping, isSearching } = this.state;
    const { t, permissionsPricing } = this.props;
    let modeCreateRegionMapConfig = 0;
    let modeModifyRegionMapConfig = 0;
    let modeGetRegionMapConfig = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateRegionMapConfig = checkPermissionCreateRegionMapConfig({ listPermission: listPermissionPricing });
      modeModifyRegionMapConfig = checkPermissionModifyRegionMapConfig({ listPermission: listPermissionPricing });
      modeGetRegionMapConfig = checkPermissionGetRegionMapConfig({ listPermission: listPermissionPricing });
    }
    if (modeGetRegionMapConfig === 0) return '';
    const creditProfileColumns = [
      {
        name: 'regionId',
        label: 'label.regionId',
        style: { textAlign: 'center', minWidth: '130px' },
        render: (colName, item) => (
          <GenericInput
            value={item.regionId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="regionId"
            type="number"
          />
        ),
      },
      {
        name: 'description',
        label: 'label.description',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.description}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="description"
          />
        ),
      },
      {
        name: 'longDistanceType',
        label: 'label.longDistanceType',
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item) => (
          <GenericInput
            value={item.longDistanceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: item.index })}
            name="longDistanceType"
            type="select"
            menuPortalTarget
            tOptions="selections:longDistanceType"
          />
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveItemTable({ index: item.index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];
    const newDataRemoveIndex =
      listRegionMapping && listRegionMapping && listRegionMapping.length
        ? listRegionMapping.filter(item => size(item) > 1)
        : [];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: `${t('baseConfigurationsPage:sidebar.regionMapping')}` },
          ]}
          isNoDefaultButton
        />
        <FormWithTableItem title={`${t('baseConfigurationsPage:sidebar.regionMapping')}`} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateRegionMapConfig === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={this.onAddNewRegionMapping}
              >
                +
                {t('label.addNewRegionMapping')}
              </button>
            )}
            {modeModifyRegionMapConfig === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHanldeSubmit()}
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={() => this.doGetRegionMappingCfg()}
              className="button button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <div>
            <DataTable
              columns={creditProfileColumns}
              data={newDataRemoveIndex}
              isLoading={isSearching}
              isFixedHeaderTable
            />
          </div>
        </FormWithTableItem>
      </div>
    );
  }
}

RegionMapping.propTypes = {
  getRegionMapConfig: PropTypes.func.isRequired,
  modifyRegionMapConfig: PropTypes.func.isRequired,
  createRegionMapConfig: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.object,
};

RegionMapping.defaultProps = {
  permissionsPricing: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getRegionMapConfig, modifyRegionMapConfig, createRegionMapConfig })(RegionMapping)
);
