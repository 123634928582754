import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  DataTable,
  TablePagination,
  ModalWithItem,
  CheckGroup,
  ModalAccept,
  ButtonExport,
  ExcelExport,
  SwitchExport,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import {
  searchBatchPaymentProcess,
  getBatchPaymentDetais,
  processBatchPaymentProxy,
  getPaymentItemMap,
  deletePaymentFile,
} from '../actions';
import { getPageTotalCount } from '../../../utils/utils';
import { BatchPaymentSearchForm } from '../../../components/PaymentHub';
import {
  sortBatchPayment,
  tableBanamexColumns,
  tableBanorteColumns,
  tableBancomerColumns,
  tableSantanderColumns,
} from '../constant';
import { makeGetPermissionsPaymentManagement } from '../../App/selectors';
import { makeGetBatchPayment } from '../selectors';
import { checkPermissionBatchPaymentProcess } from '../CheckPermission';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelProcessPayment';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfProcessPayment';
import UploadPayment from '../UploadPayment';

class ProcessPayment extends React.PureComponent {
  state = {
    data: [],
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    dataSelect: [],
    paymentTypeSelect: null,
    itemChecked: null,
    isOpenModalAccept: false,
    fileNameOptions: [],
    isActivePdf: false,
    itemSelect: null,
  };

  buttonRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.batchPayment,
      totalCount: getPageTotalCount({ ...state, items: props.batchPayment }),
    };
  }

  componentDidMount() {
    this.doSearchBatchPaymentProcess();
    const { getPaymentItemMap } = this.props;
    getPaymentItemMap('', ({ success, data }) => {
      if (success && data && data.paymentItemMaps) {
        this.setState({
          fileNameOptions: sortBy(
            data.paymentItemMaps.map(val => ({ value: val.fileName, label: val.fileName })),
            ['label']
          ),
        });
      }
    });
  }

  doSearchBatchPaymentProcess = () => {
    const { searchBatchPaymentProcess } = this.props;
    const { filter, page, size, sorted } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter: Object.keys(filter).length < 1 ? null : filter,
      sort: !isEmpty(sorted) ? sortBatchPayment[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchBatchPaymentProcess(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchBatchPaymentProcess());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchBatchPaymentProcess());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchBatchPaymentProcess());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchBatchPaymentProcess());
  };

  onHandleViewBatchPayment = itemSelect => {
    const { getBatchPaymentDetais } = this.props;
    getBatchPaymentDetais(
      { paymentType: itemSelect.paymentType, fileName: itemSelect.fileName },
      ({ success, data }) => {
        if (success)
          this.setState({
            dataSelect: data || [],
            paymentTypeSelect: itemSelect.paymentType,
            itemSelect,
            isOpen: true,
            isActivePdf: false,
          });
      }
    );
  };

  onToggle = () => {
    this.setState({ dataSelect: [], paymentTypeSelect: null, isOpen: false });
  };

  onChangeCheckBox = item => {
    const { itemChecked } = this.state;
    if (itemChecked && itemChecked.id === item.id) {
      return this.setState({ itemChecked: null });
    }
    return this.setState({ itemChecked: item });
  };

  onProcessItem = () => {
    const { processBatchPaymentProxy } = this.props;
    const { itemChecked } = this.state;
    processBatchPaymentProxy(
      { formatType: itemChecked.paymentType, fileName: itemChecked.fileName },
      ({ success, data }) => {
        if (success) {
          this.setState({ itemChecked: null });
          this.doSearchBatchPaymentProcess();
        }
      }
    );
  };

  // onProcessAllItems = () => {
  //   const { processBatchPaymentProxy } = this.props;
  //   const { filter } = this.state;
  //   processBatchPaymentProxy({ formatType: filter.paymentType }, ({ success }) => {
  //     if (success) {
  //       this.setState({ itemChecked: null });
  //       this.doSearchBatchPaymentProcess();
  //     }
  //   });
  // };

  onToggleModalAccept = () => {
    const { isOpenModalAccept } = this.state;
    this.setState({ isOpenModalAccept: !isOpenModalAccept });
  };

  onAccept = () => {
    const { processBatchPaymentProxy } = this.props;
    const { filter } = this.state;
    processBatchPaymentProxy({ formatType: filter.paymentType }, ({ success }) => {
      if (success) {
        this.setState({ itemChecked: null, isOpenModalAccept: false });
        this.doSearchBatchPaymentProcess();
      }
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = ({ colums, fileNameExport }) => {
    const { isActivePdf, dataSelect } = this.state;
    if (isActivePdf) {
      const { t } = this.props;
      convertJson2Pdf({
        data: dataSelect || [],
        t,
        title: fileNameExport.toLocaleUpperCase(),
        fileName: `${fileNameExport.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
        colums,
      });
    } else {
      this.buttonRef.current.click();
    }
  };

  onDeletePaymentFile = ({ item }) => {
    const { deletePaymentFile } = this.props;
    deletePaymentFile({ paymentFileName: item.fileName || '' }, ({ success }) => {
      if (success) this.doSearchBatchPaymentProcess();
    });
  };

  render() {
    const { t, permissionsPayment } = this.props;
    const {
      data,
      totalCount,
      sorted,
      isSearching,
      page,
      size,
      isOpen,
      paymentTypeSelect,
      dataSelect,
      itemChecked,
      filter,
      isOpenModalAccept,
      fileNameOptions,
      isActivePdf,
      itemSelect,
    } = this.state;
    let modeGetBatchPayment = 0;
    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermissionsPayment = permissionsPayment.paymentModulePermissions;
      modeGetBatchPayment = checkPermissionBatchPaymentProcess({ listPermission: listPermissionsPayment });
    }
    if (!modeGetBatchPayment) return '';
    let columnsViewModal = [];
    switch (paymentTypeSelect) {
      case 'BANAMEX':
        columnsViewModal = tableBanamexColumns;
        break;
      case 'BANCOMER':
        columnsViewModal = tableBancomerColumns;
        break;
      case 'BANORTE':
        columnsViewModal = tableBanorteColumns;
        break;
      case 'SANTANDER':
        columnsViewModal = tableSantanderColumns;
        break;
      default:
        columnsViewModal = tableBanamexColumns;
      // return response;
      // code block
    }

    let fileNameExport = '';
    if (itemSelect?.fileName) {
      if (itemSelect?.fileName.lastIndexOf('.')) {
        fileNameExport = itemSelect.fileName.substring(0, itemSelect.fileName.lastIndexOf('.'));
      } else {
        fileNameExport = itemSelect.fileName;
      }
    }

    const tableColumns = [
      {
        name: 'export',
        label: 'label.export',
        render: (colName, item) => {
          return (
            <CheckGroup
              onChange={() => this.onChangeCheckBox(item)}
              value={item.userId}
              name="idSelected"
              checked={itemChecked && itemChecked.id === item.id}
              disabled={item.paymentStatus !== 'LOADED'}
            />
          );
        },
      },
      {
        name: 'view',
        label: t('label.view'),
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.onHandleViewBatchPayment(item)}
          >
            {t('common:label.view')}
          </button>
        ),
      },
      {
        name: 'paymentType',
        label: 'label.type',
        sortable: true,
      },
      {
        name: 'name',
        label: 'label.name',
      },
      {
        name: 'createdDate',
        label: 'label.fileDateTime',
        sortable: true,
        render: (colName, item) => (
          <span>{item && item.createdDate ? moment(item.createdDate).format('MM-DD-YYYY') : ''}</span>
        ),
      },
      {
        name: 'fileName',
        label: 'label.fileName',
        sortable: true,
      },
      {
        name: 'paymentStatus',
        label: 'label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:batchPaymentFileStatus')().find(val => val.value === item.paymentStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'totalRecords',
        label: 'label.totalRecords',
        sortable: true,
      },
      {
        name: 'importedRecords',
        label: 'label.importedRecords',
      },
      {
        name: 'pendingRecords',
        label: 'label.pendingRecords',
      },
      {
        name: 'successRecords',
        label: 'label.processedRecords',
      },
      // {
      //   name: 'suspendedRecords',
      //   label: 'label.suspendedRecords',
      // },
      {
        name: 'errorRecords',
        label: 'label.errorRecords',
      },
      {
        name: 'failedRecords',
        label: 'label.failedRecords',
      },
      {
        name: 'setAsideRecords',
        label: 'label.setAsideRecords',
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                disabled={item.paymentStatus !== 'LOADED'}
                className="btn-phone"
                onClick={() => this.onDeletePaymentFile({ item })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.paymentConfiguration.path}
          titleBtn={t('label.back')}
          titleRight={t('paymentPage:sidebar.processPayment')}
        />
        <br />
        <UploadPayment fileNameOptions={fileNameOptions} onRefresh={this.doSearchBatchPaymentProcess} />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <BatchPaymentSearchForm
                    onSubmit={this.onHandleSubmit}
                    keyOptionInject="name"
                    optionsInject={fileNameOptions || []}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="pt-3 pb-3 pr-3">
              <button
                disabled={!itemChecked || itemChecked?.paymentStatus !== 'LOADED'}
                onClick={this.onProcessItem}
                type="button"
                className="button x-small mt-2 ml-3 float-right"
              >
                {t('label.process')}
              </button>
              <button
                type="button"
                disabled={!filter.paymentType}
                onClick={this.onToggleModalAccept}
                className="button x-small mt-2 ml-3 float-right"
              >
                {t('label.processAll')}
              </button>
              <br />
            </div>
            <div className="card-body">
              <DataTable
                columns={tableColumns}
                sorted={sorted}
                data={data}
                onSort={this.onSortColumn}
                isLoading={isSearching}
                isFixedHeaderTable
              />
            </div>
          </div>
        </div>

        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
        <ModalWithItem
          onToggle={this.onToggle}
          modalTitle={fileNameExport.toLocaleUpperCase()}
          isOpen={isOpen}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isSupportTopBackButton
        >
          <div className="d-flex float-right pb-3">
            <div className="ml-auto mt-2 mb-auto mr-3">
              <SwitchExport
                onChange={this.onChangeSwitch}
                wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                title={t('label.excel')}
                checked={isActivePdf}
                rightTitle={t('label.pdf')}
              />
            </div>
            <>
              <ButtonExport onExport={() => this.onExport({ colums: columnsViewModal, fileNameExport })} />
              <ExcelExport
                element={<button type="button" className="display-none" ref={this.buttonRef} />}
                nameSheet={fileNameExport.toLocaleUpperCase()}
                multiDataSet={convertJson2Sheet({
                  data: dataSelect || [],
                  t,
                  title: fileNameExport.toLocaleUpperCase(),
                  colums: columnsViewModal,
                })}
                fileName={`${fileNameExport.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
                  'YYYY_MM_DD'
                )}`}
              />
            </>
          </div>

          <DataTable
            isFixedHeaderTable
            columns={columnsViewModal}
            data={dataSelect}
            onSort={this.onSortColumn}
            isLoading={isSearching}
          />
          <br />
          {/* <div className="col-md-12">
            <button onClick={this.onToggle} type="button" className="button x-small mt-2 ml-3 float-right">
              {t('label.back')}
            </button>
          </div> */}
        </ModalWithItem>
        <ModalAccept
          isOpen={isOpenModalAccept}
          onToggle={this.onToggleModalAccept}
          onAcceptModal={this.onAccept}
          message={t('message.messageProcess')}
        />
      </div>
    );
  }
}

ProcessPayment.propTypes = {
  searchBatchPaymentProcess: PropTypes.func,
};

ProcessPayment.defaultProps = {
  searchBatchPaymentProcess: () => {},
};

const mapStateToProps = createStructuredSelector({
  batchPayment: makeGetBatchPayment() || [],
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchBatchPaymentProcess,
    getBatchPaymentDetais,
    processBatchPaymentProxy,
    getPaymentItemMap,
    deletePaymentFile,
  })(withRouter(ProcessPayment))
);
