import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { searchEmployeeCostingConfig } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import SearchForm from './SearchForm';

class ModalSelectEmployee extends PureComponent {
  doSearchOrders = (payload, cb) => {
    const { filter, ...rest } = payload;
    const { searchEmployeeCostingConfig, isOpen } = this.props;
    if (isOpen)
      searchEmployeeCostingConfig({ ...rest, filter: { ...filter } }, ({ success, data }) => {
        if (cb)
          cb({
            success,
            data: success && data ? data : [],
          });
      });
  };

  render() {
    const orderColumns = [
      {
        name: 'id',
        label: 'label.id',
      },
      {
        name: 'employeeName',
        label: 'label.employee',
      },
      {
        name: 'jobTitle',
        label: 'label.jobTitle',
      },
      {
        name: 'clientId',
        label: 'label.clientId',
      },
      {
        name: 'clientName',
        label: 'label.clientName',
      },
      {
        name: 'clientJobTitle',
        label: 'label.clientJobTitle',
      },
      {
        name: 'firstPayrollDate',
        label: 'label.firstPayrollDate',
      },
      {
        name: 'firstSalaryAmount',
        label: 'label.firstPayrollAmount',
      },
      {
        name: 'secondPayrollDate',
        label: 'label.secondPayrollDate',
      },
      {
        name: 'secondSalaryAmount',
        label: 'label.secondSalaryAmount',
      },
      {
        name: 'noOfDaysWorked',
        label: 'label.numDaysWorked',
      },
      {
        name: 'startDate',
        label: 'label.startDate',
      },
      {
        name: 'endDate',
        label: 'label.endDate',
      },
      {
        name: 'createdDate',
        label: 'label.createdDate',
      },
      {
        name: 'status',
        label: 'label.status',
      },
    ];

    const { isOpen, onSelect, onCancel, selectedParentId, t, defaultSearchParams } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.selectEmployee')}
        isOpen={isOpen}
        SearchForm={SearchForm}
        searchItems={this.doSearchOrders}
        tableColumns={orderColumns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName="id"
        defaultSearchParams={defaultSearchParams}
      />
    );
  }
}

ModalSelectEmployee.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchEmployeeCostingConfig: PropTypes.func,
};

ModalSelectEmployee.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  searchEmployeeCostingConfig: () => {},
  selectedParentId: '',
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, { searchEmployeeCostingConfig })(ModalSelectEmployee)
);
