import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PieCharts = ({ title, wrapperClass, data, keySeries, keyCategories, isToolbar, style, height, subClass }) => {
  const { t } = useTranslation('common');
  const options = {
    series: data && data.length > 0 ? data.map(val => val.value) : [],
    options: {
      chart: {
        width: '100%',
        type: 'pie',
        toolbar: {
          show: isToolbar || false,
          export: {
            csv: {
              filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
              columnDelimiter: ',',
              headerCategory: keyCategories || 'Category',
              headerValue: keySeries || 'Value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
            },
            png: {
              filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
            },
          },
        },
        height: height || '330px',
        responsive: [
          {
            options: {
              chart: {
                width: '100%',
                height: height || '330px',
              },
            },
          },
        ],
      },
      labels: data && data.length > 0 ? data.map(val => val.name) : [],
      dataLabels: {
        formatter(val, opts) {
          // const name = opts.w.globals.labels[opts.seriesIndex];
          return [`${Number.parseFloat(val).toFixed(2)}%`];
        },
      },
      noData: {
        text: 'No data to present',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          // color: 'red',
          fontSize: '13px',
          fontFamily: "'Poppins', sans-serif",
          fontStyle: 'italic',
        },
      },
    },
  };

  // const sortData = data && data.length ? sortBy(data, value => moment(value.mon, 'MM-YYYY')).reverse() : [];
  return (
    <div className={`mixed-chart ${wrapperClass || 'col-md-4'}`}>
      <div style={style}>
        <h5 className={`p-2 text-center card-title ${subClass || ''} no-border`}>{title}</h5>
        <ReactApexChart
          className={`embrix-chart ${subClass || ''}${data.length ? '' : 'no-data-chart'}`}
          {...options}
          width="100%"
          height={height || '330px'}
          type="pie"
        />
      </div>
    </div>
  );
};

PieCharts.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  keySeries: PropTypes.string,
  keyCategories: PropTypes.string,
};

PieCharts.defaultProps = {
  title: '',
  wrapperClass: '',
  keySeries: '',
  keyCategories: '',
  data: [],
};

export default PieCharts;
