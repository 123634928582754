import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SideBar from '../Sidebar';
import CollectionCofig from './CollectionConfig';
import AccountCollection from './AccountCollection';
import AgentSumaryActivity from './AgentSumaryActivity';
// import SchedulePatternConfig from './SchedulePatternConfig';
import PageTitle from '../../../components/PageTitle';
import Footer from '../../../components/Footer';
import RouteName from '../../App/RouteNames';

class CollectionAdmin extends React.PureComponent {
  renderContent() {
    const {
      match: {
        params: { childRoute },
      },
    } = this.props;
    switch (childRoute) {
      // case 'collection-config':
      //   return <CollectionCofig />;
      case 'accounts-in-collection':
        return <AccountCollection />;
      case 'agent-activity-summary':
        return <AgentSumaryActivity />;

      // case 'schedule-pattern-config':
      //   return <SchedulePatternConfig />;
      default:
        return <div />;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteName.collectionsConfig.path}
          titleBtn={t('label.back')}
          titleRight={t('label.collections')}
        />
        <br />
        {this.renderContent()}
        <Footer className="footer-bottom" />
      </div>
    );
  }
}

CollectionAdmin.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

CollectionAdmin.defaultProps = {
  match: {},
};

export default withTranslation('common')(CollectionAdmin);
