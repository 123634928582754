import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'noOfInstallments',
    'daysOffset',
    'monthOffset',
    'amount',
    'percent',
    'index',
  ]);
  const setupMultiInvoicePaymentInstallment = `setupMultiInvoicePaymentInstallment(input: ${dataModify})`;
  return gql`
  mutation {
    ${setupMultiInvoicePaymentInstallment} {
      accountId
    }
  }
`;
};
