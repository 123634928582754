import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  futureCycleCfg: {},
  advanceBillingConfig: {},
  pendingBillingTrigger: {},
  employees: [],
  listALlEmployees: [],
  clientProject: [],
  listALlClientProject: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    // collection units

    case types.GET_FUTURE_CYCLE_CFG_SUCCESS_BILLING:
      return state.set('futureCycleCfg', action.payload);

    case types.GET_FUTURE_ADVANCE_CFG_SUCCESS_BILLING:
      return state.set('advanceBillingConfig', action.payload);

    case types.GET_PENDING_BILL_TRIGGER_SUCCESS:
      return state.set('pendingBillingTrigger', action.payload);

    case types.SEARCH_EMPLOYEE_COSTING_SUCCESS:
      return state.set('employees', action.payload);

    case types.SEARCH_ALL_EMPLOYEE_COSTING_SUCCESS:
      return state.set('listALlEmployees', action.payload);

      case types.SEARCH_CLIENT_PROJECT_SUCCESS:
        return state.set('clientProject', action.payload);
  
      case types.SEARCH_ALL_CLIENT_PROJECT_SUCCESS:
        return state.set('listALlClientProject', action.payload);

    default:
      return state;
  }
}
